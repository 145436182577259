import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Badge,
} from "@chakra-ui/react";

import { setNavigationBarStatus } from "../../../actions/utilityActions";

import { Nav } from "./styles";
import CheckPermission from "../../../util/CheckPermission";
import { CheckPerm } from "../../../util/CheckPerm";
let overViewLink = [
  {
    link: "/core",
    path: "/core",
    iconActive:
      require("../../../assets/images/sidebar/wdIcons-active/dashboard-active.svg")
        .default,
    iconInActive:
      require("../../../assets/images/sidebar/wdIcons-Inactive/dashboard-Inactive.svg")
        .default,
    name: "Dashboard",
    section: "OVERVIEW",
    permission: "ALL_FUNCTIONS",
  },
];

let businessLink = [
  {
    link: "/core/clients",
    path: "/core/clients",
    iconActive:
      require("../../../assets/images/sidebar/wdIcons-active/customer-active.svg")
        .default,
    iconInActive:
      require("../../../assets/images/sidebar/wdIcons-Inactive/customer-Inactive.svg")
        .default,
    name: "Clients",
    section: "BUSINESS",
    permission: "READ_CLIENT",
  },
  // {
  //   link: "/core/transactions",
  //   path: "/core/transactions",
  //   iconActive:
  //     require("../../../assets/images/sidebar/business/transaction-active.svg")
  //       .default,
  //   iconInActive:
  //     require("../../../assets/images/sidebar/business/transaction-inactive.svg")
  //       .default,
  //   name: "Transactions",
  //   section: "BUSINESS",
  //   permission: "IGNORE",
  // },
  // {
  //   link: "/core/accounting",
  //   path: "/core/accounting",
  //   iconActive:
  //     require("../../../assets/images/sidebar/business/accounting-active.svg")
  //       .default,
  //   iconInActive:
  //     require("../../../assets/images/sidebar/business/accounting-inactive.svg")
  //       .default,
  //   name: "Accounting",
  //   section: "BUSINESS",
  //   permission: "IGNORE",
  // },
  {
    link: "/core/teller-group",
    path: "/core/teller-group",
    iconActive:
      require("../../../assets/images/sidebar/wdIcons-active/tellerGroup-active.svg")
        .default,
    iconInActive:
      require("../../../assets/images/sidebar/wdIcons-Inactive/tellerGroup-Inactive.svg")
        .default,
    name: "Tellers",
    section: "BUSINESS",
    permission: "READ_TELLER",
  },
  {
    link: "/core/checkers",
    path: "/core/checkers",
    iconActive:
      require("../../../assets/images/sidebar/wdIcons-active/checkers-active.svg")
        .default,
    iconInActive:
      require("../../../assets/images/sidebar/wdIcons-Inactive/checkers-Inactive.svg")
        .default,
    // name: "Checker Inbox",
    name: "Approvals",
    section: "BUSINESS",
    permission: "IGNORE",
  },
  // {
  //   link: "/core/bulk-actions",
  //   path: "/core/bulk-actions",
  //   iconActive:
  //     require("../../../assets/images/sidebar/business/bulk-action-active.svg")
  //       .default,
  //   iconInActive:
  //     require("../../../assets/images/sidebar/business/bulk-action-inactive.svg")
  //       .default,
  //   name: "Bulk actions",
  //   section: "BUSINESS",
  //   permission: "IGNORE",
  // },
];

let accountLink = [
  {
    link: "/core/savings-accounts",
    path: "/core/savings-accounts",
    iconActive:
      require("../../../assets/images/sidebar/wdIcons-active/savingsAcct-active.svg")
        .default,
    iconInActive:
      require("../../../assets/images/sidebar/wdIcons-Inactive/savingsAcct-Inactive.svg")
        .default,
    name: "Deposits",
    section: "ACCOUNT",
    permission: "READ_SAVINGSACCOUNT",
  },
  // {
  //   link: "/core/deposit-accounts",
  //   path: "/core/deposit-accounts",
  //   iconActive:
  //     require("../../../assets/images/sidebar/accounts/deposit-account-active.svg")
  //       .default,
  //   iconInActive:
  //     require("../../../assets/images/sidebar/accounts/deposit-account-inactive.svg")
  //       .default,
  //   name: "Deposit Acccounts",
  //   section: "ACCOUNT",
  // },
  {
    link: "/core/loan-accounts",
    path: "/core/loan-accounts",
    iconActive:
      require("../../../assets/images/sidebar/wdIcons-active/loanAcct-active.svg")
        .default,
    iconInActive:
      require("../../../assets/images/sidebar/wdIcons-Inactive/loanAcct-Inactive.svg")
        .default,
    name: "Loans",
    section: "ACCOUNT",
    permission: "READ_LOAN",
  },
  // {
  //   link: "/core/fixed-deposit-accounts",
  //   path: "/core/fixed-deposit-accounts",
  //   iconActive:
  //     require("../../../assets/images/sidebar/accounts/fd-acc-active.svg")
  //       .default,
  //   iconInActive:
  //     require("../../../assets/images/sidebar/accounts/fd-acc-inactive.svg")
  //       .default,
  //   name: "Fixed deposit Accounts",
  //   section: "ACCOUNT",
  // },
  // {
  //   link: "#",
  //   path: "#",
  //   iconActive:
  //     require("../../../assets/images/sidebar/accounts/standing-instruction-active.svg")
  //       .default,
  //   iconInActive:
  //     require("../../../assets/images/sidebar/accounts/standing-instruction-inactive.svg")
  //       .default,
  //   name: "Standing instructions",
  //   section: "ACCOUNT",
  // },
];

let accountingLink = [
  {
    link: "/core/accounting/account-to-journal-entry",
    path: "/core/accounting/account-to-journal-entry",
    iconActive:
      require("../../../assets/images/sidebar/wdIcons-active/journalEntry-active.svg")
        .default,
    iconInActive:
      require("../../../assets/images/sidebar/wdIcons-Inactive/journalEntry-Inactive.svg")
        .default,
    name: "Account Entries",
    section: "ACCOUNTING",
    permission: "CREATE_JOURNALENTRY",
  },
  {
    link: "/core/add-journal-entries",
    path: "/core/add-journal-entries",
    iconActive:
      require("../../../assets/images/sidebar/wdIcons-active/journalEntry-active.svg")
        .default,
    iconInActive:
      require("../../../assets/images/sidebar/wdIcons-Inactive/journalEntry-Inactive.svg")
        .default,
    name: "Journal Entries",
    section: "ACCOUNTING",
    permission: "CREATE_JOURNALENTRY",
  },
  {
    link: "/core/charts-of-account",
    path: "/core/charts-of-account",
    iconActive:
      require("../../../assets/images/sidebar/wdIcons-active/chartAcct-active.svg")
        .default,
    iconInActive:
      require("../../../assets/images/sidebar/wdIcons-Inactive/chartAcct-Inactive.svg")
        .default,
    name: "Chart of Accounts",
    section: "ACCOUNTING",
    permission: "IGNORE",
  },
  {
    link: "/core/search-journal-entries",
    path: "/core/search-journal-entries",
    iconActive:
      require("../../../assets/images/sidebar/wdIcons-active/searchJournal-active.svg")
        .default,
    iconInActive:
      require("../../../assets/images/sidebar/wdIcons-Inactive/searchJournal-Inactive.svg")
        .default,
    name: "Search Entries",
    section: "ACCOUNTING",
    permission: "READ_JOURNALENTRY",
  },
  {
    link: "/core/accounting/rules",
    path: "/core/accounting/rules",
    iconActive:
      require("../../../assets/images/sidebar/wdIcons-active/searchJournal-active.svg")
        .default,
    iconInActive:
      require("../../../assets/images/sidebar/wdIcons-Inactive/searchJournal-Inactive.svg")
        .default,
    name: "Accounting Rules",
    section: "ACCOUNTING",
    permission: "READ_ACCOUNTINGRULE",
  },
];

// let assetManagementLink = [
//   {
//     link: "/core/amortizations",
//     path: "/core/amortizations",
//     iconActive:
//       require("../../../assets/images/sidebar/assetMang/amortization-active.svg")
//         .default,
//     iconInActive:
//       require("../../../assets/images/sidebar/assetMang/amortization-inactive.svg")
//         .default,
//     name: "Amortization",
//     section: "ASSET MANAGEMENT",
//     permission: "IGNORE",
//   },
// ];

let reportsLink = [
  {
    link: "/core/customer-reports",
    path: "/core/customer-reports",
    iconActive:
      require("../../../assets/images/sidebar/wdIcons-active/customer-active.svg")
        .default,
    iconInActive:
      require("../../../assets/images/sidebar/wdIcons-Inactive/customer-Inactive.svg")
        .default,
    name: "Customer ",
    section: "REPORTS",
    permission: "READ_REPORT",
  },
  {
    link: "/core/financial-reports",
    path: "/core/financial-reports",
    iconActive:
      require("../../../assets/images/sidebar/wdIcons-active/financialReport-active.svg")
        .default,
    iconInActive:
      require("../../../assets/images/sidebar/wdIcons-Inactive/financialReport-Inactive.svg")
        .default,
    name: "Financial ",
    section: "REPORTS",
    permission: "READ_REPORT",
  },
  {
    link: "/core/loan-reports",
    path: "/core/loan-reports",
    iconActive:
      require("../../../assets/images/sidebar/wdIcons-active/loanReport-active.svg")
        .default,
    iconInActive:
      require("../../../assets/images/sidebar/wdIcons-Inactive/loanReport-Inactive.svg")
        .default,
    name: "Loan ",
    section: "REPORTS",
    permission: "READ_REPORT",
  },
  {
    link: "/core/organization-reports",
    path: "/core/organization-reports",
    iconActive:
      require("../../../assets/images/sidebar/wdIcons-active/organizationReport-active.svg")
        .default,
    iconInActive:
      require("../../../assets/images/sidebar/wdIcons-Inactive/organizationReport-Inactive.svg")
        .default,
    name: "Organization ",
    section: "REPORTS",
    permission: "READ_REPORT",
  },
  {
    link: "/core/regulation-reports",
    path: "/core/regulation-reports",
    iconActive:
      require("../../../assets/images/sidebar/wdIcons-active/regulationReport-active.svg")
        .default,
    iconInActive:
      require("../../../assets/images/sidebar/wdIcons-Inactive/regulationReport-Inactive.svg")
        .default,
    name: "Regulatory ",
    section: "REPORTS",
    permission: "READ_REPORT",
  },
  {
    link: "/core/deposits-reports",
    path: "/core/deposits-reports",
    iconActive:
      require("../../../assets/images/sidebar/wdIcons-active/depositReport-active.svg")
        .default,
    iconInActive:
      require("../../../assets/images/sidebar/reports/deposit-inactive.svg")
        .default,
    name: "Deposits ",
    section: "REPORTS",
    permission: "READ_REPORT",
  },
  {
    link: "/core/system-reports",
    path: "/core/system-reports",
    iconActive:
      require("../../../assets/images/sidebar/wdIcons-active/systemReport-active.svg")
        .default,
    iconInActive:
      require("../../../assets/images/sidebar/wdIcons-Inactive/systemReport-Inactive.svg")
        .default,
    name: "System ",
    section: "REPORTS",
    permission: "READ_REPORT",
  },
];

// 'UTILITY LINK'

let utitlityLink = [
  {
    // core/configuration/organization
    link: "/core/configuration/organization",
    path: "/core/configuration/organization",
    iconActive:
      require("../../../assets/images/sidebar/wdIcons-active/systemConfig-active.svg")
        .default,
    iconInActive:
      require("../../../assets/images/sidebar/wdIcons-Inactive/systemConfig-Inactive.svg")
        .default,
    name: "System Configs",
    section: "UTILITIES",
    permission: "READ_CONFIGURATION",
  },
  {
    link: "/core/products",
    path: "/core/products",
    iconActive:
      // require("../../../assets/images/sidebar/reports/dynamic-report-active.svg")
      require("../../../assets/images/sidebar/wdIcons-active/depositReport-active.svg")
        .default,
    iconInActive:
      // require("../../../assets/images/sidebar/reports/dynamic-report-inactive.svg")
      require("../../../assets/images/sidebar/wdIcons-Inactive/depositReport-Inactive.svg")
        .default,
    name: "Products",
    section: "UTILITIES",
    permission: "IGNORE",
  },
  {
    link: "/core/manage-tax-configuration",
    path: "/core/manage-tax-configuration",
    iconActive:
      require("../../../assets/images/sidebar/wdIcons-active/systemConfig-active.svg")
        .default,
    iconInActive:
      require("../../../assets/images/sidebar/wdIcons-Inactive/systemConfig-Inactive.svg")
        .default,
    name: "Tax Configs",
    section: "UTILITIES",
    permission: "IGNORE",
  },
  // {
  //   link: "/core/manage-utilities",
  //   path: "/core/manage-utilities",
  //   iconActive:
  //     require("../../../assets/images/sidebar/utilities/manage-utilities-active.svg")
  //       .default,
  //   iconInActive:
  //     require("../../../assets/images/sidebar/utilities/manage-utitlities-inactive.svg")
  //       .default,
  //   name: "Manage utilities",
  //   section: "UTILITIES",
  // },
  {
    link: "/core/mc-tasks",
    path: "/core/mc-tasks",
    iconActive:
      require("../../../assets/images/sidebar/wdIcons-active/manageUtilities-active.svg")
        .default,
    iconInActive:
      require("../../../assets/images/sidebar/wdIcons-Inactive/manageUtilities-Inactive.svg")
        .default,
    // changed maker checker to permission
    name: "Checker Tasks",
    section: "UTILITIES",
    permission: "READ_PERMISSION",
  },
  {
    link: "/core/bulk-import",
    path: "/core/bulk-import",
    iconActive:
      require("../../../assets/images/sidebar/wdIcons-active/bulkimports-active.svg")
        .default,
    iconInActive:
      require("../../../assets/images/sidebar/wdIcons-Inactive/bulkimport-Inactive.svg")
        .default,
    name: "Bulk Imports",
    hasBeta: true,
    section: "UTILITIES",
    permission: "READ_IMPORT",
  },
  {
    link: "/core/manage-data-tables",
    path: "/core/manage-data-tables",
    iconActive:
      require("../../../assets/images/sidebar/wdIcons-active/datatables-active.svg")
        .default,
    iconInActive:
      require("../../../assets/images/sidebar/wdIcons-Inactive/datatables-Inactive.svg")
        .default,
    name: "Data Tables",
    section: "UTILITIES",
    permission: "READ_DATATABLE",
  },
  {
    link: "/core/currency-management",
    path: "/core/currency-management",
    iconActive:
      require("../../../assets/images/sidebar/wdIcons-active/currencyMgmt-active.svg")
        .default,
    iconInActive:
      require("../../../assets/images/sidebar/wdIcons-Inactive/currencyMgmt-Inactive.svg")
        .default,
    name: "Currency Mgt",
    section: "UTILITIES",
    permission: "READ_CURRENCY",
  },
  {
    link: "/core/charges",
    path: "/core/charges",
    iconActive:
      require("../../../assets/images/sidebar/wdIcons-active/charges-active.svg")
        .default,
    iconInActive:
      require("../../../assets/images/sidebar/wdIcons-Inactive/charges-Inactive.svg")
        .default,
    name: "Charges",
    section: "UTILITIES",
    permission: "READ_CHARGE",
  },
];

let usersLink = [
  {
    link: "/core/user-management",
    path: "/core/user-management",
    iconActive:
      require("../../../assets/images/sidebar/wdIcons-active/userMgmt-active.svg")
        .default,
    iconInActive:
      require("../../../assets/images/sidebar/wdIcons-Inactive/userMgmt-Inactive.svg")
        .default,
    name: "User & API Mgt",
    section: "USERS",
    permission: "READ_USER",
  },
  // {
  //   link: "#",
  //   path: "#",
  //   iconActive: require("../../../assets/images/sidebar/users/api-active.svg")
  //     .default,
  //   iconInActive:
  //     require("../../../assets/images/sidebar/users/api-inactive.svg").default,
  //   name: "API Management",
  //   section: "USERS",
  // },
  {
    link: "/core/roles-management",
    path: "/core/roles-management",
    iconActive:
      require("../../../assets/images/sidebar/wdIcons-active/roleMgmt-active.svg")
        .default,
    iconInActive:
      require("../../../assets/images/sidebar/wdIcons-Inactive/roleMgmt-Inactive.svg")
        .default,
    name: "Role Management",
    section: "USERS",
    permission: "READ_ROLE",
  },
  {
    link: "/core/employee-management",
    path: "/core/employee-management",
    iconActive:
      require("../../../assets/images/sidebar/users/employee-mang-active.svg")
        .default,
    iconInActive:
      require("../../../assets/images/sidebar/users/employee-mang-inactive.svg")
        .default,
    name: "Employee Mgt",
    section: "USERS",
    permission: "READ_STAFF",
  },
];

let connectorsLink = [
  // {
  //   link: "/core/nibss-management",
  //   path: "/core/nibss-management",
  //   iconActive:
  //     require("../../../assets/images/sidebar/connectors/noti-service-active.svg")
  //       .default,
  //   iconInActive:
  //     require("../../../assets/images/sidebar/connectors/noti-service-inactive.svg")
  //       .default,
  //   name: "NIBSS Integration",
  //   section: "CONNECTORS",
  //   permission: "CREATE_INTERTRANSFER",
  // },
  // {
  //   link: "#",
  //   path: "#",
  //   iconActive:
  //     require("../../../assets/images/sidebar/connectors/payment-active.svg")
  //       .default,
  //   iconInActive:
  //     require("../../../assets/images/sidebar/connectors/payment-inactive.svg")
  //       .default,
  //   name: "Payments",
  //   hasBeta: true,
  //   section: "CONNECTORS",
  //   permission: "IGNORE",
  // },
];

let auditLink = [
  {
    link: "/core/audit-trail",
    path: "/core/audit-trail",
    iconActive:
      require("../../../assets/images/sidebar/wdIcons-active/auditTrail-active.svg")
        .default,
    iconInActive:
      require("../../../assets/images/sidebar/wdIcons-Inactive/auditTrail-Inactive.svg")
        .default,
    name: "Audit Trail",
    section: "AUDIT",
    permission: "READ_AUDIT",
  },

  // {
  //   link: "/core/user-audit",
  //   path: "/core/user-audit",
  //   iconActive:
  //     require("../../../assets/images/sidebar/audit/user-audit-active.svg")
  //       .default,
  //   iconInActive:
  //     require("../../../assets/images/sidebar/audit/user-audit-inactive.svg")
  //       .default,
  //   name: "User Audits",
  //   section: "AUDIT",
  //   permission: "IGNORE",
  // },
];

let settlementLink = [
  {
    link: "/core/settlement",
    path: "/core/settlement",
    iconActive:
      require("../../../assets/images/sidebar/wdIcons-active/loanReport-active.svg")
        .default,
    iconInActive:
      require("../../../assets/images/sidebar/wdIcons-Inactive/loanReport-Inactive.svg")
        .default,
    name: "End Of Year",
    section: "SETTLEMENT",
    permission: "CREATE_END_OF_YEAR",
  },
];

const DashboardAsideNaivagation = () => {
  const dispatch = useDispatch();
  const navigationBar = useSelector((state) => state.navigationBar);
  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;
  let { navigationBarActive } = navigationBar || {};
  const { pathname } = useLocation();
  let myPath = pathname.split("/");
  let mainPath = `/${myPath[1]}${myPath[2] ? `/${myPath[2]}` : ""}`;

  const handleNavBar = () => {
    // console.log("status", !navigationBarActive);
    dispatch(setNavigationBarStatus(!navigationBarActive));
  };

  const returnNavigationBar = (data) => {
    return data.map(
      (
        {
          link,
          path,
          iconActive,
          iconInActive,
          hasBeta,
          name,
          section,
          permission,
        },
        i
      ) => {
        return (permissions && CheckPermission(permission, permissions)) ||
          permission === "IGNORE" ? (
          <li
            key={i}
            className="nav-item"
            // style={{ width: "54px", height: "54px" }}
          >
            {hasBeta ? (
              // <span className="nav-link text-center position-relative">
              // <RenderTooltip text={name} id={name}>
              <Link
                className={`nav-link position-relative ${
                  mainPath === path && "active"
                }`}
                to={link}
              >
                <Badge
                  className="position-absolute"
                  style={{
                    fontSize: 6,
                    top: 2,
                    right: 3,
                    padding: "0 2px",
                    background: "none",
                    color: "#17a2b8",
                    border: "1px solid #17a2b8",
                    borderRadius: 16,
                  }}
                  colorScheme="green"
                >
                  {name === "Payments" ? "Coming soon" : "Beta"}
                </Badge>

                {mainPath === path ? (
                  <div className="d-flex w-100">
                    <img
                      src={iconActive}
                      style={{
                        height: "20px",
                        width: "20px",
                        marginRight: "10px",
                      }}
                      alt="icon"
                      className="icon-img"
                    />
                    {navigationBarActive ? (
                      <>
                        <p className="sub-link"> {name}</p>{" "}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <div className="d-flex w-100">
                    <img
                      src={iconInActive}
                      style={{
                        height: "20px",
                        width: "20px",
                        marginRight: "10px",
                      }}
                      alt="icon"
                      className="icon-img"
                    />
                    {navigationBarActive ? (
                      <>
                        <p className="sub-link">{name}</p>{" "}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </Link>
            ) : (
              //  {/* </RenderTooltip> */}
              // </span>
              // <RenderTooltip text={name} id={name}>
              <Link
                className={`nav-link  ${mainPath === path && "active"}`}
                to={link}
              >
                {mainPath === path ? (
                  <div className="d-flex w-100">
                    <img
                      src={iconActive}
                      style={{
                        height: "20px",
                        width: "20px",
                        marginRight: "10px",
                      }}
                      alt="icon"
                      className="icon-img"
                    />
                    {navigationBarActive ? (
                      <>
                        <p className="sub-link">{name}</p>{" "}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <div className="d-flex w-100">
                    <img
                      src={iconInActive}
                      style={{
                        height: "20px",
                        width: "20px",
                        marginRight: "10px",
                      }}
                      alt="icon"
                      className="icon-img"
                    />
                    {navigationBarActive ? (
                      <>
                        <p className="sub-link">{name}</p>{" "}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </Link>
              // </RenderTooltip>
            )}
          </li>
        ) : null;
      }
    );
  };

  const headers = [
    { heading: "OVERVIEW", link: overViewLink },
    { heading: "BUSINESS", link: businessLink },
    { heading: "ACCOUNT", link: accountLink },
    { heading: "ACCOUNTING", link: accountingLink },
    // { heading: "ASSET MANAGEMENT", link: assetManagementLink },
    { heading: "REPORTS", link: reportsLink },
    { heading: "UTILITIES", link: utitlityLink },
    { heading: "USERS", link: usersLink },
    // { heading: "CONNECTORS", link: connectorsLink },
    { heading: "SETTLEMENT", link: settlementLink },
    { heading: "AUDIT", link: auditLink },
  ];

  return (
    <Nav
      // role="aside"
      className={`${
        navigationBarActive ? "openbar" : "sidebar"
      } scroll-side-bar d-flex justify-content-center`}
    >
      {/* <div className="enlarge-sidebar pointer" onClick={() => handleNavBar()}>
        {navigationBarActive ? (
          <>
            <img
              style={{ display: "inline", marginLeft: "10px" }}
              src={CloseSvg}
              alt=""
            />
          </>
        ) : (
          <>
            <img
              style={{ display: "inline", marginLeft: "10px" }}
              src={OpenSvg}
              alt=""
            />{" "}
          </>
        )}
      </div> */}
      <div className="sidebar-sticky">
        <ul className="nav flex-column" style={{ paddingTop: "2rem" }}>
          {!navigationBarActive ? (
            headers.map((item, key) => (
              <div key={key}>
                <p className="side-bar-header"></p>
                {returnNavigationBar(item.link)}
                <div
                  style={{
                    margin: "10px 0px",
                  }}
                  className=""
                ></div>
              </div>
            ))
          ) : (
            <Accordion
              className="border-0"
              defaultIndex={[0]}
              allowToggle
              allowMultiple
            >
              {headers.map((item, key) => (
                <AccordionItem key={key} className="accordion-style">
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton
                        key={key}
                        className="p-0 d-flex align-items-center accordion-btn-style"
                      >
                        <p className="side-bar-header">{item.heading}</p>
                        <svg
                          viewBox="0 0 24 24"
                          focusable="false"
                          className={`chakra-icon chakra-accordion__icon ${
                            isExpanded ? "rotate" : ""
                          } css-j2ph2z`}
                          aria-hidden="true"
                        >
                          <path
                            fill="#fff"
                            d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"
                          ></path>
                        </svg>
                      </AccordionButton>
                      {item.heading === "SETTLEMENT" ? (
                        <AccordionPanel className="p-0">
                          {returnNavigationBar(item.link.filter(link => CheckPerm(link.permission, permissions)))}
                        </AccordionPanel>
                      ) : (
                        <AccordionPanel className="p-0">
                          {returnNavigationBar(item.link)}
                        </AccordionPanel>
                      )}
                    </>
                  )}
                </AccordionItem>
              ))}
            </Accordion>
          )}
        </ul>
      </div>
    </Nav>
  );
};

export default DashboardAsideNaivagation;
