import React from "react";
import { Select, Input, Textarea, FormControl } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import CurrencyFormat from "react-currency-format";
import { textToUpperCase } from "../../../../../../util/Tools";

import {
  FormClass,
  FormElements,
  ActionButton,
  CardDetails,
} from "../../styles";

const AmortizationDetails = ({
  handleNextScreen,
  office,
  setOffice,
  currency,
  setCurrency,
  setDetailsError,
  currencies,
  offices,
  glAcccounts,
  paymentTypes,
  setGlCredits,
  glCredits,
  glDebits,
  setGlDebits,
  setMainCredits,
  refNumber,
  setRefNumber,
  setTransaDate,
  transDate,
}) => {
  const defaultCredit = { glAccountId: "" };
  const addToCredit = () => {
    setGlCredits([...glCredits, defaultCredit]);
  };
  const removeCredit = (position) => {
    let noo = [];
    glCredits.forEach((element, index) => {
      if (position !== index) {
        noo.push(element);
      }
    });
    setGlCredits(noo);
  };

  const handleOnCreditChange = (position, timeStamp) => {
    let hhh = [...glCredits];
    hhh[Number(position)].glAccountId = timeStamp;
    setGlCredits(hhh);
  };
  const handleOnCreditAmountChange = (position, timeStamp) => {
    let hhh = [...glCredits];
    hhh[Number(position)].amount = timeStamp;
    setGlCredits(hhh);
  };

  // For Debits
  const defaultDebit = { glAccountId: "" };
  const addToDebit = () => {
    setGlDebits([...glDebits, defaultDebit]);
  };
  const removeDebit = (position) => {
    let noo = [];
    glDebits.forEach((element, index) => {
      if (position !== index) {
        noo.push(element);
      }
    });
    setGlDebits(noo);
  };

  const handleOnDebitChange = (position, timeStamp) => {
    let hhh = [...glDebits];
    hhh[Number(position)].glAccountId = timeStamp;
    setGlDebits(hhh);
  };
  const handleOnDebitAmountChange = (position, timeStamp) => {
    let hhh = [...glDebits];
    hhh[Number(position)].amount = timeStamp;
    setGlDebits(hhh);
  };
  return (
    <>
      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-muted d-flex flex-align-center">
                  Office*
                </label>
                <Select
                  size="md"
                  placeholder="Select Office"
                  name="office"
                  value={office}
                  onChange={(e) => {
                    setOffice(e.target.value);
                  }}
                >
                  {!!offices.length
                    ? offices.map(({ id, name }) => {
                        return (
                          <option value={id} key={id}>
                            {name}
                          </option>
                        );
                      })
                    : null}
                </Select>
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-muted d-flex flex-align-center">
                  Currency*
                </label>
                <Select
                  size="md"
                  placeholder="Select Currency"
                  name="currency"
                  value={currency}
                  onChange={(e) => {
                    setCurrency(e.target.value);
                  }}
                >
                  {!!currencies.length
                    ? currencies.map(({ code, displayLabel }) => {
                        return (
                          <option value={code} key={code}>
                            {displayLabel}
                          </option>
                        );
                      })
                    : null}
                </Select>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>

      <div className="col-md-12 col-sm-12">
        <div className="row ml-5">
          <div className="col-sm-12 col-md-12 px-0">
            <CardDetails>
              <div className="dflex">
                <div className="pt-3">
                  <p className="sub-title">
                    <span className="text-info mr-1 linkSavings ">Credit</span>
                  </p>
                </div>
                <div className="pt-4">
                  <hr />
                </div>
                {/* <div className="mb-3">
                  <div className="button-right ml-3">
                    <ActionButton
                      color="#2C1DFF"
                      className="float-right"
                      style={{ margin: "0px 0px" }}
                      onClick={() => addToCredit()}
                    >
                      <img
                        src={
                          require("../../../../../../assets/images/icons/add.svg")
                            .default
                        }
                        alt="add icon"
                      />
                      Add Credit
                    </ActionButton>
                  </div>
                </div> */}
              </div>
              <FormElements>
                {glCredits.map(({ glAccountId }, index) => {
                  return (
                    <div className="row">
                      <div className="p-relative col-md-6">
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-muted d-flex flex-align-center">
                            GL Account*
                          </label>
                          <Select
                            size="md"
                            placeholder="Select Gl Account"
                            name="credit"
                            value={glCredits[index].glAccountId}
                            onChange={(e) => {
                              handleOnCreditChange(index, e.target.value);
                            }}
                          >
                            {!!glAcccounts.length
                              ? glAcccounts.map(({ id, name }) => {
                                  return (
                                    <option value={id} key={id}>
                                      {textToUpperCase(name)}
                                    </option>
                                  );
                                })
                              : null}
                          </Select>
                        </div>
                        {glCredits.length > 1 ? (
                          <div
                            className="remove-icon2"
                            onClick={() => removeCredit(index)}
                          >
                            <img
                              src={
                                require("../../../../../../assets/images/icons/cancel.svg")
                                  .default
                              }
                              className="detail-cancel-icon"
                              alt="cancel icon"
                            />
                          </div>
                        ) : null}
                      </div>
                      <div className="col-md-6">
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-muted d-flex flex-align-center">
                            Amount*
                          </label>
                          <CurrencyFormat
                            value={glCredits[index].amount}
                            thousandSeparator={true}
                            className="amount-input "
                            style={{
                              width: "60%",
                            }}
                            // prefix={"$"}
                            onValueChange={(values) => {
                              const { value } = values;
                              // formattedValue = $2,223
                              // value ie, 2223
                              handleOnCreditAmountChange(index, value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </FormElements>
            </CardDetails>
          </div>
        </div>{" "}
        <div className="row ml-5">
          <div className="col-sm-12 col-md-12 px-0">
            <CardDetails>
              <div className="dflex">
                <div className="pt-3">
                  <p className="sub-title">
                    <span className="text-info mr-1 linkSavings ">Debit</span>
                  </p>
                </div>
                <div className="pt-4">
                  <hr />
                </div>
                {/* <div className="mb-3">
                  <div className="button-right ml-3">
                    <ActionButton
                      color="#2C1DFF"
                      className="float-right"
                      style={{ margin: "0px 0px" }}
                      onClick={() => addToDebit()}
                    >
                      <img
                        src={
                          require("../../../../../../assets/images/icons/add.svg")
                            .default
                        }
                        alt="add icon"
                      />
                      Add Debit
                    </ActionButton>
                  </div>
                </div> */}
              </div>
              <FormElements>
                {glDebits.map(({ glAccountId }, index) => {
                  return (
                    <div className="row">
                      <div className="p-relative col-md-6">
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-muted d-flex flex-align-center">
                            Gl Account*
                          </label>
                          <Select
                            size="md"
                            placeholder="Select Gl Account"
                            name="debit"
                            value={glDebits[index].glAccountId}
                            onChange={(e) => {
                              handleOnDebitChange(index, e.target.value);
                            }}
                          >
                            {!!glAcccounts.length
                              ? glAcccounts.map(({ id, name }) => {
                                  return (
                                    <option value={id} key={id}>
                                      {textToUpperCase(name)}
                                    </option>
                                  );
                                })
                              : null}
                          </Select>
                        </div>
                        {glDebits.length > 1 ? (
                          <div
                            className="remove-icon2"
                            onClick={() => removeDebit(index)}
                          >
                            <img
                              src={
                                require("../../../../../../assets/images/icons/cancel.svg")
                                  .default
                              }
                              className="detail-cancel-icon"
                              alt="cancel icon"
                            />
                          </div>
                        ) : null}
                      </div>
                      <div className="col-md-6">
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-muted d-flex flex-align-center">
                            Amount*
                          </label>
                          <CurrencyFormat
                            value={glDebits[index].amount}
                            thousandSeparator={true}
                            className="amount-input "
                            style={{
                              width: "60%",
                            }}
                            // prefix={"$"}
                            onValueChange={(values) => {
                              const { value } = values;
                              // formattedValue = $2,223
                              // value ie, 2223
                              handleOnDebitAmountChange(index, value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </FormElements>
            </CardDetails>
          </div>
        </div>
      </div>

      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-muted d-flex flex-align-center">
                  Reference Number
                </label>
                <Input
                  style={{ width: "60%" }}
                  size="md"
                  name="refNumber"
                  value={refNumber}
                  onChange={(e) => {
                    setRefNumber(e.target.value);
                  }}
                />
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-muted d-flex flex-align-center">
                  Transaction date*
                </label>
                <FormControl style={{ width: "70%" }}>
                  <DatePicker
                    id="fromDate"
                    selected={transDate}
                    onChange={(date) => setTransaDate(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="dd/mmm/yyyy"
                  />
                </FormControl>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>

      {/* <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-muted d-flex flex-align-center">
                  Number of days*
                </label>
                <Input
                  style={{ width: "60%" }}
                  size="md"
                  name="noOfDays"
                  value={noOfDays}
                  onChange={(e) => {
                    setNoOfDays(e.target.value);
                  }}
                />
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label
                  className="text-muted d-flex flex-align-center"
                  style={{ marginTop: "-30px" }}
                >
                  Comments
                </label>
                <Textarea
                  style={{ width: "70%" }}
                  size="md"
                  name="comment"
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                />
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div> */}

      <div>
        <div className="float-right pt-5">
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={() => {
              office &&
              glDebits[0].amount &&
              currency &&
              glDebits[0].glAccountId &&
              glCredits[0].glAccountId &&
              glCredits[0].amount
                ? setDetailsError(false)
                : setDetailsError(true);
              handleNextScreen();
            }}
          >
            <img
              src={
                require("../../../../../../assets/images/icons/arrow-right.svg")
                  .default
              }
              alt="submit icon"
            />
            Next
          </ActionButton>
        </div>
      </div>
    </>
  );
};

export default AmortizationDetails;
