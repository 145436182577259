import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  ContentWrapper,
  ActionButton,
  EditButton,
  FormElements,
} from "./styles";
import { Select } from "@chakra-ui/react";
import { Table } from "react-bootstrap";
// import { Link } from "react-router-dom";
import {
  getImportTemplate,
  getBulkImportDocument,
  uploadBulkDocument,
} from "../../../../actions/utilityActions";
import Toast from "../../../../util/Toast";
import axiosData from "../../../../services/axiosData";
import config from "../../../../config";
import AuthModule from "../../../../modules/Auth.module";

import ArrowRight from "../../../../assets/images/icons/point-right.svg";
import DownLoadSvg from "../../../../assets/images/icons/download.svg";
import cancelSvg from "../../../../assets/images/icons/cancel.svg";

import { formatDate } from "../../../../util/FormatDate";

const BulkImportView = () => {
  const dispatch = useDispatch();
  const ancRef = useRef();

  const importTemplates = useSelector((state) => state.importTemplate);

  let { importTemplate = {}, bulkDocuments = [] } = importTemplates || {};

  let { officeOptions = [], staffOptions = [] } = importTemplate || {};

  const [bulkType, setBulkType] = useState("");

  const [officeId, setOffice] = useState("");
  const [staffId, setStaffId] = useState("");
  const [legalForm, setLegalForm] = useState("");

  const [currentView, setCurrentView] = useState(1);

  // let documentType = "";

  const [documentType, setDocumentType] = useState("");

  useEffect(() => {
    //  Get Documents
    if (bulkType) {
      dispatch(
        getImportTemplate(
          "centers/template?officeId=1&staffInSelectedOfficeOnly=true"
        )
      );

      if (bulkType === "clients") {
        setDocumentType("client");
        // eslint-disable-next-line react-hooks/exhaustive-deps
        // documentType = "client";
        dispatch(getBulkImportDocument(`imports?entityType=client`));
      } else {
        setDocumentType(bulkType);
        dispatch(getBulkImportDocument(`imports?entityType=${bulkType}`));
      }

      // dispatch(getBulkImportDocument(`imports?entityType=${documentType}`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulkType]);

  const handleBulkImportRefresh = () => {
    dispatch(getBulkImportDocument(`imports?entityType=${documentType}`));
  };

  const [downloadType, setDownloadType] = useState("");

  useEffect(() => {
    if (bulkType === "clients") {
      setDownloadType("clients");
    } else if (bulkType === "savingsaccount") {
      setDownloadType("savingsaccounts");
    } else if (bulkType === "fixeddepositaccounts") {
      setDownloadType("fixeddepositaccounts");
    } else if (bulkType === "loans") {
      setDownloadType("loans");
    } else if (bulkType === "savingstransactions") {
      setDownloadType("savingsaccounts/transactions");
    } else if (bulkType === "loantransactions") {
      setDownloadType("loans/repayments");
    } else if (bulkType === "gljournalentries") {
      setDownloadType("journalentries");
    } else if (bulkType === "accgljournalentries") {
      setDownloadType("journalentries/accounts");
    } else if (bulkType === "block-savings-accounts") {
      setDownloadType("savingsaccounts/block-savings-accounts");
    } else if (bulkType === "un-block-savings-accounts") {
      setDownloadType("savingsaccounts/un-block-savings-accounts");
    }
  }, [bulkType]);

  const [file, setFile] = useState(null);

  const handleBulkUpload = () => {
    if (file === null || file === "" || !file) {
      dispatch(Toast({ text: "Please add a file to upload", icon: "info" }));
    } else if (bulkType === "clients" && !legalForm) {
      dispatch(Toast({ text: "Please selecct Legal Form", icon: "info" }));
    } else {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("locale", "en");
      formData.append("dateFormat", "dd MMMM yyyy");

      let urlLink = "";
      if (downloadType === "clients") {
        urlLink = `${downloadType}/uploadtemplate?legalFormType=${legalForm}`;
      } else {
        urlLink = `${downloadType}/uploadtemplate`;
      }

      let fileData = {
        name: file.name,
        typeUrl: `imports?entityType=${documentType}`,
      };

      // upload file
      dispatch(uploadBulkDocument(urlLink, formData, fileData));
    }
  };

  const handleChange = () => {
    setOffice("");
    setStaffId("");
    setLegalForm("");
    setFile(null);
  };

  const downloadBulkDocument = async () => {
    const url =`${
      config.apiGateway.BASE_API_URL
    }/${downloadType}/downloadtemplate?tenantIdentifier=${
      config.apiGateway.IS_PRODUCTION === `true`
        ? AuthModule.getTenant()
        : config.apiGateway.WOODCORE_TENANT
    }&locale=en&dateFormat=dd%20MMMM%20yyyy&&officeId=${officeId}${
      staffId ? `&staffId=${staffId}` : ""
    }${
      legalForm ? `&legalFormType=${legalForm}` : ""
    }`;

    try {
      const { data, error } = await axiosData({
        method: "GET",
        signal: false,
        url: url,
        isBearerToken: true,
        responseType: 'blob'
      });
      if (data) {
        const dataUrl = URL.createObjectURL(data);
        ancRef.current.href = dataUrl;
        ancRef.current.download = 'download';
        setTimeout(() => {
          URL.revokeObjectURL(dataUrl);
        }, 150);
        ancRef.current.click();
      } else {
      }
    }catch (ex) {

    } finally {

    } 
  }

  const downloadBulkHistoryDocument = async (importId) => {
    const url = `${
      config.apiGateway.BASE_API_URL
    }/imports/downloadOutputTemplate?importDocumentId=${importId}&tenantIdentifier=${
      config.apiGateway.IS_PRODUCTION === `true`
        ? AuthModule.getTenant()
        : config.apiGateway.WOODCORE_TENANT
    }`;
    try {
      const { data, error } = await axiosData({
        method: "GET",
        signal: false,
        url: url,
        isBearerToken: true,
        responseType: "blob",
      });
      if (data) {
        const dataUrl = URL.createObjectURL(data);
        ancRef.current.href = dataUrl;
        ancRef.current.download = "download";
        setTimeout(() => {
          URL.revokeObjectURL(dataUrl);
        }, 150);
        ancRef.current.click();
      } else {
      }
    } catch (ex) {
    } finally {
    }
  };

  return (
    <>
    <a style={{display: 'none'}} ref={ancRef}></a>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Bulk import</h3>
            <p className="sub-title">
              Bulk data import using excel spreadsheet for clients, offices etc.
            </p>
            <p className="font-weight sub-title mt-2">
              {/* <span className="text-info">
                <Link to="/core/configuration">Configuration</Link> /{" "}
                <Link to="/core/configuration/organization">Utilities</Link>
              </span>{" "} */}
              {/* Bulk import */}
            </p>
          </div>
        </div>
        <ContentWrapper>
          {currentView === 1 ? (
            <>
              <div className="row h-100">
                {bulkType ? (
                  <>
                    <div className="spaceBetweenFlexEnd w-100">
                      <div
                        onClick={() => setCurrentView(2)}
                        className="api-keys-btn"
                      >
                        <p>Documents</p>
                        <img src={ArrowRight} alt="" className="icon-img mt-1"/>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}

                <div className="container pb-5" style={{ marginTop: "20px" }}>
                  <div style={{ marginBottom: 50 }} className="row">
                    <div className="col-md-12 d-flex justify-content-between align-items-center form-inner-container">
                      <FormElements>
                        <div className="form-group d-flex my-3 w-100 justify-content-around align-items-center">
                          <div style={{ width: "20%" }}>
                            <label className="text-label">
                              Select Bulk Import Type
                            </label>{" "}
                          </div>
                          <div style={{ width: "60%" }}>
                            <Select
                              placeholder="Select Import Type"
                              size="md"
                              name="bulkType"
                              value={bulkType}
                              onChange={(e) => {
                                setBulkType(e.target.value);
                                handleChange();
                              }}
                            >
                              <option value="clients">Clients</option>
                              <option value="savingsaccount">
                                Savings Accounts
                              </option>
                              <option value="fixeddepositaccounts">
                                Fixed Deposit Accounts
                              </option>
                              <option value="loans">Loan Accounts</option>
                              <option value="savingstransactions">
                                Savings Transactions
                              </option>
                              <option value="loantransactions">
                                Loan Repayment
                              </option>
                              <option value="gljournalentries">
                                Journal Entries
                              </option>
                              <option value="accgljournalentries">
                                Account Journal Entries
                              </option>
                              <option value="block-savings-accounts">
                                Block Savings Account
                              </option>
                              <option value="un-block-savings-accounts">
                                Un-Block Savings Account
                              </option>
                            </Select>
                          </div>
                        </div>
                      </FormElements>
                    </div>

                    {bulkType ? (
                      <>
                        <div className="form-inner-container row p-0 w-100">
                          <div className="col-md-6 d-flex justify-content-between align-items-center">
                            <FormElements>
                              <div className="form-group d-flex my-3 w-100 justify-content-around align-items-center">
                                <label className="text-label">Office</label>{" "}
                                <Select
                                  placeholder="Select Office"
                                  size="md"
                                  name="office"
                                  value={officeId}
                                  onChange={(e) => setOffice(e.target.value)}
                                >
                                  {officeOptions && officeOptions.length
                                    ? officeOptions.map(({ name, id }) => {
                                        return (
                                          <option value={id} key={id}>
                                            {name}
                                          </option>
                                        );
                                      })
                                    : ""}
                                </Select>
                              </div>
                            </FormElements>
                          </div>

                          {bulkType === "clients" ? (
                            <>
                              <div className="col-md-6 d-flex justify-content-between align-items-center">
                                <FormElements>
                                  <div className="form-group d-flex my-3 w-100 justify-content-around align-items-center">
                                    <label className="text-label">Staff</label>{" "}
                                    <Select
                                      placeholder="Select Staff"
                                      size="md"
                                      name="staffId"
                                      value={staffId}
                                      onChange={(e) =>
                                        setStaffId(e.target.value)
                                      }
                                    >
                                      {staffOptions && staffOptions.length
                                        ? staffOptions.map(
                                            ({ displayName, id }) => {
                                              return (
                                                <option value={id} key={id}>
                                                  {displayName}
                                                </option>
                                              );
                                            }
                                          )
                                        : ""}
                                    </Select>
                                  </div>
                                </FormElements>
                              </div>
                              <div className="col-md-6 d-flex justify-content-between align-items-center">
                                <FormElements>
                                  <div className="form-group d-flex my-3 w-100 justify-content-around align-items-center">
                                    <label className="text-label">
                                      Legal Form
                                    </label>{" "}
                                    <Select
                                      placeholder="Select Legal Form"
                                      size="md"
                                      name="legalForm"
                                      value={legalForm}
                                      onChange={(e) =>
                                        setLegalForm(e.target.value)
                                      }
                                    >
                                      <option value="CLIENTS_PERSON">
                                        Person
                                      </option>
                                      <option value="CLIENTS_ENTITY">
                                        Entity
                                      </option>
                                    </Select>
                                  </div>
                                </FormElements>
                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          {bulkType === "savingsaccount" ||
                          bulkType === "loans" ||
                          bulkType === "fixeddepositaccounts" ? (
                            <>
                              {" "}
                              <div className="col-md-6 d-flex justify-content-between align-items-center">
                                <FormElements>
                                  <div className="form-group d-flex my-3 w-100 justify-content-around align-items-center">
                                    <label className="text-label">Staff</label>{" "}
                                    <Select
                                      placeholder="Select Staff"
                                      size="md"
                                      name="staffId"
                                      value={staffId}
                                      onChange={(e) =>
                                        setStaffId(e.target.value)
                                      }
                                    >
                                      {staffOptions && staffOptions.length
                                        ? staffOptions.map(
                                            ({ displayName, id }) => {
                                              return (
                                                <option value={id} key={id}>
                                                  {displayName}
                                                </option>
                                              );
                                            }
                                          )
                                        : ""}
                                    </Select>
                                  </div>
                                </FormElements>
                              </div>{" "}
                            </>
                          ) : (
                            ""
                          )}

                          {/* TODO This is the download section */}
                          <div className="col-md-12 d-flex justify-content-between align-items-center">
                            <div className="spaceBetweenFlexEnd w-100 mb-3" onClick={() => downloadBulkDocument()}>
                             {/* <a> */}
                                {/* Download Image */}
                                <div className="btn-small d-flex" >Download{" "}
                                  <img className="ml-3 icon-img" src={DownLoadSvg} alt="" />
                                </div>
                                {/* </a> */}
                            </div>
                          </div>
                        </div>{" "}

                        <div className="col-md-12 form-inner-container">
                          <FormElements>
                            <div className="row mx-0 my-3">
                              <div className="col-md-10 m-0 p-0">
                                <div className="form-group m-0 d-flex justify-content-between align-items-center">
                                  <div style={{ width: "20%" }} className="pl-3">
                                    <label className="text-label">
                                      Upload File
                                    </label>
                                  </div>
                                  <div className="row p-0 m-0">
                                    <div className="col-md-8 p-0 m-0">
                                      <input
                                        type="file"
                                        name="file"
                                        accept=".xls,.xlsx"
                                        className="bulk-import-file form-control mb-2 text-label"
                                        style={{
                                          fontWeight: "700",
                                          letterSpacing: "0.0em",
                                          width: "100%",
                                          height: "43px",
                                        }}
                                        onChange={(e) =>
                                          setFile(e.target.files[0])
                                        }
                                      />
                                    </div>
                                    <div className="col-md-4 p-0 m-0">
                                      <ActionButton onClick={handleBulkUpload}>
                                        Upload
                                      </ActionButton>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </FormElements>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>{" "}
            </>
          ) : (
            ""
          )}

          {currentView === 2 ? (
            <>
              <div className="row pt-5 px-4">
                <div className="col-md-6">
                  <h3>Documents</h3>
                </div>
                <div className="col-md-6">
                  <div className="spaceBetweenFlexEnd">
                    <div
                      className="btn-small mr-3"
                      onClick={() => handleBulkImportRefresh()}
                    >
                      Refresh{" "}
                    </div>
                    <div
                      className="pointer btn-small"
                      onClick={() => setCurrentView(1)}
                    >
                      <img className="icon-img" src={cancelSvg} alt="" />{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row w-100 py-4 px-5">
                <Table className="child-table">
                  <thead className="">
                    <tr style={{ backgroundColor: "#FAFAFA" }}>
                      <th>Name</th>
                      <th>Import Time</th>
                      <th>End Time</th>
                      <th>Completed</th>
                      <th>Total Records</th>
                      <th>Success Count</th>
                      <th>Failure Count</th>
                      <th>Download</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bulkDocuments.map(
                      (
                        {
                          completed,
                          createdBy,
                          documentId,
                          endTime,
                          failureCount,
                          importId,
                          importTime,
                          name,
                          successCount,
                          totalRecords,
                          id,
                        },
                        i
                      ) => (
                        <tr key={i}>
                          <td>{name}</td>
                          <td>{formatDate(importTime)}</td>
                          <td>{formatDate(endTime)}</td>
                          <td>{`${completed}`}</td>
                          <td>{totalRecords}</td>
                          <td>{successCount}</td>
                          <td>{failureCount}</td>
                          <td>
                            {/* TODO 2nd download button */}
                            {/* <a
                              target="_blank"
                              rel="noreferrer"
                              href={`${
                                config.apiGateway.BASE_API_URL
                              }/imports/downloadOutputTemplate?importDocumentId=${importId}&tenantIdentifier=${
                                config.apiGateway.IS_PRODUCTION === `true`
                                  ? AuthModule.getTenant()
                                  : config.apiGateway.WOODCORE_TENANT
                              }`}
                            > */}
                              <EditButton onClick={() => downloadBulkHistoryDocument(importId)}> Download</EditButton>
                            {/* </a> */}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
              </div>
            </>
          ) : (
            ""
          )}
        </ContentWrapper>
      </div>
    </>
  );
};

export default BulkImportView;
