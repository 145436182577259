import React from "react";
import { Table } from "antd";
import Loading from "./Loading";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: 150,
    ellipsis: true,
  },
  { title: "Short name", dataIndex: "shortname", key: "shortname" },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    width: 150,
    ellipsis: true,
  },
  { title: "Currency", dataIndex: "currency", key: "currency" },
  {
    title: "Min Deposit Amount",
    dataIndex: "minDepositAmount",
    key: "minDepositAmount",
  },
  {
    title: "Days in a Year",
    dataIndex: "interestCalculationDaysInYearType",
    key: "interestCalculationDaysInYearType",
  },
  { title: "With Hold Tax", dataIndex: "withHoldTax", key: "withHoldTax" },
  { title: "", dataIndex: "edit", key: "edit" },
];

const TableLoader = (props) => {
  const someChartsData = [
    {
      key: 1,
      name: <Loading />,
      shortname: <Loading />,
      description: <Loading />,
      currency: <Loading />,
      interestCalculationDaysInYearType: <Loading />,
      withHoldTax: <Loading />,
      edit: <Loading />,
    },
    {
      key: 2,
      name: <Loading />,
      shortname: <Loading />,
      description: <Loading />,
      currency: <Loading />,
      interestCalculationDaysInYearType: <Loading />,
      withHoldTax: <Loading />,
      edit: <Loading />,
    },
    {
      key: 3,
      name: <Loading />,
      shortname: <Loading />,
      description: <Loading />,
      currency: <Loading />,
      interestCalculationDaysInYearType: <Loading />,
      withHoldTax: <Loading />,
      edit: <Loading />,
    },
    {
      key: 4,
      name: <Loading />,
      shortname: <Loading />,
      description: <Loading />,
      currency: <Loading />,
      interestCalculationDaysInYearType: <Loading />,
      withHoldTax: <Loading />,
      edit: <Loading />,
    },
    {
      key: 5,
      name: <Loading />,
      shortname: <Loading />,
      description: <Loading />,
      currency: <Loading />,
      interestCalculationDaysInYearType: <Loading />,
      withHoldTax: <Loading />,
      edit: <Loading />,
    },

  ];
  return (
    <>
      <Table
        columns={columns}
        dataSource={someChartsData}
        pagination={{ defaultPageSize: 8 }}
        className="antdTable"
        defaultPageSize={8}
      />
    </>
  );
};

export default TableLoader;
