export const SET_ALL_REPORTS = "SET_ALL_REPORTS";
export const GENERATED_REPORT = "GENERATED_REPORT";
export const GET_ALL_REPORTS_PARAMETERS = "GET_ALL_REPORTS_PARAMETERS";
export const GET_ALL_REPORTS_FULL_PARAMETERS = "GET_ALL_REPORTS_FULL_PARAMETERS";
export const GET_ALL_OFFICES = "GET_ALL_OFFICES";
export const GET_ALL_STAFF_SELECTS = "GET_ALL_STAFF_SELECTS";
export const ALL_STAFF_SELECT_RESET = "ALL_STAFF_SELECT_RESET";

export const GET_ALL_STAFF_SELECT_BRANCHS = "GET_ALL_STAFF_SELECT_BRANCHS";

export const GET_ACCOUNT_OFFICER_SELECTS = "GET_ACCOUNT_OFFICER_SELECTS";

export const GET_ALL_CLIENT_SELECTS = "GET_ALL_CLIENT_SELECTS";

export const GET_ALL_STAFFS = "GET_ALL_STAFFS";
export const GET_ALL_CURRENCIES = "GET_ALL_CURRENCIES";
export const GET_LOAN_PRODUCT = "GET_LOAN_PRODUCT";
export const GET_SAVINGS_PRODUCT = "GET_SAVINGS_PRODUCT";
export const GET_LOAN_PURPOSE = "GET_LOAN_PURPOSE";
export const GET_LOAN_FUND = "GET_LOAN_FUND";
export const GET_PAR_SELECT = "GET_PAR_SELECT";
export const GET_REPORT_PAYMENT_TYPES = "GET_REPORT_PAYMENT_TYPES";
export const GET_REPORT_STATUS = "GET_REPORT_STATUS";
export const GET_REPORT_ACCOUNT = "GET_REPORT_ACCOUNT";
export const GET_REPORT_LOAN_OBLIGATIONS = "GET_REPORT_LOAN_OBLIGATIONS";
export const GENERATING_REPORTS = "GENERATING_REPORTS";
export const SET_GENERATING_REPORTS = "SET_GENERATING_REPORTS";
