import API_AUTH from '../services/Api/auth.api.services';
import AuthModule from '../modules/Auth.module';
// import cookie from "react-cookies";

import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_DATA_REQUEST,
  USER_DATA_SUCCESS,
  USER_DATA_FAIL,
} from '../constants/authConstants';

import {
  SET_ENTERED_USER_DATA,
  SET_VALIDITY_OF_OTP,
  SET_RES_USER_DATA,
  SET_RES_USER_DATA_PERMISSION,
  SET_IS_VALIDUSERSECURITY,
  SHOW_SECURITY_MODAL,
} from '../constants/preAuthConstats';
import { SET_LOADING_STATE } from '../constants/utilityConstants';

import Toast from '../util/Toast';
import config from '../config';
import { converStringToBoolean } from '../util/Tools';
import Auth from '../modules/Auth.module';

const loginStart = () => {
  return {
    type: USER_LOGIN_REQUEST,
  };
};

const loginSuccess = () => {
  return {
    type: USER_LOGIN_SUCCESS,
  };
};

const loginFail = (payload) => {
  return {
    type: USER_LOGIN_FAIL,
    payload,
  };
};

const logout = () => {
  return {
    type: USER_LOGOUT,
  };
};
//
const loginUser = (history) => {
  return async (dispatch) => {
    dispatch(loginStart());
    const access_token = AuthModule.getToken();
    await API_AUTH.finaliseLogin(access_token)
      .then((response) => {
        dispatch({ type: SET_RES_USER_DATA, payload: response });
        dispatch({ type: SET_VALIDITY_OF_OTP, payload: false });
        dispatch({ type: SHOW_SECURITY_MODAL, payload: false });
        //teller ---
        let { isCashier } = response;
        if (isCashier && response.cashierData) {
          let { id: cashierId, tellerId: tellerGroupId } =
            response?.cashierData;
          AuthModule.tellerDetails(cashierId, tellerGroupId);
        }
        if (isCashier) {
          AuthModule.setInterfaceManager(
            isCashier
              ? 'ds67&fdsfsd8986*&dg#@J5fsdf'
              : 'df3!!4sdfdsf2355@dfg156!/sdfsdfsdf'
          );
        } else {
          AuthModule.setInterfaceManager('df3!!4sdfdsf2355@dfg156!/sdfsdfsdf');
        }

        // auth user
        localStorage.setItem('woodyUser', response.userId);
        dispatch(loginSuccess());
        history.push('/core');
        // console.log(response);
        localStorage.setItem(
          'permissions',
          JSON.stringify(response.permissions)
        );
        dispatch({ type: SET_ENTERED_USER_DATA, payload: {} });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.error,
              icon: 'error',
            })
          );
          dispatch(loginFail(ex.response.data.error));
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: 'error' }));
          dispatch(loginFail('Network Error'));
        } else {
          dispatch(Toast({ text: String(ex), icon: 'error' }));
          dispatch(loginFail(ex));
        }
      });
    // } else {
    // }
  };
};

const userSecurity = (userData) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_AUTH.userSecurity(userData)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        if (!!response.base64EncodedAuthenticationKey) {
          logoutUser();
          dispatch({ type: SET_IS_VALIDUSERSECURITY, payload: true });
          dispatch({ type: SHOW_SECURITY_MODAL, payload: false });
          AuthModule.authenticateUser(response.base64EncodedAuthenticationKey);
          localStorage.setItem('woodyUser', response.userId);
          dispatch({ type: SET_RES_USER_DATA, payload: response });
          // cookie.save("permissions", response);
          localStorage.setItem(
            'permissions',
            JSON.stringify(response.permissions)
          );
          dispatch(getUserData());
        }
        // Save user token to localStorage
      })
      .catch((ex) => {
        logoutUser();
        dispatch({ type: SET_LOADING_STATE, payload: false });
        if (ex.response) {
          dispatch(
            Toast({ text: ex.response.data.defaultUserMessage, icon: 'error' })
          );
          dispatch(loginFail(ex.response.data.defaultUserMessage));
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: 'error' }));
          dispatch(loginFail('Network Error'));
        } else {
          dispatch(Toast({ text: String(ex), icon: 'error' }));
          dispatch(loginFail(ex));
        }
      });
  };
};

const getUserData = () => {
  return async (dispatch) => {
    dispatch({ type: USER_DATA_REQUEST });
    const userId = localStorage.getItem('woodyUser');
    await API_AUTH.getUserData(userId)
      .then((response) => {
        dispatch({ type: USER_DATA_SUCCESS, payload: response });
      })
      .catch((ex) => {
        dispatch(
          Toast({
            text: `${ex} Please check connection and refresh`,
            icon: "error",
          })
        );
        dispatch({ type: USER_DATA_FAIL, payload: ex });
      });
  };
};

const getNewAccessTokenWithRefreshToken = () => {
  const refresh_token = AuthModule.getAuthDetails()
    ? AuthModule.getAuthDetails().refresh_token
    : '';
  return async (dispatch) => {
    const data = {
      client_id: config.apiGateway.CLIENT_ID,
      client_secret: config.apiGateway.CLIENT_SECRET,
      grant_type: config.apiGateway.GRANT_TYPE,
      refresh_token,
    };
    API_AUTH.getNewAccessToken(data)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        AuthModule.authDetails(JSON.stringify(response));
        AuthModule.authenticateUser(response.access_token);
        // let { message, data } = response || {};
        // if (message === "succesfull" && data === "Valid OTP") {
        // } else {
        // dispatch(Toast({ text: String(message), icon: "info" }));
        // }
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.message,
              icon: 'error',
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: 'error' }));
        } else {
          dispatch(Toast({ text: String(ex), icon: 'error' }));
        }
        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

// const checkTokenExpirationMiddleware = store => next => action => {
//   const exp = AuthModule.getAuthDetails() ? AuthModule.getAuthDetails().expires_in :0
//   if (exp < Date.now() / 1000) {
//     getNewAccessTokenWithRefreshToken()
//     localStorage.clear();
//   }
//   next(action);
// };

const logoutUser = () => {
  return (dispatch) => {
    AuthModule.deAuthenticateUser();
    dispatch(logout());
    // cookie.remove("permissions");
    localStorage.removeItem('permissions');
    localStorage.removeItem("tenant");
  };
};

const logoutUser2 = () => {
  AuthModule.deAuthenticateUser();
  // cookie.remove("permissions");
  localStorage.clear();
  window.location.href = '/';
};
const deleteToken = () => {
  AuthModule.deleteToken();
};

const setUserData = (data, dispatch) => {
  dispatch({ type: SET_RES_USER_DATA, payload: data });
};
const setUserDataPermission = (data, dispatch) => {
  dispatch({ type: SET_RES_USER_DATA_PERMISSION, payload: data });
};
const setShowSecurityModal = (data, dispatch) => {
  dispatch({ type: SHOW_SECURITY_MODAL, payload: true });
};

export {
  loginUser,
  deleteToken,
  getUserData,
  logoutUser,
  logoutUser2,
  setUserData,
  setUserDataPermission,
  userSecurity,
  setShowSecurityModal,
  getNewAccessTokenWithRefreshToken,
};
