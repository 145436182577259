import React, { useState, useEffect } from "react";
import { Checkbox, Input, Select } from "@chakra-ui/react";
import {
  NewActionButton,
  FormElements,
  FormClass,
  RoleContainer,
  IconContainer,
} from "../styles";
import {
  RoleButton,
  RoleStyleButton,
  TagButton,
} from "../CreateNewUser/styles";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import {
  getAllUsers,
  updateUserInfo,
  getOfficeStaffs,
} from "../../../../../actions/configurationActions";
import Toast from "../../../../../util/Toast";

import { SET_LOADING_STATE } from "../../../../../constants/utilityConstants";
import ModalContainer from "../../../components/ModalContainer";
import SelectRolesModal from "../RoleModals/CreditRuleModal";

const EditUser = ({ onHide, user, template }) => {
  const dispatch = useDispatch();
  const [editUser, setEditUser] = useState({
    firstname: user ? user.firstname : "",
    lastname: user ? user.lastname : "",
    username: user ? user.username : "",
    email: user ? user.email : "",
    officeId: user ? String(user.officeId) : "",
    staffId: user ? (user.staff ? String(user.staff.id) : "") : "",
  });
  let { firstname, lastname, username, email, officeId, staffId } = editUser;
  let oldRole = user ? user.selectedRoles : [];
  const [selectedRole, setSelectedRole] = useState(oldRole ? oldRole : []);
  const [loading, setLoading] = useState(false);
  const [isRoleSelected, setIsRoleSelected] = useState(false);
  const [modalState, toggleModal] = useState(false);
  const [modalContentName, setModalContentName] = useState("");
  const editUserChangeHandler = (e) =>
    setEditUser({ ...editUser, [e.target.name]: e.target.value });

  const onSubmit = async () => {
    if (username && firstname && lastname && email && officeId) {
      setLoading(true);
      const userData = {
        ...editUser,
        officeId: Number(officeId),
        staffId: staffId ? Number(staffId) : null,
        roles:
          selectedRole.length > 0 ? selectedRole.map((item) => item.id) : [],
        sendPasswordToEmail: false,
      };
      const func = () => {
        dispatch(getAllUsers());
        setLoading(false);
        onHide();
      };
      await dispatch(updateUserInfo(userData, user.id, func));
      setLoading(false);
    } else {
      dispatch(
        Toast({
          text: "Please fill the required field",
          icon: "error",
          timer: 2000,
        })
      );
    }
  };
  const officeStaffs = useSelector((state) => state.officeStaffs);

  const handleModalClose = () => {
    toggleModal(false);
    setIsRoleSelected(false);
  };

  const openModal = () => {
    toggleModal(true);
    setModalContentName("rolesModal");
  };

  const displayModalContent = () => {
    switch (modalContentName) {
      case "rolesModal":
        return (
          <SelectRolesModal
            allData={template?.availableRoles}
            onHide={handleModalClose}
            setSelectedRole={setSelectedRole}
            selectedRole={selectedRole}
          />
        );
      default:
        return;
    }
  };


  const { loading: staffsLoading, staffs } = officeStaffs;
  useEffect(() => {
    if (officeId) dispatch(getOfficeStaffs(officeId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [officeId]);
  useEffect(() => {
    dispatch({ type: SET_LOADING_STATE, payload: staffsLoading });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffsLoading, officeId]);
  return (
    <>
      <ModalContainer show={modalState} dialogClassName="document_infoModal">
        {modalState && displayModalContent()}
      </ModalContainer>
      <Modal.Header>
        <Modal.Title>Edit User</Modal.Title>
      </Modal.Header>
      <Modal.Body className="wc-modal">
        <div className="d-flex align-items-center justify-content-center flex-direction-coloumn">
          <FormClass style={{ width: "90%" }}>
            <FormElements style={{ width: "100%" }}>
              <div className="row h-100">
                <div className="col-sm-12 col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">First Name*</label>
                    <Input
                      style={{ width: "60%" }}
                      size="md"
                      name="firstname"
                      value={firstname}
                      onChange={editUserChangeHandler}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Last Name*</label>
                    <Input
                      style={{ width: "60%" }}
                      size="md"
                      name="lastname"
                      value={lastname}
                      onChange={editUserChangeHandler}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Username*</label>
                    <Input
                      style={{ width: "60%" }}
                      size="md"
                      name="username"
                      value={username}
                      onChange={editUserChangeHandler}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Email Address*</label>
                    <Input
                      style={{ width: "60%" }}
                      size="md"
                      name="email"
                      value={email}
                      onChange={editUserChangeHandler}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Office*</label>
                    <Select
                      placeholder="Select Office"
                      size="md"
                      name="officeId"
                      value={officeId}
                      onChange={editUserChangeHandler}
                    >
                      {template && template.allowedOffices
                        ? template.allowedOffices.map((off, i) => (
                            <option value={off.id} key={i}>
                              {off.name}
                            </option>
                          ))
                        : null}
                    </Select>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Staff</label>
                    <Select
                      placeholder="Select Staff from selected office"
                      size="md"
                      name="staffId"
                      value={staffId}
                      onChange={editUserChangeHandler}
                    >
                      {staffs && staffs.length > 0
                        ? staffs.map((staff) => (
                            <option value={staff.id} key={staff.id}>
                              {staff.displayName}
                            </option>
                          ))
                        : null}
                    </Select>
                  </div>
                </div>
                <div className="form-group w-100 row m-0">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label mb-3 mr-1 pl-3">
                      Select available roles for user
                    </label>
                    <div className="col-sm-12 col-md-7 p-0">
                      <RoleStyleButton
                        onClick={openModal}
                        shadow
                        className="m-0"
                        color="#2C1DFF"
                      >
                        Select roles
                      </RoleStyleButton>
                    </div>
                  </div>
                  <div className="col-md-12 justify-content-sm-center col-sm-12 col-xs-12">
                    {isRoleSelected === false && (
                      <div className="col-md-12">
                        {selectedRole.length
                          ? selectedRole.map((item, key) => (
                              <TagButton
                                onClick={() =>
                                  setSelectedRole(
                                    selectedRole.filter(
                                      (val) => val.id !== item.id
                                    )
                                  )
                                }
                                key={key}
                              >
                                {item.name}
                                <img
                                  src={
                                    require("../../../../../assets/images/icons/cancel-tags.svg")
                                      .default
                                  }
                                  alt="cancel icon"
                                  className="ml-3"
                                />
                              </TagButton>
                            ))
                          : null}
                      </div>
                    )}

                    {/* <RoleContainer className="ml-md-3 w-100 mx-sm-2">
                    {selectedRole.map((item, key) => (
                      <RoleButton
                        textColor="#002C79"
                        key={key}
                        color="#e6f7ff"
                        className="form-control mb-2"
                      >
                        {item.name}
                        <IconContainer
                          onClick={() =>
                            setSelectedRole(
                              selectedRole.filter((val) => val.id !== item.id)
                            )
                          }
                          className=""
                        >
                          <img
                            src={
                              require("../../../../../assets/images/icons/cancel-black.svg")
                                .default
                            }
                            className="ml-2"
                            alt="cancel"
                          />{" "}
                        </IconContainer>
                      </RoleButton>
                    ))}
                  </RoleContainer> */}
                  </div>
                </div>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-1">
          <NewActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require("../../../../../assets/images/icons/cancel.svg").default
              }
              alt="cancel icon"
              className="icon-img"
            />
            Cancel
          </NewActionButton>
          <NewActionButton
            onClick={onSubmit}
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
          >
            {loading ? (
              <Spinner
                animation="border"
                color="#fff"
                className="mr-2 text-white"
                size="sm"
              />
            ) : (
              <img
                src={
                  require("../../../../../assets/images/icons/save.svg").default
                }
                alt="submit icon"
                className="icon-img"
              />
            )}
            Submit
          </NewActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default EditUser;
