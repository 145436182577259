import styled from "styled-components";

export const Nav = styled.nav`
  .accordion-style {
    border-top: none;
    // margin-left: 15px;
    padding: 0px 30px;
  }
  .css-17mg6aq:last-of-type {
    border-bottom-width: 0px;
  }
  .accordion-btn-style {
    margin-bottom: 20px;
    border: 0;
    outline: 0;
    display: flex !important;
    justify-content: space-between;
    &:focus {
      border: 0;
      outline: 0;
      box-shadow: none;
    }
  }
  .css-j2ph2z {
    height: 1.5rem;
    width: 1.5rem;
    display: inline-block;
    line-height: 1em;
    flex-shrink: 0;
    color: currentcolor;
    opacity: 1;
    transition: transform 0.2s ease 0s;
    transform-origin: center center;
    font-size: 1.25em;
    vertical-align: middle;
    transform: rotate(-90deg);
  }
  .rotate {
    transform: rotate(-360deg);
  }
`;
