import React from "react";
import styled from "styled-components";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
} from "@chakra-ui/react";
import { toTitleCase } from "../../../../../util/Tools";
import Button from "../../../../../components/Button/index";

export const AuditModal = ({ onClose, data, isOpen }) => {
  const {
    status,
    currencyData,
    type,
    isInstant,
    recipient,
    savingsAccountNumber,
    approvedNote,
    actionRequestTimelineData,
    description,
    amount,
    rejectedNote,
  } = data;

  const approvedDate = actionRequestTimelineData?.approvedOnDate
    ? actionRequestTimelineData?.approvedOnDate[2] +
      "/" +
      actionRequestTimelineData?.approvedOnDate[1] +
      "/" +
      actionRequestTimelineData?.approvedOnDate[0]
    : "";
  const rejectedDate = actionRequestTimelineData?.rejectedOnDate
    ? actionRequestTimelineData?.rejectedOnDate[2] +
      "/" +
      actionRequestTimelineData?.rejectedOnDate[1] +
      "/" +
      actionRequestTimelineData?.rejectedOnDate[0]
    : "";

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered size="xl">
      <ModalOverlay />
      <ModalContent>
      <ModalBody className="p-5">
          <div className="d-flex mb-3">
            <TextQuestion className="">Amount:</TextQuestion>
            <TextAnswer>{amount}</TextAnswer>
          </div>
          <div className="d-flex mb-3">
            <TextQuestion>Currency type:</TextQuestion>
            <TextAnswer>{currencyData}</TextAnswer>
          </div>
          <div className="d-flex mb-3">
            <TextQuestion>Request note:</TextQuestion>
            <TextArea
              readOnly
              value={description}
              style={{ width: "40%", marginTop: "10px", fontSize: "12px" }}
            ></TextArea>
          </div>
          {isInstant === true || status === "PENDING" ? (
            ""
          ) : (
            <div className="d-flex mb-3">
              <TextQuestion>
                {status === "APPROVED"
                  ? "Approved note"
                  : status === "REJECTED"
                  ? "Rejected note"
                  : "Pending"}
              </TextQuestion>
              <TextArea
                readOnly
                style={{ width: "40%", marginTop: "10px", fontSize: "12px" }}
                value={approvedNote || rejectedNote || ""}
              ></TextArea>
            </div>
          )}

          <div className="d-flex mb-3">
            <TextQuestion>Action type:</TextQuestion>
            <TextAnswer>{type}</TextAnswer>
          </div>
          <div className="d-flex mb-3">
            <TextQuestion>Recipient:</TextQuestion>
            <TextAnswer className="text-uppercase">{recipient}</TextAnswer>
          </div>
          {savingsAccountNumber ? (
            <div className="d-flex mb-3">
              <TextQuestion>Account number:</TextQuestion>
              <TextAnswer>{savingsAccountNumber}</TextAnswer>
            </div>
          ) : (
            ""
          )}

          <div className="d-flex mb-3">
            <TextQuestion>Status:</TextQuestion>
            <TextAnswer value={status}>
              {status ? toTitleCase(status) : ""}
            </TextAnswer>
          </div>
          <div className="d-flex mb-3">
            <TextQuestion>Submitted Date and Time:</TextQuestion>
            <TextAnswer>
              {actionRequestTimelineData?.submittedOnDate[2] +
                "/" +
                actionRequestTimelineData?.submittedOnDate[1] +
                "/" +
                actionRequestTimelineData?.submittedOnDate[0]}
              : {actionRequestTimelineData?.submittedOnTime}
            </TextAnswer>
          </div>

          <div className="d-flex mb-3">
            {actionRequestTimelineData?.approvedOnDate ||
            actionRequestTimelineData?.rejectedOnDate ? (
              <>
                <TextQuestion>
                  {status === "REJECTED" ? "Rejected" : "Approved"} Date and
                  Time:
                </TextQuestion>
                <TextAnswer>
                  {approvedDate || rejectedDate || "Pending"} :{" "}
                  {actionRequestTimelineData?.rejectedOnTime ||
                    actionRequestTimelineData?.approvedOnTime}
                </TextAnswer>
              </>
            ) : (
              ""
            )}
          </div>
          {isInstant === true ? (
            ""
          ) : (
            <div className="d-flex mb-3">
              {status === "APPROVED" ? (
                <>
                  <TextQuestion>Approved by:</TextQuestion>
                  <TextAnswer>
                    {actionRequestTimelineData?.approvedByFirstname ||
                      actionRequestTimelineData?.actionRequestTimelineData
                        ?.rejectedByFirstname}{" "}
                    {actionRequestTimelineData?.approvedByLastname ||
                      actionRequestTimelineData?.rejectedByLastname}
                  </TextAnswer>
                </>
              ) : status === "REJECTED" ? (
                <>
                  <TextQuestion>Rejected by:</TextQuestion>
                  <TextAnswer>
                    {actionRequestTimelineData?.approvedByFirstname ||
                      actionRequestTimelineData?.rejectedByFirstname}{" "}
                    {actionRequestTimelineData?.approvedByLastname ||
                      actionRequestTimelineData?.rejectedByLastname}
                  </TextAnswer>
                </>
              ) : (
                ""
              )}
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <div className="float-right mt-1">
            <ActionButton color="#2C1DFF" onClick={onClose}>
              <img
                src={
                  require("../../../../../assets/images/icons/cancel.svg")
                    .default
                }
                alt="cancel icon"
              />
              Close
            </ActionButton>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const TextQuestion = styled.p`
  width: 40%;
  font-size: 12px;
  font-family: "Helvetica Neue";
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: black;
  margin-right: 25px;
`;
const TextAnswer = styled.p`
  font-size: 12px;

  color: ${(props) =>
    props?.value === "APPROVED"
      ? "#00c537"
      : props?.value === "REJECTED"
      ? "#ff0000"
      : ""};

  background: ${(props) =>
    props?.value === "APPROVED"
      ? "rgba(0, 197, 55, 0.2)"
      : props?.value === "REJECTED"
      ? "rgba(255, 0, 0, 0.2)"
      : ""};
  padding: 1px 14px 1px 18px;
  border-radius: 20px;
  list-style-type: none;
  position: relative;
  color: black;
`;
const TextArea = styled.textarea`
  background: #ffffff;
  border: 0.5px solid #969cba;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
`;

export const TransactionModal = ({ onClose, data, isOpen }) => {
  const {
    runningBalanceDerived,
    transactionAmount,
    transactionNote,
    transactionType,
    cashierTransactionId,
    cashierActionId,
    transactionDate,
    name,
  } = data;
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalBody className="p-5">
          <div className="d-flex mb-3">
            <TextQuestion className="">Cashier name:</TextQuestion>
            <TextAnswer>{name}</TextAnswer>
          </div>
          <div className="d-flex mb-3">
            <TextQuestion className="">Amount:</TextQuestion>
            <TextAnswer>{transactionAmount}</TextAnswer>
          </div>
          <div className="d-flex mb-3">
            <TextQuestion>Currency type:</TextQuestion>
            <TextAnswer>NGN</TextAnswer>
          </div>
          <div className="d-flex mb-3">
            <TextQuestion>Transaction note:</TextQuestion>
            <TextArea
              readOnly
              value={transactionNote}
              style={{ width: "40%", marginTop: "10px", fontSize: "12px" }}
            ></TextArea>
          </div>
          <div className="d-flex mb-3">
            <TextQuestion>Transaction type:</TextQuestion>
            <TextAnswer>{transactionType}</TextAnswer>
          </div>
          {transactionDate ? (
            <div className="d-flex mb-3">
              <TextQuestion>Transaction Date:</TextQuestion>
              <TextAnswer>{transactionDate}</TextAnswer>
            </div>
          ) : (
            ""
          )}

          <div className="d-flex mb-3">
            <TextQuestion>Running balance:</TextQuestion>
            <TextAnswer>{runningBalanceDerived}</TextAnswer>
          </div>
          <div className="d-flex mb-3">
            <TextQuestion>Transaction ID:</TextQuestion>
            <TextAnswer>{cashierTransactionId}</TextAnswer>
          </div>
          <div className="d-flex mb-3">
            <TextQuestion>Action ID:</TextQuestion>
            <TextAnswer>{cashierActionId}</TextAnswer>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const ActionButton = styled.button`
  background: ${(props) => props.color || "#2C1DFF"} !important;
  border-radius: 5px !important;
  color: #fff !important;
  margin: 0 3px;
  border: none;
  font-family: 'AventaMedium';
  display: inline;
  font-size: 14px;
  height: 35px;
  width: 130px;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  position: relative;
  cursor: pointer;
  img {
    height: 14px;
    margin-right: 10px;
    display: inline;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;
