import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";
import {
  ContentWrapper,
  AdvSearchActionButton,
  ActionButtonsGroup,
} from "./styles";
import {
  getAllAudit,
  getAuditTemplate,
} from "../../../../actions/configurationActions";
import CheckPermission from "../../../../util/CheckPermission";
import Toast from "../../../../util/Toast";
import TableLoader from "./TableLoader";
import { formatDate } from "../../../../util/FormatDate";
import { InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import ModalContainer from "../../components/ModalContainer";
import AdvanceEditModal from "./AdvanceEditModal";
import AuditTrailDetails from "./AuditTrailDetails";

const AuditTrailsView = ({ history  , pagination: externalPagination}) => {
  const dispatch = useDispatch();
  const auditReducer = useSelector((state) => state.auditReducer);
  // const auditDataRes = useSelector((state) => state.preAuth);
  let { loading, audits = [] } = auditReducer;
  const [displayAudits, setDisplayAudits] = useState(audits);
  const [filter, setFilter] = useState("");
  // const [auditTrailsDedtail, setAuditTrailsDedtail] = useState({});
  // const [modalState, toggleModal] = useState(false);
  const [advanceModalState, advanceToggleModal] = useState(false);
  const [advanceFilter, setAdvanceFilter] = useState({});

  const [currentView, setCurrentView] = useState(1);
  const [additionalData, setAdditionalData] = useState(null);

  // const [showModal, setShowModal] = useState("");

  audits.sort((a, b) => (a.maker > b.maker ? 1 : -1));
  // const [searchState, setSearchState] = useState(1);

  const search = useRef("");

  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  // const [pagination, setPagination] = useState({
  //   current: 1,
  //   pageSize: 15,
  // });

  const [pagination, setPagination] = useState({
    current: externalPagination?.current || 1,
    pageSize: externalPagination?.pageSize || 15,
  });

  const handleTableChange = (pagination, filters, sorter) => {
    // Handle table changes and update the pagination state
    setPagination({ ...pagination });
  };

  // const [auditData, setAuditData] = useState([]);
  // const [activePagination, setActivePagination] = useState();

  // const [currentPagination, setCurrentPagination] = useState(1);
  const tableData = [];
  // const filteredAuditUsers = [];

  useEffect(() => {
    if (filter) {
      const filteredAudit = audits.filter((cst) => {
        const regex = new RegExp(filter, "gi");
        return cst.maker && cst.maker.match(regex);
      });
      if (filteredAudit.length == 0) {
        if (filter !== null && displayAudits?.length === 0) {
          dispatch(
            Toast({
              text: "No user found",
              icon: "error",
              timer: 2000,
            })
          );
        }
      }
      setDisplayAudits(filteredAudit);
    } else {
      setDisplayAudits(audits);
    }
  }, [filter]);

  useEffect(() => {
    setDisplayAudits(audits);
  }, [audits]);

  !loading &&
    displayAudits.forEach((audit) => {
      const {
        id,
        actionName,
        entityName,
        madeOnDate,
        maker,
        processingResult,
        resourceId,
        officeName,
        // checker,
        // checkedOnDate,
      } = audit;

      tableData.push({
        id,
        rid: resourceId,
        status: processingResult,
        madeBy: maker,
        action: actionName,
        entity: entityName,
        office: officeName,
        madedate: formatDate(madeOnDate),
        // checker: checker,
        // checkeddate: checkedOnDate != null ? formatDate(checkedOnDate) : null,
        audit,
      });
    });

  // We coonvert the date string to raw Date so we can sort, after sorting we reverse it.
  const newData = tableData
    .sort((a, b) => {
      var c = new Date(a.madedate);
      var d = new Date(b.madedate);
      return c - d;
    })
    .reverse();

  const columns = [
    { title: "ID", dataIndex: "id", key: "id" },
    { title: "Resource ID", dataIndex: "rid", key: "rid" },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Madeby",
      dataIndex: "madeBy",
      key: "madeBy",
    },
    { title: "Action", dataIndex: "action", key: "action" },
    {
      title: "Entity",
      dataIndex: "entity",
      key: "entity",
    },
    {
      title: "Office",
      dataIndex: "office",
      key: "office",
    },
    {
      title: "Made Date",
      dataIndex: "madedate",
      key: "madedate",
    },
    // {
    //   title: "Checker",
    //   dataIndex: "CheckBy",
    //   key: "CheckBy",
    // },
    // {
    //   title: "Checked Date",
    //   dataIndex: "checkeddate",
    //   key: "checkeddate",
    // },
  ];

  const onFilterAuditTrial = (e) => {
    setFilter(e.target.value);
  };

  useEffect(() => {
    dispatch(getAllAudit());
    dispatch(getAuditTemplate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log(currentView, "currentView");
  // console.log(additionalData, "additionalData");

  return (
    <>
      {/* <ModalContainer show={modalState} dialogClassName="document_infoModal">
        {modalState && (
          <AuditTrailsDetailsModal
            onHide={handleOnHide}`x
            details={auditTrailsDedtail}
          />
        )}
      </ModalContainer> */}
      {currentView === 1 && (
        <div className="container-fluid">
          <div className="row justify-content-between">
            <div className="col-sm-12 col-md-6 pb-3">
              <h3 className="page-title">Audit Trails</h3>
              <p className="sub-title">Audit logs of all the activities</p>
            </div>
            <div className="col-sm-12 col-md-6 px-0 pt-3">
              <ActionButtonsGroup>
                <div className="col-sm-12 col-md-6 pr-2">
                  <InputGroup>
                    <InputLeftElement
                      width="3.1rem"
                      height="38px"
                      pointerEvents="none"
                    >
                      <img
                        src={
                          require("../../../../assets/images/icons/search.svg")
                            .default
                        }
                        style={{ height: "30px" }}
                        alt="Icon"
                        className="icon-img"
                      />
                    </InputLeftElement>
                    <Input
                      style={{
                        background: "#ffffff",
                        height: "35px",
                      }}
                      type="text"
                      className="general-font-size"
                      placeholder="Search by name"
                      value={filter}
                      ref={search}
                      onChange={onFilterAuditTrial}
                    />
                  </InputGroup>
                </div>
                <div className="col-sm-12 col-md-6 p-0 d-flex">
                  <AdvSearchActionButton
                    className="form-control general-font-size"
                    onClick={() => advanceToggleModal(true)}
                  >
                    {/* <Link> */}
                    Advance Search
                    {/* </Link> */}
                  </AdvSearchActionButton>
                </div>
              </ActionButtonsGroup>
            </div>
          </div>
          {CheckPermission("READ_AUDIT", permissions) && (
            <ContentWrapper>
              {loading ? (
                <TableLoader />
              ) : (
                <Table
                  columns={columns}
                  dataSource={newData}
                  // pagination={{ defaultPageSize: 13 }}
                  defaultPageSize={13}
                  pagination={{ ...pagination, ...externalPagination }} 
                  onChange={handleTableChange}
                  onRow={(r) => ({
                    onClick: () => {
                      setCurrentView(2);
                      setAdditionalData(r.id);
                      // setAuditTrailsDedtail(r);
                      // history.push(`/core/audit-trail/${r.id}`)
                      // toggleModal(true);
                      // advanceToggleModal(true);
                    },
                  })}
                />
              )}
              {pagination === false ? (
                <>
                  {/* <div className="spaceBetweenFlexEnd my-pag"> */}
                  {/* <div */}
                  <h2>Welcome</h2>
                </>
              ) : (
                ""
              )}
              <ModalContainer
                show={advanceModalState}
                dialogClassName="document_infoModal"
              >
                <AdvanceEditModal
                  audits={audits}
                  advanceFilter={advanceFilter}
                  setAdvanceFilter={setAdvanceFilter}
                  onCancel={() => advanceToggleModal(false)}
                  onClear={() => {
                    setDisplayAudits(audits);
                    dispatch(getAllAudit());
                  }}
                  onSearch={(filteredAudit) => {
                    advanceToggleModal(false);
                    dispatch(
                      getAllAudit(
                        filteredAudit.actionName,
                        filteredAudit.checkerDateTimeFrom,
                        filteredAudit.checkerDateTimeTo,
                        filteredAudit.checkerId,
                        filteredAudit.entityName,
                        filteredAudit.makerDateTimeFrom,
                        filteredAudit.makerDateTimeTo,
                        filteredAudit.makerId,
                        filteredAudit.resourceId,
                        filteredAudit.status
                      )
                    );
                  }}
                />
              </ModalContainer>
            </ContentWrapper>
          )}
        </div>
      )}
      {currentView === 2 && (
        <AuditTrailDetails
          setCurrentView={setCurrentView}
          additionalData={additionalData}
        />
      )}
    </>
  );
};

export default AuditTrailsView;
