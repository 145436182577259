import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";
import { ContentWrapper, ActionButton } from "./styles";
import {
  getAllUserAudit,
  getAuditTemplate,
} from "../../../../actions/configurationActions";
import SearchInputs from "./SearchInputs";
import TableLoader from "./TableLoader";
import { formatDate } from "../../../../util/FormatDate";

import UserAuditDetailsModal from "./UserAuditDetailsModal";
import ModalContainer from "../../components/ModalContainer";

const UserAuditView = ({ history }) => {
  const dispatch = useDispatch();
  const auditReducer = useSelector((state) => state.auditReducer);
  let { loading, audits = [] } = auditReducer;

  const [searchState, setSearchState] = useState(1);

  const tableData = [];

  !loading &&
    audits.forEach((audit) => {
      const {
        id,
        actionName,
        entityName,
        madeOnDate,
        maker,
        processingResult,
        resourceId,
        officeName,
      } = audit;

      tableData.push({
        id,
        rid: resourceId,
        status: processingResult,
        madeBy: maker,
        action: actionName,
        entity: entityName,
        office: officeName,
        madedate: formatDate(madeOnDate),
        audit,
      });
    });

  const columns = [
    { title: "ID", dataIndex: "id", key: "id" },
    { title: "Resource ID", dataIndex: "rid", key: "rid" },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Madeby",
      dataIndex: "madeBy",
      key: "madeBy",
    },
    { title: "Action", dataIndex: "action", key: "action" },
    {
      title: "Entity",
      dataIndex: "entity",
      key: "entity",
    },
    {
      title: "Office",
      dataIndex: "office",
      key: "office",
    },
    {
      title: "Made Date",
      dataIndex: "madedate",
      key: "madedate",
    },
    // {
    //   title: "Checked Date",
    //   dataIndex: "checkeddate",
    //   key: "checkeddate",
    // },
  ];

  useEffect(() => {
    dispatch(getAllUserAudit());
    dispatch(getAuditTemplate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnHide = () => {
    toggleModal(false);
  };

  const [userAuditDedtail, setUserAuditDedtail] = useState({});
  const [modalState, toggleModal] = useState(false);
  return (
    <>
      <ModalContainer show={modalState}>
        {modalState && (
          <UserAuditDetailsModal
            onHide={handleOnHide}
            details={userAuditDedtail}
          />
        )}
      </ModalContainer>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-1">
            <h3 className="page-title">User Audit</h3>
            <p className="sub-title">User Audit logs of all the activities</p>
            {/* <p className="font-weight sub-title mt-2">
              <span className="text-info">
                <Link to="/core/configuration">Configuration</Link> /{" "}
                <Link to="/core/configuration/organization">Utilities</Link>
              </span>{" "}
              / Audit Trails
            </p> */}
          </div>
        </div>
        <ContentWrapper>
          {searchState === 1 ? (
            <div style={{ padding: 50 }}>
              <SearchInputs setSearchState={setSearchState} />
            </div>
          ) : (
            <div className="p-relative">
              <div className="parametersAbsolute">
                <ActionButton
                  color="#2C1DFF"
                  className="float-right"
                  style={{ margin: "0px 0px" }}
                  onClick={() => setSearchState(1)}
                >
                  <img
                    src={
                      require("../../../../assets/images/icons/searchWhite.svg")
                        .default
                    }
                    className="d-inline mr-3"
                    alt="icon"
                  />
                  Parameters
                </ActionButton>
              </div>
              {loading ? (
                <TableLoader />
              ) : (
                <Table
                  columns={columns}
                  dataSource={tableData}
                  pagination={{ defaultPageSize: 8 }}
                  defaultPageSize={8}
                  onRow={(r) => ({
                    onClick: () => {
                      setUserAuditDedtail(r);
                      toggleModal(true);
                    },
                  })}
                />
              )}
            </div>
          )}
        </ContentWrapper>
      </div>
    </>
  );
};

export default UserAuditView;
