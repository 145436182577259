import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import ModalContainer from "../../../components/ModalContainer";
import { ContentWrapper, ActionButton } from "./styles";
import DeleteAccountModal from "./DeleteAccountModal";
import DisableAccountModal from "./DisableAccountModal";

const AccountInfo = ({ history }) => {
  const [modalState, toggleModal] = useState(false);
  const [modalContentName, setModalContentName] = useState("");

  const currentContent = (e) => {
    const val = e.currentTarget.id.trim();

    if (val) {
      toggleModal(true);
      setModalContentName(val);
    }
  };

  const handleModalClose = () => toggleModal(false);

  const displayModalContent = () => {
    switch (modalContentName) {
      case "deleteAccount":
        return <DeleteAccountModal onHide={handleModalClose} />;
      case "disableAccount":
        return <DisableAccountModal onHide={handleModalClose} />;
      default:
        return;
    }
  };
  return (
    <>
      <ModalContainer show={modalState}>
        {modalState && displayModalContent()}
      </ModalContainer>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Create GL Account</h3>
            <p className="sub-title mt-2">
              <span className="text-info mr-1">
                <Link to="/core/charts-of-account">Charts of Accounts</Link>
              </span>{" "}
              /{" "}
              <span className="text-info mr-1">
                <Link to="/core/charts-of-account">
                  Mark Peters - 2212344434
                </Link>
              </span>
              / Account Info
            </p>
          </div>
        </div>
        <ContentWrapper>
          <div className="row">
            <div className="col-sm-12 col-md-4">
              <div className="m-2">
                <Table responsive striped bordered hover>
                  <thead className="border-0">
                    <tr>
                      <td
                        className="font-weight-bold md-font-weight text-center"
                        colSpan="2"
                      >
                        Mark Peters
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="font-weight-bold md-font-weight">
                        GL Code
                      </td>
                      <td>22123455778</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold md-font-weight">
                        Account type
                      </td>
                      <td>Assets</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold md-font-weight">
                        Parent Account Name
                      </td>
                      <td>Workcore Account</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold md-font-weight">
                        Account Usage
                      </td>
                      <td>Header</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold md-font-weight">
                        Manual Entries
                      </td>
                      <td>True</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold md-font-weight">Tags</td>
                      <td>Moveable Assets</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold md-font-weight">
                        Description
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
            <div className="col-sm-12 col-md-7">
              <div className="row">
                <div className="button-right mt-5">
                  <ActionButton
                    color="#2C1DFF"
                    className="float-right"
                    style={{ margin: "0px 5px" }}
                    onClick={currentContent}
                    id="deleteAccount"
                  >
                    <img
                      src={
                        require("../../../../../assets/images/icons/delete.svg")
                          .default
                      }
                      alt="delete icon"
                    />
                    Delete Account
                  </ActionButton>{" "}
                  <ActionButton
                    color="#2C1DFF"
                    className="float-right"
                    style={{ margin: "0px 5px" }}
                    onClick={() =>
                      history.push(
                        "/core/accounting/charts-of-account/edit-gl-account"
                      )
                    }
                  >
                    <img
                      src={
                        require("../../../../../assets/images/icons/edit-profile.svg")
                          .default
                      }
                      alt="add icon"
                    />
                    Edit Account
                  </ActionButton>{" "}
                  <ActionButton
                    color="#2C1DFF"
                    className="float-right"
                    style={{ margin: "0px 5px" }}
                    onClick={currentContent}
                    id="disableAccount"
                  >
                    <img
                      src={
                        require("../../../../../assets/images/icons/disable.svg")
                          .default
                      }
                      alt="add icon"
                    />
                    Enable/Disable
                  </ActionButton>
                  <ActionButton
                    color="#2C1DFF"
                    className="float-right"
                    style={{ margin: "0px 5px" }}
                    // onClick={}
                  >
                    <img
                      src={
                        require("../../../../../assets/images/icons/add.svg")
                          .default
                      }
                      alt="mark icon"
                    />
                    Subledger Account
                  </ActionButton>
                </div>
              </div>
            </div>
          </div>
        </ContentWrapper>
      </div>
    </>
  );
};

export default AccountInfo;
