export const ALL_CUSTOMERS_REQUEST = "ALL_CUSTOMERS_REQUEST";
export const ALL_CUSTOMERS_SUCCESS = "ALL_CUSTOMERS_SUCCESS";
export const ALL_CUSTOMERS_FAIL = "ALL_CUSTOMERS_FAIL";
export const ALL_CUSTOMERS_PAGINATION = "ALL_CUSTOMERS_PAGINATION";

export const SINGLE_CUSTOMER_REQUEST = "SINGLE_CUSTOMER_REQUEST";
export const SINGLE_CUSTOMER_SUCCESS = "SINGLE_CUSTOMER_SUCCESS";
export const SINGLE_CUSTOMER_FAIL = "SINGLE_CUSTOMER_FAIL";
export const SINGLE_CUSTOMER_RESET = "SINGLE_CUSTOMER_RESET";

export const UPDATE_CUSTOMER_REQUEST = "UPDATE_CUSTOMER_REQUEST";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
export const UPDATE_CUSTOMER_FAIL = "UPDATE_CUSTOMER_FAIL";
export const UPDATE_CUSTOMER_RESET = "UPDATE_CUSTOMER_RESET";

export const  UPDATE_CUSTOMER_ADDRESS_REQUEST = "CUSTOMER_ADDRESS_REQUEST";
export const  UPDATE_CUSTOMER_ADDRESS_SUCCESS = "CUSTOMER_ADDRESS_SUCCESS";
export const  UPDATE_CUSTOMER_ADDRESS_FAIL = "CUSTOMER_ADDRESS_FAIL";

export const CUSTOMER_ADDRESS_REQUEST = "CUSTOMER_ADDRESS_REQUEST";
export const CUSTOMER_ADDRESS_SUCCESS = "CUSTOMER_ADDRESS_SUCCESS";
export const CUSTOMER_ADDRESS_FAIL = "CUSTOMER_ADDRESS_FAIL";

export const CREATE_NEW_CUSTOMER_REQUEST = "CREATE_NEW_CUSTOMER_REQUEST";
export const CREATE_NEW_CUSTOMER_SUCCESS = "CREATE_NEW_CUSTOMER_SUCCESS";
export const CREATE_NEW_CUSTOMER_FAIL = "CREATE_NEW_CUSTOMER_FAIL";
export const CREATE_NEW_CUSTOMER_RESET = "CREATE_NEW_CUSTOMER_RESET";

export const IMAGE_UPLOAD_REQUEST = "IMAGE_UPLOAD_REQUEST";
export const IMAGE_UPLOAD_SUCCESS = "IMAGE_UPLOAD_SUCCESS";
export const IMAGE_UPLOAD_FAIL = "IMAGE_UPLOAD_FAIL";

export const DOCUMENT_UPLOAD_REQUEST = "DOCUMENT_UPLOAD_REQUEST";
export const DOCUMENT_UPLOAD_SUCCESS = "DOCUMENT_UPLOAD_SUCCESS";
export const DOCUMENT_UPLOAD_FAIL = "DOCUMENT_UPLOAD_FAIL";

export const GET_DOCUMENTS_REQUEST = "GET_DOCUMENTS_REQUEST";
export const GET_DOCUMENTS_SUCCESS = "GET_DOCUMENTS_SUCCESS";
export const GET_DOCUMENTS_FAIL = "GET_DOCUMENTS_FAIL";
export const GET_DOCUMENTS_RESET = "GET_DOCUMENTS_RESET";

export const DELETE_DOCUMENT_REQUEST = "DELETE_DOCUMENT_REQUEST";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT_FAIL = "DELETE_DOCUMENT_FAIL";

export const IMAGE_UPDATE_REQUEST = "IMAGE_UPDATE_REQUEST";
export const IMAGE_UPDATE_SUCCESS = "IMAGE_UPDATE_SUCCESS";
export const IMAGE_UPDATE_FAIL = "IMAGE_UPDATE_FAIL";

export const GET_IMAGE_REQUEST = "GET_IMAGE_REQUEST";
export const GET_IMAGE_SUCCESS = "GET_IMAGE_SUCCESS";
export const GET_IMAGE_FAIL = "GET_IMAGE_FAIL";
export const GET_IMAGE_RESET = "GET_IMAGE_RESET";

export const GET_CHARGES_REQUEST = "GET_CHARGES_REQUEST";
export const GET_CHARGES_SUCCESS = "GET_CHARGES_SUCCESS";
export const GET_CHARGES_FAIL = "GET_CHARGES_FAIL";

export const GET_CUSTOMER_ACCOUNTS_REQUEST = "GET_CUSTOMER_ACCOUNTS_REQUEST";
export const GET_CUSTOMER_ACCOUNTS_SUCCESS = "GET_CUSTOMER_ACCOUNTS_SUCCESS";
export const GET_CUSTOMER_ACCOUNTS_FAIL = "GET_CUSTOMER_ACCOUNTS_FAIL";
export const CLEAR_CUSTOMER_ACCOUNTS = "CLEAR_CUSTOMER_ACCOUNTS";

export const SEARCH_CUSTOMERS_REQUEST = "SEARCH_CUSTOMERS_REQUEST";
export const SEARCH_CUSTOMERS_SUCCESS = "SEARCH_CUSTOMERS_SUCCESS";
export const SEARCH_CUSTOMERS_FAIL = "SEARCH_CUSTOMERS_FAIL";

export const FILTER_CUSTOMERS_REQUEST = "FILTER_CUSTOMERS_REQUEST";
export const CLEAR_FILTER = "CLEAR_FILTER";

// SAVINGS ACCOUNT ON CUSTOMERS
export const CREATE_SAVINGS_REQUEST = "CREATE_SAVINGS_REQUEST";
export const CREATE_SAVINGS_SUCCESS = "CREATE_SAVINGS_SUCCESS";
export const CREATE_SAVINGS_FAIL = "CREATE_SAVINGS_FAIL";

//EDIT SAVINGS ACCOUNT
// export const SAVINGS_ACCOUNT_FAIL = "SAVINGS_ACCOUNT_FAIL";
// export const SAVINGS_ACCOUNT_SUCCESS = "SAVINGS_ACCOUNT_SUCCESS";
// export const SAVINGS_ACCOUNT_REQUEST = "SAVINGS_ACCOUNT_REQUEST";

//UPDATE SAVINGS ACCOUNT
// export const UPDATE_SAVINGS_ACCOUNT_REQUEST = "UPDATE_SAVINGS_ACCOUNT_REQUEST";
// export const UPDATE_SAVINGS_ACCOUNT_SUCCESS = "UPDATE_SAVINGS_ACCOUNT_SUCCESS";
// export const UPDATE_SAVINGS_ACCOUNT_FAIL = "UPDATE_SAVINGS_ACCOUNT_FAIL";

export const MAKE_DEPOSIT_REQUEST = "MAKE_DEPOSIT_REQUEST";
export const MAKE_DEPOSIT_SUCCESS = "MAKE_DEPOSIT_SUCCESS";
export const MAKE_DEPOSIT_FAIL = "MAKE_DEPOSIT_FAIL";

export const DOWNLOAD_ACCOUNT_STATEMENT = "DOWNLOAD_ACCOUNT_STATEMENT";
export const DOWNLOAD_ACCOUNT_STATEMENT_SUCCESS =
  "DOWNLOAD_ACCOUNT_STATEMENT_SUCCESS";
export const DOWNLOAD_ACCOUNT_STATEMENT_FAIL =
  "DOWNLOAD_ACCOUNT_STATEMENT_FAIL";
export const DOWNLOAD_ACCOUNT_STATEMENT_RESET =
  "DOWNLOAD_ACCOUNT_STATEMENT_RESET";

  export const DOWNLOAD_TRANSACTION_RECEIPT = "DOWNLOAD_TRANSACTION_RECEIPT";
export const DOWNLOAD_TRANSACTION_RECEIPT_SUCCESS =
  "DOWNLOAD_TRANSACTION_RECEIPT_SUCCESS";
  export const DOWNLOAD_TRANSACTION_RECEIPT_FAIL =
  "DOWNLOAD_TRANSACTION_RECEIPT_FAIL";
export const DOWNLOAD_TRANSACTION_RECEIPT_RESET =
  "DOWNLOAD_TRANSACTION_RECEIPT_RESET";

export const DOWNLOAD_REPAYMENT_SCHEDULE = "DOWNLOAD_REPAYMENT_SCHEDULE";
export const DOWNLOAD_REPAYMENT_SCHEDULE_SUCCESS =
  "DOWNLOAD_REPAYMENT_SCHEDULE_SUCCESS";
export const DOWNLOAD_REPAYMENT_SCHEDULE_FAIL =
  "DOWNLOAD_REPAYMENT_SCHEDULE_FAIL";
export const DOWNLOAD_REPAYMENT_SCHEDULE_RESET =
  "DOWNLOAD_REPAYMENT_SCHEDULE_RESET";

export const MAKE_WITHDRAWAL_REQUEST = "MAKE_WITHDRAWAL_REQUEST";
export const MAKE_WITHDRAWAL_SUCCESS = "MAKE_WITHDRAWAL_SUCCESS";
export const MAKE_WITHDRAWAL_FAIL = "MAKE_WITHDRAWAL_FAIL";

//LOAN ACCOUNT ON CUSTOMER
export const CREATE_LOAN_REQUEST = "CREATE_LOAN_REQUEST";
export const CREATE_LOAN_SUCCESS = "CREATE_LOAN_SUCCESS";
export const CREATE_LOAN_FAIL = "CREATE_LOAN_FAIL";

export const GET_LOAN_DETAILS_REQUEST = "GET_LOAN_DETAILS_REQUEST";
export const GET_LOAN_DETAILS_SUCCESS = "GET_LOAN_DETAILS_SUCCESS";
export const GET_LOAN_DETAILS_FAIL = "GET_LOAN_DETAILS_FAIL";
export const GET_LOAN_DETAILS_RESET = "GET_LOAN_DETAILS_RESET";

export const LOAN_APPROVAL_REQUEST = "LOAN_APPROVAL_REQUEST";
export const LOAN_APPROVAL_SUCCESS = "LOAN_APPROVAL_SUCCESS";
export const LOAN_APPROVAL_FAIL = "LOAN_APPROVAL_FAIL";

export const LOAN_DISBURSAL_REQUEST = "LOAN_DISBURSAL_REQUEST";
export const LOAN_DISBURSAL_SUCCESS = "LOAN_DISBURSAL_SUCCESS";
export const LOAN_DISBURSAL_FAIL = "LOAN_DISBURSAL_FAIL";

export const GET_LOAN_LETTER = "GET_LOAN_LETTER";
export const GET_LOAN_LETTER_SUCCESS = "GET_LOAN_LETTER_SUCCESS";
export const GET_LOAN_LETTER_FAIL = "GET_LOAN_LETTER";

export const GET_LOAN_OFFER_LETTER = "GET_LOAN_OFFER_LETTER";
export const GET_LOAN_OFFER_LETTER_SUCCESS = "GET_LOAN_OFFER_LETTER_SUCCESS";
export const GET_LOAN_OFFER_LETTER_FAIL = "GET_LOAN_OFFER_LETTER_FAIL";
export const CLEAR_LOAN_OFFER_LETTER = "CLEAR_LOAN_OFFER_LETTER";

export const SAVINGS_ACCOUNT_FAIL = "SAVINGS_ACCOUNT_FAIL "
export const  SAVINGS_ACCOUNT_SUCCESS = "SAVINGS_ACCOUNT_SUCCESS "
export const  SAVINGS_ACCOUNT_REQUEST = "SAVINGS_ACCOUNT_REQUEST "
export const  SAVINGS_ACCOUNT_RESET = "SAVINGS_ACCOUNT_RESET "


export const  UPDATE_SAVINGS_ACCOUNT_REQUEST = "UPDATE_SAVINGS_ACCOUNT_REQUEST "
export const  UPDATE_SAVINGS_ACCOUNT_SUCCESS = " UPDATE_SAVINGS_ACCOUNT_SUCCESS"
export const  UPDATE_SAVINGS_ACCOUNT_FAIL = "UPDATE_SAVINGS_ACCOUNT_FAIL "

export const LOAN_ACCOUNT_FAIL = "LOAN_ACCOUNT_FAIL "
export const  LOAN_ACCOUNT_SUCCESS = "LOAN_ACCOUNT_SUCCESS "
export const  LOAN_ACCOUNT_REQUEST = "LOAN_ACCOUNT_REQUEST "
export const  LOAN_ACCOUNT_RESET = "LOAN_ACCOUNT_RESET "

export const  UPDATE_LOAN_ACCOUNT_REQUEST = "UPDATE_LOAN_ACCOUNT_REQUEST "
export const  UPDATE_LOAN_ACCOUNT_SUCCESS = " UPDATE_LOAN_ACCOUNT_SUCCESS"
export const  UPDATE_LOAN_ACCOUNT_FAIL = "UPDATE_LOAN_ACCOUNT_FAIL "

export const  UPDATE_CLIENT_TIER_REQUEST = "UPDATE_CLIENT_TIER_REQUEST "
export const  UPDATE_CLIENT_TIER_SUCCESS = " UPDATE_CLIENT_TIER_SUCCESS"
export const  UPDATE_CLIENT_TIER__FAIL = "UPDATE_CLIENT_TIER__FAIL "

export const ALL_COUNTRY_REQUEST = "ALL_COUNTRY_REQUEST";
export const ALL_COUNTRY_SUCCESS = "ALL_COUNTRY_SUCCESS";
export const ALL_COUNTRY_FAIL = "ALL_COUNTRY_FAIL";
export const ALL_COUNTRY_RESET = "ALL_COUNTRY_RESET";

export const ALL_STATES_REQUEST = "ALL_STATES_REQUEST";
export const ALL_STATES_SUCCESS = "ALL_STATES_SUCCESS";
export const ALL_STATES_FAIL = "ALL_STATES_FAIL";
export const ALL_STATES_RESET = "ALL_STATES_RESET";

export const FETCH_CUSTOMER_NOTES_REQUEST = "FETCH_CUSTOMER_NOTES_REQUEST";
export const FETCH_CUSTOMER_NOTES_FAIL = "FETCH_CUSTOMER_NOTES_FAIL";
export const FETCH_CUSTOMER_NOTES_SUCCESS = "FETCH_CUSTOMER_NOTES_SUCCESS";

export const CREATE_CUSTOMER_NOTE_REQUEST = "CREATE_CUSTOMER_NOTE_REQUEST";
export const CREATE_CUSTOMER_NOTE_FAIL = "CREATE_CUSTOMER_NOTE_FAIL";
export const CREATE_CUSTOMER_NOTE_SUCCESS = "CREATE_CUSTOMER_NOTE_SUCCESS";

export const UPDATE_CUSTOMER_NOTE_REQUEST = "UPDATE_CUSTOMER_NOTE_REQUEST";
export const UPDATE_CUSTOMER_NOTE_FAIL = "UPDATE_CUSTOMER_NOTE_FAIL";
export const UPDATE_CUSTOMER_NOTE_SUCCESS = "UPDATE_CUSTOMER_NOTE_SUCCESS";

export const DELETE_CUSTOMER_NOTE_REQUEST = "DELETE_CUSTOMER_NOTE_REQUEST";
export const DELETE_CUSTOMER_NOTE_FAIL = "DELETE_CUSTOMER_NOTE_FAIL";
export const DELETE_CUSTOMER_NOTE_SUCCESS = "DELETE_CUSTOMER_NOTE_SUCCESS";
