import config from "../../config";
import axiosData from "../axiosData";

/**
 * Get loan collateral template
 */
export const getLoanCollateralTemplate = (clientId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/loans/${clientId}/collaterals/template`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/**
 * Get loan collateral template
 */
export const getLoanCollaterals = (loanId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/loans/${loanId}/collaterals`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/**
 * Add collateral(s) to a pending loan
 */
export const updatePendingLoanWithCollaterals = (loanId, collaterals) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        url: `${config.apiGateway.BASE_API_URL}/loans/${loanId}/collaterals`,
        data: collaterals,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/**
 * Remove a collateral from an active loan
 */
export const updateCollateral = (loanId, collateralId, updatedData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "PUT",
        url: `${config.apiGateway.BASE_API_URL}/loans/${loanId}/collaterals/${collateralId}`,
        data: updatedData,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/**
 * Remove a collateral from an active loan
 */
export const deleteCollateral = (loanId, collateralId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "DELETE",
        url: `${config.apiGateway.BASE_API_URL}/loans/${loanId}/collaterals/${collateralId}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};