import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Pagination, Table } from "antd";
import GetCustomerImage from "./GetCustomerImage";

import { InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import {
  ActionButton,
  ContentWrapper,
  ActionButtonsGroup,
  NewActionButton,
} from "./styles";

import {
  getAllCustomersPagination,
  clearFilteredCustomerData,
  filterCustomerData,
} from "../../../actions/customerActions";
import Toast from "../../../util/Toast";
import CustomItemLoader from "../../../components/Loader/CustomItemLoader";
import CheckPermission from "../../../util/CheckPermission";
import { formatDate } from "../../../util/FormatDate";

const CustomerView = ({ history }) => {
  const dispatch = useDispatch();
  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  const customerPagination = useSelector(
    (state) => state.customerPagination.customersPagination
  );
  const isLoading = useSelector((state) => state.isLoading.isLoading);

  const isTableLoading = useSelector(
    (state) => state.isTableLoading.isTableLoading
  );

  const {
    totalFilteredRecords,
    totalItems,
    numberOfPages,
    pageItems = [],
  } = customerPagination || {};

  // pageItems === content
  // totalFilteredRecords === totalElements

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 13,
  });

  const [searchPagination, setSearchPagination] = useState({
    current: 1,
    pageSize: 13,
  });

  const [myData, setMyData] = useState([]);
  const [search, setSearch] = useState("");
  const [activeSearchPagination, setActiveSearchPagination] = useState();
  const [activePagination, setActivePagination] = useState();

  const handleRefresh = () => {
    if (CheckPermission("READ_CLIENT", permissions)) {
      dispatch(
        getAllCustomersPagination("clients?limit=13&offset=0&sortOrder=DESC")
      );
    }
  };

  // const search = useRef("");

  // Search
  const onFiltercustomer = (e) => {
    e.preventDefault();
    handleSearchTableChange(searchPagination);
  };

  const handleTableChange = (pagination) => {
    setActivePagination(pagination.current);
    if (!!customerPagination && !!customerPagination.length) {
      setPagination({
        current: activePagination,
        pageSize: 13,
        total: customerPagination.length,
      });
    } else {
      let myOffsect = pagination.current * 13;
      let dataToSend2 = `clients?limit=13&offset=${
        myOffsect - 13
      }&sortOrder=DESC`;
      dispatch(getAllCustomersPagination(dataToSend2));
    }
  };

  const handleSearchTableChange = (searchPagination) => {
    setActiveSearchPagination(searchPagination?.current);
    if (!!customerPagination && !!customerPagination?.length) {
      setSearchPagination({
        current: activeSearchPagination,
        pageSize: 13,
        total: customerPagination?.length,
      });
    } else {
      if (search !== null && search !== "") {
        setMyData([]);
        let myOffsect = searchPagination?.current * 13;
        let dataToSend2 = `search?limit=13&offset=${
          myOffsect - 13
        }&exactMatch=false&query=${search}&resource=clients&sortOrder=DESC`;
        dispatch(getAllCustomersPagination(dataToSend2));
      }
    }
  };

  useEffect(() => {
    if (totalFilteredRecords !== 0 && totalFilteredRecords !== 1) {
      setPagination({
        current: activePagination,
        pageSize: 13,
        total: totalFilteredRecords,
      });
    } else if (totalItems === 0 && numberOfPages === 0) {
      setPagination(false);
    } else if (!!customerPagination && !!customerPagination.length) {
      setPagination({ defaultPageSize: 13 });
    }
  }, [
    totalItems,
    numberOfPages,
    totalFilteredRecords,
    activePagination,
    customerPagination,
  ]);

  useEffect(() => {
    if (!!customerPagination && !!customerPagination.length) {
      setMyData(customerPagination);
    } else if (!!pageItems.length) {
      setMyData(pageItems);
    }
  }, [pageItems, customerPagination]);

  useEffect(() => {
    if (CheckPermission("READ_CLIENT", permissions)) {
      dispatch(
        getAllCustomersPagination("clients?limit=13&offset=0&sortOrder=DESC")
      );
    }
  }, [permissions, dispatch]);

  const handleCustomerStatus = (entityStatus, active) => {
    if (entityStatus && !!entityStatus.id) {
      if (entityStatus.id === 300) {
        return <span className="new-text-success">Active</span>;
      } else {
        return <span className="text-danger">Inactive</span>;
      }
    } else {
      if (active) {
        return <span className="new-text-success">Active</span>;
      } else {
        return <span className="text-danger">Inactive</span>;
      }
    }
  };

  //TABLE CONFIGURATION
  const dataSource = [];

  const searchDataSource = [];

  const filteredData = myData?.filter(data => data?.entityType ? data?.entityType === "CLIENT" : data);
  filteredData.forEach((customer) => {
    const {
      id,
      firstname = "",
      lastname = "",
      externalId = "",
      accountNo = "",
      officeName = "",
      active,
      timeline = "",
      staffName = "",
      mobileNo = "",
      legalForm,
      fullname = "",
      entityName = "",
      entityAccountNo = "",
      entityMobileNo = "",
      entityExternalId = "",
      parentName = "",
      entityId = "",
      entityStatus = {},
    } = customer;

    dataSource.push({
      key: !!id ? id : entityId,
      name: (
        <div className="d-flex align-items-center">
          <GetCustomerImage id={id} />
          {!!entityName
            ? entityName
            : !!legalForm && legalForm.id === 2 && !!fullname
            ? fullname
            : `${firstname} ${lastname}`}{" "}
        </div>
      ),
      accountNumber: !!accountNo ? accountNo : entityAccountNo,
      externalId: !!externalId ? externalId : entityExternalId,
      mobileNo: !!mobileNo ? mobileNo : entityMobileNo,
      submittedDate: !!timeline ? formatDate(timeline.submittedOnDate) : null,
      status: handleCustomerStatus(entityStatus, active),
      office: !!officeName ? officeName : parentName,
      staff: staffName,
    });
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 160,
      ellipsis: true,
    },
    {
      title: "CIF Number",
      dataIndex: "accountNumber",
      key: "accountNumber",
    },
    {
      title: "External ID",
      dataIndex: "externalId",
      key: "externalId",
      width: 140,
      ellipsis: true,
    },
    { title: "Mobile", dataIndex: "mobileNo", key: "mobileNo" },
    {
      title: "Submitted Date",
      dataIndex: "submittedDate",
      key: "submittedDate",
    },
    { title: "Status", dataIndex: "status", key: "status" },
    { title: "Office", dataIndex: "office", key: "office" },
    { title: "Assigned Staff", dataIndex: "staff", key: "staff" },
  ];

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between pt-3">
          <div className="col-sm-12 col-md-4">
            <h3 className="page-title">Customers</h3>
          </div>
          <div className="col-sm-12 col-md-6 px-0">
            <div className="row" style={{ maxWidth: "100%" }}>
              <div className="col-sm-12 col-md-6 pr-2">
                <form onSubmit={(e) => onFiltercustomer(e)}>
                  <InputGroup>
                    <InputLeftElement
                      width="3.1rem"
                      height="38px"
                      pointerEvents="none"
                    >
                      <img
                        src={
                          require("../../../assets/images/icons/search.svg")
                            .default
                        }
                        style={{ height: "30px" }}
                        className="icon-img"
                        alt="Icon"
                      />
                    </InputLeftElement>
                    <Input
                      style={{
                        background: "#ffffff",
                        height: "35px !important",
                      }}
                      className="general-font-size"
                      type="text"
                      placeholder="Search by name/account no./external ID"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </InputGroup>
                </form>
              </div>
              {/* New Client */}
              <div className="col-sm-12 col-md-6 p-0 d-flex">
                {CheckPermission("CREATE_CLIENT", permissions) && (
                  <ActionButton className="form-control general-font-size">
                    <Link to="/core/new-client">
                      <img
                        src={
                          require("../../../assets/images/icons/add.svg")
                            .default
                        }
                        className="d-inline mr-3 icon-img"
                        alt="icon"
                      />
                      New Client
                    </Link>
                  </ActionButton>
                )}
                {CheckPermission("READ_CLIENT", permissions) && (
                  <NewActionButton
                    className="form-control general-font-size"
                    onClick={handleRefresh}
                  >
                    <img
                      src={
                        require("../../../assets/images/icons/new-refresh.svg")
                          .default
                      }
                      className="d-inline mr-3 icon-img"
                      alt="icon"
                    />
                    Refresh Clients
                  </NewActionButton>
                )}
              </div>
            </div>
            <ActionButtonsGroup></ActionButtonsGroup>
          </div>
        </div>
        <ContentWrapper>
          {isLoading ? (
            <CustomItemLoader />
          ) : (
            <>
              {search ? (
                <Table
                  columns={columns}
                  dataSource={dataSource}
                  pagination={pagination}
                  defaultPageSize={13}
                  loading={isTableLoading}
                  onChange={handleSearchTableChange}
                  onRow={(r) => ({
                    onClick: () => history.push(`/core/client/${r.key}`),
                  })}
                />
              ) : (
                <>
                  <Table
                    columns={columns}
                    dataSource={dataSource}
                    pagination={pagination}
                    defaultPageSize={13}
                    loading={isTableLoading}
                    onChange={handleTableChange}
                    onRow={(r) => ({
                      onClick: () => history.push(`/core/client/${r.key}`),
                    })}
                  />
                </>
              )}
            </>
          )}
        </ContentWrapper>
      </div>
    </>
  );
};

export default CustomerView;
