import React from "react";
import { connect, useDispatch } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import ReportNotification from "../components/ReportNotification";
import AuthModule from "../modules/Auth.module";
import config from "../config";

import { logoutUser } from "../actions/authActions";

export const PrivateRoute = (props) => {
  const dispatch = useDispatch();

  const { isAuthenticated, component: Component, ...rest } = props;

  let interfaceManager = AuthModule.getInterfaceManager();

  const hanldeInterface = (props) => {
    if (interfaceManager === "ds67&fdsfsd8986*&dg#@J5fsdf") {
      return (
        <div>
          {/* <OtherComponent {...props} /> */}
          <Redirect
            to={{ pathname: "/teller", state: { from: props.location } }}
          />
        </div>
      );
    } else if (interfaceManager === "df3!!4sdfdsf2355@dfg156!/sdfsdfsdf") {
      return (
        <div>
          <ReportNotification {...props} />
          <Component {...props} />
        </div>
      );
    } else if (config.apiGateway.IS_PRODUCTION === `false`) {
      return (
        <div>
          <ReportNotification {...props} />
          <Component {...props} />
        </div>
      );
    } else {
      dispatch(logoutUser());
      // Logout and direct to login page
    }
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          hanldeInterface(props)
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: !!state.userLogin.isAuthenticated,
});

export default connect(mapStateToProps)(PrivateRoute);
