import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { cancelSearchModal } from "../../actions/utilityActions";

import Loader from "./Loader";

import { ContentWrapper } from "./style";

const GeneralSearch = ({ history }) => {
  const dispatch = useDispatch();
  const generalSearchQuery = useSelector((state) => state.generalSearchQuery);
  const isGeneralSearchingLoding = useSelector(
    (state) => state.isGeneralSearchingLoding.isGeneralSearchingLoaing
  );

  let { searchQuery = [] } = generalSearchQuery || {};

  const newSearchQuery = searchQuery.pageItems;

  const handleCancelModal = () => {
    dispatch(cancelSearchModal());
  };

  return (
    <>
      <div className="search-panel">
        <ContentWrapper>
          <div className="row relative">
            <p className="search-header">Search Results</p>
            <div
              className="cancel-search"
              onClick={(e) => {
                e.preventDefault();
                handleCancelModal();
              }}
            >
              <img
                src={require("../../assets/images/icons/cancel.svg").default}
                className="detail-cancel-icon"
                alt="cancel icon"
              />
            </div>
          </div>
          <div className="row wc-modal">
            {isGeneralSearchingLoding ? (
              <Loader />
            ) : !!newSearchQuery?.length ? (
              newSearchQuery?.map(
                (
                  {
                    entityId,
                    entityType,
                    entityName,
                    entityAccountNo,
                    parentName,
                  },
                  index
                ) => {
                  return (
                    <>
                    <div
                      className="col-sm-12 col-md-6 col-lg-3 mt-2"
                      onClick={(e) => {
                        e.preventDefault();
                        handleCancelModal();
                      }}
                      key={index}
                    >
                      {entityType === "SAVING" ? (
                        <Link to={`/core/client/savings-account/${entityId}`} style={{pointerEvents: 'none'}} disabled="true">
                          <div className="search-card">
                            <p>Name: {entityName}</p>
                            <p>ACC: {entityAccountNo}</p>
                            <p>Entity: {entityType}</p>
                            <p>Client: {parentName}</p>
                          </div>
                        </Link>
                      ) : (
                        ""
                      )}{" "}
                      {entityType === "CLIENT" ? (
                        <Link to={`/core/client/${entityId}`}>
                          <div className="search-card">
                            <p>Name: {entityName}</p>
                            <p>ACC: {entityAccountNo}</p>
                            <p>Entity: {entityType}</p>
                            <p>Office: {parentName}</p>
                          </div>
                        </Link>
                      ) : (
                        ""
                      )}{" "}
                      {entityType === "LOAN" ? (
                        <Link to={`/core/client/loan/${entityId}`} style={{pointerEvents: 'none'}} disabled="true">
                          <div className="search-card" aria-disabled="true">
                            <p>Name: {entityName}</p>
                            <p>ACC: {entityAccountNo} </p>
                            <p>Entity: {entityType}</p>
                            <p>Client: {parentName}</p>
                          </div>
                        </Link>
                      ) : (
                        ""
                      )}
                    </div>
                    </>
                  );
                }
              )
            ) : (
              " Kayodxe"
            )}
          </div>
        </ContentWrapper>
      </div>
    </>
  );
};

export default GeneralSearch;
