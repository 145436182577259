import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "@chakra-ui/react";
import {
  addFinancialActivityMappings,
  editFinancialActivityMappings,
} from "../../../../../actions/accountingActions";
import Toast from "../../../../../util/Toast";
import { FormClass } from "../styles";
import { ActionButton, FormElements } from "../../styles";

import {
  camelCaseToSentence,
  textToUpperCase,
} from "../../../../../util/Tools";

const AddFinancialActivityModal = ({
  history,
  onHide,
  financialActivityTemplate,
  name,
  id,
  financialActivityId,
  mappedGLAccountType,
}) => {
  const dispatch = useDispatch();

  let { financialActivityOptions, glAccountOptions } =
    financialActivityTemplate || {};

  let toEditActivity = {};
  let toEditId = financialActivityId;
  let toEditMappedGLAccountType = mappedGLAccountType;

  financialActivityOptions.forEach((option) => {
    let { id } = option;
    if (toEditId === id) {
      toEditActivity = option;
    }
  });

  const [financialActivity, setFinancialActivity] = useState(
    !!name && !!id ? toEditActivity.name : "Select Financial Activity"
  );
  const [accountInfo, setAccountInfo] = useState("");

  let theMainGlAccountType = "";

  if (!!financialActivity) {
    financialActivityOptions.forEach((option) => {
      let { id, mappedGLAccountType } = option;

      if (`${id}` === financialActivity) {
        theMainGlAccountType = mappedGLAccountType;
      } else if (!!name && !!id) {
        theMainGlAccountType = toEditMappedGLAccountType;
      }
    });
  }

  let {
    assetAccountOptions = [],
    expenseAccountOptions = [],
    liabilityAccountOptions = [],
    equityAccountOptions = [],
  } = glAccountOptions || {};

  let theAccountDetail = [];

  if (theMainGlAccountType === "ASSET") {
    theAccountDetail = assetAccountOptions;
  } else if (theMainGlAccountType === "LIABILITY") {
    theAccountDetail = liabilityAccountOptions;
  } else if (theMainGlAccountType === "EQUITY") {
    theAccountDetail = equityAccountOptions;
  } else if (theMainGlAccountType === "EXPENSE") {
    theAccountDetail = expenseAccountOptions;
  }

  const handleAddFinancialActivityMapping = (e) => {
    e.preventDefault();
    if (!financialActivity) {
      Toast({ text: "Please select a FinancialActivity" });
    } else if (!accountInfo) {
      Toast({ text: "Please select an Account Information" });
    } else {
      let finActivityToAdd = {
        financialActivityId: financialActivity,
        glAccountId: accountInfo,
      };
      if (!!name && !!id) {
        let dataToAdd = {
          financialActivityId: `${toEditId}`,
          glAccountId: accountInfo,
        };
        dispatch(editFinancialActivityMappings(dataToAdd, id, history));
        onHide();
      } else {
        dispatch(addFinancialActivityMappings(finActivityToAdd, history));
        onHide();
      }
    }
  };
  return (
    <>
      <Modal.Header>
        <Modal.Title>
          {!!name ? name : "Add"} Financial Activity Mapping
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <FormElements style={{ width: "100%" }}>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Financial activity*</label>
              <Select
                placeholder="Select Financial Activity"
                size="md"
                disabled={!!name && !!id ? true : false}
                name="financialActivity"
                value={financialActivity}
                onChange={(e) => setFinancialActivity(e.target.value)}
              >
                {!!financialActivityOptions ? (
                  financialActivityOptions.map(
                    ({ id, name, mappedGLAccountType }) => {
                      return (
                        <option key={id} value={id}>
                          {camelCaseToSentence(name)}
                        </option>
                      );
                    }
                  )
                ) : (
                  <option>Loading</option>
                )}
              </Select>
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Account Details</label>
              <Select
                placeholder="Select Account Info"
                size="md"
                name="accountInfo"
                value={accountInfo}
                onChange={(e) => setAccountInfo(e.target.value)}
              >
                {!!theAccountDetail ? (
                  theAccountDetail.map(({ id, name, glCode }) => {
                    return (
                      <option key={id} value={id}>
                        {textToUpperCase(name)}({glCode})
                      </option>
                    );
                  })
                ) : (
                  <option></option>
                )}
              </Select>
            </div>
          </FormElements>
        </FormClass>
        <div className="float-right mt-4">
          <ActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require("../../../../../assets/images/icons/cancel.svg").default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={(e) => handleAddFinancialActivityMapping(e)}
          >
            <img
              src={
                require("../../../../../assets/images/icons/save.svg").default
              }
              alt="submit icon"
            />
            Submit
          </ActionButton>
        </div>
      </Modal.Body>
    </>
  );
};

export default AddFinancialActivityModal;
