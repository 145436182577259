import config from "../../config";
import axiosData from "../axiosData";

// CHECKER

export const pendingRescheduleLoans = (dataToSend) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/rescheduleloans?command=pending`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getMakerCheckersSearchTemplate = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/makercheckers/searchtemplate`,
        params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getMakerCheckers = (
  actionType,
  makerDateTimeFrom,
  makerDateTimeTo,
  userId
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/makercheckers?${
          !!actionType ? `&actionName=${!!actionType ? actionType : ""}` : ""
        }${
          !!makerDateTimeFrom
            ? `&makerDateTimeFrom=${
                !!makerDateTimeFrom ? makerDateTimeFrom : ""
              }`
            : ""
        }${
          !!makerDateTimeTo
            ? `&makerDateTimeTo=${!!makerDateTimeTo ? makerDateTimeTo : ""}`
            : ""
        }${!!userId ? `&userId=${!!userId ? userId : ""}` : ""}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const getCheckerInboxDetails = (id) => {
  // https://core.staging.woodcoreapp.com/woodcore/api/v1/audits/4970
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/audits/${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getClients = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/clients?limit=1000&sqlSearch=c.status_enum%3D100`,
        params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getLoans = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/loans?limit=1000&sqlSearch=l.loan_status_id+in+(100,200)`,
        params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const processChecker = (dataToSend) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/batches`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const processMakerChecker = (id, command, dataToSend) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: `${command !== "delete" ? "POST" : "DELETE"}`,
        // signal: cancelToken ? cancelToken : false,
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/makercheckers/${id}?command=${command}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
