import React, { useState, useEffect } from 'react';
import { Table, Modal, Button, DatePicker, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { formatCurrency } from '../../../../../util/FormatAmount';
import ModalContainer from '../../../components/ModalContainer';
import TransactionDetailsModal from './TransactionDetailsModal';
import {
  getSavingsAccountTransactions,
  getSavingsAccountStatementAction,
  resetTransactionReceipt,
} from "../../../../../actions/SavingsAndLoanActions";
import config from "../../../../../config";
import AuthModule from "../../../../../modules/Auth.module";

import { IconButton } from "../styles";
import { ActionAnchorButton } from "../../styles";
import LedgerDetailsModal from '../../CustomerFixedDepositAccount/Transactions/TransactionDetailsModal/ledgermodal';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { ReactComponent as ThreeDots } from "../../../../../assets/images/icons/three-dots.svg";
import { formatDate } from '../../../../../util/FormatDate';

const columns = [
  { title: "ID", dataIndex: "id", key: "id" },
  { title: "Transaction Date", dataIndex: "transDate", key: "transDate" },
  { title: "Account Number", dataIndex: "accountNo", key: "accountNo" },
  {
    title: "Transaction Type",
    dataIndex: "transactionType",
    key: "transactionType",
  },
  {
    title: "Narration",
    dataIndex: "narration",
    key: "narration",
  },
  { title: "Currency", dataIndex: "currencyCode", key: "currencyCode" },
  // { title: "Reversal", dataIndex: "reversed", key: "reversed" },
  { title: "Debit", dataIndex: "debit", key: "debit" },
  { title: "Credit", dataIndex: "credit", key: "credit" },
  {
    title: "Balance ",
    dataIndex: "balance",
    key: "balance",
  },
  { title: "", dataIndex: "actions", key: "actions" },

];

const Transactions = ({ transactions = [], history, id }) => {
  const dispatch = useDispatch();
  const savingsAndLoan = useSelector((state) => state.savingsAndLoan);
  const state = useSelector((state) => state);
  const isTableLoading = useSelector(
    (state) => state.isTableLoading.isTableLoading
  );

  let { savingsAccountTransactions = {}, savingsTransactionReceipt } =
    savingsAndLoan || {};
  let { pageItems = [], totalFilteredRecords } =
    savingsAccountTransactions || {};

  let transactionData = [];

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
  });

  const openPdfNewTab = (file) => {
    setTimeout(() => {
      window.open(file);
    }, 3000);
  };


  useEffect(() => {
    const file = savingsTransactionReceipt?.file;
    if (
      savingsTransactionReceipt?.status === "Done" &&
      !!savingsTransactionReceipt?.file
    ) {
      return [openPdfNewTab(file), dispatch(resetTransactionReceipt())];
    }
  }, [dispatch, savingsTransactionReceipt]);

  const [currentPagination, setCurrentPagination] = useState(1);

  const [filterDate, setFilterDate] = useState([]);
  const [myData, setMyData] = useState([]);
  const [amount, setAmount] = useState("");

  useEffect(() => {
    if (!!savingsAccountTransactions.length) {
      setMyData(savingsAccountTransactions);
    } else if (!!pageItems.length) {
      setMyData(pageItems);
    }
  }, [pageItems, savingsAccountTransactions]);

  const handleFilterDate = (value, dateString) => {
    setFilterDate(dateString);
  };

  const [currentView, setCurrentView] = useState(1);
  const [currentTransId, setCurrentTransId] = useState();

  const RenderTooltip = ({ text, id, children }) => (
    <>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id={`tooltip-${id}`}>{text}</Tooltip>}
      >
        {children}
      </OverlayTrigger>
    </>
  );

  const handleSubmitFilter = () => {
    let startDate = !!filterDate.length
      ? filterDate[0].replaceAll("-", "/")
      : "";
    let endDate = !!filterDate.length ? filterDate[1].replaceAll("-", "/") : "";
    let dataToSend = !!amount
      ? `?offset=0&limit=5`
      : `/between?startDate=${startDate}&endDate=${endDate}&orderBy=transactionId&sortOrder=DESC`;
    let amountFilter = !!amount
      ? {
        amount,
        type: 1,
      }
      : {};
    dispatch(getSavingsAccountTransactions(id, amountFilter, dataToSend));
  };

  useEffect(() => {
    if (totalFilteredRecords) {
      setPagination({
        current: currentPagination,
        pageSize: 5,
        total: totalFilteredRecords,
      });
    }
  }, [totalFilteredRecords, currentPagination]);

  const handleTableChange = (pagination, filters, sorter) => {
    setCurrentPagination(pagination.current);
    let dataToSend = `?offset=${(pagination.current - 1) * 5
      }&limit=5&orderBy=transactionId&sortOrder=DESC`;
    dispatch(getSavingsAccountTransactions(id, {}, dataToSend));
  };

  useEffect(() => {
    dispatch(
      getSavingsAccountTransactions(
        id,
        {},
        "?offset=0&limit5&orderBy=transactionId&sortOrder=DESC"
      )
    );
  }, [id, dispatch]);


  const [modalState, toggleModal] = useState(false);
  const [modalContentName, setModalContentName] = useState("");

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOk = () => {
    handleSubmitFilter();
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  myData.forEach((transaction) => {
    const {
      runningBalance,
      accountNo,
      // reversed,
      amount,
      id,
      currency = {},
      transactionType = {},
      date = [],
      transfer,
    } = transaction || {};

    transactionData.push({
      id: id,
      transDate: formatDate(date),
      accountNo,
      currencyCode: currency.code,
      debit:
        transactionType.withdrawal ||
          transactionType.feeDeduction ||
          transactionType.dividendPayout ||
          transactionType.initiateTransfer
          ? `${currency.code} ${formatCurrency(amount)}`
          : "",
      credit:
        transactionType.deposit ||
          transactionType.interestPosting ||
          transactionType.amountRelease
          ? `${currency.code} ${formatCurrency(amount)}`
          : "",
      transactionType: transactionType.value,
      narration: transaction?.note || transfer?.transferDescription,
      balance: `${currency.code} ${formatCurrency(runningBalance)}`,
      actions: (
        <>
          <Menu direction="rtl">
            {({ isOpen }) => (
              <>
                <MenuButton transition="all 0.2s">
                  <ThreeDots />
                </MenuButton>
                <MenuList className="menu-list-teller">
                  <MenuItem
                    onClick={() => handleViewTransactions(transaction)}
                    className="text-label"
                  >
                    View
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleViewLedgerTransactions(transaction)}
                    className="text-label"
                  >
                    Ledger details
                  </MenuItem>

                  <MenuItem
                    onClick={() => dispatch(getSavingsAccountStatementAction(id))}
                    className="text-label"
                  >
                    Download receipt
                  </MenuItem>
                </MenuList>
              </>
            )}
          </Menu></>
      ),
      transaction,
    });
  });

  const handleRefresh = () => {
    dispatch(
      getSavingsAccountTransactions(
        id,
        {},
        "?offset=0&limit=5&orderBy=transactionId&sortOrder=DESC"
      )
    );
    setPagination({
      current: 1,
      pageSize: 5,
    });
  };

  const handleOnHide = () => {
    toggleModal(false);
  };

  const handleModalClose = () => toggleModal(false);
  const displayModalContent = () => {
    switch (modalContentName) {
      case "viewTransaction":
        return <TransactionDetailsModal transactionDetails={transactionDetails} onHide={handleModalClose} />;
      case "ledgerTransaction":
        return (
          <LedgerDetailsModal
            transactions={transactionDetails}
            onHide={handleModalClose}
            module={'savings'}
          />
        );
      default:
        return;
    }
  };

  const handleViewTransactions = (transaction) => {
    setTransactionDetails(transaction);
    setCurrentTransId(transaction.id);
    setModalContentName("viewTransaction");
    toggleModal(true);
  };
  const handleViewLedgerTransactions = (transaction) => {
    setTransactionDetails(transaction);
    setCurrentTransId(transaction.id);
    setModalContentName("ledgerTransaction");
    toggleModal(true);
  };

  const [transactionDetails, setTransactionDetails] = useState({});

  const { RangePicker } = DatePicker;

  return (
    <>
      <ModalContainer show={modalState}>
        {modalState && displayModalContent()}
      </ModalContainer>
      <Modal
        title="Filter Transactions"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isTableLoading}
            onClick={handleOk}
          >
            Filter
          </Button>,
        ]}
      >
        <RangePicker
          // value={filterDate}
          onChange={handleFilterDate}
          dateFormat="YYYY/MM/DD"
        />
        <Input
          className="mt-4"
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          placeholder="Amount"
        />
      </Modal>
      {/* Refresh Transactions */}
      <div className="spaceBetweenFlexEnd">
        <div className="float-right">
          <ActionAnchorButton
            onClick={(e) => {
              e.preventDefault();
              handleRefresh();
            }}
            color="#2C1DFF"
          >
            Refresh Transactions
          </ActionAnchorButton>
        </div>

        {/* <div className="my-4 ml-4" onClick={showModal}> */}
        {/* <div className="btn-small white">Filter</div> */}
        {/* </div> */}
      </div>
      {/* {currentView === 1 ? ( */}
      <>
        <Table
          style={{
            overflow: "hidden",
            overflowX: "scroll",
          }}
          columns={columns}
          dataSource={transactionData}
          pagination={pagination}
          defaultPageSize={5}
          loading={false}
          onChange={handleTableChange}
        />
      </>
      {/* ) : (
        <></>
      )} */}

      {currentView === 2 ? (
        <>
          <p className="BackPointer" onClick={(e) => setCurrentView(1)}>
            Go Back
          </p>
          <object
            data={`${config.apiGateway.BASE_API_URL
              }/runreports/Savings%20Transaction%20Receipt?output-type=PDF&tenantIdentifier=${config.apiGateway.IS_PRODUCTION === `true`
                ? AuthModule.getTenant()
                : config.apiGateway.WOODCORE_TENANT
              }&locale=en&R_transactionId=${currentTransId}`}
            type="application/pdf"
            width="100%"
            height="900"
          >
            <p>
              You don't have a PDF plugin, but you can
              <a
                href={`${config.apiGateway.BASE_API_URL
                  }/runreports/Savings%20Transaction%20Receipt?output-type=PDF&tenantIdentifier=${config.apiGateway.IS_PRODUCTION === `true`
                    ? AuthModule.getTenant()
                    : config.apiGateway.WOODCORE_TENANT
                  }&locale=en&R_transactionId=${currentTransId}`}
              >
                download the PDF file.
              </a>
            </p>
          </object>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default Transactions;
