import styled from "styled-components";

export const ActionButtonsGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  select {
    // background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    border-radius: 3px;
    height: 50px;
    min-width: 100px;
    border: none;
    // margin: ;
  }
`;

export const ContentWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 0px #ededf6;
  min-height: 70vh;
  margin-top: 20px;
  table {
    background: #ffffff;
    border: 1px solid rgba(46, 91, 255, 0.08) !important;
    box-sizing: border-box;
    border-radius: 1px;

    .md-font-weight {
      
      font-style: normal;
      font-size: 1rem;
      line-height: 20px;
    }

    td {
      
      font-size: 1rem;
      font-weight: 400;
      line-height: 19px;
      color: #2e384d;
      vertical-align: middle;
    }

    }
  }
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #f7f8fc;
  }
`;

export const IconButton = styled.button`
  width: 50px;
  height: 50px;
  background: #2C1DFF;
  text-align: center;
  border-radius: 5px;
  img {
    height: 16px;
    display: inline;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;
