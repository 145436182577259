import React, { useState } from "react";
import {
  ContentWrapper,
  FormClass,
  ActionButton,
  FormElements,
} from "./styles";
import { Select } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const PasswordPreferenceView = () => {
  const [passwordPreference, setPasswordPreference] = useState({
    passwordCharacter: "",
    passwordLength: "",
  });
  const workingDaysChangeHandler = (e) =>
    setPasswordPreference({
      ...passwordPreference,
      [e.target.name]: e.target.value,
    });

  let { passwordCharacter, passwordLength } = passwordPreference;
  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Password Preference</h3>
            <p className="sub-title">
              Define standards for enforcing the usage of stronger password
            </p>
            <p className="font-weight sub-title mt-2">
              <span className="text-info">
                <Link to="/core/configuration">Configuration</Link> /{" "}
                <Link to="/core/configuration/organization">Utilities</Link>
              </span>{" "}
              / Password Preference
            </p>
          </div>
        </div>
        <ContentWrapper>
          <FormClass className="h-100 container">
            <FormElements>
              <div
                className="form-group row m-0"
                style={{ paddingTop: "20px" }}
              ></div>

              <div className=" h-100" style={{ marginTop: 50 }}>
                <div className="form-group justify-content-between align-items-center">
                  <label className="text-label">
                    Password must be at least 1 character and not more that 50
                    characters long
                  </label>
                  <Select
                    placeholder="Select Yes or No"
                    size="md"
                    name="passwordCharacter"
                    value={passwordCharacter}
                    onChange={workingDaysChangeHandler}
                  >
                    <option>Yes</option>
                    <option>No</option>
                  </Select>
                </div>

                <div className="form-group justify-content-between align-items-center">
                  <label className="text-label">
                    Password must be at least 6 characters, no more than 50
                    characters long, must include at least one upper case
                    letter, one lower case letter, one numeric digit and no
                    space
                  </label>
                  <Select
                    placeholder="Select Yes or No"
                    size="md"
                    name="passwordLength"
                    value={passwordLength}
                    onChange={workingDaysChangeHandler}
                  >
                    <option>Yes</option>
                    <option>No</option>
                  </Select>
                </div>
              </div>
              <div className="float-right h-100 mt-5">
                <ActionButton shadow className="mb-2" color="#2C1DFF">
                  <img
                    src={
                      require("../../../../../assets/images/icons/cancel.svg")
                        .default
                    }
                    alt="cancel"
                  />
                  Cancel
                </ActionButton>
                <ActionButton className="mr-0" shadow color="#2C1DFF">
                  <img
                    src={
                      require("../../../../../assets/images/icons/save.svg")
                        .default
                    }
                    alt="save"
                  />
                  Submit
                </ActionButton>
              </div>
              <br />
              <br />
            </FormElements>
          </FormClass>
        </ContentWrapper>
      </div>
    </>
  );
};

export default PasswordPreferenceView;
