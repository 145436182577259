import React, { useEffect, useRef, useState } from "react";
import {
  ContentWrapper,
  ActionButtonsGroup,
  ActionButton,
  StatusBox,
  EditButton,
  DeleteButton,
  IconButton,
} from "./styles";
import { InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import ModalContainer from "../../components/ModalContainer";
import { useDispatch, useSelector } from "react-redux";
import DeleteCharge from "./DeleteCharge";
import ChargeDetails from "./ChargeDetails";

import {
  getAllCharges,
  filterCharges,
  clearFilteredCharges,
  resetCharge,
} from "../../../../actions/configurationActions";

import Toast from "../../../../util/Toast";
import TableLoader from "./TableLoader";
import CheckPermission from "../../../../util/CheckPermission";

const ChargesView = ({ history }) => {
  const [modalState, toggleModal] = useState(false);
  const [chargeId, setChargeId] = useState("");
  const dispatch = useDispatch();

  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  const chargeList = useSelector((state) => state.chargesReducer);

  const { loading, charges, filtered } = chargeList;
  charges.sort((a, b) => (a.name > b.name ? 1 : -1));

  const search = useRef("");
  const onFilterCharges = (e) => {
    search.current.value !== ""
      ? dispatch(filterCharges(e.target.value))
      : dispatch(clearFilteredCharges());
  };
  useEffect(() => {
    dispatch(clearFilteredCharges());
    dispatch(getAllCharges());
    dispatch(resetCharge());
    if (filtered === null) {
      search.current.value = "";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChargeDelete = (id) => {
    setChargeId(id);
    toggleModal(true);
  };
  const handleModalClose = () => {
    toggleModal(false);
    setshowDt(false);
    setChargeId("");
  };

  if (filtered !== null && filtered.length === 0) {
    Toast({
      text: "No charge found",
      icon: "error",
      timer: 2000,
    });
  }

  //Table configuration

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Charges applied to?", dataIndex: "charges", key: "charges" },
    { title: "Is penalty?", dataIndex: "isPen", key: "isPen" },
    { title: "Status", dataIndex: "status", key: "status" },
    { title: "", dataIndex: "edit", key: "edit" },
    { title: "", dataIndex: "delete", key: "delete" },
  ];

  const filteredCharges = [];
  const dataSource = [];
  if (filtered !== null) {
    filtered.forEach((chg) => {
      const { id, penalty, chargeAppliesTo, name, active } = chg;
      filteredCharges.push({
        chg,
        key: id,
        name,
        charges: chargeAppliesTo.value,
        isPen: penalty ? "True" : "False",
        status: (
          <>
            {active ? (
              <span className="new-text-success">Active</span>
            ) : (
              <span className="new-text-danger">Inactive</span>
            )}
          </>
        ),
        edit: (
          <>
            {CheckPermission("UPDATE_CHARGE", permissions) ? (
              <EditButton
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(`charges/edit-charges/${id}`);
                }}
              >
                Edit
              </EditButton>
            ) : null}
          </>
        ),
        delete: (
          <>
            {CheckPermission("DELETE_CHARGE", permissions) ? (
              <DeleteButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleChargeDelete(id);
                }}
              >
                Delete
              </DeleteButton>
            ) : null}
          </>
        ),
      });
    });
  }

  !loading &&
    charges.forEach((chg) => {
      const { id, penalty, chargeAppliesTo, name, active } = chg;
      dataSource.push({
        chg,
        key: id,
        name,
        charges: chargeAppliesTo.value,
        isPen: penalty ? "True" : "False",
        status: (
          <>
            {active ? (
              <span className="new-text-success">Active</span>
            ) : (
              <span className="new-text-danger">Inactive</span>
            )}
          </>
        ),
        edit: (
          <>
            {CheckPermission("UPDATE_CHARGE", permissions) ? (
              <EditButton
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(`charges/edit-charges/${id}`);
                }}
              >
                Edit
              </EditButton>
            ) : null}
          </>
        ),
        delete: (
          <>
            {CheckPermission("DELETE_CHARGE", permissions) ? (
              <DeleteButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleChargeDelete(id);
                }}
              >
                Delete
              </DeleteButton>
            ) : null}
          </>
        ),
      });
    });

  const [showDt, setshowDt] = useState(false);
  const [currentCharge, setCurrentCharge] = useState({});

  const setShowDetails = () => {
    toggleModal(!modalState);
    setshowDt(!showDt);
  };

  return (
    <>
      <ModalContainer show={modalState} dialogClassName="document_infoModal">
        {modalState && !!chargeId && (
          <DeleteCharge onHide={handleModalClose} id={chargeId} />
        )}{" "}
        {modalState && !!showDt && (
          <ChargeDetails
            onHide={() => setShowDetails()}
            currentCharge={currentCharge}
          />
        )}
      </ModalContainer>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Charges</h3>
            <p className="sub-title">
              Define charges for Loan, Savings and Deposit chargse
            </p>
            {/* <p className="font-weight sub-title mt-2">
              <span className="text-info">
                <Link to="/core/configuration">Configuration</Link> /{" "}
                <Link to="/core/configuration/products">Products</Link>
              </span>{" "}
              / Charges
            </p> */}
          </div>
          <div className="col-sm-12 col-md-6 px-0 pt-3">
            <ActionButtonsGroup>
              <div className="col-sm-12 col-md-6 pr-2">
                <InputGroup>
                  <InputLeftElement
                    width="3.1rem"
                    height="38px"
                    pointerEvents="none"
                  >
                    <img
                      src={
                        require("../../../../assets/images/icons/search.svg")
                          .default
                      }
                      style={{ height: "30px" }}
                      alt="Icon"
                      className="icon-img"
                    />
                  </InputLeftElement>
                  <Input
                    style={{
                      background: "#ffffff",
                      height: "35px",
                    }}
                    ref={search}
                    onChange={onFilterCharges}
                    type="text"
                    className="general-font-size"
                    placeholder="Search by name"
                  />
                </InputGroup>
              </div>
              <div className="col-sm-12 col-md-6 p-0 d-flex">
                <Link to="charges/create-charges">
                  {CheckPermission("CREATE_CHARGE", permissions) && (
                    <ActionButton>
                      <img
                        src={
                          require("../../../../assets/images/icons/add.svg")
                            .default
                        }
                        alt="add"
                      />
                      Create Charges
                    </ActionButton>
                  )}
                </Link>
              </div>
            </ActionButtonsGroup>
          </div>
        </div>
        <ContentWrapper>
          {loading ? (
            <TableLoader />
          ) : (
            <>
              {filtered !== null ? (
                <Table
                  columns={columns}
                  dataSource={filteredCharges}
                  onRow={(r) => ({
                    onClick: () => {
                      setShowDetails();
                      setCurrentCharge(r.chg);
                    },
                  })}
                />
              ) : (
                <Table
                  columns={columns}
                  dataSource={dataSource}
                  pagination={{ defaultPageSize: 13 }}
                  defaultPageSize={13}
                  onRow={(r) => ({
                    onClick: () => {
                      setShowDetails();
                      setCurrentCharge(r.chg);
                    },
                  })}
                />
              )}
            </>
          )}
        </ContentWrapper>
      </div>
    </>
  );
};

export default ChargesView;
