import React, { useEffect, useState } from "react";
import { ContentWrapper, ContentCard } from "./styles";
import LoadingScreen from "./LoadingScreen";
import { Link } from "react-router-dom";

const CardListings = [
  {
    icon: require("../../../assets/images/icons/termii.svg").default,
    title: "Termii",
    description: "Define loan provisioning criteria for organization",
    route: "/core/notification/termii",
  },
];

const NotificationView = () => {
  const [loading, setloading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setloading(false);
    }, 1000);
  }, []);

  return !!loading ? (
    <LoadingScreen />
  ) : (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Notification services</h3>
            <p className="sub-title">
              Customize your own alert messages for your customers.
            </p>
            {/* <p className="font-weight sub-title mt-2">
              <span className="text-info">
                <Link to="/core/configuration">Configuration</Link>
              </span>{" "}
              / Utilities
            </p> */}
          </div>
        </div>
        <ContentWrapper>
          <div className="row">
            {CardListings.map(({ icon, title, description, route }, i) => (
              <div className="col-sm-12 col-md-4 col-lg-3 mb-4" key={i}>
                <Link to={`${route}`}>
                  <ContentCard>
                    <img className="card-img-right" src={icon} alt={title} />
                    <div className="body">
                      <p className="title">{title}</p>
                      <p className="description">{description}</p>
                    </div>
                  </ContentCard>
                </Link>
              </div>
            ))}
          </div>
        </ContentWrapper>
      </div>
    </>
  );
};

export default NotificationView;
