import React, { useState, useEffect } from "react";
import { Input } from "@chakra-ui/react";
import { ActionButton, FormElements, FormClass } from "../styles";
import { Modal, Spinner } from "react-bootstrap";
import {
  updateNotificationInfo,
  getAllNotification,
} from "../../../../../../actions/configurationActions";
import { useDispatch } from "react-redux";

const EditNotification = ({ onHide, data }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [editNotificationInfo, setEditNotificationInfo] = useState({
    name: "",
  });
  useEffect(() => {
    if (data) {
      if (data.length > 0)
        data.map((item, index) =>
          setEditNotificationInfo((p) => ({
            ...p,
            [`value${index}`]: item.value,
          }))
        );
    }
  }, [data]);

  const editNotificationHandler = (e) =>
    setEditNotificationInfo({
      ...editNotificationInfo,
      [e.target.name]: e.target.value,
    });

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    const dataToSubmit = {};
    const func = () => {
      setLoading(false);
      dispatch(getAllNotification());
      onHide();
    };
    await dispatch(updateNotificationInfo(dataToSubmit, func));
    setLoading(false);
  };
  return (
    <>
      <Modal.Header>
        <Modal.Title>Edit External Service Configuration</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <FormElements style={{ width: "100%" }}>
            {data ? (
              data.length > 0 ? (
                data.map((item, index) => (
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">{item.name}</label>
                    <Input
                      style={{ width: "60%" }}
                      size="md"
                      name={`value${index}`}
                      value={editNotificationInfo[`value${index}`]}
                      onChange={editNotificationHandler}
                    />
                  </div>
                ))
              ) : null
            ) : (
              <p className="font-weight-bold"> No Data Available</p>
            )}
          </FormElements>
          <div className="float-right mt-4">
            <ActionButton color="#2C1DFF" onClick={onHide}>
              <img
                src={
                  require("../../../../../../assets/images/icons/cancel.svg")
                    .default
                }
                alt="cancel icon"
              />
              Cancel
            </ActionButton>
            <ActionButton
              onClick={handleSubmit}
              color="#2C1DFF"
              style={{ margin: "0px 0px 0 10px" }}
              disabled={loading}
            >
              {loading ? (
                <Spinner
                  animation="border"
                  color="#fff"
                  className="mr-2 text-white"
                  size="sm"
                />
              ) : (
                <img
                  src={
                    require("../../../../../../assets/images/icons/save.svg")
                      .default
                  }
                  alt="submit icon"
                />
              )}
              Submit
            </ActionButton>
          </div>
        </FormClass>
      </Modal.Body>
    </>
  );
};

export default EditNotification;
