export const SET_LOADING_STATE = "SET_LOADING_STATE";
export const PROCESS_CHECKER = "PROCESS_CHECKER";
export const GET_LOANS_REQUEST = "GET_LOANS_REQUEST";
export const GET_LOANS_SUCCESS = "GET_LOANS_SUCCESS";
export const GET_LOANS_FAIL = "GET_LOANS_FAIL";
export const FILTER_LOANS_REQUEST = "FILTER_LOANS_REQUEST";
export const CLEAR_LOANS_FILTER = "CLEAR_LOANS_FILTER";
export const GET_CLIENTS_REQUEST = "GET_CLIENTS_REQUEST";
export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS";
export const GET_CLIENTS_FAIL = "GET_CLIENTS_FAIL";
export const FILTER_CLIENTS_REQUEST = "FILTER_CLIENTS_REQUEST";
export const CLEAR_CLIENTS_FILTER = "CLEAR_CLIENTS_FILTER";
export const GET_MAKER_CHECKER_REQUEST = "GET_MAKER_CHECKER_REQUEST";
export const GET_MAKER_CHECKER_SUCCESS = "GET_MAKER_CHECKER_SUCCESS";
export const GET_MAKER_CHECKER_FAIL = "GET_MAKER_CHECKER_FAIL";
export const FILTER_MAKER_REQUEST = "FILTER_MAKER_REQUEST";
export const CLEAR_MAKER_FILTER = "CLEAR_MAKER_FILTER";
export const GET_MAKER_CHECKER_TEMPLATE_REQUEST =
  "GET_MAKER_CHECKER_TEMPLATE_REQUEST";
export const GET_MAKER_CHECKER_TEMPLATE_SUCCESS =
  "GET_MAKER_CHECKER_TEMPLATE_SUCCESS";
export const GET_MAKER_CHECKER_TEMPLATE_FAIL =
  "GET_MAKER_CHECKER_TEMPLATE_FAIL";
export const GET_PENDING_RES_LOANS_REQUEST = "GET_PENDING_RES_LOANS_REQUEST";
export const FILTER_RES_LOANS_REQUEST = "FILTER_RES_LOANS_REQUEST";
export const CLEAR_RES_LOANS_FILTER = "CLEAR_RES_LOANS_FILTER";
export const GET_PENDING_RES_LOANS_SUCCESS = "GET_PENDING_RES_LOANS_SUCCESS";
export const GET_PENDING_RES_LOANS_FAIL = "GET_PENDING_RES_LOANS_FAIL";
export const GET_CHECKER_INBOX_DETAILS = "GET_CHECKER_INBOX_DETAILS";
export const GET_CHECKER_INBOX_DETAILS_SUCCESS =
  "GET_CHECKER_INBOX_DETAILS_SUCCESS";
export const GET_CHECKER_INBOX_DETAILS_FAIL = "GET_CHECKER_INBOX_DETAILS_FAIL";
