import React, { useState, useEffect } from "react";
import {
  ContentWrapper,
  FormClass,
  FormElements,
  ActionButton,
} from "./styles";
import { Select, FormControl, Input } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getStandingInstructionTemplate,
  getStandingInstructions,
} from "../../../../../actions/configurationActions";
import TableLoader from "./TableLoader";
import { formatDate } from "../../../../../util/FormatDate";

const StandingInstructionsHistoryView = () => {
  const dispatch = useDispatch();
  const [standingInstructions, setStandingInstructions] = useState({
    clientName: "",
    accountType: "",
    fromLoanOffice: "",
    transferType: "",
    toLoanOffice: "",
  });
  const standingInstructionsTemplate = useSelector(
    (state) => state.standingInstructionsTemplate
  );
  const standingInstructionsReducer = useSelector(
    (state) => state.standingInstructionsReducer
  );
  const { standingTemplate } = standingInstructionsTemplate;
  const { loading, standingInstruction } = standingInstructionsReducer;
  // console.log(standingInstruction);
  const [assignmentDate, setAssignmentDate] = useState(new Date() || "");
  const [endDate, setEndDate] = useState(new Date() || "");
  const [startDate, setStartDate] = useState(new Date() || "");
  const standingInstrChangeHandler = (e) =>
    setStandingInstructions({
      ...standingInstructions,
      [e.target.name]: e.target.value,
    });

  let { clientName, accountType, transferType } = standingInstructions;

  const handleSearch = () => {
    let params = {
      clientName,
      accountType,
      transferType,
      endDate: formatDate(endDate, true),
      startDate: formatDate(startDate, true),
      assignmentDate,
    };
    dispatch(getStandingInstructions(params));
  };
  useEffect(() => {
    dispatch(getStandingInstructionTemplate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-1">
            <h3 className="page-title">Standing Instructions History</h3>
            <p className="sub-title">View logged history of standing order</p>
            <p className="font-weight sub-title">
              <span className="text-info">
                {/* <Link to="/core/configuration">Configuration</Link> /{" "} */}
                <Link to="/core/configuration/organization">Utilities</Link>
              </span>{" "}
              / Standing Instructions History
            </p>
          </div>
        </div>
        <ContentWrapper>
          <FormClass className="h-100 container">
            <FormElements>
              <div className="row h-100" style={{ paddingTop: "50px" }}>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Client Name</label>
                    <Input
                      style={{ width: "60%" }}
                      size="md"
                      name="clientName"
                      value={clientName}
                      onChange={standingInstrChangeHandler}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Account Type</label>
                    <Select
                      size="md"
                      name="accountType"
                      value={accountType}
                      onChange={standingInstrChangeHandler}
                    >
                      {standingTemplate &&
                      standingTemplate.fromAccountTypeOptions
                        ? standingTemplate.fromAccountTypeOptions.map(
                            ({ value, id }) => (
                              <option key={id} value={id}>
                                {value}
                              </option>
                            )
                          )
                        : null}
                    </Select>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Transfer type</label>
                    <Select
                      size="md"
                      name="transferType"
                      value={transferType}
                      onChange={standingInstrChangeHandler}
                    >
                      {standingTemplate && standingTemplate.transferTypeOptions
                        ? standingTemplate.transferTypeOptions.map(
                            ({ value, id }) => (
                              <option key={id} value={id}>
                                {value}
                              </option>
                            )
                          )
                        : null}
                    </Select>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Assignment date</label>
                    <FormControl style={{ width: "60%" }}>
                      <div className="datepicker-100-width">
                        <DatePicker
                          style={{ width: "100%" }}
                          id="start-date"
                          selected={assignmentDate}
                          onChange={(date) => setAssignmentDate(date)}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          placeholderText="dd/mmm/yyyy"
                        />
                      </div>
                    </FormControl>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Start date</label>
                    <FormControl style={{ width: "60%" }}>
                      <div className="datepicker-100-width">
                        <DatePicker
                          style={{ width: "100%" }}
                          id="start-date"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          placeholderText="dd/mmm/yyyy"
                        />
                      </div>
                    </FormControl>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">End date</label>
                    <FormControl style={{ width: "60%" }}>
                      <div className="datepicker-100-width">
                        <DatePicker
                          style={{ width: "100%" }}
                          id="start-date"
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          placeholderText="dd/mmm/yyyy"
                        />
                      </div>
                    </FormControl>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="float-right mt-3">
                    <ActionButton
                      onClick={handleSearch}
                      color="#2C1DFF"
                      style={{ margin: "0px 0px 0 10px" }}
                    >
                      <img
                        src={
                          require("../../../../../assets/images/icons/save.svg")
                            .default
                        }
                        alt="submit icon"
                      />
                      Submit
                    </ActionButton>
                  </div>
                </div>
              </div>
              <div className="h-100 mt-5">
                {!loading ? (
                  standingInstruction.length > 0 ? (
                    <Table hover responsive={true} borderless>
                      <thead className="border-bottom">
                        <tr>
                          <th>From Client</th>
                          <th>From Account</th>
                          <th>To Client</th>
                          <th>To Account</th>
                          <th>Execution Time</th>
                          <th>Amount</th>
                          <th>Status</th>
                          <th>Error log</th>
                        </tr>
                      </thead>
                      <tbody>
                        {standingInstruction.map(
                          (
                            {
                              fromClient,
                              fromAccount,
                              toClient,
                              toAccount,
                              validFrom,
                              amount,
                              status,
                              errorLog,
                            },
                            i
                          ) => (
                            <tr key={i}>
                              <td>{fromClient.displayName}</td>
                              <td>{fromAccount.productName}r</td>
                              <td>{toClient.displayName}</td>
                              <td>{toAccount.productName}</td>
                              <td>{formatDate(validFrom)}</td>
                              <td>₦{amount}</td>
                              <td>
                                <p
                                  style={{
                                    color:
                                      status.value === "Active" || "success"
                                        ? "#1DCB43"
                                        : "#333333",
                                  }}
                                  className="text-capitalize"
                                >
                                  {status.value}
                                </p>
                              </td>
                              <td>{errorLog}</td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  ) : (
                    <p className="text-dark text-center">No Data found</p>
                  )
                ) : (
                  <TableLoader />
                )}
              </div>
              <br />
              <br />
            </FormElements>
          </FormClass>
        </ContentWrapper>
      </div>
    </>
  );
};

export default StandingInstructionsHistoryView;
