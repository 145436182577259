import React from "react";
// import { useSelector } from "react-redux";
import { Select, Input, FormControl, Checkbox } from "@chakra-ui/react";
import { Table } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import {
  FormClass,
  FormElements,
  ActionButton,
  AddActionButton,
} from "../../../styles.jsx";
import { IconButton } from "../../../../SavingsProducts/styles.jsx";

const defaultPrincipalByLC = {
  valueConditionType: "",
  borrowerCycleNumber: "",
  defaultValue: "",
  maxValue: "",
  minValue: "",
};

const LoanTerms = ({
  handleNextScreen,
  handlePreviousScreen,
  max_pricipal,
  def_pricipal,
  min_pricipal,
  setMax_pricipal,
  setDef_pricipal,
  setMin_pricipal,
  max_repayment,
  def_repayment,
  min_repayment,
  max_interest,
  def_interest,
  min_interest,
  interestSelect,
  repaid,
  repaidSelect,
  disbursal,
  updateLoanProductInfoHandler,
  template,
  termsError,
  setTermsError,
  numberOfPrincipalLC,
  setNumberOfPrincipalLC,
  numberOfRepayment,
  setNumberOfRepayment,
  numberOfNominal,
  setNumberOfNominal,
  setTerms,
  terms,
  renderTooltip,
}) => {
  //configure Principal By Loan Cycle

  const addToPrincipalLC = () => {
    setNumberOfPrincipalLC([...numberOfPrincipalLC, defaultPrincipalByLC]);
  };
  const removeFromPrincipalLC = (position) => {
    setNumberOfPrincipalLC(
      numberOfPrincipalLC.filter((item, index) => index !== position)
    );
  };
  const handlePrincipalCode = (position, value) => {
    let newPrincipalLC = [...numberOfPrincipalLC];
    newPrincipalLC[Number(position)].valueConditionType = value;
    setNumberOfPrincipalLC(newPrincipalLC);
  };
  const handlePrincipalBorrowerCycleNumber = (position, value) => {
    let newPrincipalLC = [...numberOfPrincipalLC];
    newPrincipalLC[Number(position)].borrowerCycleNumber = value;
    setNumberOfPrincipalLC(newPrincipalLC);
  };
  const handlePrincipalMinValue = (position, value) => {
    let newPrincipalLC = [...numberOfPrincipalLC];
    newPrincipalLC[Number(position)].minValue = value;
    setNumberOfPrincipalLC(newPrincipalLC);
  };
  const handlePrincipalMaxValue = (position, value) => {
    let newPrincipalLC = [...numberOfPrincipalLC];
    newPrincipalLC[Number(position)].maxValue = value;
    setNumberOfPrincipalLC(newPrincipalLC);
  };
  const handlePrincipalDefaultValue = (position, value) => {
    let newPrincipalLC = [...numberOfPrincipalLC];
    newPrincipalLC[Number(position)].defaultValue = value;
    setNumberOfPrincipalLC(newPrincipalLC);
  };

  //configure  Repayments By Loan Cycle

  const addToRepayment = () => {
    setNumberOfRepayment([...numberOfRepayment, defaultPrincipalByLC]);
  };
  const removeFromRepayment = (position) => {
    setNumberOfRepayment(
      numberOfRepayment.filter((item, index) => index !== position)
    );
  };
  const handleRepaymentCode = (position, value) => {
    let newRepayment = [...numberOfRepayment];
    newRepayment[Number(position)].valueConditionType = value;
    setNumberOfRepayment(newRepayment);
  };
  const handleRepaymentBorrowerCycleNumber = (position, value) => {
    let newRepayment = [...numberOfRepayment];
    newRepayment[Number(position)].borrowerCycleNumber = value;
    setNumberOfRepayment(newRepayment);
  };
  const handleRepaymentMinValue = (position, value) => {
    let newRepayment = [...numberOfRepayment];
    newRepayment[Number(position)].minValue = value;
    setNumberOfRepayment(newRepayment);
  };
  const handleRepaymentMaxValue = (position, value) => {
    let newRepayment = [...numberOfRepayment];
    newRepayment[Number(position)].maxValue = value;
    setNumberOfRepayment(newRepayment);
  };
  const handleRepaymentDefaultValue = (position, value) => {
    let newRepayment = [...numberOfRepayment];
    newRepayment[Number(position)].defaultValue = value;
    setNumberOfRepayment(newRepayment);
  };

  //configure Nominal Interest Rate By Loan Cycle

  const addToNominal = () => {
    setNumberOfNominal([...numberOfNominal, defaultPrincipalByLC]);
  };
  const removeFromNominal = (position) => {
    setNumberOfNominal(
      numberOfNominal.filter((item, index) => index !== position)
    );
  };
  const handleNominalCode = (position, value) => {
    let newNominal = [...numberOfNominal];
    newNominal[Number(position)].valueConditionType = value;
    setNumberOfNominal(newNominal);
  };
  const handleNominalBorrowerCycleNumber = (position, value) => {
    let newNominal = [...numberOfNominal];
    newNominal[Number(position)].borrowerCycleNumber = value;
    setNumberOfNominal(newNominal);
  };
  const handleNominalMinValue = (position, value) => {
    let newNominal = [...numberOfNominal];
    newNominal[Number(position)].minValue = value;
    setNumberOfNominal(newNominal);
  };
  const handleNominalMaxValue = (position, value) => {
    let newNominal = [...numberOfNominal];
    newNominal[Number(position)].maxValue = value;
    setNumberOfNominal(newNominal);
  };
  const handleNominalDefaultValue = (position, value) => {
    let newNominal = [...numberOfNominal];
    newNominal[Number(position)].defaultValue = value;
    setNumberOfNominal(newNominal);
  };

  return (
    <>
      <div>
        <FormClass>
          <FormElements style={{ width: "100%" }}>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label d-flex flex-align-center">
                Terms Vary Based On Loan Cycle{" "}
                {renderTooltip("Terms Vary Based On Loan Cycle")}
              </label>
              <FormControl style={{ width: "60%" }}>
                <Checkbox
                  size="md"
                  name="terms"
                  isChecked={terms}
                  onChange={(e) => setTerms(e.target.checked)}
                />
              </FormControl>
            </div>
            <div className="form-group">
              <div className="d-flex justify-content-between align-items-center">
                <label className="text-label">
                  Principal* {renderTooltip("Principal")}
                </label>
                <div className="row m-0 w-80">
                  <div className="col-md-4 mb-2">
                    {/* <Input
                      style={{
                        width: "100%",
                        borderColor:
                          !min_pricipal && termsError ? "red" : "inherit",
                      }}
                      type="number"
                      size="md"
                      placeholder="Minimum"
                      value={min_pricipal}
                      name="min_pricipal"
                      onChange={updateLoanProductInfoHandler}
                    /> */}
                    <CurrencyFormat
                      value={min_pricipal}
                      name="min_pricipal"
                      thousandSeparator={true}
                      className="amount-input"
                      placeholder="Minimum"
                      style={{
                        width: "100%",
                        borderColor:
                          !min_pricipal && termsError ? "red" : "inherit",
                      }}
                      // prefix={"$"}
                      onValueChange={(values) => {
                        const { value } = values;
                        // formattedValue = $2,223
                        // value ie, 2223
                        setMin_pricipal(value);
                      }}
                    />
                  </div>
                  <div className="col-md-4 mb-2">
                    {/* <Input
                      style={{
                        width: "100%",
                        borderColor:
                          !def_pricipal && termsError ? "red" : "inherit",
                      }}
                      type="number"
                      placeholder="Default"
                      size="md"
                      value={def_pricipal}
                      name="def_pricipal"
                      onChange={updateLoanProductInfoHandler}
                    /> */}
                    <CurrencyFormat
                      value={def_pricipal}
                      name="def_pricipal"
                      thousandSeparator={true}
                      className="amount-input"
                      placeholder="Default"
                      style={{
                        width: "100%",
                        borderColor:
                          !def_pricipal && termsError ? "red" : "inherit",
                      }}
                      // prefix={"$"}
                      onValueChange={(values) => {
                        const { value } = values;
                        // formattedValue = $2,223
                        // value ie, 2223
                        setDef_pricipal(value);
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    {/* <Input
                      style={{
                        width: "100%",
                        borderColor:
                          !max_pricipal && termsError ? "red" : "inherit",
                      }}
                      type="number"
                      placeholder="Maximum"
                      size="md"
                      name="max_pricipal"
                      value={max_pricipal}
                      onChange={updateLoanProductInfoHandler}
                    /> */}
                    <CurrencyFormat
                      value={max_pricipal}
                      name="min_pricipal"
                      thousandSeparator={true}
                      className="amount-input"
                      placeholder="Maximum"
                      style={{
                        width: "100%",
                        borderColor:
                          !max_pricipal && termsError ? "red" : "inherit",
                      }}
                      // prefix={"$"}
                      onValueChange={(values) => {
                        const { value } = values;
                        // formattedValue = $2,223
                        // value ie, 2223
                        setMax_pricipal(value);
                      }}
                    />
                  </div>
                </div>
              </div>
              {terms && (
                <div>
                  <div className="mt-3">
                    <div className="d-flex">
                      <label className="text-label">
                        Principal By Loan Cycle{" "}
                        {renderTooltip("Principal By Loan Cycle")}
                      </label>
                      <div className="row ml-0 w-80">
                        <AddActionButton
                          onClick={addToPrincipalLC}
                          style={{ height: 30, minWidth: 60 }}
                        >
                          <img
                            src={
                              require("../../../../../../../../assets/images/icons/add.svg")
                                .default
                            }
                            alt="add icon"
                          />{" "}
                          Add
                        </AddActionButton>
                      </div>
                    </div>
                  </div>

                  {numberOfPrincipalLC.length > 0 && (
                    <div className="mt-2">
                      <Table>
                        <thead className="">
                          <tr style={{ backgroundColor: "#EDEFF5" }}>
                            <th></th>
                            <th>Loan Cycle</th>
                            <th>Minimum</th>
                            <th>Default</th>
                            <th>Maximum</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {numberOfPrincipalLC.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <Select
                                  size="md"
                                  value={
                                    numberOfPrincipalLC[index]
                                      .valueConditionType
                                  }
                                  onChange={(e) =>
                                    handlePrincipalCode(index, e.target.value)
                                  }
                                >
                                  <option value="">Select one</option>
                                  {template !== undefined && template
                                    ? template.valueConditionTypeOptions &&
                                      template.valueConditionTypeOptions.map(
                                        (cur) => (
                                          <option value={cur.id} key={cur.id}>
                                            {cur.value}
                                          </option>
                                        )
                                      )
                                    : null}
                                </Select>
                              </td>
                              <td>
                                <Input
                                  style={{ width: "100%" }}
                                  size="md"
                                  value={
                                    numberOfPrincipalLC[index]
                                      .borrowerCycleNumber
                                  }
                                  onChange={(e) =>
                                    handlePrincipalBorrowerCycleNumber(
                                      index,
                                      e.target.value
                                    )
                                  }
                                  type="number"
                                />
                              </td>
                              <td>
                                <Input
                                  style={{ width: "100%" }}
                                  size="md"
                                  value={numberOfPrincipalLC[index].minValue}
                                  onChange={(e) =>
                                    handlePrincipalMinValue(
                                      index,
                                      e.target.value
                                    )
                                  }
                                  type="number"
                                />
                              </td>
                              <td>
                                <Input
                                  style={{ width: "100%" }}
                                  size="md"
                                  value={
                                    numberOfPrincipalLC[index].defaultValue
                                  }
                                  onChange={(e) =>
                                    handlePrincipalDefaultValue(
                                      index,
                                      e.target.value
                                    )
                                  }
                                  type="number"
                                />
                              </td>
                              <td>
                                <Input
                                  style={{ width: "100%" }}
                                  size="md"
                                  value={numberOfPrincipalLC[index].maxValue}
                                  onChange={(e) =>
                                    handlePrincipalMaxValue(
                                      index,
                                      e.target.value
                                    )
                                  }
                                  type="number"
                                />
                              </td>
                              <td>
                                <IconButton
                                  onClick={() => removeFromPrincipalLC(index)}
                                >
                                  <img
                                    src={
                                      require("../../../../../../../../assets/images/icons/delete.svg")
                                        .default
                                    }
                                    alt="add icon"
                                    className="icon-img"
                                  />
                                </IconButton>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  )}
                </div>
              )}
            </div>
          </FormElements>
        </FormClass>
      </div>

      <div>
        <FormClass>
          <FormElements style={{ width: "100%" }}>
            <div className="form-group">
              <div className="d-flex justify-content-between align-items-center">
                <label className="text-label">
                  Number Of Repayments* {renderTooltip("Number Of Repayments")}
                </label>
                <div className="row m-0 w-80">
                  <div className="col-md-4 mb-2">
                    <Input
                      style={{
                        width: "100%",
                        borderColor:
                          !min_repayment && termsError ? "red" : "inherit",
                      }}
                      type="number"
                      size="md"
                      placeholder="Minimum"
                      value={min_repayment}
                      name="min_repayment"
                      onChange={updateLoanProductInfoHandler}
                    />
                  </div>
                  <div className="col-md-4 mb-2">
                    <Input
                      style={{
                        width: "100%",
                        borderColor:
                          !def_repayment && termsError ? "red" : "inherit",
                      }}
                      type="number"
                      placeholder="Default"
                      size="md"
                      value={def_repayment}
                      name="def_repayment"
                      onChange={updateLoanProductInfoHandler}
                    />
                  </div>
                  <div className="col-md-4">
                    <Input
                      style={{
                        width: "100%",
                        borderColor:
                          !max_repayment && termsError ? "red" : "inherit",
                      }}
                      type="number"
                      placeholder="Maximum"
                      size="md"
                      name="max_repayment"
                      value={max_repayment}
                      onChange={updateLoanProductInfoHandler}
                    />
                  </div>
                </div>
              </div>
              {terms && (
                <div>
                  <div className="mt-3">
                    <div className="d-flex">
                      <label className="text-label">
                        Number Of Repayments By Loan Cycle
                        {renderTooltip("Number Of Repayments By Loan Cycle")}
                      </label>
                      <div className="row ml-0 w-80">
                        <AddActionButton
                          onClick={addToRepayment}
                          style={{ height: 30, minWidth: 60 }}
                        >
                          <img
                            src={
                              require("../../../../../../../../assets/images/icons/add.svg")
                                .default
                            }
                            alt="add icon"
                          />{" "}
                          Add
                        </AddActionButton>
                      </div>
                    </div>
                  </div>

                  {numberOfRepayment.length > 0 && (
                    <div className="mt-2">
                      <Table>
                        <thead className="">
                          <tr style={{ backgroundColor: "#EDEFF5" }}>
                            <th></th>
                            <th>Loan Cycle</th>
                            <th>Minimum</th>
                            <th>Default</th>
                            <th>Maximum</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {numberOfRepayment.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <Select
                                  size="md"
                                  value={
                                    numberOfRepayment[index].valueConditionType
                                  }
                                  onChange={(e) =>
                                    handleRepaymentCode(index, e.target.value)
                                  }
                                >
                                  <option value="">Select one</option>
                                  {template !== undefined && template
                                    ? template.valueConditionTypeOptions &&
                                      template.valueConditionTypeOptions.map(
                                        (cur) => (
                                          <option value={cur.id} key={cur.id}>
                                            {cur.value}
                                          </option>
                                        )
                                      )
                                    : null}
                                </Select>
                              </td>
                              <td>
                                <Input
                                  style={{ width: "100%" }}
                                  size="md"
                                  value={
                                    numberOfRepayment[index].borrowerCycleNumber
                                  }
                                  onChange={(e) =>
                                    handleRepaymentBorrowerCycleNumber(
                                      index,
                                      e.target.value
                                    )
                                  }
                                  type="number"
                                />
                              </td>
                              <td>
                                <Input
                                  style={{ width: "100%" }}
                                  size="md"
                                  value={numberOfRepayment[index].minValue}
                                  onChange={(e) =>
                                    handleRepaymentMinValue(
                                      index,
                                      e.target.value
                                    )
                                  }
                                  type="number"
                                />
                              </td>
                              <td>
                                <Input
                                  style={{ width: "100%" }}
                                  size="md"
                                  value={numberOfRepayment[index].defaultValue}
                                  onChange={(e) =>
                                    handleRepaymentDefaultValue(
                                      index,
                                      e.target.value
                                    )
                                  }
                                  type="number"
                                />
                              </td>
                              <td>
                                <Input
                                  style={{ width: "100%" }}
                                  size="md"
                                  value={numberOfRepayment[index].maxValue}
                                  onChange={(e) =>
                                    handleRepaymentMaxValue(
                                      index,
                                      e.target.value
                                    )
                                  }
                                  type="number"
                                />
                              </td>
                              <td>
                                <IconButton
                                  onClick={() => removeFromRepayment(index)}
                                >
                                  <img
                                    src={
                                      require("../../../../../../../../assets/images/icons/delete.svg")
                                        .default
                                    }
                                    alt="add icon"
                                    className="icon-img"
                                  />
                                </IconButton>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  )}
                </div>
              )}
            </div>
          </FormElements>
        </FormClass>
      </div>

      <div>
        <FormClass>
          <FormElements style={{ width: "100%" }}>
            <div className="form-group">
              <div className="d-flex justify-content-between align-items-center">
                <label className="text-label">
                  {" "}
                  Nominal Interest Rate*{" "}
                  {renderTooltip("Nominal Interest Rate")}
                </label>
                <div className="row m-0 w-80">
                  <div className="col-md-4 mb-2">
                    <Input
                      style={{
                        width: "90%",
                        borderRadius: "0",
                        borderColor:
                          !min_interest && termsError ? "red" : "inherit",
                      }}
                      type="number"
                      size="md"
                      placeholder="Minimum"
                      value={min_interest}
                      name="min_interest"
                      onChange={updateLoanProductInfoHandler}
                    />
                    <span className="afterInputText">%</span>
                  </div>
                  <div className="col-md-4 mb-2">
                    <Input
                      style={{
                        width: "90%",
                        borderRadius: "0",
                        borderColor:
                          !def_interest && termsError ? "red" : "inherit",
                      }}
                      type="number"
                      placeholder="Default"
                      size="md"
                      value={def_interest}
                      name="def_interest"
                      onChange={updateLoanProductInfoHandler}
                    />
                    <span className="afterInputText">%</span>
                  </div>
                  <div className="col-md-4">
                    <Input
                      style={{
                        width: "90%",
                        borderRadius: "0",
                        borderColor:
                          !max_interest && termsError ? "red" : "inherit",
                      }}
                      type="number"
                      placeholder="Maximum"
                      size="md"
                      name="max_interest"
                      value={max_interest}
                      onChange={updateLoanProductInfoHandler}
                    />
                    <span className="afterInputText">%</span>
                  </div>
                  <div className="col-md-6 mt-3">
                    <Select
                      size="md"
                      style={{
                        borderColor:
                          !interestSelect && termsError ? "red" : "inherit",
                      }}
                      name="interestSelect"
                      value={interestSelect}
                      onChange={updateLoanProductInfoHandler}
                    >
                      <option value="">Select one</option>
                      {template !== undefined && template
                        ? template.interestRateFrequencyTypeOptions &&
                          template.interestRateFrequencyTypeOptions.map(
                            (cur) => (
                              <option value={cur.id} key={cur.id}>
                                {cur.value}
                              </option>
                            )
                          )
                        : null}
                    </Select>
                  </div>
                </div>
              </div>
              {terms && (
                <div>
                  <div className="mt-3">
                    <div className="d-flex">
                      <label className="text-label">
                        Nominal Interest Rate By Loan Cycle
                        {renderTooltip("Nominal Interest Rate By Loan Cycle")}
                      </label>
                      <div className="row ml-0 w-80">
                        <AddActionButton
                          onClick={addToNominal}
                          style={{ height: 30, minWidth: 60 }}
                        >
                          <img
                            src={
                              require("../../../../../../../../assets/images/icons/add.svg")
                                .default
                            }
                            alt="add icon"
                          />{" "}
                          Add
                        </AddActionButton>
                      </div>
                    </div>
                  </div>

                  {numberOfNominal.length > 0 && (
                    <div className="mt-2">
                      <Table>
                        <thead className="">
                          <tr style={{ backgroundColor: "#EDEFF5" }}>
                            <th></th>
                            <th>Loan Cycle</th>
                            <th>Minimum</th>
                            <th>Default</th>
                            <th>Maximum</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {numberOfNominal.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <Select
                                  size="md"
                                  value={
                                    numberOfNominal[index].valueConditionType
                                  }
                                  onChange={(e) =>
                                    handleNominalCode(index, e.target.value)
                                  }
                                >
                                  <option value="">Select one</option>
                                  {template !== undefined && template
                                    ? template.valueConditionTypeOptions &&
                                      template.valueConditionTypeOptions.map(
                                        (cur) => (
                                          <option value={cur.id} key={cur.id}>
                                            {cur.value}
                                          </option>
                                        )
                                      )
                                    : null}
                                </Select>
                              </td>
                              <td>
                                <Input
                                  style={{ width: "100%" }}
                                  size="md"
                                  value={
                                    numberOfNominal[index].borrowerCycleNumber
                                  }
                                  onChange={(e) =>
                                    handleNominalBorrowerCycleNumber(
                                      index,
                                      e.target.value
                                    )
                                  }
                                  type="number"
                                />
                              </td>
                              <td>
                                <Input
                                  style={{ width: "100%" }}
                                  size="md"
                                  value={numberOfNominal[index].minValue}
                                  onChange={(e) =>
                                    handleNominalMinValue(index, e.target.value)
                                  }
                                  type="number"
                                />
                              </td>
                              <td>
                                <Input
                                  style={{ width: "100%" }}
                                  size="md"
                                  value={numberOfNominal[index].defaultValue}
                                  onChange={(e) =>
                                    handleNominalDefaultValue(
                                      index,
                                      e.target.value
                                    )
                                  }
                                  type="number"
                                />
                              </td>
                              <td>
                                <Input
                                  style={{ width: "100%" }}
                                  size="md"
                                  value={numberOfNominal[index].maxValue}
                                  onChange={(e) =>
                                    handleNominalMaxValue(index, e.target.value)
                                  }
                                  type="number"
                                />
                              </td>
                              <td>
                                <IconButton
                                  onClick={() => removeFromNominal(index)}
                                >
                                  <img
                                    src={
                                      require("../../../../../../../../assets/images/icons/delete.svg")
                                        .default
                                    }
                                    alt="add icon"
                                    className="icon-img"
                                  />
                                </IconButton>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  )}
                </div>
              )}
            </div>
          </FormElements>
        </FormClass>
      </div>

      <div>
        <FormClass>
          <FormElements style={{ width: "100%" }}>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">
                {" "}
                Repaid Every
                {renderTooltip("Repaid Every")}
              </label>
              <div className="row m-0 w-80">
                <div className="col-md-4 mb-2">
                  <Input
                    style={{
                      width: "100%",
                      borderColor: !repaid && termsError ? "red" : "inherit",
                    }}
                    size="md"
                    value={repaid}
                    name="repaid"
                    onChange={updateLoanProductInfoHandler}
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <Select
                    size="md"
                    style={{
                      borderColor:
                        !repaidSelect && termsError ? "red" : "inherit",
                    }}
                    value={repaidSelect}
                    onChange={updateLoanProductInfoHandler}
                    name="repaidSelect"
                  >
                    <option value="">Select one</option>
                    {template !== undefined && template
                      ? template.repaymentFrequencyTypeOptions &&
                        template.repaymentFrequencyTypeOptions.map((cur) => (
                          <option value={cur.id} key={cur.id}>
                            {cur.value}
                          </option>
                        ))
                      : null}
                  </Select>
                </div>
              </div>
            </div>
          </FormElements>
        </FormClass>
      </div>

      <div>
        <FormClass>
          <FormElements style={{ width: "100%" }}>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">
                Repayment Starts After{" "}
                {renderTooltip(
                  "Minimum Days Between Disbursal And First Repayment Date"
                )}
              </label>
              <div className="row m-0 w-80">
                <div className="col-md-4 mb-2">
                  <Input
                    style={{ width: "80%", borderRadius: "0" }}
                    size="md"
                    value={disbursal}
                    type="number"
                    name="disbursal"
                    onChange={updateLoanProductInfoHandler}
                  />
                  <span className="afterInputText">Days</span>
                </div>
              </div>
            </div>
          </FormElements>
        </FormClass>
      </div>

      <div>
        <div className="float-right pt-5">
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={handlePreviousScreen}
          >
            <img
              src={
                require("../../../../../../../../assets/images/icons/arrow-left.svg")
                  .default
              }
              alt="cancel icon"
            />
            Previous
          </ActionButton>
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={() => {
              min_interest &&
              min_pricipal &&
              min_repayment &&
              def_interest &&
              def_pricipal &&
              def_repayment &&
              max_interest &&
              max_pricipal &&
              max_repayment &&
              repaid &&
              (repaidSelect !== "" || repaidSelect !== undefined || repaidSelect !== null)
                ? setTermsError(false)
                : setTermsError(true);
              handleNextScreen();
            }}
          >
            <img
              src={
                require("../../../../../../../../assets/images/icons/arrow-right.svg")
                  .default
              }
              alt="submit icon"
            />{" "}
            Next
          </ActionButton>
        </div>
      </div>
    </>
  );
};

export default LoanTerms;
