import React, { useState, useEffect } from "react";
import { ContentWrapper, ContentCard } from "./styles";
import { Link } from "react-router-dom";
import LoadingScreen from "./LoadingScreen";

const TransactionsView = () => {
  const CardListings = [
    // {
    //   icon: require("../../../assets/images/icons/gl-trans.svg").default,
    //   title: "GL Transaction",
    //   description: "These are predefined postings",
    //   route: "gl-transaction",
    // },
    {
      icon: require("../../../assets/images/icons/savings-trans.svg").default,
      title: "Savings Transaction",
      description: "Manual add journal entry transaction recorded in a journal",
      route: "savings-transaction",
      // permission: "READ_SAVINGSACCOUNT",
    },
    {
      icon: require("../../../assets/images/icons/loan-trans.svg").default,
      title: "Loan Transaction",
      description: "Advances search option for journal entries",
      route: "loan-transaction",
      // permission: "READ_LOANACCOUNT",
    },
    // {
    //   icon: require("../../../assets/images/icons/fixed-trans.svg").default,
    //   title: "Fixed deposit Transaction",
    //   description: "List of financial activity &  GL account matching",
    //   route: "fixed-deposit-transaction",
    // },
  ];
  const [loading, setloading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setloading(false);
    }, 1000);
  }, []);

  return !!loading ? (
    <LoadingScreen />
  ) : (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Transactions</h3>
            <p className="sub-title">
              A complete list of client’s transactions in your business
            </p>
          </div>
        </div>
        <ContentWrapper>
          <div className="row">
            {CardListings.map(({ icon, title, description, route }, i) => (
              <div
                className="col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-4"
                key={i}
              >
                <Link to={`transactions/${route}`}>
                  <ContentCard>
                    <img className="card-img-right" src={icon} alt={title} />
                    <div className="body">
                      <p className="title">{title}</p>
                      <p className="description">{description}</p>
                    </div>
                  </ContentCard>
                </Link>
              </div>
            ))}
          </div>
        </ContentWrapper>
      </div>
    </>
  );
};

export default TransactionsView;
