import React from 'react'
import {useDispatch } from 'react-redux'
import { Modal} from "react-bootstrap";

import {ActionButton} from "../../styles.jsx";

import { deleteCustomerDocument, getCustomerDocuments } from '../../../../../../actions/customerActions'

const DeleteDocument = ({onHide, userData, docId}) => {
  const dispatch = useDispatch()

  const handleDocumentDelete = async () => {
    await dispatch(deleteCustomerDocument(userData.id, docId))
    await dispatch(getCustomerDocuments(userData.id))
    onHide()
  }

  return (
    <>
      <Modal.Header>
        <Modal.Title>Delete Document</Modal.Title>
      </Modal.Header>
      <Modal.Body className=" align-items-center justify-content-center flex-direction-coloumn">
        <div className='pt-1 px-4'>
          <p style={{ fontSize: '15px' }}>
            Are you sure you want to delete this document from this account?
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
      <div className="float-right mt-1" >
          <ActionButton color="#2C1DFF" onClick={onHide}>
            <img src={require("../../../../../../assets/images/icons/cancel.svg").default} 
              alt="cancel icon"
            /> Cancel
          </ActionButton>
          <ActionButton id='testid' color="#2C1DFF" style={{ margin: '0px 0px 0 10px' }} onClick={handleDocumentDelete}>
            <img src={require("../../../../../../assets/images/icons/bin.svg").default}
              alt="delete icon"
            />Delete
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  )
}

  export default DeleteDocument