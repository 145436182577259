import styled from "styled-components";

export const ContentWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 0px #ededf6;
  min-height: 70vh;
  margin-top: 20px;
  padding: 50px;

  .button-right {
    width: 100%;
    height: 50px;
    padding-right: 15px;
  }
  table th {
    font-size: 18px;
    font-weight: bold;
  }
`;

export const NewContentWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 0px #ededf6;
  min-height: 70vh;
  margin-top: 20px;
  .button-right {
    width: 100%;
    height: 50px;
    padding-right: 15px;
  }
  table th {
    font-size: 18px;
    font-weight: bold;
  }
`;

export const TableButton = styled.div`
  height: 35px;
  width: 160px;
  background: #2C1DFF !important;
  margin: 10px 5px;
  border: none;
  border-radius: 5px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
`;

export const FormClass = styled.div``;
