import React from "react";
import { NewActionButton, FormClass } from "./styles";
import { Modal, Table } from "react-bootstrap";
import { formatCurrency } from "../../../../util/FormatAmount";

const ChargeDetailsModal = ({ onHide, currentCharge }) => {
  let {
    active,
    amount,
    chargeAppliesTo,
    chargeCalculationType,
    chargePaymentMode,
    chargeTimeType,
    currency,
    name,
    penalty,
    minCap,
    maxCap,
  } = currentCharge || {};
  return (
    <>
      <Modal.Header>
        <Modal.Title>Charge Details</Modal.Title>
      </Modal.Header>
      <Modal.Body className="wc-modal">
        <div className="d-flex align-items-center justify-content-center flex-direction-coloumn">
          <FormClass style={{ width: "90%" }}>
            <Table responsive striped bordered hover>
              <thead className="border-0">
                <tr>
                  <td className="text-label font-weight-bold md-font-weight">
                    Charge Name
                  </td>
                  <td>{!!name ? name : ""}</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-label font-weight-bold md-font-weight">
                    Charge Type
                  </td>
                  <td>{penalty ? "Penalty" : "Charge"}</td>
                </tr>
                <tr>
                  <td className="text-label font-weight-bold md-font-weight">Currency</td>
                  <td>{currency && currency.displayLabel}</td>
                </tr>
                <tr>
                  <td className="text-label font-weight-bold md-font-weight">Amount</td>
                  <td>{formatCurrency(amount)}</td>
                </tr>
                
                <tr>
                  <td className="text-label font-weight-bold md-font-weight">Minimum Cap</td>
                  <td>{minCap}</td>
                </tr>
                <tr>
                  <td className="text-label font-weight-bold md-font-weight">Maximum Cap</td>
                  <td>{maxCap}</td>
                </tr>
                <tr>
                  <td className="text-label font-weight-bold md-font-weight">
                    Charge Time Type
                  </td>
                  <td>{chargeTimeType && chargeTimeType.value}</td>
                </tr>
                <tr>
                  <td className="text-label font-weight-bold md-font-weight">
                    Charge Applies To
                  </td>
                  <td>{chargeAppliesTo && chargeAppliesTo.value}</td>
                </tr>
                <tr>
                  <td className="text-label font-weight-bold md-font-weight">
                    Charge Calculation Type
                  </td>
                  <td>
                    {chargeCalculationType && chargeCalculationType.value}
                  </td>
                </tr>
                <tr>
                  <td className="text-label font-weight-bold md-font-weight">Active </td>
                  <td>{`${active}`}</td>
                </tr>
                <tr>
                  <td className="text-label font-weight-bold md-font-weight">
                    Charge Payment Mode
                  </td>
                  <td>{chargePaymentMode && chargePaymentMode.value}</td>
                </tr>
              </tbody>
            </Table>
          </FormClass>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-1">
          <NewActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require("../../../../assets/images/icons/cancel.svg").default
              }
              alt="cancel icon"
            />
            Cancel
          </NewActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default ChargeDetailsModal;
