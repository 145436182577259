import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import { ContentWrapper, FormTitle } from "../styles";
import { getSavingsProduct } from "../../../../../../actions/configurationActions";
import { formatCurrency } from "../../../../../../util/FormatAmount";

const SavingsProductDetails = ({ match }) => {
  const dispatch = useDispatch();
  const savingsProductReducer = useSelector(
    (state) => state.savingsProductReducer
  );

  const { savingsProduct } = savingsProductReducer;

  useEffect(() => {
    dispatch(getSavingsProduct(match.params.id));
  }, [match.params.id]);

  const isBoolean = (variable) => {
    if (typeof variable === "boolean") {
      return true;
    }
    return false;
  };


  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Savings products</h3>
            <p className="font-weight sub-title mt-2">
              <span className="text-info mr-1">
                {/* <Link to="/core/configuration">Configuration</Link> /{" "} */}
                <Link to="/core/products">Products</Link> /
              </span>
              <span className="text-info mr-1">
                <Link to="/core/products/savings-products">
                  Savings products
                </Link>
              </span>
              /{" "}
              {!!savingsProduct && !!savingsProduct.name
                ? savingsProduct.name
                : ""}{" "}
              Details
            </p>
          </div>
        </div>
        <ContentWrapper>
          <div className="col-sm-12 col-md-12 d-flex py-5">
            <div className="container">
              <div>
                <FormTitle>
                  <p className="text-info">Details</p>
                  <div className="lineThrough"></div>
                </FormTitle>
                <div className="pt-2">
                  <div
                    className="form-group d-flex  align-items-center text-label"
                    style={{ marginBottom: "1rem !important" }}
                  >
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      Description &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!savingsProduct && !!savingsProduct.description
                        ? savingsProduct.description
                        : ""}
                    </p>
                  </div>
                  <div
                    className="form-group d-flex  align-items-center text-label"
                    style={{ marginBottom: "1rem !important" }}
                  >
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      Short name &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!savingsProduct && !!savingsProduct.shortName
                        ? savingsProduct.shortName
                        : ""}
                    </p>
                  </div>
                </div>
              </div>
              <div className="pt-1">
                <FormTitle>
                  <p className="text-info">Terms</p>
                  <div className="lineThrough"></div>
                </FormTitle>
                <div className="pt-2">
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      Currency &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!savingsProduct &&
                      !!savingsProduct.currency &&
                      !!savingsProduct.currency.displayLabel
                        ? savingsProduct.currency.displayLabel
                        : ""}
                    </p>
                  </div>
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      Decimal Places &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!savingsProduct &&
                      !!savingsProduct.currency &&
                      !!savingsProduct.currency.decimalPlaces
                        ? savingsProduct.currency.decimalPlaces
                        : ""}
                    </p>
                  </div>

                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      Currency In Multiples Of &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {" "}
                      {!!savingsProduct &&
                      !!savingsProduct.currency &&
                      !!savingsProduct.currency.inMultiplesOf
                        ? savingsProduct.currency.inMultiplesOf
                        : ""}
                    </p>
                  </div>
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      {" "}
                      Nominal Annual Interest Rate &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!savingsProduct &&
                      savingsProduct.nominalAnnualInterestRate
                        ? savingsProduct.nominalAnnualInterestRate
                        : ""}
                    </p>
                  </div>
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      {" "}
                      Interest Compounding Period &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!savingsProduct &&
                      !!savingsProduct.interestCompoundingPeriodType &&
                      !!savingsProduct.interestCompoundingPeriodType.value
                        ? savingsProduct.interestCompoundingPeriodType.value
                        : ""}
                    </p>
                  </div>
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      {" "}
                      Interest Posting Period &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!savingsProduct &&
                      !!savingsProduct.interestPostingPeriodType &&
                      !!savingsProduct.interestPostingPeriodType.value
                        ? savingsProduct.interestPostingPeriodType.value
                        : ""}
                    </p>
                  </div>
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      Interest Calculated Using Days In Years
                      &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!savingsProduct &&
                      !!savingsProduct.interestCalculationType &&
                      !!savingsProduct.interestCalculationType.value
                        ? savingsProduct.interestCalculationType.value
                        : ""}
                    </p>
                  </div>
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      Days In Years &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!savingsProduct &&
                      !!savingsProduct.interestCalculationDaysInYearType &&
                      !!savingsProduct.interestCalculationDaysInYearType.value
                        ? savingsProduct.interestCalculationDaysInYearType.value
                        : ""}
                    </p>
                  </div>
                </div>
              </div>
              <div className="pt-1">
                <FormTitle>
                  <p className="text-info">Settings</p>
                  <div className="lineThrough"></div>
                </FormTitle>
                <div className="pt-2">
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      Minimum Opening Balance &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!savingsProduct &&
                        !!savingsProduct.minRequiredOpeningBalance &&
                        formatCurrency(
                          Number(savingsProduct.minRequiredOpeningBalance)
                        )}
                    </p>
                  </div>
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      Lock-In Period &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!savingsProduct && savingsProduct.lockinPeriodFrequency}{" "}
                      {!!savingsProduct &&
                        savingsProduct.lockinPeriodFrequencyType &&
                        savingsProduct.lockinPeriodFrequencyType.value}
                    </p>
                  </div>
                  <div className="form-group d-flex align-items-center text-label">
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      Apply Withdrawal Fee For Transfers &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!savingsProduct &&
                      isBoolean(savingsProduct.withdrawalFeeForTransfers)
                        ? `${savingsProduct.withdrawalFeeForTransfers}`
                        : ""}
                    </p>
                  </div>
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      Allow Overdraft &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!savingsProduct &&
                      isBoolean(savingsProduct.allowOverdraft)
                        ? `${savingsProduct.allowOverdraft}`
                        : ""}
                    </p>
                  </div>
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      Balance Required For Interest Calculation
                      &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!savingsProduct &&
                        !!savingsProduct.minBalanceForInterestCalculation &&
                        formatCurrency(
                          Number(
                            savingsProduct.minBalanceForInterestCalculation
                          )
                        )}
                    </p>
                  </div>
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      Enforce Minimum Balance &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!savingsProduct &&
                      isBoolean(savingsProduct.enforceMinRequiredBalance)
                        ? `${savingsProduct.enforceMinRequiredBalance}`
                        : ""}
                    </p>
                  </div>
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      Minimum Balance &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!savingsProduct &&
                        !!savingsProduct.minRequiredBalance &&
                        formatCurrency(
                          Number(savingsProduct.minRequiredBalance)
                        )}
                    </p>
                  </div>
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      Is Withhold Tax Applicable &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!savingsProduct && isBoolean(savingsProduct.withHoldTax)
                        ? `${savingsProduct.withHoldTax}`
                        : ""}
                    </p>
                  </div>
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      Tax Group &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!savingsProduct &&
                      !!savingsProduct.taxGroup &&
                      !!savingsProduct.taxGroup.name
                        ? savingsProduct.taxGroup.name
                        : ""}
                    </p>
                  </div>
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      Is Dormancy Tracking Active &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!savingsProduct &&
                      isBoolean(savingsProduct.isDormancyTrackingActive)
                        ? `${savingsProduct.isDormancyTrackingActive}`
                        : ""}
                    </p>
                  </div>
                </div>
              </div>
              <div className="pt-1">
                <FormTitle>
                  <p className="text-info">Charges</p>
                  <div className="lineThrough"></div>
                </FormTitle>
                <div className="pt-2">
                  <Table className="child-table">
                    <thead className="">
                      <tr style={{ backgroundColor: "#FAFAFA" }}>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Amount</th>
                        <th>Collected on</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!!savingsProduct &&
                      savingsProduct.charges &&
                      savingsProduct.charges.length > 0
                        ? savingsProduct.charges.map(
                            ({
                              id,
                              name,
                              chargeCalculationType,
                              amount,
                              chargeTimeType,
                            }) => (
                              <tr key={id}>
                                <td>{name}</td>
                                <td>
                                  {chargeCalculationType &&
                                    chargeCalculationType.value}
                                </td>
                                <td>{formatCurrency(Number(amount))}</td>
                                <td>
                                  {chargeTimeType && chargeTimeType.value}
                                </td>
                              </tr>
                            )
                          )
                        : null}
                    </tbody>
                  </Table>
                </div>
              </div>
              <div className="pt-1">
                <FormTitle>
                  <p className="text-info">Accounting</p>
                  <div className="lineThrough"></div>
                </FormTitle>
                <div className="pt-2">
                  <div
                    className="form-group d-flex  align-items-center text-label"
                    style={{ marginBottom: "1rem !important" }}
                  >
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      Account type &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!savingsProduct &&
                        savingsProduct.accountingRule &&
                        savingsProduct.accountingRule.value}
                    </p>
                  </div>
                  {!!savingsProduct &&
                  savingsProduct.accountingRule &&
                  savingsProduct.accountingRule.id === 2 ? (
                    <>
                      <div
                        className="form-group d-flex  align-items-center text-label"
                        style={{ marginBottom: "1rem !important" }}
                      >
                        <h3
                          style={{
                            fontFamily: "AventaSemiBold",
                            marginRight: "1rem",
                          }}
                          className="col-md-4"
                        >
                          Savings Reference(Assets) &nbsp;&nbsp;&nbsp;:
                        </h3>
                        <p style={{ width: "60%", fontWeight: "bold" }}>
                          {!!savingsProduct &&
                            savingsProduct.accountingMappings &&
                            savingsProduct.accountingMappings
                              .savingsReferenceAccount &&
                            savingsProduct.accountingMappings
                              .savingsReferenceAccount.name}
                        </p>
                      </div>
                      <div
                        className="form-group d-flex  align-items-center text-label"
                        style={{ marginBottom: "1rem !important" }}
                      >
                        <h3
                          style={{
                            fontFamily: "AventaSemiBold",
                            marginRight: "1rem",
                          }}
                          className="col-md-4"
                        >
                          Overdraft Portfolio(Assets) &nbsp;&nbsp;&nbsp;:
                        </h3>
                        <p style={{ width: "60%", fontWeight: "bold" }}>
                          {!!savingsProduct &&
                            savingsProduct.accountingMappings &&
                            savingsProduct.accountingMappings
                              .overdraftPortfolioControl &&
                            savingsProduct.accountingMappings
                              .overdraftPortfolioControl.name}
                        </p>
                      </div>
                      <div
                        className="form-group d-flex  align-items-center text-label"
                        style={{ marginBottom: "1rem !important" }}
                      >
                        <h3
                          style={{
                            fontFamily: "AventaSemiBold",
                            marginRight: "1rem",
                          }}
                          className="col-md-4"
                        >
                          Savings Control(Liabilities) &nbsp;&nbsp;&nbsp;:
                        </h3>
                        <p style={{ width: "60%", fontWeight: "bold" }}>
                          {!!savingsProduct &&
                            savingsProduct.accountingMappings &&
                            savingsProduct.accountingMappings
                              .savingsControlAccount &&
                            savingsProduct.accountingMappings
                              .savingsControlAccount.name}
                        </p>
                      </div>
                      <div
                        className="form-group d-flex  align-items-center text-label"
                        style={{ marginBottom: "1rem !important" }}
                      >
                        <h3
                          style={{
                            fontFamily: "AventaSemiBold",
                            marginRight: "1rem",
                          }}
                          className="col-md-4"
                        >
                          Transfers In Suspense(Liabilities) &nbsp;&nbsp;&nbsp;:
                        </h3>
                        <p style={{ width: "60%", fontWeight: "bold" }}>
                          {!!savingsProduct &&
                            savingsProduct.accountingMappings &&
                            savingsProduct.accountingMappings
                              .transfersInSuspenseAccount &&
                            savingsProduct.accountingMappings
                              .transfersInSuspenseAccount.name}
                        </p>
                      </div>
                      <div
                        className="form-group d-flex  align-items-center text-label"
                        style={{ marginBottom: "1rem !important" }}
                      >
                        <h3
                          style={{
                            fontFamily: "AventaSemiBold",
                            marginRight: "1rem",
                          }}
                          className="col-md-4"
                        >
                          Escheat Liability(Liabilities) &nbsp;&nbsp;&nbsp;:
                        </h3>
                        <p style={{ width: "60%", fontWeight: "bold" }}>
                          {!!savingsProduct &&
                            savingsProduct.accountingMappings &&
                            savingsProduct.accountingMappings
                              .escheatLiabilityAccount &&
                            savingsProduct.accountingMappings
                              .escheatLiabilityAccount.name}
                        </p>
                      </div>{" "}
                      <div
                        className="form-group d-flex  align-items-center text-label"
                        style={{ marginBottom: "1rem !important" }}
                      >
                        <h3
                          style={{
                            fontFamily: "AventaSemiBold",
                            marginRight: "1rem",
                          }}
                          className="col-md-4"
                        >
                          Interest On Savings(Expenses) &nbsp;&nbsp;&nbsp;:
                        </h3>
                        <p style={{ width: "60%", fontWeight: "bold" }}>
                          {!!savingsProduct &&
                            savingsProduct.accountingMappings &&
                            savingsProduct.accountingMappings
                              .interestOnSavingsAccount &&
                            savingsProduct.accountingMappings
                              .interestOnSavingsAccount.name}
                        </p>
                      </div>{" "}
                      <div
                        className="form-group d-flex  align-items-center text-label"
                        style={{ marginBottom: "1rem !important" }}
                      >
                        <h3
                          style={{
                            fontFamily: "AventaSemiBold",
                            marginRight: "1rem",
                          }}
                          className="col-md-4"
                        >
                          Write-Off(Expenses) &nbsp;&nbsp;&nbsp;:
                        </h3>
                        <p style={{ width: "60%", fontWeight: "bold" }}>
                          {!!savingsProduct &&
                            savingsProduct.accountingMappings &&
                            savingsProduct.accountingMappings.writeOffAccount &&
                            savingsProduct.accountingMappings.writeOffAccount
                              .name}
                        </p>
                      </div>{" "}
                      <div
                        className="form-group d-flex  align-items-center text-label"
                        style={{ marginBottom: "1rem !important" }}
                      >
                        <h3
                          style={{
                            fontFamily: "AventaSemiBold",
                            marginRight: "1rem",
                          }}
                          className="col-md-4"
                        >
                          Income From Fee(Income) &nbsp;&nbsp;&nbsp;:
                        </h3>
                        <p style={{ width: "60%", fontWeight: "bold" }}>
                          {!!savingsProduct &&
                            savingsProduct.accountingMappings &&
                            savingsProduct.accountingMappings
                              .incomeFromFeeAccount &&
                            savingsProduct.accountingMappings
                              .incomeFromFeeAccount.name}
                        </p>
                      </div>{" "}
                      <div
                        className="form-group d-flex  align-items-center text-label"
                        style={{ marginBottom: "1rem !important" }}
                      >
                        <h3
                          style={{
                            fontFamily: "AventaSemiBold",
                            marginRight: "1rem",
                          }}
                          className="col-md-4"
                        >
                          Income From Penalties(Income) &nbsp;&nbsp;&nbsp;:
                        </h3>
                        <p style={{ width: "60%", fontWeight: "bold" }}>
                          {!!savingsProduct &&
                            savingsProduct.accountingMappings &&
                            savingsProduct.accountingMappings
                              .incomeFromPenaltyAccount &&
                            savingsProduct.accountingMappings
                              .incomeFromPenaltyAccount.name}
                        </p>
                      </div>
                      <div
                        className="form-group d-flex  align-items-center text-label"
                        style={{ marginBottom: "1rem !important" }}
                      >
                        <h3
                          style={{
                            fontFamily: "AventaSemiBold",
                            marginRight: "1rem",
                          }}
                          className="col-md-4"
                        >
                          Overdraft Interest Income(Income) &nbsp;&nbsp;&nbsp;:
                        </h3>
                        <p style={{ width: "60%", fontWeight: "bold" }}>
                          {!!savingsProduct &&
                            savingsProduct.accountingMappings &&
                            savingsProduct.accountingMappings
                              .incomeFromInterest &&
                            savingsProduct.accountingMappings.incomeFromInterest
                              .name}
                        </p>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {!!savingsProduct &&
              savingsProduct.accountingRule &&
              savingsProduct.accountingRule.id === 2 ? (
                <div className="pt-1">
                  <FormTitle>
                    <p className="text-info">Advanced Accounting Rule</p>
                    <div className="lineThrough"></div>
                  </FormTitle>

                  <div className="pt-2">
                    <h3
                      style={{ fontSize: 16 }}
                      className="font-weight-bold mb-2"
                    >
                      Configure Fund Sources For Payment Channels
                      &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <Table className="child-table">
                      <thead className="">
                        <tr style={{ backgroundColor: "#FAFAFA" }}>
                          <th>Payment Type</th>
                          <th>Fund Source</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!!savingsProduct &&
                        !!savingsProduct.paymentChannelToFundSourceMappings &&
                        savingsProduct.paymentChannelToFundSourceMappings
                          .length > 0
                          ? savingsProduct.paymentChannelToFundSourceMappings.map(
                              ({ paymentType, fundSourceAccount }, index) => (
                                <tr key={index}>
                                  <td>{paymentType.name}</td>
                                  <td>{fundSourceAccount.name}</td>
                                </tr>
                              )
                            )
                          : null}
                      </tbody>
                    </Table>
                  </div>
                  <div className="pt-2">
                    <h3
                      style={{ fontSize: 16 }}
                      className="font-weight-bold mb-2"
                    >
                      Fees To Specific Income Account &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <Table className="child-table">
                      <thead className="">
                        <tr style={{ backgroundColor: "#FAFAFA" }}>
                          <th>Fees</th>
                          <th>Income Account</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!!savingsProduct &&
                        !!savingsProduct.feeToIncomeAccountMappings &&
                        savingsProduct.feeToIncomeAccountMappings.length > 0
                          ? savingsProduct.feeToIncomeAccountMappings.map(
                              ({ charge, incomeAccount }, index) => (
                                <tr key={index}>
                                  <td>{charge.name}</td>
                                  <td>{incomeAccount.name}</td>
                                </tr>
                              )
                            )
                          : null}
                      </tbody>
                    </Table>
                  </div>
                  <div className="pt-2">
                    <h3
                      style={{ fontSize: 16 }}
                      className="font-weight-bold mb-2"
                    >
                      Penalties To Specific Income Account &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <Table className="child-table">
                      <thead className="">
                        <tr style={{ backgroundColor: "#FAFAFA" }}>
                          <th>Penalty</th>
                          <th>Income Account</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!!savingsProduct &&
                        !!savingsProduct.penaltyToIncomeAccountMappings &&
                        savingsProduct.penaltyToIncomeAccountMappings.length > 0
                          ? savingsProduct.penaltyToIncomeAccountMappings.map(
                              ({ charge, incomeAccount }, index) => (
                                <tr key={index}>
                                  <td>{charge.name}</td>
                                  <td>{incomeAccount.name}</td>
                                </tr>
                              )
                            )
                          : null}
                      </tbody>
                    </Table>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </ContentWrapper>
      </div>
    </>
  );
};

export default SavingsProductDetails;
