import { Modal } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Select } from "@chakra-ui/react";
import { NewActionButton } from "./styles";
import DatePicker from "react-datepicker";
import { getAllAudit } from "../../../../actions/configurationActions";
import { formatDate, formatDateString } from "../../../../util/FormatDate";

const AdvanceEditModal = ({
  onSearch,
  onClear,
  onCancel,
  audits,
  setAdvanceFilter,
  advanceFilter,
}) => {
  const auditTemplateReducer = useSelector((state) => state.auditTemplate);
  let { auditTemplate = {} } = auditTemplateReducer;
  const [status, setStatus] = useState(advanceFilter?.status ?? "");
  const [checker, setChecker] = useState(advanceFilter?.checker ?? "");

  const [resourceId, setResourceId] = useState(advanceFilter?.resourceId ?? "");

  const [fromDate, handleFromDate] = useState(advanceFilter?.fromDate ?? "");
  const [checkFromDate, handleCheckFromDate] = useState(
    advanceFilter?.checkFromDate ?? ""
  );
  const [toDate, handleToDate] = useState(advanceFilter?.toDate ?? "");
  const [checkToDate, handleCheckToDate] = useState(
    advanceFilter?.checkToDate ?? ""
  );


  const handleSearch = (e) => {
    e.preventDefault();

    setAdvanceFilter({
      status,
      checker,
      resourceId,
      fromDate,
      checkFromDate,
      toDate,
      checkToDate,
    });

    onSearch({
      actionName: "",
      checkerDateTimeFrom: checkFromDate,
      checkerDateTimeTo: checkToDate,
      checkerId: checker,
      entityName: "",
      // makerDateTimeFrom: fromDate ? formatDate(fromDate, true) : "",
      // makerDateTimeTo: toDate ? formatDate(toDate, true) : "",
      makerDateTimeFrom: fromDate ? formatDateString(fromDate, "yyyy-MMMM-dd") : "",
      makerDateTimeTo: toDate ? formatDateString(toDate, "yyyy-MMMM-dd") : "",
      makerId: "",
      resourceId: resourceId,
      status: status,
    });
  };

  const clearFilter = () => {
    onClear && onClear();
    setAdvanceFilter({});
    setStatus("");
    setChecker("");
    setResourceId("");
    handleFromDate("");
    handleCheckFromDate("");
    handleToDate("");
    handleCheckToDate("");
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>Advance Search</Modal.Title>
        <div onClick={clearFilter}>
          <Modal.Title
            style={{ color: "red", fontSize: "15px", cursor: "pointer" , textDecoration: "underline"}}
          >
            Clear all filters
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body className="wc-modal">
        <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
          <div className="col-sm-6 col-md-6 px-0 mb-3 p-1" style={{ width: "70%" }}>
            <Input
              size="md"
              
              value={resourceId}
              onChange={(e) => setResourceId(e.target.value)}
              placeholder="Resource id"
            />
          </div>
          {/* <div className="col-xl-6 col-md-3 col-sm-12 mt-4"> */}
          <div
            className="col-sm-12 col-md-6 px-0 mb-3 p-1"
            style={{ width: "70%" }}
          >
            <div className="select-100-width">
              <Select
                placeholder="Select Status"
                size="md"
                className="text-capitalize"
                name="accName"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                {!!auditTemplate ? (
                  auditTemplate.processingResults ? (
                    auditTemplate.processingResults.map((item) => {
                      return (
                        <option value={item.id} key={item.id}>
                          {item.processingResult}
                        </option>
                      );
                    })
                  ) : (
                    ""
                  )
                ) : (
                  <option>Loading</option>
                )}
              </Select>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">

          {/* <div
            className="col-sm-12 col-md-6 px-0 mb-3 p-1"
            style={{ width: "70%" }}
          >
            <div className="select-100-width">
            <Select
                placeholder="Select checker"
                size="md"
                name="checker"
                className="text-capitalize"
                value={checker}
                onChange={(e) => setChecker(e.target.value)}
                disabled
              >
                {!!auditTemplate ? (
                  auditTemplate.appUsers ? (
                    auditTemplate.appUsers.map((item) => {
                      return (
                        <option
                          className="text-capitalize"
                          value={item.id}
                          key={item.id}
                        >
                          {item.username}
                        </option>
                      );
                    })
                  ) : (
                    ""
                  )
                ) : (
                  <option>Loading</option>
                )}
              </Select>
            </div>
          </div> */}


        </div>

        {/* datepicker */}
        <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div
            className="col-sm-12 col-md-6 px-0 mb-3 p-1"
            style={{ width: "70%" }}
          >
            {/* fromDate */}
            <div className="datepicker-100-width">
              <DatePicker
                id="from-date"
                selected={fromDate}
                onChange={(date) => handleFromDate(date)}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="Start date"
              />
            </div>
          </div>

          <div
            className="col-sm-12 col-md-6 px-0 mb-3 p-1"
            style={{ width: "70%" }}
          >
            {/* toDate */}
            <div className="datepicker-100-width">
              <DatePicker
                id="to-date"
                selected={toDate}
                onChange={(date) => handleToDate(date)}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="End date"
              />
            </div>
          </div>
        

        </div>

        {/* <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
       
          <div
            className="col-sm-12 col-md-6 px-0 mb-3 p-1"
            style={{ width: "70%" , opacity :'50%' }}
          >
            {/* checkFromDate 
            <div className="datepicker-100-width">
              <DatePicker
                id="checked-from-date"
                selected={checkFromDate}
                onChange={(date) => handleCheckFromDate(date)}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="Checked from"
                disabled
              />
            </div>
          </div>

          <div
            className="col-sm-12 col-md-6 px-0 mb-3 p-1"
            style={{ width: "70%" , opacity :'50%'}}
          >
            {/* checkToDate 
            <div className="datepicker-100-width">
              <DatePicker
                id="checked-to-date"
                selected={checkToDate}
                onChange={(date) => handleCheckToDate(date)}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="Checked To"
                disabled

              />
            </div>
          </div>
        </div> */}
      </Modal.Body>
      <Modal.Footer>
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexGrow: "0.2",
          }}
        >
          <div>
            <NewActionButton
              color="#2C1DFF"
              className="float-right"
              style={{ margin: "0px 0px" }}
              onClick={(e) => handleSearch(e)}
            >
              <img
                src={
                  require("../../../../assets/images/icons/checked-white.svg")
                    .default
                }
                className="d-inline mr-3"
                alt="icon"
              />
              Apply
            </NewActionButton>
          </div>
          <div>
            <NewActionButton
              color="#2C1DFF"
              className="float-right"
              style={{ margin: "0px 0px" }}
              onClick={onCancel}
            >
              <img
                src={
                  require("../../../../assets/images/icons/cancel.svg").default
                }
                className="d-inline mr-3"
                alt="icon"
              />
              Cancel
            </NewActionButton>
          </div>
        </div>
      </Modal.Footer>
    </>
  );
};

export default AdvanceEditModal;
