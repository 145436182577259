import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import {
  Input,
  // Select,
  FormControl,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { ActionButton, NewActionButton } from "../styles";
import {
  getJournalEntry,
  getAllGlAccounts,
  getOffices,
} from "../../../../../actions/accountingActions";
import { formatDate } from "../../../../../util/FormatDate";
import { textToUpperCase } from "../../../../../util/Tools";
import {
  getAllUsers,
  filterUsers,
  clearFilteredUsers,
  getUserTemplate,
} from "../../../../../actions/configurationActions";
import Toast from "../../../../../util/Toast";
// import { Select as AntSelect } from "antd";
import { fetchUsers } from "../../AddAccountJournalEntry/search";
import { LoadingOutlined } from "@ant-design/icons";
import { Select } from "antd";

const SearchInputs = ({
  setSearchState,
  setOffice,
  setAccNameOrCode,
  setEntry,
  setDateFrom,
  setDateTo,
  setTransactionId,
  setOffset,
  office,
  accNameOrCode,
  entry,
  dateFrom,
  dateTo,
  transactionId,
  offset,
  referenceNumber,
  setReferenceNumber,
  userId,
  setUserId,
  selectedUser,
  setSelectedUser,
}) => {
  const dispatch = useDispatch();

  // const { Option } = AntSelect;

  // const [selectedUserId, setSelectedUserId] = useState("");

  const accountingData = useSelector((state) => state.accountingData);

  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(false);

  const userList = useSelector((state) => state.userReducer);

  const { loading, users, filtered } = userList;

  const [userrId, setUserrId] = useState("");
  // const defaultUser ={
  //   userId: '',
  // }

  const [numberOfUserAccount, setNumberOfUserAccount] = useState("");

  const [index, setIndex] = useState({});
  useEffect(() => {}, [index, numberOfUserAccount]);

  // const handleUserToChange = (position, timeStamp) => {
  //   getSavingsAcc(timeStamp, position);
  //   let hhh = [...numberOfUserAccount];
  //   hhh[Number(position)].userId = timeStamp;
  //   setNumberOfUserAccount(hhh);
  // };

  // const removeUserAccount = (position) => {
  //   let noo = [];
  //   numberOfUserAccount.forEach((element, index) => {
  //     if (position !== index) {
  //       noo.push(element);
  //     }
  //   });
  //   setNumberOfUserAccount(noo);
  // };
  // console.log(users, "users, Id");
  let result = users.find((user) => user.username.toLowerCase() == userId)?.id;
  // console.log(result, "id")

  useEffect(() => {
    dispatch(getAllGlAccounts());
    dispatch(getOffices());
    dispatch(getAllUsers());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let { offices, glAccounts } = accountingData;

  let searchDayFrom = !!dateFrom ? formatDate(dateFrom, true) : "";
  let searchDayTo = !!dateTo ? formatDate(dateTo, true) : "";

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(
      getJournalEntry(
        office,
        accNameOrCode,
        entry,
        searchDayFrom,
        searchDayTo,
        transactionId,
        referenceNumber,
        selectedUser,
        // (userId = result),
        offset
      )
    );
    setSearchState(2);
  };

  // console.log(userId, "userId");

  const onGetSearch = (value) => {
    if (!value.length) {
      setData([]);
      setFetching(false);
    }
    if (value.length > 3) setFetching(true);
    fetchUsers(value, (data) => setData(data));
  };

  const handleChange = (value) => {
    setSelectedUser(value);
  };

  const searchOption =
    data &&
    data?.map((d) => (
      <Select.Option key={d.value}>{textToUpperCase(d.text)}</Select.Option>
    ));

  console.log(numberOfUserAccount, "numberOfUserAccount");
  console.log(data, "data");
  console.log(selectedUser, "selectedUser");

  return (
    <>
      <div className="row m-0" style={{ width: "70%" }}>
        <div className="col-md-4 mb-2 pl-0">
          {/* <label className="text-label">Select Office</label> */}
          <Select
            placeholder="Select Office"
            size="md"
            name="currency"
            value={office || undefined}
            onChange={(value) => {
              office = value;
              setOffice(value);
            }}
            // onChange={(e) => setOffice(e.target.value)}
          >
            {!!offices ? (
              offices.map(({ id, name }) => {
                return (
                  <option value={id} key={id}>
                    {name}
                  </option>
                );
              })
            ) : (
              <option>Loading</option>
            )}
          </Select>
        </div>
        <div className="col-md-4 mb-2 pl-0">
          {/* <label className="text-label">Select Account Name or Code</label> */}
          <Select
            // placeholder="Select Account Name or Code"
            size="md"
            name="accName"
            value={accNameOrCode || undefined}
            onChange={(value) => {
              accNameOrCode = value;
              setAccNameOrCode(value);
            }}
            placeholder="Select Account Name or Code"
            showSearch // Enable search functionality
            optionFilterProp="children" // Specify which property of the option is used for filtering
            // filterOption={(input, option) =>
            //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            // }
            filterOption={(input, option) =>
              String(option.children)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            // onChange={(e) => setAccNameOrCode(e.target.value)}
          >
            {!!glAccounts ? (
              glAccounts.map(({ id, name, glCode }) => {
                return (
                  <Select.Option value={id} key={id}>
                    {textToUpperCase(name)} {glCode}
                  </Select.Option>
                );
              })
            ) : (
              <option>Loading</option>
            )}
          </Select>
        </div>
        <div className="col-md-4 mb-2 pl-0">
          {/* <label className="text-label">Select Filter</label> */}
          <Select
            placeholder="Select Filter"
            size="md"
            name="entry"
            value={entry || undefined}
            onChange={(value) => {
              entry = value;
              setEntry(value);
            }}
            // onChange={(e) => setEntry(e.target.value)}
          >
            <option value="all">All</option>
            <option value="manual">Manual Entries</option>
            <option value="system">System Entries</option>
          </Select>
        </div>
      </div>
      {/* User Id Search */}
      <div className="row m-0 w-60">
        <div className="col-xl-6 mb-2 pl-0">
          {/* <label className="text-label">Select Filter</label> */}
          {/* <InputGroup>
            <InputLeftElement width="3.1rem" height="38px" pointerEvents="none">
              <img
                src={
                  require("../../../../../assets/images/icons/search.svg").default
                }
                style={{ height: "30px" }}
                alt="Icon"
                className="icon-img"
              />
            </InputLeftElement>
            <Input
              style={{
                background: "#ffffff",
                height: "35px",
              }}
              type="text"
              className="general-font-size"
              // ref={search}
              // onChange={onFilterUser}
              onChange={(e) => {
                userId = e.target.value;
                setUserId(e.target.value);
              }}
              placeholder="Search by name"
            />
          </InputGroup> */}

          {/* Search Input */}
          <Select
            notFoundContent={
              loading ? <LoadingOutlined size="small" /> : "No result"
            }
            loading={true}
            showSearch
            placeholder="Search by name"
            showArrow={false}
            filterOption={false}
            onSearch={onGetSearch}
            onChange={handleChange}
            // onChange={(e) => setUserrId(e.target.value)}
          >
            {searchOption}
          </Select>
        </div>
      </div>

      <div className="row mt-4 w-60">
        <div className="col-md-7 col-md-7 col-sm-12">
          {/* <div className="spaceAroundFlex">
            <div className="dateSelectForm form-group d-flex justify-content-between align-items-center">
              <label className="text-muted mr-3">From</label>
              <Select
                placeholder="Day"
                size="md"
                name="day"
                value={dayFrom}
                onChange={(e) => setDayFrom(e.target.value)}
              >
                {myDays.map(({ day }) => {
                  return (
                    <option value={day} key={day}>
                      {day}
                    </option>
                  );
                })}
              </Select>
            </div>
            <div className="dateSelectForm">
              <Select
                placeholder="Month"
                size="md"
                name="month"
                value={monthFrom}
                onChange={(e) => setMonthFrom(e.target.value)}
              >
                {myMonth.map(({ month }) => {
                  return (
                    <option value={month} key={month}>
                      {month}
                    </option>
                  );
                })}
              </Select>
            </div>
            <div className="dateSelectForm">
              <Select
                placeholder="Year"
                size="md"
                name="year"
                value={yearFrom}
                onChange={(e) => setYearFrom(e.target.value)}
              >
                {myYear.map(({ year }) => {
                  return (
                    <option value={year} key={year}>
                      {year}
                    </option>
                  );
                })}
              </Select>
            </div>
          </div> */}
          <div className="form-group d-flex justify-content-between align-items-center">
            <label className="text-label">From</label>
            <FormControl style={{ width: "64%" }}>
              <div className="datepicker-100-width">
                <DatePicker
                  id="published-date"
                  selected={dateFrom}
                  onChange={(date) => {
                    dateFrom = date;
                    setDateFrom(date);
                  }}
                  // onChange={(date) => setDateFrom(date)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="dd/mmm/yyyy"
                />
              </div>
            </FormControl>
          </div>
        </div>
      </div>
      <div className="row mt-2 w-60">
        <div className="col-xl-6 col-md-6 col-sm-12">
          {/* <div className="spaceAroundFlex">
            <div className="dateSelectForm form-group d-flex justify-content-between align-items-center">
              <label className="text-muted mr-3">To</label>
              <Select
                placeholder="Day"
                size="md"
                name="day"
                value={dayTo}
                onChange={(e) => setDayTo(e.target.value)}
              >
                {myDays.map(({ day }) => {
                  return (
                    <option value={day} key={day}>
                      {day}
                    </option>
                  );
                })}
              </Select>
            </div>
            <div className="dateSelectForm">
              <Select
                placeholder="Month"
                size="md"
                name="month"
                value={monthTo}
                onChange={(e) => setMonthTo(e.target.value)}
              >
                {myMonth.map(({ month }) => {
                  return (
                    <option value={month} key={month}>
                      {month}
                    </option>
                  );
                })}
              </Select>
            </div>
            <div className="dateSelectForm">
              <Select
                placeholder="Year"
                size="md"
                name="month"
                value={yearTo}
                onChange={(e) => setYearTo(e.target.value)}
              >
                {myYear.map(({ year }) => {
                  return (
                    <option value={year} key={year}>
                      {year}
                    </option>
                  );
                })}
              </Select>
            </div>
          </div> */}
          <div className="form-group d-flex justify-content-between align-items-center">
            <label className="text-label">To</label>
            <FormControl style={{ width: "64%" }}>
              <div className="datepicker-100-width">
                <DatePicker
                  id="published-date"
                  selected={dateTo}
                  onChange={(date) => {
                    dateTo = date;
                    setDateTo(date);
                  }}
                  // onChange={(date) => setDateTo(date)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="dd/mmm/yyyy"
                />
              </div>
            </FormControl>
          </div>
        </div>
      </div>

      <div className="row mt-2 w-60">
        <div className="col-xl-6 col-md-6">
          <div className="form-group d-flex justify-content-between align-items-center">
            <label className="text-label">Transfer ID</label>
            <Input
              style={{ width: "64%" }}
              size="md"
              name="debitAmount"
              value={transactionId}
              onChange={(e) => {
                transactionId = e.target.value;
                setTransactionId(e.target.value);
              }}
              // onChange={(e) => setTransactionId(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="row mt-2 w-60">
        <div className="col-xl-6 col-md-6">
          <div className="form-group d-flex justify-content-between align-items-center">
            <label className="text-label">Reference Number</label>
            <Input
              style={{ width: "64%" }}
              size="md"
              name="debitAmount"
              value={referenceNumber}
              onChange={(e) => {
                referenceNumber = e.target.value;
                setReferenceNumber(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="row mt-2 w-60">
        <div className="col-xl-6">
          <NewActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px" }}
            onClick={(e) => handleSearch(e)}
          >
            <img
              src={
                require("../../../../../assets/images/icons/searchWhite.svg")
                  .default
              }
              className="d-inline mr-3"
              alt="icon"
            />
            Search
          </NewActionButton>
        </div>
      </div>
    </>
  );
};

export default SearchInputs;
