import React from "react";
import ContentLoader from "react-content-loader";

const LoadingLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={100}
      height={30}
      viewBox="0 0 100 30"
      backgroundColor="#e0e0e0"
      foregroundColor="#ffffff"
    >
      <rect x="0" y="0" rx="3" ry="3" width="90" height="8" />
      <rect x="0" y="16" rx="3" ry="3" width="60" height="6" />
    </ContentLoader>
  );
};

export default LoadingLoader;
