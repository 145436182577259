//SAVINGS TRANSACTIONS CONSTANT
export const SAVINGS_TRANSACTIONS_REQUEST = "SAVINGS_TRANSACTIONS_REQUEST";
export const SAVINGS_TRANSACTIONS_SUCCESS = "SAVINGS_TRANSACTIONS_SUCCESS";
export const SAVINGS_TRANSACTIONS_FAIL = "SAVINGS_TRANSACTIONS_FAIL";
export const CLEAR_SAVINGS = "CLEAR_SAVINGS";

//LOAN TRANSACTIONS CONSTANT
export const LOAN_TRANSACTIONS_REQUEST = "LOAN_TRANSACTIONS_REQUEST";
export const LOAN_TRANSACTIONS_SUCCESS = "LOAN_TRANSACTIONS_SUCCESS";
export const LOAN_TRANSACTIONS_FAIL = "LOAN_TRANSACTIONS_FAIL";
export const CLEAR_LOAN = "CLEAR_LOAN";

//FIXED TRANSACTIONS CONSTANT
export const FIXED_TRANSACTIONS_REQUEST = "FIXED_TRANSACTIONS_REQUEST";
export const FIXED_TRANSACTIONS_SUCCESS = "FIXED_TRANSACTIONS_SUCCESS";
export const FIXED_TRANSACTIONS_FAIL = "FIXED_TRANSACTIONS_FAIL";
export const CLEAR_FIXED = "CLEAR_FIXED";






