import React, { useState, useRef, useEffect } from "react";
import { Table } from "antd";
import { InputGroup, InputLeftElement, Input } from "@chakra-ui/react";

import ModalContainer from "../../../components/ModalContainer";
import ActionModal from "./modals/ActionModal";

import CheckedWhiteSvg from "../../../../../assets/images/icons/checked-white.svg";
import { useSelector, useDispatch } from "react-redux";
import {
  clearFilteredLoans,
  filterLoans,
  processAllChecker,
} from "../../../../../actions/businessActions";
import Toast from "../../../../../util/Toast";
import { formatDate } from "../../../../../util/FormatDate";
import { useHistory } from "react-router";

const LoanApproval = () => {
  const history = useHistory();
  const businessReducer = useSelector((state) => state.businessReducer);
  const dispatch = useDispatch();
  const search = useRef("");
  const { loading, filteredLoans, checkerLoans } = businessReducer;
  const [selectedRows, setSelectedRows] = useState([]);

  const onFilterLoans = (e) => {
    search.current.value !== ""
      ? dispatch(filterLoans(e.target.value, "pendingApproval"))
      : dispatch(clearFilteredLoans());
  };

  useEffect(() => {
    if (filteredLoans === null) {
      search.current.value = "";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const viewLoan = (rowIndex) => {
    const { clientId, accountNo } = getTableRowData(rowIndex);
    history.push(
      `/core/client/${clientId}?tab=loan&type=accountNo&accountNo=${accountNo}`
    );
  };

  const viewAccount = (rowIndex) => {
    history.push(`/core/client/${getTableRowData(rowIndex).clientId}`);
  };

  const getTableRowData = (rowIndex) => {
    const data = filteredLoans !== null ? filteredLoansData : dataSource;
    return data[rowIndex];
  };

  const columns = [
    {
      title: "Client",
      dataIndex: "client",
      render: (value, record, index) => (
        <td onClick={() => viewAccount(index)}>{value}</td>
      ),
    },
    {
      title: "Loan",
      dataIndex: "loan",
      render: (value, record, index) => (
        <td onClick={() => viewLoan(index)}>{value}</td>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
    },
    {
      title: "Loan Purpose",
      dataIndex: "loanPurpose",
    },
  ];

  const dataSource = [];
  const filteredLoansData = [];

  if (filteredLoans !== null) {
    // console.log(filterLoans)

    filteredLoans.forEach((client) => {
      const {
        id,
        clientName,
        accountNo,
        approvedPrincipal,
        loanProductName,
        clientId,
      } = client;

      filteredLoansData.push({
        key: id,
        client: clientName,
        loan: `${loanProductName}(${accountNo})`,
        amount: approvedPrincipal,
        clientId,
        accountNo,
      });

      // console.log(client + 'new here')
    });
  }

  !loading &&
    checkerLoans.length &&
    checkerLoans
      .filter((cst) => cst.status.pendingApproval)
      .forEach((client) => {
        const {
          id,
          clientName,
          accountNo,
          approvedPrincipal,
          loanProductName,
          clientId,
        } = client;
        dataSource.push({
          key: id,
          client: clientName,
          loan: `${loanProductName}(${accountNo})`,
          amount: approvedPrincipal,
          clientId,
          accountNo,
        });

        // console.log(dataSource[0].client + 'dataSource')
      });

  // const dataSource = [
  //   {
  //     key: 1,
  //     client: "Clement James",
  //     loan: "23652145621",
  //     amount: "10,000",
  //   },
  //   {
  //     key: 2,
  //     client: "Samuel Victor",
  //     loan: "562359451223",
  //     amount: "5,000",
  //   },
  //   {
  //     key: 3,
  //     client: "Dami Moyin",
  //     loan: "12236551259",
  //     amount: "1,000,000",
  //   },
  // ];

  // This is for the selected Row

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  // console.log("I got here");

  const onFilterclient = () => {};

  const [modalState, toggleModal] = useState(false);
  const [modalContentName, setModalContentName] = useState("");

  const currentContent = (e) => {
    if (selectedRows.length) {
      const val = e.currentTarget.id.trim();
      if (val) {
        toggleModal(true);
        setModalContentName(val);
      }
    } else {
      dispatch(
        Toast({
          text: "No client selected",
          icon: "error",
          timer: 2000,
        })
      );
    }
  };

  const handleModalClose = () => toggleModal(false);

  const handleApprove = () => {
    let dataToSend =
      selectedRows.length &&
      selectedRows.map((data, i) => ({
        body: JSON.stringify({
          approvedOnDate: formatDate(new Date(), true),
          dateFormat: "dd MMMM yyyy",
          locale: "en",
        }),
        method: "POST",
        relativeUrl: `loans/${data.key}?command=approve`,
        requestId: i + 1,
      }));
    // console.log({ dataToSend });

    dispatch(processAllChecker(dataToSend, history));
  };

  const displayModalContent = () => {
    switch (modalContentName) {
      case "approve":
        return (
          <ActionModal
            header="Loan Approval"
            onHide={handleModalClose}
            action={handleApprove}
          />
        );

      default:
        break;
    }
  };

  return (
    <>
      <ModalContainer show={modalState}>
        {modalState && displayModalContent()}
      </ModalContainer>
      <div className="border-left">
        <div className="row mb-4">
          <div className="col-md-4">
            <h2>Head Office</h2>
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-8">
                <form
                  style={{ marginTop: "-20px" }}
                  onSubmit={(e) => onFilterclient(e)}
                >
                  <InputGroup>
                    <InputLeftElement
                      width="3.1rem"
                      height="50px"
                      pointerEvents="none"
                    >
                      <img
                        src={
                          require("../../../../../assets/images/icons/search.svg")
                            .default
                        }
                        style={{ height: "20px" }}
                        alt="Icon"
                      />
                    </InputLeftElement>
                    <Input
                      style={{
                        background: "#ffffff",
                        height: "45px",
                      }}
                      type="text"
                      placeholder="Search by name"
                      ref={search}
                      onChange={onFilterLoans}
                    />
                  </InputGroup>
                </form>
              </div>
              <div className="col-md-4">
                <div
                  onClick={currentContent}
                  id="approve"
                  className="approve-btn"
                >
                  <img className="mr-4" src={CheckedWhiteSvg} alt="" />
                  Approve
                </div>
              </div>
            </div>
          </div>
        </div>
        <Table
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          columns={columns}
          dataSource={filteredLoans !== null ? filteredLoansData : dataSource}
        />
      </div>
    </>
  );
};

export default LoanApproval;
