import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Toast from "../../util/Toast";
import SuccessSvg from "../../assets/notification-icons/success.svg";
import InfoSvg from "../../assets/notification-icons/info.svg";
import FailedSvg from "../../assets/notification-icons/failed.svg";
// import YellowSvg from "../../assets/notification-icons/yellow.svg";
import CancelErrorSvg from "../../assets/notification-icons/cancel-failed.svg";
import CancelSuccessSvg from "../../assets/notification-icons/cancel-success.svg";
import CancelInfoSvg from "../../assets/notification-icons/cancel-info.svg";

import "./index.css";

const ToastManager = () => {
  const dispatch = useDispatch();
  const notification = useSelector((state) => state.notification);

  let { text = "", icon = "", timer } = notification.notification || {};
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!!text.length) {
      setShow(true);
      setTimeout(
        () => {
          setShow(false);
          dispatch(Toast({ text: "", icon: "" }));
        },
        !!timer ? timer : 10000
      );
    } else {
      setShow(false);
    }
  }, [text, timer, dispatch]);

  return (
    <>
      <div className={`notification-container  ${show ? "show" : ""}`}>
        <div className={`row notification success toast-${icon}`}>
          <div className="col-2 notification-image">
            {icon === "success" ? <img src={SuccessSvg} alt="" /> : null}
            {icon === "info" ? <img src={InfoSvg} alt="" /> : null}
            {icon === "error" ? <img src={FailedSvg} alt="" /> : null}
          </div>
          <div className="col-8">
            <p
              style={{
                color: `${
                  icon === "success"
                    ? "#1dcb43"
                    : icon === "error"
                    ? "red"
                    : "#2C1DFF"
                }`,
              }}
              className="notification-message linkSavings"
            >
              {text}
            </p>
          </div>
          <div
            className="col-2 cancelImg"
            onClick={(e) => {
              e.preventDefault();
              setShow(false);
            }}
          >
            {icon === "success" ? <img src={CancelSuccessSvg} alt="" /> : null}
            {icon === "info" ? <img src={CancelInfoSvg} alt="" /> : null}
            {icon === "error" ? <img src={CancelErrorSvg} alt="" /> : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default ToastManager;
