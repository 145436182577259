import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { FormClass, FormElements, ActionButton } from "../styles.jsx";
import {
  uploadCustomerImage,
  updateCustomerImage,
} from "../../../../../actions/customerActions.js";
import Toast from "../../../../../util/Toast.js";

import FileUpload from "../../../../../assets/images/file-upload.svg";

const AddProfilePicture = ({ onHide, imgData, singleUser, history }) => {
  const dispatch = useDispatch();

  const [file, setFile] = useState(null);

  // console.log(file, "outtt");

  const handleSubmitProfilePicture = async () => {
    if (file === null || file === undefined || !file) {
      dispatch(Toast({ text: "Please select photo", icon: "info" }));
    } else if (imgData && file !== null) {
      const formData = new FormData();
      formData.append("file", file);
      await dispatch(updateCustomerImage(singleUser.id, formData));
      onHide();
    } else if (singleUser && singleUser.id && file !== null) {
      const formData = new FormData();
      formData.append("file", file);
      await dispatch(uploadCustomerImage(singleUser.id, formData, history));
      onHide();
    } else {
      dispatch(
        Toast({ text: "Something went wrong.. check Internet", icon: "error" })
      );
    }
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>Upload Profile Picture</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <FormElements style={{ width: "100%" }}>
            <div className="d-flex justify-content-center my-4">
              <div className="draganddrop">
                <input
                  type="file"
                  name="document"
                  id="signature"
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    opacity: "0",
                    cursor: "pointer",
                  }}
                  onChange={(e) => setFile(e.target.files[0])}
                  onDrop={(e) => setFile(e.target.files[0])}
                  onDragOver={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
                <div className="draganddrop-dd">
                  <div className="d-flex justify-content-center my-4">
                    <img className="file-Img" src={FileUpload} alt="" />
                  </div>
                  <p className="text-center">
                    Drag & drop your files here <br /> or
                  </p>
                  <ActionButton
                    color="#2C1DFF"
                    className="mt-4"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <input
                      type="file"
                      id="signature"
                      name="image"
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        top: "0",
                        left: "0",
                        opacity: "0",
                        cursor: "pointer",
                      }}
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                    Choose File
                  </ActionButton>
                  {!!file ? <p>{file.name}</p> : ""}
                </div>
              </div>
            </div>{" "}
            {/* <div className="d-flex justify-content-center my-4">
              <div className="draganddrop">
                <input
                  type="file"
                  name="document"
                  id="signature"
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    opacity: "0",
                    cursor: "pointer",
                  }}
                  onChange={(e) => setFile(e.target.files[0])}
                  onDrop={(e) => setFile(e.target.files[0])}
                  onDragOver={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
                <div className="draganddrop-dd">
                  <div className="d-flex justify-content-center my-4">
                    <img className="file-Img" src={FileUpload} alt="" />
                  </div>
                  <p className="text-center">
                    Drag & drop your files here <br /> or
                  </p>
                  <ActionButton
                    color="#2C1DFF"
                    className="mt-4"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <input
                      type="file"
                      id="signature"
                      name="image"
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        top: "0",
                        left: "0",
                        opacity: "0",
                        cursor: "pointer",
                      }}
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                    Choose File
                  </ActionButton>
                  {!!file ? <p>{file.name}</p> : ""}
                </div>
              </div>
            </div> */}
          </FormElements>
        </FormClass>
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-4">
          <ActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require("../../../../../assets/images/icons/cancel.svg").default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={handleSubmitProfilePicture}
          >
            <img
              src={
                require("../../../../../assets/images/icons/save.svg").default
              }
              alt="submit icon"
            />
            Submit
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default AddProfilePicture;
