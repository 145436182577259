import config from "../../config";
import axiosData from "../axiosData";

export const getAllCurrencies = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: userData,
        url: `${config.apiGateway.BASE_API_URL}/currencies?fields=selectedCurrencyOptions`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const getAllOffices = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: userData,
        url: `${config.apiGateway.BASE_API_URL}/offices`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const getGlAccounts = (type) => {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${config.apiGateway.BASE_API_URL}/glaccounts`;

      if (type !== undefined) {
        url += `?type=${type}&manualEnteries=1&systemDefined=0`;
      } else {
        url += `?disabled=false&manualEntriesAllowed=true&usage=1`;
      }
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: userData,
        url: url,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const getGlAccountsBalanceEOY = (date , currency) => {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${config.apiGateway.BASE_API_URL}/glaccounts/glbalance?types=4,5&dateFormat=dd MMMM yyyy&date=${date}&currency=${currency}`;

      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: userData,
        url: url,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getAllSavingsAccounts = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: userData,
        url: `${config.apiGateway.BASE_API_URL}/savingsaccounts`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const getAllPaymentTypes = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: userData,
        url: `${config.apiGateway.BASE_API_URL}/paymenttypes`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const createAnAccountToJournalEntry = (dataToSend) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/journalentries?command=acc-gl`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getAmortizations = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: userData,
        url: `${config.apiGateway.UTILITY_API_URL}/fincon/amortizations`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const createAmortization = (sendingData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        data: sendingData,
        url: `${config.apiGateway.UTILITY_API_URL}/fincon/amortizations`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// JOURNAL ENTRY
export const addNewJournalEntry = (journalEntry) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        data: journalEntry,
        url: `${config.apiGateway.BASE_API_URL}/journalentries`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const getOneJournalEntry = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: activity,
        url: `${config.apiGateway.BASE_API_URL}/journalentries?transactionDetails=true&transactionId=${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const reverseJournalEntry = (id, dataToSend) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/journalentries/${id}?command=reverse`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const getAllJournalEntry = (
  office,
  accNameOrCode,
  entry,
  searchDayFrom,
  searchDayTo,
  transactionId,
  referenceNumber,
  selectedUser,
  offset
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: jourEntry,
        url: `${
          config.apiGateway.BASE_API_URL
        }/journalentries?sortOrder=DESC&orderBy=transaction_date&dateFormat=dd MMMM yyyy${
          !!office ? `&officeId=${!!office ? office : ""}` : ""
        }${
          !!searchDayFrom
            ? `&fromDate=${!!searchDayFrom ? searchDayFrom : ""}`
            : ""
        }${!!searchDayTo ? `&toDate=${!!searchDayTo ? searchDayTo : ""}` : ""}${
          !!entry ? `&manualEntriesOnly=${!!entry ? entry : ""}` : ""
        }${
          !!transactionId
            ? `&transactionId=${!!transactionId ? transactionId : ""}`
            : ""
        }${
          !!referenceNumber
            ? `&referenceNumber=${!!referenceNumber ? referenceNumber : ""}`
            : ""
        }${
          !!selectedUser ? `&userId=${!!selectedUser ? selectedUser : ""}` : ""
        }${
          !!accNameOrCode
            ? `&glAccountId=${!!accNameOrCode ? accNameOrCode : ""}`
            : ""
        }&limit=14&locale=en${`&offset=${!!offset ? offset : 0}`}`,

        // api/v1/journalentries?dateFormat=dd+MMMM+yyyy&limit=14&locale=en&offset=28

        // officeId=${
        //   !!office ? office : ""
        // }&fromDate=${!!searchDayFrom ? searchDayFrom : ""}&toDate=${
        //   !!searchDayTo ? searchDayTo : ""
        // }&manualEntriesOnly=${!!entry ? entry : ""}&glAccountId=${
        //   !!accNameOrCode ? accNameOrCode : ""
        // }&transactionId=${
        //   !!transactionId ? transactionId : ""
        // }&locale=en&offset=0`,
      });

      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// FINANCIAL ACTIVITY
export const getAllFinancialActivity = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: jourEntry,
        url: `${config.apiGateway.BASE_API_URL}/financialactivityaccounts`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const getAllFinancialActivityTemplate = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: jourEntry,
        url: `${config.apiGateway.BASE_API_URL}/financialactivityaccounts/template`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const addNewFinancialActivity = (activity) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        data: activity,
        url: `${config.apiGateway.BASE_API_URL}/financialactivityaccounts`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const editFinancialActivity = (activity, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "PUT",
        // signal: cancelToken ? cancelToken : false,
        data: activity,
        url: `${config.apiGateway.BASE_API_URL}/financialactivityaccounts/${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const deleteFinancialActivity = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "DELETE",
        // signal: cancelToken ? cancelToken : false,
        // data: activity,
        url: `${config.apiGateway.BASE_API_URL}/financialactivityaccounts/${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Chart OF ACCOUNT
export const getChartsOfAccount = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: activity,
        url: `${config.apiGateway.BASE_API_URL}/glaccounts`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const getAChartOfAccount = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: activity,
        url: `${config.apiGateway.BASE_API_URL}/glaccounts/${id}?template=true`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const getChartsOfAccountTemplate = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: activity,
        url: `${config.apiGateway.BASE_API_URL}/glaccounts/template?type=0`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const addChartsOfAccount = (newChart) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        data: newChart,
        url: `${config.apiGateway.BASE_API_URL}/glaccounts`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const editChartsOfAccount = (newChart, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "PUT",
        // signal: cancelToken ? cancelToken : false,
        data: newChart,
        url: `${config.apiGateway.BASE_API_URL}/glaccounts/${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const deleteChartsOfAccount = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "DELETE",
        // signal: cancelToken ? cancelToken : false,
        // data: newChart,
        url: `${config.apiGateway.BASE_API_URL}/glaccounts/${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const editAccountongRule = (newChart, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "PUT",
        // signal: cancelToken ? cancelToken : false,
        data: newChart,
        url: `${config.apiGateway.BASE_API_URL}/accountingrules/${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const deleteAccountingRule = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "DELETE",
        // signal: cancelToken ? cancelToken : false,
        // data: newChart,
        url: `${config.apiGateway.BASE_API_URL}/accountingrules/${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const disableEnableAChartsOfAccount = (status, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "PUT",
        // signal: cancelToken ? cancelToken : false,
        data: { disabled: status },
        url: `${config.apiGateway.BASE_API_URL}/glaccounts/${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// ACCOUNT CLOSURES
export const getAccountClosures = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: activity,
        url: `${config.apiGateway.BASE_API_URL}/glclosures`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const createAccountClosures = (accountClosure) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        data: accountClosure,
        url: `${config.apiGateway.BASE_API_URL}/glclosures`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const updateAccountClosures = (updateDetails, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "PUT",
        // signal: cancelToken ? cancelToken : false,
        data: updateDetails,
        url: `${config.apiGateway.BASE_API_URL}/glclosures/${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const deleteAccountClosures = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "DELETE",
        // signal: cancelToken ? cancelToken : false,
        // data: updateDetails,
        url: `${config.apiGateway.BASE_API_URL}/glclosures/${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// ACCOUNT RULES
export const getAccountRules = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: activity,
        url: `${config.apiGateway.BASE_API_URL}/accountingrules`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const getAccountRulesTemplate = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: activity,
        url: `${config.apiGateway.BASE_API_URL}/accountingrules/template`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const createAccountRules = (accountRule) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        data: accountRule,
        url: `${config.apiGateway.BASE_API_URL}/accountingrules`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const updateAccountRule = (updateDetails, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "PUT",
        // signal: cancelToken ? cancelToken : false,
        data: updateDetails,
        url: `${config.apiGateway.BASE_API_URL}/accountingrules/${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const deleteAccountRule = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "DELETE",
        // signal: cancelToken ? cancelToken : false,
        // data: updateDetails,
        url: `${config.apiGateway.BASE_API_URL}/accountingrules/${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
