// import API_CONFIGURATION from "../services/Api/settlement.api.services";

import { SET_LOADING_STATE } from "../constants/accountingConstants";
import {
  SETTLE_EOY_REQUEST,
  SETTLE_EOY_SUCCESS,
  SETTLE_EOY_FAIL,
  //   SETTLE_EOY_RESET,
} from "../constants/settlementConstants";
import createUserSettlement from "../services/Api/settlement.api.services";
import Toast from "../util/Toast";

const userSettlementFail = (payload) => {
  return { type: SETTLE_EOY_FAIL, payload };
};

const sendUserSettlement = (userData, setModalOpen) => {
  return async (dispatch) => {
    dispatch({ type: SETTLE_EOY_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });

    await createUserSettlement(userData)
      .then(async (response) => {
        dispatch({ type: SETTLE_EOY_SUCCESS, payload: response });
        dispatch(Toast({ text: "Request Successful", icon: "success" }));
        setModalOpen(true);
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch({ type: SET_LOADING_STATE, payload: false });

        dispatch(userSettlementFail(ex));
      });
  };
};

export { sendUserSettlement };
