import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCashierTemplate } from "../../../../actions/tellerActions";
import { Select as AntSelect } from "antd";
import CurrencyFormat from "react-currency-format";

// arr1 = [1,2,3,4,5]
// arr2 = [4,2]

// arr1.map(arr1_item => {
//   return arr2.includes(arr1_item) ? console.log('render this currency') : 'pass on this'
// })

const CurrencyTeller = ({
  currency,
  cashierTemplate,
  numberOfCurrencies,
  setNumberOfCurrencies,
  currencyIndex,
  optionSelected,
  //   currencyCode,
  newLocal,
}) => {
  const { Option } = AntSelect;

  // const [newData, setNewData] = useState("");

  const tellerOption =
    cashierTemplate?.glAccountOptions?.length > 0 &&
    cashierTemplate?.glAccountOptions.map(({ id, name }) => {
      return (
        <>
          <Option key={id} value={id}>
            {name}
          </Option>
        </>
      );
    });

  // console.log([...numberOfCurrencies], "inside currencyTeller");

  // let newData = "";
  // let newCurrency = "";

  // let newObject = {
  //   currencyCode: newLocal,
  //   // currencyName: "",
  //   dailyLimit: "",
  //   glAccountId: "",
  //   // glAccountName: "",
  //   withdrawalLimit: "",
  // };

  const handleDailyTellerLimitToChange = (position, timeStamp) => {
    let hhh = [...numberOfCurrencies];
    // console.log([...numberOfCurrencies, defaultCurrenciesAcct]);

    // console.log(hhh, "hhh");
    // console.log(optionSelected, "optionSelected");
    // for (let i = 0; i < optionSelected.length; i++) {
    //   for (let j = 1; j < hhh.length; j++) {
    //     if (optionSelected[i] !== hhh[j]) {
    //       newCurrency = optionSelected[i];
    //       // newData = newCurrency;
    //     }
    //   }
    // }
    // console.log(newCurrency, "newCurrency");
    // // console.log(newLocal, "newData before if");

    // if (newCurrency !== newLocal) {
    //   hhh.push(newObject);
    //   newData = newCurrency;
    //   localStorage.setItem("newData", newData);
    //   console.log(newData, "newData inside if");
    // }

    hhh[Number(position)].dailyLimit = timeStamp;
    setNumberOfCurrencies(hhh);
  };

  const handleWithdrawalLimitToChange = (position, timeStamp) => {
    let hhh = [...numberOfCurrencies];
    hhh[Number(position)].withdrawalLimit = timeStamp;
    setNumberOfCurrencies(hhh);
  };

  // console.log(numberOfCurrencies, "before handleWithdrawalLimitToChange ")
  // const handleWithdrawalLimitToChange = (position, value, timeStamp) => {
  //   console.log("--------- withdraw")
  //   let hhh = [...numberOfCurrencies];
  //   console.log(hhh[Number(position)] , "hhh[Number(position)]")
  //   let currencyObj = hhh[Number(position)];
  //   console.log("--------- i dey outside if" , currencyObj)
  //   if (currencyObj) {
  //   console.log("--------- i enter if" , currencyObj)

  //     console.log("i dey here inside if")
  //     if (timeStamp !== null) {
  //       currencyObj.timeStamp = timeStamp;
  //     }
  //     currencyObj.withdrawalLimit = timeStamp;
  //     console.log(hhh , "withdraw");
  //     setNumberOfCurrencies(hhh);
  //   }
  // };

  const handleTellerToChange = (position, timestamp) => {
    let hhh = [...numberOfCurrencies];
    hhh[Number(position)].glAccountId = timestamp;
    setNumberOfCurrencies(hhh);
  };

  // const handleTellerToChange = (position, value, timeStamp) => {
  //   let hhh = [...numberOfCurrencies];
  //   let currencyObj = hhh[Number(position)];
  //   if (currencyObj) {
  //     if (timeStamp !== null) {
  //       currencyObj.timeStamp = timeStamp;
  //     }
  //     currencyObj.glAccountId = timeStamp;
  //     console.log(hhh);
  //     console.log(value , "gl");
  //     setNumberOfCurrencies(hhh);
  //   }
  // };

  // const handleTellerToChange = (position, timestamp) => {
  //   setNumberOfCurrencies(prevState => prevState.map((item, index) => {
  //     if (index === position) {
  //       console.log("inside if of teller")
  //       return { ...item, glAccountId: timestamp };
  //     } else {
  //       return item;
  //     }
  //   }));
  //   console.log(numberOfCurrencies , "inside teller")
  // };

  const removeCurrencyDetails = (position) => {
    let noo = [];
    numberOfCurrencies.forEach((element, index) => {
      if (position !== index) {
        noo.push(element);
      }
    });
    setNumberOfCurrencies(noo);
  };

  return (
    <>
      <div className="justify-content-between">
        <div className="dflex">
          <div className="">
            <p className="tellerCurr-Text">
              <span>{currency ? currency : ""}</span>
            </p>
          </div>
          <div className="">
            <hr
              style={{
                width: "350px",
                background: "#DADCE5",
              }}
            />
          </div>
        </div>
        {/* {numberOfCurrencies?.map((displayCurr, index) => { */}
        <>
          <div
            className="d-flex justify-content-between"
            style={{ paddingBottom: "15px" }}
          >
            <label
              htmlFor="Withdrawal Limit"
              className="tellerCurr-Text"
              style={{ width: "48%" }}
            >
              Teller GL
              <span className="required-color">*</span>
            </label>
            <div className="" style={{ width: "68%" , marginRight: "13px" }}>
              <AntSelect
                className="searchSelect"
                value={
                  numberOfCurrencies[currencyIndex]?.glAccountId
                    ? numberOfCurrencies[currencyIndex]?.glAccountId
                    : undefined
                }
                name="tellerGroup"
                showSearch
                placeholder="Select Teller"
                emptyMessage="Not found"
                onSelect={(value) => {
                  handleTellerToChange(currencyIndex, value);
                }}
                // style={{ width: "60%" }}
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    ?.indexOf(input?.toLowerCase()) >= 0
                }
              >
                {tellerOption}
              </AntSelect>
            </div>
          </div>
          <div
            className="d-flex justify-content-between"
            style={{ paddingBottom: "15px" }}
          >
            <label
              htmlFor="Withdrawal Limit"
              className="tellerCurr-Text"
              style={{ width: "48%" }}
            >
              Withdrawal Limit
              <span className="required-color">*</span>
            </label>
            <div className="" style={{ width: "73%" }}>
              <span>{numberOfCurrencies?.currency}</span>
              <CurrencyFormat
                required
                value={numberOfCurrencies[currencyIndex]?.withdrawalLimit}
                thousandSeparator={true}
                className="amount-input"
                style={{
                  width: "91%",
                  // marginLeft: "16px",
                }}
                onValueChange={(values) => {
                  const { value } = values;
                  handleWithdrawalLimitToChange(currencyIndex, value);
                }}
              />
            </div>
          </div>
          <div
            className="d-flex justify-content-between "
            style={{ paddingBottom: "15px" }}
          >
            <label
              htmlFor="Daily Teller Limit"
              className="tellerCurr-Text"
              style={{ width: "48%" }}
            >
              Daily Teller Limit
              <span className="required-color">*</span>
            </label>
            <div className="" style={{ width: "73%" }}>
              <CurrencyFormat
                required
                value={numberOfCurrencies[currencyIndex]?.dailyLimit}
                thousandSeparator={true}
                className="amount-input"
                style={{
                  width: "91%",
                  // marginLeft: "16px",
                }}
                onValueChange={(values) => {
                  const { value } = values;
                  handleDailyTellerLimitToChange(currencyIndex, value);
                }}
              />
            </div>
          </div>
        </>
        {/* })} */}
      </div>
    </>
  );
};

export default CurrencyTeller;
