import {
  PROCESS_CHECKER,
  SET_LOADING_STATE,
  GET_LOANS_SUCCESS,
  GET_CLIENTS_SUCCESS,
  GET_MAKER_CHECKER_SUCCESS,
  GET_MAKER_CHECKER_TEMPLATE_SUCCESS,
  GET_PENDING_RES_LOANS_SUCCESS,
  FILTER_CLIENTS_REQUEST,
  CLEAR_CLIENTS_FILTER,
  FILTER_LOANS_REQUEST,
  CLEAR_LOANS_FILTER,
  FILTER_MAKER_REQUEST,
  CLEAR_MAKER_FILTER,
  FILTER_RES_LOANS_REQUEST,
  CLEAR_RES_LOANS_FILTER,
  GET_CHECKER_INBOX_DETAILS_SUCCESS,
} from "../constants/businessConstants";

const initialState = {
  loading: false,
  processChecker: null,
  checkerClients: [],
  filteredClients: null,
  checkerLoans: [],
  filteredLoans: null,
  checkerMakerChecker: [],
  checkerInboxDetails: {},
  filteredMakers: null,
  checkerPendingResLoans: [],
  filteredPendingResLoans: null,
  checkerMakerCheckerTemplate: {},
};

const setBusinessReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING_STATE: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case GET_CLIENTS_SUCCESS: {
      // console.log(action.payload);
      return {
        ...state,
        checkerClients: action.payload,
      };
    }
    case GET_LOANS_SUCCESS: {
      return {
        ...state,
        checkerLoans: action.payload,
      };
    }
    case GET_MAKER_CHECKER_SUCCESS: {
      return {
        ...state,
        checkerMakerChecker: action.payload,
      };
    }
    case GET_CHECKER_INBOX_DETAILS_SUCCESS: {
      return {
        ...state,
        checkerInboxDetails: action.payload,
      };
    }
    case GET_MAKER_CHECKER_TEMPLATE_SUCCESS: {
      return {
        ...state,
        checkerMakerCheckerTemplate: action.payload,
      };
    }
    case GET_PENDING_RES_LOANS_SUCCESS: {
      return {
        ...state,
        checkerPendingResLoans: action.payload,
      };
    }
    case PROCESS_CHECKER: {
      return {
        ...state,
        processChecker: action.payload,
      };
    }
    case FILTER_CLIENTS_REQUEST:
      return {
        ...state,
        filteredClients: state.checkerClients.filter((cst) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return cst.displayName.match(regex);
        }),
      };
    case CLEAR_CLIENTS_FILTER:
      return { ...state, filteredClients: null };

    case FILTER_LOANS_REQUEST:
      return {
        ...state,
        filteredLoans: state.checkerLoans.filter((cst) => {
          const regex = new RegExp(`${action.payload.filterParam}`, "gi");
          return cst.clientName.match(regex) && cst.status[action.payload.type];
        }),
      };
    case CLEAR_LOANS_FILTER:
      return { ...state, filteredLoans: null };

    case FILTER_MAKER_REQUEST:
      return {
        ...state,
        filteredMakers: state.checkerMakerChecker?.pageItems?.filter((cst) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return cst.maker.match(regex);
        }),
      };
    case CLEAR_MAKER_FILTER:
      return { ...state, filteredMakers: null };
    case FILTER_RES_LOANS_REQUEST:
      return {
        ...state,
        filteredPendingResLoans: state.checkerPendingResLoans.filter((cst) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return cst.maker.match(regex);
        }),
      };
    case CLEAR_RES_LOANS_FILTER:
      return { ...state, filteredPendingResLoans: null };
    default: {
      return state;
    }
  }
};

export default setBusinessReducers;
