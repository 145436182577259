import React from "react";
import { Modal, Table } from "react-bootstrap";
import { ActionButton } from "../../styles";
import { useDispatch } from "react-redux";
import { getSavingsAccountStatementAction } from "../../../../../../actions/SavingsAndLoanActions";
import { formatCurrency } from "../../../../../../util/FormatAmount";
import { formatDate } from "../../../../../../util/FormatDate";

const TransactionDetailsModal = ({ onHide, transactionDetails }) => {
  const mappedTransactionDetails = {
    id: transactionDetails.id,
      transDate: formatDate(transactionDetails.date),
      accountNo: transactionDetails.accountNo,
      currencyCode: transactionDetails.currency.code,
      debit:
        transactionDetails.transactionType.withdrawal ||
          transactionDetails.transactionType.feeDeduction ||
          transactionDetails.transactionType.dividendPayout ||
          transactionDetails.transactionType.initiateTransfer
          ? `${transactionDetails.currency.code} ${formatCurrency(transactionDetails.amount)}`
          : "",
      credit:
        transactionDetails.transactionType.deposit ||
          transactionDetails.transactionType.interestPosting ||
          transactionDetails.transactionType.amountRelease
          ? `${transactionDetails.currency.code} ${formatCurrency(transactionDetails.amount)}`
          : "",
      transactionType: transactionDetails.transactionType.value,
      narration: transactionDetails.note || transactionDetails.transactionDetailstransfer?.transferDescription,
      balance: `${transactionDetails.currency.code} ${formatCurrency(transactionDetails.runningBalance)}`,
      submittedByUsername: transactionDetails.submittedByUsername,
      transaction: {}
  }
  let {
    id,
    accountNo,
    balance,
    credit,
    debit,
    transDate,
    transactionType,
    submittedByUsername,
    narration,
    transaction = {},
  } = mappedTransactionDetails;

  const dispatch = useDispatch();
  return (
    <>
      <Modal.Header>
        <Modal.Title>Transaction Details</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <div className="full-width mt-4">
          <Table responsive striped bordered hover>
            <thead className="border-0">
              <tr>
                <td className="td-font-bold md-font-weight">Account Number</td>
                <td>{accountNo}</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td-font-bold md-font-weight">Balance</td>
                <td>{balance}</td>
              </tr>
              <tr>
                <td className="td-font-bold md-font-weight">
                  Transaction Type
                </td>
                <td>{transactionType}</td>
              </tr>
              <tr>
                <td className="td-font-bold md-font-weight">Debit</td>
                <td>{debit}</td>
              </tr>{" "}
              <tr>
                <td className="td-font-bold md-font-weight">Credit</td>
                <td>{credit}</td>
              </tr>
              <tr>
                <td className="td-font-bold md-font-weight">
                  Transaction Date
                </td>
                <td>{transDate}</td>
              </tr>
              <tr>
                <td className="td-font-bold md-font-weight">Submitted By</td>
                <td>{submittedByUsername}</td>
              </tr>
              <tr>
                <td className="td-font-bold md-font-weight">Narration</td>
                <td>{narration}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="spaceBetweenFlexEnd mt-2">
          <div className="">
            <ActionButton color="#2C1DFF" onClick={onHide}>
              <img
                src={
                  require("../../../../../../assets/images/icons/cancel.svg")
                    .default
                }
                alt="cancel icon"
              />
              Cancel
            </ActionButton>
            <ActionButton
              color="#2C1DFF"
              onClick={() => {
                dispatch(getSavingsAccountStatementAction(id));
              }}
            >
              <img
                src={
                  require("../../../../../../assets/images/icons/download.svg")
                    .default
                }
                alt="add"
                className="icon-img"
              />
              Download receipt
            </ActionButton>
          </div>
        </div>
      </Modal.Footer>
    </>
  );
};

export default TransactionDetailsModal;
