import React from "react";
import { Table } from "antd";
import Loading from "./Loading";

const columns = [
  { title: "Holiday Name", dataIndex: "name", key: "name" },
  { title: "Start Date", dataIndex: "startDate", key: "startDate" },
  { title: "End Date", dataIndex: "endDate", key: "endDate" },
  {
    title: "Repayments Scheduled To",
    dataIndex: "repayments",
    key: "repayments",
  },
  { title: "", dataIndex: "status", key: "status" },
  { title: "", dataIndex: "actions", key: "actions" },
];

const TableLoader = (props) => {
  const someChartsData = [
    {
      key: 1,
      name: <Loading />,
      startDate: <Loading />,
      endDate: <Loading />,
      repayments: <Loading />,
      status: <Loading />,
      actions: <Loading />,
    },
    {
      key: 2,
      name: <Loading />,
      startDate: <Loading />,
      endDate: <Loading />,
      repayments: <Loading />,
      status: <Loading />,
      actions: <Loading />,
    },
    {
      key: 3,
      name: <Loading />,
      startDate: <Loading />,
      endDate: <Loading />,
      repayments: <Loading />,
      status: <Loading />,
      actions: <Loading />,
    },
    {
      key: 4,
      name: <Loading />,
      startDate: <Loading />,
      endDate: <Loading />,
      repayments: <Loading />,
      status: <Loading />,
      actions: <Loading />,
    },
    {
      key: 5,
      name: <Loading />,
      startDate: <Loading />,
      endDate: <Loading />,
      repayments: <Loading />,
      status: <Loading />,
      actions: <Loading />,
    },
  ];
  return (
    <>
      <Table
        columns={columns}
        dataSource={someChartsData}
        pagination={{ defaultPageSize: 8 }}
        className="antdTable"
        defaultPageSize={8}
      />
    </>
  );
};

export default TableLoader;
