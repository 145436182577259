import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Input } from "@chakra-ui/react";
import { Menu, Dropdown } from "antd";

import { ContentWrapper, ActionButton, NewActionButton } from "../styles";

import { RoleButton } from "../CreateNewUser/styles";
import { GENERATED_USER_KEY } from "../../../../../constants/configurationConstants";

import EditUser from "../components/EditUser";
import DeleteUser from "../components/DeleteUser";
import ResetUserPassword from "../components/ResetUserPassword";
import GenerateApiKey from "../components/GenerateApiKey";
import CheckPermission from "../../../../../util/CheckPermission";

import ModalContainer from "../../../components/ModalContainer";

import EditProfile from "../../../../../assets/images/icons/colored-edit-profile.svg";
import ArrowDown from "../../../../../assets/images/icons/arrow-down2.svg";
import ChangePasswordSvg from "../../../../../assets/images/icons/colored-change-password.svg";
import DeleteSvg from "../../../../../assets/images/icons/colored-delete.svg";
import ArrowRight from "../../../../../assets/images/icons/point-right.svg";
import cancelSvg from "../../../../../assets/images/icons/cancel-black.svg";

import {
  getUserApiKeys,
  manageApiKeys,
} from "../../../../../actions/configurationActions";

import { formatDate } from "../../../../../util/FormatDate";

import DeleteApiKey from "../components/DeleteApiKey";

const UserDetails = ({ match, history }) => {
  const dispatch = useDispatch();

  const userList = useSelector((state) => state.userReducer);

  const { users, userApiKeys } = userList;

  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  const userTemplate = useSelector((state) => state.userTemplate);

  const { template } = userTemplate;

  const [user, setUser] = useState({});

  let isApiUser = user && user.isApiUser;

  useEffect(() => {
    if (match && match.params && match.params.id && isApiUser) {
      // Get user Api keys
      let header = {
        action: "DISPLAY",
      };
      dispatch(getUserApiKeys(match.params.id, header));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id, isApiUser]);

  const [userr, setUserr] = useState({
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    officeName: "",
    officeId: "",
    staffId: "",
    selectedRoles: [],
  });

  useEffect(() => {
    let userArray = users.filter((val) => val.id === Number(match.params.id));
    setUser(userArray[0]);
    setUserr({
      firstname: userArray[0] ? userArray[0].firstname : "",
      lastname: userArray[0] ? userArray[0].lastname : "",
      username: userArray[0] ? userArray[0].username : "",
      email: userArray[0] ? userArray[0].email : "",
      officeName: userArray[0] ? userArray[0].officeName : "",
      officeId: userArray[0] ? String(userArray[0].officeId) : "",
      staffId: userArray[0]
        ? userArray[0].staff
          ? String(userArray[0].staff.id)
          : ""
        : "",
      selectedRoles: userArray[0] ? userArray[0].selectedRoles : [],
    });
  }, [match, users]);

  //   let userArray = users.filter((val) => val.id === Number(match.params.id));

  let { firstname, lastname, username, email, officeName, selectedRoles } =
    userr;

  const [modalState, toggleModal] = useState(false);
  const [modalContentName, setModalContentName] = useState("");

  const handleModalClose = () => toggleModal(false);
  const handleGeneratedKeyModalClose = () => {
    toggleModal(false);
    dispatch({ type: GENERATED_USER_KEY, payload: "" });
  };

  const currentContent = (e) => {
    const val = e.currentTarget.id.trim();

    if (val) {
      toggleModal(true);
      setModalContentName(val);
    }
  };

  const [currentApiKeyId, setCurrentApiKeyId] = useState("");
  const [currentStatus, setCurrentStatus] = useState();

  const removeApiKey = () => {
    let headers = {
      action: "DELETE",
    };
    if (currentApiKeyId) {
      dispatch(manageApiKeys(currentApiKeyId, headers, match.params.id));
    }
    handleModalClose();
  };
  const handlStatusApiKey = () => {
    let headers = {
      action: currentStatus ? "DEACTIVATE" : "ACTIVATE",
    };
    if (currentApiKeyId) {
      dispatch(manageApiKeys(currentApiKeyId, headers, match.params.id));
    }
    handleModalClose();
  };

  const displayModalContent = () => {
    switch (modalContentName) {
      case "editUser":
        return (
          <EditUser template={template} user={user} onHide={handleModalClose} />
        );
      case "deleteUser":
        return (
          <DeleteUser
            id={user.id}
            onHide={handleModalClose}
            history={history}
          />
        );

      case "generateApiKey":
        return (
          <GenerateApiKey id={user.id} onHide={handleGeneratedKeyModalClose} />
        );

      case "resetUserPassword":
        return (
          <ResetUserPassword
            id={user?.id}
            username={user?.username}
            onHide={handleModalClose}
          />
        );
      case "deleteApiKey":
        return (
          <DeleteApiKey
            onHide={handleModalClose}
            action={removeApiKey}
            header="Delete Api Key"
            desc="Are you sure you want to delete this api key?"
          />
        );
      case "statusApiKey":
        return (
          <DeleteApiKey
            onHide={handleModalClose}
            action={handlStatusApiKey}
            header={`${currentStatus ? "Deactivate" : "Activate"} Api Key`}
            desc={`Are you sure you want to ${
              currentStatus ? "deactivate" : "activate"
            } this api key?`}
          />
        );
      default:
        return;
    }
  };

  const menu = (
    <Menu>
      {CheckPermission("UPDATE_USER", permissions) ? (
        <Menu.Item>
          <div
            className="new-dropdown-menu"
            id="editUser"
            onClick={(e) => {
              currentContent(e);
            }}
          >
            <img src={EditProfile} alt="" className="mr-2" /> Edit Profile
          </div>
        </Menu.Item>
      ) : (
        ""
      )}
      {CheckPermission("UPDATE_USER", permissions) ? (
        <Menu.Item>
          <div
            className="new-dropdown-menu"
            id="resetUserPassword"
            onClick={(e) => {
              currentContent(e);
            }}
          >
            <img src={ChangePasswordSvg} alt="" className="mr-2" /> Reset
            Password
          </div>
        </Menu.Item>
      ) : (
        ""
      )}
      {CheckPermission("DELETE_USER", permissions) ? (
        <Menu.Item>
          <div
            className="new-dropdown-menu"
            id="deleteUser"
            onClick={(e) => {
              currentContent(e);
            }}
          >
            <img src={DeleteSvg} alt="" className="mr-2" /> Delete
          </div>
        </Menu.Item>
      ) : (
        <p style={{ padding: "10px" }}>No action available</p>
      )}
    </Menu>
  );

  const [showKeys, setShowKeys] = useState(false);
  return (
    <>
      <ModalContainer show={modalState} dialogClassName="document_infoModal">
        {modalState && displayModalContent()}
      </ModalContainer>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Users</h3>
            <p className="sub-title">Create and edit customer information</p>
            <p className="sub-title mt-2">
              <span className="text-info mr-1">
                <Link to="/core/user-management">Users Management</Link>
              </span>{" "}
              / User Details
            </p>
          </div>
        </div>
        <ContentWrapper>
          <div className="row p-relative">
            <div className="button-absolute">
              <div className="spaceBetweenFlexEnd">
                {user && user.isApiUser ? (
                  <ActionButton
                    color="#2C1DFF"
                    id="generateApiKey"
                    onClick={(e) => {
                      currentContent(e);
                    }}
                  >
                    Generate API key
                  </ActionButton>
                ) : (
                  ""
                )}
                <Dropdown overlay={menu}>
                  <NewActionButton color="#2C1DFF">
                    More{" "}
                    <img
                      style={{ width: "16px", marginLeft: "10px" }}
                      src={ArrowDown}
                      alt=""
                    />
                  </NewActionButton>
                </Dropdown>
              </div>
            </div>

            {user && user.isApiUser ? (
              <>
                {" "}
                <div className="spaceBetweenFlexEnd w-100">
                  <div
                    onClick={() => setShowKeys(true)}
                    className="api-keys-btn"
                  >
                    <p>Api Keys</p>
                    <img src={ArrowRight} alt="" className="icon-img mt-1" />
                  </div>
                </div>
                {showKeys && (
                  <>
                    <div className="user-keys-content">
                      <div
                        onClick={() => setShowKeys(false)}
                        className="spaceBetweenFlexEnd pointer"
                      >
                        <img src={cancelSvg} alt="" />
                      </div>

                      {userApiKeys && userApiKeys.length
                        ? userApiKeys.map(
                            ({
                              id,
                              name,
                              created_date,
                              last_used,
                              is_active,
                            }) => {
                              return (
                                <div className="key-content" key={id}>
                                  <div className="row key-content-main">
                                    <div className="col-12">
                                      <div className="d-flex justify-content-between w-100 mt-3">
                                        <div className="text-label">Name</div>
                                        <div className="key-content-value">
                                          <p className="text-label font-bold">
                                            {" "}
                                            {name}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="d-flex justify-content-between w-100 mt-3">
                                        <div className="text-label">
                                          Created date
                                        </div>
                                        <div className="key-content-value">
                                          <p className="text-label font-bold">
                                            {formatDate(created_date)}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="d-flex justify-content-between w-100 mt-3">
                                        <div className="text-label">
                                          Last used
                                        </div>
                                        <div className="key-content-value">
                                          <p className="text-label font-bold">
                                            {last_used} days ago
                                          </p>
                                        </div>
                                      </div>
                                      <div className="d-flex justify-content-between w-100 mt-3">
                                        <div className="text-label">
                                          Enabled
                                        </div>
                                        <div className="key-content-value">
                                          <p className="text-label font-bold">
                                            {is_active ? "Active" : "InActive"}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-12">
                                      <div className="spaceBetweenFlexEnd mt-3">
                                        <div
                                          className="btn-small red mx-2"
                                          onClick={(e) => {
                                            setCurrentApiKeyId(id);
                                            currentContent(e);
                                          }}
                                          id="deleteApiKey"
                                        >
                                          Delete
                                        </div>
                                        <div
                                          className="btn-small mx-2"
                                          onClick={(e) => {
                                            setCurrentApiKeyId(id);
                                            setCurrentStatus(is_active);
                                            currentContent(e);
                                          }}
                                          id="statusApiKey"
                                        >
                                          {is_active
                                            ? "Deactivate"
                                            : "Activate"}{" "}
                                          Api
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )
                        : ""}
                    </div>
                  </>
                )}{" "}
              </>
            ) : (
              ""
            )}

            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <div style={{ width: "50%" }} className="mt-5">
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">First Name</label>
                  <Input
                    style={{ width: "60%" }}
                    size="md"
                    name="firstname"
                    value={firstname}
                    readOnly
                  />
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Last Name</label>
                  <Input
                    style={{ width: "60%" }}
                    size="md"
                    name="lastname"
                    value={lastname}
                    readOnly
                  />
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Username</label>
                  <Input
                    style={{ width: "60%" }}
                    size="md"
                    name="username"
                    value={username}
                    readOnly
                  />
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Email address</label>
                  <Input
                    style={{ width: "60%" }}
                    size="md"
                    name="email"
                    value={email}
                    readOnly
                  />
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Office</label>
                  <Input
                    style={{ width: "60%" }}
                    size="md"
                    name="officeName"
                    value={officeName}
                    readOnly
                  />
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Roles</label>
                  <div className="" style={{ width: "60%" }}>
                    {selectedRoles &&
                      selectedRoles.map((item, key) => (
                        <div key={key} className="role-perm-class mb-2">{item.name}</div>
                        // <RoleButton
                        //   color={
                        //     selectedRoles.some((val) => val.id === item.id)
                        //       ? "#ADDCFF"
                        //       : "#fff"
                        //   }
                        //   border="#01B1FC"
                        //   textColor="#002C79"
                        //   key={key}
                        //   className="form-control mb-2"
                        // >
                        //   {item.name}
                        // </RoleButton>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ContentWrapper>
      </div>
    </>
  );
};

export default UserDetails;
