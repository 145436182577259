import React from "react";
import { Input } from "@chakra-ui/react";
import { Table } from "react-bootstrap";
import DashboardLayout from "../../../../layouts/DashboardLayout/index.jsx";
import { ContentWrapper, ActionButton } from "./styles";

const BVNValidationView = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">BVN Validation</h3>
            <p className="sub-title">
              Bank Verification Number Validation to verify and authenticate
              your bank identity
            </p>
            <p className="sub-title mt-2">
              <span className="text-info mr-1">Customer </span> / BVN Validation
            </p>
          </div>
        </div>
        <ContentWrapper>
          <div className="row justify-content-start pl-5 h-100">
            <div className="col-sm-12 col-md-5 h-100">
              <div className="container pb-5" style={{ marginTop: "100px" }}>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Enter BVN</label>
                  <Input
                    style={{ width: "70%" }}
                    value="22211100334"
                    size="md"
                  />
                </div>
              </div>
              <div className="float-right">
                <ActionButton>
                  <img
                    src={
                      require("../../../../assets/images/icons/cancel.svg")
                        .default
                    }
                    alt="cancel"
                  />
                  Cancel
                </ActionButton>
                <ActionButton>
                  <img
                    src={
                      require("../../../../assets/images/icons/save.svg")
                        .default
                    }
                    alt="cancel"
                  />
                  Submit
                </ActionButton>
              </div>
              <br />
              <br />
              <div className="mt-5">
                <Table responsive striped bordered hover>
                  <thead className="border-0">
                    <tr>
                      <td className="font-weight-bold md-font-weight">
                        Status
                      </td>
                      <td>
                        <span className="text-success">Active</span>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="font-weight-bold md-font-weight">
                        First Name
                      </td>
                      <td>Emeka</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold md-font-weight">
                        Last Name
                      </td>
                      <td>Adebayo</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold md-font-weight">
                        Date of Birth
                      </td>
                      <td>1989-11-20</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold md-font-weight">
                        Mobile Number
                      </td>
                      <td>08033112234</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold md-font-weight">BVN</td>
                      <td>22211100334</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </ContentWrapper>
      </div>
    </>
  );
};

export default BVNValidationView;
