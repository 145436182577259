import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { Input, Select, FormControl, Textarea } from "@chakra-ui/react";
import CurrencyFormat from "react-currency-format";
import {
  FormClass,
  FormElements,
  ActionButton,
} from "../../CustomerInformation/styles";
import DatePicker from "react-datepicker";
import {
  getLoanDisburseDetails,
  disburseLoan,
  withdrawLoan,
} from "../../../../../actions/SavingsAndLoanActions";
import Toast from "../../../../../util/Toast";
import { formatAmount } from "../../../../../util/FormatAmount";
import { formatDate } from "../../../../../util/FormatDate.js";

const DisburseLoanModal = ({id, onHide, transId }) => {
  const dispatch = useDispatch();
  const savingsAndLoan = useSelector((state) => state.savingsAndLoan);

  let { loanAccountDisburseDetails, loanDetails } = savingsAndLoan || {};
  const { currency } = loanDetails || {};
  let { amount } = loanAccountDisburseDetails || {};

  useEffect(() => {
    dispatch(getLoanDisburseDetails(transId, "disburseToSavings"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transId]);

  //STATE HANDLER FOR DEPOSIT INFO
  const [transactionDate, setTransactionDate] = useState(new Date());

  const [enteredAmount, setAmount] = useState(amount || "");
  const [note, setNote] = useState("");
  const [modalState, setModalState] = useState(1);
  const [paymentType, setPaymentType] = useState("");

  useEffect(() => {
    if (amount) {
      setAmount(amount);
    }
  }, [amount]);

  //RESET VARIABLES WITH ID DATAS

  let formatTransactionDate = "";
  if (transactionDate !== "") {
    formatTransactionDate = formatDate(transactionDate, true);
  }
  // console.log(savingsAndLoan, "test");

  const submitDisbursementHandler = async () => {
    let disbursementDataCash = {
      actualDisbursementDate: formatTransactionDate,
      locale: "en",
      dateFormat: "dd MMM yyy",
      transactionAmount: enteredAmount,
      note,
    };
    let disbursementDataLinked = {
      actualDisbursementDate: formatTransactionDate,
      locale: "en",
      dateFormat: "dd MMM yyy",
      transactionAmount: enteredAmount,
      note,
    };

    if (!note || !formatTransactionDate || !enteredAmount || !paymentType) {
      dispatch(Toast({ text: "All fields are required", icon: "error" }));
      return;
    }

    paymentType === "linked"
      ? console.log(disbursementDataLinked)
      : console.log(disbursementDataCash);
    paymentType === "linked"
      ? dispatch(disburseLoan(transId, disbursementDataLinked))
      : dispatch(withdrawLoan(id, disbursementDataCash, "disburse"));
    onHide();
  };


  return (
    <>
      <Modal.Header>
        <Modal.Title>Loan Disbursal</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        {modalState === 1 && (
          <FormClass style={{ width: "90%" }}>
            <FormElements style={{ width: "100%" }}>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Disbursal type*</label>
                <Select
                  placeholder="Select disbursal type"
                  size="md"
                  name="paymentType"
                  value={paymentType}
                  onChange={(e) => setPaymentType(e.target.value)}
                >
                  <option value="cash" id="cash">
                    Cash disbursal
                  </option>
                  <option value="linked" id="linked">
                    To linked savings account
                  </option>
                </Select>
              </div>
              {paymentType === "linked" && (
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Linked account*</label>
                  <Input
                    disabled
                    style={{ width: "60%" }}
                    name="account"
                    value={loanDetails?.linkedAccount?.accountNo}
                    size="md"
                  />
                </div>
              )}
              {(paymentType === "linked" || paymentType === "cash") && (
                <>
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Transaction amount*</label>
                    <CurrencyFormat
                      value={enteredAmount}
                      thousandSeparator={true}
                      className="amount-input "
                      style={{
                        width: "60%",
                      }}
                      onValueChange={(values) => {
                        const { value } = values;
                        setAmount(value);
                      }}
                    />
                  </div>
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Transaction Date*</label>
                    <FormControl style={{ width: "60%" }}>
                      <div className="datepicker-100-width">
                        <DatePicker
                          id="published-date"
                          selected={transactionDate}
                          onChange={(date) => setTransactionDate(date)}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          placeholderText="dd/mmm/yyyy"
                        />
                      </div>
                    </FormControl>
                  </div>
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Narration*</label>
                    <Textarea
                      style={{ width: "60%", fontSize: "12px" }}
                      name="note"
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                      size="md"
                    />
                  </div>
                </>
              )}
            </FormElements>
          </FormClass>
        )}
        {modalState === 2 && (
          <FormClass style={{ width: "90%" }}>
            <FormElements style={{ width: "100%" }}>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Disbursal Type</label>
                <p>
                  {paymentType === "linked"
                    ? "Linked Savings Account"
                    : "Cash Disbursment"}
                </p>
              </div>
              {paymentType === "linked" && (
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Linked Savings Account</label>
                  <p>{loanDetails?.linkedAccount?.accountNo}</p>
                </div>
              )}
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Transaction Amount</label>
                <p>{currency?.code}{" "}{formatAmount(Number(enteredAmount))}</p>
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Transaction Date</label>
                <p>{formatTransactionDate}</p>
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Narration</label>
                <p>{note}</p>
              </div>
            </FormElements>
          </FormClass>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-4">
          <ActionButton
            color="#2C1DFF"
            onClick={() => {
              return modalState === 1 ? onHide() : setModalState(1);
            }}
          >
            <img
              src={
                require("../../../../../assets/images/icons/cancel.svg").default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton
            onClick={() => {
              return modalState === 1
                ? setModalState(2)
                : submitDisbursementHandler();
            }}
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
          >
            <img
              src={
                require("../../../../../assets/images/icons/save.svg").default
              }
              alt="submit icon"
            />
            {modalState === 1 ? "Next" : "Submit"}
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default DisburseLoanModal;
