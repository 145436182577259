import {
  SET_ALL_TELLER_GROUPS,
  SET_SINGLE_TELLER_GROUP_CASHIERS,
  SET_SINGLE_TELLER_GROUP_CASHIERS_RESET,
  SET_ALL_TELLERS,
  SET_ALL_OFFICE,
  SET_STAFF_IN_OFFICE,
  SET_TILLS,
  SET_ALL_CURRENCY,
  SET_TELLER_CASHIER_DETAILS,
  SET_TELLER_CASHIER_DETAILS_RESET,
  DELETE_TELLER_GROUP,
  DELETE_TELLER_GROUP_SUCCESS,
  DELETE_TELLER_GROUP_FAIL,
  CREATE_TELLER_CASHIER,
  GET_ALL_CASHIER_ACTION_REQUEST,
  RESET_ALL_CASHIER_ACTION,
  GET_CASHIER_TEMPLATE,
  APPROVE_REJECT_ACTION_TO_VAULT_CASHIER,
  APPROVE_REJECT_ACTION_TO_VAULT_CASHIER_SUCCESS,
  DELETE_TELLER_CASHIER,
  DELETE_TELLER_CASHIER_SUCCESS,
  DELETE_TELLER_CASHIER_FAIL,
  GET_CASHIER_TEMPLATE_RESET,
  CREATE_TELLER_CASHIER_FAIL,
  CREATE_TELLER_CASHIER_SUCCESS,
  UPDATE_TELLER_GROUP,
  UPDATE_TELLER_GROUP_SUCCESS,
  UPDATE_TELLER_GROUP_FAIL,
  UPDATE_TELLER_CASHIER,
  UPDATE_TELLER_CASHIER_SUCCESS,
  UPDATE_TELLER_CASHIER_FAIL,
  GET_CASHIER_TRANSACTIONS,
  DEPOSIT_WITHDRAW_CASH,
  DEPOSIT_WITHDRAW_CASH_SUCCESS,
  DEPOSIT_WITHDRAW_CASH_RESET,
  BUY_SELL_CASH,
  BUY_SELL_CASH_SUCCESS,
  GET_CASHIER_ACTION_REQUEST_RECEIVED,
  GET_CASHIER_ACTION_REQUEST_RECEIVED_SUCCESS,
  GET_CASHIER_ACTION_REQUEST_RECEIVED_FAIL,
  GET_CASHIER_ACTION_REQUEST_SENT,
  GET_CASHIER_ACTION_REQUEST_SENT_SUCCESS,
  GET_CASHIER_ACTION_REQUEST_SENT_FAIL,
  GET_TRANSACTION_SUMMARY,
  GET_TRANSACTION_SUMMARY_SUCCESS,
  GET_CLIENT_IMAGE,
  GET_CLIENT_IMAGE_SUCCESS,
  GET_CLIENT_IMAGE_RESET,
  GET_DATA_TABLES,
  GET_DATA_TABLES_SUCCESS,
  GET_DATA_TABLES_FAIL,
  GET_CASHIERDATA_REQUEST,
  GET_CASHIERDATA_SUCCESS,
  GET_CASHIERDATA_RESET,
  GET_CASHIERDATA_FAIL,
  GET_CASHIERDATATELLER_REQUEST,
  GET_CASHIERDATATELLER_SUCCESS,
  GET_CASHIERDATATELLER_FAIL,
  GET_CASHIERDATATELLER_RESET,
} from "../constants/tellerConstants";

const initialState = {
  allTellerGroups: [],
  singleTellerGroupCashiers: [],
  tellerCashierDetails: [],
  allTellers: [],
  allOffice: [],
  staffInOffice: [],
  setTills: [],
  setCurrencies: [],
  singleCashierTransactions: [],
  newTellerGroup: {},
  allCashierActionRequest: [],
  cashierTransactions: [],
  cashierTemplate: [],
  actionRequestReceived: [],
  tellerActionRequests: [],
  summary: {},
  depositWithdrawResponseData: {},
  clientImageData: "",
  dataTables: {},
  dataTablesError: {},
};

function allTellerReducers(state = initialState, action) {
  switch (action.type) {
    case GET_TRANSACTION_SUMMARY: {
      return {
        loading: true,
        ...state,
      };
    }
    case GET_TRANSACTION_SUMMARY_SUCCESS: {
      return {
        loading: false,
        ...state,
        summary: action?.payload,
      };
    }
    case GET_CASHIER_ACTION_REQUEST_SENT: {
      return {
        loading: true,
        ...state,
      };
    }
    case GET_CASHIER_ACTION_REQUEST_SENT_SUCCESS: {
      return {
        loading: false,
        ...state,
        tellerActionRequests: action?.payload,
      };
    }
    case GET_CASHIER_ACTION_REQUEST_SENT_FAIL: {
      return {
        loading: false,
        ...state,
        error: action?.payload,
      };
    }
    case GET_CASHIER_ACTION_REQUEST_RECEIVED: {
      return {
        loading: true,
        ...state,
      };
    }
    case GET_CASHIER_ACTION_REQUEST_RECEIVED_SUCCESS: {
      return {
        loading: false,
        ...state,
        actionRequestReceived: action.payload,
      };
    }
    case GET_CASHIER_ACTION_REQUEST_RECEIVED_FAIL: {
      return {
        loading: false,
        ...state,
        error: action.payload,
      };
    }
    case APPROVE_REJECT_ACTION_TO_VAULT_CASHIER: {
      return {
        loading: true,
        ...state,
      };
    }
    case APPROVE_REJECT_ACTION_TO_VAULT_CASHIER_SUCCESS: {
      return {
        loading: false,
        ...state,
      };
    }
    case DEPOSIT_WITHDRAW_CASH: {
      return {
        loading: true,
        ...state,
      };
    }
    case DEPOSIT_WITHDRAW_CASH_SUCCESS: {
      return {
        loading: false,
        ...state,
        depositWithdrawResponseData: action?.payload,
      };
    }
    case DEPOSIT_WITHDRAW_CASH_RESET: {
      return {
        loading: false,
        ...state,
        depositWithdrawResponseData: {},
      };
    }
    case BUY_SELL_CASH: {
      return {
        loading: true,
        ...state,
      };
    }
    case BUY_SELL_CASH_SUCCESS: {
      return {
        loading: false,
        ...state,
      };
    }
    case CREATE_TELLER_CASHIER: {
      return {
        loading: true,
        ...state,
      };
    }
    case CREATE_TELLER_CASHIER_SUCCESS: {
      return {
        loading: false,
        ...state,
        // newTellerGroup: action.payload,
      };
    }
    case CREATE_TELLER_CASHIER_FAIL: {
      return {
        loading: false,
        ...state,
        error: action.payload,
      };
    }
    case UPDATE_TELLER_GROUP: {
      return {
        loading: true,
        ...state,
      };
    }
    case UPDATE_TELLER_GROUP_SUCCESS: {
      return {
        loading: false,
        ...state,
        // allTellerGroups: action.payload,
      };
    }
    case UPDATE_TELLER_GROUP_FAIL: {
      return {
        loading: false,
        ...state,
        error: action.payload,
      };
    }
    case UPDATE_TELLER_CASHIER: {
      return {
        loading: true,
        ...state,
      };
    }
    case UPDATE_TELLER_CASHIER_SUCCESS: {
      return {
        loading: false,
        ...state,
        // allTellerGroups: action.payload,
      };
    }
    case UPDATE_TELLER_CASHIER_FAIL: {
      return {
        loading: false,
        ...state,
        error: action.payload,
      };
    }
    case SET_ALL_TELLER_GROUPS: {
      return {
        ...state,
        allTellerGroups: action.payload,
      };
    }
    case GET_CASHIER_TRANSACTIONS: {
      return {
        ...state,
        cashierTransactions: action.payload,
      };
    }
    case GET_ALL_CASHIER_ACTION_REQUEST: {
      return {
        ...state,
        allCashierActionRequest: action.payload,
      };
    }
    case RESET_ALL_CASHIER_ACTION: {
      return {
        ...state,
        allCashierActionRequest: [],
        loading: false,
        error: null,
      };
    }
    case GET_CASHIER_TEMPLATE: {
      return {
        ...state,
        cashierTemplate: action.payload,
      };
    }
    case GET_CASHIER_TEMPLATE_RESET: {
      return {
        ...state,
        cashierTemplate: [],
        loading: false,
        error: null,
      };
    }
    case SET_SINGLE_TELLER_GROUP_CASHIERS: {
      return {
        ...state,
        singleTellerGroupCashiers: action.payload,
      };
    }
    case SET_SINGLE_TELLER_GROUP_CASHIERS_RESET: {
      return {
        ...state,
        singleTellerGroupCashiers: [],
        loading: false,
        error: null,
      };
    }
    case DELETE_TELLER_GROUP: {
      return { loading: true, ...state };
    }
    case DELETE_TELLER_GROUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        allTellerGroups: state?.allTellerGroups?.filter(
          (item) => item.id !== action.payload.resourceId
        ),
      };
    }
    case DELETE_TELLER_GROUP_FAIL: {
      return { loading: false, error: action.payload };
    }
    case DELETE_TELLER_CASHIER: {
      return { loading: true, ...state };
    }
    case DELETE_TELLER_CASHIER_SUCCESS: {
      return {
        ...state,
        loading: false,
        singleTellerGroupCashiers:
          state?.singleTellerGroupCashiers?.cashiers?.filter(
            (item) => item.id !== action.payload.resourceId
          ),
      };
    }
    case DELETE_TELLER_CASHIER_FAIL: {
      return { loading: false, error: action.payload };
    }
    case SET_TELLER_CASHIER_DETAILS: {
      return {
        ...state,
        tellerCashierDetails: action.payload,
      };
    }
    case SET_TELLER_CASHIER_DETAILS_RESET: {
      return {
        ...state,
        tellerCashierDetails: null,
        loading: false,
        error: null,
      };
    }
    case SET_ALL_TELLERS: {
      return {
        ...state,
        allTellers: action.payload,
      };
    }
    case SET_ALL_OFFICE: {
      return {
        ...state,
        allOffice: action.payload,
      };
    }
    case SET_STAFF_IN_OFFICE: {
      return {
        ...state,
        staffInOffice: action.payload,
      };
    }
    case SET_TILLS: {
      return {
        ...state,
        setTills: action.payload,
      };
    }
    case SET_ALL_CURRENCY: {
      return {
        ...state,
        setCurrencies: action.payload,
      };
    }
    case GET_CLIENT_IMAGE: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_CLIENT_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        clientImageData: action.payload,
      };
    }
    case GET_CLIENT_IMAGE_RESET: {
      return {
        ...state,
        loading: false,
        clientImageData: "",
      };
    }
    case GET_DATA_TABLES: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_DATA_TABLES_SUCCESS: {
      return {
        ...state,
        loading: false,
        dataTables: action.payload,
      };
    }
    case GET_DATA_TABLES_FAIL: {
      return {
        ...state,
        loading: false,
        dataTables: {},
        dataTablesError: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

export const cashierDataReducer = (
  state = { cashierDataDetails: {} },
  action
) => {
  switch (action.type) {
    case GET_CASHIERDATA_REQUEST:
      return { ...state, loading: true };
    case GET_CASHIERDATA_SUCCESS:
      return { ...state, loading: false, cashierDataDetails: action.payload };
    case GET_CASHIERDATA_FAIL:
      return { ...state, loading: false, error: action.payload };
    case GET_CASHIERDATA_RESET:
      return { ...state, cashierDataDetails: {}, loading: false, error: null };
    default:
      return state;
  }
};
export const cashierDataTellerReducer = (
  state = { cashierDataDetailsTeller: {} },
  action
) => {
  switch (action.type) {
    case GET_CASHIERDATATELLER_REQUEST:
      return { ...state, loading: true };
    case GET_CASHIERDATATELLER_SUCCESS:
      return {
        ...state,
        loading: false,
        cashierDataDetailsTeller: action.payload,
      };
    case GET_CASHIERDATATELLER_FAIL:
      return { ...state, loading: false, error: action.payload };
    case GET_CASHIERDATATELLER_RESET:
      return {
        ...state,
        cashierDataDetailsTeller: {},
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

export default allTellerReducers;
