import config from "../../config";
import axiosData from "../axiosData";

export const getAllSavingAccounts = (
  params,
  savingProduct,
  isActive,
  isInactive,
  startDate,
  endDate,
  accountOnPnd
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${
          config.apiGateway.TRANSACTION_API_URL
        }/savings-accounts?${params}${
          !!savingProduct
            ? `&productType=${!!savingProduct ? savingProduct : ""}`
            : ""
        }${
          !!startDate && !!endDate
            ? `&dateRange=${!!startDate ? `${startDate}-${endDate}` : ""}`
            : ""
        }${
          !!isInactive
            ? `&inactiveAccounts=${!!isInactive ? isInactive : ""}`
            : ""
        }${
          !!accountOnPnd
            ? `&zeroAccounts=${!!accountOnPnd ? accountOnPnd : ""}`
            : ""
        }${!!isActive ? `&activeAccounts=${!!isActive ? isActive : ""}` : ""}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getAllSavingAccountsTemplate = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: jourEntry,
        url: `${config.apiGateway.TRANSACTION_API_URL}/savings-accounts/products`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const searchSavingsAcc = (query) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.TRANSACTION_API_URL}/savings-accounts/search?q=${query}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const searchLoanAcc = (query) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.TRANSACTION_API_URL}/loan-accounts/search?q=${query}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getLoanAccounts = (
  params,
  loanProducts,
  isActive,
  loansUnpaid,
  loansRepaid,
  startDate,
  endDate
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",

        url: `${config.apiGateway.TRANSACTION_API_URL}/loan-accounts?${params}${
          !!loanProducts
            ? `&productType=${!!loanProducts ? loanProducts : ""}`
            : ""
        }${
          !!startDate && !!endDate
            ? `&dateRange=${!!startDate ? `${startDate}-${endDate}` : ""}`
            : ""
        }${
          !!loansUnpaid
            ? `&loansUnpaid=${!!loansUnpaid ? loansUnpaid : ""}`
            : ""
        }${
          !!loansRepaid
            ? `&loansRepaid=${!!loansRepaid ? loansRepaid : ""}`
            : ""
        }${!!isActive ? `&activeLoans=${!!isActive ? isActive : ""}` : ""}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getLoanAccountsTemplate = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: jourEntry,
        url: `${config.apiGateway.TRANSACTION_API_URL}/loan-accounts/products`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
