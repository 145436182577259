import axiosData from "../axiosData";
import config from "../../config";
/*
 * Get statistics
 */
const getStatistics = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.DASH_API_URL}`,
        params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { getStatistics };
