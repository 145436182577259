import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { Avatar } from "@chakra-ui/react";
import {
  getAllCashierActionRequests,
  approveRejectRequestMgr,
} from "../../../../actions/tellerActions";
import CustomItemLoader from "../../../../components/Loader/CustomItemLoader";
import { formatCurrency } from "../../../../util/FormatAmount";
import { truncateString } from "../../../../util/FormatText";
import { useDispatch, useSelector } from "react-redux";
import { Menu, Dropdown, Button } from "antd";
import {
  Rejected,
  Approved,
  Heading,
  H3,
  Grid,
  ActionButton,
  LinkColor,
} from "./styled";
import { Input, Textarea } from "@chakra-ui/react";
import CheckPermission from "../../../../util/CheckPermission";
import ModalContainer from "../../../../components/ModalContainer/index";
import { Modal } from "react-bootstrap";

const TellerRequest = ({ history }) => {
  const [currentTableActive, setCurrentTableActive] = useState({});
  const [actionValue, setActionValue] = useState("");
  const [amount, setAmount] = useState("");
  const [modalState, toggleModal] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [activePagination, setActivePagination] = useState();
  const [myData, setMyData] = useState([]);
  const [rowDetailModal, toggleDetailsModal] = useState(false);
  const dispatch = useDispatch();
  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  const handleCloseModal = () => {
    toggleModal(false);
    return setActionValue("");
  };

  const handleClick = (value) => {
    setActionValue(value);
    return toggleModal(true);
  };

  const displayModalContent = (currentTableActive) => {
    switch (actionValue) {
      case "approve":
        return (
          <ModalReq
            data={currentTableActive}
            status="approve"
            handleCloseModal={handleCloseModal}
          />
        );
      case "reject":
        return (
          <ModalReq
            data={currentTableActive}
            status="reject"
            handleCloseModal={handleCloseModal}
          />
        );
      default:
        break;
    }
  };

  useEffect(() => {
    if (modalState === true) {
      return toggleDetailsModal(false);
    }
    if (rowDetailModal === true) {
      return toggleModal(false);
    }
  }, [modalState, rowDetailModal]);

  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          toggleDetailsModal(false);
          return handleClick("approve");
        }}
      >
        <p>Approve</p>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          toggleDetailsModal(false);
          return handleClick("reject");
        }}
      >
        <p>Reject</p>
      </Menu.Item>
    </Menu>
  );
  const isLoading = useSelector((state) => state.isLoading.isLoading);

  const tellerGroupReducer = useSelector((state) => state.tellerReducer);

  const { allCashierActionRequest } = tellerGroupReducer;

  const {
    totalFilteredRecords,
    totalItems,
    numberOfPages,
    pageItems = [],
  } = allCashierActionRequest || {};

  const closeDetailsModal = () => {
    toggleDetailsModal(false);
  };

  useEffect(() => {
    if (CheckPermission("READ_CASHIERREQUEST", permissions)) {
      dispatch(getAllCashierActionRequests("type=vault&status=100&offset=0&limit=10&sortOrder=DESC"));
    }
  }, [dispatch]);

  useEffect(() => {
    if (!!allCashierActionRequest && !!allCashierActionRequest.length) {
      setMyData(allCashierActionRequest);
    } else if (!!pageItems.length) {
      setMyData(pageItems);
    }
  }, [pageItems, allCashierActionRequest]);

  useEffect(() => {
    if (totalFilteredRecords !== 0 && totalFilteredRecords !== 1) {
      setPagination({
        current: activePagination,
        pageSize: 10,
        total: totalFilteredRecords,
      });
    } else if (totalItems === 0 && numberOfPages === 0) {
      setPagination(false);
    } else if (!!allCashierActionRequest && !!allCashierActionRequest.length) {
      setPagination({ defaultPageSize: 10 });
    }
  }, [
    totalItems,
    numberOfPages,
    totalFilteredRecords,
    activePagination,
    allCashierActionRequest,
  ]);

  const handleTableChange = (pagination, filters, sorter) => {
    setActivePagination(pagination.current);
    if (!!allCashierActionRequest && !!allCashierActionRequest.length) {
      setPagination({
        current: activePagination,
        pageSize: 10,
        total: allCashierActionRequest.length,
      });
    } else {
      let myOffsect = pagination.current * 10;
      let dataToSend2 = `type=vault&status=100&offset=${myOffsect - 10}&limit=10&sortOrder=DESC`;
      dispatch(getAllCashierActionRequests(dataToSend2));
    }
  };

  const dataSource = [];

  !!myData &&
    myData?.forEach((eachData) => {
      const {
        id,
        amount,
        savingsAccountNumber,
        savingsAccountId,
        clientDisplayName,
        clientId,
        actionType,
        actionStatus: { value: currentStatus },
        requestNote: description,
        currencyData: { code },
        actionRequestTimelineData,
        actionType: { value },
      } = eachData;
      dataSource.push({
        key: id,
        id: id,
        status: currentStatus,
        savingsAccountNumber,
        savingsAccountId,
        clientId,
        clientDisplayName: clientDisplayName,
        actionRequestTimelineData,
        actionType,
        tellerName: truncateString(
          `${actionRequestTimelineData?.submittedByFirstname} ${actionRequestTimelineData?.submittedByLastname}`,
          18
        ),
        type: `${value} CASH`,
        amount: `${code} ${formatCurrency(amount)}`,
        description: description,
        avatar: (
          <Avatar
            name={
              actionRequestTimelineData?.submittedByFirstname +
              " " +
              actionRequestTimelineData?.submittedByLastname
            }
          />
        ),
        action: CheckPermission("APPROVE_CASHIERREQUEST", permissions) ? (
          <div>
            {currentStatus === "PENDING" ? (
              <Dropdown
                onClick={(e) => toggleDetailsModal(false)}
                overlay={menu}
              >
                <Button
                  onClick={(e) => e.stopPropagation()}
                  className="action_button"
                >
                  Action
                </Button>
              </Dropdown>
            ) : currentStatus === "APPROVED" ? (
              <Approved>{currentStatus}</Approved>
            ) : (
              <Rejected>{currentStatus}</Rejected>
            )}
          </div>
        ) : (
          ""
        ),
      });
    });

  const columns = [
    { title: "", dataIndex: "avatar", key: "avatar" },
    { title: "Teller Name", dataIndex: "tellerName", key: "tellerName" },
    { title: " Type", dataIndex: "type", key: "type" },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    { title: "Action", dataIndex: "action", key: "action" },
  ];

  return (
    <div className="container-fluid">
      <div className="row justify-content-between">
        <div className="col-sm-12 col-md-12 col-lg-6 pb-5">
          <h3 className="page-title">Teller requests</h3>
          <p className="text-muted sub-title mb-2">
            A complete overview of your business with analysis and data metrics
          </p>
          <div className="font-weight sub-title">
            <LinkColor to="/core/teller-group" color>
              Teller Management /{" "}
            </LinkColor>
            <span>Teller requests</span>
          </div>
        </div>
        <div>
          <ActionButton onClick={() => history.push("/core/teller-history")}>
            Requests History
          </ActionButton>
        </div>
      </div>
      <div>
        <ModalContainer
          size="md"
          show={modalState}
          dialogClassName="document_infoModal"
        >
          {modalState && displayModalContent(currentTableActive)}
        </ModalContainer>
        {!!modalState ? (
          " "
        ) : (
          <ModalContainer size="md" show={rowDetailModal}>
            {rowDetailModal && (
              <DetailsModal
                closeDetailsModal={closeDetailsModal}
                data={currentTableActive}
              />
            )}
          </ModalContainer>
        )}

        <div>
          {isLoading ? (
            <CustomItemLoader />
          ) : (
            <>
              {CheckPermission("READ_CASHIERREQUEST", permissions) && (
                <Table
                  onRow={(r) => ({
                    onClick: () => {
                      setAmount(r?.amount);
                      toggleDetailsModal(true);
                      return setCurrentTableActive(r);
                    },
                  })}
                  onChange={handleTableChange}
                  columns={columns}
                  dataSource={dataSource}
                  pagination={pagination}
                  defaultPageSize={'10'}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TellerRequest;

const ModalReq = ({ data, handleCloseModal, status }) => {
  const [note, setNote] = useState("");
  const dispatch = useDispatch();

  const func = () => {
    dispatch(getAllCashierActionRequests("type=vault&status=100&offset=0&limit=10&sortOrder=DESC"));
  };

  const handleSubmit = () => {
    if (!note) {
    } else {
      dispatch(approveRejectRequestMgr(data?.id, status, note, func));
      return handleCloseModal();
    }
  };
  const { amount } = data;
  return (
    <>
      <Modal.Header>
        <Modal.Title className="text-capitalize">{status}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="position-relative">
          <div>
            <div>
              <div className="d-flex my-3 justify-content-between">
                <label className="text-label" htmlFor="Amount">
                  Amount
                </label>
                <Input
                  style={{ width: "60%" }}
                  readOnly
                  value={amount}
                  type="text"
                />
              </div>
              <div className="d-flex justify-content-between">
                <label
                  className="text-label"
                  style={{ fontSize: "12px" }}
                  htmlFor="Description"
                >
                  Description<span className="required-color">*</span>
                </label>
                <Textarea
                  onChange={(e) => setNote(e.target.value)}
                  placeholder="Approval note"
                  style={{ fontSize: "12px" }}
                  name="note"
                  required
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-1">
          <ActionButton color="#2C1DFF" onClick={handleCloseModal}>
            <img
              src={
                require("../../../../assets/images/icons/cancel.svg").default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={handleSubmit}
          >
            <img
              src={require("../../../../assets/images/icons/save.svg").default}
              alt="submit icon"
            />
            Submit
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

const DetailsModal = ({ data, closeDetailsModal }) => {
  return (
    <>
      <Modal.Header>
        <Modal.Title>
          {data?.avatar} <span className="ml-3">{data?.tellerName}</span>{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="position-relative">
          <div className="mt-2">
            <div className="d-flex my-3 justify-content-between">
              <label className="text-label">Type</label>
              <Input
                style={{ width: "60%" }}
                readOnly
                value={data?.type}
                type="text"
              />
            </div>
            <div className="d-flex my-3 justify-content-between">
              <label className="text-label">Amount</label>
              <Input
                style={{ width: "60%" }}
                readOnly
                value={data?.amount}
                type="text"
              />
            </div>

            {data?.savingsAccountNumber ? (
              <div className="d-flex my-3 justify-content-between">
                <p className="text-label">Account number</p>
                <Input
                  readOnly
                  type="text"
                  style={{ width: "60%" }}
                  value={data?.savingsAccountNumber}
                />
              </div>
            ) : (
              ""
            )}
            {data?.clientDisplayName ? (
              <div className="d-flex my-3 justify-content-between">
                <p className="text-label">Client name</p>
                <Input
                  readOnly
                  type="text"
                  style={{ width: "60%" }}
                  value={data?.clientDisplayName}
                />
              </div>
            ) : (
              ""
            )}
            <div className="d-flex my-3 justify-content-between">
              <p className="text-label">Date</p>
              <Input
                readOnly
                type="text"
                style={{ width: "60%" }}
                value={
                  data?.actionRequestTimelineData?.submittedOnDate[2] +
                  "/" +
                  data?.actionRequestTimelineData?.submittedOnDate[1] +
                  "/" +
                  data?.actionRequestTimelineData?.submittedOnDate[0]
                }
              />
            </div>
            <div className="d-flex my-3 justify-content-between">
              <p className="text-label">Submitted on</p>
              <Input
                readOnly
                type="text"
                style={{ width: "60%" }}
                value={data?.actionRequestTimelineData?.submittedOnTime}
              />
            </div>
            <div className="d-flex my-3 justify-content-between">
              <p className="text-label">Status</p>
              <Input
                readOnly
                type="text"
                style={{ width: "60%" }}
                value={data?.status}
              />
            </div>
            <div className="d-flex my-3 justify-content-between">
              <p className="text-label">Description</p>
              <Textarea
                readOnly
                value={data?.description}
                style={{ fontSize: "12px" }}
              ></Textarea>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-1">
          <ActionButton color="#2C1DFF" onClick={closeDetailsModal}>
            <img
              src={
                require("../../../../assets/images/icons/cancel.svg").default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};
