import axiosData from "../axiosData";
import config from "../../config";

/*
 * Get all Customers
 */
const getAllCustomers = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/clients`,
        params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
const getAllCustomersPagination = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/${params}`,
        // params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Search All Customer Database (from dashboard header)
 */
const searchCustomer = (searchQuery) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        data: searchQuery,
        url: `${config.apiGateway.BASE_API_URL}/search?exactMatch=false&query=${searchQuery}&resource=clients,clientIdentifiers,groups,savings,shares,loans`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
/*
 * Get Single Customer
 */
const getSingleCustomer = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        data: id,
        url: `${config.apiGateway.BASE_API_URL}/clients/${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
const getLoanLetters = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.REPORT_API_URL}/template/all?templateType=loans`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

const getLoanOfferLetters = (loanId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.DYNAMIC_REPORT_API_URL}/reports/loan_offer_letter?loanId=${loanId}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

const getLoanAccountStatement = (loanId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.DYNAMIC_REPORT_API_URL}/reports/loan_statement?loanId=${loanId}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Get Single Customer Additional info
 */
const getSingleCustomerAdditionalInfo = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/datatables/Additional%20Information/${id}?genericResultSet=true`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
/*
 * Create New Customer
 */
const createNewCustomer = (userData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        data: userData,
        url: `${config.apiGateway.BASE_API_URL}/clients`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Create New Customer
 */
const createNewCustomerAdditionalInfo = (userData, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        data: userData,
        url: `${config.apiGateway.BASE_API_URL}/datatables/Additional%20Information/${id}?genericResultSet=true`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Create Customer Image
 */
const uploadCustomerImage = (clientId, imgPayload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        data: imgPayload,
        url: `${config.apiGateway.BASE_API_URL}/clients/${clientId}/images`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
/*
 * UPDATE Customer Image
 */
const updateCustomerImage = (clientId, imgPayload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "PUT",
        data: imgPayload,
        url: `${config.apiGateway.BASE_API_URL}/clients/${clientId}/images`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
/*
 * Fetch Customer Image
 */
const getCustomerImage = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        data: id,
        url: `${config.apiGateway.BASE_API_URL}/clients/${id}/images`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/**
 * Update Customer information
 */
const editCustomerInfo = (userData, clientId, cancelToken) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "PUT",
        data: userData,
        url: `${config.apiGateway.BASE_API_URL}/clients/${clientId}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/**
 * Update Customer additional information
 */
const editCustomerAdditionalInfo = (userData, id, datableAction) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        // method: datableAction,
        method: "PUT",
        data: userData,
        url: `${config.apiGateway.BASE_API_URL}/datatables/Additional%20Information/${id}?genericResultSet=true`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Get Customer Address
 */
const getCustomerAddress = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        data: id,
        url: `${config.apiGateway.BASE_API_URL}/client/${id}/addresses`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/**
 * Update Customer information
 */
const editCustomerAddress = (userData, clientId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "PUT",
        data: userData,
        url: `${config.apiGateway.BASE_API_URL}/client/${clientId}/addresses`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Upload Customer Document
 */
const uploadCustomerDocument = (clientId, docPayload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        data: docPayload,
        url: `${config.apiGateway.BASE_API_URL}/clients/${clientId}/documents`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Get customer Documents
 */
const getCustomerDocuments = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        data: id,
        url: `${config.apiGateway.BASE_API_URL}/clients/${id}/documents`,
        isBearerToken: true,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Get customer Documents
 */
const deleteCustomerDocument = (clientId, docId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "DELETE",
        data: docId,
        url: `${config.apiGateway.BASE_API_URL}/clients/${clientId}/documents/${docId}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Get customer Charges
 */
const getCustomerCharges = (clientId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/clients/${clientId}/charges`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Get customer Charges
 */
const getCustomerAccounts = (clientId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/clients/${clientId}/accounts`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Make Deposit on customer savings account
 */
const makeSavingsDeposit = (transId, transData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        data: transData,
        url: `${config.apiGateway.BASE_API_URL}/savingsaccounts/${transId}/transactions?command=deposit`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Make Withdrawal on customer savings account
 */
const makeSavingsWithdrawal = (transId, transData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        data: transData,
        url: `${config.apiGateway.BASE_API_URL}/savingsaccounts/${transId}/transactions?command=withdrawal`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Get details of a single loan
 */
const getLoanDetails = (loanId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/loans/${loanId}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Get account statement of a savings account
 */
const downloadAccountStatement = ({
  startDate,
  endDate,
  accountNumber,
  dateFormat,
  locale,
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.DYNAMIC_REPORT_API_URL}/reports/account_statement?accountNo=${accountNumber}&startDate=${startDate}&endDate=${endDate}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Transaction Receipt
const downloadTransactionReceipt = ({ transactionId }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.PRE_AUTH_API_URL}/transaction/reciept/${transactionId}`,
        headers: {
          "Content-Type": "woodcoretenant/default",
        },
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
/*
 * Get repayment schedule of a loan account
 */
const downloadRepaymentSchedule = (loanId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.DYNAMIC_REPORT_API_URL}/reports//loan_repayment_schedule?loanId=${loanId}`,
        // url: `${config.apiGateway.REPORT_API_URL}/reports/loan-repayment?accountNo=${accountNumber}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Approve a loan
 */
const approveLoan = (transId, transData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        data: transData,
        url: `${config.apiGateway.BASE_API_URL}/loans/${transId}?command=approve`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Disburse a loan
 */
const disburseLoan = (transId, transData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        data: transData,
        url: `${config.apiGateway.BASE_API_URL}/loans/${transId}?command=disburse`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Create New Savings Account
 */
const createSavingsAccount = (dataPayload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        data: dataPayload,
        url: `${config.apiGateway.BASE_API_URL}/savingsaccounts`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/**
 * Update Savings Account information
 */
const editSavingsAccountInfo = (userData, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "PUT",
        data: userData,
        url: `${config.apiGateway.BASE_API_URL}/savingsaccounts/${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Get all a savings product
const getSavingsAccount = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/savingsaccounts/${id}?associations=charges&staffInSelectedOfficeOnly=true&template=true`,
        // savingsaccounts/465?associations=charges&staffInSelectedOfficeOnly=true&template=true
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Create New Loan Account
 */
const createLoanAccount = (dataPayload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        data: dataPayload,
        url: `${config.apiGateway.BASE_API_URL}/loans`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
const updateClientTier = (id, rank) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        data: { tierRank: rank },
        url: `${config.apiGateway.BASE_API_URL}/clients/${id}?command=updateTier`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Activate a customer
 */
const activateCustomer = (id, dataPayload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        data: dataPayload,
        url: `${config.apiGateway.BASE_API_URL}/clients/${id}?command=activate`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

const getAllCountries = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/codes/name?code=country`,
        // params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

const getCountryStates = (code) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/codes/name?code=${code}`,
        // params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

const fetchCustomerNotes = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/clients/${id}/notes`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

const createCustomerNote = (id, dataPayload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        data: dataPayload,
        url: `${config.apiGateway.BASE_API_URL}/clients/${id}/notes`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

const updateCustomerNote = (clientId, noteId, payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "PUT",
        url: `${config.apiGateway.BASE_API_URL}/clients/${clientId}/notes/${noteId}`,
        data: payload,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

const deleteCustomerNote = (clientId, noteId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "DELETE",
        url: `${config.apiGateway.BASE_API_URL}/clients/${clientId}/notes/${noteId}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAllCustomers,
  getAllCustomersPagination,
  getSingleCustomer,
  getSingleCustomerAdditionalInfo,
  editCustomerInfo,
  editCustomerAdditionalInfo,
  getCustomerAddress,
  createNewCustomer,
  createNewCustomerAdditionalInfo,
  uploadCustomerImage,
  updateCustomerImage,
  updateClientTier,
  getCustomerImage,
  uploadCustomerDocument,
  getCustomerDocuments,
  deleteCustomerDocument,
  getCustomerCharges,
  getCustomerAccounts,
  searchCustomer,
  activateCustomer,
  createSavingsAccount,
  getSavingsAccount,
  editSavingsAccountInfo,
  editCustomerAddress,
  createLoanAccount,
  makeSavingsDeposit,
  makeSavingsWithdrawal,
  getLoanDetails,
  approveLoan,
  disburseLoan,
  downloadAccountStatement,
  downloadRepaymentSchedule,
  getLoanLetters,
  getLoanOfferLetters,
  getLoanAccountStatement,
  downloadTransactionReceipt,
  getAllCountries,
  getCountryStates,
  fetchCustomerNotes,
  createCustomerNote,
  updateCustomerNote,
  deleteCustomerNote
};
