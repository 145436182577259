import React from "react";
import { Table } from "react-bootstrap";
import { formatCurrency } from "../../../../../util/FormatAmount";
import { formatDate } from "../../../../../util/FormatDate";

const FixedDetails = ({ fixedAccountDetails }) => {
  let {
    clientId,
    clientName = "",
    depositProductName,
    depositPeriod,
    accountNo,
    fieldOfficerName,
    id,
    maturityAmount,
    depositAmount,
    linkedAccount,
    lockinPeriodFrequency,
    lockinPeriodFrequencyType,
    depositPeriodFrequency,
    preClosurePenalInterest,
    preClosurePenalInterestOnType,
    currency = {},
    status = {},
    interestCalculationDaysInYearType = {},
    interestCalculationType = {},
    interestCompoundingPeriodType = {},
    interestPostingPeriodType = {},
    summary = {},
    timeline = {},
    taxGroup = {},
    maturityDate = [],
    nominalAnnualInterestRate = "",

    subStatus = {},
  } = fixedAccountDetails || {};

  let { activatedOnDate = [], submittedOnDate = [] , approvedOnDate = [] , rejectedOnDate = [] , closedOnDate = []} = timeline || {};

  let { accountBalance, availableBalance, totalDeposits, totalInterestEarned } =
    summary || {};

  return (
    <>
      <div className="row">
        <div className="col-xl-6 col-md-12">
          {/* Savings Details */}
          <div className="m-2">
            <Table
              responsive
              striped 
              bordered
              hover
              className="table-form-style"
            >
              <thead className="border-0">
                <tr>
                  <td
                    className="font-weight-bold md-font-weight text-center"
                    colSpan="2"
                  >
                    Fixed Deposit Details
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="font-weight-bold md-font-weight">Status</td>
                  <td>
                    <>
                      <div>
                        {status.active ? (
                          <span className="new-text-success-table">
                            {" "}
                            {(status && status.value) || ""}
                            <span
                              style={{ fontSize: "12px" }}
                              className="text-danger"
                            >
                              {subStatus && subStatus.block && " (Blocked)"}
                              {subStatus && subStatus.blockDebit && " (On PND)"}
                            </span>
                          </span>
                        ) : (
                          <span className="text-danger">
                            {/* {(status && status.value) || ""} */}
                          </span>
                        )}
                        {status.id === 100 ? (
                          <>
                            {" "}
                            <span className="new-text-pending-table">
                              {" "}
                              {status &&
                              status.value.toLowerCase() ===
                                "submitted and pending approval"
                                ? "Pending"
                                : (status && status.value) || ""}
                            </span>{" "}
                          </>
                        ) : (
                          ""
                        )}
                        {status.id === 200 ? (
                          <>
                            {" "}
                            <span className="new-text-info-table">
                              {" "}
                              {(status && status.value) || ""}
                            </span>{" "}
                          </>
                        ) : (
                          ""
                        )}
                        {status.id === 600 ||
                        status.id === 500 ||
                        status.id === 400 ||
                        status.id === 600 ||
                        status.id === 700 ||
                        status.id === 800 ? (
                          <>
                            <span className="new-text-danger-table">
                              {" "}
                              {(status && status.value) || ""}
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  </td>
                </tr>
                <tr>
                  <td className="font-weight-bold md-font-weight">
                    Field Officer
                  </td>
                  <td>
                    {!!fieldOfficerName ? fieldOfficerName : "Unassigned"}
                  </td>
                </tr>
                {status?.id === 300 && <tr>
                  <td className="font-weight-bold md-font-weight">
                    Activation Date
                  </td>
                  <td>
                    {!!activatedOnDate.length
                      ? formatDate(activatedOnDate)
                      : ""}
                  </td>
                </tr>}
                {status?.id === 100 && (
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Submitted Date
                    </td>
                    <td>
                      {!!submittedOnDate.length
                        ? formatDate(submittedOnDate)
                        : ""}
                    </td>
                  </tr>
                )}
                {status.id === 200 && (
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Approved Date
                    </td>
                    <td>
                      {!!approvedOnDate.length
                        ? formatDate(approvedOnDate)
                        : ""}
                    </td>
                  </tr>
                )}
                {status.id === 500 && (
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Rejected Date
                    </td>
                    <td>
                      {!!rejectedOnDate.length
                        ? formatDate(rejectedOnDate)
                        : ""}
                    </td>
                  </tr>
                )}
                {status.id === 700 && (
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                       Closed Date
                    </td>
                    <td>
                      {!!closedOnDate.length
                        ? formatDate(closedOnDate)
                        : ""}
                    </td>
                  </tr>
                )}
                <tr>
                  <td className="font-weight-bold md-font-weight">
                    Maturity Date
                  </td>
                  <td>
                    {" "}
                    {!!maturityDate.length ? formatDate(maturityDate) : ""}
                  </td>
                </tr>
                <tr>
                  <td className="font-weight-bold md-font-weight">Period</td>
                  <td>
                    {!!depositPeriod ? depositPeriod : ""}{" "}
                    {!!depositPeriodFrequency && !!depositPeriodFrequency.value
                      ? depositPeriodFrequency.value
                      : ""}
                  </td>
                </tr>
                {!!taxGroup && !!taxGroup.name ? (
                  <>
                    <tr>
                      <td className="font-weight-bold md-font-weight">
                        Witholding Tax Group
                      </td>
                      <td>
                        {!!taxGroup && !!taxGroup.name ? taxGroup.name : ""}
                      </td>
                    </tr>{" "}
                  </>
                ) : (
                  ""
                )}
              </tbody>
            </Table>
          </div>
          {/* Interest Details */}
          <div className="m-2">
            <Table
              responsive
              striped
              bordered
              hover
              className="table-form-style"
            >
              <thead className="border-0">
                <tr>
                  <td
                    className="font-weight-bold md-font-weight text-center"
                    colSpan="2"
                  >
                    Interest Details
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="font-weight-bold md-font-weight">
                    Norminal Interest Rate
                  </td>
                  <td>
                    {!!nominalAnnualInterestRate
                      ? nominalAnnualInterestRate
                      : "0"}{" "}
                    %
                  </td>
                </tr>
                <tr>
                  <td className="font-weight-bold md-font-weight">
                    Interest Compunding Period
                  </td>
                  <td>
                    {!!interestCompoundingPeriodType &&
                    !!interestCompoundingPeriodType.value
                      ? interestCompoundingPeriodType.value
                      : ""}
                  </td>
                </tr>{" "}
                <tr>
                  <td className="font-weight-bold md-font-weight">
                    Interest Posting Period
                  </td>
                  <td>
                    {!!interestPostingPeriodType &&
                    !!interestPostingPeriodType.value
                      ? interestPostingPeriodType.value
                      : ""}
                  </td>
                </tr>
                <tr>
                  <td className="font-weight-bold md-font-weight">
                    Interest Calculated Using
                  </td>
                  <td>
                    {!!interestCalculationType &&
                    !!interestCalculationType.value
                      ? interestCalculationType.value
                      : ""}
                  </td>
                </tr>
                <tr>
                  <td className="font-weight-bold md-font-weight">
                    # Days in Year
                  </td>
                  <td>
                    {!!interestCalculationDaysInYearType &&
                    !!interestCalculationDaysInYearType.value
                      ? interestCalculationDaysInYearType.value
                      : ""}
                  </td>
                </tr>{" "}
                <tr>
                  <td className="font-weight-bold md-font-weight">
                    Pre-Closure Penal Interest (Less)
                  </td>
                  <td>
                    {!!preClosurePenalInterest ? preClosurePenalInterest : ""}{"%"}
                    {/* On{" "}
                    {!!preClosurePenalInterestOnType &&
                    !!preClosurePenalInterestOnType.value
                      ? preClosurePenalInterestOnType.value
                      : ""} */}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
        {/* Performance History */}
        <div className="col-xl-6 col-md-12">
          <div className="m-2">
            <Table
              responsive
              striped
              bordered
              hover
              className="table-form-style"
            >
              <thead className="border-0">
                <tr>
                  <td
                    className="font-weight-bold md-font-weight text-center"
                    colSpan="2"
                  >
                    Performance History
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="font-weight-bold md-font-weight">
                    Principal Amount
                  </td>
                  <td>
                    {" "}
                    {!!currency.code ? currency.code : ""}{" "}
                    {!!depositAmount ? formatCurrency(depositAmount) : ""}
                  </td>
                </tr>
                <tr>
                  <td className="font-weight-bold md-font-weight">
                    Maturity Amount
                  </td>
                  <td>
                    {!!currency.code ? currency.code : ""}{" "}
                    {!!maturityAmount ? formatCurrency(maturityAmount) : "0"}
                  </td>
                </tr>
                {!!totalDeposits ? (
                  <>
                    {" "}
                    <tr>
                      <td className="font-weight-bold md-font-weight">
                        Total Deposits
                      </td>
                      <td>
                        {!!currency.code ? currency.code : ""}
                        {!!totalDeposits ? formatCurrency(totalDeposits) : "0"}
                      </td>
                    </tr>{" "}
                  </>
                ) : (
                  ""
                )}{" "}
                {!!totalInterestEarned ? (
                  <>
                    {" "}
                    <tr>
                      <td className="font-weight-bold md-font-weight">
                        Interest Earned
                      </td>
                      <td>
                        {!!currency.code ? currency.code : ""}
                        {!!totalInterestEarned
                          ? formatCurrency(totalInterestEarned)
                          : "0"}
                      </td>
                    </tr>{" "}
                  </>
                ) : (
                  ""
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default FixedDetails;
