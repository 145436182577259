import React from "react";
import ReportView from "./index";

const data = {
  name: "Customer Reports",
  overview:
    "A complete overview of your business with analysis and data metrics",
  filter: "Client",
};
const CustomerReport = () => {
  return <ReportView repData={data} />;
};

export default CustomerReport;
