import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ContentWrapper, ActionButton } from "./styles";
import Toast from "../../../../util/Toast";

import StepView from "./component/StepView";

import {
  getCreateCustomerDetails,
  getAllCountryCodes,
  getCodeValues,
} from "../../../../actions/utilityActions";

import {
  createNewCustomer,
  getAllCountries,
  getAllStates,
} from "../../../../actions/customerActions";
import { formatDate } from "../../../../util/FormatDate";

// Forms
import LegalForm from "./component/LegalForm";
import PersonalInformation from "./component/PersonalInformation";
import AccountInformation from "./component/AccountInformation";
import ContactAddress from "./component/ContactAddress";
import Document from "./component/Document";

const CreateNewCustomer = ({ history }) => {
  const dispatch = useDispatch();

  const [current, setCurrent] = useState(0);
  const [allCountryCodes, setAllCountryCodes] = useState([]);

  useEffect(() => {
    dispatch(
      getCreateCustomerDetails(
        "clients/template?staffInSelectedOfficeOnly=true"
      )
    );
    dispatch(getCodeValues());
    dispatch(getAllCountries());
  }, []);

  const allCodeValues = useSelector((state) => state.codeValues);
  let { codeValues } = allCodeValues;

  const createCustomerDetails = useSelector(
    (state) => state.createCustomerDetails.createCustomerDetails
  );

  const getCountryReducer = useSelector((state) => state.getCountryReducer);
  const { countryData } = getCountryReducer;

  const getStateReducer = useSelector((state) => state.getStateReducer);
  const { loading, stateData } = getStateReducer;

  useEffect(() => {
    if (!!codeValues && codeValues.length > 0) {
      const countryCode = codeValues.find((e) => e.name === "CountryCode").id;
      dispatch(getAllCountryCodes(`codes/${countryCode}/codevalues`));
    }
  }, [allCodeValues]);

  const countryCodesDetails = useSelector(
    (state) => state.countryCodesDetails.countryCodesDetails
  );

  useEffect(() => {
    if (!!countryCodesDetails && countryCodesDetails.length > 0) {
      setAllCountryCodes(countryCodesDetails);
    }
  }, [countryCodesDetails]);

  const {
    officeOptions = [],
    staffOptions = [],
    genderOptions = [],
    savingProductOptions = [],
    clientLegalFormOptions = [],
    address = [],
    clientTierOptions,
    clientClassificationOptions = [],
    clientTypeOptions = [],
    clientNonPersonConstitutionOptions = [],
  } = createCustomerDetails || {};

  const [createCustomer, setCreateCustomer] = useState({
    staffId: "",
    legalFormId: "1",
    firstname: "",
    lastname: "",
    middlename: "",
    genderId: "",
    mobileNo: "",
    contactCountryCode: "",
    companyIncNumber: "",
    remarks: "",
    emailAddress: "",
    tierRank: "",
    dateOfBirth: "",
    bvn: "",
    nin: "",
    cliendTypeId: "",
    cliendClassificationId: "",
    externalId: "",
    savingsProductId: "",
    companyName: "",
    constitutionId: "",
  });

  let {
    legalFormId,
    firstname,
    lastname,
    middlename,
    companyName,
    emailAddress,
    bvn,
    nin,
    genderId,
    tierRank,
    mobileNo,
    contactCountryCode,
    externalId,
    companyIncNumber,
    remarks,
    // cliendTypeId,
    // cliendClassificationId,
    savingsProductId,
    constitutionId,
  } = createCustomer || {};

  const createCustomerHandler = (e) => {
    setCreateCustomer({
      ...createCustomer,
      [e.target.name]: e.target.value,
    });
  };

  const setSavingsProductId = (value) => {
    setCreateCustomer({
      ...createCustomer,
      savingsProductId: value,
    });
  };

  const [officeId, setOfficeId] = useState("");
  const [staffId, setStaffId] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [companyIncDate, setCompanyIncDate] = useState("");
  const [companyIncValidityDate, setCompanyIncValidityDate] = useState("");
  const [isStaff, setIsStaff] = useState(false);
  const [submittedOn, setSubmittedOn] = useState(new Date());
  const [openSavingsAccount, setOpenSavingsAccount] = useState(false);
  const [activatedOn, setActivatedOn] = useState("");
  const [isAccountActive, setIsAccountActive] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState("");

  let defaultAddress = {
    addressTypeId: "",
    countryId: "",
    countryValue: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    city: "",
    stateProvinceId: "",
    postalCode: "",
  };
  const [addresses, setAddresses] = useState([defaultAddress]);

  const [signature, setSignature] = useState(null);
  const [imgPayload, setImgPayload] = useState(null);

  const handleCreateCustomer = () => {
    if (!officeId || officeId === 0) {
      dispatch(Toast({ text: "Please Select an Office", icon: "info" }));
    } else if (!legalFormId) {
      dispatch(Toast({ text: "Please Select a Legal Form", icon: "info" }));
    } else if (!tierRank) {
      dispatch(Toast({ text: "Please Select a Tier Level", icon: "info" }));
    } else if (
      (legalFormId === "0" || legalFormId === "1") &&
      (!firstname || !lastname)
    ) {
      dispatch(Toast({ text: "Please Provide Customer Name", icon: "info" }));
    } else if (legalFormId === "2" && !companyName) {
      dispatch(Toast({ text: "Please Provide Entity Name", icon: "info" }));
    } else if (legalFormId === "2" && !emailAddress) {
      dispatch(
        Toast({ text: "Please Provide Entity Email Address", icon: "info" })
      );
    } else if (
      addresses &&
      (!addresses.length ||
        !addresses[0].addressLine1 ||
        !addresses[0].addressTypeId ||
        !addresses[0].stateProvinceId ||
        !addresses[0].countryId)
    ) {
      dispatch(
        Toast({
          text: "Please Provide All Neccessary Paramters on Customer's Address",
          icon: "info",
        })
      );
    } else {
      let userData = {
        firstname,
        lastname,
        middlename,
        genderId,
        mobileNo,
        externalId,
        countryCodeId: contactCountryCode,
        emailAddress,
        dateOfBirth: (dateOfBirth !== "" && formatDate(dateOfBirth)) || "",
        active: isAccountActive,
        officeId,
        tierRank: parseInt(tierRank),
        legalFormId,
        // activationDate: (activatedOn !== '' && formatDate(activatedOn)) || '',
        activationDate: activatedOn ? formatDate(activatedOn) : undefined,

        submittedOnDate: (submittedOn !== "" && formatDate(submittedOn)) || "",
        locale: "en",
        dateFormat: "dd MMMM yyyy",
        staffId,
        address: addresses.map((address) => ({
          ...address,
          countryValue: undefined, // Remove countryValue from each address object
        })),
        // address: [...addresses],
        isStaff,
        // cliendTypeId,
        // cliendClassificationId,
      };
      let clientNonPersonDetails = {
        incorpNumber: companyIncNumber,
        remarks,
        locale: "en",
        dateFormat: "dd MMMM yyyy",
        // incorpDate: (companyIncDate !== "" && formatDate(companyIncDate)) || "",
        incorpValidityTillDate:
          (companyIncValidityDate !== "" &&
            formatDate(companyIncValidityDate)) ||
          "",
        constitutionId,
      };
      let companyData = {
        clientNonPersonDetails,
        fullname: companyName,
        legalFormId,
        officeId,
        active: isAccountActive,
        // address: [...addresses],
        address: addresses.map((address) => ({
          ...address,
          countryValue: undefined, // Remove countryValue from each address object
        })),
        locale: "en",
        dateFormat: "dd MMMM yyyy",
        staffId,
        submittedOnDate: (submittedOn !== "" && formatDate(submittedOn)) || "",
        dateOfBirth:
          (companyIncDate !== "" && formatDate(companyIncDate)) || "",
        externalId,
        // activationDate: (activatedOn !== "" && formatDate(activatedOn)) || "",
        activationDate: activatedOn ? formatDate(activatedOn) : undefined,

        emailAddress: emailAddress,
        // countryCodeId: contactCountryCode,
        mobileNo: mobileNo,
        tierRank: parseInt(tierRank),

        // cliendTypeId,
        // cliendClassificationId,
      };
      let additionalData = {
        Bvn: bvn,
        NIN: nin,
        dateFormat: "yyyy-MM-dd",
        locale: "en",
      };

      if (openSavingsAccount) {
        companyData.savingsProductId = savingsProductId;
        userData.savingsProductId = savingsProductId;
      }

      let signatureData = null;
      if (signature !== null) {
        signatureData = new FormData();
        signatureData.append("file", signature);
        signatureData.append("description", "Client Signature");
        signatureData.append("name", "ClientSignature");
      }
      let formData = null;
      if (imgPayload !== null) {
        formData = new FormData();
        formData.append("file", imgPayload);
      }

      const newObject = { ...userData };
      for (const key in newObject) {
        if (Object.prototype.hasOwnProperty.call(newObject, key)) {
          const element = newObject[key];
          if (!element) {
            delete newObject[key];
          } else if (
            element === null ||
            element === "" ||
            element === undefined
          ) {
            delete newObject[key];
          } else if ((element + "").length <= 0) {
            delete newObject[key];
          }
        }
      }

      userData = newObject;

      dispatch(
        createNewCustomer(
          legalFormId === "1" ? userData : companyData,
          formData,
          !!bvn || !!nin ? additionalData : "",
          signatureData,
          history
        )
      );
    }
  };

  useEffect(() => {
    if (createCustomerDetails && createCustomerDetails.officeId) {
      setOfficeId(createCustomerDetails.officeId);
    }
  }, [createCustomerDetails]);

  useEffect(() => {
    if (officeId === 0 || officeId) {
      dispatch(
        getCreateCustomerDetails(
          `clients/template?officeId=${officeId}&staffInSelectedOfficeOnly=true`
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [officeId]);

  useEffect(() => {
    const getSelectedCountry = () => {
      const selectedCountryId = addresses.map((k) => k.countryValue);
      setSelectedCountry(selectedCountryId);
    };

    getSelectedCountry();
  }, [addresses[0]?.countryValue]);

  useEffect(() => {
    if (!!selectedCountry && selectedCountry.length > 0) {
      dispatch(getAllStates(selectedCountry[0]));
    }
  }, [selectedCountry]);

  const setCountryValue = (countryName) => {
    // Find the country object with the matching name
    if (countryData?.length > 0) {
      const matchingCountry = countryData?.find(
        (country) => country.name === countryName
      );

      // If a matching country is found, set the countryValue in addresses state
      if (matchingCountry) {
        setAddresses((prevAddresses) => [
          {
            ...prevAddresses[0],
            countryId: matchingCountry.id.toString(),
            // countryValue: matchingCountry.id.toString(), // Set it as a string if needed
          },
        ]);
      }
    }
  };

  // Example usage: Set the countryValue for "Albania"
  useEffect(() => {
    setCountryValue(addresses[0]?.countryValue);
  }, [addresses[0]?.countryValue]);

  // Now, defaultAddress should be updated with the countryId and countryValue


  const renderComponent = () => {
    let currentComponet = "";
    switch (current) {
      case 0:
        currentComponet = (
          <LegalForm
            {...createCustomer}
            createCustomerHandler={createCustomerHandler}
            clientLegalFormOptions={clientLegalFormOptions}
            staffOptions={staffOptions}
            officeOptions={officeOptions}
            officeId={officeId}
            setOfficeId={setOfficeId}
            staffId={staffId}
            setStaffId={setStaffId}
          />
        );
        break;
      case 1:
        currentComponet = (
          <PersonalInformation
            {...createCustomer}
            createCustomerHandler={createCustomerHandler}
            dateOfBirth={dateOfBirth}
            setDateOfBirth={setDateOfBirth}
            companyIncDate={companyIncDate}
            setCompanyIncDate={setCompanyIncDate}
            companyIncValidityDate={companyIncValidityDate}
            setCompanyIncValidityDate={setCompanyIncValidityDate}
            genderOptions={genderOptions}
            countryCodes={allCountryCodes}
            clientNonPersonConstitutionOptions={
              clientNonPersonConstitutionOptions
            }
            constitutionId={constitutionId}
          />
        );
        break;
      case 2:
        currentComponet = (
          <AccountInformation
            {...createCustomer}
            tierRank={tierRank}
            clientTierOptions={clientTierOptions}
            createCustomerHandler={createCustomerHandler}
            isStaff={isStaff}
            setIsStaff={setIsStaff}
            submittedOn={submittedOn}
            setSubmittedOn={setSubmittedOn}
            openSavingsAccount={openSavingsAccount}
            setOpenSavingsAccount={setOpenSavingsAccount}
            activatedOn={activatedOn}
            setActivatedOn={setActivatedOn}
            isAccountActive={isAccountActive}
            setIsAccountActive={setIsAccountActive}
            savingProductOptions={savingProductOptions}
            clientClassification={clientClassificationOptions}
            clientTypeOptions={clientTypeOptions}
            setSavingsProductId={setSavingsProductId}
          />
        );
        break;
      case 3:
        currentComponet = (
          <ContactAddress
            addresses={addresses}
            setAddresses={setAddresses}
            defaultAddress={defaultAddress}
            address={address}
            countryData={countryData}
            stateData={stateData}
          />
        );
        break;
      case 4:
        currentComponet = (
          <Document
            signature={signature}
            setSignature={setSignature}
            imgPayload={imgPayload}
            setImgPayload={setImgPayload}
          />
        );
        break;
      default:
    }
    return currentComponet;
  };

  const handlePreviousScreen = () => {
    if (current !== 0) {
      setCurrent(current - 1);
    }
  };

  const handleNextScreen = () => {
    if (current < 4) {
      setCurrent(current + 1);
    } else if (current === 4) {
      handleCreateCustomer();
    }
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">New Customer</h3>
            <p className="sub-title mt-2">
              <span className="text-info mr-1">
                <Link to="/core/clients">Customer</Link>
              </span>{" "}
              / New Customer
            </p>
          </div>
        </div>
        <ContentWrapper className="application-wrapper">
          <StepView
            current={current}
            legalFormId={legalFormId}
            setCurrent={setCurrent}
          />
          <div className="container">
            <div className="row mt-2">
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                {renderComponent()}
              </div>
            </div>
            <div className="spaceBetweenFlexEnd my-4 mr-5">
              {current <= 0 ? (
                ""
              ) : (
                <ActionButton color="#2C1DFF" onClick={handlePreviousScreen}>
                  <img
                    src={
                      require("../../../../assets/images/icons/cancel.svg")
                        .default
                    }
                    alt="cancel icon"
                  />
                  Previous
                </ActionButton>
              )}

              <ActionButton
                color="#2C1DFF"
                style={{ margin: "0px 0px 0 10px" }}
                onClick={handleNextScreen}
              >
                {current === 4 ? (
                  "Submit"
                ) : (
                  <>
                    <img
                      src={
                        require("../../../../assets/images/icons/save.svg")
                          .default
                      }
                      alt="submit icon"
                    />
                    Next{" "}
                  </>
                )}
              </ActionButton>
            </div>
          </div>
        </ContentWrapper>
      </div>
    </>
  );
};
export default CreateNewCustomer;
