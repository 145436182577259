import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { Checkbox } from "antd";
import DatePicker from "react-datepicker";
import { Input, Select, FormControl } from "@chakra-ui/react";
import { FormClass, ActionButton, FormElements } from "../styles";
import { formatDate } from "../../../../../util/FormatDate";
import {
  closeSavingsAccount,
  getTransactionTemplate,
} from "../../../../../actions/SavingsAndLoanActions";

const CloseAccount = ({ onHide, history, id, savingsAccountDetails }) => {
  const dispatch = useDispatch();

  const savingsAndLoan = useSelector((state) => state.savingsAndLoan);

  let { savingsAccountTransactionTemplate = {} } = savingsAndLoan || {};

  let { summary = {} } = savingsAccountDetails || {};

  let { paymentTypeOptions = [] } = savingsAccountTransactionTemplate || {};

  const [closeOn, setCloseOn] = useState();
  const [withdawBalance, setWithdrawBalance] = useState(false);
  const [postIntrestOnClosureDate, setPostIntrestOnClosureDate] =
    useState(false);
  const [note, setNote] = useState("");

  const [amount, setAmount] = useState(
    !!summary.availableBalance ? summary.availableBalance : 0
  );
  const [paymentType, setPaymentType] = useState();

  let closeOnDate = new Date(closeOn);

  useEffect(() => {
    dispatch(getTransactionTemplate(id));
  }, [id, dispatch]);

  const handleCloseAccount = () => {
    let dataToSend = {
      dateFormat: "dd MMM yyyy",
      locale: "en",
      closedOnDate: formatDate(closeOnDate, true),
      note,
      paymentTypeId: paymentType,
      postInterestValidationOnClosure: postIntrestOnClosureDate,
      withdrawBalance: withdawBalance,
    };
    dispatch(closeSavingsAccount(id, dataToSend, history));
    onHide();
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>Close Savings Account</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <FormElements style={{ width: "100%" }}>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Due For Collection On*</label>
              <FormControl style={{ width: "60%" }}>
              <div className="datepicker-100-width">

                <DatePicker
                  id="published-date"
                  selected={closeOn}
                  onChange={(date) => setCloseOn(date)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="dd/mmm/yyyy"
                />
                </div>
              </FormControl>
            </div>
            {/* Withdraw balance */}
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Withdraw Balance*</label>

              <Checkbox
                onChange={(e) => setWithdrawBalance(!withdawBalance)}
                value={withdawBalance}
              ></Checkbox>
            </div>
            {/* setPostIntrestOnClosureDate */}
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">
                Is Interest Posting Required On Closure Date?*
              </label>

              <Checkbox
                onChange={(e) =>
                  setPostIntrestOnClosureDate(!postIntrestOnClosureDate)
                }
                value={postIntrestOnClosureDate}
              ></Checkbox>
            </div>

            {withdawBalance === true ? (
              <>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Transaction Amount*</label>
                  <Input
                    style={{ width: "60%" }}
                    size="md"
                    name="accNumber"
                    disabled
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Payment Type</label>
                  <Select
                    placeholder="Select Payment Type"
                    size="md"
                    name="paymentType"
                    value={paymentType}
                    onChange={(e) => setPaymentType(e.target.value)}
                  >
                    {!!paymentTypeOptions ? (
                      paymentTypeOptions.map(({ name, id }) => {
                        return (
                          <option key={id} value={id}>
                            {name}
                          </option>
                        );
                      })
                    ) : (
                      <option>Loading</option>
                    )}
                  </Select>
                </div>
              </>
            ) : (
              ""
            )}
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Note</label>
              <Input
                style={{ width: "60%" }}
                size="md"
                name="accNumber"
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </div>
          </FormElements>
      
        </FormClass>
      </Modal.Body>

      <Modal.Footer>
      <div className="d-flex mt-1">
            <ActionButton color="#2C1DFF" onClick={onHide}>
              <img
                src={
                  require("../../../../../assets/images/icons/cancel.svg")
                    .default
                }
                alt="cancel icon"
              />
              Cancel
            </ActionButton>
            <ActionButton
              color="#2C1DFF"
              style={{ margin: "0px 0px 0 10px" }}
              onClick={(e) => {
                e.preventDefault();
                handleCloseAccount();
              }}
            >
              <img
                src={
                  require("../../../../../assets/images/icons/save.svg").default
                }
                alt="submit icon"
              />
              Submit
            </ActionButton>
          </div>
      </Modal.Footer>
    </>
  );
};

export default CloseAccount;
