import React, { useState, useEffect, useRef } from "react";
import {
  ContentWrapper,
  ActionButtonsGroup,
  ActionButton,
  EditButton,
} from "./styles";
import { InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import ModalContainer from "../../../components/ModalContainer";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllOffices,
  filterOffices,
  clearFilteredOffices,
} from "../../../../../actions/configurationActions";
import Toast from "../../../../../util/Toast";
import TableLoader from "./components/TableLoader";
import CreateOffice from "./components/CreateOffice";
import EditOffice from "./components/EditOffice";
import TreeView, { Tree, InnerTree } from "../../../../../components/TreeView";
import CheckPermission from "../../../../../util/CheckPermission";

const ManageOfficesView = () => {
  const [modalState, toggleModal] = useState(false);

  const dispatch = useDispatch();

  const [modalContentName, setModalContentName] = useState("");
  const [selectedOffice, setSelectedOffice] = useState({});
  const [isTree, setIsTree] = useState(false);

  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  const currentContent = (e) => {
    const val = e.currentTarget.id.trim();

    if (val) {
      toggleModal(true);
      setModalContentName(val);
    }
  };
  const handleModalClose = () => toggleModal(false);

  const officeList = useSelector((state) => state.officeReducer);

  const { loading, offices, filtered } = officeList;
  offices.sort((a, b) => (a.name > b.name ? 1 : -1));

  const search = useRef("");
  const onFilterOffice = (e) => {
    setIsTree(false);
    search.current.value !== ""
      ? dispatch(filterOffices(e.target.value))
      : dispatch(clearFilteredOffices());
  };
  useEffect(() => {
    dispatch(clearFilteredOffices());
    dispatch(getAllOffices());
    if (filtered === null) {
      search.current.value = "";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditOffice = (id) => {
    setSelectedOffice(offices.filter((off) => off.id === id)[0]);
    toggleModal(true);
    setModalContentName("editOffice");
  };

  if (filtered !== null && filtered.length === 0) {
    Toast({
      text: "No office found",
      icon: "error",
      timer: 2000,
    });
  }

  //Table configuration

  const columns = [
    { title: "Office Name", dataIndex: "officename", key: "officename" },
    { title: "Parent Office", dataIndex: "parentoffice", key: "parentoffice" },
    { title: "External Id", dataIndex: "externalId", key: "externalId" },
    { title: "Date Created", dataIndex: "date", key: "date" },
    { title: "", dataIndex: "edit", key: "edit" },
  ];

  const dataSource = [];
  const filteredOffices = [];
  // console.log();
  if (filtered !== null) {
    filtered.forEach((user) => {
      const { id, name, externalId, openingDate, parentName } = user;
      filteredOffices.push({
        key: id,
        officename: name,
        parentoffice: parentName,
        externalId,
        date: new Date(openingDate).toLocaleDateString(),
        edit: CheckPermission("UPDATE_OFFICE", permissions) && (
          <EditButton offices={offices} onClick={() => handleEditOffice(id)}>
            Edit
          </EditButton>
        ),
      });
    });
  }

  !loading &&
    offices.forEach((off) => {
      const { id, name, externalId, openingDate, parentName } = off;
      dataSource.push({
        key: id,
        officename: name,
        parentoffice: parentName,
        externalId,
        date: new Date(openingDate).toLocaleDateString(),
        edit: CheckPermission("UPDATE_OFFICE", permissions) && (
          <EditButton onClick={() => handleEditOffice(id)}>Edit</EditButton>
        ),
      });
    });

  const displayModalContent = () => {
    switch (modalContentName) {
      case "createOffice":
        return <CreateOffice offices={offices} onHide={handleModalClose} />;
      case "editOffice":
        return (
          <EditOffice
            offices={offices}
            officeData={selectedOffice}
            onHide={handleModalClose}
          />
        );

      default:
        return;
    }
  };
  return (
    <>
      <ModalContainer show={modalState} dialogClassName="document_infoModal">
        {modalState && displayModalContent()}
      </ModalContainer>
      <div className="container-fluid">
        <div className="row justify-content-between pt-3">
          <div className="col-sm-12 col-md-4">
            <h3 className="page-title">Manage Offices</h3>
            <p className="font-weight sub-title mt-2">
              <span className="text-info">
                {/* <Link to="/core/configuration">Configuration</Link> /{" "} */}
                <Link to="/core/configuration/organization">Utilities</Link>
              </span>{" "}
              / Manage Offices
            </p>
          </div>
          <div className="col-sm-12 col-md-6 px-0">
            <div className="row" style={{ maxWidth: "100%" }}>
              <div className="col-sm-12 col-md-6 pr-2">
                <InputGroup>
                  <InputLeftElement
                    width="3.1rem"
                    height="38px"
                    pointerEvents="none"
                  >
                    <img
                      src={
                        require("../../../../../assets/images/icons/search.svg")
                          .default
                      }
                      style={{ height: "30px" }}
                      className="icon-img"
                      alt="Icon"
                    />
                  </InputLeftElement>
                  <Input
                    style={{
                      background: "#ffffff",
                      height: "50px",
                    }}
                    ref={search}
                    onChange={onFilterOffice}
                    type="text"
                    className="mb-3"
                    placeholder="Search by name /external ID"
                  />
                </InputGroup>
              </div>

              <div className="col-sm-12 col-md-6 p-0 d-flex">
                <ActionButton
                  onClick={() => setIsTree((p) => !p)}
                  className="mb-3"
                >
                  <img
                    src={
                      require(`../../../../../assets/images/icons/${
                        !isTree ? "tree" : "list"
                      }-view.svg`).default
                    }
                    alt="tree"
                  />
                  {!isTree ? "Tree View" : "List View"}
                </ActionButton>
                {CheckPermission("CREATE_OFFICE", permissions) && (
                  <ActionButton id="createOffice" onClick={currentContent}>
                    <img
                      src={
                        require("../../../../../assets/images/icons/add.svg")
                          .default
                      }
                      alt="add"
                    />
                    New Office
                  </ActionButton>
                )}
              </div>
            </div>
          </div>
        </div>
        <ContentWrapper>
          {loading ? (
            <TableLoader />
          ) : !isTree ? (
            <>
              {filtered !== null ? (
                <Table
                  columns={columns}
                  dataSource={filteredOffices}
                  // onRow={(r) => ({
                  //   onClick: () => (history.push(`/core/client/${r.key}`))
                  // })}
                />
              ) : (
                <div className="mt-2">
                  <Table
                    columns={columns}
                    dataSource={dataSource}
                    pagination={{ defaultPageSize: 13 }}
                    defaultPageSize={13}
                  />
                </div>
              )}
            </>
          ) : (
            <div className={isTree ? "p-5" : ""}>
              {offices && offices.length > 0 ? (
                <TreeView>
                  <Tree
                    lineWidth="14rem"
                    id={1}
                    name={
                      offices &&
                      offices.filter(
                        (item) => item.parentName === undefined || null
                      ).length > 0 &&
                      offices.filter(
                        (item) => item.parentName === undefined || null
                      )[0].name
                    }
                  >
                    {[
                      ...new Set(
                        offices
                          .filter(
                            (item) => item.parentName !== undefined || null
                          )
                          .map((item) => item.parentName)
                      ),
                    ].map((type, j) => (
                      <div key={j}>
                        {offices.filter((item) => item.parentName === type)
                          .length > 0 ? (
                          <Tree name={type}>
                            {offices
                              .filter((item) => item.parentName === type)
                              .map((item, i) => (
                                <div key={i}>
                                  <InnerTree
                                    name={item.name}
                                    style={{ color: "#37ceff" }}
                                  />
                                </div>
                              ))}
                          </Tree>
                        ) : (
                          <Tree name={type} />
                        )}
                      </div>
                    ))}
                  </Tree>
                </TreeView>
              ) : (
                <p>No Data found</p>
              )}
            </div>
          )}
        </ContentWrapper>
      </div>
    </>
  );
};

export default ManageOfficesView;
