import styled from "styled-components";

export const ActionButtonsGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

export const ActionButton = styled.button`
  background: #2C1DFF !important;
  border-radius: 5px !important;
  color: #fff !important;
  margin: 0 10px;
  border: none;
  display: inline;
  font-size: 14px;
  height: 35px;
  font-family: 'AventaMedium';
  max-width: 120px;
  min-width: 100px;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  img {
    height: 14px;
    display: inline;
    margin-right: 10px;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;

export const NewActionButton = styled.button`
  background: #2C1DFF !important;
  border-radius: 5px !important;
  color: #fff !important;
  margin: 0 10px;
  border: none;
  display: inline;
  font-size: 14px;
  font-family: 'AventaMedium';
  height: 35px;
  max-width: 130px;
  min-width: 100px;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  img {
    height: 14px;
    display: inline;
    margin-right: 7px;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;

export const RoleButton = styled.button`
  background: ${(props) => props.color} !important;
  border-radius: 5px !important;
  color: ${(props) => (props.textColor ? props.textColor : "#fff")} !important;
  margin: 0 10px;
  border: none;
  display: inline;
  font-size: 14px;
  height: 35px;
  width: 30%;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  position: relative;
  cursor: pointer;
  img {
    height: 10px;
    margin-right: 10px;
    display: inline;
  }
  &:hover {
    box-shadow: ${(props) =>
      props.shadow ? "0px 4px 4px rgba(0, 0, 0, 0.09)" : "none"};
    color: #fff;
  }
`;

export const ContentWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 0px #ededf6;
  min-height: 70vh;
  margin-top: 5px;
`;

export const IconButton = styled.button`
  width: 36px;
  height: 36px;
  background: #2C1DFF;
  text-align: center;
  border-radius: 5px;
  img {
    display: inline;
    margin-right: 7px;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;

export const ModalText = styled.p`
  font-size: 15px;
  color: #000000;
  margin: 10px 0 10px 0;
`;

export const FormClass = styled.div``;

export const FormElements = styled.div`
  width: 90%;
  display: block;
`;

export const IconContainer = styled.span`
  padding: 6px 3px;
  border-radius: 50%;
  transition: background-color 0.2s ease-in-out !important;
  &:hover {
    background-color: rgba(4, 176, 252, 0.1);
  }
`;

export const RoleContainer = styled.div`
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid #dadce6;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 16px;
  min-height: 120px;
`;
