
import styled from "styled-components";

export const ActionButtonsGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

export const IconButton = styled.button`
  background: ${(props) => props.color} !important;
  width: 22px;
  height: 22px;
  background: #2C1DFF;
  text-align: center;
  border-radius: 5px;
  margin-right: 10px;
  img {
    height: 15px;
    display: inline;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;

export const ActionButton = styled.button`
  background: #2C1DFF !important;
  border-radius: 5px !important;
  color: #fff !important;
  margin: 0 10px;
  border: none;
  display: inline;
  font-size: 14px;
  height: 35px;
  max-width: 200px;
  min-width: 180px;
  font-family: 'AventaMedium';
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  img {
    height: 14px;
    display: inline;
    margin-right: 10px;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;

export const FormTitle = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  p {
    color: #01b1fc !important;
  }
  .lineThrough {
    margin-left: 30px;
    flex: auto;
    height: 1px;
    border: 1px solid #e1e1e4;
  }
`;

export const EditButton = styled.button`
  background: #2C1DFF !important;
  border-radius: 5px !important;
  color: #fff !important;
  border: none;
  display: inline;
  font-size: 14px;
  height: 30px;
  width: 78px;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
  }
`;

export const ContentWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 0px #ededf6;
  margin-top: 20px;
  table {
    font-size: 14px;
  }
  .css-42b2qy {
    width: 60% !important;
  }
`;

export const FormClass = styled.div`
  .w-80 {
    width: 80% !important;
  }
`;

export const FormElements = styled.div`
  width: 90%;
  margin-top: 30px;
  margin-bottom: 30px;
  display: block;

  label {
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    color: #969cba;
    width: 30%;
  }
`;

export const BlueBox = styled.span`
  width: 19px;
  height: 19px;
  background: #2C1DFF;
  border-radius: 2px;
`;
