import styled from "styled-components";

export const FilterButtonsGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  button {
    background: #ffffff;

    font-size: 14px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    border-radius: 3px;
    border: none;
    padding: 0 16px;
    margin-right: 9px;
    // flex: auto;
    transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out;
    margin-bottom: 1rem;
    margin-top: 1rem;

    &:hover {
      box-shadow: 0px 2px 20px rgba(0, 91, 252, 0.24);
      color: #2C1DFF;
    }
  }
  .active-filter {
    border: 2px solid #2C1DFF !important;
  }
`;

export const RefreshButton = styled.button`
  background: #2C1DFF !important;
  border-radius: 5px !important;
  color: #fff !important;
  font-family: 'AventaMedium';
  height: 35px;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;

export const OverviewStatsCard = styled.div`
  height: 100px;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: start;
  padding-right: 20px;
  padding-left: 20px;
  box-shadow: 0px 2px 0px #ededf6;
  width: 100%;
  .card-icon {
    // width: 60px;
    // height: 60px;
    // background: rgba(0, 91, 252, 0.05);
    // border-radius: 100%;
    margin-right: 10px;
  }
  .card-stats-count {
    margin-left: 20px;
    // h1 {
    //   font-style: normal;
    //   font-weight: bold;
    //   font-size: 20px;
    //   line-height: 24px;
    //   letter-spacing: 0.01em;
    //   color: #000000;
    //   margin: 0;
    //   margin-bottom: 10px;
    // }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0.01em;
      color: #000;
      margin: 0;
    }
  }
`;

export const ChartMarker = styled.div`
  height: 8px;
  width: 8px;
  background: ${(props) => props.color};
  display: inline-block;
  border-radius: 100%;
  margin-right: 10px;
`;

export const ChartCard = styled.div`
  background: #ffffff;
  border-radius: 10px;
  height: 420px;
  box-shadow: 0px 2px 0px #ededf6;
  .heading {
    padding: 20px 25px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eef0f7;
    font-size: 13px;
    .title {
      font-size: 13px;
      line-height: 24px;
      letter-spacing: 0.01em;
    }
    div {
      // flex: auto;
    }
    p {
      margin: 0;
    }
  }
`;

export const PieChartLabels = styled.div`
  width: 100%;
  font-size: 14px;
    font-family: 'AventaRegular';
    font-weight: 400;

  div {
    margin-bottom: 20px;
  }
  p {
    font-size: 16px;
  }
`;

export const PieChartMeta = styled.div`
  width: 70px;
  height: 70px;
  position: absolute;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  border-radius: 100%;
  align-items: center;
  h2 {
    font-weight: 700;
    font-size: 0.875rem;
    color: #000000;
  }
`;
