import React, { useState, useEffect } from "react";
import {
  ContentWrapper,
  ActionButtonsGroup,
  ActionButton,
  NewActionButton,
  ActionButton2,
  FormElements,
  FormClass,
} from "./styles";
import "./checker.scss";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  updateRolesInfo,
  getRole,
} from "../../../../../actions/configurationActions";
import Loader from "./components/Loader";
import { FormControl, Checkbox } from "@chakra-ui/react";
import Toast from "../../../../../util/Toast";

const ViewRoleView = ({ history, match }) => {
  const [editState, setEditState] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [tasksState, settasksState] = useState({});
  const dispatch = useDispatch();

  const roleReducer = useSelector((state) => state.roleReducer);

  const {
    loading,
    role: { permissionUsageData = [], name },
  } = roleReducer;

  useEffect(() => {
    dispatch(getRole(match.params.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (permissionUsageData.length > 0) {
      permissionUsageData.map((item) =>
        settasksState((p) => ({
          ...p,
          [item.code]: item.selected,
        }))
      );
      setSelectedGroup(permissionUsageData[0].grouping);
    }
  }, [permissionUsageData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    const func = () => {
      setProcessing(false);
      setEditState(false);
      dispatch(getRole(match.params.id));
    };
    await dispatch(
      updateRolesInfo({ permissions: tasksState }, match.params.id, func)
    );
    setProcessing(false);
  };

  const handleSelectAll = () => {
    permissionUsageData.filter((item) => item.grouping === selectedGroup)
      .length > 0
      ? permissionUsageData
          .filter((item) => item.grouping === selectedGroup)
          .map((item) =>
            settasksState((p) => ({
              ...p,
              [item.code]: true,
            }))
          )
      : dispatch(
          Toast({
            text: "No permission to select",
            icon: "error",
            timer: 2000,
          })
        );
  };

  const handleDeselectAll = () => {
    permissionUsageData.filter((item) => item.grouping === selectedGroup)
      .length > 0
      ? permissionUsageData
          .filter((item) => item.grouping === selectedGroup)
          .map((item) =>
            settasksState((p) => ({
              ...p,
              [item.code]: false,
            }))
          )
      : dispatch(
          Toast({
            text: "No permission to select",
            icon: "error",
            timer: 2000,
          })
        );
  };

  console.log(selectedGroup, "selectedGroup");
  console.log(permissionUsageData, "permissionUsageData");

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title text-capitalize">
              {name ? name : "View Role"}
            </h3>
            <p className="sub-title">Define Or Modify Associated Permissions</p>
            <p className="font-weight sub-title mt-2">
              <span className="text-info">
                <Link to="/core/roles-management">
                  Manage Roles And Permissions
                </Link>
              </span>{" "}
              / {name ? name : "View Role"}
            </p>
          </div>
          <div className="col-sm-12 col-md-6 px-0 d-flex justify-content-end align-items-end">
            {!editState && (
              <ActionButtonsGroup>
                <ActionButton2
                  style={{ width: 225 }}
                  onClick={() => setEditState(true)}
                >
                  <img
                    src={
                      require("../../../../../assets/images/icons/edit-profile.svg")
                        .default
                    }
                    alt="add"
                  />
                  Edit
                </ActionButton2>
              </ActionButtonsGroup>
            )}
          </div>
        </div>
        <ContentWrapper>
          <div style={{ paddingTop: "50px" }}>
            <div className="d-flex justify-content-end align-items-end">
              {editState && (
                <ActionButtonsGroup>
                  <ActionButton2
                    style={{ width: 140 }}
                    onClick={handleSelectAll}
                  >
                    Select All
                  </ActionButton2>
                  <ActionButton2
                    style={{ width: 140 }}
                    onClick={handleDeselectAll}
                  >
                    Deselect All
                  </ActionButton2>
                </ActionButtonsGroup>
              )}
            </div>
            <h3 className="text-capitalize pl-2">
              Permissions:{" "}
              <span>
                {selectedGroup
                  ? selectedGroup.replace(/_/g, " ")
                  : selectedGroup}
              </span>
            </h3>
            {loading ? (
              <Loader />
            ) : (
              <div className="checkerWrapper">
                <div className="checkerSidebar">
                  {permissionUsageData
                    ? permissionUsageData.length > 0 &&
                      [
                        ...new Set(
                          permissionUsageData.map(({ grouping }) => grouping)
                        ),
                      ]
                        // filter out report from the list
                        .filter((value) => value !== "report")
                        .map((grouping) => (
                          <div className="mb-3" key={grouping}>
                            <NewActionButton
                              color={
                                selectedGroup === grouping
                                  ? "#002C79"
                                  : "#FFFFFF"
                              }
                              textColor={
                                selectedGroup === grouping
                                  ? "#FFFFFF"
                                  : "#2C1DFF"
                              }
                              className="text-capitalize"
                              onClick={() => setSelectedGroup(grouping)}
                            >
                              <span className="new-design-text">
                                {grouping
                                  ? grouping.toLowerCase().replace(/_/g, " ")
                                  : grouping}
                              </span>
                              <img
                                textColor={
                                  selectedGroup === grouping
                                    ? "#FFFFFF"
                                    : "#2C1DFF"
                                }
                                src={
                                  // require("../../../assets/images/icons/arror-forward.svg")
                                  require("../../../../../assets/images/icons/Arrow-Vector.svg")
                                    .default
                                }
                                className="d-inline mr-3 icon-img"
                                alt="icon"
                                fill="#fff"
                              />
                            </NewActionButton>
                          </div>
                        ))
                    : ""}
                </div>
                <div className="checkerContent">
                  {permissionUsageData.filter(
                    (item) => item.grouping === selectedGroup
                  ).length > 0
                    ? permissionUsageData
                        .filter((item) => item.grouping === selectedGroup)
                        .map((item) => (
                          <FormClass key={item.code}>
                            <FormElements>
                              <div className="form-group d-flex text-capitalize align-items-center">
                                <FormControl style={{ width: "10%" }}>
                                  <Checkbox
                                    size="md"
                                    disabled={!editState}
                                    isDisabled={!editState}
                                    isChecked={tasksState[item.code]}
                                    onChange={(ev) =>
                                      settasksState((p) => ({
                                        ...p,
                                        [item.code]: ev.target.checked,
                                      }))
                                    }
                                  />
                                </FormControl>
                                {/* <label className="text-muted d-flex flex-align-center">
                                  {item.actionName
                                    ? `${item.actionName.toLowerCase()} ${
                                        item.entityName &&
                                        item.entityName.toLowerCase()
                                      }`
                                    : item.code}
                                </label> */}
                                <label className="text-muted d-flex flex-align-center">
                                  {item?.actionName ? `${item?.code?.replace('_', ' ')}` : item.code}
                                </label>
                                {/* json_data['code'] = json_data['code'].replace('_', ' ').upper() */}
                              </div>
                            </FormElements>
                          </FormClass>
                        ))
                    : null}
                </div>
              </div>
            )}
            {editState && (
              <div className="float-right">
                <ActionButton
                  color="#2C1DFF"
                  onClick={() => setEditState(false)}
                >
                  <img
                    src={
                      require("../../../../../assets/images/icons/cancel.svg")
                        .default
                    }
                    alt="cancel icon"
                  />
                  Cancel
                </ActionButton>

                <ActionButton
                  onClick={handleSubmit}
                  color="#2C1DFF"
                  style={{ margin: "0px 0px 0 10px" }}
                  disabled={processing}
                >
                  {processing ? (
                    <Spinner
                      animation="border"
                      color="#fff"
                      className="mr-2 text-white"
                      size="sm"
                    />
                  ) : (
                    <img
                      src={
                        require("../../../../../assets/images/icons/save.svg")
                          .default
                      }
                      alt="submit icon"
                    />
                  )}
                  Submit
                </ActionButton>
              </div>
            )}
          </div>
          <br />
          <br />
          {/* <br /> */}
        </ContentWrapper>
      </div>
    </>
  );
};

export default ViewRoleView;
