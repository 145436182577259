import React, { useEffect, useState } from "react";
import {
  ActionButtonsGroup,
  ContentWrapper,
  ContentWrapperNull,
  DownloadActionButton,
  NodataText,
  ProcessActionButton,
} from "./styles";
import ModalContainer from "../../../components/ModalContainer";
import ProcessEOYModal from "./ProcessEOYModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllGlAccounts,
  getAllGlAccountsBalances,
  getOffices,
} from "../../../actions/accountingActions";
import { Table } from "antd";
import Toast from "../../../util/Toast";
import { sendUserSettlement } from "../../../actions/settlementActions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getAllCurrency } from "../../../actions/configurationActions";

const SettlementView = ({ pagination: externalPagination }) => {
  const dispatch = useDispatch();

  const history = useHistory();
  const accountingData = useSelector((state) => state.accountingData);
  const getGlAccountsBalances = useSelector(
    (state) => state.getGlAccountsBalances
  );
  let { offices, glAccounts } = accountingData || {};

  let { eoyGlAccountsBalances } = getGlAccountsBalances || {};

  const currencyList = useSelector((state) => state.currencyReducer);

  const {
    loading,
    currencies: { selectedCurrencyOptions, currencyOptions },
  } = currencyList;


  const [office, setOffice] = useState("");
  const [transactionDate, setTrasactionDate] = useState("");
  const [accountGl, setAccountGl] = useState("");
  const [currencySelected, setCurrencySelected] = useState("");

  const [processModalState, processToggleModal] = useState(false);

  const [glBalanceLoader, setGlBalanceLoader] = useState(true);
  //   const [data, setData] = useState(null);

  const [currentView, setCurrentView] = useState(1);

  useEffect(() => {
    dispatch(getOffices());
    dispatch(getAllGlAccounts(3));
    dispatch(getAllCurrency());
  }, [dispatch]);

  const [pagination, setPagination] = useState({
    current: externalPagination?.current || 1,
    pageSize: externalPagination?.pageSize || 15,
  });

  const handleTableChange = (pagination, filters, sorter) => {
    // Handle table changes and update the pagination state
    setPagination({ ...pagination });
  };

  const columns = [
    { title: "GL Name", dataIndex: "glName", key: "glNAme" },
    { title: "Expense", dataIndex: "expense", key: "expense" },
    {
      title: "Income",
      dataIndex: "income",
      key: "income",
    },
  ];

  function formatBalance(amount) {
    // Ensure amount is a number
    const parsedAmount = parseFloat(amount);

    // Check if parsedAmount is a valid number
    if (isNaN(parsedAmount)) {
      return "Invalid Amount";
    }

    // Use toLocaleString to format the number with commas and decimal places
    const formattedAmount = parsedAmount.toLocaleString("en-US", {
      // style: "currency",
      // currency: "NGN", // Change this to your currency code
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formattedAmount;
  }

  const tableData = [];

  !glBalanceLoader &&
    eoyGlAccountsBalances.forEach((accounts) => {
      const {
        id,
        name,
        balance,
        type: { value },
      } = accounts;

      const formattedBalance = formatBalance(balance);

      tableData.push({
        id,
        glName: name,
        income: value === "INCOME" ? formattedBalance : "0",
        expense: value === "EXPENSE" ? formattedBalance : "0",
      });
    });

  //   const loading = true;

  function formatCustomDate(dateString) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0"); // Ensure two digits
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  }

  const onHide = () => {
    processToggleModal(false);
  };

  const getGlBalances = async () => {
    let newDate = new Date(transactionDate);

    let date = formatCustomDate(newDate);
    let currency = currencySelected;
    // let data = {
    //   transactionDate: formatDate(newDate, true),
    // };
    if (!office || office.length < 1) {
      Toast({ text: "Please select an Office" });
    } else if (!accountGl || accountGl.length < 1) {
      Toast({ text: "Please select a GL Account" });
    } else if (!transactionDate) {
      Toast({ text: "Please select a transaction Date" });
    } else {
      dispatch(
        await getAllGlAccountsBalances(
          date,
          history,
          office,
          accountGl,
          transactionDate,
          currency,
        )
      );
    }
  };

  const handleSendSettlement = () => {
    // e.preventDefault();
    let newDate = new Date(transactionDate);

    let data = {
      officeId: office,
      transactionDate: formatCustomDate(newDate, true),
      locale: "en",
      currencyCode: "NGN",
      dateFormat: "dd MMMM yyyy",
      equityAccountId: accountGl,
    };

    if (!office || office.length < 1) {
      Toast({ text: "Please select an Office" });
    } else if (!accountGl || accountGl.length < 1) {
      Toast({ text: "Please select a GL Account" });
    } else if (!transactionDate) {
      Toast({ text: "Please select a transaction Date" });
    } else {
      dispatch(sendUserSettlement(data));
    }
  };

  const handleSwitchView = (viewNumber) => {
    setCurrentView(viewNumber);
  };


  return (
    <div className="container-fluid">
      <div className="row justify-content-between">
        <div className="col-sm-12 col-md-6 pb-3">
          <h3 className="page-title">End of Year</h3>
          {eoyGlAccountsBalances.length > 0 && currentView === 2 ? (
            <p className="sub-title">
              {" "}
              <span
                style={{ color: "blue", cursor: "pointer" }}
                onClick={handleSwitchView(1)}
              >
                Process EOY /
              </span>{" "}
              End of Year GL's settlement
            </p>
          ) : (
            <p className="sub-title">End of Year GL's settlement</p>
          )}
          {/* {eoyGlAccountsBalances && (
           
          )} */}
          {/* <p className="sub-title">End of Year GL's settlement</p> */}
        </div>
        <div className="col-sm-12 col-md-6 px-0 pt-3 d-flex justify-content-end">
          <ActionButtonsGroup>
            <div className="col-sm-12 col-md-12 p-0 d-flex">
              <ProcessActionButton
                className="form-control general-font-size"
                onClick={() => processToggleModal(true)}
              >
                Process EOY
              </ProcessActionButton>
            </div>
          </ActionButtonsGroup>
        </div>
      </div>
      {currentView === 1 && (
        <ContentWrapperNull>
          <NodataText>
            You have not processed the EOY GL’s settlement yet
          </NodataText>
        </ContentWrapperNull>
      )}
      {/* )} */}

      <ModalContainer
        show={processModalState}
        size={`md`}
        dialogClassName="document_infoModal"
      >
        <ProcessEOYModal
          onCancel={() => processToggleModal(false)}
          // onClear={() => {
          //   setDisplayAudits(audits);s
          //   dispatch(getAllAudit());
          // }}
          offices={offices}
          glAccounts={glAccounts}
          office={office}
          setOffice={setOffice}
          transactionDate={transactionDate}
          setTrasactionDate={setTrasactionDate}
          accountGl={accountGl}
          setAccountGl={setAccountGl}
          getGlBalances={getGlBalances}
          selectedCurrencyOptions={selectedCurrencyOptions}
          currencySelected={currencySelected}
          setCurrencySelected={setCurrencySelected}
          // switchView={handleSwitchView}
        />
      </ModalContainer>
    </div>
  );
};

export default SettlementView;
