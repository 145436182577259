import React from "react";
import { Input } from "@chakra-ui/react";
import { Table } from "react-bootstrap";
import DashboardLayout from "../../../../layouts/DashboardLayout/index.jsx";
import { ContentWrapper, ActionButton } from "./styles";

const ImportCustomerView = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Import Customer</h3>
            <p className="sub-title">Import files</p>
            <p className="sub-title mt-2">
              <span className="text-info mr-1">Customer </span> / file input
            </p>
          </div>
        </div>
        <ContentWrapper>
          <div className="row justify-content-start pl-5 h-100">
            <div className="col-sm-12 col-md-7 h-100">
              <div className="container pb-5" style={{ marginTop: "100px" }}>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label
                    className="text-muted"
                    style={{ fontSize: "1.125rem", fontWeight: "700" }}
                  >
                    Select excel file*
                  </label>
                  <div
                    className="form-control"
                    style={{ width: "50%", height: "43px" }}
                  >
                    <input
                      type="file"
                      name="file"
                      style={{
                        width: "100%",
                        fontWeight: "700",
                        letterSpacing: "0.0em",
                      }}
                    />
                  </div>
                  <ActionButton>Upload</ActionButton>
                </div>
                <div className="mt-5">
                  <p className="text-info">
                    Please retain the value Entity/Person in the filename
                  </p>
                </div>
              </div>
            </div>
          </div>
        </ContentWrapper>
      </div>
    </>
  );
};

export default ImportCustomerView;
