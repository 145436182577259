import React, { useState } from "react";
import ModalContainer from "../../../components/ModalContainer";
import SignatureModal from "./SignatureModal";

import userAvatar from "../../../../../assets/images/icons/user-avatar.svg";

import { FormElements, AvatarContainer2, ActionButton } from "../styles";

const Document = ({ setSignature, setImgPayload, signature, imgPayload }) => {
  let imgSrc = userAvatar;

  const previewImage = (e) => {
    const preview = document.querySelector("#textImage");
    const file = e.target.files[0];
    setImgPayload(file);
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        // convert image file to base64 string
        preview.src = reader.result;
      },
      false
    );
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSignature = (e) => {
    const file = e.target.files[0];
    setSignature(file);
  };

  const [modalState, toggleModal] = useState(false);
  const [modalContentName, setModalContentName] = useState("");

  const currentContent = (e) => {
    const val = e.currentTarget.id.trim();

    if (val) {
      toggleModal(true);
      setModalContentName(val);
    }
  };

  const handleModalClose = () => toggleModal(false);

  const displayModalContent = () => {
    switch (modalContentName) {
      case "addSignature":
        return (
          <SignatureModal
            handleSignature={handleSignature}
            onHide={handleModalClose}
            signature={signature}
          />
        );

      default:
        return;
    }
  };
  return (
    <>
      <ModalContainer show={modalState}>
        {modalState && displayModalContent()}
      </ModalContainer>
      <div className="w-6">
        <FormElements>
          <div className="row justify-content-center">
            <div className="d-flex flex-column">
              <AvatarContainer2>
                <div
                  style={{
                    width: "250px",
                    height: "250px",
                    position: "relative",
                    marginBottom: "0",
                  }}
                >
                  <img
                    src={imgSrc}
                    id="textImage"
                    alt="CustomerImg"
                    style={{
                      width: "90%",
                      height: "90%",
                      cursor: "pointer",
                      borderRadius: "100%",
                    }}
                  />
                  <input
                    type="file"
                    id="single"
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      top: "0",
                      left: "0",
                      opacity: "0",
                      cursor: "pointer",
                    }}
                    onChange={previewImage}
                    onDrop={previewImage}
                    onDragOver={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  />
                </div>
                <ActionButton color="#2C1DFF" className="mt-2">
                  <span>Upload Image</span>
                  <input
                    type="file"
                    id="single"
                    name="image"
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      top: "0",
                      left: "0",
                      opacity: "0",
                      cursor: "pointer",
                    }}
                    onChange={previewImage}
                  />
                </ActionButton>

                <ActionButton
                  color="#2C1DFF"
                  className="mt-2"
                  id="addSignature"
                  onClick={currentContent}
                >
                  <span>Upload Signature</span>
                  {/* <input
                    type="file"
                    id="single"
                    name="image"
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      top: "0",
                      left: "0",
                      opacity: "0",
                      cursor: "pointer",
                    }}
                    onChange={handleSignature}
                  /> */}
                </ActionButton>
                {!!signature ? <p>{signature.name}</p> : ""}
                {/* <ActionButton color="#01B1FC" className="mt-2">
                  Upload Signature
                </ActionButton> */}
              </AvatarContainer2>
            </div>
          </div>
        </FormElements>
      </div>
    </>
  );
};

export default Document;
