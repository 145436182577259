import React, { memo, useState } from "react";
import { useMeasure, usePrevious } from "./helpers";
import {
  Global,
  Frame,
  Title,
  Content,
  toggle,
  Button,
  MainCon,
} from "./styles";
import * as Icons from "./icons";
import InnerTree from "./InnerTree";
import { ContentInner } from "./innerTreeStyles";

const Tree = memo(
  ({ children, name, style, defaultOpen = false, id, lineWidth }) => {
    const [isOpen, setOpen] = useState(defaultOpen);
    const previous = usePrevious(isOpen);
    const [bind, { height: viewHeight }] = useMeasure();

    const Icon =
      Icons[`${children ? (isOpen ? "Minus" : "Plus") : "Close"}SquareO`];
    return (
      <Frame>
        <div className="d-flex mr-4 con align-items-center mt-3 ml-3">
          <div className="position-relative">
            <span className="v-line" />
            <Icon
              style={{
                ...toggle,
                opacity: children ? 1 : 0.3,
                fill: isOpen ? "#FF0000" : "#2C1DFF",
              }}
            />
            {id === 1 && isOpen && <span className="v-line-b" />}
            {isOpen && children && id !== 1 && <span className="v-line-bb" />}
          </div>
          <span className="span" />
          <Button
            id={id}
            disabled={!children}
            onClick={() => (children ? setOpen(!isOpen) : null)}
          >
            <Title style={style}>{name}</Title>
            {children && (
              <img
                src={
                  require(`../../assets/images/icons/arrow-${
                    isOpen
                      ? id === 1
                        ? "up"
                        : "upb"
                      : id === 1
                      ? "down2"
                      : "downb"
                  }.svg`).default
                }
                alt=""
              />
            )}
          </Button>
        </div>
        {isOpen &&
          (id === 1 ? (
            <Content
              style={{
                opacity: 1,
                height:
                  isOpen && previous === isOpen
                    ? "auto"
                    : isOpen
                    ? viewHeight
                    : 0,
              }}
              className="position-relative"
            >
              {id === 1 && (
                <span
                  style={{ width: lineWidth ? lineWidth : "41.9rem" }}
                  className="line"
                ></span>
              )}

              <div className="mt-5" {...bind} children={children} />
            </Content>
          ) : (
            <ContentInner
              style={{
                opacity: 1,
                //   height:
                //     isOpen && previous === isOpen
                //       ? "auto"
                //       : isOpen
                //       ? viewHeight
                //       : 0,
              }}
            >
              <div className="mt-1" {...bind} children={children} />
            </ContentInner>
          ))}
      </Frame>
    );
  }
);

const TreeView = ({ children }) => {
  return (
    <MainCon>
      <Global />
      {children}
    </MainCon>
    //Sample
    //always pass id = 1 to the first Parent Tree comp.
    /* <TreeView>
    <Tree id={1} name="Account types">
              <Tree name="Credit" />
              <Tree name="Savings" />
              <Tree name="Loan">
                <InnerTree name="sub child 1" style={{ color: "#37ceff" }} />
                <InnerTree name="sub child 2" style={{ color: "#37ceff" }} />
                <InnerTree name="sub child 3" style={{ color: "#37ceff" }} />
              </Tree>
              <Tree name="Assets" />
              <Tree name={<span>🙀 something</span>} />
            </Tree> 
    </TreeView>
            */
  );
};

export default TreeView;
export { Tree, InnerTree };
