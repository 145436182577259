import {
  getAllCurrencies,
  getAllOffices,
  getAllPaymentTypes,
  getGlAccounts,
  addNewJournalEntry,
  getAllJournalEntry,
  getAllFinancialActivity,
  getAllFinancialActivityTemplate,
  addNewFinancialActivity,
  editFinancialActivity,
  deleteFinancialActivity,
  getChartsOfAccount,
  getAChartOfAccount,
  getChartsOfAccountTemplate,
  addChartsOfAccount,
  editChartsOfAccount,
  deleteChartsOfAccount,
  disableEnableAChartsOfAccount,
  getAccountClosures,
  createAccountClosures,
  updateAccountClosures,
  deleteAccountClosures,
  getAccountRules,
  getAccountRulesTemplate,
  createAccountRules,
  updateAccountRule,
  deleteAccountRule,
  getOneJournalEntry,
  reverseJournalEntry,
  getAmortizations,
  createAmortization,
  getAllSavingsAccounts,
  createAnAccountToJournalEntry,
  deleteAccountingRule,
  editAccountongRule,
  getGlAccountsBalanceEOY,
} from "../services/Api/accounting.api.services";
import {
  SET_CURRIENCIES,
  // SET_ACCOUNTING_ERROR,
  SET_OFFICES,
  SET_PAYMENT_TYPES,
  ADD_JOURNAL_ENRIES,
  SET_JOURNAL_ENRIES,
  ONE_JOURNAL_ENTRY,
  REVERSE_JOURNAL_ENTRY,
  REVERSE_JOURNAL_ENTRY_RESET,
  ADD_JOURNAL_ENRIES_RESET,
  SET_FIANANCIAL_ACTIVITY_MAPPINGS,
  ADD_FIANANCIAL_ACTIVITY_MAPPINGS,
  SET_LOADING_STATE,
  EDIT_FIANANCIAL_ACTIVITY_MAPPINGS,
  SET_CHARTS_OF_ACCOUNT,
  GET_A_CHART_OF_ACCOUNT,
  FILTER_CHARTS_OF_ACCOUNT,
  CLEAR_FILTER_CHARTS_OF_ACCOUNT,
  SET_CHARTS_OF_ACCOUNT_TEMPLATE,
  SET_ACCOUNT_CLOSURE,
  SET_ACCOUNT_RULES,
  SET_ACCOUNT_RULES_TEMPLATE,
  SET_GL_ACCOUNTS,
  SET_FIANANCIAL_ACTIVITY_TEMPLATE,
  SET_CHARTS_OF_ACCOUNT_PARENT,
  SET_AMORTIZATION,
  SET_SAVINGS_ACCOUNT,
  SET_FREQUENT_POSTING,
  GET_GL_BALANCES_REQUEST,
  GET_GL_BALANCES_SUCCESS,
  GET_GL_BALANCES_FAIL,
} from "../constants/accountingConstants";

import Toast from "../util/Toast";
// import axiosData from "../axiosData";

const handleError = (ex, dispatch) => {
  if (ex.response) {
    dispatch(
      Toast({
        text: ex.response.data.errors
          ? ex.response.data.errors[0].defaultUserMessage
          : ex.response.data.defaultUserMessage,
        icon: "error",
      })
    );
  } else if (ex.request) {
    dispatch(Toast({ text: String(ex), icon: "error" }));
  } else {
    dispatch(Toast({ text: String(ex), icon: "error" }));
  }

  dispatch({ type: SET_LOADING_STATE, payload: false });
};

const getCurriencies = () => {
  return async (dispatch) => {
    dispatch({ type: SET_CURRIENCIES });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getAllCurrencies()
      .then((response) => {
        dispatch({
          type: SET_CURRIENCIES,
          payload: response.selectedCurrencyOptions,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
const getOffices = () => {
  return async (dispatch) => {
    dispatch({ type: SET_OFFICES });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getAllOffices()
      .then((response) => {
        dispatch({
          type: SET_OFFICES,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
const getPaymentTypes = () => {
  return async (dispatch) => {
    dispatch({ type: SET_PAYMENT_TYPES });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getAllPaymentTypes()
      .then((response) => {
        dispatch({
          type: SET_PAYMENT_TYPES,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
const getAllGlAccounts = (type) => {
  return async (dispatch) => {
    dispatch({ type: SET_GL_ACCOUNTS });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getGlAccounts(type)
      .then((response) => {
        dispatch({
          type: SET_GL_ACCOUNTS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const getAllGlAccountsBalances = (
  date,
  history,
  office,
  accountGl,
  transactionDate,
  currency
) => {
  return async (dispatch) => {
    dispatch({ type: GET_GL_BALANCES_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getGlAccountsBalanceEOY(date , currency)
      .then((response) => {
        dispatch({
          type: GET_GL_BALANCES_SUCCESS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
        history.push("/core/settlement/completeEoy", {
          office,
          accountGl,
          transactionDate,
          currency
        });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const getAllAmortization = () => {
  return async (dispatch) => {
    dispatch({ type: SET_AMORTIZATION });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getAmortizations()
      .then((response) => {
        dispatch({
          type: SET_AMORTIZATION,
          payload: response.data,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const addJournalEntry = (journalEntry, dispatch, history) => {
  // return async (dispatch) => {
  dispatch({ type: ADD_JOURNAL_ENRIES });
  dispatch({ type: SET_LOADING_STATE, payload: true });
  // await addNewJournalEntry(journalEntry)
  addNewJournalEntry(journalEntry)
    .then((response) => {
      if (response) {
        dispatch({
          type: ADD_JOURNAL_ENRIES,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(
          Toast({ text: "Succesfully Added Journal Entry", icon: "success" })
        );

        history.push(`/core/journal-entries/details/${response.transactionId}`);
      }
    })
    .catch((ex) => {
      handleError(ex, dispatch);
    });
  // };
};

const createAnAmortization = (dataToSend, dispatch, history) => {
  dispatch({ type: SET_LOADING_STATE, payload: true });
  createAmortization(dataToSend)
    .then((response) => {
      dispatch({ type: SET_LOADING_STATE, payload: false });
      dispatch(
        Toast({ text: "Succesfully Added Amortization", icon: "success" })
      );
      history.push(`/core/amortizations`);
    })
    .catch((ex) => {
      handleError(ex, dispatch);
    });
};
const createAnPlacement = (journalEntry, dataToSend, dispatch, history) => {
  dispatch({ type: SET_LOADING_STATE, payload: true });
  addNewJournalEntry(journalEntry)
    .then((response) => {
      createAmortization(dataToSend)
        .then((response) => {
          dispatch({ type: SET_LOADING_STATE, payload: false });
          dispatch(
            Toast({ text: "Succesfully Added Placement", icon: "success" })
          );
          history.push(`/core/accounting/placement`);
        })
        .catch((ex) => {
          handleError(ex, dispatch);
        });
    })
    .catch((ex) => {
      handleError(ex, dispatch);
    });
};
const createFrequentPosting = (posting, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    addNewJournalEntry(posting)
      .then((response) => {
        dispatch({
          type: SET_FREQUENT_POSTING,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(
          Toast({
            text: "Succesfully created frequent posting",
            icon: "success",
          })
        );
        history.push(`/core/accounting/frequent-posting`);
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const getJournalEntry = (
  office,
  accNameOrCode,
  entry,
  searchDayFrom,
  searchDayTo,
  transactionId,
  referenceNumber,
  selectedUser,
  offset
) => {
  return async (dispatch) => {
    dispatch({ type: SET_JOURNAL_ENRIES });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getAllJournalEntry(
      office,
      accNameOrCode,
      entry,
      searchDayFrom,
      searchDayTo,
      transactionId,
      referenceNumber,
      selectedUser,
      offset
    )
      .then((response) => {
        dispatch({
          type: SET_JOURNAL_ENRIES,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
const getAJournalEntry = (transactionId) => {
  return async (dispatch) => {
    dispatch({ type: ONE_JOURNAL_ENTRY });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getOneJournalEntry(transactionId)
      .then((response) => {
        dispatch({
          type: ONE_JOURNAL_ENTRY,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
const reverseAJournalEntry = (
  transactionId,
  dataToSend,
  history,
  refreshPageData
) => {
  return async (dispatch) => {
    dispatch({ type: REVERSE_JOURNAL_ENTRY });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await reverseJournalEntry(transactionId, dataToSend)
      .then((response) => {
        dispatch({
          type: REVERSE_JOURNAL_ENTRY,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
        refreshPageData();
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const reverseAJournalEntryReset = () => {
  return (dispatch) => {
    dispatch({
      type: REVERSE_JOURNAL_ENTRY_RESET,
      payload: {},
    });
  };
};

const addJournalEntryReset = () => {
  return (dispatch) => {
    dispatch({
      type: ADD_JOURNAL_ENRIES_RESET,
      payload: {},
    });
  };
};

const getFinancialActivityMappings = () => {
  return async (dispatch) => {
    dispatch({ type: SET_FIANANCIAL_ACTIVITY_MAPPINGS });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getAllFinancialActivity()
      .then((response) => {
        dispatch({
          type: SET_FIANANCIAL_ACTIVITY_MAPPINGS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
const getFinancialActivityTemplate = () => {
  return async (dispatch) => {
    dispatch({ type: SET_FIANANCIAL_ACTIVITY_TEMPLATE });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getAllFinancialActivityTemplate()
      .then((response) => {
        dispatch({
          type: SET_FIANANCIAL_ACTIVITY_TEMPLATE,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
const addFinancialActivityMappings = (data, history) => {
  return async (dispatch) => {
    dispatch({ type: ADD_FIANANCIAL_ACTIVITY_MAPPINGS });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await addNewFinancialActivity(data)
      .then((response) => {
        dispatch({
          type: ADD_FIANANCIAL_ACTIVITY_MAPPINGS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
        history.push("/core/accounting/financial-activities");
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
const editFinancialActivityMappings = (data, id, history) => {
  return async (dispatch) => {
    dispatch({ type: EDIT_FIANANCIAL_ACTIVITY_MAPPINGS });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await editFinancialActivity(data, id)
      .then((response) => {
        dispatch({
          type: EDIT_FIANANCIAL_ACTIVITY_MAPPINGS,
          payload: response,
        });
        dispatch(
          Toast({
            text: "Financial Activity has been updated",
            icon: "success",
          })
        );
        dispatch({ type: SET_LOADING_STATE, payload: false });
        history.push("/core/accounting/financial-activities");
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const deleteFinancialActivityMappings = (id, dispatch, history) => {
  dispatch({ type: SET_LOADING_STATE, payload: true });
  deleteFinancialActivity(id)
    .then((response) => {
      if (response) {
        dispatch(
          Toast({
            text: "Financial Activity has been deleted",
            icon: "success",
          })
        );
        dispatch({ type: SET_LOADING_STATE, payload: false });
        history.push("/core/accounting/financial-activities");
      }
    })
    .catch((ex) => {
      handleError(ex, dispatch);
    });
};

// FILTER Charts of Account
const filterAccountingData = (filterParam) => {
  return (dispatch) => {
    dispatch({ type: FILTER_CHARTS_OF_ACCOUNT, payload: filterParam });
  };
};

//RESET SINGLE Charts of Account STATE
const clearFilteredAccountingData = (param) => {
  return (dispatch) => {
    dispatch({ type: CLEAR_FILTER_CHARTS_OF_ACCOUNT, payload: param });
  };
};
//R

// CHARTS OF ACCOUNT
const getAllChartsOfAccount = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: SET_CHARTS_OF_ACCOUNT });
    await getChartsOfAccount()
      .then((response) => {
        if (response) {
          dispatch({ type: SET_CHARTS_OF_ACCOUNT, payload: response });
          dispatch({ type: SET_LOADING_STATE, payload: false });
        }
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
const getAChartsOfAccount = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: GET_A_CHART_OF_ACCOUNT });
    await getAChartOfAccount(id)
      .then((response) => {
        if (response) {
          dispatch({ type: GET_A_CHART_OF_ACCOUNT, payload: response });
          dispatch({ type: SET_LOADING_STATE, payload: false });
        }
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
const getAllChartsOfAccountTemplate = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: SET_CHARTS_OF_ACCOUNT_TEMPLATE });
    await getChartsOfAccountTemplate()
      .then((response) => {
        if (response) {
          dispatch({ type: SET_CHARTS_OF_ACCOUNT_TEMPLATE, payload: response });
          dispatch({ type: SET_LOADING_STATE, payload: false });
        }
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const addNewChartsOfAccount = (data, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await addChartsOfAccount(data)
      .then((response) => {
        if (response) {
          dispatch({ type: SET_LOADING_STATE, payload: false });
          dispatch(
            Toast({
              text: "Chart Of Account Succesfully Added",
              icon: "success",
            })
          );
          
          history.push("/core/charts-of-account");
        }
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
const updateChartsOfAccount = (data, id, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await editChartsOfAccount(data, id)
      .then((response) => {
        if (response) {
          dispatch({ type: SET_LOADING_STATE, payload: false });
          dispatch(
            Toast({
              text: "Chart Of Account Succesfully Updated",
              icon: "success",
            })
          );
          history.push("/core/charts-of-account");
        }
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
const deleteAChartsOfAccount = (id, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await deleteChartsOfAccount(id)
      .then((response) => {
        if (response) {
          dispatch({ type: SET_LOADING_STATE, payload: false });
          dispatch(
            Toast({
              text: "Chart Of Account Succesfully Deleted",
              icon: "success",
            })
          );
          history.push("/core/charts-of-account");
        }
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
const updateAccountongRule = (data, id, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await editAccountongRule(data, id)
      .then((response) => {
        if (response) {
          dispatch({ type: SET_LOADING_STATE, payload: false });
          dispatch(
            Toast({
              text: "Accounting Rule Succesfully Updated",
              icon: "success",
            })
          );
          history.push("/core/accounting/rules");
        }
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
const deleteAccountongRule = (id, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await deleteAccountingRule(id)
      .then((response) => {
        if (response) {
          dispatch({ type: SET_LOADING_STATE, payload: false });
          dispatch(
            Toast({
              text: "Accounting Rule Succesfully Deleted",
              icon: "success",
            })
          );
          history.push("/core/accounting/rules");
        }
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const disableOrEnableAChartsOfAccount = (status, id, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await disableEnableAChartsOfAccount(status, id)
      .then((response) => {
        if (response) {
          dispatch({ type: SET_LOADING_STATE, payload: false });
          dispatch(
            Toast({
              text: "Chart Of Account Succesfully Updated",
              icon: "success",
            })
          );
          history.push("/core/charts-of-account");
        }
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

// ACCOUNT CLOSURE
const getAllAccountClosures = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: SET_ACCOUNT_CLOSURE });
    await getAccountClosures()
      .then((response) => {
        if (response) {
          dispatch({ type: SET_ACCOUNT_CLOSURE, payload: response });
          dispatch({ type: SET_LOADING_STATE, payload: false });
        }
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
const createANewAccountClosures = (accountClosure, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await createAccountClosures(accountClosure)
      .then((response) => {
        if (response) {
          dispatch({ type: SET_LOADING_STATE, payload: false });
          dispatch(
            Toast({
              text: "Account Closure Succesfully Created",
              icon: "success",
            })
          );
          history.push("/core/accounting/closing-entries");
        }
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
const updateAnAccountClosures = (accountClosure, id, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await updateAccountClosures(accountClosure, id)
      .then((response) => {
        if (response) {
          dispatch({ type: SET_LOADING_STATE, payload: false });
          dispatch(
            Toast({
              text: "Account Closure Succesfully Updated",
              icon: "success",
            })
          );
        }
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
const deleteAnAccountClosures = (id, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await deleteAccountClosures(id)
      .then((response) => {
        if (response) {
          dispatch({ type: SET_LOADING_STATE, payload: false });
          dispatch(
            Toast({
              text: "Account Closure Succesfully Updated",
              icon: "success",
            })
          );
          history.push("/core/accounting/closing-entries");
        }
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

// ACCOUNT RULES
const getAllAccountRules = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: SET_ACCOUNT_RULES });
    await getAccountRules()
      .then((response) => {
        if (response) {
          dispatch({ type: SET_ACCOUNT_RULES, payload: response });
          dispatch({ type: SET_LOADING_STATE, payload: false });
        }
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
const getAllAccountRulesTemplate = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: SET_ACCOUNT_RULES_TEMPLATE });
    await getAccountRulesTemplate()
      .then((response) => {
        if (response) {
          dispatch({ type: SET_ACCOUNT_RULES_TEMPLATE, payload: response });
          dispatch({ type: SET_LOADING_STATE, payload: false });
        }
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
const createANewAccountRule = (accountRule, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await createAccountRules(accountRule)
      .then((response) => {
        if (response) {
          dispatch({ type: SET_LOADING_STATE, payload: false });
          dispatch(
            Toast({ text: "Account Rule Succesfully Created", icon: "success" })
          );
          history.push("/core/accounting/rules");
        }
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
const updateAnAccountRule = (accountRule, id, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await updateAccountRule(accountRule, id)
      .then((response) => {
        if (response) {
          dispatch({ type: SET_LOADING_STATE, payload: false });
          dispatch(
            Toast({ text: "Account Rule Succesfully Updated", icon: "success" })
          );
          history.push("/accounting/rules");
        }
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
const deleteAnAccountRule = (id, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await deleteAccountRule(id)
      .then((response) => {
        if (response) {
          dispatch({ type: SET_LOADING_STATE, payload: false });
          dispatch(
            Toast({ text: "Account Rule Succesfully Updated", icon: "success" })
          );
          history.push("/accounting/rules");
        }
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const getSavingsAccounts = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: SET_SAVINGS_ACCOUNT });
    await getAllSavingsAccounts()
      .then((response) => {
        if (response) {
          dispatch({ type: SET_LOADING_STATE, payload: false });
          dispatch({ type: SET_SAVINGS_ACCOUNT, payload: response.pageItems });
        }
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const createAccountToJournalEntry = (dataToSend, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await createAnAccountToJournalEntry(dataToSend)
      .then((response) => {
        if (response) {
          dispatch({ type: SET_LOADING_STATE, payload: false });
          if (response.commandId) {
            dispatch(Toast({ text: response.message, icon: "info" }));
            history.push("/core/accounting/account-to-journal-entry");
          } else {
            dispatch(Toast({
              text: "Succesfully Added Account To Journal Entry",
              icon: "success",
            }));
            history.push(`/core/accounting/account-to-journal-entry/details/${response.transactionId}`);
          }
        }
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const setChartOfAccountParent = (dispatch, parentToSet) => {
  dispatch({ type: SET_CHARTS_OF_ACCOUNT_PARENT, payload: parentToSet });
};

export {
  getCurriencies,
  getOffices,
  getJournalEntry,
  getAJournalEntry,
  getAllGlAccounts,
  getAllGlAccountsBalances,
  getPaymentTypes,
  addJournalEntry,
  reverseAJournalEntry,
  reverseAJournalEntryReset,
  addJournalEntryReset,
  getFinancialActivityMappings,
  getFinancialActivityTemplate,
  addFinancialActivityMappings,
  editFinancialActivityMappings,
  deleteFinancialActivityMappings,
  getAllChartsOfAccount,
  filterAccountingData,
  clearFilteredAccountingData,
  getAChartsOfAccount,
  getAllChartsOfAccountTemplate,
  addNewChartsOfAccount,
  updateChartsOfAccount,
  deleteAChartsOfAccount,
  deleteAccountongRule,
  disableOrEnableAChartsOfAccount,
  getAllAccountClosures,
  createANewAccountClosures,
  updateAnAccountClosures,
  deleteAnAccountClosures,
  getAllAccountRules,
  getAllAccountRulesTemplate,
  createANewAccountRule,
  updateAccountongRule,
  updateAnAccountRule,
  deleteAnAccountRule,
  setChartOfAccountParent,
  getAllAmortization,
  createAnAmortization,
  createAnPlacement,
  getSavingsAccounts,
  createAccountToJournalEntry,
  createFrequentPosting,
};
