const TableWrapper = ({ children, showTitle, title }) => (
	<>
		{showTitle && (
			<div className="entityDetails">
				<h3>{title ?? 'Entity Details'}</h3>
			</div>
		)}
		<div
			style={{
				marginBottom: "25px",
				display: "grid",
				gap: "2rem",
				gridTemplateColumns: "1fr  1fr",
				alignItems: "start",
				border: "1px solid #DADCE5",
				padding: "0.5rem",
				overflow: "auto",
			}}
		>
			{children}
		</div>
	</>
)

export default TableWrapper