import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Table, Modal } from "react-bootstrap";
import ModalContainer from "../../../../components/ModalContainer";
import { ContentWrapper, FormTitle } from "../styles";
import { getFixedDepositProduct } from "../../../../../../actions/fixedDeposit";
import { formatCurrency } from "../../../../../../util/FormatAmount";
import { formatDate } from "../../../../../../util/FormatDate";

const FixedDepositProductDetails = ({ match }) => {
  const dispatch = useDispatch();
  const fixedDepositData = useSelector((state) => state.fixedDepositData);

  const { fixedDepositAccountTemplate = {} } = fixedDepositData || {};

  useEffect(() => {
    dispatch(getFixedDepositProduct(match.params.id));
  }, [match.params.id]);

  const isBoolean = (variable) => {
    if (typeof variable === "boolean") {
      return true;
    }
    return false;
  };

  const [modalState, toggleModal] = useState(false);
  const [currentIncentives, setCurrentIncentives] = useState([]);

  const incentiveModal = (currentIncentives) => {
    return (
      <>
        <Modal.Header>
          <Modal.Title>Incentives</Modal.Title>
        </Modal.Header>
        <Modal.Body className="wc-modal">
          <h2 className="my-3" style={{ fontSize: "20px" }}>
            Customer Attributes
          </h2>
          <div className="d-flex align-items-center justify-content-center flex-direction-coloumn">
            <Table className="child-table">
              <thead className="">
                <tr style={{ backgroundColor: "#FAFAFA" }}>
                  <th>Attribute</th>
                  <th>Condition</th>
                  <th>Value</th>
                  <th>Type</th>
                  <th>Interest</th>
                </tr>
              </thead>
              <tbody>
                {currentIncentives.map(
                  (
                    {
                      attributeName,
                      amount,
                      attributeValue,
                      attributeValueDesc,
                      conditionType,
                      incentiveType,
                    },
                    index
                  ) => {
                    return (
                      <tr key={index}>
                        <td>{attributeName && attributeName.value}</td>
                        <td>{conditionType && conditionType.value}</td>
                        <td>
                          {!!attributeValueDesc ? attributeValueDesc : ""}
                        </td>
                        <td>{incentiveType && incentiveType.value}</td>
                        <td>{amount}</td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex mt-4">
            <div className="btn-small" onClick={() => toggleModal(false)}>
              Close
            </div>
          </div>
        </Modal.Footer>
      </>
    );
  };

  return (
    <>
      <ModalContainer show={modalState}>
        {modalState && incentiveModal(currentIncentives)}
      </ModalContainer>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">
              Fixed deposit products&nbsp;&nbsp;&nbsp;
            </h3>
            <p className="font-weight sub-title mt-2">
              <span className="text-info mr-1">
                {/* <Link to="/core/configuration">Configuration</Link> /{" "} */}
                <Link to="/core/products">Products</Link> /{" "}
              </span>
              <span className="text-info mr-1">
                <Link to="/core/products/fixed-deposit-products">
                  Fixed deposit products
                </Link>
              </span>
              /{" "}
              {!!fixedDepositAccountTemplate &&
              !!fixedDepositAccountTemplate.name
                ? fixedDepositAccountTemplate.name
                : ""}{" "}
              Details
            </p>
          </div>
        </div>
        <ContentWrapper>
          <div className="col-sm-12 col-md-12 d-flex py-5">
            <div className="container">
              {/* Details */}
              <div>
                <FormTitle>
                  <p className="text-info">Details</p>
                  <div className="lineThrough"></div>
                </FormTitle>
                <div className="pt-2">
                  <div
                    className="form-group d-flex  align-items-center text-label"
                    style={{
                      marginBottom: "1rem !important",
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      Description &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!fixedDepositAccountTemplate &&
                      !!fixedDepositAccountTemplate.description
                        ? fixedDepositAccountTemplate.description
                        : ""}
                    </p>
                  </div>
                  <div
                    className="form-group d-flex  align-items-center text-label"
                    style={{
                      marginBottom: "1rem !important",
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      Short name &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!fixedDepositAccountTemplate &&
                      !!fixedDepositAccountTemplate.shortName
                        ? fixedDepositAccountTemplate.shortName
                        : ""}
                    </p>
                  </div>
                </div>
              </div>
              {/* // Terms */}
              <div className="pt-1">
                <FormTitle>
                  <p className="text-info">Terms</p>
                  <div className="lineThrough"></div>
                </FormTitle>
                <div className="pt-2">
                  <div
                    style={{
                      marginBottom: "1rem !important",
                    }}
                    className="form-group d-flex  align-items-center text-label"
                  >
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      Currency &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!fixedDepositAccountTemplate &&
                      !!fixedDepositAccountTemplate.currency &&
                      !!fixedDepositAccountTemplate.currency.displayLabel
                        ? fixedDepositAccountTemplate.currency.displayLabel
                        : ""}
                    </p>
                  </div>
                  <div
                    style={{
                      marginBottom: "1rem !important",
                    }}
                    className="form-group d-flex  align-items-center text-label"
                  >
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      Decimal Places &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!fixedDepositAccountTemplate &&
                      !!fixedDepositAccountTemplate.currency &&
                      !!fixedDepositAccountTemplate.currency.decimalPlaces
                        ? fixedDepositAccountTemplate.currency.decimalPlaces
                        : ""}
                    </p>
                  </div>
                  <div
                    style={{
                      marginBottom: "1rem !important",
                    }}
                    className="form-group d-flex  align-items-center text-label"
                  >
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      Currency In Multiples Of &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {" "}
                      {!!fixedDepositAccountTemplate &&
                      !!fixedDepositAccountTemplate.currency &&
                      !!fixedDepositAccountTemplate.currency.inMultiplesOf
                        ? fixedDepositAccountTemplate.currency.inMultiplesOf
                        : ""}
                    </p>
                  </div>{" "}
                  <div
                    style={{
                      marginBottom: "1rem !important",
                    }}
                    className="form-group d-flex  align-items-center text-label"
                  >
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      Minimum Deposit Amount &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {" "}
                      {!!fixedDepositAccountTemplate &&
                        fixedDepositAccountTemplate.minDepositAmount &&
                        formatCurrency(
                          Number(fixedDepositAccountTemplate.minDepositAmount)
                        )}
                    </p>
                  </div>{" "}
                  <div
                    style={{
                      marginBottom: "1rem !important",
                    }}
                    className="form-group d-flex  align-items-center text-label"
                  >
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      Default Deposit Amount &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {" "}
                      {!!fixedDepositAccountTemplate &&
                        fixedDepositAccountTemplate.depositAmount &&
                        formatCurrency(
                          Number(fixedDepositAccountTemplate.depositAmount)
                        )}
                    </p>
                  </div>{" "}
                  <div
                    style={{
                      marginBottom: "1rem !important",
                    }}
                    className="form-group d-flex  align-items-center text-label"
                  >
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      Maximum Deposit Amount &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {" "}
                      {!!fixedDepositAccountTemplate &&
                        fixedDepositAccountTemplate.maxDepositAmount &&
                        formatCurrency(
                          Number(fixedDepositAccountTemplate.maxDepositAmount)
                        )}
                    </p>
                  </div>
                  <div
                    style={{
                      marginBottom: "1rem !important",
                    }}
                    className="form-group d-flex  align-items-center text-label"
                  >
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      {" "}
                      Interest Compounding Period &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!fixedDepositAccountTemplate &&
                      !!fixedDepositAccountTemplate.interestCompoundingPeriodType &&
                      !!fixedDepositAccountTemplate
                        .interestCompoundingPeriodType.value
                        ? fixedDepositAccountTemplate
                            .interestCompoundingPeriodType.value
                        : ""}
                    </p>
                  </div>
                  <div
                    style={{
                      marginBottom: "1rem !important",
                    }}
                    className="form-group d-flex  align-items-center text-label"
                  >
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      Interest Posting Period &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!fixedDepositAccountTemplate &&
                      !!fixedDepositAccountTemplate.interestPostingPeriodType &&
                      !!fixedDepositAccountTemplate.interestPostingPeriodType
                        .value
                        ? fixedDepositAccountTemplate.interestPostingPeriodType
                            .value
                        : ""}
                    </p>
                  </div>
                  <div
                    style={{
                      marginBottom: "1rem !important",
                    }}
                    className="form-group d-flex  align-items-center text-label"
                  >
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      Interest Calculated Using &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!fixedDepositAccountTemplate &&
                      !!fixedDepositAccountTemplate.interestCalculationType &&
                      !!fixedDepositAccountTemplate.interestCalculationType
                        .value
                        ? fixedDepositAccountTemplate.interestCalculationType
                            .value
                        : ""}
                    </p>
                  </div>
                  <div
                    style={{
                      marginBottom: "1rem !important",
                    }}
                    className="form-group d-flex  align-items-center text-label"
                  >
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      Days In Years &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!fixedDepositAccountTemplate &&
                      !!fixedDepositAccountTemplate.interestCalculationDaysInYearType &&
                      !!fixedDepositAccountTemplate
                        .interestCalculationDaysInYearType.value
                        ? fixedDepositAccountTemplate
                            .interestCalculationDaysInYearType.value
                        : ""}
                    </p>
                  </div>
                </div>
              </div>
              {/* Settings */}
              <div className="pt-1">
                <FormTitle>
                  <p className="text-info">Settings</p>
                  <div className="lineThrough"></div>
                </FormTitle>
                <div className="pt-2">
                  <div
                    style={{
                      marginBottom: "1rem !important",
                    }}
                    className="form-group d-flex  align-items-center text-label"
                  >
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      Lock-In Period &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!fixedDepositAccountTemplate &&
                        fixedDepositAccountTemplate.lockinPeriodFrequency}{" "}
                      {!!fixedDepositAccountTemplate &&
                        fixedDepositAccountTemplate.lockinPeriodFrequencyType &&
                        fixedDepositAccountTemplate.lockinPeriodFrequencyType
                          .value}
                    </p>
                  </div>
                  <div
                    style={{
                      marginBottom: "1rem !important",
                    }}
                    className="form-group d-flex align-items-center text-label"
                  >
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      Minimum Deposit Term &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!fixedDepositAccountTemplate &&
                      fixedDepositAccountTemplate.minDepositTerm
                        ? `${fixedDepositAccountTemplate.minDepositTerm}`
                        : ""}{" "}
                      {!!fixedDepositAccountTemplate &&
                        fixedDepositAccountTemplate.minDepositTermType &&
                        fixedDepositAccountTemplate.minDepositTermType.value}
                    </p>
                  </div>
                  <div
                    style={{
                      marginBottom: "1rem !important",
                    }}
                    className="form-group d-flex  align-items-center text-label"
                  >
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      Maximum Deposit Term &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!fixedDepositAccountTemplate &&
                      fixedDepositAccountTemplate.maxDepositTerm
                        ? `${fixedDepositAccountTemplate.maxDepositTerm}`
                        : ""}{" "}
                      {!!fixedDepositAccountTemplate &&
                        fixedDepositAccountTemplate.maxDepositTermType &&
                        fixedDepositAccountTemplate.maxDepositTermType.value}
                    </p>
                  </div>
                  <div
                    style={{
                      marginBottom: "1rem !important",
                    }}
                    className="form-group d-flex  align-items-center text-label"
                  >
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      Deposit Term In Multiple Of &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!fixedDepositAccountTemplate &&
                      fixedDepositAccountTemplate.inMultiplesOfDepositTerm
                        ? `${fixedDepositAccountTemplate.inMultiplesOfDepositTerm}`
                        : ""}{" "}
                      {!!fixedDepositAccountTemplate &&
                        fixedDepositAccountTemplate.inMultiplesOfDepositTermType &&
                        fixedDepositAccountTemplate.inMultiplesOfDepositTermType
                          .value}
                    </p>
                  </div>
                  <div
                    style={{
                      marginBottom: "1rem !important",
                    }}
                    className="form-group d-flex  align-items-center text-label"
                  >
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      Is Pre Closure Penal Applicable &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!fixedDepositAccountTemplate &&
                      isBoolean(fixedDepositAccountTemplate.preClosurePenalApplicable)
                        ? `${fixedDepositAccountTemplate.preClosurePenalApplicable}`
                        : ""}
                    </p>
                  </div>
                  <div
                    style={{
                      marginBottom: "1rem !important",
                    }}
                    className="form-group d-flex  align-items-center text-label"
                  >
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      Apply Penal Interest (Less) &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!fixedDepositAccountTemplate &&
                      fixedDepositAccountTemplate.preClosurePenalInterest
                        ? `${fixedDepositAccountTemplate.preClosurePenalInterest}`
                        : ""}{" "}
                      % 
                      {/* On{" "}
                      {!!fixedDepositAccountTemplate &&
                        fixedDepositAccountTemplate.preClosurePenalInterestOnType &&
                        fixedDepositAccountTemplate
                          .preClosurePenalInterestOnType.value} */}
                    </p>
                  </div>
                  <div
                    style={{
                      marginBottom: "1rem !important",
                    }}
                    className="form-group d-flex  align-items-center text-label"
                  >
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      Is Withhold Tax Applicable &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!fixedDepositAccountTemplate &&
                      isBoolean(fixedDepositAccountTemplate.withHoldTax)
                        ? `${fixedDepositAccountTemplate.withHoldTax}`
                        : ""}
                    </p>
                  </div>
                  <div
                    style={{
                      marginBottom: "1rem !important",
                    }}
                    className="form-group d-flex  align-items-center text-label"
                  >
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      Withhold Tax Group &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!fixedDepositAccountTemplate &&
                        fixedDepositAccountTemplate.taxGroup &&
                        fixedDepositAccountTemplate.taxGroup.value}
                    </p>
                  </div>
                </div>
              </div>

              {/* Interest Rate */}
              <div className="pt-1">
                <FormTitle>
                  <p className="text-info">Interest Rate</p>
                  <div className="lineThrough"></div>
                </FormTitle>
                <div className="pt-2">
                  {/* Valid From Date:  */}
                  <div
                    style={{
                      marginBottom: "1rem !important",
                    }}
                    className="form-group d-flex  align-items-center text-label"
                  >
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      {" "}
                      Valid From Date: &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {" "}
                      {!!fixedDepositAccountTemplate &&
                        !!fixedDepositAccountTemplate.activeChart &&
                        !!fixedDepositAccountTemplate.activeChart.fromDate &&
                        formatDate(
                          fixedDepositAccountTemplate.activeChart.fromDate
                        )}
                    </p>
                  </div>
                  {/* End Date: */}
                  <div
                    style={{
                      marginBottom: "1rem !important",
                    }}
                    className="form-group d-flex  align-items-center text-label"
                  >
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      End Date: &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {" "}
                      {!!fixedDepositAccountTemplate &&
                        !!fixedDepositAccountTemplate.activeChart &&
                        !!fixedDepositAccountTemplate.activeChart.endDate &&
                        formatDate(
                          fixedDepositAccountTemplate.activeChart.endDate
                        )}
                    </p>
                  </div>

                  <div
                    style={{
                      marginBottom: "1rem !important",
                    }}
                    className="form-group d-flex  align-items-center text-label"
                  >
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      Interest &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!fixedDepositAccountTemplate &&
                      fixedDepositAccountTemplate.activeChart &&
                      fixedDepositAccountTemplate.activeChart.chartSlabs &&
                      fixedDepositAccountTemplate.activeChart.chartSlabs
                        .length > 0
                        ? fixedDepositAccountTemplate.activeChart.chartSlabs.map(
                            ({
                              id,
                              amountRangeFrom,
                              amountRangeTo,
                              annualInterestRate,
                              description,
                              fromPeriod,
                              toPeriod,
                              incentives,
                              periodType,
                            }) => <>{annualInterestRate}</>
                          )
                        : null}
                    </p>
                  </div>
                  {/* <Table>
                    <thead className="">
                      <tr style={{ backgroundColor: "#EDEFF5" }}>
                        <th>Period From</th>
                        <th>Amount Range</th>
                        <th>Interest</th>
                        <th>Description</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {!!fixedDepositAccountTemplate &&
                      fixedDepositAccountTemplate.activeChart &&
                      fixedDepositAccountTemplate.activeChart.chartSlabs &&
                      fixedDepositAccountTemplate.activeChart.chartSlabs
                        .length > 0
                        ? fixedDepositAccountTemplate.activeChart.chartSlabs.map(
                            ({
                              id,
                              amountRangeFrom,
                              amountRangeTo,
                              annualInterestRate,
                              description,
                              fromPeriod,
                              toPeriod,
                              incentives,
                              periodType,
                            }) => (
                              <tr key={id}>
                                <td>
                                  {!!fromPeriod ? fromPeriod : ""} -{" "}
                                  {!!toPeriod ? toPeriod : ""}
                                </td>
                                <td>
                                  {!!amountRangeFrom ? amountRangeFrom : ""} -{" "}
                                  {!!amountRangeTo ? amountRangeTo : ""}
                                </td>
                                <td>
                                  {annualInterestRate && annualInterestRate}
                                </td>
                                <td>{description}</td>
                              </tr>
                            )
                          )
                        : null}
                    </tbody>
                  </Table> */}
                </div>
              </div>

              {/* Charges */}
              <div className="pt-1">
                <FormTitle>
                  <p className="text-info">Charges</p>
                  <div className="lineThrough"></div>
                </FormTitle>
                <div className="pt-2">
                  <Table className="child-table">
                    <thead className="">
                      <tr style={{ backgroundColor: "#FAFAFA" }}>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Amount</th>
                        <th>Collected on</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!!fixedDepositAccountTemplate &&
                      fixedDepositAccountTemplate.charges &&
                      fixedDepositAccountTemplate.charges.length > 0
                        ? fixedDepositAccountTemplate.charges.map(
                            ({
                              id,
                              name,
                              chargeCalculationType,
                              amount,
                              chargeTimeType,
                            }) => (
                              <tr key={id}>
                                <td>{name}</td>
                                <td>
                                  {chargeCalculationType &&
                                    chargeCalculationType.value}
                                </td>
                                <td>{formatCurrency(Number(amount))}</td>
                                <td>
                                  {chargeTimeType && chargeTimeType.value}
                                </td>
                              </tr>
                            )
                          )
                        : null}
                    </tbody>
                  </Table>
                </div>
              </div>
              {/* Accounting */}
              <div className="pt-1">
                <FormTitle>
                  <p className="text-info">Accounting</p>
                  <div className="lineThrough"></div>
                </FormTitle>
                <div className="pt-2">
                  <div
                    className="form-group d-flex  align-items-center text-label"
                    style={{
                      marginBottom: "1rem !important",
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                      className="col-md-4"
                    >
                      Account type &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!fixedDepositAccountTemplate &&
                        fixedDepositAccountTemplate.accountingRule &&
                        fixedDepositAccountTemplate.accountingRule.value}
                    </p>
                  </div>
                  {!!fixedDepositAccountTemplate &&
                  fixedDepositAccountTemplate.accountingRule &&
                  fixedDepositAccountTemplate.accountingRule.id === 2 ? (
                    <>
                      <div
                        className="form-group d-flex  align-items-center text-label"
                        style={{
                          marginBottom: "1rem !important",
                        }}
                      >
                        <h3
                          style={{
                            fontFamily: "AventaSemiBold",
                            marginRight: "1rem",
                          }}
                        className="col-md-4"
                        >
                          Savings Reference(Assets) &nbsp;&nbsp;&nbsp;:
                        </h3>
                        <p style={{ width: "60%", fontWeight: "bold" }}>
                          {!!fixedDepositAccountTemplate &&
                            fixedDepositAccountTemplate.accountingMappings &&
                            fixedDepositAccountTemplate.accountingMappings
                              .savingsReferenceAccount &&
                            fixedDepositAccountTemplate.accountingMappings
                              .savingsReferenceAccount.name}
                        </p>
                      </div>
                      <div
                        className="form-group d-flex  align-items-center text-label"
                        style={{
                          marginBottom: "1rem !important",
                        }}
                      >
                        <h3
                          style={{
                            fontFamily: "AventaSemiBold",
                            marginRight: "1rem",
                          }}
                          className="col-md-4"
                        >
                          Savings Control(Liabilities) &nbsp;&nbsp;&nbsp;:
                        </h3>
                        <p style={{ width: "60%", fontWeight: "bold" }}>
                          {!!fixedDepositAccountTemplate &&
                            fixedDepositAccountTemplate.accountingMappings &&
                            fixedDepositAccountTemplate.accountingMappings
                              .savingsControlAccount &&
                            fixedDepositAccountTemplate.accountingMappings
                              .savingsControlAccount.name}
                        </p>
                      </div>
                      <div
                        className="form-group d-flex  align-items-center text-label"
                        style={{
                          marginBottom: "1rem !important",
                        }}
                      >
                        <h3
                          style={{
                            fontFamily: "AventaSemiBold",
                            marginRight: "1rem",
                          }}
                          className="col-md-4"
                        >
                          Transfers In Suspense(Liabilities) &nbsp;&nbsp;&nbsp;:
                        </h3>
                        <p style={{ width: "60%", fontWeight: "bold" }}>
                          {!!fixedDepositAccountTemplate &&
                            fixedDepositAccountTemplate.accountingMappings &&
                            fixedDepositAccountTemplate.accountingMappings
                              .transfersInSuspenseAccount &&
                            fixedDepositAccountTemplate.accountingMappings
                              .transfersInSuspenseAccount.name}
                        </p>
                      </div>
                     
                      <div
                        className="form-group d-flex  align-items-center text-label"
                        style={{
                          marginBottom: "1rem !important",
                        }}
                      >
                        <h3
                          style={{
                            fontFamily: "AventaSemiBold",
                            marginRight: "1rem",
                          }}
                          className="col-md-4"
                        >
                          Interest On Savings(Expenses) &nbsp;&nbsp;&nbsp;:
                        </h3>
                        <p style={{ width: "60%", fontWeight: "bold" }}>
                          {!!fixedDepositAccountTemplate &&
                            fixedDepositAccountTemplate.accountingMappings &&
                            fixedDepositAccountTemplate.accountingMappings
                              .interestOnSavingsAccount &&
                            fixedDepositAccountTemplate.accountingMappings
                              .interestOnSavingsAccount.name}
                        </p>
                      </div>{" "}
                      <div
                        className="form-group d-flex  align-items-center text-label"
                        style={{
                          marginBottom: "1rem !important",
                        }}
                      >
                        <h3
                          style={{
                            fontFamily: "AventaSemiBold",
                            marginRight: "1rem",
                          }}
                          className="col-md-4"
                        >
                          Income From Fee(Income) &nbsp;&nbsp;&nbsp;:
                        </h3>
                        <p style={{ width: "60%", fontWeight: "bold" }}>
                          {!!fixedDepositAccountTemplate &&
                            fixedDepositAccountTemplate.accountingMappings &&
                            fixedDepositAccountTemplate.accountingMappings
                              .incomeFromFeeAccount &&
                            fixedDepositAccountTemplate.accountingMappings
                              .incomeFromFeeAccount.name}
                        </p>
                      </div>{" "}
                      <div
                        className="form-group d-flex  align-items-center text-label"
                        style={{
                          marginBottom: "1rem !important",
                        }}
                      >
                        <h3
                          style={{
                            fontFamily: "AventaSemiBold",
                            marginRight: "1rem",
                          }}
                          className="col-md-4"
                        >
                          Income From Penalties(Income) &nbsp;&nbsp;&nbsp;:
                        </h3>
                        <p style={{ width: "60%", fontWeight: "bold" }}>
                          {!!fixedDepositAccountTemplate &&
                            fixedDepositAccountTemplate.accountingMappings &&
                            fixedDepositAccountTemplate.accountingMappings
                              .incomeFromPenaltyAccount &&
                            fixedDepositAccountTemplate.accountingMappings
                              .incomeFromPenaltyAccount.name}
                        </p>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {!!fixedDepositAccountTemplate &&
              fixedDepositAccountTemplate.accountingRule &&
              fixedDepositAccountTemplate.accountingRule.id === 2 ? (
                <div className="pt-1">
                  <FormTitle>
                    <p className="text-info">Advanced Accounting Rule</p>
                    <div className="lineThrough"></div>
                  </FormTitle>

                  <div className="pt-2">
                    <h3
                      style={{ fontSize: 16 }}
                      className="font-weight-bold mb-2"
                    >
                      Configure Fund Sources For Payment Channels
                      &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <div className="pt-2">
                      <Table className="child-table">
                        <thead className="">
                          <tr style={{ backgroundColor: "#FAFAFA" }}>
                            <th>Payment Type</th>
                            <th>Fund Source</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!!fixedDepositAccountTemplate &&
                          !!fixedDepositAccountTemplate.paymentChannelToFundSourceMappings &&
                          fixedDepositAccountTemplate
                            .paymentChannelToFundSourceMappings.length > 0
                            ? fixedDepositAccountTemplate.paymentChannelToFundSourceMappings.map(
                                ({ paymentType, fundSourceAccount }, index) => (
                                  <tr key={index}>
                                    <td>{paymentType.name}</td>
                                    <td>{fundSourceAccount.name}</td>
                                  </tr>
                                )
                              )
                            : null}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="pt-2">
                    <h3
                      style={{ fontSize: 16 }}
                      className="font-weight-bold mb-2"
                    >
                      Fees To Specific Income Account &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <div className="pt-2">
                      <Table className="child-table">
                        <thead className="">
                          <tr style={{ backgroundColor: "#FAFAFA" }}>
                            <th>Fees</th>
                            <th>Income Account</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!!fixedDepositAccountTemplate &&
                          !!fixedDepositAccountTemplate.feeToIncomeAccountMappings &&
                          fixedDepositAccountTemplate.feeToIncomeAccountMappings
                            .length > 0
                            ? fixedDepositAccountTemplate.feeToIncomeAccountMappings.map(
                                ({ charge, incomeAccount }, index) => (
                                  <tr key={index}>
                                    <td>{charge.name}</td>
                                    <td>{incomeAccount.name}</td>
                                  </tr>
                                )
                              )
                            : null}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="pt-2">
                    <h3
                      style={{ fontSize: 16 }}
                      className="font-weight-bold mb-2"
                    >
                      Penalties To Specific Income Account &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <div className="pt-2">
                      <Table className="child-table">
                        <thead className="">
                          <tr style={{ backgroundColor: "#FAFAFA" }}>
                            <th>Penalty</th>
                            <th>Income Account</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!!fixedDepositAccountTemplate &&
                          !!fixedDepositAccountTemplate.penaltyToIncomeAccountMappings &&
                          fixedDepositAccountTemplate
                            .penaltyToIncomeAccountMappings.length > 0
                            ? fixedDepositAccountTemplate.penaltyToIncomeAccountMappings.map(
                                ({ charge, incomeAccount }, index) => (
                                  <tr key={index}>
                                    <td>{charge.name}</td>
                                    <td>{incomeAccount.name}</td>
                                  </tr>
                                )
                              )
                            : null}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </ContentWrapper>
      </div>
    </>
  );
};

export default FixedDepositProductDetails;
