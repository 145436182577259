import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Input, FormControl } from "@chakra-ui/react";
import { ActionButton, FormClass, FormElements } from "../styles";
import { formatDate } from "../../../../util/FormatDate";

import {
  getReportFullParameters,
  getReportParameters,
  generateReport,
  getReportOffices,
  getReportStaffSelects,
  getReportStaffBranchs, //wip
  getReportClientSelects,
  getReportStaffs,
  getReportAccountOfficerSelects,
  getAllCurrency,
  getAllLoanProduct,
  getAllSavingsProduct,
  getAllLoanPurpose,
  getAllLoanFund,
  getAllParSelect,
  getReportTypes,
  getAccounts,
  getAllLoanObligation,
} from "../../../../actions/reportActions";
import { Select } from "antd";

const ReportDetailsModal = ({ onHide, currentReport, setShowReport }) => {
  const dispatch = useDispatch();

  const reportData = useSelector((state) => state.reportData);

  let {
    reportFullParameters = {},
    reportParameters = {},
    reportCurriencies = {},
    reportOffices = {},
    reportStaffSelects = {},
    reportStaffSelectBranchs = {}, //wip
    reportClientSelects = {},
    reportStaffs = {},
    reportAccountOfficerSelects = {},
    reportLoanProducts = {},
    reportSavingsProducts = {},
    reportLoanFund = {},
    reportLoanPurpose = {},
    reportParSelect = {},
    reportPaymentTypes = {},
    reportAccount = {},
    reportLoanObligation = {},
    allReports = {},
  } = reportData || {};

  let newReport = new Set();
  Array.from(
    new Set(
      allReports?.map((item) => {
        for (let i of item.generationTypes) {
          newReport.add(i);
        }
      })
    )
  );

  const typeReport = [...newReport];

  let { name, report } = currentReport || {};
  let { reportId } = report || {};

  let { data: reportOffice = [] } = reportOffices || {};
  let { data: reportStaffSelect = [] } = reportStaffSelects || {};
  let { data: reportClientSelect = [] } = reportClientSelects || {};
  let { data: reportStaff = [] } = reportStaffs || {};
  let { data: reportAccountOfficerSelect = [] } =
    reportAccountOfficerSelects || {};
  let { data: reportCurrency = [] } = reportCurriencies || {};
  let { data: reportLoanProduct = [] } = reportLoanProducts || {};
  let { data: reportSavingsProduct = [] } = reportSavingsProducts || {};
  let { data: reportLoanPurposes = [] } = reportLoanPurpose || {};
  let { data: reportLoanFunds = [] } = reportLoanFund || {};
  let { data: reportParSelects = [] } = reportParSelect || {};
  // let { data: reportPaymentType = [] } = reportPaymentTypes || {};
  let { data: reportAccounts = [] } = reportAccount || {};
  // let { data: reportLoanObligations = [] } = reportLoanObligation || {};
  let { data: reportStaffSelectBranch = [] } = reportStaffSelectBranchs || {};

  // console.log(reportStaffSelect, "reportStaffSelect")
  const [R_officeId, selectOffice] = useState("");
  const [R_selectOffice, setSelectOffice] = useState("");

  const [R_staffId, selectStafff] = useState("");
  const [R_selectStafff, setSelectStafff] = useState("");

  const [R_clientId, selectClient] = useState("");
  const [R_selectClient, setSelectClient] = useState("");

  const [R_currencyId, selectCurrency] = useState("");
  const [R_CurrencyId, setSelectCurrency] = useState("");

  const [R_fundId, selectFund] = useState("");
  const [R_loanProductId, selectLoanProduct] = useState("");
  const [R_loanPurposeId, selectLoanPurpose] = useState("");

  const [R_savingsProductId, setR_savingsProductId] = useState();

  const [R_selectAccount, setR_selectAccount] = useState("");
  const [R_selectProduct, setR_selectProduct] = useState("");
  const [R_account, setR_account] = useState("");
  const [R_parType, setR_parType] = useState("");
  const [R_currencyCode, setCurrency] = useState("");
  const [R_loanOfficerId, selectStaff] = useState("");
  const [R_loanOfficer, setR_loanOfficer] = useState("");
  const [R_date, setR_date] = useState("");

  const [R_toDate, setEndDate] = useState("");
  const [R_fromDate, setStartDate] = useState("");

  const [R_branch, selectBranch] = useState("");
  const [R_Branch, setBranch] = useState("");

  const [R_staffBranchId, selectStaffBranch] = useState("");
  const [R_staffBranch, setR_staffBranch] = useState("");

  const [R_accountOfficerId, selectAccountOfficer] = useState("");
  const [R_accountOfficer, setR_accountOfficer] = useState("");

  const [R_startDate, setBeginDate] = useState("");
  const [R_endDate, setCloseDate] = useState("");
  const [R_asOnDate, setAsOnDate] = useState("");
  const [R_asOn, setR_AsOn] = useState("");
  const [R_asofDate, setAsOfDate] = useState("");
  const [R_transactionId, setTransactionId] = useState("");
  const [R_cycleY, setR_cycleY] = useState("");
  const [R_cycleX, setR_cycleX] = useState("");
  const [R_paymentTypeId, setR_paymentTypeId] = useState("");
  const [R_accountNo, setR_accountNo] = useState("");
  const [R_obligDateType, setR_obligDateType] = useState("");
  const [generateType, setGenerateType] = useState("");

  const [officeData, setOfficeData] = useState({ officeId: [] });

  const [staffData, setStaffData] = useState({ staffId: [] });

  const [clientData, setClientData] = useState({ clientId: [] });

  const [loanOfficerData, setLoanOfficerData] = useState({
    loanOfficerId: reportStaff,
  });

  const [accountOfficerData, setAccountOfficerData] = useState({
    accountOfficerId: [],
  });

  const [staffBranchData, setStaffBranchData] = useState({
    staffBranchId: [],
  });

  const [currencyData, setCurrencyData] = useState({
    currencyId: reportCurrency,
  });
  const [fundData, setFundData] = useState({ fundId: reportLoanFunds });
  // const [allReportAccounts, setAllReportAccounts] = useState({ "reportAccounts": reportAccounts });
  const [savingsProductData, setSavingsProductData] = useState({
    savingsProductId: reportSavingsProduct,
  });
  const [loanProductData, setLoanProductData] = useState({
    loanProductId: reportLoanProduct,
  });
  const [loanPurposeData, setLoanPurposeData] = useState({
    loanPurposeId: reportLoanPurposes,
  });
  const [parTypeData, setParTypeData] = useState({ parType: reportParSelects });

  const handleRunReport = (e) => {
    e.preventDefault();
    // let locale = "en&dateFormat=dd%20MMMM%20yyyy"
    let params = `${
      !!R_transactionId ? `&R_transactionId=${R_transactionId}` : ""
    }${!!R_officeId ? `&R_officeId=${R_officeId}` : ""}
    ${!!R_loanOfficer ? `&R_loanOfficer=${R_loanOfficer}` : ""}
    ${!!R_accountOfficer ? `&R_accountOfficer=${R_accountOfficer}` : ""}
    ${!!R_staffBranch ? `&R_staffBranch=${R_staffBranch}` : ""}
    ${!!R_staffId ? `&R_staffId=${R_staffId}` : ""}
    ${!!R_clientId ? `&R_client=${R_clientId}` : ""}

    ${
      !!R_date
        ? `&R_date=${formatDate(R_date, false, "string:yyyy-MMMM-dd")}`
        : ""
    }
    ${!!R_loanOfficerId ? `&R_loanOfficerId=${R_loanOfficerId}` : ""}
    ${
      // Account Officer
      !!R_accountOfficerId ? `&R_officer=${R_accountOfficerId}` : ""
    }
    ${
      // StaffBranch Officer
      !!R_staffBranch ? `&R_officeId=${R_staffBranch}` : ""
    }
    ${
      // StaffBranch Officer
      !!R_staffBranchId ? `&R_officeId=${R_staffBranchId}` : ""
    }
    ${!!R_cycleY ? `&R_cycleY=${R_cycleY}` : ""}${
      !!R_Branch ? `&R_Branch=${R_Branch}` : ""
    }
    ${!!R_selectProduct ? `&R_selectProduct=${R_selectProduct}` : ""}${
      !!R_cycleX ? `&R_cycleX=${R_cycleX}` : ""
    }
    ${!!R_currencyCode ? `&R_currencyCode=${R_currencyCode}` : ""}${
      !!R_currencyCode ? `&R_currencyCode=${R_currencyCode}` : ""
    }
    ${!!R_CurrencyId ? `&R_CurrencyId=${R_CurrencyId}` : ""}${
      !!R_loanProductId ? `&R_loanProductId=${R_loanProductId}` : ""
    }
    
    ${!!R_currencyId ? `&R_currencyId=${R_currencyId}` : ""}${
      !!R_loanProductId ? `&R_loanProductId=${R_loanProductId}` : ""
    }
    ${!!R_selectOffice ? `&R_selectOffice=${R_selectOffice}` : ""}${
      !!R_loanPurposeId ? `&R_loanPurposeId=${R_loanPurposeId}` : ""
    }
    ${!!R_fundId ? `&R_fundId=${R_fundId}` : ""}${
      !!R_fromDate
        ? `&R_fromDate=${formatDate(R_fromDate, false, "string:yyyy-MMMM-dd")}`
        : ""
    }
    ${
      !!R_toDate
        ? `&R_toDate=${formatDate(R_toDate, false, "string:yyyy-MMMM-dd")}`
        : ""
    }
    ${
      !!R_startDate
        ? `&R_startDate=${formatDate(
            R_startDate,
            false,
            "string:yyyy-MMMM-dd"
          )}`
        : ""
    }
    ${
      !!R_endDate
        ? `&R_endDate=${formatDate(R_endDate, false, "string:yyyy-MMMM-dd")}`
        : ""
    }
    ${
      !!R_asOnDate
        ? `&R_asOnDate=${formatDate(R_asOnDate, false, "string:yyyy-MMMM-dd")}`
        : ""
    }
    ${
      !!R_asOn
        ? `&R_asOn=${formatDate(R_asOn, false, "string:yyyy-MMMM-dd")}`
        : ""
    }
    ${
      !!R_asofDate
        ? `&R_asofDate=${formatDate(R_asofDate, false, "string:yyyy-MMMM-dd")}`
        : ""
    }${!!R_selectAccount ? `&R_selectAccount=${R_selectAccount}` : ""}${
      !!R_savingsProductId ? `&R_savingsProductId=${R_savingsProductId}` : ""
    }${!!R_parType ? `&R_parType=${R_parType}` : ""}${
      !!R_paymentTypeId ? `&R_paymentTypeId=${R_paymentTypeId}` : ""
    }${!!R_account ? `&R_account=${R_account}` : ""}${
      !!R_obligDateType ? `&R_obligDateType=${R_obligDateType}` : ""
    }${!!R_accountNo ? `&R_accountNo=${R_accountNo}` : ""}
    ${!!R_branch ? `&R_branch=${R_branch}` : ""}${
      !!generateType ? `&type=${generateType}` : ""
    }`;
    // ${!!R_fromDate ? `&locale=${locale}` : ""}
    report.type = generateType;
    report.generatedOn = new Date();
    report.status = "pending";

    dispatch(generateReport(reportId, params.replace(/\s/g, ""), report));

    if (generateType === "R_exportJSON") {
      setShowReport(true);
    }
    onHide();
  };

  const selectDate = (val) => {
    if (val === "R_fromDate") return R_fromDate;
    else if (val === "R_date") return R_date;
    else if (val === "R_asOnDate") return R_asOnDate;
    else if (val === "R_asOn") return R_asOn;
    else if (val === "R_toDate") return R_toDate;
    else if (val === "R_startDate") return R_startDate;
    else if (val === "R_endDate") return R_endDate;
  };

  const selectDropDown = (val) => {
    // console.log(val , "val")
    if (val === "R_officeId") return R_officeId;
    else if (val === "R_branch") return R_branch;
    else if (val === "R_staffId") return R_staffId;
    else if (val === "R_client") return R_clientId;
    else if (val === "R_clientId") return R_clientId;
    else if (val === "R_Branch") return R_Branch;
    else if (val === "R_selectOffice") return R_selectOffice;
    else if (val === "R_selectStafff") return R_selectStafff;
    else if (val === "R_selectClient") return R_selectClient;
    else if (val === "R_LoanOfficer") return R_loanOfficer;
    else if (val === "R_loanOfficerId") return R_loanOfficerId;
    else if (val === "R_accountOfficer") return R_accountOfficer;
    else if (val === "R_accountOfficerId") return R_accountOfficerId;
    else if (val === "R_Staff") return R_accountOfficerId;
    // else if (val === "R_branch") return R_staffBranch;
    else if (val === "R_staffBranch") return R_staffBranch;
    else if (val === "R_staffBranchId") return R_staffBranchId;
    else if (val === "R_branch") return R_staffBranchId;
    else if (val === "R_currencyId") return R_currencyId;
    else if (val === "R_CurrencyId") return R_CurrencyId;
    else if (val === "R_fundId") return R_fundId;
    else if (val === "R_loanProductId") return R_loanProductId;
    else if (val === "R_loanPurposeId") return R_loanPurposeId;
    else if (val === "R_parType") return R_parType;
  };

  useEffect(() => {
    if (reportOffice.length > 0) {
      setOfficeData({ officeId: reportOffice });
    }
  }, [reportOffice]);

  useEffect(() => {
    if (reportStaffSelect.length > 0) {
      setStaffData({ staffId: reportStaffSelect });
    }
  }, [reportStaffSelect]);

  // Client Select
  useEffect(() => {
    if (reportClientSelect.length > 0) {
      setClientData({ clientId: reportClientSelect });
    }
  }, [reportClientSelect]);

  useEffect(() => {
    if (reportOffice.length > 0) {
      setOfficeData({ officeId: reportOffice });
    }
  }, [reportOffice]);

  useEffect(() => {
    if (reportStaffSelect.length > 0) {
      setStaffData({ staffId: reportStaffSelect });
    }
  }, [reportStaffSelect]);

  useEffect(() => {
    if (reportClientSelect.length > 0) {
      setClientData({ clientId: reportClientSelect });
    }
  }, [reportClientSelect]);

  useEffect(() => {
    if (reportLoanFunds.length > 0) {
      setFundData({ fundId: reportLoanFunds });
    }
  }, [reportLoanFunds]);

  useEffect(() => {
    if (reportSavingsProduct.length > 0) {
      setSavingsProductData({ savingsProductId: reportSavingsProduct });
    }
  }, [reportSavingsProduct]);

  useEffect(() => {
    if (reportLoanProduct.length > 0) {
      setLoanProductData({ loanProductId: reportLoanProduct });
    }
  }, [reportLoanProduct]);

  useEffect(() => {
    if (reportLoanPurposes.length > 0) {
      setLoanPurposeData({ loanPurposeId: reportLoanPurposes });
    }
  }, [reportLoanPurposes]);

  useEffect(() => {
    if (reportParSelects.length > 0) {
      setParTypeData({ parType: reportParSelects });
    }
  }, [reportParSelects]);

  useEffect(() => {
    if (!!R_officeId) {
      dispatch(getReportStaffs(R_officeId));
    }
  }, [R_officeId]);

  useEffect(() => {
    if (!!R_Branch) {
      dispatch(getReportStaffs(R_Branch));
    }
  }, [R_Branch]);

  useEffect(() => {
    if (!!R_branch) {
      dispatch(getReportStaffs(R_branch));
    }
  }, [R_branch]);

  useEffect(() => {
    if (!!R_clientId) {
      dispatch(getReportAccountOfficerSelects(R_clientId));
      setAccountOfficerData({ accountOfficerId: reportAccountOfficerSelect });
    }
  }, [R_clientId, R_accountOfficer]);

  // Wip
  useEffect(() => {
    if (!!R_officeId) {
      dispatch(getReportStaffBranchs(R_officeId));
      setStaffBranchData({ staffBranchId: [] });
    }
  }, [R_officeId, R_staffBranch]);

  useEffect(() => {
    if (!!R_officeId) {
      dispatch(getReportStaffBranchs(R_officeId));
    }
  }, [R_officeId]);

  useEffect(() => {
    if (!!R_Branch) {
      dispatch(getReportStaffBranchs(R_Branch));
    }
  }, [R_Branch]);

  useEffect(() => {
    if (!!R_branch) {
      dispatch(getReportStaffBranchs(R_branch));
    }
  }, [R_branch]);

  useEffect(() => {
    if (!!R_selectOffice) {
      dispatch(getReportStaffBranchs(R_selectOffice));
    }
  }, [R_selectOffice]);

  useEffect(() => {
    if (!!reportStaff.length) {
      setLoanOfficerData({ loanOfficerId: reportStaff });
    }
  }, [reportStaff]);

  useEffect(() => {
    if (!!reportAccountOfficerSelect.length) {
      setAccountOfficerData({ accountOfficerId: reportAccountOfficerSelect });
    }
  }, [reportAccountOfficerSelect]);

  useEffect(() => {
    if (!!reportStaffSelectBranch.length > 0) {
      setStaffBranchData({ staffBranchId: reportStaffSelectBranch });
    }
  }, [reportStaffSelectBranch]);

  useEffect(() => {
    if (!!reportStaffSelectBranch.length === 0) {
      setStaffBranchData({ staffBranchId: [] });
    }
  }, [reportStaffSelectBranch.length]);

  useEffect(() => {
    if (!!R_branch) {
      dispatch(getReportStaffs(R_branch));
    }
  }, [R_branch]);

  useEffect(() => {
    if (!!R_Branch) {
      dispatch(getReportStaffs(R_Branch));
    }
  }, [R_Branch]);

  useEffect(() => {
    if (!!R_selectOffice) {
      dispatch(getReportStaffs(R_selectOffice));
    }
  }, [R_selectOffice]);

  useEffect(() => {
    dispatch(getReportFullParameters(name));
  }, [name]);

  useEffect(() => {
    dispatch(getReportParameters(reportId));
    dispatch(getReportOffices());
    dispatch(getReportClientSelects());
    dispatch(getReportStaffSelects());
    dispatch(getAllCurrency());
    dispatch(getAllLoanFund());
    dispatch(getAllParSelect());
    dispatch(getAllLoanPurpose());
    dispatch(getAllSavingsProduct());
    dispatch(getAllLoanProduct());
    dispatch(getReportTypes());
    dispatch(getAccounts());
    dispatch(getAllLoanObligation());
  }, [reportId]);

  useEffect(() => {
    if (R_currencyId) {
      dispatch(getAllLoanProduct(R_currencyId));
      dispatch(getAllSavingsProduct(R_currencyId));
      setSavingsProductData({ savingsProductId: reportSavingsProduct });
      setLoanPurposeData({ loanPurposeId: reportLoanPurpose });
    }
  }, [R_currencyId]);

  useEffect(() => {
    if (R_CurrencyId) {
      dispatch(getAllLoanProduct(R_CurrencyId));
      dispatch(getAllSavingsProduct(R_CurrencyId));
      setSavingsProductData({ savingsProductId: reportSavingsProduct });
      setLoanProductData({ loanProductId: reportLoanProduct });
    }
  }, [R_CurrencyId, R_currencyId])


  return (
    <>
      <Modal.Header>
        <Modal.Title>{name}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="wc-modal">
        <div className="d-flex align-items-center justify-content-center flex-direction-coloumn">
          <FormClass style={{ width: "90%" }}>
            <FormElements style={{ width: "100%" }}>
              {reportFullParameters.length > 0 &&
                reportFullParameters?.map((eachRow, index) => {
                  const paramFunc = (index) =>
                    reportParameters?.reportParameters?.filter(
                      (data, idx) => idx === index
                    );
                  let param = paramFunc(index);
                  const selectedValue = `R_${param}`;

                  const selected = selectDate(selectedValue);

                  switch (eachRow?.row[3]) {
                    case "date":
                      return (
                        <div
                          key={index}
                          className="form-group d-flex justify-content-between align-items-center"
                        >
                          <label className="text-label">
                            {eachRow?.row[2] === "startDate"
                              ? "Start Date"
                              : eachRow?.row[2] === "endDate"
                              ? "End Date"
                              : eachRow?.row[2] === "As On"
                              ? "Date on"
                              : eachRow?.row[0] === "asOnDate"
                              ? "Date on Test"
                              : "Date"}
                          </label>
                          {/* <label className="text-label">{selectedValue}</label> */}
                          <FormControl style={{ width: "60%" }}>
                            <div className="datepicker-100-width">
                              <DatePicker
                                id="published-date"
                                selected={selected}
                                // onChange={(e) => {
                                //   console.log(selectedValue , "select");
                                // }}
                                onChange={
                                  selectedValue === "R_date"
                                    ? (date) => setR_date(date)
                                    : selectedValue === "R_fromDate"
                                    ? (date) => setStartDate(date)
                                    : selectedValue === "R_toDate"
                                    ? (date) => setEndDate(date)
                                    : selectedValue === "R_startDate"
                                    ? (date) => setBeginDate(date)
                                    : selectedValue === "R_asOnDate"
                                    ? (date) => setAsOnDate(date)
                                    : selectedValue === "R_asOn"
                                    ? (date) => setR_AsOn(date)
                                    : selectedValue === "R_asofDate"
                                    ? (date) => setAsOfDate(date)
                                    : selectedValue === "R_endDate"
                                    ? (date) => setCloseDate(date)
                                    : ""
                                }
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                placeholderText="dd/mmm/yyyy"
                              />
                            </div>
                          </FormControl>
                        </div>
                      );
                    case "select":
                      const select = selectDropDown(selectedValue);
                      // const change = `set${selectedValue}`
                      return (
                        <div
                          key={index}
                          className="form-group d-flex justify-content-between align-items-center"
                        >
                          <label className="text-label">
                            Select {eachRow?.row[2]}
                          </label>
                          {/* <label className="text-label">{selectedValue}</label> */}
                          <Select
                            placeholder={`Select ${eachRow?.row[2]} `}
                            name={eachRow?.row[2]}
                            value={select}
                            mode={(eachRow?.row[1] === "GLAccountNO" && name === 'Bulk General Ledger') ? "multiple" : ""}
                            maxTagCount={(eachRow?.row[1] === "GLAccountNO" && name === 'Bulk General Ledger') ? "responsive" : ""}
                            maxCount={10}
                            className="w-60"
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={
                              selectedValue === "R_branch"
                                ? (value) => selectBranch(value)
                                : selectedValue === "R_Branch"
                                ? (value) => setBranch(value)
                                : selectedValue === "R_officeId"
                                ? (value) => selectOffice(value)
                                : selectedValue === "R_staffId"
                                ? (value) => selectStafff(value)
                                : selectedValue === "R_client"
                                ? (value) => selectClient(value)
                                : selectedValue === "R_clientId"
                                ? (value) => setSelectClient(value)
                                : selectedValue === "R_account"
                                ? (value) => setR_account(value)
                                : selectedValue === "R_selectOffice"
                                ? (value) => setSelectOffice(value)
                                : selectedValue === "R_selectStafff"
                                ? (value) => setSelectStafff(value)
                                : selectedValue === "R_selectClient"
                                ? (value) => setSelectClient(value)
                                : selectedValue === "R_loanOfficer"
                                ? (value) => setR_loanOfficer(value)
                                : selectedValue === "R_loanOfficerId"
                                ? (value) => selectStaff(value)
                                : selectedValue === "R_accountOfficer"
                                ? (value) => setR_accountOfficer(value)
                                : selectedValue === "R_officer"
                                ? (value) => selectAccountOfficer(value)
                                : selectedValue === "R_staffBranch"
                                ? (value) => setR_staffBranch(value)
                                : selectedValue === "R_officer"
                                ? (value) => selectStaffBranch(value)
                                : selectedValue === "R_loanProductId"
                                ? (value) => selectLoanProduct(value)
                                : selectedValue === "R_loanPurposeId"
                                ? (value) => selectLoanPurpose(value)
                                : selectedValue === "R_selectAccount"
                                ? (value) => setR_selectAccount(value)
                                : selectedValue === "R_currencyId"
                                ? (value) => selectCurrency(value)
                                : selectedValue === "R_CurrencyId"
                                ? (value) => setSelectCurrency(value)
                                : selectedValue === "R_fundId"
                                ? (value) => selectFund(value)
                                : selectedValue === "R_selectProduct"
                                ? (value) => setR_selectProduct(value)
                                : selectedValue === "R_parType"
                                ? (value) => setR_parType(value)
                                : ""
                            }
                          >
                            {eachRow?.row[1] === "officeId"
                              ? !!officeData?.officeId?.length > 0
                                ? officeData?.officeId?.map((item) => {
                                    let { row = [] } = item;
                                    return (
                                      <option key={row[0]} value={row[0]}>
                                        {row[1]}
                                      </option>
                                    );
                                  })
                                : ""
                              : eachRow?.row[1] === "staff"
                              ? !!staffData?.staffId?.length > 0
                                ? staffData?.staffId?.map((item) => {
                                    let { row = [] } = item;
                                    return (
                                      <>
                                        <option key={row[0]} value={row[0]}>
                                          {row[1]}
                                        </option>
                                      </>
                                    );
                                  })
                                : ""
                              : eachRow?.row[1] === "client"
                              ? !!clientData?.clientId?.length > 0
                                ? clientData?.clientId?.map((item) => {
                                    let { row = [] } = item;
                                    return (
                                      <>
                                        <option key={row[0]} value={row[0]}>
                                          {row[1]}
                                        </option>
                                      </>
                                    );
                                  })
                                : ""
                              : eachRow?.row[1] === "accountOfficer"
                              ? !!accountOfficerData?.accountOfficerId?.length >
                                0
                                ? accountOfficerData?.accountOfficerId?.map(
                                    (item) => {
                                      let { row = [] } = item;

                                      return (
                                        <option key={row[0]} value={row[0]}>
                                          {row[1]}
                                        </option>
                                      );
                                    }
                                  )
                                : ""
                              : eachRow?.row[1] === "staffBranch"
                              ? !!staffBranchData?.staffBranchId?.length > 0
                                ? staffBranchData?.staffBranchId?.map((item) => {
                                    let { row = [] } = item;
                                    return (
                                      <option key={row[0]} value={row[0]}>
                                        {row[1]}
                                      </option>
                                    );
                                  })
                                : ""
                              : eachRow?.row[1] === "loanOfficerId"
                              ? !!loanOfficerData?.loanOfficerId?.length > 0
                                ? loanOfficerData?.loanOfficerId?.map((item) => {
                                    let { row = [] } = item;

                                    return (
                                      <option key={row[0]} value={row[0]}>
                                        {row[1]}
                                      </option>
                                    );
                                  })
                                : ""
                              : eachRow?.row[1] === "GLAccountNO"
                              ? !!reportAccounts && reportAccounts?.length > 0
                                ? reportAccounts?.map((item) => {
                                    let { row = [] } = item;
                                    return (
                                      <option
                                        key={row[0]}
                                        value={row[0]}
                                        disabled={R_account.length >= 10 && !R_account.includes(row[0])}
                                      >
                                        {row[1]}
                                      </option>
                                    );
                                  })
                                : ""
                              : eachRow?.row[1] === "loanOfficer"
                              ? !!loanOfficerData?.loanOfficerId?.length > 0
                                ? loanOfficerData?.loanOfficerId?.map((item) => {
                                    let { row = [] } = item;
                                    return (
                                      <option key={row[0]} value={row[0]}>
                                        {row[1]}
                                      </option>
                                    );
                                  })
                                : ""
                              : eachRow?.row[1] === "currencyId"
                              ? !!reportCurriencies?.data?.length > 0
                                ? reportCurriencies?.data?.map((item) => {
                                    let { row = [] } = item;
                                    return (
                                      <option key={row[0]} value={row[0]}>
                                        {row[2]} ({row[1]})
                                      </option>
                                    );
                                  })
                                : ""
                              : eachRow?.row[1] === "parType"
                              ? !!parTypeData?.parType?.length > 0
                                ? parTypeData?.parType?.map((item) => {
                                    let { row = [] } = item;
                                    return (
                                      <option key={row[0]} value={row[0]}>
                                        {row[1]}
                                      </option>
                                    );
                                  })
                                : ""
                              : eachRow?.row[1] === "fundId"
                              ? !!fundData?.fundId?.length > 0
                                ? fundData?.fundId?.map((item) => {
                                    let { row = [] } = item;
                                    return (
                                      <option key={row[0]} value={row[0]}>
                                        {row[1]}
                                      </option>
                                    );
                                  })
                                : ""
                              : eachRow?.row[1] === "loanProductId"
                              ? !!loanProductData?.loanProductId?.length > 0
                                ? loanProductData?.loanProductId?.map((item) => {
                                    let { row = [] } = item;
                                    return (
                                      <option key={row[0]} value={row[0]}>
                                        {row[1]}
                                      </option>
                                    );
                                  })
                                : "No data"
                              : eachRow?.row[1] === "loanPurposeId"
                              ? !!loanPurposeData?.loanPurposeId?.length > 0
                                ? loanPurposeData?.loanPurposeId?.map((item) => {
                                    let { row = [] } = item;
                                    return (
                                      <option key={row[0]} value={row[0]}>
                                        {row[1]}
                                      </option>
                                    );
                                  })
                                : ""
                              : eachRow?.row[1] === "savingsProductId"
                              ? !!savingsProductData?.savingsProductId?.length >
                                0
                                ? savingsProductData?.savingsProductId?.map(
                                    (item) => {
                                      let { row = [] } = item;
                                      return (
                                        <option key={row[0]} value={row[0]}>
                                          {row[1]}
                                        </option>
                                      );
                                    }
                                  )
                                : ""
                              : ""}
                          </Select>
                        </div>
                      );
                    case "text":
                      return (
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label">Account Number</label>
                          {/* <label className="text-label">{selectedValue}</label> */}
                          <Input
                            style={{ width: "60%" }}
                            size="md"
                            // name={parameterName}
                            value={R_accountNo}
                            onChange={
                              selectedValue === "R_accountNo"
                                ? (e) => setR_accountNo(e.target.value)
                                : ""
                            }
                          />
                        </div>
                      );
                    default:
                      return "";
                  }
                })}
              <div className="">
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Generate Type</label>
                  <Select
                    placeholder="Select Generate Type"
                    name="generateType"
                    value={generateType}
                    onChange={(_, option) => setGenerateType(option?.children)}
                    className="w-60"
                  >
                    {typeReport?.length > 0 &&
                      typeReport?.map((item, index) => {
                        return (
                          <option value={item.id} id={item.id} key={index}>
                            {item}
                          </option>
                        );
                      })}
                  </Select>
                </div>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-1">
          <ActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require("../../../../assets/images/icons/cancel.svg").default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton
            disabled={!generateType}
            color={generateType ? "#2C1DFF" : "#b3b3b3"}
            onClick={handleRunReport}
            style={{ cursor: !generateType ? 'not-allowed' : 'pointer' }}
          >
            <img
              src={
                require("../../../../assets/images/icons/edit-profile.svg")
                  .default
              }
              alt="cancel icon"
            />
            Run Report
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default ReportDetailsModal;
