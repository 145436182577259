import { Table } from "antd";
import { ContentWrapper } from "../styles";
import TableLoader from "./TableLoader";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { getAllLoanAccounts, getAllLoanAccountsTemplate } from "../../../../actions/accountActions";
import { useDispatch, useSelector } from "react-redux";

export const LoanAccountsTable = () => {
	const dispatch = useDispatch();
  const accountData = useSelector((state) => state.accountData);
  let { loanAccounts, isLoading, filteredLoan, loanAccountTemplate } =
    accountData;
  const isTableLoading = useSelector(
    (state) => state.isTableLoading.isTableLoading
  );
  const {
    totalFilteredRecords,
    totalItems,
    numberOfPages,
    pageItems = [],
  } = loanAccounts || {};

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 13,
  });
  const [searchParams, setParam] = useState("");
  const [activePagination, setActivePagination] = useState();
  const [myData, setMyData] = useState([]);
  const [firstTime, setFirstTime] = useState(true);
  const [currentTab, setCurrentTab] = useState("loans");
  useEffect(() => {
    dispatch(getAllLoanAccounts("limit=13&offset=0&sortOrder=DESC"));
    dispatch(getAllLoanAccountsTemplate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
	
	const history = useHistory();
	
	const getTableRowData = (rowIndex) => {
		const data = filteredLoan !== null ? filteredLoanAccounts : dataSource;
    return data[rowIndex];
  };
	
	const viewLoan = (rowIndex) => {
		const { clientId, accountNumber } = getTableRowData(rowIndex);
		history.push(
			`/core/client/${clientId}?tab=loan&type=accountNo&accountNo=${accountNumber}`
		);
	};

	const refresh = () => {
    dispatch(getAllLoanAccounts("limit=13&offset=0&sortOrder=DESC"));
    setActivePagination(1);
  };

	const handleTableChange = (pagination, filters, sorter) => {
    setActivePagination(pagination.current);
    if (!!loanAccounts && !!loanAccounts.length) {
      setPagination({
        current: activePagination,
        pageSize: 13,
        total: loanAccounts.length,
      });
    } else {
      let myOffsect = pagination.current * 13;
      let dataToSend2 = `limit=13&offset=${myOffsect - 13}&sortOrder=DESC`;
      dispatch(getAllLoanAccounts(dataToSend2));
    }
  };
	
	const loanColumns = [
    { title: "", dataIndex: "picture", key: "picture" },
    {
      title: "Customer Name",
      dataIndex: "name",
      key: "name",
      render: (value, record, index) => (
        <span onClick={() => viewLoan(index)}>{value}</span>
      ),
    },
    { title: "Status", dataIndex: "status", key: "status" },
    { title: "External ID", dataIndex: "externalId", key: "externalId" },
    {
      title: "Account Number",
      dataIndex: "accountNumber",
      key: "accountNumber",
    },
    {
      title: "Loan Product",
      dataIndex: "pName",
      key: "pName",
      // render: (value, record, index) => (
      //   <td onClick={() => viewLoan(index)}>{value}</td>
      // ),
    },

    { title: "Principal", dataIndex: "principal", key: "principal" },
    { title: "Interest Amount", dataIndex: "interest", key: "interest" },
    {
      title: "Amount Repaid",
      dataIndex: "totalRepayment",
      key: "totalRepayment",
    },
    {
      title: "Outstanding Amount",
      dataIndex: "outstanding",
      key: "outstanding",
    },
    {
      title: "Loan Tenure",
      dataIndex: "loanTenure",
      key: "loanTenure",
    },
    { title: "Overdue Amount", dataIndex: "overdue", key: "overdue" },
    {
      title: "Days in Arrears",
      dataIndex: "daysInArrars",
      key: "daysInArrars",
    },
    { title: "Amount Waived", dataIndex: "totalWaived", key: "totalWaived" },
    {
      title: "Amount Written Off",
      dataIndex: "totalWrittenOff",
      key: "totalWrittenOff",
    },
  ];

	useEffect(() => {
    // if (totalItems !== 0) {
    if (totalFilteredRecords !== 0 && totalFilteredRecords !== 1) {
      setFirstTime(false);
      setPagination({
        current: activePagination,
        pageSize: 13,
        total: totalFilteredRecords,
      });
    } else if (totalItems === 0 && numberOfPages === 0) {
      setPagination(false);
    } else if (!!loanAccounts && !!loanAccounts.length) {
      setPagination({ defaultPageSize: 13 });
    }
  }, [
    totalItems,
    numberOfPages,
    totalFilteredRecords,
    activePagination,
    loanAccounts,
  ]);

	useEffect(() => {
    if (!!loanAccounts && !!loanAccounts.length) {
      const formatedData = loanAccounts?.pageItems?.map(
        ({
          accountNo,
          clientId,
          status,
          customerName,
          productName,
          daysInArrears,
          externalId,
          interest,
          loanTenure,
          outstandingAmount,
          totalRepayment,
          overdueAmount,
          principal,
          totalWaived,
          totalWrittenOff,
        }) => {
          return {
            accountNo,
            clientId,
            status,
            customerName,
            daysInArrears,
            productName,
            externalId,
            loanTenure,
            interest: parseFloat(interest).toFixed(2),
            totalRepayment: parseFloat(totalRepayment).toFixed(2),
            totalWaived: parseFloat(totalWaived).toFixed(2),
            totalWrittenOff: parseFloat(totalWrittenOff).toFixed(2),
            outstandingAmount: parseFloat(outstandingAmount).toFixed(2),
            overdueAmount: parseFloat(overdueAmount).toFixed(2),
            principal: parseFloat(principal).toFixed(2),
          };
        }
      );
      setMyData(formatedData);
    } else if (!!pageItems.length) {
      const formatedData = pageItems?.map(
        ({
          accountNo,
          clientId,
          status,
          customerName,
          daysInArrears,
          externalId,
          productName,
          interest,
          loanTenure,
          outstandingAmount,
          totalWaived,
          totalWrittenOff,
          totalRepayment,
          overdueAmount,
          principal,
        }) => {
          return {
            accountNo,
            clientId,
            status,
            customerName,
            daysInArrears,
            productName,
            loanTenure,
            externalId,
            interest: parseFloat(interest).toFixed(2),
            totalRepayment: parseFloat(totalRepayment).toFixed(2),
            totalWaived: parseFloat(totalWaived).toFixed(2),
            totalWrittenOff: parseFloat(totalWrittenOff).toFixed(2),
            outstandingAmount: parseFloat(outstandingAmount).toFixed(2),
            overdueAmount: parseFloat(overdueAmount).toFixed(2),
            principal: parseFloat(principal).toFixed(2),
          };
        }
      );
      setMyData(formatedData);
    }
  }, [pageItems, loanAccounts]);

	const dataSource = [];
  const filteredLoanAccounts = [];

  if (filteredLoan !== null) {
    filteredLoan?.forEach((loanAccount) => {
      const {
        id,
        status,
        customerName,
        externalId,
        interest,
        principal,
        loanTenure,
        accountNo,
        outstandingAmount,
        totalWaived,
        totalWrittenOff,
        overdueAmount,
        daysInArrears,
        clientId,
      } = loanAccount;

      filteredLoanAccounts.push({
        key: id,
        picture: (
          <div
            className={
              status === "Active"
                ? "box-green"
                : status === "Submitted and awaiting approval" ||
                  status === "Approved"
                ? "box-blue"
                : status === "Closed" ||
                  status === "Withdrawn By Client" ||
                  status === "Rejected" ||
                  status === "Written-off"
                ? "box-red"
                : ""
            }
          />
        ),
        name: customerName,
        externalId,
        accountNumber: accountNo,
        clientId,
        principal,
        loanTenure,
        interest: interest?.toFixed(2),
        outstanding: outstandingAmount?.toFixed(2),
        totalWaived: totalWaived?.toFixed(2),
        totalWrittenOff: totalWrittenOff?.toFixed(2),
        overdue: overdueAmount,
        daysInArrars: daysInArrears,
      });
    });
  }

  !isLoading &&
    myData?.forEach((loanAccount) => {
      const {
        id,
        status,
        customerName,
        externalId,
        interest,
        outstandingAmount,
        principal,
        loanTenure,
        productName,
        totalRepayment,
        totalWaived,
        totalWrittenOff,
        accountNo,
        overdueAmount,
        daysInArrears,
        clientId,
      } = loanAccount;

      dataSource.push({
        key: id,
        picture: (
          <div
            className={
              status === "Active"
                ? "box-green"
                : status === "Submitted and awaiting approval" ||
                  status === "Approved" ||
                  status === "Rescheduled"
                ? "box-blue"
                : status === "Closed" ||
                  status === "Withdrawn by client" ||
                  status === "Rejected" ||
                  status === "Written-Off"
                ? "box-red"
                : ""
            }
          />
        ),
        name: customerName,
        status: <p className="mr-3">{status}</p>,
        externalId,
        accountNumber: accountNo,
        principal,
        interest,
        pName: productName,
        outstanding: outstandingAmount,
        loanTenure: loanTenure,
        totalRepayment,
        totalWaived,
        totalWrittenOff,
        overdue: overdueAmount,
        daysInArrars: daysInArrears,
        clientId,
      });
    });

	return (
		<>
			{isLoading === true && firstTime ? (
				<TableLoader />
			) : (
				<>
					{myData.length && myData.length > 13 ? (
						<Table
							style={{
								overflowX: "scroll",
							}}
							columns={loanColumns}
							pagination={{ pageSize: 13 }}
							defaultPageSize={13}
							loading={isTableLoading}
							dataSource={
								0 || filteredLoan !== null
									? filteredLoanAccounts
									: dataSource
							}
						/>
					) : totalFilteredRecords === 0 ? (
						<Table
							style={{
								overflow: "hidden",
								overflowX: "scroll",
							}}
							columns={loanColumns}
							loading={isTableLoading}
							dataSource={0}
						/>
					) : (
						<Table
							style={{
								overflow: "hidden",
								overflowX: "scroll",
							}}
							columns={loanColumns}
							pagination={pagination}
							defaultPageSize={13}
							loading={isTableLoading}
							onChange={handleTableChange}
							// dataSource={tableData}
							dataSource={
								filteredLoan !== null ? filteredLoanAccounts : dataSource
							}
						/>
					)}
				</>
			)}
		</>
	);
};
