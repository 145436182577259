import React from "react";
import ReportView from "./index";

const data = {
  name: "System Reports",
  overview:
    "A complete overview of your business with analysis and data metrics",
  filter: "System",
};
const SystemReport = () => {
  return <ReportView repData={data} />;
};

export default SystemReport;
