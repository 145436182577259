import ContentLoader from "react-content-loader";
import {
  // FilterButtonsGroup,
  OverviewStatsCard,
  ChartCard,
  // ChartMarker,
  // PieChartLabels,
  // PieChartMeta,
} from "../styles";

const cards = [{ id: "1" }, { id: "2" }, { id: "3" }, { id: "4" }];

const DashboardLoader = () => {
  return (
    <>
      <div>
        {/* <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-5">
            <h3 className="page-title">
              <ContentLoader
                speed={2}
                width={400}
                height={100}
                viewBox="0 0 400 100"
                backgroundColor="#ffffff"
                foregroundColor="#ecebeb"
              >
                <rect x="2" y="20" rx="1" ry="1" width="350" height="40" />
              </ContentLoader>
            </h3>
            <p className="loaderMarginRedude">
              <ContentLoader
                speed={2}
                width={400}
                height={100}
                viewBox="0 0 400 100"
                backgroundColor="#ffffff"
                foregroundColor="#ecebeb"
              >
                <rect x="2" y="0" rx="3" ry="10" width="609" height="10" />
              </ContentLoader>
            </p>
          </div>
          <div className="col-sm-12 col-md-6">
            <FilterButtonsGroup>
              {[0, 1, 2, 3, 4].map((val) => (
                <div style={{ width: "20%" }} key={val}>
                  <ContentLoader
                    speed={2}
                    width={200}
                    height={100}
                    viewBox="0 0 200 100"
                    backgroundColor="#ffffff"
                    foregroundColor="#ecebeb"
                  >
                    <rect x="2" y="10" rx="3" ry="3" width="94" height="40" />
                  </ContentLoader>
                </div>
              ))}
            </FilterButtonsGroup>
          </div>
        </div> */}
        <div>
          <div className="">
            <div className="row">
              {cards.map(({ id }) => (
                <div className="col-sm-12 col-md-4 col-lg-3" key={id}>
                  <OverviewStatsCard>
                    <ContentLoader
                      speed={2}
                      width={300}
                      height={100}
                      viewBox="0 0 300 100"
                      backgroundColor="#f3f3f3"
                      foregroundColor="#ffffff"
                    >
                      <rect
                        x="90"
                        y="30"
                        rx="3"
                        ry="3"
                        width="180"
                        height="24"
                      />
                      <rect
                        x="90"
                        y="69"
                        rx="3"
                        ry="3"
                        width="94"
                        height="10"
                      />
                      <rect
                        x="90"
                        y="106"
                        rx="3"
                        ry="3"
                        width="146"
                        height="6"
                      />
                      <circle cx="31" cy="53" r="30" />
                    </ContentLoader>
                  </OverviewStatsCard>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="row mt-4">
          {[0, 1, 2, 3].map((val) => (
            <div key={val} className="col-sm-12 col-md-6 mb-4 col-lg-6">
              <ChartCard>
                <div className="heading">
                  <div className="title">
                    <ContentLoader
                      speed={2}
                      width={200}
                      height={50}
                      viewBox="0 0 400 100"
                      backgroundColor="#ffffff"
                      foregroundColor="#ecebeb"
                    >
                      <rect
                        x="2"
                        y="0"
                        rx="3"
                        ry="10"
                        width="609"
                        height="20"
                      />
                    </ContentLoader>
                    <ContentLoader
                      speed={2}
                      width={200}
                      height={50}
                      viewBox="0 0 400 50"
                      backgroundColor="#ffffff"
                      foregroundColor="#ecebeb"
                    >
                      <rect
                        x="2"
                        y="0"
                        rx="3"
                        ry="10"
                        width="609"
                        height="10"
                      />
                    </ContentLoader>
                  </div>
                </div>
                <div className="content p-2">
                  <ContentLoader
                    speed={2}
                    // width={100}
                    // height={300}
                    viewBox="0 0 400 100"
                    backgroundColor="#ffffff"
                    foregroundColor="#ecebeb"
                  >
                    <rect x="2" y="0" rx="3" ry="10" width="609" height="300" />
                  </ContentLoader>
                </div>
              </ChartCard>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default DashboardLoader;
