import React from "react";
import ContentLoader from "react-content-loader";

const Loading = () => {
  return (
    <ContentLoader
    speed={2}
    width={200}
    height={35}
    // viewBox="0 0 100 50"
    backgroundColor="#2C1DFF"
    foregroundColor="#ffffff"
    >
      <rect x="0" y="0" rx="0" ry="0" width="100%" height="50" />
    </ContentLoader>
  );
};

export default Loading;
