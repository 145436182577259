import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Select, FormControl, Checkbox } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import CurrencyFormat from "react-currency-format";
import {
  FormClass,
  FormElements,
  ContentWrapper,
  FormTitle,
} from "../../styles.jsx";
import { Link } from "react-router-dom";
import EditLoanAccountCharges from "./EditLoanAccountCharges.jsx";
// import { SET_LOADING_STATE } from '../../../../../../constants/utilityConstants';
import {
  updateLoanAccountInfo,
  getLoanAccount,
  calculateRepaymentSchedule,
} from "../../../../../../actions/SavingsAndLoanActions";
import { getSelectedLoanProductData } from "../../../../../../actions/utilityActions";
import {
  formatDate,
  formatDateString,
} from "../../../../../../util/FormatDate.js";
import BackSvg from "../../../../../../assets/images/icons/back.svg";
import { ActionAnchorButton } from "../../../styles.jsx";
import PreviewRepaymentSchedule from "../../extras/PreviewRepaymentSchedule.jsx";
import CheckPermission from "../../../../../../util/CheckPermission.js";

const EditLoanAccount = ({ match, history, id, currentTab }) => {
  // const [productType, setProductType] = useState('');
  const [productId, setProductId] = useState("");
  const [showSchedule, setShowSchedule] = useState(false);
  const [goToLoanTab] = useState("fromLoan");
  const dispatch = useDispatch();
  const savingsAndLoan = useSelector((state) => state.savingsAndLoan);
  const { productData } = useSelector((state) => state.selectedLoanData);
  let { loanAccountDetails } = savingsAndLoan || {};
  let { clientId, clientName } = loanAccountDetails || {};
  const { responseUserData: { permissions } } = useSelector((state) => state.preAuth);

  const handleProductType = (e) => {
    setProductId(e.target.value);
    setNewLoanAccount({
      ...newLoanAccount,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (productData.charges) {
      setCharges(!!productData.charges ? productData.charges : []);
    }
    if (productData.overdueCharges) {
      setOverdueCharges(
        !!productData.overdueCharges ? productData.overdueCharges : []
      );
    }
    if (productData.timeline) {
      setDisbursementSubmitted(
        productData.timeline?.expectedDisbursementDate
          ? new Date(productData?.timeline?.expectedDisbursementDate)
          : new Date()
      );
    }
    if (productData) {
      setNewLoanAccount((previousData) => {
        return {
          ...previousData,
          loanProductId: productData?.loanProductId,
          clientId: productData ? productData?.clientId : "",
          allowPartialPeriod:
            productData?.allowPartialPeriodInterestCalcualtion,
          principal: productData?.principal,
          loanTermId: productData ? productData?.termFrequency : "",
          loanTermSelect: productData
            ? productData?.termPeriodFrequencyType
              ? productData?.termPeriodFrequencyType?.id
              : ""
            : "",
          noOfRepayments: productData ? productData?.numberOfRepayments : "",

          rapaidId: productData ? productData?.repaymentEvery : "",

          rapaidSelect: productData
            ? productData?.repaymentFrequencyType
              ? productData?.repaymentFrequencyType.id
              : ""
            : "",
          nominalAnnualInterestRate: productData
            ? productData?.interestRatePerPeriod
            : "",
          interestMethod: productData ? productData?.interestType?.id : "",
          amortizationType: productData
            ? productData?.amortizationType
              ? productData?.amortizationType?.id
              : ""
            : "",
          isAmortization: productData
            ? productData?.isEqualAmortization
            : false,
          interestCalculationPeriodSelect:
            productData?.interestCalculationPeriodType?.id === 0
              ? "0"
              : productData?.interestCalculationPeriodType?.id,
          isCalculateInterest: productData
            ? productData.isInterestRecalculationEnabled
            : false,
          arrearsTolerance: productData ? productData?.inArrearsTolerance : "",
          repaymentStrategySelect: productData
            ? productData?.transactionProcessingStrategyId
              ? productData?.transactionProcessingStrategyId
              : ""
            : "",
          repaymentFrequencyNthDay: productData
            ? productData?.repaymentFrequencyNthDayType?.id
            : "",
          repaymentFrequencyDaysOfWeek: productData
            ? productData?.repaymentFrequencyDayOfWeekType?.id
            : "",

          MoratoriumOnPrincipal: productData
            ? productData?.graceOnPrincipalPayment
            : "",
          InterestPayment: productData
            ? productData?.graceOnInterestPayment
            : "",

          MoratoriumOnArrears: productData
            ? productData?.graceOnArrearsAgeing
            : "",
        };
      });
    }
  }, [productData]);

  useEffect(() => {
    if (clientId && productId) {
      dispatch(getSelectedLoanProductData(clientId, productId));
    }
  }, [clientId, dispatch, productId]);

  useEffect(() => {
    dispatch(getLoanAccount(match.params.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match?.params?.id]);

  const [newLoanAccount, setNewLoanAccount] = useState({
    externalId: "",
    loanOfficerId: "",
    loanProductId: "",
    loanPurpose: "",
    fund: "",
    dateSubmitted: "",
    disbursementSubmitted: "",
    loanType: "",
    clientId: "",

    linkedAccountId: "",
    isCreateStandings: false,

    // TERMS
    principal: "",
    loanTermId: "",
    loanTermSelect: "",
    noOfRepayments: "",
    rapaidId: "",
    rapaidSelect: "",
    firstRepayment: "",
    interestChargedFrom: "",
    nominalAnnualInterestRate: "",
    interestMethod: "",
    amortizationType: "",
    isAmortization: false,
    interestCalculationPeriodSelect: " ",
    isCalculateInterest: false,
    arrearsTolerance: "",
    interestFreePeriod: "",
    repaymentStrategySelect: "",
    repaymentFrequencyDaysOfWeek: "",
    repaymentFrequencyNthDay: "",
    allowPartialPeriod: false,
    // Start Check for parameter
    MoratoriumOnPrincipal: "",
    InterestPayment: "",
    MoratoriumOnArrears: "",
    isTopUpLoan: false,
    // End Check for parameter
  });

  const [dateSubmitted, setDateSubmitted] = useState(new Date());
  const [disbursementSubmitted, setDisbursementSubmitted] = useState(
    new Date()
  );
  const [firstRepaymentDate, setFirstRepaymentDate] = useState("");
  const [interestChargedDate, setInterestChargedDate] = useState("");
  const [processing] = useState(false);
  const [charges, setCharges] = useState([]);
  const [overdueCharges, setOverdueCharges] = useState([]);

  let newCharges = [];

  useEffect(() => {
    charges.map((charge) => {
      let {
        id,
        amount,
        amountOrPercentage,
        chargeId,
        chargeTimeType,
        date,
        dueDate,
      } = charge;
      if (id && chargeId) {
        newCharges.push({
          id: id,
          dueDate: formatDate(dueDate),
          chargeId: chargeId,
          // amount: parseFloat(amount),
          // amount: amount,
          amount: amountOrPercentage,
        });
      } else if (!chargeId && id) {
        newCharges.push({
          id: null,
          chargeId: id,
          // amount: parseFloat(amount),
          // amount: amount,
          amount: amountOrPercentage,
          dueDate: formatDate(dueDate),
        });
      }
    });
  }, [charges, newCharges]);
  const toggleSchedule = () => setShowSchedule(state => !state);
  const generateRepaymentSchedule = () => {
    const payload = {
      clientId: newLoanAccount?.clientId,
      productId: newLoanAccount?.loanProductId,
      principal: newLoanAccount?.principal,
      loanTermFrequency: newLoanAccount?.loanTermId,
      loanTermFrequencyType: newLoanAccount?.loanTermSelect,
      numberOfRepayments: newLoanAccount?.noOfRepayments,
      repaymentEvery: newLoanAccount?.rapaidId,
      repaymentFrequencyType: newLoanAccount?.rapaidSelect,
      interestRatePerPeriod: newLoanAccount?.nominalAnnualInterestRate,
      amortizationType: newLoanAccount?.amortizationType,
      isEqualAmortization: newLoanAccount?.isAmortization,
      interestType: newLoanAccount?.interestMethod,
      interestCalculationPeriodType: newLoanAccount?.interestCalculationPeriodSelect,
      allowPartialPeriodInterestCalcualtion: newLoanAccount?.allowPartialPeriod,
      repaymentFrequencyNthDayType: newLoanAccount?.repaymentFrequencyNthDay,
      repaymentFrequencyDayOfWeekType: newLoanAccount?.repaymentFrequencyDaysOfWeek,
      // inArrearsTolerance: newLoanAccount?.inArrears,
      // graceOnPrincipalPayment,
      // graceOnInterestPayment,
      transactionProcessingStrategyId: newLoanAccount?.repaymentStrategySelect,
      // loanPurposeId: purposeId,
      charges: (loanAccountDetails?.charges ?? newCharges)?.map(({ chargeId, amount, dueDate }) => ({ chargeId, amount, dueDate })),
      loanType: loanAccountDetails?.loanType?.value.toLowerCase(),
      expectedDisbursementDate: formatDate(disbursementSubmitted, true),
      submittedOnDate: formatDate(dateSubmitted, true),
      locale: "en",
      dateFormat: "dd MMM yyyy",
      // fundId: '',
      // rates: [],
      // disbursementData: [],
    }
    dispatch(calculateRepaymentSchedule(payload))
  }
  const editLoanAccountHandler = (e) => {
    setNewLoanAccount({
      ...newLoanAccount,
      [e.target.name]: e.target.value,
    });
  };

  const handleExternalId = (e) => {
    setNewLoanAccount({
      ...newLoanAccount,
      externalId: e.target.value,
    });
  };
  useEffect(() => {
    if (loanAccountDetails) {
      setDateSubmitted(
        loanAccountDetails.timeline?.submittedOnDate
          ? new Date(loanAccountDetails.timeline?.submittedOnDate)
          : new Date()
      );
      setDisbursementSubmitted(
        loanAccountDetails.timeline?.expectedDisbursementDate
          ? new Date(loanAccountDetails.timeline?.expectedDisbursementDate)
          : new Date()
      );
    }

    setNewLoanAccount({
      externalId: loanAccountDetails ? loanAccountDetails?.externalId : "",
      dateSubmitted: loanAccountDetails
        ? loanAccountDetails?.timeline?.submittedOnDate
        : "",
      disbursementSubmitted: loanAccountDetails
        ? loanAccountDetails?.timeline?.expectedDisbursementDate.toString()
        : "",

      loanOfficerId: loanAccountDetails
        ? loanAccountDetails?.loanOfficerId
        : "",
      clientId: loanAccountDetails ? loanAccountDetails?.clientId : "",

      loanType: loanAccountDetails
        ? loanAccountDetails?.loanType?.value.toLowerCase()
        : "",

      loanProductId: loanAccountDetails
        ? loanAccountDetails?.loanProductId
          ? loanAccountDetails?.loanProductId
          : ""
        : "",

      linkedAccountId: loanAccountDetails
        ? loanAccountDetails?.linkedAccount?.id
          ? loanAccountDetails?.linkedAccount?.id
          : ""
        : "",
      isCreateStandings: loanAccountDetails
        ? loanAccountDetails?.createStandingInstructionAtDisbursement
        : false,

      allowPartialPeriod: loanAccountDetails
        ? loanAccountDetails?.allowPartialPeriodInterestCalcualtion
        : false,

      principal: loanAccountDetails ? loanAccountDetails?.principal : "",
      loanTermId: loanAccountDetails ? loanAccountDetails?.termFrequency : "",
      loanTermSelect: loanAccountDetails
        ? loanAccountDetails?.termPeriodFrequencyType
          ? loanAccountDetails?.termPeriodFrequencyType?.id
          : ""
        : "",
      noOfRepayments: loanAccountDetails
        ? loanAccountDetails?.numberOfRepayments
        : "",
      rapaidId: loanAccountDetails ? loanAccountDetails?.repaymentEvery : "",
      rapaidSelect: loanAccountDetails
        ? loanAccountDetails?.repaymentFrequencyType
          ? loanAccountDetails?.repaymentFrequencyType.id
          : ""
        : "",
      firstRepayment: loanAccountDetails
        ? loanAccountDetails?.expectedFirstRepaymentOnDate
        : "",
      interestChargedFrom: loanAccountDetails
        ? loanAccountDetails?.interestChargedFromDate
        : "",
      nominalAnnualInterestRate: loanAccountDetails
        ? loanAccountDetails?.interestRatePerPeriod
        : "",
      interestMethod: loanAccountDetails
        ? loanAccountDetails?.interestType?.id
        : "",
      amortizationType: loanAccountDetails
        ? loanAccountDetails?.amortizationType
          ? loanAccountDetails?.amortizationType?.id
          : ""
        : "",
      isAmortization: loanAccountDetails
        ? loanAccountDetails?.isEqualAmortization
        : false,
      interestCalculationPeriodSelect: loanAccountDetails
        ? loanAccountDetails?.interestCalculationPeriodType?.id
          ? loanAccountDetails?.interestCalculationPeriodType?.id
          : ""
        : "",
      isCalculateInterest: loanAccountDetails
        ? loanAccountDetails.isInterestRecalculationEnabled
        : false,
      arrearsTolerance: loanAccountDetails
        ? loanAccountDetails?.inArrearsTolerance
        : "",
      repaymentStrategySelect: loanAccountDetails
        ? loanAccountDetails?.transactionProcessingStrategyId
          ? loanAccountDetails?.transactionProcessingStrategyId
          : ""
        : "",
      interestFreePeriod: loanAccountDetails
        ? loanAccountDetails?.interestFreePeriod
        : "",

      repaymentFrequencyNthDay: loanAccountDetails
        ? loanAccountDetails?.repaymentFrequencyNthDayType?.id
        : "",
      repaymentFrequencyDaysOfWeek: loanAccountDetails
        ? loanAccountDetails?.repaymentFrequencyDayOfWeekType?.id
        : "",
      MoratoriumOnPrincipal: loanAccountDetails
        ? loanAccountDetails?.graceOnPrincipalPayment
        : "",
      InterestPayment: loanAccountDetails
        ? loanAccountDetails?.graceOnInterestPayment
        : "",
      MoratoriumOnArrears: loanAccountDetails
        ? loanAccountDetails?.graceOnArrearsAgeing
        : "",
    });

    setFirstRepaymentDate(
      loanAccountDetails ? loanAccountDetails?.expectedFirstRepaymentOnDate : ""
    );

    setInterestChargedDate(
      loanAccountDetails ? loanAccountDetails?.interestChargedFromDate : ""
    );
    setCharges(!!loanAccountDetails.charges ? loanAccountDetails.charges : []);
    setOverdueCharges(loanAccountDetails.overdueCharges);
  }, [loanAccountDetails]);

  const handleSubmit = async () => {
    let data = {
      productId: newLoanAccount.loanProductId,
      externalId: newLoanAccount.externalId,
      submittedOnDate: formatDate(dateSubmitted, true),
      expectedDisbursementDate: formatDate(disbursementSubmitted, true),
      loanOfficerId: newLoanAccount.loanOfficerId,
      principal: newLoanAccount.principal,
      linkAccountId: newLoanAccount.linkedAccountId,
      repaymentsStartingFromDate: formatDate(firstRepaymentDate, true),
      interestChargedFromDate: formatDate(interestChargedDate, true),
      loanTermFrequencyType: newLoanAccount.loanTermSelect,
      loanTermFrequency: newLoanAccount.loanTermId,
      numberOfRepayments: newLoanAccount.noOfRepayments,
      repaymentEvery: newLoanAccount.rapaidId,
      repaymentFrequencyType: newLoanAccount.rapaidSelect,
      repaymentFrequencyDayOfWeekType:
        newLoanAccount.repaymentFrequencyDaysOfWeek,
      repaymentFrequencyNthDayType: newLoanAccount.repaymentFrequencyNthDay,
      interestCalculationPeriodType:
        newLoanAccount.interestCalculationPeriodSelect,
      interestRatePerPeriod: newLoanAccount.nominalAnnualInterestRate,
      interestType: newLoanAccount.interestMethod,
      amortizationType: newLoanAccount.amortizationType,
      isEqualAmortization: newLoanAccount.isAmortization,
      allowPartialPeriodInterestCalcualtion: newLoanAccount.allowPartialPeriod,
      transactionProcessingStrategyId: newLoanAccount.repaymentStrategySelect,
      graceOnPrincipalPayment: newLoanAccount.MoratoriumOnPrincipal,
      graceOnArrearsAgeing: newLoanAccount.MoratoriumOnArrears,
      graceOnInterestPayment: newLoanAccount.InterestPayment,
      createStandingInstructionAtDisbursement: newLoanAccount.isCreateStandings,
      locale: "en",
      dateFormat: "dd MMM yyyy",
      loanType: loanAccountDetails
        ? loanAccountDetails?.loanType?.value.toLowerCase()
        : "",
      clientId: newLoanAccount.clientId,
      charges: newCharges,
    };

    dispatch(
      updateLoanAccountInfo(
        data,
        match.params.id,
        history,
        clientId,
        goToLoanTab
      )
    );
  };

  // useEffect(() => {
  //   if (productId && loanDetails?.clientId) {
  //     dispatch(getSelectedLoanProductData(loanDetails?.clientId, productId));
  //   }
  // }, [dispatch, loanDetails?.clientId, productId]);

  return showSchedule ? (
    <PreviewRepaymentSchedule
      generateRepaymentSchedule={generateRepaymentSchedule}
      toggleSchedule={toggleSchedule}
    />
  ) : (
    <>
      <div className="row justify-content-between">
        <div className="col-sm-12 col-md-6 pb-5 mt-1">
          <h3 className="page-title">Edit Loan Account</h3>
          <p className="text-label sub-title">Modify Loan accounts</p>
          <p className="font-weight sub-title mt-2">
            <span className="text-info">
              <Link to="/core/clients">Customer </Link>
            </span>{" "}
            /{" "}
            <span className="text-info">
              <Link to={`/core/client/${clientId}`}>
                {!!clientName ? `${clientName}  ` : ""}
              </Link>
            </span>
            / Edit loan Accounts
          </p>
          <p
            onClick={(e) => {
              e.stopPropagation();
              history.push(
                `/core/client/${clientId}/${goToLoanTab}/${match?.params.id}/routeFrom`
              );
            }}
            className="d-flex mt-4"
            style={{ color: "#2C1DFF", cursor: "pointer" }}
          >
            <img className="mr-2" src={BackSvg} alt="" />
            Back
          </p>
        </div>
      </div>

      <div className="row" style={{ minHeight: "calc(100vh - 250px)" }}>
        <div className="col-sm-12 col-md-12">
          <ContentWrapper style={{ paddingTop: 50, paddingBottom: 50 }}>
            <div className="container edit-content">
              {/* Details */}
              <FormTitle>
                <p className="text-info">Details</p>
                <div className="lineThrough"></div>
              </FormTitle>
              {/* Product */}
              <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                <div className="col-sm-12 col-md-6 px-0 ">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label d-flex flex-align-center">
                          Product &nbsp; <span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          size="md"
                          name="loanProductId"
                          onChange={handleProductType}
                          value={newLoanAccount?.loanProductId}
                        >
                          <option value="">Select ProductName</option>
                          {loanAccountDetails !== undefined &&
                          loanAccountDetails
                            ? loanAccountDetails.productOptions &&
                              loanAccountDetails.productOptions.map((cur) => (
                                <option value={cur.id} key={cur.id}>
                                  {cur.name}
                                </option>
                              ))
                            : null}
                        </Select>
                      </div>
                    </FormElements>
                  </FormClass>
                </div>

                {/* Field officer */}
                <div className="col-sm-12 col-md-6 px-0">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label d-flex flex-align-center">
                          Loan officer
                        </label>
                        <Select
                          size="md"
                          name="loanOfficerId"
                          value={newLoanAccount.loanOfficerId}
                          onChange={editLoanAccountHandler}
                        >
                          <option value="">Select One</option>
                          {loanAccountDetails !== undefined &&
                          loanAccountDetails
                            ? loanAccountDetails.loanOfficerOptions &&
                              loanAccountDetails.loanOfficerOptions.map(
                                (cur) => (
                                  <option value={cur.id} key={cur.id}>
                                    {cur.displayName}
                                  </option>
                                )
                              )
                            : null}
                        </Select>
                      </div>
                    </FormElements>
                  </FormClass>
                </div>
              </div>

              {/* Loan Purpose */}
              <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                <div className="col-sm-12 col-md-6 px-0 ">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label d-flex flex-align-center">
                          Loan Purpose &nbsp;{" "}
                          <span style={{ color: "red" }}>*</span>
                          {/* Loan Purpose* */}
                        </label>
                        <Select
                          size="md"
                          name="loanPurpose"
                          //   value={newLoanAccount.productId}
                          //   onChange={editLoanAccountHandler}
                        >
                          <option value="">Select Purpose</option>
                          {/* {loanAccountDetails !== undefined && loanAccountDetails
                            ? loanAccountDetails.productOptions &&
                            loanAccountDetails.productOptions.map((cur) => (
                              <option value={cur.id} key={cur.id}>
                                {cur.name}
                              </option>
                            ))
                            : null} */}
                        </Select>
                      </div>
                    </FormElements>
                  </FormClass>
                </div>

                {/* Fund */}
                <div className="col-sm-12 col-md-6 px-0">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label d-flex flex-align-center">
                          Fund
                        </label>
                        <Select
                          size="md"
                          name="fieldOfficerId"
                          //   value={newLoanAccount.fieldOfficerId}
                          //   onChange={editLoanAccountHandler}
                        >
                          <option value="">Select Fund</option>
                        </Select>
                      </div>
                    </FormElements>
                  </FormClass>
                </div>
              </div>

              {/* Submitted / Disbursementon* */}
              <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                <div className="col-sm-12 col-md-6 px-0 ">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label d-flex flex-align-center">
                          Submitted on&nbsp;{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <FormControl style={{ width: "60%" }}>
                          <div className="datepicker-100-width">
                            <DatePicker
                              id="published-date"
                              name="dateSubmitted"
                              selected={dateSubmitted}
                              onChange={(date) => setDateSubmitted(date)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              placeholderText="dd/mmm/yyyy"
                            />
                          </div>
                        </FormControl>
                      </div>
                    </FormElements>
                  </FormClass>
                </div>

                <div className="col-sm-12 col-md-6 px-0 ">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label">
                          Disbursement on&nbsp;{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <FormControl style={{ width: "60%" }}>
                          <div className="datepicker-100-width">
                            <DatePicker
                              id="published-date"
                              name="disbursementSubmitted"
                              selected={disbursementSubmitted}
                              onChange={(date) =>
                                setDisbursementSubmitted(date)
                              }
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              placeholderText="dd/mmm/yyyy"
                            />
                          </div>
                        </FormControl>
                      </div>
                    </FormElements>
                  </FormClass>
                </div>
              </div>

              <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                {/* External ID */}
                <div className="col-sm-12 col-md-6 px-0">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label">External ID</label>
                        <Input
                          style={{ width: "60%" }}
                          value={newLoanAccount?.externalId}
                          onChange={handleExternalId}
                          size="md"
                        />
                      </div>
                    </FormElements>
                  </FormClass>
                </div>
              </div>

              {/* Savings Linkage */}
              <div className="pt-5">
                <FormTitle>
                  <p className="text-info">Savings Linkage</p>
                  <div className="lineThrough"></div>
                </FormTitle>
              </div>
              <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                {/* Link Savings */}
                <div className="col-sm-12 col-md-6 px-0 ">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label d-flex flex-align-center">
                          Link Savings &nbsp;{" "}
                          <span style={{ color: "red" }}>*</span>
                          {/* Product* */}
                        </label>
                        <Select
                          size="md"
                          name="linkedAccountId"
                          value={newLoanAccount.linkedAccountId}
                          onChange={editLoanAccountHandler}
                        >
                          <option value="">Select Link Savings</option>
                          {loanAccountDetails !== undefined &&
                          loanAccountDetails
                            ? loanAccountDetails.accountLinkingOptions &&
                              loanAccountDetails.accountLinkingOptions.map(
                                (cur) => (
                                  <option value={cur.id} key={cur.id}>
                                    {`${cur.productName} - ${cur.accountNo}`}
                                  </option>
                                )
                              )
                            : null}
                        </Select>
                      </div>
                    </FormElements>
                  </FormClass>
                </div>

                {/* Standing Instructions */}
                <div className="col-sm-12 col-md-6 px-0 ">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label">
                          Create Standing Instructions at disbursement{" "}
                        </label>
                        <FormControl style={{ width: "60%" }}>
                          <Checkbox
                            size="md"
                            name="isCreateStandings"
                            isChecked={newLoanAccount?.isCreateStandings}
                            value={newLoanAccount.isCreateStandings}
                            onChange={(e) =>
                              setNewLoanAccount((p) => ({
                                ...p,
                                isCreateStandings: e.target.checked,
                              }))
                            }
                          />
                        </FormControl>
                      </div>
                    </FormElements>
                  </FormClass>
                </div>
              </div>

              {/***** Terms *****/}

              <div className="pt-5">
                <FormTitle>
                  <p className="text-info">Terms</p>
                  <div className="lineThrough"></div>
                </FormTitle>
              </div>
              <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                {/* Principal / Loan Term */}
                <div className="col-sm-12 col-md-6 px-0 ">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label">
                          Principal<span>&nbsp; *</span>
                        </label>

                        <CurrencyFormat
                          thousandSeparator={true}
                          className="amount-input"
                          style={{
                            width: "60%",
                          }}
                          name="principal"
                          onChange={editLoanAccountHandler}
                          value={newLoanAccount.principal}
                        />
                      </div>
                    </FormElements>
                  </FormClass>
                </div>
                {/* Loan Term */}
                <div className="col-sm-12 col-md-6 px-0 ">
                  <FormClass>
                      {/* Loan Term */}
                      <div className="form-group d-flex mb-10-imp justify-content-between align-items-center">
                        <label className="text-label">
                          Loan Term <span>&nbsp; *</span>
                        </label>
                      <div className="d-grid grid-columns-2">
                        <Input
                          size="md"
                          name="loanTermId"
                          onChange={editLoanAccountHandler}
                          value={newLoanAccount.loanTermId}
                        />
                        <Select
                          placeholder="Select one"
                          size="md"
                          name="loanTermSelect"
                          onChange={editLoanAccountHandler}
                          value={newLoanAccount.loanTermSelect}
                        >
                          {loanAccountDetails !== undefined &&
                          loanAccountDetails
                            ? loanAccountDetails.termFrequencyTypeOptions &&
                              loanAccountDetails.termFrequencyTypeOptions.map(
                                (cur) => (
                                  <option value={cur.id} key={cur.id}>
                                    {cur.value}
                                  </option>
                                )
                              )
                            : null}
                        </Select>
                      </div>
                      </div>
                  </FormClass>
                </div>
              </div>

              <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                {/* Number Of Repayments / Repaid Every */}
                <div className="col-sm-12 col-md-6 px-0 ">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label">
                          Number Of Repayments <span>&nbsp; *</span>
                        </label>

                        <CurrencyFormat
                          thousandSeparator={true}
                          className="amount-input"
                          style={{
                            width: "60%",
                          }}
                          name="noOfRepayments"
                          onChange={editLoanAccountHandler}
                          value={newLoanAccount.noOfRepayments}
                        />
                      </div>
                    </FormElements>
                  </FormClass>
                </div>

                {/* Repaid Every */}
                <div className="col-sm-12 col-md-6 px-0 ">
                  <FormClass>
                    <div className="form-group d-flex mb-10-imp justify-content-between align-items-center">
                      <label className="text-label">
                        Repaid Every <span>&nbsp;*</span>
                      </label>
                      <div className="d-grid grid-columns-2 w-full">
                        <Input
                          size="md"
                          name="rapaidId"
                          onChange={editLoanAccountHandler}
                          value={newLoanAccount.rapaidId}
                        />
                        <Select
                          placeholder="Select one"
                          size="md"
                          name="rapaidSelect"
                          onChange={editLoanAccountHandler}
                          value={newLoanAccount.rapaidSelect}
                        >
                          {loanAccountDetails !== undefined &&
                          loanAccountDetails
                            ? loanAccountDetails.repaymentFrequencyTypeOptions &&
                              loanAccountDetails.repaymentFrequencyTypeOptions.map(
                                (cur) => (
                                  <option value={cur.id} key={cur.id}>
                                    {cur.value}
                                  </option>
                                )
                              )
                            : null}
                        </Select>
                      </div>
                    </div>
                    {/* repaymentFrequencyDaysOfWeekTypeOptions // repaymentFrequencyNthDayTypeOptions */}
                    <div className="form-group d-flex mb-10-imp justify-content-between align-items-center">
                      <label className="text-label">
                        On <span>&nbsp;*</span>
                      </label>
                      <div className="d-grid grid-columns-2">
                        <Select
                          placeholder="Select one"
                          size="md"
                          name="repaymentFrequencyNthDay"
                          onChange={editLoanAccountHandler}
                          value={
                            newLoanAccount.repaymentFrequencyNthDay
                          }
                        >
                          {loanAccountDetails !== undefined &&
                          loanAccountDetails
                            ? loanAccountDetails.repaymentFrequencyNthDayTypeOptions &&
                              loanAccountDetails.repaymentFrequencyNthDayTypeOptions.map(
                                (cur) => (
                                  <option value={cur.id} key={cur.id}>
                                    {cur.value}
                                  </option>
                                )
                              )
                            : null}
                        </Select>
                        <Select
                          placeholder="Select one"
                          size="md"
                          name="repaymentFrequencyDaysOfWeek"
                          onChange={editLoanAccountHandler}
                          value={
                            newLoanAccount.repaymentFrequencyDaysOfWeek
                          }
                        >
                          {loanAccountDetails !== undefined &&
                          loanAccountDetails
                            ? loanAccountDetails.repaymentFrequencyDaysOfWeekTypeOptions &&
                              loanAccountDetails.repaymentFrequencyDaysOfWeekTypeOptions.map(
                                (cur) => (
                                  <option value={cur.id} key={cur.id}>
                                    {cur.value}
                                  </option>
                                )
                              )
                            : null}
                        </Select>
                      </div>
                    </div>
                  </FormClass>
                </div>
              </div>

              <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                {/* First Repayment On / Interest Charged From */}
                <div className="col-sm-12 col-md-6 px-0 ">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label">First Repayment On</label>

                        <DatePicker
                          id="published-date"
                          name="firstRepaymentDate"
                          selected={
                            firstRepaymentDate
                              ? new Date(firstRepaymentDate)
                              : ""
                          }
                          onChange={(date) => setFirstRepaymentDate(date)}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          placeholderText="dd/mmm/yyyy"
                        />
                      </div>
                    </FormElements>
                  </FormClass>
                </div>
                {/* Interest Charged From */}
                <div className="col-sm-12 col-md-6 px-0 ">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label">
                          Interest Charged From
                        </label>
                        <DatePicker
                          id="published-date"
                          name="interestChargedDate"
                          selected={
                            interestChargedDate
                              ? new Date(interestChargedDate)
                              : ""
                          }
                          onChange={(date) => setInterestChargedDate(date)}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          placeholderText="dd/mmm/yyyy"
                        />
                      </div>
                    </FormElements>
                  </FormClass>
                </div>
              </div>

              <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                {/* Nominal Interest Rate / Interest Method*/}
                <div className="col-sm-12 col-md-6 px-0 ">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label">
                          Nominal Interest Rate
                        </label>

                        <Input
                          //   value={minRequiredOpeningBalance}
                          className="amount-input"
                          style={{
                            width: "60%",
                          }}
                          name="nominalAnnualInterestRate"
                          onChange={editLoanAccountHandler}
                          value={newLoanAccount.nominalAnnualInterestRate}
                        />
                      </div>
                    </FormElements>
                  </FormClass>
                </div>
                {/* Interest Method */}
                <div className="col-sm-12 col-md-6 px-0 ">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label">Interest Method</label>

                        <Select
                          placeholder="Select one"
                          size="md"
                          name="interestMethod"
                          onChange={editLoanAccountHandler}
                          value={newLoanAccount.interestMethod}
                        >
                          {loanAccountDetails !== undefined &&
                          loanAccountDetails
                            ? loanAccountDetails.interestTypeOptions &&
                              loanAccountDetails.interestTypeOptions.map(
                                (cur) => (
                                  <option value={cur.id} key={cur.id}>
                                    {cur.value}
                                  </option>
                                )
                              )
                            : null}
                        </Select>
                      </div>
                    </FormElements>
                  </FormClass>
                </div>
              </div>

              {/* Amortization */}
              <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                <div className="col-sm-12 col-md-6 px-0 ">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label d-flex flex-align-center">
                          Amortization &nbsp;{" "}
                          <span style={{ color: "red" }}>*</span>
                          {/* Product* */}
                        </label>
                        <Select
                          size="md"
                          name="amortizationType"
                          value={newLoanAccount.amortizationType}
                          onChange={editLoanAccountHandler}
                        >
                          <option value="">Select ProductName</option>
                          {loanAccountDetails !== undefined &&
                          loanAccountDetails
                            ? loanAccountDetails.amortizationTypeOptions &&
                              loanAccountDetails.amortizationTypeOptions.map(
                                (cur) => (
                                  <option value={cur.id} key={cur.id}>
                                    {cur.value}
                                  </option>
                                )
                              )
                            : null}
                        </Select>
                      </div>
                    </FormElements>
                  </FormClass>
                </div>

                {/* Is Equal Amortization */}
                <div className="col-sm-12 col-md-6 px-0 ">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label">
                          Is Equal Amortization&nbsp;{" "}
                        </label>
                        <FormControl style={{ width: "60%" }}>
                          <Checkbox
                            size="md"
                            name="isAmortization"
                            isChecked={newLoanAccount?.isAmortization}
                            value={newLoanAccount.isAmortization}
                            onChange={(e) =>
                              setNewLoanAccount((p) => ({
                                ...p,
                                isAmortization: e.target.checked,
                              }))
                            }
                          />
                        </FormControl>
                      </div>
                    </FormElements>
                  </FormClass>
                </div>
              </div>

              {/* Interest Calculation Period / */}
              <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                <div className="col-sm-12 col-md-6 px-0 ">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label d-flex flex-align-center">
                          {/* Amortization &nbsp; <span style={{ color: "red" }}>*</span> */}
                          Interest Calculation Period
                          {/* Product* */}
                        </label>
                        <Select
                          size="md"
                          name="interestCalculationPeriodSelect"
                          value={newLoanAccount.interestCalculationPeriodSelect}
                          onChange={editLoanAccountHandler}
                        >
                          <option value="">Select One</option>
                          {loanAccountDetails !== undefined &&
                          loanAccountDetails
                            ? loanAccountDetails?.interestCalculationPeriodTypeOptions &&
                              loanAccountDetails.interestCalculationPeriodTypeOptions.map(
                                (cur) => (
                                  <option value={cur.id} key={cur.id}>
                                    {cur.value}
                                  </option>
                                )
                              )
                            : null}
                        </Select>
                      </div>
                    </FormElements>
                  </FormClass>
                </div>

                {/* Calculate interest for exact days in partial period */}
                <div className="col-sm-12 col-md-6 px-0 ">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label">
                          Calculate interest for exact days in partial period
                        </label>
                        <FormControl style={{ width: "60%" }}>
                          <Checkbox
                            size="md"
                            name="allowPartialPeriod"
                            isChecked={newLoanAccount?.allowPartialPeriod}
                            value={newLoanAccount.allowPartialPeriod}
                            onChange={(e) =>
                              setNewLoanAccount((p) => ({
                                ...p,
                                allowPartialPeriod: e.target.checked,
                              }))
                            }
                          />
                        </FormControl>
                      </div>
                    </FormElements>
                  </FormClass>
                </div>
              </div>

              {/* Arrears Tolerance / Interest Free Period */}
              <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                <div className="col-sm-12 col-md-6 px-0 ">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label">Arrears Tolerance</label>

                        <Input
                          className="amount-input"
                          style={{
                            width: "60%",
                          }}
                          name="Principal"
                          onChange={editLoanAccountHandler}
                          value={newLoanAccount.arrearsTolerance}
                        />
                      </div>
                    </FormElements>
                  </FormClass>
                </div>
                {/* Interest Free Period */}
                <div className="col-sm-12 col-md-6 px-0 ">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label">
                          Interest Free Period
                        </label>

                        <Input
                          //   value={minRequiredOpeningBalance}
                          className="amount-input"
                          style={{
                            width: "45%",
                          }}
                          name="Principal"
                          onChange={editLoanAccountHandler}
                          value={newLoanAccount.minimumOpeningBalance}
                        />
                      </div>
                    </FormElements>
                  </FormClass>
                </div>
              </div>

              {/* Repayment Strategy / Moratorium on principal payment */}
              <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                <div className="col-sm-12 col-md-6 px-0 ">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label d-flex flex-align-center">
                          Repayment Strategy
                          {/* Product* */}
                        </label>
                        <Select
                          size="md"
                          name="repaymentStrategySelect"
                          value={newLoanAccount.repaymentStrategySelect}
                          onChange={editLoanAccountHandler}
                        >
                          <option value="">Select One</option>
                          {loanAccountDetails !== undefined &&
                          loanAccountDetails
                            ? loanAccountDetails.transactionProcessingStrategyOptions &&
                              loanAccountDetails.transactionProcessingStrategyOptions.map(
                                (cur) => (
                                  <option value={cur.id} key={cur.id}>
                                    {cur.name}
                                  </option>
                                )
                              )
                            : null}
                        </Select>
                      </div>
                    </FormElements>
                  </FormClass>
                </div>

                {/* Moratorium on principal payment */}
                <div className="col-sm-12 col-md-6 px-0 ">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label
                          className="text-label d-flex flex-align-center"
                          style={{
                            width: "65%",
                          }}
                        >
                          Moratorium on principal payment
                        </label>
                        <FormControl>
                          <Input
                            // value={MoratoriumOnPrincipal}
                            className="amount-input"
                            name="MoratoriumOnPrincipal"
                            onChange={editLoanAccountHandler}
                            value={newLoanAccount.MoratoriumOnPrincipal}
                          />
                        </FormControl>
                      </div>
                    </FormElements>
                  </FormClass>
                </div>
              </div>

              {/* On interest Payment / Moratorium on principal payment */}
              <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                <div className="col-sm-12 col-md-6 px-0 ">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label d-flex flex-align-center">
                          Moratorium on interest Payment
                          {/* Product* */}
                        </label>
                        <FormControl style={{ width: "60%" }}>
                          <Input
                            className="amount-input"
                            style={{
                              width: "100%",
                            }}
                            name="InterestPayment"
                            onChange={editLoanAccountHandler}
                            value={newLoanAccount.InterestPayment}
                          />
                        </FormControl>
                      </div>
                    </FormElements>
                  </FormClass>
                </div>

                {/* Moratorium on Arrears Aging */}
                <div className="col-sm-12 col-md-6 px-0 ">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label
                          className="text-label d-flex flex-align-center"
                          style={{
                            width: "65%",
                          }}
                        >
                          Moratorium on Arrears Aging
                        </label>
                        <FormControl>
                          <Input
                            className="amount-input"
                            name="MoratoriumOnArrears"
                            onChange={editLoanAccountHandler}
                            value={newLoanAccount.MoratoriumOnArrears}
                          />
                        </FormControl>
                      </div>
                    </FormElements>
                  </FormClass>
                </div>
              </div>
              {/* ===== End Loan Edit ===== */}

              {/****** Charges  *****/}
              {CheckPermission('UPDATE_LOANCHARGE', permissions) && (
              <div className="pt-5">
                <FormTitle>
                  <p className="text-info">Charges</p>
                  <div className="lineThrough"></div>
                </FormTitle>
                <EditLoanAccountCharges
                  template={loanAccountDetails}
                  setCharges={setCharges}
                  charges={charges}
                  overdueCharges={overdueCharges}
                  setOverdueCharges={setOverdueCharges}
                  editLoanAccountHandler={editLoanAccountHandler}
                  loading={processing}
                  handleSubmit={handleSubmit}
                  toggleSchedule={toggleSchedule}
                />
              </div>
              )}
            </div>
          </ContentWrapper>
        </div>
      </div>
    </>
  );
};

export default EditLoanAccount;

