import React, { useState } from "react";
import {
  ContentWrapper,
  FormClass,
  ActionButton,
  FormElements,
  RoleContainer,
  IconContainer,
} from "./styles";
import { Select, FormControl } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";

const BulkLoanReassignmentView = () => {
  const [bulkLoan, setBulkLoan] = useState({
    office: "",
    loanOffice: "",
    fromLoanOffice: "",
    toLoanOffice: "",
  });
  const [selectedReassignment, setSelectedReassignment] = useState([
    "Administrator",
  ]);
  const [assignmentDate, setAssignmentDate] = useState(new Date() || "");
  const bulkLoanChangeHandler = (e) =>
    setBulkLoan({ ...bulkLoan, [e.target.name]: e.target.value });

  let { office, fromLoanOffice, toLoanOffice } = bulkLoan;
  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Bulk Loan Reassignment</h3>
            <p className="sub-title">
              Reassigning all loans from one loan officer to another
            </p>
            <p className="font-weight sub-title mt-2">
              <span className="text-info">
                <Link to="/core/configuration">Configuration</Link> /{" "}
                <Link to="/core/configuration/organization">Utilities</Link>
              </span>{" "}
              / Bulk Loan Reassignment
            </p>
          </div>
        </div>
        <ContentWrapper>
          <FormClass className="h-100 container">
            <FormElements>
              <div className="row h-100" style={{ paddingTop: "100px" }}>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Office</label>
                    <Select
                      placeholder="Select Office"
                      size="md"
                      name="office"
                      value={office}
                      onChange={bulkLoanChangeHandler}
                    >
                      <option>Head Office</option>
                      <option>Mid Office</option>
                      {/* <option>Woodcore Global</option> */}
                    </Select>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Assignment date</label>
                    <FormControl style={{ width: "70%" }}>
                      <DatePicker
                        style={{ width: "100%" }}
                        id="start-date"
                        selected={assignmentDate}
                        onChange={(date) => setAssignmentDate(date)}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="dd/mmm/yyyy"
                      />
                    </FormControl>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">From loan office</label>
                    <Select
                      placeholder="Select Office"
                      size="md"
                      name="fromLoanOffice"
                      value={fromLoanOffice}
                      onChange={bulkLoanChangeHandler}
                    >
                      <option>Woodcore Global</option>
                      <option>Head Office</option>
                    </Select>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">To Loan Office</label>
                    <Select
                      placeholder="Select Office"
                      size="md"
                      name="toLoanOffice"
                      value={toLoanOffice}
                      onChange={bulkLoanChangeHandler}
                    >
                      <option>Woodcore Local</option>
                      <option>Head Office</option>
                    </Select>
                  </div>
                </div>
                <div className="lineThrough"></div>
                <div className="form-group row m-0">
                  <div className="col-md-2 col-xs-12 col-sm-12">
                    <label className="text-muted mb-3">
                      Select bulk loan reassignment
                    </label>
                  </div>
                  <div className="col-md-10 justify-content-sm-center col-sm-12 col-xs-12">
                    <div className="ml-md-2">
                      {[
                        "Administrator",
                        "Teller",
                        "Super User",
                        "Manager",
                        "Executive",
                        "Associate",
                        "Intern",
                        "Officer",
                        "Cashier",
                      ].map((item, key) => (
                        <ActionButton
                          onClick={() =>
                            selectedReassignment.some((val) => val === item)
                              ? setSelectedReassignment(
                                  selectedReassignment.filter(
                                    (val) => val !== item
                                  )
                                )
                              : setSelectedReassignment((prev) => [
                                  ...prev,
                                  item,
                                ])
                          }
                          color={
                            selectedReassignment.some((val) => val === item)
                              ? "#01B1FC"
                              : "#68d0fd"
                          }
                          textColor="#002C79"
                          key={key}
                          className="form-control mb-2"
                        >
                          {item}
                        </ActionButton>
                      ))}
                    </div>
                    <RoleContainer className="ml-md-3 mx-sm-2">
                      {selectedReassignment.map((item, key) => (
                        <ActionButton
                          textColor="#002C79"
                          key={key}
                          color="#e6f7ff"
                          className="form-control mb-2"
                        >
                          {item}
                          <IconContainer
                            onClick={() =>
                              setSelectedReassignment(
                                selectedReassignment.filter(
                                  (val) => val !== item
                                )
                              )
                            }
                            className=""
                          >
                            <img
                              src={
                                require("../../../../../assets/images/icons/cancel-black.svg")
                                  .default
                              }
                              className="ml-2"
                              alt="cancel"
                            />{" "}
                          </IconContainer>
                        </ActionButton>
                      ))}
                    </RoleContainer>
                  </div>
                </div>
              </div>
              <div className="float-right h-100 mt-5">
                <ActionButton shadow className="mb-2" color="#2C1DFF">
                  <img
                    src={
                      require("../../../../../assets/images/icons/refresh.svg")
                        .default
                    }
                    alt="refresh"
                  />
                  Refresh
                </ActionButton>
                <ActionButton className="mr-0" shadow color="#2C1DFF">
                  <img
                    src={
                      require("../../../../../assets/images/icons/save.svg")
                        .default
                    }
                    alt="save"
                  />
                  Submit
                </ActionButton>
              </div>
              <br />
              <br />
            </FormElements>
          </FormClass>
        </ContentWrapper>
      </div>
    </>
  );
};

export default BulkLoanReassignmentView;
