import styled from "styled-components";

export const ActionButtonsGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

export const ActionButton = styled.button`
  background: ${(props) => props.color} !important;
  border-radius: 5px !important;
  color: #fff !important;
  margin: 0 10px;
  border: none;
  display: inline;
  font-size: 14px;
  height: 35px;
  font-family: 'AventaMedium';
  min-width: 130px;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  position: relative;
  cursor: pointer;
  img {
    height: 10px;
    margin-right: 10px;
    display: inline;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;

export const ContentWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 0px #ededf6;
  min-height: 70vh;
  margin-top: 0px;
  padding-bottom: 50px;

  .family-action-button {
    width: 90%;
    height: 50px;
  }
`;

export const AvatarContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 150px;
`;
export const AvatarContainer2 = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const FormClass = styled.div``;

export const FormTitle = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  .lineThrough {
    margin-left: 30px;
    flex: auto;
    height: 1px;
    border-top: 1px solid #e1e1e4;
    max-width: 60%;
  }
  .text-info {
    font-size: 1.125rem;
    line-height: 22px;
  }
`;

export const FormElements = styled.div`
  width: 90%;
  margin-top: 30px;
  margin-bottom: 30px;
  display: block;
  .text-muted {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #969cba;
  }
  .css-42b2qy {
    width: 60%;
    color: #666666;
    & > option {
      padding: 0.5rem 0;
    }
  }
`;
