import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { Avatar } from "@chakra-ui/react";
import { getAllCashierActionRequests } from "../../../../actions/tellerActions";
import CustomItemLoader from "../../../../components/Loader/CustomItemLoader";
import { formatCurrency } from "../../../../util/FormatAmount";
import { truncateString } from "../../../../util/FormatText";
import { useDispatch, useSelector } from "react-redux";
import { Menu, Dropdown, Button } from "antd";
import CheckPermission from "../../../../util/CheckPermission";
import {
  Rejected,
  Approved,
  Heading,
  H3,
  Grid,
  ActionButton,
  LinkColor,
} from "./styled";
import { Input, Textarea } from "@chakra-ui/react";
import ModalContainer from "../../../../components/ModalContainer/index";
import { Modal } from "react-bootstrap";

const Transactions = ({ history }) => {
  const [currentTableActive, setCurrentTableActive] = useState({});
  const [actionValue, setActionValue] = useState("");
  const [amount, setAmount] = useState("");
  const [modalState, toggleModal] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [activePagination, setActivePagination] = useState();
  const [myData, setMyData] = useState([]);
  const [rowDetailModal, toggleDetailsModal] = useState(false);
  const dispatch = useDispatch();
  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  const handleCloseModal = () => {
    toggleModal(false);
    return setActionValue("");
  };

  const handleClick = (value) => {
    setActionValue(value);
    return toggleModal(true);
  };

  const displayModalContent = () => {
    switch (actionValue) {
      case "approve":
        return (
          <ModalReq
            data={currentTableActive}
            status="Approve"
            handleCloseModal={handleCloseModal}
          />
        );
      case "reject":
        return (
          <ModalReq
            data={currentTableActive}
            status="Reject"
            handleCloseModal={handleCloseModal}
          />
        );
      default:
        break;
    }
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={() => handleClick("approve")}>
        <p>Approve</p>
      </Menu.Item>
      <Menu.Item onClick={() => handleClick("reject")}>
        <p>Reject</p>
      </Menu.Item>
    </Menu>
  );
  const isLoading = useSelector((state) => state.isLoading.isLoading);

  const tellerGroupReducer = useSelector((state) => state.tellerReducer);

  const { allCashierActionRequest } = tellerGroupReducer;

  const {
    totalFilteredRecords,
    totalItems,
    numberOfPages,
    pageItems = [],
  } = allCashierActionRequest || {};

  const filteredCashierActionRequest =
    allCashierActionRequest?.pageItems?.filter(
      (each) => each.actionStatus.value !== "PENDING"
    );

  const closeDetailsModal = () => {
    toggleDetailsModal(false);
  };

  useEffect(() => {
    if (CheckPermission("READ_CASHIERREQUEST", permissions)) {
      dispatch(getAllCashierActionRequests("offset=0&limit=10&sortOrder=DESC"));
    }
  }, [dispatch]);

  useEffect(() => {
    if (!!allCashierActionRequest && !!allCashierActionRequest.length) {
      setMyData(allCashierActionRequest);
    } else if (!!pageItems.length) {
      setMyData(pageItems);
    }
  }, [pageItems, allCashierActionRequest]);

  useEffect(() => {
    if (totalFilteredRecords !== 0 && totalFilteredRecords !== 1) {
      setPagination({
        current: activePagination,
        pageSize: 10,
        total: totalFilteredRecords,
      });
    } else if (totalItems === 0 && numberOfPages === 0) {
      setPagination(false);
    } else if (!!allCashierActionRequest && !!allCashierActionRequest.length) {
      setPagination({ defaultPageSize: 10 });
    }
  }, [
    totalItems,
    numberOfPages,
    totalFilteredRecords,
    activePagination,
    allCashierActionRequest,
  ]);

  const handleTableChange = (pagination, filters, sorter) => {
    setActivePagination(pagination.current);
    if (!!allCashierActionRequest && !!allCashierActionRequest.length) {
      setPagination({
        current: activePagination,
        pageSize: 10,
        total: allCashierActionRequest.length,
      });
    } else {
      let myOffsect = pagination.current * 10;
      let dataToSend2 = `offset=${myOffsect - 10}&limit=10&sortOrder=DESC`;
      dispatch(getAllCashierActionRequests(dataToSend2));
    }
  };

  const dataSource = [];

  !!myData &&
    myData?.forEach((eachData) => {
      const {
        id,
        amount,
        actionStatus: { value: currentStatus },
        requestNote: description,
        currencyData: { code },
        rejectedNote,
        approvedNote,
        actionPersonaType,
        actionRequestTimelineData,
        actionType: { value },
      } = eachData;
      dataSource.push({
        key: id,
        approvedNote,
        status: currentStatus,
        rejectedNote,
        actionRequestTimelineData,
        actionPersonaType,
        tellerName: truncateString(
          `${actionRequestTimelineData?.submittedByFirstname} ${actionRequestTimelineData?.submittedByLastname}`,
          18
        ),
        type: `${value} CASH`,
        amount: `${code} ${formatCurrency(amount)}`,
        description: truncateString(description, 50),
        avatar: (
          <Avatar
            name={
              actionRequestTimelineData?.submittedByFirstname +
              " " +
              actionRequestTimelineData?.submittedByLastname
            }
          />
        ),
        action: (
          <div onClick={(e) => e.stopPropagation()}>
            {currentStatus === "PENDING" ? (
              <Dropdown overlay={menu}>
                <Button onMouse className="action_button">
                  Action
                </Button>
              </Dropdown>
            ) : currentStatus === "APPROVED" ? (
              <span className="new-text-success">{currentStatus}</span>
              // <Approved>{currentStatus}</Approved>
            ) : (
              <span className="new-text-danger">{currentStatus}</span>
              // <Rejected>{currentStatus}</Rejected>
            )}
          </div>
        ),
      });
    });

  const columns = [
    { title: "", dataIndex: "avatar", key: "avatar" },
    { title: "Teller Name", dataIndex: "tellerName", key: "tellerName" },
    { title: " Type", dataIndex: "type", key: "type" },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    { title: "Status", dataIndex: "action", key: "action" },
  ];

  return (
    <div className="container-fluid">
      <div className="row justify-content-between">
        <div className="col-sm-12 col-md-12 col-lg-6 pb-2">
          <h3 className="page-title">Request history</h3>
          <p className="text-muted sub-title mb-2">
            A complete overview of your business with analysis and data metrics
          </p>
          <div className="font-weight sub-title">
            <LinkColor to="/core/teller-group" color>
              Teller management /{" "}
            </LinkColor>
            <LinkColor to="/core/teller-request" color>
              Teller requests /{" "}
            </LinkColor>
            <span>History</span>
          </div>
        </div>
      </div>
      <div>
        <ModalContainer size="md" show={modalState}>
          {modalState && displayModalContent()}
        </ModalContainer>
        {!!modalState ? (
          " "
        ) : (
          <ModalContainer size="md" show={rowDetailModal}>
            {rowDetailModal && (
              <DetailsModal
                closeDetailsModal={closeDetailsModal}
                data={currentTableActive}
              />
            )}
          </ModalContainer>
        )}

        <div>
          {isLoading ? (
            <CustomItemLoader />
          ) : (
            <>
              {CheckPermission("READ_CASHIERREQUEST", permissions) && (
                <Table
                  onRow={(r) => ({
                    onClick: () => {
                      setAmount(r?.amount);
                      toggleDetailsModal(true);
                      return setCurrentTableActive(r);
                    },
                  })}
                  onChange={handleTableChange}
                  columns={columns}
                  dataSource={dataSource}
                  pagination={pagination}
                  defaultPageSize={'10'}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Transactions;

const ModalReq = ({ data, handleCloseModal, status }) => {
  const { amount } = data;
  return (
    <div className="p-4 position-relative">
      <div>
        <Heading>{status}</Heading>
        <div>
          <div className="d-flex my-3 justify-content-between">
            <label style={{ minWidth: "30%" }} htmlFor="Amount">
              Amount
            </label>
            <Input readOnly value={amount} type="text" />
          </div>
          <div className="d-flex justify-content-between">
            <label style={{ minWidth: "30%" }} htmlFor="Description">
              Description
            </label>
            {status === "Approve" ? (
              <Textarea
                placeholder="Approval note"
                name="approvedNote"
              ></Textarea>
            ) : status === "Reject" ? (
              <Textarea
                placeholder="Rejection note"
                name="rejectedNote"
              ></Textarea>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="d-flex mt-3 justify-content-end">
          <ActionButton>Submit</ActionButton>
        </div>
      </div>
      <div className="detail-cancel" onClick={handleCloseModal}>
        <img
          src={require("../../../../assets/images/icons/close.svg").default}
          className="detail-cancel-icon"
          alt="cancel icon"
        />
      </div>
    </div>
  );
};

const DetailsModal = ({ data, closeDetailsModal }) => {
  return (
    <>
      <Modal.Header>
        <Modal.Title>
          {data?.avatar} <span className="ml-3" style={{textTransform: "capitalize"}}>{data?.tellerName}</span>{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="position-relative">
          <div className="mt-1">
            <div className="d-flex my-3 justify-content-between">
              <label className="text-label">Type</label>
              <Input
                style={{ width: "60%" }}
                readOnly
                value={data?.type}
                type="text"
              />
            </div>
            <div className="d-flex my-3 justify-content-between">
              <label className="text-label">Amount</label>
              <Input
                style={{ width: "60%" }}
                readOnly
                value={data?.amount}
                type="text"
              />
            </div>
            <div className="d-flex my-3 justify-content-between">
              <p className="text-label">Submitted date</p>
              <Input
                readOnly
                style={{ width: "60%" }}
                type="text"
                value={
                  data?.actionRequestTimelineData?.submittedOnDate[2] +
                  "/" +
                  data?.actionRequestTimelineData?.submittedOnDate[1] +
                  "/" +
                  data?.actionRequestTimelineData?.submittedOnDate[0]
                }
              />
            </div>
            <div className="d-flex my-3 justify-content-between">
              <p className="text-label">Submitted time</p>
              <Input
                readOnly
                type="text"
                style={{ width: "60%" }}
                value={data?.actionRequestTimelineData?.submittedOnTime}
              />
            </div>
            <div className="d-flex my-3 justify-content-between">
              <p className="text-label">Request note</p>
              <Textarea
                style={{ width: "60%", fontSize: "12px" }}
                readOnly
                value={data?.description}
              ></Textarea>
            </div>
            <div className="d-flex my-3 justify-content-between">
              <p className="text-label">Status</p>
              <Input
                style={{ width: "60%" }}
                readOnly
                type="text"
                value={data?.status}
              />
            </div>
            {data?.actionRequestTimelineData?.rejectedOnDate ? (
              <>
                <div className="d-flex my-3 justify-content-between">
                  <p className="text-label">Rejected date</p>
                  <Input
                    readOnly
                    type="text"
                    style={{ width: "60%" }}
                    value={
                      data?.actionRequestTimelineData?.rejectedOnDate[2] +
                      "/" +
                      data?.actionRequestTimelineData?.rejectedOnDate[1] +
                      "/" +
                      data?.actionRequestTimelineData?.rejectedOnDate[0]
                    }
                  />
                </div>
                <div className="d-flex my-3 justify-content-between">
                  <p className="text-label">Rejected time</p>
                  <Input
                    readOnly
                    style={{ width: "60%" }}
                    type="text"
                    value={data?.actionRequestTimelineData?.rejectedOnTime}
                  />
                </div>
                <div className="d-flex my-3 justify-content-between">
                  <p className="text-label">Rejected by</p>
                  <Input
                    readOnly
                    type="text"
                    style={{ width: "60%" }}
                    value={
                      data?.actionRequestTimelineData?.rejectedByFirstname +
                      " " +
                      data?.actionRequestTimelineData?.rejectedByLastname
                    }
                  />
                </div>
              </>
            ) : (
              ""
            )}
            {data?.actionRequestTimelineData?.approvedOnDate ? (
              <>
                <div className="d-flex my-3 justify-content-between">
                  <p className="text-label">Approved date</p>
                  <Input
                    readOnly
                    style={{ width: "60%" }}
                    type="text"
                    value={
                      data?.actionRequestTimelineData?.approvedOnDate[2] +
                      "/" +
                      data?.actionRequestTimelineData?.approvedOnDate[1] +
                      "/" +
                      data?.actionRequestTimelineData?.approvedOnDate[0]
                    }
                  />
                </div>
                <div className="d-flex my-3 justify-content-between">
                  <p className="text-label">Approved time</p>
                  <Input
                    readOnly
                    type="text"
                    style={{ width: "60%" }}
                    value={data?.actionRequestTimelineData?.approvedOnTime}
                  />
                </div>
                <div className="d-flex my-3 justify-content-between">
                  <p className="text-label">Approved by</p>
                  <Input
                    readOnly
                    type="text"
                    style={{ width: "60%" }}
                    value={
                      data?.actionRequestTimelineData?.approvedByFirstname +
                      " " +
                      data?.actionRequestTimelineData?.approvedByLastname
                    }
                  />
                </div>
              </>
            ) : (
              ""
            )}
            {data?.rejectedNote ? (
              <div className="d-flex my-3 justify-content-between">
                <p className="text-label">Rejection note</p>
                <Textarea
                  style={{ width: "60%", fontSize: "12px" }}
                  readOnly
                  value={data?.rejectedNote}
                ></Textarea>
              </div>
            ) : (
              ""
            )}
            {data?.approvedNote ? (
              <div className="d-flex my-3 justify-content-between">
                <p className="text-label">Approval note</p>
                <Textarea
                  style={{ width: "60%", fontSize: "12px" }}
                  eadOnly
                  value={data?.approvedNote}
                ></Textarea>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-1">
          <ActionButton color="#2C1DFF" onClick={closeDetailsModal}>
            <img
              src={
                require("../../../../assets/images/icons/cancel.svg").default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};
