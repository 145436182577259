import React from "react";
// import { useSelector } from "react-redux";
import { Select, Input, FormControl, Checkbox } from "@chakra-ui/react";

import {
  FormClass,
  FormElements,
  ActionButton,
  FormTitle,
} from "../../../styles.jsx";

const LoanSettings = ({
  handleNextScreen,
  handlePreviousScreen,
  amortization,
  interestMethod,
  interestCalculation,
  isEqualAmortization,
  transactionProcessingStrategyId,
  moratonium,
  moratonium2,
  graceOnInterestCharged,
  inArrearsToleranceValue,
  daysInyear,
  daysInMonth,
  canDefineInstallmentAmount,
  graceOnArrearsAgeingValue,
  overdueDaysForNPA,
  accountMovesOutOfNPAOnlyOnArrearsCompletion,
  principalThresholdForLastInstallment,
  isVariableInstallment,
  canUseForTopup,
  updateLoanProductInfoHandler,
  isInterestRecalculationEnabled,
  placeGuarantee,
  loanTrancte,
  setSettingsError,
  template,
  setNewLoanProduct,
  amortizationType,
  repaymentEvery,
  interestType,
  transactionProcessingStrategy,
  interestCalculationPeriodType,
  inArrearsTolerance,
  graceOnArrearsAgeing,
  graceOnPrincipalAndInterestPayment,
  settingsError,
  minimumGap,
  maximumGap,
  recalculationRestFrequencyInterval,
  preClosureInterestCalculationStrategy,
  interestRecalculationCompoundingMethod,
  rescheduleStrategyMethod,
  recalculationRestFrequencyType,
  recalculationCompoundingFrequencyInterval,
  recalculationCompoundingFrequencyType,
  recalculationCompoundingFrequencyDayOfWeekType,
  recalculationCompoundingFrequencyNthDayType,
  recalculationCompoundingFrequencyOnDayType,
  isArrearsBasedOnOriginalSchedule,
  recalculationRestFrequencyDayOfWeekType,
  recalculationRestFrequencyNthDayType,
  recalculationRestFrequencyOnDayType,
  mandatoryGuarantee,
  minimumGuaranteeFromOwnFunds,
  minimumGuaranteeFromGuarantor,
  maxTrancheCount,
  outstandingLoanBalance,
  allowPartialPeriodInterestCalcualtion,
  renderTooltip,
}) => {
  return (
    <>
      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        {/* <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Amortization*
                  {renderTooltip("Amortization")}
                </label>
                <Select
                  style={{
                    borderColor:
                      !amortization && settingsError ? "red" : "inherit",
                  }}
                  size="md"
                  name="amortization"
                  value={amortization}
                  onChange={updateLoanProductInfoHandler}
                >
                  <option value="">Select an option</option>
                  {template !== undefined && template
                    ? template.amortizationTypeOptions &&
                      template.amortizationTypeOptions.map((cur) => (
                        <option value={cur.id} key={cur.id}>
                          {cur.value}
                        </option>
                      ))
                    : null}
                </Select>
              </div>
            </FormElements>
          </FormClass>
        </div> */}
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Interest Method*
                  {renderTooltip("Interest Method")}
                </label>
                <Select
                  size="md"
                  name="interestMethod"
                  value={interestMethod}
                  onChange={updateLoanProductInfoHandler}
                >
                  <option value="">Select an option</option>
                  {template !== undefined && template
                    ? template.interestTypeOptions &&
                      template.interestTypeOptions.map((cur) => (
                        <option value={cur.id} key={cur.id}>
                          {cur.value}
                        </option>
                      ))
                    : null}
                </Select>
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Interest Calculation*
                  {renderTooltip("Interest Calculation Period")}
                </label>
                <Select
                  size="md"
                  name="interestCalculation"
                  style={{
                    borderColor:
                      !interestCalculation && settingsError ? "red" : "inherit",
                  }}
                  value={String(interestCalculation)}
                  onChange={updateLoanProductInfoHandler}
                >
                  <option value="">Select an option</option>
                  {template !== undefined && template
                    ? template.interestCalculationPeriodTypeOptions &&
                      template.interestCalculationPeriodTypeOptions.map(
                        (cur) => (
                          <option value={cur.id} key={cur.id}>
                            {cur.value}
                          </option>
                        )
                      )
                    : null}
                </Select>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>

      {/* <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
   
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Is Equal Amortization
                  {renderTooltip("Is Equal Amortization")}
                </label>
                <FormControl style={{ width: "60%" }}>
                  <Checkbox
                    size="md"
                    isChecked={isEqualAmortization}
                    onChange={(e) =>
                      setNewLoanProduct((p) => ({
                        ...p,
                        isEqualAmortization: e.target.checked,
                      }))
                    }
                  />
                </FormControl>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div> */}

      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Repayment Strategy*
                  {renderTooltip("Repayment Strategy")}
                </label>
                <Select
                  size="md"
                  name="transactionProcessingStrategyId"
                  value={transactionProcessingStrategyId}
                  onChange={updateLoanProductInfoHandler}
                  style={{
                    borderColor:
                      !transactionProcessingStrategyId && settingsError
                        ? "red"
                        : "inherit",
                  }}
                >
                  <option value="">Select an option</option>
                  {template !== undefined && template
                    ? template.transactionProcessingStrategyOptions &&
                      template.transactionProcessingStrategyOptions.map(
                        (cur) => (
                          <option value={cur.id} key={cur.id}>
                            {cur.name}
                          </option>
                        )
                      )
                    : null}
                </Select>
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Calculate Interest For Exact Days In Partial Period
                  {renderTooltip(
                    "Calculate Interest For Exact Days In Partial Period"
                  )}
                </label>
                <FormControl style={{ width: "60%" }}>
                  <Checkbox
                    size="md"
                    isChecked={allowPartialPeriodInterestCalcualtion}
                    onChange={(e) =>
                      setNewLoanProduct((p) => ({
                        ...p,
                        allowPartialPeriodInterestCalcualtion: e.target.checked,
                      }))
                    }
                  />
                </FormControl>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>
      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex align-items-center">
                <label
                  className="text-label d-flex flex-align-center"
                  style={{ marginRight: "43px" }}
                >
                  Moratorium
                  {renderTooltip("Moratorium")}
                </label>
                <Input
                  style={{ width: "50%", borderRadius: "0" }}
                  size="md"
                  name="moratonium"
                  value={moratonium}
                  type="number"
                  placeholder="On principal payment"
                  onChange={updateLoanProductInfoHandler}
                />
                <span className="afterInputText">Days</span>
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex align-items-center">
                <label
                  className="text-label d-flex flex-align-center"
                  style={{ marginRight: "43px" }}
                >
                  Moratorium 2{renderTooltip("Moratorium 2")}
                </label>
                <Input
                  style={{ width: "50%", borderRadius: "0" }}
                  size="md"
                  name="moratonium2"
                  value={moratonium2}
                  type="number"
                  placeholder="On interest payment"
                  onChange={updateLoanProductInfoHandler}
                />
                <span className="afterInputText">Days</span>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>
      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Interest Free Period
                  {renderTooltip("Interest Free Period")}
                </label>
                <Input
                  style={{ width: "60%" }}
                  size="md"
                  name="graceOnInterestCharged"
                  type="number"
                  value={graceOnInterestCharged}
                  onChange={updateLoanProductInfoHandler}
                />
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Arrears Tolerance*
                  {renderTooltip("Arrears Tolerance")}
                </label>
                <Input
                  style={{
                    width: "60%",
                  }}
                  size="md"
                  name="inArrearsToleranceValue"
                  value={inArrearsToleranceValue}
                  type="number"
                  onChange={updateLoanProductInfoHandler}
                />
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>

      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Days In Year*
                  {renderTooltip("Days In Year")}
                </label>
                <Select
                  size="md"
                  name="daysInyear"
                  value={daysInyear}
                  onChange={updateLoanProductInfoHandler}
                  style={{
                    borderColor:
                      !daysInyear && settingsError ? "red" : "inherit",
                  }}
                >
                  <option value="">Select an option</option>
                  {template !== undefined && template
                    ? template.daysInYearTypeOptions &&
                      template.daysInYearTypeOptions.map((cur) => (
                        <option value={cur.id} key={cur.id}>
                          {cur.value}
                        </option>
                      ))
                    : null}
                </Select>
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Days In Month*
                  {renderTooltip("Days In Month")}
                </label>
                <Select
                  size="md"
                  name="daysInMonth"
                  value={daysInMonth}
                  onChange={updateLoanProductInfoHandler}
                  style={{
                    borderColor:
                      !daysInMonth && settingsError ? "red" : "inherit",
                  }}
                >
                  <option value="">Select an option</option>
                  {template !== undefined && template
                    ? template.daysInMonthTypeOptions &&
                      template.daysInMonthTypeOptions.map((cur) => (
                        <option value={cur.id} key={cur.id}>
                          {cur.value}
                        </option>
                      ))
                    : null}
                </Select>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>

      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          {/* <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label
                  style={{ width: "40%" }}
                  className="text-label d-flex flex-align-center"
                >
                  Allow Fixing Of The Installment Amount
                  {renderTooltip("Allow Fixing Of The Installment Amount")}
                </label>
                <FormControl style={{ width: "60%" }}>
                  <Checkbox
                    size="md"
                    isChecked={canDefineInstallmentAmount}
                    onChange={(e) =>
                      setNewLoanProduct((p) => ({
                        ...p,
                        canDefineInstallmentAmount: e.target.checked,
                      }))
                    }
                  />
                </FormControl>
              </div>
            </FormElements>
          </FormClass> */}
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label
                  style={{ width: "40%" }}
                  className="text-label d-flex flex-align-center"
                >
                  Number Of Days A Loan May Be Overdue Before Moving Into
                  Arrears
                  {renderTooltip(
                    "Number Of Days A Loan May Be Overdue Before Moving Into Arrears"
                  )}
                </label>
                <Input
                  style={{ width: "60%" }}
                  size="md"
                  name="graceOnArrearsAgeingValue"
                  type="number"
                  value={graceOnArrearsAgeingValue}
                  onChange={updateLoanProductInfoHandler}
                />
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label
                  style={{ width: "40%" }}
                  className="text-label d-flex flex-align-center"
                >
                  Maximum Number Of Days A Loan Maybe Overdue Before Becoming A
                  NPA ( Non Performing Asset)
                  {renderTooltip(
                    "Maximum Number Of Days A Loan Maybe Overdue Before Becoming ANPA ( Non Performing Asset)"
                  )}
                </label>
                <Input
                  style={{ width: "60%" }}
                  size="md"
                  name="overdueDaysForNPA"
                  value={overdueDaysForNPA}
                  type="number"
                  onChange={updateLoanProductInfoHandler}
                />
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>
      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label
                  style={{ width: "40%" }}
                  className="text-label d-flex flex-align-center"
                >
                  Account Moves Out Of NPA Only After All Arrears Have Been
                  Cleared
                  {renderTooltip(
                    "Account Moves Out Of NPA Only After All Arrears Have Been Cleared"
                  )}
                </label>
                <FormControl style={{ width: "60%" }}>
                  <Checkbox
                    size="md"
                    isChecked={accountMovesOutOfNPAOnlyOnArrearsCompletion}
                    onChange={(e) =>
                      setNewLoanProduct((p) => ({
                        ...p,
                        accountMovesOutOfNPAOnlyOnArrearsCompletion:
                          e.target.checked,
                      }))
                    }
                  />
                </FormControl>
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Is Allowed To Be Used For Providing Topup Loans?
                  {renderTooltip(
                    "Is Allowed To Be Used For Providing Topup Loans?"
                  )}
                </label>
                <FormControl style={{ width: "60%" }}>
                  <Checkbox
                    size="md"
                    isChecked={canUseForTopup}
                    onChange={(e) =>
                      setNewLoanProduct((p) => ({
                        ...p,
                        canUseForTopup: e.target.checked,
                      }))
                    }
                  />
                </FormControl>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>
      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Principal Threshold (%) For Last Installment
                  {renderTooltip(
                    "Principal Threshold (%) For Last Installment"
                  )}
                </label>
                <Input
                  style={{ width: "60%" }}
                  size="md"
                  name="principalThresholdForLastInstallment"
                  value={principalThresholdForLastInstallment}
                  onChange={updateLoanProductInfoHandler}
                  type="number"
                />
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Is Variable Installment Allowed
                  {renderTooltip("Is Variable Installment Allowed")}
                </label>
                <FormControl style={{ width: "60%" }}>
                  <Checkbox
                    size="md"
                    isChecked={isVariableInstallment}
                    onChange={(e) =>
                      setNewLoanProduct((p) => ({
                        ...p,
                        isVariableInstallment: e.target.checked,
                      }))
                    }
                  />
                </FormControl>
              </div>
            </FormElements>
          </FormClass>

          {isVariableInstallment && (
            <div>
              <FormClass>
                <FormElements>
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label d-flex flex-align-center">
                      Variable Installments
                      {renderTooltip("Variable Installments")}
                    </label>
                    <Input
                      style={{ width: "60%" }}
                      size="md"
                      name="minimumGap"
                      value={minimumGap}
                      type="number"
                      placeholder="Minimum gap between"
                      onChange={updateLoanProductInfoHandler}
                    />
                  </div>
                </FormElements>
              </FormClass>
              <FormClass>
                <FormElements>
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label d-flex flex-align-center"></label>
                    <Input
                      style={{ width: "60%" }}
                      size="md"
                      name="maximumGap"
                      value={maximumGap}
                      type="number"
                      placeholder="Maximum gap between"
                      onChange={updateLoanProductInfoHandler}
                    />
                  </div>
                </FormElements>
              </FormClass>
            </div>
          )}
        </div>
      </div>

      <FormTitle style={{ width: "62.5%", margin: "0 auto" }} className="mt-5">
        <p className="text-info">Interest calculation</p>
        <div className="lineThrough"></div>
      </FormTitle>
      <div style={{ width: "62.5%", margin: "0 auto" }}>
        <FormClass>
          <FormElements>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label d-flex flex-align-center">
                Recalculate Interest
                {renderTooltip("Recalculate Interest")}
              </label>
              <FormControl style={{ width: "60%" }}>
                <Checkbox
                  size="md"
                  isChecked={isInterestRecalculationEnabled}
                  onChange={(e) =>
                    setNewLoanProduct((p) => ({
                      ...p,
                      isInterestRecalculationEnabled: e.target.checked,
                    }))
                  }
                />
              </FormControl>
            </div>
          </FormElements>
        </FormClass>

        {isInterestRecalculationEnabled && (
          <div>
            <FormClass>
              <FormElements>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label d-flex flex-align-center">
                    Pre-Closure Interest Calculation Rule*
                    {renderTooltip("Pre-Closure Interest Calculation Rule")}
                  </label>
                  <Select
                    size="md"
                    name="preClosureInterestCalculationStrategy"
                    value={preClosureInterestCalculationStrategy}
                    onChange={updateLoanProductInfoHandler}
                    style={{
                      borderColor:
                        !preClosureInterestCalculationStrategy &&
                        settingsError &&
                        isInterestRecalculationEnabled
                          ? "red"
                          : "inherit",
                    }}
                  >
                    <option value="">Select an option</option>
                    {template !== undefined && template
                      ? template.preClosureInterestCalculationStrategyOptions &&
                        template.preClosureInterestCalculationStrategyOptions.map(
                          (cur) => (
                            <option value={cur.id} key={cur.id}>
                              {cur.value}
                            </option>
                          )
                        )
                      : null}
                  </Select>
                </div>
              </FormElements>
            </FormClass>
            <FormClass>
              <FormElements>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label d-flex flex-align-center">
                    Advance Payments Adjustment Type*
                    {renderTooltip("Advance Payments Adjustment Type")}
                  </label>
                  <Select
                    size="md"
                    name="rescheduleStrategyMethod"
                    value={rescheduleStrategyMethod}
                    onChange={updateLoanProductInfoHandler}
                    style={{
                      borderColor:
                        !rescheduleStrategyMethod &&
                        settingsError &&
                        isInterestRecalculationEnabled
                          ? "red"
                          : "inherit",
                    }}
                  >
                    <option value="">Select an option</option>
                    {template !== undefined && template
                      ? template.rescheduleStrategyTypeOptions &&
                        template.rescheduleStrategyTypeOptions.map((cur) => (
                          <option value={cur.id} key={cur.id}>
                            {cur.value}
                          </option>
                        ))
                      : null}
                  </Select>
                </div>
              </FormElements>
            </FormClass>
            <FormClass>
              <FormElements>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label d-flex flex-align-center">
                    Interest Recalculation Compounding On*
                    {renderTooltip("Interest Recalculation Compounding On")}
                  </label>
                  <Select
                    size="md"
                    name="interestRecalculationCompoundingMethod"
                    value={interestRecalculationCompoundingMethod}
                    onChange={updateLoanProductInfoHandler}
                    style={{
                      borderColor:
                        !interestRecalculationCompoundingMethod &&
                        settingsError &&
                        isInterestRecalculationEnabled
                          ? "red"
                          : "inherit",
                    }}
                  >
                    <option value="">Select an option</option>
                    {template !== undefined && template
                      ? template.interestRecalculationCompoundingTypeOptions &&
                        template.interestRecalculationCompoundingTypeOptions.map(
                          (cur) => (
                            <option value={cur.id} key={cur.id}>
                              {cur.value}
                            </option>
                          )
                        )
                      : null}
                  </Select>
                </div>
              </FormElements>
            </FormClass>
            {interestRecalculationCompoundingMethod !== "" &&
              interestRecalculationCompoundingMethod !== "0" && (
                <div>
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label d-flex flex-align-center">
                          Frequency For Compounding*
                          {renderTooltip("Frequency For Compounding")}
                        </label>
                        <Select
                          size="md"
                          name="recalculationCompoundingFrequencyType"
                          value={recalculationCompoundingFrequencyType}
                          onChange={updateLoanProductInfoHandler}
                          style={{
                            borderColor:
                              !recalculationCompoundingFrequencyType &&
                              settingsError &&
                              isInterestRecalculationEnabled
                                ? "red"
                                : "inherit",
                          }}
                        >
                          <option value="">Select an option</option>
                          {template !== undefined && template
                            ? template.interestRecalculationFrequencyTypeOptions &&
                              template.interestRecalculationFrequencyTypeOptions.map(
                                (cur) => (
                                  <option value={cur.id} key={cur.id}>
                                    {cur.value}
                                  </option>
                                )
                              )
                            : null}
                        </Select>
                      </div>
                    </FormElements>
                  </FormClass>
                  {(recalculationCompoundingFrequencyType === "3" ||
                    recalculationCompoundingFrequencyType === "4") && (
                    <div className="row">
                      {recalculationCompoundingFrequencyType === "4" && (
                        <div className="col-md-6">
                          <Select
                            size="md"
                            name="recalculationCompoundingFrequencyNthDayType"
                            value={recalculationCompoundingFrequencyNthDayType}
                            onChange={updateLoanProductInfoHandler}
                            style={{
                              borderColor:
                                !recalculationCompoundingFrequencyNthDayType &&
                                settingsError &&
                                isInterestRecalculationEnabled
                                  ? "red"
                                  : "inherit",
                            }}
                          >
                            <option value="">Select an option</option>
                            {template !== undefined && template
                              ? template.interestRecalculationNthDayTypeOptions &&
                                template.interestRecalculationNthDayTypeOptions.map(
                                  (cur) => (
                                    <option value={cur.id} key={cur.id}>
                                      {cur.value}
                                    </option>
                                  )
                                )
                              : null}
                            <option value="-2">on day</option>
                          </Select>
                        </div>
                      )}
                      <div className="col-md-6">
                        {recalculationCompoundingFrequencyNthDayType ===
                        "-2" ? (
                          <Select
                            size="md"
                            className="mr-2"
                            name="recalculationCompoundingFrequencyOnDayType"
                            value={recalculationCompoundingFrequencyOnDayType}
                            onChange={updateLoanProductInfoHandler}
                            style={{
                              borderColor:
                                !recalculationCompoundingFrequencyOnDayType &&
                                settingsError &&
                                isInterestRecalculationEnabled
                                  ? "red"
                                  : "inherit",
                            }}
                          >
                            <option value="">Select day</option>
                            {[...Array(28)].map((_, i) => (
                              <option value={i + 1} key={i}>
                                {i + 1}
                              </option>
                            ))}
                          </Select>
                        ) : (
                          <Select
                            size="md"
                            className="mr-2"
                            name="recalculationCompoundingFrequencyDayOfWeekType"
                            value={
                              recalculationCompoundingFrequencyDayOfWeekType
                            }
                            onChange={updateLoanProductInfoHandler}
                            style={{
                              borderColor:
                                !recalculationCompoundingFrequencyDayOfWeekType &&
                                settingsError &&
                                isInterestRecalculationEnabled
                                  ? "red"
                                  : "inherit",
                            }}
                          >
                            <option value="">Select day</option>
                            {template !== undefined && template
                              ? template.interestRecalculationDayOfWeekTypeOptions &&
                                template.interestRecalculationDayOfWeekTypeOptions.map(
                                  (cur) => (
                                    <option value={cur.id} key={cur.id}>
                                      {cur.value}
                                    </option>
                                  )
                                )
                              : null}
                          </Select>
                        )}
                      </div>
                    </div>
                  )}
                  {recalculationCompoundingFrequencyType !== "" &&
                    recalculationCompoundingFrequencyType !== "1" && (
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label d-flex flex-align-center">
                              Frequency Interval For Compounding*
                              {renderTooltip(
                                "Frequency Interval For Compounding"
                              )}
                            </label>
                            <Input
                              style={{
                                width: "60%",
                                borderColor:
                                  !recalculationCompoundingFrequencyInterval &&
                                  settingsError &&
                                  isInterestRecalculationEnabled
                                    ? "red"
                                    : "inherit",
                              }}
                              size="md"
                              name="recalculationCompoundingFrequencyInterval"
                              value={recalculationCompoundingFrequencyInterval}
                              onChange={updateLoanProductInfoHandler}
                            />
                          </div>
                        </FormElements>
                      </FormClass>
                    )}
                </div>
              )}
            <FormClass>
              <FormElements>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label d-flex flex-align-center">
                    Frequency For Recalculate Outstanding Principal*
                    {renderTooltip(
                      "Frequency For Recalculate Outstanding Principal"
                    )}
                  </label>
                  <Select
                    size="md"
                    name="recalculationRestFrequencyType"
                    value={recalculationRestFrequencyType}
                    onChange={updateLoanProductInfoHandler}
                    style={{
                      borderColor:
                        !recalculationRestFrequencyType && settingsError
                          ? "red"
                          : "inherit",
                    }}
                  >
                    <option value="">Select an option</option>
                    {template !== undefined && template
                      ? template.interestRecalculationFrequencyTypeOptions &&
                        template.interestRecalculationFrequencyTypeOptions.map(
                          (cur) => (
                            <option value={cur.id} key={cur.id}>
                              {cur.value}
                            </option>
                          )
                        )
                      : null}
                  </Select>
                </div>
              </FormElements>
            </FormClass>
            {recalculationRestFrequencyType !== "" && (
              <div>
                {(recalculationRestFrequencyType === "3" ||
                  recalculationRestFrequencyType === "4") && (
                  <div className="row">
                    {recalculationRestFrequencyType === "4" && (
                      <div className="col-md-6">
                        <Select
                          size="md"
                          name="recalculationRestFrequencyNthDayType"
                          value={recalculationRestFrequencyNthDayType}
                          onChange={updateLoanProductInfoHandler}
                          style={{
                            borderColor:
                              !recalculationRestFrequencyNthDayType &&
                              settingsError &&
                              isInterestRecalculationEnabled
                                ? "red"
                                : "inherit",
                          }}
                        >
                          <option value="">Select an option</option>
                          {template !== undefined && template
                            ? template.interestRecalculationNthDayTypeOptions &&
                              template.interestRecalculationNthDayTypeOptions.map(
                                (cur) => (
                                  <option value={cur.id} key={cur.id}>
                                    {cur.value}
                                  </option>
                                )
                              )
                            : null}
                          <option value="-2">on day</option>
                        </Select>
                      </div>
                    )}
                    <div className="col-md-6">
                      {recalculationRestFrequencyNthDayType === "-2" ? (
                        <Select
                          size="md"
                          className="mr-2"
                          name="recalculationRestFrequencyOnDayType"
                          value={recalculationRestFrequencyOnDayType}
                          onChange={updateLoanProductInfoHandler}
                          style={{
                            borderColor:
                              !recalculationRestFrequencyOnDayType &&
                              settingsError &&
                              isInterestRecalculationEnabled
                                ? "red"
                                : "inherit",
                          }}
                        >
                          <option value="">Select day</option>
                          {[...Array(28)].map((_, i) => (
                            <option value={i + 1} key={i}>
                              {i + 1}
                            </option>
                          ))}
                        </Select>
                      ) : (
                        <Select
                          size="md"
                          className="mr-2"
                          name="recalculationRestFrequencyDayOfWeekType"
                          value={recalculationRestFrequencyDayOfWeekType}
                          onChange={updateLoanProductInfoHandler}
                          style={{
                            borderColor:
                              !recalculationRestFrequencyDayOfWeekType &&
                              settingsError
                                ? "red"
                                : "inherit",
                          }}
                        >
                          <option value="">Select day</option>
                          {template !== undefined && template
                            ? template.interestRecalculationDayOfWeekTypeOptions &&
                              template.interestRecalculationDayOfWeekTypeOptions.map(
                                (cur) => (
                                  <option value={cur.id} key={cur.id}>
                                    {cur.value}
                                  </option>
                                )
                              )
                            : null}
                        </Select>
                      )}
                    </div>
                  </div>
                )}
                {recalculationCompoundingFrequencyType !== "" &&
                  recalculationCompoundingFrequencyType !== "1" && (
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label d-flex flex-align-center">
                            Frequency Interval For Recalculation*
                            {renderTooltip(
                              "Frequency Interval For Recalculation"
                            )}
                          </label>
                          <Input
                            style={{
                              width: "60%",
                              borderColor:
                                !recalculationRestFrequencyInterval &&
                                settingsError &&
                                isInterestRecalculationEnabled
                                  ? "red"
                                  : "inherit",
                            }}
                            size="md"
                            name="recalculationRestFrequencyInterval"
                            value={recalculationRestFrequencyInterval}
                            onChange={updateLoanProductInfoHandler}
                          />
                        </div>
                      </FormElements>
                    </FormClass>
                  )}
              </div>
            )}
            <FormClass>
              <FormElements>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label d-flex flex-align-center">
                    Is Arrears Recognization Based On Original Schedule*
                    {renderTooltip(
                      "Is Arrears Recognization Based On Original Schedule"
                    )}
                  </label>
                  <FormControl style={{ width: "60%" }}>
                    <Checkbox
                      size="md"
                      isChecked={isArrearsBasedOnOriginalSchedule}
                      onChange={(e) =>
                        setNewLoanProduct((p) => ({
                          ...p,
                          isArrearsBasedOnOriginalSchedule: e.target.checked,
                        }))
                      }
                    />
                  </FormControl>
                </div>
              </FormElements>
            </FormClass>
          </div>
        )}
      </div>

      <FormTitle style={{ width: "62.5%", margin: "0 auto" }} className="mt-5">
        <p className="text-info">Guarantee requiremnets</p>
        <div className="lineThrough"></div>
      </FormTitle>
      <div style={{ width: "62.5%", margin: "0 auto" }}>
        <FormClass>
          <FormElements>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label d-flex flex-align-center">
                Place Guarantee Funds On Hold ?
                {renderTooltip("Place Guarantee Funds On Hold ?")}
              </label>
              <FormControl style={{ width: "60%" }}>
                <Checkbox
                  size="md"
                  name="placeGuarantee"
                  value={placeGuarantee}
                  onChange={(e) =>
                    setNewLoanProduct((p) => ({
                      ...p,
                      placeGuarantee: e.target.checked,
                    }))
                  }
                />
              </FormControl>
            </div>
          </FormElements>
        </FormClass>
        {placeGuarantee && (
          <div>
            <FormClass>
              <FormElements>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label d-flex flex-align-center">
                    Mandatory Guarantee: (%)*
                    {renderTooltip("Mandatory Guarantee: (%)")}
                  </label>
                  <Input
                    style={{ width: "60%" }}
                    size="md"
                    name="mandatoryGuarantee"
                    type="number"
                    value={mandatoryGuarantee}
                    onChange={updateLoanProductInfoHandler}
                  />
                </div>
              </FormElements>
            </FormClass>
            <FormClass>
              <FormElements>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label d-flex flex-align-center">
                    Minimum Guarantee From Own Funds: (%)
                    {renderTooltip("Minimum Guarantee From Own Funds: (%)")}
                  </label>
                  <Input
                    style={{ width: "60%" }}
                    size="md"
                    name="minimumGuaranteeFromOwnFunds"
                    type="number"
                    value={minimumGuaranteeFromOwnFunds}
                    onChange={updateLoanProductInfoHandler}
                  />
                </div>
              </FormElements>
            </FormClass>
            <FormClass>
              <FormElements>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label d-flex flex-align-center">
                    Minimum Guarantee From Guarantor Funds: (%)
                    {renderTooltip(
                      "Minimum Guarantee From Guarantor Funds: (%)"
                    )}
                  </label>
                  <Input
                    style={{ width: "60%" }}
                    size="md"
                    name="minimumGuaranteeFromGuarantor"
                    type="number"
                    value={minimumGuaranteeFromGuarantor}
                    onChange={updateLoanProductInfoHandler}
                  />
                </div>
              </FormElements>
            </FormClass>
          </div>
        )}
      </div>

      <FormTitle style={{ width: "62.5%", margin: "0 auto" }} className="mt-5">
        <p className="text-info">Loan Trancte details</p>
        <div className="lineThrough"></div>
      </FormTitle>
      <div style={{ width: "62.5%", margin: "0 auto" }}>
        <FormClass>
          <FormElements>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label d-flex flex-align-center">
                Enable Multiple Disbursals
                {renderTooltip("Enable Multiple Disbursals")}
              </label>
              <FormControl style={{ width: "60%" }}>
                <Checkbox
                  size="md"
                  isChecked={loanTrancte}
                  onChange={(e) =>
                    setNewLoanProduct((p) => ({
                      ...p,
                      loanTrancte: e.target.checked,
                    }))
                  }
                />
              </FormControl>
            </div>
          </FormElements>
        </FormClass>

        {loanTrancte && (
          <div>
            <FormClass>
              <FormElements>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label d-flex flex-align-center">
                    Maximum Tranche Count
                    {renderTooltip("Maximum Tranche Count")}
                  </label>
                  <Input
                    style={{ width: "60%" }}
                    size="md"
                    name="maxTrancheCount"
                    type="number"
                    value={maxTrancheCount}
                    onChange={updateLoanProductInfoHandler}
                  />
                </div>
              </FormElements>
            </FormClass>
            <FormClass>
              <FormElements>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label d-flex flex-align-center">
                    Maximum Allowed Outstanding Balance
                    {renderTooltip("Maximum Allowed Outstanding Balance")}
                  </label>
                  <Input
                    style={{ width: "60%" }}
                    size="md"
                    name="outstandingLoanBalance"
                    type="number"
                    value={outstandingLoanBalance}
                    onChange={updateLoanProductInfoHandler}
                  />
                </div>
              </FormElements>
            </FormClass>
          </div>
        )}
      </div>

      <FormTitle style={{ width: "62.5%", margin: "0 auto" }} className="mt-5">
        <p className="text-info">Configurable Terms and settings</p>
        <div className="lineThrough"></div>
      </FormTitle>
      <div className="row justify-content-center">
        <FormElements
          className="mr-3 d-flex align-items-center justify-content-center"
          style={{ maxWidth: 200 }}
        >
          <div className="d-flex align-items-center">
            <label className="text-label w-100 mr-2">
              Amortization {renderTooltip("Amortization")}
            </label>
            <Checkbox
              size="md"
              isChecked={amortizationType}
              onChange={(e) =>
                setNewLoanProduct((p) => ({
                  ...p,
                  amortizationType: e.target.checked,
                }))
              }
            />
          </div>
        </FormElements>

        <FormElements
          className="mr-3 d-flex align-items-center justify-content-center"
          style={{ maxWidth: 200 }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <label className="text-label w-100 mr-2 ">
              Repayment Strategy {renderTooltip("Repayment Strategy")}
            </label>
            <Checkbox
              size="md"
              isChecked={transactionProcessingStrategy}
              onChange={(e) =>
                setNewLoanProduct((p) => ({
                  ...p,
                  transactionProcessingStrategy: e.target.checked,
                }))
              }
            />
          </div>
        </FormElements>
        <FormElements
          className="mr-3 d-flex align-items-center justify-content-center"
          style={{ maxWidth: 200 }}
        >
          <div className="d-flex align-items-center">
            <label className="text-label w-100 mr-2">
              {" "}
              Arrears Tolerance {renderTooltip("Arrears Tolerance")}
            </label>
            <Checkbox
              size="md"
              isChecked={graceOnArrearsAgeing}
              onChange={(e) =>
                setNewLoanProduct((p) => ({
                  ...p,
                  graceOnArrearsAgeing: e.target.checked,
                }))
              }
            />
          </div>
        </FormElements>
        <FormElements
          className="mr-3 d-flex align-items-center justify-content-center"
          style={{ maxWidth: 200 }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <label className="text-label w-100 mr-2 ">
              Moratorium {renderTooltip("Moratorium")}
            </label>
            <Checkbox
              size="md"
              isChecked={graceOnPrincipalAndInterestPayment}
              onChange={(e) =>
                setNewLoanProduct((p) => ({
                  ...p,
                  graceOnPrincipalAndInterestPayment: e.target.checked,
                }))
              }
            />
          </div>
        </FormElements>

        <FormElements
          className="mr-3 d-flex align-items-center justify-content-center"
          style={{ maxWidth: 200 }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <label className="text-label w-100 mr-2 ">
              Interest Method {renderTooltip("Interest Method")}
            </label>
            <Checkbox
              size="md"
              isChecked={interestType}
              onChange={(e) =>
                setNewLoanProduct((p) => ({
                  ...p,
                  interestType: e.target.checked,
                }))
              }
            />
          </div>
        </FormElements>

        <FormElements
          className="mr-3 d-flex align-items-center justify-content-center"
          style={{ maxWidth: 200 }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <label className="text-label w-100 mr-2 ">
              Repaid Every {renderTooltip("Repaid Every")}
            </label>
            <Checkbox
              size="md"
              isChecked={repaymentEvery}
              onChange={(e) =>
                setNewLoanProduct((p) => ({
                  ...p,
                  repaymentEvery: e.target.checked,
                }))
              }
            />
          </div>
        </FormElements>

        <FormElements
          className="mr-3 d-flex align-items-center justify-content-center"
          style={{ maxWidth: 200 }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <label className="text-label w-100 mr-2 ">
              Interest Calculation Period
              {renderTooltip("Interest Calculation Period")}
            </label>
            <Checkbox
              size="md"
              isChecked={interestCalculationPeriodType}
              onChange={(e) =>
                setNewLoanProduct((p) => ({
                  ...p,
                  interestCalculationPeriodType: e.target.checked,
                }))
              }
            />
          </div>
        </FormElements>
        <FormElements
          className="mr-3 d-flex align-items-center justify-content-center"
          style={{ maxWidth: 270 }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <label style={{ width: "70%" }} className="text-label mr-2 ">
              Number Of Days A Loan May Be Overdue Before Moving Into Arrears
              {renderTooltip(
                "Number Of Days A Loan May Be Overdue Before Moving Into Arrears"
              )}
            </label>
            <Checkbox
              size="md"
              isChecked={inArrearsTolerance}
              onChange={(e) =>
                setNewLoanProduct((p) => ({
                  ...p,
                  inArrearsTolerance: e.target.checked,
                }))
              }
            />
          </div>
        </FormElements>
      </div>

      <div>
        <div className="float-right pt-5">
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={handlePreviousScreen}
          >
            <img
              src={
                require("../../../../../../../../assets/images/icons/arrow-left.svg")
                  .default
              }
              alt="cancel icon"
            />
            Previous
          </ActionButton>
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={() => {
              (interestCalculation !== "" || interestCalculation !== undefined || interestCalculation !== null) &&
              transactionProcessingStrategyId &&
              daysInMonth &&
              daysInyear
                ? setSettingsError(false)
                : setSettingsError(true);
              handleNextScreen();
            }}
          >
            <img
              src={
                require("../../../../../../../../assets/images/icons/arrow-right.svg")
                  .default
              }
              alt="submit icon"
            />
            Next
          </ActionButton>
        </div>
      </div>
    </>
  );
};

export default LoanSettings;
