import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Select, FormControl } from "@chakra-ui/react";
import {
  FormClass,
  FormElements,
  ActionButton,
} from "../../CustomerInformation/styles";
import {
  getLoanAccountTemplate,
  assignLoanAccountOfficer,
} from "../../../../../actions/SavingsAndLoanActions";
import Toast from "../../../../../util/Toast";
import { formatDate } from "../../../../../util/FormatDate";

const AssignStaff = ({ onHide, id, history, action, loanOfficerId }) => {
  const dispatch = useDispatch();
  const savingsAndLoan = useSelector((state) => state.savingsAndLoan);

  let { loanAccountTemplate } = savingsAndLoan || {};

  let { loanOfficerOptions = [] } = loanAccountTemplate || {};

  const [selectedStaff, setSelectedStaff] = useState("");
  const [submittedOnDate, setSubmittedOnDate] = useState();
  let newDate = new Date(submittedOnDate);

  useEffect(() => {
    dispatch(getLoanAccountTemplate(id));
  }, [id, dispatch]);

  const handleAssignLoanOfficer = () => {
    const dataToSendAssign = {
      // toSavingsOfficerId: !!fieldOfficerName ? fieldOfficerId : selectedStaff,
      assignmentDate: formatDate(newDate, true),
      dateFormat: "dd MMM yyyy",
      locale: "en",
      fromLoanOfficerId: loanOfficerId || "",
      toLoanOfficerId: selectedStaff,
    };
    dispatch(
      assignLoanAccountOfficer(
        id,
        dataToSendAssign,
        "assignloanofficer",
        history
      )
    );
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>Assign Loan Officer</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <FormElements style={{ width: "100%" }}>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Staff*</label>
              <Select
                placeholder="Select Staff"
                size="md"
                value={selectedStaff}
                onChange={(e) => {
                  e.preventDefault();
                  setSelectedStaff(e.target.value);
                }}
              >
                {!!loanOfficerOptions && loanOfficerOptions.length ? (
                  loanOfficerOptions.map(({ id, displayName }) => {
                    return (
                      <option key={id} value={id}>
                        {displayName}
                      </option>
                    );
                  })
                ) : (
                  <option>Loading</option>
                )}
              </Select>
            </div>
          </FormElements>

          <div className="form-group d-flex justify-content-between align-items-center">
            <label className="text-label">Assignment Date*</label>
            <FormControl style={{ width: "60%" }}>
              <DatePicker
                id="published-date"
                selected={submittedOnDate}
                onChange={(date) => setSubmittedOnDate(date)}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="dd/mmm/yyyy"
              />
            </FormControl>
          </div>

          <div className="float-right mt-1">
            <ActionButton color="#2C1DFF" onClick={onHide}>
              <img
                src={
                  require("../../../../../assets/images/icons/cancel.svg")
                    .default
                }
                alt="cancel icon"
              />
              Cancel
            </ActionButton>
            <ActionButton
              color="#2C1DFF"
              style={{ margin: "0px 0px 0 10px" }}
              onClick={(e) => {
                e.preventDefault();
                handleAssignLoanOfficer();
              }}
            >
              <img
                src={
                  require("../../../../../assets/images/icons/save.svg").default
                }
                alt="submit icon"
              />
              Submit
            </ActionButton>
          </div>
        </FormClass>
      </Modal.Body>
    </>
  );
};

export default AssignStaff;
