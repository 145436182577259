import React, { useEffect } from "react";
import { useSelector } from 'react-redux';
import Routes from './routes';
import config from './config';
import 'antd/dist/antd.css';
import 'react-datepicker/dist/react-datepicker.css';
import './scss/index.scss';
import BlockUI from './components/BlockUI';
import ToastManager from './components/ToastManager';

function App() {
  // let ourUrl = window.location.hostname;
  // let urlArray = ourUrl.split(".");
  // let gottenTenant = urlArray[0];
  // AuthModule.setTenant(gottenTenant);

  const {
    userData: { email, firstname },
  } = useSelector((state) => state.userData);

  useEffect(() => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = '6943ade5-a19a-482b-ae90-c3ca8dec24e8';
    (function () {
      let d = document;
      let s = d.createElement('script');
      s.src = 'https://client.crisp.chat/l.js';
      s.async = 1;
      d.getElementsByTagName('head')[0].appendChild(s);
    })();
  }, []);

  useEffect(() => {
    if (firstname && email) {
      window.$crisp.push(['set', 'user:email', email]);
      window.$crisp.push(['set', 'user:nickname', firstname]);
    }
  }, [email, firstname]);

  // useEffect(() => {
  //   if (config.apiGateway.IS_PRODUCTION === `true`) {
  //     setLoadingState(true, dispatch);
  //     if (!!gottenTenant) {
  //       setTenant(gottenTenant, dispatch);
  //       // AuthModule.setTenant("woodcore_woodcore");
  //       AuthModule.setTenant(gottenTenant);
  //       getTenantValidity(dispatch);
  //     } else {
  //       setLoadingState(true, dispatch);
  //     }
  //     if (performance.navigation.type === 1) {
  //       dispatch(logoutUser());
  //     }
  //   }
  // }, [dispatch, gottenTenant]);

  
  return config.apiGateway.IS_PRODUCTION === `true` ? (
    <div className="woodcore-dashboard">
      <ToastManager /> <BlockUI />
      <Routes />
    </div>
  ) : (
    <div className="woodcore-dashboard">
      <ToastManager /> <BlockUI />
      <Routes />
    </div>
  );
}

export default App;
