import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { Select } from "@chakra-ui/react";
import { FormClass, FormElements, ActionButton } from "../../styles.jsx";
import {
  getCustomerStaff,
  assignStaffToCustomer,
} from "../../../../../../actions/SavingsAndLoanActions";
import Toast from "../../../../../../util/Toast";

const AssignStaff = ({ onHide, userData, history }) => {
  const dispatch = useDispatch();
  const savingsAndLoan = useSelector((state) => state.savingsAndLoan);

  let { customerOptions = {} } = savingsAndLoan || {};

  let { staffOptions = [] } = customerOptions;

  const [selectedStaff, setSelectedStaff] = useState("");

  let { staffName = "", id, staffId } = userData || {};

  const handleStaffAssign = () => {
    if (!staffName && !selectedStaff.length) {
      dispatch(
        Toast({ text: "Please select a staff to assign", icon: "info" })
      );
    } else {
      const dataToSend = {
        staffId: !!staffName ? staffId : selectedStaff,
      };
      dispatch(
        assignStaffToCustomer(
          id,
          dataToSend,
          !staffName ? "assignStaff" : "unassignstaff",
          history
        )
      );
      onHide();
    }
  };

  useEffect(() => {
    if (!staffOptions.length) {
      dispatch(getCustomerStaff(id));
    }
  }, [id, dispatch]);

  return (
    <>
      <Modal.Header>
        <Modal.Title>{!staffName ? "Assign" : "UnAssign"} Staff</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          {!staffName ? (
            <FormElements style={{ width: "100%" }}>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Staff*</label>
                <Select
                  placeholder="Select Staff"
                  size="md"
                  value={selectedStaff}
                  onChange={(e) => {
                    e.preventDefault();
                    setSelectedStaff(e.target.value);
                  }}
                >
                  {!!staffOptions && staffOptions.length ? (
                    staffOptions.map(({ id, displayName }) => {
                      return (
                        <option key={id} value={id}>
                          {displayName}
                        </option>
                      );
                    })
                  ) : (
                    <option>Loading</option>
                  )}
                </Select>
              </div>
            </FormElements>
          ) : (
            <h1 style={{ fontSize: "20px", margin: "20px 0px" }}>
              Unassign {staffName} from Customer
            </h1>
          )}

          <div className="float-right mt-1">
            <ActionButton color="#2C1DFF" onClick={onHide}>
              <img
                src={
                  require("../../../../../../assets/images/icons/cancel.svg")
                    .default
                }
                alt="cancel icon"
              />
              Cancel
            </ActionButton>
            <ActionButton
              color="#2C1DFF"
              style={{ margin: "0px 0px 0 10px" }}
              onClick={(e) => {
                e.preventDefault();
                handleStaffAssign();
              }}
            >
              <img
                src={
                  require("../../../../../../assets/images/icons/save.svg")
                    .default
                }
                alt="submit icon"
              />
              Submit
            </ActionButton>
          </div>
        </FormClass>
      </Modal.Body>
    </>
  );
};

export default AssignStaff;
