/* eslint-disable import/no-anonymous-default-export */
import axiosData from '../axiosData';
import config from '../../config';

const getAllClientTiers = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/clienttiers`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
const createNewClientTier = (dataToSend) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'POST',
        url: `${config.apiGateway.BASE_API_URL}/clienttiers`,
        data: dataToSend,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
const editClientTier = (dataToSend, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'PUT',
        url: `${config.apiGateway.BASE_API_URL}/clienttiers/${id}`,
        data: dataToSend,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
const deleteClientTier = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'DELETE',
        url: `${config.apiGateway.BASE_API_URL}/clienttiers/${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export default {
  getAllClientTiers,
  createNewClientTier,
  editClientTier,
  deleteClientTier,
};
