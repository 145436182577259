import React, { useState, useEffect } from "react";
// import { Divider } from "antd";

import { ContentWrapper } from "./styles";

import ClientApproval from "./components/ClientApproval";
import LoanApproval from "./components/LoanApproval";

import RightArrowActive from "../../../../assets/images/icons/checkers-active.svg";
import RightArrow from "../../../../assets/images/icons/checkers-inactive.svg";
import { useDispatch } from "react-redux";
import {
  clearFilteredClients,
  clearFilteredLoans,
  getAllClients,
  getAllLoans,
  getAllMakerCheckers,
  // getAllMakerCheckersTemplate,
  // getAllPendingRescheduleLoans,
} from "../../../../actions/businessActions";

const Checkers = () => {
  const [activeTab, setActiveTab] = useState("clientApproval");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearFilteredClients());
    dispatch(clearFilteredLoans());
    dispatch(getAllClients());
    dispatch(getAllLoans());
    dispatch(getAllMakerCheckers());
    // dispatch(getAllMakerCheckersTemplate());
    // dispatch(getAllPendingRescheduleLoans());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let sideBars = [
    {
      name: "Client Activation",
      key: "clientApproval",
    },
    {
      name: "Loan Approval",
      key: "loanApproval",
    },
  ];

  const handleCurrentView = () => {
    switch (activeTab) {
      case "clientApproval":
        return <ClientApproval />;
      case "loanApproval":
        return <LoanApproval />;
      default:
        return "";
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Bulk Actions</h3>
            <p className="sub-title">
              An overview of maker actions awaiting approval
            </p>
          </div>
        </div>
        <ContentWrapper>
          <div className="row">
            <div className="col-md-3">
              {sideBars.map(({ key, name }) => {
                return (
                  <>
                    <div
                      className={`checker-tab ${
                        key === activeTab ? "cherker-active" : ""
                      }`}
                      key={key}
                      onClick={() => {
                        setActiveTab(key);
                      }}
                    >
                      {name}{" "}
                      <img
                        src={key === activeTab ? RightArrowActive : RightArrow}
                        alt=""
                      />
                    </div>
                  </>
                );
              })}
            </div>
            {/* <Divider /> */}
            <div className="col-md-9">{handleCurrentView()}</div>
          </div>
        </ContentWrapper>
      </div>
    </>
  );
};

export default Checkers;
