import React, { useState } from "react";
import { Select } from "@chakra-ui/react";
import { Modal, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { FormClass, SubmitActionButton, NoteFormElements, CancelActionButton } from "../../../styles";
import { handleEventUpdate } from "../../../../../../../actions/SavingsAndLoanActions";

const EditNotificationModal = ({ onHide, notificationData, allEventOptions, savingsId }) => {
  const dispatch = useDispatch();

  const [editNotification, setEditNotification] = useState({
    codeName: notificationData?.notification?.codeName || "",
    emailActive: notificationData?.notification?.emailActive || false,
    chargeApplied: notificationData?.notification?.chargeApplied || false,
  });
  const [loading, setLoading] = useState(false);
  const { codeName, emailActive } =
    editNotification;
  const editNotificationHandler = (e) =>
    setEditNotification({ ...editNotification, [e.target.name]: e.target.value });

  const handleSubmit = async (e) => {
    e.preventDefault();
      setLoading(true);
      const data = {
        eventId: allEventOptions.selectedEventOptions.find((e) => e.codeName === codeName).id,
        emailActive: emailActive === 'true' ? true : emailActive === true ? true : false,
        savingsAccountId: savingsId,
      };
      dispatch(handleEventUpdate(savingsId, data));
      onHide();
      setLoading(false);
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>Edit new event</Modal.Title>
      </Modal.Header>
      <Modal.Body className="wc-modal pt-0">
        <div className="d-flex align-items-center justify-content-center flex-direction-coloumn">
          <FormClass style={{ width: "90%" }}>
            <NoteFormElements style={{ width: "100%" }}>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Event name*</label>
                <Select
                    placeholder="Select event"
                    size="md"
                    name="codeName"
                    disabled
                    value={codeName}
                    onChange={editNotificationHandler}
                  >
                    {allEventOptions !== undefined && allEventOptions
                      ? allEventOptions.selectedEventOptions &&
                        allEventOptions.selectedEventOptions.map((off) => (
                          <option value={off.codeName} key={off.id}>
                            {off.codeName}
                          </option>
                        ))
                      : null}
                  </Select>
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Email Status*</label>
                <Select
                  placeholder="Select status"
                  size="md"
                  name="emailActive"
                  value={emailActive}
                  onChange={editNotificationHandler}
                >
                  <option value="true">Enabled</option>
                  <option value="false">Disabled</option>
                </Select>
              </div>
            </NoteFormElements>
          </FormClass>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-1">
          <CancelActionButton color="#FFFFFF" onClick={onHide}>
            Cancel
          </CancelActionButton>
          <SubmitActionButton
            onClick={handleSubmit}
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            disabled={loading}
          >
            {loading ? (
              <Spinner
                animation="border"
                color="#fff"
                className="mr-2 text-white"
                size="sm"
              />
            ) : (
              <img
                src={
                  require("../../../../../../../assets/images/icons/new-submit.svg").default
                }
                alt="submit icon"
              />
            )}
            Submit
          </SubmitActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default EditNotificationModal;
