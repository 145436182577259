import React from "react";
import { NewActionButton, FormClass, ModalText } from "../styles";
import { Modal } from "react-bootstrap";

const DeleteApiKey = ({ onHide, action, header, desc }) => {
  return (
    <>
      <Modal.Header>
        <Modal.Title>{header}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "95%" }}>
          <ModalText>{desc}</ModalText>
      
        </FormClass>
      </Modal.Body>
      <Modal.Footer>
      <div className="float-right mt-1">
            <NewActionButton color="#2C1DFF" onClick={onHide}>
              <img
                src={
                  require("../../../../../assets/images/icons/cancel.svg")
                    .default
                }
                className="icon-img"

                alt="cancel icon"
              />
              Cancel
            </NewActionButton>
            <NewActionButton
              color="#2C1DFF"
              onClick={() => action()}
              style={{ margin: "0px 0px 0 10px" }}
            >
              Confirm
            </NewActionButton>
          </div>
      </Modal.Footer>
    </>
  );
};

export default DeleteApiKey;
