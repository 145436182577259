import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Select, Tag, Checkbox } from "antd";
import DatePicker from "react-datepicker";
import { useDispatch } from "react-redux";
import { formatDateString } from "../../../../../util/FormatDate";
import { NewActionButton } from "../../styles";
import { getAllLoanAccounts } from "../../../../../actions/accountActions";

const FilterModal = ({ onHide, setViewState, template }) => {
  // let options = [{ value: "woodCore Loan" }, { value: "agric Loan" }];

  const options = [];
  const dispatch = useDispatch();
  template && template.forEach((opt) => options.push({ value: opt }));

  const tagRender = (props) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color="blue"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  const [myStartDate, setStartDate] = useState("");
  const [myEndDate, setEndDate] = useState("");
  const [loanProducts, setLoanProducts] = useState([]);
  const [checkBoxes, setCheckBoxes] = useState({
    activeLoans: false,
    loansRepaid: false,
    // overdue: false,
    loansUnpaid: false,
  });
  const onChange = (e) => {
    setCheckBoxes((p) => ({ ...p, [e.target.name]: e.target.checked }));
  };
  // console.log(loanProducts);
  const handleFilter = (e) => {
    const startDate = myStartDate
      ? formatDateString(myStartDate, "dd/MMM/yyyy")
      : "";
    const endDate = myEndDate ? formatDateString(myEndDate, "dd/MMM/yyyy") : "";

    e.preventDefault();
    dispatch(
      getAllLoanAccounts(
        "sortOrder=DESC",
        loanProducts.length ? loanProducts : null,
        checkBoxes.activeLoans,
        checkBoxes.loansUnpaid,
        checkBoxes.loansRepaid,
        startDate ? startDate : "",
        endDate ? endDate : ""
        // checkBoxes.due,
      )
    );
    setViewState(true);
    onHide();
  };
  const handleClearFilter = () => {
    setStartDate("");
    setEndDate("");
    setLoanProducts([]);
    setCheckBoxes({
      active: false,
      repaid: false,
      overdue: false,
      due: false,
    });
  };

  return (
    <>
      <Modal.Body className="wc-modal">
        <div className=" my-3">
          <div className="row ml-1" style={{minWidth: "250px", width:"fit-content"}}>
            <Select
              mode="multiple"
              onChange={(e) => setLoanProducts(e)}
              showArrow
              value={loanProducts}
              tagRender={tagRender}
              placeholder="Select Loan Product"

              options={options}
            />
          </div>
          <div className="border-line my-3"></div>
          <div className="row mt-2">
            <div className="col-3">
              <p className="text-label">Filter Options</p>
            </div>
            <div className="col-9">
              <div className="row">
                <div className="col">
                  <Checkbox
                    name="activeLoans"
                    checked={checkBoxes.activeLoans}
                    onChange={onChange}
                  >
                    <p className="text-label">Active Loans </p>
                  </Checkbox>
                </div>
                <div className="col">
                  <Checkbox
                    name="loansRepaid"
                    checked={checkBoxes.loansRepaid}
                    onChange={onChange}
                  >
                    <p className="text-label">Loans repaid</p>
                  </Checkbox>
                </div>
                <div className="col">
                  <Checkbox
                    name="loansUnpaid"
                    checked={checkBoxes.loansUnpaid}
                    onChange={onChange}
                  >
                    <p className="text-label">Loans repayment due</p>
                  </Checkbox>
                </div>
              </div>
            </div>
          </div>
          <div className="border-line my-3"></div>
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                <div className="col-3 text-label">Start Date</div>
                <div className="col-9">
                  <div className="datepicker-100-width">
                    <DatePicker
                      id="from-date"
                      selected={myStartDate}
                      onChange={(date) => setStartDate(date)}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="start date"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-3 text-label">End Date</div>
                <div className="col-9">
                  <div className="datepicker-100-width">
                    <DatePicker
                      id="from-date"
                      selected={myEndDate}
                      onChange={(date) => setEndDate(date)}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="start date"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border-line my-3"></div>
          <div className="mt-1">
            <div className="d-flex justify-content-between">
              <div className="">
                <p onClick={handleClearFilter} className="pointer" style={{ color: "red", fontSize: "15px", cursor: "pointer" , textDecoration: "underline"}}>
                  Clear all filters
                </p>
              </div>
              <div className="d-flex">
                <NewActionButton onClick={onHide}>Cancel </NewActionButton>
                <NewActionButton onClick={handleFilter}>Submit </NewActionButton>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default FilterModal;
