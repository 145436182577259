/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { useParams } from "react-router-dom";
import { getSingleAudit } from "../../../../actions/configurationActions";
import { TD } from "./styles";
import { formatCamelCaseText } from "../../../../util/FormatText";
import TableLoaderSingle from "./TableLoaderSingle";

import { useSelector, useDispatch } from "react-redux";

const savingsColumns = [
  {
    title: "",
    dataIndex: "key",
  },
  {
    title: "Amount",
    dataIndex: "amount",
  },
  {
    title: "Charge ID",
    dataIndex: "chargeId",
  },
  {
    title: "Due Date",
    dataIndex: "dueDate",
  },
  {
    title: "Fee Interval",
    dataIndex: "feeInterval",
  },
  {
    title: "Fee On Month Day",
    dataIndex: "feeOnMonthDay",
  },
];

const AuditTrailDetails = ({ history, setCurrentView, additionalData }) => {
  const { singleAudit, loading } = useSelector((state) => state.singleAudit);
  let { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    // if (id) {
    //   dispatch(getSingleAudit(id));
    // }
    if (additionalData) {
      dispatch(getSingleAudit(additionalData));
    }
  }, [dispatch, additionalData]);

  const [data, setData] = useState();

  // console.log(additionalData, "additionalData inside comp 2");

  let savingsCharges = [];

  data?.charges?.forEach((each, idx) => {
    let { amount, chargeId, dueDate, feeOnMonthDay, feeInterval } = each;
    savingsCharges.push({
      key: idx + 1,
      amount: amount || "--------",
      chargeId: chargeId || "--------",
      dueDate: dueDate || "--------",
      feeOnMonthDay: feeOnMonthDay || "--------",
      feeInterval: feeInterval || "--------",
    });
  });

  useEffect(() => {
    const details = singleAudit?.commandAsJson;
    if (details) {
      const res = JSON?.parse(details);
      setData(res);
    }
  }, [singleAudit?.commandAsJson]);

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-2 mb-4">
            <h3 className="page-title">Action Details</h3>
            <p className="sub-title">An overview of maker actions details</p>
          </div>
        </div>
        <div className="mb-4 mt-2 d-flex row">
          <div className="col-md-5 d-flex align-items-center">
            <div>
              <button
                style={{
                  width: "80px",
                  color: "#fff",
                  borderRadius: "4px",
                  padding: "4px 2px",
                  backgroundColor: "blue",
                }}
                // onClick={() => history.push('/core/audit-trail')}
                onClick={() => setCurrentView(1)}
              >
                Back
              </button>
            </div>
          </div>
        </div>
        {loading ? (
          <TableLoaderSingle />
        ) : (
          <div className="">
            <div
              style={{
                marginBottom: "25px",
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                borderBottom: "1px solid #3833331f",
                paddingBottom: "25px",
              }}
            >
              <table>
                {singleAudit?.id && (
                  <tr>
                    <td style={{ width: "150px", fontWeight: 600 }}>ID</td>
                    <td>{singleAudit.id}</td>
                  </tr>
                )}
                {singleAudit?.processingResult && (
                  <tr>
                    <td style={{ width: "150px", fontWeight: 600 }}>Status</td>
                    <td>{singleAudit?.processingResult}</td>
                  </tr>
                )}
                {singleAudit?.maker && (
                  <tr>
                    <td style={{ width: "150px", fontWeight: 600 }}>Maker</td>
                    <td>{singleAudit?.maker}</td>
                  </tr>
                )}
                {singleAudit?.clientName && (
                  <tr>
                    <td style={{ width: "150px", fontWeight: 600 }}>User</td>
                    <td>{singleAudit?.clientName}</td>
                  </tr>
                )}
              </table>
              <table>
                {singleAudit?.actionName && (
                  <tr>
                    <td style={{ width: "150px", fontWeight: 600 }}>Action</td>
                    <td>{singleAudit?.actionName}</td>
                  </tr>
                )}
                {singleAudit?.entityName && (
                  <tr>
                    <td style={{ width: "150px", fontWeight: 600 }}>Entity</td>
                    <td>{singleAudit?.entityName}</td>
                  </tr>
                )}
                {singleAudit?.clientId && (
                  <tr>
                    <td style={{ width: "150px", fontWeight: 600 }}>
                      Client ID
                    </td>
                    <td>{singleAudit?.clientId}</td>
                  </tr>
                )}
                {singleAudit?.resourceId && (
                  <tr>
                    <td style={{ width: "150px", fontWeight: 600 }}>
                      Resource ID
                    </td>
                    <td>{singleAudit?.resourceId}</td>
                  </tr>
                )}
              </table>
              <table>
                {singleAudit?.resourceId?.date && (
                  <tr>
                    <td style={{ width: "150px", fontWeight: 600 }}>Date</td>
                    <td>maker</td>
                  </tr>
                )}
                {singleAudit?.officeName && (
                  <tr>
                    <TD style={{ width: "150px" }}>Office</TD>
                    <td>{singleAudit?.officeName}</td>
                  </tr>
                )}
                {singleAudit?.savingsAccountNo && (
                  <tr>
                    <td style={{ width: "150px", fontWeight: 600 }}>
                      Savings Account No
                    </td>
                    <td>{singleAudit?.savingsAccountNo}</td>
                  </tr>
                )}
                {singleAudit?.loanAccountNo && (
                  <tr>
                    <td style={{ width: "150px", fontWeight: 600 }}>
                      Loan Account No
                    </td>
                    <td>{singleAudit?.loanAccountNo}</td>
                  </tr>
                )}
                {singleAudit?.loanId && (
                  <tr>
                    <td style={{ width: "150px", fontWeight: 600 }}>Loan ID</td>
                    <td>{singleAudit?.loanId}</td>
                  </tr>
                )}
                {singleAudit?.madeOnDate && (
                  <tr>
                    <td style={{ width: "150px", fontWeight: 600 }}>
                      Made On Date
                    </td>
                    <td>{singleAudit?.madeOnDate}</td>
                  </tr>
                )}
                {singleAudit?.subresourceId && (
                  <tr>
                    <td style={{ width: "150px", fontWeight: 600 }}>
                      Sub Resource ID
                    </td>
                    <td>{singleAudit?.subresourceId}</td>
                  </tr>
                )}
              </table>
            </div>

            <div>
              <table
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                }}
              >
                {data &&
                  Object?.entries(data).map((each) => {
                    if (typeof each[1] == "object" || !each[1]) return;
                    return (
                      <div>
                        <tr>
                          <TD style={{ width: "250px" }}>
                            {(typeof each[0] === "string" ||
                              typeof each[0] === "number") &&
                            typeof each[1]
                              ? formatCamelCaseText(each[0])
                              : ""}
                          </TD>
                          <td>
                            {typeof each[1] == "boolean"
                              ? JSON.stringify(each[1])
                              : typeof each[1] == "string" ||
                                typeof each[1] == "number"
                              ? each[1]
                              : ""}
                          </td>
                        </tr>
                      </div>
                    );
                  })}
              </table>
              <div style={{ marginTop: "20px" }}>
                {data &&
                  Object?.entries(data).map((each) => {
                    if (typeof each[1] !== "object") return;
                    // console.log(each[1], "kkk");
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flexStart",
                          marginBottom: "10px",
                        }}
                      >
                        <TD style={{ padding: "10px", paddingTop: "0px" }}>
                          {(typeof each[0] !== "string" ||
                            typeof each[0] !== "number") &&
                          each[1]
                            ? formatCamelCaseText(each[0])
                            : ""}
                        </TD>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            lineHeight: "22px",
                          }}
                        >
                          {Object.entries(each[1])?.map((eachVal) => {
                            if (typeof eachVal[1] == "object") return;
                            return (
                              <p
                                style={{
                                  fontSize: "12px",
                                  fontFamily: "AVENTAREGULAR",
                                  marginRight: "15px",
                                  color: "#333333",
                                }}
                              >
                                {eachVal[0]}: {JSON.stringify(eachVal[1])}
                              </p>
                            );
                          })}
                        </div>
                        <div>
                          {Object.entries(each[1])?.map((eachVal) =>
                            Object.entries(eachVal[1])?.map((eachNewVal) => {
                              return (
                                <span
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: "AVENTAREGULAR",
                                    marginRight: "15px",
                                    color: "#333333",
                                  }}
                                >
                                  {eachNewVal[0]}: {eachNewVal[1]}
                                </span>
                              );
                            })
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>

            {savingsCharges.length ? (
              <Table
                pagination={false}
                onRow={(r) => ({
                  onClick: (e) => {
                    // console.log(r);
                  },
                })}
                columns={savingsColumns}
                dataSource={savingsCharges}
              />
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default AuditTrailDetails;
