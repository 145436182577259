import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  Alert,
  OverlayTrigger,
  Tooltip,
  Modal,
  Button,
} from "react-bootstrap";
import { Input, Select } from "@chakra-ui/react";
import {
  FormTitle,
  FormSubTitle,
  FormInput,
  FormButton,
  FormClass,
  FormElements,
  ActionButton,
  LineThrough,
  IconButton,
  ContentWrapper,
} from "../styles.jsx";
import DashboardLayout from "../../../../../layouts/DashboardLayout/index.jsx";

const ApplicationFee = () => {
  return (
    <>
      <>
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-5 mt-1">
            <h3 className="page-title">Mark Peters</h3>
            <p className="text-muted sub-title">Customer overall details</p>
            <p className="font-weight sub-title mt-2">
              <span className="text-info">
                <Link to="/core/customer">Customer</Link>
              </span>{" "}
              /{" "}
              <span className="text-info">
                <Link to="/core/customer/information">Mark Peter</Link>
              </span>
              / Application fee
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <ContentWrapper>
              <div className="container py-5">
                <div className="row">
                  <div className="col-sm-12 col-md-7">
                    <h3 className="page-title">Application Fee</h3>
                  </div>
                  <div className="col-sm-12 col-md-5 d-flex justify-content-around">
                    <ActionButton color="#2C1DFF">
                      <img
                        src={
                          require("../../../../../assets/images/icons/make-repayment.svg")
                            .default
                        }
                        className="mr-2"
                        alt="add"
                      />
                      Pay Charge
                    </ActionButton>
                    <ActionButton color="#2C1DFF">
                      <img
                        src={
                          require("../../../../../assets/images/icons/invoice.svg")
                            .default
                        }
                        className="mr-2"
                        alt="add"
                      />
                      Waive Charge
                    </ActionButton>
                    <ActionButton color="#2C1DFF">
                      <img
                        src={
                          require("../../../../../assets/images/icons/bin.svg")
                            .default
                        }
                        className="mr-2"
                        alt="add"
                      />
                      Delete Charge
                    </ActionButton>
                  </div>
                </div>
                <br />

                <Table
                  hover={false}
                  bordered
                  striped
                  style={{ maxWidth: "750px", width: "95%" }}
                  className="mt-5"
                >
                  <thead
                    className="border-bottom"
                    style={{ background: "#F7F8FC" }}
                  >
                    <tr>
                      <th>Period Type</th>
                      <th>Period From/To</th>
                    </tr>
                  </thead>
                  <tbody id="interest-rate-body">
                    <tr>
                      <td>Currency</td>
                      <td>Naira (NGN)</td>
                    </tr>
                    <tr>
                      <td>Charge Time Type</td>
                      <td>Specified due date</td>
                    </tr>
                    <tr>
                      <td>Charge Calculation Type</td>
                      <td>Flat</td>
                    </tr>
                    <tr>
                      <td>Due as of</td>
                      <td>11 December 2017</td>
                    </tr>
                    <tr>
                      <td>Due</td>
                      <td>100</td>
                    </tr>
                    <tr>
                      <td>Paid</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td>Waived</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td>Outstanding</td>
                      <td>100</td>
                    </tr>
                  </tbody>
                </Table>

                <Table
                  hover={false}
                  borderless
                  style={{ textAlign: "center", marginTop: "7rem" }}
                >
                  <thead style={{ background: "#EDEFF5" }}>
                    <tr>
                      <th>ID</th>
                      <th>Office Name</th>
                      <th>Type</th>
                      <th>Transaction Date</th>
                      <th>Amount</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>12132323232</td>
                      <td>WoodCore Global</td>
                      <td>Pay Charge</td>
                      <td>20/04/2020</td>
                      <td>400</td>
                      <td></td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </ContentWrapper>
          </div>
        </div>
      </>
    </>
  );
};

const RenderTooltip = ({ text, id, children }) => (
  <>
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={`tooltip-${id}`}>{text}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  </>
);

export default ApplicationFee;
