import React, { useState, useEffect } from "react";
import styled from "styled-components";
import TellerGroupCard from "../components/TellerGroupCard";
import Button from "../../../../components/Button/index";
import { useDispatch, useSelector } from "react-redux";
import { Image } from "@chakra-ui/react";
import { getAllTellerGroups, getAllOffices } from "../../../../actions/tellerActions";
import { getAllAccountRules } from "../../../../actions/accountingActions";
import AddTellerGroupForm from "./AddTellerGroupForm";
import AddTellerGroupFormModal from "./AddTellerGroupFormModal";
import CheckPermission from "../../../../util/CheckPermission";
import ModalContainer from "../../components/ModalContainer";
import { ContentWrapper, ActionButton, TabButton } from "./styles";

const TellerGroup = ({ history }) => {
  const [addTellerGroup, setAddTellerGroup] = useState(false);
  const dispatch = useDispatch();
  const tellerGroupReducer = useSelector((state) => state.tellerReducer);
  const accountingData = useSelector((state) => state.accountingData);
  const { allTellerGroups, allOffice } = tellerGroupReducer;
  const { isLoading, accountRules } = accountingData;
  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  const resetAddTellerGroup = () => {
    setAddTellerGroup(false);
  };

  useEffect(() => {
    if (CheckPermission("READ_TELLER", permissions)) {
      dispatch(getAllTellerGroups());
      dispatch(getAllAccountRules());
      dispatch(getAllOffices());
    }
  }, [dispatch]);


  const [showModal, setShowModal] = useState(false);

  const handleModalClose = () => setShowModal(false);

  const openModal = () => {
    setShowModal(prev => !prev);
  };


  return (
    <>
    <div className="container-fluid">
      <div className="row justify-content-between">
        <div className="col-sm-12 col-md-12 col-lg-6 pb-5">
          <h3 className="page-title">Teller Management</h3>
          <p className="text-muted sub-title">
            A complete overview of Teller Groups
          </p>
        </div>
      </div>
       {CheckPermission("READ_TELLER", permissions) && (
        <Container className="pt-3 pb-3">
        <div className="d-flex justify-content-end mb-4 mr-3">
            {CheckPermission("CREATE_TELLER", permissions) && (
              <Button
                variant="secondary"
                // onClick={() => setAddTellerGroup(!addTellerGroup)}
                onClick={openModal}
                icon={
                  require("../../../../assets/images/icons/add-group.svg")
                    .default
                }
              >
                New Group
              </Button>
            )}
            
        
        
          {CheckPermission("READ_CASHIERREQUEST", permissions) && (
            <Button
              onClick={() => history.push("/core/teller-request")}
              icon={
                require("../../../../assets/images/icons/bell.svg").default
              }
            >
              Teller requests
            </Button>
          )}
        </div>
  
        <ContentContainer addTeller={addTellerGroup} className="m-3">
          {!allTellerGroups?.length ? (
            <EmptyPage>
              {/* <Image
                boxSize="50px"
                objectFit="cover"
                src={require("../../../../assets/images/empty.svg").default}
                alt="Dan Abramov"
              /> */}
              <p className="empty-page-text-style">You don’t have any Teller group yet.</p>
              <p className="empty-page-text-style">Create one now</p>
              {!!allTellerGroups?.length}
            </EmptyPage>
          ) : (
            <div className="card-container">
              {allTellerGroups?.map((each, idx) => {
                return (
                  <>
                    <TellerGroupCard
                      idx={idx + 1}
                      data={each}
                      key={each?.id}
                      accountRules={accountRules}
                      allOffice = {allOffice}
                    />
                  </>
                );
              })}
            </div>
          )}
  
        <AddTellerGroupFormModal 
        showModal={showModal}
        setShowModal={setShowModal}
        onHide={handleModalClose}
        resetAddTellerGroup={resetAddTellerGroup}
        addTellerGroup={addTellerGroup}
        accountRules = {accountRules}
            />
          {/* {addTellerGroup ? (
            <AddTellerGroupForm
              resetAddTellerGroup={resetAddTellerGroup}
              addTellerGroup={addTellerGroup}
            />
          ) : (
            ""
          )} */}

        </ContentContainer>
      </Container>
    )}
      
    </div>
    </>
  );
};

export default TellerGroup;

const EmptyPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    margin-bottom: 40px;
  }

  p {
    font-size: 15px;
    color: #969cba;
  }
`;

const Container = styled.div`
  background-color: #fff;
  border-radius: 5px;
  min-height: 70vh;

  .card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    height: min-content;
    place-items: center;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
`;

const ContentContainer = styled.div`
  display: ${(props) => (props.addTeller === true ? "grid" : "")} !important;
  grid-template-columns: 5fr 3fr;

  .add-teller-group {
    height: 100%;
    background: #ffffff;
    border-radius: 10px 10px 0px 10px;
    z-index: 10;
  }
`;
