import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import jwtDecode from "jwt-decode";

import { Modal } from 'react-bootstrap';
import AuthModule from '../../modules/Auth.module';
import {
  setLoadingState,
  setTenant,
} from '../../actions/preAuthActions';

import config from '../../config';
import Toast from '../../util/Toast';
import {
  InputGroup,
  Input,
  InputRightElement
} from '@chakra-ui/react';
import {
  FormSubTitile,
  FormButton,
} from './styles';
import AxiosData from '../../services/axiosData.js';
import AuthLayout from '../../layouts/AuthLayout';
import { logoutUser } from '../../actions/authActions';

const ResetPassword = ({ history }) => {
  const preAuth = useSelector((state) => state.preAuth);
  const { loading } = preAuth

  const dispatch = useDispatch();
  const { token } = useParams();

  const [tenant, setTenant] = useState(null);
  const [userId, setUserId] = useState(null);
  const [resetToken, setResetToken] = useState(null);
  const [authToken, setAuthToken] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [isNewUser] = useState(window.location.pathname.includes('setup-password'));

  let resetData = {
    password,
    repeatPassword,
    resetToken
  };

  const decodeToken = () => {
    const decoded = jwtDecode(token);
    setUserId(decoded?.sub)
    const subToken = decoded?.token?.split('|');
    setTenant(subToken[0])
    setResetToken(subToken[1])
    setAuthToken(subToken[2])
  }

  const resetPassword = () => {
    setLoadingState(true, dispatch);
    AuthModule.setTenant(tenant)
    AuthModule.authenticateUser(authToken)
    AxiosData({
      method: "PUT",
      data: resetData,
      url: `${config.apiGateway.BASE_API_URL}/users/${userId}/reset-password`,
    }).then((response) => {
      if (response?.data?.resourceId) {
        dispatch(
          Toast({
            text: `Password ${isNewUser ? 'setup' : 'reset'} successful!`,
            icon: 'success',
          })
        );
        setTimeout(() => history.push('/'), 1000);
      } else if (response?.error) {
        dispatch(
          Toast({
            text: String(response?.error).includes('network error')
              ? String(response?.error)
              : `Password ${isNewUser ? 'setup' : 'reset'} link is invalid or has expired`,
            icon: "error",
          })
        );
      }
      setLoadingState(false, dispatch);
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (
      !!resetToken &&
      !!password &&
      !!repeatPassword &&
      (password === repeatPassword)
    ) {
      resetPassword();
    } else if (password !== repeatPassword) {
      dispatch(
        Toast({
          text: "The passwords you provided don't match",
          icon: 'error',
        })
      );
    } else {
      dispatch(
        Toast({
          text: 'An error occurred! :(',
          icon: 'error',
        })
      );
    }
  };
  
  useEffect(() => {
    dispatch(logoutUser());
    decodeToken();
  }, [token]);

  return (
    <>
      {loading && <LogoModal show={loading} onHide={() => null} />}

      <AuthLayout>
        <div style={{ width: '23.313rem' }}>
          <FormSubTitile>
            <span className='login-mainlogo'>
              {' '}
              <img
                src={require('../../assets/logo/mainlogo.svg').default}
                alt=''
              />
            </span>
            {`${isNewUser ? 'Set your new' : 'Reset your'} password`}
          </FormSubTitile>
          <form id='auth-form' onSubmit={submitHandler}>
            <div className='form-label-group mb-4'>
              <label className='login-label'>Password</label>
              <InputGroup size='md'>
                <Input
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  pr='4.5rem'
                  type={showPassword ? 'text' : 'password'}
                  style={{
                    background: '#ffffff',
                    height: '45px !important',
                  }}
                />
                <InputRightElement width='4.5rem' height='44px'>
                  <svg
                    onClick={() => setShowPassword(!showPassword)}
                    width='17'
                    height='12'
                    viewBox='0 0 17 12'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M14.1425 2.6802C15.2613 3.51 16.2441 4.6166 17 6C14.5523 10.4797 9.72559 12.0569 5.60275 10.7316L7.32384 9.10889C7.69143 9.23251 8.08738 9.29983 8.5 9.29983C10.433 9.29983 12 7.82245 12 6C12 5.61098 11.9286 5.23768 11.7975 4.89111L14.1425 2.6802ZM11.3972 1.26845L9.67616 2.89111C9.30857 2.76749 8.91262 2.70017 8.5 2.70017C6.567 2.70017 5 4.17755 5 6C5 6.38902 5.0714 6.76233 5.20252 7.10889L2.8575 9.3198C1.73865 8.49 0.755893 7.38341 0 6C2.44773 1.52026 7.27441 -0.0569261 11.3972 1.26845ZM2.13604 11.3333L14.1569 0L14.864 0.666667L2.84315 12L2.13604 11.3333Z'
                      fill='#CAC7C7'
                    />
                  </svg>
                </InputRightElement>
              </InputGroup>
            </div>

            <div className='form-label-group mb-4'>
              <label className='login-label'>Confirm Password</label>
              <InputGroup size='md'>
                <Input
                  required
                  value={repeatPassword}
                  onChange={(e) => setRepeatPassword(e.target.value)}
                  pr='4.5rem'
                  type={showRepeatPassword ? 'text' : 'password'}
                  style={{
                    background: '#ffffff',
                    height: '45px !important',
                  }}
                />
                <InputRightElement width='4.5rem' height='44px'>
                  <svg
                    onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                    width='17'
                    height='12'
                    viewBox='0 0 17 12'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M14.1425 2.6802C15.2613 3.51 16.2441 4.6166 17 6C14.5523 10.4797 9.72559 12.0569 5.60275 10.7316L7.32384 9.10889C7.69143 9.23251 8.08738 9.29983 8.5 9.29983C10.433 9.29983 12 7.82245 12 6C12 5.61098 11.9286 5.23768 11.7975 4.89111L14.1425 2.6802ZM11.3972 1.26845L9.67616 2.89111C9.30857 2.76749 8.91262 2.70017 8.5 2.70017C6.567 2.70017 5 4.17755 5 6C5 6.38902 5.0714 6.76233 5.20252 7.10889L2.8575 9.3198C1.73865 8.49 0.755893 7.38341 0 6C2.44773 1.52026 7.27441 -0.0569261 11.3972 1.26845ZM2.13604 11.3333L14.1569 0L14.864 0.666667L2.84315 12L2.13604 11.3333Z'
                      fill='#CAC7C7'
                    />
                  </svg>
                </InputRightElement>
              </InputGroup>
            </div>

            <div style={{ paddingTop: '0.5rem' }}>
              <FormButton
                className={`btn-block btn ${loading ? 'btn-secondary' : 'btn-primary'
                  } btn-login text-center my-2`}
                style={{ backgroundColor: loading ? '#f1f1f1' : '' }}
                type='submit'
                disabled={loading}

              >
                {`${isNewUser ? 'Set' : 'Reset'} Password`}
              </FormButton>
            </div>
          </form>
        </div>
      </AuthLayout>
    </>
  );
};

function LogoModal(props) {
  return (
    <Modal
      {...props}
      backdrop='static'
      keyboard={false}
      size='lg'
      centered
      contentClassName='customLogoModal'
    >
      <Modal.Body className='d-flex align-items-center justify-content-center'>
        <img
          style={{ height: '40px', zIndex: '3' }}
          alt='woodcore logo'
          src={require('../../assets/images/wcLogoWhite.svg').default}
        />
      </Modal.Body>
    </Modal>
  );
}

export default ResetPassword;
