import {
  SET_CURRIENCIES,
  SET_ACCOUNTING_ERROR,
  SET_OFFICES,
  SET_PAYMENT_TYPES,
  ADD_JOURNAL_ENRIES,
  SET_FREQUENT_POSTING,
  SET_JOURNAL_ENRIES,
  REVERSE_JOURNAL_ENTRY,
  REVERSE_JOURNAL_ENTRY_RESET,
  ADD_JOURNAL_ENRIES_RESET,
  SET_FIANANCIAL_ACTIVITY_MAPPINGS,
  SET_FIANANCIAL_ACTIVITY_TEMPLATE,
  ADD_FIANANCIAL_ACTIVITY_MAPPINGS,
  SET_LOADING_STATE,
  EDIT_FIANANCIAL_ACTIVITY_MAPPINGS,
  SET_CHARTS_OF_ACCOUNT,
  GET_A_CHART_OF_ACCOUNT,
  FILTER_CHARTS_OF_ACCOUNT,
  CLEAR_FILTER_CHARTS_OF_ACCOUNT,
  SET_CHARTS_OF_ACCOUNT_TEMPLATE,
  SET_ACCOUNT_CLOSURE,
  SET_ACCOUNT_RULES,
  SET_ACCOUNT_RULES_TEMPLATE,
  SET_GL_ACCOUNTS,
  ONE_JOURNAL_ENTRY,
  SET_CHARTS_OF_ACCOUNT_PARENT,
  SET_AMORTIZATION,
  SET_SAVINGS_ACCOUNT,
  GET_GL_BALANCES_REQUEST,
  GET_GL_BALANCES_SUCCESS,
  GET_GL_BALANCES_FAIL,
  GET_GL_BALANCES_RESET,
} from "../constants/accountingConstants";

const initialState = {
  currencies: [],
  offices: [],
  paymentTypes: [],
  glAccounts: [],
  financialActivityMappings: [],
  chartsOfAccount: [],
  chartsOfAccountTemplate: {},
  accountsClosure: [],
  accountRules: [],
  accountRulesTemplate: {},
  journalEntries: {},
  reversedJournalEntries: {},
  chartOfAccountParent: {},
  oneJournalEntry: {},
  gottenChartOfAccount: {},
  financialActivityTemplate: {},
  accountingError: "",
  addJournalEntry: {},
  frequentPosting: {},
  isLoading: false,
  newfinancialActivityMappings: {},
  editedfinancialActivityMappings: {},
  amortizations: [],
  savingsAccount: [],
};

function setAccountingReducers(state = initialState, action) {
  switch (action.type) {
    case SET_CURRIENCIES: {
      return {
        ...state,
        currencies: action.payload,
      };
    }
    case SET_OFFICES: {
      return {
        ...state,
        offices: action.payload,
      };
    }
    case SET_GL_ACCOUNTS: {
      return {
        ...state,
        glAccounts: action.payload,
      };
    }
    case SET_ACCOUNTING_ERROR: {
      return {
        ...state,
        accountingError: action.payload,
      };
    }
    case SET_PAYMENT_TYPES: {
      return {
        ...state,
        paymentTypes: action.payload,
      };
    }
    case SET_AMORTIZATION: {
      return {
        ...state,
        amortizations: action.payload,
      };
    }
    case ADD_JOURNAL_ENRIES: {
      return {
        ...state,
        addJournalEntry: action.payload,
      };
    }
    case ADD_JOURNAL_ENRIES_RESET: {
      console.log("ADD_JOURNAL_ENRIES Reducer");
      console.log(
        {
          ...state,
          addJournalEntry: action.payload,
        },
        "ADD_JOURNAL_ENRIES console"
      );
      return {
        ...state,
        addJournalEntry: action.payload,
      };
    }
    case SET_JOURNAL_ENRIES: {
      return {
        ...state,
        journalEntries: action.payload,
      };
    }
    case SET_FREQUENT_POSTING: {
      return {
        ...state,
        frequentPosting: action.payload,
      };
    }
    case ONE_JOURNAL_ENTRY: {
      return {
        ...state,
        oneJournalEntry: action.payload,
      };
    }
    case REVERSE_JOURNAL_ENTRY: {
      console.log("REVERSE_JOURNAL_ENTRY Reducer");
      return {
        ...state,
        reversedJournalEntries: action.payload,
      };
    }
    case REVERSE_JOURNAL_ENTRY_RESET: {
      console.log("reset Reducer");
      console.log(
        {
          ...state,
          reversedJournalEntries: action.payload,
        },
        "state console"
      );
      return {
        ...state,
        reversedJournalEntries: action.payload,
      };
    }
    case SET_FIANANCIAL_ACTIVITY_MAPPINGS: {
      return {
        ...state,
        financialActivityMappings: action.payload,
      };
    }
    case SET_FIANANCIAL_ACTIVITY_TEMPLATE: {
      return {
        ...state,
        financialActivityTemplate: action.payload,
      };
    }
    case ADD_FIANANCIAL_ACTIVITY_MAPPINGS: {
      return {
        ...state,
        newfinancialActivityMappings: action.payload,
      };
    }
    case EDIT_FIANANCIAL_ACTIVITY_MAPPINGS: {
      return {
        ...state,
        editedfinancialActivityMappings: action.payload,
      };
    }
    case SET_CHARTS_OF_ACCOUNT: {
      return {
        ...state,
        chartsOfAccount: action.payload,
      };
    }
    case GET_A_CHART_OF_ACCOUNT: {
      return {
        ...state,
        gottenChartOfAccount: action.payload,
      };
    }
    case SET_SAVINGS_ACCOUNT: {
      return {
        ...state,
        savingsAccount: action.payload,
      };
    }
    case FILTER_CHARTS_OF_ACCOUNT:
      return {
        ...state,
        filtered: state.chartsOfAccount.filter((cst) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return (
            cst.name.match(regex) ||
            cst.glCode.match(regex) ||
            (cst.accountType && cst.accountType.match(regex))
          );
        }),
      };
    case CLEAR_FILTER_CHARTS_OF_ACCOUNT:
      return { ...state, filtered: null };
    case SET_CHARTS_OF_ACCOUNT_TEMPLATE: {
      return {
        ...state,
        chartsOfAccountTemplate: action.payload,
      };
    }
    case SET_ACCOUNT_CLOSURE: {
      return {
        ...state,
        accountsClosure: action.payload,
      };
    }
    case SET_ACCOUNT_RULES: {
      return {
        ...state,
        accountRules: action.payload,
      };
    }
    case SET_ACCOUNT_RULES_TEMPLATE: {
      return {
        ...state,
        accountRulesTemplate: action.payload,
      };
    }
    case SET_CHARTS_OF_ACCOUNT_PARENT: {
      return {
        ...state,
        chartOfAccountParent: action.payload,
      };
    }
    case SET_LOADING_STATE: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

export default setAccountingReducers;

export const getGlAccountsBalancesReducers = (
  state = { eoyGlAccountsBalances: {} },
  action
) => {
  switch (action.type) {
    case GET_GL_BALANCES_REQUEST:
      return { loading: true, ...state };
    case GET_GL_BALANCES_SUCCESS:
      return {
        ...state,
        loading: false,
        eoyGlAccountsBalances: action.payload,
      };
    case GET_GL_BALANCES_FAIL:
      return { ...state, loading: false, error: action.payload };
    case GET_GL_BALANCES_RESET:
      return {
        ...state,
        eoyGlAccountsBalances: null,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
