import styled from "styled-components";
import { devices } from "../../../components/Devices";
import HeadwayWidget from "@headwayapp/react-widget";

export const SearchInput = styled.input`
  &:focus {
    box-shadow: none;
  }
`;

export const ProfileBarDropDown = styled.div`
  display: flex;
  align-items: center;
  .profile-name {
    // font-style: normal;
    // font-weight: normal;
    font-size: 16px;
    // line-height: 21px;
    text-align: right;
    // letter-spacing: -0.03em;
    color: #000000;
    margin: 0;
  }
  .profile-email {
    // font-style: normal;
    font-weight: 300;
    font-size: 13px;
    // line-height: 19px;
    text-align: right;
    // letter-spacing: -0.03em;
    color: #a6acbe;
    margin: 0;
  }
  .profile-image img {
    margin-left: 10px;
    height: 40px;
  }
  .arrow {
    margin: 0 10px;
  }
  .verticalLine {
    width: 34px;
    height: 0px;
    border: 1px solid #eff4f7;
    transform: rotate(90deg);
  }
`;

export const NavBrand = styled.div`
  background: #2c1dff;
  @media ${devices.mobileS} {
    width: 20%;
  }
  @media ${devices.laptop} {
    width: 5%;
  }
`;

export const NavBrandOpen = styled.div`
  background: #2c1dff;
  width: 15%;
  @media ${devices.mobileS} {
    width: 20%;
  }
  @media ${devices.laptop} {
    width: 15%;
  }
`;

export const NavWrapper = styled.div`
  margin-bottom: 70px;
  .HW_right {
    right: -4px !important;
  }
  .HW_bottom {
    top: -6px !important;
  }
  nav {
    position: fixed !important;
    width: 100%;
  }
`;

export const StyledHeadwayWidget = styled(HeadwayWidget)`
  display: flex !important;
  align-items: center;
`;
