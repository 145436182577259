export const REACT_SELECT_CUSTOM_STYlES = {
  control: (baseStyle) => ({
    ...baseStyle,
		width: '100%',
    borderRadius: "6px",
    borderColor: "inherit",
    minHeight: "35px",
    maxHeight: "35px",
  }),
  placeholder: (baseStyle) => ({
    ...baseStyle,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }),
  singleValue: (baseStyle) => ({
    ...baseStyle,
    color: "black",
    margin: 0,
  }),
  valueContainer: (baseStyle) => ({
    ...baseStyle,
    padding: "0 16px",
  }),
};
