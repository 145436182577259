import React from "react";
import { Table } from "antd";
import Loading from "./Loading";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  { title: "Description", dataIndex: "description", key: "description" },
  { title: "Status", dataIndex: "status", key: "status" },
  { title: "", dataIndex: "able", key: "able" },
  { title: "", dataIndex: "edit", key: "edit" },
  { title: "", dataIndex: "delete", key: "delete" },
];
const TableLoader = (props) => {
  const someChartsData = [
    {
      key: 1,
      name: <Loading />,
      description: <Loading />,
      status: <Loading />,
      able: <Loading />,
      edit: <Loading />,
      delete: <Loading />,
    },
    {
      key: 2,
      name: <Loading />,
      description: <Loading />,
      status: <Loading />,
      able: <Loading />,
      edit: <Loading />,
      delete: <Loading />,
    },
    {
      key: 3,
      name: <Loading />,
      description: <Loading />,
      status: <Loading />,
      able: <Loading />,
      edit: <Loading />,
      delete: <Loading />,
    },
    {
      key: 4,
      name: <Loading />,
      description: <Loading />,
      status: <Loading />,
      able: <Loading />,
      edit: <Loading />,
      delete: <Loading />,
    },
    {
      key: 5,
      name: <Loading />,
      description: <Loading />,
      status: <Loading />,
      able: <Loading />,
      edit: <Loading />,
      delete: <Loading />,
    },
  ];
  return (
    <>
      <Table
        columns={columns}
        dataSource={someChartsData}
        pagination={{ defaultPageSize: 8 }}
        className="antdTable"
        defaultPageSize={8}
      />
    </>
  );
};

export default TableLoader;
