import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { Checkbox } from "antd";
import DatePicker from "react-datepicker";
import { Input, Select, FormControl } from "@chakra-ui/react";
import { FormClass, ActionButton, FormElements } from "../styles";
import { formatDate } from "../../../../../util/FormatDate";
import {
  closeSavingsAccount,
  getTransactionTemplate,
} from "../../../../../actions/SavingsAndLoanActions";
import {
  closeFixedDepositAccount,
  getFixedDepositAccountTemplates,
} from "../../../../../actions/fixedDeposit";

const CloseAccount = ({
  onHide,
  history,
  id,
  fixedDepositAccountDetails,
  clientId,
}) => {
  const dispatch = useDispatch();

  const fixedDepositAccountTemplate = useSelector(
    (state) => state.fixedDepositAccountTemplate
  );

  let { fixedDepositAccountCreateTemplate = {} } =
    fixedDepositAccountTemplate || {};
  const savingsAndLoan = useSelector((state) => state.savingsAndLoan);

  let { savingsAccountTransactionTemplate = {} } = savingsAndLoan || {};

  let { summary = {} } = fixedDepositAccountDetails || {};

  let { paymentTypeOptions = [] } = savingsAccountTransactionTemplate || {};

  let { savingsAccounts = [] } = fixedDepositAccountCreateTemplate || {};

  // console.log(savingsAccounts , "accounts")

  useEffect(() => {
    dispatch(getFixedDepositAccountTemplates(clientId, ""));
  }, [dispatch, clientId]);

  const [closeOn, setCloseOn] = useState();
  const [withdawBalance, setWithdrawBalance] = useState(false);
  const [postIntrestOnClosureDate, setPostIntrestOnClosureDate] =
    useState(false);
  const [note, setNote] = useState("");

  const [amount, setAmount] = useState(
    !!summary.availableBalance ? summary.availableBalance : 0
  );
  const [paymentType, setPaymentType] = useState();

  let closeOnDate = new Date(closeOn);
  const [transferDescription, setTransferDescription] = useState();
  const [savingsAction, setSavingsAction] = useState("");
  const [action, setAction] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [activateOnDate, setActivatedOnDate] = useState("");
  const [activatedOn, setActivatedOn] = useState(new Date());

  // useEffect(() => {
  //   dispatch(getTransactionTemplate(id));
  // }, [id, dispatch]);

  useEffect(() => {
    // console.log(fixedDepositAccountActions, 'fixedDepositAccountActions')
    if (
      !!fixedDepositAccountDetails &&
      !!fixedDepositAccountDetails?.maturityAmount
    ) {
      setAmount(fixedDepositAccountDetails?.maturityAmount);
    }
  }, [fixedDepositAccountDetails]);

  const handleActionOnChange = (e) => {
    setAction(e.target.value);
    setSelectedOption(e.target.value);
  };

  const handleDateChange = (date) => {
    setActivatedOn(date);
    let dataToSend = {
      dateFormat: "dd MMM yyyy",
      closedOnDate: formatDate(activatedOn, true),
      locale: "en",
    };
  };

  useEffect(() => {
    if (fixedDepositAccountDetails?.maturityAmount) {
      handleDateChange(activatedOn);
    }
  }, [activatedOn]);

  const handleClosure = () => {
    let dataToSend = {
      dateFormat: "dd MMM yyyy",
      closedOnDate: formatDate(activatedOn, true),
      locale: "en",
      onAccountClosureId: selectedOption,
      toSavingsAccountId: savingsAction,
      transferDescription,
      note,
    };
    // dispatch(closeSavingsAccount(id, dataToSend, history));
    dispatch(
      closeFixedDepositAccount(id, dataToSend, history, onHide, "close")
    );
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>Close Fixed Deposit Account</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <FormElements style={{ width: "100%" }}>
            {/* Pre Mature Close Date* */}
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Closed on Date*</label>
              {/* <FormControl style={{ width: "70%" }}> */}
                <DatePicker
                  id="published-date"
                  selected={activatedOn}
                  onChange={(date) => setActivatedOn(date)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="dd/mmm/yyyy"
                />
              {/* </FormControl> */}
            </div>
            {/* {!!fixedDepositAccountDetails &&
            !!fixedDepositAccountDetails?.maturityAmount ? ( */}
            <>
              {/* Maturity Amount* */}
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Maturity Amount*</label>

                {/* <FormControl style={{ width: "70%" }}> */}
                  <Input
                    value={amount}
                    name="description"
                    style={{ width: "60%" }}
                    onChange={(e) => setAmount(e.target.value)}
                    disabled
                  ></Input>
                {/* </FormControl> */}
              </div>
              {/* setPostIntrestOnClosureDate */}
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label"> Action*</label>
                {/* <FormControl style={{ width: "70%" }}> */}
                  <Select
                    placeholder="Select One"
                    size="md"
                    name="action"
                    value={action}
                    // disabled
                    onChange={handleActionOnChange}
                  >
                    <option value="200">Transfer To Savings</option>
                  </Select>
                {/* </FormControl> */}
              </div>
              {/* Transfer to Savings*  and Transfer description*/}
              {selectedOption && selectedOption === "200" && (
                <>
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Transfer to Savings*</label>
                    {/* <div className="selecttf" style={{ width: "70%" }}> */}
                      <Select
                        placeholder="Select One"
                        size="md"
                        name="savingsAction"
                        value={savingsAction}
                        // disabled
                        onChange={(e) => setSavingsAction(e.target.value)}
                        // style={{width: "60%"}}
                      >
                        {!!savingsAccounts && !!savingsAccounts.length
                          ? savingsAccounts?.map((cur) => {
                              return (
                                <option value={cur.id} key={cur.id}>
                                  {cur.savingsProductName}({cur.accountNo}
                                </option>
                              );
                            })
                          : ""}
                      </Select>
                    {/* </div> */}
                  </div>
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Transfer Description</label>
                    {/* <div className="inputtf" style={{ width: "70%" }}> */}
                      <Input
                        value={transferDescription}
                        name="transferDescription"
                        style={{ width: "60%" }}
                        onChange={(e) => setTransferDescription(e.target.value)}
                      ></Input>
                    {/* </div> */}
                  </div>
                </>
              )}
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Note</label>
                {/* <FormControl style={{ width: "70%" }}> */}
                  <Input
                    value={note}
                    name="note"
                    style={{ width: "60%" }}
                    onChange={(e) => setNote(e.target.value)}
                  ></Input>
                {/* </FormControl> */}
              </div>
            </>
          </FormElements>
          <div
            className="d-flex mt-4"
            style={{ justifyContent: "end" }}
          >
            <ActionButton color="#2C1DFF" onClick={onHide}>
              <img
                src={
                  require("../../../../../assets/images/icons/cancel.svg")
                    .default
                }
                alt="cancel icon"
              />
              Cancel
            </ActionButton>
            <ActionButton
              color="#2C1DFF"
              style={{ margin: "0px 0px 0 10px" }}
              onClick={(e) => {
                e.preventDefault();
                if (
                  !!fixedDepositAccountDetails &&
                  !!fixedDepositAccountDetails.maturityAmount
                ) {
                  handleClosure();
                }
              }}
            >
              <img
                src={
                  require("../../../../../assets/images/icons/save.svg").default
                }
                alt="submit icon"
              />
              Submit
            </ActionButton>
          </div>
        </FormClass>
      </Modal.Body>
    </>
  );
};

export default CloseAccount;
