import {
  GET_CLIENT_TIERS_REQUEST,
  GET_CLIENT_TIERS_SUCCESS,
  GET_CLIENT_TIERS_FAIL,
  CREATE_NEW_CLIENT_TIERS,
  CREATE_NEW_CLIENT_TIERS_SUCCESS,
  CREATE_NEW_CLIENT_TIERS_FAIL,
  EDIT_CLIENT_TIER,
  EDIT_CLIENT_TIER_SUCCESS,
  EDIT_CLIENT_TIER_FAIL,
  DELETE_CLIENT_TIER,
  DELETE_CLIENT_TIER_SUCCESS,
  DELETE_CLIENT_TIER_FAIL,
} from '../constants/systemTiersConstants';

const initialState = {
  clientTiers: [],
  loading: false,
  error: null,
  createTierError: null,
};

export const getSystemTiers = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLIENT_TIERS_REQUEST:
      return { ...state, loading: true };
    case GET_CLIENT_TIERS_SUCCESS:
      return {
        ...state,
        clientTiers: action.payload,
        error: null,
        loading: false,
      };
    case GET_CLIENT_TIERS_FAIL:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};
export const createNewSystemTiers = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_NEW_CLIENT_TIERS:
      return { loading: true, ...state };
    case CREATE_NEW_CLIENT_TIERS_SUCCESS:
      return { ...state, loading: false, newClientTier: action.payload };
    case CREATE_NEW_CLIENT_TIERS_FAIL:
      return { ...state, loading: false, createTierError: action.payload };
    default:
      return state;
  }
};
export const editSystemTiers = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_CLIENT_TIER:
      return { loading: true, ...state };
    case EDIT_CLIENT_TIER_SUCCESS:
      return { ...state, loading: false };
    case EDIT_CLIENT_TIER_FAIL:
      return { ...state, loading: false };
    default:
      return state;
  }
};
export const deleteSystemTiers = (action) => {
  switch (action.type) {
    case DELETE_CLIENT_TIER:
      return { loading: true };
    case DELETE_CLIENT_TIER_SUCCESS:
      return { loading: false };
    case DELETE_CLIENT_TIER_FAIL:
      return { loading: false };
    default:
      return;
  }
};
