import styled from "styled-components";

export const ContentWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 0px #ededf6;
  min-height: 70vh;
  margin-top: 0px;
  padding: 30px 30px 50px 30px;

  p {
    font-size: 18px;
    font-weight: 400;
  }

  .pHeader {
    font-size: 24px;
    font-weight: 700;
  }

  .lineHeader {
    font-weight: 20px;
    font-weight: 600;
  }

  .button-right {
    width: 100%;
    height: 50px;
    padding-right: 15px;
  }
`;

export const FormClass = styled.div``;

export const CardDetails = styled.div`
  // margin-left: 130px;

  .dflex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  hr {
    width: 600px;
    height: 1px;
    color: #e1e1e4;
  }
`;

export const ActionButton = styled.button`
  background: ${(props) => props.color} !important;
  border-radius: 5px !important;
  color: #fff !important;
  margin: 0 3px;
  border: none;
  display: inline;
  font-size: 14px;
  font-family: "AventaMedium";
  height: 35px;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  position: relative;
  padding: 8px;
  cursor: pointer;
  img {
    height: 14px;
    margin-right: 10px;
    display: inline;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;

export const TabButton = styled.button`
  border: 0.5px solid ${(props) => props.color} !important;
  border-radius: 5px !important;
  color: #2c1dff !important;
  background: #fff;
  margin: 0 3px;
  border: none;
  display: inline;
  font-size: 14px;
  height: 35px;
  width: 187px;
  margin-top: 10px;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  position: relative;
  cursor: pointer;
  text-align: left;
  .new-design-text {
    padding-left: 10px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;

    .small-text {
      font-size: 9px;
    }
  }
  img {
    height: 10px;
    margin-left: 10px !important;
    display: inline;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;

export const ChartCard = styled.div`
  background: #ffffff;
  border-radius: 10px;
  height: 400px;
  box-shadow: 0px 2px 0px #ededf6;
  .heading {
    padding: 20px 25px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eef0f7;
    font-size: 13px;
    .title {
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0.01em;
    }
    div {
      // flex: auto;
    }
    p {
      margin: 0;
    }
  }
`;

export const ChartMarker = styled.div`
  height: 8px;
  width: 8px;
  background: ${(props) => props.color};
  display: inline-block;
  border-radius: 100%;
  margin-right: 10px;
`;

export const IconButton = styled.button`
  background: ${(props) => props.color} !important;
  width: 22px;
  height: 22px;
  background: #2c1dff;
  text-align: center;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
  img {
    // height: 16px;
    // margin-right: 10px;
    display: inline;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;

export const FormElements = styled.div`
  width: 90%;
  margin-top: 30px;
  margin-bottom: 30px;
  display: block;

  label {
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 22px;
    color: #969cba;
  }
`;
