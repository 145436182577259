import React, { useState } from "react";
import {
  ContentWrapper,
  FormClass,
  ActionButton,
  FormElements,
} from "./styles";
import { Input } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const CreateCampaignView = () => {
  const [newUser, setNewUser] = useState({
    firstname: "",
    lastname: "",
    username: "",
    emailAddress: "",
    password: "",
    office: "",
    staff: "",
  });

  const newUserChangeHandler = (e) =>
    setNewUser({ ...newUser, [e.target.name]: e.target.value });

  let { firstname, lastname, username } = newUser;
  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">SMS Campaign</h3>
            <p className="sub-title">Create SMS Campaign</p>
            <p className="font-weight sub-title mt-2">
              <span className="text-info">
                <Link to="/core/configuration">Configuration</Link> /{" "}
                <Link to="/core/configuration/organization">Utilities</Link>/{" "}
                <Link to="/core/configuration/organization/sms-campaign">
                  SMS Campaign
                </Link>
              </span>{" "}
              / New Campaign
            </p>
            {/* <p className="sub-title mt-2">
              <span className="text-info mr-1">Configuration / Users </span> /
              Create User
            </p> */}
          </div>
        </div>
        <ContentWrapper>
          <FormClass className="h-100 container">
            <FormElements>
              <div className="row h-100" style={{ paddingTop: "100px" }}>
                <div className="col-md-1"></div>
                <div className="col-sm-12 col-md-8">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">First Name</label>
                    <Input
                      style={{ width: "60%" }}
                      size="md"
                      name="firstname"
                      value={firstname}
                      onChange={newUserChangeHandler}
                    />
                  </div>

                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Last Name</label>
                    <Input
                      style={{ width: "60%" }}
                      size="md"
                      name="lastname"
                      value={lastname}
                      onChange={newUserChangeHandler}
                    />
                  </div>

                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Username</label>
                    <Input
                      style={{ width: "60%" }}
                      size="md"
                      name="username"
                      value={username}
                      onChange={newUserChangeHandler}
                    />
                  </div>
                </div>
              </div>
              <div className="float-right h-100 mt-5">
                <ActionButton shadow className="mb-2" color="#2C1DFF">
                  <img
                    src={
                      require("../../../../../../assets/images/icons/cancel.svg")
                        .default
                    }
                    alt="cancel"
                  />
                  Cancel
                </ActionButton>
                <ActionButton className="mr-0" shadow color="#2C1DFF">
                  <img
                    src={
                      require("../../../../../../assets/images/icons/save.svg")
                        .default
                    }
                    alt="cancel"
                  />
                  Submit
                </ActionButton>
              </div>
              <br />
              <br />
            </FormElements>
          </FormClass>
        </ContentWrapper>
      </div>
    </>
  );
};

export default CreateCampaignView;
