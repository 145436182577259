/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ContentWrapper } from "../styles";

import AppDetails from "../components/common/AppDetails";
import AppTerms from "../components/common/AppTerms";
import AppCharges from "../components/common/AppCharges";
import AppReviews from "../components/common/AppReviews";

import LoanDetails from "../components/loans/LoanDetails.jsx";
import LoanTerms from "../components/loans/LoanTerms.jsx";
import LoanCharges from "../components/loans/LoanCharges.jsx";
import LoanReview from "../components/loans/LoanReview.jsx";
import ScrollToTop from "../../../../../components/ScrollToTop/index.jsx";
import { formatDate } from "../../../../../util/FormatDate";

import {
  getLoansProductOptions,
  getSelectedLoanProductData,
  // addLoansCharge,
  // getLoansChargeOptionData,
} from "../../../../../actions/utilityActions";

import {
  getLoanProductsTemplate,
  resetNewLoanProductState,
} from "../../../../../actions/configurationActions";

import { createLoanAccount } from "../../../../../actions/customerActions";
import PreviewRepaymentSchedule from "./PreviewRepaymentSchedule.jsx";
import { calculateRepaymentSchedule } from "../../../../../actions/SavingsAndLoanActions.js";
import CheckPermission from "../../../../../util/CheckPermission.js";

const LoanApplicationScreen = ({ match, history }) => {
  const dispatch = useDispatch();

  // Added
  const loanProductsTemplate = useSelector(
    (state) => state.loanProductsTemplate
  );

  const selectedLoanData = useSelector((state) => state.selectedLoanData);
  const { responseUserData: { permissions } } = useSelector((state) => state.preAuth);
  const { productData } = selectedLoanData;

  // const fetchedLoansChargeData = useSelector(
  //   (state) => state.loansChargeData
  // );

  // const { LoanchargeData } = fetchedLoansChargeData;

  const { loanProductsTemplate: template } = loanProductsTemplate;
  useEffect(() => {
    dispatch(resetNewLoanProductState());
    dispatch(getLoanProductsTemplate());
  }, []);

  const {
    principal: loanPrincipal,
    numberOfRepayments: loanNumberOfRepayment,
    amortizationType: loanAmortizationType,
    termFrequency,
    termPeriodFrequencyType,
    repaymentEvery: loanRepaymentEvery,
    repaymentFrequencyType: loanRepaymentEveryType,
    interestRatePerPeriod: loanInterestRatePerPeriod,
    interestType: loanInterestType,
    transactionProcessingStrategyOptions = [],
    transactionProcessingStrategyId: loanTransactionProcessingStrategyId,
    interestCalculationPeriodType: loanInterestCalculationPeriodType,
    clientName,
  } = productData;

  const [productType, setProductType] = useState("");
  const [productId, setProductId] = useState("");

  const [externalId, setExternalId] = useState("");
  const [fullCharges, setFullCharges] = useState([]);
  const [valueCharges, setValueCharges] = useState([]);
  const [charge, setCharge] = useState("");
  const [chargeId, setChargeId] = useState("");

  const [chargesArray, setChargesArray] = useState([]);
  const [myChargesArray, setMyChargesArray] = useState([]);

  const [overdueChargesArray, setOverdueChargesArray] = useState([]);
  const [myOverdueChargesArray, setMyOverdueChargesArray] = useState([]);

  const [fullOverdueCharges, setFullOverdueCharges] = useState([]);
  const [valueOverdueCharges, setValueOverdueCharges] = useState([]);
  const [feeToIncomeAccountMappings, setFeeToIncomeAccountMappings] = useState(
    []
  );

  // overdueCharges

  useEffect(() => {
    const createCharges = async () => {
      if (productData) {
        let myCharges = {
          index: myChargesArray.length,
          name: productData.name ? productData.name : "",
          chargeId: productData.id ? productData.id : "",
          amount: productData.amount ? productData.amount : "",
          type:
            productData.chargeCalculationType &&
            productData.chargeCalculationType.value
              ? productData.chargeCalculationType.value
              : "",
          chargeTimeTypeName:
            productData.chargeTimeType && productData.chargeTimeType.value
              ? productData.chargeTimeType.value
              : "",
          chargeTimeType:
            productData.chargeTimeType && productData.chargeTimeType.id
              ? productData.chargeTimeType.id
              : "",
          // date: productData.feeOnMonthDay ? formatDate(productData.feeOnMonthDay) : "",
          // date: productData.feeOnMonthDay,
          date: "",
          repayments: productData.feeInterval,
        };
        await setMyChargesArray([...myChargesArray, myCharges]);

        await setChargesArray([...chargesArray, productData]);
      }
    };
    createCharges();
  }, [productData]);

  //OverDue Charges

  useEffect(() => {
    const createOverdueCharges = async () => {
      if (productData) {
        let myOverdueCharges = {
          index: myOverdueChargesArray.length,
          name: productData.name ? productData.name : "",
          chargeId: productData.id ? productData.id : "",
          amount: productData.amount ? productData.amount : "",
          type:
            productData.chargeCalculationType &&
            productData.chargeCalculationType.value
              ? productData.chargeCalculationType.value
              : "",
          chargeTimeTypeName:
            productData.chargeTimeType && productData.chargeTimeType.value
              ? productData.chargeTimeType.value
              : "",
          chargeTimeType:
            productData.chargeTimeType && productData.chargeTimeType.id
              ? productData.chargeTimeType.id
              : "",
          date: "",
        };

        await setMyOverdueChargesArray([
          ...myOverdueChargesArray,
          myOverdueCharges,
        ]);

        await setOverdueChargesArray([...overdueChargesArray, productData]);
      }
    };
    createOverdueCharges();
  }, [productData]);

  const [penaltyToIncomeAccountMappings, setPenaltyToIncomeAccountMappings] =
    useState([]);
  const [
    paymentChannelToFundSourceMappings,
    setPaymentChannelToFundSourceMappings,
  ] = useState([]);
  const [newLoanProduct, setNewLoanProduct] = useState({
    productName: "",
    currency: "",
    shortName: "",
    multipleCurrency: "1",
    description: "",
    linkedAccount: "",
    loanPurpose: "",
    installment: "",
    decimalPlaces: "2",
    customerLoanCounter: false,
    createStandingInstructionAtDisbursement: false,
    fund: "",
    max_pricipal: "",
    def_pricipal: "",
    min_pricipal: "",
    max_repayment: "",
    def_repayment: "",
    min_repayment: "",
    max_interest: "",
    def_interest: "",
    min_interest: "",
    interestSelect: "",
    interestMethod: "",
    repaid: "",
    repaidSelect: "",
    disbursal: "",
    selectCharges: "",
    selectOverDueCharges: "",
    none: true,
    cash: false,
    accuralPeriodic: false,
    accuralUpfront: false,
    amortizationType: "",
    amortization: true,
    isEqualAmortization: false,
    interestType: true,
    transactionProcessingStrategy: true,
    transactionProcessingStrategyId: "",
    graceOnArrearsAgeing: true,
    interestCalculationPeriodType: true,
    repaymentEvery: true,
    inArrearsTolerance: true,
    graceOnPrincipalAndInterestPayment: true,
    interestCalculation: "",
    moratonium2: "",
    moratonium: "",
    graceOnInterestCharged: "",
    inArrearsToleranceValue: "",
    daysInyear: "",
    daysInMonth: "",
    canDefineInstallmentAmount: false,
    numberOfDaysLoan: "",
    accountMovesOutOfNPAOnlyOnArrearsCompletion: false,
    graceOnArrearsAgeingValue: "",
    overdueDaysForNPA: "",
    principalThresholdForLastInstallment: "",
    isVariableInstallment: false,
    minimumGap: "",
    maximumGap: "",
    canUseForTopup: false,
    isInterestRecalculationEnabled: false,
    recalculationRestFrequencyInterval: "",
    preClosureInterestCalculationStrategy: "",
    interestRecalculationCompoundingMethod: "",
    rescheduleStrategyMethod: "",
    recalculationRestFrequencyType: "",
    recalculationCompoundingFrequencyInterval: "",
    recalculationCompoundingFrequencyType: "",
    recalculationCompoundingFrequencyNthDayType: "",
    recalculationCompoundingFrequencyDayOfWeekType: "",
    recalculationCompoundingFrequencyOnDayType: "",
    isArrearsBasedOnOriginalSchedule: false,
    recalculationRestFrequencyDayOfWeekType: "",
    recalculationRestFrequencyOnDayType: "",
    recalculationRestFrequencyNthDayType: "",
    placeGuarantee: "",
    mandatoryGuarantee: "",
    minimumGuaranteeFromOwnFunds: "",
    minimumGuaranteeFromGuarantor: "",
    loanTrancte: false,
    maxTrancheCount: "",
    outstandingLoanBalance: "",
    // principalOverdueAccountId: "",
    fundSource: "",
    loanPortfolio: "",
    transferInSusp: "",
    incomeFromInterest: "",
    incomeFromFees: "",
    incomeFromPenalties: "",
    incomeFromRecovery: "",
    overPaymentLia: "",
    lossesWrittenOff: "",
    interestReceivable: "",
    feesReceivable: "",
    penaltiesReceivable: "",
    // interestInSuspenseAccountId: "",
    // interestOverdueAccountId: "",
    allowPartialPeriodInterestCalcualtion: false,
  });

  const createLoanProductHandler = (e) =>
    setNewLoanProduct({ ...newLoanProduct, [e.target.name]: e.target.value });

  const [accountType, setAccountType] = useState("1");
  const [chargesError, setChargesError] = useState(false);

  const [submittedDate, setSubmittedDate] = useState(new Date());
  let submittedOnDate = formatDate(submittedDate);

  const [disbursementDate, setExpectedDisbursementDate] = useState("");
  let expectedDisbursementDate = formatDate(disbursementDate);

  const [loanOfficer, setLoanOfficer] = useState("");
  const [loanOfficerId, setLoanOfficerId] = useState("");

  const [principal, setPrincipal] = useState("");

  const [linkedAccount, setLinkedAccount] = useState("");
  const [linkAccountId, setLinkedAccountId] = useState("");

  const [fund, setFund] = useState("");

  const [loanPurpose, setLoanPurpose] = useState("");
  const [loanPurposeId, setLoanPurposeId] = useState("");

  const [purposeType, setPurposeType] = useState("");
  const [purposeId, setPurposeId] = useState("");

  const [loanTermFreqType, setLoanTermFreqType] = useState("");
  const [loanTermFrequencyType, setLoanTermFrequencyType] = useState("");

  const [loanTermFrequency, setLoanTermFrequency] = useState("");

  const [numberOfRepayments, setNumberOfRepayments] = useState("");

  const [repaymentEvery, setRepaymentEvery] = useState("");

  const [repaymentFreqType, setRepaymentFreqType] = useState("");
  const [repaymentFrequencyType, setRepaymentFrequencyType] = useState("");

  const [repaymentFreqDayType, setRepaymentFreqDayType] = useState("");
  const [repaymentFrequencyDayOfWeekType, setRepaymentFrequencyDayOfWeekType] =
    useState("");

  const [repaymentFreqNthDayType, setRepaymentFreqNthDayType] = useState("");
  const [repaymentFrequencyNthDayType, setRepaymentFrequencyNthDayType] =
    useState("");

  const [interestCalculationPeriod, setInterestCalculationPeriod] =
    useState("");
  const [interestCalculationPeriodType, setInterestCalculationPeriodType] =
    useState("");

  const [interestRatePerPeriod, setInterestRatePerPeriod] = useState("");

  const [intType, setIntType] = useState("");
  const [interestType, setInterestType] = useState("");

  const [amortization, setAmortization] = useState("");
  const [amortizationType, setAmortizationType] = useState("");

  const [equalAmortization, setEqualAmortization] = useState("");
  // const [isEqualAmortization, setIsEqualAmortization] = useState(false);

  const [allowPartialPeriodInterest, setAllowPartialPeriodInterest] =
    useState("");
  const [
    allowPartialPeriodInterestCalcualtion,
    setAllowPartialPeriodInterestCalcualtion,
  ] = useState("");

  const [transactionProcessingStrategy, setTransactionProcessingStrategy] =
    useState("");
  const [transactionProcessingStrategyId, setTransactionProcessingStrategyId] =
    useState("");

  const [inArrearsTolerance, setInArrearsTolerance] = useState("");

  const [graceOnInterestCharged, setGraceOnInterestCharged] = useState("");

  const [graceOnPrincipalPayment, setGraceOnPrincipalPayment] = useState("");

  const [graceOnArrearsAgeing, setGraceOnArrearsAgeing] = useState("");

  const [graceOnInterestPayment, setGraceOnInterestPayment] = useState("");

  // const [
  //   createStandingInstructionAtDisbursement,
  //   setCreateStandingInstructionAtDisbursement,
  // ] = useState(false);

  const [repaymentStartingFrom, setRepaymentStartingFrom] = useState("");
  let repaymentsStartingFromDate = "";

  const [interestChargedFrom, setInterestChargedFrom] = useState("");
  let interestChargedFromDate = "";

  const [loanType, setLoanType] = useState("individual");

  const [disbursementData, setDisbursementData] = useState([]);

  const [collaterals, setCollaterals] = useState([]);

  useEffect(() => {
    let startategy = {};
    transactionProcessingStrategyOptions.forEach((option) => {
      let { id } = option;
      if (`${id}` === `${loanTransactionProcessingStrategyId}`) {
        startategy = option;
      }
    });
    if (!!loanPrincipal) {
      setPrincipal(loanPrincipal);
      setNumberOfRepayments(loanNumberOfRepayment);
      setAmortization(loanAmortizationType.value);
      setAmortizationType(loanAmortizationType.id);
      setLoanTermFrequency(termFrequency);
      setLoanTermFreqType(termPeriodFrequencyType.value);
      setLoanTermFrequencyType(termPeriodFrequencyType.id);
      setRepaymentEvery(loanRepaymentEvery);
      setRepaymentFreqType(loanRepaymentEveryType.value);
      setRepaymentFrequencyType(loanRepaymentEveryType.id);
      setInterestRatePerPeriod(loanInterestRatePerPeriod);
      setIntType(loanInterestType.value);
      setInterestType(loanInterestType.id);
      setTransactionProcessingStrategy(startategy.name);
      setTransactionProcessingStrategyId(loanTransactionProcessingStrategyId);
      setInterestCalculationPeriod(loanInterestCalculationPeriodType.value);
      setInterestCalculationPeriodType(loanInterestCalculationPeriodType.id);
    }
  }, [
    loanPrincipal,
    loanNumberOfRepayment,
    loanAmortizationType,
    termFrequency,
    termPeriodFrequencyType,
    loanRepaymentEvery,
    loanRepaymentEveryType,
    loanInterestRatePerPeriod,
    loanInterestType,
    transactionProcessingStrategyOptions,
    loanTransactionProcessingStrategyId,
    loanInterestCalculationPeriodType,
  ]);

  // HANDLE LOaN PRODUCT TYPE
  const handleProductType = (e) => {
    setProductType(e.target.value);
    let arr = e.target.querySelectorAll("option");
    arr.forEach((x) => {
      if (e.target.value === x.innerHTML) {
        setProductId(x.id);
        dispatch(getSelectedLoanProductData(match.params.id, +x.id));
      }
    });
  };

  //Handle Purpose Type for loan
  const handlePurposeType = (e) => {
    setPurposeType(e.target.value);
    let arr = e.target.querySelectorAll("option");
    arr.forEach((x) => {
      if (e.target.value === x.innerText) {
        setPurposeId(x?.id);
      }
    });
  };

  //HANDLE EXTERNAL ID
  const handleExternalId = (e) => setExternalId(e.target.value);
  //HANDLE PRINCIPAL
  const handlePrincipal = (val) => setPrincipal(val);
  //HANDLE SUBMITTED DATE DATA
  const handleSubmittedOn = (date) => {
    setSubmittedDate(date);
    submittedOnDate = formatDate(date);
  };
  //HANDLE DISBURSEMENT DATE DATA
  const handleDisbursementDate = (date) => {
    setExpectedDisbursementDate(date);
    expectedDisbursementDate = formatDate(date);
  };
  //HANDLE FIRST REPAYMENT DATE DATA
  const handleRepaymentStartingFrom = (date) => {
    setRepaymentStartingFrom(date);
    repaymentsStartingFromDate = formatDate(date);
  };
  //HANDLE FIRST REPAYMENT DATE DATA
  const handleInterestChargedFrom = (date) => {
    setInterestChargedFrom(date);
    interestChargedFromDate = formatDate(date);
  };
  //HANDLE PRINCIPAL
  const handleLoanTermFreq = (e) => setLoanTermFrequency(e.target.value);
  // HANDLE TERM FREQ TYPE
  const handleLoanTermFreqType = (e) => {
    setLoanTermFreqType(e.target.value);
    let arr = e.target.querySelectorAll("option");
    arr.forEach((x) => {
      if (e.target.value === x.innerHTML && x.value === x.innerHTML) {
        setLoanTermFrequencyType(x.id);
      }
    });
  };
  //HANDLE NUMBER OF REPAYMENTS
  const handleNoOfRepayment = (e) => setNumberOfRepayments(e.target.value);
  //HANDLE REPAYMENTS EVERY
  const handleRepaymentEvery = (e) => setRepaymentEvery(e.target.value);
  // HANDLE REPAYMENT FREQ TYPE
  const handleRepaymentFreqType = (e) => {
    setRepaymentFreqType(e.target.value);
    let arr = e.target.querySelectorAll("option");
    arr.forEach((x) => {
      if (e.target.value === x.innerHTML && x.value === x.innerHTML) {
        setRepaymentFrequencyType(x.id);
      }
    });
  };
  // HANDLE TERM FREQ DAY TYPE
  const handleRepaymentFreqDayType = (e) => {
    setRepaymentFreqDayType(e.target.value);
    let arr = e.target.querySelectorAll("option");
    arr.forEach((x) => {
      if (e.target.value === x.innerHTML && x.value === x.innerHTML) {
        setRepaymentFrequencyDayOfWeekType(x.id);
      }
    });
  };
  // HANDLE TERM FREQ NTH DAY TYPE
  const handleRepaymentFreqNthDayType = (e) => {
    setRepaymentFreqNthDayType(e.target.value);
    let arr = e.target.querySelectorAll("option");
    arr.forEach((x) => {
      if (e.target.value === x.innerHTML && x.value === x.innerHTML) {
        setRepaymentFrequencyNthDayType(x.id);
      }
    });
  };
  // HANDLE INTEREST CALCULATION TYPE
  const handleIntCalcPerType = (e) => {
    setInterestCalculationPeriod(e.target.value);
    let arr = e.target.querySelectorAll("option");
    arr.forEach((x) => {
      if (e.target.value === x.innerHTML && x.value === x.innerHTML) {
        setInterestCalculationPeriodType(x.id);
      }
    });
  };
  // HANDLE INTEREST TYPE
  const handleInterestType = (e) => {
    setIntType(e.target.value);
    let arr = e.target.querySelectorAll("option");
    arr.forEach((x) => {
      if (e.target.value === x.innerHTML && x.value === x.innerHTML) {
        setInterestType(x.id);
      }
    });
  };
  //HANDLE INTEREST RATE PERIOD
  const handleIntRatePer = (e) => setInterestRatePerPeriod(e.target.value);
  // HANDLE EQUAL AMORTIZATION
  // const handleEqualAmortization = (e) => {
  //   setEqualAmortization(e.target.value);
  //   let arr = e.target.querySelectorAll("option");
  //   arr.forEach((x) => {
  //     if (e.target.value === x.innerHTML && x.value === x.innerHTML) {
  //       setIsEqualAmortization(x.id);
  //     }
  //   });
  // };

  // const handleEqualAmortization = (e) => {
  //   setEqualAmortization(e.target.value);

  //   // If you need to set another state based on the selected option id
  //   let arr = e.target.querySelectorAll("option");
  //   arr.forEach((x) => {
  //     if (selectedOption === x.innerHTML && x.value === x.innerHTML) {
  //       setIsEqualAmortization(x.id);
  //     }
  //   });

  //   // Now you can send the boolean value to the backend or perform other actions
  //   // Example: sendBooleanValueToBackend(booleanValue);
  // };
  // HANDLE AMORTIZATION TYPE
  const handleAmortizationType = (e) => {
    setAmortization(e.target.value);
    let arr = e.target.querySelectorAll("option");
    arr.forEach((x) => {
      if (e.target.value === x.innerHTML) {
        setAmortizationType(x.id);
      }
    });
  };
  // HANDLE PARTIAL INTEREST PERIOD
  const handlePartialInterest = (e) => {
    setAllowPartialPeriodInterest(e.target.value);
    let arr = e.target.querySelectorAll("option");
    arr.forEach((x) => {
      if (e.target.value === x.innerHTML) {
        setAllowPartialPeriodInterestCalcualtion(x.id);
      }
    });
  };
  // HANDLE transaction Processing Strategy
  const handleProcessingStrategy = (e) => {
    setTransactionProcessingStrategy(e.target.value);
    let arr = e.target.querySelectorAll("option");
    arr.forEach((x) => {
      if (e.target.value === x.innerHTML) {
        setTransactionProcessingStrategyId(x.id);
      }
    });
  };
  //HANDLE ARREARS TOLERANCE
  const handleArrearsTolerance = (e) => setInArrearsTolerance(e.target.value);
  //HANDLE GACE ON INTEREST CHARGED
  const handleGraceOnInterestCharged = (e) =>
    setGraceOnInterestCharged(e.target.value);
  //HANDLE GACE ON PRINCIPAL PAY
  const handleGraceOnPrincipalPay = (val) => setGraceOnPrincipalPayment(val);
  //HANDLE GACE ON ARREARS AGEING PAY
  const handleGraceOnArrearsAgeing = (val) => setGraceOnArrearsAgeing(val);
  //HANDLE GACE ON INTEREST PAY
  const handleGraceOnIntestPay = (val) => setGraceOnInterestPayment(val);

  // HANDLE FIELD OFFICER
  const handleOfficer = (e) => {
    setLoanOfficer(e.target.value);
    let arr = e.target.querySelectorAll("option");
    arr.forEach((x) => {
      if (e.target.value === x.innerHTML && x.value === x.innerHTML) {
        setLoanOfficerId(x.id);
      }
    });
  };
  // HANDLE LINKED ACCOUNT
  const handleLinkedAccount = (e) => {
    setLinkedAccount(e.target.value);
    let arr = e.target.querySelectorAll("option");
    arr.forEach((x) => {
      if (e.target.value === x.innerHTML && x.value === x.innerHTML) {
        setLinkedAccountId(x.id);
      }
    });
  };
  // HANDLE LOaN FUND
  const handleFund = (e) => {
    setFund(e.target.value);
    let arr = e.target.querySelectorAll("option");
    arr.forEach((x) => {
      if (e.target.value === x.innerHTML) {
        // setProductId(x.id)
      }
    });
  };
  // HANDLE LOaN PURPOSE
  const handleLoanPurpose = (e) => {
    setLoanPurpose(e.target.value);
    let arr = e.target.querySelectorAll("option");
    arr.forEach((x) => {
      if (e.target.value === x.innerHTML) {
        // setLoanPurposeId(x.id)
      }
    });
  };

  const handleSelectedChargeDate = (selectedIndex, date) => {
    let mydata = [...myChargesArray];
    let newDate = new Date(date);
    mydata[Number(selectedIndex)].date = newDate;
    setMyChargesArray(mydata);
  };

  const handleSelectedOverdueChargeDate = (selectedIndex, date) => {
    let mydata = [...myOverdueChargesArray];
    let newDate = new Date(date);
    mydata[Number(selectedIndex)].date = newDate;
    setMyOverdueChargesArray(mydata);
  };

  const handleRepaymentsChange = (index, repayments) => {
    let hhh = [...myChargesArray];
    hhh[Number(index)].repayments = repayments;
    setMyChargesArray(hhh);
  };

  const handleDeleteChargeData = (position) => {
    let noo = [];
    myChargesArray.forEach((element, index) => {
      if (position !== index) {
        noo.push(element);
      }
    });
    setMyChargesArray(noo);
  };

  // HANDLE CHARGE ID
  const handleCharge = (e) => {
    setCharge(e.target.value);
    let arr = e.target.querySelectorAll("option");

    arr.forEach((x) => {
      if (e.target.value == x.innerHTML) {
        setChargeId(x.id);
      }
    });
  };

  // const handleAddCharge = async () => {
  //   await dispatch(getLoansChargeOptionData(chargeId));

  //   await dispatch(addLoansCharge(productData));

  // };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(getLoansProductOptions(match.params.id));
    };
    fetchData();

    if (productData.charges) {
      setChargesArray(productData.charges);
      setMyChargesArray(
        productData.charges.map((charge) => {
          return {
            index: myChargesArray.length,
            name: charge.name,
            chargeId: charge.chargeId,
            amount: charge.amount,
            type: charge.chargeCalculationType.value,
            chargeTimeTypeName: charge.chargeTimeType.value,
            chargeTimeType: charge.chargeTimeType.id,
            date: !!charge?.feeOnMonthDay
              ? formatDate(charge.feeOnMonthDay, true)
              : "",
          };
        })
      );
    }
  }, [match, productData]);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(getLoansProductOptions(match.params.id));
    };
    fetchData();

    if (productData.overdueCharges) {
      setOverdueChargesArray(productData.overdueCharges);
      setMyOverdueChargesArray(
        productData.overdueCharges.map((charge) => {
          return {
            index: myOverdueChargesArray.length,
            name: charge.name,
            chargeId: charge.id,
            amount: charge.amount,
            type: charge.chargeCalculationType.value,
            chargeTimeType: charge.chargeTimeType.id,
            chargeTimeTypeName: charge.chargeTimeType.value,
            date: !!charge?.feeOnMonthDay
              ? formatDate(charge.feeOnMonthDay, true)
              : "",
          };
        })
      );
    }
  }, [match, productData]);

  //HANDLE SUBMITTING DATA FOR ACCOUNT CREATION
  const reviewData = {
    fund,
    productType,
    purposeType,
    // loanPurpose,
    submittedOnDate,
    loanOfficer,
    // loanPurpose,
    externalId,
    transactionProcessingStrategy,
    amortization,
    repaymentFrequencyType,
    principal,
    numberOfRepayments,
    interestRatePerPeriod,
    expectedDisbursementDate,
    loanTermFrequencyType,
    loanTermFrequency,
    isEqualAmortization: newLoanProduct.isEqualAmortization,
    interestType,
    repaymentsStartingFromDate,
    intType,
    repaymentEvery,
    repaymentFreqType,
    repaymentStartingFrom,
    interestChargedFrom,
  };

  let newCharges = [];
  let newOverdueCharges = [];

  myChargesArray.map((charge) => {
    let { chargeId, amount, date } = charge;

    newCharges.push({
      chargeId,
      amount,
      dueDate: !!date ? formatDate(date, true) : "",
    });
  });

  // myOverdueChargesArray.map((charge) => {
  //   let { chargeId, amount, date} = charge;

  //   newOverdueCharges.push({
  //     chargeId,
  //     amount,
  //     dueDate: !!date ? formatDate(date, true) : ""

  //   })
  // })

  const loanData = {
    productId,
    externalId,
    submittedOnDate,
    expectedDisbursementDate,
    loanOfficerId,
    principal,
    linkAccountId: newLoanProduct.linkedAccount,
    loanPurposeId: purposeId,
    // loanPurposeId: newLoanProduct.loanPurpose,
    // loanPurposeId,
    // fund,
    // charges: [...newCharges, ...newOverdueCharges],
    charges: newCharges,
    loanTermFrequencyType,
    loanTermFrequency,
    numberOfRepayments,
    repaymentEvery,
    repaymentFrequencyType,
    repaymentFrequencyDayOfWeekType,
    repaymentFrequencyNthDayType,
    interestCalculationPeriodType,
    interestRatePerPeriod,
    interestType,
    amortizationType,
    isEqualAmortization: newLoanProduct.isEqualAmortization,
    allowPartialPeriodInterestCalcualtion,
    transactionProcessingStrategyId,
    inArrearsTolerance,
    graceOnInterestCharged,
    graceOnPrincipalPayment,
    graceOnArrearsAgeing,
    graceOnInterestPayment,
    createStandingInstructionAtDisbursement:
      newLoanProduct.createStandingInstructionAtDisbursement,
    repaymentsStartingFromDate: formatDate(repaymentStartingFrom),
    interestChargedFromDate: formatDate(interestChargedFrom),
    disbursementData,
    locale: "en",
    dateFormat: "dd MMM yyyy",
    loanType,
    clientId: match.params.id,
    collateral: collaterals?.map(collateral => ({
      type: Number(collateral?.type),
      value: collateral?.value,
      description: collateral?.description
    })),
  };

  const handleSubmit = async () => {
    dispatch(createLoanAccount(loanData, history));
  };

  const [currentScreen, setCurrentScreen] = useState(0);

  const [showSchedule, setShowSchedule] = useState(false);

  const toggleSchedule = () => setShowSchedule(state => !state);

  const generateRepaymentSchedule = () => {
    const payload = {
      clientId: match.params.id,
      productId,
      principal,
      loanTermFrequency,
      loanTermFrequencyType,
      numberOfRepayments,
      repaymentEvery,
      repaymentFrequencyType,
      interestRatePerPeriod,
      amortizationType,
      isEqualAmortization: newLoanProduct.isEqualAmortization,
      interestType,
      interestCalculationPeriodType,
      allowPartialPeriodInterestCalcualtion,
      inArrearsTolerance,
      graceOnPrincipalPayment,
      graceOnInterestPayment,
      transactionProcessingStrategyId,
      loanPurposeId: purposeId,
      charges: newCharges,
      loanType,
      expectedDisbursementDate,
      submittedOnDate,
      locale: "en",
      dateFormat: "dd MMMM yyyy",
      repaymentFrequencyNthDayType: repaymentFreqNthDayType,
      repaymentFrequencyDayOfWeekType: repaymentFreqDayType,
      // fundId: '',
      // rates: [],
      // disbursementData: [],
    }
    dispatch(calculateRepaymentSchedule(payload))
  }

  const renderComponent = () => {
    let currentComponet = "";
    switch (currentScreen) {
      case 0:
        currentComponet = (
          <AppDetails>
            <LoanDetails
              handleNextScreen={handleNextCustomIndexSelect}
              productType={productType}
              handleProductType={handleProductType}
              purposeType={purposeType}
              handlePurposeType={handlePurposeType}
              externalId={externalId}
              handleExternalId={handleExternalId}
              submittedOnDate={submittedDate}
              handleSubmittedOn={handleSubmittedOn}
              disbursementDate={disbursementDate}
              handleDisbursementDate={handleDisbursementDate}
              handleOfficer={handleOfficer}
              loanOfficer={loanOfficer}
              handleLoanPurpose={handleLoanPurpose}
              loanPurpose={newLoanProduct.loanPurpose}
              handleFund={handleFund}
              fund={fund}
              handleLinkedAccount={handleLinkedAccount}
              linkedAccount={newLoanProduct.linkedAccount}
              createStandingInstructionAtDisbursement={
                newLoanProduct.createStandingInstructionAtDisbursement
              }
              setNewLoanProduct={setNewLoanProduct}
              createLoanProductHandler={createLoanProductHandler}
              // setCreateStandingInstructionAtDisbursement={
              //   setCreateStandingInstructionAtDisbursement
              // }
              // createStandingInstructionAtDisbursement={
              //   createStandingInstructionAtDisbursement
              // }
            />
          </AppDetails>
        );
        break;
      case 1:
        currentComponet = (
          <AppTerms>
            <LoanTerms
              handleNextScreen={handleNextCustomIndexSelect}
              handlePreviousScreen={handlePreviousCustomIndexSelect}
              principal={principal}
              handlePrincipal={handlePrincipal}
              loanTermFreqType={loanTermFreqType}
              handleLoanTermFreqType={handleLoanTermFreqType}
              loanTermFrequency={loanTermFrequency}
              handleLoanTermFreq={handleLoanTermFreq}
              numberOfRepayments={numberOfRepayments}
              handleNoOfRepayment={handleNoOfRepayment}
              repaymentEvery={repaymentEvery}
              handleRepaymentEvery={handleRepaymentEvery}
              repaymentFreqType={repaymentFreqType}
              handleRepaymentFreqType={handleRepaymentFreqType}
              repaymentFreqDayType={repaymentFreqDayType}
              handleRepaymentFreqDayType={handleRepaymentFreqDayType}
              repaymentFreqNthDayType={repaymentFreqNthDayType}
              handleRepaymentFreqNthDayType={handleRepaymentFreqNthDayType}
              interestCalculationPeriod={interestCalculationPeriod}
              handleIntCalcPerType={handleIntCalcPerType}
              interestRatePerPeriod={interestRatePerPeriod}
              handleIntRatePer={handleIntRatePer}
              intType={intType}
              handleInterestType={handleInterestType}
              amortization={amortization}
              handleAmortizationType={handleAmortizationType}
              equalAmortization={equalAmortization}
              // handleEqualAmortization={handleEqualAmortization}
              isEqualAmortization={newLoanProduct.isEqualAmortization}
              // setIsEqualAmortization={setIsEqualAmortization}
              allowPartialPeriodInterest={allowPartialPeriodInterest}
              handlePartialInterest={handlePartialInterest}
              transactionProcessingStrategy={transactionProcessingStrategy}
              handleProcessingStrategy={handleProcessingStrategy}
              inArrearsTolerance={inArrearsTolerance}
              handleArrearsTolerance={handleArrearsTolerance}
              graceOnInterestCharged={graceOnInterestCharged}
              handleGraceOnInterestCharged={handleGraceOnInterestCharged}
              graceOnPrincipalPayment={graceOnPrincipalPayment}
              handleGraceOnPrincipalPay={handleGraceOnPrincipalPay}
              graceOnArrearsAgeing={graceOnArrearsAgeing}
              handleGraceOnArrearsAgeing={handleGraceOnArrearsAgeing}
              graceOnInterestPayment={graceOnInterestPayment}
              handleGraceOnIntestPay={handleGraceOnIntestPay}
              repaymentStartingFrom={repaymentStartingFrom}
              handleRepaymentStartingFrom={handleRepaymentStartingFrom}
              interestChargedFrom={interestChargedFrom}
              handleInterestChargedFrom={handleInterestChargedFrom}
              setNewLoanProduct={setNewLoanProduct}
              collaterals={collaterals}
              setCollaterals={setCollaterals}
              clientId={match.params.id}
            />
          </AppTerms>
        );
        break;
      case 2:
        currentComponet = (
          <AppCharges>
            {showSchedule ? (
              <PreviewRepaymentSchedule
                generateRepaymentSchedule={generateRepaymentSchedule}
                toggleSchedule={toggleSchedule}
              />
            ) : (
              <LoanCharges
                radio={accountType}
                setChargesError={setChargesError}
                setRadio={setAccountType}
                handleNextScreen={handleNextCustomIndexSelect}
                handlePreviousScreen={handlePreviousCustomIndexSelect}
                createLoanProductHandler={createLoanProductHandler}
                template={template}
                fundSource={newLoanProduct.fundSource}
                loanPortfolio={newLoanProduct.loanPortfolio}
                transferInSusp={newLoanProduct.transferInSusp}
                incomeFromInterest={newLoanProduct.incomeFromInterest}
                incomeFromFees={newLoanProduct.incomeFromFees}
                incomeFromPenalties={newLoanProduct.incomeFromPenalties}
                incomeFromRecovery={newLoanProduct.incomeFromRecovery}
                overPaymentLia={newLoanProduct.overPaymentLia}
                lossesWrittenOff={newLoanProduct.lossesWrittenOff}
                interestReceivable={newLoanProduct.interestReceivable}
                feesReceivable={newLoanProduct.feesReceivable}
                penaltiesReceivable={newLoanProduct.penaltiesReceivable}
                canDefineInstallmentAmount={
                  newLoanProduct.canDefineInstallmentAmount
                }
                numberOfDaysLoan={newLoanProduct.numberOfDaysLoan}
                accountMovesOutOfNPAOnlyOnArrearsCompletion={
                  newLoanProduct.accountMovesOutOfNPAOnlyOnArrearsCompletion
                }
                graceOnArrearsAgeingValue={
                  newLoanProduct.graceOnArrearsAgeingValue
                }
                overdueDaysForNPA={newLoanProduct.overdueDaysForNPA}
                principalThresholdForLastInstallment={
                  newLoanProduct.principalThresholdForLastInstallment
                }
                isVariableInstallment={newLoanProduct.isVariableInstallment}
                minimumGap={newLoanProduct.minimumGap}
                maximumGap={newLoanProduct.maximumGap}
                canUseForTopup={newLoanProduct.canUseForTopup}
                isInterestRecalculationEnabled={
                  newLoanProduct.isInterestRecalculationEnabled
                }
                preClosureInterestCalculationStrategy={
                  newLoanProduct.preClosureInterestCalculationStrategy
                }
                recalculationRestFrequencyInterval={
                  newLoanProduct.recalculationRestFrequencyInterval
                }
                interestRecalculationCompoundingMethod={
                  newLoanProduct.interestRecalculationCompoundingMethod
                }
                rescheduleStrategyMethod={newLoanProduct.rescheduleStrategyMethod}
                recalculationRestFrequencyType={
                  newLoanProduct.recalculationRestFrequencyType
                }
                recalculationCompoundingFrequencyInterval={
                  newLoanProduct.recalculationCompoundingFrequencyInterval
                }
                recalculationCompoundingFrequencyType={
                  newLoanProduct.recalculationCompoundingFrequencyType
                }
                recalculationCompoundingFrequencyNthDayType={
                  newLoanProduct.recalculationCompoundingFrequencyNthDayType
                }
                recalculationCompoundingFrequencyDayOfWeekType={
                  newLoanProduct.recalculationCompoundingFrequencyDayOfWeekType
                }
                recalculationCompoundingFrequencyOnDayType={
                  newLoanProduct.recalculationCompoundingFrequencyOnDayType
                }
                isArrearsBasedOnOriginalSchedule={
                  newLoanProduct.isArrearsBasedOnOriginalSchedule
                }
                recalculationRestFrequencyDayOfWeekType={
                  newLoanProduct.recalculationRestFrequencyDayOfWeekType
                }
                recalculationRestFrequencyOnDayType={
                  newLoanProduct.recalculationRestFrequencyOnDayType
                }
                recalculationRestFrequencyNthDayType={
                  newLoanProduct.recalculationRestFrequencyNthDayType
                }
                placeGuarantee={newLoanProduct.placeGuarantee}
                mandatoryGuarantee={newLoanProduct.mandatoryGuarantee}
                minimumGuaranteeFromOwnFunds={
                  newLoanProduct.minimumGuaranteeFromOwnFunds
                }
                minimumGuaranteeFromGuarantor={
                  newLoanProduct.minimumGuaranteeFromGuarantor
                }
                loanTrancte={newLoanProduct.loanTrancte}
                maxTrancheCount={newLoanProduct.maxTrancheCount}
                outstandingLoanBalance={newLoanProduct.outstandingLoanBalance}
                setFullOverdueCharges={setFullOverdueCharges}
                setFullCharges={setFullCharges}
                feeToIncomeAccountMappings={feeToIncomeAccountMappings}
                penaltyToIncomeAccountMappings={penaltyToIncomeAccountMappings}
                paymentChannelToFundSourceMappings={
                  paymentChannelToFundSourceMappings
                }
                setFeeToIncomeAccountMappings={setFeeToIncomeAccountMappings}
                setPenaltyToIncomeAccountMappings={
                  setPenaltyToIncomeAccountMappings
                }
                setPaymentChannelToFundSourceMappings={
                  setPaymentChannelToFundSourceMappings
                }
                // setValueCharges={setValueCharges}
                // valueCharges={valueCharges}
                chargesArray={chargesArray}
                myChargesArray={myChargesArray}
                myOverdueChargesArray={myOverdueChargesArray}
                overdueChargesArray={overdueChargesArray}
                setValueOverdueCharges={setValueOverdueCharges}
                valueOverdueCharges={valueOverdueCharges}
                handleSelectedChargeDate={handleSelectedChargeDate}
                handleSelectedOverdueChargeDate={handleSelectedOverdueChargeDate}
                handleDeleteChargeData={handleDeleteChargeData}
                handleRepaymentsChange={handleRepaymentsChange}
                charge={charge}
                handleChargeId={handleCharge}
                setMyChargesArray={setMyChargesArray}
                setChargesArray={setChargesArray}
                setMyOverdueChargesArray={setMyOverdueChargesArray}
                setOverdueChargesArray={setOverdueChargesArray}
                toggleSchedule={toggleSchedule}
                // handleAddCharge={handleAddCharge}
              />
            )}
          </AppCharges>
        );
        break;
      case 3:
        currentComponet = (
          <AppReviews>
            <LoanReview
              handlePreviousScreen={handlePreviousCustomIndexSelect}
              reviewData={reviewData}
              handleSubmit={handleSubmit}
              principal={principal}
              repaymentEvery={repaymentEvery}
              myChargesArray={myChargesArray}
              myOverdueChargesArray={myOverdueChargesArray}
              repaymentFreqType={repaymentFreqType}
              repaymentFreqDayType={repaymentFreqDayType}
              loanPurpose={newLoanProduct.loanPurpose}
              createStandingInstructionAtDisbursement={
                newLoanProduct.createStandingInstructionAtDisbursement
              }
              transactionProcessingStrategy={transactionProcessingStrategy}
              amortization={amortization}
              intType={intType}
              interestRatePerPeriod={interestRatePerPeriod}
              newLoanProduct={newLoanProduct}
              collaterals={collaterals}
            />
          </AppReviews>
        );
        break;
      default:
    }
    return currentComponet;
  };

  // FUNCTION HERE FOR MOVING TO THE NEXT PAGE
  const handleNextCustomIndexSelect = () => {
    let newIndex = currentScreen + 1;
    if (newIndex <= 3) {
      setCurrentScreen(newIndex);
    }
  };
  // FUNCTION HERE FOR MOVING TO THE PREVIOUS PAGE
  const handlePreviousCustomIndexSelect = () => {
    let newIndex = currentScreen - 1;
    if (newIndex >= 0) {
      setCurrentScreen(newIndex);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3 mt-1">
            <h3 className="page-title">Loan Application</h3>
            <p className="text-muted sub-title">Create a new Loan</p>
            <p className="font-weight sub-title mt-2">
              <span className="text-info">
                <Link to="/core/clients">Customer</Link>
              </span>
              /{" "}
              <span className="text-info">
                <span className="text-info">
                  <Link to={`/core/client/${match.params.id}`}>
                    {!!productData?.clientName
                      ? productData?.clientName
                      : "Customer"}
                  </Link>
                </span>
              </span>
              / Loan Application
            </p>
          </div>
        </div>

        <div className="row" style={{ minHeight: "calc(100vh - 250px)" }}>
          <div className="col-sm-12 col-md-12">
            <ScrollToTop>
              <ContentWrapper className="application-wrapper">
                {renderComponent()}
              </ContentWrapper>
            </ScrollToTop>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoanApplicationScreen;
