import React, { useState } from "react";
import { NewActionButton, FormClass, ModalText } from "./styles";
import { Modal, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  deleteCharge,
  getAllCharges,
} from "../../../../actions/configurationActions";

const DeleteCharge = ({ onHide, id }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const handleDelete = async () => {
    setLoading(true);
    const func = () => {
      dispatch(getAllCharges());
      setLoading(false);
      onHide();
    };
    await dispatch(deleteCharge(id, func));
    setLoading(false);
  };
  return (
    <>
      <Modal.Header>
        <Modal.Title>Delete Charge</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "95%" }}>
          <ModalText>Are you sure you want to delete this charge?</ModalText>
        </FormClass>
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-1">
          <NewActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require("../../../../assets/images/icons/cancel.svg").default
              }
              alt="cancel icon"
            />
            Cancel
          </NewActionButton>
          <NewActionButton
            onClick={handleDelete}
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
          >
            {loading ? (
              <Spinner
                animation="border"
                color="#fff"
                className="mr-2 text-white"
                size="sm"
              />
            ) : (
              <img
                src={
                  require("../../../../assets/images/icons/delete.svg").default
                }
                alt="submit icon"
              />
            )}
            Delete
          </NewActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default DeleteCharge;
