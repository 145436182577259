import styled from "styled-components";

export const ActionButtonsGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

export const ActionButton = styled.button`
  background: #2C1DFF !important;
  border-radius: 5px !important;
  color: #fff !important;
  margin: 0 10px;
  border: none;
  display: inline;
  font-size: 14px;
  height: 40px;
  font-family: 'AventaMedium';
  max-width: 180px;
  min-width: 150px;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  img {
    height: 14px;
    display: inline;
    margin-right: 10px;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;

export const ContentWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 0px #ededf6;
  min-height: 70vh;
  margin-top: 20px;
  table {
    background: #ffffff;
    border: 1px solid rgba(46, 91, 255, 0.08) !important;
    box-sizing: border-box;
    border-radius: 1px;

    .md-font-weight {
      font-style: normal;
      font-size: 1rem;
      line-height: 20px;
    }

    td {
      font-size: 1rem;
      font-weight: 700;
      line-height: 19px;
      color: #2e384d;
    }
  }
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #f7f8fc;
  }
`;
