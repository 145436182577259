import React from "react";
import { Table } from "antd";
import Loading from "./Loading";

const columns = [
  { title: "Entry ID", dataIndex: "entryId", key: "entryId" },
  { title: "Office", dataIndex: "office", key: "office" },
  {
    title: "Transaction Date",
    dataIndex: "transactionDate",
    key: "transactionDate",
  },
  {
    title: "Transaction ID",
    dataIndex: "transactionId",
    key: "transactionId",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  { title: "Created By", dataIndex: "createdBy", key: "createdBy" },
  {
    title: "Account",
    dataIndex: "account",
    key: "account",
  },
  {
    title: "Debit",
    dataIndex: "debit",
    key: "debit",
  },
  {
    title: "Credit",
    dataIndex: "credit",
    key: "credit",
  },
];

const TableLoader = (props) => {
  const someChartsData = [
    {
      entryId: <Loading />,
      office: <Loading />,
      transactionDate: <Loading />,
      transactionId: <Loading />,
      type: <Loading />,
      createdBy: <Loading />,
      account: <Loading />,
      debit: <Loading />,
      credit: <Loading />,
    },
    {
      entryId: <Loading />,
      office: <Loading />,
      transactionDate: <Loading />,
      transactionId: <Loading />,
      type: <Loading />,
      createdBy: <Loading />,
      account: <Loading />,
      debit: <Loading />,
      credit: <Loading />,
    },
    {
      entryId: <Loading />,
      office: <Loading />,
      transactionDate: <Loading />,
      transactionId: <Loading />,
      type: <Loading />,
      createdBy: <Loading />,
      account: <Loading />,
      debit: <Loading />,
      credit: <Loading />,
    },
    {
      entryId: <Loading />,
      office: <Loading />,
      transactionDate: <Loading />,
      transactionId: <Loading />,
      type: <Loading />,
      createdBy: <Loading />,
      account: <Loading />,
      debit: <Loading />,
      credit: <Loading />,
    },
    {
      entryId: <Loading />,
      office: <Loading />,
      transactionDate: <Loading />,
      transactionId: <Loading />,
      type: <Loading />,
      createdBy: <Loading />,
      account: <Loading />,
      debit: <Loading />,
      credit: <Loading />,
    },
  ];
  return (
    <>
      <Table
        columns={columns}
        dataSource={someChartsData}
        pagination={{ defaultPageSize: 8 }}
        className="antdTable"
        defaultPageSize={8}
      />
    </>
  );
};

export default TableLoader;
