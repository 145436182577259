import React from "react";
import { Table } from "antd";
import Loading from "./Loading";

const columns = [
  { title: "Office", dataIndex: "office", key: "office" },
  {
    title: "Account closure date",
    dataIndex: "accountClosureDate",
    key: "accountClosureDate",
  },
  { title: "Comment", dataIndex: "comment", key: "comment" },
  {
    title: "Close Accounting Created by",
    dataIndex: "closeAccountingCreatedBy",
    key: "closeAccountingCreatedBy",
  },
];

const TableLoader = (props) => {
  const someChartsData = [
    {
      office: <Loading />,
      accountClosureDate: <Loading />,
      comment: <Loading />,
      closeAccountingCreatedBy: <Loading />,
      selectedClosure: <Loading />,
    },
    {
      office: <Loading />,
      accountClosureDate: <Loading />,
      comment: <Loading />,
      closeAccountingCreatedBy: <Loading />,
      selectedClosure: <Loading />,
    },
    {
      office: <Loading />,
      accountClosureDate: <Loading />,
      comment: <Loading />,
      closeAccountingCreatedBy: <Loading />,
      selectedClosure: <Loading />,
    },
    {
      office: <Loading />,
      accountClosureDate: <Loading />,
      comment: <Loading />,
      closeAccountingCreatedBy: <Loading />,
      selectedClosure: <Loading />,
    },
    {
      office: <Loading />,
      accountClosureDate: <Loading />,
      comment: <Loading />,
      closeAccountingCreatedBy: <Loading />,
      selectedClosure: <Loading />,
    },
  ];
  return (
    <>
      <Table
        columns={columns}
        dataSource={someChartsData}
        pagination={{ defaultPageSize: 8 }}
        className="antdTable"
        defaultPageSize={8}
      />
    </>
  );
};

export default TableLoader;
