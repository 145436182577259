import {
  ALL_CUSTOMERS_REQUEST,
  ALL_CUSTOMERS_SUCCESS,
  ALL_CUSTOMERS_FAIL,
  SINGLE_CUSTOMER_REQUEST,
  SINGLE_CUSTOMER_SUCCESS,
  SINGLE_CUSTOMER_FAIL,
  SINGLE_CUSTOMER_RESET,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAIL,
  UPDATE_CUSTOMER_ADDRESS_REQUEST,
  UPDATE_CUSTOMER_ADDRESS_SUCCESS,
  UPDATE_CUSTOMER_ADDRESS_FAIL,
  CUSTOMER_ADDRESS_REQUEST,
  CUSTOMER_ADDRESS_SUCCESS,
  CUSTOMER_ADDRESS_FAIL,
  CREATE_NEW_CUSTOMER_REQUEST,
  CREATE_NEW_CUSTOMER_SUCCESS,
  CREATE_NEW_CUSTOMER_FAIL,
  CREATE_NEW_CUSTOMER_RESET,
  IMAGE_UPLOAD_REQUEST,
  IMAGE_UPLOAD_SUCCESS,
  IMAGE_UPLOAD_FAIL,
  IMAGE_UPDATE_REQUEST,
  IMAGE_UPDATE_SUCCESS,
  IMAGE_UPDATE_FAIL,
  GET_IMAGE_REQUEST,
  GET_IMAGE_SUCCESS,
  GET_IMAGE_FAIL,
  GET_IMAGE_RESET,
  DOCUMENT_UPLOAD_SUCCESS,
  DOCUMENT_UPLOAD_REQUEST,
  DOCUMENT_UPLOAD_FAIL,
  GET_DOCUMENTS_REQUEST,
  GET_DOCUMENTS_SUCCESS,
  GET_DOCUMENTS_FAIL,
  GET_DOCUMENTS_RESET,
  DELETE_DOCUMENT_REQUEST,
  DELETE_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_FAIL,
  SEARCH_CUSTOMERS_REQUEST,
  SEARCH_CUSTOMERS_SUCCESS,
  SEARCH_CUSTOMERS_FAIL,
  FILTER_CUSTOMERS_REQUEST,
  CLEAR_FILTER,
  GET_CHARGES_REQUEST,
  GET_CHARGES_SUCCESS,
  GET_CHARGES_FAIL,
  GET_CUSTOMER_ACCOUNTS_REQUEST,
  GET_CUSTOMER_ACCOUNTS_SUCCESS,
  GET_CUSTOMER_ACCOUNTS_FAIL,
  MAKE_DEPOSIT_REQUEST,
  MAKE_DEPOSIT_SUCCESS,
  MAKE_DEPOSIT_FAIL,
  MAKE_WITHDRAWAL_REQUEST,
  MAKE_WITHDRAWAL_SUCCESS,
  MAKE_WITHDRAWAL_FAIL,
  GET_LOAN_DETAILS_REQUEST,
  GET_LOAN_DETAILS_SUCCESS,
  GET_LOAN_DETAILS_FAIL,
  GET_LOAN_DETAILS_RESET,
  LOAN_APPROVAL_REQUEST,
  LOAN_APPROVAL_SUCCESS,
  LOAN_APPROVAL_FAIL,
  LOAN_DISBURSAL_REQUEST,
  LOAN_DISBURSAL_SUCCESS,
  LOAN_DISBURSAL_FAIL,
  CREATE_SAVINGS_FAIL,
  CREATE_SAVINGS_REQUEST,
  CREATE_SAVINGS_SUCCESS,
  CREATE_LOAN_REQUEST,
  CREATE_LOAN_SUCCESS,
  CREATE_LOAN_FAIL,
  ALL_CUSTOMERS_PAGINATION,
  DOWNLOAD_ACCOUNT_STATEMENT,
  DOWNLOAD_ACCOUNT_STATEMENT_SUCCESS,
  DOWNLOAD_ACCOUNT_STATEMENT_FAIL,
  DOWNLOAD_ACCOUNT_STATEMENT_RESET,
  DOWNLOAD_TRANSACTION_RECEIPT,
  DOWNLOAD_TRANSACTION_RECEIPT_SUCCESS,
  DOWNLOAD_TRANSACTION_RECEIPT_FAIL,
  DOWNLOAD_TRANSACTION_RECEIPT_RESET,
  DOWNLOAD_REPAYMENT_SCHEDULE,
  DOWNLOAD_REPAYMENT_SCHEDULE_SUCCESS,
  DOWNLOAD_REPAYMENT_SCHEDULE_FAIL,
  DOWNLOAD_REPAYMENT_SCHEDULE_RESET,
  GET_LOAN_LETTER,
  GET_LOAN_LETTER_SUCCESS,
  GET_LOAN_LETTER_FAIL,
  GET_LOAN_OFFER_LETTER,
  GET_LOAN_OFFER_LETTER_SUCCESS,
  GET_LOAN_OFFER_LETTER_FAIL,
  SAVINGS_ACCOUNT_FAIL,
  SAVINGS_ACCOUNT_SUCCESS,
  SAVINGS_ACCOUNT_REQUEST,
  UPDATE_SAVINGS_ACCOUNT_REQUEST,
  UPDATE_SAVINGS_ACCOUNT_SUCCESS,
  UPDATE_SAVINGS_ACCOUNT_FAIL,
  SAVINGS_ACCOUNT_RESET,
  UPDATE_CLIENT_TIER_REQUEST,
  UPDATE_CLIENT_TIER_SUCCESS,
  UPDATE_CLIENT_TIER__FAIL,
  LOAN_ACCOUNT_SUCCESS,
  LOAN_ACCOUNT_REQUEST,
  LOAN_ACCOUNT_FAIL,
  LOAN_ACCOUNT_RESET,
  UPDATE_LOAN_ACCOUNT_REQUEST,
  UPDATE_LOAN_ACCOUNT_SUCCESS,
  UPDATE_LOAN_ACCOUNT_FAIL,
  ALL_COUNTRY_REQUEST,
  ALL_COUNTRY_SUCCESS,
  ALL_COUNTRY_FAIL,
  ALL_STATES_REQUEST,
  ALL_STATES_SUCCESS,
  ALL_STATES_FAIL,
  FETCH_CUSTOMER_NOTES_REQUEST,
  FETCH_CUSTOMER_NOTES_SUCCESS,
  FETCH_CUSTOMER_NOTES_FAIL,
  CREATE_CUSTOMER_NOTE_REQUEST,
  CREATE_CUSTOMER_NOTE_FAIL,
  CREATE_CUSTOMER_NOTE_SUCCESS,
  UPDATE_CUSTOMER_NOTE_FAIL,
  DELETE_CUSTOMER_NOTE_FAIL,
  DELETE_CUSTOMER_NOTE_REQUEST,
  UPDATE_CUSTOMER_NOTE_SUCCESS,
  DELETE_CUSTOMER_NOTE_SUCCESS,
  UPDATE_CUSTOMER_NOTE_REQUEST,
  CLEAR_CUSTOMER_ACCOUNTS,
  CLEAR_LOAN_OFFER_LETTER,
} from "../constants/customerConstants";

export const setGetAllCustomer = (
  state = { customersPagination: {} },
  action
) => {
  switch (action.type) {
    case ALL_CUSTOMERS_PAGINATION:
      return { ...state, customersPagination: action.payload };
    default:
      return state;
  }
};

export default (state = { customers: [] }, action) => {
  switch (action.type) {
    case ALL_CUSTOMERS_REQUEST:
      return { ...state, loading: true };
    case ALL_CUSTOMERS_SUCCESS:
      return { ...state, loading: false, customers: action.payload };
    case ALL_CUSTOMERS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case FILTER_CUSTOMERS_REQUEST:
      return {
        ...state,
        filtered: state.customers.filter((cst) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          if (!!cst.legalForm && cst.legalForm.id === 2 && !!cst.fullname) {
            return (
              cst.fullname.match(regex) ||
              cst.accountNo.match(regex) ||
              (cst.externalId && cst.externalId.match(regex))
            );
          } else {
            return (
              cst.firstname.match(regex) ||
              cst.lastname.match(regex) ||
              cst.accountNo.match(regex) ||
              (cst.externalId && cst.externalId.match(regex))
            );
          }
        }),
      };
    case CLEAR_FILTER:
      return { ...state, filtered: null };
    default:
      return state;
  }
};

//REDUCER TO SEARCH ENTIRE CUSTOMER/USER DATABASE
export const searchCustomerReducer = (state = {}, action) => {
  switch (action.type) {
    case SEARCH_CUSTOMERS_REQUEST:
      return { ...state, loading: true };
    case SEARCH_CUSTOMERS_SUCCESS:
      return { ...state, nloading: false, searchedData: action.payload };
    case SEARCH_CUSTOMERS_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
//REDUCER FOR CREATE NEW CUSTOMER
export const createNewCustomerReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_NEW_CUSTOMER_REQUEST:
      return { loading: true, ...state };
    case CREATE_NEW_CUSTOMER_SUCCESS:
      return { ...state, loading: false, newCustomerData: action.payload };
    case CREATE_NEW_CUSTOMER_FAIL:
      return { ...state, loading: false, error: action.payload };
    case CREATE_NEW_CUSTOMER_RESET:
      return { ...state, newCustomerData: null, loading: false, error: null };
    default:
      return state;
  }
};

export const uploadCustomerImageReducer = (state = {}, action) => {
  switch (action.type) {
    case IMAGE_UPLOAD_REQUEST:
      return { loading: true, ...state };
    case IMAGE_UPLOAD_SUCCESS:
      return { ...state, loading: false, imgData: action.payload };
    case IMAGE_UPLOAD_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
export const updateCustomerImageReducer = (state = {}, action) => {
  switch (action.type) {
    case IMAGE_UPDATE_REQUEST:
      return { loading: true, ...state };
    case IMAGE_UPDATE_SUCCESS:
      return { ...state, loading: false, imgData: action.payload };
    case IMAGE_UPDATE_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
export const updateCustomerTier = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_CLIENT_TIER_REQUEST:
      return { loading: true, ...state };
    case UPDATE_CLIENT_TIER_SUCCESS:
      return { ...state, loading: false, res: action.payload };
    case UPDATE_CLIENT_TIER__FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
export const getCustomerImageReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_IMAGE_REQUEST:
      return { loading: true, ...state };
    case GET_IMAGE_SUCCESS:
      return { ...state, loading: false, imgData: action.payload };
    case GET_IMAGE_FAIL:
      return { ...state, loading: false, error: action.payload };
    case GET_IMAGE_RESET:
      return { ...state, imgData: null, loading: true };
    default:
      return state;
  }
};
export const getLoanLetters = (state = { loanLetters: [] }, action) => {
  switch (action.type) {
    case GET_LOAN_LETTER:
      return { loading: true, ...state };
    case GET_LOAN_LETTER_SUCCESS:
      return { ...state, loading: false, loanLetters: action.payload };
    case GET_LOAN_LETTER_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getLoanOfferLetters = (
  state = { loanOfferLetters: {} },
  action
) => {
  switch (action.type) {
    case GET_LOAN_OFFER_LETTER:
      return { loading: true, ...state };
    case GET_LOAN_OFFER_LETTER_SUCCESS:
      return { ...state, loading: false, loanOfferLetters: action.payload };
    case GET_LOAN_OFFER_LETTER_FAIL:
      return { ...state, loading: false, error: action.payload };
    case CLEAR_LOAN_OFFER_LETTER:
      return { ...state, loading: false, loanOfferLetters: {} };
    default:
      return state;
  }
};

export const uploadCustomerDocumentReducer = (state = {}, action) => {
  switch (action.type) {
    case DOCUMENT_UPLOAD_REQUEST:
      return { loading: true, ...state };
    case DOCUMENT_UPLOAD_SUCCESS:
      return { ...state, loading: false };
    case DOCUMENT_UPLOAD_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
//GET CUSTOMER DOCUMENTS
export const getCustomerDocumentReducer = (
  state = { documents: [] },
  action
) => {
  switch (action.type) {
    case GET_DOCUMENTS_REQUEST:
      return { loading: true, ...state };
    case GET_DOCUMENTS_SUCCESS:
      return { ...state, loading: false, documents: action.payload };
    case GET_DOCUMENTS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case GET_DOCUMENTS_RESET:
      return { ...state, documents: [], loading: false };
    default:
      return state;
  }
};
//DELETE DOCUMENT REDUCER
export const deleteDocumentReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_DOCUMENT_REQUEST:
      return { loading: true, ...state };
    case DELETE_DOCUMENT_SUCCESS:
      return { ...state, loading: false };
    case DELETE_DOCUMENT_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
//GET CUSTOMER charges
export const getCustomerChargesReducer = (state = { charges: [] }, action) => {
  switch (action.type) {
    case GET_CHARGES_REQUEST:
      return { loading: true, ...state };
    case GET_CHARGES_SUCCESS:
      return { ...state, loading: false, charges: action.payload };
    case GET_CHARGES_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
//GET CUSTOMER ACCOUNTS
export const getCustomerAccountsReducer = (
  state = { accounts: [] },
  action
) => {
  switch (action.type) {
    case GET_CUSTOMER_ACCOUNTS_REQUEST:
      return { loading: true, ...state };
    case GET_CUSTOMER_ACCOUNTS_SUCCESS:
      return { ...state, loading: false, accounts: action.payload };
    case GET_CUSTOMER_ACCOUNTS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case CLEAR_CUSTOMER_ACCOUNTS:
      return { ...state, loading: false, accounts: [] };
    default:
      return state;
  }
};
//REDUCER TO GET SINGLE CUSTOMER DETAILS
export const getSingleCustomerReducer = (
  state = { singleUser: {}, singleAdditionalInfo: {} },
  action
) => {
  switch (action.type) {
    case SINGLE_CUSTOMER_REQUEST:
      return { loading: true, ...state };
    case SINGLE_CUSTOMER_SUCCESS:
      return {
        loading: false,
        singleUser: action.payload.response,
        singleAdditionalInfo: action.payload.addResponse,
      };
    case SINGLE_CUSTOMER_FAIL:
      return { loading: false, error: action.payload };
    case SINGLE_CUSTOMER_RESET:
      return { ...state, singleUser: {}, loading: true };
    default:
      return state;
  }
};
//REDUCER TO UPDATE CUSTOMER DETAILS
export const updateCustomerInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_CUSTOMER_REQUEST:
      return { loading: true, ...state };
    case UPDATE_CUSTOMER_SUCCESS:
      return { loading: false, singleUser: action.payload };
    case UPDATE_CUSTOMER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER TO UPDATE CUSTOMER Address details
export const updateCustomerAddressReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_CUSTOMER_ADDRESS_REQUEST:
      return { loading: true, ...state };
    case UPDATE_CUSTOMER_ADDRESS_SUCCESS:
      return { loading: false, singleUser: action.payload };
    case UPDATE_CUSTOMER_ADDRESS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
//REDUCER TO GET CUSTOMER ADDRESS
export const getCustomerAddressReducer = (state = {}, action) => {
  switch (action.type) {
    case CUSTOMER_ADDRESS_REQUEST:
      return { loading: true };
    case CUSTOMER_ADDRESS_SUCCESS:
      return { loading: false, customerAddress: action.payload };
    case CUSTOMER_ADDRESS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER TO CREATE NEW SAVINGS
export const createSavingsReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_SAVINGS_REQUEST:
      return { loading: true, ...state };
    case CREATE_SAVINGS_SUCCESS:
      return { ...state, loading: false };
    case CREATE_SAVINGS_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER FOR SAVINGS ACCOUNT
export const savingsAccountReducer = (
  state = { savingsAccount: {} },
  action
) => {
  switch (action.type) {
    case SAVINGS_ACCOUNT_REQUEST:
      return { ...state, loading: true };
    case SAVINGS_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        savingsAccount: action.payload,
      };
    case SAVINGS_ACCOUNT_FAIL:
      return { ...state, loading: false, error: action.payload };
    case SAVINGS_ACCOUNT_RESET:
      return { ...state, savingsAccount: null, loading: false, error: null };
    default:
      return state;
  }
};

//REDUCER TO UPDATE SAVINGS ACCOUNT DETAILS
export const updateSavingsAccountInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_SAVINGS_ACCOUNT_REQUEST:
      return { loading: true, ...state };
    case UPDATE_SAVINGS_ACCOUNT_SUCCESS:
      return { loading: false, singleSavingsProduct: action.payload };
    case UPDATE_SAVINGS_ACCOUNT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER FOR LOAN ACCOUNT
// export const loanAccountReducer = (
//   state = { loanAccount: {} },
//   action
// ) => {
//   switch (action.type) {
//     case LOAN_ACCOUNT_REQUEST:
//       return { ...state, loading: true };
//     case LOAN_ACCOUNT_SUCCESS:
//       return {
//         ...state,
//         loading: false,
//         loanAccount: action.payload,
//       };
//     case LOAN_ACCOUNT_FAIL:
//       return { ...state, loading: false, error: action.payload };
//     case LOAN_ACCOUNT_RESET:
//       return { ...state, loanAccount: null, loading: false, error: null };
//     default:
//       return state;
//   }
// };

//REDUCER TO UPDATE SAVINGS ACCOUNT DETAILS
// export const updateLoanAccountInfoReducer = (state = {}, action) => {
//   switch (action.type) {
//     case UPDATE_LOAN_ACCOUNT_REQUEST:
//       return { loading: true, ...state };
//     case UPDATE_LOAN_ACCOUNT_SUCCESS:
//       return { loading: false, singleLoanProduct: action.payload };
//     case UPDATE_LOAN_ACCOUNT_FAIL:
//       return { loading: false, error: action.payload };
//     default:
//       return state;
//   }
// };

//REDUCER TO MAKE DEPOSIT
export const makeDepositReducer = (state = {}, action) => {
  switch (action.type) {
    case MAKE_DEPOSIT_REQUEST:
      return { loading: true, ...state };
    case MAKE_DEPOSIT_SUCCESS:
      return { ...state, loading: false };
    case MAKE_DEPOSIT_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER TO MAKE WITHDRAWAL
export const makeWithdrawalReducer = (state = {}, action) => {
  switch (action.type) {
    case MAKE_WITHDRAWAL_REQUEST:
      return { loading: true, ...state };
    case MAKE_WITHDRAWAL_SUCCESS:
      return { ...state, loading: false };
    case MAKE_WITHDRAWAL_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER TO CREATE NEW LOAN ACCOUNT
export const createLoanReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_LOAN_REQUEST:
      return { loading: true, ...state };
    case CREATE_LOAN_SUCCESS:
      return { ...state, loading: false };
    case CREATE_LOAN_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER TO MAKE DEPOSIT
export const downloadAccountStatementReducer = (
  state = { accountStatement: { status: "", file: "" } },
  action
) => {
  switch (action.type) {
    case DOWNLOAD_ACCOUNT_STATEMENT:
      // console.log(action.payload, "payload fom first reddddd");
      return { ...state, loading: true };
    case DOWNLOAD_ACCOUNT_STATEMENT_SUCCESS:
      // console.log(action.payload, "payload fom second  reddddd");
      return { loading: false, accountStatement: action.payload };
    case DOWNLOAD_ACCOUNT_STATEMENT_FAIL:
      return { loading: false, error: action.payload };
    case DOWNLOAD_ACCOUNT_STATEMENT_RESET:
      return { ...state, accountStatement: { status: "", file: "" } };
    default:
      return state;
  }
};

// REDUCER TO DOWNLOAD RECEIPT
export const downloadTransactionReceiptReducer = (
  state = { transactionReceipt: { status: "", file: "" } },
  action
) => {
  switch (action.type) {
    case DOWNLOAD_TRANSACTION_RECEIPT:
      // console.log(action.payload, "payload fom first reddddd");
      return { ...state, loading: true };
    case DOWNLOAD_TRANSACTION_RECEIPT_SUCCESS:
      // console.log(action.payload, "payload fom second  reddddd");
      return { loading: false, transactionReceipt: action.payload };
    case DOWNLOAD_TRANSACTION_RECEIPT_FAIL:
      return { loading: false, error: action.payload };
    case DOWNLOAD_TRANSACTION_RECEIPT_RESET:
      return { ...state, transactionReceipt: { status: "", file: "" } };
    default:
      return state;
  }
};

//REDUCER TO GET A LOAN DETAIL
export const downloadRepaymentScheduleReducer = (
  state = { repaymentSchedule: { status: "", file: "" } },
  action
) => {
  switch (action.type) {
    case DOWNLOAD_REPAYMENT_SCHEDULE:
      // console.log(action.payload, "payload fom reddddd iniiittt");
      return { ...state, loading: true };
    case DOWNLOAD_REPAYMENT_SCHEDULE_SUCCESS:
      // console.log(action.payload, "payload fom reddddd");
      return { loading: false, repaymentSchedule: action.payload };
    case DOWNLOAD_REPAYMENT_SCHEDULE_FAIL:
      return { loading: false, error: action.payload };
    case DOWNLOAD_REPAYMENT_SCHEDULE_RESET:
      return { ...state, repaymentSchedule: { status: "", file: "" } };
    default:
      return state;
  }
};
//REDUCER TO GET A LOAN DETAIL
export const getLoanDetailsReducer = (
  state = { loanDetails: { approvedPrincipal: "" } },
  action
) => {
  switch (action.type) {
    case GET_LOAN_DETAILS_REQUEST:
      // console.log(action.payload, "payload fom reddddd iniiittt");
      return { ...state, loading: true };
    case GET_LOAN_DETAILS_SUCCESS:
      // console.log(action.payload, "payload fom reddddd");
      return { loading: false, loanDetails: action.payload };
    case GET_LOAN_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case GET_LOAN_DETAILS_RESET:
      return { ...state, loanDetails: { approvedPrincipal: "" } };
    default:
      return state;
  }
};

//REDUCER TO APPROVE A LOAN
export const approveLoanReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAN_APPROVAL_REQUEST:
      return { loading: true, ...state };
    case LOAN_APPROVAL_SUCCESS:
      return { ...state, loading: false };
    case LOAN_APPROVAL_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER TO DISBURSE A LOAN
export const disburseLoanReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAN_DISBURSAL_REQUEST:
      return { loading: true, ...state };
    case LOAN_DISBURSAL_SUCCESS:
      return { ...state, loading: false };
    case LOAN_DISBURSAL_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getCountryReducer = (state = { countryData: [] }, action) => {
  switch (action.type) {
    case ALL_COUNTRY_REQUEST:
      return { loading: true, ...state };
    case ALL_COUNTRY_SUCCESS:
      return { ...state, loading: false, countryData: action.payload };
    case ALL_COUNTRY_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getStateReducer = (state = { stateData: [] }, action) => {
  switch (action.type) {
    case ALL_STATES_REQUEST:
      return { loading: true, ...state };
    case ALL_STATES_SUCCESS:
      return { ...state, loading: false , stateData: action.payload};
    case ALL_STATES_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const customerNotesReducer = (state = { notes: [] }, action) => {
  switch (action.type) {
    case FETCH_CUSTOMER_NOTES_REQUEST:
    case CREATE_CUSTOMER_NOTE_REQUEST:
    case UPDATE_CUSTOMER_NOTE_REQUEST:
    case DELETE_CUSTOMER_NOTE_REQUEST:
      return { loading: true, ...state };
    case FETCH_CUSTOMER_NOTES_SUCCESS:
      return { ...state, loading: false , notes: action.payload};
    case CREATE_CUSTOMER_NOTE_SUCCESS:
    case UPDATE_CUSTOMER_NOTE_SUCCESS:
    case DELETE_CUSTOMER_NOTE_SUCCESS:
      return { ...state, loading: false , response: action.payload};
    case FETCH_CUSTOMER_NOTES_FAIL:
    case CREATE_CUSTOMER_NOTE_FAIL:
    case UPDATE_CUSTOMER_NOTE_FAIL:
    case DELETE_CUSTOMER_NOTE_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
