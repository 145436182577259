import React from "react";
import { Table } from "antd";
import Loading from "./Loading";

const columns = [
  { title: "Transaction ID", dataIndex: "tid", key: "tid" },
  {
    title: "Transaction Date",
    dataIndex: "date",
    key: "date",
  },
  { title: "Amount", dataIndex: "amount", key: "amount" },
  { title: "Currency", dataIndex: "currency", key: "currency" },
];

const TableLoader = (props) => {
  const someChartsData = [
    {
      key: 1,
      tid: <Loading />,
      date: <Loading />,
      amount: <Loading />,
      currency: <Loading />,
    },
    {
      key: 2,
      tid: <Loading />,
      date: <Loading />,
      amount: <Loading />,
      currency: <Loading />,
    },
    {
      key: 3,
      tid: <Loading />,
      date: <Loading />,
      amount: <Loading />,
      currency: <Loading />,
    },
    {
      key: 4,
      tid: <Loading />,
      date: <Loading />,
      amount: <Loading />,
      currency: <Loading />,
    },
    {
      key: 5,
      tid: <Loading />,
      date: <Loading />,
      amount: <Loading />,
      currency: <Loading />,
    },
  ];
  return (
    <>
      <Table
        columns={columns}
        dataSource={someChartsData}
        pagination={{ defaultPageSize: 8 }}
        className="antdTable"
        defaultPageSize={8}
      />
    </>
  );
};

export default TableLoader;
