import React, { useState } from "react";
import { Input } from "@chakra-ui/react";
import {
  ContentWrapper,
  ActionButtonsGroup,
  EditButton,
  ActionButton,
} from "./styles";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import EditUserPassword from "./components/ChangeUserPassword";
import ModalContainer from "../../components/ModalContainer";
import { Table } from "antd";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  { title: "Description", dataIndex: "description", key: "description" },
  { title: "Enabled", dataIndex: "status", key: "status" },
  { title: "", dataIndex: "view", key: "view" },
];

const ProfileView = () => {
  const userDataObj = useSelector((state) => state.userData);
  const { userData } = userDataObj;
  const [modalState, toggleModal] = useState(false);

  const dataSource = [];
  userData.selectedRoles?.forEach((each) => {
    const { id, name, disabled, description } = each;
    dataSource.push({
      key: id,
      name: name,
      description: description,
      status: JSON.stringify(!disabled),
      view: (
        <Link to={`roles-management/view-role/${id}`}>
          <EditButton>View</EditButton>
        </Link>
      ),
    });
  });

  return (
    <>
      <ModalContainer size="md" show={modalState}>
        {modalState && (
          <EditUserPassword
            id={userData ? userData.id : ""}
            onHide={() => toggleModal(false)}
          />
        )}
      </ModalContainer>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Profile</h3>
            <p className="sub-title">User profile</p>
            <p className="sub-title mt-2">
              <span className="text-info mr-1">
                <Link to="/core">Home</Link>{" "}
              </span>{" "}
              / Profile
            </p>
          </div>
          <div className="col-sm-12 col-md-6 px-0">
            <ActionButtonsGroup>
              {userData.selectedRoles?.length > 1 ? (
                ""
              ) : (
                <Link
                  to={`roles-management/view-role/${userData?.selectedRoles[0]?.id}`}
                >
                  <ActionButton className="form-control mr-4">
                    <img
                      src={
                        require("../../../../assets/images/icons/view-permission.svg")
                          .default
                      }
                      className="d-inline mr-3"
                      alt="icon"
                    />
                    View Permissions
                  </ActionButton>
                </Link>
              )}

              <ActionButton
                onClick={() => toggleModal(true)}
                className="form-control"
              >
                <img
                  src={
                    require("../../../../assets/images/icons/change-password.svg")
                      .default
                  }
                  className="d-inline mr-3"
                  alt="icon"
                />
                Change Password
              </ActionButton>
            </ActionButtonsGroup>
          </div>
        </div>
        <div>
          {userData.selectedRoles?.length > 1 ? (
            <Table
              columns={columns}
              dataSource={dataSource}
              pagination={{ defaultPageSize: 13 }}
              defaultPageSize={13}
            />
          ) : (
            <ContentWrapper>
              <div className="row h-100">
                <div className="col-sm-12 col-md-6 h-100">
                  <div
                    className="container pb-5"
                    style={{ marginTop: "100px" }}
                  >
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-label">User ID</label>
                      <Input
                        style={{ width: "60%" }}
                        readOnly
                        value={userData ? userData.id : ""}
                        size="md"
                      />
                    </div>
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-label">User Name</label>
                      <Input
                        style={{ width: "60%" }}
                        value={userData ? userData.username : ""}
                        className="text-capitalize"
                        readOnly
                        size="md"
                      />
                    </div>
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-label">Office</label>
                      <Input
                        style={{ width: "60%" }}
                        value={userData ? userData.officeName : ""}
                        readOnly
                        className="text-capitalize"
                        size="md"
                      />
                    </div>
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-label">Status</label>
                      <Input
                        style={{ width: "60%" }}
                        value="Authenticated"
                        readOnly
                        className="text-capitalize"
                        size="md"
                      />
                    </div>
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-label">Language</label>
                      <Input
                        style={{ width: "60%" }}
                        readOnly
                        className="text-capitalize"
                        value="English"
                        size="md"
                      />
                    </div>
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-label">Primary Email</label>
                      <Input
                        style={{ width: "60%" }}
                        value={userData ? userData.email : ""}
                        size="md"
                        className="text-capitalize"
                        readOnly
                      />
                    </div>
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-label">Role</label>
                      <Input
                        style={{ width: "60%" }}
                        className="text-capitalize"
                        value={
                          userData
                            ? userData.selectedRoles
                              ? userData.selectedRoles.length > 0
                                ? userData.selectedRoles
                                    .map(({ name }) => name)
                                    .join(", ")
                                : ""
                              : ""
                            : ""
                        }
                        size="md"
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </ContentWrapper>
          )}
        </div>
      </div>
    </>
  );
};

export default ProfileView;
