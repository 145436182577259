import React, { useState } from "react";
import { ContentWrapper, ActionButtonsGroup, ActionButton } from "./styles";
import { InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import ModalContainer from "../../../components/ModalContainer";
import NewAdHocQuery from "./components/NewAdHocQuery";

const AdHocQueryView = () => {
  const [modalState, toggleModal] = useState(false);
  const [modalContentName, setModalContentName] = useState("");
  const currentContent = (e) => {
    const val = e.currentTarget.id.trim();
    if (val) {
      toggleModal(true);
      setModalContentName(val);
    }
  };
  const handleModalClose = () => toggleModal(false);
  const displayModalContent = () => {
    switch (modalContentName) {
      case "newAdHocQuery":
        return <NewAdHocQuery onHide={handleModalClose} />;
      default:
        return;
    }
  };

  //Table configuration

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    { title: "SQL Query", dataIndex: "sqlQuery", key: "sqlQuery" },
    {
      title: "Table affected",
      dataIndex: "tableAffected",
      key: "tableAffected",
    },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Report Run Frequency", dataIndex: "report", key: "report" },
    { title: "Status", dataIndex: "status", key: "status" },
    { title: "Created By", dataIndex: "createdBy", key: "createdBy" },
  ];

  const dataSource = [
    {
      name: "Mark",
      sqlQuery: "Peters",
      tableAffected: "10 tables",
      email: "dammy@woodcore.com",
      report: "Enabled lorem",
      status: "Active",
      createdBy: "12/33/2020",
    },
    {
      name: "Mark",
      sqlQuery: "Peters",
      tableAffected: "10 tables",
      email: "dammy@woodcore.com",
      report: "Enabled lorem",
      status: "Active",
      createdBy: "12/33/2020",
    },
    {
      name: "Mark",
      sqlQuery: "Peters",
      tableAffected: "10 tables",
      email: "dammy@woodcore.com",
      report: "Enabled lorem",
      status: "Active",
      createdBy: "12/33/2020",
    },
    {
      name: "Mark",
      sqlQuery: "Peters",
      tableAffected: "10 tables",
      email: "dammy@woodcore.com",
      report: "Enabled lorem",
      status: "Active",
      createdBy: "12/33/2020",
    },
    {
      name: "Mark",
      sqlQuery: "Peters",
      tableAffected: "10 tables",
      email: "dammy@woodcore.com",
      report: "Enabled lorem",
      status: "Active",
      createdBy: "12/33/2020",
    },
    {
      name: "Mark",
      sqlQuery: "Peters",
      tableAffected: "10 tables",
      email: "dammy@woodcore.com",
      report: "Enabled lorem",
      status: "Active",
      createdBy: "12/33/2020",
    },
    {
      name: "Mark",
      sqlQuery: "Peters",
      tableAffected: "10 tables",
      email: "dammy@woodcore.com",
      report: "Enabled lorem",
      status: "Active",
      createdBy: "12/33/2020",
    },
    {
      name: "Mark",
      sqlQuery: "Peters",
      tableAffected: "10 tables",
      email: "dammy@woodcore.com",
      report: "Enabled lorem",
      status: "Active",
      createdBy: "12/33/2020",
    },
    {
      name: "Mark",
      sqlQuery: "Peters",
      tableAffected: "10 tables",
      email: "dammy@woodcore.com",
      report: "Enabled lorem",
      status: "Active",
      createdBy: "12/33/2020",
    },
  ];
  return (
    <>
      <ModalContainer show={modalState}>
        {modalState && displayModalContent()}
      </ModalContainer>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">AdHocQuery</h3>
            <p className="sub-title">Define AdHocQuery for organization</p>
            <p className="font-weight sub-title mt-2">
              <span className="text-info">
                <Link to="/core/configuration">Configuration</Link> /{" "}
                <Link to="/core/configuration/organization">Utilities</Link>
              </span>{" "}
              / AdHocQuery
            </p>
          </div>
          <div className="col-sm-12 col-md-6 px-0">
            <ActionButtonsGroup>
              <InputGroup style={{ maxWidth: "450px" }}>
                <InputLeftElement
                  width="3.1rem"
                  height="50px"
                  pointerEvents="none"
                >
                  <img
                    src={
                      require("../../../../../assets/images/icons/search.svg")
                        .default
                    }
                    style={{ height: "30px" }}
                    alt="Icon"
                  />
                </InputLeftElement>
                <Input
                  style={{
                    background: "#ffffff",
                    height: "50px",
                  }}
                  type="text"
                  className="mb-3"
                  placeholder="Search for name"
                />
              </InputGroup>

              <ActionButton id="newAdHocQuery" onClick={currentContent}>
                <img
                  src={
                    require("../../../../../assets/images/icons/add.svg")
                      .default
                  }
                  alt="add"
                />
                Create AdHocQuery
              </ActionButton>
            </ActionButtonsGroup>
          </div>
        </div>
        <ContentWrapper>
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={{ defaultPageSize: 8 }}
            defaultPageSize={8}
          />
        </ContentWrapper>
      </div>
    </>
  );
};

export default AdHocQueryView;
