import {
  SET_TENANT,
  SET_VALID_TENANT,
  SHOW_OTP_MODAL,
  SHOW_OTP_2FA_MODAL,
  SET_VALIDITY_OF_OTP,
  SET_ENTERED_USER_DATA,
  SET_TO_DISPLAY,
  SET_BARCODE_DATA,
  SET_AUTH_REQUEST_ID,
  SHOW_SECURITY_MODAL,
} from "../constants/preAuthConstats";
import { SET_LOADING_STATE } from "../constants/utilityConstants";
import config from "../config";
import AuthModule from "../modules/Auth.module";
import Toast from "../util/Toast";
import API_AUTH from "../services/Api/auth.api.services";
import { USER_LOGIN_FAIL } from "../constants/authConstants";
import Auth from "../modules/Auth.module";

const loginFail = (payload) => {
  return {
    type: USER_LOGIN_FAIL,
    payload,
  };
};

const getTenantValidity = (dispatch, authData) => {
  dispatch({ type: SET_LOADING_STATE, payload: true });
  fetch(`${config.apiGateway.PRE_AUTH_API_URL}/validate_tenant`, {
    method: "POST", // or 'PUT'
    body: JSON.stringify({}), // data can be `string` or {object}!
    headers: {
      "Content-Type": "application/json",
      WoodCoreTenant: AuthModule.getTenant(),
    },
  })
    .then((res) => res.json())
    .then((response) => {
      dispatch({ type: SET_LOADING_STATE, payload: false });
      dispatch({ type: SET_TO_DISPLAY, payload: true });
      let { message } = response || {};
      if (message === "succesfull") {
        dispatch({ type: SET_VALID_TENANT, payload: true });
        getTenantLoggedIn(dispatch, authData);
      } else {
        dispatch({ type: SET_VALID_TENANT, payload: false });
        dispatch(
          Toast({ text: "Please provide a valid account Id", icon: "error" })
        );
      }
    })
    .catch((error) => {
      dispatch({ type: SET_LOADING_STATE, payload: false });
      dispatch({ type: SET_VALID_TENANT, payload: true });
    });
};

const authenticateUser = (dispatch, data) => {
  API_AUTH.loginUserNew(data)
    .then((response) => {
      dispatch({ type: SET_LOADING_STATE, payload: false });
      dispatch({ type: SET_AUTH_REQUEST_ID, payload: response.request_id });
      dispatch({ type: SHOW_OTP_MODAL, payload: true });
    })
    .catch((ex) => {
      dispatch({ type: SET_LOADING_STATE, payload: false });
      dispatch({ type: SET_VALID_TENANT, payload: true });

      if (ex.response) {
        dispatch(
          Toast({
            text: ex.response.data.error,
            icon: "error",
          })
        );
        dispatch(loginFail(ex.response.data.error));
      } else if (ex.request) {
        dispatch(Toast({ text: String(ex), icon: "error" }));
        dispatch(loginFail("Network Error"));
      } else {
        dispatch(Toast({ text: String(ex), icon: "error" }));
        dispatch(loginFail(ex));
      }
    });
};

const getTenantLoggedIn = (dispatch, data) => {
  dispatch({ type: SET_LOADING_STATE, payload: true });
  let { username } = data || {};

  API_AUTH.loginUserNew(data)
    .then(async (res) => {
      //check if 2FA is enabled
      if (res["2FA_enabled"]) {
        authenticateUser(dispatch, data);
      } else {
        // if status is false, generate a barcode for the user to scan
        await API_AUTH.generateBarcode(username)
          .then((response) => {
            dispatch({
              type: SET_BARCODE_DATA,
              payload: URL.createObjectURL(response),
            });
            dispatch({ type: SET_LOADING_STATE, payload: false });
          })
          .catch((ex) => {
            if (ex.response) {
              dispatch(
                Toast({
                  text: ex.response.data.message,
                  icon: "error",
                })
              );
              dispatch(loginFail(ex.response.data.message));
            } else if (ex.request) {
              dispatch(Toast({ text: String(ex), icon: "error" }));
              dispatch(loginFail("Network Error"));
            } else {
              dispatch(Toast({ text: String(ex), icon: "error" }));
              dispatch(loginFail(ex));
            }
          });
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: SHOW_OTP_2FA_MODAL, payload: true });
      }
    })
    .catch((ex) => {
      dispatch({ type: SET_LOADING_STATE, payload: false });
      if (ex.response) {
        console.log(ex.response, "response");
        dispatch(
          Toast({
            text: ex?.response?.data?.message || ex?.response?.data?.error,
            icon: "error",
          })
        );
        dispatch(loginFail(ex.response.data.message));
      } else if (ex.request) {
        dispatch(Toast({ text: String(ex.request), icon: "error" }));
        dispatch(loginFail("Network Error"));
      } else {
        dispatch(Toast({ text: String(ex), icon: "error" }));
        dispatch(loginFail(ex));
      }
    });
};

const validateOtp = (dispatch, data) => {
  dispatch({ type: SET_LOADING_STATE, payload: true });
  API_AUTH.authenticateOTP(data)
    .then((response) => {
      dispatch({ type: SET_LOADING_STATE, payload: false });
      Auth.authDetails(JSON.stringify(response));
      Auth.authenticateUser(response.access_token);
      dispatch({ type: SET_VALIDITY_OF_OTP, payload: true });
      dispatch({ type: SHOW_OTP_MODAL, payload: false });
      dispatch({ type: SHOW_SECURITY_MODAL, payload: false });
    })
    .catch((ex) => {
      if (ex.response) {
        dispatch(
          Toast({
            text: ex.response.data.message,
            icon: 'error',
          })
        );
      } else if (ex.request) {
        dispatch(Toast({ text: String(ex), icon: 'error' }));
      } else {
        dispatch(Toast({ text: String(ex), icon: 'error' }));
      }
      dispatch({ type: SET_LOADING_STATE, payload: false });
      dispatch({ type: SET_VALID_TENANT, payload: true });
    });
};

const validateAuthenticatorOtp = (dispatch, datatoSend, authData) => {
  dispatch({ type: SET_LOADING_STATE, payload: true });
  const { username, token } = datatoSend;
  API_AUTH.getCode(username)
    .then(async (response) => {
      dispatch({ type: SET_LOADING_STATE, payload: false });
      if (String(response) === String(token)) {
        await authenticateUser(dispatch, authData);
        dispatch({ type: SHOW_OTP_2FA_MODAL, payload: false });
      } else {
        dispatch(Toast({ text: 'Incorrect Code', icon: 'info' }));
      }
    })
    .catch((error) => {
      dispatch({ type: SET_LOADING_STATE, payload: false });
      dispatch({ type: SET_VALID_TENANT, payload: true });
    });
};

const setTenant = (tenant, dispatch) => {
  dispatch({ type: SET_TENANT, payload: tenant });
};

const setOtpModal = (staus, dispatch) => {
  dispatch({ type: SHOW_OTP_MODAL, payload: staus });
};

const setOtp2FAModal = (staus, dispatch) => {
  dispatch({ type: SHOW_OTP_2FA_MODAL, payload: staus });
};

const setEntedUserData = (data, dispatch) => {
  dispatch({ type: SET_ENTERED_USER_DATA, payload: data });
};
const setLoadingState = (data, dispatch) => {
  dispatch({ type: SET_LOADING_STATE, payload: data });
};

const activateOtp = (dispatch, data) => {
  dispatch({ type: SET_LOADING_STATE, payload: true });
  API_AUTH.activateOTP(data)
    .then((response) => {
      dispatch({ type: SET_LOADING_STATE, payload: false });

      let { status } = response || {};
      if (status === 'Success') {
        dispatch({ type: SHOW_OTP_2FA_MODAL, payload: false });
        dispatch(Toast({ text: 'User Activated', icon: 'success' }));
      } else {
        dispatch(Toast({ text: 'User Not Activated', icon: 'warning' }));
      }
    })
    .catch((error) => {
      dispatch(Toast({ text: 'Incorrect Code', icon: 'warning' }));
      dispatch({ type: SET_LOADING_STATE, payload: false });
      dispatch({ type: SET_VALID_TENANT, payload: true });
    });
};

export {
  setTenant,
  getTenantValidity,
  getTenantLoggedIn,
  setOtpModal,
  setOtp2FAModal,
  validateOtp,
  activateOtp,
  setEntedUserData,
  setLoadingState,
  validateAuthenticatorOtp,
};
