import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { formatCurrency } from "../../../util/FormatAmount";
import { getTransactionSummary } from "../../../actions/tellerActions";

const Statistics = ({ defaultCurrencyValue }) => {
  const dropdownValue = localStorage.getItem("myDefaultValue");
  const [currencyValue, setCurrencyValue] = useState("");

  useEffect(() => {
    if (dropdownValue) {
      // let oldCurrency = [];
      //  const oldCurrency = {...dropdownValue};
      //  const keyValue = oldCurrency[1];
      setCurrencyValue(dropdownValue);
    } else setCurrencyValue(defaultCurrencyValue);
  }, [dropdownValue, defaultCurrencyValue]);

  const dispatch = useDispatch();
  const { summary } = useSelector((state) => state.tellerReducer);

  let cashierId = localStorage.getItem("cashierId");
  let tellerGroupId = localStorage.getItem("tellerGroupId");

  useEffect(() => {
    if (tellerGroupId && cashierId) {
      dispatch(getTransactionSummary(tellerGroupId, cashierId));
    }
  }, [cashierId, dispatch, tellerGroupId]);

  // const runningBalance = formatCurrency(summary?.runningBalance);
  // const totalDebit = formatCurrency(summary?.totalDebit);
  // const totalCredit = formatCurrency(summary?.totalCredit);


  return (
    <>
      {summary?.transactionSummaryDataList?.map(
        ({ currency, runningBalance, totalCredit, totalDebit }) => {
          return currencyValue?.includes(currency) ? (
            <>
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-4 mt-3 p-0">
                  <SingleContainer>
                    <div className="card-icon">
                      <img
                        src={
                          require("../../../assets/images/icons/opn-close-teller.svg")
                            .default
                        }
                        alt="arrow down"
                        className="teller-icon-position"
                      />
                      <StyledIcon />
                    </div>

                    <div className="card-stats-count">
                      <p className="text-grey-teller">
                        Opening/Closing Balance:
                      </p>
                      {formatCurrency(runningBalance)?.includes("-") ? (
                        <h1 id="mini-head-small">
                          {currencyValue ? currencyValue : ""}{" "}
                          {formatCurrency(runningBalance)
                            ? `(${formatCurrency(runningBalance).replace(/-/g, "")})`
                            : ""}
                        </h1>
                      ) : (
                        <h1 id="mini-head">
                          {currencyValue ? currencyValue : ""}{" "}
                          {formatCurrency(runningBalance) ? formatCurrency(runningBalance) : ""}
                        </h1>
                      )}
                    </div>
                  </SingleContainer>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4 mt-3 p-0">
                  <SingleContainer>
                    <div className="card-icon">
                      <img
                        src={
                          require("../../../assets/images/icons/total-debit-teller.svg")
                            .default
                        }
                        alt="arrow down"
                        className="teller-icon-position"
                      />
                      <StyledIcon />
                    </div>

                    <div className="card-stats-count">
                      <p className="text-red-teller">Total Debit:</p>

                      {formatCurrency(totalDebit)?.includes("-") ? (
                        <h1 id="mini-head-small">
                          {formatCurrency(currencyValue) ? formatCurrency(currencyValue) : ""}{" "}
                          {formatCurrency(totalDebit) ? `(${formatCurrency(totalDebit).replace(/-/g, "")})` : ""}
                        </h1>
                      ) : (
                        <h1 id="mini-head">
                          {formatCurrency(currencyValue) ? formatCurrency(currencyValue) : ""}{" "}
                          {formatCurrency(totalDebit) ? formatCurrency(totalDebit) : ""}
                        </h1>
                      )}
                    </div>
                  </SingleContainer>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4 mt-3 p-0">
                  <SingleContainer>
                    <div className="card-icon">
                      <img
                        src={
                          require("../../../assets/images/icons/total-credit-teller.svg")
                            .default
                        }
                        alt="arrow down"
                        className="teller-icon-position"
                      />
                      <StyledIcon />
                    </div>

                    <div className="card-stats-count">
                      <p className="text-green-teller">Total Credit:</p>
                      {formatCurrency(totalCredit)?.includes("-") ? (
                        <h1 id="mini-head-small">
                          {currencyValue ? currencyValue : ""}{" "}
                          {formatCurrency(totalCredit) ? `(${formatCurrency(totalCredit).replace(/-/g, "")})` : ""}
                        </h1>
                      ) : (
                        <h1 id="mini-head">
                          {currencyValue ? currencyValue : ""}{" "}
                          {formatCurrency(totalCredit) ? formatCurrency(totalCredit) : ""}
                        </h1>
                      )}
                    </div>
                  </SingleContainer>
                </div>
              </div>
            </>
          ) : (
            ""
          );
        }
      )}
    </>
  );
};

export default Statistics;

const SingleContainer = styled.div`
  height: 100px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: start;
  padding-right: 20px;
  padding-left: 20px;
  box-shadow: 0px 2px 0px #ededf6;
  border: 0.5px solid #dadce6;
  width: 100%;
  .card-icon {
    margin-right: 10px;
  }
  .card-stats-count {
    margin-left: 20px;
    p {
      line-height: 21px;
      letter-spacing: 0.01em;
      margin: 0;
    }
  }
`;
const StyledIcon = styled.div`
  background-color: #01b1fc39;
  padding: 20px;
  border-radius: 50%;
  position: absolute;
  top: 30px;
  @media only screen and (max-width: 992px) {
    margin-bottom: 15px;
  }
`;
