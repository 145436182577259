import React from "react";
import CurrencyFormat from "react-currency-format";
import { Select as AntSelect } from "antd";
// import { Text } from "../../../../TellerInterface/Transaction/styles";
import styled from "styled-components";

const CurrencyTellerEdit = ({
  currency,
  currencyIndex,
  cashierTemplate,
  numberOfCurrencies,
  currencyCode,
  glIndex,
}) => {
  const glArray = numberOfCurrencies?.map((k) => {
    // return k.glAccountId[index];
    let id = k.glAccountId;
    return id;
  });

  return (
    <>
      <div className="dblock">
        <div className="dflex">
          <div className="">
            <p className="tellerCurr-labelT">
              <span>{numberOfCurrencies[glIndex]?.currencyName}</span>
            </p>
          </div>
          <div className="">
            <hr
              style={{
                width: "352px",
                background: "#DADCE5",
              }}
            />
          </div>
        </div>
        {/* {numberOfCurrencies?.map((displayCurr, index) => { */}
        <>
          <div
            className="d-flex justify-content-between"
            style={{ paddingBottom: "15px" }}
          >
            <label
              htmlFor="Withdrawal Limit"
              className="tellerCurr-labelT"
              style={{ width: "60%" }}
            >
              Teller GL:
            </label>

            <>
              <div
                className=""
                style={{ width: "85%", marginRight: "14px" }}
                // key={id}
              >
                <Text>
                  {numberOfCurrencies[glIndex]?.glAccountName}
                </Text>
              </div>
            </>
          </div>
          <div
            className="d-flex justify-content-between"
            style={{ paddingBottom: "15px" }}
          >
            <label
              htmlFor="Withdrawal Limit"
              className="tellerCurr-labelT"
              style={{ width: "60%" }}
            >
              Withdrawal Limit:
            </label>
            <div className="" style={{ width: "90%" }}>
              <span>{numberOfCurrencies[glIndex]?.currency}</span>
              <Text>{numberOfCurrencies[glIndex]?.withdrawalLimit}</Text>
            </div>
          </div>
          <div
            className="d-flex justify-content-between "
            style={{ paddingBottom: "15px" }}
          >
            <label
              htmlFor="Daily Teller Limit"
              className="tellerCurr-labelT"
              style={{ width: "60%" }}
            >
              Daily Teller Limit:
            </label>
            <div className="" style={{ width: "90%" }}>
              <span>{numberOfCurrencies[glIndex]?.currency}</span>
              <Text>{numberOfCurrencies[glIndex]?.dailyLimit}</Text>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default CurrencyTellerEdit;

const Text = styled.p`
  font-size: 12px;
  font-family: "Helvetica Neue";
  color: black;
  text-align: center;
  margin-right: 66%;
`;

const LeftText = styled.p`
  font-size: 12px;
  font-family: "Helvetica Neue";
  color: black;
  text-align: center;
`;
