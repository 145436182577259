import styled from "styled-components";

export const Container = styled.div`
  overflow: hidden;
  height: ${(props) => (!!props?.reducedHeight ? "60px" : "140px")};
  position: relative;
  padding: 15px;
  min-width: 260px;
  background: #ffffff;
  border: ${(props) =>
    !!props?.noBorder === "true" ? "" : "0.5px solid #addcff"};
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  max-width: min-content;

  .teller-card-icon {
    height: ${(props) =>
      !!props?.reducedHeight ? "30px !important" : "50px !important"};
    width: ${(props) =>
      !!props?.reducedHeight ? "30px !important" : "50px !important"};
  }

  .teller-head {
    font-size: ${(props) =>
      !!props?.reducedHeight ? "12px !important" : "14px !important"};
  }
  .cashier__header {
    font-size: 18px;
    margin-bottom: 8px;
    text-transform: capitalize;
  }
  .tag {
    background-color: #edeff5;
    color: #969cba;
    text-align: center;
    vertical-align: middle;
    border-radius: 5px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 12px;
    padding: 2px 5px;
  }

  .teller-balance {
    font-family: "AventaMedium";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    display: flex;
    align-items: center;

    color: #969cba;
  }

  .teller-amount-balance {
    font-family: "AventaBold";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #000000;
  }
  .started-on {
    font-size: 15px;
    color: #969cba;
  }
`;

export const Status = styled.p`
  content: "";
  width: 15px !important;
  height: 15px !important;
  margin-right: 12px;
  border-radius: 50%;
  background-color: ${(props) =>
    props?.status === "active" ? "#1dcb43" : "#FF0000"};
`;

export const ShowMore = styled.div`
  padding: 10px 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  align-items: flex-end;
  position: absolute;
  z-index: 1;
  background: #eff4f7;
  border-radius: 0px 10px 10px 0px;
  top: 0;
  right: 0;
  height: 100%;
  width: 26%;

  button {
    color: #002c79;
  }
`;

export const AddActionButton = styled.button`
  background: #ffffff !important;
  border-radius: 5px !important;
  color: #2c1dff !important;
  // border: #2c1dff 1px solid;
  font-family: "AventaMedium";
  display: inline;
  font-size: 11px;
  height: 20px;
  // max-width: 80px;
  // min-width: 65px;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  position: relative;
  bottom: 90px;
  width: 10px;
  img {
    height: 7px !important;
    display: inline;
    margin-right: 2px;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;
