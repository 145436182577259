import { Modal } from "antd";
import React from "react";
import DatePicker from "react-datepicker";
import { NewActionButton, ResetButton } from "../styles";
import closeModal from "../../../../assets/images/icons/closePayment.svg";
import { useDispatch } from "react-redux";
import { getNibssOutflowData, resetFilteredTransactionsOutflow } from "../../../../actions/nibssConnectActions";

const OutFlowFilterReportModal = ({
  filterModal,
  close,
  onHide,
  toReportDate,
  setToReportDate,
  fromReportDate,
  setFromReportDate,
  handleFilterTrans,
  tenantCode
}) => {
  const dispatch = useDispatch();


  if (!filterModal) {
    return null;
  }


  const handleOutflowReset = () => {
    dispatch(resetFilteredTransactionsOutflow());
    dispatch(
      getNibssOutflowData("orderBy=createdAt&sortBy=DESC&limit=12&offset=0",tenantCode)
    );
    setToReportDate("");
    setFromReportDate("");
    onHide();
  };


  return (
    // <div onClick={() => close()}>
    <div className="modalNibbs">
      {/* datepicker */}
      <div className="modal-contentNibss">
        <div className="modal-bodyNibss">
          <div className="download__close-modal" onClick={onHide}>
            <img src={closeModal} alt="" style={{ width: "8px" }} />
          </div>
          <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
            <div
              className="col-sm-12 col-md-6 px-0 mb-3 p-1"
              style={{ width: "70%" }}
            >
              {/* fromDate */}
              <label htmlFor="startDate" className="nibsstext-info">
                {" "}
                Start date
              </label>
              <div className="datepicker-100-width">
                <DatePicker
                  id="from-date"
                  selected={fromReportDate}
                  onChange={(date) => setFromReportDate(date)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="Start date"
                />
              </div>
            </div>

            <div
              className="col-sm-12 col-md-6 px-0 mb-3 p-1"
              style={{ width: "70%" }}
            >
              {/* toDate */}
              <label htmlFor="endDate" className="nibsstext-info">
                End date
              </label>
              <div className="datepicker-100-width">
                <DatePicker
                  id="to-date"
                  selected={toReportDate}
                  onChange={(date) => setToReportDate(date)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="End date"
                />
              </div>
            </div>
          </div>
        </div>
        {/* Download */}
        <div className="modal-footerNibss">
          <NewActionButton
            color="#2C1DFF"
            className="nibss__download"
            style={{ margin: "0px 0px" }}
              onClick={(e) => handleFilterTrans(e)}
          >
            Submit
          </NewActionButton>
          <ResetButton
            color="#2C1DFF"
            className="nibss__download"
            style={{ margin: "0px 0px" }}
              onClick={(e) => handleOutflowReset(e)}
          >
            Reset
          </ResetButton>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default OutFlowFilterReportModal;
