import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table } from 'antd';
import { ActionButton, IconButton } from "../styles";

import ModalContainer from "../../../components/ModalContainer";
import UndoApproval from "../components/accounts/UndoApproval";
import ActivateAccount from "../components/accounts/ActivateAccount";
import ApproveAccount from "../components/accounts/ApproveAccount";
import { formatCurrency } from "../../../../../util/FormatAmount";
import { useLocation } from "react-router-dom";
import CustomerSavingsAccount from "../../CustomerSavingsAccount";
import CheckPermission from "../../../../../util/CheckPermission";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const AccountsTab = ({
  userData,
  history,
  defaultCurrentView,
  currentSavingsId,
}) => {
  const accountsList = useSelector((state) => state.customerAccounts);

  const [transactionId, setTransId] = useState("");

  const [modalState, toggleModal] = useState(false);

  const [modalContentName, setModalContentName] = useState("");

  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;
  const { search } = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    // console.log(queryParams, 'QUERY PARAMAS');
    const currentIdQuery = queryParams.get("currentId");
    const curSavId = currentSavingsId;
    if (currentIdQuery) {
      setCurrentView(2);
      setCurrentId(currentIdQuery);
    } else if (curSavId) {
      setCurrentView(2);
      setCurrentId(curSavId);
    }
  }, [currentSavingsId]);

  const currentContent = (e, transId) => {
    const val = e.currentTarget.id.trim();
    setTransId(transId);

    if (val) {
      toggleModal(true);
      setModalContentName(val);
    }
  };

  const handleModalClose = () => toggleModal(false);

  const displayModalContent = () => {
    switch (modalContentName) {
      // case 'deposit':
      //   return (
      //     <Deposit
      //       transId={transactionId}
      //       onHide={handleModalClose}
      //       history={history}
      //     />
      //   );
      // case 'withdraw':
      //   return (
      //     <Withdraw
      //       transId={transactionId}
      //       onHide={handleModalClose}
      //       history={history}
      //     />
      //   );
      case "undoApproval":
        return <UndoApproval onHide={handleModalClose} />;
      case "activateAccount":
        return (
          <ActivateAccount
            id={transactionId}
            history={history}
            onHide={handleModalClose}
          />
        );
      case "approveAccount":
        return (
          <ApproveAccount
            id={transactionId}
            history={history}
            onHide={handleModalClose}
          />
        );
      default:
        return;
    }
  };

  const columns = [
    { title: "Account No", dataIndex: "accNo", key: "accNo" },
    { title: "Account Type", dataIndex: "accType", key: "accType" },
    { title: "Account Status", dataIndex: "status", key: "status" },
    { title: "Account Balance", dataIndex: "balance", key: "balance" },
    { title: "Currency", dataIndex: "curr", key: "curr" },
    { title: "Action", dataIndex: "action", key: "action" },
  ];

  let savingsAccount = [];

  !!accountsList &&
    !!accountsList.accounts &&
    !!accountsList.accounts.savingsAccounts &&
    !!accountsList.accounts.savingsAccounts.length &&
    accountsList.accounts.savingsAccounts.forEach((account) => {
      let { depositType } = account;
      if (
        !!depositType &&
        depositType.id === 100 &&
        depositType.value === "Savings"
      ) {
        let { id, accountNo, productName, status, currency, accountBalance } =
          account;
        savingsAccount.push({
          key: id,
          accNo: accountNo,
          accType: productName,
          status: (
            <>
              <div>
                {status.active ? (
                  <span className="new-text-success">
                    {" "}
                    {(status && status.value) || ""}
                    <span style={{ fontSize: "12px" }} className="text-danger">
                      {account.subStatus &&
                        account.subStatus.block &&
                        " (Blocked)"}
                      {account.subStatus &&
                        account.subStatus.blockDebit &&
                        " (On PND)"}
                    </span>
                  </span>
                ) : (
                  <span className="text-danger">
                    {/* {(account.status && account.status.value) || ""} */}
                  </span>
                )}
                {account.status.id === 100 ? (
                  <>
                    {" "}
                    <span className="new-text-pending">
                      {" "}
                      {account.status &&
                      account.status.value.toLowerCase() ===
                        "submitted and pending approval"
                        ? "Pending"
                        : (account.status && account.status.value) || ""}
                    </span>{" "}
                  </>
                ) : (
                  ""
                )}
                {account.status.id === 200 ? (
                  <>
                    {" "}
                    <span className="new-text-info">
                      {" "}
                      {(account.status && account.status.value) || ""}
                    </span>{" "}
                  </>
                ) : (
                  ""
                )}
                {account.status.id === 600 ||
                account.status.id === 500 ||
                account.status.id === 400 ? (
                  <>
                    <span className="new-text-danger">
                      {" "}
                      {(account.status && account.status.value) || ""}
                    </span>
                  </>
                ) : (
                  ""
                )}
              </div>
            </>
          ),
          balance: !!accountBalance
            ? formatCurrency(Number(accountBalance))
            : "",
          curr: accountBalance > 0 && currency !== null ? currency.code : "",

          action: (
            <>
              {/* Remove Deposit and Withdrawal button */}
              <>
               {/* {account.status.id === 300 ? (
                  <>
                    {CheckPermission('DEPOSIT_SAVINGSACCOUNT', permissions) ? (
                      <>
                        {' '}
                        <RenderTooltip text="Deposit" id="deposit">
                          <IconButton
                            id="deposit"
                            color="#2C1DFF"
                            onClick={(e) => {
                              e.stopPropagation();
                              currentContent(e, account.id);
                            }}
                          >
                            <img
                              src={
                                require('../../../../../assets/images/icons/deposit.svg')
                                  .default
                              }
                              alt="add"
                              className="icon-img"
                            />
                          </IconButton>
                        </RenderTooltip>{' '}
                      </>
                    ) : (
                      ''
                    )}
                    {CheckPermission('WITHDRAW_SAVINGSACCOUNT', permissions) ? (
                      <>
                        <RenderTooltip text="Withdraw" id="withdraw">
                          <IconButton
                            color="#2C1DFF"
                            id="withdraw"
                            onClick={(e) => {
                              e.stopPropagation();
                              currentContent(e, account.id);
                            }}
                          >
                            <img
                              src={
                                require('../../../../../assets/images/icons/withdraw.svg')
                                  .default
                              }
                              alt="add"
                              className="icon-img"
                            />
                          </IconButton>
                        </RenderTooltip>{' '}
                      </>
                    ) : (
                      ''
                    )} 
                  </>
                ) : (
                  ''
                )} */}
                {account.status.approved === false &&
                account.status.active === false &&
                account.status.closed === false &&
                CheckPermission('APPROVE_SAVINGSACCOUNT', permissions) ? (
                  <RenderTooltip text="Approve" id="approve">
                    <IconButton
                      color="#2C1DFF"
                      id="approveAccount"
                      onClick={(e) => {
                        e.stopPropagation();
                        currentContent(e, account.id);
                      }}
                    >
                      <img
                        src={
                          require('../../../../../assets/images/icons/check-mark.svg')
                            .default
                        }
                        alt="approve"
                        className="icon-img"
                      />
                    </IconButton>
                  </RenderTooltip>
                ) : (
                  ''
                )}
                {account.status.active === false &&
                account.status.approved === true &&
                account.status.closed === false &&
                CheckPermission('ACTIVATE_SAVINGSACCOUNT', permissions) ? (
                  <RenderTooltip text="Activate" id="activate">
                    <IconButton
                      color="#2C1DFF"
                      id="activateAccount"
                      onClick={(e) => {
                        e.stopPropagation();
                        currentContent(e, account.id);
                      }}
                    >
                      <img
                        src={
                          require('../../../../../assets/images/icons/check-mark.svg')
                            .default
                        }
                        alt="activate"
                        className="icon-img"
                      />
                    </IconButton>
                  </RenderTooltip>
                ) : (
                  ''
                )}
              </>
            </>
          ),
        });
      }
    });

  const [currentView, setCurrentView] = useState(1);
  // const [currentView, setCurrentView] = useState(defaultCurrentView = 2 ? 2 : 1);
  // {func.currentView === 2 ? 2 : 1}
  const [currentId, setCurrentId] = useState();

  return (
    <>
      <ModalContainer show={modalState} dialogClassName="document_infoModal">
        {modalState && displayModalContent()}
      </ModalContainer>
      {currentView === 1 ? (
        <div className="container pt-4">
          <div className="row">
            <div className="col-sm-12 col-md-7">
              <h3 className="page-title">Deposits</h3>
            </div>
            <div className="col-sm-12 col-md-5 ">
              <div className="spaceBetweenFlexEnd">
                <div className="">
                  <Link
                    to={userData && `/core/savings-application/${userData.id}`}
                  >
                    {CheckPermission("CREATE_SAVINGSACCOUNT", permissions) && (
                      <ActionButton color="#2C1DFF">
                        <img
                          src={
                            require("../../../../../assets/images/icons/activate.svg")
                              .default
                          }
                          className="mr-2"
                          alt="add"
                        />{" "}
                        New Deposit
                      </ActionButton>
                    )}
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5">
            <Table
              style={{
                overflow: "hidden",
                overflowX: "scroll",
              }}
              columns={columns}
              dataSource={savingsAccount}
              pagination={{ defaultPageSize: 5 }}
              defaultPageSize={5}
              onRow={(r) => ({
                onClick: () => {
                  setCurrentView(2);
                  setCurrentId(r.key);
                },
              })}
            />
          </div>
        </div>
      ) : (
        <CustomerSavingsAccount
          currentId={currentId}
          history={history}
          setCurrentView={setCurrentView}
        />
      )}
    </>
  );
};

const RenderTooltip = ({ text, id, children }) => (
  <>
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={`tooltip-${id}`}>{text}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  </>
);

export default AccountsTab;