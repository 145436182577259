import styled from "styled-components";

export const ActionButtonsGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

export const ActionButton = styled.button`
  background: #2C1DFF !important;
  border-radius: 5px !important;
  color: #fff !important;
  margin: 0 10px;
  border: none;
  font-family: 'AventaMedium';
  display: inline;
  font-size: 12px;
  height: 35px;
  max-width: 180px;
  min-width: 110px;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  img {
    height: 14px;
    display: inline;
    margin-right: 10px;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;

export const EditButton = styled.button`
background: #2C1DFF !important;
border-radius: 5px !important;
color: #fff !important;
border: none;
display: inline;
font-size: 12px;
height: 25px;
width: 78px;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
  }
`;

export const ContentWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 0px #ededf6;
  margin-top: 20px;
  table {
    font-size: 14px;
  }
`;

export const FormClass = styled.div``;

export const FormElements = styled.div`
  width: 90%;
  margin-top: 30px;
  margin-bottom: 30px;
  display: block;

  label {
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 22px;
    color: #969cba;
  }
  .css-42b2qy,
  .css-o0j7c0 {
    width: 70% !important;
  }
`;

// export const ActionButton = styled.button`
//   background: ${(props) => props.color} !important;
//   border-radius: 5px !important;
//   color: #fff !important;
//   margin: 0 10px;
//   border: none;
//   display: inline;
//   font-size: 14px;
//   height: 48px;
//   min-width: 160px;
//   transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
//   img {
//     height: 10px;
//     margin-right: 10px;
//     display: inline;
//   }
//   &:hover {
//     box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
//     color: #fff;
//   }
// `;
