import API_UTILITY from "../services/Api/utility.api.services";
import Toast from "../util/Toast";

import {
  GET_PAYMENT_TYPES_REQUEST,
  GET_PAYMENT_TYPES_SUCCESS,
  GET_PAYMENT_TYPES_FAIL,
  GET_PAYMENT_TYPES_RESET,
  GET_USER_PAYMENT_TYPES_REQUEST,
  GET_USER_PAYMENT_TYPES_SUCCESS,
  GET_USER_PAYMENT_TYPES_FAIL,
  GET_ACCOUNT_OPTIONS_REQUEST,
  GET_ACCOUNT_OPTIONS_SUCCESS,
  GET_ACCOUNT_OPTIONS_FAIL,
  GET_S_PRODUCT_OPTIONS_REQUEST,
  GET_S_PRODUCT_OPTIONS_SUCCESS,
  GET_S_PRODUCT_OPTIONS_FAIL,
  GET_S_CHARGE_FAIL,
  GET_S_CHARGE_SUCCESS,
  GET_S_CHARGE_REQUEST,
  GET_L_CHARGE_FAIL,
  GET_L_CHARGE_SUCCESS,
  GET_L_CHARGE_REQUEST,
  ADD_SAVINGS_CHARGE,
  ADD_LOANS_CHARGE,
  GET_LOAN_OPTIONS_FAIL,
  GET_LOAN_OPTIONS_SUCCESS,
  GET_LOAN_OPTIONS_REQUEST,
  GET_L_PRODUCT_DATA_SUCCESS,
  GET_L_PRODUCT_DATA_REQUEST,
  GET_L_PRODUCT_DATA_RESET,
  GET_L_PRODUCT_DATA_FAIL,
  SET_LOADING_STATE,
  GET_STATES_IDS,
  GET_CREATE_CUSTOMER_DETAILS,
  GET_COUNTRY_CODES_DETAILS,
  IS_GENERAL_SEARCHING,
  GET_SEARCH_QUERY,
  IS_GENERAL_SEARCHING_LOADING,
  GET_GL_ACCOUNTS,
  GET_CALCULATED_LOAN,
  GET_ALL_TOOLTIP,
  GET_ALL_CODE_VALUES,
  GET_APPLICABlE_DATA_TABLES,
  GET_CURRENT_DATATABLE,
  GET_IMPORT_TEMPLATE,
  GET_BULK_IMPORT_DOCUMENT,
  SET_UPLOADED_BULK_IMPORT,
  SET_NAVIGATION_BAR_ACTIVE,
  SET_DROPDOWN_VALUE,
  CLEAR_APPLICABLE_DATA_TABLE,
  GET_PROFILE_APPLICABlE_DATATABLES,
  GET_PROFILE_CURRENT_DATATABLE,
  GET_SAVINGS_APPLICABlE_DATATABLES,
  GET_SAVINGS_CURRENT_DATATABLE,
  GET_FD_PRODUCT_DATA_REQUEST,
  GET_FD_PRODUCT_DATA_SUCCESS,
  GET_FD_PRODUCT_DATA_FAIL,
  GET_FD_PRODUCT_DATA_RESET,
  GET_CURRENT_CLIENT_DATA_TABLE,
  GET_CURRENT_DEPOSIT_DATA_TABLE,
  GET_CURRENT_LOAN_DATA_TABLE,
} from "../constants/utilityConstants";

const getPaymentTypesFail = (payload) => {
  return { type: GET_PAYMENT_TYPES_FAIL, payload };
};

const getUserPaymentTypesFail = (payload) => {
  return { type: GET_USER_PAYMENT_TYPES_FAIL, payload };
};

const getSavingsOptionsFail = (payload) => {
  return { type: GET_ACCOUNT_OPTIONS_FAIL, payload };
};

const getSavingsProductOptionsFail = (payload) => {
  return { type: GET_S_PRODUCT_OPTIONS_FAIL, payload };
};
const getFixedDepositProductOptionsFail = (payload) => {
  return { type: GET_FD_PRODUCT_DATA_FAIL, payload };
};

const getSavingsChargeFail = (payload) => {
  return { type: GET_S_CHARGE_FAIL, payload };
};
const getLoansChargeFail = (payload) => {
  return { type: GET_L_CHARGE_FAIL, payload };
};

const getLoanOptionsFail = (payload) => {
  return { type: GET_LOAN_OPTIONS_FAIL, payload };
};

const clearApplicableDataTablesSavings = () => {
  return { type: CLEAR_APPLICABLE_DATA_TABLE };
};

const handleError = (ex, dispatch) => {
  if (ex.response) {
    dispatch(
      Toast({
        text: ex.response.data.errors
          ? ex.response.data.errors[0].defaultUserMessage
          : ex.response.data.defaultUserMessage,
        icon: "error",
      })
    );
  } else if (ex.request) {
    dispatch(Toast({ text: String(ex), icon: "error" }));
  } else {
    dispatch(Toast({ text: String(ex), icon: "error" }));
  }

  dispatch({ type: SET_LOADING_STATE, payload: false });
};

const getPaymentTypes = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: GET_PAYMENT_TYPES_REQUEST });
    dispatch({ type: GET_PAYMENT_TYPES_RESET });
    await API_UTILITY.getPaymentTypes()
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_PAYMENT_TYPES_SUCCESS, payload: response });
      })
      .catch((ex) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(getPaymentTypesFail(ex));
      });
  };
};

const getUserPaymentTypes = (id) => {
  return async (dispatch) => {
    dispatch({ type: GET_USER_PAYMENT_TYPES_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_UTILITY.getUserPaymentTypes(id)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_USER_PAYMENT_TYPES_SUCCESS, payload: response });
      })
      .catch((ex) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(getUserPaymentTypesFail(ex));
      });
  };
};

const getGlAccounts = () => {
  return async (dispatch) => {
    dispatch({ type: GET_GL_ACCOUNTS });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_UTILITY.getAllGlAccounts()
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_GL_ACCOUNTS, payload: response });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
const getImportTemplate = (url) => {
  return async (dispatch) => {
    dispatch({ type: GET_IMPORT_TEMPLATE });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_UTILITY.getImportTemplate(url)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_IMPORT_TEMPLATE, payload: response });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const getBulkImportDocument = (url) => {
  return async (dispatch) => {
    dispatch({ type: GET_BULK_IMPORT_DOCUMENT });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_UTILITY.getBulkImportDocument(url)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_BULK_IMPORT_DOCUMENT, payload: response });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const uploadBulkDocument = (url, dataToSend, fileData) => {
  return async (dispatch) => {
    dispatch({ type: GET_BULK_IMPORT_DOCUMENT });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_UTILITY.uploadBulkDocument(url, dataToSend)
      .then((response) => {
        fileData.id = response;
        fileData.status = 20;
        dispatch({ type: SET_UPLOADED_BULK_IMPORT, payload: fileData });
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(
          Toast({ text: "File Successfully Uploaded", icon: "success" })
        );
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const getApplicableDataTables = (name) => {
  return async (dispatch) => {
    dispatch({ type: GET_APPLICABlE_DATA_TABLES, payload: {} });
    dispatch({ type: GET_CURRENT_DATATABLE, payload: {} });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_UTILITY.getApplicableDataTables(name)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({
          type: GET_APPLICABlE_DATA_TABLES,
          payload: response ? response : {},
        });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const getProfileApplicableDataTables = (name) => {
  return async (dispatch) => {
    dispatch({ type: GET_PROFILE_APPLICABlE_DATATABLES, payload: {} });
    dispatch({ type: GET_PROFILE_CURRENT_DATATABLE, payload: {} });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_UTILITY.getApplicableDataTables(name)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({
          type: GET_PROFILE_APPLICABlE_DATATABLES,
          payload: response ? response : {},
        });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
const getSavingsApplicableDataTables = (name) => {
  return async (dispatch) => {
    dispatch({ type: GET_SAVINGS_APPLICABlE_DATATABLES, payload: {} });
    dispatch({ type: GET_SAVINGS_CURRENT_DATATABLE, payload: {} });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_UTILITY.getApplicableDataTables(name)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({
          type: GET_SAVINGS_APPLICABlE_DATATABLES,
          payload: response ? response : {},
        });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const getCurrentDataTables = (name, id) => {
  return async (dispatch) => {
    dispatch({ type: GET_CURRENT_DATATABLE });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_UTILITY.getCurrentDataTables(name, id)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_CURRENT_DATATABLE, payload: response });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const getCurrentClientDataTable = (name, id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_UTILITY.getCurrentDataTables(name, id)
      .then((response) => {
        dispatch({ type: GET_CURRENT_CLIENT_DATA_TABLE, payload: response });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      })
      .finally(() => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const getCurrentDepositDataTable = (name, id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_UTILITY.getCurrentDataTables(name, id)
      .then((response) => {
        dispatch({ type: GET_CURRENT_DEPOSIT_DATA_TABLE, payload: response });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      })
      .finally(() => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const getCurrentLoanDataTable = (name, id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_UTILITY.getCurrentDataTables(name, id)
      .then((response) => {
        dispatch({ type: GET_CURRENT_LOAN_DATA_TABLE, payload: response });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      })
      .finally(() => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const getProfileCurrentDataTables = (name, id) => {
  return async (dispatch) => {
    dispatch({ type: GET_PROFILE_CURRENT_DATATABLE });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_UTILITY.getCurrentDataTables(name, id)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_PROFILE_CURRENT_DATATABLE, payload: response });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
const getSavingsCurrentDataTables = (name, id) => {
  return async (dispatch) => {
    dispatch({ type: GET_SAVINGS_CURRENT_DATATABLE });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_UTILITY.getCurrentDataTables(name, id)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_SAVINGS_CURRENT_DATATABLE, payload: response });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const createSingleDataTables = (name, id, dataToSend, func) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_UTILITY.createSingleDataTables(name, id, dataToSend)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(getCurrentDataTables(name, id));
        func();
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const deleteAllSingleDataTables = (name, id, singleid, func) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_UTILITY.deleteAllSingleDataTables(name, id, singleid)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(getCurrentDataTables(name, id));
        func();
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const editSingleDataTables = (
  name,
  id,
  dataToSend,
  func,
  setPostType,
  currentMultiRowId
) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_UTILITY.editSingleDataTables(
      name,
      id,
      dataToSend,
      setPostType,
      currentMultiRowId
    )
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(getCurrentDataTables(name, id));
        func();
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const getCalculatedLoan = (dataToSend) => {
  return async (dispatch) => {
    dispatch({ type: GET_CALCULATED_LOAN });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_UTILITY.getCalculatedLoan(dataToSend)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_CALCULATED_LOAN, payload: response });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const getStatesIds = () => {
  return async (dispatch) => {
    dispatch({ type: GET_STATES_IDS });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_UTILITY.getAllStatesIds()
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_STATES_IDS, payload: response });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const getCodeValues = () => {
  return async (dispatch) => {
    dispatch({ type: GET_ALL_CODE_VALUES });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_UTILITY.getAllCodeValues()
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_ALL_CODE_VALUES, payload: response });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const getCreateCustomerDetails = (url) => {
  return async (dispatch) => {
    dispatch({ type: GET_CREATE_CUSTOMER_DETAILS });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_UTILITY.getCreateCustomerForm(url)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_CREATE_CUSTOMER_DETAILS, payload: response });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const getAllCountryCodes = (url) => {
  return async (dispatch) => {
    dispatch({ type: GET_COUNTRY_CODES_DETAILS });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_UTILITY.getAllAvailableCountryCodes(url)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_COUNTRY_CODES_DETAILS, payload: response });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const generalSearch = (query) => {
  return async (dispatch) => {
    dispatch({ type: GET_SEARCH_QUERY });
    dispatch({ type: IS_GENERAL_SEARCHING, payload: true });
    dispatch({ type: IS_GENERAL_SEARCHING_LOADING, payload: true });
    await API_UTILITY.getGeneralSearch(query)
      .then((response) => {
        dispatch({ type: IS_GENERAL_SEARCHING_LOADING, payload: false });
        dispatch({ type: GET_SEARCH_QUERY, payload: response });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch({ type: IS_GENERAL_SEARCHING_LOADING, payload: false });
      });
  };
};

const getSavingsProductOptions = (clientId) => {
  return async (dispatch) => {
    dispatch({ type: GET_ACCOUNT_OPTIONS_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_UTILITY.getSavingsProductOptions(clientId)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_ACCOUNT_OPTIONS_SUCCESS, payload: response });
      })
      .catch((ex) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });

        dispatch(getSavingsOptionsFail(ex));
      });
  };
};

const getSelectedSavingsProductOptions = (clientId, productId) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: GET_S_PRODUCT_OPTIONS_REQUEST });
    await API_UTILITY.getSelectedSavingsProductOptions(clientId, productId)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_S_PRODUCT_OPTIONS_SUCCESS, payload: response });
      })
      .catch((ex) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });

        dispatch(getSavingsProductOptionsFail(ex));
      });
  };
};

const getSavingsChargeOptionData = (chargeTypeId) => {
  return async (dispatch) => {
    if (chargeTypeId !== undefined || chargeTypeId !== null) {
      dispatch({ type: GET_S_CHARGE_REQUEST });
      dispatch({ type: SET_LOADING_STATE, payload: true });
      await API_UTILITY.getSelectedCharge(chargeTypeId)
        .then((response) => {
          dispatch({ type: SET_LOADING_STATE, payload: false });
          dispatch({ type: GET_S_CHARGE_SUCCESS, payload: response });
        })
        .catch((ex) => {
          dispatch({ type: SET_LOADING_STATE, payload: false });
          dispatch(getSavingsChargeFail(ex));
        });
    }
  };
};

const getLoansChargeOptionData = (chargeTypeId) => {
  return async (dispatch) => {
    if (chargeTypeId !== undefined || chargeTypeId !== null) {
      dispatch({ type: GET_L_CHARGE_REQUEST });
      dispatch({ type: SET_LOADING_STATE, payload: true });
      await API_UTILITY.getSelectedCharge(chargeTypeId)
        .then((response) => {
          dispatch({ type: SET_LOADING_STATE, payload: false });
          dispatch({ type: GET_L_CHARGE_SUCCESS, payload: response });
        })
        .catch((ex) => {
          dispatch({ type: SET_LOADING_STATE, payload: false });
          dispatch(getLoansChargeFail(ex));
        });
    }
  };
};

const addSavingsCharge = (charge) => {
  return async (dispatch) => {
    charge !== undefined
      ? dispatch({ type: ADD_SAVINGS_CHARGE, payload: charge })
      : console.log("no data");
  };
};

const addLoansCharge = (charge) => {
  return async (dispatch) => {
    charge !== undefined
      ? dispatch({ type: ADD_LOANS_CHARGE, payload: charge })
      : console.log("no data");
  };
};

const getLoansProductOptions = (clientId) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: GET_LOAN_OPTIONS_REQUEST });
    await API_UTILITY.getLoansProductOptions(clientId)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_LOAN_OPTIONS_SUCCESS, payload: response });
      })
      .catch((ex) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(getLoanOptionsFail(ex));
      });
  };
};

const getSelectedLoanProductData = (clientId, productId) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: GET_L_PRODUCT_DATA_RESET });
    dispatch({ type: GET_L_PRODUCT_DATA_REQUEST });
    await API_UTILITY.getSelectedLoanProductData(clientId, productId)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_L_PRODUCT_DATA_SUCCESS, payload: response });
      })
      .catch((ex) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(getSavingsProductOptionsFail(ex));
      });
  };
};

// getSelectedFixedDepositProductOptions;

const getSelectedFixedDepositProductData = (clientId, productId) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: GET_FD_PRODUCT_DATA_RESET });
    dispatch({ type: GET_FD_PRODUCT_DATA_REQUEST });
    await API_UTILITY.getSelectedFixedDepositProductOptions(clientId, productId)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_FD_PRODUCT_DATA_SUCCESS, payload: response });
      })
      .catch((ex) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(getFixedDepositProductOptionsFail(ex));
      });
  };
};

const getToolTips = (name) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: GET_ALL_TOOLTIP });
    await API_UTILITY.getToolTips(name)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_ALL_TOOLTIP, payload: response.data });
      })
      .catch((ex) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        // console.log(ex);
      });
  };
};

const generateGlCode = (type, setValue) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_UTILITY.generateGlCode(type)
      .then((response) => {
        setValue(response.data);
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        // console.log(ex);
      });
  };
};

const cancelSearchModal = () => {
  return async (dispatch) => {
    dispatch({ type: IS_GENERAL_SEARCHING, payload: false });
  };
};
const setNavigationBarStatus = (status) => {
  return async (dispatch) => {
    dispatch({ type: SET_NAVIGATION_BAR_ACTIVE, payload: status });
  };
};

const updateDropdownValue = (value) => {
  console.log(value, "value");
  return async (dispatch) => {
    dispatch({ type: SET_DROPDOWN_VALUE, payload: value });
  };
};

export {
  getPaymentTypes,
  getUserPaymentTypes,
  getSavingsProductOptions,
  getSelectedSavingsProductOptions,
  addSavingsCharge,
  addLoansCharge,
  getSavingsChargeOptionData,
  getLoansChargeOptionData,
  getLoansProductOptions,
  getSelectedLoanProductData,
  getStatesIds,
  getCreateCustomerDetails,
  getAllCountryCodes,
  generalSearch,
  cancelSearchModal,
  getGlAccounts,
  getCalculatedLoan,
  getToolTips,
  getCodeValues,
  getApplicableDataTables,
  getCurrentDataTables,
  createSingleDataTables,
  editSingleDataTables,
  deleteAllSingleDataTables,
  getImportTemplate,
  getBulkImportDocument,
  uploadBulkDocument,
  generateGlCode,
  setNavigationBarStatus,
  updateDropdownValue,
  clearApplicableDataTablesSavings,
  getCurrentClientDataTable,
  getCurrentDepositDataTable,
  getCurrentLoanDataTable,
  getProfileApplicableDataTables,
  getSavingsApplicableDataTables,
  getProfileCurrentDataTables,
  getSavingsCurrentDataTables,
  getSelectedFixedDepositProductData
};
