import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Textarea } from "@chakra-ui/react";

import { SelectButton, ActionButton } from "../styles";

import { getAllSpecialCharacters } from "../../../../../actions/notificationActions";

import { replaceUnderscoreToTextOnly } from "../../../../../util/Tools";

import { addTermiTemplate } from "../../../../../actions/notificationActions";
import Toast from "../../../../../util/Toast";

const MessageTemplate = () => {
  const dispatch = useDispatch();

  const allNotificationReducers = useSelector(
    (state) => state.allNotificationReducers
  );

  let { allSpecialCharacters } = allNotificationReducers || {};

  const [selected, setSelected] = useState("deposit");

  const [text, setText] = useState("");

  useEffect(() => {
    dispatch(getAllSpecialCharacters(selected === "deposit" ? 1 : 2));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const addToMessage = (paramter) => {
    setText(text + `#{${paramter}}`);
  };
  const addToNewLine = (paramter) => {
    setText(text + `${paramter}`);
  };

  const handleSelected = (data) => {
    setText("");
    setSelected(data);
  };

  const handleAddTermiTemplate = () => {
    if (!text) {
      dispatch(Toast({ text: "Please add a template to add", icon: "info" }));
    } else {
      let dataToSend = {
        body: text,
      };
      dispatch(addTermiTemplate(dataToSend, selected === "deposit" ? 1 : 2));
    }
  };

  return (
    <>
      <div className="w-100">
        <div className="row mt-4 ml-4">
          <SelectButton
            onClick={() => handleSelected("deposit")}
            color={selected === "deposit" ? "ADDCFF" : "ffffff"}
          >
            Deposit
          </SelectButton>
          <SelectButton
            onClick={() => handleSelected("withdrawal")}
            color={selected === "withdrawal" ? "ADDCFF" : "ffffff"}
          >
            Withdrawal
          </SelectButton>
        </div>
        <div className="row m-4">
          <div className="col-md-7">
            {/* Text area goes here */}
            <Textarea
              onChange={(e) => setText(e.target.value)}
              value={text}
              size="xs"
              row="5"
              className="message-textarea"
            />
            <div className="row">
              <div className="spaceBetweenFlexEnd w-100 mt-3">
                <ActionButton onClick={() => handleAddTermiTemplate()}>
                  Submit
                </ActionButton>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            {/* Special characters goes here */}
            <p>Add Special Characters</p>
            <div className="">
              <div className="row">
                <div
                  onClick={() => addToNewLine("/n")}
                  className="btn-small mt-3 mr-3"
                >
                  New Line
                </div>
                {allSpecialCharacters && allSpecialCharacters.length
                  ? allSpecialCharacters.map(({ parameter }, index) => {
                      return (
                        <div
                          onClick={() => addToMessage(parameter)}
                          className="btn-small mt-3 mr-3"
                          key={index}
                        >
                          {replaceUnderscoreToTextOnly(parameter)}
                        </div>
                      );
                    })
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MessageTemplate;
