import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Loading from "./LoadingScreen";

import CheckPermission from "../../../util/CheckPermission";

import { ContentWrapper, ContentCard } from "./styles";

const ManageUtility = () => {
  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  const [loading, setloading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setloading(false);
    }, 1000);
  }, []);
  const CardListings = [
    // {
    //   icon: require("../../../assets/images/icons/organization.svg").default,
    //   title: "Manage Roles And Permissions",
    //   description: "Define Or Modify Roles And Associated Permissions",
    //   route: "manage-roles-permissions",
    //   permission: "READ_ROLE",
    // },
    // {
    //   icon: require("../../../assets/images/icons/bulk.svg").default,
    //   title: "Manage Web Hooks",
    //   description: "Define Hooks",
    //   route: "manage-hooks",
    //   permission: " READ_HOOK",
    // },
    {
      icon: require("../../../assets/images/icons/provisioning-entries.svg")
        .default,
      title: "External Services",
      description: "External Services Configuration",
      route: "external-services",
      permission: "READ_EXTERNALSERVICES",
    },
    {
      icon: require("../../../assets/images/icons/organization.svg").default,
      title: "Maker Checker",
      description: "Define Or Modify Maker Checker Tasks",
      route: "mc-tasks",
      permission: "READ_PERMISSIONS",
    },
  ];

  return !!loading ? (
    <Loading />
  ) : (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Manage Utilitites</h3>
            <p className="sub-title">
              A complete overview of your business with analysis and data
              metrics
            </p>
          </div>
        </div>
        <ContentWrapper>
          <div className="row">
            {CardListings.map(
              ({ icon, title, description, route, permission }, i) =>
                CheckPermission(permission, permissions) && (
                  <div className="col-sm-12 col-md-4 col-lg-3 mb-4" key={i}>
                    <Link to={`manage-utilities/${route}`}>
                      <ContentCard>
                        <img
                          className="card-img-right"
                          src={icon}
                          alt={title}
                        />
                        <div className="body">
                          <p className="title">{title}</p>
                          <p className="description">{description}</p>
                        </div>
                      </ContentCard>
                    </Link>
                  </div>
                )
            )}
          </div>
        </ContentWrapper>
      </div>
    </>
  );
};

export default ManageUtility;
