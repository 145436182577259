import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const GenerateLetterModal = ({ onHide }) => {
  const loanOffer = useSelector((state) => state.loanOfferLetters);
  let { loanOfferLetters } = loanOffer || {};

  const openNewTab = () => {
    window.open(loanOfferLetters.file);
    onHide();
  };

  useEffect(() => {
    if (loanOfferLetters.file) openNewTab();
  }, [loanOfferLetters.file])

  return <></>;
};

export default GenerateLetterModal;
