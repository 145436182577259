import {
  SET_SPECIAL_CHARACTERS,
  GET_TENANT_TERMII,
} from "../constants/notificationConstants";

const initialState = {
  allSpecialCharacters: [],
  termiiDetails: [],
};

function allNotificationReducers(state = initialState, action) {
  switch (action.type) {
    case SET_SPECIAL_CHARACTERS: {
      return {
        ...state,
        allSpecialCharacters: action.payload,
      };
    }
    case GET_TENANT_TERMII: {
      return {
        ...state,
        termiiDetails: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

export default allNotificationReducers;
