import styled from "styled-components";
import { devices } from "../../components/Devices";

export const MainWrapper = styled.div`
  display: flex;
  width: 100%;

  .sidebar {
    width: 5%;
    background: #002c79;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    padding: 0;
    justify-content: center;

    @media ${devices.mobileS} {
      width: 10%;
    }
    @media ${devices.laptop} {
      width: 5%;
    }

    .sidebar-sticky {
      position: fixed;
      top: 48px;
      height: calc(100vh - 48px);
      padding-top: 0.5rem;
      overflow-x: hidden;
      overflow-y: auto;
    }

    .nav-item {
      .nav-link {
        margin: auto;
        margin-bottom: 10px;
      }
      .nav-link {
        width: 50px;
        height: 50px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background 0.15s ease-in-out;
        &.active {
          color: #FDE849 !important;
          // background: #ffffff;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
        }
        &:hover {
          color: #FDE849 !important;
          // background: #ffffff;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
        }
      }
    }
  }
  .openbar {
    width: 15%;
    background: #002c79;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    padding: 0;

    @media ${devices.mobileS} {
      width: 20%;
    }
    @media ${devices.laptop} {
      width: 15%;
    }

    .side-bar-header {
      font-size: 12px;
      color: #c9ced6;
      font-family: 'AventaRegular';
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 4;
    }

    .sidebar-sticky {
      position: fixed;
      top: 48px;
      height: calc(100vh - 48px);
      padding-top: 0.5rem;
      overflow-x: hidden;
      overflow-y: auto;
    }

    .nav-item {
      .nav-link {
        margin: auto;
        margin-bottom: 10px;
      }
      .nav-link {
        // width: 50px;
        height: 50px;
        border-radius: 10px;
        display: flex;
        // align-items: center;
        // justify-content: center;
        transition: background 0.15s ease-in-out;
        color: #fff;
        &.active {
          // background: #ffffff;
          color: #FDE849 !important;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
        }
        &:hover {
          // color: #002c79;
          // background: #ffffff;
          color: #FDE849 !important;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
        }
      }
    }
  }
  .main-content {
    width: 95%;
    margin-left: 5%;
    background: #f6f8fc;
    min-height: calc(100vh - 48px);
    @media ${devices.mobileS} {
      margin-left: 20%;
      width: 80%;
    }
    @media ${devices.laptop} {
      width: 95%;
      margin-left: 5%;
    }
  }
  .main-content-open {
    width: 85%;
    margin-left: 15%;
    background: #f6f8fc;
    min-height: calc(100vh - 48px);
    @media ${devices.mobileS} {
      margin-left: 20%;
      width: 80%;
    }
    @media ${devices.laptop} {
      width: 85%;
      margin-left: 15%;
    }
  }
`;
