import React, { useState, useEffect } from "react";
import Button from "../../../../components/Button/index";
import { SlideFade } from "@chakra-ui/react";
import { params } from "react-router-dom";
import { formatDateString } from "../../../../util/FormatDate";
import Toast from "../../../../util/Toast";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllOffices,
  createTellerGroup,
  getAllTellerGroups,
} from "../../../../actions/tellerActions";
import DatePicker from "react-datepicker";
import { Select, Textarea, Input, Divider } from "@chakra-ui/react";

const AddTellerGroupForm = ({ resetAddTellerGroup, addTellerGroup }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("");
  const [description, setDescription] = useState("");
  const [officeId, setOfficeId] = useState("");
  const [name, setName] = useState("");

  const dispatch = useDispatch();
  const tellerGroupReducer = useSelector((state) => state.tellerReducer);
  const { allOffice } = tellerGroupReducer;

  let newDate = new Date(startDate);
  let newDate2 = new Date(endDate);

  const onSubmit = (e) => {
    const func = () => {
      resetAddTellerGroup();
      dispatch(getAllTellerGroups());
    };
    if (!officeId || !name || !status || !startDate) {
      dispatch(Toast({ text: "Enter the required fields", icon: "info" }));
    } else {
      const dataToSend = {
        startDate: formatDateString(newDate, "dd/MMM/yyyy"),
        endDate: endDate ? formatDateString(newDate2, "dd/MMM/yyyy") : "",
        dateFormat: "dd/MMM/yyyy",
        locale: "en",
        name: name,
        status: status,
        officeId: officeId,
        description: description,
      };
      dispatch(createTellerGroup(dataToSend, func));
    }
  };
  useEffect(() => {
    dispatch(getAllOffices());
  }, [dispatch]);
  
  return (
    <SlideFade offsetX="100px" className="add-teller-group" in={addTellerGroup}>
      <Heading className="d-flex pb-3  px-5 pt-2 justify-content-between">
        <H3 style={{ fontFamily: "AventaSemiBold", fontSize: "15px" }}>
          New Teller Group
        </H3>
      </Heading>
      <Divider />
      <Form className="px-4 pt-3">
        <GridContainer className="d-flex justify-content-between">
          <label htmlFor="Staff" className="text-label">
            Select office*
          </label>
          <div className="select-100-width">
            <Select
              isFullWidth="true"
              name="officeId"
              placeholder="Select"
              value={officeId}
              onChange={(e) => setOfficeId(e.target.value)}
            >
              {!!allOffice?.length > 0
                ? allOffice?.map((item) => {
                    return (
                      <option key={item.id} value={item?.id}>
                        {item?.name}
                      </option>
                    );
                  })
                : ""}
            </Select>
          </div>
        </GridContainer>
        <GridContainer className="d-flex justify-content-between">
          <label htmlFor="Description" className="text-label">
            Group Name*
          </label>
          <Input
            name="name"
            onChange={(e) => setName(e.target.value)}
            type="text"
            placeholder="Enter new group name"
          />
        </GridContainer>
        <GridContainer className="d-flex justify-content-between">
          <label htmlFor="Daily Teller Limit" className="text-label">
            Description
          </label>
          <div className="textarea-100-width">
            <Textarea
              name="description"
              onChange={(e) => setDescription(e.target.value)}
            ></Textarea>
          </div>
        </GridContainer>
        <GridContainer className="d-flex justify-content-between"></GridContainer>
        <GridContainer className="d-flex justify-content-between">
          <label htmlFor="Daily Teller Limit" className="text-label">
            Status*
          </label>
          <div className="select-100-width">
            <Select
              name="status"
              placeholder="Select status"
              isFullWidth="true"
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="300">Active</option>
              <option value="400">Inactive</option>
            </Select>
          </div>
        </GridContainer>
        <GridContainer className="d-flex">
          <label htmlFor="Daily Teller Limit" className="text-label">
            Start Date*
          </label>
          <div className="datepicker-100-width">
            <DatePicker
              id="published-date"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText="dd/mmm/yyyy"
            />
          </div>
        </GridContainer>
        <GridContainer className="d-flex">
          <label htmlFor="Daily Teller Limit" className="text-label">
            End Date
          </label>
          <div className="datepicker-100-width">
            <DatePicker
              id="published-date"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText="dd/mmm/yyyy"
            />
          </div>
        </GridContainer>
        <div className="d-flex justify-content-end">
          <Button onClick={onSubmit}>Create group</Button>
        </div>
        <div className="pb-3"></div>
      </Form>
    </SlideFade>
  );
};

export default AddTellerGroupForm;

const GridContainer = styled.div`
  display: grid !important;
  grid-template-columns: 2fr 3fr;
  align-items: center;
  margin-bottom: 15px;
  color: #969cba;

  label {
    font-size: 18px;
    color: #969cba;
  }
`;

const H3 = styled.h3`
  font-size: 20px;
`;

const Form = styled.div`
  z-index: 10;
  border: 0.5px solid #0000004b;
  border-top: 0px;
  border-radius: 0 0 5px 5px;
`;
const Heading = styled.div`
  border: 0.5px solid #0000004b;
  border-bottom: 0px;
  border-radius: 5px 5px 0 0;
`;
