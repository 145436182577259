import React from "react";
import { Table } from "antd";
import Loading from "./Loading";

const columns = [
  { title: "", dataIndex: "picture", key: "picture" },
  { title: "Customer Name", dataIndex: "name", key: "name" },
  { title: "External ID", dataIndex: "externalId", key: "externalId" },
  { title: "Savings Product", dataIndex: "product", key: "product" },
  {
    title: "Account Number",
    dataIndex: "accountNumber",
    key: "accountNumber",
  },
  {
    title: "Account Balance",
    dataIndex: "accountbalance",
    key: "accountbalance",
  },
  { title: "Interest", dataIndex: "interest", key: "interest" },
];

const TableLoader = (props) => {
  const someChartsData = [
    {
      picture: <Loading />,
      name: <Loading />,
      externalId: <Loading />,
      product: <Loading />,
      accountNumber: <Loading />,
      accountbalance: <Loading />,
      interest: <Loading />,
    },
    {
      picture: <Loading />,
      name: <Loading />,
      externalId: <Loading />,
      product: <Loading />,
      accountNumber: <Loading />,
      accountbalance: <Loading />,
      interest: <Loading />,
    },
    {
      picture: <Loading />,
      name: <Loading />,
      externalId: <Loading />,
      product: <Loading />,
      accountNumber: <Loading />,
      accountbalance: <Loading />,
      interest: <Loading />,
    },
    {
      picture: <Loading />,
      name: <Loading />,
      externalId: <Loading />,
      product: <Loading />,
      accountNumber: <Loading />,
      accountbalance: <Loading />,
      interest: <Loading />,
    },
    {
      picture: <Loading />,
      name: <Loading />,
      externalId: <Loading />,
      product: <Loading />,
      accountNumber: <Loading />,
      accountbalance: <Loading />,
      interest: <Loading />,
    },
    {
      picture: <Loading />,
      name: <Loading />,
      externalId: <Loading />,
      product: <Loading />,
      accountNumber: <Loading />,
      accountbalance: <Loading />,
      interest: <Loading />,
    },
    {
      picture: <Loading />,
      name: <Loading />,
      externalId: <Loading />,
      product: <Loading />,
      accountNumber: <Loading />,
      accountbalance: <Loading />,
      interest: <Loading />,
    },
    {
      picture: <Loading />,
      name: <Loading />,
      externalId: <Loading />,
      product: <Loading />,
      accountNumber: <Loading />,
      accountbalance: <Loading />,
      interest: <Loading />,
    },
    {
      picture: <Loading />,
      name: <Loading />,
      externalId: <Loading />,
      product: <Loading />,
      accountNumber: <Loading />,
      accountbalance: <Loading />,
      interest: <Loading />,
    },
    {
      picture: <Loading />,
      name: <Loading />,
      externalId: <Loading />,
      product: <Loading />,
      accountNumber: <Loading />,
      accountbalance: <Loading />,
      interest: <Loading />,
    },
    {
      picture: <Loading />,
      name: <Loading />,
      externalId: <Loading />,
      product: <Loading />,
      accountNumber: <Loading />,
      accountbalance: <Loading />,
      interest: <Loading />,
    },
    {
      picture: <Loading />,
      name: <Loading />,
      externalId: <Loading />,
      product: <Loading />,
      accountNumber: <Loading />,
      accountbalance: <Loading />,
      interest: <Loading />,
    },
    {
      picture: <Loading />,
      name: <Loading />,
      externalId: <Loading />,
      product: <Loading />,
      accountNumber: <Loading />,
      accountbalance: <Loading />,
      interest: <Loading />,
    },
    {
      picture: <Loading />,
      name: <Loading />,
      externalId: <Loading />,
      product: <Loading />,
      accountNumber: <Loading />,
      accountbalance: <Loading />,
      interest: <Loading />,
    },
    {
      picture: <Loading />,
      name: <Loading />,
      externalId: <Loading />,
      product: <Loading />,
      accountNumber: <Loading />,
      accountbalance: <Loading />,
      interest: <Loading />,
    },
    {
      picture: <Loading />,
      name: <Loading />,
      externalId: <Loading />,
      product: <Loading />,
      accountNumber: <Loading />,
      accountbalance: <Loading />,
      interest: <Loading />,
    },
  ];
  return (
    <>
      <Table
        columns={columns}
        dataSource={someChartsData}
        pagination={{ defaultPageSize: 15 }}
        className="antdTable"
        defaultPageSize={15}
      />
    </>
  );
};

export default TableLoader;
