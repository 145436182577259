import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Input, Textarea } from "@chakra-ui/react";
import { FormClass, FormElements, ActionButton } from "../../styles.jsx";
import config from "../../../../../../config";

import AuthModule from "../../../../../../modules/Auth.module";
import axiosData from '../../../../../../services/axiosData'

const ViewDocument = ({ onHide, data, id }) => {
  const { name, description, id: did, fileName, type, location } = data || {};
  const [source, setSource] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const viewDocument = async () => {
    setIsLoading(true);
    const url = `${
      config.apiGateway.BASE_API_URL
    }/clients/${id}/documents/${did}/attachment?tenantIdentifier=${
      config.apiGateway.IS_PRODUCTION === `true`
        ? AuthModule.getTenant()
        : config.apiGateway.WOODCORE_TENANT
    }`;
    try {
      const { data, error } = await axiosData({
        method: "GET",
        signal: false,
        url: url,
        isBearerToken: true,
        responseType: 'blob'
      });
      if (data) {
        const dataUrl = URL.createObjectURL(data);
        setSource(dataUrl)
      }else {

      }
    }catch (ex) {

    }finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    viewDocument();
  }, []);

  return (
    <>
      <Modal.Header>
        <Modal.Title>View Document</Modal.Title>
      </Modal.Header>
      <Modal.Body className="wc-modal">
        <div className="d-flex align-items-center justify-content-center flex-direction-coloumn">
          <FormClass style={{ width: "90%" }}>
            <FormElements style={{ width: "100%" }}>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Name</label>
                <Input
                  style={{ width: "60%" }}
                  size="md"
                  readOnly
                  defaultValue={name}
                />
              </div>

              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Description</label>
                <Textarea
                  size="md"
                  style={{ width: "60%" }}
                  resize={"none"}
                  defaultValue={description}
                  readOnly
                />
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <div>
                  {/* {type !== "application/pdf" && (
                    <img src={`${location}`} alt={name} />
                  )} */}
                  {isLoading && <p> ...loading </p>}
                  {type !== "application/pdf" && (
                    <object
                      height="100%"
                      width="100%"
                      ng-show="preview"
                      data={source}
                      type="image/png"
                    >
                      {fileName}
                    </object>
                  )}
                  {/* {type !== "application/pdf" && (
                  <object
                    height="100%"
                    width="100%"
                    ng-show="preview"
                    data={`${location}`}
                    type="image/png"
                  >
                    {location}
                  </object>
                )} */}
                </div>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-4">
          <ActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require("../../../../../../assets/images/icons/cancel.svg")
                  .default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default ViewDocument;
