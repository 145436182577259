import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ContentWrapper } from "../styles";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../../../../../util/FormatDate";
import ScrollToTop from "../../../../../components/ScrollToTop/index";

import {
  getCurriencies,
  getPaymentTypes,
} from "../../../../../actions/accountingActions";

import { getGlAccounts } from "../../../../../actions/utilityActions";
import { createAnAmortization } from "../../../../../actions/accountingActions";

import AppDetails from "./components/AppDetails";
import AppReview from "./components/AppReview";
import AmortizationDetails from "./components/AmortizationDetails";
import AmorizationReview from "./components/AmorizationReview";

const CreateAmortization = ({ history }) => {
  const dispatch = useDispatch();

  const accountingData = useSelector((state) => state.accountingData);
  const glAccounts = useSelector((state) => state.glAccounts);

  let { glAcccounts = [] } = glAccounts || {};

  let {
    currencies = [],
    offices = [],
    paymentTypes = [],
  } = accountingData || {};

  const defaultCredit = { glAccountId: "" };
  const defaultDebit = { glAccountId: "" };
  const [currentScreen, setCurrentScreen] = useState(0);
  const [name, setName] = useState("");
  const [currency, setCurrency] = useState("");
  const [amount, setAmount] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [noOfDays, setNoOfDays] = useState("");
  const [comment, setComment] = useState("");
  const [detailsError, setDetailsError] = useState(false);
  const [active, setActive] = useState(false);
  const [credits, setCredits] = useState([defaultCredit]);
  const [debits, setDebits] = useState([defaultDebit]);

  useEffect(() => {
    dispatch(getCurriencies());
    dispatch(getPaymentTypes());
    dispatch(getGlAccounts());
  }, [dispatch]);

  const handleSubmitAmortization = () => {
    let dataToSend = {
      creditGlAccount: Number(credits[0].glAccountId),
      debitGlAccount: Number(debits[0].glAccountId),
      transactionsAmount: Number(amount),
      // numberOfDays: noOfDays,
      currencyCode: currency,
      startDate: formatDate(fromDate, false, "string:dd/MMM/yyyy"),
      endDate: formatDate(endDate, false, "string:dd/MMM/yyyy"),
      description: comment,
      name,
      active,
    };
    createAnAmortization(dataToSend, dispatch, history);
  };

  const renderComponent = () => {
    let currentComponet = "";

    switch (currentScreen) {
      case 0:
        currentComponet = (
          <AppDetails error={detailsError}>
            <AmortizationDetails
              name={name}
              setName={setName}
              currency={currency}
              setCurrency={setCurrency}
              currencies={currencies}
              offices={offices}
              paymentTypes={paymentTypes}
              amount={amount}
              setAmount={setAmount}
              fromDate={fromDate}
              setFromDate={setFromDate}
              noOfDays={noOfDays}
              setNoOfDays={setNoOfDays}
              comment={comment}
              setComment={setComment}
              glAcccounts={glAcccounts}
              credits={credits}
              setCredits={setCredits}
              setDebits={setDebits}
              debits={debits}
              setEndDate={setEndDate}
              endDate={endDate}
              active={active}
              setActive={setActive}
              setDetailsError={setDetailsError}
              handleNextScreen={handleNextCustomIndexSelect}
              handlePreviousScreen={handlePreviousCustomIndexSelect}
            />
          </AppDetails>
        );
        break;
      case 1:
        currentComponet = (
          <AppReview>
            <AmorizationReview
              name={name}
              currency={currency}
              amount={amount}
              currencies={currencies}
              offices={offices}
              noOfDays={noOfDays}
              credits={credits}
              debits={debits}
              fromDate={fromDate}
              endDate={endDate}
              comment={comment}
              glAcccounts={glAcccounts}
              handleSubmitAmortization={handleSubmitAmortization}
              handlePreviousScreen={handlePreviousCustomIndexSelect}
            />
          </AppReview>
        );
        break;
      default:
    }
    return currentComponet;
  };

  const handleNextCustomIndexSelect = () => {
    let newIndex = currentScreen + 1;
    if (newIndex <= 2) {
      setCurrentScreen(newIndex);
    }
  };
  // FUNCTION HERE FOR MOVING TO THE PREVIOUS PAGE
  const handlePreviousCustomIndexSelect = () => {
    let newIndex = currentScreen - 1;
    // console.log(newIndex, "and", currentScreen);
    if (newIndex >= 0) {
      setCurrentScreen(newIndex);
    }
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12  col-md-12 col-xl-6 pb-3">
            <h3 className="page-title">Create Amortization</h3>
            <p className="sub-title">Manage amortization postings</p>
            <p className="sub-title mt-2">
              <span className="text-info mr-1">
                <Link to="/core/amortizations">Amortizations</Link>
              </span>
              / Create Amortization
            </p>
          </div>
        </div>
        <div className="row" style={{ minHeight: "calc(100vh - 250px)" }}>
          <div className="col-sm-12 col-md-12">
            <ScrollToTop>
              <ContentWrapper className="application-wrapper">
                {renderComponent()}
              </ContentWrapper>
            </ScrollToTop>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateAmortization;
