import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import CurrencyFormat from "react-currency-format";
import { useDispatch, useSelector } from "react-redux";
import { fetch } from "./search";
import ModalContainer from "../../components/ModalContainer";
import { Input, Select, FormControl, Textarea } from "@chakra-ui/react";
import { LoadingOutlined } from "@ant-design/icons";
import { Select as AntSelect, Checkbox } from "antd";
import AddPaymentDetailsModal from "./AddPaymentDetailsModal";
import { FormElements, ActionButton } from "../styles";
import { ContentWrapper, NewContentWrapper, CardDetails } from "./styles";
import CheckPermission from "../../../../util/CheckPermission";
import Toast from "../../../../util/Toast";
import {
  getCurriencies,
  getOffices,
  getPaymentTypes,
  getAllGlAccounts,
  getSavingsAccounts,
  createAccountToJournalEntry,
} from "../../../../actions/accountingActions";
import config from "../../../../config";
import axiosData from "../../../../services/axiosData";
import { textToUpperCase } from "../../../../util/Tools";
import { formatDate } from "../../../../util/FormatDate";

import "./styles.css";

const AddAccountJournalEntry = ({ history }) => {
  const dispatch = useDispatch();
  const { Option } = AntSelect;

  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(false);

  const handleSearch = (value) => {
    if (isSearchByNuban[activeIndex]?.checked && isNaN(Number(value))) return;
    if (!value.length) {
      setData([]);
      setFetching(false);
    };
    setFetching(true);
    fetch(value, ({ filteredData, allData }) => {
      setFetching(false);
      setData(filteredData?.filter(({ type }) => type === 'CLIENT'));
      setSavingsAccounts(allData?.filter(({ entityType }) => entityType === 'SAVING'));
    });
  };

  const defaultCredit = { glAccountId: "", amount: "", comments: "" };
  const defaultDebit = { glAccountId: "", amount: "", comments: "" };
  const defaultCustomerAccount = {
    customerAccountId: "",
    amount: "",
    clientId: "",
    comments: "",
    currency: "",
  };
  const [office, setOffice] = useState("");
  const [currency, setCurrency] = useState("");
  const [operationType, setOperationType] = useState("");
  const [numberOfDebit, setNumberOfDebit] = useState([defaultDebit]);
  const [numberOfCredit, setNumberOfCredit] = useState([defaultCredit]);
  const [numberOfCustomerAccount, setNumberOfCustomerAccount] = useState([
    defaultCustomerAccount,
  ]);
  const [refNumber, setRefNumber] = useState("");
  const [transactionDate, setTrasactionDate] = useState("");
  const [comments, setComment] = useState("");
  const [description, setDescription] = useState("");
  const [paymentDetailsEntered, setPaymentDetailsEntered] = useState({});

  const [modalState, toggleModal] = useState(false);
  const [modalContentName, setModalContentName] = useState("");
  const [activeClientName, setActiveClientName] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);
  const [savingsAccounts, setSavingsAccounts] = useState([]); 
  const [isSearchByNuban, setIsSearchByNuban] = useState([{ checked: false }]);
  const [debitCreditOptions, setDebitCreditOptions] = useState([]);

  const handleChecked = (event, index) => {
    setFetching(false);
    setNumberOfCustomerAccount(prevState => prevState.map((item, idx) => index === idx
      ? { ...item, customerAccountId: "", clientId: "" }
      : item
    ));
    setIsSearchByNuban(isSearchByNuban.map((item, idx) => idx === index
      ? { checked: event.target.checked }
      : item
    ));
  }

  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;
  const [enableSubmit, setEnableSubmit] = useState(true);

  useEffect(() => {    
    if (activeClientName && savingsAccounts && isSearchByNuban[activeIndex]?.checked) {
      const savingsAccountSelected = savingsAccounts?.find((e) => e.parentName === activeClientName);      
      setNumberOfCustomerAccount(prevState => prevState.map((item, index) => index === activeIndex
        ? { ...item, customerAccountId: savingsAccountSelected?.entityAccountNo }
        : item
      ));
    };
  }, [
    activeClientName,
    savingsAccounts,
    activeIndex,
    isSearchByNuban,
    numberOfCustomerAccount[Number(activeIndex)]?.clientId
  ]);  

  useEffect(() => {
    dispatch(getCurriencies());
    dispatch(getOffices());
    dispatch(getPaymentTypes());
    dispatch(getAllGlAccounts());
    // dispatch(getSavingsAccounts());
  }, [dispatch]);

  // For Customer Account
  const addToCustomerAccount = () => {
    setNumberOfCustomerAccount([
      ...numberOfCustomerAccount,
      defaultCustomerAccount,
    ]);
  };

  const handleCustomerAmountToChange = (position, timeStamp) => {
    let hhh = [...numberOfCustomerAccount];
    hhh[Number(position)].amount = timeStamp;
    setNumberOfCustomerAccount(hhh);
  };
  const handleCustomerCommentToChange = (position, timeStamp) => {
    let hhh = [...numberOfCustomerAccount];
    hhh[Number(position)].comments = timeStamp;
    setNumberOfCustomerAccount(hhh);
  };

  const [index, setIndex] = useState({});
  // useEffect(() => {
  //   setEnableSubmit(numberOfCustomerAccount.every((customer) => customer.currency === currency));
  // }, [index, numberOfCustomerAccount, currency]);

  const handleClientToChange = (position, clientId) => {
    getSavingsAcc(clientId, position);
    setNumberOfCustomerAccount(prevState => prevState.map((item, index) => index === position
      ? { ...item, clientId }
      : item
    ));
  };
  
  const handleCustomerAccountToChange = (position, customerAccountId) => {
    const savingsAccountSelected  = savingsOpt?.find((option) => option.value === customerAccountId);
    setNumberOfCustomerAccount(prevState => prevState.map((item, index) => index === position
      ? {
        ...item,
        customerAccountId,
        currency: savingsAccountSelected?.currency,
      } : item
    ));
  };

  const removeCustomerAccount = (position) => {
    let noo = [];
    numberOfCustomerAccount.forEach((element, index) => {
      if (position !== index) {
        noo.push(element);
      }
    });
    setNumberOfCustomerAccount(noo);
  };
  // For Debit
  const addToArray = () => {
    setNumberOfDebit([...numberOfDebit, defaultDebit]);
  };
  const handleDebitToChange = (position, timeStamp) => {
    let hhh = [...numberOfDebit];
    hhh[Number(position)].glAccountId = timeStamp;
    setNumberOfDebit(hhh);
  };
  const handleDebitCommentToChange = (position, timeStamp) => {
    let hhh = [...numberOfDebit];
    hhh[Number(position)].comments = timeStamp;
    setNumberOfDebit(hhh);
  };
  const handleDebitAmountToChange = (position, timeStamp) => {
    let hhh = [...numberOfDebit];
    hhh[Number(position)].amount = timeStamp;
    setNumberOfDebit(hhh);
  };
  const removeDebit = (position) => {
    let noo = [];
    numberOfDebit.forEach((element, index) => {
      if (position !== index) {
        noo.push(element);
      }
    });
    setNumberOfDebit(noo);
  };

  const addToCreditArray = () => {
    setNumberOfCredit([...numberOfCredit, defaultCredit]);
  };
  const handleCreditToChange = (position, timeStamp) => {
    let hhh = [...numberOfCredit];
    hhh[Number(position)].glAccountId = timeStamp;
    setNumberOfCredit(hhh);
  };
  const handleCreditAmountToChange = (position, timeStamp) => {
    let hhh = [...numberOfCredit];
    hhh[Number(position)].amount = timeStamp;
    setNumberOfCredit(hhh);
  };
  const handleCreditCommentToChange = (position, timeStamp) => {
    let hhh = [...numberOfCredit];
    hhh[Number(position)].comments = timeStamp;
    setNumberOfCredit(hhh);
  };
  const removeCredit = (position) => {
    let noo = [];
    numberOfCredit.forEach((element, index) => {
      if (position !== index) {
        noo.push(element);
      }
    });
    setNumberOfCredit(noo);
  };
  const accountingData = useSelector((state) => state.accountingData);

  let {
    currencies,
    offices,
    paymentTypes,
    glAccounts,
    //savingsAccount = [],
  } = accountingData || {};

  const currentContent = (e) => {
    const val = e.currentTarget.id.trim();

    if (val) {
      toggleModal(true);
      setModalContentName(val);
    }
  };

  const handleModalClose = () => toggleModal(false);

  const displayModalContent = () => {
    switch (modalContentName) {
      case "addPaymentDetails":
        return (
          <AddPaymentDetailsModal
            paymentTypes={paymentTypes}
            onHide={handleModalClose}
            onSubmit={(data) => setPaymentDetailsEntered(data)}
          />
        );
      default:
        return;
    }
  };

  let newDate = new Date(transactionDate);

  let {
    paymentType,
    accountNumber,
    routingCode,
    receiptNumber,
    bankNumber,
    chequeNumber,
  } = paymentDetailsEntered;

  let journalEntry = {
    officeId: office,
    transactionDate: formatDate(newDate, true),
    comments: description,
    locale: "en",
    currencyCode: currency,
    dateFormat: "dd MMMM yyyy",
    credits: operationType === "debit" ? numberOfCredit : [],
    debits: operationType === "credit" ? numberOfDebit : [],
    operationType: operationType === "debit" ? "Debit" : "Credit",
    paymentTypeId: paymentType,
    accountNumber,
    checkNumber: chequeNumber,
    routingCode,
    receiptNumber,
    bankNumber,
    referenceNumber: refNumber,
    customerAccounts: numberOfCustomerAccount,
  };

  const handleAddJournalEntry = (e) => {
    e.preventDefault();
    if (!office || office.length < 1) {
      Toast({ text: "Please select an Office" });
    } else if (!currency || currency.length < 1) {
      Toast({ text: "Please select a Currency" });
    } else if (!transactionDate) {
      Toast({ text: "Please select a transaction Date" });
    } else {
      dispatch(createAccountToJournalEntry(journalEntry, history));
    }
  };

  useEffect(() => {
    if (glAccounts) {
      setDebitCreditOptions(glAccounts?.filter(ledger => !ledger?.isSystemDefined)
        ?.map(({ glCode, id, name }) => ({
          value: id,
          name: `${name}(${glCode})`,
        })
      ));
    }
  }, [glAccounts]);

  const [savingsOpt, setOpt] = useState([]);
  const [dat, setDat] = useState([]);
  useEffect(() => {
    if (dat && dat?.savingsAccounts && dat?.savingsAccounts?.length) {
      const newdata = dat?.savingsAccounts?.filter(account => account.status.value === "Active")?.map(
        ({ accountNo, productName, currency }) => ({
          value: accountNo,
          name: `${accountNo}${` ${currency.code} `}(${productName})`,
          currency: currency.code,
        })
      );
      setOpt(newdata);
    }
    // else if ()
  }, [dat]);

  const getSavingsAcc = useCallback((id, position) => {
    if (position) setIndex({ ...index, [position]: position });
    if (!id) return;
    if (id) {
      return new Promise(async (resolve, reject) => {
        axiosData({
          method: "GET",
          url: `${config.apiGateway.BASE_API_URL}/clients/${id}/accounts?fields=savingsAccounts`,
        })
          .then(({ data = [] }) => {
            if (data?.savingsAccounts) {
              setDat(data);
            } else if (!data?.savingsAccounts) {
              setOpt([]);
            }
            resolve(
              data?.savingsAccount?.map(({ accountNo, productName, currency }) => ({
                value: accountNo,
                name: `${accountNo}${` ${currency.code} `}(${productName})`,
              }))
            );
          })
          .catch(reject);
      });
    } else {
      return;
    }
  }, []);

  const searchOption =
    data &&
    data?.map((d) => <Option key={d.value}>{textToUpperCase(d.text)}</Option>);

  const savingsAccOption =
    savingsOpt &&
    savingsOpt?.map((d) => (
      <Option key={d.value} value={d.value}>{textToUpperCase(d.name)}</Option>
    ));
    

  return (
    <>
      <ModalContainer show={modalState} dialogClassName="document_infoModal">
        {modalState && displayModalContent()}
      </ModalContainer>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12  col-md-12 col-xl-6">
            <h3 className="page-title">Account To Journal Entry</h3>
            <p className="sub-title">
              Manual account journal entry transaction recorded in a journal
            </p>
            <p className="sub-title mt-2">Account To Journal Entry</p>
          </div>
        </div>
        {CheckPermission("CREATE_ACCOUNT_JOURNALENTRY", permissions) && (
          <NewContentWrapper>
            <FormElements>
              <div className="row h-100">
                <div className="col-sm-12  col-md-12 col-xl-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Office*</label>
                    <Select
                      placeholder="Select Office"
                      size="md"
                      name="office"
                      value={office}
                      onChange={(e) => setOffice(e.target.value)}
                    >
                      {!!offices
                        ? offices.map(
                            ({ externalId, id, name, nameDecorated }) => {
                              return (
                                <option value={id} key={id}>
                                  {name}
                                </option>
                              );
                            }
                          )
                        : null}
                    </Select>
                  </div>
                </div>
                <div className="col-sm-12  col-md-12 col-xl-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label" style={{marginLeft : '70px'}}>Currency*</label>
                    <Select
                      placeholder="Select Currency"
                      size="md"
                      name="currency"
                      value={currency}
                      onChange={(e) => setCurrency(e.target.value)}
                    >
                      {!!currencies ? (
                        currencies.map(({ code, displayLabel }) => {
                          return (
                            <option value={code} key={code}>
                              {displayLabel}
                            </option>
                          );
                        })
                      ) : (
                        <option>Loading</option>
                      )}
                    </Select>
                  </div>
                </div>
              </div>
            </FormElements>
            <CardDetails>
              {/* For Customer Account */}
              <div className="dflex">
                <div className="pt-3">
                  <p className="sub-title">
                    <span className="text-info mr-1 linkSavings ">
                      Customer Account
                    </span>
                  </p>
                </div>
                <div className="pt-4">
                  <hr />
                </div>
                <div className="mb-3">
                  <div className="button-right ml-3">
                    <ActionButton
                      color="#2C1DFF"
                      className="float-right"
                      style={{ margin: "0px 0px" }}
                      onClick={() => {
                        addToCustomerAccount();
                        setIsSearchByNuban([...isSearchByNuban, { checked: false }]);
                      }}
                    >
                      <img
                        src={
                          require("../../../../assets/images/icons/add.svg")
                            .default
                        }
                        alt="add icon"
                      />
                      Add Account
                    </ActionButton>
                  </div>
                </div>
              </div>
              <FormElements>
                {numberOfCustomerAccount?.map((customerAccount, index) => {
                  return (
                    <div className="p-relative mb-4 row h-100" key={index}>
                      <div className="col-sm-12  col-md-12 col-xl-6">
                        <div className="form-group d-flex justify-content-between align-items-start">
                          <label className="text-label mt-2">
                            Customer {isSearchByNuban[index]?.checked ? "NUBAN" : "Name/ID"}*
                          </label>
                          <div className="d-flex flex-column gap-1 w-60 m-0">
                            <AntSelect
                              notFoundContent={fetching ? <LoadingOutlined size="small" /> : "No result"}
                              allowClear
                              onFocus={() => setActiveIndex(index)}
                              onClear={() => {
                                setData([]);
                                setFetching(false);
                                setNumberOfCustomerAccount(prevState => prevState.map((item, idx) => index === idx
                                  ? { ...item, customerAccountId: "", clientId: "" }
                                  : item
                                ));
                              }}
                              loading={true}
                              showSearch
                              placeholder={`Search customers by ${isSearchByNuban[index]?.checked ? "NUBAN" : "Name/ID"}`}
                              showArrow={false}
                              filterOption={false}
                              value={numberOfCustomerAccount[index]?.clientId}
                              onSearch={handleSearch}
                              onSelect={(selected) => {
                                setActiveIndex(index);
                                handleClientToChange(index, selected);
                                setActiveClientName(data?.find(client => client.value == selected)?.name);
                                setNumberOfCustomerAccount(prevState => prevState.map((item, idx) => index === idx
                                  ? { ...item, customerAccountId: "" }
                                  : item
                                ));
                              }}
                            >
                              {searchOption}
                            </AntSelect>
                            <Checkbox
                              checked={isSearchByNuban[index]?.checked}
                              onChange={(event) => handleChecked(event, index)}
                              className="fs-10"
                            >
                              Search by NUBAN
                            </Checkbox>
                          </div>
                        </div>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label">Savings Account*</label>
                          {isSearchByNuban[index]?.checked ? (
                            <Input
                              style={{ width: "60%" }}
                              size="md"
                              placeholder={numberOfCustomerAccount[Number(index)]?.clientId &&
                                !numberOfCustomerAccount[Number(index)]?.customerAccountId
                                  ? "Selected client has no savings account"
                                  : ""}
                              value={numberOfCustomerAccount[Number(index)]?.customerAccountId &&
                                numberOfCustomerAccount[Number(index)]?.clientId
                                  ? numberOfCustomerAccount[Number(index)]?.customerAccountId
                                  : ""}
                              disabled
                            />
                          ) : (
                            <AntSelect
                              optionFilterProp="children"
                              notFoundContent="Customer has no savings account"
                              showSearch
                              // value={numberOfCustomerAccount[index]?.clientId}
                              onSelect={(value) => {
                                // setSavingsAccountValue(value);
                                handleCustomerAccountToChange(index, value);
                              }}
                              name="savings acc"
                              placeholder="Select savings account"
                              style={{ width: "60%" }}
                            >
                              {savingsAccOption}
                            </AntSelect>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-12  col-md-12 col-xl-6">
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label" style={{marginLeft : '70px'}}>Amount*</label>
                          <CurrencyFormat
                            value={numberOfCustomerAccount[index].amount}
                            thousandSeparator={true}
                            placeholder="Enter amount"
                            className="amount-input"
                            style={{
                              width: "60%",
                            }}
                            onValueChange={(values) => {
                              const { value } = values;
                              handleCustomerAmountToChange(index, value);
                            }}
                          />
                        </div>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label" style={{marginLeft : '70px'}}>Comment</label>
                          <Textarea
                            value={numberOfCustomerAccount[index].comments}
                            onChange={(e) =>
                              handleCustomerCommentToChange(
                                index,
                                e.target.value
                              )
                            }
                            style={{ width: "60%", fontSize: "12px" }}
                          ></Textarea>
                        </div>
                      </div>
                      {numberOfCustomerAccount.length > 1 ? (
                        <div
                          className="new-remove-icon"
                          onClick={() => {
                            removeCustomerAccount(index);
                            setIsSearchByNuban(isSearchByNuban.filter((_, idx) => idx !== index));
                          }}
                        >
                          <img
                            src={
                              require("../../../../assets/images/icons/cancel.svg")
                                .default
                            }
                            className="icon-img"
                            alt="cancel icon"
                          />
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </FormElements>{" "}
              {/* For Debit */}
              {/* Select Should go here */}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label
                      className="text-label"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Select Operation Type*
                    </label>
                    <Select
                      placeholder="Select Operation Type"
                      size="md"
                      name="operationType"
                      value={operationType}
                      onChange={(e) => setOperationType(e.target.value)}
                    >
                      <option value="credit">Credit</option>
                      <option value="debit">Debit</option>
                    </Select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <p className="sub-title">
                    <span className="text-info mr-1 linkSavings ">
                      Affected GL Entries*
                    </span>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  {operationType === "credit" ? (
                    <>
                      <div className="dflex">
                        <div className="pt-3">
                          <p className="sub-title">
                            <span className="text-info mr-1 linkSavings ">
                              Debit
                            </span>
                          </p>
                        </div>
                        <div className="pt-4">
                          <hr />
                        </div>
                        <div className="mb-3">
                          <div className="button-right ml-3">
                            <ActionButton
                              color="#2C1DFF"
                              className="float-right"
                              style={{ margin: "0px 0px" }}
                              onClick={() => addToArray()}
                            >
                              <img
                                src={
                                  require("../../../../assets/images/icons/add.svg")
                                    .default
                                }
                                alt="add icon"
                              />
                              Add Debit details
                            </ActionButton>
                          </div>
                        </div>
                      </div>
                      <FormElements>
                        {numberOfDebit.map((debit, index) => {
                          return (
                            <div
                              className="p-relative mb-5 border-bottom row h-100"
                              key={index}
                            >
                              <div className="col-sm-12  col-md-12 col-xl-6">
                                <div className="form-group d-flex justify-content-between align-items-center">
                                  <label className="text-label">Debit*</label>
                                  <AntSelect
                                    optionFilterProp="children"
                                    showSearch
                                    name="debit"
                                    placeholder="Select debit"
                                    onSelect={(value) => {
                                      handleDebitToChange(index, value);
                                    }}
                                    style={{ width: "60%" }}
                                  >
                                    {debitCreditOptions?.map((d) => (
                                      <Option key={d?.value}>{d?.name}</Option>
                                    ))}
                                  </AntSelect>
                                </div>
                              </div>
                              <div className="col-sm-12  col-md-12 col-xl-6">
                                <div className="form-group d-flex justify-content-between align-items-center">
                                  <label className="text-label" style={{marginLeft : '70px'}}>Amount*</label>
                                  <CurrencyFormat
                                    value={numberOfDebit[index].amount}
                                    thousandSeparator={true}
                                    placeholder="Enter amount"
                                    className="amount-input "
                                    style={{
                                      width: "60%",
                                    }}
                                    // prefix={"$"}
                                    onValueChange={(values) => {
                                      const { value } = values;
                                      // formattedValue = $2,223
                                      // value ie, 2223
                                      handleDebitAmountToChange(index, value);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-12  col-md-12 col-xl-6">
                                <div className="form-group d-flex justify-content-between align-items-center">
                                  <label className="text-label">Comment</label>
                                  <Textarea
                                    style={{ width: "60%", fontSize: "12px" }}
                                    value={numberOfDebit[index]?.comments}
                                    onChange={(e) =>
                                      handleDebitCommentToChange(
                                        index,
                                        e.target.value
                                      )
                                    }
                                    name="comment"
                                    placeholder="Enter comments"
                                  />
                                </div>
                              </div>
                              {numberOfDebit.length > 1 ? (
                                <div
                                  className="remove-icon"
                                  onClick={() => removeDebit(index)}
                                >
                                  <img
                                    src={
                                      require("../../../../assets/images/icons/cancel.svg")
                                        .default
                                    }
                                    className="detail-cancel-icon"
                                    alt="cancel icon"
                                  />
                                </div>
                              ) : null}
                            </div>
                          );
                        })}
                      </FormElements>
                    </>
                  ) : null}

                  {operationType === "debit" ? (
                    <>
                      {" "}
                      <div className="dflex">
                        <div className="pt-3">
                          <p className="sub-title">
                            <span className="text-info mr-1 linkSavings">
                              Credit
                            </span>
                          </p>
                        </div>
                        <div className="pt-4">
                          <hr />
                        </div>
                        <div className="mb-3">
                          <div className="button-right ml-3">
                            <ActionButton
                              color="#2C1DFF"
                              className="float-right"
                              style={{ margin: "0px 0px" }}
                              onClick={() => addToCreditArray()}
                            >
                              <img
                                src={
                                  require("../../../../assets/images/icons/add.svg")
                                    .default
                                }
                                alt="add icon"
                              />
                              Add Credit details
                            </ActionButton>
                          </div>
                        </div>
                      </div>
                      <FormElements>
                        {numberOfCredit?.map((credit, index) => {
                          return (
                            <div
                              className="p-relative mb-5 border-bottom row h-100"
                              key={index}
                            >
                              <div className="col-sm-12  col-md-12 col-xl-6">
                                <div className="form-group d-flex justify-content-between align-items-center">
                                  <label className="text-label">Credit*</label>
                                  <AntSelect
                                    optionFilterProp="children"
                                    showSearch
                                    name="debcreditit"
                                    placeholder="Select credit"
                                    onSelect={(value) => {
                                      handleCreditToChange(index, value);
                                    }}
                                    style={{ width: "60%" }}
                                  >
                                    {debitCreditOptions?.map((d) => (
                                      <Option key={d?.value}>{d?.name}</Option>
                                    ))}
                                  </AntSelect>
                                </div>
                              </div>
                              <div className="col-sm-12  col-md-12 col-xl-6">
                                <div className="form-group d-flex justify-content-between align-items-center">
                                  <label className="text-label" style={{marginLeft : '70px'}}>Amount*</label>
                                  <CurrencyFormat
                                    value={numberOfCredit[index].amount}
                                    thousandSeparator={true}
                                    placeholder="Enter amount"
                                    className="amount-input "
                                    style={{
                                      width: "60%",
                                    }}
                                    onValueChange={(values) => {
                                      const { value } = values;
                                      handleCreditAmountToChange(index, value);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-12  col-md-12 col-xl-6">
                                <div className="form-group d-flex justify-content-between align-items-center">
                                  <label className="text-label">Comment</label>
                                  <Textarea
                                    style={{ width: "60%", fontSize: "12px" }}
                                    value={numberOfCredit[index]?.comments}
                                    onChange={(e) =>
                                      handleCreditCommentToChange(
                                        index,
                                        e.target.value
                                      )
                                    }
                                    name="comment"
                                    placeholder="Enter comments"
                                  />
                                </div>
                              </div>
                              {numberOfCredit.length > 1 ? (
                                <div
                                  className="remove-icon"
                                  onClick={() => removeCredit(index)}
                                >
                                  <img
                                    src={
                                      require("../../../../assets/images/icons/cancel.svg")
                                        .default
                                    }
                                    className="detail-cancel-icon"
                                    alt="cancel icon"
                                  />
                                </div>
                              ) : null}
                            </div>
                          );
                        })}
                      </FormElements>{" "}
                    </>
                  ) : null}

                  {/* For Credit */}
                </div>
              </div>
              <div className="dflex">
                <div className="pt-3"></div>
                <div className="">
                  <hr />
                </div>
              </div>
            </CardDetails>
            <FormElements>
              <div className="row h-100">
                <div className="col-sm-12  col-md-12 col-xl-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Reference number</label>
                    <Input
                      style={{ width: "60%" }}
                      size="md"
                      name="refNumber"
                      value={refNumber}
                      onChange={(e) => setRefNumber(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-xl-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label" style={{marginLeft : '70px'}}>Transaction date*</label>
                    <FormControl style={{ width: "60%" }}>
                      <div className="datepicker-100-width">
                        <DatePicker
                          id="published-date"
                          selected={transactionDate}
                          onChange={(date) => setTrasactionDate(date)}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          placeholderText="dd/mmm/yyyy"
                        />
                      </div>
                    </FormControl>
                  </div>
                </div>
              </div>
            </FormElements>

            {/* <div className="row">
              <div className="button-right">
                <ActionButton
                  color="#2C1DFF"
                  className="float-right"
                  style={{ margin: "0px 0px" }}
                  onClick={currentContent}
                  id="addPaymentDetails"
                >
                  <img
                    src={
                      require("../../../../assets/images/icons/add.svg").default
                    }
                    alt="add icon"
                  />
                  Add Payment details
                </ActionButton>
              </div>
            </div> */}
            <div className="row">
              <div className="col-md-6">
                <FormElements>
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Description</label>
                    <Textarea
                      size="md"
                      style={{
                        width: "80%",
                        marginTop: "10px",
                        fontSize: "12px",
                      }}
                      resize={"none"}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                </FormElements>
              </div>
            </div>
            <div className="row">
              <div className="button-right">
                <div className="">
                  <ActionButton
                    color="#2C1DFF"
                    className="float-right"
                    disabled={!enableSubmit}
                    style={{ margin: "0px 0px" }}
                    onClick={(e) => handleAddJournalEntry(e)}
                  >
                    <img
                      src={
                        require("../../../../assets/images/icons/check-mark.svg")
                          .default
                      }
                      alt="mark icon"
                    />
                    Submit
                  </ActionButton>
                </div>
              </div>
            </div>
          </NewContentWrapper>
        )}
      </div>
    </>
  );
};

export default AddAccountJournalEntry;
