export const SET_CURRIENCIES = "SET_CURRIENCIES";
export const SET_OFFICES = "SET_OFFICES";
export const SET_GL_ACCOUNTS = "SET_GL_ACCOUNTS";
export const SET_ACCOUNTING_ERROR = "SET_ACCOUNTING_ERROR";
export const SET_PAYMENT_TYPES = "SET_PAYMENT_TYPES";
export const ADD_JOURNAL_ENRIES = "ADD_JOURNAL_ENRIES";
export const SET_FREQUENT_POSTING = "SET_FREQUENT_POSTING";
export const SET_JOURNAL_ENRIES = "SET_JOURNAL_ENRIES";
export const REVERSE_JOURNAL_ENTRY = "REVERSE_JOURNAL_ENTRY";
export const REVERSE_JOURNAL_ENTRY_RESET = "REVERSE_JOURNAL_ENTRY_RESET";
export const ADD_JOURNAL_ENRIES_RESET = "ADD_JOURNAL_ENRIES_RESET";
export const SET_CHARTS_OF_ACCOUNT = "SET_CHARTS_OF_ACCOUNT";
export const GET_A_CHART_OF_ACCOUNT = "GET_A_CHART_OF_ACCOUNT";
export const FILTER_CHARTS_OF_ACCOUNT = "FILTER_CHARTS_OF_ACCOUNT";
export const CLEAR_FILTER_CHARTS_OF_ACCOUNT = "CLEAR_FILTER_CHARTS_OF_ACCOUNT";
export const SET_CHARTS_OF_ACCOUNT_TEMPLATE = "SET_CHARTS_OF_ACCOUNT_TEMPLATE";
export const SET_ACCOUNT_CLOSURE = "SET_ACCOUNT_CLOSURE";
export const SET_ACCOUNT_RULES = "SET_ACCOUNT_RULES";
export const SET_ACCOUNT_RULES_TEMPLATE = "SET_ACCOUNT_RULES_TEMPLATE";
export const SET_AMORTIZATION = "SET_AMORTIZATION";
export const SET_SAVINGS_ACCOUNT = "SET_SAVINGS_ACCOUNT";
export const SET_CHARTS_OF_ACCOUNT_PARENT = "SET_CHARTS_OF_ACCOUNT_PARENT";
export const ONE_JOURNAL_ENTRY = "ONE_JOURNAL_ENTRY";
export const SET_FIANANCIAL_ACTIVITY_TEMPLATE =
  "SET_FIANANCIAL_ACTIVITY_TEMPLATE";
export const EDIT_FIANANCIAL_ACTIVITY_MAPPINGS =
  "EDIT_FIANANCIAL_ACTIVITY_MAPPINGS";
export const SET_LOADING_STATE = "SET_LOADING_STATE";
export const ADD_FIANANCIAL_ACTIVITY_MAPPINGS =
  "ADD_FIANANCIAL_ACTIVITY_MAPPINGS";
export const SET_FIANANCIAL_ACTIVITY_MAPPINGS =
  "SET_FIANANCIAL_ACTIVITY_MAPPINGS";

export const GET_GL_BALANCES_REQUEST = "GET_GL_BALANCES_REQUEST";
export const GET_GL_BALANCES_SUCCESS = "GET_GL_BALANCES_SUCCESS";
export const GET_GL_BALANCES_FAIL = "GET_GL_BALANCES_FAIL";
export const GET_GL_BALANCES_RESET = "GET_GL_BALANCES_RESET";
