import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Input, Select, Textarea, FormControl } from "@chakra-ui/react";
import { Table } from "react-bootstrap";
import { Modal } from "antd";
import SelectSearch from "react-select-search";
import CurrencyFormat from "react-currency-format";
import {
  getTransferSavingsAccountTemplate,
  accountTransfer,
  hideShowTransferModal,
  hideMultipleShowTransferModal,
  multipleAccountTransfer,
} from "../../../../../actions/SavingsAndLoanActions";
import { formatDate } from "../../../../../util/FormatDate";
import {
  ContentWrapper,
  ActionButton,
  FormElements,
  NewFormElements,
  CancelActionButton,
  SubmitActionButton,
  TransferToCard,
  SubmitTransferActionButton,
} from "../styles";
import { Select as AntSelect } from "antd";
import "./index.css";
import { useEffectAfterMount } from "../../../../../util/CustomHooks/useEffectAfterMount";
import Toast from "../../../../../util/Toast";
import { getAllCustomersPagination } from "../../../../../actions/customerActions";
import { LoadingOutlined } from "@ant-design/icons";
// import { ActionButton } from "../../../Accounting/styles";

const TransferFund = ({ match, history }) => {
  const dispatch = useDispatch();
  const { Option } = AntSelect;

  const savingsAndLoan = useSelector((state) => state.savingsAndLoan);

  let {
    savingsAccountTransferTemplate = {},
    showTransferModalSuccessful,
    showMultipleTransferModalSuccessful,
  } = savingsAndLoan || {};

  let {
    currency = {},
    fromAccount = {},
    fromAccountType = {},
    fromAccountTypeOptions = [],
    fromClient = {},
    toClientOptions = [],
    fromOffice = {},
    fromOfficeOptions = [],
    toOfficeOptions = [],
    toAccountTypeOptions = {},
    toAccountOptions = [],
    transferAmount,
  } = savingsAccountTransferTemplate || {};

  let { id: clientId, accountNo, displayName, officeName } = fromClient || {};

  let { accountNo: fromAccountNo, productName } = fromAccount || {};

  let { id: officeId } = fromOffice || {};

  const customerPagination = useSelector((state) => state.customerPagination);

  const [office, setOffice] = useState("");
  const [client, setClient] = useState("");
  const [accType, setAccType] = useState("");
  const [account, setAccount] = useState("");
  const [amount, setAmount] = useState();
  const [description, setDescription] = useState("");
  const [transDate, setTransDate] = useState();
  const [mydata, setMyData] = useState();

  const defaultTransferAcct = {
    amount: "",
    office: "",
    client: "",
    accType: "",
    account: "",
    description: "",
  };
  const [transferTo, setTransferTo] = useState([defaultTransferAcct]);

  let options = [];

  let modal;

  // function success() {
  //   modal = Modal.success({
  //     content: (
  //       <>
  //         <div>
  //           <p>Transfer Successful!</p>
  //         </div>
  //         <div className="ant-modal-confirm-btns show-btns">
  //           <button
  //             onClick={(e) => {
  //               history.push(
  //                 `/core/client/savings-account/${match.params.id}`
  //               );
  //             }}
  //             type="button"
  //             class="ant-btn ant-btn-primary"
  //           >
  //             <span>Go to Account</span>
  //           </button>
  //           <button
  //             onClick={(e) => {
  //               e.preventDefault();
  //               dispatch(hideShowTransferModal());
  //             }}
  //             type="button"
  //             class="ant-btn ant-btn-primary"
  //           >
  //             <span>Perform Another Transaction</span>
  //           </button>
  //         </div>
  //       </>
  //     ),
  //   });
  // }

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [toAcctType, setToAcctType] = useState([]);
  const [toOffice, setToOffice] = useState("");
  const [toClient, setToClient] = useState("");
  const [toAccount, setToAccount] = useState("");
  const [toAmount, setToAmount] = useState("");
  const [toComments, setToComments] = useState("");
  const [filterCurrency, setFilterCurrency] = useState("");
  const [searchValue, setSearchValue] = useState('');
  const [clientOptions, setClientOptions] = useState([]);

  useEffect(() => {
    setFilterCurrency(!!currency ? currency?.code : "");
  }, [currency]);

  useEffect(() => {
    if (showMultipleTransferModalSuccessful === true) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [showMultipleTransferModalSuccessful]);

  useEffect(() => {
    transferTo?.map(
      ({ office, client, accType, amount, account, description }) => {
        setToAcctType(accType);
        setToOffice(office);
        setToClient(client);
        setToAccount(account);
        setToAmount(amount);
        setToComments(description);
      }
    );
  }, [transferTo]);

  useEffect(() => {
    if (!!toClient?.length) {
      let dataToShow = toClientOptions?.filter((cst) => {
        const regex = new RegExp(`${client}`, "gi");
        return cst.displayName?.match(regex);
      });

      if (!!dataToShow.length) {
        setMyData(dataToShow);
      }
    }
  }, [toClient, toClientOptions]);

  toClientOptions.forEach((clientOption) => {
    let { id, displayName } = clientOption;

    options.push({
      value: id,
      name: displayName,
    });
  });

  useEffect(() => {
    dispatch(getTransferSavingsAccountTemplate(match.params.id, ""));
  }, []);

  useEffectAfterMount(() => {
    if (!!toOffice && !!toClient === false) {
      dispatch(
        getTransferSavingsAccountTemplate(
          match.params.id,
          // `toOfficeId=${toOffice ? toOffice[toOffice.length - 1] : ""}`
          `toOfficeId=${toOffice}`
        )
      );
    } else if (!!toOffice && !!toClient && !!toAcctType) {
      dispatch(
        getTransferSavingsAccountTemplate(
          match.params.id,
          `toOfficeId=${
            // toOffice ? toOffice[toOffice.length - 1] : ""
            toOffice
          }&toAccountType=${
            // toAcctType ? toAcctType[toAcctType.length - 1] : ""
            toAcctType
            // }&toClientId=${toClient ? toClient[toClient.length - 1] : ""}`
          }&toClientId=${toClient}`
        )
      );
    }
  }, [dispatch, match, toOffice, toAcctType, toClient]);

  // Handle Submit
  const handleTransferAccount = () => {
    let id = match.params.id;

    let data = [];
    transferTo.map((t) => {
      let { office, client, accType, account, amount, description } = t;

      data.push({
        toOfficeId: office,
        toClientId: client,
        toAccountType: accType,
        toAccountId: account,
        transferAmount: amount,
        transferDescription: description,
      });
    });
    let dataToSend = {
      fromOfficeId: officeId,
      fromClientId: clientId,
      fromAccountType: 2,
      fromAccountId: id,
      dateFormat: "dd MMM yyyy",
      locale: "en",
      transferDate: formatDate(transDate, true),
      data,
    };

    if (!toOffice || toOffice.length < 1) {
      dispatch(Toast({ text: "Please select an Office", icon: "info" }));
    } else if (!toClient || toClient.length < 1) {
      dispatch(Toast({ text: "Please select a Client", icon: "info" }));
    } else if (!toAcctType) {
      dispatch(Toast({ text: "Please select an Account Type", icon: "info" }));
    } else if (!toAccount) {
      dispatch(Toast({ text: "Please select an Account", icon: "info" }));
    } else if (!toAmount) {
      dispatch(Toast({ text: "Please Enter Amount", icon: "info" }));
    } else if (!transDate) {
      dispatch(
        Toast({ text: "Please select a transaction Date", icon: "info" })
      );
    } else if (!toComments) {
      dispatch(Toast({ text: "Please Enter Description", icon: "info" }));
    } else {
      // addJournalEntry(journalEntry, dispatch, history);
      dispatch(multipleAccountTransfer(id, dataToSend, history, resetFields));
      // resetFields();
    }
  };

  // const handleCancel = (id) => {
  //   history
  // }
  //   {
  //     "fromOfficeId": 1,
  //     "fromClientId": 410,
  //     "fromAccountType": 2,
  //     "fromAccountId": 151,
  //     "dateFormat": "dd MMMM yyyy",
  //     "locale": "en",
  //     "transferDate": "21 Feb 2023",
  // "data": [
  //     {
  //         "toOfficeId": 1,
  //         "toClientId": 411,
  //         "toAccountType": 2,
  //         "toAccountId": 121,
  //         "transferAmount": "300.0",
  //         "transferDescription": "A transfer 00"
  //     }
  // ]

  // }

  // response
  //   "savingsId": 151,
  //   "changes": {
  //       "toSavingsTransactionId": 673350,
  //       "fromSavingsTransactionId": 673349
  //   }
  // }

  const fuzzySearch = (options) => {
    const fuses = [];

    return (value) => {
      !!options.length &&
        options.filter((cst) => {
          const regex = new RegExp(`${value}`, "gi");
          if (!!cst && cst.name && cst.name.match(regex)) {
            fuses.push(cst);
          }
        });
      if (!value.length) {
        return options;
      }
      return fuses;
    };
  };

  const addToArray = () => {
    setTransferTo([...transferTo, defaultTransferAcct]);
  };

  const removeTranferTo = (position) => {
    let noo = [];
    transferTo.forEach((element, index) => {
      if (position !== index) {
        noo.push(element);
      }
    });
    setTransferTo(noo);
  };

  const handleOfficeToChange = (position, timestamp) => {
    let hhh = [...transferTo];
    hhh[Number(position)].office = timestamp;
    setTransferTo(hhh);
  };

  const handleClientToChange = (position, timestamp) => {
    let hhh = [...transferTo];
    hhh[Number(position)].client = timestamp;
    setTransferTo(hhh);
  };

  const handleAmountToChange = (position, timestamp) => {
    let hhh = [...transferTo];
    hhh[Number(position)].amount = timestamp;
    setTransferTo(hhh);
  };
  const handleAccountTypeToChange = (position, timestamp) => {
    let hhh = [...transferTo];
    hhh[Number(position)].accType = timestamp;
    setTransferTo(hhh);
  };
  const handleAccountToChange = (position, timestamp) => {
    let hhh = [...transferTo];
    hhh[Number(position)].account = timestamp;
    setTransferTo(hhh);
  };
  const handleCommentToChange = (position, timestamp) => {
    let hhh = [...transferTo];
    hhh[Number(position)].description = timestamp;
    setTransferTo(hhh);
  };

  const resetFields = () => {
    setTransferTo([defaultTransferAcct]);
    setTransDate("");
  };

  const accountOption =
    toAccountOptions &&
    toAccountOptions.map(({ id, accountNo, productName }) => {
      return (
        <Option key={id} value={id}>
          {productName} - {accountNo}
        </Option>
      );
    });

  const accountTypesOption =
    fromAccountTypeOptions &&
    fromAccountTypeOptions.map(({ id, value }) => {
      return (
        <Option key={id} value={id}>
          {value}
        </Option>
      );
    });

  const officeOption =
    toOfficeOptions &&
    toOfficeOptions.map(({ id, name }) => {
      return (
        <>
          <Option key={id} value={id}>
            {name}
          </Option>
        </>
      );
    });

  useEffect(() => {
    let queryParams = `search?exactMatch=false&query=${searchValue}&resource=clients&sortOrder=DESC`;
    if(!isNaN(Number(searchValue))) {
      dispatch(getAllCustomersPagination(queryParams));
    };
  }, [searchValue]);

  useEffect(() => {
    if (!isNaN(Number(searchValue))) {
      setClientOptions(
        customerPagination?.customersPagination?.pageItems
        ?.filter((option) => option?.entityType === 'CLIENT')
      );
    }
  }, [searchValue, customerPagination]);

  useEffect(() => {
    if (toClientOptions?.length > 0 && isNaN(Number(searchValue))) {
      setClientOptions(toClientOptions);
    };
  }, [searchValue, toClientOptions]);

  return (
    <>
      {/* <Modal
        visible={isModalVisible}
        footer={[
          <>
            <button
              key="acc"
              onClick={(e) => {
                history.push(`/core/client/${clientId}`);
              }}
              type="button"
              class="ant-btn ant-btn-primary"
            >
              <span>Go to Account</span>
            </button>
            <button
              key="another"
              onClick={(e) => {
                e.preventDefault();
                dispatch(hideMultipleShowTransferModal());
                resetFields();
              }}
              type="button"
              class="ant-btn ant-btn-primary"
            >
              <span>Perform Another Transaction</span>
            </button>{" "}
          </>,
        ]}
      >
        <>
          <div>
            <div class="ant-modal-confirm-body-wrapper">
              <div class="ant-modal-confirm-body">
                <span
                  role="img"
                  aria-label="check-circle"
                  class="anticon anticon-check-circle successful-svg"
                >
                  <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="check-circle"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z"></path>
                    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                  </svg>
                </span>
                <div class="ant-modal-confirm-content">Transfer Successful</div>
              </div>
            </div>
          </div>
        </>
      </Modal> */}
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12  col-md-12 col-xl-6 pb-3">
            <h3 className="page-title">Account Transfer</h3>
            <p className="sub-title mt-2">
              <span className="text-info mr-1">
                <Link to="/core/clients">Customer</Link>
              </span>{" "}
              /{" "}
              <span className="text-info mr-1">
                <Link to={`/core/client/${clientId}`}>
                  {!!displayName ? `${displayName} / ` : ""}
                </Link>
              </span>{" "}
              Account Transfer
            </p>
          </div>
        </div>
        <ContentWrapper>
          <div className="row my-4">
            <div className="col-xl-12">
              <p className="account-details">Transferring From Details</p>
            </div>
            {/* First Table */}
            <div className="col-sm-6 col-md-6 mb-3" style={{ padding: "40px" }}>
              <div className="m-3">
                <Table
                  responsive
                  // striped
                  bordered
                  // hover
                  className="accountTransfer"
                >
                  <thead>
                    <tr>
                      <th className="tableHead td1">Client's name</th>
                      <th
                        className="tableHead td22 md-font-weight"
                        style={{ textAlign: "end", fontSize: "12px" }}
                      >
                        {!!displayName ? displayName : ""}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="td1 md-font-weight">From Account</td>
                      <td className="td2 md-font-weight">
                        {!!fromAccountNo
                          ? `${productName} - ${fromAccountNo}`
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="td1 md-font-weight">Currency</td>
                      <td className="td2 md-font-weight">
                        {" "}
                        {!!currency ? currency.name : ""}{" "}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>

            {/* Second table */}
            <div className="col-sm-6 col-md-6 mb-3" style={{ padding: "40px" }}>
              <div className="m-3">
                <Table
                  responsive
                  // striped
                  bordered
                  // hover
                  className="accountTransfer"
                >
                  <thead>
                    <tr>
                      <th className="tableHead td1">Office</th>
                      <th
                        className="tableHead td22 md-font-weight"
                        style={{ textAlign: "end", fontSize: "12px" }}
                      >
                        {!!officeName ? officeName : ""}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="td1 md-font-weight">From Account Type</td>
                      <td className="td2 md-font-weight">
                        {!!fromAccountType ? fromAccountType.value : ""}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>

            {/* Transferring To */}
            <div className="col-xl-12">
              <div className="transfer-to">
                <p className="account-details-to1">Transferring To</p>
                <hr className="hr-divider" />
              </div>
              <div className="col-xl-6 col-md-12 mb-3 trans-date">
                <NewFormElements>
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Transaction date*</label>
                    <FormControl style={{ width: "60%" }}>
                      <div className="datepicker-100-width">
                        <DatePicker
                          id="published-date"
                          selected={transDate}
                          onChange={(date) => setTransDate(date)}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          placeholderText="dd/mmm/yyyy"
                        />
                      </div>
                    </FormControl>
                  </div>
                </NewFormElements>
              </div>

              {/* Add Account */}
              <div className="">
                {/* <div className="pt-4"></div> */}
                <div className="mb-3 add-account">
                  <hr className="hr-divider-add" />
                  {/* <div className="button-right ml-3" style={{marginBottom: "2rem"}} > */}
                  <ActionButton
                    color="#2C1DFF"
                    className="float-right"
                    style={{ marginBottom: "2rem" }}
                    onClick={() => addToArray()}
                  >
                    <img
                      src={
                        require("../../../../../assets/images/icons/add.svg")
                          .default
                      }
                      alt="add icon"
                    />
                    Add Account
                  </ActionButton>
                  {/* </div> */}
                </div>
              </div>
            </div>

            {/* Columns Section Office, Narration .... */}
            <div className="col-xl-12">
              {transferTo?.map((x, index) => {
                return (
                  <TransferToCard className="p-relative  row mb-4" key={index}>
                    <div className="col-xl-6 col-md-12">
                      {/* <NewFormElements> */}
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label">Office*</label>
                        <AntSelect
                          placeholder="Select Office"
                          value={
                            transferTo[index]?.office
                              ? transferTo[index]?.office
                              : undefined
                          }
                          onSelect={(value) => {
                            handleOfficeToChange(index, value);
                          }}
                          style={{ width: "60%" }}
                        >
                          {officeOption}
                        </AntSelect>
                      </div>
                      {/* </NewFormElements> */}
                    </div>
                    <div className="col-xl-6 col-md-12">
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label">Client*</label>
                        <AntSelect
                          className="searchSelect"
                          value={transferTo?.[index]?.client}
                          name="client"
                          showSearch
                          placeholder="Search Client"
                          //emptyMessage="Not found"
                          notFoundContent={!clientOptions ? <LoadingOutlined size="small" /> : 'No result'}
                          onSelect={(value) => {
                            handleClientToChange(index, value);
                            setSearchValue("");
                          }}
                          onSearch={value => setSearchValue(value)}
                          style={{ width: "60%" }}
                          filterOption={isNaN(Number(searchValue))
                            ? (input, option) => option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                            : false
                          }
                        >
                          {clientOptions?.map((option) => (
                            <Option
                              key={option?.entityId ?? option?.id}
                              value={option?.entityId ?? option?.id}
                            >
                              {option?.entityName ?? option?.displayName}
                            </Option>
                          ))}
                        </AntSelect>
                      </div>
                    </div>
                    <div className="col-xl-6 col-md-12">
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label">Account Type*</label>
                        <AntSelect
                          placeholder="Select Account Type"
                          size="md"
                          emptyMessage="Not found"
                          value={
                            transferTo[index].accType
                              ? transferTo[index].accType
                              : undefined
                          }
                          onSelect={(value) => {
                            handleAccountTypeToChange(index, value);
                          }}
                          style={{ width: "60%" }}
                        >
                          {accountTypesOption}
                        </AntSelect>
                      </div>
                    </div>
                    <div className="col-xl-6 col-md-12">
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label">Account*</label>
                        <AntSelect
                          placeholder="Select Account"
                          size="md"
                          name="account"
                          // value={toAccountOptions
                          //   ?.find((account) => account?.clientId === transferTo?.[index]?.client)
                          //   ?.id}
                          // showSearch
                          value={transferTo?.[index]?.account}
                          onSelect={(value) => {
                            handleAccountToChange(index, value);
                          }}
                          style={{ width: "60%" }}
                          // filterOption={(input, option) =>
                          //   option?.children2
                          //     ?.toLowerCase()
                          //     ?.indexOf(input?.toLowerCase()) >= 0
                          // }
                        >
                          {accountOption}
                        </AntSelect>
                      </div>
                    </div>
                    <div className="col-xl-6 col-md-12">
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label">Amount*</label>
                        <CurrencyFormat
                          value={transferTo[index].amount}
                          thousandSeparator={true}
                          className="amount-input "
                          placeholder="Enter amount"
                          style={{
                            width: "60%",
                          }}
                          onValueChange={(values) => {
                            const { value } = values;
                            handleAmountToChange(index, value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-md-12">
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label">Description*</label>
                        <Textarea
                          style={{ width: "60%", fontSize: "12px" }}
                          size="md"
                          name="description"
                          placeholder="Enter Description"
                          value={transferTo[index].description}
                          onChange={(e) =>
                            handleCommentToChange(index, e.target.value)
                          }
                        />
                      </div>
                    </div>
                    {transferTo.length > 1 ? (
                      <div
                        className="new-remove-icon"
                        onClick={() => removeTranferTo(index)}
                      >
                        <img
                          src={
                            require("../../../../../assets/images/icons/cancel.svg")
                              .default
                          }
                          className="icon-img"
                          alt="cancel icon"
                        />
                      </div>
                    ) : null}
                  </TransferToCard>
                );
              })}
            </div>
          </div>

          {/* Buttons */}
          <div className="row footer-style">
            <div className="button-rightt">
              <SubmitTransferActionButton
                color={`${!toOffice ? "#BFBBFF" : "#2C1DFF"}`}
                // color="#2C1DFF"
                // className={`${!toOffice ? " disableButton" : "float-right"}`}
                // className="float-right"
                className="float-right"
                // style={{ margin: "0px 25px" }}
                onClick={(e) => handleTransferAccount(e)}
                disable={`${!toOffice ? "not-allowed" : "pointer"}`}
              >
                {/* <img
                  src={
                    require("../../../../../assets/images/icons/check-mark.svg")
                      .default
                  }
                  alt="mark icon"
                /> */}
                Submit
              </SubmitTransferActionButton>
              <CancelActionButton
                color="#fff"
                className="float-right"
                style={{ marginRight: "20px" }}
                onClick={() => history.push(`/core/client/${clientId}`)}
              >
                {/* <img
                  src={
                    require("../../../../../assets/images/icons/cancel.svg")
                      .default
                  }
                  alt="add icon"
                /> */}
                Cancel
              </CancelActionButton>
            </div>
          </div>
        </ContentWrapper>
      </div>
    </>
  );
};

export default TransferFund;
