import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Badge,
} from "@chakra-ui/react";

import CloseSvg from "../../../assets/images/sidebar/close.svg";
import OpenSvg from "../../../assets/images/sidebar/open.svg";

import { setNavigationBarStatus } from "../../../actions/utilityActions";

import { Nav } from "./styles";
import CheckPermission from "../../../util/CheckPermission";
let overViewLink = [
  {
    link: "/teller",
    path: "/teller",
    iconActive:
      require("../../../assets/images/icons/dashboard-teller-icon.svg")
        .default,
    iconInActive:
      require("../../../assets/images/icons/dashboard-teller-icon.svg")
        .default,
    name: "Dashboard",
    section: "OVERVIEW",
    permission: "IGNORE",
  },
  {
    link: "/teller/transaction",
    path: "/teller/transaction",
    iconActive:
      require("../../../assets/images/icons/transaction-icon-teller.svg")
        .default,
    iconInActive:
      require("../../../assets/images/icons/transaction-icon-teller.svg")
        .default,
    name: "Transaction",
    section: "OVERVIEW",
    permission: "IGNORE",
  },
  // {
  //   link: "/teller/settlement",
  //   path: "/teller/settlement",
  //   iconActive:
  //     require("../../../assets/images/sidebar/settlement/settlement-active.svg")
  //       .default,
  //   iconInActive:
  //     require("../../../assets/images/sidebar/settlement/settlement-inactive.svg")
  //       .default,
  //   name: "Settlement",
  //   section: "OVERVIEW",
  //   permission: "IGNORE",
  // },
  {
    link: "/teller/requests",
    path: "/teller/requests",
    iconActive:
      require("../../../assets/images/icons/request-teller-icon.svg")
        .default,
    iconInActive:
      require("../../../assets/images/icons/request-teller-icon.svg")
        .default,
    name: "Requests",
    section: "OVERVIEW",
    permission: "IGNORE",
  },
];

const DashboardAsideNaivagation = () => {
  const dispatch = useDispatch();
  const navigationBar = useSelector((state) => state.navigationBar);
  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;
  let { navigationBarActive } = navigationBar || {};
  const { pathname } = useLocation();
  let myPath = pathname.split("/");
  let mainPath = `/${myPath[1]}${myPath[2] ? `/${myPath[2]}` : ""}`;

  const handleNavBar = () => {
    // console.log("status", !navigationBarActive);
    dispatch(setNavigationBarStatus(!navigationBarActive));
  };

  const returnNavigationBar = (data) => {
    return data.map(
      (
        {
          link,
          path,
          iconActive,
          iconInActive,
          hasBeta,
          name,
          section,
          permission,
        },
        i
      ) => {
        return (permissions && CheckPermission(permission, permissions)) ||
          permission === "IGNORE" ? (
          <li
            key={i}
            className="nav-item"
            // style={{ width: "54px", height: "54px" }}
          >
            {hasBeta ? (
              // <span className="nav-link text-center position-relative">
              // <RenderTooltip text={name} id={name}>
              <Link
                className={`nav-link position-relative ${
                  mainPath === path && "active"
                }`}
                to={link}
              >
                <Badge
                  className="position-absolute"
                  style={{
                    fontSize: 6,
                    top: 2,
                    right: 3,
                    padding: "0 2px",
                    background: "none",
                    color: "#17a2b8",
                    border: "1px solid #17a2b8",
                    borderRadius: 16,
                  }}
                  colorScheme="green"
                >
                  {name === "Payments" ? "Coming soon" : "Beta"}
                </Badge>

                {mainPath === path ? (
                  <div className="d-flex w-100">
                    <img
                      src={iconActive}
                      style={{
                        height: "20px",
                        width: "20px",
                        marginRight: "10px",
                      }}
                      alt="icon"
                      className="icon-img"
                    />
                    {navigationBarActive ? (
                      <>
                        <p className="sub-link">{name}</p>{" "}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <div className="d-flex w-100">
                    <img
                      src={iconInActive}
                      style={{
                        height: "20px",
                        width: "20px",
                        marginRight: "10px",
                      }}
                      alt="icon"
                      className="icon-img"
                    />
                    {navigationBarActive ? (
                      <>
                        <p className="sub-link">{name}</p>{" "}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </Link>
            ) : (
              <Link
                className={`nav-link  ${mainPath === path && "active"}`}
                to={link}
              >
                {mainPath === path ? (
                  <div className="d-flex w-100">
                    <img
                      src={iconActive}
                      style={{
                        height: "20px",
                        width: "20px",
                        marginRight: "10px",
                      }}
                      alt="icon"
                      className="icon-img"
                    />
                    {navigationBarActive ? (
                      <>
                        <p className="sub-link">{name}</p>{" "}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <div className="d-flex w-100">
                    <img
                      src={iconInActive}
                      style={{
                        height: "20px",
                        width: "20px",
                        marginRight: "10px",
                      }}
                      alt="icon"
                      className="icon-img"
                    />
                    {navigationBarActive ? (
                      <>
                        <p className="sub-link">{name}</p>{" "}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </Link>
              // </RenderTooltip>
            )}
          </li>
        ) : null;
      }
    );
  };

  const headers = [
    { heading: "OVERVIEW", link: overViewLink },
    // { heading: "BUSINESS", link: businessLink },
    // { heading: "ACCOUNT", link: accountLink },
  ];

  return (
    <Nav
      // role="aside"
      className={`${
        navigationBarActive ? "openbar" : "sidebar"
      } scroll-side-bar d-flex justify-content-center`}
    >
      {/* <div className="enlarge-sidebar pointer" onClick={() => handleNavBar()}>
        {navigationBarActive ? (
          <>
            <img
              style={{ display: "inline", marginLeft: "10px" }}
              src={CloseSvg}
              alt=""
            />
          </>
        ) : (
          <>
            <img
              style={{ display: "inline", marginLeft: "10px" }}
              src={OpenSvg}
              alt=""
            />{" "}
          </>
        )}
      </div> */}
  
      <div className="sidebar-sticky">
        <ul className="nav flex-column" style={{ paddingTop: "4.5rem" }}>
          {!navigationBarActive ? (
            headers.map((item, key) => (
              <div key={key}>
                <p className="side-bar-header"></p>
                {returnNavigationBar(item.link)}
                <div
                  style={{
                    margin: "10px 0px",
                  }}
                  className=""
                ></div>
              </div>
            ))
          ) : (
            <Accordion
              className="border-0"
              defaultIndex={[0]}
              allowToggle
              allowMultiple
            >
              {headers.map((item, key) => (
                <AccordionItem key={key} className="accordion-style">
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton
                        key={key}
                        className="p-0 d-flex align-items-center accordion-btn-style"
                      >
                        <p className="side-bar-header">{item.heading}</p>
                        <svg
                          viewBox="0 0 24 24"
                          focusable="false"
                          className={`chakra-icon chakra-accordion__icon ${
                            isExpanded ? "rotate" : ""
                          } css-j2ph2z`}
                          aria-hidden="true"
                        >
                          <path
                            fill="#fff"
                            d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"
                          ></path>
                        </svg>
                      </AccordionButton>
                      <AccordionPanel className="p-0">
                        {returnNavigationBar(item.link)}
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              ))}
            </Accordion>
          )}
        </ul>
      </div>
    </Nav>
  );
};

export default DashboardAsideNaivagation;
