import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Textarea, FormControl } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { rejectOrWithdrawSavingsAccount } from "../../../../../../actions/SavingsAndLoanActions";

import { FormClass, FormElements, ActionButton } from "../../styles.jsx";
import { formatDate } from "../../../../../../util/FormatDate";

const RejectOrWithdrawAccount = ({ onHide, savingsAccountTemplate, action, id, history }) => {
  const dispatch = useDispatch();

  const [submittedOnDate, setSubmittedOnDate] = useState();
  const [note, setNote] = useState("");

  let newDate = new Date(submittedOnDate);

  let {
    fieldOfficerName,
    fieldOfficerId,
    fieldOfficerOptions = [],
  } = savingsAccountTemplate || {};

  const handleRejectOrApproveAccount = () => {
    if (action === "reject") {
      const dataToSubmit = {
        rejectedOnDate: formatDate(newDate),
        dateFormat: "dd MMM yyyy",
        locale: "en",
        note,
      };

      dispatch(
        rejectOrWithdrawSavingsAccount(id, dataToSubmit, action, history)
      );
      onHide();
    }

    if (action === "withdrawnByApplicant") {
      const dataToSubmit = {
        withdrawnOnDate: formatDate(newDate),
        dateFormat: "dd MMM yyyy",
        locale: "en",
        note,
      };

      dispatch(
        rejectOrWithdrawSavingsAccount(id, dataToSubmit, action, history)
      );
      onHide();
    }
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          {action === "reject" ? "Reject " : "Withdraw "}Savings Account
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <FormElements style={{ width: "100%" }}>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">
                {action === "reject" ? "Rejected" : "Withdraw"} On*
              </label>
              <FormControl style={{ width: "60%" }}>
                <div className="datepicker-100-width">
                  <DatePicker
                    id="published-date"
                    selected={submittedOnDate}
                    onChange={(date) => setSubmittedOnDate(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="dd/mmm/yyyy"
                  />
                </div>
              </FormControl>
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Additional note</label>
              <Textarea
                style={{ fontSize: "12px" }}
                value={note}
                onChange={(e) => setNote(e.target.value)}
                size="md"
                resize={"none"}
              />
            </div>
          </FormElements>
        </FormClass>
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-1">
          <ActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require("../../../../../../assets/images/icons/cancel.svg")
                  .default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={(e) => {
              e.preventDefault();
              handleRejectOrApproveAccount();
            }}
          >
            <img
              src={
                require("../../../../../../assets/images/icons/save.svg")
                  .default
              }
              alt="submit icon"
            />
            Submit
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default RejectOrWithdrawAccount;
