import React, { useEffect, useState } from "react";
import { ContentWrapper, ContentCard } from "./styles";
import LoadingScreen from "./LoadingScreen";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CheckPermission from "../../../../util/CheckPermission";

const CardListings = [
  {
    icon: require('../../../../assets/images/icons/office.svg').default,
    title: 'Manage Office',
    description: 'Add new, modify and edit Offices',
    route: 'manage-offices',
    permission: 'READ_OFFICE',
  },
  // {
  //   icon: require("../../../../assets/images/icons/holiday.svg").default,
  //   title: "Manage Holiday",
  //   description: "Define holidays and leave for offices",
  //   route: "manage-holidays",
  //   permission: "READ_HOLIDAY",
  // },
  {
    icon: require('../../../../assets/images/icons/history.svg').default,
    title: 'Standing Order History',
    description: 'View logged history of standing orders',
    route: 'standing-order-history',
    permission: 'READ_STANDINGINSTRUCTIONS',
  },
  {
    icon: require('../../../../assets/images/icons/working-days.svg').default,
    title: 'Working Days',
    description:
      'Define working days and configure behaviour of payments due to holiday',
    route: 'working-days',
    permission: 'READ_WORKINGDAYS',
  },
  {
    icon: require('../../../../assets/images/icons/funds.svg').default,
    title: 'Payment Type',
    description: 'Manage payment types',
    route: 'payment-type',
    permission: 'PAYMENT_TYPES',
  },
  {
    icon: require('../../../../assets/images/icons/tier.svg').default,
    title: 'Tier',
    description: 'Manage, define Tier levels',
    route: 'tier',
    permission: 'READ_CLIENTTIER',
  },
  // {
  //   icon: require("../../../../assets/images/icons/provisioning-entries.svg")
  //     .default,
  //   title: "Configuration",
  //   description: "Global Configurations And Cache Settings",
  //   route: "configuration",
  //   permission: "READ_CONFIGURATION",
  // },
  // {
  //   icon: require("../../../../assets/images/icons/history.svg").default,
  //   title: "Loan Calculator",
  //   description:
  //     "Loan calculator to calculate based on loan product and other parameters",
  //   route: "loan-calculator",
  // },
];

const OrganizationView = () => {
  const [loading, setloading] = useState(true);
  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;
  useEffect(() => {
    setTimeout(() => {
      setloading(false);
    }, 1000);
  }, []);

  return !!loading ? (
    <LoadingScreen />
  ) : (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Utilities</h3>
            <p className="sub-title">
              Manage groups, employess and business information
            </p>
            <p className="font-weight sub-title mt-2">
              {/* <span className="text-info">
                <Link to="/core/configuration">Configuration</Link>
              </span>{" "} */}
              Utilities
            </p>
          </div>
        </div>
        <ContentWrapper>
          <div className="row">
            {CardListings.map(
              ({ icon, title, description, route, permission }, i) =>
                CheckPermission(permission, permissions) && (
                  <div className="col-sm-12 col-md-4 col-lg-3 mb-4" key={i}>
                    <Link to={`organization/${route}`}>
                      <ContentCard>
                        <img
                          className="teller-card-icon"
                          src={icon}
                          alt={title}
                        />
                        <div className="p-2">
                          <p className="teller-head teller-ellipses">{title}</p>
                          <p className="teller-sub-menu">{description}</p>
                        </div>
                      </ContentCard>
                    </Link>
                  </div>
                )
            )}
          </div>
        </ContentWrapper>
      </div>
    </>
  );
};

export default OrganizationView;
