import styled from "styled-components";

export const ContentWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 0px #ededf6;
  min-height: 70vh;
  margin-top: 20px;
  padding: 50px;

  .button-right {
    width: 100%;
    height: 50px;
    padding-top: 20px;
  }
`;

export const TagButton = styled.button`
  padding: 5px 10px;
  height: 30px;
  background: #edf3ff;
  border-radius: 5px;
  font-family: "AventaMedium";
  font-size: 12px;
  line-height: 20px;
  color: #2c1dff;
  margin: 5px 5px !important;
  height: 35px;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  position: relative;
  cursor: pointer;
  img {
    height: 10px;
    margin-right: 10px;
    display: inline;
  }
`;

export const RoleButton = styled.button`
  background: ${(props) => props.color} !important;
  border-radius: 5px !important;
  border: 1px solid ${(props) => props.border} !important;
  color: ${(props) => (props.textColor ? props.textColor : "#fff")} !important;
  margin: 0 10px;
  border: none;
  display: inline;
  font-size: 14px;
  height: 35px;
  width: 100%;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  position: relative;
  cursor: pointer;
  img {
    height: 10px;
    margin-right: 10px;
    display: inline;
  }
  &:hover {
    box-shadow: ${(props) =>
      props.shadow ? "0px 4px 4px rgba(0, 0, 0, 0.09)" : "none"};
    color: #fff;
  }
`;

export const FormClass = styled.div``;
