import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";

const DropdownMenu = ({
  multiple = false,
  options,
  byId,
  required,
  values,
  updateServices,
  objKey,
  //   value,
  label,
  remove,
  deleteServices,
  // removeCurrencyDetail,
}) => {
  const [value, setValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef(null);

  useEffect(() => {
    if (values) {
      setValue([...values]);
    } else setValue("");
  }, [values]);

  const onChange = (e, o) => {
    if (multiple) {
      if (value.includes(byId ? o.value : o.label)) {
        const newOptions = [...value];
        setValue([
          ...newOptions.filter((opt) => opt !== (byId ? o.value : o.label)),
        ]);
        if (objKey) {
          updateServices({
            [objKey]: [
              ...newOptions.filter((opt) => opt !== (byId ? o.value : o.label)),
            ],
          });
        } else {
          updateServices([
            ...newOptions.filter((opt) => opt !== (byId ? o.value : o.label)),
          ]);
        }
      } else {
        setValue((prev) => [...prev, byId ? o.value : o.label]);
        if (objKey) {
          updateServices({ [objKey]: [...value, byId ? o.value : o.label] });
        } else {
          updateServices([...value, byId ? o.value : o.label]);
        }
      }
    } else {
      setValue(byId ? o.value : o.label);
    }
  };

  const getValue = (id) => {
    return options?.find((option) => option.value === id)?.label;
  };

  const deleteOption = (e, o) => {
    e.stopPropagation();
    const newOptions = [...value];

    setValue(newOptions.filter((opt) => opt !== o));

    deleteServices(o);

    if (objKey) {
      updateServices({
        [objKey]: newOptions.filter((opt) => opt !== o),
      });
    } 
    // else {
    //   updateServices(newOptions.filter((opt) => opt !== o));
    // }
  };

  const selectAll = () => {
    const allData = [];
    options.map((op) =>
      byId ? allData.push(op.value) : allData.push(op.label)
    );
    setValue(allData);
  };

  const clear = () => {
    setValue("");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="dropdown-container" ref={dropdownRef}>
      <label className="dropdown-label">
        {label}
        {required ? <span className="input--required"></span> : null}
      </label>
      <div
        className="dropdown-select"
        onClick={() => setIsOpen((prev) => !prev)}
        onBlur={() => setIsOpen(false)}
      >
        <motion.div className="dropdown-value-container">
          {typeof value == "string" ? (
            value
          ) : (
            <AnimatePresence initial={false}>
              {value.map((val, index) => (
                <motion.div
                  initial={{ height: 0 }}
                  animate={{ height: "auto" }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ type: "spring", stiffness: 600, damping: 40 }}
                  key={val}
                  layout
                  className="shrink-0"
                >
                  <motion.div className="dropdown-value-item relative">
                    <span>{byId ? getValue(val) : val}</span>
                    <button type="button" onClick={(e) => deleteOption(e, val)}>
                      <svg
                        width="9"
                        height="10"
                        viewBox="0 0 9 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M4.5 1.25C2.42893 1.25 0.75 2.92893 0.75 5C0.75 7.07107 2.42893 8.75 4.5 8.75C6.57107 8.75 8.25 7.07107 8.25 5C8.25 2.92893 6.57107 1.25 4.5 1.25ZM4.5 2C6.15685 2 7.5 3.34315 7.5 5C7.5 6.65685 6.15685 8 4.5 8C2.84315 8 1.5 6.65685 1.5 5C1.5 3.34315 2.84315 2 4.5 2ZM4.01517 3.98483L3.97984 3.95364L3.94178 3.92764C3.79781 3.84185 3.60875 3.86092 3.48483 3.98483C3.33839 4.13128 3.33839 4.36872 3.48483 4.51517L3.96938 5L3.48483 5.48484L3.45364 5.52016C3.33925 5.66727 3.34965 5.87998 3.48483 6.01516C3.63128 6.16161 3.86872 6.16161 4.01517 6.01516L4.5 5.53062L4.98484 6.01516L5.02016 6.04636C5.16727 6.16075 5.37998 6.15035 5.51516 6.01516C5.66161 5.86872 5.66161 5.63128 5.51516 5.48484L5.03062 5L5.51516 4.51517L5.54636 4.47984C5.66075 4.33273 5.65035 4.12002 5.51516 3.98483C5.36872 3.83839 5.13128 3.83839 4.98484 3.98483L4.5 4.46938L4.01517 3.98483Z"
                          fill="#141414"
                        />
                      </svg>
                    </button>
                  </motion.div>
                </motion.div>
              ))}
            </AnimatePresence>
          )}
        </motion.div>

        <button type="button">
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="ml-2"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.00012 10.8333C7.82292 10.8343 7.6526 10.7648 7.52679 10.64L4.86012 7.97332C4.59871 7.71191 4.59871 7.28807 4.86012 7.02666C5.12154 6.76524 5.54538 6.76524 5.80679 7.02666L8.00012 9.23332L10.2001 7.11332V7.11332C10.4463 6.83956 10.8678 6.81722 11.1416 7.06342C11.4154 7.30962 11.4377 7.73114 11.1915 8.00491C11.1694 8.02945 11.1456 8.05232 11.1201 8.07332L8.45346 10.6467C8.33154 10.7642 8.16946 10.8309 8.00012 10.8333L8.00012 10.8333Z"
              fill="#3CB2FF"
            />
          </svg>
        </button>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.ul
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.18 }}
            className="dropdown-options-container"
            whileHover={() => setIsOpen(true)}
            onBlur={() => setIsOpen(false)}
            style={{ width: "225px" }}
          >
            {options?.map((o) => (
              <li
                key={o.label}
                className="dropdown-options-items"
                onClick={(e) => {
                  e.stopPropagation();
                  onChange(e, o);
                }}
              >
                {multiple && (
                  <span>
                    {value.includes(byId ? o.value : o.label) ? (
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12 2H4C2.89543 2 2 2.89543 2 4V12C2 13.1046 2.89543 14 4 14H12C13.1046 14 14 13.1046 14 12V4C14 2.89543 13.1046 2 12 2ZM4 3.33333H12C12.3682 3.33333 12.6667 3.63181 12.6667 4V12C12.6667 12.3682 12.3682 12.6667 12 12.6667H4C3.63181 12.6667 3.33333 12.3682 3.33333 12V4C3.33333 3.63181 3.63181 3.33333 4 3.33333ZM10.4714 6.19526C10.2111 5.93491 9.78895 5.93491 9.5286 6.19526L7.33333 8.39L6.47141 7.5286L6.4086 7.47314C6.14707 7.26979 5.76892 7.28827 5.5286 7.5286C5.26825 7.78895 5.26825 8.21105 5.5286 8.4714L6.86193 9.80474L6.92473 9.8602C7.18626 10.0635 7.56442 10.0451 7.80474 9.80474L10.4714 7.13807L10.5269 7.07527C10.7302 6.81374 10.7117 6.43559 10.4714 6.19526Z"
                          fill="#3CB2FF"
                        />
                      </svg>
                    ) : (
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12 2H4C2.89543 2 2 2.89543 2 4V12C2 13.1046 2.89543 14 4 14H12C13.1046 14 14 13.1046 14 12V4C14 2.89543 13.1046 2 12 2ZM4 3.33333H12C12.3682 3.33333 12.6667 3.63181 12.6667 4V12C12.6667 12.3682 12.3682 12.6667 12 12.6667H4C3.63181 12.6667 3.33333 12.3682 3.33333 12V4C3.33333 3.63181 3.63181 3.33333 4 3.33333ZM6.66667 8C6.66667 7.26362 7.26362 6.66667 8 6.66667C8.73638 6.66667 9.33333 7.26362 9.33333 8C9.33333 8.73638 8.73638 9.33333 8 9.33333C7.26362 9.33333 6.66667 8.73638 6.66667 8Z"
                          fill="#BDBDBD"
                        />
                      </svg>
                    )}
                  </span>
                )}
                <span>{o.label}</span>
              </li>
            ))}
            {/* <div className="select-clear">
              <li
                onClick={(e) => e.stopPropagation()}
                className="dropdown-value--extra"
              >
                <button className="extra-all" onClick={selectAll}>
                  Select All
                </button>
              </li>
              <li className="dropdown-value--extra">
                <button className="extra-clear" onClick={clear}>
                  Clear
                </button>
              </li>
            </div> */}
          </motion.ul>
        )}
      </AnimatePresence>
    </div>
  );
};
export default DropdownMenu;
