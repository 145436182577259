import React from "react";
import { Modal, Table } from "react-bootstrap";
import { ModalActionButton } from "../../styles";

function LoanDocDetailsModal({ onHide, loanDocumentsDetails, docModal }) {
  if (!docModal) {
    return null;
  }
  let { fullName, fullDescription, fullFileName } = loanDocumentsDetails;

  return (
    <>
      <Modal.Header>
        <Modal.Title>Loan Document Details</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <div className="full-width mt-4">
          <Table responsive striped bordered hover className="table-form-style">
            {/* <thead className="border-0">
              <tr>
                <td className="font-weight-bold md-font-weight">Name</td>
                {/* <td>{fullName}</td>
                <textarea disabled style={{ width: "100%", resize: "none" , textTransform: "uppercase"}}>{fullName}</textarea>
              </tr>
            </thead> */}
            <tbody>
              <tr>
                <td className="font-weight-bold md-font-weight">Name</td>
                <td>
                  <textarea className="loan-text-area" disabled>
                    {fullName}
                  </textarea>
                </td>
              </tr>
              <tr>
                <td className="font-weight-bold md-font-weight">Description</td>
                <td>
                  <textarea disabled className="loan-text-area">
                    {fullDescription}
                  </textarea>
                </td>
              </tr>
              <tr>
                <td className="font-weight-bold md-font-weight">FileName</td>
                <td>
                  <textarea disabled className="loan-text-area">
                    {fullFileName}
                  </textarea>
                </td>

                {/* <td>{fullFileName}</td> */}
              </tr>
            </tbody>
          </Table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-1">
          <ModalActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require("../../../../../assets/images/icons/cancel.svg").default
              }
              alt="cancel icon"
            />
            Cancel
          </ModalActionButton>
        </div>
      </Modal.Footer>
    </>
  );
}

export default LoanDocDetailsModal;
