import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
// clear the one up
import Button from "../../../../components/Button/index";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { formatDateString, formatTime } from "../../../../util/FormatDate";
import DatePicker from "react-datepicker";
import CurrencyFormat from "react-currency-format";
import Toast from "../../../../util/Toast";
import { TimePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Select, Textarea, Checkbox, Divider } from "@chakra-ui/react";
import {
  createTellerCashieraction,
  getAllOffices,
  getCashierTemplate,
  getSingleTellerGroupCashier,
  getCurrencies,
} from "../../../../actions/tellerActions";
import { FormClass, ActionButton } from "./styles";
import DropdownMenu from "../TellerGroup/DropdownMenu";
import { Select as AntSelect } from "antd";
import CurrencyTeller from "./CurrencyTeller";

const AddTellerModal = ({
  closeAddTellerForm,
  addTeller,
  showModal,
  setShowModal,
  onHide,
}) => {
  const { Option } = AntSelect;

  const { id } = useParams();
  const [staffId, setStaffId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [endDate, setEndDate] = useState("");
  const [debitAccountId, setDebit] = useState("");
  const [creditAccountId, setCredit] = useState("");
  const [isFullDay, setFullDay] = useState(false);
  const [description, setDescription] = useState("");
  const [dailyTellerLimit, setLimit] = useState("");
  const [withdrawalLimit, setWithdrawalLimit] = useState("");
  const hourStartTime = !!startTime ? startTime.slice(0, 2) : "";
  const minStartTime = !!startTime ? startTime.slice(-5, -3) : "";
  const hourEndTime = !!endTime ? endTime.slice(0, 2) : "";
  const minEndTime = !!endTime ? endTime.slice(-5, -3) : "";
  const dispatch = useDispatch();

  const tellerGroupReducer = useSelector((state) => state.tellerReducer);
  const { cashierTemplate, glAccountOptions } = tellerGroupReducer;
  const [optionSelected, setOptionSelected] = useState([]);

  const { currencyOptions = [] } = cashierTemplate || {};

  const [defaultCurrencyId, setDefaultCurrencyId] = useState("");
  const [defaultCurrencyOptions, setDefaultCurrencyOptions] = useState([]);
  const [defaultSetCurrency, setDefaultSetCurrency] = useState([]);
  const tellerReducer = useSelector((state) => state.tellerReducer);

  let { setCurrencies = [] } = tellerReducer || {};

  const [currenciesOptions, setCurrenciesOptions] = useState([]);
  const [tellerCurrencyOptions, setTellerCurrencyOptions] = useState([]);

  const defaultCurrenciesAcct = {
    glAccountId: "",
    currency: "",
    dailyLimit: "",
    withdrawalLimit: "",
  };

  useEffect(() => {
    if (optionSelected) {
      const selectedCurrencyOption = currenciesOptions.filter((option) =>
        optionSelected?.includes(option.value)
      );
      setDefaultCurrencyOptions(selectedCurrencyOption);
    }
  }, [optionSelected, currenciesOptions]);

  useEffect(() => {
    setDefaultSetCurrency(setCurrencies);
  }, []);

  const [numberOfCurrencies, setNumberOfCurrencies] = useState([]);

  useEffect(() => {
    if (currencyOptions) {
      const newCurrencies = [];
      currencyOptions.map((curr) =>
        newCurrencies.push({ value: curr.code, label: curr.name })
      );
      setCurrenciesOptions(newCurrencies);
    } else setCurrenciesOptions([]);
  }, [currencyOptions]);

  useEffect(() => {
    if (currencyOptions) {
      const newSelected = [];
      currencyOptions.map(({ code, name }) => newSelected.push(code, name));
      setTellerCurrencyOptions(newSelected);
    } else setTellerCurrencyOptions([]);
  }, [currencyOptions]);

  const handleUpdateServices = (service_Data) => {
    setOptionSelected([...service_Data]);
    defaultCurrenciesAcct.currency = service_Data[service_Data.length - 1];
    setNumberOfCurrencies([...numberOfCurrencies, defaultCurrenciesAcct]);
  };

  useEffect(() => {
    dispatch(getCurrencies());
  }, []);

  useEffect(() => {
    dispatch(getAllOffices());
    dispatch(getCashierTemplate(id));
  }, [id]);

  const data2 =
    isFullDay === true
      ? ""
      : {
          hourStartTime: hourStartTime,
          hourEndTime: hourEndTime,
          minStartTime: minStartTime,
          minEndTime: minEndTime,
        };

  const handleDeleteServices = (o) => {
    const index = optionSelected.indexOf(o);


    // Remove the corresponding element from the optionSelected array
    const newOptionSelected = optionSelected.filter((option) => option !== o);

    // Remove the corresponding element from the numberOfCurrencies array
    const newNumberOfCurrencies = numberOfCurrencies.filter(
      (currency, i) => i !== index
    );

    // Remove the corresponding element from the oldCurrenciesTeller array
    const newOldCurrenciesTeller = defaultCurrencyOptions.filter(
      (currency) => currency.code !== o
    );

    // Update the state of optionSelected and numberOfCurrencies
    setOptionSelected(newOptionSelected);
    setNumberOfCurrencies(newNumberOfCurrencies);
    setDefaultCurrencyOptions(newOldCurrenciesTeller);

  };

  const onSubmit = (e) => {
    // if (!staffId || !debitAccountId || !creditAccountId || !startDate) {
    if (!staffId || !startDate) {
      dispatch(Toast({ text: "Required fields can't be empty", icon: "info" }));
    } else {
      let glCurrencies = [];
      numberOfCurrencies.map((t) => {
        let { glAccountId, currency, dailyLimit, withdrawalLimit } = t;

        glCurrencies.push({
          glAccountId,
          currency,
          dailyLimit,
          withdrawalLimit,
          isDebit: true,
          isCredit: true,
        });
      });

      // "isCredit": true,
      //       "isDebit": true

      const dataToSend = {
        locale: "en",
        staffId: staffId,
        description: description,
        dateFormat: "dd/MMM/yyyy",
        startDate: formatDateString(startDate, "dd/MMM/yyyy"),
        endDate: formatDateString(endDate, "dd/MMM/yyyy"),
        isFullDay: isFullDay,
        ...data2,
        currencies: optionSelected,
        defaultCurrency: defaultCurrencyId,
        glCurrencies,
      };
      const func = () => {
        dispatch(getSingleTellerGroupCashier(id));
      };
      dispatch(createTellerCashieraction(dataToSend, id, func));
      closeAddTellerForm();
      setStaffId("");
      setDescription("");
      setStartDate("");
      setEndDate("");
      setStartTime("");
      setEndTime("");
      setDebit("");
      setCredit("");
      setLimit("");
      setWithdrawalLimit("");
      setOptionSelected([]);
      setDefaultCurrencyId("");
      setCurrenciesOptions([]);
      setOptionSelected([]);
      setDefaultCurrencyOptions([]);
      // setFullDay(false);
      onHide();
    }
  };

  const handleCancel = () => {
    closeAddTellerForm();
    setStaffId("");
    setDescription("");
    setStartDate("");
    setEndDate("");
    setStartTime("");
    setEndTime("");
    setDebit("");
    setCredit("");
    setLimit("");
    setWithdrawalLimit("");
    setNumberOfCurrencies([]);
    setShowModal((prev) => !prev);
  };

  return (
    <>
      {showModal ? (
        <>
          <Background>
            <ModalWrapperT showModal={showModal}>
              <ModalContent>
                <Modal.Header>
                  <Modal.Title>Add Teller</Modal.Title>
                </Modal.Header>
                <Modal.Body className="wc-modal">
                  <Form className="px-4 pt-3">
                    <GridContainer className="d-flex justify-content-between">
                      <label htmlFor="Staff" className="text-label">
                        Staff<span className="required-color">*</span>
                      </label>
                      <div
                        className="select-100-width"
                        style={{ width: "90%" }}
                      >
                        <Select
                          isFullWidth="true"
                          name="staffId"
                          placeholder="Select"
                          value={staffId}
                          onChange={(e) => setStaffId(e.target.value)}
                        >
                          {!!cashierTemplate?.staffOptions?.length > 0
                            ? cashierTemplate?.staffOptions?.map((item) => {
                                return (
                                  <option key={item.id} value={item?.id}>
                                    {item?.displayName}
                                  </option>
                                );
                              })
                            : ""}
                        </Select>
                      </div>
                    </GridContainer>
                    <GridContainer className="d-flex justify-content-between">
                      <label htmlFor="Staff" className="text-label">
                        Currency<span className="required-color">*</span>
                      </label>
                      <div
                        className="select-100-width"
                        style={{ width: "90%" }}
                      >
                        <DropdownMenu
                          multiple
                          name="currency"
                          placeholder="Select"
                          updateServices={handleUpdateServices}
                          options={currenciesOptions}
                          byId
                          deleteServices={handleDeleteServices}
                        />
                        {/* <CheckSelect /> */}
                        {/* <CheckboxSelect /> */}
                      </div>
                    </GridContainer>
                    {/* Adding currencies and displaying teller limits and dropdown */}
                    {defaultSetCurrency &&
                      numberOfCurrencies.length > 0 &&
                      defaultSetCurrency.map(({ code, name }) => {
                        return optionSelected.includes(code) ? (
                          <CurrencyTeller
                            currency={name}
                            cashierTemplate={cashierTemplate}
                            numberOfCurrencies={numberOfCurrencies}
                            setNumberOfCurrencies={setNumberOfCurrencies}
                            currencyIndex={optionSelected.indexOf(code)}
                            currencyCode={code}
                          />
                        ) : (
                          ""
                        );
                      })}
                    {numberOfCurrencies.length > 0 ? (
                      <hr
                        style={{
                          // border: "1px solid rgb(218, 220, 229)",
                          width: "100%",
                          marginBottom: "15px",
                          background: "#DADCE5",
                        }}
                      />
                    ) : (
                      ""
                    )}
                    <GridContainer className="d-flex justify-content-between">
                      <label htmlFor="Staff" className="text-label">
                        Default Currency
                        <span className="required-color">*</span>
                      </label>
                      <div
                        className="select-100-width"
                        style={{ width: "90%" }}
                      >
                        <Select
                          isFullWidth="true"
                          name="defaultCurrency"
                          placeholder="Select"
                          value={defaultCurrencyId}
                          onChange={(e) => setDefaultCurrencyId(e.target.value)}
                        >
                          {defaultCurrencyOptions && defaultCurrencyOptions
                            ? defaultCurrencyOptions?.map((item) => {
                                return (
                                  <option key={item.value} value={item?.value}>
                                    {item?.label}
                                  </option>
                                );
                              })
                            : ""}
                        </Select>
                      </div>
                    </GridContainer>
                    <GridContainer className="d-flex justify-content-between">
                      <label htmlFor="Description" className="text-label">
                        Description
                      </label>
                      <div
                        className="textarea-100-width"
                        style={{ width: "90%" }}
                      >
                        <Textarea
                          name="description"
                          style={{ fontSize: "12px" }}
                          onChange={(e) => setDescription(e.target.value)}
                          id=""
                          size="sm"
                          rows="2"
                        ></Textarea>
                      </div>
                    </GridContainer>

                    {/* Credit Account GL */}
                    <GridContainer className="d-flex">
                      <label
                        htmlFor="Daily Teller Limit"
                        className="text-label"
                      >
                        Start Date<span className="required-color">*</span>
                      </label>
                      <div
                        className="datepicker-100-width"
                        style={{ width: "90%", borderRadius: "5px" }}
                      >
                        <DatePicker
                          id="published-date"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          placeholderText="dd/mmm/yyyy"
                        />
                      </div>
                    </GridContainer>
                    <GridContainer className="d-flex">
                      <label
                        htmlFor="Daily Teller Limit"
                        className="text-label"
                      >
                        End Date<span className="required-color">*</span>
                      </label>
                      <div
                        className="datepicker-100-width"
                        style={{ width: "90%" }}
                      >
                        <DatePicker
                          id="published-date"
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          placeholderText="dd/mmm/yyyy"
                        />
                      </div>
                    </GridContainer>
                    <GridContainer className="">
                      <label htmlFor="Is full day" className="text-label">
                        Is full day
                      </label>
                      <Checkbox
                        checked={isFullDay}
                        onChange={(e) => setFullDay(e.target.checked)}
                        colorScheme="blue"
                      />
                    </GridContainer>
                    {isFullDay === true ? (
                      ""
                    ) : (
                      <>
                        <GridContainer className="d-flex">
                          <label htmlFor="Start time" className="text-label">
                            Start time<span className="required-color">*</span>
                          </label>
                          <div
                            className="timepicker-100-width"
                            style={{ width: "90%" }}
                          >
                            <TimePicker
                              onChange={(timeString) => {
                                const timeHr = formatTime(timeString?._d);
                                setStartTime(timeHr);
                              }}
                            />
                          </div>
                        </GridContainer>
                        <GridContainer className="d-flex">
                          <label
                            htmlFor="Daily Teller Limit"
                            className="text-label"
                          >
                            End time<span className="required-color">*</span>
                          </label>
                          <div
                            className="timepicker-100-width"
                            style={{ width: "90%" }}
                          >
                            <TimePicker
                              onChange={(timeString) => {
                                const timeHr = formatTime(timeString?._d);
                                setEndTime(timeHr);
                              }}
                            />
                          </div>
                        </GridContainer>
                      </>
                    )}
                    <div className="pb-3"></div>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <div className="float-right mt-1">
                    {/* <ActionButton color="#2C1DFF" onClick={onHide}> */}
                    <ActionButton
                      color="#2C1DFF"
                      // onClick={() => {
                      //   setNumberOfCurrencies([]);
                      //   // setDefaultSetCurrency([]);
                      //   // setCurrenciesOptions([])
                      //   setShowModal((prev) => !prev);
                      // }}
                      onClick={handleCancel}
                    >
                      <img
                        src={
                          require("../../../../assets/images/icons/cancel.svg")
                            .default
                        }
                        alt="cancel icon"
                      />
                      Cancel
                    </ActionButton>
                    <ActionButton
                      color="#2C1DFF"
                      style={{ margin: "0px 0px 0 10px" }}
                      onClick={onSubmit}
                    >
                      <img
                        src={
                          require("../../../../assets/images/icons/save.svg")
                            .default
                        }
                        alt="submit icon"
                      />
                      Add teller
                    </ActionButton>
                  </div>
                </Modal.Footer>
              </ModalContent>
            </ModalWrapperT>
          </Background>
        </>
      ) : null}
    </>
  );
};

export default AddTellerModal;

const Form = styled.div`
  z-index: 10;
  // border: 0.5px solid #0000004b;
  border-top: 0px;
  border-radius: 0 0 5px 5px;
`;
const GridContainer = styled.div`
  display: grid !important;
  grid-template-columns: 2fr 3fr;
  align-items: center;
  margin-bottom: 15px;
  color: #969cba;

  label {
    font-size: 18px;
    color: #969cba;
  }
`;
const Background = styled.div`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalWrapper = styled.div`
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  display: grid;
  position: relative;
  z-index: 99;
  border-radius: 10px;
`;
const ModalWrapperT = styled.div`
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  display: grid;
  position: relative;
  z-index: 99;
  border-radius: 10px;
  width: 500px;
`;

const ModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
`;

// {/* <Select
//   optionFilterProp="children"
//   value={defaultCurrencyValue}
//   onChange={(value) => setDefaultCurrencyValue(value)}
//   autoComplete="false"
// >
//   {!!currencies?.length > 0
//     ? currencies?.map((curr) => {
//         return (
//           <Option key={curr?.code} value={curr?.code}>
//             {curr?.name}
//           </Option>
//         );
//       })
//     : ""}
// </Select>; */}
