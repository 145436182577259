import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Input, Select } from "@chakra-ui/react";
import { FormClass } from "../styles";
import { FormElements, ActionButton } from "../../styles";
import Toast from "../../../../../util/Toast";

const AddPaymentDetailsModal = ({ onHide, paymentTypes, onSubmit }) => {
  const [paymentType, setPaymentType] = useState("");
  const [accNumber, setAccNumber] = useState("");
  const [routingCode, setRoutingCode] = useState("");
  const [receiptNumber, setReceiptNumber] = useState("");
  const [bankNumber, setBankNumber] = useState("");
  const [chequeNumber, setChequeNumber] = useState("");

  return (
    <>
      <Modal.Header>
        <Modal.Title>Add payment details</Modal.Title>
      </Modal.Header>
      <Modal.Body className="wc-modal">
        <div className="d-flex align-items-center justify-content-center flex-direction-coloumn">
          <FormClass style={{ width: "90%" }}>
            <FormElements style={{ width: "90%" }}>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Payment type</label>
                <Select
                  placeholder="Select Payment type"
                  size="md"
                  name="paymentType"
                  value={paymentType}
                  onChange={(e) => setPaymentType(e.target.value)}
                >
                  {!!paymentTypes ? (
                    paymentTypes.map(({ name, id }) => {
                      return (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      );
                    })
                  ) : (
                    <option>Loading</option>
                  )}
                </Select>
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Account number</label>
                <Input
                  style={{ width: "60%" }}
                  size="md"
                  name="accNumber"
                  value={accNumber}
                  onChange={(e) => setAccNumber(e.target.value)}
                />
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Cheque Number</label>
                <Input
                  style={{ width: "60%" }}
                  size="md"
                  name="chequeNumber"
                  value={chequeNumber}
                  onChange={(e) => setChequeNumber(e.target.value)}
                />
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Routing code</label>
                <Input
                  style={{ width: "60%" }}
                  size="md"
                  name="routingCode"
                  value={routingCode}
                  onChange={(e) => setRoutingCode(e.target.value)}
                />
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Receipt Number</label>
                <Input
                  style={{ width: "60%" }}
                  size="md"
                  name="receiptNumber"
                  value={receiptNumber}
                  onChange={(e) => setReceiptNumber(e.target.value)}
                />
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Bank Number</label>
                <Input
                  style={{ width: "60%" }}
                  size="md"
                  name="bankNumber"
                  value={bankNumber}
                  onChange={(e) => setBankNumber(e.target.value)}
                />
              </div>
            </FormElements>
          </FormClass>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-1">
          <ActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require("../../../../../assets/images/icons/cancel.svg").default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={(e) => {
              e.preventDefault();
              if (!paymentType || paymentType === "") {
                Toast({ text: "Please select a payment type" });
              } else if (!accNumber || accNumber === "") {
                Toast({ text: "Please add an account number" });
              } else {
                const dataToSend = {
                  paymentType,
                  accountNumber: accNumber,
                  routingCode,
                  receiptNumber,
                  bankNumber,
                  chequeNumber,
                };
                onSubmit(dataToSend);
                onHide();
                Toast({ text: "Payment Detail Succesfully Added" });
              }
            }}
          >
            <img
              src={
                require("../../../../../assets/images/icons/save.svg").default
              }
              alt="submit icon"
            />
            Submit
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default AddPaymentDetailsModal;
