import React from "react";
import ContentLoader from "react-content-loader";

const cards = [
  { id: "1" },
  { id: "2" },
  { id: "3" },
  { id: "4" },
  { id: "5" },
  { id: "6" },
];

const Loader = () => {
  return (
    <>
      {cards.map((index) => {
        return (
          <div className="col-md-4 col-sm-12 mt-4" key={index}>
            <ContentLoader
              speed={2}
              width={300}
              height={100}
              viewBox="0 0 300 100"
              backgroundColor="#f3f3f3"
              foregroundColor="#ffffff"
            >
              <rect x="2" y="11" rx="3" ry="3" width="180" height="14" />
              <rect x="2" y="39" rx="3" ry="3" width="160" height="14" />
              <rect x="2" y="69" rx="3" ry="3" width="94" height="10" />
              <rect x="2" y="89" rx="3" ry="3" width="64" height="9" />
              {/* <rect x="2" y="66" rx="3" ry="3" width="180" height="9" /> */}
              {/* <rect x="90" y="82" rx="3" ry="3" width="120" height="6" /> */}
              {/* <rect x="90" y="106" rx="3" ry="3" width="146" height="6" /> */}
            </ContentLoader>
          </div>
        );
      })}
    </>
  );
};

export default Loader;
