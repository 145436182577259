import styled from "styled-components";

export const ActionButtonsGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  justify-content: space-around;
  select {
    // background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    border-radius: 3px;
    height: 47px;
    min-width: 100px;
    border: none;
    // margin: ;
  }
`;

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 20px;
  border-radius: 10px;
  margin-top: 20px;
  table {
    font-size: 14px;
  }
`;

export const ActionButton = styled.button`
  background: ${(props) => props.color || "#2C1DFF"} !important;
  border-radius: 5px !important;
  color: #fff !important;
  margin: 0 3px;
  font-family: 'AventaMedium';
  border: none;
  display: inline;
  font-size: 14px;
  height: 35px;
  width: 130px;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  position: relative;
  cursor: pointer;
  img {
    height: 14px;
    margin-right: 10px;
    display: inline;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;

export const FormClass = styled.div``;

export const FormElements = styled.div`
  // width: 90%;
  margin-top: 30px;
  margin-bottom: 30px;
  display: block;
  .text-muted {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #969cba;
  }
  .css-42b2qy {
    width: 60%;
    color: #666666;
    & > option {
      padding: 0.5rem 0;
    }
  }
`;

export const GeneratedReportContainer = styled.div`
  background: #ffffff;
  border-radius: 5px;
  min-height: 70vh;
  display: flex;
  flex-direction: column;

  .heading {
    padding: 20px 16px;
    font-family: "AventaSemiBold";
  }
  .center-text {
    font-family: "AventaSemiBold";
    font-size: 16px;
    color: #969cba;
  }
`;

export const GeneratedReport = styled.div`
  background: #ffffff;
  grid-gap: 3px;
  border: 0.5px solid #969cba;
  border-radius: 5px;
  margin: 15px 5px;
  display: grid;
  grid-template-columns: 4fr 4fr 1fr;
  padding: 18px 10px 10px;
  align-items: center;
  position: relative;

  span {
    font-size: 13px;
  }
  h4 {
    color: #333333;
  }
  .pending {
    font-size: 12px;
    margin: 0px 3px;
    color: #969cba;
  }
  .time_query {
    font-size: 12px;
    color: #969cba;
  }
  .time_value {
    font-size: 12px;
  }
`;

export const IconButton = styled.button`
  background: ${(props) => props.color} !important;
  width: 35px;
  height: 35px;
  background: #2C1DFF;
  text-align: center;
  border-radius: 10px;
  /* margin-right: 5px; */
  img {
    display: inline;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;
