import React from "react";
import { Select } from "@chakra-ui/react";
import { Select as AntdSelect } from "antd";

import { FormElements } from "../styles";

const LegalForm = ({
  officeId,
  officeOptions,
  staffId,
  staffOptions,
  legalFormId,
  createCustomerHandler,
  clientLegalFormOptions,
  setOfficeId,
  setStaffId,
}) => {
  let { Option } = AntdSelect;
  return (
    <>
      <div style={{ width: "60%" }}>
        <FormElements>
          <div className="form-group d-flex justify-content-between align-items-center">
            <label className="text-label">Office*</label>
            <AntdSelect
              showSearch
              placeholder="Select a bank"
              optionFilterProp="children"
              className="chakra-select__wrapper"
              onChange={setOfficeId}
              value={officeId}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {!!officeOptions.length
                ? officeOptions.map(({ id, name }) => {
                    return (
                      <Option value={id} key={id}>
                        {name}
                      </Option>
                    );
                  })
                : null}
            </AntdSelect>
          </div>
          <div className="form-group d-flex justify-content-between align-items-center">
            <label className="text-label">Staff</label>
            <AntdSelect
              showSearch
              placeholder="Select a bank"
              optionFilterProp="children"
              className="chakra-select__wrapper"
              onChange={setStaffId}
              value={staffId}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {!!staffOptions.length
                ? staffOptions.map(({ id, displayName }) => {
                    return (
                      <Option value={id} key={id}>
                        {displayName}
                      </Option>
                    );
                  })
                : null}
            </AntdSelect>
          </div>
          <div className="form-group d-flex justify-content-between align-items-center">
            <label className="text-label">Legal Form*</label>
            <Select
              size="md"
              name="legalFormId"
              value={legalFormId}
              onChange={createCustomerHandler}
            >
              {!!clientLegalFormOptions.length
                ? clientLegalFormOptions.map(({ value, id }) => {
                    return (
                      <option value={id} id={id}>
                        {value}
                      </option>
                    );
                  })
                : null}
            </Select>
          </div>
        </FormElements>
      </div>
    </>
  );
};

export default LegalForm;
