import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { Input, Textarea, FormControl } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import CurrencyFormat from "react-currency-format";
import { FormClass, FormElements, ActionButton } from "../../styles.jsx";

import {
  getLoanDetails,
  approveLoan,
  getloanDetaillsReset,
  getCustomerAccounts,
} from "../../../../../../actions/customerActions.js";
import { formatDate } from "../../../../../../util/FormatDate.js";

const ApproveLoan = ({ userId, onHide, transId, history }) => {
  const dispatch = useDispatch();

  const loanDetailsList = useSelector((state) => state.loanDetails);
  const { loading, loanDetails } = loanDetailsList;

  //STATE HANDLER FOR DEPOSIT INFO
  const [note, setNote] = useState("");
  const [approvedDate, setApprovedDate] = useState(new Date());
  const [expectedDisburseDate, setExpectedDisburseDate] = useState(new Date());

  // const [approveTest, setApproveTest] = useState()

  const [approvedAmount, setApprovedAmount] = useState();

  // const { approvedPrincipal } = approvedAmount

  // console.log(approvedAmount, 'appAmmmmont')
  // console.log(approvedPrincipal, 'appPrinnccee')

  // const [transactionAmount, setTransactionAmount] = useState(0)

  // console.log(onHide, loading, "hidddddee");

  const handleAmountChange = (e) => {
    e.preventDefault();
    // console.log(e.target.value, "eeee");
    setApprovedAmount(e.target.value);
    // setTransactionAmount(e.target.value)
  };

  useEffect(() => {
    dispatch(getLoanDetails(transId));
  }, [dispatch, transId]);

  useEffect(() => {
    if (loanDetails && !!loanDetails.approvedPrincipal) {
      setApprovedAmount(loanDetails.approvedPrincipal);
    }
  }, [loanDetails]);

  //RESET VARIABLES WITH ID DATAS
  let formatApprovedDate = "";
  let formatExpectedDisburseDate = "";
  if (approvedDate !== "") {
    formatApprovedDate = formatDate(approvedDate);
  }
  if (expectedDisburseDate !== "") {
    formatExpectedDisburseDate = formatDate(expectedDisburseDate);
  }

  const submitApprovalHandler = async () => {
    let approvalData = {
      approvedLoanAmount: +approvedAmount,
      approvedOnDate: formatApprovedDate,
      expectedDisbursementDate: formatExpectedDisburseDate,
      disbursementData: [],
      locale: "en",
      dateFormat: "dd MMM yyy",
    };
    // console.log(approvalData, transId, "cheeekkkkkk");
    await dispatch(approveLoan(transId, approvalData, history));
    await dispatch(getCustomerAccounts(userId));
    onHide();
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>Approve loan account</Modal.Title>
      </Modal.Header>
      <Modal.Body className="wc-modal">
        <div className="d-flex align-items-center justify-content-center flex-direction-coloumn">
          <FormClass style={{ width: "90%" }}>
            <FormElements style={{ width: "100%" }}>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Approved on*</label>
                <FormControl style={{ width: "60%" }}>
                  <DatePicker
                    id="published-date"
                    selected={approvedDate}
                    onChange={(date) => setApprovedDate(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="dd/mmm/yyyy"
                  />
                </FormControl>
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">
                  Expected disbursement date*
                </label>
                <FormControl style={{ width: "60%" }}>
                  <DatePicker
                    id="published-date"
                    selected={expectedDisburseDate}
                    onChange={(date) => setExpectedDisburseDate(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="dd/mmm/yyyy"
                  />
                </FormControl>
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Approved amount</label>

                {/* <Input
                style={{ width: "60%" }}
                size="md"
                name="approvedAmount"
                value={approvedAmount}
                onChange={(e) => setApprovedAmount(e)}
              /> */}

                <CurrencyFormat
                  value={approvedAmount}
                  thousandSeparator={true}
                  className="amount-input "
                  style={{
                    width: "60%",
                  }}
                  onValueChange={(values) => {
                    const { value } = values;
                    setApprovedAmount(value);
                  }}
                />
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Transaction amount*</label>
                {/* <Input
                  style={{ width: "60%" }}
                  size="md"
                  name="transactionAmount"
                  value={approvedAmount}
                  onChange={(e) => handleAmountChange(e)}
                /> */}
                <CurrencyFormat
                  value={approvedAmount}
                  thousandSeparator={true}
                  className="amount-input "
                  style={{
                    width: "60%",
                  }}
                  onValueChange={(values) => {
                    const { value } = values;
                    setApprovedAmount(value);
                  }}
                />
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Additional note</label>
                <Textarea
                  size="md"
                  resize={"none"}
                  className="amount-input"
                  name="note"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
              </div>
            </FormElements>
          </FormClass>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-4">
          <ActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require("../../../../../../assets/images/icons/cancel.svg")
                  .default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton
            onClick={submitApprovalHandler}
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
          >
            <img
              src={
                require("../../../../../../assets/images/icons/save.svg")
                  .default
              }
              alt="submit icon"
            />{" "}
            Submit
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default ApproveLoan;
