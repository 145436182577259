import React, { useEffect, useState } from "react";
// import { Modal } from "@chakra-ui/react";
import { Modal } from "react-bootstrap";
// import { ActionButton, FormClass, FormElements } from "../../styles";
import { Input, Select, Textarea } from "@chakra-ui/react";
// import { Input, Textarea } from "@chakra-ui/react";
import ModalContainer from "../../../components/ModalContainer";
import OtpModal from "./OtpModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getBanks,
  getNameEnquiry,
  getNameEnquiryReset,
  getNibssConnectTenantsWallets,
  getNibssTenantsAccountBalance,
} from "../../../../../actions/nibssConnectActions";
import Toast from "../../../../../util/Toast";
import { formatAmount, formatCurrency } from "../../../../../util/FormatAmount";
import { ActionButton, FormClass, FormElements } from "../../styles";
import CurrencyFormat from "react-currency-format";
import { Select as AntSelect } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { textToUpperCase } from "../../../../../util/Tools";
import closeModal from "../../../../../assets/images/icons/closePayment.svg";
import {
  getClientBvn,
  getClientNuban,
} from "../../../../../services/Api/nibss.api.services";

const PaymentModal = ({
  walletNumberId,
  makePaymentModal,
  setMakePaymentModal,
  onHide,
  otpModal,
  setOtpModal,
  onClose,
  setReceiverSessionId,
  setReceiverChannelCode,
  setReceiverInstitutionCode,
  setReceiverAccountNumber,
  setReceiverBvn,
  setReceiverKyc,
  setSenderBvn,
  receiverSessionId,
  receiverChannelCode,
  receiverInstitutionCode,
  receiverAccountNumber,
  receiverBvn,
  receiverKyc,
  senderBvn,
  setReceiverAccountName,
  setSenderAccountName,
  setSenderAccountNumber,
  setSenderKyc,
  setTransferNarration,
  setTransferAmount,
  receiverAccountName,
  senderAccountName,
  senderAccountNumber,
  senderKyc,
  transferNarration,
  transferAmount,
  bankType,
  setBankType,
  setClientNuban,
  clientNuban,
  tenantIdentifier
}) => {
  const dispatch = useDispatch();
  const { Option } = AntSelect;

  const nibssGetBanksTemplate = useSelector(
    (state) => state.nibssGetBanksTemplate
  );
  const { nibssGetBanks } = nibssGetBanksTemplate;
  const { banks } = nibssGetBanks;

  // const [amount, setAmount] = useState("");
  // const [accountNumber, setReceiverAccountName] = useState("");
  // const [bankType, setBankType] = useState({
  //   accountValue: "",
  //   accountCode: "",
  // });
  // const [narration, setNarration] = useState("");
  const [bankError, setBankError] = useState("");
  const [resCode, setRescode] = useState("");
  // const [walletNumberId, setWalletNumber] = useState("");
  const [formError, setFormError] = useState({
    amountError: false,
    accountNumberError: false,
    bankTypeError: false,
    narrationError: false,
  });
  const [fetching, setFetching] = useState(false);
  const [clientBvn, setClientBvn] = useState("");

  const [selectedWalletNumber, setSelectedWalletNumber] =
    useState(walletNumberId);

  // console.log(selectedWalletNumber, "selectedWalletNumber");

  const nibssTenantsWalletTemplate = useSelector(
    (state) => state.nibssTenantsWalletTemplate
  );
  const { nibssTenantsWalletsTemplate } = nibssTenantsWalletTemplate;

  // console.log(nibssGetBanksTemplate, "nibssGetBanksTemplate");

  const nibssPerformNameEnquiries = useSelector(
    (state) => state.nibbsPerfomNameEnquiry
  );

  const nibssTenantsAccountBalanceTemplate = useSelector(
    (state) => state.nibssTenantsAccountBalanceTemplate
  );

  const { nibssTenantAccountBalance } =
    nibssTenantsAccountBalanceTemplate || {};
  const {
    summary: { availableBalance = {} } = {},
    clientId = {},
    id = {},
    clientName = {},
    accountNo = {},
  } = nibssTenantAccountBalance || {};

  const stateData = useSelector((state) => state);

  // console.log(stateData, "stateData");

  const { error, loading, nibssPerformNameEnquiry } = nibssPerformNameEnquiries;

  // console.log(nibssPerformNameEnquiries, " line 58");
  const {
    sessionId,
    channelCode,
    beneficiaryInstitutionCode,
    beneficiaryAccountNumber,
    beneficiaryAccountName,
    beneficiaryBankVerificationNumber,
    beneficiaryKycLevel,
    responseCode,
  } = nibssPerformNameEnquiry || {};

  // console.log(nibssPerformNameEnquiry, "nibssPerformNameEnquiry");

  const { response } = error || {};
  const { data } = response || {};

  useEffect(() => {
    setReceiverSessionId(sessionId);
    setReceiverChannelCode(channelCode);
    setReceiverInstitutionCode(beneficiaryInstitutionCode);
    setReceiverAccountNumber(beneficiaryAccountNumber);
    setReceiverBvn(beneficiaryBankVerificationNumber);
    setReceiverKyc(beneficiaryKycLevel);
    setReceiverAccountName(beneficiaryAccountName);

    setSenderBvn(clientBvn);
    setSenderAccountName(clientName);
    setSenderKyc(1);
    setSenderAccountNumber(accountNo);
  }, [
    sessionId,
    channelCode,
    beneficiaryInstitutionCode,
    beneficiaryAccountNumber,
    beneficiaryAccountName,
    beneficiaryBankVerificationNumber,
    beneficiaryKycLevel,
    clientBvn,
    clientName,
  ]);



  //  Get Bakance start ===========
  const getBalanceValidate = () => {
    if (selectedWalletNumber && selectedWalletNumber !== null) {
      dispatch(getNibssTenantsAccountBalance(selectedWalletNumber));
    }
  };
  useEffect(() => {
    getBalanceValidate();
  }, [dispatch, selectedWalletNumber]);

  useEffect(() => {
    setRescode(responseCode);
  }, [nibssPerformNameEnquiry]);

  useEffect(() => {
    if (data) {
      setBankError(data);
    }
    setBankError("");
  }, []);

  useEffect(() => {
    dispatch(getNibssConnectTenantsWallets(tenantIdentifier));
  }, []);

  useEffect(() => {
    dispatch(getBanks());
  }, []);

  useEffect(() => {
    // const getData = setTimeout(() => {
    if (receiverAccountNumber?.length === 10) {
      dispatch(getNameEnquiry(receiverAccountNumber, bankType.accountValue));
    }
    // dispatch(getNameEnquiryReset());
    // }, 100);

    // return () => clearTimeout(getData)
  }, [receiverAccountNumber, bankType.accountValue, dispatch]);

  // Get Client Bvn
  useEffect(() => {
    if (clientId) {
      getClientBvn(clientId)
        // .then((data) => {console.log(data.data[0].row[1] , data)})
        .then((data) => {
          setClientBvn(data?.data[0]?.row[1]);
        });
    }
  }, [clientId]);

  // Get Client Nuban
  useEffect(() => {
    if (id ) {
      getClientNuban(id )
        // .then((data) => {console.log(data.data[0].row[1] , data)})
        .then((data) => {
          setClientNuban(data?.data[0]?.row[1]);
        });
    }
  }, [id ]);


  // const handleOtpModalClose = () => setOtpModal(false);
  if (!makePaymentModal) {
    return null;
  }

  const handleContinue = (e) => {
    e.preventDefault();
    setOtpModal(false);

    let dataToSubmit = {
      selectedWalletNumber,
      transferAmount,
      receiverAccountNumber,
      bankType,
      transferNarration,
    };
    if (bankType.accountValue && resCode !== "00") {
      dispatch(
        Toast({
          text: " Account Number Incorrect ",
          icon: "error",
          timer: 2000,
        })
      );
    } else if (
      transferAmount === "" ||
      receiverAccountNumber === "" ||
      bankType.accountValue === "" ||
      transferNarration === ""
    ) {
      setFormError({
        amountError: true,
        accountNumberError: true,
        bankTypeError: true,
        narrationError: true,
      });
    } else if (dataToSubmit) {
      setFormError({
        amountError: false,
        accountNumberError: false,
        bankTypeError: false,
        narrationError: false,
      });
      setOtpModal(true);
      setMakePaymentModal(false);
      // console.log("am showing modal");
    } else {
      setRescode("");
    }
  };

  const BankOption =
    banks &&
    banks?.map((d, index) => (
      <Option key={d.institutionName} value={d.institutionCode}>
        {textToUpperCase(d.institutionName)}
      </Option>
    ));

  return (
    <>
      {/* <form onSubmit={handleContinue}> */}
      <Modal.Header>
        <Modal.Title>Make Payment</Modal.Title>
        <div className="payment__close-modal" onClick={onHide}>
          <img src={closeModal} alt="" style={{ width: "10px" }} />
        </div>
      </Modal.Header>
      <Modal.Body className="wc-modal">
        <div className="col-sm-12 col-md-12 d-flex align-items-center justify-content-center flex-direction-coloumn">
          <FormClass style={{ width: "90%" }}>
            {/* <div className="select-100-width "> */}
            <FormElements style={{ width: "100%" }}>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="payment-text-label ">NIBSS account</label>
                <Select
                  placeholder="Select Account"
                  size="md"
                  className="text-capitalize"
                  name="accName"
                  value={
                    selectedWalletNumber
                      ? selectedWalletNumber
                      : "Select Account"
                  }
                  onChange={(e) => setSelectedWalletNumber(e.target.value)}
                >
                  {nibssTenantsWalletsTemplate !== undefined &&
                  nibssTenantsWalletsTemplate
                    ? nibssTenantsWalletsTemplate &&
                      nibssTenantsWalletsTemplate?.map((item) => {
                        return (
                          <option value={item.walletId} key={item.walletId}>
                            {item.walletNumber}
                          </option>
                        );
                      })
                    : null}
                </Select>
              </div>

              {/* Amount */}
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="payment-text-label ">Amount*</label>
                {/* <Input */}
                <CurrencyFormat
                  value={transferAmount}
                  name="transferAmount"
                  thousandSeparator={true}
                  // className="amount-input"
                  className={`${
                    !transferAmount && formError.amountError
                      ? "AmountformError"
                      : "amount-input"
                  }`}
                  style={{ width: "60%" }}
                  size="md"
                  onValueChange={(values) => {
                    const { value } = values;
                    setTransferAmount(value);
                  }}
                  // onChange={(e) => setAmount(e.target.value)}
                />
              </div>
              <span className="formErrorSpan">
                {!transferAmount && formError.amountError
                  ? "Amount field is empty"
                  : ""}
              </span>
              {/* check for insufficient balance */}
              {transferAmount > availableBalance ? (
                <>
                  <span className="insufficientText">Insufficient Balance</span>
                </>
              ) : null}

              {/* bankType */}
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="payment-text-label ">Bank institution*</label>
                <AntSelect
                  notFoundContent={
                    fetching ? <LoadingOutlined size="small" /> : "No result"
                  }
                  // loading={true}
                  showSearch
                  placeholder="Select / Search Bank Institution"
                  showArrow={false}
                  // filterOption={false}
                  size="md"
                  optionFilterProp="children"
                  // onSearch={handleSearch}
                  filterOption={(input, Option) =>
                    (Option?.key ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  className={`${
                    !bankType?.accountValue && formError.bankTypeError
                      ? "formError"
                      : "select-drop"
                  }`}
                  name="accName"
                  value={bankType.accountValue}
                  onChange={(e) =>
                    setBankType({ ...bankType, accountValue: e })
                  }
                  style={{ width: "60%" }}
                >
                  {BankOption ? (
                    BankOption
                  ) : (
                    <>
                      <option>Loading...</option>
                    </>
                  )}
                </AntSelect>
              </div>
              <span className="formErrorSpan">
                {!bankType?.accountValue && formError.bankTypeError
                  ? "Please ensure the field is selected."
                  : ""}
              </span>

              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="payment-text-label ">Account Number*</label>
                <Input
                  style={{ width: "60%" }}
                  size="md"
                  className={`${
                    !receiverAccountNumber && formError.accountNumberError
                      ? "formError"
                      : "form-group-input"
                  }`}
                  name="receiverAccountName"
                  maxLength={10}
                  pattern="[+-]?\d+(?:[.,]\d+)?"
                  value={receiverAccountNumber}
                  onChange={(e) => setReceiverAccountNumber(e.target.value)}
                />
              </div>
              <span className="formErrorSpan">
                {!receiverAccountNumber && formError.accountNumberError
                  ? "Please ensure the field is filled."
                  : ""}
              </span>

              {/* Return Name */}
              {loading ? (
                <>
                  {" "}
                  <span className="loader">loading...</span>
                </>
              ) : receiverAccountNumber?.length === 10 &&
                bankType &&
                resCode === "00" ? (
                <>
                  <div className="nameEnquiry">
                    <span>{receiverAccountName}</span>
                  </div>
                </>
              ) : loading ? (
                <>
                  {" "}
                  <span className="loader">loading...</span>
                </>
              ) : (
                <>
                  {receiverAccountNumber?.length === 10 &&
                    bankType &&
                    error && (
                      <div className="errorEnquiry">
                        <span>{data?.message}</span>
                      </div>
                    )}
                </>
              )}

              <div className="form-group d-flex justify-content-between align-items-center narration">
                <label className="payment-text-label ">Narration*</label>
                {/* <div className="form-group-block"> */}
                <Textarea
                  className={`${
                    !transferNarration && formError.narrationError
                      ? "formError"
                      : "inherit"
                  }`}
                  style={{
                    width: "100% !important",
                    resize: "none",
                    fontSize: "12px",
                  }}
                  size="md"
                  name="transferNarration"
                  value={transferNarration}
                  onChange={(e) => setTransferNarration(e.target.value)}
                />
              </div>
              {/* error message */}
              <span className="formErrorSpan">
                {!transferNarration && formError.narrationError
                  ? "Please ensure the field is filled."
                  : ""}
              </span>
            </FormElements>
          </FormClass>
        </div>
      </Modal.Body>
      {/* Download */}
      <div className="payment__footer">
        <div
          color="#2C1DFF"
          className={`${
            transferAmount > availableBalance
              ? "disableDownload"
              : "downloadButton"
          }`}
          // className="downloadButton"
          style={{ margin: "0px 5px" }}
          id="continue"
          onClick={handleContinue}
        >
          Continue
        </div>{" "}
        {/* <ModalContainer
          show={otpModal}
          dialogClassName="document_infoModal"
        >
          <OtpModal
            otpModal = {otpModal}
            onHide = {handleOtpModalClose}
          />
        </ModalContainer> */}
      </div>
      {/* </form> */}
    </>
  );
};

export default PaymentModal;
