import React, { useState } from "react";
import { Input, Select } from "@chakra-ui/react";
import { ActionButton, FormElements, FormClass } from "../styles";
import { Modal } from "react-bootstrap";

const CreateDataTable = ({ onHide }) => {
  const [newHolidayInfo, setNewHolidayInfo] = useState({
    entity: "",
    status: "",
    dataTable: "",
  });
  const { entity, dataTable, status } = newHolidayInfo;
  const createDataTableHandler = (e) =>
    setNewHolidayInfo({ ...newHolidayInfo, [e.target.name]: e.target.value });
  return (
    <>
      <Modal.Header>
        <Modal.Title>Create Entity-Data Table Check</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <FormElements style={{ width: "100%" }}>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Entity*</label>
              <Input
                style={{ width: "70%" }}
                size="md"
                name="entity"
                value={entity}
                onChange={createDataTableHandler}
                placeholder="Input Office Name"
              />
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Status*</label>
              <Select
                placeholder="Select specific date or next repayment  date"
                size="md"
                name="status"
                value={status}
                onChange={createDataTableHandler}
              >
                <option>false</option>
                <option>true</option>
              </Select>
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Data Table*</label>
              <Select
                placeholder="Select specific date or next repayment  date"
                size="md"
                name="dataTable"
                value={dataTable}
                onChange={createDataTableHandler}
              >
                <option>false</option>
                <option>true</option>
              </Select>
            </div>
          </FormElements>
          <div className="float-right mt-4">
            <ActionButton color="#2C1DFF" onClick={onHide}>
              <img
                src={
                  require("../../../../../../assets/images/icons/cancel.svg")
                    .default
                }
                alt="cancel icon"
              />
              Cancel
            </ActionButton>
            <ActionButton color="#2C1DFF" style={{ margin: "0px 0px 0 10px" }}>
              <img
                src={
                  require("../../../../../../assets/images/icons/save.svg")
                    .default
                }
                alt="submit icon"
              />
              Submit
            </ActionButton>
          </div>
        </FormClass>
      </Modal.Body>
    </>
  );
};

export default CreateDataTable;
