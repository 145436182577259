import React, { useEffect, useState } from "react";
import { ContentWrapper, ActionButtonsGroup, ActionButton } from "./styles";
import { InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getDataTables } from "../../../../actions/configurationActions";
import CustomItemLoader from "../../../../components/Loader/CustomItemLoader";
import TableLoader from "./components/TableLoader"
import { replaceUnderscoreToTextOnly } from "../../../../util/Tools";
import CheckPermission from "../../../../util/CheckPermission";

const ManageDataTables = ({ history }) => {
  const accountingData = useSelector((state) => state.accountingData);
  let { isLoading } = accountingData;

  const dataTablesData = useSelector((state) => state.dataTables);

  let { dataTables = [] } = dataTablesData || {};

  const dispatch = useDispatch();

  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch(getDataTables());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Table configuration
  let dataSource = [];

  const columns = [
    {
      title: "Data Table Name",
      dataIndex: "dataTableName",
      key: "dataTableName",
    },
    {
      title: "Associated With",
      dataIndex: "associatedWith",
      key: "associatedWith",
    },
  ];

  dataTables &&
    dataTables.length &&
    dataTables.forEach((data, index) => {
      let { registeredTableName, applicationTableName } = data;

      dataSource.push({
        key: index,
        dataTableName: registeredTableName,
        associatedWith: replaceUnderscoreToTextOnly(
          applicationTableName.substring(2)
        ),
      });
    });

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Manage Data Tables</h3>
            <p className="sub-title">
              Add new extra fields to any entity in the form of data tables
            </p>
            {/* <p className="font-weight sub-title mt-2">
              <span className="text-info">
                <Link to="/core/configuration">Configuration</Link> /{" "}
                <Link to="/core/configuration/organization">Utilities</Link>
              </span>{" "}
              / Manage Data Tables
            </p> */}
          </div>
          <div className="col-sm-12 col-md-6 px-0 pt-3">
            <ActionButtonsGroup>
              {/* <div className="col-sm-12 col-md-6 pr-2">
                <InputGroup>
                  <InputLeftElement
                    width="3.1rem"
                    height="38px"
                    pointerEvents="none"
                  >
                    <img
                      src={
                        require("../../../../assets/images/icons/search.svg")
                          .default
                      }
                      style={{ height: "30px" }}
                      alt="Icon"
                      className="icon-img"
                    />
                  </InputLeftElement>
                  <Input
                    style={{
                      background: "#ffffff",
                      height: "35px",
                    }}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    type="text"
                    className="general-font-size"
                    placeholder="Search by name"
                  />
                </InputGroup>
              </div> */}
              <div className="col-sm-12 col-md-4 p-0 d-flex">
                {CheckPermission("CREATE_DATATABLE", permissions) && (
                  <ActionButton
                    big
                    id="createDataTable"
                    onClick={() =>
                      history.push("/core/manage-data-tables/create")
                    }
                  >
                    <img
                      src={
                        require("../../../../assets/images/icons/add.svg")
                          .default
                      }
                      alt="add"
                    />
                    Create Data Table
                  </ActionButton>
                )}
              </div>
            </ActionButtonsGroup>
          </div>
        </div>
        <ContentWrapper>
          {isLoading ? (
            <TableLoader />
          ) : (
            <>
              <Table
                columns={columns}
                dataSource={dataSource}
                pagination={{ defaultPageSize: 13 }}
                defaultPageSize={13}
                onRow={(r) => ({
                  onClick: () => {
                    history.push(
                      `/core/manage-data-tables/details/${r.dataTableName}`
                    );
                  },
                })}
              />
            </>
          )}
        </ContentWrapper>
      </div>
    </>
  );
};

export default ManageDataTables;
