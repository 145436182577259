import React from "react";
import Loading from "./Loading";
// import Loading2 from "./Loading2";
import { ActionButton } from "../../styles";
const Loader = () => {
  return (
    <div>
      <div className="row">
        <div className="col-md-4">
          {[...Array(6)].map((_, i) => (
            <div className="mb-3" key={i}>
              <ActionButton color="#68d0fd" textColor="#002C79">
                <Loading />
              </ActionButton>
            </div>
          ))}
        </div>
        {/* <div className="col-md-8">
          {[...Array(8)].map((_, i) => (
            <div className="mb-5" key={i}>
              <Loading2 />
            </div>
          ))}
        </div> */}
      </div>
    </div>
  );
};

export default Loader;
