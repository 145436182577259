import React, { useState, useRef, useEffect } from "react";
import { Container, ShowMore, AddActionButton } from "./styles";
import { Avatar } from "@chakra-ui/react";
import { formatDateString, formatDateTime } from "../../../../../util/FormatDate";
import { formatCurrency } from "../../../../../util/FormatAmount";
import { Link } from "react-router-dom";
import { toTitleCase } from "../../../../../util/Tools";
import ModalContainer from "../../../components/ModalContainer";
import {
  getTellerCashierDetails,
  getTransactionSummary,
  resetTellerCashierDetails,
  deleteTellerCashier,
  getSingleTellerGroupCashier,
} from "../../../../../actions/tellerActions";
import { ReactComponent as ThreeDots } from "../../../../../assets/images/icons/three-dots.svg";

import { useOutsideClick } from "@chakra-ui/react";
import { ViewModal, EditModal, DeleteModal, RemoveModal } from "./Modal";
import { useDispatch, useSelector } from "react-redux";
import CheckPermission from "../../../../../util/CheckPermission";
import logo from "../../../../../assets/images/icons/total-customers.svg";

const TellerCard = ({
  noBorder,
  noMore,
  onClick,
  data,
  officeName,
  tellerId,
  reducedHeight,
}) => {
  const dispatch = useDispatch();
  const tellerGroupReducer = useSelector((state) => state.tellerReducer);
  const { tellerCashierDetails } = tellerGroupReducer;
  const cashierId = data?.id;
  const ref = useRef();
  const [showMore, setShowMore] = useState(false);
  const [modalContentName, setModalContentName] = useState("");
  const [modalState, toggleModal] = useState(false);
  useOutsideClick({
    ref: ref,
    handler: () => setShowMore(false),
  });

  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  const startDate = data?.startDate ? formatDateTime(data?.startDate) : "";
  // const endDate = data?.endDate ? formatDateTime(data?.startDate) : "";

  const func = (telId) => {
    dispatch(getSingleTellerGroupCashier(telId));
  };
  const deleteCashier = (telId, cashId) => {
    dispatch(deleteTellerCashier(telId, cashId, func));
  };

  useEffect(() => {
    if (
      modalContentName === "view" ||
      modalContentName === "edit" ||
      modalContentName === "delete"
    ) {
      dispatch(getTellerCashierDetails(tellerId, cashierId));
    }
  }, [cashierId, dispatch, modalContentName, tellerId]);

  const handleCloseModal = () => {
    toggleModal(false);
    setModalContentName("");
    dispatch(resetTellerCashierDetails());
  };
  const handleClick = (value) => {
    setModalContentName(value);
    return toggleModal(true);
  };
  const displayModalContent = () => {
    switch (modalContentName) {
      case "view":
        return (
          <ViewModal
            data={tellerCashierDetails}
            handleCloseModal={handleCloseModal}
          />
        );
      case "edit":
        return <EditModal data={data} handleCloseModal={handleCloseModal} />;
      case "delete":
        return (
          <DeleteModal
            tellerId={tellerId}
            cashierId={cashierId}
            deleteTellerCashier={deleteCashier}
            data={tellerCashierDetails}
            handleCloseModal={handleCloseModal}
          />
        );
      default:
        return;
    }
  };
  return (
    <Container
      noBorder={noBorder}
      reducedHeight={reducedHeight}
      status="active"
    >
      <ModalContainer
        size="md"
        show={modalState}
        dialogClassName="document_infoModal"
      >
        {modalState && displayModalContent()}
      </ModalContainer>
      {!!showMore && (
        <ShowMore ref={ref}>
          <button
            onClick={() => {
              handleClick("view");
            }}
            className="text-label"
          >
            View
          </button>
          {CheckPermission("UPDATECASHIERALLOCATION_TELLER", permissions) && (
            <button
              onClick={() => {
                handleClick("edit");
              }}
              className="text-label"
            >
              Edit
            </button>
          )}
          {CheckPermission("DELETECASHIERALLOCATION_TELLER", permissions) && (
            <button
              onClick={() => {
                handleClick("delete");
              }}
              className="text-label"
            >
              Delete
            </button>
          )}
        </ShowMore>
      )}
      {reducedHeight ? (
        <div className="d-flex align-items-center">
          <div className="pr-3">
            <img src={logo} className="teller-card-icon" />
          </div>
          <div>
            {noMore ? (
              <h5 className="teller-head">
                {toTitleCase(data?.staffName) || "Test Name"}
              </h5>
            ) : (
              <Link to={`/core/teller-group/${tellerId}/teller/${cashierId}`}>
                <h5 className="teller-head teller-ellipses">
                  {toTitleCase(data?.staffName) || "Test Name"}
                </h5>
                <p className="tag">{officeName || "Test office"}</p>
              </Link>
            )}
          </div>
        </div>
      ) : (
        <div className="d-flex align-items-center">
          <div>
            {noMore ? (
              <h5 className="teller-head">
                {toTitleCase(data?.staffName) || "Test Name"}
              </h5>
            ) : (
              <Link to={`/core/teller-group/${tellerId}/teller/${cashierId}`}>
                <h5 className="teller-head teller-ellipses">
                  {toTitleCase(data?.staffName) || "Test Name"}
                </h5>
                <p className="tag">{officeName || "Test office"}</p>
              </Link>
            )}
          </div>
        </div>
      )}

      {reducedHeight ? null : (
        <div className="d-flex justify-content-center mt-1 " style={{paddingBottom:"30px"}}>
          {/* <div className="align-items-center">
            <p className="justify-content-center teller-balance">
              Teller Balance{" "}
            </p>
            {data?.runningBalance?.toString().includes("-") ? (
              <p className="justify-content-center teller-amount-balance">
                NGN{" "}
                {data?.runningBalance
                  ? `(${formatCurrency(Number(data?.runningBalance)).replace(
                      /-/g,
                      ""
                    )})`
                  : ""}
              </p>
            ) : (
              <p className="justify-content-center teller-amount-balance">
                NGN {formatCurrency(Number(data?.runningBalance))}
              </p>
            )}
          </div> */}
        </div>
      )}

      {reducedHeight ? null : (
        <div className="d-flex justify-content-between mt-2">
          <div className="d-flex align-items-end">
            <p className="teller-text-label">Start date: </p>
            <span className="ml-1 teller-text-label">
              {formatDateString(data?.startDate, "dd/MMM/yyyy")}
            </span>
          </div>
          <div className="d-flex align-items-end">
            <p className="teller-text-label">End date: </p>
            <span className="ml-1 teller-text-label">
              {data?.endDate
                ? formatDateString(data?.endDate, "dd/MMM/yyyy")
                : "No end date"}
            </span>
          </div>
          {!showMore ? (
            <AddActionButton
              onClick={(e) => {
                noMore ? e.stopPropagation() : setShowMore(!showMore);
              }}
            >
              <ThreeDots />
            </AddActionButton>
          ) : (
            ""
          )}
        </div>
      )}
    </Container>
  );
};

export default TellerCard;
