import React from "react";
// import DashboardLayout from "../../../layouts/dashboardLayout/index.jsx";
import { ContentWrapper, ContentCard, ActionButton } from "./styles";
// import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const CardListings = [
  {
    icon: require("../../../../assets/images/icons/tax-component.svg").default,
    description: "S3 Amazon Service Configuration",
    route: "external-services/s3",
    title: "S3 Configuration",
  },
  {
    icon: require("../../../../assets/images/icons/sms.svg").default,
    description: "Email Service Configuration",
    route: "external-services/smtp",
    title: "Email Configuration",
  },
  // {
  //   icon: require("../../../../../assets/images/icons/sms.svg").default,
  //   description: "SMS Service Configuration",
  //   route: "external-services/sms",
  // },
  // {
  //   icon: require("../../../../../assets/images/icons/sms.svg").default,
  //   description: "Notification Configuration",
  //   route: "external-services/notification",
  // },
];

const ExternalServices = ({ history }) => {
  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">External Services</h3>
            <p className="sub-title">External Services Configuration</p>
            <p className="font-weight sub-title mt-2">
              <span className="text-info">
                <Link to="/core/manage-utilities">Manage Utilities</Link>
              </span>{" "}
              / External Services
            </p>
          </div>
        </div>
        <ContentWrapper>
          <div className="row">
            {CardListings.map(({ icon, title, description, route }, i) => (
              <div className="col-sm-12 col-md-6 col-lg-6 mb-4" key={i}>
                <ContentCard>
                  <img className="card-img-right" src={icon} alt={title} />
                  <div className="body">
                    <p className="title">{title}</p>
                    <p className="description">{description}</p>
                  </div>
                  <Link to={route}>
                    <ActionButton>
                      <img
                        src={
                          require("../../../../assets/images/icons/arrow-right.svg")
                            .default
                        }
                        alt="submit icon"
                      />
                      Continue
                    </ActionButton>
                  </Link>
                </ContentCard>
              </div>
            ))}
          </div>
        </ContentWrapper>
      </div>
    </>
  );
};

export default ExternalServices;
