import React, { useState, useEffect } from "react";

import config from "../../../../config";
import axiosData from "../../../../services/axiosData";

const GetCustomerImage = ({ id }) => {
  const [gettingImage, setGettingImage] = useState(false);
  const [image, setImage] = useState("");

  const getMainImage = (id) => {
    setGettingImage(true);
    axiosData({
      method: "GET",
      data: id,
      url: `${config.apiGateway.BASE_API_URL}/clients/${id}/images`,
    }).then((data) => {
      setGettingImage(false);
      if (!!data && !!data.data) {
        setImage(data.data);
      }
    });
  };

  useEffect(() => {
    getMainImage(id);
  }, [id]);

  return (
    <>
      {!!image ? (
        <img src={image} alt="" className="customer-imgs mr-3" />
      ) : (
        <img
          src={require("../../../../assets/images/defaultUser.png").default}
          alt=""
          className="customer-imgs mr-3"
        />
      )}
    </>
  );
};

export default GetCustomerImage;
