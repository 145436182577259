import { useEffect, useRef } from "react";

export function useEffectAfterMount(effect, deps) {
  const isMounted = useRef(false);

  useEffect(() => {
    let cleanup = undefined;

    if (isMounted.current) {
      cleanup = effect();
    }

    isMounted.current = true;

    return cleanup;
  }, deps);
}
