import React from "react";
import ContentLoader from "react-content-loader";
import { Table } from "antd";

import {
  Tabs,
  Tab,
  // OverlayTrigger, Tooltip
} from "react-bootstrap";

import { AvatarContainer, ActionButton } from "../styles";

const Loader = () => {
  const infoLoader = () => {
    return (
      <>
        <ContentLoader
          speed={2}
          width={200}
          height={30}
          viewBox="0 0 200 30"
          backgroundColor="#ffffff"
          foregroundColor="#ecebeb"
        >
          <rect x="-30" y="0" rx="0" ry="0" width="150" height="20" />
        </ContentLoader>
      </>
    );
  };

  const columns = [
    { title: infoLoader(), dataIndex: "accNo", key: "accNo" },
    { title: infoLoader(), dataIndex: "accType", key: "accType" },
    { title: infoLoader(), dataIndex: "status", key: "status" },
    { title: infoLoader(), dataIndex: "balance", key: "balance" },
    { title: infoLoader(), dataIndex: "action", key: "action" },
  ];

  let dataObject = {
    accNo: infoLoader(),
    accType: infoLoader(),
    status: infoLoader(),
    balance: infoLoader(),
    action: infoLoader(),
  };

  const data = [dataObject, dataObject, dataObject, dataObject, dataObject];

  const accountLoader = () => {
    return (
      <>
        <div className="container pt-5">
          <div className="row">
            <div className="col-sm-12 col-md-7">
              <h3 className="page-title">
                <ContentLoader
                  speed={2}
                  width={300}
                  height={60}
                  viewBox="0 0 300 60"
                  backgroundColor="#ffffff"
                  foregroundColor="#ecebeb"
                >
                  <rect x="-60" y="0" rx="0" ry="0" width="200" height="50" />
                </ContentLoader>
              </h3>
            </div>
            <div className="col-sm-12 col-md-5">
              <div className="spaceBetweenFlexEnd">
                <div className="">
                  <ContentLoader
                    speed={2}
                    width={300}
                    height={60}
                    viewBox="0 0 300 60"
                    backgroundColor="#ffffff"
                    foregroundColor="#ecebeb"
                  >
                    <rect x="30" y="0" rx="0" ry="0" width="200" height="50" />
                  </ContentLoader>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5">
            <Table columns={columns} dataSource={data} />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-3 col-md-12 border-right">
          {/* Images and Customer details goes here */}
          <div className="row">
            <div className="col-xl-12 col-md-6">
              <AvatarContainer>
                <ContentLoader
                  speed={2}
                  width={400}
                  height={200}
                  viewBox="0 0 400 200"
                  backgroundColor="#ffffff"
                  foregroundColor="#ecebeb"
                >
                  <circle cx="200" cy="100" r="100" />
                </ContentLoader>
              </AvatarContainer>
            </div>

            <div className="col-xl-12 col-md-6 general-font-size">
              <div className="mt-5 mb-5 mr-2 ml-4">
                <div className="row margin-bottom-10">
                  <div className="col-lg-12 col-xl-6 col-md-12 p-0">
                    <h3 style={{ color: "black", fontWeight: "bold" }}>Full Name:</h3>
                  </div>
                  <div className="col-lg-12 col-xl-6 col-md-12 p-0">
                    <p>{infoLoader()}</p>
                  </div>
                </div>
                <div className="row margin-bottom-10">
                  <div className="col-lg-12 col-xl-6 col-md-12 p-0">
                    <h3 style={{ color: "black", fontWeight: "bold" }}>DOB:</h3>
                  </div>
                  <div className="col-lg-12 col-xl-6 col-md-12 p-0">
                    <p>{infoLoader()}</p>
                  </div>
                </div>
                <div className="row margin-bottom-10">
                  <div className="col-lg-12 col-xl-6 col-md-12 p-0">
                    <h3 style={{ color: "black", fontWeight: "bold" }}>Mobile Number:</h3>
                  </div>
                  <div className="col-lg-12 col-xl-6 col-md-12 p-0">
                    <p>{infoLoader()}</p>
                  </div>
                </div>
                <div className="row margin-bottom-10">
                  <div className="col-lg-12 col-xl-6 col-md-12 p-0">
                    <h3 style={{ color: "black", fontWeight: "bold" }}>Gender:</h3>
                  </div>
                  <div className="col-lg-12 col-xl-6 col-md-12 p-0">
                    <p>{infoLoader()}</p>
                  </div>
                </div>
                <div className="row margin-bottom-10">
                  <div className="col-lg-12 col-xl-6 col-md-12 p-0">
                    <h3 style={{ color: "black", fontWeight: "bold" }}>External Id:</h3>
                  </div>
                  <div className="col-lg-12 col-xl-6 col-md-12 p-0">
                    <p>{infoLoader()}</p>
                  </div>
                </div>
                <div className="row margin-bottom-10">
                  <div className="col-lg-12 col-xl-6 col-md-12 p-0">
                    <h3 style={{ color: "black", fontWeight: "bold" }}>Activation Date:</h3>
                  </div>
                  <div className="col-lg-12 col-xl-6 col-md-12 p-0">
                    <p>{infoLoader()}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-9 col-md-12">
          {/* Tabs goes here */}
          <Tabs
            className="infoNav"
            defaultActiveKey="account"
            // transition={false}
            id="noanim-tab-example"
          >
            <Tab eventKey="account" title="Account">
              {accountLoader()}
            </Tab>{" "}
            <Tab eventKey="fixedDeposit" title="Fixed Deposit">
              {accountLoader()}
            </Tab>
            <Tab eventKey="loan" title="Loan">
              {accountLoader()}
            </Tab>
            <Tab eventKey="document" title="Document">
              {accountLoader()}
            </Tab>
            <Tab eventKey="profile" title="Profile">
              {accountLoader()}
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default Loader;
