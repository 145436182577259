import React, { useState, useEffect } from "react";
import { Input, Select, FormControl, Textarea } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import CurrencyFormat from "react-currency-format";
import { useDispatch, useSelector } from "react-redux";
import ModalContainer from "../../components/ModalContainer";
import AddPaymentDetailsModal from "./AddPaymentDetailsModal";
import { FormElements, ActionButton } from "../styles";
import { ContentWrapper, NewContentWrapper, CardDetails, DebitCreditCard } from "./styles";
import Toast from "../../../../util/Toast";
import {
  getCurriencies,
  getOffices,
  getPaymentTypes,
  getAllGlAccounts,
  addJournalEntry,
} from "../../../../actions/accountingActions";
import { textToUpperCase } from "../../../../util/Tools";
import { formatDate } from "../../../../util/FormatDate";
import CheckPermission from "../../../../util/CheckPermission";
import { Select as AntSelect } from "antd";

const AddJournalEntries = ({ history }) => {
  const dispatch = useDispatch();
  const { Option } = AntSelect;

  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  const defaultCredit = { glAccountId: "", amount: "" };
  const defaultDebit = { glAccountId: "", amount: "", comments: "" };
  const [office, setOffice] = useState("");
  const [currency, setCurrency] = useState("");
  const [numberOfDebit, setNumberOfDebit] = useState([defaultDebit]);
  const [numberOfCredit, setNumberOfCredit] = useState([defaultCredit]);
  const [refNumber, setRefNumber] = useState("");
  const [transactionDate, setTrasactionDate] = useState("");
  const [comments, setComment] = useState("");
  const [paymentDetailsEntered, setPaymentDetailsEntered] = useState({});

  const [modalState, toggleModal] = useState(false);
  const [modalContentName, setModalContentName] = useState("");

  useEffect(() => {
    dispatch(getCurriencies());
    dispatch(getOffices());
    dispatch(getPaymentTypes());
    dispatch(getAllGlAccounts());
  }, [dispatch]);

  const addToArray = () => {
    setNumberOfDebit([...numberOfDebit, defaultDebit]);
  };
  const handleDebitToChange = (position, timeStamp) => {
    let hhh = [...numberOfDebit];
    hhh[Number(position)].glAccountId = timeStamp;
    setNumberOfDebit(hhh);
  };
  const handleDebitAmountToChange = (position, timeStamp) => {
    let hhh = [...numberOfDebit];
    hhh[Number(position)].amount = timeStamp;
    setNumberOfDebit(hhh);
  };
  const handleDebitCommentToChange = (position, timeStamp) => {
    let hhh = [...numberOfDebit];
    hhh[Number(position)].comments = timeStamp;
    setNumberOfDebit(hhh);
  };

  const removeDebit = (position) => {
    let noo = [];
    numberOfDebit.forEach((element, index) => {
      if (position !== index) {
        noo.push(element);
      }
    });
    setNumberOfDebit(noo);
  };

  const addToCreditArray = () => {
    setNumberOfCredit([...numberOfCredit, defaultCredit]);
  };
  const handleCreditToChange = (position, timeStamp) => {
    let hhh = [...numberOfCredit];
    hhh[Number(position)].glAccountId = timeStamp;
    setNumberOfCredit(hhh);
  };
  const handleCreditAmountToChange = (position, timeStamp) => {
    let hhh = [...numberOfCredit];
    hhh[Number(position)].amount = timeStamp;
    setNumberOfCredit(hhh);
  };
  const handleCreditCommentToChange = (position, timeStamp) => {
    let hhh = [...numberOfCredit];
    hhh[Number(position)].comments = timeStamp;
    setNumberOfCredit(hhh);
  };

  const removeCredit = (position) => {
    let noo = [];
    numberOfCredit.forEach((element, index) => {
      if (position !== index) {
        noo.push(element);
      }
    });
    setNumberOfCredit(noo);
  };

  const accountingData = useSelector((state) => state.accountingData);

  let { currencies, offices, paymentTypes, glAccounts } = accountingData || {};

  const currentContent = (e) => {
    const val = e.currentTarget.id.trim();

    if (val) {
      toggleModal(true);
      setModalContentName(val);
    }
  };

  const handleModalClose = () => toggleModal(false);

  const displayModalContent = () => {
    switch (modalContentName) {
      case "addPaymentDetails":
        return (
          <AddPaymentDetailsModal
            paymentTypes={paymentTypes}
            onHide={handleModalClose}
            onSubmit={(data) => setPaymentDetailsEntered(data)}
          />
        );
      default:
        return;
    }
  };

  let newDate = new Date(transactionDate);

  let {
    paymentType,
    accountNumber,
    routingCode,
    receiptNumber,
    bankNumber,
    chequeNumber,
  } = paymentDetailsEntered;
  let journalEntry = {
    officeId: office,
    transactionDate: formatDate(newDate, true),
    comments,
    locale: "en",
    currencyCode: currency,
    dateFormat: "dd MMMM yyyy",
    credits: numberOfCredit,
    debits: numberOfDebit,
    paymentTypeId: paymentType,
    accountNumber,
    checkNumber: chequeNumber,
    routingCode,
    receiptNumber,
    bankNumber,
    referenceNumber: refNumber,
  };

  const handleAddJournalEntry = (e) => {
    e.preventDefault();
    if (!office || office.length < 1) {
      Toast({ text: "Please select an Office" });
    } else if (!currency || currency.length < 1) {
      Toast({ text: "Please select a Currency" });
    } else if (!transactionDate) {
      Toast({ text: "Please select a transaction Date" });
    } else {
      addJournalEntry(journalEntry, dispatch, history);
    }
  };

  return (
    <>
      <ModalContainer show={modalState} dialogClassName="document_infoModal">
        {modalState && displayModalContent()}
      </ModalContainer>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12  col-md-12 col-xl-6">
            <h3 className="page-title">Add Journal Entry</h3>
            <p className="sub-title">
              Manual add journal entry transaction recorded in a journal
            </p>
          </div>
        </div>
        {CheckPermission("CREATE_ACCOUNT_JOURNALENTRY", permissions) && (
          <NewContentWrapper>
            <FormElements>
              <div className="row h-100">
                <div className="col-sm-12  col-md-12 col-xl-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Office*</label>
                    <Select
                      placeholder="Select Office"
                      size="md"
                      name="office"
                      value={office}
                      onChange={(e) => setOffice(e.target.value)}
                    >
                      {!!offices
                        ? offices.map(
                            ({ externalId, id, name, nameDecorated }) => {
                              return (
                                <option value={id} key={id}>
                                  {name}
                                </option>
                              );
                            }
                          )
                        : null}
                    </Select>
                  </div>
                </div>
                <div className="col-sm-12  col-md-12 col-xl-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label" style={{marginLeft : '70px'}}>Currency*</label>
                    <Select
                      placeholder="Select Currency"
                      size="md"
                      name="currency"
                      value={currency}
                      onChange={(e) => setCurrency(e.target.value)}
                    >
                      {!!currencies ? (
                        currencies.map(({ code, displayLabel }) => {
                          return (
                            <option value={code} key={code}>
                              {displayLabel}
                            </option>
                          );
                        })
                      ) : (
                        <option>Loading</option>
                      )}
                    </Select>
                  </div>
                </div>
              </div>
            </FormElements>
            <CardDetails>
              {/* For Debit */}
              <div className="dflex">
                <div className="pt-3">
                  <p className="sub-title">
                    <span className="text-info mr-1 linkSavings ">
                      Account to debit
                    </span>
                  </p>
                </div>
                <div className="pt-4">
                  <hr />
                </div>
                <div className="mb-3">
                  <div className="button-right ml-3">
                    <ActionButton
                      color="#2C1DFF"
                      className="float-right"
                      style={{ margin: "0px 0px" }}
                      onClick={() => addToArray()}
                    >
                      <img
                        src={
                          require("../../../../assets/images/icons/add.svg")
                            .default
                        }
                        alt="add icon"
                      />
                      Add Debit details
                    </ActionButton>
                  </div>
                </div>
              </div>
              <FormElements>
                {numberOfDebit.map((debit, index) => {
                  return (
                    <DebitCreditCard
                      className="p-relative  row h-100 mb-4"
                      key={index}
                    >
                      <div className="col-sm-12  col-md-12 col-xl-6">
                        <div className="form-group d-flex justify-content-between align-items-center" >
                          <label className="text-label">Debit*</label>
                          <AntSelect
                            optionFilterProp="children"
                            showSearch
                            name="debit"
                            placeholder="Select Debit"
                            onSelect={(value) => {
                              handleDebitToChange(index, value);
                            }}
                            style={{width: '60%'}}
                          >
                            {glAccounts?.map(({ glCode, id, name }) => (
                              <Option key={id}>
                                {textToUpperCase(name)}({glCode})
                              </Option>
                            ))}
                          </AntSelect>
                        </div>
                      </div>
                      <div className="col-sm-12  col-md-12 col-xl-6">
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label" style={{marginLeft : '70px'}}>Amount*</label>
                          <CurrencyFormat
                            value={numberOfDebit[index].amount}
                            thousandSeparator={true}
                            placeholder="Enter amount"
                            className="amount-input "
                            style={{
                              width: "60%",
                            }}
                            onValueChange={(values) => {
                              const { value } = values;
                              handleDebitAmountToChange(index, value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12  col-md-12 col-xl-6">
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label">Comment</label>
                          <Textarea
                            size="md"
                            style={{ width: "60%", marginTop: "20px", fontSize: "12px" }}
                            resize={"none"}
                            value={numberOfDebit[index].comments}
                            placeholder="Enter your comment here"
                            onChange={(e) =>
                              handleDebitCommentToChange(index, e.target.value)
                            }
                          />
                        </div>
                      </div>
                      {numberOfDebit.length > 1 ? (
                        <div
                          className="new-remove-icon"
                          onClick={() => removeDebit(index)}
                        >
                          <img
                            src={
                              require("../../../../assets/images/icons/cancel.svg")
                                .default
                            }
                            className="icon-img"
                            alt="cancel icon"
                          />
                        </div>
                      ) : null}
                    </DebitCreditCard>
                  );
                })}
              </FormElements>{" "}
              {/* For Credit */}
              <div className="dflex">
                <div className="pt-3">
                  <p className="sub-title">
                    <span className="text-info mr-1 linkSavings">
                      Account to credit
                    </span>
                  </p>
                </div>
                <div className="pt-4">
                  <hr />
                </div>
                <div className="mb-3">
                  <div className="button-right ml-3">
                    <ActionButton
                      color="#2C1DFF"
                      className="float-right"
                      style={{ margin: "0px 0px" }}
                      onClick={() => addToCreditArray()}
                    >
                      <img
                        src={
                          require("../../../../assets/images/icons/add.svg")
                            .default
                        }
                        alt="add icon"
                      />
                      Add Credit details
                    </ActionButton>
                  </div>
                </div>
              </div>
              <FormElements>
                {numberOfCredit.map((credit, index) => {
                  return (
                    <DebitCreditCard
                      className="p-relative  row h-100 mb-4"
                      key={index}
                    >
                      <div className="col-sm-12  col-md-12 col-xl-6">
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label">Credit*</label>
                          <AntSelect
                            optionFilterProp="children"
                            showSearch
                            name="credit"
                            placeholder="Select Credit"
                            onSelect={(value) => {
                              handleCreditToChange(index, value);
                            }}
                            style={{width: '60%'}}

                          >
                            {glAccounts?.map(({ glCode, id, name }) => (
                              <Option key={id}>
                                {textToUpperCase(name)}({glCode})
                              </Option>
                            ))}
                          </AntSelect>
                        </div>
                      </div>
                      <div className="col-sm-12  col-md-12 col-xl-6">
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label" style={{marginLeft : '70px'}}>Amount*</label>
                          <CurrencyFormat
                            placeholder="Enter amount"
                            value={numberOfCredit[index].amount}
                            thousandSeparator={true}
                            className="amount-input "
                            style={{
                              width: "60%",
                            }}
                            onValueChange={(values) => {
                              const { value } = values;
                              handleCreditAmountToChange(index, value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12  col-md-12 col-xl-6">
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label">Comment</label>
                          <Textarea
                            size="md"
                            style={{ width: "60%", marginTop: "20px", fontSize: "12px" }}
                            resize={"none"}
                            value={numberOfCredit[index].comments}
                            placeholder="Enter your comment here"
                            onChange={(e) =>
                              handleCreditCommentToChange(index, e.target.value)
                            }
                          />
                        </div>
                      </div>
                      
                      {numberOfCredit.length > 1 ? (
                        <div
                          className="new-remove-icon"
                          onClick={() => removeCredit(index)}
                        >
                          <img
                            src={
                              require("../../../../assets/images/icons/cancel.svg")
                                .default
                            }
                            className="icon-img"
                            alt="cancel icon"
                          />
                        </div>
                      ) : null}
                    </DebitCreditCard>
                  );
                })}
              </FormElements>
            </CardDetails>
            <br />
            <hr />
            <br />
            <FormElements>
              <div className="row h-100">
                <div className="col-sm-12  col-md-12 col-xl-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Reference number</label>
                    <Input
                      style={{ width: "60%" }}
                      size="md"
                      name="refNumber"
                      value={refNumber}
                      onChange={(e) => setRefNumber(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-xl-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label" style={{marginLeft : '70px'}}>Transaction date*</label>
                    <FormControl style={{ width: "60%" }}>
                    <div className="datepicker-100-width">

                      <DatePicker
                        id="published-date"
                        selected={transactionDate}
                        onChange={(date) => setTrasactionDate(date)}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="dd/mmm/yyyy"
                      />
                      </div>
                    </FormControl>
                  </div>
                </div>
              </div>
            </FormElements>

            <div className="row">
              {/* {!!paymentDetailsEntered ? <div className="">
            {
              const {accaccountNumber, paymentType, routingCode} = paymentDetailsEntered;
            }
            <p>{paymentDetailsEntered.accaccountNumber}</p>
            </div> : null} */}
              {/* <div className="button-right">
                <ActionButton
                  color="#2C1DFF"
                  className="float-right"
                  style={{ margin: "0px 0px" }}
                  onClick={currentContent}
                  id="addPaymentDetails"
                >
                  <img
                    src={
                      require("../../../../assets/images/icons/add.svg").default
                    }
                    alt="add icon"
                  />
                  Add Payment details
                </ActionButton>
              </div> */}
            </div>
            <div className="row">
              <div className="col-md-6">
                <FormElements>
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Description</label>
                    <Textarea
                      size="md"
                      style={{ width: "80%", marginTop: "10px", fontSize: "12px" }}
                      resize={"none"}
                      value={comments}
                      onChange={(e) => setComment(e.target.value)}
                    />
                  </div>
                </FormElements>
              </div>
            </div>
            <div className="row">
              <div className="button-right">
                {/* <div className="">
                  <ActionButton
                    color="#2C1DFF"
                    className="float-right"
                    style={{ margin: "0px 0px" }}
                    onClick={() => history.push("/core/accounting")}
                  >
                    <img
                      src={
                        require("../../../../assets/images/icons/cancel.svg")
                          .default
                      }
                      alt="add icon"
                    />
                    Cancel
                  </ActionButton>
                </div> */}
                <div className="">
                  <ActionButton
                    color="#2C1DFF"
                    className="float-right"
                    style={{ margin: "0px 0px" }}
                    onClick={(e) => handleAddJournalEntry(e)}
                  >
                    <img
                      src={
                        require("../../../../assets/images/icons/check-mark.svg")
                          .default
                      }
                      alt="mark icon"
                    />
                    Submit
                  </ActionButton>
                </div>
              </div>
            </div>
          </NewContentWrapper>
        )}
      </div>
    </>
  );
};

export default AddJournalEntries;
