import React, { useState } from "react";
import { Input, Select, FormControl } from "@chakra-ui/react";
import { ActionButton, FormElements, FormClass } from "../styles";
import { Modal, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useDispatch } from "react-redux";
import { formatDate } from "../../../../../../util/FormatDate";
import {
  updateHolidayInfo,
  getAllHolidays,
} from "../../../../../../actions/configurationActions";
import Toast from "../../../../../../util/Toast";

const EditHoliday = ({ onHide, holidayTemplate, offices, data, officeIdd }) => {
  const [editHolidayInfo, setNewHolidayInfo] = useState({
    name: data ? data.name : "",
    repayment: data ? data.reschedulingType : "",
    oficeId: "",
  });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { name, repayment } = editHolidayInfo;
  const editHolidayHandler = (e) =>
    setNewHolidayInfo({ ...editHolidayInfo, [e.target.name]: e.target.value });
  const [startDate, setStartDate] = useState(
    new Date(data.fromDate) || new Date()
  );
  const [endDate, setEndDate] = useState(new Date(data.toDate) || new Date());

  const [scheduledTo, setScheduledDate] = useState(
    new Date(data.repaymentsRescheduledTo) || new Date()
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (name && repayment && scheduledTo) {
      if (endDate >= startDate) {
        setLoading(true);
        const editedData = {
          dateFormat: "dd MMMM yyyy",
          fromDate: formatDate(startDate, true),
          locale: "en",
          name,
          // offices: [{ officeId: Number(officeId) }],
          repaymentsRescheduledTo: formatDate(scheduledTo, true),
          reschedulingType: Number(repayment),
          toDate: formatDate(endDate, true),
        };
        const func = () => {
          dispatch(getAllHolidays(Number(officeIdd)));
          onHide();
        };
        await dispatch(updateHolidayInfo(editedData, data.id, func));
        setLoading(false);
      } else {
        Toast({
          text: "End date must be greater than start date",
          icon: "error",
          timer: 3000,
        });
      }
    } else {
      Toast({
        text: "Please fill the required fields",
        icon: "error",
        timer: 2000,
      });
    }
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>Edit Holiday</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <FormElements style={{ width: "100%" }}>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Name*</label>
              <Input
                style={{ width: "60%" }}
                size="md"
                name="name"
                value={name}
                onChange={editHolidayHandler}
                placeholder="Input Holiday Name"
              />
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Start Date*</label>
              <FormControl style={{ width: "60%" }}>
                <DatePicker
                  style={{ width: "100%" }}
                  id="start-date"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="dd/mmm/yyyy"
                />
              </FormControl>
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">End Date*</label>
              <FormControl style={{ width: "60%" }}>
                <DatePicker
                  style={{ width: "100%" }}
                  id="end-date"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="dd/mmm/yyyy"
                />
              </FormControl>
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Repayment Scheduling Type*</label>
              <Select
                placeholder="Select specific date or next repayment  date"
                size="md"
                name="repayment"
                value={repayment}
                onChange={editHolidayHandler}
              >
                {holidayTemplate && holidayTemplate.length > 0
                  ? holidayTemplate.map((hol, i) => (
                      <option value={hol.id} key={i}>
                        {hol.value}
                      </option>
                    ))
                  : null}
              </Select>
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Repayment scheduled to*</label>
              <FormControl style={{ width: "60%" }}>
                <DatePicker
                  style={{ width: "100%" }}
                  id="scheduleTo"
                  selected={scheduledTo}
                  onChange={(date) => setScheduledDate(date)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="dd/mmm/yyyy"
                />
              </FormControl>
            </div>
            {/* <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Applicable Office*</label>
              <Select
                placeholder="Select Office"
                size="md"
                name="officeId"
                value={officeId}
                onChange={editHolidayHandler}
              >
                {offices && offices.length > 0
                  ? offices.map((hol, i) => (
                      <option value={hol.id} key={i}>
                        {hol.name}
                      </option>
                    ))
                  : null}
              </Select>
            </div> */}
          </FormElements>
          <div className="float-right mt-4">
            <ActionButton color="#2C1DFF" onClick={onHide}>
              <img
                src={
                  require("../../../../../../assets/images/icons/cancel.svg")
                    .default
                }
                alt="cancel icon"
              />
              Cancel
            </ActionButton>
            <ActionButton
              onClick={handleSubmit}
              color="#2C1DFF"
              style={{ margin: "0px 0px 0 10px" }}
            >
              {loading ? (
                <Spinner
                  animation="border"
                  color="#fff"
                  className="mr-2 text-white"
                  size="sm"
                />
              ) : (
                <img
                  src={
                    require("../../../../../../assets/images/icons/save.svg")
                      .default
                  }
                  alt="submit icon"
                />
              )}
              Submit
            </ActionButton>
          </div>
        </FormClass>
      </Modal.Body>
    </>
  );
};

export default EditHoliday;
