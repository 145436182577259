import React, { useEffect, useState } from "react";
// import DashboardLayout from "../../../layouts/dashboardLayout/index.jsx";
import { ContentWrapper, ContentCard } from "./styles";
// import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LoadingScreen from "./LoadingScreen";
import CheckPermission from "../../../util/CheckPermission";
const CardListings = [
  // {
  //   icon: require("../../../assets/images/icons/user.svg").default,
  //   title: "Users",
  //   description: "Create and edit customer information",
  //   permission: " READ_USER",
  // },
  {
    icon: require("../../../assets/images/icons/organization.svg").default,
    title: "Utilities",
    description: "Manage groups, employees and business information",
    permission: "",
  },
  // {
  //   icon: require("../../../assets/images/icons/system.svg").default,
  //   title: "System",
  //   description: "Manage profile and account settings",
  // },
  // {
  //   icon: require("../../../assets/images/icons/products.svg").default,
  //   title: "Products",
  //   description: "Loans, savings and charges informations",
  //   permission: "",
  // },
  // {
  //   icon: require("../../../assets/images/icons/template.svg").default,
  //   title: "Template",
  //   description: "Account sample for business organization",
  // },
  // {
  //   icon: require("../../../assets/images/icons/developer.svg").default,
  //   title: "Developer",
  //   description: "Documentations and developer information for your system",
  // },
  // {
  //   icon: require("../../../assets/images/icons/adapters.svg").default,
  //   title: "Adapters",
  //   description:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam",
  // },
];

const ConfigurationView = () => {
  const [loading, setloading] = useState(true);
  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  useEffect(() => {
    setTimeout(() => {
      setloading(false);
    }, 1000);
  }, []);

  return !!loading ? (
    <LoadingScreen />
  ) : (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Configuration</h3>
            <p className="sub-title">
              Custom settings and configurations to help suit your financial
              needs
            </p>
          </div>
        </div>
        <ContentWrapper>
          <div className="row">
            {CardListings.map(
              ({ icon, title, description, permission }, i) =>
                CheckPermission(permission, permissions) && (
                  <div className="col-sm-12 col-md-4 col-lg-3 mb-4" key={i}>
                    <Link
                      to={`configuration/${title === "Utilities"
                        ? "organization"
                        : title.toLowerCase()
                        }`}
                    >
                      <ContentCard>
                        <img
                          className="card-img-right"
                          src={icon}
                          alt={title}
                        />
                        <div className="body">
                          <p className="title">{title}</p>
                          <p className="description">{description}</p>
                        </div>
                      </ContentCard>
                    </Link>
                  </div>
                )
            )}
            
          </div>
        </ContentWrapper>
      </div>
    </>
  );
};

export default ConfigurationView;
