import React, { useState, useEffect, useRef } from "react";
import {
  ContentWrapper,
  ActionButtonsGroup,
  ActionButton,
  EditButton,
  StatusBox,
} from "./styles";
import { InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import ModalContainer from "../../../components/ModalContainer";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllConfigurations,
  filterConfigurations,
  clearFilteredConfigurations,
  enabledConfiguration,
  disableConfiguration,
} from "../../../../../actions/configurationActions";
import Toast from "../../../../../util/Toast";
import TableLoader from "./components/TableLoader";
import EditConfiguration from "./components/EditConfiguration";
import CheckPermission from "../../../../../util/CheckPermission";
const ConfigurationView = () => {
  const [modalState, toggleModal] = useState(false);

  const dispatch = useDispatch();

  const [modalContentName, setModalContentName] = useState("");
  const [selectedConfig, setSelectedConfig] = useState({});

  const handleModalClose = () => toggleModal(false);

  const configuration = useSelector((state) => state.configurationReducer);
  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  const {
    loading,
    configurations: { globalConfiguration = [] },
    filtered,
  } = configuration;
  globalConfiguration.sort((a, b) => (a.name > b.name ? 1 : -1));

  const search = useRef("");
  const onFilterConfiguration = (e) => {
    search.current.value !== ""
      ? dispatch(filterConfigurations(e.target.value))
      : dispatch(clearFilteredConfigurations());
  };
  useEffect(() => {
    dispatch(clearFilteredConfigurations());
    dispatch(getAllConfigurations());
    if (filtered === null) {
      search.current.value = "";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditConfiguration = (id) => {
    setSelectedConfig(globalConfiguration.filter((off) => off.id === id)[0]);
    toggleModal(true);
    setModalContentName("editConfig");
  };

  if (filtered !== null && filtered.length === 0) {
    dispatch(
      Toast({
        text: "No configuration found",
        icon: "error",
        timer: 2000,
      })
    );
  }

  const handleDisable = async (id) => {
    const dataToSubmit = {
      enabled: "false",
    };
    const func = () => {
      dispatch(getAllConfigurations());
    };
    await dispatch(disableConfiguration(dataToSubmit, id, func));
  };

  const handleEnable = async (id) => {
    const dataToSubmit = {
      enabled: "true",
    };
    const func = () => {
      dispatch(getAllConfigurations());
    };
    await dispatch(enabledConfiguration(dataToSubmit, id, func));
  };

  //Table configuration

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Enabled?", dataIndex: "enabled", key: "enabled" },
    { title: "", dataIndex: "able", key: "able" },
    // { title: "Value", dataIndex: "value", key: "value" },
    // { title: "", dataIndex: "edit", key: "edit" },
  ];

  const dataSource = [];
  const filteredConfiguration = [];

  if (filtered !== null) {
    filtered.forEach((con) => {
      const { id, name, enabled } = con;
      filteredConfiguration.push({
        key: id,
        name,
        enabled: <StatusBox color={enabled === true ? "#1DCB43" : "#FF0000"} />,
        able:
          enabled && CheckPermission("UPDATE_CONFIGURATION", permissions) ? (
            <ActionButton onClick={() => handleDisable(id)} color={"#FF0000"}>
              Disable
            </ActionButton>
          ) : (
            <ActionButton onClick={() => handleEnable(id)} color={"#2C1DFF"}>
              Enable
            </ActionButton>
          ),
        // value,
        // edit: (
        //   <EditButton onClick={() => handleEditConfiguration(id)}>
        //     Edit
        //   </EditButton>
        // ),
      });
    });
  }

  !loading &&
    globalConfiguration?.forEach((con) => {
      const { id, name, enabled, value } = con;
      dataSource.push({
        key: id,
        name,
        enabled: <StatusBox color={enabled === true ? "#1DCB43" : "#FF0000"} />,
        able: CheckPermission("UPDATE_CONFIGURATION", permissions) ? (
          enabled ? (
            <ActionButton onClick={() => handleDisable(id)} color={"#FF0000"}>
              Disable
            </ActionButton>
          ) : (
            <ActionButton onClick={() => handleEnable(id)} color={"#2C1DFF"}>
              Enable
            </ActionButton>
          )
        ) : (
          ""
        ),
        value,
        edit: (
          <EditButton onClick={() => handleEditConfiguration(id)}>
            Edit
          </EditButton>
        ),
      });
    });

  const displayModalContent = () => {
    switch (modalContentName) {
      case "editConfig":
        return (
          <EditConfiguration
            template={globalConfiguration}
            data={selectedConfig}
            onHide={handleModalClose}
          />
        );

      default:
        return;
    }
  };
  return (
    <>
      <ModalContainer show={modalState}>
        {modalState && displayModalContent()}
      </ModalContainer>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Configuration</h3>
            <p className="sub-title">
              Global Configurations And Cache Settings
            </p>
            <p className="font-weight sub-title mt-2">
              <span className="text-info">
                <Link to="/core/configuration">Configuration</Link> /{" "}
                <Link to="/core/configuration/organization">Utilities</Link>{" "}
              </span>{" "}
              / Global Configuration
            </p>
          </div>
          <div className="col-sm-12 col-md-6 px-0">
            <ActionButtonsGroup>
              <InputGroup style={{ maxWidth: "450px" }}>
                <InputLeftElement
                  width="3.1rem"
                  height="50px"
                  pointerEvents="none"
                >
                  <img
                    src={
                      require("../../../../../assets/images/icons/search.svg")
                        .default
                    }
                    style={{ height: "30px" }}
                    alt="Icon"
                  />
                </InputLeftElement>
                <Input
                  style={{
                    background: "#ffffff",
                    height: "50px",
                  }}
                  ref={search}
                  onChange={onFilterConfiguration}
                  type="text"
                  className="mb-3"
                  placeholder="Search by name"
                />
              </InputGroup>
            </ActionButtonsGroup>
          </div>
        </div>
        <ContentWrapper>
          {loading ? (
            <TableLoader />
          ) : (
            CheckPermission("READ_CONFIGURATION", permissions) && (
              <>
                {filtered !== null ? (
                  <Table
                    columns={columns}
                    dataSource={filteredConfiguration}
                    // onRow={(r) => ({
                    //   onClick: () => (history.push(`/core/client/${r.key}`))
                    // })}
                  />
                ) : (
                  <Table
                    columns={columns}
                    dataSource={dataSource}
                    pagination={{ defaultPageSize: 8 }}
                    defaultPageSize={8}
                  />
                )}
              </>
            )
          )}
        </ContentWrapper>
      </div>
    </>
  );
};

export default ConfigurationView;
