import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
import { Table } from "antd";
import { ContentWrapper, ActionButton } from "./styles";
import {
  getJournalEntry,
  getAllGlAccounts,
  getOffices,
} from "../../../../actions/accountingActions";
import SearchInputs from "./SearchInputs";
import TableLoader from "./TableLoader";
import { formatDate } from "../../../../util/FormatDate";
import JournalEntryDetail from "./AccountToJournalEntryDetails";

const SearchJournalEntries = ({ history, pagination: externalPagination }) => {
  const dispatch = useDispatch();
  const accountingData = useSelector((state) => state.accountingData);

  let { journalEntries = {}, isLoading } = accountingData;
  let { totalFilteredRecords, pageItems = [] } = journalEntries || {};

  const [office, setOffice] = useState("");
  const [accNameOrCode, setAccNameOrCode] = useState("");
  const [entry, setEntry] = useState("");

  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [userId, setUserId] = useState("");
  const [offset, setOffset] = useState(0);
  const [selectedUser, setSelectedUser] = useState("");

  const [pagination, setPagination] = useState({
    current: externalPagination?.current || 1,
    pageSize: externalPagination?.pageSize || 14,
  });

  const [searchState, setSearchState] = useState(1);
  const [activePagination, setActivePagination] = useState();
  const [myData, setMyData] = useState([]);
  const [currentPagination, setCurrentPagination] = useState(1);

  let searchDayFrom = !!dateFrom ? formatDate(dateFrom, true) : "";
  let searchDayTo = !!dateTo ? formatDate(dateTo, true) : "";

  const [currentView, setCurrentView] = useState(1);
  const [additionalData, setAdditionalData] = useState(null);

  const tableData = [];

  useEffect(() => {
    if (!!pageItems && !!pageItems.length) {
      setSearchState(2);
      // setPagination(pagination);
    }
  }, [pageItems]);

  useEffect(() => {
    if (searchState == 1) {
      setActivePagination(1);
    }
  }, [searchState]);

  // console.log(journalEntries, "journalEntries");

  pageItems.forEach((journalEntry) => {
    const {
      id,
      amount,
      currency,
      officeName,
      createdByUserName,
      glAccountType = {},
      transactionId,
      transactionDate = [],
      entryType = {},
      glAccountName,
      glAccountCode,
    } = journalEntry;

    tableData.push({
      entryId: id,
      office: officeName,
      transactionDate: formatDate(transactionDate),
      transactionId,
      type: glAccountType.value,
      createdBy: createdByUserName,
      account: `${glAccountName} (${glAccountCode})`,
      debit: entryType.value === "DEBIT" ? `${currency.code} ${amount}` : null,
      credit:
        entryType.value === "CREDIT" ? `${currency.code} ${amount}` : null,
    });
  });

  const columns = [
    { title: "Entry ID", dataIndex: "entryId", key: "entryId" },
    { title: "Office", dataIndex: "office", key: "office" },
    {
      title: "Transaction Date",
      dataIndex: "transactionDate",
      key: "transactionDate",
    },
    {
      title: "Transaction ID",
      dataIndex: "transactionId",
      key: "transactionId",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    { title: "Created By", dataIndex: "createdBy", key: "createdBy" },
    {
      title: "Gl Account",
      dataIndex: "account",
      key: "account",
    },
    {
      title: "Debit",
      dataIndex: "debit",
      key: "debit",
    },
    {
      title: "Credit",
      dataIndex: "credit",
      key: "credit",
    },
  ];

  const handleTableChange = (pagination) => {
    setActivePagination(pagination.current);
    if (!!journalEntries && !!journalEntries.length) {
      setPagination({
        current: activePagination,
        pageSize: 14,
        total: journalEntries.totalFilteredRecords,
      });
    } else {
      let myOffSect = pagination.current * 14 - 14;
      dispatch(
        getJournalEntry(
          office,
          accNameOrCode,
          entry,
          searchDayFrom,
          searchDayTo,
          transactionId,
          referenceNumber,
          selectedUser,
          myOffSect
        )
      );
    }
  };

  // useEffect 1
  useEffect(() => {
    if (totalFilteredRecords !== 0 && totalFilteredRecords !== 1) {
      setPagination({
        current: activePagination,
        pageSize: 14,
        total: totalFilteredRecords,
      });
    } else if (pageItems === 0) {
      setPagination(false);
    } else if (!!journalEntries && !!journalEntries.length) {
      setPagination({ defaultPageSize: 14 });
    }
  }, [activePagination, journalEntries, totalFilteredRecords]);

  // use
  // useEffect 2
  useEffect(() => {
    if (!!journalEntries && !!journalEntries.length) {
      setMyData(journalEntries);
    } else if (!!pageItems.length) {
      setMyData(pageItems);
    }
  }, [pageItems, journalEntries]);

  useEffect(() => {
    dispatch(getAllGlAccounts());
    dispatch(getOffices());
  }, []);

  return (
    <>
      {currentView === 1 && (
        <div className="container-fluid">
          <div className="row justify-content-between">
            <div className="col-sm-12 col-md-6 pb-1">
              <h3 className="page-title">Search Journal Entries</h3>
              <p className="sub-title">
                Advances search option for journal entries
              </p>
              {/* <p className="sub-title mt-2">
              <span className="text-info mr-1">
                <Link to="/core/accounting">Accounting</Link>
              </span>{" "}
              / Search Journal Entries
            </p> */}
            </div>
          </div>
          <ContentWrapper>
            {searchState === 1 ? (
              <>
                <SearchInputs
                  setOffice={setOffice}
                  setAccNameOrCode={setAccNameOrCode}
                  setEntry={setEntry}
                  setDateFrom={setDateFrom}
                  setDateTo={setDateTo}
                  setTransactionId={setTransactionId}
                  setReferenceNumber={setReferenceNumber}
                  setOffset={setOffset}
                  office={office}
                  accNameOrCode={accNameOrCode}
                  entry={entry}
                  dateFrom={dateFrom}
                  dateTo={dateTo}
                  transactionId={transactionId}
                  referenceNumber={referenceNumber}
                  offset={offset}
                  setSearchState={setSearchState}
                  userId={userId}
                  setUserId={setUserId}
                  selectedUser={selectedUser}
                  setSelectedUser={setSelectedUser}
                />
              </>
            ) : (
              <div className="p-relative">
                <div className="parametersAbsolute">
                  <ActionButton
                    color="#2C1DFF"
                    className="float-right"
                    style={{ margin: "0px 0px" }}
                    onClick={() => setSearchState(1)}
                  >
                    <img
                      src={
                        require("../../../../assets/images/icons/searchWhite.svg")
                          .default
                      }
                      className="d-inline mr-3"
                      alt="icon"
                    />
                    Parameters
                  </ActionButton>
                </div>
                {isLoading === true && !pageItems.length ? (
                  <TableLoader />
                ) : (
                  <>
                    <Table
                      columns={columns}
                      dataSource={tableData}
                      pagination={{ ...pagination, ...externalPagination }}
                      defaultPageSize={14}
                      onChange={handleTableChange}
                      onRow={(r) => ({
                        onClick: () => {
                          setCurrentView(2);
                          setAdditionalData(r.transactionId);
                          // const nextPage = pagination.current || 1;
                          // history.push({
                          //   pathname: `/core/search-journal-entries/details/${r.transactionId}`,
                          //   search: `?page=${nextPage}`,
                          // });
                          // history.push(
                          //   `/core/search-journal-entries/details/${r.transactionId}`
                          // );
                        },
                      })}
                    />
                    {pagination === false ? (
                      <>
                        <div className="spaceBetweenFlexEnd my-pag">
                          <div
                            onClick={() => {
                              if (currentPagination >= 2) {
                                setCurrentPagination(currentPagination - 1);
                                // handleNextData(currentPagination - 1);
                                handleTableChange(currentPagination - 1);
                              }
                            }}
                            className="my-pagination mr-4"
                          >
                            <span
                              role="img"
                              aria-label="left"
                              class="anticon anticon-left ml-1"
                            >
                              <svg
                                viewBox="64 64 896 896"
                                focusable="false"
                                data-icon="left"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"></path>
                              </svg>
                            </span>
                          </div>
                          <div
                            onClick={() => {
                              setCurrentPagination(currentPagination + 1);
                              handleTableChange(currentPagination + 1);
                            }}
                            className="my-pagination"
                          >
                            <span
                              role="img"
                              aria-label="right"
                              class="anticon anticon-right ml-2"
                            >
                              <svg
                                viewBox="64 64 896 896"
                                focusable="false"
                                data-icon="right"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path>
                              </svg>
                            </span>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
            )}
          </ContentWrapper>
        </div>
      )}
      {currentView === 2 && (
        <JournalEntryDetail
          setCurrentView={setCurrentView}
          additionalData={additionalData}
        />
      )}
    </>
  );
};

export default SearchJournalEntries;
