import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  max-height: min-content;
  width: 100%;
  display: flex;
  margin-top: 10px;
`;

export const Card = styled.div`
  background-color: #fff;
  flex: 1;
  border-radius: 5px;
  min-height: 300px;

  .heading {
    border-bottom: 0.5px solid #969cba26;
  }
  .content {
    padding: 30px 30px;
  }

  h1 {
    font-weight: bold;
    font-size: 16px;
    padding: 20px 30px;
  }
  .button {
    color: #fff;
    /* font-weight: bold; */
    padding: 5px 10px;
    border-radius: 3px;
    background-color: #2c1dff;
    font-size: 16px;
    margin: 15px 30px;
  }
`;
export const FormGroup = styled.div`
  display: grid;
  grid-template-columns: 2fr 4fr !important;
  margin-bottom: 25px;
  align-items: center;
`;

export const Rejected = styled.p`
  background-color: #ff0000;
  color: #fff;
  border-radius: 5px;
  padding: 5px 8px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
`;

export const Approved = styled.p`
  background-color: #00c537;
  color: #fff;
  border-radius: 5px;
  padding: 5px 8px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
`;

export const Heading = styled.div`
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 25px;
  color: #002c79;
  text-transform: capitalize;
`;
export const H3 = styled.h3`
  font-weight: bold;
  font-size: 22px;
  color: #002c79;
`;
export const Grid = styled.div`
  display: flex;

  .first-child {
    min-width: 30%;
  }
`;

export const ActionButton = styled.button`
  background: ${(props) => props.color || "#2C1DFF"} !important;
  border-radius: 5px !important;
  color: #fff !important;
  margin: 0 3px;
  border: none;
  font-family: "AventaMedium";
  display: inline;
  font-size: 14px;
  height: 35px;
  width: 130px;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  position: relative;
  cursor: pointer;
  img {
    height: 14px;
    margin-right: 10px;
    display: inline;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;

export const LinkColor = styled(Link)`
  color: #2c1dff;
`;

export const FormClass = styled.div``;

export const FormElements = styled.div`
  // width: 90%;
  display: block;
  .text-muted {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #969cba;
  }
  .css-42b2qy {
    width: 60%;
    color: #666666;
    & > option {
      padding: 0.5rem 0;
    }
  }
`;
