import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Table } from "antd";
import { ActionButton, IconButton, LineThrough } from "../styles";

import ModalContainer from "../../../components/ModalContainer";
import UndoApproval from "../../CustomerFixedDepositAccount/UndoApproveModal";
import ActivateAccount from "../../CustomerFixedDepositAccount/ActivateModal";
import ApproveAccount from "../../CustomerFixedDepositAccount/ApproveModal";
import { formatCurrency } from "../../../../../util/FormatAmount";
import CheckPermission from "../../../../../util/CheckPermission";
import CustomerFixedDepositAccount from "../../CustomerFixedDepositAccount";

const FixedDepositTab = ({ userData, history, currentFixedId }) => {
  const accountsList = useSelector((state) => state.customerAccounts);

  const [transactionId, setTransId] = useState("");

  const [modalState, toggleModal] = useState(false);

  const [modalContentName, setModalContentName] = useState("");

  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  const [currentView, setCurrentView] = useState(1);
  const [currentId, setCurrentId] = useState();

  const { search } = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    // console.log(queryParams, 'QUERY PARAMAS');
    const currentIdQuery = queryParams.get("currentId");
    const curFixId = currentFixedId;
    if (currentIdQuery) {
      setCurrentView(2);
      setCurrentId(currentIdQuery);
    } else if (curFixId) {
      setCurrentView(2);
      setCurrentId(curFixId);
    }
  }, [currentFixedId]);

  const currentContent = (e, transId) => {
    const val = e.currentTarget.id.trim();
    setTransId(transId);

    if (val) {
      toggleModal(true);
      setModalContentName(val);
    }
  };

  const handleModalClose = () => toggleModal(false);

  const displayModalContent = () => {
    switch (modalContentName) {
      case "undoApproval":
        return (
          <UndoApproval
            id={transactionId}
            history={history}
            onHide={handleModalClose}
          />
        );
      case "activateAccount":
        return (
          <ActivateAccount
            id={transactionId}
            history={history}
            onHide={handleModalClose}
          />
        );
      case "approveAccount":
        return (
          <ApproveAccount
            id={transactionId}
            history={history}
            onHide={handleModalClose}
          />
        );
      default:
        return;
    }
  };

  let fixedDepositAccount = [];

  !!accountsList &&
    !!accountsList.accounts &&
    !!accountsList.accounts.savingsAccounts &&
    !!accountsList.accounts.savingsAccounts.length &&
    accountsList.accounts.savingsAccounts.forEach((account) => {
      let { depositType } = account;
      if (
        !!depositType &&
        depositType.id === 200 &&
        depositType.value === "Fixed Deposit"
      ) {
        let { id, accountNo, productName, status, accountBalance } = account;
        fixedDepositAccount.push({
          key: id,
          accNo: accountNo,
          accType: productName,
          status: (
            <>
              <div>
                {account.status.active ? (
                  <span className="new-text-success">
                    {" "}
                    {(account.status && account.status.value) || ""}
                    <span style={{ fontSize: "12px" }} className="text-danger">
                      {account.subStatus &&
                        account.subStatus.block &&
                        " (Blocked)"}
                      {account.subStatus &&
                        account.subStatus.blockDebit &&
                        " (On PND)"}
                    </span>
                  </span>
                ) : (
                  <span className="text-danger">
                    {/* {(account.status && account.status.value) || ""} */}
                  </span>
                )}
                {account.status.id === 100 ? (
                  <>
                    {" "}
                    <span className="new-text-pending">
                      {" "}
                      {account.status &&
                      account.status.value.toLowerCase() ===
                        "submitted and pending approval"
                        ? "Pending"
                        : (account.status && account.status.value) || ""}
                    </span>{" "}
                  </>
                ) : (
                  ""
                )}
                {account.status.id === 200 ? (
                  <>
                    {" "}
                    <span className="new-text-info">
                      {" "}
                      {(account.status && account.status.value) || ""}
                    </span>{" "}
                  </>
                ) : (
                  ""
                )}
                {account.status.id === 600 ||
                account.status.id === 500 ||
                account.status.id === 700 ||
                account.status.id === 800 ||
                account.status.id === 400 ? (
                  <>
                    <span className="new-text-danger">
                      {" "}
                      {(account.status && account.status.value) || ""}
                    </span>
                  </>
                ) : (
                  ""
                )}
                {/* {account.status.id === 800 ? (
                  <span className="new-text-matured">
                    {" "}
                    {(account.status && account.status.value) || ""}
                  </span>
                ) : (
                  ""
                )} */}
              </div>
            </>
          ),
          balance:
            (account.accountBalance &&
              formatCurrency(account.accountBalance)) ||
            "",
          action: (
            <>
              {account.status.approved === false &&
              account.status.active === false &&
              account.status.closed === false &&
              account.status.prematureClosed === false &&
              account.status.matured === false &&
              CheckPermission("APPROVE_FIXEDDEPOSITACCOUNT", permissions) ? (
                <RenderTooltip text="Approve" id="approve">
                  <IconButton
                    color="#2C1DFF"
                    id="approveAccount"
                    onClick={(e) => {
                      e.stopPropagation();
                      currentContent(e, account.id);
                    }}
                  >
                    <img
                      src={
                        require("../../../../../assets/images/icons/check-mark.svg")
                          .default
                      }
                      alt="approve"
                    />
                  </IconButton>
                </RenderTooltip>
              ) : (
                ""
              )}

              {account.status.active === false &&
              account.status.approved === true &&
              account.status.closed === false &&
              account.status.prematureClosed === false &&
              account.status.matured === false &&
              CheckPermission("ACTIVATE_FIXEDDEPOSITACCOUNT", permissions) ? (
                <RenderTooltip text="Activate" id="activate">
                  <IconButton
                    color="#2C1DFF"
                    id="activateAccount"
                    onClick={(e) => {
                      e.stopPropagation();
                      currentContent(e, account.id);
                    }}
                  >
                    <img
                      src={
                        require("../../../../../assets/images/icons/check-mark.svg")
                          .default
                      }
                      alt="activate"
                    />
                  </IconButton>
                </RenderTooltip>
              ) : (
                ""
              )}
              {account.status.active === false &&
              account.status.approved === true &&
              account.status.closed === false &&
              account.status.prematureClosed === false &&
              account.status.matured === false &&
              CheckPermission(
                "APPROVALUNDO_FIXEDDEPOSITACCOUNT",
                permissions
              ) ? (
                <RenderTooltip text="Undo Approval" id="undoApproval">
                  <IconButton
                    color="#2C1DFF"
                    id="undoApproval"
                    onClick={(e) => {
                      e.stopPropagation();
                      currentContent(e, account.id);
                    }}
                  >
                    <img
                      src={
                        require("../../../../../assets/images/icons/unapproval.svg")
                          .default
                      }
                      alt="undoApproval"
                      className="icon-img"
                    />
                  </IconButton>
                </RenderTooltip>
              ) : (
                ""
              )}
            </>
          ),
        });
      }
    });

  const columns = [
    { title: "Account No", dataIndex: "accNo", key: "accNo" },
    { title: "Account Type", dataIndex: "accType", key: "accType" },
    { title: "Account Status", dataIndex: "status", key: "status" },
    { title: "Account Balance", dataIndex: "balance", key: "balance" },
    { title: "Action", dataIndex: "action", key: "action" },
  ];

  let activeAccount = 0;
  // accountsList.accounts.savingsAccounts.forEach((account) => {
  //   let { status } = account;
  //   if (status.id === 300) {
  //     activeAccount += 1;
  //   }
  // });

  return (
    <>
      <ModalContainer show={modalState}>
        {modalState && displayModalContent()}
      </ModalContainer>
      {currentView === 1 ? (
        <div className="container pt-4">
          <div className="row">
            <div className="col-sm-12 col-md-7">
              <h3 className="page-title">Fixed Deposit Account</h3>

              {/* <div className="d-flex w-100 align-items-center flex-wrap text-muted">
              <p className="m-0">BVN: 22212345671</p>
              <LineThrough width="20px" />
              <p className="m-0">Active accounts: {activeAccount}</p>
            </div> */}
            </div>
            <div className="col-sm-12 col-md-5 ">
              <div className="spaceBetweenFlexEnd">
                <div className="">
                  {CheckPermission(
                    "CREATE_FIXEDDEPOSITACCOUNT",
                    permissions
                  ) && (
                    <Link
                      to={
                        userData &&
                        `/core/fixed-deposit-application/${userData.id}`
                      }
                    >
                      <ActionButton color="#2C1DFF">
                        <img
                          src={
                            require("../../../../../assets/images/icons/activate.svg")
                              .default
                          }
                          className="mr-2"
                          alt="add"
                        />{" "}
                        New Fixed Account
                      </ActionButton>
                    </Link>
                  )}
                </div>
                {/* <ActionButton color="#2C1DFF" className=" px-3">
              <img
                src={
                  require("../../../../../assets/images/icons/transfer.svg")
                    .default
                }
                className="mr-2"
                style={{ height: "18px" }}
                alt="add"
              />{" "}
              Update Default Account
            </ActionButton> */}
              </div>
            </div>
          </div>
          {CheckPermission("READ_FIXEDDEPOSITACCOUNT", permissions) && (
            <div className="mt-5">
              <Table
                style={{
                  overflow: "hidden",
                  overflowX: "scroll",
                }}
                columns={columns}
                dataSource={fixedDepositAccount}
                pagination={{ defaultPageSize: 5 }}
                defaultPageSize={5}
                onRow={(r) => ({
                  onClick: () => {
                    setCurrentView(2);
                    setCurrentId(r.key);
                  },
                  // history.push(`/core/client/fixed-deposit-account/${r.key}`),
                })}
              />
            </div>
          )}
        </div>
      ) : (
        <CustomerFixedDepositAccount
          currentId={currentId}
          history={history}
          setCurrentView={setCurrentView}
        />
      )}
    </>
  );
};

const RenderTooltip = ({ text, id, children }) => (
  <>
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={`tooltip-${id}`}>{text}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  </>
);

export default FixedDepositTab;
