import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getFixedDepositAccountDetails,
  getFixedDepositAccountTemplates,
  updateFixedDepositAccount,
} from "../../../../../../actions/fixedDeposit";
import { Link } from "react-router-dom";
import BackSvg from "../../../../../../assets/images/icons/back.svg";
import {
  ActionButton,
  ContentWrapper,
  FormClass,
  FormElements,
} from "../styles";
import { Checkbox, FormControl, Input, Select } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import CurrencyFormat from "react-currency-format";
import { LineThrough } from "../../styles";
import { getSavingsAccountTemplate } from "../../../../../../actions/SavingsAndLoanActions";
import { getSelectedFixedDepositProductData } from "../../../../../../actions/utilityActions";
import EditFixedDepositCharges from "./EditFixedDepositCharges";
import { formatDate } from "../../../../../../util/FormatDate";
import EditFixedInterestRate from "./EditFixedInterestRate";
import { SET_LOADING_STATE } from "../../../../../../constants/utilityConstants";

const EditFixedDepositAccount = ({ match, history, id, currentTab }) => {
  const dispatch = useDispatch();

  const fixedDepositData = useSelector((state) => state.fixedDepositData);

  let { loading, fixedDepositAccountDetails } = fixedDepositData || {};
  const savingsAndLoan = useSelector((state) => state.savingsAndLoan);
  // const isLoading = useSelector((state) => state.isLoading.isLoading);

  // let { loanAccountDetails } = savingsAndLoan || {};
  let { clientId, clientName } = fixedDepositAccountDetails || {};

  const { productData } = useSelector(
    (state) => state.selectedFixedDepositData
  );

  let { savingsAccountDetails = {} } = savingsAndLoan || {};

  const [productId, setProductId] = useState("");
  const [goToFixedTab, setGoToFixedTab] = useState("fromFixed");

  const fixedDepositAccountTemplate = useSelector(
    (state) => state.fixedDepositAccountTemplate
  );

  const [submittedDate, setSubmittedDate] = useState(new Date());
  const [depositAmount, setDepositAmount] = useState("");
  const [charges, setCharges] = useState([]);

  const [validFrom, handleValidFrom] = useState();
  const [endDate, handleEndDate] = useState();

  const [processing, setLoading] = useState(false);

  let defaultData = {
    amountRangeFrom: "",
    amountRangeTo: "",
    annualInterestRate: "",
    description: "",
    fromPeriod: "",
    incentives: [],
    locale: "en",
    periodType: "",
    toPeriod: "",
  };

  const [interests, setInterests] = useState([]);
  const [currentChart, setCurrentChart] = useState([]);

  const [mindepositTerm, setMindepositTerm] = useState("");

  const [chartSlabs, setChartSlabs] = useState([]);
  let wales = [];

  useEffect(() => {
    // const nominal = newFixedDepositAccount.nominalAnnualInterest;
    if (currentChart && currentChart.length) {
      currentChart &&
        currentChart.length &&
        currentChart.forEach((interest) => {
          let {
            incentives: myIncentives = [],
            periodType: myPeriodType,
            fromPeriod,
            toPeriod,
            description,
            annualInterestRate,
            amountRangeFrom,
            amountRangeTo,
            id,
          } = interest || {};

          let newIncentives = [];

          myIncentives.forEach((incentive) => {
            let {
              amount,
              attributeValue,
              attributeName,
              conditionType,
              incentiveType,
            } = incentive || {};

            newIncentives.push({
              amount,
              attributeName: `${attributeName.id}`,
              attributeValue,
              conditionType: `${conditionType.id}`,
              entityType: "2",
              incentiveType: `${incentiveType.id}`,
            });
          });

          wales.push({
            amountRangeFrom,
            amountRangeTo,
            toPeriod,
            periodType:
              !!myPeriodType && !!myPeriodType.id ? myPeriodType.id : 0,
            fromPeriod,
            description,
            annualInterestRate,
            // annualInterestRate: newFixedDepositAccount?.nominalAnnualInterest,
            incentives: newIncentives,
            id,
            local: "en",
          });
        });
    } else {
      setInterests([]);
    }
  }, []);

  useEffect(() => {
    if (wales && wales.length) {
      setInterests(wales);
    }
  }, []);

  useEffect(() => {
    dispatch(getFixedDepositAccountDetails(match.params.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match?.params?.id]);

  // useEffect(() => {
  //   dispatch(getFixedDepositAccountTemplates(match.params.id, ""));
  // }, [dispatch, match.params.id]);

  useEffect(() => {
    if (fixedDepositAccountDetails?.depositProductId && clientId) {
      dispatch(getSelectedFixedDepositProductData(clientId, fixedDepositAccountDetails?.depositProductId));
    }
  }, [clientId, fixedDepositAccountDetails?.depositProductId]);

  let { fixedDepositAccountCreateTemplate = {} } =
    fixedDepositAccountTemplate || {};

  let {
    productOptions = [],
    fieldOfficerOptions = [],
    interestCompoundingPeriodTypeOptions = [],
    interestPostingPeriodTypeOptions = [],
    interestCalculationTypeOptions = [],
    interestCalculationDaysInYearTypeOptions = [],
    periodFrequencyTypeOptions = [],
    lockinPeriodFrequencyTypeOptions = [],
    preClosurePenalInterestOnTypeOptions = [],
    savingsAccounts = [],
    currency = {},
    taxGroup = {},
  } = productData || {};

  // console.log(
  //   fixedDepositAccountCreateTemplate,
  //   "fixedDepositAccountCreateTemplate"
  // );

  useEffect(() => {
    dispatch({ type: SET_LOADING_STATE, payload: loading });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (productData) {
      setCharges(!!productData?.charges ? productData?.charges : []);
      setNewFixedDepositAccount((prevData) => {
        return {
          ...prevData,
          depositProductId: productData?.depositProductId,
          clientId: productId ? productData?.clientId : "",
          interestCalculationPeriodSelect:
            productData?.interestCalculationPeriodType?.id === 0
              ? "0"
              : productData?.interestCalculationPeriodType?.id,
          nominalAnnualInterest: productData?.nominalAnnualInterestRate,
          interestCompoundingPeriod:
            productData?.interestCompoundingPeriodType?.id,
          interestPostingPeriod: productData?.interestPostingPeriodType?.id,
          interestCalculated: productData?.interestCalculationType?.id,
        };
      });
    }
  }, [productData, clientId]);

  // useEffect(() => {
  //   if (productData) {
  //     setCurrentChart(
  //       productData &&
  //         productData.accountChart &&
  //         productData.accountChart.chartSlabs &&
  //         productData.accountChart.chartSlabs.length &&
  //         productData.accountChart.chartSlabs
  //     );

  //     if (productData?.accountChart?.endDate) {
  //       handleEndDate(
  //         productData && !!productData.accountChart
  //           ? new Date(productData?.accountChart?.endDate)
  //           : ""
  //       );
  //     }
  //     handleValidFrom(
  //       productData && !!productData.accountChart
  //         ? new Date(productData.accountChart.fromDate)
  //         : ""
  //     );

  //     setChartSlabs(
  //       productData && !!productData.accountChart
  //         ? productData.accountChart.chartSlabs
  //         : ""
  //     );
  //   }
  // }, [productData]);

  const [newFixedDepositAccount, setNewFixedDepositAccount] = useState({
    externalId: "",
    fieldOfficerId: "",
    productId: "",
    dateSubmitted: "",

    // Terms
    DecemalPlace: "",
    currency: "",
    multipleCurrency: "",
    nominalAnnualInterest: "",
    interestCompoundingPeriod: "",
    interestPostingPeriod: " ",
    interestCalculated: "",
    daysInYear: "",
    minimumOpeningBalance: "",
    lockinPeriodFreq: "",
    lockInPeriodFreqSelect: "",
    applyWithdrawalFee: false,
    enforceMinimumBalance: false,
    minumumBalance: "",
    isOverdraft: false,
    overdraftLimit: "",
    transferToSavings: "",
    maturityInstruction: "",
    linkedAccountId: "",
    depositPeriod: "",
    depositPeriodFrequencyId: "",
    // minDepositTerm: "",
    minDepositTermTypeId: "",
    preClosurePenalInterest: "",
    preClosurePenalInterestOnTypeId: "",

    preClosurePenalApplicable: false,
    transferInterestToSavings: false,
    withHoldTax: false,
    transferToSavingsId: false,
  });

  const handleProductType = (e) => {
    setProductId(e.target.value);
    setNewFixedDepositAccount({
      ...newFixedDepositAccount,
      [e.target.name]: e.target.value,
    });
  };

  const editFixedDepositAccountHandler = (e) => {
    setNewFixedDepositAccount({
      ...newFixedDepositAccount,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (fixedDepositAccountDetails) {
      // console.log(fixedDepositAccountDetails, "fixedDepositAccountDetails");
      setDepositAmount(
        fixedDepositAccountDetails
          ? fixedDepositAccountDetails.depositAmount
          : ""
      );
      // setMinumumBalance(
      //   fixedDepositAccountDetails ? fixedDepositAccountDetails.minRequiredBalance : ''
      // );
      // setOverdraftLimit(fixedDepositAccountDetails ? fixedDepositAccountDetails.overdraftLimit : '');
      // setMinBalanceForInterestCalculation(
      //   fixedDepositAccountDetails ? fixedDepositAccountDetails.minOverdraftForInterestCalculation : ''
      // );
      // setProductId(
      //   fixedDepositAccountDetails
      //     ? fixedDepositAccountDetails.depositProductId
      //     : ""
      // );
      setMindepositTerm(
        fixedDepositAccountDetails
          ? fixedDepositAccountDetails.minDepositTerm
          : ""
      );
      setSubmittedDate(
        fixedDepositAccountDetails.timeline?.submittedOnDate
          ? new Date(fixedDepositAccountDetails.timeline?.submittedOnDate)
          : new Date()
      );

      setNewFixedDepositAccount({
        fieldOfficerId: fixedDepositAccountDetails
          ? fixedDepositAccountDetails.fieldOfficerId
          : "",
        productId: fixedDepositAccountDetails
          ? fixedDepositAccountDetails.depositProductId
            ? fixedDepositAccountDetails.depositProductId
            : ""
          : "",
        daysInYear: fixedDepositAccountDetails
          ? fixedDepositAccountDetails.interestCalculationDaysInYearType
            ? fixedDepositAccountDetails.interestCalculationDaysInYearType.id
            : ""
          : "",
        interestCompoundingPeriod: fixedDepositAccountDetails
          ? fixedDepositAccountDetails.interestCompoundingPeriodType
            ? fixedDepositAccountDetails.interestCompoundingPeriodType.id
            : ""
          : "",

        interestPostingPeriod: fixedDepositAccountDetails
          ? fixedDepositAccountDetails.interestPostingPeriodType
            ? fixedDepositAccountDetails.interestPostingPeriodType.id
            : ""
          : "",

        interestCalculated: fixedDepositAccountDetails
          ? fixedDepositAccountDetails.interestCalculationType
            ? fixedDepositAccountDetails.interestCalculationType.id
            : ""
          : "",
        // depositAmount: fixedDepositAccountDetails
        //   ? fixedDepositAccountDetails.depositAmount
        //     ? fixedDepositAccountDetails.depositAmount
        //     : ""
        //   : "",
        depositPeriod: fixedDepositAccountDetails
          ? fixedDepositAccountDetails.depositPeriod
            ? fixedDepositAccountDetails.depositPeriod
            : ""
          : "",
        depositPeriodFrequencyId: fixedDepositAccountDetails
          ? fixedDepositAccountDetails.depositPeriodFrequency
            ? fixedDepositAccountDetails.depositPeriodFrequency.id
            : ""
          : "",
        minDepositTerm: fixedDepositAccountDetails
          ? fixedDepositAccountDetails.minDepositTerm
            ? fixedDepositAccountDetails.minDepositTerm
            : ""
          : "",
        minDepositTermTypeId: fixedDepositAccountDetails
          ? fixedDepositAccountDetails.minDepositTermType
            ? fixedDepositAccountDetails.minDepositTermType.id
            : ""
          : "",

        preClosurePenalInterest: fixedDepositAccountDetails
          ? fixedDepositAccountDetails.preClosurePenalInterest
            ? fixedDepositAccountDetails.preClosurePenalInterest
            : ""
          : "",

        preClosurePenalInterestOnTypeId: fixedDepositAccountDetails
          ? fixedDepositAccountDetails.preClosurePenalInterestOnType
            ? fixedDepositAccountDetails.preClosurePenalInterestOnType.id
            : ""
          : "",

        linkedAccountId: fixedDepositAccountDetails
          ? fixedDepositAccountDetails.linkedAccount
            ? fixedDepositAccountDetails.linkedAccount.id
            : ""
          : "",
        maturityInstruction: fixedDepositAccountDetails
          ? fixedDepositAccountDetails.onAccountClosure
            ? fixedDepositAccountDetails.onAccountClosure.id
            : ""
          : "",
        transferToSavings: fixedDepositAccountDetails
          ? fixedDepositAccountDetails.transferToSavingsAccount
            ? fixedDepositAccountDetails.transferToSavingsAccount.id
            : ""
          : "",

        // transferToSavingsId: fixedDepositAccountDetails
        //   ? fixedDepositAccountDetails?.transferToSavingsId
        //   : "",

        preClosurePenalApplicable: fixedDepositAccountDetails
          ? fixedDepositAccountDetails?.preClosurePenalApplicable
          : false,
        transferToSavingsId: fixedDepositAccountDetails
          ? fixedDepositAccountDetails?.transferInterestToSavings
          : false,
        withHoldTax: fixedDepositAccountDetails
          ? fixedDepositAccountDetails?.withHoldTax
          : false,
        nominalAnnualInterest: fixedDepositAccountDetails
          ? fixedDepositAccountDetails?.nominalAnnualInterestRate
          : "",
        // enforceMinimumBalance: fixedDepositAccountDetails
        //   ? fixedDepositAccountDetails.enforceMinRequiredBalance
        //   : false,
        // enforeceMinBalance: fixedDepositAccountDetails
        //   ? fixedDepositAccountDetails.enforceMinReqBalance
        //   : false,
        // applyWithdrawalFee: fixedDepositAccountDetails
        //   ? fixedDepositAccountDetails.withdrawalFeeForTransfers
        //   : false,

        lockInPeriodFreqSelect: fixedDepositAccountDetails?.lockinPeriodFrequencyType?.id ?? "",
        lockinPeriodFreq: fixedDepositAccountDetails?.lockinPeriodFrequency ?? "",

        // minimumOpeningBalance: fixedDepositAccountDetails
        //   ? fixedDepositAccountDetails.minRequiredOpeningBalance
        //     ? fixedDepositAccountDetails.minRequiredOpeningBalance
        //     : ''
        //   : '',
        // isOverdraft: fixedDepositAccountDetails ? fixedDepositAccountDetails.allowOverdraft : false,
        // overdraftLimit: fixedDepositAccountDetails ? fixedDepositAccountDetails.overdraftLimit : '',
        // minBalanceForInterestCalculation: fixedDepositAccountDetails
        //   ? fixedDepositAccountDetails.minOverdraftForInterestCalculation
        //   : '',
        // nominalAnnualInterestRateOverdraft: fixedDepositAccountDetails
        //   ? fixedDepositAccountDetails.nominalAnnualInterestRateOverdraft
        //   : '',
      });

      setCharges(
        !!fixedDepositAccountDetails.charges
          ? fixedDepositAccountDetails.charges
          : []
      );
    }
  }, [fixedDepositAccountDetails]);

  // console.log(charges, " before useEffect");


  let newCharges = [];
  useEffect(() => {
    charges.map((charge) => {
      let { id, amount, chargeId, chargeTimeType, date, dueDate, feeInterval } =
        charge;
      let dayMonth = !!date ? formatDate(date, true) : "";
      if (id && chargeId) {
        if (chargeTimeType.value === "Weekly Fee") {
          newCharges.push({
            dueDate: formatDate(dueDate),
            id,
            amount: parseFloat(amount),
            feeInterval: parseFloat(feeInterval),
          });
        } else if (
          chargeTimeType.value === "Withdrawal Fee" ||
          chargeTimeType.value === "Savings Activation" ||
          chargeTimeType.value === "Overdraft Fee" ||
          chargeTimeType.value === "Saving No Activity Fee"
        ) {
          newCharges.push({
            id,
            amount: parseFloat(amount),
          });
        } else if (chargeTimeType.value === "Specified due date") {
          newCharges.push({
            id,
            amount: parseFloat(amount),
            dueDate: formatDate(dueDate),
          });
        } else if (chargeTimeType.value === "Annual Fee") {
          newCharges.push({
            feeOnMonthDay: dayMonth?.substring(0, dayMonth?.length - 5),
            // chargeId: chargeId,
            id,
            amount: parseFloat(amount),
          });
        } else {
          return newCharges.push({
            // removing the year and the space b4 it as endpoint only accept month and day
            feeOnMonthDay: dayMonth?.substring(0, dayMonth?.length - 5),
            locale: "en",
            monthDayFormat: "dd MMMM",
            id,
            amount: parseFloat(amount),
            feeInterval: parseFloat(feeInterval),
          });
        }
      } else if (!chargeId && id) {
        if (chargeTimeType.value === "Weekly Fee") {
          return newCharges.push({
            dueDate: dayMonth,
            chargeId: id,
            locale: "en",
            dateFormat: "dd MM yyyy",
            amount: parseFloat(amount),
            feeInterval: parseFloat(feeInterval),
          });
        } else if (
          chargeTimeType.value === "Withdrawal Fee" ||
          chargeTimeType.value === "Savings Activation" ||
          chargeTimeType.value === "Overdraft Fee" ||
          chargeTimeType.value === "Saving No Activity Fee"
        ) {
          return newCharges.push({
            chargeId: id,
            amount: parseFloat(amount),
          });
        } else if (chargeTimeType.value === "Specified due date") {
          return newCharges.push({
            chargeId: id,
            amount: parseFloat(amount),
            dueDate: formatDate(dueDate),
            locale: "en",
            dateFormat: "dd MM yyyy",
          });
        } else if (chargeTimeType.value === "Annual Fee") {
          return newCharges.push({
            feeOnMonthDay: dayMonth?.substring(0, dayMonth?.length - 5),
            chargeId: id,
            locale: "en",
            monthDayFormat: "dd MMMM",
            amount: parseFloat(amount),
          });
        } else {
          return newCharges.push({
            // removing the year and the space b4 it as endpoint only accept month and day
            feeOnMonthDay: dayMonth?.substring(0, dayMonth?.length - 5),
            chargeId: id,
            locale: "en",
            monthDayFormat: "dd MMMM",
            amount: parseFloat(amount),
            feeInterval: parseFloat(feeInterval),
          });
        }
      }
    });
  }, [charges, newCharges]);

  const handleSubmit = async () => {
    let chartSlabsArray = chartSlabs.map((item) => {
      return { ...item, periodType: item?.periodType.id };
    });

    // const
    let charts = [
      {
        chartSlabs: chartSlabsArray,
        dateFormat: "dd MMMM yyyy",
        endDate: endDate ? formatDate(endDate, true) : "",
        fromDate: validFrom ? formatDate(validFrom, true) : "",
        locale: "en",
        // periodType: 1,
        id:
          fixedDepositAccountDetails &&
          fixedDepositAccountDetails.accountChart &&
          fixedDepositAccountDetails.accountChart.id,
      },
    ];

    // const nominalAnnualInterestRate =
    //   charts[0].chartSlabs[0].annualInterestRate;

    let data = {
      charts,
      locale: "en",
      dateFormat: "dd MMMM yyyy",
      productId: newFixedDepositAccount?.productId,
      clientId,
      submittedOnDate: formatDate(submittedDate, true),
      depositPeriod: newFixedDepositAccount.depositPeriod,
      depositPeriodFrequencyId: newFixedDepositAccount.depositPeriodFrequencyId,
      interestCompoundingPeriodType:
        newFixedDepositAccount.interestCompoundingPeriod,
      interestPostingPeriodType: newFixedDepositAccount.interestPostingPeriod,
      interestCalculationType: newFixedDepositAccount.interestCalculated,
      interestCalculationDaysInYearType: newFixedDepositAccount.daysInYear,
      withHoldTax: newFixedDepositAccount.withHoldTax,
      preClosurePenalApplicable:
        newFixedDepositAccount.preClosurePenalApplicable,
      depositAmount,
      nominalAnnualInterestRate: newFixedDepositAccount.nominalAnnualInterest,
      // nominalAnnualInterestRate: newFixedDepositAccount.nominalAnnualInterest,
      transferToSavingsId: newFixedDepositAccount.transferToSavings,
      maturityInstructionId: newFixedDepositAccount.maturityInstruction,
      preClosurePenalInterest: newFixedDepositAccount.preClosurePenalInterest,
      preClosurePenalInterestOnTypeId:
        newFixedDepositAccount.preClosurePenalInterestOnTypeId,
      fieldOfficerId: newFixedDepositAccount.fieldOfficerId,
      linkAccountId: newFixedDepositAccount.linkedAccountId,
      minDepositTermTypeId: newFixedDepositAccount.minDepositTermTypeId,
      minDepositTerm: mindepositTerm,

      // externalId: newFixedDepositAccount?.externalId,
      // enforceMinRequiredBalance: newFixedDepositAccount.enforceMinimumBalance,
      // fieldOfficerId: newFixedDepositAccount.fieldOfficerId,
      // overdraftLimit: overdraftLimit ? parseFloat(overdraftLimit) : '',
      // allowOverdraft: newFixedDepositAccount.isOverdraft,
      // minRequiredOpeningBalance: newFixedDepositAccount.minimumOpeningBalance,
      // minRequiredBalance: minumumBalance ? parseFloat(minumumBalance) : '',
      lockinPeriodFrequency: newFixedDepositAccount.lockinPeriodFreq,
      lockinPeriodFrequencyType: newFixedDepositAccount.lockInPeriodFreqSelect,
      // withHoldTax: newFixedDepositAccount.isWitholdTax,
      // withdrawalFeeForTransfers: newFixedDepositAccount.applyWithdrawalFee,
      // nominalAnnualInterestRate: newFixedDepositAccount.nominalAnnualInterest,
      // minOverdraftForInterestCalculation: minBalanceForInterestCalculation,
      // nominalAnnualInterestRateOverdraft: nominalAnnualInterestRateOverdraft
      //   ? parseFloat(nominalAnnualInterestRateOverdraft)
      //   : '',
    };

    // console.log(data, "data");

    const newObject = { ...data };
    for (const key in newObject) {
      if (Object.prototype.hasOwnProperty.call(newObject, key)) {
        const element = newObject[key];
        if (!element && typeof element !== "number") {
          delete newObject[key];
        } else if (element === undefined || element === "") {
          delete newObject[key];
        } else if ((element + "").length <= 0) {
          delete newObject[key];
        }
      }
    }
    data = newObject;
    // const newData = { ...data,  };
    const newData = { ...data, charges: newCharges };
    // console.log(newData?.charges, "new charges");
    // console.log(newData, "Before Submission");
    setLoading(true);
    dispatch(
      updateFixedDepositAccount(newData, match.params.id, history, clientId)
    );
    setLoading(false);
    // }
  };

  return (
    <>
      <div className="row justify-content-between">
        <div className="col-sm-12 col-md-6 pb-5 mt-1">
          <h3 className="page-title">Edit Fixed Deposit Account</h3>
          <p className="text-label sub-title">Modify Fixed Deposit accounts</p>
          <p className="font-weight sub-title mt-2">
            <span className="text-info">
              <Link to="/core/clients">Customer </Link>
            </span>{" "}
            /{" "}
            <span className="text-info">
              <Link to={`/core/client/${clientId}`}>
                {!!clientName ? `${clientName}  ` : ""}
              </Link>
            </span>
            / Edit Fixed Deposit Accounts
          </p>
          <p
            onClick={(e) => {
              e.stopPropagation();
              history.push(
                `/core/client/${clientId}/${goToFixedTab}/${match?.params.id}/routeFrom`
              );
            }}
            className="d-flex mt-4"
            style={{ color: "#2C1DFF", cursor: "pointer" }}
          >
            <img className="mr-2" src={BackSvg} alt="" />
            Back
          </p>
        </div>
      </div>

      <div className="row" style={{ minHeight: "calc(100vh - 250px)" }}>
        <div className="col-sm-12 col-md-12">
          <ContentWrapper style={{ paddingTop: 50, paddingBottom: 50 }}>
            <div
              className="container pl-5 pr-5 pt-2 pb-2"
              id="fixed-deposit-screen"
            >
              <div className="row">
                <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                  <h3 className="page-title">Details</h3>
                </div>

                <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                  <div className="col-sm-12 col-md-6 px-0 ">
                    {/* Product */}
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label">Product*</label>
                          <Select
                            placeholder="Select fixed deposit type"
                            size="md"
                            name="depositProductId"
                            value={newFixedDepositAccount.productId}
                            onChange={handleProductType}
                          >
                            {!!fixedDepositAccountDetails?.productOptions &&
                              !!fixedDepositAccountDetails?.productOptions
                                .length &&
                              fixedDepositAccountDetails?.productOptions.map(
                                (cur) => {
                                  return (
                                    <option key={cur.id} value={cur.id}>
                                      {cur.name}
                                    </option>
                                  );
                                }
                              )}
                          </Select>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>

                  {/* Submitted on* */}
                  <div className="col-sm-12 col-md-6 px-0">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label">Submitted on*</label>
                          <FormControl style={{ width: "60%" }}>
                            <div className="datepicker-100-width">
                              <DatePicker
                                id="start-date"
                                selected={submittedDate}
                                onChange={(date) => setSubmittedDate(date)}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                placeholderText="dd/mmm/yyyy"
                              />
                            </div>
                          </FormControl>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                </div>

                <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                  <div className="col-sm-12 col-md-6 px-0 ">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label">Field Officer</label>
                          <Select
                            value={newFixedDepositAccount.fieldOfficerId}
                            name="fieldOfficerId"
                            onChange={editFixedDepositAccountHandler}
                            placeholder="Select field officer"
                            size="md"
                          >
                            {!!fixedDepositAccountDetails?.fieldOfficerOptions &&
                              !!fixedDepositAccountDetails?.fieldOfficerOptions
                                .length &&
                              fixedDepositAccountDetails?.fieldOfficerOptions.map(
                                (cur) => {
                                  return (
                                    <option value={cur.id} key={cur.id}>
                                      {cur.displayName}
                                    </option>
                                  );
                                }
                              )}
                          </Select>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                </div>
                <div style={{ width: "100%" }} className="py-1">
                  <LineThrough width="100%" />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                  <h3 className="page-title">Terms</h3>
                </div>

                {/* <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                    <div className="col-sm-12 col-md-6 px-0 ">
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label">Currency*</label>
                            <Select
                              disabled
                              value={
                                !!currency && !!currency.code && currency.code
                              }
                              placeholder="Select currency"
                              size="md"
                            >
                              <option
                                value={
                                  !!currency && !!currency.code && currency.code
                                }
                              >
                                {!!currency &&
                                  !!currency.displayLabel &&
                                  currency.displayLabel}
                              </option>
                            </Select>
                          </div>
                        </FormElements>
                      </FormClass>
                    </div>
                    <div className="col-sm-12 col-md-6 px-0">
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label">Decimal Places</label>
                            <Input
                              value={
                                !!currency && !!currency.decimalPlaces
                                  ? currency.decimalPlaces
                                  : ""
                              }
                              placeholder="Decimal Places"
                              style={{ width: "60%" }}
                              disabled
                            />
                          </div>
                        </FormElements>
                      </FormClass>
                    </div>
                  </div> */}
                <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                  <div className="col-sm-12 col-md-6 px-0 ">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label">
                            Interest Compounding Period*
                          </label>
                          <Select
                            placeholder="Select Interest compounding period"
                            size="md"
                            value={
                              newFixedDepositAccount.interestCompoundingPeriod
                            }
                            name="interestCompoundingPeriod"
                            onChange={editFixedDepositAccountHandler}
                          >
                            {!!fixedDepositAccountDetails?.interestCompoundingPeriodTypeOptions &&
                              !!fixedDepositAccountDetails
                                ?.interestCompoundingPeriodTypeOptions.length &&
                              fixedDepositAccountDetails?.interestCompoundingPeriodTypeOptions.map(
                                (cur) => {
                                  return (
                                    <option value={cur.id}>{cur.value}</option>
                                  );
                                }
                              )}
                          </Select>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                  <div className="col-sm-12 col-md-6 px-0">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label">
                            Interest Posting Period*
                          </label>
                          <Select
                            placeholder="Select Interest posting period"
                            size="md"
                            value={newFixedDepositAccount.interestPostingPeriod}
                            name="interestPostingPeriod"
                            onChange={editFixedDepositAccountHandler}
                          >
                            {!!fixedDepositAccountDetails?.interestPostingPeriodTypeOptions &&
                              !!fixedDepositAccountDetails
                                ?.interestPostingPeriodTypeOptions.length &&
                              fixedDepositAccountDetails?.interestPostingPeriodTypeOptions.map(
                                (cur) => {
                                  return (
                                    <option value={cur.id}>{cur.value}</option>
                                  );
                                }
                              )}
                          </Select>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                  <div className="col-sm-12 col-md-6 px-0 ">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label">
                            Interest Calculated Using*
                          </label>
                          <Select
                            placeholder="Interest calculated using"
                            size="md"
                            value={newFixedDepositAccount.interestCalculated}
                            name="interestCalculated"
                            onChange={editFixedDepositAccountHandler}
                          >
                            {!!fixedDepositAccountDetails?.interestCalculationTypeOptions &&
                              !!fixedDepositAccountDetails
                                ?.interestCalculationTypeOptions.length &&
                              fixedDepositAccountDetails?.interestCalculationTypeOptions.map(
                                (cur) => {
                                  return (
                                    <option value={cur.id}>{cur.value}</option>
                                  );
                                }
                              )}
                          </Select>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                  <div className="col-sm-12 col-md-6 px-0">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label">Days In Year*</label>
                          <Select
                            placeholder="Select Days in Year"
                            size="md"
                            value={newFixedDepositAccount.daysInYear}
                            name="daysInYear"
                            onChange={editFixedDepositAccountHandler}
                          >
                            {!!fixedDepositAccountDetails?.interestCalculationDaysInYearTypeOptions &&
                              !!fixedDepositAccountDetails
                                ?.interestCalculationDaysInYearTypeOptions
                                .length &&
                              fixedDepositAccountDetails?.interestCalculationDaysInYearTypeOptions.map(
                                (cur) => {
                                  return (
                                    <option value={cur.id}>{cur.value}</option>
                                  );
                                }
                              )}
                          </Select>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                  <div className="col-sm-12 col-md-6 px-0 ">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label">Deposit Amount*</label>
                          <CurrencyFormat
                            value={depositAmount}
                            name="depositAmount"
                            thousandSeparator={true}
                            className="amount-input"
                            style={{
                              width: "60%",
                            }}
                            onValueChange={(values) => {
                              const { value } = values;
                              setDepositAmount(value);
                            }}
                          />
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                  <div className="col-sm-12 col-md-6 px-0">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center mb-10-imp">
                          <label className="text-label">Deposit Period*</label>
                          <Input
                            value={newFixedDepositAccount.depositPeriod}
                            onChange={editFixedDepositAccountHandler}
                            name="depositPeriod"
                            style={{ width: "60%" }}
                          />
                        </div>
                      </FormElements>
                    </FormClass>
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label d-flex flex-align-center"></label>
                          <Select
                            placeholder="Select Deposit Period Type"
                            size="md"
                            name="depositPeriodFrequencyId"
                            value={
                              newFixedDepositAccount.depositPeriodFrequencyId
                            }
                            onChange={editFixedDepositAccountHandler}
                          >
                            {!!fixedDepositAccountDetails?.periodFrequencyTypeOptions &&
                              !!fixedDepositAccountDetails
                                ?.periodFrequencyTypeOptions.length &&
                              fixedDepositAccountDetails?.periodFrequencyTypeOptions.map(
                                (cur) => {
                                  return (
                                    <option value={cur.id}>{cur.value}</option>
                                  );
                                }
                              )}
                          </Select>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                  <div className="col-sm-12 col-md-6 px-0">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center mb-10-imp">
                          <label className="text-label">Lock-In Period</label>
                          <Input
                            style={{ width: "60%" }}
                            name="lockinPeriodFreq"
                            value={newFixedDepositAccount.lockinPeriodFreq}
                            onChange={editFixedDepositAccountHandler}
                          />
                        </div>
                      </FormElements>
                    </FormClass>
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label d-flex flex-align-center"></label>
                          <Select
                            placeholder="Select Lock in Period Type"
                            size="md"
                            name="lockInPeriodFreqSelect"
                            value={
                              newFixedDepositAccount?.lockInPeriodFreqSelect
                            }
                            // value={
                            //   createFixedDepositAccount.lockinPeriodFrequencyType ===
                            //   ""
                            //     ? ""
                            //     : createFixedDepositAccount.lockinPeriodFrequencyType
                            // }
                            onChange={editFixedDepositAccountHandler}
                          >
                            {!!fixedDepositAccountDetails?.lockinPeriodFrequencyTypeOptions &&
                              !!fixedDepositAccountDetails
                                ?.lockinPeriodFrequencyTypeOptions.length &&
                              fixedDepositAccountDetails?.lockinPeriodFrequencyTypeOptions.map(
                                (cur) => {
                                  return (
                                    <option id={cur.id} key={cur.id} value={cur.id}>
                                      {cur.value}
                                    </option>
                                  );
                                }
                              )}
                          </Select>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                </div>

                <div style={{ width: "100%" }} className="py-1">
                  <LineThrough width="100%" />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                  <h3 className="page-title">Settings</h3>
                </div>

                {/* <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                    <div className="col-sm-12 col-md-6 px-0 ">
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label">
                              Minimum Deposit Term
                            </label>
                            <Input
                              value={minimumDepositTerm}
                              // onChange={createFixedDepositAccountHandler}
                              onChange={handleMinDepositTerm}
                              name="minDepositTerm"
                              style={{ width: "60%" }}
                              size="md"
                              disabled
                            />
                          </div>
                        </FormElements>
                      </FormClass>
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label d-flex flex-align-center"></label>
                            <Select
                              placeholder="Select Minimum Deposit Period"
                              size="md"
                              name="minDepositTermTypeId"
                              disabled
                              value={minimumDepTermValue}
                              // value={
                              //   createFixedDepositAccount.minDepositTermTypeId ===
                              //   ""
                              //     ? 0
                              //     : createFixedDepositAccount.minDepositTermTypeId
                              // }
                              // onChange={createFixedDepositAccountHandler}
                              onChange={handleMinDepositType}
                            >
                              {!!periodFrequencyTypeOptions &&
                                !!periodFrequencyTypeOptions.length &&
                                periodFrequencyTypeOptions.map((cur) => {
                                  return (
                                    <option id={cur.id} key={cur.id}>
                                      {cur.value}
                                    </option>
                                  );
                                })}
                            </Select>
                          </div>
                        </FormElements>
                      </FormClass>
                    </div>
                    <div className="col-sm-12 col-md-6 px-0">
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label">
                              And thereafter, In Multiples of:
                            </label>
                            <Input
                              value={
                                createFixedDepositAccount.inMultiplesOfDepositTerm
                              }
                              onChange={createFixedDepositAccountHandler}
                              name="inMultiplesOfDepositTerm"
                              style={{ width: "60%" }}
                              size="md"
                              disabled
                            />
                          </div>
                        </FormElements>
                      </FormClass>
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label d-flex flex-align-center"></label>
                            <Select
                              placeholder="Select In Multiples of Deposit Term"
                              size="md"
                              name="inMultiplesOfDepositTermTypeId"
                              disabled
                              value={
                                createFixedDepositAccount.inMultiplesOfDepositTermTypeId
                              }
                              onChange={createFixedDepositAccountHandler}
                            >
                              {!!periodFrequencyTypeOptions &&
                                !!periodFrequencyTypeOptions.length &&
                                periodFrequencyTypeOptions.map((cur) => {
                                  return (
                                    <option value={cur.id}>{cur.value}</option>
                                  );
                                })}
                            </Select>
                          </div>
                        </FormElements>
                      </FormClass>
                    </div>
                  </div> */}
                {/* <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                    <div className="col-sm-12 col-md-6 px-0 ">
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label">
                              Maximum Deposit Term
                            </label>
                            <Input
                              value={maximumDepositTerm}
                              // onChange={createFixedDepositAccountHandler}
                              onChange={handleMaxDepositTerm}
                              name="maxDepositTerm"
                              disabled
                              style={{ width: "60%" }}
                              size="md"
                            />
                          </div>
                        </FormElements>
                      </FormClass>
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label d-flex flex-align-center"></label>
                            <Select
                              placeholder="Select Maximum Deposit Period Type"
                              size="md"
                              disabled
                              name="maxDepositTermTypeId"
                              value={maximumDepTermValue}
                              // value={
                              //   createFixedDepositAccount.maxDepositTermTypeId ===
                              //   ""
                              //     ? 0
                              //     : createFixedDepositAccount.maxDepositTermTypeId
                              // }
                              onChange={handleMaxDepositType}
                            >
                              {!!periodFrequencyTypeOptions &&
                                !!periodFrequencyTypeOptions.length &&
                                periodFrequencyTypeOptions.map((cur) => {
                                  return (
                                    <option id={cur.id} key={cur.id}>
                                      {cur.value}
                                    </option>
                                  );
                                })}
                            </Select>
                          </div>
                        </FormElements>
                      </FormClass>
                    </div>
                  </div> */}
                <div
                  className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap"
                  style={{ marginBottom: "5px", alignItems: "center" }}
                >
                  <div className="col-sm-4 col-md-4 px-0 ">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label d-flex flex-align-center">
                            For premature closure
                          </label>
                          {/* <FormControl style={{ width: "60%" }}> */}
                          <FormControl
                            className="fd-FormControl"
                            style={{ marginRight: "127px" }}
                          >
                            <Checkbox
                              size="md"
                              name="preClosurePenalApplicable"
                              isChecked={
                                newFixedDepositAccount.preClosurePenalApplicable
                              }
                              value={
                                newFixedDepositAccount.preClosurePenalApplicable
                              }
                              isDisabled
                              onChange={(e) =>
                                setNewFixedDepositAccount((p) => ({
                                  ...p,
                                  preClosurePenalApplicable: e.target.checked,
                                }))
                              }
                            />
                          </FormControl>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                  {/* {preClosurePenalApplicable ? ( */}
                  <>
                    {/*  Apply penal interest */}
                    {/* <div
                          className="col-sm-4 col-md-4 px-0 "
                          style={{ marginLeft: "-30px" }}
                        >
                          <FormClass>
                            <FormElements>
                              <div className="form-group d-flex justify-content-between align-items-center">
                                <label
                                  className="text-label"
                                  style={{
                                    width: "100%",
                                    // marginLeft: "-100px",
                                    marginLeft: "-156px",
                                  }}
                                >
                                  Apply penal interest
                                </label>
                                <Input
                                  value={
                                    createFixedDepositAccount.preClosurePenalInterest
                                  }
                                  onChange={createFixedDepositAccountHandler}
                                  name="preClosurePenalInterest"
                                  disabled
                                  // style={{ width: "60%", marginRight: "60px" }}
                                  style={{ width: "90%", marginRight: "10px" }}
                                  size="md"
                                />
                              </div>
                            </FormElements>
                          </FormClass>
                        </div> */}

                    {/* <div
                          className="col-sm-4 col-md-4 px-0"
                          style={{ marginRight: "18px" }}
                        >
                          <FormClass>
                            <FormElements>
                              <div className="form-group d-flex justify-content-between align-items-center">
                                <label className="text-label d-flex flex-align-center">
                                  on
                                </label>
                                <Select
                                  size="md"
                                  name="preClosurePenalInterestOnTypeId"
                                  placeholder="Select On"
                                  value={
                                    createFixedDepositAccount?.preClosurePenalInterestOnTypeId
                                      ? createFixedDepositAccount?.preClosurePenalInterestOnTypeId
                                      : ""
                                  }
                                  disabled
                                  onChange={createFixedDepositAccountHandler}
                                >
                                  {!!preClosurePenalInterestOnTypeOptions &&
                                    !!preClosurePenalInterestOnTypeOptions.length &&
                                    preClosurePenalInterestOnTypeOptions.map(
                                      (cur) => {
                                        return (
                                          <option value={cur.id} key={cur.id}>
                                            {cur.value}
                                          </option>
                                        );
                                      }
                                    )}
                                </Select>
                              </div>
                            </FormElements>
                          </FormClass>
                        </div> */}
                  </>
                  {/* ) : (
                    ""
                  )} */}
                </div>
                <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                  <div className="col-sm-12 col-md-6 px-0 ">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label">
                            Transfer Interest to Linked Savings Account
                          </label>
                          <FormControl style={{ width: "60%" }}>
                            <Checkbox
                              size="md"
                              name="transferToSavingsId"
                              isChecked={
                                newFixedDepositAccount.transferToSavingsId
                              }
                              value={newFixedDepositAccount.transferToSavingsId}
                              onChange={(e) =>
                                setNewFixedDepositAccount((p) => ({
                                  ...p,
                                  transferToSavingsId: e.target.checked,
                                }))
                              }
                            />
                          </FormControl>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                  <div className="col-sm-12 col-md-6 px-0 ">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label">Link Savings*</label>
                          <Select
                            placeholder="Select Link Savings"
                            size="md"
                            value={newFixedDepositAccount.linkedAccountId}
                            name="linkedAccountId"
                            onChange={editFixedDepositAccountHandler}
                          >
                            {!!fixedDepositAccountDetails?.savingsAccounts &&
                              !!fixedDepositAccountDetails?.savingsAccounts
                                .length &&
                              fixedDepositAccountDetails?.savingsAccounts.map(
                                (cur) => {
                                  return (
                                    <option value={cur.id}>
                                      {cur.savingsProductName}({cur.accountNo})
                                    </option>
                                  );
                                }
                              )}
                          </Select>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                </div>
                {/* Maturity Instruction */}
                <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                  <div className="col-sm-12 col-md-6 px-0 ">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label">
                            Maturity Instruction &nbsp; *
                          </label>
                          <Select
                            placeholder="Select Instruction"
                            size="md"
                            value={newFixedDepositAccount.maturityInstruction}
                            name="maturityInstruction"
                            onChange={editFixedDepositAccountHandler}
                          >
                            <option value="200">Transfer To Savings</option>
                          </Select>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>

                  {newFixedDepositAccount.maturityInstruction == "200" ? (
                    <div className="col-sm-12 col-md-6 px-0 ">
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label">
                              Transfer to Savings*
                            </label>
                            <Select
                              placeholder="Select Savings Account"
                              size="md"
                              value={newFixedDepositAccount.transferToSavings}
                              name="transferToSavings"
                              onChange={editFixedDepositAccountHandler}
                            >
                              {!!fixedDepositAccountDetails?.savingsAccounts &&
                                !!fixedDepositAccountDetails?.savingsAccounts
                                  .length &&
                                fixedDepositAccountDetails?.savingsAccounts.map(
                                  (cur) => {
                                    return (
                                      <option value={cur.id}>
                                        {cur.savingsProductName}({cur.accountNo}
                                        )
                                      </option>
                                    );
                                  }
                                )}
                            </Select>
                          </div>
                        </FormElements>
                      </FormClass>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                  {productData?.withHoldTax && (
                  <div className="col-sm-12 col-md-6 px-0 ">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label">
                            Is Withholding Tax Applicable
                          </label>
                          <FormControl style={{ width: "60%" }}>
                            <Checkbox
                              size="md"
                              name="withHoldTax"
                              isChecked={newFixedDepositAccount.withHoldTax}
                              value={newFixedDepositAccount.withHoldTax}
                              onChange={(e) =>
                                setNewFixedDepositAccount((p) => ({
                                  ...p,
                                  withHoldTax: e.target.checked,
                                }))
                              }
                              // onChange={(e) => setWithHoldTax(!withHoldTax)}
                            />
                          </FormControl>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                  )}
                  {/* {withHoldTax ? (
                      <div className="col-sm-12 col-md-6 px-0">
                        <FormClass>
                          <FormElements>
                            <div className="form-group d-flex justify-content-between align-items-center">
                              <label className="text-label">Tax Group</label>
                              <Input
                                value={
                                  !!taxGroup && !!taxGroup.name
                                    ? taxGroup.name
                                    : ""
                                }
                                placeholder="Tax Group"
                                style={{ width: "60%" }}
                                disabled
                              />
                            </div>
                          </FormElements>
                        </FormClass>
                      </div>
                    ) : null} */}
                </div>
                <div style={{ width: "100%" }} className="py-1">
                  <LineThrough width="100%" />
                </div>
              </div>

              {/* Interets Charts Goes here */}
              <div className="row">
                <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                  <h3 className="page-title">Interest</h3>
                </div>
                <EditFixedInterestRate
                  validFrom={validFrom}
                  handleValidFrom={handleValidFrom}
                  endDate={endDate}
                  handleEndDate={handleEndDate}
                  defaultData={defaultData}
                  chartSlabs={chartSlabs}
                  setChartSlabs={setChartSlabs}
                  template={fixedDepositAccountDetails}
                  editFixedDepositAccountHandler={
                    editFixedDepositAccountHandler
                  }
                  newFixedDepositAccount={newFixedDepositAccount}
                  // interests={interests}
                  // ÷setInterests={setInterests}
                  // renderTooltip={renderTooltip}
                />
              </div>

              <div className="row">
                <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                  <h3 className="page-title">Charges</h3>
                </div>

                <EditFixedDepositCharges
                  setCharges={setCharges}
                  charges={charges}
                  template={fixedDepositAccountDetails?.chargeOptions}
                  editFixedDepositAccountHandler={
                    editFixedDepositAccountHandler
                  }
                  loading={processing}
                  handleSubmit={handleSubmit}
                />
                {/* <Charges
                  setCharges={setCharges}
                  charges={charges}
                  // template={fixedDepositAccountCreationTemplate}
                  template={fixedDepositAccountCreateTemplate}
                /> */}

                {/* For Charges */}
              </div>

              {/* <div className="float-right" style={{ padding: "6rem 0 3rem" }}>
                <ActionButton
                  color="#2C1DFF"
                  // onClick={(e) =>
                  //   history.push(`/core/client/${match.params.id}`)
                  // }
                >
                  <img
                    src={
                      require("../../../../../../assets/images/icons/cancel.svg")
                        .default
                    }
                    alt="cancel icon"
                  />
                  Cancel
                </ActionButton>
                <ActionButton
                  color="#2C1DFF"
                  style={{ margin: "0px 0px 0 10px" }}
                  // onClick={(e) => handleSubmitFixedDeposit()}
                >
                  <img
                    src={
                      require("../../../../../../assets/images/icons/save.svg")
                        .default
                    }
                    alt="submit icon"
                  />
                  Submit
                </ActionButton>
              </div> */}
            </div>
          </ContentWrapper>
        </div>
      </div>
    </>
  );
};

export default EditFixedDepositAccount;

// getFixedDepositAccountDetails
