export const SET_FIXED_DEPOSIT = "SET_FIXED_DEPOSIT";
export const SET_ONE_FIXED_DEPOSIT = "SET_ONE_FIXED_DEPOSIT";
export const SET_FIXED_DEPOSIT_ACTIONS = "SET_FIXED_DEPOSIT_ACTIONS";
export const SET_FIXED_DEPOSIT_TEMPLATE = "SET_FIXED_DEPOSIT_TEMPLATE";
export const SET_FIXED_DEPOSIT_ACCOUNT_DETAILS =
  "SET_FIXED_DEPOSIT_ACCOUNT_DETAILS";
export const SET_FD_LEDGER_DETAILS = "SET_FD_LEDGER_DETAILS";
export const SET_FIXED_DEPOSIT_ACCOUNT_TEMPLATE =
  "SET_FIXED_DEPOSIT_ACCOUNT_TEMPLATE";

export const UPDATE_CHARGES = "UPDATE_CHARGES";
export const WAIVE_CHARGES = "WAIVE_CHARGES";
export const SET_FIXED_TRANSACTIONS = "SET_FIXED_TRANSACTIONS";
export const SET_FIXED_DEPOSIT_ACTIONS_RESET =
  "SET_FIXED_DEPOSIT_ACTIONS_RESET";

export const CREATE_NEW_FD_PRODUCT_REQUEST = "CREATE_NEW_FD_PRODUCT_REQUEST";
export const CREATE_NEW_FD_PRODUCT_SUCCESS = "CREATE_NEW_FD_PRODUCT_SUCCESS";
export const CREATE_NEW_FD_PRODUCT_FAIL = "CREATE_NEW_FD_PRODUCT_FAIL";
export const CREATE_NEW_FD_PRODUCT_RESET = "CREATE_NEW_FD_PRODUCT_RESET";

export const FD_PRODUCT_REQUEST = "FD_PRODUCT_REQUEST";
export const FD_PRODUCT_SUCCESS = "FD_PRODUCT_SUCCESS";
export const FD_PRODUCT_FAIL = "FD_PRODUCT_FAIL";
export const FD_PRODUCT_RESET = "FD_PRODUCT_RESET";

export const ALL_FD_PRODUCT_REQUEST = "ALL_FD_PRODUCT_REQUEST";
export const ALL_FD_PRODUCT_SUCCESS = "ALL_FD_PRODUCT_SUCCESS";
export const ALL_FD_PRODUCT_FAIL = "ALL_FD_PRODUCT_FAIL";

export const ALL_FD_PRODUCTS_TEMPLATE_REQUEST =
  "ALL_FD_PRODUCTS_TEMPLATE_REQUEST";
export const ALL_FD_PRODUCTS_TEMPLATE_SUCCESS =
  "ALL_FD_PRODUCTS_TEMPLATE_SUCCESS";
export const ALL_FD_PRODUCTS_TEMPLATE_FAIL = "ALL_FD_PRODUCTS_TEMPLATE_FAIL";

export const UPDATE_FD_PRODUCT_REQUEST = "UPDATE_FD_PRODUCT_REQUEST";
export const UPDATE_FD_PRODUCT_SUCCESS = "UPDATE_FD_PRODUCT_SUCCESS";
export const UPDATE_FD_PRODUCT_FAIL = "UPDATE_FD_PRODUCT_FAIL";

export const FIXED_DEPOSITS_PRODUCT_REQUEST = "FIXED_DEPOSITS_PRODUCT_REQUEST";
export const FIXED_DEPOSITS_PRODUCT_SUCCESS = "FIXED_DEPOSITS_PRODUCT_SUCCESS";
export const FIXED_DEPOSITS_PRODUCT_FAIL = "FIXED_DEPOSITS_PRODUCT_FAIL";

// export const CREATE_NEW_FD_PRODUCT_REQUEST = 'CREATE_NEW_FD_PRODUCT_REQUEST';
// export const CREATE_NEW_FD_PRODUCT_SUCCESS = 'CREATE_NEW_FD_PRODUCT_SUCCESS';
// export const CREATE_NEW_FD_PRODUCT_FAIL = "CREATE_NEW_FD_PRODUCT_FAIL";

export const FILTER_FD_PRODUCT_REQUEST = "FILTER_FD_PRODUCT_REQUEST";
export const CLEAR_FD_PRODUCT_FILTER = "CLEAR_FD_PRODUCT_FILTER";

export const ALL_FIXEDDEPOSIT_PRODUCTS_TEMPLATE_REQUEST =
  "ALL_FIXEDDEPOSIT_PRODUCTS_TEMPLATE_REQUEST";
export const ALL_FIXEDDEPOSIT_PRODUCTS_TEMPLATE_SUCCESS =
  "ALL_FIXEDDEPOSIT_PRODUCTS_TEMPLATE_SUCCESS";
export const ALL_FIXEDDEPOSIT_PRODUCTS_TEMPLATE_FAIL =
  "ALL_FIXEDDEPOSIT_PRODUCTS_TEMPLATE_FAIL";
export const RESET_ALL_FIXEDDEPOSIT_PRODUCTS_TEMPLATE =
  "RESET_ALL_FIXEDDEPOSIT_PRODUCTS_TEMPLATE";

export const GENERATE_FD_FILE_REQUEST = "GENERATE_FD_FILE_REQUEST";
export const GENERATE_FD_FILE_SUCCESS = "GENERATE_FD_FILE_SUCCESS";
export const GENERATE_FD_FILE_FAIL = "GENERATE_FD_FILE_FAIL";
export const RESET_GENERATE_FD_FILE = "RESET_GENERATE_FD_FILE";

export const UPDATE_FD_ACCOUNT_REQUEST = "UPDATE_FD_ACCOUNT_REQUEST";
export const UPDATE_FD_ACCOUNT_SUCCESS = "UPDATE_FD_ACCOUNT_SUCCESS";
export const UPDATE_FD_ACCOUNT_FAIL = "UPDATE_FD_ACCOUNT_FAIL";
export const UPDATE_FD_ACCOUNT_RESET = "UPDATE_FD_ACCOUNT_RESET";
