import {
  NIBSS_TENANTS_REQUEST,
  NIBSS_TENANTS_SUCCESS,
  NIBSS_TENANTS_FAIL,
  NIBSS_TENANTS_RESET,
  NIBSS_TENANTS_WALLET_REQUEST,
  NIBSS_TENANTS_WALLET_SUCCESS,
  NIBSS_TENANTS_WALLET_FAIL,
  NIBSS_TENANTS_WALLET_RESET,
  NIBSS_TENANTS_ACCOUNT_BALANCE_REQUEST,
  NIBSS_TENANTS_ACCOUNT_BALANCE_SUCCESS,
  NIBSS_TENANTS_ACCOUNT_BALANCE_FAIL,
  NIBSS_TENANTS_ACCOUNT_BALANCE_RESET,
  NIBSS_INFLOW_DATA_REQUEST,
  NIBSS_INFLOW_DATA_SUCCESS,
  NIBSS_INFLOW_DATA_FAIL,
  NIBSS_INFLOW_DATA_RESET,
  NIBSS_OUTFLOW_DATA_REQUEST,
  NIBSS_OUTFLOW_DATA_SUCCESS,
  NIBSS_OUTFLOW_DATA_FAIL,
  NIBSS_OUTFLOW_DATA_RESET,
  NIBSS_SINGLE_INFLOW_DATA_REQUEST,
  NIBSS_SINGLE_INFLOW_DATA_SUCCESS,
  NIBSS_SINGLE_INFLOW_DATA_FAIL,
  NIBSS_SINGLE_INFLOW_DATA_RESET,
  NIBSS_SINGLE_OUTFLOW_DATA_REQUEST,
  NIBSS_SINGLE_OUTFLOW_DATA_SUCCESS,
  NIBSS_SINGLE_OUTFLOW_DATA_RESET,
  NIBSS_SINGLE_OUTFLOW_DATA_FAIL,
  NIBSS_GET_BANK_REQUEST,
  NIBSS_GET_BANK_SUCCESS,
  NIBSS_GET_BANK_FAIL,
  NIBSS_GET_BANK_RESET,
  NIBSS_NAME_ENQUIRY_REQUEST,
  NIBSS_NAME_ENQUIRY_SUCCESS,
  NIBSS_NAME_ENQUIRY_FAIL,
  NIBSS_NAME_ENQUIRY_RESET,
  NIBSS_AUTHENTICATE_OTP_REQUEST,
  NIBSS_AUTHENTICATE_OTP_SUCCESS,
  NIBSS_AUTHENTICATE_OTP_FAIL,
  NIBSS_FUND_TRANSFER_REQUEST,
  NIBSS_FUND_TRANSFER_SUCCESS,
  NIBSS_FUND_TRANSFER_FAIL,
  NIBSS_AUTHENTICATE_OTP_RESET,
  NIBSS_FUND_TRANSFER_RESET,
  NIBSS_ALL_REPORT_REQUEST,
  NIBSS_ALL_REPORT_SUCCESS,
  NIBSS_ALL_REPORT_RESET,
  NIBSS_ALL_REPORT_FAIL,
  NIBSS_FILTER_REPORT_REQUEST,
  NIBSS_FILTER_REPORT_SUCCESS,
  NIBSS_FILTER_REPORT_FAIL,
  NIBSS_FILTER_REPORT_RESET,
  NIBSS_OUTFLOW_DATA_REQUEST_FILTER,
  NIBSS_OUTFLOW_DATA_SUCCESS_FILTER,
  NIBSS_OUTFLOW_DATA_RESET_FILTER,
  NIBSS_OUTFLOW_DATA_FAIL_FILTER,
  NIBSS_INFLOW_DATA_REQUEST_FILTER,
  NIBSS_INFLOW_DATA_SUCCESS_FILTER,
  NIBSS_INFLOW_DATA_RESET_FILTER,
  NIBSS_INFLOW_DATA_FAIL_FILTER,
  NIBSS_INFLOW_DATA_REQUEST_FILTER_DOWNLOAD,
  NIBSS_INFLOW_DATA_SUCCESS_FILTER_DOWNLOAD,
  NIBSS_INFLOW_DATA_FAIL_FILTER_DOWNLOAD,
  NIBSS_INFLOW_DATA_RESET_FILTER_DOWNLOAD,
} from "../constants/nibssConnectConstants";

// const initialState = {
//   nibssTenantsDetailsTemplate: [],
//   nibssTenantsWalletsTemplate: [],
//   nibssInflowTemplate: ,
//   nibssOutflowTemplate: {},
// };

//REDUCER FOR NIBSS TENANTS DETAILS ACCOUNT TEMPLATE
export const nibssTenantsDetailsTemplateReducer = (
  state = { nibssTenantsDetailsTemplate: [] },
  action
) => {
  switch (action.type) {
    case NIBSS_TENANTS_REQUEST:
      return { ...state, loading: true };
    case NIBSS_TENANTS_SUCCESS:
      return {
        ...state,
        loading: false,
        nibssTenantsDetailsTemplate: action.payload,
      };
    case NIBSS_TENANTS_RESET:
      return {
        nibssTenantsDetailsTemplate: null,
        loading: false,
        error: null,
      };
    case NIBSS_TENANTS_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

//REDUCER FOR NIBSS TENANTS WALLET  DETAILS ACCOUNT TEMPLATE
export const nibssTenantsWalletsTemplateReducer = (
  state = { nibssTenantsWalletsTemplate: [] },
  action
) => {
  switch (action.type) {
    case NIBSS_TENANTS_WALLET_REQUEST:
      return { ...state, loading: true };
    case NIBSS_TENANTS_WALLET_SUCCESS:
      return {
        ...state,
        loading: false,
        nibssTenantsWalletsTemplate: action.payload,
      };
    case NIBSS_TENANTS_WALLET_RESET:
      return {
        ...state,
        nibssTenantsWalletsTemplate: null,
        loading: false,
        error: null,
      };
    case NIBSS_TENANTS_WALLET_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
//REDUCER FOR NIBSS INFLOW DATA TEMPLATE
export const nibssInflowTemplateReducer = (
  state = { nibssInflowTemplate: [] },
  action
) => {
  switch (action.type) {
    case NIBSS_INFLOW_DATA_REQUEST:
      return { ...state, loading: true };
    case NIBSS_INFLOW_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        nibssInflowTemplate: action.payload,
      };
    case NIBSS_INFLOW_DATA_RESET:
      return {
        nibssInflowTemplate: null,
        loading: false,
        error: null,
      };
    case NIBSS_INFLOW_DATA_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
//REDUCER FOR NIBSS TENANT ACCOUNT BALANCE
export const nibssTenantsAccountBalanceReducer = (
  state = { nibssTenantAccountBalance: [] },
  action
) => {
  switch (action.type) {
    case NIBSS_TENANTS_ACCOUNT_BALANCE_REQUEST:
      return { ...state, loading: true };
    case NIBSS_TENANTS_ACCOUNT_BALANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        nibssTenantAccountBalance: action.payload,
      };
    case NIBSS_TENANTS_ACCOUNT_BALANCE_RESET:
      return {
        ...state,
        nibssTenantAccountBalance: null,
        loading: false,
        error: null,
      };
    case NIBSS_TENANTS_ACCOUNT_BALANCE_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

//REDUCER FOR NIBSS SINGLE INFLOW DATA TEMPLATE
export const nibssSingleInflowTemplateReducer = (
  state = { nibssSingleInflowTemplate: {} },
  action
) => {
  switch (action.type) {
    case NIBSS_SINGLE_INFLOW_DATA_REQUEST:
      return { ...state, loading: true };
    case NIBSS_SINGLE_INFLOW_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        nibssSingleInflowTemplate: action.payload,
      };
    case NIBSS_SINGLE_INFLOW_DATA_RESET:
      return {
        ...state,
        nibssSingleInflowTemplate: null,
        loading: false,
        error: null,
      };
    case NIBSS_SINGLE_INFLOW_DATA_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

//REDUCER FOR NIBSS OUTFLOW DATA TEMPLATE
export const nibssOutflowTemplateReducer = (
  state = { nibssOutflowTemplate: [] },
  action
) => {
  switch (action.type) {
    case NIBSS_OUTFLOW_DATA_REQUEST:
      return { ...state, loading: true };
    case NIBSS_OUTFLOW_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        nibssOutflowTemplate: action.payload,
      };
    case NIBSS_OUTFLOW_DATA_RESET:
      return {
        ...state,
        nibssOutflowTemplate: null,
        loading: false,
        error: null,
      };
    case NIBSS_OUTFLOW_DATA_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

//REDUCER FOR NIBSS SINGLE OUTFLOW DATA TEMPLATE
export const nibssSingleOutflowTemplateReducer = (
  state = { nibssSingleOutflowTemplate: {} },
  action
) => {
  switch (action.type) {
    case NIBSS_SINGLE_OUTFLOW_DATA_REQUEST:
      return { ...state, loading: true };
    case NIBSS_SINGLE_OUTFLOW_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        nibssSingleOutflowTemplate: action.payload,
      };
    case NIBSS_SINGLE_OUTFLOW_DATA_RESET:
      return {
        ...state,
        nibssSingleOutflowTemplate: null,
        loading: false,
        error: null,
      };
    case NIBSS_SINGLE_OUTFLOW_DATA_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
// REDUCER TO GET BANKS
export const nibssGetBanksTemplateReducer = (
  state = { nibssGetBanks: {} },
  action
) => {
  switch (action.type) {
    case NIBSS_GET_BANK_REQUEST:
      return { ...state, loading: true };
    case NIBSS_GET_BANK_SUCCESS:
      return {
        ...state,
        loading: false,
        nibssGetBanks: action.payload,
      };
    case NIBSS_GET_BANK_RESET:
      return {
        ...state,
        nibssGetBanks: null,
        loading: false,
        error: null,
      };
    case NIBSS_GET_BANK_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
// REDUCER TO PERFORM NAME ENQUIRY
export const nibbsPerfomNameEnquiryReducer = (
  state = { nibssPerformNameEnquiry: {} },
  action
) => {
  switch (action.type) {
    case NIBSS_NAME_ENQUIRY_REQUEST:
      return { ...state, loading: true };
    case NIBSS_NAME_ENQUIRY_SUCCESS:
      return {
        ...state,
        loading: false,
        nibssPerformNameEnquiry: action.payload,
      };
    case NIBSS_NAME_ENQUIRY_RESET:
      return {
        ...state,
        nibssPerformNameEnquiry: null,
        loading: false,
        error: null,
      };
    case NIBSS_NAME_ENQUIRY_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

// REDUCER TO GET OTP
export const nibbsAuthenticateOtpReducer = (
  state = { nibssAuthenticateNibssOtp: {} },
  action
) => {
  switch (action.type) {
    case NIBSS_AUTHENTICATE_OTP_REQUEST:
      return { ...state, loading: true };
    case NIBSS_AUTHENTICATE_OTP_SUCCESS:
      return {
        loading: false,
        nibssAuthenticateNibssOtp: action.payload,
      };
    case NIBSS_AUTHENTICATE_OTP_RESET:
      return {
        // ...state,
        nibssAuthenticateNibssOtp: null,
        loading: false,
        error: null,
      };
    case NIBSS_AUTHENTICATE_OTP_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
// REDUCER TO PERFORM FUND TRANSFER
export const nibbsTransferFundReducer = (
  state = { nibssTransferFunds: {} },
  action
) => {
  switch (action.type) {
    case NIBSS_FUND_TRANSFER_REQUEST:
      return { ...state, loading: true };
    case NIBSS_FUND_TRANSFER_SUCCESS:
      return {
        loading: false,
        nibssTransferFunds: action.payload,
      };
    case NIBSS_FUND_TRANSFER_RESET:
      return {
        nibssTransferFunds: null,
        loading: false,
        error: null,
      };
    case NIBSS_FUND_TRANSFER_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

// REDUCER TO GET ALL REPORTS
export const nibssGetAllReportReducer = (
  state = { nibssGetAllReportTemplate: [] },
  action
) => {
  switch (action.type) {
    case NIBSS_ALL_REPORT_REQUEST:
      return { ...state, loading: true };
    case NIBSS_ALL_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        nibssGetAllReportTemplate: action.payload,
      };
    case NIBSS_ALL_REPORT_RESET:
      return {
        nibssGetAllReportTemplate: null,
        loading: false,
        error: null,
      };
    case NIBSS_ALL_REPORT_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
export const nibssGetFilteredReportReducer = (
  state = { nibssGetFilteredReportTemplate: [] },
  action
) => {
  switch (action.type) {
    case NIBSS_FILTER_REPORT_REQUEST:
      return { ...state, loading: true };
    case NIBSS_FILTER_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        nibssGetFilteredReportTemplate: action.payload,
      };
    case NIBSS_FILTER_REPORT_RESET:
      return {
        nibssGetFilteredReportTemplate: null,
        loading: false,
        error: null,
      };
    case NIBSS_FILTER_REPORT_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export const nibssGetFilteredTransReducer = (
  state = { nibssGetFilteredTransTemplate: [] },
  action
) => {
  switch (action.type) {
    case NIBSS_OUTFLOW_DATA_REQUEST_FILTER:
      return { ...state, loading: true };
    case NIBSS_OUTFLOW_DATA_SUCCESS_FILTER:
      return {
        ...state,
        loading: false,
        nibssGetFilteredTransTemplate: action.payload,
      };
    case NIBSS_OUTFLOW_DATA_RESET_FILTER:
      return {
        nibssGetFilteredTransTemplate: null,
        loading: false,
        error: null,
      };
    case NIBSS_OUTFLOW_DATA_FAIL_FILTER:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export const nibssInflowFilteredTransReducer = (
  state = { nibssInflowFilteredTransTemplate: [] },
  action
) => {
  switch (action.type) {
    case NIBSS_INFLOW_DATA_REQUEST_FILTER:
      return { ...state, loading: true };
    case NIBSS_INFLOW_DATA_SUCCESS_FILTER:
      return {
        ...state,
        loading: false,
        nibssInflowFilteredTransTemplate: action.payload,
      };
    case NIBSS_INFLOW_DATA_RESET_FILTER:
      return {
        nibssInflowFilteredTransTemplate: null,
        loading: false,
        error: null,
      };
    case NIBSS_INFLOW_DATA_FAIL_FILTER:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
export const nibssInflowFilteredTransReducerDownload = (
  state = { nibssInflowFilteredDownload: [] },
  action
) => {
  switch (action.type) {
    case NIBSS_INFLOW_DATA_REQUEST_FILTER_DOWNLOAD:
      return { ...state, loading: true };
    case NIBSS_INFLOW_DATA_SUCCESS_FILTER_DOWNLOAD:
      return {
        ...state,
        loading: false,
        nibssInflowFilteredDownload: action.payload,
      };
    case NIBSS_INFLOW_DATA_RESET_FILTER_DOWNLOAD:
      return {
        nibssInflowFilteredDownload: null,
        loading: false,
        error: null,
      };
    case NIBSS_INFLOW_DATA_FAIL_FILTER_DOWNLOAD:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
