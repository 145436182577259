import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { Input, Select, FormControl } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import {
  FormClass,
  FormElements,
  ActionButton,
} from "../../CustomerInformation/styles";
import {
  accountTransfer,
  forClosureLoan,
  getLoanDisburseDetails,
  handleLoanTransactions,
} from "../../../../../actions/SavingsAndLoanActions";
import { formatDate } from "../../../../../util/FormatDate.js";
import { formatCurrency, formatAmount } from "../../../../../util/FormatAmount";

const LoanForClosureModal = ({ onHide, id, history }) => {
  const dispatch = useDispatch();
  const savingsAndLoan = useSelector((state) => state.savingsAndLoan);

  let {
    loanAccountDisburseDetails,
    loanDetails: { linkedAccount, clientId, clientOfficeId, id: loanId, currency },
  } = savingsAndLoan || {};

  let {
    amount,
    date = [],
    interestPortion,
    feeChargesPortion,
    penaltyChargesPortion,
    principalPortion,
    // paymentTypeOptions = [],
  } = loanAccountDisburseDetails || {};

  //STATE HANDLER FOR DEPOSIT INFO
  const [transactionDate, setTransactionDate] = useState(new Date());

  const [enteredAmount, setAmount] = useState(0);
  const [enteredIntereset, setInterest] = useState(0);
  const [enteredPrincipal, setPrincipal] = useState(0);
  const [enteredFee, setFee] = useState(0);
  const [enteredPenalty, setPenalty] = useState(0);
  const [note, setNote] = useState("");
  const [modalState, setModalState] = useState(1);
  const [paymentType, setPaymentType] = useState("");

  let formatTransactionDate = "";
  if (transactionDate !== "") {
    formatTransactionDate = formatDate(transactionDate, true);
  }

  useEffect(() => {
    let datetoSend = formatDate(transactionDate, true);
    dispatch(
      getLoanDisburseDetails(
        id,
        `foreclosure&dateFormat=dd+MMM+yyyy&locale=en&transactionDate=${datetoSend}`
      )
    );
  }, [id, transactionDate]);

  useEffect(() => {
    if (
      !!amount ||
      !!interestPortion ||
      !!principalPortion ||
      !!feeChargesPortion ||
      !!penaltyChargesPortion
    ) {
      setAmount(amount);
      setInterest(interestPortion);
      setPrincipal(principalPortion);
      setFee(feeChargesPortion);
      setPenalty(penaltyChargesPortion);
    }
  }, [
    principalPortion,
    amount,
    interestPortion,
    feeChargesPortion,
    penaltyChargesPortion,
  ]);

  const handleSubmitForClosure = () => {
    let disbursementData = {
      locale: "en",
      dateFormat: "dd MMM yyy",
      note,
      transactionDate: formatTransactionDate,
      transactionAmount: enteredAmount,
    };

    let disbursmentData2 = {
      dateFormat: "dd MMMM yyyy",
      locale: "en",
      transactionDate: formatTransactionDate,
      note,
    };

    paymentType === "linked"
      ? dispatch(forClosureLoan(id, disbursmentData2))
      : dispatch(
          handleLoanTransactions(id, disbursementData, "repayment", history)
        );

    onHide();
  };

  // {
  //   "dateFormat": "dd MMMM yyyy",
  //   "locale": "en",
  //   "transactionDate": "30 May 2023",
  //   "note": "Linked account foreclose"
  // }

  return (
    <>
      <Modal.Header>
        <Modal.Title>Loan Foreclosure</Modal.Title>
      </Modal.Header>
      <Modal.Body className="wc-modal">
        {modalState === 1 && (
          <div className="d-flex align-items-center justify-content-center flex-direction-coloumn">
            <FormClass style={{ width: "90%" }}>
              <FormElements style={{ width: "100%" }}>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Payment Type</label>
                  <Select
                    placeholder="Select Payment Type"
                    size="md"
                    name="paymentType"
                    value={paymentType}
                    onChange={(e) => setPaymentType(e.target.value)}
                  >
                    {/* <option value="cash" id="cash">
                      Cash (Over the counter)
                    </option> */}
                    <option value="linked" id="linked">
                      Linked Savings account (debit a savings account){" "}
                    </option>
                  </Select>
                </div>
                {(paymentType === "linked" || paymentType === "cash") && (
                  <>
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-label">
                        Transaction Date<span style={{ color: "red" }}>*</span>
                      </label>
                      <FormControl style={{ width: "60%" }}>
                        <div className="datepicker-100-width">
                          <DatePicker
                            id="published-date"
                            selected={transactionDate}
                            onChange={(date) => setTransactionDate(date)}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            placeholderText="dd/mmm/yyyy"
                          />
                        </div>
                      </FormControl>
                    </div>
                    {paymentType === "linked" && (
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label">Account to Debit</label>
                        <Input
                          style={{ width: "60%", fontSize: "12px" }}
                          name="account"
                          value={linkedAccount?.accountNo}
                          size="md"
                          disabled
                        />
                      </div>
                    )}
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-label">Account Balance</label>
                      <Input
                        style={{ width: "60%", fontSize: "12px" }}
                        name="account"
                        value={formatCurrency(
                          Number(linkedAccount?.accountBalance)
                        )}
                        size="md"
                        disabled
                      />
                    </div>
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-label">Principal</label>
                      <Input
                        style={{ width: "60%" }}
                        name="enteredPrincipal"
                        value={formatCurrency(Number(enteredPrincipal))}
                        onChange={(e) => setPrincipal(e.target.value)}
                        size="md"
                        disabled
                      />
                    </div>
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-label">Interest</label>
                      <Input
                        style={{ width: "60%" }}
                        name="enteredIntereset"
                        value={formatCurrency(Number(enteredIntereset))}
                        onChange={(e) => setInterest(e.target.value)}
                        size="md"
                        disabled
                      />
                    </div>
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-label">Fee Amount</label>
                      <Input
                        style={{ width: "60%" }}
                        name="enteredIntereset"
                        value={formatCurrency(Number(enteredFee))}
                        onChange={(e) => setFee(e.target.value)}
                        size="md"
                        disabled
                      />
                    </div>
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-label">Penalty Amount</label>
                      <Input
                        style={{ width: "60%" }}
                        name="enteredIntereset"
                        value={formatCurrency(Number(enteredPenalty))}
                        onChange={(e) => setPenalty(e.target.value)}
                        size="md"
                        disabled
                      />
                    </div>

                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-label">
                        Transaction Amount
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <Input
                        style={{ width: "60%" }}
                        name="enteredAmount"
                        value={formatCurrency(Number(enteredAmount))}
                        onChange={(e) => setAmount(e.target.value)}
                        size="md"
                        disabled
                      />
                    </div>

                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-label">
                        Additional Note<span style={{ color: "red" }}>*</span>
                      </label>
                      <Input
                        style={{ width: "60%" }}
                        name="note"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        size="md"
                      />
                    </div>
                  </>
                )}
              </FormElements>
            </FormClass>
          </div>
        )}
        {modalState === 2 && (
          <div className="d-flex align-items-center justify-content-center flex-direction-coloumn">
            <FormClass style={{ width: "90%" }}>
              <FormElements style={{ width: "100%" }}>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Repayment Type</label>
                  <p className="popup-details">
                    {paymentType === "linked"
                      ? "Linked Savings account (debit a savings account)"
                      : "Cash (Over the counter)"}
                  </p>
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Transaction Date</label>
                  <p className="popup-details">{formatTransactionDate}</p>
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Principal</label>
                  <p className="popup-details">
                    {" "}
                    {formatAmount(Number(enteredPrincipal))}
                  </p>
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Interest Amount</label>
                  <p className="popup-details">
                    {currency?.code}{" "}{formatAmount(Number(enteredIntereset))}
                  </p>
                </div>

                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Fee Amount</label>
                  <p className="popup-details">
                    {currency?.code}{" "}{formatAmount(Number(enteredFee))}
                  </p>
                </div>

                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Penalty Amount</label>
                  <p className="popup-details">
                    {currency?.code}{" "}{formatAmount(Number(enteredPenalty))}
                  </p>
                </div>

                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Transaction Amount</label>
                  <p className="popup-details">
                    {currency?.code}{" "}{formatAmount(Number(enteredAmount))}
                  </p>
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Account Balance</label>
                  <p className="popup-details">
                    {formatAmount(Number(linkedAccount?.accountBalance))}
                  </p>
                </div>
                {paymentType === "linked" && (
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Account to Debit</label>
                    <p className="popup-details">{linkedAccount?.accountNo}</p>
                  </div>
                )}

                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Additional Note</label>
                  <p className="popup-details">{note}</p>
                </div>
              </FormElements>
            </FormClass>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-1">
          <ActionButton
            color="#2C1DFF"
            onClick={() => {
              return modalState === 1 ? onHide() : setModalState(1);
            }}
          >
            <img
              src={
                require("../../../../../assets/images/icons/cancel.svg").default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton
            onClick={() => {
              return modalState === 1
                ? setModalState(2)
                : handleSubmitForClosure();
            }}
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
          >
            <img
              src={
                require("../../../../../assets/images/icons/save.svg").default
              }
              alt="submit icon"
            />
            {modalState === 1 ? "Next" : "Submit"}
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default LoanForClosureModal;
