import React, { useState, useEffect } from "react";
import { Input, Select } from "@chakra-ui/react";
import {
  ActionButton,
  FormElements,
  FormClass,
  RoleButton,
  RoleContainer,
  // IconContainer,
} from "../styles";
import { Modal } from "react-bootstrap";
import { getOfficeStaffs } from "../../../../../actions/configurationActions";

import { SET_LOADING_STATE } from "../../../../../constants/utilityConstants";
import { useSelector, useDispatch } from "react-redux";

const ViewUser = ({ onHide, user, template }) => {
  const dispatch = useDispatch();

  const [userr] = useState({
    firstname: user ? user.firstname : "",
    lastname: user ? user.lastname : "",
    username: user ? user.username : "",
    email: user ? user.email : "",
    officeId: user ? String(user.officeId) : "",
    staffId: user ? (user.staff ? String(user.staff.id) : "") : "",
  });
  let { firstname, lastname, username, email, officeId, staffId } = userr;
  let oldRole = user ? user.selectedRoles : [];
  const [selectedRole] = useState(oldRole ? oldRole : []);

  const officeStaffs = useSelector((state) => state.officeStaffs);

  const { loading: staffsLoading, staffs } = officeStaffs;
  useEffect(() => {
    if (officeId) dispatch(getOfficeStaffs(officeId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    dispatch({ type: SET_LOADING_STATE, payload: staffsLoading });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffsLoading]);

  return (
    <>
      <Modal.Header>
        <Modal.Title>View User</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <FormElements style={{ width: "100%" }}>
            <div className="row h-100">
              <div className="col-sm-12 col-md-12">
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">First Name*</label>
                  <Input
                    style={{ width: "60%" }}
                    size="md"
                    name="firstname"
                    value={firstname}
                    readOnly
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-12">
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Last Name*</label>
                  <Input
                    style={{ width: "60%" }}
                    size="md"
                    name="lastname"
                    value={lastname}
                    readOnly
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-12">
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Username*</label>
                  <Input
                    style={{ width: "60%" }}
                    size="md"
                    name="username"
                    value={username}
                    readOnly
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-12">
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Email Address*</label>
                  <Input
                    style={{ width: "60%" }}
                    size="md"
                    name="email"
                    value={email}
                    readOnly
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-12">
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Office*</label>
                  <Select
                    placeholder="Select Office"
                    size="md"
                    name="officeId"
                    value={officeId}
                    disabled
                  >
                    {template && template.allowedOffices
                      ? template.allowedOffices.map((off, i) => (
                          <option value={off.id} key={i}>
                            {off.name}
                          </option>
                        ))
                      : null}
                  </Select>
                </div>
              </div>
              <div className="col-sm-12 col-md-12">
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Staff</label>
                  <Select
                    placeholder="Select Staff from selected office"
                    size="md"
                    name="staffId"
                    value={staffId}
                    disabled
                  >
                    {staffs && staffs.length > 0
                      ? staffs.map((staff) => (
                          <option value={staff.id} key={staff.id}>
                            {staff.displayName}
                          </option>
                        ))
                      : null}
                  </Select>
                </div>
              </div>
              <div className="form-group w-100 row m-0">
                <label className="text-muted mb-3">
                  Select available roles for user
                </label>
                <div className="col-md-2 col-xs-12 col-sm-12"></div>
                <div className="col-md-10 justify-content-sm-center col-sm-12 col-xs-12">
                  {/* <div className="ml-md-2">
                    {template && template.availableRoles
                      ? template.availableRoles //id && name
                          .map((item, key) => (
                            <RoleButton
                              onClick={() =>
                                selectedRole.some((val) => val.id === item.id)
                                  ? setSelectedRole(
                                      selectedRole.filter(
                                        (val) => val.id !== item.id
                                      )
                                    )
                                  : setSelectedRole((prev) => [...prev, item])
                              }
                              color={
                                selectedRole.some((val) => val.id === item.id)
                                  ? "#01B1FC"
                                  : "#68d0fd"
                              }
                              textColor="#002C79"
                              key={key}
                              className="form-control mb-2"
                            >
                              {item.name}
                            </RoleButton>
                          ))
                      : null}
                  </div> */}

                  <RoleContainer className="ml-md-3 w-100 mx-sm-2">
                    {selectedRole.map((item, key) => (
                      <RoleButton
                        textColor="#002C79"
                        key={key}
                        color="#e6f7ff"
                        className="form-control mb-2"
                      >
                        {item.name}
                      </RoleButton>
                    ))}
                  </RoleContainer>
                </div>
              </div>
            </div>
          </FormElements>

          <div className="float-right mt-4">
            <ActionButton color="#2C1DFF" onClick={onHide}>
              <img
                src={
                  require("../../../../../assets/images/icons/cancel.svg")
                    .default
                }
                alt="cancel icon"
              />
              Cancel
            </ActionButton>
            {/* <ActionButton color="#2C1DFF" style={{ margin: "0px 0px 0 10px" }}>
              <img
                src={
                  require("../../../../../assets/images/icons/save.svg").default
                }
                alt="submit icon"
              />
              Submit
            </ActionButton> */}
          </div>
        </FormClass>
      </Modal.Body>
    </>
  );
};

export default ViewUser;
