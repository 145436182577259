import React, { useState, useEffect } from "react";
// import { useSelector } from "react-redux";
import {
  FormControl,
  Checkbox,
  Input,
  Textarea,
  Select,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
// import { Table, Modal } from "react-bootstrap";
// import ModalContainer from "../../../../../../components/ModalContainer";

import {
  FormClass,
  FormElements,
  NextActionButton,
  IconButton,
} from "../../../styles.jsx";
import CurrencyFormat from "react-currency-format";

const FixedInterestTemp = ({
  validFrom,
  handleNextScreen,
  handlePreviousScreen,
  handleValidFrom,
  endDate,
  handleEndDate,
  renderTooltip,
  template,
  setChartSlabs,
  chartSlabs,
  createFixedDepositProductHandler,
}) => {
  let defaultData = {
    // amountRangeFrom: "",
    annualInterestRate: "",
    // description: "",
    locale: "en",
    periodType: "1",
    fromPeriod: "1",
  };

  //   const [isPrimary, setIsPrimary] = useState(false);
  //   const [amount, setAmount] = useState("");
  const [interests, setInterests] = useState(defaultData);

  useEffect(() => {
    setInterests((oldChartSlabs) => ({
      ...oldChartSlabs,
      ...chartSlabs,
    }));
  }, [chartSlabs]);

  // handle periodType
  // const handleOnChangeOfPeriodType = (value) => {
  //   setInterests((oldInterest) => {
  //     let newInterest = { ...oldInterest };
  //     newInterest.periodType = value;

  //     return newInterest;
  //   });
  // };

  // Handle starting period / periodFrom
  // const handleOnChangeOfFromPeriod = (value) => {
  //   setInterests((oldInterest) => {
  //     let newInterest = {...oldInterest };
  //     newInterest.fromPeriod = value;

  //     return newInterest;
  //   });
  // };

  //   // Handle amount as amountRangeFrom
  const handleOnChangeOfAmountRangeFrom = (value) => {
    interests.amountRangeFrom = value;
    setInterests(interests);
  };

  //   //   handle interest change
  const handleOnChangeOfInterest = (value) => {
    setInterests((oldInterests) => ({
      ...oldInterests,
      annualInterestRate: value,
    }));
  };

  //   //   handle description change
  const handleOnChangeOfDescription = (value) => {
    interests.description = value;
    setInterests(interests);
  };

  // const [isPrimary, setIsPrimary] = useState(false);

  return (
    <>
      <div>
        <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
          <div className="col-sm-12 col-md-6 px-0 ">
            {/* Valid from date * */}
            <FormClass>
              <FormElements>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label d-flex flex-align-center">
                    Valid from date *
                  </label>
                  <FormControl style={{ width: "60%" }}>
                    <div className="datepicker-100-width">
                      <DatePicker
                        id="start-date"
                        selected={validFrom}
                        onChange={(date) => handleValidFrom(date)}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="dd/mmm/yyyy"
                      />
                    </div>
                  </FormControl>
                </div>
              </FormElements>
            </FormClass>
          </div>

          <div className="col-sm-12 col-md-6 px-0">
            {/* End date  */}
            <FormClass>
              <FormElements>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label d-flex flex-align-center">
                    End date
                  </label>
                  <FormControl style={{ width: "60%" }}>
                    <div className="datepicker-100-width">
                      <DatePicker
                        id="close-date"
                        selected={endDate}
                        onChange={(date) => handleEndDate(date)}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="dd/mmm/yyyy"
                      />
                    </div>
                  </FormControl>
                </div>
              </FormElements>
            </FormClass>
          </div>

          <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
            {/* Interest */}
            <div className="col-sm-12 col-md-6 px-0 ">
              <FormClass>
                <FormElements>
                  {/* {interests.map(interest => { */}
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label d-flex flex-align-center">
                      Interest* {renderTooltip("Interest")}
                    </label>

                    <CurrencyFormat
                      value={interests.annualInterestRate}
                      name="interest"
                      thousandSeparator={true}
                      className="amount-input"
                      style={{
                        width: "62%",
                        // borderColor:
                        //   !depositAmount && termsError ? "red" : "inherit",
                      }}
                      // onChange={createLoanProductHandler}
                      onChange={(e) => handleOnChangeOfInterest(e.target.value)}
                    />
                  </div>
                  {/* })} */}
                </FormElements>
              </FormClass>
            </div>

            {/* FromPeriod */}
            {/* <div className="col-sm-12 col-md-6 px-0 ">
              <FormClass>
                <FormElements>
                  {/* {interests.map(interest => { 
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label d-flex flex-align-center">
                      fromPeriod* {renderTooltip("Interest")}
                    </label>
                    <input
                      // disabled
                      value={interests.fromPeriod}
                      className="amount-input"
                      size="md"
                      name="fromPeriod"
                      style={{
                        width: "60%",
                      }}
                      // onChange={createLoanProductHandler}
                      onChange={(e) =>
                        handleOnChangeOfFromPeriod(e.target.value)
                      }
                    />
                  </div>
                  {/* })} 
                </FormElements>
              </FormClass>
            </div> */}
          </div>
        </div>
      </div>

      {/* Next and previous */}
      <div>
        <div className="float-right pt-5">
          <NextActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={() => {
              setChartSlabs(interests);
              handlePreviousScreen();
            }}
          >
            <img
              src={
                require("../../../../../../../../assets/images/icons/arrow-left.svg")
                  .default
              }
              alt="cancel icon"
            />
            Previous
          </NextActionButton>
          <NextActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={() => {
              setChartSlabs(interests);
              handleNextScreen();
            }}
          >
            <img
              src={
                require("../../../../../../../../assets/images/icons/arrow-right.svg")
                  .default
              }
              alt="submit icon"
            />{" "}
            Next
          </NextActionButton>
        </div>
      </div>
    </>
  );
};

export default FixedInterestTemp;
