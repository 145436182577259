import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Table } from "antd";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ActionButton, IconButton, NewActionButton } from "../styles";
import { formatCurrency } from "../../../../../util/FormatAmount";

import ModalContainer from "../../../components/ModalContainer";
// import LoanRepayment from "../components/loans/LoanRepayment";
import LoanRepaymentModal from "../../CusomerLoanDetails/LoanRepaymentModal";
import DisburseLoanModal from "../../CusomerLoanDetails/DisburseLoanModal";
import ApproveLoan from "../components/loans/ApproveLoan";

// import { getloanDetaillsReset } from "../../../../../actions/customerActions";

import CusomerLoanDetails from "../../CusomerLoanDetails";
import CheckPermission from "../../../../../util/CheckPermission";
import { useLocation } from "react-router-dom";
import ViewGuarantor from "../../CusomerLoanDetails/ViewGuarantor";
import ViewCollateral from "../../CusomerLoanDetails/ViewCollateral";
// import RejectLoan from "../components/loans/RejectLoan";
import RejectLoanModal from "../../CusomerLoanDetails/RejectLoanModal";

const LoansTab = ({ userData, history, filter, currentLoanId ,  setCurrentTab , currentTab }) => {
  // const dispatch = useDispatch();
  const accountsList = useSelector((state) => state.customerAccounts);

  const [loanId, setLoanId] = useState("");
  const [userId, setUserId] = useState();


  useEffect(() => {
    userData?.id && setUserId(userData?.id);
  }, [userData?.id]);

  

  // const [lastLoanAmount, setLastLoanAmount] = useState("");

  const userDataRes = useSelector((state) => state.preAuth);
  const { search, pathname } = useLocation();
  const {
    responseUserData: { permissions },
  } = userDataRes;
  const [loanAccountNo, setLoanAccountNo] = useState("");

  // const {search} = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    // console.log(queryParams, 'QUERY PARAMAS');
    const currentIdQuery = queryParams.get("currentId");
    const curLoanId = currentLoanId;
    if (currentIdQuery) {
      setCurrentView(2);
      setCurrentId(currentIdQuery);
    } else if (curLoanId) {
      setCurrentView(2);
      setCurrentId(curLoanId);
    }
  }, [currentLoanId]);

  useEffect(() => {
    let searchParams = new URLSearchParams(search.replace("?", ""));
    setLoanAccountNo(searchParams.get("accountNo") ?? "");
  }, []);

  const [modalState, toggleModal] = useState(false);

  const [modalContentName, setModalContentName] = useState("");

  const currentContent = (e, loanId) => {
    const val = e.currentTarget.id.trim();
    setLoanId(loanId);

    if (val) {
      toggleModal(true);
      setModalContentName(val);
    }
  };

  const handleModalClose = () => toggleModal(false);

  const displayModalContent = () => {
    switch (modalContentName) {
      case "loanRepayment":
        return (
          <LoanRepaymentModal
            history={history}
            id={loanId}
            onHide={handleModalClose}
          />
        );
      case "approveLoan":
        return (
          <ApproveLoan
            userId={userId}
            transId={loanId}
            onHide={handleModalClose}
            history={history}
            />
          );
      case "rejectLoan":
        return (
          <RejectLoanModal
            userId={userId}
            id={loanId}
            onHide={handleModalClose}
            history={history}
          />
        );
      case "disburseLoan":
        return <DisburseLoanModal transId={loanId} onHide={handleModalClose} />;
      default:
        return;
    }
  };

  const columns = [
    { title: "Account No", dataIndex: "accNo", key: "accNo" },
    { title: "Loan Account Type", dataIndex: "accType", key: "accType" },
    { title: "Original Loan", dataIndex: "originalLoan", key: "originalLoan" },
    { title: "Loan Balance", dataIndex: "balance", key: "balance" },
    { title: "Amount Paid", dataIndex: "amount", key: "amount" },
    { title: "Status", dataIndex: "status", key: "status" },
    { title: "Action", dataIndex: "action", key: "action" },
  ];

  let loanAccount = [];

  accountsList.accounts &&
    accountsList.accounts.loanAccounts &&
    accountsList.accounts.loanAccounts.forEach((account) => {
      // console.log(filter, '*****')
      let {
        id,
        accountNo,
        productName,
        originalLoan,
        loanBalance,
        amountPaid,
        status: { pendingApproval },
      } = account;
      // console.log(filter === 'accountNo')
      if (
        filter.toLowerCase() === "accountno" &&
        loanAccountNo &&
        loanAccountNo !== accountNo
      ) {
        return;
      }

      loanAccount.push({
        key: id,
        accNo: accountNo,
        accType: productName,
        originalLoan: !!originalLoan && formatCurrency(originalLoan),
        balance: !!loanBalance && formatCurrency(loanBalance),
        amount: !!amountPaid && formatCurrency(amountPaid),
        status: (
          <>
            {account.status.active ? (
              <span className="new-text-success">
                {" "}
                {(account.status && account.status.value) || ""}
              </span>
            ) : (
              <span className="text-danger">
                {/* {(account.status && account.status.value) || ""} */}
              </span>
            )}
            {account.status.id === 100 ? (
              <>
                {" "}
                <span className="new-text-pending">
                  {" "}
                  {account.status &&
                  account.status.value.toLowerCase() ===
                    "submitted and pending approval"
                    ? "Pending"
                    : (account.status && account.status.value) || ""}
                </span>{" "}
              </>
            ) : (
              ""
            )}
            {account.status.id === 200 ? (
              <>
                {" "}
                <span className="new-text-info">
                  {" "}
                  {(account.status && account.status.value) || ""}
                </span>{" "}
              </>
            ) : (
              ""
            )}
            {account.status.id === 600 ||
            account.status.id === 500 ||
            account.status.id === 400 ? (
              <>
                <span className="new-text-danger">
                  {" "}
                  {(account.status && account.status.value) || ""}
                </span>
              </>
            ) : (
              ""
            )}
          </>
        ),
        action: (
          <>
            {account.status.id === 100 ? (
              <>
                {CheckPermission("APPROVE_LOAN", permissions) && (
                  <RenderTooltip text="Approve" id="approve">
                    <IconButton
                      color="#2C1DFF"
                      id="approveLoan"
                      onClick={(e) => {
                        e.stopPropagation();
                        currentContent(e, account.id);
                      }}
                    >
                      <img
                        src={
                          require("../../../../../assets/images/icons/approve.svg")
                            .default
                        }
                        alt="approve loan"
                      />
                    </IconButton>
                  </RenderTooltip>
                )}
                {CheckPermission("REJECT_LOAN", permissions) && (
                  <RenderTooltip text="Reject" id="reject">
                    <IconButton
                      color="#2C1DFF"
                      id="rejectLoan"
                      onClick={(e) => {
                        e.stopPropagation();
                        currentContent(e, account.id);
                      }}
                    >
                      <img
                        src={
                          require("../../../../../assets/images/icons/cancel.svg")
                            .default
                        }
                        alt="reject loan"
                      />
                    </IconButton>
                  </RenderTooltip>
                )}
              </>
            ) : account.status.id === 300 &&
              CheckPermission("REPAYMENT_LOAN", permissions) ? (
              <RenderTooltip text="Make repayment" id="make-repayment">
                <IconButton
                  color="#2C1DFF"
                  id="loanRepayment"
                  onClick={(e) => {
                    e.stopPropagation();
                    currentContent(e, account.id);
                  }}
                >
                  <img
                    src={
                      require("../../../../../assets/images/icons/make-repayment.svg")
                        .default
                    }
                    alt="make loan repayment"
                    className="icon-img"
                  />
                </IconButton>
              </RenderTooltip>
            ) : account.status.id === 200 &&
              CheckPermission("DISBURSE_LOAN", permissions) ? (
              <RenderTooltip text="Disburse" id="disburse">
                <IconButton
                  color="#2C1DFF"
                  id="disburseLoan"
                  onClick={(e) => {
                    e.stopPropagation();
                    currentContent(e, account.id);
                  }}
                >
                  <img
                    src={
                      require("../../../../../assets/images/icons/disburse.svg")
                        .default
                    }
                    alt="disburse loan"
                  />
                </IconButton>
              </RenderTooltip>
            ) : (
              <></>
            )}
          </>
        ),
      });
    });

  const [currentView, setCurrentView] = useState(1);
  const [currentId, setCurrentId] = useState();

  return (
    <>
      <ModalContainer show={modalState} dialogClassName="document_infoModal">
        {modalState && displayModalContent()}
      </ModalContainer>
      {currentView === 1 ? (
        <div className="container pt-4">
          <div className="row">
            <div className="col-sm-12 col-md-4">
              <h3 className="page-title">Loans</h3>
            </div>
            {/* <div className="col-sm-12 col-md-8 d-flex flex-wrap justify-content-between"> */}
            <div className="col-sm-12 col-md-8 spaceBetweenFlexEnd">
              {/* const link = "configuration/organization/loan-calculator", */}
              {/* <Link to="configuration/organization/loan-calculator"> */}
              <Link
                to={
                  userData &&
                  `/core/configuration/organization/loan-calculator/`
                }
              >
                <NewActionButton>
                  <img
                    style={{ width: "25px" }}
                    src={
                      require("../../../../../assets/images/icons/history.svg")
                        .default
                    }
                    className="mr-2"
                    alt="loan"
                  />{" "}
                  Loan Calculator
                </NewActionButton>
              </Link>

              {CheckPermission("CREATE_LOAN", permissions) && (
                <Link to={userData && `/core/loan-application/${userId}`}>
                  <ActionButton color="#2C1DFF">
                    <img
                      src={
                        require("../../../../../assets/images/icons/add.svg")
                          .default
                      }
                      className="mr-2"
                      alt="add"
                    />{" "}
                    New Loan
                  </ActionButton>
                </Link>
              )}
            </div>
          </div>

          <div className="mt-5">
            <Table
              style={{
                overflow: "hidden",
                overflowX: "scroll",
              }}
              columns={columns}
              dataSource={loanAccount}
              pagination={{ defaultPageSize: 5 }}
              defaultPageSize={5}
              onRow={(r) => ({
                onClick: () => {
                  setCurrentView(2);
                  setCurrentId(r.key);
                },
              })}
            />
          </div>
        </div>
      ) : currentView === 2 ? (
        <CusomerLoanDetails
          currentId={currentId}
          history={history}
          setCurrentView={setCurrentView}
          currentTab={currentTab}
        />
      ) : currentView === 3 ? (
        <ViewGuarantor
          currentId={currentId}
          history={history}
          setCurrentView={setCurrentView}
        />
      ) : currentView === 4 && (
        <ViewCollateral
          currentId={currentId}
          setCurrentView={setCurrentView}
        />
      )}
    </>
  );
};

const RenderTooltip = ({ text, id, children }) => (
  <>
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={`tooltip-${id}`}>{text}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  </>
);
export default LoansTab;
