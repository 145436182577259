import ContentLoader from "react-content-loader";
import { RoleContainer } from "../styles";

const DashboardLoader = () => {
  return (
    <div className="h-100 container">
      <div className="form-group row m-0" style={{ paddingTop: "100px" }}>
        <div className="col-md-2">
          <ContentLoader
            speed={2}
            width={200}
            height={100}
            viewBox="0 0 400 100"
            backgroundColor="#ffffff"
            foregroundColor="#ecebeb"
          >
            <rect x="2" y="0" rx="3" ry="10" width="200" height="20" />
          </ContentLoader>
        </div>
        <div className="col-md-10 justify-content-sm-center">
          <div className="ml-md-2 row">
            {[
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Associate",
              "Saturday",
              "Sunday",
            ].map((item) => (
              <div className="col-md-4" key={item}>
                <ContentLoader
                  speed={2}
                  width={250}
                  height={100}
                  viewBox="0 0 400 100"
                  backgroundColor="#ffffff"
                  foregroundColor="#ecebeb"
                >
                  <rect x="2" y="0" rx="1" ry="1" width="300" height="80" />
                </ContentLoader>
              </div>
            ))}
          </div>
          <RoleContainer className="ml-md-3 mx-sm-2">
            <ContentLoader
              speed={2}
              width={250}
              height={100}
              viewBox="0 0 400 100"
              backgroundColor="#ffffff"
              foregroundColor="#ecebeb"
            >
              <rect x="2" y="0" rx="1" ry="1" width="300" height="80" />
            </ContentLoader>
          </RoleContainer>
        </div>
      </div>

      <div className="row h-100" style={{ marginTop: 50 }}>
        <div className="col-sm-12 col-md-6">
          <div className="form-group d-flex justify-content-between align-items-center">
            <ContentLoader
              speed={2}
              // width={100}
              // height={300}
              viewBox="0 0 400 100"
              backgroundColor="#ffffff"
              foregroundColor="#ecebeb"
            >
              <rect x="2" y="0" rx="3" ry="10" width="609" height="300" />
            </ContentLoader>
          </div>
        </div>
        <div className="col-sm-12 col-md-6">
          <div className="form-group d-flex justify-content-between align-items-center">
            <ContentLoader
              speed={2}
              // width={100}
              // height={300}
              viewBox="0 0 400 100"
              backgroundColor="#ffffff"
              foregroundColor="#ecebeb"
            >
              <rect x="2" y="0" rx="3" ry="10" width="609" height="300" />
            </ContentLoader>
          </div>
        </div>
      </div>

      <br />
      <br />
    </div>
  );
};
export default DashboardLoader;
