/**
 * TIPS
 * Read up some react tips for improving performance
 * https://www.freecodecamp.org/news/how-to-work-with-react-the-right-way-to-avoid-some-common-pitfalls-fc9eb5e34d9e/
 */
import axios from "axios";
import AuthModule from "../modules/Auth.module";
import config from "../config";

import { logoutUser2 } from "../actions/authActions";
// import AtlasSecurityAuditor from '@woodcore-hq/atlas_security_auditor';


// const security = new AtlasSecurityAuditor();
// security.requestInterceptor(axios);
// security.responseInterceptor(axios, AuthModule, true);

/**
 * Helper for calling axios services
 */
async function AxiosData({
  url,
  method,
  data,
  signal,
  params,
  headers,
  responseType = null,
  isBearerToken = true,
}) {
  try {
    const result = await axios({
      method,
      url,
      ...(responseType && { responseType }),
      ...(params && { params }),
      ...(signal && { cancelToken: signal }),
      ...(data && { data }),
      headers: {
        Accept: "application/json,  text/plain, */*",
        "Content-Type": "application/json;charset=UTF-8",
        "X-ZINGER": "zingerzinger",
        WoodCoreTenant: `${config.apiGateway.IS_PRODUCTION === `true`
          ? AuthModule.getTenant()
          : config.apiGateway.WOODCORE_TENANT
          }`,
        crossdomain: true,
        ...(AuthModule.getToken() &&
          isBearerToken && {
          Authorization: `Bearer ${AuthModule.getToken()}`,
        }),
        ...headers,
      },
    });
    return { data: result.data };
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Error: ", "Cancelling axios");
    }
    if (
      error &&
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      // Logout User
      logoutUser2();
    }
    return { error };
  }
}

export default AxiosData;
