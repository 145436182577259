import React, { useState, useEffect } from "react";
import {
  ContentWrapper,
  ActionButtonsGroup,
  ActionButton,
  NewActionButton,
  FormElements,
  FormClass,
} from "./styles";
import { Input, Select } from "@chakra-ui/react";
import { Modal, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import ModalContainer from "../../components/ModalContainer";
import { useDispatch, useSelector } from "react-redux";
import CheckPermission from "../../../../util/CheckPermission";
import ContentLoader from "react-content-loader";
import {
  getAllCurrency,
  createNewCurrency,
} from "../../../../actions/configurationActions";
import DeleteCurrency from "./components/DeleteCurrency";
const CurrencyConfigurationView = () => {
  const dispatch = useDispatch();
  const [modalState, toggleModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [codeToDelete, setCodeToDelete] = useState(null);
  const [modalContent, setModalContent] = useState(false);
  const [newCurrency, setNewCurrency] = useState({
    currencyName: "",
    // currencyIso: "",
  });

  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  const { currencyName } = newCurrency;

  const currencyList = useSelector((state) => state.currencyReducer);

  const {
    loading,
    currencies: { selectedCurrencyOptions, currencyOptions },
  } = currencyList;

  const newCurrencyHandler = (e) =>
    setNewCurrency({ ...newCurrency, [e.target.name]: e.target.value });

  useEffect(() => {
    dispatch(getAllCurrency());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (currencyName) {
      setProcessing(true);
      const prevCurrencies = selectedCurrencyOptions.map((item) => item.code);
      const data = {
        currencies: [...prevCurrencies, currencyName],
      };
      await dispatch(createNewCurrency(data));
      setProcessing(false);
      dispatch(getAllCurrency());
      toggleModal(false);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    if (codeToDelete) {
      setProcessing(true);
      const prevCurrencies = selectedCurrencyOptions.map((item) => item.code);
      const data = {
        currencies: prevCurrencies.filter((item) => item !== codeToDelete),
      };
      await dispatch(createNewCurrency(data, true));
      setProcessing(false);
      dispatch(getAllCurrency());
      toggleModal(false);
    }
  };
  return (
    <>
      <ModalContainer show={modalState} dialogClassName="document_infoModal">
        {modalContent === "delete" ? (
          <DeleteCurrency
            handleDelete={handleDelete}
            onHide={() => toggleModal(false)}
            loading={processing}
          />
        ) : (
          <>
            <Modal.Header>
              <Modal.Title>Create Currency</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
              <FormClass style={{ width: "90%" }}>
                <FormElements style={{ width: "100%" }}>
                  <Select
                    placeholder="Select New Currency"
                    size="md"
                    name="currencyName"
                    value={currencyName}
                    onChange={newCurrencyHandler}
                  >
                    {currencyOptions
                      ? currencyOptions.map((off, i) => (
                          <option value={off.code} key={i}>
                            {off.displayLabel}
                          </option>
                        ))
                      : null}
                  </Select>
                  {/* <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Currency Name</label>
                <Input
                  style={{ width: "70%" }}
                  size="md"
                  name="currencyName"
                  value={currencyName}
                  onChange={newCurrencyHandler}
                />
              </div> */}

                  {/* <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">External Id</label>
                <Input
                  style={{ width: "70%" }}
                  size="md"
                  name="currencyIso"
                  value={currencyIso}
                  onChange={newCurrencyHandler}
                />
              </div> */}
                </FormElements>
              </FormClass>
            </Modal.Body>
            <Modal.Footer>
              <div className="float-right mt-1">
                <NewActionButton
                  color="#2C1DFF"
                  onClick={() => toggleModal(false)}
                >
                  <img
                    src={
                      require("../../../../assets/images/icons/cancel.svg")
                        .default
                    }
                    alt="cancel icon"
                  />
                  Cancel
                </NewActionButton>
                <NewActionButton
                  color="#2C1DFF"
                  style={{ margin: "0px 0px 0 10px" }}
                  onClick={handleSubmit}
                >
                  {processing ? (
                    <Spinner
                      animation="border"
                      color="#fff"
                      className="mr-2 text-white"
                      size="sm"
                    />
                  ) : (
                    <img
                      src={
                        require("../../../../assets/images/icons/save.svg")
                          .default
                      }
                      alt="submit icon"
                    />
                  )}
                  Submit
                </NewActionButton>
              </div>
            </Modal.Footer>
          </>
        )}
      </ModalContainer>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Currency Management</h3>
            <p className="sub-title">
              Currency management for various organization
            </p>
          </div>
          <div className="col-sm-12 col-md-6 px-0 pt-4">
            <ActionButtonsGroup>
              {CheckPermission("UPDATE_CURRENCY", permissions) ? (
                <ActionButton
                  onClick={() => {
                    setModalContent("new");
                    toggleModal(true);
                  }}
                >
                  <img
                    src={
                      require("../../../../assets/images/icons/add.svg").default
                    }
                    alt="add"
                  />
                  New Currency
                </ActionButton>
              ) : (
                ""
              )}
            </ActionButtonsGroup>
          </div>
        </div>
        <ContentWrapper>
          <div className="row m-0 p-0 h-100">
            <div className="col-md-1" />
            <div className="col-md-10 p-0 m-0 row">
              {loading ? (
                [1, 2, 3, 4].map((val, i) => (
                  <div className="col-md-6 row currency-container" key={i}>
                    <ContentLoader
                      speed={2}
                      width={400}
                      height={100}
                      viewBox="0 0 400 100"
                      backgroundColor="#ffffff"
                      foregroundColor="#ecebeb"
                    >
                      <rect
                        x="2"
                        y="20"
                        rx="1"
                        ry="1"
                        width="350"
                        height="40"
                      />
                    </ContentLoader>
                    <ContentLoader
                      speed={2}
                      width={400}
                      height={100}
                      viewBox="0 0 400 100"
                      backgroundColor="#ffffff"
                      foregroundColor="#ecebeb"
                    >
                      <rect
                        x="2"
                        y="0"
                        rx="3"
                        ry="10"
                        width="609"
                        height="10"
                      />
                    </ContentLoader>
                  </div>
                ))
              ) : selectedCurrencyOptions &&
                selectedCurrencyOptions.length > 0 ? (
                selectedCurrencyOptions.map(({ displayLabel, code }, i) => (
                  <div className="col-md-6 row currency-container" key={i}>
                    <Input
                      style={{ width: "80%" }}
                      size="md"
                      value={displayLabel}
                      readOnly
                      className="mb-sm-2"
                    />
                    <NewActionButton
                      onClick={() => {
                        setCodeToDelete(code);
                        setModalContent("delete");
                        toggleModal(true);
                      }}
                    >
                      <img
                        src={
                          require("../../../../assets/images/icons/delete.svg")
                            .default
                        }
                        alt="delete"
                        className="icon-img"
                      />
                      Delete
                    </NewActionButton>
                  </div>
                ))
              ) : (
                <h3 className="font-weight-bold">No Currency Found</h3>
              )}
            </div>
            <div className="col-md-1" />
          </div>
        </ContentWrapper>
      </div>
    </>
  );
};

export default CurrencyConfigurationView;
