import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Select, FormControl, Checkbox } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import CurrencyFormat from "react-currency-format";
import {
  FormClass,
  FormElements,
  ContentWrapper,
  FormTitle,
} from "../../styles.jsx";
import { Link } from "react-router-dom";
import { SET_LOADING_STATE } from "../../../../../../constants/utilityConstants";
import {
  updateSavingsAccountInfo,
  getSavingsAccount,
} from "../../../../../../actions/customerActions";
import { getSelectedSavingsProductOptions } from "../../../../../../actions/utilityActions";
import { formatDate } from "../../../../../../util/FormatDate";
import EditSavingsAccountCharges from "./EditSavingsAccountCharges.jsx";
import BackSvg from "../../../../../../assets/images/icons/back.svg";

const EditSavingsAccount = ({ match, history, id }) => {
  const [productId, setProductId] = useState("");
  const [goToSavingsTab, setGoToSavingsTab] = useState("fromSavings");

  const [minimumOpeningBalance, setMinimumOpeningBalance] = useState("");
  const [minumumBalance, setMinumumBalance] = useState("");
  const [charges, setCharges] = useState([]);
  const [overdraftLimit, setOverdraftLimit] = useState("");
  const [
    minBalanceForInterestCalculation,
    setMinBalanceForInterestCalculation,
  ] = useState("");

  const [
    nominalAnnualInterestRateOverdraft,
    setNominalAnnualInterestRateOverdraft,
  ] = useState("");

  const dispatch = useDispatch();
  const savingsAccountReducer = useSelector(
    (state) => state.savingsAccountReducer
  );
  const { productOptionsData } = useSelector(
    (state) => state.selectedOptionData
  );

  const { loading, savingsAccount } = savingsAccountReducer || {};

  let { clientId, clientName = "" } = savingsAccount || {};

  useEffect(() => {
    dispatch(getSavingsAccount(match.params.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match?.params?.id]);

  useEffect(() => {
    if (productId && clientId) {
      dispatch(getSelectedSavingsProductOptions(clientId, productId));
    }
  }, [dispatch, clientId, productId]);


  const handleProductType = (e) => {
    setProductId(e.target.value);
    setNewSavingsAccount({
      ...newSavingsAccount,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    dispatch({ type: SET_LOADING_STATE, payload: loading });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const [newSavingsAccount, setNewSavingsAccount] = useState({
    externalId: "",
    fieldOfficerId: "",
    productId: "",
    dateSubmitted: "",

    // Terms
    DecemalPlace: "",
    currency: "",
    multipleCurrency: "",
    nominalAnnualInterest: "",
    interestCompoundingPeriod: "",
    interestPostingPeriod: " ",
    interestCalculated: "",
    daysInYear: "",
    minimumOpeningBalance: "",
    lockinPeriodFreq: "",
    lockInPeriodFreqSelect: "",
    applyWithdrawalFee: false,
    enforceMinimumBalance: false,
    minumumBalance: "",
    isOverdraft: false,
    overdraftLimit: "",
  });


  useEffect(() => {
    if (productOptionsData) {
      setCharges(
        !!productOptionsData.charges ? productOptionsData.charges : []
      );
      setNewSavingsAccount((prevData) => {
        // console.log(prevData, 'previous data');
        return {
          ...prevData,
          productId: productOptionsData?.savingsProductId,
          currency: productOptionsData?.currency?.displayLabel,
          nominalAnnualInterest: productOptionsData?.nominalAnnualInterestRate,
          interestCompoundingPeriod:
            productOptionsData?.interestCompoundingPeriodType?.id,
          interestPostingPeriod:
            productOptionsData?.interestPostingPeriodType?.id,
          interestCalculated: productOptionsData?.interestCalculationType?.id,
          enforceMinimumBalance: productOptionsData
            ? productOptionsData?.enforceMinRequiredBalance
            : false,
          applyWithdrawalFee: productOptionsData
            ? productOptionsData?.withdrawalFeeForTransfers
            : false,

          minimumOpeningBalance: productOptionsData?.minRequiredOpeningBalance,
          isOverdraft: productOptionsData
            ? productOptionsData?.allowOverdraft
            : false,
          overdraftLimit: productOptionsData
            ? productOptionsData?.overdraftLimit
            : "",
        };
      });
    }
  }, [productOptionsData]);

  const [processing, setLoading] = useState(false);
  const [submittedDate, setSubmittedDate] = useState(new Date());
  // let submittedOnDate = formatDate(submittedDate);

  // console.log(submittedOnDate, 'submittedOnDate')

  const editSavingsAccountHandler = (e) => {
    setNewSavingsAccount({
      ...newSavingsAccount,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (savingsAccount) {
      setMinimumOpeningBalance(
        savingsAccount ? savingsAccount.minRequiredOpeningBalance : ""
      );
      setMinumumBalance(
        savingsAccount ? savingsAccount.minRequiredBalance : ""
      );
      setOverdraftLimit(savingsAccount ? savingsAccount.overdraftLimit : "");
      setMinBalanceForInterestCalculation(
        savingsAccount ? savingsAccount.minOverdraftForInterestCalculation : ""
      );
      setNominalAnnualInterestRateOverdraft(
        savingsAccount ? savingsAccount.nominalAnnualInterestRateOverdraft : ""
      );
      setSubmittedDate(
        savingsAccount.timeline?.submittedOnDate
          ? new Date(savingsAccount.timeline?.submittedOnDate)
          : new Date()
      );

      setNewSavingsAccount({
        nominalAnnualInterest: savingsAccount
          ? savingsAccount.nominalAnnualInterestRate
            ? savingsAccount.nominalAnnualInterestRate
            : ""
          : "",

        currency: savingsAccount
          ? savingsAccount.currency
            ? savingsAccount.currency.displayLabel
            : ""
          : "",
        externalId: savingsAccount?.externalId,
        DecimalPlace: savingsAccount
          ? savingsAccount.currency
            ? savingsAccount.currency.decimalPlaces
            : ""
          : "",

        multipleCurrency: savingsAccount
          ? savingsAccount.currency
            ? savingsAccount.currency.inMultiplesOf
            : ""
          : "",
        fieldOfficerId: savingsAccount ? savingsAccount.fieldOfficerId : "",
        productId: savingsAccount
          ? savingsAccount.savingsProductId
            ? savingsAccount.savingsProductId
            : ""
          : "",
        daysInYear: savingsAccount
          ? savingsAccount.interestCalculationDaysInYearType
            ? savingsAccount.interestCalculationDaysInYearType.id
            : ""
          : "",
        interestCompoundingPeriod: savingsAccount
          ? savingsAccount.interestCompoundingPeriodType
            ? savingsAccount.interestCompoundingPeriodType.id
            : ""
          : "",

        interestPostingPeriod: savingsAccount
          ? savingsAccount.interestPostingPeriodType
            ? savingsAccount.interestPostingPeriodType.id
            : ""
          : "",

        interestCalculated: savingsAccount
          ? savingsAccount.interestCalculationType
            ? savingsAccount.interestCalculationType.id
            : ""
          : "",
        enforceMinimumBalance: savingsAccount
          ? savingsAccount.enforceMinRequiredBalance
          : false,
        enforeceMinBalance: savingsAccount
          ? savingsAccount.enforceMinReqBalance
          : false,
        applyWithdrawalFee: savingsAccount
          ? savingsAccount.withdrawalFeeForTransfers
          : false,

        lockInPeriodSelect: savingsAccount
          ? savingsAccount.lockinPeriodFrequencyType
            ? savingsAccount.lockinPeriodFrequencyType.id
            : ""
          : "",

        lockinPeriodFreq: savingsAccount
          ? savingsAccount.lockinPeriodFrequency
          : "",

        minimumOpeningBalance: savingsAccount
          ? savingsAccount.minRequiredOpeningBalance
            ? savingsAccount.minRequiredOpeningBalance
            : ""
          : "",
        isOverdraft: savingsAccount ? savingsAccount.allowOverdraft : false,
        overdraftLimit: savingsAccount ? savingsAccount.overdraftLimit : "",
        minBalanceForInterestCalculation: savingsAccount
          ? savingsAccount.minOverdraftForInterestCalculation
          : "",
        nominalAnnualInterestRateOverdraft: savingsAccount
          ? savingsAccount.nominalAnnualInterestRateOverdraft
          : "",
      });

      setCharges(!!savingsAccount.charges ? savingsAccount.charges : []);
    }
  }, [savingsAccount]);

  // console.log(newSavingsAccount, charges, 'fghjklkjhgfdfghjk');
  let newCharges = [];
  useEffect(() => {
    charges.map((charge) => {
      let { id, amount, chargeId, chargeTimeType, date, dueDate, feeInterval } =
        charge;
      let dayMonth = !!date ? formatDate(date, true) : "";
      if (id && chargeId) {
        if (chargeTimeType.value === "Weekly Fee") {
          newCharges.push({
            dueDate: formatDate(dueDate),
            id,
            amount: parseFloat(amount),
            feeInterval: parseFloat(feeInterval),
          });
        } else if (
          chargeTimeType.value === "Withdrawal Fee" ||
          chargeTimeType.value === "Savings Activation" ||
          chargeTimeType.value === "Overdraft Fee" ||
          chargeTimeType.value === "Saving No Activity Fee"
        ) {
          newCharges.push({
            id,
            amount: parseFloat(amount),
          });
        } else if (chargeTimeType.value === "Specified due date") {
          newCharges.push({
            id,
            amount: parseFloat(amount),
            dueDate: formatDate(dueDate),
          });
        } else if (chargeTimeType.value === "Annual Fee") {
          newCharges.push({
            feeOnMonthDay: dayMonth?.substring(0, dayMonth?.length - 5),
            // chargeId: chargeId,
            id,
            amount: parseFloat(amount),
          });
        } else {
          return newCharges.push({
            // removing the year and the space b4 it as endpoint only accept month and day
            feeOnMonthDay: dayMonth?.substring(0, dayMonth?.length - 5),
            locale: "en",
            monthDayFormat: "dd MMMM",
            id,
            amount: parseFloat(amount),
            feeInterval: parseFloat(feeInterval),
          });
        }
      } else if (!chargeId && id) {
        if (chargeTimeType.value === "Weekly Fee") {
          return newCharges.push({
            dueDate: dayMonth,
            chargeId: id,
            locale: "en",
            dateFormat: "dd MM yyyy",
            amount: parseFloat(amount),
            feeInterval: parseFloat(feeInterval),
          });
        } else if (
          chargeTimeType.value === "Withdrawal Fee" ||
          chargeTimeType.value === "Savings Activation" ||
          chargeTimeType.value === "Overdraft Fee" ||
          chargeTimeType.value === "Saving No Activity Fee"
        ) {
          return newCharges.push({
            chargeId: id,
            amount: parseFloat(amount),
          });
        } else if (chargeTimeType.value === "Specified due date") {
          return newCharges.push({
            chargeId: id,
            amount: parseFloat(amount),
            dueDate: formatDate(dueDate),
            locale: "en",
            dateFormat: "dd MM yyyy",
          });
        } else if (chargeTimeType.value === "Annual Fee") {
          return newCharges.push({
            feeOnMonthDay: dayMonth?.substring(0, dayMonth?.length - 5),
            chargeId: id,
            locale: "en",
            monthDayFormat: "dd MMMM",
            amount: parseFloat(amount),
          });
        } else {
          return newCharges.push({
            // removing the year and the space b4 it as endpoint only accept month and day
            feeOnMonthDay: dayMonth?.substring(0, dayMonth?.length - 5),
            chargeId: id,
            locale: "en",
            monthDayFormat: "dd MMMM",
            amount: parseFloat(amount),
            feeInterval: parseFloat(feeInterval),
          });
        }
      }
    });
  }, [charges, newCharges]);

  const handleSubmit = async () => {
    let data = {
      locale: "en",
      dateFormat: "dd MMMM yyyy",
      productId: newSavingsAccount?.productId,
      externalId: newSavingsAccount?.externalId,
      enforceMinRequiredBalance: newSavingsAccount.enforceMinimumBalance,
      fieldOfficerId: newSavingsAccount.fieldOfficerId,
      overdraftLimit: overdraftLimit ? parseFloat(overdraftLimit) : "",
      allowOverdraft: newSavingsAccount.isOverdraft,
      interestCompoundingPeriodType:
        newSavingsAccount.interestCompoundingPeriod,
      interestPostingPeriodType: newSavingsAccount.interestPostingPeriod,
      interestCalculationType: newSavingsAccount.interestCalculated,
      interestCalculationDaysInYearType: newSavingsAccount.daysInYear,
      minRequiredOpeningBalance: newSavingsAccount.minimumOpeningBalance,
      minRequiredBalance: minumumBalance ? parseFloat(minumumBalance) : "",
      lockinPeriodFrequency: newSavingsAccount.lockinPeriodFreq,
      lockinPeriodFrequencyType: newSavingsAccount.lockInPeriodSelect,
      withHoldTax: newSavingsAccount.isWitholdTax,
      withdrawalFeeForTransfers: newSavingsAccount.applyWithdrawalFee,
      nominalAnnualInterestRate: newSavingsAccount.nominalAnnualInterest,
      minOverdraftForInterestCalculation: minBalanceForInterestCalculation,
      nominalAnnualInterestRateOverdraft: nominalAnnualInterestRateOverdraft
        ? parseFloat(nominalAnnualInterestRateOverdraft)
        : "",
    };

    // const newData = { ...data,  };
    const newData = { ...data, charges: newCharges };
    setLoading(true);
    dispatch(
      updateSavingsAccountInfo(newData, match.params.id, history, clientId)
    );
    setLoading(false);
    // }
  };

  return (
    <>
      <div className="row justify-content-between">
        <div className="col-sm-12 col-md-6 pb-5 mt-1">
          <h3 className="page-title">Edit savings Account</h3>
          <p className="text-label sub-title">Modify savings accounts</p>
          <p className="font-weight sub-title mt-2">
            <span className="text-info">
              <Link to="/core/clients">Customer </Link>
            </span>{" "}
            /{" "}
            <span className="text-info">
              <Link to={`/core/client/${clientId}`}>
                {!!clientName ? `${clientName}  ` : ""}
              </Link>
            </span>
            / Edit savings Accounts
          </p>
          <p
            onClick={(e) => {
              e.stopPropagation();
              history.push(
                `/core/client/${clientId}/${goToSavingsTab}/${match?.params.id}/routeFrom`
              );
            }}
            className="d-flex mt-4"
            style={{ color: "#2C1DFF", cursor: "pointer" }}
          >
            <img className="mr-2" src={BackSvg} alt="" />
            Back
          </p>
        </div>
      </div>

      <div className="row" style={{ minHeight: "calc(100vh - 250px)" }}>
        <div className="col-sm-12 col-md-12">
          <ContentWrapper style={{ paddingTop: 50, paddingBottom: 50 }}>
            <div className="container edit-content">
              {/* Details */}
              <FormTitle>
                <p className="text-info">Details</p>
                <div className="lineThrough"></div>
              </FormTitle>
              {/* Product */}
              <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                <div className="col-sm-12 col-md-6 px-0 ">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label d-flex flex-align-center">
                          Product &nbsp; <span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          size="md"
                          name="productId"
                          value={newSavingsAccount.productId}
                          onChange={handleProductType}
                        >
                          <option value="">Select ProductName</option>
                          {savingsAccount !== undefined && savingsAccount
                            ? savingsAccount.productOptions &&
                              savingsAccount.productOptions.map((cur) => (
                                <option value={cur.id} key={cur.id}>
                                  {cur.name}
                                </option>
                              ))
                            : null}
                        </Select>
                      </div>
                    </FormElements>
                  </FormClass>
                </div>

                {/* Field officer */}
                <div className="col-sm-12 col-md-6 px-0">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label d-flex flex-align-center">
                          Field officer
                        </label>
                        <Select
                          size="md"
                          name="fieldOfficerId"
                          value={newSavingsAccount.fieldOfficerId}
                          onChange={editSavingsAccountHandler}
                        >
                          <option value="">Select One</option>
                          {savingsAccount !== undefined && savingsAccount
                            ? savingsAccount.fieldOfficerOptions &&
                              savingsAccount.fieldOfficerOptions.map((cur) => (
                                <option value={cur.id} key={cur.id}>
                                  {cur.displayName}
                                </option>
                              ))
                            : null}
                        </Select>
                      </div>
                    </FormElements>
                  </FormClass>
                </div>
              </div>

              {/* Submitted on* */}
              <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                <div className="col-sm-12 col-md-6 px-0 ">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label">
                          Submitted on&nbsp;{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <FormControl style={{ width: "60%" }}>
                          <div className="datepicker-100-width">
                            <DatePicker
                              id="published-date"
                              name="submittedOnDate"
                              selected={submittedDate}
                              onChange={(date) => setSubmittedDate(date)}
                              peekNextMonth
                              showMonthDropdown
                              // dateFormat="dd/MM/YYYY"
                              showYearDropdown
                              dropdownMode="select"
                              placeholderText="dd/mmm/yyyy"
                            />
                          </div>
                        </FormControl>
                      </div>
                    </FormElements>
                  </FormClass>
                </div>

                {/* External ID */}
                <div className="col-sm-12 col-md-6 px-0">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label">External ID</label>
                        <Input
                          style={{ width: "60%" }}
                          value={newSavingsAccount?.externalId}
                          onChange={editSavingsAccountHandler}
                          name="externalId"
                          size="md"
                        />
                      </div>
                    </FormElements>
                  </FormClass>
                </div>
              </div>

              {/***** Terms *****/}

              <div className="pt-5">
                <FormTitle>
                  <p className="text-info">Terms</p>
                  <div className="lineThrough"></div>
                </FormTitle>
              </div>
              <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                {/* Currency */}
                <div className="col-sm-12 col-md-6 px-0 ">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label">
                          Currency&nbsp; <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          style={{ width: "60%" }}
                          size="md"
                          value={newSavingsAccount.currency}
                          readOnly
                        />
                      </div>
                    </FormElements>
                  </FormClass>
                </div>
                {/* Decimal */}
                <div className="col-sm-12 col-md-6 px-0">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label">Decimal Places</label>
                        <Input
                          style={{ width: "60%" }}
                          size="md"
                          value={newSavingsAccount.DecimalPlace}
                          readOnly
                        />
                      </div>
                    </FormElements>
                  </FormClass>
                </div>
              </div>

              <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                {/* Nominal */}
                <div className="col-sm-12 col-md-6 px-0 ">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label">
                          Nominal Annual Interest&nbsp;{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          style={{ width: "60%" }}
                          name="nominalAnnualInterest"
                          value={newSavingsAccount.nominalAnnualInterest}
                          size="md"
                          onChange={editSavingsAccountHandler}
                        />
                      </div>
                    </FormElements>
                  </FormClass>
                </div>

                {/* Interest Compounding Period*/}
                <div className="col-sm-12 col-md-6 px-0">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label">
                          Interest Compounding Period&nbsp;{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          placeholder="Select Interest Compounding Period"
                          size="md"
                          name="interestCompoundingPeriod"
                          onChange={editSavingsAccountHandler}
                          value={newSavingsAccount.interestCompoundingPeriod}
                        >
                          {/* <option value=""></option> */}
                          {savingsAccount !== undefined && savingsAccount
                            ? savingsAccount.interestCompoundingPeriodTypeOptions &&
                              savingsAccount.interestCompoundingPeriodTypeOptions.map(
                                (cur) => (
                                  <option value={cur.id} key={cur.id}>
                                    {cur.value}
                                  </option>
                                )
                              )
                            : null}
                        </Select>
                      </div>
                    </FormElements>
                  </FormClass>
                </div>
              </div>

              <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                {/* Currency In Multiples */}
                <div className="col-sm-12 col-md-6 px-0">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label">
                          {" "}
                          Currency In Multiples Of&nbsp;{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          style={{ width: "60%" }}
                          size="md"
                          value={newSavingsAccount.multipleCurrency}
                          readOnly
                        />
                      </div>
                    </FormElements>
                  </FormClass>
                </div>
                {/* Interest Posting */}
                <div className="col-sm-12 col-md-6 px-0">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label">
                          Interest Posting Period&nbsp;{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          placeholder="Select Interest posting Period"
                          size="md"
                          name="interestPostingPeriod"
                          onChange={editSavingsAccountHandler}
                          value={newSavingsAccount.interestPostingPeriod}
                        >
                          {savingsAccount !== undefined && savingsAccount
                            ? savingsAccount.interestPostingPeriodTypeOptions &&
                              savingsAccount.interestPostingPeriodTypeOptions.map(
                                (cur) => (
                                  <option value={cur.id} key={cur.id}>
                                    {cur.value}
                                  </option>
                                )
                              )
                            : null}
                        </Select>
                      </div>
                    </FormElements>
                  </FormClass>
                </div>
              </div>

              <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                {/* Interest calculated */}
                <div className="col-sm-12 col-md-6 px-0 ">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label">
                          Interest calculated using&nbsp;{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          placeholder="Select Interest calculated using"
                          size="md"
                          name="interestCalculated"
                          onChange={editSavingsAccountHandler}
                          value={newSavingsAccount.interestCalculated}
                        >
                          {savingsAccount !== undefined && savingsAccount
                            ? savingsAccount.interestCalculationTypeOptions &&
                              savingsAccount.interestCalculationTypeOptions.map(
                                (cur) => (
                                  <option value={cur.id} key={cur.id}>
                                    {cur.value}
                                  </option>
                                )
                              )
                            : null}
                        </Select>
                      </div>
                    </FormElements>
                  </FormClass>
                </div>
                {/*  No. of Days in Year */}
                <div className="col-sm-12 col-md-6 px-0 ">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label">
                          No. of Days in Year&nbsp;{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          placeholder="Select no. of days in year"
                          size="md"
                          name="daysInYear"
                          onChange={editSavingsAccountHandler}
                          value={newSavingsAccount.daysInYear}
                        >
                          {savingsAccount !== undefined && savingsAccount
                            ? savingsAccount.interestCalculationDaysInYearTypeOptions &&
                              savingsAccount.interestCalculationDaysInYearTypeOptions.map(
                                (cur) => (
                                  <option value={cur.id} key={cur.id}>
                                    {cur.value}
                                  </option>
                                )
                              )
                            : null}
                        </Select>
                      </div>
                    </FormElements>
                  </FormClass>
                </div>
              </div>

              <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                {/* Minimum Opening */}
                <div className="col-sm-12 col-md-6 px-0 ">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label">
                          Minimum Opening Balance
                        </label>

                        <CurrencyFormat
                          //   value={minRequiredOpeningBalance}
                          thousandSeparator={true}
                          className="amount-input"
                          style={{
                            width: "60%",
                          }}
                          name="minimumOpeningBalance"
                          onChange={editSavingsAccountHandler}
                          value={newSavingsAccount.minimumOpeningBalance}
                        />
                      </div>
                    </FormElements>
                  </FormClass>
                </div>
                {/* Lock-in Period */}
                <div className="col-sm-12 col-md-6 px-0 ">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex mb-10-imp justify-content-between align-items-center">
                        <label className="text-label">Lock-in Period</label>
                        <Input
                          style={{ width: "60%" }}
                          size="md"
                          name="lockinPeriodFreq"
                          onChange={editSavingsAccountHandler}
                          value={newSavingsAccount.lockinPeriodFreq}
                        />
                      </div>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label"></label>
                        <Select
                          placeholder="Select one"
                          size="md"
                          name="lockInPeriodSelect"
                          onChange={editSavingsAccountHandler}
                          value={newSavingsAccount.lockInPeriodSelect}
                        >
                          {savingsAccount !== undefined && savingsAccount
                            ? savingsAccount.lockinPeriodFrequencyTypeOptions &&
                              savingsAccount.lockinPeriodFrequencyTypeOptions.map(
                                (cur) => (
                                  <option value={cur.id} key={cur.id}>
                                    {cur.value}
                                  </option>
                                )
                              )
                            : null}
                        </Select>
                      </div>
                    </FormElements>
                  </FormClass>
                </div>
              </div>

              <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                {/* Apply Withdrawal Fee */}
                <div className="col-sm-12 col-md-6 px-0 ">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label">
                          Apply Withdrawal Fee for Transfers&nbsp;{" "}
                        </label>
                        <FormControl style={{ width: "60%" }}>
                          <Checkbox
                            size="md"
                            name="applyWithdrawalFee"
                            isChecked={newSavingsAccount?.applyWithdrawalFee}
                            value={newSavingsAccount.applyWithdrawalFee}
                            onChange={(e) =>
                              setNewSavingsAccount((p) => ({
                                ...p,
                                applyWithdrawalFee: e.target.checked,
                              }))
                            }
                          />
                        </FormControl>
                      </div>
                    </FormElements>
                  </FormClass>

                  {/* Is Overdraft Allowed */}
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label d-flex flex-align-center">
                          Is Overdraft Allowed
                        </label>
                        <FormControl style={{ width: "60%" }}>
                          <Checkbox
                            size="md"
                            name="isOverdraft"
                            isChecked={newSavingsAccount?.isOverdraft}
                            value={newSavingsAccount.isOverdraft}
                            onChange={(e) =>
                              setNewSavingsAccount((p) => ({
                                ...p,
                                isOverdraft: e.target.checked,
                              }))
                            }
                          />
                        </FormControl>
                      </div>
                    </FormElements>
                  </FormClass>

                  {newSavingsAccount.isOverdraft ? (
                    <>
                      {/* Maximum Overdraft Amount Limit */}
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label d-flex flex-align-center">
                              Maximum Overdraft Amount Limit
                            </label>
                            <CurrencyFormat
                              value={overdraftLimit}
                              name="overdraftLimit"
                              thousandSeparator={true}
                              className="amount-input"
                              // placeholder="Minimum"
                              style={{
                                width: "60%",
                              }}
                              // prefix={"$"}
                              onValueChange={(values) => {
                                const { value } = values;
                                // formattedValue = $2,223
                                // value ie, 2223
                                setOverdraftLimit(value);
                              }}
                            />
                          </div>
                        </FormElements>
                      </FormClass>
                      {/* Min Overdraft Required For Interest Calculation */}
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label d-flex flex-align-center">
                              Min Overdraft Required For Interest Calculation
                            </label>
                            <CurrencyFormat
                              value={minBalanceForInterestCalculation}
                              name="minBalanceForInterestCalculation"
                              thousandSeparator={true}
                              className="amount-input"
                              style={{
                                width: "60%",
                              }}
                              onValueChange={(values) => {
                                const { value } = values;
                                setMinBalanceForInterestCalculation(value);
                              }}
                            />
                          </div>
                        </FormElements>
                      </FormClass>
                      {/* Nominal Annual Interest Rate For Overdraft */}
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label d-flex flex-align-center">
                              Nominal Annual Interest Rate For Overdraft
                            </label>
                            <CurrencyFormat
                              value={nominalAnnualInterestRateOverdraft}
                              name="nominalAnnualInterestRateOverdraft"
                              thousandSeparator={true}
                              className="amount-input"
                              // placeholder="Minimum"
                              style={{
                                width: "60%",
                              }}
                              // prefix={"$"}
                              onValueChange={(values) => {
                                const { value } = values;
                                // formattedValue = $2,223
                                // value ie, 2223
                                setNominalAnnualInterestRateOverdraft(value);
                              }}
                            />
                          </div>
                        </FormElements>
                      </FormClass>
                    </>
                  ) : (
                    <>
                      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                        {/* Minimum Balance */}
                        <div className="col-sm-12 col-md-6 px-0 ">
                          <FormClass>
                            <FormElements>
                              <div className="form-group d-flex justify-content-between align-items-center">
                                <label className="text-label">
                                  Minimum Balance
                                </label>
                                <CurrencyFormat
                                  value={minumumBalance}
                                  name="minumumBalance"
                                  thousandSeparator={true}
                                  className="amount-input"
                                  // placeholder="Minimum"
                                  style={{
                                    width: "60%",
                                  }}
                                  // prefix={"$"}
                                  onValueChange={(values) => {
                                    const { value } = values;
                                    // formattedValue = $2,223
                                    // value ie, 2223
                                    setMinumumBalance(value);
                                  }}
                                />
                              </div>
                            </FormElements>
                          </FormClass>
                        </div>

                        {/* Enforce Minimum Balance */}
                        <div className="col-sm-12 col-md-6 px-0 ">
                          <FormClass>
                            <FormElements>
                              <div className="form-group d-flex justify-content-between align-items-center">
                                <label className="text-label">
                                  {/* Enforce Minimum Balance&nbsp; <span>?</span> */}
                                  Enforce Minimum Balance&nbsp;
                                </label>
                                <FormControl style={{ width: "60%" }}>
                                  <Checkbox
                                    size="md"
                                    name="enforceMinimumBalance"
                                    isChecked={
                                      newSavingsAccount?.enforceMinimumBalance
                                    }
                                    value={
                                      newSavingsAccount.enforceMinimumBalance
                                    }
                                    onChange={(e) =>
                                      setNewSavingsAccount((p) => ({
                                        ...p,
                                        enforceMinimumBalance: e.target.checked,
                                      }))
                                    }
                                  />
                                </FormControl>
                              </div>
                            </FormElements>
                          </FormClass>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>

              {/****** Charges  *****/}
              <div className="pt-5">
                <FormTitle>
                  <p className="text-info">Charges</p>
                  <div className="lineThrough"></div>
                </FormTitle>
                <EditSavingsAccountCharges
                  template={savingsAccount}
                  setCharges={setCharges}
                  charges={charges}
                  editSavingsAccountHandler={editSavingsAccountHandler}
                  loading={processing}
                  handleSubmit={handleSubmit}
                />
              </div>

              {/* Action buttons */}
              {/* <div className="float-right mt-4">
                <ActionButton color="#005BFC">
                  <img
                    src={
                      require("../../../../../../assets/images/icons/cancel.svg")
                        .default
                    }
                    alt="cancel icon"
                  />
                  Cancel
                </ActionButton>
                <ActionButton
                  color="#005BFC"
                  //   onClick={handleUndoApprove}
                  style={{ margin: "0px 0px 0 10px" }}
                >
                  <img
                    src={
                      require("../../../../../../assets/images/icons/save.svg")
                        .default
                    }
                    alt="submit icon"
                  />
                  Submit
                </ActionButton>
              </div> */}
            </div>
          </ContentWrapper>
        </div>
      </div>
    </>
  );
};

export default EditSavingsAccount;
