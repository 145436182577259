/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, useRef } from 'react';
import { ActionButton } from '../../styles';
import { Modal } from 'react-bootstrap';
import axiosData from '../../../../services/axiosData';
import styled from 'styled-components';
import ModalContainer from '../../components/ModalContainer';
import { Button } from '../styles';
import { getCustomerDocuments } from '../../../../actions/customerActions';
import config from '../../../../config';
import { useDispatch, useSelector } from 'react-redux';
import AuthModule from '../../../../modules/Auth.module';
import ViewImage from './ViewImage';
import { SET_LOADING_STATE } from '../../../../constants/utilityConstants';

export const MandateModal = ({ onHide, id }) => {
  const [showImage, setShowImage] = useState(false);
  const [imageData, setImageData] = useState();
  const [clientId, setClientId] = useState(id);
  const dispatch = useDispatch();
  const ancRef = useRef();
  const { documents, loading } = useSelector((state) => state.documentsList);

  useEffect(() => {
    if (id) {
      dispatch(getCustomerDocuments(id));
    }
  }, []);

  const cancelImageShow = () => {
    setShowImage(false);
    setImageData();
  };

  useEffect(() => {
    setClientId(id);
  }, [id]);

  useEffect(() => {
    if (imageData) setShowImage(true);
  }, [imageData]);

  const downloadDocument = async (documentId) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    const url = `${
      config.apiGateway.BASE_API_URL
    }/clients/${id}/documents/${documentId}/attachment?tenantIdentifier=${
      config.apiGateway.IS_PRODUCTION === `true`
        ? AuthModule.getTenant()
        : config.apiGateway.WOODCORE_TENANT
    }`;
    try {
      const { data } = await axiosData({
        method: 'GET',
        signal: false,
        url: url,
        isBearerToken: true,
        responseType: 'blob',
      });
      if (data) {
        const dataUrl = URL.createObjectURL(data);
        ancRef.current.href = dataUrl;
        ancRef.current.download = 'download';
        setTimeout(() => {
          URL.revokeObjectURL(dataUrl);
        }, 150);
        ancRef.current.click();
      }
      dispatch({ type: SET_LOADING_STATE, payload: false });
    } catch (ex) {}
  };

  return !loading ? (
    <>
      <ModalContainer size="lg" show={showImage}>
        {showImage && (
          <ViewImage
            onHide={cancelImageShow}
            clientId={clientId}
            data={imageData}
          />
        )}
      </ModalContainer>
      <Container>
        <a style={{ display: 'none' }} ref={ancRef}></a>
        <Modal.Header>
          <Modal.Title>Mandate instruction</Modal.Title>
        </Modal.Header>

        <Modal.Body className="wc-modal">
          {documents.length ? (
            documents?.map((document) => {
              return (
                <div key={document?.id} className="document-container">
                  <div className="d-flex">
                    <p>{document?.fileName}</p>
                  </div>
                  {document?.type.startsWith('image') ? (
                    <Button
                      type="button"
                      onClick={() => setImageData(document)}
                    >
                      View
                    </Button>
                  ) : (
                    <Button
                      type="button"
                      onClick={() => downloadDocument(document?.id)}
                    >
                      Download
                    </Button>
                  )}
                  {downloadDocument}
                </div>
              );
            })
          ) : (
            <h3
              style={{
                fontFamily: 'AventaSemiBold',
                display: 'flex',
                justifyContent: 'center',
              }}
              className="h4 px-4 py-5"
            >
              Customer has no documents
            </h3>
          )}
        </Modal.Body>

        <Modal.Footer>
          <ActionButton onClick={onHide} color="#2C1DFF">
            <img
              src={
                require('../../../../assets/images/icons/cancel.svg').default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
        </Modal.Footer>
      </Container>
    </>
  ) : (
    ''
  );
};

const Container = styled.div`
  .modal-body {
    padding: 0px;
  }
  .document-container {
    padding: 10px 20px;
    color: #969cba;
    font-family: 'AventaRegular';
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .document-container:not(:last-child) {
    border-bottom: 1px solid #dadce6;
  }
`;
