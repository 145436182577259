import React from "react";
import { Modal, Table } from "react-bootstrap";
import { ActionButton } from "../../styles";
import { formatDate } from "../../../../../../util/FormatDate";
import { formatCurrency } from "../../../../../../util/FormatAmount";

const TransactionDetailsModal = ({ onHide, transactionDetails }) => {
  const mappedTransactionDetails = {
    id: transactionDetails.id,
    transDate: formatDate(transactionDetails.date),
    debit:
      transactionDetails.transactionType.withdrawal ||
        transactionDetails.transactionType.feeDeduction ||
        transactionDetails.transactionType.dividendPayout ||
        transactionDetails.transactionType.initiateTransfer
        ? `${transactionDetails.currency.code} ${formatCurrency(transactionDetails.amount)}`
        : "",
    credit:
      transactionDetails.transactionType.deposit ||
        transactionDetails.transactionType.interestPosting ||
        transactionDetails.transactionType.amountRelease
        ? `${transactionDetails.currency.code} ${formatCurrency(transactionDetails.amount)}`
        : "",
    transactionType: transactionDetails.transactionType.value,
    narration: transactionDetails.note || transactionDetails.transactionDetailstransfer?.transferDescription,
    balance: `${transactionDetails.currency.code} ${formatCurrency(transactionDetails.runningBalance)}`,
    submittedByUsername: transactionDetails.submittedByUsername,
    transaction: {}
  };
  let {
    balance,
    credit,
    debit,
    transDate,
    transactionType,
    submittedByUsername,
    narration,
    transaction = {},
  } = mappedTransactionDetails;

  return (
    <>
      <Modal.Header>
        <Modal.Title>Transaction Details</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <div className="full-width mt-4">
          <Table responsive striped bordered hover>
            <thead className="border-0">
              <tr>
                <td className="font-weight-bold md-font-weight">Balance</td>
                <td>{balance}</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="font-weight-bold md-font-weight">
                  Transaction Type
                </td>
                <td>{transactionType}</td>
              </tr>
              <tr>
                <td className="font-weight-bold md-font-weight">Debit</td>
                <td>{debit}</td>
              </tr>{" "}
              <tr>
                <td className="font-weight-bold md-font-weight">Credit</td>
                <td>{credit}</td>
              </tr>
              <tr>
                <td className="font-weight-bold md-font-weight">
                  Transaction Date
                </td>
                <td>{transDate}</td>
              </tr>
              <tr>
                <td className="font-weight-bold md-font-weight">
                  Submitted By
                </td>
                <td>{submittedByUsername}</td>
              </tr>
              <tr>
                <td className="font-weight-bold md-font-weight">Narration</td>
                <td>{narration}</td>
              </tr>
            </tbody>
          </Table>
          <div className="spaceBetweenFlexEnd mt-3">
            <div className="">
              <ActionButton color="#2C1DFF" onClick={onHide}>
                <img
                  src={
                    require("../../../../../../assets/images/icons/cancel.svg")
                      .default
                  }
                  alt="cancel icon"
                />
                Cancel
              </ActionButton>
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default TransactionDetailsModal;
