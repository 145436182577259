import React from "react";
import { Modal, Table } from "react-bootstrap";
import { ActionButton } from "../../styles";
import { formatCurrency } from "../../../../../util/FormatAmount";
import { formatDate } from "../../../../../util/FormatDate";
import { textToUpperCase } from "../../../../../util/Tools";

const AmortizationDetailsModal = ({ onHide, amortizationDetails }) => {
  let { amor } = amortizationDetails;
  let {
    amount,
    createdDate,
    endDate,
    office = {},
    creditGlAccount = {},
    debitGlAccount = {},
    description,
    isActive,
    number_of_days,
  } = amor || {};

  return (
    <>
      <Modal.Header>
        <Modal.Title>Placement Details</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <div className="full-width mt-4">
          <Table responsive striped bordered hover>
            <thead className="border-0">
              <tr>
                <td className="font-weight-bold md-font-weight">Amount</td>
                <td>{formatCurrency(Number(amount))}</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="font-weight-bold md-font-weight">Office Name</td>
                <td> {!!office.name ? `${office.name}` : ""}</td>
              </tr>{" "}
              <tr>
                <td className="font-weight-bold md-font-weight">Start Date</td>
                <td>{formatDate(createdDate)}</td>
              </tr>
              <tr>
                <td className="font-weight-bold md-font-weight">End Date</td>
                <td>{formatDate(endDate)}</td>
              </tr>
              <tr>
                <td className="font-weight-bold md-font-weight">
                  Credit Account
                </td>
                <td>
                  {!!creditGlAccount.name && !!creditGlAccount.glCode
                    ? `${textToUpperCase(creditGlAccount.name)}(${
                        creditGlAccount.glCode
                      })`
                    : ""}
                </td>
              </tr>
              <tr>
                <td className="font-weight-bold md-font-weight">
                  Debit Account
                </td>
                <td>
                  {!!debitGlAccount.name && !!debitGlAccount.glCode
                    ? `${textToUpperCase(debitGlAccount.name)}(${
                        debitGlAccount.glCode
                      })`
                    : ""}
                </td>
              </tr>
              <tr>
                <td className="font-weight-bold md-font-weight">Description</td>
                <td>{description}</td>
              </tr>
              <tr>
                <td className="font-weight-bold md-font-weight">Is Active</td>
                <td>{isActive ? "Active" : "InActive"}</td>
              </tr>
              <tr>
                <td className="font-weight-bold md-font-weight">
                  Number Of Days
                </td>
                <td>{number_of_days}</td>
              </tr>
            </tbody>
          </Table>
          <div className="spaceBetweenFlexEnd mt-3">
            <div className="">
              <ActionButton color="#2C1DFF" onClick={onHide}>
                <img
                  src={
                    require("../../../../../assets/images/icons/cancel.svg")
                      .default
                  }
                  alt="cancel icon"
                />
                Cancel
              </ActionButton>
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default AmortizationDetailsModal;
