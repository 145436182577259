import React, { useState } from "react";
import { ActionButton } from "../styles";
import { useSelector } from "react-redux";

import ModalContainer from "../../../components/ModalContainer";
import AssignStaff from "../../CustomerInformation/components/general/AssignStaff";
// import { TabButton } from '../styles';

import EditProfileIcon from '../../../../../assets/images/icons/profile-edit.svg';
import EditProfileInfoIcon from '../../../../../assets/images/icons/profile-info.svg';
import EditProfileAddressIcon from '../../../../../assets/images/icons/profile-address.svg';
import ArrowCancelSvg from '../../../../../assets/images/icons/arrow-cancel.svg';
// import ArrowForwardSvg from '../../../../../assets/images/icons/arror-forward.svg';

import PersonalInformation from './PersonalInformation';
import DataTables from './DataTables';
import Address from './Address';

import CheckPermission from '../../../../../util/CheckPermission';
import EditTier from '../../CustomerInformation/components/general/UpdateTier';

const ProfileTab = ({ userData, history, addresses, id, dataTables }) => {
  const [showMenu, setShowMenu] = useState(false);

  const createCustomerDetails = useSelector(
    (state) => state.createCustomerDetails.createCustomerDetails
  );

  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  const customerAddressDetails = useSelector((state) => state.customerAddress);
  const { customerAddress = [] } = customerAddressDetails;

  const {
    genderOptions = [],
    clientLegalFormOptions = [],
    address = [],
    // clientClassificationOptions = [],
    // clientTypeOptions = [],
    clientNonPersonConstitutionOptions = [],
  } = createCustomerDetails || {};
  const [modalState, toggleModal] = useState(false);
  const [modalContentName, setModalContentName] = useState('');
  const currentContent = (e) => {
    setShowMenu(false);
    const val = e.currentTarget.id.trim();
    if (val) {
      toggleModal(true);
      setModalContentName(val);
    }
  };

  const handleModalClose = () => toggleModal(false);

  const displayModalContent = () => {
    switch (modalContentName) {
      case 'assignStaff':
        return (
          <AssignStaff
            history={history}
            userData={userData}
            onHide={handleModalClose}
          />
        );
      case 'editTier':
        return <EditTier onHide={handleModalClose} userData={userData} />;

      default:
        return;
    }
  };

  const [profileTab, setProfileTab] = useState(1);

  const renderComponent = () => {
    let currentComponet = '';
    switch (profileTab) {
      case 1:
        currentComponet = (
          <>
            <PersonalInformation
              userData={userData}
              genderOptions={genderOptions}
              clientLegalFormOptions={clientLegalFormOptions}
              clientNonPersonConstitutionOptions={
                clientNonPersonConstitutionOptions
              }
              id={id}
            />
          </>
        );
        break;
      case 2:
        currentComponet = (
          <DataTables id={id} type="client" dataTables={dataTables} />
        );
        break;
      case 3:
        currentComponet = (
          <>
            <Address
              userData={userData}
              address={customerAddress}
              addressTemplate={address}
              id={id}
            />
          </>
        );
        break;

      default:
        break;
    }

    return currentComponet;
  };

  const handleTakeActions = () => {
    return (
      <div className="blockUi">
        <div className="container-fluid">
          <div className="spaceBetweenFlexEnd">
            <div className="new-button-menus">
              <div className="row">
                <div className="spaceBetweenFlexEnd w-100">
                  <div
                    onClick={() => setShowMenu(false)}
                    className="p-relative mb-3 pointer"
                  >
                    <img src={ArrowCancelSvg} alt="" />
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {!!userData &&
                CheckPermission('UNASSIGNSTAFF_CLIENT', permissions) ? (
                  <ActionButton
                    color="#2C1DFF"
                    onClick={currentContent}
                    id="assignStaff"
                  >
                    <img
                      src={
                        require('../../../../../assets/images/icons/customer.svg')
                          .default
                      }
                      className="mr-2"
                      style={{ height: '16px' }}
                      alt="add"
                    />
                    {!userData.staffName ? 'Assign ' : 'Unassign '}
                    Staff
                  </ActionButton>
                ) : (
                  ''
                )}
                <ActionButton
                  color="#2C1DFF"
                  onClick={currentContent}
                  id="editTier"
                >
                  <img
                    src={
                      require('../../../../../assets/images/icons/customer.svg')
                        .default
                    }
                    className="mr-2"
                    style={{ height: '16px' }}
                    alt="add"
                  />
                  Edit tier
                </ActionButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <ModalContainer size="md" show={modalState}>
        {modalState && displayModalContent()}
      </ModalContainer>
      <div className="container pt-4">
        <div className="row">
          <div className="col-sm-12 col-md-7">
            <h3 className="page-title">Profile</h3>
          </div>
          <div className="col-sm-12 col-md-5">
            <div className="spaceBetweenFlexEnd">
              <div className="spaceBetweenFlexEnd">
                <ActionButton
                  onClick={() => setShowMenu(!showMenu)}
                  color="#2C1DFF"
                >
                  Options
                  <img
                    src={
                      require('../../../../../assets/images/icons/arrow-right.svg')
                        .default
                    }
                    className="ml-2"
                    alt="Options"
                  />
                </ActionButton>
              </div>

              {showMenu && handleTakeActions()}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <div className="profile-nav d-flex justify-content-around">
            <div
              className={`profile-nav-box ${
                profileTab === 1 ? 'background-blue' : ''
              }`}
              onClick={() => setProfileTab(1)}
            >
              <img
                className="profile-nav-box-img"
                src={EditProfileIcon}
                alt=""
              />
            </div>
            <div
              className={`profile-nav-box ${
                profileTab === 2 ? 'background-blue' : ''
              }`}
              onClick={() => setProfileTab(2)}
            >
              <img
                className="profile-nav-box-img"
                src={EditProfileInfoIcon}
                alt=""
              />
            </div>
            <div
              className={`profile-nav-box ${
                profileTab === 3 ? 'background-blue' : ''
              }`}
              onClick={() => setProfileTab(3)}
            >
              <img
                className="profile-nav-box-img"
                src={EditProfileAddressIcon}
                alt=""
              />
            </div>
          </div>
        </div>

        {renderComponent()}
      </div>
    </>
  );
};

export default ProfileTab;
