import React, { useState } from "react";
import { Input, Select } from "@chakra-ui/react";
import { ActionButton, FormElements, FormClass } from "../styles";
import { Modal } from "react-bootstrap";

const NewAdHocQuery = ({ onHide }) => {
  const [newAdHocQuery, setNewAdHocQueryInfo] = useState({
    name: "",
    sqlQuery: "",
    insert: "",
    tableFields: "",
    email: "",
    frequency: "",
    activeStatus: "",
  });
  const {
    name,
    sqlQuery,
    insert,
    tableFields,
    frequency,
    email,
    activeStatus,
  } = newAdHocQuery;
  const createPaymentTypeHandler = (e) =>
    setNewAdHocQueryInfo({
      ...newAdHocQuery,
      [e.target.name]: e.target.value,
    });

  return (
    <>
      <Modal.Header>
        <Modal.Title>Create AdHocQuery</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <FormElements style={{ width: "100%" }}>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Name</label>
              <Input
                style={{ width: "70%" }}
                size="md"
                name="name"
                value={name}
                onChange={createPaymentTypeHandler}
              />
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">SQL Query</label>
              <Input
                style={{ width: "70%" }}
                size="md"
                name="sqlQuery"
                value={sqlQuery}
                onChange={createPaymentTypeHandler}
              />
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Insert Into table</label>
              <Input
                style={{ width: "70%" }}
                size="md"
                name="insert"
                value={insert}
                onChange={createPaymentTypeHandler}
              />
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Table Fields</label>
              <Input
                style={{ width: "70%" }}
                size="md"
                name="tableFields"
                value={tableFields}
                onChange={createPaymentTypeHandler}
              />
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Email</label>
              <Input
                style={{ width: "70%" }}
                size="md"
                name="email"
                value={email}
                onChange={createPaymentTypeHandler}
              />
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Report Run Frequency</label>
              <Select
                placeholder="Select Frequency"
                size="md"
                name="frequency"
                value={frequency}
                onChange={createPaymentTypeHandler}
              >
                <option value="true">Enabled</option>
                <option value="false">Disabled</option>
              </Select>
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Active Status</label>
              <Select
                placeholder="Select Frequency"
                size="md"
                s
                name="activeStatus"
                value={activeStatus}
                onChange={createPaymentTypeHandler}
              >
                <option value="true">Enabled</option>
                <option value="false">Disabled</option>
              </Select>
            </div>
          </FormElements>
          <div className="float-right mt-4">
            <ActionButton color="#2C1DFF" onClick={onHide}>
              <img
                src={
                  require("../../../../../../assets/images/icons/cancel.svg")
                    .default
                }
                alt="cancel icon"
              />
              Cancel
            </ActionButton>
            <ActionButton color="#2C1DFF" style={{ margin: "0px 0px 0 10px" }}>
              <img
                src={
                  require("../../../../../../assets/images/icons/save.svg")
                    .default
                }
                alt="submit icon"
              />
              Submit
            </ActionButton>
          </div>
        </FormClass>
      </Modal.Body>
    </>
  );
};

export default NewAdHocQuery;
