import styled from "styled-components";

export const ActionButtonsGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  justify-content: space-around;
  select {
    // background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    border-radius: 3px;
    height: 47px;
    min-width: 100px;
    border: none;
    // margin: ;
  }
`;

export const ContentWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 0px #ededf6;
  min-height: 70vh;
  margin-top: 20px;
  padding: 30px;

  .button-right {
    width: 100%;
    height: 50px;
    padding-right: 15px;
  }
  table th {
    font-size: 18px;
    font-weight: bold;
  }
`;

export const ActionButton = styled.button`
  background: ${(props) => props.background || "#2C1DFF"} !important;
  border-radius: 5px !important;
  color: ${(props) => props.color || "#fff"} !important;
  margin: 0 3px;
  border: ${(props) => props.border || "#none"} !important;
  display: inline;
  font-size: 14px;
  height: 35px;
  width: 180px;
  font-family: 'AventaMedium';
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  position: relative;
  cursor: pointer;
  img {
    height: 14px;
    margin-right: 10px;
    display: inline;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;

export const FormClass = styled.div``;

export const FormElements = styled.div`
  // width: 90%;
  margin-top: 30px;
  margin-bottom: 30px;
  display: block;
  .text-muted {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #969cba;
  }
  .css-42b2qy {
    width: 60%;
    color: #666666;
    & > option {
      padding: 0.5rem 0;
    }
  }
`;

export const GeneratedReportContainer = styled.div`
  background: #ffffff;
  border-radius: 5px;
  min-height: 70vh;
  display: flex;
  flex-direction: column;

  .heading {
    padding: 23px;
  }
  .center-text {
    font-family: "AventaSemiBold";
    font-size: 16px;
    color: #969cba;
  }
`;

export const IconButton = styled.button`
  background: ${(props) => props.color} !important;
  width: 35px;
  height: 35px;
  background: #2C1DFF;
  text-align: center;
  border-radius: 10px;
  /* margin-right: 5px; */
  img {
    display: inline;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;

export const NewActionButton = styled.div`
  width: max-content !important;
  height: 35px;
  background: #fff !important;
  margin: 0 2px;
  border: 1px solid #2C1DFF;
  padding: 0 15px;
  border-radius: 5px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'AventaMedium';
  cursor: pointer;
  color: #2C1DFF !important;
  a {
    width: max-content !important;
    color: #fff !important;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none !important;
    transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
    img {
      height: 18px;
    }
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;