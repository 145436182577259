import axiosData from "../../../../services/axiosData";
import config from "../../../../config";

let timeout;
let currentValue;

export function fetch(value, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;
  function getCustomers() {
    axiosData({
      method: "GET",
      url: `${config.apiGateway.BASE_API_URL}/search?exactMatch=false&query=${value}&resource=clients`,
    }).then((response) => {
      if (currentValue === value) {
        const { data: rawData } = response;
        const filteredData = [];
        const allData = [];
        rawData?.pageItems?.forEach((r) => {
          allData?.push(r);
          filteredData?.push({
            type: r?.entityType,
            value: r?.entityId, 
            name: r?.entityName, 
            text: `${r?.entityName} (${r?.entityAccountNo})`,
          });
        });
        callback({ filteredData, allData });
      }
    });
  }
  timeout = setTimeout(getCustomers, 600);
}

export function fetchUsers(value, callback) {
  if (value.length < 3) return;
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;
  function getUsers() {
    axiosData({
      method: "GET",
      url: `${config.apiGateway.BASE_API_URL}/search?exactMatch=false&query=${value}&resource=users`,
    }).then((response) => {
      if (currentValue === value) {
        const { data: rawData } = response;
        const data = [];
        rawData?.pageItems?.forEach((r) => {
          data?.push({
            value: r?.entityId,
            text: `${r?.entityName}`,
            // text: `${r?.entityName} (${r?.entityAccountNo})`,
          });
        });
        callback(data);
      }
    });
  }
  timeout = setTimeout(getUsers, 600);
}

// https://core.staging.woodcoreapp.com/woodcore/api/v1/users