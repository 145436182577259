import React from "react";
// import { Input } from "@chakra-ui/react"
import { ContentWrapper } from "./styles";
import { Link } from "react-router-dom";

const SettingsView = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Settings</h3>
            <p className="sub-title">User settings</p>
            <p className="sub-title mt-2">
              <span className="text-info mr-1">
                <Link to="/core">Home</Link>{" "}
              </span>{" "}
              / Settings
            </p>
          </div>
          <div className="col-sm-12 col-md-6 px-0">
            {/* <ActionButtonsGroup>
              <ActionButton className="form-control">
                <img
                  src={
                    require("../../../../assets/images/icons/change-password.svg")
                      .default
                  }
                  className="d-inline mr-3"
                  alt="icon"
                />
                Change Password
              </ActionButton>
            </ActionButtonsGroup> */}
          </div>
        </div>
        <ContentWrapper>
          <div className="row justify-content-center h-100">
            <div className="col-sm-12 col-md-4 h-100">
              <div className="container pb-5" style={{ marginTop: "100px" }}>
                {/* <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Language</label>
                  <select className="custom-select px-4" custom>
                    <option>English</option>
                  </select>
                </div> */}
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Date format</label>
                  <select
                    onChange={(e) =>
                      localStorage.setItem("woodDateFormat", e.target.value)
                    }
                    defaultValue={
                      localStorage.getItem("woodDateFormat")
                        ? localStorage.getItem("woodDateFormat")
                        : "string:dd MMMM yyyy"
                    }
                    className="custom-select px-4"
                    custom
                  >
                    <option label="dd/MMMM/yyyy" value="string:dd/MMMM/yyyy">
                      dd/MMMM/yyyy
                    </option>
                    <option label="dd MMMM yyyy" value="string:dd MMMM yyyy">
                      dd MMMM yyyy
                    </option>
                    <option label="dd-MMMM-yyyy" value="string:dd-MMMM-yyyy">
                      dd-MMMM-yyyy
                    </option>
                    <option label="MMMM-dd-yyyy" value="string:MMMM-dd-yyyy">
                      MMMM-dd-yyyy
                    </option>
                    <option label="MMMM dd yyyy" value="string:MMMM dd yyyy">
                      MMMM dd yyyy
                    </option>
                    <option label="MMMM/dd/yyyy" value="string:MMMM/dd/yyyy">
                      MMMM/dd/yyyy
                    </option>
                    <option label="yyyy-MM-dd" value="string:yyyy-MM-dd">
                      yyyy-MM-dd
                    </option>
                  </select>
                </div>
                {/* <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Currency</label>
                  <select className="custom-select px-4" custom>
                    <option>Naira</option>
                  </select> */}
                {/* </div> */}
              </div>
            </div>
          </div>
        </ContentWrapper>
      </div>
    </>
  );
};

export default SettingsView;
