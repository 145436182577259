import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import IdleTimer from "react-idle-timer";
import PropTypes from "prop-types";
// import cookie from "react-cookies";
import DashboardNavigation from "../../components/TellerUi/Navbar";
import DashboardAsideNaivagation from "../../components/TellerUi/Sidebar";
import { MainWrapper } from "./styles";
import BlockUI from "../../components/BlockUI";
import ToastManager from "../../components/ToastManager";
import PasswordSecurity from "../../components/PasswordSecurity";
import { logoutUser, setUserDataPermission } from "../../actions/authActions";
import { setShowSecurityModal } from "../../actions/authActions";

const TellerLayout = ({ children }, props) => {
  const dispatch = useDispatch();

  const navigationBar = useSelector((state) => state.navigationBar);

  let { navigationBarActive } = navigationBar || {};

  const userDataRes = useSelector((state) => state.preAuth);
  const { responseUserData, showSecurityModal } = userDataRes || {};

  useEffect(() => {
    if (!showSecurityModal) {
      setTimeout(function () {
        setShowSecurityModal(true, dispatch);
      }, 1000 * 60 * 60); //60 mins
      // 20mins 1000 * 60 * 20
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSecurityModal]);

  useEffect(() => {
    let { permissions = [] } = responseUserData || {};
    if (!permissions || permissions === [] || !permissions.length) {
      let userPermission = localStorage.getItem("permissions");
      if (userPermission)
        setUserDataPermission(JSON.parse(userPermission), dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isTimedOut, setIsTimedOut] = useState(false);
  const [timeOut, setTimeOut] = useState(1000 * 60 * 15);
  // const [timeOut, setTimeOut] = useState(10 * 1000);

  const refContainer = useRef();

  const onIdle = () => {
    setIsTimedOut(true);
    dispatch(logoutUser());
  };

  return (
    <div className="woodcore-dashboard">
      <IdleTimer
        ref={refContainer}
        element={document}
        onIdle={onIdle}
        debounce={250}
        timeout={timeOut}
      />

      <ToastManager />
      {showSecurityModal ? <PasswordSecurity /> : ""}

      <DashboardNavigation />
      <BlockUI />
      <div className="dashboard">
        <MainWrapper>
          <DashboardAsideNaivagation />
          <main
            role="main"
            className={`${
              navigationBarActive ? "main-content-open" : "main-content"
            } pt-5 px-4 pb-5`}
          >
            {children}
          </main>
        </MainWrapper>
      </div>
    </div>
  );
};
TellerLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TellerLayout;
