import React, { useState } from "react";
import { Table } from "antd";
import { formatCurrency } from "../../../../../util/FormatAmount";
import ModalContainer from "../../../components/ModalContainer";
import TransactionDetailsModal from "./TransactionDetailsModal";
import { formatDate } from "../../../../../util/FormatDate";
import { EditButton } from '../../../Configuration/Organization/PaymentType/styles';
import LedgerDetailsModal from "./TransactionDetailsModal/ledgermodal";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { ReactComponent as ThreeDots } from "../../../../../assets/images/icons/three-dots.svg";

const columns = [
  { title: "ID", dataIndex: "id", key: "id" },
  { title: "Transaction Date", dataIndex: "transDate", key: "transDate" },

  {
    title: "Transaction Type",
    dataIndex: "transactionType",
    key: "transactionType",
  },
  { title: "Debit", dataIndex: "debit", key: "debit" },
  { title: "Credit", dataIndex: "credit", key: "credit" },
  {
    title: "Balance ",
    dataIndex: "balance",
    key: "balance",
  },
  { title: "", dataIndex: "action", key: "action" }
];


const Transactions = ({ transactions = [], history, id }) => {
  let transactionData = [];

  const [modalState, toggleModal] = useState(false);
  const [modalContentName, setModalContentName] = useState("");

  const handleViewTransactions = (transaction) => {
    setTransactionDetails(transaction);
    toggleModal(true);
    setModalContentName("viewTransaction");
  };
  const handleViewLedgerTransactions = (transaction) => {
    setTransactionDetails(transaction);
    setModalContentName("ledgerTransaction");
    toggleModal(true);
  };

  const currentContent = (e) => {
    const val = e.currentTarget.id.trim();
    if (val) {
      toggleModal(true);
      setModalContentName(val);
    }
  };

  transactions.forEach((transaction) => {
    const {
      runningBalance,
      amount,
      id,
      currency = {},
      transactionType = {},
      date = [],
    } = transaction || {};

    transactionData.push({
      id: id,
      transDate: formatDate(date),
      debit:
        transactionType.withdrawal ||
          transactionType.feeDeduction ||
          transactionType.dividendPayout ||
          transactionType.initiateTransfer ||
          transactionType.withholdTax
          ? `${currency.code} ${formatCurrency(amount)}`
          : "",
      credit:
        transactionType.deposit ||
          transactionType.interestPosting ||
          transactionType.amountRelease
          ? `${currency.code} ${formatCurrency(amount)}`
          : "",
      transactionType: transactionType.value,
      balance: `${currency.code} ${formatCurrency(runningBalance)}`,
      transaction,
      action: (
        <>
          <Menu direction="rtl">
            {({ isOpen }) => (
              <>
                <MenuButton transition="all 0.2s">
                  <ThreeDots />
                </MenuButton>
                <MenuList className="menu-list-teller">
                  <MenuItem
                    onClick={() => handleViewTransactions(transaction)}
                    className="text-label"
                  >
                    View
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleViewLedgerTransactions(transaction)}
                    className="text-label"
                  >
                    Ledger details
                  </MenuItem>
                </MenuList>
              </>
            )}
          </Menu></>
      )
    });
  });

  const handleModalClose = () => toggleModal(false);
  const displayModalContent = () => {
    switch (modalContentName) {
      case "viewTransaction":
        return <TransactionDetailsModal transactionDetails={transactionDetails} onHide={handleModalClose} />;
      case "ledgerTransaction":
        return (
          <LedgerDetailsModal
            transactions={transactionDetails}
            onHide={handleModalClose}
            module={'fixed-deposit'}
          />
        );
      default:
        return;
    }
  };

  const [transactionDetails, setTransactionDetails] = useState({});

  return (
    <>
      <ModalContainer show={modalState}>
        {modalState && displayModalContent()}
      </ModalContainer>
      <Table
        columns={columns}
        dataSource={transactionData}
        pagination={{ defaultPageSize: 8 }}
        defaultPageSize={8}
      />
    </>
  );
};

export default Transactions;
