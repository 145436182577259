import { GeneratedReport, IconButton } from "./styles";
import Countdown from "react-countdown";
import { formatTime } from "../../../util/FormatDate";

export const ReportCard = ({ report, setAllGeneratedReports }) => {
	const expireDocument = () => {
		setAllGeneratedReports((prevReports) => {
			const updatedReports = prevReports.map((prevReport) => {
				if (prevReport.id === report.id) {
					return { ...prevReport, isExpired: true };
				}
				return prevReport;
			});
			return updatedReports;
		});
	};

	return (
		<div className="position-relative px-2">
			<div className="report-type">
				<img
					src={report?.type === "xlsx"
						? require("../../../assets/images/excel.svg").default
						: require("../../../assets/images/csv.svg").default
					}
					alt=""
					className="shadow-lg"
				/>
			</div>
			<GeneratedReport className="d-flex align-items-end border">
				<div className="d-flex flex-column gap-1 w-100">
					<h4 className="fs-14">{report?.reportName}</h4>
					<div className="d-grid grid-columns-2 align-items-end">
						<div>
							<span className="time_query">Generated: </span>
							<span className="time_value">{formatTime(report.generatedOn)}</span>
						</div>
						{report?.status === "pending" && !report?.file ? (
							<p className="pending p-0">Pending</p>
						) : (
							<div>
								<span className="time_query">
									Expire{report?.isExpired ? 'd: ' : 's in: '}
								</span>
								<span className="time_value">
									{report?.isExpired
										? formatTime(new Date(report?.expiresIn))
										: <Countdown date={report?.expiresIn} onComplete={expireDocument} />}
								</span>
							</div>
						)}
					</div>
				</div>
				{(report?.status !== "pending" && !report?.isExpired && report?.file) && (
					<a
						target="_blank"
						href={report?.file}
						rel="noreferrer"
					>
						<IconButton color="#2C1DFF">
							<img
								src={require("../../../assets/images/icons/download.svg").default}
								alt="download"
								className="icon-img"
							/>
						</IconButton>
					</a>
				)}
			</GeneratedReport>
		</div>
	);
};
