import React, { useState, useEffect, useCallback } from "react";
import { Input, Select, Textarea } from "@chakra-ui/react";
import { ActionButton } from "../../styles";
import { MandateModal } from "./MandateModal";
import {
  SearchResultContainer,
  SavingsAccountContainer,
  Button,
} from "../styles";
import { useDispatch, useSelector } from "react-redux";
import ModalContainer from "../../components/ModalContainer";
import { Select as AntSelect, Checkbox } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Avatar from "react-avatar";
import { fetch } from "../search";
import CurrencyFormat from "react-currency-format";
import axiosData from "../../../../services/axiosData";
import DataTableModal from "./DataTableModal";
import config from "../../../../config";
import { formatCurrency } from "../../../../util/FormatAmount";
import SuccessSvg from "../../../../assets/notification-icons/success.svg";
import InfoSvg from "../../../../assets/notification-icons/info.svg";
import { ReactComponent as Close } from "../../../../assets/images/icons/cancel-black.svg";
import {
  depositWithDrawCash,
  resetDepositWithdrawResponse,
  getTransactionSummary,
  getAllCashierTransactions,
  resetCustomerImage,
  getCustomerImage,
} from "../../../../actions/tellerActions";
import Toast from "../../../../util/Toast";
import { textToUpperCase } from "../../../../util/Tools";
import styled from "styled-components";
import { Modal } from "react-bootstrap";

export const FormSubmit = ({ defaultCurrencyValue }) => {
  const defaultCustomerAccount = {
    customerAccountId: "",
    amount: "",
    clientId: "",
  };
  const [showModal, setShowModal] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [successPendingModal, setModal] = useState(false);
  const [modalStatus, setModalStatus] = useState("");
  const dispatch = useDispatch();
  const [transactionNote, setTransactionNote] = useState("");
  const [cashierNote, setCashierNote] = useState("");
  const [command, setCommand] = useState("");
  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [savingsSelected, setSavingsSelected] = useState("");
  const [customerSelected, setCustomerSelected] = useState();
  const [mandateShow, setMandateShow] = useState(false);
  const [savingAccountData, setSavingAccountData] = useState({});
  const [selectedCustomerData, setSelectedCustomerData] = useState();
  const [currencyValue, setCurrencyValue] = useState("");
  const [isSearchByNuban, setIsSearchByNuban] = useState(false);
  const [savingsAccounts, setSavingsAccounts] = useState([]);
  const [customerAccount, setCustomerAccount] = useState(
    defaultCustomerAccount
  );

  const toggleIsSearchByNuban = (checked) => setIsSearchByNuban(checked);

  const {
    userData: { username },
  } = useSelector((state) => state.userData);
  const { depositWithdrawResponseData, clientImageData } = useSelector(
    (state) => state.tellerReducer
  );

  const dropdownValue = localStorage.getItem("myDefaultValue");
  let currentCashierId = localStorage.getItem("cashierId");


  useEffect(() => {
    if (dropdownValue) {
      // let oldCurrency = [];
      //  const oldCurrency = {...dropdownValue};
      //  const keyValue = oldCurrency[1];
      setCurrencyValue(dropdownValue);
    } else setCurrencyValue(defaultCurrencyValue);
  }, [dropdownValue, defaultCurrencyValue]);

  const [savingsOpt, setOpt] = useState([]);
  const [dat, setDat] = useState([]);
  const { Option } = AntSelect;
  let cashierId = localStorage.getItem("cashierId");
  let tellerGroupId = localStorage.getItem("tellerGroupId");

  const reFetchStat = () => {
    if (tellerGroupId && cashierId) {
      dispatch(getTransactionSummary(tellerGroupId, cashierId));
    }
  };

  const reFetchCashierTransaction = () => {
    if (currentCashierId) {
      dispatch(
        getAllCashierTransactions(
          `?cashierId=${currentCashierId}&offset=0&limit=9`
        )
      );
    }
  }


  const handleSearch = (value) => {
    if (isSearchByNuban && isNaN(Number(value))) return;
    if (!value.length) {
      setData([]);
      setFetching(false);
    };
    setFetching(true);
    fetch(value, ({ filteredData, allData }) => {
      setFetching(false);
      setData(filteredData?.filter(({ type }) => type === 'CLIENT'));
      setSavingsAccounts(allData?.filter(({ entityType }) => entityType === 'SAVING'));
    });
  };

  useEffect(() => {    
    if (isSearchByNuban && savingsAccounts) {
      const savingsAccountSelected = savingsAccounts?.find((e) => e.parentId == customerAccount.clientId);      
      setCustomerAccount({
        ...customerAccount,
        customerAccountId: savingsAccountSelected?.entityAccountNo,
        customerAccountBalance: savingsAccountSelected?.entityAccountBalance
      });
    };
  }, [isSearchByNuban, savingsAccounts, customerAccount.clientId]);

  // useEffect(() => {
  //   if (defaultCurrencyValue) {
  //     // let oldCurrency = [];
  //     // const oldCurrency = { ...defaultCurrencyValue };
  //     // const keyValue = oldCurrency[1];
  //     setCurrencyValue(defaultCurrencyValue);
  //   } else setCurrencyValue("");
  // }, [defaultCurrencyValue]);

  const closeSuccessPendingModal = () => {
    dispatch(resetDepositWithdrawResponse());
    setModal(false);
  };

  useEffect(() => {
    dispatch(resetDepositWithdrawResponse());
  }, []);

  useEffect(() => {
    if (
      depositWithdrawResponseData?.resourceId &&
      depositWithdrawResponseData?.transactionId
    ) {
      setModalStatus("success");
      setModal(true);
    } else if (depositWithdrawResponseData?.resourceId) {
      setModalStatus("pending");
      setModal(true);
    }
  }, [
    depositWithdrawResponseData?.resourceId,
    depositWithdrawResponseData?.transactionId,
  ]);

  const handleSubmit = () => {
    const dataToSubmit = {
      locale: "en",
      dateFormat: "dd MMM yyyy",
      currencyCode: isSearchByNuban ? "NGN" : savingAccountData?.currencyCode ?? currencyValue,
      paymentTypeId: "1",
      transactionAmount: Number(customerAccount?.amount),
      savingsAccountId: customerAccount?.customerAccountId,
      transactionNote: transactionNote,
      cashierNote: cashierNote,
    };
    
    if (
      dataToSubmit.cashierNote &&
      dataToSubmit.transactionAmount &&
      dataToSubmit.dateFormat &&
      dataToSubmit.locale &&
      dataToSubmit.transactionNote &&
      dataToSubmit.currencyCode &&
      dataToSubmit.savingsAccountId &&
      command
    ) {
      dispatch(depositWithDrawCash(dataToSubmit, command, reFetchStat , reFetchCashierTransaction));
      setShowModal(false);
      setCommand("");
      setTransactionNote("");
      setCashierNote("");
      setData([]);
      setSavingsSelected(null);
      setFetching(false);
      setSavingAccountData({});
      setSelectedCustomerData();
      setCustomerAccount(defaultCustomerAccount);
    } else {
      dispatch(
        Toast({
          text: "Fill all required fields",
          icon: "error",
          duration: "5",
        })
      );
    }
  };

  const searchOption =
    data &&
    data?.map((d) => (
      <Option key={d.value} cifNumber={d.clientCIFNumber} name={d.clientName}>
        {textToUpperCase(d.clientName)} {d.clientCIFNumber}
      </Option>
    ));

  const getSavingsAcc = useCallback((id) => {
    if (!id) return;
    if (id) {
      return new Promise(async (resolve, reject) => {
        axiosData({
          method: "GET",
          url: `${config.apiGateway.BASE_API_URL}/clients/${id}/accounts?fields=savingsAccounts`,
        })
          .then(({ data = [] }) => {
            if (data?.savingsAccounts) {
              setDat(data);
            } else if (!data?.savingsAccounts) {
              setOpt([]);
            }
            resolve(
              data?.savingsAccount?.map(({ accountNo, productName }) => ({
                value: accountNo,
                name: `${accountNo}(${productName})`,
              }))
            );
          })
          .catch(reject);
      });
    } else {
      return;
    }
  }, []);

  const getCustomerImg = useCallback(
    (id) => {
      if (!id) return;
      if (id) {
        dispatch(getCustomerImage(id));
      } else {
        return;
      }
    },
    [dispatch]
  );



  useEffect(() => {
    if (dat && dat?.savingsAccounts && dat?.savingsAccounts?.length) {
      const newData = dat?.savingsAccounts
        // ?.filter((account) => {
        //   return account?.currency?.code === currencyValue;
        // })
        ?.filter(({ status }) => status?.value === "Active")
        ?.map(({ accountNo, productName, accountBalance, id, currency }) => ({
          value: accountNo,
          name: `${accountNo}${" "}(${productName})`,
          accountBalance: accountBalance,
          savingsAccId: id,
          currencyCode: currency?.code
        }));
      setOpt(newData);
    }
  }, [dat]);

  const handleClientToChange = (timeStamp) => {
    dispatch(resetCustomerImage());
    getSavingsAcc(timeStamp);
    getCustomerImg(timeStamp);
    setCustomerAccount({
      ...customerAccount,
      clientId: timeStamp,
    });
  };

  const handleCustomerAccountToChange = (timeStamp, options) => {
    setSavingAccountData(options);
    setCustomerAccount({
      ...customerAccount,
      customerAccountId: timeStamp,
    });
  };

  const handleCustomerAmountToChange = (timeStamp) => {
    setCustomerAccount({
      ...customerAccount,
      amount: timeStamp,
    });
  };
  const savingsAccOption =
    savingsOpt &&
    savingsOpt?.map((d) => (
      <Option
        key={d.value}
        savingsAccId={d?.savingsAccId}
        accountBalance={d.accountBalance}
        currencyCode={d.currencyCode}
      >
        {textToUpperCase(d.name)}
      </Option>
    ));


  return (
    <>
      <ModalContainer size="md" show={mandateShow}>
        {mandateShow && (
          <MandateModal
            onHide={() => setMandateShow(false)}
            id={customerAccount?.clientId}
          />
        )}
      </ModalContainer>
      <ModalContainer
        size="md"
        show={showDetails && savingAccountData?.savingsAccId}
      >
        {showDetails && savingAccountData?.savingsAccId && (
          <DataTableModal
            onHide={() => setShowDetails(false)}
            savingsAccId={savingAccountData?.savingsAccId}
          />
        )}
      </ModalContainer>
      <form
        // onSubmit={(e) => {
        //   e.preventDefault();
        //   setShowModal(true);
        // }}
        className="d-flex justify-content-between pt-4 mt-5 teller-transaction-form row"
      >
        <ModalContainer size="md" show={showModal}>
          {showModal && (
            <DetailsModal
              currencyValue={currencyValue}
              command={command}
              transactionNote={transactionNote}
              cashierNote={cashierNote}
              customerAccount={customerAccount}
              username={username}
              submit={handleSubmit}
              closeDetailsModal={() => setShowModal(false)}
            />
          )}
        </ModalContainer>
        <ModalContainer size="md" show={successPendingModal}>
          {successPendingModal && (
            <SuccessPendingModal
              modalStatus={modalStatus}
              savingsTransactionId={depositWithdrawResponseData?.transactionId}
              closeSuccessPendingModal={closeSuccessPendingModal}
            />
          )}
        </ModalContainer>

        <div style={{ width: "100%" }}>
          <div>
            <div className="p-relative h-100">
              <div className="">
                <div className="form-group d-flex justify-content-between align-items-between">
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{ width: "40%" }}
                  >
                    <label className="text-label mb-0">
                      Search Customer<span className="required-color">*</span>
                    </label>
                    <div className="d-flex flex-column gap-2 w-60">
                      <AntSelect
                        notFoundContent={fetching ? <LoadingOutlined size="small" /> : "No result"}
                        allowClear
                        style={{ width: "100%" }}
                        onClear={() => {
                          setData([]);
                          setFetching(false);
                          setCustomerAccount({
                            ...customerAccount,
                            customerAccountId: "",
                            clientId: ""
                          });
                        }}
                        loading={true}
                        showSearch
                        placeholder={`Search customers by ${isSearchByNuban ? "NUBAN" : "Name/ID"}`}
                        showArrow={false}
                        filterOption={false}
                        value={customerAccount.clientId}
                        onSearch={handleSearch}
                        onChange={(value) => setCustomerSelected(value)}
                        onSelect={(selected, options) => {
                          handleClientToChange(selected);
                          setSelectedCustomerData(options);
                          setCustomerAccount({
                            ...customerAccount,
                            clientId: selected,
                            customerAccountId: ""
                          });
                        }}
                      >
                        {searchOption}
                      </AntSelect>
                      <Checkbox
                        checked={isSearchByNuban}
                        onChange={(event) => {
                          toggleIsSearchByNuban(event?.target?.checked);
                          setCustomerAccount({
                            ...customerAccount,
                            customerAccountId: "",
                            clientId: ""
                          });
                        }}
                        className="fs-10"
                      >
                        Search by NUBAN
                      </Checkbox>
                    </div>
                  </div>
                  {selectedCustomerData && customerAccount.clientId ? (
                    <SearchResultContainer className="d-flex justify-content-between">
                      <div className="d-flex">
                        <div>
                          <Avatar
                            src={clientImageData}
                            round={true}
                            size="45px"
                            name={selectedCustomerData?.name}
                          />
                        </div>
                        <div className="name-container">
                          <h4>{selectedCustomerData?.name}</h4>
                          <p>{selectedCustomerData?.cifNumber}</p>
                        </div>
                      </div>
                      <div>
                        <Button
                          type="button"
                          onClick={() => setMandateShow(true)}
                        >
                          Mandate
                        </Button>
                      </div>
                    </SearchResultContainer>
                  ) : (
                    <div style={{ height: "100px" }} />
                  )}
                </div>
                <hr />
                <div className="form-group d-flex mt-5 justify-content-between align-items-center">
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{
                      width: "40%",
                      paddingBottom: "40px",
                      position: "relative",
                      fontFamily: "AventaMedium",
                      fontSize: "12px",
                    }}
                  >
                    <label className="text-label">
                      Savings Account <span className="required-color"> *</span>
                    </label>
                    {isSearchByNuban ? (
                      <Input
                        style={{ width: "60%" }}
                        size="md"
                        placeholder={customerAccount?.clientId &&
                          !customerAccount.customerAccountId
                            ? "Selected client has no savings account"
                            : ""}
                        value={customerAccount.customerAccountId &&
                          customerAccount.clientId
                            ? customerAccount.customerAccountId
                            : ""}
                        disabled
                      />
                    ) : (
                      <AntSelect
                        optionFilterProp="children"
                        notFoundContent="Customer has no savings account"
                        showSearch
                        value={customerAccount.customerAccountId}
                        onSelect={(value, options) => {
                          handleCustomerAccountToChange(value, options);
                        }}
                        name="savings acc"
                        placeholder="Select savings account"
                        style={{ width: "60%" }}
                      >
                        {savingsAccOption}
                      </AntSelect>
                    )}
                  </div>
                  <SavingsAccountContainer>
                    <div>
                      <p>Account balance:</p>
                      <h4>
                        {isSearchByNuban ? 'NGN' : savingAccountData?.currencyCode ?? currencyValue ?? ""}{" "}
                        {isSearchByNuban && customerAccount?.customerAccountId
                          ? !customerAccount?.customerAccountBalance
                            ? '-- --'
                            : customerAccount?.customerAccountBalance
                              ? formatCurrency(Number(customerAccount?.customerAccountBalance))
                              : "00.00"
                          : savingAccountData?.accountBalance
                            ? formatCurrency(Number(savingAccountData?.accountBalance))
                            : "00.00"
                        }
                      </h4>
                    </div>
                    {!savingAccountData?.savingsAccId ? (
                      ""
                    ) : (
                      <div>
                        <Button
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            setShowDetails(true);
                          }}
                        >
                          Details
                        </Button>
                      </div>
                    )}
                  </SavingsAccountContainer>
                </div>
              </div>
              <div className="d-flex mt-5">
                <div
                  style={{ width: "40%" }}
                  className="form-group d-flex justify-content-between align-items-center"
                >
                  <label className="text-label">
                    Amount<span className="required-color">*</span>
                  </label>
                  <CurrencyFormat
                    required
                    value={customerAccount.amount}
                    thousandSeparator={true}
                    className="amount-input"
                    style={{
                      width: "60%",
                    }}
                    onValueChange={(values) => {
                      const { value } = values;
                      handleCustomerAmountToChange(value);
                    }}
                  />
                </div>
                <div
                  style={{ width: "40%", margin: "auto" }}
                  className="form-group d-flex justify-content-between align-items-center"
                >
                  <label className="text-label mr-4 w-full">
                    Transaction Type<span className="required-color">*</span>
                  </label>
                  <Select
                    required
                    value={command}
                    onChange={(e) => setCommand(e.target.value)}
                    placeholder="Select option"
                  >
                    <option value="deposit">Deposit</option>
                    <option value="withdraw">Withdrawal</option>
                  </Select>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex mt-4">
            <div
              className="form-group d-flex justify-content-between align-items-center"
              style={{ width: "40%" }}
            >
              <label className="text-label d-flex flex-align-center">
                Transaction note <span className="required-color">*</span>
              </label>
              <Textarea
                required
                value={transactionNote}
                onChange={(e) => setTransactionNote(e.target.value)}
                style={{ width: "70%", fontSize: "12px" }}
                placeholder="Enter transaction description"
              />
            </div>
            <div
              style={{ width: "40%", margin: "auto" }}
              className="form-group d-flex justify-content-between align-items-center"
            >
              <label className="text-label d-flex flex-align-center">
                Cashier note<span className="required-color">*</span>
              </label>
              <Textarea
                required
                value={cashierNote}
                onChange={(e) => setCashierNote(e.target.value)}
                style={{ width: "70%", fontSize: "12px" }}
                name="productName"
                placeholder="Enter your description here"
              />
            </div>
          </div>
        </div>
        <div
          style={{
            width: "91%",
            display: "flex",
            marginTop: "40px",
            justifyContent: "flex-end",
          }}
        >
          <ActionButton onClick={() => setShowModal(true)} type="button">
            Proceed
          </ActionButton>
        </div>
      </form>
    </>
  );
};

const DetailsModal = ({
  username,
  command,
  transactionNote,
  cashierNote,
  customerAccount,
  submit,
  closeDetailsModal,
  currencyValue,
}) => {
  return (
    <div>
      <Modal.Header>
        <Modal.Title>Details</Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <div className="p-1 pb-3 position-relative">
          <div className="d-flex mb-3 ">
            <TextQuestion>Teller Name </TextQuestion> <Texts>{username}</Texts>
          </div>

          <div className="d-flex mb-3 ">
            <TextQuestion>Amount </TextQuestion>{" "}
            <Texts>
              {currencyValue ? currencyValue : ""}{" "}
              {formatCurrency(Number(customerAccount?.amount)) ||
                "No amount entered"}
            </Texts>
          </div>

          <div className="d-flex mb-3 ">
            <TextQuestion>Transaction type </TextQuestion>{" "}
            <Texts>{command || "No type selected"}</Texts>
          </div>

          <div className="d-flex mb-3 ">
            <TextQuestion>Teller note</TextQuestion>{" "}
            <Texts>{transactionNote || "No description entered"}</Texts>
          </div>
          <div className="d-flex mb-3 ">
            <TextQuestion>Cashier note</TextQuestion>{" "}
            <Texts>{cashierNote || "No description entered"}</Texts>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="float-right mt-1">
          <ActionButton color="#2C1DFF" onClick={closeDetailsModal}>
            <img
              src={
                require("../../../../assets/images/icons/cancel.svg").default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>

          <ActionButton color="#2C1DFF" onClick={submit}>
            <img
              src={require("../../../../assets/images/icons/save.svg").default}
              alt="cancel icon"
            />
            Submit
          </ActionButton>
        </div>
      </Modal.Footer>
    </div>
  );
};

const SuccessPendingModal = ({
  modalStatus,
  savingsTransactionId,
  closeSuccessPendingModal,
}) => {
  const [transactionDetailsModal, setDetailsModal] = useState(false);
  const { cashierTransactions } = useSelector((state) => state.tellerReducer);
  const dispatch = useDispatch();

  const getTransactionDetails = (savingsTransactionId) => {
    setDetailsModal(true);
    const data = `/${savingsTransactionId}`;
    if (savingsTransactionId) {
      dispatch(getAllCashierTransactions(data));
    }
  };
  return (
    <>
      {transactionDetailsModal === false ? (
        <div>
          {modalStatus === "success" ? (
            <img
              style={{ width: "60px", margin: "auto", marginTop: "25px" }}
              src={SuccessSvg}
              alt=""
            />
          ) : null}
          {modalStatus === "pending" ? (
            <img
              style={{ width: "60px", margin: "auto", marginTop: "25px" }}
              src={InfoSvg}
              alt=""
            />
          ) : null}

          <div
            style={{
              padding: "30px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "18px",
            }}
            className="aventa"
          >
            {modalStatus === "success" ? (
              <>
                <p>Transaction was successful.</p>
                <ActionButton
                  style={{ marginTop: "15px" }}
                  onClick={() => getTransactionDetails(savingsTransactionId)}
                >
                  View transaction details
                </ActionButton>
                {/* <p>Transaction Id is: {savingsTransactionId}</p> */}
              </>
            ) : (
              <p style={{ textAlign: "center" }}>
                Transaction is pending and requires approval from teller manager
              </p>
            )}
          </div>

          <button
            onClick={closeSuccessPendingModal}
            style={{ position: "absolute", top: 0, right: 0, padding: "15px" }}
          >
            <Close />
          </button>
        </div>
      ) : (
        ""
      )}

      {transactionDetailsModal === true ? (
        <div>
          <Modal.Header>
            <Modal.Title>Transaction details</Modal.Title>
          </Modal.Header>

          <Modal.Body className="wc-modal aventa">
            <div className="p-1 position-relative">
              <div className="d-flex mb-3 ">
                <TextQuestion>Amount </TextQuestion>{" "}
                <Texts>{cashierTransactions?.currencyData?.code ? cashierTransactions?.currencyData?.code : ""} &nbsp;{`${cashierTransactions?.transactionAmount}`}</Texts>
              </div>

              <div className="d-flex mb-3 ">
                <TextQuestion>Transaction ID </TextQuestion>{" "}
                <Texts>{cashierTransactions?.transactionId}</Texts>
              </div>

              <div className="d-flex mb-3 ">
                <TextQuestion>Account number </TextQuestion>{" "}
                <Texts>
                  {
                    cashierTransactions?.savingsAccountTransactionData
                      ?.accountNo
                  }
                </Texts>
              </div>

              <div className="d-flex mb-3 ">
                <TextQuestion>Balance derived </TextQuestion>{" "}
                <Texts>
                {cashierTransactions?.currencyData?.code ? cashierTransactions?.currencyData?.code : ""} ({cashierTransactions?.runningBalanceDerived})
                </Texts>
              </div>

              <div className="d-flex mb-3 ">
                <TextQuestion>Transaction type </TextQuestion>{" "}
                <Texts>
                  {
                    cashierTransactions?.savingsAccountTransactionData
                      ?.transactionType?.value
                  }
                </Texts>
              </div>
              <div className="d-flex mb-3 ">
                <TextQuestion>Transaction note </TextQuestion>{" "}
                <Texts>{cashierTransactions?.transactionNote}</Texts>
              </div>
              <div className="d-flex mb-3 ">
                <TextQuestion>Cashier note </TextQuestion>{" "}
                <Texts>{cashierTransactions?.cashierNote}</Texts>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="float-right mt-1">
              <ActionButton color="#2C1DFF" onClick={closeSuccessPendingModal}>
                <img
                  src={
                    require("../../../../assets/images/icons/cancel.svg")
                      .default
                  }
                  alt="cancel icon"
                />
                Cancel
              </ActionButton>
            </div>
          </Modal.Footer>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

const TextQuestion = styled.p`
  width: 40%;
  font-size: 12px;
  font-family: "AventaSemiBold";
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: black;
  margin-right: 25px;
`;

const Texts = styled.p`
  font-size: 12px;
  font-family: "AventaRegular";
  color: black;
  text-align: center;
`;
