import React from "react";

import { Table } from "react-bootstrap";

import { formatCurrency } from "../../../../../util/FormatAmount";
import { formatDate } from "../../../../../util/FormatDate";

const SavingsDetails = ({ savingsAccountDetails }) => {
  let {
    fieldOfficerName,
    id,
    externalId,

    overdraftLimit,
    nominalAnnualInterestRateOverdraft,
    minOverdraftForInterestCalculation,
    allowOverdraft,
    allowWithdrawalLimit,
    withdrawalLimit,

    currency = {},
    status = {},
    subStatus = {},
    interestCalculationDaysInYearType = {},
    interestCalculationType = {},
    interestCompoundingPeriodType = {},
    interestPostingPeriodType = {},
    summary = {},
    timeline = {},
    lastActiveTransactionDate = [],
    nominalAnnualInterestRate = "",
  } = savingsAccountDetails || {};

  let { activatedOnDate = [] } = timeline || {};


  let {
    totalDeposits,
    totalWithdrawals,
    totalOverdraftInterestDerived,
    interestNotPosted,
    totalInterestPosted,
  } = summary || {};
  return (
    <>
      <div className="row">
        <div className="col-xl-6 col-md-12">
          <div className="m-2">
            <Table
              responsive
              striped
              bordered
              hover
              className="table-form-style"
            >
              <thead className="border-0">
                <tr>
                  <td className="md-font-weight">Activation Status</td>
                  <td>
                    <>
                      <div>
                        {status.active ? (
                          <span className="new-text-success-table">
                            {" "}
                            {(status && status.value) || ""}
                            <span
                              style={{ fontSize: "12px" }}
                              className="text-danger"
                            >
                              {subStatus && subStatus.block && " (Blocked)"}
                              {subStatus && subStatus.blockDebit && " (On PND)"}
                            </span>
                          </span>
                        ) : (
                          <span className="text-danger">
                            {/* {(status && status.value) || ""} */}
                          </span>
                        )}
                        {status.id === 100 ? (
                          <>
                            {" "}
                            <span className="new-text-pending-table">
                              {" "}
                              {status &&
                              status.value.toLowerCase() ===
                                "submitted and pending approval"
                                ? "Pending"
                                : (status && status.value) || ""}
                            </span>{" "}
                          </>
                        ) : (
                          ""
                        )}
                        {status.id === 200 ? (
                          <>
                            {" "}
                            <span className="new-text-info-table">
                              {" "}
                              {(status && status.value) || ""}
                            </span>{" "}
                          </>
                        ) : (
                          ""
                        )}
                        {status.id === 600 ||
                        status.id === 500 ||
                        status.id === 400 ? (
                          <>
                            <span className="new-text-danger-table">
                              {" "}
                              {(status && status.value) || ""}
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  </td>
                </tr>
              </thead>
              <tbody>
                {status.active && !!totalWithdrawals ? (
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Total Withdrawal
                    </td>
                    <td>
                      {" "}
                      {!!currency.code ? currency.code : ""}{" "}
                      {formatCurrency(totalWithdrawals)}
                    </td>
                  </tr>
                ) : null}
                {status.active && !!totalOverdraftInterestDerived ? (
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Interest earned
                    </td>
                    <td>
                      {" "}
                      {!!currency.code ? currency.code : ""}{" "}
                      {!!totalOverdraftInterestDerived
                        ? totalOverdraftInterestDerived
                        : "0"}
                    </td>
                  </tr>
                ) : null}
                {status.active && !!totalInterestPosted ? (
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Interest posted
                    </td>
                    <td>
                      {!!currency.code ? currency.code : ""}{" "}
                      {!!totalInterestPosted ? totalInterestPosted : "0"}
                    </td>
                  </tr>
                ) : null}{" "}
                {status.active && !!interestNotPosted ? (
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Earned interest not posted
                    </td>
                    <td>
                      {!!currency.code ? currency.code : ""}{" "}
                      {!!interestNotPosted ? interestNotPosted : "0"}
                    </td>
                  </tr>
                ) : null}
                {status.active && !!nominalAnnualInterestRate ? (
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Nominal interest rate
                    </td>
                    <td>
                      {!!nominalAnnualInterestRate
                        ? nominalAnnualInterestRate
                        : "0"}
                      %
                    </td>
                  </tr>
                ) : null}
                {status.active && !!interestCompoundingPeriodType.value ? (
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Interest compounding period
                    </td>
                    <td>
                      {!!interestCompoundingPeriodType.value
                        ? interestCompoundingPeriodType.value
                        : ""}
                    </td>
                  </tr>
                ) : null}
                {status.active && !!interestPostingPeriodType.value ? (
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Interest posting period
                    </td>
                    <td>
                      {!!interestPostingPeriodType.value
                        ? interestPostingPeriodType.value
                        : ""}
                    </td>
                  </tr>
                ) : null}
                {status.active && !!interestCalculationType.value ? (
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Interest calculated using
                    </td>
                    <td>
                      {!!interestCalculationType.value
                        ? interestCalculationType.value
                        : ""}
                    </td>
                  </tr>
                ) : null}
                {status.active && !!interestCalculationDaysInYearType.value ? (
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      # Days in Year
                    </td>
                    <td>
                      {!!interestCalculationDaysInYearType.value
                        ? interestCalculationDaysInYearType.value
                        : ""}
                    </td>
                  </tr>
                ) : null}
                {status.active ? (
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                    Is Overdraft Allowed
                    </td>
                    <td>
                      {!!allowOverdraft
                        ? 'Yes'
                        : "No"}
                    </td>
                  </tr>
                ) : null}
                {status.active && !!allowOverdraft ? (
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                    Max. Overdraft Amount Limit
                    </td>
                    <td>
                      {!!overdraftLimit
                        ? formatCurrency(overdraftLimit)
                        : ""}
                    </td>
                  </tr>
                ) : null}
                {status.active && !!allowOverdraft ? (
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                    Interest Rate For Overdraft
                    </td>
                    <td>
                      {!!nominalAnnualInterestRateOverdraft
                        ? nominalAnnualInterestRateOverdraft
                        : ""}
                    </td>
                  </tr>
                ) : null}
                {status.active && !!allowOverdraft ? (
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                    Min. Overdraft For Interest Calc.
                    </td>
                    <td>
                      {!!minOverdraftForInterestCalculation
                        ? formatCurrency(minOverdraftForInterestCalculation)
                        : ""}
                    </td>
                  </tr>
                ) : null}
                {status.active && !!lastActiveTransactionDate.length ? (
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Last Active Transaction Date
                    </td>
                    <td>
                      {lastActiveTransactionDate.length ? formatDate(lastActiveTransactionDate) : ""}
                    </td>
                  </tr>
                ) : null}
                 {status.active ? (
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                    Enable Withdrawal Count<br/> For Interest Posting
                    </td>
                    <td>
                      {!!allowWithdrawalLimit
                        ? 'Yes'
                        : "No"}
                    </td>
                  </tr>
                ) : null}
                {status.active && allowWithdrawalLimit ? (
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                    Withdrawal Limit Number
                    </td>
                    <td>
                      {withdrawalLimit}
                    </td>
                  </tr>
                ) : null}
                {/* <tr>
                      <td className="font-weight-bold md-font-weight">
                        Interest Recalculation Date
                      </td>
                      <td>23 September 2020</td>
                    </tr> */}
              </tbody>
            </Table>
          </div>
        </div>
        <div className="col-xl-6 col-md-12">
          <div className="m-2">
            <Table
              responsive
              striped
              bordered
              hover
              className="table-form-style"
            >
              <thead className="border-0">
                <tr>
                  <td
                    className="font-weight-bold md-font-weight text-center"
                    colSpan="2"
                  >
                    Savings Details
                  </td>
                </tr>
              </thead>
              <tbody>
                {status.active && !!activatedOnDate.length ? (
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Activation Date
                    </td>
                    <td>
                      {activatedOnDate.length ? formatDate(activatedOnDate) : ""}
                    </td>
                  </tr>
                ) : null}
                {!!fieldOfficerName && fieldOfficerName.length ? (
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Field Officer
                    </td>
                    <td>
                      {!!fieldOfficerName ? fieldOfficerName : "Unassigned"}
                    </td>
                  </tr>
                ) : null}
                {status.active && !!id ? (
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      External Id
                    </td>
                    <td>{!!externalId ? externalId : ""}</td>
                  </tr>
                ) : null}
                {status.active && !!currency.displayLabel ? (
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Currency
                    </td>
                    <td>
                      {!!currency.displayLabel ? currency.displayLabel : ""}
                    </td>
                  </tr>
                ) : null}
                {status.active && !!nominalAnnualInterestRate ? (
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Nominal interest rate
                    </td>
                    <td>
                      {" "}
                      {!!nominalAnnualInterestRate
                        ? nominalAnnualInterestRate
                        : "0"}
                      %
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </Table>
          </div>

          <div className="m-2 mt-1">
            <Table
              responsive
              striped
              bordered
              hover
              className="table-form-style"
            >
              <thead className="border-0">
                <tr>
                  <td
                    className="font-weight-bold md-font-weight text-center"
                    colSpan="2"
                  >
                    Performance History
                  </td>
                </tr>
              </thead>
              <tbody>
                {status.active && !!totalDeposits ? (
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Deposit Total
                    </td>
                    <td>
                      {" "}
                      {!!currency.code ? currency.code : ""}{" "}
                      {!!totalDeposits ? formatCurrency(totalDeposits) : ""}
                    </td>
                  </tr>
                ) : null}
                {status.active && !!totalInterestPosted ? (
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Interest earned
                    </td>
                    <td>
                      {!!currency.code ? currency.code : ""}{" "}
                      {!!totalInterestPosted
                        ? formatCurrency(totalInterestPosted)
                        : "0"}
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default SavingsDetails;
