export const GET_CLIENT_TIERS_REQUEST = 'GET_CLIENT_TIERS_REQUEST';
export const GET_CLIENT_TIERS_SUCCESS = 'GET_CLIENT_TIERS_SUCCESS';
export const GET_CLIENT_TIERS_FAIL = 'GET_CLIENT_TIERS_FAIL';

export const CREATE_NEW_CLIENT_TIERS = 'CREATE_NEW_CLIENT_TIERS';
export const CREATE_NEW_CLIENT_TIERS_SUCCESS =
  'CREATE_NEW_CLIENT_TIERS_SUCCESS';
export const CREATE_NEW_CLIENT_TIERS_FAIL = 'CREATE_NEW_CLIENT_TIERS_FAIL';

export const EDIT_CLIENT_TIER = 'EDIT_CLIENT_TIER';
export const EDIT_CLIENT_TIER_SUCCESS = 'EDIT_CLIENT_TIER_SUCCESS';
export const EDIT_CLIENT_TIER_FAIL = 'EDIT_CLIENT_TIER_FAIL';

export const DELETE_CLIENT_TIER = 'DELETE_CLIENT_TIER';
export const DELETE_CLIENT_TIER_SUCCESS = 'DELETE_CLIENT_TIER_SUCCESS';
export const DELETE_CLIENT_TIER_FAIL = 'DELETE_CLIENT_TIER_FAIL';
