/* eslint-disable import/no-anonymous-default-export */
import {
  DASHBOARD_FAIL,
  DASHBOARD_REQUEST,
  DASHBOARD_SUCCESS,
  CLEAR_FILTER,
  // FILTER_STATISTICS_REQUEST,
} from "../constants/dashboardConstants";

export default (state = { statistics: [] }, action) => {
  switch (action.type) {
    case DASHBOARD_REQUEST:
      return { ...state, loading: true };
    case DASHBOARD_SUCCESS:
      return { ...state, loading: false, statistics: action.payload };
    case DASHBOARD_FAIL:
      return { ...state, loading: false, error: action.payload };
    // case FILTER_STATISTICS_REQUEST:
    //     return {...state,
    //         filtered: state.statistics.filter(cst => {
    //             const regex = new RegExp(`${action.payload}`, 'gi')
    //             return cst.firstname.match(regex) || cst.lastname.match(regex) || cst.accountNo.match(regex) || cst.externalId && cst.externalId.match(regex)
    //         })}
    case CLEAR_FILTER:
      return { ...state, filtered: null };
    default:
      return state;
  }
};
