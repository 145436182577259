import React from "react";
// import { useSelector } from "react-redux";
import { Select, Input } from "@chakra-ui/react";

import { FormClass, FormElements, NextActionButton } from "../../../styles.jsx";

const SavingsTerms = ({
  handleNextScreen,
  handlePreviousScreen,
  nominalAnnualInterest,
  interestPostingPeriod,
  interestCompoundingPeriod,
  daysInYear,
  interestCalculated,
  createSavingsProductHandler,
  template,
  setTermsError,
  renderTooltip,
}) => {
  return (
    <>
      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Nominal Annual Interest*{" "}
                  {renderTooltip("Nominal Annual Interest")}
                </label>
                <Input
                  style={{ width: "60%" }}
                  size="md"
                  type="number"
                  name="nominalAnnualInterest"
                  value={nominalAnnualInterest}
                  onChange={createSavingsProductHandler}
                />
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Interest Posting Period*{" "}
                  {renderTooltip("Interest Posting Period")}
                </label>
                <Select
                  size="md"
                  name="interestPostingPeriod"
                  value={interestPostingPeriod}
                  onChange={createSavingsProductHandler}
                >
                  <option value="">Select posting period</option>
                  {template !== undefined && template
                    ? template.interestPostingPeriodTypeOptions &&
                    template.interestPostingPeriodTypeOptions.map((cur) => (
                      <option value={cur.id} key={cur.id}>
                        {cur.value}
                      </option>
                    ))
                    : null}
                </Select>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>

      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Interest Compounding Period*
                  {renderTooltip("Interest Compounding Period")}
                </label>
                <Select
                  size="md"
                  name="interestCompoundingPeriod"
                  value={interestCompoundingPeriod}
                  onChange={createSavingsProductHandler}
                >
                  <option value="">Select compounding period</option>
                  {template !== undefined && template
                    ? template.interestCompoundingPeriodTypeOptions &&
                    template.interestCompoundingPeriodTypeOptions.map(
                      (cur) => (
                        <option value={cur.id} key={cur.id}>
                          {cur.value}
                        </option>
                      )
                    )
                    : null}
                </Select>
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Days In The Year*
                  {renderTooltip(" Days In The Year")}
                </label>
                <Select
                  size="md"
                  name="daysInYear"
                  value={daysInYear}
                  onChange={createSavingsProductHandler}
                >
                  <option value="">Select Days in the year</option>
                  {template !== undefined && template
                    ? template.interestCalculationDaysInYearTypeOptions &&
                    template.interestCalculationDaysInYearTypeOptions.map(
                      (cur) => (
                        <option value={cur.id} key={cur.id}>
                          {cur.value}
                        </option>
                      )
                    )
                    : null}
                </Select>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>

      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Interest Calculated Using*
                  {renderTooltip("Interest Calculated Using")}
                </label>
                <Select
                  size="md"
                  name="interestCalculated"
                  value={interestCalculated}
                  onChange={createSavingsProductHandler}
                >
                  <option value="">Select using</option>
                  {template !== undefined && template
                    ? template.interestCalculationTypeOptions &&
                    template.interestCalculationTypeOptions.map((cur) => (
                      <option value={cur.id} key={cur.id}>
                        {cur.value}
                      </option>
                    ))
                    : null}
                </Select>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>

      <div>
        <div className="float-right pt-5">
          <NextActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={handlePreviousScreen}
          >
            <img
              src={
                require("../../../../../../../../assets/images/icons/arrow-left.svg")
                  .default
              }
              alt="cancel icon"
            />
            Previous
          </NextActionButton>
          <NextActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={() => {
              interestPostingPeriod &&
                interestCompoundingPeriod &&
                daysInYear &&
                interestCalculated
                ? setTermsError(false)
                : setTermsError(true);
              handleNextScreen();
            }}
          >
            <img
              src={
                require("../../../../../../../../assets/images/icons/arrow-right.svg")
                  .default
              }
              alt="submit icon"
            />{" "}
            Next
          </NextActionButton>
        </div>
      </div>
    </>
  );
};

export default SavingsTerms;
