import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Select,
  Input,
  FormControl,
  InputGroup,
  InputRightElement,
  Checkbox,
  Textarea,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import CurrencyFormat from "react-currency-format";

import {
  FormClass,
  FormElements,
  ActionButton,
  FormTitle,
} from "../../styles.jsx";
import { IconButton } from "../styles.jsx";
import { Table } from "react-bootstrap";
import { fetchCollateralTemplate } from "../../../../../../actions/loanCollateralActions.js";

const LoanTerms = ({
  handleNextScreen,
  handlePreviousScreen,
  principal,
  handlePrincipal,
  handleLoanTermFreqType,
  loanTermFreqType,
  loanTermFrequency,
  handleLoanTermFreq,
  numberOfRepayments,
  handleNoOfRepayment,
  handleRepaymentEvery,
  repaymentEvery,
  repaymentFreqType,
  handleRepaymentFreqType,
  handleRepaymentFreqDayType,
  repaymentFreqDayType,
  repaymentFreqNthDayType,
  handleRepaymentFreqNthDayType,
  handleIntCalcPerType,
  interestCalculationPeriod,
  handleIntRatePer,
  interestRatePerPeriod,
  handleInterestType,
  intType,
  amortization,
  amortizationType,
  handleAmortizationType,
  handleEqualAmortization,
  equalAmortization,
  handlePartialInterest,
  allowPartialPeriodInterest,
  handleProcessingStrategy,
  transactionProcessingStrategy,
  inArrearsTolerance,
  handleArrearsTolerance,
  handleGraceOnInterestCharged,
  graceOnInterestCharged,
  graceOnPrincipalPayment,
  handleGraceOnPrincipalPay,
  graceOnArrearsAgeing,
  handleGraceOnArrearsAgeing,
  handleGraceOnIntestPay,
  graceOnInterestPayment,
  handleRepaymentStartingFrom,
  repaymentStartingFrom,
  interestChargedFrom,
  handleInterestChargedFrom,
  isEqualAmortization,
  setIsEqualAmortization,
  setNewLoanProduct,
  collaterals,
  setCollaterals,
  clientId,
}) => {
  const dispatch = useDispatch();
  const { loanCollateralTemplate } = useSelector(state => state.loanCollateralReducer);

  const [collateralToBeAdded, setCollateralToBeAdded] = useState({
    type: '',
    value: '',
    description: '',
  });

  const resetCollateralToBeAdded = () => {
    setCollateralToBeAdded({
      type: '',
      value: '',
      description: '',
    });
  };

  const addCollateral = () => {
    setCollaterals(collaterals => [
      ...collaterals.filter(collateral => collateral.type),
      {
        ...collateralToBeAdded,
        id: collaterals.length,
        name: loanCollateralTemplate?.allowedCollateralTypes?.find(
          type => type?.id == collateralToBeAdded?.type
        )?.name,
      },
    ]);
    resetCollateralToBeAdded();
  };

  const modifyCollateralValue = (id, value) => {
    setCollaterals(collaterals.map(
      collateral => (id === collateral.id ? {
        ...collateral,
        value
      } : collateral))
    );
  }

  const removeCollateral = (idToBeRemoved) => {
    setCollaterals(
      collaterals
      ?.filter(collateral => collateral?.id !== idToBeRemoved)
    )
  }
  const handleChange = (event) => {
    const { name, value } = event.target;
    setCollateralToBeAdded(collateral => ({
      ...collateral,
      [name]: value,
    }));
  };
  const selectedLoanData = useSelector((state) => state.selectedLoanData);
  const loanProductsTemplate = useSelector((state) => state.loanProductsTemplate);
  const { productData } = selectedLoanData;
  const { loanProductsTemplate: template } = loanProductsTemplate;

  const {
    repaymentFrequencyTypeOptions,
    termFrequencyTypeOptions,
    repaymentFrequencyNthDayTypeOptions,
    repaymentFrequencyDaysOfWeekTypeOptions,
    interestCalculationPeriodTypeOptions,
    interestTypeOptions,
    interestRateFrequencyType,
    amortizationTypeOptions,
    transactionProcessingStrategyOptions,
  } = productData;

  const customOptions = [
    { id: "true", title: "Yes" },
    { id: "false", title: "No" },
  ];

  useEffect(() => {
    dispatch(fetchCollateralTemplate(clientId))
  }, [clientId])

  return (
    <>
      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Principal*:</label>
                {/* <Input
                  style={{ width: "60%" }}
                  size="md"
                  value={principal}
                  onChange={handlePrincipal}
                /> */}
                <CurrencyFormat
                  value={principal}
                  thousandSeparator={true}
                  className="amount-input"
                  style={{
                    width: "60%",
                  }}
                  // prefix={"$"}
                  onValueChange={(values) => {
                    const { value } = values;
                    handlePrincipal(value);
                  }}
                />
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center mb-10-imp">
                <label className="text-label d-flex flex-align-center">
                  <span>Loan Term:</span>
                </label>
                <div
                  style={{ width: "60%" }}
                  className="d-flex justify-content-between align-items-center"
                >
                  <Input
                    style={{ width: "49%" }}
                    value={loanTermFrequency}
                    onChange={handleLoanTermFreq}
                  />
                  <div className="mini-select-wrap" style={{ width: "49%" }}>
                    <div className="select-100-width">
                      <Select
                        placeholder="Select Period"
                        size="md"
                        value={loanTermFreqType}
                        onChange={handleLoanTermFreqType}
                      >
                        {termFrequencyTypeOptions !== undefined &&
                          termFrequencyTypeOptions.map((opt) => (
                            <option id={opt.id} key={opt.id}>
                              {opt.value}
                            </option>
                          ))}
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>

      <FormTitle style={{ width: "62.5%", margin: "0 auto" }} className="mt-5 mb-5">
        <p className="text-info linkSavings">Repayments</p>
        <div className="lineThrough"></div>
      </FormTitle>

      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Number of repayments*</label>
                <Input
                  style={{ width: "60%" }}
                  value={numberOfRepayments}
                  onChange={handleNoOfRepayment}
                  size="md"
                />
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center mb-10-imp">
                <label className="text-label d-flex flex-align-center">
                  <span>Repaid Every</span>
                </label>
                <div
                  style={{ width: "60%" }}
                  className="d-flex justify-content-between align-items-center"
                >
                  <Input
                    style={{ width: "49%" }}
                    value={repaymentEvery}
                    onChange={handleRepaymentEvery}
                  />
                  <div className="mini-select-wrap" style={{ width: "49%" }}>
                    <div className="select-100-width">
                      <Select
                        placeholder="Select one"
                        size="md"
                        value={repaymentFreqType}
                        onChange={handleRepaymentFreqType}
                      >
                        {termFrequencyTypeOptions !== undefined &&
                          termFrequencyTypeOptions.map((opt) => (
                            <option id={opt.id} key={opt.id}>
                              {opt.value}
                            </option>
                          ))}
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  {" "}
                  On
                </label>
                <div
                  style={{ width: "60%" }}
                  className="d-flex justify-content-between align-items-center"
                >
                  <div className="mini-select-wrap" style={{ width: "49%" }}>
                    <div className="select-100-width">
                      <Select
                        placeholder="Select one"
                        size="md"
                        value={repaymentFreqNthDayType}
                        onChange={handleRepaymentFreqNthDayType}
                      >
                        {repaymentFrequencyNthDayTypeOptions !== undefined &&
                          repaymentFrequencyNthDayTypeOptions.map((opt) => (
                            <option id={opt.id} key={opt.id} value={opt.id}>
                              {opt.value}
                            </option>
                          ))}
                      </Select>
                    </div>
                  </div>
                  <div className="mini-select-wrap" style={{ width: "49%" }}>
                    <div className="select-100-width">
                      <Select
                        placeholder="Select one"
                        size="md"
                        value={repaymentFreqDayType}
                        onChange={handleRepaymentFreqDayType}
                      >
                        {repaymentFrequencyDaysOfWeekTypeOptions !==
                          undefined &&
                          repaymentFrequencyDaysOfWeekTypeOptions.map((opt) => (
                            <option id={opt.id} key={opt.id} value={opt.id}>
                              {opt.value}
                            </option>
                          ))}
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>

      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  <span>First Repayment</span>
                  {/* <img src={QuestionMark} alt="ask" className="ml-1" /> */}
                </label>
                <FormControl style={{ width: "60%" }}>
                  <div className="datepicker-100-width">
                    <DatePicker
                      id="published-date"
                      selected={repaymentStartingFrom}
                      onChange={(date) => handleRepaymentStartingFrom(date)}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="dd/mmm/yyyy"
                    />
                  </div>
                </FormControl>
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  <span>Interest Charged From</span>
                  {/* <img src={QuestionMark} alt="ask" className="ml-1" /> */}
                </label>
                <FormControl style={{ width: "60%" }}>
                  <div className="datepicker-100-width">
                    <DatePicker
                      id="published-date"
                      selected={interestChargedFrom}
                      onChange={(date) => handleInterestChargedFrom(date)}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="dd/mmm/yyyy"
                    />
                  </div>
                </FormControl>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>

      <FormTitle style={{ width: "62.5%", margin: "0 auto" }} className="mt-5 mb-5">
        <p className="text-info linkSavings">Interests</p>
        <div className="lineThrough"></div>
      </FormTitle>

      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  <span>Nominal Interest Rate*</span>
                </label>
                <InputGroup style={{ width: "60%", height: "48px" }}>
                  <Input
                    style={{ height: "48px" }}
                    value={interestRatePerPeriod}
                    onChange={handleIntRatePer}
                  />
                  <InputRightElement
                    style={{
                      width: "fit-content",
                      height: "35px",
                      fontSize: "11px",
                      background: "#f6f6f6",
                      borderRadius: "0 4px 4px 0",
                      padding: "0px 0.3rem 0px 0.3rem",
                    }}
                    children={
                      <span>
                        {interestRateFrequencyType !== undefined &&
                          interestRateFrequencyType.value}
                      </span>
                    }
                  />
                </InputGroup>
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  <span>Interest method</span>
                </label>
                <Select
                  placeholder="Select type"
                  size="md"
                  value={intType}
                  onChange={handleInterestType}
                >
                  {interestTypeOptions !== undefined &&
                    interestTypeOptions.map((opt) => (
                      <option id={opt.id} key={opt.id}>
                        {opt.value}
                      </option>
                    ))}
                </Select>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>

      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  <span>Equal Amortization</span>
                  {/* <img src={QuestionMark} alt="ask" className="ml-1" /> */}
                </label>
                <Checkbox
                  style={{ marginRight: "16rem" }}
                  size="md"
                  isChecked={isEqualAmortization}
                  onChange={(e) =>
                    setNewLoanProduct((p) => ({
                      ...p,
                      isEqualAmortization: e.target.checked,
                    }))
                  }
                />
                {/* <Select
                  // disabled
                  placeholder="Select Yes or No"
                  size="md"
                  value={equalAmortization}
                  onChange={handleEqualAmortization}
                >
                  {/* {customOptions.map((opt) => (
                    <option id={opt.id} key={opt.id} value={opt.title}>
                      {opt.title}
                    </option> */}
                {/* ))}
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Select> */}
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  <span>Amortization*</span>
                </label>
                <Select
                  placeholder="Select amortization type"
                  size="md"
                  value={amortization}
                  onChange={handleAmortizationType}
                >
                  {amortizationTypeOptions !== undefined &&
                    amortizationTypeOptions.map((opt) => (
                      <option id={opt.id} key={opt.id}>
                        {opt.value}
                      </option>
                    ))}
                </Select>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>

      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  <span>Interest calculation period*</span>
                </label>
                <Select
                  placeholder="Select One"
                  size="md"
                  value={interestCalculationPeriod}
                  onChange={handleIntCalcPerType}
                >
                  {interestCalculationPeriodTypeOptions !== undefined &&
                    interestCalculationPeriodTypeOptions.map((opt) => (
                      <option id={opt.id} key={opt.id}>
                        {opt.value}
                      </option>
                    ))}
                </Select>
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  <span>
                    Calculate interest for exact days in partial period*
                  </span>
                </label>
                <Select
                  placeholder="Select Yes or No"
                  size="md"
                  value={allowPartialPeriodInterest}
                  onChange={handlePartialInterest}
                >
                  {customOptions.map((opt) => (
                    <option id={opt.id} key={opt.id}>
                      {opt.title}
                    </option>
                  ))}
                </Select>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>

      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  <span>Arrears tolerance</span>
                </label>
                <Input
                  style={{ width: "60%" }}
                  value={inArrearsTolerance}
                  onChange={handleArrearsTolerance}
                />
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  <span>Interest free period</span>
                </label>
                <Input
                  style={{ width: "60%" }}
                  value={graceOnInterestCharged}
                  onChange={handleGraceOnInterestCharged}
                />
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>

      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  <span>Repayment Strategy*</span>
                </label>
                <Select
                  placeholder="Select strategy"
                  size="md"
                  value={transactionProcessingStrategy}
                  onChange={handleProcessingStrategy}
                >
                  {transactionProcessingStrategyOptions !== undefined &&
                    transactionProcessingStrategyOptions.map((opt) => (
                      <option id={opt.id} key={opt.id}>
                        {opt.name}
                      </option>
                    ))}
                </Select>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>

      <FormTitle style={{ width: "62.5%", margin: "0 auto" }} className="mt-5">
        <p className="text-info linkSavings">Moratorium</p>
        <div className="lineThrough"></div>
      </FormTitle>

      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap pt-5">
        <div className=" px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label
                  className="text-label d-flex flex-align-center"
                  style={{ width: "47%" }}
                >
                  <span>On principal payment</span>
                </label>
                {/* <Input
                  style={{ width: "50%" }}
                  value={graceOnPrincipalPayment}
                  onChange={handleGraceOnPrincipalPay}
                /> */}
                <CurrencyFormat
                  value={graceOnPrincipalPayment}
                  thousandSeparator={true}
                  className="amount-input"
                  style={{
                    width: "50%",
                  }}
                  // prefix={"$"}
                  onValueChange={(values) => {
                    const { value } = values;
                    handleGraceOnPrincipalPay(value);
                  }}
                />
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className=" px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label
                  className="text-label d-flex flex-align-center"
                  style={{ width: "47%" }}
                >
                  <span>On interest payment</span>
                </label>
                {/* <Input
                  style={{ width: "50%" }}
                  value={graceOnInterestPayment}
                  onChange={handleGraceOnIntestPay}
                /> */}
                <CurrencyFormat
                  value={graceOnInterestPayment}
                  thousandSeparator={true}
                  className="amount-input"
                  style={{
                    width: "60%",
                  }}
                  // prefix={"$"}
                  onValueChange={(values) => {
                    const { value } = values;
                    handleGraceOnIntestPay(value);
                  }}
                />
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className=" px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label
                  className="text-label d-flex flex-align-center"
                  style={{ width: "47%" }}
                >
                  <span>On Arrears Aging</span>
                </label>
                {/* <Input
                  style={{ width: "50%" }}
                  value={graceOnArrearsAgeing}
                  onChange={handleGraceOnArrearsAgeing}
                /> */}
                <CurrencyFormat
                  value={graceOnArrearsAgeing}
                  thousandSeparator={true}
                  className="amount-input"
                  style={{
                    width: "60%",
                  }}
                  // prefix={"$"}
                  onValueChange={(values) => {
                    const { value } = values;
                    handleGraceOnArrearsAgeing(value);
                  }}
                />
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>

      <FormTitle style={{ width: "62.5%", margin: "0 auto" }} className="mt-5 mb-5">
        <p className="text-info linkSavings">Collateral</p>
        <div className="lineThrough"></div>
      </FormTitle>

      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap pt-5 pb-2">
        <FormClass style={{ width: '32%' }}>
          <FormElements>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label mt-1">Collateral Type</label>
              <Select
                placeholder="Select one"
                size="md"
                value={collateralToBeAdded.type}
                name="type"
                onChange={handleChange}
              >
                {loanCollateralTemplate?.allowedCollateralTypes?.map((type) => (
                  <option value={type.id} key={type.id}>
                    {type.name}
                  </option>
                ))}
              </Select>
            </div>
          </FormElements>
        </FormClass>
        <FormClass style={{ width: '25%' }}>
          <FormElements className="d-flex flex-column">
            <div className="form-group d-flex justify-content-between align-items-start">
              <label className="text-label mt-1">Value</label>
              <div className="d-flex flex-column">
                <CurrencyFormat
                  value={collateralToBeAdded?.value}
                  thousandSeparator={true}
                  className="amount-input"
                  prefix={template?.currency?.displaySymbol}
                  onValueChange={({ value }) => {
                    setCollateralToBeAdded(collateral => ({
                      ...collateral,
                      value
                    }));
                  }}
                />
                <div className="d-flex align-items-end mt-1">
                  <img
                    src={require("../../../../../../assets/images/icons/info-circle-2.svg").default}
                    width={18}
                    height={18}
                    alt="tooltip icon"
                  />
                  <span className="fs-10 ml-1">Amount the collateral guarantees.</span>
                </div>
              </div>
            </div>
          </FormElements>
        </FormClass>
        <FormClass style={{ width: '40%' }}>
          <FormElements>
            <div className="form-group d-flex justify-between align-items-start">
              <label className="text-label mt-1 mr-4">Description</label>
              <div className="w-100">
                <Textarea
                  style={{ fontSize: "12px", minWidth: '100%' }}
                  name="description"
                  value={collateralToBeAdded?.description}
                  onChange={handleChange}
                />
              </div>
            </div>
          </FormElements>
        </FormClass>
        <div className="d-flex w-100 justify-content-end">
          <ActionButton
            color={
              collateralToBeAdded.type &&
              collateralToBeAdded.value &&
              collateralToBeAdded.description
              ? "#2C1DFF"
              : "#e3e3ff"
            }
            disabled={
              !collateralToBeAdded.type &&
              !collateralToBeAdded.value &&
              !collateralToBeAdded.description
            }
            style={{ margin: "0 40px 0 0"}}
            onClick={addCollateral}
          >
            <img
              src={
                require("../../../../../../assets/images/icons/add.svg")
                  .default
              }
              alt="forward icon"
            />
            Add Collateral
          </ActionButton>
        </div>
      </div>

      {collaterals
        ?.filter(collateral => collateral.type)
        ?.length ? (
        <Table className="child-table">
          <thead>
            <tr style={{ backgroundColor: "#FAFAFA" }}>
              <th>S/N</th>
              <th>Collateral Type</th>
              <th>Value</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {collaterals?.map((collateral, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{collateral?.name}</td>
                <CurrencyFormat
                  value={collateral?.value}
                  thousandSeparator={true}
                  className="amount-input mt-1"
                  prefix={template?.currency?.displaySymbol}
                  onValueChange={({ value }) => {
                    modifyCollateralValue(collateral.id, value);
                  }}
                />
                <td>{collateral?.description}</td>
                <td>
                  <IconButton
                    onClick={() => removeCollateral(collateral?.id)}
                    id="removeCollateral"
                    className="mr-1 mb-1"
                  >
                    <img
                      src={
                        require("../../../../../../assets/images/icons/delete.svg")
                          .default
                      }
                      alt="remove collateral"
                      className="icon-img"
                    />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : <></>}
      <div>
        <div className="float-right pt-5">
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={handlePreviousScreen}
          >
            <img
              src={
                require("../../../../../../assets/images/icons/arrow-left.svg")
                  .default
              }
              alt="cancel icon"
            />
            Previous
          </ActionButton>
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={handleNextScreen}
          >
            <img
              src={
                require("../../../../../../assets/images/icons/arrow-right.svg")
                  .default
              }
              alt="submit icon"
            />{" "}
            Next
          </ActionButton>
        </div>
      </div>
    </>
  );
};

export default LoanTerms;
