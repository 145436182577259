import React, { useEffect, useState } from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

const StepView = ({ current, setCurrent, legalFormId }) => {
  const [percentage, setPercentage] = useState(0);
  useEffect(() => {
    // if (!!current) {
    setPercentage((current * 100) / 4);
    // }
  }, [current]);
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <div
              style={{
                paddingBottom: "4.5rem",
                borderBottom: "1px solid #E2E8F0",
              }}
            >
              <ProgressBar percent={percentage} style={{ padding: "" }}>
                <Step style={{ flexDirection: "column" }}>
                  {({ accomplished, index, position }) => (
                    <>
                      <div
                        className={`indexedStep pointer ${
                          accomplished ? "accomplished" : ""
                        }`}
                        onClick={() => setCurrent(0)}
                      >
                        {position === percentage ? (
                          ""
                        ) : accomplished ? (
                          <img
                            style={{ width: "10px", height: "10px" }}
                            src={
                              require("../../../../../assets/images/icons/save.svg")
                                .default
                            }
                            alt="submit icon"
                          />
                        ) : (
                          <span></span>
                        )}
                      </div>
                      <div className="pt-3">
                        <h3 className="woodcore-blue">Legal Form</h3>
                      </div>
                    </>
                  )}
                </Step>
                <Step style={{ flexDirection: "column" }}>
                  {({ accomplished, index, position }) => (
                    <>
                      <div
                        className={`indexedStep pointer ${
                          accomplished ? "accomplished" : ""
                        }`}
                        onClick={() => setCurrent(1)}
                      >
                        {position === percentage ? (
                          ""
                        ) : accomplished ? (
                          <img
                            style={{ width: "10px", height: "10px" }}
                            src={
                              require("../../../../../assets/images/icons/save.svg")
                                .default
                            }
                            alt="submit icon"
                          />
                        ) : (
                          <span></span>
                        )}
                      </div>
                      <div className="pt-3">
                        <h3 className="woodcore-blue">
                          {legalFormId === "0" || legalFormId === "1"
                            ? "Personal"
                            : "Entity"}{" "}
                          Information
                        </h3>
                      </div>
                    </>
                  )}
                </Step>
                <Step style={{ flexDirection: "column" }}>
                  {({ accomplished, index, position }) => (
                    <>
                      <div
                        className={`indexedStep pointer ${
                          accomplished ? "accomplished" : ""
                        }`}
                        onClick={() => setCurrent(2)}
                      >
                        {position === percentage ? (
                          ""
                        ) : accomplished ? (
                          <img
                            style={{ width: "10px", height: "10px" }}
                            src={
                              require("../../../../../assets/images/icons/save.svg")
                                .default
                            }
                            alt="submit icon"
                          />
                        ) : (
                          <span></span>
                        )}
                      </div>
                      <div className="pt-3">
                        <h3 className="woodcore-blue">Account Information</h3>
                      </div>
                    </>
                  )}
                </Step>
                <Step style={{ flexDirection: "column" }}>
                  {({ accomplished, index, position }) => (
                    <>
                      <div
                        className={`indexedStep pointer ${
                          accomplished ? "accomplished" : ""
                        }`}
                        onClick={() => setCurrent(3)}
                      >
                        {position === percentage ? (
                          ""
                        ) : accomplished ? (
                          <img
                            style={{ width: "10px", height: "10px" }}
                            src={
                              require("../../../../../assets/images/icons/save.svg")
                                .default
                            }
                            alt="submit icon"
                          />
                        ) : (
                          <span></span>
                        )}
                      </div>
                      <div className="pt-3">
                        <h3 className="woodcore-blue">Contact Address</h3>
                      </div>
                    </>
                  )}
                </Step>
                <Step style={{ flexDirection: "column" }}>
                  {({ accomplished, index, position }) => (
                    <>
                      <div
                        className={`indexedStep pointer ${
                          accomplished ? "accomplished" : ""
                        }`}
                        onClick={() => setCurrent(4)}
                      >
                        {position === percentage ? (
                          ""
                        ) : accomplished ? (
                          <img
                            style={{ width: "10px", height: "10px" }}
                            src={
                              require("../../../../../assets/images/icons/save.svg")
                                .default
                            }
                            alt="submit icon"
                          />
                        ) : (
                          <span></span>
                        )}
                      </div>
                      <div className="pt-3">
                        <h3 className="woodcore-blue">Document</h3>
                      </div>
                    </>
                  )}
                </Step>
              </ProgressBar>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StepView;
