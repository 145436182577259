import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../../../../../util/FormatDate";
import { getCustomerNotes, modifyCustomerNote } from "../../../../../actions/customerActions";
import { DeleteNote } from "./DeleteCustomerNote";
import ModalContainer from "../../../components/ModalContainer";
import {
	BtnBold,
	BtnBulletList,
	BtnClearFormatting,
	BtnItalic,
	BtnLink,
	BtnNumberedList,
	BtnStrikeThrough,
	Editor,
	EditorProvider,
	Separator,
	Toolbar,
	createButton
} from "react-simple-wysiwyg";
import { truncateText } from "../../../../../util/TruncateText";
import { Tooltip } from "antd";
import CheckPermission from "../../../../../util/CheckPermission";

export const Note = (props) => {
	const dispatch = useDispatch();
	const {
		id,
		clientId,
		note,
		createdByUsername,
		updatedByUsername,
		createdOn,
		updatedOn,
	} = props;
	const maxLength = 160;

	const [newNote, setNewNote] = useState(note);
	const [isEditable, setIsEditable] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [isExpanded, setIsExpanded] = useState(false);

	const { responseUserData: { permissions } } = useSelector((state) => state.preAuth);

	const handleNoteChange = (event) => setNewNote(event.target.value);
	const toggleIsEditable = () => setIsEditable(state => !state);
	const toggleShowModal = () => setShowModal(state => !state);
	const toggleIsExpanded = () => setIsExpanded(state => !state);
	const updateNote = () => dispatch(modifyCustomerNote(
		clientId,
		id,
		{ note: newNote },
		() => {
			dispatch(getCustomerNotes(clientId));
			toggleIsEditable();
			toggleIsExpanded();
		}
	));

	const BtnUndo = createButton('Undo', <>&#8634;</>, 'undo');
	const BtnRedo = createButton('Redo', <>&#8635;</>, 'redo');

	return (
		<div className="d-flex flex-column gap-5 border rounded fs-12 p-2">
			<ModalContainer size="md" show={showModal}>
				<DeleteNote
					clientId={clientId}
					noteId={id}
					handleCloseModal={toggleShowModal}
				/>
			</ModalContainer>
			<div className="d-flex flex-column justify-content-between align-items-start gap-2 text-secondary">
				{isEditable ? (
					<div className="w-100">
						<EditorProvider>
							<Editor
								value={newNote}
								onChange={handleNoteChange}
							>
								<Toolbar>
									<BtnUndo />
									<BtnRedo />
									<Separator />
									<BtnBold />
									<BtnItalic />
									<BtnStrikeThrough />
									<Separator />
									<BtnNumberedList />
									<BtnBulletList />
									<BtnLink />
									<Separator />
									<BtnClearFormatting />
								</Toolbar>
							</Editor>
						</EditorProvider>
					</div>
				) : (
					<div
						className="note-previewer"
						dangerouslySetInnerHTML={{
							__html: isExpanded
								? newNote
								: truncateText(newNote, maxLength)
						}}
					/>
				)}
				<div className="d-flex w-100 items-center">
					{newNote?.length > maxLength && !isEditable && (
						<button onClick={toggleIsExpanded} className="flex align-items-center fs-12 text-primary font-bold">
							{isExpanded ? <>see less &#8673;</> : <>see more &#8675;</>}
						</button>
					)}
					{isEditable ? (
						<div className="d-flex gap-2 ml-auto">
							<button className="rounded px-2 py-1 border border-primary text-primary" onClick={updateNote}>Save</button>
							<button className="rounded px-2 py-1 border" onClick={toggleIsEditable}>Cancel</button>
						</div>
					) : (
						<div className="d-flex gap-2 ml-auto">
							{CheckPermission('UPDATE_CLIENTNOTE', permissions) &&
								CheckPermission('CREATE_CLIENTNOTE', permissions) && (
									<button className="rounded px-2 py-1 border" onClick={toggleIsEditable}>Edit</button>
								)}
							{CheckPermission('DELETE_CLIENTNOTE', permissions) && (
								<button className="rounded px-2 py-1 border border-danger text-danger" onClick={toggleShowModal}>Delete</button>
							)}
						</div>
					)}
				</div>
			</div>
			<div className="d-flex flex-column gap-0.5 fs-10">
				<div className="d-flex gap-4">
					<span className="w-60">
						<Tooltip title={createdByUsername}>
							<span className="text-secondary">Created by:</span> {createdByUsername}
						</Tooltip>
					</span>
					<span className="w-40">
						<span className="text-secondary">Created on:</span> {formatDate(createdOn)}
					</span>
				</div>
				{updatedOn !== createdOn && (
					<div className="d-flex gap-4">
						<span className="w-60">
							<Tooltip title={updatedByUsername}>
								<span className="text-secondary">Updated by:</span> {updatedByUsername}
							</Tooltip>
						</span>
						<span className="w-40">
							<span className="text-secondary">Updated on:</span> {formatDate(updatedOn)}
						</span>
					</div>
				)}
			</div>
		</div>
	);
};
