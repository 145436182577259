import styled from "styled-components";

const FrameInner = styled.div`
  position: relative;
  padding: 4px 0px 0px 0px;
  width: 13rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  vertical-align: middle;
  color: white;
  fill: white;
`;

const Title = styled.span`
  vertical-align: middle;
  color: #666666 !important;
  margin-left: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ContentInner = styled.div`
  will-change: transform, opacity, height;
  margin-left: 2px;
  //   padding: 0px 0px 0px 14px;
  border-left: 1px solid #2C1DFF;
  margin-left: 20px;
  position: relative;
  overflow: hidden;
  font-size: 13px;
  .span {
    width: 20px;
    height: 1px;
    background: #2C1DFF;
  }
`;

const toggle = {
  width: "1em",
  height: "1em",
  marginRight: 10,
  cursor: "pointer",
  verticalAlign: "middle",
};

export { FrameInner, ContentInner, toggle, Title };
