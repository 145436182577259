import React, { useState } from "react";
import { Input, Select, FormControl, Checkbox } from "@chakra-ui/react";
import {
  ActionButton,
  FormElements,
  FormClass,
  EventContainer,
} from "../styles";
import { Modal, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Table } from "react-bootstrap";
import {
  createNewHook,
  getAllHook,
} from "../../../../../actions/configurationActions";
import Toast from "../../../../../util/Toast";
import { formatApiText } from "../../../../../util/FormatApiText";

const NewHook = ({ onHide, template }) => {
  const dispatch = useDispatch();
  const [newHook, setNewHookInfo] = useState({
    hookTemplate: "",
    displayName: "",
    isActive: false,
    contentType: "",
    phone: "",
    smsProvider: "",
    payloadUrl: "",
    smsProviderAccountId: "",
    smsProviderToken: "",
    event: "",
    actionName: "",
    entityName: "",
  });
  const [loading, setLoading] = useState(false);
  const {
    displayName,
    hookTemplate,
    isActive,
    contentType,
    phone,
    smsProvider,
    payloadUrl,
    smsProviderAccountId,
    smsProviderToken,
    event,
    entityName,
    actionName,
  } = newHook;

  const [events, setEvents] = useState([]);
  const addToEvents = () => {
    if (event && actionName && entityName)
      setEvents([...events, { actionName, entityName }]);
  };
  const removeFromEvents = (position) => {
    setEvents(events.filter((item, index) => index !== position));
  };

  const createHookHandler = (e) =>
    setNewHookInfo({
      ...newHook,
      [e.target.name]: e.target.value,
    });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (displayName && hookTemplate) {
      setLoading(true);
      const data = {
        name: hookTemplate,
        isActive,
        displayName,
        events,
        config: {
          "Payload URL": payloadUrl,
          "Content Type": contentType,
          "Phone Number": phone,
          "SMS Provider": smsProvider,
          "SMS Provider Account Id": smsProviderAccountId,
          "SMS Provider Token": smsProviderToken,
        },
      };
      const func = () => {
        dispatch(getAllHook());
        onHide();
      };
      await dispatch(createNewHook(data, func));
      setLoading(false);
    } else {
      dispatch(
        Toast({
          text: "please fill the required fields",
          icon: "error",
          timer: 2000,
        })
      );
    }
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>New Hook</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <FormElements style={{ width: "100%" }}>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Hook Template*</label>
              <Select
                size="md"
                name="hookTemplate"
                value={hookTemplate}
                onChange={createHookHandler}
              >
                <option value="">Select an option</option>
                {template !== undefined && template
                  ? template.templates &&
                    template.templates.map((cur) => (
                      <option value={cur.name} key={cur.name}>
                        {cur.name}
                      </option>
                    ))
                  : null}
              </Select>
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Display Name*</label>
              <Input
                style={{ width: "60%" }}
                size="md"
                name="displayName"
                value={displayName}
                onChange={createHookHandler}
              />
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-muted d-flex flex-align-center">
                Active
              </label>
              <FormControl style={{ width: "60%" }}>
                <Checkbox
                  size="md"
                  name="isActive"
                  isChecked={isActive}
                  onChange={(e) =>
                    setNewHookInfo((p) => ({
                      ...p,
                      isActive: e.target.checked,
                    }))
                  }
                />
              </FormControl>
            </div>
            {hookTemplate === "Web" && (
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Content Type *</label>
                <Input
                  style={{ width: "60%" }}
                  size="md"
                  placeholder="json / form"
                  name="contentType"
                  value={contentType}
                  onChange={createHookHandler}
                />
              </div>
            )}

            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Payload URL *</label>
              <Input
                style={{ width: "60%" }}
                size="md"
                name="payloadUrl"
                value={payloadUrl}
                onChange={createHookHandler}
              />
            </div>

            {hookTemplate === "SMS Bridge" && (
              <>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Phone Number *</label>
                  <Input
                    style={{ width: "60%" }}
                    size="md"
                    type="phone"
                    name="phone"
                    value={phone}
                    onChange={createHookHandler}
                  />
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">SMS Provider *</label>
                  <Input
                    style={{ width: "60%" }}
                    size="md"
                    name="smsProvider"
                    value={smsProvider}
                    onChange={createHookHandler}
                  />
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">
                    SMS Provider Account Id *
                  </label>
                  <Input
                    style={{ width: "60%" }}
                    size="md"
                    name="smsProviderAccountId"
                    value={smsProviderAccountId}
                    onChange={createHookHandler}
                  />
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">SMS Provider Token *</label>
                  <Input
                    style={{ width: "60%" }}
                    size="md"
                    name="smsProviderToken"
                    value={smsProviderToken}
                    onChange={createHookHandler}
                  />
                </div>
              </>
            )}
            <EventContainer className="form-group d-flex justify-content-between align-items-center">
              <p className="text-muted">Events</p>
              <div className="d-flex justify-content-start">
                {/* <div className="col-md-3"> */}
                <Select
                  size="md"
                  name="event"
                  value={event}
                  onChange={(e) => {
                    setNewHookInfo((p) => ({
                      ...p,
                      entityName: "",
                      actionName: "",
                      event: e.target.value,
                    }));
                  }}
                >
                  <option value="">Select an option</option>
                  {template !== undefined && template
                    ? template.groupings &&
                      template.groupings.map((cur) => (
                        <option value={cur.name} key={cur.name}>
                          {formatApiText(cur.name)}
                        </option>
                      ))
                    : null}
                </Select>

                <Select
                  size="md"
                  name="entityName"
                  value={entityName}
                  disabled={!event}
                  onChange={createHookHandler}
                >
                  <option value="">Select an option</option>
                  {template !== undefined && template && event
                    ? template.groupings &&
                      template.groupings
                        .filter((item) => item.name === event)[0]
                        .entities.map((cur) => (
                          <option value={cur.name} key={cur.name}>
                            {cur.name}
                          </option>
                        ))
                    : null}
                </Select>
                <Select
                  size="md"
                  name="actionName"
                  value={actionName}
                  disabled={!entityName}
                  onChange={createHookHandler}
                >
                  <option value="">Select an option</option>
                  {template !== undefined && template && event && entityName
                    ? template.groupings &&
                      template.groupings
                        .filter((item) => item.name === event)[0]
                        .entities.filter((item) => item.name === entityName)[0]
                        .actions.map((cur, i) => (
                          <option value={cur} key={i}>
                            {cur}
                          </option>
                        ))
                    : null}
                </Select>
                <ActionButton
                  onClick={addToEvents}
                  style={{ width: 100, minWidth: 100 }}
                >
                  <img
                    src={
                      require("../../../../../assets/images/icons/add.svg")
                        .default
                    }
                    alt="add icon"
                  />{" "}
                  add
                </ActionButton>
              </div>
            </EventContainer>
            <div className="col-sm-12 col-md-12 px-0">
              {events.length > 0 && (
                <Table>
                  <thead className="">
                    <tr style={{ backgroundColor: "#EDEFF5" }}>
                      <th>Entity Name</th>
                      <th>Action Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {events.map((item, index) => (
                      <tr key={index}>
                        <td>{formatApiText(item.entityName)}</td>
                        <td>{item.actionName}</td>
                        <td>
                          <ActionButton
                            style={{ minWidth: 120, width: 120 }}
                            onClick={() => removeFromEvents(index)}
                          >
                            <img
                              src={
                                require("../../../../../assets/images/icons/delete.svg")
                                  .default
                              }
                              alt="add icon"
                            />{" "}
                            Delete
                          </ActionButton>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </div>
          </FormElements>
          <div className="float-right mt-4">
            <ActionButton color="#2C1DFF" onClick={onHide}>
              <img
                src={
                  require("../../../../../assets/images/icons/cancel.svg")
                    .default
                }
                alt="cancel icon"
              />
              Cancel
            </ActionButton>
            <ActionButton
              onClick={handleSubmit}
              color="#2C1DFF"
              style={{ margin: "0px 0px 0 10px" }}
            >
              {loading ? (
                <Spinner
                  animation="border"
                  color="#fff"
                  className="mr-2 text-white"
                  size="sm"
                />
              ) : (
                <img
                  src={
                    require("../../../../../assets/images/icons/save.svg")
                      .default
                  }
                  alt="submit icon"
                />
              )}
              Submit
            </ActionButton>
          </div>
        </FormClass>
      </Modal.Body>
    </>
  );
};

export default NewHook;
