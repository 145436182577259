import React from "react";
import { Table } from "antd";
import Loading from "./Loading";

const columns = [
  { title: "ID", dataIndex: "id", key: "id" },
  { title: "Resource ID", dataIndex: "rid", key: "rid" },

  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Made By",
    dataIndex: "madeBy",
    key: "madeBy",
  },
  { title: "Action", dataIndex: "action", key: "action" },
  {
    title: "Entity",
    dataIndex: "entity",
    key: "entity",
  },
  {
    title: "Office",
    dataIndex: "office",
    key: "office",
  },
  {
    title: "Made Date",
    dataIndex: "madedate",
    key: "madedate",
  },
  // {
  //   title: "Checked Date",
  //   dataIndex: "checkeddate",
  //   key: "checkeddate",
  // },
];

const TableLoader = (props) => {
  const someChartsData = [
    {
      key: 1,
      id: <Loading />,
      rid: <Loading />,

      status: <Loading />,
      madeBy: <Loading />,
      action: <Loading />,
      entity: <Loading />,
      office: <Loading />,
      madedate: <Loading />,
    },
    {
      key: 2,
      id: <Loading />,
      rid: <Loading />,

      status: <Loading />,
      madeBy: <Loading />,
      action: <Loading />,
      entity: <Loading />,
      office: <Loading />,
      madedate: <Loading />,
    },
    {
      key: 3,
      id: <Loading />,
      rid: <Loading />,

      status: <Loading />,
      madeBy: <Loading />,
      action: <Loading />,
      entity: <Loading />,
      office: <Loading />,
      madedate: <Loading />,
    },
    {
      key: 4,
      id: <Loading />,
      rid: <Loading />,

      status: <Loading />,
      madeBy: <Loading />,
      action: <Loading />,
      entity: <Loading />,
      office: <Loading />,
      madedate: <Loading />,
    },
    {
      key: 5,
      id: <Loading />,
      rid: <Loading />,

      status: <Loading />,
      madeBy: <Loading />,
      action: <Loading />,
      entity: <Loading />,
      office: <Loading />,
      madedate: <Loading />,
    },
  ];
  return (
    <>
      <Table
        columns={columns}
        dataSource={someChartsData}
        pagination={{ defaultPageSize: 8 }}
        className="antdTable"
        defaultPageSize={8}
      />
    </>
  );
};

export default TableLoader;
