import React, { useState, useRef, useEffect } from "react";
import {
  ContentWrapper,
  ActionButtonsGroup,
  ActionButton,
  EditButton,
} from "./styles";
import { InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import ModalContainer from "../../../components/ModalContainer";
import CreateDataTable from "./components/CreateDataTable";
import RemoveDataTable from "./components/RemoveDataTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllEntityDataTableCheck,
  getEntityDataTableTemplate,
  filterEntityDataTable,
  clearFilteredEntityDataTable,
} from "../../../../../actions/configurationActions";
import Toast from "../../../../../util/Toast";
import CustomItemLoader from "../../../../../components/Loader/CustomItemLoader";
const EntityDataTableView = () => {
  const dispatch = useDispatch();
  const [modalState, toggleModal] = useState(false);
  const [modalContentName, setModalContentName] = useState("");

  useEffect(() => {
    dispatch(clearFilteredEntityDataTable());
    dispatch(getAllEntityDataTableCheck());
    dispatch(getEntityDataTableTemplate());
    if (filtered === null) {
      search.current.value = "";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const entityList = useSelector((state) => state.entityDataTableReducer);

  const { loading, entities, filtered } = entityList;

  const template = useSelector((state) => state.entityDataTemplate);

  const { loading: templateLoading, entityTemplate } = template;
  const search = useRef("");
  const onFilterEntity = (e) => {
    search.current.value !== ""
      ? dispatch(filterEntityDataTable(e.target.value))
      : dispatch(clearFilteredEntityDataTable());
  };
  const currentContent = (e) => {
    const val = e.currentTarget.id.trim();

    if (val) {
      toggleModal(true);
      setModalContentName(val);
    }
  };

  const handleModalClose = () => toggleModal(false);

  const displayModalContent = () => {
    switch (modalContentName) {
      case "createDataTable":
        return (
          <CreateDataTable
            template={entityTemplate}
            onHide={handleModalClose}
          />
        );
      case "removeDataTable":
        return <RemoveDataTable onHide={handleModalClose} />;
      default:
        return;
    }
  };

  //Table configuration
  let dataSource = [];
  const filteredEntiies = [];

  const columns = [
    { title: "Entity", dataIndex: "entity", key: "entity" },
    { title: "Product", dataIndex: "product", key: "product" },
    { title: "Data Table", dataIndex: "datatable", key: "datatable" },
    {
      title: "	Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "System Defined",
      dataIndex: "systemDefined",
      key: "systemDefined",
    },
    { title: "Action", dataIndex: "action", key: "actions" },
  ];

  dataSource = [
    {
      key: 1,
      entity: "WoodCore",
      product: "lorem",
      datatable: "ipsumd",
      systemDefined: "Next Repayment Date",
      status: "Lorem ipsum dolor ",
      action: (
        <EditButton id="removeDataTable" onClick={currentContent}>
          Delete
        </EditButton>
      ),
    },
    {
      key: 2,
      entity: "GreenDay Hols",
      product: "lorem",
      datatable: "ipsumd",
      systemDefined: "Next Repayment",
      status: "Lorem ipsum dolor ",
      action: (
        <EditButton id="removeDataTable" onClick={currentContent}>
          Delete
        </EditButton>
      ),
    },
    {
      key: 3,
      entity: "Final bank holiday",
      product: "lorem",
      datatable: "ipsumd",
      systemDefined: "Next Repayment",
      status: "Lorem ipsum dolor ",
      action: (
        <EditButton id="removeDataTable" onClick={currentContent}>
          Delete
        </EditButton>
      ),
    },
    {
      key: 4,
      entity: "End of the year",
      product: "lorem",
      datatable: "ipsumd",
      systemDefined: "Next Repayment Date",
      status: "Lorem ipsum dolor ",
      action: (
        <EditButton id="removeDataTable" onClick={currentContent}>
          Delete
        </EditButton>
      ),
    },
  ];
  return (
    <>
      <ModalContainer show={modalState}>
        {modalState && displayModalContent()}
      </ModalContainer>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Entity Data Table Checks</h3>
            <p className="sub-title">
              Define entity data table checks for organization
            </p>
            <p className="font-weight sub-title mt-2">
              <span className="text-info">
                <Link to="/core/configuration">Configuration</Link> /{" "}
                <Link to="/core/configuration/organization">Utilities</Link>
              </span>{" "}
              / Entity Data Table Checks
            </p>
          </div>
          <div className="col-sm-12 col-md-6 px-0">
            <ActionButtonsGroup>
              <InputGroup style={{ maxWidth: "450px" }}>
                <InputLeftElement
                  width="3.1rem"
                  height="50px"
                  pointerEvents="none"
                >
                  <img
                    src={
                      require("../../../../../assets/images/icons/search.svg")
                        .default
                    }
                    style={{ height: "30px" }}
                    alt="Icon"
                  />
                </InputLeftElement>
                <Input
                  style={{
                    background: "#ffffff",
                    height: "50px",
                  }}
                  ref={search}
                  // onChange={onFilterEntity}
                  type="text"
                  className="mb-3"
                  placeholder="Search for name"
                />
              </InputGroup>
              <ActionButton big id="createDataTable" onClick={currentContent}>
                <img
                  src={
                    require("../../../../../assets/images/icons/add.svg")
                      .default
                  }
                  alt="add"
                />
                Entity Data Table Checks
              </ActionButton>
            </ActionButtonsGroup>
          </div>
        </div>
        <ContentWrapper>
          {loading && templateLoading ? (
            <CustomItemLoader />
          ) : (
            <>
              {filtered !== null ? (
                <Table columns={columns} dataSource={filteredEntiies} />
              ) : (
                <Table
                  columns={columns}
                  dataSource={dataSource}
                  pagination={{ defaultPageSize: 8 }}
                  defaultPageSize={8}
                />
              )}
            </>
          )}
        </ContentWrapper>
      </div>
    </>
  );
};

export default EntityDataTableView;
