import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { Image } from "@chakra-ui/react";
import Button from "../../../../components/Button/index";
import TellerCard from "../components/TellerCard/index";
import { useDispatch, useSelector } from "react-redux";
import { getSingleTellerGroupCashier } from "../../../../actions/tellerActions";
import AddTellerForm from "./AddTellerForm";
import CheckPermission from "../../../../util/CheckPermission";
import AddTellerModal from "./AddTellerModal";

const SingleTellerGroup = ({ match }) => {
  const [addTeller, setAddTeller] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const tellerGroupReducer = useSelector((state) => state.tellerReducer);
  const { singleTellerGroupCashiers } = tellerGroupReducer;
  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  useEffect(() => {
    if (CheckPermission("READ_CASHIERTRANSACTION", permissions)) {
      dispatch(getSingleTellerGroupCashier(id));
    }
  }, [dispatch, id]);

  const closeAddTellerForm = () => {
    setAddTeller(false);
  };

  const [showModal, setShowModal] = useState(false);

  const handleModalClose = () => setShowModal(false);

  const openModal = () => {
    setShowModal(prev => !prev);
  };



  return (
    <div className="container-fluid">
      <div className="row justify-content-between">
        <div className="col-sm-12 col-md-12 col-lg-6 pb-3">
          <h3 className="page-title">
            {singleTellerGroupCashiers?.tellerName}
          </h3>
          <p className="text-muted sub-title">
            A complete overview of Teller in A Group
          </p>
          <div className="mt-2 font-weight sub-title">
            <LinkColor to="/core/teller-group" color>
              Teller management /{" "}
            </LinkColor>
            <CapitalizeText>
              {singleTellerGroupCashiers?.tellerName}
            </CapitalizeText>
          </div>
        </div>
      </div>
      <Container className="pt-3 pb-3">
        <div className="d-flex justify-content-end mb-4 mr-3">
          {CheckPermission("ALLOCATECASHIER_TELLER", permissions) && (
            <Button
              // onClick={() => setAddTeller(!addTeller)}
              onClick={openModal}
              icon={require("../../../../assets/images/icons/add.svg").default}
            >
              Add Teller
            </Button>
          )}
        </div>
        {CheckPermission("READ_CASHIERREQUEST", permissions) && (
          <ContentContainer addTeller={addTeller} className="m-3">
            {!singleTellerGroupCashiers?.cashiers?.length ? (
              <EmptyPage>
                {/* <Image
                  boxSize="50px"
                  objectFit="cover"
                  src={require("../../../../assets/images/empty.svg").default}
                  alt="Dan Abramov"
                /> */}
                <p>You don’t have any Teller in this group yet.</p>
                <p>Start adding tellers now.</p>
                {!!!singleTellerGroupCashiers?.cashier?.length}
              </EmptyPage>
            ) : (
              <div className="card-container">
                {singleTellerGroupCashiers?.cashiers?.map((data, i) => {
                  return (
                    <>
                      <TellerCard
                        key={data?.id}
                        noBorder="false"
                        data={data}
                        tellerId={data?.tellerId}
                        officeName={singleTellerGroupCashiers?.officeName}
                      />
                    </>
                  );
                })}
              </div>
            )}
             <AddTellerModal
            showModal={showModal}
            setShowModal={setShowModal}
            onHide={handleModalClose}
            closeAddTellerForm={closeAddTellerForm}
            addTeller={addTeller}
            />
            {/* {addTeller && (
              <AddTellerForm
                closeAddTellerForm={closeAddTellerForm}
                addTeller={addTeller}
              /> 
            )}*/}
          </ContentContainer>
        )}
      </Container>
    </div>
  );
};

export default SingleTellerGroup;

const LinkColor = styled(Link)`
  color: #2C1DFF;
`;
const CapitalizeText = styled.span`
  text-transform: capitalize !important;
`;

const Container = styled.div`
  background-color: #fff;
  border-radius: 5px;
  min-height: 70vh;

  .card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    height: min-content;
    place-items: center;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
`;

const ContentContainer = styled.div`
  display: ${(props) => (props.addTeller === true ? "grid" : "")} !important;
  grid-template-columns: 5fr 3fr;

  .add-teller {
    height: 100%;
    background: #ffffff;
    border: 0.5px solid #0000004b;
    border-radius: 10px 10px 0px 10px;
  }
`;

const EmptyPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    margin-bottom: 40px;
  }

  p {
    font-size: 15px;
    color: #969cba;
  }
`;
