import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { Avatar } from "@chakra-ui/react";
import {
  getAllCashierActionRequestsReceived,
  approveRejectRequest,
} from "../../../actions/tellerActions";
import CustomItemLoader from "../../../components/Loader/CustomItemLoader";
import { formatCurrency } from "../../../util/FormatAmount";
import { truncateString } from "../../../util/FormatText";
import { useDispatch, useSelector } from "react-redux";
import { Menu, Dropdown, Button } from "antd";
import {
  Rejected,
  Approved,
  H3,
  Grid,
  ActionButton,
  FormClass,
  FormElements,
} from "./styled";
import { Input, Textarea } from "@chakra-ui/react";
import ModalContainer from "../../../components/ModalContainer/index";
import { Modal } from "react-bootstrap";
import styled from "styled-components";

const ReportTable = ({ history, currentCashierId }) => {
  const [currentTableActive, setCurrentTableActive] = useState({});
  const [actionValue, setActionValue] = useState("");
  const [amount, setAmount] = useState("");
  const [modalState, toggleModal] = useState(false);
  const [rowDetailModal, toggleDetailsModal] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [activePagination, setActivePagination] = useState();
  const [myData, setMyData] = useState([]);
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    toggleModal(false);
    return setActionValue("");
  };

  const userDetails = useSelector((state) => state.userDetails);

  const {
    userDetails: { cashierData: { defaultCurrency = {} } = {} },
  } = userDetails || {};

  const defaultCurrencyValue = defaultCurrency.code;

  const dropdownValue = localStorage.getItem("myDefaultValue");
  const [currencyValue, setCurrencyValue] = useState("");

  useEffect(() => {
    if (dropdownValue) {
      // let oldCurrency = [];
      //  const oldCurrency = {...dropdownValue};
      //  const keyValue = oldCurrency[1];
      setCurrencyValue(dropdownValue);
    } else setCurrencyValue(defaultCurrencyValue);
  }, [dropdownValue, defaultCurrencyValue]);

  const refetchRequests = (currentCashierId) => {
    if (currentCashierId) {
      dispatch(
        getAllCashierActionRequestsReceived(
          `cashierId=${currentCashierId}&status=100`
        )
      );
    }
  };

  const handleClick = (value) => {
    setActionValue(value);
    return toggleModal(true);
  };

  const displayModalContent = (currentTableActive) => {
    switch (actionValue) {
      case "approve":
        return (
          <Modals
            data={currentTableActive}
            refetchRequests={refetchRequests}
            currentCashierId={currentCashierId}
            status="approve"
            handleCloseModal={handleCloseModal}
          />
        );
      case "reject":
        return (
          <Modals
            data={currentTableActive}
            refetchRequests={refetchRequests}
            status="reject"
            currentCashierId={currentCashierId}
            handleCloseModal={handleCloseModal}
          />
        );
      default:
        break;
    }
  };

  useEffect(() => {
    if (modalState === true) {
      return toggleDetailsModal(false);
    }
    if (rowDetailModal === true) {
      return toggleModal(false);
    }
  }, [modalState, rowDetailModal]);

  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          toggleDetailsModal(false);
          return handleClick("approve");
        }}
      >
        <p>Approve</p>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          toggleDetailsModal(false);
          return handleClick("reject");
        }}
      >
        <p>Reject</p>
      </Menu.Item>
    </Menu>
  );
  const isLoading = useSelector((state) => state.isLoading.isLoading);

  const tellerGroupReducer = useSelector((state) => state.tellerReducer);

  const { allCashierActionRequest } = tellerGroupReducer;

  const {
    totalFilteredRecords,
    totalItems,
    numberOfPages,
    pageItems = [],
  } = allCashierActionRequest || {};

  const closeDetailsModal = () => {
    toggleDetailsModal(false);
  };

  useEffect(() => {
    if (!currentCashierId) {
      return;
    } else if (!!currentCashierId) {
      dispatch(
        getAllCashierActionRequestsReceived(
          `cashierId=${currentCashierId}&status=100&offset=0&limit=10&sortOrder=DESC`
        )
      );
    }
  }, [currentCashierId, dispatch]);

  useEffect(() => {
    if (!!allCashierActionRequest && !!allCashierActionRequest.length) {
      setMyData(allCashierActionRequest);
    } else if (!!pageItems.length) {
      setMyData(pageItems);
    }
  }, [pageItems, allCashierActionRequest]);

  useEffect(() => {
    if (totalFilteredRecords !== 0 && totalFilteredRecords !== 1) {
      setPagination({
        current: activePagination,
        pageSize: 10,
        total: totalFilteredRecords,
      });
    } else if (totalItems === 0 && numberOfPages === 0) {
      setPagination(false);
    } else if (!!allCashierActionRequest && !!allCashierActionRequest.length) {
      setPagination({ defaultPageSize: 10 });
    }
  }, [
    totalItems,
    numberOfPages,
    totalFilteredRecords,
    activePagination,
    allCashierActionRequest,
  ]);

  const handleTableChange = (pagination, filters, sorter) => {
    setActivePagination(pagination.current);
    if (!!allCashierActionRequest && !!allCashierActionRequest.length) {
      setPagination({
        current: activePagination,
        pageSize: 10,
        total: allCashierActionRequest.length,
      });
    } else {
      let myOffsect = pagination.current * 10;
      let dataToSend2 = `cashierId=${currentCashierId}&status=100&offset=${
        myOffsect - 10
      }&limit=10&sortOrder=DESC`;
      dispatch(getAllCashierActionRequestsReceived(dataToSend2));
    }
  };

  const dataSource = [];

  !!myData &&
    myData?.forEach((eachData) => {
      const {
        id,
        amount,
        linkedCashierFirstname,
        linkedCashierLastname,
        actionPersonaType: { value: vaultData },
        actionStatus: { value: currentStatus },
        requestNote: description,
        currencyData: { code },
        actionRequestTimelineData,
        actionType: { value },
      } = eachData;
      if (code === currencyValue) {
        dataSource.push({
          key: id,
          linkedCashierFirstname,
          linkedCashierLastname,
          id: id,
          actionRequestTimelineData: actionRequestTimelineData,
          tellerName: truncateString(
            `${actionRequestTimelineData?.submittedByFirstname} ${actionRequestTimelineData?.submittedByLastname}`,
            18
          ),
          type: `${value} CASH`,
          amount: `${code} ${formatCurrency(amount)}`,
          description: description,
          avatar: (
            <Avatar
              name={
                actionRequestTimelineData?.submittedByFirstname
                  ? actionRequestTimelineData?.submittedByFirstname +
                    " " +
                    actionRequestTimelineData?.submittedByLastname
                  : ""
              }
            />
          ),
          action: (
            <div>
              {currentStatus === "PENDING" ? (
                <Dropdown
                  onClick={(e) => toggleDetailsModal(false)}
                  overlay={menu}
                >
                  <Button
                    onClick={(e) => e.stopPropagation()}
                    className="action_button"
                  >
                    Action
                  </Button>
                </Dropdown>
              ) : currentStatus === "APPROVED" ? (
                <Approved>{currentStatus}</Approved>
              ) : (
                <Rejected>{currentStatus}</Rejected>
              )}
            </div>
          ),
        });
      }
    });

  const columns = [
    { title: "", dataIndex: "avatar", key: "avatar" },
    { title: "Teller Name", dataIndex: "tellerName", key: "tellerName" },
    { title: " Type", dataIndex: "type", key: "type" },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    { title: "Action", dataIndex: "action", key: "action" },
  ];

  return (
    <div className="">
      <div>
        <ModalContainer size="md" show={modalState}>
          {modalState && displayModalContent(currentTableActive)}
        </ModalContainer>
        {!!modalState ? (
          " "
        ) : (
          <ModalContainer size="md" show={rowDetailModal}>
            {rowDetailModal && (
              <DetailsModal
                closeDetailsModal={closeDetailsModal}
                data={currentTableActive}
              />
            )}
          </ModalContainer>
        )}

        <div>
          {isLoading ? (
            <CustomItemLoader />
          ) : (
            <>
              {
                <Table
                  onRow={(r) => ({
                    onClick: () => {
                      setAmount(r?.amount);
                      toggleDetailsModal(true);
                      return setCurrentTableActive(r);
                    },
                  })}
                  onChange={handleTableChange}
                  columns={columns}
                  dataSource={dataSource}
                  pagination={pagination}
                  defaultPageSize={"10"}
                />
              }
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportTable;

const Modals = ({
  data,
  handleCloseModal,
  currentCashierId,
  status,
  refetchRequests,
}) => {
  const [requestNote, setNote] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (!requestNote) {
      return;
    } else {
      dispatch(
        approveRejectRequest(
          data?.id,
          status,
          requestNote,
          currentCashierId,
          refetchRequests
        )
      );
      return handleCloseModal();
    }
  };
  const { amount } = data;
  return (
    <>
      <Modal.Header>
        <Modal.Title>{status}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <FormElements style={{ width: "100%" }}>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Amount</label>
              <Input style={{ width: "60%" }} readOnly value={amount} />
            </div>

            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">
                Description<span className="required-color">*</span>
              </label>
              <Textarea
                style={{ fontSize: "12px" }}
                onChange={(e) => setNote(e.target.value)}
                placeholder="Approval note"
                name="note"
                size="md"
                resize={"none"}
                required
              />
            </div>
          </FormElements>
        </FormClass>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex mt-1">
          <ActionButton color="#2C1DFF" onClick={handleCloseModal}>
            <img
              src={require("../../../assets/images/icons/cancel.svg").default}
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={handleSubmit}
          >
            <img
              src={require("../../../assets/images/icons/save.svg").default}
              alt="submit icon"
            />
            Submit
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

const DetailsModal = ({ data, closeDetailsModal }) => {
  // console.log(data);
  return (
    <>
      <Modal.Header>
        <Modal.Title>
          {data?.avatar} <span className="ml-3">{data?.tellerName}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="wc-modal">
        <div className="p-1 position-relative">
          <div className="d-flex mb-3 ">
            <TextQuestion>Type: </TextQuestion> <Heading>{data?.type}</Heading>
          </div>
          <div className="d-flex mb-3 ">
            <TextQuestion>Amount </TextQuestion> <Text>{data?.amount}</Text>
          </div>

          {data?.actionRequestTimelineData ? (
            <div className="d-flex mb-3 ">
              <TextQuestion>Date </TextQuestion>
              <Text>
                {data?.actionRequestTimelineData?.submittedOnDate[2] +
                  "/" +
                  data?.actionRequestTimelineData?.submittedOnDate[1] +
                  "/" +
                  data?.actionRequestTimelineData?.submittedOnDate[0]}
              </Text>
            </div>
          ) : (
            ""
          )}
          {data?.actionRequestTimelineData?.submittedOnTime ? (
            <div className="d-flex mb-3 ">
              <TextQuestion>Time </TextQuestion>
              <Text>{data?.actionRequestTimelineData?.submittedOnTime}</Text>
            </div>
          ) : (
            ""
          )}

          <div className="d-flex mb-3 ">
            <TextQuestion>Description </TextQuestion>{" "}
            <Text>{data?.description}</Text>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="float-right mt-1">
          <ActionButton color="#2C1DFF" onClick={closeDetailsModal}>
            <img
              src={require("../../../assets/images/icons/cancel.svg").default}
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

const TextQuestion = styled.p`
  width: 40%;
  font-size: 12px;
  font-family: "Helvetica Neue";
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: black;
  margin-right: 25px;
`;

const Text = styled.p`
  font-size: 12px;
  font-family: "Helvetica Neue";
  color: black;
  text-align: center;
`;

const Heading = styled.h4`
  font-size: 12px;
  font-family: "Helvetica Neue";
  display: flex;
  align-items: center;
  text-align: center;
  color: #002c79;
`;
