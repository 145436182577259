import React, { useState } from "react";
import { Table } from "antd";
import { formatCurrency } from "../../../../../util/FormatAmount";
import { useSelector } from "react-redux";
import { ActionButton } from "../styles.jsx";
import ApproveReschedule from "./ApproveReschedule.jsx";
import ModalContainer from "../../../components/ModalContainer.jsx";
import RejectReschedule from "./RejectReschedule.jsx";
import CheckPermission from "../../../../../util/CheckPermission.js";
import { formatDate } from "../../../../../util/FormatDate.js";

const PreviewRepaymentSchedule = ({ loanId, id, changeCurrentView }) => {
	const { repaymentSchedule } = useSelector(state => state.savingsAndLoan);
	let { periods = [] } = repaymentSchedule || {};
  const { responseUserData: { permissions } } = useSelector((state) => state.preAuth);

	const [showApprovalModal, setShowApprovalModal] = useState(false);
	const [showRejectionModal, setShowRejectionModal] = useState(false);

	const toggleApprovalModal = () => setShowApprovalModal(state => !state);
	const toggleRejectionModal = () => setShowRejectionModal(state => !state);

	const columns = [
		{ title: "#", dataIndex: "index", key: "index" },
		{ title: "Days", dataIndex: "days", key: "days" },
		{
			title: "Date",
			dataIndex: "date",
			key: "date",
		},
		{ title: "Principal Due", dataIndex: "principalDue", key: "pricipalDue" },
		{
			title: "Balance Of Loan",
			dataIndex: "balanceOfLoan",
			key: "balanceOfLoan",
		},
		{ title: "Interest", dataIndex: "interest", key: "interest" },
		{ title: "Fees", dataIndex: "fees", key: "fees" },
		{ title: "Penalties", dataIndex: "penalties", key: "penalties" },
		{ title: "Due", dataIndex: "due", key: "due" },
		{ title: "Outstanding", dataIndex: "outstanding", key: "outstanding" },
	];

	let myRepaymentSchedule = [];

	let totalDays = 0;
	let totalPrincipalDue = 0;
	let totalInterest = 0;
	let totalFees = 0;
	let totalPenalties = 0;
	let totalDue = 0;
	let totalOutstanding = 0;

	periods.forEach((repayment, index) => {
		const {
			dueDate = [],
			obligationsMetOnDate = [],
			daysInPeriod = 0,
			principalDue = 0,
			totalDueForPeriod = 0,
			principalLoanBalanceOutstanding = 0,
			interestDue = 0,
			feeChargesDue = 0,
			penaltyChargesDue = 0,
			totalOutstandingForPeriod = 0,
			complete = false,
		} = repayment;

		totalDays += daysInPeriod;
		totalPrincipalDue += principalDue;
		totalInterest += interestDue;
		totalFees += feeChargesDue;
		totalPenalties += penaltyChargesDue;
		totalDue += totalDueForPeriod;
		totalOutstanding += totalOutstandingForPeriod;

		myRepaymentSchedule.push({
			key: index,
			index: (
				<div className="d-flex align-items-center">
					<div
						className={
							index === 0
								? "circle-yellow"
								: complete
									? "circle-green"
									: "circle-red"
						}
					></div>
					<span className="ml-2">{index + 1}</span>
				</div>
			),
			days: daysInPeriod,
			date: formatDate(dueDate),
			principalDue: formatCurrency(principalDue),
			balanceOfLoan: formatCurrency(principalLoanBalanceOutstanding),
			interest: formatCurrency(interestDue),
			fees: formatCurrency(feeChargesDue),
			penalties: formatCurrency(penaltyChargesDue),
			due: formatCurrency(totalDueForPeriod),
			outstanding: formatCurrency(totalOutstandingForPeriod),
		});
	});

	myRepaymentSchedule = myRepaymentSchedule.map((schedule, index) => {
    if ((index + 1) % 5 === 0 || index === myRepaymentSchedule.length - 1) {
      return [
        schedule,
        {
          key: `totals-${index}`,
          index: <span className="font-weight-bolder">TOTALS</span>,
          days: <span className="font-weight-bolder">{repaymentSchedule?.loanTermInDays}</span>,
          date: <></>,
          principalDue: <span className="font-weight-bolder">{formatCurrency(repaymentSchedule?.totalPrincipalExpected)}</span>,
          balanceOfLoan: <span className="font-weight-bolder">{formatCurrency(repaymentSchedule?.totalRepaymentExpected)}</span>,
          interest: <span className="font-weight-bolder">{formatCurrency(repaymentSchedule?.totalInterestCharged)}</span>,
          fees: <span className="font-weight-bolder">{formatCurrency(repaymentSchedule?.totalFeeChargesCharged)}</span>,
          penalties: <span className="font-weight-bolder">{formatCurrency(repaymentSchedule?.totalPenaltyChargesCharged)}</span>,
          due: <span className="font-weight-bolder">{formatCurrency(repaymentSchedule?.totalDue)}</span>,
          outstanding: <span className="font-weight-bolder">{formatCurrency(repaymentSchedule?.totalOutstanding)}</span>,
        },
      ];
    }
    return schedule;
  }).flat();

	return (
		<>
			<ModalContainer show={showApprovalModal} dialogClassName="document_infoModal">
				<ApproveReschedule
					loanId={loanId}
          id={id}
          onHide={toggleApprovalModal}
          changeCurrentView={changeCurrentView}
        />
			</ModalContainer>
			<ModalContainer show={showRejectionModal} dialogClassName="document_infoModal">
				<RejectReschedule
					loanId={loanId}
          id={id}
          onHide={toggleRejectionModal}
          changeCurrentView={changeCurrentView}
        />
			</ModalContainer>
			<h1 className="mt-4 font-weight-bolder fs-16">New repayment schedule</h1>
			<div className="float-right mb-3" style={{ marginTop: '-28px' }}>
				{CheckPermission('REJECT_RESCHEDULELOAN', permissions) && (
					<ActionButton color="#FF0000" onClick={toggleRejectionModal}>
						<img
							src={
								require("../../../../../assets/images/icons/cancel.svg").default
							}
							alt="cancel icon"
						/>
						Reject
					</ActionButton>
				)}
				{CheckPermission('APPROVE_RESCHEDULELOAN', permissions) && (
					<ActionButton
						onClick={toggleApprovalModal}
						color="#008F28"
					>
						<img
							src={
								require("../../../../../assets/images/icons/save.svg").default
							}
							alt="submit icon"
						/>
						Approve
					</ActionButton>
				)}
			</div>
			<Table
				style={{ overflow: "scroll" }}
				columns={columns}
				dataSource={myRepaymentSchedule}
				pagination={{ defaultPageSize: 6 }}
				defaultPageSize={6}
			/>
		</>
	);
};

export default PreviewRepaymentSchedule;
