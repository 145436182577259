import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Input, Select, FormControl, Textarea } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import ModalContainer from "../../components/ModalContainer";
import AddPaymentDetailsModal from "./AddPaymentDetailsModal";
import { FormElements, ActionButton } from "../styles";
import { ContentWrapper } from "./styles";
import { useSelector, useDispatch } from "react-redux";
import {
  createFrequentPosting,
  getAllAccountRules,
  getCurriencies,
  getOffices,
  getPaymentTypes,
} from "../../../../actions/accountingActions";
import Toast from "../../../../util/Toast";
import { formatDate } from "../../../../util/FormatDate";

const FrequentPosting = ({ history }) => {
  const [office, setOffice] = useState("");
  const [currency, setCurrency] = useState("");
  const [refNumber, setRefNumber] = useState("");
  const [comments, setComments] = useState("");
  const [accountingOptions, setAccountingOptions] = useState("");
  const [debitSelect, setDebitSelect] = useState("");
  const [debitInput, setDebitInput] = useState("");
  const [creditSelect, setCreditSelect] = useState("");
  const [creditInput, setCreditInput] = useState("");
  const [transactionDate, setTrasactionDate] = useState("");
  const [paymentDetailsEntered, setPaymentDetailsEntered] = useState({});

  const [modalState, toggleModal] = useState(false);
  const [modalContentName, setModalContentName] = useState("");
  const dispatch = useDispatch();
  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  const currentContent = (e) => {
    const val = e.currentTarget.id.trim();

    if (val) {
      toggleModal(true);
      setModalContentName(val);
    }
  };

  const accountingData = useSelector((state) => state.accountingData);

  useEffect(() => {
    dispatch(getPaymentTypes());
    dispatch(getAllAccountRules());
    dispatch(getOffices());
    dispatch(getCurriencies());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { accountRules, currencies, offices, paymentTypes } =
    accountingData || {};

  useEffect(() => {
    if (accountingOptions && accountRules && accountRules.length) {
      if (
        accountRules.filter((it) => String(it.id) === accountingOptions).length
      )
        setDebitSelect(
          accountRules.filter((it) => String(it.id) === accountingOptions)[0]
            .debitAccounts.length
            ? accountRules.filter(
                (it) => String(it.id) === accountingOptions
              )[0].debitAccounts[0].id
            : ""
        );
      setCreditSelect(
        accountRules.filter((it) => String(it.id) === accountingOptions)[0]
          .creditAccounts.length
          ? accountRules.filter((it) => String(it.id) === accountingOptions)[0]
              .creditAccounts[0].id
          : ""
      );
    }
  }, [accountingOptions, accountRules]);

  const handleModalClose = () => toggleModal(false);

  const displayModalContent = () => {
    switch (modalContentName) {
      case "addPaymentDetails":
        return (
          <AddPaymentDetailsModal
            paymentTypes={paymentTypes}
            onHide={handleModalClose}
            onSubmit={(data) => setPaymentDetailsEntered(data)}
          />
        );
      default:
        return;
    }
  };
  let newDate = new Date(transactionDate);
  let {
    paymentType,
    accountNumber,
    routingCode,
    receiptNumber,
    bankNumber,
    chequeNumber,
  } = paymentDetailsEntered;
  let frequentPostingData = {
    accountingRule: Number(accountingOptions),
    officeId: Number(office),
    transactionDate: formatDate(newDate, true),
    comments,
    locale: "en",
    currencyCode: currency,
    dateFormat: "dd MMMM yyyy",
    credits:
      creditSelect && creditInput
        ? [{ glAccountId: Number(creditSelect), amount: creditInput }]
        : [],
    debits:
      debitSelect && debitInput
        ? [{ glAccountId: Number(debitSelect), amount: debitInput }]
        : [],
    paymentTypeId: paymentType,
    accountNumber,
    checkNumber: chequeNumber,
    routingCode,
    receiptNumber,
    bankNumber,
    referenceNumber: refNumber,
  };

  const handleFrequentPosting = (e) => {
    e.preventDefault();
    if (!office || office.length < 1) {
      dispatch(Toast({ text: "Please select an Office", type: "info" }));
    } else if (!currency || currency.length < 1) {
      dispatch(Toast({ text: "Please select a Currency", type: "info" }));
    } else if (!transactionDate) {
      dispatch(
        Toast({ text: "Please select a transaction Date", type: "info" })
      );
    } else {
      if (!paymentType) delete frequentPostingData.paymentTypeId;
      if (!accountNumber) delete frequentPostingData.accountNumber;
      if (!chequeNumber) delete frequentPostingData.checkNumber;
      if (!routingCode) delete frequentPostingData.routingCode;
      if (!receiptNumber) delete frequentPostingData.receiptNumber;
      if (!bankNumber) delete frequentPostingData.bankNumber;
      if (!refNumber) delete frequentPostingData.referenceNumber;
      if (!debitSelect) delete frequentPostingData.debits;
      if (!creditSelect) delete frequentPostingData.credits;
      dispatch(createFrequentPosting(frequentPostingData, history));
    }
  };

  return (
    <>
      <ModalContainer show={modalState} dialogClassName="document_infoModal">
        {modalState && displayModalContent()}
      </ModalContainer>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Frequent Posting</h3>
            <p className="sub-title">Theses are predefined postings</p>
            <p className="sub-title mt-2">
              <span className="text-info mr-1">
                <Link to="/core/accounting">Accounting</Link>
              </span>
              / Frequent Posting
            </p>
          </div>
        </div>
        <ContentWrapper>
          <div className="row mb-0">
            <div className="col-md-12 col-xl-6">
              <FormElements>
                <div className="form-group mb-0 d-flex justify-content-between align-items-center">
                  <label className="text-label">Office*</label>
                  <Select
                    placeholder="Select Office"
                    size="md"
                    name="office"
                    value={office}
                    onChange={(e) => setOffice(e.target.value)}
                  >
                    {!!offices
                      ? offices.map(({ id, name }) => {
                          return (
                            <option value={id} key={id}>
                              {name}
                            </option>
                          );
                        })
                      : null}
                  </Select>
                </div>
              </FormElements>
            </div>
            <div className="col-md-12 col-xl-6">
              {/* <FormElements> */}
              <div className="form-group mb-0 d-flex justify-content-between align-items-center">
                <label className="text-label">Accounting Rule*</label>
                <Select
                  placeholder="Select accounting options*"
                  size="md"
                  name="accountingOptions"
                  value={accountingOptions}
                  onChange={(e) => setAccountingOptions(e.target.value)}
                >
                  {!!accountRules
                    ? accountRules.map(({ id, name }) => {
                        return (
                          <option value={id} key={id}>
                            {name}
                          </option>
                        );
                      })
                    : null}
                </Select>
              </div>
              {/* </FormElements> */}
            </div>
          </div>
          {accountingOptions &&
            accountRules &&
            accountRules.filter(
              (it) => String(it.id) === accountingOptions
            ) && (
              <FormElements>
                <div className="">
                  <label className="text-muted mb-4">
                    Affected GL entries*
                  </label>
                  <div className="row">
                    <div className="col-md-12 col-xl-6">
                      <p>Debit</p>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <Select
                          size="md"
                          name="debitSelect"
                          placeholder="Select debit account"
                          value={debitSelect}
                          onChange={(e) => setDebitSelect(e.target.value)}
                        >
                          {accountingOptions &&
                          accountRules &&
                          accountRules.length
                            ? accountRules.filter(
                                (it) => String(it.id) === accountingOptions
                              ).length
                              ? accountRules
                                  .filter(
                                    (it) => String(it.id) === accountingOptions
                                  )[0]
                                  .debitAccounts.map(({ id, name }) => {
                                    return (
                                      <option value={id} key={id}>
                                        {name}
                                      </option>
                                    );
                                  })
                              : null
                            : null}
                        </Select>
                        <Input
                          style={{ width: "60%", marginLeft: 16 }}
                          size="md"
                          placeholder="Amount"
                          name="debitInput"
                          value={debitInput}
                          onChange={(e) => setDebitInput(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 col-xl-6">
                      <p>Credit</p>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <Select
                          size="md"
                          className="mr-2"
                          placeholder="Select credit account"
                          name="creditSelect"
                          value={creditSelect}
                          onChange={(e) => setCreditSelect(e.target.value)}
                        >
                          {accountingOptions &&
                          accountRules &&
                          accountRules.length
                            ? accountRules.filter(
                                (it) => String(it.id) === accountingOptions
                              ).length
                              ? accountRules
                                  .filter(
                                    (it) => String(it.id) === accountingOptions
                                  )[0]
                                  .creditAccounts.map(({ id, name }) => {
                                    return (
                                      <option value={id} key={id}>
                                        {name}
                                      </option>
                                    );
                                  })
                              : null
                            : null}
                        </Select>
                        <Input
                          style={{ width: "60%", marginLeft: 16 }}
                          placeholder="Amount"
                          size="md"
                          name="creditInput"
                          value={creditInput}
                          onChange={(e) => setCreditInput(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </FormElements>
            )}
          <div className="row">
            <div className="col-md-12 col-xl-6">
              <FormElements>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Currency*</label>
                  <Select
                    placeholder="Select Currency"
                    size="md"
                    name="currency"
                    value={currency}
                    onChange={(e) => setCurrency(e.target.value)}
                  >
                    {!!currencies
                      ? currencies.map(({ code, name }) => {
                          return (
                            <option value={code} key={code}>
                              {name}
                            </option>
                          );
                        })
                      : null}
                  </Select>
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Reference number</label>
                  <Input
                    style={{ width: "60%" }}
                    size="md"
                    name="refNumber"
                    value={refNumber}
                    onChange={(e) => setRefNumber(e.target.value)}
                  />
                </div>
              </FormElements>
            </div>{" "}
            <div className="col-md-12 col-xl-6">
              <FormElements>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Transaction date*</label>
                  <FormControl style={{ width: "70%" }}>
                    <DatePicker
                      id="published-date"
                      selected={transactionDate}
                      onChange={(date) => setTrasactionDate(date)}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="dd/mmm/yyyy"
                    />
                  </FormControl>
                </div>
              </FormElements>
            </div>
          </div>

          <div className="row">
            <div className="button-right">
              <ActionButton
                color="#2C1DFF"
                className="float-right"
                style={{ margin: "0px 0px" }}
                onClick={currentContent}
                id="addPaymentDetails"
              >
                <img
                  src={
                    require("../../../../assets/images/icons/add.svg").default
                  }
                  alt="add icon"
                />
                Add Payment details
              </ActionButton>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <FormElements>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Comments</label>
                  <Textarea
                    size="md"
                    style={{ width: "80%", marginTop: "20px" }}
                    resize={"none"}
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                  />
                </div>
              </FormElements>
            </div>
          </div>

          <div className="row">
            <div className="button-right">
              <ActionButton
                color="#2C1DFF"
                className="float-right"
                style={{ margin: "0px 25px" }}
                onClick={handleFrequentPosting}
              >
                <img
                  src={
                    require("../../../../assets/images/icons/check-mark.svg")
                      .default
                  }
                  alt="mark icon"
                />
                Submit
              </ActionButton>
              <ActionButton
                color="#2C1DFF"
                className="float-right"
                style={{ margin: "0px 0px" }}
                // onClick={}
              >
                <img
                  src={
                    require("../../../../assets/images/icons/cancel.svg")
                      .default
                  }
                  alt="add icon"
                />
                Cancel
              </ActionButton>
            </div>
          </div>
        </ContentWrapper>
      </div>
    </>
  );
};

export default FrequentPosting;
