import {
  SET_SAVINGS_ACCOUNT_DETAILS,
  SET_LOAN_DETAILS,
  SET_CUSTOMER_OPTIONS,
  SET_SAVINGS_ACCOUNT_TEMPLATE,
  SET_SAVINGS_ACCOUNT_EMAIL_NOTIFICATIONS,
  SET_SAVINGS_ACCOUNT_SMS_NOTIFICATIONS,
  SET_SAVINGS_ACCOUNT_CHARGE_TEMPLATE,
  SET_SAVINGS_ACCOUNT_TRANSACTION_TEMPLATE,
  SET_TRANSFER_SAVINGS_ACCOUNT_TEMPLATE,
  SET_LOAN_DOCUMENT,
  SET_LOAN_ACCOUNT_TEMPLATE,
  SET_LOAN_DISBURSE_DETAILS,
  SET_REPAY_LOAN_TEMPLATE,
  SHOW_TRANSFER_MODAL_SUCCESSFUL,
  SHOW_MULTIPLE_TRANSFER_MODAL_SUCCESSFUL,
  SET_RESCHEDULED_LOAN_DETAILS,
  SET_SAVINGS_TRANSACTIONS,
  SET_SAVINGS_EVENTS,
  SET_OPTIONS_EVENTS,
  SET_LOAN_TRANSACTIONS,
  SET_SAVINGS_ACCOUNT_AUDIT,
  SET_LOAN_ACCOUNT_AUDIT,
  SET_LOAN_ACCOUNT_CHARGES,
  SET_SAVINGS_CHART,
  SET_LOAN_CHART,
  UPDATE_CHARGES,
  SET_LOAN_GUARANTOR_TEMPLATE,
  DELETE_LOAN_GUARANTOR_REQUEST,
  DELETE_LOAN_GUARANTOR_SUCCESS,
  DELETE_LOAN_GUARANTOR_FAIL,
  RECOVER_LOAN_GUARANTOR_FAIL,
  RECOVER_LOAN_GUARANTOR_REQUEST,
  RECOVER_LOAN_GUARANTOR_SUCCESS,
  WAIVE_CHARGES,
  LOAN_ACCOUNT_SUCCESS,
  LOAN_ACCOUNT_REQUEST,
  LOAN_ACCOUNT_FAIL,
  LOAN_ACCOUNT_RESET,
  UPDATE_LOAN_CHARGES,
  WAIVE_LOAN_CHARGES,
  DOWNLOAD_TRANSACTION_RECEIPT,
  DOWNLOAD_TRANSACTION_RECEIPT_SUCCESS,
  DOWNLOAD_TRANSACTION_RECEIPT_RESET,
  SET_LOAN_ACCOUNTS_CHARGE_TEMPLATE,
  REPAYMENT_SCHEDULE_REQUEST,
  REPAYMENT_SCHEDULE_SUCCESS,
  REPAYMENT_SCHEDULE_FAIL,
  FETCH_LOAN_COLLATERAL_TEMPLATE_REQUEST,
  FETCH_LOAN_COLLATERAL_TEMPLATE_SUCCESS,
  FETCH_LOAN_COLLATERAL_TEMPLATE_FAIL,
  ADD_COLLATERAL_TO_LOAN_REQUEST,
  ADD_COLLATERAL_TO_LOAN_SUCCESS,
  ADD_COLLATERAL_TO_LOAN_FAIL,
  DELETE_LOAN_NOTE_REQUEST,
  FETCH_LOAN_NOTES_SUCCESS,
  CREATE_LOAN_NOTE_SUCCESS,
  UPDATE_LOAN_NOTE_SUCCESS,
  DELETE_LOAN_NOTE_SUCCESS,
  FETCH_LOAN_NOTES_FAIL,
  CREATE_LOAN_NOTE_FAIL,
  UPDATE_LOAN_NOTE_FAIL,
  DELETE_LOAN_NOTE_FAIL,
  FETCH_LOAN_NOTES_REQUEST,
  CREATE_LOAN_NOTE_REQUEST,
  UPDATE_LOAN_NOTE_REQUEST,
  FETCH_SAVINGS_NOTES_REQUEST,
  CREATE_SAVINGS_NOTE_REQUEST,
  UPDATE_SAVINGS_NOTE_REQUEST,
  DELETE_SAVINGS_NOTE_REQUEST,
  FETCH_SAVINGS_NOTES_SUCCESS,
  CREATE_SAVINGS_NOTE_SUCCESS,
  UPDATE_SAVINGS_NOTE_SUCCESS,
  DELETE_SAVINGS_NOTE_SUCCESS,
  FETCH_SAVINGS_NOTES_FAIL,
  CREATE_SAVINGS_NOTE_FAIL,
  UPDATE_SAVINGS_NOTE_FAIL,
  DELETE_SAVINGS_NOTE_FAIL,
  SET_RESCHEDULE_LOAN_TEMPLATE,
  EDIT_REPAYMENT_SCHEDULE_REQUEST,
  EDIT_REPAYMENT_SCHEDULE_FAIL,
  EDIT_REPAYMENT_SCHEDULE_SUCCESS,
  SET_LOAN_CHARGES,
  SET_RESCHEDULED_LOAN_REQUESTS,
} from "../constants/savingsLoan";

const initialState = {
  savingsAccountDetails: {},
  savingsTransactionReceipt: {},
  loanDetails: {},
  loanAccountDetails: {},
  customerOptions: {},
  savingsAccountTemplate: {},
  savingsAccountTransactions: {},
  savingsAccountEvents: {},
  allEventOptions: {},
  savingsAccountEmailNotifications: {},
  savingsAccountSmsNotifications: {},
  savingsAccountChargeTemplate: {},
  savingsAccountTransactionTemplate: {},
  savingsAccountTransferTemplate: {},
  loanDocuments: [],
  loanAccountTemplate: {},
  loanAccountsChargeTemplate: {},
  loanAccountTransactions: {},
  loanAccountDisburseDetails: {},
  loanAccountRepaymentTemplate: {},
  loanGuarantorTemplate: {},
  loanAccountRescheduleTemplate: {},
  loanAccountRescheduleRequests: [],
  loanAccountRescheduleDetails: {},
  savingsAccountAudit: [],
  loanAccountAudit: [],
  loanAccountCharges: [],
  savingsAccountChart: [],
  loanAccountChart: [],
  showTransferModalSuccessful: false,
  showMultipleTransferModalSuccessful: false,
  loanAccount: {},
  singleLoanProduct: {},
  repaymentSchedule: {},
  loanNotes: [],
  savingsNotes: [],
};

function setSavingsAndLoanReducers(state = initialState, action) {
  switch (action.type) {
    case DOWNLOAD_TRANSACTION_RECEIPT: {
      return {
        ...state,
        loading: true,
      };
    }
    case DOWNLOAD_TRANSACTION_RECEIPT_RESET: {
      return {
        ...state,
        loading: false,
        savingsTransactionReceipt: { status: "", file: "" },
      };
    }
    case DOWNLOAD_TRANSACTION_RECEIPT_SUCCESS: {
      return {
        ...state,
        loading: false,
        savingsTransactionReceipt: action.payload,
      };
    }
    case SET_SAVINGS_ACCOUNT_DETAILS: {
      return {
        ...state,
        savingsAccountDetails: action.payload,
      };
    }
    case SET_SAVINGS_TRANSACTIONS: {
      return {
        ...state,
        savingsAccountTransactions: action.payload,
      };
    }
    case SET_SAVINGS_EVENTS: {
      return {
        ...state,
        savingsAccountEvents: action.payload,
      };
    }
    case SET_OPTIONS_EVENTS: {
      return {
        ...state,
        allEventOptions: action.payload,
      };
    }
    case SET_SAVINGS_ACCOUNT_TEMPLATE: {
      return {
        ...state,
        savingsAccountTemplate: action.payload,
      };
    }
    case SET_LOAN_DETAILS: {
      return {
        ...state,
        loanDetails: action.payload,
      };
    }
    case SHOW_TRANSFER_MODAL_SUCCESSFUL: {
      return {
        ...state,
        showTransferModalSuccessful: action.payload,
      };
    }
    case SHOW_MULTIPLE_TRANSFER_MODAL_SUCCESSFUL: {
      return {
        ...state,
        showMultipleTransferModalSuccessful: action.payload,
      };
    }
    case SET_LOAN_GUARANTOR_TEMPLATE: {
      return {
        ...state,
        loanGuarantorTemplate: action.payload,
      };
    }
    case DELETE_LOAN_GUARANTOR_REQUEST:
      return { loading: true, ...state };
    case DELETE_LOAN_GUARANTOR_SUCCESS:
      return { loading: false, deletedData: action.payload };

    case RECOVER_LOAN_GUARANTOR_REQUEST:
      return { loading: true, ...state };
    case RECOVER_LOAN_GUARANTOR_SUCCESS:
      return { loading: false, deletedData: action.payload };

    case DELETE_LOAN_GUARANTOR_FAIL:
      return { loading: false, error: action.payload };
    case RECOVER_LOAN_GUARANTOR_FAIL:
      return { loading: false, error: action.payload };

    case SET_CUSTOMER_OPTIONS: {
      return {
        ...state,
        customerOptions: action.payload,
      };
    }
    case SET_SAVINGS_ACCOUNT_EMAIL_NOTIFICATIONS: {
      return {
        ...state,
        savingsAccountEmailNotifications: action.payload,
      };
    }
    case SET_SAVINGS_ACCOUNT_SMS_NOTIFICATIONS: {
      return {
        ...state,
        savingsAccountSmsNotifications: action.payload,
      };
    }
    case SET_SAVINGS_ACCOUNT_CHARGE_TEMPLATE: {
      return {
        ...state,
        savingsAccountChargeTemplate: action.payload,
      };
    }
    case SET_SAVINGS_ACCOUNT_TRANSACTION_TEMPLATE: {
      return {
        ...state,
        savingsAccountTransactionTemplate: action.payload,
      };
    }
    case SET_TRANSFER_SAVINGS_ACCOUNT_TEMPLATE: {
      return {
        ...state,
        savingsAccountTransferTemplate: action.payload,
      };
    }
    case SET_LOAN_DOCUMENT: {
      return {
        ...state,
        loanDocuments: action.payload,
      };
    }
    case SET_LOAN_ACCOUNT_TEMPLATE: {
      return {
        ...state,
        loanAccountTemplate: action.payload,
      };
    }
    case SET_LOAN_ACCOUNTS_CHARGE_TEMPLATE: {
      return {
        ...state,
        loanAccountsChargeTemplate: action.payload,
      };
    }
    case SET_LOAN_TRANSACTIONS: {
      return {
        ...state,
        loanAccountTransactions: action.payload,
      };
    }
    case SET_LOAN_CHARGES: {
      return {
        ...state,
        loanCharges: action.payload,
      };
    }
    case SET_LOAN_DISBURSE_DETAILS: {
      return {
        ...state,
        loanAccountDisburseDetails: action.payload,
      };
    }
    case SET_REPAY_LOAN_TEMPLATE: {
      return {
        ...state,
        loanAccountRepaymentTemplate: action.payload,
      };
    }
    case SET_RESCHEDULE_LOAN_TEMPLATE: {
      return {
        ...state,
        loanAccountRescheduleTemplate: action.payload,
      };
    }
    case SET_RESCHEDULED_LOAN_REQUESTS: {
      return {
        ...state,
        loanAccountRescheduleRequests: action.payload,
      };
    }
    case SET_RESCHEDULED_LOAN_DETAILS: {
      return {
        ...state,
        loanAccountRescheduleDetails: action.payload,
      };
    }
    case SET_SAVINGS_ACCOUNT_AUDIT: {
      return {
        ...state,
        savingsAccountAudit: action.payload,
      };
    }
    case SET_LOAN_ACCOUNT_AUDIT: {
      return {
        ...state,
        loanAccountAudit: action.payload,
      };
    }
    case SET_LOAN_ACCOUNT_CHARGES: {
      return {
        ...state,
        loanAccountCharges: action.payload,
      };
    }

    case SET_SAVINGS_CHART: {
      return {
        ...state,
        savingsAccountChart: action.payload,
      };
    }
    case SET_LOAN_CHART: {
      return {
        ...state,
        loanAccountChart: action.payload,
      };
    }

    case UPDATE_CHARGES: {
      let newCharges = [];
      const oldCharges = state.savingsAccountDetails.charges;
      if (oldCharges) {
        newCharges = [...oldCharges];
        const amountOutstanding =
          newCharges[action.payload.index].amountOutstanding;
        newCharges[action.payload.index].amountOutstanding = 0;
        newCharges[action.payload.index].amountPaid = amountOutstanding;
      }
      // console.log(newCharges, 'dispatch chargess')
      return {
        ...state,
        savingsAccountDetails: {
          ...state.savingsAccountDetails,
          charges: newCharges,
        },
      };
    }

    case WAIVE_CHARGES: {
      let newCharges = [];
      const oldCharges = state.savingsAccountDetails.charges;
      if (oldCharges) {
        newCharges = [...oldCharges];
        const amountOutstanding =
          newCharges[action.payload.index].amountOutstanding;
        newCharges[action.payload.index].amountOutstanding = 0;
        newCharges[action.payload.index].amountWaived = amountOutstanding;
      }
      // console.log(newCharges, 'dispatch chargess')
      return {
        ...state,
        savingsAccountDetails: {
          ...state.savingsAccountDetails,
          charges: newCharges,
        },
      };
    }

    case UPDATE_LOAN_CHARGES: {
      let newCharges = [];
      const oldCharges = state.loanDetails.charges;
      console.log(oldCharges, "oldCharges");
      if (oldCharges) {
        newCharges = [...oldCharges];
        const amountOutstanding =
          newCharges[action.payload.index].amountOutstanding;
        newCharges[action.payload.index].amountOutstanding = 0;
        newCharges[action.payload.index].amountPaid = amountOutstanding;
      }
      return {
        ...state,
        loanDetails: {
          ...state.loanDetails,
          charges: newCharges,
        },
      };
    }

    case WAIVE_LOAN_CHARGES: {
      let newCharges = [];
      const oldCharges = state.loanDetails.charges;

      if (oldCharges) {
        newCharges = [...oldCharges];
        const amountOutstanding =
          newCharges[action.payload.index].amountOutstanding;
        newCharges[action.payload.index].amountOutstanding = 0;
        newCharges[action.payload.index].amountWaived = amountOutstanding;
      }
      // console.log(newCharges, 'dispatch chargess')
      return {
        ...state,
        loanDetails: {
          ...state.loanDetails,
          charges: newCharges,
        },
      };
    }

    case LOAN_ACCOUNT_REQUEST:
      return { ...state, loading: true };
    case LOAN_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        loanAccountDetails: action.payload,
      };
    case LOAN_ACCOUNT_FAIL:
      return { ...state, loading: false, error: action.payload };
    case LOAN_ACCOUNT_RESET:
      return {
        ...state,
        loanAccountDetails: null,
        loading: false,
        error: null,
      };
    case REPAYMENT_SCHEDULE_REQUEST:
    case EDIT_REPAYMENT_SCHEDULE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REPAYMENT_SCHEDULE_SUCCESS:
      return {
        ...state,
        loading: false,
        repaymentSchedule: action.payload,
      };
    case EDIT_REPAYMENT_SCHEDULE_SUCCESS:
      return {
        ...state,
        loading: false,
        editRepaymentScheduleResponse: action.payload,
      };
    case REPAYMENT_SCHEDULE_FAIL:
    case EDIT_REPAYMENT_SCHEDULE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_LOAN_NOTES_REQUEST:
    case CREATE_LOAN_NOTE_REQUEST:
    case UPDATE_LOAN_NOTE_REQUEST:
    case DELETE_LOAN_NOTE_REQUEST:
    case FETCH_SAVINGS_NOTES_REQUEST:
    case CREATE_SAVINGS_NOTE_REQUEST:
    case UPDATE_SAVINGS_NOTE_REQUEST:
    case DELETE_SAVINGS_NOTE_REQUEST:
      return { loading: true, ...state };
    case FETCH_LOAN_NOTES_SUCCESS:
      return { ...state, loading: false , loanNotes: action.payload};
    case FETCH_SAVINGS_NOTES_SUCCESS:
      return { ...state, loading: false , savingsNotes: action.payload};
    case CREATE_LOAN_NOTE_SUCCESS:
    case UPDATE_LOAN_NOTE_SUCCESS:
    case DELETE_LOAN_NOTE_SUCCESS:
    case CREATE_SAVINGS_NOTE_SUCCESS:
    case UPDATE_SAVINGS_NOTE_SUCCESS:
    case DELETE_SAVINGS_NOTE_SUCCESS:
      return { ...state, loading: false , response: action.payload};
    case FETCH_LOAN_NOTES_FAIL:
    case CREATE_LOAN_NOTE_FAIL:
    case UPDATE_LOAN_NOTE_FAIL:
    case DELETE_LOAN_NOTE_FAIL:
    case FETCH_SAVINGS_NOTES_FAIL:
    case CREATE_SAVINGS_NOTE_FAIL:
    case UPDATE_SAVINGS_NOTE_FAIL:
    case DELETE_SAVINGS_NOTE_FAIL:
      return { ...state, loading: false, error: action.payload };
    default: {
      return state;
    }
  }
}

export default setSavingsAndLoanReducers;
