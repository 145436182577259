import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Table, Button } from "antd";
import Countdown from "react-countdown";
import { ContentWrapper, ActionButton } from "../styles";
import { formatDate } from "../../../../util/FormatDate";

const GeneratedReports = () => {
  const reportData = useSelector((state) => state.reportData);

  let { generatingReports = [] } = reportData || {};

  const columns = [
    { title: "Id", dataIndex: "id", key: "id" },
    { title: "Report Name", dataIndex: "name", key: "name" },
    // { title: "Report Category", dataIndex: "category", key: "category" },
    { title: "Status", dataIndex: "status", key: "status" },
    { title: "Report Format", dataIndex: "format", key: "format" },
    { title: "Generated On", dataIndex: "generatedOn", key: "generatedOn" },
    { title: "Deletes In", dataIndex: "experiesIn", key: "experiesIn" },
    { title: "Action", dataIndex: "action", key: "action" },
  ];

  const reportTableData = [];

  // const checkFormatType = (type) => {
  //   if (type === "R_exportJSON") {
  //     return "Json";
  //   } else if (type === "R_exportPDF") {
  //     return "Pdf";
  //   } else if (type === "R_exportXLSX") {
  //     return "Xlsx";
  //   } else if (type === "R_exportCSV") {
  //     return "Cvs";
  //   } else {
  //     return "";
  //   }
  // };

  !!generatingReports &&
    !!generatingReports.length &&
    generatingReports.forEach((report, index) => {
      // console.log(report);
      const {
        reportName,
        type,
        reportCategory,
        generatedOn,
        status,
        expiresIn,
        file,
      } = report;

      let currentDate = Date.now();

      if (status !== "expired" && !!expiresIn && expiresIn > currentDate) {
        reportTableData.push({
          key: index + 1,
          id: index + 1,
          name: reportName,
          category: reportCategory,
          status: status,
          format: type,
          generatedOn: !!generatedOn ? formatDate(generatedOn) : "",
          experiesIn: !!expiresIn ? <Countdown date={expiresIn} /> : "",
          action: (
            <>
              {!!file ? (
                <>
                  <a href={file} rel="noreferrer" target="_blank" download>
                    <Button type="primary" size="small" className="ml-2">
                      Download
                    </Button>
                  </a>{" "}
                </>
              ) : (
                ""
              )}
            </>
          ),
        });
      } else if (!expiresIn) {
        reportTableData.push({
          key: index + 1,
          id: index + 1,
          name: reportName,
          category: reportCategory,
          status: status,
          format: type,
          generatedOn: !!generatedOn ? formatDate(generatedOn) : "",
          experiesIn: !!expiresIn ? <Countdown date={expiresIn} /> : "",
          action: (
            <>
              {!!file ? (
                <>
                  <a href={file} rel="noreferrer" target="_blank" download>
                    <Button type="primary" size="small" className="ml-2">
                      Download
                    </Button>
                  </a>{" "}
                </>
              ) : (
                ""
              )}
            </>
          ),
        });
      }
    });

  return (
    <div className="container-fluid">
      <ContentWrapper>
        {/* <div className="p-5 full-width"> */}
        {/* <div className="row"> */}
        <Table
          className="full-width"
          columns={columns}
          dataSource={reportTableData}
          pagination={{ defaultPageSize: 8 }}
          defaultPageSize={8}
          onRow={(r) => ({
            onClick: () => {},
          })}
        />
      </ContentWrapper>
    </div>
  );
};

export default GeneratedReports;
