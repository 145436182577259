import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { getLoanNotes, removeLoanNote } from "../../../../../actions/SavingsAndLoanActions";
import { ActionButton } from "../styles";

export const DeleteNote = ({ loanId, noteId, handleCloseModal }) => {
	const dispatch = useDispatch();

	const callback = () => dispatch(getLoanNotes(loanId));

	const deleteNote = () => dispatch(removeLoanNote(
		loanId,
		noteId,
		callback
	));

	return (
		<>
			<Modal.Header>
				<Modal.Title className="text-capitalize">Delete note</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p className="fs-14 m-1">
					Are you sure you want to delete this note?
				</p>
			</Modal.Body>
			<Modal.Footer className="d-flex gap-3 justify-content-end">
				<ActionButton
					color="#2C1DFF"
					style={{ minWidth: 'fit-content' }}
					onClick={handleCloseModal}
					className="px-3 m-0"
				>
					<img
						src={
							require("../../../../../assets/images/icons/cancel.svg").default
						}
						alt="cancel icon"
					/>
					Cancel
				</ActionButton>
				<ActionButton
					color="#2C1DFF"
					onClick={deleteNote}
					style={{ minWidth: 'fit-content' }}
					className="px-3 m-0"
				>
					<img
						src={
							require("../../../../../assets/images/icons/save.svg").default
						}
						alt="submit icon"
					/>
					Delete
				</ActionButton>
			</Modal.Footer>
		</>
	);
};
