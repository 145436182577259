import React, { useState } from "react";
import { Input, Select, FormControl, Checkbox } from "@chakra-ui/react";
import { NewActionButton, FormElements, FormClass } from "../styles";
import { Modal, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
  createNewEmployee,
  getAllEmployees,
} from "../../../../../actions/configurationActions";
import { useDispatch } from "react-redux";
import { formatDate } from "../../../../../util/FormatDate";
import Toast from "../../../../../util/Toast";
import { Select as AntSelect } from "antd";

const NewEmployee = ({ countryCodes, onHide, offices }) => {
  const dispatch = useDispatch();
  const [newEmployeeInfo, setNewEmployeeInfo] = useState({
    office: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    isLoanOfficer: false,
    mobileNumber: "",
    isActive: "",
  });
  const [contactCountryCode, setContactCountryCode] = useState('')
  const [joinedOn, setJoinedOn] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const { office, firstName, lastName, emailAddress, isLoanOfficer, mobileNumber, isActive } =
    newEmployeeInfo;

  const handleImageFileSelect = (event) => {
    const image = event.target.files[0]
    if (image) {
      const imageUrl = URL.createObjectURL(image);
      setPreviewImage(imageUrl);
      setImage(image)
    }
  }
  const createEmployeeHandler = (e) =>
    setNewEmployeeInfo({ ...newEmployeeInfo, [e.target.name]: e.target.value });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (office && firstName && lastName && emailAddress && isActive && joinedOn) {
      setLoading(true);
      const data = {
        officeId: office,
        firstname: firstName,
        lastname: lastName,
        emailAddress: emailAddress,
        isActive: isActive === "true" ? true : false,
        mobileNo: mobileNumber,
        countryCodeId: contactCountryCode,
        joiningDate: formatDate(joinedOn, true),
        isLoanOfficer: isLoanOfficer,
        locale: "en",
        dateFormat: "dd MMMM yyyy",
      };
      const func = () => {
        dispatch(getAllEmployees());
        onHide();
      };
      dispatch(createNewEmployee(data, image, func));
      setLoading(false);
    } else {
      Toast({
        text: "Please fill the required fields",
        icon: "error",
        timer: 2000,
      });
    }
  };

  // Change the keys of id and name to label and value because that is what ANT SELECT understands.
  const newCountryCodes = countryCodes && countryCodes.map(({ name: label, id: value, ...rest }) => ({ label, value, ...rest }));
  const handleSelected = (selected) => {
    const selectedCountryCode = countryCodes.find((e) => e.id === selected).id;
    setContactCountryCode(selectedCountryCode)
  }

  return (
    <>
      <Modal.Header>
        <Modal.Title>New Employee</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-0">
        <div className="d-flex align-items-center justify-content-center flex-column">
          <div className="position-relative">
            <img
              src={previewImage ?? require("../../../../../assets/images/defaultUser.png").default}
              alt={`${firstName}'s profile avatar`}
              className="rounded-circle border"
              style={{
                width: "5.375rem",
                height: "5.375rem",
                objectFit: "cover"
              }}
            />
            <label
              className="d-flex justify-content-center align-items-center border border-white rounded-circle position-absolute pointer"
              style={{
                background: "#2c1dff",
                width: "2rem",
                height: "2rem",
                bottom: "-4px",
                right: "-4px"
              }}
            >
              <img
                src={require("../../../../../assets/images/icons/add.svg").default}
                alt="add file icon"
              />
              <input
                type="file"
                accept="image/*"
                onChange={handleImageFileSelect}
                className="d-none"
              />
            </label>
          </div>
          <FormClass style={{ width: "90%" }}>
            <FormElements style={{ width: "100%" }}>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Office*</label>
                <Select
                  placeholder="Select Office"
                  size="md"
                  name="office"
                  value={office}
                  onChange={createEmployeeHandler}
                  disabled={loading}
                >
                  {offices && offices.length > 0
                    ? offices.map(({ name, id }) => (
                      <option value={id} key={id}>
                        {name}
                      </option>
                    ))
                    : null}
                </Select>
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">First name*</label>
                <Input
                  style={{ width: "70%" }}
                  size="md"
                  name="firstName"
                  value={firstName}
                  onChange={createEmployeeHandler}
                  disabled={loading}
                />
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Last name*</label>
                <Input
                  style={{ width: "70%" }}
                  size="md"
                  name="lastName"
                  value={lastName}
                  onChange={createEmployeeHandler}
                  disabled={loading}
                />
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Email*</label>
                <Input
                  style={{ width: "70%" }}
                  size="md"
                  name="emailAddress"
                  value={emailAddress}
                  onChange={createEmployeeHandler}
                  disabled={loading}
                />
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Is loan officer?
                </label>
                <FormControl style={{ width: "70%" }}>
                  <Checkbox
                    size="md"
                    isChecked={isLoanOfficer}
                    name="isLoanOfficer"
                    onChange={(e) => {
                      setNewEmployeeInfo({
                        ...newEmployeeInfo,
                        [e.target.name]: e.target.checked,
                      });
                    }}
                    disabled={loading}
                  />
                </FormControl>
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Status*</label>
                <Select
                  placeholder="Select Office"
                  size="md"
                  name="isActive"
                  value={isActive}
                  onChange={createEmployeeHandler}
                  disabled={loading}
                >
                  <option value="true">Enabled</option>
                  <option value="false">Disabled</option>
                </Select>
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Country code for SMS</label>
                <AntSelect
                  loading={true}
                  style={{ width: "70%" }}
                  showSearch
                  dropdownMatchSelectWidth={true}
                  placeholder="Search country code"
                  showArrow={false}
                  filterOption={(input, option) => (option?.label ?? '').includes(input)}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  onSelect={(selected) =>
                    handleSelected(selected)
                  }
                  options={newCountryCodes}
                  disabled={loading}
                >
                </AntSelect>
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Mobile Number for SMS</label>
                <Input
                  style={{ width: "73%" }}
                  size="md"
                  name="mobileNumber"
                  value={mobileNumber}
                  onChange={createEmployeeHandler}
                  disabled={loading}
                />

              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Joined On</label>
                <FormControl style={{ width: "69%" }}>
                  <div className="datepicker-100-width">
                    <DatePicker
                      style={{ width: "100%" }}
                      id="start-date"
                      selected={joinedOn}
                      onChange={(date) => setJoinedOn(date)}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="dd/mmm/yyyy"
                      disabled={loading}
                    />
                  </div>
                </FormControl>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-1">
          <NewActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require("../../../../../assets/images/icons/cancel.svg").default
              }
              alt="cancel icon"
            />
            Cancel
          </NewActionButton>
          <NewActionButton
            onClick={handleSubmit}
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            disabled={loading}
          >
            {loading ? (
              <Spinner
                animation="border"
                color="#fff"
                className="mr-2 text-white"
                size="sm"
              />
            ) : (
              <img
                src={
                  require("../../../../../assets/images/icons/save.svg").default
                }
                alt="submit icon"
              />
            )}
            Submit
          </NewActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default NewEmployee;
