import React, { useState, useEffect } from "react";
import { Table } from "antd";
import styled from "styled-components";
import { Avatar } from "@chakra-ui/react";
import { getAllCashierActionSent } from "../../../actions/tellerActions";
import CustomItemLoader from "../../../components/Loader/CustomItemLoader";
import { formatCurrency } from "../../../util/FormatAmount";
import { truncateString } from "../../../util/FormatText";
import { useDispatch, useSelector } from "react-redux";
import {
  H3,
  Grid,
  ActionButton,
  FormElements,
  FormClass,
  GoBack,
} from "./styled";
import { ReactComponent as GoBackIcon } from "../../../assets/images/icons/back-left.svg";
import { Input, Textarea } from "@chakra-ui/react";
import ModalContainer from "../../../components/ModalContainer/index";
import { Modal } from "react-bootstrap";

const Transactions = ({ history }) => {
  const [currentTableActive, setCurrentTableActive] = useState({});
  const [actionValue, setActionValue] = useState("");
  const [amount, setAmount] = useState("");
  const [modalState, toggleModal] = useState(false);
  const [rowDetailModal, toggleDetailsModal] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [activePagination, setActivePagination] = useState();
  const [myData, setMyData] = useState([]);
  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);

  const {
    userDetails: { cashierData: { defaultCurrency = {} } = {} },
  } = userDetails || {};

  // const [dropdownValue, setDropdownValue] = useState("");

  const defaultCurrencyValue = defaultCurrency.code;

  const local = localStorage.getItem("myDefaultValue");

  let currentCashierId = localStorage.getItem("cashierId");
  const dropdownValue = localStorage.getItem("myDefaultValue");
  const [currencyValue, setCurrencyValue] = useState("");

  // useEffect(() => {
  //   setDropdownValue(local || defaultCurrencyValue);
  // }, [defaultCurrencyValue, local]);

  // console.log(currencyValue, "currencyValue");

  useEffect(() => {
    if (dropdownValue) {
      // let oldCurrency = [];
      //  const oldCurrency = {...dropdownValue};
      //  const keyValue = oldCurrency[1];
      setCurrencyValue(dropdownValue);
    } else setCurrencyValue(defaultCurrencyValue);
  }, [dropdownValue, defaultCurrencyValue]);

  const handleCloseModal = () => {
    toggleModal(false);
    return setActionValue("");
  };

  const displayModalContent = () => {
    switch (actionValue) {
      case "approve":
        return (
          <Modals
            data={currentTableActive}
            status="Approve"
            handleCloseModal={handleCloseModal}
          />
        );
      case "reject":
        return (
          <Modals
            data={currentTableActive}
            status="Reject"
            handleCloseModal={handleCloseModal}
          />
        );
      default:
        break;
    }
  };
  const isLoading = useSelector((state) => state.isLoading.isLoading);

  const tellerGroupReducer = useSelector((state) => state.tellerReducer);

  const { tellerActionRequests } = tellerGroupReducer;

  const {
    totalFilteredRecords,
    totalItems,
    numberOfPages,
    pageItems = [],
  } = tellerActionRequests || {};

  const closeDetailsModal = () => {
    toggleDetailsModal(false);
  };

  useEffect(() => {
    if (currentCashierId) {
      dispatch(
        getAllCashierActionSent(
          `cashierId=${currentCashierId}&offset=0&limit=10&sortOrder=DESC`
        )
      );
    }
  }, [currentCashierId, dispatch]);

  useEffect(() => {
    if (!!tellerActionRequests && !!tellerActionRequests.length) {
      setMyData(tellerActionRequests);
    } else if (!!pageItems.length) {
      setMyData(pageItems);
    }
  }, [pageItems, tellerActionRequests]);

  useEffect(() => {
    if (totalFilteredRecords !== 0 && totalFilteredRecords !== 1) {
      setPagination({
        current: activePagination,
        pageSize: 10,
        total: totalFilteredRecords,
      });
    } else if (totalItems === 0 && numberOfPages === 0) {
      setPagination(false);
    } else if (!!tellerActionRequests && !!tellerActionRequests.length) {
      setPagination({ defaultPageSize: 10 });
    }
  }, [
    totalItems,
    numberOfPages,
    totalFilteredRecords,
    activePagination,
    tellerActionRequests,
  ]);

  const handleTableChange = (pagination, filters, sorter) => {
    setActivePagination(pagination.current);
    if (!!tellerActionRequests && !!tellerActionRequests.length) {
      setPagination({
        current: activePagination,
        pageSize: 10,
        total: tellerActionRequests.length,
      });
    } else {
      let myOffsect = pagination.current * 10;
      let dataToSend2 = `cashierId=${currentCashierId}&offset=${
        myOffsect - 10
      }&limit=10&sortOrder=DESC`;
      dispatch(getAllCashierActionSent(dataToSend2));
    }
  };

  const dataSource = [];

  console.log(dataSource, "dataSource");

  !!myData &&
    myData?.forEach((eachData) => {
      const {
        actionStatus: { value },
        id,
        linkedCashierFirstname,
        linkedCashierLastname,
        actionType: { value: actionValue },
        actionPersonaType: { value: vaultData },
        actionRequestTimelineData,
        linkedCashierId,
        clientAccountNumber,
        savingsAccountNumber,
        approvedNote,
        rejectedNote,
        clientDisplayName,
        requestNote,
        amount,
        currencyData: { code },
      } = eachData;
      const recipient = linkedCashierFirstname
        ? linkedCashierFirstname
        : clientDisplayName
        ? clientDisplayName
        : vaultData === "VAULT"
        ? vaultData
        : "";
      if (code === currencyValue) {
        dataSource.push({
          actionRequestTimelineData,
          linkedCashierId,
          clientAccountNumber,
          savingsAccountNumber,
          approvedNote: approvedNote,
          rejectedNote: rejectedNote,
          key: id,
          recipient: recipient,
          type: `${actionValue}`,
          status: value,
          amount: `${code} ${formatCurrency(amount)}`,
          description: truncateString(requestNote, 50),
          avatar: (
            <Avatar
              name={
                linkedCashierFirstname
                  ? linkedCashierFirstname + " " + linkedCashierLastname
                  : clientDisplayName
                  ? clientDisplayName
                  : vaultData
              }
            />
          ),
        });
      }
    });

  const columns = [
    { title: "", dataIndex: "avatar", key: "avatar" },
    { title: "Recipient", dataIndex: "recipient", key: "recipient" },
    { title: " Type", dataIndex: "type", key: "type" },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  return (
    <div className="container-fluid">
      <div className="spaceBetweenFlex pb-4">
        <div>
          <h3 className="page-title">Request History</h3>
          <GoBack to="/teller/requests" className="ml-0">
            <GoBackIcon />
            <span className="ml-3">Back</span>
          </GoBack>
        </div>
      </div>
      <div>
        <ModalContainer
          size="md"
          show={modalState}
          dialogClassName="document_infoModal"
        >
          {modalState && displayModalContent()}
        </ModalContainer>
        {!!modalState ? (
          " "
        ) : (
          <ModalContainer
            size="md"
            show={rowDetailModal}
            dialogClassName="document_infoModal"
          >
            {rowDetailModal && (
              <DetailsModal
                closeDetailsModal={closeDetailsModal}
                data={currentTableActive}
              />
            )}
          </ModalContainer>
        )}

        <div>
          {isLoading ? (
            <CustomItemLoader />
          ) : (
            <>
              <Table
                onRow={(r) => ({
                  onClick: () => {
                    setAmount(r?.amount);
                    toggleDetailsModal(true);
                    return setCurrentTableActive(r);
                  },
                })}
                onChange={handleTableChange}
                columns={columns}
                dataSource={dataSource}
                pagination={pagination}
                defaultPageSize={"10"}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Transactions;

const Modals = ({ data, handleCloseModal, status }) => {
  const { amount } = data;
  return (
    <>
      <Modal.Header>
        <Modal.Title>{status}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <FormElements style={{ width: "100%" }}>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Amount</label>
              <Input style={{ width: "70%" }} readOnly value={amount} />
            </div>

            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Description</label>
              {status === "Approve" ? (
                <Textarea
                  style={{ fontSize: "12px" }}
                  placeholder="Approval note"
                  name="approvedNote"
                  size="md"
                  resize={"none"}
                />
              ) : status === "Reject" ? (
                <Textarea
                  style={{ fontSize: "12px" }}
                  placeholder="Rejection note"
                  name="rejectedNote"
                  size="md"
                  resize={"none"}
                />
              ) : (
                ""
              )}
            </div>
          </FormElements>
        </FormClass>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex mt-1">
          <ActionButton color="#2C1DFF" onClick={handleCloseModal}>
            <img
              src={require("../../../assets/images/icons/cancel.svg").default}
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton color="#2C1DFF" style={{ margin: "0px 0px 0 10px" }}>
            <img
              src={require("../../../assets/images/icons/save.svg").default}
              alt="submit icon"
            />
            Submit
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

const DetailsModal = ({ data, closeDetailsModal }) => {
  return (
    <>
      <Modal.Header>
        <Modal.Title>
          {data?.avatar} <span className="ml-3">{data?.tellerName}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="wc-modal">
        <div className="p-1 position-relative">
          <div className="d-flex mb-3 ">
            <TextQuestion>Type: </TextQuestion> <Heading>{data?.type}</Heading>
          </div>
          <div className="d-flex mb-3 ">
            <TextQuestion>Amount </TextQuestion> <Text>{data?.amount}</Text>
          </div>
          {data?.savingsAccountNumber ? (
            <div className="d-flex mb-3 ">
              <TextQuestion>Account number </TextQuestion>{" "}
              <Text>{data?.savingsAccountNumber}</Text>
            </div>
          ) : (
            ""
          )}
          <div className="d-flex mb-3 ">
            <TextQuestion>Recipient: </TextQuestion>{" "}
            <Text>{data?.recipient}</Text>
          </div>
          <div className="d-flex mb-3 align-items-start">
            <TextQuestion>Date: </TextQuestion>{" "}
            <Text>
              {data?.actionRequestTimelineData?.submittedOnDate[2] +
                "/" +
                data?.actionRequestTimelineData?.submittedOnDate[1] +
                "/" +
                data?.actionRequestTimelineData?.submittedOnDate[0] ||
                "No description"}
            </Text>
          </div>
          <div className="d-flex mb-3">
            <TextQuestion>Submitted on: </TextQuestion>{" "}
            <Text>{data?.actionRequestTimelineData?.submittedOnTime}</Text>
          </div>
          <div className="d-flex mb-3">
            <TextQuestion>Submitted by: </TextQuestion>{" "}
            <Text>
              {data?.actionRequestTimelineData?.submittedByFirstname +
                " " +
                data?.actionRequestTimelineData?.submittedByLastname}
            </Text>
          </div>

          <div className="d-flex mb-3">
            <TextQuestion>Status:</TextQuestion>
            <Text>{data?.status}</Text>
          </div>
          <div className="d-flex mb-3">
            <TextQuestion>Description:</TextQuestion>
            <Text>{data?.description}</Text>
          </div>
          {data?.approvedNote ? (
            <div className="d-flex mb-3">
              <TextQuestion>Approval note:</TextQuestion>
              <Text>{data?.approvedNote}</Text>
            </div>
          ) : (
            ""
          )}
          {data?.actionRequestTimelineData?.rejectedOnDate ? (
            <>
              <div className="d-flex mb-3">
                <TextQuestion>Rejected date:</TextQuestion>
                <Text>
                  {data?.actionRequestTimelineData?.rejectedOnDate[2] +
                    "/" +
                    data?.actionRequestTimelineData?.rejectedOnDate[1] +
                    "/" +
                    data?.actionRequestTimelineData?.rejectedOnDate[0]}
                </Text>
              </div>

              <div className="d-flex mb-3">
                <TextQuestion>Rejected time:</TextQuestion>
                <Text>{data?.actionRequestTimelineData?.rejectedOnTime}</Text>
              </div>

              <div className="d-flex mb-3">
                <TextQuestion>Rejected by:</TextQuestion>
                <Text>
                  {data?.actionRequestTimelineData?.rejectedByFirstname +
                    " " +
                    data?.actionRequestTimelineData?.rejectedByLastname}
                </Text>
              </div>
            </>
          ) : (
            ""
          )}

          {data?.actionRequestTimelineData?.approvedOnDate ? (
            <>
              <div className="d-flex mb-3">
                <TextQuestion>Approved date:</TextQuestion>
                <Text>
                  {data?.actionRequestTimelineData?.approvedOnDate[2] +
                    "/" +
                    data?.actionRequestTimelineData?.approvedOnDate[1] +
                    "/" +
                    data?.actionRequestTimelineData?.approvedOnDate[0]}
                </Text>
              </div>

              <div className="d-flex mb-3">
                <TextQuestion>Approved time:</TextQuestion>
                <Text>{data?.actionRequestTimelineData?.approvedOnTime}</Text>
              </div>

              <div className="d-flex mb-3">
                <TextQuestion>Approved by:</TextQuestion>
                <Text>
                  {data?.actionRequestTimelineData?.approvedByFirstname +
                    " " +
                    data?.actionRequestTimelineData?.approvedByLastname}
                </Text>
              </div>
            </>
          ) : (
            ""
          )}

          {data?.approvedNote ? (
            <div className="d-flex mb-3">
              <TextQuestion>Approval note:</TextQuestion>
              <Text>{data?.approvedNote}</Text>
            </div>
          ) : (
            ""
          )}

          {data?.rejectedNote ? (
            <div className="d-flex mb-3">
              <TextQuestion>Rejection note:</TextQuestion>
              <Text>{data?.rejectedNote}</Text>
            </div>
          ) : (
            ""
          )}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="float-right mt-1">
          <ActionButton color="#2C1DFF" onClick={closeDetailsModal}>
            <img
              src={require("../../../assets/images/icons/cancel.svg").default}
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

const TextQuestion = styled.p`
  width: 40%;
  font-size: 12px;
  font-family: "Helvetica Neue";
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: black;
  margin-right: 25px;
`;

const Text = styled.p`
  font-size: 12px;
  font-family: "Helvetica Neue";
  color: black;
  text-align: center;
`;

const Heading = styled.h4`
  font-size: 12px;
  font-family: "Helvetica Neue";
  display: flex;
  align-items: center;
  text-align: center;
  color: #002c79;
`;
