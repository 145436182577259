import React from "react";
import DatePicker from "react-datepicker";
import { FormControl, Select, Input } from "@chakra-ui/react";
import { Select as AntSelect } from "antd";

import { FormElements } from "../styles";

const PersonalInformation = ({
  legalFormId,
  firstname,
  lastname,
  middlename,
  createCustomerHandler,
  dateOfBirth,
  setDateOfBirth,
  genderId,
  genderOptions,
  mobileNo,
  contactCountryCode,
  setCompanyIncDate,
  companyIncDate,
  companyName,
  setCompanyIncValidityDate,
  companyIncValidityDate,
  companyIncNumber,
  remarks,
  emailAddress,
  clientNonPersonConstitutionOptions,
  constitutionId,
  countryCodes
}) => {

  // Change the keys of id and name to label and value because that is what ANT SELECT understands.
  const newCountryCodes = countryCodes && countryCodes.map(({ name: label, id: value, ...rest }) => ({ label, value, ...rest }));
  const handleSelected = (selected) => {
    const selectedCountryCode = countryCodes.find((e) => e.id === selected).id;
    contactCountryCode = selectedCountryCode;
    const obj = { target: { name: 'contactCountryCode', value: contactCountryCode } };
    createCustomerHandler(obj);
  }

  return (
    <>
      <div style={{ width: "60%" }}>
        <FormElements>
          {legalFormId === "1" || legalFormId === "0" ? (
            <>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">First Name*</label>
                <Input
                  style={{ width: "60%" }}
                  size="md"
                  name="firstname"
                  className="general-font-size"
                  value={firstname}
                  onChange={createCustomerHandler}
                />
              </div>{" "}
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Last Name*</label>
                <Input
                  style={{ width: "60%" }}
                  size="md"
                  name="lastname"
                  className="general-font-size"
                  value={lastname}
                  onChange={createCustomerHandler}
                />
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Middle Name</label>
                <Input
                  style={{ width: "60%" }}
                  size="md"
                  name="middlename"
                  className="general-font-size"
                  value={middlename}
                  onChange={createCustomerHandler}
                />
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Date Of Birth</label>
                <FormControl style={{ width: "60%" }}>
                  <div className="datepicker-100-width">
                    <DatePicker
                      id="published-date"
                      selected={dateOfBirth}
                      onChange={(date) => setDateOfBirth(date)}
                      peekNextMonth
                      showMonthDropdown
                      className="general-font-size"
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="dd/mmm/yyyy"
                    />
                  </div>
                </FormControl>
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Gender</label>
                <Select
                  placeholder="Select Gender"
                  size="md"
                  name="genderId"
                  className="general-font-size"
                  value={genderId}
                  onChange={createCustomerHandler}
                >
                  {!!genderOptions.length
                    ? genderOptions.map(({ name, id }) => {
                      return (
                        <option value={id} id={id}>
                          {name}
                        </option>
                      );
                    })
                    : null}
                </Select>
              </div>
              {/* <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Marital Status</label>
                <Select
                  placeholder="Select Gender"
                  size="md"
                  name="maritalId"
                  value={maritalId}
                  onChange={createCustomerHandler}
                >
                  <option>Male</option>
                  <option>Female</option>
                </Select>
              </div> */}
            </>
          ) : (
            <>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Name*</label>
                <Input
                  style={{ width: "60%" }}
                  size="md"
                  name="companyName"
                  className="general-font-size"
                  value={companyName}
                  onChange={createCustomerHandler}
                />
              </div>{" "}
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Incorporation date</label>
                <FormControl style={{ width: "60%" }}>
                  <div className="datepicker-100-width">
                    <DatePicker
                      id="incorporation-date"
                      selected={companyIncDate}
                      onChange={(date) => setCompanyIncDate(date)}
                      peekNextMonth
                      className="general-font-size"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="dd/mm/yyyy"
                    />
                  </div>
                </FormControl>
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">
                  Incorporation Validity Till Date
                </label>
                <FormControl style={{ width: "60%" }}>
                <div className="datepicker-100-width">
                  <DatePicker
                    id="incorporation-validity-date"
                    selected={companyIncValidityDate}
                    onChange={(date) => setCompanyIncValidityDate(date)}
                    peekNextMonth
                    showMonthDropdown
                    className="general-font-size"
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="dd/mm/yyyy"
                  />
                  </div>
                </FormControl>
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Incorporation Number</label>
                <Input
                  style={{ width: "60%" }}
                  size="md"
                  type="number"
                  name="companyIncNumber"
                  className="general-font-size"
                  value={companyIncNumber}
                  onChange={createCustomerHandler}
                />
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Constitution</label>
                <Select
                  placeholder="Select Constitution"
                  size="md"
                  name="constitutionId"
                  className="general-font-size"
                  value={constitutionId}
                  onChange={createCustomerHandler}
                >
                  {!!clientNonPersonConstitutionOptions.length
                    ? clientNonPersonConstitutionOptions.map(({ name, id }) => {
                      return (
                        <option value={id} id={id}>
                          {name}
                        </option>
                      );
                    })
                    : null}
                </Select>
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Remarks</label>
                <Input
                  style={{ width: "60%" }}
                  size="md"
                  name="remarks"
                  value={remarks}
                  className="general-font-size"
                  onChange={createCustomerHandler}
                />
              </div>
            </>
          )}
          <div className="form-group d-flex justify-content-between align-items-center">
            <label className="text-label">Email</label>
            <Input
              style={{ width: "60%" }}
              size="md"
              name="emailAddress"
              className="general-font-size"
              value={emailAddress}
              onChange={createCustomerHandler}
            />
          </div>
          <div className="form-group d-flex justify-content-between align-items-center">
            <label className="text-label">Country code</label>

            <AntSelect
              loading={true}
              style={{ width: "60%" }}
              showSearch
              dropdownMatchSelectWidth={true}
              placeholder="Search country code"
              showArrow={false}
              filterOption={(input, option) => (option?.label ?? '').includes(input)}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
              }
              value={contactCountryCode}
              onSelect={(selected) =>
                handleSelected(selected)
              }
              options={newCountryCodes}
            >
            </AntSelect>

          </div>
          <div className="form-group d-flex justify-content-between align-items-center">
            <label className="text-label">Mobile Number</label>
            <Input
              style={{ width: "60%" }}
              size="md"
              name="mobileNo"
              className="general-font-size"
              value={mobileNo}
              onChange={createCustomerHandler}
            />
          </div>
        </FormElements>
      </div>
    </>
  );
};

export default PersonalInformation;
