import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import { ActionButton } from "../styles";
import { NewContentWrapper, TableButton } from "./styles";
import AccountInfoSlide from "./AccountInfoSlide";
import {
  getAllChartsOfAccount,
  filterAccountingData,
  clearFilteredAccountingData,
  setChartOfAccountParent,
} from "../../../../actions/accountingActions";
import TableLoader from "./TableLoader";
import TreeView, { Tree, InnerTree } from "../../../../components/TreeView";
import Toast from "../../../../util/Toast";
import CheckPermission from "../../../../util/CheckPermission";
import { textToUpperCase } from "../../../../util/Tools";

const columns = [
  { title: "Account Name", dataIndex: "accountName", key: "accountName" },
  { title: "GL Code", dataIndex: "glCode", key: "glCode" },
  { title: "Account type", dataIndex: "accountType", key: "accountType" },
  { title: "Status", dataIndex: "status", key: "status" },
  {
    title: "Manual Entries Status ",
    dataIndex: "manualEntryStatus",
    key: "manualEntryStatus",
  },
  { title: "Used as", dataIndex: "usedAs", key: "usedAs" },
  {
    title: "Tag",
    dataIndex: "linkedAccounts",
    key: "linkedAccounts",
    // render: (linkedAccounts) =>
    //   linkedAccounts ? <TableButton>{linkedAccounts}</TableButton> : "",
  },
];

const ChartsOfAccount = ({ history }) => {
  const dispatch = useDispatch();

  const accountingData = useSelector((state) => state.accountingData);

  let { chartsOfAccount = [], isLoading, filtered = null } = accountingData;
  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  const truncateString = (str, num) => {
    if (str.length > num) {
        let subStr = str.substring(0, num);
        return subStr + "...";
    } else {
        return str;
    }
}

console.log(chartsOfAccount , "inside chart of account")

const [showCard, setShowCard] = useState(false)

const openCard = () => {
  setShowCard(true);
};

  useEffect(() => {
    dispatch(clearFilteredAccountingData(""));
    dispatch(getAllChartsOfAccount());
    if (filtered === null) {
      search.current.value = "";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (filtered !== null && filtered.length === 0) {
    dispatch(
      Toast({
        text: "No valumes for this filter",
        icon: "error",
        timer: 2000,
      })
    );
  }

  const onFilterAccountingData = (e) => {
    search.current.value !== ""
      ? dispatch(filterAccountingData(e.target.value))
      : dispatch(clearFilteredAccountingData(""));
  };
  const search = useRef("");

  const chartsOfAccountData = [];
  const filteredAccountData = [];
  const displayAccountData = [];

  // filteredAccountData
  if (filtered !== null) {
    filtered.forEach((chartAccount) => {
      const {
        disabled,
        glCode,
        manualEntriesAllowed,
        name,
        tagId = {},
        type = {},
        usage = {},
      } = chartAccount;


      filteredAccountData.push({
        accountName: textToUpperCase(name) ,
        glCode,
        accountType: type.value,
        // status: `${disabled}`, //For the status use something like "active ? <span className="text-success">Active</span> : <span className="text-danger">Inactive</span>" for color change
        status: (
          <>
        {disabled ? 
        <span className="new-text-danger">Inactive</span>
        : 
        <span className="new-text-success">Active</span> 
        }
        </>
          // "active ? <span className="text-success">Active</span> : <span className="text-danger">Inactive</span>"
        ),
        // manualEntryStatus: `${manualEntriesAllowed}`,
        manualEntryStatus: (
          <>
          {manualEntriesAllowed ? 
            <span className="new-text-success">Allowed</span> 
            : <span className="new-text-danger">not Allowed</span>
          }
          </>
        ),
        usedAs: usage.value,
        linkedAccounts: (
          <>
          {tagId && tagId.name ? 
              <span>{truncateString(tagId?.name, 15)}</span> 
              : 
              <span>-</span> 
          }
          </>
        ),
        chartAccount: chartAccount,
      });
    });
  }

  // chartsOfAccountData
  chartsOfAccount.forEach((chartAccount) => {
    const {
      disabled,
      glCode,
      manualEntriesAllowed,
      name,
      tagId = {},
      type = {},
      usage = {},
    } = chartAccount;

    chartsOfAccountData.push({
      accountName: textToUpperCase(name) && truncateString(name , 15),
      glCode,
      accountType: type.value,
      // status: `${disabled}`, //For the status use something like "active ? <span className="text-success">Active</span> : <span className="text-danger">Inactive</span>" for color change
      status: (
        <>
        {disabled ? 
        <span className="new-text-danger">Inactive</span>
        : 
        <span className="new-text-success">Active</span> 
        }
        </>
      ),
      manualEntryStatus: (
        <>
        {manualEntriesAllowed ? 
          <span className="new-text-success">Allowed</span> 
          : <span className="new-text-danger">not Allowed</span>
        }
        </>
      ),
      usedAs: usage.value,
      linkedAccounts: (
         <>
          {tagId && tagId.name? 
            <span>{truncateString(tagId?.name, 15)}</span> 
            : 
            <span>-</span> 
          }
          
          </>
      ),
      chartAccount: chartAccount,
    });

  });

  // displayAccountData
  chartsOfAccount.forEach((chartAccount) => {
    const {
      glCode,
      name,
      type = {},
    } = chartAccount;

    displayAccountData.push({
      accountName: textToUpperCase(name),
      glCode,
      accountType: type.value,
      chartAccount: chartAccount,
    });
  });


  const [editState, setEditStage] = useState("");
  const [currentView, setCurrentView] = useState("")
  const [selectedAcount, setSelectedAccount] = useState({});
  const [isTree, setIsTree] = useState(true);

  const handleCloseDetail = () => {
    setEditStage(false);
    setShowCard(false);
  };

  const handleSetParent = () => {
    let parentToSet = {};
    setChartOfAccountParent(dispatch, parentToSet);
  };
  return (
    <>
      <div className="container-fluid p-relative">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-xl-4 col-md-12">
            <h3 className="page-title">Charts of Accounts</h3>
            <p className="sub-title">
              Lists of accounts used by the organization
            </p>
            <p className="sub-title mt-2">
              {editState === true ? (
                <>
                  {" "}
                  <span className="text-info mr-1">
                    <Link to="/core/charts-of-account">Charts of Accounts</Link>
                  </span>{" "}
                  /{" "}
                  {!!selectedAcount.accountName
                    ? selectedAcount.accountName
                    : ""}
                </>
              ) : (
                ""
              )}
            </p>
          </div>
          <div className="col-sm-12 col-xl-8 col-md-12">
            <div className="row" style={{ maxWidth: "100%" }}>
              <div className="col-sm-12 col-md-6">
                <InputGroup>
                  <InputLeftElement
                    width="3.1rem"
                    height="38px"
                    pointerEvents="none"
                  >
                    <img
                      src={
                        require("../../../../assets/images/icons/search.svg")
                          .default
                      }
                      style={{ height: "30px" }}
                      className="icon-img"
                      alt="Icon"
                    />
                  </InputLeftElement>
                  <Input
                    style={{
                      background: "#ffffff",
                      height: "45px",
                    }}
                    type="text"
                    placeholder="Search by account/GL code/account type"
                    ref={search}
                    onChange={(e) => {
                      setIsTree(true);
                      onFilterAccountingData(e);
                    }}
                  />
                </InputGroup>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="d-flex">
                  <ActionButton
                    color="#2C1DFF"
                    onClick={() => setIsTree((p) => !p)}
                    className=""
                  >
                    <img
                      src={
                        require(`../../../../assets/images/icons/${
                          isTree ? "tree" : "list"
                        }-view.svg`).default
                      }
                      className="d-inline mr-3"
                      alt="icon"
                    />
                    {isTree ? "Tree View" : "List View"}
                  </ActionButton>

                  {CheckPermission("CREATE_GLACCOUNT", permissions) && (
                    <ActionButton
                      onClick={(e) => {
                        handleSetParent();
                        history.push(
                          "/core/charts-of-account/create-gl-account"
                        );
                      }}
                      color="#2C1DFF"
                    >
                      <img
                        src={
                          require("../../../../assets/images/icons/add.svg")
                            .default
                        }
                        className="d-inline mr-3"
                        alt="icon"
                      />
                      Add Account
                    </ActionButton>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {showCard && (
          <div className="d-flex slide-detail">
            <AccountInfoSlide
              selectedAcount={selectedAcount}
              permissions={permissions}
              history={history}
              showCard={showCard}
              setShowCard={setShowCard}
              onHide={handleCloseDetail}
            />
          </div>
        )}
        <div className="tableToShift">
          <div className={`${editState === true ? "hide-detail" : ""}`}>
          {/* <div className="hide-detail"> */}
          {/* <div className=""> */}
            <NewContentWrapper>
              {isLoading === true && !chartsOfAccount.length ? (
                <TableLoader />
              ) : (
                <>
                  {isTree ? (
                    filtered !== null ? (
                      <Table
                        columns={columns}
                        dataSource={filteredAccountData}
                        pagination={{ defaultPageSize: 13 }}
                        defaultPageSize={13}
                        onRow={(r) => ({
                          onClick: () => {
                            console.log(r, "rrrr")
                            openCard()
                            // setEditStage(true);
                            setSelectedAccount(r);
                          },
                        })}
                      />
                    ) : (
                      <Table
                        columns={columns}
                        dataSource={chartsOfAccountData}
                        pagination={{ defaultPageSize: 13 }}
                        defaultPageSize={13}
                        onRow={(r) => ({
                          onClick: () => {
                            openCard()
                            // setEditStage(true);
                            setSelectedAccount(r);
                          },
                          // onClick={openCard}
                        })}
                      />

                    )
                  ) : 
                  chartsOfAccount && chartsOfAccount.length > 0 ? (
                    <TreeView>
                      <Tree id={1} name="Account types">
                        {[
                          ...new Set(
                            chartsOfAccount
                              .sort((a, b) =>
                                a.type.value.localeCompare(b.type.value)
                              )
                              .map((item) => item.type.value)
                          ),
                        ].map((type, j) => (
                          <div key={j}>
                            {chartsOfAccount.filter(
                              (item) => item.type.value === type
                            ).length > 0 ? (
                              <Tree name={type}>
                                {chartsOfAccount
                                  .filter((item) => item.type.value === type)
                                  .map((item, i) =>
                                    item.parentId === undefined &&
                                    item.usage.value === "DETAIL" ? (
                                      <div
                                        onClick={() => {
                                          setEditStage(true);
                                          setSelectedAccount(item);
                                        }}
                                        key={i}
                                      >
                                        <InnerTree
                                          name={textToUpperCase(item.name)}
                                          style={{ color: "#37ceff" }}
                                        />
                                      </div>
                                    ) : (
                                      item.parentId === undefined &&
                                      item.usage.value !== "DETAIL" && (
                                        <InnerTree
                                          name={
                                            <span
                                              onClick={() => {
                                                setEditStage(true);
                                                setSelectedAccount(item);
                                              }}
                                            >
                                              {textToUpperCase(item.name)}
                                            </span>
                                          }
                                        >
                                          {chartsOfAccount
                                            .filter(
                                              (inner) =>
                                                inner.parentId === item.id
                                            )
                                            .map((innerItem, ii) =>
                                              innerItem.parentId &&
                                              innerItem.usage.value !==
                                                "DETAIL" ? (
                                                <InnerTree
                                                  key={ii}
                                                  name={
                                                    <span
                                                      onClick={() => {
                                                        setEditStage(true);
                                                        setSelectedAccount(
                                                          innerItem
                                                        );
                                                      }}
                                                    >
                                                      {textToUpperCase(
                                                        innerItem.name
                                                      )}
                                                    </span>
                                                  }
                                                >
                                                  {chartsOfAccount
                                                    .filter(
                                                      (inner) =>
                                                        inner.parentId ===
                                                        innerItem.id
                                                    )
                                                    .map((moreInner, iii) => (
                                                      <div
                                                        onClick={() => {
                                                          setEditStage(true);
                                                          setSelectedAccount(
                                                            moreInner
                                                          );
                                                        }}
                                                        key={iii}
                                                      >
                                                        <InnerTree
                                                          name={moreInner.name}
                                                          style={{
                                                            color: "#37ceff",
                                                          }}
                                                        />
                                                      </div>
                                                    ))}
                                                </InnerTree>
                                              ) : (
                                                <div
                                                  onClick={() => {
                                                    setEditStage(true);
                                                    setSelectedAccount(
                                                      innerItem
                                                    );
                                                  }}
                                                  key={ii}
                                                >
                                                  <InnerTree
                                                    name={textToUpperCase(
                                                      innerItem.name
                                                    )}
                                                    style={{
                                                      color: "#37ceff",
                                                    }}
                                                  />
                                                </div>
                                              )
                                            )}
                                        </InnerTree>
                                      )
                                    )
                                  )}
                              </Tree>
                            ) : (
                              <Tree name={type} />
                            )}
                          </div>
                        ))}
                      </Tree>
                    </TreeView>
                  ) : (
                    <p>No Data found</p>
                  )}
                </>
              )}
            </NewContentWrapper>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChartsOfAccount;
