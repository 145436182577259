import React, {useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
// import DatePicker from "react-datepicker";
import { Input } from "@chakra-ui/react";
import { FormClass, ActionButton, FormElements } from "../styles";
import { undoApprovedLoanAccount } from "../../../../../actions/SavingsAndLoanActions";
import {
  // getLoanDetails,
  getCustomerAccounts,
} from "../../../../../actions/customerActions.js";

const UndoApprovalLoanModal = ({ userId, id, onHide, history }) => {
  const dispatch = useDispatch();

  const [note, setNote] = useState("");

  const handleUndoApprove = async () => {
    let dataToSend = {
      note,
    };

    await dispatch(undoApprovedLoanAccount(id, dataToSend, history));
    await dispatch(getCustomerAccounts(userId));

    onHide();

    // https://core.staging.woodcoreapp.com/woodcore/api/v1/loans/40?command=undoapproval
    // console.log()
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>Undo Loan Approval Account</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <FormElements style={{ width: "100%" }}>
            <div className="mt-5 form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Note</label>
              <Input
                style={{ width: "60%" }}
                name="note"
                value={note}
                onChange={(e) => setNote(e.target.value)}
                size="md"
              />
            </div>
          </FormElements>
   
        </FormClass>
      </Modal.Body>
      <Modal.Footer>
      <div className="float-right mt-1">
            <ActionButton color="#2C1DFF" onClick={onHide}>
              <img
                src={
                  require("../../../../../assets/images/icons/cancel.svg")
                    .default
                }
                alt="cancel icon"
              />
              Cancel
            </ActionButton>
            <ActionButton
              onClick={handleUndoApprove}
              color="#2C1DFF"
              style={{ margin: "0px 0px 0 10px" }}
            >
              <img
                src={
                  require("../../../../../assets/images/icons/save.svg").default
                }
                alt="submit icon"
              />
              Submit
            </ActionButton>
          </div>
      </Modal.Footer>
    </>
  );

};

export default UndoApprovalLoanModal;
