import React, { useState, useEffect } from "react";
import {
  ContentWrapper,
  ActionButton,
  FormElements,
  FormClass,
} from "../styles";
import DatePicker from "react-datepicker";
import { Input, Select, FormControl, Checkbox } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { useDispatch, useSelector } from "react-redux";
import {
  getChargesTemplate,
  resetNewChargeState,
  createNewCharge,
} from "../../../../../actions/configurationActions";
import Toast from "../../../../../util/Toast.js";
import { formatMonth } from "../../../../../util/FormatDate";

const CreateChargesView = ({ history }) => {
  const dispatch = useDispatch();
  const chargesTemplate = useSelector((state) => state.chargesTemplate);

  const { chargesTemplate: template } = chargesTemplate;
  useEffect(() => {
    dispatch(resetNewChargeState());
    dispatch(getChargesTemplate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [newChargeDetails, setNewChargeDetails] = useState({
    name: "",
    currencyCode: "",
    chargeAppliesTo: "",
    chargeTimeType: "",
    chargeCalculationType: "",
    chargePaymentMode: "",
    active: "",
    penalty: "",
    minCap: "",
    maxCap: "",
    taxGroupId: "",
  });
  const [feeInterval, setFeeInterval] = useState("");
  const [feeOnMonthDay, setFeeOnMonthDay] = useState(new Date());
  const {
    name,
    currencyCode,
    chargeAppliesTo,
    chargeTimeType,
    chargeCalculationType,
    chargePaymentMode,
    active,
    penalty,
    taxGroupId,
    // minCap,
    // maxCap
  } = newChargeDetails;
  const [processing, setProcessing] = useState(false);
  const [amount, setAmount] = useState("");
  const [minCap, setminCap] = useState(0);
  const [maxCap, setmaxCap] = useState(0);

  const createChargeHandler = (e) =>
    setNewChargeDetails({
      ...newChargeDetails,
      [e.target.name]: e.target.value,
    });

  // Handle Submit
  const handleSubmit = async () => {
    let data = {
      active,
      amount,
      minCap,
      maxCap,
      chargeAppliesTo,
      chargeCalculationType,
      chargePaymentMode,
      chargeTimeType,
      currencyCode,
      locale: "en",
      monthDayFormat: "dd MMM",
      penalty,
      taxGroupId,
      name,
    };

    const newObject = { ...data };
    for (const key in newObject) {
      if (Object.prototype.hasOwnProperty.call(newObject, key)) {
        const element = newObject[key];
        if (!element) {
          delete newObject[key];
        }
      }
    }

    data = newObject;

    // console.log(data, 'dataaa');

    if (chargeTimeType === "11" || chargeTimeType === "7") {
      data.feeInterval = feeInterval;
    }
    if (chargeTimeType === "6" || chargeTimeType === "7") {
      data.feeOnMonthDay = formatMonth(feeOnMonthDay);
    }
    if (!taxGroupId) {
      delete data.taxGroupId;
    }
    if (
      (name && chargeAppliesTo && currencyCode && amount) ||
      minCap ||
      maxCap
    ) {
      setProcessing(true);
      await dispatch(createNewCharge(data, history));
      setProcessing(false);
    } else {
      dispatch(
        Toast({
          text: "Please fill the required fields",
          icon: "error",
          timer: 2000,
        })
      );
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Create Charges</h3>
            <p className="sub-title">
              Define charges for Loan, Savings and Deposit charges
            </p>
            <p className="font-weight sub-title mt-2">
              <span className="text-info">
                <Link to="/core/charges"> Charges</Link>
              </span>{" "}
              / Create Charges
            </p>
          </div>
        </div>
        <ContentWrapper style={{ minHeight: "50vh" }}>
          <div className="d-flex justify-content-center align-items-center pt-5">
            <FormElements style={{ width: "45%", margin: "0 auto" }}>
              {/* Charges Applies To */}
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Charges Applies To</label>
                <Select
                  placeholder="Select Charge"
                  size="md"
                  value={chargeAppliesTo}
                  name="chargeAppliesTo"
                  onChange={createChargeHandler}
                >
                  {template !== undefined && template
                    ? template.chargeAppliesToOptions &&
                      template.chargeAppliesToOptions.map((cur) => (
                        <option value={cur.id} key={cur.id}>
                          {cur.value}
                        </option>
                      ))
                    : null}
                </Select>
                {/* <ActionButton
                  color="#2C1DFF"
                  style={{ margin: "0px 0px 0 10px" }}
                >
                  Add Charge
                </ActionButton> */}
              </div>
            </FormElements>
          </div>
          {chargeAppliesTo ? (
            <div className="container">
              {/* Name , Currency */}
              <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                {/* Name* */}
                <div className="col-sm-12 col-md-6 px-0 ">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label d-flex flex-align-center">
                          Name*
                        </label>
                        <Input
                          style={{ width: "70%" }}
                          size="md"
                          name="name"
                          value={name}
                          onChange={createChargeHandler}
                        />
                      </div>
                    </FormElements>
                  </FormClass>
                </div>

                {/* Currency* */}
                <div className="col-sm-12 col-md-6 px-0">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label d-flex flex-align-center">
                          Currency*
                        </label>
                        <Select
                          size="md"
                          name="currencyCode"
                          value={currencyCode}
                          onChange={createChargeHandler}
                        >
                          <option value="">Select Currency</option>
                          {template !== undefined && template
                            ? template.currencyOptions &&
                              template.currencyOptions.map((cur) => (
                                <option value={cur.code} key={cur.code}>
                                  {cur.displayLabel}
                                </option>
                              ))
                            : null}
                        </Select>
                      </div>
                    </FormElements>
                  </FormClass>
                </div>
              </div>

              {/* Charge Time Type* */}
              <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                <div className="col-sm-12 col-md-6 px-0 ">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label d-flex flex-align-center">
                          Charge Time Type*
                        </label>
                        <Select
                          size="md"
                          name="chargeTimeType"
                          value={chargeTimeType}
                          onChange={createChargeHandler}
                        >
                          <option value="">Select One</option>
                          {template !== undefined && template
                            ? chargeAppliesTo === "1"
                              ? template.loanChargeTimeTypeOptions &&
                                template.loanChargeTimeTypeOptions.map(
                                  (cur) => (
                                    <option value={cur.id} key={cur.id}>
                                      {cur.value}
                                    </option>
                                  )
                                )
                              : chargeAppliesTo === "2"
                              ? template.savingsChargeTimeTypeOptions &&
                                template.savingsChargeTimeTypeOptions.map(
                                  (cur) => (
                                    <option value={cur.id} key={cur.id}>
                                      {cur.value}
                                    </option>
                                  )
                                )
                              : chargeAppliesTo === "3"
                              ? template.clientChargeTimeTypeOptions &&
                                template.clientChargeTimeTypeOptions.map(
                                  (cur) => (
                                    <option value={cur.id} key={cur.id}>
                                      {cur.value}
                                    </option>
                                  )
                                )
                              : chargeAppliesTo === "4"
                              ? template.shareChargeTimeTypeOptions &&
                                template.shareChargeTimeTypeOptions.map(
                                  (cur) => (
                                    <option value={cur.id} key={cur.id}>
                                      {cur.value}
                                    </option>
                                  )
                                )
                              : null
                            : null}
                        </Select>
                      </div>
                    </FormElements>
                  </FormClass>
                </div>
                {/* Due Date* */}
                {chargeTimeType === "6" || chargeTimeType === "7" ? (
                  <div className="col-sm-12 col-md-6 px-0">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label d-flex flex-align-center">
                            Due Date*
                          </label>
                          <FormControl style={{ width: "70%" }}>
                            <div className="datepicker-100-width">
                              <DatePicker
                                id="published-date"
                                selected={feeOnMonthDay}
                                onChange={(date) => setFeeOnMonthDay(date)}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                placeholderText="dd/mmm/yyyy"
                              />
                            </div>
                          </FormControl>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                ) : (
                  ""
                )}

                {/* Repeats Every* */}
                {chargeTimeType === "11" || chargeTimeType === "7" ? (
                  <div className="col-sm-12 col-md-6 px-0">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label d-flex flex-align-center">
                            Repeats Every*
                          </label>
                          <Input
                            style={{ width: "70%" }}
                            size="md"
                            name="feeInterval"
                            value={feeInterval}
                            onChange={(e) => setFeeInterval(e.target.value)}
                          />
                          {chargeTimeType === "11" ? (
                            <p
                              style={{
                                marginLeft: "10px",
                              }}
                            >
                              Weeks
                            </p>
                          ) : (
                            ""
                          )}
                          {chargeTimeType === "7" ? (
                            <p
                              style={{
                                marginLeft: "10px",
                              }}
                            >
                              Months
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                ) : (
                  ""
                )}

                {/* Charge Calculation* */}
                <div className="col-sm-12 col-md-6 px-0">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label d-flex flex-align-center">
                          Charge Calculation*
                        </label>
                        <Select
                          size="md"
                          name="chargeCalculationType"
                          value={chargeCalculationType}
                          onChange={createChargeHandler}
                        >
                          <option value="">Select One</option>
                          {template !== undefined && template
                            ? chargeAppliesTo === "1"
                              ? template.loanChargeCalculationTypeOptions &&
                                template.loanChargeCalculationTypeOptions.map(
                                  (cur) => (
                                    <option value={cur.id} key={cur.id}>
                                      {cur.value}
                                    </option>
                                  )
                                )
                              : chargeAppliesTo === "2"
                              ? template.savingsChargeCalculationTypeOptions &&
                                template.savingsChargeCalculationTypeOptions.map(
                                  (cur) => (
                                    <option value={cur.id} key={cur.id}>
                                      {cur.value}
                                    </option>
                                  )
                                )
                              : chargeAppliesTo === "3"
                              ? template.clientChargeCalculationTypeOptions &&
                                template.clientChargeCalculationTypeOptions.map(
                                  (cur) => (
                                    <option value={cur.id} key={cur.id}>
                                      {cur.value}
                                    </option>
                                  )
                                )
                              : chargeAppliesTo === "4"
                              ? template.shareChargeCalculationTypeOptions &&
                                template.shareChargeCalculationTypeOptions.map(
                                  (cur) => (
                                    <option value={cur.id} key={cur.id}>
                                      {cur.value}
                                    </option>
                                  )
                                )
                              : null
                            : null}
                        </Select>
                      </div>
                    </FormElements>
                  </FormClass>
                </div>
              </div>

              {/* Charge Payment By* */}
              <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                {chargeAppliesTo === "1" && (
                  <div className="col-sm-12 col-md-6 px-0">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label d-flex flex-align-center">
                            Charge Payment By*
                          </label>
                          <Select
                            size="md"
                            name="chargePaymentMode"
                            value={chargePaymentMode}
                            onChange={createChargeHandler}
                          >
                            <option value="">Select One</option>
                            {template !== undefined && template
                              ? template.chargePaymetModeOptions &&
                                template.chargePaymetModeOptions.map((cur) => (
                                  <option value={cur.id} key={cur.id}>
                                    {cur.value}
                                  </option>
                                ))
                              : null}
                          </Select>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                )}

                {/* minimum cap and maximum cap */}
                {chargeCalculationType && chargeCalculationType !== "1" && (
                  <div className="col-sm-12 col-md-6 px-0 d-flex">
                    <div className="col-sm-6">
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex align-items-center">
                            <label className="text-label d-flex flex-align-center">
                              Minimum Cap
                            </label>
                            {/* <Input
                            style={{ width: "70%" }}
                            size="md"
                            type="number"
                            name="amount"
                            value={amount}
                            onChange={createChargeHandler}
                            /> */}
                            <CurrencyFormat
                              value={minCap}
                              name="minCap"
                              thousandSeparator={true}
                              className="amount-input"
                              style={{
                                width: "70%",
                              }}
                              // prefix={"$"}
                              // setNewChargeDetails((p) => ({
                              //   ...p,
                              //   active: e.target.checked,
                              // }))
                              onValueChange={(values) => {
                                const { value } = values;
                                // formattedValue = $2,223
                                // value ie, 2223
                                setminCap(value);
                              }}
                            />
                          </div>
                        </FormElements>
                      </FormClass>
                    </div>
                    <div className="col-sm-6">
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex  align-items-center">
                            <label className="text-label d-flex flex-align-center">
                              Maximum Cap
                            </label>
                            {/* <Input
                            style={{ width: "70%" }}
                            size="md"
                            type="number"
                            name="amount"
                            value={amount}
                            onChange={createChargeHandler}
                            /> */}
                            <CurrencyFormat
                              value={maxCap}
                              name="maxCap"
                              thousandSeparator={true}
                              className="amount-input"
                              style={{
                                width: "70%",
                              }}
                              // prefix={"$"}
                              onValueChange={(values) => {
                                const { value } = values;
                                // formattedValue = $2,223
                                // value ie, 2223
                                setmaxCap(value);
                              }}
                            />
                          </div>
                        </FormElements>
                      </FormClass>
                    </div>
                  </div>
                )}
              </div>

              {/* Active ,  Is Penalty? , Amount , Income From Charge */}
              <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                <div className="col-sm-12 col-md-6 px-0">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label d-flex flex-align-center">
                          Active
                        </label>
                        <FormControl style={{ width: "70%" }}>
                          <Checkbox
                            size="md"
                            name="active"
                            isChecked={active}
                            onChange={(e) =>
                              setNewChargeDetails((p) => ({
                                ...p,
                                active: e.target.checked,
                              }))
                            }
                          />
                        </FormControl>
                      </div>
                    </FormElements>
                  </FormClass>
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label d-flex flex-align-center">
                          Is Penalty?
                        </label>
                        <FormControl style={{ width: "70%" }}>
                          <Checkbox
                            size="md"
                            name="penalty"
                            isChecked={penalty}
                            onChange={(e) =>
                              setNewChargeDetails((p) => ({
                                ...p,
                                penalty: e.target.checked,
                              }))
                            }
                          />
                        </FormControl>
                      </div>
                    </FormElements>
                  </FormClass>
                </div>

                {/* Calculate Amount */}
                <div className="col-sm-12 col-md-6 px-0 ">
                  <FormClass>
                    <FormElements>
                      {chargeCalculationType &&
                      chargeCalculationType !== "1" ? (
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label d-flex flex-align-center">
                            % of Amount*
                          </label>
                          <CurrencyFormat
                            value={amount}
                            name="amount"
                            thousandSeparator={true}
                            className="amount-input"
                            style={{
                              width: "70%",
                            }}
                            onValueChange={(values) => {
                              const { value } = values;
                              setAmount(value);
                            }}
                          />
                        </div>
                      ) : (
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label d-flex flex-align-center">
                            Amount*
                          </label>
                          <CurrencyFormat
                            value={amount}
                            name="amount"
                            thousandSeparator={true}
                            className="amount-input"
                            style={{
                              width: "70%",
                            }}
                            onValueChange={(values) => {
                              const { value } = values;
                              setAmount(value);
                            }}
                          />
                        </div>
                      )}
                    </FormElements>
                  </FormClass>
                </div>

                {/* Tax Group */}
                <div className="col-sm-12 col-md-6 px-0 ">
                  <FormClass>
                    <FormElements>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label d-flex flex-align-center">
                          Tax Group
                        </label>
                        <Select
                          size="md"
                          name="taxGroupId"
                          value={taxGroupId}
                          onChange={createChargeHandler}
                        >
                          <option value="">Select an option</option>
                          {template !== undefined && template
                            ? template.taxGroupOptions &&
                              template.taxGroupOptions.map((cur) => (
                                <option value={cur.id} key={cur.id}>
                                  {cur.name}
                                </option>
                              ))
                            : null}
                        </Select>
                      </div>
                    </FormElements>
                  </FormClass>
                </div>

                {/* Income From Charge */}
                {chargeAppliesTo === "3" && (
                  <div className="col-sm-12 col-md-6 px-0 ">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label d-flex flex-align-center">
                            Income From Charge
                          </label>
                          <Select
                            size="md"
                            name="taxGroupId"
                            value={taxGroupId}
                            onChange={createChargeHandler}
                          >
                            <option value="">Select an option</option>
                            {template !== undefined && template
                              ? template.incomeOrLiabilityAccountOptions &&
                                template.incomeOrLiabilityAccountOptions
                                  .liabilityAccountOptions &&
                                template.incomeOrLiabilityAccountOptions.liabilityAccountOptions.map(
                                  (cur) => (
                                    <option value={cur.id} key={cur.id}>
                                      {cur.name}
                                    </option>
                                  )
                                )
                              : null}
                          </Select>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                )}
              </div>

              {/* Submit */}
              <div className="float-right pt-1">
                <ActionButton
                  onClick={handleSubmit}
                  color="#2C1DFF"
                  style={{ margin: "0px 0px 0 10px" }}
                >
                  {processing ? (
                    <Spinner
                      animation="border"
                      color="#fff"
                      className="mr-2 text-white"
                      size="sm"
                    />
                  ) : (
                    <img
                      src={
                        require("../../../../../assets/images/icons/save.svg")
                          .default
                      }
                      alt="submit icon"
                    />
                  )}
                  Submit
                </ActionButton>
              </div>
              <br />
              <br />
              <br />
              <br />
            </div>
          ) : null}
        </ContentWrapper>
      </div>
    </>
  );
};

export default CreateChargesView;
