import React, { useEffect, useState } from "react";
import { Input } from "@chakra-ui/react";
import { ActionButton2, FormElements, FormClass } from "../styles";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import {
  getAllUsers,
  passwordCheckReset,
  performPasswordCheck,
  updateUserPassword,
} from "../../../../../actions/configurationActions";
import Toast from "../../../../../util/Toast";
// import { performPasswordCheck } from "../../../../../services/Api/configuration.api.services";
import { logoutUser } from "../../../../../actions/authActions";

function LogoModal(props) {
  return (
    <Modal
      {...props}
      backdrop="static"
      keyboard={false}
      size="lg"
      centered
      contentClassName="customLogoModal"
    >
      <Modal.Body className="d-flex align-items-center justify-content-center">
        <img
          style={{ height: "40px", zIndex: "3" }}
          alt="woodcore logo"
          src={require("../../../../../assets/images/wcLogoWhite.svg").default}
        />
      </Modal.Body>
    </Modal>
  );
}

const EditUserPassword = ({ onHide, id, props }) => {
  const dispatch = useDispatch();

  const userOldPasswordCheck = useSelector((state) => state.checkUserInfo);

  const { loading, error: { response: { data } = {} } = {} } =
    userOldPasswordCheck || {};

  const [load, setLoad] = useState(false);
 

  const logoutHandler = () => {
    setLoad(true);
    setTimeout(async () => {
      await dispatch(logoutUser());
      return () => setLoad(false);
    }, 2000);
  };

  const [editUser, setEditUserPassword] = useState({
    oldPassword: "",
    password: "",
    repeatPassword: "",
  });

  let { oldPassword, password, repeatPassword } = editUser;
  const [isloading, setIsLoading] = useState(false);
  const [showOldPassword, setOldShowPassword] = useState(false);
  const [showNewPassword, setNewShowPassword] = useState(false);
  const [showForgetPassword, setForgetShowPassword] = useState(false);

  // const [passMessage, setPassMessage] = useState("");
  // const [passwordError, setPasswordError] = useState("");

  const [formError, setFormError] = useState({
    oldPasswordError: false,
    newPasswordError: false,
    repeatPassword: false,
  });

  const [passErrorOld , setPassErrorOld] = useState(false)

  


  const handleToggleOldPassword = () => {
    setOldShowPassword(!showOldPassword);
  };
  const handleToggleNewPassword = () => {
    setNewShowPassword(!showNewPassword);
  };
  const handleToggleForgetPassword = () => {
    setForgetShowPassword(!showForgetPassword);
  };

  const editUserPasswordChangeHandler = (e) =>
    setEditUserPassword({ ...editUser, [e.target.name]: e.target.value });

  useEffect(() => {
    if (oldPassword && id && oldPassword?.length > 3) {
      dispatch(performPasswordCheck(id, oldPassword));
    } else {
      return "";
    }
  }, [oldPassword, id]);

  useEffect(() => {
    if (data) {
      setFormError({
        oldPasswordError: true,
      });
    } else {
      setFormError({
        oldPasswordError: false,
      });
    }
  }, [data , formError?.oldPasswordError]);


  const onSubmit = async () => {
    if (password && repeatPassword) {
      if (password === repeatPassword) {
        setIsLoading(true);
        const userData = {
          oldPassword,
          password,
          repeatPassword,
        };
        const func = () => {
          dispatch(getAllUsers());
          setIsLoading(false);
          onHide();
        };
        dispatch(updateUserPassword(userData, id, func , logoutHandler));
        setIsLoading(false);
      } else {
        dispatch(
          Toast({
            text: "Password & Repeat Password Must be the same",
            icon: "error",
            timer: 2000,
          })
        );
      }
    } else {
      dispatch(
        Toast({
          text: "Please fill the required field",
          icon: "error",
          timer: 2000,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(passwordCheckReset());
  }, []);

  return (
    <>
      <LogoModal show={load} />
      <Modal.Header>
        <Modal.Title>Change Password</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <FormElements style={{ width: "100%" }}>
            <div className="row h-100">
              {/* current password */}
              <div className="col-sm-12 col-md-12">
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="textPass-label">Current Password*</label>
                  <Input
                    type={showOldPassword ? "text" : "password"}
                    style={{ width: "100%" }}
                    size="md"
                    name="oldPassword"
                    value={oldPassword}
                    onChange={editUserPasswordChangeHandler}
                    className={`${
                      !loading && formError.oldPasswordError
                        ? " pass-input-error "
                        : "pass-input"
                    }`}
                  />
                  <div onClick={handleToggleOldPassword}>
                    <img
                      src={
                        require("../../../../../assets/images/icons/passwordIcon.svg")
                          .default
                      }
                      className="passwordIcon"
                      alt="icon"
                    />
                    {/* {showPassword ? 'hide' : "show"} */}
                  </div>
                </div>
              </div>
              <span className="formErrorSpanPassword">
                {!loading &&
                oldPassword.length > 3 &&
                formError.oldPasswordError
                  ? "Incorrect password"
                  : ""}
              </span>
              <hr
                style={{
                  border: ".5px solid #E1E1E4",
                  width: "100%",
                  marginBottom: "15px",
                }}
              />

              <div className="col-sm-12 col-md-12">
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="textPass-label">New Password*</label>
                  <Input
                    style={{ width: "100%" }}
                    size="md"
                    name="password"
                    type={showNewPassword ? "text" : "password"}
                    value={password}
                    onChange={editUserPasswordChangeHandler}
                  />
                  <div onClick={handleToggleNewPassword}>
                    <img
                      src={
                        require("../../../../../assets/images/icons/passwordIcon.svg")
                          .default
                      }
                      className="passwordIcon"
                      alt="icon"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12">
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="textPass-label">Confirm Password*</label>
                  <Input
                    style={{ width: "100%"}}
                    size="md"
                    name="repeatPassword"
                    type={showForgetPassword ? "text" : "password"}
                    value={repeatPassword}
                    onChange={editUserPasswordChangeHandler}
                  />
                  <div onClick={handleToggleForgetPassword}>
                    <img
                      src={
                        require("../../../../../assets/images/icons/passwordIcon.svg")
                          .default
                      }
                      className="passwordIcon"
                      alt="icon"
                    />
                  </div>
                </div>
              </div>
            </div>
          </FormElements>
          <div className="float-right mt-4">
            <ActionButton2 color="#2C1DFF" onClick={onHide}>
              <img
                src={
                  require("../../../../../assets/images/icons/cancel.svg")
                    .default
                }
                alt="cancel icon"
              />
              Cancel
            </ActionButton2>
            <ActionButton2
              onClick={onSubmit}
              color="#2C1DFF"
              style={{ margin: "0px 0px 0 10px" }}
            >
              {isloading ? (
                <Spinner
                  animation="border"
                  color="#fff"
                  className="mr-2 text-white"
                  size="sm"
                />
              ) : (
                <img
                  src={
                    require("../../../../../assets/images/icons/save.svg")
                      .default
                  }
                  alt="submit icon"
                />
              )}
              Submit
            </ActionButton2>
          </div>
        </FormClass>
      </Modal.Body>
    </>
  );
};

export default EditUserPassword;
