import React, { useState } from "react";
import { Input, FormControl } from "@chakra-ui/react";
import { ActionButton, FormElements, FormClass } from "../styles";
import { Modal, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
  updateOfficeInfo,
  getAllOffices,
} from "../../../../../../actions/configurationActions";
import { useDispatch } from "react-redux";
import { formatDate } from "../../../../../../util/FormatDate";

const EditOffice = ({ onHide, officeData, offices = [] }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [editOfficeInfo, setEditOfficeInfo] = useState({
    office: officeData.name || "",
    parentOffice: officeData.parentName || "",
    externalId: officeData.externalId || "",
  });
  const { office, parentOffice, externalId } = editOfficeInfo;

  const editOfficeHandler = (e) =>
    setEditOfficeInfo({ ...editOfficeInfo, [e.target.name]: e.target.value });
  const [openedOn, setOpenedOn] = useState(
    new Date(officeData.openingDate) || new Date()
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (office && openedOn) {
      setLoading(true);
      const data = {
        name: office,
        dateFormat: "dd MMMM yyyy",
        locale: "en",
        openingDate: formatDate(openedOn, true),
        parentId: officeData ? officeData.parentId : null,
        externalId,
      };
      await dispatch(updateOfficeInfo(data, officeData.id));
      setLoading(false);
      dispatch(getAllOffices());
      onHide();
    }
  };
  return (
    <>
      <Modal.Header>
        <Modal.Title>Edit Office</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <FormElements style={{ width: "100%" }}>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Office*</label>
              <Input
                style={{ width: "60%" }}
                size="md"
                name="office"
                value={office}
                onChange={editOfficeHandler}
                placeholder="Input Office Name"
              />
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Parent Office*</label>
              <Input
                style={{ width: "60%" }}
                size="md"
                name="parentOffice"
                readOnly
                value={parentOffice}
              />
            </div>

            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Opened on*</label>
              <FormControl style={{ width: "60%" }}>
                <div className="datepicker-100-width">
                  <DatePicker
                    style={{ width: "100%" }}
                    id="opened-date"
                    selected={openedOn}
                    onChange={(date) => setOpenedOn(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="dd/mmm/yyyy"
                  />
                </div>
              </FormControl>
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">External Id</label>
              <Input
                style={{ width: "60%" }}
                size="md"
                name="externalId"
                value={externalId}
                onChange={editOfficeHandler}
                placeholder="Input Exterrnal ID"
              />
            </div>
          </FormElements>
        </FormClass>
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-1">
          <ActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require("../../../../../../assets/images/icons/cancel.svg")
                  .default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>

          <ActionButton
            onClick={handleSubmit}
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            disabled={loading}
          >
            {loading ? (
              <Spinner
                animation="border"
                color="#fff"
                className="mr-2 text-white"
                size="sm"
              />
            ) : (
              <img
                src={
                  require("../../../../../../assets/images/icons/save.svg")
                    .default
                }
                alt="submit icon"
              />
            )}
            Submit
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default EditOffice;
