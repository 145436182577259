import React from "react";
import { Modal } from "react-bootstrap";
import { FormClass, ActionButton } from "../styles";

import FileUpload from "../../../../../assets/images/file-upload.svg";

const SignatureModal = ({ handleSignature, onHide, signature }) => {
  return (
    <>
      <Modal.Header>
        <Modal.Title>Add Customer Signature</Modal.Title>
      </Modal.Header>
      <Modal.Body className="wc-modal">
        <FormClass style={{ width: "100%" }}>
          <div className="d-flex justify-content-center my-4">
            <div className="draganddrop">
              <input
                type="file"
                id="signature"
                name="image"
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: "0",
                  left: "0",
                  opacity: "0",
                  cursor: "pointer",
                }}
                onChange={handleSignature}
                onDrop={handleSignature}
                onDragOver={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
              <div className="draganddrop-dd">
                <div className="d-flex justify-content-center my-4">
                  <img className="file-Img" src={FileUpload} alt="" />
                </div>
                <p className="text-center">
                  Drag & drop your files here <br /> or
                </p>
                <ActionButton
                  color="#2C1DFF"
                  className="mt-4"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <input
                    type="file"
                    id="signature"
                    name="image"
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      top: "0",
                      left: "0",
                      opacity: "0",
                      cursor: "pointer",
                    }}
                    onChange={handleSignature}
                  />
                  Choose File
                </ActionButton>
                {!!signature ? <p>{signature.name}</p> : ""}
              </div>
            </div>
          </div>
        </FormClass>
      </Modal.Body>
      <Modal.Footer>
        <div className="row">
          <div className="spaceBetweenFlexEnd">
            <ActionButton color="#2C1DFF" onClick={onHide}>
              <img
                src={
                  require("../../../../../assets/images/icons/save.svg").default
                }
                alt="cancel icon"
              />
              Confirm
            </ActionButton>
          </div>
        </div>
      </Modal.Footer>
    </>
  );
};

export default SignatureModal;
