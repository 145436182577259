import config from "../../config";
import axiosData from "../axiosData";

export const getAllFixedDeposits = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: userData,
        url: `${config.apiGateway.BASE_API_URL}/fixeddepositproducts`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getAllFixedDepositsProducts = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/fixeddepositproducts`,
        params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getAllFixedDepositTemplate = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: userData,
        url: `${config.apiGateway.BASE_API_URL}/fixeddepositproducts/template`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const createAFixedDepositTemplate = (dataToSend) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/fixeddepositproducts`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Create New Fixed Deposit Product
 */
export const createFixedDepositsProduct = (fixedDepositProductData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        data: fixedDepositProductData,
        url: `${config.apiGateway.BASE_API_URL}/fixeddepositproducts`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Get Fixed Deposit template Template
const getFixedDepositsProductsTemplate = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/fixeddepositproducts/template`,
        params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
/*
 * Create New Fixed Deposit Product
 */
export const getFixedDepositsProduct = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/fixeddepositproducts/${id}?template=true`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getAFixedDepositProduct = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/fixeddepositproducts/${id}?template=true`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const editAFixedDepositTemplate = (dataToSend, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "PUT",
        // signal: cancelToken ? cancelToken : false,
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/fixeddepositproducts/${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const createAFixedDepositAccountTemplate = (id, params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/fixeddepositaccounts/template?clientId=${id}${params}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getFixedDepositAccountTemplates = (id, params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/fixeddepositaccounts/template?clientId=${id}${params}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const createAFixedDepositAccount = (dataToSend) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/fixeddepositaccounts`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getAFixedDepositAccountDetails = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/fixeddepositaccounts/${id}?associations=all&staffInSelectedOfficeOnly=true&template=true`,
        // url: `${config.apiGateway.BASE_API_URL}/fixeddepositaccounts/${id}?associations=all`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getLedgerTransactionsDetails = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: dataToSend,
        url: `${config.apiGateway.TRANSACTION_API_URL}/transactions/fetchSingle?savingsTransactionId=${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getLedgerTransactionsForLoanDetails = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: dataToSend,
        url: `${config.apiGateway.TRANSACTION_API_URL}/transactions/singleLedgerLoan?loanTransactionId=${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getOneFixedAccountTransactions = (id, dataToSend, params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/fixeddepositaccounts/${id}/transactions${params}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const approveAFixedDepositAccount = (id, dataToSend, action) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/fixeddepositaccounts/${id}?command=${action}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// POST https://DomainName/api/v1/savingsaccounts/{accountId}/charges/{savingsAccountChargeId}?command=paycharge

export const payAFixedDepositAccountCharge = (
  id,
  chargeId,
  dataToSend,
  action
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/savingsaccounts/${id}/charges/${chargeId}?command=${action}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const waiveAFixedDepositAccountCharge = (
  id,
  chargeId,
  dataToSend,
  action
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/savingsaccounts/${id}/charges/${chargeId}?command=${action}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const addAFixedDepositAccountCharge = (id, dataToSend) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/savingsaccounts/${id}/charges`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const deleteAFixedDepositAccount = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "DELETE",
        // signal: cancelToken ? cancelToken : false,
        // data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/fixeddepositaccounts/${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Generate file for FD account
 */
export const generateAFDFile = (accountNo) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.DYNAMIC_REPORT_API_URL}/reports/fixed_deposit_certificate?accountNo=${accountNo}`,
        // fixed_deposit_certificate?accountNo=
        // url: `${config.apiGateway.REPORT_API_URL}/reports/account-statement?dateRange=${startDate}-${endDate}&accountNo=${accountNumber}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const editFixedDepositAccountInfo = (userData, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "PUT",
        data: userData,
        url: `${config.apiGateway.BASE_API_URL}/fixeddepositaccounts/${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export default {
  generateAFDFile,
  getFixedDepositAccountTemplates,
  getAllFixedDepositsProducts,
  createFixedDepositsProduct,
  getFixedDepositsProductsTemplate,
  getFixedDepositsProduct,
  editFixedDepositAccountInfo,
};
