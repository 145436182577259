import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { Textarea, FormControl } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { formatDate } from "../../../../../../util/FormatDate";
import { activateSavingsAccount } from "../../../../../../actions/SavingsAndLoanActions";
import { FormClass, FormElements, ActionButton } from "../../styles.jsx";

const ActivateAccount = ({ id, onHide, history }) => {
  const dispatch = useDispatch();

  const [submittedOnDate, setSubmittedOnDate] = useState();
  let newDate = new Date(submittedOnDate);

  const handleActivateAccount = () => {
    const dataToSubmit = {
      activatedOnDate: formatDate(newDate, true),
      dateFormat: "dd MMM yyyy",
      locale: "en",
    };
    dispatch(activateSavingsAccount(id, dataToSubmit, history, onHide));
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>Activate account</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <FormElements style={{ width: "100%" }}>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Activated on*</label>
              <FormControl style={{ width: "60%" }}>
                <div className="datepicker-100-width">
                  <DatePicker
                    id="published-date"
                    selected={submittedOnDate}
                    onChange={(date) => setSubmittedOnDate(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="dd/mmm/yyyy"
                  />
                </div>
              </FormControl>
            </div>
          </FormElements>
          <div className="float-right mt-4">
            <ActionButton color="#2C1DFF" onClick={onHide}>
              <img
                src={
                  require("../../../../../../assets/images/icons/cancel.svg")
                    .default
                }
                alt="cancel icon"
              />
              Cancel
            </ActionButton>
            <ActionButton
              color="#2C1DFF"
              style={{ margin: "0px 0px 0 10px" }}
              onClick={(e) => {
                e.preventDefault();
                handleActivateAccount();
              }}
            >
              <img
                src={
                  require("../../../../../../assets/images/icons/save.svg")
                    .default
                }
                alt="submit icon"
              />
              Submit
            </ActionButton>
          </div>
        </FormClass>
      </Modal.Body>
    </>
  );
};

export default ActivateAccount;
