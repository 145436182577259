import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { FormClass, ActionButton } from "../../CustomerInformation/styles";
import {
  getLoanDisburseDetails,
  postLoanCommand,
} from "../../../../../actions/SavingsAndLoanActions";
import { formatDate } from "../../../../../util/FormatDate.js";

const RecoverFromGuarantor = ({ onHide, id, history, closeType }) => {
  const dispatch = useDispatch();

  const handleRecoverLoan = () => {
    let disbursementData = {
      locale: "en",
      dateFormat: "dd MMM yyy",
    };
    dispatch(
      postLoanCommand(id, disbursementData, "recoverGuarantees", history)
    );

    onHide();
    // console.log()
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>Recover From Guarantor</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <div className="">
            <h1>Are you Sure?</h1>
          </div>
        </FormClass>
      </Modal.Body>
      <Modal.Footer>
      <div className="float-right mt-1">
            <ActionButton color="#2C1DFF" onClick={onHide}>
              <img
                src={
                  require("../../../../../assets/images/icons/cancel.svg")
                    .default
                }
                alt="cancel icon"
              />
              Cancel
            </ActionButton>
            <ActionButton
              onClick={handleRecoverLoan}
              color="#2C1DFF"
              style={{ margin: "0px 0px 0 10px" }}
            >
              <img
                src={
                  require("../../../../../assets/images/icons/save.svg").default
                }
                alt="submit icon"
              />
              Confirm
            </ActionButton>
          </div>
      </Modal.Footer>
    </>
  );
};

export default RecoverFromGuarantor;
