import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Table } from "antd";
import { InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import CreateClosure from "./CreateClosure";
import ModalContainer from "../../components/ModalContainer";
import { ActionButton } from "../styles";
import { ContentWrapper } from "./styles";
import AccountClosureDetailSlide from "./AccountClosureDetailSlide";
import {
  getAllAccountClosures,
  getOffices,
} from "../../../../actions/accountingActions";
import TableLoader from "./TableLoader";
import CheckPermission from "../../../../util/CheckPermission";
import { formatDate } from "../../../../util/FormatDate";

const columns = [
  { title: "Office", dataIndex: "office", key: "office" },
  {
    title: "Account closure date",
    dataIndex: "accountClosureDate",
    key: "accountClosureDate",
  },
  { title: "Comment", dataIndex: "comment", key: "comment" },
  {
    title: "Close Accounting Created by",
    dataIndex: "closeAccountingCreatedBy",
    key: "closeAccountingCreatedBy",
  },
];

const AccountingClosures = ({ history }) => {
  const dispatch = useDispatch();
  const accountingData = useSelector((state) => state.accountingData);

  const { offices = [], accountsClosure = [], isLoading } = accountingData;
  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  const closuresData = [];
  accountsClosure.forEach((accountClosure) => {
    let { officeName, closingDate, comments, createdByUsername } =
      accountClosure;

    closuresData.push({
      office: officeName,
      accountClosureDate: formatDate(closingDate),
      comment: comments,
      closeAccountingCreatedBy: createdByUsername,
      selectedClosure: accountClosure,
    });
  });

  const [search, setSearch] = useState("");
  const [editState, setEditStage] = useState("");
  const [selectedClosure, setSelectedClosure] = useState({});

  const [modalState, toggleModal] = useState(false);
  const [modalContentName, setModalContentName] = useState("");

  const handleCloseDetail = () => {
    setEditStage(false);
  };

  useEffect(() => {
    dispatch(getAllAccountClosures());
    dispatch(getOffices());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentContent = (e) => {
    const val = e.currentTarget.id.trim();

    if (val) {
      toggleModal(true);
      setModalContentName(val);
    }
  };

  const handleModalClose = () => toggleModal(false);

  const displayModalContent = () => {
    switch (modalContentName) {
      case "createClosure":
        return (
          <CreateClosure
            history={history}
            offices={offices}
            onHide={handleModalClose}
          />
        );
      default:
        return;
    }
  };

  return (
    <>
      <ModalContainer show={modalState}>
        {modalState && displayModalContent()}
      </ModalContainer>
      <div className="container-fluid p-relative">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-xl-4 col-md-12 pb-3">
            <h3 className="page-title">Accounting Closures</h3>
            <p className="sub-title">
              Journal entries made at the end of a accounting peroid
            </p>
            <p className="sub-title mt-2">
              <span className="text-info mr-1">
                <Link to="/core/accounting">Accounting</Link>
              </span>
              {editState === true ? (
                <>
                  {" "}
                  <span className="text-info mr-1">
                    <Link to="/core/accounting/closing-entries">
                      / Accounting Closures
                    </Link>
                  </span>
                  / Details
                </>
              ) : (
                "/ Accounting Closures"
              )}
            </p>
          </div>
          <div className="col-sm-12 col-xl-8 col-md-12">
            <div className="spaceBetweenFlex" style={{ maxWidth: "100%" }}>
              <div className="col-sm-12 col-xl-8 col-md-6">
                <div className="">
                  <InputGroup>
                    <InputLeftElement
                      width="3.1rem"
                      height="50px"
                      pointerEvents="none"
                    >
                      <img
                        src={
                          require("../../../../assets/images/icons/search.svg")
                            .default
                        }
                        style={{ height: "30px" }}
                        alt="Icon"
                      />
                    </InputLeftElement>
                    <Input
                      style={{
                        background: "#ffffff",
                        height: "45px",
                      }}
                      type="text"
                      placeholder="Search by offices"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </InputGroup>
                </div>
              </div>
              <div className="col-sm-12 col-xl-4 col-md-6">
                {CheckPermission("CREATE_GLCLOSURE", permissions) && (
                  <ActionButton
                    color="#2C1DFF"
                    className="float-right"
                    onClick={currentContent}
                    id="createClosure"
                  >
                    <img
                      src={
                        require("../../../../assets/images/icons/add.svg")
                          .default
                      }
                      className="d-inline mr-3"
                      alt="icon"
                    />
                    Create Closure
                  </ActionButton>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${editState === true ? "show-detail" : "hide-detail"}`}
        >
          <AccountClosureDetailSlide
            handleCloseDetail={handleCloseDetail}
            thisSelectedClosure={selectedClosure}
            history={history}
            permissions={permissions}
          />
        </div>

        <div className="tableToShift">
          <div className={`${editState === true ? "shifting-table" : ""}`}>
            <ContentWrapper>
              {isLoading === true && !accountsClosure.length ? (
                <TableLoader />
              ) : (
                <Table
                  columns={columns}
                  dataSource={closuresData}
                  pagination={{ defaultPageSize: 8 }}
                  defaultPageSize={8}
                  onRow={(r) => ({
                    onClick: () => {
                      setEditStage(true);
                      setSelectedClosure(r);
                    },
                  })}
                />
              )}
            </ContentWrapper>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountingClosures;
