import React from "react";
import styled from "styled-components";

const index = ({ children, variant, icon, onClick }) => {
  return (
    <ActionButton variant={variant} onClick={onClick} className="refresh mt-2">
      {!!icon ? (
        <img
          className="mr-2 d-inline"
          src={icon}
          style={{ height: "15px" }}
          alt="Add"
        />
      ) : (
        ""
      )}
      <span className="d-inline">{children}</span>
    </ActionButton>
  );
};

export default index;

const ActionButton = styled.button`
  background: ${(props) =>
    props.variant === "secondary" ? "#fff" : "#2C1DFF"} !important;
  border: ${(props) =>
    props.variant === "secondary" ? "1px solid #2C1DFF" : ""} !important;
  color: ${(props) =>
    props.variant === "secondary" ? "#2C1DFF" : "#fff"} !important;
  border-radius: 5px !important;

  margin: 0 10px;
  margin-right: 0px !important;
  border: none;
  font-size: 14px;
  height: 35px;
  width: 130px;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  position: relative;
  cursor: pointer;
  img {
    height: 8px;
    margin-right: 10px !important;
    display: inline;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;
