import React, { useState } from "react";
import { Input, Select, FormControl } from "@chakra-ui/react";
import { ActionButton, FormElements, FormClass } from "../styles";
import { Modal, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useDispatch } from "react-redux";
import { formatDate } from "../../../../../../../util/FormatDate";
import {
  createNewTaxComponent,
  getAllTaxComponents,
} from "../../../../../../../actions/configurationActions";
import Toast from "../../../../../../../util/Toast";

const CreateTaxComponent = ({ onHide, template = [] }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  //create office states
  const [newTaxComponentInfo, setNewOfficeInfo] = useState({
    name: "",
    percentage: "",
    creditAccountType: "",
    creditAcountId: "",
  });
  const { name, percentage, creditAccountType, creditAcountId } =
    newTaxComponentInfo;

  const newTaxComponentHandler = (e) =>
    setNewOfficeInfo({
      ...newTaxComponentInfo,
      [e.target.name]: e.target.value,
    });
  const [openedOn, setOpenedOn] = useState(new Date() || "");
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (name && percentage && openedOn) {
      setLoading(true);
      const data = {
        name,
        dateFormat: "dd MMMM yyyy",
        locale: "en",
        startDate: formatDate(openedOn, true),
        creditAccountType: Number(creditAccountType),
        creditAcountId: Number(creditAcountId),
        percentage,
      };
      const func = () => {
        dispatch(getAllTaxComponents());
        onHide();
      };
      await dispatch(createNewTaxComponent(data, func));
      setLoading(false);
    } else {
      dispatch(
        Toast({
          text: "Please fill the required fields",
          icon: "error",
          timer: 2000,
        })
      );
    }
  };
  return (
    <>
      <Modal.Header>
        <Modal.Title>New Tax Component</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <FormElements style={{ width: "100%" }}>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Name*</label>
              <Input
                style={{ width: "60%" }}
                size="md"
                name="name"
                value={name}
                onChange={newTaxComponentHandler}
              />
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Percentage*</label>
              <Input
                style={{ width: "60%" }}
                size="md"
                name="percentage"
                value={percentage}
                onChange={newTaxComponentHandler}
              />
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Credit Account Type</label>
              <FormControl style={{ width: "60%" }}>
                <div className="select-100-width">
                  <Select
                    placeholder="Select An Option"
                    size="md"
                    name="creditAccountType"
                    value={creditAccountType}
                    onChange={newTaxComponentHandler}
                  >
                    {template
                      ? template.glAccountTypeOptions
                        ? template.glAccountTypeOptions.map((off, i) => (
                            <option value={off.id} key={i}>
                              {off.value}
                            </option>
                          ))
                        : null
                      : null}
                  </Select>
                </div>
              </FormControl>
            </div>

            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Credit Account</label>
              <FormControl style={{ width: "60%" }}>
                <div className="select-100-width">
                  <Select
                    placeholder="Select An Option"
                    size="md"
                    name="creditAcountId"
                    value={creditAcountId}
                    onChange={newTaxComponentHandler}
                  >
                    {template
                      ? template.glAccountOptions
                        ? creditAccountType === "1"
                          ? template.glAccountOptions.assetAccountOptions
                            ? template.glAccountOptions.assetAccountOptions.map(
                                (off, i) => (
                                  <option value={off.id} key={i}>
                                    {off.name}
                                  </option>
                                )
                              )
                            : null
                          : creditAccountType === "2"
                          ? template.glAccountOptions.liabilityAccountOptions
                            ? template.glAccountOptions.liabilityAccountOptions.map(
                                (off, i) => (
                                  <option value={off.id} key={i}>
                                    {off.name}
                                  </option>
                                )
                              )
                            : null
                          : creditAccountType === "3"
                          ? template.glAccountOptions.equityAccountOptions
                            ? template.glAccountOptions.equityAccountOptions.map(
                                (off, i) => (
                                  <option value={off.id} key={i}>
                                    {off.name}
                                  </option>
                                )
                              )
                            : null
                          : creditAccountType === "4"
                          ? template.glAccountOptions.incomeAccountOptions
                            ? template.glAccountOptions.incomeAccountOptions.map(
                                (off, i) => (
                                  <option value={off.id} key={i}>
                                    {off.name}
                                  </option>
                                )
                              )
                            : null
                          : creditAccountType === "5"
                          ? template.glAccountOptions.expenseAccountOptions
                            ? template.glAccountOptions.expenseAccountOptions.map(
                                (off, i) => (
                                  <option value={off.id} key={i}>
                                    {off.name}
                                  </option>
                                )
                              )
                            : null
                          : null
                        : null
                      : null}
                  </Select>
                </div>
              </FormControl>
            </div>

            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Start Date*</label>
              <FormControl style={{ width: "60%" }}>
                <div className="datepicker-100-width">
                  <DatePicker
                    style={{ width: "100%" }}
                    id="opened-date"
                    selected={openedOn}
                    onChange={(date) => setOpenedOn(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="dd/mmm/yyyy"
                  />
                </div>
              </FormControl>
            </div>
          </FormElements>
        </FormClass>
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-1">
          <ActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require("../../../../../../../assets/images/icons/cancel.svg")
                  .default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton
            onClick={handleSubmit}
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            disabled={loading}
          >
            {loading ? (
              <Spinner
                animation="border"
                color="#fff"
                className="mr-2 text-white"
                size="sm"
              />
            ) : (
              <img
                src={
                  require("../../../../../../../assets/images/icons/save.svg")
                    .default
                }
                alt="submit icon"
              />
            )}
            Submit
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default CreateTaxComponent;
