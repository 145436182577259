import React from "react";
import { Table } from "antd";
import Loading from "./Loading";

const columns = [
  { title: "First Name", dataIndex: "firstname", key: "firstname" },
  { title: "Last Name", dataIndex: "lastname", key: "lastname" },
  { title: "Loan Officer", dataIndex: "isLoanOfficer", key: "isLoanOfficer" },
  { title: "Office", dataIndex: "officeName", key: "officeName" },
  { title: "Status", dataIndex: "status", key: "status" },
  { title: "", dataIndex: "edit", key: "edit" },
];

const TableLoader = (props) => {
  const someChartsData = [
    {
      key: 1,
      firstname: <Loading />,
      lastname: <Loading />,
      isLoanOfficer: <Loading />,
      officeName: <Loading />,
      status: <Loading />,
      edit: <Loading />,
    },
    {
      key: 2,
      firstname: <Loading />,
      lastname: <Loading />,
      isLoanOfficer: <Loading />,
      officeName: <Loading />,
      status: <Loading />,
      edit: <Loading />,
    },
    {
      key: 3,
      firstname: <Loading />,
      lastname: <Loading />,
      isLoanOfficer: <Loading />,
      officeName: <Loading />,
      status: <Loading />,
      edit: <Loading />,
    },
    {
      key: 4,
      firstname: <Loading />,
      lastname: <Loading />,
      isLoanOfficer: <Loading />,
      officeName: <Loading />,
      status: <Loading />,
      edit: <Loading />,
    },
    {
      key: 5,
      firstname: <Loading />,
      lastname: <Loading />,
      isLoanOfficer: <Loading />,
      officeName: <Loading />,
      status: <Loading />,
      edit: <Loading />,
    },
  ];
  return (
    <>
      <Table
        columns={columns}
        dataSource={someChartsData}
        pagination={{ defaultPageSize: 8 }}
        className="antdTable"
        defaultPageSize={8}
      />
    </>
  );
};

export default TableLoader;
