import React from "react";
import { Table } from "react-bootstrap";
import { formatDate } from "../../../../../util/FormatDate";

const AccountDetails = ({ details }) => {
  let {
    transactionProcessingStrategyName,
    termPeriodFrequencyType = {},
    repaymentFrequencyType = {},
    repaymentFrequencyNthDayType = {},
    interestType = {},
    interestRatePerPeriod,
    interestRateFrequencyType = {},
    interestCalculationPeriodType = {},
    daysInYearType = {},
    daysInMonthType = {},
    repaymentFrequencyDayOfWeekType = {},
    amortizationType = {},
    repaymentEvery,
    numberOfRepayments,
    isEqualAmortization,
    annualInterestRate,
    allowPartialPeriodInterestCalcualtion,
    timeline = {},
    isInterestRecalculationEnabled,
  } = details || {};

  let {
    submittedOnDate = [],
    rejectedOnDate = [],
    expectedMaturityDate = [],
    approvedOnDate = [],
  } = timeline || {};
  return (
    <>
      <div className="m-2">
        <Table responsive striped bordered hover className="table-form-style">
          <thead className="border-0">
            <tr>
              <td className="font-weight-bold md-font-weight">
                Repayment Strategy
              </td>
              <td>
                {!!transactionProcessingStrategyName
                  ? transactionProcessingStrategyName
                  : ""}
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="font-weight-bold md-font-weight">Repayments</td>
              <td>
                {!!numberOfRepayments && !!repaymentEvery
                  ? `${!!numberOfRepayments ? numberOfRepayments : ""} Every ${
                      !!repaymentEvery ? repaymentEvery : ""
                    } ${
                      !!repaymentFrequencyType.value
                        ? repaymentFrequencyType.value
                        : ""
                    } On ${
                      !!repaymentFrequencyNthDayType.value
                        ? repaymentFrequencyNthDayType.value
                        : ""
                    } ${
                      !!repaymentFrequencyDayOfWeekType.value
                        ? repaymentFrequencyDayOfWeekType.value
                        : ""
                    }`
                  : ""}
              </td>
            </tr>
            <tr>
              <td className="font-weight-bold md-font-weight">Amortization</td>
              <td>{!!amortizationType.value ? amortizationType.value : ""}</td>
            </tr>
            <tr>
              <td className="font-weight-bold md-font-weight">
                Equal Amortization
              </td>
              <td>{`${isEqualAmortization}`}</td>
            </tr>
            <tr>
              <td className="font-weight-bold md-font-weight">Interest</td>
              <td>
                {!!annualInterestRate
                  ? `${annualInterestRate} Per Annum (${
                      !!interestRatePerPeriod ? interestRatePerPeriod : ""
                    }% ${
                      !!interestRateFrequencyType.value
                        ? interestRateFrequencyType.value
                        : ""
                    }) - ${!!interestType.value ? interestType.value : ""}`
                  : ""}
              </td>
            </tr>
            <tr>
              <td className="font-weight-bold md-font-weight">
                Grace: On Principal Payment
              </td>
              <td></td>
            </tr>{" "}
            <tr>
              <td className="font-weight-bold md-font-weight">
                Grace: On Interest Payment
              </td>
              <td></td>
            </tr>{" "}
            <tr>
              <td className="font-weight-bold md-font-weight">
                Grace On Arrears Ageing
              </td>
              <td></td>
            </tr>{" "}
            <tr>
              <td className="font-weight-bold md-font-weight">Fund Source</td>
              <td></td>
            </tr>
            <tr>
              <td className="font-weight-bold md-font-weight">
                Interest Free Period
              </td>
              <td></td>
            </tr>{" "}
            <tr>
              <td className="font-weight-bold md-font-weight">
                Interest Calculation Period
              </td>
              <td>
                {!!interestCalculationPeriodType.value
                  ? interestCalculationPeriodType.value
                  : ""}
              </td>
            </tr>
            <tr>
              <td className="font-weight-bold md-font-weight">
                Allow Partial Interest Calculation With Same As Repayment
              </td>
              <td>{`${allowPartialPeriodInterestCalcualtion}`}</td>
            </tr>
            <tr>
              <td className="font-weight-bold md-font-weight">Interest Type</td>
              <td>{!!interestType.value ? interestType.value : ""}</td>
            </tr>
            <tr>
              <td className="font-weight-bold md-font-weight">Submitted On</td>
              <td>
                {!!submittedOnDate.length ? formatDate(submittedOnDate) : ""}
              </td>
            </tr>
            <tr>
              <td className="font-weight-bold md-font-weight">Approved On</td>
              <td>
                {!!approvedOnDate.length ? formatDate(approvedOnDate) : ""}
              </td>
            </tr>
            <tr>
              <td className="font-weight-bold md-font-weight">Disbursed On</td>
              <td>
                {/* {!!approvedOnDate.length
                  ? `${approvedOnDate[2]}/${approvedOnDate[1]}/${approvedOnDate[0]}`
                  : ""} */}
              </td>
            </tr>
            <tr>
              <td className="font-weight-bold md-font-weight">Matures On</td>
              <td>
                {!!expectedMaturityDate.length ? formatDate(expectedMaturityDate) : ""}
              </td>
            </tr>
            {!!rejectedOnDate.length ? (
              <tr>
                <td className="font-weight-bold md-font-weight">Rejected On</td>
                <td>
                  {!!rejectedOnDate.length ? formatDate(rejectedOnDate) : ""}
                </td>
              </tr>
            ) : null}
            <tr>
              <td className="font-weight-bold md-font-weight">
                Recalculate Interest
              </td>
              <td>{isInterestRecalculationEnabled === true ? "YES" : "NO"}</td>
            </tr>
            <tr>
              <td className="font-weight-bold md-font-weight">Days In Year</td>
              <td>{!!daysInYearType.value ? daysInYearType.value : ""}</td>
            </tr>
            <tr>
              <td className="font-weight-bold md-font-weight">Days In Month</td>
              <td>{!!daysInMonthType.value ? daysInMonthType.value : ""}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default AccountDetails;
