import React from "react";
import { Input } from "@chakra-ui/react";
import { ActionButton, FormElements, FormClass } from "../styles";
import { Modal } from "react-bootstrap";

const ViewCampaign = ({
  onHide,
  campaignInfo = {
    campaignName: "Mark",
    templateMessage: "Peters",
    triggerType: "Triggered",
    approveBy: "Woodcore",
    status: "Active",
  },
}) => {
  const {
    campaignName,
    reportName,
    triggerType,
    status,
    templateMessage,
    approveBy,
  } = campaignInfo;

  return (
    <>
      <Modal.Header>
        <Modal.Title>{campaignName}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <FormElements style={{ width: "100%" }}>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Campaign Name</label>
              <Input
                style={{ width: "70%" }}
                size="md"
                name="campaignName"
                value={campaignName}
                disabled
              />
            </div>

            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Report Name</label>
              <Input
                style={{ width: "70%" }}
                size="md"
                name="reportName"
                value={reportName}
                disabled
              />
            </div>

            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Status</label>
              <Input
                style={{ width: "70%" }}
                size="md"
                name="status"
                value={status}
                disabled
              />
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Trigger Type</label>
              <Input
                style={{ width: "70%" }}
                size="md"
                name="triggerType"
                value={triggerType}
                disabled
              />
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Trigger Type</label>
              <Input
                style={{ width: "70%" }}
                size="md"
                name="triggerType"
                value={triggerType}
                disabled
              />
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Template Message</label>
              <Input
                style={{ width: "70%" }}
                size="md"
                name="templateMessage"
                value={templateMessage}
                disabled
              />
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Submitted on</label>
              <Input
                style={{ width: "70%" }}
                size="md"
                name="approveBy"
                value={approveBy}
                disabled
              />
            </div>
          </FormElements>
          <div className="float-right mt-4">
            <ActionButton color="#2C1DFF" onClick={onHide}>
              <img
                src={
                  require("../../../../../../assets/images/icons/cancel.svg")
                    .default
                }
                alt="cancel icon"
              />
              Close
            </ActionButton>
          </div>
        </FormClass>
      </Modal.Body>
    </>
  );
};

export default ViewCampaign;
