import React, { useState } from "react";
import { Input } from "@chakra-ui/react";
import { NewActionButton, FormElements, FormClass } from "../styles";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import {
  getAllUsers,
  resetUserPassword,
  updateUserPassword,
} from "../../../../../actions/configurationActions";
import Toast from "../../../../../util/Toast";

const ResetUserPassword = ({ onHide, id, username }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    const func = () => {
      dispatch(getAllUsers());
      setLoading(false);
      onHide();
    };
    await dispatch(resetUserPassword(id, func));
    setLoading(false);
  };

  return (
    <>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-column py-4">
        <Modal.Title>Password Reset</Modal.Title>
        <div className="row h-100 mt-4">
          <p style={{ maxWidth: "22.8rem", textAlign: "center" }}>
            Are you sure you want to Reset the password of <span className="font-weight-bolder w-100">{username}</span>
          </p>
        </div>
        <div className="mt-4">
          <NewActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require("../../../../../assets/images/icons/cancel.svg")
                  .default
              }
              alt="cancel icon"
            />
            No
          </NewActionButton>
          <NewActionButton
            onClick={onSubmit}
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
          >
            {loading ? (
              <Spinner
                animation="border"
                color="#fff"
                className="mr-2 text-white"
                size="sm"
              />
            ) : (
              <img
                src={
                  require("../../../../../assets/images/icons/save.svg")
                    .default
                }
                alt="submit icon"
              />
            )}
            Yes
          </NewActionButton>
        </div>
      </Modal.Body>
    </>
  );
};

export default ResetUserPassword;
