import React, { useRef, useState } from "react";
import { Table } from "antd";
import { InputGroup, InputLeftElement, Input } from "@chakra-ui/react";

import ModalContainer from "../../../components/ModalContainer";
import ActionModal from "./modals/ActionModal";

import CheckedWhiteSvg from "../../../../../assets/images/icons/checked-white.svg";
import { useSelector, useDispatch } from "react-redux";
import {
  clearFilteredClients,
  filterClients,
  processAllChecker,
} from "../../../../../actions/businessActions";
import Toast from "../../../../../util/Toast";

const ClientApproval = () => {
  const businessReducer = useSelector((state) => state.businessReducer);
  const dispatch = useDispatch();
  const search = useRef("");
  const { loading, filteredClients, checkerClients } = businessReducer;
  const [selectedRows, setSelectedRows] = useState([]);

  const onFilterClient = (e) => {
    search.current.value !== ""
      ? dispatch(filterClients(e.target.value))
      : dispatch(clearFilteredClients());
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Account Number",
      dataIndex: "accNumber",
    },
    {
      title: "Staff",
      dataIndex: "staff",
    },
  ];

  const dataSource = [];
  const filteredClientsData = [];
  if (filteredClients !== null) {
    filteredClients.forEach((client) => {
      const { id, displayName, accountNo, staffName } = client;
      filteredClientsData.push({
        key: id,
        name: displayName,
        accNumber: accountNo,
        staff: staffName,
      });
    });
  }

  !loading &&
    checkerClients.forEach((client) => {
      const { id, displayName, accountNo, staffName } = client;
      dataSource.push({
        key: id,
        name: displayName,
        accNumber: accountNo,
        staff: staffName,
      });
    });
  //  dataSource = [
  //   {
  //     key: 1,
  //     name: "Clement James",
  //     accNumber: "23652145621",
  //     staff: "James Brown",
  //   },
  //   {
  //     key: 2,
  //     name: "Samuel Victor",
  //     accNumber: "562359451223",
  //     staff: "Williams Mike",
  //   },
  //   {
  //     key: 3,
  //     name: "Dami Moyin",
  //     accNumber: "12236551259",
  //     staff: "Samuel Victor",
  //   },
  // ];

  // This is for the selected Row

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  // console.log("I gt here");

  const onFilterclient = () => {};

  const [modalState, toggleModal] = useState(false);
  const [modalContentName, setModalContentName] = useState("");

  const currentContent = (e) => {
    if (selectedRows.length) {
      const val = e.currentTarget.id.trim();
      if (val) {
        toggleModal(true);
        setModalContentName(val);
      }
    } else {
      dispatch(
        Toast({
          text: "No client selected",
          icon: "error",
          timer: 2000,
        })
      );
    }
  };

  const [activationDate, setActivationDate] = useState("");
  const handleModalClose = () => toggleModal(false);

  const handleApprove = () => {
    // console.log(selectedRows);
    let dataToSend =
      selectedRows.length &&
      selectedRows.map((data, i) => ({
        body: { activationDate, dateFormat: "dd MMMM yyyy", locale: "en" },
        method: "POST",
        relativeUrl: `clients/${data.id}?command=activate`,
        requestId: i + 1,
      }));
    // console.log({ dataToSend });
    if (activationDate) {
      dispatch(processAllChecker(dataToSend));
    } else {
      dispatch(
        Toast({
          text: "Activation Date is required",
          icon: "error",
          timer: 2000,
        })
      );
    }
  };

  const displayModalContent = () => {
    switch (modalContentName) {
      case "approve":
        return (
          <ActionModal
            header="Enter Client’s Activation date"
            onHide={handleModalClose}
            type="clientActivation"
            action={handleApprove}
            activationDate={activationDate}
            setActivationDate={setActivationDate}
          />
        );

      default:
        break;
    }
  };

  return (
    <>
      <ModalContainer show={modalState}>
        {modalState && displayModalContent()}
      </ModalContainer>
      <div className="border-left">
        <div className="row mb-4">
          <div className="col-md-4">
            <h2>Head Office</h2>
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-8">
                <form
                  style={{ marginTop: "-20px" }}
                  onSubmit={(e) => onFilterclient(e)}
                >
                  <InputGroup>
                    <InputLeftElement
                      width="3.1rem"
                      height="50px"
                      pointerEvents="none"
                    >
                      <img
                        src={
                          require("../../../../../assets/images/icons/search.svg")
                            .default
                        }
                        style={{ height: "20px" }}
                        alt="Icon"
                      />
                    </InputLeftElement>
                    <Input
                      style={{
                        background: "#ffffff",
                        height: "45px",
                      }}
                      type="text"
                      placeholder="Search by name"
                      ref={search}
                      onChange={onFilterClient}
                    />
                  </InputGroup>
                </form>
              </div>
              <div className="col-md-4">
                {" "}
                <div
                  onClick={currentContent}
                  id="approve"
                  className="approve-btn px-4"
                >
                  <img className="mr-2" src={CheckedWhiteSvg} alt="" />
                  Activate
                </div>
              </div>
            </div>
          </div>
        </div>
        <Table
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          columns={columns}
          dataSource={
            filteredClients !== null ? filteredClientsData : dataSource
          }
        />
      </div>
    </>
  );
};

export default ClientApproval;
