import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { Checkbox } from "antd";
import { Input, Select, FormControl } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import CurrencyFormat from "react-currency-format";
import {
  FormClass,
  FormElements,
  ActionButton,
} from "../../CustomerInformation/styles";
import {
  getLoanDisburseDetails,
  handleLoanTransactions,
} from "../../../../../actions/SavingsAndLoanActions";
import { formatDate } from "../../../../../util/FormatDate.js";
import { formatCurrency } from "../../../../../util/FormatAmount";

const RecoverRepaymentModal = ({ onHide, id, history }) => {
  const dispatch = useDispatch();
  const savingsAndLoan = useSelector((state) => state.savingsAndLoan);

  let { loanAccountDisburseDetails } = savingsAndLoan || {};

  let {
    amount,
    // currency = {},
    // date = [],
    paymentTypeOptions = [],
  } = loanAccountDisburseDetails || {};

  useEffect(() => {
    dispatch(getLoanDisburseDetails(id, "recoverypayment"));
  }, [dispatch, id]);

  const [transactionDate, setTransactionDate] = useState(new Date());

  const [enteredAmount, setAmount] = useState(amount || "");
  const [note, setNote] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [showPaymentDetails, setShowPaymentDetails] = useState(false);

  const [account, setAccount] = useState("");
  const [cheque, setCheque] = useState("");
  const [receipt, setReceipt] = useState("");
  const [routineCode, setRoutineCode] = useState("");
  const [bank, setBank] = useState("");

  let formatTransactionDate = "";
  if (transactionDate !== "") {
    formatTransactionDate = formatDate(transactionDate, true);
  }

  useEffect(() => {
    if (!!amount) {
      setAmount(amount);
    }
  }, [amount]);

  const handleLoanRepayment = async () => {
    let disbursementData = {
      actualDisbursementDate: formatTransactionDate,
      locale: "en",
      dateFormat: "dd MMM yyy",
      paymentTypeId: paymentType,
      transactionAmount: enteredAmount,
      note,
    };
    await dispatch(
      handleLoanTransactions(id, disbursementData, "recoverypayment", history)
    );

    onHide();
    // console.log()
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>Recovery Repayment</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <FormElements style={{ width: "100%" }}>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Transaction Date*</label>
              <FormControl style={{ width: "60%" }}>
                <DatePicker
                  id="published-date"
                  selected={transactionDate}
                  onChange={(date) => setTransactionDate(date)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="dd/mmm/yyyy"
                />
              </FormControl>
            </div>

            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Transaction Amount*</label>
              {/* <Input
                style={{ width: "60%" }}
                name="enteredAmount"
                value={formatCurrency(enteredAmount)}
                onChange={(e) => setAmount(e.target.value)}
                size="md"
              /> */}
              <CurrencyFormat
                value={enteredAmount}
                thousandSeparator={true}
                className="amount-input "
                style={{
                  width: "60%",
                }}
                // prefix={"$"}
                onValueChange={(values) => {
                  const { value } = values;
                  // formattedValue = $2,223
                  // value ie, 2223
                  setAmount(value);
                }}
              />
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Payment Type</label>
              <Select
                placeholder="Select Payment Type"
                size="md"
                name="paymentType"
                value={paymentType}
                onChange={(e) => setPaymentType(e.target.value)}
              >
                {!!paymentTypeOptions ? (
                  paymentTypeOptions.map(({ name, id }) => {
                    return (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    );
                  })
                ) : (
                  <option>Loading</option>
                )}
              </Select>
            </div>

            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Show Payment Details</label>
              <Checkbox
                onChange={(e) => setShowPaymentDetails(!showPaymentDetails)}
                value={showPaymentDetails}
              ></Checkbox>
            </div>

            {showPaymentDetails === true ? (
              <>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Account#</label>
                  <Input
                    style={{ width: "60%" }}
                    name="account"
                    value={account}
                    onChange={(e) => setAccount(e.target.value)}
                    size="md"
                  />
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Cheque#</label>
                  <Input
                    style={{ width: "60%" }}
                    name="cheque"
                    value={cheque}
                    onChange={(e) => setCheque(e.target.value)}
                    size="md"
                  />
                </div>{" "}
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Routing Code</label>
                  <Input
                    style={{ width: "60%" }}
                    name="routineCode"
                    value={routineCode}
                    onChange={(e) => setRoutineCode(e.target.value)}
                    size="md"
                  />
                </div>{" "}
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Receipt#</label>
                  <Input
                    style={{ width: "60%" }}
                    name="receipt"
                    value={receipt}
                    onChange={(e) => setReceipt(e.target.value)}
                    size="md"
                  />
                </div>{" "}
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Bank#</label>
                  <Input
                    style={{ width: "60%" }}
                    name="bank"
                    value={bank}
                    onChange={(e) => setBank(e.target.value)}
                    size="md"
                  />
                </div>
              </>
            ) : null}

            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Additional Note</label>
              <Input
                style={{ width: "60%" }}
                name="note"
                value={note}
                onChange={(e) => setNote(e.target.value)}
                size="md"
              />
            </div>
          </FormElements>
          <div className="float-right mt-4">
            <ActionButton color="#2C1DFF" onClick={onHide}>
              <img
                src={
                  require("../../../../../assets/images/icons/cancel.svg")
                    .default
                }
                alt="cancel icon"
              />
              Cancel
            </ActionButton>
            <ActionButton
              onClick={handleLoanRepayment}
              color="#2C1DFF"
              style={{ margin: "0px 0px 0 10px" }}
            >
              <img
                src={
                  require("../../../../../assets/images/icons/save.svg").default
                }
                alt="submit icon"
              />
              Submit
            </ActionButton>
          </div>
        </FormClass>
      </Modal.Body>
    </>
  );
};

export default RecoverRepaymentModal;
