import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getSingleInflowDetails } from "../../../../../actions/nibssConnectActions";
import { formatDateTimeNibss } from "../../../../../util/FormatDate";
import { TabContentWrapper, TabContentWrapperTrans } from "../../styles";

function InflowTransactionDetails({ match }) {
  const dispatch = useDispatch();
  const nibssInflowList = useSelector((state) => state.nibssInflowTemplate);

  const nibssSingleInflow = useSelector(
    (state) => state.nibssSingleInflowTemplate
  );
  const location = useLocation();

  const inflowState = location.state;

  // useEffect(() => {
  //   const { myKey } = match?.params?.id;
  // }, []);

  // const inflowData = inflowState.map(({}) => {}).filter()

  const inflowData = inflowState[0].filter(
    ({ id }) => id.toString() === match?.params?.id
  );

  const { nibssSingleInflowTemplate } = nibssSingleInflow;

  useEffect(() => {
    dispatch(getSingleInflowDetails(match.params.id));
  }, [match.params.id]);

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-4">
            <h3 className="page-title">Transaction report</h3>
            <p className="font-weight Nibss-sub-title">
              View transaction report <br />
              <span className="text-info">
                <Link to="/core/nibss-management">NIBSS Integration</Link>
              </span>{" "}
              <span className="text-info">
                <Link to="/core/nibss-management/transaction-report">
                  {" "}
                  / Transaction Report{" "}
                </Link>
              </span>{" "}
              /{" "}
              {!!inflowData[0] && !!inflowData[0]?.customerAccountName
                ? inflowData[0]?.customerAccountName
                : ""}
            </p>
          </div>
        </div>

        <TabContentWrapperTrans>
          <div className="col-sm-12 col-md-12 pb-4 ">
            <div
              className="row justify-content-between"
              style={{ padding: "10px" }}
            >
              {/* First Table */}
              <div className="col-sm-6 col-md-6 pb-4 tablePaddingNibss">
                <div className="m-5">
                  <Table
                    responsive
                    // striped
                    bordered
                    // hover
                    className="tableNibss"
                  >
                    <thead>
                      <tr>
                        <th className="tableHead td1">Sender account number</th>
                        <th className="tableHead td22 md-font-weight">
                          {!!inflowData[0] &&
                          !!inflowData[0]?.customerAccountNumber
                            ? inflowData[0]?.customerAccountNumber
                            : ""}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="td1 md-font-weight">
                          Sender account name
                        </td>
                        <td className="td2 md-font-weight">
                          {!!inflowData[0] &&
                          !!inflowData[0]?.customerAccountName
                            ? inflowData[0]?.customerAccountName
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <td className="td1 md-font-weight">Amount</td>
                        <td className="td2">
                          <span>NGN </span>
                          {!!inflowData[0] && inflowData[0]?.amount
                            ? inflowData[0]?.amount
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <td className="td1 md-font-weight">
                          {" "}
                          Sender Bank Name
                        </td>
                        <td
                          className="td2"
                          style={{ textTransform: "capitalize !important" }}
                        >
                          {!!inflowData[0] &&
                          !!inflowData[0]?.senderBankName === null
                            ? " "
                            : inflowData[0]?.senderBankName}
                        </td>
                      </tr>
                      <tr>
                        <td className="td1 md-font-weight">Fee amount</td>
                        <td className="td2">
                          <span>NGN </span>
                          {!!inflowData[0] && inflowData[0]?.feeAmount
                            ? inflowData[0]?.feeAmount
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <td className="td1 md-font-weight">
                          Receipient's Account number
                        </td>
                        <td className="td2">
                          {!!inflowData[0] &&
                          !!inflowData[0]?.beneficiaryAccountNumber
                            ? inflowData[0]?.beneficiaryAccountNumber
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <td className="td1 md-font-weight">
                          Receipient's Account name
                        </td>
                        <td className="td2">
                          {!!inflowData[0] &&
                          !!inflowData[0]?.beneficiaryAccountName
                            ? inflowData[0]?.beneficiaryAccountName
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <td className="td1 md-font-weight">
                          Receipient's Institution code
                        </td>
                        <td className="td2">
                          {!!inflowData[0] &&
                          !!inflowData[0]?.beneficiaryBankCode
                            ? inflowData[0]?.beneficiaryBankCode
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <td className="td1 md-font-weight">
                          Name enquiry reference
                        </td>
                        <td className="td2">
                          {!!inflowData[0] &&
                          !!inflowData[0]?.nameEnquiryReference
                            ? inflowData[0]?.nameEnquiryReference
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <td className="td1 md-font-weight">
                          Payment reference
                        </td>
                        <td className="td2">
                          {!!inflowData[0] && !!inflowData[0]?.paymentReference
                            ? inflowData[0]?.paymentReference
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <td className="td1 md-font-weight">
                          Fee charge transaction ID
                        </td>
                        <td className="td2">
                          {!!inflowData[0] &&
                          !!inflowData[0]?.feeChargeTransactionId === null
                            ? " "
                            : inflowData[0]?.feeChargeTransactionId}
                        </td>
                      </tr>
                      <tr>
                        <td className="td1 md-font-weight">
                          Fee charge reference ID
                        </td>
                        <td className="td2">
                          {!!inflowData[0] &&
                          !!inflowData[0]?.feeChargeReferenceId === null
                            ? " "
                            : inflowData[0]?.feeChargeReferenceId}
                        </td>
                      </tr>
                      <tr>
                        <td className="td1 md-font-weight">
                          CBA transaction ID
                        </td>
                        <td className="td2">
                          {!!inflowData[0] &&
                          !!inflowData[0]?.cbaTransactionId === null
                            ? " "
                            : inflowData[0]?.cbaTransactionId}
                        </td>
                      </tr>
                      <tr>
                        <td className="td1 md-font-weight">
                          CBA journal reference
                        </td>
                        <td className="td2">
                          {!!inflowData[0] &&
                          !!inflowData[0]?.cbaJournalRef === null
                            ? " "
                            : inflowData[0]?.cbaJournalRef}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
              {/* Second Table */}
              <div className="col-sm-6 col-md-6 pb-4 tablePaddingNibss">
                <div className="m-5 mt-3">
                  <Table
                    responsive
                    // striped
                    bordered
                    // hover
                    className=" tableNibss"
                  >
                    <thead>
                      <tr>
                        <th className="td1 tableHead">Session ID</th>
                        <th className="tableHead td22 md-font-weight">
                          {!!inflowData[0] && !!inflowData[0]?.sessionId
                            ? inflowData[0]?.sessionId
                            : ""}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="td1 md-font-weight">Remarks</td>
                        <td className="td2">
                          {!!inflowData[0] && !!inflowData[0]?.remarks
                            ? inflowData[0]?.remarks
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <td className="td1 md-font-weight">Narration</td>
                        <td className="td2" style={{ width: "80%" }}>
                          <textarea style={{ width: "100%", textAlign: "end" }}>
                            {!!inflowData[0] && !!inflowData[0]?.narration
                              ? inflowData[0]?.narration
                              : ""}
                          </textarea>
                        </td>
                      </tr>
                      <tr>
                        <td className="td1 md-font-weight">Response code</td>
                        <td className="td2">
                          {!!inflowData[0] && inflowData[0]?.responseCode
                            ? inflowData[0]?.responseCode
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <td className="td1 md-font-weight">
                          Transaction status
                        </td>
                        <td className="td2">
                          {!!inflowData[0] &&
                          inflowData[0]?.responseCode === "00" ? (
                            <>
                              <span className="new-text-success">Approved</span>
                            </>
                          ) : (
                            ""
                          )}
                          {!!inflowData[0] &&
                          inflowData[0]?.responseCode === "96" ? (
                            <>
                              <span className="new-text-danger">
                                System Malfunction
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                          {!!inflowData[0] &&
                          inflowData[0]?.responseCode === "57" ? (
                            <>
                              <span className="new-text-danger">
                                Not Permitted
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="td1 md-font-weight">Created at</td>
                        <td className="td2">
                          {!!inflowData[0] && !!inflowData[0]?.createdAt
                            ? formatDateTimeNibss(inflowData[0]?.createdAt)
                            : ""}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </TabContentWrapperTrans>
      </div>
    </>
  );
}

export default InflowTransactionDetails;

// {
//   "id": 28,
//   "customerAccountNumber": "1780159383",
//   "customerAccountName": "Ajao Niyi",
//   "amount": 100,
//   "feeAmount": null,
//   "beneficiaryAccountNumber": "1100066120",
//   "beneficiaryAccountName": "olawale adeogun",
//   "beneficiaryKycLevel": "1",
//   "nameEnquiryReference": "999999160226091314160226091314",
//   "senderBankCode": "999999",
//   "beneficiaryBankCode": "999319",
//   "remarks": "Transaction from me",
//   "paymentReference": "ref/2015/05/02/val 4",
//   "narration": "Transaction from me",
//   "sessionId": "1780159383",
//   "responseCode": "00",
//   "operationType": "CREDIT",
//   "cbaTransactionId": 0,
//   "cbaJournalRef": null,
//   "feeChargeTransactionId": null,
//   "feeChargeReferenceId": null,
//   "senderBankName": "NIBSS INWARD TEST",
//   "createdAt": "2023-01-12T11:30:41.026637"
// }
