import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import { ContentWrapper, FormTitle } from "../styles";
import { getLoanProduct } from "../../../../../../actions/configurationActions";
import { formatCurrency } from "../../../../../../util/FormatAmount";
import { formatDate } from "../../../../../../util/FormatDate";

const LoanProductDetails = ({ match }) => {
  const dispatch = useDispatch();

  const loanProductReducer = useSelector((state) => state.loanProductReducer);

  const { loanProduct } = loanProductReducer;
  const [charges, setCharges] = useState([]);
  const [overdueCharges, setOverdueCharges] = useState([]);

  useEffect(() => {
    const _overdueCharges = [];
    const _charges = [];
    loanProduct?.charges?.forEach((charge) => {
      if (charge.penalty) {
        _overdueCharges.push(charge);
      } else {
        _charges.push(charge);
      }
      setOverdueCharges(_overdueCharges);
      setCharges(_charges);
    });
  }, [loanProduct]);

  useEffect(() => {
    dispatch(getLoanProduct(match.params.id));
  }, [match.params.id]);

  const isBoolean = (variable) => {
    if (typeof variable === "boolean") {
      return true;
    }
    return false;
  };
  const accountType = loanProduct?.accountingRule?.id;
  const accountingSummary = [
    {
      label: 'Fund Source (Asset)',
      value: loanProduct?.accountingMappings?.fundSourceAccount?.name,
      show: accountType !== "1",
    },
    {
      label: 'Loan Portfolio (Asset)',
      value: loanProduct?.accountingMappings?.loanPortfolioAccount?.name,
      show: accountType !== "1",
    },
    {
      label: 'Interest Receivable (Asset)',
      value: loanProduct?.accountingMappings?.receivableInterestAccount?.name,
      show: accountType === "3" && accountType !== "1",
    },
    {
      label: 'Fees Receivable (Asset)',
      value: loanProduct?.accountingMappings?.receivableFeeAccount?.name,
      show: accountType === "3" && accountType !== "1",
    },
    {
      label: 'Penalties Receivable (Asset)',
      value: loanProduct?.accountingMappings?.receivablePenaltyAccount?.name,
      show: accountType === "3" && accountType !== "1",
    },
    {
      label: 'Transfer In Suspense (Asset)',
      value: loanProduct?.accountingMappings?.transfersInSuspenseAccount?.name,
      show: accountType !== "1",
    },
    {
      label: 'Income From Interest (Income)',
      value: loanProduct?.accountingMappings?.interestOnLoanAccount?.name,
      show: accountType !== "1",
    },
    {
      label: 'Income From Penalties (Income)',
      value: loanProduct?.accountingMappings?.incomeFromPenaltyAccount?.name,
      show: accountType !== "1",
    },
    {
      label: 'Income From Fees (Income)',
      value: loanProduct?.accountingMappings?.incomeFromFeeAccount?.name,
      show: accountType !== "1",
    },
    {
      label: 'Income From Recovery Repayments (Income)',
      value: loanProduct?.accountingMappings?.incomeFromRecoveryAccount?.name,
      show: accountType !== "1",
    },
    {
      label: 'Losses Written Off (Expense)',
      value: loanProduct?.accountingMappings?.writeOffAccount?.name,
      show: accountType !== "1",
    },
    {
      label: 'Over Payment Liability (Liability)',
      value: loanProduct?.accountingMappings?.overpaymentLiabilityAccount?.name,
      show: accountType !== "1",
    },
  ];

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Loan products</h3>
            <p className="font-weight sub-title mt-2">
              <span className="text-info mr-1">
                <Link to="/core/products">Products </Link>
              </span>
              /{" "}
              <span className="text-info mr-1">
                <Link to="/core/products/loan-products">Loan products </Link>
              </span>
              / {!!loanProduct && !!loanProduct.name ? loanProduct.name : ""}{" "}
              Details
            </p>
          </div>
        </div>
        <ContentWrapper>
          <div className="col-sm-12 col-md-12 d-flex py-5">
            <div className="container">
              <div>
                <FormTitle>
                  <p className="text-info">Details</p>
                  <div className="lineThrough"></div>
                </FormTitle>
                <div className="pt-4">
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      className="col-md-4"
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Description&nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!loanProduct && !!loanProduct.description
                        ? loanProduct.description
                        : ""}
                    </p>
                  </div>
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      className="col-md-4"
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Short name&nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!loanProduct && !!loanProduct.shortName
                        ? loanProduct.shortName
                        : ""}
                    </p>
                  </div>
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      className="col-md-4"
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Start Date&nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!loanProduct &&
                      !!loanProduct.startDate &&
                      !!loanProduct.startDate.length
                        ? formatDate(loanProduct.startDate)
                        : ""}
                    </p>
                  </div>
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      className="col-md-4"
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Closed Date&nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!loanProduct &&
                      !!loanProduct.closeDate &&
                      !!loanProduct.closeDate.length
                        ? formatDate(loanProduct.closeDate)
                        : ""}
                    </p>
                  </div>
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      className="col-md-4"
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Include In Customer Loan Counter&nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!loanProduct &&
                        isBoolean(loanProduct.includeInBorrowerCycle) &&
                        `${loanProduct.includeInBorrowerCycle}`}
                    </p>
                  </div>
                </div>
              </div>
              <div className="pt-1">
                <FormTitle>
                  <p className="text-info">Terms</p>
                  <div className="lineThrough"></div>
                </FormTitle>
                <div className="pt-2">
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      className="col-md-4"
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Currency&nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!loanProduct &&
                      !!loanProduct.currency &&
                      !!loanProduct.currency.displayLabel
                        ? loanProduct.currency.displayLabel
                        : ""}
                    </p>
                  </div>
                  {/* <div className="form-group d-flex  align-items-center text-label">
                    <h3 className="col-md-4"
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Decimal Places&nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!loanProduct &&
                      !!loanProduct.currency &&
                      !!loanProduct.currency.decimalPlaces
                        ? loanProduct.currency.decimalPlaces
                        : ""}
                    </p>
                  </div>
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3 className="col-md-4"
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Currency In Multiples Of&nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!loanProduct &&
                      !!loanProduct.currency &&
                      !!loanProduct.currency.inMultiplesOf
                        ? loanProduct.currency.inMultiplesOf
                        : ""}
                    </p>
                  </div>{" "} */}
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      className="col-md-4"
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Installment In Multiples Of &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {" "}
                      {!!loanProduct &&
                      !!loanProduct.installmentAmountInMultiplesOf
                        ? loanProduct.installmentAmountInMultiplesOf
                        : ""}
                    </p>
                  </div>
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      className="col-md-4"
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Terms Vary Based On Loan Cycle&nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!loanProduct &&
                        isBoolean(loanProduct.useBorrowerCycle) &&
                        `${loanProduct.useBorrowerCycle}`}
                    </p>
                  </div>
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      className="col-md-4"
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Principal&nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!loanProduct &&
                        loanProduct.principal &&
                        formatCurrency(loanProduct.principal)}{" "}
                      (Min:{" "}
                      {!!loanProduct &&
                        loanProduct.minPrincipal &&
                        formatCurrency(loanProduct.minPrincipal)}
                      , Max:{" "}
                      {!!loanProduct &&
                        loanProduct.maxPrincipal &&
                        formatCurrency(loanProduct.maxPrincipal)}{" "}
                      )
                    </p>
                  </div>
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      className="col-md-4"
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Number Of Repayments&nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!loanProduct && loanProduct.numberOfRepayments} (Min:{" "}
                      {!!loanProduct && loanProduct.minNumberOfRepayments}, Max:{" "}
                      {!!loanProduct && loanProduct.maxNumberOfRepayments} )
                    </p>
                  </div>
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      className="col-md-4"
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Repay Every&nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!loanProduct && loanProduct.repaymentEvery}{" "}
                      {!!loanProduct &&
                        !!loanProduct.repaymentFrequencyType &&
                        loanProduct.repaymentFrequencyType.value}
                    </p>
                  </div>
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      className="col-md-4"
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Nominal Interest Rate&nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!loanProduct && loanProduct.interestRatePerPeriod} (Min:{" "}
                      {!!loanProduct && loanProduct.minInterestRatePerPeriod},
                      Max:{" "}
                      {!!loanProduct && loanProduct.maxInterestRatePerPeriod} ){" "}
                      {!!loanProduct &&
                        !!loanProduct.interestRateFrequencyType &&
                        !!loanProduct.interestRateFrequencyType.value &&
                        loanProduct.interestRateFrequencyType.value}
                    </p>
                  </div>
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      className="col-md-4"
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Minimum Days Between Disbursal And First Repayment Date
                      &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!loanProduct &&
                        !!loanProduct.minimumDaysBetweenDisbursalAndFirstRepayment &&
                        loanProduct.minimumDaysBetweenDisbursalAndFirstRepayment}
                    </p>
                  </div>
                </div>
              </div>
              <div className="pt-1">
                <FormTitle>
                  <p className="text-info">Settings</p>
                  <div className="lineThrough"></div>
                </FormTitle>
                <div className="pt-2">
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      className="col-md-4"
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Amortization &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!loanProduct &&
                        !!loanProduct.amortizationType &&
                        loanProduct.amortizationType.value}
                    </p>
                  </div>
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      className="col-md-4"
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Interest Method &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!loanProduct &&
                        loanProduct.interestType &&
                        loanProduct.interestType.value}{" "}
                    </p>
                  </div>
                  <div className="form-group d-flex align-items-center text-label">
                    <h3
                      className="col-md-4"
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Equal Amortization &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!loanProduct &&
                      isBoolean(loanProduct.isEqualAmortization)
                        ? `${loanProduct.isEqualAmortization}`
                        : ""}
                    </p>
                  </div>
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      className="col-md-4"
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Interest Calculation Period &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!loanProduct &&
                        !!loanProduct.interestCalculationPeriodType &&
                        loanProduct.interestCalculationPeriodType.value}
                    </p>
                  </div>
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      className="col-md-4"
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Allow Partial Interest Calculation With Same As Repayment{" "}
                      &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!loanProduct &&
                        isBoolean(
                          loanProduct.allowPartialPeriodInterestCalcualtion
                        ) &&
                        `${loanProduct.allowPartialPeriodInterestCalcualtion}`}
                    </p>
                  </div>
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      className="col-md-4"
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Arrears Tolerance &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!loanProduct &&
                        loanProduct.inArrearsTolerance &&
                        formatCurrency(loanProduct.inArrearsTolerance)}
                    </p>
                  </div>
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      className="col-md-4"
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Repayment Strategy&nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!loanProduct &&
                        !!loanProduct.transactionProcessingStrategyName &&
                        loanProduct.transactionProcessingStrategyName}
                    </p>
                  </div>
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      className="col-md-4"
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Grace&nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!loanProduct &&
                        isBoolean(loanProduct.graceOnPrincipalPayment) &&
                        `${loanProduct.graceOnPrincipalPayment}`}
                    </p>
                  </div>
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      className="col-md-4"
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Interest Free Period&nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {/* {!!loanProduct
                        ? `${loanProduct.isDormancyTrackingActive}`
                        : ""} */}
                    </p>
                  </div>
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      className="col-md-4"
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Grace On Arrears Ageing &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!loanProduct &&
                      isBoolean(loanProduct.graceOnArrearsAgeing)
                        ? `${loanProduct.graceOnArrearsAgeing}`
                        : ""}
                    </p>
                  </div>
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      className="col-md-4"
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Overdue Days For NPA &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!loanProduct && isBoolean(loanProduct.overdueDaysForNPA)
                        ? `${loanProduct.overdueDaysForNPA}`
                        : ""}
                    </p>
                  </div>{" "}
                  <div className="form-group d-flex align-items-center text-label">
                    <h3
                      className="col-md-4"
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Account Moves Out Of NPA Only After All Arrears is
                      Cleared? &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!loanProduct &&
                      isBoolean(
                        loanProduct.accountMovesOutOfNPAOnlyOnArrearsCompletion
                      )
                        ? `${loanProduct.accountMovesOutOfNPAOnlyOnArrearsCompletion}`
                        : ""}
                    </p>
                  </div>{" "}
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      className="col-md-4"
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Days In Year &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!loanProduct &&
                        !!loanProduct.daysInYearType &&
                        loanProduct.daysInYearType.value}
                    </p>
                  </div>{" "}
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      className="col-md-4"
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Days In Month &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!loanProduct &&
                        !!loanProduct.daysInMonthType &&
                        loanProduct.daysInMonthType.value}
                    </p>
                  </div>
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      className="col-md-4"
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Principal Threshold (%) For Last Instalment
                      &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!loanProduct &&
                        loanProduct.principalThresholdForLastInstallment}
                    </p>
                  </div>
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      className="col-md-4"
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Allow Fixing Of The Installment Amount &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!loanProduct &&
                        isBoolean(loanProduct.allowVariableInstallments) &&
                        `${loanProduct.allowVariableInstallments}`}
                    </p>
                  </div>
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      className="col-md-4"
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Is Allowed To Be Used For Providing Topup Loans?
                      &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!loanProduct &&
                        isBoolean(loanProduct.canUseForTopup) &&
                        `${loanProduct.canUseForTopup}`}
                    </p>
                  </div>
                </div>
              </div>
              <div className="pt-1">
                <FormTitle>
                  <p className="text-info">Interest Recalculation</p>
                  <div className="lineThrough"></div>
                </FormTitle>
                <div className="pt-2">
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      className="col-md-4"
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Recalculate Interest&nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!loanProduct &&
                        isBoolean(loanProduct.isInterestRecalculationEnabled) &&
                        `${loanProduct.isInterestRecalculationEnabled}`}
                    </p>
                  </div>
                </div>
              </div>
              <div className="pt-1">
                <FormTitle>
                  <p className="text-info">Guarantee Requirements</p>
                  <div className="lineThrough"></div>
                </FormTitle>
                <div className="pt-2">
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      className="col-md-4"
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Place Guarantee Funds On-Hold?&nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!loanProduct &&
                        isBoolean(loanProduct.holdGuaranteeFunds) &&
                        `${loanProduct.holdGuaranteeFunds}`}
                    </p>
                  </div>
                </div>
              </div>{" "}
              <div className="pt-1">
                <FormTitle>
                  <p className="text-info">Loan Tranche Details</p>
                  <div className="lineThrough"></div>
                </FormTitle>
                <div className="pt-2">
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      className="col-md-4"
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Enable Multiple Disbursals&nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!loanProduct &&
                        isBoolean(loanProduct.multiDisburseLoan) &&
                        `${loanProduct.multiDisburseLoan}`}
                    </p>
                  </div>
                </div>
              </div>
              <div className="pt-1">
                <FormTitle>
                  <p className="text-info">Charges</p>
                  <div className="lineThrough"></div>
                </FormTitle>
                <div className="pt-2">
                  <Table className="child-table">
                    <thead className="">
                      <tr style={{ backgroundColor: "#FAFAFA" }}>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Amount</th>
                        <th>Collected on</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!!loanProduct && charges && charges.length > 0
                        ? charges.map(
                            ({
                              id,
                              name,
                              chargeCalculationType,
                              amount,
                              chargeTimeType,
                            }) => (
                              <tr key={id}>
                                <td>{name}</td>
                                <td>
                                  {chargeCalculationType &&
                                    chargeCalculationType.value}
                                </td>
                                <td>{formatCurrency(Number(amount))}</td>
                                <td>
                                  {chargeTimeType && chargeTimeType.value}
                                </td>
                              </tr>
                            )
                          )
                        : null}
                    </tbody>
                  </Table>
                </div>
              </div>
              <div className="pt-1">
                <FormTitle>
                  <p className="text-info">Overdue Charges</p>
                  <div className="lineThrough"></div>
                </FormTitle>
                <div className="pt-2">
                  <Table className="child-table">
                    <thead className="">
                      <tr style={{ backgroundColor: "#FAFAFA" }}>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Amount</th>
                        <th>Collected on</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!!loanProduct &&
                      overdueCharges &&
                      overdueCharges.length > 0
                        ? overdueCharges.map(
                            ({
                              id,
                              name,
                              chargeCalculationType,
                              amount,
                              chargeTimeType,
                            }) => (
                              <tr key={id}>
                                <td>{name}</td>
                                <td>
                                  {chargeCalculationType &&
                                    chargeCalculationType.value}
                                </td>
                                <td>{formatCurrency(Number(amount))}</td>
                                <td>
                                  {chargeTimeType && chargeTimeType.value}
                                </td>
                              </tr>
                            )
                          )
                        : null}
                    </tbody>
                  </Table>
                </div>
              </div>
              <div className="pt-1">
                <FormTitle>
                  <p className="text-info">Accounting</p>
                  <div className="lineThrough"></div>
                </FormTitle>
                <div className="pt-2">
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      className="col-md-4"
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Account type&nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {!!loanProduct &&
                        loanProduct.accountingRule &&
                        loanProduct.accountingRule.value}
                    </p>
                  </div>
                  {accountingSummary?.filter(gl => gl?.value && gl?.show)?.map((gl, index) => (
                    <div key={index} className="form-group d-flex align-items-center text-label">
                      <h3 style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }} className="col-md-4">
                        {gl?.label}&nbsp;&nbsp;&nbsp;:
                      </h3>
                      <p style={{ width: "60%", fontWeight: "bold" }}>{gl?.value}</p>
                    </div>
                  ))}
                </div>
              </div>
              {!!loanProduct &&
              loanProduct.accountingRule &&
              loanProduct.accountingRule.id === 2 ? (
                <div className="pt-1">
                  <FormTitle>
                    <p className="text-info">Advanced Accounting Rule</p>
                    <div className="lineThrough"></div>
                  </FormTitle>

                  <div className="pt-2">
                    <h3
                      style={{ fontSize: 16 }}
                      className="font-weight-bold mb-2"
                    >
                      Configure Fund Sources For Payment Channels
                      &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <Table className="child-table">
                      <thead className="">
                        <tr style={{ backgroundColor: "#FAFAFA" }}>
                          <th>Payment Type</th>
                          <th>Fund Source</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!!loanProduct &&
                        !!loanProduct.paymentChannelToFundSourceMappings &&
                        loanProduct.paymentChannelToFundSourceMappings.length >
                          0
                          ? loanProduct.paymentChannelToFundSourceMappings.map(
                              ({ paymentType, fundSourceAccount }, index) => (
                                <tr key={index}>
                                  <td>{paymentType.name}</td>
                                  <td>{fundSourceAccount.name}</td>
                                </tr>
                              )
                            )
                          : null}
                      </tbody>
                    </Table>
                  </div>
                  <div className="pt-4">
                    <h3
                      style={{ fontSize: 16 }}
                      className="font-weight-bold mb-2"
                    >
                      Fees To Specific Income Account &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <Table className="child-table">
                      <thead className="">
                        <tr style={{ backgroundColor: "#FAFAFA" }}>
                          <th>Fees</th>
                          <th>Income Account</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {charges.length > 0
                      ? charges.map(({ id, name, chargeCalculationType }) => (
                          <tr key={id}>
                            <td>{name}</td>
                            <td>{chargeCalculationType}</td>
                          </tr>
                        ))
                      : null} */}
                      </tbody>
                    </Table>
                  </div>
                  <div className="pt-4">
                    <h3
                      style={{ fontSize: 16 }}
                      className="font-weight-bold mb-2"
                    >
                      Penalties To Specific Income Account &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <Table className="child-table">
                      <thead className="">
                        <tr style={{ backgroundColor: "#FAFAFA" }}>
                          <th>Penalty</th>
                          <th>Income Account</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!!loanProduct &&
                        !!loanProduct.penaltyToIncomeAccountMappings &&
                        loanProduct.penaltyToIncomeAccountMappings.length > 0
                          ? loanProduct.penaltyToIncomeAccountMappings.map(
                              ({ charge, incomeAccount }, index) => (
                                <tr key={index}>
                                  <td>{charge.name}</td>
                                  <td>{incomeAccount.name}</td>
                                </tr>
                              )
                            )
                          : null}
                      </tbody>
                    </Table>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </ContentWrapper>
      </div>
    </>
  );
};

export default LoanProductDetails;
