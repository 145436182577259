import styled from "styled-components";

export const ActionButtonsGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  button {
    background: #ffffff;

    font-size: 14px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    border-radius: 3px;
    border: none;
    padding: 0 16px;
    margin-right: 9px;
    // flex: auto;
    transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out;
    margin-bottom: 1rem;
    margin-top: 1rem;

    &:hover {
      box-shadow: 0px 2px 20px rgba(0, 91, 252, 0.24);
      color: #2c1dff;
    }
  }
  .active-filter {
    border: 2px solid #2c1dff !important;
  }
`;
export const ActionButton = styled.button`
  background: #2c1dff !important;
  border-radius: 5px !important;
  color: #fff !important;
  border: none;
  display: inline;
  font-family: "AventaMedium";
  font-size: 14px;
  height: 35px;
  padding: 0 8px;
  min-width: 130px;
  margin: 0 10px;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  img {
    height: 14px;
    display: inline;
    margin-right: 10px;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;

export const EditButton = styled.button`
  background: #2c1dff !important;
  border-radius: 5px !important;
  color: #fff !important;
  border: none;
  display: inline;
  font-size: 14px;
  height: 30px;
  width: 78px;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
  }
`;

export const Heading = styled.h3`
  font-weight: 700;
  font-size: 32px;
  margin-right: 10px;
  margin-bottom: 10px;
  text-transform: capitalize;
`;
export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const OfficeTag = styled.p`
  background-color: #01b1fc1e;
  color: #2c1dff;
  font-size: 10px;
  font-weight: bold;
  margin-left: 13px;
  text-align: center;
  vertical-align: middle;
  border-radius: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 4px 8px;
`;

export const ChartCard = styled.div`
  height: 400px;
  margin-top: 50px;
  .heading {
    padding: 20px 25px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eef0f7;
    font-size: 13px;
    .title {
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0.01em;
    }
    p {
      margin: 0;
    }
  }
`;

export const StatContainer = styled.div`
  display: flex;
  background-color: #fff;
  max-height: min-content;
  padding-top: 25px;
  padding-right: 15px;
  padding-bottom: 50px;

  @media only screen and (max-width: 992px) {
    flex-direction: column;
  }
`;
export const StatTransactionContainer = styled.div`
  background-color: #fff;
  max-height: min-content;
  padding: 25px 150px 50px 150px;

  @media only screen and (max-width: 1280px) {
    padding: 25px 15px 50px 15px;
  }
`;

// here

export const OTPTitle = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 29px;
  color: #141414;
  text-align: center;
  padding-bottom: 1rem;
`;

export const OTPSubTitile = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 0.813rem;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.035em;
  color: #666666;
  margin-bottom: 25px;
`;

export const OTPDigitsFormContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0 20px;
  margin-bottom: 1.875rem;
  input {
    background: #ffffff;
    width: 50px;
    height: 50px;
    border: 1px solid rgba(150, 156, 186, 0.2);
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 3px;
    &:focus {
      box-shadow: none !important;
    }
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

export const OTPResendTitle = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height, or 154% */
  text-align: center;
  letter-spacing: 0.035em;
  color: #666666;
  margin-bottom: 40px;
  a {
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.035em;
    color: #2c1dff;
    text-decoration: none;
  }
`;
export const DownloadButtonsGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  // &:hover {
  //   box-shadow: 0px 2px 20px rgba(0, 91, 252, 0.24);
  //   color: #2c1dff;
  // }

  // .active-filter {
  //   border: 2px solid #2c1dff !important;
  // }
`;
