import React, { useState } from "react";
import { Input, Textarea } from "@chakra-ui/react";
import { NewActionButton, FormElements, FormClass } from "../styles";
import { Modal, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  createNewRole,
  getAllRoles,
} from "../../../../../actions/configurationActions";
import Toast from "../../../../../util/Toast";

const NewHook = ({ onHide, template }) => {
  const dispatch = useDispatch();
  const [newHook, setNewHookInfo] = useState({
    hookTemplate: "",
    name: "",
    description: "",
  });
  const [loading, setLoading] = useState(false);
  const { name, description } = newHook;

  const createHookHandler = (e) =>
    setNewHookInfo({
      ...newHook,
      [e.target.name]: e.target.value,
    });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (name && description) {
      setLoading(true);
      const data = {
        name,
        description,
      };
      const func = () => {
        dispatch(getAllRoles());
        onHide();
      };
      await dispatch(createNewRole(data, func));
      setLoading(false);
    } else {
      dispatch(
        Toast({
          text: "please fill the required fields",
          icon: "error",
          timer: 2000,
        })
      );
    }
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>Add Role</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <FormElements style={{ width: "100%" }}>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Name*</label>
              <Input
                style={{ width: "60%" }}
                size="md"
                name="name"
                value={name}
                onChange={createHookHandler}
              />
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label d-flex flex-align-center">
                Description*
              </label>
              <Textarea
                name="description"
                value={description}
                onChange={createHookHandler}
                style={{ width: "60%", fontSize: "12px" }}
                size="md"
              />
            </div>
          </FormElements>
         
        </FormClass>
      </Modal.Body>
      <Modal.Footer>
      <div className="float-right mt-1">
            <NewActionButton name="description" color="#2C1DFF" onClick={onHide}>
              <img
                src={
                  require("../../../../../assets/images/icons/cancel.svg")
                    .default
                }
                alt="cancel icon"
              />
              Cancel
            </NewActionButton>
            <NewActionButton
              onClick={handleSubmit}
              color="#2C1DFF"
              style={{ margin: "0px 0px 0 10px" }}
            >
              {loading ? (
                <Spinner
                  animation="border"
                  color="#fff"
                  className="mr-2 text-white"
                  size="sm"
                />
              ) : (
                <img
                  src={
                    require("../../../../../assets/images/icons/save.svg")
                      .default
                  }
                  alt="submit icon"
                />
              )}
              Submit
            </NewActionButton>
          </div>
      </Modal.Footer>
    </>
  );
};

export default NewHook;
