import React from "react";
import ReportView from "./index";

const data = {
  name: "Financial Reports",
  overview:
    "A complete overview of your business with analysis and data metrics",
  filter: "Accounting",
};
const AccountingReport = () => {
  return <ReportView repData={data} />;
};

export default AccountingReport;
