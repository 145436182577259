import axiosData from "../axiosData";
import config from "../../config";

/*
 * Get all Reports
 */
export const getAllReports = (reportCategory) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.REPORT_API_URL}/reports?&reportType=Table`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Get all Reports Form Parameter
 */
export const getAllReportParamenter = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.REPORT_API_URL}/reports/${id}`,
        // params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// test
export const getReportFullParamenterList = (reportListing) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/runreports/FullParameterList?R_reportListing='${reportListing}'&parameterType=true`,
        // params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Get all Reports Form Parameter
 */
export const generateAReport = (id, params) => {
  return new Promise(async (resolve, reject) => {
    try {
      // console.log(id);
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.REPORT_API_URL}/runreports/table/${id}?${params}`,
        // params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};


/*
 * Get teller report Form Parameter
 */
export const generateTellerReport = (tellerId, dropdownValue) => {
  return new Promise(async (resolve, reject) => {
    try {
      // console.log(id);
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.REPORT_API_URL}/runreports/teller-report/${tellerId}?currencyCode=${dropdownValue}`,
        // params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Get all Offices
 */
export const getOffices = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/runreports/OfficeIdSelectOne?parameterType=true`,
        // params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
/*
 * Get all Staff Selected
 */
export const getStaffSelects = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/runreports/staffSelect?parameterType=true`,
        // params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
/*
* Get all Staff Branch Selected
*/
export const getStaffSelectBranchs = (officeId) => {
// export const getStaffSelectBranchs = (id) => {
 return new Promise(async (resolve, reject) => {
   try {
     const { data, error } = await axiosData({
       method: "GET",
      //  url: `${config.apiGateway.BASE_API_URL}/runreports/staffSelectBranch?parameterType=true&R_officeId=${id}`,
       url: `${config.apiGateway.BASE_API_URL}/runreports/staffSelectBranch?parameterType=true&R_officeId=${officeId}`,
       // params,
     });
     if (data) {
       resolve(data);
     } else {
       reject(error);
     }
   } catch (ex) {
     reject(ex);
   }
 });
};


/*
 * Get all Client Selected
 */
export const getClientSelects = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/runreports/clientSelect?parameterType=true`,
        // params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
/*
 * Get Account Officer Selected
 */
export const getAccountOfficerSelects = (clientId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // url: `${config.apiGateway.BASE_API_URL}runreports/accountOfficerSelect?R_clientId=${id}&parameterType=true`,
        url: `${config.apiGateway.BASE_API_URL}/runreports/accountOfficerSelect?parameterType=true&R_clientId=${clientId}`,
        // params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Get all Staff In Office
 */
export const getStaffInOffice = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/runreports/loanOfficerIdSelectAll?R_officeId=${id}&parameterType=true`,
        // params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Get all Currency
 */
export const getCurrency = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/runreports/currencyIdSelectAll?parameterType=true`,
        // params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Get all Loan Products
 */
export const getALoanProduct = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/runreports/loanProductIdSelectAll?R_currencyId=${id}&parameterType=true`,
        // params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Get all Savings Products
 */
export const getASavingsProduct = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/runreports/savingsProductIdSelectAll?R_currencyId=${id}&parameterType=true`,
        // params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Get all Loan Fund
 */
export const getALoanFund = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/runreports/fundIdSelectAll?parameterType=true`,
        // params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Get all Loan Fund
 */
export const getAllPaymentTypes = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/runreports/SelectPaymentType?parameterType=true`,
        // params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Get all Accounts
 */
export const getAllAccount = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/runreports/SelectGLAccountNO?parameterType=true`,
        // params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Get all Accounts
 */
export const getAllObligations = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/runreports/obligDateTypeSelect?parameterType=true`,
        // params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Get all Loan Purpose
 */
export const getALoanPurpose = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/runreports/loanPurposeIdSelectAll?parameterType=true`,
        // params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Get all Par Select
 */
export const getALoanParSelect = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/runreports/parTypeSelect?parameterType=true`,
        // params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Get a report status
 */
export const getAReportStatus = (code) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.REPORT_API_URL}/runreports/table/status/${code}`,
        // params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
