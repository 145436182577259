import React, { useRef, useEffect } from "react";
import {
  ContentWrapper,
  ActionButtonsGroup,
  ActionButton,
  EditButton,
} from "./styles";

import { InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import { Table } from "antd";
import { Link } from "react-router-dom";
// import ModalContainer from "../../../components/ModalContainer";

import { useDispatch, useSelector } from "react-redux";
import {
  getFixedDeposits,
  getAllFixedDepositsProducts,
  filterFixedDepositProduct,
  clearFilteredFDProducts,
  resetFDProduct,
} from "../../../../../actions/fixedDeposit";
import CheckPermission from "../../../../../util/CheckPermission";
import Toast from "../../../../../util/Toast";
import TableLoader from "./TableLoader";


const FixedDepositProductsView = ({ history }) => {
  const dispatch = useDispatch();

  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  const fixedDepositData = useSelector((state) => state.fixedDepositData);

  const fdProductList = useSelector((state) => state.fdProductsReducer);

  // let { fixedDeposits = [] } = fixedDepositData || {};

  const { loading, filtered , fixedDepositProducts} = fdProductList;

  const handleTaxStatus = (withHoldTax) => {
    if (withHoldTax) {
      return <span className="new-text-success">Applied</span>;
    } else {
      return <span className="new-text-danger">Not Applied</span>;
    }
  };

  const search = useRef("");

  const onFilterFdProducts = (e) => {
    search.current.value !== ""
      ? dispatch(filterFixedDepositProduct(e.target.value))
      : dispatch(clearFilteredFDProducts());
  };
  if (filtered !== null && filtered.length === 0) {
    Toast({
      text: "No Fixed Product product found",
      icon: "error",
    });
  }

  useEffect(() => {
    dispatch(clearFilteredFDProducts());
    dispatch(resetFDProduct());
    // dispatch(getFixedDeposits());
    dispatch(getAllFixedDepositsProducts());
    if (filtered === null) {
      search.current.value = "";
    }
  }, []);

  // useEffect(() => {
  //   dispatch(getFixedDeposits());
  // }, [dispatch]);

  //Table configuration
  const dataSource = [];
  const filteredFDProducts = [];

  if (filtered !== null) {
    filtered.forEach((fixedaccount) => {
      const {
        id,
        name,
        shortName,
        description,
        currency,
        minDepositAmount,
        interestCalculationDaysInYearType,
        withHoldTax,
      } = fixedaccount;
      filteredFDProducts.push({
        key: id,
        name: name,
        description: description,
        currency: currency.code,
        minDepositAmount: minDepositAmount,
        interestCalculationDaysInYearType:
          interestCalculationDaysInYearType.value,
        withHoldTax: handleTaxStatus(withHoldTax),
        shortname: shortName,
        edit: (
          <>
            <EditButton
              onClick={(e) => {
                e.stopPropagation();
                history.push(
                  `/core/products/fixed-deposit-products/edit-fixed-deposit-product/${fixedaccount?.id}`
                );
              }}
            >
              Edit
            </EditButton>
          </>
        ),
      });
    });
  }

  !loading &&
  fixedDepositProducts?.forEach((fixedaccount) => {
    let {
      id,
      name,
      shortName,
      description,
      currency,
      minDepositAmount,
      interestCalculationDaysInYearType,
      withHoldTax,
    } = fixedaccount;

    dataSource.push({
      key: id,
      name: name,
      description: description,
      currency: currency.code,
      minDepositAmount: minDepositAmount,
      interestCalculationDaysInYearType:
        interestCalculationDaysInYearType.value,
      withHoldTax: handleTaxStatus(withHoldTax),
      shortname: shortName,
      edit: (
        <>
          <EditButton
            onClick={(e) => {
              e.stopPropagation();
              history.push(
                `/core/products/fixed-deposit-products/edit-fixed-deposit-product/${fixedaccount?.id}`
              );
            }}
          >
            Edit
          </EditButton>
        </>
      ),
    });
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 150,
      ellipsis: true,
    },
    { title: "Short name", dataIndex: "shortname", key: "shortname" },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 150,
      ellipsis: true,
    },
    { title: "Currency", dataIndex: "currency", key: "currency" },
    {
      title: "Min Deposit Amount",
      dataIndex: "minDepositAmount",
      key: "minDepositAmount",
    },
    {
      title: "Days in a Year",
      dataIndex: "interestCalculationDaysInYearType",
      key: "interestCalculationDaysInYearType",
    },
    { title: "With Hold Tax", dataIndex: "withHoldTax", key: "withHoldTax" },
    { title: "", dataIndex: "edit", key: "edit" },
  ];

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Fixed deposit products</h3>
            <p className="font-weight sub-title">
              {/* Add, modify or inactivate Fixed deposit products */}
            </p>
            <p className="font-weight sub-title">
              <span className="text-info">
                {/* <Link to="/core/configuration">Configuration</Link> /{" "} */}
                <Link to="/core/products">Products</Link>
              </span>{" "}
              / Fixed deposit products
            </p>
          </div>
          <div className="col-sm-12 col-md-6 px-0 pt-4">
            <ActionButtonsGroup>
              <div className="col-sm-12 col-md-6 pr-2">
                <InputGroup>
                  <InputLeftElement
                    width="3.1rem"
                    height="38px"
                    pointerEvents="none"
                  >
                    <img
                      src={
                        require("../../../../../assets/images/icons/search.svg")
                          .default
                      }
                      style={{ height: "30px" }}
                      alt="Icon"
                      className="icon-img"
                    />
                  </InputLeftElement>
                  <Input
                    style={{
                      background: "#ffffff",
                      height: "35px !important",
                    }}
                    type="text"
                    className="general-font-size"
                    placeholder="Filter by Name/Short name"
                    ref={search}
                    onChange={onFilterFdProducts}
                  />
                </InputGroup>
              </div>
              <div className="col-sm-12 col-md-6 p-0 d-flex">
                {CheckPermission("CREATE_FIXEDDEPOSITPRODUCT", permissions) && (
                  <Link to="fixed-deposit-products/create-fixed-deposit-product">
                    <ActionButton>
                      <img
                        src={
                          require("../../../../../assets/images/icons/add.svg")
                            .default
                        }
                        alt="add"
                      />
                      Create
                    </ActionButton>
                  </Link>
                )}
              </div>
            </ActionButtonsGroup>
          </div>
        </div>
        <ContentWrapper>
          {loading ? (
            <TableLoader />
          ) : (
            CheckPermission("READ_FIXEDDEPOSITPRODUCT", permissions) && (
          <>
              {filtered !== null ? (
                <Table
                  columns={columns}
                  dataSource={filteredFDProducts}
                  onRow={(r) => ({
                    onClick: () =>
                    history.push(
                      `/core/products/fixed-deposit-products/details/${r?.key}`
                    ),
                  })}
                />
            ) : (
              <Table
                columns={columns}
                dataSource={dataSource}
                pagination={{ defaultPageSize: 13 }}
                defaultPageSize={13}
                onRow={(r) => ({
                  onClick: () =>
                    history.push(
                      `/core/products/fixed-deposit-products/details/${r?.key}`
                    ),
                })}
              />
            )}
          </>
            )
          )}
        </ContentWrapper>
      </div>
    </>
  );
};

export default FixedDepositProductsView;
