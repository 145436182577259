import styled from "styled-components";

export const ContentWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 0px #ededf6;
  min-height: 70vh;
  margin-top: 20px;
  padding: 50px;

  .button-right {
    width: 100%;
    height: 50px;
    padding-right: 15px;
  }
`;

export const FormClass = styled.div``;

export const ActionButton = styled.button`
  background: ${(props) => props.color || "#2C1DFF"} !important;
  border-radius: 5px !important;
  color: #fff !important;
  margin: 0 10px;
  border: none;
  font-family: 'AventaMedium';
  display: inline;
  font-size: 14px;
  height: 40px;
  width: 170px;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  position: relative;
  cursor: pointer;
  img {
    height: 10px;
    width: 12px;
    margin-right: 10px;
    display: inline;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;
