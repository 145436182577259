/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getStatistics,
  clearFilteredStatistics,
} from "../../../actions/dashboardActions";
import {
  FilterButtonsGroup,
  RefreshButton,
  OverviewStatsCard,
  ChartCard,
  ChartMarker,
  PieChartLabels,
  PieChartMeta,
} from "./styles";
import { Bar, Doughnut, Line } from "@reactchartjs/react-chart.js";
import DashboardLoader from "./DashboardLoader";
import { formatCurrency } from "../../../util/FormatAmount";
import { Select } from "antd";
import CheckPermission from "../../../util/CheckPermission";

const StatsCard = ({ amount, meta, icon }) => (
  <OverviewStatsCard>
    <div className="card-icon">
      <img src={icon} alt={meta} />
    </div>
    <div className="card-stats-count">
      {amount.length > 10 ? (
        <h1 id="mini-head-small">{amount}</h1>
      ) : (
        <h1 id="mini-head">{amount}</h1>
      )}
      <p id="sub-title">{meta}</p>
    </div>
  </OverviewStatsCard>
);

const DashboardOverview = () => {
  const dispatch = useDispatch();

  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;


  const dashboardReducer = useSelector((state) => state.dashboardReducer);
  const { statistics: statsData, loading } = dashboardReducer;
  const statistics =
    statsData && statsData.length
      ? statsData
      : Object.keys(statsData).map((key) => ({ [key]: statsData[key] }));
  const currency = statistics[6]?.currency[0]?.code;
  const [filterBy, setFilterBy] = useState("today"); // set today to default
  useEffect(() => {
    let mounted = true;
    if (mounted && statistics.length === 0) {
      dispatch(getStatistics());
    }
    return () => (mounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CardStatsData = [
    {
      icon: require("../../../assets/images/icons/total-balance.svg").default,
      amount:
        statistics.length > 0 &&
        statistics[1] &&
        statistics[1].savings_accounts_metrics[0] &&
        statistics[1].savings_accounts_metrics[0][`running_balance_${filterBy}`]
          ? `${currency} ${formatCurrency(
              Number(
                statistics[1].savings_accounts_metrics[0][
                  `running_balance_${filterBy}`
                ]
              )
            )}`
          : "0",
      meta: "Total Deposits",
    },
    {
      icon: require("../../../assets/images/icons/total-customers.svg").default,
      amount:
        statistics.length > 0 &&
        statistics[0] &&
        statistics[0].customer_metrics[0] &&
        !!statistics[0].customer_metrics[0][`active_customer_${filterBy}`]
          ? formatCurrency(
              Number(
                statistics[0].customer_metrics[0][`active_customer_${filterBy}`]
              )
            )
          : "0",
      meta: "Total Customers",
    },
    {
      icon: require("../../../assets/images/icons/total-transactions.svg")
        .default,
      amount:
        statistics.length > 0 &&
        statistics[2] &&
        statistics[2].loan_repayment_done_metric[0] &&
        !!statistics[2].loan_repayment_done_metric[0][`repayment_${filterBy}`]
          ? `${currency} ${formatCurrency(
              Number(
                statistics[2].loan_repayment_done_metric[0][
                  `repayment_${filterBy}`
                ]
              )
            )}`
          : "0",
      meta: "Total Repayments",
    },
    {
      icon: require("../../../assets/images/icons/total-loans.svg").default,
      amount:
        statistics.length > 0 &&
        statistics[3] &&
        statistics[3].loan_disbursement_done_metric[0] &&
        !!statistics[3].loan_disbursement_done_metric[0][
          `total_disbursed_${filterBy}`
        ]
          ? `${currency} ${formatCurrency(
              Number(
                statistics[3].loan_disbursement_done_metric[0][
                  `total_disbursed_${filterBy}`
                ]
              )
            )}`
          : "0",
      meta: "Total Disbursement",
    },
  ];

  const million = 1000000;

  const outstandingData2 = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Principal  (in millions)",
        backgroundColor: "#2C1DFF",
        maintainAspectRatio: false,
        borderWidth: 1,
        barThickness: 20,
        data: [
          !!statistics.length &&
          !!statistics[4].par_metrics[0].total_january_outstanding_principal
            ? statistics[4].par_metrics[0].total_january_outstanding_principal /
              million
            : "0",
          !!statistics.length &&
          !!statistics[4].par_metrics[0].total_feburary_outstanding_principal
            ? statistics[4].par_metrics[0]
                .total_feburary_outstanding_principal / million
            : "0",
          !!statistics.length &&
          !!statistics[4].par_metrics[0].total_march_outstanding_principal
            ? statistics[4].par_metrics[0].total_march_outstanding_principal /
              million
            : "0",
          !!statistics.length &&
          !!statistics[4].par_metrics[0].total_april_outstanding_principal
            ? statistics[4].par_metrics[0].total_april_outstanding_principal /
              million
            : "0",
          !!statistics.length &&
          !!statistics[4].par_metrics[0].total_may_outstanding_principal
            ? statistics[4].par_metrics[0].total_may_outstanding_principal /
              million
            : "0",
          !!statistics.length &&
          !!statistics[4].par_metrics[0].total_june_outstanding_principal
            ? statistics[4].par_metrics[0].total_june_outstanding_principal /
              million
            : "0",
          !!statistics.length &&
          !!statistics[4].par_metrics[0].total_july_outstanding_principal
            ? statistics[4].par_metrics[0].total_july_outstanding_principal /
              million
            : "0",
          !!statistics.length &&
          !!statistics[4].par_metrics[0].total_august_outstanding_principal
            ? statistics[4].par_metrics[0].total_august_outstanding_principal /
              million
            : "0",
          !!statistics.length &&
          !!statistics[4].par_metrics[0].total_september_outstanding_principal
            ? statistics[4].par_metrics[0]
                .total_september_outstanding_principal / million
            : "0",
          !!statistics.length &&
          !!statistics[4].par_metrics[0].total_october_outstanding_principal
            ? statistics[4].par_metrics[0].total_october_outstanding_principal /
              million
            : "0",
          !!statistics.length &&
          !!statistics[4].par_metrics[0].total_november_outstanding_principal
            ? statistics[4].par_metrics[0]
                .total_november_outstanding_principal / million
            : "0",
          !!statistics.length &&
          !!statistics[4].par_metrics[0].total_december_outstanding_principal
            ? statistics[4].par_metrics[0]
                .total_december_outstanding_principal / million
            : "0",
        ],
      },
      {
        label: "Interest  (in millions)",
        backgroundColor: "#01B1FC",
        maintainAspectRatio: false,
        borderWidth: 1,
        barThickness: 20,
        data: [
          !!statistics.length &&
          !!statistics[4].par_metrics[0].total_january_outstanding_interest
            ? statistics[4].par_metrics[0].total_january_outstanding_interest /
              million
            : "0",
          !!statistics.length &&
          !!statistics[4].par_metrics[0].total_feburary_outstanding_interest
            ? statistics[4].par_metrics[0].total_feburary_outstanding_interest /
              million
            : "0",
          !!statistics.length &&
          !!statistics[4].par_metrics[0].total_march_outstanding_interest
            ? statistics[4].par_metrics[0].total_march_outstanding_interest /
              million
            : "0",
          !!statistics.length &&
          !!statistics[4].par_metrics[0].total_april_outstanding_interest
            ? statistics[4].par_metrics[0].total_april_outstanding_interest /
              million
            : "0",
          !!statistics.length &&
          !!statistics[4].par_metrics[0].total_may_outstanding_interest
            ? statistics[4].par_metrics[0].total_may_outstanding_interest /
              million
            : "0",
          !!statistics.length &&
          !!statistics[4].par_metrics[0].total_june_outstanding_principal
            ? statistics[4].par_metrics[0].total_june_outstanding_principal /
              million
            : "0",
          !!statistics.length &&
          !!statistics[4].par_metrics[0].total_june_outstanding_interest
            ? statistics[4].par_metrics[0].total_june_outstanding_interest /
              million
            : "0",
          !!statistics.length &&
          !!statistics[4].par_metrics[0].total_july_outstanding_interest
            ? statistics[4].par_metrics[0].total_july_outstanding_interest /
              million
            : "0",
          !!statistics.length &&
          !!statistics[4].par_metrics[0].total_september_outstanding_interest
            ? statistics[4].par_metrics[0]
                .total_september_outstanding_interest / million
            : "0",
          !!statistics.length &&
          !!statistics[4].par_metrics[0].total_october_outstanding_interest
            ? statistics[4].par_metrics[0].total_october_outstanding_interest /
              million
            : "0",
          !!statistics.length &&
          !!statistics[4].par_metrics[0].total_november_outstanding_interest
            ? statistics[4].par_metrics[0].total_november_outstanding_interest /
              million
            : "0",
          !!statistics.length &&
          !!statistics[4].par_metrics[0].total_december_outstanding_interest
            ? statistics[4].par_metrics[0].total_december_outstanding_interest /
              million
            : "0",
        ],
      },
    ],
  };

  // var gradient;
  // var ctx;

  // useEffect(() => {
  //   ctx = document.getElementById("canvas").getContext("2d");
  //   gradient = ctx.createLinearGradient(0, 0, 0, 400);
  //   gradient.addColorStop(0, "#01B1FC");
  //   gradient.addColorStop(0.8, "#FFFFFF");
  // }, []);

  const [effect, setEffect] = useState();

  useEffect(() => {
    let canvas = document.getElementById("canvas");
    if (canvas) {
      let cvx = canvas.getContext("2d");
      let gradient = cvx.createLinearGradient(0, 0, 0, 400);
      gradient.addColorStop(0, "#01B1FC");
      gradient.addColorStop(0.8, "#FFFFFF");
      setEffect(gradient);
    }
  }, []);

  // console.log("gradient", effect);
  const totalDebitCreditData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Total Amount Sum",
        backgroundColor: effect,
        // backgroundColor: "#2C1DFF",
        maintainAspectRatio: false,
        data: [
          !!statistics.length &&
          !!statistics[5].savings_transactions_metrics[0]
            .total_credit_january_transactions
            ? statistics[5].savings_transactions_metrics[0]
                .total_credit_january_transactions / million
            : "0",
          !!statistics.length &&
          !!statistics[5].savings_transactions_metrics[0]
            .total_credit_february_transactions
            ? statistics[5].savings_transactions_metrics[0]
                .total_credit_february_transactions / million
            : "0",
          !!statistics.length &&
          !!statistics[5].savings_transactions_metrics[0]
            .total_credit_march_transactions
            ? statistics[5].savings_transactions_metrics[0]
                .total_credit_march_transactions / million
            : "0",
          !!statistics.length &&
          !!statistics[5].savings_transactions_metrics[0]
            .total_credit_april_transactions
            ? statistics[5].savings_transactions_metrics[0]
                .total_credit_april_transactions / million
            : "0",
          !!statistics.length &&
          !!statistics[5].savings_transactions_metrics[0]
            .total_credit_may_transactions
            ? statistics[5].savings_transactions_metrics[0]
                .total_credit_may_transactions / million
            : "0",
          !!statistics.length &&
          !!statistics[5].savings_transactions_metrics[0]
            .total_credit_june_transactions
            ? statistics[5].savings_transactions_metrics[0]
                .total_credit_june_transactions / million
            : "0",
          !!statistics.length &&
          !!statistics[5].savings_transactions_metrics[0]
            .total_credit_july_transactions
            ? statistics[5].savings_transactions_metrics[0]
                .total_credit_july_transactions / million
            : "0",
          !!statistics.length &&
          !!statistics[5].savings_transactions_metrics[0]
            .total_credit_august_transactions
            ? statistics[5].savings_transactions_metrics[0]
                .total_credit_august_transactions / million
            : "0",
          !!statistics.length &&
          !!statistics[5].savings_transactions_metrics[0]
            .total_credit_september_transactions
            ? statistics[5].savings_transactions_metrics[0]
                .total_credit_september_transactions / million
            : "0",
          !!statistics.length &&
          !!statistics[5].savings_transactions_metrics[0]
            .total_credit_october_transactions
            ? statistics[5].savings_transactions_metrics[0]
                .total_credit_october_transactions / million
            : "0",
          !!statistics.length &&
          !!statistics[5].savings_transactions_metrics[0]
            .total_credit_november_transactions
            ? statistics[5].savings_transactions_metrics[0]
                .total_credit_november_transactions / million
            : "0",
          !!statistics.length &&
          !!statistics[5].savings_transactions_metrics[0]
            .total_credit_december_transactions
            ? statistics[5].savings_transactions_metrics[0]
                .total_credit_december_transactions / million
            : "0",
        ],
        // here
        borderColor: "#2C1DFF",
        tension: 0.1,
        borderWidth: 4,
      },
    ],
  };

  const totalDebitCreditDataOld = {
    labels: ["", ""],
    datasets: [
      {
        label: "Total Amount Sum",
        backgroundColor: "#2C1DFF",
        maintainAspectRatio: false,
        data: [
          0,
          statistics.length > 0 &&
          !!statistics[1].savings_accounts_metrics[0][
            `running_balance_${filterBy}`
          ]
            ? statistics[1].savings_accounts_metrics[0][
                `running_balance_${filterBy}`
              ] / million
            : "0",
        ],
      },
    ],
  };
  const totalDebitCreditData2 = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Debit  (in millions)",
        backgroundColor: "#01B1FC",
        maintainAspectRatio: false,
        borderWidth: 1,
        barThickness: 20,
        data: [
          !!statistics.length &&
          !!statistics[5].savings_transactions_metrics[0]
            .total_debit_january_transactions
            ? statistics[5].savings_transactions_metrics[0]
                .total_debit_january_transactions / million
            : "0",
          !!statistics.length &&
          !!statistics[5].savings_transactions_metrics[0]
            .total_debit_february_transactions
            ? statistics[5].savings_transactions_metrics[0]
                .total_debit_february_transactions / million
            : "0",
          !!statistics.length &&
          !!statistics[5].savings_transactions_metrics[0]
            .total_debit_march_transactions
            ? statistics[5].savings_transactions_metrics[0]
                .total_debit_march_transactions / million
            : "0",
          !!statistics.length &&
          !!statistics[5].savings_transactions_metrics[0]
            .total_debit_april_transactions
            ? statistics[5].savings_transactions_metrics[0]
                .total_debit_april_transactions / million
            : "0",
          !!statistics.length &&
          !!statistics[5].savings_transactions_metrics[0]
            .total_debit_may_transactions
            ? statistics[5].savings_transactions_metrics[0]
                .total_debit_may_transactions / million
            : "0",
          !!statistics.length &&
          !!statistics[5].savings_transactions_metrics[0]
            .total_debit_june_transactions
            ? statistics[5].savings_transactions_metrics[0]
                .total_debit_june_transactions / million
            : "0",
          !!statistics.length &&
          !!statistics[5].savings_transactions_metrics[0]
            .total_debit_july_transactions
            ? statistics[5].savings_transactions_metrics[0]
                .total_debit_july_transactions / million
            : "0",
          !!statistics.length &&
          !!statistics[5].savings_transactions_metrics[0]
            .total_debit_august_transactions
            ? statistics[5].savings_transactions_metrics[0]
                .total_debit_august_transactions / million
            : "0",
          !!statistics.length &&
          !!statistics[5].savings_transactions_metrics[0]
            .total_debit_september_transactions
            ? statistics[5].savings_transactions_metrics[0]
                .total_debit_september_transactions / million
            : "0",
          !!statistics.length &&
          !!statistics[5].savings_transactions_metrics[0]
            .total_debit_october_transactions
            ? statistics[5].savings_transactions_metrics[0]
                .total_debit_october_transactions / million
            : "0",
          !!statistics.length &&
          !!statistics[5].savings_transactions_metrics[0]
            .total_debit_november_transactions
            ? statistics[5].savings_transactions_metrics[0]
                .total_debit_november_transactions / million
            : "0",
          !!statistics.length &&
          !!statistics[5].savings_transactions_metrics[0]
            .total_debit_december_transactions
            ? statistics[5].savings_transactions_metrics[0]
                .total_debit_december_transactions / million
            : "0",
        ],
      },
      {
        label: "Credit (in millions)",
        backgroundColor: "#2C1DFF",
        maintainAspectRatio: false,
        borderWidth: 1,
        barThickness: 20,
        data: [
          !!statistics.length &&
          !!statistics[5].savings_transactions_metrics[0]
            .total_credit_january_transactions
            ? statistics[5].savings_transactions_metrics[0]
                .total_credit_january_transactions / million
            : "0",
          !!statistics.length &&
          !!statistics[5].savings_transactions_metrics[0]
            .total_credit_february_transactions
            ? statistics[5].savings_transactions_metrics[0]
                .total_credit_february_transactions / million
            : "0",
          !!statistics.length &&
          !!statistics[5].savings_transactions_metrics[0]
            .total_credit_march_transactions
            ? statistics[5].savings_transactions_metrics[0]
                .total_credit_march_transactions / million
            : "0",
          !!statistics.length &&
          !!statistics[5].savings_transactions_metrics[0]
            .total_credit_april_transactions
            ? statistics[5].savings_transactions_metrics[0]
                .total_credit_april_transactions / million
            : "0",
          !!statistics.length &&
          !!statistics[5].savings_transactions_metrics[0]
            .total_credit_may_transactions
            ? statistics[5].savings_transactions_metrics[0]
                .total_credit_may_transactions / million
            : "0",
          !!statistics.length &&
          !!statistics[5].savings_transactions_metrics[0]
            .total_credit_june_transactions
            ? statistics[5].savings_transactions_metrics[0]
                .total_credit_june_transactions / million
            : "0",
          !!statistics.length &&
          !!statistics[5].savings_transactions_metrics[0]
            .total_credit_july_transactions
            ? statistics[5].savings_transactions_metrics[0]
                .total_credit_july_transactions / million
            : "0",
          !!statistics.length &&
          !!statistics[5].savings_transactions_metrics[0]
            .total_credit_august_transactions
            ? statistics[5].savings_transactions_metrics[0]
                .total_credit_august_transactions / million
            : "0",
          !!statistics.length &&
          !!statistics[5].savings_transactions_metrics[0]
            .total_credit_september_transactions
            ? statistics[5].savings_transactions_metrics[0]
                .total_credit_september_transactions / million
            : "0",
          !!statistics.length &&
          !!statistics[5].savings_transactions_metrics[0]
            .total_credit_october_transactions
            ? statistics[5].savings_transactions_metrics[0]
                .total_credit_october_transactions / million
            : "0",
          !!statistics.length &&
          !!statistics[5].savings_transactions_metrics[0]
            .total_credit_november_transactions
            ? statistics[5].savings_transactions_metrics[0]
                .total_credit_november_transactions / million
            : "0",
          !!statistics.length &&
          !!statistics[5].savings_transactions_metrics[0]
            .total_credit_december_transactions
            ? statistics[5].savings_transactions_metrics[0]
                .total_credit_december_transactions / million
            : "0",
        ],
      },
    ],
  };

  const data2 = {
    labels: [""],
    datasets: [
      {
        label: "Total Sum (in millions)",
        backgroundColor: "#01B1FC",
        barThickness: 30,
        data: [
          statistics.length > 0 &&
          !!statistics[1].savings_accounts_metrics[0][
            `running_balance_${filterBy}`
          ]
            ? statistics[1].savings_accounts_metrics[0][
                `running_balance_${filterBy}`
              ] / million
            : "0",
        ],
      },
    ],
  };

  const PieData = {
    datasets: [
      {
        label: "Accounts",
        // backgroundColor: "#01B1FC",
        data:
          statistics.length > 0 &&
          !!statistics[0].customer_metrics[0].total_active_customer &&
          !!statistics[0].customer_metrics[0].total_inactive_customer &&
          !!statistics[0].customer_metrics[0].total_closed_customer
            ? [
                statistics[0].customer_metrics[0].total_active_customer,
                statistics[0].customer_metrics[0].total_inactive_customer,
                statistics[0].customer_metrics[0].total_closed_customer,
              ]
            : [10, 20, 30],
        weight: 0.4,
        backgroundColor: ["#002C79", "#2C1DFF", "#01B1FC"],
      },
    ],

    labels: ["Active Accounts", "Inactive Accounts", "Deactivated accounts"],
  };

  const handleRefresh = () => {
    dispatch(clearFilteredStatistics());
    dispatch(getStatistics());
  };

  let { Option } = Select;

  return (
    <>
      {CheckPermission("ALL_FUNCTIONS", permissions) ? (
        <div className="container-fluid">
          <div className="row justify-content-between">
            <div className="col-sm-12 col-md-12 col-lg-6 pb-4">
              <h3 className="page-title">Dashboard</h3>
              <p className="sub-title">
                A complete overview of your business with analysis and data
                metrics
              </p>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
              <FilterButtonsGroup>
                <div className="mr-3 mt-3">
                  <Select
                    placeholder="Filter By"
                    optionFilterProp="children"
                    value={filterBy}
                    onChange={(value) => setFilterBy(value)}
                    autoComplete="false"
                  >
                    <Option value="today">Today</Option>
                    <Option value="week">Last 7 days</Option>
                    <Option value="month">30 days</Option>
                    <Option value="year">1 year</Option>
                  </Select>
                </div>
                <RefreshButton onClick={handleRefresh} className="refresh">
                  <img
                    className="mr-2 d-inline icon-img"
                    src={
                      require("../../../assets/images/icons/refresh.svg")
                        .default
                    }
                    style={{ height: "15px" }}
                    alt="refresh"
                  />
                  Refresh Dashboard
                </RefreshButton>
              </FilterButtonsGroup>
            </div>
          </div>
          {!loading ? (
            <div>
              <div className="row">
                {CardStatsData.map((value, i) => (
                  <div key={i} className="col-sm-12 col-md-6 col-lg-3 mt-3">
                    <StatsCard {...value} />
                  </div>
                ))}
              </div>
              <div className="row mt-4">
                <div className="col-sm-12 col-md-12 col-lg-6 mt-3">
                  <ChartCard>
                    <div className="heading">
                      <div className="title">
                        Total debit & credit in customers account (in Millions)
                      </div>
                      <div className="d-flex">
                        <div>
                          <ChartMarker color="#01B1FC" />
                          Debit
                        </div>
                        <div className="ml-3">
                          <ChartMarker color="#2C1DFF" />
                          Credit
                        </div>
                      </div>
                    </div>
                    <div className="content p-2">
                      <Bar
                        data={totalDebitCreditData2}
                        width={100}
                        height={300}
                        options={{
                          maintainAspectRatio: false,
                          legend: {
                            display: false,
                          },
                          scales: {
                            xAxes: [
                              {
                                barPercentage: 1,
                                categoryPercentage: 0.4,
                                gridLines: {
                                  display: false,
                                },
                              },
                            ],
                            yAxes: [
                              {
                                barPercentage: 0.4,
                                categoryPercentage: 0.4,
                                gridLines: {
                                  borderDash: [2, 5, 7],
                                  color: "#EDEFF5",
                                },
                              },
                            ],
                          },
                        }}
                      />
                    </div>
                  </ChartCard>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6 mt-3">
                  <ChartCard>
                    <div className="heading">
                      <div className="title">
                        Total amount sum in customer’s account (in millions)
                      </div>
                    </div>
                    <div className="content p-2">
                      <Line
                        id="canvas"
                        data={totalDebitCreditData}
                        width={100}
                        height={300}
                        options={{
                          maintainAspectRatio: false,
                          legend: {
                            display: false,
                          },
                          scales: {
                            xAxes: [
                              {
                                barPercentage: 0.4,
                                categoryPercentage: 0.4,
                                gridLines: {
                                  display: false,
                                },
                              },
                            ],
                            yAxes: [
                              {
                                barPercentage: 0.4,
                                categoryPercentage: 0.4,
                                gridLines: {
                                  borderDash: [2, 5, 7],
                                  color: "#EDEFF5",
                                },
                              },
                            ],
                          },
                        }}
                      />
                    </div>
                  </ChartCard>
                </div>
              </div>
              {/* <div className="row mt-4">
                <div className="col-sm-12 col-md-12 col-lg-6 mt-3">
                  <ChartCard>
                    <div className="heading">
                      <div className="title">
                        Total Out principal & Out interest from Loans
                      </div>
                    </div>
                  </ChartCard>
                </div>
              </div> */}
              <div className="row mt-4">
                <div className="col-sm-12 col-md-12 col-lg-6 mt-3">
                  <ChartCard>
                    <div className="heading">
                      <div className="title">
                        Total unpaid principal & unpaid interest from Loans (in
                        Millions)
                      </div>
                      <div className="d-flex">
                        <div>
                          <ChartMarker color="#01B1FC" />
                          Interest
                        </div>
                        <div className="ml-3">
                          <ChartMarker color="#2C1DFF" />
                          Principal
                        </div>
                      </div>
                    </div>
                    <div className="content p-2">
                      <Bar
                        data={outstandingData2}
                        width={100}
                        height={300}
                        options={{
                          maintainAspectRatio: false,
                          legend: {
                            display: false,
                          },
                          scales: {
                            xAxes: [
                              {
                                barPercentage: 1,
                                categoryPercentage: 0.4,
                                gridLines: {
                                  display: false,
                                },
                              },
                            ],
                            yAxes: [
                              {
                                barPercentage: 0.4,
                                categoryPercentage: 0.4,
                                gridLines: {
                                  borderDash: [2, 5, 7],
                                  color: "#EDEFF5",
                                },
                              },
                            ],
                          },
                        }}
                      />
                    </div>
                  </ChartCard>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6 mt-3">
                  <ChartCard>
                    <div className="heading">
                      <div className="title">Customers segmentation</div>
                    </div>
                    <div className="content h-75 p-4 pt-0">
                      <div className="row h-100">
                        <div className="col-sm-12 col-md-6 pt-0 d-flex align-items-center">
                          <PieChartLabels>
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <ChartMarker
                                  className="m-0 mr-3"
                                  color="#002C79"
                                />
                                Active Accounts
                              </div>
                              <div className="d-flex">
                                <p style={{ color: "#000", fontWeight: "700" }}>
                                  {statistics.length > 0 &&
                                  !!statistics[0].customer_metrics[0]
                                    .total_active_customer
                                    ? statistics[0].customer_metrics[0]
                                        .total_active_customer
                                    : ""}
                                </p>
                                {/* <span className="text-muted">
                                (
                                {statistics.length > 0
                                  ? getPercentage(
                                      statistics[0].customer_metrics[0]
                                        .total_active_customer,
                                      statistics[0].customer_metrics[0]
                                        .total_active_customer +
                                        statistics[0].customer_metrics[0]
                                          .total_inactive_customer +
                                        statistics[0].customer_metrics[0]
                                          .total_closed_customer
                                    )
                                  : 0}
                                )
                              </span> */}
                              </div>
                            </div>
                            <div className="d-flex justify-content-between">
                              <div>
                                <ChartMarker
                                  className="m-0 mr-3"
                                  color="#2C1DFF"
                                />
                                Inactive Accounts
                              </div>
                              <div className="d-flex">
                                <p style={{ color: "#000", fontWeight: "700" }}>
                                  {statistics.length > 0 &&
                                  !!statistics[0].customer_metrics[0]
                                    .total_inactive_customer !== 0 &&
                                  !!statistics[0].customer_metrics[0][
                                    `inactive_customer_${filterBy}`
                                  ]
                                    ? statistics[0].customer_metrics[0]
                                        .total_inactive_customer !== 0
                                      ? statistics[0].customer_metrics[0][
                                          `inactive_customer_${filterBy}`
                                        ]
                                      : 0
                                    : 0}{" "}
                                </p>
                                {/* <span className="text-muted">
                                (
                                {statistics.length > 0
                                  ? getPercentage(
                                      statistics[0].customer_metrics[0]
                                        .total_inactive_customer,
                                      statistics[0].customer_metrics[0]
                                        .total_active_customer +
                                        statistics[0].customer_metrics[0]
                                          .total_inactive_customer +
                                        statistics[0].customer_metrics[0]
                                          .total_closed_customer
                                    )
                                  : 0}
                                )
                              </span> */}
                              </div>
                            </div>
                            <div className="d-flex justify-content-between">
                              <div>
                                <ChartMarker
                                  className="m-0 mr-3"
                                  color="#01B1FC"
                                />
                                Deactivated accounts
                              </div>
                              <div className="d-flex">
                                <p style={{ color: "#000", fontWeight: "700" }}>
                                  {statistics.length > 0
                                    ? statistics[0].customer_metrics[0]
                                        .total_closed_customer !== 0
                                      ? statistics[0].customer_metrics[0]
                                          .total_closed_customer
                                      : 0
                                    : 0}{" "}
                                </p>
                                {/* <span className="text-muted">
                                (
                                {statistics.length > 0
                                  ? getPercentage(
                                      statistics[0].customer_metrics[0]
                                        .total_closed_customer,
                                      statistics[0].customer_metrics[0]
                                        .total_active_customer +
                                        statistics[0].customer_metrics[0]
                                          .total_inactive_customer +
                                        statistics[0].customer_metrics[0]
                                          .total_closed_customer
                                    )
                                  : 0}
                                )
                              </span> */}
                              </div>
                            </div>
                          </PieChartLabels>
                        </div>
                        <div className="col-sm-12 col-md-6 d-flex align-items-center justify-content-center flex-column">
                          <Doughnut
                            data={PieData}
                            width={100}
                            height={50}
                            options={{
                              cutoutPercentage: 80,
                              legend: {
                                display: false,
                              },
                            }}
                          />
                          <PieChartMeta>
                            <h2>
                              {statistics.length > 0
                                ? formatCurrency(
                                    Number(
                                      statistics[0].customer_metrics[0]
                                        .total_active_customer
                                    ) +
                                      Number(
                                        statistics[0].customer_metrics[0]
                                          .total_inactive_customer
                                      ) +
                                      Number(
                                        statistics[0].customer_metrics[0]
                                          .total_closed_customer
                                      )
                                  )
                                : "0"}
                            </h2>
                          </PieChartMeta>
                        </div>
                      </div>
                    </div>
                  </ChartCard>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <DashboardLoader />
            </div>
          )}
        </div>
      ) : (
        <div className="container-fluid">
          <div className="row justify-content-between">
            <div className="col-sm-12 col-md-12 col-lg-6 pb-5">
              <h3 className="page-title">Welcome to the Dashboard</h3>
              {/* <p className="sub-title">
                A complete overview of your business with analysis and data
                metrics
              </p> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DashboardOverview;
