import {
  SET_TENANT,
  SET_VALID_TENANT,
  SHOW_OTP_MODAL,
  SET_VALIDITY_OF_OTP,
  SET_LOGIN_RESPONSE,
  SET_ENTERED_USER_DATA,
  SET_TO_DISPLAY,
  SET_RES_USER_DATA,
  SET_IS_VALIDUSERSECURITY,
  SHOW_SECURITY_MODAL,
  SET_RES_USER_DATA_PERMISSION,
  SET_BARCODE_DATA,
  SHOW_OTP_2FA_MODAL,
  SET_AUTH_REQUEST_ID,
} from "../constants/preAuthConstats";

const initialState = {
  tenant: "",
  validTenant: false,
  show2FAModal: false,
  showOtpModal: false,
  setValidityOfOtp: false,
  toDisplay: false,
  loginResponse: {},
  enteredUserData: {},
  responseUserData: { permissions: [] },
  isValidUserSecurity: false,
  showSecurityModal: false,
  barcode: null,
  requestID: null,
};
function setPreAuthReducers(state = initialState, action) {
  switch (action.type) {
    case SET_TENANT: {
      return {
        ...state,
        tenant: action.payload,
      };
    }
    case SET_VALID_TENANT: {
      return {
        ...state,
        validTenant: action.payload,
      };
    }
    case SHOW_OTP_MODAL: {
      return {
        ...state,
        showOtpModal: action.payload,
      };
    }
    case SHOW_OTP_2FA_MODAL: {
      return {
        ...state,
        show2FAModal: action.payload,
      };
    }
    case SHOW_SECURITY_MODAL: {
      return {
        ...state,
        showSecurityModal: action.payload,
      };
    }
    case SET_IS_VALIDUSERSECURITY: {
      return {
        ...state,
        isValidUserSecurity: action.payload,
      };
    }
    case SET_VALIDITY_OF_OTP: {
      return {
        ...state,
        setValidityOfOtp: action.payload,
      };
    }
    case SET_LOGIN_RESPONSE: {
      return {
        ...state,
        loginResponse: action.payload,
      };
    }
    case SET_ENTERED_USER_DATA: {
      return {
        ...state,
        enteredUserData: action.payload,
      };
    }
    case SET_RES_USER_DATA: {
      return {
        ...state,
        responseUserData: action.payload,
      };
    }
    case SET_RES_USER_DATA_PERMISSION: {
      return {
        ...state,
        responseUserData: {
          ...state.responseUserData,
          permissions: action.payload,
        },
      };
    }
    case SET_TO_DISPLAY: {
      return {
        ...state,
        toDisplay: action.payload,
      };
    }
    case SET_BARCODE_DATA: {
      return {
        ...state,
        barcode: action.payload,
      };
    }
    case SET_AUTH_REQUEST_ID: {
      return {
        ...state,
        requestID: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

export default setPreAuthReducers;
