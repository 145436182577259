import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ContentWrapper } from "../styles";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import AppDetails from "./components/common/AppDetails";
import AppTerms from "./components/common/AppTerms";
import AppCharges from "./components/common/AppCharges";
import AppPreview from "./components/common/AppPreview";
import AppSettings from "./components/common/AppSettings";

import SavingsDetails from "./components/savings/SavingsDetails.jsx";
import SavingsTerms from "./components/savings/SavingsTerms.jsx";
import SavingsCharges from "./components/savings/SavingsCharges.jsx";
import SavingsReview from "./components/savings/SavingsReview.jsx";
import ScrollToTop from "../../../../../../components/ScrollToTop/index.jsx";
import SavingsSettings from "./components/savings/SavingsSettings";
import Toast from "../../../../../../util/Toast.js";
import ToolTipSvg from "../../../../../../assets/images/icons/tooltip.svg";

import {
  getSavingsProductsTemplate,
  createSavingsProduct,
  resetNewSavingsProductState,
} from "../../../../../../actions/configurationActions";

const CreateSavingsProductView = ({ match, history }) => {
  const dispatch = useDispatch();
  const savingsProductsTemplate = useSelector(
    (state) => state.savingsProductsTemplate
  );

  const toolTips = useSelector((state) => state.toolTips.tooltips);

  const renderTooltip = (name) => (
    <>
      {toolTips && toolTips[name] ? (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={`tooltip-${toolTips[name]._id}`}>
              {toolTips[name].toolTip}
            </Tooltip>
          }
        >
          <img src={ToolTipSvg} style={{ marginLeft: "10px" }} alt="" />
        </OverlayTrigger>
      ) : (
        ""
      )}
    </>
  );

  const { loading, savingsProductsTemplate: template } =
    savingsProductsTemplate;

  useEffect(() => {
    dispatch(resetNewSavingsProductState());
    dispatch(getSavingsProductsTemplate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [newSavingsProduct, setNewSavingsProduct] = useState({
    productName: "",
    currency: "",
    shortName: "",
    multipleCurrency: "10",
    description: "",
    decimalPlaces: "2",
    nominalAnnualInterest: "",
    nominalAnnualInterestRateOverdraft: "",
    interestPostingPeriod: "",
    interestCompoundingPeriod: "",
    daysInYear: "",
    interestCalculated: "",
    minimumOpeningBalance: "",
    lockInPeriod: "",
    lockInPeriodSelect: "",
    withdrawalLimitPeriod: "",
    applyWithdrawalFee: false,
    minOverdraftForInterestCalculation: "",
    minumumBalance: "",
    enforceMinimumBalance: false,
    isOverdraft: false,
    overdraftLimit: "",
    minBalanceForInterestCalculation: "",
    isWitholdTax: false,
    taxGroupOptions: "",
    enableDomingTracking: false,
    overdraftPortfolio: "",
    savingPreference: "",
    savingControl: "",
    savingTransferInSuspense: "",
    interestOnSavings: "",
    writeOff: "",
    incomeFromFees: "",
    incomeFromPenalties: "",
    overdraftInterestIncome: "",
    // interestPayableAccountId: "",
    escheatLiabilityId: "",
    enableWithdrawallimit: false,
  });

  const [accountType, setAccountType] = useState("1");
  const [showAdvance, setShowAdvance] = useState(false);
  const [minimumOpeningBalance, setMinimumOpeningBalance] = useState("");
  const [minumumBalance, setMinumumBalance] = useState("");
  const [overdraftLimit, setOverdraftLimit] = useState("");
  const [daysToInactive, setDaysToInactive] = useState("");
  const [daysToDormancy, setDaysToDormancy] = useState("");
  const [daysToEscheat, setDaysToEscheat] = useState("");
  // const [enableWithdrawallimit, setEnableWithdrawallimit] = useState("")
  // const [nubanPrefix, setNubanPrefix] = useState("");

  const [overdraftPortfolio, setOverdraftPortfolio] = useState("");
  const [savingPreference, setSavingPreference] = useState("");
  const [savingControl, setSavingControl] = useState("");
  const [interestOnSavings, setInterestOnSavings] = useState("");
  const [writeOff, setWriteOff] = useState("");
  const [incomeFromFees, setIncomeFromFees] = useState("");
  const [overdraftInterestIncome, setOverdraftInterestIncome] = useState("");
  const [incomeFromPenalties, setIncomeFromPenalties] = useState("");

  const [
    minOverdraftForInterestCalculation,
    setMinOverdraftForInterestCalculation,
  ] = useState("");
  const [
    minBalanceForInterestCalculation,
    setMinBalanceForInterestCalculation,
  ] = useState("");
  const [detailsError, setDetailsError] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [settingsError, setSettingsError] = useState(false);
  const [chargesError, setChargesError] = useState(false);
  const [charges, setCharges] = useState([]);
  const [valueCharges, setValueCharges] = useState([]);
  const [processing, setLoading] = useState(false);
  const [feeToIncomeAccountMappings, setFeeToIncomeAccountMappings] = useState(
    []
  );
  const [penaltyToIncomeAccountMappings, setPenaltyToIncomeAccountMappings] =
    useState([]);
  const [
    paymentChannelToFundSourceMappings,
    setPaymentChannelToFundSourceMappings,
  ] = useState([]);

  const createSavingsProductHandler = (e) =>
    setNewSavingsProduct({
      ...newSavingsProduct,
      [e.target.name]: e.target.value,
    });

  const [currentScreen, setCurrentScreen] = useState(0);



  const handleSubmit = async () => {
    let data = {
      locale: "en",
      name: newSavingsProduct.productName,
      shortName: newSavingsProduct.shortName,
      description: newSavingsProduct.description,
      currencyCode: newSavingsProduct.currency,
      digitsAfterDecimal: newSavingsProduct.decimalPlaces,
      inMultiplesOf: newSavingsProduct.multipleCurrency
        ? Number(newSavingsProduct.multipleCurrency)
        : "",
      nominalAnnualInterestRate: newSavingsProduct.nominalAnnualInterest,
      nominalAnnualInterestRateOverdraft: newSavingsProduct.isOverdraft
        ? newSavingsProduct.nominalAnnualInterestRateOverdraft
        : "",
      interestCompoundingPeriodType:
        newSavingsProduct.interestCompoundingPeriod,
      interestPostingPeriodType: newSavingsProduct.interestPostingPeriod,
      interestCalculationType: newSavingsProduct.interestCalculated,
      interestCalculationDaysInYearType: newSavingsProduct.daysInYear,
      minRequiredOpeningBalance: minimumOpeningBalance,
      allowOverdraft: newSavingsProduct.isOverdraft,
      overdraftLimit:
        newSavingsProduct.isOverdraft && overdraftLimit
          ? Number(overdraftLimit)
          : "",
      minBalanceForInterestCalculation: minBalanceForInterestCalculation
        ? Number(minBalanceForInterestCalculation)
        : "",
      minOverdraftForInterestCalculation:
        newSavingsProduct.isOverdraft && minOverdraftForInterestCalculation
          ? Number(minOverdraftForInterestCalculation)
          : "",
      minRequiredBalance: minumumBalance ? Number(minumumBalance) : "",
      enforceMinRequiredBalance: newSavingsProduct.enforceMinimumBalance,
      lockinPeriodFrequency: newSavingsProduct.lockInPeriod,
      lockinPeriodFrequencyType: newSavingsProduct.lockInPeriodSelect,
      taxGroupId: newSavingsProduct.taxGroupOptions,
      withHoldTax: newSavingsProduct.isWitholdTax,
      withdrawalFeeForTransfers: newSavingsProduct.applyWithdrawalFee,
      accountingRule: accountType,
      charges,
      // savingsReferenceAccountId: newSavingsProduct.savingPreference,
      savingsReferenceAccountId: savingPreference,
      transfersInSuspenseAccountId: savingControl,
      savingsControlAccountId: savingControl,
      // transfersInSuspenseAccountId: newSavingsProduct.savingControl,
      // savingsControlAccountId: newSavingsProduct.savingControl,
      // interestOnSavingsAccountId: newSavingsProduct.interestOnSavings,
      interestOnSavingsAccountId: interestOnSavings,
      incomeFromFeeAccountId: incomeFromFees,
      // incomeFromFeeAccountId: newSavingsProduct.incomeFromFees,
      // incomeFromPenaltyAccountId: newSavingsProduct.incomeFromPenalties,
      incomeFromPenaltyAccountId: incomeFromPenalties,
      isDormancyTrackingActive: newSavingsProduct.enableDomingTracking,
      feeToIncomeAccountMappings,
      penaltyToIncomeAccountMappings,
      paymentChannelToFundSourceMappings,
      daysToEscheat,
      daysToDormancy,
      daysToInactive,
      // nubanPrefix,
      // overdraftPortfolioControlId: newSavingsProduct.overdraftPortfolio,
      overdraftPortfolioControlId: overdraftPortfolio,
      writeOffAccountId: writeOff,
      // writeOffAccountId: newSavingsProduct.writeOff,
      // incomeFromInterestId: newSavingsProduct.overdraftInterestIncome,
      incomeFromInterestId: overdraftInterestIncome,
      escheatLiabilityId: newSavingsProduct.savingControl,
      withdrawalLimit: newSavingsProduct.withdrawalLimit,
      allowWithdrawalLimit: newSavingsProduct.enableWithdrawallimit,
      // interestPayableAccountId: newSavingsProduct.interestPayableAccountId,

      //       overdraftPortfolio
      // savingPreference
      // savingControl
      // interestOnSavings
      // writeOff
      // incomeFromFees
      // overdraftInterestIncome
      // incomeFromPenalties
    };
    if (!savingControl) {
      delete data.escheatLiabilityId;
      delete data.savingsControlAccountId;
      delete data.transfersInSuspenseAccountId;
    }
    if (
      // !nubanPrefix ||
      chargesError ||
      detailsError ||
      termsError ||
      settingsError
    ) {
      dispatch(
        Toast({
          text: "Please fill the required fields",
          icon: "error",
          timer: 2000,
        })
      );
    } else {
      const newObject = { ...data };
      for (const key in newObject) {
        if (Object.prototype.hasOwnProperty.call(newObject, key)) {
          const element = newObject[key];
          if (!element && typeof element !== "number") {
            delete newObject[key];
          } else if (element === null || element === "") {
            delete newObject[key];
          } else if ((element + "").length <= 0) {
            delete newObject[key];
          }

        }
      }

      data = newObject;


      setLoading(true);
      await dispatch(createSavingsProduct(data, history));
      setLoading(false);
    }
  };

  const renderComponent = () => {
    let currentComponet = "";
    switch (currentScreen) {
      case 0:
        currentComponet = (
          <AppDetails>
            <SavingsDetails
              setDetailsError={setDetailsError}
              template={loading || !template ? null : template}
              handleNextScreen={handleNextCustomIndexSelect}
              createSavingsProductHandler={createSavingsProductHandler}
              productName={newSavingsProduct.productName}
              currency={newSavingsProduct.currency}
              shortName={newSavingsProduct.shortName}
              multipleCurrency={newSavingsProduct.multipleCurrency}
              description={newSavingsProduct.description}
              // nubanPrefix={nubanPrefix}
              // setNubanPrefix={setNubanPrefix}
              decimalPlaces={newSavingsProduct.decimalPlaces}
              renderTooltip={renderTooltip}
            />
          </AppDetails>
        );
        break;
      case 1:
        currentComponet = (
          <AppTerms error={detailsError}>
            <SavingsTerms
              setTermsError={setTermsError}
              template={loading || !template ? null : template}
              handleNextScreen={handleNextCustomIndexSelect}
              handlePreviousScreen={handlePreviousCustomIndexSelect}
              nominalAnnualInterest={newSavingsProduct.nominalAnnualInterest}
              interestPostingPeriod={newSavingsProduct.interestPostingPeriod}
              interestCompoundingPeriod={
                newSavingsProduct.interestCompoundingPeriod
              }
              daysInYear={newSavingsProduct.daysInYear}
              interestCalculated={newSavingsProduct.interestCalculated}
              createSavingsProductHandler={createSavingsProductHandler}
              renderTooltip={renderTooltip}
            />
          </AppTerms>
        );
        break;
      case 2:
        currentComponet = (
          <AppSettings error={termsError || detailsError}>
            <SavingsSettings
              setSettingsError={setSettingsError}
              template={template}
              handleNextScreen={handleNextCustomIndexSelect}
              handlePreviousScreen={handlePreviousCustomIndexSelect}
              minimumOpeningBalance={minimumOpeningBalance}
              nominalAnnualInterestRateOverdraft={
                newSavingsProduct?.nominalAnnualInterestRateOverdraft
              }
              setMinimumOpeningBalance={setMinimumOpeningBalance}
              lockInPeriod={newSavingsProduct.lockInPeriod}
              lockInPeriodSelect={newSavingsProduct.lockInPeriodSelect}
              applyWithdrawalFee={newSavingsProduct.applyWithdrawalFee}
              minOverdraftForInterestCalculation={
                minOverdraftForInterestCalculation
              }
              setMinOverdraftForInterestCalculation={
                setMinOverdraftForInterestCalculation
              }
              minumumBalance={minumumBalance}
              setMinumumBalance={setMinumumBalance}
              enforceMinimumBalance={newSavingsProduct.enforceMinimumBalance}
              isOverdraft={newSavingsProduct.isOverdraft}
              overdraftLimit={overdraftLimit}
              setOverdraftLimit={setOverdraftLimit}
              minBalanceForInterestCalculation={
                minBalanceForInterestCalculation
              }
              setMinBalanceForInterestCalculation={
                setMinBalanceForInterestCalculation
              }
              isWitholdTax={newSavingsProduct.isWitholdTax}
              taxGroupOptions={newSavingsProduct.taxGroupOptions}
              enableDomingTracking={newSavingsProduct.enableDomingTracking}
              createSavingsProductHandler={createSavingsProductHandler}
              setNewSavingsProduct={setNewSavingsProduct}
              daysToEscheat={daysToEscheat}
              setDaysToEscheat={setDaysToEscheat}
              daysToDormancy={daysToDormancy}
              setDaysToDormancy={setDaysToDormancy}
              daysToInactive={daysToInactive}
              setDaysToInactive={setDaysToInactive}
              renderTooltip={renderTooltip}
              enableWithdrawallimit={newSavingsProduct?.enableWithdrawallimit}
              withdrawalLimitPeriod={newSavingsProduct?.withdrawalLimitPeriod}
            />
          </AppSettings>
        );
        break;
      case 3:
        currentComponet = (
          <AppCharges error={termsError || detailsError || settingsError}>
            <SavingsCharges
              setChargesError={setChargesError}
              template={template}
              // overdraftPortfolio={newSavingsProduct.overdraftPortfolio}
              overdraftPortfolio={overdraftPortfolio}
              setOverdraftPortfolio={setOverdraftPortfolio}
              // savingPreference={newSavingsProduct.savingPreference}
              // savingControl={newSavingsProduct.savingControl}
              savingTransferInSuspense={
                newSavingsProduct.savingTransferInSuspense
              }
              // interestOnSavings={newSavingsProduct.interestOnSavings}
              // writeOff={newSavingsProduct.writeOff}
              // incomeFromFees={newSavingsProduct.incomeFromFees}
              // incomeFromPenalties={newSavingsProduct.incomeFromPenalties}
              // overdraftInterestIncome={
              //   newSavingsProduct.overdraftInterestIncome
              // }
              setShowAdvance={setShowAdvance}
              showAdvance={showAdvance}
              // interestPayableAccountId={
              //   newSavingsProduct.interestPayableAccountId
              // }
              // escheatLiabilityId={newSavingsProduct.escheatLiabilityId}
              valueCharges={valueCharges}
              setValueCharges={setValueCharges}
              setFullCharges={setCharges}
              setFeeToIncomeAccountMappings={setFeeToIncomeAccountMappings}
              feeToIncomeAccountMappings={feeToIncomeAccountMappings}
              setPenaltyToIncomeAccountMappings={
                setPenaltyToIncomeAccountMappings
              }
              penaltyToIncomeAccountMappings={penaltyToIncomeAccountMappings}
              setPaymentChannelToFundSourceMappings={
                setPaymentChannelToFundSourceMappings
              }
              paymentChannelToFundSourceMappings={
                paymentChannelToFundSourceMappings
              }
              handleNextScreen={handleNextCustomIndexSelect}
              handlePreviousScreen={handlePreviousCustomIndexSelect}
              createSavingsProductHandler={createSavingsProductHandler}
              setAccountType={setAccountType}
              accountType={accountType}
              renderTooltip={renderTooltip}
              savingPreference={savingPreference}
              setSavingPreference={setSavingPreference}
              savingControl={savingControl}
              setSavingControl={setSavingControl}
              interestOnSavings={interestOnSavings}
              setInterestOnSavings={setInterestOnSavings}
              writeOff={writeOff}
              setWriteOff={setWriteOff}
              incomeFromFees={incomeFromFees}
              setIncomeFromFees={setIncomeFromFees}
              overdraftInterestIncome={overdraftInterestIncome}
              setOverdraftInterestIncome={setOverdraftInterestIncome}
              incomeFromPenalties={incomeFromPenalties}
              setIncomeFromPenalties={setIncomeFromPenalties}
            />
          </AppCharges>
        );
        break;
      case 4:
        currentComponet = (
          <AppPreview
            error={termsError || detailsError || settingsError || chargesError}
          >
            <SavingsReview
              setChargesError={setChargesError}
              handlePreviousScreen={handlePreviousCustomIndexSelect}
              description={newSavingsProduct.description}
              // nubanPrefix={nubanPrefix}
              shortName={newSavingsProduct.shortName}
              currency={newSavingsProduct.currency}
              decimalPlaces={newSavingsProduct.decimalPlaces}
              multipleCurrency={newSavingsProduct.multipleCurrency}
              nominalAnnualInterest={newSavingsProduct.nominalAnnualInterest}
              interestPostingPeriod={newSavingsProduct.interestPostingPeriod}
              interestCompoundingPeriod={
                newSavingsProduct.interestCompoundingPeriod
              }
              minBalanceForInterestCalculation={
                minBalanceForInterestCalculation
              }
              interestCalculated={newSavingsProduct.interestCalculated}
              minimumOpeningBalance={minimumOpeningBalance}
              lockInPeriod={newSavingsProduct.lockInPeriod}
              lockInPeriodSelect={newSavingsProduct.lockInPeriodSelect}
              applyWithdrawalFee={newSavingsProduct.applyWithdrawalFee}
              isOverdraft={newSavingsProduct.isOverdraft}
              minOverdraftForInterestCalculation={
                minOverdraftForInterestCalculation
              }
              enforceMinimumBalance={newSavingsProduct.enforceMinimumBalance}
              minumumBalance={minumumBalance}
              isWitholdTax={newSavingsProduct.isWitholdTax}
              enableDomingTracking={newSavingsProduct.enableDomingTracking}
              charges={valueCharges}
              template={template}
              feeToIncomeAccountMappings={feeToIncomeAccountMappings}
              penaltyToIncomeAccountMappings={penaltyToIncomeAccountMappings}
              paymentChannelToFundSourceMappings={
                paymentChannelToFundSourceMappings
              }
              accountType={accountType}
              loading={processing}
              handleSubmit={handleSubmit}
              renderTooltip={renderTooltip}
              newSavingsProduct={newSavingsProduct}
              enableWithdrawallimit={newSavingsProduct?.enableWithdrawallimit}
              // overdraftPortfolio={overdraftPortfolio}
              // savingPreference={savingPreference}
              // savingControl={savingControl}
              // interestOnSavings={interestOnSavings}
              // writeOff={writeOff}
              // incomeFromFees={incomeFromFees}
              // overdraftInterestIncome={overdraftInterestIncome}
              // incomeFromPenalties={incomeFromPenalties}
            />
          </AppPreview>
        );
        break;
      default:
    }
    return currentComponet;
  };

  // FUNCTION HERE FOR MOVING TO THE NEXT PAGE
  const handleNextCustomIndexSelect = () => {
    let newIndex = currentScreen + 1;
    if (newIndex <= 4) {
      setCurrentScreen(newIndex);
    }
  };
  // FUNCTION HERE FOR MOVING TO THE PREVIOUS PAGE
  const handlePreviousCustomIndexSelect = () => {
    let newIndex = currentScreen - 1;
    if (newIndex >= 0) {
      setCurrentScreen(newIndex);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3 mt-1">
            <h3 className="page-title">Create savings products</h3>

            <p className="sub-title mt-1">
              {/* <span className="text-info">
              <Link to="/core/configuration">Configuration </Link>
            </span>
            /{" "} */}
              <span className="text-info mr-1">
                <Link to="/core/products">Products </Link>
              </span>
              /{" "}
              <span className="text-info mr-1">
                <Link to="/core/products/savings-products">
                  Savings products{" "}
                </Link>
              </span>
              / Create savings products
            </p>
          </div>
        </div>

        <div className="row" style={{ minHeight: "calc(100vh - 250px)" }}>
          <div className="col-sm-12 col-md-12">
            <ScrollToTop>
              <ContentWrapper className="application-wrapper">
                {renderComponent()}
              </ContentWrapper>
            </ScrollToTop>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateSavingsProductView;
