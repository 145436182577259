import React from "react";
import { Table, Spinner } from "react-bootstrap";
import { ActionButton, FormTitle } from "../../../styles";
import { formatCurrency } from "../../../../../../../../util/FormatAmount";

const LoanReview = ({
  handlePreviousScreen,
  handleSubmit,
  customerLoanCounter,
  currency,
  decimalPlaces,
  multipleCurrency,
  installment,
  terms,
  principal,
  max_pricipal,
  min_pricipal,
  repayment,
  min_repayment,
  max_repayment,
  repaid,
  max_interest,
  min_interest,
  def_interest,
  disbursal,
  amortizationType,
  interestType,
  isEqualAmortication,
  interestCalculationPeriodType,
  graceOnArrearsAgeing,
  graceOnPrincipalAndInterestPayment,
  inArrearsTolerance,
  transactionProcessingStrategyId,
  accountType,
  inArrearsToleranceValue,
  graceOnInterestCharged,
  graceOnArrearsAgeingValue,
  accountMovesOutOfNPAOnlyOnArrearsCompletion,
  daysInyear,
  template,
  daysInMonth,
  isInterestRecalculationEnabled,
  placeGuarantee,
  loading,
  loanTrancte,
  charges,
  overdueCharges,
  feeToIncomeAccountMappings,
  penaltyToIncomeAccountMappings,
  paymentChannelToFundSourceMappings,
}) => {
  const returnPaymentType = (id) => {
    let paymentType = template
      ? template.paymentTypeOptions.filter((item) => item.id === Number(id))
      : [];
    let paymentTypeValue = paymentType.length > 0 ? paymentType[0].name : "";
    return paymentTypeValue;
  };
  const returnFundSource = (id) => {
    let fundSource =
      template && template.accountingMappingOptions
        ? template.accountingMappingOptions.assetAccountOptions.filter(
            (item) => item.id === Number(id)
          )
        : [];
    let fundSourceValue = fundSource.length > 0 ? fundSource[0].name : "";
    return fundSourceValue;
  };
  const returnFeeType = (id) => {
    let feeType = template
      ? template.chargeOptions.filter((item) => item.id === Number(id))
      : [];
    let feeTypeValue = feeType.length > 0 ? feeType[0].name : "";
    return feeTypeValue;
  };

  const returnIncomeAccount = (id) => {
    let incomeAccount =
      template && template.accountingMappingOptions
        ? template.accountingMappingOptions.incomeAccountOptions.filter(
            (item) => item.id === Number(id)
          )
        : [];
    let incomeAccountValue =
      incomeAccount.length > 0 ? incomeAccount[0].name : "";
    return incomeAccountValue;
  };
  const returnPenaltyType = (id) => {
    let penaltyType =
      template && template.penaltyOptions
        ? template.penaltyOptions.filter((item) => item.id === Number(id))
        : [];

    let penaltyTypeValue = penaltyType?.length > 0 ? penaltyType[0].name : "";
    return penaltyTypeValue;
  };
  const accountingSummary = [
    {
      label: 'Fund Source (Asset)',
      value: template?.accountingMappings?.fundSourceAccount?.name,
      show: accountType !== "1",
    },
    {
      label: 'Loan Portfolio (Asset)',
      value: template?.accountingMappings?.loanPortfolioAccount?.name,
      show: accountType !== "1",
    },
    {
      label: 'Interest Receivable (Asset)',
      value: template?.accountingMappings?.receivableInterestAccount?.name,
      show: accountType === "3" && accountType !== "1",
    },
    {
      label: 'Fees Receivable (Asset)',
      value: template?.accountingMappings?.receivableFeeAccount?.name,
      show: accountType === "3" && accountType !== "1",
    },
    {
      label: 'Penalties Receivable (Asset)',
      value: template?.accountingMappings?.receivablePenaltyAccount?.name,
      show: accountType === "3" && accountType !== "1",
    },
    {
      label: 'Transfer In Suspense (Asset)',
      value: template?.accountingMappings?.transfersInSuspenseAccount?.name,
      show: accountType !== "1",
    },
    {
      label: 'Income From Interest (Income)',
      value: template?.accountingMappings?.interestOnLoanAccount?.name,
      show: accountType !== "1",
    },
    {
      label: 'Income From Penalties (Income)',
      value: template?.accountingMappings?.incomeFromPenaltyAccount?.name,
      show: accountType !== "1",
    },
    {
      label: 'Income From Fees (Income)',
      value: template?.accountingMappings?.incomeFromFeeAccount?.name,
      show: accountType !== "1",
    },
    {
      label: 'Income From Recovery Repayments (Income)',
      value: template?.accountingMappings?.incomeFromRecoveryAccount?.name,
      show: accountType !== "1",
    },
    {
      label: 'Losses Written Off (Expense)',
      value: template?.accountingMappings?.writeOffAccount?.name,
      show: accountType !== "1",
    },
    {
      label: 'Over Payment Liability (Liability)',
      value: template?.accountingMappings?.overpaymentLiabilityAccount?.name,
      show: accountType !== "1",
    },
  ];
  return (
    <>
      <div className="col-sm-12 col-md-12 d-flex ">
        <div className="container">
          <div>
            <FormTitle>
              <p className="text-info">Details</p>
              <div className="lineThrough"></div>
            </FormTitle>
            <div className="pt-2">
              <div
                className="form-group d-flex  align-items-center text-label"
                style={{ marginBottom: "1rem !important" }}
              >
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Include In Customer Loan Counter &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {customerLoanCounter ? "True" : "False"}
                </p>
              </div>
            </div>
          </div>

          <div className="pt-1">
            <FormTitle>
              <p className="text-info">Currency</p>
              <div className="lineThrough"></div>
            </FormTitle>
            <div className="pt-2">
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Currency &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>{currency}</p>
              </div>
              {/* <div className="form-group d-flex  align-items-center text-label">
                <h3 style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }} className="col-md-4">Decimal Places &nbsp;&nbsp;&nbsp;:</h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>{decimalPlaces}</p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3 style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }} className="col-md-4">Currency In Multiples Of &nbsp;&nbsp;&nbsp;:</h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>{multipleCurrency}</p>
              </div> */}
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Installment In Multiples Of &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {installment}
                </p>
              </div>
            </div>
          </div>

          <div className="pt-1">
            <FormTitle>
              <p className="text-info">Terms</p>
              <div className="lineThrough"></div>
            </FormTitle>
            <div className="pt-2">
              <div className="form-group d-flex align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Terms Vary Based On Loan Cycle &nbsp;&nbsp;&nbsp;:
                </h3>
                <p>{terms ? "True" : "False"}</p>
              </div>
              <div className="form-group d-flex align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Principal &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  ({principal} Min: {min_pricipal}; Max: {max_pricipal};)
                </p>
              </div>
              <div className="form-group d-flex align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Number Of Repayments &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  ({repayment} Min: {min_repayment}; Max {max_repayment}; )
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Nominal Interest Rate &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  ({def_interest} Min: {min_interest}; Max: {max_interest}; )
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Repay Every &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>{repaid}</p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Minimum Days Between Disbursal And First Repayment Date
                  &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>{disbursal}</p>
              </div>
            </div>
          </div>

          <div className="pt-1">
            <FormTitle>
              <p className="text-info">Settings</p>
              <div className="lineThrough"></div>
            </FormTitle>
            <div className="pt-2">
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Amortization &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {amortizationType ? "True" : "False"}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Interest Method &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {interestCalculationPeriodType ? "True" : "False"}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Is Equal Amortization &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {isEqualAmortication ? "True" : "False"}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Interest Calculation Period &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {interestType ? "True" : "False"}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Allow Partial Interest Calculation With Same As Repayment{" "}
                  &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {graceOnPrincipalAndInterestPayment ? "True" : "False"}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Arrears Tolerance &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {inArrearsTolerance ? "True" : "False"}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Repayment Strategy &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {transactionProcessingStrategyId ? "True" : "False"}
                </p>
              </div>
              {/* <div className="form-group d-flex  align-items-center text-label">
                <h3 style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }} className="col-md-4">Grace &nbsp;&nbsp;&nbsp;:</h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>{}</p>
              </div> */}
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Interest Free Period &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {graceOnInterestCharged}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Grace On Arrears Ageing &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {graceOnArrearsAgeing ? "True" : "False"}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Overdue Days For NPA &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {graceOnArrearsAgeingValue}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Account Moves Out Of NPA Only After &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {accountMovesOutOfNPAOnlyOnArrearsCompletion
                    ? "True"
                    : "False"}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  All Arrears Have Been Cleared? &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {accountMovesOutOfNPAOnlyOnArrearsCompletion
                    ? "True"
                    : "False"}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Days In Year &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {
                    daysInyear === "1"
                      ? "Actual"
                      : daysInyear === "2"
                      ? "360 Days"
                      : daysInyear === "3"
                      ? "364 Days"
                      : "365 Days"

                    // template?  template.daysInYearTypeOptions ? template.daysInYearTypeOptions.filter :"":""
                  }
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Days In Month &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {daysInMonth === "1"
                    ? "Actual"
                    : daysInMonth === "30"
                    ? "30 Days"
                    : ""}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Principal Threshold (%) For Last Instalment
                  &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>{}</p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Allow Fixing Of The Installment Amount &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>{}</p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Is Allowed To Be Used For Providing &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>{}</p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Topup Loans? &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>{}</p>
              </div>
            </div>
          </div>

          <div className="pt-1">
            <FormTitle>
              <p className="text-info">Interest recalculation</p>
              <div className="lineThrough"></div>
            </FormTitle>
            <div className="pt-2">
              <div
                className="form-group d-flex  align-items-center text-label"
                style={{ marginBottom: "1rem !important" }}
              >
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Recalculate interest &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "80%" }}>
                  {isInterestRecalculationEnabled ? "Yes" : "No"}
                </p>
              </div>
            </div>
          </div>

          <div className="pt-1">
            <FormTitle>
              <p className="text-info">Guarantee requirement</p>
              <div className="lineThrough"></div>
            </FormTitle>
            <div className="pt-2">
              <div
                className="form-group d-flex  align-items-center text-label"
                style={{ marginBottom: "1rem !important" }}
              >
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Place Guarantee Funds On-Hold? &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "80%" }}>{placeGuarantee ? "Yes" : "No"}</p>
              </div>
            </div>
          </div>

          <div className="pt-1">
            <FormTitle>
              <p className="text-info">Loan Tranche requirement</p>
              <div className="lineThrough"></div>
            </FormTitle>
            <div className="pt-2">
              <div
                className="form-group d-flex  align-items-center text-label"
                style={{ marginBottom: "1rem !important" }}
              >
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Enable Multiple Disbursals &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "80%" }}> {loanTrancte ? "Yes" : "No"}</p>
              </div>
            </div>
          </div>

          <div className="pt-1">
            <FormTitle>
              <p className="text-info">Configurable terms & settings</p>
              <div className="lineThrough"></div>
            </FormTitle>
            <div className="pt-2">
              <div
                className="form-group d-flex  align-items-center text-label"
                style={{ marginBottom: "1rem !important" }}
              >
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  {" "}
                  Allow Overriding Select Terms And &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "80%" }}>True</p>
              </div>
              <div
                className="form-group d-flex  align-items-center text-label"
                style={{ marginBottom: "1rem !important" }}
              >
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Settings In Loan Accounts &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "80%" }}>True</p>
              </div>{" "}
              <div
                className="form-group d-flex  align-items-center text-label"
                style={{ marginBottom: "1rem !important" }}
              >
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Amortization &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "80%" }}>True</p>
              </div>{" "}
              <div
                className="form-group d-flex  align-items-center text-label"
                style={{ marginBottom: "1rem !important" }}
              >
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Arrears Tolerance &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "80%" }}>
                  {inArrearsToleranceValue ? "True" : "False"}
                </p>
              </div>
              <div
                className="form-group d-flex  align-items-center text-label"
                style={{ marginBottom: "1rem !important" }}
              >
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Repaid Every &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "80%" }}>{repaid ? "True" : "False"}</p>
              </div>
              <div
                className="form-group d-flex  align-items-center text-label"
                style={{ marginBottom: "1rem !important" }}
              >
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Moratorium &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "80%" }}>
                  {graceOnPrincipalAndInterestPayment ? "True" : "False"}
                </p>
              </div>
              <div
                className="form-group d-flex  align-items-center text-label"
                style={{ marginBottom: "1rem !important" }}
              >
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Number Of Days A Loan May Be Overdue Before Moving Into
                  Arrears &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "80%" }}>
                  {" "}
                  {inArrearsTolerance ? "True" : "False"}
                </p>
              </div>
            </div>
          </div>

          <div className="pt-1">
            <FormTitle>
              <p className="text-info">Charges</p>
              <div className="lineThrough"></div>
            </FormTitle>
            <div className="pt-2">
              <Table className="child-table">
                <thead className="">
                  <tr style={{ backgroundColor: "#FAFAFA" }}>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Amount</th>
                    <th>Collected on</th>
                  </tr>
                </thead>
                <tbody>
                  {charges.length > 0
                    ? charges.map(
                        ({
                          id,
                          name,
                          chargeCalculationType,
                          amount,
                          chargeTimeType,
                        }) => (
                          <tr key={id}>
                            <td>{name}</td>
                            <td>
                              {chargeCalculationType &&
                                chargeCalculationType.value}
                            </td>
                            <td>{formatCurrency(Number(amount))}</td>
                            <td>{chargeTimeType && chargeTimeType.value}</td>
                          </tr>
                        )
                      )
                    : null}
                </tbody>
              </Table>
            </div>
          </div>

          <div className="pt-1">
            <FormTitle>
              <p className="text-info">Overdue charges</p>
              <div className="lineThrough"></div>
            </FormTitle>
            <div className="pt-2">
              <Table className="child-table">
                <thead className="">
                  <tr style={{ backgroundColor: "#FAFAFA" }}>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Amount</th>
                    <th>Collected on</th>
                  </tr>
                </thead>
                <tbody>
                  {overdueCharges?.length > 0
                    ? overdueCharges.map(
                        ({
                          id,
                          name,
                          chargeCalculationType,
                          amount,
                          chargeTimeType,
                        }) => (
                          <tr key={id}>
                            <td>{name}</td>
                            <td>
                              {chargeCalculationType &&
                                chargeCalculationType.value}
                            </td>
                            <td>{formatCurrency(Number(amount))}</td>
                            <td>{chargeTimeType && chargeTimeType.value}</td>
                          </tr>
                        )
                      )
                    : null}
                </tbody>
              </Table>
            </div>
          </div>

          <div className="pt-1">
            <FormTitle>
              <p className="text-info">Accounting</p>
              <div className="lineThrough"></div>
            </FormTitle>
            <div className="pt-2">
              <div
                className="form-group d-flex  align-items-center text-label"
                style={{ marginBottom: "1rem !important" }}
              >
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Account type &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "80%" }}>
                  {accountType === "1"
                    ? "None"
                    : accountType === "2"
                    ? "Cash"
                    : accountType === "3"
                    ? "Accural (Periodic)"
                    : "Accural (Upfront)"}
                </p>
              </div>
              {accountingSummary?.filter(gl => gl?.value && gl?.show)?.map((gl, index) => (
                <div key={index} className="form-group d-flex align-items-center text-label">
                  <h3 style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }} className="col-md-4">
                    {gl?.label}&nbsp;&nbsp;&nbsp;:
                  </h3>
                  <p>{gl?.value}</p>
                </div>
              ))}
            </div>
            <div className="pt-2">
              <FormTitle>
                <p className="text-info">
                  Configure Fund Sources For Payment Channels
                </p>
              </FormTitle>
              <Table className="child-table">
                <thead className="">
                  <tr style={{ backgroundColor: "#FAFAFA" }}>
                    <th>PAYMENT TYPE</th>
                    <th>FUND SOURCE</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentChannelToFundSourceMappings.length > 0
                    ? paymentChannelToFundSourceMappings.map(
                        ({ paymentTypeId, id, fundSourceAccountId }) => (
                          <tr key={id}>
                            <td>{returnPaymentType(paymentTypeId)}</td>
                            <td>{returnFundSource(fundSourceAccountId)}</td>
                          </tr>
                        )
                      )
                    : null}
                </tbody>
              </Table>
            </div>
            <div className="pt-2">
              <FormTitle>
                <p className="text-info">Map fees to income accounts</p>
              </FormTitle>
              <Table className="child-table">
                <thead className="">
                  <tr style={{ backgroundColor: "#FAFAFA" }}>
                    <th>PAYMENT TYPE</th>
                    <th>FUND SOURCE</th>
                  </tr>
                </thead>
                <tbody>
                  {feeToIncomeAccountMappings.length > 0
                    ? feeToIncomeAccountMappings.map(
                        ({ incomeAccountId, id, chargeId }) => (
                          <tr key={id}>
                            <td>{returnFeeType(chargeId)}</td>
                            <td>{returnIncomeAccount(incomeAccountId)}</td>
                          </tr>
                        )
                      )
                    : null}
                </tbody>
              </Table>
            </div>
            <div className="pt-2">
              <FormTitle>
                <p className="text-info">
                  Map Penalties To Specific Income Accounts
                </p>
              </FormTitle>
              <Table className="child-table">
                <thead className="">
                  <tr style={{ backgroundColor: "#FAFAFA" }}>
                    <th>PAYMENT TYPE</th>
                    <th>FUND SOURCE</th>
                  </tr>
                </thead>
                <tbody>
                  {penaltyToIncomeAccountMappings.length > 0
                    ? penaltyToIncomeAccountMappings.map(
                        ({ incomeAccountId, id, chargeId }) => (
                          <tr key={id}>
                            <td>{returnPenaltyType(chargeId)}</td>
                            <td>{returnIncomeAccount(incomeAccountId)}</td>
                          </tr>
                        )
                      )
                    : null}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="float-right pt-5">
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={handlePreviousScreen}
          >
            <img
              src={
                require("../../../../../../../../assets/images/icons/cancel.svg")
                  .default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton
            onClick={handleSubmit}
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
          >
            {loading ? (
              <Spinner
                animation="border"
                color="#fff"
                className="mr-2 text-white"
                size="sm"
              />
            ) : (
              <img
                src={
                  require("../../../../../../../../assets/images/icons/save.svg")
                    .default
                }
                alt="submit icon"
              />
            )}
            Submit
          </ActionButton>
        </div>
      </div>
    </>
  );
};

export default LoanReview;
