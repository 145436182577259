import styled from "styled-components";

export const ActionButtonsGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  justify-content: space-around;
  select {
    // background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    border-radius: 3px;
    height: 50px;
    min-width: 100px;
    border: none;
    // margin: ;
  }
`;

export const ContentWrapper = styled.div`
  margin-top: 50px;

  .button-right {
    width: 100%;
    height: 50px;
  }
`;

export const ContentCard = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  min-width: 0;
  word-wrap: break-word;
  justify-content: space-around;
  align-items: center;
  //   padding-left: 20px;
  //   padding-right: 20px;
  padding: 20px 10px;
  background-color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  flex-direction: row;
  box-shadow: 0px 2px 0px #ededf6;
  .body {
    width: 150px;
  }
  .title {
    font-weight: bold;
    font-size: 15px;
    color: #333333;
  }
  .description {
    margin-top: 10px;
    font-weight: 300;
    font-size: 13px;
    color: #a6acbe;
  }
`;

export const FormElements = styled.div`
  // width: 90%;
  margin-top: 30px;
  margin-bottom: 30px;
  display: block;
  .text-muted {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #969cba;
  }
  .css-42b2qy {
    width: 60%;
    color: #666666;
    & > option {
      padding: 0.5rem 0;
    }
  }
`;

export const ActionButton = styled.button`
  background: ${(props) => props.color} !important;
  border-radius: 5px !important;
  color: #fff !important;
  margin: 0 10px;
  border: none;
  font-family: 'AventaMedium';
  display: inline;
  font-size: 14px;
  height: 35px;
  width: 200px;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  position: relative;
  cursor: pointer;
  img {
    height: 10px;
    margin-right: 10px;
    display: inline;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;
