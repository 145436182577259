import React, { memo, useState } from "react";
import { useMeasure, usePrevious } from "./helpers";
import { FrameInner, Title, ContentInner, toggle } from "./innerTreeStyles";
import * as Icons from "./icons";

const InnerTree = memo(({ children, name, style, defaultOpen = false }) => {
  const [isOpen, setOpen] = useState(defaultOpen);
  const previous = usePrevious(isOpen);
  const [bind, { height: viewHeight }] = useMeasure();

  const Icon =
    Icons[`${children ? (isOpen ? "Minus" : "Plus") : "Close"}SquareO`];
  return (
    <FrameInner>
      <div className="d-flex align-items-center">
        <span className="span mr-2" />
        <Icon
          style={{
            ...toggle,
            opacity: children ? 1 : 0.3,
            fill: isOpen ? "#FF0000" : "#2C1DFF",
          }}
          onClick={() => (children ? setOpen(!isOpen) : null)}
        />
        <Title style={style}>{name}</Title>
      </div>
      <ContentInner
        style={{
          opacity: 1,
          height:
            isOpen && previous === isOpen ? "auto" : isOpen ? viewHeight : 0,
        }}
      >
        <div {...bind} children={children} />
      </ContentInner>
    </FrameInner>
  );
});

export default InnerTree;
