/**
 * Truncates a string to a specified maximum length, optionally appending an ellipsis (...).
 * Preserves whole words, ensuring truncation occurs at word boundaries.
 *
 * @param {string} text - The text string to truncate.
 * @param {number} [maxLength=200] - The maximum number of characters to display. Defaults to 200.
 * @returns {string} The truncated string (with or without ellipsis), or an empty string if `text` is empty.
 */
export function truncateText(text, maxLength = 200) {
  if (!text) return '';

  // Check if truncation is needed only when text length exceeds maxLength
  if (text.length > maxLength) {
    // Truncate and add ellipsis
    return `${text.slice(0, maxLength)}...`;
  } else {
    // Return the original text if it's within the limit
    return text;
  }}