import React from "react";
import { Table } from "antd";
import Loading from "./Loading";

const columns = [
  { title: "", dataIndex: "picture", key: "picture" },
  { title: "Customer Name", dataIndex: "name", key: "name" },
  { title: "External ID", dataIndex: "externalId", key: "externalId" },
  {
    title: "Account Number",
    dataIndex: "accountNumber",
    key: "accountNumber",
  },
  { title: "Principal", dataIndex: "pricipal", key: "pricipal" },
  { title: "Interest Amount", dataIndex: "interest", key: "interest" },
  {
    title: "Outstanding Amount",
    dataIndex: "outstanding",
    key: "outstanding",
  },
  { title: "Overdue Amount", dataIndex: "overdue", key: "overdue" },
  { title: "Days in Arrear", dataIndex: "daysInArrars", key: "daysInArrars" },
];
const TableLoader = (props) => {
  const someChartsData = [
    {
      picture: <Loading />,
      name: <Loading />,
      externalId: <Loading />,
      accountNumber: <Loading />,
      accountbalance: <Loading />,
      pricipal: <Loading />,
      interest: <Loading />,
      outstanding: <Loading />,
      overdue: <Loading />,
      daysInArrars: <Loading />,
    },
    {
      picture: <Loading />,
      name: <Loading />,
      externalId: <Loading />,
      accountNumber: <Loading />,
      accountbalance: <Loading />,
      pricipal: <Loading />,
      interest: <Loading />,
      outstanding: <Loading />,
      overdue: <Loading />,
      daysInArrars: <Loading />,
    },
    {
      picture: <Loading />,
      name: <Loading />,
      externalId: <Loading />,
      accountNumber: <Loading />,
      accountbalance: <Loading />,
      pricipal: <Loading />,
      interest: <Loading />,
      outstanding: <Loading />,
      overdue: <Loading />,
      daysInArrars: <Loading />,
    },
    {
      picture: <Loading />,
      name: <Loading />,
      externalId: <Loading />,
      accountNumber: <Loading />,
      accountbalance: <Loading />,
      pricipal: <Loading />,
      interest: <Loading />,
      outstanding: <Loading />,
      overdue: <Loading />,
      daysInArrars: <Loading />,
    },
    {
      picture: <Loading />,
      name: <Loading />,
      externalId: <Loading />,
      accountNumber: <Loading />,
      accountbalance: <Loading />,
      pricipal: <Loading />,
      interest: <Loading />,
      outstanding: <Loading />,
      overdue: <Loading />,
      daysInArrars: <Loading />,
    },
    {
      picture: <Loading />,
      name: <Loading />,
      externalId: <Loading />,
      accountNumber: <Loading />,
      accountbalance: <Loading />,
      pricipal: <Loading />,
      interest: <Loading />,
      outstanding: <Loading />,
      overdue: <Loading />,
      daysInArrars: <Loading />,
    },
    {
      picture: <Loading />,
      name: <Loading />,
      externalId: <Loading />,
      accountNumber: <Loading />,
      accountbalance: <Loading />,
      pricipal: <Loading />,
      interest: <Loading />,
      outstanding: <Loading />,
      overdue: <Loading />,
      daysInArrars: <Loading />,
    },
    {
      picture: <Loading />,
      name: <Loading />,
      externalId: <Loading />,
      accountNumber: <Loading />,
      accountbalance: <Loading />,
      pricipal: <Loading />,
      interest: <Loading />,
      outstanding: <Loading />,
      overdue: <Loading />,
      daysInArrars: <Loading />,
    },
    {
      picture: <Loading />,
      name: <Loading />,
      externalId: <Loading />,
      accountNumber: <Loading />,
      accountbalance: <Loading />,
      pricipal: <Loading />,
      interest: <Loading />,
      outstanding: <Loading />,
      overdue: <Loading />,
      daysInArrars: <Loading />,
    },
    {
      picture: <Loading />,
      name: <Loading />,
      externalId: <Loading />,
      accountNumber: <Loading />,
      accountbalance: <Loading />,
      pricipal: <Loading />,
      interest: <Loading />,
      outstanding: <Loading />,
      overdue: <Loading />,
      daysInArrars: <Loading />,
    },
    {
      picture: <Loading />,
      name: <Loading />,
      externalId: <Loading />,
      accountNumber: <Loading />,
      accountbalance: <Loading />,
      pricipal: <Loading />,
      interest: <Loading />,
      outstanding: <Loading />,
      overdue: <Loading />,
      daysInArrars: <Loading />,
    },
    {
      picture: <Loading />,
      name: <Loading />,
      externalId: <Loading />,
      accountNumber: <Loading />,
      accountbalance: <Loading />,
      pricipal: <Loading />,
      interest: <Loading />,
      outstanding: <Loading />,
      overdue: <Loading />,
      daysInArrars: <Loading />,
    },
    {
      picture: <Loading />,
      name: <Loading />,
      externalId: <Loading />,
      accountNumber: <Loading />,
      accountbalance: <Loading />,
      pricipal: <Loading />,
      interest: <Loading />,
      outstanding: <Loading />,
      overdue: <Loading />,
      daysInArrars: <Loading />,
    },
    {
      picture: <Loading />,
      name: <Loading />,
      externalId: <Loading />,
      accountNumber: <Loading />,
      accountbalance: <Loading />,
      pricipal: <Loading />,
      interest: <Loading />,
      outstanding: <Loading />,
      overdue: <Loading />,
      daysInArrars: <Loading />,
    },
    {
      picture: <Loading />,
      name: <Loading />,
      externalId: <Loading />,
      accountNumber: <Loading />,
      accountbalance: <Loading />,
      pricipal: <Loading />,
      interest: <Loading />,
      outstanding: <Loading />,
      overdue: <Loading />,
      daysInArrars: <Loading />,
    },
  ];
  return (
    <>
      <Table
        columns={columns}
        dataSource={someChartsData}
        pagination={{ defaultPageSize: 15 }}
        className="antdTable"
        defaultPageSize={8}
      />
    </>
  );
};

export default TableLoader;
