import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Select,
  Textarea,
  Input,
  Radio,
  RadioGroup,
  Stack,
  FormControl,
  Checkbox,
} from "@chakra-ui/react";
import { FormElements, ActionButton } from "../../styles";
import { ContentWrapper, RoleButton, TagButton } from "./styles";
import ModalContainer from "../../../components/ModalContainer";
import {
  createANewAccountRule,
  getAllAccountRulesTemplate,
} from "../../../../../actions/accountingActions";
import { useDispatch, useSelector } from "react-redux";
import Toast from "../../../../../util/Toast";
import CreditRuleModal from "../Modals/CreditRuleModal";
import DebitRuleModal from "../Modals/DebitRuleModal";

const CreateAccountRule = ({ history }) => {
  const [modalState, toggleModal] = useState(false);
  const [modalContentName, setModalContentName] = useState("");
  const [office, setOffice] = useState("");
  const [accountingRuleName, setAccountingRuleName] = useState("");
  const [debitFixedAccount, setDebitFixedAccount] = useState("");
  const [selectedDebitRole, setSelectedDebitRole] = useState([]);
  const [debitRadio, setDebitRadio] = useState("1");
  const [debitEntries, setDebitEntries] = useState(false);
  const [creditFixedAccount, setCreditFixedAccount] = useState("");
  const [selectedCreditRole, setSelectedCreditRole] = useState([]);
  const [creditRadio, setCreditRadio] = useState("1");
  const [creditEntries, setCreditEntries] = useState(false);
  const [description, setDescription] = useState("");
  const dispatch = useDispatch();
  const accountingData = useSelector((state) => state.accountingData);

  const handleModalClose = () => {
    setCreditRadio("3");
    setDebitRadio("3");
    toggleModal(false);
  };

  useEffect(() => {
    dispatch(getAllAccountRulesTemplate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let {
    accountRulesTemplate = {},
    // isLoading,
  } = accountingData;
  // useEffect(() => {
  //   if (
  //     accountRulesTemplate &&
  //     accountRulesTemplate.allowedAccounts &&
  //     accountRulesTemplate.allowedAccounts.length
  //   ) {
  //     setDebitFixedAccount(String(accountRulesTemplate.allowedAccounts[0].id));
  //     setCreditFixedAccount(String(accountRulesTemplate.allowedAccounts[0].id));
  //   }
  // }, [accountRulesTemplate]);

  let dataTosubmit = {
    officeId: Number(office),
    name: accountingRuleName,
    accountToCredit: Number(creditFixedAccount),
    accountToDebit: Number(debitFixedAccount),
    description,
    allowMultipleDebitEntries: debitEntries,
    allowMultipleCreditEntries: creditEntries,
    creditTags:
      selectedCreditRole.length > 0
        ? selectedCreditRole.map((item) => Number(item.id))
        : [],
    debitTags:
      selectedDebitRole.length > 0
        ? selectedDebitRole.map((item) => Number(item.id))
        : [],
  };

  const handleSubmitAccountingRule = (e) => {
    e.preventDefault();
    if (!accountingRuleName) {
      dispatch(Toast({ text: "Please input an accounting rule name" }));
    } else if (!office || office.length < 1) {
      dispatch(Toast({ text: "Please select an Office", type: "info" }));
    } else {
      debitRadio === "2" || debitRadio === "3"
        ? delete dataTosubmit["accountToDebit"]
        : delete dataTosubmit["debitTags"];
      creditRadio === "2" || creditRadio === "3"
        ? delete dataTosubmit["accountToCredit"]
        : delete dataTosubmit["creditTags"];
      dispatch(createANewAccountRule(dataTosubmit, history));
    }
  };

  const displayModalContent = () => {
    switch (modalContentName) {
      case "creditrulemodal":
        return (
          <CreditRuleModal
            allData={accountRulesTemplate?.allowedCreditTagOptions}
            onHide={handleModalClose}
            setSelectedCreditRole={setSelectedCreditRole}
            selectedCreditRole= {selectedCreditRole}
          />
        );
      case "debitrulemodal":
        return (
          <DebitRuleModal
            allData={accountRulesTemplate?.allowedDebitTagOptions}
            onHide={handleModalClose}
            setSelectedDebitRole={setSelectedDebitRole}
            selectedDebitRole = {selectedDebitRole}
          />
        );
      default:
        return;
    }
  };

  useEffect(() => {
    // run something every time name changes
    if (debitRadio === "2") {
      toggleModal(true);
      setModalContentName("debitrulemodal");
    } else if (creditRadio === "2") {
      toggleModal(true);
      setModalContentName("creditrulemodal");
    }
  }, [debitRadio, creditRadio]);

  return (
    <>
      <ModalContainer show={modalState} dialogClassName="document_infoModal">
        {modalState && displayModalContent()}
      </ModalContainer>

      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6">
            <h3 className="page-title">Create Account Rule</h3>
            <p className="sub-title">
              <span className="text-info mr-1">
                <Link to="/core/accounting/rules">Account Rules</Link>
              </span>
              / Add Rule
            </p>
          </div>
        </div>
        <ContentWrapper>
          <div className="row h-100">
            <div className="col-md-12 col-xl-6">
              <FormElements>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Account Office</label>
                  <Select
                    placeholder="Select Office"
                    size="md"
                    name="office"
                    value={office}
                    onChange={(e) => setOffice(e.target.value)}
                  >
                    {accountRulesTemplate !== undefined && accountRulesTemplate
                      ? accountRulesTemplate.allowedOffices &&
                        accountRulesTemplate.allowedOffices.map((off) => (
                          <option value={off.id} key={off.id}>
                            {off.name}
                          </option>
                        ))
                      : null}
                  </Select>
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Accounting rule name*</label>

                  <Input
                    style={{
                      // background: "#ffffff",
                      width: "60%",
                      color: "#727272",
                    }}
                    type="text"
                    className="mb-3"
                    placeholder="Enter accounting rule name"
                    name="accountingRuleName"
                    value={accountingRuleName}
                    onChange={(e) => setAccountingRuleName(e.target.value)}
                  />
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Description</label>
                  <Textarea
                    size="md"
                    style={{ width: "60%", fontSize: "12px" }}
                    resize={"none"}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </FormElements>
            </div>
            <div className="col-md-12 col-xl-6">
              <FormElements>
                {/* <label className="text-label">Affected GL entries*</label> */}
                <div>
                  <div className="d-flex form-group">
                    <div className="col-5 pl-3">
                      <p className="sub-title">
                        <span className="text-azure mr-1 ">
                          Debit Account details
                        </span>
                      </p>
                    </div>
                    <div className="col-7 d-flex m-0 p-0 align-items-center pl-3">
                      <hr />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between pl-3">
                    <label className="text-label">Debit rule type</label>
                    <div style={{ width: "60%" }}>
                      <RadioGroup
                        onChange={(e) => setDebitRadio(e)}
                        value={debitRadio}
                      >
                        <Stack
                          spacing={5}
                          direction="row"
                          style={{ justifyContent: "center" }}
                        >
                          <Radio colorScheme="blue" value="1" >
                            <span className="text-label"> Fixed account</span>
                          </Radio>
                          <Radio colorScheme="blue" value="2">
                            <span className="text-label">
                              List of tags{" "}
                            </span>
                          </Radio>
                        </Stack>
                      </RadioGroup>

                      {debitRadio === "1" && (
                        <div className="justify-content-center d-flex mt-2 select-100-width">
                          <Select
                            placeholder="Select Debit Account"
                            size="sm"
                            layerStyle={{ width: "70%" }}
                            name="debitFixedAccount"
                            value={debitFixedAccount}
                            onChange={(e) =>
                              setDebitFixedAccount(e.target.value)
                            }
                          >
                            {accountRulesTemplate !== undefined &&
                            accountRulesTemplate
                              ? accountRulesTemplate.allowedAccounts &&
                                accountRulesTemplate.allowedAccounts.map(
                                  (off) => (
                                    <option value={off.id} key={off.id}>
                                      {off.name}
                                    </option>
                                  )
                                )
                              : null}
                          </Select>
                        </div>
                      )}
                    </div>
                  </div>
                  {debitRadio === "3" && (
                    <div className="col-md-12">
                      {selectedDebitRole.length
                        ? selectedDebitRole.map((item, key) => (
                            <TagButton
                              onClick={() =>
                                setSelectedDebitRole(
                                  selectedDebitRole.filter(
                                    (val) => val.id !== item.id
                                  )
                                )
                              }
                              key={key}
                            >
                              {item.name}
                              <img
                                src={
                                  require("../../../../../assets/images/icons/cancel-tags.svg")
                                    .default
                                }
                                alt="cancel icon"
                                className="ml-3"
                              />
                            </TagButton>
                          ))
                        : null}
                    </div>
                  )}
                </div>

                <div>
                  <div className="d-flex form-group">
                    <div className="col-5 pl-3">
                      <p className="sub-title">
                        <span className="text-azure mr-1 ">
                          Credit Account details
                        </span>
                      </p>
                    </div>
                    <div className="col-7 d-flex m-0 p-0 align-items-center">
                      <hr />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between pl-3">
                    <label className="text-label">Credit rule type</label>
                    <div style={{ width: "60%" }}>
                      <RadioGroup
                        onChange={(e) => setCreditRadio(e)}
                        value={creditRadio}
                      >
                        <Stack
                          spacing={5}
                          direction="row"
                          style={{ justifyContent: "center" }}
                        >
                          <Radio colorScheme="blue" value="1">
                            <span className="text-label"> Fixed account</span>
                          </Radio>
                          <Radio colorScheme="blue" value="2">
                            <span className="text-label">
                              List of tags{" "}
                            </span>
                          </Radio>
                        </Stack>
                      </RadioGroup>

                      {creditRadio === "1" && (
                        <div className="justify-content-center d-flex mt-2 select-100-width">
                          <Select
                            placeholder="Select Credit Account"
                            size="sm"
                            layerStyle={{ width: "70%" }}
                            name="creditFixedAccount"
                            value={creditFixedAccount}
                            onChange={(e) =>
                              setCreditFixedAccount(e.target.value)
                            }
                          >
                            {accountRulesTemplate !== undefined &&
                            accountRulesTemplate
                              ? accountRulesTemplate.allowedAccounts &&
                                accountRulesTemplate.allowedAccounts.map(
                                  (off) => (
                                    <option value={off.id} key={off.id}>
                                      {off.name}
                                    </option>
                                  )
                                )
                              : null}
                          </Select>
                        </div>
                      )}
                    </div>
                  </div>
                  {creditRadio === "3" && (
                    <div className="col-md-12">
                      {selectedCreditRole.length
                        ? selectedCreditRole.map((item, key) => (
                            <TagButton
                              onClick={() =>
                                setSelectedCreditRole(
                                  selectedCreditRole.filter(
                                    (val) => val.id !== item.id
                                  )
                                )
                              }
                              key={key}
                            >
                              {item.name}
                              <img
                                src={
                                  require("../../../../../assets/images/icons/cancel-tags.svg")
                                    .default
                                }
                                alt="cancel icon"
                                className="ml-3"
                              />
                            </TagButton>
                          ))
                        : null}
                    </div>
                  )}
                </div>
              </FormElements>
            </div>
          </div>

          <div className="row">
            <div className="button-right">
              <ActionButton
                color="#2C1DFF"
                className="float-right"
                style={{ margin: "0px 14px 0px 25px" }}
                onClick={handleSubmitAccountingRule}
              >
                <img
                  src={
                    require("../../../../../assets/images/icons/check-mark.svg")
                      .default
                  }
                  alt="mark icon"
                />
                Submit
              </ActionButton>
              <Link to="/core/accounting/rules">
                <ActionButton
                  color="#2C1DFF"
                  className="float-right"
                  style={{ margin: "0px 0px" }}
                  // onClick={}
                >
                  <img
                    src={
                      require("../../../../../assets/images/icons/cancel.svg")
                        .default
                    }
                    alt="add icon"
                  />
                  Cancel
                </ActionButton>
              </Link>
            </div>
          </div>
        </ContentWrapper>
      </div>
    </>
  );
};

export default CreateAccountRule;
