import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import TellerCard from "../components/TellerCard/index";
import styled from "styled-components";
import { Image } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { formatCurrency } from "../../../../util/FormatAmount";
import { Table } from "antd";
import CheckPermission from "../../../../util/CheckPermission";
import { truncateString } from "../../../../util/FormatText";
import {
  getSingleTellerGroupCashier,
  getAllCashierActionRequests,
  getAllCashierTransactions,
  resetAllCashierActions,
} from "../../../../actions/tellerActions";
import { AuditModal, TransactionModal } from "../components/modal/Audit";

import { useDisclosure } from "@chakra-ui/hooks";
import { ReactComponent as Search } from "../../../../assets/images/icons/search.svg";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  InputGroup,
  InputLeftElement,
  Input,
  Stack,
} from "@chakra-ui/react";

const SingleTeller = () => {
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [showTransactionModal, setTransactionModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [transactionData, setTransactionData] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [pagination1, setPagination1] = useState({
    current: 1,
    pageSize: 10,
  });
  const [activePagination, setActivePagination] = useState();
  const [activePagination1, setActivePagination1] = useState();
  const [myActionTrailData, setMyActionTrailData] = useState([]);
  const [myCashierTrailData, setMyCashierTrailData] = useState([]);

  const { id } = useParams();
  const dispatch = useDispatch();
  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;
  const tellerGroupReducer = useSelector((state) => state.tellerReducer);
  const {
    singleTellerGroupCashiers,
    cashierTransactions,
    allCashierActionRequest,
  } = tellerGroupReducer;

  const {
    totalFilteredRecords,
    totalItems,
    numberOfPages,
    pageItems = [],
  } = cashierTransactions || {};

  const { ...paginationProperties } = allCashierActionRequest || {};

  useEffect(() => {
    dispatch(resetAllCashierActions());
    let data =
      singleTellerGroupCashiers &&
      !!singleTellerGroupCashiers.cashiers?.length &&
      singleTellerGroupCashiers?.cashiers[0];
    setActiveTab(data?.id);
  }, []);

  const close = () => {
    setTransactionModal(false);
  };

  useEffect(() => {
    if (CheckPermission("READ_CASHIERTRANSACTION", permissions)) {
      dispatch(getSingleTellerGroupCashier(id));
      return dispatch(resetAllCashierActions());
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (CheckPermission("READ_CASHIERTRANSACTION", permissions)) {
      dispatch(resetAllCashierActions());
      if (activeTab) {
        dispatch(
          getAllCashierActionRequests(
            `cashierId=${activeTab}&offset=0&limit=10&sortOrder=DESC`
          )
        );
        dispatch(
          getAllCashierTransactions(
            `?cashierId=${activeTab}&offset=0&limit=10&sortOrder=DESC`
          )
        );
      }
    }
  }, [activeTab, dispatch]);

  useEffect(() => {
    if (!!cashierTransactions && !!cashierTransactions.length) {
      setMyCashierTrailData(cashierTransactions);
    } else if (!!pageItems.length) {
      setMyCashierTrailData(pageItems);
    }
  }, [pageItems, cashierTransactions]);

  useEffect(() => {
    if (!!allCashierActionRequest && !!allCashierActionRequest.length) {
      setMyActionTrailData(allCashierActionRequest);
    } else if (!!paginationProperties?.pageItems?.length) {
      setMyActionTrailData(paginationProperties?.pageItems);
    }
  }, [paginationProperties?.pageItems, allCashierActionRequest]);

  useEffect(() => {
    if (totalFilteredRecords !== 0 && totalFilteredRecords !== 1) {
      setPagination({
        current: activePagination,
        pageSize: 10,
        total: totalFilteredRecords,
      });
    } else if (totalItems === 0 && numberOfPages === 0) {
      setPagination(false);
    } else if (!!cashierTransactions && !!cashierTransactions.length) {
      setPagination({ defaultPageSize: 10 });
    }
  }, [
    totalItems,
    numberOfPages,
    totalFilteredRecords,
    activePagination,
    cashierTransactions,
  ]);

  useEffect(() => {
    if (
      paginationProperties.totalFilteredRecords !== 0 &&
      paginationProperties.totalFilteredRecords !== 1
    ) {
      setPagination1({
        current: activePagination1,
        pageSize: 10,
        total: paginationProperties.totalFilteredRecords,
      });
    } else if (
      paginationProperties.totalItems === 0 &&
      paginationProperties.numberOfPages === 0
    ) {
      setPagination1(false);
    } else if (!!allCashierActionRequest && !!allCashierActionRequest.length) {
      setPagination1({ defaultPageSize: 10 });
    }
  }, [
    paginationProperties.totalItems,
    paginationProperties.numberOfPages,
    paginationProperties.totalFilteredRecords,
    activePagination1,
    allCashierActionRequest,
  ]);

  const handleActionTableChange = (pagination, filters, sorter) => {
    setActivePagination1(pagination.current);
    if (!!allCashierActionRequest && !!allCashierActionRequest.length) {
      setPagination({
        current: activePagination1,
        pageSize: 10,
        total: allCashierActionRequest.length,
      });
    } else {
      let myOffsect = pagination.current * 10;
      let dataToSend = `cashierId=${activeTab}&limit=10&offset=${
        myOffsect - 10
      }&sortOrder=DESC`;
      dispatch(getAllCashierActionRequests(dataToSend));
    }
  };

  const handleCashierTableChange = (pagination, filters, sorter) => {
    setActivePagination(pagination.current);
    if (!!cashierTransactions && !!cashierTransactions.length) {
      setPagination1({
        current: activePagination,
        pageSize: 10,
        total: cashierTransactions.length,
      });
    } else {
      let myOffsect = pagination.current * 10;
      let dataToSend = `?cashierId=${activeTab}&limit=10&offset=${
        myOffsect - 10
      }&sortOrder=DESC`;
      dispatch(getAllCashierTransactions(dataToSend));
    }
  };

  let dataSource = [];
  let cashierTransaction = [];

  // let sortedCashierTransaction = [];

  // console.log(myCashierTrailData, "myCashierTrailData");
  !!myCashierTrailData &&
    myCashierTrailData
      .forEach((cashierTrailData) => {
        const { cashierTransactionData } = cashierTrailData;
        cashierTransactionData?.forEach((cashierTransactions) => {
          const {
            cashierFirstName,
            cashierLastName,
            transactionAmount,
            transactionNote,
            transactionType: { value },
            transactionDate,
            runningBalanceDerived,
            cashierTransactionId,
            cashierActionId,
          } = cashierTransactions;
          
          cashierTransaction.push({
            key: cashierTransactionId,
            name: cashierFirstName + " " + cashierLastName,
            cashierFirstName,
            cashierLastName,
            transactionAmount: `NGN ${transactionAmount}`,
            transactionNote,
            type: `${value} CASH`,
            transactionDate:
              transactionDate[2] +
              "/" +
              transactionDate[1] +
              "/" +
              transactionDate[0],
            runningBalanceDerived,
            cashierTransactionId,
            cashierActionId,
          });
        });
      })
     

  !!myActionTrailData &&
    myActionTrailData.forEach((eachData) => {
      const {
        id,
        amount,
        actionStatus,
        actionRequestTimelineData,
        actionPersonaType,
        currencyData,
        requestNote,
        rejectedNote,
        approvedNote,
        linkedCashierFirstname,
        linkedCashierLastname,
        savingsAccountNumber,
        clientDisplayName,
      } = eachData;
      dataSource.push({
        key: id,
        actionRequestTimelineData: actionRequestTimelineData,
        type:
          actionPersonaType && actionPersonaType?.value
            ? actionPersonaType.value
            : "",
        date:
          actionRequestTimelineData &&
          actionRequestTimelineData?.submittedOnDate
            ? actionRequestTimelineData?.submittedOnDate[2] +
              "/" +
              actionRequestTimelineData?.submittedOnDate[1] +
              "/" +
              actionRequestTimelineData?.submittedOnDate[0]
            : "",
        currencyData:
          currencyData && currencyData?.code ? currencyData.code : "",
        approvedNote: approvedNote,
        savingsAccountNumber,
        recipient:
          actionPersonaType?.value === "VAULT"
            ? "Vault"
            : actionPersonaType?.value === "CASHIER"
            ? linkedCashierFirstname + " " + linkedCashierLastname
            : clientDisplayName,
        status: actionStatus && actionStatus?.value ? actionStatus.value : "",
        rejectedNote,
        actionPersonaType,
        tellerName:
          actionRequestTimelineData &&
          actionRequestTimelineData?.submittedByFirstname
            ? truncateString(
                `${actionRequestTimelineData?.submittedByFirstname} ${actionRequestTimelineData?.submittedByLastname}`,
                18
              )
            : "",
        amount:
          currencyData && currencyData?.code
            ? `${currencyData?.code} ${formatCurrency(amount)}`
            : `${formatCurrency(amount)}`,
        description: requestNote ? truncateString(requestNote, 50) : "",
      });
    });

  // Convert the date string to raw Date so we can sort, after sorting we reverse it.
  const cashierTransactionSorted = cashierTransaction
    .sort((a, b) => {
      var c = new Date(a.transactionDate);
      var d = new Date(b.transactionDate);
      return c - d;
    })
    .reverse();

  // console.log(cashierTransactionSorted, "cashierTransactionSorted");
  // console.log(cashierTransaction, "cashierTransaction");

  const dataSourceSorted = dataSource
    .sort((a, b) => {
      var c = new Date(a.date);
      var d = new Date(b.date);
      return c - d;
    })
    .reverse();

  //  const sortedCashierTransaction =  cashierTransaction?.sort((a, b) => new Date(b.transactionDate) - new Date(a.transactionDate));


  // console.log(dataSourceSorted, "dataSourceSorted");
  // console.log(sortedCashierTransaction, "sortedCashierTransaction");

  const columns = [
    {
      title: "Teller Name",
      dataIndex: "tellerName",
      key: "tellerName",
      width: 80,
      ellipsis: true,
    },
    {
      title: " Type",
      dataIndex: "type",
      key: "type",
      width: 80,
      ellipsis: true,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: 100,
      ellipsis: true,
    },
    {
      title: "Recipient",
      dataIndex: "recipient",
      key: "recipient",
      width: 80,
      ellipsis: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 140,
      ellipsis: true,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: 80,
      ellipsis: true,
    },
    {
      title: " Status",
      dataIndex: "status",
      key: "status",
      width: 80,
      ellipsis: true,
    },
  ];
  const transactionColumn = [
    { title: "Name", dataIndex: "name", key: "name", width: 120,
    ellipsis: true, },
    {
      title: " Type",
      dataIndex: "type",
      key: "type",
      width: 120,
      ellipsis: true,
    },
    {
      title: "Amount",
      dataIndex: "transactionAmount",
      key: "transactionAmount",
      width: 120,
      ellipsis: true,
    },
    {
      title: "Transaction Date",
      dataIndex: "transactionDate",
      key: "transactionDate",
      width: 140,
      ellipsis: true,
    },
    {
      title: "Description",
      dataIndex: "transactionNote",
      key: "transactionNote",
      width: 140,
      ellipsis: true,
    },
  ];

  // console.log(cashierTransactions, "cashierTransactions");

  return (
    <div className="container-fluid">
      <AuditModal
        data={modalData}
        AuditModal
        isOpen={isOpen}
        onClose={onClose}
      />
      <TransactionModal
        data={transactionData}
        isOpen={showTransactionModal}
        onClose={close}
      />
      <div className="row justify-content-between">
        <div className="col-sm-12 col-md-12 col-lg-6 pb-3">
          <h3 className="page-title">
            {singleTellerGroupCashiers?.tellerName}
          </h3>
          <p className="text-muted sub-title">
            A complete overview of your business with analysis and data metrics
          </p>
          <div className="mt-2 font-weight sub-title">
            <LinkColor to="/core/teller-group" color>
              Teller management /{" "}
            </LinkColor>
            <LinkColor
              to={`/core/teller-group/${singleTellerGroupCashiers?.tellerId}`}
              color
            >
              {singleTellerGroupCashiers?.tellerName}{" "}
            </LinkColor>
            <span>{}</span>
          </div>
        </div>
      </div>
      {CheckPermission("READ_CASHIERTRANSACTION", permissions) && (
        <Container className="d-flex flex-column p-3 pt-5 pb-5">
          <StyledTabs orientation="vertical" variant="none" colorScheme="green">
            <StyledTabContainer>
              <Stack className="mt-4 mb-3">
                <InputGroup>
                  <InputLeftElement
                    height="35px"
                    pointerEvents="none"
                    children={<Search height="200px" />}
                  />
                  <Input type="search" placeholder="Search by name" />
                </InputGroup>
              </Stack>
              {singleTellerGroupCashiers &&
                !!singleTellerGroupCashiers.cashiers?.length &&
                singleTellerGroupCashiers?.cashiers?.map((each) => {
                  return (
                    <StyledTab
                      isActive={activeTab === each.id ? true : false}
                      onClick={() => {
                        setActiveTab(each?.id);
                      }}
                    >
                      <TellerCard
                        noMore
                        key={each?.id}
                        officeName={singleTellerGroupCashiers?.officeName}
                        data={each}
                        noBorder
                        reducedHeight
                      />
                    </StyledTab>
                  );
                })}
            </StyledTabContainer>
            <Tabs isFitted>
              <TabList mb="1em">
                <Tab className="font-weight-bold">Action Trail</Tab>
                <Tab className="font-weight-bold">Cashier Transactions</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  {!allCashierActionRequest?.pageItems?.length ? (
                    <EmptyDiv>
                      {/* <Image
                        boxSize="50px"
                        objectFit="cover"
                        src={
                          require("../../../../assets/images/empty.svg").default
                        }
                        alt="Dan Abramov"
                      /> */}
                      <p>No action available for this teller.</p>
                      {!!!singleTellerGroupCashiers?.cashier?.length}
                    </EmptyDiv>
                  ) : (
                    <>
                      {
                        <Table
                          onRow={(r) => ({
                            onClick: () => {
                              onOpen();
                              return setModalData(r);
                            },
                          })}
                          //first
                          columns={columns}
                          dataSource={dataSourceSorted}
                          pagination={pagination1}
                          defaultPageSize={"10"}
                          onChange={handleActionTableChange}
                        />
                      }
                    </>
                  )}
                </TabPanel>
                <TabPanel>
                  {cashierTransactions?.length === "0" ? (
                    <EmptyDiv>
                      <Image
                        boxSize="100px"
                        objectFit="cover"
                        src={
                          require("../../../../assets/images/empty.svg").default
                        }
                        alt="Dan Abramov"
                      />
                      <p>No transactions available for this teller.</p>
                    </EmptyDiv>
                  ) : (
                    <>
                      {
                        <Table
                          onRow={(r) => ({
                            onClick: () => {
                              setTransactionModal(true);
                              return setTransactionData(r);
                            },
                          })}
                          //second
                          columns={transactionColumn}
                          dataSource={cashierTransactionSorted}
                          // dataSource={sortedCashierTransaction}
                          pagination={pagination}
                          defaultPageSize={"10"}
                          onChange={handleCashierTableChange}
                        />
                      }
                    </>
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </StyledTabs>
        </Container>
      )}
    </div>
  );
};

export default SingleTeller;

const LinkColor = styled(Link)`
  color: #2c1dff;
`;
const StyledTab = styled(Tab)`
  padding: 0px !important;
  color: #2c1dff;
  margin-bottom: 25px;
  border-radius: 10px;
  /* border: ${(props) =>
    props?.isActive === true ? "2px solid #2C1DFF" : ""}; */
  transform: ${(props) => (props?.isActive === true ? "scale(1.07)" : "")};
`;
const StyledTabContainer = styled(TabList)`
  border-right: 2px solid #c9ced6;
  padding: 0px 50px 0 25px;
`;

const Container = styled.div`
  display: flex;
  background-color: #fff;
  border-radius: 5px;
  min-height: 400px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
`;

const EmptyDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;

  p {
    margin-top: 30px;
  }
`;

const StyledTabs = styled(Tabs)`
  display: grid !important;
  grid-template-columns: 2fr 5fr;
`;
