import styled from "styled-components";

export const ActionButtonsGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

export const ActionButton = styled.button`
  background: #2C1DFF !important;
  border-radius: 5px !important;
  color: #fff !important;
  margin: 0 10px;
  border: none;
  display: inline;
  font-family: 'AventaMedium';
  font-size: 14px;
  height: 35px;
  max-width: 200px;
  min-width: 180px;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  img {
    height: 14px;
    display: inline;
    margin-right: 10px;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;

export const ModalText = styled.p`
  font-size: 18px;
  color: #000000;
  margin: 42px 0 32px 0;
`;

export const EditButton = styled.button`
  background: #2C1DFF !important;
  border-radius: 5px !important;
  color: #fff !important;
  border: none;
  display: inline;
  font-size: 14px;
  height: 30px;
  width: 78px;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
  }
`;

export const ContentWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 0px #ededf6;
  margin-top: 20px;
  table {
    font-size: 14px;
  }
  .css-42b2qy {
    width: 70% !important;
  }
`;

export const FormClass = styled.div``;

export const FormElements = styled.div`
  width: 90%;
  margin-top: 30px;
  margin-bottom: 30px;
  display: block;

  label {
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 22px;
    color: #969cba;
  }
`;
