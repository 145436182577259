import React, { useState, useEffect } from "react";
import {
  Select,
  Textarea,
  Input,
  Radio,
  RadioGroup,
  Stack,
  FormControl,
  Checkbox,
} from "@chakra-ui/react";
import { FormElements, ActionButton } from "../../../styles";
import { ContentWrapper, RoleButton, TagButton } from "./styles";
import {
  updateAccountongRule,
  getAllAccountRulesTemplate,
} from "../../../../../../actions/accountingActions";
import { useDispatch, useSelector } from "react-redux";
import Toast from "../../../../../../util/Toast";
import ModalContainer from "../../../../components/ModalContainer";
import CreditRuleModal from "../../Modals/CreditRuleModal";
import DebitRuleModal from "../../Modals/DebitRuleModal";
import { Modal } from "react-bootstrap";

const ViewAccountRule = ({ history, selectedRule, onHide, accountRulesTemplate }) => {
  const [modalState, toggleModal] = useState(false);
  const [modalContentName, setModalContentName] = useState("");
  const [office, setOffice] = useState(
    selectedRule ? selectedRule.officeId : ""
  );

  const [accountingRuleName, setAccountingRuleName] = useState(
    selectedRule ? selectedRule.name : ""
  );
  const [debitFixedAccount, setDebitFixedAccount] = useState(
    selectedRule && selectedRule.debitAccounts
      ? selectedRule.debitAccounts[0].id
      : ""
  );
  const [selectedDebitRole, setSelectedDebitRole] = useState(
    selectedRule && selectedRule.debitTags
      ? selectedRule.debitTags.map((e) => ({
          ...e.tag,
        }))
      : []
  );
  const [debitRadio, setDebitRadio] = useState(
    selectedRule && selectedRule.debitTags ? "3" : "1"
  );
  const [debitEntries, setDebitEntries] = useState(
    selectedRule ? selectedRule.allowMultipleDebitEntries : false
  );
  const [creditFixedAccount, setCreditFixedAccount] = useState(
    selectedRule && selectedRule.creditAccounts
      ? selectedRule.creditAccounts[0].id
      : ""
  );
  const [selectedCreditRole, setSelectedCreditRole] = useState(
    selectedRule && selectedRule.creditTags
      ? selectedRule.creditTags.map((e) => ({
          ...e.tag,
        }))
      : []
  );
  const [creditRadio, setCreditRadio] = useState(
    selectedRule && selectedRule.creditTags ? "3" : "1"
  );
  const [creditEntries, setCreditEntries] = useState(
    selectedRule ? selectedRule.allowMultipleCreditEntries : false
  );
  const [description, setDescription] = useState(
    selectedRule ? selectedRule.description : ""
  );
  const dispatch = useDispatch();


  let dataTosubmit = {
    officeId: Number(office),
    name: accountingRuleName,
    accountToCredit: Number(creditFixedAccount),
    accountToDebit: Number(debitFixedAccount),
    description,
    allowMultipleDebitEntries: debitEntries,
    allowMultipleCreditEntries: creditEntries,
    creditTags:
      selectedCreditRole.length > 0
        ? selectedCreditRole.map((item) => Number(item.id))
        : [],
    debitTags:
      selectedDebitRole.length > 0
        ? selectedDebitRole.map((item) => Number(item.id))
        : [],
  };



  const handleModalClose = () => {
    setCreditRadio("3");
    setDebitRadio("3");
    toggleModal(false);
  };

  const displayModalContent = () => {
    switch (modalContentName) {
      case "creditrulemodal":
        return (
          <CreditRuleModal
            allData={accountRulesTemplate?.allowedCreditTagOptions}
            onHide={handleModalClose}
            setSelectedCreditRole={setSelectedCreditRole}
            selectedCreditRole={selectedCreditRole}
          />
        );
      case "debitrulemodal":
        return (
          <DebitRuleModal
            allData={accountRulesTemplate?.allowedDebitTagOptions}
            onHide={handleModalClose}
            setSelectedDebitRole={setSelectedDebitRole}
            selectedDebitRole={selectedDebitRole}
          />
        );
      default:
        return;
    }
  };

  useEffect(() => {
    // run something every time name changes
    if (debitRadio === "2") {
      toggleModal(true);
      setModalContentName("debitrulemodal");
    } else if (creditRadio === "2") {
      toggleModal(true);
      setModalContentName("creditrulemodal");
    }
  }, [debitRadio, creditRadio]);

  return (
    <>
      <ModalContainer show={modalState} dialogClassName="document_infoModal">
        {modalState && displayModalContent()}
      </ModalContainer>
      <Modal.Header>
        <Modal.Title>View Rule</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-fluid">
          <ContentWrapper>
            <div className="row h-100">
              <div className="col-md-12">
                <FormElements>
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Account Office</label>
                    <Select
                      placeholder="Select Office"
                      size="md"
                      disabled
                      name="office"
                      value={office}
                      onChange={(e) => setOffice(e.target.value)}
                    >
                      {accountRulesTemplate !== undefined &&
                      accountRulesTemplate
                        ? accountRulesTemplate.allowedOffices &&
                          accountRulesTemplate.allowedOffices.map((off) => (
                            <option value={off.id} key={off.id}>
                              {off.name}
                            </option>
                          ))
                        : null}
                    </Select>
                  </div>
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Accounting rule name*</label>

                    <Input
                      style={{
                        // background: "#ffffff",
                        width: "60%",
                        color: "#727272",
                      }}
                      type="text"
                      disabled
                      className="mb-3"
                      placeholder="Enter accounting rule name"
                      name="accountingRuleName"
                      value={accountingRuleName}
                      onChange={(e) => setAccountingRuleName(e.target.value)}
                    />
                  </div>
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Description</label>
                    <Textarea
                      size="md"
                      disabled
                      style={{ width: "60%", fontSize: "12px" }}
                      resize={"none"}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                </FormElements>
              </div>
              <div className="col-md-12">
                <FormElements>
                  <label className="text-label">Affected GL entries*</label>
                  <div>
                    <div className="d-flex form-group">
                      <div className="col-6 p-0">
                        <p className="sub-title">
                          <span className="text-azure mr-1 ">
                            DEBIT ACCOUNT DETAILS
                          </span>
                        </p>
                      </div>
                      <div className="col-6 d-flex m-0 p-0 align-items-center">
                        <hr />
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <label className="text-label">Debit rule type</label>
                      <div style={{ width: "60%" }}>
                        <RadioGroup value={debitRadio}>
                          <Stack
                            spacing={5}
                            direction="row"
                            style={{ justifyContent: "center" }}
                          >
                            <Radio colorScheme="blue" value="1" disabled>
                              <span className="text-label"> Fixed account</span>
                            </Radio>
                            <Radio colorScheme="blue" value="2" disabled>
                              <span className="text-label">List of tags</span>
                            </Radio>
                          </Stack>
                        </RadioGroup>

                        {debitRadio === "1" && (
                          <div className="justify-content-center d-flex mt-2 select-100-width">
                            <Select
                              placeholder={selectedRule?.debitAccounts[0].name}
                              size="sm"
                              disabled
                              layerStyle={{ width: "70%" }}
                              name="debitFixedAccount"
                              value={debitFixedAccount}
                              onChange={(e) =>
                                setDebitFixedAccount(e.target.value)
                              }
                            >
                              {accountRulesTemplate !== undefined &&
                              accountRulesTemplate
                                ? accountRulesTemplate.allowedAccounts &&
                                  accountRulesTemplate.allowedAccounts.map(
                                    (off) => (
                                      <option value={off.name} key={off.id}>
                                        {off.name}
                                      </option>
                                    )
                                  )
                                : null}
                            </Select>
                          </div>
                        )}
                      </div>
                    </div>
                    {debitRadio === "3" && (
                      <div className="col-md-12 p-0">
                        {selectedDebitRole.length
                          ? selectedDebitRole.map((item, key) => (
                              <TagButton key={key}>{item.name}</TagButton>
                            ))
                          : null}
                      </div>
                    )}
                  </div>

                  <div>
                    <div className="d-flex form-group">
                      <div className="col-6 p-0">
                        <p className="sub-title">
                          <span className="text-azure mr-1 ">
                            CREDIT ACCOUNT DETAILS
                          </span>
                        </p>
                      </div>
                      <div className="col-6 d-flex m-0 p-0 align-items-center">
                        <hr />
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <label className="text-label">Credit rule type</label>
                      <div style={{ width: "60%" }}>
                        <RadioGroup value={creditRadio}>
                          <Stack
                            spacing={5}
                            direction="row"
                            style={{ justifyContent: "center" }}
                          >
                            <Radio colorScheme="blue" value="1" disabled>
                              <span className="text-label"> Fixed account</span>
                            </Radio>
                            <Radio colorScheme="blue" value="2" disabled>
                              <span className="text-label">List of tags</span>
                            </Radio>
                          </Stack>
                        </RadioGroup>

                        {creditRadio === "1" && (
                          <div className="justify-content-center d-flex mt-2 select-100-width">
                            <Select
                              placeholder={selectedRule?.creditAccounts[0].name}
                              size="sm"
                              disabled
                              layerStyle={{ width: "70%" }}
                              name="creditFixedAccount"
                              value={creditFixedAccount}
                              onChange={(e) =>
                                setCreditFixedAccount(e.target.value)
                              }
                            >
                              {accountRulesTemplate !== undefined &&
                              accountRulesTemplate
                                ? accountRulesTemplate.allowedAccounts &&
                                  accountRulesTemplate.allowedAccounts.map(
                                    (off) => (
                                      <option value={off.name} key={off.id}>
                                        {off.name}
                                      </option>
                                    )
                                  )
                                : null}
                            </Select>
                          </div>
                        )}
                      </div>
                    </div>
                    {creditRadio === "3" && (
                      <div className="col-md-12 p-0">
                        {selectedCreditRole.length
                          ? selectedCreditRole.map((item, key) => (
                              <TagButton key={key}>{item.name}</TagButton>
                            ))
                          : null}
                      </div>
                    )}
                  </div>
                </FormElements>
              </div>
            </div>
          </ContentWrapper>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="row">
          <div className="button-right">
            <ActionButton
              color="#2C1DFF"
              className="float-right"
              onClick={onHide}
            >
              <img
                src={
                  require("../../../../../../assets/images/icons/cancel.svg")
                    .default
                }
                alt="add icon"
              />
              Cancel
            </ActionButton>
          </div>
        </div>
      </Modal.Footer>
    </>
  );
};

export default ViewAccountRule;
