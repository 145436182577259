export const columns = [
	{
		title: "Address",
		dataIndex: "address",
	},
	{
		title: "Address Line",
		dataIndex: "addressLine1",
	},
	{
		title: "City",
		dataIndex: "city",
	},
	{
		title: "Country",
		dataIndex: "country",
	},
	{
		title: "Active",
		dataIndex: "isActive",
	},
];

export const months = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"Jun",
	"Jul",
	"Aug",
	"Sep",
	"Oct",
	"Nov",
	"Dec",
];