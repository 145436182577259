import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContentWrapper, GeneratedReportContainer, IconButton } from "./styles";
import { Table } from "antd";
import { getReports } from "../../../actions/reportActions";
import { formatDate, formatTime } from "../../../util/FormatDate";
import Countdown from "react-countdown";
import ModalContainer from "../components/ModalContainer";
import ReportDetailsModal from "./ReportDetailsModal";
import styled from "styled-components";
import { ReportCard } from "./ReportCard";

const ReportView = ({ history, repData }) => {
  const [modalState, toggleModal] = useState(false);
  const [currentReport, setCurrentReport] = useState({});
  const [dataToMap, setDataToMap] = useState([]);
  const [allGeneratedReports, setAllGeneratedReports] = useState([]);
  const dispatch = useDispatch();

  const reportData = useSelector((state) => state.reportData);

  let {
    allReports = [],
    generatedReport = {},
    generatingReports = [],
  } = reportData || {};

  
  let { columnHeaders = [], data = [] } = generatedReport || {};
  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Category", dataIndex: "category", key: "category" },
  ];

  let reportTableData = [];
  let letdata = [];

  data.map((mydata, index) => {

    let { row } = mydata;
    let myExcelArray = {};
    columnHeaders.map((header, index) => {
      myExcelArray[header.columnName] = row[index];
    });
    letdata.push(myExcelArray);
  });

  useEffect(() => {
    dispatch(getReports());
  }, []);

  useEffect(() => {
    generatingReports && setAllGeneratedReports(generatingReports);
  }, [generatingReports]);


  !!dataToMap &&
    !!dataToMap.length &&
    dataToMap.forEach((report) => {
      const { reportName = "", reportCategory = "" } = report;

      reportTableData.push({
        name: reportName && reportName === "C Over" ? "Call Over Report" : reportName ,
        category: reportCategory,
        report,
      });
    });
  useEffect(() => {
    let newData = allReports?.filter(
      (eachReport) => eachReport?.reportCategory === repData?.filter
    );
    setDataToMap(newData);
  }, [allReports]);


  const handleOnHide = () => {
    toggleModal(false);
  };

  return (
    <>
      <ModalContainer show={modalState} dialogClassName="document_infoModal">
        {modalState && (
          <ReportDetailsModal
            onHide={handleOnHide}
            currentReport={currentReport}
          />
        )}
      </ModalContainer>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">{repData?.name}</h3>
            <p className="sub-title">{repData?.overview}</p>
          </div>
        </div>
        <ContentWrapper>
          <Table
            columns={columns}
            dataSource={reportTableData}
            pagination={{ defaultPageSize: 8 }}
            defaultPageSize={8}
            onRow={(r) => ({
              onClick: () => {
                setCurrentReport(r);
                toggleModal(true);
              },
            })}
          />
          <GeneratedReportContainer>
            <p className="heading">Generated reports <br /><span className="text-muted fs-11">in this session</span></p>
            <hr className="mb-2" />
            {allGeneratedReports?.length > 0
              ? allGeneratedReports
                ?.sort((a, b) => b?.generatedOn - a?.generatedOn)
                ?.filter((report) => report?.reportCategory === repData?.filter)
                ?.map((report) => (
                  <ReportCard
                    key={report?.id}
                    report={report}
                    setAllGeneratedReports={setAllGeneratedReports}
                  />
                ))
              : (
              <div className="d-flex flex-column justify-content-center align-items-center m-auto">
                <img
                  src={
                    require("../../../assets/images/icons/empty-report.svg")
                      .default
                  }
                  alt="download"
                  width={50}
                  height={50}
                />
                <p className="center-text mt-3">
                  You have no generated reports yet!
                </p>
              </div>
            )}
          </GeneratedReportContainer>
        </ContentWrapper>
      </div>
    </>
  );
};

export default ReportView;
