import styled from "styled-components";

export const FormTitile = styled.h1`
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 29px;
  text-align: center;
  color: #141414;
`;

export const FormSubTitile = styled.p`
  font-size: 0.813rem;
  font-style: normal;
  line-height: 20px;
  padding: 2.5rem 0;
  text-align: center;
  color: #969CBA;
`;

export const FormInput = styled.input`
  box-sizing: border-box;
  border-radius: 2px;
  transition: border-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
`;

export const FormButton = styled.button`
  height: 40px;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
  width: 100%;
`;

export const OTPTitle = styled.h2`
  //
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 29px;
  color: #141414;
  text-align: center;
  padding-bottom: 1rem;
`;

export const OTPSubTitile = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 0.813rem;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.035em;
  color: ${(props) => (props.color ? props.color : "#666666")};
  margin-bottom: 25px;
`;

export const OTPCountdown = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 0.813rem;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.035em;
  color: #2C1DFF;
`;

export const OTPDigitsFormContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0 20px;
  input {
    background: #ffffff;
    width: 50px;
    height: 50px;
    border: 1px solid rgba(150, 156, 186, 0.2);
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 3px;
    &:focus {
      box-shadow: none !important;
    }
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

export const OTPResendTitle = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height, or 154% */
  text-align: center;
  letter-spacing: 0.035em;
  color: #666666;
  margin-bottom: 40px;
  a {
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.035em;
    color: #2C1DFF;
    text-decoration: none;
  }
`;

