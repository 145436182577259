import React, { useState, useEffect, useRef } from "react";
import {
  Heading,
  FlexContainer,
  OfficeTag,
  ActionButton,
  ButtonGroup,
  ChartCard,
  StatContainer,
  DownloadButtonsGroup,
} from "../styles";
import Statistics from "../components/Statistics";
import { Line } from "@reactchartjs/react-chart.js";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllCashierTransactions,
  getCashierData,
  getCashierDataTeller,
} from "../../../actions/tellerActions";
import { generateTelReport } from "../../../actions/reportActions";
import { SET_GENERATING_REPORTS } from "../../../constants/reportsConstants";
import { getAReportStatus } from "../../../services/Api/report.api.services";
import { Select } from "antd";
// import DropdownCurrency from "./DropdownCurrency";
// import { updateDropdownValue } from "../../../actions/utilityActions";
// import { useHistory } from "react-router-dom";
// import { loginUser } from "../../../actions/authActions";
import { getTransactionSummary } from "../../../actions/tellerActions";

// generateTelReport
const Overview = () => {
  const dispatch = useDispatch();
  // const history = useHistory();

  const { summary } = useSelector((state) => state.tellerReducer);

  let cashierId = localStorage.getItem("cashierId");
  let tellerGroupId = localStorage.getItem("tellerGroupId");

  useEffect(() => {
    if (tellerGroupId && cashierId) {
      dispatch(getTransactionSummary(tellerGroupId, cashierId));
    }
  }, [cashierId, dispatch, tellerGroupId]);


  const cashierData = useSelector((state) => state.cashierData);
  const cashierDataTeller = useSelector((state) => state.cashierDataTeller);
  // const sample = useSelector((state) => console.log(state));

  const { currencies = [], defaultCurrency = {} } =
    cashierData?.cashierDataDetails || {};
  // const {
  //   userDetails: {
  //     userDetails: { currencies = [], defaultCurrency = {} } = {},
  //   },
  // } = userDetails || {};
  // const { userDetails } = useSelector((state) => state.userDetails);


  let currentCashierId = localStorage.getItem("cashierId");

  let tellerIdNew = localStorage.getItem("tellerGroupId");

  // const { cashierDataDetails } = cashierDataDetails || {};


  const {
    userData: { username, officeName },
  } = useSelector((state) => state.userData);

  const { cashierTransactions } = useSelector((state) => state.tellerReducer);
  const reportData = useSelector((state) => state.reportData);

  let { generatingReports = [] } = reportData || {};

  let { Option } = Select;


  useEffect(() => {
    if (currentCashierId && tellerIdNew) {
      dispatch(getCashierData(tellerIdNew, currentCashierId));
    }
  }, [currentCashierId, tellerIdNew]);


  const defaultCurrencyValue = defaultCurrency.code;

  const local = localStorage.getItem("myDefaultValue");

  const [dropdownValue, setDropdownValue] = useState("");
  const [currencyOptions, setCurrencyOptions] = useState([]);

  useEffect(() => {
    setCurrencyOptions(currencies);
  }, [currencies]);

  useEffect(() => {
    setDropdownValue(local || defaultCurrencyValue);
  }, [defaultCurrencyValue, local]);

  useEffect(() => {
    if (dropdownValue && currentCashierId && tellerIdNew) {
      dispatch(getCashierDataTeller(tellerIdNew,dropdownValue, currentCashierId));
    }
  }, [dropdownValue ,currentCashierId, tellerIdNew ])
  // const [currencyOptionsDrop , setCurrencyOptionsDrop] = useState([])

  const [effect, setEffect] = useState();

  useEffect(() => {
    let canvas = document.getElementById("canvas");
    if (canvas) {
      let cvx = canvas.getContext("2d");
      let gradient = cvx.createLinearGradient(0, 0, 0, 400);
      gradient.addColorStop(0, "#01B1FC");
      gradient.addColorStop(0.8, "#FFFFFF");
      setEffect(gradient);
    }

    if (!!generatingReports && !!generatingReports.length) {
      setInterval(function () {
        generatingReports.forEach((report, index) => {
          let { code, status, reportName } = report;
          if (!!code && status === "received") {
            getAReportStatus(code)
              .then((response) => {
                if (!!response && !!response.file) {
                  let hhh = [...generatingReports];
                  hhh[Number(index)].status = "Completed";
                  hhh[Number(index)].file = response.file;
                  dispatch({ type: SET_GENERATING_REPORTS, payload: hhh });
                  // This below downloads the file dynamically
                  const link = document.createElement("a");
                  link.href = response.file;
                  link.download = response.file.split(".com/")[1];
                  // link.target = "_blank";
                  link.click();
                }
              })
              .catch((ex) => {
                if (ex) {
                  let hhh = [...generatingReports];
                  hhh[Number(index)].status = "Failed";
                  hhh[Number(index)].expiresIn = Date.now() + 1000 * 60 * 0.1;
                  dispatch({ type: SET_GENERATING_REPORTS, payload: hhh });
                }
              });
          }
        });
      }, 1000 * 1 * 1);
    }
  }, [generatingReports, dispatch]);

  useEffect(() => {
    if (currentCashierId) {
      dispatch(
        getAllCashierTransactions(
          `?cashierId=${currentCashierId}&offset=0&limit=9`
        )
      );
    }
  }, [currentCashierId, dispatch]);

  const data = cashierTransactions?.pageItems?.map(
    (each) => each.transactionAmount
  );

 
  const filteredData = cashierTransactions
    ?.filter((obj) => obj.currencyCode === dropdownValue)
    ?.flatMap((obj) => obj.cashierTransactionData)
    ?.map((cashier) => cashier.transactionAmount);


  const totalDebitCreditData = {
    labels: ["", "1", "2", "3", "4", "5", "6", "7", "8"],
    datasets: [
      {
        label: "Total Amount Sum",
        backgroundColor: effect,
        maintainAspectRatio: false,
        data: filteredData,
        borderColor: "#2C1DFF",
        tension: 0.1,
        borderWidth: 4,
      },
    ],
  };

  const handleChange = (value) => {
    setDropdownValue(value);
    localStorage.setItem("myDefaultValue", value);
  };

  const handleRunReport = (e) => {
    e.preventDefault();
    const tellerId = localStorage.getItem("cashierId");
    dispatch(generateTelReport(tellerId,dropdownValue));
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-12 col-lg-6 pb-4">
            <FlexContainer className="flex">
              <h3 className="page-title">{username}</h3>
              <OfficeTag>{officeName}</OfficeTag>
            </FlexContainer>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6">
            <DownloadButtonsGroup>
              <div className="mr-3 mt-3">
                <Select
                  // ref={selectRef}
                  value={dropdownValue}
                  onChange={handleChange}
                  optionFilterProp="children"
                  defaultValue={
                    dropdownValue ? dropdownValue : "Select Currency"
                  }
                >
                  {currencyOptions?.map((option) => (
                    <Option key={option?.code} value={option?.code}>
                      {option?.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <ButtonGroup>
                <ActionButton onClick={handleRunReport}>
                  Download Till Report
                </ActionButton>
              </ButtonGroup>
            </DownloadButtonsGroup>
          </div>
        </div>

        <div className="col-12">
          <Statistics defaultCurrencyValue={defaultCurrencyValue} />
          <div className="chart-container mt-5">
            <ChartCard>
              <div className="heading">
                <div className="title">Recent transactions </div>
              </div>
              <div className="content p-2">
                <Line
                  id="canvas"
                  data={totalDebitCreditData}
                  width={100}
                  height={300}
                  options={{
                    maintainAspectRatio: false,
                    legend: {
                      display: false,
                    },
                    scales: {
                      xAxes: [
                        {
                          barPercentage: 0.4,
                          categoryPercentage: 0.4,
                          gridLines: {
                            display: false,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          barPercentage: 0.4,
                          categoryPercentage: 0.4,
                          gridLines: {
                            borderDash: [2, 5, 7],
                            color: "#EDEFF5",
                          },
                        },
                      ],
                    },
                  }}
                />
              </div>
            </ChartCard>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
