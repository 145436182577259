import React, { useState } from "react";
import { Spinner, Table } from "react-bootstrap";
// import { Select, Radio, RadioGroup, Stack } from "@chakra-ui/react";
import { Radio, RadioGroup, Stack } from "@chakra-ui/react";

import {
  FormClass,
  FormElements,
  AddActionButton,
  NextActionButton,
  FormTitle,
  IconButton,
} from "../styles.jsx";
import Toast from "../../../../../../util/Toast.js";
import { formatCurrency } from "../../../../../../util/FormatAmount";
import { Select } from "antd";

const EditSavingsCharges = ({
  overdraftPortfolio,
  savingPreference,
  savingControl,
  savingTransferInSuspense,
  interestOnSavings,
  writeOff,
  incomeFromFees,
  incomeFromPenalties,
  overdraftInterestIncome,
  editSavingsProductHandler,
  template,
  charges,
  setCharges,
  setRadio,
  radio,
  numberOfMapFeeToIncome,
  setNumberOfMapFeeToIncome,
  numberOfMapPenalties,
  setNumberOfMapPenalties,
  numberOfConfigFS,
  setNumberOfConfigFS,
  handleSubmit,
  loading,
  escheatLiabilityId,
  // interestPayableAccountId,
  renderTooltip,
  setOverdraftPortfolio,
  setSavingPreference,
  setSavingControl,
  setInterestOnSavings,
  setWriteOff,
  setIncomeFromFees,
  setOverdraftInterestIncome,
  setIncomeFromPenalties,
  setEscheatLiabilityId,
  setSavingTransferInSuspense,
}) => {
  const defaultConfigFS = { paymentTypeId: "", fundSourceAccountId: "" };
  const defaultMapFeeToIncome = { chargeId: "", incomeAccountId: "" };
  const defaultMapPenalties = { chargeId: "", incomeAccountId: "" };

  const [advance, setAdvance] = useState(false);
  const [selectCharges, setSelectCharges] = useState("");

  //configure fund sources for payment channels
  const addToConfigFSArray = () => {
    setNumberOfConfigFS([...numberOfConfigFS, defaultConfigFS]);
  };
  const removeFromConfigFSArray = (position) => {
    setNumberOfConfigFS(
      numberOfConfigFS.filter((item, index) => index !== position)
    );
  };
  const handleConfigFSPaymentIdToChange = (position, value) => {
    let newConfigFS = [...numberOfConfigFS];
    newConfigFS[Number(position)].paymentTypeId = value;
    setNumberOfConfigFS(newConfigFS);
  };
  const handleConfigFSSourceIdToChange = (position, value) => {
    let newConfigFS = [...numberOfConfigFS];
    newConfigFS[Number(position)].fundSourceAccountId = value;
    setNumberOfConfigFS(newConfigFS);
  };


  // Map fees to income accounts
  // const [numberOfMapFeeToIncome, setNumberOfMapFeeToIncome] = useState([]);
  const addToMapFeeToIncomeArray = () => {
    setNumberOfMapFeeToIncome([
      ...numberOfMapFeeToIncome,
      defaultMapFeeToIncome,
    ]);
  };
  const removeFromMapFeeToIncomeArray = (position) => {
    setNumberOfMapFeeToIncome(
      numberOfMapFeeToIncome.filter((item, index) => index !== position)
    );
  };
  const handleMapFeeToIncomePaymentIdToChange = (position, value) => {
    let newMapFeeToIncome = [...numberOfMapFeeToIncome];
    newMapFeeToIncome[Number(position)].chargeId = value;
    setNumberOfMapFeeToIncome(newMapFeeToIncome);
  };
  const handleMapFeeToIncomeSourceIdToChange = (position, value) => {
    let newMapFeeToIncome = [...numberOfMapFeeToIncome];
    newMapFeeToIncome[Number(position)].incomeAccountId = value;
    setNumberOfMapFeeToIncome(newMapFeeToIncome);
  };

  // Map fees to income accounts
  // const [numberOfMapPenalties, setNumberOfMapPenalties] = useState([]);
  const addToMapPenaltiesArray = () => {
    setNumberOfMapPenalties([...numberOfMapPenalties, defaultMapPenalties]);
  };
  const removeFromMapPenaltiesArray = (position) => {
    setNumberOfMapPenalties(
      numberOfMapPenalties.filter((item, index) => index !== position)
    );
  };
  const handleMapPenaltiesPaymentIdToChange = (position, value) => {
    let newMapPenalties = [...numberOfMapPenalties];
    newMapPenalties[Number(position)].chargeId = value;
    setNumberOfMapPenalties(newMapPenalties);
  };
  const handleMapPenaltiesSourceIdToChange = (position, value) => {
    let newMapPenalties = [...numberOfMapPenalties];
    newMapPenalties[Number(position)].incomeAccountId = value;
    setNumberOfMapPenalties(newMapPenalties);
  };

  const handleAddCharge = () => {
    if (selectCharges) {
      let newCharge = template.chargeOptions.filter(
        (item) => item.id === Number(selectCharges)
      )[0];
      let check = charges.filter((item) => item === newCharge);
      if (check.length === 0) setCharges((p) => [...p, newCharge]);
      else {
        Toast({
          text: "Already added!",
          icon: "error",
          timer: 2000,
        });
      }
    } else {
      Toast({
        text: "Please select a charge",
        icon: "error",
        timer: 2000,
      });
    }
  };
  const handleChargeDelete = (id) => {
    setCharges(charges.filter((item) => item.id !== id));
  };

  return (
    <>
      <FormClass>
        <FormElements style={{ width: "60%", margin: "0 auto" }}>
          <div className="form-group d-flex justify-content-between align-items-center">
            {/* <label className="text-label">Product</label> */}
            <Select
              name="selectCharges"
              value={selectCharges || undefined}
              onChange={(value) => setSelectCharges(value)}
              size="md"
              placeholder="select charges"
            >
              {/* <option value="">Select Charge</option> */}
              {template !== undefined && template
                ? template.chargeOptions &&
                  template.chargeOptions.map((cur) => (
                    <Select.Option value={cur.id} key={cur.id}>
                      {cur.name}
                    </Select.Option>
                  ))
                : null}
            </Select>
            <NextActionButton
              color="#2C1DFF"
              style={{ margin: "0px 0px 0 10px" }}
              onClick={handleAddCharge}
            >
              <img
                src={
                  require("../../../../../../assets/images/icons/add.svg")
                    .default
                }
                alt="forward icon"
              />
              Add Charge
            </NextActionButton>
          </div>
        </FormElements>
        <Table className="child-table">
          <thead className="">
            <tr style={{ backgroundColor: "#FAFAFA" }}>
              <th>Name</th>
              <th>Type</th>
              <th>Amount</th>
              <th>Collected on</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {charges && charges.length > 0
              ? charges.map(
                  ({
                    id,
                    name,
                    chargeCalculationType,
                    amount,
                    chargeTimeType,
                  }) => (
                    <tr key={id}>
                      <td>{name}</td>
                      <td>{chargeCalculationType.value}</td>
                      <td>{formatCurrency(Number(amount))}</td>
                      <td>{chargeTimeType.value}</td>
                      <td>
                        <IconButton
                          onClick={() => handleChargeDelete(id)}
                          id="deleteHoliday"
                          className="mr-1 mb-1"
                        >
                          <img
                            src={
                              require("../../../../../../assets/images/icons/delete.svg")
                                .default
                            }
                            alt="add"
                            className="icon-img"
                          />
                        </IconButton>
                      </td>
                    </tr>
                  )
                )
              : null}
          </tbody>
        </Table>
        <FormTitle style={{ width: "60%", margin: "2rem auto" }}>
          <p className="text-info">Accounting type</p>
          <div className="lineThrough"></div>
        </FormTitle>
        <div style={{ width: "60%", margin: "0 auto" }}>
          <RadioGroup onChange={(e) => setRadio(e)} defaultValue={radio}>
            <Stack
              spacing={5}
              direction="row"
              style={{ justifyContent: "center" }}
            >
              <Radio colorScheme="blue" value="1">
                None
              </Radio>
              <Radio colorScheme="blue" value="2">
                Cash
              </Radio>
            </Stack>
          </RadioGroup>
        </div>

        {radio === "2" ? (
          <div>
            <FormTitle style={{ width: "60%", margin: "2rem auto" }}>
              <p className="text-info">Assets</p>
              <div className="lineThrough"></div>
            </FormTitle>

            <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
              <div className="col-sm-12 col-md-6 px-0 ">
                <FormClass>
                  <FormElements>
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-muted d-flex flex-align-center">
                        Overdraft Portfolio*
                        {renderTooltip("Overdraft Portfolio")}
                      </label>
                      <Select
                        size="md"
                        name="overdraftPortfolio"
                        value={overdraftPortfolio}
                        // onChange={editSavingsProductHandler}
                        onChange={(value) => setOverdraftPortfolio(value)}
                        placeholder="Select an option"
                        showSearch // Enable search functionality
                        optionFilterProp="children" // Specify which property of the option is used for filtering
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {/* <option value="">Select an option</option> */}
                        {template !== undefined && template
                          ? template.accountingMappingOptions &&
                            template.accountingMappingOptions
                              .assetAccountOptions &&
                            template.accountingMappingOptions.assetAccountOptions.map(
                              (item) => (
                                <Select.Option value={item.id} key={item.id}>
                                  {item.name}
                                </Select.Option>
                              )
                            )
                          : null}
                      </Select>
                    </div>
                  </FormElements>
                </FormClass>
              </div>
              <div className="col-sm-12 col-md-6 px-0">
                <FormClass>
                  <FormElements>
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-muted d-flex flex-align-center">
                        Saving Reference*
                        {renderTooltip("Saving Reference")}
                      </label>
                      <Select
                        size="md"
                        name="savingPreference"
                        value={savingPreference}
                        // onChange={editSavingsProductHandler}
                        onChange={(value) => setSavingPreference(value)}
                        placeholder="Select an option"
                        showSearch // Enable search functionality
                        optionFilterProp="children" // Specify which property of the option is used for filtering
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {/* <option value="">Select an option</option> */}
                        {template !== undefined && template
                          ? template.accountingMappingOptions &&
                            template.accountingMappingOptions
                              .assetAccountOptions &&
                            template.accountingMappingOptions.assetAccountOptions.map(
                              (item) => (
                                <option value={item.id} key={item.id}>
                                  {item.name}
                                </option>
                              )
                            )
                          : null}
                      </Select>
                    </div>
                  </FormElements>
                </FormClass>
              </div>
            </div>
            <FormTitle style={{ width: "60%", margin: "2rem auto" }}>
              <p className="text-info">Liabilities</p>
              <div className="lineThrough"></div>
            </FormTitle>

            <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
              <div className="col-sm-12 col-md-6 px-0 ">
                <FormClass>
                  <FormElements>
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-muted d-flex flex-align-center">
                        Saving Control*
                        {renderTooltip("Saving Control")}
                      </label>
                      <Select
                        size="md"
                        name="savingControl"
                        value={savingControl}
                        // onChange={editSavingsProductHandler}
                        onChange={(value) => setSavingControl(value)}
                        placeholder="Select an option"
                        showSearch // Enable search functionality
                        optionFilterProp="children" // Specify which property of the option is used for filtering
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {/* <option value="">Select an option</option> */}
                        {template !== undefined && template
                          ? template.accountingMappingOptions &&
                            template.accountingMappingOptions
                              .liabilityAccountOptions &&
                            template.accountingMappingOptions.liabilityAccountOptions.map(
                              (item) => (
                                <option value={item.id} key={item.id}>
                                  {item.name}
                                </option>
                              )
                            )
                          : null}
                      </Select>
                    </div>
                  </FormElements>
                </FormClass>
              </div>
              <div className="col-sm-12 col-md-6 px-0">
                <FormClass>
                  <FormElements>
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-muted d-flex flex-align-center">
                        Savings Transfer In Suspense*
                        {renderTooltip("Savings Transfer In Suspense")}
                      </label>
                      <Select
                        size="md"
                        name="savingTransferInSuspense"
                        value={savingTransferInSuspense}
                        // onChange={editSavingsProductHandler}
                        onChange={(value) => setSavingTransferInSuspense(value)}
                        placeholder="Select an option"
                        showSearch // Enable search functionality
                        optionFilterProp="children" // Specify which property of the option is used for filtering
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {/* <option value="">Select an option</option> */}
                        {template !== undefined && template
                          ? template.accountingMappingOptions &&
                            template.accountingMappingOptions
                              .liabilityAccountOptions &&
                            template.accountingMappingOptions.liabilityAccountOptions.map(
                              (item) => (
                                <Select.Option value={item.id} key={item.id}>
                                  {item.name}
                                </Select.Option>
                              )
                            )
                          : null}
                      </Select>
                    </div>
                  </FormElements>
                </FormClass>
              </div>
              <div className="col-sm-12 col-md-6 px-0">
                <FormClass>
                  <FormElements>
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-muted d-flex flex-align-center">
                        Escheat Liability
                        {renderTooltip("Escheat Liability")}
                      </label>
                      <Select
                        size="md"
                        name="escheatLiabilityId"
                        value={escheatLiabilityId}
                        // onChange={editSavingsProductHandler}
                        onChange={(value) => setEscheatLiabilityId(value)}
                        placeholder="Select an option"
                        showSearch // Enable search functionality
                        optionFilterProp="children" // Specify which property of the option is used for filtering
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {/* <option value="">Select an option</option> */}
                        {template !== undefined && template
                          ? template.accountingMappingOptions &&
                            template.accountingMappingOptions
                              .liabilityAccountOptions &&
                            template.accountingMappingOptions.liabilityAccountOptions.map(
                              (item) => (
                                <Select.Option value={item.id} key={item.id}>
                                  {item.name}
                                </Select.Option>
                              )
                            )
                          : null}
                      </Select>
                    </div>
                  </FormElements>
                </FormClass>
              </div>
              {/* <div className="col-sm-12 col-md-6 px-0">
                <FormClass>
                  <FormElements>
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-muted d-flex flex-align-center">
                        Interest Payable*
                        {renderTooltip("Interest Payable")}
                      </label>
                      <Select
                        size="md"
                        name="interestPayableAccountId"
                        value={interestPayableAccountId}
                        onChange={editSavingsProductHandler}
                      >
                        <option value="">Select an option</option>
                        {template !== undefined && template
                          ? template.accountingMappingOptions &&
                            template.accountingMappingOptions
                              .liabilityAccountOptions &&
                            template.accountingMappingOptions.liabilityAccountOptions.map(
                              (item) => (
                                <option value={item.id} key={item.id}>
                                  {item.name}
                                </option>
                              )
                            )
                          : null}
                      </Select>
                    </div>
                  </FormElements>
                </FormClass>
              </div> */}
            </div>

            <FormTitle style={{ width: "60%", margin: "2rem auto" }}>
              <p className="text-info">Expenses</p>
              <div className="lineThrough"></div>
            </FormTitle>

            <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
              <div className="col-sm-12 col-md-6 px-0 ">
                <FormClass>
                  <FormElements>
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-muted d-flex flex-align-center">
                        Interest On Savings*
                        {renderTooltip("Interest On Savings")}
                      </label>
                      <Select
                        size="md"
                        name="interestOnSavings"
                        value={interestOnSavings}
                        // onChange={editSavingsProductHandler}
                        onChange={(value) => setInterestOnSavings(value)}
                        placeholder="Select an option"
                        showSearch // Enable search functionality
                        optionFilterProp="children" // Specify which property of the option is used for filtering
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {/* <option value="">Select an option</option> */}
                        {template !== undefined && template
                          ? template.accountingMappingOptions &&
                            template.accountingMappingOptions
                              .expenseAccountOptions &&
                            template.accountingMappingOptions.expenseAccountOptions.map(
                              (item) => (
                                <option value={item.id} key={item.id}>
                                  {item.name}
                                </option>
                              )
                            )
                          : null}
                      </Select>
                    </div>
                  </FormElements>
                </FormClass>
              </div>
              <div className="col-sm-12 col-md-6 px-0">
                <FormClass>
                  <FormElements>
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-muted d-flex flex-align-center">
                        Write Off*
                        {renderTooltip("Write Off")}
                      </label>
                      <Select
                        size="md"
                        name="writeOff"
                        value={writeOff}
                        // onChange={editSavingsProductHandler}
                        onChange={(value) => setWriteOff(value)}
                        placeholder="Select an option"
                        showSearch // Enable search functionality
                        optionFilterProp="children" // Specify which property of the option is used for filtering
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {/* <option value="">Select an option</option> */}
                        {template !== undefined && template
                          ? template.accountingMappingOptions &&
                            template.accountingMappingOptions
                              .expenseAccountOptions &&
                            template.accountingMappingOptions.expenseAccountOptions.map(
                              (item) => (
                                <Select.Option value={item.id} key={item.id}>
                                  {item.name}
                                </Select.Option>
                              )
                            )
                          : null}
                      </Select>
                    </div>
                  </FormElements>
                </FormClass>
              </div>
            </div>

            <FormTitle style={{ width: "60%", margin: "2rem auto" }}>
              <p className="text-info">Income</p>
              <div className="lineThrough"></div>
            </FormTitle>

            <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
              <div className="col-sm-12 col-md-6 px-0 ">
                <FormClass>
                  <FormElements>
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-muted d-flex flex-align-center">
                        Income From Fees*
                        {renderTooltip("Income From Fees")}
                      </label>
                      <Select
                        size="md"
                        name="incomeFromFees"
                        value={incomeFromFees}
                        // onChange={editSavingsProductHandler}
                        onChange={(value) => setIncomeFromFees(value)}
                        placeholder="Select an option"
                        showSearch // Enable search functionality
                        optionFilterProp="children" // Specify which property of the option is used for filtering
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {/* <option value="">Select an option</option> */}
                        {template !== undefined && template
                          ? template.accountingMappingOptions &&
                            template.accountingMappingOptions
                              .incomeAccountOptions &&
                            template.accountingMappingOptions.incomeAccountOptions.map(
                              (item) => (
                                <Select.Option value={item.id} key={item.id}>
                                  {item.name}
                                </Select.Option>
                              )
                            )
                          : null}
                      </Select>
                    </div>
                  </FormElements>
                </FormClass>
                <FormClass>
                  <FormElements>
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-muted d-flex flex-align-center">
                        Overdraft Interest Income*
                        {renderTooltip("Overdraft Interest Income")}
                      </label>
                      <Select
                        size="md"
                        name="overdraftInterestIncome"
                        value={overdraftInterestIncome}
                        // onChange={editSavingsProductHandler}
                        onChange={(value) => setOverdraftInterestIncome(value)}
                        placeholder="Select an option"
                        showSearch // Enable search functionality
                        optionFilterProp="children" // Specify which property of the option is used for filtering
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {/* <option value="">Select an option</option> */}
                        {template !== undefined && template
                          ? template.accountingMappingOptions &&
                            template.accountingMappingOptions
                              .incomeAccountOptions &&
                            template.accountingMappingOptions.incomeAccountOptions.map(
                              (item) => (
                                <Select.Option value={item.id} key={item.id}>
                                  {item.name}
                                </Select.Option>
                              )
                            )
                          : null}
                      </Select>
                    </div>
                  </FormElements>
                </FormClass>
              </div>
              <div className="col-sm-12 col-md-6 px-0">
                <FormClass>
                  <FormElements>
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-muted d-flex flex-align-center">
                        Income From Penalties*
                        {renderTooltip("Income From Penalties")}
                      </label>
                      <Select
                        size="md"
                        name="incomeFromPenalties"
                        value={incomeFromPenalties}
                        // onChange={editSavingsProductHandler}
                        onChange={(value) => setIncomeFromPenalties(value)}
                        placeholder="Select an option"
                        showSearch // Enable search functionality
                        optionFilterProp="children" // Specify which property of the option is used for filtering
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {/* <option value="">Select an option</option> */}
                        {template !== undefined && template
                          ? template.accountingMappingOptions &&
                            template.accountingMappingOptions
                              .incomeAccountOptions &&
                            template.accountingMappingOptions.incomeAccountOptions.map(
                              (item) => (
                                <Select.Option value={item.id} key={item.id}>
                                  {item.name}
                                </Select.Option>
                              )
                            )
                          : null}
                      </Select>
                    </div>
                  </FormElements>
                </FormClass>
              </div>
            </div>
            <div className="pt-4">
              <div
                className="form-group d-flex  align-items-center"
                style={{ marginBottom: "1rem !important" }}
              >
                <h3 className="font-weight-bold">Advanced Accounting Rule</h3>
                <AddActionButton onClick={() => setAdvance((p) => !p)}>
                  Show
                  <img
                    className="icon-img ml-2"
                    src={
                      require(`../../../../../../assets/images/icons/arrow-${
                        advance ? "up" : "down2"
                      }.svg`).default
                    }
                    alt="dropdown icon"
                  />
                </AddActionButton>
              </div>
            </div>

            {advance ? (
              <div>
                <div className="d-flex justify-content-between flex-wrap">
                  <div className="col-sm-12 col-md-6 px-0 ">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label
                            style={{ width: "70%" }}
                            className="text-muted d-flex flex-align-center"
                          >
                            Configure Fund Sources For Payment Channels
                          </label>
                          <AddActionButton onClick={addToConfigFSArray}>
                            <img
                              src={
                                require("../../../../../../assets/images/icons/add.svg")
                                  .default
                              }
                              alt="add icon"
                            />{" "}
                            Add
                          </AddActionButton>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                  <div className="col-sm-12 col-md-12 px-0">
                    {numberOfConfigFS.length > 0 && (
                      <Table className="child-table">
                        <thead className="">
                          <tr style={{ backgroundColor: "#FAFAFA" }}>
                            <th>Payment type</th>
                            <th>Fund source</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {numberOfConfigFS.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <Select
                                  size="md"
                                  name="savingPreference"
                                  value={numberOfConfigFS[index].paymentTypeId}
                                  onChange={(value) =>
                                    handleConfigFSPaymentIdToChange(
                                      index,
                                      value
                                    )
                                  }
                                  placeholder="Select an option"
                                  showSearch // Enable search functionality
                                  optionFilterProp="children" // Specify which property of the option is used for filtering
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {/* <option value="">Select one</option> */}
                                  {template !== undefined && template
                                    ? template.paymentTypeOptions &&
                                      template.paymentTypeOptions.map((cur) => (
                                        <Select.Option
                                          value={cur.id}
                                          key={cur.id}
                                        >
                                          {cur.name}
                                        </Select.Option>
                                      ))
                                    : null}
                                </Select>
                              </td>
                              <td>
                                <Select
                                  size="md"
                                  name="savingPreference"
                                  value={
                                    numberOfConfigFS[index].fundSourceAccountId
                                  }
                                  onChange={(value) =>
                                    handleConfigFSSourceIdToChange(index, value)
                                  }
                                  placeholder="Select an option"
                                  showSearch // Enable search functionality
                                  optionFilterProp="children" // Specify which property of the option is used for filtering
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {/* <option value="">Select an option</option> */}
                                  {template !== undefined && template
                                    ? template.accountingMappingOptions &&
                                      template.accountingMappingOptions
                                        .assetAccountOptions &&
                                      template.accountingMappingOptions.assetAccountOptions.map(
                                        (item) => (
                                          <Select.Option
                                            value={item.id}
                                            key={item.id}
                                          >
                                            {item.name}
                                          </Select.Option>
                                        )
                                      )
                                    : null}
                                </Select>
                              </td>
                              <td>
                                <IconButton
                                  onClick={() => removeFromConfigFSArray(index)}
                                >
                                  <img
                                    src={
                                      require("../../../../../../assets/images/icons/delete.svg")
                                        .default
                                    }
                                    alt="add icon"
                                    className="icon-img"
                                  />
                                </IconButton>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-6 px-0 ">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label
                            style={{ width: "70%" }}
                            className="text-muted d-flex flex-align-center"
                          >
                            Map fees to income accounts
                          </label>
                          <AddActionButton onClick={addToMapFeeToIncomeArray}>
                            <img
                              src={
                                require("../../../../../../assets/images/icons/add.svg")
                                  .default
                              }
                              alt="add icon"
                            />{" "}
                            Add
                          </AddActionButton>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                  <div className="col-sm-12 col-md-12 px-0">
                    {numberOfMapFeeToIncome.length > 0 && (
                      <Table className="child-table">
                        <thead className="">
                          <tr style={{ backgroundColor: "#FAFAFA" }}>
                            <th>Fees</th>
                            <th>Income Account</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {numberOfMapFeeToIncome.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <Select
                                  size="md"
                                  name="savingPreference"
                                  value={numberOfMapFeeToIncome[index].chargeId}
                                  onChange={(value) =>
                                    handleMapFeeToIncomePaymentIdToChange(
                                      index,
                                      value
                                    )
                                  }
                                  placeholder="Select an option"
                                  showSearch // Enable search functionality
                                  optionFilterProp="children" // Specify which property of the option is used for filtering
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {/* <option value="">Select one</option> */}
                                  {template !== undefined && template
                                    ? template.chargeOptions &&
                                      template.chargeOptions.map((cur) => (
                                        <option value={cur.id} key={cur.id}>
                                          {cur.name}
                                        </option>
                                      ))
                                    : null}
                                </Select>
                              </td>
                              <td>
                                <Select
                                  size="md"
                                  name="savingPreference"
                                  value={
                                    numberOfMapFeeToIncome[index]
                                      .incomeAccountId
                                  }
                                  onChange={(value) =>
                                    handleMapFeeToIncomeSourceIdToChange(
                                      index,
                                      value
                                    )
                                  }
                                  placeholder="Select an option"
                                  showSearch // Enable search functionality
                                  optionFilterProp="children" // Specify which property of the option is used for filtering
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {/* <option value="">Select an option</option> */}
                                  {template !== undefined && template
                                    ? template.accountingMappingOptions &&
                                      template.accountingMappingOptions
                                        .incomeAccountOptions &&
                                      template.accountingMappingOptions.incomeAccountOptions.map(
                                        (item) => (
                                          <Select.Option
                                            value={item.id}
                                            key={item.id}
                                          >
                                            {item.name}
                                          </Select.Option>
                                        )
                                      )
                                    : null}
                                </Select>
                              </td>
                              <td>
                                <IconButton
                                  onClick={() =>
                                    removeFromMapFeeToIncomeArray(index)
                                  }
                                >
                                  <img
                                    src={
                                      require("../../../../../../assets/images/icons/delete.svg")
                                        .default
                                    }
                                    alt="add icon"
                                    className="icon-img"
                                  />
                                </IconButton>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )}
                  </div>
                  {/* <div className="col-sm-12 col-md-6 px-0 ">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label
                            style={{ width: "70%" }}
                            className="text-muted d-flex flex-align-center"
                          >
                            Map fees to liability accounts
                          </label>
                          <ActionButton>
                            <img
                              src={
                                require("../../../../../../../../assets/images/icons/add.svg")
                                  .default
                              }
                              alt="add icon"
                            />{" "}
                            Add
                          </ActionButton>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                  <div className="col-sm-12 col-md-6 px-0" />*/}
                  <div className="col-sm-12 col-md-6 px-0 ">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label
                            style={{ width: "70%" }}
                            className="text-muted d-flex flex-align-center"
                          >
                            Map Penalties To Specific Income Accounts
                          </label>
                          <AddActionButton onClick={addToMapPenaltiesArray}>
                            <img
                              src={
                                require("../../../../../../assets/images/icons/add.svg")
                                  .default
                              }
                              alt="add icon"
                            />{" "}
                            Add
                          </AddActionButton>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                  <div className="col-sm-12 col-md-12 px-0">
                    {numberOfMapPenalties.length > 0 && (
                      <Table className="child-table">
                        <thead className="">
                          <tr style={{ backgroundColor: "#FAFAFA" }}>
                            <th>Penalty</th>
                            <th>Income Account</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {numberOfMapPenalties.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <Select
                                  size="md"
                                  name="savingPreference"
                                  value={numberOfMapPenalties[index].chargeId}
                                  onChange={(value) =>
                                    handleMapPenaltiesPaymentIdToChange(
                                      index,
                                      value
                                    )
                                  }
                                  placeholder="Select an option"
                                  showSearch // Enable search functionality
                                  optionFilterProp="children" // Specify which property of the option is used for filtering
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {/* <option value="">Select one</option> */}
                                  {template !== undefined && template
                                    ? template.chargeOptions &&
                                      template.chargeOptions.map(
                                        ({ name, id, penalty }) =>
                                          penalty === true ? (
                                            <Select.Option value={id} key={id}>
                                              {name}
                                            </Select.Option>
                                          ) : null
                                      )
                                    : null}
                                </Select>
                              </td>
                              <td>
                                <Select
                                  size="md"
                                  name="savingPreference"
                                  value={
                                    numberOfMapPenalties[index].incomeAccountId
                                  }
                                  onChange={(value) =>
                                    handleMapPenaltiesSourceIdToChange(
                                      index,
                                      value
                                    )
                                  }
                                  placeholder="Select an option"
                                  showSearch // Enable search functionality
                                  optionFilterProp="children" // Specify which property of the option is used for filtering
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {/* <option value="">Select an option</option> */}
                                  {template !== undefined && template
                                    ? template.accountingMappingOptions &&
                                      template.accountingMappingOptions
                                        .incomeAccountOptions &&
                                      template.accountingMappingOptions.incomeAccountOptions.map(
                                        (item) => (
                                          <Select.Option
                                            value={item.id}
                                            key={item.id}
                                          >
                                            {item.name}
                                          </Select.Option>
                                        )
                                      )
                                    : null}
                                </Select>
                              </td>
                              <td>
                                <IconButton
                                  onClick={() =>
                                    removeFromMapPenaltiesArray(index)
                                  }
                                >
                                  <img
                                    src={
                                      require("../../../../../../assets/images/icons/delete.svg")
                                        .default
                                    }
                                    alt="add icon"
                                    className="icon-img"
                                  />
                                </IconButton>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        ) : null}

        <div className="float-right pt-4">
          <NextActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={handleSubmit}
          >
            {loading ? (
              <Spinner
                animation="border"
                color="#fff"
                className="mr-2 text-white"
                size="sm"
              />
            ) : (
              <img
                src={
                  require("../../../../../../assets/images/icons/save.svg")
                    .default
                }
                alt="submit icon"
              />
            )}
            Submit
          </NextActionButton>
        </div>
        <br />
        <br />
        <br />
        <br />
      </FormClass>
    </>
  );
};

export default EditSavingsCharges;
