import React, { useEffect } from "react";
// import { Divider } from "antd";

import { ContentWrapper } from "./styles";

import CheckerInbox from "./components/CheckerInbox";

import { useDispatch } from "react-redux";
import {
  clearFilteredClients,
  clearFilteredLoans,
  getAllClients,
  getAllLoans,
  getAllMakerCheckers,
} from "../../../../actions/businessActions";

const Checkers = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(clearFilteredClients());
    // dispatch(clearFilteredLoans());
    // dispatch(getAllClients());
    // dispatch(getAllLoans());
    dispatch(getAllMakerCheckers());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-2">
            <h3 className="page-title">Approvals</h3>
            <p className="sub-title">
              An overview of maker actions awaiting approval
            </p>
          </div>
        </div>
        <ContentWrapper>
          <div>
            <CheckerInbox />
          </div>
        </ContentWrapper>
      </div>
    </>
  );
};

export default Checkers;
