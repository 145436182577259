import React, { useState, useEffect } from "react";
import {
  ContentWrapper,
  ActionButtonsGroup,
  ActionButton,
  NewActionButton,
  ActionButton2,
  FormElements,
  FormClass,
} from "./styles";
import "./checker.scss";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllMCTasks,
  updateMCTasksInfo,
} from "../../../../actions/configurationActions";
import Loader from "./components/Loader";
import { FormControl, Checkbox } from "@chakra-ui/react";
const MCTasksView = () => {
  const [editState, setEditState] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [tasksState, settasksState] = useState({});
  const dispatch = useDispatch();

  const MCTasksList = useSelector((state) => state.MCTasksReducer);

  const { loading, MCTasks } = MCTasksList;

  useEffect(() => {
    dispatch(getAllMCTasks());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (MCTasks.length > 0) {
      MCTasks.map((item) =>
        settasksState((p) => ({
          ...p,
          [item.code]: item.selected,
        }))
      );
    }
  }, [MCTasks]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setProcessing(true);
    const func = () => {
      setProcessing(false);
      setEditState(false);
      dispatch(getAllMCTasks());
    };
    await dispatch(updateMCTasksInfo({ permissions: tasksState }, func));
    setProcessing(false);
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Configure Checker Tasks</h3>
            <p className="sub-title">Define Or Modify Checker Tasks</p>
            <p className="font-weight sub-title mt-2">
              <span className="text-info">
                <Link to="/core/manage-utilities">Manage Utilities</Link>{" "}
              </span>{" "}
              / Configure Checker Tasks
            </p>
          </div>
          {/* Edit Button */}
          <div className="col-sm-12 col-md-6 px-0">
            {!editState && (
              <ActionButtonsGroup>
                <ActionButton2
                  style={{ width: 225 }}
                  onClick={() => setEditState(true)}
                >
                  <img
                    src={
                      require("../../../../assets/images/icons/edit-profile.svg")
                        .default
                    }
                    alt="add"
                  />
                  Edit
                </ActionButton2>
              </ActionButtonsGroup>
            )}
          </div>
        </div>
        <ContentWrapper>
          <div style={{ paddingTop: "80px" }}>
            {loading ? (
              <Loader />
            ) : (
              // <div className="row checkerWrapper">
              <div className="checkerWrapper">
                {/* Flex 0.4 */}
                <div className="checkerSidebar">
                  {MCTasks.length > 0 &&
                    [...new Set(MCTasks.map(({ grouping }) => grouping))].map(
                      (grouping) => (
                        <div className="mb-3" key={grouping}>
                          <NewActionButton
                            color={
                              selectedGroup === grouping ? "#002C79" : "#FFFFFF"
                            }
                            textColor={
                              selectedGroup === grouping ? "#FFFFFF" : "#2C1DFF"
                            }
                            className="text-capitalize"
                            onClick={() => setSelectedGroup(grouping)}
                          >
                            <span className="new-design-text">{grouping
                              ? grouping.toLowerCase().replace(/_/g, " ")
                              : grouping}</span>
                            <img
                            textColor={
                              selectedGroup === grouping ? "#FFFFFF" : "#2C1DFF"
                            }
                              src={
                                // require("../../../assets/images/icons/arror-forward.svg")
                                require("../../../../assets/images/icons/Arrow-Vector.svg")
                                  .default
                              }
                              className="d-inline mr-3 icon-img"
                              alt="icon"
                              fill="#fff"
                            />
                          </NewActionButton>
                        </div>
                      )
                    )}
                </div>
                {/* Flex 0.6 */}
                <div className="checkerContent">
                  {MCTasks.filter((item) => item.grouping === selectedGroup)
                    .length > 0
                    ? MCTasks.filter(
                        (item) => item.grouping === selectedGroup
                      ).map((item) => (
                        <FormClass>
                          <FormElements>
                            <div className="form-group d-flex align-items-center">
                              <FormControl style={{ width: "6%" }}>
                                <Checkbox
                                  size="md"
                                  disabled={!editState}
                                  isDisabled={!editState}
                                  isChecked={tasksState[item.code]}
                                  onChange={(ev) =>
                                    settasksState((p) => ({
                                      ...p,
                                      [item.code]: ev.target.checked,
                                    }))
                                  }
                                />
                              </FormControl>
                              <label className="text-muted d-flex flex-align-center new-design-check-label">
                                {item.actionName
                                  ? `${item.actionName.toLowerCase()} ${
                                      item.entityName &&
                                      item.entityName.toLowerCase()
                                    }`
                                  : item.code}
                              </label>
                            </div>
                          </FormElements>
                        </FormClass>
                      ))
                    : null}
                </div>
              </div>
            )}
            {editState && (
              <div className="float-right">
                <ActionButton
                  color="#2C1DFF"
                  onClick={() => setEditState(false)}
                >
                  <img
                    src={
                      require("../../../../assets/images/icons/cancel.svg")
                        .default
                    }
                    alt="cancel icon"
                  />
                  Cancel
                </ActionButton>

                <ActionButton
                  onClick={handleSubmit}
                  color="#2C1DFF"
                  style={{ margin: "0px 0px 0 10px" }}
                  disabled={processing}
                >
                  {processing ? (
                    <Spinner
                      animation="border"
                      color="#fff"
                      className="mr-2 text-white"
                      size="sm"
                    />
                  ) : (
                    <img
                      src={
                        require("../../../../assets/images/icons/save.svg")
                          .default
                      }
                      alt="submit icon"
                    />
                  )}
                  Submit
                </ActionButton>
              </div>
            )}
          </div>
          <br />
          <br />
          {/* <br /> */}
        </ContentWrapper>
      </div>
    </>
  );
};

export default MCTasksView;
