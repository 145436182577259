import React from "react";
import { Table } from "antd";
import Loading from "./Loading";

const columns = [
  { title: "Name", dataIndex: "name", key: "name" },
  { title: "Value", dataIndex: "percentage", key: "percentage" },
];

const TableLoader = (props) => {
  const someChartsData = [
    {
      key: 1,
      name: <Loading />,
      percentage: <Loading />,
    },
    {
      key: 2,
      name: <Loading />,
      percentage: <Loading />,
    },
    {
      key: 3,
      name: <Loading />,
      percentage: <Loading />,
    },
    {
      key: 4,
      name: <Loading />,
      percentage: <Loading />,
    },
    {
      key: 5,
      name: <Loading />,
      percentage: <Loading />,
    },
  ];
  return (
    <>
      <Table
        columns={columns}
        dataSource={someChartsData}
        pagination={{ defaultPageSize: 8 }}
        className="antdTable"
        defaultPageSize={8}
      />
    </>
  );
};

export default TableLoader;
