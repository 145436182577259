import styled from "styled-components";

export const ContentWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 0px #ededf6;
  min-height: 70vh;
  margin-top: 20px;
  padding: 50px;

  .button-right {
    width: 100%;
    height: 50px;
    padding-right: 15px;
  }
  table th {
    font-size: 18px;
    font-weight: bold;
  }

  tr td:last-child {
  }
`;

export const ActionButton = styled.button`
  background: ${(props) => props.color} !important;
  border-radius: 5px !important;
  color: #fff !important;
  margin: 0 10px;
  border: none;
  display: inline;
  font-size: 14px;
  font-family: 'AventaMedium';
  height: 35px;
  width: 250px;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  position: relative;
  cursor: pointer;
  img {
    height: 18px;
    margin-right: 10px;
    display: inline;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;

export const TableButton = styled.div`
  height: 35px;
  width: 160px;
  background: #2C1DFF !important;
  margin: 10px 5px;
  border: none;
  border-radius: 5px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
`;
