import React, { useState, useEffect } from "react";
import {
  ContentWrapper,
  FormClass,
  ActionButton,
  RoleStyleButton,
  FormElements,
  RoleButton,
  TagButton,
} from "./styles";
import { Input, Select } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Checkbox } from "antd";
import Toast from "../../../../../util/Toast";
import {
  createNewUser,
  resetNewUserState,
  getUserTemplate,
  getOfficeStaffs,
  resetOfficerStaffs,
  getSingleEmployee,
} from "../../../../../actions/configurationActions";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { SET_LOADING_STATE } from "../../../../../constants/utilityConstants";
import SelectRolesModal from "../RoleModals/CreditRuleModal";
import ModalContainer from "../../../components/ModalContainer";
import { FETCH_SINGLE_EMPLOYEE_RESET } from "../../../../../constants/configurationConstants";

const CreateNewUserView = (props) => {
  const dispatch = useDispatch();
  const [newUser, setNewUser] = useState({
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    officeId: "",
    staffId: "",
  });
  let { firstname, lastname, username, email, officeId, staffId } = newUser;

  const [selectedRole, setSelectedRole] = useState([]);
  const [processing, setProcessing] = useState(false);

  const [isApiUser, setIsApiUser] = useState(false);
  const [isConsoleUser, setIsConsoleUser] = useState(false);
  const [isRoleSelected, setIsRoleSelected] = useState(false);
  const [modalState, toggleModal] = useState(false);
  const [modalContentName, setModalContentName] = useState("");

  useEffect(() => {
    dispatch(resetNewUserState());
    dispatch(getUserTemplate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(resetOfficerStaffs());
    if (officeId) dispatch(getOfficeStaffs(officeId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [officeId]);

  const userTemplate = useSelector((state) => state.userTemplate);
  const officeStaffs = useSelector((state) => state.officeStaffs);
  const singleEmployee = useSelector((state) => state.singleEmployeeReducer);

  const { loading, template } = userTemplate;
  const { loading: staffsLoading, staffs } = officeStaffs;
  const { loading: staffLoading, employee } = singleEmployee;

  useEffect(() => {
    dispatch({ type: SET_LOADING_STATE, payload: staffsLoading || loading || staffLoading });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffsLoading, loading, staffLoading]);

  const newUserChangeHandler = (e) =>
    setNewUser({ ...newUser, [e.target.name]: e.target.value });

  const func = () => {
    props.history.push("/core/user-management");
  };

  const openModal = () => {
    toggleModal(true);
    setModalContentName("rolesModal");
  };
  const handleModalClose = () => {
    toggleModal(false);
    setIsRoleSelected(false);
  };

  const displayModalContent = () => {
    switch (modalContentName) {
      case "rolesModal":
        return (
          <SelectRolesModal
            allData={template?.availableRoles}
            onHide={handleModalClose}
            setSelectedRole={setSelectedRole}
            selectedRole={selectedRole}
          />
        );
      default:
        return;
    }
  };

  const onSubmit = async () => {
    if (
      username &&
      firstname &&
      lastname &&
      // email &&
      officeId &&
      selectedRole &&
      (isConsoleUser || isApiUser)
    ) {
      setProcessing(true);
      const userData = {
        ...newUser,
        officeId: Number(officeId),
        staffId: staffId ? Number(staffId) : null,
        roles:
          selectedRole.length > 0 ? selectedRole.map((item) => item.id) : [],
        isApiUser,
        isConsoleUser,
      };
      await dispatch(createNewUser(userData, func));
      setProcessing(false);
    } else {
      dispatch(
        Toast({
          text: "Please fill the required field",
          icon: "error",
          timer: 2000,
        })
      );
    }
  };

  useEffect(() => {
    newUser?.staffId && dispatch(getSingleEmployee(newUser?.staffId))
    return () => {
      setNewUser(user => ({
        ...user,
        firstname: '',
        lastname: '',
        email: '',
      }))
    }
  }, [dispatch, officeStaffs, newUser?.staffId])

  useEffect(() => {
    setNewUser(user => ({
      ...user,
      firstname: '',
      lastname: '',
      email: '',
    }))
    if (employee) {
      setNewUser(user => ({
        ...user,
        firstname: employee.firstname,
        lastname: employee.lastname,
        email: employee.emailAddress,
      }))
    }
  }, [employee])

  return (
    <>
      <ModalContainer show={modalState} dialogClassName="document_infoModal">
        {modalState && displayModalContent()}
      </ModalContainer>
      {/* {() && (
        <Modal style={{ backgroundColor: "hsla(0, 50%, 100%, 0.65)" }}>
          <ModalLoader />
        </Modal>
      )} */}
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6">
            <h3 className="page-title">Create a new User</h3>
            <p className="sub-title">Create and edit customer information</p>
            <p className="font-weight sub-title">
              <span className="text-info">
                <Link to="/core/user-management">User Management</Link>
              </span>{" "}
              / Create User
            </p>
            {/* <p className="sub-title mt-2">
              <span className="text-info mr-1">Configuration / Users </span> /
              Create User
            </p> */}
          </div>
        </div>
        <ContentWrapper>
          <FormClass className="h-100 container">
            <FormElements>
              <div className="row h-100" style={{ paddingTop: "30px" }}>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Office*</label>
                    <Select
                      placeholder="Select Office"
                      size="md"
                      name="officeId"
                      value={officeId}
                      onChange={newUserChangeHandler}
                    >
                      {template && template.allowedOffices
                        ? template.allowedOffices.map((off, i) => (
                            <option value={off.id} key={i}>
                              {off.name}
                            </option>
                          ))
                        : null}
                    </Select>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Staff</label>
                    <Select
                      placeholder="Select Staff from selected office"
                      size="md"
                      name="staffId"
                      value={staffId}
                      onChange={newUserChangeHandler}
                    >
                      {staffs && staffs.length > 0
                        ? staffs.map((staff) => (
                            <option value={staff.id} key={staff.id}>
                              {staff.displayName}
                            </option>
                          ))
                        : null}
                    </Select>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Username*</label>
                    <Input
                      style={{ width: "60%" }}
                      size="md"
                      name="username"
                      value={username}
                      onChange={newUserChangeHandler}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">First Name*</label>
                    <Input
                      style={{ width: "60%" }}
                      size="md"
                      name="firstname"
                      value={firstname}
                      onChange={newUserChangeHandler}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Last Name*</label>
                    <Input
                      style={{ width: "60%" }}
                      size="md"
                      name="lastname"
                      value={newUser?.lastname}
                      onChange={newUserChangeHandler}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Email Address*</label>
                    <div className="d-flex flex-column" style={{ width: "60%" }}>
                      <Input
                        style={{ width: "100%", border: `${staffId && firstname && !email ? '1px solid red' : 'none'}` }}
                        size="md"
                        name="email"
                        value={email}
                        onChange={newUserChangeHandler}
                        disabled={true}
                      />
                      {staffId && firstname && !email && (
                        <span style={{ fontSize: '12px', marginTop: '4px', color: 'red' }}>
                          add an email address to this employee's details <Link to="/core/employee-management" style={{ color: '#2c1dff', textDecoration: 'underline' }}>here</Link>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label for="isApiUser" className="text-label">
                      Programmatic access
                    </label>
                    <div className="" style={{ width: "60%" }}>
                      <Checkbox
                        onChange={(e) => setIsApiUser(!isApiUser)}
                        value={isApiUser}
                        id="isApiUser"
                      ></Checkbox>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label for="isConsoleUser" className="text-label">
                      Console access
                    </label>
                    <div className="" style={{ width: "60%" }}>
                      <Checkbox
                        onChange={(e) => setIsConsoleUser(!isConsoleUser)}
                        value={isConsoleUser}
                        id="isConsoleUser"
                      ></Checkbox>
                    </div>
                  </div>
                </div>
                {/* {isConsoleUser ? (
                  <div className="col-sm-12 col-md-6">
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label for="isConsoleUser" className="text-label">
                        Send Password to Email
                      </label>
                      <div className="" style={{ width: "60%" }}>
                        <Checkbox
                          onChange={(e) =>
                            setSendPasswordToEmail(!sendPasswordToEmail)
                          }
                          value={sendPasswordToEmail}
                          id="isConsoleUser"
                        ></Checkbox>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )} */}
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-6 row">
                  <div className="form-group col-sm-12 col-md-5">
                    <label className="text-label mb-3">
                      Select available roles for user
                    </label>
                  </div>
                  <div className="col-sm-12 col-md-7 p-0">
                    <RoleStyleButton
                      onClick={openModal}
                      shadow
                      className="m-0"
                      color="#2C1DFF"
                    >
                      Select roles
                    </RoleStyleButton>
                  </div>
                </div>
                {isRoleSelected === false && (
                  <div className="col-md-6">
                    {selectedRole.length
                      ? selectedRole.map((item, key) => (
                          <TagButton
                            onClick={() =>
                              setSelectedRole(
                                selectedRole.filter((val) => val.id !== item.id)
                              )
                            }
                            key={key}
                          >
                            {item.name}
                            <img
                              src={
                                require("../../../../../assets/images/icons/cancel-tags.svg")
                                  .default
                              }
                              alt="cancel icon"
                              className="ml-3"
                            />
                          </TagButton>
                        ))
                      : null}
                  </div>
                )}
              </div>
              <div className="float-right h-100 mt-5">
                <Link to="/core/user-management">
                  <ActionButton shadow className="mb-2" color="#2C1DFF">
                    <img
                      src={
                        require("../../../../../assets/images/icons/cancel.svg")
                          .default
                      }
                      alt="cancel"
                    />
                    Cancel
                  </ActionButton>
                </Link>
                <ActionButton
                  onClick={onSubmit}
                  type="submit"
                  className="mr-0"
                  shadow
                  color="#2C1DFF"
                >
                  {processing ? (
                    <Spinner
                      animation="border"
                      color="#fff"
                      className="mr-2 text-white"
                      size="sm"
                    />
                  ) : (
                    <img
                      src={
                        require("../../../../../assets/images/icons/save.svg")
                          .default
                      }
                      alt="save"
                    />
                  )}
                  Submit
                </ActionButton>
              </div>
              <br />
              <br />
            </FormElements>
          </FormClass>
        </ContentWrapper>
      </div>
    </>
  );
};

export default CreateNewUserView;
