import React from "react";
import { useSelector } from "react-redux";
import { Table } from "antd";

import { ActionButton, FormTitle } from "../../styles";
import { formatToCurrency } from "../../../../../../util/FormatNumber";
import { formatDate } from "../../../../../../util/FormatDate";
import CurrencyFormat from "react-currency-format";

const SavingReview = ({
  handlePreviousScreen,
  handleSubmit,
  reviewData,
  reviewDataTwo,
  myChargesArray,
  withdrawals,
}) => {
  const fetchedProductData = useSelector((state) => state.selectedOptionData);
  const { productOptionsData } = fetchedProductData;
  const { currency, savingsProductName } = productOptionsData;
  const { withdrawalLimit, numberOfWithdrawals } = withdrawals;

  let chargesData = [];

  productOptionsData &&
    myChargesArray &&
    myChargesArray.map((charge) => {
      chargesData.push({
        key: charge.chargeId,
        name: charge.name,
        type: charge.type,
        amount: formatToCurrency(charge.amount),
        collectedOn: charge.chargeTimeTypeName,
        date: charge.date ? formatDate(charge.date).slice(0, -4) : "",
        repayments: charge.repayments,
      });
    });

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Type", dataIndex: "type", key: "type" },
    { title: "Amount", dataIndex: "amount", key: "amount" },
    { title: "Collected On", dataIndex: "collectedOn", key: "collectedOn" },
    { title: "Date", dataIndex: "date", key: "date" },
  ];

  return (
    <>
      <div className="col-sm-12 col-md-12 d-flex ">
        <div className="container">
          <div>
            <FormTitle>
              <p className="text-info">Details</p>
              <div className="lineThrough"></div>
            </FormTitle>
            <div className="pt-2">
              <div
                className="form-group d-flex  align-items-center text-label"
                style={{ marginBottom: "1rem !important" }}
              >
                <h3
                  className="col-md-4"
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                >
                  Product &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ fontFamily: "Helvetica Neue" }}>
                  {productOptionsData && savingsProductName}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  className="col-md-4"
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                >
                  Date &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ fontFamily: "Helvetica Neue" }}>
                  {reviewData.submittedOnDate ? reviewData.submittedOnDate : ""}
                </p>
              </div>
              {reviewDataTwo.fieldOfficer !== "" ? (
                <div className="form-group d-flex  align-items-center text-label">
                  <h3
                    className="col-md-4"
                    style={{
                      fontFamily: "AventaSemiBold",
                      marginRight: "1rem",
                    }}
                  >
                    Field Officer &nbsp;&nbsp;&nbsp;:
                  </h3>
                  <p style={{ fontFamily: "Helvetica Neue" }}>
                    {reviewDataTwo.fieldOfficer
                      ? reviewDataTwo.fieldOfficer
                      : ""}
                  </p>
                </div>
              ) : (
                <></>
              )}
              {reviewDataTwo.externalId !== "" ? (
                <div className="form-group d-flex  align-items-center text-label">
                  <h3
                    className="col-md-4"
                    style={{
                      fontFamily: "AventaSemiBold",
                      marginRight: "1rem",
                    }}
                  >
                    External Id &nbsp;&nbsp;&nbsp;:
                  </h3>
                  <p style={{ fontFamily: "Helvetica Neue" }}>
                    {reviewDataTwo.externalId ? reviewDataTwo.externalId : ""}
                  </p>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="pt-1">
            <FormTitle>
              <p className="text-info">Terms</p>
              <div className="lineThrough"></div>
            </FormTitle>
            <div className="pt-2">
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  className="col-md-4"
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                >
                  Currency &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ fontFamily: "Helvetica Neue" }}>
                  {productOptionsData && currency && currency.name}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  className="col-md-4"
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                >
                  Decimal Places &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ fontFamily: "Helvetica Neue" }}>
                  {productOptionsData && currency && currency.decimalPlaces}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  className="col-md-4"
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                >
                  Nominal Annual Interest &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ fontFamily: "Helvetica Neue" }}>
                  {reviewData.nominalAnnualInterestRate}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  className="col-md-4"
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                >
                  Interest Compounding Periods &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ fontFamily: "Helvetica Neue" }}>
                  {reviewDataTwo.interestCompPeriodType}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  className="col-md-4"
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                >
                  Currency In Multiples Of &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ fontFamily: "Helvetica Neue" }}>
                  {productOptionsData && currency && currency.inMultiplesOf}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  className="col-md-4"
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                >
                  Interest Posting Period &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ fontFamily: "Helvetica Neue" }}>
                  {reviewDataTwo.interestPostPeriodType}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  className="col-md-4"
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                >
                  Interest Calculated Using &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ fontFamily: "Helvetica Neue" }}>
                  {reviewDataTwo.interestCalculationTypeChk}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  className="col-md-4"
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                >
                  Days In Year &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ fontFamily: "Helvetica Neue" }}>
                  {reviewDataTwo.interestCalcDIYType}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  className="col-md-4"
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                >
                  Minimum Opening Balance &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ fontFamily: "Helvetica Neue" }}>
                  {/* {formatToCurrency(reviewData.minRequiredOpeningBalance)} */}
                  <>
                    {
                      <CurrencyFormat
                        value={reviewData.minRequiredOpeningBalance}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    }
                  </>
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  className="col-md-4"
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                >
                  Apply Withdrawal Fee For Transfers &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ fontFamily: "Helvetica Neue" }}>
                  {reviewData.withdrawalFeeForTransfers === true ||
                  reviewData.withdrawalFeeForTransfers === "true"
                    ? "True"
                    : "False"}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  className="col-md-4"
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                >
                  Lock-in Period &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ fontFamily: "Helvetica Neue" }}>{`${
                  reviewData.lockinPeriodFrequency
                    ? reviewData.lockinPeriodFrequency
                    : ""
                } ${
                  reviewDataTwo.lockinPeriodFreqType
                    ? reviewDataTwo.lockinPeriodFreqType
                    : ""
                }`}</p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  className="col-md-4"
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                >
                  Enable Withdrawal Count For Interest Posting
                  &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ fontFamily: "Helvetica Neue" }}>
                  {withdrawalLimit === true ||
                  withdrawalLimit === "yes" ||
                  withdrawalLimit === "Yes"
                    ? "True"
                    : "False"}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  className="col-md-4"
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                >
                  Withdrawal Limit Number &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ fontFamily: "Helvetica Neue" }}>
                  {numberOfWithdrawals}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  className="col-md-4"
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                >
                  Is Overdraft Allowed &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ fontFamily: "Helvetica Neue" }}>
                  {reviewData.allowOverdraft === true ||
                  reviewData.allowOverdraft === "true"
                    ? "True"
                    : "False"}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  className="col-md-4"
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                >
                  Enforce Minimum Balance &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ fontFamily: "Helvetica Neue" }}>
                  {reviewData.enforceMinRequiredBalance === true ||
                  reviewData.enforceMinRequiredBalance === "true"
                    ? "True"
                    : "False"}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  className="col-md-4"
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                >
                  Minimum Balance &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ fontFamily: "Helvetica Neue" }}>
                  {/* {formatToCurrency(reviewData.minRequiredBalance)} */}
                  {
                    <CurrencyFormat
                      value={reviewData.minRequiredBalance}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  }
                </p>
              </div>

              {reviewData.allowOverdraft === true ||
              reviewData.allowOverdraft === "true" ? (
                <div className="form-group d-flex  align-items-center text-label">
                  <h3
                    className="col-md-4"
                    style={{
                      fontFamily: "AventaSemiBold",
                      marginRight: "1rem",
                    }}
                  >
                    Maximum Overdraft Amount Limit &nbsp;&nbsp;&nbsp;:
                  </h3>
                  <p style={{ fontFamily: "Helvetica Neue" }}>
                    {/* {formatToCurrency(reviewData.overdraftLimit)} */}
                    {
                      <CurrencyFormat
                        value={reviewData.overdraftLimit}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    }
                  </p>
                </div>
              ) : null}

              {reviewData.allowOverdraft === true ||
              reviewData.allowOverdraft === "true" ? (
                <div className="form-group d-flex  align-items-center text-label">
                  <h3
                    className="col-md-4"
                    style={{
                      fontFamily: "AventaSemiBold",
                      marginRight: "1rem",
                    }}
                  >
                    Min Overdraft Required For Interest Calculation
                    &nbsp;&nbsp;&nbsp;:
                  </h3>
                  <p style={{ fontFamily: "Helvetica Neue" }}>
                    {/* {formatToCurrency(
                      reviewData.minOverdraftForInterestCalculation
                    )} */}
                    {
                      <CurrencyFormat
                        value={reviewData.minOverdraftForInterestCalculation}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    }
                  </p>
                </div>
              ) : null}

              {reviewData.allowOverdraft === true ||
              reviewData.allowOverdraft === "true" ? (
                <div className="form-group d-flex  align-items-center text-label">
                  <h3
                    className="col-md-4"
                    style={{
                      fontFamily: "AventaSemiBold",
                      marginRight: "1rem",
                    }}
                  >
                    Nominal Annual Interest Rate For Overdraft
                    &nbsp;&nbsp;&nbsp;:
                  </h3>
                  <p style={{ fontFamily: "Helvetica Neue" }}>
                    {/* {formatToCurrency(
                      reviewData.nominalAnnualInterestRateOverdraft
                    )} */}
                    {
                      <CurrencyFormat
                        value={reviewData.nominalAnnualInterestRateOverdraft}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    }
                  </p>
                </div>
              ) : null}
            </div>
          </div>

          <div className="pt-3">
            <FormTitle>
              <p className="text-info">Charges</p>
              <div className="lineThrough"></div>
            </FormTitle>

            <div className="mt-5">
              {
                <Table
                  className="child-table"
                  columns={columns}
                  dataSource={chargesData}
                />
              }
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="float-right pt-3">
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={handlePreviousScreen}
          >
            <img
              src={
                require("../../../../../../assets/images/icons/cancel.svg")
                  .default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={handleSubmit}
          >
            <img
              src={
                require("../../../../../../assets/images/icons/save.svg")
                  .default
              }
              alt="submit icon"
            />
            Submit
          </ActionButton>
        </div>
      </div>
    </>
  );
};

export default SavingReview;
