import React, { useEffect, useState } from "react";
import { Table, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import ModalContainer from "../../../../components/ModalContainer";
import {
  Input,
  Textarea,
  FormControl,
  Select,
  Checkbox,
} from "@chakra-ui/react";

import {
  FormClass,
  FormElements,
  LineThrough,
  IconButton,
} from "../../styles.jsx";

const InterestCharts = ({
  validFrom,
  handleValidFrom,
  validTo,
  handleValidTo,
  template,
  interests,
  setInterests,
  defaultData,
}) => {
  useEffect(() => {}, []);

  const [isPrimary, setIsPrimary] = useState(false);

  const handleAddInterest = () => {
    setInterests([...interests, defaultData]);
  };

  const handleDeleteInterest = (position) => {
    let noo = [];
    interests.forEach((element, index) => {
      if (position !== index) {
        noo.push(element);
      }
    });
    setInterests(noo);
  };

  // const handleOnChangeOfPeriodType = (index, value) => {
  //   let hhh = [...interests];
  //   hhh[Number(index)].periodType = value;
  //   setInterests(hhh);
  // };

  // const handleOnChangeOfFromPeriod = (index, value) => {
  //   let hhh = [...interests];
  //   hhh[Number(index)].fromPeriod = value;
  //   setInterests(hhh);
  // };

  // const handleOnChangeOfToPeriod = (index, value) => {
  //   let hhh = [...interests];
  //   hhh[Number(index)].toPeriod = value;
  //   setInterests(hhh);
  // };

  // const handleOnChangeOfAmountRangeFrom = (index, value) => {
  //   let hhh = [...interests];
  //   hhh[Number(index)].amountRangeFrom = value;
  //   setInterests(hhh);
  // };

  // const handleOnChangeOfAmountRangeTo = (index, value) => {
  //   let hhh = [...interests];
  //   hhh[Number(index)].amountRangeTo = value;
  //   setInterests(hhh);
  // };

  // const handleOnChangeOfInterest = (index, value) => {
  //   let hhh = [...interests];
  //   console.log(hhh, 'hhhhhhh')
  //   hhh[Number(index)].annualInterestRate = value;
  //   console.log(hhh[Number(index)].annualInterestRate, 'hereee')
  //   setInterests(hhh);
  // };

  const handleOnChangeOfInterest = (value, index) => {
    // console.log(interests, 'Account Creation Original')
    setInterests((oldInterest) => {
      // console.log(oldInterest, 'oldInterests');
      const newInterest = [...oldInterest];
      newInterest[index].annualInterestRate = value;
      return newInterest;
    });
  };

  // const handleOnChangeOfDescription = (index, value) => {
  //   let hhh = [...interests];
  //   hhh[Number(index)].description = value;
  //   setInterests(hhh);
  // };

  const [modalState, toggleModal] = useState(false);
  const [currentIncentives, setCurrentIncentives] = useState([]);
  const [currentIndex, setCurrentIndex] = useState();

  let defaultIncentiveData = {
    amount: "",
    attributeName: "2",
    attributeValue: "",
    conditionType: "",
    entityType: "2",
    incentiveType: "",
  };
  const handleAddIncentives = () => {
    setCurrentIncentives([...currentIncentives, defaultIncentiveData]);
  };

  const handleDeleteIncentive = (position) => {
    let noo = [];
    currentIncentives.forEach((element, index) => {
      if (position !== index) {
        noo.push(element);
      }
    });
    setCurrentIncentives(noo);
  };

  const handleOnChangeOfIncentiveAttributeName = (index, value) => {
    let hhh = [...currentIncentives];
    hhh[Number(index)].attributeName = value;
    setCurrentIncentives(hhh);
  };

  const handleOnChangeOfIncentiveConditionType = (index, value) => {
    let hhh = [...currentIncentives];
    hhh[Number(index)].conditionType = value;
    setCurrentIncentives(hhh);
  };

  const handleOnChangeOfIncentiveAttributeValue = (index, value) => {
    let hhh = [...currentIncentives];
    hhh[Number(index)].attributeValue = value;
    setCurrentIncentives(hhh);
  };

  const handleOnChangeOfIncentiveType = (index, value) => {
    let hhh = [...currentIncentives];
    hhh[Number(index)].incentiveType = value;
    setCurrentIncentives(hhh);
  };

  const handleOnChangeOfIncentiveAmount = (index, value) => {
    let hhh = [...currentIncentives];
    hhh[Number(index)].amount = value;
    setCurrentIncentives(hhh);
  };

  const handleCloseIncentives = () => {
    let hhh = [...interests];
    hhh[Number(currentIndex)].incentives = currentIncentives;
    setInterests(hhh);
    toggleModal(false);
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
          <h3 className="page-title">Interest Rate</h3>
        </div>

        {/* <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
          <div className="col-sm-12 col-md-6 px-0 ">
            <FormClass>
              <FormElements>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Valid From Date*</label>
                  <FormControl style={{ width: "60%" }}>
                    <div className="datepicker-100-width">
                      <DatePicker
                        id="start-date"
                        selected={validFrom}
                        onChange={(date) => handleValidFrom(date)}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="dd/mmm/yyyy"
                      />
                    </div>
                  </FormControl>
                </div>
              </FormElements>
            </FormClass>
          </div>
          <div className="col-sm-12 col-md-6 px-0">
            <FormClass>
              <FormElements>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">End Date</label>
                  <FormControl style={{ width: "60%" }}>
                    <div className="datepicker-100-width">
                      <DatePicker
                        id="validTo"
                        selected={validTo}
                        onChange={(date) => handleValidTo(date)}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="dd/mmm/yyyy"
                      />
                    </div>
                  </FormControl>
                </div>
              </FormElements>
            </FormClass>
          </div>
        </div> */}

        <div className="col-sm-12 col-md-6 px-0" style={{ marginLeft: "19px" }}>
          {interests && interests.length ? (
            interests.map((interest, index) => {
              return (
                <>
                  <div
                    className="form-group d-flex  justify-content-between align-items-center"
                    style={{ marginBottom: "1rem !important" }}
                  >
                    <label className="text-label d-flex flex-align-center">
                      Interest
                    </label>
                    <Input
                      style={{ width: "52%", marginRight: "62px" }}
                      name="annualInterestRate"
                      value={
                        !!interest?.annualInterestRate
                          ? interest?.annualInterestRate
                          : ""
                      }
                      onChange={(e) =>
                        handleOnChangeOfInterest(e.target.value, index)
                      }
                      size="md"
                    />
                  </div>
                </>
              );
            })
          ) : (
            <>
              <div
                className="form-group d-flex  justify-content-between align-items-center"
                style={{ marginBottom: "1rem !important" }}
              >
                <label className="text-label d-flex flex-align-center">
                  Interest
                </label>
                <Input
                  style={{ width: "52%", marginRight: "62px" }}
                  name="annualInterestRate"
                  size="md"
                  // disabled
                />
              </div>
            </>
          )}
        </div>

        {/* <div className="col-md-12">
          <Table>
            <thead>
              <tr style={{ backgroundColor: "#EDEFF5" }}>
                <th>{isPrimary ? "Amount range" : "Period type"}</th>
                <th>{isPrimary ? "Period type" : "Period from"}</th>
                <th>{isPrimary ? "Period from" : "Amount range"}</th>
                <th>Interest</th>
                <th>Description</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {!!interests &&
                !!interests.length &&
                interests.map((interest, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {isPrimary ? (
                          <>
                            <div className="d-flex">
                              <Input
                                name="amountRangeFrom"
                                value={interest.amountRangeFrom}
                                onChange={(e) =>
                                  handleOnChangeOfAmountRangeFrom(
                                    index,
                                    e.target.value
                                  )
                                }
                                size="md"
                              />{" "}
                            </div>{" "}
                          </>
                        ) : (
                          <>
                            {" "}
                            <Select
                              className="d-flex"
                              style={{ width: "100%" }}
                              size="md"
                              name="periodType"
                              value={Number(interest.periodType)}
                              onChange={(e) =>
                                handleOnChangeOfPeriodType(
                                  index,
                                  e.target.value
                                )
                              }
                            >
                              {template !== undefined &&
                                !!template.accountChart &&
                                !!template.accountChart.periodTypes &&
                                !!template.accountChart.periodTypes.length &&
                                template.accountChart.periodTypes.map((cur) => (
                                  <option
                                    id={cur.id}
                                    value={cur.id}
                                    key={cur.id}
                                  >
                                    {cur.value}
                                  </option>
                                ))}
                            </Select>{" "}
                          </>
                        )}
                      </td>
                      <td>
                        {isPrimary ? (
                          <>
                            {" "}
                            <Select
                              className="d-flex"
                              // style={{width: "100%"}}
                              size="md"
                              name="periodType"
                              value={Number(interest.periodType)}
                              onChange={(e) =>
                                handleOnChangeOfPeriodType(
                                  index,
                                  e.target.value
                                )
                              }
                            >
                              {template !== undefined &&
                                !!template.accountChart &&
                                !!template.accountChart.periodTypes &&
                                !!template.accountChart.periodTypes.length &&
                                template.accountChart.periodTypes.map((cur) => (
                                  <option
                                    id={cur.id}
                                    value={cur.id}
                                    key={cur.id}
                                  >
                                    {cur.value}
                                  </option>
                                ))}
                            </Select>{" "}
                          </>
                        ) : (
                          <>
                            {" "}
                            <div className="d-flex">
                              <Input
                                name="fromPeriod"
                                value={interest.fromPeriod}
                                onChange={(e) =>
                                  handleOnChangeOfFromPeriod(
                                    index,
                                    e.target.value
                                  )
                                }
                                size="md"
                              />
                            </div>{" "}
                          </>
                        )}
                      </td>
                      <td>
                        {isPrimary ? (
                          <>
                            {" "}
                            <div className="d-flex">
                              <Input
                                name="fromPeriod"
                                value={interest.fromPeriod}
                                onChange={(e) =>
                                  handleOnChangeOfFromPeriod(
                                    index,
                                    e.target.value
                                  )
                                }
                                size="md"
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="d-flex">
                              <Input
                                name="amountRangeFrom"
                                value={interest.amountRangeFrom}
                                onChange={(e) =>
                                  handleOnChangeOfAmountRangeFrom(
                                    index,
                                    e.target.value
                                  )
                                }
                                size="md"
                              />{" "}
                            </div>{" "}
                          </>
                        )}
                      </td>
                      <td>
                        <Input
                          name="annualInterestRate"
                          value={interest.annualInterestRate}
                          onChange={(e) =>
                            handleOnChangeOfInterest(index, e.target.value)
                          }
                          size="md"
                        />
                      </td>
                      <td>
                        <Textarea
                          size="md"
                          name="description"
                          value={interest.description}
                          onChange={(e) =>
                            handleOnChangeOfDescription(index, e.target.value)
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div> */}

        <div style={{ width: "100%" }} className="py-5">
          <LineThrough width="100%" />
        </div>
      </div>
    </>
  );
};

export default InterestCharts;
