import React, { useState, useRef, useEffect } from "react";
import { Table } from "antd";
import { InputGroup, InputLeftElement, Input } from "@chakra-ui/react";

import ModalContainer from "../../../components/ModalContainer";
import ActionModal from "./modals/ActionModal";
import DetailsModal from "./modals/DetailsModal";

import CheckedWhiteSvg from "../../../../../assets/images/icons/checked-white.svg";
import DeleteSvg from "../../../../../assets/images/icons/delete.svg";
import CloseSvg from "../../../../../assets/images/icons/cancel.svg";
import { NewActionButton } from "../styles";
import { useSelector, useDispatch } from "react-redux";
import {
  clearFilteredMakers,
  filterMakers,
  processAllMakerChecker,
} from "../../../../../actions/businessActions";
import Toast from "../../../../../util/Toast";
import { formatDate } from "../../../../../util/FormatDate";
import { useHistory } from "react-router";
import FilterModal from "./modals/FilterModal";
import CustomButton from "../../../../../components/CustomButton";
const CheckerInbox = () => {
  const history = useHistory();
  const businessReducer = useSelector((state) => state.businessReducer);
  const dispatch = useDispatch();
  const search = useRef("");
  const { loading, checkerMakerChecker, filteredMakers } = businessReducer;
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentCheckerDetails, setCurrentChecker] = useState("");
  const [filterModal, setFilterModal] = useState(false);

  const onFilterClient = (e) => {
    search.current.value !== ""
      ? dispatch(filterMakers(e.target.value))
      : dispatch(clearFilteredMakers());
  };

  useEffect(() => {
    if (filteredMakers === null) {
      search.current.value = "";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: "Action ID",
      dataIndex: "id",
    },
    {
      title: "Made On Date",
      dataIndex: "madeOnDate",
    },
    {
      title: "Action Status",
      dataIndex: "status",
    },
    {
      title: "User",
      dataIndex: "user",
    },
    {
      title: "Action",
      dataIndex: "action",
    },
    {
      title: "Entity",
      dataIndex: "entity",
    },
  ];


  const dataSource = [];
  const filteredMakersData = [];
  if (filteredMakers !== null) {
    filteredMakers.forEach((client) => {
      const { id, clientId, maker, entityName, actionName, madeOnDate } =
        client;
      filteredMakersData.push({
        key: id,
        // id: id,
        id,
        madeOnDate: formatDate(madeOnDate),
        status: "not approved",
        user: maker,
        action: actionName,
        entity: entityName,
      });
    });
  }


  !loading &&
    checkerMakerChecker?.pageItems?.forEach((client) => {
      const { id, clientId, maker, entityName, actionName, madeOnDate } =
        client;
      dataSource.push({
        key: id,
        // id: clientId,
        id,
        madeOnDate: formatDate(madeOnDate),
        status: "not approved",
        user: maker,
        action: actionName,
        entity: entityName,
      });
    });

  const [modalState, toggleModal] = useState(false);
  const [modalContentName, setModalContentName] = useState("");
  const [showCheckerDetails, setShowCheckerDetails] = useState(false);

  const currentContent = (e) => {
    if (selectedRows.length) {
      const val = e.currentTarget.id.trim();
      if (val) {
        toggleModal(true);
        setModalContentName(val);
      }
    } else {
      dispatch(
        Toast({
          text: "No client selected",
          icon: "error",
          timer: 2000,
        })
      );
    }
  };

  useEffect(() => { }, []);

  const handleModalClose = () => toggleModal(false);

  const handleApprove = () => {
    selectedRows.map((data) =>
      dispatch(
        processAllMakerChecker(
          data.key,
          modalContentName.toLocaleLowerCase(),
          {},
          history
        )
      )
    );
    // console.log({ dataToSend });
  };

  const displayModalContent = () => {
    return (
      <ActionModal
        header={`Confirm ${modalContentName}`}
        onHide={handleModalClose}
        action={handleApprove}
      />
    );
  };

  // This is for the selected Row

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  // console.log("I gt here");

  const onFilterclient = () => { };

  return (
    <>
      <ModalContainer show={modalState} dialogClassName="document_infoModal">
        {modalState && displayModalContent()}
      </ModalContainer>
      <ModalContainer show={filterModal}>
        {filterModal && <FilterModal onHide={() => setFilterModal(false)} />}
      </ModalContainer>
      {showCheckerDetails && (
        <DetailsModal
          showCheckerDetails={showCheckerDetails}
          history={history}
          activeChecker={currentCheckerDetails}
          onHide={() => setShowCheckerDetails(false)}
        />
      )}
      <div className="">
        <div className="mb-4 mt-2 d-flex row">
          <div className="col-md-5 d-flex align-items-center">
            <div>
              <form onSubmit={(e) => onFilterclient(e)}>
                <InputGroup>
                  <InputLeftElement
                    // width="3.1rem"
                    width="40px"
                    height="38px"
                    // height="50px"
                    pointerEvents="none"
                  >
                    <img
                      src={
                        require("../../../../../assets/images/icons/search.svg")
                          .default
                      }
                      style={{ height: "15px", width: "15px" }}
                      alt="Icon"
                    />
                  </InputLeftElement>
                  <Input
                    width="220px"
                    style={{
                      background: "#ffffff",
                      height: "45px",
                    }}
                    type="text"
                    placeholder="Search by name"
                    ref={search}
                    onChange={onFilterClient}
                  />
                </InputGroup>
              </form>
            </div>
            <div className="col-sm-12 col-md-6 p-0 d-flex ml-2">
              <NewActionButton
                className="form-control general-font-size"
                onClick={() => setFilterModal(true)}
              >
                {/* <Link> */}

                Advance Search
                {/* </Link> */}
              </NewActionButton>
            </div>
          </div>

          <div className="col-md-7 d-flex justify-content-end">
            <CustomButton
              id="Approve"
              icon={CheckedWhiteSvg}
              onClick={currentContent}
              buttonText="Approve"
              buttonClass="approve-btn bg-success px-3 mr-3"
            />
            <CustomButton
              id="Delete"
              icon={DeleteSvg}
              onClick={currentContent}
              buttonText="Delete"
              buttonClass="bg-danger px-3 mr-3"
            />
            <CustomButton
              id="Reject"
              icon={CloseSvg}
              onClick={currentContent}
              buttonText="Reject"
              buttonClass="bg-warning px-3"
            />
          </div>
        </div>
        <Table
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          onRow={(r) => ({
            onClick: (e) => {
              console.log(r);
              setCurrentChecker(r?.key);
              history.push(`/core/checkers/${r?.key}`);
              setShowCheckerDetails(true);
            }
          })}
          columns={columns}
          dataSource={filteredMakers !== null ? filteredMakersData : dataSource}
        />
      </div>
    </>
  );
};

export default CheckerInbox;
