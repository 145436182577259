import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Input, Select, FormControl } from "@chakra-ui/react";
import { FormClass, ActionButton, FormElements } from "../styles";
import { formatDate } from "../../../../../util/FormatDate";
import CurrencyFormat from "react-currency-format";
import {
  getSavingsChargeTemplate,
  addSavingsAccountCharge,
} from "../../../../../actions/SavingsAndLoanActions";

const AddCharge = ({ onHide, history, id }) => {
  const dispatch = useDispatch();

  const savingsAndLoan = useSelector((state) => state.savingsAndLoan);

  let { savingsAccountChargeTemplate = {} } = savingsAndLoan || {};

  let { chargeOptions = [] } = savingsAccountChargeTemplate;

  const [charges, setCharges] = useState();

  let selectedTemplate = {};

  function isEmptyObject(obj) {
    return Object.keys(obj).length === 0;
  }

  let { chargeCalculationType = {}, id: selectedTemplateId } =
    selectedTemplate || {};

  const [mySelectedTemplate, setMySelectedTemplate] = useState({});

  chargeOptions.forEach((option) => {
    let { id } = option;
    if (charges === `${id}`) {
      selectedTemplate = option;
    }
  });

  const [amount, setAmount] = useState(0);
  const [chargeCalculation, setChargeCalculation] = useState();
  const [chargeTimeType, setChargeTimeType] = useState();
  const [dueDate, setDueDate] = useState("");

  useEffect(() => {
    if (!isEmptyObject(selectedTemplate)) {
      setAmount(selectedTemplate.amount);
      setChargeCalculation(selectedTemplate.chargeCalculationType.value);
      setChargeTimeType(selectedTemplate.chargeTimeType.value);
    } else {
      setAmount("");
      setChargeCalculation("");
      setChargeTimeType("");
    }
  }, [selectedTemplate]);

  let newDate = new Date(dueDate);

  useEffect(() => {
    dispatch(getSavingsChargeTemplate(id));
  }, [id, dispatch]);

  const handleAddCharge = () => {
    let dataToSend = {
      amount,
      chargeId: charges,
      dateFormat: "dd MMM yyyy",
      dueDate: formatDate(newDate, true),
      locale: "en",
    };
    dispatch(addSavingsAccountCharge(id, dataToSend, history));
    onHide();
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>Add Savings Charge</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <FormElements style={{ width: "100%" }}>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Charges*</label>
              <Select
                placeholder="Select Charges"
                size="md"
                name="charges"
                value={charges}
                onChange={(e) => setCharges(e.target.value)}
              >
                {!!chargeOptions ? (
                  chargeOptions.map(({ name, id }) => {
                    return (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    );
                  })
                ) : (
                  <option>Loading</option>
                )}
              </Select>
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Amount*</label>
              {/* <Input
                style={{ width: "70%" }}
                size="md"
                name="accNumber"
                value={formatCurrency(amount)}
                onChange={(e) => setAmount(e.target.value)}
              /> */}
              <CurrencyFormat
                value={amount}
                thousandSeparator={true}
                className="amount-input "
                style={{
                  width: "60%",
                }}
                // prefix={"$"}
                onValueChange={(values) => {
                  const { value } = values;
                  // formattedValue = $2,223
                  // value ie, 2223
                  setAmount(value);
                }}
              />
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Charge Calculation</label>
              <Select
                placeholder="Select Charge Calculation"
                size="md"
                name="chargeCalculation"
                value={chargeCalculation}
                disabled
                onChange={(e) => setChargeCalculation(e.target.value)}
              >
                <option value={chargeCalculation}>{chargeCalculation}</option>
                {/* {!!paymentTypes ? (
                  paymentTypes.map(({ name, id }) => {
                    return (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    );
                  })
                ) : (
                  <option>Loading</option>
                )} */}
              </Select>
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Charge Time Type</label>
              <Select
                placeholder="Select Charge Time Type"
                size="md"
                name="chargeTimeType"
                value={chargeTimeType}
                disabled
                onChange={(e) => setChargeTimeType(e.target.value)}
              >
                <option value={chargeTimeType}>{chargeTimeType}</option>
                {/* {!!paymentTypes ? (
                  paymentTypes.map(({ name, id }) => {
                    return (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    );
                  })
                ) : (
                  <option>Loading</option>
                )} */}
              </Select>
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Due For Collection On*</label>
              <FormControl style={{ width: "60%" }}>
              <div className="datepicker-100-width">

                <DatePicker
                  id="published-date"
                  selected={dueDate}
                  onChange={(date) => setDueDate(date)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="dd/mmm/yyyy"
                />
                </div>
              </FormControl>
            </div>
          </FormElements>
        </FormClass>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex mt-1">
          <ActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require("../../../../../assets/images/icons/cancel.svg").default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={(e) => {
              e.preventDefault();
              handleAddCharge();
            }}
          >
            <img
              src={
                require("../../../../../assets/images/icons/save.svg").default
              }
              alt="submit icon"
            />
            Submit
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default AddCharge;
