/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import PublicRoute from "./public";
import PrivateRoute from "./private";
import TellerRoute from "./teller";
import ScrollToTop from "../components/ScrollToTop";

import DashboardContainer from "../layouts/DashboardLayout/DashboardContainer";
import TellerContainer from "../layouts/TellerLayout/TellerContainer";
// import AuthContainer from "../layouts/AuthLayout/AuthContainer";
import AuthLayout from "../layouts/AuthLayout";
import Login from "../views/auth";
import ResetPassword from "../views/auth/ResetPassword";

export default () => (
  <BrowserRouter>
    <ScrollToTop>
      <Switch>
        <PublicRoute exact path="/" component={Login} />
        <Route
          path="/reset-password/:token"
          component={(props) => <ResetPassword {...props} />}
        />
        <Route
          path="/setup-password/:token"
          component={(props) => <ResetPassword {...props} />}
        />
        <PrivateRoute path="/core" component={DashboardContainer} />
        <TellerRoute path="/teller" component={TellerContainer} />
        {/* <Route component={NotFoundPage} exact={true} /> */}
      </Switch>
    </ScrollToTop>
  </BrowserRouter>
);
