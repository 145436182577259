import React from "react";
import { Modal } from "react-bootstrap";
import { ActionButton } from "../../../styles";

const AccountRuleDetailModal = ({ onHide }) => {
  return (
    <>
      <Modal.Header>
        <Modal.Title>Delete Rule</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <div className="m-5">
          <p style={{ fontSize: "24px" }}>
            Are you sure you want to delete this accounting rule.
          </p>
        </div>
        <div className="float-right mt-4">
          <ActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require("../../../../../../assets/images/icons/cancel.svg")
                  .default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            //   onClick={}
          >
            <img
              src={
                require("../../../../../../assets/images/icons/save.svg")
                  .default
              }
              alt="submit icon"
            />
            Submit
          </ActionButton>
        </div>
      </Modal.Body>
    </>
  );
};

export default AccountRuleDetailModal;
