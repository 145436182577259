import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ContentWrapper } from "../styles";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import AppDetails from "./components/common/AppDetails";
import AppTerms from "./components/common/AppTerms";
import AppCharges from "./components/common/AppCharges";
import AppPreview from "./components/common/AppPreview";
import AppSettings from "./components/common/AppSettings";

import LoanDetails from "./components/loans/LoanDetails.jsx";
import LoanTerms from "./components/loans/LoanTerms.jsx";
import LoanCharges from "./components/loans/LoanCharges.jsx";
import LoanReview from "./components/loans/LoanReview.jsx";
import ScrollToTop from "../../../../../../components/ScrollToTop/index.jsx";
import LoanSettings from "./components/loans/LoanSettings";

import Toast from "../../../../../../util/Toast.js";
// import { getToolTips } from "../../../../../../actions/utilityActions";
import ToolTipSvg from "../../../../../../assets/images/icons/tooltip.svg";

import {
  getLoanProductsTemplate,
  createLoanProduct,
  resetNewLoanProductState,
} from "../../../../../../actions/configurationActions";
import { formatDate } from "../../../../../../util/FormatDate";

const CreateLoanProductView = ({ match, history }) => {
  const dispatch = useDispatch();
  const loanProductsTemplate = useSelector(
    (state) => state.loanProductsTemplate
  );

  const toolTips = useSelector((state) => state.toolTips.tooltips);

  const renderTooltip = (name) => (
    <>
      {toolTips && toolTips[name] ? (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={`tooltip-${toolTips[name]._id}`}>
              {toolTips[name].toolTip}
            </Tooltip>
          }
        >
          <img src={ToolTipSvg} style={{ marginLeft: "10px" }} alt="" />
        </OverlayTrigger>
      ) : (
        ""
      )}
    </>
  );

  const { loading, loanProductsTemplate: template } = loanProductsTemplate;
  useEffect(() => {
    dispatch(resetNewLoanProductState());
    dispatch(getLoanProductsTemplate());
    // dispatch(getToolTips("loanProduct"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [fullCharges, setFullCharges] = useState([]);
  const [fullOverdueCharges, setFullOverdueCharges] = useState([]);
  const [valueCharges, setValueCharges] = useState([]);
  const [valueOverdueCharges, setValueOverdueCharges] = useState([]);
  const [showAdvance, setShowAdvance] = useState(false);
  const [feeToIncomeAccountMappings, setFeeToIncomeAccountMappings] = useState(
    []
  );
  const [penaltyToIncomeAccountMappings, setPenaltyToIncomeAccountMappings] =
    useState([]);
  const [
    paymentChannelToFundSourceMappings,
    setPaymentChannelToFundSourceMappings,
  ] = useState([]);
  const [newLoanProduct, setNewLoanProduct] = useState({
    productName: "",
    currency: "",
    shortName: "",
    multipleCurrency: "1",
    description: "",
    installment: "",
    decimalPlaces: "2",
    customerLoanCounter: false,
    fund: "",
    max_pricipal: "",
    def_pricipal: "",
    min_pricipal: "",
    max_repayment: "",
    def_repayment: "",
    min_repayment: "",
    max_interest: "",
    def_interest: "",
    min_interest: "",
    interestSelect: "",
    interestMethod: "",
    repaid: "",
    repaidSelect: "",
    disbursal: "",
    selectCharges: "",
    selectOverDueCharges: "",
    none: true,
    cash: false,
    accuralPeriodic: false,
    accuralUpfront: false,
    amortizationType: true,
    amortization: "",
    isEqualAmortization: false,
    interestType: true,
    transactionProcessingStrategy: true,
    transactionProcessingStrategyId: "",
    graceOnArrearsAgeing: true,
    interestCalculationPeriodType: true,
    repaymentEvery: true,
    inArrearsTolerance: true,
    graceOnPrincipalAndInterestPayment: true,
    interestCalculation: "",
    moratonium2: "",
    moratonium: "",
    graceOnInterestCharged: "",
    inArrearsToleranceValue: "",
    daysInyear: "",
    daysInMonth: "",
    canDefineInstallmentAmount: false,
    numberOfDaysLoan: "",
    accountMovesOutOfNPAOnlyOnArrearsCompletion: false,
    graceOnArrearsAgeingValue: "",
    overdueDaysForNPA: "",
    principalThresholdForLastInstallment: "",
    isVariableInstallment: false,
    minimumGap: "",
    maximumGap: "",
    canUseForTopup: false,
    isInterestRecalculationEnabled: false,
    recalculationRestFrequencyInterval: "",
    preClosureInterestCalculationStrategy: "",
    interestRecalculationCompoundingMethod: "",
    rescheduleStrategyMethod: "",
    recalculationRestFrequencyType: "",
    recalculationCompoundingFrequencyInterval: "",
    recalculationCompoundingFrequencyType: "",
    recalculationCompoundingFrequencyNthDayType: "",
    recalculationCompoundingFrequencyDayOfWeekType: "",
    recalculationCompoundingFrequencyOnDayType: "",
    isArrearsBasedOnOriginalSchedule: false,
    recalculationRestFrequencyDayOfWeekType: "",
    recalculationRestFrequencyOnDayType: "",
    recalculationRestFrequencyNthDayType: "",
    placeGuarantee: false,
    mandatoryGuarantee: "",
    minimumGuaranteeFromOwnFunds: "",
    minimumGuaranteeFromGuarantor: "",
    loanTrancte: false,
    maxTrancheCount: "",
    outstandingLoanBalance: "",
    // principalOverdueAccountId: "",
    // fundSource: "",
    // loanPortfolio: "",
    // transferInSusp: "",
    // incomeFromInterest: "",
    // incomeFromFees: "",
    // incomeFromPenalties: "",
    // incomeFromRecovery: "",
    // overPaymentLia: "",
    // lossesWrittenOff: "",
    // interestReceivable: "",
    // feesReceivable: "",
    // penaltiesReceivable: "",
    // interestInSuspenseAccountId: "",
    // interestOverdueAccountId: "",
    allowPartialPeriodInterestCalcualtion: false,
  });

  const createLoanProductHandler = (e) =>
    setNewLoanProduct({ ...newLoanProduct, [e.target.name]: e.target.value });
  const [min_pricipal, setMin_pricipal] = useState("");
  const [def_pricipal, setDef_pricipal] = useState("");
  const [max_pricipal, setMax_pricipal] = useState("");
  const [accountType, setAccountType] = useState("1");
  const [detailsError, setDetailsError] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [settingsError, setSettingsError] = useState(false);
  const [chargesError, setChargesError] = useState(false);
  const [processing, setLoading] = useState(false);
  const [startDate, handleStartDate] = useState(new Date());
  const [closeDate, handleCloseDate] = useState(new Date());
  const [numberOfPrincipalLC, setNumberOfPrincipalLC] = useState([]);
  const [numberOfRepayment, setNumberOfRepayment] = useState([]);
  const [numberOfNominal, setNumberOfNominal] = useState([]);
  const [terms, setTerms] = useState(false);
  const [currentScreen, setCurrentScreen] = useState(0);

  const [fundSource, setFundSource] = useState("");
  const [loanPortfolio, setLoanPortfolio] = useState("");
  const [transferInSusp, setTransferInSusp] = useState("");
  const [incomeFromInterest, setIncomeFromInterest] = useState("");
  const [incomeFromFees, setIncomeFromFees] = useState("");
  const [incomeFromPenalties, setIncomeFromPenalties] = useState("");
  const [incomeFromRecovery, setIncomeFromRecovery] = useState("");
  const [overPaymentLia, setOverPaymentLia] = useState("");
  const [lossesWrittenOff, setLossesWrittenOff] = useState("");
  const [interestReceivable, setInterestReceivable] = useState("");
  const [feesReceivable, setFeesReceivable] = useState("");
  const [penaltiesReceivable, setPenaltiesReceivable] = useState("");

  const renderComponent = () => {
    let currentComponet = "";
    switch (currentScreen) {
      case 0:
        currentComponet = (
          <AppDetails>
            <LoanDetails
              setDetailsError={setDetailsError}
              detailsError={detailsError}
              setNewLoanProduct={setNewLoanProduct}
              template={loading || !template ? null : template}
              handleNextScreen={handleNextCustomIndexSelect}
              createLoanProductHandler={createLoanProductHandler}
              productName={newLoanProduct.productName}
              currency={newLoanProduct.currency}
              shortName={newLoanProduct.shortName}
              multipleCurrency={newLoanProduct.multipleCurrency}
              description={newLoanProduct.description}
              installment={newLoanProduct.installment}
              decimalPlaces={newLoanProduct.decimalPlaces}
              handleStartDate={handleStartDate}
              startDate={startDate}
              closeDate={closeDate}
              handleCloseDate={handleCloseDate}
              customerLoanCounter={newLoanProduct.customerLoanCounter}
              fund={newLoanProduct.fund}
              renderTooltip={renderTooltip}
            />
          </AppDetails>
        );
        break;
      case 1:
        currentComponet = (
          <AppTerms error={detailsError}>
            <LoanTerms
              handleNextScreen={handleNextCustomIndexSelect}
              handlePreviousScreen={handlePreviousCustomIndexSelect}
              max_pricipal={max_pricipal}
              min_pricipal={min_pricipal}
              setMin_pricipal={setMin_pricipal}
              setDef_pricipal={setDef_pricipal}
              setMax_pricipal={setMax_pricipal}
              def_pricipal={def_pricipal}
              max_interest={newLoanProduct.max_interest}
              min_interest={newLoanProduct.min_interest}
              def_interest={newLoanProduct.def_interest}
              max_repayment={newLoanProduct.max_repayment}
              min_repayment={newLoanProduct.min_repayment}
              def_repayment={newLoanProduct.def_repayment}
              disbursal={newLoanProduct.disbursal}
              interestSelect={newLoanProduct.interestSelect}
              termsError={termsError}
              repaid={newLoanProduct.repaid}
              repaidSelect={newLoanProduct.repaidSelect}
              setTermsError={setTermsError}
              createLoanProductHandler={createLoanProductHandler}
              template={template}
              setNumberOfPrincipalLC={setNumberOfPrincipalLC}
              numberOfPrincipalLC={numberOfPrincipalLC}
              numberOfRepayment={numberOfRepayment}
              setNumberOfRepayment={setNumberOfRepayment}
              numberOfNominal={numberOfNominal}
              setNumberOfNominal={setNumberOfNominal}
              setTerms={setTerms}
              terms={terms}
              renderTooltip={renderTooltip}
            />
          </AppTerms>
        );
        break;
      case 2:
        currentComponet = (
          <AppSettings error={termsError || detailsError}>
            <LoanSettings
              handleNextScreen={handleNextCustomIndexSelect}
              amortization={newLoanProduct.amortization}
              handlePreviousScreen={handlePreviousCustomIndexSelect}
              settingsError={settingsError}
              setSettingsError={setSettingsError}
              createLoanProductHandler={createLoanProductHandler}
              interestMethod={newLoanProduct.interestMethod}
              setNewLoanProduct={setNewLoanProduct}
              amortizationType={newLoanProduct.amortizationType}
              isEqualAmortization={newLoanProduct.isEqualAmortization}
              repaymentEvery={newLoanProduct.repaymentEvery}
              interestType={newLoanProduct.interestType}
              transactionProcessingStrategy={
                newLoanProduct.transactionProcessingStrategy
              }
              transactionProcessingStrategyId={
                newLoanProduct.transactionProcessingStrategyId
              }
              interestCalculationPeriodType={
                newLoanProduct.interestCalculationPeriodType
              }
              inArrearsTolerance={newLoanProduct.inArrearsTolerance}
              graceOnPrincipalAndInterestPayment={
                newLoanProduct.graceOnPrincipalAndInterestPayment
              }
              graceOnArrearsAgeing={newLoanProduct.graceOnArrearsAgeing}
              template={template}
              interestCalculation={newLoanProduct.interestCalculation}
              moratonium2={newLoanProduct.moratonium2}
              moratonium={newLoanProduct.moratonium}
              graceOnInterestCharged={newLoanProduct.graceOnInterestCharged}
              inArrearsToleranceValue={newLoanProduct.inArrearsToleranceValue}
              daysInMonth={newLoanProduct.daysInMonth}
              daysInyear={newLoanProduct.daysInyear}
              renderTooltip={renderTooltip}
              canDefineInstallmentAmount={
                newLoanProduct.canDefineInstallmentAmount
              }
              numberOfDaysLoan={newLoanProduct.numberOfDaysLoan}
              accountMovesOutOfNPAOnlyOnArrearsCompletion={
                newLoanProduct.accountMovesOutOfNPAOnlyOnArrearsCompletion
              }
              graceOnArrearsAgeingValue={
                newLoanProduct.graceOnArrearsAgeingValue
              }
              overdueDaysForNPA={newLoanProduct.overdueDaysForNPA}
              principalThresholdForLastInstallment={
                newLoanProduct.principalThresholdForLastInstallment
              }
              isVariableInstallment={newLoanProduct.isVariableInstallment}
              minimumGap={newLoanProduct.minimumGap}
              allowPartialPeriodInterestCalcualtion={
                newLoanProduct.allowPartialPeriodInterestCalcualtion
              }
              maximumGap={newLoanProduct.maximumGap}
              canUseForTopup={newLoanProduct.canUseForTopup}
              isInterestRecalculationEnabled={
                newLoanProduct.isInterestRecalculationEnabled
              }
              preClosureInterestCalculationStrategy={
                newLoanProduct.preClosureInterestCalculationStrategy
              }
              recalculationRestFrequencyInterval={
                newLoanProduct.recalculationRestFrequencyInterval
              }
              interestRecalculationCompoundingMethod={
                newLoanProduct.interestRecalculationCompoundingMethod
              }
              rescheduleStrategyMethod={newLoanProduct.rescheduleStrategyMethod}
              recalculationRestFrequencyType={
                newLoanProduct.recalculationRestFrequencyType
              }
              recalculationCompoundingFrequencyInterval={
                newLoanProduct.recalculationCompoundingFrequencyInterval
              }
              recalculationCompoundingFrequencyType={
                newLoanProduct.recalculationCompoundingFrequencyType
              }
              recalculationCompoundingFrequencyNthDayType={
                newLoanProduct.recalculationCompoundingFrequencyNthDayType
              }
              recalculationCompoundingFrequencyDayOfWeekType={
                newLoanProduct.recalculationCompoundingFrequencyDayOfWeekType
              }
              recalculationCompoundingFrequencyOnDayType={
                newLoanProduct.recalculationCompoundingFrequencyOnDayType
              }
              isArrearsBasedOnOriginalSchedule={
                newLoanProduct.isArrearsBasedOnOriginalSchedule
              }
              recalculationRestFrequencyDayOfWeekType={
                newLoanProduct.recalculationRestFrequencyDayOfWeekType
              }
              recalculationRestFrequencyOnDayType={
                newLoanProduct.recalculationRestFrequencyOnDayType
              }
              recalculationRestFrequencyNthDayType={
                newLoanProduct.recalculationRestFrequencyNthDayType
              }
              placeGuarantee={newLoanProduct.placeGuarantee}
              mandatoryGuarantee={newLoanProduct.mandatoryGuarantee}
              minimumGuaranteeFromOwnFunds={
                newLoanProduct.minimumGuaranteeFromOwnFunds
              }
              minimumGuaranteeFromGuarantor={
                newLoanProduct.minimumGuaranteeFromGuarantor
              }
              loanTrancte={newLoanProduct.loanTrancte}
              maxTrancheCount={newLoanProduct.maxTrancheCount}
              outstandingLoanBalance={newLoanProduct.outstandingLoanBalance}
            />
          </AppSettings>
        );
        break;
      case 3:
        currentComponet = (
          <AppCharges error={termsError || detailsError || settingsError}>
            <LoanCharges
              radio={accountType}
              setChargesError={setChargesError}
              setRadio={setAccountType}
              handleNextScreen={handleNextCustomIndexSelect}
              handlePreviousScreen={handlePreviousCustomIndexSelect}
              createLoanProductHandler={createLoanProductHandler}
              template={template}
              fundSource={fundSource}
              loanPortfolio={loanPortfolio}
              transferInSusp={transferInSusp}
              incomeFromInterest={incomeFromInterest}
              incomeFromFees={incomeFromFees}
              incomeFromPenalties={incomeFromPenalties}
              incomeFromRecovery={incomeFromRecovery}
              overPaymentLia={overPaymentLia}
              lossesWrittenOff={lossesWrittenOff}
              interestReceivable={interestReceivable}
              feesReceivable={feesReceivable}
              penaltiesReceivable={penaltiesReceivable}
              setFundSource={setFundSource}
              setLoanPortfolio={setLoanPortfolio}
              setTransferInSusp={setTransferInSusp}
              setIncomeFromInterest={setIncomeFromInterest}
              setIncomeFromFees={setIncomeFromFees}
              setIncomeFromPenalties={setIncomeFromPenalties}
              setIncomeFromRecovery={setIncomeFromRecovery}
              setOverPaymentLia={setOverPaymentLia}
              setLossesWrittenOff={setLossesWrittenOff}
              setInterestReceivable={setInterestReceivable}
              setFeesReceivable={setFeesReceivable}
              setPenaltiesReceivable={setPenaltiesReceivable}
              canDefineInstallmentAmount={
                newLoanProduct.canDefineInstallmentAmount
              }
              showAdvance={showAdvance}
              setShowAdvance={setShowAdvance}
              numberOfDaysLoan={newLoanProduct.numberOfDaysLoan}
              accountMovesOutOfNPAOnlyOnArrearsCompletion={
                newLoanProduct.accountMovesOutOfNPAOnlyOnArrearsCompletion
              }
              graceOnArrearsAgeingValue={
                newLoanProduct.graceOnArrearsAgeingValue
              }
              overdueDaysForNPA={newLoanProduct.overdueDaysForNPA}
              principalThresholdForLastInstallment={
                newLoanProduct.principalThresholdForLastInstallment
              }
              isVariableInstallment={newLoanProduct.isVariableInstallment}
              minimumGap={newLoanProduct.minimumGap}
              maximumGap={newLoanProduct.maximumGap}
              canUseForTopup={newLoanProduct.canUseForTopup}
              isInterestRecalculationEnabled={
                newLoanProduct.isInterestRecalculationEnabled
              }
              preClosureInterestCalculationStrategy={
                newLoanProduct.preClosureInterestCalculationStrategy
              }
              recalculationRestFrequencyInterval={
                newLoanProduct.recalculationRestFrequencyInterval
              }
              interestRecalculationCompoundingMethod={
                newLoanProduct.interestRecalculationCompoundingMethod
              }
              rescheduleStrategyMethod={newLoanProduct.rescheduleStrategyMethod}
              recalculationRestFrequencyType={
                newLoanProduct.recalculationRestFrequencyType
              }
              recalculationCompoundingFrequencyInterval={
                newLoanProduct.recalculationCompoundingFrequencyInterval
              }
              recalculationCompoundingFrequencyType={
                newLoanProduct.recalculationCompoundingFrequencyType
              }
              recalculationCompoundingFrequencyNthDayType={
                newLoanProduct.recalculationCompoundingFrequencyNthDayType
              }
              recalculationCompoundingFrequencyDayOfWeekType={
                newLoanProduct.recalculationCompoundingFrequencyDayOfWeekType
              }
              recalculationCompoundingFrequencyOnDayType={
                newLoanProduct.recalculationCompoundingFrequencyOnDayType
              }
              isArrearsBasedOnOriginalSchedule={
                newLoanProduct.isArrearsBasedOnOriginalSchedule
              }
              recalculationRestFrequencyDayOfWeekType={
                newLoanProduct.recalculationRestFrequencyDayOfWeekType
              }
              recalculationRestFrequencyOnDayType={
                newLoanProduct.recalculationRestFrequencyOnDayType
              }
              recalculationRestFrequencyNthDayType={
                newLoanProduct.recalculationRestFrequencyNthDayType
              }
              placeGuarantee={newLoanProduct.placeGuarantee}
              mandatoryGuarantee={newLoanProduct.mandatoryGuarantee}
              minimumGuaranteeFromOwnFunds={
                newLoanProduct.minimumGuaranteeFromOwnFunds
              }
              minimumGuaranteeFromGuarantor={
                newLoanProduct.minimumGuaranteeFromGuarantor
              }
              loanTrancte={newLoanProduct.loanTrancte}
              maxTrancheCount={newLoanProduct.maxTrancheCount}
              outstandingLoanBalance={newLoanProduct.outstandingLoanBalance}
              setFullOverdueCharges={setFullOverdueCharges}
              // principalOverdueAccountId={
              //   newLoanProduct.principalOverdueAccountId
              // }
              setFullCharges={setFullCharges}
              feeToIncomeAccountMappings={feeToIncomeAccountMappings}
              penaltyToIncomeAccountMappings={penaltyToIncomeAccountMappings}
              paymentChannelToFundSourceMappings={
                paymentChannelToFundSourceMappings
              }
              setFeeToIncomeAccountMappings={setFeeToIncomeAccountMappings}
              setPenaltyToIncomeAccountMappings={
                setPenaltyToIncomeAccountMappings
              }
              setPaymentChannelToFundSourceMappings={
                setPaymentChannelToFundSourceMappings
              }
              setValueCharges={setValueCharges}
              valueCharges={valueCharges}
              setValueOverdueCharges={setValueOverdueCharges}
              valueOverdueCharges={valueOverdueCharges}
              // interestInSuspenseAccountId={
              //   newLoanProduct.interestInSuspenseAccountId
              // }
              // interestOverdueAccountId={newLoanProduct.interestOverdueAccountId}
              renderTooltip={renderTooltip}
            />
          </AppCharges>
        );
        break;
      case 4:
        currentComponet = (
          <AppPreview
            error={termsError || detailsError || settingsError || chargesError}
          >
            <LoanReview
              handlePreviousScreen={handlePreviousCustomIndexSelect}
              handleSubmit={handleSubmit}
              customerLoanCounter={newLoanProduct.customerLoanCounter}
              currency={newLoanProduct.currency}
              decimalPlaces={newLoanProduct.decimalPlaces}
              multipleCurrency={newLoanProduct.multipleCurrency}
              installment={newLoanProduct.installment}
              terms={terms}
              principal={def_pricipal}
              max_pricipal={max_pricipal}
              min_pricipal={min_pricipal}
              repayment={newLoanProduct.def_repayment}
              min_repayment={newLoanProduct.min_repayment}
              max_repayment={newLoanProduct.max_repayment}
              max_interest={newLoanProduct.max_interest}
              min_interest={newLoanProduct.min_interest}
              def_interest={newLoanProduct.def_interest}
              repaymentEvery={newLoanProduct.repaymentEvery}
              repaid={newLoanProduct.repaid}
              repaidSelect={newLoanProduct.repaidSelect}
              disbursal={newLoanProduct.disbursal}
              amortizationType={newLoanProduct.amortizationType}
              isEqualAmortization={newLoanProduct.isEqualAmortization}
              interestType={newLoanProduct.interestType}
              transactionProcessingStrategyId={
                newLoanProduct.transactionProcessingStrategyId
              }
              interestCalculationPeriodType={
                newLoanProduct.interestCalculationPeriodType
              }
              inArrearsTolerance={newLoanProduct.inArrearsTolerance}
              graceOnPrincipalAndInterestPayment={
                newLoanProduct.graceOnPrincipalAndInterestPayment
              }
              graceOnArrearsAgeing={newLoanProduct.graceOnArrearsAgeing}
              daysInMonth={newLoanProduct.daysInMonth}
              daysInyear={newLoanProduct.daysInyear}
              accountType={accountType}
              loading={processing}
              fundSource={fundSource}
              loanPortfolio={loanPortfolio}
              transferInSusp={transferInSusp}
              incomeFromInterest={incomeFromInterest}
              incomeFromFees={incomeFromFees}
              incomeFromPenalties={incomeFromPenalties}
              incomeFromRecovery={incomeFromRecovery}
              overPaymentLia={overPaymentLia}
              lossesWrittenOff={lossesWrittenOff}
              interestReceivable={interestReceivable}
              feesReceivable={feesReceivable}
              penaltiesReceivable={penaltiesReceivable}
              canDefineInstallmentAmount={
                newLoanProduct.canDefineInstallmentAmount
              }
              numberOfDaysLoan={newLoanProduct.numberOfDaysLoan}
              accountMovesOutOfNPAOnlyOnArrearsCompletion={
                newLoanProduct.accountMovesOutOfNPAOnlyOnArrearsCompletion
              }
              graceOnArrearsAgeingValue={
                newLoanProduct.graceOnArrearsAgeingValue
              }
              overdueDaysForNPA={newLoanProduct.overdueDaysForNPA}
              principalThresholdForLastInstallment={
                newLoanProduct.principalThresholdForLastInstallment
              }
              isVariableInstallment={newLoanProduct.isVariableInstallment}
              minimumGap={newLoanProduct.minimumGap}
              maximumGap={newLoanProduct.maximumGap}
              canUseForTopup={newLoanProduct.canUseForTopup}
              isInterestRecalculationEnabled={
                newLoanProduct.isInterestRecalculationEnabled
              }
              preClosureInterestCalculationStrategy={
                newLoanProduct.preClosureInterestCalculationStrategy
              }
              recalculationRestFrequencyInterval={
                newLoanProduct.recalculationRestFrequencyInterval
              }
              interestRecalculationCompoundingMethod={
                newLoanProduct.interestRecalculationCompoundingMethod
              }
              rescheduleStrategyMethod={newLoanProduct.rescheduleStrategyMethod}
              recalculationRestFrequencyType={
                newLoanProduct.recalculationRestFrequencyType
              }
              recalculationCompoundingFrequencyInterval={
                newLoanProduct.recalculationCompoundingFrequencyInterval
              }
              recalculationCompoundingFrequencyType={
                newLoanProduct.recalculationCompoundingFrequencyType
              }
              recalculationCompoundingFrequencyNthDayType={
                newLoanProduct.recalculationCompoundingFrequencyNthDayType
              }
              recalculationCompoundingFrequencyDayOfWeekType={
                newLoanProduct.recalculationCompoundingFrequencyDayOfWeekType
              }
              recalculationCompoundingFrequencyOnDayType={
                newLoanProduct.recalculationCompoundingFrequencyOnDayType
              }
              isArrearsBasedOnOriginalSchedule={
                newLoanProduct.isArrearsBasedOnOriginalSchedule
              }
              recalculationRestFrequencyDayOfWeekType={
                newLoanProduct.recalculationRestFrequencyDayOfWeekType
              }
              recalculationRestFrequencyOnDayType={
                newLoanProduct.recalculationRestFrequencyOnDayType
              }
              recalculationRestFrequencyNthDayType={
                newLoanProduct.recalculationRestFrequencyNthDayType
              }
              placeGuarantee={newLoanProduct.placeGuarantee}
              mandatoryGuarantee={newLoanProduct.mandatoryGuarantee}
              // interestInSuspenseAccountId={
              //   newLoanProduct.interestInSuspenseAccountId
              // }
              minimumGuaranteeFromOwnFunds={
                newLoanProduct.minimumGuaranteeFromOwnFunds
              }
              minimumGuaranteeFromGuarantor={
                newLoanProduct.minimumGuaranteeFromGuarantor
              }
              loanTrancte={newLoanProduct.loanTrancte}
              maxTrancheCount={newLoanProduct.maxTrancheCount}
              outstandingLoanBalance={newLoanProduct.outstandingLoanBalance}
              template={template}
              charges={valueCharges}
              overdueCharges={valueOverdueCharges}
              paymentChannelToFundSourceMappings={
                paymentChannelToFundSourceMappings
              }
              feeToIncomeAccountMappings={feeToIncomeAccountMappings}
              penaltyToIncomeAccountMappings={penaltyToIncomeAccountMappings}
            />
          </AppPreview>
        );
        break;
      default:
    }
    return currentComponet;
  };

  // FUNCTION HERE FOR MOVING TO THE NEXT PAGE
  const handleNextCustomIndexSelect = () => {
    let newIndex = currentScreen + 1;
    if (newIndex <= 4) {
      setCurrentScreen(newIndex);
    }
  };
  // FUNCTION HERE FOR MOVING TO THE PREVIOUS PAGE
  const handlePreviousCustomIndexSelect = () => {
    let newIndex = currentScreen - 1;
    // console.log(newIndex, "and", currentScreen);
    if (newIndex >= 0) {
      setCurrentScreen(newIndex);
    }
  };

  const handleSubmit = async () => {
    let dataToSub = {
      accountingRule: accountType,
      allowAttributeOverrides: {
        amortizationType: newLoanProduct.amortizationType,
        interestType: newLoanProduct.interestType,
        transactionProcessingStrategyId:
          newLoanProduct.transactionProcessingStrategy,
        graceOnPrincipalAndInterestPayment:
          newLoanProduct.graceOnPrincipalAndInterestPayment,
        inArrearsTolerance: newLoanProduct.inArrearsTolerance,
        graceOnArrearsAgeing: newLoanProduct.graceOnArrearsAgeing,
        interestCalculationPeriodType:
          newLoanProduct.interestCalculationPeriodType,
        repaymentEvery: newLoanProduct.repaymentEvery,
      },
      allowVariableInstallments: newLoanProduct.isVariableInstallment,

      fundId: newLoanProduct.fund,
      amortizationType: newLoanProduct.amortization
        ? Number(newLoanProduct.amortization)
        : "",
      isEqualAmortization: newLoanProduct.isEqualAmortization,
      charges: [...fullOverdueCharges , ...fullCharges],
      // overdueCharges: fullOverdueCharges,
      closeDate: formatDate(closeDate, true),
      currencyCode: newLoanProduct.currency,
      dateFormat: "dd MMMM yyyy",
      daysInMonthType: newLoanProduct.daysInMonth
        ? Number(newLoanProduct.daysInMonth)
        : "",
      daysInYearType: newLoanProduct.daysInyear
        ? Number(newLoanProduct.daysInyear)
        : "",
      description: newLoanProduct.description,
      digitsAfterDecimal: newLoanProduct.decimalPlaces,
      fundSourceAccountId: fundSource,
      inMultiplesOf: newLoanProduct.multipleCurrency,
      includeInBorrowerCycle: newLoanProduct.customerLoanCounter
        ? "true"
        : "false",
      installmentAmountInMultiplesOf: newLoanProduct.installment == 0 ? "" : newLoanProduct.installment,
      interestCalculationPeriodType: newLoanProduct.interestCalculation
        ? Number(newLoanProduct.interestCalculation)
        : "",
      interestRateFrequencyType: newLoanProduct.interestSelect
        ? Number(newLoanProduct.interestSelect)
        : "",
      interestRatePerPeriod: newLoanProduct.def_interest
        ? Number(newLoanProduct.def_interest)
        : "",
      interestRateVariationsForBorrowerCycle: numberOfNominal,
      interestType: newLoanProduct.interestMethod
        ? Number(newLoanProduct.interestMethod)
        : "",
      isInterestRecalculationEnabled:
        newLoanProduct.isInterestRecalculationEnabled,
      isLinkedToFloatingInterestRates: false,
      locale: "en",
      allowPartialPeriodInterestCalcualtion:
        newLoanProduct.allowPartialPeriodInterestCalcualtion,
      maxInterestRatePerPeriod: newLoanProduct.max_interest,
      maxNumberOfRepayments: newLoanProduct.max_repayment,
      maxPrincipal: max_pricipal,
      minInterestRatePerPeriod: newLoanProduct.min_interest,
      minNumberOfRepayments: newLoanProduct.min_repayment,
      minPrincipal: min_pricipal,
      name: newLoanProduct.productName,
      numberOfRepaymentVariationsForBorrowerCycle: numberOfRepayment,
      numberOfRepayments: newLoanProduct.def_repayment,
      preClosureInterestCalculationStrategy:
        newLoanProduct.preClosureInterestCalculationStrategy
          ? Number(newLoanProduct.preClosureInterestCalculationStrategy)
          : "",
      principal: def_pricipal,
      principalVariationsForBorrowerCycle: numberOfPrincipalLC,
      repaymentEvery: newLoanProduct.repaid,
      repaymentFrequencyType: newLoanProduct.repaidSelect
        ? Number(newLoanProduct.repaidSelect)
        : "",
      shortName: newLoanProduct.shortName,
      startDate: formatDate(startDate, true),
      transactionProcessingStrategyId:
        newLoanProduct.transactionProcessingStrategyId,
      useBorrowerCycle: terms,
      minimumDaysBetweenDisbursalAndFirstRepayment: newLoanProduct.disbursal,
      graceOnInterestPayment: newLoanProduct.moratonium2,
      graceOnPrincipalPayment: newLoanProduct.moratonium,
      graceOnInterestCharged: newLoanProduct.graceOnInterestCharged,
      inArrearsTolerance: newLoanProduct.inArrearsToleranceValue,
      canDefineInstallmentAmount: newLoanProduct.canDefineInstallmentAmount,
      accountMovesOutOfNPAOnlyOnArrearsCompletion:
        newLoanProduct.accountMovesOutOfNPAOnlyOnArrearsCompletion,
      graceOnArrearsAgeing: newLoanProduct.graceOnArrearsAgeingValue,
      overdueDaysForNPA: newLoanProduct.overdueDaysForNPA,
      principalThresholdForLastInstallment:
        newLoanProduct.principalThresholdForLastInstallment,
      canUseForTopup: newLoanProduct.canUseForTopup,
      recalculationRestFrequencyInterval:
        newLoanProduct.recalculationRestFrequencyInterval,
      interestRecalculationCompoundingMethod:
        newLoanProduct.interestRecalculationCompoundingMethod
          ? Number(newLoanProduct.interestRecalculationCompoundingMethod)
          : 0,
      rescheduleStrategyMethod: newLoanProduct.rescheduleStrategyMethod
        ? Number(newLoanProduct.rescheduleStrategyMethod)
        : "",
      recalculationRestFrequencyType:
        newLoanProduct.recalculationRestFrequencyType
          ? Number(newLoanProduct.recalculationRestFrequencyType)
          : "",
      recalculationCompoundingFrequencyInterval:
        newLoanProduct.recalculationCompoundingFrequencyInterval
          ? Number(newLoanProduct.recalculationCompoundingFrequencyInterval)
          : "",
      recalculationCompoundingFrequencyType:
        newLoanProduct.recalculationCompoundingFrequencyType
          ? Number(newLoanProduct.recalculationCompoundingFrequencyType)
          : "",
      recalculationCompoundingFrequencyNthDayType:
        newLoanProduct.recalculationCompoundingFrequencyNthDayType
          ? Number(newLoanProduct.recalculationCompoundingFrequencyNthDayType)
          : "",
      recalculationCompoundingFrequencyDayOfWeekType:
        newLoanProduct.recalculationCompoundingFrequencyDayOfWeekType
          ? Number(
              newLoanProduct.recalculationCompoundingFrequencyDayOfWeekType
            )
          : "",
      recalculationCompoundingFrequencyOnDayType:
        newLoanProduct.recalculationCompoundingFrequencyOnDayType
          ? Number(newLoanProduct.recalculationCompoundingFrequencyOnDayType)
          : "",
      isArrearsBasedOnOriginalSchedule:
        newLoanProduct.isArrearsBasedOnOriginalSchedule,
      recalculationRestFrequencyDayOfWeekType:
        newLoanProduct.recalculationRestFrequencyDayOfWeekType
          ? Number(newLoanProduct.recalculationRestFrequencyDayOfWeekType)
          : "",
      recalculationRestFrequencyOnDayType:
        newLoanProduct.recalculationRestFrequencyOnDayType
          ? Number(newLoanProduct.recalculationRestFrequencyOnDayType)
          : "",
      recalculationRestFrequencyNthDayType:
        newLoanProduct.recalculationRestFrequencyNthDayType
          ? Number(newLoanProduct.recalculationRestFrequencyNthDayType)
          : "",
      holdGuaranteeFunds: newLoanProduct.placeGuarantee,
      mandatoryGuarantee: newLoanProduct.mandatoryGuarantee,
      minimumGuaranteeFromOwnFunds: newLoanProduct.minimumGuaranteeFromOwnFunds,
      minimumGuaranteeFromGuarantor:
        newLoanProduct.minimumGuaranteeFromGuarantor,
      multiDisburseLoan: newLoanProduct.loanTrancte,
      maxTrancheCount: newLoanProduct.maxTrancheCount,
      outstandingLoanBalance: newLoanProduct.outstandingLoanBalance,
      // interestInSuspenseAccountId: newLoanProduct.interestInSuspenseAccountId,
      // interestOverdueAccountId: newLoanProduct.interestOverdueAccountId,

      loanPortfolioAccountId: loanPortfolio,
      interestOnLoanAccountId: incomeFromInterest,
      incomeFromFeeAccountId: incomeFromFees,
      incomeFromPenaltyAccountId: incomeFromPenalties,
      incomeFromRecoveryAccountId: incomeFromRecovery,
      writeOffAccountId: lossesWrittenOff,
      transfersInSuspenseAccountId: transferInSusp,
      overpaymentLiabilityAccountId: overPaymentLia,
      receivableInterestAccountId: interestReceivable,
      receivableFeeAccountId: feesReceivable,
      receivablePenaltyAccountId: penaltiesReceivable,
      // principalOverdueAccountId: newLoanProduct.principalOverdueAccountId,
      feeToIncomeAccountMappings,
      penaltyToIncomeAccountMappings,
      paymentChannelToFundSourceMappings,
    };
    let dataTosub2 = {
      ...dataToSub,
      minimumGap: newLoanProduct.isVariableInstallment
        ? newLoanProduct.minimumGap
        : null,
      maximumGap: newLoanProduct.isVariableInstallment
        ? newLoanProduct.maximumGap
        : null,
    };
    if (chargesError || detailsError || termsError || settingsError) {
      dispatch(
        Toast({
          text: "Please fill the required fields",
          icon: "error",
          timer: 2000,
        })
      );
    } else {
      setLoading(true);
      await dispatch(
        createLoanProduct(
          newLoanProduct.isVariableInstallment ? dataTosub2 : dataToSub,
          history
        )
      );
      setLoading(false);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Create loan products</h3>
            <p className="sub-title mt-1">
              <span className="text-info mr-1">
                <Link to="/core/products">Products </Link>
              </span>
              /{" "}
              <span className="text-info mr-1">
                <Link to="/core/products/loan-products">Loan products </Link>
              </span>
              / Create loan products
            </p>
          </div>
        </div>

        <div className="row" style={{ minHeight: "calc(100vh - 250px)" }}>
          <div className="col-sm-12 col-md-12">
            <ScrollToTop>
              <ContentWrapper className="application-wrapper">
                {renderComponent()}
              </ContentWrapper>
            </ScrollToTop>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateLoanProductView;
