import React from "react";

import { Select, Input } from "@chakra-ui/react";
import { Select as AntdSelect } from "antd";

import { FormElements, ActionButton } from "../styles";

const ContactAddress = ({
  defaultAddress,
  setAddresses,
  addresses,
  createCustomerHandler,
  address,
  countryData,
  stateData,
  loading,
}) => {
  let {
    addressTypeIdOptions = [],
    countryIdOptions = [],
    stateProvinceIdOptions = [],
  } = address[0] || {};

  const handleAddAddress = () => {
    setAddresses([...addresses, defaultAddress]);
  };

  const handleAddressChange = (value, index, paramter) => {
    let hhh = [...addresses];
    hhh[index][paramter] = value;
    setAddresses(hhh);
  };

  const handleRemoveAddress = (position) => {
    let hhh = [];
    addresses.forEach((element, index) => {
      if (position !== index) {
        hhh.push(element);
      }
    });
    setAddresses(hhh);
  };

  let { Option } = AntdSelect;


  return (
    <>
      <div
        className={
          addresses && addresses.length && addresses.length > 1 ? "w-8" : "w-6"
        }
      >
        <FormElements>
          <div className="row">
            {addresses.map((address, index) => {
              return (
                <div
                  className={
                    addresses && addresses.length && addresses.length > 1
                      ? "col-md-6 col-sm-12 p-relative"
                      : "col-md-12 p-relative"
                  }
                  key={index}
                >
                  {addresses && addresses.length && addresses.length > 1 ? (
                    <div
                      className="remove-icon"
                      onClick={() => handleRemoveAddress(index)}
                    >
                      <img
                        src={
                          require("../../../../../assets/images/icons/cancel.svg")
                            .default
                        }
                        className="detail-cancel-icon"
                        alt="cancel icon"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Address Type*</label>
                    <Select
                      placeholder="Select address type"
                      size="md"
                      name="addressTypeId"
                      value={address.addressTypeId}
                      onChange={(e) => {
                        handleAddressChange(
                          e.target.value,
                          index,
                          "addressTypeId"
                        );
                      }}
                    >
                      {addressTypeIdOptions &&
                        addressTypeIdOptions.map(({ id, name }) => {
                          return (
                            <option value={id} key={id}>
                              {name}
                            </option>
                          );
                        })}
                    </Select>
                  </div>
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Address*</label>
                    <div style={{ width: "60%" }}>
                      <Input
                        style={{ width: "100%" }}
                        size="md"
                        name="addressLine1"
                        value={address.addressLine1}
                        onChange={(e) => {
                          handleAddressChange(
                            e.target.value,
                            index,
                            "addressLine1"
                          );
                        }}
                      />
                    </div>
                  </div>{" "}
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Address Line 2</label>
                    <div style={{ width: "60%" }}>
                      <Input
                        style={{ width: "100%" }}
                        size="md"
                        name="addressLine2"
                        value={address.addressLine2}
                        onChange={(e) => {
                          handleAddressChange(
                            e.target.value,
                            index,
                            "addressLine2"
                          );
                        }}
                      />
                    </div>
                  </div>{" "}
                  {/* <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Address Line 3</label>
                    <div style={{ width: "60%" }}>
                      <Input
                        style={{ width: "100%"}}
                        size="md"
                        name="addressLine3"
                        value={address.addressLine3}
                        onChange={(e) => {
                          handleAddressChange(
                            e.target.value,
                            index,
                            "addressLine3"
                          );
                        }}
                      />
                    </div>
                  </div> */}
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Country*</label>
                    <AntdSelect
                      showSearch
                      placeholder="Select country"
                      optionFilterProp="children"
                      className="chakra-select__wrapper"
                      value={address.countryValue}
                      onChange={(value) => {
                        handleAddressChange("", index, "stateProvinceId");
                        handleAddressChange(value, index, "countryValue");
                      }}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {countryData &&
                        countryData.length > 0 &&
                        countryData.map(({ id, name }) => {
                          return (
                            <option value={name} key={id}>
                              {name}
                            </option>
                          );
                        })}
                    </AntdSelect>
                  </div>
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">State*</label>
                    <AntdSelect
                      showSearch
                      placeholder="Select state"
                      optionFilterProp="children"
                      className="chakra-select__wrapper"
                      onChange={(value) => {
                        handleAddressChange(value, index, "stateProvinceId");
                      }}
                      autoComplete="false"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      value={address.stateProvinceId}
                    >
                      {stateData &&
                        stateData.length > 0 &&
                        stateData.map(({ id, name }) => {
                          return (
                            <Option value={id} key={id}>
                              {name}
                            </Option>
                          );
                        })}
                    </AntdSelect>
                  </div>{" "}
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">City</label>
                    <div style={{ width: "60%" }}>
                      <Input
                        style={{ width: "100%" }}
                        size="md"
                        name="city"
                        value={address.city}
                        onChange={(e) => {
                          handleAddressChange(e.target.value, index, "city");
                        }}
                      />
                    </div>
                  </div>
                  {/* <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Postal Code*</label>
                    <div style={{ width: "60%" }}>
                      <Input
                        style={{ width: "100%"}}
                        size="md"
                        type="number"
                        name="postalCode"
                        value={address.postalCode}
                        onChange={(e) => {
                          handleAddressChange(
                            e.target.value,
                            index,
                            "postalCode"
                          );
                        }}
                      />
                    </div>
                  </div> */}
                  {/* {addresses &&
                  addresses.length &&
                  addresses.length === index + 1 ? (
                    <div className="row">
                      <div className="spaceBetweenFlexEnd w-100">
                        <div className="">
                          <ActionButton
                            color="#2C1DFF"
                            style={{ margin: "0px 0px 0 10px" }}
                            onClick={handleAddAddress}
                          >
                            <img
                              src={
                                require("../../../../../assets/images/icons/add.svg")
                                  .default
                              }
                              alt="add"
                            />
                            Add Address
                          </ActionButton>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )} */}
                </div>
              );
            })}
          </div>
        </FormElements>
      </div>
    </>
  );
};

export default ContactAddress;
