import React from "react";
import { ActionButton, FormClass, ModalText } from "../styles";
import { Modal } from "react-bootstrap";

const RemoveDataTable = ({ onHide }) => {
  return (
    <>
      <Modal.Header>
        <Modal.Title>Remove data table check</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "95%" }}>
          <ModalText>
            Are you sure you want to remove Entity Data Table Check?
          </ModalText>
          <div className="float-right mt-4">
            <ActionButton color="#2C1DFF" onClick={onHide}>
              <img
                src={
                  require("../../../../../../assets/images/icons/cancel.svg")
                    .default
                }
                alt="cancel icon"
              />
              Cancel
            </ActionButton>
            <ActionButton color="#2C1DFF" style={{ margin: "0px 0px 0 10px" }}>
              <img
                src={
                  require("../../../../../../assets/images/icons/delete.svg")
                    .default
                }
                alt="submit icon"
              />
              Delete
            </ActionButton>
          </div>
        </FormClass>
      </Modal.Body>
    </>
  );
};

export default RemoveDataTable;
