import React from "react";
// import DashboardLayout from "../../../layouts/dashboardLayout/index.jsx";
import { ContentWrapper, ContentCard, ActionButton } from "./styles";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CheckPermission from "../../../../../util/CheckPermission";

const CardListings = [
  {
    icon: require("../../../../../assets/images/icons/tax-component.svg")
      .default,
    title: "Manage Tax Component",
    description: "Refine tax components",
    route: "manage-tax-configuration/manage-tax-components",
    permission: "READ_TAXCOMPONENT",
  },
  {
    icon: require("../../../../../assets/images/icons/tax-product.svg").default,
    title: "Manage Tax Groups",
    description: "Refine taxing groups",
    route: "manage-tax-configuration/manage-tax-groups",
    permission: "READ_TAXGROUP",
  },
];

const ManageTaxView = ({ history }) => {
  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;
  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Manage Tax Configuration</h3>
            <p className="sub-title">
              Define charges for Loan, Savings and Deposit chargse
            </p>
          </div>
        </div>
        <ContentWrapper>
          <div className="row">
            {CardListings.map(
              ({ icon, title, description, route, permission }, i) =>
                CheckPermission(permission, permissions) && (
                  <div className="col-sm-12 col-md-4 col-lg-3 mb-4" key={i}>
                    <Link to={route}>
                      <ContentCard>
                        <img
                          className="teller-card-icon"
                          src={icon}
                          alt={title}
                        />
                        <div className="p-2">
                          <p className="teller-head">{title}</p>
                          <p className="teller-sub-menu">{description}</p>
                        </div>
                      </ContentCard>
                    </Link>
                  </div>
                )
            )}
          </div>
        </ContentWrapper>
      </div>
    </>
  );
};

export default ManageTaxView;
