import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { FormControl, Select, Input } from "@chakra-ui/react";
import { FormElements, ActionButton } from "../styles";
import { updateOnlyCustomerInfo } from "../../../../../actions/customerActions";
import Toast from "../../../../../util/Toast";
import CheckPermission from "../../../../../util/CheckPermission";
import { formatDate } from "../../../../../util/FormatDate";
import { Select as AntSelect } from "antd";
import {
  getAllCountryCodes,
  getCodeValues,
} from "../../../../../actions/utilityActions";

const PersonalInformation = ({
  userData,
  genderOptions,
  clientNonPersonConstitutionOptions,
  clientLegalFormOptions,
  id,
}) => {
  const dispatch = useDispatch();

  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [middlename, setMiddlename] = useState("");
  const [legalFormId, setLegalFormId] = useState("1");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [genderId, setGenderId] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyIncValidityDate, setCompanyIncValidityDate] = useState("");
  const [companyIncNumber, setCompanyIncNumber] = useState("");
  const [constitutionId, setConstitutionId] = useState("");
  const [remarks, setRemarks] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [countryCodeId, setCountryCodeId] = useState("");
  const [externalId, setExternalId] = useState("");
  const [submittedOnDate, setSubmittedOnDate] = useState("");

  const [valueChanged, setValueChanged] = useState(false);
  const [allCountryCodes, setAllCountryCodes] = useState([]);

  const perFormOperation = (data) => {
    const { submittedOnDate } = data?.timeline || {};

    if (data && data.legalForm && data.legalForm.id) {
      setLegalFormId(
        data && data.legalForm && data.legalForm.id && String(data.legalForm.id)
      );
    } else {
      setLegalFormId("1");
    }

    if (data && data.gender && data.gender.id) {
      setGenderId(data && data.gender && data.gender.id);
    }

    setFirstname(data && data?.firstname);
    setLastname(data && data?.lastname);
    setMiddlename(data && data?.middlename);
    setMobileNo(data && data?.mobileNo);
    setCountryCodeId(data && data?.countryCode?.id);
    setExternalId(data && data?.externalId);
    setEmailAddress(data && data?.emailAddress);
    setDateOfBirth(data && data?.dateOfBirth && new Date(data?.dateOfBirth));
    setSubmittedOnDate(data && submittedOnDate && new Date(submittedOnDate));
    // setSubmittedOnDate(
    //   data && data?.timeline.submittedOnDate
    //   // new Date(data?.timeline?.submittedOnDate)
    // );

    // For Company
    setCompanyName(data && data.fullname);
    setCompanyIncValidityDate(
      data &&
        data.clientNonPersonDetails &&
        data.clientNonPersonDetails.incorpValidityTillDate &&
        new Date(data.clientNonPersonDetails.incorpValidityTillDate)
    );
    setCompanyIncNumber(
      data &&
        data.clientNonPersonDetails &&
        data.clientNonPersonDetails.incorpNumber
    );
    if (
      data &&
      data.clientNonPersonDetails &&
      data.clientNonPersonDetails.constitution &&
      data.clientNonPersonDetails.constitution.id
    ) {
      setConstitutionId(
        data &&
          data.clientNonPersonDetails &&
          data.clientNonPersonDetails.constitution &&
          data.clientNonPersonDetails.constitution.id
      );
    }
    setRemarks(
      data && data.clientNonPersonDetails && data.clientNonPersonDetails.remarks
    );
  };

  useEffect(() => {
    if (userData) {
      perFormOperation(userData);
    }
    dispatch(getCodeValues());
  }, [userData]);

  const allCodeValues = useSelector((state) => state.codeValues);
  let { codeValues } = allCodeValues;

  useEffect(() => {
    if (!!codeValues && codeValues.length > 0) {
      const countryCode = codeValues.find((e) => e.name === "CountryCode").id;
      dispatch(getAllCountryCodes(`codes/${countryCode}/codevalues`));
    }
  }, [allCodeValues]);

  const countryCodesDetails = useSelector(
    (state) => state.countryCodesDetails.countryCodesDetails
  );

  useEffect(() => {
    if (!!countryCodesDetails && countryCodesDetails.length > 0) {
      setAllCountryCodes(countryCodesDetails);
    }
  }, [countryCodesDetails]);

  const handleCancel = () => {
    setValueChanged(false);
    perFormOperation(userData);
  };

  const handleCustomerEdit = () => {
    if (!legalFormId) {
      dispatch(Toast({ text: "Please Select a Legal Form", icon: "info" }));
    } else if (
      (legalFormId === "0" || legalFormId === "1") &&
      (!firstname || !lastname)
    ) {
      dispatch(Toast({ text: "Please Provide Customer Name", icon: "info" }));
    } else if (legalFormId === "2" && !companyName) {
      dispatch(Toast({ text: "Please Provide Entity Name", icon: "info" }));
    } else if (legalFormId === "2" && !emailAddress) {
      dispatch(
        Toast({ text: "Please Provide Entity Email Address", icon: "info" })
      );
    } else {
      let userData = {
        firstname,
        lastname,
        middlename,
        genderId,
        mobileNo,
        countryCodeId,
        externalId,
        emailAddress,
        legalFormId,
        submittedOnDate: submittedOnDate ? formatDate(submittedOnDate) : "",
        dateOfBirth: dateOfBirth ? formatDate(dateOfBirth) : "",
        locale: "en",
        dateFormat: "dd MMMM yyyy",
      };
      let clientNonPersonDetails = {
        incorpNumber: companyIncNumber,
        remarks,
        locale: "en",
        dateFormat: "dd MMMM yyyy",
        incorpValidityTillDate:
          (companyIncValidityDate !== "" &&
            formatDate(companyIncValidityDate)) ||
          "",
        constitutionId,
      };
      let companyData = {
        clientNonPersonDetails,
        fullname: companyName,
        legalFormId,
        locale: "en",
        dateFormat: "dd MMMM yyyy",
        emailAddress: emailAddress,
        mobileNo: mobileNo,
        countryCodeId: countryCodeId,
        externalId: externalId,
      };
      if (!dateOfBirth) {
        delete userData.dateOfBirth;
      }
      dispatch(
        updateOnlyCustomerInfo(
          legalFormId === "1" ? userData : companyData,
          id,
          handleCancel
        )
      );
    }
  };

  // Change the keys of id and name to label and value because that is what ANT SELECT understands.
  const newCountryCodes =
    allCountryCodes &&
    allCountryCodes.map(({ name: label, id: value, ...rest }) => ({
      label,
      value,
      ...rest,
    }));
  const handleSelected = (selected) => {
    const selectedCountryCode = allCountryCodes.find(
      (e) => e.id === selected
    ).id;
    setCountryCodeId(selectedCountryCode);
    setValueChanged(true);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          marginTop: "10px",
        }}
      >
        <div style={{ width: "60%" }}>
          <FormElements>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Legal Form*</label>
              <Select
                size="md"
                name="legalFormId"
                className="general-font-size"
                value={legalFormId}
                onChange={(e) => {
                  setLegalFormId(e.target.value);
                  setValueChanged(true);
                }}
                disabled
              >
                {!!clientLegalFormOptions.length
                  ? clientLegalFormOptions.map(({ value, id }) => {
                      return (
                        <option value={id} id={id}>
                          {value}
                        </option>
                      );
                    })
                  : null}
              </Select>
            </div>
            {legalFormId === "1" || legalFormId === "0" ? (
              <>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">First Name*</label>
                  <Input
                    style={{ width: "60%" }}
                    size="md"
                    name="firstname"
                    className="general-font-size"
                    value={firstname}
                    onChange={(e) => {
                      setFirstname(e.target.value);
                      setValueChanged(true);
                    }}
                  />
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Middle Name*</label>
                  <Input
                    style={{ width: "60%" }}
                    size="md"
                    name="firstname"
                    className="general-font-size"
                    value={middlename}
                    onChange={(e) => {
                      setMiddlename(e.target.value);
                      setValueChanged(true);
                    }}
                  />
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Last Name*</label>
                  <Input
                    style={{ width: "60%" }}
                    size="md"
                    name="lastname"
                    className="general-font-size"
                    value={lastname}
                    onChange={(e) => {
                      setLastname(e.target.value);
                      setValueChanged(true);
                    }}
                  />
                </div>{" "}
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Date Of Birth</label>
                  <FormControl style={{ width: "60%" }}>
                    <div className="datepicker-100-width">
                      <DatePicker
                        id="published-date"
                        selected={dateOfBirth}
                        onChange={(date) => {
                          setDateOfBirth(date);
                          setValueChanged(true);
                        }}
                        peekNextMonth
                        showMonthDropdown
                        className="general-font-size"
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="dd/mmm/yyyy"
                      />
                    </div>
                  </FormControl>
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Gender</label>
                  <Select
                    placeholder="Select Gender"
                    size="md"
                    className="general-font-size"
                    name="genderId"
                    value={genderId}
                    onChange={(e) => {
                      setGenderId(e.target.value);
                      setValueChanged(true);
                    }}
                  >
                    {!!genderOptions.length
                      ? genderOptions.map(({ name, id }) => {
                          return (
                            <option value={id} id={id}>
                              {name}
                            </option>
                          );
                        })
                      : null}
                  </Select>
                </div>
                {/* <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Marital Status</label>
                <Select
                  placeholder="Select Gender"
                  size="md"
                  name="maritalId"
                  value={maritalId}
                  onChange={createCustomerHandler}
                >
                  <option>Male</option>
                  <option>Female</option>
                </Select>
              </div> */}
              </>
            ) : (
              <>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Name*</label>
                  <Input
                    style={{ width: "60%" }}
                    size="md"
                    name="companyName"
                    className="general-font-size"
                    value={companyName}
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                      setValueChanged(true);
                    }}
                  />
                </div>{" "}
                {/* <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Incorporation date</label>
                <FormControl style={{ width: "60%" }}>
                  <DatePicker
                    id="incorporation-date"
                    selected={companyIncDate}
                    onChange={(date) => setCompanyIncDate(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="dd/mm/yyyy"
                  />
                </FormControl>
              </div> */}
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">
                    Incorporation Validity Till Date
                  </label>
                  <FormControl style={{ width: "60%" }}>
                    <div className="datepicker-100-width">
                      <DatePicker
                        id="incorporation-validity-date"
                        className="general-font-size"
                        selected={companyIncValidityDate}
                        onChange={(date) => {
                          setCompanyIncValidityDate(date);
                          setValueChanged(true);
                        }}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="dd/mm/yyyy"
                      />
                    </div>
                  </FormControl>
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Incorporation Number</label>
                  <Input
                    style={{ width: "60%" }}
                    size="md"
                    className="general-font-size"
                    type="number"
                    name="companyIncNumber"
                    value={companyIncNumber}
                    onChange={(e) => {
                      setCompanyIncNumber(e.target.value);
                      setValueChanged(true);
                    }}
                  />
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Constitution</label>
                  <Select
                    placeholder="Select Constitution"
                    size="md"
                    className="general-font-size"
                    name="constitutionId"
                    value={constitutionId}
                    onChange={(e) => {
                      setConstitutionId(e.target.value);
                      setValueChanged(true);
                    }}
                  >
                    {!!clientNonPersonConstitutionOptions.length
                      ? clientNonPersonConstitutionOptions.map(
                          ({ name, id }) => {
                            return (
                              <option value={id} id={id}>
                                {name}
                              </option>
                            );
                          }
                        )
                      : null}
                  </Select>
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Remarks</label>
                  <Input
                    style={{ width: "60%" }}
                    size="md"
                    name="remarks"
                    value={remarks}
                    className="general-font-size"
                    onChange={(e) => {
                      setRemarks(e.target.value);
                      setValueChanged(true);
                    }}
                  />
                </div>
              </>
            )}
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Email</label>
              <Input
                style={{ width: "60%" }}
                size="md"
                g
                name="emailAddress"
                className="general-font-size"
                value={emailAddress}
                onChange={(e) => {
                  setEmailAddress(e.target.value);
                  setValueChanged(true);
                }}
              />
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Country code</label>

              <AntSelect
                loading={true}
                style={{ width: "60%" }}
                showSearch
                dropdownMatchSelectWidth={true}
                placeholder="Search country code"
                showArrow={false}
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                value={countryCodeId}
                onSelect={(selected) => handleSelected(selected)}
                options={newCountryCodes}
              ></AntSelect>
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Mobile Number</label>
              <Input
                style={{ width: "60%" }}
                size="md"
                type="text"
                className="general-font-size"
                name="mobileNo"
                value={mobileNo}
                onChange={(e) => {
                  setMobileNo(e.target.value);
                  setValueChanged(true);
                }}
              />
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">External ID</label>
              <Input
                style={{ width: "60%" }}
                size="md"
                type="text"
                className="general-font-size"
                name="externalId"
                value={externalId}
                onChange={(e) => {
                  setExternalId(e.target.value);
                  setValueChanged(true);
                }}
              />
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label"> Submitted on</label>
              <FormControl style={{ width: "60%" }}>
                <div className="datepicker-100-width">
                  <DatePicker
                    id="published-date"
                    className="general-font-size"
                    selected={submittedOnDate}
                    onChange={(date) => {
                      setSubmittedOnDate(date);
                      setValueChanged(true);
                    }}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="dd/mmm/yyyy"
                  />
                </div>
              </FormControl>
            </div>
          </FormElements>
          <div className="row">
            {valueChanged ? (
              <div className="spaceBetweenFlexEnd w-100 my-4 mr-5">
                <ActionButton
                  color="#2C1DFF"
                  style={{ margin: "0px 0px 0 10px" }}
                  onClick={handleCancel}
                >
                  Cancel
                </ActionButton>
                {CheckPermission("UPDATE_CLIENT", permissions) ? (
                  <>
                    {" "}
                    <ActionButton
                      color="#2C1DFF"
                      style={{ margin: "0px 0px 0 10px" }}
                      onClick={handleCustomerEdit}
                    >
                      Update
                    </ActionButton>{" "}
                  </>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalInformation;
