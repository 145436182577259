import { Modal } from "antd";
import React from "react";
import DatePicker from "react-datepicker";
import { NewActionButton, ResetButton } from "../styles";
import closeModal from "../../../../assets/images/icons/closePayment.svg";
import { formatDateString } from "../../../../util/FormatDate";
import { useDispatch } from "react-redux";
import { getNibssReports, resetFilteredReports } from "../../../../actions/nibssConnectActions";

const FilterReportModal = ({
  filterModal,
  close,
  onHide,
  fromDate,
  toDate,
  setFromDate,
  setToDate,
  handleFilter,
}) => {
  //   const clearFilter = () => {
  //     onClear && onClear();
  //     handleFromDate("");
  //     handleToDate("");
  //   };
  const dispatch = useDispatch();

  if (!filterModal) {
    return null;
  }

  const handleFilterReset = () => {
    dispatch(resetFilteredReports());
    dispatch(getNibssReports("pageNo=0&pageSize=10"));
    setFromDate("");
    setToDate("");
    onHide();
  };

  return (
    // <div onClick={() => close()}>
    <div className="modafilterNibbs">
      {/* datepicker */}
      <div className="">
        <div className="modal-bodyNibss">
          <div className="download__close-modal" onClick={onHide}>
            <img src={closeModal} alt="" style={{ width: "8px" }} />
          </div>
          <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
            <div
              className="col-sm-12 col-md-6 px-0 mb-3 p-1"
              style={{ width: "70%" }}
            >
              {/* fromDate */}
              <label htmlFor="startDate" className="nibsstext-info">
                {" "}
                Start date
              </label>
              <div className="datepicker-100-width">
                <DatePicker
                  id="from-date"
                  selected={fromDate}
                  onChange={(date) => setFromDate(date)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="Start date"
                />
              </div>
            </div>

            <div
              className="col-sm-12 col-md-6 px-0 mb-3 p-1"
              style={{ width: "70%" }}
            >
              {/* toDate */}
              <label htmlFor="endDate" className="nibsstext-info">
                End date
              </label>
              <div className="datepicker-100-width">
                <DatePicker
                  id="to-date"
                  selected={toDate}
                  onChange={(date) => setToDate(date)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="End date"
                />
              </div>
            </div>
          </div>
        </div>
        {/* Download */}
        <div className="modal-footerNibss">
          <NewActionButton
            color="#2C1DFF"
            className="nibss__download"
            style={{ margin: "0px 0px" }}
            onClick={(e) => handleFilter(e)}
          >
            Submit
          </NewActionButton>
          <ResetButton
            color="#2C1DFF"
            className="nibss__download"
            style={{ margin: "0px 0px" }}
            onClick={(e) => handleFilterReset(e)}
          >
            Reset
          </ResetButton>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default FilterReportModal;
