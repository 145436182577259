import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { ActionButton } from '../../styles.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from '@chakra-ui/input';
import { Tabs } from 'antd';
import { getDataTablesAction } from '../../../../actions/tellerActions';

const DataTableModal = ({ onHide, savingsAccId }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.isLoading);
  const { dataTables, dataTablesError } = useSelector(
    (state) => state.tellerReducer
  );

  const { TabPane } = Tabs;

  useEffect(() => {
    if (savingsAccId) {
      dispatch(getDataTablesAction(savingsAccId));
    }
  }, []);

  return isLoading ? (
    ''
  ) : (
    <>
      <Modal.Header>
        <Modal.Title>Account Details</Modal.Title>
      </Modal.Header>
      <Modal.Body className="wc-modal">
        <div className="mb-4">
          {!dataTables?.loops?.length ? (
            <h4
              className="h5"
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              {dataTablesError[0]?.defaultUserMessage || "Savings Account has no data"}
            </h4>
          ) : (
            <Tabs defaultActiveKey="1">
              {dataTables?.loops?.map(({ loopName, data }, idx) => {
                const index = idx + 1;
                let newData = Object.entries(data);

                let result = newData.map((each) => {
                  return (
                    <div style={{ margin: '10px 20px' }}>
                      <p className="mr-4 h6 text-capitalize">
                        {each[0]?.includes('_')
                          ? each[0].split('_').join(' ')
                          : each[0]}
                      </p>
                      <Input
                        className="mb-3 font-weight-bold"
                        style={{ fontSize: '25px !important' }}
                        value={each[1]}
                      />
                    </div>
                  );
                });

                return (
                  <TabPane tab={loopName} key={index}>
                    {result}
                  </TabPane>
                );
              })
            }
            </Tabs>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-2">
          <ActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require('../../../../assets/images/icons/cancel.svg').default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default DataTableModal;
