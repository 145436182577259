import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { Table, Modal } from "react-bootstrap";
// import ModalContainer from "../../../../components/ModalContainer";
import {
  FormControl,
  Checkbox,
  Input,
  Textarea,
  Select,
} from "@chakra-ui/react";

import { FormClass, FormElements, IconButton } from "../styles.jsx";
import CurrencyFormat from "react-currency-format";

const EditFixedInterestRate = ({
  validFrom,
  handleValidFrom,
  endDate,
  handleEndDate,
  template,
  // interests,
  // setInterests,
  // defaultData,
  setChartSlabs,
  chartSlabs,
  editFixedDepositAccountHandler,
  newFixedDepositAccount
}) => {
  // let defaultData = {
  //   annualInterestRate : "",
  //   locale: "en",
  //   periodType : "1",
  //   fromPeriod: "1",

  // }
  // const [isPrimary, setIsPrimary] = useState(false);

  // const handleOnChangeOfPeriodType = (index, value) => {
  //   let hhh = [...interests];
  //   hhh[Number(index)].periodType = value;
  //   setInterests(hhh);
  // };

  // const handleOnChangeOfFromPeriod = (index, value) => {
  //   let hhh = [...interests];
  //   hhh[Number(index)].fromPeriod = value;
  //   setInterests(hhh);
  // };

  // const handleOnChangeOfToPeriod = (index, value) => {
  //   let hhh = [...interests];
  //   hhh[Number(index)].toPeriod = value;
  //   setInterests(hhh);
  // };

  // //   handle interest change
  // // const handleOnChangeOfInterest = (value) => {
  // //   setInterests((oldInterests) => ({
  // //     ...oldInterests,
  // //     ...interests,
  // //     annualInterestRate: value,
  // //   }));
  // // };

  // // const handleOnChangeOfInterest = (value) => {
  // //   interests.annualInterestRate = value;
  // //   setInterests(interests);
  // // };

  // // Handle handleOnChangeOfInterest
  // const handleOnChangeOfInterest = (value, index) => {
  //   setChartSlabs((oldInterest) => {
  //     const newInterest = [...oldInterest];
  //     newInterest[index].annualInterestRate = value;
  //     return newInterest;
  //   });
  // };

  // const handleOnChangeOfInterest = (value) => {
  //   setInterests((oldInterests) => ({...oldInterests, annualInterestRate: value}));
  // };

  // const [modalState, toggleModal] = useState(false);
  // const [currentIncentives, setCurrentIncentives] = useState([]);
  // const [currentIndex, setCurrentIndex] = useState();

  // const [interests, setInterests] = useState(defaultData);

  return (
    <>
      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        {/* <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-end">
                <label className="text-label d-flex flex-align-center">
                  Valid from date
                </label>
                <FormControl style={{ width: "60%" }}>
                  <div className="datepicker-100-width">
                    <DatePicker
                      id="start-date"
                      selected={validFrom}
                      onChange={(date) => handleValidFrom(date)}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="dd/mmm/yyyy"
                    />
                  </div>
                </FormControl>
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-end">
                <label className="text-label d-flex flex-align-center">
                  End date
                </label>
                <FormControl style={{ width: "60%" }}>
                  <div className="datepicker-100-width">
                    <DatePicker
                      id="close-date"
                      selected={endDate}
                      onChange={(date) => handleEndDate(date)}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="dd/mmm/yyyy"
                    />
                  </div>
                </FormControl>
              </div>
            </FormElements>
          </FormClass>
        </div> */}
      </div>
      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              {/* { */}
              {/* // chartSlabs?.length ?
                // chartSlabs?.map((interest, index) => { */}
              {/* return ( */}
              <>
                <div className="form-group d-flex justify-content-between align-items-end">
                  <label className="text-label d-flex flex-align-center">
                    Interest
                  </label>
                  <Input
                    style={{ width: "60%" }}
                    name="nominalAnnualInterest"
                    value={newFixedDepositAccount.nominalAnnualInterest}
                    onChange={editFixedDepositAccountHandler}
                    size="md"
                  />
                  {/* <p style={{ width: "60%" }}>{interest.annualInterestRate}</p> */}
                </div>
              </>
              {/* ); */}
              {/* // })
              // : null} */}
            </FormElements>
          </FormClass>

          {/* <Table>
            <thead>
              <tr style={{ backgroundColor: "#EDEFF5" }}>
                <th>{isPrimary ? "Amount range" : "Period type"}</th>
                <th>{isPrimary ? "Period type" : "Period from"}</th>
                <th>{isPrimary ? "Period from" : "Amount range"}</th>
                <th>Interest</th>
                <th>Description</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {!!interests &&
                !!interests.length &&
                interests.map((interest, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {isPrimary ? (
                          <>
                            <div className="d-flex">
                              <Input
                                name="amountRangeFrom"
                                value={interest.amountRangeFrom}
                                onChange={(e) =>
                                  handleOnChangeOfAmountRangeFrom(
                                    index,
                                    e.target.value
                                  )
                                }
                                size="md"
                              />{" "}
                            </div>{" "}
                          </>
                        ) : (
                          <>
                            {" "}
                            <Select
                              size="md"
                              name="periodType"
                              value={Number(interest.periodType)}
                              onChange={(e) =>
                                handleOnChangeOfPeriodType(
                                  index,
                                  e.target.value
                                )
                              }
                            >
                              {template !== undefined &&
                                !!template.chartTemplate &&
                                !!template.chartTemplate.periodTypes &&
                                !!template.chartTemplate.periodTypes.length &&
                                template.chartTemplate.periodTypes.map(
                                  (cur) => (
                                    <option
                                      id={cur.id}
                                      value={cur.id}
                                      key={cur.id}
                                    >
                                      {cur.value}
                                    </option>
                                  )
                                )}
                            </Select>{" "}
                          </>
                        )}
                      </td>
                      <td>
                        {isPrimary ? (
                          <>
                            {" "}
                            <Select
                              size="md"
                              name="periodType"
                              value={Number(interest.periodType)}
                              onChange={(e) =>
                                handleOnChangeOfPeriodType(
                                  index,
                                  e.target.value
                                )
                              }
                            >
                              {template !== undefined &&
                                !!template.chartTemplate &&
                                !!template.chartTemplate.periodTypes &&
                                !!template.chartTemplate.periodTypes.length &&
                                template.chartTemplate.periodTypes.map(
                                  (cur) => (
                                    <option
                                      id={cur.id}
                                      value={cur.id}
                                      key={cur.id}
                                    >
                                      {cur.value}
                                    </option>
                                  )
                                )}
                            </Select>{" "}
                          </>
                        ) : (
                          <>
                            {" "}
                            <div className="d-flex">
                              <Input
                                name="fromPeriod"
                                value={interest.fromPeriod}
                                onChange={(e) =>
                                  handleOnChangeOfFromPeriod(
                                    index,
                                    e.target.value
                                  )
                                }
                                size="md"
                              />
                            </div>{" "}
                          </>
                        )}
                      </td>
                      <td>
                        {isPrimary ? (
                          <>
                            {" "}
                            <div className="d-flex">
                              <Input
                                name="fromPeriod"
                                value={interest.fromPeriod}
                                onChange={(e) =>
                                  handleOnChangeOfFromPeriod(
                                    index,
                                    e.target.value
                                  )
                                }
                                size="md"
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="d-flex">
                              <Input
                                name="amountRangeFrom"
                                value={interest.amountRangeFrom}
                                onChange={(e) =>
                                  handleOnChangeOfAmountRangeFrom(
                                    index,
                                    e.target.value
                                  )
                                }
                                size="md"
                              />{" "}
                            </div>{" "}
                          </>
                        )}
                      </td>
                      <td>
                        <Input
                          name="annualInterestRate"
                          value={interest.annualInterestRate}
                          onChange={(e) =>
                            handleOnChangeOfInterest(index, e.target.value)
                          }
                          size="md"
                        />
                      </td>
                      <td>
                        <Textarea
                          size="md"
                          name="description"
                          value={interest.description}
                          onChange={(e) =>
                            handleOnChangeOfDescription(index, e.target.value)
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table> */}
        </div>
      </div>
    </>
  );
};

export default EditFixedInterestRate;
