import React, { useRef, useEffect, useState } from "react";
import {
  ContentWrapper,
  ActionButtonsGroup,
  ActionButton,
  IconButton,
} from "./styles";
import { InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllUsers,
  filterUsers,
  clearFilteredUsers,
  getUserTemplate,
} from "../../../../actions/configurationActions";
import CheckPermission from "../../../../util/CheckPermission";
import Toast from "../../../../util/Toast";
import TableLoader from "./TableLoader";
import NewTeller from "./components/NewTeller";
import EditUser from "./components/EditUser";
import ViewUser from "./components/ViewUser";
import DeleteUser from "./components/DeleteUser";
import EditUserPassword from "./components/ChangeUserPassword";
import ModalContainer from "../../components/ModalContainer";

const UsersView = (props) => {
  const dispatch = useDispatch();

  const userList = useSelector((state) => state.userReducer);

  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  const { loading, users, filtered } = userList;

  users.sort((a, b) => (a.firstname > b.firstname ? 1 : -1));

  const userTemplate = useSelector((state) => state.userTemplate);

  const { template } = userTemplate;
  const search = useRef("");

  const onFilterUser = (e) => {
    search.current.value !== ""
      ? dispatch(filterUsers(e.target.value))
      : dispatch(clearFilteredUsers());
  };
  useEffect(() => {
    dispatch(clearFilteredUsers());
    dispatch(getUserTemplate());
    dispatch(getAllUsers());
    if (filtered === null) {
      search.current.value = "";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (filtered !== null && filtered.length === 0) {
    dispatch(
      Toast({
        text: "No user found",
        icon: "error",
        timer: 2000,
      })
    );
  }

  const [modalState, toggleModal] = useState(false);
  const [modalContentName, setModalContentName] = useState("");
  const [selectedUserId, setSelectedUserId] = useState("");

  const [selectedUser, setSelectedUser] = useState({});
  // const currentContent = (e) => {
  //   const val = e.currentTarget.id.trim();

  //   if (val) {
  //     toggleModal(true);
  //     setModalContentName(val);
  //   }
  // };

  const handleEditUser = (id) => {
    setSelectedUser(users.filter((usr) => usr.id === id)[0]);
    toggleModal(true);
    setModalContentName("editUser");
  };
  const handleViewUser = (id) => {
    setSelectedUser(users.filter((usr) => usr.id === id)[0]);
    toggleModal(true);
    setModalContentName("viewUser");
  };
  const handleDeleteUser = (id) => {
    setSelectedUserId(id);
    toggleModal(true);
    setModalContentName("deleteUser");
  };

  const handleEditUserPassword = (id) => {
    setSelectedUserId(id);
    toggleModal(true);
    setModalContentName("editUserPassword");
  };
  const handleModalClose = () => toggleModal(false);
  const displayModalContent = () => {
    switch (modalContentName) {
      case "newTeller":
        return <NewTeller onHide={handleModalClose} />;
      case "editUser":
        return (
          <EditUser
            template={template}
            user={selectedUser}
            onHide={handleModalClose}
          />
        );
      case "deleteUser":
        return <DeleteUser id={selectedUserId} onHide={handleModalClose} />;
      case "viewUser":
        return (
          <ViewUser
            id={selectedUserId}
            user={selectedUser}
            onHide={handleModalClose}
            template={template}
          />
        );
      case "editUserPassword":
        return (
          <EditUserPassword id={selectedUserId} onHide={handleModalClose} />
        );
      default:
        return;
    }
  };

  //TABLE CONFIGURATION
  const dataSource = [];
  const filteredUsers = [];

  if (filtered !== null) {
    filtered.forEach((user) => {
      const { id, firstname, lastname, email, officeName } = user;
      filteredUsers.push({
        key: id,
        // image: (
        //   <div className="profile-image ml-2">
        //     <img
        //       src={require("../../../../assets/images/avatar.png").default}
        //       alt="user"
        //     />
        //   </div>
        // ),
        firstname,
        lastname,
        email,
        office: officeName,
        action: (
          <>
            <IconButton
              id="viewUser"
              onClick={() => handleViewUser(id)}
              className="mr-2 mb-2"
            >
              <img
                src={
                  require("../../../../assets/images/icons/view.svg").default
                }
                alt="view"
              />
            </IconButton>
            <IconButton
              id="editUser"
              onClick={() => handleEditUser(id)}
              className="mr-2 mb-2"
            >
              <img
                src={
                  require("../../../../assets/images/icons/edit-profile.svg")
                    .default
                }
                alt="edit"
              />
            </IconButton>
            <IconButton
              className="mr-2 mb-2"
              onClick={() => handleEditUserPassword(id)}
            >
              <img
                src={
                  require("../../../../assets/images/icons/password2.svg")
                    .default
                }
                alt="password"
              />
            </IconButton>
            <IconButton id="deleteUser" onClick={() => handleDeleteUser(id)}>
              <img
                src={
                  require("../../../../assets/images/icons/delete.svg").default
                }
                alt="delete"
                className="icon-img"
              />
            </IconButton>
          </>
        ),
      });
    });
  }

  !loading &&
    users.forEach((user) => {
      const { id, firstname, lastname, email, officeName } = user;
      dataSource.push({
        key: id,
        // image: (
        //   <div className="profile-image ml-2">
        //     <img
        //       src={require("../../../../assets/images/avatar.png").default}
        //       alt="user"
        //     />
        //   </div>
        // ),
        firstname,
        lastname,
        email,
        office: officeName,
        action: (
          <>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleViewUser(id);
              }}
              className="mr-2 mb-2"
            >
              <img
                src={
                  require("../../../../assets/images/icons/view.svg").default
                }
                alt="view"
              />
            </IconButton>
            <IconButton
              id="editUser"
              onClick={(e) => {
                e.stopPropagation();
                handleEditUser(id);
              }}
              className="mr-2 mb-2"
            >
              <img
                src={
                  require("../../../../assets/images/icons/edit-profile.svg")
                    .default
                }
                alt="edit"
              />
            </IconButton>
            <IconButton
              className="mr-2 mb-2"
              onClick={(e) => {
                e.stopPropagation();
                handleEditUserPassword(id);
              }}
            >
              <img
                src={
                  require("../../../../assets/images/icons/password2.svg")
                    .default
                }
                alt="password"
              />
            </IconButton>
            <IconButton
              id="deleteUser"
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteUser(id);
              }}
            >
              <img
                src={
                  require("../../../../assets/images/icons/delete.svg").default
                }
                alt="delete"
                className="icon-img"
              />
            </IconButton>
          </>
        ),
      });
    });

  const columns = [
    // { title: "", dataIndex: "image", key: "image" },
    { title: "First Name", dataIndex: "firstname", key: "firstName" },
    { title: "Last Name", dataIndex: "lastname", key: "lastName" },
    { title: "Email Address", dataIndex: "email", key: "email" },
    { title: "Office", dataIndex: "office", key: "office" },
    // { title: "", dataIndex: "action", key: "action" },
  ];

  return (
    <>
      <ModalContainer show={modalState} dialogClassName="document_infoModal">
        {modalState && displayModalContent()}
      </ModalContainer>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">User Management</h3>
            <p className="sub-title">Create and edit customer information</p>
            {/* <p className="font-weight sub-title mt-2">
              <span className="text-info">
                <Link to="/core/configuration">Configuration</Link>
              </span>{" "}
              / Users
            </p> */}
          </div>
          <div className="col-sm-12 col-md-6 px-0 pt-3">
            <ActionButtonsGroup>
              <div className="col-sm-12 col-md-6 pr-2">
                <InputGroup>
                  <InputLeftElement
                    width="3.1rem"
                    height="38px"
                    pointerEvents="none"
                  >
                    <img
                      src={
                        require("../../../../assets/images/icons/search.svg")
                          .default
                      }
                      style={{ height: "30px" }}
                      alt="Icon"
                      className="icon-img"
                    />
                  </InputLeftElement>
                  <Input
                    style={{
                      background: "#ffffff",
                      height: "35px",
                    }}
                    type="text"
                    className="general-font-size"
                    ref={search}
                    onChange={onFilterUser}
                    placeholder="Search by name /email /office"
                  />
                </InputGroup>
              </div>
              <div className="col-sm-12 col-md-6 p-0 d-flex">
                <Link to="user-management/new-user">
                  {CheckPermission("CREATE_USER", permissions) ? (
                    <ActionButton>
                      <img
                        src={
                          require("../../../../assets/images/icons/add.svg")
                            .default
                        }
                        alt="add"
                      />
                      New User
                    </ActionButton>
                  ) : (
                    ""
                  )}
                </Link>
              </div>
            </ActionButtonsGroup>
          </div>
        </div>
        {CheckPermission("READ_USER", permissions) && (
          <ContentWrapper>
            {loading ? (
              <TableLoader />
            ) : (
              <>
                {filtered !== null ? (
                  <Table
                    columns={columns}
                    dataSource={filteredUsers}
                    onRow={(r) => ({
                      onClick: () =>
                        props.history.push(
                          `/core/user-management/details/${r.key}`
                        ),
                    })}
                    scroll={{ x: true }}
                  />
                ) : (
                  <Table
                    columns={columns}
                    dataSource={dataSource}
                    pagination={{ defaultPageSize: 13 }}
                    scroll={{ x: true }}
                    onRow={(r) => ({
                      onClick: () =>
                        props.history.push(
                          `/core/user-management/details/${r.key}`
                        ),
                    })}
                    defaultPageSize={13}
                  />
                )}
              </>
            )}
          </ContentWrapper>
        )}
      </div>
    </>
  );
};

export default UsersView;
