import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";
import { Link } from "react-router-dom";
import { ContentWrapper } from "../styles";
import { ActionButton } from "../../styles";
import { getAJournalEntry } from "../../../../../actions/accountingActions";
import ReverseModal from "./ReverseModal";
import ModalContainer from "../../../components/ModalContainer";
import { formatCurrency } from "../../../../../util/FormatAmount";
import { formatDate } from "../../../../../util/FormatDate";

const columns = [
  { title: "Entry ID", dataIndex: "entryId", key: "entryId" },
  { title: "Type", dataIndex: "type", key: "type" },
  {
    title: "Account",
    dataIndex: "account",
    key: "account",
  },
  {
    title: "Debit",
    dataIndex: "debit",
    key: "debit",
  },
  {
    title: "Credit",
    dataIndex: "credit",
    key: "credit",
  },
  {
    title: "Comment",
    dataIndex: "comment",
    key: "comment",
  },
];

const JournalEntryDetail = ({ history }) => {
  const dispatch = useDispatch();
  const accountingData = useSelector((state) => state.accountingData);
  let { oneJournalEntry = {} } = accountingData;

  // const [modalState, toggleModal] = useState(false);
  const [reverseSuccessModal, setReverseSuccessModal] = useState(false);
  const [modalContentName, setModalContentName] = useState("");



  let { pageItems = [] } = oneJournalEntry;
  let firstItem = !!pageItems ? pageItems[0] : {};

  let {
    officeName,
    transactionDate = [],
    createdByUserName,
    createdDate = [],
    reversed,
    comments,
    referenceNumber
  } = firstItem || {};

  let journalTableData = [];
  !!pageItems &&
    pageItems?.forEach((singleData) => {
      const {
        glAccountType,
        id,
        entryType: { value },
        glAccountName,
        glAccountCode,
        amount,
        comments,
      } = singleData;
      journalTableData.push({
        entryId: !!id ? id : "",
        comment: comments,
        type: !!glAccountType ? glAccountType.value : "",
        account: !!glAccountName ? `${glAccountName} ${glAccountCode}` : "",
        debit:
          value === "DEBIT"
            ? !!amount
              ? formatCurrency(Number(amount))
              : ""
            : "",
        credit:
          value === "CREDIT"
            ? !!amount
              ? formatCurrency(Number(amount))
              : ""
            : "",
      });
    });

  const [isReversed, setIsReversed] = useState('');

  useEffect(() => {
    setIsReversed(reversed)
  }, [firstItem])
  


  let { location = {} } = history || {};
  let { pathname = "" } = location;
  let splitedPathname = pathname.split("/", 10);
  let myId = splitedPathname[splitedPathname.length - 1];

  const [viewState, setViewState] = useState(1);

  useEffect(() => {
    dispatch(getAJournalEntry(myId));
  }, []);

  const currentContent = (e) => {
    const val = e.currentTarget.id.trim();

    if (val) {
      // toggleModal(true);
      setReverseSuccessModal(true);
      setModalContentName(val);
    }
  };

  const refreshPageData = () => {
    dispatch(getAJournalEntry(myId));
  }

  // const handleModalClose = () => toggleModal(false);
  const handleModalClose = () => setReverseSuccessModal(false);

  const displayModalContent = () => {
    switch (modalContentName) {
      case "reverseTransaction":
        return (
          <ReverseModal
            transactionId={myId}
            onHide={handleModalClose}
            history={history}
            refreshPageData= {refreshPageData}
          />
        );
      default:
        return;
    }
  };

  return (
    <>
      {/* <ModalContainer show={modalState}> */}
        {/* {modalState && displayModalContent()} */}
      <ModalContainer show={reverseSuccessModal}>
        {reverseSuccessModal && displayModalContent()}
      </ModalContainer>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6">
            <h3 className="page-title">Journal Entry Details</h3>
            <p className="sub-title">
              Manual add journal entry transaction recorded in a journal
            </p>
            <p className="sub-title mt-2">
              <span className="text-info mr-1">
                <Link to="/core/add-journal-entries">Add Journal Entry</Link>
              </span>{" "}
              / View Transaction
            </p>
          </div>
        </div>
        <ContentWrapper>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <p className="pHeader">
                Transaction Number - {!!myId ? myId : ""}
              </p>
              <p className="pHeader accountText-label">
                Reference Number - {!!referenceNumber ? referenceNumber : ""}
              </p>
            </div>{" "}
            <div className="col-md-6 col-sm-12">
              <div className="spaceEndFlex mb-3">
                {!!reversed ? (
                  <div className="">
                    <p className="warningText">This Transaction Was Reversed</p>
                  </div>
                ) : (
                  <div className="">
                    <ActionButton
                      color="#2C1DFF"
                      className="float-right"
                      style={{ margin: "0px 0px" }}
                      // onClick={() => setViewState(2)}
                      onClick={currentContent}
                      id="reverseTransaction"
                    >
                      <img
                        src={
                          require("../../../../../assets/images/icons/reverse.svg")
                            .default
                        }
                        className="d-inline mr-3"
                        alt="icon"
                      />
                      Reverse
                    </ActionButton>
                  </div>
                )}
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-xl-6 col-md-12">
              <div className="row mt-4">
                <div className="col-4">
                  <p className="lineHeader">Office</p>
                </div>
                <div className="col-8">
                  <p>{!!officeName && officeName?.length ? officeName : ""}</p>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-md-12">
              <div className="row mt-4">
                <div className="col-4">
                  <p className="lineHeader">Transaction date</p>
                </div>
                <div className="col-8">
                  <p>
                    {!!transactionDate && transactionDate?.length ? formatDate(transactionDate) : " "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-md-12">
              <div className="row mt-4">
                <div className="col-4">
                  <p className="lineHeader">Created by</p>
                </div>
                <div className="col-8">
                  <p>
                    {!!createdByUserName && createdByUserName?.length
                      ? createdByUserName
                      : ""}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-md-12">
              <div className="row mt-4">
                <div className="col-4">
                  <p className="lineHeader">Created On</p>
                </div>
                <div className="col-8">
                  <p>
                    {" "}
                    {!!createdDate && createdDate?.length
                      ? formatDate(createdDate)
                      : " "}
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="col-xl-6 col-md-12">
              <div className="row mt-4">
                <div className="col-4">
                  <p className="lineHeader">Comment</p>
                </div>
                <div className="col-8">
                  <p>{!!comments ? comments : ""}</p>
                </div>
              </div>
            </div> */}
          </div>
          <div className="row mt-5">
            <div className="col-xl-12">
              <Table
                columns={columns}
                dataSource={journalTableData}
                defaultPageSize={8}
              />
            </div>
          </div>
        </ContentWrapper>
      </div>
    </>
  );
};

export default JournalEntryDetail;
