import React from "react";
import { ContentWrapper, ActionButtonsGroup, IconButton } from "./styles";
import { Table } from "antd";
import { Link } from "react-router-dom";

const GLTransactionsView = () => {
  // table configuration
  const columns = [
    { title: "Transaction ID", dataIndex: "tid", key: "tid" },
    {
      title: "Date & Time",
      dataIndex: "date",
      key: "date",
    },
    { title: "Transaction EFT", dataIndex: "teft", key: "teft" },
    { title: "Transaction ch.", dataIndex: "transch", key: "transch" },
    { title: "Amount", dataIndex: "amount", key: "amount" },
    { title: "Issuer Acct", dataIndex: "issueracc", key: "issueracc" },
    { title: "Issuer Hold EFT", dataIndex: "issuerhold", key: "issuerhold" },
    {
      title: "Recipients Acct",
      dataIndex: "recipientsacc",
      key: "recipientsacc",
    },
    {
      title: "Recipients Hold EFT",
      dataIndex: "recipientsHold",
      key: "recipientsHold",
    },
    { title: "Status", dataIndex: "status", key: "status" },
  ];

  const dataSource = [...Array(17)].map((e, i) => ({
    key: i,
    tid: "3e23wsqwe...",
    date: "04/22/2020 02:40",
    teft: "Transfer",
    transch: "NIP",
    amount: "₦20,000.00",
    issueracc: "1000012334",
    issuerhold: "WoodCore - Obi Ala...",
    recipientsacc: "2232231112",
    recipientsHold: "GTB - Olukemi Ab...",
    status: "Success",
  }));
  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-5 pb-3">
            <h3 className="page-title">GL Transactions</h3>
            <p className="sub-title">
              A complete list of client’s transactions in your business
            </p>
            <p className="font-weight sub-title mt-2">
              <span className="text-info">
                <Link to="/core/transactions">Transactions</Link>
              </span>{" "}
              / GL Transactions
            </p>
          </div>
          <div className="col-sm-12 col-md-7 px-0">
            <ActionButtonsGroup>
              <div className="form-group mb-3">
                <select className="custom-select" custom>
                  <option>NGN</option>
                </select>
              </div>
              <div className="form-group mb-3">
                <select className="custom-select" custom>
                  <option>Account Type</option>
                </select>
              </div>
              <div className="form-group mb-3">
                <select className="custom-select px-4" custom>
                  <option>From: 04/20/2020</option>
                </select>
              </div>
              <div className="form-group mb-3">
                <select className="custom-select px-4" custom>
                  <option>From: 04/22/2020</option>
                </select>
              </div>
              <IconButton>
                <img
                  src={
                    require("../../../../assets/images/icons/downloadArr.svg")
                      .default
                  }
                  alt="edit"
                />
              </IconButton>
            </ActionButtonsGroup>
          </div>
        </div>

        <ContentWrapper>
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={{ defaultPageSize: 15 }}
            defaultPageSize={15}
            scroll={{ x: true }}
          />
        </ContentWrapper>
      </div>
    </>
  );
};

export default GLTransactionsView;
