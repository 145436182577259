import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
// import DatePicker from "react-datepicker";
import { Input } from "@chakra-ui/react";
import { FormClass, ActionButton, FormElements } from "../styles";
import { approveFixedDepositAccount, undoFixedDepositAccount } from "../../../../../actions/fixedDeposit";

const UndoApprovalModal = ({ onHide, history, id }) => {
  const dispatch = useDispatch();

  const [description, setDescription] = useState("");

  const handleApprove = () => {
    let dataToSend = {
      note: description,
    };
    dispatch(
      undoFixedDepositAccount(id, dataToSend, history, onHide , "undoapproval")
    );
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>Undo Approve Of Fixed Deposit Account</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <FormElements style={{ width: "100%" }}>
            {/* <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Approve On*</label>
              <FormControl style={{ width: "70%" }}>
                <DatePicker
                  id="published-date"
                  selected={approveOn}
                  onChange={(date) => setApproveOn(date)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="dd/mmm/yyyy"
                />
              </FormControl>
            </div> */}
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Note</label>
              <Input
                value={description}
                name="description"
                style={{ width: "70%" }}
                onChange={(e) => setDescription(e.target.value)}
              ></Input>
            </div>
          </FormElements>
        </FormClass>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex mt-4">
          <ActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require("../../../../../assets/images/icons/cancel.svg").default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={(e) => {
              e.preventDefault();
              handleApprove();
            }}
          >
            <img
              src={
                require("../../../../../assets/images/icons/save.svg").default
              }
              alt="submit icon"
            />
            Submit
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default UndoApprovalModal;
