import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteGuarantor,
  getAllGuarantorPerId,
} from "../../../../../actions/SavingsAndLoanActions";
import { Modal } from "react-bootstrap";
import { ContentWrapper, ActionButton, TabButton } from "../styles";

export const DeleteGuarantor = ({ data, handleCloseModal }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    const guarantorFundingId = data?.guarantorFundingDetails ? data?.guarantorFundingDetails[0].id : null;
    setLoading(true);
    dispatch(deleteGuarantor(data?.loanId, data?.id, guarantorFundingId));
    setLoading(false);
    handleCloseModal();
  };
  return (
    <>
      <Modal.Header>
        <Modal.Title className="text-capitalize">Delete guarantor</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center flex-direction-coloumn">
        <div className="m-1">
          <p style={{ fontSize: "16px" }}>
            Are you sure you want to delete this loan guarantor?
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-1">
          <ActionButton color="#2C1DFF" onClick={handleCloseModal}>
            <img
              src={
                require("../../../../../assets/images/icons/cancel.svg").default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={handleSubmit}
          >
            <img
              src={
                require("../../../../../assets/images/icons/save.svg").default
              }
              alt="submit icon"
            />
            Delete
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};
