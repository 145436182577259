import React, { useEffect, useState } from "react";
import { Divider, Select } from "@chakra-ui/react";
import { Card } from "antd";
import { Link } from "react-router-dom";
import { ActionButton, DownloadButton, FilterTransButton } from "../styles";
import {
  Tabs,
  Tab,
  // OverlayTrigger, Tooltip
} from "antd";
// } from "react-bootstrap";
import { TabContentWrapper, ContentCard } from "../styles";
import InflowTab from "../Tab/Inflow";
import OutflowTab from "../Tab/Outflow";
import ModalContainer from "../../components/ModalContainer";
import DownloadReportModal from "./DownloadModal";
import PaymentModal from "./MakePayment/PaymentModal";
import OtpModal from "./MakePayment/OtpModal";
import {
  getNibssConnectTenants,
  getNibssConnectTenantsWallets,
  getNibssInflowData,
  getNibssInflowDataFilter,
  getNibssInflowDataFilterDownload,
  getNibssOutflowDataFilter,
  getNibssTenantsAccountBalance,
  resetFilteredTransactionsInflow,
  resetTenantDetails,
  // getOutflowData,
  // getTenantsNibss,
} from "../../../../actions/nibssConnectActions";
import { useDispatch, useSelector } from "react-redux";
import CheckPermission from "../../../../util/CheckPermission";
import { formatCurrency } from "../../../../util/FormatAmount";
import inflow from "../../../../assets/images/icons/inflow-arrow.svg";
import outflow from "../../../../assets/images/icons/outflow-arrow.svg";
import SuccessModal from "./MakePayment/SuccessModal";
import { getTenantLoggedIn } from "../../../../actions/preAuthActions";
import FilterReportModal from "./DownloadModal";
import { formatDateString } from "../../../../util/FormatDate";
import InflowFilterReportModal from "./InflowFilterModal";
import OutFlowFilterReportModal from "./DownloadModal";
import Toast from "../../../../util/Toast";
import ManageInflow from "./ManageInflow";
import ManageOutflow from "./ManageOutflow";

const NibssCustomerView = () => {
  const dispatch = useDispatch();

  const [tenantIdentifier, setTenantIdentifier] = useState("");
  const [tenantCode, SetTenantCode] = useState("");

  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  const nibssTenantsTemplate = useSelector(
    (state) => state.nibssTenantDetailsTemplate
  );
  const nibssOutflowListFilter = useSelector(
    (state) => state.nibssGetFilteredTransTemplate
  );
  const { nibssGetFilteredTransTemplate } = nibssOutflowListFilter;

  const { nibssTenantsDetailsTemplate: { data = [] } = {} } =
    nibssTenantsTemplate;

  const [tenantIdentifier2, setTenantIdentifier2] = useState("");
  const [tenantData, setTenantData] = useState([]);

  useEffect(() => {
    setTenantData(data);
  }, [data]);

  // Get tenant identifier
  useEffect(() => {
    setTenantIdentifier2(localStorage.getItem("tenant"));
  }, []);

  // Filter tenant Identifier
  useEffect(() => {
    if (tenantData && tenantData.length > 0) {
      let tenantResult = tenantData.filter(
        (tenant) => tenant.tenantIdentifier === tenantIdentifier2
      );
      // const result = tenantData.filter(checkTenantIdentifier);
      let tenantId = tenantResult?.map((c) => c.tenantIdentifier);
      setTenantIdentifier(tenantId[0]);

      // function checkTenantIdentifier(d) {
      //   if (d.tenantIdentifier === tenantIdentifier2) {
      //     setTenantIdentifier(result)
      //   }
      // }
      // tenantData?.filter((d) => {
      //   if (tenantIdentifier2 === d.tenantIdentifier) {
      //     setTenantIdentifier(d.tenantIdentifier)
      //   }
      // });
    }

    // let bigCities = cities.filter(city => city.population > 3000000);
  }, [tenantData]);

  const nibssTenantsWalletTemplate = useSelector(
    (state) => state.nibssTenantsWalletTemplate
  );
  const { nibssTenantsWalletsTemplate } = nibssTenantsWalletTemplate;

  const nibssTenantsAccountBalanceTemplate = useSelector(
    (state) => state.nibssTenantsAccountBalanceTemplate
  );

  const { loading, nibssTenantAccountBalance } =
    nibssTenantsAccountBalanceTemplate || {};
  const { summary: { availableBalance = {}, currency = {} } = {} } =
    nibssTenantAccountBalance || {};

  const [walletNumberId, setWalletNumber] = useState("");
  const [filterModal, setFilterModal] = useState(false);
  const [makePaymentModal, setMakePaymentModal] = useState(false);
  // const [modalContentName, setModalContentName] = useState("");
  const [otpModal, setOtpModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  // Beneficiary
  const [receiverSessionId, setReceiverSessionId] = useState("");
  const [receiverChannelCode, setReceiverChannelCode] = useState("");
  const [receiverInstitutionCode, setReceiverInstitutionCode] = useState("");
  const [receiverAccountNumber, setReceiverAccountNumber] = useState("");
  const [receiverBvn, setReceiverBvn] = useState("");
  const [receiverKyc, setReceiverKyc] = useState("");
  const [receiverAccountName, setReceiverAccountName] = useState("");

  // Sender
  const [senderBvn, setSenderBvn] = useState("");
  const [senderAccountName, setSenderAccountName] = useState("");
  const [senderAccountNumber, setSenderAccountNumber] = useState("");
  const [senderKyc, setSenderKyc] = useState("");
  const [transferNarration, setTransferNarration] = useState("");
  const [transferAmount, setTransferAmount] = useState("");
  const [clientNuban, setClientNuban] = useState("");

  const [toReportDate, setToReportDate] = useState("");
  const [fromReportDate, setFromReportDate] = useState("");

  const [toInflowDate, setToInflowDate] = useState("");
  const [fromInflowDate, setFromInflowDate] = useState("");

  const [bankType, setBankType] = useState({
    accountValue: "",
    accountCode: "",
  });
  const [inflowModal, setInflowModal] = useState(false);

  const handleModalClose = () => setMakePaymentModal(false);
  const handleOtpModalClose = () => setOtpModal(false);
  // const onHide = () => setFilterModal(false);
  const handleInflowModalClose = () => setInflowModal(false);

  const [currentView, setCurrentView] = useState(1);

  const onHide = () => {
    setFilterModal(false);
    // setFromReportDate("")
    // setToReportDate("")
  };

  // clear Date input
  useEffect(() => {
    if (!nibssGetFilteredTransTemplate?.pageItem) {
      setToReportDate("");
      setFromReportDate("");
    }
  }, [nibssGetFilteredTransTemplate?.pageItem]);

  // getNibssConnectTenants
  useEffect(() => {
    if (tenantIdentifier2) {
      dispatch(getNibssConnectTenants(tenantIdentifier2));
    }
    // dispatch(getNibssConnectTenants());
  }, [tenantIdentifier2]);

  // getNibssConnectTenantsWallets
  useEffect(() => {
    // dispatch(getNibssConnectTenantsWallets(id));
    if (tenantIdentifier) {
      dispatch(getNibssConnectTenantsWallets(tenantIdentifier));
    }
  }, [tenantIdentifier]);

  // Get tenantCode
  useEffect(() => {
    if (tenantData.length > 0) {
      // let newTenantCode = tenantData.map((x) => {
      SetTenantCode(tenantData[0]?.tenantInstitutionCode);
      // });
    } else {
      SetTenantCode("");
    }
  }, [tenantData]);


  // Reset Tenant code
  useEffect(() => {
    resetTenantDetails();
  }, []);

  //  Get Bakance start ===========
  const getBalance = () => {
    if (walletNumberId && walletNumberId !== null) {
      dispatch(getNibssTenantsAccountBalance(walletNumberId));
    }
  };
  useEffect(() => {
    getBalance();
  }, [dispatch, walletNumberId]);

  const nibssInflowListFilter = useSelector(
    (state) => state.nibssInflowFilteredTransTemplate
  );

  const { nibssInflowFilteredTransTemplate } = nibssInflowListFilter;

  let filteredData = nibssInflowFilteredTransTemplate?.pageItems;
  let filteredTotalRecord =
    nibssInflowFilteredTransTemplate?.totalFilteredRecords;
  //  Get Balance End ===========

  // const dataSource = [];

  const handleFilterTrans = () => {
    if (fromReportDate && toReportDate) {
      let startDate = formatDateString(fromReportDate, "yyyy-MMMM-dd");
      let endDate = formatDateString(toReportDate, "yyyy-MMMM-dd");

      dispatch(
        getNibssOutflowDataFilter(
          "orderBy=createdAt&sortBy=DESC&limit=10000&offset=0",
          tenantCode,
          startDate,
          endDate,
          onHide
        )
      );
    }
  };

  // let inflowKey = 1;
  // let outflowKey = 2;

  const handleInwardFilterTrans = () => {
    if (fromInflowDate && toInflowDate) {
      let startDate = formatDateString(fromInflowDate, "yyyy-MMMM-dd");
      let endDate = formatDateString(toInflowDate, "yyyy-MMMM-dd");

      dispatch(
        getNibssInflowDataFilter(
          "orderBy=createdAt&sortBy=DESC&limit=10000&offset=0",
          tenantCode,
          startDate,
          endDate,
          handleInflowModalClose
        )
      );
    }
  };

  const handleInwardFilterTransDownload = () => {
    if (fromInflowDate && toInflowDate) {
      let startDate = formatDateString(fromInflowDate, "yyyy-MMMM-dd");
      let endDate = formatDateString(toInflowDate, "yyyy-MMMM-dd");

      dispatch(
        getNibssInflowDataFilterDownload(
          "orderBy=createdAt&sortBy=DESC",
          tenantCode,
          startDate,
          endDate,
          handleInflowModalClose
        )
      );
    }
  };

  return (
    <>
      {/* <ModalContainer
        show={makePaymentModal}
        dialogClassName="document_infoModal"
      >
        {makePaymentModal && displayModalContent()}
      </ModalContainer> */}
      {CheckPermission("CREATE_INTERTRANSFER", permissions) ? (
        <div className="container-fluid">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Manage account</h3>
            <p className="Nibss-sub-title">
              <span className="text-info">
                <Link to="/core/nibss-management">NIBSS Integration</Link>
              </span>{" "}
              / Customer overall details
            </p>
          </div>

          {/* Make Payment  */}
          {/* <div className="col-sm-12 col-md-12 p-0 d-flex justify-content-end">
            {/* {CheckPermission("", permissions) && ( 
            <ActionButton
              onClick={() => setMakePaymentModal(true)}
              // onClick={currentContent}
              id="paymentModal"
            >
              Make Payment
              <img
                src={
                  require("../../../../assets/images/icons/nibss-arrow.svg")
                    .default
                }
                alt="right arrow"
              />
            </ActionButton>
            {/* )} 
          </div> */}

          {/* Nibss Account and Account Balance */}
          <div className="col-sm-12 col-md-6 pb-3">
            <Card style={{ marginBottom: "20px" }}>
              <div className="acct__card">
                {/* Nibss Account */}
                <div className="acct__container">
                  <h3 className="acct__text ">NIBSS Account</h3>
                  <div className="select-100-width">
                    <Select
                      placeholder="Select Account"
                      size="md"
                      className="text-capitalize"
                      name="accName"
                      value={walletNumberId}
                      onChange={(e) => setWalletNumber(e.target.value)}
                    >
                      {nibssTenantsWalletsTemplate !== undefined &&
                      nibssTenantsWalletsTemplate
                        ? nibssTenantsWalletsTemplate &&
                          nibssTenantsWalletsTemplate?.map((item) => {
                            return (
                              <option value={item.walletId} key={item.walletId}>
                                {item.walletNumber}
                              </option>
                            );
                          })
                        : null}
                    </Select>
                  </div>
                </div>
                <Divider orientation="vertical" className="divider__nibss" />
                {/* Account Balance */}
                <div className="acct__balance">
                  <h3 className="acct__text ">Account balance</h3>
                  <div>
                    <span className="balance__style">
                      <h1>
                        <span>{currency?.code ? currency?.code : "NGN"}</span>{" "}
                        {formatCurrency(
                          Number(
                            availableBalance &&
                              walletNumberId &&
                              !isNaN(availableBalance)
                              ? availableBalance
                              : "0.00"
                          )
                        )}
                      </h1>
                    </span>
                  </div>
                </div>
              </div>
            </Card>
          </div>

          {/* inflow / outflow */}
          <div className="col-sm-12 col-md-12 pb-3 ">
            <Card>
              <div className="row d-flex">
                <div className="col-sm-12 col-md-12">
                  <TabContentWrapper>
                    <Tabs defaultActiveKey="1">
                      <Tabs.TabPane
                        tab={
                          <span className="inflowArrow">
                            Inflow
                            <img
                              src={inflow}
                              alt=""
                              style={{ width: "10px" }}
                            />
                          </span>
                        }
                        key="1"
                      >
                        <ManageInflow
                          // dataSource={dataSource}
                          toInflowDate={toInflowDate}
                          setToInflowDate={setToInflowDate}
                          fromInflowDate={fromInflowDate}
                          setFromInflowDate={setFromInflowDate}
                          onHide={handleInflowModalClose}
                          handleInwardFilterTrans={handleInwardFilterTrans}
                          handleInwardFilterTransDownload={
                            handleInwardFilterTransDownload
                          }
                          setInflowModal={setInflowModal}
                          inflowModal={inflowModal}
                          tenantCode={tenantCode}
                          // key=
                          // history={history}
                          // userData={singleUser}
                        />
                      </Tabs.TabPane>
                      <Tabs.TabPane
                        tab={
                          <span className="outflowArrow">
                            Outflow
                            <img
                              src={outflow}
                              alt=""
                              style={{ width: "10px" }}
                            />
                          </span>
                        }
                        key="2"
                      >
                        <ManageOutflow
                          toReportDate={toReportDate}
                          fromReportDate={fromReportDate}
                          handleFilterTrans={handleFilterTrans}
                          onHide={onHide}
                          setFilterModal={setFilterModal}
                          filterModal={filterModal}
                          tenantCode={tenantCode}
                          // key={key}

                          // userData={singleUser}
                          // history={history}
                        />
                      </Tabs.TabPane>
                    </Tabs>
                  </TabContentWrapper>
                </div>
                {/* Download History */}
              </div>
            </Card>
          </div>

          <ModalContainer
            show={makePaymentModal}
            dialogClassName="document_infoModal"
          >
            <PaymentModal
              makePaymentModal={makePaymentModal}
              setMakePaymentModal={setMakePaymentModal}
              onHide={handleModalClose}
              otpModal={otpModal}
              setOtpModal={setOtpModal}
              walletNumberId={walletNumberId}
              setReceiverSessionId={setReceiverSessionId}
              setReceiverChannelCode={setReceiverChannelCode}
              setReceiverInstitutionCode={setReceiverInstitutionCode}
              setReceiverAccountNumber={setReceiverAccountNumber}
              setReceiverBvn={setReceiverBvn}
              setReceiverKyc={setReceiverKyc}
              setSenderBvn={setSenderBvn}
              receiverSessionId={receiverSessionId}
              receiverChannelCode={receiverChannelCode}
              receiverInstitutionCode={receiverInstitutionCode}
              receiverAccountNumber={receiverAccountNumber}
              receiverBvn={receiverBvn}
              receiverKyc={receiverKyc}
              senderBvn={senderBvn}
              setReceiverAccountName={setReceiverAccountName}
              setSenderAccountName={setSenderAccountName}
              setSenderAccountNumber={setSenderAccountNumber}
              setSenderKyc={setSenderKyc}
              setTransferNarration={setTransferNarration}
              setTransferAmount={setTransferAmount}
              receiverAccountName={receiverAccountName}
              senderAccountName={senderAccountName}
              senderAccountNumber={senderAccountNumber}
              senderKyc={senderKyc}
              transferNarration={transferNarration}
              transferAmount={transferAmount}
              bankType={bankType}
              setBankType={setBankType}
              setClientNuban={setClientNuban}
              clientNuban={clientNuban}
              tenantIdentifier={tenantIdentifier}
            />
          </ModalContainer>
          <ModalContainer show={otpModal} dialogClassName="document_infoModal">
            <OtpModal
              otpModal={otpModal}
              setOtpModal={setOtpModal}
              onHide={handleOtpModalClose}
              setMakePaymentModal={setMakePaymentModal}
              receiverSessionId={receiverSessionId}
              receiverChannelCode={receiverChannelCode}
              receiverInstitutionCode={receiverInstitutionCode}
              receiverAccountNumber={receiverAccountNumber}
              receiverAccountName={receiverAccountName}
              receiverBvn={receiverBvn}
              receiverKyc={receiverKyc}
              // Sender
              senderBvn={senderBvn}
              senderAccountName={senderAccountName}
              senderAccountNumber={senderAccountNumber}
              senderKyc={senderKyc}
              transferNarration={transferNarration}
              transferAmount={transferAmount}
              successModal={successModal}
              setSuccessModal={setSuccessModal}
              clientNuban={clientNuban}
            />
          </ModalContainer>
          <ModalContainer
            show={successModal}
            dialogClassName="document_infoModal"
          >
            <SuccessModal
              successModal={successModal}
              setSuccessModal={setSuccessModal}
              onHide={handleOtpModalClose}
            />
          </ModalContainer>

          <InflowFilterReportModal
            onHide={handleInflowModalClose}
            setInflowModal={setInflowModal}
            inflowModal={inflowModal}
            toInflowDate={toInflowDate}
            setToInflowDate={setToInflowDate}
            fromInflowDate={fromInflowDate}
            setFromInflowDate={setFromInflowDate}
            handleInwardFilterTrans={handleInwardFilterTrans}
            handleInwardFilterTransDownload={handleInwardFilterTransDownload}
            tenantCode={tenantCode}
          />

          <OutFlowFilterReportModal
            filterModal={filterModal}
            onHide={onHide}
            handleFilterTrans={handleFilterTrans}
            toReportDate={toReportDate}
            setToReportDate={setToReportDate}
            fromReportDate={fromReportDate}
            setFromReportDate={setFromReportDate}
            tenantCode={tenantCode}
          />
        </div>
      ) : (
        <div className="container-fluid">
          <div className="row justify-content-between">
            <div className="col-sm-12 col-md-12 col-lg-6 pb-5">
              <h3 className="page-title">Welcome to Connectors</h3>
              {/* <p className="sub-title">
              A complete overview of your business with analysis and data
              metrics
            </p> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NibssCustomerView;

// eslint-disable-next-line no-lone-blocks
{
  /* <Tabs defaultActiveKey="1">
    <Tabs.TabPane tab="Tab 1" key="1">
      Content of Tab Pane 1
    </Tabs.TabPane>
    <Tabs.TabPane tab="Tab 2" key="2">
      Content of Tab Pane 2
    </Tabs.TabPane>
    <Tabs.TabPane tab="Tab 3" key="3">
      Content of Tab Pane 3
    </Tabs.TabPane>
  </Tabs> */
}

// const [nibssTenants, setNibssTenant] = useState({});
// const [nibsswallets, setNibssWallets] = useState([]);
// const [walletNumber, setWalletNumber] = useState("");
