import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Tooltip } from "antd";
import { Input, FormControl, Select, Textarea } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { ActionButton } from "../../CustomerInformation/styles.jsx";
import {
  fetchRescheduleLoanTemplate,
  rescheduleLoan,
} from "../../../../../actions/SavingsAndLoanActions.js";
import { formatDate } from "../../../../../util/FormatDate.js";
import { removeEmptyValues } from "../../../../../util/FilterObject.js";
import ModalContainer from "../../../components/ModalContainer.jsx";
import { RescheduleRequestModal } from "./RescheduleRequestModal.jsx";
import ApproveReschedule from "./ApproveReschedule.jsx";
import RejectReschedule from "./RejectReschedule.jsx";

const RescheduleLoan = ({ id, changeCurrentView, resourceId, setResourceId }) => {
  const dispatch = useDispatch();
  const { loanAccountRescheduleTemplate } = useSelector((state) => state.savingsAndLoan);

  useEffect(() => {
    dispatch(fetchRescheduleLoanTemplate());
  }, []);

  const [changeRepaymentDate, setChangeRepaymentDate] = useState(false);
  const [introduceGracePeriod, setIntroduceGracePeriod] = useState(false);
  const [extendRepaymentPeriod, setExtendRepaymentPeriod] = useState(false);
  const [adjustInterestRate, setAdjustInterestRate] = useState(false);
  const [showRescheduleRequest, setShowRescheduleRequest] = useState(false);
  const [rescheduleData, setRescheduleData] = useState({
    rescheduleFromDate: "",
    rescheduleReasonId: "",
    adjustedDueDate: "",
    graceOnPrincipal: "",
    graceOnInterest: "",
    extraTerms: "",
    newInterestRate: "",
    submittedOnDate: Date.now(),
    rescheduleReasonComment: "",
    loanId: id,
    dateFormat: "dd MMM yyyy",
    locale: "en",
    // endDate: "",
    // emi: "2",
  });

  const localState = {
    adjustInterestRate,
    extendRepaymentPeriod,
    introduceGracePeriod,
    extraTerms: rescheduleData.extraTerms,
    newInterestRate: rescheduleData.newInterestRate,
    graceOnPrincipal: rescheduleData.graceOnPrincipal,
    graceOnInterest: rescheduleData.graceOnInterest,
    adjustedDueDate: rescheduleData.adjustedDueDate,
  };

  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [showRejectionModal, setShowRejectionModal] = useState(false);

  const toggleApprovalModal = () => {
    toggleRequestModal();
    setShowApprovalModal(state => !state);
  };
  const toggleRejectionModal = () => {
    toggleRequestModal();
    setShowRejectionModal(state => !state);
  };

  const toggleRequestModal = () => setShowRescheduleRequest(state => !state);

  const handleChange = (event, fieldName, date) => {
    const { name, value } = event.target;
    setRescheduleData(data => ({
      ...data,
      [fieldName ?? name]: date ?? value,
    }));
  };

  const handleRescheduleLoan = () => {
    const data = {
      ...rescheduleData,
      rescheduleFromDate: formatDate(rescheduleData.rescheduleFromDate),
      adjustedDueDate: changeRepaymentDate ? formatDate(rescheduleData.adjustedDueDate) : null,
      graceOnPrincipal: introduceGracePeriod ? rescheduleData.graceOnPrincipal : null,
      graceOnInterest: introduceGracePeriod ? rescheduleData.graceOnInterest : null,
      extraTerms: extendRepaymentPeriod ? rescheduleData.extraTerms : null,
      newInterestRate: adjustInterestRate ? rescheduleData.newInterestRate : null,
      submittedOnDate: formatDate(rescheduleData.submittedOnDate),
      recalculateInterest: adjustInterestRate,
      // endDate: formatDate(rescheduleData.endDate),
    };

    dispatch(rescheduleLoan(removeEmptyValues(data), toggleRequestModal));
  };

  return (
    <>
      <ModalContainer show={showApprovalModal} dialogClassName="document_infoModal">
        <ApproveReschedule
          loanId={id}
          id={resourceId}
          onHide={toggleApprovalModal}
          changeCurrentView={changeCurrentView}
        />
      </ModalContainer>
      <ModalContainer show={showRejectionModal} dialogClassName="document_infoModal">
        <RejectReschedule
          loanId={id}
          id={resourceId}
          onHide={toggleRejectionModal}
          changeCurrentView={changeCurrentView}
        />
      </ModalContainer>
      <ModalContainer show={showRescheduleRequest} dialogClassName="document_infoModal">
        <RescheduleRequestModal
          onClose={toggleRequestModal}
          rescheduleData={rescheduleData}
          changeCurrentView={changeCurrentView}
          setResourceId={setResourceId}
          toggleApprovalModal={toggleApprovalModal}
          toggleRejectionModal={toggleRejectionModal}
          localState={localState}
        />
      </ModalContainer>
      <div className="d-flex flex-column">
        <h3 className="mb-4 modal-title">Reschedule Loan</h3>
        <div className="form-group d-flex justify-content-between align-items-center">
          <label className="text-label">
            Reschedule From Installment On*
          </label>
          <FormControl style={{ width: "60%" }}>
            <DatePicker
              selected={rescheduleData?.rescheduleFromDate}
              onChange={(date, event) => handleChange(event, 'rescheduleFromDate', date)}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText="dd/mmm/yyyy"
            />
          </FormControl>
        </div>
        <div className="form-group d-flex justify-content-between align-items-center">
          <label className="text-label">Reason For Rescheduling*</label>
          <div className="w-60">
            <Select
              placeholder="Select Reason For Rescheduling"
              size="md"
              name="rescheduleReasonId"
              value={rescheduleData?.rescheduleReasonId}
              onChange={handleChange}
            >
              {loanAccountRescheduleTemplate?.rescheduleReasons?.map(({ name, id }) => (
                <option key={id} value={id}>{name}</option>
              ))}
            </Select>
          </div>
        </div>
        <div className="form-group d-flex justify-content-between align-items-center">
          <label className="text-label">Change Repayment Date</label>
          <div className="w-60">
            <Checkbox
              onChange={() => setChangeRepaymentDate(state => !state)}
              value={changeRepaymentDate}
            />
          </div>
        </div>
        {changeRepaymentDate && (
          <div className="form-group d-flex justify-content-between align-items-center">
            <label className="text-label">Installment Rescheduled To</label>
            <FormControl style={{ width: "60%" }}>
              <DatePicker
                selected={rescheduleData?.adjustedDueDate}
                onChange={(date, event) => handleChange(event, 'adjustedDueDate', date)}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="dd/mmm/yyyy"
              />
            </FormControl>
          </div>
        )}
        <div className="form-group d-flex justify-content-between align-items-center">
          <label className="text-label">Introduce Mid-Term Grace Periods</label>
          <div className="w-60">
            <Checkbox
              onChange={(e) => setIntroduceGracePeriod(state => !state)}
              checked={introduceGracePeriod}
            />
          </div>
        </div>
        {introduceGracePeriod && (
          <>
            <div className="form-group d-flex justify-content-between align-items-center">
              <span className="d-flex align-items-start gap-3">
                <label className="text-label">Principal Grace Periods</label>
                <Tooltip title="This is a period (in months) during which principal payments are not required on the loan">
                  <img
                    src={require("../../../../../assets/images/icons/info-circle-2.svg").default}
                    width={20}
                    height={20}
                    alt="tooltip icon"
                  />
                </Tooltip>
              </span>
              <FormControl style={{ width: "60%" }}>
                <div className="w-60">
                  <Input
                    name="graceOnPrincipal"
                    value={rescheduleData?.graceOnPrincipal}
                    onChange={handleChange}
                    type="number"
                    size="md"
                  />
                </div>
              </FormControl>
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <span className="d-flex align-items-start gap-3">
                <label className="text-label">Interest Grace Periods</label>
                <Tooltip title="This is a period (in months) during which interest is not required to be paid on the loan.">
                  <img
                    src={require("../../../../../assets/images/icons/info-circle-2.svg").default}
                    width={20}
                    height={20}
                    alt="tooltip icon"
                  />
                </Tooltip>
              </span>
              <FormControl style={{ width: "60%" }}>
                <div className="w-60">
                  <Input
                    name="graceOnInterest"
                    value={rescheduleData?.graceOnInterest}
                    onChange={handleChange}
                    type="number"
                    size="md"
                  />
                </div>
              </FormControl>
            </div>
          </>
        )}
        <div className="form-group d-flex justify-content-between align-items-center">
          <label className="text-label">Extend Repayment Period</label>
          <div className="w-60">
            <Checkbox
              onChange={() => setExtendRepaymentPeriod(state => !state)}
              checked={extendRepaymentPeriod}
            />
          </div>
        </div>
        {extendRepaymentPeriod && (
          <div className="form-group d-flex justify-content-between align-items-center">
            <span className="d-flex align-items-start gap-3">
              <label className="text-label">Number Of New Repayments</label>
              <Tooltip title="This will be added to the existing number of outstanding repayments">
                <img
                  src={require("../../../../../assets/images/icons/info-circle-2.svg").default}
                  width={20}
                  height={20}
                  alt="tooltip icon"
                />
              </Tooltip>
            </span>
            <FormControl style={{ width: "60%" }}>
              <div className="w-60">
                <Input
                  name="extraTerms"
                  value={rescheduleData?.extraTerms}
                  type="number"
                  onChange={handleChange}
                  size="md"
                />
              </div>
            </FormControl>
          </div>
        )}
        <div className="form-group d-flex justify-content-between align-items-center">
          <label className="text-label">Adjust Interest Rates For Remainder Of Loan</label>
          <div className="w-60">
            <Checkbox
              onChange={() => setAdjustInterestRate(state => !state)}
              checked={adjustInterestRate}
            />
          </div>
        </div>
        {adjustInterestRate && (
          <div className="form-group d-flex justify-content-between align-items-center">
            <label className="text-label">New Interest Rate </label>
            <FormControl style={{ width: "60%" }}>
              <div className="w-60">
                <Input
                  name="newInterestRate"
                  value={rescheduleData?.newInterestRate}
                  onChange={handleChange}
                  type="number"
                  size="md"
                />
              </div>
            </FormControl>
          </div>
        )}
        <div className="form-group d-flex justify-content-between align-items-center">
          <label className="text-label">Submitted On*</label>
          <FormControl style={{ width: "60%" }}>
            <DatePicker
              selected={rescheduleData?.submittedOnDate}
              onChange={(date, event) => handleChange(event, 'submittedOnDate', date)}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText="dd/mmm/yyyy"
            />
          </FormControl>
        </div>
        <div className="form-group d-flex justify-content-between align-items-center">
          <label className="text-label">Comment</label>
          <Textarea
            style={{ fontSize: '12px', width: '60%' }}
            name="rescheduleReasonComment"
            value={rescheduleData?.rescheduleReasonComment}
            onChange={handleChange}
            size="md"
          />
        </div>
      </div>
      <div className="float-right mt-1">
        <ActionButton color="#2C1DFF">
          <img
            src={
              require("../../../../../assets/images/icons/cancel.svg").default
            }
            alt="cancel icon"
          />
          Cancel
        </ActionButton>
        <ActionButton
          onClick={handleRescheduleLoan}
          color="#2C1DFF"
          style={{ margin: "0px 0px 0 10px" }}
        >
          <img
            src={
              require("../../../../../assets/images/icons/save.svg").default
            }
            alt="submit icon"
          />
          Submit
        </ActionButton>
      </div>
    </>
  );
};

export default RescheduleLoan;
