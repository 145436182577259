// import axiosData from "../axiosData";
import axios from "axios";
import config from "../../config";
// import AxiosData from "../axiosData";
import axiosData from "../axiosData";

// const IDENTITY_BASE_URL = 'https://identity-service.test.woodcoreapp.com'
// const INWARD_BASE_URL = 'https://inward-service.test.woodcoreapp.com/inward'
// const OUTWARD_BASE_URL = 'https://outward-service.test.woodcoreapp.com'



// https://identity-service.test.woodcoreapp.com/identity/tenants/by/lifegatemfb/wallets
// tenantIdentifier


// *********** Manage Account Endpoints *************
// ===== Get Tenants return id ===========
export const getNibssTenants = (tenantIdentifier2) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axios({
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*/*",
        },
        // url: `${config.apiGateway.IDENTITY_BASE_API_URL}/identity/tenants?enabled=true&page=0&size=1`,
        url: `${config.apiGateway.IDENTITY_BASE_API_URL}/identity/tenants?enabled=true&tenantIdentifier=${tenantIdentifier2}&page=0&size=1`,
        // https://identity-service.test.woodcoreapp.com/identity/tenants?enabled=true&tenantIdentifier=default&page=0&size=100
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// ========= Get Tenants wallets with id =============
// return walletId, walletNumber, tenantId , tenantName
export const getNibssTenantsWallets = async (tenantIdentifier) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axios({
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*/*",
        },
        // url: `https://identity-service.staging.woodcoreapp.com/identity/tenants/${id}/wallets`,
        url: `${config.apiGateway.IDENTITY_BASE_API_URL}/identity/tenants/by/${tenantIdentifier}/wallets`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// ========== Get selected WalletNumber Account Balance with walletId ===========
// woodcore core endpoint return savings account details with walletId sent
export const getAccountBalance = (walletNumberId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*/*",
        },
        url: `${config.apiGateway.BASE_API_URL}/savingsaccounts/${walletNumberId}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// ==== Get Inflow and Outflow data
// inflow data
// {{nibss-inward}}/inward/transactions?orderBy=createdAt&sortBy=DESC&limit=14&offset=0
// woodcore connectors
export const getInflowData = (params,tenantCode) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*/*",
        },
        // url: "https://inward-service.staging.woodcoreapp.com/inward/transactions?orderBy=createdAt&sortBy=DESC&limit=14&offset=0",
        url: `${config.apiGateway.INWARD_BASE_API_UR}/transactions?receiverBankCode=${tenantCode ? tenantCode : 0}&${params}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getInflowDataDownload = (params, tenantCode) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*/*",
        },
        // url: "https://inward-service.staging.woodcoreapp.com/inward/transactions?orderBy=createdAt&sortBy=DESC&limit=14&offset=0",
        url: `${config.apiGateway.INWARD_BASE_API_UR}/transactions?receiverBankCode=${tenantCode ? tenantCode : 0}&${params}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Outflow data
// outward service endpoint
//
export const getOutflowData = (params , tenantCode) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*/*",
        },
        // url: "https://outward-service.staging.woodcoreapp.com/outward/transactions?limit=15&offset=0&orderBy=createdAt&sortBy=ASC",
        // https://outward-service.test.woodcoreapp.com/outward/transactions?senderBankCode=999319&limit=15&offset=0&orderBy=createdAt&sortBy=ASC
        url: `${config.apiGateway.OUTWARD_BASE_API_UR}/outward/transactions?senderBankCode=${tenantCode  ? tenantCode : 0}&${params}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getOutflowDataDownload = (params,tenantCode) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*/*",
        },
        // url: "https://outward-service.staging.woodcoreapp.com/outward/transactions?limit=15&offset=0&orderBy=createdAt&sortBy=ASC",
        url: `${config.apiGateway.OUTWARD_BASE_API_UR}/outward/transactions?senderBankCode=${tenantCode  ? tenantCode : 0}&${params}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getOutflowDataFilter = (params,tenantCode,startDate, endDate) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*/*",
        },
        // url: "https://outward-service.staging.woodcoreapp.com/outward/transactions?limit=15&offset=0&orderBy=createdAt&sortBy=ASC",
        url: `${config.apiGateway.OUTWARD_BASE_API_UR}/outward/transactions?senderBankCode=${tenantCode  ? tenantCode : 0}&fromDate=${startDate}&toDate=${endDate}&${params}`,
        // https://outward-service.test.woodcoreapp.com/outward/transactions?fromDate=2022-04-01&toDate=2022-05-17&limit=15&offset=0&orderBy=createdAt&sortBy=ASC
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getOutflowDataFilterDownload = (startDate, endDate , params, tenantCode ) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*/*",
        },
        // url: "https://outward-service.staging.woodcoreapp.com/outward/transactions?limit=15&offset=0&orderBy=createdAt&sortBy=ASC",
        url: `${config.apiGateway.OUTWARD_BASE_API_UR}/outward/transactions?senderBankCode=${tenantCode ? tenantCode : 0}&fromDate=${startDate}&toDate=${endDate}&${params}`,
        // https://outward-service.test.woodcoreapp.com/outward/transactions?fromDate=2022-04-01&toDate=2022-05-17&limit=15&offset=0&orderBy=createdAt&sortBy=ASC
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};



export const getInflowDataFilter = ( params, tenantCode ,startDate, endDate) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*/*",
        },
        url: `${config.apiGateway.INWARD_BASE_API_UR}/transactions?receiverBankCode=${tenantCode ? tenantCode : 0}&fromDate=${startDate}&toDate=${endDate}&${params}`,

      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getInflowDataFilterDownload = (params,tenantCode, startDate,endDate  ) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*/*",
        },
        url: `${config.apiGateway.INWARD_BASE_API_UR}/transactions?receiverBankCode=${tenantCode ? tenantCode : 0}&fromDate=${startDate}&toDate=${endDate}&${params}`,

      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};



// Get Single Inflow Transaction
export const getOneInflowData = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*/*",
        },
        url: `${config.apiGateway.INWARD_BASE_API_UR}/transactions/${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Get Single Outflow Transaction
export const getOneOutflowData = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*/*",
        },
        // https://outward-service.staging.woodcoreapp.com/outward/transactions/1
        url: `${config.apiGateway.OUTWARD_BASE_API_UR}/outward/transactions/${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};


// *********** Payment Endpoints ***************

// Get banks
// Get bank controller
export const getBanksNibss = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axios({
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*/*",
        },
        url: `${config.apiGateway.OUTWARD_BASE_API_UR}/banks`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};


// Get name enquiry
// https://outward-service.staging.woodcoreapp.com/transfers/999319/accounts/names?beneficiaryAccountNumber=1100066120&beneficiaryInstitutionCode=999319&channelCode=1
export const performNameEnquiry = (
  // tenantInstitutionCode,
  beneficiaryAccountNumber,
  beneficiaryInstitutionCode
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axios({
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*/*",
        },
        url: `${config.apiGateway.OUTWARD_BASE_API_UR}/transfers/999319/accounts/names?beneficiaryAccountNumber=${beneficiaryAccountNumber}&beneficiaryInstitutionCode=${beneficiaryInstitutionCode}&channelCode=1`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// AUTHENTICATE PinCode
export const authenticateNibssPinCode = (userData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        data: userData,
        // headers: {
        //   "Access-Control-Allow-Origin": "*/*",
        // },
        url: "https://atlas-twofactor.test.woodcoreapp.com/api/v1/otpCode/authenticate-pin",
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Transfer Fund Post
export const TransferFunds = (userData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        data: userData,
        // url: `${config.apiGateway.BASE_API_URL}/authentication`,
        url: `${config.apiGateway.OUTWARD_BASE_API_UR}/transfers/098988/`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Fetch Customer Bvn
// https://core.staging.woodcoreapp.com/woodcore/api/v1/datatables/Bvn/405?genericResultSet=true
export const getClientBvn = (clientId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // headers: {
        //   "Access-Control-Allow-Origin": "*/*",
        // },
        url: `${config.apiGateway.BASE_API_URL}/datatables/Bvn/${clientId}?genericResultSet=true`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Get Client Nuban
export const getClientNuban = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // headers: {
        //   "Access-Control-Allow-Origin": "*/*",
        // },
        // https://core.staging.woodcoreapp.com/woodcore/api/v1/datatables/Nuban/1?genericResultSet=true
        url: `${config.apiGateway.BASE_API_URL}/datatables/Nuban/${id }?genericResultSet=true`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Get all Nibss Report
export const getNibssReport = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*/*",
        },
        url: `${config.apiGateway.IDENTITY_BASE_API_URL}/api/v1/reports?${params}`
        // url: `https://identity-service.staging.woodcoreapp.com/api/v1/reports?${params}${
        //   !!startDate && !!endDate
        //     ? `&dateRange=${!!startDate ? `${startDate}-${endDate}` : ""}`
        //     : ""
        // }`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const getFilteredNibssReport = (startDate , endDate) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*/*",
        },
        url: `${config.apiGateway.IDENTITY_BASE_API_URL}/api/v1/reports?from=${startDate}&to=${endDate}`
        // url: `https://identity-service.staging.woodcoreapp.com/api/v1/reports?${params}${
        //   !!startDate && !!endDate
        //     ? `&dateRange=${!!startDate ? `${startDate}-${endDate}` : ""}`
        //     : ""
        // }`,
        // https://identity-service.test.woodcoreapp.com/api/v1/reports?from=2023-01-01&to=2023-01-10&pageNo=0&pageSize=10
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};