import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_DATA_REQUEST,
  USER_DATA_SUCCESS,
  USER_DATA_FAIL,
  SET_RES_USER_DATA,
} from "../constants/authConstants";

const INITIAL_STATE = {
  error: null,
  loading: false,
  isAuthenticated: false,
  userData: {},
};

const loginStart = (state, action) => {
  return {
    ...INITIAL_STATE,
    loading: true,
  };
};

const loginSuccess = (state, action) => {
  return {
    ...INITIAL_STATE,
    loading: false,
    isAuthenticated: true,
  };
};

const loginFail = (state, action) => {
  return {
    ...INITIAL_STATE,
    error: action.payload,
  };
};

const logout = (state, action) => {
  return {
    ...INITIAL_STATE,
  };
};

export default (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return loginStart(state, action);
    case USER_LOGIN_SUCCESS:
      return loginSuccess(state, action);
    case USER_LOGIN_FAIL:
      return loginFail(state, action);
    case USER_LOGOUT:
      return logout(state, action);
    default:
      return state;
  }
};

export const getUserDataReducer = (state = { userData: {} }, action) => {
  switch (action.type) {
    case USER_DATA_REQUEST:
      return { loading: true, ...state };
    case USER_DATA_SUCCESS:
      return { ...state, loading: false, userData: action.payload };
    case USER_DATA_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
export const getDataReducer = (state = { userDetails: {} }, action) => {
  switch (action.type) {
    case SET_RES_USER_DATA:
      return { ...state, loading: false, userDetails: action.payload };
    default:
      return state;
  }
};
