import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ContentWrapper } from "../styles";
// import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { formatDate } from "../../../../../../util/FormatDate";

import AppDetails from "./components/common/AppDetails";
import AppTerms from "./components/common/AppTerms";
import AppCharges from "./components/common/AppCharges";
import AppPreview from "./components/common/AppPreview";
import AppSettings from "./components/common/AppSettings";

import FixedDetails from "./components/fixed/FixedDetails.jsx";
import FixedTerms from "./components/fixed/FixedTerms.jsx";
import FixedCharges from "./components/fixed/FixedCharges.jsx";
import FixedPreview from "./components/fixed/FixedPreview.jsx";
import ScrollToTop from "../../../../../../components/ScrollToTop/index.jsx";
import FixedSettings from "./components/fixed/FixedSettings";
import AppIntrest from "./components/common/AppInterest";
import FixedInterest from "./components/fixed/FixedInterest";
// import { getToolTips } from "../../../../../../actions/utilityActions";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ToolTipSvg from "../../../../../../assets/images/icons/tooltip.svg";
import FixedInterestTemp from "./components/fixed/FixedInterestTemp";

import {
  // getFixedDepositTemplate,
  // createFixedDepositTemplate,
  getFixedDepositsProductsTemplate,
  createFixedDepositsProduct,
  resetNewFixedDepositProductState,

} from "../../../../../../actions/fixedDeposit";

const CreateFixedDepositProductView = ({ match, history }) => {
  const dispatch = useDispatch();

  // const fixedDepositData = useSelector((state) => state.fixedDepositData);

  const fixedDepositProductsTemplate = useSelector((state) => state.fixedDepositProductsTemplate);

  // let { fixedDepositTemplate = {} } = fixedDepositData || {};


  const toolTips = useSelector((state) => state.toolTips.tooltips);

  const renderTooltip = (name) => (
    <>
      {toolTips && toolTips[name] ? (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={`tooltip-${toolTips[name]._id}`}>
              {toolTips[name].toolTip}
            </Tooltip>
          }
        >
          <img src={ToolTipSvg} style={{ marginLeft: "10px" }} alt="" />
        </OverlayTrigger>
      ) : (
        ""
      )}
    </>
  );

  const { loading, fixedDepositProductsTemplate: template } = fixedDepositProductsTemplate;

  // console.log(template, 'fdTemplate');
  // console.log(fixedDepositProductsTemplate, 'fixedDepositProductsTemplate');

  useEffect(() => {
    dispatch(resetNewFixedDepositProductState());
    dispatch(getFixedDepositsProductsTemplate());
    // dispatch(getToolTips("fixedDepositProduct"));
  }, [dispatch]);

  const [newFixedDepositProduct, setNewFixedDepositProduct] = useState({
    name: "",
    currencyCode: "",
    shortName: "",
    inMultiplesOf: "10",
    description: "",
    digitsAfterDecimal: "2",

    // For terms
    interestPostingPeriodType: "",
    interestCompoundingPeriodType: "",
    interestCalculationType: "",
    interestCalculationDaysInYearType: "",

    // For Settings
    lockinPeriodFrequency: "",
    lockinPeriodFrequencyType: "",
    minDepositTerm: "",
    minDepositTermTypeId: "",
    maxDepositTerm: "",
    maxDepositTermTypeId: "",
    preClosurePenalInterest: "",
    preClosurePenalInterestOnTypeId: "1",
    taxGroupId: "",
    inMultiplesOfDepositTerm: "",
    inMultiplesOfDepositTermTypeId: "",

    // For Charges
    savingsReferenceAccountId: "",
    savingsControlAccountId: "",
    // interestPayableAccountId: "",
    transfersInSuspenseAccountId: "",
    interestOnSavingsAccountId: "",
    incomeFromFeeAccountId: "",
    incomeFromPenaltyAccountId: "",
  });

  const [feeToIncomeAccountMappings, setFeeToIncomeAccountMappings] = useState(
    []
  );
  const [penaltyToIncomeAccountMappings, setPenaltyToIncomeAccountMappings] =
    useState([]);
  const [
    paymentChannelToFundSourceMappings,
    setPaymentChannelToFundSourceMappings,
  ] = useState([]);

  const createFixedDepositProductHandler = (e) =>
    setNewFixedDepositProduct({
      ...newFixedDepositProduct,
      [e.target.name]: e.target.value,
    });

  const createFixedDepositProductHandlerForSelect = (name, value) =>
    setNewFixedDepositProduct({
      ...newFixedDepositProduct,
      [name]: value,
    });

  const [accountType, setAccountType] = useState("1");
  const [radio, setRadio] = useState("1");

  const [depositAmount, setDepositAmount] = useState();
  const [maxDepositAmount, setMaxDepositAmount] = useState();
  const [minDepositAmount, setMinDepositAmount] = useState();
  const [termsError, setTermsError] = useState();
  const [preClosurePenalApplicable, setPreClosurePenalApplicable] =
    useState(false);
  const [withHoldTax, setWithHoldTax] = useState(false);
  const [validFrom, handleValidFrom] = useState();
  const [endDate, handleEndDate] = useState();
  const [chartSlabs, setChartSlabs] = useState([]);
  const [charges, setCharges] = useState([]);
  const [valueCharges, setValueCharges] = useState([]);
  const [advance, setAdvance] = useState(false);

  // const [myCharges, setMyCharges] = useState([]);

    const defaultConfigFS = { paymentTypeId: "", fundSourceAccountId: "" };
  const defaultMapFeeToIncome = { chargeId: "", incomeAccountId: "" };
  const defaultMapPenalties = { chargeId: "", incomeAccountId: "" };

  const [currentScreen, setCurrentScreen] = useState(0);

    //configure fund sources for payment channels
    const [numberOfConfigFS, setNumberOfConfigFS] = useState([]);
    const addToConfigFSArray = () => {
      setNumberOfConfigFS([...numberOfConfigFS, defaultConfigFS]);
    };
    const removeFromConfigFSArray = (position) => {
      setNumberOfConfigFS(
        numberOfConfigFS.filter((item, index) => index !== position)
      );
    };
    
    const handleConfigFSPaymentIdToChange = (position, value) => {
      let newConfigFS = [...numberOfConfigFS];
      newConfigFS[Number(position)].paymentTypeId = value;
      setNumberOfConfigFS(newConfigFS);
    };
    const handleConfigFSSourceIdToChange = (position, value) => {
      let newConfigFS = [...numberOfConfigFS];
      newConfigFS[Number(position)].fundSourceAccountId = value;
      setNumberOfConfigFS(newConfigFS);
    };

      // Map fees to income accounts
  const [numberOfMapFeeToIncome, setNumberOfMapFeeToIncome] = useState([]);
  const addToMapFeeToIncomeArray = () => {
    setNumberOfMapFeeToIncome([
      ...numberOfMapFeeToIncome,
      defaultMapFeeToIncome,
    ]);
  };
  const removeFromMapFeeToIncomeArray = (position) => {
    setNumberOfMapFeeToIncome(
      numberOfMapFeeToIncome.filter((item, index) => index !== position)
    );
  };
  const handleMapFeeToIncomePaymentIdToChange = (position, value) => {
    let newMapFeeToIncome = [...numberOfMapFeeToIncome];
    newMapFeeToIncome[Number(position)].chargeId = value;
    setNumberOfMapFeeToIncome(newMapFeeToIncome);
  };
  const handleMapFeeToIncomeSourceIdToChange = (position, value) => {
    let newMapFeeToIncome = [...numberOfMapFeeToIncome];
    newMapFeeToIncome[Number(position)].incomeAccountId = value;
    setNumberOfMapFeeToIncome(newMapFeeToIncome);
  };

  // Map fees to income accounts
  const [numberOfMapPenalties, setNumberOfMapPenalties] = useState([]);
  const addToMapPenaltiesArray = () => {
    setNumberOfMapPenalties([...numberOfMapPenalties, defaultMapPenalties]);
  };
  const removeFromMapPenaltiesArray = (position) => {
    setNumberOfMapPenalties(
      numberOfMapPenalties.filter((item, index) => index !== position)
    );
  };
  const handleMapPenaltiesPaymentIdToChange = (position, value) => {
    let newMapPenalties = [...numberOfMapPenalties];
    newMapPenalties[Number(position)].chargeId = value;
    setNumberOfMapPenalties(newMapPenalties);
  };
  const handleMapPenaltiesSourceIdToChange = (position, value) => {
    let newMapPenalties = [...numberOfMapPenalties];
    newMapPenalties[Number(position)].incomeAccountId = value;
    setNumberOfMapPenalties(newMapPenalties);
  };

  // Handle submit
  const handleSubmit = () => {
    let chartSlabsArray = [];
    const newChartSlabs = chartSlabs;
    chartSlabsArray.push(newChartSlabs);

    // console.log(chartSlabsArray, "chartSlabsArrayyyy");

    let charts = [
      {
        chartSlabs: chartSlabsArray,
        dateFormat: "dd MMMM yyyy",
        endDate: endDate ? formatDate(endDate, true) : "",
        fromDate: validFrom ? formatDate(validFrom, true) : "",
        locale: "en",
      },
    ];
    // const newEndDate = charts[0].endDate

    // console.log(charts, "chartssss");

      const nominalAnnualInterestRate = charts[0].chartSlabs[0].annualInterestRate;
      // console.log(nominalAnnualInterestRate, 'newInterest');

    //  const  nominalAnnualInterestRate = [...charts]
    //  console.log(nominalAnnualInterestRate, 'nominalAnnualInterestRate');

    let dataToSend = {
      ...newFixedDepositProduct,
      charts,
      charges,
      locale: "en",
      nominalAnnualInterestRate,
      depositAmount,
      maxDepositAmount,
      minDepositAmount,
      preClosurePenalApplicable,
      accountingRule: radio,
      withHoldTax,
      feeToIncomeAccountMappings,
      penaltyToIncomeAccountMappings,
      paymentChannelToFundSourceMappings,
    };

    const newObject = { ...dataToSend };
    for (const key in newObject) {
      if (Object.prototype.hasOwnProperty.call(newObject, key)) {
        const element = newObject[key];
        if (!element) {
          delete newObject[key];
        }
      }
    }

    // console.log(newObject, newObject.charts[0].endDate, 'endDate')
    if (newObject.charts[0].endDate === "") {
      delete newObject.charts[0].endDate;
    }

    dataToSend = newObject;
    // Object.keys(dataToSend).forEach(() => {

    // });
    // console.log(dataToSend, "datatosenddddd");
    dispatch(createFixedDepositsProduct(dataToSend, history));
  };

  const renderComponent = () => {
    let currentComponet = "";
    switch (currentScreen) {
      case 0:
        currentComponet = (
          <AppDetails>
            <FixedDetails
              handleNextScreen={handleNextCustomIndexSelect}
              createFixedDepositProductHandler={createFixedDepositProductHandler}
              createFixedDepositProductHandlerForSelect={createFixedDepositProductHandlerForSelect}
              name={newFixedDepositProduct.name}
              currencyCode={newFixedDepositProduct.currencyCode}
              shortName={newFixedDepositProduct.shortName}
              inMultiplesOf={newFixedDepositProduct.inMultiplesOf}
              description={newFixedDepositProduct.description}
              digitsAfterDecimal={newFixedDepositProduct.digitsAfterDecimal}
              template={loading || !template ? null : template}
              renderTooltip={renderTooltip}
              setAccountType={setAccountType}

            />
          </AppDetails>
        );
        break;
      case 1:
        currentComponet = (
          <AppTerms>
            <FixedTerms
              handleNextScreen={handleNextCustomIndexSelect}
              handlePreviousScreen={handlePreviousCustomIndexSelect}
              {...newFixedDepositProduct}
              // template={fixedDepositTemplate}
              template={loading || !template ? null : template}
              depositAmount={depositAmount}
              setDepositAmount={setDepositAmount}
              maxDepositAmount={maxDepositAmount}
              setMaxDepositAmount={setMaxDepositAmount}
              minDepositAmount={minDepositAmount}
              setMinDepositAmount={setMinDepositAmount}
              createFixedDepositProductHandler={createFixedDepositProductHandler}
              createFixedDepositProductHandlerForSelect={createFixedDepositProductHandlerForSelect}
              termsError={termsError}
              setTermsError={setTermsError}
              renderTooltip={renderTooltip}
              setAccountType={setAccountType}

            />
          </AppTerms>
        );
        break;
      case 2:
        currentComponet = (
          <AppSettings>
            <FixedSettings
              handleNextScreen={handleNextCustomIndexSelect}
              handlePreviousScreen={handlePreviousCustomIndexSelect}
              {...newFixedDepositProduct}
              createFixedDepositProductHandler={createFixedDepositProductHandler}
              createFixedDepositProductHandlerForSelect={createFixedDepositProductHandlerForSelect}
              // template={fixedDepositTemplate}
              template={template}
              withHoldTax={withHoldTax}
              setWithHoldTax={setWithHoldTax}
              preClosurePenalApplicable={preClosurePenalApplicable}
              setPreClosurePenalApplicable={setPreClosurePenalApplicable}
              renderTooltip={renderTooltip}
              setAccountType={setAccountType}

            />
          </AppSettings>
        );
        break;
      case 3:
        currentComponet = (
          <AppIntrest>
            <FixedInterestTemp
              {...newFixedDepositProduct}
              handleNextScreen={handleNextCustomIndexSelect}
              handlePreviousScreen={handlePreviousCustomIndexSelect}
              createFixedDepositProductHandler={createFixedDepositProductHandler}
              handleEndDate={handleEndDate}
              // template={fixedDepositTemplate}
              template={template}
              endDate={endDate}
              validFrom={validFrom}
              handleValidFrom={handleValidFrom}
              setChartSlabs={setChartSlabs}
              chartSlabs={chartSlabs}
              // setAmount={setAmount}
              // name={newFixedDepositProduct.name}
              // amount={newFixedDepositProduct.amount}
              // annualInterestRate={newFixedDepositProduct.annualInterestRate}
              // periodType={newFixedDepositProduct.periodType}
              // description={newFixedDepositProduct.description}
              // fromPeriod ={newFixedDepositProduct.fromPeriod}
              // amountRangeFrom ={newFixedDepositProduct.amountRangeFrom}
              // setChartSlabs={setChartSlabs}
              // chartSlabs={chartSlabs}
              renderTooltip={renderTooltip}
              setAccountType={setAccountType}

            />
          </AppIntrest>
        );
        break;
      case 4:
        currentComponet = (
          <AppCharges>
            <FixedCharges
              // template={fixedDepositTemplate}
              template={template}
              {...newFixedDepositProduct}
              handleNextScreen={handleNextCustomIndexSelect}
              chartSlabs={chartSlabs}
              handlePreviousScreen={handlePreviousCustomIndexSelect}
              createFixedDepositProductHandler={createFixedDepositProductHandler}
              createFixedDepositProductHandlerForSelect={createFixedDepositProductHandlerForSelect}
              setPaymentChannelToFundSourceMappings={
                setPaymentChannelToFundSourceMappings
              }
              setPenaltyToIncomeAccountMappings={
                setPenaltyToIncomeAccountMappings
              }
              setFeeToIncomeAccountMappings={setFeeToIncomeAccountMappings}
              // charges={charges}
              // setCharges={setCharges}
              // setAccountingRule={setAccountingRule}
              // accountingRule={accountingRule}
              setAccountType={setAccountType}
              valueCharges={valueCharges}
              setValueCharges={setValueCharges}
              setFullCharges={setCharges}
              // myCharges={myCharges}
              // setMyCharges={setMyCharges}
              renderTooltip={renderTooltip}
              accountType ={accountType}
              setRadio={setRadio}
              radio = {radio}
              setAdvance={setAdvance}
              advance={advance}
              addToConfigFSArray={addToConfigFSArray}
              removeFromConfigFSArray = {removeFromConfigFSArray}
              handleConfigFSPaymentIdToChange = {handleConfigFSPaymentIdToChange}
              handleConfigFSSourceIdToChange = {handleConfigFSSourceIdToChange}
              numberOfConfigFS = {numberOfConfigFS}
              setNumberOfConfigFS = {setNumberOfConfigFS}
              numberOfMapFeeToIncome = {numberOfMapFeeToIncome}
              setNumberOfMapFeeToIncome = {setNumberOfMapFeeToIncome}
              addToMapFeeToIncomeArray = {addToMapFeeToIncomeArray}
              removeFromMapFeeToIncomeArray = {removeFromMapFeeToIncomeArray}
              handleMapFeeToIncomePaymentIdToChange ={handleMapFeeToIncomePaymentIdToChange}
              handleMapFeeToIncomeSourceIdToChange ={handleMapFeeToIncomeSourceIdToChange}
              numberOfMapPenalties={numberOfMapPenalties}
              setNumberOfMapPenalties={setNumberOfMapPenalties}
              addToMapPenaltiesArray={addToMapPenaltiesArray}
              removeFromMapPenaltiesArray={removeFromMapPenaltiesArray}
              handleMapPenaltiesPaymentIdToChange={handleMapPenaltiesPaymentIdToChange}
              handleMapPenaltiesSourceIdToChange={handleMapPenaltiesSourceIdToChange}

            />
          </AppCharges>
        );
        break;
      case 5:
        currentComponet = (
          <AppPreview>
            <FixedPreview
              {...newFixedDepositProduct}
              chartSlabs={chartSlabs}
              // charges={charges}
              // template={fixedDepositTemplate}
              template={template}
              minDepositAmount={minDepositAmount}
              depositAmount={depositAmount}
              maxDepositAmount={maxDepositAmount}
              withHoldTax={withHoldTax}
              endDate={endDate}
              validFrom={validFrom}
              handlePreviousScreen={handlePreviousCustomIndexSelect}
              handleSubmit={handleSubmit}
              feeToIncomeAccountMappings={feeToIncomeAccountMappings}
              penaltyToIncomeAccountMappings={penaltyToIncomeAccountMappings}
              paymentChannelToFundSourceMappings={paymentChannelToFundSourceMappings}
              // myCharges={myCharges}

              charges={valueCharges}
              // accountingRule={accountingRule}
              renderTooltip={renderTooltip}
              setRadio={setRadio}
              radio={radio}
              // setAccountType= {setAccountType}
              accountType ={accountType}
            />
          </AppPreview>
        );
        break;
      default:
    }
    return currentComponet;
  };

  // FUNCTION HERE FOR MOVING TO THE NEXT PAGE
  const handleNextCustomIndexSelect = () => {
    let newIndex = currentScreen + 1;
    if (newIndex <= 5) {
      setCurrentScreen(newIndex);
    }
    // console.log(setRadio, 'setRadio')
    // console.log(radio, 'radio')
  };
  // FUNCTION HERE FOR MOVING TO THE PREVIOUS PAGE
  const handlePreviousCustomIndexSelect = () => {
    let newIndex = currentScreen - 1;
    // console.log(newIndex, "and", currentScreen);
    if (newIndex >= 0) {
      setCurrentScreen(newIndex);
    }
    // console.log(setRadio, 'setRadio')
    // console.log(radio, 'radio')
  };

  // console.log(currentScreen, "currentScreen")

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Create Fixed deposit products</h3>
            <p className="sub-title mt-1">
              {/* <span className="text-info">
              <Link to="/core/configuration">Configuration </Link>
            </span>
            /{" "} */}
              <span className="text-info mr-1">
                <Link to="/core/products">Products </Link>
              </span>
              /{" "}
              <span className="text-info mr-1">
                <Link to="/core/products/fixed-deposit-products">
                  Fixed deposit products{" "}
                </Link>
              </span>
              / Create Fixed deposit products
            </p>
          </div>
        </div>

        <div className="row" style={{ minHeight: "calc(100vh - 250px)" }}>
          <div className="col-sm-12 col-md-12">
            <ScrollToTop>
              <ContentWrapper className="application-wrapper">
                {renderComponent()}
              </ContentWrapper>
            </ScrollToTop>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateFixedDepositProductView;
