import React, { useRef, useEffect } from "react";
import {
  ContentWrapper,
  ActionButtonsGroup,
  ActionButton,
  EditButton,
} from "./styles";
import { InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import { Table } from "antd";
import { Link } from "react-router-dom";
// import ModalContainer from "../../../components/ModalContainer";

import { useDispatch, useSelector } from "react-redux";
import {
  getAllLoanProducts,
  clearFilteredLoanProducts,
  filterLoanProduct,
  resetLoanProduct,
} from "../../../../../actions/configurationActions";
import Toast from "../../../../../util/Toast";
import TableLoader from "./TableLoader";
import { formatDate } from "../../../../../util/FormatDate";
import CheckPermission from "../../../../../util/CheckPermission";

const LoanProductsView = ({ history }) => {
  const dispatch = useDispatch();


  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  const loanProductList = useSelector((state) => state.loanProductsReducer);

  const { loading, loanProducts, filtered } = loanProductList;

  loanProducts.sort((a, b) => (a.name > b.name ? 1 : -1));
  // console.log(filtered);
  const search = useRef("");

  const onFilterLoanProducts = (e) => {
    search.current.value !== ""
      ? dispatch(filterLoanProduct(e.target.value))
      : dispatch(clearFilteredLoanProducts());
  };
  if (filtered !== null && filtered.length === 0) {
    Toast({
      text: "No loan product found",
      icon: "error",
    });
  }
  
  useEffect(() => {
    dispatch(clearFilteredLoanProducts());
    dispatch(getAllLoanProducts());
    dispatch(resetLoanProduct());
    if (filtered === null) {
      search.current.value = "";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Table configuration

  const dataSource = [];
  const filteredLoanProducts = [];

  if (filtered !== null) {
    filtered.forEach((loanP) => {
      const { id, name, maxPrincipal, interestType, currency,  shortName, closeDate } = loanP;
      filteredLoanProducts.push({
        key: id,
        name,
        maxPrincipal: maxPrincipal,
        interestType: interestType.value,
        shortname: shortName,
        currency:currency.code,
        expiryDate: closeDate ? formatDate(closeDate) : "",
        edit: CheckPermission("UPDATE_LOANProduct", permissions) && (
          <EditButton
            onClick={(e) => {
              e.stopPropagation();
              history.push(
                `/core/products/loan-products/edit-loan-product/${id}`
              );
            }}
          >
            Edit
          </EditButton>
        ),
      });
    });
  }

  !loading &&
    loanProducts.forEach((loanP) => {
      const { id, name, maxPrincipal, interestType, currency, shortName, closeDate } = loanP;
      dataSource.push({
        key: id,
        name,
        maxPrincipal: maxPrincipal,
        interestType: interestType.value,
        shortname: shortName,
        currency:currency.code,
        expiryDate: closeDate ? formatDate(closeDate) : "",
        edit: CheckPermission("UPDATE_LOAN", permissions) && (
          <EditButton
            onClick={(e) => {
              e.stopPropagation();
              history.push(
                `/core/products/loan-products/edit-loan-product/${id}`
              );
            }}
          >
            Edit
          </EditButton>
        ),
      });
    });

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Short name", dataIndex: "shortname", key: "shortname" },
    { title: "Max Principal", dataIndex: "maxPrincipal", key: "maxPrincipal" },
    { title: "Interest Type", dataIndex: "interestType", key: "interestType" },
    { title: "Currency", dataIndex: "currency", key: "currency" },
    { title: "Expiry date", dataIndex: "expiryDate", key: "expiryDate" },
    // { title: "Status", dataIndex: "status", key: "status" },
    { title: "", dataIndex: "edit", key: "edit" },
  ];

    // maxPrincipal
  //interestType.value
  //currency.code

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Loan products</h3>
            <p className="font-weight sub-title">
              <span className="text-info">
                {/* <Link to="/core/configuration">Configuration</Link> /{" "} */}
                <Link to="/core/products">Products</Link>
              </span>{" "}
              / Loan products
            </p>
          </div>
          <div className="col-sm-12 col-md-6 px-0 pt-4">
            <ActionButtonsGroup>
              <div className="col-sm-12 col-md-6 pr-2">
                <InputGroup>
                  <InputLeftElement
                    width="3.1rem"
                    height="38px"
                    pointerEvents="none"
                  >
                    <img
                      src={
                        require("../../../../../assets/images/icons/search.svg")
                          .default
                      }
                      style={{ height: "30px" }}
                      alt="Icon"
                      className="icon-img"
                    />
                  </InputLeftElement>
                  <Input
                    style={{
                      background: "#ffffff",
                      height: "35px !important",
                    }}
                    type="text"
                    className="general-font-size"
                    placeholder="Filter by Name/Short name"
                    ref={search}
                    onChange={onFilterLoanProducts}
                  />
                </InputGroup>
              </div>
              <div className="col-sm-12 col-md-6 p-0 d-flex">
                {CheckPermission("CREATE_LOANPRODUCT", permissions) && (
                  <Link to="loan-products/create-loan-product">
                    <ActionButton>
                      <img
                        src={
                          require("../../../../../assets/images/icons/add.svg")
                            .default
                        }
                        alt="add"
                      />
                      Create
                    </ActionButton>
                  </Link>
                )}
              </div>
            </ActionButtonsGroup>
          </div>
        </div>
        <ContentWrapper>
          {loading ? (
            <TableLoader />
          ) : (
            CheckPermission("READ_LOANPRODUCT", permissions) && (
              <>
                {filtered !== null ? (
                  <Table
                    columns={columns}
                    dataSource={filteredLoanProducts}
                    onRow={(r) => ({
                      onClick: () =>
                        history.push(
                          `/core/products/loan-products/details/${r.key}`
                        ),
                    })}
                  />
                ) : (
                  <Table
                    columns={columns}
                    dataSource={dataSource}
                    pagination={{ defaultPageSize: 13 }}
                    defaultPageSize={13}
                    onRow={(r) => ({
                      onClick: () =>
                        history.push(
                          `/core/products/loan-products/details/${r.key}`
                        ),
                    })}
                  />
                )}
              </>
            )
          )}
        </ContentWrapper>
      </div>
    </>
  );
};

export default LoanProductsView;
