import React from "react";
import { NewActionButton, FormClass, ModalText } from "../styles";
import { Modal, Spinner } from "react-bootstrap";

const DeleteCurrency = ({ onHide, handleDelete, loading }) => {
  return (
    <>
      <Modal.Header>
        <Modal.Title>Delete Currency</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "95%" }}>
          <ModalText>Are you sure you want to delete this currency?</ModalText>
    
        </FormClass>
      </Modal.Body>
      <Modal.Footer>
      <div className="float-right mt-1">
            <NewActionButton color="#2C1DFF" onClick={onHide}>
              <img
                src={
                  require("../../../../../assets/images/icons/cancel.svg")
                    .default
                }
                alt="cancel icon"
              />
              Cancel
            </NewActionButton>
            <NewActionButton
              onClick={handleDelete}
              color="#2C1DFF"
              style={{ margin: "0px 0px 0 10px" }}
            >
              {loading ? (
                <Spinner
                  animation="border"
                  color="#fff"
                  className="mr-2 text-white"
                  size="sm"
                />
              ) : (
                <img
                  src={
                    require("../../../../../assets/images/icons/delete.svg")
                      .default
                  }
                  alt="submit icon"
                />
              )}
              Delete
            </NewActionButton>
          </div>
      </Modal.Footer>
    </>
  );
};

export default DeleteCurrency;
