/* eslint-disable import/no-anonymous-default-export */
import config from "../../config";
import axiosData from "../axiosData";

const getAllTellerGroups = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/tellers${!!id ? `/${id}` : ""}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
const getSingleTellerGroupCashier = (id, fromDate, toDate) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/tellers/${id}/cashiers?${
          !!fromDate ? `&fromDate=${!!fromDate ? fromDate : ""}` : ""
        }${!!toDate ? `&toDate=${!!toDate ? toDate : ""}` : ""}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
const getTellerCashierDetails = (tellerId, cashierId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/tellers/${tellerId}/cashiers/${cashierId}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
const getAllTellers = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: activity,
        url: `${config.apiGateway.UTILITY_API_URL}/tellering/get_teller`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
const getAllCashierActionRequests = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/cashieractions?${params}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
const getAllCashierActionRequestReceived = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/cashieractions/received?${params}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
const getAllCashierActionRequest = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/cashieractions/sent?${params}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
const getCashierTransactions = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/cashiertransactions${params}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
const getCashierDataDetails = (tellerId, cashierId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/tellers/${tellerId}/cashiers/${cashierId}`,
        // 30/cashiers?currencyCode=CAD&cashierId=56
        // url: `${config.apiGateway.BASE_API_URL}/tellers/${tellerId}/cashiers?currencyCode=${dropdownValue}&cashierId=${cashierId}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

const getCashierDataDetailsTeller = (tellerId, dropdownValue, cashierId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // url: `${config.apiGateway.BASE_API_URL}/tellers/${tellerId}/cashiers/${cashierId}`,
        // 30/cashiers?currencyCode=CAD&cashierId=56
        url: `${config.apiGateway.BASE_API_URL}/tellers/${tellerId}/cashiers?currencyCode=${dropdownValue}&cashierId=${cashierId}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

const getCashierTemplate = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: activity,
        url: `${config.apiGateway.BASE_API_URL}/tellers/${id}/cashiers/template`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
const getTransactionSummary = (id, cashierId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/tellers/${id}/cashiers/${cashierId}/transactionsummary`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

const createTellerGroup = (dataToSend) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/tellers`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
const updateTellerGroup = (dataToSend, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "PUT",
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/tellers/${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
const updateTellerCashier = (dataToSend, id, cashierId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "PUT",
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/tellers/${id}/cashiers/${cashierId}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
const createTellerCashier = (dataToSend, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        url: `${config.apiGateway.BASE_API_URL}/tellers/${id}/cashiers`,
        data: dataToSend,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
const createTeller = (dataToSend) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        data: dataToSend,
        url: `${config.apiGateway.UTILITY_API_URL}/tellering/create_teller`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

const createCashier = (dataToSend) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        data: dataToSend,
        url: `${config.apiGateway.UTILITY_API_URL}/tellering/create_till`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
const approveRejectRequestToVaultCashier = (id, command, note) => {
  return new Promise(async (resolve, reject) => {
    try {
      // console.log(id);
      const { data, error } = await axiosData({
        method: "POST",
        url: `${config.apiGateway.BASE_API_URL}/cashieractions/${id}?command=${command}`,
        data:
          command === "approve"
            ? { approvedNote: note }
            : command === "reject"
            ? { rejectedNote: note }
            : "",
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

const handleBuySellCash = (dataToSend) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/cashieractions`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

const getTills = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: dataToSend,
        url: `${config.apiGateway.UTILITY_API_URL}/tellering/get_till/${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

const getAllOffices = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: userData,
        url: `${config.apiGateway.BASE_API_URL}/offices`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

const getStaffInOffice = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: userData,
        url: `${config.apiGateway.BASE_API_URL}/staff?officeId=${id}&status=all`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
const getCurrencies = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: userData,
        url: `${config.apiGateway.BASE_API_URL}/currencies?fields=selectedCurrencyOptions`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

const deleteTellerGroup = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "DELETE",
        url: `${config.apiGateway.BASE_API_URL}/tellers/${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
const deleteTellerCashier = (tellerId, cashierId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "DELETE",
        url: `${config.apiGateway.BASE_API_URL}/tellers/${tellerId}/cashiers/${cashierId}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

const depositWithdrawCash = (dataToSend, command) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/cashiertransactions?command=${command}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
const getDataTables = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/savingsaccounts/${id}?fetchLoops=true&withMultiple=true`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export default {
  getAllOffices,
  getAllTellers,
  getAllTellerGroups,
  updateTellerGroup,
  updateTellerCashier,
  getStaffInOffice,
  getTellerCashierDetails,
  getSingleTellerGroupCashier,
  getAllCashierActionRequests,
  getCashierTemplate,
  getTills,
  getCurrencies,
  getCashierTransactions,
  createTeller,
  createCashier,
  createTellerGroup,
  createTellerCashier,
  handleBuySellCash,
  approveRejectRequestToVaultCashier,
  deleteTellerGroup,
  deleteTellerCashier,
  depositWithdrawCash,
  getAllCashierActionRequestReceived,
  getAllCashierActionRequest,
  getTransactionSummary,
  getDataTables,
  getCashierDataDetails,
  getCashierDataDetailsTeller,
};
