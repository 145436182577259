import React, { useState, useEffect } from "react";
import {
  ContentWrapper,
  FormClass,
  FormElements,
  ActionButton,
} from "./styles";
import {
  Select,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  Checkbox,
} from "@chakra-ui/react";
// import Pdf from "react-to-pdf";
import CurrencyFormat from "react-currency-format";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import TableLoader from "./TableLoader";
import { formatDate } from "../../../../../util/FormatDate";
import { formatCurrency } from "../../../../../util/FormatAmount";

import {
  getLoansProductOptions,
  getSelectedLoanProductData,
  getCalculatedLoan,
} from "../../../../../actions/utilityActions";

const LoanCalculator = () => {
  const dispatch = useDispatch();

  const productOptionData = useSelector(
    (state) => state.loanProductOptions.productData
  );

  const calculatedLoan = useSelector(
    (state) => state.calculatedLoan.calculatedLoan
  );

  const { productOptions } = productOptionData;
  console.log(productOptions , 'productOptions');



  const selectedLoanData = useSelector((state) => state.selectedLoanData);
  const { productData } = selectedLoanData;

  console.log(productData , 'productData');
  const standingInstructionsReducer = useSelector(
    (state) => state.standingInstructionsReducer
  );
  const { loading, standingInstruction } = standingInstructionsReducer;

  useEffect(() => {
    dispatch(getLoansProductOptions("1"));
  }, [dispatch]);

  const [loanCalculatorData, setLoanCalculatorData] = useState({
    clientId: "1",
    loanTermFrequency: "",
    loanTermFrequencyType: "",
    numberOfRepayments: "",
    repaymentEvery: "",
    repaymentFrequencyType: "",
    interestRatePerPeriod: "",
    amortizationType: 1,
    interestType: "",
    interestCalculationPeriodType: 1,
    transactionProcessingStrategyId: 1,
    linkAccountId: "",
    loanType: "individual",
    isEqualAmortization: false,
  });

  const [
    allowPartialPeriodInterestCalcualtion,
    setAllowPartialPeriodInterestCalcualtion,
  ] = useState(false);
  const [
    createStandingInstructionAtDisbursement,
    setCreateStandingInstructionAtDisbursement,
  ] = useState(false);
  const [principal, setPrincipal] = useState();
  const [expectedDisbursementDate, setExpectedDisbursementDate] = useState(
    new Date() || ""
  );
  const [submittedOnDate, setSubmittedOnDate] = useState(new Date() || "");

  const loanCalculatorChangeHandler = (e) =>
    setLoanCalculatorData({
      ...loanCalculatorData,
      [e.target.name]: e.target.value,
    });

  useEffect(() => {
    if (!!productData && !!productData.principal) {
      setPrincipal(productData.principal);
      setLoanCalculatorData({
        loanTermFrequency: !!productData.termFrequency
          ? productData.termFrequency
          : "",
        loanTermFrequencyType: !!productData.repaymentFrequencyType
          ? productData.repaymentFrequencyType?.id
          : "",
        numberOfRepayments: !!productData.numberOfRepayments
          ? productData.numberOfRepayments
          : "",
        repaymentEvery: !!productData.repaymentEvery
          ? productData.repaymentEvery
          : "",
        repaymentFrequencyType: !!productData.repaymentFrequencyType
          ? productData.repaymentFrequencyType?.id
          : "",
        interestRatePerPeriod: !!productData.interestRatePerPeriod
          ? productData.interestRatePerPeriod
          : "",
        amortizationType: !!productData.amortizationType
          ? productData.amortizationType?.id
          : "",
        interestType: !!productData.interestType
          ? productData.interestType?.id
          : "",
        isEqualAmortization: productData.isEqualAmortization,
        interestCalculationPeriodType:
          !!productData.interestCalculationPeriodType
            ? productData.interestCalculationPeriodType?.id
            : "",
        linkAccountId:
          !!productData &&
          !!productData.accountLinkingOptions &&
          !!productData.accountLinkingOptions.length &&
          productData.accountLinkingOptions[1]?.id,
      });
      setAllowPartialPeriodInterestCalcualtion(
        productData.allowPartialPeriodInterestCalcualtion
      );
    }
  }, [productData]);

  let {
    loanTermFrequencyType,
    loanTermFrequency,
    numberOfRepayments,
    repaymentFrequencyType,
    repaymentEvery,
    interestRatePerPeriod,
    isEqualAmortization,
    amortizationType,
    interestType,
    interestCalculationPeriodType,
    linkAccountId,
  } = loanCalculatorData;

  const [accountType, setAccountType] = useState();

  useEffect(() => {
    if (accountType) {
      dispatch(getSelectedLoanProductData("1", accountType));
    }
  }, [accountType, dispatch]);

  const handleSubmit = () => {
    let dataToSend = {
      ...loanCalculatorData,
      principal,
      createStandingInstructionAtDisbursement,
      allowPartialPeriodInterestCalcualtion,
      dateFormat: "dd MMMM yyyy",
      locale: "en",
      expectedDisbursementDate: formatDate(expectedDisbursementDate, true),
      submittedOnDate: formatDate(submittedOnDate, true),
      productId: accountType,
      loanType: "individual",
      transactionProcessingStrategyId: 1,
      clientId: "1",
    };
    dispatch(getCalculatedLoan(dataToSend));
  };

  const toConvertToPdf = React.createRef();
  const ref = React.createRef();

  const generatePDF = async () => {
    // document.body.css("overflow-y", "hidden");
    // document.getElementById("divToPrint").style.position = "absolute";

    const input = document.getElementById("divToPrint");
    html2canvas(input, { scrollY: -window.scrollY }).then((canvas) => {
      // document.body.appendChild(canvas); // if you want see your screenshot in body.

      const imgData = canvas.toDataURL("image/png");
      // window.open(imgData);
      const pdf = new jsPDF("portrait", "mm", "a4");

      pdf.setFontSize(40);
      // pdf.text(35, 25, "WoodCore Loan Calculator");
      pdf.addImage(imgData, "JPEG", 12, 20, 180, 200);
      // pdf.addImage(imgData, "JPEG", 60, 60);
      pdf.save("WoodCore-Generated-Loan-Pdf.pdf");
    });
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Loan Calculator</h3>
            <p className="sub-title">Loan calculator for clients loan</p>
            <p className="font-weight sub-title mt-2">
            <span className="text-info">
                <Link to="/core/clients">Customer</Link>
              </span>
              /{" "}
              <span className="text-info">
                {/* <Link to={`/core/client/${clientId}`}>
                  {!!clientName ? `${clientName} /` : ""}
                </Link> */}
              </span>
              Loan Calculator
            </p>
          </div>
        </div>
        <ContentWrapper>
          <FormClass className="h-100 container p-relative">
            <FormElements>
              <div className="row h-100" style={{ paddingTop: "100px" }}>
                {/* <div className="col-sm-12 col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Client Id</label>
                    <Input
                      style={{ width: "60%" }}
                      size="md"
                      name="clientName"
                      value={clientName}
                      onChange={loanCalculatorChangeHandler}
                    />
                  </div>
                </div> */}
                <div className="col-sm-12 col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Select Loan Product</label>
                    <Select
                      size="md"
                      name="accountType"
                      placeholder="Select Loan Product"
                      value={accountType}
                      onChange={(e) => setAccountType(e.target.value)}
                    >
                      {!!productOptions && !!productOptions.length
                        ? productOptions.map(({ name, id }) => (
                          <option key={id} value={id}>
                            {name}
                          </option>
                        ))
                        : null}
                    </Select>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Principal</label>
                    <CurrencyFormat
                      value={principal}
                      thousandSeparator={true}
                      className="amount-input "
                      style={{
                        width: "60%",
                      }}
                      // prefix={"$"}
                      onValueChange={(values) => {
                        const { value } = values;
                        // formattedValue = $2,223
                        // value ie, 2223
                        setPrincipal(value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 px-0">
                  <div className="form-group d-flex justify-content-between align-items-center mb-10-imp">
                    <label className="text-label">Loan Frequency</label>
                    <Input
                      style={{ width: "60%" }}
                      size="md"
                      name="loanTermFrequency"
                      value={loanTermFrequency}
                      onChange={loanCalculatorChangeHandler}
                    />
                  </div>
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-muted d-flex flex-align-center"></label>
                    <Select
                      size="md"
                      name="loanTermFrequencyType"
                      value={loanTermFrequencyType}
                      onChange={loanCalculatorChangeHandler}
                    >
                      {!!productData &&
                        !!productData.repaymentFrequencyTypeOptions &&
                        !!productData.repaymentFrequencyTypeOptions.length
                        ? productData.repaymentFrequencyTypeOptions.map(
                          ({ value, id }) => (
                            <option key={id} value={id}>
                              {value}
                            </option>
                          )
                        )
                        : null}
                    </Select>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Number of repayments*</label>
                    <Input
                      style={{ width: "60%" }}
                      value={numberOfRepayments}
                      name="numberOfRepayments"
                      onChange={loanCalculatorChangeHandler}
                      size="md"
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center mb-10-imp">
                    <label className="text-muted d-flex flex-align-center">
                      <span>Repaid Every</span>
                    </label>
                    <div
                      style={{ width: "60%" }}
                      className="d-flex justify-content-between align-items-center"
                    >
                      <Input
                        style={{ width: "49%" }}
                        value={repaymentEvery}
                        onChange={loanCalculatorChangeHandler}
                      />
                      <div
                        className="mini-select-wrap"
                        style={{ width: "49%" }}
                      >
                        <Select
                          placeholder="Select one"
                          size="md"
                          width="100%"
                          style={{ width: "100% !important" }}
                          name="repaymentFrequencyType"
                          value={Number(repaymentFrequencyType)}
                          onChange={loanCalculatorChangeHandler}
                        >
                          {!!productData &&
                            productData.termFrequencyTypeOptions &&
                            productData.termFrequencyTypeOptions.length &&
                            productData.termFrequencyTypeOptions.map((opt) => (
                              <option id={opt.id} value={opt.id} key={opt.id}>
                                {opt.value}
                              </option>
                            ))}
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-muted d-flex flex-align-center">
                      <span>Nominal Interest Rate*</span>
                    </label>
                    <InputGroup style={{ width: "60%", height: "48px" }}>
                      <Input
                        style={{ height: "48px" }}
                        value={interestRatePerPeriod}
                        onChange={loanCalculatorChangeHandler}
                      />
                      <InputRightElement
                        style={{
                          width: "fit-content",
                          height: "48px",
                          background: "#f6f6f6",
                          borderRadius: "0 4px 4px 0",
                          padding: "0 1rem 0 0.3rem",
                        }}
                        children={
                          <span>
                            {productData.interestRateFrequencyType !==
                              undefined &&
                              productData.interestRateFrequencyType.value}
                          </span>
                        }
                      />
                    </InputGroup>
                  </div>
                </div>

                {/* <div className="col-sm-12 col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-muted d-flex flex-align-center">
                      <span>Equal Amortization</span>
                    </label>
                    <Select
                      placeholder="Select Yes or No"
                      size="md"
                      value={isEqualAmortization}
                      name="isEqualAmortization"
                      onChange={loanCalculatorChangeHandler}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </Select>
                  </div>
                </div> */}

                {/* <div className="col-sm-12 col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-muted d-flex flex-align-center">
                      <span>Amortization*</span>
                    </label>
                    <Select
                      placeholder="Select amortization type"
                      size="md"
                      value={amortizationType}
                      name="amortizationType"
                      onChange={loanCalculatorChangeHandler}
                    >
                      {!!productData &&
                        productData.amortizationTypeOptions !== undefined &&
                        productData.amortizationTypeOptions.length &&
                        productData.amortizationTypeOptions.map((opt) => (
                          <option id={opt.id} value={opt.id} key={opt.id}>
                            {opt.value}
                          </option>
                        ))}
                    </Select>
                  </div>
                </div> */}

                <div className="col-sm-12 col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-muted d-flex flex-align-center">
                      <span>Interest method</span>
                    </label>
                    <Select
                      placeholder="Select type"
                      size="md"
                      name="interestType"
                      value={Number(interestType)}
                      onChange={loanCalculatorChangeHandler}
                    >
                      {!!productData &&
                        productData.interestTypeOptions !== undefined &&
                        !!productData.interestTypeOptions.length &&
                        productData.interestTypeOptions.map((opt) => (
                          <option id={opt.id} value={opt.id} key={opt.id}>
                            {opt.value}
                          </option>
                        ))}
                    </Select>
                  </div>
                </div>

                {/* <div className="col-sm-12 col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-muted d-flex flex-align-center">
                      <span>Linked Account*</span>
                    </label>
                    <Select
                      placeholder="Select Linked Account"
                      size="md"
                      name="linkAccountId"
                      value={linkAccountId}
                      onChange={loanCalculatorChangeHandler}
                    >
                      {!!productData &&
                        productData.accountLinkingOptions !== undefined &&
                        !!productData.accountLinkingOptions.length &&
                        productData.accountLinkingOptions.map((opt) => (
                          <option id={opt.id} value={opt.id} key={opt.id}>
                            {opt.productName}({opt.accountNo})
                          </option>
                        ))}
                    </Select>
                  </div>
                </div> */}

                {/* <div className="col-sm-12 col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-muted d-flex flex-align-center">
                      <span>Interest calculation period*</span>
                    </label>
                    <Select
                      placeholder="Select Interest calculation period"
                      size="md"
                      name="interestCalculationPeriodType"
                      value={interestCalculationPeriodType}
                      onChange={loanCalculatorChangeHandler}
                    >
                      {!!productData &&
                        productData.interestCalculationPeriodTypeOptions !==
                          undefined &&
                        !!productData.interestCalculationPeriodTypeOptions
                          .length &&
                        productData.interestCalculationPeriodTypeOptions.map(
                          (opt) => (
                            <option id={opt.id} value={opt.id} key={opt.id}>
                              {opt.value}
                            </option>
                          )
                        )}
                    </Select>
                  </div>
                </div> */}

                <div className="col-sm-12 col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-muted d-flex flex-align-center">
                      <span>Allow Partial Interest Calculation</span>
                    </label>
                    <div className="" style={{ width: "60%" }}>
                      <Checkbox
                        isChecked={allowPartialPeriodInterestCalcualtion}
                        onChange={(e) =>
                          setAllowPartialPeriodInterestCalcualtion(
                            !allowPartialPeriodInterestCalcualtion
                          )
                        }
                      ></Checkbox>
                    </div>
                  </div>
                </div>

                {/* <div className="col-sm-12 col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-muted d-flex flex-align-center">
                      <span>Create Standing Instruction at Disbursement</span>
                    </label>
                    <div className="" style={{ width: "60%" }}>
                      <Checkbox
                        isChecked={createStandingInstructionAtDisbursement}
                        onChange={(e) =>
                          setCreateStandingInstructionAtDisbursement(
                            !createStandingInstructionAtDisbursement
                          )
                        }
                      ></Checkbox>
                    </div>
                  </div>
                </div> */}

                {/* <div className="col-sm-12 col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Submitted On</label>
                    <FormControl style={{ width: "60%" }}>
                      <DatePicker
                        style={{ width: "100%" }}
                        id="start-date"
                        selected={submittedOnDate}
                        onChange={(date) => setSubmittedOnDate(date)}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="dd/mmm/yyyy"
                      />
                    </FormControl>
                  </div>
                </div> */}
                <div className="col-sm-12 col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">
                      Expected Disbursement Date
                    </label>
                    <FormControl style={{ width: "60%" }}>
                      <DatePicker
                        style={{ width: "100%" }}
                        id="start-date"
                        selected={expectedDisbursementDate}
                        onChange={(date) => setExpectedDisbursementDate(date)}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="dd/mmm/yyyy"
                      />
                    </FormControl>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="float-right mt-3">
                    <ActionButton
                      onClick={handleSubmit}
                      color="#2C1DFF"
                      style={{ margin: "0px 0px 0 10px" }}
                    >
                      <img
                        src={
                          require("../../../../../assets/images/icons/save.svg")
                            .default
                        }
                        alt="submit icon"
                      />
                      Calculate
                    </ActionButton>
                  </div>
                </div>
              </div>

              <div className="h-100 mt-5" id="divToPrint">
                {!loading ? (
                  !!calculatedLoan &&
                    !!calculatedLoan.periods &&
                    calculatedLoan.periods.length > 0 ? (
                    <>
                      <div className="p-relative" ref={toConvertToPdf}>
                        <Table hover striped responsive={true} bordered>
                          <thead>
                            <tr>
                              <th>Disbursement Date</th>
                              <th>{formatDate(expectedDisbursementDate)}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Loan Amount </td>
                              <td>
                                {" "}
                                {!!calculatedLoan.totalPrincipalDisbursed
                                  ? formatCurrency(
                                    calculatedLoan.totalPrincipalDisbursed
                                  )
                                  : 0}
                              </td>
                            </tr>
                            <tr>
                              <td>Total Interest Charged</td>
                              <td>
                                {!!calculatedLoan.totalInterestCharged
                                  ? formatCurrency(
                                    calculatedLoan.totalInterestCharged
                                  )
                                  : 0}
                              </td>
                            </tr>
                            <tr>
                              <td>Total Fees Charged</td>
                              <td>
                                {!!calculatedLoan.totalFeeChargesCharged
                                  ? formatCurrency(
                                    calculatedLoan.totalFeeChargesCharged
                                  )
                                  : 0}
                              </td>
                            </tr>
                            <tr>
                              <td>Total Amount To Be Repaid</td>
                              <td>
                                {!!calculatedLoan.totalRepaymentExpected
                                  ? formatCurrency(
                                    calculatedLoan.totalRepaymentExpected
                                  )
                                  : 0}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                        <div className="mt-5">
                          <Table hover striped responsive={true} bordered>
                            <thead className="border-bottom">
                              <tr>
                                <th>#</th>
                                <th>Dates</th>
                                <th># Days</th>
                                <th>Disbursement</th>
                                <th>Principal Due</th>
                                <th>Principal Balance</th>
                                <th>Interest Due</th>
                                <th>Fees</th>
                                <th>Total Due</th>
                              </tr>
                            </thead>
                            <tbody>
                              {calculatedLoan.periods.map(
                                (
                                  {
                                    dueDate,
                                    principalDisbursed,
                                    principalLoanBalanceOutstanding,
                                    feeChargesDue,
                                    feeChargesOutstanding,
                                    totalOriginalDueForPeriod,
                                    totalDueForPeriod,
                                    totalOutstandingForPeriod,
                                    totalOverdue,
                                    totalActualCostOfLoanForPeriod,
                                    period,
                                    daysInPeriod,
                                    principalDue,
                                    interestDue,
                                  },
                                  i
                                ) => (
                                  <tr key={i}>
                                    <td>{!!period ? period : ""}</td>
                                    <td>{formatDate(dueDate)}</td>
                                    <td>
                                      {!!daysInPeriod ? daysInPeriod : ""}
                                    </td>
                                    <td>
                                      {!!principalDisbursed
                                        ? formatCurrency(principalDisbursed)
                                        : 0}
                                    </td>
                                    <td>
                                      {!!principalDue
                                        ? formatCurrency(principalDue)
                                        : 0}
                                    </td>
                                    <td>
                                      {!!principalLoanBalanceOutstanding
                                        ? formatCurrency(
                                          principalLoanBalanceOutstanding
                                        )
                                        : 0}
                                    </td>
                                    <td>
                                      {!!interestDue
                                        ? formatCurrency(interestDue)
                                        : 0}
                                    </td>
                                    <td>
                                      {!!feeChargesDue
                                        ? formatCurrency(feeChargesDue)
                                        : 0}
                                    </td>
                                    <td>
                                      {!!totalDueForPeriod
                                        ? formatCurrency(totalDueForPeriod)
                                        : 0}
                                    </td>
                                  </tr>
                                )
                              )}
                              <tr>
                                <td></td>
                                <td>Total</td>
                                <td></td>
                                <td>
                                  {calculatedLoan.totalPrincipalDisbursed
                                    ? formatCurrency(
                                      calculatedLoan.totalPrincipalDisbursed
                                    )
                                    : 0}
                                </td>
                                <td>
                                  {" "}
                                  {calculatedLoan.totalPrincipalExpected
                                    ? formatCurrency(
                                      calculatedLoan.totalPrincipalExpected
                                    )
                                    : 0}
                                </td>
                                <td></td>
                                <td>
                                  {calculatedLoan.totalInterestCharged
                                    ? formatCurrency(
                                      calculatedLoan.totalInterestCharged
                                    )
                                    : 0}
                                </td>
                                <td>
                                  {" "}
                                  {calculatedLoan.totalFeeChargesCharged
                                    ? formatCurrency(
                                      calculatedLoan.totalFeeChargesCharged
                                    )
                                    : 0}
                                </td>
                                <td>
                                  {calculatedLoan.totalRepaymentExpected
                                    ? formatCurrency(
                                      calculatedLoan.totalRepaymentExpected
                                    )
                                    : 0}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                      <div className="spaceBetweenFlexEnd">
                        <button
                          className="btn-small"
                          onClick={() => generatePDF()}
                        >
                          Generate pdf
                        </button>
                        {/* <Pdf targetRef={ref} filename="code-example.pdf">
                          {({ toPdf }) => (
                            <button className="btn-small" onClick={toPdf}>
                              Generate Pdf
                            </button>
                          )}
                        </Pdf>

                        <div ref={ref}>
                          <h1>Hello CodeSandbox</h1>
                          <h2>Start editing to see some magic happen!</h2>
                        </div> */}

                        {/* <ReactToPdf>
                          {({ toPdf, toConvertToPdf }) => (
                            <div
                              className="btn-small"
                              onClick={toPdf}
                              ref={toConvertToPdf}
                            >
                              Download PDF
                            </div>
                          )}
                        </ReactToPdf> */}
                      </div>
                    </>
                  ) : (
                    <p className="text-dark text-center"></p>
                  )
                ) : (
                  <TableLoader />
                )}
              </div>

              <br />
              <br />
            </FormElements>
          </FormClass>
        </ContentWrapper>
      </div>
    </>
  );
};

export default LoanCalculator;
