import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { FormControl } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { FormClass, ActionButton } from "../../styles.jsx";
import { activateCustomer } from "../../../../../../actions/customerActions";
import { formatDate } from "../../../../../../util/FormatDate";

const ActivateClient = ({ onHide, userData, history }) => {
  const dispatch = useDispatch();

  const [activatedOn, setActivatedOn] = useState(new Date());

  let { id } = userData || {};

  const handleActivateClient = () => {
    let dataToSend = {
      activationDate: formatDate(activatedOn, true),
      dateFormat: "dd MMMM yyyy",
      locale: "en",
    };
    dispatch(activateCustomer(id, dataToSend, history));
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>Activate Customer</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <div className="form-group d-flex justify-content-between align-items-center mt-5">
            <label className="text-label">Activation Date*</label>
            <FormControl style={{ width: "70%" }}>
              <DatePicker
                id="published-date"
                selected={activatedOn}
                onChange={(date) => setActivatedOn(date)}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="dd/mmm/yyyy"
              />
            </FormControl>
          </div>
        </FormClass>
      </Modal.Body>
      <Modal.Footer>
      <div className="float-right mt-1">
            <ActionButton color="#2C1DFF" onClick={onHide}>
              <img
                src={
                  require("../../../../../../assets/images/icons/cancel.svg")
                    .default
                }
                alt="cancel icon"
              />
              Cancel
            </ActionButton>
            <ActionButton
              color="#2C1DFF"
              style={{ margin: "0px 0px 0 10px" }}
              onClick={(e) => {
                e.preventDefault();
                handleActivateClient();
              }}
            >
              <img
                src={
                  require("../../../../../../assets/images/icons/save.svg")
                    .default
                }
                alt="submit icon"
              />
              Submit
            </ActionButton>
          </div>
      </Modal.Footer>
    </>
  );
};

export default ActivateClient;
