import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getNibssOutflowData,
  getNibssOutflowDataFilter,
  resetFilteredTransactionsOutflow,
} from "../../../../actions/nibssConnectActions";
import TableLoaderView from "../TableLoader";
import {
  formatDateString,
  formatDateTimeNibss,
} from "../../../../util/FormatDate";
import { useHistory } from "react-router-dom";
// import InflowFilterReportModal from "../../ManageNibssAccount/InflowFilterModal";
import CheckPermission from "../../../../util/CheckPermission";
import { DownloadButton, FilterTransButton } from "../styles";
// import OutFlowFilterReportModal from "../../ManageNibssAccount/DownloadModal";
import Toast from "../../../../util/Toast";
import { getOutflowDataDownload } from "../../../../services/Api/nibss.api.services";

function ManageOutflow({
  handleFilterTrans,
  toReportDate,
  fromReportDate,
  setToReportDate,
  setFromReportDate,
  onHide,
  setFilterModal,
  filterModal,
  tenantCode,
}) {
  const dispatch = useDispatch();

  let history = useHistory();
  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  const nibssOutflowList = useSelector((state) => state.nibssOutflowTemplate);

  const nibssOutflowListFilter = useSelector(
    (state) => state.nibssGetFilteredTransTemplate
  );

  const { loading, nibssOutflowTemplate } = nibssOutflowList;

  const { nibssGetFilteredTransTemplate } = nibssOutflowListFilter;

  let filteredData = nibssGetFilteredTransTemplate?.pageItems;
  let filteredTotalRecords =
    nibssGetFilteredTransTemplate?.totalFilteredRecords;

  const { totalItems, numberOfPages, totalFilteredRecords, pageItems } =
    nibssOutflowTemplate || {};

  // const { totalItems, numberOfPages, totalFilteredRecords, pageItems } =
  // nibssGetFilteredTransTemplate || {};

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 12,
  });

  const [paginationFilter, setPaginationFilter] = useState({
    current: 1,
    pageSize: 12,
  });

  const [activePagination, setActivePagination] = useState();
  const [activePaginationFilter, setActivePaginationFilter] = useState();
  const [myData, setMyData] = useState([]);
  // console.log(nibssOutflowTemplate, "nibssOutflowTemplate");

  useEffect(() => {
    dispatch(
      getNibssOutflowData(
        "orderBy=createdAt&sortBy=DESC&limit=12&offset=0",
        tenantCode
      )
    );
  }, []);

  useEffect(() => {
    if (!!nibssOutflowTemplate && !!nibssOutflowTemplate?.length) {
      setMyData(nibssOutflowTemplate);
    } else if (!!pageItems?.length) {
      setMyData(pageItems);
    }
  }, [pageItems, nibssOutflowTemplate]);

  // FILTER TRANSACTION
  useEffect(() => {
    if (
      !!nibssGetFilteredTransTemplate &&
      !!nibssGetFilteredTransTemplate?.length
    ) {
      setMyData(nibssGetFilteredTransTemplate);
    } else if (!!filteredData?.length) {
      setMyData(filteredData);
    }
  }, [filteredData, nibssGetFilteredTransTemplate]);

  useEffect(() => {
    if (totalFilteredRecords !== 0 && totalFilteredRecords !== 1) {
      setPagination({
        current: activePagination,
        pageSize: 12,
        total: totalFilteredRecords,
      });
    } else if (totalItems === 0 && numberOfPages === 0) {
      setPagination(false);
    } else if (!!nibssOutflowTemplate && !!nibssOutflowTemplate?.length) {
      setPagination({ defaultPageSize: 12 });
    }
  }, [
    totalItems,
    numberOfPages,
    totalFilteredRecords,
    activePagination,
    nibssOutflowTemplate,
  ]);

  // FILTER TRANSACTION
  useEffect(() => {
    if (filteredTotalRecords !== 0 && filteredTotalRecords !== 1) {
      setPaginationFilter({
        current: activePaginationFilter,
        pageSize: 12,
        total: filteredTotalRecords,
      });
    } else if (totalItems === 0 && numberOfPages === 0) {
      setPaginationFilter(false);
    } else if (
      !!nibssGetFilteredTransTemplate &&
      !!nibssGetFilteredTransTemplate?.length
    ) {
      setPaginationFilter({ defaultPageSize: 12 });
    }
  }, [
    totalItems,
    numberOfPages,
    filteredTotalRecords,
    activePaginationFilter,
    nibssGetFilteredTransTemplate,
  ]);

  const handleTableChange = (pagination, filters, sorter) => {
    setActivePagination(pagination.current);
    if (!!nibssOutflowTemplate && !!nibssOutflowTemplate?.length) {
      setPagination({
        current: activePagination,
        pageSize: 12,
        total: nibssOutflowTemplate?.length,
      });
    } else {
      let myOffsect = pagination.current * 12;

      // let dataToSend2 = `limit=12&offset=${myOffsect - 12}&sortOrder=DESC`;
      let dataToSend2 = `orderBy=createdAt&sortBy=DESC&limit=12&offset=${
        myOffsect - 12
      }`;
      dispatch(getNibssOutflowData(dataToSend2, tenantCode));
    }
  };

  // FILTER HANDLETABLECHANGE
  const handleFilterTableChange = (paginationFilter, filters, sorte) => {
    setActivePaginationFilter(paginationFilter.current);
    if (
      !!nibssGetFilteredTransTemplate &&
      !!nibssGetFilteredTransTemplate?.length
    ) {
      setPaginationFilter({
        current: activePaginationFilter,
        pageSize: 12,
        total: nibssGetFilteredTransTemplate?.length,
      });
    } else {
      let myOffsect = paginationFilter.current * 12;
      // console.log(fromReportDate , toReportDate , "endDate , startDate")

      let startDate = formatDateString(fromReportDate, "yyyy-MMMM-dd");
      let endDate = formatDateString(toReportDate, "yyyy-MMMM-dd");

      let dataToSend2 = `orderBy=createdAt&sortBy=DESC&limit=12&offset=${
        myOffsect - 12
      }`;

      dispatch(
        getNibssOutflowDataFilter(
          dataToSend2,
          tenantCode,
          startDate,
          endDate,
          onHide
        )
      );
    }
  };

  const truncateString = (str, num) => {
    if (str?.length > num) {
      let subStr = str.substring(0, num);
      return subStr + "...";
    } else {
      return str;
    }
  };

  // reset filtered Transactions
  useEffect(() => {
    dispatch(resetFilteredTransactionsOutflow());
  }, []);

  const dataSource = [];
  const filteredTransaction = [];

  !loading &&
    nibssOutflowTemplate?.pageItems?.forEach((outflow) => {
      const {
        id,
        createdAt,
        transactionRequestStatus,
        responseCode,
        customerAccountNumber,
        customerAccountName,
        amount,
        beneficiaryAccountName,
        beneficiaryAccountNumber,
        beneficiaryBankName,
        paymentReference,
        sessionId,
        narration,
      } = outflow;
      dataSource.push({
        key: id,
        createdAt: formatDateTimeNibss(createdAt),
        transactionRequestStatus: (
          <>
            <div>
              {transactionRequestStatus === "TRANSFER_APPROVED" ? (
                <span className="new-text-success">Approved</span>
              ) : (
                ""
              )}
              {transactionRequestStatus === "PENDING" ? (
                <span className="new-text-pending">Pending</span>
              ) : (
                ""
              )}
              {transactionRequestStatus === "INVALID_REQUEST" ? (
                <span className="new-text-danger">Invalid Request</span>
              ) : (
                ""
              )}
              {transactionRequestStatus === "UNKNOWN" ? (
                <span className="new-text-danger">Unknown</span>
              ) : (
                ""
              )}
            </div>
          </>
        ),
        responseCode: responseCode,
        customerAccountNumber: customerAccountNumber,
        customerAccountName: customerAccountName,
        amount: amount,
        name: truncateString(beneficiaryAccountName, 15),
        number: beneficiaryAccountNumber,
        beneficiaryBankName: beneficiaryBankName,
        ref: truncateString(paymentReference, 20),
        sessionId: sessionId,
        narration: truncateString(narration, 20),
      });
    });

  // FILTER DATASOURCE
  !loading &&
    filteredData?.forEach((outflow) => {
      const {
        id,
        createdAt,
        transactionRequestStatus,
        responseCode,
        customerAccountNumber,
        customerAccountName,
        amount,
        beneficiaryAccountName,
        beneficiaryAccountNumber,
        beneficiaryBankName,
        paymentReference,
        sessionId,
        narration,
      } = outflow;
      filteredTransaction.push({
        key: id,
        createdAt: formatDateTimeNibss(createdAt),
        transactionRequestStatus: (
          <>
            <div>
              {transactionRequestStatus === "TRANSFER_APPROVED" ? (
                <span className="new-text-success">Approved</span>
              ) : (
                ""
              )}
              {transactionRequestStatus === "PENDING" ? (
                <span className="new-text-pending">Pending</span>
              ) : (
                ""
              )}
              {transactionRequestStatus === "INVALID_REQUEST" ? (
                <span className="new-text-danger">Invalid Request</span>
              ) : (
                ""
              )}
              {transactionRequestStatus === "UNKNOWN" ? (
                <span className="new-text-danger">Unknown</span>
              ) : (
                ""
              )}
            </div>
          </>
        ),
        responseCode: responseCode,
        customerAccountNumber: customerAccountNumber,
        customerAccountName: customerAccountName,
        amount: amount,
        name: truncateString(beneficiaryAccountName, 15),
        number: beneficiaryAccountNumber,
        beneficiaryBankName: beneficiaryBankName,
        ref: truncateString(paymentReference, 20),
        sessionId: sessionId,
        narration: truncateString(narration, 20),
      });
    });

  const columns = [
    { title: "Date / Time", dataIndex: "createdAt", key: "createdAt" },
    {
      title: "Transaction Status",
      dataIndex: "transactionRequestStatus",
      key: "transactionRequestStatus",
    },
    { title: "Response Code", dataIndex: "responseCode", key: "responseCode" },
    {
      title: "Receipient's Account Number",
      dataIndex: "number",
      key: "number",
    },
    { title: "Receipient's Name", dataIndex: "name", key: "name" },
    {
      title: "Receipient's Bank Name",
      dataIndex: "beneficiaryBankName",
      key: "beneficiaryBankName",
    },
    { title: "Amount", dataIndex: "amount", key: "amount" },
    {
      title: "Sender's Account Number",
      dataIndex: "customerAccountNumber",
      key: "customerAccountNumber",
    },
    {
      title: "Sender's Account Name",
      dataIndex: "customerAccountName",
      key: "customerAccountName",
    },
    { title: "Reference ID", dataIndex: "ref", key: "ref" },
    { title: "Session ID", dataIndex: "sessionId", key: "sessionId" },
    { title: "Narration", dataIndex: "narration", key: "narration" },
  ];

  const [outDownloadDataSource, setOutDownloadDataSource] = useState([]);

  useEffect(() => {
    if (tenantCode) {
      getOutflowDataDownload(
        "orderBy=createdAt&sortBy=DESC&limit=10000&offset=0",
        tenantCode
      ).then((data) => setOutDownloadDataSource(data?.pageItems));
    }
  }, [tenantCode]);

  // Download Report
  const downloadCSVOutflow = () => {
    if (
      filteredData !== null &&
      filteredData !== undefined
      // filteredData === null
    ) {
      const items = filteredData;
      // Convert JSON data to CSV
      const replacer = (key, value) => (value === null ? "" : value);
      // const header = Object.keys(items[0]);
      const header = [
        "DATE/TIME",
        "TRANSACTION STATUS",
        "RESPONSE CODE",
        "SENDER'S ACCOUNT NUMBER",
        "SENDER'S ACCOUNT NAME",
        "AMOUNT",
        "RECEIPIENT'S ACCOUNT NUMBER",
        "RECEIPIENT'S ACCOUNT NAME",
        "RECEIPIENT'S BANK NAME",
        "REFERENCE ID",
        "SESSION ID",
        "NARRATION",
      ]; //Object.keys(items[0]);

      const headerFilter = [
        "createdAt",
        "transactionRequestStatus",
        "responseCode",
        "customerAccountNumber",
        "customerAccountName",
        "amount",
        "beneficiaryAccountNumber",
        "beneficiaryAccountName",
        "receipientBankName",
        "nameEnquiryReference",
        "sessionId",
        "narration",
      ]; //Object.keys(items[0]);

      let csv = items.map((row) =>
        headerFilter
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      );
      csv.unshift(header.join(","));
      csv = csv.join("\r\n");
      // Download the CSV file
      const filename =
        "Outflow Transaction History" +
        Math.floor(Math.random() * (9000 - 1000) + 1000) +
        "data.csv";
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      if (link.download !== undefined) {
        link.setAttribute("href", URL.createObjectURL(blob));
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } else {
      const items = outDownloadDataSource;
      // Convert JSON data to CSV
      const replacer = (key, value) => (value === null ? "" : value);

      const headerTitle = ["OUTFLOW TRANSACTIONs", ",", ","]; //Object.keys(items[0]);

      const header = [
        "DATE/TIME",
        "TRANSACTION STATUS",
        "RESPONSE CODE",
        "SENDER'S ACCOUNT NUMBER",
        "SENDER'S ACCOUNT NAME",
        "AMOUNT",
        "RECEIPIENT'S ACCOUNT NUMBER",
        "RECEIPIENT'S ACCOUNT NAME",
        "RECEIPIENT'S BANK NAME",
        "REFERENCE ID",
        "SESSION ID",
        "NARRATION",
      ]; //Object.keys(items[0]);
      const headerFilter = [
        "createdAt",
        "transactionRequestStatus",
        "responseCode",
        "customerAccountNumber",
        "customerAccountName",
        "amount",
        "beneficiaryAccountNumber",
        "beneficiaryAccountName",
        "receipientBankName",
        "nameEnquiryReference",
        "sessionId",
        "narration",
      ]; //Object.keys(items[0]);

      let csv = items.map((row) =>
        headerFilter
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          // .map((fieldName) => console.log(JSON.stringify(row[fieldName], replacer)) , "-------")
          .join(",")
      );
      csv.unshift(header.join(","));
      csv = csv.join("\r\n");
      // Download the CSV file
      const filename =
        "Outflow Transaction History" +
        Math.floor(Math.random() * (9000 - 1000) + 1000) +
        "data.csv";
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      if (link.download !== undefined) {
        link.setAttribute("href", URL.createObjectURL(blob));
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  useEffect(() => {
    if (filteredTotalRecords === 0) {
      dispatch(
        Toast({
          text: "No report Found",
          icon: "error",
        })
      );
    }
  }, [filteredTotalRecords]);

  return (
    <>
      <div className="button__downloadoutflow">
        {CheckPermission("", permissions) && (
          <FilterTransButton onClick={() => setFilterModal(true)}>
            {/* // <FilterTransButton> */}
            Filter
            <img
              src={
                require("../../../../assets/images/icons/filterArrowDown.svg")
                  .default
              }
              alt="filter"
            />
          </FilterTransButton>
        )}
        {CheckPermission("", permissions) && (
          <DownloadButton onClick={downloadCSVOutflow}>
            {/* <DownloadButton> */}
            Download
            <img
              src={
                require("../../../../assets/images/icons/nibss-download.svg")
                  .default
              }
              alt="download"
            />
          </DownloadButton>
        )}
      </div>
      {/* // Download Button */}

      <div className="mt-3 col-sm-12 col-md-12">
        {loading === true && nibssOutflowTemplate?.pageItems?.length ? (
          <TableLoaderView />
        ) : (
          <>
            {filteredData && handleFilterTrans ? (
              <Table
                style={{
                  overflow: "hidden",
                  overflowX: "scroll",
                }}
                className="tableNibss"
                columns={columns ? columns : null}
                defaultPageSize={"12"}
                dataSource={filteredTransaction}
                onRow={(r) => ({
                  onClick: () =>
                    history?.push({
                      pathname: `/core/nibss-management/manage-account/outflowtransactiondetails/${r.key}`,
                      state: [filteredData],
                    }),
                })}
                pagination={paginationFilter}
                onChange={handleFilterTableChange}
              />
            ) : (
              <Table
                style={{
                  overflow: "hidden",
                  overflowX: "scroll",
                }}
                className="tableNibss"
                columns={columns}
                defaultPageSize={"12"}
                dataSource={dataSource}
                onRow={(r) => ({
                  onClick: () =>
                    history?.push({
                      pathname: `/core/nibss-management/manage-account/outflowtransactiondetails/${r.key}`,
                      state: [pageItems],
                    }),
                })}
                pagination={pagination}
                onChange={handleTableChange}
              />
            )}
          </>
        )}
      </div>
    </>
  );
}

export default ManageOutflow;
