export const SET_TENANT = "SET_TENANT";
export const SET_VALID_TENANT = "SET_VALID_TENANT";
export const SHOW_OTP_2FA_MODAL = "SHOW_OTP_2FA_MODAL";
export const SHOW_OTP_MODAL = "SHOW_OTP_MODAL";
export const SET_VALIDITY_OF_OTP = "SET_VALIDITY_OF_OTP";
export const SET_LOGIN_RESPONSE = "SET_LOGIN_RESPONSE";
export const SET_ENTERED_USER_DATA = "SET_ENTERED_USER_DATA";
export const SET_RES_USER_DATA = "SET_RES_USER_DATA";
export const SET_RES_USER_DATA_PERMISSION = "SET_RES_USER_DATA_PERMISSION";
export const SET_TO_DISPLAY = "SET_TO_DISPLAY";
export const SHOW_SECURITY_MODAL = "SHOW_SECURITY_MODAL";
export const SET_IS_VALIDUSERSECURITY = "SET_IS_VALIDUSERSECURITY";
export const SET_BARCODE_DATA = "SET_BARCODE_DATA";
export const SET_AUTH_REQUEST_ID = "SET_AUTH_REQUEST_ID";
export const SET_OTP_ACTIVATION = "SET_OTP_ACTIVATION";
