import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { Select } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
// import { formatDate } from '../../../../../../util/FormatDate';

import {
  FormClass,
  FormElements,
  ActionButton,
  FormTitle,
  IconButton,
} from "../styles.jsx";
import Toast from "../../../../../../util/Toast.js";
import CurrencyFormat from "react-currency-format";
import { useSelector } from "react-redux";
import CheckPermission from "../../../../../../util/CheckPermission.js";
import { ActionAnchorButton } from "../../../styles.jsx";

const EditLoanAccountCharges = ({
  template,
  charges,
  setCharges,
  overdueCharges,
  setOverdueCharges,
  handleSubmit,
  toggleSchedule,
}) => {
  const { responseUserData: { permissions } } = useSelector((state) => state.preAuth);

  const [selectCharges, setSelectCharges] = useState("");

  const handleAddCharge = () => {
    if (selectCharges) {
      let newCharge = template.chargeOptions.filter(
        (item) => item.id === Number(selectCharges)
      )[0];
      let check = charges.filter((item) => item === newCharge);
      if (check.length === 0) setCharges((p) => [...p, newCharge]);
      else {
        Toast({
          text: "Already added!",
          icon: "error",
          timer: 2000,
        });
      }
    } else {
      Toast({
        text: "Please select a charge",
        icon: "error",
        timer: 2000,
      });
    }
  };

  // console.log(charges, 'charges');

  const handleChargeDelete = (id) => {
    setCharges(charges.filter((item) => item.id !== id));
  };

  const handleOverdueChargeDelete = (id) => {
    setOverdueCharges(overdueCharges.filter((item) => item.id !== id));
  };

  const handleOverdueAmountChange = (index, amount) => {
    let hhh = [...overdueCharges];
    hhh[Number(index)].amount = amount;
    setOverdueCharges(hhh);
  };

  const handleAmountChange = (index, amount) => {
    let hhh = [...charges];

    // if (type === "chargeCalculationType.flat") {
    //   hhh[Number(index)].amountOrPercentage = amount;
    //   // hhh[Number(index)].percentage = amount;
    // } else if (type === "chargeCalculationType.percent.of.amount") {
    //   hhh[Number(index)].amountOrPercentage = amount;
    //   // hhh[Number(index)].amount = amount;
    // }
    hhh[Number(index)].amountOrPercentage = amount;

    setCharges(hhh);
  };

  const handleDateChange = (index, date, type) => {
    let hhh = [...charges];
    hhh[Number(index)].dueDate = date;
    setCharges(hhh);
  };

  return (
    <>
      <FormClass>
        <FormElements style={{ width: "60%", margin: "0 auto" }}>
          <div className="form-group d-flex justify-content-between align-items-center">
            {/* <label className="text-muted">Product</label> */}
            <Select
              name="selectCharges"
              value={selectCharges}
              onChange={(e) => setSelectCharges(e.target.value)}
              size="md"
            >
              <option value="">Select Charge</option>
              {template !== undefined && template
                ? template.chargeOptions &&
                  template.chargeOptions.map((cur) => (
                    <option value={cur.id} key={cur.id}>
                      {cur.name}
                    </option>
                  ))
                : null}
            </Select>
            {CheckPermission('CREATE_LOANCHARGE', permissions) && (
              <ActionButton
                color="#005BFC"
                style={{ margin: "0px 0px 0 10px" }}
                onClick={handleAddCharge}
              >
                Add Charge
              </ActionButton>
            )}
          </div>
        </FormElements>
        <Table>
          <thead className="">
            <tr style={{ backgroundColor: "#EDEFF5" }}>
              <th>Name</th>
              <th>Type</th>
              <th>Amount/Percentage</th>
              <th>Collected on</th>
              <th>Date</th>
              {CheckPermission('DELETE_LOANCHARGE', permissions) && <th>Action</th>}
            </tr>
          </thead>
          <tbody>
            {charges && charges?.length > 0
              ? charges?.map(
                  (
                    {
                      id,
                      name,
                      chargeCalculationType,
                      amount,
                      // amount: amountValue,
                      feeOnMonthDay,
                      chargeTimeType,
                      percentage,
                      dueDate,
                      amountOrPercentage
                    },
                    index
                  ) => {
                    // let amount = percentage !== 0 ? percentage : amountValue;
                    return (
                      <tr key={id}>
                        <td>{name}</td>
                        <td>{chargeCalculationType.value}</td>
                        {chargeCalculationType?.code ===
                          "chargeCalculationType.percent.of.amount" && (
                          <td>
                            <CurrencyFormat
                              value={amountOrPercentage}
                              name="min_pricipal"
                              thousandSeparator={true}
                              className="amount-input"
                              disabled={!CheckPermission('UPDATE_LOANCHARGE', permissions)}
                              style={{
                                width: "60%",
                              }}
                              onValueChange={(Values) => {
                                const { value } = Values;
                                handleAmountChange(
                                  index,
                                  value,
                                  // chargeCalculationType?.code
                                );
                              }}
                            />
                          </td>
                        )}
                        {chargeCalculationType?.code ===
                          "chargeCalculationType.flat" && (
                          <td>
                            <CurrencyFormat
                              value={amountOrPercentage}
                              name="min_pricipal"
                              thousandSeparator={true}
                              className="amount-input"
                              disabled={!CheckPermission('UPDATE_LOANCHARGE', permissions)}
                              style={{
                                width: "60%",
                              }}
                              onValueChange={(Values) => {
                                const { value } = Values;
                                handleAmountChange(
                                  index,
                                  value,
                                  // chargeCalculationType?.code
                                );
                              }}
                            />
                          </td>
                        )}
                        <td>{chargeTimeType.value}</td>
                        <td>
                          <DatePicker
                            id="start-date"
                            selected={dueDate ? new Date(dueDate) : ""}
                            onChange={(date) =>
                              handleDateChange(
                                index,
                                date,
                                chargeTimeType?.value
                              )
                            }
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            placeholderText="dd/mmm/yyyy"
                            disabled={!CheckPermission('UPDATE_LOANCHARGE', permissions)}
                          />
                        </td>
                        {CheckPermission('DELETE_LOANCHARGE', permissions) && (
                          <td>
                            <IconButton
                              onClick={() => handleChargeDelete(id)}
                              id="deleteHoliday"
                              className="mr-1 mb-1"
                            >
                              <img
                                src={
                                  require("../../../../../../assets/images/icons/delete.svg")
                                    .default
                                }
                                alt="add"
                              />
                            </IconButton>
                          </td>
                        )}
                      </tr>
                    );
                  }
                )
              : null}
          </tbody>
        </Table>

        {/* Overdue Charges  */}
        {overdueCharges?.length > 0 ? (
          <>
            <FormTitle style={{ width: "100%", margin: "4rem auto" }}>
              <p className="text-info">Overdue charges</p>
              <div className="lineThrough"></div>
            </FormTitle>

            <Table>
              <thead className="">
                <tr style={{ backgroundColor: "#EDEFF5" }}>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Amount</th>
                  <th>Collected on</th>
                  {CheckPermission('DELETE_LOANCHARGE', permissions) && <th>Action</th>}
                </tr>
              </thead>

              {/* Display Overdue body */}
              <tbody>
                {overdueCharges?.length > 0
                  ? overdueCharges?.map(
                      (
                        {
                          id,
                          name,
                          chargeCalculationType,
                          amount,
                          chargeTimeType,
                        },
                        index
                      ) => (
                        <tr key={id}>
                          <td>{name}</td>
                          <td>{chargeCalculationType.value}</td>
                          <td>
                            <CurrencyFormat
                              value={amount}
                              name="min_pricipal"
                              thousandSeparator={true}
                              className="amount-input"
                              disabled={!CheckPermission('UPDATE_LOANCHARGE', permissions)}
                              style={{
                                width: "60%",
                              }}
                              onValueChange={(overdueValues) => {
                                const { value } = overdueValues;
                                handleOverdueAmountChange(index, value);
                              }}
                            />
                          </td>
                          <td>{chargeTimeType.value}</td>
                          {CheckPermission('DELETE_LOANCHARGE', permissions) && (
                            <td>
                              <IconButton
                                onClick={() => handleChargeDelete(id)}
                                id="deleteHoliday"
                                className="mr-1 mb-1"
                              >
                                <img
                                  src={
                                    require("../../../../../../assets/images/icons/delete.svg")
                                      .default
                                  }
                                  alt="add"
                                />
                              </IconButton>
                            </td>
                          )}
                        </tr>
                      )
                    )
                  : null}
              </tbody>
            </Table>
          </>
        ) : null}

        <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
          <ActionAnchorButton className="fs-14 p-0" onClick={toggleSchedule}>
            View Repayment Schedule &#8594;
          </ActionAnchorButton>
        </div>

        <div className="float-right pt-4">
          <ActionButton
            color="#005BFC"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={handleSubmit}
          >
            {/* {loading ? (
              <Spinner
                animation="border"
                color="#fff"
                className="mr-2 text-white"
                size="sm"
              />
            ) : ( */}
            <img
              src={
                require("../../../../../../assets/images/icons/save.svg")
                  .default
              }
              alt="submit icon"
            />
            {/* )} */}
            Submit
          </ActionButton>
        </div>
        <br />
        <br />
        <br />
        <br />
      </FormClass>
    </>
  );
};

export default EditLoanAccountCharges;
