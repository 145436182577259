export const formatToCurrency = (data) => {
  let formatedNum = "";
  if (Number.isNaN(data) || data === "" || data === undefined) {
    formatedNum = "";
  } else {
    formatedNum = new Intl.NumberFormat("en-IN").format(data);
  }
  return `${formatedNum}`;
};

export function numberWithCommas(x) {
  if (x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }
}

export const removeCommas = (value = "0") => Number(value?.toString()?.split(',').join(""));
