import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { Input, FormControl } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { formatDate } from "../../../../../../util/FormatDate.js";

import { FormClass, FormElements, ActionButton } from "../../styles.jsx";

import {
  downloadAccountStatement,
  resetDownloadAccountStatementState,
} from "../../../../../../actions/customerActions.js";

const DownloadAccountStatement = ({ onHide, accountNo }) => {
  const dispatch = useDispatch();

  const accountStatement = useSelector((state) => state.accountStatement);
  //STATE HANDLER FOR DEPOSIT INFO
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [accountNumber] = useState(accountNo);

  const openPdfNewTab = (file) => {
    setTimeout(() => {
      window.open(file);
    }, 3000);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let accountStatementData = {
      startDate: formatDate(startDate, false, "string:yyyy-MMMM-dd"),
      endDate: formatDate(endDate, false, "string:yyyy-MMMM-dd"),
      accountNumber,
      dateFormat: "dd MMM yyy",
      locale: "en",
    };
    const funcTorun = () => onHide();
    dispatch(downloadAccountStatement(accountStatementData, funcTorun));
  };

  useEffect(() => {
    const file = accountStatement?.accountStatement?.file;
    if (
      accountStatement?.accountStatement?.status === "Done" &&
      !!accountStatement?.accountStatement?.file
    ) {
      return [
        openPdfNewTab(file),
        dispatch(resetDownloadAccountStatementState()),
      ];
    }
  }, [accountStatement, dispatch]);

  return (
    <>
      <Modal.Header>
        <Modal.Title>Savings Account Statement</Modal.Title>
      </Modal.Header>
      <Modal.Body className="wc-modal">
        <div className="d-flex align-items-center justify-content-center flex-direction-coloumn">
          <FormClass style={{ width: "90%" }}>
            <FormElements style={{ width: "100%" }}>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Start Date</label>
                <FormControl style={{ width: "60%" }}>
                  <div className="datepicker-100-width">
                    <DatePicker
                      id="start-date"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="dd/mmm/yyyy"
                    />
                  </div>
                </FormControl>
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">End Date</label>
                <FormControl style={{ width: "60%" }}>
                  <div className="datepicker-100-width">
                    <DatePicker
                      id="end-date"
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="dd/mmm/yyyy"
                    />
                  </div>
                </FormControl>
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Account Number</label>
                <Input
                  style={{ width: "60%" }}
                  name="note"
                  placeholder="Enter account number"
                  value={accountNumber}
                  disabled
                  size="md"
                />
              </div>
            </FormElements>
          </FormClass>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-1">
          <ActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require("../../../../../../assets/images/icons/cancel.svg")
                  .default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton
            onClick={(e) => submitHandler(e)}
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
          >
            <img
              src={
                require("../../../../../../assets/images/icons/save.svg")
                  .default
              }
              alt="submit icon"
            />
            Submit
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default DownloadAccountStatement;
