import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { formatCurrency } from "../../../../../util/FormatAmount";
import { ActionAnchorButton } from "../../styles.jsx";
import { useSelector } from "react-redux";
import { formatDate } from "../../../../../util/FormatDate.js";

const PreviewRepaymentSchedule = ({ toggleSchedule, generateRepaymentSchedule }) => {
	const { repaymentSchedule } = useSelector(state => state.savingsAndLoan);
  let { periods = [] } = repaymentSchedule || {};

  const columns = [
    { title: "#", dataIndex: "index", key: "index" },
    { title: "Days", dataIndex: "days", key: "days" },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    { title: "Principal Due", dataIndex: "principalDue", key: "pricipalDue" },
    {
      title: "Balance Of Loan",
      dataIndex: "balanceOfLoan",
      key: "balanceOfLoan",
    },
    { title: "Interest", dataIndex: "interest", key: "interest" },
    { title: "Fees", dataIndex: "fees", key: "fees" },
    { title: "Penalties", dataIndex: "penalties", key: "penalties" },
    { title: "Due", dataIndex: "due", key: "due" },
    { title: "Outstanding", dataIndex: "outstanding", key: "outstanding" },
  ];

  let myRepaymentSchedule = [];

  let totalDays = 0;
  let totalPrincipalDue = 0;
  let totalInterest = 0;
  let totalFees = 0;
  let totalPenalties = 0;
  let totalDue = 0;
  let totalOutstanding = 0;

  periods.forEach((repayment, index) => {
    const {
      dueDate = [],
      obligationsMetOnDate = [],
      daysInPeriod = 0,
      principalDue = 0,
      totalDueForPeriod = 0,
      principalLoanBalanceOutstanding = 0,
      interestDue = 0,
      feeChargesDue = 0,
      penaltyChargesDue = 0,
      totalOutstandingForPeriod = 0,
      complete = false,
    } = repayment;

    totalDays += daysInPeriod;
    totalPrincipalDue += principalDue;
    totalInterest += interestDue;
    totalFees += feeChargesDue;
    totalPenalties += penaltyChargesDue;
    totalDue += totalDueForPeriod;
    totalOutstanding += totalOutstandingForPeriod;

    myRepaymentSchedule.push({
      key: index,
      index: (
        <div className="d-flex align-items-center">
          <div
            className={
              index === 0
                ? "circle-yellow"
                : complete
                ? "circle-green"
                : "circle-red"
            }
          ></div>
          <span className="ml-2">{index + 1}</span>
        </div>
      ),
      days: daysInPeriod,
      date: formatDate(dueDate),
      principalDue: formatCurrency(principalDue),
      balanceOfLoan: formatCurrency(principalLoanBalanceOutstanding),
      interest: formatCurrency(interestDue),
      fees: formatCurrency(feeChargesDue),
      penalties: formatCurrency(penaltyChargesDue),
      due: formatCurrency(totalDueForPeriod),
      outstanding: formatCurrency(totalOutstandingForPeriod),
    });
  });

	useEffect(() => {
		generateRepaymentSchedule();
	}, []);

  return (
    <>
      <div className="float-left">
        <ActionAnchorButton className="fs-14 p-0" onClick={toggleSchedule}>
					&#8592; go back
        </ActionAnchorButton>
      </div>
      <Table
        style={{ overflow: "scroll" }}
        columns={columns}
        dataSource={myRepaymentSchedule}
        pagination={{ defaultPageSize: 5 }}
        defaultPageSize={5}
      />
    </>
  );
};

export default PreviewRepaymentSchedule;
