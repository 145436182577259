import React, { useState } from "react";
import {
  ContentWrapper,
  ActionButtonsGroup,
  ActionButton,
  ModalText,
  IconButton,
  FormClass,
} from "./styles";
import { Modal } from "react-bootstrap";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import ModalContainer from "../../../components/ModalContainer";
const ManageFundsView = () => {
  const [modalState, toggleModal] = useState(false);
  const [isEdit, setIsEdit] = useState(null);
  const funds = [
    "Agrotech  Funds",
    "Testing  Funds",
    "Speechless  Funds",
    "Billions  Funds",
    "Automobile  Funds",
    "Grand  Funds",
    "Aerospace  Funds",
    "Excel  Funds",
    "Divine intervention  Funds",
    "Amadioah  Funds",
    "Primary  Funds",
    "Loan  Funds",
    "Healthcare  Funds",
    "Rangerover  Funds",
  ];
  return (
    <>
      <ModalContainer show={modalState}>
        <Modal.Header>
          <Modal.Title>Delete Fund</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
          <FormClass style={{ width: "95%" }}>
            <ModalText>Are you sure you want to delete this fund?</ModalText>
            <div className="float-right mt-4">
              <ActionButton color="#2C1DFF" onClick={() => toggleModal(false)}>
                <img
                  src={
                    require("../../../../../assets/images/icons/cancel.svg")
                      .default
                  }
                  alt="cancel icon"
                />
                Cancel
              </ActionButton>
              <ActionButton
                color="#2C1DFF"
                style={{ margin: "0px 0px 0 10px" }}
              >
                <img
                  src={
                    require("../../../../../assets/images/icons/delete.svg")
                      .default
                  }
                  alt="submit icon"
                />
                Delete
              </ActionButton>
            </div>
          </FormClass>
        </Modal.Body>
      </ModalContainer>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Manage Funds</h3>
            <p className="sub-title">Manage funds associated from loans</p>
            <p className="font-weight sub-title mt-2">
              <span className="text-info">
                <Link to="/core/configuration">Configuration</Link> /{" "}
                <Link to="/core/configuration/organization">Utilities</Link>
              </span>{" "}
              / Manage Funds
            </p>
          </div>
          <div className="col-sm-12 col-md-6 px-0">
            <ActionButtonsGroup>
              <InputGroup style={{ maxWidth: "450px" }}>
                <InputLeftElement
                  width="3.1rem"
                  height="50px"
                  pointerEvents="none"
                >
                  <img
                    src={
                      require("../../../../../assets/images/icons/search.svg")
                        .default
                    }
                    style={{ height: "30px" }}
                    alt="Icon"
                  />
                </InputLeftElement>
                <Input
                  style={{
                    background: "#ffffff",
                    height: "50px",
                  }}
                  type="text"
                  className="mb-3"
                  placeholder="Search for fund name"
                />
              </InputGroup>
              <ActionButton
                id="createHoliday"
                onClick={() => toggleModal(true)}
              >
                <img
                  src={
                    require("../../../../../assets/images/icons/add.svg")
                      .default
                  }
                  alt="add"
                />
                New Fund
              </ActionButton>
            </ActionButtonsGroup>
          </div>
        </div>
        <ContentWrapper>
          <div className="row h-100">
            <div className="col-lg-1 col-md-12" />
            <div className="col-md-12 col-lg-10 justify-content-center row">
              {funds.map((val, i) => (
                <div className="col-md-6 row currency-container" key={i}>
                  <div className="col-md-8 col-sm-12 mb-2 pl-md-2 p-0">
                    <Input
                      style={{ width: "90%" }}
                      size="md"
                      value={val}
                      className={isEdit === i && "active-input"}
                      disabled={isEdit !== i}
                      // onChange={newCurrencyHandler}
                    />
                  </div>
                  <div className="col-md-4 col-sm-12 pl-0 pr-2">
                    <IconButton
                      onClick={() =>
                        isEdit === i ? setIsEdit(null) : setIsEdit(i)
                      }
                      className="mr-2 mb-2"
                      // disabled=
                    >
                      <img
                        src={
                          require("../../../../../assets/images/icons/edit-profile.svg")
                            .default
                        }
                        alt="edit"
                      />
                    </IconButton>
                    <IconButton onClick={() => toggleModal(true)}>
                      <img
                        src={
                          require("../../../../../assets/images/icons/delete.svg")
                            .default
                        }
                        alt="delete"
                        className="icon-img"
                      />
                    </IconButton>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-lg-1 col-md-12" />
          </div>
        </ContentWrapper>
      </div>
    </>
  );
};

export default ManageFundsView;
