import React from "react";
import "./index.css";
import { useSelector } from "react-redux";

const BlockUI = () => {
  const accountingData = useSelector((state) => state.accountingData);
  let { isLoading } = accountingData;
  return (
    <>
      {isLoading ? (
        <div className="blockUi">
          <div className="loader-bg " data-text="Loading">
            <div className="loader-track">
              <div className="loader-fill"></div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default BlockUI;
