import React, { useState, useEffect } from "react";
import { InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import { Table } from "antd";

import {
  ContentWrapper,
  ActionButtonsGroup,
  ActionAnchorButton,
  AdvSearchActionButton,
} from "../styles";
import ModalContainer from "../../components/ModalContainer";
import { useSelector, useDispatch } from "react-redux";

import {
  getAllLoanAccounts,
  getAllLoanAccountsTemplate,
  searchLoanAccount,
} from "../../../../actions/accountActions";
import TableLoader from "./TableLoader";
import FilterModal from "./components/FilterModal";

import { useHistory } from "react-router";
import { LoanAccountsTable } from "./Loans";
import { RescheduleRequests } from "./RescheduleRequests";
import { Tabs, Tab } from "react-bootstrap";
import PreviewRepaymentSchedule from "../../Customer/CusomerLoanDetails/RescheduleLoan/PreviewRepaymentSchedule";
import backSvg from "../../../../assets/images/icons/back.svg";

const LoanAccount = () => {
  const dispatch = useDispatch();
  const accountData = useSelector((state) => state.accountData);
  let { loanAccounts, isLoading, filteredLoan, loanAccountTemplate } =
    accountData;
  const isTableLoading = useSelector(
    (state) => state.isTableLoading.isTableLoading
  );
  const {
    totalFilteredRecords,
    totalItems,
    numberOfPages,
    pageItems = [],
  } = loanAccounts || {};

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 13,
  });
  const [searchParams, setParam] = useState("");
  const [activePagination, setActivePagination] = useState();
  const [myData, setMyData] = useState([]);
  const [firstTime, setFirstTime] = useState(true);
  const [showRepaymentSchedule, setShowRepaymentSchedule] = useState(false);
  const [currentTab, setCurrentTab] = useState("loans");
  const [rescheduleId, setRescheduleId] = useState(null);
  useEffect(() => {
    dispatch(getAllLoanAccounts("limit=13&offset=0&sortOrder=DESC"));
    dispatch(getAllLoanAccountsTemplate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const history = useHistory();

  const handleClick = (tabValue) => {
    setCurrentTab(tabValue);
  };

  const toggleRepaymentSchedule = () => {
    setShowRepaymentSchedule(!showRepaymentSchedule);
  };

  // const handleNextData = (myCurrentPagination) => {
  //   let myOffsect = myCurrentPagination * 15;
  //   let dataToSend2 = `limit=15&offset=${myOffsect - 15}&sortOrder=DESC`;
  //   dispatch(getAllLoanAccounts(dataToSend2));
  // };

  useEffect(() => {
    if (!!loanAccounts && !!loanAccounts.length) {
      const formatedData = loanAccounts?.pageItems?.map(
        ({
          accountNo,
          clientId,
          status,
          customerName,
          productName,
          daysInArrears,
          externalId,
          interest,
          loanTenure,
          outstandingAmount,
          totalRepayment,
          overdueAmount,
          principal,
          totalWaived,
          totalWrittenOff,
        }) => {
          return {
            accountNo,
            clientId,
            status,
            customerName,
            daysInArrears,
            productName,
            externalId,
            loanTenure,
            interest: parseFloat(interest).toFixed(2),
            totalRepayment: parseFloat(totalRepayment).toFixed(2),
            totalWaived: parseFloat(totalWaived).toFixed(2),
            totalWrittenOff: parseFloat(totalWrittenOff).toFixed(2),
            outstandingAmount: parseFloat(outstandingAmount).toFixed(2),
            overdueAmount: parseFloat(overdueAmount).toFixed(2),
            principal: parseFloat(principal).toFixed(2),
          };
        }
      );
      setMyData(formatedData);
    } else if (!!pageItems.length) {
      const formatedData = pageItems?.map(
        ({
          accountNo,
          clientId,
          status,
          customerName,
          daysInArrears,
          externalId,
          productName,
          interest,
          loanTenure,
          outstandingAmount,
          totalWaived,
          totalWrittenOff,
          totalRepayment,
          overdueAmount,
          principal,
        }) => {
          return {
            accountNo,
            clientId,
            status,
            customerName,
            daysInArrears,
            productName,
            loanTenure,
            externalId,
            interest: parseFloat(interest).toFixed(2),
            totalRepayment: parseFloat(totalRepayment).toFixed(2),
            totalWaived: parseFloat(totalWaived).toFixed(2),
            totalWrittenOff: parseFloat(totalWrittenOff).toFixed(2),
            outstandingAmount: parseFloat(outstandingAmount).toFixed(2),
            overdueAmount: parseFloat(overdueAmount).toFixed(2),
            principal: parseFloat(principal).toFixed(2),
          };
        }
      );
      setMyData(formatedData);
    }
  }, [pageItems, loanAccounts]);

  const handleChange = (e) => {
    setParam(e.target.value);
  };
  const handleSubmit = () => {
    if (searchParams.length) {
      dispatch(searchLoanAccount(searchParams));
    }
  };

  const refresh = () => {
    dispatch(getAllLoanAccounts("limit=13&offset=0&sortOrder=DESC"));
    setActivePagination(1);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setActivePagination(pagination.current);
    if (!!loanAccounts && !!loanAccounts.length) {
      setPagination({
        current: activePagination,
        pageSize: 13,
        total: loanAccounts.length,
      });
    } else {
      let myOffsect = pagination.current * 13;
      let dataToSend2 = `limit=13&offset=${myOffsect - 13}&sortOrder=DESC`;
      dispatch(getAllLoanAccounts(dataToSend2));
    }
  };

  const [modalState, toggleModal] = useState(false);

  const handleOnHide = () => {
    toggleModal(false);
  };

  const [viewState, setViewState] = useState(false);

  return (
    <>
      <ModalContainer show={modalState}>
        {modalState && (
          <FilterModal
            onHide={handleOnHide}
            template={loanAccountTemplate}
            setViewState={setViewState}
          />
        )}
      </ModalContainer>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Loans</h3>
            <p className="sub-title">
              A complete overview of all loan accounts
            </p>
          </div>
          {currentTab === "loans" && (
            <div className="col-sm-12 col-md-6 px-0 pt-3">
              <ActionButtonsGroup>
                <div className="col-sm-12 col-md-4 p-0 d-flex">
                  <ActionAnchorButton
                    onClick={() => toggleModal(true)}
                    color="#2C1DFF"
                  >
                    Filter By
                  </ActionAnchorButton>
                  <ActionAnchorButton onClick={refresh} color="#2C1DFF">
                    Refresh
                  </ActionAnchorButton>
                </div>
                <div className="col-sm-12 col-md-8 pr-2">
                  <InputGroup>
                    <InputLeftElement
                      width="3.1rem"
                      height="38px"
                      pointerEvents="none"
                    >
                      <img
                        src={
                          require("../../../../assets/images/icons/search.svg")
                            .default
                        }
                        style={{ height: "30px" }}
                        alt="Icon"
                        className="icon-img"
                      />
                    </InputLeftElement>
                    <Input
                      style={{
                        background: "#ffffff",
                        height: "35px",
                      }}
                      type="text"
                      placeholder="Search by name/account no./external ID"
                      onChange={handleChange}
                    />
                    <AdvSearchActionButton onClick={handleSubmit}>
                      Search
                    </AdvSearchActionButton>
                  </InputGroup>
                </div>
              </ActionButtonsGroup>
            </div>
          )}
        </div>

        <ContentWrapper>
          <Tabs
            className="infoNav"
            defaultActiveKey={currentTab}
            // transition={false}
            id="noanim-tab-example"
            onSelect={(eventKey) => handleClick(eventKey)}
          >
            <Tab eventKey="loans" title="Loan accounts">
              <LoanAccountsTable
                myData={myData}
                isTableLoading={isTableLoading}
              />
            </Tab>
            <Tab eventKey="reschedule-requests" title="Reschedule requests">
              {showRepaymentSchedule
                ? (
                  <RescheduleRequests
                    setRescheduleId={setRescheduleId}
                    toggleRepaymentSchedule={toggleRepaymentSchedule}
                  />
                ) : (
                  <div className="">
                    <p onClick={toggleRepaymentSchedule} className="back-btn d-flex">
                      <img className="mr-2" src={backSvg} alt="" />
                      Back
                    </p>
                    <PreviewRepaymentSchedule id={rescheduleId} />
                  </div>
                )}
            </Tab>
          </Tabs>
        </ContentWrapper>
      </div>
    </>
  );
};

export default LoanAccount;
