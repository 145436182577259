//Users constants
export const ALL_USERS_REQUEST = "ALL_USERS_REQUEST";
export const ALL_USERS_SUCCESS = "ALL_USERS_SUCCESS";
export const ALL_USERS_FAIL = "ALL_USERS_FAIL";
export const FILTER_USERS_REQUEST = "FILTER_USERS_REQUEST";
export const CLEAR_FILTER = "CLEAR_FILTER";
export const CREATE_NEW_USER_REQUEST = "CREATE_NEW_USER_REQUEST";
export const CREATE_NEW_USER_SUCCESS = "CREATE_NEW_USER_SUCCESS";
export const CREATE_NEW_USER_FAIL = "CREATE_NEW_USER_FAIL";
export const CREATE_NEW_USER_RESET = "CREATE_NEW_USER_RESET";
export const ALL_USERS_TEMPLATE_REQUEST = "ALL_USERS_TEMPLATE_REQUEST";
export const ALL_USERS_TEMPLATE_SUCCESS = "ALL_USERS_TEMPLATE_SUCCESS";
export const ALL_USERS_TEMPLATE_FAIL = "ALL_USERS_TEMPLATE_FAIL";
export const ALL_OFFICE_STAFFS_REQUEST = "ALL_OFFICE_STAFFS_REQUEST";
export const ALL_OFFICE_STAFFS_SUCCESS = "ALL_OFFICE_STAFFS_SUCCESS";
export const ALL_OFFICE_STAFFS_RESET = "ALL_OFFICE_STAFFS_RESET";
export const ALL_OFFICE_STAFFS_FAIL = "ALL_OFFICE_STAFFS_FAIL";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const RESET_USER_PASSWORD_SUCCESS = "RESET_USER_PASSWORD_SUCCESS";
export const RESET_USER_PASSWORD_REQUEST = "RESET_USER_PASSWORD_REQUEST";
export const RESET_USER_PASSWORD_FAIL = "RESET_USER_PASSWORD_FAIL";
export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";
export const GENERATED_USER_KEY = "GENERATED_USER_KEY";
export const GET_USER_KEYS = "GET_USER_KEYS";

//Manage Offices constants
export const ALL_OFFICES_REQUEST = "ALL_OFFICES_REQUEST";
export const ALL_OFFICES_SUCCESS = "ALL_OFFICES_SUCCESS";
export const ALL_OFFICES_FAIL = "ALL_OFFICES_FAIL";
export const FILTER_OFFICES_REQUEST = "FILTER_OFFICES_REQUEST";
export const CLEAR_OFFICES_FILTER = "CLEAR_OFFICES_FILTER";
export const UPDATE_OFFICE_REQUEST = "UPDATE_OFFICE_REQUEST";
export const UPDATE_OFFICE_SUCCESS = "UPDATE_OFFICE_SUCCESS";
export const UPDATE_OFFICE_FAIL = "UPDATE_OFFICE_FAIL";
export const CREATE_NEW_OFFICE_REQUEST = "CREATE_NEW_OFFICE_REQUEST";
export const CREATE_NEW_OFFICE_SUCCESS = "CREATE_NEW_OFFICE_SUCCESS";
export const CREATE_NEW_OFFICE_FAIL = "CREATE_NEW_OFFICE_FAIL";
export const CREATE_NEW_OFFICE_RESET = "CREATE_NEW_OFFICE_RESET";

//Currency configuration constants
export const ALL_CURRENCY_REQUEST = "ALL_CURRENCY_REQUEST";
export const ALL_CURRENCY_SUCCESS = "ALL_CURRENCY_SUCCESS";
export const ALL_CURRENCY_FAIL = "ALL_CURRENCY_FAIL";
export const CREATE_NEW_CURRENCY_REQUEST = "CREATE_NEW_CURRENCY_REQUEST";
export const CREATE_NEW_CURRENCY_SUCCESS = "CREATE_NEW_CURRENCY_SUCCESS";
export const CREATE_NEW_CURRENCY_FAIL = "CREATE_NEW_CURRENCY_FAIL";

//Manage Employees constants
export const ALL_EMPLOYEES_REQUEST = "ALL_EMPLOYEES_REQUEST";
export const ALL_EMPLOYEES_SUCCESS = "ALL_EMPLOYEES_SUCCESS";
export const ALL_EMPLOYEES_FAIL = "ALL_EMPLOYEES_FAIL";
export const FILTER_EMPLOYEES_REQUEST = "FILTER_EMPLOYEES_REQUEST";
export const CLEAR_EMPLOYEES_FILTER = "CLEAR_FILTER";
export const CREATE_NEW_EMPLOYEE_REQUEST = "CREATE_NEW_EMPLOYEE_REQUEST";
export const CREATE_NEW_EMPLOYEE_SUCCESS = "CREATE_NEW_EMPLOYEE_SUCCESS";
export const CREATE_NEW_EMPLOYEE_FAIL = "CREATE_NEW_EMPLOYEE_FAIL";
export const CREATE_NEW_EMPLOYEE_RESET = "CREATE_NEW_EMPLOYEE_RESET";
export const UPDATE_EMPLOYEE_REQUEST = "UPDATE_EMPLOYEE_REQUEST";
export const UPDATE_EMPLOYEE_SUCCESS = "UPDATE_EMPLOYEE_SUCCESS";
export const UPDATE_EMPLOYEE_FAIL = "UPDATE_EMPLOYEE_FAIL";
export const FETCH_SINGLE_EMPLOYEE_REQUEST = "FETCH_SINGLE_EMPLOYEE_REQUEST";
export const FETCH_SINGLE_EMPLOYEE_SUCCESS = "FETCH_SINGLE_EMPLOYEE_SUCCESS";
export const FETCH_SINGLE_EMPLOYEE_FAIL = "FETCH_SINGLE_EMPLOYEE_FAIL";
export const FETCH_SINGLE_EMPLOYEE_RESET = "FETCH_SINGLE_EMPLOYEE_RESET";
export const UPDATE_EMPLOYEE_IMAGE_REQUEST = "UPDATE_EMPLOYEE_IMAGE_REQUEST";
export const UPDATE_EMPLOYEE_IMAGE_SUCCESS = "UPDATE_EMPLOYEE_IMAGE_SUCCESS";
export const UPDATE_EMPLOYEE_IMAGE_FAIL = "UPDATE_EMPLOYEE_IMAGE_FAIL";
export const FETCH_EMPLOYEE_IMAGE_REQUEST = "FETCH_EMPLOYEE_IMAGE_REQUEST";
export const FETCH_EMPLOYEE_IMAGE_SUCCESS = "FETCH_EMPLOYEE_IMAGE_SUCCESS";
export const FETCH_EMPLOYEE_IMAGE_FAIL = "FETCH_EMPLOYEE_IMAGE_FAIL";
export const RESET_EMPLOYEE_IMAGE = "RESET_EMPLOYEE_IMAGE";

//Entity Data Table Checks constants
export const ALL_ENTITY_DATA_TABLE_REQUEST = "ALL_ENTITY_DATA_TABLE_REQUEST";
export const ALL_ENTITY_DATA_TABLE_SUCCESS = "ALL_ENTITY_DATA_TABLE_SUCCESS";
export const ALL_ENTITY_DATA_TABLE_FAIL = "ALL_ENTITY_DATA_TABLE_FAIL";
export const FILTER_ENTITY_DATA_TABLE_REQUEST =
  "FILTER_ENTITY_DATA_TABLE_REQUEST";
export const CLEAR_ENTITY_DATA_TABLE_FILTER = "CLEAR_ENTITY_DATA_TABLE_FILTER";
export const CREATE_NEW_ENTITY_DATA_TABLE_REQUEST =
  "CREATE_NEW_ENTITY_DATA_TABLE_REQUEST";
export const CREATE_ENTITY_DATA_TABLE_SUCCESS =
  "CREATE_ENTITY_DATA_TABLE_SUCCESS";
export const CREATE_ENTITY_DATA_TABLE_FAIL = "CREATE_ENTITY_DATA_TABLE_FAIL";
export const CREATE_ENTITY_DATA_TABLE_RESET = "CREATE_ENTITY_DATA_TABLE_RESET";
export const DELETE_ENTITY_DATA_TABLE_REQUEST =
  "DELETE_ENTITY_DATA_TABLE_REQUEST";
export const DELETE_ENTITY_DATA_TABLE_SUCCESS =
  "DELETE_ENTITY_DATA_TABLE_SUCCESS";
export const DELETE_ENTITY_DATA_TABLE_FAIL = "DELETE_ENTITY_DATA_TABLE_FAIL";
export const ALL_ENTITY_DATA_TABLE_TEMPLATE_REQUEST =
  "ALL_ENTITY_DATA_TABLE_TEMPLATE_REQUEST";
export const ALL_ENTITY_DATA_TABLE_TEMPLATE_SUCCESS =
  "ALL_ENTITY_DATA_TABLE_TEMPLATE_SUCCESS";
export const ALL_ENTITY_DATA_TABLE_TEMPLATE_FAIL =
  "ALL_ENTITY_DATA_TABLE_TEMPLATE_FAIL";

export const ALL_HOLIDAYS_REQUEST = "ALL_HOLIDAYS_REQUEST";
export const ALL_HOLIDAYS_SUCCESS = "ALL_HOLIDAYS_SUCCESS";
export const ALL_HOLIDAYS_FAIL = "ALL_HOLIDAYS_FAIL";
export const FILTER_HOLIDAYS_REQUEST = "FILTER_HOLIDAYS_REQUEST";
export const CLEAR_HOLIDAYS_FILTER = "CLEAR_FILTER";
export const ALL_HOLIDAYS_TEMPLATE_REQUEST = "ALL_HOLIDAYS_TEMPLATE_REQUEST";
export const ALL_HOLIDAYS_TEMPLATE_SUCCESS = "ALL_HOLIDAYS_TEMPLATE_SUCCESS";
export const ALL_HOLIDAYS_TEMPLATE_FAIL = "ALL_HOLIDAYS_TEMPLATE_FAIL";
export const CREATE_NEW_HOLIDAY_REQUEST = "CREATE_NEW_HOLIDAY_REQUEST";
export const CREATE_NEW_HOLIDAY_SUCCESS = "CREATE_NEW_HOLIDAY_SUCCESS";
export const CREATE_NEW_HOLIDAY_FAIL = "CREATE_NEW_HOLIDAY_FAIL";
export const CREATE_NEW_HOLIDAY_RESET = "CREATE_NEW_HOLIDAY_RESET";
export const DELETE_HOLIDAY_REQUEST = "DELETE_HOLIDAY_REQUEST";
export const DELETE_HOLIDAY_SUCCESS = "DELETE_HOLIDAY_SUCCESS";
export const DELETE_HOLIDAY_FAIL = "DELETE_HOLIDAY_FAIL";
export const ACTIVATE_HOLIDAY_REQUEST = "ACTIVATE_HOLIDAY_REQUEST";
export const ACTIVATE_HOLIDAY_SUCCESS = "ACTIVATE_HOLIDAY_SUCCESS";
export const ACTIVATE_HOLIDAY_FAIL = "ACTIVATE_HOLIDAY_FAIL";
export const UPDATE_HOLIDAY_REQUEST = "UPDATE_HOLIDAY_REQUEST";
export const UPDATE_HOLIDAY_SUCCESS = "UPDATE_HOLIDAY_SUCCESS";
export const UPDATE_HOLIDAY_FAIL = "UPDATE_HOLIDAY_FAIL";

//Working days constant
export const ALL_WORKING_DAYS_REQUEST = "ALL_WORKING_DAYS_REQUEST";
export const ALL_WORKING_DAYS_SUCCESS = "ALL_WORKING_DAYS_SUCCESS";
export const ALL_WORKING_DAYS_FAIL = "ALL_WORKING_DAYS_FAIL";
export const CREATE_NEW_WORKING_DAYS_REQUEST =
  "CREATE_NEW_WORKING_DAYS_REQUEST";
export const CREATE_NEW_WORKING_DAYS_SUCCESS =
  "CREATE_NEW_WORKING_DAYS_SUCCESS";
export const CREATE_NEW_WORKING_DAYS_FAIL = "CREATE_NEW_WORKING_DAYS_FAIL";
export const CREATE_NEW_WORKING_DAYS_RESET = "CREATE_NEW_WORKING_DAYS_RESET";

// Standing instructions constant
export const ALL_STANDING_INSTRUCTION_TEMPLATE_REQUEST =
  "ALL_STANDING_INSTRUCTION_TEMPLATE_REQUEST";
export const ALL_STANDING_INSTRUCTION_TEMPLATE_SUCCESS =
  "ALL_STANDING_INSTRUCTION_TEMPLATE_SUCCESS";
export const ALL_STANDING_INSTRUCTION_TEMPLATE_FAIL =
  "ALL_STANDING_INSTRUCTION_TEMPLATE_FAIL";
export const ALL_STANDING_INSTRUCTION_REQUEST =
  "ALL_STANDING_INSTRUCTION_REQUEST";
export const ALL_STANDING_INSTRUCTION_SUCCESS =
  "ALL_STANDING_INSTRUCTION_SUCCESS";
export const ALL_STANDING_INSTRUCTION_FAIL = "ALL_STANDING_INSTRUCTION_FAIL";

//Payment type constant
export const ALL_PAYMENT_TYPE_REQUEST = "ALL_PAYMENT_TYPE_REQUEST";
export const ALL_PAYMENT_TYPE_SUCCESS = "ALL_PAYMENT_TYPE_SUCCESS";
export const ALL_PAYMENT_TYPE_FAIL = "ALL_PAYMENT_TYPE_FAIL";
export const FILTER_PAYMENT_TYPE_REQUEST = "FILTER_PAYMENT_TYPE_REQUEST";
export const CLEAR_PAYMENT_TYPE_FILTER = "CLEAR_PAYMENT_TYPE_FILTER";
export const CREATE_NEW_PAYMENT_TYPE_REQUEST =
  "CREATE_NEW_PAYMENT_TYPE_REQUEST";
export const CREATE_NEW_PAYMENT_TYPE_SUCCESS =
  "CREATE_NEW_PAYMENT_TYPE_SUCCESS";
export const CREATE_NEW_PAYMENT_TYPE_FAIL = "CREATE_NEW_PAYMENT_TYPE_FAIL";
export const UPDATE_PAYMENT_TYPE_REQUEST = "UPDATE_PAYMENT_TYPE_REQUEST";
export const UPDATE_PAYMENT_TYPE_SUCCESS = "UPDATE_PAYMENT_TYPE_SUCCESS";
export const UPDATE_PAYMENT_TYPE_FAIL = "UPDATE_PAYMENT_TYPE_FAIL";
export const DELETE_PAYMENT_TYPE_FAIL = "DELETE_PAYMENT_TYPE_FAIL";
export const DELETE_PAYMENT_TYPE_SUCCESS = "DELETE_PAYMENT_TYPE_SUCCESS";
export const DELETE_PAYMENT_TYPE_REQUEST = "DELETE_PAYMENT_TYPE_REQUEST";

//PRODUCTS SECTION

//savings products
export const ALL_SAVINGS_PRODUCTS_REQUEST = "ALL_SAVINGS_PRODUCTS_REQUEST";
export const ALL_SAVINGS_PRODUCTS_SUCCESS = "ALL_SAVINGS_PRODUCTS_SUCCESS";
export const ALL_SAVINGS_PRODUCTS_FAIL = "ALL_SAVINGS_PRODUCTS_FAIL";
export const FILTER_SAVINGS_PRODUCTS_REQUEST =
  "FILTER_SAVINGS_PRODUCTS_REQUEST";
export const CLEAR_SAVINGS_PRODUCTS_FILTER = "CLEAR_SAVINGS_PRODUCTS_FILTER";
export const ALL_SAVINGS_PRODUCTS_TEMPLATE_REQUEST =
  "ALL_SAVINGS_PRODUCTS_TEMPLATE_REQUEST";
export const ALL_SAVINGS_PRODUCTS_TEMPLATE_SUCCESS =
  "ALL_SAVINGS_PRODUCTS_TEMPLATE_SUCCESS";
export const ALL_SAVINGS_PRODUCTS_TEMPLATE_FAIL =
  "ALL_SAVINGS_PRODUCTS_TEMPLATE_FAIL";
export const CREATE_NEW_SAVINGS_PRODUCT_REQUEST =
  "CREATE_NEW_SAVINGS_PRODUCT_REQUEST";
export const CREATE_NEW_SAVINGS_PRODUCT_SUCCESS =
  "CREATE_NEW_SAVINGS_PRODUCT_SUCCESS";
export const CREATE_NEW_SAVINGS_PRODUCT_FAIL =
  "CREATE_NEW_SAVINGS_PRODUCT_FAIL";
export const CREATE_NEW_SAVINGS_PRODUCT_RESET =
  "CREATE_NEW_SAVINGS_PRODUCT_RESET";
export const SAVINGS_PRODUCT_FAIL = "SAVINGS_PRODUCT_FAIL";
export const SAVINGS_PRODUCT_SUCCESS = "SAVINGS_PRODUCT_SUCCESS";
export const SAVINGS_PRODUCT_REQUEST = "SAVINGS_PRODUCT_REQUEST";
export const SAVINGS_PRODUCT_RESET = "SAVINGS_PRODUCT_RESET";
export const UPDATE_SAVINGS_PRODUCT_REQUEST = "UPDATE_SAVINGS_PRODUCT_REQUEST";
export const UPDATE_SAVINGS_PRODUCT_SUCCESS = "UPDATE_SAVINGS_PRODUCT_SUCCESS";
export const UPDATE_SAVINGS_PRODUCT_FAIL = "UPDATE_SAVINGS_PRODUCT_FAIL";

//loan products
export const ALL_LOAN_PRODUCT_REQUEST = "ALL_LOAN_PRODUCT_REQUEST";
export const ALL_LOAN_PRODUCT_SUCCESS = "ALL_LOAN_PRODUCT_SUCCESS";
export const ALL_LOAN_PRODUCT_FAIL = "ALL_LOAN_PRODUCT_FAIL";
export const FILTER_LOAN_PRODUCT_REQUEST = "FILTER_LOAN_PRODUCT_REQUEST";
export const CLEAR_LOAN_PRODUCT_FILTER = "CLEAR_LOAN_PRODUCT_FILTER";
export const ALL_LOAN_PRODUCT_TEMPLATE_REQUEST =
  "ALL_LOAN_PRODUCT_TEMPLATE_REQUEST";
export const ALL_LOAN_PRODUCT_TEMPLATE_SUCCESS =
  "ALL_LOAN_PRODUCT_TEMPLATE_SUCCESS";
export const ALL_LOAN_PRODUCT_TEMPLATE_FAIL = "ALL_LOAN_PRODUCT_TEMPLATE_FAIL";
export const CREATE_NEW_LOAN_PRODUCT_REQUEST =
  "CREATE_NEW_LOAN_PRODUCT_REQUEST";
export const CREATE_NEW_LOAN_PRODUCT_SUCCESS =
  "CREATE_NEW_LOAN_PRODUCT_SUCCESS";
export const CREATE_NEW_LOAN_PRODUCT_FAIL = "CREATE_NEW_LOAN_PRODUCT_FAIL";
export const CREATE_NEW_LOAN_PRODUCT_RESET = "CREATE_NEW_LOAN_PRODUCT_RESET";
export const LOAN_PRODUCT_FAIL = "LOAN_PRODUCT_FAIL";
export const LOAN_PRODUCT_SUCCESS = "LOAN_PRODUCT_SUCCESS";
export const LOAN_PRODUCT_REQUEST = "LOAN_PRODUCT_REQUEST";
export const LOAN_PRODUCT_RESET = "LOAN_PRODUCT_RESET";
export const UPDATE_LOAN_PRODUCT_REQUEST = "UPDATE_LOAN_PRODUCT_REQUEST";
export const UPDATE_LOAN_PRODUCT_SUCCESS = "UPDATE_LOAN_PRODUCT_SUCCESS";
export const UPDATE_LOAN_PRODUCT_FAIL = "UPDATE_LOAN_PRODUCT_FAIL";

// charges constant

export const ALL_CHARGES_REQUEST = "ALL_CHARGES_REQUEST";
export const ALL_CHARGES_SUCCESS = "ALL_CHARGES_SUCCESS";
export const ALL_CHARGES_FAIL = "ALL_CHARGES_FAIL";
export const FILTER_CHARGES_REQUEST = "FILTER_CHARGES_REQUEST";
export const CLEAR_CHARGES_FILTER = "CLEAR_CHARGES_FILTER";
export const ALL_CHARGES_TEMPLATE_REQUEST = "ALL_CHARGES_TEMPLATE_REQUEST";
export const ALL_CHARGES_TEMPLATE_SUCCESS = "ALL_CHARGES_TEMPLATE_SUCCESS";
export const ALL_CHARGES_TEMPLATE_FAIL = "ALL_CHARGES_TEMPLATE_FAIL";
export const CREATE_NEW_CHARGE_REQUEST = "CREATE_NEW_CHARGE_REQUEST";
export const CREATE_NEW_CHARGE_SUCCESS = "CREATE_NEW_CHARGE_SUCCESS";
export const CREATE_NEW_CHARGE_FAIL = "CREATE_NEW_CHARGE_FAIL";
export const CREATE_NEW_CHARGE_RESET = "CREATE_NEW_CHARGE_RESET";
export const DELETE_CHARGE_REQUEST = "DELETE_CHARGE_REQUEST";
export const DELETE_CHARGE_SUCCESS = "DELETE_CHARGE_SUCCESS";
export const DELETE_CHARGE_FAIL = "DELETE_CHARGE_FAIL";
export const CHARGE_FAIL = "CHARGE_FAIL";
export const CHARGE_SUCCESS = "CHARGE_SUCCESS";
export const CHARGE_REQUEST = "CHARGE_REQUEST";
export const CHARGE_RESET = "CHARGE_RESET";
export const UPDATE_CHARGE_REQUEST = "UPDATE_CHARGE_REQUEST";
export const UPDATE_CHARGE_SUCCESS = "UPDATE_CHARGE_SUCCESS";
export const UPDATE_CHARGE_FAIL = "UPDATE_CHARGE_FAIL";

//manage tax component constant

export const ALL_TAX_COMPONENTS_REQUEST = "ALL_TAX_COMPONENTS_REQUEST";
export const ALL_TAX_COMPONENTS_SUCCESS = "ALL_TAX_COMPONENTS_SUCCESS";
export const ALL_TAX_COMPONENTS_FAIL = "ALL_TAX_COMPONENTS_FAIL";
export const FILTER_TAX_COMPONENTS_REQUEST = "FILTER_TAX_COMPONENTS_REQUEST";
export const CLEAR_TAX_COMPONENTS_FILTER = "CLEAR_TAX_COMPONENTS_FILTER";
export const UPDATE_TAX_COMPONENT_REQUEST = "UPDATE_TAX_COMPONENT_REQUEST";
export const UPDATE_TAX_COMPONENT_SUCCESS = "UPDATE_TAX_COMPONENT_SUCCESS";
export const UPDATE_TAX_COMPONENT_FAIL = "UPDATE_TAX_COMPONENT_FAIL";
export const CREATE_NEW_TAX_COMPONENT_REQUEST =
  "CREATE_NEW_TAX_COMPONENT_REQUEST";
export const CREATE_NEW_TAX_COMPONENT_SUCCESS =
  "CREATE_NEW_TAX_COMPONENT_SUCCESS";
export const CREATE_NEW_TAX_COMPONENT_FAIL = "CREATE_NEW_TAX_COMPONENT_FAIL";
export const CREATE_NEW_TAX_COMPONENT_RESET = "CREATE_NEW_TAX_COMPONENT_RESET";
export const ALL_TAX_COMPONENTS_TEMPLATE_REQUEST =
  "ALL_TAX_COMPONENTS_TEMPLATE_REQUEST";
export const ALL_TAX_COMPONENTS_TEMPLATE_SUCCESS =
  "ALL_TAX_COMPONENTS_TEMPLATE_SUCCESS";
export const ALL_TAX_COMPONENTS_TEMPLATE_FAIL =
  "ALL_TAX_COMPONENTS_TEMPLATE_FAIL";

//manage tax groups constant

export const ALL_TAX_GROUPS_REQUEST = "ALL_TAX_GROUPS_REQUEST";
export const ALL_TAX_GROUPS_SUCCESS = "ALL_TAX_GROUPS_SUCCESS";
export const ALL_TAX_GROUPS_FAIL = "ALL_TAX_GROUPS_FAIL";
export const FILTER_TAX_GROUPS_REQUEST = "FILTER_TAX_GROUPS_REQUEST";
export const CLEAR_TAX_GROUPS_FILTER = "CLEAR_TAX_GROUPS_FILTER";
export const UPDATE_TAX_GROUP_REQUEST = "UPDATE_TAX_GROUP_REQUEST";
export const UPDATE_TAX_GROUP_SUCCESS = "UPDATE_TAX_GROUP_SUCCESS";
export const UPDATE_TAX_GROUP_FAIL = "UPDATE_TAX_GROUP_FAIL";
export const CREATE_NEW_TAX_GROUP_REQUEST = "CREATE_NEW_TAX_GROUP_REQUEST";
export const CREATE_NEW_TAX_GROUP_SUCCESS = "CREATE_NEW_TAX_GROUP_SUCCESS";
export const CREATE_NEW_TAX_GROUP_FAIL = "CREATE_NEW_TAX_GROUP_FAIL";
export const CREATE_NEW_TAX_GROUP_RESET = "CREATE_NEW_TAX_GROUP_RESET";
export const ALL_TAX_GROUPS_TEMPLATE_REQUEST =
  "ALL_TAX_GROUPS_TEMPLATE_REQUEST";
export const ALL_TAX_GROUPS_TEMPLATE_SUCCESS =
  "ALL_TAX_GROUPS_TEMPLATE_SUCCESS";
export const ALL_TAX_GROUPS_TEMPLATE_FAIL = "ALL_TAX_GROUPS_TEMPLATE_FAIL";

// AUDIT TRAILS CONSTANTS
export const ALL_AUDIT_REQUEST = "ALL_AUDIT_REQUEST";
export const ALL_AUDIT_SUCCESS = "ALL_AUDIT_SUCCESS";
export const ALL_AUDIT_FAIL = "ALL_AUDIT_FAIL";
export const ALL_AUDIT_TEMPLATE_FAIL = "ALL_AUDIT_TEMPLATE_FAIL";
export const ALL_AUDIT_TEMPLATE_REQUEST = "ALL_AUDIT_TEMPLATE_REQUEST";
export const ALL_AUDIT_TEMPLATE_SUCCESS = "ALL_AUDIT_TEMPLATE_SUCCESS";
export const GET_SINGLE_AUDIT_REQUEST = "GET_SINGLE_AUDIT_REQUEST";
export const GET_SINGLE_AUDIT_SUCCESS = "GET_SINGLE_AUDIT_SUCCESS";
export const GET_SINGLE_AUDIT_FAIL = "GET_SINGLE_AUDIT_FAIL";

// User Audit constants
export const ALL_USER_AUDIT_REQUEST = "ALL_USER_AUDIT_REQUEST";
export const ALL_USER_AUDIT_SUCCESS = "ALL_USER_AUDIT_SUCCESS";
export const ALL_USER_AUDIT_FAIL = "ALL_USER_AUDIT_FAIL";
export const FILTER_AUDIT_USERS_REQUEST = "FILTER_AUDIT_USERS_REQUEST";
export const CLEAR_AUDIT_USERS_FILTER = "CLEAR_AUDIT_USERS_FILTER";

//Hooks constant
export const ALL_HOOK_REQUEST = "ALL_HOOK_REQUEST";
export const ALL_HOOK_SUCCESS = "ALL_HOOK_SUCCESS";
export const ALL_HOOK_FAIL = "ALL_HOOK_FAIL";
export const FILTER_HOOK_REQUEST = "FILTER_HOOK_REQUEST";
export const CLEAR_HOOK_FILTER = "CLEAR_HOOK_FILTER";
export const CREATE_NEW_HOOK_REQUEST = "CREATE_NEW_HOOK_REQUEST";
export const CREATE_NEW_HOOK_SUCCESS = "CREATE_NEW_HOOK_SUCCESS";
export const CREATE_NEW_HOOK_FAIL = "CREATE_NEW_HOOK_FAIL";
export const UPDATE_HOOK_REQUEST = "UPDATE_HOOK_REQUEST";
export const UPDATE_HOOK_SUCCESS = "UPDATE_HOOK_SUCCESS";
export const UPDATE_HOOK_FAIL = "UPDATE_HOOK_FAIL";
export const DELETE_HOOK_FAIL = "DELETE_HOOK_FAIL";
export const DELETE_HOOK_SUCCESS = "DELETE_HOOK_SUCCESS";
export const DELETE_HOOK_REQUEST = "DELETE_HOOK_REQUEST";
export const ALL_HOOK_TEMPLATE_REQUEST = "ALL_HOOK_TEMPLATE_REQUEST";
export const ALL_HOOK_TEMPLATE_SUCCESS = "ALL_HOOK_TEMPLATE_SUCCESS";
export const ALL_HOOK_TEMPLATE_FAIL = "ALL_HOOK_TEMPLATE_FAIL";

// --- EXTERNAL SERVICES CONSTANTS

export const UPDATE_S3_REQUEST = "UPDATE_S3_REQUEST";
export const UPDATE_S3_SUCCESS = "UPDATE_S3_SUCCESS";
export const UPDATE_S3_FAIL = "UPDATE_S3_FAIL";
export const ALL_S3_REQUEST = "ALL_S3_REQUEST";
export const ALL_S3_SUCCESS = "ALL_S3_SUCCESS";
export const ALL_S3_FAIL = "ALL_S3_FAIL";
export const UPDATE_SMTP_REQUEST = "UPDATE_SMTP_REQUEST";
export const UPDATE_SMTP_SUCCESS = "UPDATE_SMTP_SUCCESS";
export const UPDATE_SMTP_FAIL = "UPDATE_SMTP_FAIL";
export const ALL_SMTP_REQUEST = "ALL_SMTP_REQUEST";
export const ALL_SMTP_SUCCESS = "ALL_SMTP_SUCCESS";
export const ALL_SMTP_FAIL = "ALL_SMTP_FAIL";
export const UPDATE_SMS_REQUEST = "UPDATE_SMS_REQUEST";
export const UPDATE_SMS_SUCCESS = "UPDATE_SMS_SUCCESS";
export const UPDATE_SMS_FAIL = "UPDATE_SMS_FAIL";
export const ALL_SMS_REQUEST = "ALL_SMS_REQUEST";
export const ALL_SMS_SUCCESS = "ALL_SMS_SUCCESS";
export const ALL_SMS_FAIL = "ALL_SMS_FAIL";
export const UPDATE_NOTIFICATION_REQUEST = "UPDATE_NOTIFICATION_REQUEST";
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";
export const UPDATE_NOTIFICATION_FAIL = "UPDATE_NOTIFICATION_FAIL";
export const ALL_NOTIFICATION_REQUEST = "ALL_NOTIFICATION_REQUEST";
export const ALL_NOTIFICATION_SUCCESS = "ALL_NOTIFICATION_SUCCESS";
export const ALL_NOTIFICATION_FAIL = "ALL_NOTIFICATION_FAIL";

//configuration constants

export const ALL_CONFIGURATION_REQUEST = "ALL_CONFIGURATION_REQUEST";
export const ALL_CONFIGURATION_SUCCESS = "ALL_CONFIGURATION_SUCCESS";
export const ALL_CONFIGURATION_FAIL = "ALL_CONFIGURATION_FAIL";
export const FILTER_CONFIGURATION_REQUEST = "FILTER_CONFIGURATION_REQUEST";
export const CLEAR_CONFIGURATION_FILTER = "CLEAR_CONFIGURATION_FILTER";
export const UPDATE_CONFIGURATION_REQUEST = "UPDATE_CONFIGURATION_REQUEST";
export const UPDATE_CONFIGURATION_SUCCESS = "UPDATE_CONFIGURATION_SUCCESS";
export const UPDATE_CONFIGURATION_FAIL = "UPDATE_TAX_COMPONENT_FAIL";
export const ENABLE_CONFIGURATION_REQUEST = "ENABLE_CONFIGURATION_REQUEST";
export const ENABLE_CONFIGURATION_SUCCESS = "ENABLE_CONFIGURATION_SUCCESS";
export const ENABLE_CONFIGURATION_FAIL = "ENABLE_CONFIGURATION_FAIL";
export const DISABLE_CONFIGURATION_REQUEST = "DISABLE_CONFIGURATION_REQUEST";
export const DISABLE_CONFIGURATION_SUCCESS = "DISABLE_CONFIGURATION_SUCCESS";
export const DISABLE_CONFIGURATION_FAIL = "DISABLE_CONFIGURATION_FAIL";

// --- MARKER CHECKER TASKS CONSTANTS

export const UPDATE_MCTASK_REQUEST = "UPDATE_MCTASK_REQUEST";
export const UPDATE_MCTASK_SUCCESS = "UPDATE_MCTASK_SUCCESS";
export const UPDATE_MCTASK_FAIL = "UPDATE_MCTASK_FAIL";
export const ALL_MCTASKS_REQUEST = "ALL_MCTASKS_REQUEST";
export const ALL_MCTASKS_SUCCESS = "ALL_MCTASKS_SUCCESS";
export const ALL_MCTASKS_FAIL = "ALL_TASKS_FAIL";

//ROLES & PERMISSIONS CONSTANT
export const ALL_ROLES_REQUEST = "ALL_ROLES_REQUEST";
export const ALL_ROLES_SUCCESS = "ALL_ROLES_SUCCESS";
export const ALL_ROLES_FAIL = "ALL_ROLES_FAIL";
export const ROLE_REQUEST = "ROLE_REQUEST";
export const ROLE_SUCCESS = "ROLE_SUCCESS";
export const ROLE_FAIL = "ROLE_FAIL";
export const FILTER_ROLES_REQUEST = "FILTER_ROLES_REQUEST";
export const CLEAR_ROLES_FILTER = "CLEAR_ROLES_FILTER";
export const CREATE_NEW_ROLE_REQUEST = "CREATE_NEW_ROLE_REQUEST";
export const CREATE_NEW_ROLE_SUCCESS = "CREATE_NEW_ROLE_SUCCESS";
export const CREATE_NEW_ROLE_FAIL = "CREATE_NEW_ROLE_FAIL";
export const UPDATE_ROLE_REQUEST = "UPDATE_ROLE_REQUEST";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const UPDATE_ROLE_FAIL = "UPDATE_ROLE_FAIL";
export const DELETE_ROLE_FAIL = "DELETE_ROLE_FAIL";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_REQUEST = "DELETE_HOOK_REQUEST";
export const ABLE_ROLE_FAIL = "ABLE_ROLE_FAIL";
export const ABLE_ROLE_REQUEST = "ABLE_ROLE_REQUEST";
export const ABLE_ROLE_SUCCESS = "ABLE_ROLE_SUCCESS";

// Data Tables
export const GET_DATA_TABLES = "GET_DATA_TABLES";
export const GET_ONE_DATA_TABLE = "GET_ONE_DATA_TABLE";

// PASSWORD CHECK
export const GET_PASSWORD_CHECK_REQUEST = "GET_PASSWORD_CHECK_REQUEST";
export const GET_PASSWORD_CHECK_SUCCESS = "GET_PASSWORD_CHECK_SUCCESS";
export const GET_PASSWORD_CHECK_FAIL = "GET_PASSWORD_CHECK_FAIL";
export const RESET_PASSWORD_CHECK = "RESET_PASSWORD_CHECK";
