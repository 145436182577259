import React from "react";
import { Route } from "react-router-dom";
import TellerLayout from "./index";
import Overview from "../../views/TellerInterface/Overview/index";
import Transaction from "../../views/TellerInterface/Transaction/index";
import TransactionsHistory from "../../views/TellerInterface/transactionHistory/index";
import Requests from "../../views/TellerInterface/requests/index";
import RequestsHistory from "../../views/TellerInterface/RequestHistory/index";

const DashboardContainer = () => {
  return (
    <TellerLayout>
      <Route
        exact
        path="/teller/transaction"
        component={(props) => <Transaction {...props} />}
      />
      <Route
        exact
        path="/teller/transaction/history"
        component={(props) => <TransactionsHistory {...props} />}
      />
      <Route
        exact
        path="/teller"
        component={(props) => <Overview {...props} />}
      />
      <Route
        exact
        path="/teller/requests"
        component={(props) => <Requests {...props} />}
      />
      <Route
        exact
        path="/teller/requests/history"
        component={(props) => <RequestsHistory {...props} />}
      />
    </TellerLayout>
  );
};

export default DashboardContainer;
