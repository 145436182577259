import React, { useEffect, useState } from "react";
import {
  ActionButtonsGroup,
  ContentWrapper,
  ContentWrapperNull,
  DownloadActionButton,
  NodataText,
  ProcessActionButton,
} from "../styles";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";
import Toast from "../../../../util/Toast";
import { sendUserSettlement } from "../../../../actions/settlementActions";
import { useLocation } from "react-router-dom";
import ModalContainer from "../../../../components/ModalContainer";
import ViewEOYModal from "../ViewEOYModal";
import { useHistory } from "react-router-dom";

const CompleteEOY = ({ pagination: externalPagination }) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const getGlAccountsBalances = useSelector(
    (state) => state.getGlAccountsBalances
  );

  const geteoyGlResponseBalance = useSelector(
    (state) => state.geteoyGlResponseBalance
  );

  let { eoyGlAccountsBalances } = getGlAccountsBalances || {};

  let { eoyGlResponseBalance } = geteoyGlResponseBalance || {};

  const [isModalOpen, setModalOpen] = useState(false);

  // Use useLocation hook to get parameters from the state
  const location = useLocation();
  const {
    office: paramOffice,
    transactionDate: paramTransactionDate,
    accountGl: paramAccountGl,
    currency: paramCurrency,
  } = location.state || {};

  // console.log(location, "location")
  //   useEffect(() => {
  //     // Use parameters in your component as needed
  //     console.log(paramOffice, paramTransactionDate, paramAccountGl);
  //   }, [paramOffice, paramTransactionDate, paramAccountGl]);

  const [pagination, setPagination] = useState({
    current: externalPagination?.current || 1,
    pageSize: externalPagination?.pageSize || 15,
  });

  const handleTableChange = (pagination, filters, sorter) => {
    // Handle table changes and update the pagination state
    setPagination({ ...pagination });
  };

  const columns = [
    { title: "GL Name", dataIndex: "glName", key: "glName" },
    { title: "Expense", dataIndex: "expense", key: "expense" },
    {
      title: "Income",
      dataIndex: "income",
      key: "income",
    },
  ];

  function formatBalance(amount) {
    // Ensure amount is a number
    const parsedAmount = parseFloat(amount);

    // Check if parsedAmount is a valid number
    if (isNaN(parsedAmount)) {
      return "Invalid Amount";
    }

    // Use toLocaleString to format the number with commas and decimal places
    const formattedAmount = parsedAmount.toLocaleString("en-US", {
      // style: "currency",
      // currency: "NGN", // Change this to your currency code
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formattedAmount;
  }

  const tableData = [];

  eoyGlAccountsBalances?.forEach((accounts) => {
    const {
      id,
      name,
      balance,
      type: { value },
    } = accounts;

    const formattedBalance = formatBalance(balance);

    tableData.push({
      id,
      glName: name,
      income: value === "INCOME" ? formattedBalance : "0",
      expense: value === "EXPENSE" ? formattedBalance : "0",
    });
  });

  //   const loading = true;

  function formatCustomDate(dateString) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0"); // Ensure two digits
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  }

  const handlegoBack = (history) => {
    history.push("/core/settlement/");
  };

  const onHide = () => {
    setModalOpen(false);
  };

  const handleSendSettlement = () => {
    // e.preventDefault();
    let newDate = new Date(paramTransactionDate);

    let data = {
      officeId: paramOffice,
      transactionDate: formatCustomDate(newDate, true),
      locale: "en",
      currencyCode: paramCurrency,
      dateFormat: "dd MMMM yyyy",
      equityAccountId: paramAccountGl,
    };

    dispatch(sendUserSettlement(data, setModalOpen));
  };

  return (
    <div className="container-fluid">
      <div className="row justify-content-between">
        <div className="col-sm-12 col-md-6 pb-3">
          <h3 className="page-title">End of Year</h3>
          <p className="sub-title">
            {" "}
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => history.goBack()}
            >
              Process EOY /
            </span>{" "}
            End of Year GL's settlement
          </p>

          {/* <p className="sub-title">End of Year GL's settlement</p> */}
        </div>
        <div className="col-sm-12 col-md-6 px-0 pt-3 d-flex justify-content-end">
          <ActionButtonsGroup>
            <div className="col-sm-12 col-md-12 p-0 d-flex">
              <DownloadActionButton
                className="form-control general-font-size"
                onClick={handleSendSettlement}
              >
                Run EOY
                <img
                  src={
                    require("../../../../assets/images/icons/downloadArrow.svg")
                      .default
                  }
                  className="d-inline ml-2"
                  alt="icon"
                />
              </DownloadActionButton>
            </div>
          </ActionButtonsGroup>
        </div>
      </div>
      <ContentWrapper>
        <Table
          columns={columns}
          dataSource={tableData}
          defaultPageSize={13}
          pagination={{ ...pagination, ...externalPagination }}
          onChange={handleTableChange}
        />
        {pagination === false ? (
          <>
            {/* <div className="spaceBetweenFlexEnd my-pag"> */}
            {/* <div */}
            <h2>Welcome</h2>
          </>
        ) : (
          ""
        )}
      </ContentWrapper>

      <ModalContainer
        show={isModalOpen}
        size={`md`}
        dialogClassName="document_infoModal"
      >
        <ViewEOYModal onCancel={handlegoBack} data={eoyGlResponseBalance} />
      </ModalContainer>
    </div>
  );
};

export default CompleteEOY;
