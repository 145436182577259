import React from "react";
import { Input } from "@chakra-ui/react";
import { ActionButton, FormElements, FormClass } from "./styles";
import { Modal } from "react-bootstrap";
import { formatDate } from "../../../../util/FormatDate";

const ViewTransaction = ({ onHide, details }) => {
  let { amount, date, currency, type, officeId, officeName } = details;

  return (
    <>
      <Modal.Header>
        <Modal.Title>View Transaction Details</Modal.Title>
      </Modal.Header>
      <Modal.Body className="wc-modal">
        <div className="d-flex align-items-center justify-content-center flex-direction-coloumn">
          <FormClass style={{ width: "90%" }}>
            <FormElements style={{ width: "100%" }}>
              <div className="row h-100">
                <div className="col-sm-12 col-md-12">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Office ID</label>
                    <Input
                      style={{ width: "60%" }}
                      size="md"
                      name="officeId"
                      value={officeId}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-12">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Office Name</label>
                    <Input
                      style={{ width: "60%" }}
                      size="md"
                      name="officeName"
                      value={officeName}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-12">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Amount</label>
                    <Input
                      style={{ width: "60%" }}
                      size="md"
                      name="amount"
                      value={amount}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-12">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Date</label>
                    <Input
                      style={{ width: "60%" }}
                      size="md"
                      name="date"
                      value={formatDate(date)}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-12">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Currency</label>
                    <Input
                      style={{ width: "60%" }}
                      size="md"
                      name="username"
                      value={currency ? currency.displayLabel : ""}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-12">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Transaction ID</label>
                    <Input
                      style={{ width: "60%" }}
                      size="md"
                      name="email"
                      value={type ? type.id : ""}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-12">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Transaction Type</label>
                    <Input
                      style={{ width: "60%" }}
                      size="md"
                      name="email"
                      value={type ? type.value : ""}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-4">
          <ActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require("../../../../assets/images/icons/cancel.svg").default
              }
              alt="cancel icon"
            />
            Close
          </ActionButton>
          {/* <ActionButton color="#2C1DFF" style={{ margin: "0px 0px 0 10px" }}>
              <img
                src={
                  require("../../../../../assets/images/icons/save.svg").default
                }
                alt="submit icon"
              />
              Submit
            </ActionButton> */}
        </div>
      </Modal.Footer>
    </>
  );
};

export default ViewTransaction;
