import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Select, Input } from "@chakra-ui/react";
import { FormClass, FormElements, ActionButton } from "../../styles.jsx";
import {
  updateCustomerTier,
  getSingleCustomer,
} from "../../../../../../actions/customerActions";
import CurrencyFormat from "react-currency-format";
// import Toast from '../../../../../../util/Toast';

const EditTier = ({ onHide, userData }) => {
  const dispatch = useDispatch();
  let { id } = useParams();

  console.log(userData, "00000");

  const userTemplate = useSelector((state) => state.createCustomerDetails);

  const { createCustomerDetails } = userTemplate;

  const [tierLevelOptions, setOptions] = useState(
    createCustomerDetails?.clientTierOptions
  );
  let { clientTier } = userData || {};

  const [clientTierLevel, setTierLevel] = useState(clientTier);
  const [tierId, setTierId] = useState(clientTier);
  // const [selectedTierLevel, setSelectedTierLevel] = useState([]);

  console.log(clientTierLevel, "clientTierLevel");
  const filterOption = useCallback(() => {
    let result = tierLevelOptions?.filter(
      (tierLevel) => tierLevel?.id == tierId
    );
    setTierLevel(result?.shift());
  }, [tierId, tierLevelOptions]);

  console.log(id, "llllllll");

  useEffect(() => {
    filterOption();
  }, [filterOption]);

  const func = () => {
    dispatch(getSingleCustomer(id));
  };

  const updateClientTier = () => {
    dispatch(updateCustomerTier(id, clientTierLevel?.tierRank, onHide, func));
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>Update Tier Level</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "100%" }}>
          <FormElements style={{ width: "100%" }}>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Current Tier level</label>
              <Input
                value={clientTier?.tierRank}
                disabled
                style={{ width: "60%" }}
              />
            </div>
          </FormElements>
          <FormElements style={{ width: "100%" }}>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">New Tier level</label>
              <Select
                placeholder="Select new tier level"
                size="md"
                onChange={(e) => setTierId(e.target.value)}
              >
                {tierLevelOptions?.map(({ id, tierRank }) => {
                  return (
                    <option key={id} value={id}>
                      {tierRank}
                    </option>
                  );
                })}
              </Select>
            </div>
          </FormElements>
          <FormElements style={{ width: "100%" }}>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Tier name</label>
              <Input
                value={clientTierLevel?.tierName}
                disabled
                style={{ width: "60%" }}
              />
            </div>
          </FormElements>
          <FormElements style={{ width: "100%" }}>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Savings withdrawal limit</label>
              <CurrencyFormat
                value={clientTierLevel?.savingsWithdrawLimit}
                thousandSeparator={true}
                className="amount-input"
                disabled
                style={{ width: "60%" }}
              />
            </div>
          </FormElements>
          {clientTier?.dailyWithdrawLimit ? (
            <>
              <FormElements style={{ width: "100%" }}>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Daily withdrawal limit</label>
                  <CurrencyFormat
                    value={clientTierLevel?.dailyWithdrawLimit}
                    thousandSeparator={true}
                    className="amount-input"
                    disabled
                    style={{ width: "60%" }}
                  />
                </div>
              </FormElements>
            </>
           ) : null} 

          {clientTier?.cumulativeBalance ? (
            <>
              <FormElements style={{ width: "100%" }}>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Cummulative Balance</label>
                  <CurrencyFormat
                    value={clientTierLevel?.cumulativeBalance}
                    thousandSeparator={true}
                    className="amount-input"
                    disabled
                    style={{ width: "60%" }}
                  />
                </div>
              </FormElements>
            </>
         ) : null} 

          {clientTier?.description ? (
            <>
              <FormElements style={{ width: "100%" }}>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">description</label>
                  <Input
                    value={clientTierLevel?.description}
                    disabled
                    style={{ width: "60%" }}
                  />
                </div>
              </FormElements>
            </>
          ) : null}

          <div className="float-right mt-1">
            <ActionButton color="#2C1DFF" onClick={onHide}>
              <img
                src={
                  require("../../../../../../assets/images/icons/cancel.svg")
                    .default
                }
                alt="cancel icon"
              />
              Cancel
            </ActionButton>
            <ActionButton
              color="#2C1DFF"
              style={{ margin: "0px 0px 0 10px" }}
              onClick={(e) => {
                e.preventDefault();
                updateClientTier();
              }}
            >
              <img
                src={
                  require("../../../../../../assets/images/icons/save.svg")
                    .default
                }
                alt="submit icon"
              />
              Submit
            </ActionButton>
          </div>
        </FormClass>
      </Modal.Body>
    </>
  );
};

export default EditTier;
