import React from 'react'
import { Link } from "react-router-dom";
import { ContentWrapper, ContentCard } from "./styles";



const CardListings = [
    {
      icon: require("../../../assets/images/icons/mg-nibss.svg").default,
      title: "Manage Account",
      description: "Manage your NIBSS accounts.",
      route: "manage-account",
    //   permission: "READ_LOANPRODUCT",
    },
    {
      icon: require("../../../assets/images/icons/TNibss-report.svg").default,
      title: "Transaction Report",
      description: "View and download your Transaction report",
      route: "transaction-report",
    //   permission: "READ_FIXEDDEPOSITPRODUCT",
    },
    {
      icon: require("../../../assets/images/icons/TNibss-report.svg").default,
      title: "NIBSS Report",
      description: "View and download your NIBSS report",
      route: "nibss-report",
    //   permission: "READ_FIXEDDEPOSITPRODUCT",
    },
  ];

const  NibssView = ()  =>{
  return (
    <>
    <div className="container-fluid">
      <div className="row justify-content-between">
        <div className="col-sm-12 col-md-6 pb-3">
          <h3 className="page-title">NIBSS integration</h3>
          <p className="sub-title">NIBSS integration for transaction</p>
          {/* <p className="font-weight sub-title mt-2">
            <span className="text-info">
              <Link to="/core/configuration">Configuration</Link>
            </span>{" "}
            / Products
          </p> */}
        </div>
      </div>
      <ContentWrapper>
        <div className="row">
          {CardListings.map(
            ({ icon, title, description, route, permission }, i) =>
            //   CheckPermission(permission, permissions) && (
                <div className="col-sm-12 col-md-4 col-lg-3 mb-4" key={i}>
                  <Link to={`nibss-management/${route}`}>
                    <ContentCard>
                      <img
                        className="teller-card-icon"
                        src={icon}
                        alt={title}
                      />
                      <div className="p-2">
                        <p className="teller-head teller-ellipses">{title}</p>
                        <p className="teller-sub-menu">{description}</p>
                      </div>
                    </ContentCard>
                  </Link>
                </div>
            //   )
          )}
        </div>
      </ContentWrapper>
    </div>
  </>
  )
}

export default NibssView