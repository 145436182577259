import React, { useState, useEffect} from "react";
import { useDispatch , useSelector} from "react-redux";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Input, FormControl } from "@chakra-ui/react";
import { FormClass, ActionButton, FormElements } from "../styles";
import { formatDate } from "../../../../../util/FormatDate";
import { approveFixedDepositAccount } from "../../../../../actions/fixedDeposit";
// import { waiveAFixedDepositAccountCharge } from "../../../../../services/Api/fixedDeposit.api.services";
import { waiveFixedDepositAccountCharge } from "../../../../../actions/fixedDeposit";

const WaiveChargesModal = ({ id, newId ,onHide, history, selectedTableIndex}) => {
  const dispatch = useDispatch();

//   const [inputAmount, setInputAmount] = useState(amount);
//   const [paymentDate, setPaymentDate] = useState(new Date());

  
  const handleWaive = async () => {
    let dataToSend = {
      dateFormat: "dd MMM yyyy",
      locale: "en",
    };
    // console.log(dataToSend, 'dataToSend')
    const successFull = await dispatch(waiveFixedDepositAccountCharge(id, newId, dataToSend, selectedTableIndex, "waive"));
    onHide();
  };

//   { locale: "en", dateFormat: "dd MMMM yyyy"}
// dateFormat: "dd MMMM yyyy"
// locale: "en"

  return (
    <>
      <Modal.Header>
        <Modal.Title>Pay Charges</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <FormElements style={{ width: "100%" }}>
            <div>
                <h3>Are you sure you want to waive charges?</h3>
            </div>
          </FormElements>
        </FormClass>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex mt-4">
          <ActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require("../../../../../assets/images/icons/cancel.svg").default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={(e) => {
              e.preventDefault();
              handleWaive();
            }}
          >
            <img
              src={
                require("../../../../../assets/images/icons/save.svg").default
              }
              alt="submit icon"
            />
            Submit
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default WaiveChargesModal;
