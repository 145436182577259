import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Select, FormControl } from "@chakra-ui/react";
import {
  FormClass,
  FormElements,
  ActionButton,
} from "../../CustomerInformation/styles";
import { assignStaffToAccount } from "../../../../../actions/SavingsAndLoanActions";
import Toast from "../../../../../util/Toast";
import { formatDate } from "../../../../../util/FormatDate";

const AssignStaff = ({ onHide, savingsAccountTemplate, history, currentSavingsAccountDetails }) => {
  const dispatch = useDispatch();

  const [selectedStaff, setSelectedStaff] = useState("");
  const [submittedOnDate, setSubmittedOnDate] = useState();
  let newDate = new Date(submittedOnDate);

  let {
    id,
    fieldOfficerId,
    fieldOfficerOptions = [],
  } = savingsAccountTemplate || {};

  const handleStaffAssign = () => {
    if (!currentSavingsAccountDetails.fieldOfficerName && !selectedStaff.length) {
      dispatch(
        Toast({ text: "Please select a staff to assign", icon: "info" })
      );
    } else {
      const dataToSendUnassign = {
        unassignedDate: formatDate(newDate, true),
        dateFormat: "dd MMM yyyy",
        locale: "en",
      };
      const dataToSendAssign = {
        toSavingsOfficerId: !!currentSavingsAccountDetails.fieldOfficerName ? fieldOfficerId : selectedStaff,
        assignmentDate: formatDate(newDate, true),
        dateFormat: "dd MMM yyyy",
        locale: "en",
        fromSavingsOfficerId: "",
      };
      dispatch(
        assignStaffToAccount(
          id,
          !currentSavingsAccountDetails.fieldOfficerName ? dataToSendAssign : dataToSendUnassign,
          !currentSavingsAccountDetails.fieldOfficerName ? "assignSavingsOfficer" : "unassignSavingsOfficer",
          history
        )
      );
      onHide();
    }
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          {!currentSavingsAccountDetails.fieldOfficerName ? "Assign" : "UnAssign"} Staff To Account
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          {!currentSavingsAccountDetails.fieldOfficerName ? (
            <FormElements style={{ width: "100%" }}>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Staff*</label>
                <Select
                  placeholder="Select Staff"
                  size="md"
                  value={selectedStaff}
                  onChange={(e) => {
                    e.preventDefault();
                    setSelectedStaff(e.target.value);
                  }}
                >
                  {!!fieldOfficerOptions && fieldOfficerOptions.length
                    ? fieldOfficerOptions.map(({ id, displayName }) => {
                        return (
                          <option key={id} value={id}>
                            {displayName}
                          </option>
                        );
                      })
                    : ""}
                </Select>
              </div>
            </FormElements>
          ) : (
            <h1 style={{ fontSize: "20px", margin: "20px 0px" }}>
              Unassign {currentSavingsAccountDetails.fieldOfficerName} from Account
            </h1>
          )}

          <div className="form-group d-flex justify-content-between align-items-center">
            <label className="text-label">
              {" "}
              {!currentSavingsAccountDetails.fieldOfficerName ? "Assign" : "UnAssign"} on*
            </label>
            <FormControl style={{ width: "60%" }}>
            <div className="datepicker-100-width">
              <DatePicker
                id="published-date"
                selected={submittedOnDate}
                onChange={(date) => setSubmittedOnDate(date)}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="dd/mmm/yyyy"
              />
              </div>
            </FormControl>
          </div>
        </FormClass>
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-1">
          <ActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require("../../../../../assets/images/icons/cancel.svg").default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={(e) => {
              e.preventDefault();
              handleStaffAssign();
            }}
          >
            <img
              src={
                require("../../../../../assets/images/icons/save.svg").default
              }
              alt="submit icon"
            />
            Submit
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default AssignStaff;
