import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ModalContainer from '../../../../components/ModalContainer';
import {
    getSavingsAccountEvents,
    getAllEventOptions,
} from "../../../../../../actions/SavingsAndLoanActions";

import { IconButton } from "../../styles";
import EditNotificationModal from './EditNotificationModal';

const columns = [
    { title: "Event Name", dataIndex: "codeName", key: "codeName" },
    { title: "SMS Status", dataIndex: "smsActive", key: "smsActive" },
    { title: "Charge", dataIndex: "chargeApplied", key: "chargeApplied" },
    { title: "Edit", dataIndex: "actions", key: "actions" },
];

const Notification = ({ id }) => {
    const dispatch = useDispatch();
    const savingsAndLoan = useSelector((state) => state.savingsAndLoan);
    const state = useSelector((state) => state);
    const isTableLoading = useSelector(
        (state) => state.isTableLoading.isTableLoading
    );
    const [modalContentName, setModalContentName] = useState("");
    const [modalState, toggleModal] = useState(false);
    const [notificationDetails, setNotificationsDetails] = useState({})

    let { savingsAccountEvents = {}, allEventOptions = {} } =
        savingsAndLoan || {};
    let { pageItems = [], totalFilteredRecords } =
        savingsAccountEvents || {};

    let notificationData = [];

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 5,
    });

    const currentContent = (e) => {
        const val = e.currentTarget.id.trim();

        if (val) {
            toggleModal(true);
            setModalContentName(val);
        }
    };

    const displayModalContent = () => {
        switch (modalContentName) {
            case "editNotification":
                return (
                    <EditNotificationModal
                        onHide={handleModalClose}
                        notificationData={notificationDetails}
                        allEventOptions={allEventOptions}
                        savingsId={id}
                    />
                );
            default:
                return;
        }
    };

    const handleModalClose = () => toggleModal(false);

    const [currentPagination, setCurrentPagination] = useState(1);
    const [myData, setMyData] = useState([]);

    useEffect(() => {
        if (!!savingsAccountEvents.length) {
            setMyData(savingsAccountEvents);
        } else if (!!pageItems.length) {
            setMyData(pageItems);
        }
    }, [pageItems, savingsAccountEvents]);


    useEffect(() => {
        if (totalFilteredRecords) {
            setPagination({
                current: currentPagination,
                pageSize: 5,
                total: totalFilteredRecords,
            });
        }
    }, [totalFilteredRecords, currentPagination]);

    const handleTableChange = (pagination, filters, sorter) => {
        setCurrentPagination(pagination.current);
        let dataToSend = `?offset=${(pagination.current - 1) * 5
            }&limit=5&sortOrder=DESC`;
        dispatch(getSavingsAccountEvents(id, {}, dataToSend));
    };

    useEffect(() => {
        dispatch(
            getSavingsAccountEvents(
                id,
                {},
                "?offset=0&limit=5&sortOrder=DESC"
            )
        );
        dispatch(getAllEventOptions())
    }, [id, dispatch]);

    myData.forEach((notification) => {
        const {
            chargeApplied,
            codeName,
            smsActive,
            linkedEvent = {}
        } = notification || {};

        notificationData.push({
            codeName: codeName.split('_')[0],
            chargeApplied: (
                chargeApplied ? (
                    <span className="new-text-success-table">
                        <span
                            style={{ fontSize: "12px" }}
                            className="text-success"
                        >
                            Yes
                        </span>
                    </span>
                ) : (
                    <span className="new-text-danger-table">
                        <span
                            style={{ fontSize: "12px" }}
                            className="text-danger"
                        >
                            No
                        </span>
                    </span>
                )
            ),
            smsActive: (
                smsActive ? (
                    <span className="new-text-success-table">
                        <span
                            style={{ fontSize: "12px" }}
                            className="text-success"
                        >
                            ACTIVE
                        </span>
                    </span>
                ) : (
                    <span className="new-text-danger-table">
                        <span
                            style={{ fontSize: "12px" }}
                            className="text-danger"
                        >
                            INACTIVE
                        </span>
                    </span>
                )
            ),
            actions: (
                <>
                    <IconButton
                        id="editNotification"
                        color="#2C1DFF"
                        onClick={(e) => {
                            currentContent(e);

                        }}
                    >
                        <img
                            src={
                                require("../../../../../../assets/images/icons/edit.svg").default
                            }
                            alt="edit"
                            className="icon-img"
                        />
                    </IconButton>
                </>
            ),
            notification,
        });
    });

    return (
        <>
            <ModalContainer show={modalState} dialogClassName="document_infoModal">
                {modalState && displayModalContent()}
            </ModalContainer>

            <>
                <Table
                    style={{
                        overflow: "hidden",
                        overflowX: "scroll",
                    }}
                    columns={columns}
                    dataSource={notificationData}
                    pagination={pagination}
                    defaultPageSize={5}
                    loading={false}
                    onChange={handleTableChange}
                    onRow={(r) => ({
                        onClick: () => {
                            setNotificationsDetails(r);
                        },
                    })}
                />
            </>
        </>
    );
};

export default Notification;
