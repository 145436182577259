import React from 'react'
import { Modal } from "react-bootstrap";
import { ActionButton } from '../../styles';
import Success from "../../../../../assets/images/icons/success-icon.svg"


function SuccessModal({ onHide , setSuccessModal}) {


const handleContinue = () => {
    setSuccessModal(false)
}

    return (
        <>
          {/* <Modal> */}
          <Modal.Header>
            <Modal.Title>Make Payment</Modal.Title>
            <div className="payment__close-modal" onClick={onHide}>
              X
            </div>
          </Modal.Header>
          <Modal.Body className="wc-modal">
            <div className="col-sm-12 col-md-12  align-items-center justify-content-center flex-direction-coloumn">
             {/* Success Payment */}
             <div className="success-text">Successful payment</div>
             {/* Success Payment Text*/}
             <div className="success_span-Text">
                <span>Your payment has been successfully made.</span>
             </div>
             {/* Done Icon */}
             <div className="success-icon">
                <img src={Success} alt="Success icon" />
             </div>
            </div>
          </Modal.Body>
          {/* Download */}
          <div className="payment__footer">
            <ActionButton
              color="#2C1DFF"
              className="float-right"
              style={{ margin: "0px 5px" }}
              id="continue"
              onClick={handleContinue}
            >
              Continue
            </ActionButton>{" "}
          </div>
          {/* </Modal> */}
        </>
      );
}

export default SuccessModal