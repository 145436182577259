import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  // formatDate,
  formatDateTime,
  // formatDateTimeNibss,
  // formatDateTimeTeller,
  formatTime,
} from "../../../../../util/FormatDate";
import { formatCurrency } from "../../../../../util/FormatAmount";
import { Input, Textarea, Select, Checkbox } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import CurrencyFormat from "react-currency-format";
import { formatDateString } from "../../../../../util/FormatDate";
import { Modal } from "react-bootstrap";
import { TimePicker } from "antd";
import moment from "moment";

import {
  updateATellerCashier,
  getCashierTemplate,
  getSingleTellerGroupCashier,
} from "../../../../../actions/tellerActions";
import { useDispatch, useSelector } from "react-redux";
import DropdownMenu from "../../TellerGroup/DropdownMenu";
import CurrencyTeller from "../../SingleTellerGroup/CurrencyTeller";
import CurrencyTellerEdit from "./CurrencyTellerEdit";
import Toast from "../../../../../util/Toast";
import CurrencyTellerSingleEdit from "../../SingleTellerGroup/CurrencyTellerSingleEdit";

export const ViewModal = ({ data, handleCloseModal }) => {
  const tellerGroupReducer = useSelector((state) => state.tellerReducer);
  const { cashierTemplate } = tellerGroupReducer;

  const startDate = data?.startDate ? formatDateTime(data?.startDate) : "";
  const endDate = data?.endDate ? formatDateTime(data?.endDate) : "";

  const [numberOfCurrencies, setNumberOfCurrencies] = useState();
  const [dataCurrenciesTeller, setDataCurrenciesTeller] = useState([]);
  const [optionSelected, setOptionSelected] = useState([]);

  useEffect(() => {
    if (data?.currencies) {
      const oldCurrencies = [];
      data?.currencies?.map((k) => oldCurrencies.push(k.code));
      setDataCurrenciesTeller(oldCurrencies);
      setNumberOfCurrencies(data?.glCurrencyMap);
      setOptionSelected(data?.currencies.map((k) => k.code));
    }
  }, [data?.currencies]);

  const dataCurrencies = data?.currencies?.map((k, index) => {
    if (index === data?.currencies.length - 1) {
      return k.name;
    } else {
      return `${k.name}, `;
    }
  });

  return (
    <>
      <Modal.Header>
        <Modal.Title>View Teller</Modal.Title>
      </Modal.Header>
      <Modal.Body className="wc-modal">
        <div className="p-1 position-relative">
          <div className="d-flex mb-3 ">
            <TextQuestion>Assigned Staff: </TextQuestion>{" "}
            <Heading>{data?.staffName}</Heading>
          </div>
          <div className="d-flex mb-3 ">
            <TextQuestion>Teller group: </TextQuestion>{" "}
            <Text>{data?.tellerName}</Text>
          </div>
          <div className="d-flex mb-3 ">
            <TextQuestion>Cashier ID: </TextQuestion> <Text>{data?.id}</Text>
          </div>
          <div className="d-flex mb-3 ">
            <TextQuestion>Currencies: </TextQuestion>{" "}
            <Text>{dataCurrencies?.join("")}</Text>
          </div>
          <div className=" mb-3 ">
            {/* <TextQuestion> */}
            {numberOfCurrencies?.map(({ currencyCode, name }) => {
              return optionSelected?.includes(currencyCode) ? (
                <>
                  <CurrencyTellerEdit
                    currency={name}
                    cashierTemplate={cashierTemplate}
                    numberOfCurrencies={numberOfCurrencies}
                    setNumberOfCurrencies={setNumberOfCurrencies}
                    glIndex={optionSelected.indexOf(currencyCode)}
                    currencyCode={currencyCode}
                  />
                </>
              ) : (
                ""
              );
            })}

            {/* </TextQuestion> */}
            {/* <Text>{data?.id}</Text> */}
          </div>
          {numberOfCurrencies ? (
            <hr
              style={{
                // border: "1px solid rgb(218, 220, 229)",
                width: "100%",
                marginBottom: "15px",
                background: "#DADCE5",
              }}
            />
          ) : (
            ""
          )}

          <div className="d-flex mb-3 align-items-start">
            <TextQuestion>Default Currency: </TextQuestion>{" "}
            <Text>{data?.defaultCurrency?.name || ""}</Text>
          </div>
          <div className="d-flex mb-3 align-items-start">
            <TextQuestion>Description: </TextQuestion>{" "}
            <Text>{data?.description || "No description"}</Text>
          </div>
          <div className="d-flex mb-3">
            <TextQuestion>Start date: </TextQuestion>{" "}
            <Text>{formatDateString(data?.startDate, "dd/MMM/yyyy")}</Text>
          </div>
          {data?.startTime !== " " ? (
            <div className="d-flex mb-3">
              <TextQuestion>Start time: </TextQuestion>{" "}
              <Text>{data?.startTime ? formatTime(data.startTime) : ""}</Text>
            </div>
          ) : (
            ""
          )}
          <div className="d-flex mb-3">
            <TextQuestion>End date: </TextQuestion>{" "}
            <Text>{formatDateString(data?.endDate, "dd/MMM/yyyy")}</Text>
          </div>
          {data?.endTime !== " " ? (
            <div className="d-flex mb-3">
              <TextQuestion>End time: </TextQuestion>{" "}
              <Text>{data?.endTime ? formatTime(data?.endTime) : ""}</Text>
            </div>
          ) : (
            ""
          )}
          <div className="d-flex mb-3">
            <TextQuestion>Status:</TextQuestion>
            <Text>{data?.isEnabled ? "Active" : "Inactive"}</Text>
          </div>
          <div className="d-flex mb-3">
            <TextQuestion>Full day teller:</TextQuestion>
            <Text>{data?.isFullDay ? "True" : "False"}</Text>
          </div>
          {/* <div className="d-flex mb-3">
            <TextQuestion>Debit account ID:</TextQuestion>
            <Text>{data?.debitAccountId}</Text>
          </div>
          <div className="d-flex mb-3">
            <TextQuestion>Credit account ID:</TextQuestion>
            <Text>{data?.creditAccountId}</Text>
          </div> */}
          {/* <div className="new-detail-cancel" onClick={handleCloseModal}>
            <img
              src={
                require("../../../../../assets/images/icons/close.svg").default
              }
              className="detail-cancel-icon"
              alt="cancel icon"
            />
          </div> */}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-1">
          <ActionButton color="#2C1DFF" onClick={handleCloseModal}>
            <img
              src={
                require("../../../../../assets/images/icons/cancel.svg").default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export const EditModal = ({ data, handleCloseModal }) => {
  const dispatch = useDispatch();
  const [staffId, setStaffId] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [status, setStatus] = useState("");
  const [debitAccountId, setDebit] = useState("");
  const [creditAccountId, setCredit] = useState("");
  const [dailyLimit, setDailyLimit] = useState("");
  const [withdrawalLimit, setWithdrawalLimit] = useState("");
  const [isFullDay, setFullDay] = useState(false);
  const [creditAccPlacholder, setPlaceholder] = useState("");
  const [debitAccPlacholder, setDebitPlaceholder] = useState("null");
  const tellerGroupReducer = useSelector((state) => state.tellerReducer);
  const { cashierTemplate } = tellerGroupReducer;
  const hourStartTime = !!startTime ? startTime.slice(0, 2) : "";
  const minStartTime = !!startTime ? startTime.slice(-5, -3) : "";
  const hourEndTime = !!endTime ? endTime.slice(0, 2) : "";
  const minEndTime = !!endTime ? endTime.slice(-5, -3) : "";
  const [optionSelected, setOptionSelected] = useState([]);
  const [currenciesOptions, setCurrenciesOptions] = useState([]);
  const [newDataValues, setNewDataValues] = useState([]);
  const [defaultCurrencyId, setDefaultCurrencyId] = useState("");
  const [glAccountMap, setGlAccountMap] = useState([]);
  const [oldCurrenciesTeller, setOldCurrenciesTeller] = useState([]);
  const [numberOfCurrencies, setNumberOfCurrencies] = useState([]);

  const [currencyTeller, setCurrencyTeller] = useState([]);

  const tellerReducer = useSelector((state) => state.tellerReducer);

  let { setCurrencies = [] } = tellerReducer || {};

  const { currencyOptions = [] } = cashierTemplate || {};

  const handleUpdateServices = (service_Data) => {
    setOptionSelected([...service_Data]);
    const newDefaultCurrenciesAcct = {
      glAccountId: "",
      currencyCode: service_Data[service_Data.length - 1],
      dailyLimit: "",
      withdrawalLimit: "",
    };
    setNumberOfCurrencies([...numberOfCurrencies, newDefaultCurrenciesAcct]);

    if (service_Data) {
      const defaultCurrency = currencyOptions.find(
        (option) => option.code === service_Data[service_Data.length - 1]
      );
      const updatedCurrenciesTeller = [...oldCurrenciesTeller];
      // check if the new default currency is already in the oldCurrenciesTeller array
      const index = updatedCurrenciesTeller.findIndex(
        (currency) => currency.code === defaultCurrency.code
      );
      if (index === -1) {
        // if the new default currency is not already in the oldCurrenciesTeller array, add it
        updatedCurrenciesTeller.push(defaultCurrency);
      }
      // set the state with the updated array of currencies
      setOldCurrenciesTeller(updatedCurrenciesTeller);
      // setDefaultCurrencyId()
    }
  };

  const handleDeleteServicesss = (o) => {
    let index = optionSelected.indexOf(o);

    // Remove the corresponding element from the numberOfCurrencies array
    let newNumberOfCurrencies = [];
    if (
      Array.isArray(numberOfCurrencies) &&
      numberOfCurrencies.length > index
    ) {
      newNumberOfCurrencies = [...numberOfCurrencies];
      newNumberOfCurrencies.splice(index, 1);
    }

    // Update the state of numberOfCurrencies
    setNumberOfCurrencies(newNumberOfCurrencies);

    // setOptionSelected(optionSelected.splice(index, 1));
    // console.log(optionSelected, "inside Handle delete");
    // console.log(service_Data, "service_Data");
    // let i = -1;
    // let hhh = [];
    // hhh = numberOfCurrencies.filter(({ currencyCode, index }) => {
    // return currencyCode !== service_Data;
    // return service_Data.includes(currencyCode);
    // });

    // handleChangeNumberOfCurrencies(hhh);
    // setNumberOfCurrencies([...hhh]);

    // console.log(i , "i")
    // console.log(service_Data , "serviceData")
    // console.log(hhh, "hhh");
  };

  const handleDeleteServices = (o) => {
    const index = optionSelected.indexOf(o);


    // Remove the corresponding element from the optionSelected array
    const newOptionSelected = optionSelected.filter((option) => option !== o);

    // Remove the corresponding element from the numberOfCurrencies array
    const newNumberOfCurrencies = numberOfCurrencies.filter(
      (currency, i) => i !== index
    );

    // Remove the corresponding element from the oldCurrenciesTeller array
    const newOldCurrenciesTeller = oldCurrenciesTeller.filter(
      (currency) => currency.code !== o
    );

    // Update the state of optionSelected and numberOfCurrencies
    setOptionSelected(newOptionSelected);
    setNumberOfCurrencies(newNumberOfCurrencies);
    setOldCurrenciesTeller(newOldCurrenciesTeller);

  };


  const newLocal = localStorage.getItem("newData");

  useEffect(() => {
    if (data?.currencies) {
      const newCurrencies = [];
      data?.currencies.map((curr) =>
        newCurrencies.push({ value: curr.code, label: curr.name })
      );
      setCurrenciesOptions(newCurrencies);
    } else setCurrenciesOptions([]);
    if (data?.currencies) {
      const oldCurrencies = [];
      data?.currencies.map((curr) => oldCurrencies.push(curr.code));
      setOldCurrenciesTeller(data?.currencies);
      setNewDataValues(oldCurrencies);
      setOptionSelected(oldCurrencies);
    } else setNewDataValues([]);
    setDefaultCurrencyId(data?.defaultCurrency?.code);
    setNumberOfCurrencies(data?.glCurrencyMap);
  }, [data]);

  // useEffect(() => {
  //   if (optionSelected) {
  //     const selectedCurrencyOption = oldCurrenciesTeller.filter((option) =>
  //       optionSelected?.includes(option.value)
  //     );
  //     setOldCurrenciesTeller(selectedCurrencyOption);
  //   }
  // }, [optionSelected]);

  useEffect(() => {
    if (currencyOptions) {
      const currenciesTeller = [];
      const oldTellerCurrencies = [];
      currencyOptions?.map((k) =>
        currenciesTeller.push({ value: k.code, label: k.name })
      );

      setCurrencyTeller(currenciesTeller);
    } else setCurrencyTeller([]);
  }, [currencyOptions]);

  useEffect(() => {
    if (data?.tellerId) {
      dispatch(getCashierTemplate(data?.tellerId));
    }
  }, [data?.tellerId, dispatch]);

  const creditPlaceholder = creditAccPlacholder
    ? creditAccPlacholder[0]?.name
    : "";
  const debitPlaceholder = debitAccPlacholder
    ? debitAccPlacholder[0]?.name
    : "";

  useEffect(() => {
    if (cashierTemplate && cashierTemplate?.glAccountOptions) {
      const creditAccountPlaceholder =
        cashierTemplate &&
        cashierTemplate?.glAccountOptions.filter(
          (eachAccount) => eachAccount?.id === data?.creditAccountId
        );
      setPlaceholder(creditAccountPlaceholder);
    }
    const debitAccountPlaceholder =
      cashierTemplate &&
      cashierTemplate?.glAccountOptions?.filter(
        (eachAccount) => eachAccount.id === data?.debitAccountId
      );
    setDebitPlaceholder(debitAccountPlaceholder);
  }, [cashierTemplate?.glAccountOptions, data]);

  useEffect(() => {
    if (data) {
      setStaffId(data?.staffId);
      setDescription(data?.description);
      setDailyLimit(data?.dailyLimit);
      setWithdrawalLimit(data?.withdrawalLimit);
      setFullDay(data?.isFullDay);
      setEndDate(new Date(data?.endDate));
      setStartDate(new Date(data?.startDate));
      if (data?.isFullDay) {
        setEndTime("00:00:00");
        setStartTime("00:00:00");
      } else {
        setEndTime(data?.endTime.trim());
        setStartTime(data?.startTime.trim());
      }
    }
  }, []);

  const data2 =
    isFullDay === true
      ? ""
      : {
          hourStartTime: hourStartTime,
          hourEndTime: hourEndTime,
          minStartTime: minStartTime,
          minEndTime: minEndTime,
        };

  // console.log(numberOfCurrencies , "numberOfCurrencies")

  const onSubmit = () => {
    let glCurrencies = [];
    numberOfCurrencies.map((k) => {
      let { glAccountId, currencyCode, dailyLimit, withdrawalLimit } = k;
      // currency = service_Data[service_Data.length - 1]

      glCurrencies.push({
        glAccountId,
        currency: currencyCode,
        dailyLimit,
        withdrawalLimit,
        isCredit: true,
        isDebit: true,
      });
    });

    const id = data?.tellerId;
    const cashId = data?.id;


    if (glCurrencies.length > 0 && staffId) {
      const datatosend = {
        staffId: staffId ? staffId : data?.staffId,
        startDate: startDate
          ? formatDateString(startDate, "dd/MMM/yyyy")
          : formatDateString(data?.startDate, "dd/MMM/yyyy"),
        endDate: endDate
          ? formatDateString(endDate, "dd/MMM/yyyy")
          : formatDateString(data?.endDate, "dd/MMM/yyyy"),
        status: status
          ? status
          : data?.isEnabled === true
          ? "300"
          : data?.isEnabled === false
          ? "400"
          : "",
        locale: "en",
        isFullDay: isFullDay,
        dateFormat: "dd/MMMM/yyyy",
        description: description ? description : data?.description,
        ...data2,
        currencies: optionSelected,
        defaultCurrency: defaultCurrencyId,
        glCurrencies,
      };

      const func = () => {
        return dispatch(getSingleTellerGroupCashier(id));
      };

      dispatch(updateATellerCashier(datatosend, id, cashId, func));
      setStaffId("");
      setDescription("");
      setStartDate("");
      setEndDate("");
      setStartTime("");
      setEndTime("");
      // setStatus("");
      setDebit("");
      setCredit("");
      setDailyLimit("");
      setWithdrawalLimit("");
      // setFullDay(false);
    } else {
      dispatch(Toast({ text: "Required fields can't be empty", icon: "info" }));
    }
  };


  return (
    <>
      <Modal.Header>
        <Modal.Title>Edit Teller</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="p-1 position-relative">
          <div className="d-flex mb-3 ">
            <TextQuestion>
              Teller<span className="required-color">*</span>{" "}
            </TextQuestion>
            <Select
              isFullWidth="true"
              name="staffId"
              placeholder={data?.staffName}
              value={staffId}
              onChange={(e) => setStaffId(e.target.value)}
            >
              {!!cashierTemplate?.staffOptions?.length > 0
                ? cashierTemplate?.staffOptions?.map((item) => {
                    return (
                      <option key={item.id} value={item?.id}>
                        {item?.displayName}
                      </option>
                    );
                  })
                : ""}
            </Select>
          </div>
          {/* dropdown menu  */}
          <div className="d-flex mb-3">
            <TextQuestion>
              Currencies<span className="required-color">*</span>{" "}
            </TextQuestion>{" "}
            <div className="select-100-width" style={{ width: "57%" }}>
              <DropdownMenu
                values={newDataValues}
                multiple
                name="currency"
                placeholder="Select"
                updateServices={handleUpdateServices}
                options={currencyTeller}
                byId
                deleteServices={handleDeleteServices}
                // removeCurrencyDetail={removeCurrencyDetail}
              />
            </div>
          </div>
          <div className="mb-3 align-items-start">
            {currencyOptions.map(({ code, name }, index) => {
              return optionSelected.includes(code) ? (
                <>
                  <CurrencyTellerSingleEdit
                    currency={name}
                    cashierTemplate={cashierTemplate}
                    numberOfCurrencies={numberOfCurrencies}
                    setNumberOfCurrencies={setNumberOfCurrencies}
                    // setNumberOfCurrencies={(value) => {
                    //   const newNumberOfCurrencies = [...numberOfCurrencies];
                    //   newNumberOfCurrencies[index] = value; // update the corresponding numberOfCurrencies value
                    //   setNumberOfCurrencies(newNumberOfCurrencies);
                    // }}
                    currencyIndex={optionSelected.indexOf(code)}
                    currencyCode={code}
                    optionSelected={optionSelected}
                    newLocal={newLocal}
                  />
                </>
              ) : (
                ""
              );
            })}
          </div>
          {numberOfCurrencies ? (
            <hr
              style={{
                // border: "1px solid rgb(218, 220, 229)",
                width: "100%",
                marginBottom: "15px",
                background: "#DADCE5",
              }}
            />
          ) : (
            ""
          )}
          <div className="d-flex mb-3 align-items-start">
            <TextQuestion>Default Currency: </TextQuestion>{" "}
            <Select
              isFullWidth="true"
              name="defaultCurrency"
              placeholder="Select"
              value={defaultCurrencyId}
              onChange={(e) => setDefaultCurrencyId(e.target.value)}
            >
              {!!oldCurrenciesTeller?.length > 0
                ? oldCurrenciesTeller?.map((item) => {
                    return (
                      <option key={item.code} value={item?.code}>
                        {item?.name}
                      </option>
                    );
                  })
                : ""}
            </Select>
          </div>
          <div className="d-flex mb-3 align-items-start">
            <TextQuestion>Description: </TextQuestion>{" "}
            <Textarea
              placeholder={data?.description}
              onChange={(e) => setDescription(e.target.value)}
              value={description}
              style={{ width: "60%", marginTop: "10px", fontSize: "12px" }}
            ></Textarea>
          </div>

          <div className="d-flex mb-3">
            <TextQuestion>
              Start date<span className="required-color">*</span>{" "}
            </TextQuestion>
            <DatePicker
              id="published-date"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              value={startDate}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText={formatDateString(data?.startDate, "dd/MMM/yyyy")}
            />
          </div>
          <div className="d-flex mb-3">
            <TextQuestion>
              End date<span className="required-color">*</span>{" "}
            </TextQuestion>
            <DatePicker
              id="published-date"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              value={endDate}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText={formatDateString(data?.endDate, "dd/MMM/yyyy")}
            />
          </div>

          <div className="d-flex">
            <TextQuestion>Status:</TextQuestion>
            <Select
              onChange={(e) => setStatus(e.target.value)}
              placeholder={data?.status}
              value={data?.status}
            >
              <option value="300">Active</option>
              <option value="400"> Inactive</option>
            </Select>
          </div>
          <div className="d-flex mt-3">
            <TextQuestion>Is full day ? </TextQuestion>
            <Checkbox
              isChecked={isFullDay}
              onChange={(e) => setFullDay(e.target.checked)}
              colorScheme="blue"
            />
          </div>
          {isFullDay === true ? (
            ""
          ) : (
            <>
              <div className="d-flex mt-3">
                <TextQuestion>
                  Start time<span className="required-color">*</span>{" "}
                </TextQuestion>

                <TimePicker
                  value={moment(startTime, "HH:mm:ss")}
                  onChange={(timeString) => {
                    const timeHr = formatTime(timeString?._d);
                    setStartTime(timeHr);
                  }}
                />
              </div>
              <div className="d-flex mt-3">
                <TextQuestion>
                  End time<span className="required-color">*</span>{" "}
                </TextQuestion>

                <TimePicker
                  value={moment(endTime, "HH:mm:ss")}
                  onChange={(timeString) => {
                    const timeHr = formatTime(timeString?._d);
                    setEndTime(timeHr);
                  }}
                />
              </div>
            </>
          )}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="float-right mt-1">
          <ActionButton color="#2C1DFF" onClick={handleCloseModal}>
            <img
              src={
                require("../../../../../../src/assets/images/icons/cancel.svg")
                  .default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={onSubmit}
          >
            <img
              src={
                require("../../../../../../src/assets/images/icons/save.svg")
                  .default
              }
              alt="submit icon"
            />
            Update
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export const DeleteModal = ({
  data,
  tellerId,
  cashierId,
  deleteTellerCashier,
  handleCloseModal,
}) => {
  const handleDelete = () => {
    return deleteTellerCashier(
      tellerId,
      cashierId,
      getSingleTellerGroupCashier(tellerId)
    );
  };
  return (
    <div className="px-5 pt-5 pb-1 position-relative">
      <Text>Are you sure you want to delete?</Text>
      <h3
        className="text-center h3 mt-3"
        style={{ fontFamily: "AventaSemiBold", fontSize: "17px" }}
      >
        Teller: {data ? data?.staffName : ""}
      </h3>
      <div className="new-detail-cancel" onClick={handleCloseModal}>
        <img
          src={require("../../../../../assets/images/icons/close.svg").default}
          className="detail-cancel-icon"
          alt="cancel icon"
        />
      </div>
      <div className="text-right mt-4 mb-3">
        <ActionButton onClick={handleDelete} color="#2C1DFF">
          <img
            src={
              require("../../../../../assets/images/icons/check-mark.svg")
                .default
            }
            alt="cancel icon"
          />
          Confirm
        </ActionButton>
      </div>
    </div>
  );
};

export const RemoveModal = ({ handleCloseModal }) => {
  return (
    <div className="px-5 pt-5 pb-1 position-relative">
      <Text>Are you sure you want to remove?</Text>
      <h3 className="text-center h3">Teller: Kore Archie</h3>
      <div className="detail-cancel" onClick={handleCloseModal}>
        <img
          src={require("../../../../../assets/images/icons/close.svg").default}
          className="detail-cancel-icon"
          alt="cancel icon"
        />
      </div>
      <div className="text-right mt-5">
        <ActionButton color="#2C1DFF">
          <img
            src={
              require("../../../../../assets/images/icons/check-mark.svg")
                .default
            }
            alt="cancel icon"
          />
          Confirm
        </ActionButton>
      </div>
    </div>
  );
};

const TextQuestion = styled.p`
  width: 40%;
  font-size: 12px;
  font-family: "" AventaMedium "";
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000;
  font-weight: 400;
  margin-right: 25px;
`;

const Heading = styled.h4`
  font-size: 12px;
  font-family: "Helvetica Neue";
  display: flex;
  align-items: center;
  text-align: center;
  color: #002c79;
`;

const Text = styled.p`
  font-size: 12px;
  font-family: "Helvetica Neue";
  color: black;
  text-align: center;
`;

const GridContainer = styled.div`
  display: grid !important;
  grid-template-columns: 2fr 3fr;
  align-items: center;
  margin-bottom: 15px;
  color: #969cba;
  max-width: 500px;

  label {
    font-size: 18px;
    color: #969cba;
  }
  /* input,
  select,
  textarea {
    width: 100%;
  } */
`;

const ActionButton = styled.button`
  background: ${(props) => props.color || "#2C1DFF"} !important;
  border-radius: 5px !important;
  color: #fff !important;
  margin: 0 3px;
  border: none;
  font-family: "AventaMedium";
  display: inline;
  font-size: 14px;
  height: 35px;
  width: 130px;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  position: relative;
  cursor: pointer;
  img {
    height: 14px;
    margin-right: 10px;
    display: inline;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;
