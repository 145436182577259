import { useState } from "react";
import "./style/checkboxStyles.css";


const CheckBox = ({ label, value, onChange, checked }) => {
  // const [checked, setIsChecked] = useState(false);

  // const handleOnChange = () => {
  //   setIsChecked(!checked);
  // };

  // const colorClassName = ``
  const checkBg = checked ? "col-sm-12 checkedClass" : " col-sm-12 uncheckedClass";

  return (
    <div className="">
      <div className="">
        <div className={checkBg}>
          <input
            type="checkbox"
            value={value}
            checked={checked}
            onChange={onChange}
          />
          <label style={{marginTop: "10px", marginLeft: "30px"}}>{label}</label>
        </div>
      </div>
    </div>
  );
};

export default CheckBox;
