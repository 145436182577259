import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { Input, FormControl } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import CurrencyFormat from "react-currency-format";

import {
  FormClass,
  FormElements,
  ActionButton,
} from "../../CustomerInformation/styles";
import {
  getLoanDisburseDetails,
  handleLoanTransactions,
} from "../../../../../actions/SavingsAndLoanActions";
import { formatDate } from "../../../../../util/FormatDate.js";
import { formatCurrency } from "../../../../../util/FormatAmount";

const WeiveInterestModal = ({ onHide, id, history }) => {
  const dispatch = useDispatch();
  const savingsAndLoan = useSelector((state) => state.savingsAndLoan);

  let { loanAccountDisburseDetails } = savingsAndLoan || {};


  let { amount = 0 } = loanAccountDisburseDetails || {};

  useEffect(() => {
    dispatch(getLoanDisburseDetails(id, "waiveinterest"));
  }, [id, dispatch]);

  //STATE HANDLER FOR DEPOSIT INFO
  const [transactionDate, setTransactionDate] = useState(new Date());

  const [enteredAmount, setAmount] = useState(0);

  const [note, setNote] = useState("");

  useEffect(() => {
    if (!!amount) {
      setAmount(amount);
    }
  }, [amount]);

  // const handleChange = () => {
  //   if (!!amount) {
  //     setAmount(enteredAmount);
  //   }
  // };

  const handleSubmitForClosure = () => {
    let disbursementData = {
      locale: "en",
      dateFormat: "dd MMM yyy",
      note,
      transactionAmount: enteredAmount,
      transactionDate: formatDate(transactionDate, true),
    };
    dispatch(
      handleLoanTransactions(id, disbursementData, "waiveinterest", history)
    );

    onHide();
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>Waive Interest</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <FormElements style={{ width: "100%" }}>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Interest Waived On*</label>
              <FormControl style={{ width: "60%" }}>
                <div className="datepicker-100-width">
                  <DatePicker
                    id="published-date"
                    selected={transactionDate}
                    onChange={(date) => setTransactionDate(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="dd/mmm/yyyy"
                  />
                </div>
              </FormControl>
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Transaction Amount*</label>
              {/* <Input
                style={{ width: "60%" }}
                name="enteredAmount"
                value={formatCurrency(Number(enteredAmount))}
                onChange={(e) => setAmount(e.target.value)}
                size="md"
                // disabled
              /> */}
              <CurrencyFormat
                value={enteredAmount}
                thousandSeparator={true}
                className="amount-input "
                style={{
                  width: "60%",
                }}
                onValueChange={(values) => {
                  const { value } = values;
                  setAmount(value);
                }}
              />
            </div>

            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Additional Note</label>
              <Input
                style={{ width: "60%" }}
                name="note"
                value={note}
                onChange={(e) => setNote(e.target.value)}
                size="md"
              />
            </div>
          </FormElements>
        </FormClass>
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-1">
          <ActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require("../../../../../assets/images/icons/cancel.svg").default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton
            onClick={handleSubmitForClosure}
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
          >
            <img
              src={
                require("../../../../../assets/images/icons/save.svg").default
              }
              alt="submit icon"
            />
            Submit
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default WeiveInterestModal;
