import React, { useState, useEffect, useRef } from "react";
import {
  ContentWrapper,
  ActionButtonsGroup,
  ActionButton,
  EditButton,
} from "./styles";
import { InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import ModalContainer from "../../../../components/ModalContainer";
import { useDispatch, useSelector } from "react-redux";
import CheckPermission from "../../../../../../util/CheckPermission";
import {
  getAllTaxGroups,
  filterTaxGroups,
  clearFilteredTaxGroups,
  getTaxGroupsTemplate,
} from "../../../../../../actions/configurationActions";
import Toast from "../../../../../../util/Toast";
import TableLoader from "./components/TableLoader";
import CreateTaxGroup from "./components/CreateTaxGroup";
import EditTaxGroup from "./components/EditTaxGroup";
const ManageTaxGroupView = () => {
  const [modalState, toggleModal] = useState(false);

  const dispatch = useDispatch();

  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  const [modalContentName, setModalContentName] = useState("");
  const [selectedTaxGroup, setSelectedTaxGroup] = useState({});
  const currentContent = (e) => {
    const val = e.currentTarget.id.trim();

    if (val) {
      toggleModal(true);
      setModalContentName(val);
    }
  };
  const handleModalClose = () => toggleModal(false);

  const taxCompList = useSelector((state) => state.taxGroupsReducer);
  const taxCompTemplate = useSelector((state) => state.taxGroupsTemplate);

  const { loading, taxGroups, filtered } = taxCompList;
  const { taxGroupsTemplate } = taxCompTemplate;
  taxGroups.sort((a, b) => (a.name > b.name ? 1 : -1));

  const search = useRef("");
  const onFilterTaxGroup = (e) => {
    search.current.value !== ""
      ? dispatch(filterTaxGroups(e.target.value))
      : dispatch(clearFilteredTaxGroups());
  };
  useEffect(() => {
    dispatch(getTaxGroupsTemplate());
    dispatch(clearFilteredTaxGroups());
    dispatch(getAllTaxGroups());
    if (filtered === null) {
      search.current.value = "";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditTaxGroup = (id) => {
    setSelectedTaxGroup(taxGroups.filter((off) => off.id === id)[0]);
    toggleModal(true);
    setModalContentName("editGroup");
  };

  if (filtered !== null && filtered.length === 0) {
    dispatch(
      Toast({
        text: "No tax group found",
        icon: "error",
        timer: 2000,
      })
    );
  }

  //Table configuration

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "", dataIndex: "edit", key: "edit" },
  ];

  const dataSource = [];
  const filteredTaxGroup = [];

  if (filtered !== null) {
    filtered.forEach((tax) => {
      const { id, name } = tax;
      filteredTaxGroup.push({
        key: id,
        name,

        edit: CheckPermission("UPDATE_TAXGROUP", permissions) && (
          <EditButton onClick={() => handleEditTaxGroup(id)}>Edit</EditButton>
        ),
      });
    });
  }

  !loading &&
    taxGroups.forEach((tax) => {
      const { id, name } = tax;
      dataSource.push({
        key: id,
        name,
        edit: CheckPermission("UPDATE_TAXGROUP", permissions) && (
          <EditButton onClick={() => handleEditTaxGroup(id)}>Edit</EditButton>
        ),
      });
    });

  const displayModalContent = () => {
    switch (modalContentName) {
      case "createGroup":
        return (
          <CreateTaxGroup
            onHide={handleModalClose}
            template={taxGroupsTemplate}
          />
        );
      case "editGroup":
        return (
          <EditTaxGroup
            template={taxGroupsTemplate}
            data={selectedTaxGroup}
            onHide={handleModalClose}
          />
        );

      default:
        return;
    }
  };
  return (
    <>
      <ModalContainer show={modalState}>
        {modalState && displayModalContent()}
      </ModalContainer>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Manage Tax Groups</h3>
            <p className="font-weight sub-title">
              <span className="text-info">
                {/* <Link to="/core/configuration">Configuration</Link> /{" "} */}
                <Link to="/core/manage-tax-configuration">
                  Manage Tax Configuration
                </Link>
              </span>{" "}
              / Manage Tax Groups
            </p>
          </div>
          <div className="col-sm-12 col-md-6 px-0 pt-4">
            <ActionButtonsGroup>
              <div className="col-sm-12 col-md-6 pr-2">
                <InputGroup style={{ maxWidth: "450px" }}>
                  <InputLeftElement
                    width="3.1rem"
                    height="38px"
                    pointerEvents="none"
                  >
                    <img
                      src={
                        require("../../../../../../assets/images/icons/search.svg")
                          .default
                      }
                      style={{ height: "30px" }}
                      alt="Icon"
                      className="icon-img"
                    />
                  </InputLeftElement>
                  <Input
                    style={{
                      background: "#ffffff",
                      height: "35px",
                    }}
                    ref={search}
                    onChange={onFilterTaxGroup}
                    type="text"
                    className="general-font-size"
                    placeholder="Search by name"
                  />
                </InputGroup>
              </div>
              <div className="col-sm-12 col-md-6 p-0 d-flex">
                {CheckPermission("CREATE_TAXGROUP", permissions) && (
                  <ActionButton id="createGroup" onClick={currentContent}>
                    <img
                      src={
                        require("../../../../../../assets/images/icons/add.svg")
                          .default
                      }
                      alt="add"
                    />
                    Create
                  </ActionButton>
                )}
              </div>
            </ActionButtonsGroup>
          </div>
        </div>
        <ContentWrapper>
          {loading ? (
            <TableLoader />
          ) : (
            CheckPermission("READ_TAXGROUP", permissions) && (
              <>
                {filtered !== null ? (
                  <Table
                    columns={columns}
                    dataSource={filteredTaxGroup}
                    // onRow={(r) => ({
                    //   onClick: () => (history.push(`/core/client/${r.key}`))
                    // })}
                  />
                ) : (
                  <Table
                    columns={columns}
                    dataSource={dataSource}
                    pagination={{ defaultPageSize: 8 }}
                    defaultPageSize={8}
                  />
                )}
              </>
            )
          )}
        </ContentWrapper>
      </div>
    </>
  );
};

export default ManageTaxGroupView;
