import styled from "styled-components";

export const ContentWrapper = styled.div`
  background: #fcfcfc;
  border-radius: 10px;
  box-shadow: 0px 2px 0px #ededf6;
  min-height: 80vh;
  margin-top: 20px;
  padding: 50px;

  p {
    font-size: 18px;
    font-weight: 400;
  }

  .pHeader {
    font-size: 24px;
    font-weight: 700;
  }

  .lineHeader {
    font-weight: 20px;
    font-weight: 600;
  }

  .button-right {
    width: 100%;
    height: 50px;
    padding-right: 15px;
  }
`;
