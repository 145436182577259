import API_TRANSACTIONS from "../services/Api/transactions.api.services";
import {
  SAVINGS_TRANSACTIONS_FAIL,
  SAVINGS_TRANSACTIONS_REQUEST,
  SAVINGS_TRANSACTIONS_SUCCESS,
  LOAN_TRANSACTIONS_FAIL,
  LOAN_TRANSACTIONS_REQUEST,
  LOAN_TRANSACTIONS_SUCCESS,
  CLEAR_SAVINGS,
  CLEAR_LOAN,
} from "../constants/transactionsConstants";
import Toast from "../util/Toast";
import {
  SET_LOADING_STATE,
  SET_TABLE_LOADING_STATE,
} from "../constants/utilityConstants";
import {
  getOneSavingsAccountTransactions,
  getOneLoanAccountTransactions,
} from "../services/Api/savings.loan.api.service";

const savingsAccountTransRequestFail = (payload) => {
  return { type: SAVINGS_TRANSACTIONS_FAIL, payload };
};

//RESET SAVINGS STATE
const clearSavings = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_SAVINGS });
  };
};

//Get a savings accounts transactions
const getSavingsAccountTransactions = (accountNum) => {
  return async (dispatch) => {
    dispatch({ type: SAVINGS_TRANSACTIONS_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_TRANSACTIONS.getSavingsAccountTransactions(accountNum)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        if (response === "No account found") {
          dispatch(Toast({ text: response, icon: "error" }));
          dispatch({
            type: SAVINGS_TRANSACTIONS_SUCCESS,
            payload: { transactions: [] },
          });
        } else {
          dispatch({ type: SAVINGS_TRANSACTIONS_SUCCESS, payload: response });
        }
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(savingsAccountTransRequestFail(ex));
      });
    dispatch({ type: SET_LOADING_STATE, payload: false });
  };
};

const getSavingsAccountTransactionsPagination = (id, dataToSend, params) => {
  return async (dispatch) => {
    dispatch({ type: SAVINGS_TRANSACTIONS_SUCCESS });
    dispatch({ type: SET_TABLE_LOADING_STATE, payload: true });
    await getOneSavingsAccountTransactions(id, dataToSend, params)
      .then((response) => {
        dispatch({
          type: SAVINGS_TRANSACTIONS_SUCCESS,
          payload: response,
        });
        dispatch({ type: SET_TABLE_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch({ type: SET_TABLE_LOADING_STATE, payload: false });
      });
  };
};

const getLoanAccountTransactionsPagination = (id, dataToSend, params) => {
  return async (dispatch) => {
    dispatch({ type: LOAN_TRANSACTIONS_SUCCESS });
    dispatch({ type: SET_TABLE_LOADING_STATE, payload: true });
    await getOneLoanAccountTransactions(id, dataToSend, params)
      .then((response) => {
        dispatch({
          type: LOAN_TRANSACTIONS_SUCCESS,
          payload: response,
        });
        dispatch({ type: SET_TABLE_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch({ type: SET_TABLE_LOADING_STATE, payload: false });
      });
  };
};

const loanAccountTransRequestFail = (payload) => {
  return { type: LOAN_TRANSACTIONS_FAIL, payload };
};
//RESET LOAN STATE
const clearLoan = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_LOAN });
  };
};
//Get a loan accounts transactions
const getLoanAccountTransactions = (accountNum) => {
  return async (dispatch) => {
    dispatch({ type: LOAN_TRANSACTIONS_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_TRANSACTIONS.getLoanAccountTransactions(accountNum)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        if (response === "No account found") {
          dispatch(Toast({ text: response, icon: "error" }));
          dispatch({
            type: LOAN_TRANSACTIONS_SUCCESS,
            payload: { transactions: [] },
          });
        } else {
          dispatch({ type: LOAN_TRANSACTIONS_SUCCESS, payload: response });
        }
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(loanAccountTransRequestFail(ex));
      });
    dispatch({ type: SET_LOADING_STATE, payload: false });
  };
};

export {
  getSavingsAccountTransactions,
  getLoanAccountTransactions,
  clearSavings,
  clearLoan,
  getSavingsAccountTransactionsPagination,
  getLoanAccountTransactionsPagination,
};
