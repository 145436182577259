import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "react-bootstrap";

import {
  getLoanDisburseDetails,
  handleLoanTransactions,
} from "../../../../../actions/SavingsAndLoanActions";
import { formatCurrency } from "../../../../../util/FormatAmount";

import { formatDate } from "../../../../../util/FormatDate.js";

const LoanDetails = ({ loanDetails }) => {
  const dispatch = useDispatch();
  const savingsAndLoan = useSelector((state) => state.savingsAndLoan);

  let { loanAccountDisburseDetails } = savingsAndLoan || {};

  let {
    amount,
    date = [],
    interestPortion,
    penaltyChargesPortion,
    principalPortion,
    feeChargesPortion,
    // paymentTypeOptions = [],
  } = loanAccountDisburseDetails || {};

  let currentDate = new Date();
  let datetoSend = formatDate(currentDate, true);
  let res = datetoSend.split(" ");

  useEffect(() => {
    !!loanDetails
      ? dispatch(
          getLoanDisburseDetails(
            loanDetails.id,
            `foreclosure&dateFormat=dd+MMM+yyyy&locale=en&transactionDate=${res[0]}+${res[1]}+${res[2]}`
          )
        )
      : (() => {})();
  }, [!!loanDetails ? loanDetails.id : ""]);

  let {
    loanOfficerName,
    externalId,
    currency = {},
    loanPurposeName,
    status = {},
    repaymentSchedule = {},
    timeline = {},
    proposedPrincipal = 0,
    approvedPrincipal = 0,
    principal,
    numberOfRepayments = 0,
    createStandingInstructionAtDisbursement,
    summary = {},
  } = loanDetails || {};


  let {
    totalOverdue = 0,
    feeChargesOverdue = 0,
    interestOverdue = 0,
    penaltyChargesOverdue = 0,
    principalOverdue = 0,
  } = summary || {};

  let {
    totalInterestCharged = 0,
    totalFeeChargesCharged = 0,
    // totalOutstanding = 0,
    totalPenaltyChargesCharged = 0,
    totalPrincipalDisbursed = 0,
    // totalRepayment = 0,
    // totalPrincipalPaid = 0,
    totalRepaymentExpected = 0,
    totalWaived = 0,
    periods = [],
    totalWrittenOff = 0,
  } = repaymentSchedule || {};

  let {
    totalOutstanding = 0,
    // principalOutstanding = 0,
    // interestOutstanding = 0,
    feeChargesOutstanding = 0,
    // penaltyChargesOutstanding = 0,
    totalRepayment = 0,
    // totalExpectedRepayment = 0,

    // principalPaid = 0,
    // interestPaid = 0,
    feeChargesPaid = 0,
    // penaltyChargesPaid = 0,

    // penaltyChargesCharged = 0,
    // feeChargesCharged = 0,
    // interestCharged = 0,
    // principalDisbursed = 0,
  } = summary || {};

  let mainPrincipalPaid = 0;
  let mainPrincipalWaivedOff = 0;
  let mainPrincipalOutstanding = 0;

  let mainInterestPaid = 0;
  let mainInterestWaived = 0;
  let mainInterestWrittenOff = 0;
  let mainInterestOutstanding = 0;

  let mainFeesPaid = 0;
  let mainFeesWaived = 0;
  let mainFeesWrittenOff = 0;
  let mainFeesOutstanding = 0;

  let mainPenaltiesPaid = 0;
  let mainPenaltiesWaived = 0;
  let mainPenaltiesWrittenOff = 0;
  let mainPenaltiesOutstanding = 0;


  periods.forEach((period) => {
    let {
      principalPaid = 0,
      principalOutstanding = 0,
      principalWrittenOff = 0,

      interestPaid = 0,
      interestWaived = 0,
      interestWrittenOff = 0,
      interestOutstanding = 0,

      feeChargesPaid = 0,
      feeChargesWaived = 0,
      feeChargesWrittenOff = 0,
      feeChargesOutstanding = 0,

      penaltyChargesPaid = 0,
      penaltyChargesWaived = 0,
      penaltyChargesWrittenOff = 0,
      penaltyChargesOutstanding = 0,
    } = period || {};
    // For Principal
    mainPrincipalPaid += principalPaid;
    mainPrincipalWaivedOff += principalWrittenOff;
    mainPrincipalOutstanding += principalOutstanding;

    // For Interest
    mainInterestPaid += interestPaid;
    mainInterestWaived += interestWaived;
    mainInterestWrittenOff += interestWrittenOff;
    mainInterestOutstanding += interestOutstanding;


    // For Fees
    mainFeesPaid += feeChargesPaid;
    mainFeesWaived += feeChargesWaived;
    mainFeesWrittenOff += feeChargesWrittenOff;
    mainFeesOutstanding += feeChargesOutstanding;

    // For Penalties
    mainPenaltiesPaid += penaltyChargesPaid;
    mainPenaltiesWaived += penaltyChargesWaived;
    mainPenaltiesWrittenOff += penaltyChargesWrittenOff;
    mainPenaltiesOutstanding += penaltyChargesOutstanding;
  });


  let {
    expectedDisbursementDate = [],
    expectedMaturityDate = [],
    submittedByFirstname = "",
    submittedByLastname = "",
    disbursedByLastname = "",
    disbursedByFirstname = "",
    approvedByLastname = "",
    approvedByFirstname = "",
  } = timeline || {};

  return (
    <>
      <div className="row">
        <div className="col-xl-12 col-md-12">
          {(status.active || status?.closedObligationsMet) ? (
            <div className="m-2">
              <Table
                responsive
                striped
                bordered
                hover
                className="table-form-style"
              >
                <thead className="border-0">
                  <tr>
                    <td className="font-weight-bold md-font-weight"></td>
                    <td>Original</td>
                    <td>Paid</td>
                    <td>Waived</td>
                    <td>Written Off</td>
                    <td>Outstanding</td>
                    <td>Over Due</td>
                    <td>Currency</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Principal
                    </td>
                    <td>{formatCurrency(principal)}</td>
                    <td>{formatCurrency(mainPrincipalPaid)}</td>
                    <td>{formatCurrency(mainPrincipalWaivedOff)}</td>
                    <td>{formatCurrency(totalWrittenOff)}</td>
                    <td>{formatCurrency(mainPrincipalOutstanding)}</td>
                    <td>{formatCurrency(principalOverdue)}</td>
                    <td>{currency.code}</td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Interest
                    </td>
                    <td>{formatCurrency(totalInterestCharged)}</td>
                    <td>{formatCurrency(mainInterestPaid)}</td>
                    <td>{formatCurrency(mainInterestWaived)}</td>
                    <td>{formatCurrency(mainInterestWrittenOff)}</td>
                    <td>{formatCurrency(mainInterestOutstanding)}</td>
                    <td>{formatCurrency(interestOverdue)}</td>
                    <td>{currency.code}</td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold md-font-weight">Fees</td>
                    <td>{formatCurrency(totalFeeChargesCharged)}</td>
                    <td>{formatCurrency(feeChargesPaid)}</td>
                    <td>{formatCurrency(mainFeesWaived)}</td>
                    <td>{formatCurrency(mainFeesWrittenOff)}</td>
                    <td>{formatCurrency(feeChargesOutstanding)}</td>
                    <td>{formatCurrency(feeChargesOverdue)}</td>
                    <td>{currency.code}</td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Penalties
                    </td>
                    <td>{formatCurrency(totalPenaltyChargesCharged)}</td>
                    <td>{formatCurrency(mainPenaltiesPaid)}</td>
                    <td>{formatCurrency(mainPenaltiesWaived)}</td>
                    <td>{formatCurrency(mainPenaltiesWrittenOff)}</td>
                    <td>{formatCurrency(mainPenaltiesOutstanding)}</td>
                    <td>{formatCurrency(penaltyChargesOverdue)}</td>
                    <td>{currency.code}</td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold md-font-weight">Total</td>
                    <td>{formatCurrency(totalRepaymentExpected)}</td>
                    {/* <td>{formatCurrency(totalPrincipalPaid)}</td> */}
                    <td>{formatCurrency(totalRepayment)}</td>
                    <td>{formatCurrency(totalWaived)}</td>
                    <td>{formatCurrency(totalWrittenOff)}</td>
                    <td>{formatCurrency(totalOutstanding)}</td>
                    <td>{formatCurrency(totalOverdue)}</td>
                    <td>{currency.code}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          ) : null}
          {loanDetails ? (
            <div className="m-2 mt-3">
              <Table
                responsive
                striped
                bordered
                hover
                className="table-form-style"
              >
                <thead className="border-0">
                  <tr>
                    <td
                      className="font-weight-bold md-font-weight text-center"
                      colSpan="2"
                    >
                      Loan Details
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Loan Status
                    </td>
                    <td>{status.value}</td>
                  </tr>
                  {status.active && !!expectedDisbursementDate.length ? (
                    <tr>
                      <td className="font-weight-bold md-font-weight">
                        Disbursement Date
                      </td>
                      <td>
                        {expectedDisbursementDate.length ? formatDate(expectedDisbursementDate) : ""}
                      </td>
                    </tr>
                  ) : null}
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Loan Purpose
                    </td>
                    <td>
                      {!!loanPurposeName ? loanPurposeName : "Not Assigned"}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Loan Officer
                    </td>
                    <td>
                      {!!loanOfficerName ? loanOfficerName : "Not Assigned"}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Submitted By
                    </td>
                    <td>
                      {!!submittedByFirstname || !!submittedByLastname
                        ? `${submittedByFirstname} ${submittedByLastname}`
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Approved By
                    </td>
                    <td>
                      {!!approvedByFirstname || !!approvedByLastname
                        ? `${approvedByFirstname} ${approvedByLastname}`
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Disbursed By
                    </td>
                    <td>
                      {!!disbursedByFirstname || !!disbursedByLastname
                        ? `${disbursedByFirstname} ${disbursedByLastname}`
                        : ""}
                    </td>
                  </tr>
                  {status.active && !!currency.displayLabel ? (
                    <tr>
                      <td className="font-weight-bold md-font-weight">
                        Currency
                      </td>
                      <td>
                        {!!currency.displayLabel ? currency.displayLabel : ""}
                      </td>
                    </tr>
                  ) : null}
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      External Id
                    </td>
                    <td>{!!externalId ? externalId : "Non Assigned"}</td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Standing Instruction At disbursement
                    </td>
                    <td>
                      {!!createStandingInstructionAtDisbursement
                        ? "Enabled"
                        : "Disabled"}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Proposed Amount
                    </td>
                    <td>{formatCurrency(proposedPrincipal)}</td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Approved Amount
                    </td>
                    <td>{formatCurrency(approvedPrincipal)}</td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Disburse Amount
                    </td>
                    <td>{formatCurrency(totalPrincipalDisbursed)}</td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      No of Repayments
                    </td>
                    <td>{numberOfRepayments}</td>
                  </tr>
                  {expectedMaturityDate.length ? (
                    <tr>
                      <td className="font-weight-bold md-font-weight">
                        Maturity Date
                      </td>
                      <td>
                        {expectedMaturityDate.length ? formatDate(expectedMaturityDate) : ""}
                      </td>
                    </tr>
                  ) : null}

                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Liquidation Principal Till Date
                    </td>
                    <td>{formatCurrency(parseInt(principalPortion))}</td>
                  </tr>
                  {expectedMaturityDate.length ? (
                    <tr>
                      <td className="font-weight-bold md-font-weight">
                        Liquidation Interest Till Date
                      </td>
                      <td>{formatCurrency(parseInt(interestPortion))}</td>
                    </tr>
                  ) : null}
                </tbody>
              </Table>
            </div>
          ) : null}

          {/* ############# */}

          {/* ############# */}
        </div>

        <div className="col-xl-4 col-md-12">
          <div className="m-2"></div>
        </div>
      </div>
    </>
  );
};

export default LoanDetails;
