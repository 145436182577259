import React from "react";
import { Route } from "react-router-dom";
import DashboardLayout from "./index";

import DashboardOverview from "../../views/dashboard/Overview";

//MAJOR ROUTES
import CustomerView from "../../views/dashboard/Customer";
import AccountingView from "../../views/dashboard/Accounting";
import Transactions from "../../views/dashboard/Transactions";
import Configuration from "../../views/dashboard/Configuration";
import CustomerReport from "../../views/dashboard/Report/CustomerReport";
import FinancialReport from "../../views/dashboard/Report/AccountingReport";
import LoanReport from "../../views/dashboard/Report/LoanReport";
import OrganizationReport from "../../views/dashboard/Report/OrganizationReport";
import RegulationReport from "../../views/dashboard/Report/RegulationReport";
import DepositsReport from "../../views/dashboard/Report/DepositsReport";
import SystemReport from "../../views/dashboard/Report/SystemReport";
import GeneratedReports from "../../views/dashboard/Report/GeneratedReports";
import Profile from "../../views/dashboard/Home/Profile";
import Settings from "../../views/dashboard/Home/Settings";

import TellerGroup from "../../views/dashboard/TellerGroup/TellerGroup/index";
import SingleTellerGroup from "../../views/dashboard/TellerGroup/SingleTellerGroup/index";
import SingleTeller from "../../views/dashboard/TellerGroup/SingleTeller/index";
import TellerRequest from "../../views/dashboard/TellerGroup/TellerRequest/index";
import TellerHistory from "../../views/dashboard/TellerGroup/TellerHistory/index";
// import Tellers from "../../views/dashboard/Configuration/Organization/Tellers";
// import CashierPage from "../../views/dashboard/Configuration/Organization/Tellers/CashierPage";

//ROUTES PERTAINING TO CUSTOMER SCREENS
import SavingsApplicationScreen from "../../views/dashboard/Customer/CustomerInformation/extras/SavingsApplicationScreen";
import LoanApplicationScreen from "../../views/dashboard/Customer/CustomerInformation/extras/LoanApplicationScreen";
import Share from "../../views/dashboard/Customer/CustomerInformation/extras/Share";
import ApplicationFee from "../../views/dashboard/Customer/CustomerInformation/extras/ApplicationFee";
import FixedDeposit from "../../views/dashboard/Customer/CustomerInformation/extras/FixedDeposit";
import BVNValidation from "../../views/dashboard/Customer/BVNValidation";
import ImportCustomer from "../../views/dashboard/Customer/ImportCustomer";
import NewCustomer from "../../views/dashboard/Customer/NewCustomer";
import CustomerInformation from "../../views/dashboard/Customer/CustomerInformation2";
import CustomerSavingsAccountInformation from "../../views/dashboard/Customer/CustomerSavingsAccount";
import CusomerLoanDetails from "../../views/dashboard/Customer/CusomerLoanDetails";
import CustomerFixedDepositAccount from "../../views/dashboard/Customer/CustomerFixedDepositAccount";
import TransferFund from "../../views/dashboard/Customer/CustomerSavingsAccount/TransferFund";

//ROUTES PERTAINING TO CUSTOMER SCREENSGL
import GLTransactions from "../../views/dashboard/Transactions/GLTransactions";
import LoanTransactions from "../../views/dashboard/Transactions/LoanTransactions";
import SavingsTransactions from "../../views/dashboard/Transactions/SavingsTransactions";
import FixedDepositTransactions from "../../views/dashboard/Transactions/FixedDepositTransactions";

//ROUTES PERTAINING TO CONFIGURATION SCREENS
import Users from "../../views/dashboard/Users/UsersManagement";
import CreateNewUser from "../../views/dashboard/Users/UsersManagement/CreateNewUser";
import UserDetails from "../../views/dashboard/Users/UsersManagement/UserDetails";
// import ViewUser from "../../views/dashboard/Configuration/Users/ViewUser";
import Organization from "../../views/dashboard/Configuration/Organization";
import ManageOffices from "../../views/dashboard/Configuration/Organization/ManageOffices";
import CurrencyConfiguration from "../../views/dashboard/Utilities/CurrencyConfiguration";
import ManageHolidays from "../../views/dashboard/Configuration/Organization/ManageHolidays";
import ManageFunds from "../../views/dashboard/Configuration/Organization/ManageFunds";
import BulkLoanReassignment from "../../views/dashboard/Configuration/Organization/BulkLoanReassignment";
import CashierManagement from "../../views/dashboard/Configuration/Organization/CashierManagement";
import StandingInstructionsHistory from "../../views/dashboard/Configuration/Organization/StandingInstructionsHistory";
import LoanCalculator from "../../views/dashboard/Configuration/Organization/LoanCalculator";
import WorkingDays from "../../views/dashboard/Configuration/Organization/WorkingDays";
import PasswordPreference from "../../views/dashboard/Configuration/Organization/PasswordPreference";
import PaymentType from "../../views/dashboard/Configuration/Organization/PaymentType";
import SMSCampaign from "../../views/dashboard/Configuration/Organization/SMSCampaign";
import CreateCampaign from "../../views/dashboard/Configuration/Organization/SMSCampaign/CreateCampaign";
import EntityDataTable from "../../views/dashboard/Configuration/Organization/EntityDataTable";
import AdHocQuery from "../../views/dashboard/Configuration/Organization/AdHocQuery";
import BulkImport from "../../views/dashboard/Utilities/BulkImport";
import AuditTrails from "../../views/dashboard/Audit/AuditTrails";
import AuditTrailDetails from "../../views/dashboard/Audit/AuditTrails/AuditTrailDetails";
import UserAudit from "../../views/dashboard/Audit/UserAudit";
import OrgConfiguration from "../../views/dashboard/Configuration/Organization/Configuration";
import Products from "../../views/dashboard/Utilities/Products";
import LoanProducts from "../../views/dashboard/Utilities/Products/LoanProducts";
import LoanProductDetails from "../../views/dashboard/Utilities/Products/LoanProducts/LoanProductDetails";
import CreateLoanProduct from "../../views/dashboard/Utilities/Products/LoanProducts/CreateLoanProduct";
import EditLoanProduct from "../../views/dashboard/Utilities/Products/LoanProducts/EditLoanProduct";
import FixedDepositProducts from "../../views/dashboard/Utilities/Products/FixedDepositProducts";
import FixedDepositProductDetails from "../../views/dashboard/Utilities/Products/FixedDepositProducts/FixedDepositProductDetails";
import CreateFixedDepositProduct from "../../views/dashboard/Utilities/Products/FixedDepositProducts/CreateFixedDepositProduct";
import EditFixedProduct from "../../views/dashboard/Utilities/Products/FixedDepositProducts/EditFixedProduct";
import Charges from "../../views/dashboard/Utilities/Charges";
import CreateCharges from "../../views/dashboard/Utilities/Charges/CreateCharges";
import EditCharges from "../../views/dashboard/Utilities/Charges/EditCharges";
import ManageTaxView from "../../views/dashboard/Utilities/Products/ManageTax";
import ManageTaxComponent from "../../views/dashboard/Utilities/Products/ManageTax/ManageTaxComponent";
import ManageTaxGroup from "../../views/dashboard/Utilities/Products/ManageTax/ManageTaxGroup";
import SavingsProducts from "../../views/dashboard/Utilities/Products/SavingsProducts";
import CreateSavingsProduct from "../../views/dashboard/Utilities/Products/SavingsProducts/CreateSavingsProduct";
import SavingsProductDetails from "../../views/dashboard/Utilities/Products/SavingsProducts/SavingsProductDetails";
import EditSavingsProduct from "../../views/dashboard/Utilities/Products/SavingsProducts/EditSavingsProduct";
// import EditSavingsProduct from "../../views/dashboard/Configuration/Products/FixedDepositProducts/CreateFixedDepositProduct";
import ManageDataTables from "../../views/dashboard/Utilities/ManageDataTables";
import CreateDataTable from "../../views/dashboard/Utilities/ManageDataTables/components/CreateDataTable";
import EditDataTable from "../../views/dashboard/Utilities/ManageDataTables/components/EditDataTable";
import DataTableDetails from "../../views/dashboard/Utilities/ManageDataTables/components/DataTableDetails";

// ROUTES PERTAINING TO ACCOUNTING SCREENS
import AccountingClosures from "../../views/dashboard/Accounting/AccountingClosures";
import AccountClosureDetails from "../../views/dashboard/Accounting/AccountingClosures/AccountClosureDetails";
import AccountingRules from "../../views/dashboard/Accounting/AccountingRules";
import CreateAccountRule from "../../views/dashboard/Accounting/AccountingRules/CreateAccountRule";
import AccountRuleDetail from "../../views/dashboard/Accounting/AccountingRules/AccountRuleDetail";
import AddJournalEntries from "../../views/dashboard/Accounting/AddJournalEntries";
import ChartsOfAccount from "../../views/dashboard/Accounting/ChartsOfAccount";
import CreateGlAccount from "../../views/dashboard/Accounting/ChartsOfAccount/CreateGlAccount";
import EditGlAccount from "../../views/dashboard/Accounting/ChartsOfAccount/EditGlAccount";
import GlAccountInfo from "../../views/dashboard/Accounting/ChartsOfAccount/AccountInfo";
import FinancialActivities from "../../views/dashboard/Accounting/FinancialActivities";
import FinancialActivityDetail from "../../views/dashboard/Accounting/FinancialActivities/FinancialActivityDetail";
import FrequentPosting from "../../views/dashboard/Accounting/FrequentPosting";
import ProvisioningEntries from "../../views/dashboard/Accounting/ProvisioningEntries";
import SearchJournalEntries from "../../views/dashboard/Accounting/SearchJournalEntries";
import SearchAccountToJournalEntryDetails from "../../views/dashboard/Accounting/SearchJournalEntries/AccountToJournalEntryDetails";
import JournalEntryDetails from "../../views/dashboard/Accounting/AddJournalEntries/JournalEntryDetails";
import Amortizations from "../../views/dashboard/AssetManagement/Amortizations";
import CreateAmortization from "../../views/dashboard/AssetManagement/Amortizations/CreateAmortization";
import Placements from "../../views/dashboard/Accounting/Placements";
import CreatePlacement from "../../views/dashboard/Accounting/Placements/CreatePlacement";
import AccountToJournalEntry from "../../views/dashboard/Accounting/AddAccountJournalEntry";
import AccountToJournalEntryDetails from "../../views/dashboard/Accounting/AddAccountJournalEntry/AccountToJournalEntryDetails";

// Notification Screens
import NotificationView from "../../views/dashboard/Notification";
import Termii from "../../views/dashboard/Notification/Termii";

// Manage Utilities Screen
import ManageUtility from "../../views/dashboard/ManageUtility";
import ManageRolesPermissions from "../../views/dashboard/Users/ManageRolesPermissions";
import ManageEmployees from "../../views/dashboard/Users/ManageEmployees";
import ViewRole from "../../views/dashboard/Users/ManageRolesPermissions/ViewRole";
import ManageHooks from "../../views/dashboard/ManageUtility/ManageHooks";
import ExternalServices from "../../views/dashboard/ManageUtility/ExternalServices";
import S3 from "../../views/dashboard/ManageUtility/ExternalServices/S3";
import SMTP from "../../views/dashboard/ManageUtility/ExternalServices/SMTP";
import SMS from "../../views/dashboard/ManageUtility/ExternalServices/SMS";
import Notification from "../../views/dashboard/ManageUtility/ExternalServices/Notification";
import MCTasks from "../../views/dashboard/ManageUtility/MCTasks";

import SavingsAccount from "../../views/dashboard/Accounts/SavingsAccount";
import LoanAccount from "../../views/dashboard/Accounts/LoanAccount";
import FixedDepositAccount from "../../views/dashboard/Accounts/FixedDepositAccount";

import Checkers from "../../views/dashboard/Business/Checkers";
import CheckerDetails from "../../views/dashboard/Business/Checkers/checkerDetails/index";
import BulkActions from "../../views/dashboard/Business/BulkActions";
import EditSavingsAccount from "../../views/dashboard/Customer/CustomerInformation/components/accounts/EditSavingsAccount";
import EditLoanAccount from "../../views/dashboard/Customer/CustomerInformation/components/loans/EditLoanAccount";
import Tier from "../../views/dashboard/Configuration/Organization/Tier";
import NibssView from "../../views/dashboard/NibssConnector";
import NibssCustomerView from "../../views/dashboard/NibssConnector/ManageNibssAccount";
import NibssReportView from "../../views/dashboard/NibssConnector/NibssReport";
import TransactionReportView from "../../views/dashboard/NibssConnector/TransactionReport";
import InflowTransactionDetails from "../../views/dashboard/NibssConnector/Tab/InflowTransactionDetails";
import OutflowTransactionDetails from "../../views/dashboard/NibssConnector/Tab/OutflowTransactionDetails";
import InflowSingleDetails from "../../views/dashboard/NibssConnector/ManageNibssAccount/ManageSingleTransactionInflow";
import OutflowSingleDetails from "../../views/dashboard/NibssConnector/ManageNibssAccount/ManageSingleTransactionOutflow";
import SettlementView from "../../views/dashboard/SettlementEOY";
import CompleteEOY from "../../views/dashboard/SettlementEOY/CompleteEOY";
import EditFixedDepositAccount from "../../views/dashboard/Customer/CustomerInformation/components/fixedDeposit/EditFixedDepositAccount";
import CheckPermission from "../../util/CheckPermission";
import { useSelector } from "react-redux";
import { CheckPerm } from "../../util/CheckPerm";

const DashboardContainer = () => {
  const userDataRes = useSelector((state) => state.preAuth);
  const { responseUserData: { permissions } } = userDataRes;

  return (
    <DashboardLayout>
      <Route exact path="/core" component={DashboardOverview} />
      <Route
        path="/core/clients"
        component={(props) => <CustomerView {...props} />}
      />
      <Route
        exact
        path="/core/accounting"
        component={(props) => <AccountingView {...props} />}
      />
      <Route
        exact
        path="/core/checkers"
        component={(props) => <Checkers {...props} />}
      />
      <Route
        exact
        path="/core/checkers/:id"
        component={(props) => <CheckerDetails {...props} />}
      />
      <Route
        exact
        path="/core/bulk-actions"
        component={(props) => <BulkActions {...props} />}
      />
      <Route
        exact
        path="/core/teller-group"
        component={(props) => <TellerGroup {...props} />}
      />
      <Route
        exact
        path="/core/teller-group/:id"
        component={(props) => <SingleTellerGroup {...props} />}
      />
      <Route
        exact
        path="/core/teller-group/:id/teller/:activeId"
        component={(props) => <SingleTeller {...props} />}
      />
      <Route
        exact
        path="/core/teller-request"
        component={(props) => <TellerRequest {...props} />}
      />
      <Route
        exact
        path="/core/teller-history"
        component={(props) => <TellerHistory {...props} />}
      />
      <Route
        exact
        path="/core/transactions"
        component={(props) => <Transactions {...props} />}
      />
      <Route
        exact
        path="/core/configuration"
        component={(props) => <Configuration {...props} />}
      />
      <Route
        exact
        path="/core/customer-reports"
        component={(props) => <CustomerReport {...props} />}
      />
      <Route
        exact
        path="/core/financial-reports"
        component={(props) => <FinancialReport {...props} />}
      />
      <Route
        exact
        path="/core/loan-reports"
        component={(props) => <LoanReport {...props} />}
      />
      <Route
        exact
        path="/core/organization-reports"
        component={(props) => <OrganizationReport {...props} />}
      />
      <Route
        exact
        path="/core/regulation-reports"
        component={(props) => <RegulationReport {...props} />}
      />
      <Route
        exact
        path="/core/deposits-reports"
        component={(props) => <DepositsReport {...props} />}
      />
      <Route
        exact
        path="/core/system-reports"
        component={(props) => <SystemReport {...props} />}
      />
      <Route
        exact
        path="/core/manage-utilities"
        component={(props) => <ManageUtility {...props} />}
      />
      <Route
        exact
        path="/core/loan-accounts"
        component={(props) => <LoanAccount {...props} />}
      />
      <Route
        exact
        path="/core/savings-accounts"
        component={(props) => <SavingsAccount {...props} />}
      />
      <Route
        exact
        path="/core/fixed-deposit-accounts"
        component={(props) => <FixedDepositAccount {...props} />}
      />
      <Route
        exact
        path="/core/roles-management"
        component={(props) => <ManageRolesPermissions {...props} />}
      />
      <Route
        exact
        path="/core/roles-management/view-role/:id"
        component={(props) => <ViewRole {...props} />}
      />
      <Route
        exact
        path="/core/manage-utilities/manage-hooks"
        component={(props) => <ManageHooks {...props} />}
      />
      <Route
        exact
        path="/core/manage-utilities/external-services"
        component={(props) => <ExternalServices {...props} />}
      />
      <Route
        exact
        path="/core/manage-utilities/external-services/s3"
        component={(props) => <S3 {...props} />}
      />
      <Route
        exact
        path="/core/manage-utilities/external-services/smtp"
        component={(props) => <SMTP {...props} />}
      />
      <Route
        exact
        path="/core/manage-utilities/external-services/sms"
        component={(props) => <SMS {...props} />}
      />
      <Route
        exact
        path="/core/manage-utilities/external-services/notification"
        component={(props) => <Notification {...props} />}
      />
      <Route
        exact
        path="/core/mc-tasks"
        component={(props) => <MCTasks {...props} />}
      />
      <Route
        exact
        path="/core/notification"
        component={(props) => <NotificationView {...props} />}
      />
      <Route
        exact
        path="/core/notification/termii"
        component={(props) => <Termii {...props} />}
      />
      <Route
        exact
        path="/core/report/generated-reports"
        component={(props) => <GeneratedReports {...props} />}
      />
      <Route
        exact
        path="/core/settings"
        component={(props) => <Settings {...props} />}
      />
      <Route
        exact
        path="/core/profile"
        component={(props) => <Profile {...props} />}
      />{" "}
      {/* <Route
        exact
        path="/core/configuration/organization/tellers"
        component={(props) => <Tellers {...props} />}
      />
      <Route
        exact
        path="/core/configuration/organization/tellers/cashier"
        component={(props) => <CashierPage {...props} />}
      /> */}
      <Route
        exact
        path="/core/new-client"
        component={(props) => <NewCustomer {...props} />}
      />
      <Route
        exact
        path="/core/client/:id"
        component={(props) => <CustomerInformation {...props} />}
      />
      <Route
        exact
        path="/core/client/:id/deposits/:savingsId"
        component={(props) => <CustomerInformation {...props} />}
      />
      <Route
        exact
        path="/core/client/savings-account/:id"
        component={(props) => <CustomerSavingsAccountInformation {...props} />}
      />
      <Route
        exact
        path="/core/client/savings-account/transfer-fund/:id"
        component={(props) => <TransferFund {...props} />}
      />
      <Route
        exact
        path="/core/client/loan/:id"
        component={(props) => <CusomerLoanDetails {...props} />}
      />{" "}
      <Route
        exact
        path="/core/client/fixed-deposit-account/:id"
        component={(props) => <CustomerFixedDepositAccount {...props} />}
      />
       <Route
        exact
        path="/core/client/edit-fixed-deposit-account/:id"
        component={(props) => <EditFixedDepositAccount {...props} />}
      />
      <Route
        exact
        path="/core/client/edit-savings-account/:id"
        component={(props) => <EditSavingsAccount {...props} />}
      />
      <Route
        exact
        path="/core/client/edit-loan-account/:id"
        component={(props) => <EditLoanAccount {...props} />}
      />
      <Route
        exact
        path="/core/client/:id/:routeFrom/:currentId/routeFrom"
        component={(props) => <CustomerInformation {...props} />}
      />
      <Route
        exact
        path="/core/loan-application/:id"
        component={(props) => <LoanApplicationScreen {...props} />}
      />
      <Route
        exact
        path="/core/savings-application/:id"
        component={(props) => <SavingsApplicationScreen {...props} />}
      />
      <Route
        exact
        path="/core/application-fee"
        component={(props) => <ApplicationFee {...props} />}
      />
      <Route
        exact
        path="/core/share"
        component={(props) => <Share {...props} />}
      />
      <Route
        exact
        path="/core/fixed-deposit-application/:id"
        component={(props) => <FixedDeposit {...props} />}
      />
      <Route
        exact
        path="/core/bvn-validation"
        component={(props) => <BVNValidation {...props} />}
      />
      <Route
        exact
        path="/core/import-client"
        component={(props) => <ImportCustomer {...props} />}
      />
      <Route
        exact
        path="/core/transactions/gl-transaction"
        component={(props) => <GLTransactions {...props} />}
      />
      <Route
        exact
        path="/core/transactions/loan-transaction"
        component={(props) => <LoanTransactions {...props} />}
      />
      <Route
        exact
        path="/core/transactions/savings-transaction"
        component={(props) => <SavingsTransactions {...props} />}
      />
      <Route
        exact
        path="/core/transactions/fixed-deposit-transaction"
        component={(props) => <FixedDepositTransactions {...props} />}
      />
      <Route
        exact
        path="/core/user-management"
        component={(props) => <Users {...props} />}
      />
      <Route
        exact
        path="/core/user-management/new-user"
        component={(props) => <CreateNewUser {...props} />}
      />
      <Route
        exact
        path="/core/user-management/details/:id"
        component={(props) => <UserDetails {...props} />}
      />
      <Route
        exact
        path="/core/configuration/organization"
        component={(props) => <Organization {...props} />}
      />
      <Route
        exact
        path="/core/configuration/organization/manage-offices"
        component={(props) => <ManageOffices {...props} />}
      />
      <Route
        exact
        path="/core/currency-management"
        component={(props) => <CurrencyConfiguration {...props} />}
      />
      <Route
        exact
        path="/core/configuration/organization/manage-holidays"
        component={(props) => <ManageHolidays {...props} />}
      />
      <Route
        exact
        path="/core/configuration/organization/manage-funds"
        component={(props) => <ManageFunds {...props} />}
      />
      <Route
        exact
        path="/core/employee-management"
        component={(props) => <ManageEmployees {...props} />}
      />
      <Route
        exact
        path="/core/configuration/organization/bulk-loan-reassignment"
        component={(props) => <BulkLoanReassignment {...props} />}
      />
      <Route
        exact
        path="/core/configuration/organization/cashier-management"
        component={(props) => <CashierManagement {...props} />}
      />
      <Route
        exact
        path="/core/configuration/organization/standing-order-history"
        component={(props) => <StandingInstructionsHistory {...props} />}
      />{" "}
      <Route
        exact
        path="/core/configuration/organization/loan-calculator"
        component={(props) => <LoanCalculator {...props} />}
      />
      <Route
        exact
        path="/core/configuration/organization/working-days"
        component={(props) => <WorkingDays {...props} />}
      />
      <Route
        exact
        path="/core/configuration/organization/password-preference"
        component={(props) => <PasswordPreference {...props} />}
      />
      <Route
        exact
        path="/core/configuration/organization/payment-type"
        component={(props) => <PaymentType {...props} />}
      />
      <Route
        exact
        path="/core/configuration/organization/tier"
        component={(props) => <Tier {...props} />}
      />
      <Route
        exact
        path="/core/configuration/organization/sms-campaign"
        component={(props) => <SMSCampaign {...props} />}
      />
      <Route
        exact
        path="/core/configuration/organization/sms-campaign/new-campaign"
        component={(props) => <CreateCampaign {...props} />}
      />
      <Route
        exact
        path="/core/configuration/organization/entity-data-table"
        component={(props) => <EntityDataTable {...props} />}
      />
      <Route
        exact
        path="/core/configuration/organization/adhocquery"
        component={(props) => <AdHocQuery {...props} />}
      />
      <Route
        exact
        path="/core/bulk-import"
        component={(props) => <BulkImport {...props} />}
      />
      <Route
        exact
        path="/core/audit-trail"
        component={(props) => <AuditTrails {...props} />}
      />
      <Route
        exact
        path="/core/audit-trail/:id"
        component={(props) => <AuditTrailDetails {...props} />}
      />
      <Route
        exact
        path="/core/user-audit"
        component={(props) => <UserAudit {...props} />}
      />
      <Route
        exact
        path="/core/configuration/organization/configuration"
        component={(props) => <OrgConfiguration {...props} />}
      />
      <Route
        exact
        path="/core/manage-data-tables"
        component={(props) => <ManageDataTables {...props} />}
      />{" "}
      <Route
        exact
        path="/core/manage-data-tables/create"
        component={(props) => <CreateDataTable {...props} />}
      />
      <Route
        exact
        path="/core/manage-data-tables/edit/:id"
        component={(props) => <EditDataTable {...props} />}
      />
      <Route
        exact
        path="/core/manage-data-tables/details/:id"
        component={(props) => <DataTableDetails {...props} />}
      />
      <Route
        exact
        path="/core/products"
        component={(props) => <Products {...props} />}
      />
      <Route
        exact
        path="/core/products/loan-products"
        component={(props) => <LoanProducts {...props} />}
      />
      <Route
        exact
        path="/core/products/loan-products/create-loan-product"
        component={(props) => <CreateLoanProduct {...props} />}
      />
      <Route
        exact
        path="/core/products/loan-products/edit-loan-product/:id"
        component={(props) => <EditLoanProduct {...props} />}
      />
      <Route
        exact
        path="/core/products/loan-products/details/:id"
        component={(props) => <LoanProductDetails {...props} />}
      />
      <Route
        exact
        path="/core/products/fixed-deposit-products"
        component={(props) => <FixedDepositProducts {...props} />}
      />{" "}
      <Route
        exact
        path="/core/products/fixed-deposit-products/details/:id"
        component={(props) => <FixedDepositProductDetails {...props} />}
      />
      <Route
        exact
        path="/core/products/fixed-deposit-products/create-fixed-deposit-product"
        component={(props) => <CreateFixedDepositProduct {...props} />}
      />
      <Route
        exact
        path="/core/products/fixed-deposit-products/edit-fixed-deposit-product/:id"
        component={(props) => <EditFixedProduct {...props} />}
      />
      <Route
        exact
        path="/core/charges"
        component={(props) => <Charges {...props} />}
      />
      <Route
        exact
        path="/core/charges/create-charges"
        component={(props) => <CreateCharges {...props} />}
      />
      <Route
        exact
        path="/core/charges/edit-charges/:id"
        component={(props) => <EditCharges {...props} />}
      />
      <Route
        exact
        path="/core/manage-tax-configuration"
        component={(props) => <ManageTaxView {...props} />}
      />
      <Route
        exact
        path="/core/manage-tax-configuration/manage-tax-components"
        component={(props) => <ManageTaxComponent {...props} />}
      />
      <Route
        exact
        path="/core/manage-tax-configuration/manage-tax-groups"
        component={(props) => <ManageTaxGroup {...props} />}
      />
      <Route
        exact
        path="/core/products/savings-products"
        component={(props) => <SavingsProducts {...props} />}
      />
      <Route
        exact
        path="/core/products/savings-products/create-savings-product"
        component={(props) => <CreateSavingsProduct {...props} />}
      />
      <Route
        exact
        path="/core/products/savings-products/edit-savings-product/:id"
        component={(props) => <EditSavingsProduct {...props} />}
      />{" "}
      <Route
        exact
        path="/core/products/savings-products/details/:id"
        component={(props) => <SavingsProductDetails {...props} />}
      />
      <Route
        exact
        path="/core/accounting/closing-entries"
        component={(props) => <AccountingClosures {...props} />}
      />{" "}
      <Route
        exact
        path="/core/amortizations"
        component={(props) => <Amortizations {...props} />}
      />{" "}
      <Route
        exact
        path="/core/accounting/account-to-journal-entry"
        component={(props) => <AccountToJournalEntry {...props} />}
      />{" "}
      <Route
        exact
        path="/core/accounting/account-to-journal-entry/details/:id"
        component={(props) => <AccountToJournalEntryDetails {...props} />}
      />{" "}
      <Route
        exact
        path="/core/amortizations/create"
        component={(props) => <CreateAmortization {...props} />}
      />{" "}
      <Route
        exact
        path="/core/accounting/placement"
        component={(props) => <Placements {...props} />}
      />{" "}
      <Route
        exact
        path="/core/accounting/placement/create"
        component={(props) => <CreatePlacement {...props} />}
      />
      <Route
        exact
        path="/core/accounting/rules"
        component={(props) => <AccountingRules {...props} />}
      />
      <Route
        exact
        path="/core/accounting/rules/create"
        component={(props) => <CreateAccountRule {...props} />}
      />
      <Route
        exact
        path="/core/accounting/rules/detail"
        component={(props) => <AccountRuleDetail {...props} />}
      />
      <Route
        exact
        path="/core/add-journal-entries"
        component={(props) => <AddJournalEntries {...props} />}
      />
      <Route
        exact
        path="/core/journal-entries/details/:id"
        component={(props) => <JournalEntryDetails {...props} />}
      />
      <Route
        exact
        path="/core/charts-of-account"
        component={(props) => <ChartsOfAccount {...props} />}
      />{" "}
      <Route
        exact
        path="/core/accounting/financial-activities"
        component={(props) => <FinancialActivities {...props} />}
      />{" "}
      <Route
        exact
        path="/core/accounting/financial-activities/detail"
        component={(props) => <FinancialActivityDetail {...props} />}
      />{" "}
      <Route
        exact
        path="/core/accounting/frequent-posting"
        component={(props) => <FrequentPosting {...props} />}
      />{" "}
      <Route
        exact
        path="/core/accounting/provisioning-entries"
        component={(props) => <ProvisioningEntries {...props} />}
      />{" "}
      <Route
        exact
        path="/core/search-journal-entries"
        component={(props) => <SearchJournalEntries {...props} />}
      />
      <Route
        exact
        path="/core/search-journal-entries/details/:id"
        component={(props) => <SearchAccountToJournalEntryDetails {...props} />}
      />
      <Route
        exact
        path="/core/charts-of-account/create-gl-account"
        component={(props) => <CreateGlAccount {...props} />}
      />
      <Route
        exact
        path="/core/charts-of-account/edit-gl-account/:id"
        component={(props) => <EditGlAccount {...props} />}
      />{" "}
      <Route
        exact
        path="/core/charts-of-account/account-info"
        component={(props) => <GlAccountInfo {...props} />}
      />
      <Route
        exact
        path="/core/accounting/closing-entries/details"
        component={(props) => <AccountClosureDetails {...props} />}
      />
      <Route
        exact
        path="/core/nibss-management"
        component={(props) => <NibssView {...props} />}
      />
      <Route
        exact
        path="/core/nibss-management/manage-account"
        component={(props) => <NibssCustomerView {...props} />}
      />
      <Route
        exact
        path="/core/nibss-management/manage-account/inflowtransactiondetails/:id"
        component={(props) => <InflowSingleDetails {...props} />}
      />
      <Route
        exact
        path="/core/nibss-management/manage-account/outflowtransactiondetails/:id"
        component={(props) => <OutflowSingleDetails {...props} />}
      />
      <Route
        exact
        path="/core/nibss-management/transaction-report"
        component={(props) => <TransactionReportView {...props} />}
      />
      <Route
        exact
        path="/core/nibss-management/transaction-report/inflowdetails/:id"
        component={(props) => <InflowTransactionDetails {...props} />}
      />
      <Route
        exact
        path="/core/nibss-management/transaction-report/outflowdetails/:id"
        component={(props) => <OutflowTransactionDetails {...props} />}
      />
      <Route
        exact
        path="/core/nibss-management/nibss-report"
        component={(props) => <NibssReportView {...props} />}
      />
      {CheckPerm("CREATE_END_OF_YEAR", permissions) && (
        <>
          <Route
            exact
            path="/core/settlement"
            component={(props) => <SettlementView {...props} />}
          />
          <Route
            exact
            path="/core/settlement/completeEoy"
            component={(props) => <CompleteEOY {...props} />}
          />
        </>
      )}
    </DashboardLayout>
  );
};

export default DashboardContainer;
