import {
  SET_SAVINGS_ACCOUNTS,
  SET_SAVINGS_ACCOUNTS_TEMPLATE,
  SET_SAVINGS_ACCOUNTS_SEARCH,
  SET_LOAN_ACCOUNTS,
  SET_LOAN_ACCOUNTS_TEMPLATE,
  SET_LOAN_ACCOUNTS_SEARCH,
} from "../constants/accountConstants";
import { SET_LOADING_STATE } from "../constants/accountingConstants";

const initialState = {
  isLoading: false,
  savingsAccounts: { pageItems: [], totalFilteredRecords: 0 },
  filteredSavings: null,
  savingsAccountTemplate: {},
  loanAccounts: { pageItems: [], totalFilteredRecords: 0 },
  filteredLoan: null,
  loanAccountTemplate: [],
};

function setAccountReducers(state = initialState, action) {
  switch (action.type) {
    case SET_SAVINGS_ACCOUNTS: {
      return {
        ...state,
        savingsAccounts: action.payload,
      };
    }
    case SET_LOADING_STATE: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case SET_SAVINGS_ACCOUNTS_SEARCH: {
      return {
        ...state,
        savingsAccounts: action.payload,
      };
    }
    case SET_LOAN_ACCOUNTS_SEARCH: {
      return {
        ...state,
        loanAccounts: action.payload,
      };
    }
    case SET_SAVINGS_ACCOUNTS_TEMPLATE: {
      return {
        ...state,
        savingsAccountTemplate: action.payload,
      };
    }
    case SET_LOAN_ACCOUNTS: {
      return {
        ...state,
        loanAccounts: action.payload,
      };
    }
    case SET_LOAN_ACCOUNTS_TEMPLATE: {
      return {
        ...state,
        loanAccountTemplate: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

export default setAccountReducers;
