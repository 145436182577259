import React from "react";
// import { useSelector } from "react-redux";
import { Select, Input, Textarea } from "@chakra-ui/react";

import { FormClass, FormElements, NextActionButton } from "../../../styles.jsx";
import ReactSelect from "react-select";
import { REACT_SELECT_CUSTOM_STYlES } from "../../../../../../../../constants/formConstants.js";

const FixedDetails = ({
  handleNextScreen,
  name,
  currencyCode,
  shortName,
  inMultiplesOf,
  description,
  digitsAfterDecimal,
  createFixedDepositProductHandler,
  createFixedDepositProductHandlerForSelect,
  template,
  // fdTemplate,
  renderTooltip,
}) => {
  //   const productOptionData = useSelector(
  //     (state) => state.loanProductOptions.productData
  //   );
  //   const { productOptions } = productOptionData;

  //   const selectedLoanData = useSelector((state) => state.selectedLoanData);
  //   const { productData } = selectedLoanData;

  //   const { loanOfficerOptions, accountLinkingOptions } = productData;

  return (
    <>
      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-end">
                <label className="text-label d-flex flex-align-center">
                  Product Name* {renderTooltip("Product Name")}
                </label>
                <Input
                  style={{ width: "60%" }}
                  size="md"
                  name="name"
                  value={name}
                  onChange={createFixedDepositProductHandler}
                />
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-end">
                <label className="text-label d-flex flex-align-center">
                  Currency* {renderTooltip("Currency")}
                </label>
                <div className="w-100" style={{ maxWidth: '60%' }}>
                  <ReactSelect
                    isClearable={true}
                    className="fs-12"
                    styles={REACT_SELECT_CUSTOM_STYlES}
                    name="currencyCode"
                    placeholder="Select a currency"
                    onChange={(currency, { name }) => createFixedDepositProductHandlerForSelect(name, currency?.value)}
                    value={{
                      value: currencyCode,
                      label: template
                        ?.currencyOptions
                        ?.find((currency) => currency?.code === currencyCode)
                        ?.displayLabel,
                    }}
                    options={template
                      ?.currencyOptions
                      ?.map((item) => ({
                        label: item.displayLabel,
                        value: item.code,
                      }))
                    }
                  />
                </div>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>

      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-end">
                <label className="text-label d-flex flex-align-center">
                  Short Name* {renderTooltip("Short Name")}
                </label>
                <Input
                  style={{ width: "60%" }}
                  size="md"
                  name="shortName"
                  maxLength="4"
                  value={shortName}
                  onChange={createFixedDepositProductHandler}
                />
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-start">
                <label className="text-label d-flex flex-align-center pt-1">
                  Description*
                  {renderTooltip("Description")}
                </label>
                <Textarea
                  style={{ width: "60%" , fontSize: "12px"}}
                  size="md"
                  name="description"
                  value={description}
                  onChange={createFixedDepositProductHandler}
                />
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>
      <div>
        <div className="float-right pt-5">
          <NextActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={handleNextScreen}
          >
            <img
              src={
                require("../../../../../../../../assets/images/icons/arrow-right.svg")
                  .default
              }
              alt="submit icon"
            />
            Next
          </NextActionButton>
        </div>
      </div>
    </>
  );
};

export default FixedDetails;
