import React, { useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import { Select, Radio, RadioGroup, Stack } from "@chakra-ui/react";
import ReactSelect from 'react-select';
import {
  FormClass,
  FormElements,
  NextActionButton,
  AddActionButton,
  FormTitle,
  IconButton,
} from "../styles.jsx";
import Toast from "../../../../../../util/Toast.js";
import { formatCurrency } from "../../../../../../util/FormatAmount";
import { useDispatch } from "react-redux";
import { REACT_SELECT_CUSTOM_STYlES } from "../../../../../../constants/formConstants.js";

const EditSavingsCharges = ({
  savingsReferenceAccountId,
  savingsControlAccountId,
  interestPayableAccountId,
  transfersInSuspenseAccountId,
  interestOnSavingsAccountId,
  incomeFromFeeAccountId,
  incomeFromPenaltyAccountId,
  savingPreference,
  editSavingsProductHandler,
  editSavingsProductHandlerForSelect,
  template,
  charges,
  setCharges,
  setAccountType,
  accountType,
  numberOfMapFeeToIncome,
  setNumberOfMapFeeToIncome,
  numberOfMapPenalties,
  setNumberOfMapPenalties,
  numberOfConfigFS,
  setNumberOfConfigFS,
  handleSubmit,
  loading,
  escheatLiabilityId,
  renderTooltip,
  radio,
  setRadio,
  advance,
  setAdvance
}) => {
  const dispatch = useDispatch();
  const defaultConfigFS = { paymentTypeId: "", fundSourceAccountId: "" };
  const defaultMapFeeToIncome = { chargeId: "", incomeAccountId: "" };
  const defaultMapPenalties = { chargeId: "", incomeAccountId: "" };

  // const [advance, setAdvance] = useState(false);
  const [selectCharges, setSelectCharges] = useState("");

  //configure fund sources for payment channels
  const addToConfigFSArray = () => {
    setNumberOfConfigFS([...numberOfConfigFS, defaultConfigFS]);
  };
  const removeFromConfigFSArray = (position) => {
    setNumberOfConfigFS(
      numberOfConfigFS.filter((item, index) => index !== position)
    );
  };
  const handleConfigFSPaymentIdToChange = (position, value) => {
    let newConfigFS = [...numberOfConfigFS];
    newConfigFS[Number(position)].paymentTypeId = value;
    setNumberOfConfigFS(newConfigFS);
  };
  const handleConfigFSSourceIdToChange = (position, value) => {
    let newConfigFS = [...numberOfConfigFS];
    newConfigFS[Number(position)].fundSourceAccountId = value;
    setNumberOfConfigFS(newConfigFS);
  };

  // Map fees to income accounts
  // const [numberOfMapFeeToIncome, setNumberOfMapFeeToIncome] = useState([]);
  const addToMapFeeToIncomeArray = () => {
    setNumberOfMapFeeToIncome([
      ...numberOfMapFeeToIncome,
      defaultMapFeeToIncome,
    ]);
  };
  const removeFromMapFeeToIncomeArray = (position) => {
    setNumberOfMapFeeToIncome(
      numberOfMapFeeToIncome.filter((item, index) => index !== position)
    );
  };
  const handleMapFeeToIncomePaymentIdToChange = (position, value) => {
    let newMapFeeToIncome = [...numberOfMapFeeToIncome];
    newMapFeeToIncome[Number(position)].chargeId = value;
    setNumberOfMapFeeToIncome(newMapFeeToIncome);
  };
  const handleMapFeeToIncomeSourceIdToChange = (position, value) => {
    let newMapFeeToIncome = [...numberOfMapFeeToIncome];
    newMapFeeToIncome[Number(position)].incomeAccountId = value;
    setNumberOfMapFeeToIncome(newMapFeeToIncome);
  };

  // Map fees to income accounts
  // const [numberOfMapPenalties, setNumberOfMapPenalties] = useState([]);
  const addToMapPenaltiesArray = () => {
    setNumberOfMapPenalties([...numberOfMapPenalties, defaultMapPenalties]);
  };
  const removeFromMapPenaltiesArray = (position) => {
    setNumberOfMapPenalties(
      numberOfMapPenalties.filter((item, index) => index !== position)
    );
  };
  const handleMapPenaltiesPaymentIdToChange = (position, value) => {
    let newMapPenalties = [...numberOfMapPenalties];
    newMapPenalties[Number(position)].chargeId = value;
    setNumberOfMapPenalties(newMapPenalties);
  };
  const handleMapPenaltiesSourceIdToChange = (position, value) => {
    let newMapPenalties = [...numberOfMapPenalties];
    newMapPenalties[Number(position)].incomeAccountId = value;
    setNumberOfMapPenalties(newMapPenalties);
  };

  const handleAddCharge = () => {
    if (selectCharges) {
      let newCharge = template.chargeOptions.filter(
        (item) => item.id === Number(selectCharges)
      )[0];
      let check = charges.filter((item) => item === newCharge);
      if (check.length === 0) setCharges((p) => [...p, newCharge]);
      else {
        dispatch(Toast({
          text: "Already added!",
          icon: "error",
          timer: 2000,
        }));
      }
    } else {
      dispatch(Toast({
        text: "Please select a charge",
        icon: "error",
        timer: 2000,
      }));
    }
  };
  const handleChargeDelete = (id) => {
    setCharges(charges.filter((item) => item.id !== id));
  };

  return (
    <>
      <FormClass>
        <FormElements style={{ width: "60%", margin: "0 auto" }}>
          <div className="form-group d-flex justify-content-between align-items-center">
            {/* <label className="text-label">Product</label> */}
            <div className="w-100" style={{ maxWidth: '60%' }}>
              <ReactSelect
                className="fs-12"
                styles={REACT_SELECT_CUSTOM_STYlES}
                name="selectCharges"
                placeholder="Select a charge"
                onChange={(newCharge) => setSelectCharges(newCharge?.value)}
                isClearable={true}
                options={template
                  ?.chargeOptions
                  ?.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))
                }
              />
            </div>
            <NextActionButton
              color="#2C1DFF"
              style={{ margin: "0px 0px 0 10px" }}
              onClick={handleAddCharge}
            >
              <img
                src={
                  require("../../../../../../assets/images/icons/add.svg")
                    .default
                }
                alt="forward icon"
              />
              Add Charge
            </NextActionButton>
          </div>
        </FormElements>
        <Table className="child-table">
          <thead className="">
            <tr style={{ backgroundColor: "#FAFAFA" }}>
              <th>Name</th>
              <th>Type</th>
              <th>Amount</th>
              <th>Collected on</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {charges && charges.length > 0
              ? charges.map(
                  ({
                    id,
                    name,
                    chargeCalculationType,
                    amount,
                    chargeTimeType,
                  }) => (
                    <tr key={id}>
                      <td>{name}</td>
                      <td>{chargeCalculationType.value}</td>
                      <td>{formatCurrency(Number(amount))}</td>
                      <td>{chargeTimeType.value}</td>
                      <td>
                        <IconButton
                          onClick={() => handleChargeDelete(id)}
                          id="deleteHoliday"
                          className="mr-1 mb-1"
                        >
                          <img
                            src={
                              require("../../../../../../assets/images/icons/delete.svg")
                                .default
                            }
                            alt="add"
                            className="icon-img"
                          />
                        </IconButton>
                      </td>
                    </tr>
                  )
                )
              : null}
          </tbody>
        </Table>
        <FormTitle style={{ width: "60%", margin: "2rem auto" }}>
          <p className="text-info">Accounting type</p>
          <div className="lineThrough"></div>
        </FormTitle>
        <RadioGroup
          onChange={(e) => setRadio(e)}
          style={{ width: "60%", margin: "0 auto" }}
          value={radio}
        >
          <div className="d-flex justify-content-center w-100" style={{ gap: 16 }}>
            <Radio colorScheme="blue" value="1">
              None
            </Radio>
            <Radio colorScheme="blue" value="2">
              Cash
            </Radio>
          </div>
        </RadioGroup>
        {radio === "2" ? (
          <div>
            <FormTitle style={{ width: "60%", margin: "2rem auto" }}>
              <p className="text-info">Assets</p>
              <div className="lineThrough"></div>
            </FormTitle>

            <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
              <div className="col-sm-12 col-md-6 px-0">
                <FormClass>
                  <FormElements>
                    <div className="form-group d-flex justify-content-between align-items-end">
                      <label className="text-label d-flex flex-align-center">
                        Saving Reference {renderTooltip("Saving Reference")}
                      </label>
                      <div className="w-100" style={{ maxWidth: '60%' }}>
                        <ReactSelect
                          isClearable={true}
                          className="fs-12"
                          styles={REACT_SELECT_CUSTOM_STYlES}
                          name="savingsReferenceAccountId"
                          placeholder="Select a saving reference"
                          onChange={(ref, { name }) => editSavingsProductHandlerForSelect(name, ref.value)}
                          value={{
                            value: savingsReferenceAccountId,
                            label: template
                            ?.accountingMappingOptions
                            ?.assetAccountOptions
                            ?.find((ref) => ref?.id === savingsReferenceAccountId)
                            ?.name,
                          }}
                          options={template
                            ?.accountingMappingOptions
                            ?.assetAccountOptions
                            ?.map((item) => ({
                              label: item.name,
                              value: item.id,
                            }))
                          }
                        />
                      </div>
                    </div>
                  </FormElements>
                </FormClass>
              </div>
            </div>
            <FormTitle style={{ width: "60%", margin: "2rem auto" }}>
              <p className="text-info">Liabilities</p>
              <div className="lineThrough"></div>
            </FormTitle>

            <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
              <div className="col-sm-12 col-md-6 px-0 ">
                <FormClass>
                  <FormElements>
                    <div className="form-group d-flex justify-content-between align-items-end">
                      <label className="text-label d-flex flex-align-center">
                        Saving Control {renderTooltip("Saving Control")}
                      </label>
                      <div className="w-100" style={{ maxWidth: '60%' }}>
                        <ReactSelect
                          isClearable={true}
                          className="fs-12"
                          styles={REACT_SELECT_CUSTOM_STYlES}
                          name="savingsControlAccountId"
                          placeholder="Select control option"
                          onChange={(period, { name }) => editSavingsProductHandlerForSelect(name, period?.value)}
                          value={{
                            value: savingsControlAccountId,
                            label: template
                              ?.accountingMappingOptions
                              ?.liabilityAccountOptions
                              ?.find((option) => option?.id === savingsControlAccountId)
                              ?.name,
                          }}
                          options={template
                            ?.accountingMappingOptions
                            ?.liabilityAccountOptions
                            ?.map((item) => ({
                              label: item.name,
                              value: item.id,
                            }))
                          }
                        />
                      </div>
                    </div>
                  </FormElements>
                </FormClass>
              </div>
              <div className="col-sm-12 col-md-6 px-0">
                <FormClass>
                  <FormElements>
                    <div className="form-group d-flex justify-content-between align-items-start">
                      <label className="text-label d-flex flex-align-center">
                        Savings Transfers In Suspense{" "}
                        {renderTooltip("Savings Transfers In Suspense")}
                      </label>
                      <div className="w-100" style={{ maxWidth: '60%' }}>
                        <ReactSelect
                          isClearable={true}
                          className="fs-12"
                          styles={REACT_SELECT_CUSTOM_STYlES}
                          name="transfersInSuspenseAccountId"
                          placeholder="Select an option"
                          onChange={(period, { name }) => editSavingsProductHandlerForSelect(name, period?.value)}
                          value={{
                            value: transfersInSuspenseAccountId,
                            label: template
                              ?.accountingMappingOptions
                              ?.liabilityAccountOptions
                              ?.find((option) => option?.id === transfersInSuspenseAccountId)
                              ?.name,
                          }}
                          options={template
                            ?.accountingMappingOptions
                            ?.liabilityAccountOptions
                            ?.map((item) => ({
                              label: item.name,
                              value: item.id,
                            }))
                          }
                        />
                      </div>
                    </div>
                  </FormElements>
                </FormClass>
              </div>
            </div>

            <FormTitle style={{ width: "60%", margin: "2rem auto" }}>
              <p className="text-info">Expenses</p>
              <div className="lineThrough"></div>
            </FormTitle>

            <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
              <div className="col-sm-12 col-md-6 px-0 ">
                <FormClass>
                  <FormElements>
                    <div className="form-group d-flex justify-content-between align-items-end">
                      <label className="text-label d-flex flex-align-center">
                        Interest On Savings{" "}
                        {renderTooltip("Interest On Savings")}
                      </label>
                      <div className="w-100" style={{ maxWidth: '60%' }}>
                        <ReactSelect
                          isClearable={true}
                          className="fs-12"
                          styles={REACT_SELECT_CUSTOM_STYlES}
                          name="interestOnSavingsAccountId"
                          placeholder="Select interest on savings"
                          onChange={(period, { name }) => editSavingsProductHandlerForSelect(name, period?.value)}
                          value={{
                            value: interestOnSavingsAccountId,
                            label: template
                              ?.accountingMappingOptions
                              ?.expenseAccountOptions
                              ?.find((option) => option?.id === interestOnSavingsAccountId)
                              ?.name,
                          }}
                          options={template
                            ?.accountingMappingOptions
                            ?.expenseAccountOptions
                            ?.map((item) => ({
                              label: item.name,
                              value: item.id,
                            }))
                          }
                        />
                      </div>
                    </div>
                  </FormElements>
                </FormClass>
              </div>
            </div>

            <FormTitle style={{ width: "60%", margin: "2rem auto" }}>
              <p className="text-info">Income</p>
              <div className="lineThrough"></div>
            </FormTitle>

            <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
              <div className="col-sm-12 col-md-6 px-0 ">
                <FormClass>
                  <FormElements>
                    <div className="form-group d-flex justify-content-between align-items-end">
                      <label className="text-label d-flex flex-align-center">
                        Income From Fees {renderTooltip("Income From Fees")}
                      </label>
                      <div className="w-100" style={{ maxWidth: '60%' }}>
                        <ReactSelect
                          isClearable={true}
                          className="fs-12"
                          styles={REACT_SELECT_CUSTOM_STYlES}
                          name="incomeFromFeeAccountId"
                          placeholder="Select an option"
                          onChange={(period, { name }) => editSavingsProductHandlerForSelect(name, period?.value)}
                          value={{
                            value: incomeFromFeeAccountId,
                            label: template
                              ?.accountingMappingOptions
                              ?.incomeAccountOptions
                              ?.find((option) => option?.id === incomeFromFeeAccountId)
                              ?.name,
                          }}
                          options={template
                            ?.accountingMappingOptions
                            ?.incomeAccountOptions
                            ?.map((item) => ({
                              label: item.name,
                              value: item.id,
                            }))
                          }
                        />
                      </div>
                    </div>
                  </FormElements>
                </FormClass>
              </div>
              <div className="col-sm-12 col-md-6 px-0">
                <FormClass>
                  <FormElements>
                    <div className="form-group d-flex justify-content-between align-items-end">
                      <label className="text-label d-flex flex-align-center">
                        Income From Penalties{" "}
                        {renderTooltip("Income From Penalties")}
                      </label>
                      <div className="w-100" style={{ maxWidth: '60%' }}>
                        <ReactSelect
                          isClearable={true}
                          className="fs-12"
                          styles={REACT_SELECT_CUSTOM_STYlES}
                          name="incomeFromPenaltyAccountId"
                          placeholder="Select an option"
                          onChange={(period, { name }) => editSavingsProductHandlerForSelect(name, period?.value)}
                          value={{
                            value: incomeFromPenaltyAccountId,
                            label: template
                              ?.accountingMappingOptions
                              ?.incomeAccountOptions
                              ?.find((option) => option?.id === incomeFromPenaltyAccountId)
                              ?.name,
                          }}
                          options={template
                            ?.accountingMappingOptions
                            ?.incomeAccountOptions
                            ?.map((item) => ({
                              label: item.name,
                              value: item.id,
                            }))
                          }
                        />
                      </div>
                    </div>
                  </FormElements>
                </FormClass>
              </div>
            </div>
            <div className="pt-4">
              <div
                className="form-group d-flex  align-items-center"
                style={{ marginBottom: "1rem !important" }}
              >
                <h3 className="font-weight-bold">Advanced Accounting Rule</h3>
                <AddActionButton onClick={() => setAdvance((p) => !p)}>
                  Show
                  <img
                    className="icon-img ml-2"
                    src={
                      require(`../../../../../../assets/images/icons/arrow-${
                        advance ? "up" : "down2"
                      }.svg`).default
                    }
                    alt="dropdown icon"
                  />
                </AddActionButton>
              </div>
            </div>

            {advance ? (
              <div>
                <div className="d-flex justify-content-between flex-wrap">
                  <div className="col-sm-12 col-md-6 px-0 ">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label
                            style={{ width: "70%" }}
                            className="text-label d-flex flex-align-center"
                          >
                            Configure Fund Sources For Payment Channels
                          </label>
                          <AddActionButton onClick={addToConfigFSArray}>
                            <img
                              src={
                                require("../../../../../../assets/images/icons/add.svg")
                                  .default
                              }
                              alt="add icon"
                            />{" "}
                            Add
                          </AddActionButton>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                  <div className="col-sm-12 col-md-12 px-0">
                    {numberOfConfigFS.length > 0 && (
                      <Table className="child-table">
                        <thead className="">
                          <tr style={{ backgroundColor: "#FAFAFA" }}>
                            <th>Payment type</th>
                            <th>Fund source</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {numberOfConfigFS.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <div style={{ width: '360px' }}>
                                  <ReactSelect
                                    isClearable={true}
                                    className="fs-12"
                                    styles={REACT_SELECT_CUSTOM_STYlES}
                                    name="savingPreference"
                                    placeholder="Select a payment type"
                                    onChange={(period) => handleConfigFSPaymentIdToChange(index, period?.value)}
                                    value={{
                                      value: numberOfConfigFS[index].paymentTypeId,
                                      label: template
                                        ?.paymentTypeOptions
                                        ?.find((option) => option?.id === numberOfConfigFS[index].paymentTypeId)
                                        ?.name,
                                    }}
                                    options={template
                                      ?.paymentTypeOptions
                                      ?.map((item) => ({
                                        label: item.name,
                                        value: item.id,
                                      }))
                                    }
                                  />
                                </div>
                              </td>
                              <td>
                                <div style={{ width: '360px' }}>
                                  <ReactSelect
                                    isClearable={true}
                                    className="fs-12"
                                    styles={REACT_SELECT_CUSTOM_STYlES}
                                    name="savingPreference"
                                    placeholder="Select an option"
                                    onChange={(period) => handleConfigFSSourceIdToChange(index, period?.value)}
                                    value={{
                                      value: numberOfConfigFS[index].fundSourceAccountId,
                                      label: template
                                        ?.accountingMappingOptions
                                        ?.assetAccountOptions
                                        ?.find((option) => option?.id === numberOfConfigFS[index].fundSourceAccountId)
                                        ?.name,
                                    }}
                                    options={template
                                      ?.accountingMappingOptions
                                      ?.assetAccountOptions
                                      ?.map((item) => ({
                                        label: item.name,
                                        value: item.id,
                                      }))
                                    }
                                  />
                                </div>
                              </td>
                              <td>
                                <IconButton
                                  onClick={() => removeFromConfigFSArray(index)}
                                >
                                  <img
                                    src={
                                      require("../../../../../../assets/images/icons/delete.svg")
                                        .default
                                    }
                                    alt="add icon"
                                    className="icon-img"
                                  />
                                </IconButton>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-6 px-0 ">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label
                            style={{ width: "70%" }}
                            className="text-label d-flex flex-align-center"
                          >
                            Map fees to income accounts
                          </label>
                          <AddActionButton onClick={addToMapFeeToIncomeArray}>
                            <img
                              src={
                                require("../../../../../../assets/images/icons/add.svg")
                                  .default
                              }
                              alt="add icon"
                            />{" "}
                            Add
                          </AddActionButton>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                  <div className="col-sm-12 col-md-12 px-0">
                    {numberOfMapFeeToIncome.length > 0 && (
                      <Table className="child-table">
                        <thead className="">
                          <tr style={{ backgroundColor: "#FAFAFA" }}>
                            <th>Fees</th>
                            <th>Income Account</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {numberOfMapFeeToIncome.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <div style={{ width: '360px' }}>
                                  <ReactSelect
                                    isClearable={true}
                                    className="fs-12"
                                    styles={REACT_SELECT_CUSTOM_STYlES}
                                    name="savingPreference"
                                    placeholder="Select an option"
                                    onChange={(period) => handleMapFeeToIncomePaymentIdToChange(index, period?.value)}
                                    value={{
                                      value: numberOfMapFeeToIncome[index].chargeId,
                                      label: template
                                        ?.chargeOptions
                                        ?.find((option) => option?.id === numberOfMapFeeToIncome[index].chargeId)
                                        ?.name,
                                    }}
                                    options={template
                                      ?.chargeOptions
                                      ?.map((item) => ({
                                        label: item.name,
                                        value: item.id,
                                      }))
                                    }
                                  />
                                </div>
                              </td>
                              <td>
                                <div style={{ width: '360px' }}>
                                  <ReactSelect
                                    isClearable={true}
                                    className="fs-12"
                                    styles={REACT_SELECT_CUSTOM_STYlES}
                                    name="savingPreference"
                                    placeholder="Select an option"
                                    onChange={(period) => handleMapFeeToIncomeSourceIdToChange(index, period?.value)}
                                    value={{
                                      value: numberOfMapFeeToIncome[index].incomeAccountId,
                                      label: template
                                        ?.accountingMappingOptions
                                        ?.incomeAccountOptions
                                        ?.find((option) => option?.id === numberOfMapFeeToIncome[index].incomeAccountId)
                                        ?.name,
                                    }}
                                    options={template
                                      ?.accountingMappingOptions
                                      ?.incomeAccountOptions
                                      ?.map((item) => ({
                                        label: item.name,
                                        value: item.id,
                                      }))
                                    }
                                  />
                                </div>
                              </td>
                              <td>
                                <IconButton
                                  onClick={() =>
                                    removeFromMapFeeToIncomeArray(index)
                                  }
                                >
                                  <img
                                    src={
                                      require("../../../../../../assets/images/icons/delete.svg")
                                        .default
                                    }
                                    alt="add icon"
                                    className="icon-img"
                                  />
                                </IconButton>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-6 px-0 ">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label
                            style={{ width: "70%" }}
                            className="text-label d-flex flex-align-center"
                          >
                            Map Penalties To Specific Income Accounts
                          </label>
                          <AddActionButton onClick={addToMapPenaltiesArray}>
                            <img
                              src={
                                require("../../../../../../assets/images/icons/add.svg")
                                  .default
                              }
                              alt="add icon"
                            />{" "}
                            Add
                          </AddActionButton>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                  
                  <div className="col-sm-12 col-md-12 px-0">
                    {numberOfMapPenalties.length > 0 && (
                      <Table className="child-table">
                        <thead className="">
                          <tr style={{ backgroundColor: "#FAFAFA" }}>
                            <th>Penalty</th>
                            <th>Income Account</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {numberOfMapPenalties.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <div style={{ width: '360px' }}>
                                  <ReactSelect
                                    isClearable={true}
                                    className="fs-12"
                                    styles={REACT_SELECT_CUSTOM_STYlES}
                                    name="savingPreference"
                                    placeholder="Select an option"
                                    onChange={(period) => handleMapPenaltiesPaymentIdToChange(index, period?.value)}
                                    value={{
                                      value: numberOfMapPenalties[index].chargeId,
                                      label: template
                                        ?.penaltyOptions
                                        ?.find((option) => option?.id === numberOfMapPenalties[index].chargeId)
                                        ?.name,
                                    }}
                                    options={template
                                      ?.penaltyOptions
                                      ?.map((item) => ({
                                        label: item.name,
                                        value: item.id,
                                      }))
                                    }
                                  />
                                </div>
                              </td>
                              <td>
                                <div style={{ width: '360px' }}>
                                  <ReactSelect
                                    isClearable={true}
                                    className="fs-12"
                                    styles={REACT_SELECT_CUSTOM_STYlES}
                                    name="savingPreference"
                                    placeholder="Select an option"
                                    onChange={(period) => handleMapPenaltiesSourceIdToChange(index, period?.value)}
                                    value={{
                                      value: numberOfMapPenalties[index].incomeAccountId,
                                      label: template
                                      ?.accountingMappingOptions
                                      ?.incomeAccountOptions
                                        ?.find((option) => option?.id === numberOfMapPenalties[index].incomeAccountId)
                                        ?.name,
                                    }}
                                    options={template
                                      ?.accountingMappingOptions
                                      ?.incomeAccountOptions
                                      ?.map((item) => ({
                                        label: item.name,
                                        value: item.id,
                                      }))
                                    }
                                  />
                                </div>
                              </td>
                              <td>
                                <IconButton
                                  onClick={() =>
                                    removeFromMapPenaltiesArray(index)
                                  }
                                >
                                  <img
                                    src={
                                      require("../../../../../../assets/images/icons/delete.svg")
                                        .default
                                    }
                                    alt="add icon"
                                    className="icon-img"
                                  />
                                </IconButton>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        ) : null}

        <div className="float-right pt-4">
          <NextActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={handleSubmit}
          >
            {loading ? (
              <Spinner
                animation="border"
                color="#fff"
                className="mr-2 text-white"
                size="sm"
              />
            ) : (
              <img
                src={
                  require("../../../../../../assets/images/icons/save.svg")
                    .default
                }
                alt="submit icon"
              />
            )}
            Submit
          </NextActionButton>
        </div>
        <br />
        <br />
        <br />
        <br />
      </FormClass>
    </>
  );
};

export default EditSavingsCharges;
