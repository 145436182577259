import React from "react";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { deleteLoanDocument } from "../../../../../../actions/SavingsAndLoanActions";
import { FormClass, ActionButton } from "../../styles";

const DeleteDocumentModal = ({ id, documentId, history, onHide }) => {
  const dispatch = useDispatch();

  const handleDeleteDocument = () => {
    dispatch(deleteLoanDocument(id, documentId, history));
  };
  return (
    <>
      <Modal.Header>
        <Modal.Title>Delete Document</Modal.Title>
      </Modal.Header>
      <Modal.Body className="my-5 d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <div className=" px-2">
            <p style={{ fontSize: "15px" }}>
              Are you sure you want to delete this document from this account?
            </p>
          </div>
        </FormClass>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex mt-4">
          <ActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require("../../../../../../assets/images/icons/cancel.svg")
                  .default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={(e) => {
              e.preventDefault();
              handleDeleteDocument();
            }}
          >
            <img
              src={
                require("../../../../../../assets/images/icons/save.svg")
                  .default
              }
              alt="submit icon"
            />
            Submit
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default DeleteDocumentModal;
