import React from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import { ContentWrapper, ActionButton, TableButton } from "./styles";

const someProvisioningData = [
  {
    createdBy: "WoodCore Admin",
    createdOn: "12/06/2020",
    journeyEntryCreated: "Inactive",
    recreateProvisioning: "Recreate",
    viewReport: "View Reports",
    viewJournal: "View Journal",
  },
  {
    createdBy: "WoodCore Admin",
    createdOn: "12/06/2020",
    journeyEntryCreated: "active",
    recreateProvisioning: "Recreate",
    viewReport: "View Reports",
  },
  {
    createdBy: "WoodCore Admin",
    createdOn: "12/06/2020",
    journeyEntryCreated: "Inactive",
    recreateProvisioning: "Recreate",
    viewReport: "View Reports",
    viewJournal: "View Journal",
  },
];

const columns = [
  { title: "Created By", dataIndex: "createdBy", key: "createdBy" },
  { title: "Created On", dataIndex: "createdOn", key: "createdOn" },
  {
    title: "Journal Entry Created",
    dataIndex: "journeyEntryCreated",
    key: "journeyEntryCreated",
  },
  {
    title: "Recreate Provisioning",
    dataIndex: "recreateProvisioning",
    key: "recreateProvisioning",
    render: (recreateProvisioning) =>
      recreateProvisioning ? (
        <TableButton>{recreateProvisioning}</TableButton>
      ) : (
        ""
      ),
  },
  {
    title: "View Reports",
    dataIndex: "viewReport",
    key: "viewReport",
    render: (viewReport) =>
      viewReport ? <TableButton>{viewReport}</TableButton> : "",
  },
  {
    title: "View Journal Entries",
    dataIndex: "viewJournal",
    key: "viewJournal",
    render: (viewJournal) =>
      viewJournal ? <TableButton>{viewJournal}</TableButton> : "",
  },
];

const ProvisioningEntries = ({ history }) => {
  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-12 col-xl-6 pb-3">
            <h3 className="page-title">Provisioning Entries</h3>
            <p className="sub-title">Create provisioning entires</p>
            <p className="sub-title mt-2">
              <span className="text-info mr-1">
                <Link to="/core/accounting">Accounting</Link>
              </span>{" "}
              / View Provisioning Entries
            </p>
          </div>
          <div className="col-sm-12 col-md-12 col-xl-6">
            <div className="float-right">
              <ActionButton
                // onClick={() =>
                //   history.push("/core/accounting/rules/create")
                // }
                color="#2C1DFF"
                className=""
              >
                <img
                  src={
                    require("../../../../assets/images/icons/add.svg").default
                  }
                  className="d-inline mr-3"
                  alt="icon"
                />
                Create Provisioning Entries
              </ActionButton>
            </div>
          </div>
        </div>
        <ContentWrapper>
          <Table
            columns={columns}
            dataSource={someProvisioningData}
            pagination={{ defaultPageSize: 8 }}
            defaultPageSize={8}
            // onRow={(r) => ({
            //   onClick: () => history.push(`/core/accounting/rules/detail`),
            // })}
          />
        </ContentWrapper>
      </div>
    </>
  );
};

export default ProvisioningEntries;
