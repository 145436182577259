import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ContentWrapper } from "../styles";

import AppDetails from "./components/common/AppDetails";
import AppTerms from "./components/common/AppTerms";
import AppSettings from "./components/common/AppSettings";
import AppCharges from "./components/common/AppCharges";
import AppPreview from "./components/common/AppPreview";

import LoanDetails from "./components/loans/LoanDetails.jsx";
import LoanTerms from "./components/loans/LoanTerms.jsx";
import LoanCharges from "./components/loans/LoanCharges.jsx";
import LoanReview from "./components/loans/LoanReview.jsx";
import ScrollToTop from "../../../../../../components/ScrollToTop/index.jsx";
import LoanSettings from "./components/loans/LoanSettings";
import { getToolTips } from "../../../../../../actions/utilityActions";

import Toast from "../../../../../../util/Toast.js";

import {
  updateLoanProductInfo,
  getLoanProduct,
  resetNewLoanProductState,
} from "../../../../../../actions/configurationActions";
import { formatDate } from "../../../../../../util/FormatDate";
import ToolTipSvg from "../../../../../../assets/images/icons/tooltip.svg";

const EditLoanProductView = ({ match, history }) => {
  const dispatch = useDispatch();

  const loanProductReducer = useSelector((state) => state.loanProductReducer);

  const { loanProduct, loading } = loanProductReducer;

  const toolTips = useSelector((state) => state.toolTips.tooltips);

  const [showAdvance, setShowAdvance] = useState(true);

  useEffect(() => {
    dispatch(resetNewLoanProductState());
    dispatch(getToolTips("loanProduct"));
    // dispatch(getLoanProductsTemplate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (match.params.id) dispatch(getLoanProduct(match.params.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id]);

  const renderTooltip = (name) => (
    <>
      {toolTips && toolTips[name] ? (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={`tooltip-${toolTips[name]._id}`}>
              {toolTips[name].toolTip}
            </Tooltip>
          }
        >
          <img src={ToolTipSvg} style={{ marginLeft: "10px" }} alt="" />
        </OverlayTrigger>
      ) : (
        ""
      )}
    </>
  );

  const [fullCharges, setFullCharges] = useState([]);
  const [fullOverdueCharges, setFullOverdueCharges] = useState([]);
  const [valueCharges, setValueCharges] = useState([]);
  const [valueOverdueCharges, setValueOverdueCharges] = useState([]);
  const [feeToIncomeAccountMappings, setFeeToIncomeAccountMappings] = useState(
    []
  );
  const [penaltyToIncomeAccountMappings, setPenaltyToIncomeAccountMappings] =
    useState([]);

  const [
    paymentChannelToFundSourceMappings,
    setPaymentChannelToFundSourceMappings,
  ] = useState([]);

  const [fundSource, setFundSource] = useState("");
  const [loanPortfolio, setLoanPortfolio] = useState("");
  const [transferInSusp, setTransferInSusp] = useState("");
  const [incomeFromInterest, setIncomeFromInterest] = useState("");
  const [incomeFromFees, setIncomeFromFees] = useState("");
  const [incomeFromPenalties, setIncomeFromPenalties] = useState("");
  const [incomeFromRecovery, setIncomeFromRecovery] = useState("");
  const [overPaymentLia, setOverPaymentLia] = useState("");
  const [lossesWrittenOff, setLossesWrittenOff] = useState("");
  const [interestReceivable, setInterestReceivable] = useState("");
  const [feesReceivable, setFeesReceivable] = useState("");
  const [penaltiesReceivable, setPenaltiesReceivable] = useState("");

  useEffect(() => {
    let paymentChannel = [];
    let incomeFees = [];
    let penaltiesIncome = [];
    if (
      loanProduct &&
      loanProduct.paymentChannelToFundSourceMappings &&
      loanProduct.paymentChannelToFundSourceMappings.length
    ) {
      loanProduct.paymentChannelToFundSourceMappings.forEach((payChann) => {
        // console.log(payChann);
        paymentChannel.push({
          fundSourceAccountId: payChann?.fundSourceAccount?.id,
          paymentTypeId: payChann.paymentType.id,
        });
      });
    }
    if (
      loanProduct &&
      loanProduct.feeToIncomeAccountMappings &&
      loanProduct.feeToIncomeAccountMappings.length
    ) {
      loanProduct.feeToIncomeAccountMappings.forEach((payChann) => {
        incomeFees.push({
          chargeId: payChann.charge.id,
          incomeAccountId: payChann.incomeAccount.id,
        });
      });
    }
    if (
      loanProduct &&
      loanProduct.penaltyToIncomeAccountMappings &&
      loanProduct.penaltyToIncomeAccountMappings.length
    ) {
      loanProduct.penaltyToIncomeAccountMappings.forEach((payChann) => {
        penaltiesIncome.push({
          chargeId: payChann.charge.id,
          incomeAccountId: payChann.incomeAccount.id,
        });
      });
    }
    setPaymentChannelToFundSourceMappings(paymentChannel);
    setFeeToIncomeAccountMappings(incomeFees);
    setPenaltyToIncomeAccountMappings(penaltiesIncome);
  }, [loanProduct]);

  const [newLoanProduct, setNewLoanProduct] = useState({
    productName: loanProduct ? loanProduct.name : "",
    currency: loanProduct
      ? loanProduct.currency
        ? loanProduct.currency.code
        : ""
      : "",
    shortName: loanProduct ? loanProduct.shortName : "",
    multipleCurrency: loanProduct
      ? loanProduct.currency
        ? loanProduct.currency.inMultiplesOf
        : "1"
      : "1",
    description: loanProduct ? loanProduct.description : "",
    installment: loanProduct ? loanProduct.installmentAmountInMultiplesOf : "",
    decimalPlaces: loanProduct
      ? loanProduct.currency
        ? loanProduct.currency.decimalPlaces
        : "2"
      : "2",
    customerLoanCounter: loanProduct
      ? loanProduct.includeInBorrowerCycle
      : false,
    fund: loanProduct ? loanProduct.fundId : "",
    max_pricipal: loanProduct ? loanProduct.maxPrincipal : "",
    def_pricipal: loanProduct ? loanProduct.principal : "",
    min_pricipal: loanProduct ? loanProduct.minPrincipal : "",
    max_repayment: loanProduct ? loanProduct.maxNumberOfRepayments : "",
    def_repayment: loanProduct ? loanProduct.numberOfRepayments : "",
    min_repayment: loanProduct ? loanProduct.minNumberOfRepayments : "",
    max_interest: loanProduct ? loanProduct.maxInterestRatePerPeriod : "",
    def_interest: loanProduct ? loanProduct.interestRatePerPeriod : "",
    min_interest: loanProduct ? loanProduct.minInterestRatePerPeriod : "",
    interestSelect: loanProduct
      ? loanProduct.interestRateFrequencyType
        ? loanProduct.interestRateFrequencyType.id
        : ""
      : "",
    interestMethod: loanProduct
      ? loanProduct.interestType
        ? loanProduct.interestType.id
        : ""
      : "",
    repaid: loanProduct ? loanProduct.repaymentEvery : "",
    repaidSelect: loanProduct?.repaymentFrequencyType?.id,
    disbursal: loanProduct
      ? loanProduct.minimumDaysBetweenDisbursalAndFirstRepayment
      : "",
    amortizationType: loanProduct
      ? loanProduct.allowAttributeOverrides
        ? loanProduct.allowAttributeOverrides.amortizationType
        : true
      : true,
    isEqualAmortization: loanProduct ? loanProduct.isEqualAmortization : false,
    amortization: loanProduct
      ? loanProduct.amortizationType
        ? loanProduct.amortizationType.id
        : ""
      : "",
    interestType: loanProduct
      ? loanProduct.allowAttributeOverrides
        ? loanProduct.allowAttributeOverrides.interestType
        : true
      : true,
    transactionProcessingStrategy: loanProduct
      ? loanProduct.allowAttributeOverrides
        ? loanProduct.allowAttributeOverrides.transactionProcessingStrategyId
        : true
      : true,
    transactionProcessingStrategyId: loanProduct
      ? loanProduct.transactionProcessingStrategyId
      : "",
    graceOnArrearsAgeing: loanProduct
      ? loanProduct.allowAttributeOverrides
        ? loanProduct.allowAttributeOverrides.graceOnArrearsAgeing
        : true
      : true,
    interestCalculationPeriodType: loanProduct
      ? loanProduct.allowAttributeOverrides
        ? loanProduct?.allowAttributeOverrides?.interestCalculationPeriodType
        : true
      : true,
    repaymentEvery: loanProduct
      ? loanProduct.allowAttributeOverrides
        ? loanProduct.allowAttributeOverrides.repaymentEvery
        : true
      : true,
    inArrearsTolerance: loanProduct
      ? loanProduct.allowAttributeOverrides
        ? loanProduct.allowAttributeOverrides.inArrearsTolerance
        : true
      : true,
    graceOnPrincipalAndInterestPayment: loanProduct
      ? loanProduct.allowAttributeOverrides
        ? loanProduct.allowAttributeOverrides.graceOnPrincipalAndInterestPayment
        : true
      : true,
    interestCalculation: loanProduct
      ? loanProduct.interestCalculationPeriodType
        ? loanProduct.interestCalculationPeriodType.id
        : ""
      : "",
    moratonium2: loanProduct ? loanProduct.graceOnInterestPayment : "",
    moratonium: loanProduct ? loanProduct.graceOnPrincipalPayment : "",
    graceOnInterestCharged: loanProduct
      ? loanProduct.graceOnInterestCharged
      : "",
    inArrearsToleranceValue: loanProduct ? loanProduct.inArrearsTolerance : "",
    daysInyear: loanProduct
      ? loanProduct.daysInYearType
        ? loanProduct.daysInYearType.id
        : ""
      : "",
    daysInMonth: loanProduct
      ? loanProduct.daysInMonthType
        ? loanProduct.daysInMonthType.id
        : ""
      : "",
    canDefineInstallmentAmount: loanProduct
      ? loanProduct.canDefineInstallmentAmount
      : false,
    accountMovesOutOfNPAOnlyOnArrearsCompletion: loanProduct
      ? loanProduct.accountMovesOutOfNPAOnlyOnArrearsCompletion
      : false,
    graceOnArrearsAgeingValue: loanProduct
      ? loanProduct.graceOnArrearsAgeing
      : "",
    overdueDaysForNPA: loanProduct ? loanProduct.overdueDaysForNPA : "",
    principalThresholdForLastInstallment: loanProduct
      ? loanProduct.principalThresholdForLastInstallment
      : "",
    isVariableInstallment: loanProduct
      ? loanProduct.allowVariableInstallments
      : false,
    minimumGap: loanProduct ? loanProduct.minimumGap : "",
    maximumGap: loanProduct ? loanProduct.maximumGap : "",
    canUseForTopup: loanProduct ? loanProduct.canUseForTopup : false,
    isInterestRecalculationEnabled: loanProduct
      ? loanProduct.isInterestRecalculationEnabled
      : false,
    recalculationRestFrequencyInterval: loanProduct
      ? loanProduct.recalculationRestFrequencyInterval
      : "",
    preClosureInterestCalculationStrategy: loanProduct
      ? loanProduct.preClosureInterestCalculationStrategy
        ? loanProduct.preClosureInterestCalculationStrategy.id
        : ""
      : "",
    interestRecalculationCompoundingMethod: loanProduct
      ? loanProduct.interestRecalculationCompoundingMethod
        ? loanProduct.interestRecalculationCompoundingMethod.id
        : ""
      : "",
    rescheduleStrategyMethod: loanProduct
      ? loanProduct.rescheduleStrategyMethod
        ? loanProduct.rescheduleStrategyMethod.id
        : ""
      : "",
    recalculationRestFrequencyType: loanProduct
      ? loanProduct.recalculationRestFrequencyType
        ? loanProduct.recalculationRestFrequencyType.id
        : ""
      : "",
    recalculationCompoundingFrequencyInterval: loanProduct
      ? loanProduct.recalculationCompoundingFrequencyInterval
        ? loanProduct.recalculationCompoundingFrequencyInterval.id
        : ""
      : "",
    recalculationCompoundingFrequencyType: loanProduct
      ? loanProduct.recalculationCompoundingFrequencyType
        ? loanProduct.recalculationCompoundingFrequencyType.id
        : ""
      : "",
    recalculationCompoundingFrequencyNthDayType: loanProduct
      ? loanProduct.recalculationCompoundingFrequencyNthDayType
        ? loanProduct.recalculationCompoundingFrequencyNthDayType.id
        : ""
      : "",
    recalculationCompoundingFrequencyDayOfWeekType: loanProduct
      ? loanProduct.recalculationCompoundingFrequencyDayOfWeekType
        ? loanProduct.recalculationCompoundingFrequencyDayOfWeekType.id
        : ""
      : "",
    recalculationCompoundingFrequencyOnDayType: loanProduct
      ? loanProduct.recalculationCompoundingFrequencyOnDayType
        ? loanProduct.recalculationCompoundingFrequencyOnDayType.id
        : ""
      : "",
    isArrearsBasedOnOriginalSchedule: loanProduct
      ? loanProduct.isArrearsBasedOnOriginalSchedule
      : false,
    recalculationRestFrequencyDayOfWeekType: loanProduct
      ? loanProduct.recalculationRestFrequencyDayOfWeekType
        ? loanProduct.recalculationRestFrequencyDayOfWeekType.id
        : ""
      : "",
    recalculationRestFrequencyOnDayType: loanProduct
      ? loanProduct.recalculationRestFrequencyOnDayType
        ? loanProduct.recalculationRestFrequencyOnDayType.id
        : ""
      : "",
    recalculationRestFrequencyNthDayType: loanProduct
      ? loanProduct.recalculationRestFrequencyNthDayType
        ? loanProduct.recalculationRestFrequencyNthDayType.id
        : ""
      : "",
    placeGuarantee: loanProduct ? loanProduct.holdGuaranteeFunds : false,
    mandatoryGuarantee: loanProduct ? loanProduct.mandatoryGuarantee : "",
    minimumGuaranteeFromOwnFunds: loanProduct
      ? loanProduct.minimumGuaranteeFromOwnFunds
      : "",
    minimumGuaranteeFromGuarantor: loanProduct
      ? loanProduct.minimumGuaranteeFromGuarantor
      : "",
    loanTrancte: loanProduct ? loanProduct.multiDisburseLoan : false,
    maxTrancheCount: loanProduct ? loanProduct.maxTrancheCount : "",
    outstandingLoanBalance: loanProduct
      ? loanProduct.outstandingLoanBalance
      : "",
    fundSource: loanProduct ? loanProduct.fundSourceAccountId : "",
    loanPortfolio: loanProduct ? loanProduct.loanPortfolioAccountId : "",
    transferInSusp: loanProduct ? loanProduct.transfersInSuspenseAccountId : "",
    incomeFromInterest: loanProduct ? loanProduct.interestOnLoanAccountId : "",
    incomeFromFees: loanProduct ? loanProduct.incomeFromFeeAccountId : "",
    incomeFromPenalties: loanProduct
      ? loanProduct.incomeFromPenaltyAccountId
      : "",
    incomeFromRecovery: "",
    overPaymentLia: loanProduct
      ? loanProduct.overpaymentLiabilityAccountId
      : "",
    lossesWrittenOff: loanProduct ? loanProduct.writeOffAccountId : "",
    interestReceivable: loanProduct
      ? loanProduct.receivableInterestAccountId
      : "",
    feesReceivable: loanProduct ? loanProduct.receivableFeeAccountId : "",
    penaltiesReceivable: loanProduct
      ? loanProduct.receivablePenaltyAccountId
      : "",
    allowPartialPeriodInterestCalcualtion: loanProduct
      ? loanProduct.allowPartialPeriodInterestCalcualtion
      : false,
    principalOverdueAccountId: "",
    interestOverdueAccountId: "",
    interestInSuspenseAccountId: "",
  });

  const updateLoanProductInfoHandler = (e) =>
    setNewLoanProduct({
      ...newLoanProduct,
      [e.target.name]: e.target.value,
    });

  const [accountType, setAccountType] = useState("1");
  const [min_pricipal, setMin_pricipal] = useState(
    loanProduct ? loanProduct.minPrincipal : ""
  );
  const [def_pricipal, setDef_pricipal] = useState(
    loanProduct ? loanProduct.principal : ""
  );
  const [max_pricipal, setMax_pricipal] = useState(
    loanProduct ? loanProduct.maxPrincipal : ""
  );
  const [detailsError, setDetailsError] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [settingsError, setSettingsError] = useState(false);
  const [chargesError, setChargesError] = useState(false);
  const [processing, setLoading] = useState(false);
  const [startDate, handleStartDate] = useState(new Date());
  const [closeDate, handleCloseDate] = useState(new Date());
  const [numberOfPrincipalLC, setNumberOfPrincipalLC] = useState([]);
  const [numberOfRepayment, setNumberOfRepayment] = useState([]);
  const [numberOfNominal, setNumberOfNominal] = useState([]);
  const [terms, setTerms] = useState(false);
  const [currentScreen, setCurrentScreen] = useState(0);

  useEffect(() => {
    if (loanProduct) {
      setMax_pricipal(loanProduct ? loanProduct.maxPrincipal : "");
      setDef_pricipal(loanProduct ? loanProduct.principal : "");
      setMin_pricipal(loanProduct ? loanProduct.minPrincipal : "");

      setFundSource(
        loanProduct &&
          loanProduct.accountingMappings &&
          loanProduct.accountingMappings.fundSourceAccount &&
          loanProduct.accountingMappings.fundSourceAccount.id
          ? loanProduct.accountingMappings.fundSourceAccount.id
          : ""
      );
      setLoanPortfolio(
        loanProduct &&
          loanProduct.accountingMappings &&
          loanProduct.accountingMappings.loanPortfolioAccount &&
          loanProduct.accountingMappings.loanPortfolioAccount.id
          ? loanProduct.accountingMappings.loanPortfolioAccount.id
          : ""
      );
      setTransferInSusp(
        loanProduct &&
          loanProduct.accountingMappings &&
          loanProduct.accountingMappings.transfersInSuspenseAccount &&
          loanProduct.accountingMappings.transfersInSuspenseAccount.id
          ? loanProduct.accountingMappings.transfersInSuspenseAccount.id
          : ""
      );
      setIncomeFromInterest(
        loanProduct &&
          loanProduct.accountingMappings &&
          loanProduct.accountingMappings.interestOnLoanAccount &&
          loanProduct.accountingMappings.interestOnLoanAccount.id
          ? loanProduct.accountingMappings.interestOnLoanAccount.id
          : ""
      );
      setIncomeFromFees(
        loanProduct &&
          loanProduct.accountingMappings &&
          loanProduct.accountingMappings.incomeFromFeeAccount &&
          loanProduct.accountingMappings.incomeFromFeeAccount.id
          ? loanProduct.accountingMappings.incomeFromFeeAccount.id
          : ""
      );
      setIncomeFromPenalties(
        loanProduct &&
          loanProduct.accountingMappings &&
          loanProduct.accountingMappings.incomeFromPenaltyAccount &&
          loanProduct.accountingMappings.incomeFromPenaltyAccount.id
          ? loanProduct.accountingMappings.incomeFromPenaltyAccount.id
          : ""
      );
      setIncomeFromRecovery(
        loanProduct &&
          loanProduct.accountingMappings &&
          loanProduct.accountingMappings.incomeFromRecoveryAccount &&
          loanProduct.accountingMappings.incomeFromRecoveryAccount.id
          ? loanProduct.accountingMappings.incomeFromRecoveryAccount.id
          : ""
      );
      setOverPaymentLia(
        loanProduct &&
          loanProduct.accountingMappings &&
          loanProduct.accountingMappings.overpaymentLiabilityAccount &&
          loanProduct.accountingMappings.overpaymentLiabilityAccount.id
          ? loanProduct.accountingMappings.overpaymentLiabilityAccount.id
          : ""
      );
      setLossesWrittenOff(
        loanProduct &&
          loanProduct.accountingMappings &&
          loanProduct.accountingMappings.writeOffAccount &&
          loanProduct.accountingMappings.writeOffAccount.id
          ? loanProduct.accountingMappings.writeOffAccount.id
          : ""
      );
      setInterestReceivable(
        loanProduct &&
          loanProduct.accountingMappings &&
          loanProduct.accountingMappings.receivableInterestAccount &&
          loanProduct.accountingMappings.receivableInterestAccount.id
          ? loanProduct.accountingMappings.receivableInterestAccount.id
          : ""
      );
      setFeesReceivable(
        loanProduct &&
          loanProduct.accountingMappings &&
          loanProduct.accountingMappings.receivableFeeAccount &&
          loanProduct.accountingMappings.receivableFeeAccount.id
          ? loanProduct.accountingMappings.receivableFeeAccount.id
          : ""
      );
      setPenaltiesReceivable(
        loanProduct
        ?.accountingMappings
        ?.receivablePenaltyAccount
        ?.id
      );
      setNewLoanProduct({
        productName: loanProduct ? loanProduct.name : "",
        currency: loanProduct
          ? loanProduct.currency
            ? loanProduct.currency.code
            : ""
          : "",
        shortName: loanProduct ? loanProduct.shortName : "",
        multipleCurrency: loanProduct
          ? loanProduct.currency
            ? loanProduct.currency.inMultiplesOf
            : "1"
          : "1",
        description: loanProduct ? loanProduct.description : "",
        installment: loanProduct
          ? loanProduct.installmentAmountInMultiplesOf
          : "",
        decimalPlaces: loanProduct
          ? loanProduct.currency
            ? loanProduct.currency.decimalPlaces
            : "2"
          : "2",
        customerLoanCounter: loanProduct
          ? loanProduct.includeInBorrowerCycle
          : false,
        fund: loanProduct ? loanProduct.fundId : "",
        max_pricipal: loanProduct ? loanProduct.maxPrincipal : "",
        def_pricipal: loanProduct ? loanProduct.principal : "",
        min_pricipal: loanProduct ? loanProduct.minPrincipal : "",
        max_repayment: loanProduct ? loanProduct.maxNumberOfRepayments : "",
        def_repayment: loanProduct ? loanProduct.numberOfRepayments : "",
        min_repayment: loanProduct ? loanProduct.minNumberOfRepayments : "",
        max_interest: loanProduct ? loanProduct.maxInterestRatePerPeriod : "",
        def_interest: loanProduct ? loanProduct.interestRatePerPeriod : "",
        min_interest: loanProduct ? loanProduct.minInterestRatePerPeriod : "",
        interestSelect: loanProduct
          ? loanProduct.interestRateFrequencyType
            ? loanProduct.interestRateFrequencyType.id
            : ""
          : "",
        interestMethod: loanProduct
          ? loanProduct.interestType
            ? loanProduct.interestType.id
            : ""
          : "",
        repaid: loanProduct ? loanProduct.repaymentEvery : "",
        repaidSelect: loanProduct?.repaymentFrequencyType?.id,
        disbursal: loanProduct
          ? loanProduct.minimumDaysBetweenDisbursalAndFirstRepayment
          : "",
        amortizationType: loanProduct
          ? loanProduct.allowAttributeOverrides
            ? loanProduct.allowAttributeOverrides.amortizationType
            : true
          : true,
        isEqualAmortization: loanProduct
          ? loanProduct.isEqualAmortization
          : false,
        amortization: loanProduct
          ? loanProduct.amortizationType
            ? loanProduct.amortizationType.id
            : ""
          : "",
        interestType: loanProduct
          ? loanProduct.allowAttributeOverrides
            ? loanProduct.allowAttributeOverrides.interestType
            : true
          : true,
        transactionProcessingStrategy: loanProduct
          ? loanProduct.allowAttributeOverrides
            ? loanProduct.allowAttributeOverrides
                .transactionProcessingStrategyId
            : true
          : true,
        transactionProcessingStrategyId: loanProduct
          ? loanProduct.transactionProcessingStrategyId
          : "",
        graceOnArrearsAgeing: loanProduct
          ? loanProduct.allowAttributeOverrides
            ? loanProduct.allowAttributeOverrides.graceOnArrearsAgeing
            : true
          : true,
        interestCalculationPeriodType: loanProduct
          ? loanProduct.allowAttributeOverrides
            ? loanProduct.allowAttributeOverrides.interestCalculationPeriodType
            : true
          : true,
        repaymentEvery: loanProduct
          ? loanProduct.allowAttributeOverrides
            ? loanProduct.allowAttributeOverrides.repaymentEvery
            : true
          : true,
        inArrearsTolerance: loanProduct
          ? loanProduct.allowAttributeOverrides
            ? loanProduct.allowAttributeOverrides.inArrearsTolerance
            : true
          : true,
        graceOnPrincipalAndInterestPayment: loanProduct
          ? loanProduct.allowAttributeOverrides
            ? loanProduct.allowAttributeOverrides
                .graceOnPrincipalAndInterestPayment
            : true
          : true,
        interestCalculation: loanProduct?.interestCalculationPeriodType?.id,
        moratonium2: loanProduct ? loanProduct.graceOnInterestPayment : "",
        moratonium: loanProduct ? loanProduct.graceOnPrincipalPayment : "",
        graceOnInterestCharged: loanProduct
          ? loanProduct.graceOnInterestCharged
          : "",
        inArrearsToleranceValue: loanProduct
          ? loanProduct.inArrearsTolerance
          : "",
        daysInyear: loanProduct
          ? loanProduct.daysInYearType
            ? loanProduct.daysInYearType.id
            : ""
          : "",
        daysInMonth: loanProduct
          ? loanProduct.daysInMonthType
            ? loanProduct.daysInMonthType.id
            : ""
          : "",
        canDefineInstallmentAmount: loanProduct
          ? loanProduct.canDefineInstallmentAmount
          : false,
        accountMovesOutOfNPAOnlyOnArrearsCompletion: loanProduct
          ? loanProduct.accountMovesOutOfNPAOnlyOnArrearsCompletion
          : false,
        graceOnArrearsAgeingValue: loanProduct
          ? loanProduct.graceOnArrearsAgeing
          : "",
        overdueDaysForNPA: loanProduct ? loanProduct.overdueDaysForNPA : "",
        principalThresholdForLastInstallment: loanProduct
          ? loanProduct.principalThresholdForLastInstallment
          : "",
        isVariableInstallment: loanProduct
          ? loanProduct.allowVariableInstallments
          : false,
        minimumGap: loanProduct ? loanProduct.minimumGap : "",
        maximumGap: loanProduct ? loanProduct.maximumGap : "",
        canUseForTopup: loanProduct ? loanProduct.canUseForTopup : false,
        isInterestRecalculationEnabled: loanProduct
          ? loanProduct.isInterestRecalculationEnabled
          : false,
        recalculationRestFrequencyInterval: loanProduct
          ? loanProduct.recalculationRestFrequencyInterval
          : "",
        preClosureInterestCalculationStrategy: loanProduct
          ? loanProduct.preClosureInterestCalculationStrategy
            ? loanProduct.preClosureInterestCalculationStrategy.id
            : ""
          : "",
        interestRecalculationCompoundingMethod: loanProduct
          ? loanProduct.interestRecalculationCompoundingMethod
            ? loanProduct.interestRecalculationCompoundingMethod.id
            : ""
          : "",
        rescheduleStrategyMethod: loanProduct
          ? loanProduct.rescheduleStrategyMethod
            ? loanProduct.rescheduleStrategyMethod.id
            : ""
          : "",
        recalculationRestFrequencyType: loanProduct
          ? loanProduct.recalculationRestFrequencyType
            ? loanProduct.recalculationRestFrequencyType.id
            : ""
          : "",
        recalculationCompoundingFrequencyInterval: loanProduct
          ? loanProduct.recalculationCompoundingFrequencyInterval
            ? loanProduct.recalculationCompoundingFrequencyInterval.id
            : ""
          : "",
        recalculationCompoundingFrequencyType: loanProduct
          ? loanProduct.recalculationCompoundingFrequencyType
            ? loanProduct.recalculationCompoundingFrequencyType.id
            : ""
          : "",
        recalculationCompoundingFrequencyNthDayType: loanProduct
          ? loanProduct.recalculationCompoundingFrequencyNthDayType
            ? loanProduct.recalculationCompoundingFrequencyNthDayType.id
            : ""
          : "",
        recalculationCompoundingFrequencyDayOfWeekType: loanProduct
          ? loanProduct.recalculationCompoundingFrequencyDayOfWeekType
            ? loanProduct.recalculationCompoundingFrequencyDayOfWeekType.id
            : ""
          : "",
        recalculationCompoundingFrequencyOnDayType: loanProduct
          ? loanProduct.recalculationCompoundingFrequencyOnDayType
            ? loanProduct.recalculationCompoundingFrequencyOnDayType.id
            : ""
          : "",
        isArrearsBasedOnOriginalSchedule: loanProduct
          ? loanProduct.isArrearsBasedOnOriginalSchedule
          : false,
        recalculationRestFrequencyDayOfWeekType: loanProduct
          ? loanProduct.recalculationRestFrequencyDayOfWeekType
            ? loanProduct.recalculationRestFrequencyDayOfWeekType.id
            : ""
          : "",
        recalculationRestFrequencyOnDayType: loanProduct
          ? loanProduct.recalculationRestFrequencyOnDayType
            ? loanProduct.recalculationRestFrequencyOnDayType.id
            : ""
          : "",
        recalculationRestFrequencyNthDayType: loanProduct
          ? loanProduct.recalculationRestFrequencyNthDayType
            ? loanProduct.recalculationRestFrequencyNthDayType.id
            : ""
          : "",
        placeGuarantee: loanProduct ? loanProduct.holdGuaranteeFunds : false,
        mandatoryGuarantee: loanProduct ? loanProduct.mandatoryGuarantee : "",
        minimumGuaranteeFromOwnFunds: loanProduct
          ? loanProduct.minimumGuaranteeFromOwnFunds
          : "",
        minimumGuaranteeFromGuarantor: loanProduct
          ? loanProduct.minimumGuaranteeFromGuarantor
          : "",
        loanTrancte: loanProduct ? loanProduct.multiDisburseLoan : false,
        maxTrancheCount: loanProduct ? loanProduct.maxTrancheCount : "",
        outstandingLoanBalance: loanProduct
          ? loanProduct.outstandingLoanBalance
          : "",
        fundSource:
          loanProduct
          ?.accountingMappings
          ?.fundSourceAccount
          ?.id,
        // loanPortfolio:
        //   loanProduct &&
        //   loanProduct.accountingMappings &&
        //   loanProduct.accountingMappings.loanPortfolioAccount &&
        //   loanProduct.accountingMappings.loanPortfolioAccount.id,
        // transferInSusp:
        //   loanProduct &&
        //   loanProduct.accountingMappings &&
        //   loanProduct.accountingMappings.transfersInSuspenseAccount &&
        //   loanProduct.accountingMappings.transfersInSuspenseAccount.id,
        // incomeFromInterest:
        //   loanProduct &&
        //   loanProduct.accountingMappings &&
        //   loanProduct.accountingMappings.interestOnLoanAccount &&
        //   loanProduct.accountingMappings.interestOnLoanAccount.id,
        // incomeFromFees:
        //   loanProduct &&
        //   loanProduct.accountingMappings &&
        //   loanProduct.accountingMappings.incomeFromFeeAccount &&
        //   loanProduct.accountingMappings.incomeFromFeeAccount.id,
        // incomeFromPenalties:
        //   loanProduct &&
        //   loanProduct.accountingMappings &&
        //   loanProduct.accountingMappings.incomeFromPenaltyAccount &&
        //   loanProduct.accountingMappings.incomeFromPenaltyAccount.id,
        // incomeFromRecovery:
        //   loanProduct &&
        //   loanProduct.accountingMappings &&
        //   loanProduct.accountingMappings.incomeFromRecoveryAccount &&
        //   loanProduct.accountingMappings.incomeFromRecoveryAccount.id,
        // overPaymentLia:
        //   loanProduct &&
        //   loanProduct.accountingMappings &&
        //   loanProduct.accountingMappings.overpaymentLiabilityAccount &&
        //   loanProduct.accountingMappings.overpaymentLiabilityAccount.id,
        // lossesWrittenOff:
        //   loanProduct &&
        //   loanProduct.accountingMappings &&
        //   loanProduct.accountingMappings.writeOffAccount &&
        //   loanProduct.accountingMappings.writeOffAccount.id,
        // interestReceivable:
        //   loanProduct &&
        //   loanProduct.accountingMappings &&
        //   loanProduct.accountingMappings.receivableInterestAccount &&
        //   loanProduct.accountingMappings.receivableInterestAccount.id,
        // feesReceivable:
        //   loanProduct &&
        //   loanProduct.accountingMappings &&
        //   loanProduct.accountingMappings.receivableFeeAccount &&
        //   loanProduct.accountingMappings.receivableFeeAccount.id,
        // interestOverdueAccountId:
        //   loanProduct &&
        //   loanProduct.accountingMappings &&
        //   loanProduct.accountingMappings.interestOverdueAccount &&
        //   loanProduct.accountingMappings.interestOverdueAccount.id,
        principalOverdueAccountId:
          loanProduct &&
          loanProduct.accountingMappings &&
          loanProduct.accountingMappings.principalOverdueAccount &&
          loanProduct.accountingMappings.principalOverdueAccount.id,
        interestInSuspenseAccountId:
          loanProduct &&
          loanProduct.accountingMappings &&
          loanProduct.accountingMappings.interestInSuspenseAccount &&
          loanProduct.accountingMappings.interestInSuspenseAccount.id,
        penaltiesReceivable:
          loanProduct &&
          loanProduct.accountingMappings &&
          loanProduct.accountingMappings.receivablePenaltyAccount &&
          loanProduct.accountingMappings.receivablePenaltyAccount.id,
        allowPartialPeriodInterestCalcualtion: loanProduct
          ? loanProduct.allowPartialPeriodInterestCalcualtion
          : false,
      });
      setAccountType(
        loanProduct.accountingRule ? String(loanProduct.accountingRule.id) : "1"
      );
      handleStartDate(
        loanProduct.startDate ? new Date(loanProduct.startDate) : new Date()
      );
      handleCloseDate(
        loanProduct.closeDate ? new Date(loanProduct.closeDate) : new Date()
      );
      setValueOverdueCharges(loanProduct.valueOverdueCharges);
    }
  }, [loanProduct]);

  const renderComponent = () => {
    let currentComponet = "";
    switch (currentScreen) {
      case 0:
        // console.log('I AM HERE BABY 0')
        currentComponet = (
          <AppDetails>
            <LoanDetails
              setDetailsError={setDetailsError}
              detailsError={detailsError}
              setNewLoanProduct={setNewLoanProduct}
              template={loading || !loanProduct ? null : loanProduct}
              handleNextScreen={handleNextCustomIndexSelect}
              updateLoanProductInfoHandler={updateLoanProductInfoHandler}
              productName={newLoanProduct.productName}
              currency={newLoanProduct.currency}
              shortName={newLoanProduct.shortName}
              multipleCurrency={newLoanProduct.multipleCurrency}
              description={newLoanProduct.description}
              installment={newLoanProduct.installment}
              decimalPlaces={newLoanProduct.decimalPlaces}
              handleStartDate={handleStartDate}
              startDate={startDate}
              closeDate={closeDate}
              handleCloseDate={handleCloseDate}
              customerLoanCounter={newLoanProduct.customerLoanCounter}
              fund={newLoanProduct.fund}
              renderTooltip={renderTooltip}
            />
          </AppDetails>
        );
        break;
      case 1:
        // console.log('I AM HERE BABY 1')
        currentComponet = (
          <AppTerms>
            <LoanTerms
              handleNextScreen={handleNextCustomIndexSelect}
              handlePreviousScreen={handlePreviousCustomIndexSelect}
              max_pricipal={max_pricipal}
              min_pricipal={min_pricipal}
              def_pricipal={def_pricipal}
              setMin_pricipal={setMin_pricipal}
              setDef_pricipal={setDef_pricipal}
              setMax_pricipal={setMax_pricipal}
              max_interest={newLoanProduct.max_interest}
              min_interest={newLoanProduct.min_interest}
              def_interest={newLoanProduct.def_interest}
              max_repayment={newLoanProduct.max_repayment}
              min_repayment={newLoanProduct.min_repayment}
              def_repayment={newLoanProduct.def_repayment}
              disbursal={newLoanProduct.disbursal}
              interestSelect={newLoanProduct.interestSelect}
              termsError={termsError}
              repaid={newLoanProduct.repaid}
              repaidSelect={newLoanProduct.repaidSelect}
              setTermsError={setTermsError}
              updateLoanProductInfoHandler={updateLoanProductInfoHandler}
              template={loanProduct}
              setNumberOfPrincipalLC={setNumberOfPrincipalLC}
              numberOfPrincipalLC={numberOfPrincipalLC}
              numberOfRepayment={numberOfRepayment}
              setNumberOfRepayment={setNumberOfRepayment}
              numberOfNominal={numberOfNominal}
              setNumberOfNominal={setNumberOfNominal}
              setTerms={setTerms}
              terms={terms}
              renderTooltip={renderTooltip}
            />
          </AppTerms>
        );
        break;
      case 2:
        // console.log('I AM HERE BABY 2')
        currentComponet = (
          <AppSettings>
            <LoanSettings
              handleNextScreen={handleNextCustomIndexSelect}
              amortization={newLoanProduct.amortization}
              handlePreviousScreen={handlePreviousCustomIndexSelect}
              settingsError={settingsError}
              setSettingsError={setSettingsError}
              updateLoanProductInfoHandler={updateLoanProductInfoHandler}
              interestMethod={newLoanProduct.interestMethod}
              setNewLoanProduct={setNewLoanProduct}
              amortizationType={newLoanProduct.amortizationType}
              isEqualAmortization={newLoanProduct.isEqualAmortization}
              repaymentEvery={newLoanProduct.repaymentEvery}
              interestType={newLoanProduct.interestType}
              transactionProcessingStrategy={
                newLoanProduct.transactionProcessingStrategy
              }
              transactionProcessingStrategyId={
                newLoanProduct.transactionProcessingStrategyId
              }
              interestCalculationPeriodType={
                newLoanProduct.interestCalculationPeriodType
              }
              inArrearsTolerance={newLoanProduct.inArrearsTolerance}
              graceOnPrincipalAndInterestPayment={
                newLoanProduct.graceOnPrincipalAndInterestPayment
              }
              graceOnArrearsAgeing={newLoanProduct.graceOnArrearsAgeing}
              template={loanProduct}
              interestCalculation={newLoanProduct.interestCalculation}
              moratonium2={newLoanProduct.moratonium2}
              moratonium={newLoanProduct.moratonium}
              graceOnInterestCharged={newLoanProduct.graceOnInterestCharged}
              inArrearsToleranceValue={newLoanProduct.inArrearsToleranceValue}
              daysInMonth={newLoanProduct.daysInMonth}
              daysInyear={newLoanProduct.daysInyear}
              canDefineInstallmentAmount={
                newLoanProduct.canDefineInstallmentAmount
              }
              accountMovesOutOfNPAOnlyOnArrearsCompletion={
                newLoanProduct.accountMovesOutOfNPAOnlyOnArrearsCompletion
              }
              graceOnArrearsAgeingValue={
                newLoanProduct.graceOnArrearsAgeingValue
              }
              overdueDaysForNPA={newLoanProduct.overdueDaysForNPA}
              principalThresholdForLastInstallment={
                newLoanProduct.principalThresholdForLastInstallment
              }
              isVariableInstallment={newLoanProduct.isVariableInstallment}
              minimumGap={newLoanProduct.minimumGap}
              allowPartialPeriodInterestCalcualtion={
                newLoanProduct.allowPartialPeriodInterestCalcualtion
              }
              maximumGap={newLoanProduct.maximumGap}
              canUseForTopup={newLoanProduct.canUseForTopup}
              isInterestRecalculationEnabled={
                newLoanProduct.isInterestRecalculationEnabled
              }
              preClosureInterestCalculationStrategy={
                newLoanProduct.preClosureInterestCalculationStrategy
              }
              recalculationRestFrequencyInterval={
                newLoanProduct.recalculationRestFrequencyInterval
              }
              interestRecalculationCompoundingMethod={
                newLoanProduct.interestRecalculationCompoundingMethod
              }
              rescheduleStrategyMethod={newLoanProduct.rescheduleStrategyMethod}
              recalculationRestFrequencyType={
                newLoanProduct.recalculationRestFrequencyType
              }
              recalculationCompoundingFrequencyInterval={
                newLoanProduct.recalculationCompoundingFrequencyInterval
              }
              recalculationCompoundingFrequencyType={
                newLoanProduct.recalculationCompoundingFrequencyType
              }
              recalculationCompoundingFrequencyNthDayType={
                newLoanProduct.recalculationCompoundingFrequencyNthDayType
              }
              recalculationCompoundingFrequencyDayOfWeekType={
                newLoanProduct.recalculationCompoundingFrequencyDayOfWeekType
              }
              recalculationCompoundingFrequencyOnDayType={
                newLoanProduct.recalculationCompoundingFrequencyOnDayType
              }
              isArrearsBasedOnOriginalSchedule={
                newLoanProduct.isArrearsBasedOnOriginalSchedule
              }
              recalculationRestFrequencyDayOfWeekType={
                newLoanProduct.recalculationRestFrequencyDayOfWeekType
              }
              recalculationRestFrequencyOnDayType={
                newLoanProduct.recalculationRestFrequencyOnDayType
              }
              recalculationRestFrequencyNthDayType={
                newLoanProduct.recalculationRestFrequencyNthDayType
              }
              placeGuarantee={newLoanProduct.placeGuarantee}
              mandatoryGuarantee={newLoanProduct.mandatoryGuarantee}
              minimumGuaranteeFromOwnFunds={
                newLoanProduct.minimumGuaranteeFromOwnFunds
              }
              minimumGuaranteeFromGuarantor={
                newLoanProduct.minimumGuaranteeFromGuarantor
              }
              loanTrancte={newLoanProduct.loanTrancte}
              maxTrancheCount={newLoanProduct.maxTrancheCount}
              outstandingLoanBalance={newLoanProduct.outstandingLoanBalance}
              renderTooltip={renderTooltip}
            />
          </AppSettings>
        );
        break;
      case 3:
        // console.log('I AM HERE BABY 3')
        currentComponet = (
          <AppCharges>
            <LoanCharges
              radio={accountType}
              setChargesError={setChargesError}
              setRadio={setAccountType}
              handleNextScreen={handleNextCustomIndexSelect}
              handlePreviousScreen={handlePreviousCustomIndexSelect}
              updateLoanProductInfoHandler={updateLoanProductInfoHandler}
              template={loanProduct}
              fundSource={fundSource}
              loanPortfolio={loanPortfolio}
              transferInSusp={transferInSusp}
              incomeFromInterest={incomeFromInterest}
              incomeFromFees={incomeFromFees}
              incomeFromPenalties={incomeFromPenalties}
              incomeFromRecovery={incomeFromRecovery}
              overPaymentLia={overPaymentLia}
              lossesWrittenOff={lossesWrittenOff}
              interestReceivable={interestReceivable}
              penaltiesReceivable={penaltiesReceivable}
              feesReceivable={feesReceivable}
              setFundSource={setFundSource}
              setLoanPortfolio={setLoanPortfolio}
              setTransferInSusp={setTransferInSusp}
              setIncomeFromInterest={setIncomeFromInterest}
              setIncomeFromFees={setIncomeFromFees}
              setIncomeFromPenalties={setIncomeFromPenalties}
              setIncomeFromRecovery={setIncomeFromRecovery}
              setOverPaymentLia={setOverPaymentLia}
              setLossesWrittenOff={setLossesWrittenOff}
              setInterestReceivable={setInterestReceivable}
              setFeesReceivable={setFeesReceivable}
              setPenaltiesReceivable={setPenaltiesReceivable}
              principalOverdueAccountId={
                newLoanProduct.principalOverdueAccountId
              }
              interestInSuspenseAccountId={
                newLoanProduct.interestInSuspenseAccountId
              }
              interestOverdueAccountId={newLoanProduct.interestOverdueAccountId}
              canDefineInstallmentAmount={
                newLoanProduct.canDefineInstallmentAmount
              }
              accountMovesOutOfNPAOnlyOnArrearsCompletion={
                newLoanProduct.accountMovesOutOfNPAOnlyOnArrearsCompletion
              }
              graceOnArrearsAgeingValue={
                newLoanProduct.graceOnArrearsAgeingValue
              }
              overdueDaysForNPA={newLoanProduct.overdueDaysForNPA}
              principalThresholdForLastInstallment={
                newLoanProduct.principalThresholdForLastInstallment
              }
              isVariableInstallment={newLoanProduct.isVariableInstallment}
              minimumGap={newLoanProduct.minimumGap}
              maximumGap={newLoanProduct.maximumGap}
              canUseForTopup={newLoanProduct.canUseForTopup}
              isInterestRecalculationEnabled={
                newLoanProduct.isInterestRecalculationEnabled
              }
              preClosureInterestCalculationStrategy={
                newLoanProduct.preClosureInterestCalculationStrategy
              }
              recalculationRestFrequencyInterval={
                newLoanProduct.recalculationRestFrequencyInterval
              }
              interestRecalculationCompoundingMethod={
                newLoanProduct.interestRecalculationCompoundingMethod
              }
              rescheduleStrategyMethod={newLoanProduct.rescheduleStrategyMethod}
              recalculationRestFrequencyType={
                newLoanProduct.recalculationRestFrequencyType
              }
              recalculationCompoundingFrequencyInterval={
                newLoanProduct.recalculationCompoundingFrequencyInterval
              }
              recalculationCompoundingFrequencyType={
                newLoanProduct.recalculationCompoundingFrequencyType
              }
              recalculationCompoundingFrequencyNthDayType={
                newLoanProduct.recalculationCompoundingFrequencyNthDayType
              }
              recalculationCompoundingFrequencyDayOfWeekType={
                newLoanProduct.recalculationCompoundingFrequencyDayOfWeekType
              }
              recalculationCompoundingFrequencyOnDayType={
                newLoanProduct.recalculationCompoundingFrequencyOnDayType
              }
              isArrearsBasedOnOriginalSchedule={
                newLoanProduct.isArrearsBasedOnOriginalSchedule
              }
              recalculationRestFrequencyDayOfWeekType={
                newLoanProduct.recalculationRestFrequencyDayOfWeekType
              }
              recalculationRestFrequencyOnDayType={
                newLoanProduct.recalculationRestFrequencyOnDayType
              }
              recalculationRestFrequencyNthDayType={
                newLoanProduct.recalculationRestFrequencyNthDayType
              }
              placeGuarantee={newLoanProduct.placeGuarantee}
              mandatoryGuarantee={newLoanProduct.mandatoryGuarantee}
              minimumGuaranteeFromOwnFunds={
                newLoanProduct.minimumGuaranteeFromOwnFunds
              }
              minimumGuaranteeFromGuarantor={
                newLoanProduct.minimumGuaranteeFromGuarantor
              }
              loanTrancte={newLoanProduct.loanTrancte}
              maxTrancheCount={newLoanProduct.maxTrancheCount}
              outstandingLoanBalance={newLoanProduct.outstandingLoanBalance}
              setFullOverdueCharges={setFullOverdueCharges}
              setFullCharges={setFullCharges}
              feeToIncomeAccountMappings={feeToIncomeAccountMappings}
              penaltyToIncomeAccountMappings={penaltyToIncomeAccountMappings}
              paymentChannelToFundSourceMappings={
                paymentChannelToFundSourceMappings
              }
              setFeeToIncomeAccountMappings={setFeeToIncomeAccountMappings}
              setPenaltyToIncomeAccountMappings={
                setPenaltyToIncomeAccountMappings
              }
              setPaymentChannelToFundSourceMappings={
                setPaymentChannelToFundSourceMappings
              }
              setValueCharges={setValueCharges}
              setValueOverdueCharges={setValueOverdueCharges}
              renderTooltip={renderTooltip}
              valueCharges={valueCharges}
              valueOverdueCharges={valueOverdueCharges}
              showAdvance={showAdvance}
              setShowAdvance={setShowAdvance}
            />
          </AppCharges>
        );
        break;
      case 4:
        // console.log('I AM HERE BABY 4')
        currentComponet = (
          <AppPreview>
            <LoanReview
              handlePreviousScreen={handlePreviousCustomIndexSelect}
              handleSubmit={handleSubmit}
              customerLoanCounter={newLoanProduct.customerLoanCounter}
              currency={newLoanProduct.currency}
              decimalPlaces={newLoanProduct.decimalPlaces}
              multipleCurrency={newLoanProduct.multipleCurrency}
              installment={newLoanProduct.installment}
              terms={terms}
              principal={def_pricipal}
              max_pricipal={max_pricipal}
              min_pricipal={min_pricipal}
              repayment={newLoanProduct.def_repayment}
              min_repayment={newLoanProduct.min_repayment}
              max_repayment={newLoanProduct.max_repayment}
              max_interest={newLoanProduct.max_interest}
              min_interest={newLoanProduct.min_interest}
              def_interest={newLoanProduct.def_interest}
              repaymentEvery={newLoanProduct.repaymentEvery}
              repaid={newLoanProduct.repaid}
              repaidSelect={newLoanProduct.repaidSelect}
              disbursal={newLoanProduct.disbursal}
              amortizationType={newLoanProduct.amortization}
              isEqualAmortization={newLoanProduct.isEqualAmortization}
              interestType={newLoanProduct.interestType}
              transactionProcessingStrategyId={
                newLoanProduct.transactionProcessingStrategyId
              }
              interestCalculationPeriodType={
                newLoanProduct.interestCalculationPeriodType
              }
              inArrearsTolerance={newLoanProduct.inArrearsTolerance}
              graceOnPrincipalAndInterestPayment={
                newLoanProduct.graceOnPrincipalAndInterestPayment
              }
              graceOnArrearsAgeing={newLoanProduct.graceOnArrearsAgeing}
              daysInMonth={newLoanProduct.daysInMonth}
              daysInyear={newLoanProduct.daysInyear}
              accountType={accountType}
              loading={processing}
              fundSource={newLoanProduct.fundSource}
              loanPortfolio={newLoanProduct.loanPortfolio}
              transferInSusp={newLoanProduct.transferInSusp}
              incomeFromInterest={newLoanProduct.incomeFromInterest}
              incomeFromFees={newLoanProduct.incomeFromFees}
              incomeFromPenalties={newLoanProduct.incomeFromPenalties}
              incomeFromRecovery={newLoanProduct.incomeFromRecovery}
              overPaymentLia={newLoanProduct.overPaymentLia}
              lossesWrittenOff={newLoanProduct.lossesWrittenOff}
              interestReceivable={newLoanProduct.interestReceivable}
              feesReceivable={newLoanProduct.feesReceivable}
              penaltiesReceivable={newLoanProduct.penaltiesReceivable}
              canDefineInstallmentAmount={
                newLoanProduct.canDefineInstallmentAmount
              }
              accountMovesOutOfNPAOnlyOnArrearsCompletion={
                newLoanProduct.accountMovesOutOfNPAOnlyOnArrearsCompletion
              }
              graceOnArrearsAgeingValue={
                newLoanProduct.graceOnArrearsAgeingValue
              }
              overdueDaysForNPA={newLoanProduct.overdueDaysForNPA}
              principalThresholdForLastInstallment={
                newLoanProduct.principalThresholdForLastInstallment
              }
              isVariableInstallment={newLoanProduct.isVariableInstallment}
              minimumGap={newLoanProduct.minimumGap}
              maximumGap={newLoanProduct.maximumGap}
              canUseForTopup={newLoanProduct.canUseForTopup}
              isInterestRecalculationEnabled={
                newLoanProduct.isInterestRecalculationEnabled
              }
              preClosureInterestCalculationStrategy={
                newLoanProduct.preClosureInterestCalculationStrategy
              }
              recalculationRestFrequencyInterval={
                newLoanProduct.recalculationRestFrequencyInterval
              }
              interestRecalculationCompoundingMethod={
                newLoanProduct.interestRecalculationCompoundingMethod
              }
              rescheduleStrategyMethod={newLoanProduct.rescheduleStrategyMethod}
              recalculationRestFrequencyType={
                newLoanProduct.recalculationRestFrequencyType
              }
              recalculationCompoundingFrequencyInterval={
                newLoanProduct.recalculationCompoundingFrequencyInterval
              }
              recalculationCompoundingFrequencyType={
                newLoanProduct.recalculationCompoundingFrequencyType
              }
              recalculationCompoundingFrequencyNthDayType={
                newLoanProduct.recalculationCompoundingFrequencyNthDayType
              }
              recalculationCompoundingFrequencyDayOfWeekType={
                newLoanProduct.recalculationCompoundingFrequencyDayOfWeekType
              }
              recalculationCompoundingFrequencyOnDayType={
                newLoanProduct.recalculationCompoundingFrequencyOnDayType
              }
              isArrearsBasedOnOriginalSchedule={
                newLoanProduct.isArrearsBasedOnOriginalSchedule
              }
              recalculationRestFrequencyDayOfWeekType={
                newLoanProduct.recalculationRestFrequencyDayOfWeekType
              }
              recalculationRestFrequencyOnDayType={
                newLoanProduct.recalculationRestFrequencyOnDayType
              }
              recalculationRestFrequencyNthDayType={
                newLoanProduct.recalculationRestFrequencyNthDayType
              }
              placeGuarantee={newLoanProduct.placeGuarantee}
              mandatoryGuarantee={newLoanProduct.mandatoryGuarantee}
              minimumGuaranteeFromOwnFunds={
                newLoanProduct.minimumGuaranteeFromOwnFunds
              }
              minimumGuaranteeFromGuarantor={
                newLoanProduct.minimumGuaranteeFromGuarantor
              }
              loanTrancte={newLoanProduct.loanTrancte}
              maxTrancheCount={newLoanProduct.maxTrancheCount}
              outstandingLoanBalance={newLoanProduct.outstandingLoanBalance}
              template={loanProduct}
              charges={valueCharges}
              overdueCharges={valueOverdueCharges}
              feeToIncomeAccountMappings={feeToIncomeAccountMappings}
              penaltyToIncomeAccountMappings={penaltyToIncomeAccountMappings}
              paymentChannelToFundSourceMappings={
                paymentChannelToFundSourceMappings
              }
            />
          </AppPreview>
        );
        break;
      default:
      // console.log('I AM HERE BABY defult')
    }
    // console.log('HERE HERE HERE')
    return currentComponet;
  };

  // FUNCTION HERE FOR MOVING TO THE NEXT PAGE
  const handleNextCustomIndexSelect = () => {
    let newIndex = currentScreen + 1;
    if (newIndex <= 4) {
      setCurrentScreen(newIndex);
    }
  };
  // FUNCTION HERE FOR MOVING TO THE PREVIOUS PAGE
  const handlePreviousCustomIndexSelect = () => {
    let newIndex = currentScreen - 1;
    // console.log(newIndex, "and", currentScreen);
    if (newIndex >= 0) {
      setCurrentScreen(newIndex);
    }
  };

  const handleSubmit = async () => {
    let dataToSub = {
      accountingRule: accountType,
      allowAttributeOverrides: {
        amortizationType: newLoanProduct.amortizationType,
        interestType: newLoanProduct.interestType,
        transactionProcessingStrategyId:
          newLoanProduct.transactionProcessingStrategy,
        graceOnPrincipalAndInterestPayment:
          newLoanProduct.graceOnPrincipalAndInterestPayment,
        inArrearsTolerance: newLoanProduct.inArrearsTolerance,
        graceOnArrearsAgeing: newLoanProduct.graceOnArrearsAgeing,
        interestCalculationPeriodType:
          newLoanProduct.interestCalculationPeriodType,
        repaymentEvery: newLoanProduct.repaymentEvery,
      },
      allowVariableInstallments: newLoanProduct.isVariableInstallment,

      fundId: newLoanProduct.fund,
      amortizationType: loanProduct.amortizationType !== ""
        ? Number(loanProduct.amortizationType.id)
        : "",
      isEqualAmortization: newLoanProduct.isEqualAmortization,

      // charges: fullCharges,
      // overdueCharges: fullOverdueCharges,
      charges: [...fullOverdueCharges, ...fullCharges],

      closeDate: formatDate(closeDate, true),
      currencyCode: newLoanProduct.currency,
      dateFormat: "dd MMMM yyyy",
      daysInMonthType: newLoanProduct.daysInMonth !== ""
        ? Number(newLoanProduct.daysInMonth)
        : "",
      daysInYearType: newLoanProduct.daysInyear !== ""
        ? Number(newLoanProduct.daysInyear)
        : "",
      description: newLoanProduct.description,
      digitsAfterDecimal: newLoanProduct.decimalPlaces,
      fundSourceAccountId: fundSource,
      inMultiplesOf: newLoanProduct.multipleCurrency,
      includeInBorrowerCycle: newLoanProduct.customerLoanCounter
        ? "true"
        : "false",
      installmentAmountInMultiplesOf: newLoanProduct.installment == 0 ? "" : newLoanProduct.installment,
      interestCalculationPeriodType: newLoanProduct.interestCalculation !== ""
        ? Number(newLoanProduct.interestCalculation)
        : "",
      interestRateFrequencyType: newLoanProduct.interestSelect !== ""
        ? Number(newLoanProduct.interestSelect)
        : "",
      interestRatePerPeriod: newLoanProduct.def_interest !== ""
        ? Number(newLoanProduct.def_interest)
        : "",
      interestRateVariationsForBorrowerCycle: numberOfNominal,
      interestType: Number(newLoanProduct.interestMethod),
      isInterestRecalculationEnabled:
        newLoanProduct.isInterestRecalculationEnabled,
      isLinkedToFloatingInterestRates: false,
      locale: "en",
      allowPartialPeriodInterestCalcualtion:
        newLoanProduct.allowPartialPeriodInterestCalcualtion,
      maxInterestRatePerPeriod: newLoanProduct.max_interest,
      maxNumberOfRepayments: newLoanProduct.max_repayment,
      maxPrincipal: max_pricipal,
      minInterestRatePerPeriod: newLoanProduct.min_interest,
      minNumberOfRepayments: newLoanProduct.min_repayment,
      minPrincipal: min_pricipal,
      name: newLoanProduct.productName,
      numberOfRepaymentVariationsForBorrowerCycle: numberOfRepayment,
      numberOfRepayments: newLoanProduct.def_repayment,
      preClosureInterestCalculationStrategy:
        newLoanProduct.preClosureInterestCalculationStrategy !== ""
          ? Number(newLoanProduct.preClosureInterestCalculationStrategy)
          : "",
      principal: def_pricipal,
      principalVariationsForBorrowerCycle: numberOfPrincipalLC,
      repaymentEvery: newLoanProduct.repaid,
      repaymentFrequencyType: newLoanProduct.repaidSelect !== ""
        ? Number(newLoanProduct.repaidSelect)
        : "",
      shortName: newLoanProduct.shortName,
      startDate: formatDate(startDate, true),
      transactionProcessingStrategyId:
        newLoanProduct.transactionProcessingStrategyId,
      useBorrowerCycle: terms,
      minimumDaysBetweenDisbursalAndFirstRepayment: newLoanProduct.disbursal,
      graceOnInterestPayment: newLoanProduct.moratonium2,
      graceOnPrincipalPayment: newLoanProduct.moratonium,
      graceOnInterestCharged: newLoanProduct.graceOnInterestCharged,
      inArrearsTolerance: newLoanProduct.inArrearsToleranceValue,
      canDefineInstallmentAmount: newLoanProduct.canDefineInstallmentAmount,
      accountMovesOutOfNPAOnlyOnArrearsCompletion:
        newLoanProduct.accountMovesOutOfNPAOnlyOnArrearsCompletion,
      graceOnArrearsAgeing: newLoanProduct.graceOnArrearsAgeingValue,
      overdueDaysForNPA: newLoanProduct.overdueDaysForNPA,
      principalThresholdForLastInstallment:
        newLoanProduct.principalThresholdForLastInstallment,
      canUseForTopup: newLoanProduct.canUseForTopup,
      recalculationRestFrequencyInterval:
        newLoanProduct.recalculationRestFrequencyInterval,
      interestRecalculationCompoundingMethod:
        newLoanProduct.interestRecalculationCompoundingMethod !== ""
          ? Number(newLoanProduct.interestRecalculationCompoundingMethod)
          : 0,
      rescheduleStrategyMethod: newLoanProduct.rescheduleStrategyMethod
        ? Number(newLoanProduct.rescheduleStrategyMethod)
        : "",
      recalculationRestFrequencyType:
        newLoanProduct.recalculationRestFrequencyType !== ""
          ? Number(newLoanProduct.recalculationRestFrequencyType)
          : "",
      recalculationCompoundingFrequencyInterval:
        newLoanProduct.recalculationCompoundingFrequencyInterval !== ""
          ? Number(newLoanProduct.recalculationCompoundingFrequencyInterval)
          : "",
      recalculationCompoundingFrequencyType:
        newLoanProduct.recalculationCompoundingFrequencyType !== ""
          ? Number(newLoanProduct.recalculationCompoundingFrequencyType)
          : "",
      recalculationCompoundingFrequencyNthDayType:
        newLoanProduct.recalculationCompoundingFrequencyNthDayType !== ""
          ? Number(newLoanProduct.recalculationCompoundingFrequencyNthDayType)
          : "",
      recalculationCompoundingFrequencyDayOfWeekType:
        newLoanProduct.recalculationCompoundingFrequencyDayOfWeekType !== ""
          ? Number(
              newLoanProduct.recalculationCompoundingFrequencyDayOfWeekType
            )
          : "",
      recalculationCompoundingFrequencyOnDayType:
        newLoanProduct.recalculationCompoundingFrequencyOnDayType !== ""
          ? Number(newLoanProduct.recalculationCompoundingFrequencyOnDayType)
          : "",
      isArrearsBasedOnOriginalSchedule:
        newLoanProduct.isArrearsBasedOnOriginalSchedule,
      recalculationRestFrequencyDayOfWeekType:
        newLoanProduct.recalculationRestFrequencyDayOfWeekType !== ""
          ? Number(newLoanProduct.recalculationRestFrequencyDayOfWeekType)
          : "",
      recalculationRestFrequencyOnDayType:
        newLoanProduct.recalculationRestFrequencyOnDayType !== ""
          ? Number(newLoanProduct.recalculationRestFrequencyOnDayType)
          : "",
      recalculationRestFrequencyNthDayType:
        newLoanProduct.recalculationRestFrequencyNthDayType !== ""
          ? Number(newLoanProduct.recalculationRestFrequencyNthDayType)
          : "",
      holdGuaranteeFunds: newLoanProduct.placeGuarantee,
      mandatoryGuarantee: newLoanProduct.mandatoryGuarantee,
      minimumGuaranteeFromOwnFunds: newLoanProduct.minimumGuaranteeFromOwnFunds,
      minimumGuaranteeFromGuarantor:
        newLoanProduct.minimumGuaranteeFromGuarantor,
      multiDisburseLoan: newLoanProduct.loanTrancte,
      maxTrancheCount: newLoanProduct.maxTrancheCount,
      outstandingLoanBalance: newLoanProduct.outstandingLoanBalance,
      loanPortfolioAccountId: loanPortfolio,
      interestOnLoanAccountId: incomeFromInterest,
      incomeFromFeeAccountId: incomeFromFees,
      incomeFromPenaltyAccountId: incomeFromPenalties,
      incomeFromRecoveryAccountId: incomeFromRecovery,
      writeOffAccountId: lossesWrittenOff,
      transfersInSuspenseAccountId: transferInSusp,
      overpaymentLiabilityAccountId: overPaymentLia,
      receivableInterestAccountId: interestReceivable,
      receivableFeeAccountId: feesReceivable,
      receivablePenaltyAccountId: penaltiesReceivable,
      interestOverdueAccountId: newLoanProduct.interestOverdueAccountId,
      principalOverdueAccountId: newLoanProduct.principalOverdueAccountId,
      interestInSuspenseAccountId: newLoanProduct.interestInSuspenseAccountId,
      feeToIncomeAccountMappings,
      penaltyToIncomeAccountMappings,
      paymentChannelToFundSourceMappings,
    };
    let dataTosub2 = {
      ...dataToSub,
      minimumGap: newLoanProduct.isVariableInstallment
        ? newLoanProduct.minimumGap
        : null,
      maximumGap: newLoanProduct.isVariableInstallment
        ? newLoanProduct.maximumGap
        : null,
    };
    if (chargesError || detailsError || termsError || settingsError) {
      dispatch(
        Toast({
          text: "Please fill the required fields",
          icon: "error",
          timer: 2000,
        })
      );
    } else {
      setLoading(true);
      await dispatch(
        updateLoanProductInfo(
          newLoanProduct.isVariableInstallment ? dataTosub2 : dataToSub,
          match.params.id,
          history
        )
      );
      setLoading(false);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Edit loan products</h3>
            <p className="font-weight sub-title mt-1">
              <span className="text-info mr-1">
                <Link to="/core/products">Products </Link>
              </span>
              /{" "}
              <span className="text-info mr-1">
                <Link to="/core/products/loan-products">Loan products </Link>
              </span>
              / Edit loan products
            </p>
          </div>
        </div>

        <div className="row" style={{ minHeight: "calc(100vh - 250px)" }}>
          <div className="col-sm-12 col-md-12">
            <ScrollToTop>
              <ContentWrapper className="application-wrapper">
                <div className="container">
                  {renderComponent()}
                  <br />
                  <br />
                  <br />
                </div>
              </ContentWrapper>
            </ScrollToTop>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditLoanProductView;
