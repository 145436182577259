import React, { useState, useEffect } from "react";
import { NewActionButton, FormClass, FormElements } from "../styles";
import { Modal, Spinner } from "react-bootstrap";
import { Input } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { generateUserApiKey } from "../../../../../actions/configurationActions";

import Toast from "../../../../../util/Toast";

const GenerateApiKey = ({ onHide, id, history }) => {
  const dispatch = useDispatch();

  const generatedKey = useSelector((state) => state.userReducer);

  const { newUserKey } = generatedKey;

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [status, setStatus] = useState(1);

  useEffect(() => {
    if (newUserKey && newUserKey.data) {
      setStatus(2);
    }
  }, [newUserKey]);
  const handleGenerateApiKey = async () => {
    if (!name) {
      dispatch(Toast({ text: "Please add Api Name", icon: "info" }));
    } else {
      let dataToSend = {
        environment: "test",
        appuser_id: id,
        name: name,
      };
      let header = {
        action: "CREATE",
      };
      setLoading(true);
      await dispatch(generateUserApiKey(dataToSend, header));
      setLoading(false);
    }
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>Generate Api Key</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <FormElements style={{ width: "100%" }}>
            <div className="row">
              {status === 2 ? (
                <>
                  <div className="col-sm-12 col-md-12">
                    <p className="mb-5 mt-3">
                      You can only copy this generated API key once. <br /> So
                      please copy your API key!
                    </p>
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-label">Api Key</label>
                      <Input
                        style={{ width: "60%" }}
                        size="md"
                        placeholder="Api Key"
                        name="apiKey"
                        value={newUserKey && newUserKey.data}
                        readOnly
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-sm-12 col-md-12">
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-label">Name Of Api Key*</label>
                      <Input
                        style={{ width: "60%" }}
                        size="md"
                        placeholder="Name of Api Key"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </FormElements>
        </FormClass>
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-1">
          <NewActionButton color="#2C1DFF" onClick={onHide}>
            Cancel
          </NewActionButton>
          {status === 2 ? (
            <>
              <CopyToClipboard
                text={newUserKey && newUserKey.data}
                onCopy={() =>
                  dispatch(Toast({ text: "Api Key Copied!", icon: "info" }))
                }
              >
                <NewActionButton
                  color="#2C1DFF"
                  style={{ margin: "0px 0px 0 10px" }}
                >
                  Copy Api Key
                </NewActionButton>
              </CopyToClipboard>
            </>
          ) : (
            <>
              <NewActionButton
                color="#2C1DFF"
                onClick={handleGenerateApiKey}
                style={{ margin: "0px 0px 0 10px" }}
              >
                {loading ? (
                  <Spinner
                    animation="border"
                    color="#fff"
                    className="mr-2 text-white"
                    size="sm"
                  />
                ) : (
                  ""
                  // <img
                  //   src={
                  //     require("../../../../../assets/images/icons/delete.svg")
                  //       .default
                  //   }
                  //   alt="submit icon"
                  // />
                )}
                Generate
              </NewActionButton>
            </>
          )}
        </div>
      </Modal.Footer>
    </>
  );
};

export default GenerateApiKey;
