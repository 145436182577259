import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Input, Select } from "@chakra-ui/react";
// import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import {
  getAllMakerCheckersTemplate,
  getAllMakerCheckers,
} from "../../../../../../actions/businessActions";
import { formatDateString } from "../../../../../../util/FormatDate";

import { ActionButton } from "../../styles";

const FilterModal = ({
  header,
  onHide,
  type,
  activationDate,
  setActivationDate,
}) => {
  const dispatch = useDispatch();
  const {
    checkerMakerCheckerTemplate: { actionNames },
  } = useSelector((state) => state.businessReducer);
  // console.log(checkerMakerChecker);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [actionType, setActionType] = useState("");
  const [userId, setUserId] = useState("");

  useEffect(() => {
    dispatch(getAllMakerCheckersTemplate());
  }, [dispatch]);

  const submit = () => {
    const userIdVal = userId ? userId : "";
    const myStartDate = startDate
      ? formatDateString(startDate, "yyyy-MMMM-dd")
      : "";
    const myEndDate = endDate ? formatDateString(endDate, "yyyy-MMMM-dd") : "";
    const action = actionType ? actionType : "";

    dispatch(getAllMakerCheckers(action, myStartDate, myEndDate, userIdVal));
    onHide();
  };

  return (
    <div className="new">
      <Modal.Header className="h5">Advanced search</Modal.Header>
      <Modal.Body className="wc-modal">
        <div className="row pb-3">
          <div className="col-md-6">
            <div className="row d-flex align-items-center">
              <div className="col-4 text-label">Start Date</div>
              <div className="col-8">
                <div className="datepicker-100-width">
                  <DatePicker
                    id="from-date"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="start date"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row d-flex align-items-center">
              <div className="col-4 text-label">End Date</div>
              <div className="col-8">
                <div className="datepicker-100-width">
                  <DatePicker
                    id="from-date"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="start date"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col-md-6">
            <div className="row d-flex align-items-center">
              <div className="col-4 text-label">Action type</div>
              <div className="col-8">
                {/* <Select
                  selected={actionType}
                  onChange={(e) => setActionType(e.target.value)}
                  value={actionType}
                  placeholder="Select action type"
                  style={{ width: "100% !important;" }}
                >
                  {actionNames?.map((each) => (
                    <Option value={each}>{each}</Option>
                  ))}
                </Select> */}
                <div className="select-100-width">
                  <Select
                    isFullWidth="true"
                    name="officeId"
                    style={{ width: "100% !important;" }}
                    placeholder="Select action type"
                    value={actionType}
                    onChange={(e) => setActionType(e.target.value)}
                  >
                    {actionNames?.map((each) => (
                      <option key={each} value={each}>
                        {each}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row d-flex align-items-center">
              <div className="col-4 text-label">User ID</div>
              <div className="col-8">
                <Input
                  id="user-id"
                  placeholder="User ID"
                  onChange={(e) => setUserId(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex mt-1">
          <ActionButton
            background="#ffffff"
            border="1px solid #2C1DFF"
            color="#2C1DFF"
            onClick={onHide}
          >
            Cancel
          </ActionButton>{" "}
          <ActionButton background="#2C1DFF" onClick={submit}>
            Continue
          </ActionButton>
        </div>
      </Modal.Footer>
    </div>
  );
};

export default FilterModal;
