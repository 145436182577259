import React from "react";
import { Modal, Table } from "react-bootstrap";
import { ActionButton } from "../../styles";
import { formatCurrency } from "../../../../../util/FormatAmount";
import { formatDate } from "../../../../../util/FormatDate";
import { textToUpperCase } from "../../../../../util/Tools";

const AmortizationDetailsModal = ({
  onHide,
  amortizationDetails,
  glAcccounts,
}) => {
  let { amor } = amortizationDetails;
  let {
    amount,
    created_date,
    end_date,
    currency_code,
    start_date,
    last_run_date,
    daily_amount,
    credit_gl_id,
    debit_gl_id,
    description,
    active,
    tenor,
    name,
  } = amor || {};

  const returnGlName = (id) => {
    let glName;
    let glAccount = glAcccounts.filter((item) => item.id === id);
    glName = glAccount[0] && `${glAccount[0].name} ${glAccount[0].glCode}`;
    return glName;
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>Amortization Details</Modal.Title>
      </Modal.Header>
      <Modal.Body className="wc-modal">
        <div className="d-flex align-items-center justify-content-center flex-direction-coloumn">
          <div className="full-width mt-4">
            <Table responsive striped bordered hover>
              <thead className="border-0">
                <tr>
                  <td className="font-weight-bold md-font-weight">Name</td>
                  <td>{name && name}</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="font-weight-bold md-font-weight">Amount</td>
                  <td>
                    {currency_code && currency_code}{" "}
                    {amount && formatCurrency(Number(amount))}
                  </td>
                </tr>
                <tr>
                  <td className="font-weight-bold md-font-weight">
                    Daily Amount
                  </td>
                  <td>
                    {currency_code && currency_code}{" "}
                    {daily_amount ? `${daily_amount}` : ""}
                  </td>
                </tr>
                <tr>
                  <td className="font-weight-bold md-font-weight">
                    Created On
                  </td>
                  <td>{formatDate(created_date)}</td>
                </tr>
                <tr>
                  <td className="font-weight-bold md-font-weight">
                    Start Date
                  </td>
                  <td>{formatDate(start_date)}</td>
                </tr>
                <tr>
                  <td className="font-weight-bold md-font-weight">End Date</td>
                  <td>{formatDate(end_date)}</td>
                </tr>
                <tr>
                  <td className="font-weight-bold md-font-weight">
                    Last Run Date
                  </td>
                  <td>{formatDate(last_run_date)}</td>
                </tr>
                <tr>
                  <td className="font-weight-bold md-font-weight">
                    Credit Account
                  </td>
                  <td>{credit_gl_id && returnGlName(credit_gl_id)}</td>
                </tr>
                <tr>
                  <td className="font-weight-bold md-font-weight">
                    Debit Account
                  </td>
                  <td>{debit_gl_id && returnGlName(debit_gl_id)}</td>
                </tr>
                <tr>
                  <td className="font-weight-bold md-font-weight">
                    Description
                  </td>
                  <td>{description}</td>
                </tr>
                <tr>
                  <td className="font-weight-bold md-font-weight">Is Active</td>
                  <td>{active === 1 ? "Active" : "InActive"}</td>
                </tr>
                <tr>
                  <td className="font-weight-bold md-font-weight">
                    Number Of Days
                  </td>
                  <td>{tenor}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="spaceBetweenFlexEnd mt-3">
          <div className="">
            <ActionButton color="#2C1DFF" onClick={onHide}>
              <img
                src={
                  require("../../../../../assets/images/icons/cancel.svg")
                    .default
                }
                alt="cancel icon"
              />
              Cancel
            </ActionButton>
          </div>
        </div>
      </Modal.Footer>
    </>
  );
};

export default AmortizationDetailsModal;
