import {
  SETTLE_EOY_REQUEST,
  SETTLE_EOY_SUCCESS,
  SETTLE_EOY_FAIL,
  SETTLE_EOY_RESET,
} from "../constants/settlementConstants";

export const sendEoySettlementReducer = (state = {eoyGlResponseBalance: {}}, action) => {
  switch (action.type) {
    case SETTLE_EOY_REQUEST:
      return { loading: true, ...state };
    case SETTLE_EOY_SUCCESS:
      return { ...state, loading: false, eoyGlResponseBalance: action.payload };
    case SETTLE_EOY_FAIL:
      return { ...state, loading: false, error: action.payload };
    case SETTLE_EOY_RESET:
      return {
        ...state,
        eoyGlResponseBalance: null,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
