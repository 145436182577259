/**
 * Removes empty values (null, empty string, or undefined) from an object.
 *
 * @param {Object} obj The object to be cleaned.
 * @returns {Object} A new object with empty values removed.
 */
export const removeEmptyValues = (obj) => {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([key, value]) => value !== null && value !== "" && value !== undefined
    )
  );
};
