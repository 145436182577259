import React, { useState } from "react";
import { Table, Modal } from "react-bootstrap";
import { NextActionButton, FormTitle } from "../../../styles";
import { formatCurrency } from "../../../../../../../../util/FormatAmount";
import { formatDate } from "../../../../../../../../util/FormatDate";
import ModalContainer from "../../../../../../components/ModalContainer";

const FixedPreview = ({
  handlePreviousScreen,
  handleSubmit,
  description,
  shortName,
  digitsAfterDecimal,
  currencyCode,
  inMultiplesOf,
  minDepositAmount,
  depositAmount,
  maxDepositAmount,
  interestCompoundingPeriodType,
  interestCalculationType,
  interestCalculationDaysInYearType,
  interestPostingPeriodType,
  lockinPeriodFrequency,
  lockinPeriodFrequencyType,
  minDepositTerm,
  minDepositTermTypeId,
  maxDepositTerm,
  maxDepositTermTypeId,
  preClosurePenalInterest,
  preClosurePenalInterestOnTypeId,
  taxGroupId,
  inMultiplesOfDepositTerm,
  inMultiplesOfDepositTermTypeId,
  withHoldTax,
  endDate,
  validFrom,
  chartSlabs,
  charges,
  template,
  // myCharges,
  accountingRule,
  accountType,
  savingsReferenceAccountId,
  savingsControlAccountId,
  interestPayableAccountId,
  transfersInSuspenseAccountId,
  interestOnSavingsAccountId,
  incomeFromFeeAccountId,
  incomeFromPenaltyAccountId,
  feeToIncomeAccountMappings,
  paymentChannelToFundSourceMappings,
  penaltyToIncomeAccountMappings,
  amountRangeFrom,
  annualInterestRate,
  periodType,
  fromPeriod,
  setAccountType,
  radio,
  setRadio,
  // accountType
}) => {
  const returnPaymentType = (id) => {
    let paymentType = template
      ? template.paymentTypeOptions.filter((item) => item.id === Number(id))
      : [];
    let paymentTypeValue = paymentType.length > 0 ? paymentType[0].name : "";
    return paymentTypeValue;
  };

  const returnFundSource = (id) => {
    let fundSource =
      template && template.accountingMappingOptions
        ? template.accountingMappingOptions.assetAccountOptions.filter(
            (item) => item.id === Number(id)
          )
        : [];
    let fundSourceValue = fundSource.length > 0 ? fundSource[0].name : "";
    return fundSourceValue;
  };

  const returnFeeType = (id) => {
    let feeType = template
      ? template.chargeOptions.filter((item) => item.id === Number(id))
      : [];
    let feeTypeValue = feeType.length > 0 ? feeType[0].name : "";
    // console.log(feeTypeValue , 'feeTypeValue');
    return feeTypeValue;
  };

  const returnIncomeAccount = (id) => {
    let incomeAccount =
      template && template.accountingMappingOptions
        ? template.accountingMappingOptions.incomeAccountOptions.filter(
            (item) => item.id === Number(id)
          )
        : [];
    let incomeAccountValue =
      incomeAccount.length > 0 ? incomeAccount[0].name : "";
    return incomeAccountValue;
  };

  const returnPenaltyType =(id) => {
    let penaltyType = template 
    ? template.penaltyOptions.filter((item) => item.id === Number(id))
    : [];
    let penaltyTypeValue = penaltyType.length > 0 ? penaltyType[0].name : "";
    return penaltyTypeValue;

  }

  const [modalState, toggleModal] = useState(false);
  const [currentIncentives, setCurrentIncentives] = useState([]);

  const incentiveModal = (currentIncentives) => {
    const returnAttribute = (id) => {
      let attribute =
        template !== undefined &&
        !!template.chartTemplate.attributeNameOptions &&
        !!template.chartTemplate.attributeNameOptions.length &&
        template.chartTemplate.attributeNameOptions.filter(
          (item) => item.id === Number(id)
        );

      return attribute[0].value || "";
    };
    const returnCondition = (id) => {
      let attribute =
        template !== undefined &&
        !!template.chartTemplate.conditionTypeOptions &&
        !!template.chartTemplate.conditionTypeOptions.length &&
        template.chartTemplate.conditionTypeOptions.filter(
          (item) => item.id === Number(id)
        );

      return attribute[0].value || "";
    };

    const returnType = (id) => {
      let attribute =
        template !== undefined &&
        !!template.chartTemplate.incentiveTypeOptions &&
        !!template.chartTemplate.incentiveTypeOptions.length &&
        template.chartTemplate.incentiveTypeOptions.filter(
          (item) => item.id === Number(id)
        );

      return attribute[0].value || "";
    };
    return (
      <>
        <Modal.Header>
          <Modal.Title>Incentives</Modal.Title>
        </Modal.Header>
        <Modal.Body className="wc-modal">
          <h2 className="my-3" style={{ fontSize: "20px" }}>
            Customer Attributes
          </h2>
          <div className="d-flex align-items-center text-label justify-content-center flex-direction-coloumn">
            <Table className="child-table">
              <thead className="">
                <tr style={{ backgroundColor: "#FAFAFA" }}>
                  <th>Attribute</th>
                  <th>Condition</th>
                  <th>Value</th>
                  <th>Type</th>
                  <th>Interest</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {currentIncentives.map((incentives, index) => {
                  return (
                    <tr key={index}>
                      <td>{returnAttribute(incentives.attributeName)}</td>
                      <td>{returnCondition(incentives.conditionType)}</td>
                      <td>{incentives.attributeValue}</td>
                      <td>{returnType(incentives.incentiveType)}</td>
                      <td>{incentives.amount}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex mt-4">
            <div className="btn-small" onClick={() => toggleModal(false)}>
              Close
            </div>
          </div>
        </Modal.Footer>
      </>
    );
  };

  let interestCompoundingPeriodName = "";
  let interestCalculationName = "";
  let interestCalculationDaysInYearName = "";
  let interestPostingPeriodTypeName = "";

  template &&
    template !== undefined &&
    !!template.interestCalculationTypeOptions &&
    !!template.interestCalculationTypeOptions.length &&
    template.interestCalculationTypeOptions.forEach((typeOptions) => {
      if (typeOptions.id === Number(interestCalculationType)) {
        interestCalculationName = typeOptions.value;
      }
    });

  template &&
    template !== undefined &&
    !!template.interestCalculationDaysInYearTypeOptions &&
    !!template.interestCalculationDaysInYearTypeOptions.length &&
    template.interestCalculationDaysInYearTypeOptions.forEach((typeOptions) => {
      if (typeOptions.id === Number(interestCalculationDaysInYearType)) {
        interestCalculationDaysInYearName = typeOptions.value;
      }
    });

  template &&
    template !== undefined &&
    !!template.interestCompoundingPeriodTypeOptions &&
    !!template.interestCompoundingPeriodTypeOptions.length &&
    template.interestCompoundingPeriodTypeOptions.forEach((typeOptions) => {
      if (typeOptions.id === Number(interestCompoundingPeriodType)) {
        interestCompoundingPeriodName = typeOptions.value;
      }
    });

  template &&
    template !== undefined &&
    !!template.interestPostingPeriodTypeOptions &&
    !!template.interestPostingPeriodTypeOptions.length &&
    template.interestPostingPeriodTypeOptions.forEach((typeOptions) => {
      if (typeOptions.id === Number(interestPostingPeriodType)) {
        interestPostingPeriodTypeName = typeOptions.value;
      }
    });

  // For Settings
  let lockinPeriodFrequencyTypeName = "";
  let minDepositTermTypeIdName = "";
  let maxDepositTermTypeIdName = "";
  let inMultiplesOfDepositTermTypeName = "";

  template &&
    template !== undefined &&
    !!template.lockinPeriodFrequencyTypeOptions &&
    !!template.lockinPeriodFrequencyTypeOptions.length &&
    template.lockinPeriodFrequencyTypeOptions.forEach((typeOptions) => {
      if (typeOptions.id === Number(lockinPeriodFrequencyType)) {
        lockinPeriodFrequencyTypeName = typeOptions.value;
      }
    });

  template &&
    template !== undefined &&
    !!template.periodFrequencyTypeOptions &&
    !!template.periodFrequencyTypeOptions.length &&
    template.periodFrequencyTypeOptions.forEach((typeOptions) => {
      if (typeOptions.id === Number(minDepositTermTypeId)) {
        minDepositTermTypeIdName = typeOptions.value;
      }
    });

  template &&
    template !== undefined &&
    !!template.periodFrequencyTypeOptions &&
    !!template.periodFrequencyTypeOptions.length &&
    template.periodFrequencyTypeOptions.forEach((typeOptions) => {
      if (typeOptions.id === Number(maxDepositTermTypeId)) {
        maxDepositTermTypeIdName = typeOptions.value;
      }
    });

  template &&
    template !== undefined &&
    !!template.periodFrequencyTypeOptions &&
    !!template.periodFrequencyTypeOptions.length &&
    template.periodFrequencyTypeOptions.forEach((typeOptions) => {
      if (typeOptions.id === Number(inMultiplesOfDepositTermTypeId)) {
        inMultiplesOfDepositTermTypeName = typeOptions.value;
      }
    });

  let savingsPrefrenceName = "";
  let savingsControlName = "";
  let savingstransferSuspenceName = "";
  let interestPayableName = "";
  let interestOnSavings = "";
  let incomeFees = "";
  let incomePenalties = "";

  template &&
    template !== undefined &&
    !!template.accountingMappingOptions &&
    !!template.accountingMappingOptions.assetAccountOptions &&
    !!template.accountingMappingOptions.assetAccountOptions.length &&
    template.accountingMappingOptions.assetAccountOptions.forEach(
      (typeOptions) => {
        if (typeOptions.id === Number(savingsReferenceAccountId)) {
          savingsPrefrenceName = typeOptions.name;
        }
      }
    );

  template &&
    template !== undefined &&
    !!template.accountingMappingOptions &&
    !!template.accountingMappingOptions.liabilityAccountOptions &&
    !!template.accountingMappingOptions.liabilityAccountOptions.length &&
    template.accountingMappingOptions.liabilityAccountOptions.forEach(
      (typeOptions) => {
        if (typeOptions.id === Number(savingsControlAccountId)) {
          savingsControlName = typeOptions.name;
        }
        if (typeOptions.id === Number(transfersInSuspenseAccountId)) {
          savingstransferSuspenceName = typeOptions.name;
        }
        if (typeOptions.id === Number(interestPayableAccountId)) {
          interestPayableName = typeOptions.name;
        }
      }
    );

  template &&
    template !== undefined &&
    !!template.accountingMappingOptions &&
    !!template.accountingMappingOptions.expenseAccountOptions &&
    !!template.accountingMappingOptions.expenseAccountOptions.length &&
    template.accountingMappingOptions.expenseAccountOptions.forEach(
      (typeOptions) => {
        if (typeOptions.id === Number(interestOnSavingsAccountId)) {
          interestOnSavings = typeOptions.name;
        }
      }
    );

  template &&
    template !== undefined &&
    !!template.accountingMappingOptions &&
    !!template.accountingMappingOptions.incomeAccountOptions &&
    !!template.accountingMappingOptions.incomeAccountOptions.length &&
    template.accountingMappingOptions.incomeAccountOptions.forEach(
      (typeOptions) => {
        if (typeOptions.id === Number(incomeFromFeeAccountId)) {
          incomeFees = typeOptions.name;
        }
        if (typeOptions.id === Number(incomeFromPenaltyAccountId)) {
          incomePenalties = typeOptions.name;
        }
      }
    );

  const result = chartSlabs;
  // console.log(result, 'resulttt')
  // const chart = Object.values(result);
  // console.log(chart);

  return (
    <>
      <ModalContainer show={modalState}>
        {modalState && incentiveModal(currentIncentives)}
      </ModalContainer>
      <div className="col-sm-12 col-md-12 d-flex ">
        <div className="container">
          {/* Details */}
          <div>
            <FormTitle>
              <p className="text-info">Details</p>
              <div className="lineThrough"></div>
            </FormTitle>
            <div className="pt-2">
              <div
                className="form-group d-flex  align-items-center text-label"
                style={{ marginBottom: "1rem !important" }}
              >
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Description&nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {description}
                </p>
              </div>
              <div
                className="form-group d-flex  align-items-center text-label"
                style={{ marginBottom: "1rem !important" }}
              >
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Short name&nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>{shortName}</p>
              </div>
            </div>
          </div>

          {/* Items */}
          <div className="pt-1">
            <FormTitle>
              <p className="text-info">Items</p>
              <div className="lineThrough"></div>
            </FormTitle>
            <div className="pt-2">
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Currency&nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {currencyCode}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Decimal Places&nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {digitsAfterDecimal}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Currency In Multiples Of&nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {inMultiplesOf}
                </p>
              </div>{" "}
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Minimum Deposit Amount &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {formatCurrency(Number(minDepositAmount))}
                </p>
              </div>{" "}
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Default Deposit Amount&nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {formatCurrency(Number(depositAmount))}
                </p>
              </div>{" "}
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Maximum Deposit Amount&nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {formatCurrency(Number(maxDepositAmount))}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Interest Compounding Period&nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {interestCompoundingPeriodName}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Interest Posting Period&nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {interestPostingPeriodTypeName}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Interest Calculated Using&nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {interestCalculationName}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Days In Years&nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {interestCalculationDaysInYearName}
                </p>
              </div>
            </div>
          </div>

          {/* Settings */}
          <div className="pt-1">
            <FormTitle>
              <p className="text-info">Settings</p>
              <div className="lineThrough"></div>
            </FormTitle>

            <div className="pt-2">
              {/* <div className="form-group d-flex  align-items-center text-label">
                <h3 style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }} className="col-md-4"> Minimum Opening Balance&nbsp;&nbsp;&nbsp;:</h3>
                <p>{}</p>
              </div> */}
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Lock-In Period&nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {lockinPeriodFrequency} {lockinPeriodFrequencyTypeName}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Minimum Deposit Term&nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {minDepositTerm} {minDepositTermTypeIdName}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Maximum Deposit Term&nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {maxDepositTerm} {maxDepositTermTypeIdName}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Deposit Term In Multiple Of &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {inMultiplesOfDepositTerm} {inMultiplesOfDepositTermTypeName}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Apply Penal Interest (Less)&nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {/* {preClosurePenalInterest} % On */}
                  {preClosurePenalInterest} % 
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Is Withhold Tax Applicable&nbsp;&nbsp;&nbsp;:
                </h3>
                <p
                  style={{ width: "60%", fontWeight: "bold" }}
                >{`${withHoldTax}`}</p>
              </div>
            </div>
          </div>

          {/* Interest rate Chart */}

          <div className="pt-1">
            <FormTitle>
              <p className="text-info">Interest Rate</p>
              <div className="lineThrough"></div>
            </FormTitle>
            <div className="pt-2">
              {/* Valid from date */}
              <div
                style={{
                  marginBottom: "1rem !important",
                }}
                className="form-group d-flex  align-items-center text-label"
              >
                {" "}
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Valid from date:&nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {!!validFrom ? formatDate(validFrom) : ""}
                </p>
              </div>
              {/* End date */}
              <div
                style={{
                  marginBottom: "1rem !important",
                }}
                className="form-group d-flex  align-items-center text-label"
              >
                {" "}
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  End date&nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {!!endDate ? formatDate(endDate) : ""}
                </p>
              </div>
            </div>
            {/* Interest rate chart */}

            {/* <div
                  className="form-group d-flex  align-items-center text-label"
                  style={{ marginBottom: "1rem !important" }}
                >
                  <h3 style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }} className="col-md-4">Period From&nbsp;&nbsp;&nbsp;:</h3>
                  <p style={{ width: "60%", fontWeight: "bold" }}>{result.fromPeriod}</p>
                </div> */}
            {/* <div
                  className="form-group d-flex  align-items-center text-label"
                  style={{ marginBottom: "1rem !important" }}
                >
                  <h3 style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }} className="col-md-4">Amount&nbsp;&nbsp;&nbsp;:</h3>
                  <p style={{ width: "60%", fontWeight: "bold" }}>{result.amountRangeFrom}</p>
                </div> */}
             <div
                    style={{
                      marginBottom: "1rem !important",
                    }}
                    className="form-group d-flex  align-items-center text-label"
                  >
              <h3
                style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                className="col-md-4"
              >
                Interest&nbsp;&nbsp;&nbsp;:
              </h3>
              <p style={{ width: "60%", fontWeight: "bold" }}>
                {result.annualInterestRate}
              </p>
            </div>
            {/* <div
                  className="form-group d-flex  align-items-center text-label"
                  style={{ marginBottom: "1rem !important" }}
                >
                  <h3 style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }} className="col-md-4">Description&nbsp;&nbsp;&nbsp;:</h3>
                  <p style={{ width: "60%", fontWeight: "bold" }}>{result.description}</p>
                </div> */}
          </div>

          {/* Charges */}
          <div className="pt-1">
            <FormTitle>
              <p className="text-info">Charges</p>
              <div className="lineThrough"></div>
            </FormTitle>
            <div className="pt-2">
              <Table className="child-table">
                <thead className="">
                  <tr style={{ backgroundColor: "#FAFAFA" }}>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Amount</th>
                    <th>Collected on</th>
                  </tr>
                </thead>
                <tbody>
                  {charges.length > 0
                    ? charges.map(
                        ({
                          id,
                          name,
                          chargeCalculationType,
                          amount,
                          chargeTimeType,
                        }) => (
                          <tr key={id}>
                            <td>{name}</td>
                            <td>
                              {chargeCalculationType &&
                                chargeCalculationType.value}
                            </td>
                            <td>{formatCurrency(Number(amount))}</td>
                            <td>{chargeTimeType && chargeTimeType.value}</td>
                          </tr>
                        )
                      )
                    : null}
                  {/* {!!charges && !!myCharges.length
                    ? myCharges.map((charge, index) => {
                        return (
                          <tr>
                            <td>{!!charge.name ? charge.name : ""}</td>
                            <td>
                              {!!charge &&
                              !!charge.chargeCalculationType &&
                              !!charge.chargeCalculationType.value
                                ? charge.chargeCalculationType.value
                                : ""}
                            </td>
                            <td>
                              {!!charge && charge.amount ? charge.amount : ""}
                            </td>
                            <td>
                              {!!charge &&
                              !!charge.chargeTimeType &&
                              !!charge.chargeTimeType.value
                                ? charge.chargeTimeType.value
                                : ""}
                            </td>
                          </tr>
                        );
                      })
                    : ""} */}
                </tbody>
              </Table>
            </div>
          </div>

          <div className="pt-1"></div>
          {/* Accounting */}
          <div className="pt-1">
            <FormTitle>
              <p className="text-info">Accounting</p>
              <div className="lineThrough"></div>
            </FormTitle>
            <div className="pt-2">
              <div
                className="form-group d-flex  align-items-center text-label"
                style={{ marginBottom: "1rem !important" }}
              >
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Account type&nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {radio === "1" ? "None" : "Cash"}
                </p>
              </div>
              {radio === "2" ? (
                <>
                  {" "}
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Savings Preference&nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p style={{ width: "60%", fontWeight: "bold" }}>
                      {savingsPrefrenceName}
                    </p>
                  </div>
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Saving control&nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p
                      style={{ width: "60%", fontWeight: "bold" }}
                    >{`${savingsControlName}`}</p>
                  </div>
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Savings transfer in suspense&nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p
                      style={{ width: "60%", fontWeight: "bold" }}
                    >{`${savingstransferSuspenceName}`}</p>
                  </div>{" "}
                  {/* <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Interest Payable&nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p
                      style={{ width: "60%", fontWeight: "bold" }}
                    >{`${interestPayableName}`}</p>
                  </div>{" "} */}
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Interest on savings&nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p
                      style={{ width: "60%", fontWeight: "bold" }}
                    >{`${interestOnSavings}`}</p>
                  </div>{" "}
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Income from fees&nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p
                      style={{ width: "60%", fontWeight: "bold" }}
                    >{`${incomeFees}`}</p>
                  </div>
                  <div className="form-group d-flex  align-items-center text-label">
                    <h3
                      style={{
                        fontFamily: "AventaSemiBold",
                        marginRight: "1rem",
                      }}
                    >
                      Income From Penalties&nbsp;&nbsp;&nbsp;:
                    </h3>
                    <p
                      style={{ width: "60%", fontWeight: "bold" }}
                    >{`${incomePenalties}`}</p>
                  </div>{" "}
                  <div className="pt-1"></div>
                  <FormTitle>
                    <p className="text-info">Advanced Accounting Rule</p>
                    <div className="lineThrough"></div>
                  </FormTitle>
                  {/* Configure Fund Sources For Payment Channels */}
                  <div className="pt-2">
                    <h3
                      style={{ fontSize: 16 }}
                      className="font-weight-bold mb-2"
                    >
                      Configure Fund Sources For Payment Channels
                      &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <Table className="child-table">
                      <thead className="">
                        <tr style={{ backgroundColor: "#FAFAFA" }}>
                          <th>Payment Type</th>
                          <th>Fund Source</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paymentChannelToFundSourceMappings.length > 0
                          ? paymentChannelToFundSourceMappings.map(
                              ({ paymentTypeId, fundSourceAccountId }) => (
                                <tr key={paymentTypeId}>
                                  <td>{returnPaymentType(paymentTypeId)}</td>
                                  <td>
                                    {returnFundSource(fundSourceAccountId)}
                                  </td>
                                </tr>
                              )
                            )
                          : null}
                      </tbody>
                    </Table>
                  </div>
                  {/* Fees To Specific Income Account */}
                  <div className="pt-2">
                    <h3
                      style={{ fontSize: 16 }}
                      className="font-weight-bold mb-2"
                    >
                      Fees To Specific Income Account &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <Table className="child-table">
                      <thead className="">
                        <tr style={{ backgroundColor: "#FAFAFA" }}>
                          <th>Fees</th>
                          <th>Income Account</th>
                        </tr>
                      </thead>
                      <tbody>
                        {feeToIncomeAccountMappings.length > 0
                          ? feeToIncomeAccountMappings.map(
                              ({ chargeId, incomeAccountId }) => (
                                <tr key={chargeId}>
                                  <td>{returnFeeType(chargeId)}</td>
                                  <td>
                                    {returnIncomeAccount(incomeAccountId)}
                                  </td>
                                </tr>
                              )
                            )
                          : null}
                      </tbody>
                    </Table>
                  </div>
                  {/* Penalties To Specific Income Account */}
                  <div className="pt-2">
                    <h3
                      style={{ fontSize: 16 }}
                      className="font-weight-bold mb-2"
                    >
                      Penalties To Specific Income Account &nbsp;&nbsp;&nbsp;:
                    </h3>
                    <Table className="child-table">
                      <thead className="">
                        <tr style={{ backgroundColor: "#FAFAFA" }}>
                          <th>Penalty</th>
                          <th>Income Account</th>
                        </tr>
                      </thead>
                      <tbody>
                        {penaltyToIncomeAccountMappings.length > 0
                          ? penaltyToIncomeAccountMappings.map(
                              ({ chargeId, incomeAccountId }) => (
                                <tr key={chargeId}>
                                  <td>{returnPenaltyType(chargeId)}</td>
                                  <td>
                                    {returnIncomeAccount(incomeAccountId)}
                                  </td>
                                </tr>
                              )
                            )
                          : null}
                      </tbody>
                    </Table>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="float-right pt-1">
          <NextActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={handlePreviousScreen}
          >
            <img
              src={
                require("../../../../../../../../assets/images/icons/cancel.svg")
                  .default
              }
              alt="cancel icon"
            />
            Cancel
          </NextActionButton>
          <NextActionButton
            onClick={handleSubmit}
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
          >
            <img
              src={
                require("../../../../../../../../assets/images/icons/save.svg")
                  .default
              }
              alt="submit icon"
            />
            Submit
          </NextActionButton>
        </div>
      </div>
    </>
  );
};

export default FixedPreview;
