import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "@chakra-ui/react";
import { Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import CurrencyFormat from "react-currency-format";

import { IconButton } from "../styles";

import Toast from "../../../../../../util/Toast";

import {
  FormClass,
  FormElements,
  ActionButton,
  FormTitle,
} from "../../styles.jsx";
import { ActionAnchorButton } from "../../../styles.jsx";
import CheckPermission from "../../../../../../util/CheckPermission.js";

const LoanCharges = ({
  handleNextScreen,
  handlePreviousScreen,
  radio,
  setRadio,

  template,
  createLoanProductHandler,
  fundSource,
  loanPortfolio,
  transferInSusp,
  incomeFromInterest,
  incomeFromFees,
  incomeFromPenalties,
  incomeFromRecovery,
  overPaymentLia,
  lossesWrittenOff,
  interestReceivable,
  feesReceivable,
  penaltiesReceivable,
  setChargesError,
  setFullCharges,
  setFullOverdueCharges,
  setFeeToIncomeAccountMappings,
  setPaymentChannelToFundSourceMappings,
  setPenaltyToIncomeAccountMappings,
  setValueOverdueCharges,
  valueOverdueCharges,
  setValueCharges,
  valueCharges,
  myChargesArray,
  chargesArray,
  setMyChargesArray,
  setChargesArray,

  // handleAddCharge,

  overdueChargesArray,
  myOverdueChargesArray,
  setMyOverdueChargesArray,
  setOverdueChargesArray,
  handleSelectedChargeDate,
  handleDeleteChargeData,
  handleRepaymentsChange,
  charge,
  handleChargeId,
  renderTooltip,
  toggleSchedule,
}) => {
  const dispatch = useDispatch();
  const { responseUserData: { permissions } } = useSelector((state) => state.preAuth);

  const defaultConfigFS = { paymentTypeId: "", fundSourceAccountId: "" };
  const defaultMapFeeToIncome = { chargeId: "", incomeAccountId: "" };
  const defaultMapPenalties = { chargeId: "", incomeAccountId: "" };

  const [selectCharges, setSelectCharges] = useState("");
  const [charges, setCharges] = useState([]);

  const [selectOverdueCharges, setSelectOverdueCharges] = useState("");
  const [overdueCharges, setOverdueCharges] = useState([]);

  const selectedLoanData = useSelector((state) => state.selectedLoanData);
  const { productData } = selectedLoanData;
  const { chargeOptions } = productData;

  // const { chargeOptions = [], charges: newApiCharges } = productData || {};

  let chargesData = [];
  let overdueChargesData = [];

  // useEffect(() => {
  //   if (valueOverdueCharges && !!valueOverdueCharges.length) {
  //     setOverdueCharges(valueOverdueCharges);
  //   }
  // }, []);

  //configure fund sources for payment channels
  const [numberOfConfigFS, setNumberOfConfigFS] = useState([]);

  // Map fees to income accounts
  const [numberOfMapFeeToIncome, setNumberOfMapFeeToIncome] = useState([]);

  const [numberOfMapPenalties, setNumberOfMapPenalties] = useState([]);

  const handleAddCharge = () => {
    if (selectCharges) {
      let newCharge = template.chargeOptions.filter((item) => {
        return item.id === Number(selectCharges);
      })[0];

      let check = myChargesArray.filter(
        (item) => item?.chargeId === newCharge?.id
      );

      let {
        id: chargeId,
        name,
        amount,
        chargeTimeType: { value: chargeTimeTypeName },
        chargeCalculationType: { value: type },
      } = newCharge;

      if (check.length === 0) {
        setMyChargesArray((p) => {
          return [
            ...p,
            { chargeId, name, amount, chargeTimeTypeName, type, date: "" },
          ];
        });
      } else {
        dispatch(
          Toast({
            text: "Already added!",
            icon: "error",
            timer: 2000,
          })
        );
      }
    } else {
      dispatch(
        Toast({
          text: "Please select a charge",
          icon: "error",
          timer: 2000,
        })
      );
    }
  };

  const handleChargeDelete = (chargeId) => {
    setMyChargesArray(
      myChargesArray.filter((item) => item.chargeId !== chargeId)
    );
  };

  const handleOverdueChargeDelete = (chargeId) => {
    setMyOverdueChargesArray(
      myOverdueChargesArray.filter((item) => item.chargeId !== chargeId)
    );
  };

  const handleDateChange = (index, date) => {
    let hhh = [...myChargesArray];
    hhh[Number(index)].date = date;
    setMyChargesArray(hhh);
  };

  const handleOverdueDateChange = (index, date) => {
    let hhh = [...myOverdueChargesArray];
    hhh[Number(index)].date = date;
    setMyOverdueChargesArray(hhh);
  };

  const handleAmountChange = (index, amount) => {
    let hhh = [...myChargesArray];
    hhh[Number(index)].amount = amount;
    setMyChargesArray(hhh);
  };

  const handleOverdueAmountChange = (index, amount) => {
    let hhh = [...myOverdueChargesArray];
    hhh[Number(index)].amount = amount;
    setMyOverdueChargesArray(hhh);
  };

  return (
    <FormClass>
      {!CheckPermission('CREATE_LOANCHARGE', permissions) ? (
        <div className="w-100 text-center">
          Sorry, You don't have the permission to add loan charges
        </div>
      ) : (
      <>
        {/* Charges  */}
        <FormTitle style={{ width: "100%", margin: "4rem auto" }}>
          <p className="text-info">Charges</p>
          <div className="lineThrough"></div>
        </FormTitle>
        {/* Charges Add Charge */}
        <FormElements style={{ width: "60%", margin: "0 auto" }}>
          <div className="form-group d-flex justify-content-between align-items-center">
            <Select
              name="selectCharges"
              value={selectCharges}
              onChange={(e) => {
                setSelectCharges(e.target.value);
              }}
              size="md"
            >
              <option value="">Select charge</option>
              {template !== undefined && template
                ? template.chargeOptions &&
                  template.chargeOptions.map((cur) => (
                    <option value={cur.id} key={cur.id}>
                      {cur.name}
                    </option>
                  ))
                : null}
            </Select>
            <ActionButton
              color="#2C1DFF"
              style={{ margin: "0px 0px 0 10px" }}
              onClick={handleAddCharge}
            >
              <img
                src={
                  require("../../../../../../assets/images/icons/add.svg")
                    .default
                }
                alt="forward icon"
              />
              Add Charge
            </ActionButton>
          </div>
        </FormElements>
        <Table className="child-table">
          <thead>
            <tr style={{ backgroundColor: "#FAFAFA" }}>
              <th>Name</th>
              <th>Type</th>
              <th>Amount</th>
              <th>Collected on</th>
              <th>Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {myChargesArray && myChargesArray?.length > 0
              ? myChargesArray.map(
                  (
                    {
                      chargeId,
                      name,
                      type,
                      amount,
                      chargeTimeTypeName,
                      date,
                      chargeTimeType,
                    },
                    index
                  ) => (
                    <tr key={chargeId}>
                      <td>{name}</td>
                      <td>{type}</td>
                      <td>
                        <CurrencyFormat
                          value={amount}
                          name="min_pricipal"
                          thousandSeparator={true}
                          className="amount-input"
                          style={{
                            width: "60%",
                          }}
                          onValueChange={(values) => {
                            const { value } = values;
                            handleAmountChange(index, value);
                          }}
                        />
                      </td>
                      <td>{chargeTimeTypeName}</td>
                      <td>
                        <>
                          <DatePicker
                            id="start-date"
                            selected={date}
                            onChange={(date) => handleDateChange(index, date)}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            placeholderText="dd/mmm/yyyy"
                          />{" "}
                        </>
                      </td>
                      <td>
                        <IconButton
                          onClick={() => handleChargeDelete(chargeId)}
                          id="deleteHoliday"
                          className="mr-1 mb-1"
                        >
                          <img
                            src={
                              require("../../../../../../assets/images/icons/delete.svg")
                                .default
                            }
                            alt="add"
                            className="icon-img"
                          />
                        </IconButton>
                      </td>
                    </tr>
                  )
                )
              : null}
          </tbody>
        </Table>

        {myOverdueChargesArray?.length > 0 ? (
          <>
            {/* Overdue Charges  */}
            <FormTitle style={{ width: "100%", margin: "4rem auto" }}>
              <p className="text-info">Overdue charges</p>
              <div className="lineThrough"></div>
            </FormTitle>
            <Table className="child-table">
              <thead className="">
                <tr style={{ backgroundColor: "#FAFAFA" }}>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Amount</th>
                  <th>Collected on</th>
                  <th>Date</th>
                  <th>Action</th>
                </tr>
              </thead>

              {/* Display Overdue body */}
              <tbody>
                {myOverdueChargesArray?.length > 0
                  ? myOverdueChargesArray.map(
                      (
                        {
                          chargeId,
                          name,
                          type,
                          amount,
                          chargeTimeTypeName,
                          date,
                        },
                        index
                      ) => (
                        <tr key={chargeId}>
                          <td>{name}</td>
                          <td>{type}</td>
                          {/* <td>{formatCurrency(Number(amount))}</td> */}
                          <td>
                            <CurrencyFormat
                              value={amount}
                              name="min_pricipal"
                              thousandSeparator={true}
                              className="amount-input"
                              style={{
                                width: "60%",
                              }}
                              onValueChange={(overdueValues) => {
                                const { value } = overdueValues;
                                handleOverdueAmountChange(index, value);
                              }}
                            />
                          </td>
                          <td>{chargeTimeTypeName}</td>
                          <td>
                            <>
                              <DatePicker
                                id="start-date"
                                selected={date}
                                onChange={(date) =>
                                  handleOverdueDateChange(index, date)
                                }
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                placeholderText="dd/mmm/yyyy"
                              />{" "}
                            </>
                          </td>
                          <td>
                            <IconButton
                              onClick={() =>
                                handleOverdueChargeDelete(chargeId)
                              }
                              id="deleteHoliday"
                              className="mr-1 mb-1"
                            >
                              <img
                                src={
                                  require("../../../../../../assets/images/icons/delete.svg")
                                    .default
                                }
                                alt="add"
                              />
                            </IconButton>
                          </td>
                        </tr>
                      )
                    )
                  : null}
              </tbody>
            </Table>
          </>
        ) : (
          ""
        )}
      </>
      )}
        <ActionAnchorButton className="fs-14 p-0" onClick={toggleSchedule}>
          View Repayment Schedule &#8594;
        </ActionAnchorButton>

        <div className="float-right py-5">
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={() => {
              myChargesArray.length > 0 &&
                //   setFullCharges(charges.map(({ id }) => ({ id })));
                // overdueCharges.length > 0 &&
                //   setFullOverdueCharges(overdueCharges.map(({ id }) => ({ id })));
                // setValueOverdueCharges(overdueCharges);
                // charges.length > 0 &&
                // setFullCharges(
                //   charges.map(({ id, amount, date, chargeId }) => ({
                //     chargeId: id ? id : chargeId,
                //     amount,
                //     dueDate: formatDate(date, true),
                //   }))
                // );
                setChargesArray(chargesArray);
              setMyChargesArray(myChargesArray);
              setPaymentChannelToFundSourceMappings(numberOfConfigFS);
              setPenaltyToIncomeAccountMappings(numberOfMapPenalties);
              setFeeToIncomeAccountMappings(numberOfMapFeeToIncome);
              handlePreviousScreen();
            }}
          >
            <img
              src={
                require("../../../../../../assets/images/icons/arrow-left.svg")
                  .default
              }
              alt="forward icon"
            />
            Previous
          </ActionButton>
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={() => {
              if (radio === "2") {
                incomeFromFees ? setChargesError(false) : setChargesError(true);
              } else {
                setChargesError(false);
              }
              myChargesArray.length > 0 &&
                // setFullCharges(
                //   charges.map(({ id, amount, chargeId, date }) => ({
                //     chargeId: id ? id : chargeId,
                //     amount,
                //     dueDate: formatDate(date, true),
                //   }))
                // );
                //   setFullCharges(charges.map(({ id }) => ({ id })));
                // overdueCharges.length > 0 &&
                //   setFullOverdueCharges(overdueCharges.map(({ id }) => ({ id })));

                setChargesArray(chargesArray);
              setMyChargesArray(myChargesArray);
              setPaymentChannelToFundSourceMappings(numberOfConfigFS);
              setPenaltyToIncomeAccountMappings(numberOfMapPenalties);
              setFeeToIncomeAccountMappings(numberOfMapFeeToIncome);
              handleNextScreen();
            }}
          >
            <img
              src={
                require("../../../../../../assets/images/icons/arrow-right.svg")
                  .default
              }
              alt="forward icon"
            />
            Next
          </ActionButton>
        </div>
      </FormClass>
  );
};

export default LoanCharges;
