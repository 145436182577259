import React from "react";
import PropTypes from "prop-types";
import {
  LayoutContainer,
  FormContainer,
  SubText,
  HeroContainer,
} from "./styles.jsx";
import { Carousel } from "antd";

const AuthLayout = ({ history, children }) => {
  return (
    <LayoutContainer className="container-fluid">
      <div className="row h-100">
        <div
          className="col-sm-12 col-md-7 col-lg-7 d-flex justify-content-center"
          style={{ padding: "8rem 0" }}
        >
          <FormContainer>
            <div className="container">{children}</div>
          </FormContainer>
        </div>
        <div className="col-md-5 p-0">
          {/* <div className="h-100"> */}
            <div className="d-none h-100 d-md-flex position-relative auth-right-bg">
              {/* <HeroContainer> */}
                <div>
                  <span className="let-provide">
                    Let us provide you with the infrastructure you<br></br> need
                    to build your financial product.
                  </span>

                  <div style={{ "margin-top": "2rem" }}>
                    <img
                    className="background-screen-image"
                      src={
                        require("../../assets/images/second-login-page.svg")
                          .default
                      }
                      // style={{position: "absolute"}}
                      alt=""
                    />
                  </div>
                {/* </div> */}
              {/* </HeroContainer> */}
            </div>
          </div>
        </div>
      </div>
    </LayoutContainer>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthLayout;
