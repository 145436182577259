import React, { useState } from "react";
import { ContentWrapper, ActionButtonsGroup, ActionButton } from "./styles";
import { InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import ModalContainer from "../../../components/ModalContainer";
import ViewCampaign from "./components/ViewCampaign";
const SMSCampaignView = () => {
  const [modalState, toggleModal] = useState(false);
  const [modalContentName, setModalContentName] = useState("");
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  const handleModalClose = () => toggleModal(false);

  //Table configuration
  const status = "Active";
  const columns = [
    { title: "Campaign Name", dataIndex: "campaignName", key: "campaignName" },
    {
      title: "Template Message",
      dataIndex: "templateMessage",
      key: "templateMessage",
    },
    { title: "Campaign Type", dataIndex: "campaignType", key: "campaignType" },
    { title: "Trigger Type", dataIndex: "triggerType", key: "triggerType" },
    { title: "Status", dataIndex: "status", key: "status" },
    { title: "Approved by", dataIndex: "approveBy", key: "approveBy" },
  ];
  const dataSource = [
    {
      key: 0,
      campaignName: "Mark",
      templateMessage: "Peters",
      triggerType: "Triggered",
      approveBy: "Woodcore",
      status: (
        <p
          style={{
            color: status === "Active" ? "#1DCB43" : "#333333",
          }}
          className="font-weight-bold"
        >
          {status}
        </p>
      ),
      campaignType: "Notification",
    },
    {
      key: 1,
      campaignName: "Mark",
      templateMessage: "Peters",
      approveBy: "Woodcore",
      status: (
        <p
          style={{
            color: status === "Active" ? "#1DCB43" : "#333333",
          }}
          className="font-weight-bold"
        >
          {status}
        </p>
      ),
      campaignType: "Notification",
      triggerType: "Direct",
    },
    {
      key: 2,
      campaignName: "Mark",
      templateMessage: "Peters",
      triggerType: "Triggered",
      approveBy: "Woodcore",
      status: (
        <p
          style={{
            color: status === "Active" ? "#1DCB43" : "#333333",
          }}
          className="font-weight-bold"
        >
          {status}
        </p>
      ),
      campaignType: "Notification",
    },
    {
      key: 3,
      campaignName: "Mark",
      templateMessage: "Peters",
      triggerType: "Triggered",
      approveBy: "Woodcore",
      status: (
        <p
          style={{
            color: status === "Active" ? "#1DCB43" : "#333333",
          }}
          className="font-weight-bold"
        >
          {status}
        </p>
      ),
      campaignType: "Notification",
    },
    {
      key: 4,
      campaignName: "Mark",
      templateMessage: "Peters",
      triggerType: "Triggered",
      approveBy: "Woodcore",
      status: (
        <p
          style={{
            color: status === "Active" ? "#1DCB43" : "#333333",
          }}
          className="font-weight-bold"
        >
          {status}
        </p>
      ),
      campaignType: "Notification",
    },
    {
      key: 5,
      campaignName: "Mark",
      templateMessage: "Peters",
      triggerType: "Triggered",
      approveBy: "Woodcore",
      status: (
        <p
          style={{
            color: status === "Active" ? "#1DCB43" : "#333333",
          }}
          className="font-weight-bold"
        >
          {status}
        </p>
      ),
      campaignType: "Notification",
    },
    {
      key: 6,
      campaignName: "Mark",
      templateMessage: "Peters",
      triggerType: "Triggered",
      approveBy: "Woodcore",
      status: (
        <p
          style={{
            color: status === "Active" ? "#1DCB43" : "#333333",
          }}
          className="font-weight-bold"
        >
          {status}
        </p>
      ),
      campaignType: "Notification",
    },
  ];
  const displayModalContent = () => {
    switch (modalContentName) {
      case "viewCampaign":
        return (
          <ViewCampaign
            campaignInfo={dataSource[selectedCampaign]}
            onHide={handleModalClose}
          />
        );

      default:
        return;
    }
  };

  const handleCampaignClick = (index) => {
    setSelectedCampaign(index);
    setModalContentName("viewCampaign");
    toggleModal(true);
  };

  return (
    <>
      <ModalContainer show={modalState}>
        {modalState && displayModalContent()}
      </ModalContainer>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">SMS Campaign</h3>
            <p className="sub-title">Manage SMS Campaign</p>
            <p className="font-weight sub-title mt-2">
              <span className="text-info">
                <Link to="/core/configuration">Configuration</Link> /{" "}
                <Link to="/core/configuration/organization">Utilities</Link>
              </span>{" "}
              / SMS Campaign
            </p>
          </div>
          <div className="col-sm-12 col-md-6 px-0">
            <ActionButtonsGroup>
              <InputGroup style={{ maxWidth: "450px" }}>
                <InputLeftElement
                  width="3.1rem"
                  height="50px"
                  pointerEvents="none"
                >
                  <img
                    src={
                      require("../../../../../assets/images/icons/search.svg")
                        .default
                    }
                    style={{ height: "30px" }}
                    alt="Icon"
                  />
                </InputLeftElement>
                <Input
                  style={{
                    background: "#ffffff",
                    height: "50px",
                  }}
                  type="text"
                  className="mb-3"
                  placeholder="Search by name"
                />
              </InputGroup>
              <Link to="sms-campaign/new-campaign">
                <ActionButton>
                  <img
                    src={
                      require("../../../../../assets/images/icons/add.svg")
                        .default
                    }
                    alt="add"
                  />
                  New Campaign
                </ActionButton>
              </Link>
            </ActionButtonsGroup>
          </div>
        </div>
        <ContentWrapper>
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={{ defaultPageSize: 8 }}
            defaultPageSize={8}
            onRow={(r) => ({
              onClick: () => handleCampaignClick(r.key),
            })}
          />
        </ContentWrapper>
      </div>
    </>
  );
};

export default SMSCampaignView;
