import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CurrencyFormat from "react-currency-format";
import { Link, useParams } from "react-router-dom";
import { ContentWrapper, FormClass, FormElements, FormTitle } from "../styles";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  Select,
  Textarea,
  Input,
  FormControl,
  Checkbox,
} from "@chakra-ui/react";

import Toast from "../../../../../../util/Toast.js";
import EditFixedCharges from "./EditFixedCharges";
// import EditFixedInterestRateChart from './EditFixedInterestRateChart';
import EditFixedInterestRateTemp from "./EditFixedInterestRateTemp";

import {
  // getFixedDepositProduct,
  getFixedDepositsProduct,
  editFixedDepositTemplate,
  resetNewFixedDepositProductState,
} from "../../../../../../actions/fixedDeposit";
// import { getToolTips } from "../../../../../../actions/utilityActions";
import { formatDate } from "../../../../../../util/FormatDate";
import ToolTipSvg from "../../../../../../assets/images/icons/tooltip.svg";
import ReactSelect from "react-select";
import { REACT_SELECT_CUSTOM_STYlES } from "../../../../../../constants/formConstants.js";

const EditFixedProductView = ({ match, history }) => {
  const dispatch = useDispatch();
  let { id } = useParams();
  const fixedDepositData = useSelector((state) => state.fixedDepositData);
  const fdProductReducer = useSelector((state) => state.fdProductReducer);

  const { fixedDepositAccountTemplate = {} } = fixedDepositData || {};

  const { fixedDepositProduct, loading } = fdProductReducer;

  const toolTips = useSelector((state) => state.toolTips.tooltips);

  useEffect(() => {
    if (match.params.id) {
      dispatch(getFixedDepositsProduct(match.params.id));
    }
  }, [match?.params?.id]);

  useEffect(() => {
    dispatch(resetNewFixedDepositProductState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [newFixedProduct, setNewFixedProduct] = useState({
    name: "",
    currencyCode: "",
    shortName: "",
    inMultiplesOf: "",
    description: "",
    digitsAfterDecimal: "",

    // For terms
    interestPostingPeriodType: "",
    interestCompoundingPeriodType: "",
    interestCalculationDaysInYearType: "",
    interestCalculationType: "",

    // For Settings
    lockinPeriodFrequency: "",
    lockinPeriodFrequencyType: "",
    minDepositTerm: "",
    minDepositTermTypeId: "",
    inMultiplesOfDepositTerm: "",
    inMultiplesOfDepositTermTypeId: "",
    maxDepositTerm: "",
    maxDepositTermTypeId: "",
    // preClosurePenalApplicable: false,
    preClosurePenalInterestOnTypeId: "",
    taxGroupId: "",
    preClosurePenalInterest: "",

    // For Charges
    savingsReferenceAccountId: "",
    savingsControlAccountId: "",
    // interestPayableAccountId: "",
    transfersInSuspenseAccountId: "",
    interestOnSavingsAccountId: "",
    incomeFromFeeAccountId: "",
    incomeFromPenaltyAccountId: "",
  });

  const [depositAmount, setDepositAmount] = useState();
  const [minDepositAmount, setMinDepositAmount] = useState();
  const [maxDepositAmount, setMaxDepositAmount] = useState();
  const [withHoldTax, setWithHoldTax] = useState(false);
  const [preClosurePenalApplicable, setPreClosurePenalApplicable] =
    useState(false);

  const [validFrom, handleValidFrom] = useState();
  const [endDate, handleEndDate] = useState();

  const [accountType, setAccountType] = useState("1");
  const [charges, setCharges] = useState([]);
  // const [valueCharges, setValueCharges] = useState([]);
  // const [fullCharges, setFullCharges] = useState([]);

  const [processing, setLoading] = useState(false);
  const [feeToIncomeAccountMappings, setFeeToIncomeAccountMappings] = useState(
    []
  );
  const [advance, setAdvance] = useState(false);

  let defaultData = {
    amountRangeFrom: "",
    amountRangeTo: "",
    annualInterestRate: "",
    description: "",
    fromPeriod: "",
    incentives: [],
    locale: "en",
    periodType: "",
    toPeriod: "",
  };

  const [interests, setInterests] = useState([]);
  const [currentChart, setCurrentChart] = useState([]);

  const [chartSlabs, setChartSlabs] = useState([]);
  let wales = [];

  useEffect(() => {
    if (currentChart && currentChart.length) {
      currentChart &&
        currentChart.length &&
        currentChart.forEach((interest) => {
          let {
            incentives: myIncentives = [],
            periodType: myPeriodType,
            fromPeriod,
            toPeriod,
            description,
            annualInterestRate,
            amountRangeFrom,
            amountRangeTo,
            id,
          } = interest || {};

          let newIncentives = [];

          myIncentives.forEach((incentive) => {
            let {
              amount,
              attributeValue,
              attributeName,
              conditionType,
              incentiveType,
            } = incentive || {};

            newIncentives.push({
              amount,
              attributeName: `${attributeName.id}`,
              attributeValue,
              conditionType: `${conditionType.id}`,
              entityType: "2",
              incentiveType: `${incentiveType.id}`,
            });
          });

          wales.push({
            amountRangeFrom,
            amountRangeTo,
            toPeriod,
            periodType:
              !!myPeriodType && !!myPeriodType.id ? myPeriodType.id : 0,
            fromPeriod,
            description,
            annualInterestRate,
            incentives: newIncentives,
            id,
            local: "en",
          });
        });
    } else {
      setInterests([]);
    }
  }, []);

  useEffect(() => {
    if (wales && wales.length) {
      setInterests(wales);
    }
  }, []);

  const [penaltyToIncomeAccountMappings, setPenaltyToIncomeAccountMappings] =
    useState([]);
  const [
    paymentChannelToFundSourceMappings,
    setPaymentChannelToFundSourceMappings,
  ] = useState([]);

  const editSavingsProductHandler = (e) =>
    setNewFixedProduct({
      ...newFixedProduct,
      [e.target.name]: e.target.value,
    });

  const editSavingsProductHandlerForSelect = (name, value) =>
    setNewFixedProduct({
      ...newFixedProduct,
      [name]: value,
    });

  useEffect(() => {
    let paymentChannel = [];
    let incomeFees = [];
    let penaltiesIncome = [];

    if (
      fixedDepositProduct &&
      fixedDepositProduct.paymentChannelToFundSourceMappings &&
      fixedDepositProduct.paymentChannelToFundSourceMappings.length
    ) {
      fixedDepositProduct.paymentChannelToFundSourceMappings.forEach(
        (payChann) => {
          paymentChannel.push({
            fundSourceAccountId: payChann.fundSourceAccount.id,
            paymentTypeId: payChann.paymentType.id,
          });
        }
      );
    }

    if (
      fixedDepositProduct &&
      fixedDepositProduct.feeToIncomeAccountMappings &&
      fixedDepositProduct.feeToIncomeAccountMappings.length
    ) {
      fixedDepositProduct.feeToIncomeAccountMappings.forEach((payChann) => {
        incomeFees.push({
          chargeId: payChann.charge.id,
          incomeAccountId: payChann.incomeAccount.id,
        });
      });
    }

    if (
      fixedDepositProduct &&
      fixedDepositProduct.penaltyToIncomeAccountMappings &&
      fixedDepositProduct.penaltyToIncomeAccountMappings.length
    ) {
      fixedDepositProduct.penaltyToIncomeAccountMappings.forEach((payChann) => {
        penaltiesIncome.push({
          chargeId: payChann.charge.id,
          incomeAccountId: payChann.incomeAccount.id,
        });
      });
    }

    setPaymentChannelToFundSourceMappings(paymentChannel);
    setFeeToIncomeAccountMappings(incomeFees);
    setPenaltyToIncomeAccountMappings(penaltiesIncome);
  }, [fixedDepositProduct]);

  useEffect(() => {
    if (loading || !fixedDepositProduct ? null : fixedDepositProduct) {
      let { accountingMappings = {} } = fixedDepositProduct;
      setDepositAmount(fixedDepositProduct.depositAmount);
      setMinDepositAmount(fixedDepositProduct.minDepositAmount);
      setMaxDepositAmount(fixedDepositProduct.maxDepositAmount);
      setWithHoldTax(fixedDepositProduct.withHoldTax);
      handleValidFrom(
        fixedDepositProduct && !!fixedDepositProduct.activeChart
          ? new Date(fixedDepositProduct.activeChart.fromDate)
          : ""
      );
      setCurrentChart(
        fixedDepositProduct &&
        fixedDepositProduct.activeChart &&
        fixedDepositProduct.activeChart.chartSlabs &&
        fixedDepositProduct.activeChart.chartSlabs.length &&
        fixedDepositProduct.activeChart.chartSlabs
      );
      if (fixedDepositProduct?.activeChart?.endDate) {
        handleEndDate(
          fixedDepositProduct && !!fixedDepositProduct.activeChart
            ? new Date(fixedDepositProduct?.activeChart?.endDate)
            : ""
        );
      }

      setPreClosurePenalApplicable(
        fixedDepositProduct
          ? fixedDepositProduct.preClosurePenalApplicable
          : false
      );
      setChartSlabs(
        fixedDepositProduct && !!fixedDepositProduct.activeChart
          ? fixedDepositProduct.activeChart.chartSlabs
          : ""
      );
      setNewFixedProduct({
        name: fixedDepositProduct ? fixedDepositProduct?.name : "",
        currencyCode: fixedDepositProduct
          ? fixedDepositProduct?.currency
            ? fixedDepositProduct?.currency?.code
            : ""
          : "",
        shortName: fixedDepositProduct ? fixedDepositProduct?.shortName : "",
        inMultiplesOf: fixedDepositProduct
          ? fixedDepositProduct.currency
            ? fixedDepositProduct.currency.inMultiplesOf
            : ""
          : "",
        description: fixedDepositProduct
          ? fixedDepositProduct?.description
          : "",
        minDepositTerm: fixedDepositProduct
          ? fixedDepositProduct?.minDepositTerm
          : "",
        maxDepositTerm: fixedDepositProduct
          ? fixedDepositProduct?.maxDepositTerm
          : "",
        // preClosurePenalApplicable: fixedDepositProduct
        // ? fixedDepositProduct.preClosurePenalApplicable
        // : false,
        preClosurePenalInterest: fixedDepositProduct
          ? fixedDepositProduct?.preClosurePenalInterest
          : "",
        inMultiplesOfDepositTerm: fixedDepositProduct
          ? fixedDepositProduct?.inMultiplesOfDepositTerm
          : "",
        inMultiplesOfDepositTermTypeId:
          fixedDepositProduct &&
            !!fixedDepositProduct?.inMultiplesOfDepositTermType
            ? fixedDepositProduct?.inMultiplesOfDepositTermType.id
            : "",
        preClosurePenalInterestOnTypeId:
          fixedDepositProduct &&
            !!fixedDepositProduct?.preClosurePenalInterestOnType
            ? fixedDepositProduct?.preClosurePenalInterestOnType?.id
            : 1,
        taxGroupId:
          fixedDepositProduct && !!fixedDepositProduct?.taxGroup
            ? fixedDepositProduct?.taxGroup?.id
            : "",
        minDepositTermTypeId:
          fixedDepositProduct && !!fixedDepositProduct?.minDepositTermType
            ? fixedDepositProduct?.minDepositTermType?.id
            : "",
        maxDepositTermTypeId:
          fixedDepositProduct && !!fixedDepositProduct?.maxDepositTermType
            ? fixedDepositProduct?.maxDepositTermType?.id
            : "",
        digitsAfterDecimal: fixedDepositProduct
          ? fixedDepositProduct?.digitsAfterDecimal
            ? fixedDepositProduct?.digitsAfterDecimal
            : "2"
          : "",
        interestPostingPeriodType: fixedDepositProduct
          ? fixedDepositProduct.interestPostingPeriodType
            ? fixedDepositProduct.interestPostingPeriodType.id
            : ""
          : "",
        interestCompoundingPeriodType: fixedDepositProduct
          ? fixedDepositProduct.interestCompoundingPeriodType
            ? fixedDepositProduct.interestCompoundingPeriodType.id
            : ""
          : "",
        interestCalculationDaysInYearType: fixedDepositProduct
          ? fixedDepositProduct.interestCalculationDaysInYearType
            ? fixedDepositProduct.interestCalculationDaysInYearType.id
            : ""
          : "",
        interestCalculationType: fixedDepositProduct
          ? fixedDepositProduct.interestCalculationType
            ? fixedDepositProduct.interestCalculationType.id
            : ""
          : "",
        lockinPeriodFrequency: fixedDepositProduct
          ? fixedDepositProduct.lockinPeriodFrequency
          : "",
        lockinPeriodFrequencyType: fixedDepositProduct
          ? fixedDepositProduct.lockinPeriodFrequencyType
            ? fixedDepositProduct.lockinPeriodFrequencyType.id
            : ""
          : "",
        taxGroupOptions: fixedDepositProduct
          ? fixedDepositProduct.taxGroupId
          : "",
        savingsReferenceAccountId:
          accountingMappings &&
            accountingMappings.savingsReferenceAccount &&
            accountingMappings.savingsReferenceAccount.id
            ? accountingMappings.savingsReferenceAccount.id
            : "",
        savingsControlAccountId:
          accountingMappings &&
            accountingMappings.savingsControlAccount &&
            accountingMappings.savingsControlAccount.id
            ? accountingMappings.savingsControlAccount.id
            : "",
        transfersInSuspenseAccountId:
          accountingMappings &&
            accountingMappings.transfersInSuspenseAccount &&
            accountingMappings.transfersInSuspenseAccount.id
            ? accountingMappings.transfersInSuspenseAccount.id
            : "",
        interestOnSavingsAccountId:
          accountingMappings &&
            accountingMappings.interestOnSavingsAccount &&
            accountingMappings.interestOnSavingsAccount.id
            ? accountingMappings.interestOnSavingsAccount.id
            : "",
        incomeFromFeeAccountId:
          accountingMappings &&
            accountingMappings.incomeFromFeeAccount &&
            accountingMappings.incomeFromFeeAccount.id
            ? accountingMappings.incomeFromFeeAccount.id
            : "",
        incomeFromPenaltyAccountId:
          accountingMappings &&
            accountingMappings.incomeFromPenaltyAccount &&
            accountingMappings.incomeFromPenaltyAccount.id
            ? accountingMappings.incomeFromPenaltyAccount.id
            : "",
        // interestPayableAccountId:
        //   accountingMappings &&
        //   accountingMappings.interestPayableAccount &&
        //   accountingMappings.interestPayableAccount.id
        //     ? accountingMappings.interestPayableAccount.id
        //     : "",
      });
      setCharges(fixedDepositProduct.charges);

      setAccountType(
        fixedDepositProduct.accountingRule
          ? String(fixedDepositProduct.accountingRule.id)
          : ""
      );
    }
  }, [fixedDepositProduct]);

  const handleSubmit = () => {
    let chartSlabsArray = chartSlabs.map((item) => {
      return { ...item, periodType: item?.periodType.id };
    });

    let charts = [
      {
        chartSlabs: chartSlabsArray,
        dateFormat: "dd MMMM yyyy",
        endDate: endDate ? formatDate(endDate, true) : "",
        fromDate: validFrom ? formatDate(validFrom, true) : "",
        locale: "en",
        // periodType: 1,
        id:
          fixedDepositProduct &&
          fixedDepositProduct.activeChart &&
          fixedDepositProduct.activeChart.id,
      },
    ];

    // if(!preClosurePenalApplicable) {
    // return newFixedProduct.preClosurePenalInterest = ""
    // }

    let dataToSend = {
      ...newFixedProduct,
      charts,
      // charges: [...fullCharges],
      charges: charges.length > 0 ? charges.map(({ id }) => ({ id })) : [],
      locale: "en",
      depositAmount,
      maxDepositAmount,
      minDepositAmount,
      preClosurePenalApplicable,
      accountingRule: accountType,
      feeToIncomeAccountMappings,
      penaltyToIncomeAccountMappings,
      paymentChannelToFundSourceMappings,
      withHoldTax,
    };

    const newObject = { ...dataToSend };
    for (const key in newObject) {
      if (Object.prototype.hasOwnProperty.call(newObject, key)) {
        const element = newObject[key];
        if (element === undefined || element === "") {
          delete newObject[key];
        }
      }
    }

    if (newObject.charts[0].endDate === "") {
      delete newObject.charts[0].endDate;
    }

    dataToSend = newObject;

    if (!newFixedProduct.name || !newFixedProduct.shortName) {
      Toast({
        text: "Please fill the required fields",
        icon: "error",
        timer: 2000,
      });
    } else {
      setLoading(true);
      dispatch(editFixedDepositTemplate(dataToSend, match.params.id, history));
      setLoading(false);
    }
  };

  const renderTooltip = (name) => (
    <>
      {toolTips && toolTips[name] ? (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={`tooltip-${toolTips[name]._id}`}>
              {toolTips[name].toolTip}
            </Tooltip>
          }
        >
          <img src={ToolTipSvg} style={{ marginLeft: "10px" }} alt="" />
        </OverlayTrigger>
      ) : (
        ""
      )}
    </>
  );

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-2 mt-1">
            <h3 className="page-title">Edit fixed deposit products</h3>
            <p className="font-weight sub-title mt-2">
              {/* <span className="text-info">
              <Link to="/core/configuration">Configuration </Link>
            </span>
            /{" "} */}
              <span className="text-info">
                <Link to="/core/products">Products </Link>
              </span>
              /{" "}
              <span className="text-info">
                <Link to="/core/products/fixed-deposit-products">
                  Fixed deposit products{" "}
                </Link>
              </span>
              / Edit Fixed deposit products
            </p>
          </div>
        </div>
        <div className="row" style={{ minHeight: "calc(100vh - 250px)" }}>
          <div className="col-sm-12 col-md-12">
            <ContentWrapper style={{ padding: "2rem" }}>
              <div className="container">
                {/* Details */}
                <FormTitle>
                  <p className="text-info">Details</p>
                  <div className="lineThrough"></div>
                </FormTitle>
                <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                  <div className="col-sm-12 col-md-6 px-0 ">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-end">
                          <label className="text-label d-flex flex-align-center">
                            Product Name* {renderTooltip("Product Name")}
                          </label>
                          <Input
                            style={{ width: "60%" }}
                            size="md"
                            name="name"
                            value={newFixedProduct.name}
                            onChange={editSavingsProductHandler}
                          />
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                  <div className="col-sm-12 col-md-6 px-0">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-end">
                          <label className="text-label d-flex flex-align-center">
                            Currency*
                            {/* {renderTooltip("Currency")} */}
                          </label>
                          <div className="w-100" style={{ maxWidth: '60%' }}>
                            <ReactSelect
                              isClearable={true}
                              className="fs-12"
                              styles={REACT_SELECT_CUSTOM_STYlES}
                              name="currencyCode"
                              placeholder="Select a currency"
                              onChange={(currency, { name }) => editSavingsProductHandlerForSelect(name, currency?.value)}
                              value={{
                                value: newFixedProduct?.currencyCode,
                                label: fixedDepositProduct
                                  ?.currencyOptions
                                  ?.find((currency) => currency?.code === newFixedProduct?.currencyCode)
                                  ?.displayLabel,
                              }}
                              options={fixedDepositProduct
                                ?.currencyOptions
                                ?.map((item) => ({
                                  label: item.displayLabel,
                                  value: item.code,
                                }))
                              }
                            />
                          </div>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                </div>

                <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                  <div className="col-sm-12 col-md-6 px-0 ">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-end">
                          <label className="text-label d-flex flex-align-center">
                            Short Name* {renderTooltip("Short Name")}
                          </label>
                          <Input
                            style={{ width: "60%" }}
                            size="md"
                            name="shortName"
                            value={newFixedProduct.shortName}
                            onChange={editSavingsProductHandler}
                          />
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                  <div className="col-sm-12 col-md-6 px-0">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-start">
                          <label className="text-label d-flex flex-align-center">
                            Currency In Multiples Of*
                            {renderTooltip("Currency In Multiples Of")}
                          </label>
                          <Input
                            style={{ width: "60%" }}
                            size="md"
                            name="inMultiplesOf"
                            value={newFixedProduct.inMultiplesOf}
                            type="number"
                            onChange={editSavingsProductHandler}
                          />
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                </div>

                <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                  <div className="col-sm-12 col-md-6 px-0 ">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-start">
                          <label className="text-label d-flex flex-align-center">
                            Description
                            {renderTooltip("Description")}
                          </label>
                          <Textarea
                            style={{ width: "60%", fontSize: "12px" }}
                            size="md"
                            name="description"
                            value={newFixedProduct.description}
                            onChange={editSavingsProductHandler}
                          />
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                  <div className="col-sm-12 col-md-6 px-0">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-end">
                          <label className="text-label d-flex flex-align-center">
                            Decimal Places*
                            {renderTooltip("Decimal Places")}
                          </label>
                          <Input
                            style={{ width: "60%" }}
                            size="md"
                            name="digitsAfterDecimal"
                            value={newFixedProduct.digitsAfterDecimal}
                            onChange={editSavingsProductHandler}
                          />
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                </div>
                {/* Terms */}
                <div className="pt-2">
                  <FormTitle>
                    <p className="text-info">Terms</p>
                    <div className="lineThrough"></div>
                  </FormTitle>
                </div>

                <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                  <div className="col-sm-12 col-md-6 px-0 ">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-start">
                          <label className="text-label d-flex flex-align-center">
                            Default Deposit Amount*{" "}
                            {renderTooltip("Default Deposit Amount")}
                          </label>
                          <CurrencyFormat
                            value={depositAmount}
                            name="min_pricipal"
                            thousandSeparator={true}
                            className="amount-input"
                            style={{
                              width: "60%",
                            }}
                            onValueChange={(values) => {
                              const { value } = values;
                              setDepositAmount(value);
                            }}
                          />
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                  <div className="col-sm-12 col-md-6 px-0 ">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-start">
                          <label className="text-label d-flex flex-align-center">
                            Minimum Deposit Amount*
                            {renderTooltip("Minimum Deposit Amount")}
                          </label>
                          <CurrencyFormat
                            value={minDepositAmount}
                            name="minDepositAmount"
                            thousandSeparator={true}
                            className="amount-input"
                            style={{
                              width: "60%",
                            }}
                            onValueChange={(values) => {
                              const { value } = values;
                              setMinDepositAmount(value);
                            }}
                          />
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>{" "}
                </div>
                <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                  <div className="col-sm-12 col-md-6 px-0 ">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-start">
                          <label className="text-label d-flex flex-align-center">
                            Maximum Deposit Amount
                            {renderTooltip("Maximum Deposit Amount")}
                          </label>
                          <CurrencyFormat
                            value={maxDepositAmount}
                            name="maxDepositAmount"
                            thousandSeparator={true}
                            className="amount-input"
                            style={{
                              width: "60%",
                            }}
                            onValueChange={(values) => {
                              const { value } = values;
                              setMaxDepositAmount(value);
                            }}
                          />
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                  <div className="col-sm-12 col-md-6 px-0">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-start">
                          <label className="text-label d-flex flex-align-center">
                            Interest Posting Period*
                            {renderTooltip("Interest Posting Period")}
                          </label>
                          <div className="w-100" style={{ maxWidth: '60%' }}>
                            <ReactSelect
                              isClearable={true}
                              className="fs-12"
                              styles={REACT_SELECT_CUSTOM_STYlES}
                              name="interestPostingPeriodType"
                              placeholder="Select interest posting period"
                              onChange={(period, { name }) => editSavingsProductHandlerForSelect(name, period?.value)}
                              value={{
                                value: newFixedProduct?.interestPostingPeriodType,
                                label: fixedDepositProduct
                                  ?.interestPostingPeriodTypeOptions
                                  ?.find((option) => option?.id === newFixedProduct?.interestPostingPeriodType)
                                  ?.value,
                              }}
                              options={fixedDepositProduct
                                ?.interestPostingPeriodTypeOptions
                                ?.map((item) => ({
                                  label: item.value,
                                  value: item.id,
                                }))
                              }
                            />
                          </div>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                </div>

                <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                  <div className="col-sm-12 col-md-6 px-0 ">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-start">
                          <label className="text-label d-flex flex-align-center">
                            Interest Compounding Period*
                            {renderTooltip("Interest Compounding Period")}
                          </label>
                          <div className="w-100" style={{ maxWidth: '60%' }}>
                            <ReactSelect
                              isClearable={true}
                              className="fs-12"
                              styles={REACT_SELECT_CUSTOM_STYlES}
                              name="interestCompoundingPeriodType"
                              placeholder="Select interest compounding period"
                              onChange={(period, { name }) => editSavingsProductHandlerForSelect(name, period?.value)}
                              value={{
                                value: newFixedProduct?.interestCompoundingPeriodType,
                                label: fixedDepositProduct
                                  ?.interestCompoundingPeriodTypeOptions
                                  ?.find((option) => option?.id === newFixedProduct?.interestCompoundingPeriodType)
                                  ?.value,
                              }}
                              options={fixedDepositProduct
                                ?.interestCompoundingPeriodTypeOptions
                                ?.map((item) => ({
                                  label: item.value,
                                  value: item.id,
                                }))
                              }
                            />
                          </div>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                  <div className="col-sm-12 col-md-6 px-0">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-end">
                          <label className="text-label d-flex flex-align-center">
                            Days In The Year*
                            {renderTooltip("Days In The Year")}
                          </label>
                          <div className="w-100" style={{ maxWidth: '60%' }}>
                            <ReactSelect
                              isClearable={true}
                              className="fs-12"
                              styles={REACT_SELECT_CUSTOM_STYlES}
                              name="interestCalculationDaysInYearType"
                              placeholder="Select days in year"
                              onChange={(period, { name }) => editSavingsProductHandlerForSelect(name, period?.value)}
                              value={{
                                value: newFixedProduct?.interestCalculationDaysInYearType,
                                label: fixedDepositProduct
                                  ?.interestCalculationDaysInYearTypeOptions
                                  ?.find((option) => option?.id === newFixedProduct?.interestCalculationDaysInYearType)
                                  ?.value,
                              }}
                              options={fixedDepositProduct
                                ?.interestCalculationDaysInYearTypeOptions
                                ?.map((item) => ({
                                  label: item.value,
                                  value: item.id,
                                }))
                              }
                            />
                          </div>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                </div>

                <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                  <div className="col-sm-12 col-md-6 px-0">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-start">
                          <label className="text-label d-flex flex-align-center">
                            Interest Calculated Using*
                            {renderTooltip("Interest Calculated Using")}
                          </label>
                          <div className="w-100" style={{ maxWidth: '60%' }}>
                            <ReactSelect
                              isClearable={true}
                              className="fs-12"
                              styles={REACT_SELECT_CUSTOM_STYlES}
                              name="interestCalculationType"
                              placeholder="Select interest calculation type"
                              onChange={(period, { name }) => editSavingsProductHandlerForSelect(name, period?.value)}
                              value={{
                                value: newFixedProduct?.interestCalculationType,
                                label: fixedDepositProduct
                                  ?.interestCalculationTypeOptions
                                  ?.find((option) => option?.id === newFixedProduct?.interestCalculationType)
                                  ?.value,
                              }}
                              options={fixedDepositProduct
                                ?.interestCalculationTypeOptions
                                ?.map((item) => ({
                                  label: item.value,
                                  value: item.id,
                                }))
                              }
                            />
                          </div>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                </div>
                {/* Settings */}
                <div className="pt-2">
                  <FormTitle>
                    <p className="text-info">Settings</p>
                    <div className="lineThrough"></div>
                  </FormTitle>

                  <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                    <div className="col-sm-12 col-md-6 px-0">
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-end">
                            <label className="text-label d-flex flex-align-center">
                              Lock-In Period {renderTooltip("Lock-in Period")}
                            </label>
                            <div
                              style={{ width: "60%" }}
                              className="d-flex gap-4 justify-content-between align-items-start"
                            >
                              <Input
                                style={{ width: "28%" }}
                                size="md"
                                name="lockinPeriodFrequency"
                                type="number"
                                value={newFixedProduct.lockinPeriodFrequency}
                                onChange={editSavingsProductHandler}
                              />
                              <div style={{ width: "70%" }}>
                                <ReactSelect
                                  isClearable={true}
                                  className="fs-12"
                                  styles={REACT_SELECT_CUSTOM_STYlES}
                                  name="lockinPeriodFrequencyType"
                                  placeholder="Select frequency"
                                  onChange={(period, { name }) => editSavingsProductHandlerForSelect(name, period?.value)}
                                  value={{
                                    value: newFixedProduct?.lockinPeriodFrequencyType,
                                    label: fixedDepositProduct
                                      ?.lockinPeriodFrequencyTypeOptions
                                      ?.find((option) => option?.id === newFixedProduct?.lockinPeriodFrequencyType)
                                      ?.value,
                                  }}
                                  options={fixedDepositProduct
                                    ?.lockinPeriodFrequencyTypeOptions
                                    ?.map((item) => ({
                                      label: item.value,
                                      value: item.id,
                                    }))
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </FormElements>
                      </FormClass>
                    </div>
                    <div className="col-sm-12 col-md-6 px-0">
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-start">
                            <label className="text-label d-flex flex-align-center">
                              Minimum Deposit Term*
                              {renderTooltip("Minimum Deposit Term")}
                            </label>
                            <div
                              style={{ width: "60%" }}
                              className="d-flex gap-4 justify-content-between align-items-start"
                            >
                              <Input
                                style={{ width: "28%" }}
                                size="md"
                                name="minDepositTerm"
                                type="number"
                                value={newFixedProduct.minDepositTerm}
                                onChange={editSavingsProductHandler}
                              />
                              <div style={{ width: "70%" }}>
                                <ReactSelect
                                  isClearable={true}
                                  className="fs-12"
                                  styles={REACT_SELECT_CUSTOM_STYlES}
                                  name="minDepositTermTypeId"
                                  placeholder="Select term"
                                  onChange={(period, { name }) => editSavingsProductHandlerForSelect(name, period?.value)}
                                  value={{
                                    value: newFixedProduct?.minDepositTermTypeId,
                                    label: fixedDepositProduct
                                      ?.periodFrequencyTypeOptions
                                      ?.find((option) => option?.id === newFixedProduct?.minDepositTermTypeId)
                                      ?.value,
                                  }}
                                  options={fixedDepositProduct
                                    ?.periodFrequencyTypeOptions
                                    ?.map((item) => ({
                                      label: item.value,
                                      value: item.id,
                                    }))
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </FormElements>
                      </FormClass>
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                    <div className="col-sm-12 col-md-6 px-0">
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-start">
                            <label className="text-label d-flex flex-align-center">
                              And Thereafter In Multiple Of
                              {renderTooltip("And Thereafter In Multiple Of")}
                            </label>
                            <div
                              style={{ width: "60%" }}
                              className="d-flex gap-4 justify-content-between align-items-start"
                            >
                              <Input
                                style={{ width: "28%" }}
                                size="md"
                                name="inMultiplesOfDepositTerm"
                                type="number"
                                value={newFixedProduct.inMultiplesOfDepositTerm}
                                onChange={editSavingsProductHandler}
                              />
                              <div style={{ width: "70%" }}>
                                <ReactSelect
                                  isClearable={true}
                                  className="fs-12"
                                  styles={REACT_SELECT_CUSTOM_STYlES}
                                  name="inMultiplesOfDepositTermTypeId"
                                  placeholder="Select term"
                                  onChange={(period, { name }) => editSavingsProductHandlerForSelect(name, period?.value)}
                                  value={{
                                    value: newFixedProduct?.inMultiplesOfDepositTermTypeId,
                                    label: fixedDepositProduct
                                      ?.periodFrequencyTypeOptions
                                      ?.find((option) => option?.id === newFixedProduct?.inMultiplesOfDepositTermTypeId)
                                      ?.value,
                                  }}
                                  options={fixedDepositProduct
                                    ?.periodFrequencyTypeOptions
                                    ?.map((item) => ({
                                      label: item.value,
                                      value: item.id,
                                    }))
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </FormElements>
                      </FormClass>
                    </div>
                    <div className="col-sm-12 col-md-6 px-0">
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-start">
                            <label className="text-label d-flex flex-align-center">
                              Maximum Deposit Term*
                              {renderTooltip("Minimum Deposit Term")}
                            </label>
                            <div
                              style={{ width: "60%" }}
                              className="d-flex gap-4 justify-content-between align-items-start"
                            >
                              <Input
                                style={{ width: "28%" }}
                                size="md"
                                name="maxDepositTerm"
                                type="number"
                                value={newFixedProduct.maxDepositTerm}
                                onChange={editSavingsProductHandler}
                              />
                              <div style={{ width: "70%" }}>
                                <ReactSelect
                                  isClearable={true}
                                  className="fs-12"
                                  styles={REACT_SELECT_CUSTOM_STYlES}
                                  name="maxDepositTermTypeId"
                                  placeholder="Select term"
                                  onChange={(period, { name }) => editSavingsProductHandlerForSelect(name, period?.value)}
                                  value={{
                                    value: newFixedProduct?.maxDepositTermTypeId,
                                    label: fixedDepositProduct
                                      ?.periodFrequencyTypeOptions
                                      ?.find((option) => option?.id === newFixedProduct?.maxDepositTermTypeId)
                                      ?.value,
                                  }}
                                  options={fixedDepositProduct
                                    ?.periodFrequencyTypeOptions
                                    ?.map((item) => ({
                                      label: item.value,
                                      value: item.id,
                                    }))
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </FormElements>
                      </FormClass>
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                    <div className="col-sm-12 col-md-6 px-0 ">
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-end">
                            <label className="text-label d-flex flex-align-center">
                              For Premature Closure
                              {renderTooltip("For Premature Closure")}
                            </label>
                            <Checkbox
                              size="md"
                              style={{ width: "60%" }}
                              name="preClosurePenalApplicable"
                              isChecked={preClosurePenalApplicable}
                              onChange={(e) =>
                                setPreClosurePenalApplicable(
                                  !preClosurePenalApplicable
                                )
                              }
                            />
                          </div>
                        </FormElements>
                      </FormClass>
                    </div>
                    {preClosurePenalApplicable && (
                      <div className="col-sm-12 col-md-6 px-0">
                        <FormClass>
                          <FormElements>
                            <div className="form-group d-flex justify-content-between align-items-end">
                              <label className="text-label d-flex flex-align-center">
                                Applied Penal Interest
                                {renderTooltip("Applied Penal Interest")}
                              </label>
                              <Input
                                style={{ width: "60%" }}
                                size="md"
                                name="preClosurePenalInterest"
                                value={newFixedProduct.preClosurePenalInterest}
                                onChange={editSavingsProductHandler}
                              />
                            </div>
                          </FormElements>
                        </FormClass>
                      </div>
                    )}
                  </div>

                  <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap align-items-start">
                    <div className="col-sm-6 col-md-6 px-0 ">
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label d-flex flex-align-center">
                              Is Withhold Tax Applicable
                              {renderTooltip("Is Withhold Tax Applicable")}
                            </label>
                            <FormControl style={{ width: "60%" }}>
                              <Checkbox
                                size="md"
                                name="withHoldTax"
                                isChecked={withHoldTax}
                                onChange={(e) => setWithHoldTax(!withHoldTax)}
                              />
                            </FormControl>
                          </div>
                        </FormElements>
                      </FormClass>
                    </div>
                    {withHoldTax && (
                      <div className="col-sm-6 col-md-6 px-0">
                        <FormClass>
                          <FormElements>
                            <div className="form-group d-flex justify-content-between align-items-end">
                              <label className="text-label d-flex flex-align-center">
                                Tax Group
                                {renderTooltip("Tax Group")}
                              </label>
                              <div className="w-100" style={{ maxWidth: '60%' }}>
                                <ReactSelect
                                  isClearable={true}
                                  className="fs-12"
                                  styles={REACT_SELECT_CUSTOM_STYlES}
                                  name="taxGroupId"
                                  placeholder="Select tax group"
                                  onChange={(period, { name }) => editSavingsProductHandlerForSelect(name, period?.value)}
                                  value={{
                                    value: newFixedProduct?.taxGroupId,
                                    label: fixedDepositProduct
                                      ?.taxGroupOptions
                                      ?.find((option) => option?.id === newFixedProduct?.taxGroupId)
                                      ?.name,
                                  }}
                                  options={fixedDepositProduct
                                    ?.taxGroupOptions
                                    ?.map((item) => ({
                                      label: item.name,
                                      value: item.id,
                                    }))
                                  }
                                />
                              </div>
                            </div>
                          </FormElements>
                        </FormClass>
                      </div>
                    )}
                  </div>
                </div>
                {/* Interest Rate Chart */}
                <div className="pt-2">
                  <FormTitle>
                    <p className="text-info">Interest Rate </p>
                    <div className="lineThrough"></div>
                  </FormTitle>
                  <EditFixedInterestRateTemp
                    template={fixedDepositProduct}
                    validFrom={validFrom}
                    handleValidFrom={handleValidFrom}
                    endDate={endDate}
                    handleEndDate={handleEndDate}
                    defaultData={defaultData}
                    // interests={interests}
                    chartSlabs={chartSlabs}
                    // setInterests={setInterests}
                    setChartSlabs={setChartSlabs}
                    renderTooltip={renderTooltip}
                  />
                </div>
                {/* Charges */}
                <div className="pt-2">
                  <FormTitle>
                    <p className="text-info">Charges</p>
                    <div className="lineThrough"></div>
                  </FormTitle>
                  <EditFixedCharges
                    template={fixedDepositProduct}
                    savingsReferenceAccountId={
                      newFixedProduct.savingsReferenceAccountId
                    }
                    savingsControlAccountId={
                      newFixedProduct.savingsControlAccountId
                    }
                    transfersInSuspenseAccountId={
                      newFixedProduct.transfersInSuspenseAccountId
                    }
                    interestOnSavingsAccountId={
                      newFixedProduct.interestOnSavingsAccountId
                    }
                    incomeFromFeeAccountId={
                      newFixedProduct.incomeFromFeeAccountId
                    }
                    incomeFromPenaltyAccountId={
                      newFixedProduct.incomeFromPenaltyAccountId
                    }
                    // interestPayableAccountId={
                    //   newFixedProduct.interestPayableAccountId
                    // }
                    // setFullCharges={setFullCharges}
                    // setValueCharges={setValueCharges}
                    // valueCharges={valueCharges}
                    charges={charges}
                    setCharges={setCharges}
                    setNumberOfMapFeeToIncome={setFeeToIncomeAccountMappings}
                    numberOfMapFeeToIncome={feeToIncomeAccountMappings}
                    setNumberOfMapPenalties={setPenaltyToIncomeAccountMappings}
                    numberOfMapPenalties={penaltyToIncomeAccountMappings}
                    setNumberOfConfigFS={setPaymentChannelToFundSourceMappings}
                    numberOfConfigFS={paymentChannelToFundSourceMappings}
                    editSavingsProductHandler={editSavingsProductHandler}
                    editSavingsProductHandlerForSelect={editSavingsProductHandlerForSelect}
                    // setAccountType={setAccountType}
                    renderTooltip={renderTooltip}
                    // accountType={accountType}

                    loading={processing}
                    radio={accountType}
                    setRadio={setAccountType}
                    handleSubmit={handleSubmit}
                    setAdvance={setAdvance}
                    advance={advance}
                  />
                </div>
              </div>
            </ContentWrapper>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditFixedProductView;
