import React from "react";
import { Table } from "antd";
import Loading from "./Loading";

const columns = [
  { title: "Office Name", dataIndex: "officename", key: "officename" },
  { title: "Parent Office", dataIndex: "parentoffice", key: "parentoffice" },
  { title: "External Id", dataIndex: "externalId", key: "externalId" },
  { title: "Date Created", dataIndex: "date", key: "date" },
  { title: "", dataIndex: "edit", key: "edit" },
];

const TableLoader = (props) => {
  const someChartsData = [
    {
      key: 1,
      officename: <Loading />,
      parentoffice: <Loading />,
      externalId: <Loading />,
      date: <Loading />,
      edit: <Loading />,
    },
    {
      key: 2,
      officename: <Loading />,
      parentoffice: <Loading />,
      externalId: <Loading />,
      date: <Loading />,
      edit: <Loading />,
    },
    {
      key: 3,
      officename: <Loading />,
      parentoffice: <Loading />,
      externalId: <Loading />,
      date: <Loading />,
      edit: <Loading />,
    },
    {
      key: 4,
      officename: <Loading />,
      parentoffice: <Loading />,
      externalId: <Loading />,
      date: <Loading />,
      edit: <Loading />,
    },
    {
      key: 5,
      officename: <Loading />,
      parentoffice: <Loading />,
      externalId: <Loading />,
      date: <Loading />,
      edit: <Loading />,
    },
  ];
  return (
    <>
      <Table
        columns={columns}
        dataSource={someChartsData}
        pagination={{ defaultPageSize: 8 }}
        className="antdTable"
        defaultPageSize={8}
      />
    </>
  );
};

export default TableLoader;
