import axiosData from "../axiosData";
import config from "../../config";

const createUserSettlement = (userData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        data: userData,
        url: `${config.apiGateway.BASE_API_URL}/journalentries/endofyear`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
}

// const getGLBalances = (date) => {
//     return new Promise(async (resolve, reject) => {

//         let url = ``
//       try {
//         const { data, error } = await axiosData({
//           method: "POST",
//           data: userData,
//           url: `${config.apiGateway.BASE_API_URL}/journalentries/endofyear`,
//         });
//         if (data) {
//           resolve(data);
//         } else {
//           reject(error);
//         }
//       } catch (ex) {
//         reject(ex);
//       }
//     });
//   }

export default createUserSettlement;
