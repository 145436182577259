import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

export const PublicRoute = (props) => {
  const { isAuthenticated, component: Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Redirect to="/core" />
        ) : (
          <div>
            <Component {...props} />
          </div>
        )
      }
    />
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: !!state.userLogin.isAuthenticated,
});

export default connect(mapStateToProps)(PublicRoute);
