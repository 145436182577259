import React, { useState } from "react";
// import { Modal } from "@chakra-ui/react";
import { Modal } from "react-bootstrap";
import {
  ActionButton,
  BackActionButton,
  FormClass,
  FormElements,
  OTPDigitsFormContainer,
} from "../../styles";
import {
  Input,
  Select,
  Textarea,
  PinInput,
  PinInputField,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Toast from "../../../../../util/Toast";
import {
  authenticateNibssCode,
  TransferNibssFunds,
} from "../../../../../actions/nibssConnectActions";
import { useEffect } from "react";
import LoadingLoader from "../../LoadingLoader";

const OtpModal = ({
  OtpModal,
  setOtpModal,
  setMakePaymentModal,
  onHide,
  data,
  receiverSessionId,
  receiverChannelCode,
  receiverInstitutionCode,
  receiverAccountNumber,
  receiverAccountName,
  receiverBvn,
  receiverKyc,
  senderBvn,
  senderAccountName,
  senderAccountNumber,
  senderKyc,
  transferNarration,
  transferAmount,
  setSuccessModal,
  successModal,
  clientNuban
}) => {
  const [otpValue, setOtpValue] = useState("");
  // const [username, setUsername] = useState("");

  const dispatch = useDispatch();
  const userDataObj = useSelector((state) => state.userData);
  const { userData } = userDataObj;

  const userAuthenticate = useSelector((state) => state.AuthenticatePinCode);

  const { username } = userData;
  console.log(userAuthenticate, "userAuthenticate");
  console.log(username, "username");

  const { nibssAuthenticateNibssOtp, error } = userAuthenticate;

  const { message, status } = nibssAuthenticateNibssOtp || {};

  const makePayment = useSelector((state) => state.TransferNibssFund);

  const { loading ,nibssTransferFunds } = makePayment || {};

  console.log(makePayment , "makePayment")
  console.log(nibssTransferFunds , "nibssTransferFunds")

  const { responseCode } = nibssTransferFunds || {};

  console.log(message, "successMessage");
  console.log(status, "successStatus");

  // if (!OtpModal) {
  //   return null;
  // }

  const validateOtp = () => {
    let datatoSend = {
      username: username,
      code: otpValue,
    };

    console.log(datatoSend, "datatoSend");
    if (!!otpValue === false || otpValue.length < 6) {
      dispatch(Toast({ text: "Please Provide a Valid OTP", icon: "info" }));
    } else {
      dispatch(authenticateNibssCode(datatoSend));
      // handleFundsTransfer()
    }
  };

  //

  useEffect(() => {
    let data = {
      // Beneficiary
      nameEnquiryRef: receiverSessionId,
      beneficiaryInstitutionCode: receiverInstitutionCode,
      channelCode: receiverChannelCode,
      beneficiaryAccountName: receiverAccountName,
      beneficiaryAccountNumber: receiverAccountNumber,
      beneficiaryBvn: receiverBvn,
      beneficiaryKycLevel: receiverKyc,

      // Sender
      customerAccountName: senderAccountName,
      customerAccountNumber: clientNuban,
      customerBvn: senderBvn,
      customerKycLevel: senderKyc,
      narration: transferNarration,
      paymentReference: "NIBBSNIP_"+receiverSessionId,
      amount: transferAmount,
    };
    console.log(data, "2222");
    if (status) {
      setOtpModal(false);
      dispatch(TransferNibssFunds(data));
    } else if (responseCode === "00") {
      setSuccessModal(true);
      setOtpModal(false);
    }
  }, [status]);

  // let fundsData = {
  // "nameEnquiryRef": "999319230104145318672843998177",
  // "beneficiaryInstitutionCode": "999998",
  // "channelCode": "1",
  // "beneficiaryAccountName": "Stan Lee",
  // "beneficiaryAccountNumber": "0051762787",
  // "beneficiaryBvn": "22222222223",
  // "beneficiaryKycLevel": "1",
  // "customerAccountName": "Olawale adeogun",
  // "customerAccountNumber": "1100066120",
  // "customerBvn": "90909090987",
  // "customerKycLevel": "1",
  // "narration": "Testing Adele transfer",
  // "paymentReference": "Ref/2023/01/04",
  // "amount": 100
  // };

  const handleBack = () => {
    console.log("here back");
    setMakePaymentModal(true);
    setOtpModal(false);
  };

  return (
    <>
      {/* <Modal> */}
      {loading && status ? <LoadingLoader show={loading}  onHide={() => null} /> : null}
      <Modal.Header>
        <Modal.Title>Make Payment</Modal.Title>
        <div className="payment__close-modal" onClick={onHide}>
          X
        </div>
      </Modal.Header>
      <Modal.Body className="wc-modal">
        <div className="col-sm-12 col-md-12  align-items-center justify-content-center flex-direction-coloumn">
          {/* Cmomplete Payment */}
          <h3 className="otp__payment">Complete Payment</h3>
          {/* span  Enter pin to confirm payment*/}
          <span className="otp__confirm">Enter pin to confirm payment</span>
          {/* Pin field */}
          <OTPDigitsFormContainer className="mt-3">
            <PinInput value={otpValue} onChange={setOtpValue}>
              {/* <PinInput> */}
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
            </PinInput>
          </OTPDigitsFormContainer>
          {/* span resend otp */}
        </div>
      </Modal.Body>
      {/* Download */}
      <div className="payment__footer">
        <BackActionButton
          color="#2C1DFF"
          className="float-right"
          style={{ margin: "0px 5px" }}
          id="continue"
          onClick={handleBack}
        >
          Back
        </BackActionButton>{" "}
        <ActionButton
          color="#2C1DFF"
          className="float-right"
          style={{ margin: "0px 5px" }}
          id="continue"
          // onClick={(e) => {
          //   e.preventDefault();
          //   validateOtp();
          // }}
          onClick={validateOtp}
        >
          Confirm
        </ActionButton>{" "}
      </div>
      {/* </Modal> */}
    </>
  );
};

export default OtpModal;
