import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import PropTypes from 'prop-types';
// import cookie from "react-cookies";
import DashboardNavigation from '../../components/Dashboard/Navbar';
import DashboardAsideNaivagation from '../../components/Dashboard/Sidebar';
import { MainWrapper } from './styles';
import BlockUI from '../../components/BlockUI';
import GeneralSearch from '../../components/GeneralSearch';
import ToastManager from '../../components/ToastManager';
// import PasswordSecurity from '../../components/PasswordSecurity';
import BulkImportNotification from '../../components/BulkImportNotification';
import {
  deleteToken,
  logoutUser,
  setUserDataPermission,
} from '../../actions/authActions';
// import { setShowSecurityModal } from '../../actions/authActions';

const DashboardLayout = ({ children }, props) => {
  const dispatch = useDispatch();

  const isGeneralSearching = useSelector(
    (state) => state.isGeneralSearching.isGeneralSearching
  );

  const navigationBar = useSelector((state) => state.navigationBar);

  let { navigationBarActive } = navigationBar || {};

  const userDataRes = useSelector((state) => state.preAuth);
  const { responseUserData, showSecurityModal, isValidUserSecurity } =
    userDataRes || {};
  // console.log({ responseUserData });
  // useEffect(() => {
  //   if (!showSecurityModal) {
  //     setTimeout(function () {
  //       setShowSecurityModal(true, dispatch);
  //       deleteToken(dispatch);
  //     }, 1 * 60 * 20); //60 mins
  //     // 20mins 1000 * 60 * 20
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [showSecurityModal]);

  useEffect(() => {
    const fetchData = async () => {
      let { permissions = [] } = responseUserData || {};
      if (!permissions || permissions.length === 0 || !permissions.length) {
        let userPermission = localStorage.getItem('permissions');
        if (userPermission) setUserDataPermission(JSON.parse(userPermission), dispatch);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isTimedOut, setIsTimedOut] = useState(false);
  const [timeOut, setTimeOut] = useState(1000 * 60 * 15);
  // const [timeOut, setTimeOut] = useState(10 * 1000);

  const refContainer = useRef();

  const onIdle = () => {
    setIsTimedOut(true);
    dispatch(logoutUser());
  };

  return (
    <div className="woodcore-dashboard">
      <IdleTimer
        ref={refContainer}
        element={document}
        onIdle={onIdle}
        debounce={250}
        timeout={timeOut}
      />
      {isGeneralSearching ? <GeneralSearch /> : ''}

      <ToastManager />
      <BulkImportNotification />
      {/* {showSecurityModal ? <PasswordSecurity /> : ''} */}

      <DashboardNavigation />
      <BlockUI />
      <div className="dashboard">
        <MainWrapper>
          <DashboardAsideNaivagation />
          <main
            role="main"
            className={`${
              navigationBarActive ? 'main-content-open' : 'main-content'
            } pt-5 px-4 pb-5`}
          >
            {children}
          </main>
        </MainWrapper>
      </div>
    </div>
  );
};
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
