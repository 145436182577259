import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ActionButton } from '../../styles.jsx';
import config from '../../../../config';
import { useDispatch, useSelector } from 'react-redux';
import AuthModule from '../../../../modules/Auth.module';
import axiosData from '../../../../services/axiosData';
import { SET_LOADING_STATE } from '../../../../constants/utilityConstants';

const ViewImage = ({ onHide, data, clientId }) => {
  const dispatch = useDispatch();
  const [source, setSource] = useState();
  const { isLoading } = useSelector((state) => state.isLoading);

  const viewDocument = async () => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    const url = `${
      config.apiGateway.BASE_API_URL
    }/clients/${clientId}/documents/${data?.id}/attachment?tenantIdentifier=${
      config.apiGateway.IS_PRODUCTION === `true`
        ? AuthModule.getTenant()
        : config.apiGateway.WOODCORE_TENANT
    }`;
    try {
      const { data } = await axiosData({
        method: 'GET',
        signal: false,
        url: url,
        responseType: 'blob',
      });
      if (data) {
        const dataUrl = URL.createObjectURL(data);
        setSource(dataUrl);
      } else {
      }
      dispatch({ type: SET_LOADING_STATE, payload: false });
    } catch (ex) {}
  };

  useEffect(() => {
    viewDocument();
  }, []);

  return isLoading ? (
    ''
  ) : (
    <>
      <Modal.Header>
        <Modal.Title>View Document</Modal.Title>
      </Modal.Header>
      <Modal.Body className="wc-modal">
        <div className="d-flex align-items-center justify-content-center flex-direction-coloumn">
          {data?.type !== 'application/pdf' && (
            <object
              height="100%"
              width="100%"
              ng-show="preview"
              data={source}
              type="image/png"
            >
              {data?.fileName}
            </object>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-4">
          <ActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require('../../../../assets/images/icons/cancel.svg').default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default ViewImage;
