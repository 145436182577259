import {
  SET_FIXED_DEPOSIT,
  SET_FIXED_DEPOSIT_TEMPLATE,
  SET_ONE_FIXED_DEPOSIT,
  SET_FIXED_DEPOSIT_ACCOUNT_TEMPLATE,
  SET_FIXED_DEPOSIT_ACCOUNT_DETAILS,
  SET_FD_LEDGER_DETAILS,
  SET_FIXED_DEPOSIT_ACTIONS,
  UPDATE_CHARGES,
  WAIVE_CHARGES,
  SET_FIXED_TRANSACTIONS,
  SET_FIXED_DEPOSIT_ACTIONS_RESET,
  ALL_FD_PRODUCT_REQUEST,
  ALL_FD_PRODUCT_SUCCESS,
  ALL_FD_PRODUCT_FAIL,
  FILTER_FD_PRODUCT_REQUEST,
  CREATE_NEW_FD_PRODUCT_REQUEST,
  CREATE_NEW_FD_PRODUCT_SUCCESS,
  CREATE_NEW_FD_PRODUCT_FAIL,
  CREATE_NEW_FD_PRODUCT_RESET,
  UPDATE_FD_PRODUCT_REQUEST,
  UPDATE_FD_PRODUCT_SUCCESS,
  UPDATE_FD_PRODUCT_FAIL,
  ALL_FD_PRODUCTS_TEMPLATE_REQUEST,
  ALL_FD_PRODUCTS_TEMPLATE_SUCCESS,
  ALL_FD_PRODUCTS_TEMPLATE_FAIL,
  FD_PRODUCT_REQUEST,
  FD_PRODUCT_SUCCESS,
  FD_PRODUCT_FAIL,
  FD_PRODUCT_RESET,
  CLEAR_FD_PRODUCT_FILTER,
  ALL_FIXEDDEPOSIT_PRODUCTS_TEMPLATE_REQUEST,
  ALL_FIXEDDEPOSIT_PRODUCTS_TEMPLATE_SUCCESS,
  ALL_FIXEDDEPOSIT_PRODUCTS_TEMPLATE_FAIL,
  RESET_ALL_FIXEDDEPOSIT_PRODUCTS_TEMPLATE,
  GENERATE_FD_FILE_REQUEST,
  GENERATE_FD_FILE_SUCCESS,
  GENERATE_FD_FILE_FAIL,
  RESET_GENERATE_FD_FILE,
  UPDATE_FD_ACCOUNT_REQUEST,
  UPDATE_FD_ACCOUNT_SUCCESS,
  UPDATE_FD_ACCOUNT_FAIL,
  UPDATE_FD_ACCOUNT_RESET,
} from "../constants/fixedDeposit";

const initialState = {
  fixedDeposits: [],
  fixedDepositTemplate: {},
  fixedAccountTransactions: {},
  fixedDepositAccountTemplate: {},
  fixedDepositAccountCreationTemplate: {},
  fixedDepositAccountDetails: {},
  fixedDepositAccountActions: {},
  fixedDepositLedgerDetails: {},
};

function setFixedDepositReducers(state = initialState, action) {
  switch (action.type) {
    case SET_FIXED_DEPOSIT: {
      return {
        ...state,
        fixedDeposits: action.payload,
      };
    }
    case SET_FIXED_DEPOSIT_TEMPLATE: {
      return {
        ...state,
        fixedDepositTemplate: action.payload,
      };
    }

    case SET_FIXED_TRANSACTIONS: {
      return {
        ...state,
        fixedAccountTransactions: action.payload,
      };
    }

    case SET_ONE_FIXED_DEPOSIT: {
      return {
        ...state,
        fixedDepositAccountTemplate: action.payload,
      };
    }
    case SET_FIXED_DEPOSIT_ACCOUNT_TEMPLATE: {
      return {
        ...state,
        fixedDepositAccountCreationTemplate: action.payload,
      };
    }
    case SET_FIXED_DEPOSIT_ACCOUNT_DETAILS: {
      return {
        ...state,
        fixedDepositAccountDetails: action.payload,
      };
    }
    case SET_FD_LEDGER_DETAILS: {
      return {
        ...state,
        fixedDepositLedgerDetails: action.payload,
      };
    }
    case SET_FIXED_DEPOSIT_ACTIONS: {
      return {
        ...state,
        fixedDepositAccountActions: action.payload,
      };
    }

    case UPDATE_CHARGES: {
      let newCharges = [];
      const oldCharges = state.fixedDepositAccountDetails.charges;
      if (oldCharges) {
        newCharges = [...oldCharges];
        const amountOutstanding =
          newCharges[action.payload.index].amountOutstanding;
        newCharges[action.payload.index].amountOutstanding = 0;
        newCharges[action.payload.index].amountPaid = amountOutstanding;
      }
      // console.log(newCharges, 'dispatch chargess')
      return {
        ...state,
        fixedDepositAccountDetails: {
          ...state.fixedDepositAccountDetails,
          charges: newCharges,
        },
      };
    }

    case WAIVE_CHARGES: {
      let newCharges = [];
      const oldCharges = state.fixedDepositAccountDetails.charges;
      if (oldCharges) {
        newCharges = [...oldCharges];
        const amountOutstanding =
          newCharges[action.payload.index].amountOutstanding;
        newCharges[action.payload.index].amountOutstanding = 0;
        newCharges[action.payload.index].amountWaived = amountOutstanding;
      }
      // console.log(newCharges, 'dispatch chargess')
      return {
        ...state,
        fixedDepositAccountDetails: {
          ...state.fixedDepositAccountDetails,
          charges: newCharges,
        },
      };
    }

    case SET_FIXED_DEPOSIT_ACTIONS_RESET: {
      return {
        ...state,
        fixedDepositAccountActions: {},
      };
    }

    default: {
      return state;
    }
  }
}

export default setFixedDepositReducers;

//REDUCER FOR FD ACCOUNT TEMPLATE
export const fixedDepositAcountTemplateReducer = (
  state = { fixedDepositAccountCreateTemplate: {} },
  action
) => {
  switch (action.type) {
    case ALL_FIXEDDEPOSIT_PRODUCTS_TEMPLATE_REQUEST:
      return { ...state, loading: true };
    case ALL_FIXEDDEPOSIT_PRODUCTS_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        fixedDepositAccountCreateTemplate: action.payload,
      };
    case RESET_ALL_FIXEDDEPOSIT_PRODUCTS_TEMPLATE:
      return {
        ...state,
        fixedDepositAccountCreateTemplate: null,
        loading: false,
        error: null,
      };
    case ALL_FIXEDDEPOSIT_PRODUCTS_TEMPLATE_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

//Fixed Deposits PRODUCTS
//REDUCER FOR ALL FD PRODUCTS
export const fdProductsReducer = (
  state = { fixedDepositProducts: [] },
  action
) => {
  switch (action.type) {
    case ALL_FD_PRODUCT_REQUEST:
      return { ...state, loading: true };
    case ALL_FD_PRODUCT_SUCCESS:
      return { ...state, loading: false, fixedDepositProducts: action.payload };
    case ALL_FD_PRODUCT_FAIL:
      return { ...state, loading: false, error: action.payload };
    case FILTER_FD_PRODUCT_REQUEST:
      return {
        ...state,
        filtered: state.fixedDepositProducts.filter((cst) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return (
            cst.shortName.match(regex) || (cst.name && cst.name.match(regex))
          );
        }),
      };
    case CLEAR_FD_PRODUCT_FILTER:
      return { ...state, filtered: null };
    default:
      return state;
  }
};

//REDUCER FOR FD PRODUCTS TEMPLATE
export const fixedDepositProductsTemplateReducer = (
  state = { fixedDepositProductsTemplate: {} },
  action
) => {
  switch (action.type) {
    case ALL_FD_PRODUCTS_TEMPLATE_REQUEST:
      return { ...state, loading: true };
    case ALL_FD_PRODUCTS_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        fixedDepositProductsTemplate: action.payload,
      };
    case ALL_FD_PRODUCTS_TEMPLATE_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

//REDUCER FOR CREATE NEW FD PRODUCT
export const createNewFDProductReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_NEW_FD_PRODUCT_REQUEST:
      return { loading: true, ...state };
    case CREATE_NEW_FD_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        newFDProductCreated: action.payload,
      };
    case CREATE_NEW_FD_PRODUCT_FAIL:
      return { ...state, loading: false, error: action.payload };
    case CREATE_NEW_FD_PRODUCT_RESET:
      return {
        ...state,
        newFDProductCreated: null,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

//REDUCER TO UPDATE FD PRODUCT DETAILS
export const updateFDProductInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_FD_PRODUCT_REQUEST:
      return { loading: true, ...state };
    case UPDATE_FD_PRODUCT_SUCCESS:
      return { loading: false, singleFDProduct: action.payload };
    case UPDATE_FD_PRODUCT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER FOR FD PRODUCT
export const updateFdAccountInfoReducer = (
  state = { FdAccountInfo: {} },
  action
) => {
  switch (action.type) {
    case UPDATE_FD_ACCOUNT_REQUEST:
      return { ...state, loading: true };
    case UPDATE_FD_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        FdAccountInfo: action.payload,
      };
    case UPDATE_FD_ACCOUNT_FAIL:
      return { ...state, loading: false, error: action.payload };
    case UPDATE_FD_ACCOUNT_RESET:
      return {
        ...state,
        FdAccountInfo: null,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
//REDUCER FOR FD PRODUCT
export const fdProductReducer = (
  state = { fixedDepositProduct: {} },
  action
) => {
  switch (action.type) {
    case FD_PRODUCT_REQUEST:
      return { ...state, loading: true };
    case FD_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        fixedDepositProduct: action.payload,
      };
    case FD_PRODUCT_FAIL:
      return { ...state, loading: false, error: action.payload };
    case FD_PRODUCT_RESET:
      return {
        ...state,
        fixedDepositProduct: null,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

//REDUCER TO GENERATE FD FILE
export const GenerateFdFileReducer = (
  // state = { generateFdTemplate: { status: "", file: "" } },
  state = { generateFdTemplate: {} },
  action
) => {
  switch (action.type) {
    case GENERATE_FD_FILE_REQUEST:
      // console.log(action.payload, "payload fom first reddddd");
      return { ...state, loading: true };
    case GENERATE_FD_FILE_SUCCESS:
      return { loading: false, generateFdTemplate: action.payload };
    case GENERATE_FD_FILE_FAIL:
      return { loading: false, error: action.payload };
    case RESET_GENERATE_FD_FILE:
      return { ...state, generateFdTemplate: { status: "", file: "" } };
    default:
      return state;
  }
};
