export const SET_ALL_TELLERS = "SET_ALL_TELLERS";
export const SET_ALL_OFFICE = "SET_ALL_OFFICE";
export const SET_ALL_CURRENCY = "SET_ALL_CURRENCY";
export const SET_STAFF_IN_OFFICE = "SET_STAFF_IN_OFFICE";
export const SET_TILLS = "SET_TILLS";
export const SET_ALL_TELLER_GROUPS = "SET_ALL_TELLER_GROUPS";
export const SET_SINGLE_TELLER_GROUP_CASHIERS =
  "SET_SINGLE_TELLER_GROUP_CASHIERS";
export const SET_SINGLE_TELLER_GROUP_CASHIERS_RESET =
  "SET_SINGLE_TELLER_GROUP_CASHIERS_RESET";

export const SET_TELLER_CASHIER_DETAILS = "SET_TELLER_CASHIER_DETAILS";
export const SET_TELLER_CASHIER_DETAILS_RESET =
  "SET_TELLER_CASHIER_DETAILS_RESET";
export const DELETE_TELLER_GROUP = "DELETE_TELLER_GROUP";
export const DELETE_TELLER_GROUP_SUCCESS = "DELETE_TELLER_GROUP_SUCCESS";
export const DELETE_TELLER_GROUP_FAIL = "DELETE_TELLER_GROUP_FAIL";

export const GET_CASHIER_TRANSACTIONS = "GET_CASHIER_TRANSACTIONS";

export const CREATE_TELLER_GROUP = "CREATE_TELLER_GROUP";
export const CREATE_TELLER_GROUP_SUCCESS = "CREATE_TELLER_GROUP_SUCCESS";
export const CREATE_TELLER_GROUP_FAIL = "CREATE_TELLER_GROUP_FAIL";

export const GET_ALL_CASHIER_ACTION_REQUEST = "GET_ALL_CASHIER_ACTION_REQUEST";
export const RESET_ALL_CASHIER_ACTION = "RESET_ALL_CASHIER_ACTION";

export const APPROVE_REJECT_ACTION_TO_VAULT_CASHIER =
  "APPROVE_REJECT_ACTION_TO_VAULT_CASHIER";
export const APPROVE_REJECT_ACTION_TO_VAULT_CASHIER_SUCCESS =
  "APPROVE_REJECT_ACTION_TO_VAULT_CASHIER_SUCCESS";
export const APPROVE_REJECT_ACTION_TO_VAULT_CASHIER_FAIL =
  "APPROVE_REJECT_ACTION_TO_VAULT_CASHIER_FAIL";

export const DELETE_TELLER_CASHIER = "DELETE_TELLER_CASHIER";
export const DELETE_TELLER_CASHIER_SUCCESS = "DELETE_TELLER_CASHIER_SUCCESS";
export const DELETE_TELLER_CASHIER_FAIL = "DELETE_TELLER_CASHIER_FAIL";

export const GET_CASHIER_TEMPLATE = "GET_CASHIER_TEMPLATE";
export const GET_CASHIER_TEMPLATE_RESET = "GET_CASHIER_TEMPLATE_RESET";

export const CREATE_TELLER_CASHIER = "CREATE_TELLER_CASHIER";
export const CREATE_TELLER_CASHIER_SUCCESS = "CREATE_TELLER_CASHIER_SUCCESS";
export const CREATE_TELLER_CASHIER_FAIL = "CREATE_TELLER_CASHIER_FAIL";

export const UPDATE_TELLER_GROUP = "UPDATE_TELLER_GROUP";
export const UPDATE_TELLER_GROUP_SUCCESS = "UPDATE_TELLER_GROUP_SUCCESS";
export const UPDATE_TELLER_GROUP_FAIL = "UPDATE_TELLER_GROUP_FAIL";

export const UPDATE_TELLER_CASHIER = "UPDATE_TELLER_CASHIER";
export const UPDATE_TELLER_CASHIER_SUCCESS = "UPDATE_TELLER_CASHIER_SUCCESS";
export const UPDATE_TELLER_CASHIER_FAIL = "UPDATE_TELLER_CASHIER_FAIL";

export const DEPOSIT_WITHDRAW_CASH = "DEPOSIT_WITHDRAW_CASH";
export const DEPOSIT_WITHDRAW_CASH_SUCCESS = "DEPOSIT_WITHDRAW_CASH_SUCCESS";
export const DEPOSIT_WITHDRAW_CASH_RESET = "DEPOSIT_WITHDRAW_CASH_RESET";

export const BUY_SELL_CASH = "BUY_SELL_CASH";
export const BUY_SELL_CASH_SUCCESS = "BUY_SELL_CASH_SUCCESS";

export const GET_CASHIER_ACTION_REQUEST_RECEIVED =
  "GET_CASHIER_ACTION_REQUEST_RECEIVED";
export const GET_CASHIER_ACTION_REQUEST_RECEIVED_SUCCESS =
  "GET_CASHIER_ACTION_REQUEST_RECEIVED";
export const GET_CASHIER_ACTION_REQUEST_RECEIVED_FAIL =
  "GET_CASHIER_ACTION_REQUEST_RECEIVED";

export const GET_CASHIER_ACTION_REQUEST_SENT =
  "GET_CASHIER_ACTION_REQUEST_SENT";
export const GET_CASHIER_ACTION_REQUEST_SENT_SUCCESS =
  "GET_CASHIER_ACTION_REQUEST_SENT_SUCCESS";
export const GET_CASHIER_ACTION_REQUEST_SENT_FAIL =
  "GET_CASHIER_ACTION_REQUEST_SENT_FAIL";

export const GET_TRANSACTION_SUMMARY = "GET_TRANSACTION_SUMMARY";
export const GET_TRANSACTION_SUMMARY_SUCCESS =
  "GET_TRANSACTION_SUMMARY_SUCCESS";

export const GET_CLIENT_IMAGE = "GET_CLIENT_IMAGE";
export const GET_CLIENT_IMAGE_SUCCESS = "GET_CLIENT_IMAGE_SUCCESS";
export const GET_CLIENT_IMAGE_RESET = "GET_CLIENT_IMAGE_RESET";

export const GET_DATA_TABLES = "GET_DATA_TABLES";
export const GET_DATA_TABLES_SUCCESS = "GET_DATA_TABLES_SUCCESS";
export const GET_DATA_TABLES_FAIL = "GET_DATA_TABLES_FAIL";

export const GET_CASHIERDATA_REQUEST = "GET_CASHIERDATA_REQUEST";
export const GET_CASHIERDATA_SUCCESS = "GET_CASHIERDATA_SUCCESS";
export const GET_CASHIERDATA_FAIL = "GET_CASHIERDATA_FAIL";
export const GET_CASHIERDATA_RESET = "GET_CASHIERDATA_RESET";

export const GET_CASHIERDATATELLER_REQUEST = "GET_CASHIERDATATELLER_REQUEST";
export const GET_CASHIERDATATELLER_SUCCESS = "GET_CASHIERDATATELLER_SUCCESS";
export const GET_CASHIERDATATELLER_FAIL = "GET_CASHIERDATATELLER_FAIL";
export const GET_CASHIERDATATELLER_RESET = "GET_CASHIERDATATELLER_RESET";
