import React from "react";
import { Select, Input, Textarea } from "@chakra-ui/react";
// import Toast from "../../../../../../../../util/Toast";

import { FormClass, FormElements, NextActionButton } from "../../../styles.jsx";

const SavingsDetails = ({
  handleNextScreen,
  productName,
  currency,
  shortName,
  description,
  createSavingsProductHandler,
  template,
  setDetailsError,
  setNubanPrefix,
  // nubanPrefix,
  renderTooltip,
}) => {
 
  return (
    <>
      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Product name* {renderTooltip("Product name")}
                </label>
                <Input
                  style={{ width: "60%" }}
                  size="md"
                  name="productName"
                  value={productName}
                  onChange={createSavingsProductHandler}
                />
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Currency* {renderTooltip("Currency")}
                </label>
                <Select
                  size="md"
                  name="currency"
                  value={currency}
                  onChange={createSavingsProductHandler}
                >
                  <option value="">Select Currency</option>
                  {template !== undefined && template
                    ? template.currencyOptions &&
                      template.currencyOptions.map((cur) => (
                        <option value={cur.code} key={cur.code}>
                          {cur.displayLabel}
                        </option>
                      ))
                    : null}
                </Select>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>

      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Short Name* {renderTooltip("Short Name")}
                </label>
                <Input
                  style={{ width: "60%" }}
                  size="md"
                  name="shortName"
                  value={shortName}
                  onChange={createSavingsProductHandler}
                />
              </div>
            </FormElements>
          </FormClass>
        </div>
        {/* <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Currency In Multiples Of*{" "}
                  {renderTooltip("Currency In Multiples Of")}
                </label>
                <Input
                  style={{ width: "60%" }}
                  size="md"
                  name="multipleCurrency"
                  value={multipleCurrency}
                  type="number"
                  onChange={createSavingsProductHandler}
                />
              </div>
            </FormElements>
          </FormClass>
        </div> */}
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Description
                  {renderTooltip("Description")}
                </label>
                <Textarea
                  style={{ width: "60%", fontSize: "12px" }}
                  size="md"
                  name="description"
                  value={description}
                  onChange={createSavingsProductHandler}
                />
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>

      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        {/* <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Nuban Prefix*
                  {renderTooltip("Nuban Prefix")}
                </label>
                <Input
                  style={{ width: "60%" }}
                  size="md"
                  name="nubanPrefix"
                  maxLength="2"
                  value={nubanPrefix}
                  onChange={(e) => {
                    e.preventDefault();
                    setNubanPrefix(e.target.value);
                  }}
                />
              </div>
            </FormElements>
          </FormClass>
        </div> */}
        {/* <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Decimal Places*
                  {renderTooltip("Decimal Places")}
                </label>
                <Input
                  style={{ width: "60%" }}
                  size="md"
                  name="decimalPlaces"
                  value={decimalPlaces}
                  onChange={createSavingsProductHandler}
                />
              </div>
            </FormElements>
          </FormClass>
        </div> */}
      </div>

      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap"></div>

      <div>
        <div className="float-right pt-5">
          <NextActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={() => {
              productName &&
              shortName &&
              currency &&
              // nubanPrefix &&
              // nubanPrefix.length === 2 &&
              // if (nubanPrefix.length !== 2) {
              //   dispatch(
              //     Toast({
              //       text: "Nuban Prefix most be two Letters",
              //       icon: "info",
              //     })
              //   );
              // }
              handleNextScreen();
            }}
          >
            <img
              src={
                require("../../../../../../../../assets/images/icons/arrow-right.svg")
                  .default
              }
              alt="submit icon"
            />
            Next
          </NextActionButton>
        </div>
      </div>
    </>
  );
};

export default SavingsDetails;
