/* eslint-disable import/no-anonymous-default-export */
import axiosData from "../axiosData";
import config from "../../config";
import axios from "axios";

const loginUser = (userData, cancelToken) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        signal: cancelToken ? cancelToken : false,
        data: userData,
        url: `${config.apiGateway.BASE_API_URL}/authentication`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

const finaliseLogin = (access_token) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/userdetails?access_token=${access_token}`,
        isBearerToken: false,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

const loginUserNew = (userData, cancelToken) => {
  return new Promise(async (resolve, reject) => {

    try {
      const { data, error } = await axiosData({
        method: "POST",
        signal: cancelToken ? cancelToken : false,
        data: userData,
        url: `${config.apiGateway.FA_API_URL}/authenticate`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

const generateBarcode = (username) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        data: {},
        url: `${config.apiGateway.FA_API_URL}/otpCode/generate?username=${username}&issuer=woodcore`,
        responseType: "blob",
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

const getCode = (username) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.FA_API_URL}/otpCode?username=${username}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// const getOtpActivation = (userData) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const { data, error } = await axiosData({
//         method: "POST",
//         data: userData,
//         url: `${config.apiGateway.FA_API_URL}/otpCode/activate`,
//       });
//       if (data) {
//         resolve(data);
//       } else {
//         reject(error);
//       }
//     } catch (ex) {
//       reject(ex);
//     }
//   });
// }

const get2FAStatus = (username) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.FA_API_URL}/otpCode/enabled?username=${username}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

const userSecurity = (userData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        data: userData,
        url: `${config.apiGateway.BASE_API_URL}/authentication`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/**
 * Get User information
 */

const getUserData = (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/users/${userId}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

const authenticateOTP = (userData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        data: userData,
        url: `${config.apiGateway.FA_API_URL}/otpCode/authenticate`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};


const activateOTP = (userData, dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        data: userData,
        url: `${config.apiGateway.FA_API_URL}/otpCode/activate`,
      });
      if (data) {
        resolve(data);
      } else {

        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

const getNewAccessToken = (userData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axios({
        method: "POST",
        data: userData,
        url: `${config.apiGateway.BASE_API_URL}api/oauth/token`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export default {
  loginUser,
  finaliseLogin,
  getUserData,
  userSecurity,
  loginUserNew,
  generateBarcode,
  getCode,
  get2FAStatus,
  authenticateOTP,
  // getOtpActivation,
  activateOTP,
  getNewAccessToken,
};
