import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { Textarea } from "@chakra-ui/react";
import { FormClass } from "../../styles";
import { FormElements, ActionButton } from "../../../styles";
import Toast from "../../../../../../util/Toast";
import { reverseAJournalEntry } from "../../../../../../actions/accountingActions";

const ReverseModal = ({ onHide, transactionId, history, refreshPageData }) => {
  const dispatch = useDispatch();
  const accountingData = useSelector((state) => state.accountingData);
  let { reversedJournalEntries = {} } = accountingData;
  let { transactionId: gottenId = "" } = reversedJournalEntries;

  const [comments, setComment] = useState("");
  const [ newGottenId, setNewGottenId] = useState('')

  useEffect(() => {
    setNewGottenId(gottenId)
  }, [reversedJournalEntries])

  const handleReverse = () => {
    if (!comments || !comments.length) {
      Toast({ text: "Please Add a comment" });
    } else {
      let dataToSend = {
        comments,
        transactionId,
      };
      dispatch(reverseAJournalEntry(transactionId, dataToSend , history,refreshPageData));
      // onHide();
    }
  };

  const resetReverseJE = () => {
    setNewGottenId(reversedJournalEntries.transactionId = "")
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>Reverse</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        {!!newGottenId ? (
          <div className="">
            <h2 style={{ fontSize: "1rem", padding: "10px" }}>
              Transaction Reversed Successfully. A New Journal Entry Has Been
              Created To Reverse This Transaction
            </h2>
            <div className="row" style={{ padding: "20px" }}>
              <div className="col-6">
                <p
                  className="lineHeader"
                  style={{ fontSize: "1.2rem", fontWeight: "500" }}
                >
                  Transaction Number :
                </p>
              </div>
              <div className="col-6">
                <p style={{ fontSize: "1.2rem", fontWeight: "500" }}>
                  {!!reversedJournalEntries.transactionId
                    ? reversedJournalEntries.transactionId
                    : ""}
                </p>
              </div>
            </div>
            <div className="d-flex mt-4">
              <ActionButton color="#2C1DFF" 
              onClick={() => {
                resetReverseJE();
                onHide();
              }}
              >
                <img
                  src={
                    require("../../../../../../assets/images/icons/cancel.svg")
                      .default
                  }
                  alt="cancel icon"
                />
                Cancel
              </ActionButton>
              <ActionButton
                color="#2C1DFF"
                style={{ margin: "0px 0px 0 10px" }}
                onClick={(e) => {
                  history.push(
                    `/core/journal-entries/details/${gottenId}`
                  );
                  resetReverseJE();
                }}
              >
                Redirect to New Transaction
              </ActionButton>
            </div>
          </div>
        ) : (
          <FormClass style={{ width: "90%" }}>
            <FormElements style={{ width: "100%" }}>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Comment</label>
                <Textarea
                  size="md"
                  style={{ width: "80%", marginTop: "20px" }}
                  resize={"none"}
                  value={comments}
                  onChange={(e) => setComment(e.target.value)}
                />
              </div>
            </FormElements>
            <div className="d-flex mt-4">
              <ActionButton color="#2C1DFF" onClick={onHide}>
                <img
                  src={
                    require("../../../../../../assets/images/icons/cancel.svg")
                      .default
                  }
                  alt="cancel icon"
                />
                Cancel
              </ActionButton>
              <ActionButton
                color="#2C1DFF"
                style={{ margin: "0px 0px 0 10px" }}
                onClick={(e) => {
                  e.preventDefault();
                  handleReverse();
                }}
              >
                <img
                  src={
                    require("../../../../../../assets/images/icons/save.svg")
                      .default
                  }
                  alt="submit icon"
                />
                Submit
              </ActionButton>
            </div>
          </FormClass>
        )}
      </Modal.Body>
    </>
  );
};

export default ReverseModal;
