import React, { useState, useEffect, useRef } from "react";
import {
  ContentWrapper,
  ActionButtonsGroup,
  ActionButton,
  EditButton,
} from "./styles";
import { InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import ModalContainer from "../../../../components/ModalContainer";
import { useDispatch, useSelector } from "react-redux";
import CheckPermission from "../../../../../../util/CheckPermission";
import {
  getAllTaxComponents,
  filterTaxComponents,
  clearFilteredTaxComponents,
  getTaxComponentsTemplate,
} from "../../../../../../actions/configurationActions";
import Toast from "../../../../../../util/Toast";
import TableLoader from "./components/TableLoader";
import CreateTaxComponent from "./components/CreateTaxComponent";
import EditTaxComponent from "./components/EditTaxComponent";
const ManageTaxComponentView = () => {
  const [modalState, toggleModal] = useState(false);

  const dispatch = useDispatch();

  const [modalContentName, setModalContentName] = useState("");
  const [selectedTaxComp, setSelectedTaxComp] = useState({});
  const currentContent = (e) => {
    const val = e.currentTarget.id.trim();

    if (val) {
      toggleModal(true);
      setModalContentName(val);
    }
  };

  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  const handleModalClose = () => toggleModal(false);

  const taxCompList = useSelector((state) => state.taxComponentsReducer);
  const taxCompTemplate = useSelector((state) => state.taxComponentsTemplate);

  const { loading, taxComponents, filtered } = taxCompList;
  const { taxComponentsTemplate } = taxCompTemplate;
  taxComponents.sort((a, b) => (a.name > b.name ? 1 : -1));

  const search = useRef("");
  const onFilterTaxComponent = (e) => {
    search.current.value !== ""
      ? dispatch(filterTaxComponents(e.target.value))
      : dispatch(clearFilteredTaxComponents());
  };
  useEffect(() => {
    dispatch(getTaxComponentsTemplate());
    dispatch(clearFilteredTaxComponents());
    dispatch(getAllTaxComponents());
    if (filtered === null) {
      search.current.value = "";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditTaxComponent = (id) => {
    setSelectedTaxComp(taxComponents.filter((off) => off.id === id)[0]);
    toggleModal(true);
    setModalContentName("editOffice");
  };

  if (filtered !== null && filtered.length === 0) {
    dispatch(
      Toast({
        text: "No tax component found",
        icon: "error",
        timer: 2000,
      })
    );
  }

  //Table configuration

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Percentage", dataIndex: "percentage", key: "percentage" },
    { title: "Account", dataIndex: "creditAccount", key: "creditAccount" },
    {
      title: "Account Type",
      dataIndex: "creditAccountType",
      key: "creditAccountType",
    },
    { title: "", dataIndex: "edit", key: "edit" },
  ];

  const dataSource = [];
  const filteredTaxComponent = [];

  if (filtered !== null) {
    filtered.forEach((tax) => {
      const { id, name, percentage, creditAccount, creditAccountType } = tax;
      filteredTaxComponent.push({
        key: id,
        name,
        percentage: percentage != null ? percentage + "%" : percentage,
        creditAccount: creditAccount?.name,
        creditAccountType: creditAccountType?.value,
        edit: CheckPermission("UPDATE_TAXCOMPONENT", permissions) && (
          <EditButton onClick={() => handleEditTaxComponent(id)}>
            Edit
          </EditButton>
        ),
      });
    });
  }

  !loading &&
    taxComponents.forEach((tax) => {
      const { id, name, percentage, creditAccount, creditAccountType } = tax;
      dataSource.push({
        key: id,
        name,
        percentage: percentage != null ? percentage + "%" : percentage,
        creditAccount: creditAccount?.name,
        creditAccountType: creditAccountType?.value,
        edit: CheckPermission("UPDATE_TAXCOMPONENT", permissions) && (
          <EditButton onClick={() => handleEditTaxComponent(id)}>
            Edit
          </EditButton>
        ),
      });
    });

  const displayModalContent = () => {
    switch (modalContentName) {
      case "createOffice":
        return (
          <CreateTaxComponent
            onHide={handleModalClose}
            template={taxComponentsTemplate}
          />
        );
      case "editOffice":
        return (
          <EditTaxComponent
            template={taxComponentsTemplate}
            data={selectedTaxComp}
            onHide={handleModalClose}
          />
        );

      default:
        return;
    }
  };
  return (
    <>
      <ModalContainer show={modalState} dialogClassName="document_infoModal">
        {modalState && displayModalContent()}
      </ModalContainer>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Manage Tax Components</h3>
            <p className="font-weight sub-title">
              <span className="text-info">
                {/* <Link to="/core/configuration">Configuration</Link> /{" "} */}
                <Link to="/core/manage-tax-configuration">
                  Manage Tax Configuration
                </Link>
              </span>{" "}
              / Manage Tax Components
            </p>
          </div>
          <div className="col-sm-12 col-md-6 px-0 pt-4">
            <ActionButtonsGroup>
              <div className="col-sm-12 col-md-6 pr-2">
                <InputGroup style={{ maxWidth: "450px" }}>
                  <InputLeftElement
                    width="3.1rem"
                    height="38px"
                    pointerEvents="none"
                  >
                    <img
                      src={
                        require("../../../../../../assets/images/icons/search.svg")
                          .default
                      }
                      style={{ height: "30px" }}
                      alt="Icon"
                      className="icon-img"
                    />
                  </InputLeftElement>
                  <Input
                    style={{
                      background: "#ffffff",
                      height: "35px",
                    }}
                    ref={search}
                    onChange={onFilterTaxComponent}
                    type="text"
                    className="general-font-size"
                    placeholder="Search by name"
                  />
                </InputGroup>
              </div>
              <div className="col-sm-12 col-md-6 p-0 d-flex">
                {CheckPermission("CREATE_TAXCOMPONENT", permissions) && (
                  <ActionButton
                    style={{ width: 225 }}
                    id="createOffice"
                    onClick={currentContent}
                  >
                    <img
                      src={
                        require("../../../../../../assets/images/icons/add.svg")
                          .default
                      }
                      alt="add"
                    />
                    Create
                  </ActionButton>
                )}
              </div>
            </ActionButtonsGroup>
          </div>
        </div>
        <ContentWrapper>
          {loading ? (
            <TableLoader />
          ) : (
            CheckPermission("READ_TAXCOMPONENT", permissions) && (
              <>
                {filtered !== null ? (
                  <Table
                    columns={columns}
                    dataSource={filteredTaxComponent}
                    // onRow={(r) => ({
                    //   onClick: () => (history.push(`/core/client/${r.key}`))
                    // })}
                  />
                ) : (
                  <Table
                    columns={columns}
                    dataSource={dataSource}
                    pagination={{ defaultPageSize: 13 }}
                    defaultPageSize={13}
                  />
                )}
              </>
            )
          )}
        </ContentWrapper>
      </div>
    </>
  );
};

export default ManageTaxComponentView;
