import React from "react";
// import DashboardLayout from "../../../layouts/dashboardLayout/index.jsx";
import { ContentWrapper, ContentCard } from "./styles";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CheckPermission from "../../../../util/CheckPermission";

const CardListings = [
  {
    icon: require("../../../../assets/images/icons/loan-product.svg").default,
    title: "Loan products",
    description: "Add new loan product or modify or Inactivate loan product",
    route: "loan-products",
    permission: "READ_LOANPRODUCT",
  },
  {
    icon: require("../../../../assets/images/icons/fixed-product.svg").default,
    title: "Fixed deposit product",
    description: "Add, modify or inactivate a fixed deposit product",
    route: "fixed-deposit-products",
    permission: "READ_FIXEDDEPOSITPRODUCT",
  },
  {
    icon: require("../../../../assets/images/icons/system.svg").default,
    title: "Savings product",
    description:
      "Add a new savings product or modify or inactivate a savings product",
    route: "savings-products",
    permission: "READ_SAVINGSPRODUCT",
  },
];

const ProductsView = () => {
  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;
  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Products</h3>
            <p className="sub-title">Loan, savings, deposit information</p>
            {/* <p className="font-weight sub-title mt-2">
              <span className="text-info">
                <Link to="/core/configuration">Configuration</Link>
              </span>{" "}
              / Products
            </p> */}
          </div>
        </div>
        <ContentWrapper>
          <div className="row">
            {CardListings.map(
              ({ icon, title, description, route, permission }, i) =>
                CheckPermission(permission, permissions) && (
                  <div className="col-sm-12 col-md-4 col-lg-3 mb-4" key={i}>
                    <Link to={`products/${route}`}>
                      <ContentCard>
                        <img
                          className="teller-card-icon"
                          src={icon}
                          alt={title}
                        />
                        <div className="p-2">
                          <p className="teller-head teller-ellipses">{title}</p>
                          <p className="teller-sub-menu">{description}</p>
                        </div>
                      </ContentCard>
                    </Link>
                  </div>
                )
            )}
          </div>
        </ContentWrapper>
      </div>
    </>
  );
};

export default ProductsView;
