import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select, Input, FormControl } from "@chakra-ui/react";
// import { Table } from "antd";
import { Table } from "react-bootstrap";

import DatePicker from "react-datepicker";
import CurrencyFormat from "react-currency-format";
import {
  convertToDateObject,
  formatDate,
  formatDateArr,
  formatDateReactDatePicker,
} from "../../../../../../util/FormatDate";
import Toast from "../../../../../../util/Toast";

import {
  FormClass,
  FormElements,
  ActionButton,
  IconButton,
} from "../../styles.jsx";
import { useState } from "react";

const SavingCharge = ({
  handleNextScreen,
  handlePreviousScreen,
  chargesArray,
  charge,
  handleChargeId,
  setChargesError,
  // handleAddCharge,
  myChargesArray,
  handleSelectedChargeDate,
  handleDeleteChargeData,
  handleAmountChange,
  handleRepaymentsChange,
  setMyChargesArray,
  template,
  setChargesArray,
}) => {
  const dispatch = useDispatch();

  const fetchedProductData = useSelector((state) => state.selectedOptionData);
  const { productOptionsData } = fetchedProductData;
  const { chargeOptions, charges } = productOptionsData;

  const [selectCharges, setSelectCharges] = useState("");

  let chargesData = [];

  const currentYear = new Date().getFullYear();

  const handleChargeAdd = () => {
    if (selectCharges) {
      let newCharge = template.chargeOptions.filter((item) => {
        return item.id === Number(selectCharges);
      })[0];

      let checkedCharge = myChargesArray.filter(
        (item) => item?.chargeId === newCharge?.id
      );

      let {
        id: chargeId,
        name,
        amount,
        chargeTimeType: { value: chargeTimeTypeName },
        chargeCalculationType: { value: type },
      } = newCharge;

      if (checkedCharge.length === 0) {
        setMyChargesArray((p) => {
          return [
            ...p,
            {
              chargeId,
              name,
              amount,
              chargeTimeTypeName,
              type,
              date: "",
            },
          ];
        });
      } else {
        dispatch(
          Toast({
            text: "Already added!",
            icon: "error",
            timer: 2000,
          })
        );
      }
    } else {
      dispatch(
        Toast({
          text: "Please select a charge",
          icon: "error",
          timer: 2000,
        })
      );
    }
  };

  const handleDateChange = (index, date) => {
    let hhh = [...myChargesArray];
    hhh[Number(index)].date = date;
    setMyChargesArray(hhh);
  };

  const handleChargeDelete = (chargeId) => {
    setMyChargesArray(
      myChargesArray.filter((item) => item.chargeId !== chargeId)
    );
  };

  // const handleAddCharge = () => {
  //   productOptionsData &&
  //     myChargesArray &&
  //     myChargesArray.map((charge, index) => {
  //       chargesData.push({
  //         key: index,
  //         name: charge.name,
  //         type: charge.type,
  //         amount: (
  //           <>
  //             <CurrencyFormat
  //               value={charge.amount}
  //               name="min_pricipal"
  //               thousandSeparator={true}
  //               className="amount-input"
  //               style={{
  //                 width: "60%",
  //               }}
  //               onValueChange={(values) => {
  //                 const { value } = values;
  //                 handleAmountChange(index, value);
  //               }}
  //             />

  //             {/* {<Input style={{ width: "80%" }} value={charge.amount} size="md" />} */}
  //           </>
  //         ),
  //         collectedOn: charge.chargeTimeTypeName,
  //         date:
  //           charge.chargeTimeTypeName === "Specified due date" ||
  //           charge.chargeTimeTypeName === "Weekly Fee" ||
  //           charge.chargeTimeTypeName === "Annual Fee" ||
  //           charge.chargeTimeTypeName === "Monthly Fee" ? (
  //             <>
  //               {
  //                 <FormControl style={{ width: "80%" }}>
  //                   <DatePicker
  //                     id="published-date"
  //                     selected={formatDateArr(charge.date)}
  //                     onChange={(date) => {
  //                       handleSelectedChargeDate(index, date);
  //                     }}
  //                     peekNextMonth
  //                     showMonthDropdown
  //                     showYearDropdown
  //                     dropdownMode="select"
  //                     placeholderText="dd/mmm/yyyy"
  //                   />
  //                 </FormControl>
  //               }
  //             </>
  //           ) : null,
  //         repayments:
  //           charge.chargeTimeTypeName === "Weekly Fee" ||
  //           charge.chargeTimeTypeName === "Monthly Fee" ? (
  //             <>
  //               <CurrencyFormat
  //                 value={charge.repayments}
  //                 name="repayments"
  //                 thousandSeparator={true}
  //                 className="amount-input"
  //                 style={{
  //                   width: "60%",
  //                 }}
  //                 onValueChange={(values) => {
  //                   const { value } = values;
  //                   handleRepaymentsChange(index, value);
  //                 }}
  //               />
  //             </>
  //           ) : // charge.repayments,
  //           null,

  //         action: (
  //           <>
  //             {
  //               <IconButton
  //                 id="deposit"
  //                 color="#2C1DFF"
  //                 onClick={() => handleDeleteChargeData(index)}
  //               >
  //                 <img
  //                   src={
  //                     require("../../../../../../assets/images/icons/cancel.svg")
  //                       .default
  //                   }
  //                   alt="add"
  //                   className="icon-img"
  //                 />
  //               </IconButton>
  //             }
  //           </>
  //         ),
  //         charge,
  //       });
  //     });
  // };
  // console.log(charge, "chacharacha");

  // console.log(chargesData, "dataasdchhc");

  // const columns = [
  //   { title: "Name", dataIndex: "name", key: "name" },
  //   { title: "Type", dataIndex: "type", key: "type" },
  //   { title: "Amount", dataIndex: "amount", key: "amount" },
  //   { title: "Collected On", dataIndex: "collectedOn", key: "collectedOn" },
  //   { title: "Date", dataIndex: "date", key: "date" },
  //   { title: "Repayments Every", dataIndex: "repayments", key: "repayments" },
  //   { title: "Action", dataIndex: "action", key: "action" },
  // ];

  return (
    <>
      <FormClass>
        <FormElements style={{ width: "60%", margin: "0 auto" }}>
          <div className="form-group d-flex justify-content-between align-items-center">
            {/* Select Charge */}
            <Select
              // placeholder="Select Charge" n
              size="md"
              value={selectCharges}
              // value={charge}
              // onChange={(e) => handleChargeId(e)}
              onChange={(e) => {
                setSelectCharges(e.target.value);
              }}
            >
              <option value="">Select charge</option>
              {template !== undefined && template
                ? template.chargeOptions &&
                  template.chargeOptions.map((cur) => (
                    <option value={cur.id} key={cur.id}>
                      {cur.name}
                    </option>
                  ))
                : null}
            </Select>
            <ActionButton
              // onClick={(e) => {
              //   if (!!charge) {
              //     handleAddCharge(e);
              //   }
              // }}
              onClick={handleChargeAdd}
              color="#2C1DFF"
              style={{ margin: "0px 0px 0 10px" }}
            >
              <img
                src={
                  require("../../../../../../assets/images/icons/add.svg")
                    .default
                }
                alt="forward icon"
              />
              Add Charge
            </ActionButton>

            {/* <button onClick={handleAddCharge}>Add charge</button> */}
          </div>
        </FormElements>

        <Table className="child-table" style={{ marginBottom: "15rem" }}>
          <thead>
            <tr style={{ backgroundColor: "#FAFAFA" }}>
              <th>Name</th>
              <th>Type</th>
              <th>Amount</th>
              <th>Collected on</th>
              <th>Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {myChargesArray && myChargesArray?.length > 0
              ? myChargesArray.map(
                  (
                    {
                      chargeId,
                      name,
                      type,
                      amount,
                      chargeTimeTypeName,
                      date,
                      chargeTimeType,
                    },
                    index
                  ) => (
                    <tr key={chargeId}>
                      <td>{name}</td>
                      <td>{type}</td>
                      <td>
                        <CurrencyFormat
                          value={amount}
                          name="min_pricipal"
                          thousandSeparator={true}
                          className="amount-input"
                          style={{
                            width: "60%",
                          }}
                          onValueChange={(values) => {
                            const { value } = values;
                            handleAmountChange(index, value);
                          }}
                        />
                      </td>
                      <td>{chargeTimeTypeName}</td>
                      <td>
                        <>
                          {/* {date } */}
                          <DatePicker
                            id="start-date"
                            selected={
                              date ? convertToDateObject(date) : ""
                            }
                            onChange={(date) => handleDateChange(index, date)}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            placeholderText="dd/mmm/yyyy"
                          />
                        </>
                      </td>
                      <td>
                        <IconButton
                          onClick={() => handleChargeDelete(chargeId)}
                          id="deleteHoliday"
                          className="mr-1 mb-1"
                        >
                          <img
                            src={
                              require("../../../../../../assets/images/icons/delete.svg")
                                .default
                            }
                            alt="add"
                            className="icon-img"
                          />
                        </IconButton>
                      </td>
                    </tr>
                  )
                )
              : null}
          </tbody>
        </Table>
        {/* {chargesArray && chargesArray.length !== 0 ? (
            <Table
              className="child-table"
              columns={columns}
              dataSource={chargesData}
              onRow={(r, rowIndex) => {
                return {
                  onClick: (event) => console.log(""),
                };
              }}
            />
          ) : (
            <></>
          )} */}

        <div className="float-right">
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={handlePreviousScreen}
          >
            <img
              src={
                require("../../../../../../assets/images/icons/arrow-left.svg")
                  .default
              }
              alt="forward icon"
            />
            Previous
          </ActionButton>
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={() => {
              setChargesError(false);
              myChargesArray.length > 0 && setChargesArray(chargesArray);
              setMyChargesArray(myChargesArray);
              handleNextScreen();
            }}
          >
            <img
              src={
                require("../../../../../../assets/images/icons/arrow-right.svg")
                  .default
              }
              alt="forward icon"
            />
            Next
          </ActionButton>
        </div>
      </FormClass>
    </>
  );
};

export default SavingCharge;
