import React, { useState, useEffect, useRef } from "react";
import {
  ContentWrapper,
  ActionButtonsGroup,
  ActionButton,
  ViewButton,
  EditButton,
} from "./styles";
import { InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import { Table } from "antd";
import ModalContainer from "../../components/ModalContainer";
import NewEmployee from "./components/NewEmployee";
import EditEmployee from "./components/ViewEmployee";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllEmployees,
  clearFilteredEmplyees,
  filterEmployees,
  getAllOffices,
} from "../../../../actions/configurationActions";
import Toast from "../../../../util/Toast";
import TableLoader from "./components/TableLoader";
import CheckPermission from "../../../../util/CheckPermission";
import { getAllCountryCodes, getCodeValues } from "../../../../actions/utilityActions";
import EditEmployeeDirectly from "./components/EditEmployee";
import ViewEmployee from "./components/ViewEmployee";

const ManageEmployeesView = () => {
  const dispatch = useDispatch();
  const [modalState, toggleModal] = useState(false);
  const [modalContentName, setModalContentName] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [allCountryCodes, setAllCountryCodes] = useState([]);
  const currentContent = (e) => {
    const val = e.currentTarget.id.trim();

    if (val) {
      toggleModal(true);
      setModalContentName(val);
    }
  };

  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  const handleModalClose = () => toggleModal(false);

  const employeeList = useSelector((state) => state.employeeReducer);

  const { loading, employees, filtered } = employeeList;
  employees.sort((a, b) => (a.firstname > b.firstname ? 1 : -1));
  const officeList = useSelector((state) => state.officeReducer);

  const { loading: officeLoading, offices } = officeList;
  offices.sort((a, b) => (a.name > b.name ? 1 : -1));
  const search = useRef("");

  const onFilterEmployee = (e) => {
    search.current.value !== ""
      ? dispatch(filterEmployees(e.target.value))
      : dispatch(clearFilteredEmplyees());
  };
  const displayModalContent = () => {
    switch (modalContentName) {
      case "newEmployee":
        return <NewEmployee countryCodes={allCountryCodes} offices={offices} onHide={handleModalClose} />;
      case "viewEmployee":
        return (
          <ViewEmployee
            countryCodes={allCountryCodes}
            offices={offices}
            employeeData={
              selectedEmployee && employees.length > 0
                ? employees.filter((item) => item.id === selectedEmployee)[0]
                : {}
            }
            onHide={handleModalClose}
          />
        );
      case "editEmployee":
        return (
          <EditEmployeeDirectly
            countryCodes={allCountryCodes}
            offices={offices}
            employeeData={
              selectedEmployee && employees.length > 0
                ? employees.filter((item) => item.id === selectedEmployee)[0]
                : {}
            }
            onHide={handleModalClose}
          />
        );
      default:
        return;
    }
  };

  useEffect(() => {
    dispatch(clearFilteredEmplyees());
    dispatch(getAllEmployees());
    dispatch(getAllOffices());
    dispatch(getCodeValues());
    if (filtered === null) {
      search.current.value = "";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allCodeValues = useSelector((state) => state.codeValues);
  let { codeValues } = allCodeValues;

  useEffect(() => {
    if (!!codeValues && codeValues.length > 0) {
      const countryCode = codeValues.find((e => e.name === "CountryCode")).id;
      dispatch(
        getAllCountryCodes(
          `codes/${countryCode}/codevalues`
        )
      );
    }
  }, [allCodeValues])

  const countryCodesDetails = useSelector(
    (state) => state.countryCodesDetails.countryCodesDetails
  );

  useEffect(() => {
    if (!!countryCodesDetails && countryCodesDetails.length > 0) {
      setAllCountryCodes(countryCodesDetails);
    }
  }, [countryCodesDetails])

  if (filtered !== null && filtered.length === 0) {
    Toast({
      text: "No Employee found",
      icon: "error",
      timer: 2000,
    });
  }
  //Table configuration
  const dataSource = [];
  const filteredEmployees = [];

  if (filtered !== null) {
    filtered.forEach((employee) => {
      const { id, firstname, lastname, isLoanOfficer, officeName, isActive } =
      employee;
      filteredEmployees.push({
        key: id,
        firstname,
        lastname,
        isLoanOfficer: (
          <p className="text-capitalize">{String(isLoanOfficer)}</p>
        ),
        officeName,
        status: (
          <>
            {isActive ? (
              <span className="new-text-success">Active</span>
            ) : (
              <span className="new-text-danger">Inactive</span>
            )}
          </>
        ),
        view: (
          <>
            {CheckPermission("UPDATE_STAFF", permissions) ? (
              <ViewButton
                id="viewEmployee"
                onClick={(e) => {
                  setSelectedEmployee(id);
                  currentContent({ currentTarget: { id: "viewEmployee" } });
                }}
              >
                <img
                  src={require("../../../../assets/images/icons/eye.svg").default}
                  alt="edit icon"
                />
                View
              </ViewButton>
            ) : null}
          </>
        ),
        edit: (
          <>
            {CheckPermission("UPDATE_STAFF", permissions) ? (
              <EditButton
                id="editEmployee"
                onClick={(e) => {
                  setSelectedEmployee(id);
                  currentContent({ currentTarget: { id: "editEmployee" } });
                }}
              >
                <img
                  src={require("../../../../assets/images/icons/edit.svg").default}
                  alt="edit icon"
                />
                Edit
              </EditButton>
            ) : null}
          </>
        ),
      });
    });
  }
  !loading &&
    employees.forEach((employee) => {
      const { id, firstname, lastname, isLoanOfficer, officeName, isActive } =
        employee;
      dataSource.push({
        key: id,
        firstname,
        lastname,
        isLoanOfficer: (
          <p className="text-capitalize">{String(isLoanOfficer)}</p>
        ),
        officeName,
        status: (
          <>
            {isActive ? (
              <span className="new-text-success">Active</span>
            ) : (
              <span className="new-text-danger">Inactive</span>
            )}
          </>
        ),
        view: (
          <>
            {CheckPermission("UPDATE_STAFF", permissions) ? (
              <ViewButton
                id="viewEmployee"
                
                onClick={(e) => {
                  setSelectedEmployee(id);
                  currentContent({ currentTarget: { id: "viewEmployee" } });
                }}
              >
                <img
                  src={require("../../../../assets/images/icons/eye.svg").default}
                  alt="edit"
                />
                View
              </ViewButton>
            ) : null}
          </>
        ),
        edit: (
          <>
            {CheckPermission("UPDATE_STAFF", permissions) ? (
              <EditButton
                id="editEmployee"
                
                onClick={(e) => {
                  setSelectedEmployee(id);
                  currentContent({ currentTarget: { id: "editEmployee" } });
                }}
              >
                <img
                  src={require("../../../../assets/images/icons/edit.svg").default}
                  alt="edit"
                />
                Edit
              </EditButton>
            ) : null}
          </>
        ),
      });
    });

  const columns = [
    { title: "First Name", dataIndex: "firstname", key: "firstname" },
    { title: "Last Name", dataIndex: "lastname", key: "lastname" },
    { title: "Loan Officer", dataIndex: "isLoanOfficer", key: "isLoanOfficer" },
    { title: "Office", dataIndex: "officeName", key: "officeName" },
    { title: "Status", dataIndex: "status", key: "status" },
    { title: "", dataIndex: "view", key: "view" },
    { title: "", dataIndex: "edit", key: "edit" },
  ];

  return (
    <>
      <ModalContainer show={modalState} dialogClassName="document_infoModal">
        {modalState && displayModalContent()}
      </ModalContainer>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Manage Employees</h3>
            <p className="sub-title">Manage employees in relation with loans</p>
          </div>
          <div className="col-sm-12 col-md-6 px-0 pt-3">
            <ActionButtonsGroup>
              <div className="col-sm-12 col-md-6 pr-2">
                <InputGroup>
                  <InputLeftElement
                    width="3.1rem"
                    height="38px"
                    pointerEvents="none"
                  >
                    <img
                      src={
                        require("../../../../assets/images/icons/search.svg")
                          .default
                      }
                      style={{ height: "30px" }}
                      alt="Icon"
                      className="icon-img"
                    />
                  </InputLeftElement>
                  <Input
                    style={{
                      background: "#ffffff",
                      height: "35px",
                    }}
                    type="text"
                    className="general-font-size"
                    placeholder="Search by name/ office"
                    ref={search}
                    onChange={onFilterEmployee}
                  />
                </InputGroup>
              </div>
              <div className="col-sm-12 col-md-6 p-0 d-flex">
                {CheckPermission("CREATE_STAFF", permissions) && (
                  <ActionButton id="newEmployee" onClick={currentContent}>
                    <img
                      src={
                        require("../../../../assets/images/icons/add.svg")
                          .default
                      }
                      alt="add"
                    />
                    New Employee
                  </ActionButton>
                )}
              </div>
              {/* </div> */}
            </ActionButtonsGroup>
          </div>
        </div>

        {CheckPermission("READ_STAFF", permissions) && (
          <ContentWrapper>
            {loading || officeLoading ? (
              <TableLoader />
            ) : (
              <>
                {filtered !== null ? (
                  <Table
                    columns={columns}
                    dataSource={filteredEmployees}
                    onRow={(row) => ({
                      onClick: () => setSelectedEmployee(row?.key)
                    })}
                  />
                ) : (
                  <Table
                    columns={columns}
                    dataSource={dataSource}
                    pagination={{ defaultPageSize: 13 }}
                    defaultPageSize={13}
                    onRow={(row) => ({
                      onClick: () => setSelectedEmployee(row?.key)
                    })}
                  />
                )}
              </>
            )}
          </ContentWrapper>
        )}
      </div>
    </>
  );
};

export default ManageEmployeesView;
