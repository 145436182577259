import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Input, Select, FormControl, Checkbox } from "@chakra-ui/react";
import { NewActionButton, FormElements, FormClass } from "../styles";
import { Modal, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
  updateEmployeeInfo,
  getAllEmployees,
  updateEmployeeImage,
  fetchEmployeeImage,
} from "../../../../../actions/configurationActions";
import { useDispatch } from "react-redux";
import { formatDate } from "../../../../../util/FormatDate";
import Toast from "../../../../../util/Toast";
import { Select as AntSelect } from "antd";

const ViewEmployee = ({ countryCodes, onHide, employeeData, offices }) => {
  const dispatch = useDispatch();
  const employeeImage = useSelector(state => state.fetchEmployeeImageReducer)
  const [editEmployeeInfo, setEditEmployeeInfo] = useState({
    office: employeeData.officeId || "",
    firstName: employeeData.firstname || "",
    lastName: employeeData.lastname || "",
    emailAddress: employeeData.emailAddress || "",
    isLoanOfficer: employeeData.isLoanOfficer || false,
    isActive: String(employeeData.isActive) || "",
    mobileNumber: employeeData.mobileNo || "",
    contactCountryCode: employeeData.countryCode.id || ""
  });
  const [joinedOn, setJoinedOn] = useState(
    new Date(employeeData.joiningDate) || new Date()
  );
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);

  const { office, firstName, lastName, emailAddress, isActive, mobileNumber, isLoanOfficer, contactCountryCode } =
    editEmployeeInfo;

  const toggleEditable = () => setEditable(state => !state)
  const handleImageFileSelect = (event) => {
    const image = event.target.files[0]
    if (image) {
      const imageUrl = URL.createObjectURL(image);
      setPreviewImage(imageUrl);
      const callbackFn = () => {
        dispatch(getAllEmployees());
      };
      dispatch(updateEmployeeImage(image, employeeData.id, callbackFn));
    }
  }
  const editEmployeeHandler = (e) =>
    setEditEmployeeInfo({
      ...editEmployeeInfo,
      [e.target.name]: e.target.value,
    });
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (office && firstName && lastName && emailAddress && joinedOn) {
      setLoading(true);
      const data = {
        officeId: office,
        firstname: firstName,
        lastname: lastName,
        emailAddress: emailAddress,
        isActive: isActive === "true" ? true : false,
        mobileNo: mobileNumber,
        countryCodeId: contactCountryCode,
        joiningDate: formatDate(joinedOn, true),
        locale: "en",
        dateFormat: "dd MMMM yyyy",
        isLoanOfficer: isLoanOfficer,
      };
      const func = () => {
        dispatch(getAllEmployees());
        onHide();
      };
      await dispatch(updateEmployeeInfo(data, employeeData.id, func));
      setLoading(false);
    } else {
      dispatch(Toast({
        text: "Please fill the required fields",
        icon: "error",
        timer: 2000,
      }));
    }
  };

  // Change the keys of id and name to label and value because that is what ANT SELECT understands.
  const newCountryCodes = countryCodes && countryCodes.map(({ name: label, id: value, ...rest }) => ({ label, value, ...rest }));
  const handleSelected = (selected) => {
    const selectedCountryCode = countryCodes.find((e) => e.id === selected).id;
    const obj = { target: { name: 'contactCountryCode', value: selectedCountryCode } };
    editEmployeeHandler(obj);
  }

  useEffect(() => {
    dispatch(fetchEmployeeImage(employeeData.id))
  }, [dispatch, employeeData.id])

  useEffect(() => {
    setPreviewImage(null)
    employeeImage?.photo && setPreviewImage(employeeImage?.photo)
  }, [employeeImage, employeeData.firstname])

  return (
    <>
      <Modal.Header>
        <Modal.Title>{editable ? "Edit" : "View"} Employee</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-0">
        <div className="d-flex align-items-center justify-content-center flex-column">
          <div className="position-relative">
            {employeeImage?.loading ? (
              <div
                className="rounded-circle border d-flex align-items-center justify-content-center"
                style={{
                  width: "5.375rem",
                  height: "5.375rem",
                  backgroundColor: "#2C1DFF"
                }}
              >
                <Spinner
                  animation="border"
                  color="#fff"
                  className="text-white"
                  size="sm"
                />
              </div>
            ) : !!employeeImage?.photo ? (
              <img
                src={employeeImage?.photo}
                alt={`${firstName}'s profile avatar`}
                className="rounded-circle border"
                style={{
                  width: "5.375rem",
                  height: "5.375rem",
                  objectFit: "cover"
                }}
              />
            ) : (
              <img
                src={previewImage ?? require("../../../../../assets/images/defaultUser.png").default}
                alt={`${firstName}'s profile avatar`}
                className="rounded-circle border"
                style={{
                  width: "5.375rem",
                  height: "5.375rem",
                  objectFit: "cover"
                }}
              />
            )}
            {editable && (
              <label
                className="d-flex justify-content-center align-items-center border border-white rounded-circle position-absolute pointer"
                style={{
                  background: "#2c1dff",
                  width: "2rem",
                  height: "2rem",
                  bottom: "-4px",
                  right: "-4px"
                }}
              >
                <img
                  src={require("../../../../../assets/images/icons/edit-file.svg").default}
                  alt="edit file icon"
                />
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageFileSelect}
                  className="d-none"
                />
              </label>
            )}
          </div>
          <FormClass style={{ width: "90%" }}>
            <FormElements style={{ width: "100%" }}>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Office</label>
                <Select
                  placeholder="Select Office"
                  size="md"
                  name="office"
                  value={office}
                  onChange={editEmployeeHandler}
                  disabled={loading || !editable}
                  className="select"
                >
                  {offices && offices.length > 0
                    ? offices.map(({ name, id }) => (
                      <option value={id} key={id}>
                        {name}
                      </option>
                    ))
                    : null}
                </Select>
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">First name*</label>
                <Input
                  style={{ width: "70%" }}
                  size="md"
                  name="firstName"
                  value={firstName}
                  onChange={editEmployeeHandler}
                  disabled={loading || !editable}
                />
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Last name*</label>
                <Input
                  style={{ width: "70%" }}
                  size="md"
                  name="lastName"
                  value={lastName}
                  onChange={editEmployeeHandler}
                  disabled={loading || !editable}
                />
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Email*</label>
                <Input
                  style={{ width: "70%" }}
                  size="md"
                  name="emailAddress"
                  value={emailAddress}
                  onChange={editEmployeeHandler}
                  disabled={loading || !editable}
                />
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Is loan officer?
                </label>
                <FormControl style={{ width: "70%" }}>
                  <Checkbox
                    size="md"
                    isChecked={isLoanOfficer}
                    onChange={(e) =>
                      setEditEmployeeInfo({
                        ...editEmployeeInfo,
                        isLoanOfficer: e.target.checked,
                      })
                    }
                    disabled={loading || !editable}
                  />
                </FormControl>
              </div>
              {/* </div> */}
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Status</label>
                <Select
                  placeholder="Select Office"
                  size="md"
                  name="isActive"
                  value={isActive}
                  onChange={editEmployeeHandler}
                  disabled={loading || !editable}
                >
                  <option value="true">Enabled</option>
                  <option value="false">Disabled</option>
                </Select>
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Country code for SMS</label>

                <AntSelect
                  loading={true}
                  style={{ width: "70%" }}
                  showSearch
                  dropdownMatchSelectWidth={true}
                  placeholder="Search country code"
                  showArrow={false}
                  filterOption={(input, option) => (option?.label ?? '').includes(input)}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  value={contactCountryCode}
                  onSelect={(selected) =>
                    handleSelected(selected)
                  }
                  options={newCountryCodes}
                  disabled={loading || !editable}
                >
                </AntSelect>

              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Mobile Number for SMS</label>
                <Input
                  style={{ width: "70%" }}
                  size="md"
                  name="mobileNumber"
                  value={mobileNumber}
                  onChange={editEmployeeHandler}
                  disabled={loading || !editable}
                />
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Joined On</label>
                <FormControl style={{ width: "70%" }}>
                  <div className="datepicker-100-width">
                    <DatePicker
                      id="start-date"
                      selected={joinedOn}
                      onChange={(date) => setJoinedOn(date)}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="dd/mmm/yyyy"
                      disabled={loading || !editable}
                    />
                  </div>
                </FormControl>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-1">
          <NewActionButton color="#2C1DFF" onClick={editable ? toggleEditable : onHide}>
            <img
              src={
                require("../../../../../assets/images/icons/cancel.svg").default
              }
              alt="cancel icon"
            />
            {editable ? "Cancel" : "Close"}
          </NewActionButton>
          <NewActionButton
            onClick={editable ? handleSubmit : toggleEditable}
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            disabled={loading}
          >
            {!editable ? (
              <img
                src={require("../../../../../assets/images/icons/edit.svg").default}
                alt="edit icon"
              />
            ) : loading ? (
              <Spinner
                animation="border"
                color="#fff"
                className="mr-2 text-white"
                size="sm"
              />
            ) : (
              <img
                src={
                  require("../../../../../assets/images/icons/save.svg").default
                }
                alt="submit icon"
              />
            )}
            {editable ? "Submit" : "Edit"}
          </NewActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default ViewEmployee;
