// import { Input, Select } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { NewActionButton } from "../styles";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllGlAccounts,
  getOffices,
} from "../../../../actions/accountingActions";
import { Select } from "antd";
import Toast from "../../../../util/Toast";
import { formatDate } from "../../../../util/FormatDate";
import { sendUserSettlement } from "../../../../actions/settlementActions";

const ProcessEOYModal = ({
  onCancel,
  offices,
  glAccounts,
  office,
  setOffice,
  transactionDate,
  setTrasactionDate,
  accountGl,
  setAccountGl,
  getGlBalances,
  selectedCurrencyOptions,
  currencySelected,
  setCurrencySelected,
  // switchView
}) => {
  const dispatch = useDispatch();

  return (
    <>
      <Modal.Header>
        <Modal.Title>Select EOY date</Modal.Title>
        {/* <div>
          {/* <div onClick={clearFilter}> 
          <Modal.Title
            style={{
              color: "red",
              fontSize: "15px",
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            Clear all filters
          </Modal.Title>
        </div> */}
      </Modal.Header>
      <Modal.Body className="wc-modal">
        <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap flex-column">
          {/* Select date */}
          <div className="col-sm-12 col-md-12 px-0 mb-3 p-1 d-flex align-items-center justify-content-between formStyle">
            {/* fromDate */}
            <div
              className="label"
              style={{ fontSize: "14px", color: "#969CBA" }}
            >
              End Date:{" "}
            </div>
            <div className="datepicker-100-width" style={{ width: "60%" }}>
              <DatePicker
                id="from-date"
                selected={transactionDate}
                onChange={(date) => setTrasactionDate(date)}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="End date"
              />
            </div>
          </div>
          {/* Select Offices */}
          <div className="col-sm-12 col-md-12 px-0 mb-3 p-1 d-flex align-items-center justify-content-between formStyle">
            <div
              className="label"
              style={{ fontSize: "14px", color: "#969CBA" }}
            >
              Select Office * :{" "}
            </div>
            <div className="select-100-width" style={{ width: "60%" }}>
              <Select
                placeholder="Select Office"
                size="md"
                className="text-capitalize"
                name="accName"
                value={office || undefined}
                onChange={(value) => setOffice(value)}
                showSearch // Enable search functionality
                optionFilterProp="children" // Specify which property of the option is used for filtering
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {!!offices
                  ? offices.map(({ externalId, id, name, nameDecorated }) => {
                      return (
                        <Select.Option value={id} key={id}>
                          {name}
                        </Select.Option>
                      );
                    })
                  : null}
              </Select>
            </div>
          </div>

          <div className="col-sm-12 col-md-12 px-0 mb-3 p-1 d-flex align-items-center justify-content-between formStyle">
            <div
              className="label"
              style={{ fontSize: "14px", color: "#969CBA" }}
            >
              Select Currency * :{" "}
            </div>
            <div className="select-100-width" style={{ width: "60%" }}>
              <Select
                placeholder="Select Currency"
                size="md"
                className="text-capitalize"
                name="accName"
                value={currencySelected || undefined}
                onChange={(value) => setCurrencySelected(value)}
                showSearch // Enable search functionality
                optionFilterProp="children" // Specify which property of the option is used for filtering
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {!!selectedCurrencyOptions
                  ? selectedCurrencyOptions.map(
                      ({ externalId, code, name, nameDecorated }) => {
                        return (
                          <Select.Option value={code} key={code}>
                            {name}
                          </Select.Option>
                        );
                      }
                    )
                  : null}
              </Select>
            </div>
          </div>

          {/* Select gl acccounts */}
          <div className="col-sm-12 col-md-12 px-0 mb-3 p-1 d-flex align-items-center justify-content-between formStyle">
            <div
              className="label"
              style={{ fontSize: "14px", color: "#969CBA" }}
            >
              Select GL * :{" "}
            </div>
            <div className="select-100-width" style={{ width: "60%" }}>
              <Select
                placeholder="Select Gl"
                size="md"
                className="text-capitalize"
                name="accName"
                value={accountGl || undefined}
                onChange={(value) => setAccountGl(value)}
                showSearch // Enable search functionality
                optionFilterProp="children" // Specify which property of the option is used for filtering
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {!!glAccounts
                  ? glAccounts.map(({ id, name }) => {
                      return (
                        <Select.Option value={id} key={id}>
                          {name}
                        </Select.Option>
                      );
                    })
                  : null}
              </Select>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexGrow: "0.2",
          }}
        >
          <div>
            <NewActionButton
              color="#fff"
              className="float-right"
              style={{ margin: "0px 0px" }}
              onClick={onCancel}
              bgColor="#2C1DFF"
            >
              {/* <img
                src={
                  require("../../../../assets/images/icons/cancel.svg").default
                }
                className="d-inline mr-3"
                alt="icon"
              /> */}
              Cancel
            </NewActionButton>
          </div>
          <div>
            <NewActionButton
              color="#2C1DFF"
              className="float-right"
              style={{ margin: "0px 0px" }}
              bgColor="#fff"
              onClick={getGlBalances}
            >
              {/* <img
                src={
                  require("../../../../assets/images/icons/checked-white.svg")
                    .default
                }
                className="d-inline mr-3"
                alt="icon"
              /> */}
              Continue
            </NewActionButton>
          </div>
        </div>
      </Modal.Footer>
    </>
  );
};

export default ProcessEOYModal;
