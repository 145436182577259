import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AuthLayout from '../../layouts/AuthLayout/index.jsx';
import { Alert, Modal, Button } from 'react-bootstrap';
import AuthModule from '../../modules/Auth.module';
import {
  getTenantValidity,
  setLoadingState,
  setTenant,
} from '../../actions/preAuthActions';

import config from '../../config';
import Toast from '../../util/Toast';
import {
  InputGroup,
  Input,
  InputRightElement,
  PinInput,
  PinInputField,
} from '@chakra-ui/react';
import {
  FormTitile,
  FormSubTitile,
  FormButton,
  // FormInput,
  OTPSubTitile,
  OTPTitle,
  OTPDigitsFormContainer,
} from './styles';

import { loginUser } from '../../actions/authActions';
import {
  getTenantLoggedIn,
  setOtpModal,
  setOtp2FAModal,
  validateOtp,
  activateOtp,
} from '../../actions/preAuthActions';

const Login = ({ history }) => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const preAuth = useSelector((state) => state.preAuth);

  let {
    showOtpModal,
    show2FAModal,
    setValidityOfOtp,
    // loginResponse = {},
    requestID,
    // enteredUserData,
    barcode,
  } = preAuth || {};
  const { loading, error } = userLogin;

  const [showPassword, setShowPassword] = useState(false);

  const [email, setEmail] = useState('');
  const [accountId, setAccountId] = useState('');
  const [password, setPassword] = useState('');
  const [otpValue, setOtpValue] = useState('');
  const [otpActivateValue, setOtpActivateValue] = useState('');

  let userDetails = {
    username: email,
    password: password,
  };
  let authData = {
    ...userDetails,
    client_id: config.apiGateway.CLIENT_ID,
    client_secret: config.apiGateway.CLIENT_SECRET,
    grant_type: config.apiGateway.GRANT_TYPE,
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!!accountId && !!email && !!password) {
      let gottenTenant = accountId;
      AuthModule.setTenant(accountId);
      if (!!gottenTenant) {
        setTenant(gottenTenant, dispatch);
        AuthModule.setTenant(gottenTenant);
        getTenantLoggedIn(dispatch, authData);
        // getTenantValidity(dispatch, authData);
      } else {
        setLoadingState(true, dispatch);
      }
    } else {
      dispatch(
        Toast({
          text: 'Please provide a valid account Id, username and password',
          icon: 'info',
        })
      );
    }
  };

  const [show2FAmodal, set2FAModal] = useState(false);
  const [showMyOtpModal, setShowMyOtpModal] = useState(false);

  useEffect(() => {
    if (otpValue.length === 6) handleOtpSubmit();
  }, [otpValue]);

  useEffect(() => {
    if (show2FAModal === true) {
      set2FAModal(true);
    } else {
      set2FAModal(false);
    }
  }, [show2FAModal]);

  useEffect(() => {
    if (showOtpModal === true) {
      setShowMyOtpModal(true);
    } else {
      setShowMyOtpModal(false);
    }
  }, [showOtpModal]);

  useEffect(() => {
    if (setValidityOfOtp === true) {
      setShowMyOtpModal((showMyOtpModal) => !showMyOtpModal);
      dispatch(loginUser(history));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValidityOfOtp, history, dispatch]);

  const handleOtpActivateSubmit = () => {
    let datatoSend = {
      code: otpActivateValue, //token from the authenticator
      username: email,
    };

    if (!!otpActivateValue === false || otpActivateValue.length < 6) {
      dispatch(Toast({ text: 'Please Provide a valid OTP', icon: 'info' }));
    } else {
      activateOtp(dispatch, datatoSend);
    }
  };

  const handleOtpSubmit = () => {
    let dataToSend = {
      username: email,
      requestId: requestID,
      code: otpValue,
    };
    validateOtp(dispatch, dataToSend);
  };

  const setOTP2FAModalShow = () => {
    setOtp2FAModal(false, dispatch);
  };
  const setOTPModalShow = () => {
    setOtpModal(false, dispatch);
  };

  return (
    <>
      {loading && <LogoModal show={loading} onHide={() => null} />}
      {show2FAmodal ? (
        <Modal
          show={true}
          size='lg'
          backdrop='static'
          keyboard={false}
          centered
          dialogClassName='otp-modal'
          contentClassName='customOTPModal'
        >
          <Modal.Body>
            <div className='spaceBetweenFlexEnd'>
              <div
                onClick={() => setOTP2FAModalShow()}
                className='auth-pin-modal-cancel pointer'
              >
                <img
                  src={
                    require('../../assets/images/icons/cancel-black.svg')
                      .default
                  }
                  alt=''
                />
              </div>
            </div>
            {/* <OTPTitle>Please enter OTP</OTPTitle> */}
            <OTPTitle>Scan QRcode</OTPTitle>
            <img
              src={barcode}
              height='227px'
              width='227px'
              className='mx-auto'
              alt='barcode'
            />
            {/* <OTPSubTitile>
              We have sent a{" "}
              <b style={{ fontFamily: "Helvetica Neue" }}>one time password</b>{" "}
              to
              <br /> your email address
            </OTPSubTitile> */}
            <OTPSubTitile>
              Don’t know how to scan the QRcode?{' '}
              <a
                href='https://docs.woodcoreapp.com/docs/2-factor-authentication'
                target='_blank'
                rel='noreferrer noopener'
                style={{
                  fontFamily: 'AventaSemiBold',
                  fontWidth: 'bold',
                  fontSize: '14px',
                  color: '#2C1DFF',
                  textDecoration: 'none',
                }}
              >
                Check here
              </a>
            </OTPSubTitile>
            {/* <OTPCountdown style={{ fontFamily: "Helvetica Neue" }}>
          1:20
        </OTPCountdown> */}
            <OTPSubTitile color='#333333'>Enter 6 digit code here</OTPSubTitile>
            <OTPDigitsFormContainer className='mt-3'>
              <PinInput value={otpActivateValue} onChange={setOtpActivateValue}>
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
              </PinInput>
            </OTPDigitsFormContainer>
            {/* <OTPResendTitle>
              Didn’t receive OTP?{" "}
              <span
                href="#"
                style={{
                  fontFamily: "AventaSemiBold",
                  fontWidth: "bold",
                  fontSize: "14px",
                  color: "#2C1DFF",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  submitHandler(e);
                }}
              >
                Resend
              </span>
            </OTPResendTitle> */}
            <Button
              style={{ fontSize: '1.125rem' }}
              className='px-5 w-75 m-auto d-block'
              onClick={(e) => {
                e.preventDefault();
                // handleOtp2FASubmit();
                handleOtpActivateSubmit();
              }}
            >
              Activate OTP
            </Button>
            {/* <Button
              className="d-block text-muted"
              onClick={() => set2FAModal(false)}
              variant="link"
              style={{ fontSize: "1rem", margin: "2rem auto 0" }}
            >
              Cancel
            </Button> */}
          </Modal.Body>
        </Modal>
      ) : null}

      {showMyOtpModal ? (
        <Modal
          show={true}
          size='lg'
          backdrop='static'
          keyboard={false}
          centered
          dialogClassName='otp-modal'
          contentClassName='customOTPModal'
        >
          <Modal.Body>
            <div className='spaceBetweenFlexEnd'>
              <div
                onClick={() => setOTPModalShow()}
                className='auth-pin-modal-cancel pointer'
              >
                <img
                  src={
                    require('../../assets/images/icons/cancel-black.svg')
                      .default
                  }
                  alt=''
                />
              </div>
            </div>
            <OTPTitle>Please enter OTP</OTPTitle>
            <OTPSubTitile>
              Enter your{' '}
              <b style={{ fontFamily: 'Helvetica Neue' }}>one time password</b>
            </OTPSubTitile>
            <OTPDigitsFormContainer className='mt-3'>
              <PinInput
                value={otpValue}
                onChange={setOtpValue}
                autoFocus
                otp
              >
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
              </PinInput>
            </OTPDigitsFormContainer>
          </Modal.Body>
        </Modal>
      ) : null}

      <AuthLayout>
        <div style={{ width: '23.313rem' }}>
          <FormSubTitile>
            <span className='login-mainlogo'>
              {' '}
              <img
                src={require('../../assets/logo/mainlogo.svg').default}
                alt=''
              />
            </span>
            Login to your cloud banking console.
          </FormSubTitile>
          <form id='auth-form' onSubmit={submitHandler}>
            <div className='form-label-group mb-4'>
              <label className='login-label'> Account Id</label>
              <Input
                required
                pr='4.5rem'
                type='text'
                style={{
                  background: '#ffffff',
                  height: '45px !important',
                }}
                value={accountId}
                onChange={(e) => setAccountId(e.target.value)}
              />
            </div>

            <div className='form-label-group mb-4'>
              <label className='login-label'>Username</label>
              <Input
                required
                pr='4.5rem'
                style={{
                  background: '#ffffff',
                  height: '45px !important',
                }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className='form-label-group mb-4'>
              <label className='login-label'>Password</label>
              <InputGroup size='md'>
                <Input
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  pr='4.5rem'
                  type={showPassword ? 'text' : 'password'}
                  style={{
                    background: '#ffffff',
                    height: '45px !important',
                  }}
                />
                <InputRightElement width='4.5rem' height='44px'>
                  <svg
                    onClick={() => setShowPassword(!showPassword)}
                    width='17'
                    height='12'
                    viewBox='0 0 17 12'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M14.1425 2.6802C15.2613 3.51 16.2441 4.6166 17 6C14.5523 10.4797 9.72559 12.0569 5.60275 10.7316L7.32384 9.10889C7.69143 9.23251 8.08738 9.29983 8.5 9.29983C10.433 9.29983 12 7.82245 12 6C12 5.61098 11.9286 5.23768 11.7975 4.89111L14.1425 2.6802ZM11.3972 1.26845L9.67616 2.89111C9.30857 2.76749 8.91262 2.70017 8.5 2.70017C6.567 2.70017 5 4.17755 5 6C5 6.38902 5.0714 6.76233 5.20252 7.10889L2.8575 9.3198C1.73865 8.49 0.755893 7.38341 0 6C2.44773 1.52026 7.27441 -0.0569261 11.3972 1.26845ZM2.13604 11.3333L14.1569 0L14.864 0.666667L2.84315 12L2.13604 11.3333Z'
                      fill='#CAC7C7'
                    />
                  </svg>
                </InputRightElement>
              </InputGroup>
            </div>

            {/* {error ? (
                <Alert
                  variant="info"
                  className="text-center mt-4"
                  style={{
                    color: "#2C1DFF",
                    background: "rgba(1, 177, 252, 0.2)",
                    fontSize: "1rem",
                  }}
                >
                  {error}
                </Alert>
              ) : (
                <Alert
                  variant="info"
                  className="text-center mt-4"
                  style={{
                    color: "#2C1DFF",
                    background: "rgba(1, 177, 252, 0.2)",
                    fontSize: "1rem",
                    opacity: "0",
                  }}
                >
                  {error}
                </Alert>
              )} */}

            {/* 
            <OTPModal
              onRedirectToDashboard={redirectToDashboard}
              show={OTPModalShow}
              onHide={() => setOTPModalShow(false)}
            /> */}

            <div style={{ paddingTop: '0.5rem' }}>
              <FormButton
                className={`btn-block btn ${loading ? 'btn-secondary' : 'btn-primary'
                  } btn-login text-center my-2`}
                style={{ backgroundColor: loading ? '#f1f1f1' : '' }}
                type='submit'
                disabled={loading}

              >
                Login
              </FormButton>
            </div>
          </form>
        </div>
      </AuthLayout>
    </>
  );
};

function LogoModal(props) {
  return (
    <Modal
      {...props}
      backdrop='static'
      keyboard={false}
      size='lg'
      centered
      contentClassName='customLogoModal'
    >
      <Modal.Body className='d-flex align-items-center justify-content-center'>
        <img
          style={{ height: '40px', zIndex: '3' }}
          alt='woodcore logo'
          // src={require('../../assets/images/logo-mini.png').default}
          src={require('../../assets/images/wcLogoWhite.svg').default}
        />
      </Modal.Body>
    </Modal>
  );
}

export default Login;
