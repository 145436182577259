import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { Input } from "@chakra-ui/react";

import { FormClass, FormElements, ActionButton } from "../../styles.jsx";

import {
  downloadRepaymentSchedule,
  resetDownloadRepaymentScheduleState,
} from "../../../../../../actions/customerActions.js";

const DownloadRepaymentSchedule = ({ onHide, accountNo }) => {
  const [accountNumber] = useState(accountNo);

  const dispatch = useDispatch();
  const repaymentScheduleState = useSelector(
    (state) => state.repaymentSchedule
  );

  const openPdfNewTab = (file) => {
    setTimeout(() => {
      window.open(file);
    }, 3000);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let data = {
      accountNumber,
    };
    const funcTorun = () => onHide();
    dispatch(downloadRepaymentSchedule(data, funcTorun));
  };

  useEffect(() => {
    const file = repaymentScheduleState.repaymentSchedule.file;
    if (
      repaymentScheduleState.repaymentSchedule?.status === "Done" &&
      !!repaymentScheduleState.repaymentSchedule.file
    ) {
      return [
        openPdfNewTab(file),
        dispatch(resetDownloadRepaymentScheduleState()),
      ];
    }
  }, [dispatch, repaymentScheduleState]);

  return (
    <>
      <Modal.Header>
        <Modal.Title>Repayment Schedule</Modal.Title>
      </Modal.Header>
      <Modal.Body className="wc-modal">
        <div className="d-flex align-items-center justify-content-center flex-direction-coloumn">
          <FormClass style={{ width: "90%" }}>
            <FormElements style={{ width: "100%" }}>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Account Number</label>
                <Input
                  style={{ width: "60%" }}
                  name="note"
                  placeholder="Enter account number"
                  value={accountNumber}
                  disabled
                  size="md"
                />
              </div>
            </FormElements>
          </FormClass>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-4">
          <ActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require("../../../../../../assets/images/icons/cancel.svg")
                  .default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton
            onClick={(e) => submitHandler(e)}
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
          >
            <img
              src={
                require("../../../../../../assets/images/icons/save.svg")
                  .default
              }
              alt="submit icon"
            />
            Submit
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default DownloadRepaymentSchedule;
