import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Textarea, FormControl } from "@chakra-ui/react";
import { FormClass, ActionButton } from "../../CustomerInformation/styles";
import { withdrawLoan } from "../../../../../actions/SavingsAndLoanActions";
import { formatDate } from "../../../../../util/FormatDate";

const WithdrawnByClientModal = ({ onHide, id, history }) => {
  const dispatch = useDispatch();

  const [submittedOnDate, setSubmittedOnDate] = useState();
  const [note, setNote] = useState("");
  let newDate = new Date(submittedOnDate);

  const handleWithdrawClient = () => {
    const dataToSend = {
      dateFormat: "dd MMM yyyy",
      locale: "en",
      note: note,
      withdrawnOnDate: formatDate(newDate, true),
    };
    dispatch(withdrawLoan(id, dataToSend, "withdrawnByApplicant", history));
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>Withdraw Loan Account</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <div className="mt-5 form-group d-flex justify-content-between align-items-center">
            <label className="text-label">Withdrawn On*</label>
            <FormControl style={{ width: "60%" }}>
              <DatePicker
                id="published-date"
                selected={submittedOnDate}
                onChange={(date) => setSubmittedOnDate(date)}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="dd/mmm/yyyy"
              />
            </FormControl>
          </div>
          <div className="form-group d-flex justify-content-between align-items-center">
            <label className="text-label">Note</label>
            <Textarea
              value={note}
              onChange={(e) => setNote(e.target.value)}
              className="amount-input"
              size="md"
              resize={"none"}
            />
          </div>
          <div className="float-right mt-1">
            <ActionButton color="#2C1DFF" onClick={onHide}>
              <img
                src={
                  require("../../../../../assets/images/icons/cancel.svg")
                    .default
                }
                alt="cancel icon"
              />
              Cancel
            </ActionButton>
            <ActionButton
              color="#2C1DFF"
              style={{ margin: "0px 0px 0 10px" }}
              onClick={(e) => {
                e.preventDefault();
                handleWithdrawClient();
              }}
            >
              <img
                src={
                  require("../../../../../assets/images/icons/save.svg").default
                }
                alt="submit icon"
              />
              Submit
            </ActionButton>
          </div>
        </FormClass>
      </Modal.Body>
    </>
  );
};

export default WithdrawnByClientModal;
