import React from "react";
import { Table } from "react-bootstrap";
import Loading from "./Loading";

const TableLoader = (props) => {
  return (
    <>
      <Table hover responsive={true} borderless>
        <thead className="border-bottom">
          <tr>
            <th>From Client</th>
            <th>From Account</th>
            <th>To Client</th>
            <th>To Account</th>
            <th>Execution Time</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Error log</th>
          </tr>
        </thead>
        <tbody>
          {[...Array(5)].map((e, i) => (
            <tr key={i}>
              <td>
                <Loading />
              </td>
              <td>
                <Loading />
              </td>
              <td>
                <Loading />
              </td>
              <td>
                <Loading />
              </td>
              <td>
                <Loading />
              </td>
              <td>
                <Loading />
              </td>
              <td>
                <Loading />
              </td>
              <td>
                <Loading />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default TableLoader;
