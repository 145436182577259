import React from "react";
import { Modal } from "react-bootstrap";
import { FormControl } from "@chakra-ui/react";
import DatePicker from "react-datepicker";

import { ActionButton } from "../../styles";

const ActionModal = ({
  header,
  onHide,
  type,
  activationDate,
  setActivationDate,
  action,
}) => {
  return (
    <div className="new">
      <Modal.Header>{header}</Modal.Header>
      {type === 'clientActivation' ? (
        <Modal.Body className="wc-modal">
          <div className="mt-5 d-flex  flex-direction-coloumn">
            {type === 'clientActivation' ? (
              <>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Activation Date</label>
                  <FormControl style={{ width: '80%' }}>
                    <DatePicker
                      id="published-date"
                      selected={activationDate}
                      onChange={(date) => setActivationDate(date)}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="dd/mmm/yyyy"
                    />
                  </FormControl>
                </div>{' '}
              </>
            ) : (
              ''
            )}
          </div>
        </Modal.Body>
      ) : (
        ''
      )}
      <Modal.Footer>
        <div className="d-flex mt-4">
          <ActionButton
            background="#ffffff"
            border="1px solid #2C1DFF"
            color="#2C1DFF"
            onClick={onHide}
          >
            Cancel
          </ActionButton>{' '}
          <ActionButton background="#2C1DFF" onClick={action}>
            Confirm
          </ActionButton>
        </div>
      </Modal.Footer>
    </div>
  );
};

export default ActionModal;
