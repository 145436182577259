import React from "react";
import { Table } from "antd";
import Loading from "./Loading";

const columns = [
  {
    title: 'Tier name',
    dataIndex: 'tierName',
    key: 'tierName',
  },
  { title: 'Tier rank', dataIndex: 'tierRank', key: 'tierRank' },
  {
    title: 'Savings withdrawal limit',
    dataIndex: 'savingsWithdrawLimit',
    key: 'savingsWithdrawLimit',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  { title: 'Action', dataIndex: 'edit', key: 'edit' },
  { title: '', dataIndex: 'delete', key: 'delete' },
];

const TableLoader = (props) => {
  const someChartsData = [
    {
      key: 1,
      tierName: <Loading />,
      tierRank: <Loading />,
      description: <Loading />,
      savingsWithdrawLimit: <Loading />,
      edit: <Loading />,
      delete: <Loading />,
    },
    {
      key: 2,
      tierName: <Loading />,
      tierRank: <Loading />,
      description: <Loading />,
      savingsWithdrawLimit: <Loading />,
      edit: <Loading />,
      delete: <Loading />,
    },
    {
      key: 3,
      tierName: <Loading />,
      tierRank: <Loading />,
      description: <Loading />,
      savingsWithdrawLimit: <Loading />,
      edit: <Loading />,
      delete: <Loading />,
    },
    {
      key: 4,
      tierName: <Loading />,
      tierRank: <Loading />,
      description: <Loading />,
      savingsWithdrawLimit: <Loading />,
      edit: <Loading />,
      delete: <Loading />,
    },
    {
      key: 5,
      tierName: <Loading />,
      tierRank: <Loading />,
      description: <Loading />,
      savingsWithdrawLimit: <Loading />,
      edit: <Loading />,
      delete: <Loading />,
    },
  ];
  return (
    <>
      <Table
        columns={columns}
        dataSource={someChartsData}
        pagination={{ defaultPageSize: 8 }}
        className="antdTable"
        defaultPageSize={8}
      />
    </>
  );
};

export default TableLoader;
