import React, { useState } from "react";
import { ReactComponent as ThreeDots } from "../../../../../assets/images/icons/three-dots.svg";
import {

  Avatar,
  AvatarGroup,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { formatDateString } from "../../../../../util/FormatDate";
import { useDispatch, useSelector } from "react-redux";
import { deleteTellerGroup } from "../../../../../actions/tellerActions";
import { toTitleCase } from "../../../../../util/Tools";
import { CardContainer, FirstRowContainer, Status, NewStatus } from "./styles";
import CheckPermission from "../../../../../util/CheckPermission";
import { UpdateModal } from "./UpdateModal";
import { Link } from "react-router-dom";
import ModalContainer from "../../../../../components/ModalContainer/index";
import logo from "../../../../../assets/images/icons/total-balance.svg";

const TellerGroupCard = ({ data, idx, accountRules, allOffice }) => {
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();

  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  const deleteGroup = (id) => {
    dispatch(deleteTellerGroup(id));
  };

  return (
    <CardContainer to={`/core/teller-group/${data?.id}`}>
      <ModalContainer
        size="md"
        show={isEditing}
        dialogClassName="document_infoModal"
      >
        {isEditing && (
          <UpdateModal
          accountRules = {accountRules}
          allOffice = {allOffice}
            data={data}
            handleCloseModal={() => setIsEditing(false)}
          />
        )}
      </ModalContainer>
      <FirstRowContainer className="d-flex justify-content-end align-items-center pt-1">
        {/* <NewStatus status={data?.status}>
          <span className="circle-icon"></span>
          <button className="button">{data?.status}</button>
        </NewStatus> */}
        <Menu direction="rtl">
          {({ isOpen }) => (
            <>
              <MenuButton transition="all 0.2s">
                <ThreeDots />
              </MenuButton>
              <MenuList className="menu-list-teller">
                {CheckPermission("READ_TELLER", permissions) && (
                  <MenuItem>
                    <Link
                      to={`/core/teller-group/${data?.id}/teller/${idx}`}
                      className="text-label"
                    >
                      View
                    </Link>
                  </MenuItem>
                )}
                {CheckPermission("UPDATE_TELLER", permissions) && (
                  <MenuItem
                    onClick={() => setIsEditing(true)}
                    className="text-label"
                  >
                    Update
                  </MenuItem>
                )}
                {CheckPermission("DELETE_TELLER", permissions) && (
                  <MenuItem
                    onClick={() => deleteGroup(data?.id)}
                    className="text-label"
                  >
                    Delete
                  </MenuItem>
                )}
              </MenuList>
            </>
          )}
        </Menu>
      </FirstRowContainer>
      <Link to={`/core/teller-group/${data?.id}`}>
        <div className="row">
          <div className="pr-3 pl-2">
            <img src={logo} className="teller-card-icon"/>
          </div>
          <div className="mt-1">
            <h1 className="teller-head teller-ellipses">{toTitleCase(data?.name)}</h1>
            <p className="teller-sub-menu">{data?.description}</p>
          </div>
        </div>
        <div className="mt-1">
          <AvatarGroup size="sm" max={3}>
            {data?.imgData?.map((each) => (
              <Avatar name={each?.name} src={each?.url} />
            ))}
          </AvatarGroup>
        </div>
        <div className="d-flex justify-content-between mt-2">
          <div className="d-flex align-items-end">
            <p className="teller-text-label">Start date: </p>
            <span className="ml-1 teller-text-label">
              {formatDateString(data?.startDate, "dd/MMM/yyyy")}
            </span>
          </div>
          <div className="d-flex align-items-end">
            <p className="teller-text-label">End date:</p>
            <span className="ml-1 teller-text-label">
              {data?.endDate
                ? formatDateString(data?.endDate, "dd/MMM/yyyy")
                : "No end date"}
            </span>
          </div>
        </div>
      </Link>
    </CardContainer>
  );
};

export default TellerGroupCard;
