import React from "react";
import { Table, Spinner } from "react-bootstrap";
import { ActionButton, FormTitle } from "../../../styles";
import { formatCurrency } from "../../../../../../../../util/FormatAmount";

const SavingsReview = ({
  handlePreviousScreen,
  handleSubmit,
  description,
  shortName,
  currency,
  decimalPlaces,
  multipleCurrency,
  nominalAnnualInterest,
  interestPostingPeriod,
  interestCompoundingPeriod,
  interestCalculated,
  minimumOpeningBalance,
  lockInPeriod,
  lockInPeriodSelect,
  applyWithdrawalFee,
  isOverdraft,
  minBalanceForInterestCalculation,
  enforceMinimumBalance,
  minumumBalance,
  isWitholdTax,
  enableDomingTracking,
  charges,
  template,
  accountType,
  loading,
  feeToIncomeAccountMappings,
  penaltyToIncomeAccountMappings,
  paymentChannelToFundSourceMappings,
  newSavingsProduct,
  nubanPrefix,
  enableWithdrawallimit,
  overdraftPortfolio,
  savingPreference,
  savingControl,
  interestOnSavings,
  writeOff,
  incomeFromFees,
  overdraftInterestIncome,
  incomeFromPenalties,
}) => {
  const returnPaymentType = (id) => {
    let paymentType = template
      ? template.paymentTypeOptions.filter((item) => item.id === Number(id))
      : [];
    let paymentTypeValue = paymentType.length > 0 ? paymentType[0].name : "";
    return paymentTypeValue;
  };
  const returnFundSource = (id) => {
    let fundSource =
      template && template.accountingMappingOptions
        ? template.accountingMappingOptions.assetAccountOptions.filter(
            (item) => item.id === Number(id)
          )
        : [];
    let fundSourceValue = fundSource.length > 0 ? fundSource[0].name : "";
    return fundSourceValue;
  };

  const returnFeeType = (id) => {
    let feeType = template
      ? template.chargeOptions.filter((item) => item.id === Number(id))
      : [];
    let feeTypeValue = feeType.length > 0 ? feeType[0].name : "nothing";
    return feeTypeValue;
  };

  const returnIncomeAccount = (id) => {
    let incomeAccount =
      template && template.accountingMappingOptions
        ? template.accountingMappingOptions.incomeAccountOptions.filter(
            (item) => item.id === Number(id)
          )
        : [];


    let incomeAccountValue =
      incomeAccount.length > 0 ? incomeAccount[0].name : "";
    return incomeAccountValue;
  };

  const returnPenaltyType = (id) => {
    let penaltyType =
      template && template.penaltyOptions
        ? template.penaltyOptions.filter((item) => item.id === Number(id))
        : [];

    let penaltyTypeValue = penaltyType?.length > 0 ? penaltyType[0].name : "";
    return penaltyTypeValue;
  };

  return (
    <>
      <div className="col-sm-12 col-md-12 d-flex">
        <div className="container">
          <div>
            <FormTitle>
              <p className="text-info">Details</p>
              <div className="lineThrough"></div>
            </FormTitle>
            <div className="pt-2">
              <div
                className="form-group d-flex  align-items-center text-label"
                style={{ marginBottom: "1rem !important" }}
              >
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Description&nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {description}
                </p>
              </div>
              <div
                className="form-group d-flex  align-items-center text-label"
                style={{ marginBottom: "1rem !important" }}
              >
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Short name&nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>{shortName}</p>
              </div>
              {/* <div
                className="form-group d-flex  align-items-center text-label"
                style={{ marginBottom: "1rem !important" }}
              >
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Nuban Prefix&nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {nubanPrefix}
                </p>
              </div> */}
            </div>
          </div>

          <div className="pt-1">
            <FormTitle>
              <p className="text-info">Terms</p>
              <div className="lineThrough"></div>
            </FormTitle>
            <div className="pt-2">
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Currency&nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {currency !== "" &&
                  template !== undefined &&
                  template.currencyOptions != null
                    ? template.currencyOptions.find(
                        (cur) => cur.code === currency
                      ).displayLabel
                    : null}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Decimal Places&nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {decimalPlaces}
                </p>
              </div>

              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Currency In Multiples Of&nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {multipleCurrency}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  {" "}
                  Nominal Annual Interest Rate&nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {nominalAnnualInterest}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  {" "}
                  Interest Compounding Period&nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {interestCompoundingPeriod !== "" &&
                  template !== undefined &&
                  template.interestCompoundingPeriodTypeOptions != null
                    ? template.interestCompoundingPeriodTypeOptions.find(
                        (cur) => cur.id === Number(interestCompoundingPeriod)
                      ).value
                    : null}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  {" "}
                  Interest Posting Period&nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {interestPostingPeriod !== "" &&
                  template !== undefined &&
                  template
                    ? template?.interestPostingPeriodTypeOptions &&
                      template?.interestPostingPeriodTypeOptions.find(
                        (cur) => cur.id === Number(interestPostingPeriod)
                      ).value
                    : null}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Interest Calculated Using Days In Years&nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {interestCalculated !== "" &&
                  template !== undefined &&
                  template
                    ? template?.interestCalculationTypeOptions &&
                      template?.interestCalculationTypeOptions.find(
                        (cur) => cur.id === Number(interestCalculated)
                      ).value
                    : null}
                  {/* {intCompoundPeriod(interestCalculated)} */}
                </p>
              </div>
            </div>
          </div>

          <div className="pt-1">
            <FormTitle>
              <p className="text-info">Settings</p>
              <div className="lineThrough"></div>
            </FormTitle>
            <div className="pt-2">
              {/* {newSavingsProduct.enableWithdrawallimit === true ? ( */}
              <>
                <div className="form-group d-flex  align-items-center text-label">
                  <h3
                    style={{
                      fontFamily: "AventaSemiBold",
                      marginRight: "1rem",
                    }}
                    className="col-md-4"
                  >
                    Enable Withdrawal Count For Interest
                    Posting&nbsp;&nbsp;&nbsp;:
                  </h3>
                  <p style={{ width: "60%", fontWeight: "bold" }}>
                    {enableWithdrawallimit === true ? "True" : "False"}
                  </p>
                </div>

                <div className="form-group d-flex  align-items-center text-label">
                  <h3
                    style={{
                      fontFamily: "AventaSemiBold",
                      marginRight: "1rem",
                    }}
                    className="col-md-4"
                  >
                    Withdrawal Limit Number&nbsp;&nbsp;&nbsp;:
                  </h3>
                  <p style={{ width: "60%", fontWeight: "bold" }}>
                    {newSavingsProduct.withdrawalLimit
                      ? newSavingsProduct.withdrawalLimit
                      : "0"}
                  </p>
                </div>
              </>
              {/* ) // : ( // "" // )} */}
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Minimum Opening Balance &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {formatCurrency(Number(minimumOpeningBalance))}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Lock-In Period&nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {lockInPeriod} -
                  {lockInPeriodSelect != "" &&
                  template !== undefined &&
                  template
                    ? template?.lockinPeriodFrequencyTypeOptions &&
                      template?.lockinPeriodFrequencyTypeOptions.find(
                        (cur) => cur.id === Number(lockInPeriodSelect)
                      ).value
                    : null}
                </p>
              </div>
              <div className="form-group d-flex align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Apply Withdrawal Fee For Transfers&nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {applyWithdrawalFee === true ? "True" : "False"}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Allow Overdraft&nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {isOverdraft === true ? "True" : "False"}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  {" "}
                  Balance Required For Interest Calculation&nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {formatCurrency(Number(minBalanceForInterestCalculation))}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Enforce Minimum Balance&nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {enforceMinimumBalance === true ? "True" : "False"}
                  {/* {formatCurrency(Number(enforceMinimumBalance))} */}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Minimum Balance&nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {formatCurrency(Number(minumumBalance))}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Is Withhold Tax Applicable&nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {isWitholdTax === true ? "True" : "False"}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Is Dormancy Tracking Active&nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "60%", fontWeight: "bold" }}>
                  {enableDomingTracking === true ? "True" : "False"}
                </p>
              </div>
            </div>
          </div>

          <div className="pt-1">
            <FormTitle>
              <p className="text-info">Charges</p>
              <div className="lineThrough"></div>
            </FormTitle>
            <div className="pt-2">
              <Table className="child-table">
                <thead className="">
                  <tr style={{ backgroundColor: "#FAFAFA" }}>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Amount</th>
                    <th>Collected on</th>
                  </tr>
                </thead>
                <tbody>
                  {charges.length > 0
                    ? charges.map(
                        ({
                          id,
                          name,
                          chargeCalculationType,
                          amount,
                          chargeTimeType,
                        }) => (
                          <tr key={id}>
                            <td>{name}</td>
                            <td>
                              {chargeCalculationType &&
                                chargeCalculationType.value}
                            </td>
                            <td>{formatCurrency(Number(amount))}</td>
                            <td>{chargeTimeType && chargeTimeType.value}</td>
                          </tr>
                        )
                      )
                    : null}
                </tbody>
              </Table>
            </div>
          </div>

          <div className="pt-1">
            <FormTitle>
              <p className="text-info">Accounting</p>
              <div className="lineThrough"></div>
            </FormTitle>
            <div className="pt-2">
              <div
                className="form-group d-flex  align-items-center text-label"
                style={{ marginBottom: "1rem !important" }}
              >
                <h3
                  style={{ fontFamily: "AventaSemiBold", marginRight: "1rem" }}
                  className="col-md-4"
                >
                  Account type&nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ width: "80%" }}>
                  {accountType === "1" ? "None" : "Cash"}
                </p>
              </div>
            </div>
          </div>
          {accountType === "2" && (
            <div className="pt-1">
              <FormTitle>
                <p className="text-info">Advanced Accounting Rule</p>
                <div className="lineThrough"></div>
              </FormTitle>

              {/* Configure Fund Sources For Payment Channels */}
              <div className="pt-2">
                <h3 className="text-label font-weight-bold mb-2">
                  Configure Fund Sources For Payment Channels
                  &nbsp;&nbsp;&nbsp;:
                </h3>
                <Table className="child-table">
                  <thead className="">
                    <tr style={{ backgroundColor: "#FAFAFA" }}>
                      <th>Payment Type</th>
                      <th>Fund Source</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentChannelToFundSourceMappings.length > 0
                      ? paymentChannelToFundSourceMappings.map(
                          ({ paymentTypeId, fundSourceAccountId }) => (
                            <tr key={paymentTypeId}>
                              <td>{returnPaymentType(paymentTypeId)}</td>
                              <td>{returnFundSource(fundSourceAccountId)}</td>
                            </tr>
                          )
                        )
                      : null}
                  </tbody>
                </Table>
              </div>
              {/* Fees To Specific Income Account */}
              <div className="pt-2">
                <h3 className="text-label font-weight-bold mb-2">
                  Fees To Specific Income Account &nbsp;&nbsp;&nbsp;:
                </h3>
                <Table className="child-table">
                  <thead className="">
                    <tr style={{ backgroundColor: "#FAFAFA" }}>
                      <th>Fees</th>
                      <th>Income Account</th>
                    </tr>
                  </thead>
                  <tbody>
                    {feeToIncomeAccountMappings.length > 0
                      ? feeToIncomeAccountMappings.map(
                          ({ chargeId, incomeAccountId }) => (
                            <tr key={chargeId}>
                              <td>{returnFeeType(chargeId)}</td>
                              <td>{returnIncomeAccount(incomeAccountId)}</td>
                            </tr>
                          )
                        )
                      : null}
                  </tbody>
                </Table>
              </div>
              {/* Penalties To Specific Income Account */}
              <div className="pt-2">
                <h3 className="text-label font-weight-bold mb-2">
                  Penalties To Specific Income Account &nbsp;&nbsp;&nbsp;:
                </h3>
                <Table className="child-table">
                  <thead className="">
                    <tr style={{ backgroundColor: "#FAFAFA" }}>
                      <th>Penalty</th>
                      <th>Income Account</th>
                    </tr>
                  </thead>
                  <tbody>
                    {penaltyToIncomeAccountMappings.length > 0
                      ? penaltyToIncomeAccountMappings.map(
                          ({ chargeId, incomeAccountId }) => (
                            <tr key={chargeId}>
                              <td>{returnPenaltyType(chargeId)}</td>
                              <td>{returnIncomeAccount(incomeAccountId)}</td>
                            </tr>
                          )
                        )
                      : null}
                  </tbody>
                </Table>
              </div>
            </div>
          )}
        </div>
      </div>
      <div>
        <div className="float-right pt-1">
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={handlePreviousScreen}
          >
            <img
              src={
                require("../../../../../../../../assets/images/icons/cancel.svg")
                  .default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton
            onClick={handleSubmit}
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
          >
            {loading ? (
              <Spinner
                animation="border"
                color="#fff"
                className="mr-2 text-white"
                size="sm"
              />
            ) : (
              <img
                src={
                  require("../../../../../../../../assets/images/icons/save.svg")
                    .default
                }
                alt="submit icon"
              />
            )}
            Submit
          </ActionButton>
        </div>
      </div>
    </>
  );
};

export default SavingsReview;
