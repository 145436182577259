import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ModalContainer from "../../components/ModalContainer";
import AddFinancialActivityModal from "./AddFinancialActivityModal";
import FinancialActivityDetailSlide from "./FinancialActivityDetailSlide";
import { ContentWrapper, ActionButton } from "./styles";
import {
  getFinancialActivityMappings,
  getFinancialActivityTemplate,
} from "../../../../actions/accountingActions";
import "./index.css";
import TableLoader from "./TableLoader";
import CheckPermission from "../../../../util/CheckPermission";
import { textToUpperCase, camelCaseToSentence } from "../../../../util/Tools";

const columns = [
  {
    title: "Financial Activity",
    dataIndex: "financialActivities",
    key: "financialActivities",
  },
  { title: "Account Name", dataIndex: "accountName", key: "accountName" },
  {
    title: "Account Number",
    dataIndex: "accountNumber",
    key: "accountNumber",
  },
];

const FinancialActivities = ({ history }) => {
  const dispatch = useDispatch();
  const accountingData = useSelector((state) => state.accountingData);
  let {
    financialActivityMappings = [],
    financialActivityTemplate = {},
    isLoading,
  } = accountingData;
  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  const activityMappingsData = [];

  financialActivityMappings.forEach((activity) => {
    const { glAccountData = {}, financialActivityData = {}, id } = activity;
    activityMappingsData.push({
      financialActivities: !!financialActivityData.name
        ? camelCaseToSentence(financialActivityData.name)
        : "",
      accountName: !!glAccountData.name
        ? textToUpperCase(glAccountData.name)
        : "",
      accountNumber: !!glAccountData.glCode ? glAccountData.glCode : "",
      id,
      financialActivityId: !!financialActivityData.id
        ? financialActivityData.id
        : "",
      mappedGLAccountType: !!financialActivityData.mappedGLAccountType
        ? financialActivityData.mappedGLAccountType
        : "",
    });
  });

  const [modalState, toggleModal] = useState(false);
  const [modalContentName, setModalContentName] = useState("");
  const [editState, setEditStage] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState({});

  const handleCloseDetail = () => {
    setEditStage(false);
  };

  useEffect(() => {
    dispatch(getFinancialActivityMappings());
    dispatch(getFinancialActivityTemplate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentContent = (e) => {
    const val = e.currentTarget.id.trim();

    if (val) {
      toggleModal(true);
      setModalContentName(val);
    }
  };

  const handleModalClose = () => toggleModal(false);

  const displayModalContent = () => {
    switch (modalContentName) {
      case "addFinancialActivity":
        return (
          <AddFinancialActivityModal
            financialActivityTemplate={financialActivityTemplate}
            onHide={handleModalClose}
            history={history}
          />
        );
      default:
        return;
    }
  };
  return (
    <>
      <ModalContainer show={modalState}>
        {modalState && displayModalContent()}
      </ModalContainer>
      <div className="container-fluid p-relative">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-12 col-xl-6 pb-3">
            <h3 className="page-title">Financial Activity Mappings</h3>
            <p className="sub-title">
              List of financial activity and GL account matching
            </p>
            <p className="sub-title mt-2">
              <span className="text-info mr-1">
                <Link to="/core/accounting">Accounting</Link>
              </span>{" "}
              {editState === true ? (
                <>
                  {" "}
                  <span className="text-info mr-1">
                    <Link to="/core/accounting/financial-activities">
                      / Financial Activity Mapping
                    </Link>
                  </span>{" "}
                  /{" "}
                  {!!selectedActivity.accountName
                    ? selectedActivity.accountName
                    : ""}
                </>
              ) : (
                "/ Financial Activity Mappings"
              )}
            </p>
          </div>
          <div className="col-sm-12 col-md-12 col-xl-6">
            <div className="float-right">
              {CheckPermission(
                "CREATE_FINANCIALACTIVITYACCOUNT",
                permissions
              ) && (
                <ActionButton
                  onClick={currentContent}
                  id="addFinancialActivity"
                  color="#2C1DFF"
                  className=""
                >
                  <img
                    src={
                      require("../../../../assets/images/icons/add.svg").default
                    }
                    className="d-inline mr-3"
                    alt="icon"
                  />
                  New Mapping
                </ActionButton>
              )}
            </div>
          </div>
        </div>
        <div
          className={`${editState === true ? "show-detail" : "hide-detail"}`}
        >
          <FinancialActivityDetailSlide
            handleCloseDetail={handleCloseDetail}
            selectedActivity={selectedActivity}
            history={history}
            financialActivityTemplate={financialActivityTemplate}
            permissions={permissions}
          />
        </div>
        <div className="tableToShift">
          <div className={`${editState === true ? "shifting-table" : ""}`}>
            {CheckPermission("READ_FINANCIALACTIVITYACCOUNT", permissions) && (
              <ContentWrapper>
                {isLoading === true && !financialActivityMappings.length ? (
                  <TableLoader />
                ) : (
                  <Table
                    columns={columns}
                    dataSource={activityMappingsData}
                    pagination={{ defaultPageSize: 8 }}
                    defaultPageSize={8}
                    onRow={(r) => ({
                      onClick: () => {
                        setEditStage(true);
                        setSelectedActivity(r);
                      },
                    })}
                  />
                )}
              </ContentWrapper>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FinancialActivities;
