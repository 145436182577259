import styled from "styled-components";

export const LayoutContainer = styled.div`
  width: 100%;
  height: 100vh;
`;

export const FormContainer = styled.div`
  width: 400px;
  height: auto;
`;

export const SubText = styled.p`
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: #fff;
`;

export const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin: 0 auto;
`;
