import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Select } from "@chakra-ui/react";
import { getAllCountries, getAllStates, updateOnlyAddressInfo } from "../../../../../actions/customerActions";
import Toast from "../../../../../util/Toast";

import { Select as AntdSelect } from "antd";

import { FormElements, ActionButton } from "../styles";

const AddressTab = ({ address, addressTemplate, id }) => {
  const dispatch = useDispatch();
  const { countryData } = useSelector((state) => state.getCountryReducer);
  const { stateData } = useSelector((state) => state.getStateReducer);

  const [valueChanged, setValueChanged] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [country, setCountry] = useState(null);
  const [state, setState] = useState(null);

  useEffect(() => {
    dispatch(getAllCountries());
  }, []);

  useEffect(() => {
    if (country?.name) {
      setState(null);
      dispatch(getAllStates(country?.name));
    }
  }, [country])

  let defaultAddress = {
    addressTypeId: "",
    countryId: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    city: "",
    stateProvinceId: "",
    postalCode: "",
  };

  useEffect(() => {
    if (address && address.length) {
      setAddresses(address || defaultAddress);
      setCountry({ id: address[0]?.countryId });
      setState({ id: address[0]?.stateProvinceId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);
  let {
    addressTypeIdOptions = [],
    countryIdOptions = [],
    stateProvinceIdOptions = [],
  } = addressTemplate && addressTemplate.length
    ? addressTemplate[0] === null
      ? {}
      : addressTemplate[0]
    : {};

  const handleAddAddress = () => {
    setAddresses([...addresses, defaultAddress]);
  };

  const handleAddressChange = (value, index, paramter) => {
    let hhh = [...addresses];
    hhh[index][paramter] = value;
    setAddresses(hhh);
  };

  const handleRemoveAddress = (position) => {
    let hhh = [];
    addresses.forEach((element, index) => {
      if (position !== index) {
        hhh.push(element);
      }
    });
    setAddresses(hhh);
  };

  const handleCancel = () => {
    setValueChanged(false);
    setAddresses(address || defaultAddress);
  };

  const handleCustomerEdit = () => {
    if (address.addressTypeId === "") {
      dispatch(Toast({ text: "Please Select an Address Type", icon: "info" }));
    } else if (address.countryId === "") {
      dispatch(Toast({ text: "Please Select a Country", icon: "info" }));
    } else if (address.stateProvinceId === "") {
      dispatch(Toast({ text: "Please Provide a State", icon: "info" }));
    } else {
      let userData = {
        addressLine1: address[0].addressLine1,
        addressLine2: address[0].addressLine2,
        city: address[0].city,
        // stateProvinceId: address[0].stateProvinceId,
        // countryId: address[0].countryId,
        countryId: Number(country?.id),
        stateProvinceId: Number(state?.id),
        locale: "en",
        postalCode: address[0].postalCode,
        addressId: address[0].addressId,
      };
      dispatch(updateOnlyAddressInfo(userData, id, handleCancel));
    }
  };

  let { Option } = AntdSelect;

  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          marginTop: "50px",
        }}
      >
        <div
          className={
            addresses && addresses.length && addresses.length > 1
              ? "w-8"
              : "w-6"
          }
        >
          <FormElements>
            <div className="row">
              {addresses.map((address, index) => {
                return (
                  <div
                    className={
                      addresses && addresses.length && addresses.length > 1
                        ? "col-md-6 col-sm-12 p-relative"
                        : "col-md-12 p-relative"
                    }
                    key={index}
                  >
                    {addresses && addresses.length && addresses.length > 1 ? (
                      <div
                        className="remove-icon"
                        onClick={() => handleRemoveAddress(index)}
                      >
                        <img
                          src={
                            require("../../../../../assets/images/icons/cancel.svg")
                              .default
                          }
                          className="detail-cancel-icon"
                          alt="cancel icon"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {/* <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-label">Address Type*</label>
                      <Select
                        placeholder="Select address type"
                        size="md"
                        name="addressTypeId"
                        value={address.addressTypeId}
                        onChange={(e) => {
                          handleAddressChange(
                            e.target.value,
                            index,
                            "addressTypeId"
                          );
                          setValueChanged(true);
                        }}
                      >
                        {addressTypeIdOptions &&
                          addressTypeIdOptions.map(({ id, name }) => {
                            return (
                              <option value={id} key={id}>
                                {name}
                              </option>
                            );
                          })}
                      </Select>
                    </div> */}
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-label">Address*</label>
                      <div style={{ width: "60%" }}>
                        <Input
                          style={{ width: "100%", marginBottom: "10px" }}
                          size="md"
                          name="addressLine1"
                          value={address.addressLine1}
                          onChange={(e) => {
                            handleAddressChange(
                              e.target.value,
                              index,
                              "addressLine1"
                            );
                            setValueChanged(true);
                          }}
                        />
                      </div>
                    </div>{" "}
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-label">Address Line 2</label>
                      <div style={{ width: "60%" }}>
                        <Input
                          style={{ width: "100%", marginBottom: "10px" }}
                          size="md"
                          name="addressLine2"
                          value={address.addressLine2}
                          onChange={(e) => {
                            handleAddressChange(
                              e.target.value,
                              index,
                              "addressLine2"
                            );
                            setValueChanged(true);
                          }}
                        />
                      </div>
                    </div>{" "}
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-label">City</label>
                      <div style={{ width: "60%" }}>
                        <Input
                          style={{ width: "100%", marginBottom: "10px" }}
                          size="md"
                          name="city"
                          value={address.city}
                          onChange={(e) => {
                            handleAddressChange(e.target.value, index, "city");
                            setValueChanged(true);
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-label">Country*</label>
                      <AntdSelect
                        showSearch
                        placeholder="Select country"
                        optionFilterProp="children"
                        className="chakra-select__wrapper fs-12"
                        defaultValue={address.countryName}
                        onChange={(_, option) => {
                          setCountry({ id: option?.key, name: option?.children })
                          setValueChanged(true);
                        }}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {countryData?.map(country => (
                          <option value={country?.id} key={country?.id}>
                            {country?.name}
                          </option>
                        ))}
                      </AntdSelect>
                    </div>
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-label">State*</label>
                      <AntdSelect
                        showSearch
                        placeholder="Select state"
                        optionFilterProp="children"
                        className="chakra-select__wrapper fs-12"
                        defaultValue={address.stateName}
                        onChange={(_, option) => {
                          setState({ id: option?.key, name: option?.children })
                          setValueChanged(true);
                        }}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {stateData?.map(state => (
                          <option value={state?.name} key={state?.id}>
                            {state?.name}
                          </option>
                        ))}
                      </AntdSelect>
                    </div>{" "}
                  </div>
                );
              })}
            </div>
          </FormElements>
          <div className="row">
            {valueChanged ? (
              <div className="spaceBetweenFlexEnd w-100 my-4 mr-5">
                <ActionButton
                  color="#2C1DFF"
                  style={{ margin: "0px 0px 0 10px" }}
                  onClick={handleCancel}
                >
                  Cancel
                </ActionButton>{" "}
                <ActionButton
                  color="#2C1DFF"
                  style={{ margin: "0px 0px 0 10px" }}
                  onClick={handleCustomerEdit}
                >
                  Update
                </ActionButton>{" "}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddressTab;
