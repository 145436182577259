import React from "react";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { ActionButton } from "../../../styles";
import { disableOrEnableAChartsOfAccount } from "../../../../../../actions/accountingActions";

const DisableAccountModal = ({ onHide, history, id, status }) => {
  const dispatch = useDispatch();

  const handleDisableEnableStatus = (e) => {
    e.preventDefault();
    dispatch(disableOrEnableAChartsOfAccount(status, id, history));
    onHide();
  };
  return (
    <>
      <Modal.Header>
        <Modal.Title>{status ? "Disable" : "Enable"} Account</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center flex-direction-coloumn">
        <div className="m-1">
          <p style={{ fontSize: "14px" }}>
            Are you sure you want to {status ? "Disable" : "Enable"}{" "}
            this GL account
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-1">
          <ActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require("../../../../../../assets/images/icons/cancel.svg")
                  .default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={(e) => handleDisableEnableStatus(e)}
          >
            <img
              src={
                require("../../../../../../assets/images/icons/save.svg")
                  .default
              }
              alt="submit icon"
            />
            Submit
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default DisableAccountModal;
