/* eslint-disable import/no-anonymous-default-export */
import axiosData from '../axiosData';
import config from '../../config';

/*
 * Get all Users
 */
const getAllUsers = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/users`,
        params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Get all Offices
const getAllOffices = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/offices`,
        params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Get User Template
const getUserTemplate = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/users/template`,
        params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Get All Office Staffs Template
const getOfficeStaffs = (officeId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/staff?officeId=${officeId}&status=all`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Get All Currencies
const getAllCurrency = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/currencies`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Get All Employees
const getAllEmployees = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/staff?status=all`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Get All Entity Data Table Check
const getAllEntityDataTableCheck = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/entityDatatableChecks`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Get Entity Data Table Template
const getEntityDataTableTemplate = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/entityDatatableChecks/template`,
        params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Get All Holidays
const getAllHolidays = (officeId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/holidays/?officeId=${officeId}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Get Holiday Template
const getHolidayTemplate = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/holidays/template`,
        params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Get Working Days
const getWorkingDays = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/workingdays`,
        params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Get Standing Instructions History Template
const getStandingInstructionsTemplate = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/standinginstructions/template`,
        params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Get Standing Instructions History
const getStandingInstructions = ({
  clientName,
  accountType,
  transferType,
  endDate,
  startDate,
  assignmentDate,
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${
          config.apiGateway.BASE_API_URL
        }/standinginstructions?dateFormat=dd+MMMM+yyyy${
          !!clientName ? `&clientName=${!!clientName ? clientName : ''}` : ''
        }${
          !!accountType
            ? `&fromAccountType=${!!accountType ? accountType : ''}`
            : ''
        }${
          !!transferType
            ? `&transferType=${!!transferType ? transferType : ''}`
            : ''
        }${
          !!assignmentDate
            ? `&validFrom=${!!assignmentDate ? assignmentDate : ''}`
            : ''
        }${!!startDate ? `&fromDate=${!!startDate ? startDate : ''}` : ''}${
          !!endDate ? `&toDate=${!!endDate ? endDate : ''}` : ''
        }&locale=en&offset=0`,
        // params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Get All Payment type
const getPaymentType = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/paymenttypes`,
        params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Create/Modify Working days
 */
const createWorkingDay = (workingHourData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'PUT',
        data: workingHourData,
        url: `${config.apiGateway.BASE_API_URL}/workingdays`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Create New User
 */
const createNewUser = (userData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'POST',
        data: userData,
        url: `${config.apiGateway.BASE_API_URL}/users`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Create New User
 */
const generateUserApiKey = (userData, headers) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'POST',
        data: userData,
        url: `${config.apiGateway.SPARK_API_URL}/gateway/create`,
        headers,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Create New User
 */
const getUserApiKeys = (id, headers) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        // data: userData,
        url: `${config.apiGateway.SPARK_API_URL}/gateway/apiKeys/${id}`,
        headers,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Create New User
 */
const manageApiKey = (id, headers) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'POST',
        // data: userData,
        url: `${config.apiGateway.SPARK_API_URL}/gateway/manage/${id}`,
        headers,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Create New User
 */
const createNewUser2 = (userData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'POST',
        data: userData,
        url: `${config.apiGateway.PRE_AUTH_API_URL}/users`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Create New Office
 */
const createNewOffice = (officeData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'POST',
        data: officeData,
        url: `${config.apiGateway.BASE_API_URL}/offices`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Create New Currency
 */
const createNewCurrency = (currencyData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'PUT',
        data: currencyData,
        url: `${config.apiGateway.BASE_API_URL}/currencies`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Create New Employee
 */
const createNewEmployee = (officeData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'POST',
        data: officeData,
        url: `${config.apiGateway.BASE_API_URL}/staff`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Create New Entity Data Table Checks
 */
const createNewEntityDataTableCheck = (officeData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'POST',
        data: officeData,
        url: `${config.apiGateway.BASE_API_URL}/entityDatatableChecks`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Create New Holiday
 */
const createNewHoliday = (holidayData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'POST',
        data: holidayData,
        url: `${config.apiGateway.BASE_API_URL}/holidays`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Create New Payment type
 */
const createNewPaymentType = (paymentTypeData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'POST',
        data: paymentTypeData,
        url: `${config.apiGateway.BASE_API_URL}/paymenttypes`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Activate Holiday
 */
const activateHoliday = (holidayData, holidayId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'POST',
        data: holidayData,
        url: `${config.apiGateway.BASE_API_URL}/holidays/${holidayId}?command=activate`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/**
 * Update Employee information
 */
const editUserInfo = (userData, userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'PUT',
        data: userData,
        url: `${config.apiGateway.BASE_API_URL}/users/${userId}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/**
 * Reset User Password
 */
const resetUserPassword = (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/users/${userId}/send-reset-password`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/**
 * Perform Password check
 */
const performPasswordCheck = (id, password) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        headers: {
          'Access-Control-Allow-Origin': '*/*',
        },
        url: `${config.apiGateway.BASE_API_URL}/users/${id}/verify?password=${password}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/**
 * Update Office information
 */
const editOfficeInfo = (userData, officeId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'PUT',
        data: userData,
        url: `${config.apiGateway.BASE_API_URL}/offices/${officeId}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/**
 * Fetch Employee information
 */
const fetchEmployeeInfo = (employeeId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/staff/${employeeId}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/**
 * Fetch Employee profile photo
 */
const fetchEmployeeImage = (employeeId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/staff/${employeeId}/images`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/**
 * Update Employee profile photo
 */
const editEmployeeImage = (employeeImage, employeeId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const formData = new FormData();
      formData.append('file', employeeImage);
      const { data, error } = await axiosData({
        method: 'PUT',
        data: formData,
        url: `${config.apiGateway.BASE_API_URL}/staff/${employeeId}/images`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/**
 * Update Employee information
 */
const editEmployeeInfo = (employeeData, employeeId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'PUT',
        data: employeeData,
        url: `${config.apiGateway.BASE_API_URL}/staff/${employeeId}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/**
 * Update Employee information
 */
const editHolidayInfo = (holidayData, holidayId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'PUT',
        data: holidayData,
        url: `${config.apiGateway.BASE_API_URL}/holidays/${holidayId}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/**
 * Update Payment type
 */
const editPaymentTypeInfo = (userData, paymentId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'PUT',
        data: userData,
        url: `${config.apiGateway.BASE_API_URL}/paymenttypes/${paymentId}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Delete User
const deleteUser = (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'DELETE',
        // data: holidayData,
        url: `${config.apiGateway.BASE_API_URL}/users/${userId}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Delete Entity-Datatable Checks
const deleteEntityDataTableCheck = (
  entityDatatableCheckData,
  entityDatatableCheckId
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'DELETE',
        data: entityDatatableCheckData,
        url: `${config.apiGateway.BASE_API_URL}/entityDatatableChecks/${entityDatatableCheckId}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Delete Holiday Checks
const deleteHoliday = (holidayId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'DELETE',
        // data: holidayData,
        url: `${config.apiGateway.BASE_API_URL}/holidays/${holidayId}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Delete Payment type
const deletePaymentType = (paymentId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'DELETE',
        // data: holidayData,
        url: `${config.apiGateway.BASE_API_URL}/paymenttypes/${paymentId}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

//--- audit trails search params

const getAllAudit = (
  actionName,
  checkerDateTimeFrom,
  checkerDateTimeTo,
  checkerId,
  entityName,
  makerDateTimeFrom,
  makerDateTimeTo,
  makerId,
  resourceId,
  status
) => {
  return new Promise(async (resolve, reject) => {
    // console.log(makerId);
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        // signal: cancelToken ? cancelToken : false,
        // data: jourEntry,

        url: `${config.apiGateway.BASE_API_URL}/audits?dateFormat=dd+MMMM+yyyy${
          !!actionName ? `&actionName=${!!actionName ? actionName : ''}` : ''
        }${!!makerId ? `&maker=${!!makerId ? makerId : ''}` : ''}${
          !!checkerDateTimeFrom
            ? `&checkerDateTimeFrom=${
                !!checkerDateTimeFrom ? checkerDateTimeFrom : ''
              }`
            : ''
        }${
          !!checkerDateTimeTo
            ? `&checkerDateTimeTo=${
                !!checkerDateTimeTo ? checkerDateTimeTo : ''
              }`
            : ''
        }${
          !!entityName ? `&entityName=${!!entityName ? entityName : ''}` : ''
        }${!!checkerId ? `&makerId=${!!checkerId ? checkerId : ''}` : ''}${
          !!makerDateTimeFrom
            ? `&makerDateTimeFrom=${
                !!makerDateTimeFrom ? makerDateTimeFrom : ''
              }`
            : ''
        }${
          !!makerDateTimeTo
            ? `&makerDateTimeTo=${!!makerDateTimeTo ? makerDateTimeTo : ''}`
            : ''
        }
        
        ${!!status ? `&processingResult=${!!status ? status : ''}` : ''}${
          !!resourceId ? `&resourceId=${!!resourceId ? resourceId : ''}` : ''
        }`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

const getAllUserAudit = (
  actionName,
  checkerDateTimeFrom,
  checkerDateTimeTo,
  checkerId,
  entityName,
  makerDateTimeFrom,
  makerDateTimeTo,
  makerId,
  resourceId,
  status
) => {
  return new Promise(async (resolve, reject) => {
    // console.log(makerId);
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        // signal: cancelToken ? cancelToken : false,
        // data: jourEntry,

        url: `${config.apiGateway.BASE_API_URL}/audits?dateFormat=dd+MMMM+yyyy${
          !!actionName ? `&actionName=${!!actionName ? actionName : ''}` : ''
        }${!!makerId ? `&maker=${!!makerId ? makerId : ''}` : ''}${
          !!checkerDateTimeFrom
            ? `&checkerDateTimeFrom=${
                !!checkerDateTimeFrom ? checkerDateTimeFrom : ''
              }`
            : ''
        }${
          !!checkerDateTimeTo
            ? `&checkerDateTimeTo=${
                !!checkerDateTimeTo ? checkerDateTimeTo : ''
              }`
            : ''
        }${
          !!entityName ? `&entityName=${!!entityName ? entityName : ''}` : ''
        }${!!checkerId ? `&makerId=${!!checkerId ? checkerId : ''}` : ''}${
          !!makerDateTimeFrom
            ? `&makerDateTimeFrom=${
                !!makerDateTimeFrom ? makerDateTimeFrom : ''
              }`
            : ''
        }${
          !!makerDateTimeTo
            ? `&makerDateTimeTo=${!!makerDateTimeTo ? makerDateTimeTo : ''}`
            : ''
        }
        
        ${!!status ? `&processingResult=${!!status ? status : ''}` : ''}${
          !!resourceId ? `&resourceId=${!!resourceId ? resourceId : ''}` : ''
        }`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

//--- External Services
// Get S3 amazon details
const getAllS3 = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/externalservice/S3`,
        params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/**
 * Update S3 information
 */
const editS3Info = (tCData, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'PUT',
        data: tCData,
        url: `${config.apiGateway.BASE_API_URL}/externalservice/S3`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Get SMTP details
const getAllSMTP = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/externalservice/SMTP`,
        params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/**
 * UpdateSMTP information
 */
const editSMTPInfo = (tCData, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'PUT',
        data: tCData,
        url: `${config.apiGateway.BASE_API_URL}/externalservice/SMTP`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Get SMS details
const getAllSMS = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/externalservice/SMS`,
        params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/**
 * UpdateSMS information
 */
const editSMSInfo = (tCData, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'PUT',
        data: tCData,
        url: `${config.apiGateway.BASE_API_URL}/externalservice/SMS`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Get NOTIFICATION details
const getAllNotification = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/externalservice/NOTIFICATION`,
        params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/**
 * Update NOTIFICATION information
 */
const editNotificationInfo = (tCData, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'PUT',
        data: tCData,
        url: `${config.apiGateway.BASE_API_URL}/externalservice/NOTIFICATION`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

//PRODUCTS APIS

//--- Savings Product
// Get all Saving Template
const getAllSavingsProducts = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/savingsproducts`,
        params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Get Savings template Template
const getSavingsProductsTemplate = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/savingsproducts/template`,
        params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Create New Savings Product
 */
const createSavingsProduct = (savingsProductData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'POST',
        data: savingsProductData,
        url: `${config.apiGateway.BASE_API_URL}/savingsproducts`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/**
 * Update Savings Product information
 */
const editSavingsProductInfo = (userData, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'PUT',
        data: userData,
        url: `${config.apiGateway.BASE_API_URL}/savingsproducts/${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Get all a savings product
const getSavingsProduct = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/savingsproducts/${id}?template=true`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

//--- Loan Product
// Get all Loan Template
const getAllLoanProducts = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/loanproducts`,
        params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Get Loan template Template
const getLoanProductsTemplate = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/loanproducts/template`,
        params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Create New Loan Product
 */
const createLoanProduct = (savingsProductData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'POST',
        data: savingsProductData,
        url: `${config.apiGateway.BASE_API_URL}/loanproducts`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/**
 * Update Loan Product information
 */
const editLoanProductInfo = (userData, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'PUT',
        data: userData,
        url: `${config.apiGateway.BASE_API_URL}/loanproducts/${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Get all a loan product
const getLoanProduct = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/loanproducts/${id}?template=true`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/// -- charges
// Get list of charges
const getAllCharges = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/charges`,
        params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Get Charges Template
const getChargesTemplate = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/charges/template`,
        params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Create New Charge
 */
const createNewCharge = (savingsProductData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'POST',
        data: savingsProductData,
        url: `${config.apiGateway.BASE_API_URL}/charges`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Delete charge
const deleteCharge = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'DELETE',
        url: `${config.apiGateway.BASE_API_URL}/charges/${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/**
 * Update charge information
 */
const editChargeInfo = (cData, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'PUT',
        data: cData,
        url: `${config.apiGateway.BASE_API_URL}/charges/${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Get a charge
const getCharge = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/charges/${id}?template=true`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// --- tax components

// Get all Tax Components
const getAllTaxComponents = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/taxes/component`,
        params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Get Tax Components Template
const getTaxComponentsTemplate = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/taxes/component/template`,
        params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/**
 * Update Tax component information
 */
const editTaxComponentInfo = (tCData, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'PUT',
        data: tCData,
        url: `${config.apiGateway.BASE_API_URL}/taxes/component/${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Create New Tax component
 */
const createNewTaxComponent = (officeData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'POST',
        data: officeData,
        url: `${config.apiGateway.BASE_API_URL}/taxes/component`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// --- tax groups

// Get all Tax Groups
const getAllTaxGroups = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/taxes/group`,
        params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Get Tax Groups Template
const getTaxGroupsTemplate = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/taxes/group/template`,
        params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/**
 * Update Tax component information
 */
const editTaxGroupInfo = (tCData, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'PUT',
        data: tCData,
        url: `${config.apiGateway.BASE_API_URL}/taxes/group/${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Create New Tax component
 */
const createNewTaxGroup = (officeData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'POST',
        data: officeData,
        url: `${config.apiGateway.BASE_API_URL}/taxes/group`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Get User Template
const getAuditTemplate = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/audits/searchtemplate`,
        params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
// Get single audit
const getSingleAudit = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/audits/${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

//-- manage hooks

// Get All Hook
const getHook = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/hooks`,
        params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Create New Hook
 */
const createNewHook = (paymentTypeData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'POST',
        data: paymentTypeData,
        url: `${config.apiGateway.BASE_API_URL}/hooks`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Delete Hook
const deleteHook = (hookId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'DELETE',
        // data: holidayData,
        url: `${config.apiGateway.BASE_API_URL}/hooks/${hookId}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
/**
 * Update Hook
 */
const editHookInfo = (userData, hookId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'PUT',
        data: userData,
        url: `${config.apiGateway.BASE_API_URL}/hooks/${hookId}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Get Hook Template
const getHookTemplate = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/hooks/template`,
        params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Get All Configuration
const getAllConfigurations = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/configurations`,
        params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/**
 * Update Configuration information
 */
const editConfigurationInfo = (tCData, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'PUT',
        data: tCData,
        url: `${config.apiGateway.BASE_API_URL}/configurations/${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

const disableConfiguration = (tCData, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'PUT',
        data: tCData,
        url: `${config.apiGateway.BASE_API_URL}/configurations/${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

const enabledConfiguration = (tCData, id) => {
  return new Promise(async (resolve, reject) => {
    // console.log(tCData)
    try {
      const { data, error } = await axiosData({
        method: 'PUT',
        data: tCData,
        url: `${config.apiGateway.BASE_API_URL}/configurations/${id}`,
      });
      // console.log(tCData)
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

//--- Maker checker tasks
// Get All MCTasks
const getAllMCTasks = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/permissions?makerCheckerable=true`,
        params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/**
 * Update MCTasks information
 */
const editMCTasksInfo = (tCData, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'PUT',
        data: tCData,
        url: `${config.apiGateway.BASE_API_URL}/permissions?makerCheckerable=true`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Get All Roles
const getAllRoles = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/roles`,
        params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Get a Role
const getRole = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        url: `${config.apiGateway.BASE_API_URL}/roles/${id}/permissions`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Create New Role
 */
const createNewRole = (paymentTypeData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'POST',
        data: paymentTypeData,
        url: `${config.apiGateway.BASE_API_URL}/roles`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Delete Role
const deleteRole = (roleId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'DELETE',
        // data: holidayData,
        url: `${config.apiGateway.BASE_API_URL}/roles/${roleId}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
/**
 * Update Role
 */
const editRoleInfo = (userData, roleId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'PUT',
        data: userData,
        url: `${config.apiGateway.BASE_API_URL}/roles/${roleId}/permissions`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

//enable/disable Role
const ableRole = (tCData, id, action) => {
  // action: enable || disable
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'POST',
        data: tCData,
        url: `${config.apiGateway.BASE_API_URL}/roles/${id}?command=${action}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

//get datatables
const getDataTables = () => {
  // action: enable || disable
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        // data: tCData,
        url: `${config.apiGateway.BASE_API_URL}/datatables`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
//get a datatable
const getASingleDataTables = (name) => {
  // action: enable || disable
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        // data: tCData,
        url: `${config.apiGateway.BASE_API_URL}/datatables/${name}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

//get a datatable
const deleteASingleDataTable = (name) => {
  // action: enable || disable
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'DELETE',
        // data: tCData,
        url: `${config.apiGateway.BASE_API_URL}/datatables/${name}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

//create datatables
const createDataTables = (dataToSend) => {
  // action: enable || disable
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'POST',
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/datatables`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

//create datatables
const updateDataTables = (datatableName, dataToSend) => {
  // action: enable || disable
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: 'PUT',
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/datatables/${datatableName}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export default {
  getAllUsers,
  createNewUser,
  getUserTemplate,
  deleteUser,
  editUserInfo,
  resetUserPassword,
  performPasswordCheck,
  getOfficeStaffs,
  getAllOffices,
  editOfficeInfo,
  createNewOffice,
  getAllCurrency,
  createNewCurrency,
  getAllEmployees,
  fetchEmployeeInfo,
  editEmployeeInfo,
  fetchEmployeeImage,
  editEmployeeImage,
  createNewEmployee,
  getAllEntityDataTableCheck,
  createNewEntityDataTableCheck,
  getEntityDataTableTemplate,
  deleteEntityDataTableCheck,
  getAllHolidays,
  getHolidayTemplate,
  createNewHoliday,
  activateHoliday,
  editHolidayInfo,
  deleteHoliday,
  getWorkingDays,
  createWorkingDay,
  getStandingInstructionsTemplate,
  getStandingInstructions,
  getPaymentType,
  createNewPaymentType,
  editPaymentTypeInfo,
  deletePaymentType,
  getAllSavingsProducts,
  getSavingsProductsTemplate,
  createSavingsProduct,
  editSavingsProductInfo,
  getSavingsProduct,
  getAllLoanProducts,
  getLoanProductsTemplate,
  createLoanProduct,
  editLoanProductInfo,
  getLoanProduct,
  getAllCharges,
  getChargesTemplate,
  createNewCharge,
  deleteCharge,
  editChargeInfo,
  getCharge,
  getAllTaxComponents,
  editTaxComponentInfo,
  createNewTaxComponent,
  getTaxComponentsTemplate,
  getAllTaxGroups,
  editTaxGroupInfo,
  createNewTaxGroup,
  getTaxGroupsTemplate,
  getAllAudit,
  getAllUserAudit,
  getAuditTemplate,
  getSingleAudit,
  getHook,
  createNewHook,
  editHookInfo,
  deleteHook,
  getHookTemplate,
  getAllS3,
  editS3Info,
  getAllNotification,
  editNotificationInfo,
  getAllSMTP,
  editSMTPInfo,
  getAllSMS,
  editSMSInfo,
  getAllConfigurations,
  editConfigurationInfo,
  disableConfiguration,
  enabledConfiguration,
  getAllMCTasks,
  editMCTasksInfo,
  getAllRoles,
  createNewRole,
  deleteRole,
  editRoleInfo,
  ableRole,
  getRole,
  generateUserApiKey,
  getUserApiKeys,
  getDataTables,
  createDataTables,
  getASingleDataTables,
  deleteASingleDataTable,
  updateDataTables,
  manageApiKey,
};
