import {
  SET_FIXED_DEPOSIT,
  SET_FIXED_DEPOSIT_TEMPLATE,
  SET_ONE_FIXED_DEPOSIT,
  SET_FIXED_DEPOSIT_ACCOUNT_TEMPLATE,
  SET_FIXED_DEPOSIT_ACCOUNT_DETAILS,
  SET_FD_LEDGER_DETAILS,
  SET_FIXED_DEPOSIT_ACTIONS,
  UPDATE_CHARGES,
  WAIVE_CHARGES,
  SET_FIXED_TRANSACTIONS,
  SET_FIXED_DEPOSIT_ACTIONS_RESET,
  ALL_FIXEDDEPOSIT_PRODUCTS_TEMPLATE_REQUEST,
  ALL_FIXEDDEPOSIT_PRODUCTS_TEMPLATE_SUCCESS,
  ALL_FIXEDDEPOSIT_PRODUCTS_TEMPLATE_FAIL,
  RESET_ALL_FIXEDDEPOSIT_PRODUCTS_TEMPLATE,
  ALL_FD_PRODUCT_REQUEST,
  ALL_FD_PRODUCT_SUCCESS,
  ALL_FD_PRODUCT_FAIL,
  FILTER_FD_PRODUCT_REQUEST,
  CLEAR_FD_PRODUCT_FILTER,
  ALL_FD_PRODUCTS_TEMPLATE_REQUEST,
  ALL_FD_PRODUCTS_TEMPLATE_SUCCESS,
  ALL_FD_PRODUCTS_TEMPLATE_FAIL,
  FD_PRODUCT_RESET,
  CREATE_NEW_FD_PRODUCT_REQUEST,
  CREATE_NEW_FD_PRODUCT_SUCCESS,
  CREATE_NEW_FD_PRODUCT_FAIL,
  CREATE_NEW_FD_PRODUCT_RESET,
  FIXED_DEPOSITS_PRODUCT_REQUEST,
  FIXED_DEPOSITS_PRODUCT_SUCCESS,
  FIXED_DEPOSITS_PRODUCT_FAIL,
  FD_PRODUCT_REQUEST,
  FD_PRODUCT_SUCCESS,
  FD_PRODUCT_FAIL,
  GENERATE_FD_FILE_REQUEST,
  GENERATE_FD_FILE_SUCCESS,
  GENERATE_FD_FILE_FAIL,
  RESET_GENERATE_FD_FILE,
  UPDATE_FD_ACCOUNT_REQUEST,
  UPDATE_FD_ACCOUNT_SUCCESS,
  UPDATE_FD_ACCOUNT_FAIL,
  UPDATE_FD_ACCOUNT_RESET,
} from "../constants/fixedDeposit";

import {
  SET_LOADING_STATE,
  SET_TABLE_LOADING_STATE,
} from "../constants/utilityConstants";

import {
  getAllFixedDeposits,
  getAllFixedDepositTemplate,
  createAFixedDepositTemplate,
  getAFixedDepositProduct,
  editAFixedDepositTemplate,
  createAFixedDepositAccountTemplate,
  createAFixedDepositAccount,
  getAFixedDepositAccountDetails,
  getLedgerTransactionsDetails,
  getLedgerTransactionsForLoanDetails,
  getOneFixedAccountTransactions,
  approveAFixedDepositAccount,
  addAFixedDepositAccountCharge,
  payAFixedDepositAccountCharge,
  waiveAFixedDepositAccountCharge,
  deleteAFixedDepositAccount,
  generateAFDFile,
  editFixedDepositAccountInfo,
} from "../services/Api/fixedDeposit.api.services";

import API_CONFIGURATION from "../services/Api/fixedDeposit.api.services";

import Toast from "../util/Toast";
import { getCustomerAccounts } from "./customerActions";

const handleError = (ex, dispatch) => {
  if (ex.response) {
    dispatch(
      Toast({
        text: ex.response.data.errors
          ? ex.response.data.errors[0].defaultUserMessage
          : ex.response.data.defaultUserMessage,
        icon: "error",
      })
    );
  } else if (ex.request) {
    dispatch(Toast({ text: String(ex), icon: "error" }));
  } else {
    dispatch(Toast({ text: String(ex), icon: "error" }));
  }

  dispatch({ type: SET_LOADING_STATE, payload: false });
};

const getFixedDeposits = () => {
  return async (dispatch) => {
    dispatch({ type: SET_FIXED_DEPOSIT });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getAllFixedDeposits()
      .then((response) => {
        dispatch({
          type: SET_FIXED_DEPOSIT,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

//Get a list of all fixed product
const getAllFixedDepositsProducts = () => {
  return async (dispatch) => {
    dispatch({ type: ALL_FD_PRODUCT_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_CONFIGURATION.getAllFixedDepositsProducts()
      .then((response) => {
        dispatch({
          type: ALL_FD_PRODUCT_SUCCESS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(fixedDepositsProductRequestFail(ex));
      });
  };
};

const getFixedDepositTemplate = () => {
  return async (dispatch) => {
    dispatch({ type: SET_FIXED_DEPOSIT_TEMPLATE });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getAllFixedDepositTemplate()
      .then((response) => {
        dispatch({
          type: SET_FIXED_DEPOSIT_TEMPLATE,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const createFixedDepositTemplate = (dataToSend, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await createAFixedDepositTemplate(dataToSend)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        history.push("/core/products/fixed-deposit-products");
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

/*
 * Create New Fixed Deposit Product
 */

const createFixedDepositsProduct = (fixedDepositProductData, history) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_NEW_FD_PRODUCT_REQUEST });
    await API_CONFIGURATION.createFixedDepositsProduct(fixedDepositProductData)
      .then(async (response) => {
        dispatch({
          type: CREATE_NEW_FD_PRODUCT_SUCCESS,
          payload: response,
        });
        dispatch(
          Toast({ text: "Fixed Deposit product created", icon: "success" })
        );
        history.push("/core/products/fixed-deposit-products");
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
              timer: 4000,
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(newFixedDepositsProductRequestFail(ex));
      });
  };
};

/*
 * GET Fixed Deposit Product Template
 */
const getFixedDepositsProductsTemplate = () => {
  return async (dispatch) => {
    dispatch({ type: ALL_FD_PRODUCTS_TEMPLATE_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_CONFIGURATION.getFixedDepositsProductsTemplate()
      .then((response) => {
        dispatch({
          type: ALL_FD_PRODUCTS_TEMPLATE_SUCCESS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(fixedDepositsProductTemplateFail(ex));
      });
    dispatch({ type: SET_LOADING_STATE, payload: false });
  };
};

/*
 * GET Fixed Deposit Product
 */
const getFixedDepositsProduct = (id) => {
  return async (dispatch) => {
    dispatch({ type: FD_PRODUCT_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_CONFIGURATION.getFixedDepositsProduct(id)
      .then((response) => {
        dispatch({ type: FD_PRODUCT_SUCCESS, payload: response });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(aFixedDepositProductRequestFail(ex));
      });
    dispatch({ type: SET_LOADING_STATE, payload: false });
  };
};

const editFixedDepositTemplate = (dataToSend, id, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await editAFixedDepositTemplate(dataToSend, id)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(
          Toast({
            text: "Fixed Deposit Product Successfully Updated",
            icon: "success",
          })
        );
        history.push("/core/products/fixed-deposit-products");
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const getFixedDepositProduct = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: SET_ONE_FIXED_DEPOSIT });
    await getAFixedDepositProduct(id)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: SET_ONE_FIXED_DEPOSIT, payload: response });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const createFixedDepositAccountTemplate = (id, params) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: SET_FIXED_DEPOSIT_ACCOUNT_TEMPLATE });
    await createAFixedDepositAccountTemplate(id, params)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({
          type: SET_FIXED_DEPOSIT_ACCOUNT_TEMPLATE,
          payload: response,
        });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

// Modify Fixed Deposit Account

const updateFixedDepositAccount = (data, id, history, clientId) => {
  return async (dispatch) => {
    // try {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: UPDATE_FD_ACCOUNT_REQUEST });
    await editFixedDepositAccountInfo(data, id)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: UPDATE_FD_ACCOUNT_SUCCESS, payload: response });
        dispatch(
          Toast({ text: "Fixed Deposit Account info updated", icon: "success" })
        );
        history.goBack();
      })
      .catch((ex) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(updateFdAccountFail(ex));
      });
  };
};
const updateFdAccountFail = (payload) => {
  return { type: UPDATE_FD_ACCOUNT_FAIL, payload };
};

//Get template for create FD Account
const getFixedDepositAccountTemplates = (id, params) => {
  return async (dispatch) => {
    dispatch({ type: ALL_FIXEDDEPOSIT_PRODUCTS_TEMPLATE_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_CONFIGURATION.getFixedDepositAccountTemplates(id, params)
      .then((response) => {
        dispatch({
          type: ALL_FIXEDDEPOSIT_PRODUCTS_TEMPLATE_SUCCESS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(fixedDepositProductTemplateFail(ex));
      });
    dispatch({ type: SET_LOADING_STATE, payload: false });
  };
};

const fixedDepositProductTemplateFail = (payload) => {
  return { type: ALL_FIXEDDEPOSIT_PRODUCTS_TEMPLATE_FAIL, payload };
};

const fixedDepositAccountTemplateReset = () => {
  return (dispatch) => {
    dispatch({ type: RESET_ALL_FIXEDDEPOSIT_PRODUCTS_TEMPLATE });
  };
};

const createFixedDepositAccounts = (dataToSend, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await createAFixedDepositAccount(dataToSend)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        history.push(`/core/client/${response.clientId}`);
        dispatch(
          Toast({
            text: "Fixed Deposit Acount Created Successfully",
            icon: "success",
          })
        );
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const getLedgerTransactions = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_FD_LEDGER_DETAILS });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getLedgerTransactionsDetails(id)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({
          type: SET_FD_LEDGER_DETAILS,
          payload: response,
        });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const getLedgerTransactionsForLoan = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_FD_LEDGER_DETAILS });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getLedgerTransactionsForLoanDetails(id)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({
          type: SET_FD_LEDGER_DETAILS,
          payload: response,
        });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const getFixedDepositAccountDetails = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_FIXED_DEPOSIT_ACCOUNT_DETAILS });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getAFixedDepositAccountDetails(id)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({
          type: SET_FIXED_DEPOSIT_ACCOUNT_DETAILS,
          payload: response,
        });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const getFixedAccountTransactions = (id, dataToSend, params) => {
  return async (dispatch) => {
    dispatch({ type: SET_FIXED_TRANSACTIONS });
    dispatch({ type: SET_TABLE_LOADING_STATE, payload: true });
    await getOneFixedAccountTransactions(id, dataToSend, params)
      .then((response) => {
        dispatch({
          type: SET_FIXED_TRANSACTIONS,
          payload: response,
        });
        dispatch({ type: SET_TABLE_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch({ type: SET_TABLE_LOADING_STATE, payload: false });
      });
  };
};

const approveFixedDepositAccount = (
  id,
  dataToSend,
  history,
  onHide,
  action,
  func
) => {
  return async (dispatch) => {
    // console.log("CALLED approveFixedDepositAccount");
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await approveAFixedDepositAccount(id, dataToSend, action)
      .then((response) => {
        dispatch(Toast({ text: "Account Approved", icon: "success" }));
        dispatch({ type: SET_LOADING_STATE, payload: false });
        onHide();
        dispatch(getCustomerAccounts(response.clientId));
        dispatch(getFixedDepositAccountDetails(response.savingsId));
        dispatch({ type: SET_FIXED_DEPOSIT_ACTIONS, payload: response });
        // console.log('payload', response);

        // if(func2){
        //   func2();
        //   }
      })
      .catch((ex) => {
        // console.log('CALLED approveFixedDepositAccount ERROR')

        handleError(ex, dispatch);
      });
  };
};
const calculatePrematureFixedDepositAccount = (
  id,
  dataToSend,
  history,
  action,
  func
) => {
  return async (dispatch) => {
    // console.log("CALLED approveFixedDepositAccount");
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await approveAFixedDepositAccount(id, dataToSend, action)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });

        dispatch({ type: SET_FIXED_DEPOSIT_ACTIONS, payload: response });
      })
      .catch((ex) => {
        // console.log('CALLED approveFixedDepositAccount ERROR')

        handleError(ex, dispatch);
      });
  };
};

const addChargesFixedDepositAccount = (
  id,
  dataToSend,
  history,
  action,
  onHide,
  func
) => {
  return async (dispatch) => {
    // console.log("CALLED approveFixedDepositAccount");
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await approveAFixedDepositAccount(id, dataToSend, action)
      .then((response) => {
        dispatch(
          Toast({ text: "Charges Added Successfully ", icon: "success" })
        );
        dispatch({ type: SET_LOADING_STATE, payload: false });
        onHide();
        dispatch(getCustomerAccounts(response.clientId));
        dispatch(getFixedDepositAccountDetails(response.savingsId));
        dispatch({ type: SET_FIXED_DEPOSIT_ACTIONS, payload: response });
        // console.log('payload', response);

        // if(func2){
        //   func2();
        //   }
      })
      .catch((ex) => {
        // console.log('CALLED approveFixedDepositAccount ERROR')

        handleError(ex, dispatch);
      });
  };
};

const closeFixedDepositAccount = (
  id,
  dataToSend,
  history,
  onHide,
  action,
  func
) => {
  return async (dispatch) => {
    // console.log("CALLED approveFixedDepositAccount");
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await approveAFixedDepositAccount(id, dataToSend, action)
      .then((response) => {
        dispatch(Toast({ text: "Closed Successfully", icon: "success" }));
        dispatch({ type: SET_LOADING_STATE, payload: false });
        onHide();
        dispatch(getCustomerAccounts(response.clientId));
        dispatch(getFixedDepositAccountDetails(response.savingsId));
        dispatch({ type: SET_FIXED_DEPOSIT_ACTIONS, payload: response });

        // if(func2){
        //   func2();
        //   }
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const activateFixedDepositAccount = (
  id,
  dataToSend,
  history,
  onHide,
  action,
  func
) => {
  return async (dispatch) => {
    // console.log("CALLED approveFixedDepositAccount");
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await approveAFixedDepositAccount(id, dataToSend, action)
      .then((response) => {
        dispatch(
          Toast({ text: "Account Activated Successfully ", icon: "success" })
        );
        dispatch({ type: SET_LOADING_STATE, payload: false });
        onHide();
        dispatch(getCustomerAccounts(response.clientId));
        dispatch(getFixedDepositAccountDetails(response.savingsId));
        dispatch({ type: SET_FIXED_DEPOSIT_ACTIONS, payload: response });
        // console.log('payload', response);

        // if(func2){
        //   func2();
        //   }
      })
      .catch((ex) => {
        // console.log('CALLED approveFixedDepositAccount ERROR')

        handleError(ex, dispatch);
      });
  };
};

const undoFixedDepositAccount = (
  id,
  dataToSend,
  history,
  onHide,
  action,
  func
) => {
  return async (dispatch) => {
    // console.log("CALLED approveFixedDepositAccount");
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await approveAFixedDepositAccount(id, dataToSend, action)
      .then((response) => {
        dispatch(Toast({ text: "Undo Successfully ", icon: "success" }));
        dispatch({ type: SET_LOADING_STATE, payload: false });
        onHide();
        dispatch(getCustomerAccounts(response.clientId));
        dispatch(getFixedDepositAccountDetails(response.savingsId));
        dispatch({ type: SET_FIXED_DEPOSIT_ACTIONS, payload: response });
      })
      .catch((ex) => {
        // console.log('CALLED approveFixedDepositAccount ERROR')

        handleError(ex, dispatch);
      });
  };
};
const postInterestFixedDepositAccount = (
  id,
  dataToSend,
  history,
  action,
  func
) => {
  return async (dispatch) => {
    // console.log("CALLED approveFixedDepositAccount");
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await approveAFixedDepositAccount(id, dataToSend, action)
      .then((response) => {
        dispatch(
          Toast({ text: "Interest Posted Successfully ", icon: "success" })
        );
        dispatch({ type: SET_LOADING_STATE, payload: false });
        // onHide();
        dispatch(getCustomerAccounts(response.clientId));
        dispatch(getFixedDepositAccountDetails(response.savingsId));
        dispatch({ type: SET_FIXED_DEPOSIT_ACTIONS, payload: response });
      })
      .catch((ex) => {
        // console.log('CALLED approveFixedDepositAccount ERROR')

        handleError(ex, dispatch);
      });
  };
};

const rejectFixedDepositAccount = (
  id,
  dataToSend,
  history,
  onHide,
  action,
  func
) => {
  return async (dispatch) => {
    // console.log("CALLED approveFixedDepositAccount");
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await approveAFixedDepositAccount(id, dataToSend, action)
      .then((response) => {
        dispatch(Toast({ text: `${action} Successfully `, icon: "success" }));
        dispatch({ type: SET_LOADING_STATE, payload: false });
        onHide();
        dispatch(getCustomerAccounts(response.clientId));
        dispatch(getFixedDepositAccountDetails(response.savingsId));
        dispatch({ type: SET_FIXED_DEPOSIT_ACTIONS, payload: response });
        // console.log('payload', response);
      })
      .catch((ex) => {
        // console.log('CALLED approveFixedDepositAccount ERROR')

        handleError(ex, dispatch);
      });
  };
};

const prematureCloseFixedDepositAccount = (
  id,
  dataToSend,
  history,
  onHide,
  action,
  func
) => {
  return async (dispatch) => {
    // console.log("CALLED approveFixedDepositAccount");
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await approveAFixedDepositAccount(id, dataToSend, action)
      .then((response) => {
        dispatch(
          Toast({ text: "Premature Closure Successful ", icon: "success" })
        );
        dispatch({ type: SET_LOADING_STATE, payload: false });
        onHide();
        console.log(response, "response");
        dispatch(getCustomerAccounts(response.clientId));
        dispatch(getFixedDepositAccountDetails(response.savingsId));
        dispatch(resetFixedDepositAccountAction());
      })
      .catch((ex) => {
        // console.log('CALLED approveFixedDepositAccount ERROR')

        handleError(ex, dispatch);
      });
  };
};

const resetFixedDepositAccountAction = () => {
  return (dispatch) => {
    dispatch({ type: SET_FIXED_DEPOSIT_ACTIONS_RESET });
  };
};

const addFixedDepositAccountCharge = (id, dataToSend, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await addAFixedDepositAccountCharge(id, dataToSend)
      .then((response) => {
        dispatch(
          Toast({
            text: "Successfully Added Charge",
            icon: "success",
          })
        );
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(getFixedDepositAccountDetails(response.savingsId));

        // history.push(
        //   `/core/client/fixed-deposit-account/${response.savingsId}`
        // );
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

// https://core.staging.woodcoreapp.com/woodcore/api/v1/savingsaccounts/136/charges/67?command=paycharge

const payFixedDepositAccountCharge = (
  id,
  chargeId,
  dataToSend,
  index,
  action
) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    try {
      const response = await payAFixedDepositAccountCharge(
        id,
        chargeId,
        dataToSend,
        action
      );

      dispatch({
        type: UPDATE_CHARGES,
        payload: { index },
      });
      dispatch(
        Toast({
          text: "Charges Paid Successfully",
          icon: "success",
        })
      );

      dispatch(getFixedDepositAccountDetails(id));

      return true;
    } catch (error) {
      // console.log(error, 'payloaddd')

      return false;
    } finally {
      dispatch({ type: SET_LOADING_STATE, payload: false });
    }
    // .then((response) => {

    //   // history.push(
    //   //   `/core/client/fixed-deposit-account/${response.savingsId}`
    //   //   )
    //   // history.push("/core/products/fixed-deposit-products");

    // })
  };
};

const waiveFixedDepositAccountCharge = (
  id,
  chargeId,
  dataToSend,
  index,
  action
) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    try {
      const response = await waiveAFixedDepositAccountCharge(
        id,
        chargeId,
        dataToSend,
        action
      );
      dispatch({
        type: WAIVE_CHARGES,
        payload: { index },
      });
      dispatch(
        Toast({
          text: "Charges Waived Successfully",
          icon: "success",
        })
      );

      dispatch(getFixedDepositAccountDetails(id));

      return true;
    } catch (error) {
      // console.log(error, 'payloaddd')

      return false;
    } finally {
      dispatch({ type: SET_LOADING_STATE, payload: false });
    }
    // .then((response) => {

    //   // history.push(
    //   //   `/core/client/fixed-deposit-account/${response.savingsId}`
    //   //   )
    //   // history.push("/core/products/fixed-deposit-products");

    // })
  };
};

const deteleFixedDepositAccount = (id, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await deleteAFixedDepositAccount(id)
      .then((response) => {
        dispatch(
          Toast({
            text: "Fixed Account Successfully Deleted",
            icon: "success",
          })
        );
        dispatch({ type: SET_LOADING_STATE, payload: false });
        history.push(`/core/client/${response.clientId}`);
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

// GENERATE FIXED DEPOSIT ACCOUNT FILE
const generateFDFile = (accountNo, funcTorun) => {
  return async (dispatch) => {
    dispatch({ type: GENERATE_FD_FILE_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await generateAFDFile(accountNo)
      .then((response) => {
        dispatch({
          type: GENERATE_FD_FILE_SUCCESS,
          payload: response,
        });

        dispatch({ type: SET_LOADING_STATE, payload: false });
        funcTorun();
        dispatch(
          Toast({
            text: "Generated File will open in a new tab",
            icon: "success",
            timer: 5000,
          })
        );
      })
      .catch((ex) => {
        handleError(ex, dispatch);
        dispatch(GenerateFDFileFail(ex));
      });
  };
};

const GenerateFDFileFail = (payload) => {
  return { type: GENERATE_FD_FILE_FAIL, payload };
};

//FILTER FD PRODUCTS
const filterFixedDepositProduct = (filterParam) => {
  return (dispatch) => {
    dispatch({ type: FILTER_FD_PRODUCT_REQUEST, payload: filterParam });
  };
};

const fixedDepositsProductRequestFail = (payload) => {
  return { type: ALL_FD_PRODUCT_FAIL, payload };
};
const newFixedDepositsProductRequestFail = (payload) => {
  return { type: CREATE_NEW_FD_PRODUCT_FAIL, payload };
};
const fixedDepositsProductTemplateFail = (payload) => {
  return { type: ALL_FD_PRODUCTS_TEMPLATE_FAIL, payload };
};
const aFixedDepositProductRequestFail = (payload) => {
  return { type: FD_PRODUCT_FAIL, payload };
};
// const updateSavingsProductFail = (payload) => {
//   return { type: UPDATE_SAVINGS_PRODUCT_FAIL, payload };
// };

//clear filtered loan products
const clearFilteredFDProducts = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_FD_PRODUCT_FILTER });
  };
};

//RESET FD PPRODUCT STATE
const resetFDProduct = () => {
  return (dispatch) => {
    dispatch({ type: FD_PRODUCT_RESET });
  };
};

//RESET NEW FD PRODUCT STATE
const resetNewFixedDepositProductState = () => {
  return (dispatch) => {
    dispatch({ type: CREATE_NEW_FD_PRODUCT_RESET });
  };
};

const resetGenerateFDFileState = () => {
  return (dispatch) => {
    dispatch({ type: RESET_GENERATE_FD_FILE });
  };
};

export {
  getFixedDeposits,
  getFixedDepositTemplate,
  createFixedDepositTemplate,
  getFixedDepositProduct,
  editFixedDepositTemplate,
  createFixedDepositAccountTemplate,
  createFixedDepositAccounts,
  getFixedDepositAccountDetails,
  getLedgerTransactions,
  getLedgerTransactionsForLoan,
  approveFixedDepositAccount,
  addFixedDepositAccountCharge,
  payFixedDepositAccountCharge,
  waiveFixedDepositAccountCharge,
  deteleFixedDepositAccount,
  getFixedAccountTransactions,
  resetFixedDepositAccountAction,
  getFixedDepositAccountTemplates,
  fixedDepositAccountTemplateReset,
  filterFixedDepositProduct,
  clearFilteredFDProducts,
  resetFDProduct,
  getAllFixedDepositsProducts,
  createFixedDepositsProduct,
  resetNewFixedDepositProductState,
  getFixedDepositsProductsTemplate,
  getFixedDepositsProduct,
  generateFDFile,
  resetGenerateFDFileState,
  addChargesFixedDepositAccount,
  closeFixedDepositAccount,
  activateFixedDepositAccount,
  undoFixedDepositAccount,
  rejectFixedDepositAccount,
  prematureCloseFixedDepositAccount,
  postInterestFixedDepositAccount,
  calculatePrematureFixedDepositAccount,
  updateFixedDepositAccount,
};
