/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Input } from "@chakra-ui/react";
import Toast from "../../../../util/Toast";

import { ContentWrapper, ContentCard, ActionButton } from "./styles";

import MessageTemplate from "./component/MessageTemplate";

import {
  addTermiApiKey,
  getTenantTermii,
} from "../../../../actions/notificationActions";

const Termii = () => {
  const dispatch = useDispatch();

  const allNotificationReducers = useSelector(
    (state) => state.allNotificationReducers
  );

  let { termiiDetails = [] } = allNotificationReducers || {};

  let mainTermiiDetails =
    termiiDetails && termiiDetails.length ? termiiDetails[0] : {};

  const [senderId, setSenderId] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [disableApiKey, setDisableApiKey] = useState(false);

  const [showTemplate, setShowTemplate] = useState(false);

  const handleShowTemplate = () => {
    setShowTemplate(true);
  };

  useEffect(() => {
    if (mainTermiiDetails && mainTermiiDetails.active) {
      setShowTemplate(true);
      setApiKey(mainTermiiDetails.api_key);
      if (mainTermiiDetails.api_key) setDisableApiKey(true);
      setSenderId(mainTermiiDetails.sender_name);
    }
  }, [mainTermiiDetails]);

  useEffect(() => {
    dispatch(getTenantTermii());
  }, []);

  const handleSubmitApiKey = () => {
    if (!senderId) {
      dispatch(
        Toast({ text: "Please add your termii Sender Name", icon: "info" })
      );
    } else if (!apiKey) {
      dispatch(Toast({ text: "Please add your termii Api Key", icon: "info" }));
    } else {
      // Send data
      let dataToSend = {
        api_key: apiKey,
        sender_name: senderId,
      };
      dispatch(
        addTermiApiKey(
          dataToSend,
          handleShowTemplate,
          mainTermiiDetails && mainTermiiDetails.api_key ? "PUT" : "POST"
        )
      );
    }
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Termii</h3>
            <p className="sub-title">
              Customize your own alert messages for your customers.
            </p>
            <p className="font-weight sub-title mt-2">
              <span className="text-info">
                <Link to="/core/notification">Notification Service</Link>
              </span>{" "}
              / Termii
            </p>
          </div>
        </div>
        <ContentWrapper>
          <div className="row ">
            <div className="col-sm-12 p-5 col-md-4 col-lg-3 border-right">
              <ContentCard>
                <img
                  className="card-img-right"
                  src={
                    require("../../../../assets/images/icons/termii.svg")
                      .default
                  }
                  alt="Termii"
                />
                <div className="body">
                  <p className="title">Termii</p>
                  <p className="description">
                    Define loan provisioning criteria for organization
                  </p>
                </div>
              </ContentCard>
            </div>
            <div className="col-sm-12 p-5 col-md-8 col-lg-9">
              <div className="row">
                <div className="col-md-8 col-lg-8 col-sm-12">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Sender Name</label>
                    <Input
                      style={{ width: "70%" }}
                      size="md"
                      name="senderId"
                      value={senderId}
                      onChange={(e) => setSenderId(e.target.value)}
                    />
                  </div>
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Api Key</label>
                    <Input
                      style={{ width: "70%" }}
                      size="md"
                      name="apiKey"
                      disabled={disableApiKey}
                      value={apiKey}
                      onChange={(e) => setApiKey(e.target.value)}
                    />
                  </div>
                  {!disableApiKey && (
                    <div className="spaceBetweenFlexEnd">
                      <ActionButton onClick={() => handleSubmitApiKey()}>
                        Submit
                      </ActionButton>
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                {showTemplate ? <MessageTemplate /> : ""}
              </div>
            </div>
          </div>
        </ContentWrapper>
      </div>
    </>
  );
};

export default Termii;
