import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store/store";
import App from "./App";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";

import "./index.scss";

const colors = {
  brand: {
    900: "#002c79",
    800: "#2C1DFF",
  },
};

const theme = extendTheme({ colors });

ReactDOM.render(
  <Provider store={store}>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </Provider>,
  document.getElementById("root")
);
