import React from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

const AppReview = ({ children }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12 col-md-12">
          <div
            style={{
              paddingBottom: "4.5rem",
              // borderBottom: "1px solid #E2E8F0",
            }}
          >
            <ProgressBar percent={100} style={{ padding: "" }}>
              <Step style={{ flexDirection: "column" }}>
                {({ accomplished, index }) => (
                  <>
                    <div
                      className={`indexedStep ${
                        accomplished ? "accomplished" : ""
                      }`}
                    >
                      {" "}
                    </div>
                    <div className="pt-3">
                      <h3>Details</h3>
                    </div>
                  </>
                )}
              </Step>
              <Step style={{ flexDirection: "column" }}>
                {({ accomplished, index }) => (
                  <>
                    <div
                      className={`indexedStep ${
                        accomplished ? "accomplished" : ""
                      }`}
                    >
                      <span></span>
                    </div>
                    <div className="pt-3">
                      <h3>Preview</h3>
                    </div>
                  </>
                )}
              </Step>
            </ProgressBar>
          </div>
        </div>
      </div>

      <div
        style={{ display: "flex", flexDirection: "column", marginTop: "5rem" }}
      >
        {children}
      </div>
    </div>
  );
};

export default AppReview;
