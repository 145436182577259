import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "react-bootstrap";

import { Input, Select } from "@chakra-ui/react";
import { Checkbox, Tooltip } from "antd";

import { getCodeValues } from "../../../../../actions/utilityActions";
import { getASingleDataTable } from "../../../../../actions/configurationActions";

import { toTitleCase } from "../../../../../util/Tools";

import {
  ContentWrapper,
  FormElements,
  IconButton,
  ActionButton,
} from "../styles";

import AddSvg from "../../../../../assets/images/icons/add.svg";

import Toast from "../../../../../util/Toast";
import { updateADataTable } from "../../../../../actions/configurationActions";

const EditDataTables = ({ history, match }) => {
  const dispatch = useDispatch();

  const allCodeValues = useSelector((state) => state.codeValues);
  let { codeValues } = allCodeValues;

  const dataTablesData = useSelector((state) => state.dataTables);

  let { oneDataTable = {} } = dataTablesData || {};

  let {
    registeredTableName = "",
    columnHeaderData = [],
    applicationTableName = "",
  } = oneDataTable || {};

  useEffect(() => {
    dispatch(getCodeValues());
    dispatch(getASingleDataTable(match.params.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [datatableName, setDatatableName] = useState("");
  const [apptableName, setApptableName] = useState("");

  const [newColomnTables, setNewColomnTables] = useState([]);
  const [newColomnTables2, setNewColomnTables2] = useState([]);
  const [checkedRow, setCheckedRow] = useState(oneDataTable?.searchColumn?.toLowerCase() ?? '0');

  const handleRowCheck = (isChecked, cellName) => {
    setCheckedRow(isChecked ? cellName?.toLowerCase() : '0');
  };

  useEffect(() => {
    if (oneDataTable) {
      setDatatableName(registeredTableName);
      setApptableName(applicationTableName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneDataTable]);

  let coloumData = [];

  columnHeaderData.length > 0 &&
    columnHeaderData.forEach((headerData) => {
      let {
        columnCode,
        columnLength,
        columnName,
        columnDisplayType,
        isColumnNullable,
      } = headerData || {};

      if (
        columnName !== "id" &&
        columnName !== "client_id" &&
        columnName !== "savings_account_id" &&
        columnName !== "loan_id"
      ) {
        coloumData.push({
          name: columnName,
          type:
            columnDisplayType === "CODELOOKUP"|| columnDisplayType === "DROPDOWN"
              ? "Dropdown"
              : columnDisplayType === "DATETIME"
                ? "DateTime"
                : columnDisplayType === "STRING" || columnDisplayType === "TEXT"
                  ? "Text"
                  : toTitleCase(columnDisplayType),
          lengths: columnLength,
          code: columnCode,
          mandatory: !isColumnNullable,
        });
      }
    });

  useEffect(() => {
    if (JSON.stringify(coloumData)) {
      setNewColomnTables(coloumData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(coloumData)]);

  const [name, setName] = useState("");
  const [type, setType] = useState("");

  const addDataTables = () => {
    if (!name) {
      dispatch(Toast({ text: "Please Add a Column Name", icon: "info" }));
    } else if (!type) {
      dispatch(Toast({ text: "Please Add a Column Type", icon: "info" }));
    } else {
      setName("");
      setType("");
      setNewColomnTables2([
        ...newColomnTables2,
        { name, type, mandatory: false },
      ]);
    }
  };

  const handleDataTableName = (value, index) => {
    let dataTableName = [...newColomnTables];
    dataTableName[Number(index)].newName = value;
    setNewColomnTables(dataTableName);
  };

  const handleNewDataTableName = (value, index) => {
    let dataTableName = [...newColomnTables2];
    dataTableName[Number(index)].name = value;
    setNewColomnTables2(dataTableName);
  };

  const handleDataTableType = (value, index) => {
    let dataTableType = [...newColomnTables];
    dataTableType[Number(index)].type = value;
    setNewColomnTables(dataTableType);
  };

  const handleNewDataTableType = (value, index) => {
    let dataTableType = [...newColomnTables2];
    dataTableType[Number(index)].type = value;
    setNewColomnTables2(dataTableType);
  };

  const handleDataTableMandatory = (value, index) => {
    let dataTableMandatory = [...newColomnTables];
    dataTableMandatory[Number(index)].mandatory = value;
    setNewColomnTables(dataTableMandatory);
  };

  const handleNewDataTableMandatory = (value, index) => {
    let dataTableMandatory = [...newColomnTables2];
    dataTableMandatory[Number(index)].mandatory = value;
    setNewColomnTables2(dataTableMandatory);
  };

  const handleDataTableLength = (value, index) => {
    let dataTableLength = [...newColomnTables];
    dataTableLength[Number(index)].lengths = value;
    dataTableLength[Number(index)].code = "";
    setNewColomnTables(dataTableLength);
  };

  const handleNewDataTableLength = (value, index) => {
    let dataTableLength = [...newColomnTables2];
    dataTableLength[Number(index)].lengths = value;
    dataTableLength[Number(index)].code = "";
    setNewColomnTables2(dataTableLength);
  };

  const handleDataTableCode = (value, index) => {
    let dataTableCode = [...newColomnTables];
    dataTableCode[Number(index)].newCode = value;
    dataTableCode[Number(index)].lengths = "";
    setNewColomnTables(dataTableCode);
  };

  const handleNewDataTableCode = (value, index) => {
    let dataTableCode = [...newColomnTables2];
    dataTableCode[Number(index)].newCode = value;
    dataTableCode[Number(index)].lengths = "";
    setNewColomnTables2(dataTableCode);
  };

  const handleChargeDelete = (id) => {
    setNewColomnTables(newColomnTables.filter((item, index) => index !== id));
  };

  const handleChargeDelete2 = (id) => {
    setNewColomnTables2(newColomnTables2.filter((item, index) => index !== id));
  };

  const handleSubmit = () => {
    if (!datatableName) {
      dispatch(Toast({ text: "Please Add a Data Table Name", icon: "info" }));
    } else if (!apptableName) {
      dispatch(
        Toast({ text: "Please Add an Applicable Table Name", icon: "info" })
      );
    } else {
      let columns = [];
      newColomnTables &&
        newColomnTables.length &&
        newColomnTables.forEach((colomnTable) => {
          let { name, newName, type, mandatory, lengths, code, newCode } =
            colomnTable;

          if (type === "Dropdown") {
            columns.push({
              name,
              newName: newName ? newName : name,
              // type,
              mandatory,
              code: code,
              newCode: newCode ? newCode : code,
            });
          } else {
            columns.push(lengths > 0 ? {
              name,
              newName: newName ? newName : name,
              // type,
              mandatory,
              length: lengths,
            } : {
              name,
              newName: newName ? newName : name,
              // type,
              mandatory,
            });
          }
        });
      let columns2 = [];
      newColomnTables2 &&
        newColomnTables2.length &&
        newColomnTables2.forEach((colomnTable) => {
          let { name, type, mandatory, lengths, code } = colomnTable;

          if (type === "Dropdown") {
            columns2.push({
              name,
              type,
              mandatory,
              code: code,
            });
          } else {
            columns2.push({
              name,
              type,
              mandatory,
              length: lengths,
            });
          }
        });

      let dataToSend = checkedRow ? {
        apptableName,
        changeColumns: columns,
        // changeColumns: columns?.length === 1 ? columns : columns?.slice(1),
        searchColumn: checkedRow,
      } : {
        apptableName,
        changeColumns: columns,
        // changeColumns: columns?.length === 1 ? columns : columns?.slice(1),
      };
      if (columns2 && columns2.length) {
        dataToSend.addColumns = columns2;
      }

      dispatch(updateADataTable(datatableName, dataToSend, history));
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6">
            <h3 className="page-title">Manage Data Tables</h3>
            <p className="sub-title">
              Add new extra fields to any entity in the form of data tables
            </p>
            <p className="font-weight sub-title">
              <span className="text-info">
                <Link to="/core/manage-data-tables">Manage Data Tables</Link>
              </span>
              / Edit Data Tables
            </p>
          </div>
        </div>
        <ContentWrapper>
          <FormElements>
            <div className="p-5 h-100">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Data Table Name*</label>
                    <Input
                      style={{ width: "60%" }}
                      size="md"
                      name="datatableName"
                      value={datatableName}
                      disabled
                      onChange={(e) => setDatatableName(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">
                      Application Table Name*
                    </label>
                    <Select
                      placeholder="Select One"
                      size="md"
                      name="apptableName"
                      value={apptableName}
                      onChange={(e) => setApptableName(e.target.value)}
                    >
                      <option value="m_client">Client</option>
                      <option value="m_group">Group</option>
                      <option value="m_center">Center</option>
                      <option value="m_loan">Loan</option>
                      <option value="m_office">Office</option>
                      <option value="m_savings_account">Savings Account</option>
                    </Select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Add Columns*</label>
                    <Input
                      style={{ width: "60%" }}
                      size="md"
                      placeholder="Column Name"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex">
                    <Select
                      placeholder="Select Colomn Type"
                      size="md"
                      name="type"
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                    >
                      <option value="Text">Text</option>
                      <option value="Number">Number</option>
                      <option value="Boolean">Boolean</option>
                      <option value="Date">Date</option>
                      <option value="DateTime">Date & time</option>
                      <option value="Dropdown">Dropdown</option>
                    </Select>
                    <div
                      onClick={() => addDataTables()}
                      className="btn-small ml-3"
                    >
                      <img
                        src={AddSvg}
                        className="icon-img"
                        alt=""
                        style={{ width: "20px", marginTop: "3px" }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {newColomnTables && newColomnTables.length > 0 ? (
                <>
                  <div className="row">
                    <div className="col-2">
                      <p className="fs-12 mt-2">Columns</p>
                    </div>
                    <div className="col-10 dataTables">
                      <Table className="child-table" border={1}>
                        <thead>
                          <tr style={{ backgroundColor: "#FAFAFA" }}>
                            <th>Name</th>
                            {/* <th>New Name</th> */}
                            <th>Type</th>
                            <th>Mandatory</th>
                            <th className="d-flex align-items-center">
                              Search by
                              <Tooltip title="When this column is checked, you would be able to search by that column name">
                                <img
                                  src={require("../../../../../assets/images/icons/info-circle-2.svg").default}
                                  width={18}
                                  height={18}
                                  alt="tooltip icon"
                                  className="ml-1 pointer"
                                />
                              </Tooltip>
                            </th>
                            <th>Length</th>
                            <th>Code</th>
                            {/* <th>New Code</th> */}
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {newColomnTables?.length > 0 &&
                            newColomnTables.filter(column => 
                              column.name !== "id" &&
                              column.name !== "client_id" &&
                              column.name !== "savings_account_id" &&
                              column.name !== "loan_id"
                            ).map((
                                { name, newName, type, lengths, searchColumn, code, newCode, mandatory },
                                index
                              ) => {
                                return (
                                  <tr key={index}>
                                    {/* <td>
                                      <Input
                                        style={{ width: "100%" }}
                                        size="md"
                                        name="datatableName"
                                        value={name}
                                        disabled
                                        onChange={(e) =>
                                          handleDataTableName(
                                            e.target.value,
                                            index
                                          )
                                        }
                                      />
                                    </td> */}
                                    <td>
                                      <Input
                                        style={{ width: "100%" }}
                                        size="md"
                                        name="newName"
                                        defaultValue={name}
                                        value={newName}
                                        onChange={(e) =>
                                          handleDataTableName(
                                            e.target.value,
                                            index
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <Select
                                        placeholder="Select Colomn Type"
                                        size="md"
                                        name="type"
                                        value={type}
                                        disabled
                                        style={{ width: "100% !important" }}
                                        onChange={(e) =>
                                          handleDataTableType(
                                            e.target.value,
                                            index
                                          )
                                        }
                                      >
                                        <option value="Text">Text</option>
                                        <option value="String">Text</option>
                                        <option value="Number">Number</option>
                                        <option value="Integer">Number</option>
                                        <option value="Decimal">Number</option>
                                        <option value="Boolean">Boolean</option>
                                        <option value="Date">Date</option>
                                        <option value="DateTime">Date & time</option>
                                        <option value="Dropdown">Dropdown</option>
                                      </Select>
                                    </td>
                                    <td>
                                      <Checkbox
                                        checked={mandatory}
                                        onChange={(e) =>
                                          handleDataTableMandatory(
                                            e.target.checked,
                                            index
                                          )
                                        }
                                      ></Checkbox>
                                    </td>
                                    <td>
                                      <Checkbox
                                        disabled={checkedRow != 0 && name?.toLowerCase() !== checkedRow}
                                        checked={name?.toLowerCase() === checkedRow}
                                        onChange={(event) => handleRowCheck(event?.target?.checked, name)}
                                      ></Checkbox>
                                    </td>
                                    <td>
                                      {type === "String" ? (
                                        <>
                                          <Input
                                            size="md"
                                            name="length"
                                            value={lengths}
                                            onChange={(e) =>
                                              handleDataTableLength(
                                                e.target.value,
                                                index
                                              )
                                            }
                                          />
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                    {/* <td>
                                      {type === "Dropdown" ? (
                                        <>
                                          <Select
                                            placeholder="Select Colomn Type"
                                            size="md"
                                            name="code"
                                            defaultValue={code}
                                            disabled
                                            style={{ width: "100% !important" }}
                                            onChange={(e) =>
                                              handleDataTableCode(
                                                e.target.value,
                                                index
                                              )
                                            }
                                          >
                                            {codeValues &&
                                              codeValues.length &&
                                              codeValues.map(({ name, id }) => {
                                                return (
                                                  <option value={name} id={id}>
                                                    {name}
                                                  </option>
                                                );
                                              })}
                                          </Select>{" "}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </td> */}
                                    <td>
                                      {type === "Dropdown" ? (
                                        <>
                                          <Select
                                            placeholder="Select Colomn Type"
                                            size="md"
                                            name="code"
                                            defaultValue={code}
                                            // value={newColomnTables[Number(index)].newCode}
                                            onChange={(e) =>
                                              handleDataTableCode(
                                                e.target.value,
                                                index
                                              )
                                            }
                                          >
                                            {codeValues &&
                                              codeValues.length &&
                                              codeValues.map(({ name, id }) => {
                                                return (
                                                  <option key={id} value={name} id={id}>
                                                    {name}
                                                  </option>
                                                );
                                              })}
                                          </Select>{" "}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                    {/* <td>
                                      {" "}
                                      <IconButton
                                        onClick={() =>
                                          handleChargeDelete(index)
                                        }
                                        id="deleteHoliday"
                                        className="mr-1 mb-1"
                                      >
                                        <img
                                          src={
                                            require("../../../../../assets/images/icons/delete.svg")
                                              .default
                                          }
                                          alt="add"
                                          className="icon-img"
                                        />
                                      </IconButton>
                                    </td> */}
                                  </tr>
                                );
                              }
                            )}
                          {newColomnTables2?.length > 0 &&
                            newColomnTables2.map(
                              ({ name, type, lengths, code, mandatory }, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <Input
                                        style={{ width: "100%" }}
                                        size="md"
                                        name="datatableName"
                                        value={name}
                                        onChange={(e) =>
                                          handleNewDataTableName(
                                            e.target.value,
                                            index
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <Select
                                        placeholder="Select Colomn Type"
                                        size="md"
                                        name="type"
                                        value={type}
                                        style={{ width: "100% !important" }}
                                        onChange={(e) =>
                                          handleNewDataTableType(
                                            e.target.value,
                                            index
                                          )
                                        }
                                      >
                                        <option value="Text">Text</option>
                                        <option value="Number">Number</option>
                                        <option value="Boolean">Boolean</option>
                                        <option value="Date">Date</option>
                                        <option value="DateTime">Date & time</option>
                                        <option value="Dropdown">Dropdown</option>
                                      </Select>
                                    </td>
                                    <td>
                                      <Checkbox
                                        checked={mandatory}
                                        onChange={(e) =>
                                          handleNewDataTableMandatory(
                                            e.target.checked,
                                            index
                                          )
                                        }
                                      ></Checkbox>
                                    </td>
                                    <td>
                                      <Checkbox
                                        disabled={checkedRow != 0 && name?.toLowerCase() !== checkedRow}
                                        checked={name?.toLowerCase() === checkedRow}
                                        onChange={(event) => handleRowCheck(event?.target?.checked, name)}
                                      ></Checkbox>
                                    </td>
                                    <td>
                                      {type === "String" ? (
                                        <>
                                          <Input
                                            size="md"
                                            name="length"
                                            value={lengths}
                                            onChange={(e) =>
                                              handleNewDataTableLength(
                                                e.target.value,
                                                index
                                              )
                                            }
                                          />
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                    <td>
                                      {type === "Dropdown" ? (
                                        <>
                                          <Select
                                            placeholder="Select Colomn Type"
                                            size="md"
                                            name="code"
                                            value={code}
                                            onChange={(e) =>
                                              handleNewDataTableCode(
                                                e.target.value,
                                                index
                                              )
                                            }
                                          >
                                            {codeValues &&
                                              codeValues.length &&
                                              codeValues.map(({ name, id }) => {
                                                return (
                                                  <option value={name} id={id}>
                                                    {name}
                                                  </option>
                                                );
                                              })}
                                          </Select>{" "}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                    <td>
                                      {" "}
                                      <IconButton
                                        onClick={() =>
                                          handleChargeDelete2(index)
                                        }
                                        id="deleteHoliday"
                                        className="mr-1 mb-1"
                                      >
                                        <img
                                          src={
                                            require("../../../../../assets/images/icons/delete.svg")
                                              .default
                                          }
                                          alt="add"
                                          className="icon-img"
                                        />
                                      </IconButton>
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              <div className="row">
                <div className="spaceBetweenFlexEnd w-100">
                  <ActionButton
                    onClick={() =>
                      history.push(
                        `/core/manage-data-tables/details/${datatableName}`
                      )
                    }
                    className="mb-3"
                  >
                    Cancel
                  </ActionButton>
                  <ActionButton onClick={() => handleSubmit()} className="mb-3">
                    Submit
                  </ActionButton>
                </div>
              </div>
            </div>
          </FormElements>
        </ContentWrapper>
      </div>
    </>
  );
};

export default EditDataTables;
