import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Checkbox, Input, Select, Textarea } from "@chakra-ui/react";
import { FormElements, ActionButton } from "../../styles";
import { ContentWrapper } from "./styles";
import {
  getAChartsOfAccount,
  getAllChartsOfAccountTemplate,
  updateChartsOfAccount,
} from "../../../../../actions/accountingActions";
import Toast from "../../../../../util/Toast";
import infoBg from "../../../../../assets/images/icons/info-circle.svg";

const EditGlAccount = ({ history }) => {
  const dispatch = useDispatch();
  const accountingData = useSelector((state) => state.accountingData);

  let { gottenChartOfAccount = {}, chartsOfAccountTemplate = {} } =
    accountingData || {};

  let { accountTypeOptions = [], usageOptions = [] } =
    chartsOfAccountTemplate || {};

  let { id, name } = gottenChartOfAccount || {};

  let { location = {} } = history || {};
  let { pathname = "" } = location;
  let splitedPathname = pathname.split("/", 10);
  let myId = splitedPathname[splitedPathname.length - 1];

  const [accType, setAccType] = useState("");
  const [accUsage, setAccUsage] = useState("");
  const [glCode, setGlCode] = useState("");
  const [parent, setParent] = useState("");
  const [accName, setAccName] = useState("");
  const [manualEntryAllowed, setManualEntryAllowed] = useState();
  const [tag, setTag] = useState();
  const [description, setDescription] = useState("");
  const [parentOptions, setParentOptions] = useState([]);
  const [tagOptions, setTagOptions] = useState("");
  const [isSystemDefined, setSystemDefined] = useState("");

  useEffect(() => {
    if (gottenChartOfAccount || accType) {
      if (Number(accType) === 1) {
        setParentOptions(
          gottenChartOfAccount && gottenChartOfAccount.assetHeaderAccountOptions
        );
      } else if (Number(accType) === 2) {
        setParentOptions(
          gottenChartOfAccount &&
            gottenChartOfAccount.liabilityHeaderAccountOptions
        );
      } else if (Number(accType) === 3) {
        setParentOptions(
          gottenChartOfAccount &&
            gottenChartOfAccount.equityHeaderAccountOptions
        );
      } else if (Number(accType) === 4) {
        setParentOptions(
          gottenChartOfAccount &&
            gottenChartOfAccount.incomeHeaderAccountOptions
        );
      } else if (Number(accType) === 5) {
        setParentOptions(
          gottenChartOfAccount &&
            gottenChartOfAccount.expenseHeaderAccountOptions
        );
      }
    }
  }, [gottenChartOfAccount, accType]);

  useEffect(() => {
    if (gottenChartOfAccount || accType) {
      if (Number(accType) === 1) {
        setTagOptions(
          gottenChartOfAccount && gottenChartOfAccount.allowedAssetsTagOptions
        );
      } else if (Number(accType) === 2) {
        setTagOptions(
          gottenChartOfAccount &&
            gottenChartOfAccount.allowedLiabilitiesTagOptions
        );
      } else if (Number(accType) === 3) {
        setTagOptions(
          gottenChartOfAccount && gottenChartOfAccount.allowedEquityTagOptions
        );
      } else if (Number(accType) === 4) {
        setTagOptions(
          gottenChartOfAccount && gottenChartOfAccount.allowedIncomeTagOptions
        );
      } else if (Number(accType) === 5) {
        setTagOptions(
          gottenChartOfAccount && gottenChartOfAccount.allowedExpensesTagOptions
        );
      }
    }
  }, [gottenChartOfAccount, accType]);

  useEffect(() => {
    if (gottenChartOfAccount) {
      setAccType(
        gottenChartOfAccount &&
          gottenChartOfAccount.type &&
          gottenChartOfAccount.type.id
      );
      setAccUsage(
        gottenChartOfAccount &&
          gottenChartOfAccount.usage &&
          gottenChartOfAccount.usage.id
      );
      setGlCode(gottenChartOfAccount && gottenChartOfAccount.glCode);
      setAccName(gottenChartOfAccount && gottenChartOfAccount.name);
      setManualEntryAllowed(
        gottenChartOfAccount && gottenChartOfAccount.manualEntriesAllowed
      );
      setDescription(gottenChartOfAccount && gottenChartOfAccount.description);
      setParent(gottenChartOfAccount && gottenChartOfAccount.parentId);
      setTag(gottenChartOfAccount && gottenChartOfAccount?.tagId?.id);
      setSystemDefined(
        gottenChartOfAccount && gottenChartOfAccount?.isSystemDefined
      );
    }
  }, [gottenChartOfAccount]);


  useEffect(() => {
    dispatch(getAChartsOfAccount(myId));
    dispatch(getAllChartsOfAccountTemplate());
  }, []);

  const handleEditGlAccount = (e) => {
    e.preventDefault();
    if (!accType) {
      dispatch(Toast({ text: "Please select an account type", icon: "info" }));
    } else if (!accUsage) {
      dispatch(Toast({ text: "Please select account usage", icon: "info" }));
    } else if (accUsage === "2" && manualEntryAllowed === "true") {
      dispatch(
        Toast({
          text: "Account Header does not allow manual entry",
          icon: "info",
        })
      );
    } else if (!glCode) {
      dispatch(Toast({ text: "Please add a Gl Code", icon: "info" }));
    } else if (!accName) {
      dispatch(Toast({ text: "Please add an account name", icon: "info" }));
    } else if (!tag) {
      dispatch(Toast({ text: "Please Select a Tag", icon: "info" }));
    } else {
      let dataToSend = {
        description,
        glCode,
        manualEntriesAllowed: manualEntryAllowed,
        name: accName.toUpperCase(),
        type: accType,
        usage: accUsage,
        parentId: parent,
        tagId: tag,
        isSystemDefined
      };
      dispatch(updateChartsOfAccount(dataToSend, Number(id), history));
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Edit GL Account</h3>
            <p className="sub-title mt-2">
              <span className="text-info mr-1">
                <Link to="/core/charts-of-account">Charts of Accounts</Link>
              </span>{" "}
              /{" "}
              {!!name ? (
                <span className="text-info mr-1">
                  <Link to="/core/charts-of-account">
                    {name} - {glCode} /
                  </Link>
                </span>
              ) : null}
              Edit Account
            </p>
          </div>
        </div>
        <ContentWrapper>
          <div className="row h-100">
            <div className="col-md-12 col-xl-6">
              <FormElements>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Account Type</label>
                  <Select
                    placeholder="Select Account Type"
                    size="md"
                    name="accType"
                    value={accType}
                    onChange={(e) => setAccType(e.target.value)}
                  >
                    {!!accountTypeOptions ? (
                      accountTypeOptions.map(({ id, code, value }) => {
                        return (
                          <option key={id} value={id}>
                            {value}
                          </option>
                        );
                      })
                    ) : (
                      <option>Loading...</option>
                    )}
                  </Select>
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Account Usage</label>
                  <Select
                    placeholder="Select Header or Detail"
                    size="md"
                    name="accUseage"
                    value={accUsage}
                    onChange={(e) => setAccUsage(e.target.value)}
                  >
                    {!!usageOptions ? (
                      usageOptions.map(({ id, code, value }) => {
                        return (
                          <option key={id} value={id}>
                            {value}
                          </option>
                        );
                      })
                    ) : (
                      <option>loading...</option>
                    )}
                  </Select>
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Gl Code*</label>
                  <Input
                    style={{ width: "60%" }}
                    size="md"
                    name="glCode"
                    value={glCode}
                    onChange={(e) => setGlCode(e.target.value)}
                    disabled
                  />
                </div>
              </FormElements>
            </div>{" "}
            <div className="col-md-12 col-xl-6">
              <FormElements>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Parent</label>
                  <Select
                    placeholder="Select Parent"
                    size="md"
                    name="parent"
                    value={parent}
                    onChange={(e) => setParent(e.target.value)}
                  >
                    {!!parentOptions &&
                      parentOptions.length &&
                      parentOptions.map((parent) => {
                        return (
                          <option value={parent.id} key={parent.id}>
                            {parent.name}
                          </option>
                        );
                      })}
                  </Select>
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Account Name*</label>
                  <Input
                    style={{ width: "60%", textTransform: "uppercase" }}
                    size="md"
                    name="accName"
                    value={accName}
                    onChange={(e) => setAccName(e.target.value)}
                  />
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Manual entries allowed</label>
                  <Select
                    placeholder="Select Yes or No"
                    size="md"
                    name="manualEntryAllowed"
                    value={manualEntryAllowed}
                    onChange={(e) => setManualEntryAllowed(e.target.value)}
                  >
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </Select>
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Tags*</label>
                  <Select
                    placeholder="Select Tag"
                    size="md"
                    name="tag"
                    value={tag}
                    onChange={(e) => setTag(e.target.value)}
                  >
                    {!!tagOptions && tagOptions.length
                      ? tagOptions.map(({ id, name }) => {
                          return (
                            <option value={id} key={id}>
                              {name}
                            </option>
                          );
                        })
                      : null}
                    {/* <option>Tag 1</option>
                    <option>Tag 2</option> */}
                  </Select>
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">
                    Add system defined ledger
                  </label>
                  <div className="flexBox-info">
                    <Checkbox
                      size="md"
                      name="isSystemDefinedLedger"
                      isChecked={isSystemDefined}
                      onChange={(e) => setSystemDefined(e.target.checked)}
                    />
                    <div
                      className="info-system"
                      title="Postings are only allowed via APIs and manual entries should be allowed"
                    >
                      <img src={infoBg} alt="info" />
                    </div>
                  </div>
                </div>
              </FormElements>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormElements>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Description</label>
                  <Textarea
                    size="md"
                    style={{
                      width: "80%",
                      marginTop: "20px",
                      fontSize: "12px",
                    }}
                    resize={"none"}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </FormElements>
            </div>
          </div>
          <div className="row">
            <div className="button-right">
              <ActionButton
                color="#2C1DFF"
                className="float-right"
                style={{ margin: "0px 25px" }}
                onClick={(e) => handleEditGlAccount(e)}
              >
                <img
                  src={
                    require("../../../../../assets/images/icons/check-mark.svg")
                      .default
                  }
                  alt="mark icon"
                />
                Submit
              </ActionButton>
              <ActionButton
                color="#2C1DFF"
                className="float-right"
                style={{ margin: "0px 0px" }}
                onClick={() => history.push("/core/charts-of-account")}
              >
                <img
                  src={
                    require("../../../../../assets/images/icons/cancel.svg")
                      .default
                  }
                  alt="add icon"
                />
                Cancel
              </ActionButton>
            </div>
          </div>
        </ContentWrapper>
      </div>
    </>
  );
};

export default EditGlAccount;
