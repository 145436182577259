import React, { useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import { Select } from '@chakra-ui/react';

import {
  FormClass,
  FormElements,
  ActionButton,
  IconButton,
} from '../styles.jsx';
import Toast from '../../../../../../util/Toast.js';
import DatePicker from 'react-datepicker';
import CurrencyFormat from 'react-currency-format';

const EditSavingsAccountCharges = ({
  template,
  charges,
  setCharges,
  loading,
  handleSubmit,
}) => {
  const [selectCharges, setSelectCharges] = useState('');

  const handleAddCharge = () => {
    if (selectCharges) {
      let newCharge = template.chargeOptions?.filter(
        (item) => item.id === Number(selectCharges)
      )[0];
      let check = charges?.filter((item) => item === newCharge);

      if (check?.length === 0) {
        setCharges((p) => [...p, newCharge]);
      } else {
        Toast({
          text: 'Already added!',
          icon: 'error',
          timer: 2000,
        });
      }
    } else {
      Toast({
        text: 'Please select a charge',
        icon: 'error',
        timer: 2000,
      });
    }
  };
  const handleChargeDelete = (id) => {
    setCharges(charges.filter((item) => item.id !== id));
  };

  const handleAmountChange = (index, amount, type) => {
    let hhh = [...charges];
    if (type === 'chargeCalculationType.flat') {
      hhh[Number(index)].amount = amount;
    } else if (type === 'chargeCalculationType.percent.of.amount') {
      hhh[Number(index)].percentage = amount;
      hhh[Number(index)].amount = amount;
    }
    setCharges(hhh);
  };

  const handleDateChange = (index, date, type) => {
    let hhh = [...charges];
    hhh[Number(index)].date = date;
    if (type === 'Annual Fee' || type === 'Monthly Fee') {
      hhh[Number(index)].feeOnMonthDay = date;
    } else hhh[Number(index)].dueDate = date;
    setCharges(hhh);
  };

  console.log(charges, 'charges');

  const handleRepaymentsChange = (index, feeInterval) => {
    let hhh = [...charges];
    hhh[Number(index)].feeInterval = feeInterval;
    setCharges(hhh);
  };

  return (
    <>
      <FormClass>
        <FormElements style={{ width: '60%', margin: '0 auto' }}>
          <div className="form-group d-flex justify-content-between align-items-center">
            {/* <label className="text-muted">Product</label> */}
            <Select
              name="selectCharges"
              value={selectCharges}
              onChange={(e) => setSelectCharges(e.target.value)}
              size="md"
            >
              <option value="">Select Charge</option>
              {template !== undefined && template
                ? template.chargeOptions &&
                  template.chargeOptions.map((cur) => (
                    <option value={cur.id} key={cur.id}>
                      {cur.name}
                    </option>
                  ))
                : null}
            </Select>
            <ActionButton
              color="#005BFC"
              style={{ margin: '0px 0px 0 10px' }}
              onClick={handleAddCharge}
            >
              Add Charge
            </ActionButton>
          </div>
        </FormElements>
        <Table className="child-table mt-5">
          <thead className="">
            <tr style={{ backgroundColor: '#EDEFF5' }}>
              <th>Name</th>
              <th>Type</th>
              <th>Amount</th>
              <th>Collected on</th>
              <th>Date</th>
              <th>Repayments Every</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {charges && charges?.length > 0
              ? charges?.map(
                  (
                    {
                      id,
                      name,
                      chargeCalculationType,
                      amount,
                      chargeId,
                      feeOnMonthDay,
                      dueDate,
                      chargeTimeType,
                      percentage,
                      date,
                      feeInterval,
                    },
                    index
                  ) => (
                    <tr key={id}>
                      <td>{name}</td>
                      <td>{chargeCalculationType?.value}</td>
                      {chargeCalculationType?.code ===
                          'chargeCalculationType.percent.of.amount' && (
                          <td>
                            <CurrencyFormat
                              value={percentage}
                              name="min_pricipal"
                              thousandSeparator={true}
                              className="amount-input"
                              style={{
                                width: '60%',
                              }}
                              onValueChange={(Values) => {
                                const { value } = Values;
                                handleAmountChange(
                                  index,
                                  value,
                                  chargeCalculationType?.code
                                );
                              }}
                            />
                          </td>
                        )}
                        {chargeCalculationType?.code ===
                          'chargeCalculationType.flat' && (
                          <td>
                            <CurrencyFormat
                              value={amount}
                              name="min_pricipal"
                              thousandSeparator={true}
                              className="amount-input"
                              style={{
                                width: '60%',
                              }}
                              onValueChange={(Values) => {
                                const { value } = Values;
                                handleAmountChange(
                                  index,
                                  value,
                                  chargeCalculationType?.code
                                );
                              }}
                            />
                          </td>
                        )}
                      <td>{chargeTimeType?.value}</td>
                      <td>
                        {chargeTimeType?.value === 'Specified due date' ||
                        chargeTimeType?.value === 'Weekly Fee' ? (
                          <DatePicker
                            id="start-date"
                            selected={dueDate ? new Date(dueDate) : ''}
                            onChange={(date) =>
                              handleDateChange(
                                index,
                                date,
                                chargeTimeType?.value
                              )
                            }
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            placeholderText="dd/mmm/yyyy"
                          />
                        ) : chargeTimeType?.value === 'Annual Fee' ||
                          chargeTimeType?.value === 'Monthly Fee' ? (
                          <DatePicker
                            id="start-date"
                            dateFormat="dd/MM"
                            selected={new Date(feeOnMonthDay)}
                            onChange={(date) =>
                              handleDateChange(
                                index,
                                date,
                                chargeTimeType?.value
                              )
                            }
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            placeholderText="dd/mmm/yyyy"
                          />
                        ) : (
                          ''
                        )}
                      </td>
                      <td>
                        {chargeTimeType?.value === 'Weekly Fee' ||
                        chargeTimeType?.value === 'Monthly Fee' ? (
                          <>
                            <CurrencyFormat
                              value={feeInterval}
                              name="repayments"
                              thousandSeparator={true}
                              className="amount-input"
                              style={{
                                width: '60%',
                              }}
                              onValueChange={(values) => {
                                const { value } = values;
                                handleRepaymentsChange(index, value);
                              }}
                            />
                          </>
                        ) : null}
                      </td>

                      <td>
                        <IconButton
                          onClick={() => handleChargeDelete(id)}
                          id="deleteHoliday"
                          className="mr-1 mb-1"
                        >
                          <img
                            src={
                              require('../../../../../../assets/images/icons/delete.svg')
                                .default
                            }
                            alt="add"
                          />
                        </IconButton>
                      </td>
                    </tr>
                  )
                )
              : null}
          </tbody>
        </Table>
        <div className="float-right pt-4">
          <ActionButton
            color="#005BFC"
            style={{ margin: '0px 0px 0 10px' }}
            onClick={handleSubmit}
          >
            {loading ? (
              <Spinner
                animation="border"
                color="#fff"
                className="mr-2 text-white"
                size="sm"
              />
            ) : (
              <img
                src={
                  require('../../../../../../assets/images/icons/save.svg')
                    .default
                }
                alt="submit icon"
              />
            )}
            Submit
          </ActionButton>
        </div>
        <br />
        <br />
        <br />
        <br />
      </FormClass>
    </>
  );
};

export default EditSavingsAccountCharges;
