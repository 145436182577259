import API_TELLER from "../services/Api/teller.api.services";
import API_CUSTOMER from "../services/Api/customer.api.services";
import Toast from "../util/Toast";

import {
  SET_ALL_TELLER_GROUPS,
  SET_TELLER_CASHIER_DETAILS_RESET,
  SET_SINGLE_TELLER_GROUP_CASHIERS,
  SET_SINGLE_TELLER_GROUP_CASHIERS_RESET,
  SET_TELLER_CASHIER_DETAILS,
  SET_ALL_TELLERS,
  SET_ALL_OFFICE,
  SET_STAFF_IN_OFFICE,
  SET_TILLS,
  SET_ALL_CURRENCY,
  GET_CASHIER_TEMPLATE,
  GET_ALL_CASHIER_ACTION_REQUEST,
  RESET_ALL_CASHIER_ACTION,
  DELETE_TELLER_GROUP,
  DELETE_TELLER_GROUP_SUCCESS,
  DELETE_TELLER_CASHIER,
  DELETE_TELLER_CASHIER_SUCCESS,
  GET_CASHIER_TEMPLATE_RESET,
  UPDATE_TELLER_GROUP,
  UPDATE_TELLER_GROUP_SUCCESS,
  UPDATE_TELLER_CASHIER,
  GET_CASHIER_TRANSACTIONS,
  DEPOSIT_WITHDRAW_CASH,
  DEPOSIT_WITHDRAW_CASH_SUCCESS,
  DEPOSIT_WITHDRAW_CASH_RESET,
  GET_CASHIER_ACTION_REQUEST_RECEIVED,
  GET_CASHIER_ACTION_REQUEST_SENT,
  GET_CASHIER_ACTION_REQUEST_SENT_SUCCESS,
  GET_TRANSACTION_SUMMARY,
  GET_TRANSACTION_SUMMARY_SUCCESS,
  GET_CLIENT_IMAGE,
  GET_CLIENT_IMAGE_SUCCESS,
  GET_CLIENT_IMAGE_RESET,
  GET_DATA_TABLES,
  GET_DATA_TABLES_SUCCESS,
  GET_DATA_TABLES_FAIL,
  GET_CASHIERDATA_REQUEST,
  GET_CASHIERDATA_SUCCESS,
  GET_CASHIERDATA_FAIL,
  GET_CASHIERDATA_RESET,
  GET_CASHIERDATATELLER_REQUEST,
  GET_CASHIERDATATELLER_SUCCESS,
  GET_CASHIERDATATELLER_RESET
} from "../constants/tellerConstants";

import { SET_LOADING_STATE } from "../constants/utilityConstants";

const handleError = (ex, dispatch) => {
  if (ex.response) {
    if (ex.request.status !== 500) {
      dispatch(
        Toast({
          text: ex.response.data.errors
            ? ex.response.data.errors[0].defaultUserMessage
            : ex.response.data.defaultUserMessage,
          icon: "info",
        })
      );
    } else {
      dispatch(
        Toast({
          text: ex.response.data.errors
            ? ex.response.data.errors[0].defaultUserMessage
            : ex.response.data.defaultUserMessage,
          icon: "error",
        })
      );
    }
  } else if (ex.request) {
    if (ex.request.status !== 500) {
      dispatch(Toast({ text: String(ex), icon: "info" }));
    } else {
      dispatch(Toast({ text: String(ex), icon: "error" }));
    }
  } else {
    dispatch(Toast({ text: String(ex), icon: "error" }));
  }

  dispatch({ type: SET_LOADING_STATE, payload: false });
};

export const resetAllCashierActionRequests = () => {
  return async (dispatch) => {
    dispatch({ type: SET_SINGLE_TELLER_GROUP_CASHIERS_RESET });
  };
};
export const resetCustomerImage = () => {
  return async (dispatch) => {
    dispatch({ type: GET_CLIENT_IMAGE_RESET });
  };
};

export const resetDepositWithdrawResponse = () => {
  return async (dispatch) => {
    dispatch({ type: DEPOSIT_WITHDRAW_CASH_RESET });
  };
};
export const resetCashierTemplate = () => {
  return async (dispatch) => {
    dispatch({ type: GET_CASHIER_TEMPLATE_RESET });
  };
};
export const resetAllCashierActions = () => {
  return async (dispatch) => {
    dispatch({ type: RESET_ALL_CASHIER_ACTION });
  };
};
// CLEAR FILTERED CUSTOMERS LIST
export const resetTellerCashierDetails = () => {
  return (dispatch) => {
    dispatch({ type: SET_TELLER_CASHIER_DETAILS_RESET });
  };
};

export const resetCashierDataDetails = () => {
  return (dispatch) => {
    dispatch({ type: GET_CASHIERDATA_RESET });
  };
};
export const resetCashierDataDetailsTeller = () => {
  return (dispatch) => {
    dispatch({ type: GET_CASHIERDATATELLER_RESET });
  };
};

export const getAllTellerGroups = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_ALL_TELLER_GROUPS });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_TELLER.getAllTellerGroups(id)
      .then((response) => {
        // console.log("response", response);
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: SET_ALL_TELLER_GROUPS, payload: response });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
export const updateATellerGroup = (data, id, func) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_TELLER_GROUP });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_TELLER.updateTellerGroup(data, id)
      .then((response) => {
        if (func) {
          func();
        }
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: UPDATE_TELLER_GROUP_SUCCESS, payload: response });
        dispatch(
          Toast({ text: "Teller Group Updated Successfuly", icon: "success" })
        );
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
export const updateATellerCashier = (data, id, cashierId, func) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_TELLER_CASHIER });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_TELLER.updateTellerCashier(data, id, cashierId)
      .then((response) => {
        if (func) {
          func();
        }
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: UPDATE_TELLER_GROUP_SUCCESS, payload: response });
        dispatch(
          Toast({ text: "Teller Cashier Updated Successfuly", icon: "success" })
        );
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
export const getAllCashierActionRequests = (params) => {
  return async (dispatch) => {
    dispatch({ type: GET_ALL_CASHIER_ACTION_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_TELLER.getAllCashierActionRequests(params)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_ALL_CASHIER_ACTION_REQUEST, payload: response });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
export const getAllCashierActionRequestsReceived = (params) => {
  return async (dispatch) => {
    dispatch({ type: GET_CASHIER_ACTION_REQUEST_RECEIVED });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_TELLER.getAllCashierActionRequestReceived(params)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_ALL_CASHIER_ACTION_REQUEST, payload: response });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
export const getAllCashierTransactions = (params) => {
  return async (dispatch) => {
    dispatch({ type: GET_CASHIER_TRANSACTIONS });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_TELLER.getCashierTransactions(params)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_CASHIER_TRANSACTIONS, payload: response });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

export const getCashierData = (tellerId, cashierId) => {
  return async (dispatch) => {
    dispatch({ type: GET_CASHIERDATA_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_TELLER.getCashierDataDetails(tellerId, cashierId)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_CASHIERDATA_SUCCESS, payload: response });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
export const getCashierDataTeller = (tellerId, dropdownValue, cashierId) => {
  return async (dispatch) => {
    dispatch({ type: GET_CASHIERDATATELLER_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_TELLER.getCashierDataDetailsTeller(
      tellerId,
      dropdownValue,
      cashierId
    )
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_CASHIERDATATELLER_SUCCESS, payload: response });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

export const getAllCashierActionSent = (params) => {
  return async (dispatch) => {
    dispatch({ type: GET_CASHIER_ACTION_REQUEST_SENT });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_TELLER.getAllCashierActionRequest(params)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({
          type: GET_CASHIER_ACTION_REQUEST_SENT,
          payload: response,
        });
        dispatch({
          type: GET_CASHIER_ACTION_REQUEST_SENT_SUCCESS,
          payload: response,
        });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
export const getSingleTellerGroupCashier = (id, fromDate, toDate) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: SET_SINGLE_TELLER_GROUP_CASHIERS });
    await API_TELLER.getSingleTellerGroupCashier(id, fromDate, toDate)
      .then((response) => {
        // console.log("response", response);
        dispatch({ type: SET_SINGLE_TELLER_GROUP_CASHIERS, payload: response });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
export const getTellerCashierDetails = (tellerId, cashierId) => {
  return async (dispatch) => {
    dispatch({ type: SET_TELLER_CASHIER_DETAILS });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_TELLER.getTellerCashierDetails(tellerId, cashierId)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: SET_TELLER_CASHIER_DETAILS, payload: response });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
export const getTransactionSummary = (id, cashierId) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: GET_TRANSACTION_SUMMARY });
    await API_TELLER.getTransactionSummary(id, cashierId)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_TRANSACTION_SUMMARY_SUCCESS, payload: response });
        // console.log(response);
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
export const getAllTellers = () => {
  return async (dispatch) => {
    dispatch({ type: SET_ALL_TELLERS });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_TELLER.getAllTellers()
      .then((response) => {
        // console.log("response", response);
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: SET_ALL_TELLERS, payload: response.data });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

export const getCashierTemplate = (id) => {
  return async (dispatch) => {
    dispatch({ type: GET_CASHIER_TEMPLATE });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_TELLER.getCashierTemplate(id)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_CASHIER_TEMPLATE, payload: response });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

export const createTellerGroup = (dataToSend, func) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_TELLER.createTellerGroup(dataToSend)
      .then((response) => {
        func();
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(
          Toast({ text: "Teller Group Created Successfuly", icon: "success" })
        );
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
export const createTellerCashieraction = (dataToSend, id, func) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_TELLER.createTellerCashier(dataToSend, id)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(
          Toast({ text: "Teller Cashier Created Successfuly", icon: "success" })
        );
        if (func) {
          return func();
        }
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
export const approveRejectRequest = (
  id,
  command,
  note,
  cashierId,
  refetchRequests
) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_TELLER.approveRejectRequestToVaultCashier(id, command, note)
      .then((response) => {
        if (response) {
          refetchRequests(cashierId);
        }
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(
          Toast({
            text: `${command} request was successfully`,
            icon: "success",
          })
        );
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

export const approveRejectRequestMgr = (id, command, note, func) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_TELLER.approveRejectRequestToVaultCashier(id, command, note)
      .then((response) => {
        if (response) {
          func();
        }
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(
          Toast({
            text: `${command} request was successfully`,
            icon: "success",
          })
        );
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

export const createTeller = (dataToSend, func) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_TELLER.createTeller(dataToSend)
      .then((response) => {
        func();
        dispatch(getAllTellers());
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(
          Toast({ text: "Teller Created Successfuly", icon: "success" })
        );
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

export const getTills = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_TILLS, payload: [] });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_TELLER.getTills(id)
      .then((response) => {
        let { data } = response || {};
        // dispatch(getAllTellers());
        dispatch({ type: SET_LOADING_STATE, payload: false });
        if (data === null) {
          dispatch({ type: SET_TILLS, payload: [] });
        } else {
          dispatch({ type: SET_TILLS, payload: data });
        }
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

// export const handleCash = (dataToSend, type, func) => {
//   return async (dispatch) => {
//     dispatch({ type: SET_LOADING_STATE, payload: true });
//     await API_TELLER.handleCash(dataToSend, type)
//       .then((response) => {
//         dispatch({ type: SET_LOADING_STATE, payload: false });
//         dispatch(Toast({ text: "Transaction Successful", icon: "success" }));
//       })
//       .catch((ex) => {
//         handleError(ex, dispatch);
//       });
//   };
// };
export const buySellCash = (dataToSend) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_TELLER.handleBuySellCash(dataToSend)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(Toast({ text: "Transaction Successful", icon: "success" }));
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

export const createCashier = (dataToSend, func) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_TELLER.createCashier(dataToSend)
      .then((response) => {
        // dispatch(getAllTellers());
        let { data } = response || {};
        func();
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(
          Toast({
            text: data && data.length ? data : "Cashier Created Successfuly",
            icon: "success",
          })
        );
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

export const getAllOffices = () => {
  return async (dispatch) => {
    dispatch({ type: SET_ALL_OFFICE });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_TELLER.getAllOffices()
      .then((response) => {
        dispatch({
          type: SET_ALL_OFFICE,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

export const getStaffInOffice = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_STAFF_IN_OFFICE });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_TELLER.getStaffInOffice(id)
      .then((response) => {
        dispatch({
          type: SET_STAFF_IN_OFFICE,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

export const getCurrencies = () => {
  return async (dispatch) => {
    dispatch({ type: SET_ALL_CURRENCY });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_TELLER.getCurrencies()
      .then((response) => {
        dispatch({
          type: SET_ALL_CURRENCY,
          payload: response.selectedCurrencyOptions,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

export const deleteTellerGroup = (id) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_TELLER_GROUP });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_TELLER.deleteTellerGroup(id)
      .then((response) => {
        dispatch({
          type: DELETE_TELLER_GROUP_SUCCESS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(
          Toast({ text: "Teller group successfully deleted", icon: "success" })
        );
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
export const deleteTellerCashier = (tellerId, cashierId, func) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_TELLER_CASHIER });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_TELLER.deleteTellerCashier(tellerId, cashierId)
      .then((response) => {
        func(tellerId);
        dispatch({
          type: DELETE_TELLER_CASHIER_SUCCESS,
          payload: response,
        });
        dispatch(
          Toast({
            text: "Teller cashier successfully deleted",
            icon: "success",
          })
        );
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
export const depositWithDrawCash = (data, command, reFetchStat, reFetchCashierTransaction) => {
  return async (dispatch) => {
    dispatch({ type: DEPOSIT_WITHDRAW_CASH });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_TELLER.depositWithdrawCash(data, command)
      .then((response) => {
        if (reFetchStat) {
          reFetchStat();
        }
        if(reFetchCashierTransaction) {
          reFetchCashierTransaction();
        }
        dispatch({
          type: DEPOSIT_WITHDRAW_CASH_SUCCESS,
          payload: response,
        });
        dispatch(
          Toast({
            text: `${command} action was successful`,
            icon: "success",
            duration: "5",
          })
        );
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

//FETCH CUSTOMER IMAGE
export const getCustomerImage = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_CLIENT_IMAGE });
      const data = await API_CUSTOMER.getCustomerImage(id);
      dispatch({ type: GET_CLIENT_IMAGE_SUCCESS, payload: data });
    } catch (ex) {
      return ex;
    }
  };
};
export const getDataTablesAction = (id) => {
  return async (dispatch) => {
    dispatch({ type: GET_DATA_TABLES });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_TELLER.getDataTables(id)
      .then((response) => {
        dispatch({ type: GET_DATA_TABLES_SUCCESS, payload: response });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        dispatch({
          type: GET_DATA_TABLES_FAIL,
          payload:
            ex.response.data.errors ||
            ex.response.data.errors[0].defaultUserMessage ||
            ex.response.data.defaultUserMessage,
        });
        handleError(ex, dispatch);
      });
  };
};
