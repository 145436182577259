import React from "react";
// import { useSelector } from "react-redux";
import { Select, Input, FormControl, Checkbox } from "@chakra-ui/react";

import { FormClass, FormElements, NextActionButton } from "../../../styles.jsx";
import { REACT_SELECT_CUSTOM_STYlES } from "../../../../../../../../constants/formConstants.js";
import ReactSelect from "react-select";

const FixedSettings = ({
  handleNextScreen,
  handlePreviousScreen,
  lockinPeriodFrequency,
  lockinPeriodFrequencyType,
  minDepositTerm,
  minDepositTermTypeId,
  maxDepositTerm,
  maxDepositTermTypeId,
  preClosurePenalInterest,
  preClosurePenalInterestOnTypeId,
  taxGroupId,
  inMultiplesOfDepositTerm,
  inMultiplesOfDepositTermTypeId,
  withHoldTax,
  preClosurePenalApplicable,
  createFixedDepositProductHandler,
  createFixedDepositProductHandlerForSelect,
  template,
  setPreClosurePenalApplicable,
  setWithHoldTax,
  renderTooltip,
}) => {
  return (
    <>
      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          {/* Lock-In Period */}
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Lock-In Period {renderTooltip("Lock-in Period")}
                </label>
                <div
                  style={{ width: "60%" }}
                  className="d-flex gap-4 justify-content-between align-items-start"
                >
                  <Input
                    style={{ width: "28%" }}
                    size="md"
                    name="lockinPeriodFrequency"
                    type="number"
                    value={lockinPeriodFrequency}
                    onChange={createFixedDepositProductHandler}
                  />
                  <div style={{ width: "70%" }}>
                    <ReactSelect
                      isClearable={true}
                      className="fs-12"
                      styles={REACT_SELECT_CUSTOM_STYlES}
                      name="lockinPeriodFrequencyType"
                      placeholder="Select frequency"
                      onChange={(period, { name }) => createFixedDepositProductHandlerForSelect(name, period?.value)}
                      defaultValue={{
                        value: template?.lockinPeriodFrequencyTypeOptions[0]?.id,
                        label: template?.lockinPeriodFrequencyTypeOptions[0]?.value,
                      }}
                      options={template
                        ?.lockinPeriodFrequencyTypeOptions
                        ?.map((item) => ({
                          label: item.value,
                          value: item.id,
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0">
          {/* Minimum Deposit Term* */}
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-end">
                <label className="text-label d-flex flex-align-center">
                  Minimum Deposit Term*
                  {renderTooltip("Minimum Deposit Term")}
                </label>
                <div
                  style={{ width: "60%" }}
                  className="d-flex gap-4 justify-content-between align-items-start"
                >
                  <Input
                    style={{ width: "28%" }}
                    size="md"
                    name="minDepositTerm"
                    type="number"
                    value={minDepositTerm}
                    onChange={createFixedDepositProductHandler}
                  />
                  <div style={{ width: "70%" }}>
                    <ReactSelect
                      isClearable={true}
                      className="fs-12"
                      styles={REACT_SELECT_CUSTOM_STYlES}
                      name="minDepositTermTypeId"
                      placeholder="Select term"
                      onChange={(period, { name }) => createFixedDepositProductHandlerForSelect(name, period?.value)}
                      defaultValue={{
                        value: template?.periodFrequencyTypeOptions[0]?.id,
                        label: template?.periodFrequencyTypeOptions[0]?.value,
                      }}
                      options={template
                        ?.periodFrequencyTypeOptions
                        ?.map((item) => ({
                          label: item.value,
                          value: item.id,
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>

      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          {/* And Therafter In Multiple Of */}
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-start">
                <label className="text-label d-flex flex-align-center">
                  And Thereafter In Multiple Of
                  {renderTooltip("And Thereafter In Multiple Of")}
                </label>
                <div
                  style={{ width: "60%" }}
                  className="d-flex gap-4 justify-content-between align-items-start"
                >
                  <Input
                    style={{ width: "28%" }}
                    size="md"
                    name="inMultiplesOfDepositTerm"
                    type="number"
                    value={inMultiplesOfDepositTerm}
                    onChange={createFixedDepositProductHandler}
                  />
                  <div style={{ width: "70%" }}>
                    <ReactSelect
                      isClearable={true}
                      className="fs-12"
                      styles={REACT_SELECT_CUSTOM_STYlES}
                      name="inMultiplesOfDepositTermTypeId"
                      placeholder="Select term"
                      onChange={(period, { name }) => createFixedDepositProductHandlerForSelect(name, period?.value)}
                      defaultValue={{
                        value: template?.periodFrequencyTypeOptions[0]?.id,
                        label: template?.periodFrequencyTypeOptions[0]?.value,
                      }}
                      options={template
                        ?.periodFrequencyTypeOptions
                        ?.map((item) => ({
                          label: item.value,
                          value: item.id,
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0">
          {/* Maximum Deposit Term */}
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-end">
                <label className="text-label d-flex flex-align-center">
                  Maximum Deposit Term
                  {renderTooltip("Maximum Deposit Term")}
                </label>
                <div
                  style={{ width: "60%" }}
                  className="d-flex gap-4 justify-content-between align-items-start"
                >
                  <Input
                    style={{ width: "28%" }}
                    size="md"
                    name="maxDepositTerm"
                    type="number"
                    value={maxDepositTerm}
                    onChange={createFixedDepositProductHandler}
                  />
                  <div style={{ width: "70%" }}>
                    <ReactSelect
                      isClearable={true}
                      className="fs-12"
                      styles={REACT_SELECT_CUSTOM_STYlES}
                      name="maxDepositTermTypeId"
                      placeholder="Select term"
                      onChange={(period, { name }) => createFixedDepositProductHandlerForSelect(name, period?.value)}
                      defaultValue={{
                        value: template?.periodFrequencyTypeOptions[0]?.id,
                        label: template?.periodFrequencyTypeOptions[0]?.value,
                      }}
                      options={template
                        ?.periodFrequencyTypeOptions
                        ?.map((item) => ({
                          label: item.value,
                          value: item.id,
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>

      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-start">
                <label className="text-label d-flex flex-align-center">
                  For Premature Closure
                  {renderTooltip("For Premature Closure")}
                </label>
                <div className="w-100" style={{ maxWidth: '60%' }}>
                  <Checkbox
                    size="md"
                    name="preClosurePenalApplicable"
                    isChecked={preClosurePenalApplicable}
                    onChange={(e) =>
                      setPreClosurePenalApplicable(!preClosurePenalApplicable)
                    }
                  />
                </div>
              </div>
            </FormElements>
          </FormClass>
        </div>
        {preClosurePenalApplicable && (
          <div className="col-sm-12 col-md-6 px-0">
            <FormClass>
              <FormElements>
                <div className="form-group d-flex justify-content-between align-items-start">
                  <label className="text-label d-flex flex-align-center">
                    Apply Penal Interest
                    {renderTooltip("Apply Penal Interest")}
                  </label>
                  <div className="w-100" style={{ maxWidth: '60%' }}>
                  <Input
                    size="md"
                    name="preClosurePenalInterest"
                    value={preClosurePenalInterest}
                    onChange={createFixedDepositProductHandler}
                  />
                  </div>
                </div>
              </FormElements>
            </FormClass>
          </div>
        )}
      </div>

      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-start">
                <label className="text-label d-flex flex-align-center">
                  Is Withhold Tax Applicable
                  {renderTooltip("Is Withhold Tax Applicable")}
                </label>
                <div className="w-100" style={{ maxWidth: '60%' }}>
                  <Checkbox
                    size="md"
                    name="withHoldTax"
                    isChecked={withHoldTax}
                    onChange={() => setWithHoldTax(!withHoldTax)}
                  />
                </div>
              </div>
            </FormElements>
          </FormClass>
        </div>
        {withHoldTax && (
          <div className="col-sm-12 col-md-6 px-0">
            <FormClass>
              <FormElements>
                <div className="form-group d-flex justify-content-between align-items-end">
                  <label className="text-label d-flex flex-align-center">
                    Tax Group
                    {renderTooltip("Tax Group")}
                  </label>
                  <div className="w-100" style={{ maxWidth: '60%' }}>
                    <ReactSelect
                      isClearable={true}
                      className="fs-12"
                      styles={REACT_SELECT_CUSTOM_STYlES}
                      name="taxGroupId"
                      placeholder="Select tax group"
                      onChange={(period, { name }) => createFixedDepositProductHandlerForSelect(name, period?.value)}
                      value={{
                        value: taxGroupId,
                        label: template
                          ?.taxGroupOptions
                          ?.find((option) => option?.id === taxGroupId)
                          ?.name,
                      }}
                      options={template
                        ?.taxGroupOptions
                        ?.map((item) => ({
                          label: item.name,
                          value: item.id,
                        }))
                      }
                    />
                  </div>
                </div>
              </FormElements>
            </FormClass>
          </div>
        )}
      </div>

      <div className="ml-auto pt-5">
        <NextActionButton
          color="#2C1DFF"
          style={{ margin: "0px 0px 0 10px" }}
          onClick={handlePreviousScreen}
        >
          <img
            src={
              require("../../../../../../../../assets/images/icons/arrow-left.svg")
                .default
            }
            alt="cancel icon"
          />
          Previous
        </NextActionButton>
        <NextActionButton
          color="#2C1DFF"
          style={{ margin: "0px 0px 0 10px" }}
          onClick={handleNextScreen}
        >
          <img
            src={
              require("../../../../../../../../assets/images/icons/arrow-right.svg")
                .default
            }
            alt="submit icon"
          />
          Next
        </NextActionButton>
      </div>
    </>
  );
};

export default FixedSettings;
