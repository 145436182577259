import styled from "styled-components";

export const ContentWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  height: 100%;
  .infoNav {
    justify-content: center;
    padding: 10px 0 1px;
  }
  .nav-tabs .nav-link {
    font-size: 16px;
    padding: 10px 35px;
    // font-weight: bold;
    text-align: center;
    color: #c2c2c2;
    &.active {
      border: none;
      color: #333333;
      opacity: none;
      border-bottom: 3px solid #002c79 !important;
    }
    &:hover {
      border: none;
    }
  }
  .application-wrapper {
    .css-1njiejo {
      width: 60% !important;
      height: 48px !important;
    }
  }
`;

export const LineThrough = styled.div`
  width: ${(props) => props.width};
  height: 0px;
  border: 1px solid #eff4f7;
`;

export const AvatarContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 150px;
`;

export const FormClass = styled.div``;

export const FormTitle = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  .lineThrough {
    margin-left: 30px;
    flex: auto;
    border: 0.5px solid #e1e1e4;
  }
`;

export const FormElements = styled.div`
  width: 90%;
  display: block;

  label {
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #969cba;
    text-transform: capitalize;
  }
`;

export const NewFormElements = styled.div`
  // width: 90%;
  display: block;
  .text-muted {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #969cba;
  }
  .css-42b2qy {
    width: 60%;
    color: #666666;
    & > option {
      padding: 0.5rem 0;
    }
  }
`;

export const ActionButton = styled.button`
  background: ${(props) => props.color} !important;
  border-radius: 5px !important;
  color: #fff !important;
  margin: 0 10px;
  border: none;
  font-family: 'AventaMedium';
  display: inline;
  font-size: 14px;
  height: 35px;
  min-width: 160px;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  img {
    height: 10px;
    margin-right: 10px;
    display: inline;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;

export const NewActionButton = styled.div`
  width: max-content !important;
  height: 35px;
  background: #fff !important;
  margin: 0 2px;
  border: 1px solid #2C1DFF;
  padding: 0 15px;
  border-radius: 5px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: 'AventaMedium';
  color: #2C1DFF !important;
  a {
    width: max-content !important;
    color: #fff !important;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none !important;
    transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
    img {
      height: 18px;
    }
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;

export const IconButton = styled.button`
  background: ${(props) => props.color} !important;
  width: 22px;
  height: 22px;
  background: #2C1DFF;
  text-align: center;
  border-radius: 5px;
  margin-right: 10px;
  img {
    // height: 16px;
    // margin-right: 10px;
    display: inline;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;
