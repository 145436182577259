import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Textarea, FormControl } from "@chakra-ui/react";
import Toast from "../../../../../util/Toast";
import {
  FormClass,
  FormElements,
  ActionButton,
} from "../../CustomerInformation/styles";
import {
  assignStaffToAccount,
  postLoanCommand,
} from "../../../../../actions/SavingsAndLoanActions";
import { formatDate } from "../../../../../util/FormatDate";
import { useParams } from 'react-router-dom';

const RejectLoanModal = ({ onHide, id}) => {
  const { id: userId } = useParams();
  const dispatch = useDispatch();

  const [submittedOnDate, setSubmittedOnDate] = useState(Date.now());
  const [note, setNote] = useState("");
  let newDate = formatDate(new Date(submittedOnDate), "dd MMMM yyyy");

  const handleStaffAssign = () => {
    if (!submittedOnDate) {
      dispatch(
        Toast({
          text: "Rejected date is required",
          icon: "error",
        })
      );
    } else {
      dispatch(
        postLoanCommand(
          id,
          Number(userId),
          {
            locale: "en",
            dateFormat: "dd MMMM yyyy",
            rejectedOnDate: newDate,
            note: note,
          },
          "reject",
          onHide
        )
      );
    }
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>Reject Loan</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-column">
        <FormClass style={{ width: "90%" }}>
          <div className="form-group d-flex justify-content-between align-items-center">
            <label className="text-label"> Rejected On*</label>
            <FormControl style={{ width: "60%" }}>
              <div className="datepicker-100-width">
                <DatePicker
                  id="published-date"
                  selected={submittedOnDate}
                  onChange={(date) => setSubmittedOnDate(date)}
                  peekNextMonth
                  className="w-100"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="dd/mmm/yyyy"
                />
              </div>
            </FormControl>
          </div>
          <div className="form-group d-flex justify-content-between align-items-start">
            <label className="text-label">Note</label>
            <Textarea
              value={note}
              onChange={(e) => setNote(e.target.value)}
              size="md"
              className="amount-input"
              resize={"none"}
            />
          </div>
          <div className="float-right mt-1">
            <ActionButton color="#2C1DFF" onClick={onHide}>
              <img
                src={
                  require("../../../../../assets/images/icons/cancel.svg")
                    .default
                }
                alt="cancel icon"
              />
              Cancel
            </ActionButton>
            <ActionButton
              color="#2C1DFF"
              style={{ margin: "0 0 0 10px" }}
              onClick={(e) => {
                e.preventDefault();
                handleStaffAssign();
              }}
            >
              <img
                src={
                  require("../../../../../assets/images/icons/save.svg").default
                }
                alt="submit icon"
              />
              Submit
            </ActionButton>
          </div>
        </FormClass>
      </Modal.Body>
    </>
  );
};

export default RejectLoanModal;
