import React, { useEffect, useRef } from "react";
import {
  ContentWrapper,
  ActionButtonsGroup,
  ActionButton,
  EditButton,
} from "./styles";
import { InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import { Table } from "antd";
import { Link } from "react-router-dom";

import CheckPermission from "../../../../../util/CheckPermission";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllSavingsProducts,
  clearFilteredSavingsProducts,
  filterSavingsProduct,
  resetSavingsProduct,
} from "../../../../../actions/configurationActions";
import Toast from "../../../../../util/Toast";
import TableLoader from "./TableLoader";

const SavingsProductsView = ({ history }) => {
  const dispatch = useDispatch();

  const savingsProductList = useSelector(
    (state) => state.savingsProductsReducer
  );

  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;
  const { loading, savingsProducts, filtered } = savingsProductList;
  savingsProducts.sort((a, b) => (a.name > b.name ? 1 : -1));
  // console.log(filtered);
  const search = useRef("");

  const onFilterSavingsProducts = (e) => {
    search.current.value !== ""
      ? dispatch(filterSavingsProduct(e.target.value))
      : dispatch(clearFilteredSavingsProducts());
  };
  if (filtered !== null && filtered.length === 0) {
    Toast({
      text: "No savings product found",
      icon: "error",
    });
  }
  useEffect(() => {
    dispatch(clearFilteredSavingsProducts());
    dispatch(getAllSavingsProducts());
    dispatch(resetSavingsProduct());
    if (filtered === null) {
      search.current.value = "";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //Table configuration

  const dataSource = [];
  const filteredSavingsProducts = [];

  const handleTaxStatus = (withHoldTax) => {
    if (withHoldTax) {
      return <span className="new-text-success">Applied</span>;
    } else {
      return <span className="new-text-danger">Not Applied</span>;
    }
  };

  if (filtered !== null) {
    filtered.forEach((savs) => {
      const {
        id,
        name,
        shortName,
        allowOverdraft,
        currency,
        interestPostingPeriodType,
        description,
        interestCalculationDaysInYearType,
      } = savs;
      filteredSavingsProducts.push({
        key: id,
        name,
        shortname: shortName,
        allowOverdraft: handleTaxStatus(allowOverdraft),
        currency: currency.code,
        description: description,
        interestCalculationDaysInYearType:
          interestCalculationDaysInYearType.value,
        interestPostingPeriodType: interestPostingPeriodType.value,
        edit: CheckPermission("UPDATE_SAVINGSPRODUCT", permissions) && (
          <>
            <EditButton
              onClick={(e) => {
                e.stopPropagation();
                history.push(
                  `/core/products/savings-products/edit-savings-product/${id}`
                );
              }}
            >
              Edit
            </EditButton>
          </>
        ),
      });
    });
  }

  !loading &&
    savingsProducts.forEach((savs) => {
      const {
        id,
        name,
        shortName,
        allowOverdraft,
        currency,
        interestPostingPeriodType,
        description,
        interestCalculationDaysInYearType,
      } = savs;
      dataSource.push({
        key: id,
        name,
        allowOverdraft: handleTaxStatus(allowOverdraft),
        currency: currency.code,
        description: description,
        interestCalculationDaysInYearType:
          interestCalculationDaysInYearType.value,
        interestPostingPeriodType: interestPostingPeriodType.value,
        shortname: shortName,
        edit: CheckPermission("UPDATE_SAVINGSPRODUCT", permissions) && (
          <>
            <EditButton
              onClick={(e) => {
                e.stopPropagation();
                history.push(
                  `/core/products/savings-products/edit-savings-product/${id}`
                );
              }}
            >
              Edit
            </EditButton>
          </>
        ),
      });
    });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 150,
      ellipsis: true,
    },
    {
      title: "Short name",
      dataIndex: "shortname",
      key: "shortname",
      width: 100,
      ellipsis: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 150,
      ellipsis: true,
    },
    { title: "Currency", dataIndex: "currency", key: "currency" },
    {
      title: "Days in a Year",
      dataIndex: "interestCalculationDaysInYearType",
      key: "interestCalculationDaysInYearType",
    },
    {
      title: "Interest Posting Period",
      dataIndex: "interestPostingPeriodType",
      key: "interestPostingPeriodType",
    },
    { title: "Over Draft", dataIndex: "allowOverdraft", key: "allowOverdraft" },
    { title: "", dataIndex: "edit", key: "edit" },
  ];

  return (
    <>
      {/* <ModalContainer show={modalState}>
        {modalState && displayModalContent()}
      </ModalContainer> */}
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Savings products</h3>

            <p className="font-weight sub-title">
              <span className="text-info">
                {/* <Link to="/core/configuration">Configuration</Link> /{" "} */}
                <Link to="/core/products">Products</Link>
              </span>{" "}
              / Savings products
            </p>
          </div>
          <div className="col-sm-12 col-md-6 px-0 pt-4">
            <ActionButtonsGroup>
              <div className="col-sm-12 col-md-6 pr-2">
                <InputGroup style={{ maxWidth: "450px" }}>
                  <InputLeftElement
                    width="3.1rem"
                    height="38px"
                    pointerEvents="none"
                  >
                    <img
                      src={
                        require("../../../../../assets/images/icons/search.svg")
                          .default
                      }
                      style={{ height: "30px" }}
                      alt="Icon"
                      className="icon-img"
                    />
                  </InputLeftElement>
                  <Input
                    style={{
                      background: "#ffffff",
                      height: "35px !important",
                    }}
                    type="text"
                    className="general-font-size"
                    placeholder="Filter by Name/Short name"
                    ref={search}
                    onChange={onFilterSavingsProducts}
                  />
                </InputGroup>
              </div>
              <div className="col-sm-12 col-md-6 p-0 d-flex">
                {CheckPermission("CREATE_SAVINGSPRODUCT", permissions) && (
                  <Link to="savings-products/create-savings-product">
                    <ActionButton>
                      <img
                        src={
                          require("../../../../../assets/images/icons/add.svg")
                            .default
                        }
                        alt="add"
                      />
                      Create
                    </ActionButton>
                  </Link>
                )}
              </div>
            </ActionButtonsGroup>
          </div>
        </div>
        <ContentWrapper>
          {loading ? (
            <TableLoader />
          ) : (
            CheckPermission("READ_SAVINGSPRODUCT", permissions) && (
              <>
                {filtered !== null ? (
                  <Table
                    columns={columns}
                    dataSource={filteredSavingsProducts}
                    onRow={(r) => ({
                      onClick: () =>
                        history.push(
                          `/core/products/savings-products/details/${r.key}`
                        ),
                    })}
                  />
                ) : (
                  <Table
                    columns={columns}
                    dataSource={dataSource}
                    pagination={{ defaultPageSize: 13 }}
                    defaultPageSize={13}
                    onRow={(r) => ({
                      onClick: () =>
                        history.push(
                          `/core/products/savings-products/details/${r.key}`
                        ),
                    })}
                  />
                )}
              </>
            )
          )}
        </ContentWrapper>
      </div>
    </>
  );
};

export default SavingsProductsView;
