/* eslint-disable import/no-anonymous-default-export */
import axiosData from "../axiosData";
import config from "../../config";

/*
 * Get Payment Types
 */
const getPaymentTypes = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/paymenttypes`,
        params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

const getUserPaymentTypes = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // data: id,
        url: `${config.apiGateway.BASE_API_URL}/savingsaccounts/${id}/transactions/template`,
        // params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};


const getAllGlAccounts = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/glaccounts?disabled=false&manualEntriesAllowed=true&usage=1`,
        // params,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

const getCalculatedLoan = (dataToSend) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        url: `${config.apiGateway.BASE_API_URL}/loans?command=calculateLoanSchedule`,
        data: dataToSend,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

const getAllStatesIds = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // url: `${config.apiGateway.BASE_API_URL}/codes`,
        url: `${config.apiGateway.BASE_API_URL}/codes/27/codevalues`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

const getAllCodeValues = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // url: `${config.apiGateway.BASE_API_URL}/codes`,
        url: `${config.apiGateway.BASE_API_URL}/codes`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

const getCreateCustomerForm = (url) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/${url}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

const getAllAvailableCodes = (url) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/${url}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

const getAllAvailableCountryCodes = (url) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/${url}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

const getGeneralSearch = (query) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/${query}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Get Details for savings account product options
 */
export const getSavingsProductOptions = (clientId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/savingsaccounts/template?clientId=${clientId}&staffInSelectedOfficeOnly=true`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Get Details for Selected Product Option
 */
export const getSelectedSavingsProductOptions = (clientId, productId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/savingsaccounts/template?clientId=${clientId}&productId=${productId}&staffInSelectedOfficeOnly=true&staffInSelectedOfficeOnly=true`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Get Details for Selected charge
 */
export const getSelectedCharge = (chargeId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/charges/${chargeId}?template=true`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Get Details for loan product options
 */
export const getLoansProductOptions = (clientId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/loans/template?activeOnly=true&clientId=${clientId}&staffInSelectedOfficeOnly=true&templateType=individual`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Get Details for Selected Product Option
 */
export const getSelectedLoanProductData = (clientId, productId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/loans/template?activeOnly=true&clientId=${clientId}&productId=${productId}&staffInSelectedOfficeOnly=true&templateType=individual`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};


/*
 * Get Details for Selected Product Option
 */
export const getSelectedFixedDepositProductOptions = (clientId, productId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/fixeddepositaccounts/template?clientId=${clientId}&productId=${productId}&staffInSelectedOfficeOnly=true&staffInSelectedOfficeOnly=true`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Get All tooltips for a particular name e.g savingsProduct, loanProduct e.t.c
 */
const getToolTips = (name) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.TOOL_TIP_API_URL}/tooltips/${name}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Get Applicable Data Tables e.g m_client, m_loan, m_savings
 */
const getApplicableDataTables = (name) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/datatables?apptable=${name}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Get Current Data Tables
 */
const getCurrentDataTables = (name, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/datatables/${name}/${id}?genericResultSet=true`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Create User Data Tables
 */
const createSingleDataTables = (name, id, dataToPost) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        data: dataToPost,
        url: `${config.apiGateway.BASE_API_URL}/datatables/${name}/${id}?genericResultSet=true`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Create User Data Tables
 */
const deleteAllSingleDataTables = (name, id, singleid) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "DELETE",
        // data: dataToPost,
        url: `${config.apiGateway.BASE_API_URL}/datatables/${name}/${id}${
          singleid ? `/${singleid}` : ""
        }?genericResultSet=true`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Edit User Data Tables
 */
const editSingleDataTables = (
  name,
  id,
  dataToPost,
  setPostType,
  currentMultiRowId
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: setPostType,
        data: dataToPost,
        url: `${config.apiGateway.BASE_API_URL}/datatables/${name}/${id}${
          currentMultiRowId ? `/${currentMultiRowId}` : ""
        }?genericResultSet=true`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Create User Data Tables
 */
const getImportTemplate = (url) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // data: dataToPost,
        url: `${config.apiGateway.BASE_API_URL}/${url}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Create User Data Tables
 */
const getBulkImportDocument = (url) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // data: dataToPost,
        url: `${config.apiGateway.BASE_API_URL}/${url}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Create User Data Tables
 */
const uploadBulkDocument = (url, dataToSend) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/${url}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/*
 * Create User Data Tables
 */
const generateGlCode = (type) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // data: dataToSend,
        url: `${config.apiGateway.PRE_AUTH_API_URL}/gl/${type}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export default {
  getPaymentTypes,
  getUserPaymentTypes,
  getAllStatesIds,
  getSavingsProductOptions,
  getSelectedSavingsProductOptions,
  getSelectedCharge,
  getCreateCustomerForm,
  getAllAvailableCodes,
  getAllAvailableCountryCodes,
  getLoansProductOptions,
  getSelectedLoanProductData,
  getGeneralSearch,
  getAllGlAccounts,
  getCalculatedLoan,
  getToolTips,
  getAllCodeValues,
  getApplicableDataTables,
  getCurrentDataTables,
  createSingleDataTables,
  editSingleDataTables,
  deleteAllSingleDataTables,
  getImportTemplate,
  getBulkImportDocument,
  uploadBulkDocument,
  generateGlCode,
  getSelectedFixedDepositProductOptions
};
