import API_NOTIFICATION from "../services/Api/notification.api.services";

import Toast from "../util/Toast";

import {
  SET_SPECIAL_CHARACTERS,
  GET_TENANT_TERMII,
} from "../constants/notificationConstants";

import { SET_LOADING_STATE } from "../constants/utilityConstants";

const handleError = (ex, dispatch) => {
  if (ex.response) {
    dispatch(
      Toast({
        text: ex.response.data.errors
          ? ex.response.data.errors[0].defaultUserMessage
          : ex.response.data.defaultUserMessage,
        icon: "error",
      })
    );
  } else if (ex.request) {
    dispatch(Toast({ text: String(ex), icon: "error" }));
  } else {
    dispatch(Toast({ text: String(ex), icon: "error" }));
  }

  dispatch({ type: SET_LOADING_STATE, payload: false });
};

export const getAllSpecialCharacters = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_SPECIAL_CHARACTERS });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_NOTIFICATION.getSpecialCharacters(id)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: SET_SPECIAL_CHARACTERS, payload: response.data });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

export const addTermiApiKey = (dataToSend, handleShowTemplate, type) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_NOTIFICATION.addTermiApiKey(dataToSend, type)
      .then((response) => {
        handleShowTemplate();
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(Toast({ text: "Termii Successfully Updated", icon: "info" }));
      })
      .catch((ex) => {
        dispatch(Toast({ text: "Unable to perform operation", icon: "error" }));
        handleError(ex, dispatch);
      });
  };
};

export const addTermiTemplate = (dataToSend, id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_NOTIFICATION.addTermiTemplate(dataToSend, id)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(Toast({ text: "Template Added Successfully", icon: "info" }));
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

export const getTenantTermii = () => {
  return async (dispatch) => {
    dispatch({ type: GET_TENANT_TERMII });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_NOTIFICATION.getTenantTermii()
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_TENANT_TERMII, payload: response.data });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
