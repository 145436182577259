import axiosData from "../axiosData";
import config from "../../config";

/**
 * Get Savings account information
 */

const getSavingsAccountTransactions = (nuban_number) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/search?exactMatch=false&query=${nuban_number}&resource=savings`,
      });
      if (data) {
        if (data.length > 0) {
          const { data: data2, error } = await axiosData({
            method: "GET",
            url: `${config.apiGateway.BASE_API_URL}/savingsaccounts/${data[0].entityId}/transactions?page=0&size=10`,
            // url: `${config.apiGateway.BASE_API_URL}/savingsaccounts/${data[0].entityId}?associations=transactions`,
          });
          if (data2) {
            resolve(data2);
          } else {
            reject(error);
          }
        } else {
          resolve("No account found");
        }
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
/**
 * Get Savings account information
 */

 const getFixedAccountTransactions = (nuban_number) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/search?exactMatch=false&query=${nuban_number}&resource=savings`,
      });
      if (data) {
        if (data.length > 0) {
          const { data: data2, error } = await axiosData({
            method: "GET",
            url: `${config.apiGateway.BASE_API_URL}/savingsaccounts/${data[0].entityId}/transactions?page=0&size=10`,
            // url: `${config.apiGateway.BASE_API_URL}/savingsaccounts/${data[0].entityId}?associations=transactions`,
          });
          if (data2) {
            resolve(data2);
          } else {
            reject(error);
          }
        } else {
          resolve("No account found");
        }
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/**
 * Get Loan account information
 */

const getLoanAccountTransactions = (nuban_number) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/search?exactMatch=false&query=${nuban_number}`,
      });
      if (data) {
        if (data.length > 0) {
          const { data: data2, error } = await axiosData({
            method: "GET",
            url: `${config.apiGateway.BASE_API_URL}/loans/${data[0].entityId}/transactions?page=0&size=100`,
            // url: `${config.apiGateway.BASE_API_URL}/loans/${data[0].entityId}?associations=all&exclude=guarantors,futureSchedule`,
          });
          if (data2) {
            resolve(data2);
          } else {
            reject(error);
          }
        } else {
          resolve("No account found");
        }
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getSavingsAccountTransactions,
  getLoanAccountTransactions,
  getFixedAccountTransactions
};
