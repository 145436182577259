import React, { useState } from "react";
import { Input } from "@chakra-ui/react";
import { NewActionButton, FormElements, FormClass } from "../styles";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import {
  getAllUsers,
  updateUserPassword,
} from "../../../../../actions/configurationActions";
import Toast from "../../../../../util/Toast";

const EditUserPassword = ({ onHide, id }) => {
  const dispatch = useDispatch();
  const [editUser, setEditUserPassword] = useState({
    password: "",
    repeatPassword: "",
  });
  let { password, repeatPassword } = editUser;
  const [loading, setLoading] = useState(false);

  const editUserPasswordChangeHandler = (e) =>
    setEditUserPassword({ ...editUser, [e.target.name]: e.target.value });

  const onSubmit = async () => {
    if (password && repeatPassword) {
      if (password === repeatPassword) {
        setLoading(true);
        const userData = {
          password,
          repeatPassword,
        };
        const func = () => {
          dispatch(getAllUsers());
          setLoading(false);
          onHide();
        };
        await dispatch(updateUserPassword(userData, id, func));
        setLoading(false);
      } else {
        dispatch(
          Toast({
            text: "Password & Repeat Password Must be the same",
            icon: "error",
            timer: 2000,
          })
        );
      }
    } else {
      dispatch(
        Toast({
          text: "Please fill the required field",
          icon: "error",
          timer: 2000,
        })
      );
    }
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>Change Password</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <FormElements style={{ width: "100%" }}>
            <div className="row h-100">
              <div className="col-sm-12 col-md-12">
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Password*</label>
                  <Input
                    style={{ width: "60%" }}
                    size="md"
                    name="password"
                    type="password"
                    value={password}
                    onChange={editUserPasswordChangeHandler}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-12">
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Repeat Password*</label>
                  <Input
                    style={{ width: "60%" }}
                    size="md"
                    name="repeatPassword"
                    type="password"
                    value={repeatPassword}
                    onChange={editUserPasswordChangeHandler}
                  />
                </div>
              </div>
            </div>
          </FormElements>
  
        </FormClass>
      </Modal.Body>
      <Modal.Footer>
      <div className="float-right mt-1">
            <NewActionButton color="#2C1DFF" onClick={onHide}>
              <img
                src={
                  require("../../../../../assets/images/icons/cancel.svg")
                    .default
                }
                alt="cancel icon"
              />
              Cancel
            </NewActionButton>
            <NewActionButton
              onClick={onSubmit}
              color="#2C1DFF"
              style={{ margin: "0px 0px 0 10px" }}
            >
              {loading ? (
                <Spinner
                  animation="border"
                  color="#fff"
                  className="mr-2 text-white"
                  size="sm"
                />
              ) : (
                <img
                  src={
                    require("../../../../../assets/images/icons/save.svg")
                      .default
                  }
                  alt="submit icon"
                />
              )}
              Submit
            </NewActionButton>
          </div>
      </Modal.Footer>
    </>
  );
};

export default EditUserPassword;
