import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import {
  Tabs,
  Tab,
  // OverlayTrigger, Tooltip
} from "react-bootstrap";

import {
  getSingleCustomer,
  resetSingleCustomer,
  getCustomerImage,
  resetCustomerImage,
  getCustomerAddress,
  getCustomerDocuments,
  getCustomerDocsReset,
  getCustomerCharges,
  getCustomerAccounts,
  updateCustomerImage,
  uploadCustomerImage,
} from "../../../../actions/customerActions";

import { clearApplicableDataTablesSavings, getCreateCustomerDetails, getProfileApplicableDataTables } from "../../../../actions/utilityActions";

import { ContentWrapper, AvatarContainer, ActionButton } from "./styles";
import { formatDate } from "../../../../util/FormatDate";

import AccountsTab from "../CustomerInformation/Tabs/Accounts";
import LoansTab from "../CustomerInformation/Tabs/Loans";
import FixedDepositTab from "../CustomerInformation/Tabs/FixedDeposit";
import DocumentsTab from "../CustomerInformation/Tabs/Documents";
import ProfileTab from "./components/Profile";
import ActivateClient from "../CustomerInformation/components/general/ActivateClient";
import AddProfilePicture from "./components/AddProfilePicture";
import CheckPermission from "../../../../util/CheckPermission";

import ModalContainer from "../../components/ModalContainer";
import { getApplicableDataTables } from "../.././../../actions/utilityActions";

import Toast from "../../../../util/Toast";

import Loader from "./Loader";
import NotesTab from "./components/NotesTab";
import { useParams } from "react-router-dom";

const CustomerInformation = ({ match, history }) => {
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();
  const { savingsId } = useParams();

  const SingleCustomerDetails = useSelector(
    (state) => state.SingleCustomerDetails
  );

  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;
  const customerImgSrc = useSelector((state) => state.customerImage);
  const { imgData } = customerImgSrc;

  // const isLoading = useSelector((state) => state.isLoading.isLoading);

  const dataTablesInfo = useSelector((state) => state.profileManageDataTables);
  const { profileApplicableDataTables = {} } = dataTablesInfo;
  // const dataTablesInfo = useSelector((state) => state.manageDataTables);
  // const { applicableDataTables = {} } = dataTablesInfo;

  let { loading, singleUser } = SingleCustomerDetails;

  let {
    displayName = "",
    dateOfBirth = [],
    mobileNo = "",
    accountNo = "",
    gender = {},
    countryCode = {},
    externalId = "",
    clientNonPersonDetails = {},
    legalForm = {},
    clientTier,
  } = singleUser || {};

  const [loanIdPassed, setLoanIdPassed] = useState('')
  const [savingsIdPassed, setSavingsIdPassed] = useState('');
  const [fixedIdPassed, setFixedIdPassed] = useState('');

  // console.log(profileApplicableDataTables , "profileApplicableDataTables")
  useEffect(() => {
    let searchParams = new URLSearchParams(search.replace('?', ''));
    if (searchParams.get('tab') == 'loan') {
      setActiveKey('loan');
    }
    if (searchParams.get('type')) {
      setLoanFilter(searchParams.get('type').toLowerCase());
    }
    if (match.params.routeFrom === 'fromLoan') {
      setLoanIdPassed(match.params.currentId);
      setActiveKey('loan');
    } else if (savingsId) {
      setSavingsIdPassed(savingsId);
      setActiveKey('account');
    } else if (match.params.routeFrom === 'fromFixed') {
      setFixedIdPassed(match.params.currentId);
      setActiveKey('fixedDeposit');
    }

    dispatch(resetSingleCustomer());
    dispatch(resetCustomerImage());
    dispatch(getCustomerDocsReset());
    dispatch(getCustomerImage(match.params.id));

    dispatch(getSingleCustomer(match.params.id));
    dispatch(getCustomerAddress(match.params.id));
    dispatch(getCustomerDocuments(match.params.id));
    dispatch(getCustomerCharges(match.params.id));
    dispatch(getCustomerAccounts(match.params.id));
    // dispatch(getApplicableDataTables('m_client'));
    dispatch(getProfileApplicableDataTables('m_client'));
    dispatch(
      getCreateCustomerDetails(
        'clients/template?staffInSelectedOfficeOnly=true'
      )
    );

    return () => {
      // Clear the applicableDataTables state when the component unmounts
      dispatch(clearApplicableDataTablesSavings());
    };
  }, [dispatch, match]);

  const [modalState, toggleModal] = useState(false);
  const [activeKey, setActiveKey] = useState('account');
  const [loanFilter, setLoanFilter] = useState('');
  const [modalContentName, setModalContentName] = useState('');

  const onTabChange = (activeKey) => {
    setActiveKey(activeKey);
  };

  const currentContent = (e) => {
    const val = e.currentTarget.id.trim();

    if (val) {
      toggleModal(true);
      setModalContentName(val);
    }
  };

  const handleModalClose = () => toggleModal(false);

  const displayModalContent = () => {
    switch (modalContentName) {
      case 'activateClient':
        return (
          <ActivateClient
            history={history}
            userData={singleUser}
            onHide={handleModalClose}
          />
        );
      case 'addProfilePicture':
        return (
          <AddProfilePicture
            history={history}
            singleUser={singleUser}
            onHide={handleModalClose}
            imgData={imgData}
          />
        );
      default:
        return;
    }
  };

  const [imgPayload, setImgPayload] = useState(null);
  // const previewImage = (e) => {
  //   const preview = document.querySelector('#updateImage');
  //   const file = e.target.files[0];
  //   setImgPayload(file);
  //   const reader = new FileReader();
  //   reader.addEventListener(
  //     'load',
  //     () => {
  //       // convert image file to base64 string
  //       preview.src = reader.result;
  //     },
  //     false
  //   );
  //   if (file) {
  //     reader.readAsDataURL(file);
  //   }
  // };

  const handleImageUpdateSubmit = async () => {
    if (imgPayload === null) {
      dispatch(Toast({ text: 'Please select photo', icon: 'error' }));
    } else if (imgData && imgPayload !== null) {
      const formData = new FormData();
      formData.append('file', imgPayload);
      await dispatch(updateCustomerImage(singleUser.id, formData));
    } else if (singleUser && singleUser.id && imgPayload !== null) {
      const formData = new FormData();
      formData.append('file', imgPayload);
      await dispatch(uploadCustomerImage(singleUser.id, formData, history));
    } else {
      dispatch(
        Toast({ text: 'Something went wrong.. check Internet', icon: 'error' })
      );
    }
  };

  return (
    <>
      <ModalContainer show={modalState}>
        {modalState && displayModalContent()}
      </ModalContainer>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3 mt-1">
            <h3 className="page-title">Customer</h3>
            <p className="text-muted sub-title">Customer overall details</p>
            <p className="font-weight sub-title mt-2">
              <span className="text-info">
                <Link to="/core/clients">Customer</Link>
              </span>{' '}
              / Customer information
            </p>
          </div>
        </div>

        <ContentWrapper>
          {loading ? (
            <Loader />
          ) : (
            <>
              {' '}
              <div className="row">
                <div className="col-xl-3 col-md-12 border-right">
                  {/* Images and Customer details goes here */}
                  <div className="row">
                    <div className="col-xl-12 col-md-6">
                      <AvatarContainer>
                        <div
                          style={{
                            width: '150px',
                            height: '150px',
                            position: 'relative',
                          }}
                        >
                          <img
                            id="updateImage"
                            src={
                              imgData
                                ? imgData
                                : require('../../../../assets/images/defaultUser.png')
                                    .default
                            }
                            style={{
                              height: '150px',
                              width: '150px',
                              borderRadius: '100%',
                              objectFit: 'cover',
                            }}
                            alt="User"
                            className="UserInfo-pic"
                          />
                          {CheckPermission(
                            'CREATE_CLIENTIMAGE',
                            permissions
                          ) ? (
                            <div
                              onClick={currentContent}
                              id="addProfilePicture"
                              className="edit-icon pointer"
                            >
                              <img
                                src={
                                  require('../../../../assets/images/icons/edit-profile.svg')
                                    .default
                                }
                                alt=""
                                className="icon-img"
                                style={{
                                  display: 'inline',
                                  marginTop: '5px',
                                  marginLeft: '9px',
                                }}
                              />
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </AvatarContainer>

                      {imgPayload !== null ? (
                        <ActionButton
                          color="#2C1DFF"
                          onClick={() => handleImageUpdateSubmit()}
                          style={{ marginTop: '100px' }}
                        >
                          Update Image
                        </ActionButton>
                      ) : null}
                    </div>

                    <div className="col-xl-12 col-md-6 general-font-size">
                      <div className="mt-5 mb-5 mr-2 ml-4">
                        <div className="row margin-bottom-10">
                          <div className="col-lg-12 col-xl-6 col-md-12 p-0">
                            <h3 style={{ color: 'black', fontWeight: 'bold' }}>
                              Full Name:
                            </h3>
                          </div>
                          <div className="col-lg-12 col-xl-6 col-md-12 p-0">
                            <p>{displayName && displayName}</p>
                          </div>
                        </div>
                        <div className="row margin-bottom-10">
                          <div className="col-lg-12 col-xl-6 col-md-12 p-0">
                            <h3 style={{ color: 'black', fontWeight: 'bold' }}>
                              CIF Number:
                            </h3>
                          </div>
                          <div className="col-lg-12 col-xl-6 col-md-12 p-0">
                            <p>{accountNo && accountNo}</p>
                          </div>
                        </div>
                        <div className="row margin-bottom-10">
                          <div className="col-lg-12 col-xl-6 col-md-12 p-0">
                            {legalForm.value === 'ENTITY' ? (
                              <h3
                                style={{ color: 'black', fontWeight: 'bold' }}
                              >
                                Incorporation date
                              </h3>
                            ) : (
                              <h3
                                style={{ color: 'black', fontWeight: 'bold' }}
                              >
                                DOB:
                              </h3>
                            )}
                          </div>
                          <div className="col-lg-12 col-xl-6 col-md-12 p-0">
                            <p>
                              {dateOfBirth &&
                                dateOfBirth.length &&
                                formatDate(dateOfBirth)}
                            </p>
                          </div>
                        </div>
                        <div className="row margin-bottom-10">
                          <div className="col-lg-12 col-xl-6 col-md-12 p-0">
                            <h3 style={{ color: 'black', fontWeight: 'bold' }}>
                              Tier level:
                            </h3>
                          </div>
                          <div className="col-lg-12 col-xl-6 col-md-12 p-0">
                            {clientTier?.tierRank ? (
                              <p>Tier {clientTier && clientTier?.tierRank}</p>
                            ) : (
                              <p>No tier</p>
                            )}
                          </div>
                        </div>
                        <div className="row margin-bottom-10">
                          <div className="col-lg-12 col-xl-6 col-md-12 p-0">
                            <h3 style={{ color: 'black', fontWeight: 'bold' }}>
                              Mobile Number:
                            </h3>
                          </div>
                          <div className="col-lg-12 col-xl-6 col-md-12 p-0">
                            <p>{countryCode && countryCode.name ? (<span>+{countryCode.name}</span>) : ('')}{mobileNo && mobileNo}</p>
                          </div>
                        </div>
                        <div className="row margin-bottom-10">
                          <div className="col-lg-12 col-xl-6 col-md-12 p-0">
                            {legalForm.value === 'ENTITY' ? (
                              <h3
                                style={{ color: 'black', fontWeight: 'bold' }}
                              >
                                Inc. Number:
                              </h3>
                            ) : (
                              <h3
                                style={{ color: 'black', fontWeight: 'bold' }}
                              >
                                Gender:
                              </h3>
                            )}
                          </div>
                          <div className="col-lg-12 col-xl-6 col-md-12 p-0">
                            {legalForm.value === 'ENTITY' ? (
                              <p>
                                {clientNonPersonDetails &&
                                  clientNonPersonDetails.incorpNumber}
                              </p>
                            ) : (
                              <p>{gender && gender.name}</p>
                            )}
                          </div>
                        </div>
                        <div className="row margin-bottom-10">
                          <div className="col-lg-12 col-xl-6 col-md-12 p-0">
                            <h3 style={{ color: 'black', fontWeight: 'bold' }}>
                              External Id:
                            </h3>
                          </div>
                          <div className="col-lg-12 col-xl-6 col-md-12 p-0">
                            <p>{externalId && externalId}</p>
                          </div>
                        </div>
                        <div className="row margin-bottom-10">
                          <div className="col-lg-12 col-xl-6 col-md-12 p-0">
                            <h3 style={{ color: 'black', fontWeight: 'bold' }}>
                              Activation Date:
                            </h3>
                          </div>
                          <div className="col-lg-12 col-xl-6 col-md-12 p-0">
                            <p>
                              {singleUser &&
                                singleUser.activationDate &&
                                formatDate(singleUser.activationDate)}
                            </p>
                          </div>
                        </div>

                        {!!singleUser &&
                        !singleUser.active &&
                        CheckPermission('ACTIVATE_CLIENT', permissions) ? (
                          <ActionButton
                            color="#2C1DFF"
                            onClick={currentContent}
                            id="activateClient"
                            style={{ marginTop: '10px' }}
                          >
                            <img
                              src={
                                require('../../../../assets/images/icons/check-mark.svg')
                                  .default
                              }
                              className="mr-2"
                              style={{ height: '16px' }}
                              alt="add"
                            />
                            Activate Customer
                          </ActionButton>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-9 col-md-12">
                  {/* Tabs goes here */}
                  <Tabs
                    className="infoNav"
                    // defaultActiveKey="account"
                    // transition={false}
                    id="noanim-tab-example"
                    activeKey={activeKey}
                    onSelect={onTabChange}
                  >
                    <Tab eventKey="account" title="Deposits">
                      <AccountsTab
                        currentSavingsId={savingsIdPassed}
                        history={history}
                        userData={singleUser}
                      />
                    </Tab>
                    <Tab eventKey="fixedDeposit" title="Fixed Deposit">
                      <FixedDepositTab
                        userData={singleUser}
                        history={history}
                        currentFixedId={fixedIdPassed}
                      />
                    </Tab>{' '}
                    <Tab eventKey="loan" title="Loan">
                      <LoansTab
                        userData={singleUser}
                        history={history}
                        filter={loanFilter}
                        currentLoanId={loanIdPassed}
                      />
                    </Tab>{' '}
                    <Tab eventKey="document" title="Document">
                      <DocumentsTab
                        userData={singleUser}
                        id={match.params.id}
                        loading={loading}
                      />
                    </Tab>{' '}
                    <Tab eventKey="profile" title="Profile">
                      <ProfileTab
                        userData={singleUser}
                        id={match.params.id}
                        loading={loading}
                        // dataTables={applicableDataTables}
                        dataTables={profileApplicableDataTables}
                      />
                    </Tab>
                    {CheckPermission('READ_CLIENTNOTE', permissions) && (
                      <Tab eventKey="notes" title="Notes">
                        <NotesTab id={match.params.id} />
                      </Tab>
                    )}
                  </Tabs>
                </div>
              </div>{' '}
            </>
          )}
        </ContentWrapper>
      </div>
    </>
  );
};

export default CustomerInformation;
