import React, { useState, useEffect, useRef } from "react";
import {
  ContentWrapper,
  ActionButtonsGroup,
  ActionButton,
  EditButton,
  IconButton,
} from "./styles";
import { InputGroup, InputLeftElement, Input, Select } from "@chakra-ui/react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import ModalContainer from "../../../components/ModalContainer";
import CreateHoliday from "./components/CreateHoliday";
import EditHoliday from "./components/EditHoliday";
import ActivateHoliday from "./components/ActivateHoliday";
import DeleteHoliday from "./components/DeleteHoliday";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllHolidays,
  filterHolidays,
  clearFilteredHolidays,
  getHolidayTemplate,
  getAllOffices,
} from "../../../../../actions/configurationActions";
import Toast from "../../../../../util/Toast";
import TableLoader from "./TableLoader";
import { formatDate } from "../../../../../util/FormatDate";
import CheckPermission from "../../../../../util/CheckPermission";

const ManageHolidaysView = () => {
  const dispatch = useDispatch();
  const [modalState, toggleModal] = useState(false);
  const [modalContentName, setModalContentName] = useState("");
  const [selectedHoliday, setSelectedHoliday] = useState({});
  const [selectedHolidayId, setSelectedHolidayId] = useState(null);
  const [officeId, setOfficeId] = useState("1");
  const holidayList = useSelector((state) => state.holidayReducer);
  const officeList = useSelector((state) => state.officeReducer);
  const holidayTemplates = useSelector((state) => state.holidayTemplate);
  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  const { offices } = officeList;
  offices.sort((a, b) => (a.name > b.name ? 1 : -1));
  const { loading: templateLoading, holidayTemplate } = holidayTemplates;

  const { loading, holidays, filtered } = holidayList;
  holidays.sort((a, b) => (a.position > b.position ? 1 : -1));
  const search = useRef("");
  const onFilterHoliday = (e) => {
    search.current.value !== ""
      ? dispatch(filterHolidays(e.target.value))
      : dispatch(clearFilteredHolidays());
  };
  useEffect(() => {
    dispatch(clearFilteredHolidays());
    dispatch(getAllOffices());
    dispatch(getHolidayTemplate());
    if (filtered === null) {
      search.current.value = "";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (officeId) dispatch(getAllHolidays(Number(officeId)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [officeId]);

  const handleEditHoliday = (id) => {
    setSelectedHoliday(holidays.filter((off) => off.id === id)[0]);
    toggleModal(true);
    setModalContentName("editHoliday");
  };

  const handleDeleteHoliday = (id) => {
    setSelectedHolidayId(id);
    toggleModal(true);
    setModalContentName("deleteHoliday");
  };

  const handleActivateHoliday = (id) => {
    setSelectedHolidayId(id);
    toggleModal(true);
    setModalContentName("activateHoliday");
  };
  const currentContent = (e) => {
    const val = e.currentTarget.id.trim();

    if (val) {
      toggleModal(true);
      setModalContentName(val);
    }
  };

  const handleModalClose = () => toggleModal(false);

  const displayModalContent = () => {
    switch (modalContentName) {
      case "createHoliday":
        return (
          <CreateHoliday
            holidayTemplate={holidayTemplate}
            onHide={handleModalClose}
            offices={offices}
            officeIdd={officeId}
          />
        );
      case "editHoliday":
        return (
          <EditHoliday
            data={selectedHoliday}
            holidayTemplate={holidayTemplate}
            offices={offices}
            onHide={handleModalClose}
            officeIdd={officeId}
          />
        );
      case "activateHoliday":
        return (
          <ActivateHoliday
            officeIdd={officeId}
            id={selectedHolidayId}
            onHide={handleModalClose}
          />
        );
      case "deleteHoliday":
        return (
          <DeleteHoliday
            officeIdd={officeId}
            id={selectedHolidayId}
            onHide={handleModalClose}
          />
        );
      default:
        return;
    }
  };

  if (filtered !== null && filtered.length === 0) {
    Toast({
      text: "No holiday found",
      icon: "error",
      timer: 2000,
    });
  }

  //Table configuration

  const columns = [
    { title: "Holiday Name", dataIndex: "name", key: "name" },
    { title: "Start Date", dataIndex: "startDate", key: "startDate" },
    { title: "End Date", dataIndex: "endDate", key: "endDate" },
    {
      title: "Repayments Scheduled To",
      dataIndex: "repayments",
      key: "repayments",
    },
    { title: "", dataIndex: "status", key: "status" },
    { title: "", dataIndex: "actions", key: "actions" },
  ];
  const dataSource = [];
  const filteredHolidays = [];

  if (filtered !== null) {
    filtered.forEach((holiday) => {
      const { id, name, repaymentsRescheduledTo, fromDate, toDate, status } =
        holiday;
      filteredHolidays.push({
        key: id,
        name,
        startDate: formatDate(fromDate),
        endDate: formatDate(toDate),
        repayments: repaymentsRescheduledTo
          ? formatDate(repaymentsRescheduledTo)
          : "Next Repayment date",
        status: CheckPermission("UPDATE_HOLIDAY", permissions) ? (
          <EditButton
            textColor={status.value === "Active" ? "#2C1DFF" : "#01B1FC"}
            color={status.value === "Active" ? "#A2C2FC" : "#C3E9F9"}
          >
            {status.value}
          </EditButton>
        ) : null,
        actions:
          status.value === "Deleted" ? null : (
            <>
              {status.value === "Pending for activation" &&
              CheckPermission("ACTIVATE_HOLIDAY", permissions) ? (
                <IconButton
                  onClick={() => handleActivateHoliday(id)}
                  className="mr-1 mb-1"
                >
                  <img
                    src={
                      require("../../../../../assets/images/icons/activate.svg")
                        .default
                    }
                    alt="activate"
                  />
                </IconButton>
              ) : null}
              {CheckPermission("UPDATE_HOLIDAY", permissions) ? (
                <IconButton
                  onClick={() => handleEditHoliday(id)}
                  className="mr-1 mb-1"
                >
                  <img
                    src={
                      require("../../../../../assets/images/icons/edit-profile.svg")
                        .default
                    }
                    alt="edit"
                  />
                </IconButton>
              ) : null}
              {CheckPermission("DELETE_HOLIDAY", permissions) ? (
                <IconButton
                  id="deleteHoliday"
                  onClick={() => handleDeleteHoliday(id)}
                  className="mr-1 mb-1"
                >
                  <img
                    src={
                      require("../../../../../assets/images/icons/delete.svg")
                        .default
                    }
                    alt="add"
                  />
                </IconButton>
              ) : null}
            </>
          ),
      });
    });
  }

  !loading &&
    holidays.forEach((holiday) => {
      const { id, name, repaymentsRescheduledTo, fromDate, toDate, status } =
        holiday;
      dataSource.push({
        key: id,
        name,
        startDate: formatDate(fromDate),
        endDate: formatDate(toDate),
        repayments: repaymentsRescheduledTo
          ? formatDate(repaymentsRescheduledTo)
          : "Next Repayment date",
        status: CheckPermission("UPDATE_HOLIDAY", permissions) ? (
          <EditButton
            textColor={status.value === "Active" ? "#2C1DFF" : "#01B1FC"}
            color={status.value === "Active" ? "#A2C2FC" : "#C3E9F9"}
          >
            {status.value}
          </EditButton>
        ) : null,
        actions:
          status.value === "Deleted" ? null : (
            <>
              {status.value === "Pending for activation" &&
              CheckPermission("ACTIVATE_HOLIDAY", permissions) ? (
                <IconButton
                  onClick={() => handleActivateHoliday(id)}
                  className="mr-1 mb-1"
                >
                  <img
                    src={
                      require("../../../../../assets/images/icons/activate.svg")
                        .default
                    }
                    alt="activate"
                  />
                </IconButton>
              ) : null}
              {CheckPermission("UPDATE_HOLIDAY", permissions) ? (
                <IconButton
                  onClick={() => handleEditHoliday(id)}
                  className="mr-1 mb-1"
                >
                  <img
                    src={
                      require("../../../../../assets/images/icons/edit-profile.svg")
                        .default
                    }
                    alt="edit"
                  />
                </IconButton>
              ) : null}
              {CheckPermission("DELETE_HOLIDAY", permissions) ? (
                <IconButton
                  id="deleteHoliday"
                  onClick={() => handleDeleteHoliday(id)}
                  className="mr-1 mb-1"
                >
                  <img
                    src={
                      require("../../../../../assets/images/icons/delete.svg")
                        .default
                    }
                    alt="add"
                  />
                </IconButton>
              ) : null}
            </>
          ),
      });
    });

  return (
    <>
      <ModalContainer show={modalState}>
        {modalState && displayModalContent()}
      </ModalContainer>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-5 pb-3">
            <h3 className="page-title">Manage Holidays</h3>
            <p className="sub-title">Add new, modify and edit Holidays</p>
            <p className="font-weight sub-title mt-2">
              <span className="text-info">
                <Link to="/core/configuration">Configuration</Link> /{" "}
                <Link to="/core/configuration/organization">Utilities</Link>
              </span>{" "}
              / Manage Holidays
            </p>
          </div>
          <div className="col-sm-12 col-md-7 px-0">
            <ActionButtonsGroup>
              <InputGroup style={{ maxWidth: "450px" }}>
                <InputLeftElement
                  width="3.1rem"
                  height="50px"
                  pointerEvents="none"
                >
                  <img
                    src={
                      require("../../../../../assets/images/icons/search.svg")
                        .default
                    }
                    style={{ height: "30px" }}
                    alt="Icon"
                  />
                </InputLeftElement>
                <Input
                  style={{
                    background: "#ffffff",
                    height: "50px",
                  }}
                  ref={search}
                  onChange={onFilterHoliday}
                  type="text"
                  className="mb-3"
                  placeholder="Search by holiday name"
                />
              </InputGroup>
              <div className="d-flex">
                {CheckPermission("Create Holiday", permissions) && (
                  <ActionButton id="createHoliday" onClick={currentContent}>
                    <img
                      src={
                        require("../../../../../assets/images/icons/add.svg")
                          .default
                      }
                      alt="add"
                    />
                    New Holiday
                  </ActionButton>
                )}
                {CheckPermission("READ_HOLIDAY", permissions) && (
                  <Select
                    placeholder="Select office"
                    size="md"
                    name="officeId"
                    value={officeId}
                    onChange={(e) => setOfficeId(e.target.value)}
                  >
                    {offices && offices.length > 0
                      ? offices.map((off, i) => (
                          <option value={off.id} key={i}>
                            {off.name}
                          </option>
                        ))
                      : null}
                  </Select>
                )}
              </div>
            </ActionButtonsGroup>
          </div>
        </div>
        <ContentWrapper>
          {loading || templateLoading ? (
            <TableLoader />
          ) : (
            CheckPermission("READ_HOLIDAY", permissions) && (
              <>
                {filtered !== null ? (
                  <Table columns={columns} dataSource={filteredHolidays} />
                ) : (
                  <Table
                    columns={columns}
                    dataSource={dataSource}
                    pagination={{ defaultPageSize: 8 }}
                    defaultPageSize={8}
                  />
                )}
              </>
            )
          )}
        </ContentWrapper>
      </div>
    </>
  );
};

export default ManageHolidaysView;
