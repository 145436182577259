import React, { useRef, useEffect, useState } from "react";
import { Table } from "antd";
import { formatCurrency } from "../../../../../util/FormatAmount";
import { IconButton } from "../../styles.jsx";
import ModalContainer from "../../../components/ModalContainer";
import { getLoanAccCharges } from "../../../../../actions/SavingsAndLoanActions";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../../../../../util/FormatDate";
import PayLoansChargeModal from "../PayLoansChargeModal";
import WaiveLoansChargeModal from "../WaiveLoansChargeModal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CheckPermission from "../../../../../util/CheckPermission.js";
import {Tooltip as AntTooltip} from 'antd';

const LoanCharges = ({ accountNo }) => {
  const selecetedAmountOutstanding = useRef("");
  const selecetedTableIndex = useRef();
  const selectedChargedId = useRef();
  const dispatch = useDispatch();

  const savingsAndLoan = useSelector((state) => state.savingsAndLoan);

  const { loanCharges = {} } = savingsAndLoan || {};
  let { pageItems = [], totalFilteredRecords } = loanCharges || {};

  let { loanDetails = {} } = savingsAndLoan || {};
  let { id } = loanDetails || {};

  const isTableLoading = useSelector(
    (state) => state.isTableLoading.isTableLoading
  );
  const { responseUserData: { permissions } } = useSelector((state) => state.preAuth);

  const [charges, setCharges] = useState([]);
  const [modalState, setModalState] = useState(false);
  const [modalContentName, setModalContentName] = useState("");
  const [dueOrder, setDueOrder] = useState('DESC');
  const [appliedOrder, setAppliedOrder] = useState('DESC');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
  });
  const [currentPagination, setCurrentPagination] = useState(1);
  
  useEffect(() => {
    pageItems && setCharges(pageItems);
  }, [pageItems]);

  useEffect(() => {
    if (totalFilteredRecords) {
      setPagination({
        current: currentPagination,
        pageSize: 5,
        total: totalFilteredRecords,
      });
    }
  }, [totalFilteredRecords, currentPagination]);

  const handleTableChange = (pagination) => {
    setCurrentPagination(pagination.current);
    let dataToSend = `?offset=${
      (pagination.current - 1) * 5
    }&limit=5&orderBy=dueAsOfDate ${dueOrder}`;
    dispatch(getLoanAccCharges(id, dataToSend));
  }

  useEffect(() => {
    handleTableChange(pagination);
  }, [dueOrder, pagination.current]);

  useEffect(() => {
    setCurrentPagination(pagination.current);
    let dataToSend = `?offset=${
      (pagination.current - 1) * 5
    }&limit=5&orderBy=loanChargeDate ${appliedOrder}`;
    dispatch(getLoanAccCharges(id, dataToSend));
  }, [appliedOrder, pagination.current]);

  useEffect(() => {
    dispatch(getLoanAccCharges(id, `?offset=0&limit=5&orderBy=dueAsOfDate ${dueOrder}`));
  }, [id, dueOrder]);

  const toggleDueOrder = () => {
    setDueOrder(order => order === 'DESC' ? 'ASC' : 'DESC');
  };

  const toggleAppliedOrder = () => {
    setAppliedOrder(order => order === 'DESC' ? 'ASC' : 'DESC');
    dispatch(getLoanAccCharges(id, `?offset=0&limit=5&orderBy=loanChargeDate ${appliedOrder}`));
  };
  
  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    {
      title: "Fee/Penalty",
      dataIndex: "feeOrPenalty",
      key: "feeOrPenalty",
    },
    { title: "Payment Due At", dataIndex: "dueAt", key: "dueAt" },
    {
      title: (
        <AntTooltip title={`${dueOrder === 'ASC' ? 'Oldest to Newest' : 'Newest to Oldest'} (tap to change)`}>
          <span onClick={toggleDueOrder} className='pointer'>
            Due As Of
            <span
              className="font-bold px-1 rounded-circle border border-secondary ml-2 fs-13"
              style={{ backgroundColor: '#e3e3e3' }}
            >
              {dueOrder === 'ASC' ? <>&#8593;</> : <>&#8595;</>}
            </span>
          </span>
        </AntTooltip>
      ),
      dataIndex: "dueDate",
      key: "dueDate"
    },
    {
      title: (
        <AntTooltip title={`${appliedOrder === 'ASC' ? 'Oldest to Newest' : 'Newest to Oldest'} (tap to change)`}>
          <span onClick={toggleAppliedOrder} className='pointer'>
            Applied Date
            <span
              className="font-bold px-1 rounded-circle border border-secondary ml-2 fs-13"
              style={{ backgroundColor: '#e3e3e3' }}
            >
              {appliedOrder === 'ASC' ? <>&#8593;</> : <>&#8595;</>}
            </span>
          </span>
        </AntTooltip>
      ),
      dataIndex: "appliedDate",
      key: "appliedDate",
    },
    {
      title: "Calculation Type",
      dataIndex: "calculationType",
      key: "calculationType",
    },
    {
      title: "Due",
      dataIndex: "due",
      key: "due",
    },
    {
      title: "Paid",
      dataIndex: "paid",
      key: "paid",
    },
    {
      title: "Waived",
      dataIndex: "waived",
      key: "waived",
    },
    {
      title: "Outstanding",
      dataIndex: "outstanding",
      key: "outstanding",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];

  // currentContent
  const currentContent = (e, amount, index, newId) => {
    selecetedAmountOutstanding.current = amount;
    selecetedTableIndex.current = index;
    selectedChargedId.current = newId;
    const val = e.currentTarget.id.trim();
    // setDocumentId(docId)
    setTimeout(() => {
      if (val) {
        setModalState(true);
        setModalContentName(val);
      }
    }, 300);
  };

  // currentWaiveContent
  const currentWaiveContent = (e, index, newId) => {
    selecetedTableIndex.current = index;
    selectedChargedId.current = newId;
    const val = e.currentTarget.id.trim();
    // setDocumentId(docId)
    setTimeout(() => {
      if (val) {
        setModalState(true);
        setModalContentName(val);
      }
    }, 300);
  };

  const handleModalClose = () => {
    setModalState(false);
    selecetedAmountOutstanding.current = "";
    selecetedTableIndex.current = null;
    selectedChargedId.current = null;
  };

  const displayModalContent = () => {
    switch (modalContentName) {
      case "payCharges":
        return (
          <PayLoansChargeModal
            id={id}
            newId={selectedChargedId.current}
            amount={selecetedAmountOutstanding.current}
            onHide={handleModalClose}
            selectedTableIndex={selecetedTableIndex.current}
          />
        );
      case "waiveCharges":
        return (
          <WaiveLoansChargeModal
            id={id}
            newId={selectedChargedId.current}
            onHide={handleModalClose}
            selectedTableIndex={selecetedTableIndex.current}
          />
        );
      default:
        return;
    }
  };

  let chargesData = [];

  charges.forEach((charge, index) => {
    const {
      newId = charge.id,
      amountOutstanding = 0,
      amountPaid = 0,
      amountWaived = 0,
      chargeCalculationType,
      chargeTimeType,
      dueDate,
      amount = 0,
      loanChargeCreatedDate,
      name,
      penalty,
    } = charge || {};

    chargesData.push({
      name,
      feeOrPenalty: penalty ? "Penalty" : "Fee",
      dueAt: !!chargeTimeType ? chargeTimeType.value : "",
      dueDate: dueDate ? formatDate(dueDate) : "",
      appliedDate: formatDate(loanChargeCreatedDate),
      calculationType: chargeCalculationType.value,
      due: formatCurrency(amount),
      paid: formatCurrency(amountPaid),
      waived: formatCurrency(amountWaived),
      outstanding: formatCurrency(amountOutstanding),
      action: (
        <>
          {(amountOutstanding > 0 && CheckPermission('PAY_LOANCHARGE', permissions)) ? (
            <RenderTooltip text="Pay Charges" id="payCharges">
              <IconButton
                color="#FFFFFF"
                className="mr-2 "
                id="payCharges"
                onClick={(e) =>
                  currentContent(e, amountOutstanding, index, newId)
                }
              >
                <img
                  src={
                    require("../../../../../assets/images/icons/approveCharges.svg")
                      .default
                  }
                  alt="pay"
                  style={{
                    height: "17px",
                  }}
                />
              </IconButton>
            </RenderTooltip>
          ) : (
            ""
          )}
          {(amountOutstanding > 0 && CheckPermission('WAIVE_LOANCHARGE', permissions)) ? (
            <RenderTooltip text="Waive Charges" id="waiveCharges">
              <IconButton
                className="mr-2"
                color="#FFFFFF"
                id="waiveCharges"
                onClick={(e) => currentWaiveContent(e, index, newId)}
              >
                <img
                  src={
                    require("../../../../../assets/images/icons/waiveCharges.svg")
                      .default
                  }
                  alt="waive"
                  style={{
                    height: "17px",
                  }}
                />
              </IconButton>
            </RenderTooltip>
          ) : (
            ""
          )}
        </>
      ),
      charge,
    });
  });

  return (
    <>
      <ModalContainer show={modalState} dialogClassName="document_infoModal">
        {modalState && displayModalContent()}
      </ModalContainer>
      <Table
        style={{
          overflow: "hidden",
          overflowX: "scroll",
        }}
        columns={columns}
        dataSource={chargesData}
        pagination={pagination}
        defaultPageSize={4}
        loading={isTableLoading}
        onChange={handleTableChange}
        onRow={(r) => ({
          // onClick: () => {
          //   setLoanCharges(r);
          //   setModalState(true);
          // },
        })}
      />
    </>
  );
};

const RenderTooltip = ({ text, id, children }) => (
  <>
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={`tooltip-${id}`}>{text}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  </>
);

export default LoanCharges;
