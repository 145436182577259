import React, { useState, useEffect } from "react";
import {
  ContentWrapper,
  // ActionButtonsGroup,
  // IconButton,
  ActionButton,
} from "./styles";
import { Table } from "antd";
import { Link } from "react-router-dom";
import {
  Input,
  InputGroup,
  InputLeftElement,
  // FormControl,
} from "@chakra-ui/react";
import CheckPermission from "../../../../util/CheckPermission";
import {
  getLoanAccountTransactions,
  clearLoan,
  getLoanAccountTransactionsPagination,
} from "../../../../actions/transactionsActions";
import { useDispatch, useSelector } from "react-redux";
// import DatePicker from "react-datepicker";
import { formatDate } from "../../../../util/FormatDate";
import TableLoader from "./TableLoader";
import ViewTransaction from "./ViewTransaction";
import ModalContainer from "../../components/ModalContainer";

const LoanTransactionsView = () => {
  const dispatch = useDispatch();

  const isTableLoading = useSelector(
    (state) => state.isTableLoading.isTableLoading
  );
  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;
  const accountDetails = useSelector((state) => state.loanAccountTransReducer);
  // const [transactionDate, setTrasactionDate] = useState("");
  const [selectedTrans, setSelectedTrans] = useState({});
  const [modalState, toggleModal] = useState(false);
  const [modalContentName, setModalContentName] = useState("");
  const { loading, loanAccountDetails } = accountDetails;

  let { pageItems = [], totalItems } = loanAccountDetails || {};

  const handleViewTrans = (id) => {
    setSelectedTrans(pageItems.filter((usr) => usr.id === id)[0]);
    toggleModal(true);
    setModalContentName("viewTrans");
  };

  useEffect(() => {
    dispatch(clearLoan());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // table configuration
  const columns = [
    { title: "Transaction ID", dataIndex: "tid", key: "tid" },
    {
      title: "Transaction Date",
      dataIndex: "date",
      key: "date",
    },
    { title: "Amount", dataIndex: "amount", key: "amount" },
    { title: "Currency", dataIndex: "currency", key: "currency" },
  ];
  const dataSource = [];
  !loading &&
    pageItems &&
    pageItems.length > 0 &&
    pageItems.forEach((trans) => {
      const { id, type, amount, date, currency } = trans;
      dataSource.push({
        key: id,
        tid: type ? type.id : "",
        date: formatDate(date),
        amount: `₦${amount}`,
        currency: currency ? currency.code : "",
      });
    });

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const [currentPagination, setCurrentPagination] = useState(1);

  useEffect(() => {
    if (totalItems) {
      setPagination({
        current: currentPagination,
        pageSize: 10,
        total: totalItems,
      });
    }
  }, [totalItems, currentPagination]);

  useEffect(() => {
    if (pageItems && pageItems.length > 0) {
      setCurrentAccount(pageItems[0].accountId);
    }
  }, [pageItems]);

  const [search, setSearch] = useState("");
  const [currentAccount, setCurrentAccount] = useState("");

  const handleTableChange = (pagination, filters, sorter) => {
    setCurrentPagination(pagination.current);
    let dataToSend = `?page=${pagination.current - 1}&size=10`;
    dispatch(
      getLoanAccountTransactionsPagination(currentAccount, {}, dataToSend)
    );
  };

  const handleAccount = () => {
    if (search !== "") {
      dispatch(getLoanAccountTransactions(search));
    }
  };
  const handleModalClose = () => toggleModal(false);

  const displayModalContent = () => {
    switch (modalContentName) {
      case "viewTrans":
        return (
          <ViewTransaction details={selectedTrans} onHide={handleModalClose} />
        );

      default:
        return;
    }
  };

  return (
    <>
      <ModalContainer show={modalState}>
        {modalState && displayModalContent()}
      </ModalContainer>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-5 pb-3">
            <h3 className="page-title">Loan Transactions</h3>
            <p className="sub-title">
              A complete list of client’s transactions in your business
            </p>
            <p className="font-weight sub-title mt-2">
              <span className="text-info">
                <Link to="/core/transactions">Transactions</Link>
              </span>{" "}
              / Loan Transactions
            </p>
          </div>
          <div className="col-sm-12 col-md-7 row px-0">
            {CheckPermission("READ_Client Loans Listing", permissions) ? (
              <>
                <InputGroup style={{ maxWidth: "450px" }}>
                  <InputLeftElement
                    width="3.1rem"
                    height="50px"
                    pointerEvents="none"
                  >
                    <img
                      src={
                        require("../../../../assets/images/icons/search.svg")
                          .default
                      }
                      style={{ height: "30px" }}
                      alt="Icon"
                    />
                  </InputLeftElement>
                  <Input
                    style={{
                      background: "#ffffff",
                      height: "50px",
                    }}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    type="text"
                    className="mb-3"
                    placeholder="Search for account number"
                  />
                </InputGroup>
                <ActionButton onClick={handleAccount}>
                  <img
                    src={
                      require("../../../../assets/images/icons/search.svg")
                        .default
                    }
                    alt="search"
                  />
                  Search
                </ActionButton>
              </>
            ) : (
              ""
            )}

            {/* </div>
            <div className="col-md-7">
              <ActionButtonsGroup>
                <div className="form-group mb-3">
                  <select className="custom-select" custom>
                    <option>NGN</option>
                  </select>
                </div>

                <div className="form-group mb-3">
                  <select className="custom-select px-4" custom>
                    <option>From: 04/20/2020</option>
                  </select>
                </div>
                <FormControl style={{ width: 120 }} className="date">
                  <DatePicker
                    id="published-date"
                    selected={transactionDate}
                    onChange={(date) => setTrasactionDate(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="from: dd/mmm/yyyy"
                  />
                </FormControl>
                
                <IconButton>
                  <img
                    src={
                      require("../../../../assets/images/icons/downloadArr.svg")
                        .default
                    }
                    alt="edit"
                  />
                </IconButton>
              </ActionButtonsGroup>
            </div>
           */}
            {/* <div className="form-group mb-3">
                  <select className="custom-select px-4" custom>
                    <option>From: 04/22/2020</option>
                  </select>
                </div> */}
          </div>
        </div>
        {CheckPermission("READ_Client Loans Listing", permissions) ? (
          <ContentWrapper>
            {loading ? (
              <TableLoader />
            ) : (
              <>
                {/* {filtered !== null ? (
                <Table
                  columns={columns}
                  dataSource={filteredHolidays}
                  // onRow={(r) => ({
                  //   onClick: () => (history.push(`/core/client/${r.key}`))
                  // })}
                />
              ) : ( */}
                <Table
                  columns={columns}
                  dataSource={dataSource}
                  pagination={pagination}
                  defaultPageSize={10}
                  loading={isTableLoading}
                  onChange={handleTableChange}
                  scroll={{ x: true }}
                  onRow={(r) => ({
                    onClick: () => handleViewTrans(r.key),
                  })}
                />
                {/* )} */}
              </>
            )}
          </ContentWrapper>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default LoanTransactionsView;
