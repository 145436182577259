import React, { useRef, useState } from 'react';
import { Table } from 'antd';
import { formatCurrency } from '../../../../../util/FormatAmount';
import { formatDate, formatMonth } from '../../../../../util/FormatDate';
import { ActionButton, IconButton, LineThrough } from './styles';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import ModalContainer from '../../../components/ModalContainer';
import PayChargesModal from '../../CustomerFixedDepositAccount/PayChargesModal';
import WaiveChargesModal from '../../CustomerFixedDepositAccount/WaiveChargesModal';
import PaySavingsChargeModal from '../PaySavingsChargeModal';
import WaiveSavingsChargeModal from '../WaiveSavingsChargeModal';

const columns = [
  { title: 'Name', dataIndex: 'name', key: 'name' },
  {
    title: 'Fee/Penalty',
    dataIndex: 'feeOrPenalty',
    key: 'feeOrPenalty',
  },
  { title: 'Payment Due At', dataIndex: 'dueAt', key: 'dueAt' },
  { title: 'Due As Of', dataIndex: 'dueAsof', key: 'dueAsof' },
  {
    title: 'Repeats On',
    dataIndex: 'repeatOn',
    key: 'repeatOn',
  },
  {
    title: 'Calculation Type',
    dataIndex: 'calculationType',
    key: 'calculationType',
  },
  {
    title: 'Due',
    dataIndex: 'due',
    key: 'due',
  },
  {
    title: 'Paid',
    dataIndex: 'paid',
    key: 'paid',
  },
  {
    title: 'Waived',
    dataIndex: 'waived',
    key: 'waived',
  },
  {
    title: 'Outstanding',
    dataIndex: 'outstanding',
    key: 'outstanding',
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
  },
];

// console.log("I am here");
const Charges = ({ charges = [] }) => {
  const [modalState, setModalState] = useState(false);
  const [modalContentName, setModalContentName] = useState('');
  const selecetedAmountOutstanding = useRef('');
  const selecetedTableIndex = useRef();
  const selectedChargedId = useRef();

  const savingsAndLoan = useSelector((state) => state.savingsAndLoan);

  let { savingsAccountDetails = {} } = savingsAndLoan || {};
  let { id } = savingsAccountDetails || {};

  const currentContent = (e, amount, index, newId) => {
    selecetedAmountOutstanding.current = amount;
    selecetedTableIndex.current = index;
    selectedChargedId.current = newId;
    const val = e.currentTarget.id.trim();
    // setDocumentId(docId)
    setTimeout(() => {
      if (val) {
        setModalState(true);
        setModalContentName(val);
      }
    }, 300);
  };

  const currentWaiveContent = (e, index, newId) => {
    selecetedTableIndex.current = index;
    selectedChargedId.current = newId;
    const val = e.currentTarget.id.trim();
    // setDocumentId(docId)
    setTimeout(() => {
      if (val) {
        setModalState(true);
        setModalContentName(val);
      }
    }, 300);
  };

  const handleModalClose = () => {
    setModalState(false);
    selecetedAmountOutstanding.current = '';
    selecetedTableIndex.current = null;
    selectedChargedId.current = null;
  };

  const displayModalContent = () => {
    switch (modalContentName) {
      case 'payCharges':
        return (
          <PaySavingsChargeModal
            id={id}
            newId={selectedChargedId.current}
            amount={selecetedAmountOutstanding.current}
            onHide={handleModalClose}
            selectedTableIndex={selecetedTableIndex.current}
          />
        );
      case 'waiveCharges':
        return (
          <WaiveSavingsChargeModal
            id={id}
            newId={selectedChargedId.current}
            onHide={handleModalClose}
            selectedTableIndex={selecetedTableIndex.current}
          />
        );
      default:
        return;
    }
  };

  let chargesData = [];

  charges.forEach((charge, index) => {
    const {
      newId = charge.id,
      amountOutstanding = 0,
      amountPaid = 0,
      amountWaived = 0,
      chargeCalculationType,
      chargeTimeType,
      dueDate,
      feeOnMonthDay,
      amount = 0,
      name,
      penalty,
    } = charge || {};

    chargesData.push({
      name: !!name ? name : '',
      feeOrPenalty: penalty ? 'Penalty' : 'Fee',
      dueAt: !!chargeTimeType ? chargeTimeType.value : '',
      // dueAsof: `${dueDate[2]}/${dueDate[1]}/${dueDate[0]}`,
      dueAsof: !!dueDate && !!dueDate.length ? formatDate(dueDate) : '',
      repeatOn: !!feeOnMonthDay && !!feeOnMonthDay.length ? formatMonth(feeOnMonthDay) : '',
      calculationType:
        !!chargeCalculationType && !!chargeCalculationType.value
          ? chargeCalculationType.value
          : '',
      due: !!amount ? formatCurrency(amount) : '',
      paid: !!amountPaid ? formatCurrency(amountPaid) : '',
      waived: !!amountWaived ? formatCurrency(amountWaived) : '',
      outstanding: !!amountOutstanding ? formatCurrency(amountOutstanding) : '',
      action: (
        <>
          {amountOutstanding !== 0 && amountOutstanding > 0 ? (
            <RenderTooltip text="Pay Charges" id="payCharges">
              <IconButton
                color="#FFFFFF"
                className="mr-2"
                id="payCharges"
                onClick={(e) =>
                  currentContent(e, amountOutstanding, index, newId)
                }
              >
                <img
                  src={
                    require('../../../../../assets/images/icons/approveCharges.svg')
                      .default
                  }
                  alt="pay"
                  style={{
                    height: "17px"
                  }}
                />
              </IconButton>
            </RenderTooltip>
          ) : (
            ''
          )}
          {amountOutstanding !== 0 && amountOutstanding > 0 ? (
            <RenderTooltip text="Waive Charges" id="waiveCharges">
              <IconButton
                className="mr-2"
                color="#FFFFFF"
                id="waiveCharges"
                onClick={(e) => currentWaiveContent(e, index, newId)}
              >
                <img
                  src={
                    require('../../../../../assets/images/icons/waiveCharges.svg')
                      .default
                  }
                  alt="waive"
                  style={{
                    height: "17px"
                  }}
                />
              </IconButton>
            </RenderTooltip>
          ) : (
            ''
          )}
        </>
      ),
      charge,
    });
  });
  return (
    <>
      <ModalContainer show={modalState} dialogClassName="document_infoModal">
        {modalState && displayModalContent()}
      </ModalContainer>
      <Table
        style={{
          overflow: 'hidden',
          overflowX: 'scroll',
        }}
        columns={columns}
        dataSource={chargesData}
        pagination={{ defaultPageSize: 4 }}
        defaultPageSize={4}
        onRow={(r) => ({})}
      />
    </>
  );
};

const RenderTooltip = ({ text, id, children }) => (
  <>
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={`tooltip-${id}`}>{text}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  </>
);

export default Charges;
