import React from "react";
import { Table } from "react-bootstrap";
import { ActionButton, FormTitle } from "../../styles";

import { formatCurrency } from "../../../../../../util/FormatAmount";

import { formatDate } from "../../../../../../util/FormatDate";

const LoanReview = ({
  handlePreviousScreen,
  handleSubmit,
  newLoanProduct,
  loanPurpose,
  customerLoanCounter,
  currency,
  decimalPlaces,
  multipleCurrency,
  installment,
  terms,
  // principal,
  max_pricipal,
  min_pricipal,
  repayment,
  min_repayment,
  max_repayment,
  repaid,
  max_interest,
  min_interest,
  def_interest,
  disbursal,
  // amortizationType,
  // interestType,
  isEqualAmortication,
  interestCalculationPeriodType,
  graceOnArrearsAgeing,
  graceOnPrincipalAndInterestPayment,
  inArrearsTolerance,
  transactionProcessingStrategyId,
  accountType,
  inArrearsToleranceValue,
  graceOnInterestCharged,
  graceOnArrearsAgeingValue,
  accountMovesOutOfNPAOnlyOnArrearsCompletion,
  daysInyear,
  template,
  daysInMonth,
  isInterestRecalculationEnabled,
  placeGuarantee,
  loading,
  loanTrancte,
  reviewData,
  myChargesArray,
  myOverdueChargesArray,
  collaterals,
}) => {
  let {
    productType,
    submittedOnDate,
    loanOfficer,
    // loanPurpose,
    purposeType,
    externalId,
    transactionProcessingStrategy,
    repaymentFrequencyType,
    principal,
    numberOfRepayments,
    interestRatePerPeriod,
    // loanPurpose,
    expectedDisbursementDate,
    fund,
    loanTermFrequencyType,
    loanTermFrequency,
    amortization,
    isEqualAmortization,
    interestType,
    repaymentsStartingFromDate,
    intType,
    repaymentEvery,
    repaymentFreqType,
    repaymentStartingFrom,
    interestChargedFrom,
  } = reviewData || {};

  console.log(formatDate(repaymentStartingFrom),
  formatDate(interestChargedFrom), "======")
  return (
    <>
      <div className="col-sm-12 col-md-12 d-flex ">
        <div className="container">
          <div>
            <FormTitle>
              <p className="text-info">Details</p>
              <div className="lineThrough"></div>
            </FormTitle>
            <div className="pt-2">
              <div
                className="form-group d-flex  align-items-center text-label"
                style={{ marginBottom: '1rem !important' }}
              >
                <h3
                  className="col-md-4"
                  style={{ fontFamily: 'AventaSemiBold', marginRight: '1rem' }}
                >
                  Product &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ fontFamily: 'Helvetica Neue' }}>{productType}</p>
              </div>
              <div
                className="form-group d-flex  align-items-center text-label"
                style={{ marginBottom: '1rem !important' }}
              >
                <h3
                  className="col-md-4"
                  style={{ fontFamily: 'AventaSemiBold', marginRight: '1rem' }}
                >
                  Loan Officer &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ fontFamily: 'Helvetica Neue' }}>{loanOfficer}</p>
              </div>
              <div
                className="form-group d-flex  align-items-center text-label"
                style={{ marginBottom: '1rem !important' }}
              >
                <h3
                  className="col-md-4"
                  style={{ fontFamily: 'AventaSemiBold', marginRight: '1rem' }}
                >
                  Loan Purpose &nbsp;&nbsp;&nbsp;:
                </h3>
                {/* <p style={{ fontFamily: 'Helvetica Neue' }}>{newLoanProduct.loanPurpose}</p> */}
                <p style={{ fontFamily: 'Helvetica Neue' }}>{purposeType}</p>
              </div>
              <div
                className="form-group d-flex  align-items-center text-label"
                style={{ marginBottom: '1rem !important' }}
              >
                <h3
                  className="col-md-4"
                  style={{ fontFamily: 'AventaSemiBold', marginRight: '1rem' }}
                >
                  Fund &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ fontFamily: 'Helvetica Neue' }}>{fund}</p>
              </div>
              <div
                className="form-group d-flex  align-items-center text-label"
                style={{ marginBottom: '1rem !important' }}
              >
                <h3
                  className="col-md-4"
                  style={{ fontFamily: 'AventaSemiBold', marginRight: '1rem' }}
                >
                  Submitted on &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ fontFamily: 'Helvetica Neue' }}>
                  {submittedOnDate}
                </p>
              </div>
              <div
                className="form-group d-flex  align-items-center text-label"
                style={{ marginBottom: '1rem !important' }}
              >
                <h3
                  className="col-md-4"
                  style={{ fontFamily: 'AventaSemiBold', marginRight: '1rem' }}
                >
                  Disbursement on &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ fontFamily: 'Helvetica Neue' }}>
                  {expectedDisbursementDate}
                </p>
              </div>
            </div>
          </div>

          <div className="pt-1">
            <FormTitle>
              <p className="text-info linkSavings">Terms</p>
              <div className="lineThrough"></div>
            </FormTitle>
            <div className="pt-2">
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  className="col-md-4"
                  style={{ fontFamily: 'AventaSemiBold', marginRight: '1rem' }}
                >
                  Principal &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ fontFamily: 'Helvetica Neue' }}>
                  {' '}
                  {formatCurrency(Number(principal))}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  className="col-md-4"
                  style={{ fontFamily: 'AventaSemiBold', marginRight: '1rem' }}
                >
                  Loan term &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ fontFamily: 'Helvetica Neue' }}>
                  {loanTermFrequency}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  className="col-md-4"
                  style={{ fontFamily: 'AventaSemiBold', marginRight: '1rem' }}
                >
                  Number of repayments &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ fontFamily: 'Helvetica Neue' }}>
                  {numberOfRepayments}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  className="col-md-4"
                  style={{ fontFamily: 'AventaSemiBold', marginRight: '1rem' }}
                >
                  Repaid every &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ fontFamily: 'Helvetica Neue' }}>
                  {repaymentEvery}&nbsp; {repaymentFreqType ? repaymentFreqType : ""}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  className="col-md-4"
                  style={{ fontFamily: 'AventaSemiBold', marginRight: '1rem' }}
                >
                  First repayment on &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ fontFamily: 'Helvetica Neue' }}>
                  {formatDate(repaymentStartingFrom)}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  className="col-md-4"
                  style={{ fontFamily: 'AventaSemiBold', marginRight: '1rem' }}
                >
                  Interest charged from &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ fontFamily: 'Helvetica Neue' }}>{formatDate(interestChargedFrom)}</p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  className="col-md-4"
                  style={{ fontFamily: 'AventaSemiBold', marginRight: '1rem' }}
                >
                  Nominal interest rate &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ fontFamily: 'Helvetica Neue' }}>{interestRatePerPeriod} &nbsp; Per Month </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  className="col-md-4"
                  style={{ fontFamily: 'AventaSemiBold', marginRight: '1rem' }}
                >
                  Interest method &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ fontFamily: 'Helvetica Neue' }}>{intType}</p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  className="col-md-4"
                  style={{ fontFamily: 'AventaSemiBold', marginRight: '1rem' }}
                >
                  Is Equal Amortization &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ fontFamily: 'Helvetica Neue' }}>
                  {isEqualAmortization}
                </p>
              </div>
              <div className="form-group d-flex  align-items-center text-label">
                <h3
                  className="col-md-4"
                  style={{ fontFamily: 'AventaSemiBold', marginRight: '1rem' }}
                >
                  Amortization &nbsp;&nbsp;&nbsp;:
                </h3>
                <p style={{ fontFamily: 'Helvetica Neue' }}>
                  {amortization}
                </p>
              </div>
            </div>
          </div>

          {collaterals?.length ? (
            <div className="pt-3">
              <FormTitle>
                <p className="text-info">Collaterals</p>
                <div className="lineThrough"></div>
              </FormTitle>
              <div className="pt-4">
                <Table className="child-table">
                  <thead>
                    <tr style={{ backgroundColor: "#FAFAFA" }}>
                      <th>S/N</th>
                      <th>Collateral Type</th>
                      <th>Value</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {collaterals?.map((collateral, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{collateral?.name}</td>
                        <td>{Number(collateral?.value)?.toLocaleString()}</td>
                        <td>{collateral?.description}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          ) : <></>}

          <div className="pt-3">
            <FormTitle>
              <p className="text-info">Charges</p>
              <div className="lineThrough"></div>
            </FormTitle>
            <div className="pt-4">
              <Table className="child-table">
                <thead className="">
                  <tr style={{ backgroundColor: '#FAFAFA' }}>
                    <td>Name</td>
                    <td>Type</td>
                    <td>Amount</td>
                    <td>Collected on</td>
                    <td>Date</td>
                  </tr>
                </thead>
                <tbody>
                  {myChargesArray && myChargesArray?.length > 0
                    ? myChargesArray.map(
                        (
                          {
                            chargeId,
                            name,
                            type,
                            amount,
                            chargeTimeTypeName,
                            date,
                            chargeTimeType,
                          },
                          index
                        ) => (
                          <tr key={chargeId}>
                            <td>{name}</td>
                            <td>{type}</td>
                            <td>{formatCurrency(Number(amount))}</td>
                            <td>{chargeTimeTypeName}</td>
                            <td>{formatDate(date)}</td>
                          </tr>
                        )
                      )
                    : null}
                </tbody>
              </Table>
            </div>
          </div>

          {myOverdueChargesArray.length > 0 ? (
            <div className="pt-5">
              <FormTitle>
                <p className="text-info">Overdue charges</p>
                <div className="lineThrough"></div>
              </FormTitle>
              <div className="pt-4">
                <Table className="child-table">
                  <thead className="">
                    <tr style={{ backgroundColor: '#FAFAFA' }}>
                      <td>Name</td>
                      <td>Type</td>
                      <td>Amount</td>
                      <td>Collected on</td>
                      <td>Date</td>
                    </tr>
                  </thead>
                  <tbody>
                    {myOverdueChargesArray.length > 0
                      ? myOverdueChargesArray.map(
                          (
                            {
                              chargeId,
                              name,
                              type,
                              amount,
                              chargeTimeTypeName,
                              date,
                            },
                            index
                          ) => (
                            <tr key={chargeId}>
                              <td>{name}</td>
                              <td>{type}</td>
                              <td>{formatCurrency(Number(amount))}</td>
                              <td>{chargeTimeTypeName}</td>
                              <td>{formatDate(date)}</td>
                            </tr>
                          )
                        )
                      : null}
                  </tbody>
                </Table>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      <div>
        <div className="float-right pt-5">
          <ActionButton
            color="#2C1DFF"
            style={{ margin: '0px 0px 0 10px' }}
            onClick={handlePreviousScreen}
          >
            <img
              src={
                require('../../../../../../assets/images/icons/cancel.svg')
                  .default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton
            onClick={handleSubmit}
            color="#2C1DFF"
            style={{ margin: '0px 0px 0 10px' }}
          >
            <img
              src={
                require('../../../../../../assets/images/icons/save.svg')
                  .default
              }
              alt="submit icon"
            />
            Submit
          </ActionButton>
        </div>
      </div>
    </>
  );
};

export default LoanReview;