import React, { useState, useEffect } from 'react';
import { ContentWrapper, ActionButton, EditButton } from './styles';
import { Table } from 'antd';
import ModalContainer from '../../../components/ModalContainer';
import NewTier from './components/NewTier';
import EditClientTier from './components/EditClientTier';
import DeleteClientTier from './components/DeleteClientTier';
import { useDispatch, useSelector } from 'react-redux';
import { formatAmount } from '../../../../../util/FormatAmount';
import { getClientTiers } from '../../../../../actions/systemTiersActions';
import TableLoader from './components/TableLoader';

const Tier = () => {
  const dispatch = useDispatch();
  const [modalState, toggleModal] = useState(false);
  const [modalContentName, setModalContentName] = useState('');
  const [selectedClientTierId, setSelectedClientTierId] = useState('');

  const { clientTiers, loading } = useSelector((state) => state.getSystemTiers);

  // clientTiers.sort((a, b) => (a.name > b.name ? 1 : -1));

  useEffect(() => {
    dispatch(getClientTiers());
  }, []);

  const handleDeleteTier = (id) => {
    setModalContentName('deleteClientTier');
    setSelectedClientTierId(id);
    toggleModal(true);
  };
  const handleEdit = (id) => {
    setModalContentName('editClientTier');
    setSelectedClientTierId(clientTiers.filter((item) => item.id === id)[0]);
    toggleModal(true);
  };

  const currentContent = (e) => {
    const val = e.currentTarget.id.trim();
    if (val) {
      toggleModal(true);
      setModalContentName(val);
    }
  };
  const handleModalClose = () => toggleModal(false);
  const displayModalContent = () => {
    switch (modalContentName) {
      case 'newClientTier':
        return <NewTier onHide={handleModalClose} />;
      case 'editClientTier':
        return (
          <EditClientTier
            data={selectedClientTierId}
            id={selectedClientTierId}
            onHide={handleModalClose}
          />
        );
      case 'deleteClientTier':
        return (
          <DeleteClientTier
            id={selectedClientTierId}
            onHide={handleModalClose}
          />
        );
      default:
        return;
    }
  };

  //Table configuration

  const columns = [
    {
      title: '   Tier name',
      dataIndex: 'tierName',
      key: 'tierName',
    },
    { title: 'Tier rank', dataIndex: 'tierRank', key: 'tierRank' },
    {
      title: 'Savings withdrawal limit',
      dataIndex: 'savingsWithdrawLimit',
      key: 'savingsWithdrawLimit',
    },
    {
      title: 'Daily withdrawal limit',
      dataIndex: 'dailyWithdrawLimit',
      key: 'dailyWithdrawLimit',
    },
    {
      title: 'Cumulative Balance',
      dataIndex: 'cumulativeBalance',
      key: 'cumulativeBalance',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    { title: 'Action', dataIndex: 'edit', key: 'edit' },
    { title: '', dataIndex: 'delete', key: 'delete' },
  ];
  const dataSource = [];
  !loading &&
    clientTiers.forEach((paymentType) => {
      console.log(paymentType , "paymentType") 
      const { id, tierRank, tierName, savingsWithdrawLimit, dailyWithdrawLimit, cumulativeBalance,  description } =
        paymentType;
      dataSource.push({
        key: id,
        id: id,
        tierRank: <p className="text-capitalize">{String(tierRank)}</p>,
        tierName: (
          <p style={{ marginLeft: '10px' }} className="text-capitalize">
            {String(tierName)}
          </p>
        ),
        description: <p>{description}</p>,
        savingsWithdrawLimit: formatAmount(savingsWithdrawLimit),
        dailyWithdrawLimit: formatAmount(dailyWithdrawLimit),
        cumulativeBalance: formatAmount(cumulativeBalance),
        edit: <EditButton onClick={() => handleEdit(id)}>Edit</EditButton>,
        delete: (
          <EditButton onClick={() => handleDeleteTier(id)}>Delete</EditButton>
        ),
      });
    });

  return (
    <>
      <ModalContainer show={modalState} dialogClassName="document_infoModal">
        {modalState && displayModalContent()}
      </ModalContainer>
      <div className="container-fluid">
        <div className="row justify-content-between pt-3">
          <div className="col-sm-12 col-md-8">
            <h3 className="page-title">Tier</h3>
            <p className="font-weight sub-title mt-2">
              A complete overview of your business with analysis and data
              metrics
            </p>
          </div>
          <div className="col-sm-12 d-flex justify-content-end pr-4 align-items-end col-md-4 px-0">
            <div className="row" style={{ maxWidth: '100%' }}>
              <div className="col-sm-12 col-md-6 p-0 d-flex">
                <ActionButton id="newClientTier" onClick={currentContent}>
                  <img
                    src={
                      require('../../../../../assets/images/icons/add.svg')
                        .default
                    }
                    alt="add"
                  />
                  New Tier Level
                </ActionButton>
              </div>
            </div>
          </div>
        </div>
        <ContentWrapper>
          {loading ? (
            <TableLoader />
          ) : (
            <Table
              columns={columns}
              dataSource={dataSource}
              pagination={{ defaultPageSize: 13 }}
              defaultPageSize={13}
            />
          )}
        </ContentWrapper>
      </div>
    </>
  );
};;

export default Tier;
