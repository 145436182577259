import React from "react";
import { useSelector } from "react-redux";
import { Select, Input } from "@chakra-ui/react";
import { ActionButton, FormClass, NewFormElements } from "../../styles";
import CurrencyFormat from "react-currency-format";
const SavingTerms = ({
  handleNextScreen,
  handlePreviousScreen,
  nominalAnnualInterestRate,
  handleNorminalIntRt,

  overdraftLimit,
  handleOverDraftLimit,
  minOverdraftForInterestCalculation,
  handleMinOverdraftForInterestCalculation,
  nominalAnnualInterestRateOverdraft,
  handleNominalAnnualInterestRateOverdraft,

  handleIntCalcType,
  interestCalculationTypeChk,
  handleIntCalcDIYType,
  interestCalcDIYType,
  handleCompoundingPeriod,
  interestCompPeriodType,
  handleIntPostPeriod,
  interestPostPeriodType,
  handleLockedInFreq,
  lockinPeriodFrequency,
  handleLockinFreqType,
  lockinPeriodFreqType,
  handleOpeningBal,
  minRequiredOpeningBalance,
  handleMinimumBal,
  minRequiredBalance,
  handleOverdraft,
  allowOverdraft,
  handleEnforceReqBal,
  enforceMinReqBal,
  handleWithdrawalFee,
  setTermsError,
  withdrawFeeForTrans,

  withdrawalLimit,
  numberOfWithdrawals,
  handleWithdrawalLimit,
}) => {
  const fetchedProductData = useSelector((state) => state.selectedOptionData);
  const { productOptionsData } = fetchedProductData;
  const {
    currency,
    interestCompoundingPeriodTypeOptions,
    interestPostingPeriodTypeOptions,
    interestCalculationTypeOptions,
    interestCalculationDaysInYearTypeOptions,
    lockinPeriodFrequencyTypeOptions,
  } = productOptionsData;
  

  const customOptions = [
    { id: "true", title: "Yes" },
    { id: "false", title: "No" },
  ];

  return (
    <>
      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-xl-6 col-md-12 ">
          <FormClass>
            <NewFormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">
                  Currency <span style={{ color: "red" }}>*</span>
                </label>
                <Input
                  style={{ width: "60%" }}
                  value={currency && currency.name}
                  size="md"
                  readOnly
                />
              </div>
            </NewFormElements>
          </FormClass>
        </div>
        <div className="col-xl-6 col-md-12">
          <FormClass>
            <NewFormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Decimal Places</label>
                <Input
                  style={{ width: "60%" }}
                  size="md"
                  value={currency && currency.decimalPlaces}
                  readOnly
                />
              </div>
            </NewFormElements>
          </FormClass>
        </div>
      </div>
      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-xl-6 col-md-12 ">
          <FormClass>
            <NewFormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">
                  Nominal Annual Interest{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <Input
                  style={{ width: "60%" }}
                  value={nominalAnnualInterestRate}
                  size="md"
                  onChange={handleNorminalIntRt}
                />
              </div>
            </NewFormElements>
          </FormClass>
        </div>
        <div className="col-xl-6 col-md-12">
          <FormClass>
            <NewFormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">
                  Interest Compounding Period{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  placeholder="Select Interest Compounding Period"
                  size="md"
                  onChange={handleCompoundingPeriod}
                  value={interestCompPeriodType}
                >
                  {interestCompoundingPeriodTypeOptions !== undefined &&
                    interestCompoundingPeriodTypeOptions.map((opt) => (
                      <option id={opt.id} key={opt.id}>
                        {opt.value}
                      </option>
                    ))}
                </Select>
              </div>
            </NewFormElements>
          </FormClass>
        </div>
      </div>
      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-xl-6 col-md-12 ">
          <FormClass>
            <NewFormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">
                  {" "}
                  Currency In Multiples Of{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <Input
                  style={{ width: "60%" }}
                  size="md"
                  value={currency && currency.inMultiplesOf}
                  readOnly
                />
              </div>
            </NewFormElements>
          </FormClass>
        </div>
        <div className="col-xl-6 col-md-12">
          <FormClass>
            <NewFormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">
                  Interest Posting Period{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  placeholder="Select Interest posting Period"
                  size="md"
                  onChange={handleIntPostPeriod}
                  value={interestPostPeriodType}
                >
                  {interestPostingPeriodTypeOptions !== undefined &&
                    interestPostingPeriodTypeOptions.map((opt) => (
                      <option id={opt.id} key={opt.id}>
                        {opt.value}
                      </option>
                    ))}
                </Select>
              </div>
            </NewFormElements>
          </FormClass>
        </div>
      </div>

      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-xl-6 col-md-12 ">
          <FormClass>
            <NewFormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">
                  Interest calculated using{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  placeholder="Select Interest calculated using"
                  size="md"
                  value={interestCalculationTypeChk}
                  onChange={handleIntCalcType}
                >
                  {interestCalculationTypeOptions !== undefined &&
                    interestCalculationTypeOptions.map((opt) => (
                      <option id={opt.id} key={opt.id}>
                        {opt.value}
                      </option>
                    ))}
                </Select>
              </div>
            </NewFormElements>
          </FormClass>
        </div>
        <div className="col-xl-6 col-md-12 ">
          <FormClass>
            <NewFormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">
                  No. of Days in Year <span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  placeholder="Select no. of days in year"
                  size="md"
                  value={interestCalcDIYType}
                  onChange={handleIntCalcDIYType}
                >
                  {interestCalculationDaysInYearTypeOptions !== undefined &&
                    interestCalculationDaysInYearTypeOptions.map((opt) => (
                      <option id={opt.id} key={opt.id}>
                        {opt.value}
                      </option>
                    ))}
                </Select>
              </div>
            </NewFormElements>
          </FormClass>
        </div>
      </div>
      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-xl-6 col-md-12 ">
          <FormClass>
            <NewFormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Minimum Opening Balance</label>
                {/* <Input
                  style={{ width: "60%" }}
                  size="md"
                  value={minRequiredOpeningBalance}
                  onChange={handleOpeningBal}
                /> */}
                <CurrencyFormat
                  value={minRequiredOpeningBalance}
                  thousandSeparator={true}
                  className="amount-input"
                  style={{
                    width: "60%",
                  }}
                  // prefix={"$"}
                  onValueChange={(values) => {
                    const { value } = values;
                    // formattedValue = $2,223
                    // value ie, 2223
                    handleOpeningBal(value);
                  }}
                />
              </div>
            </NewFormElements>
          </FormClass>
        </div>
        <div className="col-xl-6 col-md-12 ">
          <FormClass>
            <NewFormElements>
              <div className="form-group d-flex mb-10-imp justify-content-between align-items-center">
                <label className="text-label">Lock-in Period</label>
                <Input
                  style={{ width: "60%" }}
                  size="md"
                  value={lockinPeriodFrequency}
                  onChange={handleLockedInFreq}
                />
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label"></label>
                <Select
                  placeholder="Select one"
                  size="md"
                  value={lockinPeriodFreqType}
                  onChange={handleLockinFreqType}
                >
                  {lockinPeriodFrequencyTypeOptions !== undefined &&
                    lockinPeriodFrequencyTypeOptions.map((opt) => (
                      <option id={opt.id} key={opt.id}>
                        {opt.value}
                      </option>
                    ))}
                </Select>
              </div>
            </NewFormElements>
          </FormClass>
        </div>
      </div>
      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-xl-6 col-md-12 ">
          <FormClass>
            <NewFormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">
                  Apply Withdrawal Fee for Transfers <span>?</span>
                </label>
                <Select
                  placeholder="Select Yes or No"
                  size="md"
                  value={withdrawFeeForTrans}
                  onChange={handleWithdrawalFee}
                >
                  {customOptions.map((opt) => (
                    <option id={opt.id} key={opt.id}>
                      {opt.title}
                    </option>
                  ))}
                </Select>
              </div>
            </NewFormElements>
          </FormClass>
        </div>
        <div className="col-xl-6 col-md-12 ">
          <FormClass>
            <NewFormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">
                  Enforce Minimum Balance <span>?</span>
                </label>
                <Select
                  placeholder="Select Yes or No"
                  size="md"
                  value={enforceMinReqBal}
                  onChange={handleEnforceReqBal}
                >
                  {customOptions.map((opt) => (
                    <option id={opt.id} key={opt.id}>
                      {opt.title}
                    </option>
                  ))}
                </Select>
              </div>
            </NewFormElements>
          </FormClass>
        </div>
      </div>
      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-xl-6 col-md-12 d-none">
          <FormClass>
            <NewFormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">
                  {/* Enable withdrawal limit <br /> on interest posting */}
                  Enable Withdrawal Count <br /> For Interest Posting
                </label>
                <Select
                  placeholder="Select Yes or No"
                  size="md"
                  name="withdrawalLimit"
                  value={withdrawalLimit}
                  onChange={handleWithdrawalLimit}
                >
                  {customOptions.map((opt) => (
                    <option id={opt.id} key={opt.id}>
                      {opt.title}
                    </option>
                  ))}
                </Select>
              </div>
            </NewFormElements>
          </FormClass>
        </div>

        <div className="col-xl-6 col-md-12 ">
          <FormClass>
            <NewFormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Is Overdraft Allowed?</label>
                <Select
                  placeholder="Select Yes or No"
                  size="md"
                  value={allowOverdraft}
                  onChange={handleOverdraft}
                >
                  {customOptions.map((opt) => (
                    <option id={opt.id} key={opt.id}>
                      {opt.title}
                    </option>
                  ))}
                </Select>
              </div>
            </NewFormElements>
          </FormClass>
        </div>

        <div className="col-xl-6 col-md-12 d-none">
          <FormClass>
            <NewFormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">
                  Number of withdrawals <br /> allowed
                </label>
                <Input
                  style={{ width: "60%" }}
                  size="md"
                  name="numberOfWithdrawals"
                  value={numberOfWithdrawals}
                  onChange={handleWithdrawalLimit}
                />
              </div>
            </NewFormElements>
          </FormClass>
        </div>

        <div className="col-xl-6 col-md-12 ">
          <FormClass>
            <NewFormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Minimum Balance</label>
                {/* <Input
                  style={{ width: "60%" }}
                  size="md"
                  value={minRequiredBalance}
                  onChange={handleMinimumBal}
                /> */}
                <CurrencyFormat
                  value={minRequiredBalance}
                  thousandSeparator={true}
                  className="amount-input"
                  style={{
                    width: "60%",
                  }}
                  // prefix={"$"}
                  onValueChange={(values) => {
                    const { value } = values;
                    // formattedValue = $2,223
                    // value ie, 2223
                    handleMinimumBal(value);
                  }}
                />
              </div>
            </NewFormElements>
          </FormClass>
        </div>
      </div>

      {allowOverdraft === "Yes" && (
        <>
          <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
            <div className="col-xl-6 col-md-12 ">
              <FormClass>
                <NewFormElements>
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label d-flex flex-align-center">
                      Maximum Overdraft Amount Limit
                    </label>
                    <CurrencyFormat
                      value={overdraftLimit}
                      name="overdraftLimit"
                      thousandSeparator={true}
                      className="amount-input"
                      // placeholder="Minimum"
                      style={{
                        width: "60%",
                      }}
                      // prefix={"$"}
                      onValueChange={(values) => {
                        const { value } = values;
                        // formattedValue = $2,223
                        // value ie, 2223
                        handleOverDraftLimit(value);
                      }}
                    />
                  </div>
                </NewFormElements>
              </FormClass>
            </div>
            <div className="col-xl-6 col-md-12 ">
              <FormClass>
                <NewFormElements>
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label d-flex flex-align-center">
                      Min Overdraft Required <br/> For Interest Calculation
                    </label>
                    <CurrencyFormat
                      value={minOverdraftForInterestCalculation}
                      name="minOverdraftForInterestCalculation"
                      thousandSeparator={true}
                      className="amount-input"
                      style={{
                        width: "60%",
                      }}
                      onValueChange={(values) => {
                        const { value } = values;
                        handleMinOverdraftForInterestCalculation(value);
                      }}
                    />
                  </div>
                </NewFormElements>
              </FormClass>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
            <div className="col-xl-6 col-md-12 ">
              <FormClass>
                <NewFormElements>
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label d-flex flex-align-center">
                      Nominal Annual Interest <br/> Rate For Overdraft
                    </label>
                    <CurrencyFormat
                      value={nominalAnnualInterestRateOverdraft}
                      name="nominalAnnualInterestRateOverdraft"
                      thousandSeparator={true}
                      className="amount-input"
                      style={{
                        width: "60%",
                      }}
                      // prefix={"$"}
                      onValueChange={(values) => {
                        const { value } = values;
                        handleNominalAnnualInterestRateOverdraft(value);
                      }}
                    />
                  </div>
                </NewFormElements>
              </FormClass>
            </div>
          </div>
        </>
      )}

      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-xl-6 col-md-12 "></div>
      </div>

      <div>
        <div className="float-right pt-3">
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={handlePreviousScreen}
          >
            <img
              src={
                require("../../../../../../assets/images/icons/arrow-left.svg")
                  .default
              }
              alt="cancel icon"
            />{" "}
            Previous
          </ActionButton>
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={() => {
              currency &&
              nominalAnnualInterestRate &&
              interestCompPeriodType &&
              interestPostPeriodType &&
              interestCalculationTypeChk &&
              interestCalcDIYType
                ? setTermsError(false)
                : setTermsError(true);
              handleNextScreen();
            }}
          >
            <img
              src={
                require("../../../../../../assets/images/icons/arrow-right.svg")
                  .default
              }
              alt="submit icon"
            />{" "}
            Next
          </ActionButton>
        </div>
      </div>
    </>
  );
};

export default SavingTerms;
