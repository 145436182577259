import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BackSvg from "../../../../../assets/images/icons/back.svg";
import { ActionButton, ContentWrapper, IconButton } from "../styles";
import { getLoanDetails } from "../../../../../actions/SavingsAndLoanActions";
import { Table } from "antd";
import { formatCurrency } from "../../../../../util/FormatAmount";
import ModalContainer from "../../../components/ModalContainer";
import { DeleteGuarantor } from "./delete";
import { Table as BootstrapTable } from "react-bootstrap";
import { addCollateralsToAPendingLoan, fetchCollateralsAssociatedWithALoan, fetchCollateralTemplate, updateALoanCollateral } from "../../../../../actions/loanCollateralActions";
import CheckPermission from "../../../../../util/CheckPermission";
import { formatDate } from "../../../../../util/FormatDate";
import CurrencyFormat from "react-currency-format";
import { Input, Select, Textarea } from "@chakra-ui/react";
import { locale } from "moment";

const ViewCollateral = ({ currentId, setCurrentView }) => {
  const dispatch = useDispatch();

	const { loanCollaterals } = useSelector((state) => state.loanCollateralReducer);
  const savingsAndLoan = useSelector((state) => state.savingsAndLoan);
  let { loanDetails } = savingsAndLoan || {};
  const { responseUserData: { permissions } } = useSelector((state) => state.preAuth);
  const { loanCollateralTemplate } = useSelector(state => state.loanCollateralReducer);

  const [collateralId, setCollateralId] = useState(null);
  const [actionValue, setActionValue] = useState("");
  const [modalState, toggleModal] = useState(false);
  const [editable, setEditable] = useState({ state: false, id: null });
  const [newCollateral, setNewCollateral] = useState({ locale: "en" });
  const [showNewCollateralForm, setShowNewCollateralForm] = useState(false);
  const [collateralToBeAdded, setCollateralToBeAdded] = useState({
    type: '',
    value: '',
    description: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCollateralToBeAdded(collateral => ({
      ...collateral,
      [name]: value,
    }));
  };

  const toggleNewCollateralForm = () => {
    setShowNewCollateralForm(!showNewCollateralForm);
  };

  const toggleEditable = (id, state) => {
    setEditable({ id, state });
  };

  let {
    clientId,
    loanProductName,
    accountNo,
    externalId,
    loanOfficerName,
    linkedAccount,
    approvedPrincipal = 0,
    timeline = {},
    status = {},
    currency = {},
    repaymentSchedule = {},
    summary = {},
  } = loanDetails || {};

  let { periods = [], totalPrincipalDisbursed = 0 } = repaymentSchedule || {};

  let { expectedDisbursementDate = [] } = timeline || {};

  let mainPrincipalOutstanding = 0;

  periods.forEach((period) => {
    let { principalOutstanding = 0 } = period || {};
    // For Principal
    mainPrincipalOutstanding += principalOutstanding;
  });

  useEffect(() => {
    dispatch(fetchCollateralsAssociatedWithALoan(currentId));
    dispatch(getLoanDetails(currentId));
  }, [currentId]);

  useEffect(() => {
    clientId && dispatch(fetchCollateralTemplate(clientId));
  }, [clientId]);

  const columns = (status.pendingApproval || status.active) ? [
    { title: "S/N", dataIndex: "serialNo", key: "serialNo" },
    { title: "Collateral Type", dataIndex: "collateralType", key: "collateralType" },
    { title: "Value", dataIndex: "value", key: "value" },
    { title: "Description", dataIndex: "description", key: "description" },
    { title: "Actions", dataIndex: "actions", key: "actions" },
  ] : [
    { title: "S/N", dataIndex: "serialNo", key: "serialNo" },
    { title: "Collateral Type", dataIndex: "collateralType", key: "collateralType" },
    { title: "Value", dataIndex: "value", key: "value" },
    { title: "Description", dataIndex: "description", key: "description" },
  ];

  const callback = () => {
    dispatch(fetchCollateralsAssociatedWithALoan(currentId));
    resetCollateralToBeAdded();
    toggleEditable(null, false);
  };

  const updateCollateral = () => {
    dispatch(updateALoanCollateral(
      currentId,
      newCollateral.id, {
        description: newCollateral.description,
        value: newCollateral.value,
        locale: "en",
      }, callback
    ));
  };

  const resetCollateralToBeAdded = () => {
    setCollateralToBeAdded({
      type: '',
      value: '',
      description: '',
    });
  };

  const submitCollaterals = () => {
    dispatch(addCollateralsToAPendingLoan(currentId, {
      collateral: [{
        type: Number(collateralToBeAdded?.type),
        value: collateralToBeAdded?.value,
        description: collateralToBeAdded?.description
      }],
      locale:"en",
      dateFormat: "dd MMM yyyy",
    }, callback));
  };

  const displayModalContent = () => {
    switch (actionValue) {
      case "delete":
        return (
          <DeleteGuarantor
						loanId={currentId}
						collateralId={collateralId}
            handleCloseModal={handleCloseModal}
          />
        );
      default:
        break;
    }
  };

  const handleCloseModal = () => {
    toggleModal(false);
    return setActionValue("");
  };

  const handleClick = (value) => {
    setActionValue(value);
    return toggleModal(true);
  };

  let collaterals = [];

  loanCollaterals?.forEach((collateral, index) => {
		collaterals.push({
			key: collateral?.id,
			id: collateral?.id,
			serialNo: index + 1,
			collateralType: collateral?.type?.name,
			value: (editable.state && editable.id === collateral.id) ? (
        <CurrencyFormat
          value={newCollateral?.value}
          style={{ border: "1px solid #E0E0E0", borderRadius: "5px", padding: "8px" }}
          thousandSeparator={true}
          className="amount-input"
          prefix={`${collateral?.currency?.code} `}
          onValueChange={({ value }) => {
            setNewCollateral(collateral => ({
              ...collateral,
              value
            }));
          }}
        />
      ) : `${collateral?.currency?.code} ${formatCurrency(collateral?.value)}`,
			description: (editable.state && editable.id === collateral.id) ? (
        <Input
          size="md"
          // name="description"
          style={{ border: "1px solid #E0E0E0", borderRadius: "5px", padding: "8px" }}
          value={newCollateral?.description}
          onChange={(e) => {
            setNewCollateral(collateral => ({
              ...collateral,
              description: e.target.value
            }));
          }}
        />
      ) : collateral?.description,
			actions: (status.pendingApproval || status.active) ? (
				<>
          {(editable.state && editable.id === collateral.id) ? (
            <>
              <IconButton onClick={updateCollateral} className="mr-1 mb-1">
                <img
                  src={require("../../../../../assets/images/icons/check-mark.svg").default}
                  alt="approve collateral update"
                  className="icon-img"
                />
              </IconButton>
              <IconButton onClick={() => toggleEditable(null, false)} className="mr-1 mb-1">
                <img
                  src={require("../../../../../assets/images/icons/cancel.svg").default}
                  alt="cancel collateral update"
                  className="icon-img"
                />
              </IconButton>
            </>
          ) : (
            <>
              {CheckPermission("UPDATE_COLLATERAL", permissions) && (
                <IconButton
                  onClick={() => {
                    toggleEditable(collateral.id, true);
                    setNewCollateral({
                      id: collateral.id,
                      description: collateral.description,
                      value: collateral.value,
                    });
                  }}
                  className="mr-1 mb-1"
                >
                  <img
                    src={require("../../../../../assets/images/icons/edit.svg").default}
                    alt="edit collateral"
                    className="icon-img"
                  />
                </IconButton>
              )}
              {CheckPermission("DELETE_COLLATERAL", permissions) && (
                <IconButton onClick={() => handleClick("delete")} className="mr-1 mb-1">
                  <img
                    src={require("../../../../../assets/images/icons/delete.svg").default}
                    alt="remove collateral"
                    className="icon-img"
                  />
                </IconButton>
              )}
            </>
          )}
        </>
			) : <></>,
		});
	});

  return (
    <ContentWrapper>
      <div className="row">
        <div className="col-xl-6 col-md-6">
          <p onClick={() => setCurrentView(2)} className="back-btn d-flex">
            <img className="mr-2" src={BackSvg} alt="" />
            Back
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12 col-md-12">
          <div className="spaceBetweenFlexEnd"></div>
        </div>
      </div>
      <div className="row ml-0 mt-3">
        <div className="col">
          <p className="account-view-gua-details-main">
            {!!loanProductName ? loanProductName : ""}
          </p>
          <p className="account-view-gua-details">
            <span className="headerSpan-view-gua">Loan account</span>:{" "}
            {!!accountNo ? accountNo : ""}
          </p>
          <p className="account-view-gua-details">
            <span className="headerSpan-view-gua">Saving account</span>:{" "}
            {!!linkedAccount?.accountNo ? linkedAccount?.accountNo : ""}
          </p>
          {status.active ? (
            <p className="account-view-gua-details">
              <span className="headerSpan-view-gua">Loan Balance</span>:{" "}
              {formatCurrency(mainPrincipalOutstanding)}
            </p>
          ) : null}{" "}
          {status.active ? (
            <p className="account-view-gua-details">
              <span className="headerSpan-view-gua">Arrears By</span>:{" "}
              {formatCurrency(summary.totalOverdue)}
            </p>
          ) : null}
        </div>
      </div>

      <div className="row">
        <BootstrapTable bordered className="table-form-style col-md-5 m-4">
          <tbody>
            {status.active && !!expectedDisbursementDate.length ? (
              <tr>
                <td className="font-weight-bold md-font-weight">
                  Disbursement Date
                </td>
                <td>
                  {expectedDisbursementDate.length ? formatDate(expectedDisbursementDate) : ""}
                </td>
              </tr>
            ) : null}

            <tr>
              <td className="font-weight-bold md-font-weight">Loan Status</td>
              <td>{status.value}</td>
            </tr>

            <tr>
              <td className="font-weight-bold md-font-weight">Loan Officer</td>
              <td>{!!loanOfficerName ? loanOfficerName : "Not Assigned"}</td>
            </tr>

            <tr>
              <td className="font-weight-bold md-font-weight">External Id</td>
              <td>{!!externalId ? externalId : "Non Assigned"}</td>
            </tr>
          </tbody>
        </BootstrapTable>

        <BootstrapTable bordered className="table-form-style col-md-5 m-4">
          <tbody>
            {status.active && !!currency.displayLabel ? (
              <tr>
                <td className="font-weight-bold md-font-weight">Currency</td>
                <td>{!!currency.displayLabel ? currency.displayLabel : ""}</td>
              </tr>
            ) : null}
            <tr>
              <td className="font-weight-bold md-font-weight">Loan Purpose</td>
              <td>Not Provided</td>
            </tr>
            <tr>
              <td className="font-weight-bold md-font-weight">
                Approved Amount
              </td>
              <td>{formatCurrency(approvedPrincipal)}</td>
            </tr>

            <tr>
              <td className="font-weight-bold md-font-weight">
                Disburse Amount
              </td>
              <td>{formatCurrency(totalPrincipalDisbursed)}</td>
            </tr>
          </tbody>
        </BootstrapTable>
      </div>

      <div className="d-flex justify-content-end my-4">
        {(status.pendingApproval || status.active) && (
          <ActionButton onClick={toggleNewCollateralForm} color="#2C1DFF">
            {showNewCollateralForm ? (
              <img
                src={require("../../../../../assets/images/icons/passwordIcon.svg").default}
                alt="forward icon"
              />
            ) : (
              <img
                src={require("../../../../../assets/images/icons/add.svg").default}
                alt="forward icon"
              />
            )}
            {showNewCollateralForm ? "Hide form" : "Add collateral"}
          </ActionButton>  
        )}
      </div>

      {showNewCollateralForm && (
        <div className="d-flex flex-column w-100 mb-4">
          <div className="d-flex justify-content-between w-100" style={{ gap: '1rem' }}>
            <div className="form-group d-flex flex-column select-100-width" style={{ width: '33%' }}>
              <label className="text-label mt-1">Collateral Type</label>
              <Select
                placeholder="Select one"
                size="md"
                value={collateralToBeAdded.type}
                name="type"
                onChange={handleChange}
              >
                {loanCollateralTemplate?.allowedCollateralTypes?.map((type) => (
                  <option className="w-100" value={type.id} key={type.id}>
                    {type.name}
                  </option>
                ))}
              </Select>
            </div>
            <div className="form-group d-flex flex-column" style={{ width: '33%' }}>
              <label className="text-label mt-1">Value</label>
              <div className="d-flex flex-column">
                <CurrencyFormat
                  value={collateralToBeAdded?.value}
                  thousandSeparator={true}
                  className="amount-input"
                  // prefix={template?.currency?.displaySymbol}
                  onValueChange={({ value }) => {
                    setCollateralToBeAdded(collateral => ({
                      ...collateral,
                      value
                    }));
                  }}
                />
                <div className="d-flex align-items-end mt-1">
                  <img
                    src={require("../../../../../assets/images/icons/info-circle-2.svg").default}
                    width={18}
                    height={18}
                    alt="tooltip icon"
                  />
                  <span className="fs-10 ml-1">Amount the collateral guarantees.</span>
                </div>
              </div>
            </div>
            <div className="form-group d-flex flex-column" style={{ width: '33%' }}>
              <label className="text-label mt-1">Description</label>
              <div className="w-100">
                <Textarea
                  style={{ fontSize: "12px", minWidth: '100%' }}
                  name="description"
                  value={collateralToBeAdded?.description}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="d-flex align-self-end" style={{ width: "fit-content" }}>
            <ActionButton
              color={
                collateralToBeAdded.type &&
                collateralToBeAdded.value &&
                collateralToBeAdded.description
                ? "#2C1DFF"
                : "#e3e3ff"
              }
              disabled={
                !collateralToBeAdded.type &&
                !collateralToBeAdded.value &&
                !collateralToBeAdded.description
              }
              onClick={submitCollaterals}
            >
              <img
                src={
                  require("../../../../../assets/images/icons/add.svg")
                    .default
                }
                alt="forward icon"
              />
              Add Collateral
            </ActionButton>
          </div>
        </div>
      )}

      <div>
        <ModalContainer size="md" show={modalState}>
          {modalState && displayModalContent()}
        </ModalContainer>
        <div className="mt-1">
          <Table
            style={{
              overflow: "hidden",
              overflowX: "scroll",
            }}
            columns={columns}
            dataSource={collaterals}
            pagination={{ defaultPageSize: 5 }}
            defaultPageSize={5}
            onRow={(row) => ({
              onClick: () => setCollateralId(row?.id)
            })}
          />
        </div>
      </div>
    </ContentWrapper>
  );
};

export default ViewCollateral;
