import React, { useState, useEffect } from "react";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Textarea } from "@chakra-ui/textarea";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleTellerGroupCashier,
  buySellCash,
  getAllCashierActionRequestsReceived,
  getCashierDataTeller,
} from "../../../actions/tellerActions";
import { Select } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { Heading, ActionButton, FlexContainer, ButtonGroup } from "../styles";
import { Container, Card, FormGroup } from "./styled";
import CurrencyFormat from "react-currency-format";
import ReportTable from "./table";

const Requests = () => {
  const { singleTellerGroupCashiers } = useSelector(
    (state) => state.tellerReducer
  );

  let currentCashierId = localStorage.getItem("cashierId");
  let tellerId = localStorage.getItem("tellerGroupId");

  const dispatch = useDispatch();
  const [personna, setPersonna] = useState("");
  const [amount, setAmount] = useState("");
  const [cashierId, setCashierId] = useState("");
  const [actionTypeId, setActionType] = useState("");
  const [glAccountId, setGlAccountId] = useState("");
  const [requestNote, setNote] = useState("");
  const [cashierNote, setCashierNote] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [amountError, setAmountError] = useState("");

  const userDetails = useSelector((state) => state.userDetails);
  // const sample = useSelector((state) => console.log(state));

  const cashierDataTeller = useSelector((state) => state.cashierDataTeller);

  const {
    userDetails: {
      cashierData: { currencies = [], defaultCurrency = {} } = {},
    },
  } = userDetails || {};

  const { cashierDataDetailsTeller } = cashierDataTeller || {};

  const history = useHistory();

  const defaultCurrencyValue = defaultCurrency.code;

  // let currentCashierId = localStorage.getItem("cashierId");

  let tellerIdNew = localStorage.getItem("tellerGroupId");

  const [dropdownValue, setDropdownValue] = useState("");
  const local = localStorage.getItem("myDefaultValue");

  useEffect(() => {
    setDropdownValue(local || defaultCurrencyValue);
  }, [defaultCurrencyValue, local]);

  useEffect(() => {
    if (amount.length > 0) {
      setAmountError("");
    }
  }, [amount.length]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (amount === "") {
      setAmountError("Amount is required");
      return;
    } else if (amount.length > 0) {
      setAmountError("");
    }

    let glArray = [];
    if (singleTellerGroupCashiers?.debitGLAccounts && actionTypeId === "6") {
      singleTellerGroupCashiers?.debitGLAccounts?.forEach((e) => {
        if (e.id === +glAccountId) {
          const test = {
            glAccountId: e.id,
            amount: amount,
            comments: cashierNote,
          };
          glArray.push(test);
        }
      });
    } else if (
      singleTellerGroupCashiers?.creditGLAccounts &&
      actionTypeId === "7"
    ) {
      singleTellerGroupCashiers?.creditGLAccounts?.forEach((e) => {
        if (e.id === +glAccountId) {
          const test = {
            glAccountId: e.id,
            amount: amount,
            comments: cashierNote,
          };
          glArray.push(test);
        }
      });
    }
    const dataToCashier = {
      locale: "en",
      dateFormat: "dd MMMM yyyy",
      currencyCode: dropdownValue,
      amount: amount,
      requestNote: requestNote,
      actionTypeId: actionTypeId,
      actionPersonaTypeId: personna,
      linkedCashierId: cashierId,
    };
    const dataToVault = {
      locale: "en",
      dateFormat: "dd MMMM yyyy",
      currencyCode: dropdownValue,
      amount: amount,
      requestNote: requestNote,
      actionTypeId: actionTypeId,
      actionPersonaTypeId: personna,
    };
    const dataToJournamPostingDebit = {
      locale: "en",
      dateFormat: "dd MMMM yyyy",
      currencyCode: dropdownValue,
      amount: amount,
      actionTypeId: actionTypeId,
      actionPersonaTypeId: personna,
      requestNote: requestNote,
      cashierNote: cashierNote,
      referenceNumber: referenceNumber,
      debits: glArray,
    };
    const dataToJournamPostingCredit = {
      locale: "en",
      dateFormat: "dd MMMM yyyy",
      currencyCode: dropdownValue,
      amount: amount,
      actionTypeId: actionTypeId,
      actionPersonaTypeId: personna,
      requestNote: requestNote,
      cashierNote: cashierNote,
      referenceNumber: referenceNumber,
      credits: glArray,
    };
    if (!!cashierId) {
      dispatch(buySellCash(dataToCashier));
    } else if (actionTypeId === "6") {
      dispatch(buySellCash(dataToJournamPostingDebit));
    } else if (actionTypeId === "7") {
      dispatch(buySellCash(dataToJournamPostingCredit));
    } else {
      dispatch(buySellCash(dataToVault));
    }

    setAmount("");
    setNote("");
    setPersonna("");
    setActionType("");
    setCashierId("");
    setCashierNote("");
  };

  const refetchRequests = () => {
    if (currentCashierId) {
      dispatch(
        getAllCashierActionRequestsReceived(
          `cashierId=${currentCashierId}&status=1000&offset=0&limit=10&sortOrder=DESC`
        )
      );
    }
  };

  const navigate = () => {
    history.push("/teller/requests/history");
  };

  useEffect(() => {
    if (personna === 2 || personna === "2") {
      setCashierId("");
    }
  }, [personna]);

  useEffect(() => {
    if (tellerId) {
      dispatch(getSingleTellerGroupCashier(tellerId));
    }
  }, [dispatch, tellerId]);


  useEffect(() => {
    if (dropdownValue && currentCashierId && tellerIdNew) {
      dispatch(getCashierDataTeller(tellerIdNew,dropdownValue, currentCashierId));
    }
  }, [dropdownValue ,currentCashierId, tellerIdNew ])

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-12 col-lg-6 pb-4 pl-3">
            <FlexContainer className="flex">
              <h3 className="page-title">Requests</h3>
            </FlexContainer>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6">
            <ButtonGroup>
              <ActionButton onClick={navigate}>Requests History</ActionButton>
            </ButtonGroup>
          </div>
        </div>
        <Container>
          <Card className="mr-3">
            <div className="heading">
              <h1>Send request</h1>
            </div>
            <div className="content">
              <form onSubmit={(e) => handleSubmit(e)}>
                <FormControl>
                  <FormGroup>
                    {/* {amountError} */}
                    <label className="text-label d-flex flex-align-center">
                      Amount
                    </label>
                    <div className="d-flex flex-column justify-">
                      <small className="text-danger mb-2 h6 text-left">
                        {amountError}
                      </small>
                      <CurrencyFormat
                        placeholder="Enter amount"
                        value={amount}
                        onValueChange={(values) => {
                          const { value } = values;
                          setAmount(value);
                        }}
                        thousandSeparator={true}
                        className={`amount-input  ${
                          amountError ? "amount-input-error" : ""
                        }`}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <label className="text-label d-flex flex-align-center">
                      Personnel
                    </label>
                    <div className="select-100-width">
                      <Select
                        isRequired
                        value={personna}
                        onChange={(e) => setPersonna(e.target.value)}
                        placeholder="Select option"
                      >
                        <option value="2">Vault</option>
                        <option value="1">Cashier</option>
                        <option value="4">Journal posting</option>
                      </Select>
                    </div>
                  </FormGroup>
                  {!!personna && personna === "4" ? (
                    <FormGroup>
                      <label className="text-label d-flex flex-align-center">
                        Action type
                      </label>
                      <div className="select-100-width">
                        <Select
                          value={actionTypeId}
                          isRequired
                          onChange={(e) => setActionType(e.target.value)}
                          placeholder="Select option"
                        >
                          <option value="6">Debit posting</option>
                          <option value="7">Credit posting</option>
                        </Select>
                      </div>
                    </FormGroup>
                  ) : (
                    <FormGroup>
                      <label className="text-label d-flex flex-align-center">
                        Action type
                      </label>
                      <div className="select-100-width">
                        <Select
                          value={actionTypeId}
                          isRequired
                          onChange={(e) => setActionType(e.target.value)}
                          placeholder="Select option"
                        >
                          <option value="1">Buy</option>
                          <option value="2">Sell</option>
                        </Select>
                      </div>
                    </FormGroup>
                  )}
                  {!!actionTypeId &&
                  (actionTypeId === "6" || actionTypeId === "7") ? (
                    <FormGroup>
                      <label className="text-label d-flex flex-align-center">
                        GL account
                      </label>
                      <div className="select-100-width">
                        <Select
                          value={glAccountId}
                          isRequired
                          onChange={(e) => setGlAccountId(e.target.value)}
                          placeholder="Select GL"
                        >
                          {singleTellerGroupCashiers !== undefined &&
                          singleTellerGroupCashiers?.creditGLAccounts?.length &&
                          actionTypeId === "7"
                            ? singleTellerGroupCashiers?.creditGLAccounts &&
                              singleTellerGroupCashiers?.creditGLAccounts?.map(
                                (each) => (
                                  <option value={each.id} key={each.id}>
                                    {each.name}
                                  </option>
                                )
                              )
                            : singleTellerGroupCashiers?.debitGLAccounts &&
                              singleTellerGroupCashiers?.debitGLAccounts?.map(
                                (each) => (
                                  <option value={each.id} key={each.id}>
                                    {each.name}
                                  </option>
                                )
                              )}
                        </Select>
                      </div>
                    </FormGroup>
                  ) : (
                    ""
                  )}
                  {!!personna && personna === "1" ? (
                    <FormGroup>
                      <label className="text-label d-flex flex-align-center">
                        Cashier
                      </label>
                      <div className="select-100-width">
                        <Select
                          placeholder="Select cashier"
                          size="sm"
                          required
                          layerStyle={{ width: "70%" }}
                          value={cashierId}
                          onChange={(e) => setCashierId(e.target.value)}
                        >
                          {cashierDataDetailsTeller !== undefined &&
                          cashierDataDetailsTeller?.cashiers?.length
                            ? cashierDataDetailsTeller?.cashiers &&
                              cashierDataDetailsTeller?.cashiers?.map(
                                (each) => (
                                  <option value={each.id} key={each.id}>
                                    {each.staffName}
                                  </option>
                                )
                              )
                            : "No other teller"}
                        </Select>
                      </div>
                    </FormGroup>
                  ) : (
                    ""
                  )}
                  <FormGroup>
                    <label className="text-label d-flex flex-align-center">
                      Request Note<span className="required-color">*</span>
                    </label>
                    <div className="textarea-100-width">
                      <Textarea
                        required
                        style={{ fontSize: "12px" }}
                        value={requestNote}
                        onChange={(e) => setNote(e.target.value)}
                      />
                    </div>
                  </FormGroup>
                  {!!personna && personna === "4" ? (
                    <>
                      <hr style={{ padding: "10px" }} />
                      <FormGroup>
                        <label className="text-label d-flex flex-align-center">
                          Cashier Note<span className="required-color">*</span>
                        </label>
                        <div className="textarea-100-width">
                          <Textarea
                            required
                            style={{ fontSize: "12px" }}
                            value={cashierNote}
                            onChange={(e) => setCashierNote(e.target.value)}
                          />
                        </div>
                      </FormGroup>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="d-flex justify-content-start">
                    <ActionButton>Submit</ActionButton>
                  </div>
                </FormControl>
              </form>
            </div>
          </Card>
          <Card className="ml-3">
            <div className="heading d-flex justify-content-between ">
              <h1>Received request</h1>
              <button className="button" onClick={refetchRequests}>
                <img
                  className="mr-2 d-inline icon-img"
                  src={
                    require("../../../assets/images/icons/refresh.svg").default
                  }
                  style={{ height: "15px" }}
                  alt="refresh"
                />
                Refresh
              </button>
            </div>
            <ReportTable currentCashierId={currentCashierId} />
          </Card>
        </Container>
      </div>
    </>
  );
};

export default Requests;
