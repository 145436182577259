import API_LOAN, {
  approveReschedule,
  createLoanNote,
  createSavingsNote,
  deleteCollateral,
  deleteLoanNote,
  deleteSavingsNote,
  editRepaymentSchedule,
  fetchLoanNotes,
  fetchSavingsNotes,
  generateRepaymentSchedule,
  getLoanCharges,
  getLoanCollateralTemplate,
  getLoanCollaterals,
  getRescheduleLoanTemplate,
  getRescheduledLoanRequests,
  multipleAccTransfer,
  previewLoanReschedule,
  rejectReschedule,
  updateLoanNote,
  updatePendingLoanWithCollaterals,
  updateSavingsNote,
} from "../services/Api/savings.loan.api.service";
import {
  SET_LOADING_STATE,
  SET_TABLE_LOADING_STATE,
} from "../constants/utilityConstants";
import {
  SET_SAVINGS_ACCOUNT_DETAILS,
  SET_LOAN_DETAILS,
  SET_CUSTOMER_OPTIONS,
  SET_SAVINGS_ACCOUNT_TEMPLATE,
  SET_SAVINGS_ACCOUNT_EMAIL_NOTIFICATIONS,
  SET_SAVINGS_ACCOUNT_SMS_NOTIFICATIONS,
  SET_SAVINGS_ACCOUNT_CHARGE_TEMPLATE,
  SET_SAVINGS_ACCOUNT_TRANSACTION_TEMPLATE,
  SET_TRANSFER_SAVINGS_ACCOUNT_TEMPLATE,
  SET_LOAN_DOCUMENT,
  SET_LOAN_ACCOUNT_TEMPLATE,
  SET_LOAN_DISBURSE_DETAILS,
  SET_REPAY_LOAN_TEMPLATE,
  SHOW_TRANSFER_MODAL_SUCCESSFUL,
  SHOW_MULTIPLE_TRANSFER_MODAL_SUCCESSFUL,
  SET_RESCHEDULED_LOAN_REQUESTS,
  SET_RESCHEDULED_LOAN_DETAILS,
  SET_SAVINGS_TRANSACTIONS,
  SET_SAVINGS_EVENTS,
  SET_OPTIONS_EVENTS,
  SET_LOAN_TRANSACTIONS,
  SET_SAVINGS_ACCOUNT_AUDIT,
  SET_LOAN_ACCOUNT_AUDIT,
  SET_LOAN_CHART,
  SET_SAVINGS_CHART,
  UPDATE_CHARGES,
  WAIVE_CHARGES,
  DELETE_LOAN_GUARANTOR_REQUEST,
  RECOVER_LOAN_GUARANTOR_REQUEST,
  RECOVER_LOAN_GUARANTOR_SUCCESS,
  RECOVER_LOAN_GUARANTOR_FAIL,
  DELETE_LOAN_GUARANTOR_FAIL,
  DELETE_LOAN_GUARANTOR_SUCCESS,
  SET_LOAN_ACCOUNT_CHARGES,
  LOAN_ACCOUNT_REQUEST,
  LOAN_ACCOUNT_FAIL,
  LOAN_ACCOUNT_SUCCESS,
  UPDATE_LOAN_ACCOUNT_REQUEST,
  SET_LOAN_GUARANTOR_TEMPLATE,
  UPDATE_LOAN_ACCOUNT_SUCCESS,
  UPDATE_LOAN_ACCOUNT_FAIL,
  UPDATE_LOAN_CHARGES,
  WAIVE_LOAN_CHARGES,
  ADD_CHARGE_TO_LOAN_ACCOUNT_REQUEST,
  ADD_CHARGE_TO_LOAN_ACCOUNT,
  SET_LOAN_ACCOUNTS_CHARGE_TEMPLATE,
  REPAYMENT_SCHEDULE_SUCCESS,
  REPAYMENT_SCHEDULE_FAIL,
  REPAYMENT_SCHEDULE_REQUEST,
  FETCH_LOAN_NOTES_REQUEST,
  FETCH_LOAN_NOTES_SUCCESS,
  FETCH_LOAN_NOTES_FAIL,
  CREATE_LOAN_NOTE_REQUEST,
  CREATE_LOAN_NOTE_SUCCESS,
  CREATE_LOAN_NOTE_FAIL,
  UPDATE_LOAN_NOTE_REQUEST,
  UPDATE_LOAN_NOTE_SUCCESS,
  UPDATE_LOAN_NOTE_FAIL,
  DELETE_LOAN_NOTE_REQUEST,
  DELETE_LOAN_NOTE_SUCCESS,
  DELETE_LOAN_NOTE_FAIL,
  FETCH_SAVINGS_NOTES_REQUEST,
  FETCH_SAVINGS_NOTES_SUCCESS,
  FETCH_SAVINGS_NOTES_FAIL,
  CREATE_SAVINGS_NOTE_REQUEST,
  CREATE_SAVINGS_NOTE_SUCCESS,
  CREATE_SAVINGS_NOTE_FAIL,
  UPDATE_SAVINGS_NOTE_REQUEST,
  UPDATE_SAVINGS_NOTE_SUCCESS,
  UPDATE_SAVINGS_NOTE_FAIL,
  DELETE_SAVINGS_NOTE_REQUEST,
  DELETE_SAVINGS_NOTE_SUCCESS,
  DELETE_SAVINGS_NOTE_FAIL,
  SET_RESCHEDULE_LOAN_TEMPLATE,
  EDIT_REPAYMENT_SCHEDULE_REQUEST,
  EDIT_REPAYMENT_SCHEDULE_SUCCESS,
  EDIT_REPAYMENT_SCHEDULE_FAIL,
  SET_LOAN_CHARGES,
} from "../constants/savingsLoan";
import {
  FETCH_LOAN_COLLATERAL_TEMPLATE_FAIL,
  FETCH_LOAN_COLLATERAL_TEMPLATE_SUCCESS,
  FETCH_LOAN_COLLATERAL_TEMPLATE_REQUEST,
  ADD_COLLATERAL_TO_LOAN_SUCCESS,
  ADD_COLLATERAL_TO_LOAN_FAIL,
  ADD_COLLATERAL_TO_LOAN_REQUEST,
  FETCH_LOAN_COLLATERALS_REQUEST,
  FETCH_LOAN_COLLATERALS_SUCCESS,
  FETCH_LOAN_COLLATERALS_FAIL,
  REMOVE_A_LOAN_COLLATERAL_REQUEST,
  REMOVE_A_LOAN_COLLATERAL_SUCCESS,
  REMOVE_A_LOAN_COLLATERAL_FAIL,
} from '../constants/loanCollateralConstant'

import {
  getOneSavingsAccount,
  getOneLoanDetails,
  getACustomerStaff,
  assignAStafftoCustomer,
  getOneSavingsAccountTemplate,
  approveASavingsAccount,
  activateASavingsAccount,
  assignAStafftoAccount,
  getAccountEmailNotification,
  getAccountSMSNotification,
  setAccountEmailNotification,
  setAccountSMSNotification,
  postSavingsAccountIntrest,
  getLoanAccountsChargeTemplate,
  getSavingsAccountsChargeTemplate,
  addASavingsAccountCharge,
  blockASavingsAccountCharge,
  getASavingsAccountTrasactionTemplate,
  closeASavingsAccount,
  rejectAndWithdrawSavingsAccount,
  deleteASavingsAccount,
  activateAPndSavindsAccount,
  getATransferSavingsAccountTemplate,
  getALoanDocument,
  uploadALoanDocument,
  getAloanOfficer,
  assignAloanOfficer,
  getAloanDisburseDetails,
  disburseALoanAccount,
  withdrawALoan,
  forClosureALoan,
  getARepayLoanTemplate,
  deleteALoan,
  handleALoanTransaction,
  undoDisburseALoanAccount,
  undoApprovedALoanAccount,
  undoApprovedASavingAccount,
  postALoanCommand,
  accTransfer,
  deleteALoanDocument,
  getRescheduledLoanDetails,
  rescheduleALoan,
  createALoanGuarantor,
  getOneSavingsAccountTransactions,
  getAllSavingsAccountEvents,
  getAllOptionsAccountEvents,
  handleAnEventUpdate,
  getOneLoanAccountTransactions,
  getASavingsAccountAudit,
  getALoanAccountAudit,
  getALoanAccountChart,
  getASavingsAccountChart,
  payASavingsAccountCharge,
  waiveASavingsAccountCharge,
  addLoanCharge,
  payALoanAccountCharge,
  waiveALoanAccountCharge,
  getLoanGuarantorTemplate,
  getLoanGuarantors,
  getSavingsTransactionReceipt,
  deleteLoanGuarantor,
  recoverLoanGuarantor,
  getALoanAccountCharges,
  editLoanAccountInfo,
} from "../services/Api/savings.loan.api.service";

import Toast from "../util/Toast";

import { getCustomerAccounts, getSingleCustomer } from "./customerActions";
import {
  DOWNLOAD_TRANSACTION_RECEIPT,
  DOWNLOAD_TRANSACTION_RECEIPT_SUCCESS,
  DOWNLOAD_TRANSACTION_RECEIPT_RESET,
} from "../constants/savingsLoan";
import { CLEAR_CUSTOMER_ACCOUNTS, DOWNLOAD_REPAYMENT_SCHEDULE_FAIL, DOWNLOAD_REPAYMENT_SCHEDULE_SUCCESS } from "../constants/customerConstants";

const handleError = (ex, dispatch) => {
  if (ex.response) {
    dispatch(
      Toast({
        text: ex.response.data.errors
          ? ex.response.data.errors[0].defaultUserMessage
          : ex.response.data.defaultUserMessage,
        icon: "error",
      })
    );
  } else if (ex.request) {
    dispatch(Toast({ text: String(ex), icon: "error" }));
  } else {
    dispatch(Toast({ text: String(ex), icon: "error" }));
  }

  dispatch({ type: SET_LOADING_STATE, payload: false });
};

const deleteGuarantorFail = (payload) => {
  return { type: DELETE_LOAN_GUARANTOR_FAIL, payload };
};

const recoverGuarantorFail = (payload) => {
  return { type: RECOVER_LOAN_GUARANTOR_FAIL, payload };
};

const resetTransactionReceipt = () => {
  return (dispatch) => {
    dispatch({ type: DOWNLOAD_TRANSACTION_RECEIPT_RESET });
  };
};

const getSavingsAccountStatementAction = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: DOWNLOAD_TRANSACTION_RECEIPT });
    await getSavingsTransactionReceipt(id)
      .then((response) => {
        if (response.statusCode == 400) {
          dispatch(
            Toast({
              text: response.status,
              icon: "error",
              timer: 5000,
            })
          );
          dispatch({ type: SET_LOADING_STATE, payload: false });
          return;
        }
        dispatch({
          type: DOWNLOAD_TRANSACTION_RECEIPT_SUCCESS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(
          Toast({
            text: "Transaction receipt will open in a new tab",
            icon: "success",
            timer: 3000,
          })
        );
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const getSavingsAccountDetails = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_SAVINGS_ACCOUNT_DETAILS });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getOneSavingsAccount(id)
      .then((response) => {
        dispatch({
          type: SET_SAVINGS_ACCOUNT_DETAILS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const getSavingsAccountTransactions = (id, dataToSend, params) => {
  return async (dispatch) => {
    dispatch({ type: SET_SAVINGS_TRANSACTIONS });
    dispatch({ type: SET_TABLE_LOADING_STATE, payload: true });
    await getOneSavingsAccountTransactions(id, dataToSend, params)
      .then((response) => {
        dispatch({
          type: SET_SAVINGS_TRANSACTIONS,
          payload: response,
        });
        dispatch({ type: SET_TABLE_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch({ type: SET_TABLE_LOADING_STATE, payload: false });
      });
  };
};

const getSavingsAccountEvents = (id, dataToSend, params) => {
  return async (dispatch) => {
    dispatch({ type: SET_SAVINGS_EVENTS });
    dispatch({ type: SET_TABLE_LOADING_STATE, payload: true });
    await getAllSavingsAccountEvents(id, dataToSend, params)
      .then((response) => {
        dispatch({
          type: SET_SAVINGS_EVENTS,
          payload: response,
        });
        dispatch({ type: SET_TABLE_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch({ type: SET_TABLE_LOADING_STATE, payload: false });
      });
  };
};

const getAllEventOptions = () => {
  return async (dispatch) => {
    dispatch({ type: SET_OPTIONS_EVENTS });
    dispatch({ type: SET_TABLE_LOADING_STATE, payload: true });
    await getAllOptionsAccountEvents()
      .then((response) => {
        dispatch({
          type: SET_OPTIONS_EVENTS,
          payload: response,
        });
        dispatch({ type: SET_TABLE_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch({ type: SET_TABLE_LOADING_STATE, payload: false });
      });
  };
};

const handleEventUpdate = (id, dataToSend) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await handleAnEventUpdate(dataToSend)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(Toast({ text: "Succesfully Updated Event", icon: "success" }));
        dispatch(getSavingsAccountEvents(id,
          {},
          "?offset=0&limit=5&sortOrder=DESC"));
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }

        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const getLoanAccountTransactions = (id, params) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOAN_TRANSACTIONS });
    dispatch({ type: SET_TABLE_LOADING_STATE, payload: true });
    await getOneLoanAccountTransactions(id, params)
      .then((response) => {
        dispatch({
          type: SET_LOAN_TRANSACTIONS,
          payload: response,
        });
        dispatch({ type: SET_TABLE_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch({ type: SET_TABLE_LOADING_STATE, payload: false });
      });
  };
};

const getLoanAccCharges = (id, params) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOAN_CHARGES });
    dispatch({ type: SET_TABLE_LOADING_STATE, payload: true });
    await getLoanCharges(id, params)
      .then((response) => {
        dispatch({
          type: SET_LOAN_CHARGES,
          payload: response,
        });
        dispatch({ type: SET_TABLE_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch({ type: SET_TABLE_LOADING_STATE, payload: false });
      });
  };
};

const getSavingsAccountTemplate = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_SAVINGS_ACCOUNT_TEMPLATE });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getOneSavingsAccountTemplate(id)
      .then((response) => {
        dispatch({
          type: SET_SAVINGS_ACCOUNT_TEMPLATE,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const getLoanDetails = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOAN_DETAILS });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getOneLoanDetails(id)
      .then((response) => {
        dispatch({
          type: SET_LOAN_DETAILS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const getCustomerStaff = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_CUSTOMER_OPTIONS });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getACustomerStaff(id)
      .then((response) => {
        dispatch({
          type: SET_CUSTOMER_OPTIONS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const assignStaffToCustomer = (id, dataToSend, action, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await assignAStafftoCustomer(id, dataToSend, action)
      .then((response) => {
        dispatch(
          Toast({ text: "Staff Successfully Updated", icon: "success" })
        );
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(getSingleCustomer(response.clientId));
        // history.push(`/core/client/${response.clientId}`);
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const assignStaffToAccount = (id, dataToSend, action, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await assignAStafftoAccount(id, dataToSend, action)
      .then((response) => {
        dispatch(
          Toast({ text: "Staff Successfully Updated", icon: "success" })
        );
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(getSavingsAccountDetails(response.savingsId));
        // history.push(`/core/client/savings-account/${response.savingsId}`);
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const aprroveSavingsAccount = (id, dataToSend, history, onHide) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await approveASavingsAccount(id, dataToSend)
      .then((response) => {
        if (response.commandId) {
          dispatch(Toast({ text: response.message, icon: "info" }));
        } else {
        dispatch(Toast({ text: "Account Approved", icon: "success" }));
        dispatch(getCustomerAccounts(response.clientId));
        dispatch(getSavingsAccountDetails(response.savingsId));
        // history.push(`/core/client/savings-account/${response.savingsId}`);
        }
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
      })
      .finally(() => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        onHide();
      });
  };
};

const undoApprovedSavingAccount = (id, dataToSend, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await undoApprovedASavingAccount(id, dataToSend)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(
          Toast({
            text: "Succesfully UnApproved Savings Account",
            icon: "success",
          })
        );
        // history.push(`/core/client/loan/${id}`);
        dispatch(getSavingsAccountDetails(id));
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }

        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const getEmailNotifications = (accountNumber) => {
  return async (dispatch) => {
    dispatch({ type: SET_SAVINGS_ACCOUNT_EMAIL_NOTIFICATIONS });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getAccountEmailNotification(accountNumber)
      .then((response) => {
        dispatch({
          type: SET_SAVINGS_ACCOUNT_EMAIL_NOTIFICATIONS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }

        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const getSmsNotifications = (accountNumber) => {
  return async (dispatch) => {
    dispatch({ type: SET_SAVINGS_ACCOUNT_SMS_NOTIFICATIONS });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getAccountSMSNotification(accountNumber)
      .then((response) => {
        dispatch({
          type: SET_SAVINGS_ACCOUNT_SMS_NOTIFICATIONS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }

        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const activateSavingsAccount = (id, dataToSend, history, onHide) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await activateASavingsAccount(id, dataToSend)
      .then((response) => {
        if (response.commandId) {
          dispatch(Toast({ text: response.message, icon: "info" }));
        } else {
        dispatch(Toast({ text: "Account Activated", icon: "success" }));
        dispatch(getCustomerAccounts(response.clientId));
        dispatch(getSavingsAccountDetails(response.savingsId));
        // history.push(`/core/client/savings-account/${response.savingsId}`);
        }
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
      })
      .finally(() => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        onHide();
      });
  };
};

const setEmailNotification = (id, dataToSend, history, actionType) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await setAccountEmailNotification(id, dataToSend, actionType)
      .then((response) => {
        dispatch(
          Toast({ text: "Email Notification Updated", icon: "success" })
        );
        dispatch({ type: SET_LOADING_STATE, payload: false });
        // history.push(`/core/client/savings-account/${response.savingsId}`);
        // history.go(0);
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }

        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const setSmsNotification = (id, dataToSend, history, actionType) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await setAccountSMSNotification(id, dataToSend, actionType)
      .then((response) => {
        dispatch(Toast({ text: "Sms Notification Updated", icon: "success" }));
        dispatch({ type: SET_LOADING_STATE, payload: false });
        // history.push(`/core/client/savings-account/${response.savingsId}`);
        // history.go(0);
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }

        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const postASavingsAccountIntrest = (id, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await postSavingsAccountIntrest(id)
      .then((response) => {
        dispatch(
          Toast({ text: "Intrest Successfully Posted", icon: "success" })
        );
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(getSavingsAccountDetails(response.savingsId));
        // history.push(`/core/client/savings-account/${response.savingsId}`);
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }

        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const getLoansChargeTemplate = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getLoanAccountsChargeTemplate(id)
      .then((response) => {
        dispatch({
          type: SET_LOAN_ACCOUNTS_CHARGE_TEMPLATE,
          payload: response,
        });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
      })
      .finally(() => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const getSavingsChargeTemplate = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: SET_SAVINGS_ACCOUNT_CHARGE_TEMPLATE });
    await getSavingsAccountsChargeTemplate(id)
      .then((response) => {
        dispatch({
          type: SET_SAVINGS_ACCOUNT_CHARGE_TEMPLATE,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }

        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const addSavingsAccountCharge = (id, dataToSend, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await addASavingsAccountCharge(id, dataToSend)
      .then((response) => {
        dispatch(
          Toast({
            text: "Successfully added savings charge",
            icon: "success",
          })
        );
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(getSavingsAccountDetails(response.savingsId));
        // history.push(`/core/client/savings-account/${response.savingsId}`);
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }

        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

// Pay Savings Account Charge
const paySavingsAccountCharge = (id, chargeId, dataToSend, index, action) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    try {
      const response = await payASavingsAccountCharge(
        id,
        chargeId,
        dataToSend,
        action
      );
      dispatch({
        type: UPDATE_CHARGES,
        payload: { index },
      });
      dispatch(
        Toast({
          text: "Charges Paid Successfully",
          icon: "success",
        })
      );

      return true;
    } catch (error) {
      return false;
    } finally {
      dispatch({ type: SET_LOADING_STATE, payload: false });
    }
  };
};
// Waive Savings Account Charge
const waiveSavingsAccountCharge = (id, chargeId, dataToSend, index, action) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    try {
      const response = await waiveASavingsAccountCharge(
        id,
        chargeId,
        dataToSend,
        action
      );
      dispatch({
        type: WAIVE_CHARGES,
        payload: { index },
      });
      dispatch(
        Toast({
          text: "Charges Waived Successfully",
          icon: "success",
        })
      );

      return true;
    } catch (error) {
      return false;
    } finally {
      dispatch({ type: SET_LOADING_STATE, payload: false });
    }
  };
};

// Add charge to a loan account
const addChargeToLoanAccount = (loanId, dataToSend) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    try {
      const response = await addLoanCharge(loanId, dataToSend);
      dispatch(
        Toast({
          text: "Charges Added Successfully",
          icon: "success",
        })
      );
      dispatch(getLoanDetails(loanId));
    } catch (ex) {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
    } finally {
      dispatch({ type: SET_LOADING_STATE, payload: false });
    }
  };
};
// Pay Loan Account Charge
const payLoanAccountCharge = (id, chargeId, dataToSend, index, action) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    try {
      const response = await payALoanAccountCharge(
        id,
        chargeId,
        dataToSend,
        action
      );
      dispatch({
        type: UPDATE_LOAN_CHARGES,
        payload: { index },
      });
      dispatch(
        Toast({
          text: "Charges Paid Successfully",
          icon: "success",
        })
      );

      return true;
    } catch (error) {
      return false;
    } finally {
      dispatch({ type: SET_LOADING_STATE, payload: false });
    }
  };
};
// Waive Loan Account Charge
const waiveLoanAccountCharge = (id, chargeId, dataToSend, index, action) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    try {
      const response = await waiveALoanAccountCharge(
        id,
        chargeId,
        dataToSend,
        action
      );
      dispatch({
        type: WAIVE_LOAN_CHARGES,
        payload: { index },
      });
      dispatch(
        Toast({
          text: "Charges Waived Successfully",
          icon: "success",
        })
      );
      return true;
    } catch (error) {
      return false;
    } finally {
      dispatch({ type: SET_LOADING_STATE, payload: false });
    }
  };
};

const blockSavingsAccount = (id, action, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await blockASavingsAccountCharge(id, action)
      .then((response) => {
        dispatch(
          Toast({
            text: "Successfully Updated Account",
            icon: "success",
          })
        );
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(getSavingsAccountDetails(response.savingsId));
        // history.push(`/core/client/savings-account/${response.savingsId}`);
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }

        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const getTransactionTemplate = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getASavingsAccountTrasactionTemplate(id)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({
          type: SET_SAVINGS_ACCOUNT_TRANSACTION_TEMPLATE,
          payload: response,
        });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }

        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const closeSavingsAccount = (id, dataToSend, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await closeASavingsAccount(id, dataToSend)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(
          Toast({
            text: "Account Succesfully Closed",
            icon: "success",
          })
        );
        dispatch(getSavingsAccountDetails(response.savingsId));
        // history.push(`/core/client/savings-account/${response.savingsId}`);
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }

        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const rejectOrWithdrawSavingsAccount = (id, dataToSend, action, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await rejectAndWithdrawSavingsAccount(id, dataToSend, action)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(
          Toast({
            text: "Account Succesfully Updated",
            icon: "success",
          })
        );
        dispatch(getSavingsAccountDetails(response.savingsId));
        // history.push(`/core/client/savings-account/${response.savingsId}`);
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }

        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const deleteSavingsAccount = (id, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await deleteASavingsAccount(id)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(
          Toast({
            text: "Account Succesfully Deleted",
            icon: "success",
          })
        );
        history.push(`/core/client/${response.clientId}`);
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }

        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const activatePndSavingsAccount = (id, action, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await activateAPndSavindsAccount(id, action)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(
          Toast({
            text: "Account Succesfully Updated",
            icon: "success",
          })
        );
        dispatch(getSavingsAccountDetails(response.savingsId));
        // history.push(`/core/client/savings-account/${response.savingsId}`);
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }

        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const getTransferSavingsAccountTemplate = (id, otherAction) => {
  return async (dispatch) => {
    dispatch({ type: SET_TRANSFER_SAVINGS_ACCOUNT_TEMPLATE });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getATransferSavingsAccountTemplate(id, otherAction)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({
          type: SET_TRANSFER_SAVINGS_ACCOUNT_TEMPLATE,
          payload: response,
        });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }

        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const getLoanDocuments = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOAN_DOCUMENT });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getALoanDocument(id)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({
          type: SET_LOAN_DOCUMENT,
          payload: response,
        });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }

        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const uploadLoanDocument = (id, dataToSend, history, onHide) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await uploadALoanDocument(id, dataToSend)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(
          Toast({ text: "Successfully Uploaded Document", icon: "success" })
        );
        dispatch(getLoanDocuments(id));
        onHide();
        // history.push(`/core/client/loan/${id}`);
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }

        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const getLoanAccountTemplate = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOAN_ACCOUNT_TEMPLATE });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getAloanOfficer(id)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: SET_LOAN_ACCOUNT_TEMPLATE, payload: response });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }

        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const assignLoanAccountOfficer = (id, dataToSend, action, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await assignAloanOfficer(id, dataToSend, action)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(Toast({ text: "Officer Updated", icon: "success" }));
        dispatch(getLoanDetails(id));
        // history.push(`/core/client/loan/${response.loanId}`);
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }

        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const getLoanDisburseDetails = (id, action) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: SET_LOAN_DISBURSE_DETAILS });
    await getAloanDisburseDetails(id, action)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: SET_LOAN_DISBURSE_DETAILS, payload: response });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }

        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const getAllLoanGuarantorTemplate = (loanId) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: SET_LOAN_GUARANTOR_TEMPLATE });
    await getLoanGuarantorTemplate(loanId)
      .then((response) => {
        if (response) {
          dispatch({ type: SET_LOAN_GUARANTOR_TEMPLATE, payload: response });
          dispatch({ type: SET_LOADING_STATE, payload: false });
        }
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const getAllGuarantorPerId = (loanId) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: SET_LOAN_GUARANTOR_TEMPLATE });
    await getLoanGuarantors(loanId)
      .then((response) => {
        if (response) {
          dispatch({ type: SET_LOAN_GUARANTOR_TEMPLATE, payload: response });
          dispatch({ type: SET_LOADING_STATE, payload: false });
        }
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

// Delete Guarantor
const deleteGuarantor = (loanId, guarantorId, guarantorFundingId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_LOAN_GUARANTOR_REQUEST });
      const data = await deleteLoanGuarantor(
        loanId,
        guarantorId,
        guarantorFundingId
      );
      dispatch(Toast({ text: "Loan guarantor deleted", icon: "success" }));
      dispatch(getAllGuarantorPerId(loanId));
      dispatch(getLoanDetails(loanId));
      dispatch({ type: DELETE_LOAN_GUARANTOR_SUCCESS, payload: data });
    } catch (ex) {
      dispatch(getAllGuarantorPerId(loanId));
      dispatch(getLoanDetails(loanId));
      if (ex.response) {
        dispatch(
          Toast({
            text: ex.response.data.errors
              ? ex.response.data.errors[0].defaultUserMessage
              : ex.response.data.defaultUserMessage,
            icon: "error",
          })
        );
      } else if (ex.request) {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      } else {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      }
      dispatch(deleteGuarantorFail(ex));
    }
  };
};

// Recover Guarantor
const recoverGuarantor = (loanId, guarantorId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: RECOVER_LOAN_GUARANTOR_REQUEST });
      const data = await recoverLoanGuarantor(loanId);
      dispatch(Toast({ text: "Loan guarantor recovered", icon: "success" }));
      dispatch(getAllGuarantorPerId(loanId));
      dispatch(getLoanDetails(loanId));
      dispatch({ type: RECOVER_LOAN_GUARANTOR_SUCCESS, payload: data });
    } catch (ex) {
      if (ex.response) {
        Toast({
          text: ex.response.data.errors
            ? ex.response.data.errors[0].defaultUserMessage
            : ex.response.data.defaultUserMessage,
          icon: "error",
        });
      } else if (ex.request) {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      } else {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      }
      dispatch(recoverGuarantorFail(ex));
    }
  };
};

const disburseLoan = (id, dataToSend, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await disburseALoanAccount(id, dataToSend)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(Toast({ text: "Loan has been disburse", icon: "success" }));
        dispatch(getLoanDetails(id));
        // history.push(`/core/client/loan/${id}`);
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }

        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const withdrawLoan = (id, dataToSend, action, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await withdrawALoan(id, dataToSend, action)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(Toast({ text: "Loan has been updated", icon: "success" }));

        dispatch(getLoanDetails(id));
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }

        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const forClosureLoan = (id, dataToSend, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await forClosureALoan(id, dataToSend)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(Toast({ text: "Loan has been Updated", icon: "success" }));
        dispatch(getLoanDetails(id));
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }

        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const getRepayLoanTemplate = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: SET_REPAY_LOAN_TEMPLATE });
    await getARepayLoanTemplate(id)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: SET_REPAY_LOAN_TEMPLATE, payload: response });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }

        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const deleteLoan = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await deleteALoan(id)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(Toast({ text: "Succesfully Deleted Loan", icon: "success" }));
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }

        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const handleLoanTransactions = (id, dataToSend, action, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await handleALoanTransaction(id, dataToSend, action)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(Toast({ text: "Succesfully Updated Loan", icon: "success" }));
        // history.push(`/core/client/loan/${id}`);
        dispatch(getLoanDetails(id));
        if (response && response.clientId) {
          dispatch(getCustomerAccounts(response.clientId));
        }
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }

        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const undoApprovedLoanAccount = (id, dataToSend, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await undoApprovedALoanAccount(id, dataToSend)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(
          Toast({ text: "Succesfully UnApproved Loan", icon: "success" })
        );
        // history.push(`/core/client/loan/${id}`);
        dispatch(getLoanDetails(id));
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }

        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const undoDibursalLoanAccount = (id, dataToSend, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await undoDisburseALoanAccount(id, dataToSend)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(Toast({ text: "Succesfully Updated Loan", icon: "success" }));
        // history.push(`/core/client/loan/${id}`);
        dispatch(getLoanDetails(id));
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }

        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const postLoanCommand = (id, userId, dataToSend, action, callback) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await postALoanCommand(id, dataToSend, action)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(Toast({
          text: action === 'reject'
            ? `Succesfully ${action}ed Loan`
            : 'Action successful',
          icon: "success"
        }));
        // history.push(`/core/client/loan/${id}`);dispatch(getCustomerAccounts(id));
        dispatch(getLoanDetails(id));
        callback?.();
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }

        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .finally(() => {
        dispatch(getCustomerAccounts(userId));
      });
  };
};

const hideShowTransferModal = () => {
  return async (dispatch) => {
    dispatch({ type: SHOW_TRANSFER_MODAL_SUCCESSFUL, payload: false });
  };
};

const hideMultipleShowTransferModal = () => {
  return async (dispatch) => {
    dispatch({ type: SHOW_MULTIPLE_TRANSFER_MODAL_SUCCESSFUL, payload: false });
  };
};

const accountTransfer = (id, dataToSend, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await accTransfer(dataToSend)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: SHOW_TRANSFER_MODAL_SUCCESSFUL, payload: true });
        dispatch(Toast({ text: "Transaction Successful", icon: "success" }));
        // history.push(`/core/client/loan/${id}`);
        dispatch(getLoanDetails(id));
        if (response && response.clientId) {
          dispatch(getCustomerAccounts(response.clientId));
        }
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }

        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const multipleAccountTransfer = (id, dataToSend, history, reset) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await multipleAccTransfer(dataToSend)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({
          type: SHOW_MULTIPLE_TRANSFER_MODAL_SUCCESSFUL,
          payload: true,
        });
        dispatch(Toast({ text: "Transaction Successful", icon: "success" }));
        reset()
        // history.push(`/core/client/loan/${id}`);
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }

        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const deleteLoanDocument = (id, documentId, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await deleteALoanDocument(id, documentId)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(Toast({ text: "Document Deleted", icon: "success" }));
        // history.push(`/core/client/loan/${id}`);
        dispatch(getLoanDocuments(id));
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }

        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const fetchRescheduleLoanTemplate = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getRescheduleLoanTemplate()
      .then((response) => {
        dispatch({ type: SET_RESCHEDULE_LOAN_TEMPLATE, payload: response });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
      })
      .finally(() => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const fetchRescheduledLoanRequests = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getRescheduledLoanRequests(id)
      .then((response) => {
        dispatch({ type: SET_RESCHEDULED_LOAN_REQUESTS, payload: response });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
      })
      .finally(() => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const fetchRescheduledLoanDetails = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getRescheduledLoanDetails(id)
      .then((response) => {
        dispatch({ type: SET_RESCHEDULED_LOAN_DETAILS, payload: response });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
      })
      .finally(() => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const previewReschedule = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await previewLoanReschedule(id)
      .then((response) => {
        dispatch({ type: REPAYMENT_SCHEDULE_SUCCESS, payload: response });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
      })
      .finally(() => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const rescheduleLoan = (data, callback) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await rescheduleALoan(data)
      .then((response) => {
        dispatch(fetchRescheduledLoanDetails(response?.resourceId));
        callback?.();
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
      })
      .finally(() => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const approveLoanReschedule = (id, data, callback) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await approveReschedule(id, data)
      .then(() => {
        dispatch(
          Toast({
            text: 'Loan rescheduled successfully',
            icon: "success",
          })
        );
        callback?.();
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
      })
      .finally(() => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const rejectLoanReschedule = (id, data, callback) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await rejectReschedule(id, data)
      .then(() => {
        dispatch(
          Toast({
            text: 'Loan reschedule rejected successfully',
            icon: "success",
          })
        );
        callback?.();
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
      })
      .finally(() => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const createLoanGuarantor = (data, history, id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await createALoanGuarantor(data, id)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(
          Toast({ text: "Succesfully Created Guarantor", icon: "success" })
        );
        // history.push(`/core/client/loan/${id}`);
        dispatch(getLoanDetails(id));
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }

        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const getSavingsAccountAudit = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_TABLE_LOADING_STATE, payload: true });
    await getASavingsAccountAudit(id)
      .then((response) => {
        dispatch({ type: SET_TABLE_LOADING_STATE, payload: false });
        dispatch({ type: SET_SAVINGS_ACCOUNT_AUDIT, payload: response.data });
        // history.push(`/core/client/loan/${id}`);
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }

        dispatch({ type: SET_TABLE_LOADING_STATE, payload: false });
      });
  };
};

const getLoanAccountAudit = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_TABLE_LOADING_STATE, payload: true });
    await getALoanAccountAudit(id)
      .then((response) => {
        dispatch({ type: SET_TABLE_LOADING_STATE, payload: false });
        dispatch({
          type: SET_LOAN_ACCOUNT_AUDIT,
          payload: response && response.data && response.data.message,
        });
        // history.push(`/core/client/loan/${id}`);
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }

        dispatch({ type: SET_TABLE_LOADING_STATE, payload: false });
      });
  };
};

const getLoanAccountCharges = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_TABLE_LOADING_STATE, payload: true });
    await getALoanAccountCharges(id)
      .then((response) => {
        dispatch({ type: SET_TABLE_LOADING_STATE, payload: false });
        dispatch({ type: SET_LOAN_ACCOUNT_CHARGES, payload: response.data });
        // history.push(`/core/client/loan/${id}`);
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }

        dispatch({ type: SET_TABLE_LOADING_STATE, payload: false });
      });
  };
};

const getLoanAccountChart = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_TABLE_LOADING_STATE, payload: true });
    await getALoanAccountChart(id)
      .then((response) => {
        dispatch({ type: SET_TABLE_LOADING_STATE, payload: false });
        dispatch({
          type: SET_LOAN_CHART,
          payload: response && response.data,
        });
        // history.push(`/core/client/loan/${id}`);
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }

        dispatch({ type: SET_TABLE_LOADING_STATE, payload: false });
      });
  };
};

const getSavingsAccountChart = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_TABLE_LOADING_STATE, payload: true });
    await getASavingsAccountChart(id)
      .then((response) => {
        dispatch({ type: SET_TABLE_LOADING_STATE, payload: false });
        dispatch({
          type: SET_SAVINGS_CHART,
          payload: response && response.data,
        });
        // history.push(`/core/client/loan/${id}`);
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }

        dispatch({ type: SET_TABLE_LOADING_STATE, payload: false });
      });
  };
};

const aLoanAccountRequestFail = (payload) => {
  return { type: LOAN_ACCOUNT_FAIL, payload };
};
const updateLoanAccountFail = (payload) => {
  return { type: UPDATE_LOAN_ACCOUNT_FAIL, payload };
};

// get a Loan account
const getLoanAccount = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: LOAN_ACCOUNT_REQUEST });
    await API_LOAN.getALoanAccount(id)
      .then((response) => {
        dispatch({ type: LOAN_ACCOUNT_SUCCESS, payload: response });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(aLoanAccountRequestFail(ex));
      });
  };
};

// Update Loan Account Info
const updateLoanAccountInfo = (
  spData,
  id,
  history,
  clientId,
  currentTab,
  match,
  goToLoanTab
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_LOADING_STATE, payload: true });
      dispatch({ type: UPDATE_LOAN_ACCOUNT_REQUEST });
      const data = await editLoanAccountInfo(spData, id);
      dispatch({ type: UPDATE_LOAN_ACCOUNT_SUCCESS, payload: data });
      dispatch(Toast({ text: "Loan Account info updated", icon: "success" }));
      // history.push(`/core/clients/${clientId}`);
      // history.push(`/core/client/${clientId}?currentId=${id}`);
      // history.push(`/core/client/${clientId}/fromLoan/${id}`);
      // history.push(`/core/client/${clientId}?tab=${currentTab}`);
      history.goBack();
      // history.push(
      //   `/core/client/${clientId}/${goToLoanTab}/${match?.params.id}/routeFrom`
      // );
    } catch (ex) {
      dispatch({ type: SET_LOADING_STATE, payload: false });
      if (ex.response) {
        dispatch(
          Toast({
            text: ex.response.data.errors
              ? ex.response.data.errors[0].defaultUserMessage
              : ex.response.data.defaultUserMessage,
            icon: "error",
          })
        );
      } else if (ex.request) {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      } else {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      }
      dispatch(updateLoanAccountFail(ex));
    }
  };
};

// GENERATE LOAN REPAYMENT SCHEDULE
const calculateRepaymentSchedule = (payload) => {
  return async (dispatch) => {
    dispatch({ type: REPAYMENT_SCHEDULE_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await generateRepaymentSchedule(payload)
      .then((response) => {
        dispatch({ type: REPAYMENT_SCHEDULE_SUCCESS, payload: response });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
        dispatch({ type: REPAYMENT_SCHEDULE_FAIL, payload: ex });
      })
      .finally(() => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

// MODIFY LOAN REPAYMENT SCHEDULE
const modifyRepaymentSchedule = (loanId, payload, callback) => {
  return async (dispatch) => {
    dispatch({ type: EDIT_REPAYMENT_SCHEDULE_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await editRepaymentSchedule(loanId, payload)
      .then((response) => {
        dispatch({ type: EDIT_REPAYMENT_SCHEDULE_SUCCESS, payload: response });
        dispatch(Toast({ text: "Repayment schedule modified successfully", icon: "success" }));
        callback?.();
      })
      .catch((ex) => {
        handleError(ex, dispatch);
        dispatch({ type: EDIT_REPAYMENT_SCHEDULE_FAIL, payload: ex });
      })
      .finally(() => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const getLoanNotes = (id) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_LOAN_NOTES_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await fetchLoanNotes(id)
      .then((response) => {
        dispatch({ type: FETCH_LOAN_NOTES_SUCCESS, payload: response });
      })
      .catch((ex) => {
        dispatch({ type: FETCH_LOAN_NOTES_FAIL, payload: ex });
        handleError(ex, dispatch);
      })
      .finally(() => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const initiateLoanNoteCreation = (id, payload, callback) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_LOAN_NOTE_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await createLoanNote(id, payload)
      .then((response) => {
        dispatch({ type: CREATE_LOAN_NOTE_SUCCESS, payload: response });
        dispatch(Toast({ text: "Note created successfully", icon: "success" }));
        callback?.();
      })
      .catch((ex) => {
        dispatch({ type: CREATE_LOAN_NOTE_FAIL, payload: ex });
        handleError(ex, dispatch);
      })
      .finally(() => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const modifyLoanNote = (loanId, noteId, payload, callback) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_LOAN_NOTE_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await updateLoanNote(loanId, noteId, payload)
      .then((response) => {
        dispatch({ type: UPDATE_LOAN_NOTE_SUCCESS, payload: response });
        dispatch(Toast({ text: "Note modified successfully", icon: "success" }));
        callback?.();
      })
      .catch((ex) => {
        dispatch({ type: UPDATE_LOAN_NOTE_FAIL, payload: ex });
        handleError(ex, dispatch);
      })
      .finally(() => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const removeLoanNote = (loanId, noteId, callback) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_LOAN_NOTE_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await deleteLoanNote(loanId, noteId)
      .then((response) => {
        dispatch({ type: DELETE_LOAN_NOTE_SUCCESS, payload: response });
        dispatch(Toast({ text: "Note deleted successfully", icon: "success" }));
        callback?.();
      })
      .catch((ex) => {
        dispatch({ type: DELETE_LOAN_NOTE_FAIL, payload: ex });
        handleError(ex, dispatch);
      })
      .finally(() => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const getSavingsNotes = (id) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_SAVINGS_NOTES_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await fetchSavingsNotes(id)
      .then((response) => {
        dispatch({ type: FETCH_SAVINGS_NOTES_SUCCESS, payload: response });
      })
      .catch((ex) => {
        dispatch({ type: FETCH_SAVINGS_NOTES_FAIL, payload: ex });
        handleError(ex, dispatch);
      })
      .finally(() => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const initiateSavingsNoteCreation = (id, payload, callback) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_SAVINGS_NOTE_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await createSavingsNote(id, payload)
      .then((response) => {
        dispatch({ type: CREATE_SAVINGS_NOTE_SUCCESS, payload: response });
        dispatch(Toast({ text: "Note created successfully", icon: "success" }));
        callback?.();
      })
      .catch((ex) => {
        dispatch({ type: CREATE_SAVINGS_NOTE_FAIL, payload: ex });
        handleError(ex, dispatch);
      })
      .finally(() => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const modifySavingsNote = (savingsId, noteId, payload, callback) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_SAVINGS_NOTE_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await updateSavingsNote(savingsId, noteId, payload)
      .then((response) => {
        dispatch({ type: UPDATE_SAVINGS_NOTE_SUCCESS, payload: response });
        dispatch(Toast({ text: "Note modified successfully", icon: "success" }));
        callback?.();
      })
      .catch((ex) => {
        dispatch({ type: UPDATE_SAVINGS_NOTE_FAIL, payload: ex });
        handleError(ex, dispatch);
      })
      .finally(() => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const removeSavingsNote = (savingsId, noteId, callback) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_SAVINGS_NOTE_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await deleteSavingsNote(savingsId, noteId)
      .then((response) => {
        dispatch({ type: DELETE_SAVINGS_NOTE_SUCCESS, payload: response });
        dispatch(Toast({ text: "Note deleted successfully", icon: "success" }));
        callback?.();
      })
      .catch((ex) => {
        dispatch({ type: DELETE_SAVINGS_NOTE_FAIL, payload: ex });
        handleError(ex, dispatch);
      })
      .finally(() => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

export {
  getSavingsAccountDetails,
  getSavingsAccountStatementAction,
  getSavingsAccountTemplate,
  getLoanDetails,
  getCustomerStaff,
  assignStaffToCustomer,
  aprroveSavingsAccount,
  activateSavingsAccount,
  assignStaffToAccount,
  getEmailNotifications,
  setEmailNotification,
  getSmsNotifications,
  setSmsNotification,
  postASavingsAccountIntrest,
  getSavingsChargeTemplate,
  getLoansChargeTemplate,
  addSavingsAccountCharge,
  blockSavingsAccount,
  getTransactionTemplate,
  closeSavingsAccount,
  rejectOrWithdrawSavingsAccount,
  deleteSavingsAccount,
  activatePndSavingsAccount,
  getTransferSavingsAccountTemplate,
  getLoanDocuments,
  uploadLoanDocument,
  getLoanAccountTemplate,
  assignLoanAccountOfficer,
  getLoanDisburseDetails,
  disburseLoan,
  withdrawLoan,
  forClosureLoan,
  getRepayLoanTemplate,
  deleteLoan,
  handleLoanTransactions,
  undoDibursalLoanAccount,
  getAllLoanGuarantorTemplate,
  getAllGuarantorPerId,
  deleteGuarantor,
  recoverGuarantor,
  undoApprovedLoanAccount,
  undoApprovedSavingAccount,
  postLoanCommand,
  accountTransfer,
  multipleAccountTransfer,
  hideShowTransferModal,
  hideMultipleShowTransferModal,
  deleteLoanDocument,
  fetchRescheduleLoanTemplate,
  fetchRescheduledLoanRequests,
  fetchRescheduledLoanDetails,
  previewReschedule,
  rescheduleLoan,
  approveLoanReschedule,
  rejectLoanReschedule,
  createLoanGuarantor,
  getSavingsAccountTransactions,
  getSavingsAccountEvents,
  getAllEventOptions,
  handleEventUpdate,
  getLoanAccountTransactions,
  getLoanAccCharges,
  getSavingsAccountAudit,
  getLoanAccountAudit,
  getLoanAccountCharges,
  getSavingsAccountChart,
  getLoanAccountChart,
  paySavingsAccountCharge,
  waiveSavingsAccountCharge,
  payLoanAccountCharge,
  addChargeToLoanAccount,
  waiveLoanAccountCharge,
  getLoanAccount,
  updateLoanAccountInfo,
  resetTransactionReceipt,
  calculateRepaymentSchedule,
  modifyRepaymentSchedule,
  getLoanNotes,
  initiateLoanNoteCreation,
  modifyLoanNote,
  removeLoanNote,
  getSavingsNotes,
  initiateSavingsNoteCreation,
  modifySavingsNote,
  removeSavingsNote,
};

// https://core.staging.woodcoreapp.com/woodcore/api/v1/loans/undefined/charges/98?command=pay
