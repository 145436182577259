/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Progress } from "antd";

import API_UTILITY from "../../services/Api/utility.api.services";

import { SET_ALL_UPLOADED_BULK_IMPORT } from "../../constants/utilityConstants";

const BulkImportNotification = () => {
  const dispatch = useDispatch();
  const bulkImports = useSelector((state) => state.importTemplate);

  let { uploadedBulkImport = [] } = bulkImports || {};

  // console.log("uploadedBulkImport", uploadedBulkImport);

  useEffect(() => {
    if (uploadedBulkImport && uploadedBulkImport.length) {
      uploadedBulkImport.forEach((bulkImport, index) => {
        let { status, id, typeUrl } = bulkImport;
        if (status <= 99) {
          API_UTILITY.getBulkImportDocument(typeUrl)
            .then((response) => {
              let importToCheck =
                response && response.length
                  ? response.filter((res) => res.importId === id)
                  : "";

              if (
                importToCheck &&
                importToCheck[0] &&
                importToCheck[0].completed
              ) {
                let hhh = [...uploadedBulkImport];
                hhh[Number(index)].status = 100;
                dispatch({ type: SET_ALL_UPLOADED_BULK_IMPORT, payload: hhh });
              } else {
                if (status >= 90) {
                  let hhh = [...uploadedBulkImport];
                  hhh[Number(index)].status = status + 1;
                  dispatch({
                    type: SET_ALL_UPLOADED_BULK_IMPORT,
                    payload: hhh,
                  });
                } else if (status <= 60) {
                  let hhh = [...uploadedBulkImport];
                  hhh[Number(index)].status = status + 5;
                  dispatch({
                    type: SET_ALL_UPLOADED_BULK_IMPORT,
                    payload: hhh,
                  });
                } else {
                  let hhh = [...uploadedBulkImport];
                  hhh[Number(index)].status = status + 20;
                  dispatch({
                    type: SET_ALL_UPLOADED_BULK_IMPORT,
                    payload: hhh,
                  });
                }
              }
            })
            .catch((ex) => {
              let hhh = [...uploadedBulkImport];
              hhh[Number(index)].status = status + 20;
              dispatch({ type: SET_ALL_UPLOADED_BULK_IMPORT, payload: hhh });
            });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedBulkImport]);

  const handleRemoveBulkImport = (id) => {
    let hhh = uploadedBulkImport.filter((item) => item.id !== id);
    dispatch({ type: SET_ALL_UPLOADED_BULK_IMPORT, payload: hhh });
  };

  return (
    <>
      <div
        className="ant-notification ant-notification-bottomRight"
        style={{ right: "0px", top: "auto", bottom: "24px" }}
      >
        {uploadedBulkImport &&
          !!uploadedBulkImport.length &&
          uploadedBulkImport.map(({ name, status, id }, index) => {
            return (
              <>
                <div
                  key={id}
                  class="ant-notification-notice ant-notification-notice-info ant-notification-notice-closable"
                >
                  <div class="ant-notification-notice-content">
                    <div class="ant-notification-notice-with-icon" role="alert">
                      <span
                        role="img"
                        aria-label="info-circle"
                        class="anticon anticon-info-circle ant-notification-notice-icon ant-notification-notice-icon-info"
                      >
                        <svg
                          viewBox="64 64 896 896"
                          focusable="false"
                          data-icon="info-circle"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                          <path d="M464 336a48 48 0 1096 0 48 48 0 10-96 0zm72 112h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V456c0-4.4-3.6-8-8-8z"></path>
                        </svg>
                      </span>
                      <div class="ant-notification-notice-message">
                        <span class="ant-notification-notice-message-single-line-auto-margin"></span>
                        {name} Bulk Import Progress
                      </div>
                      <div class="ant-notification-notice-description">
                        <Progress percent={status} />
                      </div>
                    </div>
                  </div>
                  <a
                    onClick={() => handleRemoveBulkImport(id)}
                    tabindex={index}
                    class="ant-notification-notice-close"
                  >
                    <span class="ant-notification-close-x">
                      <span
                        role="img"
                        aria-label="close"
                        class="anticon anticon-close ant-notification-close-icon"
                      >
                        <svg
                          viewBox="64 64 896 896"
                          focusable="false"
                          data-icon="close"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                        </svg>
                      </span>
                    </span>
                  </a>
                </div>
              </>
            );
          })}
      </div>
    </>
  );
};

export default BulkImportNotification;
