import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BackSvg from "../../../../../assets/images/icons/back.svg";
import { ContentWrapper, ActionButton, TabButton } from "../styles";
import {
  deleteGuarantor,
  getAllGuarantorPerId,
  recoverGuarantor,
  getLoanAccount,
  getLoanDetails,
} from "../../../../../actions/SavingsAndLoanActions";
import { Menu, Table, Dropdown, Button, Tooltip } from "antd";
import { formatCurrency } from "../../../../../util/FormatAmount";
import ModalContainer from "../../../components/ModalContainer";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { DeleteGuarantor } from "./delete";
import { Table as BootstrapTable } from "react-bootstrap";
import CheckPermission from "../../../../../util/CheckPermission";
import { formatDate } from "../../../../../util/FormatDate";
import { Link } from "react-router-dom";

const ViewGuarantor = ({ currentId, history, setCurrentView }) => {
  const dispatch = useDispatch();

  const savingsAndLoan = useSelector((state) => state.savingsAndLoan);
  let { loanAccountDisburseDetails, loanGuarantorTemplate, loanDetails } =
    savingsAndLoan || {};

  const { responseUserData: { permissions } } = useSelector((state) => state.preAuth);

  const [currentTableActive, setCurrentTableActive] = useState({});
  const [actionValue, setActionValue] = useState("");
  const [modalState, toggleModal] = useState(false);

  let {
    loanProductName,
    accountNo,
    externalId,
    loanOfficerName,
    linkedAccount,
    approvedPrincipal = 0,
    timeline = {},
    status = {},
    currency = {},
    repaymentSchedule = {},
    summary = {},
  } = loanDetails || {};

  let { periods = [], totalPrincipalDisbursed = 0 } = repaymentSchedule || {};

  let { expectedDisbursementDate = [] } = timeline || {};

  let mainPrincipalOutstanding = 0;

  periods.forEach((period) => {
    let { principalOutstanding = 0 } = period || {};
    // For Principal
    mainPrincipalOutstanding += principalOutstanding;
  });

  useEffect(() => {
    dispatch(getAllGuarantorPerId(currentId));
    dispatch(getLoanDetails(currentId));
    // history.push(`/core/client/loans/${currentId}/guarantors`);
  }, [currentId]);

  const columns = [
    { title: "Full name", dataIndex: "fullname", key: "fullname" },
    { title: "Relationship", dataIndex: "relationship", key: "relationship" },
    { title: "Guarantor type", dataIndex: "guaType", key: "guaType" },
    { title: "Account", dataIndex: "account", key: "account" },
    { title: "Amount", dataIndex: "amount", key: "amount" },
    { title: "Status", dataIndex: "status", key: "status" },
    { title: "Action", dataIndex: "action", key: "action" },
  ];

  const displayModalContent = () => {
    switch (actionValue) {
      case "view":
        return (
          <DetailsModal
            data={currentTableActive}
            handleCloseModal={handleCloseModal}
          />
        );
      case "delete":
        return (
          <DeleteGuarantor
            data={currentTableActive}
            handleCloseModal={handleCloseModal}
          />
        );
      case "recoverLoan":
        return (
          <RecoverLoan
            data={currentTableActive}
            handleCloseModal={handleCloseModal}
          />
        );
      default:
        break;
    }
  };

  const handleCloseModal = () => {
    toggleModal(false);
    return setActionValue("");
  };

  const handleClick = (value) => {
    setActionValue(value);
    return toggleModal(true);
  };

  const activeMenu = (
    <Menu>
      <Menu.Item onClick={() => handleClick("view")}>
        <p>View</p>
      </Menu.Item>
      {CheckPermission("DELETE_GUARANTOR", permissions) && (
        <Menu.Item onClick={() => handleClick("delete")}>
          <p>Delete</p>
        </Menu.Item>
      )}
      {/* <Menu.Item onClick={() => handleClick("recoverLoan")}>
        <p>Recover Loan</p>
      </Menu.Item> */}
    </Menu>
  );

  const inActiveMenu = (
    <Menu>
      <Menu.Item onClick={() => handleClick("view")}>
        <p>View</p>
      </Menu.Item>
      {/* <Menu.Item onClick={() => handleClick("delete")}>
        <p>Delete</p>
      </Menu.Item> */}
      {/* <Menu.Item onClick={() => handleClick("recoverLoan")}>
        <p>Recover Loan</p>
      </Menu.Item> */}
    </Menu>
  );

  let loanGuarantors = [];

  loanGuarantorTemplate &&
    loanGuarantorTemplate.length > 0 &&
    loanGuarantorTemplate.forEach((guarantors) => {
      let {
        id,
        entityId,
        firstname,
        lastname,
        clientRelationshipType,
        guarantorType,
        savingsAccount,
        amount,
        addressLine1,
        mobileNumber,
        state,
        country,
        loanId,
        comment,
        status,
        guarantorFundingDetails,
      } = guarantors;

      loanGuarantors.push({
        key: id,
        id: id,
        fullname: (
          <Tooltip title="View guarantor's savings account" className="py-2">
            <Link to={`${entityId}/deposits/${guarantorFundingDetails?.[0]?.savingsAccount?.id}`}>
              {`${firstname} ${lastname}` ?? ""}
            </Link>
          </Tooltip>
        ),
        relationship: !!clientRelationshipType
          ? clientRelationshipType.name
          : "",
          guaType: !!guarantorType ? guarantorType.value : "",
          account: (
          <Tooltip title="View guarantor's savings account" className="py-2">
            <Link to={`${entityId}/deposits/${guarantorFundingDetails?.[0]?.savingsAccount?.id}`}>
              {guarantorFundingDetails?.[0].savingsAccount?.accountNo ?? ""}
            </Link>
          </Tooltip>
        ),
        amount:
          !!guarantorFundingDetails && guarantorFundingDetails.length > 0
            ? formatCurrency(guarantorFundingDetails?.[0]?.amount)
            : "",
        address: addressLine1,
        mobileNumber: mobileNumber,
        state: state,
        country: country,
        loanId: loanId,
        comment: comment,
        guarantorFundingDetails: guarantorFundingDetails
          ? guarantorFundingDetails
          : null,
        status: (
          <>
            {status ? (
              <span className="new-text-success">ACTIVE</span>
            ) : (
              <span className="new-text-danger">INACTIVE </span>
            )}
          </>
        ),
        action: (
          <>
            {status ? (
              <div>
                <Dropdown overlay={activeMenu}>
                  <Button className="action_button" style={{ height: "25px" }}>
                    More
                  </Button>
                </Dropdown>
              </div>
            ) : (
              <div>
                <Dropdown overlay={inActiveMenu}>
                  <Button className="action_button" style={{ height: "25px" }}>
                    More
                  </Button>
                </Dropdown>
              </div>
            )}
          </>
        ),
      });
    });

  return (
    <ContentWrapper>
      <div className="row">
        <div className="col-xl-6 col-md-6">
          <p onClick={() => setCurrentView(2)} className="back-btn d-flex">
            <img className="mr-2" src={BackSvg} alt="" />
            Back
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12 col-md-12">
          <div className="spaceBetweenFlexEnd"></div>
        </div>
      </div>
      <div className="row ml-0 mt-3">
        <div className="col">
          <p className="account-view-gua-details-main">
            {!!loanProductName ? loanProductName : ""}
          </p>
          <p className="account-view-gua-details">
            <span className="headerSpan-view-gua">Loan account</span>:{" "}
            {!!accountNo ? accountNo : ""}
          </p>
          <p className="account-view-gua-details">
            <span className="headerSpan-view-gua">Saving account</span>:{" "}
            {!!linkedAccount?.accountNo ? linkedAccount?.accountNo : ""}
          </p>
          {status.active ? (
            <p className="account-view-gua-details">
              <span className="headerSpan-view-gua">Loan Balance</span>:{" "}
              {formatCurrency(mainPrincipalOutstanding)}
            </p>
          ) : null}{" "}
          {status.active ? (
            <p className="account-view-gua-details">
              <span className="headerSpan-view-gua">Arrears By</span>:{" "}
              {formatCurrency(summary.totalOverdue)}
            </p>
          ) : null}
        </div>
      </div>

      <div className="row">
        <BootstrapTable bordered className="table-form-style col-md-5 m-4">
          <tbody>
            {status.active && !!expectedDisbursementDate.length ? (
              <tr>
                <td className="font-weight-bold md-font-weight">
                  Disbursement Date
                </td>
                <td>
                  {expectedDisbursementDate.length ? formatDate(expectedDisbursementDate) : ""}
                </td>
              </tr>
            ) : null}

            <tr>
              <td className="font-weight-bold md-font-weight">Loan Status</td>
              <td>{status.value}</td>
            </tr>

            <tr>
              <td className="font-weight-bold md-font-weight">Loan Officer</td>
              <td>{!!loanOfficerName ? loanOfficerName : "Not Assigned"}</td>
            </tr>

            <tr>
              <td className="font-weight-bold md-font-weight">External Id</td>
              <td>{!!externalId ? externalId : "Non Assigned"}</td>
            </tr>
          </tbody>
        </BootstrapTable>

        <BootstrapTable bordered className="table-form-style col-md-5 m-4">
          <tbody>
            {status.active && !!currency.displayLabel ? (
              <tr>
                <td className="font-weight-bold md-font-weight">Currency</td>
                <td>{!!currency.displayLabel ? currency.displayLabel : ""}</td>
              </tr>
            ) : null}
            <tr>
              <td className="font-weight-bold md-font-weight">Loan Purpose</td>
              <td>Not Provided</td>
            </tr>
            <tr>
              <td className="font-weight-bold md-font-weight">
                Approved Amount
              </td>
              <td>{formatCurrency(approvedPrincipal)}</td>
            </tr>

            <tr>
              <td className="font-weight-bold md-font-weight">
                Disburse Amount
              </td>
              <td>{formatCurrency(totalPrincipalDisbursed)}</td>
            </tr>
          </tbody>
        </BootstrapTable>
      </div>

      <div>
        <ModalContainer size="md" show={modalState}>
          {modalState && displayModalContent()}
        </ModalContainer>
        <div className="mt-1">
          <Table
            style={{
              overflow: "hidden",
              overflowX: "scroll",
            }}
            columns={columns}
            dataSource={loanGuarantors}
            pagination={{ defaultPageSize: 5 }}
            defaultPageSize={5}
            onRow={(r) => ({
              onClick: () => {
                return setCurrentTableActive(r);
              },
            })}
          />
        </div>
      </div>
    </ContentWrapper>
  );
};

export default ViewGuarantor;

const DetailsModal = ({ data, handleCloseModal }) => {
  return (
    <>
      {data?.guaType === "EXTERNAL" ? (
        <>
          <Modal.Header>
            <Modal.Title className="text-capitalize">
              View guarantor
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="wc-modal">
            <div className="p-1 position-relative">
              <div className="d-flex mb-3 ">
                <TextQuestion>Guarantor name: </TextQuestion>{" "}
                <Text>{data?.fullname || ""}</Text>
              </div>
              <div className="d-flex mb-3 ">
                <TextQuestion>Account: </TextQuestion>{" "}
                <Text>{data?.account || ""}</Text>
              </div>
              <div className="d-flex mb-3">
                <TextQuestion>Status:</TextQuestion>
                <Text>{data?.status || ""}</Text>
              </div>
              <div className="d-flex mb-3 ">
                <TextQuestion>Relationship type: </TextQuestion>{" "}
                <Text>{data?.relationship || ""}</Text>
              </div>
              <div className="d-flex mb-3 align-items-start">
                <TextQuestion>Guarantor type: </TextQuestion>{" "}
                <Text>{data?.guaType || ""}</Text>
              </div>

              <div className="d-flex mb-3">
                <TextQuestion>Mobile number:</TextQuestion>
                <Text>{data?.mobileNumber || ""}</Text>
              </div>
              <div className="d-flex mb-3">
                <TextQuestion>Comment:</TextQuestion>
                <Text>{data?.comment || ""}</Text>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="float-right mt-1">
              <ActionButton color="#2C1DFF" onClick={handleCloseModal}>
                <img
                  src={
                    require("../../../../../assets/images/icons/cancel.svg")
                      .default
                  }
                  alt="cancel icon"
                />
                Cancel
              </ActionButton>
            </div>
          </Modal.Footer>
        </>
      ) : (
        <>
          <Modal.Header>
            <Modal.Title className="text-capitalize">
              View guarantor
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="wc-modal">
            <div className="p-1 position-relative">
              <div className="d-flex mb-3 ">
                <TextQuestion>Guarantor name: </TextQuestion>{" "}
                <Text>{data?.fullname || ""}</Text>
              </div>
              <div className="d-flex mb-3 ">
                <TextQuestion>Savings account: </TextQuestion>{" "}
                <Text>{data?.account || ""}</Text>
              </div>
              <div className="d-flex mb-3">
                <TextQuestion>Status:</TextQuestion>
                <Text>{data?.status || ""}</Text>
              </div>
              <div className="d-flex mb-3 ">
                <TextQuestion>Amount: </TextQuestion>{" "}
                <Text>{data?.amount || ""}</Text>
              </div>
              <div className="d-flex mb-3 ">
                <TextQuestion>Relationship type: </TextQuestion>{" "}
                <Text>{data?.relationship || ""}</Text>
              </div>
              <div className="d-flex mb-3 align-items-start">
                <TextQuestion>Guarantor type: </TextQuestion>{" "}
                <Text>{data?.guaType || ""}</Text>
              </div>
              <div className="d-flex mb-3">
                <TextQuestion>Comment:</TextQuestion>
                <Text>{data?.comment || ""}</Text>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="float-right mt-1">
              <ActionButton color="#2C1DFF" onClick={handleCloseModal}>
                <img
                  src={
                    require("../../../../../assets/images/icons/cancel.svg")
                      .default
                  }
                  alt="cancel icon"
                />
                Cancel
              </ActionButton>
            </div>
          </Modal.Footer>
        </>
      )}
    </>
  );
};

const RecoverLoan = ({ data, handleCloseModal }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleSubmit = () => {
    setLoading(true);
    dispatch(recoverGuarantor(data?.loanId));
    setLoading(false);
    handleCloseModal();
  };
  return (
    <>
      <Modal.Header>
        <Modal.Title className="text-capitalize">Recover from loan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="m-1">
          <p
            style={{ textAlign: "center", fontWeight: "400", fontSize: "18px" }}
          >
            Are you sure?
          </p>
          <p
            style={{ textAlign: "center", fontSize: "16px", color: "#969CBA" }}
          >
            You are about to recover {data?.amount || 0} from.
          </p>
        </div>

        <div
          className="position-relative"
          style={{
            margin: "20px 50px",
            border: "0.5px solid #969CBA",
            borderRadius: "5px",
            padding: "30px",
          }}
        >
          <div className="d-flex mb-3 ">
            <TextQuestion>Guarantor name: </TextQuestion>{" "}
            <Heading>{data?.name || ""}</Heading>
          </div>
          <div className="d-flex mb-3 ">
            <TextQuestion>Account: </TextQuestion>{" "}
            <Text>{data?.account || ""}</Text>
          </div>
          <div className="d-flex mb-3 ">
            <TextQuestion>Relationship type: </TextQuestion>{" "}
            <Text>{data?.relationship || ""}</Text>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-1">
          <ActionButton color="#2C1DFF" onClick={handleCloseModal}>
            <img
              src={
                require("../../../../../assets/images/icons/cancel.svg").default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={handleSubmit}
          >
            <img
              src={
                require("../../../../../assets/images/icons/save.svg").default
              }
              alt="submit icon"
            />
            Confirm
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

const TextQuestion = styled.p`
  width: 40%;
  font-size: 12px;
  font-family: "AventaSemiBold";
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: black;
  margin-right: 25px;
`;

const Heading = styled.h4`
  font-size: 12px;
  font-family: "AventaSemiBold";
  display: flex;
  align-items: center;
  text-align: center;
  color: #002c79;
`;

const Text = styled.p`
  font-size: 12px;
  font-family: "AventaRegular";
  color: black;
  text-align: center;
`;
