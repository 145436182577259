import { combineReducers } from "redux";

import userLogin, { getUserDataReducer, getDataReducer } from "./authReducers";

import customerReducer, {
  getSingleCustomerReducer,
  updateCustomerInfoReducer,
  updateCustomerAddressReducer,
  getCustomerAddressReducer,
  createNewCustomerReducer,
  uploadCustomerImageReducer,
  updateCustomerImageReducer,
  getCustomerImageReducer,
  getLoanLetters,
  getLoanOfferLetters,
  searchCustomerReducer,
  uploadCustomerDocumentReducer,
  getCustomerDocumentReducer,
  deleteDocumentReducer,
  getCustomerChargesReducer,
  getCustomerAccountsReducer,
  makeDepositReducer,
  downloadAccountStatementReducer,
  downloadRepaymentScheduleReducer,
  makeWithdrawalReducer,
  getLoanDetailsReducer,
  approveLoanReducer,
  disburseLoanReducer,
  createSavingsReducer,
  createLoanReducer,
  setGetAllCustomer,
  downloadTransactionReceiptReducer,
  savingsAccountReducer,
  getCountryReducer,
  getStateReducer,
  customerNotesReducer,
} from "./customerReducers";

import {
  userReducer,
  createNewUserReducer,
  updateUserInfoReducer,
  resetUserPasswordReducer,
  deletUserReducer,
  officeReducer,
  createNewOfficeReducer,
  updateOfficeInfoReducer,
  userTemplateReducer,
  officeStaffsReducer,
  currencyReducer,
  createNewCurrencyReducer,
  employeeReducer,
  singleEmployeeReducer,
  createNewEmployeeReducer,
  fetchEmployeeImageReducer,
  updateEmployeeImageReducer,
  updateEmployeeInfoReducer,
  entityDataTableReducer,
  entityDataTableTemplateReducer,
  createNewEntityDataTableReducer,
  deleteEntityDataTableInfoReducer,
  holidayReducer,
  activateHolidayReducer,
  createNewHolidayReducer,
  updateHolidayInfoReducer,
  deleteHolidayReducer,
  holidayTemplateReducer,
  workingDaysReducer,
  createWorkingDayReducer,
  standingInstructionsReducer,
  standingInstructionsTemplateReducer,
  paymentTypeReducer,
  createNewPaymentReducer,
  updatePaymentTypeInfoReducer,
  deletePaymentTypeReducer,
  savingsProductsReducer,
  savingsProductsTemplateReducer,
  createNewSavingsProductReducer,
  updateSavingsProductInfoReducer,
  savingsProductReducer,
  loanProductReducer,
  loanProductsReducer,
  loanProductsTemplateReducer,
  createNewLoanProductReducer,
  updateLoanProductInfoReducer,
  chargesReducer,
  chargesTemplateReducer,
  createNewChargeReducer,
  deleteChargeReducer,
  updateChargeInfoReducer,
  chargeReducer,
  taxComponentsReducer,
  taxComponentsTemplateReducer,
  createNewTaxComponentReducer,
  updateTaxComponentInfoReducer,
  taxGroupsReducer,
  taxGroupsTemplateReducer,
  createNewTaxGroupReducer,
  updateTaxGroupInfoReducer,
  auditReducer,
  auditTemplateReducer,
  singleAuditReducer,
  hookReducer,
  createNewHookReducer,
  deleteHookReducer,
  updateHookInfoReducer,
  hookTemplateReducer,
  s3Reducer,
  updateS3InfoReducer,
  SMTPReducer,
  updateSMTPInfoReducer,
  SMSReducer,
  updateSMSInfoReducer,
  notificationReducer,
  updateNotificationInfoReducer,
  configurationReducer,
  enableConfigurationReducer,
  disableConfigurationReducer,
  updateConfigurationInfoReducer,
  MCTasksReducer,
  updateMCTasksInfoReducer,
  rolesReducer,
  createNewRoleReducer,
  deleteRoleReducer,
  updateRoleInfoReducer,
  ableRoleReducer,
  roleReducer,
  ManageDataTables,
  checkUserPasswordReducer,
} from "./configurationReducers";

import { sendEoySettlementReducer } from "./settlementReducer";

import {
  getPaymentTypesReducer,
  getSavingsProductOptionsReducer,
  getSelectedSavingsProductOptionsReducer,
  getSavingChargeReducer,
  getLoanChargeReducer,
  getLoanProductOptionsReducer,
  getSelectedLoanProductDataReducer,
  getSelectedFixedDepositProductDataReducer,
  setIsLoading,
  setNavigationBar,
  setIsTableLoading,
  setCodeValues,
  manageDataTables,
  profileManageDataTables,
  savingsManageDataTables,
  setNotification,
  setToolTips,
  setCreateCustomerDetails,
  setCountryCodesDetails,
  setIsGenralSearching,
  setCalculatedLoan,
  setIsGenralSearchingLoading,
  setGeneralSearchQuery,
  setGlAccounts,
  setImportTemplate,
  getUserPaymentTypesReducer,
  setDropdownValueReducer,
} from "./utilityReducers";

import {
  savingsAccountTransReducer,
  loanAccountTransReducer,
} from "./transactionsReducer";

import {
  fdProductsReducer,
  fixedDepositProductsTemplateReducer,
  fdProductReducer,
  fixedDepositAcountTemplateReducer,
  // createNewFDProductReducer,
  createNewFDProductReducer,
  GenerateFdFileReducer,
  updateFdAccountInfoReducer,
} from "./fixedDepositReducer";

import allAccountingReducer, {
  getGlAccountsBalancesReducers,
} from "./accountingReducer";
import allAccountReducer from "./accountReducer";
import setFixedDepositReducers from "./fixedDepositReducer";
import allReportsReducers from "./reportsReducer";
import setPreAuthReducers from "./preAuthReducer";
import savingsAndLoanReducer from "./savingsAndLoanReducer";
import dashboardReducer from "./dashboardReducers";
import allTellerReducers, {
  cashierDataReducer,
  cashierDatareducer,
  cashierDataTellerReducer,
} from "./tellerReducer";
import allNotificationReducers from "./notificationReducer";
import { getSystemTiers } from "./systemTiersReducer";

import allBusinessReducer from "./businessReducer";
import {
  nibbsPerfomNameEnquiryReducer,
  nibssGetBanksTemplateReducer,
  nibssInflowTemplateReducer,
  nibssOutflowTemplateReducer,
  nibssSingleInflowTemplateReducer,
  nibssSingleOutflowTemplateReducer,
  nibssTenantsAccountBalanceReducer,
  nibssTenantsDetailsTemplateReducer,
  nibssTenantsWalletsTemplateReducer,
  nibbsAuthenticateOtpReducer,
  nibbsTransferFundReducer,
  // nibbsGetAllReportReducer,
  nibssGetAllReportReducer,
  nibssGetFilteredReportReducer,
  nibssGetFilteredTransReducer,
  nibssInflowFilteredTransReducer,
  nibssInflowFilteredTransReducerDownload,
} from "./nibssConnectReducer";
import loanCollateralReducer from "./loanCollateralReducer";

export default combineReducers({
  userLogin,
  userData: getUserDataReducer,
  userDetails: getDataReducer,
  isLoading: setIsLoading,
  navigationBar: setNavigationBar,
  customerPagination: setGetAllCustomer,
  isTableLoading: setIsTableLoading,
  codeValues: setCodeValues,
  manageDataTables,
  profileManageDataTables,
  savingsManageDataTables,
  notification: setNotification,
  toolTips: setToolTips,
  createCustomerDetails: setCreateCustomerDetails,
  countryCodesDetails: setCountryCodesDetails,
  countryDropdownValue: setDropdownValueReducer,
  savingsAndLoan: savingsAndLoanReducer,
  isGeneralSearching: setIsGenralSearching,
  calculatedLoan: setCalculatedLoan,
  isGeneralSearchingLoding: setIsGenralSearchingLoading,
  generalSearchQuery: setGeneralSearchQuery,
  glAccounts: setGlAccounts,
  importTemplate: setImportTemplate,
  preAuth: setPreAuthReducers,
  tellerReducer: allTellerReducers,
  cashierData: cashierDataReducer,
  cashierDataTeller: cashierDataTellerReducer,
  allNotificationReducers: allNotificationReducers,
  resetUserPasswordReducer,
  customerReducer,
  newCustomerCreated: createNewCustomerReducer,
  imgData: uploadCustomerImageReducer,
  updatedInfo: updateCustomerInfoReducer,
  updatedCustomerAddress: updateCustomerAddressReducer,
  customerImage: getCustomerImageReducer,
  loanLetters: getLoanLetters,
  loanOfferLetters: getLoanOfferLetters,
  updateCustomerImage: updateCustomerImageReducer,
  documentUpload: uploadCustomerDocumentReducer,
  documentsList: getCustomerDocumentReducer,
  chargesList: getCustomerChargesReducer,
  deleteDocument: deleteDocumentReducer,
  customerAccounts: getCustomerAccountsReducer,
  SingleCustomerDetails: getSingleCustomerReducer,
  customerAddress: getCustomerAddressReducer,
  searchedData: searchCustomerReducer,
  getCountryReducer,
  getStateReducer,

  accountingData: allAccountingReducer,
  accountData: allAccountReducer,
  fixedDepositData: setFixedDepositReducers,
  reportData: allReportsReducers,
  paymentTypes: getPaymentTypesReducer,
  savingsProductOptions: getSavingsProductOptionsReducer,
  selectedOptionData: getSelectedSavingsProductOptionsReducer,
  savingsChargeData: getSavingChargeReducer,
  loansChargeData: getLoanChargeReducer,
  loanProductOptions: getLoanProductOptionsReducer,
  selectedLoanData: getSelectedLoanProductDataReducer,
  selectedFixedDepositData: getSelectedFixedDepositProductDataReducer,
  userPaymentTypes: getUserPaymentTypesReducer,
  getGlAccountsBalances: getGlAccountsBalancesReducers,
  newSavingsAccount: createSavingsReducer,
  newLoanAccount: createLoanReducer,
  savingsDeposit: makeDepositReducer,
  savingsWithrawal: makeWithdrawalReducer,
  loanDetails: getLoanDetailsReducer,
  savingsAccountReducer,
  approveLoanReducer,
  disburseLoanReducer,
  accountStatement: downloadAccountStatementReducer,
  repaymentSchedule: downloadRepaymentScheduleReducer,
  transactionReceipt: downloadTransactionReceiptReducer,
  //fixedDepositAccountDetails
  fdProductsReducer,
  fixedDepositProductsTemplate: fixedDepositProductsTemplateReducer,
  fdProductReducer,
  fixedDepositAccountTemplate: fixedDepositAcountTemplateReducer,
  fdAccountInfo: updateFdAccountInfoReducer,
  generateFdTemplate: GenerateFdFileReducer,
  //  createNewFdProduct: createNewFDProductReducer,

  userReducer,
  newUserCreated: createNewUserReducer,
  updateUserInfo: updateUserInfoReducer,
  resetUserPasswordReducer,
  checkUserInfo: checkUserPasswordReducer,
  deletUser: deletUserReducer,
  userTemplate: userTemplateReducer,
  officeStaffs: officeStaffsReducer,
  updatedOfficeInfo: updateOfficeInfoReducer,
  newOfficeCreated: createNewOfficeReducer,
  officeReducer,
  currencyReducer,
  newCurrencyCreated: createNewCurrencyReducer,
  singleEmployeeReducer,
  employeeReducer,
  newEmployeeCreated: createNewEmployeeReducer,
  fetchEmployeeImageReducer,
  updateEmployeeImageReducer,
  updatedEmployeeInfo: updateEmployeeInfoReducer,
  entityDataTableReducer,
  entityDataTemplate: entityDataTableTemplateReducer,
  createNewEntityDataTable: createNewEntityDataTableReducer,
  deleteEntityDataTableInfo: deleteEntityDataTableInfoReducer,
  dashboardReducer,
  holidayReducer,
  holidayTemplate: holidayTemplateReducer,
  activatedHoliday: activateHolidayReducer,
  newHolidayCreated: createNewHolidayReducer,
  updatedHolidayInfo: updateHolidayInfoReducer,
  deletedHoliday: deleteHolidayReducer,
  workingDaysReducer,
  createWorkingDay: createWorkingDayReducer,
  standingInstructionsReducer,
  standingInstructionsTemplate: standingInstructionsTemplateReducer,
  paymentTypeReducer,
  createNewPayment: createNewPaymentReducer,
  updatePaymentTypeInfo: updatePaymentTypeInfoReducer,
  deletePaymentType: deletePaymentTypeReducer,
  savingsProductsReducer,
  savingsProductsTemplate: savingsProductsTemplateReducer,
  createNewSavingsProduct: createNewSavingsProductReducer,
  updateSavingsProductInfo: updateSavingsProductInfoReducer,
  savingsProductReducer,
  loanProductsReducer,
  loanProductsTemplate: loanProductsTemplateReducer,
  createNewLoanProduct: createNewLoanProductReducer,
  updateLoanProductInfo: updateLoanProductInfoReducer,
  loanProductReducer,
  //fixedDepositAccountDetails
  fdProductsReducer,
  fixedDepositProductsTemplate: fixedDepositProductsTemplateReducer,
  fdProductReducer,
  createNewFdProduct: createNewFDProductReducer,

  geteoyGlResponseBalance: sendEoySettlementReducer,

  // eoyGlResponseBalance: 

  chargesReducer,
  chargesTemplate: chargesTemplateReducer,
  createNewCharge: createNewChargeReducer,
  deleteCharge: deleteChargeReducer,
  chargeReducer,
  updateChargeInfo: updateChargeInfoReducer,
  taxComponentsReducer,
  taxComponentsTemplate: taxComponentsTemplateReducer,
  createNewTaxComponent: createNewTaxComponentReducer,
  updateTaxComponentInfo: updateTaxComponentInfoReducer,
  taxGroupsReducer,
  taxGroupsTemplate: taxGroupsTemplateReducer,
  createNewTaxGroup: createNewTaxGroupReducer,
  updateTaxGroupInfo: updateTaxGroupInfoReducer,
  auditReducer,
  auditTemplate: auditTemplateReducer,
  singleAudit: singleAuditReducer,
  hookReducer,
  createNewHook: createNewHookReducer,
  updateHookInfo: updateHookInfoReducer,
  deleteHook: deleteHookReducer,
  hookTemplate: hookTemplateReducer,
  s3Reducer,
  updateS3Info: updateS3InfoReducer,
  SMTPReducer,
  updateSMTPInfo: updateSMTPInfoReducer,
  SMSReducer,
  updateSMSInfo: updateSMSInfoReducer,
  notificationReducer,
  updateNotificationInfo: updateNotificationInfoReducer,
  configurationReducer,
  enableConfiguration: enableConfigurationReducer,
  disableConfiguration: disableConfigurationReducer,
  updateConfigurationInfoReducer,
  MCTasksReducer,
  updateMCTasksInfor: updateMCTasksInfoReducer,
  rolesReducer,
  createNewRole: createNewRoleReducer,
  deleteRole: deleteRoleReducer,
  updateRoleInfo: updateRoleInfoReducer,
  ableRoleReducer,
  roleReducer,
  savingsAccountTransReducer,
  loanAccountTransReducer,
  dataTables: ManageDataTables,
  businessReducer: allBusinessReducer,

  getSystemTiers: getSystemTiers,

  // nibbsWalletReducer,
  nibssTenantDetailsTemplate: nibssTenantsDetailsTemplateReducer,
  nibssTenantsWalletTemplate: nibssTenantsWalletsTemplateReducer,
  nibssTenantsAccountBalanceTemplate: nibssTenantsAccountBalanceReducer,
  nibssInflowTemplate: nibssInflowTemplateReducer,
  nibssOutflowTemplate: nibssOutflowTemplateReducer,
  nibssSingleInflowTemplate: nibssSingleInflowTemplateReducer,
  nibssSingleOutflowTemplate: nibssSingleOutflowTemplateReducer,
  nibssGetBanksTemplate: nibssGetBanksTemplateReducer,
  nibbsPerfomNameEnquiry: nibbsPerfomNameEnquiryReducer,
  AuthenticatePinCode: nibbsAuthenticateOtpReducer,
  TransferNibssFund: nibbsTransferFundReducer,
  nibssGetAllReportTemplate: nibssGetAllReportReducer,
  nibssGetFilteredReportTemplate: nibssGetFilteredReportReducer,
  nibssGetFilteredTransTemplate: nibssGetFilteredTransReducer,
  nibssInflowFilteredTransTemplate: nibssInflowFilteredTransReducer,
  nibssInflowFilteredDownload: nibssInflowFilteredTransReducerDownload,
  loanCollateralReducer,
  customerNotesReducer
});
