import React from "react";
import { useSelector } from "react-redux";
import { Select, Input, FormControl, Checkbox } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
// import { Checkbox } from "antd";

// import QuestionMark from "../../../../../../assets/images/icons/question-mark.svg";

import {
  FormClass,
  FormElements,
  ActionButton,
  FormTitle,
} from "../../styles.jsx";

const LoanDetails = ({
  handleNextScreen,
  productType,
  handleProductType,
  purposeType,
  handlePurposeType,
  externalId,
  handleExternalId,
  handleSubmittedOn,
  submittedOnDate,
  disbursementDate,
  handleDisbursementDate,
  handleFund,
  fund,
  loanPurpose,
  handleLoanPurpose,
  loanOfficer,
  handleOfficer,
  handleLinkedAccount,
  linkedAccount,
  setNewLoanProduct,
  createStandingInstructionAtDisbursement,
  createLoanProductHandler,
  // setCreateStandingInstructionAtDisbursement,
}) => {
  const productOptionData = useSelector(
    (state) => state.loanProductOptions.productData
  );
  const { productOptions } = productOptionData;

  const selectedLoanData = useSelector((state) => state.selectedLoanData);
  const { productData } = selectedLoanData;

  const { loanOfficerOptions, accountLinkingOptions, loanPurposeOptions } = productData;


  const customOptions = [
    { id: "true", title: "Yes" },
    { id: "false", title: "No" },
  ];

  return (
    <>
      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  <span>Product</span>
                  {/* <img src={QuestionMark}alt="ask" className='ml-1'/> */}
                </label>
                <Select
                  placeholder="Select loan account type"
                  size="md"
                  value={productType}
                  onChange={handleProductType}
                >
                  {productOptions?.map((opt) => (
                    <option id={opt.id} key={opt.id}>
                      {opt.name}
                    </option>
                  ))}
                </Select>
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  <span>Loan officer</span>
                  {/* <img src={QuestionMark}alt="ask" className='ml-1'/> */}
                </label>
                <Select
                  placeholder="Select loan officer"
                  size="md"
                  value={loanOfficer}
                  onChange={handleOfficer}
                >
                  {loanOfficerOptions !== undefined &&
                    loanOfficerOptions.map((officer) => (
                      <option
                        id={officer.id}
                        key={officer.id}
                      >{`${officer.firstname} ${officer.lastname}`}</option>
                    ))}
                </Select>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>

      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  <span>Loan Purpose</span>
                  {/* <img src={QuestionMark}alt="ask" className='ml-1'/> */}
                </label>
                <Select
                  placeholder="Select loan purpose"
                  size="md"
                  // name="loanPurpose"
                  value={purposeType}
                  onChange={handlePurposeType}
                  // value={loanPurpose}
                  // onChange={createLoanProductHandler}
                >
                  {loanPurposeOptions !== undefined &&
                    loanPurposeOptions?.map((opt) => (
                      <option id={opt.id} key={opt.id}
                      >
                        {opt.name}
                      </option>
                    ))}
                </Select>
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  <span>Fund</span>
                </label>
                <Select
                  placeholder="Select fund"
                  size="md"
                  value={fund}
                  onChange={handleFund}
                >
                  {/* {fieldOfficerOptions !== undefined && fieldOfficerOptions.map(officer => (
                                <option id={officer.id} key={officer.id}>{`${officer.firstname} ${officer.lastname}`}</option>                          
                            ))} */}
                </Select>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>

      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  <span>Submitted On*</span>
                  {/* <img src={QuestionMark}alt="ask" className='ml-1'/> */}
                </label>
                <FormControl style={{ width: "60%" }}>
                  <DatePicker
                    id="published-date"
                    selected={submittedOnDate}
                    onChange={(date) => handleSubmittedOn(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="dd/mmm/yyyy"
                  />
                </FormControl>
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  <span>Disbursed On*</span>
                  {/* <img src={QuestionMark}alt="ask" className='ml-1'/> */}
                </label>
                <FormControl style={{ width: "60%" }}>
                  <DatePicker
                    id="published-date"
                    selected={disbursementDate}
                    onChange={(date) => handleDisbursementDate(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="dd/mmm/yyyy"
                  />
                </FormControl>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>

      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  <span>External ID</span>
                  {/* <img src={QuestionMark}alt="ask" className='ml-1'/> */}
                </label>
                <Input
                  style={{ width: "60%" }}
                  size="md"
                  value={externalId}
                  onChange={handleExternalId}
                />
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>

      <FormTitle style={{ width: "62.5%", margin: "0 auto" }} className="mt-5">
        <p className="text-info linkSavings">
          Link An Account for Disbursement and Repayment
        </p>
        <div className="lineThrough"></div>
      </FormTitle>

      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap mt-5">
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  <span>Link Savings:</span>
                </label>
                <Select
                  placeholder="Select one"
                  size="md"
                  name="linkedAccount"
                  value={linkedAccount}
                  onChange={createLoanProductHandler}
                >
                  
                    {accountLinkingOptions !== undefined &&
                    accountLinkingOptions.map((opt) => (
                      <option value={opt.id} key={opt.id}>
                        {`${opt.productName} - ${opt.accountNo}`}
                      </option>
                    ))}
                </Select>
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  <span>Create standing instructions at disbursement:</span>
                </label>
                <div className="" style={{ width: "60%" }}>
                  <Checkbox
                    size="md"
                    name="createStandingInstructionAtDisbursement"
                    isChecked={createStandingInstructionAtDisbursement}
                    onChange={(e) =>
                      setNewLoanProduct((p) => ({
                        ...p,
                        createStandingInstructionAtDisbursement: e.target.checked,
                      }))
                    }
                  />
                </div>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>

      <div>
        <div className="float-right pt-5">
          <ActionButton
            color="#005BFC"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={handleNextScreen}
          >
            <img
              src={
                require("../../../../../../assets/images/icons/arrow-right.svg")
                  .default
              }
              alt="submit icon"
            />
            Next
          </ActionButton>
        </div>
      </div>
    </>
  );
};

export default LoanDetails;