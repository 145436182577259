import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Select, FormControl } from "@chakra-ui/react";
import { FormClass, ActionButton, FormElements } from "../styles";
import { formatDate, formatDateReactDatePicker } from "../../../../../util/FormatDate";
import CurrencyFormat from "react-currency-format";
import { addChargeToLoanAccount, getLoansChargeTemplate } from "../../../../../actions/SavingsAndLoanActions";

const AddCharge = ({ onHide, history, id }) => {
  const dispatch = useDispatch();

  const { loanAccountsChargeTemplate: { chargeOptions } } = useSelector(state => state.savingsAndLoan)

  let selectedTemplate = {};

  const [charges, setCharges] = useState();

  function isEmptyObject(obj) {
    return Object.keys(obj).length === 0;
  }

  chargeOptions?.forEach((option) => {
    let { id } = option;
    if (charges === `${id}`) {
      selectedTemplate = option;
    }
  });

  const [amount, setAmount] = useState(0);
  const [chargeCalculation, setChargeCalculation] = useState();
  const [chargeTimeType, setChargeTimeType] = useState();
  const [dueDate, setDueDate] = useState("");

  useEffect(() => {
    if (!isEmptyObject(selectedTemplate)) {
      setAmount(selectedTemplate.amount);
      setChargeCalculation(selectedTemplate.chargeCalculationType.value);
      setChargeTimeType(selectedTemplate.chargeTimeType.value);
    } else {
      setAmount("");
      setChargeCalculation("");
      setChargeTimeType("");
    }
  }, [selectedTemplate]);

  useEffect(() => {
    dispatch(getLoansChargeTemplate(id));
  }, [id, dispatch]);

  const handleAddCharge = () => {
    let dataToSend = {
      amount,
      chargeId: charges,
      dateFormat: "dd MMM yyyy",
      dueDate: !!dueDate ? formatDate(dueDate, true) : "",
      locale: "en",
    };

    const newObject = { ...dataToSend };
    for (const key in newObject) {
      if (Object.prototype.hasOwnProperty.call(newObject, key)) {
        const element = newObject[key];
        if (!element && typeof element !== 'number') {
          delete newObject[key];
        } else if((element + '').length <= 0) {
          delete newObject[key];
        }
      }
    }

    dataToSend = newObject;

    dispatch(addChargeToLoanAccount(id, dataToSend));
    onHide();
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>Add Loan Charge</Modal.Title>
      </Modal.Header>
      <Modal.Body className="wc-modal">
        <div className="d-flex align-items-center justify-content-center flex-direction-coloumn">
          <FormClass style={{ width: "90%" }}>
            <FormElements style={{ width: "100%" }}>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Charges*</label>
                <Select
                  placeholder="Select Charges"
                  size="md"
                  name="charges"
                  value={charges}
                  onChange={(e) => setCharges(e.target.value)}
                >
                  {!!chargeOptions ? (
                    chargeOptions.map(({ name, id }) => {
                      return (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      );
                    })
                  ) : (
                    <option>Loading</option>
                  )}
                </Select>
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">
                  Amount ({
                    selectedTemplate?.chargeCalculationType?.value === "Flat"
                    ? selectedTemplate?.currency?.code
                    : '%'
                  })*
                </label>
                <CurrencyFormat
                  value={amount}
                  thousandSeparator={true}
                  className="amount-input "
                  style={{
                    width: "60%",
                  }}
                  onValueChange={(values) => {
                    const { value } = values;
                    setAmount(value);
                  }}
                />
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Charge Calculation</label>
                <Select
                  placeholder="Select Charge Calculation"
                  size="md"
                  name="chargeCalculation"
                  value={chargeCalculation}
                  disabled
                  onChange={(e) => setChargeCalculation(e.target.value)}
                >
                  <option value={chargeCalculation}>{chargeCalculation}</option>
                </Select>
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Charge Time Type</label>
                <Select
                  placeholder="Select Charge Time Type"
                  size="md"
                  name="chargeTimeType"
                  value={chargeTimeType}
                  disabled
                  onChange={(e) => setChargeTimeType(e.target.value)}
                >
                  <option value={chargeTimeType}>{chargeTimeType}</option>
                </Select>
              </div>
              {!!selectedTemplate &&
              !!selectedTemplate.chargeTimeType &&
              selectedTemplate.chargeTimeType.id &&
              selectedTemplate.chargeTimeType?.id === 2 ||
              selectedTemplate.chargeTimeType?.id === 11 
              ? (
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Due For Collection On*</label>
                  <FormControl style={{ width: "60%" }}>
                    <div className="datepicker-100-width">
                    <DatePicker
                      id="published-date"
                      selected={dueDate}
                      onChange={(date) => setDueDate(date)}
                      minDate={new Date(formatDate(new Date()))}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="dd/mmm/yyyy"
                    />
                    </div>
                  </FormControl>
                </div>
              ) : (
                ""
              )}
            </FormElements>
          </FormClass>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex mt-4">
          <ActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require("../../../../../assets/images/icons/cancel.svg").default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={(e) => {
              e.preventDefault();
              handleAddCharge();
            }}
          >
            <img
              src={
                require("../../../../../assets/images/icons/save.svg").default
              }
              alt="submit icon"
            />
            Submit
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default AddCharge;
