import React, { useState, useRef, useEffect } from "react";
import {
  ContentWrapper,
  ActionButtonsGroup,
  ActionButton,
  EditButton,
} from "./styles";
import { InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import ModalContainer from "../../components/ModalContainer";
import NewRolesPermissions from "./components/NewRP";
import DeleteRolesPermissions from "./components/DeleteRP";
import { useDispatch, useSelector } from "react-redux";
import {
  filterRoles,
  clearFilteredRoles,
  getAllRoles,
  ableRole,
} from "../../../../actions/configurationActions";
import TableLoader from "./components/TableLoader";
import CheckPermission from "../../../../util/CheckPermission";

const ManageRolesPermissionsView = () => {
  const dispatch = useDispatch();
  const [modalState, toggleModal] = useState(false);
  const [modalContentName, setModalContentName] = useState("");
  const [selectedRolesPermissionsId, setSelectedRolesPermissionsId] =
    useState("");
  const roleList = useSelector((state) => state.rolesReducer);
  const { loading, roles, filtered } = roleList;
  roles.sort((a, b) => (a.name > b.name ? 1 : -1));
  const search = useRef("");

  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  const onFilterRole = (e) => {
    search.current.value !== ""
      ? dispatch(filterRoles(e.target.value))
      : dispatch(clearFilteredRoles());
  };
  useEffect(() => {
    dispatch(clearFilteredRoles());
    dispatch(getAllRoles());
    if (filtered === null) {
      search.current.value = "";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteRolesPermissions = (id) => {
    setSelectedRolesPermissionsId(id);
    toggleModal(true);
    setModalContentName("deleteRolesPermissions");
  };

  const currentContent = (e) => {
    const val = e.currentTarget.id.trim();
    if (val) {
      toggleModal(true);
      setModalContentName(val);
    }
  };
  const handleModalClose = () => toggleModal(false);
  const displayModalContent = () => {
    switch (modalContentName) {
      case "newRolesPermissions":
        return <NewRolesPermissions onHide={handleModalClose} />;
      case "deleteRolesPermissions":
        return (
          <DeleteRolesPermissions
            id={selectedRolesPermissionsId}
            onHide={handleModalClose}
          />
        );
      default:
        return;
    }
  };

  const handleAble = async (id, action) => {
    const func = () => {
      dispatch(getAllRoles());
    };
    await dispatch(ableRole(null, id, action, func));
  };

  //Table configuration

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    { title: "Description", dataIndex: "description", key: "description" },
    { title: "Status", dataIndex: "status", key: "status" },
    { title: "", dataIndex: "able", key: "able" },
    { title: "", dataIndex: "edit", key: "edit" },
    { title: "", dataIndex: "delete", key: "delete" },
  ];
  const dataSource = [];
  const filteredRolesPermissions = [];

  if (filtered !== null) {
    filtered.forEach((hook) => {
      const { id, name, disabled, description } = hook;
      filteredRolesPermissions.push({
        key: id,
        name: name,
        description: description,
        status: (
          <>
            {disabled ? (
              <span className="new-text-danger">Disabled</span>
            ) : (
              <span className="new-text-success">Enabled</span>
            )}
          </>
        ),
        able: CheckPermission(
          disabled ? "ENABLE_ROLE" : "DISABLE_ROLE",
          permissions
        ) && (
          <EditButton
            color={!disabled ? "#FF0000" : "#1DCB43"}
            onClick={() => handleAble(id, disabled ? "enable" : "disable")}
          >
            {!disabled ? "Disable" : "Enable"}
          </EditButton>
        ),
        edit: CheckPermission("UPDATE_ROLE", permissions) && (
          <Link to={`roles-management/view-role/${id}`}>
            <EditButton>View</EditButton>
          </Link>
        ),
        delete: CheckPermission("DELETE_ROLE", permissions) && (
          <EditButton onClick={() => handleDeleteRolesPermissions(id)}>
            Delete
          </EditButton>
        ),
      });
    });
  }
  !loading &&
    roles.forEach((hook) => {
      const { id, name, disabled, description } = hook;
      dataSource.push({
        key: id,
        name: name,
        description: description,
        status: (
          <>
            {disabled ? (
              <span className="new-text-danger">Disabled</span>
            ) : (
              <span className="new-text-success">Enabled</span>
            )}
          </>
        ),
        able: CheckPermission(
          disabled ? "ENABLE_ROLE" : "DISABLE_ROLE",
          permissions
        ) && (
          <EditButton
            color={!disabled ? "#FF0000" : "#1DCB43"}
            onClick={() => handleAble(id, disabled ? "enable" : "disable")}
          >
            {!disabled ? "Disable" : "Enable"}
          </EditButton>
        ),
        edit: CheckPermission("UPDATE_ROLE", permissions) && (
          <Link to={`roles-management/view-role/${id}`}>
            <EditButton>View</EditButton>
          </Link>
        ),
        delete: CheckPermission("DELETE_ROLE", permissions) && (
          <EditButton onClick={() => handleDeleteRolesPermissions(id)}>
            Delete
          </EditButton>
        ),
      });
    });

  return (
    <>
      <ModalContainer show={modalState} dialogClassName="document_infoModal">
        {modalState && displayModalContent()}
      </ModalContainer>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Manage Roles And Permissions</h3>
            <p className="sub-title">
              Define Or Modify Roles And Associated Permissions
            </p>
            {/* <p className="font-weight sub-title mt-2">
              <span className="text-info">
                <Link to="/core/manage-utilities">Manage Utilities</Link>
              </span>{" "}
              / Manage Roles And Permissions
            </p> */}
          </div>
          <div className="col-sm-12 col-md-6 px-0 pt-3">
            <ActionButtonsGroup>
              <div className="col-sm-12 col-md-6 pr-2">
                <InputGroup>
                  <InputLeftElement
                    width="3.1rem"
                    height="38px"
                    pointerEvents="none"
                  >
                    <img
                      src={
                        require("../../../../assets/images/icons/search.svg")
                          .default
                      }
                      style={{ height: "30px" }}
                      alt="Icon"
                      className="icon-img"
                    />
                  </InputLeftElement>
                  <Input
                    style={{
                      background: "#ffffff",
                      height: "35px",
                    }}
                    ref={search}
                    onChange={onFilterRole}
                    type="text"
                    className="general-font-size"
                    placeholder="Search role name"
                  />
                </InputGroup>
              </div>
              <div className="col-sm-12 col-md-6 p-0 d-flex">
                {CheckPermission("CREATE_ROLE", permissions) && (
                  <ActionButton
                    id="newRolesPermissions"
                    onClick={currentContent}
                  >
                    <img
                      src={
                        require("../../../../assets/images/icons/add.svg")
                          .default
                      }
                      alt="add"
                    />
                    Add Role
                  </ActionButton>
                )}
              </div>
            </ActionButtonsGroup>
          </div>
        </div>
        {CheckPermission("READ_ROLE", permissions) && (
          <ContentWrapper>
            {loading ? (
              <TableLoader />
            ) : (
              <>
                {filtered !== null ? (
                  <Table
                    columns={columns}
                    dataSource={filteredRolesPermissions}
                  />
                ) : (
                  <Table
                    columns={columns}
                    dataSource={dataSource}
                    pagination={{ defaultPageSize: 13 }}
                    defaultPageSize={13}
                  />
                )}
              </>
            )}
          </ContentWrapper>
        )}
      </div>
    </>
  );
};

export default ManageRolesPermissionsView;
