import React from "react";
import { Table } from "antd";
import Loading from "./Loading";

const columns = [
  { title: "Name", dataIndex: "name", key: "name" },
  { title: "Charges applied to?", dataIndex: "charges", key: "charges" },
  { title: "Is penalty?", dataIndex: "isPen", key: "isPen" },
  { title: "Status", dataIndex: "status", key: "status" },
  { title: "", dataIndex: "edit", key: "edit" },
];

const TableLoader = (props) => {
  const someChartsData = [
    {
      key: 1,
      name: <Loading />,
      charges: <Loading />,
      isPen: <Loading />,
      status: <Loading />,
      edit: <Loading />,
    },
    {
      key: 2,
      name: <Loading />,
      charges: <Loading />,
      isPen: <Loading />,
      status: <Loading />,
      edit: <Loading />,
    },
    {
      key: 3,
      name: <Loading />,
      charges: <Loading />,
      isPen: <Loading />,
      status: <Loading />,
      edit: <Loading />,
    },
    {
      key: 4,
      name: <Loading />,
      charges: <Loading />,
      isPen: <Loading />,
      status: <Loading />,
      edit: <Loading />,
    },
    {
      key: 5,
      name: <Loading />,
      charges: <Loading />,
      status: <Loading />,
      isPen: <Loading />,
      edit: <Loading />,
    },
  ];
  return (
    <>
      <Table
        columns={columns}
        dataSource={someChartsData}
        pagination={{ defaultPageSize: 8 }}
        className="antdTable"
        defaultPageSize={8}
      />
    </>
  );
};

export default TableLoader;
