import API_DASHBOARD from "../services/Api/dashboard.api.services";
import {
  DASHBOARD_REQUEST,
  DASHBOARD_SUCCESS,
  DASHBOARD_FAIL,
  CLEAR_FILTER,
} from "../constants/dashboardConstants";

import Toast from "../util/Toast";

const dashboardRequestFail = (payload) => {
  return { type: DASHBOARD_FAIL, payload };
};

// FILTER DASHBOARD

//RESET DASHBOARD
const clearFilteredStatistics = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_FILTER });
  };
};

// GET DASHBOARD STATISTICS
const getStatistics = () => {
  return async (dispatch) => {
    dispatch({ type: DASHBOARD_REQUEST });
    await API_DASHBOARD.getStatistics()
      .then((response) => {
        dispatch({ type: DASHBOARD_SUCCESS, payload: response.data });
      })
      .catch((ex) => {
        if (ex.response) {
          // console.log(ex);
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else if (ex.request) {
          dispatch(
            Toast({
              text: "Error: Network Error Please check connection and refresh",
              icon: "error",
            })
          );
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(dashboardRequestFail(ex));
      });
  };
};

export { getStatistics, clearFilteredStatistics };
