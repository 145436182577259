import styled from "styled-components";

export const FormElements = styled.div`
  /* width: 90%; */
  margin-top: 30px;
  margin-bottom: 30px;
  display: block;

  label {
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    color: #969cba;
    width: fit-content;
    margin-right: 10px;
    width: 30%;
  }
`;

export const Container = styled.div`
  background: #ffffff;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 5px 0 50px 0;
  margin: 25px 150px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .label {
    border-right: 1px solid #a6acbe;
  }
  .cancel_btn {
    color: #002c79;
  }
  @media only screen and (max-width: 960px) {
    margin: 25px 0px;
  }
`;
export const Text = styled.p`
  padding-bottom: 10px;
  font-size: 16px;
  color: #a6acbe;
`;
export const Value = styled.p`
  padding-bottom: 10px;
  font-size: 16px;
  text-transform: capitalize;
`;
export const SearchResultContainer = styled.div`
  background: #eef0f7;
  border-radius: 8px;
  padding: 25px 30px;
  width: 40%;
  margin: auto;

  h4 {
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    font-family: 'AventaSemiBold';
  }
  p {
    font-weight: 400;
    font-size: 14px;
    color: #969cba;
    font-family: 'AventaBold';
  }
  .name-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 18px;
  }
`;

export const SavingsAccountContainer = styled.button`
  width: 35%;
  margin: auto;
  background: #f6f8fc;
  display: flex;
  justify-content: space-between;
  padding: 16px 30px;

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.03em;
    color: #969cba;
    font-family: 'AventaSemiBold';
  }
  h4 {
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    display: flex;
    align-items: center;
    color: #000000;
    font-family: 'AventaBold';
  }
`;

export const Button = styled.button`
  background: #ffffff;
  border: 1px solid #2c1dff;
  border-radius: 5px;
  color: #2c1dff;
  padding: 7px 17px;
  cursor: pointer;
`;
