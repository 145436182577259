import React, { useState, useEffect } from "react";
import { Input } from "@chakra-ui/react";
import { ActionButton, FormElements, FormClass } from "../styles";
import { Modal, Spinner } from "react-bootstrap";
import {
  updateS3Info,
  getAllS3,
} from "../../../../../../actions/configurationActions";

import { getUserData } from "../../../../../../actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import Toast from "../../../../../../util/Toast";

const EditS3 = ({ onHide, data }) => {
  const dispatch = useDispatch();

  const userDataObj = useSelector((state) => state.userData);
  const { userData } = userDataObj;

  const [loading, setLoading] = useState(false);
  const [editS3Info, setEditS3Info] = useState({
    name: "",
  });
  useEffect(() => {
    if (data) {
      if (data.length > 0)
        data.map((item, index) =>
          setEditS3Info((p) => ({
            ...p,
            [`value${index}`]: item.value,
          }))
        );
    }
  }, [data]);

  const editS3Handler = (e) =>
    setEditS3Info({
      ...editS3Info,
      [e.target.name]: e.target.value,
    });

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    const dataToSubmit = {};
    const func = () => {
      setLoading(false);
      dispatch(getAllS3());
      onHide();
    };
    await dispatch(updateS3Info(dataToSubmit, func));
    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getUserData());
    };

    fetchData();
  }, [dispatch]);

  const [reason, setReason] = useState("");
  const newEditS3Config = (e) => {
    e.preventDefault();

    fetch(
      "https://hooks.slack.com/services/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",
      {
        method: "POST", // or 'PUT'
        body: JSON.stringify({
          text: "S3 Change Request",
          attachments: [
            {
              text: `User Fullname: ${
                !!userData && !!userData.firstname && !!userData.lastname
                  ? `${userData.firstname} ${userData.lastname} `
                  : ""
              }`,
            },
            {
              text: `User Email: ${
                !!userData && !!userData.email ? userData.email : ""
              }`,
            },
            {
              text: `User Reason For Change: ${reason}`,
            },
          ],
        }), // data can be `string` or {object}!
        headers: {
          // "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        let { status, ok } = res || {};
        if (ok && status === 200) {
          onHide();
          dispatch(Toast({ text: "Successful!", icon: "success" }));
        }
      })
      .catch((response) => {
        onHide();
        dispatch(Toast({ text: "Unable to Send!", icon: "error" }));
      });
  };
  return (
    <>
      <Modal.Header>
        <Modal.Title>Edit External Service Configuration</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <FormElements style={{ width: "100%" }}>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-muted text-capitalize">
                Why do you want to Change?
              </label>
              <Input
                style={{ width: "60%" }}
                size="md"
                name="reason"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </div>
          </FormElements>
          <div className="float-right mt-4">
            <ActionButton color="#2C1DFF" onClick={onHide}>
              <img
                src={
                  require("../../../../../../assets/images/icons/cancel.svg")
                    .default
                }
                alt="cancel icon"
              />
              Cancel
            </ActionButton>
            <ActionButton
              onClick={newEditS3Config}
              color="#2C1DFF"
              style={{ margin: "0px 0px 0 10px" }}
              disabled={loading}
            >
              {loading ? (
                <Spinner
                  animation="border"
                  color="#fff"
                  className="mr-2 text-white"
                  size="sm"
                />
              ) : (
                <img
                  src={
                    require("../../../../../../assets/images/icons/save.svg")
                      .default
                  }
                  alt="submit icon"
                />
              )}
              Submit
            </ActionButton>
          </div>
        </FormClass>
      </Modal.Body>
    </>
  );
};

export default EditS3;
