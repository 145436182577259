import styled, { createGlobalStyle } from "styled-components";

const Global = createGlobalStyle`
  html,
  body,
  div,
  a,
  i,
  button,
  select,
  option,
  optgroup,
  hr,
  br {
    user-select: none;
    cursor: default;
  }
`;

const MainCon = styled.div`
  overflow-x: auto;
  overflew-y: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  padding: 0 10px;
  min-height: 50vh;
`;

const Frame = styled.div`
  position: relative;
  padding: 4px 0px 0px 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
  // overflow-x: hidden;
  vertical-align: middle;
  color: white;
  fill: white;
  .v-line-b {
      height: 58px;
      width: 1px;
      background: #2C1DFF;
      position: absolute;
      bottom: -60px;
      left: 2px;
  }
  .v-line-bb{
    height: 58px;
    width: 1px;
    background: #2C1DFF;
    position: absolute;
    bottom: -50px;
    left: 20px;
  }
  &:first-child{
    
    svg{
      margin-left: -5px !important;
    }
    .v-line {
     left: 30% !important;
    }
  }
  }
`;

const Title = styled.span`
  vertical-align: middle;
`;

const Content = styled.div`
  will-change: transform, opacity, height;
  margin-left: -1px;
  // padding: 0px 0px 0px 14px;
  // border-left: 1px dashed red;
  // overflow: hidden;
  &:nth-child(2) {
    .svg-line {
      display: block !important;
    }
  }
  > div {
    &:first-of-type {
      display: flex;
      margin-top: -32px;
    }
  }
  .span {
    width: 28px;
    height: 1px;
    background: #2C1DFF;
  }
  .v-line {
    height: 50px;
    width: 1px;
    background: #2C1DFF;
    position: absolute;
    top: -50px;
    left: 75%;
  }
  svg {
    margin-right: 0 !important;
    margin-left: 20px;
  }
  margin-top: 72px;
  position: relative;
  .line {
    position: absolute;
    height: 1px;
    background: #2C1DFF;
    width: 41.9rem;
    top: -30px;
    left: 3px;
  }
`;

const toggle = {
  width: "1em",
  height: "1em",
  marginRight: 10,
  cursor: "pointer",
  verticalAlign: "middle",
};

const Button = styled.button`
  background: ${(props) =>
    props.id === 1 ? "#2C1DFF" : "rgba(162, 194, 252, 0.6)"};
  border-radius: 5px !important;
  border: none !important;
  outline: none !important;
  display: inline;
  font-size: 12px;
  height: 35px;
  width: ${(props) => (props.id === 1 ? "230px" : "8rem")};
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  position: relative;
  color: ${(props) => (props.id === 1 ? "#fff" : "#000")};
  cursor: pointer;
  &:active {
    outline: none !important;
  }
  img {
    height: 7px;
    margin-left: 8px;
    display: inline;
  }
`;

export { Global, Frame, Content, toggle, Title, Button, MainCon };
