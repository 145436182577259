import React from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

const AppSettings = ({ children }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12 col-md-12">
          <div
            style={{
              paddingBottom: "4.5rem",
                borderBottom: "1px solid #E2E8F0",
            }}
          >
            <ProgressBar percent={40}>
              <Step style={{ flexDirection: "column" }}>
                {({ accomplished, index }) => (
                  <>
                    <div
                      className={`indexedStep ${
                        accomplished ? "accomplished" : ""
                      }`}
                    >
                      <img
                        style={{ width: "10px", height: "10px" }}
                        src={
                          require("../../../../../../../../assets/images/icons/save.svg")
                            .default
                        }
                        alt="submit icon"
                      />
                    </div>
                    <div className="pt-3">
                      <h3 className="woodcore-blue">Details & currency</h3>
                    </div>
                  </>
                )}
              </Step>
              <Step style={{ flexDirection: "column" }}>
                {({ accomplished, index }) => (
                  <>
                    <div
                      className={`indexedStep ${
                        accomplished ? "accomplished" : ""
                      }`}
                    >
                      <img
                        style={{ width: "10px", height: "10px" }}
                        src={
                          require("../../../../../../../../assets/images/icons/save.svg")
                            .default
                        }
                        alt="submit icon"
                      />
                    </div>
                    <div className="pt-3">
                      <h3 className="woodcore-blue">Terms</h3>
                    </div>
                  </>
                )}
              </Step>
              <Step style={{ flexDirection: "column" }}>
                {({ accomplished, index }) => (
                  <>
                    <div
                      className={`indexedStep ${
                        accomplished ? "accomplished" : ""
                      }`}
                    ></div>
                    <div className="pt-3">
                      <h3 className="woodcore-blue">Settings</h3>
                    </div>
                  </>
                )}
              </Step>
              <Step style={{ flexDirection: "column" }}>
                {({ accomplished, index }) => (
                  <>
                    <div
                      className={`indexedStep ${
                        accomplished ? "accomplished" : ""
                      }`}
                    >
                      <span></span>
                    </div>
                    <div className="pt-3">
                      <h3 className="woodcore-blue">Interest rate</h3>
                    </div>
                  </>
                )}
              </Step>
              <Step style={{ flexDirection: "column" }}>
                {({ accomplished, index }) => (
                  <>
                    <div
                      className={`indexedStep ${
                        accomplished ? "accomplished" : ""
                      }`}
                    >
                      <span></span>
                    </div>
                    <div className="pt-3">
                      <h3 className="woodcore-blue" style={{marginLeft:"45px"}}>Charges & Accounting</h3>
                    </div>
                  </>
                )}
              </Step>
              <Step style={{ flexDirection: "column" }}>
                {({ accomplished, index }) => (
                  <>
                    <div
                      className={`indexedStep ${
                        accomplished ? "accomplished" : ""
                      }`}
                    >
                      <span></span>
                    </div>
                    <div className="pt-3">
                      <h3 className="woodcore-blue">Preview</h3>
                    </div>
                  </>
                )}
              </Step>
            </ProgressBar>
          </div>
        </div>
      </div>

      <div
        style={{ display: "flex", flexDirection: "column", marginTop: "30px" }}
      >
        {children}
      </div>
    </div>
  );
};

export default AppSettings;
