import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { Input, Select, FormControl, Textarea } from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import CurrencyFormat from 'react-currency-format';
import {
  FormClass,
  FormElements,
  ActionButton,
} from '../../CustomerInformation/styles';
import { formatAmount } from '../../../../../util/FormatAmount';
import {
  getLoanDisburseDetails,
  handleLoanTransactions,
  accountTransfer,
} from "../../../../../actions/SavingsAndLoanActions";
import { formatDate } from "../../../../../util/FormatDate.js";
import Toast from "../../../../../util/Toast";
import { removeCommas } from "../../../../../util/FormatNumber.js";

const LoanRepaymentModal = ({ onHide, id, history }) => {
  const dispatch = useDispatch();
  const savingsAndLoan = useSelector((state) => state.savingsAndLoan);

  let {
    loanAccountDisburseDetails,
    loanDetails: { linkedAccount, clientId, clientOfficeId, id: loanId, currency },
  } = savingsAndLoan || {};

  let { amount } = loanAccountDisburseDetails || {};

  useEffect(() => {
    dispatch(getLoanDisburseDetails(id, "repayment"));
  }, [dispatch, id]);

  const [transactionDate, setTransactionDate] = useState(new Date());
  const [modalState, setModalState] = useState(1);
  const [enteredAmount, setAmount] = useState(amount || "");
  const [note, setNote] = useState("");

  let formatTransactionDate = "";
  if (transactionDate !== "") {
    formatTransactionDate = formatDate(transactionDate, true);
  }

  useEffect(() => {
    if (!!amount) {
      setAmount(amount);
    }
  }, [amount]);

  const handleLoanRepayment = () => {
    let disbursementData = {
      transactionDate: formatTransactionDate,
      locale: "en",
      dateFormat: "dd MMM yyy",
      accountNumber: linkedAccount?.accountNo,
      transactionAmount: enteredAmount,
      note,
    };
    let disbursmentData2 = {
      fromOfficeId: clientOfficeId,
      fromClientId: clientId,
      fromAccountType: 2,
      fromAccountId: linkedAccount?.id,
      toOfficeId: clientOfficeId,
      toClientId: clientId,
      toAccountType: 1,
      toAccountId: loanId,
      dateFormat: "dd MMMM yyyy",
      locale: "en",
      transferDate: formatTransactionDate,
      transferAmount: enteredAmount,
      transferDescription: note,
    };

    if (!note || !formatTransactionDate || !enteredAmount) {
      dispatch(Toast({ text: "All fields are required", icon: "error" }));
      return;
    }

    dispatch(accountTransfer(id, disbursmentData2))
    // paymentType === "linked"
    //   ? dispatch(accountTransfer(id, disbursmentData2))
    //   : dispatch(
    //       handleLoanTransactions(id, disbursementData, "repayment", history)
    //     );
    onHide();
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>Loan Repayments</Modal.Title>
      </Modal.Header>
      <Modal.Body className="wc-modal">
        {modalState === 1 && (
          <div className="d-flex align-items-center justify-content-center flex-direction-coloumn">
            <FormClass style={{ width: "90%" }}>
              <FormElements style={{ width: "100%" }}>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Repayment Type<span style={{ color: "red" }}>*</span></label>
                  <Input
                    disabled
                    style={{ width: "60%" }}
                    name="account"
                    value="Linked savings account (Debit a linked savings account.)"
                    size="md"
                  />
                </div>
                <div className="form-group d-flex flex-column">
                  <div className="d-flex justify-content-between align-items-center w-full">
                    <label className="text-label">Account to debit<span style={{ color: "red" }}>*</span></label>
                    <Input
                      disabled
                      style={{ width: "60%" }}
                      name="account"
                      value={linkedAccount?.accountNo}
                      size="md"
                    />
                  </div>
                  <div
                    className={`d-flex align-self-end mt-2 text-${
                      linkedAccount?.accountBalance >= removeCommas(enteredAmount) ? 'success' : 'danger'
                    }`}
                    style={{
                      width: "60%",
                      fontSize: '11px',
                    }}
                  >
                    Account Balance:{" "}
                    {currency?.code}{" "}
                    {formatAmount(Number(linkedAccount?.accountBalance))}
                    {linkedAccount?.accountBalance < removeCommas(enteredAmount) && (
                      <span>&nbsp;(insufficient)</span>
                    )}
                  </div>
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Transaction Amount<span style={{ color: "red" }}>*</span></label>
                  <CurrencyFormat
                    value={enteredAmount}
                    thousandSeparator={true}
                    className="amount-input "
                    style={{
                      width: "60%",
                    }}
                    onValueChange={(values) => {
                      const { value } = values;
                      setAmount(value);
                    }}
                  />
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Transaction Date<span style={{ color: "red" }}>*</span></label>
                  <FormControl style={{ width: "60%" }}>
                    <div className="datepicker-100-width">
                      <DatePicker
                        id="published-date"
                        selected={transactionDate}
                        onChange={(date) => setTransactionDate(date)}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="dd/mmm/yyyy"
                      />
                    </div>
                  </FormControl>
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Narration<span style={{ color: "red" }}>*</span></label>
                  <Textarea
                    style={{ width: "60%", fontSize: '12px' }}
                    name="note"
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    size="md"
                  />
                </div>
              </FormElements>
            </FormClass>
          </div>
        )}
        {modalState === 2 && (
          <div className="d-flex align-items-center justify-content-center flex-direction-column">
            <FormClass style={{ width: "90%" }}>
              <FormElements style={{ width: "100%" }}>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Repayment Type</label>
                  <p className="popup-details">Linked Savings Account</p>
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Account to debit</label>
                  <p className="popup-details">{linkedAccount?.accountNo}</p>
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Transaction Amount</label>
                  <p className="popup-details">{currency?.code}{" "}{formatAmount(Number(enteredAmount))}</p>
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Transaction Date</label>
                  <p className="popup-details">{formatTransactionDate}</p>
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Narration</label>
                  <p className="popup-details">{note}</p>
                </div>
              </FormElements>
            </FormClass>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-1">
          <ActionButton
            color="#2C1DFF"
            onClick={() => {
              return modalState === 1 ? onHide() : setModalState(1);
            }}
          >
            <img
              src={
                require("../../../../../assets/images/icons/cancel.svg").default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton
            disabled={linkedAccount?.accountBalance < enteredAmount}
            onClick={() => {
              return modalState === 1
                ? setModalState(2)
                : handleLoanRepayment();
            }}
            color={linkedAccount?.accountBalance < enteredAmount ? '#6C757D50' : '#2C1DFF'}
            style={{
              cursor: linkedAccount?.accountBalance < enteredAmount ? 'not-allowed' : 'pointer',
              marginLeft: "10px",
            }}
          >
            <img
              src={
                require("../../../../../assets/images/icons/save.svg").default
              }
              alt="submit icon"
            />
            {modalState === 1 ? "Next" : "Submit"}
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};;

export default LoanRepaymentModal;
