import React, { useState } from "react";
import { ActionButton, FormClass, ModalText } from "../styles";
import { Modal, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  deleteHook,
  getAllHook,
} from "../../../../../actions/configurationActions";

const DeleteHook = ({ onHide, id }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const handleDelete = async () => {
    setLoading(true);
    await dispatch(deleteHook(id));
    dispatch(getAllHook());
    setLoading(false);
    onHide();
  };
  return (
    <>
      <Modal.Header>
        <Modal.Title>Delete Hook</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "95%" }}>
          <ModalText>Are you sure you want to delete this hook?</ModalText>
          <div className="float-right mt-4">
            <ActionButton color="#2C1DFF" onClick={onHide}>
              <img
                src={
                  require("../../../../../assets/images/icons/cancel.svg")
                    .default
                }
                alt="cancel icon"
              />
              Cancel
            </ActionButton>
            <ActionButton
              onClick={handleDelete}
              color="#2C1DFF"
              style={{ margin: "0px 0px 0 10px" }}
            >
              {loading ? (
                <Spinner
                  animation="border"
                  color="#fff"
                  className="mr-2 text-white"
                  size="sm"
                />
              ) : (
                <img
                  src={
                    require("../../../../../assets/images/icons/delete.svg")
                      .default
                  }
                  alt="submit icon"
                />
              )}
              Delete
            </ActionButton>
          </div>
        </FormClass>
      </Modal.Body>
    </>
  );
};

export default DeleteHook;
