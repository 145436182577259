import React, { useState } from "react";
import { Input, Select } from "@chakra-ui/react";
import { ActionButton, FormElements, FormClass, AddActionButton } from "../styles";
import { Modal, Spinner, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import { formatDate } from "../../../../../../../util/FormatDate";
import {
  createNewTaxGroup,
  getAllTaxGroups,
} from "../../../../../../../actions/configurationActions";
import Toast from "../../../../../../../util/Toast";
import { IconButton } from "../../../SavingsProducts/styles";

const defaultComp = { taxComponentId: "", startDate: new Date() };

const CreateTaxGroup = ({ onHide, template = [] }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  //create office states
  const [newTaxGroupInfo, setNewTaxGroupInfo] = useState({
    name: "",
  });
  const { name } = newTaxGroupInfo;

  const [numberOfComp, setNumberOfComp] = useState([]);
  const addToCompArray = () => {
    setNumberOfComp([...numberOfComp, defaultComp]);
  };
  const removeFromCompArray = (position) => {
    setNumberOfComp(numberOfComp.filter((item, index) => index !== position));
  };
  const handleCompIdToChange = (position, value) => {
    let newComp = [...numberOfComp];
    newComp[Number(position)].taxComponentId = value;
    setNumberOfComp(newComp);
  };
  const handleStartDateToChange = (position, value) => {
    let newComp = [...numberOfComp];
    newComp[Number(position)].startDate = value;
    setNumberOfComp(newComp);
  };

  const newTaxGroupHandler = (e) =>
    setNewTaxGroupInfo({
      ...newTaxGroupInfo,
      [e.target.name]: e.target.value,
    });
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (name) {
      setLoading(true);
      const data = {
        name,
        dateFormat: "dd MMMM yyyy",
        locale: "en",
        taxComponents:
          numberOfComp.length > 0
            ? numberOfComp.map(({ taxComponentId, startDate }) => ({
                taxComponentId: Number(taxComponentId),
                startDate: formatDate(startDate, true),
              }))
            : [],
      };
      const func = () => {
        dispatch(getAllTaxGroups());
        onHide();
      };
      await dispatch(createNewTaxGroup(data, func));
      setLoading(false);
    } else {
      dispatch(
        Toast({
          text: "Please fill the required fields",
          icon: "error",
          timer: 2000,
        })
      );
    }
  };
  return (
    <>
      <Modal.Header>
        <Modal.Title>New Tax Group</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <FormElements style={{ width: "100%" }}>
            <div className="form-group d-flex justify-content-between align-items-center" style={{width: "50%"}}>
              <label className="text-label">Name*</label>
              <Input
                style={{ width: "60%" }}
                size="md"
                name="name"
                value={name}
                onChange={newTaxGroupHandler}
              />
            </div>

            <div className="row p-0 m-0">
              <div className="col-sm-12 col-md-10 px-0">
                <Table className="child-table">
                  <thead className="">
                    <tr style={{ backgroundColor: "#FAFAFA" }}>
                      <th>Tax Component</th>
                      <th>Start Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {numberOfComp.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <Select
                            size="md"
                            style={{ width: "100%" }}
                            value={numberOfComp[index].taxComponentId}
                            onChange={(e) =>
                              handleCompIdToChange(index, e.target.value)
                            }
                          >
                            <option value="">Select one</option>
                            {template !== undefined && template
                              ? template.taxComponents &&
                                template.taxComponents.map((cur) => (
                                  <option value={cur.id} key={cur.id}>
                                    {cur.name}
                                  </option>
                                ))
                              : null}
                          </Select>
                        </td>
                        <td>
                          <DatePicker
                            style={{ width: "100%" }}
                            id="opened-date"
                            selected={numberOfComp[index].startDate}
                            onChange={(date) =>
                              handleStartDateToChange(index, date)
                            }
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            placeholderText="dd/mmm/yyyy"
                          />
                        </td>
                        <td>
                          <IconButton
                            onClick={() => removeFromCompArray(index)}
                          >
                            <img
                              src={
                                require("../../../../../../../assets/images/icons/delete.svg")
                                  .default
                              }
                              alt="add icon"
                              className="icon-img"
                            />{" "}
                          </IconButton>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <div className="col-md-2">
                <AddActionButton
                  style={{
                    marginTop: "5px"
                  }}
                  onClick={addToCompArray}
                >
                  <img
                    src={
                      require("../../../../../../../assets/images/icons/add.svg")
                        .default
                    }
                    alt="submit icon"
                  />
                  Add
                </AddActionButton>
              </div>
            </div>
          </FormElements>
        </FormClass>
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-2">
          <ActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require("../../../../../../../assets/images/icons/cancel.svg")
                  .default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton
            onClick={handleSubmit}
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            disabled={loading}
          >
            {loading ? (
              <Spinner
                animation="border"
                color="#fff"
                className="mr-2 text-white"
                size="sm"
              />
            ) : (
              <img
                src={
                  require("../../../../../../../assets/images/icons/save.svg")
                    .default
                }
                alt="submit icon"
              />
            )}
            Submit
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default CreateTaxGroup;
