import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Textarea, FormControl } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { aprroveSavingsAccount } from "../../../../../../actions/SavingsAndLoanActions";

import { FormClass, FormElements, ActionButton } from "../../styles.jsx";
import { formatDate } from "../../../../../../util/FormatDate";
import Toast from "../../../../../../util/Toast";

const ApproveAccount = ({ onHide, id, history }) => {
  const dispatch = useDispatch();

  const [submittedOnDate, setSubmittedOnDate] = useState();
  const [note, setNote] = useState("");

  let newDate = new Date(submittedOnDate);

  const handleApproveAccount = () => {
    if (submittedOnDate) {
      const dataToSubmit = {
        approvedOnDate: formatDate(newDate, true),
        dateFormat: "dd MMM yyyy",
        locale: "en",
        note,
      };
      dispatch(aprroveSavingsAccount(id, dataToSubmit, history, onHide));
    } else {
      dispatch(Toast({ text: "Please fill the required field", icon: "info" }));
    }
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>Approve account</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <FormElements style={{ width: "100%" }}>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Approved on*</label>
              <FormControl style={{ width: "60%" }}>
                <div className="datepicker-100-width">
                  <DatePicker
                    id="published-date"
                    selected={submittedOnDate}
                    onChange={(date) => setSubmittedOnDate(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="dd/mmm/yyyy"
                  />
                </div>
              </FormControl>
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Additional note</label>
              <Textarea
                value={note}
                style={{ fontSize: "12px" }}
                onChange={(e) => setNote(e.target.value)}
                size="md"
                resize={"none"}
              />
            </div>
          </FormElements>
        </FormClass>
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-4">
          <ActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require("../../../../../../assets/images/icons/cancel.svg")
                  .default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={(e) => {
              e.preventDefault();
              handleApproveAccount();
            }}
          >
            <img
              src={
                require("../../../../../../assets/images/icons/save.svg")
                  .default
              }
              alt="submit icon"
            />
            Submit
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default ApproveAccount;
