import styled from "styled-components";
import { devices } from "../../../../components/Devices";

export const ActionButtonsGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

export const ActionButton2 = styled.button`
  background: #2C1DFF !important;
  border-radius: 5px !important;
  color: #fff !important;
  margin: 0 10px;
  border: none;
  display: inline;
  font-size: 14px;
  height: 35px;
  max-width: 180px;
  min-width: 150px;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  img {
    height: 14px;
    display: inline;
    margin-right: 10px;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;

export const ActionButton = styled.button`
  background: ${(props) => props.color} !important;
  border-radius: 5px !important;
  color: ${(props) => (props.textColor ? props.textColor : "#fff")} !important;
  margin: 0 10px;
  border: none;
  font-family: 'AventaMedium';
  display: inline;
  font-size: 14px;
  height: 35px;
  width: 90%;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  position: relative;
  cursor: pointer;
  img {
    height: 10px;
    margin-right: 10px;
    display: inline;
  }
  &:hover {
    box-shadow: ${(props) =>
      props.shadow ? "0px 4px 4px rgba(0, 0, 0, 0.09)" : "none"};
    color: #fff;
  }
  @media ${devices.mobileL} {
    width: 140px;
  }
`;

export const NewActionButton = styled.button`
  background: ${(props) => props.color} !important;
  border-radius: 5px !important;
  color: ${(props) => (props.textColor ? props.textColor : "#fff")} !important;
  margin: 0 10px;
  border: 1px solid #2c1dff;
  display: inline;
  font-size: 14px;
  font-family: "AventaMedium";
  height: 35px;
  width: 90%;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  position: relative;
  cursor: pointer;
  text-align: left;
  .new-design-text {
    padding-left: 10px;
    text-transform: uppercase;
    font-size: 12px;
    font-family: "AventaRegular";
    font-weight: 600;
  }
  img {
    fill: ${(props) => (props.textColor ? props.textColor : "#fff")} !important;
    height: 10px;
    margin-left: 10px !important;
    display: inline;
    // fill: #FFFFFF;
  }
  svg {
    fill: ${(props) => (props.textColor ? props.textColor : "#fff")} !important;
  }
  &:hover {
    box-shadow: ${(props) =>
      props.shadow ? "0px 4px 4px rgba(0, 0, 0, 0.09)" : "none"};
    color: #fff;
  }
  @media ${devices.mobileL} {
    width: 200px;
  }
`;

export const ContentWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 0px #ededf6;
  min-height: 70vh;
  margin-top: 20px;
  padding-bottom: 100px;
  padding-left: 100px;
  padding-right: 100px;
  .family-action-button {
    width: 90%;
    height: 50px;
  }
  @media ${devices.mobileL} {
    padding-bottom: 50px;
  }
  .lineThrough {
    height: 1px;
    border-top: 1px solid #e1e1e4;
    width: 100%;
    margin-bottom: 50px;
  }
`;

export const FormClass = styled.div``;

export const FormElements = styled.div`
  width: 90%;
  margin-top: 30px;
  margin-bottom: 30px;
  display: block;

  label {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    font-family: 'AventaSemiBold';
    color: #969cba;
  }
`;

// export const ActionButton = styled.button`
//   background: ${(props) => props.color} !important;
//   border-radius: 5px !important;
//   color: #fff !important;
//   margin: 0 10px;
//   border: none;
//   display: inline;
//   font-size: 14px;
//   height: 48px;
//   min-width: 160px;
//   transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
//   img {
//     height: 10px;
//     margin-right: 10px;
//     display: inline;
//   }
//   &:hover {
//     box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
//     color: #fff;
//   }
// `;
