import React from "react";
// import { useSelector } from "react-redux";
import { Select, Input } from "@chakra-ui/react";
import CurrencyFormat from "react-currency-format";

import { FormClass, FormElements, NextActionButton } from "../../../styles.jsx";
import { REACT_SELECT_CUSTOM_STYlES } from "../../../../../../../../constants/formConstants.js";
import ReactSelect from "react-select";

const FixedTerms = ({
  handleNextScreen,
  handlePreviousScreen,
  depositAmount,
  setDepositAmount,
  maxDepositAmount,
  setMaxDepositAmount,
  minDepositAmount,
  setMinDepositAmount,
  interestPostingPeriodType,
  interestCompoundingPeriodType,
  interestCalculationType,
  interestCalculationDaysInYearType,
  createFixedDepositProductHandler,
  createFixedDepositProductHandlerForSelect,
  template,
  termsError,
  setTermsError,
  renderTooltip,
}) => {
  //   const productOptionData = useSelector(
  //     (state) => state.loanProductOptions.productData
  //   );
  //   const { productOptions } = productOptionData;

  //   const selectedLoanData = useSelector((state) => state.selectedLoanData);
  //   const { productData } = selectedLoanData;

  //   const { loanOfficerOptions, accountLinkingOptions } = productData;

  return (
    <>
      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Default Deposit Amount*{" "}
                  {renderTooltip("Default Deposit Amount")}
                </label>

                <CurrencyFormat
                  value={depositAmount}
                  name="min_pricipal"
                  thousandSeparator={true}
                  className="amount-input"
                  style={{
                    width: "60%",
                    borderColor:
                      !depositAmount && termsError ? "red" : "inherit",
                  }}
                  onValueChange={(values) => {
                    const { value } = values;
                    setDepositAmount(value);
                  }}
                />
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Maximum Deposit Amount
                  {renderTooltip("Maximum Deposit Amount")}
                </label>
                <CurrencyFormat
                  value={maxDepositAmount}
                  name="maxDepositAmount"
                  thousandSeparator={true}
                  className="amount-input"
                  style={{
                    width: "60%",
                    borderColor:
                      !maxDepositAmount && termsError ? "red" : "inherit",
                  }}
                  onValueChange={(values) => {
                    const { value } = values;
                    setMaxDepositAmount(value);
                  }}
                />
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>

      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Minimum Deposit Amount*
                  {renderTooltip("Minimum Deposit Amount")}
                </label>
                <CurrencyFormat
                  value={minDepositAmount}
                  name="minDepositAmount"
                  thousandSeparator={true}
                  className="amount-input"
                  style={{
                    width: "60%",
                    borderColor:
                      !minDepositAmount && termsError ? "red" : "inherit",
                  }}
                  onValueChange={(values) => {
                    const { value } = values;
                    setMinDepositAmount(value);
                  }}
                />
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-end">
                <label className="text-label d-flex flex-align-center">
                  Interest Posting Period*
                  {renderTooltip("Interest Posting Period")}
                </label>
                <div className="w-100" style={{ maxWidth: '60%' }}>
                  <ReactSelect
                    isClearable={true}
                    className="fs-12"
                    styles={REACT_SELECT_CUSTOM_STYlES}
                    name="interestPostingPeriodType"
                    placeholder="Select interest posting period"
                    onChange={(period, { name }) => createFixedDepositProductHandlerForSelect(name, period?.value)}
                    value={{
                      value: interestPostingPeriodType,
                      label: template
                        ?.interestPostingPeriodTypeOptions
                        ?.find((option) => option?.id === interestPostingPeriodType)
                        ?.value,
                    }}
                    options={template
                      ?.interestPostingPeriodTypeOptions
                      ?.map((item) => ({
                        label: item.value,
                        value: item.id,
                      }))
                    }
                  />
                </div>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>

      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Interest Compounding Period*
                  {renderTooltip("Interest Compounding Period")}
                </label>
                <div className="w-100" style={{ maxWidth: '60%' }}>
                  <ReactSelect
                    isClearable={true}
                    className="fs-12"
                    styles={REACT_SELECT_CUSTOM_STYlES}
                    name="interestCompoundingPeriodType"
                    placeholder="Select interest compounding period"
                    onChange={(period, { name }) => createFixedDepositProductHandlerForSelect(name, period?.value)}
                    value={{
                      value: interestCompoundingPeriodType,
                      label: template
                        ?.interestCompoundingPeriodTypeOptions
                        ?.find((option) => option?.id === interestCompoundingPeriodType)
                        ?.value,
                    }}
                    options={template
                      ?.interestCompoundingPeriodTypeOptions
                      ?.map((item) => ({
                        label: item.value,
                        value: item.id,
                      }))
                    }
                  />
                </div>
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-end">
                <label className="text-label d-flex flex-align-center">
                  Days In The Year*
                  {renderTooltip("Days In The Year")}
                </label>
                <div className="w-100" style={{ maxWidth: '60%' }}>
                  <ReactSelect
                    isClearable={true}
                    className="fs-12"
                    styles={REACT_SELECT_CUSTOM_STYlES}
                    name="interestCalculationDaysInYearType"
                    placeholder="Select days in year"
                    onChange={(period, { name }) => createFixedDepositProductHandlerForSelect(name, period?.value)}
                    value={{
                      value: interestCalculationDaysInYearType,
                      label: template
                        ?.interestCalculationDaysInYearTypeOptions
                        ?.find((option) => option?.id === interestCalculationDaysInYearType)
                        ?.value,
                    }}
                    options={template
                      ?.interestCalculationDaysInYearTypeOptions
                      ?.map((item) => ({
                        label: item.value,
                        value: item.id,
                      }))
                    }
                  />
                </div>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>

      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Interest Calculated Using*
                  {renderTooltip("Interest Calculated Using")}
                </label>
                <div className="w-100" style={{ maxWidth: '60%' }}>
                  <ReactSelect
                    isClearable={true}
                    className="fs-12"
                    styles={REACT_SELECT_CUSTOM_STYlES}
                    name="interestCalculationType"
                    placeholder="Select interest calculation type"
                    onChange={(period, { name }) => createFixedDepositProductHandlerForSelect(name, period?.value)}
                    value={{
                      value: interestCalculationType,
                      label: template
                        ?.interestCalculationTypeOptions
                        ?.find((option) => option?.id === interestCalculationType)
                        ?.value,
                    }}
                    options={template
                      ?.interestCalculationTypeOptions
                      ?.map((item) => ({
                        label: item.value,
                        value: item.id,
                      }))
                    }
                  />
                </div>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>

      <div>
        <div className="float-right pt-5">
          <NextActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={handlePreviousScreen}
          >
            <img
              src={
                require("../../../../../../../../assets/images/icons/arrow-left.svg")
                  .default
              }
              alt="cancel icon"
            />
            Previous
          </NextActionButton>
          <NextActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={() => {
              !depositAmount ||
              !maxDepositAmount ||
              !minDepositAmount ||
              !interestPostingPeriodType ||
              !interestCompoundingPeriodType ||
              !interestCalculationDaysInYearType ||
              !interestCalculationType
                ? setTermsError(true)
                : setTermsError(false);

              handleNextScreen();
            }}
          >
            <img
              src={
                require("../../../../../../../../assets/images/icons/arrow-right.svg")
                  .default
              }
              alt="submit icon"
            />{" "}
            Next
          </NextActionButton>
        </div>
      </div>
    </>
  );
};

export default FixedTerms;
