import React, { useState } from "react";
import { Checkbox } from "@chakra-ui/react";
import { ActionButton, FormElements, FormClass } from "../styles";
import { Modal, Spinner } from "react-bootstrap";

const CreditRuleModal = ({ onHide, allData, setSelectedCreditRole, selectedCreditRole }) => {
  const [loading, setLoading] = useState(false);

  const [selectedCreditData, setSelectedCreditData] = useState([...selectedCreditRole]);


  const addedToList = () => {
    const allRules = [...selectedCreditData];
    setSelectedCreditRole(allRules);
    onHide();
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>Credit Tags</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflow: "scroll", maxHeight: "450px" }}>
        <FormClass style={{ width: "80%" }}>
          <FormElements style={{ width: "100%" }}>
            {allData
              ? allData.map((off) => (
                  <li value={off.id} key={off.id} className="rule-list">
                    <div className="d-flex">
                      <Checkbox
                      defaultChecked={selectedCreditRole.some((val) => val.id === off.id) ? true : false}
                        size="lg"
                        onChange={(e) =>
                          selectedCreditData.some((val) => val.id === off.id)
                            ? setSelectedCreditData(
                                selectedCreditData.filter(
                                  (val) => val.id !== off.id
                                )
                              )
                            : setSelectedCreditData((prev) => [...prev, off])
                        }
                      />
                      <span className="ml-3">{off.name}</span>
                    </div>
                  </li>
                ))
              : ""}
          </FormElements>
        </FormClass>
      </Modal.Body>
      <Modal.Footer>
        {selectedCreditData?.length > 0 ? (
          <div className="float-left selected-tags">
            {selectedCreditData?.length > 0
              ? `${selectedCreditData.length} tags selected`
              : ""}
            {selectedCreditData?.length > 0 ? (
              <img
                src={
                  require("../../../../../assets/images/icons/cancel-tags.svg")
                    .default
                }
                alt="cancel icon"
                className="ml-3"
              />
            ) : null}
          </div>
        ) : null}
        <div className="float-right mt-1">
          <ActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require("../../../../../assets/images/icons/cancel.svg").default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton onClick={addedToList} color="#2C1DFF" style={{ margin: "0px 0px 0 10px" }}>
            {loading ? (
              <Spinner
                animation="border"
                color="#fff"
                className="mr-2 text-white"
                size="sm"
              />
            ) : (
              <img
                src={
                  require("../../../../../assets/images/icons/save.svg").default
                }
                alt="submit icon"
              />
            )}
            Done
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default CreditRuleModal;
