import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ContentWrapper, ActionButton } from "./styles";
import { formatCurrency } from "../../../../util/FormatAmount";
import { formatDate } from "../../../../util/FormatDate";
import ModalContainer from "../../components/ModalContainer";
import AmortizationDetailsModal from "./AmortizationDetailsModal";

import { getAllAmortization } from "../../../../actions/accountingActions";
import { getGlAccounts } from "../../../../actions/utilityActions";

const Amortizations = ({ history }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllAmortization());
    dispatch(getGlAccounts());
  }, [dispatch]);

  const [amortizationDetails, setAmortizationDetails] = useState({});
  const [modalState, toggleModal] = useState(false);

  const accountingData = useSelector((state) => state.accountingData);

  let { amortizations = [] } = accountingData || {};

  const glAccounts = useSelector((state) => state.glAccounts);

  let { glAcccounts = [] } = glAccounts || {};

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    { title: "Number of days", dataIndex: "noOfDays", key: "noOfDays" },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
    },
    {
      title: "Start date",
      dataIndex: "startDate",
      key: "startDate",
    },
    {
      title: "End date",
      dataIndex: "endDate",
      key: "endDate",
    },
  ];
  let amortizationData = [];

  amortizations.forEach((amor) => {
    const { amount, currency_code, start_date, end_date, active, tenor, name } =
      amor;

    amortizationData.push({
      amount: `${currency_code} ${formatCurrency(Number(amount))}`,
      noOfDays: tenor,
      active: active === 1 ? "active" : "inactive",
      startDate: start_date && formatDate(start_date),
      endDate: end_date && formatDate(end_date),
      amor,
      name,
    });
  });

  const handleOnHide = () => {
    toggleModal(false);
  };

  return (
    <>
      <ModalContainer show={modalState}>
        {modalState && (
          <AmortizationDetailsModal
            onHide={handleOnHide}
            amortizationDetails={amortizationDetails}
            glAcccounts={glAcccounts}
          />
        )}
      </ModalContainer>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12  col-md-12 col-xl-6 pb-3">
            <h3 className="page-title">Amortizations</h3>
            <p className="sub-title">Manage amortization postings</p>
            <p className="sub-title mt-2">
              {/* <span className="text-info mr-1">
                <Link to="/core/accounting">Accounting</Link>
              </span> */}
              {/* Amortizations */}
            </p>
          </div>
          <div className="col-sm-12 col-md-12 col-xl-6">
            <div className="float-right">
              <Link to="/core/amortizations/create">
                <ActionButton color="#2C1DFF" className="">
                  <img
                    src={
                      require("../../../../assets/images/icons/add.svg").default
                    }
                    className="d-inline mr-3"
                    alt="icon"
                  />
                  Create Amortization
                </ActionButton>
              </Link>
            </div>
          </div>
        </div>
        <ContentWrapper>
          <Table
            columns={columns}
            dataSource={amortizationData}
            pagination={{ defaultPageSize: 8 }}
            defaultPageSize={8}
            onRow={(r) => ({
              onClick: () => {
                setAmortizationDetails(r);
                toggleModal(true);
              },
            })}
          />
        </ContentWrapper>
      </div>
    </>
  );
};

export default Amortizations;
