import React, { useState, useEffect } from "react";
import Button from "../../../../components/Button/index";
import { SlideFade } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { formatDateString, formatTime } from "../../../../util/FormatDate";
import DatePicker from "react-datepicker";
import CurrencyFormat from "react-currency-format";
import Toast from "../../../../util/Toast";
import { TimePicker } from "antd";
import { Select, Textarea, Checkbox, Divider } from "@chakra-ui/react";
import {
  createTellerCashieraction,
  getAllOffices,
  getCashierTemplate,
  getSingleTellerGroupCashier,
} from "../../../../actions/tellerActions";
import DropdownMenu from "../TellerGroup/DropdownMenu";

const AddTellerForm = ({ addTeller, closeAddTellerForm }) => {
  const { id } = useParams();
  const [staffId, setStaffId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [endDate, setEndDate] = useState("");
  const [debitAccountId, setDebit] = useState("");
  const [creditAccountId, setCredit] = useState("");
  const [isFullDay, setFullDay] = useState(false);
  const [description, setDescription] = useState("");
  const [dailyTellerLimit, setLimit] = useState("");
  const hourStartTime = !!startTime ? startTime.slice(0, 2) : "";
  const minStartTime = !!startTime ? startTime.slice(-2) : "";
  const hourEndTime = !!endTime ? endTime.slice(0, 2) : "";
  const minEndTime = !!endTime ? endTime.slice(-2) : "";


  const dispatch = useDispatch();


  const tellerGroupReducer = useSelector((state) => state.tellerReducer);
  const { cashierTemplate } = tellerGroupReducer;


  useEffect(() => {
    dispatch(getAllOffices());
    dispatch(getCashierTemplate(id));
  }, [dispatch, id]);

  const data2 =
    isFullDay === true
      ? ""
      : {
          hourStartTime: hourStartTime,
          hourEndTime: hourEndTime,
          minStartTime: minStartTime,
          minEndTime: minEndTime,
        };
  const submit = (e) => {
    if (!staffId || !debitAccountId || !creditAccountId || !startDate) {
      dispatch(Toast({ text: "Required fields can't be empty", icon: "info" }));
    } else {
      const dataToSend = {
        locale: "en",
        staffId: staffId,
        dailyLimit: dailyTellerLimit,
        debitAccountId: debitAccountId,
        creditAccountId: creditAccountId,
        description: description,
        dateFormat: "dd/MMM/yyyy",
        startDate: formatDateString(startDate, "dd/MMM/yyyy"),
        endDate: formatDateString(endDate, "dd/MMM/yyyy"),
        isFullDay: isFullDay,
        ...data2,
      };
      const func = () => {
        dispatch(getSingleTellerGroupCashier(id));
      };
      dispatch(createTellerCashieraction(dataToSend, id, func));
      closeAddTellerForm();
    }
  };

  return (
    <SlideFade offsetX="100px" className="add-teller" in={addTeller}>
      <div className="d-flex  px-3 pt-2 justify-content-between mb-2">
        <H3 style={{ fontFamily: "AventaSemiBold", fontSize: "15px" }}>
          Add Teller
        </H3>
      </div>
      <Divider />
      <div className="px-4 pt-3" onSubmit={submit}>
        <GridContainer className="d-flex justify-content-between">
          <label htmlFor="Staff" className="text-label">
            Staff*
          </label>
          <div className="select-100-width">
            <Select
              isFullWidth="true"
              name="staffId"
              placeholder="Select"
              value={staffId}
              onChange={(e) => setStaffId(e.target.value)}
            >
              {!!cashierTemplate?.staffOptions?.length > 0
                ? cashierTemplate?.staffOptions?.map((item) => {
                    return (
                      <option key={item.id} value={item?.id}>
                        {item?.displayName}
                      </option>
                    );
                  })
                : ""}
            </Select>
          </div>
        </GridContainer>
        <GridContainer className="d-flex justify-content-between">
          <label htmlFor="Description" className="text-label">
            Description
          </label>
          <div className="textarea-100-width">
            <Textarea
              name="description"
              onChange={(e) => setDescription(e.target.value)}
              id=""
              size="sm"
              rows="2"
            ></Textarea>
          </div>
        </GridContainer>
        <GridContainer className="d-flex justify-content-between">
          <label htmlFor="Daily Teller Limit" className="text-label">
            Daily Teller Limit*
          </label>
          <CurrencyFormat
            required
            value={dailyTellerLimit}
            thousandSeparator={true}
            className="amount-input"
            style={{
              width: "100%",
            }}
            onValueChange={(values) => {
              const { value } = values;
              setLimit(value);
            }}
          />
        </GridContainer>
        <GridContainer className="d-flex justify-content-between">
          <label htmlFor="Daily Teller Limit" className="text-label">
            Debit Account GL*
          </label>
          <div className="select-100-width">
            <Select
              placeholder="Select Debit account"
              isFullWidth="true"
              name="debitAccount"
              value={debitAccountId}
              onChange={(e) => setDebit(e.target.value)}
            >
              {!!cashierTemplate?.glAccountOptions?.length > 0
                ? cashierTemplate?.glAccountOptions?.map((item) => {
                    return (
                      <option key={item?.id} value={item?.id}>
                        {item?.name}
                      </option>
                    );
                  })
                : ""}
            </Select>
          </div>
        </GridContainer>
        <GridContainer className="d-flex justify-content-between">
          <label htmlFor="Daily Teller Limit" className="text-label">
            Credit Account GL*
          </label>
          <div className="select-100-width">
            <Select
              isFullWidth="true"
              placeholder="Select Credit account"
              name="debitAccount"
              value={creditAccountId}
              onChange={(e) => setCredit(e.target.value)}
            >
              {!!cashierTemplate?.glAccountOptions?.length > 0
                ? cashierTemplate?.glAccountOptions?.map((item) => {
                    return (
                      <option key={item.id} value={item?.id}>
                        {item?.name}
                      </option>
                    );
                  })
                : ""}
            </Select>
          </div>
        </GridContainer>
        <GridContainer className="d-flex">
          <label htmlFor="Daily Teller Limit" className="text-label">
            Start Date*
          </label>
          <div className="datepicker-100-width">
            <DatePicker
              id="published-date"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText="dd/mmm/yyyy"
            />
          </div>
        </GridContainer>
        <GridContainer className="d-flex">
          <label htmlFor="Daily Teller Limit" className="text-label">
            End Date
          </label>
          <div className="datepicker-100-width">
            <DatePicker
              id="published-date"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText="dd/mmm/yyyy"
            />
          </div>
        </GridContainer>
        <GridContainer className="">
          <label htmlFor="Is full day" className="text-label">
            Is full day
          </label>
          <Checkbox
            checked={isFullDay}
            onChange={(e) => setFullDay(e.target.checked)}
            colorScheme="blue"
          />
        </GridContainer>
        {isFullDay === true ? (
          ""
        ) : (
          <>
            <GridContainer className="d-flex">
              <label htmlFor="Start time" className="text-label">
                Start time*
              </label>
              <div className="timepicker-100-width">
                <TimePicker
                  onChange={(timeString) => {
                    const timeHr = formatTime(timeString?._d);
                    setStartTime(timeHr);
                  }}
                />
              </div>
            </GridContainer>
            <GridContainer className="d-flex">
              <label htmlFor="Daily Teller Limit" className="text-label">
                End time*
              </label>
              <div className="timepicker-100-width">
                <TimePicker
                  onChange={(timeString) => {
                    const timeHr = formatTime(timeString?._d);
                    setEndTime(timeHr);
                  }}
                />
              </div>
            </GridContainer>
          </>
        )}

        <div className="d-flex justify-content-end mb-3 mt-5">
          <Button type="submit" onClick={submit}>
            Add Teller
          </Button>
        </div>
      </div>
    </SlideFade>
  );
};

export default AddTellerForm;

const GridContainer = styled.div`
  display: grid !important;
  grid-template-columns: 2fr 3fr;
  align-items: center;
  margin-bottom: 15px;
  color: #969cba;
  max-width: 500px;

  label {
    font-size: 18px;
    color: #969cba;
  }
  /* input,
  select,
  textarea {
    width: 100%;
  } */
`;

const H3 = styled.h3`
  font-size: 20px;
`;
