import React, {useState} from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { Textarea, Input } from "@chakra-ui/react";
import { undoApprovedSavingAccount } from "../../../../../../actions/SavingsAndLoanActions";
import {
    getCustomerAccounts,
  } from "../../../../../../actions/customerActions.js";

import {
    FormClass,
    FormElements,
    ActionButton
} from "../../styles.jsx";

const UndoApproval = ({ userId, onHide, history,  id}) => {
    const dispatch = useDispatch();

  const [note, setNote] = useState("");

  const handleUndoApprove = async () => {
    let dataToSend = {
      note,
    };

    await dispatch(undoApprovedSavingAccount(id, dataToSend, history));
    await dispatch(getCustomerAccounts(userId));

    onHide();

};

    return (
        <>
            <Modal.Header>
                <Modal.Title>Undo approval on account</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
                <FormClass style={{width: '90%'}}>
                    <FormElements style={{width: '100%'}}>
                    <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label">Note</label>
                            <Input 
                            style={{ width: "60%" }}
                            name="note"
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                            size="md"
                             />
                    </div>
                    </FormElements>
                    <div className="float-right mt-4" >
                        <ActionButton color="#2C1DFF" onClick={onHide}>
                        <img
                            src={
                            require("../../../../../../assets/images/icons/cancel.svg")
                                .default
                            }
                            alt="cancel icon"
                        />
                        Cancel
                        </ActionButton>
                        <ActionButton 
                        color="#2C1DFF" 
                        onClick={handleUndoApprove}
                        style={{margin: '0px 0px 0 10px'}}
                        >
                        <img
                            src={
                            require("../../../../../../assets/images/icons/save.svg")
                                .default
                            }
                            alt="submit icon"
                        />
                        Submit
                        </ActionButton>
                    </div>
                </FormClass>
   
            </Modal.Body>
        </>
    )
}

export default UndoApproval
