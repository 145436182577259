import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notification, Button } from "antd";
import { SET_GENERATING_REPORTS } from "../../constants/reportsConstants";
import { getAReportStatus } from "../../services/Api/report.api.services";

const ReportNotification = ({ history }) => {
  const dispatch = useDispatch();

  const reportData = useSelector((state) => state.reportData);

  let { generatingReports = [] } = reportData || {};
  const btn = (file) => {
    return (
      <>
        {/* <Button
          type="primary"
          size="small"
          onClick={(e) => {
            history.push("/core/report/generated-reports");
          }}
        >
          Report Details
        </Button> */}
        <a href={file} rel="noreferrer" target="_blank" download>
          <Button type="primary" size="small" className="ml-2">
            Download
          </Button>
        </a>
      </>
    );
  };
  const openNotification = (name, file) => {
    notification.info({
      message: "Report Notification",
      description: `Your ${name} report is ready`,
      placement: "bottomRight",
      btn: btn(file),
      duration: 3,
    });
  };

  useEffect(() => {
    if (!!generatingReports && !!generatingReports.length) {
      setInterval(function () {
        generatingReports.forEach((report, index) => {
          let { code, status, reportName } = report;
          if (!!code && status === "pending") {
            getAReportStatus(code)
              .then((response) => {
                if (response.status === "expired") {
                  let hhh = [...generatingReports];
                  hhh[Number(index)].status = "Expired";
                  hhh[Number(index)].expiresIn = Date.now() + 1000 * 60 * 5;
                  dispatch({ type: SET_GENERATING_REPORTS, payload: hhh });
                }
                if (!!response && !!response.file) {
                  let hhh = [...generatingReports];
                  hhh[Number(index)].status = "Completed";
                  hhh[Number(index)].file = response.file;
                  hhh[Number(index)].expiresIn = Date.now() + 1000 * 60 * 5;
                  dispatch({ type: SET_GENERATING_REPORTS, payload: hhh });
                  // openNotification(reportName, response.file);
                }
              })
              .catch((ex) => {
                if (ex) {
                  let hhh = [...generatingReports];
                  hhh[Number(index)].status = "Failed";
                  hhh[Number(index)].expiresIn = Date.now() + 1000 * 60 * 0.1;
                  dispatch({ type: SET_GENERATING_REPORTS, payload: hhh });
                }
              });
          }
        });
      }, 1000 * 1 * 1);
    }
  }, [generatingReports]);

  return (
    <>
      <div className=""></div>
    </>
  );
};

export default ReportNotification;
