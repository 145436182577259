import styled from "styled-components";

export const ActionButtonsGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

export const ActionButton = styled.button`
  background: #2C1DFF !important;
  border-radius: 5px !important;
  color: #fff !important;
  border: none;
  display: inline;
  font-family: 'AventaMedium';
  font-size: 14px;
  height: 35px;
  max-width: 180px;
  min-width: 150px;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  img {
    height: 14px;
    display: inline;
    margin-right: 10px;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;

export const NewActionButton = styled.button`
  background: #2C1DFF !important;
  border-radius: 5px !important;
  color: #fff !important;
  border: none;
  display: inline;
  font-size: 14px;
  font-family: 'AventaMedium';
  height: 35px;
  max-width: 180px;
  min-width: 120px;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  img {
    height: 14px;
    display: inline;
    margin-right: 10px;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;

export const ModalText = styled.p`
  font-size: 15px;
  color: #000000;
  margin: 20px 0 20px 0;
`;

export const ContentWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 0px #ededf6;
  min-height: 70vh;
  margin-top: 20px;
  padding-top: 50px;
  .currency-container {
    margin-bottom: 30px;
    display: flex;
    &:nth-child(even) {
      margin-left: 8px;
    }
    &:nth-child(odd) {
      margin-right: 8px;
    }
  }
  input {
    &:disabled {
      color: #666666 !important;
      opacity: 1;
    }
  }
`;

export const FormClass = styled.div``;

export const FormElements = styled.div`
  width: 90%;
  margin-top: 15px;
  margin-bottom: 15px;
  display: block;

  label {
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 22px;
    color: #969cba;
  }
`;

// export const Title = styled.span`
//   width: 100%;
//   display: block;
//
//   font-weight: 400;
//   font-size: 1.125rem;
//   min-height: 50px;
//   color: #666666;
//   background: #ffffff;
//   border: 1px solid #dadce6;
//   box-sizing: border-box;
//   border-radius: 5px;
//   padding: 11px 20px;
// `;
