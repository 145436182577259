import {
  NIBSS_TENANTS_REQUEST,
  NIBSS_TENANTS_SUCCESS,
  NIBSS_TENANTS_FAIL,
  NIBSS_TENANTS_WALLET_REQUEST,
  NIBSS_TENANTS_WALLET_SUCCESS,
  NIBSS_TENANTS_WALLET_FAIL,
  NIBSS_TENANTS_ACCOUNT_BALANCE_REQUEST,
  NIBSS_TENANTS_ACCOUNT_BALANCE_SUCCESS,
  NIBSS_TENANTS_ACCOUNT_BALANCE_FAIL,
  NIBSS_INFLOW_DATA_REQUEST,
  NIBSS_INFLOW_DATA_SUCCESS,
  NIBSS_INFLOW_DATA_FAIL,
  NIBSS_OUTFLOW_DATA_REQUEST,
  NIBSS_OUTFLOW_DATA_SUCCESS,
  NIBSS_OUTFLOW_DATA_FAIL,
  NIBSS_SINGLE_INFLOW_DATA_REQUEST,
  NIBSS_SINGLE_INFLOW_DATA_SUCCESS,
  NIBSS_SINGLE_INFLOW_DATA_FAIL,
  NIBSS_SINGLE_OUTFLOW_DATA_REQUEST,
  NIBSS_SINGLE_OUTFLOW_DATA_SUCCESS,
  NIBSS_SINGLE_OUTFLOW_DATA_FAIL,
  NIBSS_GET_BANK_REQUEST,
  NIBSS_GET_BANK_SUCCESS,
  NIBSS_GET_BANK_FAIL,
  NIBSS_NAME_ENQUIRY_REQUEST,
  NIBSS_NAME_ENQUIRY_SUCCESS,
  NIBSS_NAME_ENQUIRY_FAIL,
  NIBSS_AUTHENTICATE_OTP_REQUEST,
  NIBSS_AUTHENTICATE_OTP_SUCCESS,
  NIBSS_AUTHENTICATE_OTP_FAIL,
  NIBSS_FUND_TRANSFER_REQUEST,
  NIBSS_FUND_TRANSFER_SUCCESS,
  NIBSS_FUND_TRANSFER_FAIL,
  NIBSS_INFLOW_DATA_RESET,
  NIBSS_OUTFLOW_DATA_RESET,
  NIBSS_TENANTS_RESET,
  NIBSS_TENANTS_WALLET_RESET,
  NIBSS_TENANTS_ACCOUNT_BALANCE_RESET,
  NIBSS_SINGLE_INFLOW_DATA_RESET,
  NIBSS_SINGLE_OUTFLOW_DATA_RESET,
  NIBSS_GET_BANK_RESET,
  NIBSS_AUTHENTICATE_OTP_RESET,
  NIBSS_FUND_TRANSFER_RESET,
  NIBSS_NAME_ENQUIRY_RESET,
  NIBSS_GET_ALL_REPORT_REQUEST,
  NIBSS_GET_ALL_REPORT_SUCCESS,
  NIBSS_GET_ALL_REPORT_FAIL,
  NIBSS_GET_ALL_REPORT_RESET,
  NIBSS_ALL_REPORT_REQUEST,
  NIBSS_ALL_REPORT_SUCCESS,
  NIBSS_ALL_REPORT_FAIL,
  NIBSS_ALL_REPORT_RESET,
  NIBSS_FILTER_REPORT_REQUEST,
  NIBSS_FILTER_REPORT_SUCCESS,
  NIBSS_FILTER_REPORT_FAIL,
  NIBSS_FILTER_REPORT_RESET,
  NIBSS_OUTFLOW_DATA_REQUEST_FILTER,
  NIBSS_OUTFLOW_DATA_SUCCESS_FILTER,
  NIBSS_OUTFLOW_DATA_FAIL_FILTER,
  NIBSS_OUTFLOW_DATA_RESET_FILTER,
  NIBSS_INFLOW_DATA_REQUEST_FILTER,
  NIBSS_INFLOW_DATA_SUCCESS_FILTER,
  NIBSS_INFLOW_DATA_FAIL_FILTER,
  NIBSS_INFLOW_DATA_RESET_FILTER,
  NIBSS_INFLOW_DATA_REQUEST_FILTER_DOWNLOAD,
  NIBSS_INFLOW_DATA_SUCCESS_FILTER_DOWNLOAD,
  NIBSS_INFLOW_DATA_FAIL_FILTER_DOWNLOAD,
  NIBSS_INFLOW_DATA_RESET_FILTER_DOWNLOAD,
} from "../constants/nibssConnectConstants";

import {
  SET_LOADING_STATE,
  SET_TABLE_LOADING_STATE,
} from "../constants/utilityConstants";

import {
  getAccountBalance,
  getBanksNibss,
  getInflowData,
  getNibssTenants,
  getNibssTenantsWallets,
  getOneInflowData,
  getOneOutflowData,
  getOutflowData,
  performNameEnquiry,
  // authenticateNibssOtpCode,
  authenticateNibssPinCode,
  TransferFunds,
  getNibssReport,
  getFilteredNibssReport,
  getOutflowDataFilter,
  getInflowDataFilter,
  getInflowDataFilterDownload,
} from "../services/Api/nibss.api.services";
import Toast from "../util/Toast";

const getNibssConnectTenants = (tenantIdentifier2) => {
  return async (dispatch) => {
    dispatch({ type: NIBSS_TENANTS_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getNibssTenants(tenantIdentifier2)
      .then((response) => {
        dispatch({
          type: NIBSS_TENANTS_SUCCESS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.message,
              icon: "error",
            })
          );
        dispatch({ type: SET_LOADING_STATE, payload: false });
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(nibssConnectTenantRequestFail(ex));
      });
  };
};

const getNibssConnectTenantsWallets = (tenantIdentifier) => {
  return async (dispatch) => {
    dispatch({ type: NIBSS_TENANTS_WALLET_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getNibssTenantsWallets(tenantIdentifier)
      .then((response) => {
        dispatch({
          type: NIBSS_TENANTS_WALLET_SUCCESS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.message,
              icon: "error",
            })
          );
        dispatch({ type: SET_LOADING_STATE, payload: false });
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(nibssConnectTenantWalletRequestFail(ex));
      });
  };
};

const getNibssTenantsAccountBalance = (walletNumberId) => {
  return async (dispatch) => {
    dispatch({ type: NIBSS_TENANTS_ACCOUNT_BALANCE_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getAccountBalance(walletNumberId)
      .then((response) => {
        dispatch({
          type: NIBSS_TENANTS_ACCOUNT_BALANCE_SUCCESS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.message,
              icon: "error",
            })
          );
        dispatch({ type: SET_LOADING_STATE, payload: false });
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(nibssTenantBalanceRequestFail(ex));
      });
  };
};

const getNibssInflowData = (params, tenantCode ) => {
  return async (dispatch) => {
    dispatch({ type: NIBSS_INFLOW_DATA_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getInflowData( params, tenantCode)
      .then((response) => {
        dispatch({
          type: NIBSS_INFLOW_DATA_SUCCESS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.message,
              icon: "error",
            })
          );
          dispatch({ type: SET_LOADING_STATE, payload: false });

        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(nibssInflowDataRequestFail(ex));
      });
  };
};

const getNibssOutflowData = (params , tenantCode) => {
  return async (dispatch) => {
    dispatch({ type: NIBSS_OUTFLOW_DATA_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getOutflowData(params , tenantCode)
      .then((response) => {
        dispatch({
          type: NIBSS_OUTFLOW_DATA_SUCCESS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        if (ex.response) {
            dispatch(
              Toast({
                text: ex.response.data.message,
                icon: "error",
              })
            );
          dispatch({ type: SET_LOADING_STATE, payload: false });
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(nibssOutflowDataRequestFail(ex));
      });
  };
};

const getNibssOutflowDataFilter = (params,tenantCode,startDate, endDate,onHide ) => {
  return async (dispatch) => {
    dispatch({ type: NIBSS_OUTFLOW_DATA_REQUEST_FILTER });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getOutflowDataFilter(params,tenantCode,startDate, endDate )
      .then((response) => {
        dispatch({
          type: NIBSS_OUTFLOW_DATA_SUCCESS_FILTER,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
        onHide();
      })
      .catch((ex) => {
        if (ex.response) {
            dispatch(
              Toast({
                text: ex.response.data.message,
                icon: "error",
              })
            );
        onHide();
        dispatch({ type: SET_LOADING_STATE, payload: false });

        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(nibssOutflowDataRequestFailFilter(ex));
      });
  };
};

const getNibssInflowDataFilter = (params,tenantCode,startDate, endDate, handleInflowModalClose ) => {
  return async (dispatch) => {
    dispatch({ type: NIBSS_INFLOW_DATA_REQUEST_FILTER });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getInflowDataFilter(params,tenantCode,startDate, endDate)
      .then((response) => {
        dispatch({
          type: NIBSS_INFLOW_DATA_SUCCESS_FILTER,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
        handleInflowModalClose();
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.message,
              icon: "error",
            })
          );
          handleInflowModalClose()
          dispatch({ type: SET_LOADING_STATE, payload: false });

        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(nibssInflowDataRequestFailFilter(ex));
      });
  };
};

const getNibssInflowDataFilterDownload = (params ,tenantCode, startDate, endDate,handleInflowModalClose) => {
  return async (dispatch) => {
    dispatch({ type: NIBSS_INFLOW_DATA_REQUEST_FILTER_DOWNLOAD });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getInflowDataFilterDownload(params, tenantCode, startDate, endDate)
      .then((response) => {
        dispatch({
          type: NIBSS_INFLOW_DATA_SUCCESS_FILTER_DOWNLOAD,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
        handleInflowModalClose();
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.message,
              icon: "error",
            })
          );
          handleInflowModalClose()
          dispatch({ type: SET_LOADING_STATE, payload: false });

        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(nibssInflowDataRequestFailFilterDownload(ex));
      });
  };
};

const getSingleInflowDetails = (id) => {
  return async (dispatch) => {
    dispatch({ type: NIBSS_SINGLE_INFLOW_DATA_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getOneInflowData(id)
      .then((response) => {
        dispatch({
          type: NIBSS_SINGLE_INFLOW_DATA_SUCCESS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.message,
              icon: "error",
            })
          );
        dispatch({ type: SET_LOADING_STATE, payload: false });

        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(nibssSingleInflowDataRequestFail(ex));
      });
  };
};

const getSingleOutflowDetails = (id) => {
  return async (dispatch) => {
    dispatch({ type: NIBSS_SINGLE_OUTFLOW_DATA_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getOneOutflowData(id)
      .then((response) => {
        dispatch({
          type: NIBSS_SINGLE_OUTFLOW_DATA_SUCCESS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.message,
              icon: "error",
            })
          );
        dispatch({ type: SET_LOADING_STATE, payload: false });

        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(nibssSingleOutflowDataRequestFail(ex));
      });
  };
};

const getBanks = () => {
  return async (dispatch) => {
    dispatch({ type: NIBSS_GET_BANK_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getBanksNibss()
      .then((response) => {
        dispatch({
          type: NIBSS_GET_BANK_SUCCESS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.message,
              icon: "error",
            })
          );
          dispatch({ type: SET_LOADING_STATE, payload: true });
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(getBanksFail(ex));
      });
  };
};

const getNameEnquiry = (
  // tenantInstitutionCode,
  beneficiaryAccountNumber,
  beneficiaryInstitutionCode
) => {
  return async (dispatch) => {
    dispatch({ type: NIBSS_NAME_ENQUIRY_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await performNameEnquiry(
      // tenantInstitutionCode,
      beneficiaryAccountNumber,
      beneficiaryInstitutionCode
    )
      .then((response) => {
        dispatch({
          type: NIBSS_NAME_ENQUIRY_SUCCESS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.message,
              icon: "error",
            })
          );
        dispatch({ type: SET_LOADING_STATE, payload: false });

        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(getNameEnquiryFail(ex));
      });
  };
};

const authenticateNibssCode = (data) => {
  return async (dispatch) => {
    dispatch({ type: NIBSS_AUTHENTICATE_OTP_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await authenticateNibssPinCode(data)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({
          type: NIBSS_AUTHENTICATE_OTP_SUCCESS,
          payload: response,
        });
      })
      .catch((ex) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.message,
              icon: "error",
            })
          );
        dispatch({ type: SET_LOADING_STATE, payload: false });

        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(getNibssCodeFail(ex));
      });
  };
};

const TransferNibssFunds = (userData) => {
  return async (dispatch) => {
    dispatch({ type: NIBSS_FUND_TRANSFER_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await TransferFunds(userData)
      .then((response) => {
        dispatch({
          type: NIBSS_FUND_TRANSFER_SUCCESS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.message,
              icon: "error",
            })
          );
        dispatch({ type: SET_LOADING_STATE, payload: false });

        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(TransferFundsFail(ex));
      });
  };
};

const getNameEnquiryReset = () => {
  return (dispatch) => {
    dispatch({
      type: NIBSS_NAME_ENQUIRY_RESET,
      payload: {},
    });
  };
};

const getNibssReports = (params) => {
  return async (dispatch) => {
    dispatch({ type: NIBSS_ALL_REPORT_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getNibssReport(params)
      .then((response) => {
        dispatch({
          type: NIBSS_ALL_REPORT_SUCCESS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.message,
              icon: "error",
            })
          );
          dispatch({ type: SET_LOADING_STATE, payload: false });
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(nibssAllReportFail(ex));
      });
  };
};
const getFilteredNibssReports = (startDate, endDate, onHide , setFromDate, setToDate) => {
  return async (dispatch) => {
    dispatch({ type: NIBSS_FILTER_REPORT_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getFilteredNibssReport(startDate, endDate)
      .then((response) => {
        dispatch({
          type: NIBSS_FILTER_REPORT_SUCCESS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
        onHide();
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.message,
              icon: "error",
              timer: 1000,
            })
          );
          // setFromDate("")
          // setToDate("")
          onHide()
          dispatch({ type: SET_LOADING_STATE, payload: false });
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(nibssFilterReportFail(ex));
      });
  };
};
const resetFilteredReports = () => {
  return async (dispatch) => {
    dispatch({ type: NIBSS_FILTER_REPORT_RESET });
  };
};

const resetTenantDetails = () => {
  return async (dispatch) => {
  dispatch({ type: NIBSS_TENANTS_RESET, });
  };
}


const resetFilteredTransactionsOutflow = () => {
  return async (dispatch) => {
    dispatch({ type: NIBSS_OUTFLOW_DATA_RESET_FILTER });
  };
};

const resetFilteredTransactionsInflow = () => {
  return async (dispatch) => {
    dispatch({ type: NIBSS_INFLOW_DATA_RESET_FILTER });
  };
};

const resetTransactionsInflow = () => {
  return async (dispatch) => {
    dispatch({ type: NIBSS_INFLOW_DATA_RESET });
  };
};

const nibssConnectTenantRequestFail = (payload) => {
  return { type: NIBSS_TENANTS_FAIL, payload };
};
const nibssFilterReportFail = (payload) => {
  return { type: NIBSS_FILTER_REPORT_FAIL, payload };
};
const nibssConnectTenantWalletRequestFail = (payload) => {
  return { type: NIBSS_TENANTS_WALLET_FAIL, payload };
};
const nibssTenantBalanceRequestFail = (payload) => {
  return { type: NIBSS_TENANTS_ACCOUNT_BALANCE_FAIL, payload };
};
const nibssInflowDataRequestFail = (payload) => {
  return { type: NIBSS_INFLOW_DATA_FAIL, payload };
};
const nibssOutflowDataRequestFail = (payload) => {
  return { type: NIBSS_OUTFLOW_DATA_FAIL, payload };
};
const nibssOutflowDataRequestFailFilter = (payload) => {
  return { type: NIBSS_OUTFLOW_DATA_FAIL_FILTER, payload };
};
const nibssInflowDataRequestFailFilter = (payload) => {
  return { type: NIBSS_INFLOW_DATA_FAIL_FILTER, payload };
};
const nibssInflowDataRequestFailFilterDownload = (payload) => {
  return { type: NIBSS_INFLOW_DATA_FAIL_FILTER_DOWNLOAD, payload };
};
const nibssSingleInflowDataRequestFail = (payload) => {
  return { type: NIBSS_SINGLE_INFLOW_DATA_FAIL, payload };
};
const nibssSingleOutflowDataRequestFail = (payload) => {
  return { type: NIBSS_SINGLE_OUTFLOW_DATA_FAIL, payload };
};
const nibssAllReportFail = (payload) => {
  return { type: NIBSS_ALL_REPORT_FAIL, payload };
};
const getBanksFail = (payload) => {
  return { type: NIBSS_GET_BANK_FAIL, payload };
};
const getNameEnquiryFail = (payload) => {
  return { type: NIBSS_NAME_ENQUIRY_FAIL, payload };
};
const getNibssCodeFail = (payload) => {
  return { type: NIBSS_AUTHENTICATE_OTP_FAIL, payload };
};
const TransferFundsFail = (payload) => {
  return { type: NIBSS_FUND_TRANSFER_FAIL, payload };
};

export {
  getNibssConnectTenants,
  getNibssConnectTenantsWallets,
  getNibssTenantsAccountBalance,
  getNibssInflowData,
  getNibssOutflowData,
  getSingleInflowDetails,
  getSingleOutflowDetails,
  getBanks,
  getNameEnquiry,
  getNameEnquiryReset,
  authenticateNibssCode,
  TransferNibssFunds,
  getNibssReports,
  getFilteredNibssReports,
  resetFilteredReports,
  getNibssOutflowDataFilter,
  resetFilteredTransactionsOutflow,
  resetFilteredTransactionsInflow,
  getNibssInflowDataFilter,
  getNibssInflowDataFilterDownload,
  resetTenantDetails,
  resetTransactionsInflow
};
