import React, { useState } from "react";
import { Input, Textarea } from "@chakra-ui/react";
import { NewActionButton, FormElements, FormClass } from "../styles";
import { Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewClientTier,
  getClientTiers,
} from "../../../../../../actions/systemTiersActions";
import Toast from "../../../../../../util/Toast";
import {
  formatAmount,
  formatCurrency,
} from "../../../../../../util/FormatAmount";
import CurrencyFormat from "react-currency-format";
const NewTier = ({ onHide }) => {
  const { clientTiers, createTierError } = useSelector(
    (state) => state.getSystemTiers
  );
  console.log(createTierError);

  const dispatch = useDispatch();
  const [newTier, setNewTier] = useState({
    tierName: "",
    tierRank: "",
    savingsWithdrawLimit: "",
    dailyWithdrawLimit: "",
    cumulativeBalance: "",
    description: "",
  });
  const [loading, setLoading] = useState(false);
  const {
    tierName,
    tierRank,
    description,
    // savingsWithdrawLimit,
    // cumulativeBalance,
    // dailyWithdrawLimit,
  } = newTier;
  const createNewTier = (e) =>
    setNewTier({
      ...newTier,
      [e.target.name]: e.target.value,
    });

  const [savingsWithdrawLimit, setSavingsWithdrawLimit] = useState("");
  const [dailyWithdrawLimit, setDailyWithdrawLimit] = useState("");
  const [cumulativeBalance, setCumulativeBalance] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      tierName &&
      tierRank &&
      savingsWithdrawLimit &&
      dailyWithdrawLimit &&
      cumulativeBalance
    ) {
      setLoading(true);
      const data = {
        tierName,
        locale: "en",
        description: description,
        tierRank: parseInt(tierRank),
        savingsWithdrawLimit: parseFloat(savingsWithdrawLimit),
        dailyWithdrawLimit: parseFloat(dailyWithdrawLimit),
        cumulativeBalance: parseFloat(cumulativeBalance),
      };
      const func = () => {
        dispatch(getClientTiers());
        onHide();
      };
      console.log(data , "data")
      dispatch(createNewClientTier(data, func));
      // onHide();
      setLoading(false);
    } else {
      dispatch(
        Toast({
          text: "All fields are required",
          icon: "error",
          timer: 2000,
        })
      );
    }
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title className="py-2">New withdraw Tier level</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn px-0 py-0">
        <FormClass style={{ width: "90%" }}>
          <FormElements style={{ width: "100%" }}>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Tier name*</label>
              <Input
                style={{ width: "60%" }}
                size="md"
                name="tierName"
                value={tierName}
                onChange={createNewTier}
                required
                type="text"
              />
            </div>

            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Tier rank</label>
              <Input
                style={{ width: "60%" }}
                size="md"
                name="tierRank"
                value={tierRank}
                onChange={createNewTier}
                required
                type="number"
              />
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label"> Savings Withdrawal limit</label>
              {/* <Input
                style={{ width: "60%" }}
                size="md"
                name="savingsWithdrawLimit"
                required
                value={formartCurrency(savingsWithdrawLimit)}
                onChange={createNewTier}
                type="number"
              /> */}
              <CurrencyFormat
                value={savingsWithdrawLimit}
                name="savingsWithdrawLimit"
                thousandSeparator={true}
                className="amount-input"
                style={{ width: "60%" }}
                size="md"
                onValueChange={(values) => {
                  const { value } = values;
                  setSavingsWithdrawLimit(value);
                }}
              />
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label"> Daily Withdrawal limit</label>
              {/* <Input
                style={{ width: "60%" }}
                size="md"
                name="dailyWithdrawLimit"
                required
                value={dailyWithdrawLimit}
                onChange={createNewTier}
                type="number"
              /> */}
              <CurrencyFormat
                value={dailyWithdrawLimit}
                name="dailyWithdrawLimit"
                thousandSeparator={true}
                className="amount-input"
                style={{ width: "60%" }}
                size="md"
                onValueChange={(values) => {
                  const { value } = values;
                  setDailyWithdrawLimit(value);
                }}
              />
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Cumulative Balance</label>
              {/* <Input
                style={{ width: "60%" }}
                size="md"
                name="cumulativeBalance"
                required
                value={cumulativeBalance}
                onChange={createNewTier}
                type="number"
              /> */}
              <CurrencyFormat
                value={cumulativeBalance}
                name="cumulativeBalance"
                thousandSeparator={true}
                className="amount-input"
                style={{ width: "60%" }}
                size="md"
                onValueChange={(values) => {
                  const { value } = values;
                  setCumulativeBalance(value);
                }}
              />
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Description</label>
              <Textarea
                style={{ width: "60%", fontSize: "12px" }}
                size="md"
                name="description"
                value={description}
                onChange={createNewTier}
              />
            </div>
          </FormElements>
        </FormClass>
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-1">
          <NewActionButton
            style={{ color: "#2C1DFF", background: "#fff !important" }}
            onClick={onHide}
          >
            <img
              src={
                require("../../../../../../assets/images/icons/cancel.svg")
                  .default
              }
              alt="cancel icon"
            />
            Cancel
          </NewActionButton>
          <NewActionButton
            onClick={handleSubmit}
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
          >
            {loading ? (
              <Spinner
                animation="border"
                color="#fff"
                className="mr-2 text-white"
                size="sm"
              />
            ) : (
              <img
                src={
                  require("../../../../../../assets/images/icons/save.svg")
                    .default
                }
                alt="submit icon"
              />
            )}
            Submit
          </NewActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default NewTier;
