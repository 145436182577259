import styled from "styled-components";

export const ActionButtonsGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  justify-content: space-around;
  select {
    // background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    border-radius: 3px;
    height: 50px;
    min-width: 100px;
    border: none;
    // margin: ;
  }
`;

export const ContentWrapper = styled.div`
  margin-top: 50px;
`;

export const ContentCard = styled.div`
  position: relative;
  height: 110px;
  display: flex;
  min-width: 0;
  word-wrap: break-word;
  justify-content: space-around;
  align-items: center;
  //   padding-left: 20px;
  //   padding-right: 20px;
  padding: 20px 10px;
  background-color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  flex-direction: row;
  box-shadow: 0px 2px 0px #ededf6;
  .body {
    width: 150px;
  }
  .title {
    font-weight: bold;
    font-size: 15px;
    color: #333333;
  }
  .description {
    margin-top: 10px;
    font-weight: 300;
    font-size: 13px;
    color: #a6acbe;
  }
`;
