import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Table, Tooltip } from 'antd';

import { ActionButton, IconButton } from '../styles.jsx';

import ModalContainer from '../../../components/ModalContainer';
import DeleteDocument from '../components/documents/DeleteDocument.jsx';
import AddDocument from '../components/documents/AddDocument.jsx';
import AddSignature from '../components/documents/AddSignature';
import ViewDocument from '../components/documents/ViewDocument.jsx';

import config from '../../../../../config';
import AuthModule from '../../../../../modules/Auth.module';
import CheckPermission from '../../../../../util/CheckPermission';
import axiosData from '../../../../../services/axiosData';

const DocumentsTab = ({ userData, loading, id }) => {
  const ancRef = useRef();
  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  const documentsList = useSelector((state) => state.documentsList);
  let { documents } = documentsList;
  const [modalState, toggleModal] = useState(false);
  const [modalContentName, setModalContentName] = useState('');

  const [documentId, setDocumentId] = useState('');
  const [selectedDocument, setSelectedDocument] = useState({});

  const currentContent = (e, docId) => {
    const val = e.currentTarget.id.trim();
    setDocumentId(docId);
    if (val) {
      toggleModal(true);
      setModalContentName(val);
    }
  };
  const handleViewDocument = (id) => {
    setSelectedDocument(documents.filter((usr) => usr.id === id)[0]);
    toggleModal(true);
    setModalContentName('viewDocument');
  };

  const downloadDocument = async (document) => {
    const url = `${config.apiGateway.BASE_API_URL}/clients/${id}/documents/${
      document.id
    }/attachment?tenantIdentifier=${
      config.apiGateway.IS_PRODUCTION === `true`
        ? AuthModule.getTenant()
        : config.apiGateway.WOODCORE_TENANT
    }`;
    try {
      const { data, error } = await axiosData({
        method: 'GET',
        signal: false,
        url: url,
        isBearerToken: true,
        responseType: 'blob',
      });
      // console.log(data, "*****");
      if (data) {
        const dataUrl = URL.createObjectURL(data);
        ancRef.current.href = dataUrl;
        ancRef.current.download = 'download';
        setTimeout(() => {
          URL.revokeObjectURL(dataUrl);
        }, 150);
        ancRef.current.click();
      } else {
      }
    } catch (ex) {
    } finally {
    }
  };

  const handleModalClose = () => toggleModal(false);

  const displayModalContent = () => {
    switch (modalContentName) {
      case 'delete':
        return (
          <DeleteDocument
            userData={userData}
            docId={documentId}
            onHide={handleModalClose}
          />
        );
      case 'addDocument':
        return <AddDocument onHide={handleModalClose} />;
      case 'addSignature':
        return <AddSignature onHide={handleModalClose} />;
      case 'viewDocument':
        return (
          <ViewDocument
            id={id}
            data={selectedDocument}
            onHide={handleModalClose}
          />
        );
      default:
        return;
    }
  };

  // const handleFileToDownload = (file) => {
  //   const link = document.createElement("a");
  //   link.href = file;
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  // function download(url) {
  //   const a = document.createElement("a");
  //   a.href = url;
  //   a.download = url.split("/").pop();
  //   document.body.appendChild(a);
  //   a.click();
  //   document.body.removeChild(a);
  // }

  let tableData = [];
  let hasSignature = false;

  // TODO This is the download section
  !loading &&
    documents !== null &&
    documents.map((document) => {
      if (document.name === 'ClientSignature') {
        hasSignature = true;
      }
      tableData.push({
        key: document.id,
        name: (
          <Tooltip title={document?.name}>
            <div className="truncate-text truncate-text-client">{document?.name}</div>
          </Tooltip>
        ),
        description: (
          <Tooltip title={document?.description}>
            <div className="truncate-text truncate-text-client">{document?.description}</div>
          </Tooltip>
        ),
        action: (
          <>
            {CheckPermission('READ_DOCUMENT', permissions) ? (
              <IconButton
                onClick={() => downloadDocument(document)}
                color="#2C1DFF"
              >
                <img
                  src={
                    require('../../../../../assets/images/icons/download.svg')
                      .default
                  }
                  alt="download"
                  className="icon-img"
                />
              </IconButton>
            ) : (
              ''
            )}
            {document.type !== 'application/pdf' &&
            CheckPermission('READ_DOCUMENT', permissions) ? (
              <IconButton
                onClick={() => handleViewDocument(document.id)}
                color="#2C1DFF"
              >
                <img
                  src={
                    require('../../../../../assets/images/icons/view.svg')
                      .default
                  }
                  alt="view"
                  className="icon-img"
                />
              </IconButton>
            ) : (
              ''
            )}
            {CheckPermission('DELETE_DOCUMENT', permissions) ? (
              <>
                <IconButton
                  color="#2C1DFF"
                  id="delete"
                  onClick={(e) => currentContent(e, document.id)}
                >
                  <img
                    src={
                      require('../../../../../assets/images/icons/bin.svg')
                        .default
                    }
                    alt="delete"
                    className="icon-img"
                  />
                </IconButton>{' '}
              </>
            ) : (
              ''
            )}
          </>
        ),
      });
    });

  const columns = [
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Description', dataIndex: 'description', key: 'description' },
    { title: 'Action', dataIndex: 'action', key: 'action' },
  ];

  return (
    <>
      <a style={{ display: "none" }} ref={ancRef}></a>
      <ModalContainer size="xl" show={modalState} dialogClassName="document_infoModal">
        {modalState && displayModalContent()}
      </ModalContainer>
      <div className="container pt-4">
        <div className="row">
          <div className="col-sm-12 col-md-7">
            <h3 className="page-title">Documents</h3>
          </div>
          {CheckPermission("CREATE_DOCUMENT", permissions) ? (
            <div className="col-sm-12 col-md-5 d-flex justify-content-around">
              <ActionButton
                color="#2C1DFF"
                id="addSignature"
                onClick={currentContent}
              >
                <img
                  src={
                    require('../../../../../assets/images/icons/add.svg')
                      .default
                  }
                  className="mr-2"
                  alt="add"
                />{' '}
                {hasSignature ? 'Update' : 'Add'} Signature
              </ActionButton>{' '}
              <ActionButton
                color="#2C1DFF"
                id="addDocument"
                onClick={currentContent}
              >
                <img
                  src={
                    require('../../../../../assets/images/icons/add.svg')
                      .default
                  }
                  className="mr-2"
                  alt="add"
                />{" "}
                Upload Document
              </ActionButton>
            </div>
          ) : (
            ""
          )}
        </div>
        <br />
        {CheckPermission('READ_DOCUMENT', permissions) ? (
          <Table columns={columns} dataSource={tableData} />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default DocumentsTab;
