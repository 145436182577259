import styled from "styled-components";
import { Link } from "react-router-dom";

export const Rejected = styled.p`
  color: #ff0000;
  border-radius: 5px;
  padding: 3px 6px !important;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
`;

export const Approved = styled.p`
  color: #00c537;
  border-radius: 5px;
  padding: 3px 6px !important;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
`;

export const Heading = styled.div`
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 25px;
  color: #002c79;
`;
export const H3 = styled.h3`
  font-weight: bold;
  font-size: 22px;
  color: #002c79;
`;
export const Grid = styled.div`
  display: flex;

  .first-child {
    min-width: 30%;
  }
`;

export const ActionButton = styled.button`
  background: ${(props) => props.color || "#2C1DFF"} !important;
  border-radius: 5px !important;
  color: #fff !important;
  margin: 0 3px;
  border: none;
  font-family: "AventaMedium";
  display: inline;
  font-size: 14px;
  height: 35px;
  width: 130px;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  position: relative;
  cursor: pointer;
  img {
    height: 14px;
    margin-right: 10px;
    display: inline;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;

export const LinkColor = styled(Link)`
  color: #2c1dff;
`;

export const GoBack = styled(Link)`
  color: #002c79;
  display: flex;
  align-items: center;
  margin-left: 10px;
  :hover {
    color: #002c79;
  }
`;
