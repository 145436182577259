import React from "react";
import { Modal } from "react-bootstrap";

const ModalContainer = (props) => {
  const { children, dialogClassName } = props;

  return (
    <Modal
      {...props}
      size={props?.size || "lg"}
      backdrop="static"
      keyboard={false}
      centered
      dialogClassName={["infoModal", dialogClassName].join(' ')}
      contentClassName="signatureModal"
    >
      {children}
    </Modal>
  );
};

export default ModalContainer;
