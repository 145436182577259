import React from "react";
import { Table } from "antd";
import Loading from "./Loading";

const columns = [
  { title: "Account Name", dataIndex: "accountName", key: "accountName" },
  { title: "GL Code", dataIndex: "glCode", key: "glCode" },
  { title: "Account type", dataIndex: "accountType", key: "accountType" },
  { title: "Status", dataIndex: "status", key: "status" },
  {
    title: "Manual Entries Status ",
    dataIndex: "manualEntryStatus",
    key: "manualEntryStatus",
  },
  { title: "Used as", dataIndex: "usedAs", key: "usedAs" },
  {
    title: "Linked Accounts",
    dataIndex: "linkedAccounts",
    key: "linkedAccounts",
  },
];

const TableLoader = (props) => {
  const someChartsData = [
    {
      accountName: <Loading />,
      glCode: <Loading />,
      accountType: <Loading />,
      status: <Loading />,
      manualEntryStatus: <Loading />,
      usedAs: <Loading />,
      linkedAccounts: <Loading />,
      chartAccount: <Loading />,
    },
    {
      accountName: <Loading />,
      glCode: <Loading />,
      accountType: <Loading />,
      status: <Loading />,
      manualEntryStatus: <Loading />,
      usedAs: <Loading />,
      linkedAccounts: <Loading />,
      chartAccount: <Loading />,
    },
    {
      accountName: <Loading />,
      glCode: <Loading />,
      accountType: <Loading />,
      status: <Loading />,
      manualEntryStatus: <Loading />,
      usedAs: <Loading />,
      linkedAccounts: <Loading />,
      chartAccount: <Loading />,
    },
    {
      accountName: <Loading />,
      glCode: <Loading />,
      accountType: <Loading />,
      status: <Loading />,
      manualEntryStatus: <Loading />,
      usedAs: <Loading />,
      linkedAccounts: <Loading />,
      chartAccount: <Loading />,
    },
    {
      accountName: <Loading />,
      glCode: <Loading />,
      accountType: <Loading />,
      status: <Loading />,
      manualEntryStatus: <Loading />,
      usedAs: <Loading />,
      linkedAccounts: <Loading />,
      chartAccount: <Loading />,
    },
  ];
  return (
    <>
      <Table
        columns={columns}
        dataSource={someChartsData}
        pagination={{ defaultPageSize: 8 }}
        className="antdTable"
        defaultPageSize={8}
      />
    </>
  );
};

export default TableLoader;
