import React from "react";
import { useDispatch } from "react-redux";

import { Modal } from "react-bootstrap";

import { ActionButton, FormClass, ModalText } from "../styles";

import { deleteASingleDataTable } from "../../../../../actions/configurationActions";

const DeleteDataTableModal = ({ name, onHide, history }) => {
  const dispatch = useDispatch();

  const handleDeleteDataTables = () => {
    if (name) {
      dispatch(deleteASingleDataTable(name, history));
    }
  };
  return (
    <>
      <Modal.Header>
        <Modal.Title>Delete Data Tables</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <FormClass style={{ width: "95%" }}>
          <ModalText>Are You Sure you want to Delete {name} Data Tables?</ModalText>
    
        </FormClass>
 
      </Modal.Body>
      <Modal.Footer>
        <div className="spaceBetweenFlexEnd">
          <ActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require("../../../../../assets/images/icons/cancel.svg").default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton color="#2C1DFF" onClick={handleDeleteDataTables}>
            <img
              src={
                require("../../../../../assets/images/icons/delete.svg").default
              }
              alt="cancel icon"
            />
            Delete
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default DeleteDataTableModal;
