import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import CloseSvgBlack from "../../../../../../assets/images/icons/cancel-black.svg";
import CheckedWhiteSvg from "../../../../../../assets/images/icons/checked-white.svg";
import DeleteSvg from "../../../../../../assets/images/icons/delete.svg";
import CloseSvg from "../../../../../../assets/images/icons/cancel.svg";
import { useSelector, useDispatch } from "react-redux";
import ActionModal from "./ActionModal";
import ModalContainer from "../../../../components/ModalContainer";

import {
  getCheckerDetails,
  processAllMakerChecker,
} from "../../../../../../actions/businessActions";
import { formatDate } from "../../../../../../util/FormatDate";

const DetailsModal = ({
  onHide,
  action,
  showCheckerDetails,
  activeChecker,
  history,
}) => {
  const dispatch = useDispatch();
  const { checkerInboxDetails } = useSelector((state) => state.businessReducer);
  const [modalContentName, setModalContentName] = useState("");
  const [modalState, toggleModal] = useState(false);
  const [checkerInbox, setCheckerInboxDetailsModalStyle] = useState("");
  const [details, setShowDetails] = useState(true);

  const handleApprove = () => {
    dispatch(
      processAllMakerChecker(
        activeChecker,
        modalContentName.toLocaleLowerCase(),
        {},
        history
      )
    );
  };
  const handleModalClose = () => {
    toggleModal(false);
    setShowDetails(true);
    setCheckerInboxDetailsModalStyle("");
  };

  const currentContent = (e) => {
    const val = e.currentTarget.id.trim();
    if (val) {
      toggleModal(true);
      setModalContentName(val);
      setShowDetails(false);
      setCheckerInboxDetailsModalStyle("hide");
    }
  };

  const displayModalContent = () => {
    return (
      <ActionModal
        style={{ height: "20px" }}
        header={`Confirm ${modalContentName}`}
        onHide={handleModalClose}
        action={handleApprove}
      />
    );
  };

  useEffect(() => {
    dispatch(getCheckerDetails(activeChecker));
  }, [activeChecker, dispatch]);

  return (
    <ModalContainer size={modalState ? "md" : ""} show={showCheckerDetails} 
    dialogClassName="document_infoModal">
    
      {modalState && displayModalContent()}
      {details && (
        <ModalDetailsContainer
          className={`${checkerInbox === "hide" ? "hide_modal" : ""}`}
          checkerInbox={checkerInbox}
        >
          <Modal.Header className="h5 bold">
            <span style={{fontWeight:"bold"}}>Checker inbox</span>
            <button onClick={onHide}>
              <img className="mr-2" src={CloseSvgBlack} alt="" />
            </button>
          </Modal.Header>
          <Modal.Body className="wc-modal">
            <div className="mt-3 d-flex justify-content-end">
              <div
                onClick={currentContent}
                id="Approve"
                className="approve-btn mr-3 px-3"
              >
                <img className="mr-2 checker-icon" src={CheckedWhiteSvg} alt="" />
                Approve
              </div>
              <div
                onClick={currentContent}
                id="Delete"
                className="approve-btn bg-danger mr-3 px-3"
              >
                <img className="mr-2 checker-icon" src={DeleteSvg} alt="" />
                Delete
              </div>
              <div
                onClick={currentContent}
                id="Reject"
                className="approve-btn bg-warning px-3"
              >
                <img className="mr-2 checker-icon" src={CloseSvg} alt="" />
                Reject
              </div>
            </div>
            <div className="mt-5">
              <div className="d-flex pb-3">
                <h4 className="mr-4 h6" style={{ width: "120px",fontWeight:"bold", fontSize:"12px" }}>
                  ID
                </h4>
                <Text style={{fontWeight:"bold", fontSize:"12px"  }}>{checkerInboxDetails?.id}</Text>
              </div>
              <div className="d-flex pb-3">
                <h4 className="mr-4 h6" style={{ width: "120px",fontWeight:"bold", fontSize:"12px"  }}>
                  Status
                </h4>
                <Text style={{fontWeight:"bold", fontSize:"12px"  }}>{checkerInboxDetails?.processingResult}</Text>
              </div>
              <div className="d-flex pb-3">
                <h4 className="mr-4 h6" style={{ width: "120px",fontWeight:"bold", fontSize:"12px"  }}>
                  User
                </h4>
                <Text style={{fontWeight:"bold", fontSize:"12px"  }}>{checkerInboxDetails?.maker}</Text>
              </div>
              <div className="d-flex pb-3">
                <h4 className="mr-4 h6" style={{ width: "120px",fontWeight:"bold", fontSize:"12px"  }}>
                  Action
                </h4>
                <Text style={{fontWeight:"bold", fontSize:"12px"  }}>{checkerInboxDetails?.actionName}</Text>
              </div>
              <div className="d-flex pb-3">
                <h4 className="mr-4 h6" style={{ width: "120px",fontWeight:"bold", fontSize:"12px"  }}>
                  Entity
                </h4>
                <Text style={{fontWeight:"bold", fontSize:"12px"  }}>{checkerInboxDetails?.entityName}</Text>
              </div>
              <div className="d-flex pb-3">
                <h4 className="mr-4 h6" style={{ width: "120px",fontWeight:"bold", fontSize:"12px"  }}>
                  Resource ID
                </h4>
                <Text style={{fontWeight:"bold", fontSize:"12px"  }}>{checkerInboxDetails?.resourceId}</Text>
              </div>
              <div className="d-flex pb-3">
                <h4 className="mr-4 h6" style={{ width: "120px" ,fontWeight:"bold", fontSize:"12px" }}>
                  Date
                </h4>
                <Text style={{fontWeight:"bold", fontSize:"12px"  }}>{formatDate(checkerInboxDetails?.madeOnDate)}</Text>
              </div>
              <div className="d-flex pb-3">
                <h4 className="mr-4 h6" style={{ width: "120px" ,fontWeight:"bold", fontSize:"12px" }}>
                  Office
                </h4>
                <Text style={{fontWeight:"bold", fontSize:"12px"  }}>{checkerInboxDetails?.officeName}</Text>
              </div>
            </div>
          </Modal.Body>
        </ModalDetailsContainer>
      )}
    </ModalContainer>
  );
};

export default DetailsModal;

const Text = styled.p`
  color: #969cba;
`;

const ModalDetailsContainer = styled.div`
  /* visibility: ${(props) =>
    props?.checkerInbox === "hide" ? "hidden" : ""} !important; */
`;
