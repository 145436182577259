import {
  SAVINGS_TRANSACTIONS_FAIL,
  SAVINGS_TRANSACTIONS_REQUEST,
  SAVINGS_TRANSACTIONS_SUCCESS,
  LOAN_TRANSACTIONS_FAIL,
  LOAN_TRANSACTIONS_REQUEST,
  LOAN_TRANSACTIONS_SUCCESS,
  FIXED_TRANSACTIONS_FAIL,
  FIXED_TRANSACTIONS_REQUEST,
  FIXED_TRANSACTIONS_SUCCESS,
  CLEAR_LOAN,
  CLEAR_SAVINGS,
  CLEAR_FIXED,

} from "../constants/transactionsConstants";

//REDUCER FOR SAVINGS ACCOUNT TRANSACTIONS
export const savingsAccountTransReducer = (
  state = { savingsAccountDetails: {} },
  action
) => {
  switch (action.type) {
    case SAVINGS_TRANSACTIONS_REQUEST:
      return { ...state, loading: true };
    case SAVINGS_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        savingsAccountDetails: action.payload,
      };
    case SAVINGS_TRANSACTIONS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case CLEAR_SAVINGS:
      return { ...state, savingsAccountDetails: { transactions: [] } };
    default:
      return state;
  }
};

//REDUCER FOR LOAN ACCOUNT TRANSACTIONS
export const loanAccountTransReducer = (
  state = { loanAccountDetails: {} },
  action
) => {
  switch (action.type) {
    case LOAN_TRANSACTIONS_REQUEST:
      return { ...state, loading: true };
    case LOAN_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        loanAccountDetails: action.payload,
      };
    case LOAN_TRANSACTIONS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case CLEAR_LOAN:
      return { ...state, loanAccountDetails: { transactions: [] } };
    default:
      return state;
  }
};

//REDUCER FOR FIXED ACCOUNT TRANSACTIONS
export const fixedAccountTransReducer = (
  state = { fixedDepositAccountDetails: {} },
  action
) => {
  switch (action.type) {
    case FIXED_TRANSACTIONS_REQUEST:
      return { ...state, loading: true };
    case FIXED_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        fixedDepositAccountDetails: action.payload,
      };
    case FIXED_TRANSACTIONS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case CLEAR_FIXED:
      return { ...state, fixedDepositAccountDetails: { transactions: [] } };
    default:
      return state;
  }
};
