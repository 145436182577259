import ContentLoader from "react-content-loader";
import { ContentWrapper, ContentCard } from "../styles";

const cards = [
  { id: "1" },
  // { id: "2" },
  // { id: "3" },
  // { id: "4" },
  // { id: "5" },
  // { id: "6" },
  // { id: "7" },
  // { id: "8" },
];

const LoadingScreen = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">
              <ContentLoader
                speed={2}
                width={400}
                height={100}
                viewBox="0 0 400 100"
                backgroundColor="#ffffff"
                foregroundColor="#ecebeb"
              >
                <rect x="2" y="20" rx="1" ry="1" width="350" height="40" />
              </ContentLoader>
            </h3>
            <p className="loaderMarginRedude">
              <ContentLoader
                speed={2}
                width={400}
                height={100}
                viewBox="0 0 400 100"
                backgroundColor="#ffffff"
                foregroundColor="#ecebeb"
              >
                <rect x="2" y="0" rx="3" ry="10" width="609" height="10" />
                <rect x="2" y="20" rx="3" ry="10" width="300" height="10" />
                {/* <rect x="2" y="40" rx="3" ry="10" width="266" height="10" /> */}
              </ContentLoader>
            </p>
          </div>
        </div>
        <ContentWrapper>
          <div className="loaderMarginRedude2">
            <div className="row">
              {cards.map(({ id }) => (
                <div
                  className="col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-4"
                  key={id}
                >
                  <ContentCard>
                    <ContentLoader
                      speed={2}
                      width={300}
                      height={100}
                      viewBox="0 0 300 100"
                      backgroundColor="#f3f3f3"
                      foregroundColor="#ffffff"
                    >
                      <rect
                        x="90"
                        y="11"
                        rx="3"
                        ry="3"
                        width="180"
                        height="14"
                      />
                      <rect
                        x="90"
                        y="29"
                        rx="3"
                        ry="3"
                        width="94"
                        height="10"
                      />
                      <rect
                        x="90"
                        y="66"
                        rx="3"
                        ry="3"
                        width="180"
                        height="9"
                      />
                      <rect
                        x="90"
                        y="82"
                        rx="3"
                        ry="3"
                        width="120"
                        height="6"
                      />
                      <rect
                        x="90"
                        y="106"
                        rx="3"
                        ry="3"
                        width="146"
                        height="6"
                      />
                      <circle cx="31" cy="53" r="30" />
                    </ContentLoader>
                  </ContentCard>
                </div>
              ))}
            </div>
          </div>
        </ContentWrapper>
      </div>
    </>
  );
};
export default LoadingScreen;
