import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSavingsNotes, initiateSavingsNoteCreation } from "../../../../../actions/SavingsAndLoanActions";
import { ActionButton } from "../styles";
import { Note } from "./Note";
import {
	BtnBold,
	BtnBulletList,
	BtnClearFormatting,
	BtnItalic,
	BtnLink,
	BtnNumberedList,
	BtnStrikeThrough,
	Editor,
	EditorProvider,
	Separator,
	Toolbar,
	createButton
} from "react-simple-wysiwyg";
import CheckPermission from "../../../../../util/CheckPermission";
import { isEven } from "../../../../../util/CheckIfEven";

const Notes = ({ id }) => {
	const dispatch = useDispatch();
	const { savingsNotes } = useSelector((state) => state.savingsAndLoan);
	const { responseUserData: { permissions } } = useSelector((state) => state.preAuth);

	const [note, setNote] = useState("");

	const handleNoteChange = (event) => {
		setNote(event.target.value);
	};
	const resetNote = () => setNote("");
	const callback = () => {
		resetNote();
		dispatch(getSavingsNotes(id));
	};
	const submitNote = () => {
		dispatch(initiateSavingsNoteCreation(id, { note }, callback));
	};

	useEffect(() => {
		callback();
	}, []);

	const BtnUndo = createButton('Undo', <>&#8634;</>, 'undo');
	const BtnRedo = createButton('Redo', <>&#8635;</>, 'redo');
	const userCanCreateNote = CheckPermission('CREATE_SAVINGNOTE', permissions);
	const leftColumnNotes = savingsNotes.filter((_, index) => isEven(index));
	const rightColumnNotes = savingsNotes.filter((_, index) => !isEven(index));

	return (
		<div className="d-flex h-full">
			{userCanCreateNote && (
				<div
					className="container border-right w-50 pt-4 d-flex flex-column h-full mb-auto"
					style={{ height: '662px' }}
				>
					<h3 className="page-title">Notes</h3>
					<div className="d-flex flex-column gap-2">
						<div className="d-flex flex-column">
							<label htmlFor="note" className="pt-2 fs-12 text-nowrap">
								Write a note
							</label>
							<EditorProvider>
								<Editor
									value={note}
									onChange={handleNoteChange}
								>
									<Toolbar>
										<BtnUndo />
										<BtnRedo />
										<Separator />
										<BtnBold />
										<BtnItalic />
										<BtnStrikeThrough />
										<Separator />
										<BtnNumberedList />
										<BtnBulletList />
										<BtnLink />
										<Separator />
										<BtnClearFormatting />
									</Toolbar>
								</Editor>
							</EditorProvider>
						</div>
						<ActionButton
							color="#2C1DFF"
							onClick={submitNote}
							style={{ minWidth: 'fit-content' }}
							className="w-fit m-0 px-3 w-fit align-self-end"
						>
							<img
								src={require("../../../../../assets/images/icons/add.svg").default}
								alt="add icon"
							/>
							Add note
						</ActionButton>
					</div>
				</div>
			)}
			<div className={`container h-full pt-4 pr-0 ${userCanCreateNote ? "w-50" : "w-100"}`}>
				<div className={`d-flex flex-column gap-6 ${userCanCreateNote ? "pl-2" : ""}`}>
					<h3 className="mt-3 fs-14" style={{ fontWeight: 'bold' }}>All notes</h3>
					{userCanCreateNote && savingsNotes.length > 0 ? (
						<div
							className="d-flex flex-column gap-4 overflow-auto pr-2 pb-4"
							style={{ maxHeight: '582px' }}
						>
							{savingsNotes?.map(note => <Note key={note?.id} savingsId={id} {...note} />)}
						</div>
					) : !userCanCreateNote && savingsNotes.length > 0 ? (
						<div
							className="d-grid grid-columns-2 gap-4 overflow-auto pr-2 pb-4"
							style={{ maxHeight: '582px' }}
						>
							<div className="d-flex flex-column gap-4">
								{leftColumnNotes?.map(note => <Note key={note?.id} savingsId={id} {...note} />)}
							</div>
							<div className="d-flex flex-column gap-4">
								{rightColumnNotes?.map(note => <Note key={note?.id} savingsId={id} {...note} />)}
							</div>
						</div>
					) : <span className="fs-12 text-secondary"><em>There are no saved notes for this loan account yet</em></span>}
				</div>
			</div>
		</div>
	);
};

export default Notes;
