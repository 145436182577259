import {
  GET_PAYMENT_TYPES_REQUEST,
  GET_PAYMENT_TYPES_SUCCESS,
  GET_PAYMENT_TYPES_FAIL,
  GET_PAYMENT_TYPES_RESET,
  GET_ACCOUNT_OPTIONS_REQUEST,
  GET_ACCOUNT_OPTIONS_SUCCESS,
  GET_ACCOUNT_OPTIONS_FAIL,
  GET_S_PRODUCT_OPTIONS_REQUEST,
  GET_S_PRODUCT_OPTIONS_SUCCESS,
  GET_S_PRODUCT_OPTIONS_FAIL,
  ADD_SAVINGS_CHARGE,
  ADD_LOANS_CHARGE,
  GET_S_CHARGE_FAIL,
  GET_S_CHARGE_SUCCESS,
  GET_S_CHARGE_REQUEST,
  GET_L_CHARGE_FAIL,
  GET_L_CHARGE_SUCCESS,
  GET_L_CHARGE_REQUEST,
  GET_LOAN_OPTIONS_REQUEST,
  GET_LOAN_OPTIONS_SUCCESS,
  GET_LOAN_OPTIONS_FAIL,
  GET_L_PRODUCT_DATA_REQUEST,
  GET_L_PRODUCT_DATA_SUCCESS,
  GET_L_PRODUCT_DATA_RESET,
  GET_L_PRODUCT_DATA_FAIL,
  SET_LOADING_STATE,
  SET_NOTIFICATION,
  GET_STATES_IDS,
  GET_CREATE_CUSTOMER_DETAILS,
  GET_COUNTRY_CODES_DETAILS,
  IS_GENERAL_SEARCHING_LOADING,
  IS_GENERAL_SEARCHING,
  GET_SEARCH_QUERY,
  GET_GL_ACCOUNTS,
  SET_TABLE_LOADING_STATE,
  GET_CALCULATED_LOAN,
  GET_ALL_TOOLTIP,
  GET_ALL_CODE_VALUES,
  GET_APPLICABlE_DATA_TABLES,
  GET_CURRENT_DATATABLE,
  GET_IMPORT_TEMPLATE,
  GET_BULK_IMPORT_DOCUMENT,
  SET_UPLOADED_BULK_IMPORT,
  SET_ALL_UPLOADED_BULK_IMPORT,
  SET_NAVIGATION_BAR_ACTIVE,
  GET_USER_PAYMENT_TYPES_REQUEST,
  GET_USER_PAYMENT_TYPES_SUCCESS,
  GET_USER_PAYMENT_TYPES_FAIL,
  SET_DROPDOWN_VALUE,
  GET_PROFILE_APPLICABlE_DATATABLES,
  GET_PROFILE_CURRENT_DATATABLE,
  CLEAR_PROFILE_APPLICABLE_DATA_TABLE,
  CLEAR_PROFILE_CURRENT_DATA_TABLE,
  GET_SAVINGS_APPLICABlE_DATATABLES,
  GET_SAVINGS_CURRENT_DATATABLE,
  CLEAR_SAVINGS_APPLICABLE_DATA_TABLE,
  CLEAR_SAVINGS_CURRENT_DATA_TABLE,
  GET_FD_PRODUCT_DATA_REQUEST,
  GET_FD_PRODUCT_DATA_SUCCESS,
  GET_FD_PRODUCT_DATA_FAIL,
  GET_FD_PRODUCT_DATA_RESET,
  GET_CURRENT_CLIENT_DATA_TABLE,
  CLEAR_CURRENT_CLIENT_DATA_TABLE,
  GET_CURRENT_DEPOSIT_DATA_TABLE,
  GET_CURRENT_LOAN_DATA_TABLE,
  CLEAR_CURRENT_DEPOSIT_DATA_TABLE,
  CLEAR_CURRENT_LOAN_DATA_TABLE,
} from "../constants/utilityConstants";

export const setIsLoading = (state = { isLoading: false }, action) => {
  switch (action.type) {
    case SET_LOADING_STATE:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};
export const setNavigationBar = (
  state = { navigationBarActive: true },
  action
) => {
  switch (action.type) {
    case SET_NAVIGATION_BAR_ACTIVE:
      return { ...state, navigationBarActive: action.payload };
    default:
      return state;
  }
};
export const setCodeValues = (state = { codeValues: [] }, action) => {
  switch (action.type) {
    case GET_ALL_CODE_VALUES:
      return { ...state, codeValues: action.payload };
    default:
      return state;
  }
};

// Manage Data Table
export const manageDataTables = (
  state = {
    applicableDataTables: {},
    currentClientDataTableInfo: {},
    currentDepositDataTableInfo: {},
    currentLoanDataTableInfo: {}
  },
  action
) => {
  switch (action.type) {
    case GET_APPLICABlE_DATA_TABLES:
      return { ...state, applicableDataTables: action.payload };
    case GET_CURRENT_CLIENT_DATA_TABLE:
      return { ...state, currentClientDataTableInfo: action.payload };
    case GET_CURRENT_DEPOSIT_DATA_TABLE:
      return { ...state, currentDepositDataTableInfo: action.payload };
    case GET_CURRENT_LOAN_DATA_TABLE:
      return { ...state, currentLoanDataTableInfo: action.payload };
    default:
      return state;
  }
};

// Manage Data Table
export const profileManageDataTables = (
  state = { profileApplicableDataTables: {}, profileCurrentDataTableInfo: {} },
  action
) => {
  switch (action.type) {
    case GET_PROFILE_APPLICABlE_DATATABLES:
      return { ...state, profileApplicableDataTables: action.payload };
    case GET_PROFILE_CURRENT_DATATABLE:
      return { ...state, profileCurrentDataTableInfo: action.payload };
    case CLEAR_PROFILE_APPLICABLE_DATA_TABLE:
      return {
        ...state,
        profileApplicableDataTables: {}, // Reset applicableDataTables to an empty object
      };
    case CLEAR_PROFILE_CURRENT_DATA_TABLE:
      return {
        ...state,
        profileCurrentDataTableInfo: {}, // Reset currentDataTableInfo to an empty object
      };
    default:
      return state;
  }
};

export const savingsManageDataTables = (
  state = { savingsApplicableDataTables: {}, savingsCurrentDataTableInfo: {} },
  action
) => {
  switch (action.type) {
    case GET_SAVINGS_APPLICABlE_DATATABLES:
      return { ...state, savingsApplicableDataTables: action.payload };
    case GET_SAVINGS_CURRENT_DATATABLE:
      return { ...state, savingsCurrentDataTableInfo: action.payload };
    case CLEAR_SAVINGS_APPLICABLE_DATA_TABLE:
      return {
        ...state,
        savingsApplicableDataTables: {}, // Reset applicableDataTables to an empty object
      };
    case CLEAR_SAVINGS_CURRENT_DATA_TABLE:
      return {
        ...state,
        savingsCurrentDataTableInfo: {}, // Reset currentDataTableInfo to an empty object
      };
    default:
      return state;
  }
};

export const setIsTableLoading = (
  state = { isTableLoading: false },
  action
) => {
  switch (action.type) {
    case SET_TABLE_LOADING_STATE:
      return { ...state, isTableLoading: action.payload };
    default:
      return state;
  }
};
export const setIsGenralSearching = (
  state = { isGeneralSearching: false },
  action
) => {
  switch (action.type) {
    case IS_GENERAL_SEARCHING:
      return { ...state, isGeneralSearching: action.payload };
    default:
      return state;
  }
};
export const setCalculatedLoan = (state = { calculatedLoan: {} }, action) => {
  switch (action.type) {
    case GET_CALCULATED_LOAN:
      return { ...state, calculatedLoan: action.payload };
    default:
      return state;
  }
};
export const setImportTemplate = (
  state = { importTemplate: {}, bulkDocuments: [], uploadedBulkImport: [] },
  action
) => {
  switch (action.type) {
    case GET_IMPORT_TEMPLATE:
      return { ...state, importTemplate: action.payload };
    case GET_BULK_IMPORT_DOCUMENT:
      return { ...state, bulkDocuments: action.payload };
    case SET_UPLOADED_BULK_IMPORT:
      return {
        ...state,
        uploadedBulkImport: [...state.uploadedBulkImport, action.payload],
      };
    case SET_ALL_UPLOADED_BULK_IMPORT:
      return {
        ...state,
        uploadedBulkImport: action.payload,
      };
    default:
      return state;
  }
};
export const setIsGenralSearchingLoading = (
  state = { isGeneralSearchingLoaing: false },
  action
) => {
  switch (action.type) {
    case IS_GENERAL_SEARCHING_LOADING:
      return { ...state, isGeneralSearchingLoaing: action.payload };
    default:
      return state;
  }
};
export const setGlAccounts = (state = { glAcccounts: [] }, action) => {
  switch (action.type) {
    case GET_GL_ACCOUNTS:
      return { ...state, glAcccounts: action.payload };
    default:
      return state;
  }
};
export const setGeneralSearchQuery = (state = { searchQuery: [] }, action) => {
  switch (action.type) {
    case GET_SEARCH_QUERY:
      return { ...state, searchQuery: action.payload };
    default:
      return state;
  }
};
export const setStatesIds = (state = { statesId: [] }, action) => {
  switch (action.type) {
    case GET_STATES_IDS:
      return { ...state, statesId: action.payload };
    default:
      return state;
  }
};
export const setCreateCustomerDetails = (
  state = { createCustomerDetails: {} },
  action
) => {
  switch (action.type) {
    case GET_CREATE_CUSTOMER_DETAILS:
      return { ...state, createCustomerDetails: action.payload };
    default:
      return state;
  }
};

export const setCountryCodesDetails = (
  state = { countryCodesDetails: {} },
  action
) => {
  switch (action.type) {
    case GET_COUNTRY_CODES_DETAILS:
      return { ...state, countryCodesDetails: action.payload };
    default:
      return state;
  }
};
export const setNotification = (state = { notification: {} }, action) => {
  switch (action.type) {
    case SET_NOTIFICATION:
      return { ...state, notification: action.payload };
    default:
      return state;
  }
};

export const setToolTips = (state = { tooltips: {} }, action) => {
  switch (action.type) {
    case GET_ALL_TOOLTIP:
      return { ...state, tooltips: action.payload };
    default:
      return state;
  }
};

export const setDropdownValueReducer = (
  state = { dropdownValue: [] },
  action
) => {
  switch (action.type) {
    case SET_DROPDOWN_VALUE:
      return { ...state, dropdownValue: action.payload };
    default:
      return state;
  }
};

//GET CUSTOMER charges
export const getPaymentTypesReducer = (
  state = { paymentTypes: [] },
  action
) => {
  switch (action.type) {
    case GET_PAYMENT_TYPES_REQUEST:
      return { loading: true, ...state };
    case GET_PAYMENT_TYPES_SUCCESS:
      return { ...state, loading: false, paymentTypes: action.payload };
    case GET_PAYMENT_TYPES_RESET:
      return {
        loading: false,
        error: null,
        paymentTypes: [],
      };
    // return null
    case GET_PAYMENT_TYPES_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//GET Account Options
export const getSavingsProductOptionsReducer = (
  state = { productOptions: { productOptions: [] } },
  action
) => {
  switch (action.type) {
    case GET_ACCOUNT_OPTIONS_REQUEST:
      return { prodOptionsLoading: true, ...state };
    case GET_ACCOUNT_OPTIONS_SUCCESS:
      return {
        ...state,
        prodOptionsLoading: false,
        productOptions: action.payload,
      };
    case GET_ACCOUNT_OPTIONS_FAIL:
      return { ...state, prodOptionsLoading: false, error: action.payload };
    default:
      return state;
  }
};

//GET SELECTED LOAN OPTION DATA
export const getSelectedSavingsProductOptionsReducer = (
  state = { productOptionsData: { charges: [] } },
  action
) => {
  switch (action.type) {
    case GET_S_PRODUCT_OPTIONS_REQUEST:
      return { loading: true, error: null, ...state };
    case GET_S_PRODUCT_OPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        productOptionsData: action.payload,
        error: null,
      };
    case ADD_SAVINGS_CHARGE:
      return {
        ...state,
        loading: false,
        error: null,
        charges: [...state.productOptionsData.charges, action.payload],
      };
    case GET_S_PRODUCT_OPTIONS_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//GET SAVINGS CHARGE TEMPLATE
export const getSavingChargeReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_S_CHARGE_REQUEST:
      return { loading: true, error: null, ...state };
    case GET_S_CHARGE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        chargeData: action.payload,
      };
    case GET_S_CHARGE_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//GET SAVINGS CHARGE TEMPLATE
export const getLoanChargeReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_L_CHARGE_REQUEST:
      return { loading: true, error: null, ...state };
    case GET_L_CHARGE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        LoanchargeData: action.payload,
      };
    case GET_L_CHARGE_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//GET LOAN PRODUCT Options
export const getLoanProductOptionsReducer = (
  state = { productData: { productOptions: [] } },
  action
) => {
  switch (action.type) {
    case GET_LOAN_OPTIONS_REQUEST:
      return { prodOptionsLoading: true, ...state };
    case GET_LOAN_OPTIONS_SUCCESS:
      return {
        ...state,
        prodOptionsLoading: false,
        productData: action.payload,
      };
    case GET_LOAN_OPTIONS_FAIL:
      return { ...state, prodOptionsLoading: false, error: action.payload };
    default:
      return state;
  }
};

//GET SELECTED LOAN PRODUCT DATA
export const getSelectedLoanProductDataReducer = (
  state = { productData: {} },
  action
) => {
  switch (action.type) {
    case GET_L_PRODUCT_DATA_REQUEST:
      return { loading: true, error: null, ...state };
    case GET_L_PRODUCT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        productData: action.payload,
        error: null,
      };
    case GET_L_PRODUCT_DATA_RESET:
      return { loading: false, error: null, productData: {} };
    // case ADD_LOANS_CHARGE:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: null,
    //     charges: [...state.productData.charges, action.payload],
    //   };
    case GET_L_PRODUCT_DATA_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};


export const getSelectedFixedDepositProductDataReducer = (
  state = { productData: {} },
  action
) => {
  switch (action.type) {
    case GET_FD_PRODUCT_DATA_REQUEST:
      return { loading: true, error: null, ...state };
    case GET_FD_PRODUCT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        productData: action.payload,
        error: null,
      };
    case GET_FD_PRODUCT_DATA_RESET:
      return { loading: false, error: null, productData: {} };
    // case ADD_LOANS_CHARGE:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: null,
    //     charges: [...state.productData.charges, action.payload],
    //   };
    case GET_FD_PRODUCT_DATA_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//GET USER PAYMENT TYPES
export const getUserPaymentTypesReducer = (
  state = { response: {} },
  action
) => {
  switch (action.type) {
    case GET_USER_PAYMENT_TYPES_REQUEST:
      return { loading: true, error: null, ...state };
    case GET_USER_PAYMENT_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        error: null,
      };
    case GET_USER_PAYMENT_TYPES_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
