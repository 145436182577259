import styled from "styled-components";

export const ContentWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 0px #ededf6;
  min-height: 70vh;
  margin-top: 20px;
  padding: 50px;

  p {
    font-size: 13px;
    font-weight: 400;
  }

  .pHeader {
    font-size: 15px;
    font-family: 'AventaBold';
    font-weight: 700;

  }

  .lineHeader {
    font-weight: 600;
    font-family: 'AventaBold';
  }

  .button-right {
    width: 100%;
    height: 50px;
    padding-right: 15px;
  }
`;

export const FormClass = styled.div``;

export const CardDetails = styled.div`
  // margin-left: 130px;

  .dflex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  hr {
    width: 600px;
    height: 1px;
    color: #e1e1e4;
  }
`;

export const DebitCreditCard = styled.div`
  :not(:last-child) {
    border-bottom: 1px solid #c9ced6;
  }
`;


export const NewContentWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 0px #ededf6;
  min-height: 70vh;
  margin-top: 20px;
  padding: 30px 60px 70px 60px;

  p {
    font-size: 18px;
    font-weight: 400;
  }

  .pHeader {
    font-size: 24px;
    font-weight: 700;
  }

  .lineHeader {
    font-weight: 20px;
    font-weight: 600;
  }

  .button-right {
    width: 100%;
    height: 50px;
    padding-right: 15px;
  }
`;