const CustomButton = ({
	id = '',
	buttonClass,
	buttonText,
	onClick,
	icon,
	alt = '',
	otherProps
}) => {
	return (
		<button
			id={id}
			className={`custom-btn ${buttonClass}`}
			onClick={onClick}
			{...otherProps}
		>
			<img src={icon} alt={alt} className="mr-2 checker-icon" />
			{buttonText}
		</button>
	)
}

export default CustomButton