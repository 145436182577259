import { Card } from "antd";
import React, { useEffect, useState } from "react";
// import { Tab, Tabs } from "react-bootstrap";
import { Tab, Tabs } from "antd";
import { Link } from "react-router-dom";
import DownloadReportModal from "../ManageNibssAccount/DownloadModal";
import {
  ActionButton,
  ContentWrapper,
  DownloadButton,
  FilterTransButton,
  TabContentWrapper,
} from "../styles";
import InflowTab from "../Tab/Inflow";
import OutflowTab from "../Tab/Outflow";
import inflow from "../../../../assets/images/icons/inflow-arrow.svg";
import outflow from "../../../../assets/images/icons/outflow-arrow.svg";
import FilterReportModal from "../ManageNibssAccount/DownloadModal";
import { formatDateString } from "../../../../util/FormatDate";
import { useDispatch, useSelector } from "react-redux";
import {
  getNibssConnectTenants,
  getNibssInflowDataFilter,
  getNibssOutflowDataFilter,
} from "../../../../actions/nibssConnectActions";
import InflowFilterReportModal from "../ManageNibssAccount/InflowFilterModal";
import OutFlowFilterReportModal from "../ManageNibssAccount/DownloadModal";
import OutFlowModal from "./OutflowModal";
import InflowModal from "./InflowModal";

function TransactionReportView() {
  const [downloadModal, setDownloadModal] = useState(false);

  const dispatch = useDispatch();

  const nibssTenantsTemplate = useSelector(
    (state) => state.nibssTenantDetailsTemplate
  );

  const [tenantCode, SetTenantCode] = useState("");

  const { nibssTenantsDetailsTemplate: { data = [] } = {} } =
    nibssTenantsTemplate;

  // inflow
  const [toInflowDate, setToInflowDate] = useState("");
  const [fromInflowDate, setFromInflowDate] = useState("");
  const [inflowModal, setInflowModal] = useState(false);
  const handleInflowModalClose = () => setInflowModal(false);

  // outflow
  const [toReportDate, setToReportDate] = useState("");
  const [fromReportDate, setFromReportDate] = useState("");
  const [filterModal, setFilterModal] = useState(false);

  // Get tenant identifier
  const tenantIdentifier2 = localStorage.getItem("tenant");
  // getNibssConnectTenants
  useEffect(() => {
    if (tenantIdentifier2) {
      dispatch(getNibssConnectTenants(tenantIdentifier2));
    }
    // dispatch(getNibssConnectTenants());
  }, [tenantIdentifier2]);

  useEffect(() => {
    if (data) {
      let newTenantCode = data.map((x) => {
        SetTenantCode(x.tenantInstitutionCode);
      });
    }
  }, [data]);

  const onHide = () => {
    setFilterModal(false);
  };

  const handleFilterTrans = () => {
    if (fromReportDate && toReportDate) {
      let startDate = formatDateString(fromReportDate, "yyyy-MMMM-dd");
      let endDate = formatDateString(toReportDate, "yyyy-MMMM-dd");

      dispatch(
        getNibssOutflowDataFilter(
          "orderBy=createdAt&sortBy=DESC&limit=12&offset=0",
          tenantCode,
          startDate,
          endDate,
          onHide
        )
      );
    }
  };

  const handleInwardFilterTrans = () => {
    if (fromInflowDate && toInflowDate) {
      let startDate = formatDateString(fromInflowDate, "yyyy-MMMM-dd");
      let endDate = formatDateString(toInflowDate, "yyyy-MMMM-dd");

      dispatch(
        getNibssInflowDataFilter(
          "orderBy=createdAt&sortBy=DESC&limit=12&offset=0",
          tenantCode,
          startDate,
          endDate,
          handleInflowModalClose
        )
      );
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-5">
            <h3 className="page-title">Transaction report</h3>
            <p className="font-weight Nibss-sub-title">
              View and download your Transaction report <br />
              <span className="text-info">
                {/* <Link to="/core/configuration">Configuration</Link> /{" "} */}
                <Link to="/core/nibss-management">NIBSS Integration</Link>
              </span>{" "}
              / Transaction Report
            </p>
          </div>
        </div>
        <TabContentWrapper>
          {/* inflow / outflow */}
          <div className="col-sm-12 col-md-12 pb-4 ">
            <div className="row d-flex">
              <div className="col-sm-12 col-md-12">
                <TabContentWrapper>
                  <Tabs defaultActiveKey="1">
                    <Tabs.TabPane
                      tab={
                        <span className="inflowArrow">
                          Inflow
                          <img src={inflow} alt="" style={{ width: "10px" }} />
                        </span>
                      }
                      key="1"
                    >
                      <InflowTab
                        toInflowDate={toInflowDate}
                        setToInflowDate={setToInflowDate}
                        fromInflowDate={fromInflowDate}
                        setFromInflowDate={setFromInflowDate}
                        onHide={handleInflowModalClose}
                        setInflowModal={setInflowModal}
                        inflowModal={inflowModal}
                        handleInwardFilterTrans={handleInwardFilterTrans}
                        // history={history}
                        // userData={singleUser}
                        tenantCode={tenantCode}
                      />
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      tab={
                        <span className="outflowArrow">
                          Outflow
                          <img src={outflow} alt="" style={{ width: "10px" }} />
                        </span>
                      }
                      key="2"
                    >
                      <OutflowTab
                        toReportDate={toReportDate}
                        fromReportDate={fromReportDate}
                        handleFilterTrans={handleFilterTrans}
                        onHide={onHide}
                        setFilterModal={setFilterModal}
                        filterModal={filterModal}
                        tenantCode={tenantCode}

                        // userData={singleUser}
                        // history={history}
                      />
                    </Tabs.TabPane>
                  </Tabs>
                </TabContentWrapper>
              </div>
              {/* Download History */}
            </div>
          </div>
        </TabContentWrapper>

        <InflowModal
          onHide={handleInflowModalClose}
          setInflowModal={setInflowModal}
          inflowModal={inflowModal}
          toInflowDate={toInflowDate}
          setToInflowDate={setToInflowDate}
          fromInflowDate={fromInflowDate}
          setFromInflowDate={setFromInflowDate}
          handleInwardFilterTrans={handleInwardFilterTrans}
          tenantCode={tenantCode}
        />

        <OutFlowModal
          filterModal={filterModal}
          onHide={onHide}
          handleFilterTrans={handleFilterTrans}
          toReportDate={toReportDate}
          setToReportDate={setToReportDate}
          fromReportDate={fromReportDate}
          setFromReportDate={setFromReportDate}
          tenantCode={tenantCode}
        />
      </div>
    </>
  );
}

export default TransactionReportView;
