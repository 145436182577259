import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import DashboardLayout from "../../../layouts/dashboardLayout/index.jsx";
import LoadingScreen from "./LoadingScreen";
import { Badge } from "@chakra-ui/react";
import { ContentWrapper, ContentCard } from "./styles";

// import { Card } from "react-bootstrap";

const AccountingView = ({ history }) => {
  const [loading, setloading] = useState(true);
  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  const CardListings = [
    {
      icon: require("../../../assets/images/icons/frequent-posting.svg")
        .default,
      title: "Frequent Posting",
      description: "Predefined postings",
      route: "/core/accounting/frequent-posting",
      show: permissions
        ? permissions.some(
            (per) => per === "CREATE_JOURNALENTRY" || per === "ALL_FUNCTIONS"
          )
        : false,
    },
    // {
    //   icon: require("../../../assets/images/icons/add-journal-entries.svg")
    //     .default,
    //   title: "Add Journal Entries",
    //   description: "Manual add journal entry transaction recorded in a journal",
    //   route: "/core/accounting/add-journal-entries",
    //   show: permissions
    //     ? permissions.some(
    //         (per) => per === "CREATE_JOURNALENTRY" || per === "ALL_FUNCTIONS"
    //       )
    //     : false,
    // },
    {
      icon: require("../../../assets/images/icons/add-journal-entries.svg")
        .default,
      title: "Account To Journal Entries",
      description:
        "Manual account journal entry transaction",
      route: "/core/accounting/account-to-journal-entry",
      show: true,
      // hasBeta: true,
    },
    // {
    //   icon: require("../../../assets/images/icons/search-journal-entries.svg")
    //     .default,
    //   title: "Search Journal Entries",
    //   description: "Advances search option for journal entries",
    //   route: "/core/accounting/search-journal-entries",
    //   show: permissions
    //     ? permissions.some(
    //         (per) => per === "READ_JOURNALENTRY" || per === "ALL_FUNCTIONS"
    //       )
    //     : false,
    // },
    {
      icon: require("../../../assets/images/icons/accounts-linked-to-financial-activities.svg")
        .default,
      title: "Accounts linked to Financial activities",
      description: "List of financial activity &  GL account matching",
      route: "/core/accounting/financial-activities",
      show: permissions
        ? permissions.some(
            (per) =>
              per === "READ_FINANCIALACTIVITYACCOUNT" || per === "ALL_FUNCTIONS"
          )
        : false,
    },
    // {
    //   icon: require("../../../assets/images/icons/chart-of-accounts.svg")
    //     .default,
    //   title: "Chart of accounts",
    //   description: "List of accounts used by the organization",
    //   route: "/core/accounting/charts-of-account",
    //   show: permissions
    //     ? permissions.some(
    //         (per) => per === "READ_GLACCOUNT" || per === "ALL_FUNCTIONS"
    //       )
    //     : false,
    // },
    {
      icon: require("../../../assets/images/icons/closing-entries.svg").default,
      title: "Closing Entries",
      description: "Journal entries made at the end of a accounting peroid",
      route: "/core/accounting/closing-entries",
      show: permissions
        ? permissions.some(
            (per) => per === "READ_GLCLOSURE" || per === "ALL_FUNCTIONS"
          )
        : false,
    },
    {
      icon: require("../../../assets/images/icons/accounting-rules.svg")
        .default,
      title: "Accounting Rules",
      description: "List all accounting rules",
      route: "/core/accounting/rules",
      show: permissions
        ? permissions.some(
            (per) => per === "READ_ACCOUNTINGRULE" || per === "ALL_FUNCTIONS"
          )
        : false,
    },
    // {
    //   icon: require("../../../assets/images/icons/provisioning-entries.svg")
    //     .default,
    //   title: "Provisioning Entries",
    //   description: "Creating provisioning entries",
    //   route: "/core/accounting/provisioning-entries",
    // },
    // {
    //   icon: require("../../../assets/images/icons/amortization.svg").default,
    //   title: "Amortizations",
    //   description: "Manage amotization postings",
    //   route: "/core/accounting/amortizations",
    //   show: true,
    // },
    // {
    //   icon: require("../../../assets/images/icons/amortization.svg").default,
    //   title: "Manage Placements",
    //   description: "Manage placements postings",
    //   route: "/core/accounting/placement",
    //   show: true,
    // },
  ];

  useEffect(() => {
    setTimeout(() => {
      setloading(false);
    }, 1000);
  }, []);

  return !!loading ? (
    <LoadingScreen />
  ) : (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Accounting</h3>
            <p className="sub-title">
              The financial transactions records pertaining to your business
            </p>
          </div>
        </div>
        <ContentWrapper>
          <div className="row">
            {CardListings.map(
              ({ icon, title, description, route, show, hasBeta }, i) =>
                show ? (
                  <div
                    className="col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-4"
                    key={i}
                    onClick={() => history.push(route)}
                  >
                    <ContentCard>
                      <img className="card-img-right" src={icon} alt={title} />
                      <div className="body relative">
                        <p className="title">{title}</p>
                        {hasBeta ? (
                          <Badge
                            className="position-absolute"
                            style={{
                              fontSize: 8,
                              top: 2,
                              right: 3,
                              padding: "0 2px",
                              background: "none",
                              color: "#17a2b8",
                              border: "1px solid #17a2b8",
                              borderRadius: 16,
                            }}
                            colorScheme="green"
                          >
                            Beta
                          </Badge>
                        ) : null}
                        <p className="description">{description}</p>
                      </div>
                    </ContentCard>
                  </div>
                ) : (
                  ""
                )
            )}
          </div>
        </ContentWrapper>
      </div>
    </>
  );
};

export default AccountingView;
