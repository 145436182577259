import React from "react";

import { formatDate } from "../../../../../../util/FormatDate";
import { formatCurrency } from "../../../../../../util/FormatAmount";
import { textToUpperCase } from "../../../../../../util/Tools";
import { ActionButton, FormTitle } from "../../styles";

const AmortizationReview = ({
  currency,
  handlePreviousScreen,
  office,
  amount,
  currencies,
  offices,
  noOfDays,
  credits,
  debits,
  fromDate,
  comment,
  glDebits,
  glCredits,
  transDate,
  refNumber,
  glAcccounts,
  handleSubmitPlacement,
}) => {
  let mycurrency = "";
  currencies.forEach((cur) => {
    if (cur.code === currency) {
      mycurrency = cur.displayLabel;
    }
  });
  let myOffice = "";
  offices.forEach((cur) => {
    if (cur.id === Number(office)) {
      myOffice = cur.name;
    }
  });
  let myCredits = [];
  glAcccounts.forEach((glacc) => {
    credits.forEach(({ glAccountId }) => {
      if (glacc.id === Number(glAccountId)) {
        myCredits.push(glacc);
      }
    });
  });
  let myGLCredits = [];
  glAcccounts.forEach((glacc) => {
    glCredits.forEach(({ glAccountId }) => {
      if (glacc.id === Number(glAccountId)) {
        myGLCredits.push(glacc);
      }
    });
  });
  let myGLDebits = [];
  glAcccounts.forEach((glacc) => {
    glDebits.forEach(({ glAccountId }) => {
      if (glacc.id === Number(glAccountId)) {
        myGLDebits.push(glacc);
      }
    });
  });
  let myDebits = [];
  glAcccounts.forEach((glacc) => {
    debits.forEach(({ glAccountId }) => {
      if (glacc.id === Number(glAccountId)) {
        myDebits.push(glacc);
      }
    });
  });
  return (
    <>
      <div className="col-sm-12 col-md-12 d-flex">
        <div className="container" id="review-list">
          <div>
            <FormTitle>
              <p className="text-info">Placement Details</p>
              <div className="lineThrough"></div>
            </FormTitle>
            <div className="pt-4">
              <div
                className="form-group d-flex  align-items-center"
                style={{ marginBottom: "1rem !important" }}
              >
                <h3>Office</h3>
                <p style={{ width: "60%" }}>{myOffice}</p>
              </div>
              <div
                className="form-group d-flex  align-items-center"
                style={{ marginBottom: "1rem !important" }}
              >
                <h3>Currency</h3>
                <p style={{ width: "60%" }}>{mycurrency}</p>
              </div>{" "}
              <div
                className="form-group d-flex  align-items-center"
                style={{ marginBottom: "1rem !important" }}
              >
                <h3>Credits</h3>
                <p style={{ width: "60%" }}>
                  {glCredits.map(({ amount, glAccountId }) => {
                    return (
                      <div className="">
                        {textToUpperCase(myGLCredits[0].name)} -{" "}
                        {formatCurrency(Number(amount))}
                      </div>
                    );
                  })}
                </p>
              </div>
              <div
                className="form-group d-flex  align-items-center"
                style={{ marginBottom: "1rem !important" }}
              >
                <h3>Debits</h3>
                <p style={{ width: "60%" }}>
                  {glDebits.map(({ amount, glAccountId }) => {
                    return (
                      <div className="">
                        {textToUpperCase(myGLDebits[0].name)} -{" "}
                        {formatCurrency(Number(amount))}
                      </div>
                    );
                  })}
                </p>
              </div>
              <div
                className="form-group d-flex  align-items-center"
                style={{ marginBottom: "1rem !important" }}
              >
                <h3>Transaction Date</h3>
                <p style={{ width: "60%" }}>{formatDate(transDate)}</p>
              </div>{" "}
              <div
                className="form-group d-flex  align-items-center"
                style={{ marginBottom: "1rem !important" }}
              >
                <h3>Refrenece Number</h3>
                <p style={{ width: "60%" }}>{refNumber}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-12 col-md-12 d-flex">
        <div className="container" id="review-list">
          <div>
            <FormTitle>
              <p className="text-info">Interest Tracker</p>
              <div className="lineThrough"></div>
            </FormTitle>
            <div className="pt-4">
              <div
                className="form-group d-flex  align-items-center"
                style={{ marginBottom: "1rem !important" }}
              >
                <h3>Amount</h3>
                <p style={{ width: "60%" }}>{formatCurrency(Number(amount))}</p>
              </div>{" "}
              <div
                className="form-group d-flex  align-items-center"
                style={{ marginBottom: "1rem !important" }}
              >
                <h3>Number of Days</h3>
                <p style={{ width: "60%" }}>{noOfDays}</p>
              </div>
              <div
                className="form-group d-flex  align-items-center"
                style={{ marginBottom: "1rem !important" }}
              >
                <h3>Credits</h3>
                <p style={{ width: "60%" }}>
                  {myCredits.map(({ name, glCode }) => {
                    return (
                      <div className="">
                        {textToUpperCase(name)} - {glCode}
                      </div>
                    );
                  })}
                </p>
              </div>
              <div
                className="form-group d-flex  align-items-center"
                style={{ marginBottom: "1rem !important" }}
              >
                <h3>Debits</h3>
                <p style={{ width: "60%" }}>
                  {myDebits.map(({ name, glCode }) => {
                    return (
                      <div className="">
                        {textToUpperCase(name)} - {glCode}
                      </div>
                    );
                  })}
                </p>
              </div>
              <div
                className="form-group d-flex  align-items-center"
                style={{ marginBottom: "1rem !important" }}
              >
                <h3>Start Date</h3>
                <p style={{ width: "60%" }}>{formatDate(fromDate)}</p>
              </div>{" "}
              <div
                className="form-group d-flex  align-items-center"
                style={{ marginBottom: "1rem !important" }}
              >
                <h3>Comment</h3>
                <p style={{ width: "60%" }}>{comment}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="float-right pt-5">
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={() => {
              handlePreviousScreen();
            }}
          >
            <img
              src={
                require("../../../../../../assets/images/icons/arrow-left.svg")
                  .default
              }
              alt="submit icon"
            />
            Previous
          </ActionButton>
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={() => {
              handleSubmitPlacement();
            }}
          >
            <img
              src={
                require("../../../../../../assets/images/icons/save.svg")
                  .default
              }
              alt="submit icon"
            />
            Submit
          </ActionButton>
        </div>
      </div>
    </>
  );
};

export default AmortizationReview;
