/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Select } from "@chakra-ui/react";
import { ActionButton } from "../styles";
import DatePicker from "react-datepicker";
import { getAllAudit } from "../../../../../actions/configurationActions";
import { formatDate } from "../../../../../util/FormatDate";

const SearchInputs = ({ setSearchState }) => {
  const dispatch = useDispatch();

  const auditTemplateReducer = useSelector((state) => state.auditTemplate);
  let { auditTemplate = {} } = auditTemplateReducer;

  const [action, setAction] = useState("");
  const [checker, setChecker] = useState("");
  const [status, setStatus] = useState("");
  const [entity, setEntity] = useState("");
  const [user, setUser] = useState("");
  const [resourceId, setResourceId] = useState("");

  const [fromDate, handleFromDate] = useState("");
  const [checkFromDate, handleCheckFromDate] = useState("");
  const [toDate, handleToDate] = useState("");
  const [checkToDate, handleCheckToDate] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();

    dispatch(
      getAllAudit(
        action,
        checkFromDate ? formatDate(checkFromDate, true) : "",
        checkToDate ? formatDate(checkToDate, true) : "",
        checker,
        entity,
        fromDate ? formatDate(fromDate, true) : "",
        toDate ? formatDate(toDate, true) : "",
        user,
        resourceId,
        status
      )
    );
    setSearchState(2);
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-3 col-md-3 col-sm-12 mt-4">
          <div className="mb-4">
            <Input
              size="md"
              value={user}
              onChange={(e) => setUser(e.target.value)}
              placeholder="Search by user"
            />
          </div>
          <Input
            size="md"
            value={resourceId}
            onChange={(e) => setResourceId(e.target.value)}
            placeholder="Resource id"
          />
        </div>
        <div className="col-xl-3 col-md-3 col-sm-12 mt-4">
          <div className="mb-4">
            <DatePicker
              id="from-date"
              selected={fromDate}
              onChange={(date) => handleFromDate(date)}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText="From date"
            />
          </div>

          <DatePicker
            id="to-date"
            selected={toDate}
            onChange={(date) => handleToDate(date)}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            placeholderText="To date"
          />
        </div>
        <div className="col-xl-3 col-md-3 col-sm-12 mt-4">
          <div className="mb-4">
            <DatePicker
              id="checked-from-date"
              selected={checkFromDate}
              onChange={(date) => handleCheckFromDate(date)}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText="Checked from"
            />
          </div>
          <DatePicker
            id="checked-to-date"
            selected={checkToDate}
            onChange={(date) => handleCheckToDate(date)}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            placeholderText="Checked To"
          />
        </div>
        <div className="col-xl-3 col-md-3 col-sm-12 mt-4">
          <div className="mb-4">
            <Select
              placeholder="--Select Status--"
              size="md"
              className="text-capitalize"
              name="accName"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              {!!auditTemplate ? (
                auditTemplate.processingResults ? (
                  auditTemplate.processingResults.map((item) => {
                    return (
                      <option value={item.id} key={item.id}>
                        {item.processingResult}
                      </option>
                    );
                  })
                ) : (
                  ""
                )
              ) : (
                <option>Loading</option>
              )}
            </Select>
          </div>
          <Select
            placeholder="--Select entity--"
            size="md"
            name="entity"
            value={entity}
            onChange={(e) => setEntity(e.target.value)}
          >
            {!!auditTemplate ? (
              auditTemplate.entityNames ? (
                auditTemplate.entityNames.map((item) => {
                  return (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  );
                })
              ) : (
                ""
              )
            ) : (
              <option>Loading</option>
            )}
          </Select>
        </div>
        <div className="col-xl-3 col-md-3 col-sm-12 mt-4">
          <Select
            placeholder="--Select action--"
            size="md"
            name="action"
            value={action}
            onChange={(e) => setAction(e.target.value)}
          >
            {!!auditTemplate ? (
              auditTemplate.actionNames ? (
                auditTemplate.actionNames.map((item) => {
                  return (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  );
                })
              ) : (
                ""
              )
            ) : (
              <option>Loading</option>
            )}
          </Select>
        </div>
        <div className="col-xl-3 col-md-3 col-sm-12 mt-4">
          <Select
            placeholder="--Select checker--"
            size="md"
            name="checker"
            className="text-capitalize"
            value={checker}
            onChange={(e) => setChecker(e.target.value)}
          >
            {!!auditTemplate ? (
              auditTemplate.appUsers ? (
                auditTemplate.appUsers.map((item) => {
                  return (
                    <option
                      className="text-capitalize"
                      value={item.id}
                      key={item.id}
                    >
                      {item.username}
                    </option>
                  );
                })
              ) : (
                ""
              )
            ) : (
              <option>Loading</option>
            )}
          </Select>
        </div>
      </div>
      <div className="">
        <ActionButton
          color="#2C1DFF"
          className="float-right"
          style={{ margin: "0px 0px" }}
          onClick={(e) => handleSearch(e)}
        >
          <img
            src={
              require("../../../../../assets/images/icons/searchWhite.svg")
                .default
            }
            className="d-inline mr-3"
            alt="icon"
          />
          Search
        </ActionButton>
      </div>
    </>
  );
};

export default SearchInputs;
