import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ContentWrapper } from "../styles";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../../../../../util/FormatDate";
import ScrollToTop from "../../../../../components/ScrollToTop/index";

import {
  getOffices,
  getCurriencies,
  getPaymentTypes,
} from "../../../../../actions/accountingActions";

import { getGlAccounts } from "../../../../../actions/utilityActions";
import { createAnPlacement } from "../../../../../actions/accountingActions";

import AppDetails from "./components/AppDetails";
import AppReview from "./components/AppReview";
import AppInterest from "./components/AppInterest";
import PlacementDetails from "./components/PlacementDetails";
import PlacementReview from "./components/PlacementReview";
import PlacementInterest from "./components/PlacementInterest";

const CreatePlacement = ({ history }) => {
  const dispatch = useDispatch();

  const accountingData = useSelector((state) => state.accountingData);
  const glAccounts = useSelector((state) => state.glAccounts);

  let { glAcccounts = [] } = glAccounts || {};

  let {
    currencies = [],
    offices = [],
    paymentTypes = [],
  } = accountingData || {};

  const defaultCredit = { glAccountId: "" };
  const defaultGlCredit = { glAccountId: "", amount: "" };
  const defaultGlDebit = { glAccountId: "", amount: "" };
  const defaultDebit = { glAccountId: "" };
  const [currentScreen, setCurrentScreen] = useState(0);
  const [office, setOffice] = useState("");
  const [currency, setCurrency] = useState("");
  const [amount, setAmount] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [noOfDays, setNoOfDays] = useState("");
  const [refNumber, setRefNumber] = useState("");
  const [transDate, setTransaDate] = useState("");
  const [comment, setComment] = useState("");
  const [detailsError, setDetailsError] = useState(false);
  const [credits, setCredits] = useState([defaultCredit]);
  const [glCredits, setGlCredits] = useState([defaultGlCredit]);
  const [debits, setDebits] = useState([defaultDebit]);
  const [glDebits, setGlDebits] = useState([defaultGlDebit]);

  useEffect(() => {
    dispatch(getCurriencies());
    dispatch(getOffices());
    dispatch(getPaymentTypes());
    dispatch(getGlAccounts());
  }, [dispatch]);

  const handleSubmitPlacement = () => {
    let journalEntry = {
      officeId: office,
      transactionDate: formatDate(transDate, true),
      locale: "en",
      currencyCode: currency,
      dateFormat: "dd MMMM yyyy",
      credits: glCredits,
      debits: glDebits,
      referenceNumber: refNumber,
    };
    let dataToSend = {
      credits,
      debits,
      officeId: office,
      amount,
      numberOfDays: noOfDays,
      currencyCode: currency,
      startDate: formatDate(fromDate, true),
      locale: "en",
      dateFormat: "dd MMMM yyyy",
      comments: comment,
      type: "placement",
    };
    createAnPlacement(journalEntry, dataToSend, dispatch, history);
  };

  const renderComponent = () => {
    let currentComponet = "";

    switch (currentScreen) {
      case 0:
        currentComponet = (
          <AppDetails error={detailsError}>
            <PlacementDetails
              office={office}
              setOffice={setOffice}
              currency={currency}
              setCurrency={setCurrency}
              currencies={currencies}
              offices={offices}
              paymentTypes={paymentTypes}
              glAcccounts={glAcccounts}
              glCredits={glCredits}
              setGlCredits={setGlCredits}
              setGlDebits={setGlDebits}
              glDebits={glDebits}
              refNumber={refNumber}
              setRefNumber={setRefNumber}
              setTransaDate={setTransaDate}
              transDate={transDate}
              setDetailsError={setDetailsError}
              handleNextScreen={handleNextCustomIndexSelect}
              handlePreviousScreen={handlePreviousCustomIndexSelect}
            />
          </AppDetails>
        );
        break;
      case 1:
        currentComponet = (
          <AppInterest error={detailsError}>
            <PlacementInterest
              office={office}
              setOffice={setOffice}
              currency={currency}
              setCurrency={setCurrency}
              currencies={currencies}
              offices={offices}
              paymentTypes={paymentTypes}
              amount={amount}
              setAmount={setAmount}
              fromDate={fromDate}
              setFromDate={setFromDate}
              noOfDays={noOfDays}
              setNoOfDays={setNoOfDays}
              comment={comment}
              setComment={setComment}
              glAcccounts={glAcccounts}
              credits={credits}
              setCredits={setCredits}
              setDebits={setDebits}
              debits={debits}
              setDetailsError={setDetailsError}
              handleNextScreen={handleNextCustomIndexSelect}
              handlePreviousScreen={handlePreviousCustomIndexSelect}
            />
          </AppInterest>
        );
        break;
      case 2:
        currentComponet = (
          <AppReview error={detailsError}>
            <PlacementReview
              office={office}
              currency={currency}
              amount={amount}
              currencies={currencies}
              offices={offices}
              noOfDays={noOfDays}
              credits={credits}
              debits={debits}
              fromDate={fromDate}
              comment={comment}
              glAcccounts={glAcccounts}
              glDebits={glDebits}
              glCredits={glCredits}
              transDate={transDate}
              refNumber={refNumber}
              handleSubmitPlacement={handleSubmitPlacement}
              handlePreviousScreen={handlePreviousCustomIndexSelect}
            />
          </AppReview>
        );
        break;
      default:
    }
    return currentComponet;
  };

  const handleNextCustomIndexSelect = () => {
    let newIndex = currentScreen + 1;
    if (newIndex <= 2) {
      setCurrentScreen(newIndex);
    }
  };
  // FUNCTION HERE FOR MOVING TO THE PREVIOUS PAGE
  const handlePreviousCustomIndexSelect = () => {
    let newIndex = currentScreen - 1;
    // console.log(newIndex, "and", currentScreen);
    if (newIndex >= 0) {
      setCurrentScreen(newIndex);
    }
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12  col-md-12 col-xl-6 pb-3">
            <h3 className="page-title">Create Placement</h3>
            <p className="sub-title">Manage placement postings</p>
            <p className="sub-title mt-2">
              <span className="text-info mr-1">
                <Link to="/core/accounting">Accounting</Link>
              </span>
              /{" "}
              <span className="text-info mr-1">
                <Link to="/core/accounting/placement">Placement</Link>
              </span>
              / Create Placement
            </p>
          </div>
        </div>
        <div className="row" style={{ minHeight: "calc(100vh - 250px)" }}>
          <div className="col-sm-12 col-md-12">
            <ScrollToTop>
              <ContentWrapper className="application-wrapper">
                {renderComponent()}
              </ContentWrapper>
            </ScrollToTop>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatePlacement;
