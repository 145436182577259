import React from "react";
import { Modal } from "react-bootstrap";

import { ActionButton } from "../styles";

const DeleteModal = ({ onHide, action, tableName }) => {
  return (
    <>
      <Modal.Header>
        <Modal.Title>Delete Data Table Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h3 className="p-4">Are you sure you want to delete this information from {tableName} data table?</h3>
      </Modal.Body>
      <Modal.Footer>
        <div className="spaceBetweenFlexEnd mt-3">
          <div className="">
            <ActionButton color="#2C1DFF" onClick={onHide}>
              <img
                src={
                  require("../../../../../assets/images/icons/cancel.svg")
                    .default
                }
                alt="cancel icon"
              />
              Cancel
            </ActionButton>
          </div>
          <div className="">
            <ActionButton color="#2C1DFF" onClick={action}>
              <img
                src={
                  require("../../../../../assets/images/icons/delete.svg")
                    .default
                }
                alt="cancel icon"
              />
              Delete
            </ActionButton>
          </div>
        </div>
      </Modal.Footer>
    </>
  );
};

export default DeleteModal;
