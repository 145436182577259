import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Input, Select, Textarea, Checkbox } from "@chakra-ui/react";
import { FormElements, ActionButton } from "../../styles";
import { ContentWrapper } from "./styles";
import {
  getAllChartsOfAccountTemplate,
  addNewChartsOfAccount,
} from "../../../../../actions/accountingActions";
import Toast from "../../../../../util/Toast";
// import { Checkbox } from "antd";

import { generateGlCode } from "../../../../../actions/utilityActions";
import infoBg from "../../../../../assets/images/icons/info-circle.svg";

const CreateGlAccount = ({ history }) => {
  const dispatch = useDispatch();
  const accountingData = useSelector((state) => state.accountingData);

  let { chartsOfAccountTemplate, chartOfAccountParent = {} } =
    accountingData || {};

  let { accountTypeOptions = [], usageOptions = [] } =
    chartsOfAccountTemplate || {};

  const [accType, setAccType] = useState();
  const [accUsage, setAccUsage] = useState("");
  const [glCode, setGlCode] = useState("");
  const [parent, setParent] = useState(
    !!chartOfAccountParent.value ? chartOfAccountParent.value : ""
  );
  const [accName, setAccName] = useState("");
  const [manualEntryAllowed, setManualEntryAllowed] = useState();
  const [tag, setTag] = useState("");
  const [description, setDescription] = useState("");
  const [parentOptions, setParentOptions] = useState("");

  const [isSystemDefined, setIsSystemDefined] = useState(false);

  const [tagOptions, setTagOptions] = useState("");
  // const [tag, setTag] = useState("")

  useEffect(() => {
    if (chartOfAccountParent) {
      setParent(chartOfAccountParent && chartOfAccountParent.parentId);
      setAccType(
        chartOfAccountParent &&
          chartOfAccountParent.type &&
          chartOfAccountParent.type.id
      );
    }
  }, [chartOfAccountParent]);

  const handleGenerateGlCode = () => {
    let mainAccType = accountTypeOptions.filter(
      (item) => item.id === Number(accType)
    );
    if (mainAccType && mainAccType[0] && mainAccType[0].value) {
      dispatch(generateGlCode(mainAccType[0].value, setGlCode));
    } else {
      dispatch(Toast({ text: "Please select an account type", icon: "info" }));
    }
  };

  useEffect(() => {
    if (chartsOfAccountTemplate || accType) {
      if (Number(accType) === 1) {
        setParentOptions(
          chartsOfAccountTemplate &&
            chartsOfAccountTemplate.assetHeaderAccountOptions
        );
      } else if (Number(accType) === 2) {
        setParentOptions(
          chartsOfAccountTemplate &&
            chartsOfAccountTemplate.liabilityHeaderAccountOptions
        );
      } else if (Number(accType) === 3) {
        setParentOptions(
          chartsOfAccountTemplate &&
            chartsOfAccountTemplate.equityHeaderAccountOptions
        );
      } else if (Number(accType) === 4) {
        setParentOptions(
          chartsOfAccountTemplate &&
            chartsOfAccountTemplate.incomeHeaderAccountOptions
        );
      } else if (Number(accType) === 5) {
        setParentOptions(
          chartsOfAccountTemplate &&
            chartsOfAccountTemplate.expenseHeaderAccountOptions
        );
      }
    }
  }, [chartsOfAccountTemplate, accType]);

  useEffect(() => {
    if (chartsOfAccountTemplate || accType) {
      if (Number(accType) === 1) {
        setTagOptions(
          chartsOfAccountTemplate &&
            chartsOfAccountTemplate.allowedAssetsTagOptions
        );
      } else if (Number(accType) === 2) {
        setTagOptions(
          chartsOfAccountTemplate &&
            chartsOfAccountTemplate.allowedLiabilitiesTagOptions
        );
      } else if (Number(accType) === 3) {
        setTagOptions(
          chartsOfAccountTemplate &&
            chartsOfAccountTemplate.allowedEquityTagOptions
        );
      } else if (Number(accType) === 4) {
        setTagOptions(
          chartsOfAccountTemplate &&
            chartsOfAccountTemplate.allowedIncomeTagOptions
        );
      } else if (Number(accType) === 5) {
        setTagOptions(
          chartsOfAccountTemplate &&
            chartsOfAccountTemplate.allowedExpensesTagOptions
        );
      }
    }
  }, [chartsOfAccountTemplate, accType]);

  useEffect(() => {
    if (accType === 1) {
    }
  }, [accType]);


  const handleCreateGlAccount = (e) => {
    e.preventDefault();
    if (!accType) {
      dispatch(Toast({ text: "Please select an account type", icon: "info" }));
    } else if (!accUsage) {
      dispatch(Toast({ text: "Please select account usage", icon: "info" }));
    } else if (accUsage === "2" && manualEntryAllowed === "true") {
      dispatch(
        Toast({
          text: "Account Header does not allow manual entry",
          icon: "info",
        })
      );
    } else if (!glCode) {
      dispatch(Toast({ text: "Please add a Gl Code", icon: "info" }));
    } else if (!accName) {
      dispatch(Toast({ text: "Please add an account name", icon: "info" }));
    } else if (!tag) {
      dispatch(Toast({ text: "Please Select a Tag", icon: "info" }));
    } else {
      let dataToSend = {
        description,
        glCode,
        manualEntriesAllowed: manualEntryAllowed,
        name: accName.toUpperCase(),
        type: accType,
        usage: accUsage,
        parentId: parent,
        tagId: tag,
        isSystemDefined
      };
      dispatch(addNewChartsOfAccount(dataToSend, history));
    }
  };

  useEffect(() => {
    dispatch(getAllChartsOfAccountTemplate());
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Create GL Account</h3>
            <p className="sub-title mt-2">
              <span className="text-info mr-1">
                <Link to="/core/charts-of-account">Charts of Accounts</Link>
              </span>
              / Create Gl Account
            </p>
          </div>
        </div>
        <ContentWrapper>
          <div className="row h-100">
            <div className="col-md-12 col-xl-6">
              <FormElements>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Account Type*</label>
                  <Select
                    placeholder="Select Account Type"
                    size="md"
                    name="accType"
                    value={accType}
                    onChange={(e) => setAccType(e.target.value)}
                  >
                    {!!accountTypeOptions ? (
                      accountTypeOptions.map(({ id, code, value }) => {
                        return (
                          <option key={id} value={id}>
                            {value}
                          </option>
                        );
                      })
                    ) : (
                      <option>Loading...</option>
                    )}
                  </Select>
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Account Usage*</label>
                  <Select
                    placeholder="Select Header or Detail"
                    size="md"
                    name="accUseage"
                    value={accUsage}
                    onChange={(e) => setAccUsage(e.target.value)}
                  >
                    {!!usageOptions ? (
                      usageOptions.map(({ id, code, value }) => {
                        return (
                          <option key={id} value={id}>
                            {value}
                          </option>
                        );
                      })
                    ) : (
                      <option>loading...</option>
                    )}
                  </Select>
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label w-50">Gl Code*</label>
                  <Input
                    style={{ width: "63%" }}
                    size="md"
                    name="glCode"
                    // value={glCode.length <= 8 || >= 10 }
                    value={glCode}
                    onChange={(e) => setGlCode(e.target.value)}
                  />
                  <span
                    className="pointer d-flex btn-gl-code"
                    onClick={() => handleGenerateGlCode()}
                  >
                    <img
                      src={
                        require("../../../../../assets/images/icons/generateCode.svg")
                          .default
                      }
                      className="icon-img"
                      alt="mark icon"
                    />
                  </span>
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Tags*</label>
                  <Select
                    placeholder="Select Tag"
                    size="md"
                    name="tag"
                    value={tag}
                    onChange={(e) => setTag(e.target.value)}
                  >
                    {!!tagOptions && tagOptions.length
                      ? tagOptions.map((tag) => {
                          return (
                            <option value={tag.id} key={tag.id}>
                              {tag.name}
                            </option>
                          );
                        })
                      : null}
                  </Select>
                </div>
              </FormElements>
            </div>{" "}
            <div className="col-md-12 col-xl-6">
              <FormElements>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Parent</label>
                  <Select
                    placeholder="Select Parent"
                    size="md"
                    name="parent"
                    value={parent}
                    onChange={(e) => setParent(e.target.value)}
                  >
                    {!!parentOptions && parentOptions.length
                      ? parentOptions.map(({ id, name, type }) => {
                          return (
                            <option key={id} value={id}>
                              {name}
                            </option>
                          );
                        })
                      : null}
                    {/* <option>parent 1</option>
                    <option>parent 2</option> */}
                  </Select>
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Account Name*</label>
                  <Input
                    style={{ width: "60%", textTransform: "uppercase" }}
                    size="md"
                    name="accName"
                    value={accName}
                    onChange={(e) => setAccName(e.target.value)}
                  />
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Manual entries allowed</label>
                  <Select
                    placeholder="Select Yes or No"
                    size="md"
                    name="manualEntryAllowed"
                    value={manualEntryAllowed}
                    onChange={(e) => setManualEntryAllowed(e.target.value)}
                  >
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </Select>
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">
                    Add system defined ledger
                  </label>
                  <div className="flexBox-info">
                    <Checkbox
                      size="md"
                      name="isSystemDefinedLedger"
                      isChecked={isSystemDefined}
                      onChange={(e) => setIsSystemDefined(e.target.checked)}
                    />
                    <div className="info-system" title="Postings are only allowed via APIs and manual entries should be allowed">
                      <img src={infoBg} alt="info" />
                    </div>
                  </div>
                </div>
              </FormElements>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormElements>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Description</label>
                  <Textarea
                    size="md"
                    style={{
                      width: "80%",
                      marginTop: "20px",
                      fontSize: "12px",
                    }}
                    resize={"none"}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </FormElements>
            </div>
          </div>
          <div className="row">
            <div className="button-right">
              <ActionButton
                color="#2C1DFF"
                className="float-right"
                style={{ margin: "0px 25px" }}
                onClick={(e) => handleCreateGlAccount(e)}
              >
                <img
                  src={
                    require("../../../../../assets/images/icons/check-mark.svg")
                      .default
                  }
                  alt="mark icon"
                />
                Submit
              </ActionButton>
              <ActionButton
                color="#2C1DFF"
                className="float-right"
                style={{ margin: "0px 0px" }}
                onClick={() => history.push("/core/charts-of-account")}
              >
                <img
                  src={
                    require("../../../../../assets/images/icons/cancel.svg")
                      .default
                  }
                  alt="add icon"
                />
                Cancel
              </ActionButton>
            </div>
          </div>
        </ContentWrapper>
      </div>
    </>
  );
};

export default CreateGlAccount;
