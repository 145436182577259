import React from "react";
import { useSelector } from "react-redux";
import { Select, Input, FormControl } from "@chakra-ui/react";
import DatePicker from "react-datepicker";

import { FormClass, NewFormElements, ActionButton } from "../../styles.jsx";

const SavingDetails = ({
  handleNextScreen,
  prodOptionsLoading,
  productType,
  handleProductType,
  handleSubmittedOn,
  submittedOnDate,
  fieldOfficer,
  handleOfficer,
  externalId,
  setDetailsError,
  handleExternalId,
}) => {
  const productOptionsList = useSelector(
    (state) => state.savingsProductOptions.productOptions
  );

  const { productOptions } = productOptionsList;

  const fetchedProductData = useSelector((state) => state.selectedOptionData);
  const { productOptionsData } = fetchedProductData;
  const { fieldOfficerOptions } = productOptionsData;

  console.log(fieldOfficer, "fieldOfficer");

  return (
    <>
      <FormClass>
        <NewFormElements style={{ width: "60%", margin: "0 auto" }}>
          {/* Product* */}
          <div className="form-group d-flex justify-content-between align-items-center">
            <label className="text-label">
              Product <span style={{ color: "red" }}>*</span>
            </label>
            <Select
              placeholder="Select product type"
              value={productType}
              onChange={handleProductType}
              size="md"
            >
              {productOptions.map((opt) => {
                return (
                  <option value={opt.id} id={opt.id} key={opt.id}>
                    {opt.name}
                  </option>
                );
              })}
            </Select>
          </div>
          {/* Field officer */}
          <div className="form-group d-flex justify-content-between align-items-center">
            <label className="text-label">Field officer</label>
            <Select
              placeholder="Select field officer"
              size="md"
              value={fieldOfficer}
              onChange={handleOfficer}
            >
              {fieldOfficerOptions !== undefined &&
                fieldOfficerOptions.map((officer) => (
                  <option
                    id={officer.id}
                    key={officer.id}
                    value={officer.id}
                  >{`${officer.firstname} ${officer.lastname}`}</option>
                ))}
            </Select>
          </div>

          {/* Submitted on* */}
          <div className="form-group d-flex justify-content-between align-items-center">
            <label className="text-label">
              Submitted on <span style={{ color: "red" }}>*</span>
            </label>
            <FormControl style={{ width: "60%" }}>
              <div className="datepicker-100-width">
                <DatePicker
                  id="published-date"
                  selected={submittedOnDate}
                  onChange={(date) => handleSubmittedOn(date)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="dd/mmm/yyyy"
                />
              </div>
            </FormControl>
          </div>
          {/* External ID */}
          <div className="form-group d-flex justify-content-between align-items-center">
            <label className="text-label">External ID</label>
            <Input
              style={{ width: "60%" }}
              value={externalId}
              onChange={handleExternalId}
              size="md"
            />
          </div>
        </NewFormElements>
        <div className="float-right">
          <ActionButton
            color={prodOptionsLoading ? "gray" : "#2C1DFF"}
            style={{ margin: "0px 0px 0 10px" }}
            onClick={() => {
              productType && fieldOfficer && submittedOnDate
                ? setDetailsError(false)
                : setDetailsError(true);
              handleNextScreen();
            }}
          >
            <img
              src={
                require("../../../../../../assets/images/icons/arrow-right.svg")
                  .default
              }
              alt="forward icon"
            />
            Next
          </ActionButton>
        </div>
      </FormClass>
    </>
  );
};

export default SavingDetails;
