class Auth {
  /**
   * Deauthenticate a user.
   * @description - Removes a token from localStorage.
   */
  static deAuthenticateUser() {
    localStorage.removeItem('token');
    // localStorage.removeItem('gottenTenant');
    localStorage.removeItem('user');
    localStorage.removeItem('woodyUser');
    localStorage.removeItem('sdfsdfsdfsdf');
    localStorage.removeItem('auth');
    localStorage.removeItem('myDefaultValue');
    localStorage.removeItem('defaulLocal');
    localStorage.removeItem('tellerGroupId');
    localStorage.removeItem('cashierId');
  }
  static deleteToken() {
    localStorage.removeItem('auth');
    localStorage.removeItem('token');
    // localStorage.removeItem('gottenTenant');
    localStorage.removeItem('permissions');
    localStorage.removeItem('myDefaultValue');
    localStorage.removeItem('defaulLocal');
    localStorage.removeItem('tellerGroupId');
    localStorage.removeItem('cashierId');
  }

  /**
   * Authenticate a user
   * @description - Save user data and token string in local storage
   */
  static authenticateUser(token) {
    // console.log(token, 'save token to local storage');
    localStorage.setItem('token', token);
    // localStorage.setItem('gottenTenant', gottenTenant);
  }

  static authDetails(details) {
    // console.log(details, 'save details to local storage');
    localStorage.setItem('auth', details);
  }
  static tellerDetails(cashierId, tellerGroupId) {
    localStorage.setItem('cashierId', cashierId);
    localStorage.setItem('tellerGroupId', tellerGroupId);
  }

  /**
   * Set the current tenant url
   * @description - Save tenant in local storage
   */
  static setTenant(tenant) {
    localStorage.setItem('tenant', tenant);
  }

  /**
   * Set if the loggedIn User is a Teller Manager
   * @description - Save sdfsdfsdfsdf in local storage
   */
  static setInterfaceManager(teller) {
    localStorage.setItem('sdfsdfsdfsdf', teller);
  }

  static getInterfaceManager() {
    const interfaceManager = localStorage.getItem('sdfsdfsdfsdf');
    if (!interfaceManager) {
      return '';
    }
    return interfaceManager;
  }

  /**
   * Check if a user is authenticated
   * @returns {boolean}
   * @description - check if a token is saved in Local Storage
   */
  static isUserAuthenticated() {
    const token = localStorage.getItem('token');
    if (!token) {
      return false;
    }
    return true;
  }

  /**
   * Retrieve token from local storage
   * @returns {boolean}
   */
  static getToken() {
    let token = localStorage.getItem('token');
    if (token) {
      return token;
    } else {
      return false;
    }
  }

  static getAuthDetails() {
    let auth = localStorage.getItem('auth');
    if (auth) {
      return JSON.parse(auth);
    } else {
      return false;
    }
  }
  /**
   * Retrieve tenant from local storage
   * @returns Tenant or empty string
   */
  static getTenant() {
    let tenant = localStorage.getItem('tenant');
    if (tenant) {
      return tenant;
    } else {
      return '';
    }
  }
}

export default Auth;
