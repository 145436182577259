import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Table, Tooltip } from "antd";
import { ActionButton, IconButton } from "../styles";
import UploadDocumentModal from "./UploadDocumentModal";
import DeleteDocument from "./DeleteDocument";
import ViewDocument from "./ViewDocument";
import ModalContainer from "../../../components/ModalContainer";
import { getLoanDocuments } from "../../../../../actions/SavingsAndLoanActions";
import config from "../../../../../config";
import AuthModule from "../../../../../modules/Auth.module";
import axiosData from "../../../../../services/axiosData";
import LoanDocDetailsModal from "./LoanDocDetailsModal";
import { truncateString } from "../../../../../util/FormatText";

const LoanDocument = ({ id, history }) => {
  const dispatch = useDispatch();
  const ancRef = useRef();
  const savingsAndLoan = useSelector((state) => state.savingsAndLoan);

  let { loanDocuments = [] } = savingsAndLoan || {};

  const [modalState, toggleModal] = useState(false);

  const [docModal, setDocModal] = useState(false);

  const [modalContentName, setModalContentName] = useState("");
  const [currentDocument, setCurrentDocument] = useState("");
  const [selectedDocument, setSelectedDocument] = useState({});

  const [loanDocumentsDetails, setLoanDocumentDetails] = useState({});
  // const [docModal, setDocModal] = useState(false);

  const currentContent = (e, transId) => {
    const val = e.currentTarget.id.trim();
    setCurrentDocument(transId);


    if (val) {
      toggleModal(true);
      setModalContentName(val);
      // setDocModal(true)
    }
  };


  const handleViewDocument = (e , id) => {
    setDocModal(false);
    setSelectedDocument(loanDocuments.filter((usr) => usr.id === id)[0]);
    toggleModal(true);
    setModalContentName("viewDocument");
    e.stopPropagation();
  };

  const downloadDocument = async (document) => {
    // setIsLoading(true);
    const url = `${config.apiGateway.BASE_API_URL}/loans/${id}/documents/${
      document.id
    }/attachment?tenantIdentifier=${
      config.apiGateway.IS_PRODUCTION === `true`
        ? AuthModule.getTenant()
        : config.apiGateway.WOODCORE_TENANT
    }`;
    try {
      const { data, error } = await axiosData({
        method: "GET",
        signal: false,
        url: url,
        isBearerToken: true,
        responseType: "blob",
      });
      if (data) {
        const dataUrl = URL.createObjectURL(data);
        ancRef.current.href = dataUrl;
        ancRef.current.download = "download";
        setTimeout(() => {
          URL.revokeObjectURL(dataUrl);
        }, 150);
        ancRef.current.click();
      } else {
      }
    } catch (ex) {
    } finally {
      // setIsLoading(false);
    }
  };

  const handleOnHide = () => {
    setDocModal(false);
  };

  useEffect(() => {
    dispatch(getLoanDocuments(id));
  }, [id, dispatch]);

  const handleModalClose = () => toggleModal(false);

  const displayModalContent = () => {
    switch (modalContentName) {
      case "upload":
        return (
          <UploadDocumentModal
            id={id}
            history={history}
            onHide={handleModalClose}
          />
        );
      case "delete":
        return (
          <DeleteDocument
            id={id}
            documentId={currentDocument}
            history={history}
            onHide={handleModalClose}
          />
        );
      case "viewDocument":
        return (
          <ViewDocument
            id={id}
            data={selectedDocument}
            documentId={currentDocument}
            onHide={handleModalClose}
          />
        );
      default:
        return;
    }
  };

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Description", dataIndex: "description", key: "description" },
    { title: "File Name", dataIndex: "fileName", key: "fileName" },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
    },
  ];

  let tableData = [];

  loanDocuments.forEach((document, index) => {
    let { id: documentId, name, description, fileName, location } = document;

    tableData.push({
      key: documentId,
      name : (
        <Tooltip title={name}>
          <div className="truncate-text truncate-text-loan">{name}</div>
        </Tooltip>
      ),
      description: (
        <Tooltip title={description}>
          <div className="truncate-text truncate-text-loan">{description}</div>
        </Tooltip>
      ),
      fileName: (
        <Tooltip title={fileName}>
          <div className="truncate-text truncate-text-loan">{fileName}</div>
        </Tooltip>
      ),
      actions: (
        <>
          {/* <a
            target="_blank"
            download
            href={`${config.apiGateway.BASE_API_URL}/loans/${id}/documents/${
              document.id
            }/attachment?tenantIdentifier=${
              config.apiGateway.IS_PRODUCTION === `true`
                ? AuthModule.getTenant()
                : config.apiGateway.WOODCORE_TENANT
            }`}
          > */}
          <IconButton
            color="#2C1DFF"
            onClick={() => downloadDocument(document)}
          >
            <img
              src={
                require("../../../../../assets/images/icons/download.svg")
                  .default
              }
              alt="download"
              className="icon-img"
            />
          </IconButton>
          {/* </a> */}
          {/* {document.type !== "application/pdf" && ( */}
          <IconButton
            onClick={(e) => handleViewDocument(e , document.id)}
            color="#2C1DFF"
          >
            <img
              src={
                require("../../../../../assets/images/icons/view.svg").default
              }
              alt="view"
              className="icon-img"
            />
          </IconButton>
          {/* )} */}
          <IconButton
            color="#2C1DFF"
            id="delete"
            onClick={(e) => currentContent(e, document.id)}
          >
            <img
              src={
                require("../../../../../assets/images/icons/bin.svg").default
              }
              alt="delete"
              className="icon-img"
            />
          </IconButton>
        </>
      ),
    });
  
  });


  return (
    <>
      <a style={{ display: "none" }} ref={ancRef}></a>
      <ModalContainer show={modalState} dialogClassName="document_infoModal">
        {modalState && displayModalContent()}
      </ModalContainer>

        <div className="w-fit ml-auto my-2" id="upload" onClick={(e) => currentContent(e)}>
          <ActionButton color="#2C1DFF">
            <img
              id="upload"
              src={
                require("../../../../../assets/images/icons/add.svg").default
              }
              className="mr-2"
              alt="add"
            />
            Upload
          </ActionButton>
        </div>

          <Table
            columns={columns}
            dataSource={tableData}
            pagination={{ defaultPageSize: 10 }}
            defaultPageSize={10}
            onRow={(r) => ({
              onClick: () => {
                setLoanDocumentDetails(r);
                setDocModal(true);
              },
            })}
          />

      <ModalContainer show={docModal} dialogClassName="document_infoModal">
        {docModal && (
          <LoanDocDetailsModal
            docModal={docModal}
            onHide={handleOnHide}
            loanDocumentsDetails={loanDocumentsDetails}
          />
        )}
      </ModalContainer>
    </>
  );
};

export default LoanDocument;
