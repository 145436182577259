import * as _ from '../constants/loanCollateralConstant';

const initialState = {
	loading: false,
	error: null,
	response: null,
	collaterals: [],
	loanCollateralTemplate: {
		allowedCollateralTypes: [],
	}
};

const loanCollateralReducer = (state = initialState, action) => {
	switch (action.type) {
		case _.FETCH_LOAN_COLLATERAL_TEMPLATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case _.FETCH_LOAN_COLLATERAL_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loanCollateralTemplate: action.payload,
      };
    case _.FETCH_LOAN_COLLATERAL_TEMPLATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
		case _.FETCH_LOAN_COLLATERALS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case _.FETCH_LOAN_COLLATERALS_SUCCESS:
      return {
        ...state,
        loading: false,
        loanCollaterals: action.payload,
      };
    case _.FETCH_LOAN_COLLATERALS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case _.ADD_COLLATERAL_TO_LOAN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case _.ADD_COLLATERAL_TO_LOAN_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case _.ADD_COLLATERAL_TO_LOAN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
		default: {
			return state;
		}
	}
}

export default loanCollateralReducer;