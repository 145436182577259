import React from "react";
import { Modal, Table } from "react-bootstrap";
import { ActionButton } from "../../styles";
import { formatDate } from "../../../../../../util/FormatDate";
import { formatCurrency } from "../../../../../../util/FormatAmount";

const TransactionDetailsModal = ({ onHide, transactionDetails }) => {

  let {
    submittedByUsername = "",
    amount,
    currency,
    date,
    officeName,
    outstandingLoanBalance,
    submittedOnDate,
    type,
  } = transactionDetails || {};

  return (
    <>
      <Modal.Header>
        <Modal.Title>Transaction Details</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <div className="full-width mt-4">
          <Table responsive striped bordered hover className="table-form-style">
            <thead className="border-0">
              <tr>
                <td className="font-weight-bold md-font-weight">Amount</td>
                <td>
                  {currency?.code} {formatCurrency(Number(amount))}
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="font-weight-bold md-font-weight">
                  Outstanding Loan Balance
                </td>
                <td>{formatCurrency(Number(outstandingLoanBalance))}</td>
              </tr>
              <tr>
                <td className="font-weight-bold md-font-weight">
                  Transaction Type
                </td>
                <td>{type?.value}</td>
              </tr>
              <tr>
                <td className="font-weight-bold md-font-weight">Office Name</td>
                <td>{officeName}</td>
              </tr>{" "}
              <tr>
                <td className="font-weight-bold md-font-weight">
                  Submitted Date
                </td>
                <td>{formatDate(submittedOnDate)}</td>
              </tr>
              {/* <tr>
                <td className="font-weight-bold md-font-weight">
                  Transaction Date
                </td>
                <td>{transDate}</td>
              </tr>
              <tr>
                <td className="font-weight-bold md-font-weight">
                  Submitted By
                </td>
                <td>{submittedByUsername}</td>
              </tr>
              <tr>
                <td className="font-weight-bold md-font-weight">Narration</td>
                <td>{transactionType}</td>
              </tr> */}
            </tbody>
          </Table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-1">
          <ActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require("../../../../../../assets/images/icons/cancel.svg")
                  .default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default TransactionDetailsModal;
