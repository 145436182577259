import React, { useEffect, useState } from "react";
import { Input, Textarea } from "@chakra-ui/react";
import { NewActionButton, FormElements, FormClass } from "../styles";
import { Modal, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  editClientTier as editSystemClientTier,
  getClientTiers,
} from "../../../../../../actions/systemTiersActions";
import CurrencyFormat from "react-currency-format";

const EditClientTier = ({ onHide, data, id }) => {
  console.log(data, "data");
  const dispatch = useDispatch();
  const [editClientTier, setEditClientTier] = useState(data);
  const [loading, setLoading] = useState(false);
  const {
    savingsWithdrawLimit: swLimit,
    dailyWithdrawLimit: dwLimit,
    cumulativeBalance: cBalance,
    tierName: tName,
    tierRank: tRank,
    id: clientId,
    description: desc,
  } = editClientTier;
  const editClientTierHandler = (e) => {
    setEditClientTier({
      ...editClientTier,
      [e.target?.name]: e.target?.value,
    });
  };
  
  // savingsWithdrawLimit: swLimit,
  // dailyWithdrawLimit: dwLimit,
  // cumulativeBalance: cBalance,

  // const [savingsWithdrawLimit , setSavingsWithdrawLimit] = ("swLimit")

  // useEffect(() => {
  //   if()
   
  // }, [editClientTier])
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const tierName = tName ? tName : "";
    const tierRank = tRank ? tRank : "";
    const locale = tRank ? "en" : "";
    const description = desc ? desc : "";
    const savingsWithdrawLimit = swLimit ? swLimit : "";
    const dailyWithdrawLimit = dwLimit ? dwLimit : "";
    const cumulativeBalance = cBalance ? cBalance : "";
    const data = {
      tierName,
      tierRank,
      locale,
      savingsWithdrawLimit,
      description,
      dailyWithdrawLimit,
      cumulativeBalance,
    };
    const func = () => {
      dispatch(getClientTiers());
      onHide();
    };
    dispatch(editSystemClientTier(data, clientId, func));
    // console.log(data);
    setLoading(false);
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>{tName || "Edit tier"}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn px-0 py-0">
        <FormClass style={{ width: "90%" }}>
          <FormElements style={{ width: "100%" }}>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Tier Name</label>
              <Input
                style={{ width: "60%" }}
                size="md"
                name="tierName"
                value={tName}
                onChange={editClientTierHandler}
              />
            </div>

            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Tier Rank</label>
              <Input
                style={{ width: "60%" }}
                size="md"
                name="tierRank"
                value={tRank}
                type="number"
                onChange={editClientTierHandler}
              />
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Savings Withdrawal Limit</label>
              <Input
                style={{ width: "60%" }}
                size="md"
                name="savingsWithdrawLimit"
                value={swLimit}
                onChange={editClientTierHandler}
              />
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Daily Withdrawal Limit</label>
              <Input
                style={{ width: "60%" }}
                size="md"
                name="dailyWithdrawLimit"
                value={dwLimit}
                onChange={editClientTierHandler}
              />
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Cumulative Balance</label>
              <Input
                style={{ width: "60%" }}
                size="md"
                name="cumulativeBalance"
                value={cBalance}
                onChange={editClientTierHandler}
              />
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Description</label>
              <Textarea
                style={{ width: "60%" , fontSize: "12px"}}
                size="md"
                name="description"
                value={desc}
                onChange={editClientTierHandler}
              />
            </div>
          </FormElements>
        </FormClass>
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-1">
          <NewActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require("../../../../../../assets/images/icons/cancel.svg")
                  .default
              }
              alt="cancel icon"
            />
            Cancel
          </NewActionButton>
          <NewActionButton
            onClick={handleSubmit}
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
          >
            {loading ? (
              <Spinner
                animation="border"
                color="#fff"
                className="mr-2 text-white"
                size="sm"
              />
            ) : (
              <img
                src={
                  require("../../../../../../assets/images/icons/save.svg")
                    .default
                }
                alt="submit icon"
              />
            )}
            Submit
          </NewActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default EditClientTier;
