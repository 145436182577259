import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { Input, Textarea } from "@chakra-ui/react";
import { FormClass, FormElements, ActionButton } from "../../styles.jsx";
import {
  uploadCustomerDocument,
  getCustomerDocuments,
} from "../../../../../../actions/customerActions.js";
import Toast from "../../../../../../util/Toast.js";
import FileUpload from "../../../../../../assets/images/file-upload.svg";

const AddDocument = ({ onHide }) => {
  const dispatch = useDispatch();

  const customerData = useSelector((state) => state.SingleCustomerDetails);

  const [documentName, setDocumentName] = useState("");
  const [documentDescription, setDocumentDescription] = useState("");
  const [file, setFile] = useState(null);

  // console.log(file, "outtt");

  const handleSubmitDocument = async () => {
    if (file === null) {
      Toast({ text: "Please select document", icon: "error" });
    } else if (documentName == "") {
      Toast({ text: "Please provide document name", icon: "error" });
    } else if (customerData && customerData.singleUser) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("description", documentDescription);
      formData.append("name", documentName);
      await dispatch(
        uploadCustomerDocument(customerData.singleUser.id, formData)
      );
      await dispatch(getCustomerDocuments(customerData.singleUser.id));
      onHide();
    } else {
      Toast({ text: "Something went wrong. check connection", icon: "error" });
    }
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>Upload Document</Modal.Title>
      </Modal.Header>
      <Modal.Body className="wc-modal">
        <div className="d-flex align-items-center justify-content-center flex-direction-coloumn">
          <FormClass style={{ width: "90%" }}>
            <FormElements style={{ width: "100%" }}>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Name*</label>
                <Input
                  style={{ width: "60%" }}
                  size="md"
                  name="documentName"
                  value={documentName}
                  onChange={(e) => setDocumentName(e.target.value)}
                />
              </div>

              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">Description</label>
                <Textarea
                  size="md"
                  style={{ width: "60%" }}
                  resize={"none"}
                  name="documentDescription"
                  value={documentDescription}
                  onChange={(e) => setDocumentDescription(e.target.value)}
                />
              </div>
              <div className="d-flex justify-content-center my-4">
                <div className="draganddrop">
                  <input
                    type="file"
                    name="document"
                    id="signature"
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      top: "0",
                      left: "0",
                      opacity: "0",
                      cursor: "pointer",
                    }}
                    onChange={(e) => setFile(e.target.files[0])}
                    onDrop={(e) => setFile(e.target.files[0])}
                    onDragOver={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  />
                  <div className="draganddrop-dd">
                    <div className="d-flex justify-content-center my-4">
                      <img className="file-Img" src={FileUpload} alt="" />
                    </div>
                    <p className="text-center">
                      Drag & drop your files here <br /> or
                    </p>
                    <ActionButton
                      color="#2C1DFF"
                      className="mt-2"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <input
                        type="file"
                        id="signature"
                        name="image"
                        style={{
                          width: "100%",
                          height: "100%",
                          position: "absolute",
                          top: "0",
                          left: "0",
                          opacity: "0",
                          cursor: "pointer",
                        }}
                        onChange={(e) => setFile(e.target.files[0])}
                      />
                      Choose File
                    </ActionButton>
                    {!!file ? <p>{file.name}</p> : ""}
                  </div>
                </div>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-1">
          <ActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require("../../../../../../assets/images/icons/cancel.svg")
                  .default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={handleSubmitDocument}
          >
            <img
              src={
                require("../../../../../../assets/images/icons/save.svg")
                  .default
              }
              alt="submit icon"
            />
            Submit
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default AddDocument;
