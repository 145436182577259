import styled from "styled-components";

export const CardContainer = styled.div`
overflow: hidden;
height: 110px;
position: relative;
padding: 15px;
min-width: 250px;
background: #ffffff;
border: ${(props) => !!props?.noBorder === "true" ? "" : "0.5px solid #addcff"};
box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.06);
border-radius: 10px;
max-width: min-content;

  h1 {
    font-size: 20px;
    color: #002c79;
  }
  p {
    font-size: 14px;
    color: #969cba;
  }
  .font-small {
    font-size: 14px;
  }
`;

export const FirstRowContainer = styled.div`
  margin: -15px 0 0 -15px;
`;

export const Status = styled.div`
  border-radius: 30px;
  background-color: ${(props) =>
    props?.status === "active" || props?.status === "ACTIVE"
      ? "rgba(29, 203, 67, 0.2);"
      : "rgba(255, 0, 0, 0.2)"};
  color: ${(props) =>
    props?.status === "active" || props?.status === "ACTIVE"
      ? "#1dcb43"
      : "#FF0000"};
  padding: 5px 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100px;

  .button {
    vertical-align: middle;
    font-size: 14px;
    text-align: center;
    text-transform: capitalize;
  }
  .circle-icon {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${(props) =>
      props?.status === "active" || props?.status === "ACTIVE"
        ? "#1dcb43"
        : "#FF0000"};
  }
`;

export const NewStatus = styled.div`
  border-radius: 20px;
  background-color: ${(props) =>
    props?.status === "active" || props?.status === "ACTIVE"
      ? "rgba(29, 203, 67, 0.2);"
      : "rgba(255, 0, 0, 0.2)"};
  color: ${(props) =>
    props?.status === "active" || props?.status === "ACTIVE"
      ? "#1dcb43"
      : "#FF0000"};
  padding: 5px 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 60px;
  margin: 4px 0 0 13px;
  height: 20px;

  .button {
    vertical-align: middle;
    font-size: 12px;
    text-align: center;
    text-transform: capitalize;
  }
  .circle-icon {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${(props) =>
      props?.status === "active" || props?.status === "ACTIVE"
        ? "#1dcb43"
        : "#FF0000"};
  }
`;

export const Menu = styled.div`
  position: absolute;
`;

export const ShowMore = styled.div`
  padding: 10px 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  align-items: flex-end;
  position: absolute;
  z-index: 1;
  background: #eff4f7;
  border-radius: 0px 10px 10px 0px;
  top: 0;
  right: 0;
  height: 100%;
  width: 26%;

  button {
    color: #002c79;
  }
`;