import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Table } from "react-bootstrap";
import ModalContainer from "../../../components/ModalContainer";
import { ActionButton, TakeActionButton, MenuButton } from "./styles";
import DeleteAccountModal from "./DeleteAccountModal";
import DisableAccountModal from "./DisableAccountModal";
import { setChartOfAccountParent } from "../../../../../actions/accountingActions";
import CheckPermission from "../../../../../util/CheckPermission";
import { Menu, Dropdown, Button } from "antd";
import { Modal } from "@chakra-ui/react";

const AccountInfoSlide = ({
  history,
  selectedAcount,
  permissions,
  showCard,
  onHide,
}) => {
  const [modalState, toggleModal] = useState(false);
  const [modalContentName, setModalContentName] = useState("");
  const [actionValue, setActionValue] = useState("");
  const [actionModal, setActionModal] = useState(false);
  const [rowDetailModal, toggleDetailsModal] = useState(false);

  const truncateString = (str, num) => {
    if (str.length > num) {
      let subStr = str.substring(0, num);
      return subStr + "...";
    } else {
      return str;
    }
  };

  const handleCloseModal = () => {
    setActionModal(false);
    return setActionValue("");
  };

  const handleClick = (value) => {
    setActionValue(value);
    return setActionValue(true);
  };

  const displayActionModalContent = (currentTableActive) => {
    switch (actionValue) {
      case "deleteAccount":
        return (
          <DeleteAccountModal
            data={currentTableActive}
            // status="approve"
            // handleCloseModal={handleCloseModal}
            history={history}
            id={id}
            onHide={handleModalClose}
          />
        );
      case "disableAccount":
        return (
          <DisableAccountModal
            data={currentTableActive}
            // status="reject"
            // handleCloseModal={handleCloseModal}
            history={history}
            id={id}
            status={!disabled}
            onHide={handleModalClose}
          />
        );
      default:
        break;
    }
  };

  useEffect(() => {
    if (actionModal === true) {
      return toggleDetailsModal(false);
    }
    if (rowDetailModal === true) {
      return setActionModal(false);
    }
  }, [actionModal, rowDetailModal]);

  let { accountName, glCode, accountType, manualEntryStatus, chartAccount } =
    selectedAcount || {};

  console.log(chartAccount, "chartAccount");

  let {
    usage = {},
    disabled,
    id,
    type,
    parentId,
    manualEntriesAllowed,
    description = "",
    isSystemDefined,
  } = chartAccount || {};

  console.log(chartAccount, "chartAccount");
  console.log(selectedAcount, "selectedAcount");
  const dispatch = useDispatch();

  const handleSetParent = () => {
    let parentToSet = {
      value: id,
      name: accountName,
      type,
      parentId,
    };
    setChartOfAccountParent(dispatch, parentToSet);
  };

  const currentContent = (e) => {
    const val = e.currentTarget.id.trim();

    if (val) {
      toggleModal(true);
      setModalContentName(val);
    }
  };

  const handleModalClose = () => toggleModal(false);

  const displayModalContent = () => {
    switch (modalContentName) {
      case "deleteAccount":
        return (
          <DeleteAccountModal
            history={history}
            id={id}
            onHide={handleModalClose}
          />
        );
      case "disableAccount":
        return (
          <DisableAccountModal
            history={history}
            id={id}
            status={!disabled}
            onHide={handleModalClose}
          />
        );
      default:
        return;
    }
  };

  const menu = (
    <div>
      {CheckPermission("UPDATE_GLACCOUNT", permissions) && (
        <MenuButton
          onClick={() =>
            history.push(`/core/charts-of-account/edit-gl-account/${id}`)
          }
        >
          <p>Edit Account</p>
        </MenuButton>
      )}

      {CheckPermission("UPDATE_GLACCOUNT", permissions) && (
        <MenuButton onClick={currentContent} id="disableAccount">
          <p>{disabled ? "Enable " : "Disable "} Account </p>
        </MenuButton>
      )}

      {!!usage && usage.value === "HEADER" ? (
        <MenuButton
          onClick={() => {
            handleSetParent();
            history.push("/core/charts-of-account/create-gl-account");
          }}
        >
          <p>Subledger Account</p>
        </MenuButton>
      ) : (
        ""
      )}

      <MenuButton onClick={currentContent} id="deleteAccount">
        <p>Delete Account</p>
      </MenuButton>
    </div>
  );

  return (
    <>
      {showCard ? (
        <>
          <ModalContainer
            showCard={showCard}
            show={modalState}
            dialogClassName="document_infoModal"
          >
            {modalState && displayModalContent()}
          </ModalContainer>

          <div className="new-content-detail container">
            <div className="detail-cancel-button" onClick={onHide}>
              <img
                src={
                  require("../../../../../assets/images/icons/cancelRed.svg")
                    .default
                }
                className="detail-cancel-icon"
                alt="cancel icon"
              />
            </div>
            <div
              className="row"
              style={{ marginBottom: "15px", marginLeft: "10px" }}
            >
              {/* <div className="details-button"> */}
              <div className="col-6">{!!accountName ? accountName : ""}</div>
              <div className="col-6">
                <Dropdown
                  onClick={(e) => toggleDetailsModal(false)}
                  overlay={menu}
                >
                  <TakeActionButton
                    // onClick={() => setShowMenu(!showMenu)}
                    color="#2C1DFF"
                  >
                    Action
                    <img
                      src={
                        require("../../../../../assets/images/icons/arrow-down.svg")
                          .default
                      }
                      className="ml-2"
                      alt="action"
                    />
                  </TakeActionButton>
                </Dropdown>
              </div>
              {/* </div> */}
            </div>
            <hr />
            <div className="container">
              <table class="table">
                <tbody>
                  <tr>
                    <td
                      className="font-weight-bold md-font-weight"
                      style={{
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "12px",
                      }}
                    >
                      GL Code
                    </td>
                    <td
                      style={{
                        fontWeight: 400,
                        fontSize: "12px",
                        color: "#969CBA",
                      }}
                    >
                      {!!glCode ? glCode : ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Account type
                    </td>
                    <td
                      style={{
                        fontWeight: 400,
                        fontSize: "12px",
                        color: "#969CBA",
                      }}
                    >
                      {!!accountType ? accountType : ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Manual Entry Status
                    </td>
                    <td
                      style={{
                        fontWeight: 400,
                        fontSize: "12px",
                        color: "#969CBA",
                      }}
                    >
                      {manualEntriesAllowed && manualEntriesAllowed !== null ? (
                        <span
                          className="new-text-success"
                          style={{ textTransform: "uppercase" }}
                        >
                          Allowed
                        </span>
                      ) : (
                        <span
                          className="new-text-danger"
                          style={{ textTransform: "uppercase" }}
                        >
                          not Allowed
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold md-font-weight">Status</td>
                    <td
                      style={{
                        fontWeight: 400,
                        fontSize: "12px",
                        color: "#969CBA",
                      }}
                    >
                      {disabled && disabled !== null ? (
                        <span
                          className="new-text-danger"
                          style={{ textTransform: "uppercase" }}
                        >
                          Inactive
                        </span>
                      ) : (
                        <span
                          className="new-text-success"
                          style={{ textTransform: "uppercase" }}
                        >
                          Active
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Account Usage
                    </td>
                    <td
                      style={{
                        fontWeight: 400,
                        fontSize: "12px",
                        color: "#969CBA",
                      }}
                    >
                      {!!usage ? usage.value : ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      System Defined Status
                    </td>
                    <td
                      style={{
                        fontWeight: 400,
                        fontSize: "12px",
                        color: "#969CBA",
                      }}
                    >
                      {isSystemDefined && isSystemDefined !== null ? (
                        <span
                          className="new-text-success"
                          style={{ textTransform: "uppercase" }}
                        >
                          True
                        </span>
                      ) : (
                        <span
                          className="new-text-danger"
                          style={{ textTransform: "uppercase" }}
                        >
                          False
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Description
                    </td>
                    <td
                      style={{
                        fontWeight: 400,
                        fontSize: "12px",
                        color: "#969CBA",
                      }}
                    >
                      {truncateString(description, 15)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default AccountInfoSlide;
