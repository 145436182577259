import styled from "styled-components";

export const ActionButtonsGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  justify-content: space-around;
  select {
    // background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    border-radius: 3px;
    height: 50px;
    min-width: 100px;
    border: none;
  }
`;

export const ContentWrapper = styled.div`
  margin-top: 50px;
`;

export const TabContentWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  height: 100%;
  padding: 5px;
  .infoNav {
    justify-content: center;
    padding: 10px 0 1px;
  }
  .nav-tabs .nav-link {
    font-size: 16px;
    padding: 10px 35px;
    // font-weight: bold;
    text-align: center;
    color: #c2c2c2;
    &.active {
      border: none;
      color: #333333;
      opacity: none;
      border-bottom: 3px solid #002c79 !important;
    }
    &:hover {
      border: none;
    }
  }
  .application-wrapper {
    .css-1njiejo {
      width: 60% !important;
      height: 48px !important;
    }
  }
`;
export const TabContentWrapperTrans = styled.div`
  background: #ffffff;
  border-radius: 10px;
  height: 100%;
  padding: 5px;
  border: 1px solid #DADCE5 !important;
  .infoNav {
    justify-content: center;
    padding: 10px 0 1px;
  }
  .nav-tabs .nav-link {
    font-size: 16px;
    padding: 10px 35px;
    // font-weight: bold;
    text-align: center;
    color: #c2c2c2;
    &.active {
      border: none;
      color: #333333;
      opacity: none;
      border-bottom: 3px solid #002c79 !important;
    }
    &:hover {
      border: none;
    }
  }
  .application-wrapper {
    .css-1njiejo {
      width: 60% !important;
      height: 48px !important;
    }
  }
`;

export const NewActionButton = styled.div`
  width: max-content !important;
  height: 35px;
  background: ${(props) => props.color} !important;
  border-radius: 5px !important;
  color: #fff !important;
  margin: 0 2px;
  font-family: "AventaMedium";
  border: 1px solid #2c1dff;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  img {
    height: 12px;
    margin-right: 10px;
    display: inline;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;

export const ResetButton = styled.div`
  width: -webkit-max-content !important;
  width: -moz-max-content !important;
  width: max-content !important;
  height: 35px;
  border-radius: 5px !important;
  color: red !important;
  font-family: "AventaMedium";
  padding: 0 15px;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  text-decoration: underline;
`;

export const ContentCard = styled.div`
  position: relative;
  overflow: hidden;
  height: 110px;
  display: flex;
  min-width: 250px;
  word-wrap: break-word;
  justify-content: space-around;
  align-items: center;
  //   padding-left: 20px;
  //   padding-right: 20px;
  padding: 20px 10px;
  background-color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  flex-direction: row;
  box-shadow: 0px 2px 0px #ededf6;
`;

export const FormElements = styled.div`
  width: 90%;
  display: block;
  label {
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #969cba;
    text-transform: capitalize;
  }
`;
export const FormClass = styled.div``;

export const ActionButton = styled.button`
  background: #2c1dff !important;
  border-radius: 5px !important;
  color: #fff !important;
  margin: 0 10px;
  border: none;
  display: inline;
  font-family: "AventaMedium";
  font-size: 14px;
  height: 40px;
  width: 174px;
  //   max-width: 100px;
  //   min-width: 100px;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  img {
    height: 13px;
    display: inline;
    margin-left: 10px;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;
export const BackActionButton = styled.button`
  background: #fff !important;
  border-radius: 5px !important;
  color: #000 !important;
  margin: 0 10px;
  border: 1px solid #c9ced6 !important;
  display: inline;
  font-family: "AventaMedium";
  font-size: 14px;
  height: 40px;
  width: 174px;
  //   max-width: 100px;
  //   min-width: 100px;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  img {
    height: 13px;
    display: inline;
    margin-left: 10px;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;

export const DownloadButton = styled.button`
  width: max-content !important;
  height: 35px;
  background: #fff !important;
  margin: 0 2px;
  border: 1px solid #000;
  padding: 0 15px;
  font-family: "AventaMedium";
  border-radius: 5px !important;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  color: #000 !important;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  img {
    height: 13px;
    display: inline;
    margin-left: 10px;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;
export const FilterTransButton = styled.button`
  width: max-content !important;
  // height: 35px;
  background: #fff !important;
  margin: 0 2px;
  border: 1px solid #000;
  padding: 0 15px;
  font-family: "AventaMedium";
  border-radius: 5px !important;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  color: #000 !important;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  img {
    display: inline;
    margin-left: 10px;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;
export const FilterButton = styled.button`
  width: max-content !important;
  height: 35px;
  background: #fff !important;
  margin: 0 2px;
  border: 1px solid #969cba;
  padding: 0 15px;
  font-family: "AventaMedium";
  border-radius: 5px !important;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  color: #000 !important;
  font-size: 14px;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  img {
    height: 13px;
    display: inline;
    margin-left: 10px;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;

export const ReportDownloadButton = styled.button`
  width: 40px !important;
  height: 35px;
  background: #2c1dff !important;
  // margin: 0 2px;
  // border: 1px solid #000;
  padding: 0 15px;
  font-family: "AventaMedium";
  border-radius: 5px !important;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  color: #000 !important;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  img {
    height: 15px;
    display: inline;
    // margin-left: 10px;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;

export const OTPDigitsFormContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0 20px;
  margin-bottom: 1.875rem;
  input {
    background: #ffffff;
    width: 50px;
    height: 50px;
    border: 1px solid rgba(150, 156, 186, 0.2);
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 3px;
    &:focus {
      box-shadow: none !important;
    }
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

export const OTPResendTitle = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height, or 154% */
  text-align: center;
  letter-spacing: 0.035em;
  color: #666666;
  margin-bottom: 40px;
  a {
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.035em;
    color: #2c1dff;
    text-decoration: none;
  }
`;
