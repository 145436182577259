import React, { useState } from "react";
import { FormClass, ModalText, NewActionButton } from "../styles";
import { Modal, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  deleteUser,
  getAllUsers,
} from "../../../../../actions/configurationActions";

const DeleteUser = ({ onHide, id, history }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const handleDelete = async () => {
    setLoading(true);
    await dispatch(deleteUser(id, history));
    dispatch(getAllUsers(Number(id)));
    setLoading(false);
    onHide();
  };
  return (
    <>
      <Modal.Header>
        <Modal.Title>Delete User</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "95%" }}>
          <ModalText>Are you sure you want to delete this user?</ModalText>
   
        </FormClass>
      </Modal.Body>
      <Modal.Footer>
      <div className="float-right mt-1">
            <NewActionButton color="#2C1DFF" onClick={onHide}>
              <img
                src={
                  require("../../../../../assets/images/icons/cancel.svg")
                    .default
                }
                alt="cancel icon"
                className="icon-img"
                />
              Cancel
            </NewActionButton>
            <NewActionButton
              color="#2C1DFF"
              onClick={handleDelete}
              style={{ margin: "0px 0px 0 10px" }}
            >
              {loading ? (
                <Spinner
                  animation="border"
                  color="#fff"
                  className="mr-2 text-white"
                  size="sm"
                />
              ) : (
                <img
                  src={
                    require("../../../../../assets/images/icons/delete.svg")
                      .default
                  }
                  alt="submit icon"
                  className="icon-img"

                />
              )}
              Delete
            </NewActionButton>
          </div>
      </Modal.Footer>
    </>
  );
};

export default DeleteUser;
