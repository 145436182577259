import React from "react";
import { Select, Input, Textarea, FormControl } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { Checkbox } from "antd";
import CurrencyFormat from "react-currency-format";
import { textToUpperCase } from "../../../../../../util/Tools";

import {
  FormClass,
  FormElements,
  ActionButton,
  CardDetails,
} from "../../styles";

const AmortizationDetails = ({
  handleNextScreen,
  name,
  setName,
  currency,
  setCurrency,
  amount,
  setAmount,
  fromDate,
  setFromDate,
  noOfDays,
  setNoOfDays,
  setComment,
  comment,
  setDetailsError,
  currencies,
  offices,
  glAcccounts,
  paymentTypes,
  setCredits,
  credits,
  debits,
  setDebits,
  setMainCredits,
  endDate,
  setEndDate,
  active,
  setActive,
}) => {
  const defaultCredit = { glAccountId: "" };
  const addToCredit = () => {
    setCredits([...credits, defaultCredit]);
  };
  const removeCredit = (position) => {
    let noo = [];
    credits.forEach((element, index) => {
      if (position !== index) {
        noo.push(element);
      }
    });
    setCredits(noo);
  };

  const handleOnCreditChange = (position, timeStamp) => {
    let hhh = [...credits];
    hhh[Number(position)].glAccountId = timeStamp;
    setCredits(hhh);
  };

  // For Debits
  const defaultDebit = { glAccountId: "" };
  const addToDebit = () => {
    setDebits([...debits, defaultDebit]);
  };
  const removeDebit = (position) => {
    let noo = [];
    debits.forEach((element, index) => {
      if (position !== index) {
        noo.push(element);
      }
    });
    setDebits(noo);
  };

  const handleOnDebitChange = (position, timeStamp) => {
    let hhh = [...debits];
    hhh[Number(position)].glAccountId = timeStamp;
    setDebits(hhh);
  };
  return (
    <>
      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-muted d-flex flex-align-center">
                  Name*
                </label>
                <Input
                  style={{ width: "60%" }}
                  placeholder="Name"
                  size="md"
                  name="name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-muted d-flex flex-align-center">
                  Currency*
                </label>
                <Select
                  size="md"
                  placeholder="Select Currency"
                  name="currency"
                  value={currency}
                  onChange={(e) => {
                    setCurrency(e.target.value);
                  }}
                >
                  {!!currencies.length
                    ? currencies.map(({ code, displayLabel }) => {
                        return (
                          <option value={code} key={code}>
                            {displayLabel}
                          </option>
                        );
                      })
                    : null}
                </Select>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>

      <div className="col-md-12 col-sm-12">
        <div className="row">
          <label className="text-muted d-flex flex-align-center">
            Affected GL Entries
          </label>
        </div>
        <div className="row ml-5">
          <div className="col-sm-12 col-md-12 px-0">
            <CardDetails>
              <div className="dflex">
                <div className="pt-3">
                  <p className="sub-title">
                    <span className="text-info mr-1 linkSavings ">Credit</span>
                  </p>
                </div>
                <div className="pt-4">
                  <hr />
                </div>
                {/* <div className="mb-3">
                  <div className="button-right ml-3">
                    <ActionButton
                      color="#2C1DFF"
                      className="float-right"
                      style={{ margin: "0px 0px" }}
                      onClick={() => addToCredit()}
                    >
                      <img
                        src={
                          require("../../../../../../assets/images/icons/add.svg")
                            .default
                        }
                        alt="add icon"
                      />
                      Add Credit
                    </ActionButton>
                  </div>
                </div> */}
              </div>
              <FormElements>
                <div className="row">
                  {credits.map(({ glAccountId }, index) => {
                    return (
                      <div className="p-relative col-md-6">
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-muted d-flex flex-align-center">
                            Credit*
                          </label>
                          <Select
                            size="md"
                            placeholder="Select Credit"
                            name="credit"
                            value={credits[index].glAccountId}
                            onChange={(e) => {
                              handleOnCreditChange(index, e.target.value);
                            }}
                          >
                            {!!glAcccounts.length
                              ? glAcccounts.map(({ id, name }) => {
                                  return (
                                    <option value={id} key={id}>
                                      {textToUpperCase(name)}
                                    </option>
                                  );
                                })
                              : null}
                          </Select>
                        </div>
                        {credits.length > 1 ? (
                          <div
                            className="remove-icon2"
                            onClick={() => removeCredit(index)}
                          >
                            <img
                              src={
                                require("../../../../../../assets/images/icons/cancel.svg")
                                  .default
                              }
                              className="detail-cancel-icon"
                              alt="cancel icon"
                            />
                          </div>
                        ) : null}
                      </div>
                    );
                  })}
                </div>
              </FormElements>
            </CardDetails>
          </div>
        </div>{" "}
        <div className="row ml-5">
          <div className="col-sm-12 col-md-12 px-0">
            <CardDetails>
              <div className="dflex">
                <div className="pt-3">
                  <p className="sub-title">
                    <span className="text-info mr-1 linkSavings ">Debit</span>
                  </p>
                </div>
                <div className="pt-4">
                  <hr />
                </div>
                {/* <div className="mb-3">
                  <div className="button-right ml-3">
                    <ActionButton
                      color="#2C1DFF"
                      className="float-right"
                      style={{ margin: "0px 0px" }}
                      onClick={() => addToDebit()}
                    >
                      <img
                        src={
                          require("../../../../../../assets/images/icons/add.svg")
                            .default
                        }
                        alt="add icon"
                      />
                      Add Debit
                    </ActionButton>
                  </div>
                </div> */}
              </div>
              <FormElements>
                <div className="row">
                  {debits.map(({ glAccountId }, index) => {
                    return (
                      <div className="p-relative col-md-6">
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-muted d-flex flex-align-center">
                            Debit*
                          </label>
                          <Select
                            size="md"
                            placeholder="Select Debits"
                            name="debit"
                            value={debits[index].glAccountId}
                            onChange={(e) => {
                              handleOnDebitChange(index, e.target.value);
                            }}
                          >
                            {!!glAcccounts.length
                              ? glAcccounts.map(({ id, name }) => {
                                  return (
                                    <option value={id} key={id}>
                                      {textToUpperCase(name)}
                                    </option>
                                  );
                                })
                              : null}
                          </Select>
                        </div>
                        {debits.length > 1 ? (
                          <div
                            className="remove-icon2"
                            onClick={() => removeDebit(index)}
                          >
                            <img
                              src={
                                require("../../../../../../assets/images/icons/cancel.svg")
                                  .default
                              }
                              className="detail-cancel-icon"
                              alt="cancel icon"
                            />
                          </div>
                        ) : null}
                      </div>
                    );
                  })}
                </div>
              </FormElements>
            </CardDetails>
          </div>
        </div>
      </div>

      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-muted d-flex flex-align-center">
                  Amount*
                </label>
                <CurrencyFormat
                  value={amount}
                  thousandSeparator={true}
                  className="amount-input "
                  style={{
                    width: "60%",
                  }}
                  // prefix={"$"}
                  onValueChange={(values) => {
                    const { value } = values;
                    // formattedValue = $2,223
                    // value ie, 2223
                    setAmount(value);
                  }}
                />
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-muted d-flex flex-align-center">
                  Start date*
                </label>
                <FormControl style={{ width: "70%" }}>
                  <DatePicker
                    id="fromDate"
                    selected={fromDate}
                    onChange={(date) => setFromDate(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="dd/mmm/yyyy"
                  />
                </FormControl>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>

      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              {/* <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-muted d-flex flex-align-center">
                  Number of days*
                </label>
                <Input
                  style={{ width: "60%" }}
                  size="md"
                  name="noOfDays"
                  value={noOfDays}
                  onChange={(e) => {
                    setNoOfDays(e.target.value);
                  }}
                />
              </div> */}
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-muted d-flex flex-align-center">
                  End date*
                </label>
                <FormControl style={{ width: "60%" }}>
                  <DatePicker
                    id="fromDate"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="dd/mmm/yyyy"
                  />
                </FormControl>
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-muted d-flex flex-align-center">
                  Active
                </label>
                <div className="" style={{ width: "70%" }}>
                  <Checkbox
                    onChange={(e) => setActive(!active)}
                    value={active}
                  ></Checkbox>
                </div>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>
      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-muted d-flex flex-align-center">
                  Number of days*
                </label>
                <Input
                  style={{ width: "60%" }}
                  size="md"
                  name="noOfDays"
                  value={noOfDays}
                  onChange={(e) => {
                    setNoOfDays(e.target.value);
                  }}
                />
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label
                  className="text-muted d-flex flex-align-center"
                  style={{ marginTop: "-30px" }}
                >
                  Description
                </label>
                <Textarea
                  style={{ width: "70%" }}
                  size="md"
                  name="description"
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                />
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>

      <div>
        <div className="float-right pt-5">
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={() => {
              name && amount && currency && fromDate && noOfDays
                ? setDetailsError(false)
                : setDetailsError(true);
              handleNextScreen();
            }}
          >
            <img
              src={
                require("../../../../../../assets/images/icons/arrow-right.svg")
                  .default
              }
              alt="submit icon"
            />
            Next
          </ActionButton>
        </div>
      </div>
    </>
  );
};

export default AmortizationDetails;
