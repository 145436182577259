import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Navbar, Nav, Button, Modal, Form } from "react-bootstrap";
import {
  SearchInput,
  ProfileBarDropDown,
  NavBrand,
  NavBrandOpen,
  NavWrapper,
  StyledHeadwayWidget,
} from "./styles";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { getUserData, logoutUser } from "../../../actions/authActions";
import { generalSearch } from "../../../actions/utilityActions";

import { Skeleton, Space } from "antd";

function LogoModal(props) {
  return (
    <Modal
      {...props}
      backdrop="static"
      keyboard={false}
      size="lg"
      centered
      contentClassName="customLogoModal"
    >
      <Modal.Body className="d-flex align-items-center justify-content-center">
        <img
          style={{ height: "40px", zIndex: "3" }}
          alt="woodcore logo"
          src={require("../../../assets/images/wcLogoWhite.svg").default}
        />
      </Modal.Body>
    </Modal>
  );
}

const DashboardNavigation = (props) => {
  const navigationBar = useSelector((state) => state.navigationBar);

  let { navigationBarActive } = navigationBar || {};

  const dispatch = useDispatch();

  const userDataObj = useSelector((state) => state.userData);
  const { loading, userData } = userDataObj;

  const [load, setLoad] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getUserData());
    };

    fetchData();
  }, [dispatch]);

  // const handleSearch = (e) => {
  //   e.preventDefault();
  //   dispatch(searchCustomer(search));
  // };

  const logoutHandler = () => {
    setLoad(true);
    setTimeout(async () => {
      await dispatch(logoutUser());
      return () => setLoad(false);
    }, 2000);
  };

  const [search, setSearch] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();
    if (search !== "" && !!search.length) {
      dispatch(
        generalSearch(
          `search?exactMatch=false&query=${search}&resource=clients,clientIdentifiers,groups,savings,shares,loans`
        )
      );
      setSearch("");
    }
  };

  return (
    <>
      <LogoModal show={load} />
      <NavWrapper>
        <Navbar
          bg="light"
          expand="lg"
          className="sticky-top bg-white flex-md-nowrap p-0"
          style={{ height: "70px" }}
        >
          {navigationBarActive ? (
            <>
              {" "}
              <NavBrandOpen
                className="mr-0 logo text-center h-100 d-flex align-items-center justify-content-center"
                href="#"
              >

                <img
                  src={require("../../../assets/images/new-logo.svg").default}
                  style={{ height: "22px" }}
                  alt="Logo"
                />
              </NavBrandOpen>
            </>
          ) : (
            <>
              <NavBrand
                className="mr-0 logo text-center h-100 d-flex align-items-center justify-content-center"
                href="#"
              >
                <img
                  src={require("../../../assets/images/wcLogoWhite.svg").default}
                  style={{ height: "15px" }}
                  alt="Logo"
                />
              </NavBrand>
            </>
          )}

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            className="bg-white"
            // style={{ marginLeft: "20%" }}
            id="basic-navbar-nav"
          >
            <Form inline className="ml-0 ml-md-3" onSubmit={handleSearch}>
              <Button type="submit" className="bg-transparent border-0 pr-0">
                <img
                  style={{ height: "15px" }}
                  src={
                    require("../../../assets/images/icons/search.svg").default
                  }
                  alt="search"
                />
              </Button>
              <SearchInput
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                type="text"
                placeholder="Search..."
                className="form-control border-0 bg-transparent"
              />
            </Form>
            <Nav className="ml-auto justify-content-sm-end pr-4">
              <>
                <ProfileBarDropDown>
                  {loading ? (
                    <Space>
                      <Skeleton.Button
                        active={true}
                        size="small"
                        shape="circle"
                      />
                      <Skeleton.Button
                        active={true}
                        size="small"
                        shape="circle"
                      />
                      {/* <Skeleton.Avatar
                      active={true}
                      size="small"
                      shape="circle"
                    /> */}
                      <Skeleton.Input
                        style={{ width: 200 }}
                        active={true}
                        size="small"
                      />
                    </Space>
                  ) : (
                    <>
                      <div className="profile-details">
                        <p className="profile-name">
                          {userData && userData.firstname}
                        </p>
                        <p className="profile-email">
                          {userData && userData.email}
                        </p>
                      </div>

                      <div className="profile-image">
                        {/* <img
                        src={
                          require("../../../assets/images/avatar.png").default
                        }
                        alt="user"
                      /> */}
                      </div>

                      <div className="arrow">
                        <Menu>
                          <MenuButton
                            variant="none"
                            as={Button}
                            placement="left-start"
                          >
                            <img
                              src={
                                require("../../../assets/images/icons/arrow-down.svg")
                                  .default
                              }
                              alt="arrow down"
                            />
                          </MenuButton>
                          <MenuList minWidth="140px">
                            <a
                              href="https://docs.woodcoreapp.com/en"
                              rel="noreferrer"
                              target="_blank"
                            >
                              <MenuItem>
                                <img
                                  src={
                                    require("../../../assets/images/icons/help.svg")
                                      .default
                                  }
                                  style={{ height: "14px" }}
                                  className="mr-4"
                                  alt="help icon"
                                />
                                Help
                              </MenuItem>
                            </a>
                            <Link
                              to="/core/profile"
                              className="text-decoration-none"
                            >
                              <MenuItem>
                                <img
                                  src={
                                    require("../../../assets/images/icons/profile.svg")
                                      .default
                                  }
                                  className="mr-4"
                                  style={{ height: "14px" }}
                                  alt="profile icon"
                                />
                                Profile
                              </MenuItem>
                            </Link>
                            <Link
                              to="/core/settings"
                              className="text-decoration-none"
                            >
                              <MenuItem>
                                <img
                                  src={
                                    require("../../../assets/images/icons/settings.svg")
                                      .default
                                  }
                                  alt="Settings icon"
                                  style={{ height: "14px" }}
                                  className="mr-4"
                                />
                                Settings
                              </MenuItem>
                            </Link>
                          </MenuList>
                        </Menu>
                      </div>
                    </>
                  )}

                  <div className="verticalLine" />
                </ProfileBarDropDown>

                <StyledHeadwayWidget account="7wRLPx">
                  <span className="nav-link">
                    <img
                      src={
                        require("../../../assets/images/icons/notifications.svg")
                          .default
                      }
                      alt="notifications"
                      className="icon-img"
                    />
                  </span>
                </StyledHeadwayWidget>

                <Nav.Link
                  onClick={(e) => {
                    e.preventDefault();
                    logoutHandler();
                  }}
                >
                  <img
                    src={
                      require("../../../assets/images/icons/logout.svg").default
                    }
                    alt="logout"
                    className="icon-img"
                  />
                </Nav.Link>
              </>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </NavWrapper>
    </>
  );
};

export default DashboardNavigation;
