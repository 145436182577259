import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { Checkbox } from "antd";
import { Input, Select, FormControl } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import CurrencyFormat from "react-currency-format";
import {
  FormClass,
  FormElements,
  ActionButton,
} from "../../CustomerInformation/styles";
import {
  accountTransfer,
  getLoanDisburseDetails,
  handleLoanTransactions,
} from "../../../../../actions/SavingsAndLoanActions";
import { formatDate } from "../../../../../util/FormatDate.js";
import { formatCurrency, formatAmount } from "../../../../../util/FormatAmount";

const PrepayLoanModal = ({ onHide, id, history }) => {
  const dispatch = useDispatch();
  const savingsAndLoan = useSelector((state) => state.savingsAndLoan);

  let {
    loanAccountDisburseDetails,
    loanDetails: {
      linkedAccount,
      clientId,
      clientOfficeId,
      id: loanId,
      currency
    }
  } = savingsAndLoan || {};

  let {
    amount = 0,
    // currency = {},
    // date = [],
    interestPortion = 0,
    principalPortion = 0,
    paymentTypeOptions = [],
  } = loanAccountDisburseDetails || {};

  useEffect(() => {
    dispatch(getLoanDisburseDetails(id, "prepayLoan"));
  }, [dispatch, id]);

  //STATE HANDLER FOR DEPOSIT INFO
  const [transactionDate, setTransactionDate] = useState(new Date());

  const [enteredAmount, setAmount] = useState(0);
  const [enteredIntereset, setInterest] = useState(0);
  const [enteredPrincipal, setPrincipal] = useState(0);
  const [note, setNote] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [showPaymentDetails, setShowPaymentDetails] = useState(false);

  const [account, setAccount] = useState("");
  const [cheque, setCheque] = useState("");
  const [receipt, setReceipt] = useState("");
  const [routineCode, setRoutineCode] = useState("");
  const [bank, setBank] = useState("");
  const [modalState, setModalState] = useState(1);

  let formatTransactionDate = "";
  if (transactionDate !== "") {
    formatTransactionDate = formatDate(transactionDate, true);
  }

  useEffect(() => {
    if (!!amount || !!interestPortion || !!principalPortion) {
      setAmount(amount);
      setInterest(interestPortion);
      setPrincipal(principalPortion);
    }
  }, [principalPortion, amount, interestPortion]);

  const handleSubmitPrepayLoan = async () => {
    let disbursementData = {
      transactionDate: formatTransactionDate,
      locale: "en",
      paymentTypeId: 1,
      dateFormat: "dd MMM yyy",
      transactionAmount: enteredAmount,
      note,
    };

    let disbursmentData2 = {
      fromOfficeId: clientOfficeId,
      fromClientId: clientId,
      fromAccountType: 2,
      fromAccountId: linkedAccount?.id,
      toOfficeId: clientOfficeId,
      toClientId: clientId,
      toAccountType: 1,
      toAccountId: loanId,
      dateFormat: "dd MMMM yyyy",
      locale: "en",
      transferDate: formatTransactionDate,
      transferAmount: enteredAmount,
      transferDescription: note,
    };

    dispatch(accountTransfer(id, disbursmentData2))
    // paymentType === "linked"
    //   ? dispatch(accountTransfer(id, disbursmentData2))
    //   : dispatch(
    //     handleLoanTransactions(id, disbursementData, "repayment", history)
    //   );
    onHide();
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>Prepay Loan</Modal.Title>
      </Modal.Header>
      <Modal.Body className="wc-modal">
        {modalState === 1 && (
          <div className="d-flex align-items-center justify-content-center flex-direction-coloumn">
            <FormClass style={{ width: "90%" }}>
              <FormElements style={{ width: "100%" }}>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Pre-Payment Type</label>
                  <Input
                    disabled
                    style={{ width: "60%" }}
                    name="account"
                    value="Linked Savings account (debit a savings account)"
                    size="md"
                  />
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Transaction Date<span style={{ color: "red" }}>*</span></label>
                  <FormControl style={{ width: "60%" }}>
                    <div className="datepicker-100-width">

                      <DatePicker
                        id="published-date"
                        selected={transactionDate}
                        onChange={(date) => setTransactionDate(date)}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="dd/mmm/yyyy"
                      />
                    </div>
                  </FormControl>
                </div>

                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Account to Debit</label>
                  <Input
                    style={{ width: "60%", fontSize: '12px' }}
                    name="account"
                    value={linkedAccount?.accountNo}
                    size="md"
                    disabled
                  />
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Account Balance</label>
                  <Input
                    style={{ width: "60%", fontSize: '12px' }}
                    name="account"
                    value={formatCurrency(Number(linkedAccount?.accountBalance))}
                    size="md"
                    disabled
                  />
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Transaction Amount<span style={{ color: "red" }}>*</span></label>

                  <CurrencyFormat
                    value={enteredAmount}
                    thousandSeparator={true}
                    className="amount-input "
                    style={{
                      width: "60%",
                    }}
                    // prefix={"$"}
                    onValueChange={(values) => {
                      const { value } = values;
                      // formattedValue = $2,223
                      // value ie, 2223
                      setAmount(value);
                    }}
                  />
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Principal</label>
                  <Input
                    style={{ width: "60%" }}
                    name="enteredPrincipal"
                    value={formatCurrency(enteredPrincipal)}
                    onChange={(e) => setPrincipal(e.target.value)}
                    size="md"
                    disabled
                  />
                </div>

                {showPaymentDetails === true ? (
                  <>
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-label">Account#</label>
                      <Input
                        style={{ width: "60%" }}
                        name="account"
                        value={account}
                        onChange={(e) => setAccount(e.target.value)}
                        size="md"
                      />
                    </div>
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-label">Cheque#</label>
                      <Input
                        style={{ width: "60%" }}
                        name="cheque"
                        value={cheque}
                        onChange={(e) => setCheque(e.target.value)}
                        size="md"
                      />
                    </div>{" "}
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-label">Routing Code</label>
                      <Input
                        style={{ width: "60%" }}
                        name="routineCode"
                        value={routineCode}
                        onChange={(e) => setRoutineCode(e.target.value)}
                        size="md"
                      />
                    </div>{" "}
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-label">Receipt#</label>
                      <Input
                        style={{ width: "60%" }}
                        name="receipt"
                        value={receipt}
                        onChange={(e) => setReceipt(e.target.value)}
                        size="md"
                      />
                    </div>{" "}
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-label">Bank#</label>
                      <Input
                        style={{ width: "60%" }}
                        name="bank"
                        value={bank}
                        onChange={(e) => setBank(e.target.value)}
                        size="md"
                      />
                    </div>
                  </>
                ) : null}
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Narration<span style={{ color: "red" }}>*</span></label>
                  <Input
                    style={{ width: "60%" }}
                    name="note"
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    size="md"
                  />
                </div>
              </FormElements>
            </FormClass>
          </div>
        )}
        {modalState === 2 && (
          <div className="d-flex align-items-center justify-content-center flex-direction-coloumn">
            <FormClass style={{ width: "90%" }}>
              <FormElements style={{ width: "100%" }}>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Pre-Payment Type</label>
                  <p className="popup-details">Linked Savings account (debit a savings account)</p>
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Transaction Date</label>
                  <p className="popup-details">{formatTransactionDate}</p>
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Transaction Amount</label>
                  <p className="popup-details">{currency?.code}{" "}{formatAmount(Number(enteredAmount))}</p>
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Principal</label>
                  <p className="popup-details"> {formatAmount(Number(enteredPrincipal))}</p>
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Account Balance</label>
                  <p className="popup-details">{formatAmount(Number(linkedAccount?.accountBalance))}</p>
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Account to Debit</label>
                  <p className="popup-details">{linkedAccount?.accountNo}</p>
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Interest Amount</label>
                  <p className="popup-details">{currency?.code}{" "}{formatAmount(Number(enteredIntereset))}</p>
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Narration</label>
                  <p className="popup-details">{note}</p>
                </div>
              </FormElements>
            </FormClass>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {" "}
        <div className="float-right mt-1">
          <ActionButton color="#2C1DFF" onClick={() => {
            return modalState === 1 ? onHide() : setModalState(1);
          }}>
            <img
              src={
                require("../../../../../assets/images/icons/cancel.svg").default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton
            onClick={() => {
              return modalState === 1
                ? setModalState(2)
                : handleSubmitPrepayLoan();
            }}
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
          >
            <img
              src={
                require("../../../../../assets/images/icons/save.svg").default
              }
              alt="submit icon"
            />
            {modalState === 1 ? "Next" : "Submit"}
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default PrepayLoanModal;
