import {
  SET_SAVINGS_ACCOUNTS,
  SET_SAVINGS_ACCOUNTS_TEMPLATE,
  SET_SAVINGS_ACCOUNTS_SEARCH,
  SET_LOAN_ACCOUNTS,
  SET_LOAN_ACCOUNTS_SEARCH,
  SET_LOAN_ACCOUNTS_TEMPLATE,
} from '../constants/accountConstants';
import { SET_LOADING_STATE } from '../constants/accountingConstants';
import { SET_TABLE_LOADING_STATE } from '../constants/utilityConstants';
import {
  getAllSavingAccounts,
  getAllSavingAccountsTemplate,
  getLoanAccounts,
  searchSavingsAcc,
  getLoanAccountsTemplate,
  searchLoanAcc,
} from '../services/Api/account.api.services';
import Toast from '../util/Toast';

const handleError = (ex, dispatch) => {
  dispatch({ type: SET_LOADING_STATE, payload: false });
  dispatch({ type: SET_TABLE_LOADING_STATE, payload: false });
  if (ex.response) {
    dispatch(
      Toast({
        text: ex.response.data.errors
          ? ex.response.data.errors[0].defaultUserMessage
          : ex.response.data.defaultUserMessage,
        icon: 'error',
      })
    );
  } else if (ex.request) {
    dispatch(Toast({ text: String(ex), icon: 'error' }));
  } else {
    dispatch(Toast({ text: String(ex), icon: 'error' }));
  }
};

//Get savings accounts
const getAllSavingsAccounts = (
  params,
  savingProduct,
  isActive,
  isInactive,
  startDate,
  endDate,
  accountOnPnd
) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: SET_TABLE_LOADING_STATE, payload: true });
    await getAllSavingAccounts(
      params,
      savingProduct,
      isActive,
      isInactive,
      startDate,
      endDate,
      accountOnPnd
    )
      .then((response) => {
        if (response) {
          dispatch({ type: SET_LOADING_STATE, payload: false });
          dispatch({ type: SET_TABLE_LOADING_STATE, payload: false });
          dispatch({ type: SET_SAVINGS_ACCOUNTS, payload: response });
        }
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

// filter savings accounts
const searchSavingsAccount = (filterParam) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    // dispatch({ type: SET_SAVINGS_ACCOUNTS_SEARCH });
    await searchSavingsAcc(filterParam)
      .then((response) => {
        if (response) {
          dispatch({ type: SET_SAVINGS_ACCOUNTS_SEARCH, payload: response });
          dispatch({ type: SET_LOADING_STATE, payload: false });
        }
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const searchLoanAccount = (filterParam) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await searchLoanAcc(filterParam)
      .then((response) => {
        if (response) {
          dispatch({ type: SET_LOAN_ACCOUNTS_SEARCH, payload: response });
          dispatch({ type: SET_LOADING_STATE, payload: false });
        }
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const getAllSavingsAccountsTemplate = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: SET_SAVINGS_ACCOUNTS_TEMPLATE });
    await getAllSavingAccountsTemplate()
      .then((response) => {
        if (response) {
          dispatch({ type: SET_SAVINGS_ACCOUNTS_TEMPLATE, payload: response });
          dispatch({ type: SET_LOADING_STATE, payload: false });
        }
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

//Get savings accounts
const getAllLoanAccounts = (
  params,
  loanProducts,
  isActive,
  loansUnpaid,
  loansRepaid,
  startDate,
  endDate
) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: SET_TABLE_LOADING_STATE, payload: true });

    await getLoanAccounts(
      params,
      loanProducts,
      isActive,
      loansUnpaid,
      loansRepaid,
      startDate,
      endDate
    )
      .then((response) => {
        if (response) {
          dispatch({ type: SET_TABLE_LOADING_STATE, payload: false });
          dispatch({ type: SET_LOADING_STATE, payload: false });
          dispatch({ type: SET_LOAN_ACCOUNTS, payload: response });
        }
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const getAllLoanAccountsTemplate = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: SET_LOAN_ACCOUNTS_TEMPLATE });
    await getLoanAccountsTemplate()
      .then((response) => {
        if (response) {
          dispatch({ type: SET_LOAN_ACCOUNTS_TEMPLATE, payload: response });
          dispatch({ type: SET_LOADING_STATE, payload: false });
        }
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

export {
  getAllSavingsAccounts,
  getAllSavingsAccountsTemplate,
  searchSavingsAccount,
  getAllLoanAccounts,
  getAllLoanAccountsTemplate,
  searchLoanAccount,
};
