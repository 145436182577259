import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import rootReducer from "../reducers";

import Auth from "../modules/Auth.module";

const userDataFromStorage = localStorage.getItem("woodyUser")
  ? JSON.parse(localStorage.getItem("woodyUser"))
  : null;

/**
 * Store initial state
 */
const initialState = {
  userLogin: {
    error: null,
    loading: false,
    isAuthenticated: Auth.isUserAuthenticated(),
    userData: userDataFromStorage,
  },
  customerReducer: {
    customers: [],
    loading: false,
    error: null,
    filtered: null,
  },
  userReducer: {
    users: [],
    loading: false,
    error: null,
    filtered: null,
  },
  officeReducer: {
    offices: [],
    loading: false,
    error: null,
    filtered: null,
  },
  dashboardReducer: {
    statistics: [],
    loading: false,
    error: null,
  },
  employeeReducer: {
    employees: [],
    loading: false,
    error: null,
    filtered: null,
  },
  entityDataTableReducer: {
    entities: [],
    loading: false,
    error: null,
    filtered: null,
  },
  holidayReducer: {
    holidays: [],
    loading: false,
    error: null,
    filtered: null,
  },
  standingInstructionsReducer: {
    standingInstruction: [],
    loading: false,
    error: null,
  },
  paymentTypeReducer: {
    paymentTypes: [],
    loading: false,
    error: null,
    filtered: null,
  },
  savingsProductsReducer: {
    savingsProducts: [],
    loading: false,
    error: null,
    filtered: null,
  },
  loanProductsReducer: {
    loanProducts: [],
    loading: false,
    error: null,
    filtered: null,
  },
  fdProductsReducer: {
    fixedDepositProducts: [],
    loading: false,
    error: null,
    filtered: null,
  },
  chargesReducer: {
    charges: [],
    loading: false,
    error: null,
    filtered: null,
  },
  taxComponentsReducer: {
    taxComponents: [],
    loading: false,
    error: null,
    filtered: null,
  },
  taxGroupsReducer: {
    taxGroups: [],
    loading: false,
    error: null,
    filtered: null,
  },
  hookReducer: {
    hooks: [],
    loading: false,
    error: null,
    filtered: null,
  },
  s3Reducer: {
    s3: [],
    loading: false,
    error: null,
  },
  notificationReducer: {
    notification: [],
    loading: false,
    error: null,
  },
  SMTPReducer: {
    SMTP: [],
    loading: false,
    error: null,
  },
  SMSReducer: {
    SMS: [],
    loading: false,
    error: null,
  },
  configurationReducer: {
    configurations: { permissionUsageData: [] },
    loading: false,
    error: null,
    filtered: null,
  },
  MCTasksReducer: {
    MCTasks: [],
    loading: false,
    error: null,
  },
  rolesReducer: {
    roles: [],
    loading: false,
    error: null,
    filtered: null,
  },
  roleReducer: {
    role: { globalConfiguration: [], description: "", name: "" },
    loading: false,
    error: null,
    filtered: null,
  },
  savingsAccountTransReducer: {
    savingsAccountDetails: { transactions: [] },
    loading: false,
    error: null,
  },
  loanAccountTransReducer: {
    loanAccountDetails: { transactions: [] },
    loading: false,
    error: null,
  },
  fdProductsReducer: {
    fixedDepositProducts: [],
    loading: false,
    error: null,
    filtered: null,
  },

};

const middleware = [thunk];

const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
