import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Select, Tag, Checkbox } from 'antd';
import DatePicker from 'react-datepicker';
import { useDispatch } from 'react-redux';
import { formatDateString } from '../../../../../util/FormatDate';
import { NewActionButton } from '../../styles';
import { getAllSavingsAccounts } from '../../../../../actions/accountActions';
import DropdownMenu from './DropdownMenu';

const FilterModal = ({ onHide, setViewState, template }) => {
  const options = [];
  const dispatch = useDispatch();
  template && template.forEach((opt) => options.push({ value: opt }));
  const tagRender = (props) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color='blue'
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };
  const [myStartDate, setStartDate] = useState('');
  const [myEndDate, setEndDate] = useState('');
  const [savingProducts, setSavingProducts] = useState([]);
  const [filterOptions, setfilterOptions] = useState([]);

  const handleFilter = (e) => {
    const startDate = myStartDate
      ? formatDateString(myStartDate, 'dd/MMM/yyyy')
      : '';
    const endDate = myEndDate ? formatDateString(myEndDate, 'dd/MMM/yyyy') : '';

    e.preventDefault();
    dispatch(
      getAllSavingsAccounts(
        'sortOrder=DESC',
        savingProducts.length ? savingProducts : null,
        filterOptions.includes('active'),
        filterOptions.includes('inactive'),
        startDate ? startDate : '',
        endDate ? endDate : '',
        filterOptions.includes('accountOnPnd')
      )
    );
    setViewState(true);
    onHide();
  };
  const handleClearFilter = () => {
    setStartDate('');
    setEndDate('');
    setSavingProducts([]);
    setfilterOptions([]);
  };
  return (
    <>
      <Modal.Body className='wc-modal'>
        <div className=' my-3'>
          <div
            className='row ml-1'
            style={{ minWidth: '250px', width: 'fit-content' }}
          >
            <Select
              mode='multiple'
              onChange={(e) => setSavingProducts(e)}
              showArrow
              value={savingProducts}
              tagRender={tagRender}
              placeholder='Select Savings Product'
              options={options}
            />
          </div>
          <div className='border-line my-3'></div>
          <div className='row mt-2 center-flex'>
            <div className='col-2'>
              <p className='text-label'>Filter Options</p>
            </div>
            <div className='col-4'>
              <div className='row'>
                {/* <div className="col mb-3">
                  <Checkbox
                    name="active"
                    checked={checkBoxes.active}
                    onChange={onChange}
                  >
                   <p className="text-label">Active Accounts</p>
                  </Checkbox>
                </div>
                <div className="col mb-3">
                  <Checkbox
                    name="inactive"
                    checked={checkBoxes.inactive}
                    onChange={onChange}
                  >
                    <p className="text-label">InActive Accounts</p>
                  </Checkbox>
                </div>
                <div className="col mb-3">
                  <Checkbox
                    name="accountOnPnd"
                    checked={checkBoxes.accountOnPnd}
                    onChange={onChange}
                  >
                    <p className="text-label">Accounts On Pnd</p>
                  </Checkbox>
                </div> */}
                <div className='col mb-3'>
                  <DropdownMenu
                    multiple
                    byId
                    updateServices={setfilterOptions}
                    options={[
                      { label: 'Active Accounts', value: 'active' },
                      { label: 'InActive Accounts', value: 'inactive' },
                      { label: 'Accounts On Pnd', value: 'accountOnPnd' },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='border-line my-3'></div>
          <div className='row'>
            <div className='col-md-6'>
              <div className='row'>
                <div className='col-3 text-label'>Start Date</div>
                <div className='col-9'>
                  <div className='datepicker-100-width'>
                    <DatePicker
                      id='from-date'
                      selected={myStartDate}
                      onChange={(date) => setStartDate(date)}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode='select'
                      placeholderText='start date'
                    />
                  </div>{' '}
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='row'>
                <div className='col-3 text-label'>End Date</div>
                <div className='col-9'>
                  <div className='datepicker-100-width'>
                    <DatePicker
                      id='from-date'
                      selected={myEndDate}
                      onChange={(date) => setEndDate(date)}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode='select'
                      placeholderText='start date'
                    />
                  </div>{' '}
                </div>
              </div>
            </div>
          </div>

          <div className='border-line my-3'></div>
          <div className='mt-1'>
            <div className='d-flex justify-content-between'>
              <div className=''>
                <p
                  onClick={handleClearFilter}
                  className='pointer'
                  style={{
                    color: 'red',
                    fontSize: '15px',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                >
                  Clear all filters
                </p>
              </div>
              <div className='d-flex'>
                <NewActionButton onClick={onHide}>Cancel </NewActionButton>
                <NewActionButton onClick={handleFilter}>
                  Submit{' '}
                </NewActionButton>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default FilterModal;
