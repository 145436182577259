import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { Input, FormControl } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import {
  FormClass,
  FormElements,
  ActionButton,
} from "../../CustomerInformation/styles";
import {
  getLoanDisburseDetails,
  handleLoanTransactions,
} from "../../../../../actions/SavingsAndLoanActions";
import { formatDate } from "../../../../../util/FormatDate.js";
import { formatCurrency } from "../../../../../util/FormatAmount";

const CloseLaonAccountScheduleModal = ({ onHide, id, history, closeType }) => {
  const dispatch = useDispatch();
  const savingsAndLoan = useSelector((state) => state.savingsAndLoan);

  let { loanAccountDisburseDetails } = savingsAndLoan || {};

  let { amount } = loanAccountDisburseDetails || {};

  useEffect(() => {
    dispatch(getLoanDisburseDetails(id, "writeoff"));
  }, [id, dispatch]);

  //STATE HANDLER FOR DEPOSIT INFO
  const [transactionDate, setTransactionDate] = useState(new Date());

  const [enteredAmount, setAmount] = useState(0);
  const [note, setNote] = useState("");

  useEffect(() => {
    if (!!amount) {
      setAmount(amount);
    }
  }, [amount]);

  const handleWriteOffAccount = () => {
    let disbursementData = {
      locale: "en",
      dateFormat: "dd MMM yyy",
      note,
      transactionDate: formatDate(transactionDate, true),
    };
    let actionToSend =
      closeType === "scheduledClose" ? "close-rescheduled" : "close";
    dispatch(
      handleLoanTransactions(id, disbursementData, actionToSend, history)
    );

    onHide();
    // console.log()
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          Close Loan Account{" "}
          {closeType === "scheduledClose" ? "As Rescheduled" : ""}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <FormElements style={{ width: "100%" }}>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Close On*</label>
              <FormControl style={{ width: "60%" }}>
              <div className="datepicker-100-width">

                <DatePicker
                  id="published-date"
                  selected={transactionDate}
                  onChange={(date) => setTransactionDate(date)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="dd/mmm/yyyy"
                />
                </div>
              </FormControl>
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Note</label>
              <Input
                style={{ width: "60%" }}
                name="note"
                value={note}
                onChange={(e) => setNote(e.target.value)}
                size="md"
              />
            </div>
          </FormElements>
        </FormClass>
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-1">
          <ActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require("../../../../../assets/images/icons/cancel.svg").default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton
            onClick={handleWriteOffAccount}
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
          >
            <img
              src={
                require("../../../../../assets/images/icons/save.svg").default
              }
              alt="submit icon"
            />
            Submit
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default CloseLaonAccountScheduleModal;
