import React from "react";
// import { useSelector } from "react-redux";
import {
  Select,
  Input,
  FormControl,
  Checkbox,
  Textarea,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";

import { FormClass, FormElements, ActionButton } from "../../../styles.jsx";

const LoanDetails = ({
  template,
  handleNextScreen,
  productName,
  currency,
  shortName,
  multipleCurrency,
  description,
  installment,
  updateLoanProductInfoHandler,
  decimalPlaces,
  handleStartDate,
  startDate,
  closeDate,
  customerLoanCounter,
  fund,
  handleCloseDate,
  setNewLoanProduct,
  setDetailsError,
  detailsError,
  renderTooltip,
}) => {
  return (
    <>
      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Product Name* {renderTooltip("Product Name")}
                </label>
                <Input
                  style={{
                    width: "60%",
                    borderColor:
                      !productName && detailsError ? "red" : "inherit",
                  }}
                  size="md"
                  name="productName"
                  value={productName}
                  onChange={updateLoanProductInfoHandler}
                />
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Currency* {renderTooltip("Currency")}
                </label>
                <Select
                  size="md"
                  style={{
                    borderColor: !currency && detailsError ? "red" : "inherit",
                  }}
                  name="currency"
                  value={currency}
                  onChange={updateLoanProductInfoHandler}
                >
                  <option value="">Select Currency</option>
                  {template !== undefined && template
                    ? template.currencyOptions &&
                      template.currencyOptions.map((cur) => (
                        <option value={cur.code} key={cur.code}>
                          {cur.displayLabel}
                        </option>
                      ))
                    : null}
                </Select>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>

      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Short Name* {renderTooltip("Short Name")}
                </label>
                <Input
                  style={{
                    width: "60%",
                    borderColor: !shortName && detailsError ? "red" : "inherit",
                  }}
                  size="md"
                  name="shortName"
                  value={shortName}
                  onChange={updateLoanProductInfoHandler}
                />
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Currency In Multiples Of{" "}
                  {renderTooltip("Currency In Multiples Of")}
                </label>
                <Input
                  style={{
                    width: "60%",
                    borderColor:
                      !multipleCurrency && detailsError ? "red" : "inherit",
                  }}
                  size="md"
                  type="number"
                  name="multipleCurrency"
                  value={multipleCurrency}
                  onChange={updateLoanProductInfoHandler}
                />
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Description {renderTooltip("Description")}
                </label>
                <Textarea
                  style={{ width: "60%", fontSize: "12px" }}
                  size="md"
                  name="description"
                  value={description}
                  onChange={updateLoanProductInfoHandler}
                />
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>

      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Installment In Multiples Of{" "}
                  {renderTooltip("Installment In Multiples Of")}
                </label>
                <Input
                  style={{
                    width: "60%",
                    borderColor:
                      !installment && detailsError ? "red" : "inherit",
                  }}
                  size="md"
                  type="number"
                  name="installment"
                  value={installment}
                  onChange={updateLoanProductInfoHandler}
                />
              </div>
            </FormElements>
          </FormClass>
          {/* <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Decimal Places*
                  {renderTooltip("Decimal Places")}
                </label>
                <Input
                  style={{
                    width: "60%",
                    borderColor:
                      !decimalPlaces && detailsError ? "red" : "inherit",
                  }}
                  size="md"
                  type="number"
                  name="decimalPlaces"
                  value={decimalPlaces}
                  onChange={updateLoanProductInfoHandler}
                />
              </div>
            </FormElements>
          </FormClass> */}
        </div>
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Start Date
                  {renderTooltip("Start Date")}
                </label>
                <FormControl style={{ width: "60%" }}>
                  <div className="datepicker-100-width">
                    <DatePicker
                      id="start-date"
                      selected={startDate}
                      onChange={(date) => handleStartDate(date)}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="dd/mmm/yyyy"
                    />
                  </div>
                </FormControl>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>

      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Close Date
                  {renderTooltip("Close Date")}
                </label>
                <FormControl style={{ width: "60%" }}>
                  <div className="datepicker-100-width">
                    <DatePicker
                      id="close-date"
                      selected={closeDate}
                      onChange={(date) => handleCloseDate(date)}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="dd/mmm/yyyy"
                    />
                  </div>
                </FormControl>
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Count Loan
                  {renderTooltip("Include In Customer Loan Counter")}
                </label>
                <FormControl style={{ width: "60%" }}>
                  <Checkbox
                    size="md"
                    name="customerLoanCounter"
                    isChecked={customerLoanCounter}
                    onChange={(e) =>
                      setNewLoanProduct((p) => ({
                        ...p,
                        customerLoanCounter: e.target.checked,
                      }))
                    }
                  />
                </FormControl>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>
      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        {/* <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Fund
                  {renderTooltip("Fund")}
                </label>
                <Select
                  size="md"
                  name="fund"
                  value={fund}
                  onChange={updateLoanProductInfoHandler}
                >
                  <option value="">Select Fund</option>
                  {template !== undefined && template
                    ? template.fundOptions &&
                      template.fundOptions.map((cur) => (
                        <option value={cur.id} key={cur.id}>
                          {cur.name}
                        </option>
                      ))
                    : null}
                </Select>
              </div>
            </FormElements>
          </FormClass>
        </div> */}
      </div>

      <div>
        <div className="float-right pt-5">
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={() => {
              productName &&
              shortName &&
              currency &&
              multipleCurrency &&
              decimalPlaces
                ? setDetailsError(false)
                : setDetailsError(true);
              handleNextScreen();
            }}
          >
            <img
              src={
                require("../../../../../../../../assets/images/icons/arrow-right.svg")
                  .default
              }
              alt="submit icon"
            />
            Next
          </ActionButton>
        </div>
      </div>
    </>
  );
};

export default LoanDetails;
