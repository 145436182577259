import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getSingleOutflowDetails } from "../../../../../actions/nibssConnectActions";
import { formatDateTimeNibss } from "../../../../../util/FormatDate";
import { TabContentWrapper, TabContentWrapperTrans } from "../../styles";

function OutflowTransactionDetails({ match }) {
  const dispatch = useDispatch();

  const nibssSingleOutflow = useSelector(
    (state) => state.nibssSingleOutflowTemplate
  );

  const location = useLocation();

  const outflowState = location.state;
  const filteredOutflow = location.state;

  const { nibssSingleOutflowTemplate } = nibssSingleOutflow;

  const outflowData = outflowState[0]?.filter(
    ({ id }) => id.toString() === match?.params?.id
  );

  useEffect(() => {
    dispatch(getSingleOutflowDetails(match.params.id));
  }, [match.params.id]);

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-4">
            <h3 className="page-title">Transaction report</h3>
            <p className="font-weight Nibss-sub-title">
              View transaction report <br />
              <span className="text-info">
                <Link to="/core/nibss-management">NIBSS Integration</Link>
              </span>{" "}
              <span className="text-info">
                <Link to="/core/nibss-management/transaction-report">
                  {" "}
                  / Transaction Report{" "}
                </Link>
              </span>{" "}
              /{" "}
              {!!outflowData[0] && !!outflowData[0].beneficiaryAccountName
                ? outflowData[0].beneficiaryAccountName
                : ""}
            </p>
          </div>
        </div>

        <TabContentWrapperTrans>
          <div className="col-sm-12 col-md-12 pb-4 ">
            <div
              className="row justify-content-between"
              style={{ padding: "10px" }}
            >
              {/* First Table */}
              <div className="col-sm-6 col-md-6 pb-4 tablePaddingNibss ">
                <div className="m-5">
                  <Table
                    responsive
                    bordered
                    // striped
                    // hover
                    className=" tableNibss "
                  >
                    <thead>
                      <tr>
                        <th className="tableHead td1">Sender account number</th>
                        <th className="tableHead td22 md-font-weight">
                          {!!outflowData[0] &&
                          !!outflowData[0].customerAccountNumber
                            ? outflowData[0].customerAccountNumber
                            : ""}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="td1 md-font-weight">
                          Sender account name
                        </td>
                        <td className="td2">
                          {!!outflowData[0] &&
                          !!outflowData[0].customerAccountName
                            ? outflowData[0].customerAccountName
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <td className="td1 md-font-weight">Amount</td>
                        <td className="td2">
                          <span>NGN </span>
                          {!!outflowData[0] && outflowData[0].amount
                            ? outflowData[0].amount
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <td className="td1 md-font-weight">
                          Recipient's Bank Name
                        </td>
                        <td className="td2">
                          {!!outflowData[0] &&
                          !!outflowData[0].beneficiaryBankName === null
                            ? " "
                            : outflowData[0].beneficiaryBankName}
                        </td>
                      </tr>
                      <tr>
                        <td className="td1 md-font-weight">Fee amount</td>
                        <td className="td2">
                          <span>NGN </span>
                          {!!outflowData[0] && outflowData[0].feeAmount
                            ? outflowData[0].feeAmount
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <td className="td1 md-font-weight">
                          Receipient's Account number
                        </td>
                        <td className="td2">
                          {!!outflowData[0] &&
                          !!outflowData[0].beneficiaryAccountNumber
                            ? outflowData[0].beneficiaryAccountNumber
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <td className="td1 md-font-weight">
                          Receipient's Account name
                        </td>
                        <td className="td2">
                          {!!outflowData[0] &&
                          !!outflowData[0].beneficiaryAccountName
                            ? outflowData[0].beneficiaryAccountName
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <td className="td1 md-font-weight">
                          Receipient's institution code
                        </td>
                        <td className="td2">
                          {!!outflowData[0] &&
                          !!outflowData[0].beneficiaryBankCode
                            ? outflowData[0].beneficiaryBankCode
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <td className="td1 md-font-weight">
                          Name enquiry reference
                        </td>
                        <td className="td2">
                          {!!outflowData[0] &&
                          !!outflowData[0].nameEnquiryReference
                            ? outflowData[0].nameEnquiryReference
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <td className="td1 md-font-weight">
                          Payment reference
                        </td>
                        <td className="td2">
                          {!!outflowData[0] && !!outflowData[0].paymentReference
                            ? outflowData[0].paymentReference
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <td className="td1 md-font-weight">
                          Fee charge transaction ID
                        </td>
                        <td className="td2">
                          {!!outflowData[0] &&
                          !!outflowData[0].feeChargeTransactionId === null
                            ? " "
                            : outflowData[0].feeChargeTransactionId}
                        </td>
                      </tr>
                      <tr>
                        <td className="td1 md-font-weight">
                          Fee charge reference ID
                        </td>
                        <td className="td2">
                          {!!outflowData[0] &&
                          !!outflowData[0].feeChargeReferenceId === null
                            ? " "
                            : outflowData[0].feeChargeReferenceId}
                        </td>
                      </tr>
                      <tr>
                        <td className="td1 md-font-weight">
                          CBA transaction ID
                        </td>
                        <td className="td2">
                          {!!outflowData[0] &&
                          !!outflowData[0].cbaTransactionId === null
                            ? " "
                            : outflowData[0].cbaTransactionId}
                        </td>
                      </tr>
                      <tr>
                        <td className="td1 md-font-weight">
                          CBA journal reference
                        </td>
                        <td className="td2">
                          {!!outflowData[0] &&
                          !!outflowData[0].cbaJournalRef === null
                            ? " "
                            : outflowData[0].cbaJournalRef}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
              {/* Second Table */}
              <div className="col-sm-6 col-md-6 pb-4 tablePaddingNibss">
                <div className="m-5 mt-3">
                  <Table
                    responsive
                    bordered
                    // striped
                    // hover
                    className=" tableNibss "
                  >
                    <thead>
                      <tr>
                        <th className="td1 tableHead">Session ID</th>
                        <th className="tableHead td22 md-font-weight">
                          {!!outflowData[0] && !!outflowData[0].sessionId
                            ? outflowData[0].sessionId
                            : ""}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="td1 md-font-weight">Remarks</td>
                        <td className="td2">
                          {!!outflowData[0] && !!outflowData[0].remarks
                            ? outflowData[0].remarks
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <td className="td1 md-font-weight">Narration</td>
                        <td className="td2" style={{ width: "80%" }}>
                          <textarea style={{ width: "100%" , textAlign: "end" }}>
                            {!!outflowData[0] && !!outflowData[0].narration
                              ? outflowData[0].narration
                              : ""}
                          </textarea>
                        </td>
                      </tr>
                      <tr>
                        <td className="td1 md-font-weight">Response code</td>
                        <td className="td2">
                          {!!outflowData[0] && outflowData[0].responseCode
                            ? outflowData[0].responseCode
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <td className="td1 md-font-weight">
                          Transaction status
                        </td>
                        <td className="td2">
                          {!!outflowData[0] &&
                          outflowData[0].responseCode === "00" ? (
                            <>
                              <span className="new-text-success">Approved</span>
                            </>
                          ) : (
                            ""
                          )}
                          {!!outflowData[0] &&
                          outflowData[0].responseCode === "96" ? (
                            <>
                              <span className="new-text-danger">
                                System Malfunction
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                          {!!outflowData[0] &&
                          outflowData[0].responseCode === "57" ? (
                            <>
                              <span className="new-text-danger">
                                Not Permitted
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                          {!!outflowData[0] &&
                          outflowData[0].responseCode === "09" ? (
                            <>
                              <span className="new-text-pending">
                                In Progress
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                          {!!outflowData[0] &&
                          outflowData[0].responseCode === "07" ? (
                            <>
                              <span className="new-text-warning">
                                Invalid Account
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                          {!!outflowData[0] &&
                          outflowData[0].responseCode === "13" ? (
                            <>
                              <span className="new-text-warning">
                                Invalid Amount
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                          {!!outflowData[0] &&
                          outflowData[0].responseCode === "61" ? (
                            <>
                              <span className="new-text-warning">
                                Transfer Limit Exceeded
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                          {!!outflowData[0] &&
                          outflowData[0].responseCode === "16" ? (
                            <>
                              <span className="new-text-danger">
                                Unknown Bank Code
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                          {!!outflowData[0] &&
                          outflowData[0].responseCode === "30" ? (
                            <>
                              <span className="new-text-danger">
                                Format Error
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="td1 md-font-weight">Created at</td>
                        <td className="td2">
                          {!!outflowData[0] && !!outflowData[0].createdAt
                            ? formatDateTimeNibss(outflowData[0].createdAt)
                            : ""}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </TabContentWrapperTrans>
      </div>
    </>
  );
}

export default OutflowTransactionDetails;
