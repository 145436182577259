const CheckPermission = (type, permissions = []) => {
  let typeContainsRead = type?.includes("READ");

  // console.log(permissions ,"permissions")
  // console.log(type ,"type")
  
  if (typeContainsRead) {
    return type
      ? permissions
        ? permissions.some(
            (per) =>
              per === type ||
              per === "ALL_FUNCTIONS_READ" ||
              per === "ALL_FUNCTIONS"
          )
        : false
      : true;
  } else {
    return type
      ? permissions
        ? permissions.some((per) => per === type || per === "ALL_FUNCTIONS")
        : false
      : true;
  }
};
export default CheckPermission;
