import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { FormClass, ActionButton } from "./styles";
import ModalContainer from "../../../../components/ModalContainer";
import Button from "../../../../components/Button/index";
import { params } from "react-router-dom";
import { formatDateString } from "../../../../util/FormatDate";
import Toast from "../../../../util/Toast";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllOffices,
  createTellerGroup,
  getAllTellerGroups,
  getCurrencies,
} from "../../../../actions/tellerActions";
import DatePicker from "react-datepicker";
import { Select, Textarea, Input, Divider } from "@chakra-ui/react";
import { Select as AntSelect, Space } from "antd";
import { components } from "react-select";
import { default as ReactSelect } from "react-select";
import DropdownMenu from "./DropdownMenu";
import { MultiSelect } from "react-multi-select-component";
import CheckSelect from "./CheckSelect";
import { CheckboxSelect } from "./CheckDropDown";

const AddTellerGroupFormModal = ({
  resetAddTellerGroup,
  addTellerGroup,
  showModal,
  setShowModal,
  onHide,
  accountRules,
}) => {
  // const { Option } = AntSelect;

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("");
  const [description, setDescription] = useState("");
  const [officeId, setOfficeId] = useState("");
  const [accountingRuleId, setAccountingRuleId] = useState("");
  const [name, setName] = useState("");


  const [currency, setCurrency] = useState([]);
  const [optionSelected, setOptionSelected] = useState([]);

  const dispatch = useDispatch();
  const tellerGroupReducer = useSelector((state) => state.tellerReducer);
  const { allOffice } = tellerGroupReducer;

  const tellerReducer = useSelector((state) => state.tellerReducer);
  // console.log(tellerReducer, "tellerReducer");

  let { setCurrencies = [] } = tellerReducer || {};

  const [currenciesOptions, setCurrenciesOptions] = useState([]);

  useEffect(() => {
    // if (setCurrencies) {
    // setCurrenciesOptions(setCurrencies);
    if (setCurrencies) {
      const newCurrencies = [];
      setCurrencies.map((curr) =>
        newCurrencies.push({ value: curr.code, label: curr.displayLabel })
      );
      setCurrenciesOptions(newCurrencies);
    } else setCurrenciesOptions([]);
  }, [setCurrencies]);

  // console.log(currenciesOptions, "======");
  const [transactionData, setTransactionData] = useState([]);

  const [selected, setSelected] = useState([]);
  let newDate = new Date(startDate);
  let newDate2 = new Date(endDate);

  const onSubmit = (e) => {
    const func = () => {
      resetAddTellerGroup();
      dispatch(getAllTellerGroups());
    };
    if (!officeId || !name || !status || !startDate || !accountingRuleId) {
      dispatch(Toast({ text: "Enter the required fields", icon: "info" }));
    } else {
      const dataToSend = {
        startDate: formatDateString(newDate, "dd/MMM/yyyy"),
        endDate: endDate ? formatDateString(newDate2, "dd/MMM/yyyy") : "",
        dateFormat: "dd/MMM/yyyy",
        locale: "en",
        name: name,
        status: status,
        officeId: officeId,
        accountingRuleId: accountingRuleId,
        description: description,
        currencies: optionSelected,
      };
      dispatch(createTellerGroup(dataToSend, func));
      setStartDate("");
      setEndDate("");
      setStatus("");
      setDescription("");
      setOfficeId("");
      setAccountingRuleId("");
      setName("");
      onHide();
    }
  };
  useEffect(() => {
    dispatch(getAllOffices());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCurrencies());
  }, []);

  const currencyOptions = [
    { id: "1", label: "Nigerian Naria (NGN)", value: "NGN" },
    { id: "2", label: "Pound Sterling (GBP)", value: "GBP" },
    { id: "3", label: "US Dollar (USD)", value: "USD" },
    { id: "4", label: "Ghanaian Cedi (GHC)", value: "GHC" },
    { id: "5", label: "Kenyan Shilling (KES)", value: "KES" },
    { id: "6", label: "Moroccan Dirham (MAD)", value: "MAD" },
  ];

  //   {
  //     "code": "CAD",
  //     "name": "Canadian Dollar",
  //     "decimalPlaces": 2,
  //     "nameCode": "currency.CAD",
  //     "displayLabel": "Canadian Dollar [CAD]"
  // }

  // const currencyOption =
  //   currenciesOptions &&
  //   currenciesOptions.map(({ code, displayLabel }) => {
  //     return (
  //       <Option key={code} value={code}>
  //         {displayLabel}
  //       </Option>
  //     );
  //   });

  // console.log(currency, "currency");

  const handleUpdateServices = (service_Data) => {
    setOptionSelected([...service_Data]);
  };
  // console.log(optionSelected, "optionSelected ====");

  // accountRules
  return (
    <>
      {showModal ? (
        <>
          <Background>
            <ModalWrapper showModal={showModal}>
              <ModalContent>
                <Modal.Header>
                  <Modal.Title>New Teller Group</Modal.Title>
                </Modal.Header>
                <Modal.Body className="wc-modal">
                  <Form className="px-4 pt-3">
                    <GridContainer className="d-flex justify-content-between">
                      <label htmlFor="Staff" className="text-label">
                        Select office<span className="required-color">*</span>
                      </label>
                      <div className="select-100-width">
                        <Select
                          isFullWidth="true"
                          name="officeId"
                          placeholder="Select"
                          value={officeId}
                          onChange={(e) => setOfficeId(e.target.value)}
                        >
                          {!!allOffice?.length > 0
                            ? allOffice?.map((item) => {
                                return (
                                  <option key={item.id} value={item?.id}>
                                    {item?.name}
                                  </option>
                                );
                              })
                            : ""}
                        </Select>
                      </div>
                    </GridContainer>
                    <GridContainer className="d-flex justify-content-between">
                      <label htmlFor="Description" className="text-label">
                        Group Name<span className="required-color">*</span>
                      </label>
                      <Input
                        name="name"
                        onChange={(e) => setName(e.target.value)}
                        type="text"
                        placeholder="Enter new group name"
                      />
                    </GridContainer>
                    {/* Currency */}
                    <GridContainer className="d-flex justify-content-between">
                      <label htmlFor="Staff" className="text-label">
                        Currency<span className="required-color">*</span>
                      </label>
                      <div className="select-100-width">
                        <DropdownMenu
                          multiple
                          // className="searchSelect"
                          name="currency"
                          placeholder="Select"
                          updateServices={handleUpdateServices}
                          options={currenciesOptions}
                          byId
                        />
                        {/* <CheckSelect /> */}
                        {/* <CheckboxSelect /> */}
                      </div>
                    </GridContainer>
                    {/* Description */}
                    <GridContainer className="d-flex justify-content-between">
                      <label
                        htmlFor="Daily Teller Limit"
                        className="text-label"
                      >
                        Description
                      </label>
                      <div className="textarea-100-width">
                        <Textarea
                          name="description"
                          onChange={(e) => setDescription(e.target.value)}
                          style={{ fontSize: "12px" }}
                        ></Textarea>
                      </div>
                    </GridContainer>
                    <GridContainer className="d-flex justify-content-between">
                      <label htmlFor="Staff" className="text-label">
                        Accounting Rule<span className="required-color">*</span>
                      </label>
                      <div className="select-100-width">
                        <Select
                          isFullWidth="true"
                          name="accountingRuleId"
                          placeholder="Select"
                          value={accountingRuleId}
                          onChange={(e) => setAccountingRuleId(e.target.value)}
                        >
                          {!!accountRules?.length > 0
                            ? accountRules?.map((item) => {
                                return (
                                  <option key={item.id} value={item?.id}>
                                    {item?.name}
                                  </option>
                                );
                              })
                            : ""}
                        </Select>
                      </div>
                    </GridContainer>
                    <GridContainer className="d-flex justify-content-between"></GridContainer>
                    <GridContainer className="d-flex justify-content-between">
                      <label
                        htmlFor="Daily Teller Limit"
                        className="text-label"
                      >
                        Status<span className="required-color">*</span>
                      </label>
                      <div className="select-100-width">
                        <Select
                          name="status"
                          placeholder="Select status"
                          isFullWidth="true"
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option value="300">Active</option>
                          <option value="400">Inactive</option>
                        </Select>
                      </div>
                    </GridContainer>
                    <GridContainer className="d-flex">
                      <label
                        htmlFor="Daily Teller Limit"
                        className="text-label"
                      >
                        Start Date<span className="required-color">*</span>
                      </label>
                      <div className="datepicker-100-width">
                        <DatePicker
                          id="published-date"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          placeholderText="dd/mmm/yyyy"
                        />
                      </div>
                    </GridContainer>
                    <GridContainer className="d-flex">
                      <label
                        htmlFor="Daily Teller Limit"
                        className="text-label"
                      >
                        End Date
                      </label>
                      <div className="datepicker-100-width">
                        <DatePicker
                          id="published-date"
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          placeholderText="dd/mmm/yyyy"
                        />
                      </div>
                    </GridContainer>
                    <div className="d-flex justify-content-end">
                      {/* <Button onClick={onSubmit}>Create group</Button> */}
                    </div>
                    <div className="pb-3"></div>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <div className="float-right mt-1">
                    {/* <ActionButton color="#2C1DFF" onClick={onHide}> */}
                    <ActionButton
                      color="#2C1DFF"
                      onClick={() => setShowModal((prev) => !prev)}
                    >
                      <img
                        src={
                          require("../../../../assets/images/icons/cancel.svg")
                            .default
                        }
                        alt="cancel icon"
                      />
                      Cancel
                    </ActionButton>
                    <ActionButton
                      color="#2C1DFF"
                      style={{ margin: "0px 0px 0 10px" }}
                      // onClick={handleSubmitDocument}
                      onClick={onSubmit}
                    >
                      <img
                        src={
                          require("../../../../assets/images/icons/save.svg")
                            .default
                        }
                        alt="submit icon"
                      />
                      Create Group
                    </ActionButton>
                  </div>
                </Modal.Footer>
              </ModalContent>
            </ModalWrapper>
          </Background>
        </>
      ) : null}
    </>
  );
};

export default AddTellerGroupFormModal;

const Form = styled.div`
  z-index: 10;
  // border: 0.5px solid #0000004b;
  border-top: 0px;
  border-radius: 0 0 5px 5px;
`;
const GridContainer = styled.div`
  display: grid !important;
  grid-template-columns: 2fr 3fr;
  align-items: center;
  margin-bottom: 15px;
  color: #969cba;

  label {
    font-size: 18px;
    color: #969cba;
  }
`;
const Background = styled.div`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalWrapper = styled.div`
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  display: grid;
  position: relative;
  z-index: 99;
  border-radius: 10px;
`;

const ModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
`;
