import React from "react";
import { Table } from "antd";
// import LoadingLoader from "./LoadingLoader";
import LoadingLoader from "../LoadingLoader";

const columns = [
  { title: "Date / Time", dataIndex: "dateTime", key: "dateTime" },
  { title: "Session ID", dataIndex: "sessionId", key: "sessionId" },
  { title: "Account Name", dataIndex: "name", key: "name" },
  { title: "Account Number", dataIndex: "number", key: "number" },
  { title: "Amount", dataIndex: "amount", key: "amount" },
  { title: "Reference ID", dataIndex: "ref", key: "ref" },
  { title: "Narration", dataIndex: "narration", key: "narration" },
  { title: "Bank Name", dataIndex: "senderBankName", key: "senderBankName" },
  {
    title: "Transaction Status",
    dataIndex: "transactionRequestStatus",
    key: "transactionRequestStatus",
  },
];

const TableLoaderView = (props) => {
  const someChartsData = [
    {
      key: 1,
      dateTime: <LoadingLoader />,
      sessionId: <LoadingLoader />,
      name: <LoadingLoader />,
      number: <LoadingLoader />,
      amount: <LoadingLoader />,
      ref: <LoadingLoader />,
      narration: <LoadingLoader />,
      senderBankName: <LoadingLoader />,
      transactionRequestStatus: <LoadingLoader />,
    },
    {
      key: 2,
      dateTime: <LoadingLoader />,
      sessionId: <LoadingLoader />,
      name: <LoadingLoader />,
      number: <LoadingLoader />,
      amount: <LoadingLoader />,
      ref: <LoadingLoader />,
      narration: <LoadingLoader />,
      senderBankName: <LoadingLoader />,
      transactionRequestStatus: <LoadingLoader />,

    },
    {
      key: 3,
      dateTime: <LoadingLoader />,
      sessionId: <LoadingLoader />,
      name: <LoadingLoader />,
      number: <LoadingLoader />,
      amount: <LoadingLoader />,
      ref: <LoadingLoader />,
      narration: <LoadingLoader />,
      senderBankName: <LoadingLoader />,
      transactionRequestStatus: <LoadingLoader />,

    },
    {
      key: 4,
      dateTime: <LoadingLoader />,
      sessionId: <LoadingLoader />,
      name: <LoadingLoader />,
      number: <LoadingLoader />,
      amount: <LoadingLoader />,
      ref: <LoadingLoader />,
      narration: <LoadingLoader />,
      senderBankName: <LoadingLoader />,
      transactionRequestStatus: <LoadingLoader />,

    },
    {
      key: 5,
      dateTime: <LoadingLoader />,
      sessionId: <LoadingLoader />,
      name: <LoadingLoader />,
      number: <LoadingLoader />,
      amount: <LoadingLoader />,
      ref: <LoadingLoader />,
      narration: <LoadingLoader />,
      senderBankName: <LoadingLoader />,
      transactionRequestStatus: <LoadingLoader />,

    },
    {
      key: 6,
      dateTime: <LoadingLoader />,
      sessionId: <LoadingLoader />,
      name: <LoadingLoader />,
      number: <LoadingLoader />,
      amount: <LoadingLoader />,
      ref: <LoadingLoader />,
      narration: <LoadingLoader />,
      senderBankName: <LoadingLoader />,
      transactionRequestStatus: <LoadingLoader />,

    },
  ];
  return (
    <>
      <Table
        columns={columns}
        dataSource={someChartsData}
        pagination={{ defaultPageSize: 8 }}
        className="antdTable"
        defaultPageSize={8}
      />
    </>
  );
};

export default TableLoaderView;
