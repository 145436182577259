import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Select, Tag, Checkbox } from "antd";
import DatePicker from "react-datepicker";

import { ActionButton } from "../../styles";

const FilterModal = ({ onHide, setViewState }) => {
  let options = [{ value: "woodCore Fd" }, { value: "Student Fd" }];

  const tagRender = (props) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color="blue"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  const onChange = () => {};

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  return (
    <>
      <Modal.Body className="wc-modal">
        <div className=" my-4">
          <div className="row" style={{width: "50%"}}>
            <Select
              mode="multiple"
              showArrow
              tagRender={tagRender}
              placeholder="Select Fixed Deposit Product"
              style={{ width: "100%" }}
              options={options}
            />
          </div>
          <div className="border-line my-4"></div>
          <div className="row mt-5">
            <div className="col-3">
              <p>Filter Options</p>
            </div>
            <div className="col-9">
              <div className="row">
                <div className="col">
                  <Checkbox onChange={onChange}>Active Accounts</Checkbox>
                </div>
                <div className="col">
                  <Checkbox onChange={onChange}>Inactive Accounts</Checkbox>
                </div>
                <div className="col">
                  <Checkbox onChange={onChange}>Due Accounts</Checkbox>
                </div>
              </div>
            </div>
          </div>
          <div className="border-line my-4"></div>
          <div className="row">
            <div className="col-3"></div>
            <div className="col-9">
              <div className="row">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-3">Start Date</div>
                    <div className="col-9">
                      <DatePicker
                        id="from-date"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="start date"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-3">End Date</div>
                    <div className="col-9">
                      <DatePicker
                        id="from-date"
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="start date"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border-line my-4"></div>
          <div className=" mt-5">
            <div className="d-flex justify-content-between">
              <div className="">
                <p className="text-red pointer">Clear all filters</p>
              </div>
              <div className="d-flex">
                <ActionButton onClick={onHide}>Cancel </ActionButton>
                <ActionButton onClick={() => setViewState(true)}>
                  Submit{" "}
                </ActionButton>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default FilterModal;
