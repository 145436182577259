import React, { useState, useRef, useEffect } from "react";
import {
  ContentWrapper,
  ActionButtonsGroup,
  ActionButton,
  EditButton,
} from "./styles";
import { InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import ModalContainer from "../../components/ModalContainer";
import NewHook from "./components/NewHook";
import EditHook from "./components/EditHook";
import DeleteHook from "./components/DeleteHook";
import { useDispatch, useSelector } from "react-redux";
import {
  filterHook,
  clearFilteredHook,
  getAllHook,
  getHookTemplate,
} from "../../../../actions/configurationActions";
// import Toast from "../../../../../util/Toast";
import TableLoader from "./components/TableLoader";

const ManageHooksView = () => {
  const dispatch = useDispatch();
  const [modalState, toggleModal] = useState(false);
  const [modalContentName, setModalContentName] = useState("");
  const [selectedHookId, setSelectedHookId] = useState("");
  const [selectedHook, setSelectedHook] = useState({});

  const hookList = useSelector((state) => state.hookReducer);
  const hookTemplate = useSelector((state) => state.hookTemplate);
  const { hookTemplate: template } = hookTemplate;
  const { loading, hooks, filtered } = hookList;

  hooks.sort((a, b) => (a.name > b.name ? 1 : -1));
  const search = useRef("");
  const onFilterHoliday = (e) => {
    search.current.value !== ""
      ? dispatch(filterHook(e.target.value))
      : dispatch(clearFilteredHook());
  };
  useEffect(() => {
    dispatch(getHookTemplate());
    dispatch(clearFilteredHook());
    dispatch(getAllHook());
    if (filtered === null) {
      search.current.value = "";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteHook = (id) => {
    setSelectedHookId(id);
    toggleModal(true);
    setModalContentName("deleteHook");
  };
  const handleEdit = (id) => {
    setSelectedHookId(id);
    setSelectedHook(hooks.filter((item) => item.id === id)[0]);
    setModalContentName("editHook");
    toggleModal(true);
  };

  const currentContent = (e) => {
    const val = e.currentTarget.id.trim();
    if (val) {
      toggleModal(true);
      setModalContentName(val);
    }
  };
  const handleModalClose = () => toggleModal(false);
  const displayModalContent = () => {
    switch (modalContentName) {
      case "newHook":
        return <NewHook template={template} onHide={handleModalClose} />;
      case "editHook":
        return (
          <EditHook
            data={selectedHook}
            id={selectedHookId}
            template={template}
            onHide={handleModalClose}
          />
        );
      case "deleteHook":
        return <DeleteHook id={selectedHookId} onHide={handleModalClose} />;
      default:
        return;
    }
  };

  //Table configuration

  const columns = [
    {
      title: "Hook Template",
      dataIndex: "hookTemp",
      key: "hookTemp",
    },
    { title: "Hook Name", dataIndex: "hookName", key: "hookName" },
    { title: "Status", dataIndex: "status", key: "status" },
    { title: "", dataIndex: "edit", key: "edit" },
    { title: "", dataIndex: "delete", key: "delete" },
  ];
  const dataSource = [];
  const filteredHook = [];

  if (filtered !== null) {
    filtered.forEach((hook) => {
      const { id, name, isActive, displayName } = hook;
      filteredHook.push({
        key: id,
        hookTemp: name,
        hookName: displayName,
        status: (
          <p
            style={{
              color: isActive ? "#1DCB43" : "#333333",
            }}
            className="font-weight-bold"
          >
            {isActive ? "Active" : "Inactive"}
          </p>
        ),
        edit: <EditButton onClick={() => handleEdit(id)}>Edit</EditButton>,
        delete: (
          <EditButton onClick={() => handleDeleteHook(id)}>Delete</EditButton>
        ),
      });
    });
  }
  !loading &&
    hooks.forEach((hook) => {
      const { id, name, isActive, displayName } = hook;
      dataSource.push({
        key: id,
        hookTemp: name,
        hookName: displayName,
        status: (
          <p
            style={{
              color: isActive ? "#1DCB43" : "#333333",
            }}
            className="font-weight-bold"
          >
            {isActive ? "Active" : "Inactive"}
          </p>
        ),
        edit: <EditButton onClick={() => handleEdit(id)}>Edit</EditButton>,
        delete: (
          <EditButton onClick={() => handleDeleteHook(id)}>Delete</EditButton>
        ),
      });
    });

  return (
    <>
      <ModalContainer show={modalState}>
        {modalState && displayModalContent()}
      </ModalContainer>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Manage Web Hooks</h3>
            <p className="sub-title">Define hooks</p>
            <p className="font-weight sub-title mt-2">
              <span className="text-info">
                <Link to="/core/manage-utilities">Manage Utilities</Link>
              </span>{" "}
              / Manage Hooks
            </p>
          </div>
          <div className="col-sm-12 col-md-6 px-0">
            <ActionButtonsGroup>
              <InputGroup style={{ maxWidth: "450px" }}>
                <InputLeftElement
                  width="3.1rem"
                  height="50px"
                  pointerEvents="none"
                >
                  <img
                    src={
                      require("../../../../assets/images/icons/search.svg")
                        .default
                    }
                    style={{ height: "30px" }}
                    alt="Icon"
                  />
                </InputLeftElement>
                <Input
                  style={{
                    background: "#ffffff",
                    height: "50px",
                  }}
                  ref={search}
                  onChange={onFilterHoliday}
                  type="text"
                  className="mb-3"
                  placeholder="Search hook name"
                />
              </InputGroup>

              <ActionButton id="newHook" onClick={currentContent}>
                <img
                  src={
                    require("../../../../assets/images/icons/add.svg").default
                  }
                  alt="add"
                />
                New Hook
              </ActionButton>
            </ActionButtonsGroup>
          </div>
        </div>
        <ContentWrapper>
          {loading ? (
            <TableLoader />
          ) : (
            <>
              {filtered !== null ? (
                <Table columns={columns} dataSource={filteredHook} />
              ) : (
                <Table
                  columns={columns}
                  dataSource={dataSource}
                  pagination={{ defaultPageSize: 8 }}
                  defaultPageSize={8}
                />
              )}
            </>
          )}
        </ContentWrapper>
      </div>
    </>
  );
};

export default ManageHooksView;
