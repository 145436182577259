import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CurrencyFormat from "react-currency-format";
import { Link } from "react-router-dom";
import { ContentWrapper, FormClass, FormElements, FormTitle } from "../styles";
import {
  Select,
  Textarea,
  Input,
  FormControl,
  Checkbox,
} from "@chakra-ui/react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { getToolTips } from "../../../../../../actions/utilityActions";
import Toast from "../../../../../../util/Toast.js";
import EditSavingsCharges from "./EditSavingsCharges";
import {
  updateSavingsProductInfo,
  getSavingsProduct,
} from "../../../../../../actions/configurationActions";
import { SET_LOADING_STATE } from "../../../../../../constants/utilityConstants";
import ToolTipSvg from "../../../../../../assets/images/icons/tooltip.svg";

const EditSavingsProductView = ({ match, history }) => {
  const dispatch = useDispatch();
  const savingsProductReducer = useSelector(
    (state) => state.savingsProductReducer
  );

  const toolTips = useSelector((state) => state.toolTips.tooltips);

  const { loading, savingsProduct } = savingsProductReducer;
  useEffect(() => {
    // dispatch(resetSavingsProduct());
    dispatch(getSavingsProduct(match.params.id));
    // dispatch(getToolTips("savingsProduct"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id]);

  useEffect(() => {
    dispatch({ type: SET_LOADING_STATE, payload: loading });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const renderTooltip = (name) => (
    <>
      {toolTips && toolTips[name] ? (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={`tooltip-${toolTips[name]._id}`}>
              {toolTips[name].toolTip}
            </Tooltip>
          }
        >
          <img src={ToolTipSvg} style={{ marginLeft: "10px" }} alt="" />
        </OverlayTrigger>
      ) : (
        ""
      )}
    </>
  );

  const [newSavingsProduct, setNewSavingsProduct] = useState({
    productName: "",
    currency: "",
    shortName: "",
    multipleCurrency: "",
    description: "",
    decimalPlaces: "",
    nominalAnnualInterest: "",
    interestPostingPeriod: "",
    interestCompoundingPeriod: "",
    daysInYear: "",
    interestCalculated: "",
    minimumOpeningBalance: "",
    lockInPeriod: "",
    lockInPeriodSelect: "",
    applyWithdrawalFee: false,
    balanceRefinedInterest: "",
    minumumBalance: "",
    enforceMinimumBalance: false,
    isOverdraft: false,
    enableWithdrawallimit: false,
    overdraftLimit: "",
    isWitholdTax: false,
    taxGroupOptions: "",
    enableDomingTracking: false,
    overdraftPortfolio: "",
    savingPreference: "",
    savingControl: "",
    savingTransferInSuspense: "",
    interestOnSavings: "",
    writeOff: "",
    incomeFromFees: "",
    incomeFromPenalties: "",
    overdraftInterestIncome: "",
    escheatLiabilityId: "",
    withdrawalLimitNo: "",
    // interestPayableAccountId: "",
  });

  const [accountType, setAccountType] = useState("1");
  const [daysToInactive, setDaysToInactive] = useState("");
  const [daysToDormancy, setDaysToDormancy] = useState("");
  const [daysToEscheat, setDaysToEscheat] = useState("");
  const [charges, setCharges] = useState([]);
  const [minimumOpeningBalance, setMinimumOpeningBalance] = useState("");
  const [minumumBalance, setMinumumBalance] = useState("");
  const [overdraftLimit, setOverdraftLimit] = useState("");
  const [withdrawalLimit, setWithdrawalLimit] = useState("");
  const [
    minBalanceForInterestCalculation,
    setMinBalanceForInterestCalculation,
  ] = useState("");
  const [
    minOverdraftForInterestCalculation,
    setMinOverdraftForInterestCalculation,
  ] = useState("");
  const [
    nominalAnnualInterestRateOverdraft,
    setNominalAnnualInterestRateOverdraft,
  ] = useState("");
  const [processing, setLoading] = useState(false);
  const [feeToIncomeAccountMappings, setFeeToIncomeAccountMappings] = useState(
    []
  );
  const [penaltyToIncomeAccountMappings, setPenaltyToIncomeAccountMappings] =
    useState([]);
  const [
    paymentChannelToFundSourceMappings,
    setPaymentChannelToFundSourceMappings,
  ] = useState([]);

  const [overdraftPortfolio, setOverdraftPortfolio] = useState("");
  const [savingPreference, setSavingPreference] = useState("");
  const [savingControl, setSavingControl] = useState("");
  const [interestOnSavings, setInterestOnSavings] = useState("");
  const [writeOff, setWriteOff] = useState("");
  const [incomeFromFees, setIncomeFromFees] = useState("");
  const [overdraftInterestIncome, setOverdraftInterestIncome] = useState("");
  const [incomeFromPenalties, setIncomeFromPenalties] = useState("");
  const [escheatLiabilityId, setEscheatLiabilityId] = useState("");
  const [savingTransferInSuspense, setSavingTransferInSuspense] = useState("");

  const editSavingsProductHandler = (e) =>
    setNewSavingsProduct({
      ...newSavingsProduct,
      [e.target.name]: e.target.value,
    });

  useEffect(() => {
    let paymentChannel = [];
    let incomeFees = [];
    let penaltiesIncome = [];
    if (
      savingsProduct &&
      savingsProduct.paymentChannelToFundSourceMappings &&
      savingsProduct.paymentChannelToFundSourceMappings.length
    ) {
      savingsProduct.paymentChannelToFundSourceMappings.forEach((payChann) => {
        paymentChannel.push({
          fundSourceAccountId: payChann.fundSourceAccount.id,
          paymentTypeId: payChann.paymentType.id,
        });
      });
    }
    if (
      savingsProduct &&
      savingsProduct.feeToIncomeAccountMappings &&
      savingsProduct.feeToIncomeAccountMappings.length
    ) {
      savingsProduct.feeToIncomeAccountMappings.forEach((payChann) => {
        incomeFees.push({
          chargeId: payChann.charge.id,
          incomeAccountId: payChann.incomeAccount.id,
        });
      });
    }
    if (
      savingsProduct &&
      savingsProduct.penaltyToIncomeAccountMappings &&
      savingsProduct.penaltyToIncomeAccountMappings.length
    ) {
      savingsProduct.penaltyToIncomeAccountMappings.forEach((payChann) => {
        penaltiesIncome.push({
          chargeId: payChann.charge.id,
          incomeAccountId: payChann.incomeAccount.id,
        });
      });
    }
    setPaymentChannelToFundSourceMappings(paymentChannel);
    setFeeToIncomeAccountMappings(incomeFees);
    setPenaltyToIncomeAccountMappings(penaltiesIncome);
  }, [savingsProduct]);

  useEffect(() => {
    if (savingsProduct) {
      let { accountingMappings = {} } = savingsProduct;
      setMinimumOpeningBalance(
        savingsProduct ? savingsProduct.minRequiredOpeningBalance : ""
      );
      setDaysToInactive(savingsProduct ? savingsProduct.daysToInactive : "");
      setDaysToEscheat(savingsProduct ? savingsProduct.daysToEscheat : "");
      setDaysToDormancy(savingsProduct ? savingsProduct.daysToDormancy : "");
      setMinumumBalance(
        savingsProduct ? savingsProduct.minRequiredBalance : ""
      );
      setOverdraftLimit(savingsProduct ? savingsProduct.overdraftLimit : "");
      // setisEnableWithdrawallimit(
      //   savingsProduct ? savingsProduct.enableWithdrawallimit : ""
      // );

      setMinBalanceForInterestCalculation(
        savingsProduct ? savingsProduct.minBalanceForInterestCalculation : ""
      );

      setWithdrawalLimit(savingsProduct ? savingsProduct.withdrawalLimit : "");

      setMinOverdraftForInterestCalculation(
        savingsProduct ? savingsProduct.minOverdraftForInterestCalculation : ""
      );

      setNominalAnnualInterestRateOverdraft(
        savingsProduct ? savingsProduct.nominalAnnualInterestRateOverdraft : ""
      );
      setOverdraftPortfolio(
        savingsProduct &&
          accountingMappings &&
          accountingMappings.overdraftPortfolioControl &&
          accountingMappings.overdraftPortfolioControl.id
          ? accountingMappings.overdraftPortfolioControl.id
          : ""
      );
      setSavingPreference(
        savingsProduct &&
          accountingMappings &&
          accountingMappings.savingsReferenceAccount &&
          accountingMappings.savingsReferenceAccount.id
          ? accountingMappings.savingsReferenceAccount.id
          : ""
      );
      setSavingControl(
        savingsProduct &&
          accountingMappings &&
          accountingMappings.savingsControlAccount &&
          accountingMappings.savingsControlAccount.id
          ? accountingMappings.savingsControlAccount.id
          : ""
      );
      setInterestOnSavings(
        savingsProduct &&
          accountingMappings &&
          accountingMappings.interestOnSavingsAccount &&
          accountingMappings.interestOnSavingsAccount.id
          ? accountingMappings.interestOnSavingsAccount.id
          : ""
      );
      setWriteOff(
        savingsProduct &&
          accountingMappings &&
          accountingMappings.writeOffAccount &&
          accountingMappings.writeOffAccount.id
          ? accountingMappings.writeOffAccount.id
          : ""
      );
      setIncomeFromFees(
        savingsProduct &&
          accountingMappings &&
          accountingMappings.incomeFromFeeAccount &&
          accountingMappings.incomeFromFeeAccount.id
          ? accountingMappings.incomeFromFeeAccount.id
          : ""
      );
      setOverdraftInterestIncome(
        savingsProduct &&
          accountingMappings &&
          !!accountingMappings.incomeFromInterest &&
          !!accountingMappings.incomeFromInterest.id
          ? accountingMappings.incomeFromInterest.id
          : ""
      );
      setIncomeFromPenalties(
        savingsProduct &&
          accountingMappings &&
          accountingMappings.incomeFromPenaltyAccount &&
          accountingMappings.incomeFromPenaltyAccount.id
          ? accountingMappings.incomeFromPenaltyAccount.id
          : ""
      );
      setEscheatLiabilityId(
        savingsProduct &&
          accountingMappings &&
          !!accountingMappings.escheatLiabilityAccount &&
          !!accountingMappings.escheatLiabilityAccount.id
          ? accountingMappings.escheatLiabilityAccount.id
          : ""
      );
      setSavingTransferInSuspense(
        savingsProduct &&
          accountingMappings &&
          accountingMappings.transfersInSuspenseAccount &&
          accountingMappings.transfersInSuspenseAccount.id
          ? accountingMappings.transfersInSuspenseAccount.id
          : ""
      );

      setNewSavingsProduct({
        productName: savingsProduct ? savingsProduct.name : "",
        currency: savingsProduct
          ? savingsProduct.currency
            ? savingsProduct.currency.code
            : ""
          : "",
        multipleCurrency: savingsProduct
          ? savingsProduct.currency
            ? savingsProduct.currency.inMultiplesOf
            : ""
          : "",
        shortName: savingsProduct ? savingsProduct.shortName : "",
        description: savingsProduct ? savingsProduct.description : "",
        decimalPlaces: savingsProduct
          ? savingsProduct.digitsAfterDecimal
            ? savingsProduct.digitsAfterDecimal
            : "2"
          : "",
        nominalAnnualInterest: savingsProduct
          ? savingsProduct.nominalAnnualInterestRate
          : "",
        interestPostingPeriod: savingsProduct
          ? savingsProduct.interestPostingPeriodType
            ? savingsProduct.interestPostingPeriodType.id
            : ""
          : "",
        interestCompoundingPeriod: savingsProduct
          ? savingsProduct.interestCompoundingPeriodType
            ? savingsProduct.interestCompoundingPeriodType.id
            : ""
          : "",
        daysInYear: savingsProduct
          ? savingsProduct.interestCalculationDaysInYearType
            ? savingsProduct.interestCalculationDaysInYearType.id
            : ""
          : "",
        interestCalculated: savingsProduct
          ? savingsProduct.interestCalculationType
            ? savingsProduct.interestCalculationType.id
            : ""
          : "",
        minimumOpeningBalance: savingsProduct
          ? savingsProduct.minRequiredOpeningBalance
          : "",
        lockInPeriod: savingsProduct
          ? savingsProduct.lockinPeriodFrequency
          : "",
        lockInPeriodSelect: savingsProduct
          ? savingsProduct.lockinPeriodFrequencyType
            ? savingsProduct.lockinPeriodFrequencyType.id
            : ""
          : "",
        applyWithdrawalFee: savingsProduct
          ? savingsProduct.withdrawalFeeForTransfers
          : false,
        enableWithdrawallimit: savingsProduct
          ? savingsProduct.allowWithdrawalLimit
          : false,
        withdrawalLimit: savingsProduct ? savingsProduct.withdrawalLimit : "",
        balanceRefinedInterest: "",
        minumumBalance: savingsProduct ? savingsProduct.minRequiredBalance : "",
        enforceMinimumBalance: savingsProduct
          ? savingsProduct.enforceMinRequiredBalance
          : false,
        isOverdraft: savingsProduct ? savingsProduct.allowOverdraft : false,
        overdraftLimit: savingsProduct ? savingsProduct.overdraftLimit : "",
        minBalanceForInterestCalculation: savingsProduct
          ? savingsProduct.minBalanceForInterestCalculation
          : "",
        minOverdraftForInterestCalculation: savingsProduct
          ? savingsProduct.minOverdraftForInterestCalculation
          : "",
        nominalAnnualInterestRateOverdraft: savingsProduct
          ? savingsProduct.nominalAnnualInterestRateOverdraft
          : "",
        isWitholdTax: savingsProduct ? savingsProduct.withHoldTax : false,
        taxGroupOptions: savingsProduct ? savingsProduct.taxGroup?.id : "",
        enableDomingTracking: savingsProduct
          ? savingsProduct.isDormancyTrackingActive
          : false,
        overdraftPortfolio:
          accountingMappings &&
          accountingMappings.overdraftPortfolioControl &&
          accountingMappings.overdraftPortfolioControl.id
            ? accountingMappings.overdraftPortfolioControl.id
            : "",
        savingPreference:
          accountingMappings &&
          accountingMappings.savingsReferenceAccount &&
          accountingMappings.savingsReferenceAccount.id
            ? accountingMappings.savingsReferenceAccount.id
            : "",
        savingControl:
          accountingMappings &&
          accountingMappings.savingsControlAccount &&
          accountingMappings.savingsControlAccount.id
            ? accountingMappings.savingsControlAccount.id
            : "",
        savingTransferInSuspense:
          accountingMappings &&
          accountingMappings.transfersInSuspenseAccount &&
          accountingMappings.transfersInSuspenseAccount.id
            ? accountingMappings.transfersInSuspenseAccount.id
            : "",
        interestOnSavings:
          accountingMappings &&
          accountingMappings.interestOnSavingsAccount &&
          accountingMappings.interestOnSavingsAccount.id
            ? accountingMappings.interestOnSavingsAccount.id
            : "",
        writeOff:
          accountingMappings &&
          accountingMappings.writeOffAccount &&
          accountingMappings.writeOffAccount.id
            ? accountingMappings.writeOffAccount.id
            : "",
        incomeFromFees:
          accountingMappings &&
          accountingMappings.incomeFromFeeAccount &&
          accountingMappings.incomeFromFeeAccount.id
            ? accountingMappings.incomeFromFeeAccount.id
            : "",
        incomeFromPenalties:
          accountingMappings &&
          accountingMappings.incomeFromPenaltyAccount &&
          accountingMappings.incomeFromPenaltyAccount.id
            ? accountingMappings.incomeFromPenaltyAccount.id
            : "",
        overdraftInterestIncome:
          accountingMappings &&
          !!accountingMappings.incomeFromInterest &&
          !!accountingMappings.incomeFromInterest.id
            ? accountingMappings.incomeFromInterest.id
            : "",
        escheatLiabilityId:
          accountingMappings &&
          !!accountingMappings.escheatLiabilityAccount &&
          !!accountingMappings.escheatLiabilityAccount.id
            ? accountingMappings.escheatLiabilityAccount.id
            : "",
        // interestPayableAccountId:
        //   accountingMappings &&
        //   !!accountingMappings.interestPayableAccount &&
        //   !!accountingMappings.interestPayableAccount.id
        //     ? savingsProduct.accountingMappings.interestPayableAccount.id
        //     : "",
      });

      setCharges(savingsProduct.charges);
      setAccountType(
        savingsProduct.accountingRule
          ? String(savingsProduct.accountingRule.id)
          : "1"
      );
    }
  }, [savingsProduct]);

  const handleSubmit = async () => {
    let data = {
      locale: "en",
      name: newSavingsProduct.productName,
      shortName: newSavingsProduct.shortName,
      description: newSavingsProduct.description,
      currencyCode: newSavingsProduct.currency,
      digitsAfterDecimal: newSavingsProduct.decimalPlaces,
      inMultiplesOf: newSavingsProduct.multipleCurrency
        ? Number(newSavingsProduct.multipleCurrency)
        : "",
      nominalAnnualInterestRate: newSavingsProduct.nominalAnnualInterest,
      interestCompoundingPeriodType:
        newSavingsProduct.interestCompoundingPeriod,
      interestPostingPeriodType: newSavingsProduct.interestPostingPeriod,
      interestCalculationType: newSavingsProduct.interestCalculated,
      interestCalculationDaysInYearType: newSavingsProduct.daysInYear,
      minRequiredOpeningBalance: minimumOpeningBalance,
      allowOverdraft: newSavingsProduct.isOverdraft,
      overdraftLimit: overdraftLimit ? Number(overdraftLimit) : "",
      minBalanceForInterestCalculation: minBalanceForInterestCalculation
        ? Number(minBalanceForInterestCalculation)
        : "",
      minOverdraftForInterestCalculation:
        newSavingsProduct.isOverdraft && minOverdraftForInterestCalculation
          ? Number(minOverdraftForInterestCalculation)
          : "",
      nominalAnnualInterestRateOverdraft: nominalAnnualInterestRateOverdraft
        ? Number(nominalAnnualInterestRateOverdraft)
        : "",
      minRequiredBalance: minumumBalance ? Number(minumumBalance) : "",
      enforceMinRequiredBalance: newSavingsProduct.enforceMinimumBalance,
      lockinPeriodFrequency: newSavingsProduct.lockInPeriod,
      lockinPeriodFrequencyType: newSavingsProduct.lockInPeriodSelect,
      taxGroupId: newSavingsProduct.taxGroupOptions,
      withHoldTax: newSavingsProduct.isWitholdTax,
      withdrawalFeeForTransfers: newSavingsProduct.applyWithdrawalFee,
      accountingRule: accountType,
      charges: charges.length > 0 ? charges.map(({ id }) => ({ id })) : [],
      // savingsReferenceAccountId: newSavingsProduct.savingPreference,
      // transfersInSuspenseAccountId: newSavingsProduct.savingTransferInSuspense,
      // savingsControlAccountId: newSavingsProduct.savingControl,
      // overdraftPortfolioControlId: newSavingsProduct.overdraftPortfolio,
      // interestOnSavingsAccountId: newSavingsProduct.interestOnSavings,
      // incomeFromFeeAccountId: newSavingsProduct.incomeFromFees,
      // incomeFromPenaltyAccountId: newSavingsProduct.incomeFromPenalties,
      savingsReferenceAccountId: savingPreference,
      transfersInSuspenseAccountId: savingTransferInSuspense,
      savingsControlAccountId: savingControl,
      overdraftPortfolioControlId: overdraftPortfolio,
      interestOnSavingsAccountId: interestOnSavings,
      incomeFromFeeAccountId: incomeFromFees,
      incomeFromPenaltyAccountId: incomeFromPenalties,
      isDormancyTrackingActive: newSavingsProduct.enableDomingTracking,
      feeToIncomeAccountMappings,
      penaltyToIncomeAccountMappings,
      paymentChannelToFundSourceMappings,
      daysToInactive,
      daysToDormancy,
      daysToEscheat,
      // writeOffAccountId: newSavingsProduct.writeOff,
      // incomeFromInterestId: newSavingsProduct.overdraftInterestIncome,
      // escheatLiabilityId: newSavingsProduct.escheatLiabilityId,
      writeOffAccountId: writeOff,
      incomeFromInterestId: overdraftInterestIncome,
      escheatLiabilityId: escheatLiabilityId,
      allowWithdrawalLimit: newSavingsProduct.enableWithdrawallimit,
      withdrawalLimit,
      // interestPayableAccountId: newSavingsProduct.interestPayableAccountId,
    };
    if (!newSavingsProduct.productName || !newSavingsProduct.shortName) {
      Toast({
        text: "Please fill the required fields",
        icon: "error",
        timer: 2000,
      });
    } else {
      // const newObject = { ...data };
      // for (const key in newObject) {
      //   if (Object.prototype.hasOwnProperty.call(newObject, key)) {
      //     const element = newObject[key];
      //     if (!element && typeof element !== "number") {
      //       delete newObject[key];
      //     } else if (
      //       element === null ||
      //       element === ""
      //       // element === null
      //     ) {
      //       delete newObject[key];
      //     } else if ((element + "").length <= 0) {
      //       delete newObject[key];
      //     }
      //   }
      // }

      // data = newObject;

      setLoading(true);
      await dispatch(updateSavingsProductInfo(data, match.params.id, history));
      setLoading(false);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3 mt-1">
            <h3 className="page-title">Edit savings products</h3>
            <p className="font-weight sub-title mt-2">
              {/* <span className="text-info">
              <Link to="/core/configuration">Configuration </Link>
            </span>
            /{" "} */}
              <span className="text-info">
                <Link to="/core/products">Products </Link>
              </span>
              /{" "}
              <span className="text-info">
                <Link to="/core/products/savings-products">
                  Savings products{" "}
                </Link>
              </span>
              / Edit savings products
            </p>
          </div>
        </div>

        <div className="row" style={{ minHeight: "calc(100vh - 250px)" }}>
          <div className="col-sm-12 col-md-12">
            <ContentWrapper style={{ padding: "2rem" }}>
              <div className="container">
                {/* Details */}
                <FormTitle>
                  <p className="text-info">Details</p>
                  <div className="lineThrough"></div>
                </FormTitle>
                <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                  <div className="col-sm-12 col-md-6 px-0 ">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label d-flex flex-align-center">
                            Product name* {renderTooltip("Product name")}
                          </label>
                          <Input
                            style={{ width: "60%" }}
                            size="md"
                            name="productName"
                            value={newSavingsProduct.productName}
                            onChange={editSavingsProductHandler}
                          />
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                  <div className="col-sm-12 col-md-6 px-0">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label d-flex flex-align-center">
                            Currency* {renderTooltip("Currency")}
                          </label>
                          <Select
                            size="md"
                            name="currency"
                            value={newSavingsProduct.currency}
                            onChange={editSavingsProductHandler}
                          >
                            <option value="">Select Currency</option>
                            {savingsProduct !== undefined && savingsProduct
                              ? savingsProduct.currencyOptions &&
                                savingsProduct.currencyOptions.map((cur) => (
                                  <option value={cur.code} key={cur.code}>
                                    {cur.displayLabel}
                                  </option>
                                ))
                              : null}
                          </Select>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                </div>

                <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                  <div className="col-sm-12 col-md-6 px-0 ">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label d-flex flex-align-center">
                            Short Name* {renderTooltip("Short Name")}
                          </label>
                          <Input
                            style={{ width: "60%" }}
                            size="md"
                            name="shortName"
                            value={newSavingsProduct.shortName}
                            onChange={editSavingsProductHandler}
                          />
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                  <div className="col-sm-12 col-md-6 px-0">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label d-flex flex-align-center">
                            Currency In Multiples Of*{" "}
                            {renderTooltip("Currency In Multiples Of")}
                          </label>
                          <Input
                            style={{ width: "60%" }}
                            size="md"
                            name="multipleCurrency"
                            value={newSavingsProduct.multipleCurrency}
                            type="number"
                            onChange={editSavingsProductHandler}
                          />
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                </div>

                <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                  <div className="col-sm-12 col-md-6 px-0 ">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label d-flex flex-align-center">
                            Description
                            {renderTooltip("Description")}
                          </label>
                          <Textarea
                            style={{ width: "60%", fontSize: "12px" }}
                            size="md"
                            name="description"
                            value={newSavingsProduct.description}
                            onChange={editSavingsProductHandler}
                          />
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                  <div className="col-sm-12 col-md-6 px-0">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label d-flex flex-align-center">
                            Decimal Places*
                            {renderTooltip("Decimal Places")}
                          </label>
                          <Input
                            style={{ width: "60%" }}
                            size="md"
                            name="decimalPlaces"
                            value={newSavingsProduct.decimalPlaces}
                            onChange={editSavingsProductHandler}
                          />
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                </div>
                {/* Terms */}
                <div className="pt-5">
                  <FormTitle>
                    <p className="text-info">Terms</p>
                    <div className="lineThrough"></div>
                  </FormTitle>
                </div>
                <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                  <div className="col-sm-12 col-md-6 px-0 ">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label d-flex flex-align-center">
                            Nominal Annual Interest*{" "}
                            {renderTooltip("Nominal Annual Interest")}
                          </label>
                          <Input
                            style={{ width: "60%" }}
                            size="md"
                            type="number"
                            name="nominalAnnualInterest"
                            value={newSavingsProduct.nominalAnnualInterest}
                            onChange={editSavingsProductHandler}
                          />
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                  <div className="col-sm-12 col-md-6 px-0">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label d-flex flex-align-center">
                            Interest Posting Period*{" "}
                            {renderTooltip("Interest Posting Period")}
                          </label>
                          <Select
                            size="md"
                            name="interestPostingPeriod"
                            value={newSavingsProduct.interestPostingPeriod}
                            onChange={editSavingsProductHandler}
                          >
                            <option value="">Select posting period</option>
                            {savingsProduct !== undefined && savingsProduct
                              ? savingsProduct.interestPostingPeriodTypeOptions &&
                                savingsProduct.interestPostingPeriodTypeOptions.map(
                                  (cur) => (
                                    <option value={cur.id} key={cur.id}>
                                      {cur.value}
                                    </option>
                                  )
                                )
                              : null}
                          </Select>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                  <div className="col-sm-12 col-md-6 px-0 ">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label d-flex flex-align-center">
                            Interest Compounding Period*
                            {renderTooltip("Interest Compounding Period")}
                          </label>
                          <Select
                            size="md"
                            name="interestCompoundingPeriod"
                            value={newSavingsProduct.interestCompoundingPeriod}
                            onChange={editSavingsProductHandler}
                          >
                            <option value="">Select compounding period</option>
                            {savingsProduct !== undefined && savingsProduct
                              ? savingsProduct.interestCompoundingPeriodTypeOptions &&
                                savingsProduct.interestCompoundingPeriodTypeOptions.map(
                                  (cur) => (
                                    <option value={cur.id} key={cur.id}>
                                      {cur.value}
                                    </option>
                                  )
                                )
                              : null}
                          </Select>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                  <div className="col-sm-12 col-md-6 px-0">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label d-flex flex-align-center">
                            Days In The Year*
                            {renderTooltip(" Days In The Year")}
                          </label>
                          <Select
                            size="md"
                            name="daysInYear"
                            value={newSavingsProduct.daysInYear}
                            onChange={editSavingsProductHandler}
                          >
                            <option value="">Select Days in the year</option>
                            {savingsProduct !== undefined && savingsProduct
                              ? savingsProduct.interestCalculationDaysInYearTypeOptions &&
                                savingsProduct.interestCalculationDaysInYearTypeOptions.map(
                                  (cur) => (
                                    <option value={cur.id} key={cur.id}>
                                      {cur.value}
                                    </option>
                                  )
                                )
                              : null}
                          </Select>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                  <div className="col-sm-12 col-md-6 px-0">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label d-flex flex-align-center">
                            Interest Calculated Using*
                            {renderTooltip("Interest Calculated Using")}
                          </label>
                          <Select
                            size="md"
                            name="interestCalculated"
                            value={newSavingsProduct.interestCalculated}
                            onChange={editSavingsProductHandler}
                          >
                            <option value="">Select using</option>
                            {savingsProduct !== undefined && savingsProduct
                              ? savingsProduct.interestCalculationTypeOptions &&
                                savingsProduct.interestCalculationTypeOptions.map(
                                  (cur) => (
                                    <option value={cur.id} key={cur.id}>
                                      {cur.value}
                                    </option>
                                  )
                                )
                              : null}
                          </Select>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                </div>

                {/* Settings */}
                <div className="pt-5">
                  <FormTitle>
                    <p className="text-info">Settings</p>
                    <div className="lineThrough"></div>
                  </FormTitle>

                  <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                    <div className="col-sm-12 col-md-6 px-0 ">
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label d-flex flex-align-center">
                              Minimum Opening Balance*
                              {renderTooltip("Minimum Opening Balance")}
                            </label>
                            <CurrencyFormat
                              value={minimumOpeningBalance}
                              name="minimumOpeningBalance"
                              thousandSeparator={true}
                              className="amount-input"
                              // placeholder="Minimum"
                              style={{
                                width: "60%",
                              }}
                              // prefix={"$"}
                              onValueChange={(values) => {
                                const { value } = values;
                                // formattedValue = $2,223
                                // value ie, 2223
                                setMinimumOpeningBalance(value);
                              }}
                            />
                          </div>
                        </FormElements>
                      </FormClass>
                    </div>
                    <div className="col-sm-12 col-md-6 px-0">
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label d-flex flex-align-center">
                              Lock-In Period
                              {renderTooltip("Lock-In Period")}
                            </label>

                            <Input
                              style={{ width: "60%" }}
                              size="md"
                              name="lockInPeriod"
                              type="number"
                              value={newSavingsProduct.lockInPeriod}
                              onChange={editSavingsProductHandler}
                            />
                          </div>
                        </FormElements>
                      </FormClass>
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label d-flex flex-align-center"></label>
                            <Select
                              size="md"
                              name="lockInPeriodSelect"
                              value={newSavingsProduct.lockInPeriodSelect}
                              onChange={editSavingsProductHandler}
                            >
                              <option value="">Select one</option>
                              {savingsProduct !== undefined && savingsProduct
                                ? savingsProduct.lockinPeriodFrequencyTypeOptions &&
                                  savingsProduct.lockinPeriodFrequencyTypeOptions.map(
                                    (cur) => (
                                      <option value={cur.id} key={cur.code}>
                                        {cur.value}
                                      </option>
                                    )
                                  )
                                : null}
                            </Select>
                          </div>
                        </FormElements>
                      </FormClass>
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                    <div className="col-sm-12 col-md-6 px-0 ">
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label d-flex flex-align-center">
                              Apply Withdrawal Fee For Transfer
                              {renderTooltip(
                                "Apply Withdrawal Fee For Transfer"
                              )}
                            </label>
                            <FormControl style={{ width: "60%" }}>
                              <Checkbox
                                size="md"
                                name="applyWithdrawalFee"
                                isChecked={
                                  newSavingsProduct?.applyWithdrawalFee
                                }
                                value={newSavingsProduct.applyWithdrawalFee}
                                onChange={(e) =>
                                  setNewSavingsProduct((p) => ({
                                    ...p,
                                    applyWithdrawalFee: e.target.checked,
                                  }))
                                }
                              />
                            </FormControl>
                          </div>
                        </FormElements>
                      </FormClass>
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label d-flex flex-align-center">
                              Enforce Minimum Balance
                              {renderTooltip("Enforce Minimum Balance")}
                            </label>
                            <FormControl style={{ width: "60%" }}>
                              <Checkbox
                                size="md"
                                name="enforceMinimumBalance"
                                isChecked={
                                  newSavingsProduct?.enforceMinimumBalance
                                }
                                value={newSavingsProduct.enforceMinimumBalance}
                                onChange={(e) =>
                                  setNewSavingsProduct((p) => ({
                                    ...p,
                                    enforceMinimumBalance: e.target.checked,
                                  }))
                                }
                              />
                            </FormControl>
                          </div>
                        </FormElements>
                      </FormClass>
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label d-flex flex-align-center">
                              Is Overdraft Allowed
                              {renderTooltip("Is Overdraft Allowed")}
                            </label>
                            <FormControl style={{ width: "60%" }}>
                              <Checkbox
                                size="md"
                                name="isOverdraft"
                                isChecked={newSavingsProduct?.isOverdraft}
                                value={newSavingsProduct.isOverdraft}
                                onChange={(e) =>
                                  setNewSavingsProduct((p) => ({
                                    ...p,
                                    isOverdraft: e.target.checked,
                                  }))
                                }
                              />
                            </FormControl>
                          </div>
                        </FormElements>
                      </FormClass>

                      {newSavingsProduct.isOverdraft && (
                        <>
                          <FormClass>
                            <FormElements>
                              <div className="form-group d-flex justify-content-between align-items-center">
                                <label className="text-label d-flex flex-align-center">
                                  Maximum Overdraft Amount Limit
                                  {renderTooltip(
                                    "Maximum Overdraft Amount Limit"
                                  )}
                                </label>
                                <CurrencyFormat
                                  value={overdraftLimit}
                                  name="overdraftLimit"
                                  thousandSeparator={true}
                                  className="amount-input"
                                  // placeholder="Minimum"
                                  style={{
                                    width: "60%",
                                  }}
                                  // prefix={"$"}
                                  onValueChange={(values) => {
                                    const { value } = values;
                                    // formattedValue = $2,223
                                    // value ie, 2223
                                    setOverdraftLimit(value);
                                  }}
                                />
                              </div>
                            </FormElements>
                          </FormClass>
                          <FormClass>
                            <FormElements>
                              <div className="form-group d-flex justify-content-between align-items-center">
                                <label className="text-label d-flex flex-align-center">
                                  Min Overdraft Required For Interest
                                  Calculation
                                  {renderTooltip(
                                    "Min Overdraft Required For Interest Calculation"
                                  )}
                                </label>
                                <CurrencyFormat
                                  value={minOverdraftForInterestCalculation}
                                  name="minOverdraftForInterestCalculation"
                                  thousandSeparator={true}
                                  className="amount-input"
                                  // placeholder="Minimum"
                                  style={{
                                    width: "60%",
                                  }}
                                  // prefix={"$"}
                                  onValueChange={(values) => {
                                    const { value } = values;
                                    // formattedValue = $2,223
                                    // value ie, 2223
                                    setMinOverdraftForInterestCalculation(
                                      value
                                    );
                                  }}
                                />
                              </div>
                            </FormElements>
                          </FormClass>
                          <FormClass>
                            <FormElements>
                              <div className="form-group d-flex justify-content-between align-items-center">
                                <label className="text-label d-flex flex-align-center">
                                  Nominal Annual Interest Rate For Overdraft
                                  {renderTooltip(
                                    "Nominal Annual Interest Rate For Overdraft"
                                  )}
                                </label>
                                <CurrencyFormat
                                  value={nominalAnnualInterestRateOverdraft}
                                  name="nominalAnnualInterestRateOverdraft"
                                  thousandSeparator={true}
                                  className="amount-input"
                                  // placeholder="Minimum"
                                  style={{
                                    width: "60%",
                                  }}
                                  // prefix={"$"}
                                  onValueChange={(values) => {
                                    const { value } = values;
                                    // formattedValue = $2,223
                                    // value ie, 2223
                                    setNominalAnnualInterestRateOverdraft(
                                      value
                                    );
                                  }}
                                />
                              </div>
                            </FormElements>
                          </FormClass>
                        </>
                      )}
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label d-flex flex-align-center">
                              Is Withhold Tax Applicable
                              {renderTooltip("Is Withhold Tax Applicable")}
                            </label>
                            <FormControl style={{ width: "60%" }}>
                              <Checkbox
                                size="md"
                                name="isWitholdTax"
                                isChecked={newSavingsProduct?.isWitholdTax}
                                value={newSavingsProduct.isWitholdTax}
                                onChange={(e) =>
                                  setNewSavingsProduct((p) => ({
                                    ...p,
                                    isWitholdTax: e.target.checked,
                                  }))
                                }
                              />
                            </FormControl>
                          </div>
                        </FormElements>
                      </FormClass>
                      {newSavingsProduct.isWitholdTax && (
                        <FormClass>
                          <FormElements>
                            <div className="form-group d-flex justify-content-between align-items-center">
                              <label className="text-label d-flex flex-align-center">
                                Tax Group
                                {renderTooltip("Tax Group")}
                              </label>
                              <Select
                                size="md"
                                name="taxGroupOptions"
                                value={newSavingsProduct.taxGroupOptions}
                                onChange={editSavingsProductHandler}
                              >
                                <option value="">Select an option</option>
                                {savingsProduct !== undefined && savingsProduct
                                  ? savingsProduct.taxGroupOptions &&
                                    savingsProduct.taxGroupOptions.map(
                                      (cur) => (
                                        <option value={cur.id} key={cur.id}>
                                          {cur.name}
                                        </option>
                                      )
                                    )
                                  : null}
                              </Select>
                            </div>
                          </FormElements>
                        </FormClass>
                      )}
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label d-flex flex-align-center">
                              Enable Dormancy Tracking
                              {renderTooltip("Enable Dormancy Tracking")}
                            </label>
                            <FormControl style={{ width: "60%" }}>
                              <Checkbox
                                size="md"
                                name="enableDomingTracking"
                                isChecked={
                                  newSavingsProduct?.enableDomingTracking
                                }
                                value={newSavingsProduct.enableDomingTracking}
                                onChange={(e) =>
                                  setNewSavingsProduct((p) => ({
                                    ...p,
                                    enableDomingTracking: e.target.checked,
                                  }))
                                }
                              />
                            </FormControl>
                          </div>
                          {newSavingsProduct.enableDomingTracking ? (
                            <>
                              {" "}
                              <div className="form-group d-flex justify-content-between align-items-center">
                                <label className="text-label d-flex flex-align-center">
                                  Number Of Days To Inactive Sub-Status
                                  {renderTooltip(
                                    "Number Of Days To Inactive Sub-Status"
                                  )}
                                </label>

                                <Input
                                  style={{ width: "60%" }}
                                  size="md"
                                  name="daysToInactive"
                                  type="number"
                                  value={daysToInactive}
                                  onChange={(e) =>
                                    setDaysToInactive(e.target.value)
                                  }
                                />
                              </div>{" "}
                              <div className="form-group d-flex justify-content-between align-items-center">
                                <label className="text-label d-flex flex-align-center">
                                  Number Of Days To Dormant Sub-Status
                                  {renderTooltip(
                                    "Number Of Days To Dormant Sub-Status"
                                  )}
                                </label>

                                <Input
                                  style={{ width: "60%" }}
                                  size="md"
                                  name="daysToInactive"
                                  type="number"
                                  value={daysToDormancy}
                                  onChange={(e) =>
                                    setDaysToDormancy(e.target.value)
                                  }
                                />
                              </div>{" "}
                              <div className="form-group d-flex justify-content-between align-items-center">
                                <label className="text-label d-flex flex-align-center">
                                  Number Of Days To Escheat
                                  {renderTooltip("Number Of Days To Escheat")}
                                </label>

                                <Input
                                  style={{ width: "60%" }}
                                  size="md"
                                  name="daysToInactive"
                                  type="number"
                                  value={daysToEscheat}
                                  onChange={(e) =>
                                    setDaysToEscheat(e.target.value)
                                  }
                                />
                              </div>
                            </>
                          ) : null}
                        </FormElements>
                      </FormClass>
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label d-flex flex-align-center">
                              Enable Withdrawal Count For Interest Posting
                              {renderTooltip(
                                "Enable withdrawal limit on interst posting"
                              )}
                            </label>
                            <FormControl style={{ width: "60%" }}>
                              <Checkbox
                                size="md"
                                name="enableWithdrawallimit"
                                // value={enableWithdrawallimit}
                                value={newSavingsProduct?.enableWithdrawallimit}
                                isChecked={
                                  newSavingsProduct?.enableWithdrawallimit
                                }
                                onChange={(e) =>
                                  setNewSavingsProduct((p) => ({
                                    ...p,
                                    enableWithdrawallimit: e.target.checked,
                                  }))
                                }
                              />
                            </FormControl>
                          </div>
                        </FormElements>
                      </FormClass>
                      {newSavingsProduct?.enableWithdrawallimit && (
                        <FormClass>
                          <FormElements>
                            <div className="form-group d-flex justify-content-between align-items-center">
                              <label className="text-label d-flex flex-align-center">
                                Withdrawal Limit Number
                                {renderTooltip("Number of withdrawals")}
                              </label>
                              <Input
                                style={{ width: "60%" }}
                                size="md"
                                type="text"
                                name="withdrawalLimit"
                                value={withdrawalLimit}
                                onChange={(e) =>
                                  setWithdrawalLimit(e.target.value)
                                }
                                // onChange={createSavingsProductHandler}
                              />
                            </div>
                          </FormElements>
                        </FormClass>
                      )}
                    </div>
                    <div className="col-sm-12 col-md-6 px-0">
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label d-flex flex-align-center">
                              Min Balance Defined For Interest Calculation
                              {renderTooltip(
                                "Min Balance Defined For Interest Calculation"
                              )}
                            </label>
                            <CurrencyFormat
                              value={minBalanceForInterestCalculation}
                              name="minBalanceForInterestCalculation"
                              thousandSeparator={true}
                              className="amount-input"
                              // placeholder="Minimum"
                              style={{
                                width: "60%",
                              }}
                              // prefix={"$"}
                              onValueChange={(values) => {
                                const { value } = values;
                                // formattedValue = $2,223
                                // value ie, 2223
                                setMinBalanceForInterestCalculation(value);
                              }}
                            />
                          </div>
                        </FormElements>
                      </FormClass>
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label d-flex flex-align-center">
                              Minimum Balance
                              {renderTooltip("Minimum Balance")}
                            </label>
                            <CurrencyFormat
                              value={minumumBalance}
                              name="minumumBalance"
                              thousandSeparator={true}
                              className="amount-input"
                              // placeholder="Minimum"
                              style={{
                                width: "60%",
                              }}
                              // prefix={"$"}
                              onValueChange={(values) => {
                                const { value } = values;
                                // formattedValue = $2,223
                                // value ie, 2223
                                setMinumumBalance(value);
                              }}
                            />
                          </div>
                        </FormElements>
                      </FormClass>
                    </div>
                  </div>
                </div>

                {/* Charges */}
                <div className="pt-5">
                  <FormTitle>
                    <p className="text-info">Charges</p>
                    <div className="lineThrough"></div>
                  </FormTitle>
                  <EditSavingsCharges
                    template={savingsProduct}
                    overdraftPortfolio={overdraftPortfolio}
                    savingPreference={savingPreference}
                    savingControl={savingControl}
                    interestOnSavings={interestOnSavings}
                    writeOff={writeOff}
                    incomeFromFees={incomeFromFees}
                    incomeFromPenalties={incomeFromPenalties}
                    overdraftInterestIncome={overdraftInterestIncome}
                    savingTransferInSuspense={savingTransferInSuspense}
                    escheatLiabilityId={escheatLiabilityId}
                    // overdraftPortfolio={newSavingsProduct.overdraftPortfolio}
                    // savingPreference={newSavingsProduct.savingPreference}
                    // savingControl={newSavingsProduct.savingControl}
                    // savingTransferInSuspense={
                    //   newSavingsProduct.savingTransferInSuspense
                    // }
                    // interestOnSavings={newSavingsProduct.interestOnSavings}
                    // writeOff={newSavingsProduct.writeOff}
                    // incomeFromFees={newSavingsProduct.incomeFromFees}
                    // incomeFromPenalties={newSavingsProduct.incomeFromPenalties}
                    // overdraftInterestIncome={
                    //   newSavingsProduct.overdraftInterestIncome
                    // }
                    // escheatLiabilityId={newSavingsProduct.escheatLiabilityId}
                    // interestPayableAccountId={
                    //   newSavingsProduct.interestPayableAccountId
                    // }
                    setCharges={setCharges}
                    charges={charges}
                    setNumberOfMapFeeToIncome={setFeeToIncomeAccountMappings}
                    numberOfMapFeeToIncome={feeToIncomeAccountMappings}
                    setNumberOfMapPenalties={setPenaltyToIncomeAccountMappings}
                    numberOfMapPenalties={penaltyToIncomeAccountMappings}
                    setNumberOfConfigFS={setPaymentChannelToFundSourceMappings}
                    numberOfConfigFS={paymentChannelToFundSourceMappings}
                    editSavingsProductHandler={editSavingsProductHandler}
                    setRadio={setAccountType}
                    radio={accountType}
                    loading={processing}
                    handleSubmit={handleSubmit}
                    renderTooltip={renderTooltip}
                    setOverdraftPortfolio={setOverdraftPortfolio}
                    setSavingPreference={setSavingPreference}
                    setSavingControl={setSavingControl}
                    setInterestOnSavings={setInterestOnSavings}
                    setWriteOff={setWriteOff}
                    setIncomeFromFees={setIncomeFromFees}
                    setOverdraftInterestIncome={setOverdraftInterestIncome}
                    setIncomeFromPenalties={setIncomeFromPenalties}
                    Ï
                    setEscheatLiabilityId={setEscheatLiabilityId}
                    setSavingTransferInSuspense={setSavingTransferInSuspense}
                  />
                </div>
              </div>
            </ContentWrapper>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSavingsProductView;
