/**
 * Add common config values here
 */

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  apiGateway: {
    BASE_API_URL: process.env.REACT_APP_BASE_API_URL,
    WOODCORE_TENANT: process.env.REACT_APP_SIGNATURE,
    DASH_API_URL: process.env.REACT_APP_DASH_API_URL,
    IS_PRODUCTION: process.env.REACT_APP_IS_PRODUCTION,
    PRE_AUTH_API_URL: process.env.REACT_APP_PRE_AUTH_API_URL,
    REPORT_API_URL: process.env.REACT_APP_REPORT_API_URL,
    FD_REPORT_API_URL: process.env.REACT_APP_FD_REPORT_API_URL,
    DYNAMIC_REPORT_API_URL: process.env.REACT_APP_DYNAMIC_REPORT_API_URL,
    TOOL_TIP_API_URL: process.env.REACT_APP_TOOLTIP_API_URL,
    DOCUMENT_API_URL: process.env.REACT_APP_DOCUMENT_API_URL,
    UTILITY_API_URL: process.env.REACT_APP_UTILITY_API_URL,
    SPARK_API_URL: process.env.REACT_APP_SPARK_API_URL,
    TRANSACTION_API_URL: process.env.REACT_APP_TRANSACTION_API_URL,
    FA_API_URL: process.env.REACT_APP_2FA_API_URL,
    CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
    CLIENT_SECRET: process.env.REACT_APP_CLIENT_SECRET,
    GRANT_TYPE: process.env.REACT_APP_GRANT_TYPE,
    IDENTITY_BASE_API_URL: process.env.REACT_APP_IDENTITY_BASE_API_URL,
    INWARD_BASE_API_UR: process.env.REACT_APP_INWARD_BASE_API_URL,
    OUTWARD_BASE_API_UR: process.env.REACT_APP_OUTWARD_BASE_API_URL,
  },
};
