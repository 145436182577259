import styled from "styled-components";

export const ActionButtonsGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  select,
  .date {
    // background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    border-radius: 3px;
    height: 50px;
    min-width: 80px;
    font-size: 13px;
    border: none;
  }
`;

export const ContentWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 0px #ededf6;
  min-height: 70vh;
  margin-top: 20px;
  table {
    background: #ffffff;
    border: 1px solid rgba(46, 91, 255, 0.08) !important;
    box-sizing: border-box;
    border-radius: 1px;

    .md-font-weight {
      
      font-style: normal;
      font-size: 1rem;
      line-height: 20px;
    }

    td {
      
      font-size: 1rem;
      font-weight: 400;
      line-height: 19px;
      color: #2e384d;
      vertical-align: middle;
    }

    }
  }
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #f7f8fc;
  }
`;

export const IconButton = styled.button`
  width: 50px;
  height: 50px;
  background: #2C1DFF;
  text-align: center;
  border-radius: 5px;
  img {
    height: 16px;
    display: inline;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;

export const ActionButton = styled.button`
  background: #2C1DFF !important;
  border-radius: 5px !important;
  color: #fff !important;
  margin: 0 10px;
  border: none;
  display: inline;
  font-size: 14px;
  font-family: 'AventaMedium';
  height: 35px;
  max-width: 180px;
  min-width: 160px;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  img {
    height: 14px;
    display: inline;
    margin-right: 10px;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;

export const FormClass = styled.div``;

export const FormElements = styled.div`
  width: 90%;
  margin-top: 30px;
  margin-bottom: 30px;
  display: block;

  label {
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 22px;
    color: #969cba;
  }
`;
