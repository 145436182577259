import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRescheduledLoanDetails, fetchRescheduledLoanRequests } from "../../../../actions/SavingsAndLoanActions";
import { Table, Tooltip } from "antd";
import { formatDate } from "../../../../util/FormatDate";
import CheckPermission from "../../../../util/CheckPermission";
import { ActionButton } from "../../Customer/CusomerLoanDetails/styles";
import ModalContainer from "../../components/ModalContainer";
import ApproveReschedule from "../../Customer/CusomerLoanDetails/RescheduleLoan/ApproveReschedule";
import RejectReschedule from "../../Customer/CusomerLoanDetails/RescheduleLoan/RejectReschedule";
import { RescheduleRequestModal } from "../../Customer/CusomerLoanDetails/RescheduleLoan/RescheduleRequestModal";
import { IconButton } from "../../Customer/CustomerInformation/styles";
import PreviewRepaymentSchedule from "../../Customer/CusomerLoanDetails/RescheduleLoan/PreviewRepaymentSchedule";

export const RescheduleRequests = ({ setRescheduleId, toggleRepaymentSchedule }) => {
	const dispatch = useDispatch();
	const { loanAccountRescheduleRequests } = useSelector((state) => state.savingsAndLoan);
	const { responseUserData: { permissions } } = useSelector((state) => state.preAuth);

	const [dataSource, setDataSource] = useState([]);
	const [resourceId, setResourceId] = useState(null);
	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 13,
	});
	const [activePagination, setActivePagination] = useState(false);
	const [showApprovalModal, setShowApprovalModal] = useState(false);
	const [showRejectionModal, setShowRejectionModal] = useState(false);
	const [showRescheduleRequest, setShowRescheduleRequest] = useState(false);

	const toggleApprovalModal = () => {
		setShowApprovalModal(!showApprovalModal);
		setShowRescheduleRequest(!showRescheduleRequest);
	};

	const toggleRejectionModal = () => {
		setShowRejectionModal(!showRejectionModal);
		setShowRescheduleRequest(!showRescheduleRequest);
	};

	const toggleRequestModal = () => {
		setShowRescheduleRequest(!showRescheduleRequest);
	};

	const handleTableChange = (pagination) => {
		setActivePagination(pagination.current);
		setPagination({
			current: activePagination,
			pageSize: 13,
			total: loanAccountRescheduleRequests.length,
		});
	};

	const columns = [
		{ title: "Reason", dataIndex: "reason", key: "reason" },
		{ title: "Rescheduled from", dataIndex: "rescheduledFromDate", key: "rescheduledFromDate" },
		{ title: "User", dataIndex: "user", key: "user" },
		// { title: "Date submitted", dataIndex: "dateSubmitted", key: "dateSubmitted" },
		{ title: "Status", dataIndex: "status", key: "status" },
		{ title: "Actions", dataIndex: "actions", key: "actions" },
	];

	useEffect(() => {
		dispatch(fetchRescheduledLoanRequests());
	}, []);

	useEffect(() => {
		setDataSource(loanAccountRescheduleRequests.map((item) => ({
			key: item.id,
			reason: item.rescheduleReasonCodeValue.name,
			rescheduledFromDate: formatDate(item.rescheduleFromDate),
			user: item.clientName,
			// dateSubmitted: item.dateSubmitted,
			status: (
				<span
					className={item.statusEnum.pendingApproval
						? "new-text-warning"
						: item.statusEnum.rejected
							? "new-text-danger"
							: "new-text-info"}
				>
					{item.statusEnum.value}
				</span>
			),
			actions: (
				<div className="d-flex">
					{CheckPermission('READ_RESCHEDULELOAN', permissions) && (
						<Tooltip title="View details">
							<IconButton
								onClick={() => {
									setResourceId(item.id);
									setRescheduleId(item.id);
									dispatch(fetchRescheduledLoanDetails(item.id));
									toggleRequestModal();
								}}
								className="bg-primary"
							>
								<img
									src={require("../../../../assets/images/icons/eye.svg").default}
									alt="cancel icon"
								/>
							</IconButton>
						</Tooltip>
					)}
					{(item.statusEnum.pendingApproval && CheckPermission('APPROVE_RESCHEDULELOAN', permissions)) && (
						<Tooltip title="Approve">
							<IconButton
								onClick={() => {
									setResourceId(item.id);
									toggleApprovalModal();
								}}
								className="bg-success"
							>
								<img
									src={require("../../../../assets/images/icons/save.svg").default}
									alt="submit icon"
								/>
							</IconButton>
						</Tooltip>
					)}
					{(item.statusEnum.pendingApproval && CheckPermission('REJECT_RESCHEDULELOAN', permissions)) && (
						<Tooltip title="Reject">
							<IconButton
								onClick={() => {
									setResourceId(item.id);
									toggleRejectionModal();
								}}
								className="bg-danger"
							>
								<img
									src={require("../../../../assets/images/icons/cancel.svg").default}
									alt="cancel icon"
								/>
							</IconButton>
						</Tooltip>
					)}
				</div>
			),
		})));
	}, [loanAccountRescheduleRequests]);

	return (
		<>
			<ModalContainer show={showApprovalModal} dialogClassName="document_infoModal">
				<ApproveReschedule
					id={resourceId}
					onHide={toggleApprovalModal}
				/>
			</ModalContainer>
			<ModalContainer show={showRejectionModal} dialogClassName="document_infoModal">
				<RejectReschedule
					id={resourceId}
					onHide={toggleRejectionModal}
				/>
			</ModalContainer>
			<ModalContainer show={showRescheduleRequest} dialogClassName="document_infoModal">
				<RescheduleRequestModal
					onClose={toggleRequestModal}
					setResourceId={setResourceId}
					toggleApprovalModal={toggleApprovalModal}
					toggleRejectionModal={toggleRejectionModal}
					toggleRepaymentSchedule={toggleRepaymentSchedule}
				/>
			</ModalContainer>
			<Table
				columns={columns}
				dataSource={dataSource}
				pagination={pagination}
				onChange={handleTableChange}
			/>
		</>
	);
};
