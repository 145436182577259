import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import ModalContainer from "../../../components/ModalContainer";
import { ActionButton } from "./styles";
import DeleteClosureModal from "./DeleteClosureModal";
import CheckPermission from "../../../../../util/CheckPermission";
import { formatDate } from "../../../../../util/FormatDate";

const AccountClosureDetail = ({
  history,
  thisSelectedClosure,
  handleCloseDetail,
  permissions,
}) => {
  let {
    office,
    accountClosureDate,
    comment,
    closeAccountingCreatedBy,
    selectedClosure,
  } = thisSelectedClosure || {};

  let { lastUpdatedByUsername, lastUpdatedDate, createdDate, id } =
    selectedClosure || {};

  const [modalState, toggleModal] = useState(false);
  const [modalContentName, setModalContentName] = useState("");

  const currentContent = (e) => {
    const val = e.currentTarget.id.trim();

    if (val) {
      toggleModal(true);
      setModalContentName(val);
    }
  };

  const handleModalClose = () => toggleModal(false);

  const displayModalContent = () => {
    switch (modalContentName) {
      case "deleteClosure":
        return (
          <DeleteClosureModal
            history={history}
            id={id}
            onHide={handleModalClose}
          />
        );
      default:
        return;
    }
  };
  return (
    <>
      <ModalContainer show={modalState}>
        {modalState && displayModalContent()}
      </ModalContainer>
      <div className="content-detail">
        <div className="detail-cancel-button" onClick={handleCloseDetail}>
          <img
            src={
              require("../../../../../assets/images/icons/cancel.svg").default
            }
            className="detail-cancel-icon"
            alt="cancel icon"
          />
        </div>
        <div className="row">
          <div className="details-button">
            <div className="mt-3">
              {CheckPermission("DELETE_GLCLOSURE", permissions) && (
                <ActionButton
                  color="#2C1DFF"
                  className="float-right"
                  style={{ margin: "0px 5px" }}
                  onClick={currentContent}
                  id="deleteClosure"
                >
                  <img
                    src={
                      require("../../../../../assets/images/icons/delete.svg")
                        .default
                    }
                    alt="delete icon"
                  />
                  Delete Closure
                </ActionButton>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 col-md-12">
            <div className="m-2">
              <Table responsive striped bordered hover>
                <thead className="border-0">
                  <tr>
                    <td className="font-weight-bold md-font-weight">Office:</td>
                    <td>{!!office ? office : ""}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Closure Date:
                    </td>
                    <td>{!!accountClosureDate ? accountClosureDate : ""}</td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Closed By:
                    </td>
                    <td>
                      {!!closeAccountingCreatedBy
                        ? closeAccountingCreatedBy
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Update By:
                    </td>
                    <td>
                      {!!lastUpdatedByUsername ? lastUpdatedByUsername : ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Updated On:
                    </td>
                    <td>
                      {!!lastUpdatedDate ? formatDate(lastUpdatedDate) : ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Closure Creation Date:
                    </td>
                    <td>
                      {!!createdDate ? formatDate(createdDate) : ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold md-font-weight">Comment</td>
                    <td>{!!comment ? comment : ""}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountClosureDetail;
