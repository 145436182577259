import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import { Table } from "react-bootstrap";
import { Menu, Dropdown, Tabs } from "antd";
import { ContentWrapper, ActionButton } from "./styles";
import { getSavingsAccountTemplate } from "../../../../actions/SavingsAndLoanActions";
import {
  getFixedDepositAccountDetails,
  deteleFixedDepositAccount,
  approveFixedDepositAccount,
  resetGenerateFDFileState,
  generateFDFile,
  postInterestFixedDepositAccount,
} from "../../../../actions/fixedDeposit";
// import Transactions from "./Transactions";
// import Charges from "./Charges";
// import { formatDate } from "../../../../util/FormatDate";
// import { Input } from "@chakra-ui/react";
// import { FormElements } from "./styles";

import ModalContainer from "../../components/ModalContainer";
import ActivateModal from "./ActivateModal";
import ApproveModal from "./ApproveModal";
import UndoApproveModal from "./UndoApproveModal";
import RejectModal from "./RejectModal";
// import EmailNotification from "./EmailNotification";
// import SmsNotification from "./SmsNotification";
import AddChargeModal from "./AddChargeModal";
import PrematureCloseModal from "./PrematureCloseModal";
import CloseAccount from "./CloseAccount";
// import Loader from "./Loader";
// import Summary from "./Summary";
// import InterestRateChart from "./InterestRateChart";

import { formatCurrency } from "../../../../util/FormatAmount";
import CheckPermission from "../../../../util/CheckPermission";
// import AxiosData from "../../../../services/axiosData";
import { getApplicableDataTables } from "../../../../actions/utilityActions";
import BackSvg from "../../../../assets/images/icons/back.svg";
import CancelSvg from "../../../../assets/images/icons/cancel.svg";

import FixedDetails from "./FixedDetails";
import Transactions from "./Transactions";

import Summary from "./Summary";
import EmailNotification from "./EmailNotification";
import Charges from "./Charges";
import SmsNotification from "./SmsNotification";
import ArrowCancelSvg from "../../../../assets/images/icons/arrow-cancel.svg";
import { TabButton } from "../CustomerSavingsAccount/styles";
import ArrowForwardSvg from "../../../../assets/images/icons/arror-forward.svg";
import DepositSvg from "../../../../assets/images/icons/deposit.svg";
import EditFixedDepositAccount from "../CustomerInformation/components/fixedDeposit/EditFixedDepositAccount";

const CustomerFixedDepositAccount = ({
  match,
  history,
  currentId,
  setCurrentView,
  currentTab,
}) => {
  const dispatch = useDispatch();

  // const ancRef = useRef();
  const fixedDepositData = useSelector((state) => state.fixedDepositData);

  let { fixedDepositAccountDetails = {} } = fixedDepositData || {};
  const savingsAndLoan = useSelector((state) => state.savingsAndLoan);
  // const isLoading = useSelector((state) => state.isLoading.isLoading);

  let { savingsAccountDetails = {} } = savingsAndLoan || {};

  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  let {
    id,
    accountNo,
    clientId,
    clientName,
    status,
    summary = {},
    depositProductName,
    linkedAccount,
    currency = {},
    transactions = [],
    charges = [],
  } = fixedDepositAccountDetails || {};

  // let { activatedOnDate = [] } = timeline || {};

  let { accountBalance, availableBalance, totalDeposits, totalInterestEarned } =
    summary || {};

  const dataTablesInfo = useSelector((state) => state.manageDataTables);
  const { applicableDataTables = {} } = dataTablesInfo;

  const { TabPane } = Tabs;

  // console.log(chargesArr , 'fixedDepositAccountcharges');

  useEffect(() => {
    // console.log(savingsAccountDetails, 'savingsAccountDetails');
    if (currentId) {
      dispatch(getFixedDepositAccountDetails(currentId));
      dispatch(getSavingsAccountTemplate(currentId));
    }
    // dispatch(getApplicableDataTables("m_fixed_account"));
  }, [dispatch, currentId]);

  const [modalState, toggleModal] = useState(false);

  const [modalContentName, setModalContentName] = useState("");

  const [transactionId, setTransId] = useState("");

  const generateFdTemplate = useSelector((state) => state.generateFdTemplate);
  // const [accountNumber] = useState(accountNo);

  console.log(generateFdTemplate, "generateFdTemplate");

  const currentContent = (e, transId) => {
    const val = e.currentTarget.id.trim();
    console.log(val, "val");
    setTransId(transId);

    if (val) {
      toggleModal(true);
      setModalContentName(val);
    }
  };

  const handleModalClose = () => toggleModal(false);

  const openPdfNewTab = (file) => {
    setTimeout(() => {
      window.open(file);
    }, 3000);
  };

  const generateFDFileHandler = (e) => {
    e.preventDefault();
    const funcTorun = () => handleModalClose();
    dispatch(generateFDFile(accountNo, funcTorun));
  };

  useEffect(() => {
    const file = generateFdTemplate?.generateFdTemplate?.file;
    if (
      generateFdTemplate?.generateFdTemplate?.status === "Done" &&
      !!generateFdTemplate?.generateFdTemplate?.file
    ) {
      return [openPdfNewTab(file), dispatch(resetGenerateFDFileState())];
    }
  }, [generateFdTemplate, dispatch]);

  // const generateDocument = async() => {
  //   const url = `https://report.staging.woodcoreapp.com/api/v1/reports/fixed-deposit-certificate?accountNo=${accountNo}`

  //   try {
  //     const {data , error} = await AxiosData({
  //       method: "GET",
  //       signal: false,
  //       url: url,
  //       isBearerToken: true,
  //       responseType: "blob",
  //     });
  //     if (data) {
  //       const dataUrl = URL.createObjectURL(data);
  //       ancRef.current.href = dataUrl;
  //       ancRef.current.download = "download";
  //       setTimeout(() => {
  //         URL.revokeObjectURL(dataUrl);
  //       }, 150);
  //       ancRef.current.click();
  //     } else {
  //     }
  //   } catch (ex){

  //   }finally {

  //   }
  // };

  const handlePostIntrest = () => {
    dispatch(postInterestFixedDepositAccount(id, {}, history, "postInterest"));
  };

  const [awaitingAction, setAwaitingAction] = useState("");

  const displayModalContent = () => {
    switch (modalContentName) {
      case "addCharge":
        return (
          <AddChargeModal history={history} id={id} onHide={handleModalClose} />
        );
      case "closeAccount":
        return (
          <CloseAccount
            history={history}
            id={id}
            onHide={handleModalClose}
            fixedDepositAccountDetails={fixedDepositAccountDetails}
            clientId={clientId}
          />
        );
      case "activateAccount":
        return (
          <ActivateModal history={history} id={id} onHide={handleModalClose} />
        );
      case "undoApproval":
        return (
          <UndoApproveModal
            history={history}
            id={id}
            onHide={handleModalClose}
          />
        );
      case "modifyApplication":
        return (
          <EditFixedDepositAccount
            history={history}
            id={id}
            onHide={handleModalClose}
            userId={clientId}
            currentTab={currentTab}
          />
        );
      case "approveAccount":
        return (
          <ApproveModal history={history} id={id} onHide={handleModalClose} />
        );
      case "rejectWithdraw":
        return (
          <RejectModal
            action={awaitingAction}
            history={history}
            id={id}
            onHide={handleModalClose}
          />
        );
      case "rematureClose":
        return (
          <PrematureCloseModal
            history={history}
            id={id}
            onHide={handleModalClose}
          />
        );
      default:
        return;
    }
  };

  const handleDeleteAccount = () => {
    dispatch(deteleFixedDepositAccount(id, history));
  };

  const [showMenu, setShowMenu] = useState(false);

  const handleTakeActions = () => {
    console.log("clicked");
    return (
      <>
        <div className="blockUI">
          <div className="container-fluid">
            <div className="spaceBetweenFlexEnd">
              <div className="new-button-menusFd">
                <div className="row">
                  <div className="spaceBetweenFlexEnd w-100">
                    <div
                      onClick={() => setShowMenu(false)}
                      className="p-relative mb-3 pointer"
                    >
                      <img src={ArrowCancelSvg} alt="" />
                    </div>
                  </div>
                </div>

                {/* PREMATURE CLOSURE */}
                {status?.approved === false &&
                status?.active === true &&
                status?.closed === false &&
                CheckPermission(
                  "PREMATURECLOSE_FIXEDDEPOSITACCOUNT",
                  permissions
                ) ? (
                  <>
                    {" "}
                    {/* Premature Close */}
                    <div
                      className=""
                      id="rematureClose"
                      onClick={(e) => {
                        currentContent(e, id);
                        setShowMenu(false);
                      }}
                    >
                      <TabButton color="#2C1DFF">
                        <span className="new-design-text">
                          {" "}
                          Premature Close
                        </span>
                        <img className="ml-4" src={CancelSvg} alt="" />
                      </TabButton>
                    </div>{" "}
                  </>
                ) : (
                  ""
                )}

                {/* POST INTEREST */}
                {status?.approved === false &&
                status?.active === true &&
                status?.closed === false &&
                CheckPermission(
                  "POSTINTEREST_FIXEDDEPOSITACCOUNT",
                  permissions
                ) ? (
                  <>
                    {" "}
                    {/* Premature Close */}
                    <div
                      className=""
                      id="postInterest"
                      // onClick={(e) => {
                      //   currentContent(e, id);
                      //   setShowMenu(false);
                      // }}
                      onClick={(e) => {
                        e.preventDefault();
                        handlePostIntrest();
                      }}
                    >
                      <TabButton color="#2C1DFF">
                        <span className="new-design-text"> Post Interest</span>
                        <img className="ml-4" src={ArrowForwardSvg} alt="" />
                      </TabButton>
                    </div>{" "}
                  </>
                ) : (
                  ""
                )}

                {/* ADD CHARGE*/}
                {status?.approved === false &&
                status?.active === true &&
                status?.closed === false &&
                CheckPermission("CREATE_SAVINGSACCOUNTCHARGE", permissions) ? (
                  <>
                    {" "}
                    {/* Premature Close */}
                    <div
                      className=""
                      id="addCharge"
                      onClick={(e) => {
                        currentContent(e, id);
                        setShowMenu(false);
                      }}
                    >
                      <TabButton color="#2C1DFF">
                        <span className="new-design-text"> Add Charge</span>
                        <img className="ml-4" src={ArrowForwardSvg} alt="" />
                      </TabButton>
                    </div>{" "}
                  </>
                ) : (
                  ""
                )}

                {/* Generate Certificate */}
                {status?.active && CheckPermission("READ_REPORT", permissions) ? (
                  <>
                    {" "}
                    {/* Premature Close */}
                    <div
                      className=""
                      id="generateDocument"
                      onClick={(e) => generateFDFileHandler(e)}
                      // onClick={(e) => {
                      //   currentContent(e, id);
                      //   setShowMenu(false);
                      // }}
                    >
                      <TabButton color="#2C1DFF">
                        <span className="new-design-text">
                          {" "}
                          Generate Certificate
                        </span>
                        <img className="ml-4" src={ArrowForwardSvg} alt="" />
                      </TabButton>
                    </div>{" "}
                  </>
                ) : (
                  ""
                )}

                {/* Activate Account */}
                {status?.active === false &&
                status?.approved === true &&
                status?.closed === false &&
                CheckPermission("ACTIVATE_FIXEDDEPOSITACCOUNT", permissions) ? (
                  // Approved -> Activate
                  <div
                    className=""
                    id="activateAccount"
                    onClick={(e) => {
                      currentContent(e, id);
                      setShowMenu(false);
                    }}
                  >
                    <TabButton color="#2C1DFF">
                      <span className="new-design-text"> Activate</span>
                      <img className="ml-4" src={DepositSvg} alt="" />
                    </TabButton>
                  </div>
                ) : null}

                {/* UNDO APPROVAL */}
                {status?.active === false &&
                status?.approved === true &&
                status?.closed === false &&
                CheckPermission(
                  "APPROVALUNDO_FIXEDDEPOSITACCOUNT",
                  permissions
                ) ? (
                  // Approved -> Undo Approval
                  <div
                    className=""
                    id="undoApproval"
                    onClick={(e) => {
                      currentContent(e, id);
                      setShowMenu(false);
                    }}
                  >
                    <TabButton color="#2C1DFF">
                      <span className="new-design-text"> Undo Approval</span>
                      <img className="ml-4" src={ArrowForwardSvg} alt="" />
                    </TabButton>
                  </div>
                ) : null}

                {/* Closed */}
                {status?.matured === true ? (
                  <div
                    className=""
                    id="closeAccount"
                    onClick={(e) => {
                      currentContent(e, id);
                      setShowMenu(false);
                    }}
                  >
                    <TabButton color="#2C1DFF">
                      <span className="new-design-text"> Close</span>
                      <img className="ml-4" src={ArrowForwardSvg} alt="" />
                    </TabButton>
                  </div>
                ) : null}

                {/* APPROVE */}
                {status?.prematureClosed === true ? null : (
                  <>
                    {status?.approved === false &&
                    status?.active === false &&
                    status?.closed === false &&
                    status?.matured === false &&
                    CheckPermission(
                      "APPROVE_FIXEDDEPOSITACCOUNT",
                      permissions
                    ) ? (
                      // {pending  -> Approve
                      <>
                        <div
                          className=""
                          id="approveAccount"
                          onClick={(e) => {
                            currentContent(e, id);
                            setShowMenu(false);
                          }}
                        >
                          <TabButton color="#2C1DFF">
                            <span className="new-design-text"> Approve</span>
                            <img
                              className="ml-4"
                              src={ArrowForwardSvg}
                              alt=""
                            />
                          </TabButton>
                        </div>
                      </>
                    ) : null}
                  </>
                )}

                {/* Pending */}
                {status?.id === 100 ? (
                  <>
                    {/* MODIFY_FDACCOUNT */}
                    {status.active === false &&
                    CheckPermission("MODIFY_FDACCOUNT", permissions) ? (
                      <div
                        className=""
                        id="modifyApplication"
                        onClick={(e) => {
                          e.stopPropagation();

                          history.push(
                            `/core/client/edit-fixed-deposit-account/${id}`
                          );
                        }}
                      >
                        <TabButton color="#005BFC">
                          <span className="new-design-text">
                            Modify Application{" "}
                          </span>
                          <img className="ml-4" src={ArrowForwardSvg} alt="" />
                        </TabButton>
                      </div>
                    ) : null}

                    {CheckPermission(
                      "REJECT_FIXEDDEPOSITACCOUNT",
                      permissions
                    ) ? (
                      <>
                        {" "}
                        <div
                          id="rejectWithdraw"
                          onClick={(e) => {
                            e.preventDefault();
                            setAwaitingAction("reject");
                            currentContent(e, id);
                            setShowMenu(false);
                          }}
                        >
                          <TabButton color="#2C1DFF">
                            <span className="new-design-text">Reject</span>
                            <img
                              className="ml-4"
                              src={ArrowForwardSvg}
                              alt=""
                            />
                          </TabButton>
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    {/* Withdraw by client */}
                    {CheckPermission(
                      "REJECT_FIXEDDEPOSITACCOUNT",
                      permissions
                    ) ? (
                      <>
                        {" "}
                        <div
                          id="rejectWithdraw"
                          onClick={(e) => {
                            e.preventDefault();
                            setAwaitingAction("withdrawnByApplicant");
                            currentContent(e, id);
                            setShowMenu(false);
                          }}
                        >
                          <TabButton color="#2C1DFF">
                            <span className="new-design-text">
                              Withdrawn By Client
                            </span>
                            <img
                              className="ml-4"
                              src={ArrowForwardSvg}
                              alt=""
                            />
                          </TabButton>
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    {/* Delete */}
                    {CheckPermission(
                      "DELETE_FIXEDDEPOSITACCOUNT",
                      permissions
                    ) ? (
                      <>
                        {" "}
                        <div
                          onClick={(e) => {
                            e.preventDefault();
                            handleDeleteAccount();
                            setShowMenu(false);
                          }}
                        >
                          <TabButton color="#2C1DFF">
                            <span className="new-design-text">Delete</span>
                            <img
                              className="ml-4"
                              src={ArrowForwardSvg}
                              alt=""
                            />
                          </TabButton>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}

                {/* AddCharge */}
                {status?.id === 100 || status?.id === 800 ? (
                  <>
                    {CheckPermission(
                      "CREATE_SAVINGSACCOUNTCHARGE",
                      permissions
                    ) ? (
                      <>
                        <div
                          id="addCharge"
                          onClick={(e) => {
                            currentContent(e, id);
                            setShowMenu(false);
                          }}
                        >
                          <TabButton color="#2C1DFF">
                            <span className="new-design-text">Add Charge</span>
                            <img
                              className="ml-4"
                              src={ArrowForwardSvg}
                              alt=""
                            />
                          </TabButton>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {/* <a style={{ display: "none" }} ref={ancRef}></a> */}
      <div className="p-relative">
        <ModalContainer show={modalState}>
          {modalState && displayModalContent()}
        </ModalContainer>
        {showMenu && handleTakeActions()}
        <div className="">
          <ContentWrapper>
            <>
              <div className="row">
                <div className="col-xl-6 col-md-6">
                  <p
                    onClick={() => {
                      setCurrentView(1);
                    }}
                    className="back-btn d-flex"
                  >
                    <img className="mr-2" src={BackSvg} alt="" />
                    Back
                  </p>
                </div>
                {status?.active ||
                status?.id === 100 ||
                status?.approved ||
                status?.matured ? (
                  <>
                    <div className="col-xl-6 col-md-6">
                      <div className="spaceBetweenFlexEnd">
                        <ActionButton
                          onClick={() => setShowMenu(!showMenu)}
                          color="#2C1DFF"
                        >
                          Take Action
                          <img
                            src={
                              require("../../../../assets/images/icons/arrow-right.svg")
                                .default
                            }
                            className="ml-2"
                            alt="take action"
                          />
                        </ActionButton>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="row my-3">
                <div className="col-xl-12">
                  {/* <p className="headerSpanBal-p">
                    {!!accountNo ? `${accountNo}` : ""}{" "}
                    {!!depositProductName ? depositProductName : ""} Account
                  </p> */}
                  {/* {status?.id === 700 || status?.id === 800 ? (
                    <>
                      <p className="account-details">
                        <span className="headerSpan">Current Balance</span>:{" "}
                        {!!accountBalance
                          ? formatCurrency(accountBalance)
                          : "0"}
                      </p>{" "}
                      <br />
                      {availableBalance ? (
                        <p className="account-details">
                          <span className="headerSpan">Available Balance</span>:{" "}
                          {!!availableBalance
                            ? formatCurrency(availableBalance)
                            : "0"}
                        </p>
                      ) : (
                        ""
                      )}
                    </>
                  ) : ( */}
                  <>
                    <div className="row">
                      <div className="col-xl-12">
                        {/* {status?.active ? (
                            <p className="account-balance">
                              <span className="headerSpanBal">Customer :</span>{" "}
                              <span className="amountCurr">
                                {!!clientName ? clientName : ""}{" "}
                              </span>
                            </p>
                          ) : null}{" "} */}
                        {status?.active ||
                        status?.matured ||
                        status?.approved ||
                        status?.closed ||
                        status?.id === 100 ||
                        status?.id === 700 ? (
                          <p className="account-balance">
                            <span className="headerSpanBal-p">
                              Linked Savings Account :
                            </span>{" "}
                            {/* <span className="amountCurr">
                        {!!currency.code ? currency.code : ""}{" "}
                      </span> */}
                            <span className="headerSpanBal">
                              {!!linkedAccount?.accountNo
                                ? linkedAccount?.accountNo
                                : ""}
                            </span>
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </>
                  {/* // )} */}
                  {/* {status?.active ? (
                    <p className="account-balance">
                      <span className="headerSpanBal">Available Bal :</span>{" "}
                      <span className="amountCurr">
                        {!!currency?.code ? currency?.code : ""}{" "}
                      </span>
                      <span className="headerSpanBal">
                        {!!availableBalance
                          ? formatCurrency(availableBalance)
                          : "0"}
                      </span>
                    </p>
                  ) : null} */}
                  {/* {status?.approved ? (
                    <p className="account-balance">
                      <span className="headerSpanBal">Current Balance</span>:{" "}
                      <span className="amountCurr">
                        {!!currency?.code ? currency?.code : ""}{" "}
                      </span>
                      <span className="headerSpanBal">
                        {!!accountBalance
                          ? formatCurrency(accountBalance)
                          : "0"}
                      </span>
                    </p>
                  ) : null}{" "} */}
                  {status?.id === 100 ||
                  status?.id === 200 ||
                  status?.id === 300 ||
                  status?.id === 400 ||
                  status?.id === 500 ||
                  status?.id === 600 ||
                  status?.id === 700 ||
                  status?.id === 800 ? (
                    <p className="account-balance">
                      <span className="headerSpanBal-p">Product Name</span>:{" "}
                      <span className="amountCurr">
                        {!!depositProductName ? depositProductName : ""} Account
                      </span>{" "}
                      <br />
                      <span className="headerSpanBal-p">ACCOUNT NO</span>:{" "}
                      <span className="amountCurr">
                        {!!accountNo ? `${accountNo}` : ""}{" "}
                      </span>
                    </p>
                  ) : null}
                </div>
              </div>
            </>

            <div className="row mt-2">
              <Tabs defaultActiveKey="details" className="full-width">
                {/* FD Details */}
                <TabPane tab="Details" key="details">
                  <FixedDetails
                    fixedAccountDetails={fixedDepositAccountDetails}
                  />
                </TabPane>
                {/* {status?.closed === true ? (
                  <TabPane
                    tab="Transactions"
                    key={status?.id === 800 ? "2" : "2"}
                  >
                    <div className="full-width">
                      <Transactions
                        transactions={transactions}
                        history={history}
                        id={id}
                      />
                    </div>
                  </TabPane>
                ) : null} */}
                {status?.active ||
                status?.prematureClosed ||
                status?.id === 600 ||
                status?.id === 400 ||
                status?.closed ||
                // status?.closed ||
                status?.matured ? (
                  <TabPane
                    tab="Transactions"
                    key={status?.id === 100 ? "2" : "2"}
                  >
                    <div className="full-width">
                      <Transactions
                        transactions={transactions}
                        history={history}
                        id={id}
                      />
                    </div>
                  </TabPane>
                ) : null}
                {/* {status?.id === 100 ? (
                  <TabPane tab="Summary" key="1">
                    <div className="full-width">
                      <Summary
                        fixedDepositAccountDetails={fixedDepositAccountDetails}
                      />
                    </div>
                  </TabPane>
                ) : null} */}
                {/* {status?.id !== 100 &&
                status?.id !== 200 &&
                status?.id !== 300 &&
                status?.id !== 700 &&
                status?.id !== 800 ? (
                  <TabPane
                    tab="Email Notification"
                    key={status?.active ? "3" : "2"}
                  >
                    <EmailNotification
                      id={id}
                      history={history}
                      accountNumber={id}
                    />
                  </TabPane>
                ) : null} */}
                {status?.id === 200 ||
                // status?.id === 600 ||
                status?.id === 500 ? null : ( // status?.id === 400 // status?.closed ||
                  <TabPane tab="Charges" key={status?.active ? "5" : "4"}>
                    <Charges id={id} history={history} charges={charges} />
                  </TabPane>
                )}
                {/* {status?.id !== 100 &&
                status?.id !== 200 &&
                status?.id !== 300 &&
                status?.id !== 300 &&
                status?.id !== 700 &&
                status?.id !== 800 ? (
                  <TabPane
                    tab="SMS Notification"
                    key={status?.active ? "4" : "3"}
                  >
                    <SmsNotification
                      id={id}
                      history={history}
                      accountNumber={id}
                    />
                  </TabPane>
                ) : null} */}
                {/* {status.id !== 100 ? (
                  <TabPane tab="Interest Rate" key="1">
                    <div className="full-width">
                      <InterestRateChart
                        fixedDepositAccountDetails={fixedDepositAccountDetails}
                      />
                    </div>
                  </TabPane>
                ) : null} */}
              </Tabs>
            </div>
          </ContentWrapper>
        </div>
      </div>
    </>
  );
};

export default CustomerFixedDepositAccount;
