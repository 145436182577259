import React, { useState } from "react";
import {
  ContentWrapper,
  ActionButtonsGroup,
  ActionButton,
  IconButton,
} from "./styles";
import { InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import ModalContainer from "../../../components/ModalContainer";
import NewTeller from "./components/NewTeller";
import EditTeller from "./components/EditTeller";
import ViewTeller from "./components/ViewTeller";
import DeleteTeller from "./components/DeleteTeller";
const CashierManagementView = () => {
  const [modalState, toggleModal] = useState(false);
  const [modalContentName, setModalContentName] = useState("");
  const currentContent = (e) => {
    const val = e.currentTarget.id.trim();

    if (val) {
      toggleModal(true);
      setModalContentName(val);
    }
  };
  const handleModalClose = () => toggleModal(false);
  const displayModalContent = () => {
    switch (modalContentName) {
      case "newTeller":
        return <NewTeller onHide={handleModalClose} />;
      case "editTeller":
        return <EditTeller onHide={handleModalClose} />;
      case "deleteTeller":
        return <DeleteTeller onHide={handleModalClose} />;
      case "viewTeller":
        return <ViewTeller onHide={handleModalClose} />;
      default:
        return;
    }
  };

  const status = "Active";
  //Table configuration
  const columns = [
    { title: "Branch", dataIndex: "branch", key: "branch" },
    { title: "Teller", dataIndex: "teller", key: "teller" },
    { title: "Status", dataIndex: "status", key: "status" },
    { title: "Started on", dataIndex: "startedOn", key: "startedOn" },
    { title: "", dataIndex: "actions", key: "actions" },
  ];
  const dataSource = [
    {
      branch: "Main Head Office",
      teller: "Mark Peters",
      status: (
        <p
          style={{
            color: status === "Active" ? "#1DCB43" : "#333333",
          }}
          className="font-weight-bold"
        >
          {status}
        </p>
      ),
      startedOn: "20/03/2020",

      actions: (
        <>
          <IconButton
            id="viewTeller"
            onClick={currentContent}
            className="mr-2 mb-2"
          >
            <img
              src={
                require("../../../../../assets/images/icons/view.svg").default
              }
              alt="view"
            />
          </IconButton>
          <IconButton
            id="editTeller"
            onClick={currentContent}
            className="mr-2 mb-2"
          >
            <img
              src={
                require("../../../../../assets/images/icons/edit-profile.svg")
                  .default
              }
              alt="edit"
            />
          </IconButton>
          <IconButton id="deleteTeller" onClick={currentContent}>
            <img
              src={
                require("../../../../../assets/images/icons/delete.svg").default
              }
              alt="delete"
              className="icon-img"
            />
          </IconButton>
        </>
      ),
    },
    {
      branch: "Main Head Office",
      teller: "Mark Peters",
      status: (
        <p
          style={{
            color: status === "Active" ? "#1DCB43" : "#333333",
          }}
          className="font-weight-bold"
        >
          {status}
        </p>
      ),
      startedOn: "20/03/2020",
      actions: (
        <>
          <IconButton
            id="viewTeller"
            onClick={currentContent}
            className="mr-2 mb-2"
          >
            <img
              src={
                require("../../../../../assets/images/icons/view.svg").default
              }
              alt="view"
            />
          </IconButton>
          <IconButton
            id="editTeller"
            onClick={currentContent}
            className="mr-2 mb-2"
          >
            <img
              src={
                require("../../../../../assets/images/icons/edit-profile.svg")
                  .default
              }
              alt="edit"
            />
          </IconButton>
          <IconButton id="deleteTeller" onClick={currentContent}>
            <img
              src={
                require("../../../../../assets/images/icons/delete.svg").default
              }
              alt="delete"
              className="icon-img"
            />
          </IconButton>
        </>
      ),
    },
    {
      branch: "Main Head Office",
      teller: "Mark Peters",
      status: (
        <p
          style={{
            color: status === "Active" ? "#1DCB43" : "#333333",
          }}
          className="font-weight-bold"
        >
          {status}
        </p>
      ),
      startedOn: "20/03/2020",

      actions: (
        <>
          <IconButton
            id="viewTeller"
            onClick={currentContent}
            className="mr-2 mb-2"
          >
            <img
              src={
                require("../../../../../assets/images/icons/view.svg").default
              }
              alt="view"
            />
          </IconButton>
          <IconButton
            id="editTeller"
            onClick={currentContent}
            className="mr-2 mb-2"
          >
            <img
              src={
                require("../../../../../assets/images/icons/edit-profile.svg")
                  .default
              }
              alt="edit"
            />
          </IconButton>
          <IconButton id="deleteTeller" onClick={currentContent}>
            <img
              src={
                require("../../../../../assets/images/icons/delete.svg").default
              }
              alt="delete"
              className="icon-img"
            />
          </IconButton>
        </>
      ),
    },
    {
      branch: "Main Head Office",
      teller: "Mark Peters",
      status: (
        <p
          style={{
            color: status === "Active" ? "#1DCB43" : "#333333",
          }}
          className="font-weight-bold"
        >
          {status}
        </p>
      ),
      startedOn: "20/03/2020",

      actions: (
        <>
          <IconButton
            id="viewTeller"
            onClick={currentContent}
            className="mr-2 mb-2"
          >
            <img
              src={
                require("../../../../../assets/images/icons/view.svg").default
              }
              alt="view"
            />
          </IconButton>
          <IconButton
            id="editTeller"
            onClick={currentContent}
            className="mr-2 mb-2"
          >
            <img
              src={
                require("../../../../../assets/images/icons/edit-profile.svg")
                  .default
              }
              alt="edit"
            />
          </IconButton>
          <IconButton id="deleteTeller" onClick={currentContent}>
            <img
              src={
                require("../../../../../assets/images/icons/delete.svg").default
              }
              alt="delete"
              className="icon-img"
            />
          </IconButton>
        </>
      ),
    },
    {
      branch: "Main Head Office",
      teller: "Mark Peters",
      status: (
        <p
          style={{
            color: status === "Active" ? "#1DCB43" : "#333333",
          }}
          className="font-weight-bold"
        >
          {status}
        </p>
      ),
      startedOn: "20/03/2020",

      actions: (
        <>
          <IconButton
            id="viewTeller"
            onClick={currentContent}
            className="mr-2 mb-2"
          >
            <img
              src={
                require("../../../../../assets/images/icons/view.svg").default
              }
              alt="view"
            />
          </IconButton>
          <IconButton
            id="editTeller"
            onClick={currentContent}
            className="mr-2 mb-2"
          >
            <img
              src={
                require("../../../../../assets/images/icons/edit-profile.svg")
                  .default
              }
              alt="edit"
            />
          </IconButton>
          <IconButton id="deleteTeller" onClick={currentContent}>
            <img
              src={
                require("../../../../../assets/images/icons/delete.svg").default
              }
              alt="delete"
              className="icon-img"
            />
          </IconButton>
        </>
      ),
    },
  ];
  return (
    <>
      <ModalContainer show={modalState}>
        {modalState && displayModalContent()}
      </ModalContainer>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Cashier Management</h3>
            <p className="sub-title">
              Manage tellers/cashiers and cash allocation & settlements
            </p>
            <p className="font-weight sub-title mt-2">
              <span className="text-info">
                <Link to="/core/configuration">Configuration</Link> /{" "}
                <Link to="/core/configuration/organization">Utilities</Link>
              </span>{" "}
              / Cashier Management
            </p>
          </div>
          <div className="col-sm-12 col-md-6 px-0">
            <ActionButtonsGroup>
              <InputGroup style={{ maxWidth: "450px" }}>
                <InputLeftElement
                  width="3.1rem"
                  height="50px"
                  pointerEvents="none"
                >
                  <img
                    src={
                      require("../../../../../assets/images/icons/search.svg")
                        .default
                    }
                    style={{ height: "30px" }}
                    alt="Icon"
                  />
                </InputLeftElement>
                <Input
                  style={{
                    background: "#ffffff",
                    height: "50px",
                  }}
                  type="text"
                  className="mb-3"
                  placeholder="Search for name/ external id"
                />
              </InputGroup>

              <ActionButton id="newTeller" onClick={currentContent}>
                <img
                  src={
                    require("../../../../../assets/images/icons/add.svg")
                      .default
                  }
                  alt="add"
                />
                New Teller
              </ActionButton>
            </ActionButtonsGroup>
          </div>
        </div>
        <ContentWrapper>
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={{ defaultPageSize: 8 }}
            defaultPageSize={8}
          />
        </ContentWrapper>
      </div>
    </>
  );
};

export default CashierManagementView;
