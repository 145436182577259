import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { Input, Select, FormControl, Textarea } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { FormClass } from "../styles";
import { FormElements, ActionButton } from "../../styles";
import Toast from "../../../../../util/Toast";
import { formatDate } from "../../../../../util/FormatDate";
import { createANewAccountClosures } from "../../../../../actions/accountingActions";

const CreateClosure = ({ onHide, offices, history }) => {
  const dispatch = useDispatch();

  const [selectedOffice, setSelectedOffice] = useState("");
  const [transactionDate, setTrasactionDate] = useState("");
  const [comment, setComment] = useState("");

  let newDate = new Date(transactionDate);

  const handleCreateClosure = (e) => {
    e.preventDefault();
    if (!selectedOffice) {
      Toast({ text: "Please select an office" });
    } else {
      const toBeSentData = {
        closingDate: formatDate(newDate, true),
        comments: comment,
        dateFormat: "dd MMMM yyyy",
        locale: "en",
        officeId: selectedOffice,
      };
      dispatch(createANewAccountClosures(toBeSentData, history));
      onHide();
    }
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>Create Closure</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <FormElements style={{ width: "100%" }}>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Office*</label>
              <Select
                placeholder="Select Office"
                size="md"
                name="selectedOffice"
                value={selectedOffice}
                onChange={(e) => setSelectedOffice(e.target.value)}
              >
                {!!offices ? (
                  offices.map(({ id, name }) => {
                    return (
                      <option value={id} key={id}>
                        {name}
                      </option>
                    );
                  })
                ) : (
                  <option>Loading</option>
                )}

                <option>Office 2</option>
              </Select>
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Closing date*</label>
              <FormControl style={{ width: "70%" }}>
                <DatePicker
                  id="published-date"
                  selected={transactionDate}
                  onChange={(date) => setTrasactionDate(date)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="dd/mmm/yyyy"
                />
              </FormControl>
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Comment</label>
              <Textarea
                size="md"
                style={{ width: "80%", marginTop: "20px" }}
                resize={"none"}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </div>
          </FormElements>
          <div className="d-flex mt-4">
            <ActionButton color="#2C1DFF" onClick={onHide}>
              <img
                src={
                  require("../../../../../assets/images/icons/cancel.svg")
                    .default
                }
                alt="cancel icon"
              />
              Cancel
            </ActionButton>
            <ActionButton
              color="#2C1DFF"
              style={{ margin: "0px 0px 0 10px" }}
              onClick={(e) => handleCreateClosure(e)}
            >
              <img
                src={
                  require("../../../../../assets/images/icons/save.svg").default
                }
                alt="submit icon"
              />
              Submit
            </ActionButton>
          </div>
        </FormClass>
      </Modal.Body>
    </>
  );
};

export default CreateClosure;
