import { Modal } from "react-bootstrap";
import { NewActionButton } from "../../styles";
import { Input } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { formatDate, formatDateForInput } from "../../../../../../util/FormatDate";
import { getLoanDetails, modifyRepaymentSchedule } from "../../../../../../actions/SavingsAndLoanActions";
import { useDispatch } from "react-redux";
import { ActionAnchorButton, ActionButton } from "../../../styles";

const EditRepaymentSchedule = ({ periods, loanId, onHide }) => {
	const dispatch = useDispatch();
	const [newSchedule, setNewSchedule] = useState([]);
	const [showCompletedSchedule, setShowCompletedSchedule] = useState(false);

	const handleChange = (period, name, value) => {
		setNewSchedule(newSchedule?.map(
			(schedule) => schedule?.period === period ? ({ ...schedule, [name]: value }) : schedule
		));
	};
	const handleReset = (period, name, value) => {
		setNewSchedule(periods?.map(schedule => ({
			period: schedule?.period,
			complete: schedule?.complete,
			duedate: schedule?.dueDate,
			principal: schedule?.principalDue,
			interest: schedule.interestDue,
		})));
	};

	const toggleCompleted = () => {
		setShowCompletedSchedule(state => !state);
	};
	
	const callback = () => {
		dispatch(getLoanDetails(loanId));
		onHide();
	};

	const handleSubmit = () => {
		const periods = newSchedule.slice(1).map(schedule => ({
			duedate: formatDate(schedule?.duedate, true),
			principal: Number(schedule?.principal),
			interest: Number(schedule?.interest),
		}));
		const data = {
			periods,
			locale: "en",
			dateFormat: "dd MMM yyyy"
		};
		dispatch(modifyRepaymentSchedule(loanId, data, callback));
	};

	useEffect(() => {
		periods && setNewSchedule(periods?.map(schedule => ({
			period: schedule?.period,
			complete: schedule?.complete,
			duedate: schedule?.dueDate,
			principal: schedule?.principalDue,
			interest: schedule.interestDue
		})));
	}, [periods]);

	const schedule = newSchedule?.slice(1);
	const completedSchedule = schedule?.filter(period => period?.complete);
	const uncompletedSchedule = schedule?.filter(period => !period?.complete);

	return (
		<>
			<Modal.Header>
				<div className="d-flex justify-content-between align-items-center w-100">
					<Modal.Title>Edit Repayment Schedule</Modal.Title>
					<span className="pointer" onClick={onHide}>&#10005;</span>
				</div>
			</Modal.Header>
			<div className="d-flex justify-content-between align-items-center nowrap w-100 px-4 py-3">
				<div className="w-30 fs-14">Due Date</div>
				<div className="w-30 fs-14">Principal</div>
				<div className="w-30 fs-14">Interest</div>
			</div>
			<Modal.Body className="wc-modal px-4">
				<div className="d-flex align-items-center justify-content-center flex-column gap-4 w-100 mt-n3">
					{showCompletedSchedule && completedSchedule?.map(schedule => (
						<div className="d-flex justify-content-between align-items-center w-100">
							<Input
								type="date"
								value={formatDateForInput(schedule?.duedate)}
								style={{ width: '30%', fontSize: '13px' }} 
								size="md"
								colorScheme="red"
								disabled
							/>
							<Input
								name="principal"
								value={schedule?.principal}
								onChange={({ target: { name, value } }) => handleChange(schedule?.period, name, value)}
								disabled={schedule?.complete}
								type="number"
								size="md"
								style={{ width: '30%', fontSize: '13px' }}
							/>
							<Input
								name="interest"
								value={schedule?.interest}
								onChange={({ target: { name, value } }) => handleChange(schedule?.period, name, value)}
								disabled={schedule?.complete}
								type="number"
								size="md"
								style={{ width: '30%', fontSize: '13px' }}
							/>
						</div>
					))}
					{(showCompletedSchedule && completedSchedule?.length > 0) &&
						<div className="border border-muted w-100 my-1" />}
					{uncompletedSchedule?.map(schedule => (
						<div className="d-flex justify-content-between align-items-center w-100">
							<Input
								type="date"
								value={formatDateForInput(schedule?.duedate)}
								style={{ width: '30%', fontSize: '13px' }} 
								size="md"
								colorScheme="red"
								disabled
							/>
							<Input
								name="principal"
								value={schedule?.principal}
								onChange={({ target: { name, value } }) => handleChange(schedule?.period, name, value)}
								disabled={schedule?.complete}
								type="number"
								size="md"
								style={{ width: '30%', fontSize: '13px' }}
							/>
							<Input
								name="interest"
								value={schedule?.interest}
								onChange={({ target: { name, value } }) => handleChange(schedule?.period, name, value)}
								disabled={schedule?.complete}
								type="number"
								size="md"
								style={{ width: '30%', fontSize: '13px' }}
							/>
						</div>
					))}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<div className="mt-4 d-flex justify-content-between w-100 fs-14">
					{completedSchedule?.length > 0 && (
						<ActionAnchorButton color="#2C1DFF" onClick={toggleCompleted}>
							{showCompletedSchedule ? 'Hide' : 'Show'} completed
						</ActionAnchorButton>
					)}
					<div className="d-flex gap-2 ml-auto">
						<NewActionButton color="#2C1DFF" onClick={handleReset}>
							Reset
						</NewActionButton>
						<ActionButton onClick={handleSubmit} className="d-flex gap-2">
							<img
								src={
									require("../../../../../../assets/images/icons/save.svg")
										.default
								}
								alt="submit icon"
							/>
							Submit
						</ActionButton>
					</div>
				</div>
			</Modal.Footer>
		</>
	);
};

export default EditRepaymentSchedule;