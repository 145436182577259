import React from 'react'
import { Stack, Skeleton, SkeletonCircle } from "@chakra-ui/react"

const CustomItemLoader = () => {
    return (
        <>
            <Stack>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: '#E2E8F0', padding: '1rem', borderRadius: '6px 6px 0 0'}}>
                    <SkeletonCircle size="8" opacity='0'/>
                    <Skeleton height="7px" width='40'/>
                    <Skeleton height="7px" width='20'/>
                    <Skeleton height="7px" width='20'/>
                    <Skeleton height="7px" width='20'/>
                    <Skeleton height="7px" width='20'/>
                    <Skeleton height="7px" width='20'/>
                </div>
            </Stack>
            {[...Array(8).keys()].map((x, i) => (
                <Stack padding="3" key={i} borderBottom='1px solid #E2E8F0'>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <SkeletonCircle size="9" />
                        <Skeleton height="7px" width='40'/>
                        <Skeleton height="7px" width='20'/>
                        <Skeleton height="7px" width='20'/>
                        <Skeleton height="7px" width='20'/>
                        <Skeleton height="7px" width='20'/>
                        <Skeleton height="7px" width='20'/>
                    </div>
                </Stack>
            ))}
        </>
    )
}

export default CustomItemLoader