import React from "react";
import { Modal } from "react-bootstrap";
import { NewActionButton } from "../styles";
import { useHistory } from "react-router-dom";

const ViewEOYModal = ({ onCancel, data }) => {
  const { totalExpense, reserve , totalIncome } = data?.changes || {};

  let history = useHistory();

  function formatBalance(amount) {
    // Ensure amount is a number
    const parsedAmount = parseFloat(amount);

    // Check if parsedAmount is a valid number
    if (isNaN(parsedAmount)) {
      return "Invalid Amount";
    }

    // Use toLocaleString to format the number with commas and decimal places
    const formattedAmount = parsedAmount.toLocaleString("en-US", {
      // style: "currency",
      // currency: "NGN", // Change this to your currency code
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formattedAmount;
  }

  return (
    <>
      <Modal.Header>
        <Modal.Title>View End Of Year Balances</Modal.Title>
      </Modal.Header>
      <Modal.Body className="wc-modal">
        <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap flex-column">
          {/* Select date */}
          {/* <div className="col-sm-12 col-md-12 px-0 mb-3 p-1 d-flex align-items-center justify-content-between formStyle"> */}
          <div className="col-sm-12 col-md-12 px-0 mb-3 p-1 d-flex align-items-center justify-content-between">
            {/* fromDate */}
            <div
              className="label"
              style={{ fontSize: "14px", color: "#969CBA" }}
            >
              Total Expense:
            </div>
            <div className="datepicker-100-width" style={{ width: "60%" }}>
              <span>{formatBalance(totalExpense)}</span>
            </div>
          </div>
          {/* Select Offices */}
          <div className="col-sm-12 col-md-12 px-0 mb-3 p-1 d-flex align-items-center justify-content-between ">
            {/* <div className="col-sm-12 col-md-12 px-0 mb-3 p-1 d-flex align-items-center justify-content-between formStyle"> */}
            <div
              className="label"
              style={{ fontSize: "14px", color: "#969CBA" }}
            >
             Total Income:
            </div>
            <div className="select-100-width" style={{ width: "60%" }}>
              <span>{formatBalance(totalIncome)}</span>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 px-0 mb-3 p-1 d-flex align-items-center justify-content-between ">
            {/* <div className="col-sm-12 col-md-12 px-0 mb-3 p-1 d-flex align-items-center justify-content-between formStyle"> */}
            <div
              className="label"
              style={{ fontSize: "14px", color: "#969CBA" }}
            >
             General Reserve:
            </div>
            <div className="select-100-width" style={{ width: "60%" }}>
              <span>{formatBalance(reserve)}</span>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexGrow: "0.2",
          }}
        >
          <div>
            <NewActionButton
              color="#fff"
              className="float-right"
              style={{ margin: "0px 0px" }}
              onClick={() => history.goBack()}
              bgColor="#2C1DFF"
            >
              Cancel
            </NewActionButton>
          </div>
        </div>
      </Modal.Footer>
    </>
  );
};

export default ViewEOYModal;
