import React from "react";
import { Table } from "antd";
import Loading from "./Loading";

const columns = [
  { title: "Name", dataIndex: "name", key: "name" },
  { title: "Enabled?", dataIndex: "enabled", key: "enabled" },
  { title: "", dataIndex: "able", key: "able" },
  // { title: "Value", dataIndex: "value", key: "value" },
  // { title: "", dataIndex: "edit", key: "edit" },
];

const TableLoader = (props) => {
  const someChartsData = [
    {
      key: 1,
      name: <Loading long />,
      enabled: <Loading />,
      able: <Loading />,
      // value: <Loading />,
      // edit: <Loading />,
    },
    {
      key: 2,
      name: <Loading />,
      enabled: <Loading />,
      able: <Loading />,
      // value: <Loading />,
      // edit: <Loading />,
    },
    {
      key: 3,
      name: <Loading />,
      enabled: <Loading />,
      able: <Loading />,
      // value: <Loading />,
      // edit: <Loading />,
    },
    {
      key: 4,
      name: <Loading />,
      enabled: <Loading />,
      able: <Loading />,
      // value: <Loading />,
      // edit: <Loading />,
    },
    {
      key: 5,
      name: <Loading />,
      enabled: <Loading />,
      able: <Loading />,
      // value: <Loading />,
      // edit: <Loading />,
    },
  ];
  return (
    <>
      <Table
        columns={columns}
        dataSource={someChartsData}
        pagination={{ defaultPageSize: 8 }}
        className="antdTable"
        defaultPageSize={8}
      />
    </>
  );
};

export default TableLoader;
