import React, { useState } from "react";
import { Table } from "react-bootstrap";
import ModalContainer from "../../../components/ModalContainer";
import { ActionButton } from "./styles";
import DeletFinancialActivityMappingModal from "./DeletFinancialActivityMappingModal";
import AddFinancialActivityModal from "../AddFinancialActivityModal";
import CheckPermission from "../../../../../util/CheckPermission";

const FinancialActivityDetail = ({
  history,
  handleCloseDetail,
  selectedActivity,
  financialActivityTemplate,
  permissions,
}) => {
  const [modalState, toggleModal] = useState(false);
  const [modalContentName, setModalContentName] = useState("");

  let {
    accountName,
    accountNumber,
    financialActivities,
    id,
    financialActivityId,
    mappedGLAccountType,
  } = selectedActivity || {};

  const currentContent = (e) => {
    const val = e.currentTarget.id.trim();

    if (val) {
      toggleModal(true);
      setModalContentName(val);
    }
  };

  const handleModalClose = () => toggleModal(false);

  const displayModalContent = () => {
    switch (modalContentName) {
      case "deleteFinancialActivity":
        return (
          <DeletFinancialActivityMappingModal
            onHide={handleModalClose}
            id={id}
            history={history}
          />
        );
      case "editFinancialActivity":
        return (
          <AddFinancialActivityModal
            onHide={handleModalClose}
            id={id}
            history={history}
            financialActivityTemplate={financialActivityTemplate}
            name={"Edit"}
            financialActivityId={financialActivityId}
            mappedGLAccountType={mappedGLAccountType}
          />
        );
      default:
        return;
    }
  };
  return (
    <>
      <ModalContainer show={modalState}>
        {modalState && displayModalContent()}
      </ModalContainer>
      <div className="content-detail">
        <div className="detail-cancel-button" onClick={handleCloseDetail}>
          <img
            src={
              require("../../../../../assets/images/icons/cancel.svg").default
            }
            className="detail-cancel-icon"
            alt="cancel icon"
          />
        </div>
        <div className="row">
          <div className="details-button">
            {CheckPermission(
              "DELETE_FINANCIALACTIVITYACCOUNT",
              permissions
            ) && (
              <ActionButton
                color="#2C1DFF"
                className="float-right"
                style={{ margin: "0px 5px" }}
                onClick={currentContent}
                id="deleteFinancialActivity"
              >
                <img
                  src={
                    require("../../../../../assets/images/icons/delete.svg")
                      .default
                  }
                  alt="delete icon"
                />
                Delete Account
              </ActionButton>
            )}
            {CheckPermission(
              "UPDATE_FINANCIALACTIVITYACCOUNT",
              permissions
            ) && (
              <ActionButton
                color="#2C1DFF"
                className="float-right"
                style={{ margin: "0px 5px" }}
                onClick={currentContent}
                id="editFinancialActivity"
              >
                <img
                  src={
                    require("../../../../../assets/images/icons/edit-profile.svg")
                      .default
                  }
                  alt="add icon"
                />
                Edit Account
              </ActionButton>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <div className="m-2">
              <Table responsive striped bordered hover>
                <thead className="border-0">
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Financial Activity
                    </td>
                    <td>{!!financialActivities ? financialActivities : ""}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Account Name
                    </td>
                    <td>{!!accountName ? accountName : ""}</td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold md-font-weight">
                      Account Number
                    </td>
                    <td>{!!accountNumber ? accountNumber : ""}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FinancialActivityDetail;
