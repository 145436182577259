import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Table } from "antd";
import { getCheckerDetails } from "../../../../../actions/businessActions";
import { processAllMakerChecker } from "../../../../../actions/businessActions";
import {
  getCreateCustomerDetails,
  getLoansProductOptions,
} from "../../../../../actions/utilityActions";
import ModalContainer from "../../../components/ModalContainer";
import ActionModal from "../components/modals/ActionModal";
import { ActionButton } from "../styles";
import {
  convertToDateString,
  formatDateChecker,
} from "../../../../../util/FormatDate";
import CheckedWhiteSvg from "../../../../../assets/images/icons/checked-white.svg";
import DeleteSvg from "../../../../../assets/images/icons/delete.svg";
import CloseSvg from "../../../../../assets/images/icons/cancel.svg";
import {
  getAllChartsOfAccountTemplate,
  getAllGlAccounts,
  getSavingsAccounts,
} from "../../../../../actions/accountingActions";
import { formatCurrency } from "../../../../../util/FormatAmount";
import { getSingleCustomer } from "../../../../../actions/customerActions";
import {
  getAllCharges,
  getLoanProductsTemplate,
} from "../../../../../actions/configurationActions";
import {
  getLoanDetails,
  getSavingsAccountDetails,
} from "../../../../../actions/SavingsAndLoanActions";
import { numberWithCommas } from "../../../../../util/FormatNumber";
import CustomButton from "../../../../../components/CustomButton";
import TableWrapper from "../components/table/TableWrapper";
import TableRow from "../components/table/TableRow";
import { columns } from "./data";
import { convertToCapitalCase } from "../../../../../util/ConvertToCapitalCase";
import TableRowSpaced from "../components/table/TableRowSpaced";
import TableWrapperSpaced from "../components/table/TableWrapperSpaced";
import ChargesRow from "../components/table/ChargesRow";
import axiosData from "../../../../../services/axiosData";
import config from "../../../../../config";
import { getFixedDepositAccountDetails } from "../../../../../actions/fixedDeposit";

const CheckerDetails = ({ history }) => {
  let { id } = useParams();
  const dispatch = useDispatch();

  const [actionId, setActionId] = useState();
  const [custId, setCustId] = useState("");
  const [data, setData] = useState();
  const [modalState, toggleModal] = useState(false);
  const [modalContentName, setModalContentName] = useState("");
  const [newTag, setNewTag] = useState("");
  const [newParent, setNewParent] = useState("");
  const [newConstId, setNewConstId] = useState("");
  const [tierValue, setTierValue] = useState("");
  const [clientTypeValue, setClientTypeValue] = useState("");

  const [legalState, setLegalState] = useState("");
  const [staffNameState, setStaffNameState] = useState("");

  const [singleUserData, setSingleUserData] = useState([]);

  const { checkerInboxDetails } = useSelector((state) => state.businessReducer);
  const {
    id: actId,
    actionName,
    entityName,
    officeName,
    resourceId,
    clientId,
    maker,
    processingResult,
    savingsAccountNo,
    madeOnDate,
  } = checkerInboxDetails;
  const { singleUser } = useSelector((state) => state.SingleCustomerDetails);

  const { timeline, clientTier, legalForm, clientNonPersonDetails } =
    singleUserData;

  const { createCustomerDetails } = useSelector(
    (state) => state.createCustomerDetails
  );
  const {
    clientLegalFormOptions = [],
    clientTierOptions,
    clientNonPersonConstitutionOptions = [],
  } = createCustomerDetails || {};
  const { charges } = useSelector((state) => state.chargesReducer);
  const accountingData = useSelector((state) => state.accountingData);
  const {
    glAccounts,
    savingsAccount = [],
    chartsOfAccountTemplate = {},
  } = accountingData;
  const { accountTypeOptions = [], usageOptions = [] } =
    chartsOfAccountTemplate;
  const { savingsAccountDetails, loanDetails } = useSelector(
    (state) => state.savingsAndLoan
  );
  
  const { loanProductsTemplate } = useSelector(
    (state) => state.loanProductsTemplate
  );
  const { fixedDepositAccountDetails } = useSelector((state) => state.fixedDepositData);

  const { incorpNumber, incorpValidityTillDate, constitutionId } =
    data?.clientNonPersonDetails ?? {};

  useEffect(() => {
    if (checkerInboxDetails?.clientId) {
      dispatch(getLoansProductOptions(checkerInboxDetails?.clientId));
    }
  }, [checkerInboxDetails?.clientId]);

  useEffect(() => {
    const getTags = () => {
      switch (parseInt(data?.type)) {
        case 1:
          return chartsOfAccountTemplate?.allowedAssetsTagOptions?.find(
            (sitem) => sitem.id === parseInt(data?.tagId)
          )?.name;
        case 2:
          return chartsOfAccountTemplate?.allowedLiabilitiesTagOptions?.find(
            (sitem) => sitem.id === parseInt(data?.tagId)
          )?.name;
        case 3:
          return chartsOfAccountTemplate?.allowedEquityTagOptions?.find(
            (sitem) => sitem.id === parseInt(data?.tagId)
          )?.name;
        case 4:
          return chartsOfAccountTemplate?.allowedIncomeTagOptions?.find(
            (sitem) => sitem.id === parseInt(data?.tagId)
          )?.name;
        case 5:
          return chartsOfAccountTemplate?.allowedExpensesTagOptions?.find(
            (sitem) => sitem.id === parseInt(data?.tagId)
          )?.name;
        default:
          return;
      }
    };
    const getParents = () => {
      switch (parseInt(data?.type)) {
        case 1:
          return chartsOfAccountTemplate?.assetHeaderAccountOptions?.find(
            (sitem) => sitem.id === parseInt(data?.parentId)
          )?.name;
        case 2:
          return chartsOfAccountTemplate?.liabilityHeaderAccountOptions?.find(
            (sitem) => sitem.id === parseInt(data?.parentId)
          )?.name;
        case 3:
          return chartsOfAccountTemplate?.equityHeaderAccountOptions?.find(
            (sitem) => sitem.id === parseInt(data?.parentId)
          )?.name;
        case 4:
          return chartsOfAccountTemplate?.incomeHeaderAccountOptions?.find(
            (sitem) => sitem.id === parseInt(data?.parentId)
          )?.name;
        case 5:
          return chartsOfAccountTemplate?.expenseHeaderAccountOptions?.find(
            (sitem) => sitem.id === parseInt(data?.parentId)
          )?.name;
        default:
          return;
      }
    };
    const getConstitution = () =>
      clientNonPersonConstitutionOptions?.find(
        (item) => item.id == constitutionId
      )?.name;
    const getTierValue = () => {
      const tierRank = data?.tierRank;

      const tierRankId = clientTierOptions?.find(
        (item) => parseInt(item.tierRank) === tierRank
      )?.tierName;

      return tierRankId;
    };

    const getClientType = () =>
      clientLegalFormOptions?.find((item) => item.id == legalForm)?.value;

    setNewTag(getTags());
    setNewParent(getParents());
    setNewConstId(getConstitution());

    const tierValue2 = getTierValue();
    setTierValue(tierValue2);

    setClientTypeValue(getClientType());
  }, [
    data,
    chartsOfAccountTemplate,
    clientNonPersonConstitutionOptions,
    constitutionId,
    clientTier?.tierRank,
    clientTierOptions,
    clientLegalFormOptions,
    legalForm,
  ]);

  useEffect(() => {
    setCustId(checkerInboxDetails?.clientId);
  }, [checkerInboxDetails?.clientId]);

  useEffect(() => {
    setActionId(id);
  }, [id]);

  useEffect(() => {
    dispatch(getAllGlAccounts());
    dispatch(getSavingsAccounts());
    dispatch(getAllChartsOfAccountTemplate());
    dispatch(getAllChartsOfAccountTemplate());
    dispatch(getAllCharges());
    dispatch(
      getCreateCustomerDetails(
        "clients/template?staffInSelectedOfficeOnly=true"
      )
    );
  }, [dispatch]);

  useEffect(() => {
    if (
      (checkerInboxDetails.actionName === "ASSIGNSTAFF" ||
        checkerInboxDetails.actionName === "ACTIVATE") &&
      custId
    ) {
      dispatch(getSingleCustomer(custId));
    }
  }, [custId]);

  useEffect(() => {
    if (data?.linkAccountId && checkerInboxDetails.actionName === "CREATE") {
      dispatch(getSavingsAccountDetails(data?.linkAccountId));
    } else if (
      checkerInboxDetails?.resourceId &&
      checkerInboxDetails.actionName !== "CREATE" &&
      checkerInboxDetails?.entityName === "SAVINGSACCOUNT"
    ) {
      dispatch(getSavingsAccountDetails(checkerInboxDetails?.resourceId));
    } else if (checkerInboxDetails?.clientId) {
      dispatch(getSavingsAccountDetails(checkerInboxDetails?.clientId));
    }
    dispatch(getLoanProductsTemplate());
  }, [data]);

  useEffect(() => {
    if (
      checkerInboxDetails?.entityName === "LOAN" &&
      checkerInboxDetails?.actionName !== "CREATE"
    ) {
      dispatch(getLoanDetails(checkerInboxDetails?.loanId));
    }
  }, [checkerInboxDetails?.loanId]);

  useEffect(() => {
    if (
      checkerInboxDetails?.entityName === "FIXEDDEPOSITACCOUNT" &&
      checkerInboxDetails?.actionName !== "CREATE"
    ) {
      dispatch(getFixedDepositAccountDetails(
        Number(checkerInboxDetails?.savingsAccountNo) || checkerInboxDetails?.savingsAccountNo
      ));
    }
  }, [checkerInboxDetails?.savingsAccountNo]);

  useEffect(() => {
    if (actionId) {
      dispatch(getCheckerDetails(actionId));
    }
  }, [actionId]);

  useEffect(() => {
    if (checkerInboxDetails?.commandAsJson) {
      setData(JSON?.parse(checkerInboxDetails?.commandAsJson));
    }
  }, [checkerInboxDetails?.commandAsJson]);

  
  let tableData = [];

  data?.address &&
    data?.address?.forEach((singleAddress) => {
      let {
        addressLine1,
        addressTypeId,
        city,
        countryId,
        isActive,
        stateProvinceId,
      } = singleAddress;
      tableData.push({
        key: addressLine1.length,
        city,
        country: countryId === 21 ? "Nigeria" : "Canada",
        isActive: isActive?.toString(),
        stateProvinceId,
        addressLine1: addressLine1,
        address: addressTypeId === 18 ? "Residential" : "Commercial",
      });
    });

  const currentContent = (e) => {
    if (id) {
      const val = e.currentTarget.id.trim();
      toggleModal(true);
      return setModalContentName(val);
    }
  };
  const handleModalClose = () => toggleModal(false);

  const handleApprove = () => {
    dispatch(
      processAllMakerChecker(
        id,
        modalContentName.toLocaleLowerCase(),
        {},
        history
      )
    );
  };

  const displayModalContent = () => {
    return (
      <ActionModal
        header={`Confirm ${modalContentName}`}
        onHide={handleModalClose}
        action={handleApprove}
      />
    );
  };

  useEffect(() => {
    if (checkerInboxDetails.actionName === "CREATE") {
      setSingleUserData("");
    } else {
      setSingleUserData(singleUser);
    }
  }, [singleUser, checkerInboxDetails.actionName]);

  useEffect(() => {
    if (checkerInboxDetails.actionName === "CREATE") {
      setLegalState("");
      setStaffNameState("");
      // setActiveState("")
    } else {
      setLegalState(legalForm?.id);
      setStaffNameState(singleUser?.staffName);
      // setActiveState()
    }
  }, [checkerInboxDetails.actionName, legalForm?.id, singleUser?.staffName]);

  // Create a function to render TableRow components conditionally
  const renderConditionalEntityTableRows = () => {
    if (data?.legalFormId === "2" || legalState === 2) {
      return (
        <>
          <TableRow label="Client Type" value="Entity" />
          <TableRow
            label="Business Name"
            value={data?.fullname || singleUser?.displayName}
          />
          <TableRow
            label="Incorporation Date"
            value={
              data?.dateOfBirth || formatDateChecker(singleUser?.dateOfBirth)
            }
          />
          <TableRow
            label="Incorporation Validity Till Date"
            value={
              incorpValidityTillDate ||
              formatDateChecker(clientNonPersonDetails?.incorpValidityTillDate)
            }
          />
          {(incorpNumber || clientNonPersonDetails?.incorpNumber) && (
            <TableRow
              label="Incorporation Number"
              value={incorpNumber || clientNonPersonDetails?.incorpNumber}
            />
          )}
          <TableRow
            label="Constitution"
            value={newConstId || clientNonPersonDetails?.constitution?.name}
          />
        </>
      );
    }
    // Return null or any other component if conditions are not met
    return null;
  };

  // Create a function to render TableRow components conditionally
  const renderConditionalPersonTableRows = () => {
    if (data?.legalFormId === "1" || legalState === 1) {
      return (
        <>
          <TableRow label="Client Type" value="Person" />
          <TableRow
            label="First Name"
            value={data?.firstname || singleUser?.firstname}
          />
          <TableRow
            label="Middle Name"
            value={data?.middlename || singleUser?.middlename}
          />
          <TableRow
            label="Last Name"
            value={data?.lastname || singleUser?.lastname}
          />
        </>
      );
    }
    return null;
  };

  return (
    <>
      <ModalContainer show={modalState} dialogClassName="document_infoModal">
        {modalState && displayModalContent()}
      </ModalContainer>

      <div className="container-fluid overflow-auto">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-2 mb-4">
            <h3 className="page-title">Action Details</h3>
            <p className="sub-title">An overview of maker actions details</p>
          </div>
        </div>
        <div className="mb-4 mt-2 d-flex row">
          <div className="col-md-5 d-flex align-items-center">
            <ActionButton
              style={{ width: "80px" }}
              onClick={() => history.push("/core/checkers")}
            >
              Back
            </ActionButton>
          </div>
          <div className="col-md-7 d-flex justify-content-end">
            <CustomButton
              id="Approve"
              icon={CheckedWhiteSvg}
              onClick={currentContent}
              buttonText="Approve"
              buttonClass="approve-btn bg-success px-3 mr-3"
            />
            <CustomButton
              id="Delete"
              icon={DeleteSvg}
              onClick={currentContent}
              buttonText="Delete"
              buttonClass="bg-danger px-3 mr-3"
            />
            <CustomButton
              id="Reject"
              icon={CloseSvg}
              onClick={currentContent}
              buttonText="Reject"
              buttonClass="bg-warning px-3"
            />
          </div>
        </div>
        <TableWrapper>
          <table>
            {actId && <TableRow label="Action ID" value={actId} />}
            {actionName && <TableRow label="Action Name" value={actionName} />}
            {entityName && <TableRow label="Entity Name" value={entityName} />}
            {officeName && <TableRow label="Office Name" value={officeName} />}
            {resourceId && <TableRow label="Resource ID" value={resourceId} />}
          </table>
          <table>
            {clientId && <TableRow label="Client ID" value={clientId} />}
            {maker && <TableRow label="Maker" value={maker} />}
            {processingResult && (
              <TableRow label="Status" value={processingResult} />
            )}
            {savingsAccountNo && (
              <TableRow label="Savings Account No." value={savingsAccountNo} />
            )}
            {madeOnDate && (
              <TableRow
                label="Action Date"
                value={convertToDateString(madeOnDate)}
              />
            )}
          </table>
        </TableWrapper>

        {checkerInboxDetails?.entityName === "ACCOUNT_JOURNALENTRY" && (
          <>
            <TableWrapper showTitle>
              <table>
                <TableRow label="Currency" value={data?.currencyCode} />
                <TableRow
                  label="Transaction Date"
                  value={data?.transactionDate}
                />
              </table>
              <table>
                <TableRow label="Reference Id" value={data?.referenceNumber} />
                {data?.comments && (
                  <TableRow label="Description" value={data?.comments} />
                )}
              </table>
            </TableWrapper>

            {/* Customer Accounts  &&  Operation Type*/}
            <div className="col-md-12 colmd12">
              <div className="col-md-6 colmd6">
                <div className="col-md-3 colmd3">Customer Accounts</div>
                <div className="col-md-3 colmd3">
                  <span className="operationType1"> Operation Type: </span>
                  {(data?.operationType === "Credit" ||
                    data?.operationType === "CREDIT") && (
                    <span
                      className="spanAcc"
                      style={{ backgroundColor: "#128100" }}
                    >
                      Credit
                    </span>
                  )}
                  {(data?.operationType === "Debit" ||
                    data?.operationType === "DEBIT") && (
                    <span
                      className="spanAcc"
                      style={{ backgroundColor: "#f08f62" }}
                    >
                      Debit
                    </span>
                  )}
                </div>
              </div>

              <div className="col-md-6 colmd6">
                <div className="col-md-3 colmd3" style={{ marginLeft: "20px" }}>
                  Gl Account
                </div>
                <div className="col-md-3 colmd3">
                  <span className="operationType2">Operation Type: </span>
                  {(data?.operationType === "Credit" ||
                    data?.operationType === "CREDIT") && (
                    <span
                      className="spanGl"
                      style={{ backgroundColor: "#f08f62" }}
                    >
                      Debit
                    </span>
                  )}
                  {(data?.operationType === "Debit" ||
                    data?.operationType === "DeEBIT") && (
                    <span
                      className="spanGl"
                      style={{ backgroundColor: "#128100" }}
                    >
                      Credit
                    </span>
                  )}
                </div>
              </div>
            </div>
            {/* <h3>Debit Details </h3> */}

            <div className="col-md-12 colmd12Cust">
              {/* customer Account */}
              {(data?.operationType === "Credit" ||
                data?.operationType === "CREDIT") &&
                data.debits?.length > 0 && (
                  <>
                    <div className="col-md-6 colmd6Cust">
                      <div>
                        <table
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "30px",
                            gap: "30px",
                          }}
                        >
                          <CustomerAccounts
                            customers={data?.customerAccounts}
                          />
                        </table>
                      </div>
                    </div>
                    <div
                      style={{
                        borderLeft: "1px solid #E0E4F4",
                        height: "auto",
                        marginTop: "-30px",
                      }}
                    ></div>
                    {/* Debit / credit GL */}
                    <div className="col-md-6 colmd6Cust">
                      <div>
                        <table
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "30px",
                            gap: "30px",
                          }}
                        >
                          {data?.debits?.map((itemgl, index) => {
                            const savingsArr = glAccounts?.find(
                              (sitem) => sitem.id == itemgl?.glAccountId
                            );
                            const glName = savingsArr ? savingsArr.name : "";
                            return (
                              <>
                                <div className="tableBorder">
                                  <tr>
                                    <td className="tdd11">GL Account</td>
                                    <td style={{ fontSize: "13px" }}>
                                      {convertToCapitalCase(glName)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="tdd11">Amount</td>
                                    <td style={{ fontSize: "13px" }}>
                                      {formatCurrency(itemgl?.amount)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="tdd11">Comments</td>
                                    <td style={{ fontSize: "13px" }}>
                                      {itemgl?.comments}
                                    </td>
                                  </tr>
                                </div>
                              </>
                            );
                          })}
                        </table>
                      </div>
                    </div>
                  </>
                )}

              {(data?.operationType === "Debit" ||
                data?.operationType === "DEBIT") &&
                data.credits?.length > 0 && (
                  <>
                    <div className="col-md-6 colmd6Cust">
                      <div>
                        <table
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "30px",
                            gap: "30px",
                          }}
                        >
                          <CustomerAccounts
                            customers={data?.customerAccounts}
                          />
                        </table>
                      </div>
                    </div>
                    <div
                      style={{
                        borderLeft: "1px solid #E0E4F4",
                        height: "auto",
                        marginTop: "-30px",
                      }}
                    ></div>
                    {/* Debit / credit GL */}
                    <div className="col-md-6 colmd6Cust">
                      <div>
                        <table
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "30px",
                            gap: "30px",
                          }}
                        >
                          {data?.credits?.map((itemgl, index) => {
                            const savingsArr = glAccounts?.find(
                              (sitem) => sitem.id == itemgl?.glAccountId
                            );
                            const glName = savingsArr ? savingsArr.name : "";
                            return (
                              <>
                                <div className="tableBorder">
                                  <tr>
                                    <td className="tdd11">GL Account</td>
                                    <td style={{ fontSize: "13px" }}>
                                      {convertToCapitalCase(glName)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="tdd11">Amount</td>
                                    <td style={{ fontSize: "13px" }}>
                                      {formatCurrency(itemgl?.amount)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="tdd11">Comments</td>
                                    <td style={{ fontSize: "13px" }}>
                                      {itemgl?.comments}
                                    </td>
                                  </tr>
                                </div>
                              </>
                            );
                          })}
                        </table>
                      </div>
                    </div>
                  </>
                )}
            </div>
          </>
        )}

        {checkerInboxDetails?.entityName === "JOURNALENTRY" && (
          <>
            <TableWrapper showTitle>
              <table>
                <TableRow label="Currency" value={data?.currencyCode} />
                <TableRow
                  label="Transaction Date"
                  value={data?.transactionDate}
                />
              </table>
              <table>
                <TableRow label="Reference Id" value={data?.referenceNumber} />
                {data?.comments && (
                  <TableRow label="Description" value={data?.comments} />
                )}
              </table>
            </TableWrapper>

            {/* Customer Accounts  &&  Operation Type*/}
            <div className="col-md-12 colmd12">
              <div className="col-md-6 colmd6">
                <div className="col-md-3 colmd3">Credit:</div>
                <div className="col-md-3 colmd3">
                  <span className="operationType1"> GL Account </span>
                </div>
              </div>

              <div className="col-md-6 colmd6">
                <div className="col-md-3 colmd3" style={{ marginLeft: "20px" }}>
                  Debit:
                </div>
                <div className="col-md-3 colmd3">
                  <span className="operationType2">Gl Account </span>
                </div>
              </div>
            </div>
            {/* <h3>Debit Details </h3> */}

            <div className="col-md-12 colmd12Cust">
              {/* customer Account */}
              {data?.credits?.length > 0 && (
                <>
                  {/* Debit / credit GL */}
                  <div className="col-md-6 colmd6Cust">
                    <div>
                      <table
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "30px",
                          gap: "30px",
                        }}
                      >
                        {data?.credits?.map((itemgl, index) => {
                          const savingsArr = glAccounts?.find(
                            (sitem) => sitem.id == itemgl?.glAccountId
                          );
                          const glName = savingsArr ? savingsArr.name : "";
                          return (
                            <>
                              <div className="tableBorder">
                                <tr>
                                  <td className="tdd11">GL Account</td>
                                  <td style={{ fontSize: "13px" }}>
                                    {convertToCapitalCase(glName)}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tdd11">Amount</td>
                                  <td style={{ fontSize: "13px" }}>
                                    {formatCurrency(itemgl?.amount)}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tdd11">Comments</td>
                                  <td style={{ fontSize: "13px" }}>
                                    {itemgl?.comments}
                                  </td>
                                </tr>
                              </div>
                            </>
                          );
                        })}
                      </table>
                    </div>
                  </div>
                </>
              )}

              {data?.debits?.length > 0 && (
                <>
                  {/* Debit / credit GL */}
                  <div className="col-md-6 colmd6Cust">
                    <div>
                      <table
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "30px",
                          gap: "30px",
                        }}
                      >
                        {data?.debits?.map((itemgl, index) => {
                          const savingsArr = glAccounts?.find(
                            (sitem) => sitem.id == itemgl?.glAccountId
                          );
                          const glName = savingsArr ? savingsArr.name : "";
                          return (
                            <>
                              <div className="tableBorder">
                                <tr>
                                  <td className="tdd11">GL Account</td>
                                  <td style={{ fontSize: "13px" }}>
                                    {convertToCapitalCase(glName)}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tdd11">Amount</td>
                                  <td style={{ fontSize: "13px" }}>
                                    {formatCurrency(itemgl?.amount)}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tdd11">Comments</td>
                                  <td style={{ fontSize: "13px" }}>
                                    {itemgl?.comments}
                                  </td>
                                </tr>
                              </div>
                            </>
                          );
                        })}
                      </table>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}

        {checkerInboxDetails?.entityName === "CLIENT" && (
          <TableWrapper showTitle>
            <table>
              {renderConditionalPersonTableRows()}
              {renderConditionalEntityTableRows()}
              {(data?.staffName || staffNameState) && (
                <TableRow
                  label="Assigned Staff"
                  value={
                    checkerInboxDetails.actionName === "CREATE" ||
                    checkerInboxDetails.actionName === "ASSIGNSTAFF"
                      ? data?.staffName
                      : singleUser?.staffName
                  }
                />
              )}
              <TableRow
                label="Email Address"
                value={data?.emailAddress || singleUser?.emailAddress || ""}
              />
              <TableRow
                label="Mobile No"
                value={data?.mobileNo || singleUser?.mobileNo || ""}
              />
            </table>
            <table>
              <TableRow
                label="Submitted Date"
                value={
                  data?.submittedOnDate ??
                  formatDateChecker(timeline?.submittedOnDate)
                }
              />
              {data?.approvedOnDate ||
                (singleUser?.approvedOnDate && (
                  <TableRow
                    label="Date Approved"
                    value={convertToDateString(
                      data?.approvedOnDate ?? singleUser?.approvedOnDate
                    )}
                  />
                ))}
              <TableRow
                label="Account status"
                value={
                  data?.active || singleUserData?.active ? "Active" : "Inactive"
                }
              />
              <TableRow
                label="External ID"
                value={data?.externalId ?? singleUser?.externalId}
              />
              <TableRow
                label="Tier Level"
                value={tierValue ?? tierValue ?? clientTier?.tierName}
              />
              {data?.savingsProductId && (
                <TableRow
                  label="Savings Product ID"
                  value={data?.savingsProductId}
                />
              )}
              <TableRow
                label="Note"
                value={data?.note ?? singleUser?.note ?? "No description added"}
              />
              {(data?.activationDate || timeline?.activatedOnDate) && (
                <TableRow
                  label="Activation Date"
                  value={
                    data?.activationDate ??
                    formatDateChecker(timeline?.activatedOnDate)
                  }
                />
              )}
            </table>
          </TableWrapper>
        )}
        {checkerInboxDetails?.entityName === "GLACCOUNT" && (
          <TableWrapper>
            <table>
              <TableRow label="GL Name" value={data?.name} />
              <TableRow
                label="Manual Entries Allowed"
                value={data?.manualEntriesAllowed ? "True" : "False"}
              />
              <TableRow label="Parent" value={newParent} />
              <TableRow label="Tag" value={newTag} />
            </table>
            <table>
              <TableRow
                label="Account Type"
                value={
                  accountTypeOptions?.find((sitem) => sitem.id == data?.type)
                    ?.value
                }
              />
              <TableRow
                label="Used As"
                value={
                  usageOptions?.find((sitem) => sitem.id == data?.usage)?.value
                }
              />
              <TableRow label="Description" value={data?.description} />
              <TableRow
                label="Is System Defined Ledger"
                value={data?.isSystemDefined ? "True" : "False"}
              />
            </table>
          </TableWrapper>
        )}
        {checkerInboxDetails?.entityName === "LOAN" && (
          <TableWrapper showTitle>
            <table>
              {(data?.clientName || loanDetails?.clientName) && (
                <TableRow
                  label="Client Name"
                  value={checkerInboxDetails?.clientName || data?.clientName || loanDetails?.clientName}
                />
              )}
              {checkerInboxDetails?.actionName === "CREATE" &&
                savingsAccountDetails?.accountNo && (
                  <TableRow
                    label="Linked Savings Account"
                    value={savingsAccountDetails?.accountNo}
                  />
                )}
              {checkerInboxDetails?.actionName !== "CREATE" &&
                loanDetails?.linkedAccount?.accountNo && (
                  <TableRow
                    label="Linked Savings Account"
                    value={loanDetails?.linkedAccount?.accountNo}
                  />
                )}
              {(data?.productName || loanDetails?.loanProductName) && (
                <TableRow
                  label="Product Name"
                  value={data?.productName || loanDetails?.loanProductName}
                />
              )}
              {(data?.loanOfficerName || loanDetails?.loanOfficerName) && (
                <TableRow
                  label="Loan Officer Name"
                  value={data?.loanOfficerName || loanDetails?.loanOfficerName}
                />
              )}
              {(data?.loanTermFrequency || loanDetails?.termFrequency) &&
                (data?.loanTermFrequencyType ||
                  loanDetails?.termPeriodFrequencyType?.value) && (
                  <TableRow
                    label="Loan Term Frequency"
                    value={`${
                      data?.loanTermFrequency || loanDetails?.termFrequency
                    }
                    ${
                      data?.loanTermFrequencyType ||
                      loanDetails?.termPeriodFrequencyType?.value
                    }`}
                  />
                )}
              {(data?.repaymentEvery || loanDetails?.termFrequency) &&
                (data?.repaymentFrequencyType ||
                  loanDetails?.repaymentFrequencyType?.value) && (
                  <TableRow
                    label="Repayment Frequency"
                    value={`
                      ${data?.repaymentEvery || loanDetails?.repaymentEvery}
                      ${
                        data?.repaymentFrequencyType ||
                        loanDetails?.repaymentFrequencyType?.value
                      }
                    `}
                  />
                )}
              {(data?.interestRatePerPeriod ||
                loanDetails?.interestRatePerPeriod) && (
                <TableRow
                  label="Interest Rate Per Period"
                  value={`${
                    data?.interestRatePerPeriod ||
                    loanDetails?.interestRatePerPeriod
                  } %`}
                />
              )}
              <TableRow
                label={
                  <>
                    Create Standing Instruction At
                    <br />
                    Disbursement
                  </>
                }
                value={
                  data?.createStandingInstructionAtDisbursement ||
                  loanDetails?.createStandingInstructionAtDisbursement
                    ? "Yes"
                    : "No"
                }
              />
            </table>
            <table>
              {(data?.numberOfRepayments ||
                loanDetails?.numberOfRepayments) && (
                <TableRow
                  label="Number Of Repayments"
                  value={
                    data?.numberOfRepayments || loanDetails?.numberOfRepayments
                  }
                />
              )}
              {data?.expectedDisbursementDate && (
                <TableRow
                  label="Expected Disbursement Date"
                  value={data?.expectedDisbursementDate}
                />
              )}
              {data?.actualDisbursementDate && (
                <TableRow
                  label="Actual Disbursement Date"
                  value={data?.actualDisbursementDate}
                />
              )}
              {(data?.principal || loanDetails?.principal) && (
                <TableRow
                  label="Principal"
                  value={`₦ ${numberWithCommas(
                    data?.principal || loanDetails?.principal
                  )}`}
                />
              )}
              {(data?.externalId || loanDetails?.externalId) && (
                <TableRow
                  label="External ID"
                  value={data?.externalId || loanDetails?.externalId}
                />
              )}
              {data?.submittedOnDate && (
                <TableRow
                  label="Submission Date"
                  value={data?.submittedOnDate}
                />
              )}
              {data?.approvedOnDate && (
                <TableRow label="Approval Date" value={data?.approvedOnDate} />
              )}
              {(data?.transactionProcessingStrategyId ||
                loanDetails?.transactionProcessingStrategyName) && (
                <TableRow
                  label="Repayment Strategy"
                  value={
                    loanProductsTemplate?.transactionProcessingStrategyOptions?.find(
                      (strategy) =>
                        strategy.id === data?.transactionProcessingStrategyId
                    )?.name || loanDetails?.transactionProcessingStrategyName
                  }
                />
              )}
              {checkerInboxDetails?.actionName === "CREATE" &&
                data?.charges?.length && (
                  <tr>
                    <td className="table-cell-title charges-title">Charges</td>
                    <table className="charges-table">
                      {data?.charges
                        ?.map((item) => {
                          const foundCharge = charges?.find(
                            (charge) => charge.id === item.chargeId
                          );
                          return {
                            ...item,
                            name: foundCharge?.name,
                            amount:
                              foundCharge?.chargeCalculationType?.value ===
                              "Flat"
                                ? `₦ ${numberWithCommas(item?.amount)}`
                                : `${item.amount} %`,
                          };
                        })
                        .map((charge) => (
                          <div
                            key={charge?.chargeId}
                            className="charges-section"
                          >
                            <ChargesRow
                              label="Charge Name"
                              value={charge?.name}
                            />
                            <ChargesRow label="Amount" value={charge?.amount} />
                            <ChargesRow
                              label="Due Date"
                              value={convertToDateString(charge?.dueDate)}
                            />
                          </div>
                        ))}
                    </table>
                  </tr>
                )}
              {checkerInboxDetails?.actionName !== "CREATE" &&
                loanDetails?.charges?.length && (
                  <tr>
                    <td className="table-cell-title charges-title">Charges</td>
                    <table className="charges-table">
                      {loanDetails?.charges
                        ?.map((charge) => ({
                          name: charge?.name,
                          amount:
                            charge?.chargeCalculationType?.value === "Flat"
                              ? `₦ ${numberWithCommas(
                                  charge?.amountOrPercentage
                                )}`
                              : `${charge.amountOrPercentage} %`,
                          dueDate: charge?.dueDate,
                        }))
                        .map((charge) => (
                          <div
                            key={charge?.chargeId}
                            className="charges-section"
                          >
                            <ChargesRow
                              label="Charge Name"
                              value={charge?.name}
                            />
                            <ChargesRow label="Amount" value={charge?.amount} />
                            <ChargesRow
                              label="Due Date"
                              value={convertToDateString(charge?.dueDate)}
                            />
                          </div>
                        ))}
                    </table>
                  </tr>
                )}
            </table>
          </TableWrapper>
        )}
        {(checkerInboxDetails?.entityName === "SAVINGSACCOUNT" ||
          checkerInboxDetails?.entityName === "FIXEDDEPOSITACCOUNT") && (
          <TableWrapper>
            <table>
              <TableRow
                label="Client Name"
                value={checkerInboxDetails?.clientName || data?.clientName || savingsAccountDetails?.clientName}
              />
              {(data?.accountNo || data?.linkAccountId) && (
                <TableRow
                  label="Linked Savings Account"
                  value={savingsAccountDetails?.accountNo}
                />
              )}
              {(data?.fieldOfficerName ||
                savingsAccountDetails?.fieldOfficerName) && (
                <TableRow
                  label="Field Officer Name"
                  value={
                    data?.fieldOfficerName ||
                    savingsAccountDetails?.fieldOfficerName
                  }
                />
              )}
              {checkerInboxDetails?.entityName !== "FIXEDDEPOSITACCOUNT" &&
                (data?.externalId || savingsAccountDetails?.externalId) && (
                  <TableRow
                    label="External Id"
                    value={
                      data?.externalId || savingsAccountDetails?.externalId
                    }
                  />
                )}
              {(data?.activatedOnDate ||
                savingsAccountDetails?.activatedOnDate) && (
                <TableRow
                  label="Activation Date"
                  value={
                    data?.activatedOnDate ||
                    savingsAccountDetails?.activatedOnDate
                  }
                />
              )}
              {data?.submittedOnDate && (
                <TableRow
                  label="Submitted On Date"
                  value={
                    data?.submittedOnDate
                    // convertToDateString(timeline?.submittedOnDate)
                  }
                />
              )}
              {data?.approvedOnDate && (
                <TableRow
                  label="Date Approved"
                  value={convertToDateString(data?.approvedOnDate)}
                />
              )}
              {(data?.note || savingsAccountDetails?.note) && (
                <TableRow
                  label="Note"
                  value={data?.note || savingsAccountDetails?.note}
                />
              )}
              {(data?.productName ||
                savingsAccountDetails?.savingsProductName) && (
                <TableRow
                  label="Product Name"
                  value={
                    data?.productName ||
                    savingsAccountDetails?.savingsProductName
                  }
                />
              )}
              {(data?.depositAmount || fixedDepositAccountDetails?.depositAmount) && (
                <TableRow
                  label="Deposit Amount"
                  value={`₦ ${numberWithCommas(data?.depositAmount || fixedDepositAccountDetails?.depositAmount)}`}
                />
              )}
              {(data?.minRequiredOpeningBalance ||
                (checkerInboxDetails?.entityName === "SAVINGSACCOUNT" &&
                  savingsAccountDetails?.minRequiredOpeningBalance)) && (
                <TableRow
                  label="Min Opening Balance"
                  value={`₦ ${formatCurrency(
                    data?.minRequiredOpeningBalance ||
                      savingsAccountDetails?.minRequiredOpeningBalance
                  )}`}
                />
              )}
              {(data?.charges ||
                (data?.charges &&
                  checkerInboxDetails?.entityName === "SAVINGSACCOUNT" &&
                  savingsAccountDetails?.charges)) && (
                <tr>
                  <td className="table-cell-title charges-title">Charges</td>
                  <table className="charges-table">
                    {(data?.charges || savingsAccountDetails?.charges)
                      ?.map((item) => {
                        const foundCharge = charges?.find(
                          (charge) => charge.id === item.chargeId
                        );
                        return {
                          ...item,
                          name: foundCharge?.name,
                          amount:
                            foundCharge?.chargeCalculationType?.value === "Flat"
                              ? `₦ ${numberWithCommas(item?.amount)}`
                              : `${item.amount} %`,
                        };
                      })
                      .map((charge) => (
                        <div key={charge?.chargeId} className="charges-section">
                          <ChargesRow
                            label="Charge Name"
                            value={charge?.name}
                          />
                          <ChargesRow label="Amount" value={charge?.amount} />
                          <ChargesRow
                            label="Due Date"
                            value={convertToDateString(charge?.dueDate)}
                          />
                          {charge?.feeInterval && (
                            <ChargesRow
                              label="Fee Interval"
                              value={charge?.feeInterval}
                            />
                          )}
                        </div>
                      ))}
                  </table>
                </tr>
              )}
            </table>
            <table>
              {+data?.maturityInstructionId === 200 && (
                <TableRow
                  label="Maturity Instruction"
                  value="Transfer to Savings"
                />
              )}
              {data?.depositAmount && data?.depositPeriodFrequencyId && (
                <TableRow
                  label="Deposit Period"
                  value={`${data?.depositPeriod} ${data?.depositPeriodFrequencyId}`}
                />
              )}
              {data?.minDepositTerm && data?.minDepositTermTypeId && (
                <TableRow
                  label="Minimum Deposit Term"
                  value={`${data?.minDepositTerm} ${data?.minDepositTermTypeId}`}
                />
              )}
              <TableRow
                label="Lock in Period Frequency"
                value={
                  data?.lockinPeriodFrequency && data?.lockinPeriodFrequencyType
                    ? `${data?.lockinPeriodFrequency} ${data?.lockinPeriodFrequencyType}`
                    : "False"
                }
              />
              {data?.charts?.[0]?.chartSlabs?.[0]?.annualInterestRate && (
                <TableRow
                  label="Withhold Tax"
                  value={
                    data?.withHoldTax
                      ? "True"
                      : savingsAccountDetails?.withHoldTax
                      ? "True"
                      : "False"
                  }
                />
              )}
              {(data?.interestCalculationDaysInYearType ||
                savingsAccountDetails?.interestCalculationDaysInYearType
                  ?.value) && (
                <TableRow
                  label="Days in Year"
                  value={
                    data?.interestCalculationDaysInYearType ||
                    savingsAccountDetails?.interestCalculationDaysInYearType
                      ?.value
                  }
                />
              )}
              {data?.charts?.[0]?.chartSlabs?.[0]?.annualInterestRate && (
                <TableRow
                  label="Annual Interest Rate"
                  value={`${data?.charts?.[0]?.chartSlabs?.[0]?.annualInterestRate} %`}
                />
              )}

              {checkerInboxDetails?.entityName !== "FIXEDDEPOSITACCOUNT" && (
                <TableRow
                  label="Nominal Interest Rate"
                  value={`${
                    data?.nominalAnnualInterestRate ||
                    savingsAccountDetails?.nominalAnnualInterestRate
                  } %`}
                />
              )}
              <TableRow
                label="Interest Compound"
                value={
                  data?.interestCompoundingPeriodType ||
                  savingsAccountDetails?.interestCompoundingPeriodType?.value
                }
              />
              <TableRow
                label="Interest Posting"
                value={
                  data?.interestPostingPeriodType ||
                  savingsAccountDetails?.interestPostingPeriodType?.value
                }
              />
              {(data?.minRequiredOpeningBalance ||
                savingsAccountDetails?.minRequiredOpeningBalance) && (
                <TableRow
                  label="Interest Calculation"
                  value={
                    data?.interestCalculationType ||
                    savingsAccountDetails?.interestCalculationType?.value
                  }
                />
              )}
              {(data?.interestCalculationDaysInYearType ||
                savingsAccountDetails?.minRequiredOpeningBalance) && (
                <TableRow
                  label="Interest Calculation Days in a year"
                  value={
                    data?.interestCalculationDaysInYearType ||
                    savingsAccountDetails?.interestCalculationDaysInYearType
                      ?.value
                  }
                />
              )}
            </table>
          </TableWrapper>
        )}
        {tableData?.length > 0 && (
          <Table
            pagination={false}
            onRow={(r) => ({
              onClick: (e) => {},
            })}
            columns={columns}
            dataSource={tableData}
          />
        )}
      </div>
    </>
  );
};

export default CheckerDetails;

async function getDisplayName(customerAccount) {
  let bulkClientId = customerAccount?.customerAccountId?.replace(/^0+/, "");
  if (customerAccount?.clientId) {
    const data = await axiosData({
      method: "GET",
      url: `${config.apiGateway.BASE_API_URL}/clients/${customerAccount.clientId}`,
    });

    // const data = await response.json();
    return {
      ...customerAccount,
      displayName: data?.data?.displayName,
    };
  } else {
    const data = await axiosData({
      method: "GET",
      url: `${config.apiGateway.BASE_API_URL}/savingsaccounts/${bulkClientId}?associations=all`,
    });

    // const data = await response.json();
    return {
      ...customerAccount,
      displayName: data?.data?.clientName,
    };
  }
}

const CustomerAccounts = ({ customers }) => {
  const [customerAccountsWithDisplayName, setCustomerAccountsWithDisplayName] =
    useState([]);

  useEffect(() => {
    const fetchDisplayNames = async () => {
      const customerAccountsWithDisplayName = await Promise.all(
        customers?.map(getDisplayName)
      );

      setCustomerAccountsWithDisplayName(customerAccountsWithDisplayName);
    };

    fetchDisplayNames();
  }, [customers]);

  return (
    <div>
      {customerAccountsWithDisplayName?.map((customerAccount) => (
        <>
          <div
            className="tableBorder"
            key={customerAccount?.customerAccountId}
            style={{ marginTop: "10px" }}
          >
            <tr>
              <td className="tdd11">Client Name</td>
              <td style={{ fontSize: "13px" }}>
                {customerAccount?.displayName}
                {/* {getClientName(item?.clientId).data} */}
              </td>
            </tr>
            <tr>
              <td className="tdd11">Savings Account Number</td>
              <td style={{ fontSize: "13px" }}>
                {customerAccount?.customerAccountId}
              </td>
            </tr>
            <tr>
              <td className="tdd11">Amount</td>
              <td style={{ fontSize: "13px" }}>
                {formatCurrency(customerAccount?.amount)}
              </td>
            </tr>
            {customerAccount?.comments && (
              <tr>
                <td className="tdd11">Comments</td>
                <td style={{ fontSize: "13px" }}>
                  {customerAccount?.comments}
                </td>
              </tr>
            )}
          </div>
        </>
      ))}
    </div>
  );
};
