import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import { ContentWrapper, ActionButton, EditButton } from "./styles";
import AccountRuleDetailSlide from "./AccountRuleDetailSlide";
import {
  getAllAccountRules,
  deleteAccountongRule,
  getAllAccountRulesTemplate
} from "../../../../actions/accountingActions";
import { useSelector, useDispatch } from "react-redux";
import CheckPermission from "../../../../util/CheckPermission";
import AccountRuleDetailModal from "./AccountRuleDetailSlide/DeleteAccountRuleModal";
import EditAccountRule from "./AccountRuleDetailSlide/EditAccountRule";
import ModalContainer from "../../../../components/ModalContainer";
import ViewAccountRule from "./AccountRuleDetailSlide/ViewAccountRule";

const columns = [
  { title: "Name", dataIndex: "name", key: "name", width: 150, ellipsis: true },
  { title: "Office", dataIndex: "office", key: "office" },
  {
    title: "Debit Tags",
    dataIndex: "debitTags",
    key: "debitTags",
    width: 130,
    ellipsis: true,
  },
  { title: "Debit Account", dataIndex: "debitAccount", key: "debitAccount" },
  {
    title: "Credit Tags",
    dataIndex: "creditTags",
    key: "creditTags",
    width: 130,
    ellipsis: true,
  },
  { title: "Credit Account", dataIndex: "creditAccount", key: "creditAccount" },
  { title: "", dataIndex: "edit", key: "edit" },
  { title: "", dataIndex: "delete", key: "delete" },
];

const AccountingRules = ({ history }) => {
  const [modalState, toggleModal] = useState(false);
  const [modalContentName, setModalContentName] = useState("");
  const [editState, setDetailStage] = useState("");
  const [selectedRule, setSelectedRule] = useState({});
  const accountingData = useSelector((state) => state.accountingData);
  const dispatch = useDispatch();
  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  const { isLoading, accountRules, accountRulesTemplate } = accountingData;

  const currentContent = (rule) => {
    const oneAccountingRule = accountRules.find((e) => e.id === rule.id);
    setSelectedRule(oneAccountingRule);
    toggleModal(true);
    setModalContentName('viewAccount');
  };

  const handleDeleteAccountingRule = async (e) => {
    e.preventDefault();
    dispatch(deleteAccountongRule(selectedRule.id, history));
    handleModalClose();
  };

  const handleModalClose = () => toggleModal(false);

  const displayModalContent = () => {
    switch (modalContentName) {
      case "deleteAccount":
        return (
          <AccountRuleDetailModal
            onDeleteAccountingRule={handleDeleteAccountingRule}
            onHide={handleModalClose}
          />
        );
      case "editAccount":
        return (
          <EditAccountRule
            onHide={handleModalClose}
            selectedRule={selectedRule}
            history={history}
            accountRulesTemplate ={accountRulesTemplate}
          />
        );
      case "viewAccount":
        return (
          <ViewAccountRule
            onHide={handleModalClose}
            selectedRule={selectedRule}
            history={history}
            accountRulesTemplate ={accountRulesTemplate}
          />
        );

      default:
        return;
    }
  };

  const handleEditRule= (id, event) => {
    event.stopPropagation();
    const oneAccountingRule = accountRules.find((e) => e.id === id);
    setSelectedRule(oneAccountingRule);
    toggleModal(true);
    setModalContentName('editAccount');
  };

  const handleDeleteRule= (id, event) => {
    event.stopPropagation();
    const oneAccountingRule = accountRules.find((e) => e.id === id);
    setSelectedRule(oneAccountingRule);
    toggleModal(true);
    setModalContentName('deleteAccount');
  };

  useEffect(() => {
    dispatch(getAllAccountRules());
    dispatch(getAllAccountRulesTemplate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dataSource = [];
  !isLoading &&
    accountRules &&
    accountRules.forEach((acc) => {
      const {
        id,
        name,
        officeName,
        creditAccounts,
        debitAccounts,
        debitTags,
        creditTags,
        ...rest
      } = acc;
      dataSource.push({
        key: id,
        id,
        name,
        office: officeName,
        debitTags: debitTags?.length ? debitTags[0].tag.name : [],
        debitAccount: debitAccounts?.length ? debitAccounts[0].name : "",
        creditTags: creditTags?.length ? creditTags[0].tag.name : [],
        creditAccount: creditAccounts?.length ? creditAccounts[0].name : "",
        edit: (
          <EditButton onClick={(event) => handleEditRule(id, event)} id="editAccount">
            Edit
          </EditButton>
        ),
        delete: (
          <EditButton onClick={(event) => handleDeleteRule(id, event)} id="deleteAccount">
            Delete
          </EditButton>
        ),
        ...rest,
      });
    });

  return (
    <>
      <ModalContainer
        show={modalState}
        size={`md`}
        dialogClassName="document_infoModal"
      >
        {modalState && displayModalContent()}
      </ModalContainer>
      <div className="container-fluid">
        <div className="row justify-content-between pt-3">
          <div className="col-sm-12 col-md-4">
            <h3 className="page-title">Accounting Rule</h3>
            <p className="sub-title">Accounting Rule</p>
          </div>
          <div className="col-sm-12 col-md-12 col-xl-6">
            <div className="float-right">
              {CheckPermission("CREATE_ACCOUNTINGRULE", permissions) && (
                <ActionButton
                  onClick={() => history.push("/core/accounting/rules/create")}
                  color="#2C1DFF"
                  className=""
                >
                  <img
                    src={
                      require("../../../../assets/images/icons/add.svg").default
                    }
                    className="d-inline mr-3"
                    alt="icon"
                  />
                  Add a rule
                </ActionButton>
              )}
            </div>
          </div>
        </div>

        <div>
          <ContentWrapper>
            <Table
              columns={columns}
              dataSource={dataSource}
              pagination={{ defaultPageSize: 13 }}
              defaultPageSize={13}
              onRow={(r) => ({
                onClick: () => {
                  setDetailStage(true);
                  currentContent(r);
                },
              })}
            />
          </ContentWrapper>
        </div>
      </div>
    </>
  );
};

export default AccountingRules;
