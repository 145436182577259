// import { getAllClientTiers } from '../services/Api/systemTiers.api.services';
import CLIENT_TIER_SERVICES from '../services/Api/systemTiers.api.services';
import {
  SET_LOADING_STATE,
  SET_TABLE_LOADING_STATE,
} from '../constants/utilityConstants';
import {
  GET_CLIENT_TIERS_REQUEST,
  GET_CLIENT_TIERS_SUCCESS,
  GET_CLIENT_TIERS_FAIL,
  CREATE_NEW_CLIENT_TIERS,
  CREATE_NEW_CLIENT_TIERS_SUCCESS,
  EDIT_CLIENT_TIER,
  EDIT_CLIENT_TIER_SUCCESS,
  EDIT_CLIENT_TIER_FAIL,
} from '../constants/systemTiersConstants';
import Toast from '../util/Toast';

const handleError = (ex, dispatch) => {
  dispatch({ type: SET_LOADING_STATE, payload: false });
  dispatch({ type: SET_TABLE_LOADING_STATE, payload: false });
  if (ex.response) {
    dispatch(
      Toast({
        text: ex.response.data.errors
          ? ex.response.data.errors[0].defaultUserMessage
          : ex.response.data.defaultUserMessage,
        icon: 'error',
      })
    );
  } else if (ex.request) {
    dispatch(Toast({ text: String(ex), icon: 'error' }));
  } else {
    dispatch(Toast({ text: String(ex), icon: 'error' }));
  }
};

const getClientTiers = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: GET_CLIENT_TIERS_REQUEST });
    await CLIENT_TIER_SERVICES.getAllClientTiers()
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_CLIENT_TIERS_SUCCESS, payload: response });
      })
      .catch((error) => {
        handleError(error, dispatch);
        dispatch({ type: GET_CLIENT_TIERS_FAIL, payload: error });
      });
  };
};
const createNewClientTier = (data, func) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: EDIT_CLIENT_TIER });
    await CLIENT_TIER_SERVICES.createNewClientTier(data)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: EDIT_CLIENT_TIER_SUCCESS, payload: response });
        func();
        dispatch(Toast({ text: 'New system tier created', icon: 'success' }));
      })
      .catch((error) => {
        handleError(error, dispatch);
        // dispatch(Toast({ text: 'An error occured', icon: 'error' }));
      });
  };
};
const editClientTier = (data, id, func) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: CREATE_NEW_CLIENT_TIERS });
    await CLIENT_TIER_SERVICES.editClientTier(data, id)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: CREATE_NEW_CLIENT_TIERS_SUCCESS, payload: response });
        func();
        dispatch(Toast({ text: 'Edit successful', icon: 'success' }));
      })
      .catch((error) => {
        handleError(error, dispatch);
        dispatch(Toast({ text: 'An error occured', icon: 'error' }));
      });
  };
};
const deleteClientTier = (id, func) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: CREATE_NEW_CLIENT_TIERS });
    await CLIENT_TIER_SERVICES.deleteClientTier(id)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: CREATE_NEW_CLIENT_TIERS_SUCCESS, payload: response });
        func();
        dispatch(Toast({ text: 'System tier deleted', icon: 'success' }));
      })
      .catch((error) => {
        handleError(error, dispatch);
      });
  };
};

export {
  getClientTiers,
  createNewClientTier,
  editClientTier,
  deleteClientTier,
};
