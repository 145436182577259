import React, { useState } from "react";
import { Input } from "@chakra-ui/react";
import { ActionButton2, FormElements, FormClass } from "../styles";
import { Modal, Spinner } from "react-bootstrap";
import {
  updateConfigurationInfo,
  getAllConfigurations,
} from "../../../../../../actions/configurationActions";
import { useDispatch } from "react-redux";

const EditConfiguration = ({ onHide, data }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(data ? data.value : "");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const dataToSubmit = {
      value,
    };
    const func = () => {
      setLoading(false);
      dispatch(getAllConfigurations());
      onHide();
    };
    await dispatch(updateConfigurationInfo(dataToSubmit, data.id, func));
    setLoading(false);
  };
  return (
    <>
      <Modal.Header>
        <Modal.Title>Edit Tax Component</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <FormElements style={{ width: "100%" }}>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Value</label>
              <Input
                style={{ width: "60%" }}
                size="md"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
          </FormElements>
          <div className="float-right mt-4">
            <ActionButton2 color="#2C1DFF" onClick={onHide}>
              <img
                src={
                  require("../../../../../../assets/images/icons/cancel.svg")
                    .default
                }
                alt="cancel icon"
              />
              Cancel
            </ActionButton2>
            <ActionButton2
              onClick={handleSubmit}
              style={{ margin: "0px 0px 0 10px" }}
              disabled={loading}
            >
              {loading ? (
                <Spinner
                  animation="border"
                  color="#fff"
                  className="mr-2 text-white"
                  size="sm"
                />
              ) : (
                <img
                  src={
                    require("../../../../../../assets/images/icons/save.svg")
                      .default
                  }
                  alt="submit icon"
                />
              )}
              Submit
            </ActionButton2>
          </div>
        </FormClass>
      </Modal.Body>
    </>
  );
};

export default EditConfiguration;
