import API_CUSTOMER from "../services/Api/customer.api.services";
import {
  ALL_CUSTOMERS_REQUEST,
  ALL_CUSTOMERS_SUCCESS,
  ALL_CUSTOMERS_FAIL,
  SINGLE_CUSTOMER_REQUEST,
  SINGLE_CUSTOMER_SUCCESS,
  // SINGLE_CUSTOMER_FAIL,
  SINGLE_CUSTOMER_RESET,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAIL,
  UPDATE_CUSTOMER_ADDRESS_REQUEST,
  UPDATE_CUSTOMER_ADDRESS_SUCCESS,
  UPDATE_CUSTOMER_ADDRESS_FAIL,
  CUSTOMER_ADDRESS_REQUEST,
  CUSTOMER_ADDRESS_SUCCESS,
  CUSTOMER_ADDRESS_FAIL,
  CREATE_NEW_CUSTOMER_REQUEST,
  CREATE_NEW_CUSTOMER_SUCCESS,
  CREATE_NEW_CUSTOMER_FAIL,
  CREATE_NEW_CUSTOMER_RESET,
  DOWNLOAD_ACCOUNT_STATEMENT,
  DOWNLOAD_ACCOUNT_STATEMENT_SUCCESS,
  DOWNLOAD_ACCOUNT_STATEMENT_FAIL,
  DOWNLOAD_ACCOUNT_STATEMENT_RESET,
  DOWNLOAD_TRANSACTION_RECEIPT,
  DOWNLOAD_TRANSACTION_RECEIPT_SUCCESS,
  DOWNLOAD_TRANSACTION_RECEIPT_FAIL,
  DOWNLOAD_TRANSACTION_RECEIPT_RESET,
  DOWNLOAD_REPAYMENT_SCHEDULE,
  DOWNLOAD_REPAYMENT_SCHEDULE_SUCCESS,
  DOWNLOAD_REPAYMENT_SCHEDULE_FAIL,
  DOWNLOAD_REPAYMENT_SCHEDULE_RESET,
  IMAGE_UPLOAD_REQUEST,
  IMAGE_UPLOAD_SUCCESS,
  IMAGE_UPLOAD_FAIL,
  IMAGE_UPDATE_REQUEST,
  IMAGE_UPDATE_SUCCESS,
  IMAGE_UPDATE_FAIL,
  GET_IMAGE_REQUEST,
  GET_IMAGE_SUCCESS,
  GET_IMAGE_FAIL,
  GET_IMAGE_RESET,
  DOCUMENT_UPLOAD_REQUEST,
  DOCUMENT_UPLOAD_SUCCESS,
  DOCUMENT_UPLOAD_FAIL,
  GET_DOCUMENTS_REQUEST,
  GET_DOCUMENTS_SUCCESS,
  GET_DOCUMENTS_FAIL,
  GET_DOCUMENTS_RESET,
  DELETE_DOCUMENT_REQUEST,
  DELETE_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_FAIL,
  GET_CHARGES_REQUEST,
  GET_CHARGES_SUCCESS,
  GET_CHARGES_FAIL,
  GET_CUSTOMER_ACCOUNTS_REQUEST,
  GET_CUSTOMER_ACCOUNTS_SUCCESS,
  GET_CUSTOMER_ACCOUNTS_FAIL,
  SEARCH_CUSTOMERS_REQUEST,
  SEARCH_CUSTOMERS_SUCCESS,
  SEARCH_CUSTOMERS_FAIL,
  FILTER_CUSTOMERS_REQUEST,
  CLEAR_FILTER,
  MAKE_DEPOSIT_REQUEST,
  MAKE_DEPOSIT_SUCCESS,
  // MAKE_DEPOSIT_FAIL,
  MAKE_WITHDRAWAL_REQUEST,
  MAKE_WITHDRAWAL_SUCCESS,
  // MAKE_WITHDRAWAL_FAIL,
  GET_LOAN_DETAILS_REQUEST,
  GET_LOAN_DETAILS_SUCCESS,
  GET_LOAN_DETAILS_FAIL,
  LOAN_APPROVAL_REQUEST,
  LOAN_APPROVAL_SUCCESS,
  // LOAN_APPROVAL_FAIL,
  LOAN_DISBURSAL_REQUEST,
  LOAN_DISBURSAL_SUCCESS,
  // LOAN_DISBURSAL_FAIL,
  GET_LOAN_DETAILS_RESET,
  // CREATE_SAVINGS_FAIL,
  CREATE_SAVINGS_REQUEST,
  CREATE_SAVINGS_SUCCESS,
  CREATE_LOAN_REQUEST,
  CREATE_LOAN_SUCCESS,
  // CREATE_LOAN_FAIL,
  ALL_CUSTOMERS_PAGINATION,
  GET_LOAN_LETTER,
  GET_LOAN_LETTER_SUCCESS,
  GET_LOAN_OFFER_LETTER,
  GET_LOAN_OFFER_LETTER_SUCCESS,
  GET_LOAN_LETTER_FAIL,
  SAVINGS_ACCOUNT_FAIL,
  SAVINGS_ACCOUNT_SUCCESS,
  SAVINGS_ACCOUNT_REQUEST,
  UPDATE_SAVINGS_ACCOUNT_REQUEST,
  UPDATE_SAVINGS_ACCOUNT_SUCCESS,
  UPDATE_SAVINGS_ACCOUNT_FAIL,
  UPDATE_CLIENT_TIER_REQUEST,
  UPDATE_CLIENT_TIER_SUCCESS,
  UPDATE_CLIENT_TIER__FAIL,
  ALL_COUNTRY_REQUEST,
  ALL_COUNTRY_SUCCESS,
  ALL_COUNTRY_FAIL,
  ALL_STATES_REQUEST,
  ALL_STATES_SUCCESS,
  ALL_STATES_FAIL,
  CREATE_CUSTOMER_NOTE_REQUEST,
  CREATE_CUSTOMER_NOTE_SUCCESS,
  CREATE_CUSTOMER_NOTE_FAIL,
  FETCH_CUSTOMER_NOTES_REQUEST,
  FETCH_CUSTOMER_NOTES_SUCCESS,
  FETCH_CUSTOMER_NOTES_FAIL,
  UPDATE_CUSTOMER_NOTE_REQUEST,
  UPDATE_CUSTOMER_NOTE_SUCCESS,
  UPDATE_CUSTOMER_NOTE_FAIL,
  DELETE_CUSTOMER_NOTE_REQUEST,
  DELETE_CUSTOMER_NOTE_SUCCESS,
  DELETE_CUSTOMER_NOTE_FAIL,
  CLEAR_CUSTOMER_ACCOUNTS,
} from "../constants/customerConstants";

import {
  SET_LOADING_STATE,
  SET_TABLE_LOADING_STATE,
} from "../constants/utilityConstants";

import {
  getSavingsAccountDetails,
  getLoanDetails as getMyLoanDetails,
} from "./SavingsAndLoanActions";

import {
  LOAN_ACCOUNT_FAIL,
  REPAYMENT_SCHEDULE_FAIL,
  REPAYMENT_SCHEDULE_REQUEST,
  REPAYMENT_SCHEDULE_SUCCESS,
  UPDATE_LOAN_ACCOUNT_FAIL,
} from "../constants/savingsLoan";

import Toast from "../util/Toast";

const customersRequetFail = (payload) => {
  return { type: ALL_CUSTOMERS_FAIL, payload };
};

// const singleCutomerFail = (payload) => {
//   return { type: SINGLE_CUSTOMER_FAIL, payload };
// };

const updateCustomerFail = (payload) => {
  return { type: UPDATE_CUSTOMER_FAIL, payload };
};

const updateCustomerAddressFail = (payload) => {
  return { type: UPDATE_CUSTOMER_ADDRESS_FAIL, payload };
};

const searchCustomerFail = (payload) => {
  return { type: SEARCH_CUSTOMERS_FAIL, payload };
};

const newCustomerFail = (payload) => {
  return { type: CREATE_NEW_CUSTOMER_FAIL, payload };
};
const downloadAccountStatementFail = (payload) => {
  return { type: DOWNLOAD_ACCOUNT_STATEMENT_FAIL, payload };
};
const downloadTransactionReceiptFail = (payload) => {
  return { type: DOWNLOAD_TRANSACTION_RECEIPT_FAIL, payload };
};
const downloadRepaymentScheduleFail = (payload) => {
  return { type: DOWNLOAD_REPAYMENT_SCHEDULE_FAIL, payload };
};

const imageUploadFail = (payload) => {
  return { type: IMAGE_UPLOAD_FAIL, payload };
};

const imageUpdateFail = (payload) => {
  return { type: IMAGE_UPDATE_FAIL, payload };
};

const getImageFail = (payload) => {
  return { type: GET_IMAGE_FAIL, payload };
};

const customerAddressFail = (payload) => {
  return { type: CUSTOMER_ADDRESS_FAIL, payload };
};

const documentUploadFail = (payload) => {
  return { type: DOCUMENT_UPLOAD_FAIL, payload };
};

const getCustomerDocumentsFail = (payload) => {
  return { type: GET_DOCUMENTS_FAIL, payload };
};

const deleteDocumentsFail = (payload) => {
  return { type: DELETE_DOCUMENT_FAIL, payload };
};

const getCustomerChargesFail = (payload) => {
  return { type: GET_CHARGES_FAIL, payload };
};

const getCustomerAccountsFail = (payload) => {
  return { type: GET_CUSTOMER_ACCOUNTS_FAIL, payload };
};
const countryRequetFail = (payload) => {
  return { type: ALL_COUNTRY_FAIL, payload };
};
const statesRequestFail = (payload) => {
  return { type: ALL_STATES_FAIL, payload };
};

const handleError = (ex, dispatch) => {
  if (ex.response) {
    dispatch(
      Toast({
        text: ex.response.data.errors
          ? ex.response.data.errors[0].defaultUserMessage
          : ex.response.data.defaultUserMessage,
        icon: "error",
      })
    );
  } else if (ex.request) {
    dispatch(Toast({ text: String(ex), icon: "error" }));
  } else {
    dispatch(Toast({ text: String(ex), icon: "error" }));
  }

  dispatch({ type: SET_LOADING_STATE, payload: false });
  dispatch({ type: SET_TABLE_LOADING_STATE, payload: false });
};

// GET A LIST OF ALL CUSTOMERS
const getAllCustomers = () => {
  return async (dispatch) => {
    dispatch({ type: ALL_CUSTOMERS_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_CUSTOMER.getAllCustomers()
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: ALL_CUSTOMERS_SUCCESS, payload: response.pageItems });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
        dispatch(customersRequetFail(ex));
      });
  };
};

// GET A LIST OF ALL CUSTOMERS PAGINATION
const getAllCustomersPagination = (params) => {
  return async (dispatch) => {
    dispatch({ type: ALL_CUSTOMERS_PAGINATION });
    dispatch({ type: SET_TABLE_LOADING_STATE, payload: true });
    await API_CUSTOMER.getAllCustomersPagination(params)
      .then((response) => {
        dispatch({ type: SET_TABLE_LOADING_STATE, payload: false });
        dispatch({ type: ALL_CUSTOMERS_PAGINATION, payload: response });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

// GET SINGLE CUSTOMER
const getSingleCustomer = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: SINGLE_CUSTOMER_REQUEST });
    await API_CUSTOMER.getSingleCustomer(id)
      .then(async (response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({
          type: SINGLE_CUSTOMER_SUCCESS,
          payload: { response },
        });
      })
      .catch((exx) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        if (exx.response) {
          dispatch(
            Toast({
              text: exx.response.data.errors
                ? exx.response.data.errors[0].defaultUserMessage
                : exx.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (exx.request) {
          dispatch(Toast({ text: String(exx), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(exx), icon: "error" }));
        }
        // dispatch({
        //   type: SINGLE_CUSTOMER_SUCCESS,
        //   payload: { response, addResponse: null },
        // });
      });
  };
};

// GET CUSTOMER ADDRESS
const getCustomerAddress = (id) => {
  return async (dispatch) => {
    dispatch({ type: CUSTOMER_ADDRESS_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_CUSTOMER.getCustomerAddress(id)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: CUSTOMER_ADDRESS_SUCCESS, payload: response });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
        dispatch(customerAddressFail(ex));
      });
  };
};
const getLoanLetters = () => {
  return async (dispatch) => {
    dispatch({ type: GET_LOAN_LETTER });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_CUSTOMER.getLoanLetters()
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_LOAN_LETTER_SUCCESS, payload: response });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
        dispatch(customerAddressFail(ex));
      });
  };
};

const getLoanOfferLetters = (loanId, currency) => {
  return async (dispatch) => {
    dispatch({ type: GET_LOAN_OFFER_LETTER });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_CUSTOMER.getLoanOfferLetters(loanId, currency)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_LOAN_OFFER_LETTER_SUCCESS, payload: response });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const getLoanAccountStatement = (loanId) => {
  return async (dispatch) => {
    dispatch({ type: GET_LOAN_OFFER_LETTER });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_CUSTOMER.getLoanAccountStatement(loanId)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_LOAN_OFFER_LETTER_SUCCESS, payload: response });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

// getLoanAccountStatement

// Update Customer info Only
const updateOnlyCustomerInfo = (userData, clientId, handleCancel) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_CUSTOMER_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_CUSTOMER.editCustomerInfo(userData, clientId)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        handleCancel();
        dispatch(Toast({ text: "Customer info updated", icon: "success" }));
        dispatch({
          type: UPDATE_CUSTOMER_SUCCESS,
          payload: response.resouceId,
        });
        dispatch(getSingleCustomer(response.clientId));
      })
      .catch((ex) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        handleError(ex, dispatch);
        dispatch(updateCustomerFail(ex));
      });
  };
};

// Update Customer info Only
const updateOnlyAddressInfo = (userData, clientId, handleCancel) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_CUSTOMER_ADDRESS_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_CUSTOMER.editCustomerAddress(userData, clientId)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        handleCancel();
        dispatch(Toast({ text: response?.message, icon: "info" }));
        dispatch({
          type: UPDATE_CUSTOMER_ADDRESS_SUCCESS,
          payload: response.resourceId,
        });
        dispatch(getSingleCustomer(clientId));
        dispatch(getCustomerAddress(userData.addressId));
      })
      .catch((ex) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        handleError(ex, dispatch);
        dispatch(updateCustomerAddressFail(ex));
      });
  };
};

// UPDATE CUSTOMER INFO
const updateCustomerInfo = (
  userData,
  clientId,
  additionalData,
  functionTorun,
  datableAction
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_CUSTOMER_REQUEST });
      dispatch({ type: SET_LOADING_STATE, payload: true });
      const addData = await API_CUSTOMER.editCustomerAdditionalInfo(
        additionalData,
        clientId,
        datableAction
      );
      const data = await API_CUSTOMER.editCustomerInfo(userData, clientId);
      dispatch(Toast({ text: "Customer info updated", icon: "success" }));
      dispatch({ type: UPDATE_CUSTOMER_SUCCESS, payload: data || addData });
      functionTorun();
      dispatch({ type: SET_LOADING_STATE, payload: false });
    } catch (ex) {
      handleError(ex, dispatch);
      dispatch(updateCustomerFail(ex));
    }
  };
};

//UPLOAD CUSTOMER IMAGE
const uploadCustomerImage = (clientId, imgPayload, history) => {
  return async (dispatch) => {
    try {
      dispatch({ type: IMAGE_UPLOAD_REQUEST });
      dispatch(
        Toast({ text: "Customer created... Uploading Image", icon: "info" })
      );
      const data = await API_CUSTOMER.uploadCustomerImage(clientId, imgPayload);
      dispatch({ type: IMAGE_UPLOAD_SUCCESS, payload: data });
      dispatch(Toast({ text: " Image upload success", icon: "success" }));
      history.push(`/core/client/${clientId}`);
    } catch (ex) {
      dispatch(Toast({ text: "Image upload failed", icon: "error" }));
      dispatch(imageUploadFail("Image upload failed"));
      history.push(`/core/client/${clientId}`);
    }
  };
};

//CREATE NEW CUSTOMER
const createNewCustomer = (
  userData,
  imgPayload = null,
  additionalData,
  signatureData = null,
  history
) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_NEW_CUSTOMER_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_CUSTOMER.createNewCustomer(userData)
      .then(async (response) => {
        if (additionalData && (additionalData.Bvn || additionalData.NIN)) {
          await API_CUSTOMER.createNewCustomerAdditionalInfo(
            additionalData,
            response.clientId
          )
            .then((re) => {})
            .catch((ex) => {
              handleError(ex, dispatch);
            });
        }
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({
          type: CREATE_NEW_CUSTOMER_SUCCESS,
          payload: response,
        });
        dispatch(Toast({ text: "Customer created", icon: "success" }));
        if (imgPayload !== null && response.clientId) {
          dispatch(uploadCustomerImage(response.clientId, imgPayload, history));
        }
        if (signatureData !== null && response.clientId) {
          await dispatch(
            uploadCustomerDocument(response.clientId, signatureData)
          );
        }

        if (imgPayload === null && response.commandId) {
          dispatch(Toast({ text: response.message, icon: "info" }));
          history.push('/core/clients');
        } else {
          history.push(`/core/client/${response.clientId}`);
        }
      })
      .catch((ex) => {
        handleError(ex, dispatch);
        dispatch(newCustomerFail(ex));
      });
  };
};

const updateCustomerTier = (id, tierRank, func, func2) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_CLIENT_TIER_REQUEST });
      dispatch({ type: SET_LOADING_STATE, payload: true });
      const addData = await API_CUSTOMER.updateClientTier(id, tierRank);
      dispatch(Toast({ text: "Customer Tier level updated", icon: "success" }));
      dispatch({ type: UPDATE_CLIENT_TIER_SUCCESS, payload: addData });
      dispatch({ type: SET_LOADING_STATE, payload: false });
      func();
      func2();
    } catch (ex) {
      handleError(ex, dispatch);
      dispatch(updateCustomerFail(ex));
    }
  };
};

// //CREATE NEW CUSTOMER
// const createNewCustomer = (
//   userData,
//   imgPayload = null,
//   additionalData,
//   signatureData= null,
//   history
// ) => {
//   return async (dispatch) => {
//     dispatch({ type: CREATE_NEW_CUSTOMER_REQUEST });
//     dispatch({ type: SET_LOADING_STATE, payload: true });
//     await API_CUSTOMER.createNewCustomer(userData)
//       .then(async (response) => {
//         await API_CUSTOMER.createNewCustomerAdditionalInfo(
//           additionalData,
//           response.clientId
//         )
//           .then((re) => {
//             dispatch({ type: SET_LOADING_STATE, payload: false });
//             dispatch({
//               type: CREATE_NEW_CUSTOMER_SUCCESS,
//               payload: response || re,
//             });
//             dispatch(Toast({ text: "Customer created", icon: "success" }));
//             if (imgPayload !== null) {
//               dispatch(
//                 uploadCustomerImage(response.clientId, imgPayload, history)
//               );
//             }
//             if (imgPayload === null) {
//               history.push(`/core/client/${response.clientId}`);
//             }
//           })
//           .catch((ex) => {
//             dispatch({ type: SET_LOADING_STATE, payload: false });
//             dispatch({
//               type: CREATE_NEW_CUSTOMER_SUCCESS,
//               payload: response,
//             });
//             dispatch(Toast({ text: "Customer created", icon: "success" }));
//             if (imgPayload !== null) {
//               dispatch(
//                 uploadCustomerImage(response.clientId, imgPayload, history)
//               );
//             }
//             if (imgPayload === null) {
//               history.push(`/core/client/${response.clientId}`);
//             }
//           });
//       })
//       .catch((ex) => {
//         handleError(ex, dispatch);
//         dispatch(newCustomerFail(ex));
//       });
//   };
// };

//UPDATE CUSTOMER IMAGE
const updateCustomerImage = (clientId, imgPayload) => {
  return async (dispatch) => {
    dispatch({ type: IMAGE_UPDATE_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch(Toast({ text: "Image updating...", icon: "info", timer: 1500 }));
    await API_CUSTOMER.updateCustomerImage(clientId, imgPayload)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: IMAGE_UPDATE_SUCCESS, payload: response });
        dispatch(Toast({ text: " Image update success", icon: "success" }));
      })
      .then(() => dispatch(getCustomerImage(clientId)))
      .catch((ex) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(Toast({ text: "Image update failed", icon: "error" }));
        dispatch(imageUpdateFail("Image update failed"));
      });
  };
};

//FETCH CUSTOMER IMAGE
const getCustomerImage = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_IMAGE_REQUEST });
      const data = await API_CUSTOMER.getCustomerImage(id);
      dispatch({ type: GET_IMAGE_SUCCESS, payload: data });
    } catch (ex) {
      dispatch(getImageFail(ex));
    }
  };
};

//UPLOAD CUSTOMER DOCUMENT
const uploadCustomerDocument = (clientId, docPayload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DOCUMENT_UPLOAD_REQUEST });
      dispatch(Toast({ text: "Uploading document", icon: "info" }));
      await API_CUSTOMER.uploadCustomerDocument(clientId, docPayload);
      dispatch({ type: DOCUMENT_UPLOAD_SUCCESS });
      dispatch(Toast({ text: " Document upload success", icon: "success" }));
    } catch (ex) {
      if (ex.response) {
        dispatch(Toast({ text: ex.response.data.message, icon: "error" }));
        dispatch(documentUploadFail(ex.response.data.message));
      } else {
        dispatch(Toast({ text: String(ex), icon: "error" }));
        dispatch(documentUploadFail(ex));
      }
    }
  };
};

// GET A LIST OF ALL CUSTOMERS
const getCustomerDocuments = (clientId) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: GET_DOCUMENTS_REQUEST });
    await API_CUSTOMER.getCustomerDocuments(clientId)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_DOCUMENTS_SUCCESS, payload: response });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
        dispatch(getCustomerDocumentsFail(ex));
      });
  };
};

// DELETE CUSTOMER DOCUMENT
const deleteCustomerDocument = (clientId, docId) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_DOCUMENT_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch(
      Toast({
        text: `Deleting document ${docId}`,
        icon: "info",
        timer: "1000",
      })
    );
    await API_CUSTOMER.deleteCustomerDocument(clientId, docId)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: DELETE_DOCUMENT_SUCCESS });
        dispatch(
          Toast({
            text: ` Document ${docId} deleted`,
            icon: "success",
            timer: "1000",
          })
        );
      })
      .catch((ex) => {
        handleError(ex, dispatch);
        dispatch(deleteDocumentsFail(ex));
      });
  };
};

// GET A LIST OF CHARGES ON A CUSTOMER
const getCustomerCharges = (clientId) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: GET_CHARGES_REQUEST });
    await API_CUSTOMER.getCustomerCharges(clientId)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_CHARGES_SUCCESS, payload: response.pageItems });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
        dispatch(getCustomerChargesFail(ex));
      });
  };
};

// GET A LIST OF CUSTOMER ACCOUNTS
const getCustomerAccounts = (clientId) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: GET_CUSTOMER_ACCOUNTS_REQUEST });
    await API_CUSTOMER.getCustomerAccounts(clientId)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_CUSTOMER_ACCOUNTS_SUCCESS, payload: response });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
        dispatch(getCustomerAccountsFail(ex));
      });
  };
};

// SEARCH ALL CUSTOMERS DB FROM DASHBOARD HEADER
const searchCustomer = (searchQuery) => {
  return async (dispatch) => {
    dispatch({ type: SEARCH_CUSTOMERS_REQUEST });
    await API_CUSTOMER.searchCustomer(searchQuery)
      .then((response) => {
        if (response.length === 0) {
          dispatch(searchCustomerFail("No volume for this search"));
          dispatch(Toast({ text: "No volume for this search", icon: "error" }));
        } else {
          dispatch({ type: SEARCH_CUSTOMERS_SUCCESS, payload: response });
        }
      })
      .catch((ex) => {
        handleError(ex, dispatch);
        dispatch(searchCustomerFail(ex));
      });
  };
};

// FILTER CUSTOMERS
const filterCustomers = (filterParam) => {
  return (dispatch) => {
    dispatch({ type: FILTER_CUSTOMERS_REQUEST, payload: filterParam });
  };
};
// CLEAR FILTERED CUSTOMERS LIST
const resetSingleCustomer = () => {
  return (dispatch) => {
    dispatch({ type: SINGLE_CUSTOMER_RESET });
  };
};
// CLEAR CUSTOMER IMAGE STATE
const resetCustomerImage = () => {
  return (dispatch) => {
    dispatch({ type: GET_IMAGE_RESET });
  };
};
const resetNewCustomerState = () => {
  return (dispatch) => {
    dispatch({ type: CREATE_NEW_CUSTOMER_RESET });
  };
};
const resetDownloadAccountStatementState = () => {
  return (dispatch) => {
    dispatch({ type: DOWNLOAD_ACCOUNT_STATEMENT_RESET });
  };
};
const resetDownloadTransactionReceiptState = () => {
  return (dispatch) => {
    dispatch({ type: DOWNLOAD_TRANSACTION_RECEIPT_RESET });
  };
};
const resetDownloadRepaymentScheduleState = () => {
  return (dispatch) => {
    dispatch({ type: DOWNLOAD_REPAYMENT_SCHEDULE_RESET });
  };
};
//RESET SINGLE CUSTOMER STATE
const clearFilteredCustomers = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_FILTER });
  };
};
//RESET CUSTOMER DOCUMENTS
const getCustomerDocsReset = () => {
  return (dispatch) => {
    dispatch({ type: GET_DOCUMENTS_RESET });
  };
};

/*
 * ACTIONS ON CUSTOMER ACCOUNTS
 */
// const newSavingsFail = (payload) => {
//   return { type: CREATE_SAVINGS_FAIL, payload };
// };

// const makeDepositFail = (payload) => {
//   return { type: MAKE_DEPOSIT_FAIL, payload };
// };

// const makeWithdrawalFail = (payload) => {
//   return { type: MAKE_WITHDRAWAL_FAIL, payload };
// };

// const createLoanFail = (payload) => {
//   return { type: CREATE_LOAN_FAIL, payload };
// };

const getLoanDetailsFail = (payload) => {
  return { type: GET_LOAN_DETAILS_FAIL, payload };
};

// const approveLoanFail = (payload) => {
//   return { type: LOAN_APPROVAL_FAIL, payload };
// };

// const disburseLoanFail = (payload) => {
//   return { type: LOAN_DISBURSAL_FAIL, payload };
// };

//CREATE NEW SAVINGS ACCOUNT
const createSavingsAccount = (dataPayload, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: CREATE_SAVINGS_REQUEST });
    await API_CUSTOMER.createSavingsAccount(dataPayload)
      .then(async (response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: CREATE_SAVINGS_SUCCESS, payload: response });
        if (response.commandId) {
          dispatch(Toast({ text: response.message, icon: "info" }));
        } else {
          dispatch(Toast({ text: "Account created", icon: "success" }));
        }
        history.push(`/core/client/${dataPayload.clientId}`);
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const aSavingsAccountRequestFail = (payload) => {
  return { type: SAVINGS_ACCOUNT_FAIL, payload };
};
const updateSavingsAccountFail = (payload) => {
  return { type: UPDATE_SAVINGS_ACCOUNT_FAIL, payload };
};

// get a savings account
const getSavingsAccount = (id) => {
  return async (dispatch) => {
    dispatch({ type: SAVINGS_ACCOUNT_REQUEST });
    await API_CUSTOMER.getSavingsAccount(id)
      .then((response) => {
        dispatch({ type: SAVINGS_ACCOUNT_SUCCESS, payload: response });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex?.response?.data?.errors
                ? ex?.response?.data?.errors[0]?.defaultUserMessage
                : ex?.response?.data?.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: "An error occured", icon: "error" }));
        }
        dispatch(aSavingsAccountRequestFail(ex));
      });
  };
};

// Update Savings Product Info
const updateSavingsAccountInfo = (spData, id, history, clientId) => {
  return async (dispatch) => {
    // try {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: UPDATE_SAVINGS_ACCOUNT_REQUEST });
    await API_CUSTOMER.editSavingsAccountInfo(spData, id)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: UPDATE_SAVINGS_ACCOUNT_SUCCESS, payload: response });
        dispatch(
          Toast({ text: "Savings Account info updated", icon: "success" })
        );
        history.goBack();
      })
      .catch((ex) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(updateSavingsAccountFail(ex));
      });
  };
};

//MAKE DEPOSIT IN SAVINGS ACCOUNT
const makeDeposit = (transId, transData, funcTorun) => {
  return async (dispatch) => {
    dispatch({ type: MAKE_DEPOSIT_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_CUSTOMER.makeSavingsDeposit(transId, transData)
      .then((response) => {
        dispatch({ type: MAKE_DEPOSIT_SUCCESS, payload: response });
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(getCustomerAccounts(response.clientId));
        dispatch(getSavingsAccountDetails(response.savingsId));
        // history.push(`/core/client/savings-account/${response.savingsId}`);
        dispatch(
          Toast({ text: "Deposit Successful", icon: "success", timer: 5000 })
        );
        funcTorun();
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
//DOWNLOAD ACCOUNT STATEMENT IN SAVINGS ACCOUNT
const downloadAccountStatement = (
  { startDate, endDate, accountNumber, dateFormat, locale },
  funcTorun
) => {
  return async (dispatch) => {
    dispatch({ type: DOWNLOAD_ACCOUNT_STATEMENT });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_CUSTOMER.downloadAccountStatement({
      startDate,
      endDate,
      accountNumber,
      dateFormat,
      locale,
    })
      .then((response) => {
        dispatch({
          type: DOWNLOAD_ACCOUNT_STATEMENT_SUCCESS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
        funcTorun();
        dispatch(
          Toast({
            text: "Account Statement will open in a new tab",
            icon: "success",
            timer: 5000,
          })
        );
      })
      .catch((ex) => {
        handleError(ex, dispatch);
        dispatch(downloadAccountStatementFail(ex));
      });
  };
};

//DOWNLOAD TRANSACTION RECEIPT STATEMENT IN LOAN ACCOUNT
const downloadTransactionReceipt = ({ transactionId }, funcTorun) => {
  return async (dispatch) => {
    dispatch({ type: DOWNLOAD_TRANSACTION_RECEIPT });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_CUSTOMER.downloadTransactionReceipt({
      transactionId,
    })
      .then((response) => {
        dispatch({
          type: DOWNLOAD_TRANSACTION_RECEIPT_SUCCESS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
        funcTorun();
        dispatch(
          Toast({
            text: "Transaction receipt will open in a new tab",
            icon: "success",
            timer: 3000,
          })
        );
      })
      .catch((ex) => {
        handleError(ex, dispatch);
        dispatch(downloadTransactionReceiptFail(ex));
      });
  };
};

//DOWNLOAD REPAYMENT SCHEDULE IN LOAN ACCOUNTS
const downloadRepaymentSchedule = (
  //{ accountNumber, dateFormat, locale },
  loanId,
  funcTorun
) => {
  return async (dispatch) => {
    dispatch({ type: DOWNLOAD_REPAYMENT_SCHEDULE });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_CUSTOMER.downloadRepaymentSchedule(loanId)
      .then((response) => {
        dispatch({
          type: DOWNLOAD_REPAYMENT_SCHEDULE_SUCCESS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(
          Toast({
            text: "Repayment schedule will download shortly",
            icon: "success",
          })
        );
        funcTorun?.();
      })
      .catch((ex) => {
        handleError(ex, dispatch);
        dispatch(downloadRepaymentScheduleFail(ex));
      });
  };
};

//MAKE WITHDRAWAL IN SAVINGS ACCOUNT
const makeWithdrawal = (transId, transData, funcTorun) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: MAKE_WITHDRAWAL_REQUEST });
    await API_CUSTOMER.makeSavingsWithdrawal(transId, transData)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: MAKE_WITHDRAWAL_SUCCESS, payload: response });
        dispatch(getCustomerAccounts(response.clientId));
        dispatch(getSavingsAccountDetails(response.savingsId));
        dispatch(Toast({ text: "Withdrawal Successful", icon: "success" }));
        funcTorun();
        // history.push(`/core/client/savings-account/${response.savingsId}`);
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

//CREATE LOAN ACCOUNT
const createLoanAccount = (dataPayload, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: CREATE_LOAN_REQUEST });
    await API_CUSTOMER.createLoanAccount(dataPayload)
      .then(async (response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: CREATE_LOAN_SUCCESS, payload: response });
        dispatch(Toast({ text: "Loan created", icon: "success" }));
        history.push(`/core/client/${response.clientId}`);
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

//FETCH LOAN DETAILS
const getLoanDetails = (loanId) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: GET_LOAN_DETAILS_REQUEST });
    await API_CUSTOMER.getLoanDetails(loanId)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_LOAN_DETAILS_SUCCESS, payload: response });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(getLoanDetailsFail(ex));
      });
  };
};
//RESET CUSTOMER DOCUMENTS
const getloanDetaillsReset = () => {
  return (dispatch) => {
    dispatch({ type: GET_LOAN_DETAILS_RESET });
  };
};

//APPROVE A LOAN
const approveLoan = (transId, transData, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: LOAN_APPROVAL_REQUEST });
    await API_CUSTOMER.approveLoan(transId, transData)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: LOAN_APPROVAL_SUCCESS, payload: response });
        dispatch(Toast({ text: "Approval Successful", icon: "success" }));
        // history.push(`/core/client/loan/${response.loanId}`);
        dispatch(getMyLoanDetails(response.loanId));
        dispatch(getCustomerAccounts(response.clientId));
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

//DISBURSE A LOAN
const disburseLoan = (transId, transData, history) => {
  return async (dispatch) => {
    dispatch({ type: LOAN_DISBURSAL_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_CUSTOMER.disburseLoan(transId, transData)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: LOAN_DISBURSAL_SUCCESS, payload: response });
        dispatch(Toast({ text: "Disbursal successful", icon: "success" }));
        // history.push(`/core/client/loan/${response.loanId}`);
        dispatch(getMyLoanDetails(response.loanId));
        dispatch(getCustomerAccounts(response.clientId));
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

//Activate Customer
const activateCustomer = (id, dataToSend, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_CUSTOMER.activateCustomer(id, dataToSend)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(Toast({ text: "Customer Activated", icon: "success" }));
        history.push(`/core/client/${response.clientId}`);
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

// GET A LIST OF ALL CUSTOMERS
const getAllCountries = () => {
  return async (dispatch) => {
    dispatch({ type: ALL_COUNTRY_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_CUSTOMER.getAllCountries()
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: ALL_COUNTRY_SUCCESS, payload: response });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
        dispatch(countryRequetFail(ex));
      });
  };
};

// GET A LIST OF ALL STATES IN A COUNTRY
const getAllStates = (code) => {
  return async (dispatch) => {
    dispatch({ type: ALL_STATES_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_CUSTOMER.getCountryStates(code)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: ALL_STATES_SUCCESS, payload: response });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
        dispatch(statesRequestFail(ex));
      });
  };
};

const getCustomerNotes = (id) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_CUSTOMER_NOTES_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_CUSTOMER.fetchCustomerNotes(id)
      .then((response) => {
        dispatch({ type: FETCH_CUSTOMER_NOTES_SUCCESS, payload: response });
      })
      .catch((ex) => {
        dispatch({ type: FETCH_CUSTOMER_NOTES_FAIL, payload: ex });
        handleError(ex, dispatch);
      })
      .finally(() => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const initiateCustomerNoteCreation = (id, payload, callback) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_CUSTOMER_NOTE_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_CUSTOMER.createCustomerNote(id, payload)
      .then((response) => {
        dispatch({ type: CREATE_CUSTOMER_NOTE_SUCCESS, payload: response });
        dispatch(Toast({ text: "Note created successfully", icon: "success" }));
        callback?.();
      })
      .catch((ex) => {
        dispatch({ type: CREATE_CUSTOMER_NOTE_FAIL, payload: ex });
        handleError(ex, dispatch);
      })
      .finally(() => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const modifyCustomerNote = (clientId, noteId, payload, callback) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_CUSTOMER_NOTE_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_CUSTOMER.updateCustomerNote(clientId, noteId, payload)
      .then((response) => {
        dispatch({ type: UPDATE_CUSTOMER_NOTE_SUCCESS, payload: response });
        dispatch(Toast({ text: "Note modified successfully", icon: "success" }));
        callback?.();
      })
      .catch((ex) => {
        dispatch({ type: UPDATE_CUSTOMER_NOTE_FAIL, payload: ex });
        handleError(ex, dispatch);
      })
      .finally(() => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

const removeCustomerNote = (clientId, noteId, callback) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_CUSTOMER_NOTE_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_CUSTOMER.deleteCustomerNote(clientId, noteId)
      .then((response) => {
        dispatch({ type: DELETE_CUSTOMER_NOTE_SUCCESS, payload: response });
        dispatch(Toast({ text: "Note deleted successfully", icon: "success" }));
        callback?.();
      })
      .catch((ex) => {
        dispatch({ type: DELETE_CUSTOMER_NOTE_FAIL, payload: ex });
        handleError(ex, dispatch);
      })
      .finally(() => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

export {
  getAllCustomers,
  getSingleCustomer,
  updateCustomerInfo,
  getCustomerAddress,
  resetSingleCustomer,
  createNewCustomer,
  resetNewCustomerState,
  updateCustomerImage,
  updateCustomerTier,
  getCustomerImage,
  uploadCustomerDocument,
  getCustomerDocuments,
  getCustomerDocsReset,
  deleteCustomerDocument,
  getCustomerCharges,
  getCustomerAccounts,
  resetCustomerImage,
  searchCustomer,
  filterCustomers,
  clearFilteredCustomers,
  makeDeposit,
  makeWithdrawal,
  getLoanDetails,
  approveLoan,
  disburseLoan,
  getloanDetaillsReset,
  createSavingsAccount,
  getSavingsAccount,
  updateSavingsAccountInfo,
  createLoanAccount,
  getAllCustomersPagination,
  activateCustomer,
  uploadCustomerImage,
  updateOnlyCustomerInfo,
  updateOnlyAddressInfo,
  downloadAccountStatement,
  downloadTransactionReceipt,
  downloadRepaymentSchedule,
  resetDownloadAccountStatementState,
  resetDownloadTransactionReceiptState,
  resetDownloadRepaymentScheduleState,
  getLoanLetters,
  getLoanOfferLetters,
  getLoanAccountStatement,
  getAllCountries,
  getAllStates,
  getCustomerNotes,
  initiateCustomerNoteCreation,
  modifyCustomerNote,
  removeCustomerNote,
};
