import Toast from "../util/Toast";
import {
  SET_LOADING_STATE,
  PROCESS_CHECKER,
  GET_LOANS_REQUEST,
  GET_LOANS_SUCCESS,
  GET_LOANS_FAIL,
  FILTER_LOANS_REQUEST,
  CLEAR_LOANS_FILTER,
  GET_CLIENTS_REQUEST,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAIL,
  FILTER_CLIENTS_REQUEST,
  CLEAR_CLIENTS_FILTER,
  GET_MAKER_CHECKER_REQUEST,
  GET_MAKER_CHECKER_SUCCESS,
  GET_MAKER_CHECKER_FAIL,
  GET_CHECKER_INBOX_DETAILS,
  GET_CHECKER_INBOX_DETAILS_SUCCESS,
  GET_CHECKER_INBOX_DETAILS_FAIL,
  FILTER_MAKER_REQUEST,
  CLEAR_MAKER_FILTER,
  GET_MAKER_CHECKER_TEMPLATE_REQUEST,
  GET_MAKER_CHECKER_TEMPLATE_SUCCESS,
  GET_MAKER_CHECKER_TEMPLATE_FAIL,
  GET_PENDING_RES_LOANS_REQUEST,
  GET_PENDING_RES_LOANS_SUCCESS,
  GET_PENDING_RES_LOANS_FAIL,
  CLEAR_RES_LOANS_FILTER,
  FILTER_RES_LOANS_REQUEST,
} from "../constants/businessConstants";
import {
  processChecker,
  getClients,
  getLoans,
  getMakerCheckers,
  getMakerCheckersSearchTemplate,
  pendingRescheduleLoans,
  processMakerChecker,
  getCheckerInboxDetails,
} from "../services/Api/business.api.services";

const handleError = (ex, dispatch) => {
  if (ex.response) {
    dispatch(
      Toast({
        text: ex.response.data.errors
          ? ex.response.data.errors[0].defaultUserMessage
          : ex.response.data.defaultUserMessage,
        icon: "error",
      })
    );
  } else if (ex.request) {
    dispatch(Toast({ text: String(ex), icon: "error" }));
  } else {
    dispatch(Toast({ text: String(ex), icon: "error" }));
  }

  dispatch({ type: SET_LOADING_STATE, payload: false });
};

const getLoansFail = (payload) => {
  return { type: GET_LOANS_FAIL, payload };
};
const getClientsFail = (payload) => {
  return { type: GET_CLIENTS_FAIL, payload };
};
const getMakerCheckerFail = (payload) => {
  return { type: GET_MAKER_CHECKER_FAIL, payload };
};
const getMakerCheckerTemplateFail = (payload) => {
  return { type: GET_MAKER_CHECKER_TEMPLATE_FAIL, payload };
};
const getPendingResLoansFail = (payload) => {
  return { type: GET_PENDING_RES_LOANS_FAIL, payload };
};

const getAllLoans = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: GET_LOANS_REQUEST });
    await getLoans()
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_LOANS_SUCCESS, payload: response.pageItems });
      })
      .catch((ex) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(getLoansFail(ex));
      });
  };
};

const getAllClients = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: GET_CLIENTS_REQUEST });
    await getClients()
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_CLIENTS_SUCCESS, payload: response.pageItems });
      })
      .catch((ex) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(getClientsFail(ex));
      });
  };
};

const filterLoans = (filterParam, type) => {
  return (dispatch) => {
    dispatch({ type: FILTER_LOANS_REQUEST, payload: { filterParam, type } });
  };
};
const filterClients = (filterParam) => {
  return (dispatch) => {
    dispatch({ type: FILTER_CLIENTS_REQUEST, payload: filterParam });
  };
};
const filterMakers = (filterParam) => {
  return (dispatch) => {
    dispatch({ type: FILTER_MAKER_REQUEST, payload: filterParam });
  };
};
const filterResLoans = (filterParam) => {
  return (dispatch) => {
    dispatch({ type: FILTER_RES_LOANS_REQUEST, payload: filterParam });
  };
};

const clearFilteredLoans = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_LOANS_FILTER });
  };
};

const clearFilteredClients = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_CLIENTS_FILTER });
  };
};

const clearFilteredMakers = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_MAKER_FILTER });
  };
};
const clearFilteredResLoans = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_RES_LOANS_FILTER });
  };
};

const getAllMakerCheckers = (
  actionName,
  makerDateTimeFrom,
  makerDateTimeto,
  userId
) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: GET_MAKER_CHECKER_REQUEST });
    await getMakerCheckers(
      actionName,
      makerDateTimeFrom,
      makerDateTimeto,
      userId
    )
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_MAKER_CHECKER_SUCCESS, payload: response });
      })
      .catch((ex) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(getMakerCheckerFail(ex));
      });
  };
};

const getCheckerDetails = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: GET_CHECKER_INBOX_DETAILS });
    await getCheckerInboxDetails(id)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({
          type: GET_CHECKER_INBOX_DETAILS_SUCCESS,
          payload: response,
        });
      })
      .catch((ex) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_CHECKER_INBOX_DETAILS_FAIL, payload: ex });
      });
  };
};

const getAllMakerCheckersTemplate = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: GET_MAKER_CHECKER_TEMPLATE_REQUEST });
    await getMakerCheckersSearchTemplate()
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({
          type: GET_MAKER_CHECKER_TEMPLATE_SUCCESS,
          payload: response,
        });
      })
      .catch((ex) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(getMakerCheckerTemplateFail(ex));
      });
  };
};

const getAllPendingRescheduleLoans = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: GET_PENDING_RES_LOANS_REQUEST });
    await pendingRescheduleLoans()
      .then((response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_PENDING_RES_LOANS_SUCCESS, payload: response });
      })
      .catch((ex) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(getPendingResLoansFail(ex));
      });
  };
};

const processAllChecker = (data, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await processChecker(data)
      .then((response) => {
        if (response) {
          dispatch({ type: PROCESS_CHECKER, payload: response });
          dispatch({ type: SET_LOADING_STATE, payload: false });
          dispatch(
            Toast({
              text: "Successful",
              icon: "success",
            })
          );
          history.push("/core/checkers");
        }
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const processAllMakerChecker = (id, command, data, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await processMakerChecker(id, command, data)
      .then((response) => {
        if (response) {
          dispatch({ type: PROCESS_CHECKER, payload: response });
          dispatch({ type: SET_LOADING_STATE, payload: false });
          dispatch(
            Toast({
              text: "Successful",
              icon: "success",
            })
          );
          history.push("/core/checkers");
        }
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

export {
  processAllChecker,
  getAllLoans,
  filterLoans,
  clearFilteredLoans,
  getAllClients,
  filterClients,
  clearFilteredClients,
  getAllMakerCheckers,
  getCheckerDetails,
  filterMakers,
  clearFilteredMakers,
  getAllMakerCheckersTemplate,
  getAllPendingRescheduleLoans,
  filterResLoans,
  clearFilteredResLoans,
  processAllMakerChecker,
};
