import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Input, FormControl } from "@chakra-ui/react";
import { FormClass, ActionButton, FormElements } from "../styles";
import { formatDate } from "../../../../../util/FormatDate";
import {
  approveFixedDepositAccount,
  getFixedDepositAccountDetails,
} from "../../../../../actions/fixedDeposit";
import { getSavingsAccountTemplate } from "../../../../../actions/SavingsAndLoanActions";

const ApproveModal = ({ onHide, history, id }) => {
  const dispatch = useDispatch();

  const [approveOn, setApproveOn] = useState(new Date());
  const [description, setDescription] = useState("");

  // const func2 = () => {
  //   dispatch(getFixedDepositAccountDetails(id));
  //   dispatch(getSavingsAccountTemplate(id));
  // }

  const handleApprove = () => {
    let dataToSend = {
      dateFormat: "dd MMM yyyy",
      approvedOnDate: formatDate(approveOn, true),
      locale: "en",
      note: description,
    };
    console.log("CALLED handleApprove");
    dispatch(
      approveFixedDepositAccount(id, dataToSend, history, onHide, "approve")
    );
    // console.log(id, 'idddd');
    // onHide();
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>Approve Fixed Deposit Account</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <FormElements style={{ width: "100%" }}>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Approve On*</label>
              <FormControl style={{ width: "70%" }}>
                <DatePicker
                  id="published-date"
                  selected={approveOn}
                  onChange={(date) => setApproveOn(date)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="dd/mmm/yyyy"
                />
              </FormControl>
            </div>

            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Note</label>
              <div style={{width: "70%"}}>
                <Input
                  value={description}
                  name="description"
                  style={{ width: "60%" }}
                  onChange={(e) => setDescription(e.target.value)}
                ></Input>
              </div>
            </div>
          </FormElements>
        </FormClass>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex mt-4">
          <ActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require("../../../../../assets/images/icons/cancel.svg").default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={(e) => {
              e.preventDefault();
              handleApprove();
            }}
          >
            <img
              src={
                require("../../../../../assets/images/icons/save.svg").default
              }
              alt="submit icon"
            />
            Submit
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default ApproveModal;
