import React, { useState } from "react";
import { InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import { Table } from "antd";

import { ContentWrapper } from "../styles";
import ModalContainer from "../../components/ModalContainer";

import FilterModal from "./components/FilterModal";

const SavingsAccount = () => {
  const [search, setSearch] = useState("");

  const columns = [
    { title: "", dataIndex: "picture", key: "picture" },
    { title: "Customer Name", dataIndex: "name", key: "name" },
    { title: "External ID", dataIndex: "externalId", key: "externalId" },
    { title: "Fixed Deposit Product", dataIndex: "product", key: "product" },
    {
      title: "Account Number",
      dataIndex: "accountNumber",
      key: "accountNumber",
    },
    { title: "Principal", dataIndex: "pricipal", key: "pricipal" },
    { title: "Interest Amount", dataIndex: "interest", key: "interest" },
  ];

  let singleData = {
    picture: (
      <>
        <div className="circle-green"></div>
        <img
          src={require("../../../../assets/images/defaultUser.png").default}
          alt=""
          className="customer-imgs mr-3"
        />
      </>
    ),
    name: "Adigo Jerome",
    externalId: "000000001",
    product: "WoodCore Fixed Deposit",
    accountNumber: "000000001",
    pricipal: "50,000",
    interest: "5,000",
  };

  let myData = [
    singleData,
    singleData,
    singleData,
    singleData,
    singleData,
    singleData,
    singleData,
    singleData,
    singleData,
    singleData,
  ];

  const [modalState, toggleModal] = useState(false);

  const handleOnHide = () => {
    toggleModal(false);
  };

  const [viewState, setViewState] = useState(false);

  return (
    <>
      <ModalContainer show={modalState}>
        {modalState && (
          <FilterModal onHide={handleOnHide} setViewState={setViewState} />
        )}
      </ModalContainer>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Fixed Deposit Accounts</h3>
            <p className="sub-title">
              A complete overview of all fixed deposit accounts
            </p>
          </div>
          <div className="col-sm-12 col-md-6 pb-3">
            <div className="d-flex justify-content-between">
              <div className="btn-white mr-4" onClick={() => toggleModal(true)}>
                Filter By
                <img
                  src={
                    require("../../../../assets/images/icons/btn-icon-down.svg")
                      .default
                  }
                  alt=""
                />
              </div>
              <InputGroup>
                <InputLeftElement
                  width="3.1rem"
                  height="50px"
                  pointerEvents="none"
                >
                  <img
                    src={
                      require("../../../../assets/images/icons/search.svg")
                        .default
                    }
                    style={{ height: "15px" }}
                    alt="Icon"
                  />
                </InputLeftElement>
                <Input
                  style={{
                    background: "#ffffff",
                    height: "45px",
                  }}
                  type="text"
                  placeholder="Search by name/account no./external ID"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </InputGroup>
            </div>
          </div>
        </div>
        {viewState ? <> </> : ""}
        <ContentWrapper>
          <Table columns={columns} dataSource={myData} />
        </ContentWrapper>
      </div>
    </>
  );
};

export default SavingsAccount;
