import React from "react";
// import { useSelector } from "react-redux";
import CurrencyFormat from "react-currency-format";
import { Select, Input, FormControl, Checkbox } from "@chakra-ui/react";

import { FormClass, FormElements, NextActionButton } from "../../../styles.jsx";

const SavingsSettings = ({
  handleNextScreen,
  handlePreviousScreen,
  minimumOpeningBalance,
  setMinimumOpeningBalance,
  nominalAnnualInterestRateOverdraft,
  lockInPeriod,
  lockInPeriodSelect,
  applyWithdrawalFee,
  minOverdraftForInterestCalculation,
  setMinOverdraftForInterestCalculation,
  minumumBalance,
  setMinumumBalance,
  enforceMinimumBalance,
  isOverdraft,
  overdraftLimit,
  setOverdraftLimit,
  minBalanceForInterestCalculation,
  setMinBalanceForInterestCalculation,
  isWitholdTax,
  taxGroupOptions,
  enableDomingTracking,
  enableWithdrawallimit,
  withdrawalLimit,
  withdrawalLimitPeriod,
  createSavingsProductHandler,
  setSettingsError,
  template,
  setNewSavingsProduct,
  daysToEscheat,
  setDaysToEscheat,
  daysToDormancy,
  setDaysToDormancy,
  daysToInactive,
  setDaysToInactive,
  renderTooltip,
}) => {
  return (
    <>
      {/* LEFT SIDE */}
      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Minimum Opening Balance*
                  {renderTooltip("Minimum Opening Balance")}
                </label>
                {/* <Input
                  style={{ width: "60%" }}
                  size="md"
                  type="number"
                  name="minimumOpeningBalance"
                  value={minimumOpeningBalance}
                  onChange={createSavingsProductHandler}
                /> */}
                <CurrencyFormat
                  value={minimumOpeningBalance}
                  name="minimumOpeningBalance"
                  thousandSeparator={true}
                  className="amount-input"
                  // placeholder="Minimum"
                  style={{
                    width: "60%",
                  }}
                  // prefix={"$"}
                  onValueChange={(values) => {
                    const { value } = values;
                    // formattedValue = $2,223
                    // value ie, 2223
                    setMinimumOpeningBalance(value);
                  }}
                />
              </div>
            </FormElements>
          </FormClass>
        </div>
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Lock-In Period
                  {renderTooltip("Lock-In Period")}
                </label>

                <Input
                  style={{ width: "60%" }}
                  size="md"
                  name="lockInPeriod"
                  type="number"
                  value={lockInPeriod}
                  onChange={createSavingsProductHandler}
                />
              </div>
            </FormElements>
          </FormClass>
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center"></label>
                <Select
                  size="md"
                  name="lockInPeriodSelect"
                  value={lockInPeriodSelect}
                  onChange={createSavingsProductHandler}
                >
                  <option value="">Select one</option>
                  {template !== undefined && template
                    ? template.lockinPeriodFrequencyTypeOptions &&
                      template.lockinPeriodFrequencyTypeOptions.map((cur) => (
                        <option value={cur.id} key={cur.code}>
                          {cur.value}
                        </option>
                      ))
                    : null}
                </Select>
              </div>
            </FormElements>
          </FormClass>
        </div>
      </div>

      <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
        <div className="col-sm-12 col-md-6 px-0 ">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Apply Withdrawal Fee For Transfer
                  {renderTooltip("Apply Withdrawal Fee For Transfer")}
                </label>
                <FormControl style={{ width: "60%" }}>
                  <Checkbox
                    size="md"
                    name="applyWithdrawalFee"
                    isChecked={applyWithdrawalFee}
                    onChange={(e) =>
                      setNewSavingsProduct((p) => ({
                        ...p,
                        applyWithdrawalFee: e.target.checked,
                      }))
                    }
                  />
                </FormControl>
              </div>
            </FormElements>
          </FormClass>
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Enforce Minimum Balance
                  {renderTooltip("Enforce Minimum Balance")}
                </label>
                <FormControl style={{ width: "60%" }}>
                  <Checkbox
                    size="md"
                    name="enforceMinimumBalance"
                    isChecked={enforceMinimumBalance}
                    onChange={(e) =>
                      setNewSavingsProduct((p) => ({
                        ...p,
                        enforceMinimumBalance: e.target.checked,
                      }))
                    }
                  />
                </FormControl>
              </div>
            </FormElements>
          </FormClass>
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Is Overdraft Allowed
                  {renderTooltip("Is Overdraft Allowed")}
                </label>
                <FormControl style={{ width: "60%" }}>
                  <Checkbox
                    size="md"
                    name="isOverdraft"
                    isChecked={isOverdraft}
                    onChange={(e) =>
                      setNewSavingsProduct((p) => ({
                        ...p,
                        isOverdraft: e.target.checked,
                      }))
                    }
                  />
                </FormControl>
              </div>
            </FormElements>
          </FormClass>

          {isOverdraft && (
            <>
              <FormClass>
                <FormElements>
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label d-flex flex-align-center">
                      Maximum Overdraft Amount Limit
                      {renderTooltip("Maximum Overdraft Amount Limit")}
                    </label>
                    {/* <Input
                      style={{ width: "60%" }}
                      size="md"
                      name="overdraftLimit"
                      type="number"
                      value={overdraftLimit}
                      onChange={createSavingsProductHandler}
                    /> */}
                    <CurrencyFormat
                      value={overdraftLimit}
                      name="overdraftLimit"
                      thousandSeparator={true}
                      className="amount-input"
                      // placeholder="Minimum"
                      style={{
                        width: "60%",
                      }}
                      // prefix={"$"}
                      onValueChange={(values) => {
                        const { value } = values;
                        // formattedValue = $2,223
                        // value ie, 2223
                        setOverdraftLimit(value);
                      }}
                    />
                  </div>
                </FormElements>
              </FormClass>
              <FormClass>
                <FormElements>
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label d-flex flex-align-center">
                      Min Overdraft Required For Interest Calculation
                      {renderTooltip(
                        "Min Overdraft Required For Interest Calculation"
                      )}
                    </label>
                    {/* <Input
                      style={{ width: "60%" }}
                      size="md"
                      name="minBalanceForInterestCalculation"
                      type="number"
                      value={minBalanceForInterestCalculation}
                      onChange={createSavingsProductHandler}
                    /> */}
                    <CurrencyFormat
                      value={minOverdraftForInterestCalculation}
                      name="minOverdraftForInterestCalculation"
                      thousandSeparator={true}
                      className="amount-input"
                      // placeholder="Minimum"
                      style={{
                        width: "60%",
                      }}
                      // prefix={"$"}
                      onValueChange={(values) => {
                        const { value } = values;
                        // formattedValue = $2,223
                        // value ie, 2223
                        setMinOverdraftForInterestCalculation(value);
                      }}
                    />
                  </div>
                </FormElements>
              </FormClass>
              <FormClass>
                <FormElements>
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label d-flex flex-align-center">
                      Nominal Annual Interest For Overdraft{" "}
                      {renderTooltip("Nominal Annual Interest")}
                    </label>
                    <Input
                      style={{ width: "60%" }}
                      size="md"
                      type="number"
                      name="nominalAnnualInterestRateOverdraft"
                      value={nominalAnnualInterestRateOverdraft}
                      onChange={createSavingsProductHandler}
                    />
                  </div>
                </FormElements>
              </FormClass>
            </>
          )}
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Is Withhold Tax Applicable
                  {renderTooltip("Is Withhold Tax Applicable")}
                </label>
                <FormControl style={{ width: "60%" }}>
                  <Checkbox
                    size="md"
                    name="isWitholdTax"
                    value={isWitholdTax}
                    onChange={(e) =>
                      setNewSavingsProduct((p) => ({
                        ...p,
                        isWitholdTax: e.target.checked,
                      }))
                    }
                  />
                </FormControl>
              </div>
            </FormElements>
          </FormClass>
          {isWitholdTax && (
            <FormClass>
              <FormElements>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label d-flex flex-align-center">
                    Tax Group
                    {renderTooltip("Tax Group")}
                  </label>
                  <Select
                    size="md"
                    name="taxGroupOptions"
                    value={taxGroupOptions}
                    onChange={createSavingsProductHandler}
                  >
                    <option value="">Select an option</option>
                    {template !== undefined && template
                      ? template.taxGroupOptions &&
                        template.taxGroupOptions.map((cur) => (
                          <option value={cur.id} key={cur.id}>
                            {cur.name}
                          </option>
                        ))
                      : null}
                  </Select>
                </div>
              </FormElements>
            </FormClass>
          )}
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Enable Dormancy Tracking
                  {renderTooltip("Enable Dormancy Tracking")}
                </label>
                <FormControl style={{ width: "60%" }}>
                  <Checkbox
                    size="md"
                    name="enableDomingTracking"
                    value={enableDomingTracking}
                    onChange={(e) =>
                      setNewSavingsProduct((p) => ({
                        ...p,
                        enableDomingTracking: e.target.checked,
                      }))
                    }
                  />
                </FormControl>
              </div>
              {enableDomingTracking ? (
                <>
                  {" "}
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label d-flex flex-align-center">
                      Number Of Days To Inactive Sub-Status
                      {renderTooltip("Number Of Days To Inactive Sub-Status")}
                    </label>

                    <Input
                      style={{ width: "60%" }}
                      size="md"
                      name="daysToInactive"
                      type="number"
                      value={daysToInactive}
                      onChange={(e) => setDaysToInactive(e.target.value)}
                    />
                  </div>{" "}
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label d-flex flex-align-center">
                      Number Of Days To Dormant Sub-Status
                      {renderTooltip("Number Of Days To Dormant Sub-Status")}
                    </label>

                    <Input
                      style={{ width: "60%" }}
                      size="md"
                      name="daysToInactive"
                      type="number"
                      value={daysToDormancy}
                      onChange={(e) => setDaysToDormancy(e.target.value)}
                    />
                  </div>{" "}
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label d-flex flex-align-center">
                      Number Of Days To Escheat
                      {renderTooltip("Number Of Days To Escheat")}
                    </label>

                    <Input
                      style={{ width: "60%" }}
                      size="md"
                      name="daysToInactive"
                      type="number"
                      value={daysToEscheat}
                      onChange={(e) => setDaysToEscheat(e.target.value)}
                    />
                  </div>
                </>
              ) : null}
            </FormElements>
          </FormClass>
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Enable Withdrawal Count For Interest Posting
                  {renderTooltip("Enable withdrawal limit on interst posting")}
                </label>
                <FormControl style={{ width: "60%" }}>
                  <Checkbox
                    size="md"
                    name="enableWithdrawallimit"
                    // value={enableWithdrawallimit}
                    isChecked={enableWithdrawallimit}
                    onChange={(e) =>
                      setNewSavingsProduct((p) => ({
                        ...p,
                        enableWithdrawallimit: e.target.checked,
                      }))
                    }
                  />
                </FormControl>
              </div>
            </FormElements>
          </FormClass>
          {enableWithdrawallimit && (
            <FormClass>
              <FormElements>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label d-flex flex-align-center">
                    Withdrawal Limit Number
                    {renderTooltip("Number of withdrawals")}
                  </label>
                  <Input
                    style={{ width: "60%" }}
                    size="md"
                    type="text"
                    name="withdrawalLimit"
                    value={withdrawalLimit}
                    onChange={createSavingsProductHandler}
                  />
                </div>
              </FormElements>
            </FormClass>
          )}
        </div>
        {/* RIGHT SIDE */}
        <div className="col-sm-12 col-md-6 px-0">
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Min Balance Defined For Interest Calculation
                  {renderTooltip(
                    "Min Balance Defined For Interest Calculation"
                  )}
                </label>
                <CurrencyFormat
                  value={minBalanceForInterestCalculation}
                  name="minBalanceForInterestCalculation"
                  thousandSeparator={true}
                  className="amount-input"
                  // placeholder="Minimum"
                  style={{
                    width: "60%",
                  }}
                  // prefix={"$"}
                  onValueChange={(values) => {
                    const { value } = values;
                    // formattedValue = $2,223
                    // value ie, 2223
                    setMinBalanceForInterestCalculation(value);
                  }}
                />
              </div>
            </FormElements>
          </FormClass>
          <FormClass>
            <FormElements>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label d-flex flex-align-center">
                  Minimum Balance
                  {renderTooltip("Minimum Balance")}
                </label>
                {/* <Input
                  style={{ width: "60%" }}
                  size="md"
                  type="number"
                  name="minumumBalance"
                  value={minumumBalance}
                  onChange={createSavingsProductHandler}
                /> */}
                <CurrencyFormat
                  value={minumumBalance}
                  name="minumumBalance"
                  thousandSeparator={true}
                  className="amount-input"
                  // placeholder="Minimum"
                  style={{
                    width: "60%",
                  }}
                  // prefix={"$"}
                  onValueChange={(values) => {
                    const { value } = values;
                    // formattedValue = $2,223
                    // value ie, 2223
                    setMinumumBalance(value);
                  }}
                />
              </div>
            </FormElements>
          </FormClass>
          {enableWithdrawallimit && (
            <FormClass className="d-none">
              <FormElements>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label d-flex flex-align-center">
                    Withdrawal Period{renderTooltip("Number of withdrawals")}
                  </label>
                  <Select
                    size="md"
                    name="withdrawalLimitPeriod"
                    value={withdrawalLimitPeriod}
                    onChange={createSavingsProductHandler}
                  >
                    <option value="">Select one</option>
                    {template !== undefined && template
                      ? template.lockinPeriodFrequencyTypeOptions &&
                        template.lockinPeriodFrequencyTypeOptions.map((cur) => (
                          <option value={cur.id} key={cur.code}>
                            {cur.value}
                          </option>
                        ))
                      : null}
                  </Select>
                </div>
              </FormElements>
            </FormClass>
          )}
        </div>
      </div>

      <div>
        <div className="float-right pt-5">
          <NextActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={handlePreviousScreen}
          >
            <img
              src={
                require("../../../../../../../../assets/images/icons/arrow-left.svg")
                  .default
              }
              alt="cancel icon"
            />
            Previous
          </NextActionButton>
          <NextActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={() => {
              minimumOpeningBalance
                ? setSettingsError(false)
                : setSettingsError(true);
              handleNextScreen();
            }}
          >
            <img
              src={
                require("../../../../../../../../assets/images/icons/arrow-right.svg")
                  .default
              }
              alt="submit icon"
            />
            Next
          </NextActionButton>
        </div>
      </div>
    </>
  );
};

export default SavingsSettings;
