import React, { useEffect, useState } from "react";
import { FormSubmit } from "./components/TransactionForm";
import { useHistory } from "react-router-dom";
import { ActionButton, ButtonGroup, FlexContainer } from "../styles";
import Statistics from "../components/Statistics";
import { useDispatch, useSelector } from "react-redux";
import { getAllCashierTransactions } from "../../../actions/tellerActions";

const Transaction = () => {
  const dispatch = useDispatch();

  const history = useHistory();
  const navigate = () => {
    history.push("/teller/transaction/history");
  };

  let currentCashierId = localStorage.getItem("cashierId");
  const dropdownValue = localStorage.getItem("myDefaultValue");

  const userDetails = useSelector((state) => state.userDetails);
  const {
    userDetails: {
      cashierData: { currencies = [], defaultCurrency = {} } = {},
    },
  } = userDetails || {};

  const [defaultCurrencyValue, setDefaultCurrencyValue] = useState("");

  useEffect(() => {
    if (dropdownValue) {
      // let oldCurrency = [];
      //  const oldCurrency = {...dropdownValue};
      //  const keyValue = oldCurrency[1];
      return;
    } else setDefaultCurrencyValue(defaultCurrency?.code);
  }, [dropdownValue, defaultCurrencyValue]);

  // useEffect(() => {
  //   if (userDetails && defaultCurrency) {
  //     // console.log("i entered")
  //     // console.log( defaultCurrency ,"defaultCurrency")
  //     // let oldCurrencies = [];
  //     // oldCurrencies = Object.values(defaultCurrency)
  //     // defaultCurrency?.map((k) => oldCurrencies?.push(k?.code));
  //     setDefaultCurrencyValue(defaultCurrency?.code);
  //   } else setDefaultCurrencyValue([]);
  // }, []);

  useEffect(() => {
    if (currentCashierId) {
      dispatch(
        getAllCashierTransactions(
          `?cashierId=${currentCashierId}&offset=0&limit=9`
        )
      );
    }
  }, [currentCashierId, dispatch]);

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-12 col-lg-6 pb-4 pl-4">
            <FlexContainer className="flex">
              <h3 className="page-title">Transaction</h3>
            </FlexContainer>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6">
            <ButtonGroup>
              <ActionButton onClick={navigate}>
                View all Transactions
              </ActionButton>
            </ButtonGroup>
          </div>
        </div>

        <div className="col-12">
          <Statistics defaultCurrencyValue={defaultCurrencyValue} />
          <FormSubmit defaultCurrencyValue={defaultCurrencyValue}/>
        </div>
      </div>
    </>
  );
};

export default Transaction;
