import React, { useState, useEffect} from "react";
import { useDispatch , useSelector} from "react-redux";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Input, FormControl } from "@chakra-ui/react";
import { FormClass, ActionButton, FormElements } from "../styles";
import { formatDate } from "../../../../../util/FormatDate";
import { approveFixedDepositAccount } from "../../../../../actions/fixedDeposit";
import { payFixedDepositAccountCharge } from "../../../../../actions/fixedDeposit";

const PayChargesModal = ({ id, newId ,onHide, history, amount, selectedTableIndex}) => {
  const dispatch = useDispatch();

  const [inputAmount, setInputAmount] = useState(amount);
  const [paymentDate, setPaymentDate] = useState(new Date());

  
  const handlePayment = async () => {
    let dataToSend = {
      dateFormat: "dd MMM yyyy",
      dueDate: formatDate(paymentDate, true),
      amount,
      locale: "en",
    };
    // console.log(dataToSend, 'dataToSend')
    const successFull = await dispatch(payFixedDepositAccountCharge(id, newId, dataToSend, selectedTableIndex, "paycharge"));
    // if(successFull) {
    //   // TODO: dispatch the modification here
    //   // dispatch();
    // }
    onHide(amount);
  };

//   {dueDate: "17 January 2022", amount: 1000, locale: "en", dateFormat: "dd MMMM yyyy"}
// amount: 1000
// dateFormat: "dd MMMM yyyy"
// dueDate: "17 January 2022"
// locale: "en"

  return (
    <>
      <Modal.Header>
        <Modal.Title>Pay Charges</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <FormElements style={{ width: "100%" }}>
          <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Amount*</label>
              <Input
                value={inputAmount}
                name="description"
                style={{ width: "70%" }}
                onChange={(e) => setInputAmount(e.target.value)}
              ></Input>
            </div>

            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Payment Date*</label>
              <FormControl style={{ width: "70%" }}>
                <DatePicker
                  id="published-date"
                  selected={paymentDate}
                  onChange={(date) => setPaymentDate(date)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="dd/mmm/yyyy"
                />
              </FormControl>
            </div>

           
          </FormElements>
        </FormClass>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex mt-4">
          <ActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require("../../../../../assets/images/icons/cancel.svg").default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={(e) => {
              e.preventDefault();
              handlePayment();
            }}
          >
            <img
              src={
                require("../../../../../assets/images/icons/save.svg").default
              }
              alt="submit icon"
            />
            Submit
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default PayChargesModal;
