export function truncateString(str, num) {
  if (str?.length <= num) {
    return str;
  }
  return str?.slice(0, num) + "...";
}


export const formatCamelCaseText = (text) => {
  let formatedText = text?.split(/(?=[A-Z])/).join(" ").toUpperCase()
  return formatedText;
};
