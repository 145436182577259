import React, { useEffect } from "react";
import { Modal, Table } from "react-bootstrap";
import { Table as MainTable } from "antd";
import { ActionButton } from "../../styles";
import { useDispatch, useSelector } from "react-redux";
import { getLedgerTransactions, getLedgerTransactionsForLoan } from "../../../../../../actions/fixedDeposit";
import { formatCurrency } from "../../../../../../util/FormatAmount";
import { useState } from "react";

const columns = [
    { title: "Type", dataIndex: "type", key: "type" },
    {
        title: "Ledger account",
        dataIndex: "ledgerAccount",
        key: "ledgerAccount",
    },
    {
        title: "Comment ",
        dataIndex: "comment",
        key: "comment",
    },
    { title: "Debit", dataIndex: "debit", key: "debit" },
    { title: "Credit", dataIndex: "credit", key: "credit" },

];

const LedgerDetailsModal = ({ onHide, transactions, module }) => {
    let ledgerTransactionData = [];
    const dispatch = useDispatch();
    const fixedDepositData = useSelector((state) => state.fixedDepositData);
    let { fixedDepositLedgerDetails = {} } = fixedDepositData || {};
    const [myData, setMyData] = useState([]);

    useEffect(() => {
        if(module === 'loan') {
            dispatch(getLedgerTransactionsForLoan(transactions?.id))
        } else {
            dispatch(getLedgerTransactions(transactions?.id))
        }
    }, [dispatch])

    useEffect(() => {
        if (!!fixedDepositLedgerDetails.length) {
            setMyData(fixedDepositLedgerDetails);
        } else {
            setMyData([])
        }
    }, [fixedDepositLedgerDetails]);


    myData.forEach((transaction) => {
        const {
            amount,
            branchName,
            createdDate,
            description,
            user,
            transactionDate,
            glDetailsData = {},

        } = transaction || {};

        ledgerTransactionData.push({
            amount: `${glDetailsData?.currencyCode} ${formatCurrency(amount)}`,
            office: branchName,
            transactionDate: transactionDate,
            createdBy: user,
            createdOn: createdDate,
            type: glDetailsData?.glCategory,
            comment: description,
            debit: glDetailsData?.journalEntryType === 'DEBIT' ? `${glDetailsData?.currencyCode} ${formatCurrency(Number(glDetailsData?.journalEntryAmount))}` : null,
            credit: glDetailsData?.journalEntryType === 'CREDIT' ? `${glDetailsData?.currencyCode} ${formatCurrency(Number(glDetailsData?.journalEntryAmount))}` : null,
            ledgerAccount: glDetailsData?.journalEntryType === 'DEBIT' ? glDetailsData?.debitGL : glDetailsData?.journalEntryType === 'CREDIT' ? glDetailsData?.creditGL : null
        })

    })
    return (
        <>
            <Modal.Header>
                <Modal.Title>Ledger Details</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
                <div className="full-width mt-2">
                    <div className="row">
                        <div className="col-xl-6 col-md-12">
                            <div className="m-2">
                                <Table
                                    responsive
                                    striped
                                    bordered
                                    hover
                                    className="table-form-style"
                                >

                                    <tbody>
                                        <tr>
                                            <td className="font-weight-bold md-font-weight">
                                                 Transaction Id
                                            </td>
                                            <td>
                                                <b>{transactions?.id}</b>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-bold md-font-weight">
                                                Office
                                            </td>
                                            <td>
                                                {ledgerTransactionData[0]?.office}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-bold md-font-weight">
                                                Created By
                                            </td>
                                            <td>
                                                {ledgerTransactionData[0]?.createdBy}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        {/* Performance History */}
                        <div className="col-xl-6 col-md-12">
                            <div className="m-2">
                                <Table
                                    responsive
                                    striped
                                    bordered
                                    hover
                                    className="table-form-style"
                                >
                                    <tbody>
                                        <tr>
                                            <td className="font-weight-bold md-font-weight">
                                                Transaction date
                                            </td>
                                            <td>
                                                {ledgerTransactionData[0]?.transactionDate}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-bold md-font-weight">
                                                Created on
                                            </td>
                                            <td>
                                                {ledgerTransactionData[0]?.createdOn}
                                            </td>
                                        </tr>

                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>

                    <div className="mt-3 px-3 full-width">
                        <MainTable
                            columns={columns}
                            rowClassName={'break-row-text'}
                            dataSource={ledgerTransactionData}
                            pagination={false}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="spaceBetweenFlexEnd mt-3">
                    <div className="">
                        <ActionButton color="#2C1DFF" onClick={() => {
                            setMyData([]);
                            onHide()
                        }}>
                            <img
                                src={
                                    require("../../../../../../assets/images/icons/cancel.svg")
                                        .default
                                }
                                alt="cancel icon"
                            />
                            Cancel
                        </ActionButton>
                    </div>
                </div>
            </Modal.Footer>
        </>
    );
};

export default LedgerDetailsModal;
