export const NIBSS_TENANTS_REQUEST = "NIBSS_TENANTS_REQUEST";
export const NIBSS_TENANTS_SUCCESS = "NIBSS_TENANTS_SUCCESS";
export const NIBSS_TENANTS_FAIL = "NIBSS_TENANTS_FAIL";

export const NIBSS_TENANTS_RESET = "NIBSS_TENANTS_RESET";

export const NIBSS_TENANTS_WALLET_REQUEST = "NIBSS_TENANTS_WALLET_REQUEST";
export const NIBSS_TENANTS_WALLET_SUCCESS = "NIBSS_TENANTS_WALLET_SUCCESS";
export const NIBSS_TENANTS_WALLET_FAIL = "NIBSS_TENANTS_WALLET_FAIL";
export const NIBSS_TENANTS_WALLET_RESET = "NIBSS_TENANTS_WALLET_RESET";

export const NIBSS_TENANTS_ACCOUNT_BALANCE_REQUEST =
  "NIBSS_TENANTS_ACCOUNT_BALANCE_REQUEST";
export const NIBSS_TENANTS_ACCOUNT_BALANCE_SUCCESS =
  "NIBSS_TENANTS_ACCOUNT_BALANCE_SUCCESS";
export const NIBSS_TENANTS_ACCOUNT_BALANCE_FAIL =
  "NIBSS_TENANTS_ACCOUNT_BALANCE_FAIL";
export const NIBSS_TENANTS_ACCOUNT_BALANCE_RESET =
  "NIBSS_TENANTS_ACCOUNT_BALANCE_RESET";

export const NIBSS_INFLOW_DATA_REQUEST = "NIBSS_INFLOW_DATA_REQUEST";
export const NIBSS_INFLOW_DATA_SUCCESS = "NIBSS_INFLOW_DATA_SUCCESS";
export const NIBSS_INFLOW_DATA_FAIL = "NIBSS_INFLOW_DATA_FAIL";
export const NIBSS_INFLOW_DATA_RESET = "NIBSS_INFLOW_DATA_RESET";

export const NIBSS_OUTFLOW_DATA_REQUEST = "NIBSS_OUTFLOW_DATA_REQUEST";
export const NIBSS_OUTFLOW_DATA_SUCCESS = "NIBSS_OUTFLOW_DATA_SUCCESS";
export const NIBSS_OUTFLOW_DATA_FAIL = "NIBSS_OUTFLOW_DATA_FAIL";
export const NIBSS_OUTFLOW_DATA_RESET = "NIBSS_OUTFLOW_DATA_RESET";

export const NIBSS_SINGLE_INFLOW_DATA_REQUEST =
  "NIBSS_SINGLE_INFLOW_DATA_REQUEST";
export const NIBSS_SINGLE_INFLOW_DATA_SUCCESS =
  "NIBSS_SINGLE_INFLOW_DATA_SUCCESS";
export const NIBSS_SINGLE_INFLOW_DATA_FAIL = "NIBSS_SINGLE_INFLOW_DATA_FAIL";
export const NIBSS_SINGLE_INFLOW_DATA_RESET = "NIBSS_SINGLE_INFLOW_DATA_RESET";

export const NIBSS_SINGLE_OUTFLOW_DATA_REQUEST =
  "NIBSS_SINGLE_OUTFLOW_DATA_REQUEST";
export const NIBSS_SINGLE_OUTFLOW_DATA_SUCCESS =
  "NIBSS_SINGLE_OUTFLOW_DATA_SUCCESS";
export const NIBSS_SINGLE_OUTFLOW_DATA_FAIL = "NIBSS_SINGLE_OUTFLOW_DATA_FAIL";
export const NIBSS_SINGLE_OUTFLOW_DATA_RESET =
  "NIBSS_SINGLE_OUTFLOW_DATA_RESET";

export const NIBSS_GET_BANK_REQUEST = "NIBSS_GET_BANK_REQUEST";
export const NIBSS_GET_BANK_SUCCESS = "NIBSS_GET_BANK_SUCCESS";
export const NIBSS_GET_BANK_FAIL = "NIBSS_GET_BANK_FAIL";
export const NIBSS_GET_BANK_RESET = "NIBSS_GET_BANK_RESET";

export const NIBSS_NAME_ENQUIRY_REQUEST = "NIBSS_NAME_ENQUIRY_REQUEST";
export const NIBSS_NAME_ENQUIRY_SUCCESS = "NIBSS_NAME_ENQUIRY_SUCCESS";
export const NIBSS_NAME_ENQUIRY_FAIL = "NIBSS_NAME_ENQUIRY_FAIL";
export const NIBSS_NAME_ENQUIRY_RESET = "NIBSS_NAME_ENQUIRY_RESET";

export const NIBSS_AUTHENTICATE_OTP_REQUEST = "NIBSS_AUTHENTICATE_OTP_REQUEST";
export const NIBSS_AUTHENTICATE_OTP_SUCCESS = "NIBSS_AUTHENTICATE_OTP_SUCCESS";
export const NIBSS_AUTHENTICATE_OTP_FAIL = "NIBSS_AUTHENTICATE_OTP_FAIL";
export const NIBSS_AUTHENTICATE_OTP_RESET = "NIBSS_AUTHENTICATE_OTP_RESET";

export const NIBSS_FUND_TRANSFER_REQUEST = "NIBSS_FUND_TRANSFER_REQUEST";
export const NIBSS_FUND_TRANSFER_SUCCESS = "NIBSS_FUND_TRANSFER_SUCCESS";
export const NIBSS_FUND_TRANSFER_FAIL = "NIBSS_FUND_TRANSFER_FAIL";
export const NIBSS_FUND_TRANSFER_RESET = "NIBSS_FUND_TRANSFER_RESET";

export const NIBSS_ALL_REPORT_REQUEST = "NIBSS_ALL_REPORT_REQUEST";
export const NIBSS_ALL_REPORT_SUCCESS = "NIBSS_ALL_REPORT_SUCCESS";
export const NIBSS_ALL_REPORT_FAIL = "NIBSS_ALL_REPORT_FAIL";
export const NIBSS_ALL_REPORT_RESET = "NIBSS_ALL_REPORT_RESET";

export const NIBSS_FILTER_REPORT_REQUEST = "NIBSS_FILTER_REPORT_REQUEST";
export const NIBSS_FILTER_REPORT_SUCCESS = "NIBSS_FILTER_REPORT_SUCCESS";
export const NIBSS_FILTER_REPORT_FAIL = "NIBSS_FILTER_REPORT_FAIL";
export const NIBSS_FILTER_REPORT_RESET = "NIBSS_FILTER_REPORT_RESET";

export const NIBSS_OUTFLOW_DATA_REQUEST_FILTER =
  "NIBSS_OUTFLOW_DATA_REQUEST_FILTER";
export const NIBSS_OUTFLOW_DATA_SUCCESS_FILTER =
  "  NIBSS_OUTFLOW_DATA_SUCCESS_FILTER";
export const NIBSS_OUTFLOW_DATA_FAIL_FILTER =
  "  NIBSS_OUTFLOW_DATA_FAIL_FILTER";
export const NIBSS_OUTFLOW_DATA_RESET_FILTER =
  "  NIBSS_OUTFLOW_DATA_RESET_FILTER";

export const NIBSS_INFLOW_DATA_REQUEST_FILTER =
  "NIBSS_INFLOW_DATA_REQUEST_FILTER";
export const NIBSS_INFLOW_DATA_SUCCESS_FILTER =
  "  NIBSS_INFLOW_DATA_SUCCESS_FILTER";
export const NIBSS_INFLOW_DATA_FAIL_FILTER = "  NIBSS_INFLOW_DATA_FAIL_FILTER";
export const NIBSS_INFLOW_DATA_RESET_FILTER =
  "  NIBSS_INFLOW_DATA_RESET_FILTER";


export const NIBSS_INFLOW_DATA_REQUEST_FILTER_DOWNLOAD =
"NIBSS_INFLOW_DATA_REQUEST_FILTER_DOWNLOAD";
export const NIBSS_INFLOW_DATA_SUCCESS_FILTER_DOWNLOAD =
"  NIBSS_INFLOW_DATA_SUCCESS_FILTER_DOWNLOAD";
export const NIBSS_INFLOW_DATA_FAIL_FILTER_DOWNLOAD = "  NIBSS_INFLOW_DATA_FAIL_FILTER_DOWNLOAD";
export const NIBSS_INFLOW_DATA_RESET_FILTER_DOWNLOAD =
"  NIBSS_INFLOW_DATA_RESET_FILTER_DOWNLOAD";