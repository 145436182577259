import React from "react";
import { Modal } from "react-bootstrap";
import { NewActionButton, FormClass, ModalText } from "../../../styles";

const AccountRuleDetailModal = ({ onHide, onDeleteAccountingRule }) => {
  return (
    <>
      <Modal.Header>
        <Modal.Title>Delete Rule</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn px-0 py-0">
        <FormClass style={{ width: "95%" }}>
          <ModalText>
            Are you sure you want to delete this accounting rule.
          </ModalText>
        </FormClass>
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right mt-1">
          <NewActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require("../../../../../../assets/images/icons/cancel.svg")
                  .default
              }
              alt="cancel icon"
            />
            Cancel
          </NewActionButton>
          <NewActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={onDeleteAccountingRule}
          >
            <img
              src={
                require("../../../../../../assets/images/icons/save.svg")
                  .default
              }
              alt="submit icon"
            />
            Submit
          </NewActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default AccountRuleDetailModal;
