import React, { useState, useEffect } from "react";
import {
  ContentWrapper,
  FormClass,
  ActionButton,
  FormElements,
  RoleContainer,
  IconContainer,
  NewActionButton,
  SecondButton
} from "./styles";
import CheckPermission from "../../../../../util/CheckPermission";
import { Select } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getWorkingDays,
  createWorkingDays,
} from "../../../../../actions/configurationActions";
import TableLoader from "./TableLoader";
import Toast from "../../../../../util/Toast";
import CheckBox from "./Checkbox.js"
import styled from 'styled-components'


const checkWorkingDays = (str = "") => {
  let days = [];
  if (str.includes("MO")) days.push("Monday");
  if (str.includes("TU")) days.push("Tuesday");
  if (str.includes("WE")) days.push("Wednesday");
  if (str.includes("TH")) days.push("Thursday");
  if (str.includes("FR")) days.push("Friday");
  if (str.includes("SA")) days.push("Saturday");
  if (str.includes("SU")) days.push("Sunday");

  return days;
};

const returnWeekDays = (arr = []) => {
  let newArr = arr?.map((item) => item.substring(0, 2).toUpperCase());
  let weekDays = "BYDAY=" + newArr.join();
  return weekDays;
};

const WorkingDaysView = ( {  label, value, onChange }) => {

  const dispatch = useDispatch();
  const [processing, setProcessing] = useState(false);
  const [bulkLoan, setBulkLoan] = useState({
    duePayment: "",
    extend: "",
  });
  const [selectedDays, setSelectedDays] = useState([]);
  const workingDaysChangeHandler = (e) =>
    setBulkLoan({ ...bulkLoan, [e.target.name]: e.target.value });

  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  let { duePayment, extend } = bulkLoan;
  const workingData = useSelector((state) => state.workingDaysReducer);
  const { loading, workingDays } = workingData;

  const [isChecked, setIsChecked] = useState(false);


  useEffect(() => {
    dispatch(getWorkingDays());
   const getDays = JSON.parse(localStorage.getItem('daysSelected'));
    setDays(getDays === null ? [] : getDays);
    getDays?.map((item) => {
     switch (item) {
       case 'monday': 
         setMonValue(true);
        break;
        case 'tuesday': 
         setTueValue(true);
        break;
        case 'wednesday': 
         setWedValue(true);
        break;
        case 'thursday': 
        setThuValue(true);
        break;
        case 'friday': 
         setFriValue(true);
        break;
        case 'saturday': 
         setSatValue(true);
        break;
        case 'sunday': 
         setSunValue(true);
        break;
     
       default:
         break;
     }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (workingDays) {
      setBulkLoan({
        extend: String(workingDays.extendTermForDailyRepayments),
        duePayment: workingDays.repaymentRescheduleType
          ? workingDays.repaymentRescheduleType.id
          : "",
      });
      setSelectedDays(checkWorkingDays(workingDays.recurrence || ""));
    }
  }, [workingDays]);

  const handleOnChange = () => {
    setIsChecked(!isChecked);
    
  };

  const handleSubmit = async () => {

    localStorage.setItem('daysSelected', JSON.stringify(days));

    let recur = "FREQ=WEEKLY;INTERVAL=1;" + returnWeekDays(days);
    if (days.length > 0) {
      if (extend && duePayment) {
        setProcessing(true);
        let data = {
          extendTermForDailyRepayments: extend,
          locale: "en",
          recurrence: recur,
          repaymentRescheduleType: duePayment,
        };
        await dispatch(createWorkingDays(data));
        setProcessing(false);
      }
    } else {
      Toast({
        text: "Please select at least a day",
        icon: "error",
        timer: 2000,
      });
    }
  };

  const [checkedTest, setCheckedTest] = useState(false);
 const handleCheckboxChange = event => {
    setCheckedTest({ checked: event.target.checked })
  }

  const [newSelectedDays, setNewSelectedDays] = useState([]);

  const [monValue, setMonValue] = useState(false);
  const [tueValue, setTueValue] = useState(false);
  const [wedValue, setWedValue] = useState(false);
  const [thuValue, setThuValue] = useState(false);
  const [friValue, setFriValue] = useState(false);
  const [satValue, setSatValue] = useState(false);
  const [sunValue, setSunValue] = useState(false);

    const [days, setDays] = useState([]);
    let daySelected = []

      const handleMonValue = (e) => {
        setMonValue(!monValue);
        setDays([...days, !monValue ? 'monday': undefined]);
      };
      const handleTueValue = (e) => {
        setTueValue(!tueValue);
        setDays([...days, !tueValue ? 'tuesday': undefined]);
      };
      const handleWedValue = (e) => {
        setWedValue(!wedValue);
        setDays([...days, !wedValue ? 'wednesday': undefined]);
      };
      const handleThuValue = (e) => {
        setThuValue(!thuValue);
        setDays([...days, !thuValue ? 'thursday': undefined]);
      };
      const handleFriValue = (e) => {
        setFriValue(!friValue);
        setDays([...days, !friValue ? 'friday': undefined]);
      };
      const handleSatValue = (e) => {
        setSatValue(!satValue);
        setDays([...days, !satValue ? 'saturday': undefined]);

      };
      const handleSunValue = (e) => {
        setSunValue(!sunValue);
        setDays([...days, !satValue ? 'saturday': undefined]);

      };

  

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Working Days</h3>
            <p className="sub-title">
              Define working days and configure behaviour of payments due to
              holidays
            </p>
            <p className="font-weight sub-title mt-2">
              <span className="text-info">
                {/* <Link to="/core/configuration">Configuration</Link> /{" "} */}
                <Link to="/core/configuration/organization">Utilities</Link>
              </span>{" "}
              / Working Days
            </p>
          </div>
        </div>
        {loading ? (
          <TableLoader />
        ) : (
          CheckPermission("READ_WORKINGDAYS", permissions) && (
            <ContentWrapper>
              <FormClass className="h-100 container">
                <FormElements>
           
                  <div
                    className="form-group row m-0"
                    style={{ paddingTop: "100px" }}
                  >
                    <div className="col-md-2 col-xs-12 col-sm-12">
                      <label className="text-muted mb-3">
                        Select Working Days
                      </label>
                    </div>
                    <div className="col-md-10 justify-content-sm-center col-sm-12 col-xs-12">
                      
                    <div style={{display: 'flex', gap: '25px'}}>
                    <CheckBox
                        onChange={(e) => handleMonValue(e)}
                        value="MO"
                        label="Monday"
                        checked={monValue}
                      />
                      <CheckBox
                        onChange={(e) => handleTueValue(e)}
                        value="TU"
                        label="Tuesday"
                        checked={tueValue}
                      />
                      <CheckBox
                        onChange={(e) => handleWedValue(e)}
                        value="WE"
                        label="Wednesday"
                        checked={wedValue}
                      />
                      <CheckBox
                        onChange={(e) => handleThuValue(e)}
                        value="TH"
                        label="Thursday"
                        checked={thuValue}
                      />
                      <CheckBox
                        onChange={(e) => handleFriValue(e)}
                        value="FR"
                        label="Friday"
                        checked={friValue}
                      /> 
                      </div>

                      <div className="ml-md-0"  style={{display: 'flex', gap: "20px" , marginTop: '20px'}}>
                      <CheckBox
                        onChange={(e) => handleSatValue(e)}
                        value="SA"
                        label="Saturday"
                        checked={satValue}
                      />
                      <CheckBox
                        onChange={(e) => handleSunValue(e)}
                        value="SU"
                        label="Sunday"
                        checked={sunValue}
                      />
                     
                      </div>


                      {/* <RoleContainer className="ml-md-3 mx-sm-2">
                        {selectedDays.map((item, key) => (
                          <ActionButton
                            textColor="#002C79"
                            key={key}
                            color="#e6f7ff"
                            className="form-control mb-2"
                          >
                            {item}
                            <IconContainer
                              onClick={() =>
                                setSelectedDays(
                                  selectedDays.filter((val) => val !== item)
                                )
                              }
                              className=""
                            >
                              <img
                                src={
                                  require("../../../../../assets/images/icons/cancel-black.svg")
                                    .default
                                }
                                className="ml-2"
                                alt="cancel"
                              />{" "}
                            </IconContainer>
                          </ActionButton>
                        ))}
                      </RoleContainer> */}
                    </div>
                  </div>

                  {/* Payments due on non working days */}
                  <div className="row h-100" style={{ marginTop: 50 }}>
                    <div className="col-sm-12 col-md-6">
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label">
                          Payments due on non working days
                        </label>
                        <Select
                          placeholder="Select Options"
                          size="md"
                          name="duePayment"
                          value={duePayment}
                          onChange={workingDaysChangeHandler}
                        >
                          {workingDays && workingDays.repaymentRescheduleOptions
                            ? workingDays.repaymentRescheduleOptions?.map(
                                (item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.value}
                                  </option>
                                )
                              )
                            : null}
                        </Select>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="text-label">
                          Extend the term for loans following a daily repayment
                          schedule
                        </label>
                        <Select
                          placeholder="Select on/off"
                          size="md"
                          name="extend"
                          value={extend}
                          onChange={workingDaysChangeHandler}
                        >
                          <option value="true">on</option>
                          <option value="false">off</option>
                        </Select>
                      </div>
                    </div>
                  </div>

                  <div className="float-right h-100 mt-5">
                    <Link to="/core/configuration/organization">
                      <ActionButton 
                      shadow className="mb-2" color="#2C1DFF">
                        <img
                          src={
                            require("../../../../../assets/images/icons/cancel.svg")
                              .default
                          }
                          alt="cancel"
                        />
                        Cancel
                      </ActionButton>
                    </Link>
                    {CheckPermission("UPDATE_WORKINGDAYS", permissions) && (
                      <ActionButton
                        onClick={handleSubmit}
                        className="mr-0"
                        shadow
                        color="#2C1DFF"
                      >
                        {processing ? (
                          <Spinner
                            animation="border"
                            color="#fff"
                            className="mr-2 text-white"
                            size="sm"
                          />
                        ) : (
                          <img
                            src={
                              require("../../../../../assets/images/icons/save.svg")
                                .default
                            }
                            alt="save"
                          />
                        )}
                        Submit
                      </ActionButton>
                    )}
                  </div>
                  <br />
                  <br />
                </FormElements>
              </FormClass>
            </ContentWrapper>
          )
        )}
      </div>
    </>
  );
};

export default WorkingDaysView;

const CheckboxWrapper = styled.div`
    border: 1px solid #2C1DFF;
    border-radius: 5px;
    padding: 4px;
    margin: 3px;
    background-color: ${(props) => props.isChecked}
    
    `
    // background: ${props => (props.isChecked ? '#E1E6FF' : '#FFFFFF')}