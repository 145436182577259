export const camelCaseToSentence = (text) => {
  var result = text.replace(/([A-Z])/g, " $1");
  var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

export const textToUpperCase = (text) => {
  return text.toUpperCase();
};

export const generateRamdomString = (length) => {
  var result = [];
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result.push(
      characters.charAt(Math.floor(Math.random() * charactersLength))
    );
  }
  return result.join("");
};

export const toTitleCase = (text) => {
  return text.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const replaceUnderscoreToText = (text) => {
  let niceText = text.replace(/_/g, " ");
  let mainText = capitalizeFirstLetter(niceText.toLowerCase());
  if (mainText.length >= 7) {
    return `${mainText.substring(0, 7)}...`;
  } else {
    return mainText;
  }
};
export const replaceUnderscoreToTextOnly = (text) => {
  let mainText = text.replace(/_/g, " ");

  return capitalizeFirstLetter(mainText.toLowerCase());
};

export const converStringToBoolean = (myValue) => {
  var isTrueSet = myValue === "true";
  return isTrueSet;
};
