import axiosData from '../../../services/axiosData';
import config from '../../../config';

let timeout;
let currentValue;

export function fetch(value, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;
  function getCustomers() {
    axiosData({
      method: 'GET',
      url: `${config.apiGateway.BASE_API_URL}/search?exactMatch=false&query=${value}&resource=clients`,
    }).then((response) => {
      if (currentValue === value) {
        const { data: rawData } = response;
        const filteredData = [];
        const allData = [];
        rawData?.pageItems?.forEach((r) => {
          allData?.push(r);
          filteredData?.push({
            value: r.entityId,
            text: `${r.entityName} (${r.entityAccountNo})`,
            type: r.entityType,
            clientName: r.entityName,
            clientCIFNumber: r.entityAccountNo,
          });
        });
        callback({ filteredData, allData });
      }
    });
  }
  timeout = setTimeout(getCustomers, 600);
}
