import styled from "styled-components";

export const ContentWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 0px #ededf6;
  min-height: 70vh;
  // margin-top: 20px;
  padding: 50px;

  p {
    font-size: 18px;
    font-weight: 400;
  }

  .pHeader {
    font-size: 24px;
    font-weight: 700;
  }

  .lineHeader {
    font-weight: 20px;
    font-weight: 600;
  }

  .button-right {
    width: 100%;
    height: 50px;
    padding-right: 15px;
  }
`;

export const FormClass = styled.div``;

export const CardDetails = styled.div`
  // margin-left: 130px;

  .dflex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  hr {
    width: 600px;
    height: 1px;
    color: #e1e1e4;
  }
`;

export const ActionButton = styled.button`
  background: ${(props) => props.color} !important;
  border-radius: 5px !important;
  color: #fff !important;
  margin: 0 3px;
  border: none;
  font-family: 'AventaMedium';
  display: inline;
  font-size: 14px;
  height: 35px;
  width: 180px;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  position: relative;
  cursor: pointer;
  img {
    height: 14px;
    margin-right: 10px;
    display: inline;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;

export const FormElements = styled.div`
  // width: 90%;
  margin-top: 30px;
  margin-bottom: 30px;
  display: block;
  .text-muted {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #969cba;
  }
  .css-42b2qy {
    width: 60%;
    color: #666666;
    & > option {
      padding: 0.5rem 0;
    }
  }
`;
