import React, { useEffect, useRef } from "react";
import { Card } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import {
  DownloadButton,
  FilterButton,
  ReportDownloadButton,
  TabContentWrapper,
} from "../styles";
import { useDispatch, useSelector } from "react-redux";
import {
  getFilteredNibssReports,
  getNibssReports,
  resetFilteredReports,
} from "../../../../actions/nibssConnectActions";
import CheckPermission from "../../../../util/CheckPermission";
import {
  formatDate,
  formatDateString,
  formatDateTimeNibss,
} from "../../../../util/FormatDate";
import { SET_LOADING_STATE } from "../../../../constants/utilityConstants";
import axiosData from "../../../../services/axiosData";
import { useState } from "react";
import FilterReportModal from "./FilterModal";
import Pagination from "./Pagination";
import ReactPaginate from "react-paginate";
import { ReportPagination } from "./ReportPagination";
import { Table } from "antd";
// import { Pagination, Table } from "antd";

function NibssReportView() {
  const dispatch = useDispatch();
  const ancRef = useRef();

  const [filterModal, setFilterModal] = useState("");

  // content is post
  const [currentPage, setCurrentPage] = useState(1);
  const [reportPerPage, setReportPerPage] = useState(10);

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  let history = useHistory();

  // nibbsGetAllReport
  const nibssAllReportsTemplate = useSelector(
    (state) => state.nibssGetAllReportTemplate
  );

  const nibssFilteredReport = useSelector(
    (state) => state.nibssGetFilteredReportTemplate
  );

  const {
    error,
    loading,
    nibssGetAllReportTemplate: { data = {} } = {} || {},
  } = nibssAllReportsTemplate || {};

  // Filtered Data
  // const { filteredLoading : loading, nibssFilteredReport: { filteredData : data = {} } = {} || {} } =
  // nibssFilteredReport || {};
  let filteredData = nibssFilteredReport?.nibssGetFilteredReportTemplate?.data;
  let ReportError = nibssFilteredReport?.error;


  const {
    content = [],
    pageable = {},
    sort = {},
    totalPages,
    totalElements,
  } = data || {};

  const { pageNumber, pageSize } = pageable;
  const [totalItems, setTotalItems] = useState(0);
  // pageItems === content

  const onHide = () => setFilterModal(false);

  const downloadReport = async (cId , cname) => {
    // dispatch({ type: SET_LOADING_STATE, payload: true });
    const url = `https://identity-service.test.woodcoreapp.com/api/v1/reports/${cId}/download`;
    try {
      const { data } = await axiosData({
        method: "GET",
        signal: false,
        url: url,
        isBearerToken: true,
        responseType: "blob",
      });
      if (data) {
        const dataUrl = URL.createObjectURL(data);
        ancRef.current.href = dataUrl;
        ancRef.current.download = `${cname} ` + Math.floor(Math.random() * (9000 - 1000) + 1000) + ".zip";
        setTimeout(() => {
          URL.revokeObjectURL(dataUrl);
        }, 150);
        ancRef.current.click();
      }
      // dispatch({ type: SET_LOADING_STATE, payload: false });
    } catch (ex) {}
  };

  // 0 10 2 12 ' pageNumber , pageSize , totalPages , totalElements'

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [paginationFilter, setPaginationFilter] = useState({
    current: 1,
    pageSize: 10,
  });
  const [activePagination, setActivePagination] = useState();
  const [activePaginationFilter, setActivePaginationFilter] = useState();
  const [myData, setMyData] = useState([]);

  useEffect(() => {
    dispatch(getNibssReports("pageNo=0&pageSize=10"));
  }, []);

  useEffect(() => {
    if (!!data && !!data?.length) {
      setMyData(data);
    } else if (!!content?.length) {
      setMyData(content);
    }
  }, [content, data]);

  // FILTER REPORT
  useEffect(() => {
    if (!!filteredData && !!filteredData?.length) {
      setMyData(filteredData);
    } else if (!!filteredData?.content?.length) {
      setMyData(filteredData?.content);
    }
  }, [filteredData?.content, filteredData]);

  useEffect(() => {
    if (totalElements !== 0 && totalElements !== 1) {
      setPagination({
        current: activePagination,
        pageSize: 10,
        total: totalElements,
      });
    } else if (totalItems === 0 && pageNumber === 0) {
      setPagination(false);
    } else if (!!data && !!data?.length) {
      setPagination({ pageSize: 10 });
    }
  }, [totalElements, pageNumber, activePagination, data, totalItems]);

  // FILTER REPORT
  useEffect(() => {
    if (
      filteredData?.totalElements !== 0 &&
      filteredData?.totalElements !== 1
    ) {
      setPaginationFilter({
        current: activePaginationFilter,
        pageSize,
        total: filteredData?.totalElements,
      });
    } else if (totalItems === 0 && filteredData?.pageNumber === 0) {
      setPaginationFilter(false);
    } else if (!!filteredData && !!filteredData?.length) {
      setPaginationFilter({ pageSize: 10 });
    }
  }, [
    filteredData?.totalElements,
    filteredData?.pageNumber,
    activePaginationFilter,
    filteredData,
    totalItems,
  ]);

  const truncateString = (str, num) => {
    if (str.length > num) {
      let subStr = str.substring(0, num);
      return subStr + "...";
    } else {
      return str;
    }
  };

  const handleTableChange = (pagination) => {
    setActivePagination(pagination.current);
    if (!!data && !!data?.length) {
      setPagination({
        current: activePagination,
        pageSize: 10,
        total: data?.length,
      });
    } else {
      let pageNo = pagination.current * 1 - 1;
      let dataToSend2 = `pageSize=10&pageNo=${pageNo}`;
      dispatch(getNibssReports(dataToSend2));
    }
  };

  // FILTER REPORT
  const handleFilterTableChange = (paginationFilter) => {
    setActivePaginationFilter(paginationFilter.current);
    if (!!filteredData && !!filteredData?.length) {
      setPaginationFilter({
        current: activePaginationFilter,
        pageSize: 10,
        total: filteredData?.length,
      });
    } else {
      let pageNo = paginationFilter.current * 1 - 1;
      let dataToSend2 = `pageSize=10&pageNo=${pageNo}`;
      dispatch(getNibssReports(dataToSend2));
    }
  };

  const dataSource = [];
  const filteredReport = [];

  !loading &&
    filteredData?.content?.forEach((c) => {
      const { id, createdAt, name } = c;
      filteredReport.push({
        key: id,
        createdAt: formatDateTimeNibss(createdAt),
        name: truncateString(name, 20),
        action: (
          <>
            <ReportDownloadButton onClick={() => downloadReport(c?.id)}>
              <img
                src={
                  require("../../../../assets/images/icons/downloadArrowIcon.svg")
                    .default
                }
                alt="right arrow"
              />
            </ReportDownloadButton>
          </>
        ),
      });
    });

  !loading &&
    content?.forEach((c) => {
      const { id, createdAt, name } = c;
      dataSource.push({
        key: id,
        createdAt: formatDateTimeNibss(createdAt),
        name: truncateString(name, 20),
        action: (
          <>
            <ReportDownloadButton onClick={() => downloadReport(c?.id , name)}>
              <img
                src={
                  require("../../../../assets/images/icons/downloadArrowIcon.svg")
                    .default
                }
                alt="right arrow"
              />
            </ReportDownloadButton>
          </>
        ),
      });
    });

  const columns = [
    { title: "Date / Time", dataIndex: "createdAt", key: "createdAt" },
    { title: "Report Name", dataIndex: "name", key: "name" },
    { title: "Action", dataIndex: "action", key: "action" },
  ].filter((item) => !item.hidden);

  const handleFilter = () => {
    if (fromDate && toDate) {
      let startDate = formatDateString(fromDate, "yyyy-MMMM-dd");
      let endDate = formatDateString(toDate, "yyyy-MMMM-dd");

      dispatch(getFilteredNibssReports(startDate, endDate, onHide ,setFromDate,setToDate));
    }
  };

  // clear Date input
  useEffect(() => {
    if (filteredData?.content) {
      setFromDate("");
      setToDate("");
    }
  }, [filteredData?.content]);

  useEffect(() => {
    dispatch(resetFilteredReports());
  }, []);

  // if(!loading && content?.length <= 0) return <div> <span>No Report Found</span></div>
  // if(!loading && filteredData?.content?.length <= 0) return <div> <span>No Report Found</span></div>

  return (
    <>
      <a style={{ display: "none" }} ref={ancRef}></a>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-4">
            <h3 className="page-title">Nibss report</h3>
            <p className="font-weight sub-title">
              View and download your NIBSS report <br />
              <span className="text-info">
                {/* <Link to="/core/configuration">Configuration</Link> /{" "} */}
                <Link to="/core/nibss-management">Nibss Integration</Link>
              </span>{" "}
              / Nibss Report
            </p>
          </div>
        </div>
        {/* {ReportError === null ? (
          <>
            {" "}
            <span>
              No Report Found
              </span>
          </>
        ) : ( */}
          <>
            <div>
              {/* // Filter Button */}
              <div className="button__filter">
                {CheckPermission("", permissions) && (
                  <FilterButton onClick={() => setFilterModal(true)}>
                    Filter
                    <img
                      src={
                        require("../../../../assets/images/icons/nibss-download.svg")
                          .default
                      }
                      alt="download"
                    />
                  </FilterButton>
                )}
                <FilterReportModal
                  filterModal={filterModal}
                  onHide={onHide}
                  fromDate={fromDate}
                  toDate={toDate}
                  setFromDate={setFromDate}
                  setToDate={setToDate}
                  handleFilter={handleFilter}
                />
              </div>
              <div className="col-md-12 justify-content-between align-items-center form-inner-container">
                {filteredData?.content && handleFilter ? (
                  <Table
                    style={{
                      overflow: "hidden",
                      overflowX: "scroll",
                    }}
                    className="tableNibssReport"
                    dataSource={filteredReport}
                    onChange={handleFilterTableChange}
                    columns={columns}
                    pagination={paginationFilter}
                    defaultPageSize={"10"}
                  />
                ) : (
                  <>
                    <Table
                      style={{
                        overflow: "hidden",
                        overflowX: "scroll",
                      }}
                      className="tableNibssReport"
                      dataSource={dataSource}
                      onChange={handleTableChange}
                      columns={columns}
                      pagination={pagination}
                      defaultPageSize={"10"}
                    />
                  </>
                )}
              </div>
            </div>
          </>
        {/* )} */}
      </div>
    </>
  );
}

export default NibssReportView;
