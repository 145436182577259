import styled from "styled-components";

export const ActionButtonsGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

export const ActionButton = styled.button`
  background: #2C1DFF !important;
  border-radius: 5px !important;
  color: #fff !important;
  border: none;
  display: inline;
  font-size: 14px;
  font-family: 'AventaMedium';
  height: 35px;
  margin: 0 10px;
  max-width: 180px;
  min-width: 150px;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  img {
    height: 14px;
    display: inline;
    margin-right: 10px;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;

export const IconButton = styled.button`
  background: ${(props) => props.color} !important;
  width: 49px;
  height: 49px;
  background: #2C1DFF;
  text-align: center;
  border-radius: 5px;
  img {
    height: 20px;
    display: inline;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;
export const ContentWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 0px #ededf6;
  min-height: 70vh;
  margin-top: 20px;
  padding-top: 50px;
  .currency-container {
    margin-bottom: 30px;
    display: flex;
  }
  input: {
    &:disabled {
      cursor: default !important;
    }
  }
  .active-input {
    box-shadow: 0px 2px 1px #dadce6 !important;
  }
`;

export const ModalText = styled.p`
  font-size: 18px;
  color: #000000;
  margin: 42px 0 32px 0;
`;

export const FormClass = styled.div``;
