import React from "react";
import { Modal, Table } from "react-bootstrap";
import { ActionButton } from "../styles";
import { formatDate } from "../../../../../util/FormatDate";

const AuditTrailsDetailsModal = ({ onHide, details }) => {
  let { audit = {} } = details;

  let {
    resourceId = "",
    processingResult = "",
    officeName = "",
    maker = "",
    madeOnDate = "",
    entityName = "",
    clientName = "",
    actionName = "",
  } = audit || {};

  return (
    <>
      <Modal.Header>
        <Modal.Title>Audit Trail Details</Modal.Title>
      </Modal.Header>
      <Modal.Body className="wc-modal">
        <div className="d-flex align-items-center justify-content-center flex-direction-coloumn full-width my-4">
          <Table responsive striped bordered hover>
            <thead className="border-0">
              <tr>
                <td className="font-weight-bold md-font-weight">Resource Id</td>
                <td>{resourceId}</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="font-weight-bold md-font-weight">Status</td>
                <td>{processingResult}</td>
              </tr>
              <tr>
                <td className="font-weight-bold md-font-weight">Made By</td>
                <td>{maker}</td>
              </tr>
              <tr>
                <td className="font-weight-bold md-font-weight">Office Name</td>
                <td>{officeName}</td>
              </tr>{" "}
              <tr>
                <td className="font-weight-bold md-font-weight">Entity Name</td>
                <td>{entityName}</td>
              </tr>{" "}
              <tr>
                <td className="font-weight-bold md-font-weight">Action Name</td>
                <td>{actionName}</td>
              </tr>
              <tr>
                <td className="font-weight-bold md-font-weight">
                  Transaction Date
                </td>
                <td>{formatDate(madeOnDate)}</td>
              </tr>
              <tr>
                <td className="font-weight-bold md-font-weight">Client Name</td>
                <td>{clientName}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="spaceBetweenFlexEnd mt-3">
          <div className="">
            <ActionButton color="#2C1DFF" onClick={onHide}>
              <img
                src={
                  require("../../../../../assets/images/icons/cancel.svg")
                    .default
                }
                alt="cancel icon"
              />
              Cancel
            </ActionButton>
          </div>
        </div>
      </Modal.Footer>
    </>
  );
};

export default AuditTrailsDetailsModal;
