import React from "react";
import { Table } from "antd";
import Loading from "./Loading";

const columns = [
  {
    title: "Financial Activity",
    dataIndex: "financialActivities",
    key: "financialActivities",
  },
  { title: "Account Name", dataIndex: "accountName", key: "accountName" },
  {
    title: "Account Number",
    dataIndex: "accountNumber",
    key: "accountNumber",
  },
];

const TableLoader = (props) => {
  const someChartsData = [
    {
      financialActivities: <Loading />,
      accountName: <Loading />,
      accountNumber: <Loading />,
    },
    {
      financialActivities: <Loading />,
      accountName: <Loading />,
      accountNumber: <Loading />,
    },
    {
      financialActivities: <Loading />,
      accountName: <Loading />,
      accountNumber: <Loading />,
    },
    {
      financialActivities: <Loading />,
      accountName: <Loading />,
      accountNumber: <Loading />,
    },
    {
      financialActivities: <Loading />,
      accountName: <Loading />,
      accountNumber: <Loading />,
    },
  ];
  return (
    <>
      <Table
        columns={columns}
        dataSource={someChartsData}
        pagination={{ defaultPageSize: 8 }}
        className="antdTable"
        defaultPageSize={8}
      />
    </>
  );
};

export default TableLoader;
