import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { Avatar } from "@chakra-ui/react";
import { getAllCashierTransactions } from "../../../actions/tellerActions";
import CustomItemLoader from "../../../components/Loader/CustomItemLoader";
import { formatCurrency } from "../../../util/FormatAmount";
import { truncateString } from "../../../util/FormatText";
import { useDispatch, useSelector } from "react-redux";
import { H3, Grid, ActionButton, GoBack } from "./styled";
import { ReactComponent as GoBackIcon } from "../../../assets/images/icons/back-left.svg";
import { Input, Textarea } from "@chakra-ui/react";
import ModalContainer from "../../../components/ModalContainer/index";
import { Modal } from "react-bootstrap";
import styled from "styled-components";

const Transactions = ({ history }) => {
  const [currentTableActive, setCurrentTableActive] = useState({});
  const [actionValue, setActionValue] = useState("");
  const [amount, setAmount] = useState("");
  const [modalState, toggleModal] = useState(false);
  const [rowDetailModal, toggleDetailsModal] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [activePagination, setActivePagination] = useState();
  const [myData, setMyData] = useState([]);
  const dispatch = useDispatch();

  const cashierData = useSelector((state) => state.cashierData);
  const { currencies = [], defaultCurrency = {} } =
    cashierData?.cashierDataDetails || {};

  const defaultCurrencyValue = defaultCurrency.code;

  let currentCashierId = localStorage.getItem("cashierId");
  const dropdownValue = localStorage.getItem("myDefaultValue");

  const [currencyValue, setCurrencyValue] = useState("");

  useEffect(() => {
    if (dropdownValue) {
      setCurrencyValue(dropdownValue);
    } else setCurrencyValue(defaultCurrencyValue);
  }, [dropdownValue, defaultCurrencyValue]);

  const handleCloseModal = () => {
    toggleModal(false);
    return setActionValue("");
  };

  const displayModalContent = () => {
    switch (actionValue) {
      case "approve":
        return (
          <Modals
            data={currentTableActive}
            status="Approve"
            handleCloseModal={handleCloseModal}
          />
        );
      case "reject":
        return (
          <Modals
            data={currentTableActive}
            status="Reject"
            handleCloseModal={handleCloseModal}
          />
        );
      default:
        break;
    }
  };

  const isLoading = useSelector((state) => state.isLoading.isLoading);

  const tellerGroupReducer = useSelector((state) => state.tellerReducer);

  const { cashierTransactions } = tellerGroupReducer;

  const {
    totalFilteredRecords,
    totalItems,
    numberOfPages,
    pageItems = [],
  } = cashierTransactions || {};

  const closeDetailsModal = () => {
    toggleDetailsModal(false);
  };

  useEffect(() => {
    if (currentCashierId) {
      dispatch(
        getAllCashierTransactions(
          `?cashierId=${currentCashierId}&offset=0&limit=10&sortOrder=DESC`
        )
      );
    }
  }, [currentCashierId, dispatch]);

  useEffect(() => {
    if (!!cashierTransactions && !!cashierTransactions.length) {
      setMyData(cashierTransactions);
    } else if (!!pageItems.length) {
      setMyData(pageItems);
    }
  }, [pageItems, cashierTransactions]);

  useEffect(() => {
    if (totalFilteredRecords !== 0 && totalFilteredRecords !== 1) {
      setPagination({
        current: activePagination,
        pageSize: 10,
        total: totalFilteredRecords,
      });
    } else if (totalItems === 0 && numberOfPages === 0) {
      setPagination(false);
    } else if (!!cashierTransactions && !!cashierTransactions.length) {
      setPagination({ defaultPageSize: 10 });
    }
  }, [
    totalItems,
    numberOfPages,
    totalFilteredRecords,
    activePagination,
    cashierTransactions,
  ]);

  const handleTableChange = (pagination, filters, sorter) => {
    setActivePagination(pagination.current);
    if (!!cashierTransactions && !!cashierTransactions.length) {
      setPagination({
        current: activePagination,
        pageSize: 10,
        total: cashierTransactions.length,
      });
    } else {
      let myOffsect = pagination.current * 10;
      let dataToSend2 = `?cashierId=${currentCashierId}&offset=${
        myOffsect - 10
      }&limit=10&sortOrder=DESC`;
      dispatch(getAllCashierTransactions(dataToSend2));
    }
  };

  const dataSource = [];

  // console.log(myData, "myData");

  !!myData &&
    myData?.forEach((cashierTransactionDataT) => {
      const { cashierTransactionData } = cashierTransactionDataT;
      cashierTransactionData?.forEach((transaction) => {
        const {
          cashierTransactionId,
          cashierActionId,
          createdDate,
          runningBalanceDerived,
          transactionDate,
          transactionType,
          transactionId,
          cashierLastName,
          transactionAmount,
          transactionNote,
          currencyData: { code },
          transactionType: { value },
          cashierFirstName,
        } = transaction;
        if (code === currencyValue) {
          dataSource.push({
            cashierTransactionId,
            cashierActionId,
            createdDate,
            runningBalanceDerived: `${code} ${formatCurrency(
              runningBalanceDerived
            )}`,
            transactionDate:
              transactionDate[2] +
              "/" +
              transactionDate[1] +
              "/" +
              transactionDate[0],
            transactionType,
            key: cashierActionId,
            transactionId: transactionId,
            tellerName: truncateString(
              `${cashierFirstName} ${cashierLastName}`
            ),
            type: `${value} CASH`,
            amount: `${code} ${formatCurrency(transactionAmount)}`,
            description: truncateString(
              transactionNote ? transactionNote : "",
              50
            ),
            avatar: <Avatar name={cashierFirstName + " " + cashierLastName} />,
          });
        }
      });
      // }
    });

  const cashierTransactionSorted = dataSource
    .sort((a, b) => {
      var c = new Date(a.transactionDate);
      var d = new Date(b.transactionDate);
      return c - d;
    })
    .reverse();

  const columns = [
    { title: "", dataIndex: "avatar", key: "avatar" },
    { title: "Teller Name", dataIndex: "tellerName", key: "tellerName" },
    {
      title: "Transaction ID",
      dataIndex: "cashierTransactionId",
      key: "cashierTransactionId",
    },
    { title: " Type", dataIndex: "type", key: "type" },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    // {
    //   title: "Date",
    //   dataIndex: "transactionDate",
    //   key: "transactionDate",
    // },
  ];

  return (
    <div className="container-fluid">
      <div className="spaceBetweenFlex pb-4">
        <div>
          <h3 className="page-title">Transaction History</h3>
          <GoBack to="/teller/transaction" className="ml-0">
            <GoBackIcon />
            <span className="ml-3">Back</span>
          </GoBack>
        </div>
      </div>
      <div>
        <ModalContainer
          size="md"
          show={modalState}
          dialogClassName="document_infoModal"
        >
          {modalState && displayModalContent()}
        </ModalContainer>
        {!!modalState ? (
          " "
        ) : (
          <ModalContainer
            size="md"
            show={rowDetailModal}
            dialogClassName="document_infoModal"
          >
            {rowDetailModal && (
              <DetailsModal
                closeDetailsModal={closeDetailsModal}
                data={currentTableActive}
              />
            )}
          </ModalContainer>
        )}

        <div>
          {isLoading ? (
            <CustomItemLoader />
          ) : (
            <>
              {
                <Table
                  onRow={(r) => ({
                    onClick: () => {
                      setAmount(r?.amount);
                      toggleDetailsModal(true);
                      return setCurrentTableActive(r);
                    },
                  })}
                  onChange={handleTableChange}
                  columns={columns}
                  dataSource={cashierTransactionSorted}
                  pagination={pagination}
                  defaultPageSize={"10"}
                />
              }
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Transactions;

const Modals = ({ data, handleCloseModal, status }) => {
  const { amount } = data;
  return (
    <div className="p-4 position-relative">
      <div>
        <Heading>{status}</Heading>
        <div>
          <div className="d-flex my-3 justify-content-between">
            <label style={{ minWidth: "30%" }} htmlFor="Amount">
              Amount
            </label>
            <Input readOnly value={amount} type="text" />
          </div>
          <div className="d-flex justify-content-between">
            <label style={{ minWidth: "30%" }} htmlFor="Description">
              Description
            </label>
            {status === "Approve" ? (
              <Textarea
                placeholder="Approval note"
                name="approvedNote"
              ></Textarea>
            ) : status === "Reject" ? (
              <Textarea
                placeholder="Rejection note"
                name="rejectedNote"
              ></Textarea>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="d-flex mt-3 justify-content-end">
          <ActionButton>Submit</ActionButton>
        </div>
      </div>
      <div className="detail-cancel" onClick={handleCloseModal}>
        <img
          src={require("../../../assets/images/icons/close.svg").default}
          className="detail-cancel-icon"
          alt="cancel icon"
        />
      </div>
    </div>
  );
};

const DetailsModal = ({ data, closeDetailsModal }) => {
  return (
    <>
      <Modal.Header>
        <Modal.Title>
          {data?.avatar} <span className="ml-3">{data?.tellerName}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="wc-modal">
        <div className="p-1 position-relative">
          <div className="d-flex mb-3 ">
            <TextQuestion>Type: </TextQuestion> <Heading>{data?.type}</Heading>
          </div>
          <div className="d-flex mb-3 ">
            <TextQuestion>Amount </TextQuestion> <Text>{data?.amount}</Text>
          </div>
          <div className="d-flex mb-3 ">
            <TextQuestion>Created date </TextQuestion>{" "}
            <Text>
              {data?.createdDate[2] +
                "/" +
                data?.createdDate[1] +
                "/" +
                data?.createdDate[0]}
            </Text>
          </div>
          <div className="d-flex mb-3 ">
            <TextQuestion>Transaction Id: </TextQuestion>{" "}
            <Text>{data?.cashierTransactionId}</Text>
          </div>
          <div className="d-flex mb-3 align-items-start">
            <TextQuestion>Action Id: </TextQuestion>{" "}
            <Text>{data?.cashierActionId}</Text>
          </div>
          <div className="d-flex mb-3">
            <TextQuestion>Balance derived: </TextQuestion>{" "}
            <Text>{data?.runningBalanceDerived}</Text>
          </div>
          <div className="d-flex mb-3">
            <TextQuestion>Transaction date: </TextQuestion>{" "}
            <Text>{data?.transactionDate}</Text>
          </div>

          <div className="d-flex mb-3">
            <TextQuestion>Description:</TextQuestion>
            <Text>{data?.description}</Text>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="float-right mt-1">
          <ActionButton color="#2C1DFF" onClick={closeDetailsModal}>
            <img
              src={require("../../../assets/images/icons/cancel.svg").default}
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

const TextQuestion = styled.p`
  width: 40%;
  font-size: 12px;
  font-family: "Helvetica Neue";
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: black;
  margin-right: 25px;
`;

const Text = styled.p`
  font-size: 12px;
  font-family: "Helvetica Neue";
  color: black;
  text-align: center;
`;

const Heading = styled.h4`
  font-size: 12px;
  font-family: "Helvetica Neue";
  display: flex;
  align-items: center;
  text-align: center;
  color: #002c79;
`;
