import React, { useState, useEffect } from "react";
import { ContentWrapper, ActionButtonsGroup, ActionButton } from "./styles";
import { Table } from "antd";
import { Link } from "react-router-dom";
import ModalContainer from "../../../components/ModalContainer";
import { useDispatch, useSelector } from "react-redux";
import { getAllSMTP } from "../../../../../actions/configurationActions";
import TableLoader from "./components/TableLoader";
import EditSMTP from "./components/EditSMTP";
const SMTPView = () => {
  const [modalState, toggleModal] = useState(false);

  const dispatch = useDispatch();

  const handleModalClose = () => toggleModal(false);

  const SMTPList = useSelector((state) => state.SMTPReducer);

  const { loading, SMTP } = SMTPList;
  SMTP.sort((a, b) => (a.name > b.name ? 1 : -1));

  useEffect(() => {
    dispatch(getAllSMTP());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Table configuration

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Value", dataIndex: "value", key: "value" },
    { title: "", dataIndex: "edit", key: "edit" },
  ];

  const dataSource = [];

  !loading &&
    SMTP.forEach((tax) => {
      const { id, name, value } = tax;
      dataSource.push({
        key: id,
        name: <span className="text-capitalize">{name}</span>,
        value: value ? "x".repeat(value.length) : "x",
      });
    });

  return (
    <>
      <ModalContainer show={modalState}>
        {modalState && <EditSMTP data={SMTP} onHide={handleModalClose} />}
      </ModalContainer>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Email Service Configuration</h3>
            <p className="sub-title">View External Service Configuration</p>
            <p className="font-weight sub-title mt-2">
              <span className="text-info">
                <Link to="/core/manage-utilities">Manage Utilities</Link> /{" "}
                <Link to="/core/manage-utilities/external-services">
                  External Services
                </Link>
              </span>{" "}
              / Email Service Configuration
            </p>
          </div>
          <div className="col-sm-12 col-md-6 px-0">
            <ActionButtonsGroup>
              <ActionButton
                style={{ width: 225 }}
                id="createOffice"
                onClick={() => toggleModal(true)}
              >
                <img
                  src={
                    require("../../../../../assets/images/icons/add.svg")
                      .default
                  }
                  alt="add"
                />
                Edit Configuration
              </ActionButton>
            </ActionButtonsGroup>
          </div>
        </div>
        <ContentWrapper>
          {loading ? (
            <TableLoader />
          ) : (
            <>
              {
                <Table
                  columns={columns}
                  dataSource={dataSource}
                  pagination={{ defaultPageSize: 8 }}
                  defaultPageSize={8}
                />
              }
            </>
          )}
        </ContentWrapper>
      </div>
    </>
  );
};

export default SMTPView;
