import React from "react";
import DatePicker from "react-datepicker";
import { FormControl, Select, Input, Checkbox } from "@chakra-ui/react";
import { Select as AntdSelect } from "antd";

import { FormElements } from "../styles";

const AccountInformation = ({
  createCustomerHandler,
  tierRank,
  externalId,
  clientTierOptions,
  isStaff,
  setIsStaff,
  submittedOn,
  setSubmittedOn,
  savingProductOptions,
  setOpenSavingsAccount,
  setActivatedOn,
  activatedOn,
  isAccountActive,
  setIsAccountActive,
  openSavingsAccount,
  savingsProductId,
  clientClassification,
  clientTypeOptions,
  setSavingsProductId,
}) => {
  let { Option } = AntdSelect;

  return (
    <>
      <div style={{ width: "60%" }}>
        <FormElements>
          {/* <div className="form-group d-flex justify-content-between align-items-center">
            <label className="text-label">BVN</label>
            <Input
              style={{ width: "60%" }}
              size="md"
              type="number"
              name="bvn"
              value={bvn}
              onChange={createCustomerHandler}
            />
          </div>
          <div className="form-group d-flex justify-content-between align-items-center">
            <label className="text-label">NIN</label>
            <Input
              style={{ width: "60%" }}
              size="md"
              type="text"
              name="nin"
              value={nin}
              onChange={createCustomerHandler}
            />
          </div>{" "} */}
          <div className="form-group d-flex justify-content-between align-items-center">
            <label className="text-label">External Id</label>
            <Input
              style={{ width: "60%" }}
              size="md"
              type="text"
              className="general-font-size"
              name="externalId"
              value={externalId}
              onChange={createCustomerHandler}
            />
          </div>
          <div className="form-group d-flex justify-content-between align-items-center">
            <label className="text-label">Tier Level</label>
            <Select
              placeholder="Choose Tier Level"
              size="md"
              name="tierRank"
              value={tierRank}
              onChange={createCustomerHandler}
            >
              {!!clientTierOptions?.length
                ? clientTierOptions.map(({ tierName, tierRank, id }) => {
                    return (
                      <option value={tierRank} id={id}>
                        {tierName} (Level: {tierRank})
                      </option>
                    );
                  })
                : null}
            </Select>
          </div>
          {/* <div className="form-group d-flex justify-content-between align-items-center">
            <label className="text-label">Client Classification</label>
            <Select
              placeholder="Select Client Classification"
              size="md"
              name="cliendClassificationId"
              value={cliendClassificationId}
              onChange={createCustomerHandler}
            >
              {!!clientClassification.length
                ? clientClassification.map(({ name, id }) => {
                    return (
                      <option value={id} id={id}>
                        {name}
                      </option>
                    );
                  })
                : null}
            </Select>
          </div> */}
          <div className="form-group d-flex justify-content-between align-items-center">
            <label className="text-label">Is Staff</label>
            <Checkbox
              size="lg"
              value={isStaff}
              // style={{borderColor:'#2C1DFF'}}
              onChange={(e) => setIsStaff(e.target.checked)}
            />
          </div>
          <div className="form-group d-flex justify-content-between align-items-center">
            <label className="text-label">Submitted On</label>
            <FormControl style={{ width: "60%" }}>
              <div className="datepicker-100-width">
                <DatePicker
                  id="published-date"
                  selected={submittedOn}
                  onChange={(date) => setSubmittedOn(date)}
                  peekNextMonth
                  showMonthDropdown
                  className="general-font-size"
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="dd/mmm/yyyy"
                />
              </div>
            </FormControl>
          </div>
          <div className="form-group d-flex justify-content-between align-items-center">
            <label className="text-label">Active</label>
            <Checkbox
              size="lg"
              onChange={(e) => setIsAccountActive(e.target.checked)}
            />
          </div>
          <div
            className="form-group justify-content-between align-items-center"
            style={{ display: isAccountActive ? "flex" : "none" }}
          >
            <label className="text-label">Activated On</label>
            <FormControl style={{ width: "60%" }}>
              <div className="datepicker-100-width">
                <DatePicker
                  id="published-date"
                  selected={activatedOn}
                  onChange={(date) => setActivatedOn(date)}
                  peekNextMonth
                  showMonthDropdown
                  className="general-font-size"
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="dd/mmm/yyyy"
                />
              </div>
            </FormControl>
          </div>
          {/* <div className="form-group d-flex justify-content-between align-items-center">
            <label className="text-label">Open Savings Account</label>
            <Checkbox
              size="lg"
              onChange={(e) => setOpenSavingsAccount(e.target.checked)}
            />
          </div> */}
          {openSavingsAccount ? (
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Savings Account</label>
              <AntdSelect
                showSearch
                placeholder="Select Savings Account"
                optionFilterProp="children"
                className="chakra-select__wrapper"
                value={savingsProductId}
                onChange={(value) => setSavingsProductId(value)}
                autoComplete="false"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {savingProductOptions?.length
                  ? savingProductOptions.map(({ id, name }) => {
                      return (
                        <Option value={id} key={id}>
                          {name}
                        </Option>
                      );
                    })
                  : ""}
              </AntdSelect>
            </div>
          ) : null}
        </FormElements>
      </div>
    </>
  );
};

export default AccountInformation;
