import React, { useState, useEffect } from "react";
import { Radio, RadioGroup, Stack } from "@chakra-ui/react";
// import { Select, Radio, RadioGroup, Stack } from "@chakra-ui/react";
import { Table } from "react-bootstrap";

import {
  FormClass,
  FormElements,
  NextActionButton,
  AddActionButton,
  FormTitle,
  IconButton,
} from "../../../styles.jsx";
import { useDispatch } from "react-redux";
import Toast from "../../../../../../../../util/Toast.js";
import { Select } from "antd";

const LoanCharges = ({
  handleNextScreen,
  handlePreviousScreen,
  radio,
  setRadio,
  template,
  updateLoanProductInfoHandler,
  fundSource,
  loanPortfolio,
  transferInSusp,
  incomeFromInterest,
  incomeFromFees,
  incomeFromPenalties,
  incomeFromRecovery,
  overPaymentLia,
  lossesWrittenOff,
  interestReceivable,
  feesReceivable,
  penaltiesReceivable,
  setChargesError,
  setFullCharges,
  setFullOverdueCharges,
  feeToIncomeAccountMappings,
  setFeeToIncomeAccountMappings,
  setPaymentChannelToFundSourceMappings,
  paymentChannelToFundSourceMappings,
  setPenaltyToIncomeAccountMappings,
  penaltyToIncomeAccountMappings,
  setValueOverdueCharges,
  setValueCharges,
  renderTooltip,
  valueCharges,
  valueOverdueCharges,
  principalOverdueAccountId,
  interestOverdueAccountId,
  interestInSuspenseAccountId,
  showAdvance,
  setShowAdvance,
  setFundSource,
  setLoanPortfolio,
  setTransferInSusp,
  setIncomeFromInterest,
  setIncomeFromFees,
  setIncomeFromPenalties,
  setIncomeFromRecovery,
  setOverPaymentLia,
  setLossesWrittenOff,
  setInterestReceivable,
  setFeesReceivable,
  setPenaltiesReceivable,
}) => {
  const dispatch = useDispatch();
  const defaultConfigFS = { paymentTypeId: "", fundSourceAccountId: "" };
  const defaultMapFeeToIncome = { chargeId: "", incomeAccountId: "" };
  const defaultMapPenalties = { chargeId: "", incomeAccountId: "" };

  const [selectCharges, setSelectCharges] = useState("");
  const [charges, setCharges] = useState([]);

  const [selectOverdueCharges, setSelectOverdueCharges] = useState("");
  const [overdueCharges, setOverdueCharges] = useState([]);
  //console.log(template, 'template.overdueCharges');
  useEffect(() => {
    // template.charges
    const tempCharges = [];
    const tempOverdueCharges = [];
    template.charges?.forEach((charge) => {
      if (charge.penalty) {
        tempOverdueCharges.push(charge);
      } else {
        tempCharges.push(charge);
      }
    });

    if (valueCharges && !!valueCharges.length) {
      setCharges(valueCharges);
    } else {
      setValueCharges(tempCharges);
      setCharges(tempCharges);
    }

    if (valueOverdueCharges && !!valueOverdueCharges.length) {
      setOverdueCharges(valueOverdueCharges);
    } else {
      setValueOverdueCharges(tempOverdueCharges);
      setOverdueCharges(tempOverdueCharges);
    }
  }, []);

  console.log(
    paymentChannelToFundSourceMappings,
    "paymentChannelToFundSourceMappings"
  );

  //configure fund sources for payment channels
  const [numberOfConfigFS, setNumberOfConfigFS] = useState(
    paymentChannelToFundSourceMappings
  );
  const addToConfigFSArray = () => {
    setNumberOfConfigFS([...numberOfConfigFS, defaultConfigFS]);
  };
  const removeFromConfigFSArray = (position) => {
    setNumberOfConfigFS(
      numberOfConfigFS.filter((item, index) => index !== position)
    );
  };
  const handleConfigFSPaymentIdToChange = (position, value) => {
    let newConfigFS = [...numberOfConfigFS];
    newConfigFS[Number(position)].paymentTypeId = value;
    setNumberOfConfigFS(newConfigFS);
  };
  const handleConfigFSSourceIdToChange = (position, value) => {
    let newConfigFS = [...numberOfConfigFS];
    newConfigFS[Number(position)].fundSourceAccountId = value;
    setNumberOfConfigFS(newConfigFS);
  };

  console.log(numberOfConfigFS, "numberOfConfigFS");
  // Map fees to income accounts
  const [numberOfMapFeeToIncome, setNumberOfMapFeeToIncome] = useState(
    feeToIncomeAccountMappings
  );
  const addToMapFeeToIncomeArray = () => {
    setNumberOfMapFeeToIncome([
      ...numberOfMapFeeToIncome,
      defaultMapFeeToIncome,
    ]);
  };
  const removeFromMapFeeToIncomeArray = (position) => {
    setNumberOfMapFeeToIncome(
      numberOfMapFeeToIncome.filter((item, index) => index !== position)
    );
  };
  const handleMapFeeToIncomePaymentIdToChange = (position, value) => {
    let newMapFeeToIncome = [...numberOfMapFeeToIncome];
    newMapFeeToIncome[Number(position)].chargeId = value;
    setNumberOfMapFeeToIncome(newMapFeeToIncome);
  };
  const handleMapFeeToIncomeSourceIdToChange = (position, value) => {
    let newMapFeeToIncome = [...numberOfMapFeeToIncome];
    newMapFeeToIncome[Number(position)].incomeAccountId = value;
    setNumberOfMapFeeToIncome(newMapFeeToIncome);
  };

  // Map fees to income accounts
  const [numberOfMapPenalties, setNumberOfMapPenalties] = useState(
    penaltyToIncomeAccountMappings
  );
  const addToMapPenaltiesArray = () => {
    setNumberOfMapPenalties([...numberOfMapPenalties, defaultMapPenalties]);
  };
  const removeFromMapPenaltiesArray = (position) => {
    setNumberOfMapPenalties(
      numberOfMapPenalties.filter((item, index) => index !== position)
    );
  };
  const handleMapPenaltiesPaymentIdToChange = (position, value) => {
    let newMapPenalties = [...numberOfMapPenalties];
    newMapPenalties[Number(position)].chargeId = value;
    setNumberOfMapPenalties(newMapPenalties);
  };
  const handleMapPenaltiesSourceIdToChange = (position, value) => {
    let newMapPenalties = [...numberOfMapPenalties];
    newMapPenalties[Number(position)].incomeAccountId = value;
    setNumberOfMapPenalties(newMapPenalties);
  };

  const handleAddCharge = () => {
    if (selectCharges) {
      let newCharge = template.chargeOptions.filter(
        (item) => item.id === Number(selectCharges)
      )[0];
      let check = charges.filter((item) => item === newCharge);
      if (check.length === 0) setCharges((p) => [...p, newCharge]);
      else {
        dispatch(
          Toast({
            text: "Already added!",
            icon: "error",
            timer: 2000,
          })
        );
      }
    } else {
      dispatch(
        Toast({
          text: "Please select a charge",
          icon: "error",
          timer: 2000,
        })
      );
    }
  };
  const handleChargeDelete = (id) => {
    setCharges(charges.filter((item) => item.id !== id));
  };

  // console.log(numberOfMapFeeToIncome, "numberOfMapFeeToIncome");
  // console.log(template.chargeOptions, "template.chargeOptions");
  const handleAddOverdueCharge = () => {
    if (selectOverdueCharges) {
      let newCharge = template.penaltyOptions.filter(
        (item) => item.id === Number(selectOverdueCharges)
      )[0];
      let check = overdueCharges.filter((item) => item === newCharge);
      if (check.length === 0) {
        setOverdueCharges((p) => [...p, newCharge]);
      } else {
        dispatch(
          Toast({
            text: "Already added!",
            icon: "error",
            timer: 2000,
          })
        );
      }
    } else {
      dispatch(
        Toast({
          text: "Please select a charge",
          icon: "error",
          timer: 2000,
        })
      );
    }
  };
  const handleOverdueChargeDelete = (id) => {
    setOverdueCharges(overdueCharges.filter((item) => item.id !== id));
  };
  return (
    <>
      <FormClass>
        <FormElements style={{ width: "60%", margin: "0 auto" }}>
          <div className="form-group d-flex justify-content-between align-items-center">
            {/* <label className="text-label">Product</label> */}
            <Select
              name="selectCharges"
              value={selectCharges}
              onChange={(value) => setSelectCharges(value)}
              size="md"
              placeholder="Select one"
            >
              {/* <option>Select charge</option> */}
              {template !== undefined && template
                ? template.chargeOptions &&
                  template.chargeOptions.map((cur) => (
                    <Select.Option value={cur.id} key={cur.id}>
                      {cur.name}
                    </Select.Option>
                  ))
                : null}
            </Select>
            <NextActionButton
              color="#2C1DFF"
              style={{ margin: "0px 0px 0 10px" }}
              onClick={handleAddCharge}
            >
              <img
                src={
                  require("../../../../../../../../assets/images/icons/add.svg")
                    .default
                }
                alt="forward icon"
              />
              Add Charge
            </NextActionButton>
          </div>
        </FormElements>
        <Table className="child-table">
          <thead className="">
            <tr style={{ backgroundColor: "#FAFAFA" }}>
              <th>Name</th>
              <th>Type</th>
              <th>Amount</th>
              <th>Collected on</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {charges.length > 0
              ? charges.map(
                  ({
                    id,
                    name,
                    chargeCalculationType,
                    amount,
                    chargeTimeType,
                  }) => (
                    <tr key={id}>
                      <td>{name}</td>
                      <td>{chargeCalculationType.value}</td>
                      <td>{amount}</td>
                      <td>{chargeTimeType.value}</td>
                      <td>
                        <IconButton
                          onClick={() => handleChargeDelete(id)}
                          id="deleteHoliday"
                          className="mr-1 mb-1"
                        >
                          <img
                            src={
                              require("../../../../../../../../assets/images/icons/delete.svg")
                                .default
                            }
                            alt="add"
                            className="icon-img"
                          />
                        </IconButton>
                      </td>
                    </tr>
                  )
                )
              : null}
          </tbody>
        </Table>

        <FormTitle style={{ width: "100%", margin: "4rem auto" }}>
          <p className="text-info">Overdue charges</p>
          <div className="lineThrough"></div>
        </FormTitle>

        <FormElements style={{ width: "60%", margin: "0 auto" }}>
          <div className="form-group d-flex justify-content-between align-items-center">
            {/* <label className="text-label">Product</label> */}
            <Select
              value={selectOverdueCharges}
              onChange={(value) => setSelectOverdueCharges(value)}
              size="md"
              placeholder="Select one"
            >
              <option>Select overdue charges</option>
              {template !== undefined && template
                ? template.penaltyOptions &&
                  template.penaltyOptions.map((cur) => (
                    <Select.Option value={cur.id} key={cur.id}>
                      {cur.name}
                    </Select.Option>
                  ))
                : null}
            </Select>
            <NextActionButton
              color="#2C1DFF"
              style={{ margin: "0px 0px 0 10px" }}
              onClick={handleAddOverdueCharge}
            >
              <img
                src={
                  require("../../../../../../../../assets/images/icons/add.svg")
                    .default
                }
                alt="forward icon"
              />
              Add Charge
            </NextActionButton>
          </div>
        </FormElements>
        <Table className="child-table">
          <thead className="">
            <tr style={{ backgroundColor: "#FAFAFA" }}>
              <th>Name</th>
              <th>Type</th>
              <th>Amount</th>
              <th>Collected on</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {overdueCharges.length > 0
              ? overdueCharges.map(
                  ({
                    id,
                    name,
                    chargeCalculationType,
                    amount,
                    chargeTimeType,
                  }) => (
                    <tr key={id}>
                      <td>{name}</td>
                      <td>{chargeCalculationType.value}</td>
                      <td>{amount}</td>
                      <td>{chargeTimeType.value}</td>
                      <td>
                        <IconButton
                          onClick={() => handleOverdueChargeDelete(id)}
                          id="deleteHoliday"
                          className="mr-1 mb-1"
                        >
                          <img
                            src={
                              require("../../../../../../../../assets/images/icons/delete.svg")
                                .default
                            }
                            alt="add"
                            className="icon-img"
                          />
                        </IconButton>
                      </td>
                    </tr>
                  )
                )
              : null}
          </tbody>
        </Table>

        <FormTitle style={{ width: "100%", margin: "4rem auto" }}>
          <p className="text-info">Accounting type</p>
          <div className="lineThrough"></div>
        </FormTitle>
        <div style={{ width: "60%", margin: "0 auto" }} className="row">
          <RadioGroup onChange={(e) => setRadio(e)} defaultValue={radio}>
            <Stack
              spacing={5}
              direction="row"
              style={{ justifyContent: "center" }}
            >
              <Radio colorScheme="blue" value="1">
                <span className="text-label">None</span>
              </Radio>
              <Radio colorScheme="blue" value="2">
                <span className="text-label">Cash</span>
              </Radio>
              <Radio colorScheme="blue" value="3">
                <span className="text-label">Accural (Periodic)</span>
              </Radio>
              <Radio colorScheme="blue" value="4">
                <span className="text-label">Accural (Upfront)</span>
              </Radio>
            </Stack>
          </RadioGroup>
        </div>

        {radio !== "1" ? (
          <div>
            <FormTitle style={{ width: "60%", margin: "4rem auto" }}>
              <p className="text-info">Assets</p>
              <div className="lineThrough"></div>
            </FormTitle>

            <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
              <div className="col-sm-12 col-md-6 px-0 ">
                <FormClass>
                  <FormElements>
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-label d-flex flex-align-center">
                        Fund Source {renderTooltip("Fund Source")}
                      </label>
                      <Select
                        size="md"
                        name="fundSource"
                        value={fundSource}
                        // onChange={updateLoanProductInfoHandler}
                        onChange={(value) => setFundSource(value)}
                        placeholder="Select an option"
                        showSearch // Enable search functionality
                        optionFilterProp="children" // Specify which property of the option is used for filtering
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {/* <option value="">Select an option</option> */}
                        {template !== undefined && template
                          ? template.accountingMappingOptions &&
                            template.accountingMappingOptions
                              .assetAccountOptions &&
                            template.accountingMappingOptions.assetAccountOptions.map(
                              (item) => (
                                <Select.Option value={item.id} key={item.id}>
                                  {item.name}
                                </Select.Option>
                              )
                            )
                          : null}
                      </Select>
                    </div>
                  </FormElements>
                </FormClass>
              </div>
              <div className="col-sm-12 col-md-6 px-0">
                <FormClass>
                  <FormElements>
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-label d-flex flex-align-center">
                        Loan Portfolio {renderTooltip("Loan Portfolio")}
                      </label>
                      <Select
                        size="md"
                        name="loanPortfolio"
                        value={loanPortfolio}
                        // onChange={updateLoanProductInfoHandler}
                        onChange={(value) => setLoanPortfolio(value)}
                        placeholder="Select an option"
                        showSearch // Enable search functionality
                        optionFilterProp="children" // Specify which property of the option is used for filtering
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {/* <option value="">Select an option</option> */}
                        {template !== undefined && template
                          ? template.accountingMappingOptions &&
                            template.accountingMappingOptions
                              .assetAccountOptions &&
                            template.accountingMappingOptions.assetAccountOptions.map(
                              (item) => (
                                <Select.Option value={item.id} key={item.id}>
                                  {item.name}
                                </Select.Option>
                              )
                            )
                          : null}
                      </Select>
                    </div>
                  </FormElements>
                </FormClass>
              </div>

              {(radio === "3" || radio === "4") && (
                <div className="d-flex justify-content-between flex-wrap">
                  <div className="col-sm-12 col-md-6 px-0 ">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label d-flex flex-align-center">
                            Interest Receivable{" "}
                            {renderTooltip("Interest Receivable")}
                          </label>
                          <Select
                            size="md"
                            name="interestReceivable"
                            value={interestReceivable}
                            // onChange={updateLoanProductInfoHandler}
                            onChange={(value) => setInterestReceivable(value)}
                            placeholder="Select an option"
                            showSearch // Enable search functionality
                            optionFilterProp="children" // Specify which property of the option is used for filtering
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {/* <option value="">Select an option</option> */}
                            {template !== undefined && template
                              ? template.accountingMappingOptions &&
                                template.accountingMappingOptions
                                  .assetAccountOptions &&
                                template.accountingMappingOptions.assetAccountOptions.map(
                                  (item) => (
                                    <Select.Option
                                      value={item.id}
                                      key={item.id}
                                    >
                                      {item.name}
                                    </Select.Option>
                                  )
                                )
                              : null}
                          </Select>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                  <div className="col-sm-12 col-md-6 px-0">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label d-flex flex-align-center">
                            Fees Receivable
                            {renderTooltip("Fees Receivable")}
                          </label>
                          <Select
                            size="md"
                            name="feesReceivable"
                            value={feesReceivable}
                            // onChange={updateLoanProductInfoHandler}
                            onChange={(value) => setFeesReceivable(value)}
                            placeholder="Select an option"
                            showSearch // Enable search functionality
                            optionFilterProp="children" // Specify which property of the option is used for filtering
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {/* <option value="">Select an option</option> */}
                            {template !== undefined && template
                              ? template.accountingMappingOptions &&
                                template.accountingMappingOptions
                                  .assetAccountOptions &&
                                template.accountingMappingOptions.assetAccountOptions.map(
                                  (item) => (
                                    <Select.Option
                                      value={item.id}
                                      key={item.id}
                                    >
                                      {item.name}
                                    </Select.Option>
                                  )
                                )
                              : null}
                          </Select>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                  {/* <div className="col-sm-12 col-md-6 px-0">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label d-flex flex-align-center">
                            Principal Overdue
                            {renderTooltip("Principal Overdue")}
                          </label>
                          <Select
                            size="md"
                            name="principalOverdueAccountId"
                            value={principalOverdueAccountId}
                            onChange={updateLoanProductInfoHandler}
                          >
                            <option value="">Select an option</option>
                            {template !== undefined && template
                              ? template.accountingMappingOptions &&
                                template.accountingMappingOptions
                                  .assetAccountOptions &&
                                template.accountingMappingOptions.assetAccountOptions.map(
                                  (item) => (
                                    <option value={item.id} key={item.id}>
                                      {item.name}
                                    </option>
                                  )
                                )
                              : null}
                          </Select>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                  <div className="col-sm-12 col-md-6 px-0">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label d-flex flex-align-center">
                            Interest Overdue
                            {renderTooltip("Interest Overdue")}
                          </label>
                          <Select
                            size="md"
                            name="interestOverdueAccountId"
                            value={interestOverdueAccountId}
                            onChange={updateLoanProductInfoHandler}
                          >
                            <option value="">Select an option</option>
                            {template !== undefined && template
                              ? template.accountingMappingOptions &&
                                template.accountingMappingOptions
                                  .assetAccountOptions &&
                                template.accountingMappingOptions.assetAccountOptions.map(
                                  (item) => (
                                    <option value={item.id} key={item.id}>
                                      {item.name}
                                    </option>
                                  )
                                )
                              : null}
                          </Select>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div> */}
                  <div className="col-sm-12 col-md-6 px-0">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label className="text-label d-flex flex-align-center">
                            Penalties Receivable
                            {renderTooltip("Penalties Receivable")}
                          </label>
                          <Select
                            size="md"
                            name="penaltiesReceivable"
                            value={penaltiesReceivable}
                            // onChange={updateLoanProductInfoHandler}
                            onChange={(value) => setPenaltiesReceivable(value)}
                            placeholder="Select an option"
                            showSearch // Enable search functionality
                            optionFilterProp="children" // Specify which property of the option is used for filtering
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {/* <option value="">Select an option</option> */}
                            {template !== undefined && template
                              ? template.accountingMappingOptions &&
                                template.accountingMappingOptions
                                  .assetAccountOptions &&
                                template.accountingMappingOptions.assetAccountOptions.map(
                                  (item) => (
                                    <Select.Option
                                      value={item.id}
                                      key={item.id}
                                    >
                                      {item.name}
                                    </Select.Option>
                                  )
                                )
                              : null}
                          </Select>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                </div>
              )}

              <div className="col-sm-12 col-md-6 px-0">
                <FormClass>
                  <FormElements>
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-label d-flex flex-align-center">
                        Transfer In Suspense
                        {renderTooltip("Transfer In Suspense")}
                      </label>
                      <Select
                        size="md"
                        name="transferInSusp"
                        value={transferInSusp}
                        // onChange={updateLoanProductInfoHandler}
                        onChange={(value) => setTransferInSusp(value)}
                        placeholder="Select an option"
                        showSearch // Enable search functionality
                        optionFilterProp="children" // Specify which property of the option is used for filtering
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {/* <option value="">Select an option</option> */}
                        {template !== undefined && template
                          ? template.accountingMappingOptions &&
                            template.accountingMappingOptions
                              .assetAccountOptions &&
                            template.accountingMappingOptions.assetAccountOptions.map(
                              (item) => (
                                <Select.Option value={item.id} key={item.id}>
                                  {item.name}
                                </Select.Option>
                              )
                            )
                          : null}
                      </Select>
                    </div>
                  </FormElements>
                </FormClass>
              </div>
            </div>

            <FormTitle style={{ width: "60%", margin: "4rem auto" }}>
              <p className="text-info">Income</p>
              <div className="lineThrough"></div>
            </FormTitle>

            <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
              <div className="col-sm-12 col-md-6 px-0 ">
                <FormClass>
                  <FormElements>
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-label d-flex flex-align-center">
                        Income From Interest
                        {renderTooltip("Income From Interest")}
                      </label>
                      <Select
                        size="md"
                        name="incomeFromInterest"
                        value={incomeFromInterest}
                        // onChange={updateLoanProductInfoHandler}
                        onChange={(value) => setIncomeFromInterest(value)}
                        placeholder="Select an option"
                        showSearch // Enable search functionality
                        optionFilterProp="children" // Specify which property of the option is used for filtering
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {/* <option value="">Select an option</option> */}
                        {template !== undefined && template
                          ? template.accountingMappingOptions &&
                            template.accountingMappingOptions
                              .incomeAccountOptions &&
                            template.accountingMappingOptions.incomeAccountOptions.map(
                              (item) => (
                                <Select.Option value={item.id} key={item.id}>
                                  {item.name}
                                </Select.Option>
                              )
                            )
                          : null}
                      </Select>
                    </div>
                  </FormElements>
                </FormClass>

                <FormClass>
                  <FormElements>
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-label d-flex flex-align-center">
                        Income From Fees*
                        {renderTooltip("Income From Fees")}
                      </label>
                      <Select
                        size="md"
                        name="incomeFromFees"
                        value={incomeFromFees}
                        // onChange={updateLoanProductInfoHandler}
                        onChange={(value) => setIncomeFromFees(value)}
                        placeholder="Select an option"
                        showSearch // Enable search functionality
                        optionFilterProp="children" // Specify which property of the option is used for filtering
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {/* <option value="">Select an option</option> */}
                        {template !== undefined && template
                          ? template.accountingMappingOptions &&
                            template.accountingMappingOptions
                              .incomeAccountOptions &&
                            template.accountingMappingOptions.incomeAccountOptions.map(
                              (item) => (
                                <Select.Option value={item.id} key={item.id}>
                                  {item.name}
                                </Select.Option>
                              )
                            )
                          : null}
                      </Select>
                    </div>
                  </FormElements>
                </FormClass>
              </div>
              <div className="col-sm-12 col-md-6 px-0">
                <FormClass>
                  <FormElements>
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-label d-flex flex-align-center">
                        Income From Penalties
                        {renderTooltip("Income From Penalties")}
                      </label>
                      <Select
                        size="md"
                        name="incomeFromPenalties"
                        value={incomeFromPenalties}
                        // onChange={updateLoanProductInfoHandler}
                        onChange={(value) => setIncomeFromPenalties(value)}
                        placeholder="Select an option"
                        showSearch // Enable search functionality
                        optionFilterProp="children" // Specify which property of the option is used for filtering
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {/* <option value="">Select an option</option> */}
                        {template !== undefined && template
                          ? template.accountingMappingOptions &&
                            template.accountingMappingOptions
                              .incomeAccountOptions &&
                            template.accountingMappingOptions.incomeAccountOptions.map(
                              (item) => (
                                <Select.Option value={item.id} key={item.id}>
                                  {item.name}
                                </Select.Option>
                              )
                            )
                          : null}
                      </Select>
                    </div>
                  </FormElements>
                </FormClass>
                <FormClass>
                  <FormElements>
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-label d-flex flex-align-center">
                        Income From Recovery Repayments
                        {renderTooltip("Income From Recovery Repayments")}
                      </label>
                      <Select
                        size="md"
                        name="incomeFromRecovery"
                        value={incomeFromRecovery}
                        // onChange={updateLoanProductInfoHandler}
                        onChange={(value) => setIncomeFromRecovery(value)}
                        placeholder="Select an option"
                        showSearch // Enable search functionality
                        optionFilterProp="children" // Specify which property of the option is used for filtering
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {/* <option value="">Select an option</option> */}
                        {template !== undefined && template
                          ? template.accountingMappingOptions &&
                            template.accountingMappingOptions
                              .incomeAccountOptions &&
                            template.accountingMappingOptions.incomeAccountOptions.map(
                              (item) => (
                                <Select.Option value={item.id} key={item.id}>
                                  {item.name}
                                </Select.Option>
                              )
                            )
                          : null}
                      </Select>
                    </div>
                  </FormElements>
                </FormClass>
              </div>
            </div>

            <FormTitle style={{ width: "60%", margin: "4rem auto" }}>
              <p className="text-info">Expenses</p>
              <div className="lineThrough"></div>
            </FormTitle>

            <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
              <div className="col-sm-12 col-md-6 px-0 ">
                <FormClass>
                  <FormElements>
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-label d-flex flex-align-center">
                        Losses Written Off
                        {renderTooltip("Losses Written Off")}
                      </label>
                      <Select
                        size="md"
                        name="lossesWrittenOff"
                        value={lossesWrittenOff}
                        // onChange={updateLoanProductInfoHandler}
                        onChange={(value) => setLossesWrittenOff(value)}
                        placeholder="Select an option"
                        showSearch // Enable search functionality
                        optionFilterProp="children" // Specify which property of the option is used for filtering
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {/* <option value="">Select an option</option> */}
                        {template !== undefined && template
                          ? template.accountingMappingOptions &&
                            template.accountingMappingOptions
                              .expenseAccountOptions &&
                            template.accountingMappingOptions.expenseAccountOptions.map(
                              (item) => (
                                <Select.Option value={item.id} key={item.id}>
                                  {item.name}
                                </Select.Option>
                              )
                            )
                          : null}
                      </Select>
                    </div>
                  </FormElements>
                </FormClass>
              </div>
            </div>

            <FormTitle style={{ width: "60%", margin: "4rem auto" }}>
              <p className="text-info">Liabilities</p>
              <div className="lineThrough"></div>
            </FormTitle>

            <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
              <div className="col-sm-12 col-md-6 px-0 ">
                <FormClass>
                  <FormElements>
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-label d-flex flex-align-center">
                        Over Payment Liability
                        {renderTooltip("Over Payment Liability")}
                      </label>
                      <Select
                        size="md"
                        name="overPaymentLia"
                        value={overPaymentLia}
                        // onChange={updateLoanProductInfoHandler}
                        onChange={(value) => setOverPaymentLia(value)}
                        placeholder="Select an option"
                        showSearch // Enable search functionality
                        optionFilterProp="children" // Specify which property of the option is used for filtering
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {/* <option value="">Select an option</option> */}
                        {template !== undefined && template
                          ? template.accountingMappingOptions &&
                            template.accountingMappingOptions
                              .liabilityAccountOptions &&
                            template.accountingMappingOptions.liabilityAccountOptions.map(
                              (item) => (
                                <Select.Option value={item.id} key={item.id}>
                                  {item.name}
                                </Select.Option>
                              )
                            )
                          : null}
                      </Select>
                    </div>
                  </FormElements>
                </FormClass>
              </div>
              {/* <div className="col-sm-12 col-md-6 px-0 ">
                <FormClass>
                  <FormElements>
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-label d-flex flex-align-center">
                        Interest In Suspense
                        {renderTooltip("Interest In Suspense")}
                      </label>
                      <Select
                        size="md"
                        name="interestInSuspenseAccountId"
                        value={interestInSuspenseAccountId}
                        onChange={updateLoanProductInfoHandler}
                      >
                        <option value="">Select an option</option>
                        {template !== undefined && template
                          ? template.accountingMappingOptions &&
                            template.accountingMappingOptions
                              .liabilityAccountOptions &&
                            template.accountingMappingOptions.liabilityAccountOptions.map(
                              (item) => (
                                <option value={item.id} key={item.id}>
                                  {item.name}
                                </option>
                              )
                            )
                          : null}
                      </Select>
                    </div>
                  </FormElements>
                </FormClass>
              </div> */}
            </div>

            <div className="pt-4">
              <div
                className="form-group d-flex  align-items-center"
                style={{ marginBottom: "1rem !important" }}
              >
                <h3 className="font-weight-bold">Advanced Accounting Rule</h3>
                <AddActionButton onClick={() => setShowAdvance((p) => !p)}>
                  Show
                  <img
                    className="icon-img ml-2"
                    src={
                      require(`../../../../../../../../assets/images/icons/arrow-${
                        showAdvance ? "up" : "down2"
                      }.svg`).default
                    }
                    alt="dropdown icon"
                  />
                </AddActionButton>
              </div>
            </div>

            {showAdvance ? (
              <div>
                <div className="d-flex justify-content-between flex-wrap">
                  <div className="col-sm-12 col-md-6 px-0 ">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label
                            style={{ width: "70%" }}
                            className="text-label d-flex flex-align-center"
                          >
                            Configure Fund Sources For Payment Channels
                          </label>
                          <AddActionButton onClick={addToConfigFSArray}>
                            <img
                              src={
                                require("../../../../../../../../assets/images/icons/add.svg")
                                  .default
                              }
                              alt="add icon"
                            />{" "}
                            Add
                          </AddActionButton>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                  <div className="col-sm-12 col-md-12 px-0">
                    {numberOfConfigFS.length > 0 && (
                      <Table className="child-table">
                        <thead className="">
                          <tr style={{ backgroundColor: "#FAFAFA" }}>
                            <th>Payment type</th>
                            <th>Fund source</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {numberOfConfigFS.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <Select
                                  size="md"
                                  name="savingPreference"
                                  value={numberOfConfigFS[index].paymentTypeId}
                                  onChange={(value) =>
                                    handleConfigFSPaymentIdToChange(
                                      index,
                                      value
                                    )
                                  }
                                  placeholder="Select an option"
                                  showSearch // Enable search functionality
                                  optionFilterProp="children" // Specify which property of the option is used for filtering
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {/* <option value="">Select one</option> */}
                                  {template !== undefined && template
                                    ? template.paymentTypeOptions &&
                                      template.paymentTypeOptions.map((cur) => (
                                        <Select.Option value={cur.id} key={cur.id}>
                                          {cur.name}
                                        </Select.Option>
                                      ))
                                    : null}
                                </Select>
                              </td>
                              <td>
                                <Select
                                  size="md"
                                  name="savingPreference"
                                  value={
                                    numberOfConfigFS[index].fundSourceAccountId
                                  }
                                  onChange={(value) =>
                                    handleConfigFSSourceIdToChange(
                                      index,
                                      value
                                    )
                                  }
                                  placeholder="Select an option"
                                  showSearch // Enable search functionality
                                  optionFilterProp="children" // Specify which property of the option is used for filtering
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {/* <option value="">Select an option</option> */}
                                  {template !== undefined && template
                                    ? template.accountingMappingOptions &&
                                      template.accountingMappingOptions
                                        .assetAccountOptions &&
                                      template.accountingMappingOptions.assetAccountOptions.map(
                                        (item) => (
                                          <Select.Option value={item.id} key={item.id}>
                                            {item.name}
                                          </Select.Option>
                                        )
                                      )
                                    : null}
                                </Select>
                              </td>
                              <td>
                                <IconButton
                                  onClick={() => removeFromConfigFSArray(index)}
                                >
                                  <img
                                    src={
                                      require("../../../../../../../../assets/images/icons/delete.svg")
                                        .default
                                    }
                                    alt="add icon"
                                    className="icon-img"
                                  />
                                </IconButton>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-6 px-0 ">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label
                            style={{ width: "70%" }}
                            className="text-label d-flex flex-align-center"
                          >
                            Map fees to income accounts
                          </label>
                          <AddActionButton onClick={addToMapFeeToIncomeArray}>
                            <img
                              src={
                                require("../../../../../../../../assets/images/icons/add.svg")
                                  .default
                              }
                              alt="add icon"
                            />{" "}
                            Add
                          </AddActionButton>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                  <div className="col-sm-12 col-md-12 px-0">
                    {numberOfMapFeeToIncome.length > 0 && (
                      <Table className="child-table">
                        <thead className="">
                          <tr style={{ backgroundColor: "#FAFAFA" }}>
                            <th>Fees</th>
                            <th>Income Account</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {numberOfMapFeeToIncome.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <Select
                                  size="md"
                                  name="savingPreference"
                                  value={Number(
                                    numberOfMapFeeToIncome[index].chargeId
                                  )}
                                  onChange={(value) =>
                                    handleMapFeeToIncomePaymentIdToChange(
                                      index,
                                      value
                                    )
                                  }
                                >
                                  {/* <option value="">Select one</option> */}
                                  {template !== undefined && template
                                    ? template.chargeOptions &&
                                      template.chargeOptions.map((cur) => (
                                        <Select.Option value={cur.id} key={cur.id}>
                                          {cur.name}
                                        </Select.Option>
                                      ))
                                    : null}
                                </Select>
                              </td>
                              <td>
                                <Select
                                  size="md"
                                  name="savingPreference"
                                  value={
                                    numberOfMapFeeToIncome[index]
                                      .incomeAccountId
                                  }
                                  onChange={(value) =>
                                    handleMapFeeToIncomeSourceIdToChange(
                                      index,
                                      value
                                    )
                                  }
                                  placeholder="Select an option"
                                  showSearch // Enable search functionality
                                  optionFilterProp="children" // Specify which property of the option is used for filtering
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {/* <option value="">Select an option</option> */}
                                  {template !== undefined && template
                                    ? template.accountingMappingOptions &&
                                      template.accountingMappingOptions
                                        .incomeAccountOptions &&
                                      template.accountingMappingOptions.incomeAccountOptions.map(
                                        (item) => (
                                          <Select.Option value={item.id} key={item.id}>
                                            {item.name}
                                          </Select.Option>
                                        )
                                      )
                                    : null}
                                </Select>
                              </td>
                              <td>
                                <IconButton
                                  onClick={() =>
                                    removeFromMapFeeToIncomeArray(index)
                                  }
                                >
                                  <img
                                    src={
                                      require("../../../../../../../../assets/images/icons/delete.svg")
                                        .default
                                    }
                                    alt="add icon"
                                    className="icon-img"
                                  />
                                </IconButton>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )}
                  </div>
                  {/* <div className="col-sm-12 col-md-6 px-0 ">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label
                            style={{ width: "70%" }}
                            className="text-label d-flex flex-align-center"
                          >
                            Map fees to liability accounts
                          </label>
                          <NextActionButton>
                            <img
                              src={
                                require("../../../../../../../../assets/images/icons/add.svg")
                                  .default
                              }
                              alt="add icon"
                            />{" "}
                            Add
                          </NextActionButton>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                  <div className="col-sm-12 col-md-6 px-0" />*/}
                  <div className="col-sm-12 col-md-6 px-0 ">
                    <FormClass>
                      <FormElements>
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label
                            style={{ width: "70%" }}
                            className="text-label d-flex flex-align-center"
                          >
                            Map Penalties To Specific Income Accounts
                          </label>
                          <AddActionButton onClick={addToMapPenaltiesArray}>
                            <img
                              src={
                                require("../../../../../../../../assets/images/icons/add.svg")
                                  .default
                              }
                              alt="add icon"
                            />{" "}
                            Add
                          </AddActionButton>
                        </div>
                      </FormElements>
                    </FormClass>
                  </div>
                  <div className="col-sm-12 col-md-12 px-0">
                    {numberOfMapPenalties.length > 0 && (
                      <Table className="child-table">
                        <thead className="">
                          <tr style={{ backgroundColor: "#FAFAFA" }}>
                            <th>Penalty</th>
                            <th>Income Account</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {numberOfMapPenalties.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <Select
                                  size="md"
                                  name="savingPreference"
                                  value={numberOfMapPenalties[index].chargeId}
                                  onChange={(value) =>
                                    handleMapPenaltiesPaymentIdToChange(
                                      index,
                                      value
                                    )
                                  }
                                  placeholder="Select an option"
                                  showSearch // Enable search functionality
                                  optionFilterProp="children" // Specify which property of the option is used for filtering
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {/* <option value="">Select one</option> */}
                                  {template !== undefined && template
                                    ? template.penaltyOptions &&
                                      template.penaltyOptions.map((cur) => (
                                        <Select.Option value={cur.id} key={cur.id}>
                                          {cur.name}
                                        </Select.Option>
                                      ))
                                    : null}
                                </Select>
                              </td>
                              <td>
                                <Select
                                  size="md"
                                  name="savingPreference"
                                  value={
                                    numberOfMapPenalties[index].incomeAccountId
                                  }
                                  onChange={(value) =>
                                    handleMapPenaltiesSourceIdToChange(
                                      index,
                                      value
                                    )
                                  }
                                  placeholder="Select an option"
                                  showSearch // Enable search functionality
                                  optionFilterProp="children" // Specify which property of the option is used for filtering
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {/* <option value="">Select an option</option> */}
                                  {template !== undefined && template
                                    ? template.accountingMappingOptions &&
                                      template.accountingMappingOptions
                                        .incomeAccountOptions &&
                                      template.accountingMappingOptions.incomeAccountOptions.map(
                                        (item) => (
                                          <Select.Option value={item.id} key={item.id}>
                                            {item.name}
                                          </Select.Option>
                                        )
                                      )
                                    : null}
                                </Select>
                              </td>
                              <td>
                                <IconButton
                                  onClick={() =>
                                    removeFromMapPenaltiesArray(index)
                                  }
                                >
                                  <img
                                    src={
                                      require("../../../../../../../../assets/images/icons/delete.svg")
                                        .default
                                    }
                                    alt="add icon"
                                    className="icon-img"
                                  />
                                </IconButton>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        ) : null}

        <div className="float-right pt-5">
          <NextActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={() => {
              charges.length > 0 &&
                setFullCharges(charges.map(({ id }) => ({ id })));
              overdueCharges.length > 0 &&
                setFullOverdueCharges(overdueCharges.map(({ id }) => ({ id })));
              // setFullCharges([...fullCharges, overdueCharges.map(({ id }) => ({ id }))]);
              // console.log(fullCharges)
              setValueOverdueCharges(overdueCharges);
              setValueCharges(charges);
              setPaymentChannelToFundSourceMappings(numberOfConfigFS);
              setPenaltyToIncomeAccountMappings(numberOfMapPenalties);
              setFeeToIncomeAccountMappings(numberOfMapFeeToIncome);
              handlePreviousScreen();
            }}
          >
            <img
              src={
                require("../../../../../../../../assets/images/icons/arrow-left.svg")
                  .default
              }
              alt="cancel icon"
            />
            Previous
          </NextActionButton>
          <NextActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={() => {
              if (radio === "2") {
                incomeFromFees ? setChargesError(false) : setChargesError(true);
              } else {
                setChargesError(false);
              }
              charges.length > 0 &&
                setFullCharges(charges.map(({ id }) => ({ id })));
              overdueCharges.length > 0 &&
                setFullOverdueCharges(overdueCharges.map(({ id }) => ({ id })));

              setValueOverdueCharges(overdueCharges);
              setValueCharges(charges);
              setPaymentChannelToFundSourceMappings(numberOfConfigFS);
              setPenaltyToIncomeAccountMappings(numberOfMapPenalties);
              setFeeToIncomeAccountMappings(numberOfMapFeeToIncome);
              handleNextScreen();
            }}
          >
            <img
              src={
                require("../../../../../../../../assets/images/icons/arrow-right.svg")
                  .default
              }
              alt="submit icon"
            />
            Next
          </NextActionButton>
        </div>
      </FormClass>
    </>
  );
};

export default LoanCharges;
