import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { Input, Textarea } from "@chakra-ui/react";
import { uploadLoanDocument } from "../../../../../../actions/SavingsAndLoanActions";
import { FormClass, ActionButton } from "../../styles";
import FileUpload from "../../../../../../assets/images/file-upload.svg";

const UploadDocumentModal = ({ id, history, onHide }) => {
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [document, setDocument] = useState("");
  const [file, setFile] = useState(null);

  const handleDocumentSubmit = () => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", name);
    formData.append("description", description);
    dispatch(uploadLoanDocument(id, formData, history, onHide));
  };
  return (
    <>
      <Modal.Header>
        <Modal.Title>Upload Loan Document</Modal.Title>
      </Modal.Header>
      <Modal.Body className="my2 d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <div className="form-group d-flex justify-content-between align-items-center">
            <label className="text-label">Name*</label>
            <Input
              style={{ width: "60%" }}
              size="md"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="form-group d-flex justify-content-between align-items-center">
            <label className="text-label">Description</label>
            <Textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              size="md"
              resize={"none"}
              style={{ fontSize: '12px' }}
            />
          </div>
          <div className="d-flex justify-content-center my-4">
            <div className="draganddrop">
              <input
                type="file"
                name="document"
                id="signature"
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: "0",
                  left: "0",
                  opacity: "0",
                  cursor: "pointer",
                }}
                onChange={(e) => setFile(e.target.files[0])}
                onDrop={(e) => setFile(e.target.files[0])}
                onDragOver={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
              <div className="draganddrop-dd">
                <div className="d-flex justify-content-center my-4">
                  <img className="file-Img" src={FileUpload} alt="" />
                </div>
                <p className="text-center">
                  Drag & drop your files here <br /> or
                </p>
                <ActionButton
                  color="#2C1DFF"
                  className="mt-2"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <input
                    type="file"
                    id="signature"
                    name="image"
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      top: "0",
                      left: "0",
                      opacity: "0",
                      cursor: "pointer",
                    }}
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                  Choose File
                </ActionButton>
                {!!file ? <p>{file.name}</p> : ""}
              </div>
            </div>
          </div>
          <div className="d-flex mt-4">
            <ActionButton color="#2C1DFF" onClick={onHide}>
              <img
                src={
                  require("../../../../../../assets/images/icons/cancel.svg")
                    .default
                }
                alt="cancel icon"
              />
              Cancel
            </ActionButton>
            <ActionButton
              color="#2C1DFF"
              style={{ margin: "0px 0px 0 10px" }}
              onClick={(e) => {
                e.preventDefault();
                handleDocumentSubmit();
              }}
            >
              <img
                src={
                  require("../../../../../../assets/images/icons/save.svg")
                    .default
                }
                alt="submit icon"
              />
              Submit
            </ActionButton>
          </div>
        </FormClass>
      </Modal.Body>
    </>
  );
};

export default UploadDocumentModal;
