import styled from "styled-components";

export const ContentWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 0px #ededf6;
  min-height: 70vh;
  margin-top: 20px;
  padding: 50px;

  .button-right {
    width: 100%;
    height: 50px;
    padding-right: 15px;
  }
`;

export const FormClass = styled.div``;
