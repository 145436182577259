import React, { useState, useEffect } from "react";
import { InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import { Table } from "antd";
import { savingsColumns } from "../constants";

import {
  ContentWrapper,
  ActionButtonsGroup,
  ActionAnchorButton,
  AdvSearchActionButton,
} from "../styles";
import ModalContainer from "../../components/ModalContainer";
import { useSelector, useDispatch } from "react-redux";

import FilterModal from "./components/FilterModal";
import {
  getAllSavingsAccounts,
  searchSavingsAccount,
  getAllSavingsAccountsTemplate,
} from "../../../../actions/accountActions";
import TableLoader from './TableLoader';

import { useHistory } from 'react-router';
import { fetch } from "../../Accounting/AddAccountJournalEntry/search";
import { SET_LOADING_STATE } from "../../../../constants/utilityConstants";

const SavingsAccount = () => {
  const history = useHistory();

  const dispatch = useDispatch();
  const accountData = useSelector((state) => state.accountData);
  const isTableLoading = useSelector(
    (state) => state.isTableLoading.isTableLoading
  );

  let { savingsAccounts, isLoading, filteredSavings, savingsAccountTemplate } =
    accountData;

  const {
    totalFilteredRecords,
    totalItems,
    numberOfPages,
    pageItems = [],
  } = savingsAccounts || {};

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 13,
  });
  const [activePagination, setActivePagination] = useState();
  const [myData, setMyData] = useState([]);
  const [nubanData, setNubanData] = useState(null);

  const viewAccount = (rowIndex) => {
    const { clientId, key } = getTableRowData(rowIndex);
    history.push(`/core/client/${clientId}/deposits/${key}`);
  };

  const getTableRowData = (rowIndex) => {
    const data = (filteredSavings !== null || nubanData !== null) ? filteredSavingAccounts : dataSource;
    return data[rowIndex];
  };

  const savingsColumns = [
    { title: '', dataIndex: 'picture', key: 'picture' },
    {
      title: 'Customer Name',
      dataIndex: 'name',
      key: 'name',
      render: (value, record, index) => (
        <span onClick={() => viewAccount(index)}>{value}</span>
      ),
    },
    { title: 'Status', dataIndex: 'status', key: 'status' },
    { title: 'External ID', dataIndex: 'externalId', key: 'externalId' },
    {
      title: 'Account Number',
      dataIndex: 'accountNumber',
      key: 'accountNumber',
    },
    { title: 'Savings Product', dataIndex: 'product', key: 'product' },
    {
      title: 'Account Balance',
      dataIndex: 'accountbalance',
      key: 'accountbalance',
    },
  ];

  useEffect(() => {
    dispatch(getAllSavingsAccounts('limit=13&offset=0&sortOrder=DESC'));
    dispatch(getAllSavingsAccountsTemplate());
  }, []);

  useEffect(() => {
    if (!!savingsAccounts && !!savingsAccounts.length) {
      setMyData(savingsAccounts);
    } else if (!!pageItems.length) {
      setMyData(pageItems);
    }
  }, [pageItems, savingsAccounts]);

  useEffect(() => {
    if (totalFilteredRecords !== 0 && totalFilteredRecords !== 1) {
      setPagination({
        current: activePagination,
        pageSize: 13,
        total: totalFilteredRecords,
      });
    } else if (totalItems === 0 && numberOfPages === 0) {
      setPagination(false);
    } else if (!!savingsAccounts && !!savingsAccounts.length) {
      setPagination({ defaultPageSize: 13 });
    }
  }, [
    totalItems,
    numberOfPages,
    totalFilteredRecords,
    activePagination,
    savingsAccounts,
  ]);

  const [searchParams, setSearchParams] = useState('');

  const handleChange = (e) => {
    setSearchParams(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchParams.length) {
      dispatch(searchSavingsAccount(searchParams));
      // TODO: Remove if not needed anymore
      // if (!isNaN(Number(searchParams))) {
      //   dispatch({ type: SET_LOADING_STATE, payload: true });
      //   fetch(
      //     searchParams,
      //     ({ allData }) => {
      //       setNubanData(allData?.filter(({ entityType }) => entityType === 'SAVING'));
      //       setPagination({
      //         current: 1,
      //         pageSize: allData?.length,
      //         total: allData?.length,
      //       });
      //       dispatch({ type: SET_LOADING_STATE, payload: false });
      //     }
      //   );
      // } else dispatch(searchSavingsAccount(searchParams));
    }
  };

  const refresh = () => {
    dispatch(getAllSavingsAccounts('limit=13&offset=0&sortOrder=DESC'));
    setNubanData(null);
    setSearchParams('');
    setActivePagination(1);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setActivePagination(pagination.current);
    if (!!savingsAccounts && !!savingsAccounts.length) {
      setPagination({
        current: activePagination,
        pageSize: 13,
        total: savingsAccounts.length,
      });
    } else {
      let myOffsect = pagination.current * 13;
      let dataToSend2 = `limit=13&offset=${myOffsect - 13}&sortOrder=DESC`;
      dispatch(getAllSavingsAccounts(dataToSend2));
    }
  };

  // const tableData = [];
  const filteredSavingAccounts = [];

  const dataSource = [];

  if (filteredSavings !== null || nubanData !== null) {
    (filteredSavings || nubanData).forEach((sa) => {
      filteredSavingAccounts.push({
        key: sa?.id ?? sa?.entityId,
        picture: (
          <div
            className={
              sa?.status === 'Active' || sa?.entityStatus?.value === 'Active'
                ? 'box-green'
                : sa?.status === 'Pending' || sa?.entityStatus?.value === 'Pending'
                ? 'box-blue'
                : 'box-red'
            }
          />
        ),
        name: sa?.customerName ?? sa?.parentName,
        status: <p className="mr-3">{sa?.status ?? sa?.entityStatus?.value}</p>,
        externalId: sa?.externalId ?? sa?.entityExternalId,
        product: sa?.productName ?? sa?.entityName,
        accountNumber: sa?.accountNo ?? sa?.entityAccountNo,
        accountbalance: sa?.accountBalance ?? '--',
        interest: sa?.interest,
        clientId: sa?.clientId ?? sa?.parentId,
      });
    });
  }

  !isLoading &&
    myData.forEach((savingAccount) => {
      const {
        id,
        status,
        productName,
        customerName,
        externalId,
        accountNo,
        accountBalance,
        interest,
        clientId,
      } = savingAccount;

      dataSource.push({
        key: id,
        picture: (
          <div
            className={
              status === 'Active'
                ? 'box-green'
                : status === 'Pending'
                ? 'box-blue'
                : 'box-red'
            }
          />
        ),
        name: customerName,
        status: <p className="mr-3">{status}</p>,
        externalId,
        product: productName,
        accountNumber: accountNo,
        accountbalance: accountBalance,
        interest,
        clientId,
      });
    });

  const [modalState, toggleModal] = useState(false);

  const handleOnHide = () => {
    toggleModal(false);
  };

  const [viewState, setViewState] = useState(false);

  return (
    <>
      <ModalContainer show={modalState}>
        {modalState && (
          <FilterModal
            template={savingsAccountTemplate}
            onHide={handleOnHide}
            setViewState={setViewState}
          />
        )}
      </ModalContainer>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">Deposits Accounts</h3>
            <p className="sub-title">
              A complete overview of all deposits accounts
            </p>
          </div>
          <div className="col-sm-12 col-md-6 px-0 pt-3">
            <ActionButtonsGroup>
              <div className="col-sm-12 col-md-4 p-0 d-flex">
                <ActionAnchorButton
                  className="general-font-size"
                  onClick={() => toggleModal(true)}
                >
                  Filter By
                </ActionAnchorButton>
                <ActionAnchorButton onClick={refresh} color="#2C1DFF">
                  Refresh
                </ActionAnchorButton>
              </div>
              <div className="col-sm-12 col-md-8 pr-2">
                <form onSubmit={handleSubmit}>
                <InputGroup>
                  <InputLeftElement
                    width="3.1rem"
                    height="38px"
                    pointerEvents="none"
                  >
                    <img
                      src={
                        require('../../../../assets/images/icons/search.svg')
                          .default
                      }
                      style={{ height: '30px' }}
                      alt="Icon"
                      className="icon-img"
                    />
                  </InputLeftElement>
                  <Input
                    style={{
                      background: '#ffffff',
                      height: '35px',
                    }}
                    value={searchParams}
                    type="text"
                    placeholder="Search by name/account no./external ID"
                    onChange={handleChange}
                  />
                  <AdvSearchActionButton onClick={handleSubmit}>
                    Search
                  </AdvSearchActionButton>
                </InputGroup>
                </form>
              </div>

            </ActionButtonsGroup>
          </div>
        </div>
        {viewState ? <> </> : ''}
        <ContentWrapper>
          {isLoading === true ? (
            <TableLoader />
          ) : (
            <>
              {myData.length && myData.length > 13 ? (
                <Table
                  style={{
                    overflow: 'hidden',
                    overflowX: 'scroll',
                  }}
                  columns={savingsColumns}
                  defaultPageSize={'13'}
                  loading={isTableLoading}
                  pagination={{ pageSize: 13 }}
                  dataSource={0 || dataSource}
                />
              ) : totalFilteredRecords === 0 ? (
                <Table
                  style={{
                    overflow: 'hidden',
                    overflowX: 'scroll',
                  }}
                  columns={savingsColumns}
                  loading={isTableLoading}
                  dataSource={0}
                />
              ) : (
                <Table
                  style={{
                    overflow: 'hidden',
                    overflowX: 'scroll',
                  }}
                  columns={savingsColumns}
                  pagination={pagination}
                  defaultPageSize={'13'}
                  loading={isTableLoading}
                  onChange={handleTableChange}
                  dataSource={
                    (filteredSavings !== null || nubanData !== null)
                      ? filteredSavingAccounts
                      : dataSource
                  }
                  // dataSource={filteredSavings != null ? filteredSavingAccounts : dataSource}
                />
              )}
            </>
          )}
        </ContentWrapper>
      </div>
    </>
  );
};

export default SavingsAccount;
