import {
  ALL_USERS_REQUEST,
  ALL_USERS_SUCCESS,
  ALL_USERS_FAIL,
  FILTER_USERS_REQUEST,
  CLEAR_FILTER,
  CREATE_NEW_USER_FAIL,
  CREATE_NEW_USER_REQUEST,
  CREATE_NEW_USER_SUCCESS,
  CREATE_NEW_USER_RESET,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_REQUEST,
  UPDATE_USER_FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  ALL_USERS_TEMPLATE_REQUEST,
  ALL_USERS_TEMPLATE_SUCCESS,
  ALL_USERS_TEMPLATE_FAIL,
  ALL_OFFICE_STAFFS_REQUEST,
  ALL_OFFICE_STAFFS_SUCCESS,
  ALL_OFFICE_STAFFS_FAIL,
  ALL_OFFICE_STAFFS_RESET,
  ALL_OFFICES_REQUEST,
  ALL_OFFICES_SUCCESS,
  ALL_OFFICES_FAIL,
  FILTER_OFFICES_REQUEST,
  CLEAR_OFFICES_FILTER,
  UPDATE_OFFICE_REQUEST,
  UPDATE_OFFICE_SUCCESS,
  UPDATE_OFFICE_FAIL,
  CREATE_NEW_OFFICE_REQUEST,
  CREATE_NEW_OFFICE_SUCCESS,
  CREATE_NEW_OFFICE_FAIL,
  CREATE_NEW_OFFICE_RESET,
  ALL_CURRENCY_REQUEST,
  ALL_CURRENCY_SUCCESS,
  ALL_CURRENCY_FAIL,
  CREATE_NEW_CURRENCY_REQUEST,
  CREATE_NEW_CURRENCY_SUCCESS,
  CREATE_NEW_CURRENCY_FAIL,
  ALL_EMPLOYEES_REQUEST,
  ALL_EMPLOYEES_SUCCESS,
  ALL_EMPLOYEES_FAIL,
  FILTER_EMPLOYEES_REQUEST,
  CLEAR_EMPLOYEES_FILTER,
  CREATE_NEW_EMPLOYEE_REQUEST,
  CREATE_NEW_EMPLOYEE_SUCCESS,
  CREATE_NEW_EMPLOYEE_FAIL,
  CREATE_NEW_EMPLOYEE_RESET,
  UPDATE_EMPLOYEE_REQUEST,
  UPDATE_EMPLOYEE_SUCCESS,
  UPDATE_EMPLOYEE_FAIL,
  UPDATE_EMPLOYEE_IMAGE_REQUEST,
  UPDATE_EMPLOYEE_IMAGE_SUCCESS,
  UPDATE_EMPLOYEE_IMAGE_FAIL,
  ALL_ENTITY_DATA_TABLE_REQUEST,
  ALL_ENTITY_DATA_TABLE_SUCCESS,
  ALL_ENTITY_DATA_TABLE_FAIL,
  FILTER_ENTITY_DATA_TABLE_REQUEST,
  CLEAR_ENTITY_DATA_TABLE_FILTER,
  CREATE_NEW_ENTITY_DATA_TABLE_REQUEST,
  CREATE_ENTITY_DATA_TABLE_SUCCESS,
  CREATE_ENTITY_DATA_TABLE_FAIL,
  CREATE_ENTITY_DATA_TABLE_RESET,
  DELETE_ENTITY_DATA_TABLE_REQUEST,
  DELETE_ENTITY_DATA_TABLE_SUCCESS,
  DELETE_ENTITY_DATA_TABLE_FAIL,
  ALL_ENTITY_DATA_TABLE_TEMPLATE_REQUEST,
  ALL_ENTITY_DATA_TABLE_TEMPLATE_SUCCESS,
  ALL_ENTITY_DATA_TABLE_TEMPLATE_FAIL,
  ALL_HOLIDAYS_REQUEST,
  ALL_HOLIDAYS_SUCCESS,
  ALL_HOLIDAYS_FAIL,
  FILTER_HOLIDAYS_REQUEST,
  CLEAR_HOLIDAYS_FILTER,
  CREATE_NEW_HOLIDAY_REQUEST,
  CREATE_NEW_HOLIDAY_SUCCESS,
  CREATE_NEW_HOLIDAY_FAIL,
  CREATE_NEW_HOLIDAY_RESET,
  DELETE_HOLIDAY_REQUEST,
  DELETE_HOLIDAY_SUCCESS,
  DELETE_HOLIDAY_FAIL,
  ACTIVATE_HOLIDAY_REQUEST,
  ACTIVATE_HOLIDAY_SUCCESS,
  ACTIVATE_HOLIDAY_FAIL,
  UPDATE_HOLIDAY_REQUEST,
  UPDATE_HOLIDAY_SUCCESS,
  UPDATE_HOLIDAY_FAIL,
  ALL_HOLIDAYS_TEMPLATE_REQUEST,
  ALL_HOLIDAYS_TEMPLATE_SUCCESS,
  ALL_HOLIDAYS_TEMPLATE_FAIL,
  ALL_WORKING_DAYS_REQUEST,
  ALL_WORKING_DAYS_SUCCESS,
  ALL_WORKING_DAYS_FAIL,
  CREATE_NEW_WORKING_DAYS_REQUEST,
  CREATE_NEW_WORKING_DAYS_SUCCESS,
  CREATE_NEW_WORKING_DAYS_FAIL,
  CREATE_NEW_WORKING_DAYS_RESET,
  ALL_STANDING_INSTRUCTION_TEMPLATE_REQUEST,
  ALL_STANDING_INSTRUCTION_TEMPLATE_SUCCESS,
  ALL_STANDING_INSTRUCTION_TEMPLATE_FAIL,
  ALL_STANDING_INSTRUCTION_REQUEST,
  ALL_STANDING_INSTRUCTION_SUCCESS,
  ALL_STANDING_INSTRUCTION_FAIL,
  ALL_PAYMENT_TYPE_REQUEST,
  ALL_PAYMENT_TYPE_SUCCESS,
  ALL_PAYMENT_TYPE_FAIL,
  FILTER_PAYMENT_TYPE_REQUEST,
  CLEAR_PAYMENT_TYPE_FILTER,
  CREATE_NEW_PAYMENT_TYPE_REQUEST,
  CREATE_NEW_PAYMENT_TYPE_SUCCESS,
  UPDATE_PAYMENT_TYPE_SUCCESS,
  UPDATE_PAYMENT_TYPE_REQUEST,
  UPDATE_PAYMENT_TYPE_FAIL,
  CREATE_NEW_PAYMENT_TYPE_FAIL,
  DELETE_PAYMENT_TYPE_REQUEST,
  DELETE_PAYMENT_TYPE_SUCCESS,
  DELETE_PAYMENT_TYPE_FAIL,
  ALL_SAVINGS_PRODUCTS_REQUEST,
  ALL_SAVINGS_PRODUCTS_SUCCESS,
  ALL_SAVINGS_PRODUCTS_FAIL,
  FILTER_SAVINGS_PRODUCTS_REQUEST,
  CLEAR_SAVINGS_PRODUCTS_FILTER,
  ALL_SAVINGS_PRODUCTS_TEMPLATE_REQUEST,
  ALL_SAVINGS_PRODUCTS_TEMPLATE_SUCCESS,
  ALL_SAVINGS_PRODUCTS_TEMPLATE_FAIL,
  CREATE_NEW_SAVINGS_PRODUCT_REQUEST,
  CREATE_NEW_SAVINGS_PRODUCT_SUCCESS,
  CREATE_NEW_SAVINGS_PRODUCT_FAIL,
  CREATE_NEW_SAVINGS_PRODUCT_RESET,
  SAVINGS_PRODUCT_FAIL,
  SAVINGS_PRODUCT_SUCCESS,
  SAVINGS_PRODUCT_REQUEST,
  UPDATE_SAVINGS_PRODUCT_REQUEST,
  UPDATE_SAVINGS_PRODUCT_SUCCESS,
  UPDATE_SAVINGS_PRODUCT_FAIL,
  SAVINGS_PRODUCT_RESET,
  ALL_LOAN_PRODUCT_REQUEST,
  ALL_LOAN_PRODUCT_SUCCESS,
  ALL_LOAN_PRODUCT_FAIL,
  FILTER_LOAN_PRODUCT_REQUEST,
  CLEAR_LOAN_PRODUCT_FILTER,
  ALL_LOAN_PRODUCT_TEMPLATE_REQUEST,
  ALL_LOAN_PRODUCT_TEMPLATE_SUCCESS,
  ALL_LOAN_PRODUCT_TEMPLATE_FAIL,
  CREATE_NEW_LOAN_PRODUCT_REQUEST,
  CREATE_NEW_LOAN_PRODUCT_SUCCESS,
  CREATE_NEW_LOAN_PRODUCT_FAIL,
  CREATE_NEW_LOAN_PRODUCT_RESET,
  LOAN_PRODUCT_FAIL,
  LOAN_PRODUCT_SUCCESS,
  LOAN_PRODUCT_REQUEST,
  UPDATE_LOAN_PRODUCT_REQUEST,
  UPDATE_LOAN_PRODUCT_SUCCESS,
  UPDATE_LOAN_PRODUCT_FAIL,
  LOAN_PRODUCT_RESET,
  ALL_CHARGES_REQUEST,
  ALL_CHARGES_SUCCESS,
  ALL_CHARGES_FAIL,
  FILTER_CHARGES_REQUEST,
  CLEAR_CHARGES_FILTER,
  ALL_CHARGES_TEMPLATE_REQUEST,
  ALL_CHARGES_TEMPLATE_SUCCESS,
  ALL_CHARGES_TEMPLATE_FAIL,
  CREATE_NEW_CHARGE_REQUEST,
  CREATE_NEW_CHARGE_SUCCESS,
  CREATE_NEW_CHARGE_FAIL,
  CREATE_NEW_CHARGE_RESET,
  DELETE_CHARGE_REQUEST,
  DELETE_CHARGE_SUCCESS,
  DELETE_CHARGE_FAIL,
  CHARGE_FAIL,
  CHARGE_SUCCESS,
  CHARGE_REQUEST,
  UPDATE_CHARGE_FAIL,
  UPDATE_CHARGE_REQUEST,
  UPDATE_CHARGE_SUCCESS,
  CHARGE_RESET,
  ALL_TAX_COMPONENTS_REQUEST,
  ALL_TAX_COMPONENTS_SUCCESS,
  ALL_TAX_COMPONENTS_FAIL,
  ALL_TAX_COMPONENTS_TEMPLATE_REQUEST,
  ALL_TAX_COMPONENTS_TEMPLATE_SUCCESS,
  ALL_TAX_COMPONENTS_TEMPLATE_FAIL,
  FILTER_TAX_COMPONENTS_REQUEST,
  CLEAR_TAX_COMPONENTS_FILTER,
  UPDATE_TAX_COMPONENT_REQUEST,
  UPDATE_TAX_COMPONENT_SUCCESS,
  UPDATE_TAX_COMPONENT_FAIL,
  CREATE_NEW_TAX_COMPONENT_REQUEST,
  CREATE_NEW_TAX_COMPONENT_SUCCESS,
  CREATE_NEW_TAX_COMPONENT_FAIL,
  CREATE_NEW_TAX_COMPONENT_RESET,
  ALL_TAX_GROUPS_REQUEST,
  ALL_TAX_GROUPS_SUCCESS,
  ALL_TAX_GROUPS_FAIL,
  ALL_TAX_GROUPS_TEMPLATE_REQUEST,
  ALL_TAX_GROUPS_TEMPLATE_SUCCESS,
  ALL_TAX_GROUPS_TEMPLATE_FAIL,
  FILTER_TAX_GROUPS_REQUEST,
  CLEAR_TAX_GROUPS_FILTER,
  UPDATE_TAX_GROUP_REQUEST,
  UPDATE_TAX_GROUP_SUCCESS,
  UPDATE_TAX_GROUP_FAIL,
  CREATE_NEW_TAX_GROUP_REQUEST,
  CREATE_NEW_TAX_GROUP_SUCCESS,
  CREATE_NEW_TAX_GROUP_FAIL,
  CREATE_NEW_TAX_GROUP_RESET,
  ALL_AUDIT_REQUEST,
  FILTER_AUDIT_USERS_REQUEST,
  CLEAR_AUDIT_USERS_FILTER,
  ALL_AUDIT_SUCCESS,
  ALL_AUDIT_FAIL,
  ALL_USER_AUDIT_REQUEST,
  ALL_USER_AUDIT_SUCCESS,
  ALL_USER_AUDIT_FAIL,
  ALL_AUDIT_TEMPLATE_REQUEST,
  ALL_AUDIT_TEMPLATE_SUCCESS,
  ALL_AUDIT_TEMPLATE_FAIL,
  ALL_HOOK_REQUEST,
  ALL_HOOK_SUCCESS,
  ALL_HOOK_FAIL,
  FILTER_HOOK_REQUEST,
  CLEAR_HOOK_FILTER,
  CREATE_NEW_HOOK_REQUEST,
  CREATE_NEW_HOOK_SUCCESS,
  UPDATE_HOOK_SUCCESS,
  UPDATE_HOOK_REQUEST,
  UPDATE_HOOK_FAIL,
  CREATE_NEW_HOOK_FAIL,
  DELETE_HOOK_REQUEST,
  DELETE_HOOK_SUCCESS,
  DELETE_HOOK_FAIL,
  ALL_HOOK_TEMPLATE_REQUEST,
  ALL_HOOK_TEMPLATE_SUCCESS,
  ALL_HOOK_TEMPLATE_FAIL,
  UPDATE_S3_REQUEST,
  UPDATE_S3_SUCCESS,
  UPDATE_S3_FAIL,
  ALL_S3_REQUEST,
  ALL_S3_SUCCESS,
  ALL_S3_FAIL,
  UPDATE_SMTP_REQUEST,
  UPDATE_SMTP_SUCCESS,
  UPDATE_SMTP_FAIL,
  ALL_SMTP_REQUEST,
  ALL_SMTP_SUCCESS,
  ALL_SMTP_FAIL,
  UPDATE_SMS_REQUEST,
  UPDATE_SMS_SUCCESS,
  UPDATE_SMS_FAIL,
  ALL_SMS_REQUEST,
  ALL_SMS_SUCCESS,
  ALL_SMS_FAIL,
  UPDATE_NOTIFICATION_REQUEST,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_FAIL,
  ALL_NOTIFICATION_REQUEST,
  ALL_NOTIFICATION_SUCCESS,
  ALL_NOTIFICATION_FAIL,
  ALL_CONFIGURATION_REQUEST,
  ALL_CONFIGURATION_SUCCESS,
  ALL_CONFIGURATION_FAIL,
  ENABLE_CONFIGURATION_REQUEST,
  ENABLE_CONFIGURATION_SUCCESS,
  ENABLE_CONFIGURATION_FAIL,
  DISABLE_CONFIGURATION_REQUEST,
  DISABLE_CONFIGURATION_SUCCESS,
  DISABLE_CONFIGURATION_FAIL,
  FILTER_CONFIGURATION_REQUEST,
  CLEAR_CONFIGURATION_FILTER,
  UPDATE_CONFIGURATION_REQUEST,
  UPDATE_CONFIGURATION_SUCCESS,
  UPDATE_CONFIGURATION_FAIL,
  UPDATE_MCTASK_REQUEST,
  UPDATE_MCTASK_SUCCESS,
  UPDATE_MCTASK_FAIL,
  ALL_MCTASKS_REQUEST,
  ALL_MCTASKS_SUCCESS,
  ALL_MCTASKS_FAIL,
  ALL_ROLES_REQUEST,
  ALL_ROLES_SUCCESS,
  ALL_ROLES_FAIL,
  FILTER_ROLES_REQUEST,
  CLEAR_ROLES_FILTER,
  CREATE_NEW_ROLE_REQUEST,
  CREATE_NEW_ROLE_SUCCESS,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_REQUEST,
  UPDATE_ROLE_FAIL,
  CREATE_NEW_ROLE_FAIL,
  DELETE_ROLE_REQUEST,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAIL,
  ABLE_ROLE_FAIL,
  ABLE_ROLE_REQUEST,
  ABLE_ROLE_SUCCESS,
  ROLE_REQUEST,
  ROLE_SUCCESS,
  ROLE_FAIL,
  GENERATED_USER_KEY,
  GET_DATA_TABLES,
  GET_ONE_DATA_TABLE,
  GET_USER_KEYS,
  GET_SINGLE_AUDIT_REQUEST,
  GET_SINGLE_AUDIT_SUCCESS,
  GET_SINGLE_AUDIT_FAIL,
  GET_PASSWORD_CHECK_REQUEST,
  GET_PASSWORD_CHECK_SUCCESS,
  GET_PASSWORD_CHECK_FAIL,
  RESET_PASSWORD_CHECK,
  FETCH_SINGLE_EMPLOYEE_REQUEST,
  FETCH_SINGLE_EMPLOYEE_SUCCESS,
  FETCH_SINGLE_EMPLOYEE_FAIL,
  FETCH_EMPLOYEE_IMAGE_REQUEST,
  FETCH_EMPLOYEE_IMAGE_SUCCESS,
  FETCH_EMPLOYEE_IMAGE_FAIL,
  RESET_EMPLOYEE_IMAGE,
  RESET_USER_PASSWORD_REQUEST,
  RESET_USER_PASSWORD_SUCCESS,
  RESET_USER_PASSWORD_FAIL,
  FETCH_SINGLE_EMPLOYEE_RESET,
} from "../constants/configurationConstants";

//REDUCER FOR ALL USERS
export const userReducer = (state = { users: [], userApiKeys: [] }, action) => {
  switch (action.type) {
    case ALL_USERS_REQUEST:
      return { ...state, loading: true };
    case ALL_USERS_SUCCESS:
      return { ...state, loading: false, users: action.payload };
    case ALL_USERS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case FILTER_USERS_REQUEST:
      return {
        ...state,
        filtered: state.users.filter((cst) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return (
            cst.firstname.match(regex) ||
            cst.lastname.match(regex) ||
            cst.email.match(regex) ||
            (cst.officeName && cst.officeName.match(regex))
          );
        }),
      };
    case GENERATED_USER_KEY:
      return { ...state, loading: false, newUserKey: action.payload };
    case GET_USER_KEYS:
      return { ...state, loading: false, userApiKeys: action.payload };
    case CLEAR_FILTER:
      return { ...state, filtered: null };
    default:
      return state;
  }
};

//REDUCER FOR CREATE NEW USER
export const createNewUserReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_NEW_USER_REQUEST:
      return { loading: true, ...state };
    case CREATE_NEW_USER_SUCCESS:
      return { ...state, loading: false, newUserCreated: action.payload };
    case CREATE_NEW_USER_FAIL:
      return { ...state, loading: false, error: action.payload };
    case CREATE_NEW_USER_RESET:
      return { ...state, newUserCreated: null, loading: false, error: null };
    default:
      return state;
  }
};

//REDUCER FOR USER TEMPLATE
export const userTemplateReducer = (state = { template: {} }, action) => {
  switch (action.type) {
    case ALL_USERS_TEMPLATE_REQUEST:
      return { ...state, loading: true };
    case ALL_USERS_TEMPLATE_SUCCESS:
      return { ...state, loading: false, template: action.payload };
    case ALL_USERS_TEMPLATE_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER TO RESET USER PASSWORD
export const resetUserPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case RESET_USER_PASSWORD_REQUEST:
      return { ...state, loading: true };
    case RESET_USER_PASSWORD_SUCCESS:
      return { loading: false, response: action.payload };
    case RESET_USER_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER TO UPDATE USER DETAILS
export const updateUserInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_USER_REQUEST:
      return { loading: true, ...state };
    case UPDATE_USER_SUCCESS:
      return { loading: false, singleUser: action.payload };
    case UPDATE_USER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const checkUserPasswordReducer = (
  state = { checkOldPassword: [] },
  action
) => {
  switch (action.type) {
    case GET_PASSWORD_CHECK_REQUEST:
      return { loading: true, ...state };
    case GET_PASSWORD_CHECK_SUCCESS:
      return { loading: false, checkOldPassword: action.payload };
    case RESET_PASSWORD_CHECK:
      return {
        checkOldPassword: [],
        loading: false,
        error: action.payload,
      };
    case GET_PASSWORD_CHECK_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER TO DELETE USER
export const deletUserReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_USER_REQUEST:
      return { loading: true, ...state };
    case DELETE_USER_SUCCESS:
      return { loading: false, deletedData: action.payload };
    case DELETE_USER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER FOR OFFICE STAFF
export const officeStaffsReducer = (state = { staffs: {} }, action) => {
  switch (action.type) {
    case ALL_OFFICE_STAFFS_REQUEST:
      return { ...state, loading: true };
    case ALL_OFFICE_STAFFS_SUCCESS:
      return { ...state, loading: false, staffs: action.payload };
    case ALL_OFFICE_STAFFS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case ALL_OFFICE_STAFFS_RESET:
      return { ...state, staffs: null, loading: false, error: null };
    default:
      return state;
  }
};

//REDUCER FOR ALL OFFICES
export const officeReducer = (state = { offices: [] }, action) => {
  switch (action.type) {
    case ALL_OFFICES_REQUEST:
      return { ...state, loading: true };
    case ALL_OFFICES_SUCCESS:
      return { ...state, loading: false, offices: action.payload };
    case ALL_OFFICES_FAIL:
      return { ...state, loading: false, error: action.payload };
    case FILTER_OFFICES_REQUEST:
      return {
        ...state,
        filtered: state.offices.filter((cst) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          // console.log(action.payload,regex)
          return cst.name.match(regex);
        }),
      };
    case CLEAR_OFFICES_FILTER:
      return { ...state, filtered: null };
    default:
      return state;
  }
};

//REDUCER FOR CREATE NEW OFFICE
export const createNewOfficeReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_NEW_OFFICE_REQUEST:
      return { loading: true, ...state };
    case CREATE_NEW_OFFICE_SUCCESS:
      return { ...state, loading: false, newOfficeCreated: action.payload };
    case CREATE_NEW_OFFICE_FAIL:
      return { ...state, loading: false, error: action.payload };
    case CREATE_NEW_OFFICE_RESET:
      return { ...state, newUserCreated: null, loading: false, error: null };
    default:
      return state;
  }
};

//REDUCER TO UPDATE OFFICE DETAILS
export const updateOfficeInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_OFFICE_REQUEST:
      return { loading: true, ...state };
    case UPDATE_OFFICE_SUCCESS:
      return { loading: false, singleOffice: action.payload };
    case UPDATE_OFFICE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER FOR ALL CURRENCY
export const currencyReducer = (state = { currencies: [] }, action) => {
  switch (action.type) {
    case ALL_CURRENCY_REQUEST:
      return { ...state, loading: true };
    case ALL_CURRENCY_SUCCESS:
      return { ...state, loading: false, currencies: action.payload };
    case ALL_CURRENCY_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER FOR CREATE NEW CURRENCY
export const createNewCurrencyReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_NEW_CURRENCY_REQUEST:
      return { loading: true, ...state };
    case CREATE_NEW_CURRENCY_SUCCESS:
      return { ...state, loading: false, newCurrencyCreated: action.payload };
    case CREATE_NEW_CURRENCY_FAIL:
      return { ...state, loading: false, error: action.payload };
    // case CREATE_NEW_CURRENCY_RESET:
    //   return { ...state, newUserCreated: null, loading: false, error: null };
    default:
      return state;
  }
};

//REDUCER FOR ALL EMPLOYEES
export const singleEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_SINGLE_EMPLOYEE_REQUEST:
      return { ...state, loading: true };
    case FETCH_SINGLE_EMPLOYEE_SUCCESS:
      return { ...state, loading: false, employee: action.payload };
    case FETCH_SINGLE_EMPLOYEE_RESET:
      return {};
    case FETCH_SINGLE_EMPLOYEE_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER FOR ALL EMPLOYEES
export const employeeReducer = (state = { employees: [] }, action) => {
  switch (action.type) {
    case ALL_EMPLOYEES_REQUEST:
      return { ...state, loading: true };
    case ALL_EMPLOYEES_SUCCESS:
      return { ...state, loading: false, employees: action.payload };
    case ALL_EMPLOYEES_FAIL:
      return { ...state, loading: false, error: action.payload };
    case FILTER_EMPLOYEES_REQUEST:
      return {
        ...state,
        filtered: state.employees.filter((cst) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return (
            cst.firstname.match(regex) ||
            cst.lastname.match(regex) ||
            (cst.officeName && cst.officeName.match(regex))
          );
        }),
      };
    case CLEAR_EMPLOYEES_FILTER:
      return { ...state, filtered: null };
    default:
      return state;
  }
};
//REDUCER FOR CREATE NEW EMPLOYEE
export const createNewEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_NEW_EMPLOYEE_REQUEST:
      return { loading: true, ...state };
    case CREATE_NEW_EMPLOYEE_SUCCESS:
      return { ...state, loading: false, newEmployeeCreated: action.payload };
    case CREATE_NEW_EMPLOYEE_FAIL:
      return { ...state, loading: false, error: action.payload };
    case CREATE_NEW_EMPLOYEE_RESET:
      return {
        ...state,
        newEmployeeCreated: null,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

//REDUCER TO UPDATE FETCH IMAGE
export const fetchEmployeeImageReducer = (state = {
  loading: false,
  photo: null,
  error: false,
  message: null,
}, action) => {
  switch (action.type) {
    case FETCH_EMPLOYEE_IMAGE_REQUEST:
      return { ...state, loading: true };
    case FETCH_EMPLOYEE_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        photo: action.payload
      };
    case FETCH_EMPLOYEE_IMAGE_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.payload
      };
    case RESET_EMPLOYEE_IMAGE:
      return {
        loading: false,
        photo: null,
        error: false,
        message: null,
      };
    default:
      return state;
  }
}

//REDUCER TO UPDATE EMPLOYEE IMAGE
export const updateEmployeeImageReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_EMPLOYEE_IMAGE_REQUEST:
      return { ...state, loading: true };
    case UPDATE_EMPLOYEE_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        singleEmployee: action.payload
      };
    case UPDATE_EMPLOYEE_IMAGE_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.payload
      };
    default:
      return state;
  }
};

//REDUCER TO UPDATE EMPLOYEE DETAILS
export const updateEmployeeInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_EMPLOYEE_REQUEST:
      return { loading: true, ...state };
    case UPDATE_EMPLOYEE_SUCCESS:
      return { loading: false, singleEmployee: action.payload };
    case UPDATE_EMPLOYEE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
// REDUCER FOR ALL ENTITY DATA TABLE CHECKS
export const entityDataTableReducer = (state = { entities: [] }, action) => {
  switch (action.type) {
    case ALL_ENTITY_DATA_TABLE_REQUEST:
      return { ...state, loading: true };
    case ALL_ENTITY_DATA_TABLE_SUCCESS:
      return { ...state, loading: false, entities: action.payload };
    case ALL_ENTITY_DATA_TABLE_FAIL:
      return { ...state, loading: false, error: action.payload };
    case FILTER_ENTITY_DATA_TABLE_REQUEST:
      return {
        ...state,
        filtered: state.entities.filter((cst) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return cst.entity.match(regex) || (cst.name && cst.name.match(regex));
        }),
      };
    case CLEAR_ENTITY_DATA_TABLE_FILTER:
      return { ...state, filtered: null };
    default:
      return state;
  }
};
//REDUCER FOR CREATE NEW ENTITY DATA TABLE CHECKS
export const createNewEntityDataTableReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_NEW_ENTITY_DATA_TABLE_REQUEST:
      return { loading: true, ...state };
    case CREATE_ENTITY_DATA_TABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        newEntityDataTableCreated: action.payload,
      };
    case CREATE_ENTITY_DATA_TABLE_FAIL:
      return { ...state, loading: false, error: action.payload };
    case CREATE_ENTITY_DATA_TABLE_RESET:
      return {
        ...state,
        newEntityDataTableCreated: null,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
//REDUCER FOR ENTITY DATA TABLE CHECKS TEMPLATE
export const entityDataTableTemplateReducer = (
  state = { entityTemplate: {} },
  action
) => {
  switch (action.type) {
    case ALL_ENTITY_DATA_TABLE_TEMPLATE_REQUEST:
      return { ...state, loading: true };
    case ALL_ENTITY_DATA_TABLE_TEMPLATE_SUCCESS:
      return { ...state, loading: false, entityTemplate: action.payload };
    case ALL_ENTITY_DATA_TABLE_TEMPLATE_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
//REDUCER TO DELETE ENTITY DATA TABLE CHECKS DETAILS
export const deleteEntityDataTableInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_ENTITY_DATA_TABLE_REQUEST:
      return { loading: true, ...state };
    case DELETE_ENTITY_DATA_TABLE_SUCCESS:
      return { loading: false, singleEntityDataTable: action.payload };
    case DELETE_ENTITY_DATA_TABLE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER FOR ALL HOLIDAYS
export const holidayReducer = (state = { holidays: [] }, action) => {
  switch (action.type) {
    case ALL_HOLIDAYS_REQUEST:
      return { ...state, loading: true };
    case ALL_HOLIDAYS_SUCCESS:
      return { ...state, loading: false, holidays: action.payload };
    case ALL_HOLIDAYS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case FILTER_HOLIDAYS_REQUEST:
      return {
        ...state,
        filtered: state.holidays.filter((cst) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return cst.name && cst.name.match(regex);
        }),
      };
    case CLEAR_HOLIDAYS_FILTER:
      return { ...state, filtered: null };
    default:
      return state;
  }
};

//REDUCER FOR HOLIDAY TEMPLATE
export const holidayTemplateReducer = (
  state = { holidayTemplate: {} },
  action
) => {
  switch (action.type) {
    case ALL_HOLIDAYS_TEMPLATE_REQUEST:
      return { ...state, loading: true };
    case ALL_HOLIDAYS_TEMPLATE_SUCCESS:
      return { ...state, loading: false, holidayTemplate: action.payload };
    case ALL_HOLIDAYS_TEMPLATE_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER FOR CREATE NEW HOLIDAY
export const createNewHolidayReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_NEW_HOLIDAY_REQUEST:
      return { loading: true, ...state };
    case CREATE_NEW_HOLIDAY_SUCCESS:
      return { ...state, loading: false, newHolidayCreated: action.payload };
    case CREATE_NEW_HOLIDAY_FAIL:
      return { ...state, loading: false, error: action.payload };
    case CREATE_NEW_HOLIDAY_RESET:
      return { ...state, newHolidayCreated: null, loading: false, error: null };
    default:
      return state;
  }
};

//REDUCER TO UPDATE HOLIDAY DETAILS
export const updateHolidayInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_HOLIDAY_REQUEST:
      return { loading: true, ...state };
    case UPDATE_HOLIDAY_SUCCESS:
      return { loading: false, singleHoliday: action.payload };
    case UPDATE_HOLIDAY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER TO DELETE HOLIDAY
export const deleteHolidayReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_HOLIDAY_REQUEST:
      return { loading: true, ...state };
    case DELETE_HOLIDAY_SUCCESS:
      return { loading: false, deletedData: action.payload };
    case DELETE_HOLIDAY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER TO ACTIVATE HOLIDAY
export const activateHolidayReducer = (state = {}, action) => {
  switch (action.type) {
    case ACTIVATE_HOLIDAY_REQUEST:
      return { loading: true, ...state };
    case ACTIVATE_HOLIDAY_SUCCESS:
      return { loading: false, activatedDate: action.payload };
    case ACTIVATE_HOLIDAY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER FOR WORKING DAYS
export const workingDaysReducer = (state = { workingDays: [] }, action) => {
  switch (action.type) {
    case ALL_WORKING_DAYS_REQUEST:
      return { ...state, loading: true };
    case ALL_WORKING_DAYS_SUCCESS:
      return { ...state, loading: false, workingDays: action.payload };
    case ALL_WORKING_DAYS_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER FOR CREATE NEW USER
export const createWorkingDayReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_NEW_WORKING_DAYS_REQUEST:
      return { loading: true, ...state };
    case CREATE_NEW_WORKING_DAYS_SUCCESS:
      return { ...state, loading: false, newUserCreated: action.payload };
    case CREATE_NEW_WORKING_DAYS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case CREATE_NEW_WORKING_DAYS_RESET:
      return { ...state, newUserCreated: null, loading: false, error: null };
    default:
      return state;
  }
};

//REDUCER FOR ALL STANDING INSTRUCTION
export const standingInstructionsReducer = (
  state = { standingInstructions: [] },
  action
) => {
  switch (action.type) {
    case ALL_STANDING_INSTRUCTION_REQUEST:
      return { ...state, loading: true };
    case ALL_STANDING_INSTRUCTION_SUCCESS:
      return {
        ...state,
        loading: false,
        standingInstructions: action.payload.pageItems,
      };
    case ALL_STANDING_INSTRUCTION_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER FOR STANDING INSTRUCTION TEMPLATE
export const standingInstructionsTemplateReducer = (
  state = { standingTemplate: {} },
  action
) => {
  switch (action.type) {
    case ALL_STANDING_INSTRUCTION_TEMPLATE_REQUEST:
      return { ...state, loading: true };
    case ALL_STANDING_INSTRUCTION_TEMPLATE_SUCCESS:
      return { ...state, loading: false, standingTemplate: action.payload };
    case ALL_STANDING_INSTRUCTION_TEMPLATE_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER FOR ALL PAYMENT TYPE
export const paymentTypeReducer = (state = { paymentTypes: [] }, action) => {
  switch (action.type) {
    case ALL_PAYMENT_TYPE_REQUEST:
      return { ...state, loading: true };
    case ALL_PAYMENT_TYPE_SUCCESS:
      return { ...state, loading: false, paymentTypes: action.payload };
    case ALL_PAYMENT_TYPE_FAIL:
      return { ...state, loading: false, error: action.payload };
    case FILTER_PAYMENT_TYPE_REQUEST:
      return {
        ...state,
        filtered: state.paymentTypes.filter((cst) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return cst.name && cst.name.match(regex);
        }),
      };
    case CLEAR_PAYMENT_TYPE_FILTER:
      return { ...state, filtered: null };
    default:
      return state;
  }
};

//REDUCER FOR CREATE NEW PAYMENT TYPE
export const createNewPaymentReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_NEW_PAYMENT_TYPE_REQUEST:
      return { loading: true, ...state };
    case CREATE_NEW_PAYMENT_TYPE_SUCCESS:
      return { ...state, loading: false, newPaymentCreated: action.payload };
    case CREATE_NEW_PAYMENT_TYPE_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER TO UPDATE PAYMENT TYPE DETAILS
export const updatePaymentTypeInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PAYMENT_TYPE_REQUEST:
      return { loading: true, ...state };
    case UPDATE_PAYMENT_TYPE_SUCCESS:
      return { loading: false, singlePaymentType: action.payload };
    case UPDATE_PAYMENT_TYPE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER TO DELETE PAYMENT TYPE
export const deletePaymentTypeReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PAYMENT_TYPE_REQUEST:
      return { loading: true, ...state };
    case DELETE_PAYMENT_TYPE_SUCCESS:
      return { loading: false, deletedData: action.payload };
    case DELETE_PAYMENT_TYPE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//PRODUCTS REDUCERS
//SAVINGS PRODUCT
//REDUCER FOR ALL SAVINGS
export const savingsProductsReducer = (
  state = { savingsProducts: [] },
  action
) => {
  switch (action.type) {
    case ALL_SAVINGS_PRODUCTS_REQUEST:
      return { ...state, loading: true };
    case ALL_SAVINGS_PRODUCTS_SUCCESS:
      return { ...state, loading: false, savingsProducts: action.payload };
    case ALL_SAVINGS_PRODUCTS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case FILTER_SAVINGS_PRODUCTS_REQUEST:
      return {
        ...state,
        filtered: state.savingsProducts.filter((cst) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return (
            cst.shortName.match(regex) || (cst.name && cst.name.match(regex))
          );
        }),
      };
    case CLEAR_SAVINGS_PRODUCTS_FILTER:
      return { ...state, filtered: null };
    default:
      return state;
  }
};

//REDUCER FOR SAVING PRODUCTS TEMPLATE
export const savingsProductsTemplateReducer = (
  state = { savingsProductsTemplate: {} },
  action
) => {
  switch (action.type) {
    case ALL_SAVINGS_PRODUCTS_TEMPLATE_REQUEST:
      return { ...state, loading: true };
    case ALL_SAVINGS_PRODUCTS_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        savingsProductsTemplate: action.payload,
      };
    case ALL_SAVINGS_PRODUCTS_TEMPLATE_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

//REDUCER FOR CREATE NEW SAVINGS PRODUCT
export const createNewSavingsProductReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_NEW_SAVINGS_PRODUCT_REQUEST:
      return { loading: true, ...state };
    case CREATE_NEW_SAVINGS_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        newSavingsProductCreated: action.payload,
      };
    case CREATE_NEW_SAVINGS_PRODUCT_FAIL:
      return { ...state, loading: false, error: action.payload };
    case CREATE_NEW_SAVINGS_PRODUCT_RESET:
      return {
        ...state,
        newSavingsProductCreated: null,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

//REDUCER TO UPDATE SAVINGS PRODUCT DETAILS
export const updateSavingsProductInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_SAVINGS_PRODUCT_REQUEST:
      return { loading: true, ...state };
    case UPDATE_SAVINGS_PRODUCT_SUCCESS:
      return { loading: false, singleSavingsProduct: action.payload };
    case UPDATE_SAVINGS_PRODUCT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER FOR SAVINGS PRODUCT
export const savingsProductReducer = (
  state = { savingsProduct: {} },
  action
) => {
  switch (action.type) {
    case SAVINGS_PRODUCT_REQUEST:
      return { ...state, loading: true };
    case SAVINGS_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        savingsProduct: action.payload,
      };
    case SAVINGS_PRODUCT_FAIL:
      return { ...state, loading: false, error: action.payload };
    case SAVINGS_PRODUCT_RESET:
      return { ...state, savingsProduct: null, loading: false, error: null };
    default:
      return state;
  }
};

//LOAN PRODUCTS
//REDUCER FOR ALL LOAN PRODUCTS
export const loanProductsReducer = (state = { loanProducts: [] }, action) => {
  switch (action.type) {
    case ALL_LOAN_PRODUCT_REQUEST:
      return { ...state, loading: true };
    case ALL_LOAN_PRODUCT_SUCCESS:
      return { ...state, loading: false, loanProducts: action.payload };
    case ALL_LOAN_PRODUCT_FAIL:
      return { ...state, loading: false, error: action.payload };
    case FILTER_LOAN_PRODUCT_REQUEST:
      return {
        ...state,
        filtered: state.loanProducts.filter((cst) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return (
            cst.shortName.match(regex) || (cst.name && cst.name.match(regex))
          );
        }),
      };
    case CLEAR_LOAN_PRODUCT_FILTER:
      return { ...state, filtered: null };
    default:
      return state;
  }
};

//REDUCER FOR LOAN PRODUCTS TEMPLATE
export const loanProductsTemplateReducer = (
  state = { loanProductsTemplate: {} },
  action
) => {
  switch (action.type) {
    case ALL_LOAN_PRODUCT_TEMPLATE_REQUEST:
      return { ...state, loading: true };
    case ALL_LOAN_PRODUCT_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loanProductsTemplate: action.payload,
      };
    case ALL_LOAN_PRODUCT_TEMPLATE_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

//REDUCER FOR CREATE NEW LOAN PRODUCT
export const createNewLoanProductReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_NEW_LOAN_PRODUCT_REQUEST:
      return { loading: true, ...state };
    case CREATE_NEW_LOAN_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        newLoanProductCreated: action.payload,
      };
    case CREATE_NEW_LOAN_PRODUCT_FAIL:
      return { ...state, loading: false, error: action.payload };
    case CREATE_NEW_LOAN_PRODUCT_RESET:
      return {
        ...state,
        newLoanProductCreated: null,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

//REDUCER TO UPDATE SAVINGS PRODUCT DETAILS
export const updateLoanProductInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_LOAN_PRODUCT_REQUEST:
      return { loading: true, ...state };
    case UPDATE_LOAN_PRODUCT_SUCCESS:
      return { loading: false, singleLoanProduct: action.payload };
    case UPDATE_LOAN_PRODUCT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER FOR LOAN PRODUCT
export const loanProductReducer = (state = { loanProduct: {} }, action) => {
  switch (action.type) {
    case LOAN_PRODUCT_REQUEST:
      return { ...state, loading: true };
    case LOAN_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        loanProduct: action.payload,
      };
    case LOAN_PRODUCT_FAIL:
      return { ...state, loading: false, error: action.payload };
    case LOAN_PRODUCT_RESET:
      return { ...state, loanProduct: null, loading: false, error: null };
    default:
      return state;
  }
};

//Charges
//REDUCER FOR ALL CHARGES
export const chargesReducer = (state = { charges: [] }, action) => {
  switch (action.type) {
    case ALL_CHARGES_REQUEST:
      return { ...state, loading: true };
    case ALL_CHARGES_SUCCESS:
      return { ...state, loading: false, charges: action.payload };
    case ALL_CHARGES_FAIL:
      return { ...state, loading: false, error: action.payload };
    case FILTER_CHARGES_REQUEST:
      return {
        ...state,
        filtered: state.charges.filter((cst) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return cst.name && cst.name.match(regex);
        }),
      };
    case CLEAR_CHARGES_FILTER:
      return { ...state, filtered: null };
    default:
      return state;
  }
};

//REDUCER FOR CHARGES TEMPLATE
export const chargesTemplateReducer = (
  state = { chargesTemplate: {} },
  action
) => {
  switch (action.type) {
    case ALL_CHARGES_TEMPLATE_REQUEST:
      return { ...state, loading: true };
    case ALL_CHARGES_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        chargesTemplate: action.payload,
      };
    case ALL_CHARGES_TEMPLATE_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

//REDUCER FOR CREATE CHARGE
export const createNewChargeReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_NEW_CHARGE_REQUEST:
      return { loading: true, ...state };
    case CREATE_NEW_CHARGE_SUCCESS:
      return {
        ...state,
        loading: false,
        createNewCharge: action.payload,
      };
    case CREATE_NEW_CHARGE_FAIL:
      return { ...state, loading: false, error: action.payload };
    case CREATE_NEW_CHARGE_RESET:
      return {
        ...state,
        createNewCharge: null,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

//REDUCER TO DELETE CHARGE
export const deleteChargeReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_CHARGE_REQUEST:
      return { loading: true, ...state };
    case DELETE_CHARGE_SUCCESS:
      return { loading: false, deletedData: action.payload };
    case DELETE_CHARGE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER TO UPDATE CHARGE DETAILS
export const updateChargeInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_CHARGE_REQUEST:
      return { loading: true, ...state };
    case UPDATE_CHARGE_SUCCESS:
      return { loading: false, singleCharge: action.payload };
    case UPDATE_CHARGE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER FOR CHARGE TEMPLATE
export const chargeReducer = (state = { charge: {} }, action) => {
  switch (action.type) {
    case CHARGE_REQUEST:
      return { ...state, loading: true };
    case CHARGE_SUCCESS:
      return {
        ...state,
        loading: false,
        charge: action.payload,
      };
    case CHARGE_FAIL:
      return { ...state, loading: false, error: action.payload };
    case CHARGE_RESET:
      return { ...state, charge: null, loading: false, error: null };
    default:
      return state;
  }
};
//REDUCER FOR TAX COMPONENTS Template
export const taxComponentsTemplateReducer = (
  state = { taxComponentsTemplate: {} },
  action
) => {
  switch (action.type) {
    case ALL_TAX_COMPONENTS_TEMPLATE_REQUEST:
      return { ...state, loading: true };
    case ALL_TAX_COMPONENTS_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        taxComponentsTemplate: action.payload,
      };
    case ALL_TAX_COMPONENTS_TEMPLATE_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER FOR ALL TAX COMPONENTS
export const taxComponentsReducer = (state = { taxComponents: [] }, action) => {
  switch (action.type) {
    case ALL_TAX_COMPONENTS_REQUEST:
      return { ...state, loading: true };
    case ALL_TAX_COMPONENTS_SUCCESS:
      return { ...state, loading: false, taxComponents: action.payload };
    case ALL_TAX_COMPONENTS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case FILTER_TAX_COMPONENTS_REQUEST:
      return {
        ...state,
        filtered: state.taxComponents.filter((cst) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return cst.name.match(regex);
        }),
      };
    case CLEAR_TAX_COMPONENTS_FILTER:
      return { ...state, filtered: null };
    default:
      return state;
  }
};

//REDUCER FOR CREATE NEW TAX COMPONENT
export const createNewTaxComponentReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_NEW_TAX_COMPONENT_REQUEST:
      return { loading: true, ...state };
    case CREATE_NEW_TAX_COMPONENT_SUCCESS:
      return {
        ...state,
        loading: false,
        newTaxComponentCreated: action.payload,
      };
    case CREATE_NEW_TAX_COMPONENT_FAIL:
      return { ...state, loading: false, error: action.payload };
    case CREATE_NEW_TAX_COMPONENT_RESET:
      return { ...state, newUserCreated: null, loading: false, error: null };
    default:
      return state;
  }
};

//REDUCER TO UPDATE TAX COMPONENT DETAILS
export const updateTaxComponentInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_TAX_COMPONENT_REQUEST:
      return { loading: true, ...state };
    case UPDATE_TAX_COMPONENT_SUCCESS:
      return { loading: false, singleTaxComponent: action.payload };
    case UPDATE_TAX_COMPONENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER FOR TAX GROUPS Template
export const taxGroupsTemplateReducer = (
  state = { taxGroupsTemplate: {} },
  action
) => {
  switch (action.type) {
    case ALL_TAX_GROUPS_TEMPLATE_REQUEST:
      return { ...state, loading: true };
    case ALL_TAX_GROUPS_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        taxGroupsTemplate: action.payload,
      };
    case ALL_TAX_GROUPS_TEMPLATE_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER FOR ALL TAX GROUPS
export const taxGroupsReducer = (state = { taxGroups: [] }, action) => {
  switch (action.type) {
    case ALL_TAX_GROUPS_REQUEST:
      return { ...state, loading: true };
    case ALL_TAX_GROUPS_SUCCESS:
      return { ...state, loading: false, taxGroups: action.payload };
    case ALL_TAX_GROUPS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case FILTER_TAX_GROUPS_REQUEST:
      return {
        ...state,
        filtered: state.taxGroups.filter((cst) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return cst.name.match(regex);
        }),
      };
    case CLEAR_TAX_GROUPS_FILTER:
      return { ...state, filtered: null };
    default:
      return state;
  }
};

//REDUCER FOR CREATE NEW TAX GROUP
export const createNewTaxGroupReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_NEW_TAX_GROUP_REQUEST:
      return { loading: true, ...state };
    case CREATE_NEW_TAX_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        newTaxGroupCreated: action.payload,
      };
    case CREATE_NEW_TAX_GROUP_FAIL:
      return { ...state, loading: false, error: action.payload };
    case CREATE_NEW_TAX_GROUP_RESET:
      return { ...state, newUserCreated: null, loading: false, error: null };
    default:
      return state;
  }
};

//REDUCER TO UPDATE TAX GROUP DETAILS
export const updateTaxGroupInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_TAX_GROUP_REQUEST:
      return { loading: true, ...state };
    case UPDATE_TAX_GROUP_SUCCESS:
      return { loading: false, singleTaxGroup: action.payload };
    case UPDATE_TAX_GROUP_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER FOR HOLIDAY TEMPLATE
export const auditReducer = (state = { audits: [] }, action) => {
  switch (action.type) {
    case ALL_AUDIT_REQUEST:
      return { ...state, loading: true };
    case ALL_AUDIT_SUCCESS:
      return { ...state, loading: false, audits: action.payload };
    case ALL_AUDIT_FAIL:
      return { ...state, loading: false, error: action.payload };
    case FILTER_AUDIT_USERS_REQUEST:
      return {
        ...state,
        filtered: state.audits.filter((cst) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return cst.maker && cst.maker.match(regex);
        }),
      };
    case CLEAR_AUDIT_USERS_FILTER:
      return { ...state, filtered: null };
    default:
      return state;
  }
};

//REDUCER FOR USER AUDIT
export const userAuditReducer = (state = { userAdits: [] }, action) => {
  switch (action.type) {
    case ALL_USER_AUDIT_REQUEST:
      return { ...state, loading: true };
    case ALL_USER_AUDIT_SUCCESS:
      return { ...state, loading: false, userAdits: action.payload };
    case ALL_USER_AUDIT_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER FOR USER TEMPLATE
export const auditTemplateReducer = (state = { auditTemplate: {} }, action) => {
  switch (action.type) {
    case ALL_AUDIT_TEMPLATE_REQUEST:
      return { ...state, loading: true };
    case ALL_AUDIT_TEMPLATE_SUCCESS:
      return { ...state, loading: false, auditTemplate: action.payload };
    case ALL_AUDIT_TEMPLATE_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
//REDUCER FOR SINGLE AUDIT
export const singleAuditReducer = (state = { singleAudit: {} }, action) => {
  switch (action.type) {
    case GET_SINGLE_AUDIT_REQUEST:
      return { ...state, loading: true };
    case GET_SINGLE_AUDIT_SUCCESS:
      return { ...state, loading: false, singleAudit: action.payload };
    case GET_SINGLE_AUDIT_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER FOR ALL HOOK
export const hookReducer = (state = { hooks: [] }, action) => {
  switch (action.type) {
    case ALL_HOOK_REQUEST:
      return { ...state, loading: true };
    case ALL_HOOK_SUCCESS:
      return { ...state, loading: false, hooks: action.payload };
    case ALL_HOOK_FAIL:
      return { ...state, loading: false, error: action.payload };
    case FILTER_HOOK_REQUEST:
      return {
        ...state,
        filtered: state.hooks.filter((cst) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return cst.displayName && cst.displayName.match(regex);
        }),
      };
    case CLEAR_HOOK_FILTER:
      return { ...state, filtered: null };
    default:
      return state;
  }
};

//REDUCER FOR CREATE NEW HOOK
export const createNewHookReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_NEW_HOOK_REQUEST:
      return { loading: true, ...state };
    case CREATE_NEW_HOOK_SUCCESS:
      return { ...state, loading: false, newHookCreated: action.payload };
    case CREATE_NEW_HOOK_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER TO UPDATE HOOK DETAILS
export const updateHookInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_HOOK_REQUEST:
      return { loading: true, ...state };
    case UPDATE_HOOK_SUCCESS:
      return { loading: false, singleHook: action.payload };
    case UPDATE_HOOK_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER TO DELETE HOOK
export const deleteHookReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_HOOK_REQUEST:
      return { loading: true, ...state };
    case DELETE_HOOK_SUCCESS:
      return { loading: false, deletedData: action.payload };
    case DELETE_HOOK_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER FOR HOOK TEMPLATE
export const hookTemplateReducer = (state = { hookTemplate: {} }, action) => {
  switch (action.type) {
    case ALL_HOOK_TEMPLATE_REQUEST:
      return { ...state, loading: true };
    case ALL_HOOK_TEMPLATE_SUCCESS:
      return { ...state, loading: false, hookTemplate: action.payload };
    case ALL_HOOK_TEMPLATE_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER FOR S3 AMAZON
export const s3Reducer = (state = { s3: {} }, action) => {
  switch (action.type) {
    case ALL_S3_REQUEST:
      return { ...state, loading: true };
    case ALL_S3_SUCCESS:
      return { ...state, loading: false, s3: action.payload };
    case ALL_S3_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER TO UPDATE S3 DETAILS
export const updateS3InfoReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_S3_REQUEST:
      return { loading: true, ...state };
    case UPDATE_S3_SUCCESS:
      return { loading: false, singleS3: action.payload };
    case UPDATE_S3_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER FOR SMTP
export const SMTPReducer = (state = { SMTP: {} }, action) => {
  switch (action.type) {
    case ALL_SMTP_REQUEST:
      return { ...state, loading: true };
    case ALL_SMTP_SUCCESS:
      return { ...state, loading: false, SMTP: action.payload };
    case ALL_SMTP_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER TO UPDATE SMTP DETAILS
export const updateSMTPInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_SMTP_REQUEST:
      return { loading: true, ...state };
    case UPDATE_SMTP_SUCCESS:
      return { loading: false, singleSMTP: action.payload };
    case UPDATE_SMTP_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER FOR SMS
export const SMSReducer = (state = { SMS: {} }, action) => {
  switch (action.type) {
    case ALL_SMS_REQUEST:
      return { ...state, loading: true };
    case ALL_SMS_SUCCESS:
      return { ...state, loading: false, SMS: action.payload };
    case ALL_SMS_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER TO UPDATE SMS DETAILS
export const updateSMSInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_SMS_REQUEST:
      return { loading: true, ...state };
    case UPDATE_SMS_SUCCESS:
      return { loading: false, singleSMS: action.payload };
    case UPDATE_SMS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER FOR NOTIFICATION AMAZON
export const notificationReducer = (state = { notification: {} }, action) => {
  switch (action.type) {
    case ALL_NOTIFICATION_REQUEST:
      return { ...state, loading: true };
    case ALL_NOTIFICATION_SUCCESS:
      return { ...state, loading: false, notification: action.payload };
    case ALL_NOTIFICATION_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER TO UPDATE NOTIFICATION DETAILS
export const updateNotificationInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_NOTIFICATION_REQUEST:
      return { loading: true, ...state };
    case UPDATE_NOTIFICATION_SUCCESS:
      return { loading: false, singleNOTIFICATION: action.payload };
    case UPDATE_NOTIFICATION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER FOR ALL CONFIGURATION
export const configurationReducer = (
  state = { configurations: { globalConfiguration: [] } },
  action
) => {
  switch (action.type) {
    case ALL_CONFIGURATION_REQUEST:
      return { ...state, loading: true };
    case ALL_CONFIGURATION_SUCCESS:
      return { ...state, loading: false, configurations: action.payload };
    case ALL_CONFIGURATION_FAIL:
      return { ...state, loading: false, error: action.payload };
    case FILTER_CONFIGURATION_REQUEST:
      return {
        ...state,
        filtered: state.configurations.globalConfiguration.filter((cst) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return cst.name && cst.name.match(regex);
        }),
      };
    case CLEAR_CONFIGURATION_FILTER:
      return { ...state, filtered: null };
    default:
      return state;
  }
};

//REDUCER FOR DISABLE CONFIGURATION
export const disableConfigurationReducer = (state = {}, action) => {
  switch (action.type) {
    case DISABLE_CONFIGURATION_REQUEST:
      return { loading: true, ...state };
    case DISABLE_CONFIGURATION_SUCCESS:
      return { ...state, loading: false, disabledConfig: action.payload };
    case DISABLE_CONFIGURATION_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER FOR DISABLE CONFIGURATION
export const enableConfigurationReducer = (state = {}, action) => {
  switch (action.type) {
    case ENABLE_CONFIGURATION_REQUEST:
      return { loading: true, ...state };
    case ENABLE_CONFIGURATION_SUCCESS:
      return { ...state, loading: false, enabledConfig: action.payload };
    case ENABLE_CONFIGURATION_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER TO UPDATE CONFIGURATION DETAILS
export const updateConfigurationInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_CONFIGURATION_REQUEST:
      return { loading: true, ...state };
    case UPDATE_CONFIGURATION_SUCCESS:
      return { loading: false, singleConfiguration: action.payload };
    case UPDATE_CONFIGURATION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER FOR MCTasks
export const MCTasksReducer = (state = { MCTasks: {} }, action) => {
  switch (action.type) {
    case ALL_MCTASKS_REQUEST:
      return { ...state, loading: true };
    case ALL_MCTASKS_SUCCESS:
      return { ...state, loading: false, MCTasks: action.payload };
    case ALL_MCTASKS_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER TO UPDATE MCTasks DETAILS
export const updateMCTasksInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_MCTASK_REQUEST:
      return { loading: true, ...state };
    case UPDATE_MCTASK_SUCCESS:
      return { loading: false, singleMCTask: action.payload };
    case UPDATE_MCTASK_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER FOR ALL ROLES
export const rolesReducer = (state = { roles: [] }, action) => {
  switch (action.type) {
    case ALL_ROLES_REQUEST:
      return { ...state, loading: true };
    case ALL_ROLES_SUCCESS:
      return { ...state, loading: false, roles: action.payload };
    case ALL_ROLES_FAIL:
      return { ...state, loading: false, error: action.payload };
    case FILTER_ROLES_REQUEST:
      return {
        ...state,
        filtered: state.roles.filter((cst) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return cst.name && cst.name.match(regex);
        }),
      };
    case CLEAR_ROLES_FILTER:
      return { ...state, filtered: null };
    default:
      return state;
  }
};

//REDUCER FOR CREATE NEW ROLE
export const createNewRoleReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_NEW_ROLE_REQUEST:
      return { loading: true, ...state };
    case CREATE_NEW_ROLE_SUCCESS:
      return { ...state, loading: false, newRoleCreated: action.payload };
    case CREATE_NEW_ROLE_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER TO UPDATE ROLE DETAILS
export const updateRoleInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_ROLE_REQUEST:
      return { loading: true, ...state };
    case UPDATE_ROLE_SUCCESS:
      return { loading: false, singleRole: action.payload };
    case UPDATE_ROLE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER TO DELETE ROLE
export const deleteRoleReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_ROLE_REQUEST:
      return { loading: true, ...state };
    case DELETE_ROLE_SUCCESS:
      return { loading: false, deletedData: action.payload };
    case DELETE_ROLE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER FOR DISABLE/ENABLE ROLE
export const ableRoleReducer = (state = {}, action) => {
  switch (action.type) {
    case ABLE_ROLE_REQUEST:
      return { loading: true, ...state };
    case ABLE_ROLE_SUCCESS:
      return { ...state, loading: false, enabledConfig: action.payload };
    case ABLE_ROLE_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER FOR A ROLE
export const roleReducer = (state = { role: {} }, action) => {
  switch (action.type) {
    case ROLE_REQUEST:
      return { ...state, loading: true };
    case ROLE_SUCCESS:
      return { ...state, loading: false, role: action.payload };
    case ROLE_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//REDUCER FOR CREATE NEW USER
export const ManageDataTables = (state = {}, action) => {
  switch (action.type) {
    case GET_DATA_TABLES:
      return { ...state, dataTables: action.payload };
    case GET_ONE_DATA_TABLE:
      return { ...state, oneDataTable: action.payload };
    default:
      return state;
  }
};
