import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { FormControl, Input, Select } from "@chakra-ui/react";
import { FormClass, ActionButton, FormElements } from "../styles";
import { formatDate } from "../../../../../util/FormatDate";
import { approveFixedDepositAccount, calculatePrematureFixedDepositAccount, prematureCloseFixedDepositAccount, prematureFixedDepositAccount } from "../../../../../actions/fixedDeposit";
import { resetFixedDepositAccountAction } from "../../../../../actions/fixedDeposit";

const PreMatureCloseModal = ({ onHide, history, id }) => {
  const dispatch = useDispatch();
  const fixedDepositData = useSelector((state) => state.fixedDepositData);

  let { fixedDepositAccountActions = {} } = fixedDepositData || {};

  const [activatedOn, setActivatedOn] = useState(new Date());

  const [activateOnDate, setActivatedOnDate] = useState("");
  const [amount, setAmount] = useState();
  const [action, setAction] = useState();
  const [note, setNote] = useState();
  const [transferDescription, setTransferDescription] = useState();
  const [description, setDescription] = useState();
  const [savingsAction, setSavingsAction] = useState();
  const [selectedOption, setSelectedOption] = useState();

  const [approvedModal, setApprovedModal] = useState(true);
  const [preClosureModal, setPreClosureModal] = useState(false);

  useEffect(() => {
    // console.log(fixedDepositAccountActions, 'fixedDepositAccountActions')
    if (
      !!fixedDepositAccountActions &&
      !!fixedDepositAccountActions.maturityAmount
    ) {
      setAmount(fixedDepositAccountActions.maturityAmount);
    }
  }, [fixedDepositAccountActions]);

  const handleActionOnChange = (e) => {
    setAction(e.target.value);
    setSelectedOption(e.target.value);
  };

  // const handleApprove = () => {
  //   let dataToSend = {
  //     dateFormat: "dd MMM yyyy",
  //     closedOnDate: formatDate(activatedOn, true),
  //     locale: "en",
  //   };
  //   dispatch(
  //     approveFixedDepositAccount(
  //       id,
  //       dataToSend,
  //       history,
  //       onHide,
  //       "calculatePrematureAmount"
  //     )
  //   );
  // };
  useEffect(() => {
    dispatch(resetFixedDepositAccountAction())
  }, [])

  const handleApprove = () => {
    let dataToSend = {
      dateFormat: "dd MMM yyyy",
      closedOnDate: formatDate(activatedOn, true),
      locale: "en",
    };
    dispatch(
      calculatePrematureFixedDepositAccount(
        id,
        dataToSend,
        history,
        "calculatePrematureAmount"
      )
    );
  };

  
  const handleDateChange = (date) => {
    setActivatedOn(date)
    let dataToSend = {
      dateFormat: "dd MMM yyyy",
      closedOnDate: formatDate(activatedOn, true),
      locale: "en",
    };
    dispatch(
      calculatePrematureFixedDepositAccount(
        id,
        dataToSend,
        history,
        // onHide,
        "calculatePrematureAmount"
      )
    );
  };

  useEffect(() => {
    if(fixedDepositAccountActions.maturityAmount) {
      handleDateChange(activatedOn)
    }
  }, [activatedOn])

  const cancelRequest = () => {
    onHide();
    dispatch(resetFixedDepositAccountAction());
  };


  const handlePreClosure = () => {
    let dataToSend = {
      dateFormat: "dd MMM yyyy",
      closedOnDate: formatDate(activatedOn, true),
      locale: "en",
      onAccountClosureId: selectedOption,
      toSavingsAccountId: savingsAction,
      transferDescription,
      note,
    };

    const newObject = { ...dataToSend };
    for (const key in newObject) {
      if (Object.prototype.hasOwnProperty.call(newObject, key)) {
        const element = newObject[key];
        if (!element) {
          delete newObject[key];
        }
      }
    }

    dataToSend = newObject;

    // console.log(dataToSend, "closureeee");

    dispatch(
      prematureCloseFixedDepositAccount(
        id,
        dataToSend,
        history,
        onHide,
        "prematureClose",
        true
      )
    );
    // onHide();
  };


  return (
    <>
      <Modal.Header>
        <Modal.Title>Premature Closure Of Fixed Deposit Account</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <FormElements style={{ width: "100%" }}>
            {/* Pre Mature Close Date* */}
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Pre Mature Close Date*</label>
              <FormControl style={{ width: "70%" }}>
                <DatePicker
                  id="published-date"
                  selected={activatedOn}
                  onChange={(date) => setActivatedOn(date)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="dd/mmm/yyyy"
                />
              </FormControl>
            </div>

            {!!fixedDepositAccountActions &&
            !!fixedDepositAccountActions.maturityAmount ? (
              <>
                {/* Maturity Amount* */}
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Maturity Amount*</label>
                  {/* <FormControl style={{ width: "42%", marginRight: "193px" }}> */}
                  <FormControl style={{ width: "70%" }}>
                    <Input
                      value={amount}
                      name="description"
                      style={{ width: "60%" }}
                      onChange={(e) => setAmount(e.target.value)}
                      disabled
                    ></Input>
                  </FormControl>
                </div>
                {/* Action* */}
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Action*</label>
                  <FormControl style={{ width: "70%" }}>
                    <Select
                      placeholder="Select Action"
                      size="md"
                      name="action"
                      value={action}
                      // disabled
                      onChange={handleActionOnChange}
                    >
                      {!!fixedDepositAccountActions &&
                      !!fixedDepositAccountActions.onAccountClosureOptions
                        ? fixedDepositAccountActions.onAccountClosureOptions.map(
                            ({ id, value }) => {
                              return (
                                <option value={id} key={id}>
                                  {value}
                                </option>
                              );
                            }
                          )
                        : ""}
                    </Select>
                  </FormControl>
                </div>

                {/* Transfer to Savings*  and Transfer description*/}
                {selectedOption && selectedOption === "200" && (
                  <>
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-label">Transfer to Savings*</label>
                      <div style={{width: "70%"}}>
                      <Select
                        placeholder="Select One"
                        size="md"
                        name="savingsAction"
                        value={savingsAction}
                        // disabled
                        onChange={(e) => setSavingsAction(e.target.value)}
                      >
                        {!!fixedDepositAccountActions &&
                        !!fixedDepositAccountActions.savingsAccounts
                          ? fixedDepositAccountActions.savingsAccounts.map(
                              ({ id, accountNo }) => {
                                return (
                                  <option value={id} key={id}>
                                    {accountNo}
                                  </option>
                                );
                              }
                            )
                          : ""}
                      </Select>

                      </div>
                    </div>
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <label className="text-label">Transfer Description</label>
                      <div className="inputtf" style={{ width: "70%" }}>
                      <Input
                        value={transferDescription}
                        name="transferDescription"
                        style={{ width: "60%" }}
                        onChange={(e) => setTransferDescription(e.target.value)}
                      ></Input>
                      </div>
                    </div>
                  </>
                )}

                {/* Note */}
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="text-label">Note</label>
                  <FormControl style={{ width: "70%" }}>
                    <Input
                      value={note}
                      name="note"
                      style={{ width: "60%" }}
                      onChange={(e) => setNote(e.target.value)}
                    ></Input>
                  </FormControl>
                </div>
              </>
            ) : (
              ""
            )}

            {/* <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Note</label>
              <Input
                value={description}
                name="description"
                style={{ width: "70%" }}
                onChange={(e) => setDescription(e.target.value)}
              ></Input>
            </div> */}
          </FormElements>
        </FormClass>
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex mt-4">
          <ActionButton color="#2C1DFF" onClick={cancelRequest}>
            <img
              src={
                require("../../../../../assets/images/icons/cancel.svg").default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={(e) => {
              e.preventDefault();
              if (
                !!fixedDepositAccountActions &&
                !!fixedDepositAccountActions.maturityAmount
              ) {
                handlePreClosure();
              } else {
                handleApprove();
              }
            }}
          >
            <img
              src={
                require("../../../../../assets/images/icons/save.svg").default
              }
              alt="submit icon"
            />
            Submit
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default PreMatureCloseModal;
