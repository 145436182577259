import React from "react";
import { Table } from "antd";
import Loading from "./Loading";

const columns = [
  {
    title: "Data Table Name",
    dataIndex: "dataTableName",
    key: "dataTableName",
  },
  {
    title: "Associated With",
    dataIndex: "associatedWith",
    key: "associatedWith",
  },
];

const TableLoader = (props) => {
  const someChartsData = [
    {
      key: 1,
      dataTableName: <Loading />,
      associatedWith: <Loading />,
    },
    {
      key: 2,
      dataTableName: <Loading />,
      associatedWith: <Loading />,
    },
    {
      key: 3,
      dataTableName: <Loading />,
      associatedWith: <Loading />,
    },
    {
      key: 4,
      dataTableName: <Loading />,
      associatedWith: <Loading />,
    },
    {
      key: 5,
      dataTableName: <Loading />,
      associatedWith: <Loading />,
    },
  ];
  return (
    <>
      <Table
        columns={columns}
        dataSource={someChartsData}
        pagination={{ defaultPageSize: 8 }}
        className="antdTable"
        defaultPageSize={8}
      />
    </>
  );
};

export default TableLoader;
