import React from "react";
import { Table } from "antd";
import Loading from "./Loading";

const columns = [
  // { title: "", dataIndex: "image", key: "image" },
  { title: "First Name", dataIndex: "firstname", key: "firstName" },
  { title: "Last Name", dataIndex: "lastname", key: "lastName" },
  { title: "Email Address", dataIndex: "email", key: "email" },
  { title: "Office", dataIndex: "office", key: "office" },
  // { title: "", dataIndex: "action", key: "action" },
];
const TableLoader = (props) => {
  const someChartsData = [
    {
      key: 1,
      firstname: <Loading />,
      lastname: <Loading />,
      email: <Loading />,
      office: <Loading />,
      action: <Loading />,
    },
    {
      key: 2,
      firstname: <Loading />,
      lastname: <Loading />,
      email: <Loading />,
      office: <Loading />,
      action: <Loading />,
    },
    {
      key: 3,
      name: <Loading />,
      lastname: <Loading />,
      email: <Loading />,
      office: <Loading />,
      action: <Loading />,
    },
    {
      key: 4,
      firstname: <Loading />,

      lastname: <Loading />,
      email: <Loading />,
      office: <Loading />,
      action: <Loading />,
    },
    {
      key: 5,
      firstname: <Loading />,
      lastname: <Loading />,
      email: <Loading />,
      office: <Loading />,
      action: <Loading />,
    },
  ];
  return (
    <>
      <Table
        columns={columns}
        dataSource={someChartsData}
        pagination={{ defaultPageSize: 8 }}
        className="antdTable"
        defaultPageSize={8}
      />
    </>
  );
};

export default TableLoader;
