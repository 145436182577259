import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Input, Textarea, Select } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import {
  getAllOffices,
  getAllTellerGroups,
  updateATellerGroup,
} from "../../../../../actions/tellerActions";
import { formatDateString } from "../../../../../util/FormatDate";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import DropdownMenu from "../../TellerGroup/DropdownMenu";

export const UpdateModal = ({
  data,
  handleCloseModal,
  accountRules,
  allOffice,
}) => {
  const [officeId, setOfficeId] = useState("");
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [accountingRuleId, setAccountingRuleId] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");
  const [defaultCurrency, setDefaultCurrency] = useState("");

  const dispatch = useDispatch();
  // accountRules

  const [optionSelected, setOptionSelected] = useState([]);
  const [currenciesOptions, setCurrenciesOptions] = useState([]);
  const [newDataValues, setNewDataValues] = useState([]);

  const tellerReducer = useSelector((state) => state.tellerReducer);

  let { setCurrencies = [] } = tellerReducer || {};

  useEffect(() => {
    if (data) {
      setDescription(data?.description);
      setName(data?.name);
      // setNewDataValues([...data.currencies]);
    }
  }, [data]);

  const onSubmit = () => {
    const id = data?.id;
    data = {
      name: name ? name : data?.name,
      startDate: startDate
        ? formatDateString(startDate, "dd/MMM/yyyy")
        : formatDateString(data?.startDate, "dd/MMM/yyyy"),
      endDate: endDate
        ? formatDateString(endDate, "dd/MMM/yyyy")
        : formatDateString(data?.endDate, "dd/MMM/yyyy"),

      status: status
        ? status
        : data?.status === "ACTIVE"
        ? "300"
        : data?.status === "INACTIVE"
        ? "400"
        : "",
      locale: "en",
      dateFormat: "dd/MMMM/yyyy",
      description: description ? description : data?.description,
      officeId: officeId ? officeId : data?.officeId,
      accountingRuleId: accountingRuleId
        ? accountingRuleId
        : data?.accountingRuleId,
      currencies: optionSelected,
    };
    const func = () => {
      dispatch(getAllTellerGroups());
    };
    dispatch(updateATellerGroup(data, id, func));
    setStartDate("");
    setEndDate("");
    setStatus("");
    setDescription("");
    setOfficeId("");
    setDefaultCurrency("");
    setAccountingRuleId("");
    setName("");
  };

  // console.log(optionSelected, "optionSelected ===");
  useEffect(() => {
    if (data?.currencies) {
      const oldCurrencies = [];
      data?.currencies.map((curr) =>
      oldCurrencies.push(curr.code)
      );
      setNewDataValues(oldCurrencies);
    } else setNewDataValues([]);
  }, [data?.currencies]);
  

  useEffect(() => {
    if (setCurrencies) {
      const newCurrencies = [];
      setCurrencies.map((curr) =>
        newCurrencies.push({ value: curr.code, label: curr.displayLabel })
      );
      setCurrenciesOptions(newCurrencies);
    } else setCurrenciesOptions([]);
  }, [setCurrencies]);

  const handleUpdateServices = (service_Data) => {
    setOptionSelected([...service_Data]);
  };


  return (
    <>
      <Modal.Header>
        <Modal.Title>Edit Teller Group</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="p-1 position-relative">
          <div className="d-flex mb-3 ">
            <TextQuestion className="text-label">
              Office<span className="required-color">*</span>{" "}
            </TextQuestion>{" "}
              <Select
                isFullWidth="true"
                name="officeId"
                placeholder={data?.officeName}
                value={officeId}
                onChange={(e) => setOfficeId(e.target.value)}
              >
                {!!allOffice?.length > 0
                  ? allOffice?.map((item) => {
                      return (
                        <option key={item.id} value={item?.id}>
                          {item?.name}
                        </option>
                      );
                    })
                  : ""}
              </Select>
          </div>
          <div className="d-flex mb-3 align-items-start">
            <TextQuestion className="text-label">
              Name<span className="required-color">*</span>{" "}
            </TextQuestion>{" "}
              <Input
                placeholder={data?.name}
                value={name}
                style={{ width: "60%" }}
                onChange={(e) => setName(e.target.value)}
              ></Input>
          </div>
          {/* dropdown menu  */}
          <div className="d-flex mb-3">
            <TextQuestion className="text-label">
              Currencies<span className="required-color">*</span>{" "}
            </TextQuestion>{" "}
            <div className="select-100-width" style={{ width: "57%" }}>
              <DropdownMenu
                values={newDataValues}
                multiple
                name="currency"
                placeholder="Select"
                updateServices={handleUpdateServices}
                options={currenciesOptions}
                byId
              />
              {/* <CheckSelect /> */}
              {/* <CheckboxSelect /> */}
            </div>
          </div>
          <div className="d-flex mb-3 ">
            <TextQuestion className="text-label">Description: </TextQuestion>{" "}
            <Textarea
              placeholder={data?.description}
              style={{ fontSize: "12px" }}
              onChange={(e) => setDescription(e.target.value)}
              value={description}
            ></Textarea>
          </div>
          <div className="d-flex mb-3 ">
            <TextQuestion className="text-label">
              Accounting Rule<span className="required-color">*</span>{" "}
            </TextQuestion>{" "}
            <Select
              isFullWidth="true"
              name="accountingRuleId"
              placeholder={data?.accountingRuleName}
              value={accountingRuleId}
              onChange={(e) => setAccountingRuleId(e.target.value)}
            >
              {!!accountRules?.length > 0
                ? accountRules?.map((item) => {
                    return (
                      <option key={item.id} value={item?.id}>
                        {item?.name}
                      </option>
                    );
                  })
                : ""}
            </Select>
          </div>
          <div className="d-flex mb-3">
            <TextQuestion className="text-label">
              Start date<span className="required-color">*</span>{" "}
            </TextQuestion>
            <DatePicker
              id="published-date"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              value={startDate}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText={formatDateString(data?.startDate, "dd/MMM/yyyy")}
            />
          </div>
          <div className="d-flex mb-3">
            <TextQuestion className="text-label">End date: </TextQuestion>
            <DatePicker
              id="published-date"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              value={endDate}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText={
                data?.endDate
                  ? formatDateString(data?.endDate, "dd/MMM/yyyy")
                  : "dd/MM/yyyy"
              }
            />
          </div>
          <div className="d-flex">
            <TextQuestion className="text-label">
              Status<span className="required-color">*</span>
            </TextQuestion>
            <Select
              onChange={(e) => setStatus(e.target.value)}
              value={status}
            >
              <option value="300">Active</option>
              <option value="400"> Inactive</option>
            </Select>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="float-right mt-1">
          <ActionButton color="#2C1DFF" onClick={handleCloseModal}>
            <img
              src={
                require("../../../../../assets/images/icons/cancel.svg").default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
            onClick={onSubmit}
          >
            <img
              src={
                require("../../../../../assets/images/icons/save.svg").default
              }
              alt="submit icon"
            />
            Update
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

const TextQuestion = styled.p`
  width: 40%;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: #979797;
  margin-right: 25px;
`;

const ActionButton = styled.button`
  background: ${(props) => props.color || "#2C1DFF"} !important;
  border-radius: 5px !important;
  color: #fff !important;
  margin: 0 3px;
  border: none;
  font-family: "AventaMedium";
  display: inline;
  font-size: 14px;
  height: 35px;
  width: 130px;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  position: relative;
  cursor: pointer;
  img {
    height: 14px;
    margin-right: 10px;
    display: inline;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;
