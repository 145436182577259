import React, { useState } from "react";
import { Input, Select, FormControl } from "@chakra-ui/react";
import { ActionButton, FormElements, FormClass } from "../styles";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";

const NewTeller = ({ onHide }) => {
  const [newTellerInfo, setNewTellerInfo] = useState({
    tellerName: "",
    office: "",
    description: "",
    status: "",
  });
  const [startDate, setStartDate] = useState(new Date() || "");
  const [endDate, setEndDate] = useState(new Date() || "");
  const { tellerName, office, description, status } = newTellerInfo;
  const createTellerHandler = (e) =>
    setNewTellerInfo({ ...newTellerInfo, [e.target.name]: e.target.value });

  return (
    <>
      <Modal.Header>
        <Modal.Title>Create Teller</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-direction-coloumn">
        <FormClass style={{ width: "90%" }}>
          <FormElements style={{ width: "100%" }}>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Teller Name*</label>
              <Input
                style={{ width: "70%" }}
                size="md"
                name="tellerName"
                value={tellerName}
                onChange={createTellerHandler}
              />
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Office*</label>
              <Select
                placeholder="Select Office"
                size="md"
                name="office"
                value={office}
                onChange={createTellerHandler}
              >
                <option>office1</option>
                <option>office2</option>
              </Select>
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Description</label>
              <Input
                style={{ width: "70%" }}
                size="md"
                name="description"
                value={description}
                onChange={createTellerHandler}
              />
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Start Date*</label>
              <FormControl style={{ width: "70%" }}>
                <DatePicker
                  style={{ width: "100%" }}
                  id="start-date"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="dd/mmm/yyyy"
                />
              </FormControl>
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Start Date*</label>
              <FormControl style={{ width: "70%" }}>
                <DatePicker
                  style={{ width: "100%" }}
                  id="start-date"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="dd/mmm/yyyy"
                />
              </FormControl>
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <label className="text-label">Status</label>
              <Select
                placeholder="Select Office"
                size="md"
                name="status"
                value={status}
                onChange={createTellerHandler}
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </Select>
            </div>
          </FormElements>
          <div className="float-right mt-4">
            <ActionButton color="#2C1DFF" onClick={onHide}>
              <img
                src={
                  require("../../../../../assets/images/icons/cancel.svg")
                    .default
                }
                alt="cancel icon"
              />
              Cancel
            </ActionButton>
            <ActionButton color="#2C1DFF" style={{ margin: "0px 0px 0 10px" }}>
              <img
                src={
                  require("../../../../../assets/images/icons/save.svg").default
                }
                alt="submit icon"
              />
              Submit
            </ActionButton>
          </div>
        </FormClass>
      </Modal.Body>
    </>
  );
};

export default NewTeller;
