import React, { useState } from "react";
import { Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Select } from "@chakra-ui/react";
import CurrencyFormat from "react-currency-format";

import {
  FormClass,
  FormElements,
  ActionButton,
  IconButton,
} from "../../styles.jsx";
import Toast from "../../../../../../util/Toast.js";
import { formatCurrency } from "../../../../../../util/FormatAmount";

const Charges = ({ template, charges, setCharges }) => {
  const [selectCharges, setSelectCharges] = useState("");


  const handleAddCharge = () => {
    if (selectCharges) {
      let newCharge = template.chargeOptions.filter(
        (item) => 
          item.id === Number(selectCharges)
      )[0];

      let check = charges.filter((item) => item.chargeId === newCharge.id);
     
      
      if (check.length === 0) {
        setCharges((p) => {
          // let {chargeId, amount, dueDate} = p
          return [...p, { ...newCharge, date: "" }];
        });

      } else {
        Toast({
          text: "Already added!",
          icon: "error",
          timer: 2000,
        });
      }
    } else {
      Toast({
        text: "Please select a charge",
        icon: "error",
        timer: 2000,
      });
    }
  };
  
  const handleChargeDelete = (chargeId) => {
    setCharges(charges.filter((item) => item.chargeId !== chargeId));
  };

  const handleDateChange = (index, date) => {
    let hhh = [...charges];

    hhh[Number(index)].date = date;
    setCharges(hhh);
  };

  const handleAmountChange = (index, amount) => {
    let hhh = [...charges];
    hhh[Number(index)].amount = amount;
    setCharges(hhh);
  };

  const handleRepaymentsChange = (index, feeInterval) => {
    let hhh = [...charges];
    hhh[Number(index)].feeInterval = feeInterval;
    setCharges(hhh);
  };
  return (
    <>
      <div className="col-sm-12 col-md-12 d-flex flex-wrap">
        <div className="col-sm-12 col-md-12 px-0 ">
          <FormClass>
            <FormElements style={{ width: "60%", margin: "0 auto" }}>
              <div className="form-group d-flex justify-content-between align-items-center">
                {/* <label className="text-label">Product</label> */}
                <Select
                  name="selectCharges"
                  value={selectCharges}
                  onChange={(e) => setSelectCharges(e.target.value)}
                  size="md"
                >
                  <option value="">Select Charge</option>
                  {template !== undefined && template
                    ? template.chargeOptions &&
                      template.chargeOptions.map((cur) => (
                          <option value={cur.id} key={cur.id}>
                            {cur.name}
                          </option>
                      ))
                    : null}
                </Select>
                <ActionButton
                  color="#2C1DFF"
                  style={{ margin: "0px 0px 0 10px" }}
                  onClick={handleAddCharge}
                >
                  Add Charge
                </ActionButton>
              </div>
            </FormElements>
            <br />
            <Table className="child-table">
              <thead>
                <tr style={{ backgroundColor: "#FAFAFA" }}>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Amount</th>
                  <th>Collected on</th>
                  <th>Repayments Every</th>
                  <th>Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {charges && charges.length > 0
                  ? charges.map(
                      (
                        {
                          chargeId,
                          name,
                          chargeCalculationType,
                          amount,
                          chargeTimeType,
                          feeInterval,
                          date,
                        },
                        index
                      ) => (
                        <tr key={chargeId}>
                          <td>{name}</td>
                          <td>{chargeCalculationType.value}</td>
                          <td>
                            <CurrencyFormat
                              value={amount}
                              name="min_pricipal"
                              thousandSeparator={true}
                              className="amount-input"
                              style={{
                                width: "60%",
                              }}
                              onValueChange={(values) => {
                                const { value } = values;
                                handleAmountChange(index, value);
                              }}
                            />
                          </td>
                          <td>{chargeTimeType.value}</td>
                          <td>
                        {chargeTimeType.value === "Weekly Fee" || 
                        chargeTimeType.value === "Monthly Fee" ? (
                        <>
                      <CurrencyFormat
                          value={feeInterval}
                          name="repayments"
                          thousandSeparator={true}
                          className="amount-input"
                          style={{
                            width: "60%",
                          }}
                          onValueChange={(values) => {
                            const { value } = values;
                            handleRepaymentsChange(index, value);
                          }}
                        />

                          </>
                        ) : null
                        }
                      </td>
                          <td>
                            {chargeTimeType.id === 2 ||
                            chargeTimeType.id === 6 ||
                            chargeTimeType.id === 11 ||
                            chargeTimeType.id === 7 
                             ? (
                              <>
                                {" "}
                                <DatePicker
                                  id="start-date"
                                  selected={date}
                                  onChange={(date) =>
                                    handleDateChange(index, date)
                                  }
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  placeholderText="dd/mmm/yyyy"
                                />
                              </>
                            ) : (
                              ""
                            )}
                          </td>
                          
                          <td>
                            <IconButton
                              onClick={() => handleChargeDelete(chargeId)}
                              id="deleteHoliday"
                              className="mr-1 mb-1"
                            >
                              <img
                                src={
                                  require("../../../../../../assets/images/icons/delete.svg")
                                    .default
                                }
                                alt="add"
                                className="icon-img"
                              />
                            </IconButton>
                          </td>
                        </tr>
                      )
                    )
                  : null}
              </tbody>
            </Table>
            <br />
            <br />
            <br />
          </FormClass>
        </div>
      </div>
    </>
  );
};

export default Charges;
