import React from 'react'

const Share = () => {
    return (
        <div>
            share
        </div>
    )
}

export default Share
