import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getNibssInflowData,
  getNibssInflowDataFilter,
  resetFilteredTransactionsInflow,
} from "../../../../actions/nibssConnectActions";
import TableLoaderView from "../TableLoader";
import {
  formatDateString,
  formatDateTimeNibss,
} from "../../../../util/FormatDate";
import { useHistory } from "react-router-dom";
import CheckPermission from "../../../../util/CheckPermission";
import { DownloadButton, FilterTransButton } from "../styles";
// import InflowFilterReportModal from "../../ManageNibssAccount/InflowFilterModal";
import Toast from "../../../../util/Toast";
import {
  getInflowDataDownload,
  getInflowDataFilter,
} from "../../../../services/Api/nibss.api.services";

function ManageInflow({
  onHide,
  handleInwardFilterTrans,
  toInflowDate,
  setToInflowDate,
  fromInflowDate,
  setFromInflowDate,
  setInflowModal,
  inflowModal,
  handleInwardFilterTransDownload,
  tenantCode,
}) {
  const dispatch = useDispatch();

  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  let history = useHistory();

  const nibssInflowList = useSelector((state) => state.nibssInflowTemplate);

  const nibssInflowListFilter = useSelector(
    (state) => state.nibssInflowFilteredTransTemplate
  );
  const nibssInflowFilteredDownloads = useSelector(
    (state) => state.nibssInflowFilteredDownload
  );

  const { loading, nibssInflowTemplate } = nibssInflowList;

  const { nibssInflowFilteredTransTemplate } = nibssInflowListFilter;

  let filteredData = nibssInflowFilteredTransTemplate?.pageItems;
  let filteredTotalRecords =
    nibssInflowFilteredTransTemplate?.totalFilteredRecords;

  const { totalItems, numberOfPages, totalFilteredRecords, pageItems } =
    nibssInflowTemplate || {};

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 12,
  });
  const [paginationFilter, setPaginationFilter] = useState({
    current: 1,
    pageSize: 12,
  });
  const [activePagination, setActivePagination] = useState();
  const [activePaginationFilter, setActivePaginationFilter] = useState();

  

  const [myData, setMyData] = useState([]);

  useEffect(() => {
    if (tenantCode) {
      dispatch(
        getNibssInflowData(
          "orderBy=createdAt&sortBy=DESC&limit=12&offset=0",
          tenantCode
        )
      );
    }
  }, [dispatch , tenantCode]);

  useEffect(() => {
    if (!!nibssInflowTemplate && !!nibssInflowTemplate?.length) {
      setMyData(nibssInflowTemplate);
    } else if (!!pageItems?.length) {
      setMyData(pageItems);
    }
  }, [pageItems, nibssInflowTemplate]);

  // FILTER TRANSACTION
  useEffect(() => {
    if (
      !!nibssInflowFilteredTransTemplate &&
      !!nibssInflowFilteredTransTemplate?.length
    ) {
      setMyData(nibssInflowFilteredTransTemplate);
    } else if (!!filteredData?.length) {
      setMyData(filteredData);
    }
  }, [filteredData, nibssInflowFilteredTransTemplate]);

  useEffect(() => {
    if (totalFilteredRecords !== 0 && totalFilteredRecords !== 1) {
      setPagination({
        current: activePagination,
        pageSize: 12,
        total: totalFilteredRecords,
      });
    } else if (totalItems === 0 && numberOfPages === 0) {
      setPagination(false);
    } else if (!!nibssInflowTemplate && !!nibssInflowTemplate?.length) {
      setPagination({ pageSize: 12 });
    }
  }, [
    totalItems,
    numberOfPages,
    totalFilteredRecords,
    activePagination,
    nibssInflowTemplate,
  ]);

  // FILTER TRANSACTION
  useEffect(() => {
    if (filteredTotalRecords !== 0 && filteredTotalRecords !== 1) {
      setPaginationFilter({
        current: activePaginationFilter,
        pageSize: 12,
        total: filteredTotalRecords,
      });
    } else if (totalItems === 0 && numberOfPages === 0) {
      setPaginationFilter(false);
    } else if (
      !!nibssInflowFilteredTransTemplate &&
      !!nibssInflowFilteredTransTemplate?.length
    ) {
      setPaginationFilter({ defaultPageSize: 12 });
    }
  }, [
    totalItems,
    numberOfPages,
    filteredTotalRecords,
    activePaginationFilter,
    nibssInflowFilteredTransTemplate,
  ]);

  const handleTableChange = (pagination) => {
    setActivePagination(pagination?.current);
    if (!!nibssInflowTemplate && !!nibssInflowTemplate?.length) {
      setPagination({
        current: activePagination,
        pageSize: 12,
        total: nibssInflowTemplate?.length,
      });
    } else {
      let myOffsect = pagination?.current * 12;
      // let dataToSend2 = `limit=12&offset=${myOffsect - 12}&sortOrder=DESC`;
      let dataToSend2 = `orderBy=createdAt&sortBy=DESC&limit=12&offset=${
        myOffsect - 12
      }`;
      dispatch(getNibssInflowData(dataToSend2, tenantCode));
    }
  };

  // FILTER HANDLETABLECHANGE
  const handleFilterTableChange = (paginationFilter, filters, sorte) => {
    setActivePaginationFilter(paginationFilter.current);
    if (
      !!nibssInflowFilteredTransTemplate &&
      !!nibssInflowFilteredTransTemplate?.length
    ) {
      setPaginationFilter({
        current: activePaginationFilter,
        pageSize: 12,
        total: nibssInflowFilteredTransTemplate?.length,
      });
    } else {
      let myOffsect = paginationFilter.current * 12;

      let startDate = formatDateString(fromInflowDate, "yyyy-MMMM-dd");
      let endDate = formatDateString(toInflowDate, "yyyy-MMMM-dd");

      let dataToSend2 = `orderBy=createdAt&sortBy=DESC&limit=12&offset=${
        myOffsect - 12
      }`;

      dispatch(
        getNibssInflowDataFilter(
          dataToSend2,
          tenantCode,
          startDate,
          endDate,
          onHide
        )
      );
    }
  };

  const truncateString = (str, num) => {
    if (str?.length > num) {
      let subStr = str.substring(0, num);
      return subStr + "...";
    } else {
      return str;
    }
  };

  const dataSource = [];
  const filteredTransaction = [];

  const [downloadDataSource, setDownloadDataSource] = useState([]);

  !loading &&
    nibssInflowTemplate?.pageItems?.forEach((inflow) => {
      const {
        id,
        createdAt,
        // transactionStatus,
        responseCode,
        customerAccountNumber,
        customerAccountName,
        senderBankName,
        amount,
        beneficiaryAccountName,
        beneficiaryAccountNumber,
        paymentReference,
        sessionId,
        narration,
      } = inflow;
      dataSource?.push({
        key: id,
        createdAt: formatDateTimeNibss(createdAt),
        responseCode: responseCode === "00" ? "00" : responseCode,
        customerAccountNumber: customerAccountNumber,
        customerAccountName: customerAccountName,
        senderBankName: truncateString(senderBankName, 15),
        amount: amount,
        name: truncateString(beneficiaryAccountName, 15),
        number: beneficiaryAccountNumber,
        ref: truncateString(paymentReference, 15),
        sessionId: sessionId,
        narration: truncateString(narration, 15),
        transactionStatus: (
          <>
            {responseCode === "00" ? (
              <>
                <span className="new-text-success">Approved</span>
              </>
            ) : (
              ""
            )}
            {responseCode === "96" ? (
              <>
                <span className="new-text-danger">System Malfunction</span>
              </>
            ) : (
              ""
            )}
            {responseCode === "57" ? (
              <>
                <span className="new-text-danger">Not Permitted</span>
              </>
            ) : (
              ""
            )}
            {responseCode === "09" ? (
              <>
                <span className="new-text-pending">In Progress</span>
              </>
            ) : (
              ""
            )}
            {responseCode === "07" ? (
              <>
                <span className="new-text-warning">Invalid Account</span>
              </>
            ) : (
              ""
            )}
            {responseCode === "13" ? (
              <>
                <span className="new-text-warning">Invalid Amount</span>
              </>
            ) : (
              ""
            )}
            {responseCode === "61" ? (
              <>
                <span className="new-text-warning">
                  Transfer Limit Exceeded
                </span>
              </>
            ) : (
              ""
            )}
            {responseCode === "16" ? (
              <>
                <span className="new-text-danger">Unknown Bank Code</span>
              </>
            ) : (
              ""
            )}
            {responseCode === "30" ? (
              <>
                <span className="new-text-danger">Format Error</span>
              </>
            ) : (
              ""
            )}
          </>
        ),
      });
    });

  // FILTER DATASOURCE

  !loading &&
    filteredData?.forEach((inflow) => {
      const {
        id,
        createdAt,
        transactionStatus,
        responseCode,
        customerAccountNumber,
        customerAccountName,
        senderBankName,
        amount,
        beneficiaryAccountName,
        beneficiaryAccountNumber,
        paymentReference,
        sessionId,
        narration,
      } = inflow;
      filteredTransaction?.push({
        key: id,
        createdAt: formatDateTimeNibss(createdAt),
        responseCode: responseCode === "00" ? "00" : responseCode,
        customerAccountNumber: customerAccountNumber,
        customerAccountName: customerAccountName,
        senderBankNamefull: senderBankName,
        senderBankName: truncateString(senderBankName, 15),
        amount: amount,
        namefull: beneficiaryAccountName,
        name: truncateString(beneficiaryAccountName, 15),
        number: beneficiaryAccountNumber,
        reffull: paymentReference,
        ref: truncateString(paymentReference, 15),
        sessionId: sessionId,
        narrationFull: narration,
        narration: truncateString(narration, 15),
        transactionStatus: (
          <>
            {responseCode === "00" ? (
              <>
                <span className="new-text-success">Approved</span>
              </>
            ) : (
              ""
            )}
            {responseCode === "96" ? (
              <>
                <span className="new-text-danger">System Malfunction</span>
              </>
            ) : (
              ""
            )}
            {responseCode === "57" ? (
              <>
                <span className="new-text-danger">Not Permitted</span>
              </>
            ) : (
              ""
            )}
            {responseCode === "09" ? (
              <>
                <span className="new-text-pending">In Progress</span>
              </>
            ) : (
              ""
            )}
            {responseCode === "07" ? (
              <>
                <span className="new-text-warning">Invalid Account</span>
              </>
            ) : (
              ""
            )}
            {responseCode === "13" ? (
              <>
                <span className="new-text-warning">Invalid Amount</span>
              </>
            ) : (
              ""
            )}
            {responseCode === "61" ? (
              <>
                <span className="new-text-warning">
                  Transfer Limit Exceeded
                </span>
              </>
            ) : (
              ""
            )}
            {responseCode === "16" ? (
              <>
                <span className="new-text-danger">Unknown Bank Code</span>
              </>
            ) : (
              ""
            )}
            {responseCode === "30" ? (
              <>
                <span className="new-text-danger">Format Error</span>
              </>
            ) : (
              ""
            )}
          </>
        ),
      });
    });

  const columns = [
    { title: "Date / Time", dataIndex: "createdAt", key: "createdAt" },
    {
      title: "Transaction Status",
      dataIndex: "transactionStatus",
      key: "transactionStatus",
    },
    { title: "Response Code", dataIndex: "responseCode", key: "responseCode" },
    {
      title: "Sender's Account Number",
      dataIndex: "customerAccountNumber",
      key: "customerAccountNumber",
    },
    {
      title: "Sender's Account Name",
      dataIndex: "customerAccountName",
      key: "customerAccountName",
    },
    {
      title: "Sender's Bank Name",
      dataIndex: "senderBankName",
      key: "senderBankName",
    },
    { title: "Amount", dataIndex: "amount", key: "amount" },
    {
      title: "Receipient's Account Number",
      dataIndex: "number",
      key: "number",
    },
    { title: "Receipient's Account Name", dataIndex: "name", key: "name" },
    { title: "Reference ID", dataIndex: "ref", key: "ref" },
    { title: "Session ID", dataIndex: "sessionId", key: "sessionId" },
    { title: "Narration", dataIndex: "narration", key: "narration" },
  ];

  useEffect(() => {
    if (tenantCode) {
      getInflowDataDownload(
        "orderBy=createdAt&sortBy=DESC&limit=10000&offset=0",
        tenantCode
      ).then((data) => setDownloadDataSource(data?.pageItems));
    }
  }, [tenantCode]);

  // UseFeect Filter
  // useEffect(() => {
  //   handleInwardFilterTransDownload()
  // }, []);

  const downloadCSV = () => {
    if (
      filteredData !== null &&
      filteredData !== undefined
      // filteredData === null
    ) {
      const items = filteredData;
      // Convert JSON data to CSV
      const replacer = (key, value) => (value === null ? "" : value);
      // const header = Object.keys(items[0]);
      const header = [
        "DATE/TIME",
        "TRANSACTION STATUS",
        "RESPONSE CODE",
        "SENDER'S ACCOUNT NUMBER",
        "SENDER'S ACCOUNT NAME",
        "SENDER'S BANK NAME",
        "AMOUNT",
        "RECEIPIENT'S ACCOUNT NAME",
        "RECEIPIENT'S ACCOUNT NUMBER",
        "REFERENCE ID",
        "SESSION ID",
        "NARRATION",
      ]; //Object.keys(items[0]);
      const headerFilter = [
        "createdAt",
        "transactionRequestStatus",
        "responseCode",
        "customerAccountNumber",
        "customerAccountName",
        "senderBankName",
        "amount",
        "beneficiaryAccountName",
        "beneficiaryAccountNumber",
        "nameEnquiryReference",
        "sessionId",
        "narration",
      ]; //Object.keys(items[0]);

      let csv = items.map((row) =>
        headerFilter
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      );
      csv.unshift(header.join(","));
      csv = csv.join("\r\n");
      // Download the CSV file
      const filename =
        "Inflow Transaction History" +
        Math.floor(Math.random() * (9000 - 1000) + 1000) +
        "data.csv";
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      if (link.download !== undefined) {
        link.setAttribute("href", URL.createObjectURL(blob));
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } else {
      const items = downloadDataSource;
      // Convert JSON data to CSV
      const replacer = (key, value) => (value === null ? "" : value);

      const headerTitle = ["INFLOW TRANSACTIONs", ",", ","]; //Object.keys(items[0]);

      const header = [
        "DATE/TIME",
        "TRANSACTION STATUS",
        "RESPONSE CODE",
        "SENDER'S ACCOUNT NUMBER",
        "SENDER'S ACCOUNT NAME",
        "SENDER'S BANK NAME",
        "AMOUNT",
        "RECEIPIENT'S ACCOUNT NAME",
        "RECEIPIENT'S ACCOUNT NUMBER",
        "REFERENCE ID",
        "SESSION ID",
        "NARRATION",
      ]; //Object.keys(items[0]);
      const headerFilter = [
        "createdAt",
        "transactionRequestStatus",
        "responseCode",
        "customerAccountNumber",
        "customerAccountName",
        "senderBankName",
        "amount",
        "beneficiaryAccountName",
        "beneficiaryAccountNumber",
        "nameEnquiryReference",
        "sessionId",
        "narration",
      ]; //Object.keys(items[0]);

      let csv = items.map((row) =>
        headerFilter
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          // .map((fieldName) => console.log(JSON.stringify(row[fieldName], replacer)) , "-------")
          .join(",")
      );
      csv.unshift(header.join(","));
      csv = csv.join("\r\n");
      // Download the CSV file
      const filename =
        "Inflow Transaction History" +
        Math.floor(Math.random() * (9000 - 1000) + 1000) +
        "data.csv";
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      if (link.download !== undefined) {
        link.setAttribute("href", URL.createObjectURL(blob));
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  // console.log(dataSource, "dataSource");

  // useEffect(() => {
  //   if (filteredTotalRecords === 0) {
  //     dispatch(
  //       Toast({
  //         text: "No report Found",
  //         icon: "error",
  //       })
  //     );
  //   }
  // }, [filteredTotalRecords]);

  return (
    <>
      {/* // Download Button */}
      <div className="button__downloadinflow">
        {CheckPermission("", permissions) && (
          <FilterTransButton onClick={() => setInflowModal(true)}>
            Filter
            <img
              src={
                require("../../../../assets/images/icons/filterArrowDown.svg")
                  .default
              }
              alt="filter"
            />
          </FilterTransButton>
        )}
        {CheckPermission("", permissions) && (
          <DownloadButton onClick={downloadCSV}>
            {/* // <DownloadButton> */}
            Download
            <img
              src={
                require("../../../../assets/images/icons/nibss-download.svg")
                  .default
              }
              alt="download"
            />
          </DownloadButton>
        )}
      </div>
      <div className="mt-3 col-sm-12 col-md-12">
        {loading === true && nibssInflowTemplate?.pageItems?.length ? (
          <TableLoaderView />
        ) : (
          // <></>
          <>
            {filteredData && handleInwardFilterTrans ? (
              <Table
                style={{
                  overflow: "hidden",
                  overflowX: "scroll",
                }}
                className="tableNibss"
                columns={columns}
                defaultPageSize={"12"}
                dataSource={filteredTransaction}
                onRow={(r) => ({
                  onClick: () =>
                    history.push({
                      pathname: `/core/nibss-management/manage-account/inflowtransactiondetails/${r.key}`,
                      state: [filteredData],
                    }),
                })}
                pagination={paginationFilter}
                onChange={handleFilterTableChange}
              />
            ) : (
              <>
                <Table
                  style={{
                    overflow: "hidden",
                    overflowX: "scroll",
                  }}
                  className="tableNibss"
                  columns={columns}
                  dataSource={dataSource}
                  onRow={(r) => ({
                    onClick: () =>
                      history.push({
                        pathname: `/core/nibss-management/manage-account/inflowtransactiondetails/${r.key}`,
                        state: [pageItems],
                      }),
                  })}
                  pagination={pagination}
                  onChange={handleTableChange}
                />
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default ManageInflow;
